const { findWhere } = require('underscore');

const GuidedLearningObjectiveSearchCriteriaFactory = require('@common/data/models/assessments/GuidedLearningObjectiveSearchCriteriaFactory');

module.exports = {
  inflateListBasedOnPrerequisiteId: (flatList = []) => {
    // inflate the flattened list
    // if it has no prerequisiteId just wrap it in an array and push to the list
    // if it does have a prerequisiteId iterate over the list of lists in reverse to find the nearest objective with a matching `id` and push to this list
    const insertItemAfterPrerequisite = (inflatedList = [], item) => {
      for (let i = inflatedList.length - 1; i >= 0; i--) {
        const list = inflatedList[i];
        if (findWhere(list, {id: item.prerequisiteId})) {
          list.push(item);
          return;
        }
      }
      // we can't find the item with the id that matches this item's prerequisiteId, there's something wrong with the data
      throw new Error(`Unable to find a perequisite with id '${ item.prerequisiteId }'`);
    };

    const inflatedList = [];

    let item;
    while (flatList.length) {
      item = flatList.shift();
      if (item.prerequisiteId) {
        insertItemAfterPrerequisite(inflatedList, item);
      } else {
        inflatedList.push([item]);
      }
    }

    return inflatedList;
  },

  decorateWithInProgressAssessments: (items, sessionModel) => {
    items.forEach((item) => {
      sessionModel.extendWithAssessmentGetter(item, GuidedLearningObjectiveSearchCriteriaFactory.createCriteria(item));
    });
  }
};
