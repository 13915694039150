// Module
var code = `<div class="base-card__wrapper base-card__wrapper--whole qa-container-card-base">
  <% if (isAssigned) { %>
    <div class="base-card__badge-assigned">
      <span class="assigned-text qa-text-assigned"><%- t('selfDirected.search.assigned').toUpperCase() %></span>
    </div>
  <% } %>
  <div class="base-card__image path-background">
    <% if (isAssigned) { %>
      <div class="base-card__badge-assigned--mobile">
        <span class="assigned-text qa-text-assigned"><%- t('selfDirected.search.assigned').toUpperCase() %></span>
      </div>
    <% } %>
  </div>
  <div class="base-card__wrapper--content qa-container-card-content">
    <div class="base-card__wrapper base-card__wrapper--upper js-base-card__wrapper--upper">
      <div class="title-region">
        <div class="base-card__title"></div>
        <div class="base-card__view-all__wrapper">
          <button class="base-card__view-all qa-button-view-all ax-button ax-button--m ax-button--ghost ax-button--ghost-parent-hover" <%= linkAttributes %>>
            <%- linkText %>
          </button>
        </div>
      </div>
    </div>
    <div class="base-card__wrapper base-card__wrapper--lower"></div>
  </div>
</div>
`;
// Exports
module.exports = code;