const ViewConfig = require('@common/components/view/ViewConfig');
const DetailItem = require('@common/components/details/item/DetailItem');

class DetailViewConfig extends ViewConfig {
  defaults() {
    return {
      viewClass: DetailItem,
      viewOptions: {
        label: '',
        key: ''
      }
    };
  }

  validate(attrs = {}) {
    const validationError = super.validate(...arguments);
    if (validationError != null) {
      return validationError;
    }
    if (!(this.inheritsFrom(attrs.viewClass, DetailItem))) {
      return this.createValidationError('viewClass needs to be inherited from DetailItem', attrs);
    }
  }
}
module.exports = DetailViewConfig;
