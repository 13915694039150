// Module
var code = `<div class="agreement-page">

  <div class="clearfix block-header">
  <h1 class="section-title page-header">
    <span class="page-header__icon icon-legal" aria-hidden="true"></span>
    <span class="page-header__title"><%- t('login.agreement.title') %></span>
  </h1>
    <div class="agreement-language-selector pull-right">
        <label for="language"><%- t('activateaccount.languagepref') %></label>
      <select no-default class="language-selector text-left" name="language" data-editor="AxonSelect" data-field="language" data-options="languages"></select>
    </div>
  </div>

  <div class="agreement-content-container" role="article">
    <%= agreeementHtml %>
  </div>

  <div class="agreement-footer-container ax-grid ax-grid--align-items-center" role="contentinfo" aria-label="<%- t('login.agreement.footer') %>">
    <div class="checkbox-container ax-grid__col--12 ax-grid__col--m">
      <%= axCheckbox({ id: 'agree-box', checked: hasAgreed, label: t('login.agreement.agreeCheckbox') }) %>
    </div>
    <div class="button-container ax-grid__col--auto-size">
      <button class="axon-button agree-button disabled" id="agree-button"
        aria-disabled="true" aria-labelledby="agree-button agree-hint">
        <%- t('login.agreement.agreeButton') %>
      </button>
    </div>
    <label class="off-screen" id="agree-hint"><%- t('login.agreement.agreeHint') %></label>
  </div>
</div>
`;
// Exports
module.exports = code;