// Module
var code = `<% if (hasRecords()) { %>
  <%if (hasPreviousPage()) { %>
  <div class="left-controls">
  <% } else { %>
  <div class="left-controls invisible">
  <% } %>
    <div class="first-page-button qa-first-page-button">
      <button class="pagination-button pagination-button__first" aria-label="<%- t('pagination.firstPage') %>">
        <span class="icon-double_angle_left"></span></span><span class="first-page-text"><%- t('general.first') %></span>
      </button>
    </div>
    <div class="previous-page-button qa-previous-page-button">
      <button class="pagination-button pagination-button__prev" aria-label="<%- t('pagination.previousPage') %>">
        <span class="icon-chevron_left"></span>
      </button>
    </div>
  </div>
  <div class="page-number-list qa-page-number-list">
    <%= getPageNumberList(pageRangeConfig) %>
  </div>
  <%if (hasNextPage()) { %>
  <div class="right-controls">
  <% } else { %>
  <div class="right-controls invisible">
  <% } %>
    <div class="next-page-button qa-next-page-button">
      <button class="pagination-button pagination-button__next" aria-label="<%- t('pagination.nextPage') %>">
        <span class="icon-chevron_right"></span>
      </button>
    </div>
    <div class="last-page-button qa-last-page-button">
      <button class="pagination-button pagination-button__last" aria-label="<%- t('pagination.lastPage') %>">
        <span class="last-page-text"><%- t('general.last') %></span><span class="icon-double_angle_right"></span></span>
      </button>
    </div>
  </div>
<% } %>
`;
// Exports
module.exports = code;