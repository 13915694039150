const Backbone = require('Backbone');
const TrainingModuleType = require('@common/data/enums/TrainingModuleType');
const ModuleLinksCollection = require('@common/components/training_modules/ModuleLinksCollection');
const TranslatableString = require('@common/data/models/translationStrings/TranslatableString');

class PreviewTrainingModule extends Backbone.Model {
  get translatableStringKey() {
    return 'translatableString';
  }

  initialize(attributes, options = {}) {
    ({
      moduleTypeEndpoint: this.moduleTypeEndpoint,
      language: this.language
    } = options);
    this._initModuleLinks();
    this._initTranslatableFields();
  }

  _getModuleLinkAttribute() {
    switch (this.get('moduleType')) {
      case TrainingModuleType.TINCAN:
      case TrainingModuleType.SCORM:
      case TrainingModuleType.EXTERNAL_LINK:
        return 'packageLinks';

      case TrainingModuleType.TRANSCODED_VIDEO:
        return 'mediaLinks';

      case TrainingModuleType.EXTERNAL_VIDEO:
        return 'urlLinks';
      case TrainingModuleType.PDF:
        return 'fileLinks';
      default:
        return '';
    }
  }

  _initTranslatableFields() {
    const translatableName = new TranslatableString(this.get('translatableString'));
    this.set('translatableString', translatableName);

    const translatableDesc = new TranslatableString(this.get('translatableDesc'));
    this.set('translatableDesc', translatableDesc);
  }

  _initModuleLinks() {
    if (this.moduleLinks == null) {
      this.moduleLinks = new ModuleLinksCollection(this.get(this._getModuleLinkAttribute()));
    }
  }

  getPackageLinks() {
    return $.Deferred().resolve(this.moduleLinks.toJSON());
  }

  getPrimaryTranslatableFieldForLanguage(language) {
    return this.getTranslatableText().getValueForLanguage(language);
  }

  getTranslatableText() {
    return this.get(this.translatableStringKey);
  }
}

module.exports = PreviewTrainingModule;
