const { LayoutView } = require('Marionette');

class UserTopicScore extends LayoutView {

  getTemplate() {
    return `<% if(score != null) {%>
<div class="user-stats-region__user-score">
  <span><%= score %><%= t('general.percent') %></span>
  <p><%- t('selfDirected.topicDetails.knowledgeScore') %></p>
</div>
<% } else { %>
<div class="user-stats-region__no-user-score">
  <p><%- t('selfDirected.topicDetails.takeTopic') %></p>
</div>
<% } %>`;
  }
}

module.exports = UserTopicScore;
