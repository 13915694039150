const I18n = require('@common/libs/I18n');
const Marionette = require('Marionette');
const {
  triggerResize
} = require('@common/libs/helpers/app/BrowserHelpers');
const { getSelectedMultiLangImage } = require('@common/libs/helpers/app/ImageHelpers');
const VariantType = require('@common/data/enums/VariantType');

class QuestionPreviewBasePage extends Marionette.LayoutView {
  events() {
    return {
      'click .image-wrapper': 'previewImage'
    };
  }

  regions() {
    return {
      zoomPlaceHolder: '.zoom-placeholder'
    };
  }

  onShow() {
    const variantType = this.variant == null ? this.model.get('variantType') : this.variant.get('variantType');
    const image = getSelectedMultiLangImage(this.getImageFile(), this.translatedLanguage, variantType === VariantType.Hotspot);
    const hasValidImage = this.hasImage() && (image != null);

    if (hasValidImage) {
      const options = {
        imgSrc: {
          image
        },
        elementToAttachTo: this.getBaseImageElementToAttachTo(),
        container: '.context-leftpane'
      };

      this._createPopoverController(options);
    }
  }

  _createPopoverController(options) {
    this.$elementToAttachTo = options.elementToAttachTo;
    const zoomView = this.initPopoverZoomView(options);
    if (zoomView.preventPopverLoading != null) {
      return;
    }

    const zoomOptions = {
      className: 'popover-image-zoom',
      placement: I18n.isCurrentLanguageRtl() ? 'right' : 'left',
      container: '.context-leftpane',
      $elementToAttachTo: this.$elementToAttachTo,
      view: zoomView,
      viewportSelector: '.context-leftpane',
      scrollContainerSelector: '.scrollable',
      trigger: 'manual'
    };

    this.zoomPlaceHolder.show(zoomView);

    this.popoverView = zoomView;
    this.popoverController = this.initPopoverController(zoomOptions);
  }

  previewImage() {
    if (this.popoverView == null) {
      return undefined;
    }
    this.popoverView.triggerMethod('popover:show');
    this.$elementToAttachTo.popover('toggle');
    return false;
  }

  triggerAdjustment() {
    triggerResize(true);
  }

  hasImage() {
    return $.noop;
  }

  getImageFile() {
    return $.noop;
  }

  getBaseImageElementToAttachTo() {
    return this.$el;
  }

  initPopoverController() {
    return $.noop;
  }

  initPopoverZoomView() {
    return $.noop;
  }
}

module.exports = QuestionPreviewBasePage;
