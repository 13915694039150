const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const CheckboxState = require('@common/components/forms/editors/checkboxes/CheckboxState');

class TeamAccessItemView extends ItemView {
  initialize(options = {}) {
    ({
      accessLevelsList: this.accessLevelsList,
      values: this.values
    } = options);
  }

  modelEvents() {
    return {
      'change:checkboxState': this._onChangeCheckboxState
    };
  }

  tagName() {
    return 'tr';
  }

  getTemplate() {
    return `
      <td class="table-layout__col1">
        <p class="ax-font--no-margin"><%= name %></p>
        <p class="ax-font--no-margin ax-font--secondary"><%= subtext %></p>
      </td>
      <td colspan="4" class="table-layout__col2">
        <form class="js-form">
          <ul
            class="ax-grid"
            data-shallow="true"
            data-field="accessLevel"
            data-editor="CascadingSelectCheckboxes"
            data-options="accessLevelsList">
          </ul>
        </form>
      </td>
    `;
  }

  templateHelpers() {
    return {
      name: I18n.t(this.model.get('name')),
      subtext: I18n.t(this.model.get('subtext'))
    };
  }

  ui() {
    return {
      form: '.js-form'
    };
  }

  onRender() {
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        accessLevelsList: this.accessLevelsList
      }
    });
  }

  setValue(value) {
    const editor = this.form.fields.accessLevel.editor;
    editor.setValue(value);
  }

  setState(values, state) {
    if (values != null) {
      const editor = this.form.fields.accessLevel.editor;
      if (values.length > 0) {
        editor.setState(state, values);
      } else {
        this.clearState();
      }
    }
  }

  clearState() {
    this.form.fields.accessLevel.editor.clearState();
  }

  _onChangeCheckboxState(userTypeModel, checkboxState) {
    const accessLevel = this.model.get('accessLevel');

    if (checkboxState.inactive != null || checkboxState.disabled != null) {
      this.clearState();
      this.setState(checkboxState.inactive, CheckboxState.OVERRIDDEN);
      this.setState(checkboxState.disabled, CheckboxState.DISABLED_SELECTED);
    } else {
      // If inactive and disabled states are undefined, fallback to the group's accessLevel value
      this.setValue(accessLevel);
    }
  }
}

module.exports = TeamAccessItemView;
