const GuidedLearningObjective = require('./GuidedLearningObjective');
const GuidedLearningObjectiveType = require('./GuidedLearningObjectiveType');
const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');

const NAME_FIELD_NAME = 'name';
const TYPE_FIELD_NAME = 'type';
const DESCRIPTION_FIELD_NAME = 'description';
const STATUS_FIELD_NAME = 'evaluationStatus';
const DATE_FIELD_NAME = 'lastAttemptDate';
const EVALUATION_ID = 'evaluationId';
const IMPORTED_FIELD_NAME = 'imported';

class EvaluationLearningObjective extends GuidedLearningObjective {
  defaults() {
    return {
      evaluationId: 0,
      evaluationFormId: 0,
      objectiveDetails: {
        evaluationStatus: EvaluationObjectiveStatus.NOT_STARTED
      },
      [TYPE_FIELD_NAME]: GuidedLearningObjectiveType.EVALUATION_OBJECTIVE
    };
  }

  getName() {
    return this.get(NAME_FIELD_NAME);
  }

  getDescription() {
    return this.get(DESCRIPTION_FIELD_NAME);
  }

  canBegin() {
    return false;
  }

  getEvaluationStatus() {
    return this.getObjectiveDetails()[STATUS_FIELD_NAME];
  }

  getLastAttemptDate() {
    return this.getObjectiveDetails()[DATE_FIELD_NAME];
  }

  getEvaluationId() {
    return this.getObjectiveDetails()[EVALUATION_ID];
  }

  isImported() {
    return this.getObjectiveDetails()[IMPORTED_FIELD_NAME];
  }

  isComplete() {
    return this.getEvaluationStatus() === EvaluationObjectiveStatus.PASSED;
  }

  isStartable() {
    return this.getEvaluationStatus() === EvaluationObjectiveStatus.NOT_STARTED;
  }

  hasStarted() {
    return this.getEvaluationStatus() !== EvaluationObjectiveStatus.NOT_STARTED;
  }

  isFailed() {
    return this.getEvaluationStatus() === EvaluationObjectiveStatus.FAILED;
  }

  isPending() {
    const status = this.getEvaluationStatus();
    return (status === EvaluationObjectiveStatus.PENDING) || (status === EvaluationObjectiveStatus.PENDING_AFTER_RETRY);
  }

  getType() {
    return GuidedLearningObjectiveType.EVALUATION_OBJECTIVE;
  }
}

module.exports = EvaluationLearningObjective;
