const { Wreqr } = require('Backbone');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

module.exports = () => {
  // if this is in mobile sdk and tenant flag wants to hide it,
  // don't call it.
  const isInMobileThirdPartyApp = Wreqr.radio.channel('nativeBridge').reqres.request('isInThirdPartyApp');
  const mobileSdkOptions = TenantPropertyProvider.get().getProperty('mobileSdkOptions') || {};

  return !(isInMobileThirdPartyApp && !mobileSdkOptions.displayHeaderLogo);
};
