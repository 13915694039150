const $os = require('detectOS');
const Backbone = require('Backbone');
const {
  CollectionView,
  LayoutView,
  ItemView
} = require('Marionette');
const SimpleArticleSearchResultsViewController = require('@training/apps/search/SimpleArticleSearchResultsViewController');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const TagBar = require('@common/components/discover/views/TagBar');
const CreateMenuDefinitionHelper = require('@training/apps/search/CreateMenuDefinitionHelper');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const {
  NoSearchResultsView
} = require('@training/apps/search/SearchResultEmptyViews');

const PageableListViewController = require('@common/components/pageableList/PageableListViewController');
const { articleSearchResultControllerDefinition } = require('@training/apps/search/ArticleSearchResultControllerDefinitionFactory');
const TagGroupView = require('@training/apps/search/tagGroupFilter/TagGroupView');
const ImpressionTrackerEnum = require('@training/apps/common/enums/ImpressionTrackerEnum');

class ArticleSearchResultsViewController extends SimpleArticleSearchResultsViewController {

  initialize(...args) {
    super.initialize(...args);
    this._getArticleResults = this._getArticleResults.bind(this);
    this.gridElementClasses = [
      'ax-grid__col--12',
      'ax-grid__col--m-6',
      'ax-grid__col--l-4'
    ];
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'article-list-view ax-grid ax-grid--align-items-start',
      template: `
        <div class="ax-grid ax-grid__col--order-1">
          <div class="article-search__view-type-selector${ this.isGrid ? ' grid-view' : '' } ax-grid__col--12"></div>
          <div class="article-search__results-region${ this.isGrid ? ' grid-view' : '' }"></div>
        </div>

        <div class="tags-button-wrapper ax-grid__col--12 ax-grid__col--m-3 ax-grid__col--l-3 ax-grid__col--m-order-2 ax-grid--no-gutter">
          <div class="ax-grid ax-grid--justify-content-end ax-grid--no-gutter">

            <div class="js-create-button-region create-button-region ax-grid__col--auto-size"></div>

            <% if (shouldShowCommunityManagementButton) { %>
              <div class="community-context-button-container ax-grid__col--auto-size">
                  <button type="button" class="js-community-management-button community-context-button ax-button--icon" aria-label="<%- t('discover.browse.communitySettings') %>">
                  <span class="icon-settings"></span>
                </button>
              </div>
            <% } %>

          </div>
          <span class="js-search-by-tag-title search-by-tag-title non-community-search-by-tag-title tags-region hidden-mobile hidden"><%- t('discover.tagBarPrompt') %></span>
          <div class="js-grouped-tags-region grouped-tags-region tags-region"></div>
          <div class="js-not-grouped-tags-region tags-region"></div>
        </div>
        `,
      templateHelpers: {
        shouldShowCommunityManagementButton: this._shouldShowCommunityManagementButton()
      },
      regions: {
        breadcrumbRegion: '.header-breadcrumbs',
        titleRegion: '.title-region',
        createButtonRegion: '.js-create-button-region',
        buttonsRegion: '.article-search__view-type-selector',
        resultsRegion: '.article-search__results-region',
        notGroupedTagsRegion: '.js-not-grouped-tags-region',
        groupedTagsRegion: '.js-grouped-tags-region'
      }
    };
  }

  delegateEvents() {
    const articleFacetList = this._getArticleResults().getFacetList();
    return {
      'view:attach': (controller, view) => {
        view.listenTo(articleFacetList, 'reset', (facetList) => {
          if (!view.isDestroyed && facetList.length) {
            view.$el.find('.js-search-by-tag-title').removeClass('hidden');
          }
        });
      }
    };
  }

  _getArticleResults() {
    return this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES];
  }

  regionControllers() {
    const searchPageState = this.searchPageState;
    const articleResults = searchPageState.get('results')[SearchCategoryEnum.ARTICLES];
    const searchString = searchPageState.get('searchString');

    const showAuthor = TenantPropertyProvider.get().getProperty('dzShowAuthorAndContributorInfo');
    const showReactions = searchPageState.get('subCategory') !== SearchSubCategoryEnum.PENDING;

    this.listenTo(articleResults.getFacetList(), 'reset', (facetList) => {
      if (facetList.length) {
        $('.js-search-by-tag-title').removeClass('hidden');
      }
    });
    const notGroupedTagFacetList = new Backbone.VirtualCollection(articleResults.getFacetList(), {
      filter: (facet) => {
        return !facet.get('tagGroupName');
      }
    });

    const groupedTagFacetLists = articleResults.getGroupedFacetLists();

    const search = () => {
      const selectedTags = articleResults.getFacetList().getSelectedFacets();
      searchPageState.set('articleUrlTags', selectedTags);
      articleResults.search(
        searchString,
        selectedTags,
        0
      );
    };

    const filterState = new Backbone.Model({
      lastActiveTagGroup: null
    });

    // SimpleArticleSearchResultsView but with the addition of a tags component and create content button
    const regionControllers = Object.assign({}, {
      groupedTagsRegion: {
        viewDefinition: {
          ViewClass: CollectionView,
          collection: groupedTagFacetLists,
          childView: TagGroupView,
          tagName: 'ul',
          childViewOptions: (model) => {
            const selectedTags = model.getSelectedTagIds();
            const formModel = new Backbone.Model({
              name: model.get('name'),
              selectedTags
            });

            return {
              model: formModel,
              filterState,
              collection: model.get('tags'),
              tagName: 'li',
              onApply: search
            };
          }
        }
      },
      notGroupedTagsRegion: {
        viewDefinition: {
          ViewClass: TagBar,
          collection: notGroupedTagFacetList,
          className: 'tag-bar article-search-tag-bar',
          id: 'tag-bar'
        },
        delegateEvents: {
          'view:show': function (controller, view) {
            view.listenTo(view.collection, 'reset', (newList) => {
              view.$el.toggle(newList.length > 0);
            });
          },
          'view:facet:clicked': (controller, view, facetModel) => {
            if (facetModel.get('isSelected')) {
              facetModel.set({
                isSelected: false
              });
            } else {
              facetModel.set({
                isSelected: true
              });
            }

            filterState.set('lastActiveTagGroup', null);
            search();
          }
        }
      },
      buttonsRegion: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'ax-grid ax-grid--justify-content-end ax-grid--no-gutter',
          template: `
<button type="button" class="axon-button white grid-view-button ax-grid__col--auto-size">
  <span role="presentation" aria-hidden="true" class="icon-th_large"></span><span class="grid-view-text"><%- t('selfDirected.search.viewTypes.gridView') %></span>
</button>
<button type="button" class="axon-button white list-view-button ax-grid__col--auto-size">
  <span role="presentation" aria-hidden="true" class="icon-list"></span><span class="list-view-text"><%- t('selfDirected.search.viewTypes.listView') %></span>
</button>`,
          events: {
            'click .grid-view-button': () => {
              this._toggleGridview(true);
            },
            'click .list-view-button': () => {
              this._toggleGridview(false);
            }
          }
        },
        delegateEvents: {
          'view:attach': (controller, view) => {
            this._toggleGridview(false);
            view.listenTo(this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES], 'sync', (collection) => {
              const buttonsRegionEl = this.getView().buttonsRegion.$el;
              if (collection.length) {
                $('button', buttonsRegionEl).toggleClass('hidden', false);
              } else {
                $('button', buttonsRegionEl).toggleClass('hidden', true);
              }
            });
          }
        }
      },
      resultsRegion: {
        ViewControllerClass: PageableListViewController,
        collection: this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES],
        childViewControllerDefinitionFn: articleSearchResultControllerDefinition,
        itemViewOptions: {
          searchPageState: this.searchPageState,
          showAuthor,
          showReactions,
          source: ImpressionTrackerEnum.ARTICLE_SEARCH_RESULTS,
          titleTag: 'h2'
        },
        viewDefinition: {
          behaviors: {
            MutationObservable: {
              observeOptions: {
                childList: true,
                subtree: true
              }
            }
          }
        },
        emptyViewClass: NoSearchResultsView,
        emptyViewOptions: {
          searchString: this.searchPageState.get('searchString')
        },
        delegateEvents: this._getViewEvents(
          this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES],
          this.searchPageState
        )
      }
    });

    regionControllers.createButtonRegion = CreateMenuDefinitionHelper.getCreateMenuRegionController(
      null, // no community is selected in this context
      searchPageState.get('userMetadata')
    );

    return regionControllers;
  }

  _onContextMenuButtonClick() {
    const community = this.searchPageState.get('community');
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/community-${ community.id }/communityManagement`, true);
  }

  _shouldShowCommunityManagementButton() {
    const community = this.searchPageState.get('community');
    return community && window.apps.auth.session.user.hasCommunityManagementAccess() && $os.desktop;
  }

  _toggleGridview(isGrid) {
    this.isGrid = isGrid;
    const view = this.getView();
    view.buttonsRegion.$el.toggleClass('grid-view', this.isGrid);
    view.resultsRegion.$el.toggleClass('grid-view', this.isGrid);
    view.resultsRegion.$el.find('ul.search__results').toggleClass('ax-grid', this.isGrid);
    view.resultsRegion.$el.find('li').toggleClass(this.gridElementClasses.join(' '), this.isGrid);
    view.buttonsRegion.$el.find('.list-view-button').attr('aria-pressed', !isGrid);
    view.buttonsRegion.$el.find('.grid-view-button').attr('aria-pressed', isGrid);
  }
}

module.exports = ArticleSearchResultsViewController;
