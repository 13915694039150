// Module
var code = `<% if(renderRow) { %>
  <div class="mq-preview-wrapper <%- getAnswerResult() %>">
    <% if(getAnswerResult() === 'correct') {%>
      <p class="off-screen"><%- t('question.accessibility.correct') %></p>
    <% } else if(getAnswerResult() === 'incorrect') { %>
      <p class="off-screen"><%- t('question.accessibility.incorrect') %></p>
    <% } %>
    <div class="mq-preview-option">
      <% if(leftImage = getLeftImage()) {%>
        <div class="imgwrap image-wrapper" data-media-id="<%- leftImage.id %>"></div>
      <%}%>
      <p><%= getLeftText() %></p>
    </div>
    <div class="mq-preview-result-indicator middle-<%- getAnswerResult() %>" aria-hidden="true" role="presentation"></div>
    <div class="mq-preview-option">
      <% if(rightImage = getRightImage()) {%>
        <div class="imgwrap image-wrapper" data-media-id="<%- rightImage.id %>"></div>
      <%}%>
      <p><%= getRightText() %></p>
    </div>
  </div>
<% } %>
<div class="zoom-placeholder hidden">
`;
// Exports
module.exports = code;