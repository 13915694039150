const IconBadgeView = require('@common/components/iconBadge/IconBadgeView');

const TitleHeaderController = require('@common/components/titleHeader/TitleHeaderController');

const BasicHeaderTitle = require('@common/components/titleHeader/BasicHeaderTitle');
const PretextHeaderTitle = require('@common/components/titleHeader/PretextHeaderTitle');
const SubtextHeaderTitle = require('@common/components/titleHeader/SubtextHeaderTitle');

const TitleHeaderTypeEnum = require('@common/components/titleHeader/TitleHeaderTypeEnum');

const getTitleControllerDefinitionViewClass = (builder) => {
  if (builder.titleType === TitleHeaderTypeEnum.PRETEXT_TITLE) {
    return PretextHeaderTitle;
  } else if (builder.titleType === TitleHeaderTypeEnum.SUBTEXT_TITLE) {
    return SubtextHeaderTitle;
  }
  return BasicHeaderTitle;

};

const buildTitleControllerDefinition = (builder) => {
  if (builder.title !== '') {
    return {
      viewDefinition: {
        ViewClass: getTitleControllerDefinitionViewClass(builder),
        title: builder.title,
        titleClass: builder.titleClass,
        titleTag: builder.titleTag,
        pretext: builder.pretext,
        pretextClass: builder.pretextClass,
        subtext: builder.subtext,
        subtextClass: builder.subtextClass
      }
    };
  }

  return null;
};

const buildBadgeControllerDefinition = (builder) => {
  if (builder.iconClass !== '' || builder.badgeModifierClasses.length > 0) {
    return {
      viewDefinition: {
        ViewClass: IconBadgeView,
        badgeModifierClasses: builder.badgeModifierClasses,
        iconClass: builder.iconClass,
        iconLabel: builder.iconLabel
      }
    };
  }

  return null;
};

class TitleHeaderDefinitionBuilder {

  constructor(options = {}) {
    ({
      iconClass: this.iconClass = '',
      iconLabel: this.iconLabel = '',
      badgeModifierClasses: this.badgeModifierClasses = [],
      titleType: this.titleType = TitleHeaderTypeEnum.BASIC_TITLE,
      title: this.title = '',
      titleClass: this.titleClass = 'ax-font--bold',
      titleTag: this.titleTag = 'p',
      pretext: this.pretext = '',
      pretextClass: this.pretextClass = 'text-muted ax-font__display',
      subTitle: this.subtext = '',
      subTitleClass: this.subtextClass = 'text-muted',
      isSemanticHeader: this.isSemanticHeader = false,
      titleControllerDefinition: this.titleControllerDefinition = null,
      badgeControllerDefinition: this.badgeControllerDefinition = null
    } = options);
  }

  setIconClass(iconClass = '') {
    this.iconClass = iconClass;
    return this;
  }

  setIconLabel(iconLabel = '') {
    this.iconLabel = iconLabel;
    return this;
  }

  setBadgeModifierClasses(badgeModifierClasses = []) {
    this.badgeModifierClasses = badgeModifierClasses;
    return this;
  }

  setTitle(title = '') {
    this.title = title;
    return this;
  }

  setPretext(pretext = '') {
    this.pretext = pretext;
    return this;
  }

  setSubTitle(subTitle = '') {
    this.subtext = subTitle;
    return this;
  }

  setTitleClass(titleClass = '') {
    this.titleClass = titleClass;
    return this;
  }

  setPretextClass(pretextClass = '') {
    this.pretextClass = pretextClass;
    return this;
  }

  setSubTitleClass(subTitleClass = '') {
    this.subtextClass = subTitleClass;
    return this;
  }

  setAsSemanticHeader(isSemanticHeader = false) {
    this.isSemanticHeader = isSemanticHeader;
    return this;
  }

  setCustomTitleDefinition(titleControllerDefinition) {
    this.titleControllerDefinition = titleControllerDefinition;
    return this;
  }

  setCustomBadgeDefinition(badgeControllerDefinition) {
    this.badgeControllerDefinition = badgeControllerDefinition;
    return this;
  }

  build() {
    return {
      ViewControllerClass: TitleHeaderController,
      badgeControllerDefinition: this.badgeControllerDefinition || buildBadgeControllerDefinition(this),
      titleControllerDefinition: this.titleControllerDefinition || buildTitleControllerDefinition(this)
    };
  }
}

module.exports = TitleHeaderDefinitionBuilder;
