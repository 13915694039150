const ReactionsDetailsModel = require('@training/apps/search/modals/reactionsModal/ReactionsDetailsModel');

class PageInsightsReactionsDetailsModel extends ReactionsDetailsModel {
  apiEndpoint() {
    return '/userReaction/userAccessibleLocations';
  }

  fetch(options = {}) {
    const locationId = this.get('locationId') || null;
    const includeSubTeams = this.get('includeSubTeams');
    return super.fetch($.extend(true, {}, { data: { locationId, includeSubTeams } }, options));
  }
}

module.exports = PageInsightsReactionsDetailsModel;
