const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const ViewController = require('@common/libs/UI/controllers/ViewController');

class ZoomOverlayContentRegionController extends ViewController {
  constructor(options = {}) {
    super(options);
    ({
      media: this.media,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight
    } = options);
    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return ImageViewerFactory.createViewerInstance({
      media: this.media,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight
    });
  }

  onViewImageLoaded() {
    triggerResize(true);
  }
}

module.exports = ZoomOverlayContentRegionController;
