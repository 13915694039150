const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');
const User = require('@common/components/discover/models/User');

class UserList extends InfiniteCollection {
  get model() {
    return User;
  }

  apiEndpoint() {
    return '/report/userList';
  }

  parseRecords(data = {}) {
    return data.results;
  }

  fetch(options = {}) {
    return super.fetch($.extend(true, options, {
      data: {
        search: encodeURIComponent(this.searchQuery)
      }
    }));
  }

  search(searchQuery = '') {
    this.setSearchQuery(searchQuery);
    return this.getFirstPage();
  }

  setSearchQuery(searchQuery) {
    this.searchQuery = searchQuery;
  }

  clear() {
    super.clear();
    return this.search('');
  }
}
module.exports = UserList;
