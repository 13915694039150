const env = require('env');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');
const AuthenticationScheme = require('@common/data/enums/AuthenticationScheme');
const AuthTypesEnum = require('@common/data/enums/AuthTypesEnum');

class LoginViewModel {
  constructor(options = {}) {
    ({
      samlSettings: this.samlSettings,
      authenticationSchemes: this.authenticationSchemes
    } = options);

    this.samlOverrides = null;
  }

  getSamlOverrides() {
    return this.samlOverrides;
  }

  setSamlOverrides(samlOverrides) {
    this.samlOverrides = samlOverrides;
  }

  aggregateSamlSettings() {
    const samlSettings = this.samlSettings;
    let settings = [];

    if (!_.isEmpty(samlSettings)) {
      settings = this._getSamlSettings(samlSettings);
    }

    return settings;
  }

  getSchemes() {
    const authSchemes = this.authenticationSchemes;
    const schemes = [];

    if (!_.isEmpty(authSchemes)) {
      _.each(authSchemes, (scheme) => {
        schemes.push(AuthenticationScheme[scheme]);
      });
    }

    return schemes;
  }

  isAuthSchemesOAuthEnabled() {
    return this.authenticationSchemes.includes(AuthTypesEnum.OAuth.serverId);
  }

  _getSamlSettings(samlSettings) {
    const mappedSamlSettings = [];

    for (const samlSetting of samlSettings) {
      // SE-24411: condition for MDM devices
      if (
        samlSetting.deviceManagementOverridable
        && Array.isArray(this.samlOverrides)
        && this.samlOverrides.length > 0
      ) {
        if (this.samlOverrides.includes(samlSetting.spEntityId)) {
          mappedSamlSettings.push(this._mapSamlSetting(samlSetting));
        }
      } else if (samlSetting.visible) {
        // SE-24411: condition for BYOD devices
        mappedSamlSettings.push(this._mapSamlSetting(samlSetting));
      }
    }

    return mappedSamlSettings;
  }

  _mapSamlSetting(samlSetting) {
    const localizableString = new LocalizableString(samlSetting.signInButtonLabel);

    return {
      label: localizableString.getValueForLanguage(I18n.getLocale()),
      url: `${ samlSetting.loginUrl }?app=${ env.settings.app }`
    };
  }
}

module.exports = LoginViewModel;
