import {
  default as _,
  partial
} from 'lodash';
import { useEffect } from 'react';
import {
  useMemoOne,
  useCallbackOne
} from 'use-memo-one';

import {
  type AxThemedDialogProps,
  useAxDialogContext,
  showModal,
  pushModal,
  closeModal
} from '@common/modules/react/themes/components';

type OnClose = NonNullable<AxThemedDialogProps['onClose']>;

export interface AxDialogHostPresenterOptions extends AxThemedDialogProps {
  push?: boolean
  enableBackdropClose?: boolean
}

export default function AxDialogHostPresenter(props: AxDialogHostPresenterOptions): JSX.Element {
  const {
    push = false,
    enableBackdropClose = true,
    ...dialogProps
  } = props;

  const setStore = useAxDialogContext();

  // If an onClose prop was passed in, wrap it with a common handler that prevents close on backdrop click when necessary
  const onClose = useCallbackOne<OnClose>((e, reason) => {
    if (reason === 'backdropClick' && !enableBackdropClose) {
      return;
    }

    dialogProps.onClose?.(e, reason);
  }, [enableBackdropClose, dialogProps.onClose]);

  const dialogPropDeps = Object.values(dialogProps);

  // Stable memoization hook, React.memo isn't guaranteed.
  const memoizedDialogProps = useMemoOne<AxThemedDialogProps>(() => {
    return {
      ...dialogProps,
      onClose
    };
  }, [onClose, ...dialogPropDeps]);

  useEffect(() => {
    const method = push ? pushModal : showModal;
    setStore(partial(method, _, memoizedDialogProps));

    return () => {
      setStore(partial(closeModal, _, memoizedDialogProps));
    };
  }, [push, memoizedDialogProps, setStore]);

  return <></>;
}
