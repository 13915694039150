const { LayoutView } = require('Marionette');

const StatefulRegion = require('@common/libs/UI/StatefulRegion');

require('@common/libs/behaviors/resizable/Resizable');

const LayeredLayoutState = require('@common/components/layeredLayout/LayeredLayoutState');

require('@common/components/layeredLayout/LayeredLayout.less');

class LayeredLayout extends LayoutView {
  className() {
    return 'layer-overlay-container parent-height';
  }

  getTemplate() {
    return `
      <div class="layer-content-region parent-height"></div>
      <div class="layer-overlay-region"></div>
    `;
  }

  regions() {
    return {
      contentRegion: {
        selector: '> .layer-content-region',
        regionClass: StatefulRegion
      },
      overlayRegion: {
        selector: '> .layer-overlay-region',
        regionClass: StatefulRegion
      }
    };
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  ui() {
    return {
      content: '> .layer-content-region',
      overlay: '> .layer-overlay-region'
    };
  }

  childEvents() {
    return {
      'dom:refresh': 'onChildDomRefresh'
    };
  }

  constructor(options = {}) {
    super(options);

    this.recalculateDimensions = this.recalculateDimensions.bind(this);

    this._viewState = new LayeredLayoutState();
  }

  onShow() {
    this._toggleOverlayRegionVisibilityClass();

    this.listenTo(this.overlayRegion, 'before:show', () => {
      this.toggleOverlay(true);
    });

    this.listenTo(this.overlayRegion, 'before:empty', () => {
      this.toggleOverlay(false);
    });
  }

  onChildDomRefresh(childView) {
    if (childView === this.contentRegion.currentView) {
      this.recalculateDimensions();
    }
  }

  onBeforeAttach() {
    this.listenTo(this._viewState, 'change', this.onViewStateChange);
  }

  onResize() {
    this.recalculateDimensions();
  }

  onViewStateChange(model) {
    if (model.hasDimensionChanged()) {
      this.ui.overlay.css(model.getDimensionCss());
    }

    if (model.hasOverlayVisibilityChanged()) {
      this._toggleOverlayRegionVisibilityClass();
    }
  }

  _toggleOverlayRegionVisibilityClass() {
    const isOverlayVisible = this._viewState.isOverlayVisible();
    this.ui.content.toggleClass('no-pointer-events', isOverlayVisible);
    this.ui.overlay.toggleClass('hidden', !isOverlayVisible);

    if (isOverlayVisible) {
      this.ui.content.attr('aria-modal', true);
    } else {
      this.ui.content.removeAttr('aria-modal');
    }
  }

  toggleOverlay(toggle = false) {
    this._viewState.toggleOverlayVisibility(toggle);
  }

  recalculateDimensions() {
    // Don't adjust the dimension data if the view isn't visible since it won't
    // give you real values. contentRegion might be null if view is being closed while window is being closed.
    if (!this.contentRegion || !(this.contentRegion.currentView != null ? this.contentRegion.currentView.isVisible() : undefined)) {
      return;
    }

    const offsetParentScrollTop = this.contentRegion.$el.offsetParent().scrollTop();
    const offset = this.contentRegion.$el.position();

    const boundingRect = this.getBoundingClientRect(this.contentRegion.$el);

    this._viewState.setDimensionCss({
      width: boundingRect.width,
      height: boundingRect.height,
      top: offset.top + offsetParentScrollTop,
      left: offset.left
    });
  }
}

module.exports = LayeredLayout;
