const Backbone = require('Backbone');
const _ = require('underscore');

const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');

const URL_USER = '/axonify/branding/user';
const URL_NOT_LOGGED_IN = '/axonify/branding';

class BrandingStyleModel extends Backbone.Model {
  static FIELDS = {
    HEADER_IMAGE_MEDIA: 'headerImageMedia',
    LOGIN_BG_MEDIA: 'loginImageMedia',
    DARK_THEME: 'DARK_THEME',
    HEADER_BORDER_COLOUR: 'HEADER_BORDER_COLOUR',
    HEADER_BORDER_WIDTH: 'HEADER_BORDER_WIDTH',
    LINK_COLOUR: 'LINK_COLOUR',
    MAIN_HEADER_COLOUR: 'MAIN_HEADER_COLOUR',
    PRIMARY_BUTTON_DEFAULT_COLOUR: 'PRIMARY_BUTTON_DEFAULT_COLOUR',
    PRIMARY_BUTTON_DEFAULT_HOVER_COLOUR: 'PRIMARY_BUTTON_DEFAULT_HOVER_COLOUR',
    PRIMARY_BUTTON_TEXT_COLOUR: 'PRIMARY_BUTTON_TEXT_COLOUR',
    PRIMARY_BUTTON_HOVER_TEXT_COLOUR: 'PRIMARY_BUTTON_HOVER_TEXT_COLOUR',
    TAB_BORDER_COLOUR: 'TAB_BORDER_COLOUR',
    BANNER_IMAGE_MEDIA: 'bannerImageMedia',
    BANNER_OVERLAY_COLOUR: 'BANNER_OVERLAY_COLOUR',
    BANNER_TEXT_COLOUR: 'BANNER_TEXT_COLOUR',
    BADGE_BACKGROUND_COLOUR: 'BADGE_BACKGROUND_COLOUR',
    PAGE_HEADER_BACKGROUND_COLOUR: 'PAGE_HEADER_BACKGROUND_COLOUR'
  };

  fetch(options = {}) {
    this.urlRoot = options.isLoggedIn ? URL_USER : URL_NOT_LOGGED_IN;
    this.clear({silent: true});
    super.fetch(options);
  }

  parse(res) {
    const json = res.entity != null ? res.entity : res;
    const result = _.omit(json, 'properties');

    return Object.assign(result, json.properties);
  }

  getHeaderImage(maxHeight) {
    const headerImageMedia = this.get(BrandingStyleModel.FIELDS.HEADER_IMAGE_MEDIA);
    if (!headerImageMedia) {
      return undefined;
    }

    return ImageHelpers.getBiggestImageBySize(
      headerImageMedia.sizes,
      {
        maxHeight
      }
    );
  }

  getLoginBgPath(maxHeight, maxWidth) {
    const loginBgMedia = this.get(BrandingStyleModel.FIELDS.LOGIN_BG_MEDIA);

    return this._getImagePath(loginBgMedia, maxHeight, maxWidth);
  }

  getBannerImagePath(maxHeight, maxWidth) {
    const bannerBgMedia = this.get(BrandingStyleModel.FIELDS.BANNER_IMAGE_MEDIA);

    return this._getImagePath(bannerBgMedia, maxHeight, maxWidth);
  }

  getLoginBgAvgColor() {
    const loginBgMedia = this.get(BrandingStyleModel.FIELDS.LOGIN_BG_MEDIA);

    if (!loginBgMedia) {
      return null;
    }

    return ImageHelpers.getAverageColour(loginBgMedia);
  }

  isDarkThemeEnabled() {
    return this.get(BrandingStyleModel.FIELDS.DARK_THEME) === 'ON';
  }

  _getImagePath(loginBgMedia, maxHeight, maxWidth) {
    if (!loginBgMedia || !loginBgMedia.sizes) {
      return null;
    }

    return ImageHelpers.getBiggestImageFileBySize(
      loginBgMedia.sizes,
      {
        maxHeight,
        maxWidth
      }
    ).path;
  }
}

module.exports = BrandingStyleModel;
