// Module
var code = `<li class="answerblock">
  <p><%= answerNode[0].questionVariantText %></p>
  <div class="answers-wrapper clearfix">
    <% _.each(answerNode, function(answer) { %>
      <button
        class="questionblock <% if(answer.answeredCorrect) { %>correctanswer<% } else { %>incorrectanswer<% } %>"
        data-answer-id="<%-answer.answerId %>"
        title="<%- t('knowledge.buttonAccessibilityLabel', {
          date: answer.answerDate,
          correctness: answer.answeredCorrect ? t('knowledge.correct'):t('knowledge.incorrect')
        }) %>"
      >
      </button>
    <% }); %>
  </div>
</li>
`;
// Exports
module.exports = code;