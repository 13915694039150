const Marionette = require('Marionette');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const ReactionsTabController = require('@training/apps/search/modals/reactionsModal/ReactionsTabController');
const ReactionModalTabConfigHelper = require('@training/apps/search/modals/reactionsModal/ReactionModalTabConfigHelper');
require('@common/libs/behaviors/scrollable/Scrollable');

class ReactionsModal extends Marionette.LayoutView {
  initialize(options = {}) {
    ({
      reactionsDetails: this.reactionsDetails
    } = options);
  }

  getTemplate() {
    return `
    <div class="modalHeader">
      <h1><%- t('discover.insights.reactionsModalTitle') %></h1>
      <button class="js-close close icon-remove" aria-label="<%- t('general.close') %>"></button>
    </div>
    <div class="full-width js-reactions"></div>`;
  }

  className() {
    return 'page-wrapper parent-height';
  }

  regions() {
    return {
      reactionsRegion: '.js-reactions'
    };
  }

  onAttach() {
    this._showReactions();
  }

  _showReactions() {
    const viewControllerFactory = new ViewControllerFactory();
    const options = ReactionModalTabConfigHelper.getTabConfigs(this.reactionsDetails);
    const reactionsTabController = viewControllerFactory.create(ReactionsTabController, options);

    reactionsTabController.show(this.reactionsRegion);
  }
}

module.exports = ReactionsModal;
