const { LayoutView } = require('Marionette');
const UIKit = require('@training/widgets/UIKit');
const KeyCode = require('@common/data/enums/KeyCode');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ReactionsModal = require('@training/apps/search/modals/reactionsModal/ReactionsModal');
const PageInsightsReactionsDetailsModel = require('@training/apps/insights/engagement/PageInsightsReactionsDetailsModel');

class PageInsightsReactionsTileView extends LayoutView {
  initialize(options = {}) {
    ({
      pageId: this.pageId,
      teamId: this.teamId,
      includeSubTeams: this.includeSubTeams,
      reactionsErrorHandler: this.reactionsErrorHandler
    } = options);

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  className() {
    return 'insights-reactions-tile';
  }

  regions() {
    return {
      reactionsCountRegion: '.js-reactions-count-region',
      reactionsRegion: '.js-reactions-region'
    };
  }

  getTemplate() {
    return `
    <div class="ax-container">
      <div class="ax-grid ax-grid__col--12">
        <h3 class="page-insights-engagement__title"><%- t('discover.insights.reactionsModalTitle') %></h3>
        <button class="page-insights-reactions__details-button ax-grid__col--2 ax-grid__col--m-1" aria-label="<%- t('discover.insights.reactionsButtonAriaLabel') %>">
          <span class="off-screen"><%- t('discover.insights.reactionsButtonLabel') %></span>
          <span class="icon-chevron_right"></span>
        </button>
      </div>
      <div class="js-reactions-count-region page-insights-reactions__wrapper--content highlights ax-grid ax-grid__col--12"></div>
      <div class="js-reactions-region"></div>
    </div>`;
  }

  events() {
    return {
      click: this._showReactionsModal,
      keydown: this.onKeyDown
    };
  }

  onKeyDown(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      e.preventDefault();
      this._showReactionsModal();
    }
  }

  _showReactionsModal() {
    const pageId = this.pageId;
    const reactionsDetails = new PageInsightsReactionsDetailsModel({
      limit: 100,
      pageId,
      locationId: this.teamId,
      includeSubTeams: this.includeSubTeams
    });

    reactionsDetails.fetch().then(() => {
      const modalView = new AccessibleModalView({
        id: 'modalview',
        className: 'modal reactions-details-modal'
      });

      const modalChildView = new ReactionsModal({ reactionsDetails });

      window.app.layout.presentModal(modalView, {
        closeClick: false,
        closeSelector: '.js-close'
      });
      modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

      this.listenToOnce(modalChildView, 'destroy', () => {
        window.app.layout.dismissModal();
      });
    })
      .fail(this.reactionsErrorHandler);
  }
}

module.exports = PageInsightsReactionsTileView;
