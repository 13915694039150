const TrainingModuleDeliveryMethodEnum = require('@common/data/enums/TrainingModuleDeliveryMethodEnum');
const {
  isInApp,
  canOpenChildWebViews,
  isMsTeamsIntegration
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

const SCORMActivityViewFactory = (SCORMDeliveryMethodViews = {}) => {
  return (options = {}) => {
    const { activity } = options;

    // default to embedded (in an iFrame) SCORM view for legacy reasons
    let SCORMDeliveryMethod = activity.getDeliveryMethod() || TrainingModuleDeliveryMethodEnum.EMBEDDED;

    // not all mobile app versions support opening child windows
    if (isInApp() && !canOpenChildWebViews() || isMsTeamsIntegration()) {
      SCORMDeliveryMethod = TrainingModuleDeliveryMethodEnum.EMBEDDED;
    }

    const ViewClass = SCORMDeliveryMethodViews[SCORMDeliveryMethod];

    return new ViewClass(options);
  };
};

module.exports = SCORMActivityViewFactory;
