import LayoutController from '@common/libs/UI/controllers/LayoutController';

import LoadingRowView from '@common/mixins/data_loading/LoadingRowView';
import LoadingSkeletonView from '@common/components/loading/skeleton/LoadingSkeletonView';

interface OptionProps {
  promise: Promise<unknown>
  contentControllerDefinition?: object
  errorControllerDefinition?: object
  loadingControllerDefinition?: object
}

export default class LoadingPromiseController extends LayoutController {

  private readonly promise: Promise<unknown>;

  private readonly contentControllerDefinition?: object;

  private readonly errorControllerDefinition?: object;

  private readonly skeletonControllerDefinition?: object;

  constructor(options: OptionProps) {
    super(options);

    ({
      promise: this.promise = Promise.resolve(),
      contentControllerDefinition: this.contentControllerDefinition,
      errorControllerDefinition: this.errorControllerDefinition,
      loadingControllerDefinition: this.skeletonControllerDefinition = {
        viewDefinition: {
          ViewClass: LoadingRowView
        }
      }
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: LoadingSkeletonView
    };
  }
  
  async onViewShow(): Promise<void> {
    if (!this.promise.isFulfilled()) {
      this.showLoadingView();
    }

    await this.promise;

    if (this.isDestroyed) {
      return;
    }

    if (this.promise.isResolved()) {
      this.showContent();
    } else {
      this.showErrorView();
    }
  }

  private showLoadingView() {
    this.swapRegionController(LoadingSkeletonView.CONTENT_REGION, this.skeletonControllerDefinition);
  }

  private showContent() {
    this.swapRegionController(LoadingSkeletonView.CONTENT_REGION, this.contentControllerDefinition);
  }

  private showErrorView() {
    this.swapRegionController(LoadingSkeletonView.CONTENT_REGION, this.errorControllerDefinition);
  }
}
