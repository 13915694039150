const logging = require('logging');
const _ = require('lodash');
const ObjectHelpers = require('@common/libs/helpers/types/ObjectHelpers');

const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');

const LoadTimeLogHelpers = {
  getAdditionalLogFields() {
    if (NativeBridgeHelpers.isInApp()) {
      return NativeBridgeHelpers.getMobileAppInfo()
        .then((mobileAppInfo) => {
          const mobileAppInfoPrefixed = _.mapKeys(ObjectHelpers.flatten(mobileAppInfo, '_'), (value, key) => {
            return `environment_mobile_${ key }`;
          });
          return mobileAppInfoPrefixed;
        })
        .catch((e) => {
          logging.info(`Not sending logs with mobile data because: ${ e.message }`);
          return {};
        });
    }
    return Promise.resolve({});
  }
};

module.exports = LoadTimeLogHelpers;
