const UIKit = require('@training/widgets/UIKit');

const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const AchievementsCongratulatoryModalContentController = require('@training/apps/home/achievementsModal/AchievementsCongratulatoryModalContentController');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const NativeAppReviewService = require('@training/apps/common/libs/services/NativeAppReviewService');

const contentViewGenerator = (achievementsCollection = {}, achievementsModal) => {
  return (model = {}) => {
    const nextBadgeCallback = contentViewBuilderGenerator(achievementsCollection, achievementsModal);

    const viewControllerFactory = new ViewControllerFactory();
    const viewControllerDefinition = {
      ViewControllerClass: AchievementsCongratulatoryModalContentController,
      model,
      viewDefinition: {
        model,
        achievementsModal
      },
      delegateEvents: {
        'view:show:next:badge': nextBadgeCallback
      }
    };
    const modalContentController = viewControllerFactory.create(viewControllerDefinition);

    modalContentController.inflateView();
    return modalContentController.getView();
  };
};

const contentViewBuilderGenerator = (achievementsCollection = {}, achievementsModal) => {
  const modalContentView = contentViewGenerator(achievementsCollection, achievementsModal);

  return function() {
    const data = achievementsCollection.shift();

    if (data) {
      window.app.layout.setViewInModal(modalContentView(data), UIKit.View.Transitions.FADE);
    } else {
      achievementsModal.dismiss();
    }
  };
};

const buildModal = function(achievementsCollection = {}) {
  if (achievementsCollection.length === 0) {
    return;
  }

  const achievementsModal = new AccessibleModalView({
    className: 'modal achievements-modal confirm-dialog-view',
    id: 'modalview'
  });
  window.app.layout.presentModal(achievementsModal, {
    onClose: () => {
      NativeAppReviewService.checkAndShowMobileAppReviewModal();
    }
  });

  contentViewBuilderGenerator(achievementsCollection, achievementsModal)();
};

module.exports = {
  buildModal
};
