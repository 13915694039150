import {
  Accordion as MuiAccordion,
  type AccordionProps as MuiAccordionProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type PropsToOmit = 'elevation' | 'variant';
type AxAccordionProps = Omit<MuiAccordionProps, PropsToOmit>;

const styledOptions = {
  name: 'AxAccordion'
};

const StyledAxAccordion = styled(MuiAccordion, styledOptions)<AxAccordionProps>(() => {
  return {};
});

/**
 * A wrapper for the MUI Accordion component
 *
 * ### Links
 * - [MUI Accordion](https://mui.com/material-ui/react-accordion/)
 * - [Figma Mockup](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=7994-7720&m=dev)
 */
export const AxAccordion = forwardRef(({
  ...otherProps
}: AxAccordionProps, ref: Ref<HTMLDivElement>) => {
  const AccordionProps: AxAccordionProps = {
    ...otherProps
  };

  return (
    <StyledAxAccordion
      { ...AccordionProps }
      ref={ ref }
      elevation={ 0 }
    />
  );
});

export default AxAccordion;
