const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');
const _ = require('underscore');

const I18n = require('@common/libs/I18n');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const SoldOutModal = require('@training/apps/training/views/SoldOutModal');
const AuctionItem = require('@common/data/models/AuctionItem');
const DELAY = 3000;
const KeyCode = require('@common/data/enums/KeyCode');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');
require('@common/libs/behaviors/card/Card');

class AuctionBidView extends UIKit.View {
  constructor(...args) {
    super(...args);
    this.selectedAuctionItem = this.selectedAuctionItem.bind(this);
    this.template = _.tpl(require('@training/apps/training/templates/AuctionBidPage.html'));
  }

  events() {
    return {
      'click .itemunlocked .js-placebid': 'showPlaceBidModal'
    };
  }

  collectionEvents() {
    //When we fetch the auction item list let's re-render the page
    return {
      sync: 'render'
    };
  }

  initialize() {
    this.auctionItemList = this.collection;
    this.removeBidInProgress = {};
  }

  render() {
    const items = this.auctionItemList.toJSON() ? this.auctionItemList.toJSON() : [];
    this.$el.html(this.template({items}));

    this._closeItemViews();
    this._renderItemViews();
    this.tryFocusButtonById(this.currentButtonId);
    return this;
  }

  _renderItemViews() {
    this.itemViews = this.auctionItemList.map((item) => {
      return new ItemView({
        el: this.$(`#auction${ item.get('rewardProgramPrize').id }`),
        model: item
      }).render();
    });
  }

  tryFocusButtonById(buttonId) {
    if ($(`#${ buttonId }`)) {
      $(`#${ buttonId }`).trigger('focus');
    }
  }

  showPlaceBidModal(e) {
    this.currentButtonId = e.currentTarget.id;
    if (this.bidNowModalActive) {
      return;
    }
    this.bidNowModalActive = true;
    const $itemrow = this.$(e.currentTarget).closest('.auctionitem');
    const itemid = $itemrow.data('auction-id');
    const auctionItem = this.auctionItemList.find((model) => {
      return model.get('rewardProgramPrize').id === itemid;
    });
    auctionItem.on('update', () => {
      this.auctionItemList.fetch();
    });

    window.app.layout.presentModal(
      new PlaceBidModal({
        model: auctionItem,
        $itemrow
      }), {
        onClose: () => {
          this.bidNowModalActive = false;
          auctionItem.off('update');
          this.tryFocusButtonById(this.currentButtonId);
        }
      }
    );
  }

  _closeItemViews() {
    for (const view of Array.from((this.itemViews != null ? this.itemViews : []))) {
      view.unload();
    }

    this.itemViews = null;
  }

  selectedAuctionItem(e, auctionItem = {}) {
    auctionItem.$itemrow = this.$(e.currentTarget).closest('.auctionitem');
    const itemid = auctionItem.$itemrow.data('auction-id');
    auctionItem.model = this.auctionItemList.find((model) => {
      return model.get('rewardProgramPrize').id === itemid;
    });
    return auctionItem;
  }

  onClose(...args) {
    this._closeItemViews();
    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }

    super.onClose(...args);
  }

  onDestroy() {
    window.app.layout.dismissMedia();
  }
}


class ItemView extends UIKit.View {

  behaviors() {
    return {
      ZoomOverlay: {},
      Card: {}
    };
  }

  modelEvents() {
    return {
      change: 'render'
    };
  }

  initialize() {
    this.listenTo(this, 'image:clicked', () => {
      window.app.layout.showMedia(this.model.get('rewardProgramPrize').prize.prizeImg);
    });
  }

  render() {
    this._closeSecondTimer();

    if (this.model.get('rewardProgramPrize').isLocked) {
      this._renderLocked();
    } else {
      this._renderUnlocked();
    }

    return this;
  }

  onRender() {
    const { prizeImg } = this.model.get('rewardProgramPrize').prize;
    if (prizeImg) {
      this._imageViewer = ImageViewerFactory.createViewerInstance({
        media: prizeImg,
        $el: this.$('.auctionitemimage')
      });
      if (this._imageViewer) {
        this._imageViewer.render();
      }
    }
  }

  _renderLocked() {
    const totalTime = this.model.get('rewardProgramPrize').unlocksInMillis / 1000;
    this.timer = new UIKit.SecondTimer({
      el: this.$el.find('.unlocksin'),
      timeFormat: true,
      totalTime,
      finishCallback: () => {
        this.model.set({unlocksInMillis: 0});
      }
    });
    this.timer.start();
  }

  _renderUnlocked() {
    const rewardProgramPrize = $.extend(true, {}, this.model.get('rewardProgramPrize'));
    const totalTime = (rewardProgramPrize.nextEndMillis - 10) / 1000.0;
    if (totalTime <= 0) {
      this.$el.addClass('itemtimeout');
      this.$('.auctiontime').text(I18n.t('auction.closed'));
    } else {
      this.timer = new UIKit.SecondTimer({
        el: this.$el.find('.timeleft'),
        timeFormat: true,
        totalTime,
        finishCallback: () => {
          rewardProgramPrize.nextEndMillis = 0;
          this.model.set({rewardProgramPrize});
        }
      });
      this.timer.start();
    }
  }

  _closeSecondTimer() {
    if (this.timer) {
      this.timer.stop();
    }
  }

  onClose() {
    this._closeSecondTimer();
  }
}

class PlaceBidModal extends AccessibleModalView {
  constructor(options) {
    super(options);

    this.onKeypress = this.onKeypress.bind(this);
    this.onChangeRewardPrize = this.onChangeRewardPrize.bind(this);
    this.placeBid = this.placeBid.bind(this);
    this.removeBid = this.removeBid.bind(this);

    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');
    this.placeBidInProgress = false;
  }

  id() {
    return 'modalview';
  }

  className() {
    return 'modal auction-item-modal confirm-dialog-view';
  }

  behaviors() {
    return Object.assign({}, super.behaviors(), {
      ZoomOverlay: {}
    });
  }

  events() {
    return Object.assign({}, super.events(), {
      'keypress #bid': 'onKeypress'
    });
  }

  modelEvents() {
    return {
      'change:rewardProgramPrize': 'onChangeRewardPrize'
    };
  }

  onKeypress(e) {
    // Check and disable input if keypressed is not in range 0-9 or space/delete
    // 0 and 8 are for Firefox's delete and arrow keys
    const acceptedCharacters = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    acceptedCharacters.push(0, KeyCode.BACKSPACE, KeyCode.ENTER, KeyCode.SPACE);
    if (!acceptedCharacters.includes(e.which)) {
      e.preventDefault()
      return;
    }
    if (e.which === KeyCode.ENTER) {
      this.placeBid(e);
    }
  }

  onRenderContent() {
    this.$('.notice').hide();

    if (this.model.get('bid') && this.model.get('isWinning')) {
      this.$('#bid').attr('min', this.model.get('bid').points);
    }
  }

  onRenderActionBar() {
    const buttons = [{
      type: 'customText',
      text: I18n.t('auction.bidbtn'),
      onClick: this.placeBid
    }];

    if (this.model.get('bid') && !this.model.get('isWinning')) {
      buttons.push({
        type: 'customText',
        text: I18n.t('auction.removebid'),
        className: 'red',
        onClick: this.removeBid
      });
    }

    this.actionBar.setActionBar({buttons});
  }

  contentTemplate() {
    const templateFn = _.tpl(require('@training/apps/training/templates/_place_bid_modal.html'));
    return templateFn({
      model: this.model.toJSON(),
      pointBalance: window.apps.auth.session.user.availablePoints()
    });
  }

  viewDidAppear() {
    super.viewDidAppear();

    const { prizeImg } = this.model.get('rewardProgramPrize').prize;
    if (prizeImg) {
      this._imageViewer = ImageViewerFactory.createViewerInstance({
        media: prizeImg,
        $el: this.$('.itemimage')
      });
      this._imageViewer.on('image:loaded', () => {
        this.triggerAdjustment();
      });
      if (this._imageViewer) {
        this._imageViewer.render();
      }
    }

    this.listenTo(this, 'image:clicked', () => {
      window.app.layout.showMedia(this.model.get('rewardProgramPrize').prize.prizeImg);
    });
  }

  onChangeRewardPrize(model, value = {}) {
    if (value.nextEndMillis <= 0) {
      this.dismiss();
    }
  }

  placeBid() {
    if (this.placeBidInProgress) {
      return;
    }
    this.placeBidInProgress = true;

    // Check to make sure that the input's value can
    // be parsed and that a number is returned
    let bid = -1;
    const inputValue = this.$('input').val()
      .trim();
    if ((inputValue.length > 0) && !isNaN(inputValue)) {
      bid = parseInt(inputValue, 10);
    } else {
      this.$('.notice').html(I18n.t('auction.notice.invalidBid'))
        .show()
        .delay(DELAY)
        .fadeOut();
      this.placeBidInProgress = false;
      return;
    }

    let totalUserPoints = window.apps.auth.session.user.availablePoints();

    //Check if user has any bids on the item already and include it in the calculation
    if (this.model.get('bid')) {
      totalUserPoints += this.model.get('bid').points;
    }

    if (bid > totalUserPoints) {
      this.$('.notice').html(I18n.t('auction.notice.nomoney'))
        .show()
        .delay(DELAY)
        .fadeOut();
      this.placeBidInProgress = false;
      return;
    }

    if (this.model.get('bid') && this.model.get('isWinning') && (bid < this.model.get('bid').points)) {
      this.$('.notice').html(I18n.t('auction.notice.forbiddenbid'))
        .show()
        .delay(DELAY)
        .fadeOut();
      this.placeBidInProgress = false;
      return;
    }

    if (bid < this.model.get('rewardProgramPrize').minBid) {
      this.$('.notice').html(I18n.t('auction.notice.minbid'))
        .show()
        .delay(DELAY)
        .fadeOut();
      this.placeBidInProgress = false;
      return;
    }

    this.model.placeBid(bid, {
      success: () => {
        this.model.trigger('update');
        this.dismiss();
      },
      error: (response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        const errCode = exception.getErrorCode();

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_AUCTION_CLOSED) {
          this.dismiss();
          this.options.$itemrow.addClass('itemtimeout');
          this.options.$itemrow.find('.auctiontime').text(I18n.t('auction.closed'));
          response.skipGlobalHandler = true;
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_CANNOT_WIN_PRIZE) {
          this.dismiss();
          this.options.$itemrow.addClass('itemtimeout');
          this.options.$itemrow.find('.auctiontime').text(I18n.t('auction.issoldout'));
          const auctionItem = new AuctionItem(this.model.get('rewardProgramPrize'));
          window.app.layout.presentModal(new SoldOutModal({model: auctionItem}));
          response.skipGlobalHandler = true;
        } else if ([AxonifyExceptionCode.CLIENT_ERROR_INSUFFICIENT_QUANTITY, AxonifyExceptionCode.CLIENT_ERROR_INVALID_VALUE].includes(errCode)) {
          const messages = {
            3012: I18n.t('auction.notice.nomoney'),
            3014: I18n.t('auction.notice.minbid')
          };

          this.$('.notice').html(messages[errCode])
            .show()
            .delay(DELAY)
            .fadeOut();
          response.skipGlobalHandler = true;
        }
      },
      complete: () => {
        this.placeBidInProgress = false;
      }
    });
  }

  removeBid() {
    if (this.removeBidInProgress) {
      return;
    }
    this.removeBidInProgress = true;

    this.model.removeBid({
      complete: () => {
        this.removeBidInProgress = false;
      },
      success: () => {
        this.model.trigger('update');
        this.dismiss();
      }
    });
  }
}

module.exports = AuctionBidView;
