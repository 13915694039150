const MilestonesStatusView = require('@common/components/guidedLearning/programs/MilestonesStatusView');
const I18n = require('@common/libs/I18n');

class MilestonesStatusEnrollView extends MilestonesStatusView {
  _createPaceMessage() {
    if (this.model.get('duration') === 1) {
      return I18n.t('GuidedLearning.statusHeader.dueOnceEnrolledSingle');
    }

    return I18n.t('GuidedLearning.statusHeader.dueDateFromEnrollment', { daysAway: this.model.get('duration') });
  }
}

module.exports = MilestonesStatusEnrollView;
