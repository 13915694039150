import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

const BREAKPOINT_TABLET = 640;
const BREAKPOINT_DESKTOP = 992;

type BreakpointType = EnumType & {
  MOBILE_MAX: number,
  TABLET_MIN: number,
  TABLET_MAX: number,
  DESKTOP_MIN: number
};

const AxBreakpointEnum = Enum({
  // UI Kit breakpoint values
  MOBILE_MAX: BREAKPOINT_TABLET - 1,
  TABLET_MIN: BREAKPOINT_TABLET,
  TABLET_MAX: BREAKPOINT_DESKTOP - 1,
  DESKTOP_MIN: BREAKPOINT_DESKTOP
}) as BreakpointType;

export default AxBreakpointEnum;
