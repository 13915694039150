const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

class Download extends Backbone.Model {
  get className() {
    return 'Download';
  }

  apiEndpoint() {
    return '/report/export';
  }

  parse(resp = {}) {
    const entity = resp.entity || resp;
    //Server only returns the file object when a success export has completed
    //Processing and Failed exports will not return anything.
    if (entity.file == null) {
      entity.file = {
        originalFileName: I18n.t(`downloadsExports.downloads.types.${ entity.type }`)
      };
    }

    return entity;
  }

  toGrid() {
    const json = this.toJSON();
    json.formattedType = this._getFormattedName(json);
    return json;
  }

  toOption() {
    return {
      id: this.getSourceName(),
      value: this._getFormattedName(this.toJSON())
    };
  }

  getSourceName() {
    return this.get('type');
  }

  _getFormattedName(model) {
    return I18n.t(`downloadsExports.downloads.types.${ model.type }`);
  }
}

module.exports = Download;
