const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  ALL: 'all',
  ARTICLES: 'articles',
  EVENTS: 'events',
  TOPICS: 'topics',
  PATHS: 'paths',
  COMMUNITIES_MANAGEMENT: 'communitiesManagement',
  COMMUNITIES_INSIGHTS: 'communitiesInsights',
  COMMUNITIES_INSIGHTS_REPORTS: 'communitiesInsightsReports',
  POSTS: 'posts',
  COMMUNITY: 'community',
  TIMELINE: 'timeline',
  PAGE_INSIGHTS: 'pageInsights'
});
