const { LayoutView } = require('Marionette');
const LayoutTemplate = require('@training/apps/training/pageTitleBar/PageTitleBarLayoutTemplate.html');

module.exports = {
  ViewClass: LayoutView,
  className: 'page-title-bar-layout page-header page-header--fixed',
  template: LayoutTemplate,
  regions: {
    leftRegion: '.page-title-left-region',
    rightRegion: '.page-title-right-region',
    bailRegion: '.page-title-bail-region'
  }
};
