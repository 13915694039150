const logging = require('logging');
const $os = require('detectOS');
const _ = require('underscore');
const VideoPlayer = require('@common/components/video/players/VideoPlayer');

class VimeoPlayer extends VideoPlayer {
  constructor(...args) {
    super(...args);
    this.template = _.tpl(`\
    <iframe
      src="<%= url %>"
      width="<%= width %>"
      height="<%= height %>"
      frameborder="0"
      webkitAllowFullScreen
      mozallowfullscreen
      allowFullScreen>
    </iframe>
    <div class="vimeomsg" style="text-align:center; width:100%; display:none;">Windows Phone does not support Vimeo.<br /> Tap the next arrow to continue</div>\
    `);
  }

  renderPlayer() {
    this._$iframe = this.$('iframe');
    this._$supportMessage = this.$('.vimeomsg');
    if ($os.windowsphone) {
      this._$iframe.hide();
      this._$supportMessage.show();
      return this._triggerError();
    }
    this._triggerLoad();
    return this._triggerEnd();

  }

  getTemplateOptions() {
    return _.extend(super.getTemplateOptions(), {url: this.getVideoUrl()});
  }

  getVideoUrl() {
    const vimeoKey = this.model.getVimeoKey();
    const vimeoHash = this.model.getVimeoHash();
    let url = `https://player.vimeo.com/video/${ vimeoKey }?title=1&byline=1&portrait=1&api=1`;
    if (this.autoplay) {
      url += '&autoplay=1';
    }
    if (vimeoHash) {
      url += `&h=${ vimeoHash }`;
    }
    logging.debug(`Vimeo video ${ url } -- ${ vimeoKey + (vimeoHash ? ' / '.concat(vimeoHash) : '') }`);
    return url;
  }

  removePlayer() {
    this._$iframe.attr('src', '');
    this._$iframe.attr('src', this.getVideoUrl());
  }

  // Overriding the aspect ratio setter so it's always 16/9
  _setAspectRatio() {
    return super._setAspectRatio(16 / 9);
  }
}
module.exports = VimeoPlayer;
