import {
  ListItemAvatar as MuiListItemAvatar,
  type ListItemAvatarProps as MuiListItemAvatarProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxListItemAvatarProps = MuiListItemAvatarProps;

const styledOptions = {
  name: 'AxListItemAvatar'
};

const StyledAxListItemAvatar = styled(MuiListItemAvatar, styledOptions)<AxListItemAvatarProps>(({theme}) => {
  return {
    minWidth: 'unset',
    marginRight: theme.uiKit.spacingS
  };
});

/**
* An avatar to be used inside of a list item
*
* ### Links
* - • [MUI ListItemAvatar | API](https://mui.com/material-ui/api/list-item-avatar/)
*
* @example
* <AxListItem>
*  <AxListItemAvatar>
*   <AxAvatar />
*  </AxListItemAvatar>
* </AxListItem>
*/
export const AxListItemAvatar = forwardRef(({
  children,
  ...otherProps
}: AxListItemAvatarProps, ref: Ref<HTMLLIElement>) => {
  const ListItemAvatarProps: AxListItemAvatarProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemAvatar { ...ListItemAvatarProps } ref={ ref }>
      { children }
    </StyledAxListItemAvatar>
  );
});

export default AxListItemAvatar;
