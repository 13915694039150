import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitAppHeader(cssVars: CSSStyleDeclaration) {
  return {
    appHeaderBorderWidth: getCssValue('--ax-app-header-border-width', cssVars), // branding style
    appHeaderBorderColor: getCssValue('--ax-app-header-border-color', cssVars), // branding style
    appHeaderBackgroundColor: getCssValue('--ax-app-header-background-color', cssVars), // branding style
    appHeaderButtonHoverBackgroundColor: getCssValue('--ax-app-header-button-hover-background-color', cssVars),
    appHeaderFontColor: getCssValue('--ax-app-header-font-color', cssVars),
    appHeaderHeightMobile: getCssValue('--ax-app-header-height-mobile', cssVars),
    appHeaderHeightDesktop: getCssValue('--ax-app-header-height-desktop', cssVars)
  };
}
