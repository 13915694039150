// Module
var code = `<li class="active-invite">
  <div class="active-invite-wrapper clearfix">
    <div class="use-profile"></div>
    <div class="name-logo" role="presentation" aria-hidden="true"></div>
    <div class="name-details">
      <div class="name text-ellipsis"><%- name %></div>
      <div class="department-meta">
        <%- department %> •
        <% if (daysLeft == 1) {%>
          <%- t('referral.dayLeft') %>
        <% } else { %>
          <%- t('referral.daysLeft', {days: daysLeft}) %>
        <% } %>
      </div>
    </div>
  </div>
</li>
`;
// Exports
module.exports = code;