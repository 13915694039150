const ObjectiveCardFactory = require('@common/components/guidedLearning/objectives/learningObjectiveCards/LearningObjectiveCardDefinitionFactory');
const LearningObjectiveBaseLayoutView = require('@common/components/guidedLearning/objectives/LearningObjectiveBaseLayoutView');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const createLayout = (objective, detailsView, options) => {
  const className = objective.getType();
  const layout = new LearningObjectiveBaseLayoutView(
    Object.assign({contentView: detailsView, className: className.toLowerCase()}, options)
  );
  return layout;
};

const createDetailView = (objective, options) => {
  options.canBegin = options.model.canBegin() && options.objectiveValidator(options.model);
  const objectiveCardDefinition = ObjectiveCardFactory.getObjectiveCardDefinition(objective, options);

  return ViewControllerFactory.createLegacyView(objectiveCardDefinition);
};

module.exports = {
  create(objective, options) {
    const newOptions = Object.assign({model: objective}, options);
    const detailView = createDetailView(objective, newOptions);
    return createLayout(objective, detailView, Object.assign({}, newOptions, {tagName: 'li'}));
  }
};
