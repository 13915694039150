const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');
const LoadingCollectionViewController = require('@common/components/loading/collection_view/LoadingCollectionViewController');

const InfiniteCollectionPageLoader = require('@common/libs/behaviors/infiniteCollectionPageLoader/InfiniteCollectionPageLoader');

/*
  InfiniteCollectionViewController is a LoadingController that strictly wraps Marionette.CollectionView/CompositeView's when
  the passed in collection is an InfiniteCollection. This controller is responsible for showing a LoadingRow or a wrapper
  blanket depending on the state of the InfiniteCollection;

  If there's no data, it shows the LoadingRow with a spinner.
  If there's data and it's fetching another page of data, it shows the LoadingRow with spinner at the bottom of the current list of Views.
  If there's data and the first page of the InfiniteCollection is being rerequested, it shows the wrapper blanket.

  Enums:
    'Options': defines all valid options that can be passed in as constructor options including the InfiniteCollectionViewController.Options

      'INFINITE_PAGE_LOADER_OPTIONS': (View Controller Definition) - the Collection/CompositeView controller that will be
      shown as the content
 */

const OptionKeys = LoadingCollectionViewController.Options.extend({
  INFINITE_PAGE_LOADER_OPTIONS: 'infinitePageLoaderOptions'
});

class InfiniteCollectionViewController extends LoadingCollectionViewController {

  static Options = OptionKeys;

  constructor(options = {}) {
    super(options);

    ({
      [OptionKeys.COLLECTION]: this._collection = new InfiniteCollection(),
      [OptionKeys.INFINITE_PAGE_LOADER_OPTIONS]: this._infinitePageLoaderOptions
    } = options);

    if (!(this._collection instanceof InfiniteCollection)) {
      throw new Error('InfiniteCollectionViewController requires the passed in collection to be of type InfiniteCollection');
    }

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return Object.assign({}, super.viewDefinition(), {
      behaviors: {
        InfiniteCollectionPageLoader: Object.assign({
          [InfiniteCollectionPageLoader.Options.COLLECTION]: this._collection
        }, this._infinitePageLoaderOptions)
      }
    });
  }

  shouldShowLoadingView(options) {
    return super.shouldShowLoadingView(options) || !this._collection.isFirstPagePending();
  }
}

module.exports = InfiniteCollectionViewController;
