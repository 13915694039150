const Marionette = require('Marionette');

class TaskDetailInfoView extends Marionette.ItemView {
  getTemplate() {
    return require('@training/apps/legacyTasks/views/taskDetail/TaskDetailInfoView.html');
  }

  className() {
    return 'task-detail-info';
  }
}

module.exports = TaskDetailInfoView;
