import {
  AxIcon,
  type IconNames
} from '@common/modules/react/themes/components/data-display/AxIcon';
import {
  BottomNavigationAction as MuiBottomNavigationAction,
  styled,
  useTheme,
  type BottomNavigationActionProps,
  type Theme
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';
import _ from 'underscore';

type PropsToOmit =
  'centerRipple' |
  'disableRipple' |
  'disableTouchRipple' |
  'focusRipple' |
  'TouchRippleProps' |
  'touchRippleRef';

export interface AxBottomNavigationActionProps extends Omit<BottomNavigationActionProps, PropsToOmit> {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * The icon to display in the bottom navigation action.
   * If the consumer passes iconName and no icon, an AxIcon component with the iconName is created.
   * It uses the default UiKit values for icon color and size.
   *
   * If the consumer passes both iconName and icon, the icon is used.
   */
  iconName?: IconNames,
  /**
   * The icon element to display in the bottom navigation action.
   * If you use icon, you're responsible for styling it.
   */
  icon?: React.ReactNode
}

const styledOptions = {
  name: 'AxBottomNavigationAction'
};

const StyledAxBottomNavigationAction = styled(MuiBottomNavigationAction, styledOptions)<AxBottomNavigationActionProps>(({theme}) => {
  return {
    transitionProperty: 'all',
    transitionTimingFunction: theme.uiKit.transitionEase,
    transitionDuration: theme.uiKit.transitionSpeed,
    '&.Mui-disabled': {
      opacity: theme.uiKit.opacityXL
    },
    '&.Mui-focusVisible': theme.mixins.componentKeyboardFocusStyles(),
    '.MuiBottomNavigationAction-label': {
      color: theme.uiKit.bottomNavigation.label.color,
      fontWeight: theme.uiKit.bottomNavigation.label.fontWeight,
      fontSize: theme.uiKit.bottomNavigation.label.fontSize,
      lineHeight: theme.uiKit.bottomNavigation.label.lineHeight,
      padding: theme.uiKit.bottomNavigation.label.padding,
      borderBottom: theme.uiKit.bottomNavigation.label.borderBottom,
      '&.Mui-selected': {
        borderBottom: theme.uiKit.bottomNavigation.label.selected.borderBottom,
        fontSize: theme.uiKit.bottomNavigation.label.fontSize // ⚠️ removes grow effect when selected
      }
    }
  };
});

/**
 * This component is a wrapper around the MuiBottomNavigationAction component.
 *
 * Use this component to create a bottom navigation action.
 * Consumer should pass the icon with the desired styles. This component doesn't add any styles to the icon.
 */
export const AxBottomNavigationAction = forwardRef(({
  ...otherProps
}: AxBottomNavigationActionProps, ref: Ref<HTMLButtonElement>) => {
  const theme = useTheme();
  const iconElement = getIconElement(otherProps.icon, otherProps.iconName, theme);
  const BottomNavigationActionProps: AxBottomNavigationActionProps = {
    ...otherProps
  };

  return (
    <StyledAxBottomNavigationAction
      { ...BottomNavigationActionProps }
      ref={ ref }
      disableRipple
      icon={ iconElement }
    />
  );
});

export default AxBottomNavigationAction;


function getIconElement(icon: React.ReactNode, iconName: IconNames | undefined, theme: Theme) {
  if (icon) {
    return icon;
  }
  if (!_.isEmpty(iconName)) {
    return <AxIcon
      iconName={ iconName }
      sx={{
        fontSize: theme.uiKit.bottomNavigation.icon.size,
        color: theme.uiKit.bottomNavigation.icon.color
      }}/>;
  }
  return null;
}
