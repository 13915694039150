const {Model} = require('Backbone');
const TranslatableString = require('@common/data/models/translationStrings/TranslatableString');

class QuestionOption extends Model {
  get className() {
    return 'QuestionOption';
  }

  urlRoot() {
    return '';
  }

  validators() {
    return {};
  }

  initialize() {
    if (!this.get('optionTextTranslation')) {
      this.set('optionTextTranslation', new TranslatableString());
    }
    if (!this.get('secondTextTranslation')) {
      this.set('secondTextTranslation', new TranslatableString());
    }
    if (!this.get('reasonTextTranslation')) {
      return this.set('reasonTextTranslation', new TranslatableString());
    }
  }
}

module.exports = QuestionOption;
