const Backbone = require('Backbone');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');

class OptionModel extends Backbone.Model {
  toOption() {
    return this.toJSON();
  }
}

const TIME_PERIOD_RANGE = 6;

const _generateTimePeriodList = function() {
  // Add the "All-Time" option as the first element
  const options = [{
    id: -1,
    value: I18n.t('discover.browse.timePeriod.allTime')
  }];
  const date = new Date();
  // Generate the months options with an optionGroup for grouping
  _.times(TIME_PERIOD_RANGE, () => {
    const currentMonth = date.getMonth();
    const year = date.getFullYear();
    const month = currentMonth + 1;
    const paddedMonth = month < 10 ? `0${ month }` : `${ month }`;
    options.push({
      optionGroup: `${ year }`,
      id: `${ year }${ paddedMonth }`,
      value: I18n.t(`discover.browse.timePeriod.months.${ paddedMonth }`)
    });
    return date.setMonth(currentMonth - 1);
  });
  return options;
};

class TimePeriodList extends Backbone.Collection {
  get model() {
    return OptionModel;
  }

  initialize() {
    this.reset(_generateTimePeriodList());
  }

  getDefaultTimePeriodDate() {
    // Get the most recent month of the most recent year and set it as the
    // selected `timePeriodDate`
    const firstOptionGroupModel = this.find((optionModel) => {
      return optionModel.has('optionGroup');
    });
    return firstOptionGroupModel.id;
  }
}
module.exports = TimePeriodList;
