import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type AchievementType = EnumType & {
  CORRECT_ANSWER_STREAK: string,
  SESSION_STREAK: string,
  PARTICIPATION_FREQUENCY: string,
  TOPIC_LEVEL_GRADUATION_STREAK: string,
  MILESTONE_ACHIEVEMENT: string
};

const AchievementTypeEnum = Enum({
  CORRECT_ANSWER_STREAK: 'CorrectAnswerStreak',
  SESSION_STREAK: 'SessionStreak',
  PARTICIPATION_FREQUENCY: 'ParticipationFrequency',
  TOPIC_LEVEL_GRADUATION_STREAK: 'TopicLevelGraduationStreak',
  MILESTONE_ACHIEVEMENT: 'MilestoneAchievement'
}) as AchievementType;

export default AchievementTypeEnum;
