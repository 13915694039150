const Form = require('@common/components/forms/Form');
const TranslationInput = require('@common/components/forms/editors/translationInput/Form.Editor.TranslationInput');
Form.Editor.TranslationTextArea = class TranslationTextArea extends TranslationInput {

  getTranslationTemplate() {
    return `\
    <textArea class="language-input translation-values"
      data-options="formOptions"
      type="text"
      id="name"
      data-field="value"
      placeholder="<%- placeholder %>"
      maxlength="<%- maxlength %>"></textarea>\
    `;
  }

  events() {
    //noop
  }
};

module.exports = Form.Editor.TranslationTextArea;
