const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const TrainingModuleType = require('@common/data/enums/TrainingModuleType');

const getPreviewButtonText = (trainingModuleType) => {
  if (trainingModuleType != null) {
    switch (trainingModuleType.toLowerCase()) {
      case TrainingModuleType.EXTERNAL_LINK.toLowerCase():
        return I18n.t('trainingPreview.externalLinkButtonLabel')
      case TrainingModuleType.PDF.toLowerCase():
        return I18n.t('trainingPreview.pdfButtonLabel')
      case TrainingModuleType.EXTERNAL_VIDEO.toLowerCase():
      case TrainingModuleType.TRANSCODED_VIDEO.toLowerCase():
        return I18n.t('trainingPreview.videoButtonLabel')
      default:
        return I18n.t('trainingPreview.moduleButtonLabel')
    }
  }

  return '';
}

const allowDirectPreview = (moduleType) => {
  const directModuleTypes = [
    TrainingModuleType.EXTERNAL_LINK.toLowerCase(),
    TrainingModuleType.PDF.toLowerCase()
  ]

  return _.contains(directModuleTypes, moduleType.toLowerCase());
}

module.exports = {
  getPreviewButtonText,
  allowDirectPreview
};
