const Marionette = require('Marionette');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

const CatalogBrowseTypeEnum = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogBrowseTypeEnum');

require('@common/components/forms/editors/checkboxes/Form.Editor.Checkboxes');
require('@common/components/forms/editors/radioGroup/Form.Editor.RadioGroup');

class CatalogFilterView extends Marionette.LayoutView {
  className() {
    return 'learner-training-catalog-filter-wrapper';
  }

  initialize(options = {}) {
    ({
      filterSubjectList: this.filterSubjectList,
      filterCollection: this.filterCollection,
      filterURLBuilder: this.filterURLBuilder,
      isRenderedInModal: this.isRenderedInModal = false
    } = options);

    this.browseType = this.filterCollection.getBrowseType();

    this.formModel = new Backbone.Model({
      browseType: this.browseType,
      subjectList: this.filterCollection.getSubjectList()
    });

    this.listenTo(this.filterSubjectList, 'sync', () => {
      this._setupFilters();
    });
  }

  getTemplate() {
    return require('@training/apps/learnerTraining/learnerTrainingTab/CatalogFilterViewTemplate.html');
  }

  ui() {
    return {
      form: '.js-form',
      subjectList: '.catalog-filter__subject-container',
      actionButtonWrapper: '.catalog-filter__action-button--wrapper',
      resetButton: '.catalog-filter__reset-button',
      applyButton: '.catalog-filter__apply-button'
    };
  }


  onRender() {
    this._setupFilters();

    if (this.isRenderedInModal) {
      this.ui.actionButtonWrapper.hide();
      this.$el.addClass('learner-training-catalog-filter-modal-wrapper')
        .removeClass('learner-training-catalog-filter-wrapper');

      const buttons = this._getButtonConfig();
      this.actionBar.setActionBar({ buttons });
    }
    triggerResize(true);
  }

  _getButtonConfig() {
    return [{
      type: 'customText',
      text: I18n.t('general.reset'),
      className: 'white',
      onClick: () => {
        this._onClickReset();
        window.app.layout.dismissModal();
      }
    }, {
      type: 'customText',
      text: I18n.t('general.apply'),
      onClick: () => {
        this._onClickApply();
        window.app.layout.dismissModal();
      }
    }];
  }

  _setupFilters() {
    const browseTypeCollection = new Backbone.Collection([
      {
        value: CatalogBrowseTypeEnum.ALL,
        label: I18n.t('learnerTrainingPage.catalog.filter.all')
      }, {
        value: CatalogBrowseTypeEnum.PATH,
        label: I18n.t('learnerTrainingPage.catalog.filter.pathsOnly')
      }, {
        value: CatalogBrowseTypeEnum.TOPIC,
        label: I18n.t('learnerTrainingPage.catalog.filter.topicsOnly')
      }
    ]);

    this.form = new Form({
      el: this.ui.form,
      model: this.formModel,
      context: {
        browseTypeFilters: browseTypeCollection,
        subjectFilters: {
          collection: this.filterSubjectList,
          toOptionCheckbox: (model) => {
            return {
              value: model.get('subjectId'),
              label: `${ model.get('subjectName') }`
            };
          }
        }
      }
    });

    this.listenTo(this.form, 'change:browseType', (form, editor) => {
      const browseType = editor.getValue();
      this._toggleSubjectList(browseType);
    });

    this._toggleSubjectList(this.formModel.get('browseType'));
  }

  _toggleSubjectList(browseType) {
    if (browseType === CatalogBrowseTypeEnum.TOPIC && this.filterSubjectList.length > 0) {
      this.ui.subjectList.removeClass('hidden');
    } else {
      this.ui.subjectList.addClass('hidden');
    }

    triggerResize(true);
  }

  events() {
    return {
      'click @ui.resetButton:not(.disabled)': '_onClickReset',
      'click @ui.applyButton:not(.disabled)': '_onClickApply',
      'click .js-close-catalog-filter': () => {
        window.app.layout.dismissModal();
      }
    };
  }

  _onClickReset() {
    this.formModel.set({
      browseType: CatalogBrowseTypeEnum.ALL,
      subjectList: []
    });

    this.form.update(this.formModel);

    // Form update doesn't trigger a reset of the checkboxes in the UI when passing in a blank array, so need to manually clear the checkboxes
    this.form.fields.subjectList.editor.resetCheckboxes();

    this._toggleSubjectList(this.formModel.get('browseType'));

    this._triggerFilterChange();

    this.ui.resetButton.trigger('blur');
  }

  _onClickApply() {
    this._triggerFilterChange();

    this.ui.applyButton.trigger('blur');
  }

  _triggerFilterChange() {
    const browseType = this.formModel.get('browseType');
    const subjectList = this.formModel.get('subjectList');

    this.filterCollection.applyFilterParams(browseType, subjectList);

    const URL = this.filterURLBuilder(
      this.filterCollection.getQueryString(),
      this.filterCollection.state.currentPage,
      browseType
    );

    Backbone.history.navigate(URL, {
      trigger: false,
      replace: true
    });
  }
}

module.exports = CatalogFilterView;
