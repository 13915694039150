class PasswordComplexityValidationResult {
  constructor(isTooShortValidation, isWithoutEnoughGroupsValidation) {
    this.isTooShortValidation = isTooShortValidation;
    this.isWithoutEnoughGroupsValidation = isWithoutEnoughGroupsValidation;
  }

  isTooShort() {
    return this.isTooShortValidation === true;
  }

  isWithoutEnoughGroups() {
    return this.isWithoutEnoughGroupsValidation === true;
  }
}

module.exports = PasswordComplexityValidationResult;
