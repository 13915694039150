const Backbone = require('Backbone');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const AxonifyObject = require('@common/libs/AxonifyObject');

const LOCK_PERIOD = 4 * 60 * 1000;

class LockController extends AxonifyObject {
  initialize(options = {}) {
    this._lock = this._lock.bind(this);
    this._startLockInterval = this._startLockInterval.bind(this);
    this._stopLockInterval = this._stopLockInterval.bind(this);
    this.authChannel = Backbone.Wreqr.radio.channel('auth');

    ({model: this.model} = options);
  }

  lockPage() {
    // once the model is locked the first time,
    // the interval timer should start and the
    // promise should get resolved
    return this._lock().done(this._startLockInterval);
  }

  unlockPage() {
    return this._stopLockInterval();
  }

  _lock() {
    return this.model.lock().fail((xhr) => {
      return this.trigger('lock:error', xhr);
    });
  }

  _startLockInterval() {
    if ((this._lockInterval == null)) {
      this._lockInterval = setInterval(this._lock, LOCK_PERIOD);
      return this._lockInterval;
    }
    return null;
  }

  _stopLockInterval() {
    if (this._lockInterval) {
      clearInterval(this._lockInterval);
      delete this._lockInterval;

      // Do not send an unlock request if user is logged out.
      if (!apps.auth.session.user.isLoggedIn()) {
        return $.Deferred().resolve()
          .promise();
      }

      return this.model.unlock().fail((xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        if ([AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY, AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED].includes(exception.getErrorCode())) {
          xhr.skipGlobalHandler = true;
        }
      });
    }
    return null;
  }

  onDestroy() {
    this._stopLockInterval();
  }
}
module.exports = LockController;
