import {
  ListSubheader as MuiListSubheader,
  styled,
  type ListSubheaderTypeMap as MuiListSubheaderTypeMap
} from '@mui/material';
import { type OverrideProps as MuiOverrideProps } from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type PropsToOmit = 'color';
type AxListSubheaderCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxListSubheaderTypeMap<P = object, D extends React.ElementType = 'li'> = MuiListSubheaderTypeMap<P & AxListSubheaderCustomProps, D>;

export type AxListSubheaderProps<
  D extends ElementType = AxListSubheaderTypeMap['defaultComponent'],
  P = object,
> = Omit<MuiOverrideProps<AxListSubheaderTypeMap<P & AxListSubheaderCustomProps, D>, D>, PropsToOmit>;

const styledOptions = {
  name: 'AxListSubheader'
};

const StyledAxListSubheader = styled(MuiListSubheader, styledOptions)<AxListSubheaderProps>(({ theme }) => {
  return {
    borderTop: `0.1rem solid ${ theme.uiKit.colorGrey20 }`,
    fontSize: theme.uiKit.fontSizeXS
  };
});

/**
* A label for a nested list
*
* ### Links
* - • [MUI ListSubheader | API](https://mui.com/material-ui/api/list-subheader/)
*
*/
export const AxListSubheader = forwardRef(({
  children,
  ...otherProps
}: AxListSubheaderProps, ref: Ref<HTMLLIElement>) => {
  const ListSubheaderProps: AxListSubheaderProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListSubheader { ...ListSubheaderProps } ref={ ref }>
      { children }
    </StyledAxListSubheader>
  );
});

export default AxListSubheader;
