const { ItemView } = require('Marionette');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const MediaThumbnailSizes = require('@common/libs/file/MediaThumbnailSizes');

class ImageCropImagePreviewsView extends ItemView {
  initialize(options = {}) {
    ({
      isImageRequired: this.isImageRequired = false,
      thumbnailSizes: this.thumbnailSizes
    } = options);

    this.isRtl = I18n.isCurrentLanguageRtl();
  }

  get template() {
    return require('@common/components/forms/editors/imageCrop/ImageCropImagePreviewsView.html');
  }

  templateHelpers() {
    return {
      isImageRequired: this.isImageRequired,
      showLargeThumbnail: _.contains(this.thumbnailSizes, MediaThumbnailSizes.LARGE),
      showMediumThumbnail: _.contains(this.thumbnailSizes, MediaThumbnailSizes.MEDIUM),
      showSmallThumbnail: _.contains(this.thumbnailSizes, MediaThumbnailSizes.SMALL)
    };
  }

  ui() {
    return {
      previewWrap: '.preview-wrap',
      thumbnailPreviews: '.thumbnail-preview, .thumbnail-preview-size'
    };
  }

  modelEvents() {
    return {
      'change:cropped': this._onChangeCropped,
      change: this._onChange
    };
  }

  onAttach() {
    this.ui.previewWrap.toggleClass('no-image', this.model == null);
  }

  _onChange() {
    const path = this.model.getFile().acquireUrl();
    this.ui.previewWrap.find('img').attr('src', path)
      .show();

    this._renderPreviewImages(this.model.get('cropped'));
  }

  _renderPreviewImages(cropped = {}) {
    this.ui.thumbnailPreviews.each((index, element) => {
      const rx = $(element).width() / cropped.width;
      const ry = $(element).height() / cropped.height;
      const width = Math.round(rx * this.model.get('originalDimensions').width);
      const height = Math.round(ry * this.model.get('originalDimensions').height);
      const left = Math.round(rx * cropped.x);
      const right = width - Math.round(rx * (cropped.x + cropped.width));
      const top = Math.round(ry * cropped.y);
      $(element).find('img')
        .css({
          width: `${ width }px`,
          height: `${ height }px`,
          marginLeft: this.isRtl ? null : `-${ left }px`,
          marginRight: this.isRtl ? `-${ right }px` : null,
          marginTop: `-${ top }px`
        });
    });
  }

  _onChangeCropped(imageMedia, cropped) {
    this._renderPreviewImages(cropped);
  }
}

module.exports = ImageCropImagePreviewsView;
