const XhrHeaders = require('XhrHeaders');

const AjaxHelpers = {

  getRequestHeader: (settings, header = '') => {
    const { headers = {} } = settings || {};
    return headers[header];
  },

  getResponseHeader: (xhr, header = '') => {
    try {
      const headerValue = xhr.getResponseHeader(header);
      return headerValue;
    } catch (error) {
      // nom nom nom
    }

    return undefined;
  },

  getApiErrorData: (options) => {
    const {
      xhr = { status: 0 },
      settings,
      exception,
      thrownError
    } = options || {};

    const data = {};

    if (settings != null) {
      Object.assign(data, {
        url: settings.url,
        data: settings.data,
        method: settings.type,
        token: AjaxHelpers.getRequestHeader(settings, XhrHeaders.REQUEST_TOKEN)
      });
    }

    const { status } = xhr;

    if (status === 0) {
      if (thrownError != null) {
        Object.assign(data, { thrownError });
      }
    } else {
      Object.assign(data, { status });

      if (exception != null) {
        Object.assign(data, {
          errorCode: exception.getErrorCode(),
          errorMessage: exception.getErrorMessage()
        });
      }
    }

    // TEMPORARY to help with 500s in GCP/Akamai world
    // check if the encrypted debug header is on the response
    // and add it's value as part of the data object
    const encryptedDebugHeadersValue = AjaxHelpers.getResponseHeader(xhr, XhrHeaders.ENCRYPTED_DEBUG);

    if (encryptedDebugHeadersValue) {
      Object.assign(data, { encryptedDebugHeadersValue });
    }

    return data;
  }
};

module.exports = AjaxHelpers;
