const AssessmentType = require('@common/data/enums/AssessmentType');

const AssessmentTopicItemView = require('@training/apps/training/views/assessments/AssessmentTopicItemView');
const AssessmentExamItemView = require('@training/apps/training/views/assessments/AssessmentExamItemView');
const AssessmentTaskItemView = require('@training/apps/training/views/assessments/AssessmentTaskItemView');
const EventEnrollmentItemView = require('@training/apps/training/views/assessments/EventEnrollmentItemView');
const EvaluationItemView = require('@training/apps/training/views/assessments/EvaluationItemView');

module.exports = {
  create(assessmentType, viewOptions = {}) {
    const AssessmentItemViewClass = (() => {
      switch (assessmentType) {
        case AssessmentType.FormalExamTraining:
          return AssessmentExamItemView;
        case AssessmentType.IntroductoryTraining:
        case AssessmentType.CertificationTraining:
        case AssessmentType.RefresherTraining:
        case AssessmentType.ExtraTraining:
          return AssessmentTopicItemView;
        case AssessmentType.TaskTraining:
          return AssessmentTaskItemView;
        case AssessmentType.EventEnrollment:
          return EventEnrollmentItemView;
        case AssessmentType.EvaluationTraining:
          return EvaluationItemView;
        default:
          throw new Error(`No AssessmentItemView class exists for this AssessmentType: '${ assessmentType }'`);
      }
    })();

    return new AssessmentItemViewClass(viewOptions);
  }
};
