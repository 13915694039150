const { Collection } = require('Backbone');

const Sync = require('@common/libs/Sync');

const MediaModel = require('@common/libs/file/MediaModel');
const FileHelpers = require('@common/libs/file/FileHelpers');
const MediaStatus = require('@common/libs/file/MediaStatus');
const TranscriptionModel = require('@common/libs/file/TranscriptionModel');

class VideoMedia extends MediaModel {
  static FIELDS = {
    CLOSED_CAPTIONS: 'closedCaptions'
  };

  constructor(attrs = {}, options = {}) {
    super(attrs, options);

    this._transcriptionsList = new Collection(attrs[VideoMedia.FIELDS.CLOSED_CAPTIONS], { model: TranscriptionModel });

    this.on(`change:${ VideoMedia.FIELDS.CLOSED_CAPTIONS }`, (model, ccs) => {
      this._transcriptionsList.reset(ccs);
    });

    this._transcriptionsList.on('update reset', (collection) => {
      this.set(VideoMedia.FIELDS.CLOSED_CAPTIONS, collection.toJSON());
    });
  }

  defaults() {
    return {
      closedCaptions: [],
      type: 'video'
    };
  }

  urlRoot() {
    return `${ super.urlRoot() }/video`;
  }

  canImportTranscription(language) {
    const transcription = this.getTranscriptionForLanguage(language);
    return transcription == null || transcription.canEdit();
  }

  hasTranscriptionForLanguage(language) {
    return this._transcriptionsList.findWhere({ language }) != null;
  }

  getTranscriptions() {
    return this.get(VideoMedia.FIELDS.CLOSED_CAPTIONS);
  }

  getTranscriptionForLanguage(language) {
    return this._transcriptionsList.findWhere({ language });
  }

  hasTranscriptionContentForLanguage(language) {
    const transcription = this.getTranscriptionForLanguage(language);
    return transcription != null && transcription.hasContent();
  }

  upsertTranscription(caption = {}) {
    const { language } = caption;

    if (this.hasTranscriptionForLanguage(language)) {
      const model = this.getTranscriptionForLanguage(language);
      model.set(caption);
      return model;
    }

    return this._transcriptionsList.add(caption);
  }

  removeTranscriptionForLanguage(language) {
    const transcription = this.getTranscriptionForLanguage(language);
    return this._transcriptionsList.remove(transcription);
  }

  importTranscriptions(lang, fileModel, saveOptions) {
    return FileHelpers.saveFile(fileModel, saveOptions)
      .then(() => {
        const model = this._transcriptionsList.create({
          uuid: fileModel.get('uuid')
        }, {
          url: `/axonify/media/video/${ this.get('uuid') }/cc/${ lang }/import`,
          wait: true,
          merge: true,
          shouldRetry: false
        });

        return model.getXHR(Sync.Method.CREATE);
      });
  }

  exportTranscriptions(lang) {
    return $.ajax({
      url: `/axonify/media/video/${ this.get('uuid') }/cc/${ lang }.vtt`,
      type: 'GET',
      shouldRetry: false,
      dataType: 'text'
    });
  }

  // Return `true` if all sizes are `done` processing
  // Return `false` as soon as one is found that is not `done` processing
  isDoneTranscoding() {
    return this.get('transcoding')?.status === MediaStatus.DONE;
  }

  isProcessed() {
    return this.isDoneTranscoding();
  }
}

module.exports = VideoMedia;
