const logging = require('logging');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
// The maximum time is 4 hours by default
const HEARTBEAT_DEFAULT_TIMEOUT = 4 * 60 * 60 * 1000;

class HeartbeatController {
  /**
   * @constructor
   * @param {Object} [options] - The options for the heartbeat controller
   * @param {number} [options.intervalMinutes] - The interval between each heartbeat request
   * @param {number} [options.maxTimeout] - The maximum time before the heartbeat stops automatically
   */
  constructor(options = {}) {
    this.ping = this.ping.bind(this);
    this.stopHeartbeat = this.stopHeartbeat.bind(this);
    this.isHeartbeatActive = false;

    // Set the timing
    this._intervalMinutes = options.intervalMinutes != null
      ? options.intervalMinutes
      : TenantPropertyProvider.get().getProperty('inactivityTimeoutMinutes') / 2;
    this._maxTimeout = options.maxTimeout != null ? options.maxTimeout : HEARTBEAT_DEFAULT_TIMEOUT;
  }

  startHeartbeat() {
    if (!this.isHeartbeatActive) {
      this.isHeartbeatActive = true;
      const interval = this._intervalMinutes * 60 * 1000;
      this.ping();
      this._heartbeatInterval = setInterval(this.ping, interval);
      if (this._maxTimeout !== Infinity) {
        // Setting timeout to Infinity will call the stopHeartbeat function immediately
        // Browsers store the delay as a 32-bit signed integer internally.
        // This causes an integer overflow when using delays over 2,147,483,647 ms (about 24.8 days)
        // This statement will skip setting the timeout since we don't want it to be called
        // Made so that MsTeams Session doesn't timeout
        this._maxHearbeatTimeout = setTimeout(this.stopHeartbeat, this._maxTimeout);
      }
    }
  }

  ping() {
    if (!apps.auth.session.user.isLoggedIn()) {
      return;
    }

    $.ajax({
      type: 'GET',
      url: '/axonify/ping',
      showSpinner: false,
      success: (resp) => {
        $(document).trigger('heartbeat');
        logging.info(`Successful ${ resp.message }`);
      },
      error: (resp) => {
        logging.error(`Error happened on ping: ${ resp }`);
      }
    });
  }

  stopHeartbeat() {
    clearInterval(this._heartbeatInterval);
    clearTimeout(this._maxHearbeatTimeout);
    this.isHeartbeatActive = false;
  }
}
module.exports = HeartbeatController;
