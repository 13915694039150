const { Model } = require('Backbone');
const logging = require('logging');

class ModalStatus extends Model {
  constructor(options = {}) {
    super(options);

    this.modalName = options.modalName || '';
  }

  apiEndpoint() {
    return '/modalViews';
  }

  fetch(options = {}) {
    options.data = Object.assign({}, options.data, {
      name: this.modalName
    });

    super.fetch(options);
  }

  setModalAsViewed() {
    // We have to do an explicit ajax call since this model doesn't have an `id`
    // and `isNew` returns false; `@save` would do a POST instead of PUT
    return $.ajax({
      type: 'PUT',
      url: `${ this.url() }?name=${ this.modalName }`,
      skipGlobalHandler: true,
      success: () => {
        logging.info('Successfully saved modal status');
        this.set('modalViewCount', this.get('modalViewCount') + 1);
      },
      error() {
        logging.error('Failed to save modal status');
      }
    });
  }
}

module.exports = ModalStatus;
