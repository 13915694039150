const UIKit = require('@training/widgets/UIKit');
const _ = require('underscore');
const logging = require('logging');

const ExternalWindowService = require('@common/services/externalWindow/ExternalWindowService');
const HeartbeatController = require('@common/libs/HeartbeatController');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

const SCORMBaseView = require('@training/apps/training/views/activities/pages/scorm/SCORMBaseView');
const SCORMStatusEnum = require('@common/data/enums/SCORMStatusEnum');
const SCORMNewWindowCardCollectionView = require('@common/components/training_modules/scorm/SCORMNewWindowCardCollectionView');
const SCORMActivityPersistanceStrategy = require('@training/libs/SCORMActivityPersistanceStrategy');

class SCORMNewWindowView extends SCORMBaseView {
  className() {
    return 'external-link-page ax-container scorm-new-window';
  }

  template = _.tpl(`\
    <div class="page-content">
      <p class="external-link-description"><%= descriptionText %></p>
      <div class="js-scorm-list-container"></div>
    </div>\
  `);

  templateHelpers() {
    return {
      descriptionText: HTMLHelpers.lineBreakToBr(HTMLHelpers.htmlEncode(this.activity.getModuleDescription()))
    };
  }

  constructor(options = {}) {
    super(options);

    this.heartbeatController = new HeartbeatController();

    this.onSCORMInitialize = this.onSCORMInitialize.bind(this);
    this.onSCORMFinish = this.onSCORMFinish.bind(this);
    this._onLaunchClicked = this._onLaunchClicked.bind(this);
    this.onFinalizeCommitCache = this.onFinalizeCommitCache.bind(this);
  }

  _createExternalWindowService() {
    logging.info('SCORMNewWindowView - _createExternalWindowService');

    try {
      this.SCORMExternalWindowService = new ExternalWindowService({
        windowOptions: {
          href: this.scorm.scoHref,
          windowId: 'Axonify_External_SCORM'
        },
        popupBlockedHandler: (message) => {
          logging.info('SCORMNewWindowView - popupBlockedHandler');
          window.app.layout.flash.error(message);
        }
      });
    } catch (error) {
      this.handleScormError(error, 'There was an exception rendering SCORM in new window.');
    }
  }

  onRender() {
    this._renderScoItems();
  }

  onComplete() {
    this.currentScoItemModel.set('status', SCORMStatusEnum.completed);
    super.onComplete();
  }

  areAllSubModulesComplete() {
    return this.cardCollectionView.collection.all((model) => {
      return model.get('status') === SCORMStatusEnum.completed;
    });
  }

  onClose() {
    logging.info('SCORMNewWindowView - onClose');
    super.onClose();

    this._closeExternalWindow();
    this.heartbeatController.stopHeartbeat();
  }

  _closeExternalWindow() {
    this.SCORMExternalWindowService?.closeWindow();
  }

  getErrorRegionSelector() {
    return '.page-content';
  }

  getScormConfig() {
    const scoItem = this.currentScoItemModel.get('scoItem');
    const isScormComplete = this.currentScoItemModel.get('status') === SCORMStatusEnum.completed;

    return Object.assign(super.getScormConfig(), {
      // onInitialize is called when the module starts communicating its state
      // more info about SCORM Events: https://scorm.com/scorm-explained/technical-scorm/run-time/
      onInitialize: this.onSCORMInitialize,
      // onFinish is called when the module finished communicating its state,
      // for example when closing the window in which is module is loaded
      onFinish: this.onSCORMFinish,
      // onFinalizeCommitCache is called every time there is a change in the commitCache
      // (commitCache represents the user progress, this is what is sent to the server to save progress)
      onFinalizeCommitCache: this.onFinalizeCommitCache,
      scormScoId: scoItem.id,
      scoValues: (scoItem.scormModuleAnswer != null ? scoItem.scormModuleAnswer.scoValues : undefined),
      lastModify: (scoItem.scormModuleAnswer != null ? scoItem.scormModuleAnswer.lastModify : undefined),
      duration: scoItem.scormModuleAnswer?.duration,
      scoHref: this.currentScoItemModel.get('href'),
      scormVersion: this.cardCollectionView.scormVersion,
      launchData: this.cardCollectionView.launchData,
      persistanceStrategy: new SCORMActivityPersistanceStrategy(this.activity, isScormComplete)
    });
  }

  _renderScoItems() {
    const scormPackage = this.activity.getScormPackage();

    this.cardCollectionView = new SCORMNewWindowCardCollectionView({
      scormPackage,
      childEvents: {
        'click:launch': this._onLaunchClicked
      }
    });
    this.setSubviewIn(this.cardCollectionView, {
      regionSelector: '.js-scorm-list-container',
      transition: UIKit.View.Transitions.NONE
    });
  }

  _onLaunchClicked(childView, {model}) {
    logging.info('SCORMNewWindowView - _onLaunchClicked');

    this.currentScoItemModel = model;
    this._createSCORM();

    // closing module because there is a progress save when closing.
    this._closeExternalWindow();
    this._createExternalWindowService();
    this.SCORMExternalWindowService?.openWindow();
  }

  onSCORMInitialize() {
    this.heartbeatController.startHeartbeat();
    if (this.currentScoItemModel.get('status') !== SCORMStatusEnum.completed) {
      this.currentScoItemModel.set('status', SCORMStatusEnum.started);
    }
  }

  onSCORMFinish() {
    this.heartbeatController.stopHeartbeat();
  }

  onFinalizeCommitCache(scoValues, lastModify, duration) {
    const scormModuleAnswer = this.currentScoItemModel.get('scoItem').scormModuleAnswer;
    this.currentScoItemModel.get('scoItem').scormModuleAnswer = scormModuleAnswer || {};
    Object.assign(this.currentScoItemModel.get('scoItem').scormModuleAnswer, {
      scoValues,
      lastModify,
      duration
    });
  }
}

module.exports = SCORMNewWindowView;
