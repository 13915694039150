import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  ImageList as MuiImageList,
  type ImageListTypeMap as MuiImageListTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxImageListCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxImageListTypeMap<P = object, D extends React.ElementType = 'ul'> = MuiImageListTypeMap<P & AxImageListCustomProps, D>;

export type AxImageListProps<
  D extends ElementType = AxImageListTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxImageListTypeMap<P & AxImageListCustomProps, D>, D>;

const styledOptions = {
  name: 'AxImageList'
};

const StyledAxImageList = styled(MuiImageList, styledOptions)<AxImageListProps>(() => {
  return {};
});

export const AxImageList: MuiOverridableComponent<AxImageListTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxImageListProps, ref: Ref<HTMLUListElement>) => {
  const ImageListProps: AxImageListProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxImageList { ...ImageListProps } ref={ ref }>
      { children }
    </StyledAxImageList>
  );
});

export default AxImageList;
