const { LayoutView } = require('Marionette');

const CONTENT_REGION = 'contentRegion';

/*
  DO NOT EXTEND THIS CLASS!!
  If you need to change something up, create a new View class instead.
*/

class LoadingSkeletonView extends LayoutView {

  static CONTENT_REGION = CONTENT_REGION;

  className() {
    return 'loading-skeleton-container';
  }

  getTemplate() {
    return false;
  }

  onRender() {
    this.addRegion(CONTENT_REGION, { el: this.$el });
  }
}

module.exports = LoadingSkeletonView;
