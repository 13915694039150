// Module
var code = `<div class="pad">
  <h1 class="clearfix">
    <span class="icon icon-male"></span>
    <span class="h1title"><%- t('selectGame.gameChallenge.howTo.title') %></span>
  </h1>

  <h2><%- t('selectGame.gameChallenge.howTo.howTitle') %></h2>
  <div class="general-description"><%- t('selectGame.gameChallenge.howTo.description') %></div>

  <% if (gameChallengeWager > 0) { %>
  <h2><%- t('selectGame.gameChallenge.howTo.wagerTitle') %></h2>
  <div class="wagering-description"><%- t('selectGame.gameChallenge.howTo.wagering') %></div>
  <% } %>
</div>
`;
// Exports
module.exports = code;