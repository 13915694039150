const Backbone = require('Backbone');
const TranslatableString = require('@common/data/models/translationStrings/TranslatableString');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');
const RteView = require('@common/components/discover/edit/RteView');

class DescriptionRteView extends RteView {
  behaviors() {
    return {};
  }

  initialize(options = {}) {
    super.initialize(options);

    const basicButtons = ['bold', 'italic', 'underline', 'insertLink'];

    this.scrollableContainer = '.editor-container';
    this.customToolbarButtons = {
      moreText: {
        buttons: basicButtons,
        buttonsVisible: basicButtons.length
      }
    };

    this.toolbarsEnabled = false;
    this.htmlAllowedTags = ['a', 'b', 'strong', 'i', 'em', 'u', 'p', 'div', 'br'];
    this.htmlAllowedAttrs = ['title', 'href', 'alt', 'target'];
    this.wordPasteEnabled = false;
    this.charCounterMax = 1024;

    ({
      model: this.model,
      isLocalizableString: this.isLocalizableString,
      translatedLanguage: this.translatedLanguage
    } = options.options);

    this.defaultValue = '';
    this.translationString = this._getDefaultForLanguage(this.translatedLanguage);

    this.state = new Backbone.Model();

    this.listenTo(this.state, 'change:value', (model, value) => {
      if (value === '') {
        delete this.translationString.getValues()[this.translatedLanguage];
      } else {
        this.translationString.setValueForLanguage(this.translatedLanguage, value);
      }
    });
  }

  onRteInitialized() {
    this.updateSelectedLanguage(this.translatedLanguage);
  }

  getTemplate() {
    return `<label for="description"><%- t('communitiesManagement.communityDescription') %></label>
        <div class="description editor-container froala-editor qa-rte-description" data-field="value"></div>
        <p class="translatable-name__limit"><%- t('translatableName.characterLimit', { maxLength: 1024 }) %></p>`;
  }

  _updateDocumentList() {
    // Empty implementation to disable the method
  }

  loadPageDetails() {
    // Empty implementation to disable the method
  }

  clearError() {
     // Empty implementation to disable the method
  }

  getError() {
     // Empty implementation to disable the method
  } 

  _getDefaultForLanguage(langCode) {
    let StringType;
    if (this.isLocalizableString === true) {
      StringType = LocalizableString;
    } else {
      StringType = TranslatableString;
    }

    const defaultValue = new StringType();
    defaultValue.setValueForLanguage(langCode, '');
    return defaultValue;
  }

  onEditorTextChange() {
    super.onEditorTextChange();

    if (this.editor) {
      const description = this.editor.html.get();
      const descriptions = this.model.get('descriptions');
    
      descriptions.setValueForLanguage(this.translatedLanguage, description);
      this.model.set('descriptions', descriptions);
    }
  }

  setValue(value) {
    if (value != null) {
      this.translationString.set(value.attributes);
    } else {
      this.translationString = this._getDefaultForLanguage(this.translatedLanguage);
    }
  }

  getValue() {
    if (this.editor) {
      const currentValue = this.editor.html.get();
      this.translationString.setValueForLanguage(this.translatedLanguage, currentValue);
    }

    return this.translationString;
  }

  updateSelectedLanguage(selectedLanguage) {
    this.translatedLanguage = selectedLanguage;
    if (this.translationString === this.defaultValue) {
      this.translationString = this._getDefaultForLanguage(this.translatedLanguage);
    }
    const translationValue = this.translationString.getValueForLanguage(this.translatedLanguage, false);

    this.updateDataEditorFields(translationValue);
  }

  updateDataEditorFields(translationValue) {
    if (this.editor != null && this.editor.html.get() !== translationValue) {
      this.editor.html.set(translationValue);
    }
  }
}

module.exports = DescriptionRteView;
