const {ItemView} = require('Marionette');
const CharacterCountView = require('@common/components/discover/views/CharacterCountView');
const I18n = require('@common/libs/I18n');

const EDITOR_NOTES_MAX_CHAR_COUNT = 100;

class EditorNotesView extends ItemView {
  getTemplate() {
    return `<div class="metadata__input">
      <h4><%- t('discover.metadata.editNote') %></h4>
      <div class="editorNotes"></div>
    </div>
    `;
  }

  ui() {
    return {
      editorNotes: '.editorNotes'
    };
  }

  onRender() {
    this.editorNotesCharacterCountView = new CharacterCountView({
      textareaId: 'editComment',
      el: this.ui.editorNotes,
      extraCSSClasses: 'edit-notes qa-edit-notes-textarea',
      extraCharacterCountClasses: 'qa-editor-notes-character-count',
      maxCharacterCount: EDITOR_NOTES_MAX_CHAR_COUNT,
      placeHolderI18nKey: 'discover.metadata.editNote'
    });
  }

  commit() {
    if (this.editorNotesCharacterCountView.getValue().length > EDITOR_NOTES_MAX_CHAR_COUNT) {
      return [I18n.t('errors.Article.required.editComment')];
    }
    this.model.currentVersion.set({
      editComment: this.editorNotesCharacterCountView.getValue()
    });

    return [];
  }

  destroy() {
    this.editorNotesCharacterCountView.destroy();
  }
}
module.exports = EditorNotesView;
