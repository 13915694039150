const {
  CollectionView,
  ItemView
} = require('Marionette');
const ReportsTableItem = require('@training/apps/search/reports/pages/dashboard/ReportsTableItem');

class EmptyRowView extends ItemView {
  get tagName() {
    return 'tr';
  }

  getTemplate() {
    return '<td colspan="10" class="empty-view"><%- t(\'general.empty\') %></td> ';
  }
}

class ReportsTableCollectionView extends CollectionView {
  initialize(options = {}) {
    ({
      collection: this.collection,
      linkToPage: this.linkToPage,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount
    } = options);
  }

  onAttach() {
    this.$el.prepend('<div class="tile-fade"></div>');
  }

  getChildView() {
    return ReportsTableItem;
  }

  childViewOptions() {
    return {
      linkToPage: this.linkToPage,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount
    };
  }

  tagName() {
    return 'tbody';
  }

  childViewContainer() {
    return 'tr';
  }

  getEmptyView() {
    return EmptyRowView;
  }
}

module.exports = ReportsTableCollectionView;
