const { omit } = require('underscore');
const Promise = require('bluebird');

class CSSLoader {

  constructor(cssInjector) {
    this._cssInjector = cssInjector;
    this._packageFactories = [];
  }

  getPackages(targetFactoryIds = ['all'], options) {
    const getAll = targetFactoryIds.includes('all');

    return this._packageFactories.reduce((memo, config) => {
      if (getAll || targetFactoryIds.includes(config.key)) {
        return memo.concat(config.create(options));
      }

      return memo;
    }, []);
  }

  registerPackageFactory(packageFactory = {}) {
    this._packageFactories.push(packageFactory);
  }

  deregisterPackageFactory(packageFactory = {}) {
    this._packageFactories = this._packageFactories.filter((config) => {
      return config.key !== packageFactory.key;
    });
  }

  load(keys = [], options = {}) {
    keys = [].concat(keys);

    const injectPackages = this.getPackages(keys, omit(options, 'pivot'));
    const injectPromises = injectPackages.map((cssPackage) => {
      return this._cssInjector.inject(cssPackage, options.pivot);
    });

    return Promise.all(injectPromises);
  }
}

module.exports = CSSLoader;
