const _ = require('underscore');
const Backbone = require('Backbone');

const _sync = Backbone.sync;
const _clear = Backbone.Model.prototype.clear;
const _reset = Backbone.Collection.prototype.reset;

const Sync = {};

Sync.Method = {
  ANY: 'any',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  PATCH: 'patch',
  DELETE: 'delete'
};

Sync.XHRState = {
  PENDING: 'pending',
  RESOLVED: 'resolved',
  FAILED: 'failed'
};

const _validMethods = Object.values(Sync.Method);

const _syncMutatorMethods = [
  'setXHR',
  'setXHRAjaxOptions',
  'resetXHR',
  'incrementSyncCount',
  'resetSyncCount'
];

Object.assign(Sync, {
  /*
    Public static sync utility methods
  */


  // Sets up proxying of the xhr data to other/"child" entities that have their sync lifecycles
  // tied to this entity.
  proxySyncMutatorMethods(entity = null, childEntity = null) {
    // Guard against non existant entities or entities that don't have the hasSyncCrudUtilities methods
    if ((entity == null) || !entity.hasSyncCrudUtilities || (childEntity == null) || !childEntity.hasSyncCrudUtilities) {
      return;
    }

    // Make sure the childEntity is preset with the the current entity's sync state
    $.extend(true, childEntity._getSyncObj(), entity._getSyncObj());

    for (const syncMutatorMethod of _syncMutatorMethods) {
      if (_.isFunction(childEntity[syncMutatorMethod])) {
        entity._getSyncProxyHandler(syncMutatorMethod).add(childEntity[syncMutatorMethod].bind(childEntity));
      }
    }
  },

  // Helper method that takes any number of model/collection entities and calls the callback when they're all done
  whenDone(entities, callback, method = Sync.Method.READ) {
    const xhrs = _.chain([entities])
      .flatten()
      .invoke('getXHR', method)
      .value();

    return $.when(...xhrs).done(() => {
      if (_.isFunction(callback)) {
        callback();
      }
    });
  },


  /*
    Private utility methods for dealing with the CRUD methods.
  */
  crudUtilities: {
    hasSyncCrudUtilities: true,

    // Verifies the method name is valid
    _assertValidMethod(method) {
      if (_validMethods.includes(!method)) {
        throw new Error(`Invalid method used: ${ method }!!! Valid options are: ${ _validMethods.join(',') }.`);
      }
    },

    // Capitalizes the method string passed in
    _capitalizeMethod(method = '') {
      return method.charAt(0).toUpperCase() + method.toLowerCase().slice(1);
    },

    // Checks the xhr's state against the one passed in
    _checkXHRState(xhr, state) {
      return xhr && xhr.state() === state;
    },

    // Transforms the method into a consistent string
    _getKey(prefix = '', method) {
      this._assertValidMethod(method);

      const methodStr = (method === Sync.Method.ANY) ? '' : method;

      return `${ prefix }${ this._capitalizeMethod(methodStr) }`;
    },

    // Gets the corresponding method key for the xhr references
    _getXHRKey(method) {
      return this._getKey('xhr', method);
    },

    // Gets the corresponding method key for the syncCount references
    _getSyncCountKey(method) {
      return this._getKey('syncCount', method);
    },

    _getSyncProxyKey(syncMethodName) {
      return `proxy${ this._capitalizeMethod(syncMethodName) }`;
    },

    // Retrieves the sync data object and ensures it exists
    _getSyncObj() {
      return this._syncData != null ? this._syncData : (this._syncData = {});
    },

    _getSyncProxyObj() {
      return this._syncProxyObj != null ? this._syncProxyObj : (this._syncProxyObj = {});
    },

    _getSyncProxyHandler(syncMethodName) {
      const syncProxyObj = this._getSyncProxyObj();
      const syncProxyKey = this._getSyncProxyKey(syncMethodName);

      return syncProxyObj[syncProxyKey] != null ? syncProxyObj[syncProxyKey] : (syncProxyObj[syncProxyKey] = $.Callbacks( 'unique' ));
    },

    _getSyncValue(key) {
      return this._getSyncObj()[key];
    },

    _setSyncValue(key, value) {
      this._getSyncObj()[key] = value;
    },

    _deleteSyncValue(key) {
      delete this._getSyncObj()[key];
    },

    // Makes sure the counter reference is initialized to 0 and increments it.
    _incrementSyncCount(method) {
      const syncObj = this._getSyncObj();
      const syncCountKey = this._getSyncCountKey(method);

      if (syncObj[syncCountKey] == null) {
        syncObj[syncCountKey] = 0;
      }

      syncObj[syncCountKey]++;
    },

    defaultSyncClearOptions: {
      clearXHR: true,
      clearSyncCount: true
    },

    /*
      Public API that gets exposed to the model and collection prototypes
    */
    clearSyncData(options = {}) {
      _.defaults(options, _.result(this.defaultSyncClearOptions != null ? this.defaultSyncClearOptions : {}));

      if (options.clearXHR) {
        this.resetXHR();
      }

      if (options.clearSyncCount) {
        this.resetSyncCount();
      }
    },


    // Utility functions to query the state of the various xhr
    // references.
    hasXHR(method = Sync.Method.ANY) {
      return this.getXHR(method) != null;
    },

    getXHR(method = Sync.Method.ANY) {
      return this._getSyncValue(this._getXHRKey(method));
    },

    setXHR(method = Sync.Method.ANY, xhr) {
      this._setSyncValue(this._getXHRKey(method), xhr);
      this._getSyncProxyHandler('setXHR').fire(method, xhr);
    },

    setXHRAjaxOptions(method = Sync.Method.ANY, ajaxOptions = {}) {
      const xhr = this.getXHR(method);
      if (xhr != null) {
        xhr._ajaxOptions = ajaxOptions;
      }
      this._getSyncProxyHandler('setXHRAjaxOptions').fire(method, ajaxOptions);
    },

    getXHRAjaxOptions(method = Sync.Method.ANY) {
      const xhr = this.getXHR(method) || {};
      return xhr._ajaxOptions || {};
    },

    resetXHR(method = Sync.Method.ANY) {
      const methods = [].concat(method === Sync.Method.ANY ? _validMethods : method);

      _.each(methods, (methodItem) => {
        this._deleteSyncValue(this._getXHRKey(methodItem));
      });

      this._getSyncProxyHandler('resetXHR').fire(method);
    },

    abortXHR(method = Sync.Method.ANY) {
      if (this.isXHRPending(method)) {
        this.getXHR(method).abort();
      }
    },

    isXHRPending(method = Sync.Method.ANY) {
      return this._checkXHRState(this.getXHR(method), Sync.XHRState.PENDING);
    },

    isXHRDone(method = Sync.Method.ANY) {
      return this._checkXHRState(this.getXHR(method), Sync.XHRState.RESOLVED);
    },

    isXHRFailed(method = Sync.Method.ANY) {
      return this._checkXHRState(this.getXHR(method), Sync.XHRState.FAILED);
    },


    // Utility functions to manage and query the 'synced', 'synced once',
    // and 'synced multiple' states.
    incrementSyncCount(method = Sync.Method.ANY) {
      if (method !== Sync.Method.ANY) {
        this._incrementSyncCount(Sync.Method.ANY);
      }

      this._incrementSyncCount(method);
      this._getSyncProxyHandler('incrementSyncCount').fire(method);
    },

    resetSyncCount(method = Sync.Method.ANY) {
      const methods = [].concat(method === Sync.Method.ANY ? _validMethods : method);

      _.each(methods, (methodItem) => {
        return this._deleteSyncValue(this._getSyncCountKey(methodItem));
      });

      this._getSyncProxyHandler('resetSyncCount').fire(method);
    },

    getSyncCount(method = Sync.Method.ANY) {
      return this._getSyncValue(this._getSyncCountKey(method)) || 0;
    },

    isSynced(method = Sync.Method.ANY) {
      const syncCount = this.getSyncCount(method);
      return (syncCount != null) && (syncCount > 0);
    },

    isSyncedOnce(method = Sync.Method.ANY) {
      return this.getSyncCount(method) === 1;
    },

    isSyncedMultiple(method = Sync.Method.ANY) {
      return this.getSyncCount(method) > 1;
    },

    proxySyncMutatorMethods(childEntity) {
      Sync.proxySyncMutatorMethods(this, childEntity);
    }
  }
});


Object.assign(Backbone.Model.prototype, Sync.crudUtilities, {
  clear(options = {}) {
    const attrs = _clear.call(this, options);

    // Clearing a model wipes out its id as well so we'll want
    // to clear the syncData associated with that id too.
    this.clearSyncData(options);

    return attrs;
  }
});

/*
  Extend Backbone.Collection with the crudUtilities as well as an override for reset() so the syncData is cleared
  properly along with the rest of the Collection.
*/

Object.assign(Backbone.Collection.prototype, Sync.crudUtilities, {
// Collections tend to be reset with new data manually a lot so
// the default here is false.
  defaultSyncClearOptions: {
    clearXHR: false,
    clearSyncCount: false
  },

  reset(array, options = {}) {
    const models = _reset.call(this, array, options);

    this.clearSyncData(options);

    return models;
  }
});

/*
  Private API used with ajax events when running Backbone.sync
*/

Object.assign(Sync, {
  actions: {
    syncStart(xhr, ajaxOptions = {}) {
      // Clear out old xhr references since this new one will supplant it but leave the
      // syncCounts alone.
      this.resetXHR(Sync.Method.ANY);

      const method = ajaxOptions._method;

      // Store the xhr on the entity for later querying
      this.setXHR(Sync.Method.ANY, xhr);

      // Store the xhr on the entity on a per method basis for later querying ie. _xhrCreate
      this.setXHR(method, xhr);

      // Store the ajaxOptions on the entity's xhr
      this.setXHRAjaxOptions(method, ajaxOptions);

      this.trigger('sync:start:any', this, ajaxOptions);
      this.trigger(`sync:start:${ method }`, this, ajaxOptions);
    },

    syncSuccess(data, textStatus, xhr) {
      const ajaxOptions = xhr._ajaxOptions;
      this.incrementSyncCount(ajaxOptions._method);
    },

    syncStop(xhr) {
      const ajaxOptions = xhr._ajaxOptions;
      this.trigger(`sync:stop:${ ajaxOptions._method }`, this, ajaxOptions);
      this.trigger('sync:stop:any', this, ajaxOptions);
    },


    extendRequestOptions(method, entity, options = {}) {
      return Object.assign(options, {
        // Keep track of the method so it can be used in beforeSend and complete
        _method: method,

        // Trigger the sync:start before any other beforeSend function can run
        // to get the absolute start of the sync call.
        beforeSend: _.wrap(options.beforeSend, (beforeSend = () => {}, ...args) => {
          Sync.actions.syncStart.apply(entity, args);
          return beforeSend.apply(this, args) && true;
        }),

        // Increment the sync counter to differentiate between 'synced', 'synced once',
        // and 'synced multiple' states.
        success: _.wrap(options.success, (success = () => {}, ...args) => {
          Sync.actions.syncSuccess.apply(entity, args);
          success.apply(this, args);
        }),

        // Trigger the sync:stop after any other complete function runs to get
        // the absolute end of the sync call.
        complete: _.wrap(options.complete, (complete = () => {}, ...args) => {
          complete.apply(this, args);
          Sync.actions.syncStop.apply(entity, args);
        })
      });
    }
  }
});

/*
  Extend Backbone with the new Backbone.Sync.sync() to wrap the sync ajax events
*/
Backbone.sync = function(method, entity, options = {}) {
  const extendedOptions = Sync.actions.extendRequestOptions(method, entity, options);
  return _sync(method, entity, extendedOptions);
};

module.exports = Sync;
