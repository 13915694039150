const { ItemView } = require('Marionette');

const IconBadgeClassModifiers = require('@common/components/iconBadge/IconBadgeClassModifiers').default;

class IconBadgeView extends ItemView {
  className() {
    return `icon--badge ${ this.getOption('badgeModifierClasses').join(' ') } ${ this.getOption('iconClass') }`;
  }

  attributes() {
    if (this.iconLabel) {
      return {
        'aria-label': this.iconLabel,
        role: 'img'
      };
    }

    return {
      'aria-hidden': true
    };
  }

  options() {
    return {
      iconClass: '',
      badgeModifierClasses: []
    };
  }

  getTemplate() {
    return super.getTemplate() || false;
  }

  preinitialize({ badgeModifierClasses } = {}) {
    badgeModifierClasses.forEach(IconBadgeClassModifiers.assertLegalValue);
  }
}

module.exports = IconBadgeView;
