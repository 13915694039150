const { ItemView } = require('Marionette');

class PretextHeaderTitle extends ItemView {
  className() {
    return 'subtext-header-title qa-subtext';
  }

  getTemplate() {
    return `
      <p class="<%= pretextClass %> qa-subtext-text"><%= pretext %></p>
      <<%= titleTag %> class="<%= titleClass %>"><%= title %></<%= titleTag %>>
    `;
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      pretext: this.getOption('pretext'),
      titleClass: this._getTitleClass(),
      titleTag: this.getOption('titleTag') || 'p',
      pretextClass: this.getOption('pretextClass')
    };
  }

  _getTitleClass() {
    let titleClass = 'qa-subtext-title';
    if (this.getOption('titleClass')) {
      titleClass += ` ${ this.getOption('titleClass') }`;
    }
    return titleClass;
  }
}

module.exports = PretextHeaderTitle;
