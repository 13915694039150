import {
  type Ref,
  forwardRef
} from 'react';
import { styled } from '@mui/material';
import {
  DataGrid as MuiDataGrid,
  type DataGridProps as MuiDataGridProps
} from '@mui/x-data-grid';

type AxDataGridProps = MuiDataGridProps;

const styledOptions = {
  name: 'AxDataGrid'
};

const StyledAxDataGrid = styled(MuiDataGrid, styledOptions)<AxDataGridProps>(() => {
  // TODO - update styles to align with UI Kit buttons
  return {
    label: {
      marginBottom: 0
    }
  };
});

export const AxDataGrid = forwardRef(({
  ...otherProps
}: AxDataGridProps, ref: Ref<HTMLDivElement>) => {
  const DataGridProps: AxDataGridProps = {
    ...otherProps
  };

  return (
    <StyledAxDataGrid { ...DataGridProps } ref={ ref } />
  );
});

export default AxDataGrid;
