// Module
var code = `<%= axSelect({
  ariaLabel: t('selfRegistration.securityQuestion', {i: id}),
  autocomplete: 'off',
  classNameSelect: 'qa-securityquestion' + id,
  data: { shallow: 'true', field: 'recoveryQuestion', options: 'recoveryOptions' },
  fullWidth: true,
  id: 'securityquestion' + id,
  name: 'securityquestion',
  placeholder: t('securityQuestions.select')
}) %>
<div class="clear"></div>
<div>
  <input type="text" data-field="recoveryAnswer" id="securityanswer<%- id %>"
    placeholder="<%- t('selfRegistration.enterSecurityAnswer') %>" autocomplete="off"
    aria-label="<%- t('selfRegistration.securityAnswer', {i: id}) %>" />
</div>
`;
// Exports
module.exports = code;