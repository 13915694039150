const I18n = require('@common/libs/I18n');
const TagsView = require('@training/apps/search/communitiesManagement/tags/TagsView');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const PageableTableController = require('@common/components/pageableList/PageableTableController');
const TagsTableItemView = require('@training/apps/search/communitiesManagement/tags/TagsTableItemView');
const TagsSearchView = require('@training/apps/search/communitiesManagement/tags/TagsSearchView');

class TagsTabController extends LayoutController {
  initialize(options = {}) {
    ({
      managementPageState: this.managementPageState,
      tagList: this.tagList
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: TagsView
    };
  }

  regionControllers() {
    const regions = {
      searchRegion: {
        viewDefinition: {
          ViewClass: TagsSearchView,
          managementPageState: this.managementPageState,
          tagList: this.tagList
        }
      },
      tagsRegion: {
        ViewControllerClass: PageableTableController,
        itemViewClass: TableLayoutView,
        rowItemView: TagsTableItemView,
        collection: this.tagList,
        headerNames: [
          I18n.t('communitiesManagement.tags.tagName'),
          I18n.t('learnerTrainingPage.catalog.filter.filter'),
          I18n.t('communitiesManagement.tags.timesUsed'),
          I18n.t('communitiesManagement.actions')
        ],
        delegateEvents: {
          'view:inflate': () => {
            this.listenTo(this.tagList, 'destroy', () => {
              this.tagList.search(this.managementPageState.get('searchData'), 0);
            });
          }
        }
      }
    };

    return regions;
  }
}

module.exports = TagsTabController;
