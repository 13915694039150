const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'ScheduledLearningEventsAvailable',
  'NoScheduledLearningEventsAvailable',
  'ManagerEnrollmentRequired',
  'Enrolled',
  'OnWaitlist',
  'Attended',
  'AttendanceNotTaken'
]);
