const { defaults } = require('underscore');

const { ItemView } = require('Marionette');

const toastr = require('toastr');

const I18n = require('@common/libs/I18n');

const MIN_DISMISS_DELAY = 2000;

const MAX_TOAST_COUNT = 3;

const defaultToastrOptions = {
  positionClass: 'toast-axonify-position',
  newestOnTop: false,
  preventDuplicates: true,
  hideDuration: 750,
  closeDuration: 250
};

require('@common/components/flash/flash.less');

class Flash extends ItemView {
  getTemplate() {
    return false;
  }

  constructor(options = {}) {
    super(options);

    this.display = this.display.bind(this);
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
    this.warning = this.warning.bind(this);
    this.info = this.info.bind(this);

    this._maxToastCount = options.maxToastCount != null ? options.maxToastCount : MAX_TOAST_COUNT;
    this._toastStack = [];
  }

  display(options = {}) {
    const displayOptions = defaults({}, options, {
      cssClass: 'info',
      message: ''
    });

    const toastType = displayOptions.cssClass;

    const toastOptions = {
      title: displayOptions.title,
      message: displayOptions.message,
      timeOut: displayOptions.dismissDelay,
      onclick: displayOptions.onclick,
      onHidden: displayOptions.onHidden
    };

    this[toastType](toastOptions);
  }

  success(options = {}) {
    const flashOptions = defaults({}, options, { title: I18n.t('general.success') });

    const toastrOptions = this._extendWithDefaultOptions(flashOptions);

    const message = toastr.success(flashOptions.message, flashOptions.title, toastrOptions);

    this._replaceTitleDivToH2();

    this._pushToast(message);

    this._trimToastStack();
  }

  error(options = {}) {
    const flashOptions = defaults({}, options, { title: I18n.t('general.error') });

    const toastrOptions = this._extendWithDefaultOptions(flashOptions);

    const message = toastr.error(flashOptions.message, flashOptions.title, toastrOptions);

    this._replaceTitleDivToH2();

    this._pushToast(message);

    this._trimToastStack();
  }

  warning(options = {}) {
    const flashOptions = defaults({}, options, { title: I18n.t('general.warning') });

    const toastrOptions = this._extendWithDefaultOptions(flashOptions);

    const message = toastr.warning(flashOptions.message, flashOptions.title, toastrOptions);

    this._replaceTitleDivToH2();

    this._pushToast(message);

    this._trimToastStack();
  }

  info(options = {}) {
    const flashOptions = defaults({}, options, { title: I18n.t('general.info') });

    const toastrOptions = this._extendWithDefaultOptions(flashOptions);

    const message = toastr.info(flashOptions.message, flashOptions.title, toastrOptions);

    this._replaceTitleDivToH2();

    this._pushToast(message);

    this._trimToastStack();
  }

  clear() {
    toastr.clear();
  }

  _extendWithDefaultOptions(options = {}) {
    return defaults({}, options, defaultToastrOptions, this._getComputedDefaults(options));
  }

  _getComputedDefaults(options = {}) {
    const timeOut = this._getOptionTimeout(options);

    return {
      target: this.$el,
      timeOut: timeOut,
      extendedTimeOut: timeOut / 2,
      rtl: I18n.isCurrentLanguageRtl()
    };
  }

  _getOptionTimeout(options = {}) {
    const {
      timeOut,
      title = '',
      message = ''
    } = options;

    return timeOut || this._computeDismissDelay(title, message);
  }

  // base the dismiss delay value on the average speed a user could read the msg which is about 3 wps.
  _computeDismissDelay(title = '', message = '') {
    const text = `${ title } ${ message }`.trim();
    return MIN_DISMISS_DELAY + Math.ceil((text.split(' ').length / 3) * 1000);
  }

  _pushToast($toast) {
    if ($toast != null) {
      this._toastStack.push($toast);
    }
  }

  _trimToastStack() {
    if (this._toastStack.length > this._maxToastCount) {
      const $oldestToast = this._toastStack.shift();
      toastr.clear($oldestToast);
    }
  }

  onDestroy() {
    this.clear();
  }

  _replaceTitleDivToH2() {
    const $divTitleTag = this.$('div.toast-title');
    const $h2Element = $('<h2></h2>');

    const $spanAttributes = $divTitleTag.prop('attributes');

    // loop through all <span> attributes and apply them on new <h2>
    $.each($spanAttributes, (index, attribute) => {
      $h2Element.attr(attribute.name, attribute.value);
    });

    $h2Element.addClass('qa-toast-title');

    this.$('div.toast-title').replaceWith($($h2Element).append($divTitleTag.contents()));
  }
}

module.exports = Flash;
