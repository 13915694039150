const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');
require('@common/components/forms/editors/pillSelect/Form.Editor.PillSelect');

class ArticleReportModal extends Marionette.LayoutView {
  constructor(options = {}) {
    super(options);

    ({
      articleModel: this.articleModel
    } = options);
  }

  getTemplate() {
    return require('@training/apps/articles/modals/ArticleReportModal.html');
  }

  className() {
    return 'page-wrapper parent-height';
  }

  onRender() {
    const buttons = [
      {
        type: 'customText',
        text: I18n.t('general.cancel'),
        className: 'white',
        onClick: this.destroy.bind(this)
      },
      {
        type: 'customText',
        text: I18n.t('discover.reportLabel'),
        onClick: this._commitForm.bind(this)
      }
    ];

    this.actionBar.setActionBar({ buttons });

    this.reportForm = new Form({
      el: this.$('#report-form'),
      model: this.model,
      context: {
        pageReportTypeListOptions: {
          collection: this.collection,
          pillOptions: {
            isSingleSelect: true
          }
        }
      }
    });
  }

  _commitForm() {
    const errors = this.reportForm.commit();

    if (errors) {
      window.app.layout.flash.error(errors);
    } else {
      this._reportArticle();
    }
  }

  _reportArticle() {
    this._reportDfr = this.articleModel.currentVersion.report({
      data: this.model.toJSON(),
      skipGlobalHandler: true
    }).done((response) => {
      // response should be an Agreed object that contains id so set the
      // model's existingAgreeId to this id
      this.articleModel.currentVersion.existingAgreeId = response.entity.id;

      this.articleModel.currentVersion.set('pageReport', response.entity);

      // on reporting an article, if there is a banner for publishing we should hide that. It would be
      // gone on page reload anyways.
      $('.inReview-container').hide();

      window.app.layout.flash.success(
        I18n.t(`discover.pageTypes.${ this.articleModel.get('type') }.report.success`, this.articleModel.toJSON())
      );
    })
      .fail((xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const errCode = exception.getErrorCode();

        // Users access rights have changed, flash error and go back to viewing only
        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
          window.app.layout.flash.error(
            I18n.t('discover.pageAccess.error.3017')
          );
        // page has already been reported. refetch model to update state.
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_DUPLICATE_ENTITY) {
          this.articleModel.fetch();

          window.app.layout.flash.success(
            I18n.t(`discover.pageTypes.${ this.articleModel.get('type') }.report.success`, this.articleModel.toJSON())
          );
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
          window.app.layout.flash.error(
            I18n.t(`discover.pageTypes.${ this.articleModel.get('type') }.error.${ errCode }`)
          );
        } else {
          window.app.layout.flash.error(
            I18n.t('errors.genericError')
          );
        }
      });

    this.destroy();
  }
}
module.exports = ArticleReportModal;
