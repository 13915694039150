const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const BreadcrumbCollection = require('@common/data/collections/BreadcrumbCollection');
const breadcrumbs = new BreadcrumbCollection();

const getBreadcrumbs = (breadcrumbPage, onClickCallback) => {
  const crumbs = [
    new Backbone.Model({
      title: I18n.t('communitiesManagement.tabs.communities'),
      target: '#hub/search/type-all/1/'
    }),
    new Backbone.Model({
      title: I18n.t('discover.insights.communicationInsightsTitle'),
      target: (includeTeamBasedCommunities) => {
        return `#hub/search/communicationsInsights${ includeTeamBasedCommunities ? '-includeTeamBasedCommunities' : '' }`;
      }
    })
  ];

  if (breadcrumbPage === 'overview') {
    crumbs.pop();
  }

  breadcrumbs.setBreadcrumbs(crumbs);

  return {
    viewDefinition: {
      ViewClass: BreadcrumbCollectionView,
      collection: breadcrumbs,
      hideLastCrumb: false,
      linkLastCrumb: true,
      doOnClick: (model) => {
        if (model && model.get('target')) {
          onClickCallback(model);
        }
      }
    }
  };
};


module.exports = {
  getBreadcrumbs
};
