const File = require('@common/libs/file/File');

const VideoFileHelpers = require('@common/libs/helpers/app/VideoFileHelpers');

class VideoFile extends File {

  static SIZE_LIMIT = 2000; // in MB

  initialize(attrs, options = {}) {
    super.initialize(attrs, options);

    this.sizeLimit = VideoFile.SIZE_LIMIT;
    this.fileType = 'video';
    this.allowedExtensions = options.allowedExtensions || VideoFileHelpers.allowedVideoExtensions;
  }

  isPreviewable() {
    return this.hasNativeFile() || this.has('path');
  }
}

module.exports = VideoFile;
