const Marionette = require('Marionette');
const AttachmentCardView = require('@training/apps/training/views/AttachmentCardView');

class AttachmentListView extends Marionette.CollectionView {
  className() {
    return 'attachment-list';
  }

  getChildView() {
    return AttachmentCardView;
  }
}

module.exports = AttachmentListView;
