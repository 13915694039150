const Backbone = require('Backbone');
const RecentGameChallenge = require('../models/RecentGameChallenge');

class RecentChallengesList extends Backbone.Collection {
  apiEndpoint() {
    return '/games/challenges/recent';
  }

  preinitialize() {
    this.model = (challenge) => {
      const gameChallenge = new RecentGameChallenge(challenge);
      // The server return a `challenger` and `challengee` but we don't know which
      // one this user is, so we'll determine by using the logged in user's `id`
      return gameChallenge.determineUserResult(this.user.id);
    };
  }

  parse(response = {}) {
    return response.results || [];
  }

  initialize(models, options = {}) {
    ({user: this.user} = options);
  }

  getUserStreak() {
    const firstChallenge = this.at(0);
    if (firstChallenge) {
      const user = firstChallenge.get('user');
      return user.gameChallengeStreak;
    }

    return undefined;
  }
}
module.exports = RecentChallengesList;
