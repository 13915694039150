const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const NavButtonItemView = require('@common/components/navButton/NavButtonItemView');

class CommunityNavButtonItemView extends NavButtonItemView {
  triggers() {
    return {click: 'item:clicked'};
  }

  attributes() {
    return Object.assign(super.attributes(), {
      'aria-label': `${ this.model.getName() }: ${ this.getMetaData() }`
    });
  }

  getTemplate() {
    return `
      <div class="icon-angle_right row-arrow silver"></div>
      <div class="community-logo-wrapper">
        <div class="community-logo icon icon-communities"></div>
      </div>
      <div class="text <% if (!meta) { %>vertical-absolute-middle<% } %>">
        <div title="<%- name %>" class="name"><%- name %></div>
        <% if (meta) { %>
          <div title="<%- meta %>" class="meta"><%- meta %></div>
        <% } %>
        <% if (lastUpdated) { %>
          <div title="<%- lastUpdated %>" class="last-updated"><%- lastUpdated %></div>
        <% } %>
      </div>
    `;
  }

  templateHelpers() {
    return {
      name: this.model.getName(),
      meta: this.getMetaData(),
      lastUpdated: this.getLastUpdated()
    };
  }

  onShow() {
    const media = this.model.get('thumbnailImage');
    const icon = this.model.get('icon');

    if (media != null) {
      this.ui.logo.removeClass('icon icon-communities');
      const imageViewer = ImageViewerFactory.createViewerInstance({
        $el: this.ui.logo,
        media
      });
      imageViewer.render();
    } else if (icon != null) {
      this.ui.logo.removeClass('icon icon-communities');
      this.ui.logo.html(`<div class="icon ${ icon }"></div>`);
    } else {
      this.ui.logo.addClass('icon icon-communities');
    }
  }

  getLastUpdated() {
    const ts = this.model.get('lastUpdateTime');
    if (!ts) {
      return '';
    }

    return I18n.t('discover.metadata.updatestring', {
      date: dateHelpers.timeFromEvent(ts)
    })
  }

  getMetaData() {
    let postPhrase = '';
    if (this.model.arePostsEnabled()) {
      const postCount = this.model.getPostCount();
      if (postCount === 0) {
        postPhrase = I18n.t('discover.browse.activePageCount.posts.none');
      } else if (postCount === 1) {
        postPhrase = I18n.t('discover.browse.activePageCount.posts.singular');
      } else {
        postPhrase = I18n.t('discover.browse.activePageCount.posts.multiple', {activePageCount: postCount});
      }
    }

    let articlePhrase = '';
    if (this.model.areArticlesEnabled()) {
      const nonPostCount = this.model.getNonPostCount();
      if (nonPostCount === 0) {
        articlePhrase = I18n.t('discover.browse.activePageCount.articles.none');
      } else if (nonPostCount === 1) {
        articlePhrase = I18n.t('discover.browse.activePageCount.articles.singular');
      } else {
        articlePhrase = I18n.t('discover.browse.activePageCount.articles.multiple', {activePageCount: nonPostCount});
      }
    }

    if (this.model.arePostsEnabled() && this.model.areArticlesEnabled()) {
      return I18n.t('discover.browse.activePageCount.list', {
        articleCount: articlePhrase,
        postCount: postPhrase
      });
    }

    if (this.model.arePostsEnabled()) {
      return postPhrase;
    }

    return articlePhrase;
  }
}

module.exports = CommunityNavButtonItemView;
