const Marionette = require('Marionette');
const GLItemViewHelpers = require('./GLItemViewHelpers');
const MilestoneRowView = require('./MilestoneRowView');

/*
 * In the DOM this is a milestone and all items between this milestone and the previous milestone that are not events
 * Created By: MilestonesView
 * Model: MilestoneInterface
 */
class MilestoneItemView extends Marionette.LayoutView {

  getTemplate() {
    return `
      <div class="milestone-prereqs"></div>
      <div class="milestone-row"></div>
      `;
  }

  className() {
    return 'milestone-item';
  }

  regions() {
    return {
      prereqsRegion: '.milestone-prereqs',
      milestoneRowRegion: '.milestone-row'
    };
  }

  onRender() {
    this._renderPrereqs();
    this._renderMilestone();
  }

  _getLinkage() {
    const linkage = {
      linkUp: false,
      linkDown: false
    };

    if (this.model.isFirstPrerequisiteLinkedAbove()) {
      linkage.linkUp = true;
    }
    if (this.model.isMilestoneLinked()) {
      linkage.linkDown = true;
    }
    return linkage;
  }

  _renderPrereqs() {
    if (this.model.getPrereqs().length > 0) {
      const linkage = this._getLinkage();
      const childOptions = {
        objectiveStarter: this.options.objectiveStarter,
        objectiveValidator: this.options.objectiveValidator,
        viewTopicDrillDown: this.options.viewTopicDrillDown,
        disabled: this.options.disabled,
        programId: this.options.programId,
        linkUp: linkage.linkUp,
        linkDown: linkage.linkDown
      };
      const prereqsView = GLItemViewHelpers.getCollectionView(this.getOption('prereqsList'), childOptions);
      this.prereqsRegion.show(prereqsView);
    }
  }

  _renderMilestone() {
    if (this.model.hasMilestone()) {
      const milestone = new MilestoneRowView({model: this.model.getMilestoneRowViewModel()});
      this.milestoneRowRegion.show(milestone);
    }
  }
}
module.exports = MilestoneItemView;
