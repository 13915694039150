// Module
var code = `<div class="modal__header">
  <h1 id="modal-label"><%- t('GuidedLearning.evaluation.details.uploadModalTitle') %></h1>
  <button class="close close-modal-button" title="<%- t('general.close') %>" aria-label="<%- t('general.close') %>"></button>
</div>

<div class="modal__body">
  <div class="modal__section evaluation-name">
    <div class="icon-evaluation"></div>
    <h2><%- formName %></h2>
  </div>

  <div class="modal__section file-upload"></div>
</div>
`;
// Exports
module.exports = code;