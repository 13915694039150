const MilestonesStatusView = require('@common/components/guidedLearning/programs/MilestonesStatusView');
const I18n = require('@common/libs/I18n');

class MilestonesStatusCompleteView extends MilestonesStatusView {
  className() {
    return super.className() + ' milestone-status--complete';
  }

  templateHelpers() {
    return {
      header: I18n.t('GuidedLearning.statusHeader.header'),
      nextMilestoneName: '',
      daysToNextMilestone: '',
      paceMessage: I18n.t('GuidedLearning.statusHeader.completed'),
      hidePaceMessage: false
    };
  }
}

module.exports = MilestonesStatusCompleteView;
