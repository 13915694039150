const MediaPreview = require('@common/components/mediaPreview/MediaPreview');
const SubmissionPreviewPlaceholder = require('@training/apps/training/views/evaluations/submissionModal/SubmissionPreviewPlaceholder');
const MediaHelpers = require('@common/libs/file/MediaHelpers');
const FileHelpers = require('@common/libs/helpers/app/FileHelpers');

class SubmissionMediaPreview extends MediaPreview {

  onError() {
    this._showPlaceholderView(this._previewState.get('file'));
    this._toggleLoadingWrapper(false);
  }

  _showPlaceholderView(file) {
    const view = new SubmissionPreviewPlaceholder({ model: file });
    this._loadingView.contentRegion.show(view);
  }

  _updatePreviewState() {
    const maxDimensions = this._getMaxDisplayDimensions();
    const file = MediaHelpers.getDisplayFile(this.model, maxDimensions);
    const fileType = FileHelpers.getFileType(file.name);
    // For now, disable submission preview for video files (as they will not be transcoded yet)
    const previewable = (file != null && file.fileType !== 'video' && fileType !== 'pdf')
      ? Boolean(file.isPreviewable())
      : Boolean(false);
    this._previewState.set({
      fileId: (file != null ? file.getGeneratedId() : undefined),
      file,
      previewable
    });
  }

}

module.exports = SubmissionMediaPreview;
