const _ = require('underscore');

// TODO fix up the API descriptions
//
// GameManager provides public APIs for game integration:
//
//   window.apps.GameManager.gameData()
//   - Return a JSON object containing game data.
//
//   window.apps.GameManager.queueQuestion()
//   - Queue a question for display.
//
//   window.apps.GameManager.displayQuestions()
//   - Display questions in the queue if the queue isn't empty.
//
//   window.apps.GameManager.gameOver(score, duration, gameOverString)
//   - Game over. Display all remaining questions.
//
// The following callbacks should also be provided to the GameManager:
//   window.apps.GameApi.questionAnsweredCorrect()
//   window.apps.GameApi.questionAnsweredIncorrect()

let gameManager;
let gameCallbacks;
let logger;

const validateApiSetup = function() {
  if (!gameManager) {
    throw new Error('The GameApi has not been instantiated properly. The frameworks GameManager is missing.');
  }

  if (!gameCallbacks) {
    throw new Error('The GameApi has not been set up properly. Make sure the first thing you do is initilize your game callbacks with the Api.');
  }
};

class GameApi {
  constructor(gm) {
    gameCallbacks = null;
    gameManager = gm;
    logger = window.logging;
  }

  //
  // These API calls originate with the game and are redirected to the
  // Axonify framework.
  //

  initGame(callbacks) {
    gameCallbacks = callbacks;

    if (!_.isFunction(gameCallbacks.pauseGame)
              || !_.isFunction(gameCallbacks.resumeGame)
              || !_.isFunction(gameCallbacks.questionAnsweredCorrect)
              || !_.isFunction(gameCallbacks.questionAnsweredIncorrect)
              || !_.isFunction(gameCallbacks.startGame)
              || !_.isFunction(gameCallbacks.endGame)) {
      throw new Error('You are missing parts of the game callback api, please implement them all!');
    }

    logger.info('initGame started.');
    gameManager.initGame();
    logger.info('initGame done.');
  }

  // XXX: This should be removed when we no longer want to ship Holiday code....
  isHolidayTime() {
    return gameManager.isHolidayTime();
  }

  // Game data, e.g. time limit, added time per question, question count.
  getGameData() {
    logger.info('getGameData done.');
    return gameManager.getGameData();
  }

  // Queue question for display
  queueQuestion() {
    validateApiSetup();
    logger.info('queueQuestion started.');
    gameManager.queueQuestion();
    logger.info('queueQuestion done.');
  }

  // In-game question delivery is usually sequential, independent of the activity flow.
  // Thus it's managed here by apps.GameApp, not by the flow controller.
  displayQuestions() {
    validateApiSetup();
    logger.info('displayQuestions started.');
    gameManager.displayQuestions();
    logger.info('displayQuestions done.');
  }

  gameOver(score, gameOverString, gState) {
    validateApiSetup();
    logger.info('gameOver started.');
    gameManager.gameOver(score, gameOverString, gState);
    logger.info('gameOver done.');
  }

  gameOverOnError() {
    logger.info('gameOverOnError started.');
    gameManager.gameOverOnError();
    logger.info('gameOverOnError done.');
  }

  getString(stringId, stringVars = {}) {
    validateApiSetup();
    logger.info('getString done.');
    return gameManager.getGameString(stringId, stringVars);
  }

  setI18n(i18nModule) {
    gameManager.setI18n(i18nModule);
  }

  getUserLocale() {
    return gameManager.getUserLocale();
  }

  performGameAction(mechanicId, type, payload = {}, callback = _.noop) {
    const action = {
      mechanic: {
        mechanicId
      },
      actionType: type,
      actionBody: payload
    };

    return gameManager.performGameAction(action, {}, callback);
  }

  saveScore(score) {
    validateApiSetup();
    logger.info('saveScore started.');
    gameManager.saveScore(score);
    logger.info('saveScore done.');
  }

  updateAssessmentGameState(gameState, async = false) {
    validateApiSetup();
    logger.info('saving assessment specific state');
    gameManager.updateAssessmentGameState(gameState, async);
  }

  updateGameState(gameState, async = false) {
    validateApiSetup();
    logger.info('saving state');
    gameManager.updateGameState(gameState, async);
  }

  //
  // These API calls are related to team based games
  //

  // Display the challenge selection modal
  displayChallengeSelections(startGameCallback) {
    validateApiSetup();
    logger.info('displayChallengeSelections started.');
    gameManager.displayChallengeSelections(startGameCallback);
    logger.info('displayChallengeSelections done.');
  }

  getNewGameInfo() {
    let left;
    validateApiSetup();
    logger.info('getNewGameInfo started.');
    const info = (left = (typeof gameCallbacks.getNewGameInfo === 'function' ? gameCallbacks.getNewGameInfo() : undefined)) != null ? left : ''; // ? is because this call is optional
    logger.info('getNewGameInfo done.');
    return info;
  }

  //
  // These API calls originate with the Axonify framework and control the
  // lifecycle of the game.
  //

  startGame() {
    validateApiSetup();
    logger.info('startGame started');
    gameCallbacks.startGame();
    logger.info('startGame done.');
  }

  pauseGame() {
    validateApiSetup();
    logger.info('pauseGame started');
    gameCallbacks.pauseGame();
    logger.info('pauseGame done.');
  }

  questionAnsweredCorrect() {
    validateApiSetup();
    logger.info('questionAnsweredCorrect started.');
    gameCallbacks.questionAnsweredCorrect();
    logger.info('questionAnsweredCorrect done.');
  }

  questionAnsweredIncorrect() {
    validateApiSetup();
    logger.info('questionAnsweredIncorrect started.');
    gameCallbacks.questionAnsweredIncorrect();
    logger.info('questionAnsweredIncorrect done.');
  }

  resumeGame() {
    validateApiSetup();
    logger.info('resumeGame started.');
    gameCallbacks.resumeGame();
    logger.info('resumeGame done.');
  }

  endGame() {
    validateApiSetup();
    logger.info('endGame started.');
    gameCallbacks.endGame();
    logger.info('endGame done.');
  }

  //
  // Private methods used by the /games app
  //

  // Set the startGameOnInit flag on the GameManager for GM games and ones like it
  _startGameOnInit(start = false) {
    logger.info('_startGameOnInit started.');
    gameManager.startGameOnInit(start);
    logger.info('_startGameOnInit done.');
  }

  // Signal the gameManager that the user is ready to play that game.
  // ie. after they're finished with the introduction
  _startPlay() {
    validateApiSetup();
    logger.info('_startPlay started.');
    gameManager.startGame();
    logger.info('_startPlay done.');
  }
}

module.exports = GameApi;
