import {
  AxThemedDialog,
  type AxThemedDialogProps,
  useAxDialogContext
} from '@common/modules/react/themes/components';
import {
  compact,
  last
} from 'lodash';

export interface AxDialogRendererProps {
  defaultDialogProps?: Partial<AxThemedDialogProps>
}

export default function AxDialogRenderer({ defaultDialogProps }: AxDialogRendererProps = { defaultDialogProps: {} }): JSX.Element {
  const [modals] = useAxDialogContext(({
    rootModals,
    pushModals
  }) => {
    return compact([last(rootModals), ...pushModals]);
  });

  // Converts all dialogProps into Dialog element instances
  const dialogElements = modals.map((dialogProps, index) => {
    const props = {
      ...defaultDialogProps,
      ...dialogProps
    };

    return <AxThemedDialog key={ index } { ...props } />;
  });

  return <>{ dialogElements }</>;
}
