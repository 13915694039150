const EmojiMap = {
  CLAP: '👏',
  HAPPY: '😀',
  POOP: '💩',
  RAINBOW: '🌈',
  SAD: '🙁',
  THUMBDOWN: '👎',
  THUMBUP: '👍',
  CHECKBOX: '✅',
  POPCORN: '🍿',
  WAVE: '👋',
  JOY: '😂',
  TACO: '🌮'
};

export type EmojiKeyType = keyof (typeof EmojiMap);

export const allowedChatEmojiReactions: EmojiKeyType[] = ['THUMBUP', 'HAPPY', 'SAD', 'CLAP', 'CHECKBOX'];

export default EmojiMap;
