// Disabling all eslint rules for this file since it's a direct copy from Marionette.Object and wanted to keep
// things as 1 to 1 as possible outside change to Class and addition of preinitialize
/* eslint-disable */

const _ = require('underscore');
const Backbone = require('Backbone');
const Marionette = require('Marionette');

// Object
// ------

// A Base Class that other Classes should descend from.
// Object borrows many conventions and utilities from Backbone.
// Includes preinitialize method to match up with Backbone's preinitialize method.

// A Base Class that other Classes should descend from.
// Object borrows many conventions and utilities from Backbone.
const AxonifyObject = function(options) {
  this.options = _.extend({}, _.result(this, 'options'), options);

  this.preinitialize.apply(this, arguments);
  this.initialize.apply(this, arguments);
};

AxonifyObject.extend = Marionette.extend;

_.extend(AxonifyObject.prototype, Backbone.Events, {
  //these are the noop methods intended to be overridden by classes that extend from this base
  preinitialize() {},
  initialize() {},

  destroy(options) {
    options = options || {};

    this.triggerMethod('before:destroy', options);
    this.triggerMethod('destroy', options);
    this.stopListening();

    return this;
  },

  // Import the `triggerMethod` to trigger events with corresponding
  // methods if the method exists
  triggerMethod: Marionette.triggerMethod,

  // A handy way to merge options onto the instance
  mergeOptions: Marionette.mergeOptions,

  // Proxy `getOption` to enable getting options from this or this.options by name.
  getOption: Marionette.proxyGetOption,

  // Proxy `bindEntityEvents` to enable binding view's events from another entity.
  bindEntityEvents: Marionette.proxyBindEntityEvents,

  // Proxy `unbindEntityEvents` to enable unbinding view's events from another entity.
  unbindEntityEvents: Marionette.proxyUnbindEntityEvents
});

module.exports = AxonifyObject;
