const Marionette = require('Marionette');
const LearningObjectiveProgressIndicatorView = require('./LearningObjectiveProgressIndicatorView');
const KeyCode = require('@common/data/enums/KeyCode');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
class LearningObjectiveBaseLayoutView extends Marionette.LayoutView {
  getTemplate() {
    return require('./LearningObjectiveBaseLayoutView.html');
  }

  regions() {
    return {
      contentRegion: '.js-content-region',
      progressRegion: '.js-progress-region'
    };
  }

  events() {
    return {
      click: 'onClick',
      keypress: 'keyPress'
    };
  }

  templateHelpers() {
    return {
      isUnlockedTask: this.isTask && this.disabled === false,
      canDrillDownOnEvent: this.isEvent && this.model.canDrillDown() === true && this.disabled === false
    };
  }

  initialize(options = {}) {
    ({
      disabled: this.disabled,
      objectiveStarter: this.objectiveStarter,
      contentView: this.contentView
    } = options);

    this.isTask = this.model.getType() === 'TASK';
    this.isEvent = this.model.getType() === 'EVENT_ENROLLMENT';
  }

  onClick() {
    if (this.disabled) {
      return;
    }

    const onClickHandler = this.contentView.onClick;

    if (onClickHandler) {
      onClickHandler();
    }
  }

  keyPress(e) {
    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      this.onClick();
    }
  }

  onRender() {
    this._renderProgressView();
    this._renderContent();
    this._renderState();
  }

  _renderState() {
    if (this.disabled) {
      this.$el.addClass('disabled');
    }
    this.$el.addClass('learning-objectives__item');

    const type = this.model.getType();
    const typeLabel = I18n.t(`GuidedLearning.icons.${ type.toLowerCase() }`);
    const name = this.model.getName();
    const isLocked = this.model.isLocked();
    const isComplete = this.model.isComplete();
    const completionDate = this.model.get('completionDate');
    const canBegin = this.model.canBegin();

    let availability;
    if (isLocked) {
      availability = I18n.t('GuidedLearning.icons.locked');
    } else if (isComplete) {
      availability = I18n.t('GuidedLearning.icons.done');

      if (completionDate) {
        availability += ' ' + dateHelpers.convertDateFormatLongDateWithStrings(completionDate);
      }
    } else {
      availability = I18n.t('GuidedLearning.icons.none');

      if (!canBegin && type === 'TOPIC') {
        availability += ' ' + I18n.t('GuidedLearning.cannotBegin');
      }
    }

    const ariaLabel = [typeLabel, name, availability].join(' ');

    this.$el.attr({'aria-label': ariaLabel});
  }

  _renderContent() {
    this.contentRegion.show(this.contentView);
  }

  _renderProgressView() {
    this.pending = this.isEvent && this.model.isPending() === true && this.disabled === false;

    const progressIndicatorView = new LearningObjectiveProgressIndicatorView({ model: this.model });
    this.progressRegion.show(progressIndicatorView);
  }
}

module.exports = LearningObjectiveBaseLayoutView;
