const { Collection } = require('Backbone');

const EngagementsEnum = require('@training/apps/insights/engagement/PageInsightsEngagementsEnum');

const ENGAGEMENTS_SORT_ORDER = {
  [EngagementsEnum.IMPRESSIONS]: 0,
  [EngagementsEnum.VIEWS]: 1,
  [EngagementsEnum.VIEWERS]: 2,
  [EngagementsEnum.COMMENTS]: 3,
  [EngagementsEnum.FAVORITES]: 4
};

class PageInsightsEngagementsCollection extends Collection {
  comparator(modelA, modelB) {
    return ENGAGEMENTS_SORT_ORDER[modelA.get('key')] - ENGAGEMENTS_SORT_ORDER[modelB.get('key')];
  }
}

module.exports = PageInsightsEngagementsCollection;
