import {
  SvgIcon as MuiSvgIcon,
  styled,
  type CSSObject,
  type SvgIconTypeMap as MuiSvgIconTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';
import { type BaseIconCustomProps } from './types';
import { getIconSizeStyles } from './utils';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    'small': true
    'medium': true
    'large': true
    'x-large': true
    'inherit': true
  }

  interface SvgIconPropsColorOverrides {
    'inherit': true
    'primary': true
    'secondary': true
    'error': true
    'disabled': true
    'info': true
    'success': true
    'warning': true

    'action': false
  }
}

type PropsToRemove = 'fontSize' | 'color'; // to update with our custom prop

export type AxSvgIconTypeMap<P = object, D extends React.ElementType = 'svg'> = {
  props: P & Omit<MuiSvgIconTypeMap['props'], PropsToRemove> & BaseIconCustomProps;
  defaultComponent: D;
};

export type AxSvgIconProps<
  D extends ElementType = AxSvgIconTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxSvgIconTypeMap<P & BaseIconCustomProps, D>, D>;

const styledOptions = {
  name: 'AxSvgIcon'
};

const StyledAxSvgIcon = styled(MuiSvgIcon, styledOptions)<AxSvgIconProps>((props) => {
  // TODO - update styles to align with UI Kit Icons - Branding (Auth) (Look for other instances of how AxSvgIcon is used)
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7244-5591&mode=design&t=FZhrwchvTAwrZyzQ-0
  const {
    fontSize,
    theme
  } = props;
  

  const style: CSSObject = {
    ...getIconSizeStyles(fontSize, theme)
  };
  return style;
});

/**
 *  A wrapper for the MUI SvgIcon component that applies Axonify styles. This component extends the native `<svg>` element
 *
 * ### Links
 * MUI: https://mui.com/material-ui/icons/#svgicon <br />
 * Figma: https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=4846-4699&t=VHMLzyY56EOzj2PM-0
 *
 * @example
 * <AxSvgIcon fontSize="large" color="primary">
 *   <path d='M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z' />
 * </AxSvgIcon>
 */
export const AxSvgIcon: MuiOverridableComponent<AxSvgIconTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxSvgIconProps, ref: Ref<SVGSVGElement>) => {
  const SvgIconProps: AxSvgIconProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxSvgIcon { ...SvgIconProps } ref={ ref }>
      { children }
    </StyledAxSvgIcon>
  );
});

export default AxSvgIcon;
