const _ = require('underscore');

const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');

// This has a bunch of the state for the controller so it can be persisted across different usages.
// It provides methods to get this data and interact with.
class GuidedLearningControllerState {
  constructor(options = {}) {
    this.hasExpired = this.hasExpired.bind(this);
    this.state = {};
    this.sessionId = options.sessionId;
  }

  // This is computed by the session ID, in case you come back from another day
  hasExpired() {
    return !(this.state['sessionId'] === this.sessionId);
  }

  clearState() {
    this.state = {};
  }

  setState(state) {
    this.state = state;
  }

  getState() {
    if (this.hasExpired()) {
      this.clearState();
    }
    return this.state;
  }

  getPageState() {
    const state = this.getState()['pageState'] || {};

    if (state.pageDetails != null) {
      state.programId = state.pageDetails;
      delete state.pageDetails;
    }

    return state;
  }

  setPageState(pageId, programId) {
    this.getState()['pageState'] = {pageId, programId};
  }

  hasPageState() {
    return !_.isEmpty(this.getState()['pageState']);
  }

  setDetailId(programId) {
    this.setState({
      sessionId: this.sessionId,
      pageState: {
        pageId: GuidedLearningPageEnum.DetailsPage,
        programId
      }
    });
  }

  setTaskDetailsState(programId, taskId) {
    this.setState({
      sessionId: this.sessionId,
      pageState: {
        pageId: GuidedLearningPageEnum.TaskDetailsPage,
        programId,
        taskId
      }
    });
  }

  setEventSessionsState(programId, eventId) {
    this.setState({
      sessionId: this.sessionId,
      pageState: {
        pageId: GuidedLearningPageEnum.EventSessionsPage,
        programId,
        eventId
      }
    });
  }

  setEventDetailsState(programId, eventId, scheduledEventId, enrollmentId, initialTab) {
    this.setState({
      sessionId: this.sessionId,
      pageState: {
        pageId: GuidedLearningPageEnum.LearningEventDetailsPage,
        programId,
        eventId,
        scheduledEventId,
        enrollmentId,
        initialTab
      }
    });
  }

  setEvaluationDetailsState(programId, evaluationId) {
    this.setState({
      sessionId: this.sessionId,
      pageState: {
        pageId: GuidedLearningPageEnum.EvaluationDetailsPage,
        programId,
        evaluationId
      }
    });
  }
}

module.exports = GuidedLearningControllerState;
