// Module
var code = `<div class="managerSelector">
    <h1 class="page-header">
        <div class="ax-container">
            <div class="page-header__content">
                <span class="page-header__icon icon-profile" aria-hidden="true"></span>
                <div class="page-header__title"><%- t('settings.notificationsTitle')%></div>
            </div>
        </div>
    </h1>

    <div class="ax-container">
        <div class="page-content">
            <div class="completeprofile">
                <form class="managerselect">
                    <h2><%- t('settings.emailNotificationsTitle') %></h2>
                    <p>
                        <%= axCheckbox({
                            label: t('settings.emailNotificationsText'),
                            data: { field: 'entity.managerNotificationSettings.EVENT_ENROLLMENT' }
                        }) %>
                    </p>
                </form>
            </div>
        </div>
    </div>
</div>
`;
// Exports
module.exports = code;