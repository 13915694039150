const Backbone = require('Backbone');

class CatalogFilterSubjectList extends Backbone.Collection {
  initialize(options) {
    super.initialize(options);
  }

  apiEndpoint() {
    return '/training/catalogSubjects';
  }
}

module.exports = CatalogFilterSubjectList;
