const logging = require('logging');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

class UserAgreementService {
  static SUCCESS_AGREEMENT_SIGNED = 0;

  static ERROR_AGREEMENT_ALREADY_SIGNED = 3098;

  static ERROR_UNKNOWN_ERROR = -1;

  constructor(networkService = $.ajax) {
    this.networkService = networkService;
  }

  acceptAgreement(agreeement, agreeementState) {
    const hasAgreed = agreeementState.hasAgreed();
    if (!hasAgreed) {
      throw new Error('You have to provide a valid agreement state in order to accept the agreement');
    }

    return this.networkService({
      type: 'POST',
      apiEndpoint: `/agreements/${ agreeement.id }/accept`
    }).then(() => {
      return UserAgreementService.SUCCESS_AGREEMENT_SIGNED;
    }, (xhr) => {
      return this.convertErrorResponseToResultCode(xhr);
    });
  }

  convertErrorResponseToResultCode(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();

    if (errCode === UserAgreementService.ERROR_AGREEMENT_ALREADY_SIGNED) {
      return UserAgreementService.ERROR_AGREEMENT_ALREADY_SIGNED;
    } else if (errCode === AxonifyExceptionCode.PARSE_ERROR_CODE) {
      logging.error(exception.getErrorMessage());
    }

    return UserAgreementService.ERROR_UNKNOWN_ERROR;
  }
}

module.exports = UserAgreementService;
