// Module
var code = `<div class="invited-container" role="region" aria-label="<%- t('referral.youinvited') %>">
	<h2><%- t('referral.youinvited')%></h2>
	<div class="claimed-invite">
		<div class="claimed-invite-wrapper clearfix">
			<div class="claim-left-side">
				<div class="large-name-logo" aria-hidden="true"></div>
			</div>
			<div class="claim-right-side">
				<div class="right-side-wrapper clearfix">
					<div class="name text-ellipsis ax-font ax-font--no-margin ax-font__display"><%- user.salutationName %></div>
					<p class="name-meta ax-font--no-margin ax-font--secondary"><%- department %></p>
					<div class="reward-structure">
						<p class="login-reward"><%- t('referral.loginReward', {points: claimed.initialPoints }) %></p>
						<p class="additional-reward"><%- t('referral.additionalReward', {points: claimed.subsequentPoints, days: bonusPointsDuration}) %></p>
					</div>
				</div>
			</div>
		</div>

		<div class="invite-button-bar">
			<div class="ax-grid ax-grid--align-items-center ax-grid--no-gutter">
				<div class="ax-font ax-font__caption ax-font__caption--bold"><%- t('referral.pendingCalltoAction', {days: daysLeft}) %></div>
				<div class="ax-grid__col--m-auto-size">
					<%= axButton({
						className: 'js-remove-bounty',
						size: 'm',
						destructive: true,
						data: { referralId: claimed.id },
						ariaLabel: t('referral.uninviteWho', {name: user.salutationName}),
						label: t('referral.uninvite')
					}) %>
				</div>
			</div>
		</div>

	</div>
</div>
`;
// Exports
module.exports = code;