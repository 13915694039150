const _ = require('underscore');
const { Model } = require('Backbone');
const I18n = require('@common/libs/I18n');

const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const RteView = require('@common/components/discover/edit/RteView');
const PostsRichContentHelper = require('@common/libs/PostsRichContentHelper');
const FileHelpers = require('@common/libs/helpers/app/FileHelpers');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const VideoFileHelpers = require('@common/libs/helpers/app/VideoFileHelpers');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class PostRteView extends RteView {

  behaviors() {
    return {};
  }

  initialize(options = {}) {
    super.initialize(options);
    ({
      model: this.model,
      userLanguage: this.userLanguage,
      fileFactory: this.fileFactory,
      // `imageSelector` and `videoSelector` are required by the superclass
      imageSelector: this.imageSelector,
      videoSelector: this.videoSelector,
      onEditorTextChange: this.onEditorTextChange
    } = options);

    // add custom buttons to the toolbar
    const basicButtons = ['bold', 'italic', 'underline'];
    const isHyperlinkAllowed = TenantPropertyProvider.get().getProperty('hyperLinksOnPostsEnabled');
    if (isHyperlinkAllowed) {
      basicButtons.push('insertLink');
    }

    this.scrollableContainer = '.editor-container';
    this.customToolbarButtons = {
      moreText: {
        buttons: basicButtons,
        buttonsVisible: basicButtons.length
      }
    };

    this.toolbarsEnabled = false;
    this.htmlAllowedTags = ['a', 'b', 'strong', 'i', 'em', 'u', 'p', 'div', 'br'];
    this.htmlAllowedAttrs = ['title', 'href', 'alt', 'target']; // these permit basic hyperlinks but nothing more messy
    this.wordPasteEnabled = false;

    this._onChangeMediaInput = this._onChangeMediaInput.bind(this);
    this._onClickInsertMedia = this._onClickInsertMedia.bind(this);

    this.options.setupHandlers({
      onClickInsertMedia: this._onClickInsertMedia
    });

    //make a copy of any existing media
    this.model.set('originalMedia', this.model.get('media'), { silent: true });

    this.videoUploadOptions = new Model({
      language: {
        id: this.userLanguage
      }
    });
    this.rcHelper = PostsRichContentHelper;

    this.listenTo(this, 'upload:start', () => {
      window.app.layout.showSpinner();
    });
    this.listenTo(this, 'upload:done', () => {
      window.app.layout.hideSpinner();
    });
    this.listenTo(this, 'exception', (reason) => {
      window.app.layout.flash.error(reason);
    });

    this.removeIconHtml = $('<button>').addClass('icon icon-remove js-remove-media');
    this.removeIconHtml.attr('aria-label', I18n.t('comms.posts.removeMediaButton'));
  }

  getTemplate() {
    return require('@training/apps/timeline/PostRteViewTemplate.html');
  }

  templateHelpers() {
    const videoTypes = VideoFileHelpers.allowedVideoMimeTypes.join(',') + ',image/*';
    return {
      allowableMediaTypes: videoTypes
    };
  }

  ui() {
    return Object.assign(super.ui(), {
      mediaContainer: '.media-container',
      mediaInput: '.local-media',
      imageInput: '.local-media'
    });
  }

  events() {
    return Object.assign(super.events(), {
      'change @ui.mediaInput': '_onChangeMediaInput',
      'focus @ui.mediaInput': '_toggleAccessibilityFocus',
      'blur @ui.mediaInput': '_toggleAccessibilityFocus',
      'click .js-remove-media': '_onClickRemoveMedia'
    });
  }

  onAttach() {
    super.onAttach();
    const mediaArray = this.model.get('media');

    if (mediaArray && mediaArray.length > 0) {
      this._attachMedia(mediaArray);
    }
  }

  _attachMedia(mediaArray) {
    // Since we currently allow a max of 1 media element, we won't be iterating over an array, and instead ...
    // we will be using the first media item in the array as our model
    const mediaModel = new Model(mediaArray[0]);

    const fileExt = FileHelpers.getFileExtension(mediaArray[0].originalFile.originalFileName);
    if (_.contains(VideoFileHelpers.allowedVideoExtensions, fileExt)) {
      this.insertVideo(mediaModel);
    } else {
      this.insertImage(mediaModel);
    }
  }

  _onClickRemoveMedia() {
    this.model.set('media', []);
    this.$('.media-container').empty();
    this.ui.mediaContainer.hide();
    triggerResize(true); // To address a rendering issue with IE11
  }

  _onClickInsertMedia() {
    this.ui.mediaInput.trigger('click');
  }

  _onChangeMediaInput() {
    const fileExt = FileHelpers.getFileExtension(this.ui.mediaInput.val());
    if (_.contains(VideoFileHelpers.allowedVideoExtensions, fileExt)) {
      this.uploadVideo();
    } else {
      this.uploadImage();
    }
  }

  _toggleAccessibilityFocus(e) {
    this.options.toggleAccessibilityFocus(e);
  }

  insertImage(imageMedia) {
    this.resetMediaUploaderInput();

    const media = [];
    media.push(imageMedia.toJSON());
    this.model.set('media', media);

    const imgHtml = this.rcHelper.createImageContainerHtml(imageMedia);
    const wrappingHtml = $('<span>').addClass('axonify-temp-img-span')
      .html('a');
    const htmlToInsert = $('<span>')
      .append(wrappingHtml.clone())
      .append(this.removeIconHtml)
      .append(imgHtml)
      .append(wrappingHtml.clone());
    this.ui.mediaContainer.html(htmlToInsert.html());
    this.ui.mediaContainer.show();

    $('.axonify-temp-img-span').remove();

    this.initializeImageViewers();
  }

  initializeImageViewers() {
    const imageMediaInfo = this.model.getImageMediaInfo(this.ui.mediaContainer);

    for (const imageMedia of imageMediaInfo) {
      const {
        $el,
        media
      } = imageMedia;
      if ($el.is(':has(.image-placeholder)') && media) {
        const imageViewerOptions = _.extend({ maxWidth: $el.width() }, imageMedia);
        const imageViewer = ImageViewerFactory.createViewerInstance(imageViewerOptions);
        imageViewer.render();
      }
    }
  }

  insertVideo(videoMedia) {
    this.resetMediaUploaderInput();

    const media = [];
    media.push(videoMedia.toJSON());
    this.model.set('media', media);

    const videoHtml = this.rcHelper.createVideoContainerHtml(videoMedia);
    const htmlToInsert = $('<span>')
      .append(this.removeIconHtml)
      .append(videoHtml);

    this.ui.mediaContainer.html(htmlToInsert.html());
    this.ui.mediaContainer.show();
  }

  resetMediaUploaderInput() {
    // clear the value of the input field so you can reupload the same media item if you delete it manually
    this.$el.find('.media-form')[0].reset();
  }
}

module.exports = PostRteView;
