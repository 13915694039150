// Module
var code = `<div class="modalHeader">
  <h1><%- t('auction.raffleItem.purchaseTickets.title') %></h1>
  <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
</div>

<div class="placebid">

  <% if (model.prize.prizeImg) { %>
  <div
    role="button"
    tabindex="0"
    aria-label="<%= t('auction.imageZoomLabel', { prizeName: model.prize.name }) %>"
    class="ax-button ax-button--ghost itemimage image-wrapper"
  ></div>
  <% } %>

  <div class="title ax-font ax-font__display"><%= model.prize.name %></div>

  <label for="bid" class="block"><%- t('auction.raffleItem.purchaseTickets.label') %></label>
  <input id="bid" type="number" onPaste="return false" min="1" max="<%- MAX_TICKET_COUNT %>" />

  <div class="notice" aria-live="assertive"></div>
</div>

<div class="biddetails biddetails--with-border show-bid-info">
  <div class="biddetailsinner ax-grid ax-grid--no-gutter clearfix">

    <p class="biddetail bids">
      <span class="biddetail-label ax-font ax-font__caption"><%- t('auction.raffleItem.ticketsPurchased') %></span>
      <span class="bidcount ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(model.raffleTicketCount) %></span>
    </p>

    <p class="biddetail currentbid">
      <span class="biddetail-label ax-font ax-font__caption"><%- t('auction.raffleItem.ticketPrice') %></span>
      <span class="amountbid ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(model.raffleTicketPrice) %></span>
    </p>

    <p class="biddetail balance ax-grid__col--12 ax-grid__col--m-4">
      <span class="biddetail-label ax-font ax-font__caption"><%- t('auction.pointBalance') %></span>
      <span class="amountbid ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(pointBalance) %></span>
    </p>

  </div>
</div>
`;
// Exports
module.exports = code;