const LearningEventView = require('@training/apps/training/views/LearningEventView');

class FilteredEventListItemView extends LearningEventView {

  initialize(options = {}) {
    ({
      eventDetailsCallback: this.eventDetailsCallback
    } = options);
  }

  viewEvent() {
    const enrollment = this.model.get('enrollment') || {};
    const enrollmentId = enrollment.id;
    const scheduledEventId = this.model.get('scheduledEvent').id;

    this.eventDetailsCallback({
      scheduledEventId,
      enrollmentId
    });
  }
}

module.exports = FilteredEventListItemView;
