const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class RecoverPasswordSubmittedPage extends Marionette.LayoutView {

  getTemplate() {
    return require('./RecoverPasswordSubmittedPage.html');
  }

  templateHelpers() {
    const username = this.flowStateModel.get('username');
    return {
      successMessage: I18n.t('login.forgotPassword.emailSent.furtherInformation', {username: `<strong>${ HTMLHelpers.stripHtmlForDisplay(username) }</strong>`})
    };
  }

  initialize(options = {}) {
    ({
      flowStateModel: this.flowStateModel
    } = options);
  }
}
module.exports = RecoverPasswordSubmittedPage;
