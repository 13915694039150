const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');

const CommunityArticleSearchResultsViewController = require('@training/apps/search/CommunityArticleSearchResultsViewController');
const CommunityTimelineController = require('@training/apps/search/CommunityTimelineController');

/**
 @getTabConfigs logic and private functions
*/
const getTabConfigs = (searchPageState, community, tabNameOption) => {
  const tabConfigsArray = [];
  const accessibleTabs = new Set();

  const pageStateTab = searchPageState.get('communitySearchCategory');
  const communityData = community || searchPageState.get('community');
  const articlesEnabled = communityData.get('articlesEnabled');
  const questionsEnabled = communityData.get('questionsEnabled');
  const postsEnabled = communityData.get('postsEnabled') && TenantPropertyProvider.get().getProperty('communicationsEnabled');

  const collections = searchPageState.get('results');

  const hasArticlesOrQuestions = community.hasNonPosts();
  const hasArticlesOrQuestionsEnabled = articlesEnabled || questionsEnabled;

  const hasAllPageTypesEnabled = postsEnabled && hasArticlesOrQuestionsEnabled;

  if (hasAllPageTypesEnabled) {
    const allTabConfig = _createTabConfig(SearchCategoryEnum.ALL, collections[SearchCategoryEnum.ALL], searchPageState);
    tabConfigsArray.push(allTabConfig);

    accessibleTabs.add(SearchCategoryEnum.ALL);
  }
  if (hasArticlesOrQuestions || hasArticlesOrQuestionsEnabled) {
    const articlesTabConfig = _createTabConfig(SearchCategoryEnum.ARTICLES, collections[SearchCategoryEnum.ARTICLES], searchPageState);
    tabConfigsArray.push(articlesTabConfig);

    accessibleTabs.add(SearchCategoryEnum.ARTICLES);
  }
  if (postsEnabled) {
    const postsTabConfig = _createTabConfig(SearchCategoryEnum.POSTS, collections[SearchCategoryEnum.POSTS], searchPageState);
    tabConfigsArray.push(postsTabConfig);

    accessibleTabs.add(SearchCategoryEnum.POSTS);
  }

  const initialTab = _setInitialTab(accessibleTabs, pageStateTab, tabNameOption);

  return {
    initialTab,
    tabList: tabConfigsArray
  };
};

const _createTabConfig = (tabName, tabCollection, searchPageState) => {
  const shouldShowCount = searchPageState.get('searchString') !== '';
  const defaultTabOptions = {
    tab: {
      tabName,
      tabText: I18n.t(`selfDirected.search.categoryNames.${ tabName }`),
      showCount: shouldShowCount,
      contentCollection: tabCollection
    },
    tabContentController: _getTabContentController(tabName, searchPageState)
  };

  return defaultTabOptions;
};

const _getTabContentController = (tabName, searchPageState) => {
  const controllersList = {
    [SearchCategoryEnum.ALL]: {
      ViewControllerClass: CommunityTimelineController,
      timelineCollection: searchPageState.get('results')[SearchCategoryEnum.ALL],
      searchPageState
    },
    [SearchCategoryEnum.ARTICLES]: {
      ViewControllerClass: CommunityArticleSearchResultsViewController,
      searchPageState
    },
    [SearchCategoryEnum.POSTS]: {
      ViewControllerClass: CommunityTimelineController,
      timelineCollection: searchPageState.get('results')[SearchCategoryEnum.POSTS],
      searchPageState
    }
  };

  return controllersList[tabName];
};

/**
 * This function handles what tab a user should see on the community search page, since it can be determined by first time navigation,
 * URL param values entered manually, or bookmark/autofill navigation
 * @param {Set} accessibleTabs - a set of valid tabs for this community
 * @param {String} pageStateTab - the current tab value for the SearchPageState for a current communtiy
 * @param {String} tabNameOption - the possible tab name passed in via a URL navigation including bookmark navigation,
 * which is used to set the tab a user wants to see, or a valid tab if the value is not found in @accessibleTabs
 * @returns {String} which is the name of the tab we should show the user
 */
const _setInitialTab = (accessibleTabs, pageStateTab, tabNameOption) => {
  const isValidTabName = accessibleTabs.has(tabNameOption);

  if ( tabNameOption && isValidTabName) {
    return tabNameOption;
  } else if (pageStateTab) {
    return pageStateTab;
  }
  return accessibleTabs.entries()[0];
};

/**
 @CommunitySearchTabController helper functions
*/
const isStateMatchingTab = (tabName, searchPageState) => {
  return tabName === searchPageState.get('communitySearchCategory');
};

const isAllTab = (tabName, searchPageState) => {
  return tabName === SearchCategoryEnum.ALL
    && searchPageState.get('communitySearchCategory') === SearchCategoryEnum.ALL;
};

module.exports = {
  getTabConfigs,
  isStateMatchingTab,
  isAllTab
};
