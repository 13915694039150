const PageableCollection = require('@common/components/pageableList/PageableCollection');
const Tag = require('@common/components/discover/models/Tag');

const LIST_SIZE = 50;

class FilterableCommunityManagementList extends PageableCollection {
  initialize(models, options = {}) {
    Object.assign(options, {
      fetchType: 'GET'
    }, options);

    super.initialize(models, options);
    this.state.pageSize = LIST_SIZE;
  }

  apiEndpoint() {
    return '/tags/details';
  }

  get model() {
    return Tag;
  }

  fetch(options = {}) {
    Object.assign(options, {
      data: {
        query: this.searchQuery
      }
    });

    return super.fetch(options);
  }

  search(searchData, pageNum, options = {}) {
    this.searchQuery = searchData.get('searchString');
    return this.getPage(pageNum || 0, options);
  }
}

module.exports = FilterableCommunityManagementList;
