const { LayoutView } = require('Marionette');

class CommunityManagementLayout extends LayoutView {
  className() {
    return 'community-management-layout parent-height';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/CommunityManagementLayout.html');
  }

  regions() {
    return {
      headerRegion: '.js-community-header',
      tabsRegion: '.js-community-management-tabs',
      actionBarRegion: '.js-action-bar'
    };
  }

  onDestroy() {
    window.app.layout.actionBar.setActionBar();
  }
}

module.exports = CommunityManagementLayout;
