const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');

const TabController = require('@common/components/tabbed_layout/TabController');
const CommunitiesController = require('@training/apps/search/communitiesManagement/communities/CommunitiesController');
const FilterableCommunityManagementList = require('@training/apps/search/communitiesManagement/collections/FilterableCommunityManagementList');
const FilterableTagList = require('@training/apps/search/communitiesManagement/collections/FilterableTagList');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const ImportHistoryController = require('@training/apps/search/communitiesManagement/ImportHistoryController');

const CommunitiesManagementTabEnum = require('@training/apps/training/enums/CommunitiesManagementTabEnum');
const SettingsTabView = require('@training/apps/search/communitiesManagement/settings/SettingsTabView');
const TagGroupFiltersTabController = require('@training/apps/search/communitiesManagement/tagGroupFilters/TagGroupFiltersTabController');
const TagsTabController = require('@training/apps/search/communitiesManagement/tags/TagsTabController');

const COMMUNITIES_URL = `${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.COMMUNITIES_MANAGEMENT }/communities/`;
const FILTERS_URL = `${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.COMMUNITIES_MANAGEMENT }/filters/`;
const IMPORT_HISTORY_URL = `${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.COMMUNITIES_MANAGEMENT }/importHistory/`;
const SETTINGS_URL = `${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.COMMUNITIES_MANAGEMENT }/settings`;
const TAGS_URL = `${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.COMMUNITIES_MANAGEMENT }/tags/`;

const SORTED_TABS_ORDER = {
  [CommunitiesManagementTabEnum.COMMUNITIES]: 0,
  [CommunitiesManagementTabEnum.SETTINGS]: 1,
  [CommunitiesManagementTabEnum.FILTERS]: 2,
  [CommunitiesManagementTabEnum.TAGS]: 3,
  [CommunitiesManagementTabEnum.IMPORT_HISTORY]: 4
};

class CommunitiesManagementTabController extends TabController {
  initialize(options = {}) {
    ({
      initialTab: this.initialTab,
      pageNum: this.pageNum,
      filterId: this.filterId
    } = options);

    this.tagList = new FilterableTagList();
    this.communityList = new FilterableCommunityManagementList();
    this.managementPageState = new Backbone.Model({
      tabName: this.initialTab,
      pageNum: this.pageNum,
      communityList: this.communityList,
      filterId: this.filterId,
      searchData: new Backbone.Model({
        searchString: '',
        includeLocationCommunities: false,
        includeArchived: false
      })
    });

    this.tabConfigs = this.tabConfigs.bind(this);
    this._onViewShowTab = this._onViewShowTab.bind(this);
  }

  tabConfigs() {
    const isCommsEnabled = TenantPropertyProvider.get().getProperty('communicationsEnabled');

    const settingsTab = {
      tab: {
        tabName: CommunitiesManagementTabEnum.SETTINGS,
        tabText: I18n.t('general.settings')
      },
      tabContentController: {
        viewDefinition: {
          ViewClass: SettingsTabView
        }
      }
    };

    const importHistoryTab = {
      tab: {
        tabName: CommunitiesManagementTabEnum.IMPORT_HISTORY,
        tabText: I18n.t('communitiesManagement.tabs.importHistory')
      },
      tabContentController: {
        ViewControllerClass: ImportHistoryController,
        id: 'import-history'
      }
    };

    const tabs = [
      {
        tab: {
          tabName: CommunitiesManagementTabEnum.COMMUNITIES,
          tabText: I18n.t('communitiesManagement.tabs.communities')
        },
        tabContentController: {
          ViewControllerClass: CommunitiesController,
          id: 'communities-tab',
          managementPageState: this.managementPageState,
          communityList: this.communityList,
          communitiesUrl: COMMUNITIES_URL
        }
      },
      {
        tab: {
          tabName: CommunitiesManagementTabEnum.FILTERS,
          tabText: I18n.t('communitiesManagement.tabs.filters')
        },
        tabContentController: {
          ViewControllerClass: TagGroupFiltersTabController,
          filterId: this.managementPageState.get('filterId')
        }
      },
      {
        tab: {
          tabName: CommunitiesManagementTabEnum.TAGS,
          tabText: I18n.t('communitiesManagement.tabs.tags')
        },
        tabContentController: {
          ViewControllerClass: TagsTabController,
          managementPageState: this.managementPageState,
          tagList: this.tagList
        }
      }
    ];

    if (isCommsEnabled) {
      tabs.push(settingsTab, importHistoryTab);
    }

    return tabs.sort((a, b) => {
      return SORTED_TABS_ORDER[a.tab.tabName] - SORTED_TABS_ORDER[b.tab.tabName];
    });
  }

  _onViewShowTab(tabName = CommunitiesManagementTabEnum.COMMUNITIES) {
    if (tabName !== this.managementPageState.get('tabName')) {
      this._resetSearch(tabName);
    }

    let tabUrl = '';

    switch (tabName) {
      case CommunitiesManagementTabEnum.FILTERS:
        tabUrl = `${ FILTERS_URL }${ this.managementPageState.get('filterId') || '' }`;
        break;
      case CommunitiesManagementTabEnum.COMMUNITIES:
        this._initCommunitesTabData();
        tabUrl = this._getHashForCommunitiesList();
        break;
      case CommunitiesManagementTabEnum.IMPORT_HISTORY:
        tabUrl = this._getHashForImportHistory();
        break;
      case CommunitiesManagementTabEnum.SETTINGS:
        tabUrl = this._getHashForSettings();
        break;
      case CommunitiesManagementTabEnum.TAGS:
        this._initTagsTabData();
        tabUrl = this._getHashForTagsList();
        break;
      default:
        break;
    }

    Backbone.history.navigate(tabUrl, {
      trigger: false,
      replace: true
    });

    super._onViewShowTab(tabName);
  }

  _initCommunitesTabData() {
    this.communityList.search(this.managementPageState.get('searchData'), this.pageNum - 1);
    this.listenTo(this.communityList, 'sync', () => {
      this._updatePageNumberInUrl(this.managementPageState, this.communityList);
      triggerResize(true);
    });
  }

  _initTagsTabData() {
    this.tagList.search(this.managementPageState.get('searchData'), this.pageNum - 1);
    this.listenTo(this.tagList, 'sync', () => {
      this._updatePageNumberInUrl(this.managementPageState, this.tagList);
      triggerResize(true);
    });
  }

  _resetSearch(tabName) {
    this.managementPageState.set({
      tabName,
      pageNum: 1
    });
    this.managementPageState.get('searchData').set('searchString', '');
  }

  _updatePageNumberInUrl(managementPageState, collection) {
    const pageInResults = collection && collection.state ? (collection.state.currentPage || 0) : 0;

    managementPageState.set('pageNum', pageInResults + 1);

    const newHash = collection === this.tagList ? this._getHashForTagsList() : this._getHashForCommunitiesList();

    Backbone.history.navigate( newHash, {
      trigger: false,
      replace: true
    });
  }

  _getHashForCommunitiesList() {
    const pageNum = encodeURIComponent(this.managementPageState.get('pageNum'));

    return `${ COMMUNITIES_URL }${ pageNum }/`;
  }

  _getHashForImportHistory() {
    const pageNum = encodeURIComponent(this.managementPageState.get('pageNum'));

    return `${ IMPORT_HISTORY_URL }${ pageNum }`;
  }

  _getHashForSettings() {
    return `${ SETTINGS_URL }`;
  }

  _getHashForTagsList() {
    const pageNum = encodeURIComponent(this.managementPageState.get('pageNum'));

    return `${ TAGS_URL }${ pageNum }/`;
  }
}

module.exports = CommunitiesManagementTabController;
