const Marionette = require('Marionette');

require('@common/components/mediaPreview/placeholder/PreviewPlaceholder.less');
class PreviewPlaceholder extends Marionette.ItemView {

  getTemplate() {
    return `\
    <div class="default-thumb-placeholder" style="display: table;">
      <div class="table-centering-hack">
        <div class="no-preview-message-text" title="<%- fileName %>" >
          <%- fileName %>
          <p class="not-available-message">(<%- t('UIKit.Form.Editor.MediaThumbnail.previewUnavailable') %>)</p>
        </div>
      </div>
    </div>\
    `;
  }

  constructor(options) {
    super(options);

    this.templateHelpers = this.templateHelpers.bind(this);
  }

  templateHelpers() {
    const fileName = this.model != null ? this.model.getName() : '';
    return {
      fileName
    };
  }
}

module.exports = PreviewPlaceholder;
