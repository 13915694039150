const MenuItemView = require('@common/modules/main/menu/item/MenuItemView');

class SelectableMenuItemView extends MenuItemView {

  constructor(options = {}) {
    super(options);

    this.linkClass = `${ this.linkClass } selectable-menu-item selectable clickable`;
  }

}

module.exports = SelectableMenuItemView;
