import {
  Skeleton as MuiSkeleton,
  styled,
  useTheme,
  type SkeletonTypeMap as MuiSkeletonTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  getUnit,
  toUnitless
} from '@mui/material/styles/cssUtils';
import {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
  type ElementType,
  type Ref
} from 'react';


type AxSkeletonCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

type PropsToRemove = 'animation'; // removes (for UI consistency)

export type AxSkeletonTypeMap<P = object, D extends React.ElementType = 'span'> = {
  props: P & Omit<MuiSkeletonTypeMap['props'], PropsToRemove> & AxSkeletonCustomProps;
  defaultComponent: D;
};

export type AxSkeletonProps<
  D extends ElementType = AxSkeletonTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxSkeletonTypeMap<P & AxSkeletonCustomProps, D>, D>;


const styledOptions = {
  name: 'AxSkeleton'
};

const StyledAxSkeleton = styled(MuiSkeleton, styledOptions)<AxSkeletonProps>((props) => {
  const {
    theme
  } = props;
  return {
    backgroundColor: theme.uiKit.skeleton.backgroundColor,
    '&.MuiSkeleton-rounded': {
      borderRadius: theme.uiKit.skeleton.borderRadius
    }
  };
});

export const AxSkeleton: MuiOverridableComponent<AxSkeletonTypeMap> = forwardRef(({
  children,
  variant = 'text',
  sx,
  ...otherProps
}: AxSkeletonProps, ref: Ref<HTMLSpanElement | null>) => {
  const SkeletonProps: AxSkeletonProps = {
    children,
    variant,
    ...otherProps
  };

  /**
   * The default implementation of MuiSkeleton uses a fixed scaling of 0.6 on the height of the skeleton
   * which largely doesn't match up with the shown texts font size and causes a jump when the skeleton is
   * replaced with the text.
   *
   * The following code calculates the scaling factor based on the font size and line height inherited from it's parent
   * and applies it to the skeleton to ensure the skeleton matches the text it is replacing.
   */
  const theme = useTheme();
  const [transformState, setTransformState] = useState({});
  const internalRef = useRef<HTMLSpanElement | null>(null);

  const radiusUnit = getUnit(theme.uiKit.borderRadiusM) || 'px';
  const radiusValue = toUnitless(theme.uiKit.borderRadiusM);

  useImperativeHandle(ref, () => {
    return internalRef.current;
  });

  useLayoutEffect(() => {
    const elRef = internalRef.current;

    if (variant === 'text' && elRef != null) {
      if (children == null) {
        const {
          fontSize,
          lineHeight
        } = window.getComputedStyle(elRef);
        const lineFontRatio = parseFloat(fontSize) / parseFloat(lineHeight);

        setTransformState({
          transform: `scale(1, ${ lineFontRatio })`,
          borderRadius: `${ radiusValue }${ radiusUnit }/${ Math.round(radiusValue / lineFontRatio * 10) / 10 }${ radiusUnit }`
        });
      } else {
        setTransformState({
          transform: `none`
        });
      }
    }
  }, [children, radiusUnit, radiusValue, ref, variant]);

  return (
    <StyledAxSkeleton
      { ...SkeletonProps }
      ref={ internalRef }
      sx={{
        ...transformState,
        ...sx
      }}
      data-testid='ax-skeleton'
    >
      { children }
    </StyledAxSkeleton>
  );
});

export default AxSkeleton;
