const { ItemView } = require('Marionette');

const I18n = require('@common/libs/I18n');

const Card = require('@common/libs/behaviors/card/Card');
const { INTERACTIVE } = require('@common/libs/behaviors/card/CardModifierClasses');
const KeyCode = require('@common/data/enums/KeyCode');

class CoachItemView extends ItemView {

  className() {
    return 'coach touchable ax-grid__col--6 ax-grid__col--m-4';
  }

  modelEvents() {
    return {
      'change:selected': this.onChangeSelected
    };
  }

  getTemplate() {
    return `
      <div class="type <%- coachType %> coach<%- id %>"></div>
      <div class="coach-selection"></div>
    `;
  }

  attributes() {
    return {
      tabindex: 0,
      role: 'radio',
      'aria-label': this._generateAriaLabel()
    };
  }

  triggers() {
    return {
      click: 'select:coach'
    };
  }

  events() {
    return {
      keydown: 'onKeydownEvent'
    };
  }

  onKeydownEvent(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      e.preventDefault();
      this.trigger('select:coach', this, this.model );
    }
  }

  behaviors() {
    return {
      Card: {
        modifierClasses: () => {
          if (this.model.collection.getSelected() !== this.model) {
            return [INTERACTIVE];
          }
          return null;
        }
      }
    };
  }

  onRender() {
    this.toggleSelected(Boolean(this.model.get('selected')));
  }

  onChangeSelected(model, value) {
    this.toggleSelected(value);
    this.triggerMethod(Card.UPDATE_EVENT);
  }

  toggleSelected(selected = false) {
    this.$el.toggleClass('selected', selected);
    this.$el.attr('aria-checked', selected.toString());
    this.$el.attr('aria-label', this._generateAriaLabel(selected));
  }

  _generateAriaLabel() {
    const coachType = this.model.get('coachType');
    let coachNumber = coachType.match(/\d+/);

    if (coachType === 'NoCoach') {
      return I18n.t('coaches.description.noCoach');
    } else if ( coachNumber !== null) {
      coachNumber = coachNumber[0];

      const coachDescription = 'coaches.description.coach' + coachNumber;

      return I18n.t(coachDescription);
    }

    return I18n.t('coaches.description.customCoach');
  }
}

module.exports = CoachItemView;
