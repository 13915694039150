import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitDatePicker(cssVars: CSSStyleDeclaration) {
  return {
    datePicker: {
      selectedDay: {
        backgroundColor: getCssValue('--axDatePicker-selectedDay-background-color', cssVars),
        color: getCssValue('--axDatePicker-selectedDay-color', cssVars)
      },
      today: {
        backgroundColor: getCssValue('--axDatePicker-today-background-color', cssVars),
        backgroundColorHover: getCssValue('--axDatePicker-today-hover-background-color', cssVars),
        borderColor: getCssValue('--axDatePicker-today-border-color', cssVars)
      }
    }
  };
}
