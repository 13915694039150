const { LayoutView } = require('Marionette');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

class TagGroupFiltersSettingsTabView extends LayoutView {
  id() {
    return 'tag-group-filters-tab';
  }

  get template() {
    return require('@training/apps/search/communitiesManagement/tagGroupFilters/TagGroupFiltersTabView.html');
  }

  regions() {
    return {
      index: '.js-index-wrapper-region',
      content: '.js-content-wrapper-region'
    };
  }

  onShow() {
    triggerResize(true);
  }

  className() {
    return 'ax-grid tag-group-filters-layout';
  }
}

module.exports = TagGroupFiltersSettingsTabView;
