const { LayoutView } = require('Marionette');
class DiscoverTrainingCardTopRegionLayoutView extends LayoutView {
  getTemplate() {
    return `
      <div class="js-graphic-region training-card__graphic-wrapper ax-grid__col ax-grid__col--m-12"></div>
      <div class="training-card__header-tag-wrapper">
        <div class="js-header-info-region training-card__discover-header-info-wrapper"></div>
      </div>
    `;
  }

  className() {
    return 'training-card__top ax-grid ax-grid--no-gutter ax-grid--direction-row';
  }

  regions() {
    return {
      graphicRegion: '.js-graphic-region',
      headerInfoRegion: '.js-header-info-region'
    };
  }
}

module.exports = DiscoverTrainingCardTopRegionLayoutView;
