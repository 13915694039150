const Marionette = require('Marionette');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');

class CharacterCountView extends Marionette.ItemView {
  getTemplate() {
    return `\
    <textarea
      id="<%- textareaId %>"
      class="js-character-count-textarea character-count-view <%- extraCSSClasses %>"
      aria-label="<%- placeholderText %>"
      placeholder="<%- placeholderText %>"
      ></textarea>
    <div class="character-count-container">
      <p class="off-screen js-off-screen-character-count" aria-live="polite"></p>
      <span class="js-character-count character-count <%- extraCharacterCountClasses %>" aria-hidden="true"></span>
    </div>\
    `;
  }

  ui() {
    return {
      textarea: '.js-character-count-textarea',
      characterCountStatus: '.js-character-count',
      characterCountStatusOffscreen: '.js-off-screen-character-count'
    };
  }

  events() {
    return {
      'keydown @ui.textarea': 'checkCharacterCount',
      'keyup @ui.textarea': 'checkCharacterCount',
      'change @ui.textarea': 'checkCharacterCount'
    };
  }


  initialize(options = {}) {
    ({
      model: this.model,
      fieldName: this.fieldName,
      textareaId: this.textareaId,
      placeHolderI18nKey: this.placeHolderI18nKey = 'discover.metadata.editNote',
      maxCharacterCount: this.maxCharacterCount = 100,
      extraCSSClasses: this.extraCSSClasses = '',
      extraCharacterCountClasses: this.extraCharacterCountClasses = ''
    } = options);

    this.render();
    this.ui.characterCountStatus.text(this.maxCharacterCount);

    // filling the textarea from a model is optional. In the case of the Editor Notes field (aka
    // 'currentVersion.editComment') , the user enters a value and it is saved, but it is not populated into the form
    // the next time the article is edited. So in that case the model isn't even being passed in. Contrarily, the
    // expiryNotes field is persistent, we get it from the model, and stick it into the textarea
    if (this.model) {
      this.ui.textarea.text(this.model.get(this.fieldName));
    }

    this.$el.on('paste', (e) => {
      _.defer(() => {
        if (this.isDestroyed) {
          return;
        }
        this.checkCharacterCount(e);
      });
    });
  }

  templateHelpers() {
    return {
      placeholderText: I18n.t(this.placeHolderI18nKey),
      textareaId: this.textareaId,
      extraCSSClasses: this.extraCSSClasses,
      extraCharacterCountClasses: this.extraCharacterCountClasses
    }
  }

  checkCharacterCount(e) {
    const editComment = e.target.value;
    const charactersRemaining = this.maxCharacterCount - (editComment.length);
    const isCharacterRemaining = charactersRemaining >= 0;
    // Disable the warning if it is enabled when there is room for more text.
    if (isCharacterRemaining && this.isUIWarningActive) {
      this.ui.characterCountStatus.removeClass('warning');
      this.isUIWarningActive = false;
    }
    // Enables the warning if it is disabled when there is too much text.
    if (!isCharacterRemaining && !this.isUIWarningActive) {
      this.ui.characterCountStatus.addClass('warning');
      this.isUIWarningActive = true;
    }
    this.ui.characterCountStatus.text(charactersRemaining);
    this.ui.characterCountStatusOffscreen.text(I18n.t('accessibility.inputRemainingCharacters', {value: charactersRemaining}));
  }

  getValue() {
    return this.ui.textarea.val();
  }

  onDestroy() {
    this.$el.off('paste');
  }

  destroy() {
    this.$el.off('paste');
  }
}
module.exports = CharacterCountView;
