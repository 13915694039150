// Module
var code = `<div class="recovery-input">
  <div class="title-wrapper">
    <div class="title-block">
      <div class="recovery-icon icon-non_participants"></div>
      <div class="recovery-title-wrapper">
        <h1 class="recovery-title"><%- t('login.forgotUsername.inputTitle') %></h1>
        <div><%- t('login.forgotUsername.inputSubtitle') %></div>
      </div>
    </div>
  </div>
  <div>
    <label for="user-recovery-username">
      <%- t('login.forgotUsername.inputLabel') %>
    </label>
    <input type="text" id="user-recovery-username" placeholder="<%- t('login.forgotUsername.inputEmail') %>" />
  </div>
  <div class="recovery-submit">
    <a href="#" class="axon-button submit"><%- t('login.submit') %></a>
  </div>
</div>
`;
// Exports
module.exports = code;