const _ = require('underscore');
const Backbone = require('Backbone');
const User = require('@common/data/models/AuthUser');

class RecentGameChallenge extends Backbone.Model {

  preinitialize() {
    this.challengerUser = new User();
    this.challengeeUser = new User();

    this.listenTo(this, 'change:challengee', (model, attributes) => {
      this.challengeeUser.set(attributes);
    });

    this.listenTo(this, 'change:challenger', (model, attributes) => {
      this.challengerUser.set(attributes);
    });
  }

  // gives model explicit `user` and `opponent` objects, not just challenger/challengee
  determineUserResult(userId) {
    let loser, loserScore, winner, winnerScore;
    if (userId == null) {
      return undefined;
    }

    const challengerScore = this.get('challengerScore');
    const challengeeScore = this.get('challengeeScore');

    const challenger = this.challengerUser.toJSON();
    const challengee = this.challengeeUser.toJSON();

    if (challengerScore > challengeeScore) {
      winner = challenger;
      loser = challengee;
      [winnerScore, loserScore] = Array.from([challengerScore, challengeeScore]);
    } else {
      winner = challengee;
      loser = challenger;
      [winnerScore, loserScore] = Array.from([challengeeScore, challengerScore]);
    }

    if (userId === winner.id) {
      this.set({
        user: winner,
        userScore: winnerScore,
        opponent: loser,
        opponentScore: loserScore
      });
    } else {
      this.set({
        user: loser,
        userScore: loserScore,
        opponent: winner,
        opponentScore: winnerScore
      });
    }

    // Return `this` for chaining
    return this;
  }

  toChallengeResultJSON() {
    const JSON = this.toJSON();

    if (_.has(JSON, 'user') && _.has(JSON, 'opponent')) {
      _.extend(JSON, {
        challenger: JSON.user,
        challengerScore: JSON.userScore,
        challengee: JSON.opponent,
        challengeeScore: JSON.opponentScore
      });
    }

    return JSON;
  }
}

module.exports = RecentGameChallenge;
