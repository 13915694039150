const { View } = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

class FooterView extends View {
  getTemplate() {
    return `
      <!-- General Tenant disclaimer -->
      <div class="js-poweredby footer__poweredby ax-grid ax-grid--align-items-center ax-grid--justify-content-center" role="presentation" aria-hidden="true">
        ${ I18n.t('layout.poweredby') }
        <div class="poweredby__logo ax-grid__col--auto-size"></div>
      </div>
      <div class="js-layoutDisclaimer footer__disclaimer"></div>
    `;
  }

  ui() {
    return {
      poweredBy: '.js-poweredby',
      disclaimer: '.js-layoutDisclaimer'
    };
  }

  render() {
    this.$el.html(this.getTemplate());
    return this;
  }

  onRender() {
    // Hide if powered-by text is empty '' or null
    // This could become a tenant property, but we will continue to leverage a string override to control this for now
    if (I18n.t('layout.poweredby') === '' || I18n.t('layout.poweredby') == null) {
      this.ui.poweredBy.hide();
    }
  }

  onResize() {
    this.show();
  }

  hide() {
    this.$el.hide();

    // Set the text to nothing so that the height gets recaclculated for the other items we set
    this.setDisclaimerText('');
  }

  show() {
    this.$el.show();

    // Use the cached type so we know what to reset on show
    switch (this.type) {
      case 'login':
        this.setLoginDisclaimer();
        break;
      case 'general':
        this.setGeneralDisclaimer();
        break;
      default:
        break;
    }
  }

  setLoginDisclaimer() {
    this.type = 'login';
    this.ui.disclaimer.addClass('loginDisclaimer');
    this.setDisclaimerText(I18n.t('login.disclaimer'));
  }

  setGeneralDisclaimer() {
    this.type = 'general';
    this.ui.disclaimer.removeClass('loginDisclaimer');
    this.setDisclaimerText(I18n.t('layout.disclaimer'));
  }

  setDisclaimerText(text = '') {
    // if text is empty, then add a descriptive classname to the footer and do not
    // set the heights and margin on the objects
    this.$el.toggleClass('footer--no-disclaimer', text === '');

    this.ui.disclaimer.html(text);
  }

  toggleFixed(fixed) {
    const positionProp = fixed ? '' : 'static';
    this.$el.css('position', positionProp);
  }
}

module.exports = FooterView;
