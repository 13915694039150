/* eslint-disable */ // This is disabled since I want the unsued vars to be shut up
class AbstractAssessmentInitiatorTracker {
  constructor(initiatorRegistry) {
    this.initiatorRegistry = initiatorRegistry;

    if(!this.initiatorRegistry) {
      throw new Error('You must provide a legal registry in order to construct this.');
    }
  }

  // This will bind the assessment inside the local storage context and we will try
  // our best to figure out where it came from
  trackAssessment(context, assessment) {
    throw new Error(
      'You must implement something here since this class is marked as abtract.'
    );
  }

  findAssessmentInitiatorContext(assessment) {
    throw new Error(
      'You must implement something here since this class is marked as abtract.'
    );
  }

  stopTrackingAssessmentId(assessmentId) {
    throw new Error('You must implement something here for this class since it is marked as abstract');
  }

  asEntryIterator() {
    throw new Error('You must implement something here for this class since it is marked as abstract');
  }
}

module.exports = AbstractAssessmentInitiatorTracker;
