const {
  mapObject,
  clone,
  keys,
  reduce
} = require('underscore');

const { actAsCollection } = require('Marionette');

const DestroyableObject = require('@common/libs/DestroyableObject');

class RegionControllerManager extends DestroyableObject {
  _controllers = {};

  length = 0;

  constructor(options = {}) {
    super(options);

    this._viewControllerFactory = this.getOption('viewControllerFactory');
  }

  getViewControllerFactory() {
    return this._viewControllerFactory;
  }

  addRegionControllers(regionControllers = {}) {
    return mapObject(regionControllers, (controllerDefinition, regionName) => {
      return this.addRegionController(regionName, controllerDefinition);
    });
  }

  addRegionController(regionName, controllerDefinition) {
    const controller = this._createController(controllerDefinition);

    this._store(regionName, controller);

    return controller;
  }

  get(regionName) {
    return this._controllers[regionName];
  }

  has(regionName) {
    return this.get(regionName) != null;
  }

  getControllers() {
    return clone(this._controllers);
  }

  removeController(regionName) {
    const controller = this.get(regionName);

    this._remove(regionName, controller);

    return controller;
  }

  removeControllers(regionNames = []) {
    return reduce(regionNames, (memo, regionName) => {
      const controller = this.get(regionName);
      memo[regionName] = controller;
      this._remove(regionName, controller);
      return memo;
    }, {});
  }

  destroy() {
    this.removeControllers(keys(this.getControllers()));

    return super.destroy(...arguments);
  }

  _store(regionName, controller) {
    this.triggerMethod('before:add:region:controller', this, regionName, controller);

    if (this._controllers[regionName] == null) {
      this.length++;
    }

    this._controllers[regionName] = controller;

    this.triggerMethod('add:region:controller', this, regionName, controller);
  }

  _remove(regionName, controller) {
    if (regionName == null || controller == null) {
      return;
    }

    this.triggerMethod('before:remove:region:controller', this, regionName, controller);

    if (!controller.isDestroyed) {
      controller.destroy();
    }

    delete this._controllers[regionName];

    this.length--;

    this.triggerMethod('remove:region:controller', this, regionName, controller);
  }

  _createController(controllerDefinition) {
    return this._viewControllerFactory.create(controllerDefinition);
  }
}

actAsCollection(RegionControllerManager.prototype, '_controllers');

module.exports = RegionControllerManager;
