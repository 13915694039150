const { Model } = require('Backbone');
const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const CheckboxGroup = require('@common/components/checkboxGroup/CheckboxGroup');

require('@common/components/forms/editors/checkboxes/Form.Editor.Checkboxes');
require('@common/libs/behaviors/scrollable/Scrollable');

class AccessSettingsGroupModalView extends LayoutView {
  initialize(options = {}) {
    ({
      groupList: this.groupList,
      selectedGroups: this.selectedGroups
    } = options);

    this.model = new Model({
      groups: this.selectedGroups.models
    });
  }

  behaviors() {
    return {
      Scrollable: {
        scrollableContainer: '.selectableList'
      }
    };
  }

  className() {
    return 'group-access-modal';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/accessSettings/AccessSettingsGroupModalView.html');
  }

  ui() {
    return {
      form: '.js-form',
      groupList: '.js-group-list'
    };
  }

  events() {
    return {
      'click .js-close-button': 'destroy'
    };
  }

  onRender() {
    const buttons = [
      {
        type: 'customText',
        text: I18n.t('general.add'),
        className: 'ax-button ax-button--branded',
        onClick: this.onClickAdd.bind(this)
      }
    ];

    this.actionBar.setActionBar({ buttons });
  }

  onShow() {
    this.groupsCheckboxGroup = new CheckboxGroup({
      el: this.ui.groupList,
      collection: this.groupList,
      filterFunction(item, value) {
        return item.get('name').toLowerCase()
          .indexOf(value.toLowerCase()) >= 0;
      }
    });

    // Compare length of collection of groups without 'everyone' to selections to find if all are checked
    const selectedGroupsClone = this.selectedGroups.clone();
    selectedGroupsClone.remove(-1);
    if (selectedGroupsClone.length === this.groupList.length) {
      this.groupsCheckboxGroup.checkAll();
    }

    this.listenTo(this.groupsCheckboxGroup, 'checkboxGroup:updated', this._onCheckboxGroupUpdated);

    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        groupList: this.groupList
      }
    });
  }

  onClickAdd() {
    const everyoneGroup = this.selectedGroups.get(-1);
    const newSelections = this.model.get('groups');

    // SE-18129: Need to maintain any previously selected access levels that may get overridden by the generic group
    // Models backing the checkboxes that are absent of an accessLevel value
    newSelections.forEach((group) => {
      const match = this.selectedGroups.where(group);
      if (match.length > 0) {
        group.accessLevel = match[0].get('accessLevel');
      }
    });

    this.selectedGroups.reset(newSelections);
    this.selectedGroups.unshift(everyoneGroup);
    this.triggerMethod('add:groups');
    this.destroy();
  }

  _onCheckboxGroupUpdated(allChecked, allUnchecked) {
    if (allChecked) {
      this.model.set('groups', this.groupList.models);
    } else if (allUnchecked) {
      this.model.set('groups', []);
    }
  }
}

module.exports = AccessSettingsGroupModalView;
