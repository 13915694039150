import {
  type CSSObject,
  type Theme
} from '@mui/material';
import { type BaseIconCustomProps } from './types';

export function getIconSizeStyles(fontSize: BaseIconCustomProps['fontSize'], theme: Theme): CSSObject {
  const style: CSSObject = {};

  if (fontSize === 'x-large') {
    style.fontSize = theme.uiKit.icon.fontSize.xl;
  }
  if (fontSize === 'large') {
    style.fontSize = theme.uiKit.icon.fontSize.l;
  }
  if (fontSize === 'medium') {
    style.fontSize = theme.uiKit.icon.fontSize.m;
  }
  if (fontSize === 'small') {
    style.fontSize = theme.uiKit.icon.fontSize.s;
  }

  return style;
}
