const Backbone = require('Backbone');
const TaskAttachmentList = require('@common/data/collections/TaskAttachmentList');

const API_ENTITIES = {
  TASK: 'task',
  PROGRAM: 'program'
};

const API_FIELDS = {
  TASK: {
    ID: 'id',
    NAME: 'name',
    DESCRIPTION: 'description',
    ATTACHMENTS: 'attachmentLinks',
    SUBJECT_NAME: 'subjectName',
    TOPIC_NAME: 'topicName',
    PROGRAM_NAME: 'programName'
  },
  PROGRAM: {
    ID: 'id',
    NAME: 'name'
  },
  COMPLETED: 'completed',
  ACTIONABLE: 'actionable',
  POINTS_AVAILABLE: 'pointsAvailable',
  POINTS_EARNED: 'pointsEarned',
  COMPLETION_DATE: 'completionDate'
};

class TaskCompletionRecord extends Backbone.Model {
  constructor(...args) {
    super(...args);
    this._taskAttachmentList = new TaskAttachmentList(this._getTaskField('ATTACHMENTS'));
    this.listenTo(this, `change:${ API_ENTITIES.TASK }`, () => {
      this._taskAttachmentList.reset(this._getTaskField('ATTACHMENTS'));
    });
  }

  apiEndpoint() {
    // If a programId was supplied for toggleCompletion(), we need to use that for the API calls since that refers to the
    //   program that is currently being acted upon.
    const programId = this._programCompletionId || this.getProgramId();
    const taskId = this.getTaskId();
    return `/tasks/${ programId }/${ taskId }`;
  }

  parse(json) {
    // We want to make sure that this value is only ever used when a programID is explicitly passed to toggleCompletion()
    //    and that when a new value is pulled from the API, we use that until told otherwise
    this._programCompletionId = undefined;

    return json.entity || json;
  }

  defaults() {
    const TASK_FIELDS = API_FIELDS.TASK;
    const PROGRAM_FIELDS = API_FIELDS.PROGRAM;

    return {
      [API_ENTITIES.TASK]: {
        [TASK_FIELDS.ID]: '',
        [TASK_FIELDS.NAME]: '',
        [TASK_FIELDS.DESCRIPTION]: '',
        [TASK_FIELDS.ATTACHMENTS]: [],
        [TASK_FIELDS.SUBJECT_NAME]: '',
        [TASK_FIELDS.TOPIC_NAME]: '',
        [TASK_FIELDS.PROGRAM_NAME]: ''
      },
      [API_ENTITIES.PROGRAM]: {
        [PROGRAM_FIELDS.ID]: '',
        [PROGRAM_FIELDS.NAME]: ''
      },
      [API_FIELDS.COMPLETED]: false,
      [API_FIELDS.ACTIONABLE]: true,
      [API_FIELDS.POINTS_AVAILABLE]: 0,
      [API_FIELDS.POINTS_EARNED]: 0,
      [API_FIELDS.COMPLETION_DATE]: 0
    };
  }

  getTaskId() {
    return this._getTaskField('ID');
  }

  getName() {
    return this._getTaskField('NAME');
  }

  doesRequireCompletion() {
    return this.get(API_FIELDS.COMPLETED) === false;
  }

  isActionable() {
    return this.get(API_FIELDS.ACTIONABLE) === true;
  }

  toggleCompletion(programId, options = {}) {
    const doesNeedCompletion = this.doesRequireCompletion();

    this.set(API_FIELDS.COMPLETED, !doesNeedCompletion);

    this._programCompletionId = programId;
    const BASE_URL = this.url();
    const URL = doesNeedCompletion ? `${ BASE_URL }/complete` : `${ BASE_URL }/reopen`;

    return this.save({}, Object.assign({
      type: 'PUT',
      url: URL
    }, options));
  }

  getPointsAvailable() {
    return this.get(API_FIELDS.POINTS_AVAILABLE);
  }

  getPointsEarned() {
    return this.get(API_FIELDS.POINTS_EARNED);
  }

  getDescription() {
    return this._getTaskField('DESCRIPTION');
  }

  getAttachments() {
    return this._taskAttachmentList;
  }

  getSubjectName() {
    return this._getTaskField('SUBJECT_NAME');
  }

  getTopicName() {
    return this._getTaskField('TOPIC_NAME');
  }

  getProgramName() {
    return this._getProgramField('NAME');
  }

  getProgramId() {
    return this._getProgramField('ID');
  }

  getCompletionDate() {
    return this.get(API_FIELDS.COMPLETION_DATE);
  }

  _getTaskField(taskFieldName) {
    return this.get(API_ENTITIES.TASK)[API_FIELDS.TASK[taskFieldName]];
  }

  _getProgramField(programFieldName) {
    return this.get(API_ENTITIES.PROGRAM)[API_FIELDS.PROGRAM[programFieldName]];
  }
}

module.exports = TaskCompletionRecord;
