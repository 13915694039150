const logging = require('logging');

const UploadHandler = require('./UploadHandler');
const AppHelpers = require('@common/libs/helpers/app/AppHelpers');

// Class for uploading files using iframe
class UploadHandlerForm extends UploadHandler {

  constructor() {
    super(...arguments);

    this.formId = 0;
    this.inputsLookup = {};
  }

  add($input) {
    $input.attr({name: this.fileInputName});
    const id = `iframe${ AppHelpers.generateGUID() }`;
    this.inputsLookup[id] = {input: $input, oldParent: $input.parent()};

    // Remove file input from DOM
    if ($input.parent().length > 0) {
      $input.detach();
    }

    return id;
  }

  getName(id) {
    // Get input value and remove path to normalize
    return this.inputsLookup[id].input.val().replace(/.*(\/|\\)/, '');
  }

  getSize() {
    return {};
  }

  _cancel(id) {
    const $iframe = $(`#${ id }`);
    if ($iframe) {
      this.onCancel(id, this.getName(id));
      delete this.inputsLookup[id];
      // To cancel request set src to something else
      // We use src="javascript:false;" because it doesn't trigger IE6 prompt on https
      $iframe.attr({src: 'javascript:false;'});
      $iframe.remove();
    }
  }

  _upload(id, params) {
    this.onUpload(id, this.getName(id), false);

    const $input = this.inputsLookup[id].input;
    if (!$input) {
      throw new Error('File with passed id was not added, or already uploaded or cancelled.');
    }

    const filename = this.getName(id);

    const $iframe = this._createIframe(id);
    const $form = this._createForm($iframe, params);
    $form.append($input);

    // `fileType` is used for the old upload API endpoint, and is not required
    // for upload paths like `media/upload`
    if (this.fileType != null) {
      $form.append($(`<input type="hidden" name="filetype" value="${ this.fileType }"></input>`));
    }

    $iframe.on('load', () => {
      logging.debug('iframe loaded');

      const response = this._getIframeContent($iframe);

      try {
        const res = JSON.parse(response);
        if (res.errCode || res.errMessage) {
          throw res.errMessage; //make sure server errors are reported
        }
        if (typeof this.onComplete === 'function') {
          this.onComplete(id, filename, res);
        }
      } catch (error) {
        let errCode;

        try {
          ({ errCode } = JSON.parse(response));
        } catch (error1) {
          // swallow error
        }

        if (typeof this.onError === 'function') {
          this.onError(id, filename, {responseText: response, errCode});
        }
      }

      this._dequeue(id);

      const { oldParent } = this.inputsLookup[id];
      oldParent.append(this.inputsLookup[id].input);
      delete this.inputsLookup[id];

      // Timeout added to fix busy state in FF3.6
      setTimeout(() => {
        return $iframe.remove();
      }, 1);
    });

    $form.submit();
    $form.remove();
    return id;
  }

  _getIframeContent($iframe) {
    const doc = $iframe[0].contentDocument != null ? $iframe[0].contentDocument : $iframe[0].contentWindow.document;
    //Firefox 3.2 doesn't support innerText while IE doesn't support textContent.
    const responseText = doc.body.innerText != null ? doc.body.innerText : doc.body.textContent;
    logging.debug(`response: ${ responseText }`);
    return responseText;
  }

  _createIframe(id) {
    const $iframe = $(`<iframe src='javascript:false;' name='${ id }' />`);
    $iframe.attr({id});
    $iframe.css({display: 'none'});
    $('body').append($iframe);
    return $iframe;
  }

  // Create form, that will be submitted to iframe
  _createForm($iframe) {
    const $form = $('<form method=\'post\' enctype=\'multipart/form-data\'></form>');

    $form.attr({
      action: this.url,
      target: $iframe.attr('name')
    });

    $form.css({display: 'none'});

    $('body').append($form);
    return $form;
  }
}

module.exports = UploadHandlerForm;
