// Module
var code = `<li class="tab <%- tabClass || tabName %>" data-tab="<%= tabName %>" role="presentation">
  <a class="tab-content" href="#" role="tab" aria-selected="false" aria-label="<%= getAriaLabel(tabKey, collectionCount) %>">
    <% if (tabName !== 'all') { %>
    <span class="reaction-icon__filled--<%= tabName %> filled" aria-hidden="true" ></span>  <% } %>
    <span><%= tabText %></span>
    <% if (showCount) { %>
      <span class="itemCountWrapper" aria-hidden="true"><span class="itemCount" aria-hidden="true"><%= collectionCount %></span></span>
    <% } %>
  </a>
</li>
`;
// Exports
module.exports = code;