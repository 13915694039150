const {
  IntroductoryTraining,
  CertificationTraining,
  RefresherTraining,
  DailyTraining,
  FormalExamTraining,
  GuidedLearningTraining
} = require('@common/data/enums/SessionTrainingType');

const TrainingSessionAssessmentInitiatorProvider = require('./TrainingSessionAssessmentInitiatorProvider');

// Guided Learning configuration
const GuidedLearningSessionConfigurationProvider = require('@training/apps/training/controllers/guidedLearning/GuidedLearningSessionConfigurationProvider');
const GuidedLearningAssessmentInitiatorContext = require('@training/apps/training/controllers/guidedLearning/GuidedLearningAssessmentInitiatorContext');

const providerFromPredicate = (predicate) => {
  return {
    getConfigurationAsync: () => {
      return predicate().then((isRequirementsPending) => {
        return {
          canContinue: !isRequirementsPending
        };
      });
    }
  };
};

/**
 * This is a default implementation which implementation which configures for a typical daily training day.
 * We are given a start page from the client for showing the types of prelude pages we would want to show.
 */
class DefaultDailyTrainingSessionInitiatorProviderFactory {
  constructor(session, registry, pageFactory) {
    this.pageFactory = pageFactory;
    this.session = session;
    this.registry = registry;
  }

  create() {
    const sessionInitiatorProvider = new TrainingSessionAssessmentInitiatorProvider(this.session, this.registry, this.pageFactory);

    this.registerGuidedLearning(sessionInitiatorProvider);
    this.registerIntroTraining(sessionInitiatorProvider);
    this.registerExamTraining(sessionInitiatorProvider);
    this.registerCertificationTraining(sessionInitiatorProvider);
    this.registerRefresherTraining(sessionInitiatorProvider);
    this.registerDailyTraining(sessionInitiatorProvider);

    return sessionInitiatorProvider;
  }

  registerIntroTraining(provider) {
    this._registerSessionTrainingTypeController(provider, IntroductoryTraining);
  }

  registerCertificationTraining(provider) {
    this._registerSessionTrainingTypeController(provider, CertificationTraining);
  }

  registerRefresherTraining(provider) {
    this._registerSessionTrainingTypeController(provider, RefresherTraining);
  }

  registerExamTraining(provider) {
    this._registerSessionTrainingTypeController(provider, FormalExamTraining);
  }

  registerDailyTraining(provider) {
    this._registerSessionTrainingTypeController(provider, DailyTraining);
  }

  registerGuidedLearning(provider) {
    const guidedLearningInitatorController = this.registry.findInitiatorWithHandlerFor(GuidedLearningAssessmentInitiatorContext.getType());

    const showInitiatorPredicateAsync = () => {
      return Promise.resolve(!this.session.isMinDailyTrainingFulfilled());
    };

    const optionsProvider = new GuidedLearningSessionConfigurationProvider(this.session, showInitiatorPredicateAsync);
    provider.registerInitiatorForSessionType(GuidedLearningTraining, guidedLearningInitatorController, showInitiatorPredicateAsync, optionsProvider);

    guidedLearningInitatorController.setConfigurationProvider(optionsProvider);
  }

  _registerSessionTrainingTypeController(provider, sessionTrainingType) {
    const sessionTrainingTypeController = this.registry.findInitiatorWithHandlerFor(sessionTrainingType);

    const showInitiatorPredicateAsync = () => {
      return Promise.resolve(!this.session.isMinDailyTrainingFulfilled());
    };

    const optionsProvider = providerFromPredicate(showInitiatorPredicateAsync);

    provider.registerInitiatorForSessionType(sessionTrainingType, sessionTrainingTypeController, showInitiatorPredicateAsync, optionsProvider);

    // We set a default config here as well so when they are being restored, we can show the context we got them from so that we have something to restore from...
    sessionTrainingTypeController.setConfigurationProvider(optionsProvider);
  }
}

module.exports = DefaultDailyTrainingSessionInitiatorProviderFactory;
