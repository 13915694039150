// This class is responsible for rolling up a single model into a singular value
// that can be reduced into something to be aggregated by the actual chart
// data summarizer.
class ReportModelChartDataSummarizer {
  getSummarizedValueForReportModel(model) {
    if (model.has('count')) {
      return model.get('count');
    }
    return 0;

  }
}
module.exports = ReportModelChartDataSummarizer;
