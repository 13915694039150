const {
  triggerResize,
  getScrollParent
} = require('@common/libs/helpers/app/BrowserHelpers');

const Marionette = require('Marionette');
const { Model } = require('Backbone');
const Cocktail = require('backbone.cocktail');
const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');

const UserView = require('@training/apps/training/views/UserView');
const HiddenUsersSnapshotView = require('@common/components/user/snapshot/HiddenUsersSnapshotView');

class EmptyAttendeeListView extends Marionette.ItemView {
  getTemplate() {
    return '\
    <div class="noevents"><%-t(\'learningEvents.noAttendees\') %></div>\
    ';
  }
}

class LearningEventAttendeesView extends Marionette.CollectionView {
  childView(...args) {
    return new UserView(...args);
  }

  childViewOptions() {
    return {
      extraClass: 'ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--l-3'
    };
  }

  className() {
    return 'learningevent-attendees ax-grid';
  }

  emptyView(...args) {
    return new EmptyAttendeeListView(...args);
  }

  initialize(options = {}) {
    this._addSyncListener(this.collection.isSynced());
    ({
      enrollment: this.enrollment
    } = options);
  }

  onRender() {
    triggerResize(true);
    this._showHiddenAttendeesNode();
  }

  _addSyncListener(skipFetch) {
    this.listenTo(this.collection, 'sync', () => {
      this._showHiddenAttendeesNode();
    });
    // If the collection has not yet been fetched/synced - do that before rendering
    if (!skipFetch) {
      this.collection.fetch();
    }
    this.render();
  }

  _showHiddenAttendeesNode() {
    // Remove any existing HiddenAttendees (HA) node (which would now be at the top of the list instead of the end)
    const curNode = this.$el.find('.hidden-users-snapshot-view').remove();
    const listCount = this.collection.listCount || 0;
    const totalCount = this.collection.totalCount || 0;

    // Now that we have stored any existing HA node, check whether the 'empty table' message is being shown incorrectly
    if (totalCount && !listCount) {
      $(this.el.firstChild).toggleClass('hidden', true);
    } else if (!listCount) {
      $(this.el.firstChild).toggleClass('hidden', false);
    }

    let hiddenAttendeesCount = 0;
    // If the full collection has been pulled check if there are hidden/undetailed attendees
    if (this.collection.length === this.collection.listCount) {
      hiddenAttendeesCount = totalCount - this.collection.listCount;
    }

    // If we have already created the correct HA node, put it back at the end
    if (hiddenAttendeesCount === this.hiddenAttendeesCount) {
      this.$el.append(curNode);

    // Otherwise, if we need a new HA node, create it and add THAT to the end of the list
    } else if (hiddenAttendeesCount) {
      const newNode = this._generateHiddenAttendeesNode(hiddenAttendeesCount);
      this.$el.append(newNode);
    }

    // Store the current number for future reference
    this.hiddenAttendeesCount = hiddenAttendeesCount;
  }

  _generateHiddenAttendeesNode(hiddenAttendeesCount) {
    const newNode = new HiddenUsersSnapshotView({
      model: new Model({hiddenUserCount: hiddenAttendeesCount})
    });
    return newNode.render().$el;
  }

  setScrollContainer() {
    if ((this.$scrollContainer == null)) {
      this.$scrollContainer = $(getScrollParent(this.el));
    }
  }

  onLoadNext() {
    if (!this.collection.isLoading) {
      this.collection.getNextPage();
    }
  }
}

Cocktail.mixin(LearningEventAttendeesView, InfiniteScrollViewMixin);

module.exports = LearningEventAttendeesView;
