const _ = require('underscore');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

class Language extends Backbone.Model {
  static initClass() {
    this.prototype.idAttribute = 'langCode';
  }

  preinitialize(...args) {
    this.toOptionCheckbox = this.toOptionCheckbox.bind(this);
    super.preinitialize(...args);
  }

  constructor(arg) {
    let attributes = {};
    if (_.isString(arg)) {
      attributes = { langCode: arg.toString() };
    } else if (_.isObject(arg)) {
      attributes = arg; // assume coming from attributes
    }
    super(attributes, {});
  }

  getName() {
    const name = this.get('name');
    const langCode = this.get('langCode');
    return name || I18n.languageNameNative(langCode);
  }

  toOption() {
    const langCode = this.get('langCode');
    return { id: langCode, value: this.getName() };
  }

  toOptionCheckbox(model = this) {
    const langCode = model.get('langCode');
    return {
      id: langCode,
      value: langCode,
      label: model.getName(),
      name: `checkbox-group-${ langCode }`
    };
  }
}
Language.initClass();

module.exports = Language;
