const Marionette = require('Marionette');

class VideoEmptyView extends Marionette.ItemView {
  className() {
    return 'preview-unavailable-video';
  }

  getTemplate() {
    return `<div class="empty-transcodings">
        <div><%= t('video.emptyTranscodingsProcessing') %></div>
        <div class="semiboldfont"><%= t('video.emptyTranscodingsReturn') %></div>
    </div>`;
  }
}

module.exports = VideoEmptyView;
