const { Model } = require('Backbone');
const _ = require('underscore');
const PageableCollection = require('@common/components/pageableList/PageableCollection');
const Community = require('@common/data/models/Community');

class ShareableCommunity extends Model {
  apiEndpoint() {
    return `/pages/${ this.bundleId }/secondaryCommunities`;
  }

  initialize(attrs, options = {}) {
    ({
      bundleId: this.bundleId
    } = options);

    this.share = this.share.bind(this);
    this.unshare = this.unshare.bind(this);
  }

  toOption(formattingOptions = {}) {
    return this.secondaryCommunity.toOption(formattingOptions);
  }

  parse(resp) {
    if (this.secondaryCommunity == null) {
      this.secondaryCommunity = new Community(resp.secondaryCommunity);
    }

    return resp;
  }

  save(attrs = {}, options = {}) {
    // Force only the attrs passed in to be sent to the server instead of a full representation of the model
    options.attrs = attrs;
    return super.save(attrs, options);
  }

  share() {
    return this.save({
      bundleId: this.bundleId,
      secondaryCommunityId: this.secondaryCommunity.id
    },
    {
      type: 'POST'
    });
  }

  // The existence of this model in the collection implies that it's shared or
  // it's possible for the user to share. Because of this we can't simply do
  // `model.destroy()` to "unshare" because we don't want to remove this model
  // from the collection, we just want to remove the `id` and set `isActive` to
  // `false`, because the user could still share to this community again.
  unshare() {
    return this.save({}, { type: 'DELETE' });
  }
}

class ShareableCommunitiesList extends PageableCollection {
  initialize(models, options = {}) {
    ({
      bundleId: this.bundleId
    } = options);

    Object.assign(options, {
      fetchType: 'GET'
    }, options);

    super.initialize(models, options);
  }

  model(attrs, options = {}) {
    return new ShareableCommunity(attrs, _.extend({ bundleId: attrs.bundleId }, options));
  }

  apiEndpoint() {
    return `/pages/${ this.bundleId }/secondaryCommunitiesModel`;
  }

  parse(res = {}) {
    const response = super.parse(res);
    this.state.totalRecords = res.totalCount;
    this.state.totalPages = Math.ceil(res.totalCount / this.state.pageSize);
    this.state.lastPage = this.state.totalPages - 1;

    return response;
  }

  comparator(shareableA, shareableB) {
    const nameA = shareableA.secondaryCommunity.getName().toLowerCase();
    const nameB = shareableB.secondaryCommunity.getName().toLowerCase();

    // When the names are alphabetically equivalent, compare the IDs
    if (nameA === nameB) {
      return shareableA.secondaryCommunity.get('id') < shareableB.secondaryCommunity.get('id') ? -1 : 1;
    }

    return nameA < nameB ? -1 : 1;
  }
}

module.exports = ShareableCommunitiesList;
