const UIKit = require('@training/widgets/UIKit');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');

class ArticleDetailsCommentController extends LayoutController {
  initialize(options) {
    this.model = options.viewDefinition.model;

    this.regionControllers = this.regionControllers.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
  }

  regionControllers() {
    const regionControllers = {};

    if (this.model.get('currentUserCanDelete') === true) {
      regionControllers.ellipsisMenuRegion = {
        viewDefinition: {
          ViewClass: MenuDropdown,
          buttonConfig: {
            buttonIcon: 'icon-ellipsis_horizontal',
            buttonAriaLabel: 'discover.comments.actions.accessibility.label',
            buttonAriaLabelContext: {
              datestamp: dateHelpers.timeFromEvent(this.model.get('createDateMillis')),
              name: this.model.get('authorName')
            },
            popupAlignment: MenuDropdownPositionEnum.RIGHT + MenuDropdownPositionEnum.BOTTOM
          },
          optionsListConfig: [
            {
              buttonText: 'discover.comments.actions.delete.button.label',
              buttonClass: 'comment-delete-action',
              buttonIcon: 'icon-trash',
              buttonAriaLabel: 'discover.comments.actions.delete.button.ariaLabel',
              buttonAriaLabelContext: {
                datestamp: dateHelpers.timeFromEvent(this.model.get('createDateMillis')),
                name: this.model.get('authorName')
              },
              clickCallback: this.clickDelete.bind(this)
            }
          ]
        }
      };
    }

    return regionControllers;
  }

  clickDelete() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s'
    });

    const modalChildView = new ConfirmDialogView({
      confirmCallback: this._deleteComment,
      model: this.model,
      title: I18n.t('discover.comments.delete.modal.title'),
      confirmationText: I18n.t('discover.comments.delete.modal.confirmation'),
      buttonText: I18n.t('general.delete'),
      iconClass: 'icon-trash red'
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _deleteComment() {
    this.model.destroy({
      wait: true,
      dataType: 'text', // this prevents the system from trying to parse an empty string as JSON.
      sender: this.model,
      skipGlobalHandler: true,
      success: () => {
        window.app.layout.flash.success(I18n.t('messages.ArticleDetailsCommentModel.DELETE.success'));
      },
      error: (model, response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_INVALID_INPUT) {
          window.app.layout.flash.error(I18n.t('messages.ArticleDetailsCommentModel.DELETE.3016'));
          model.collection.remove(model);
        } else {
          window.app.layout.flash.error(I18n.t('messages.ArticleDetailsCommentModel.DELETE.error'));
        }
      }
    });
  }
}

module.exports = ArticleDetailsCommentController;
