const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');

class ArticlePublishingTableView extends TableLayoutView {
  onAttach() {
    BrowserHelpers.triggerResize(true);
  }
}

module.exports = ArticlePublishingTableView;
