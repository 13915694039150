const Backbone = require('Backbone');

class CaptchaConfig extends Backbone.Model {
  static FIELD_NAMES = {
    PUBLIC_KEY_FIELD_NAME: 'publicKey'
  }

  defaults() {
    return {
      [CaptchaConfig.FIELD_NAMES.PUBLIC_KEY_FIELD_NAME]: ''
    };
  }

  url() {
    return '/axonify/register/recaptcha';
  }

  getSiteKey() {
    return this.get(CaptchaConfig.FIELD_NAMES.PUBLIC_KEY_FIELD_NAME);
  }
}

module.exports = CaptchaConfig;
