const { Collection } = require('backbone');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');

import { ItemView } from 'Marionette';
import { getBaseCardControllerDefinition } from '@common/components/baseCard/BaseCardControllerDefinitionFactory';

export default function AchievementsCardSkeletonDefinition() {
  const defaultOptions = {
    cardClass: 'base-card--achievements-skeleton',
    titleOptions: {
      title: '<div class="skeleton"></div>'
    },
    templateOverride: `
      <div class="base-card__wrapper base-card__wrapper--upper">
        <div class="base-card__title"></div>
        <div class="base-card__view-all">
          <div class="skeleton achievements-action"></div>
        </div>
      </div>
      <div class="base-card__wrapper base-card__wrapper--lower"></div>
    `,
    bottomWrapperControllerDefinition: {
      ViewControllerClass: CollectionLayoutViewController,
      collection: new Collection([null, null, null]), // mimic 3 achievement rows
      viewDefinition: {
        tagName: 'ul',
        className: 'achievements__list full-width',
        childViewOptions: {
          template: false,
          tagName: 'li',
          className: 'achievements__row-region'
        }
      },
      ChildViewControllerDefinition: {
        viewDefinition: {
          ViewClass: ItemView,
          template: `
            <div class="achievements__row">
              <div class="skeleton skeleton__badge"></div>
              <div>
                <div class="skeleton achievements__row--title"></div>
                <div class="skeleton"></div>
              </div>
            </div>
          `
        }
      }
    }
  };

  return getBaseCardControllerDefinition(defaultOptions);
}
