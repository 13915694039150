const UpdateStatus = require('@common/modules/updates/UpdateStatus');

// notifiers
const SilentAxonifyUpdateNotificationStrategy = require('@common/modules/updates/notifier/SilentAxonifyUpdateNotificationStrategy');
const FlashAxonifyUpdateNotificationStrategy = require('@common/modules/updates/notifier/FlashAxonifyUpdateNotificationStrategy');

// You need the bind or you are going to get illegal invocation errors when calling this
const BOUND_WINDOW_RELOAD = function() {
  window.location.reload();
};

class UpdateNotificationBuilder {
  handlers = {};

  updateStrategy = BOUND_WINDOW_RELOAD;

  withApplicationUpdateStrategy(strategy) {
    this.updateStrategy = strategy;
    return this;
  }

  withDefaultSilentUpdate() {
    const handler = this._createSilentStrategy(this.updateStrategy);
    this.withHandlerFor(handler, UpdateStatus.Inessential);
    this.withHandlerFor(handler, UpdateStatus.Required);
    return this;
  }

  withNotificationHandler(message, flashService) {
    const silentHandler = this._createSilentStrategy(this.updateStrategy);
    const notificationHandler = this._createNotificationStrategy(message, flashService, this.updateStrategy);
    this.withHandlerFor(notificationHandler, UpdateStatus.Inessential);
    this.withHandlerFor(silentHandler, UpdateStatus.Required);
    return this;
  }

  withHandlerFor(handler, upgradeStatus) {
    upgradeStatus = UpdateStatus.assertLegalValue(upgradeStatus);
    this.handlers[upgradeStatus] = handler;

    return this;
  }

  withDecorator(decoratorFactory) {
    for (const handlerKey in this.handlers) {
      const handler = this.handlers[handlerKey];
      this.withHandlerFor(decoratorFactory(handler), handlerKey);
    }

    return this;
  }

  build() {
    return this.handlers;
  }

  // implementations
  _createSilentStrategy(updateStrategy) {
    return new SilentAxonifyUpdateNotificationStrategy(updateStrategy);
  }

  _createNotificationStrategy(message, flashService, updateStrategy) {
    return new FlashAxonifyUpdateNotificationStrategy(updateStrategy, flashService, message);
  }
}

module.exports = UpdateNotificationBuilder;
