const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const FixedHeaderLayout = require('@common/components/fixedLayout/FixedHeaderLayout');

const ContentContextConfig = require('@common/modules/main/content/ContentContextConfig');
const ContentRegionControllerDefinitionFactory = require('@common/modules/main/content/ContentRegionControllerDefinitionFactory');

module.exports = (contextModel) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: FixedHeaderLayout,
    regionControllers: ContentRegionControllerDefinitionFactory(contextModel),
    delegateEvents: {
      'view:inflate': (controller, view) => {
        controller.listenTo(contextModel, `change:${ ContentContextConfig.Keys.CONTENT_HEADER_VISIBLE }`, (state, visible) => {
          view.toggleHeader(visible);
        });
      }
    }
  };
};
