const logging = require('logging');
const _ = require('underscore');

const { sendErrorLog } = require('LoggingService');

const Timer = require('@common/libs/Timer');
const Stopwatch = require('@common/libs/Stopwatch');

class TimeLogController {
  constructor(options) {
    ({
      TimeLogConfig: this.TimeLogConfig,
      appIdentifier: this.appIdentifier,
      postProcessingFn: this.postProcessingFn
    } = options);

    this.timer = new Timer(this.appIdentifier);
    this.generateStartStopHelpers();

    this.startTimeLogInterval = this.startTimeLogInterval.bind(this);
    this.stopTimeLogInterval = this.stopTimeLogInterval.bind(this);
    this.createStamp = this.createStamp.bind(this);
  }

  startTimeLogInterval() {
    // Send the time log every 15 seconds if there is something to send
    // If we can't send the time log due to an exception just kill the interval
    this.stopTimeLogInterval();

    this.intervalId = setInterval(() => {
      try {
        this.sendTimeLog();
      } catch (error) {
        sendErrorLog({
          logData: { error }
        });

        this.stopTimeLogInterval();
      }
    }
    , 15000);
  }

  stopTimeLogInterval() {
    if (this.intervalId == null) {
      return;
    }
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  bindPageViewLog(view, timeLogType, referenceId) {
    if (!view || !timeLogType) {
      return;
    }

    view.listenTo(view, 'show', () => {
      const startFunction = this[`startPageView${ timeLogType }`];
      if (_.isFunction(startFunction)) {
        startFunction(referenceId);
      }
    });

    view.listenTo(view, 'destroy', () => {
      const stopFunction = this[`stopPageView${ timeLogType }`];
      if (_.isFunction(stopFunction)) {
        stopFunction(referenceId);
      }
    });
  }

  // Generate the code to create the `start` and `stop` helper methods
  generateStartStopHelpers() {
    this.drillDownToType(this.TimeLogConfig, (methodName, timerOptions) => {
      // Start method
      this[`start${ methodName }`] = (referenceIdString) => {
        const referenceId = parseInt(referenceIdString, 10) || undefined;
        return this.timer.start(timerOptions, referenceId);
      };

      // Stop method
      this[`stop${ methodName }`] = (referenceIdOverrideString) => {
        const referenceIdOverride = parseInt(referenceIdOverrideString, 10) || undefined;
        const timeLogEntry = this.timer.getStartLogEntryByType(timerOptions.type);

        if (timeLogEntry != null) {
          return this.timer.stop(timeLogEntry.id, referenceIdOverride);
        }

        return undefined;
      };
    });
  }

  // Recursively traverse an object hierarchy until you reach the parent of the
  // `type` property, at this point stop going deeper and call the callback with
  // the object hierarchy (depth) as the key and the object as the value.
  drillDownToType(object = {}, callback = _.noop, depth = []) {
    if (Object.prototype.hasOwnProperty.call(object, 'type')) {
      callback(depth.join(''), object);
    }

    for (const property in object) {
      if (Object.prototype.hasOwnProperty.call(object, property) && (typeof object[property] === 'object')) {
        this.drillDownToType(object[property], callback, depth.concat(property));
      }
    }
  }

  setTimeDeltaToServerMs(timeDeltaToServerMs) {
    this.timer.setTimeDeltaToServerMs(timeDeltaToServerMs);
  }

  setAppStartDateMs(appStartDateMs) {
    this.timer.setAppStartDateMs(appStartDateMs);
  }

  setUser(user) {
    this.user = user;
  }

  start(timerOptions = {}, referenceId) {
    return this.timer.start(timerOptions, referenceId);
  }

  pause(id) {
    if (id != null) {
      return this.timer.pause(id);
    }

    return undefined;
  }

  getCurrentTimeSpent(id) {
    return this.timer.getTime(id);
  }

  resume(id) {
    if (id != null) {
      return this.timer.resume(id);
    }

    return undefined;
  }

  complete(id, referenceIdOverride) {
    return this.timer.complete(id, referenceIdOverride);
  }

  stop(id, referenceIdOverride) {
    if (id != null) {
      return this.timer.stop(id, referenceIdOverride);
    }

    return undefined;
  }

  stopAll() {
    this.timer.stopAll();
  }

  startTimeSpentTracker(timeSpentReferenceId) {
    this.timeSpentReferenceId = timeSpentReferenceId;
    this.timeSpentStopwatch = new Stopwatch();
    this.timeSpentStopwatch.start();
  }

  getTimeSpent() {
    const elapsedTime = this.timeSpentStopwatch.elapsedTime();
    this.timeSpentStopwatch.setElapsedTime(0);

    return this.timer.generateTimeLog({
      type: this.TimeLogConfig.TimeSpent.type,
      appStartDate: this.timer.appStartDateMs,
      referenceId: this.timeSpentReferenceId,
      seconds: elapsedTime,
      appIdentifier: 'NotApplicable'
    });
  }

  sendTimeLog(options = {}) {
    // `timelogs` is an authenticated API so don't try to send the log if the
    // user isn't logged in
    // Don't try to send time logs if there is nothing to send

    if (!this.user || !this.user.isLoggedIn() || (this.timer.timeLog.length === 0)) {
      return undefined;
    }

    // Track the time spent since the last call to `sendTimeLog`, this is a
    // special time log type.

    this.getTimeSpent();

    let timelogEntries = this.timer.getTimeLogEntries();

    if (this.postProcessingFn) {
      timelogEntries = this.postProcessingFn(timelogEntries);
    }

    return $.ajax(_.extend(options, {
      type: 'POST',
      apiEndpoint: '/timelogs/multiple',
      data: JSON.stringify(timelogEntries),
      showSpinner: false,
      error() {
        logging.error('An error occured while sending the timelog');
      }
    }));
  }

  createStamp(timerOptions = {}, referenceId) {
    const timerId = this.start(timerOptions, referenceId);

    if (timerId != null) {
      this.stop(timerId);
    }
  }
}

// if we add local storage caching to the timer we should add the timeLog
// entries back to the timer

module.exports = TimeLogController;
