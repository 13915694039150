const logging = require('logging');
const Marionette = require('Marionette');
const Cocktail = require('backbone.cocktail');

const Sync = require('@common/libs/Sync');

const LoadingWrapperController = require('@common/components/loading/wrapper/LoadingWrapperController');
const LoadingWrapperView = require('@common/components/loading/wrapper/LoadingWrapperView');
const LoadingCollectionViewMixin = require('@common/components/loading/collection_view/LoadingCollectionViewMixin');

/*
  LoadingCollectionViewController is a LoadingController that strictly wraps Marionette.CollectionView/CompositeView's.
  Depending on the state of the Collection, this controller will show 2 different loading effects.

  If the collection is empty, it shows a LoadingRow with a spinner.
  If the collection has content from a previous request, it shows the wrapper blanket.

  Enums:
    'Options': defines all valid options that can be passed in as constructor options including the LoadingWrapperController.Options

      'CONTENT_CONTROLLER_DEFINITION': (View Controller Definition) - the Collection/CompositeView controller that will be
      shown as the content
 */

const OptionKeys = LoadingWrapperController.Options.extend({
  COLLECTION: 'collection'
});

class LoadingCollectionViewController extends LoadingWrapperController {

  static Options = OptionKeys;

  constructor(options = {}) {
    super(options);

    ({
      [OptionKeys.COLLECTION]: this._collection
    } = options);
  }

  viewDefinition() {
    return Object.assign({}, super.viewDefinition(), { spinner: false });
  }

  syncTracker() {
    const contentController = this.findControllerByRegion(LoadingWrapperView.CONTENT_REGION);
    const contentView = contentController && contentController.getView();
    const entities = this._collection || contentView && contentView.collection;

    return {
      loadOnSync: true,
      entities,
      syncMethods: [Sync.Method.READ]
    };
  }

  onAddRegionController(loadingController, manager, regionName, contentController) {
    if (regionName === LoadingWrapperView.CONTENT_REGION) {
      if (contentController.isViewInflated()) {
        this.onContentControllerViewInflate(contentController, contentController.getView());
      } else {
        this.listenToOnce(contentController, 'view:inflate', this.onContentControllerViewInflate);
      }
    }

    super.onAddRegionController(loadingController, manager, regionName, contentController);
  }

  onContentControllerViewInflate(contentController, view) {
    if (view instanceof Marionette.CollectionView || view instanceof Marionette.CompositeView) {
      this.mixinLoadingCollectionViewMixin(view);
    } else {
      logging.error('Invalid View subclass', view);
      throw new Error('Invalid View subclass, Collection/CompositeView rquired!');
    }
  }

  onContentControllerViewRendered(contentController, view) {
    super.onContentControllerViewRendered(contentController, view);

    if (view instanceof Marionette.CompositeView) {
      this.getView().setContentEl(view.getChildViewContainer(view));
    }
  }

  onBeforeRemoveRegionController(loadingController, manager, regionName) {
    if (regionName === LoadingWrapperView.CONTENT_REGION) {
      this.getView().setContentEl(null);
    }

    super.onBeforeRemoveRegionController(loadingController, manager, regionName);
  }

  showLoadingView(options) {
    if (this.shouldShowLoadingView(options)) {
      this.showCollectionLoadingView(options);
    } else {
      super.showLoadingView(options);
    }
  }

  hideLoadingView(options) {
    super.hideLoadingView(options);
    // Close loadingView if it's shown
    this.closeCollectionLoadingView(options);
  }

  showCollectionLoadingView() {
    if (this.getCollectionView() != null) {
      this.getCollectionView().showCollectionLoadingView();
    }
  }

  closeCollectionLoadingView() {
    if (this.getCollectionView() != null) {
      this.getCollectionView().closeCollectionLoadingView();
    }
  }

  getCollectionView() {
    return this.findControllerByRegion(LoadingWrapperView.CONTENT_REGION).getView();
  }

  mixinLoadingCollectionViewMixin(view) {
    // Mixin the LoadingCollectionViewMixin into the view instance to decouple this process
    // from the CollectionView's class definition.
    Cocktail.mixin(view, LoadingCollectionViewMixin);
  }

  shouldShowLoadingView() {
    return this._isCollectionViewEmpty();
  }

  _isCollectionViewEmpty() {
    if (this.getCollectionView() != null) {
      return this.getCollectionView().isEmpty();
    }

    return true;
  }
}

module.exports = LoadingCollectionViewController;
