const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const { ReactControllerDefinitionFactory } = require('@common/modules/react');

require('@common/libs/behaviors/msHideable/MsHideable');

class LearnerTrainingPage extends Marionette.LayoutView {
  className() {
    return 'learner-training-page';
  }

  regions() {
    return {
      dailyTrainingBanner: '.learner-daily-training',
      tabContent: '.learner-training-section-tab',
      howToModalRegion: '.js-how-to__modal'
    };
  }

  events() {
    return {
      'click .js-how-to-link': '_onHowToLinkClick'
    };
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  getTemplate() {
    return require('@training/apps/learnerTraining/LearnerTrainingPage.html');
  }

  viewDidAppear() {
    window.app.layout.setTitle(I18n.t('hub.training'));
  }

  _onHowToLinkClick() {
    const modal = ViewControllerFactory.createLegacyView(ReactControllerDefinitionFactory({
      component: import('@training/apps/training/howTo/TopicProgressHowToModal')
    }));
    this.howToModalRegion.show(modal);
  }
}

module.exports = LearnerTrainingPage;
