import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  type CARD_PADDING,
  getCardPadding
} from './AxCard';
import {
  styled,
  CardContent as MuiCardContent,
  type CardContentTypeMap as MuiCardContentTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxCardContentCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  padding?: CARD_PADDING
};

export type AxCardContentTypeMap<P = object, D extends React.ElementType = 'div'> = MuiCardContentTypeMap<P & AxCardContentCustomProps, D>;

export type AxCardContentProps<
  D extends ElementType = AxCardContentTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxCardContentTypeMap<P & AxCardContentCustomProps, D>, D>;

const styledOptions = {
  name: 'AxCardContent',
  shouldForwardProp: (prop: string) => {
    return prop !== 'padding';
  }
};

const StyledAxCardContent = styled(MuiCardContent, styledOptions)<AxCardContentProps>(({
  padding
}) => {
  return {
    padding: getCardPadding(padding),
    '&:last-child': {
      paddingBottom: getCardPadding(padding)
    }
  };
});

export const AxCardContent: MuiOverridableComponent<AxCardContentTypeMap> = forwardRef(({
  children,
  padding = 'medium',
  ...otherProps
}: AxCardContentProps, ref: Ref<HTMLDivElement>) => {
  const cardContentProps: AxCardContentProps = {
    children,
    padding,
    ...otherProps
  };

  return (
    <StyledAxCardContent { ...cardContentProps } ref={ ref }>
      { children }
    </StyledAxCardContent>
  );
});

export default AxCardContent;
