// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-tellafriend" aria-hidden="true"></span>
      <span class="page-header__title"><%- msgTypeTitle %></span>
    </div>
  </div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="user-messaging">
      <div class="broadcastmessage <%- subType %>">
        <div class="claimed-invite">
        <div class="claimed-invite-wrapper clearfix">
          <div class="claim-left-side">
            <div class="large-name-logo" role="img" aria-label="<%- t('stats.avatar') %>"></div>
          </div>
          <div class="claim-right-side">
            <div class="right-side-wrapper clearfix">
              <p class="name text-ellipsis" id="name"><%- name %></p>
              <p class="name-meta"><%- department %></p>
              <div class="reward-structure">
                <p class="login-reward"><%- t('referral.expiredMessage.firstLoginPoints.Title', {points: loginPoints}) %></p>
                <p class="additional-reward">
                  <%- t('referral.expiredMessage.subsequentPoints.SubText', {points: totalSubsequentPoints, loginCount: loginCount, days: duration}) %>
                </p>
              </div>
              <p class="invite-completed"><%- t('referral.expiredMessage.completed') %></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



`;
// Exports
module.exports = code;