/* eslint-disable
    no-unused-vars,
*/
const { isEmpty } = require('underscore');

const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');

module.exports = {
  saveInstanceState(storageObject) {
    this._checkRequirements(storageObject);

    const storageKey = this._generateStorageKey();

    const bundleJSON = {};

    this.onSaveInstanceState(bundleJSON);

    // No point taking up space in localStorage if the state is empty, even though 2 char doesn't take up much space.
    if (!isEmpty(bundleJSON) || this.getOption('allowEmptyStorageEntries')) {
      // We attach the current user's id to the stored data to ensure only that user is able to view said data in the future
      bundleJSON[LocalStorageHelpers.localStorageUserKey] = LocalStorageHelpers.getLocalStorageUserId();
      const bundleString = JSON.stringify(bundleJSON);
      storageObject[storageKey] = bundleString;
    }
  },

  restoreInstanceState(storageObject) {
    this._checkRequirements(storageObject);

    const storageKey = this._generateStorageKey();

    const bundleString = storageObject[storageKey] || '{}';
    const bundleJSON = JSON.parse(bundleString);
    // If the last user to save the data is not the currently logged in user, don't restore the values
    if (bundleJSON[LocalStorageHelpers.localStorageUserKey] === LocalStorageHelpers.getLocalStorageUserId()) {
      this.onRestoreInstanceState(bundleJSON);
    } else {
      this.onRestoreInstanceState({});
    }
  },

  _checkRequirements(storageObject) {
    if (storageObject == null) {
      throw new Error('storageObject is not defined');
    }
    if (this.getOption('storageKey') == null) {
      throw new Error('storageKey is undefined');
    }
  },

  _generateStorageKey() {
    const storageNamespace = this.getOption('storageNamespace') || '';
    const storageKey = this.getOption('storageKey');

    return UrlHelpers.combineUrlPaths(storageNamespace, storageKey);
  },

  // Override these methods in your view
  onSaveInstanceState(bundleJSON) {
    // override this
  },

  // store the instance state in a bare JSON object and return it
  onRestoreInstanceState(bundleJSON) {
    //override this
  }
};
// given a json representation of the bundle recreate your object
