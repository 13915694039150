const { LayoutView } = require('Marionette');
class TrainingCardTopRegionLayoutView extends LayoutView {
  getTemplate() {
    return `
      <div class="training-card__recommendation-tag-wrapper ax-grid__col--12">
        <p class="js-recommendation-tag ax-tag training-card__recommendation-tag <%- recommendedTagCssClass %>"><%- t('training.cards.tags.recommendation') %></p>
      </div>

      <div class="js-graphic-region ax-grid__col  ax-grid__col--m-12 training-card__graphic-wrapper"></div>
      <div class="training-card__header-tag-wrapper ax-grid__col">
        <div class="js-header-info-region training-card__header-info-wrapper"></div>
        <div class="js-tag-region training-card__header-tags-wrapper"></div>
      </div>
    `;
  }

  className() {
    return 'training-card__top ax-grid ax-grid--no-gutter ax-grid--direction-row';
  }

  templateHelpers() {
    const isRecommended = this.getOption('isRecommended');
    const recommendedTagCssClass = isRecommended ? '' : 'hidden';

    return {
      recommendedTagCssClass
    };
  }

  regions() {
    return {
      graphicRegion: '.js-graphic-region',
      headerInfoRegion: '.js-header-info-region',
      tagRegion: '.js-tag-region'
    };
  }
}

module.exports = TrainingCardTopRegionLayoutView;
