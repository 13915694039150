module.exports = {
  AVERAGE_PERFORMER: 'AVERAGE_PERFORMER',
  NEEDS_COACHING: 'POOR_PERFORMER',
  OVERCONFIDENT: 'OVERCONFIDENT_PERFORMER',
  UNDERCONFIDENT: 'UNDERCONFIDENT_PERFORMER',
  EXPERT: 'Expert',
  GRADUATE: 'Graduate',
  INTERMEDIATE: 'Intermediate',
  BEGINNER: 'Beginner',
  NOT_STARTED: 'NotStarted'
};
