const logging = require('logging');

module.exports = (options = {}) => {
  const {
    videoPlayer,
    preventSeekForward = false,
    timeRemaining = 0,
    callback = () => {}
  } = options;


  if (videoPlayer == null) {
    throw new Error('"videoPlayer" is required');
  }

  if (timeRemaining < 0) {
    throw new Error('"timeRemaining" is required to be greater than 0');
  }

  const onPlayerTimeUpdate = () => {
    const isSeeking = videoPlayer.isSeeking();

    if (preventSeekForward && isSeeking) {
      return;
    }

    const videoDuration = videoPlayer.getDuration();
    const currentTime = videoPlayer.getCurrentTime();

    if (videoDuration <= timeRemaining) {
      logging.debug('VideoTimeRemainingCallbackWrapper - video duration shorter than time remaining value!');
      videoPlayer.stopListening(videoPlayer, 'player:timeUpdate', onPlayerTimeUpdate);
      return;
    }

    const timeRemainingThrehold = videoDuration - timeRemaining;

    if (videoDuration > timeRemaining && currentTime >= timeRemainingThrehold) {
      videoPlayer.stopListening(videoPlayer, 'player:timeUpdate', onPlayerTimeUpdate);
      callback();
    }
  };

  videoPlayer.listenTo(videoPlayer, 'player:timeUpdate', onPlayerTimeUpdate);
};
