const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const FilterControlView = require('@common/components/filterableCommunity/FilterControlView');

class MultiselectFilterControlView extends FilterControlView {
  initialize(options = {}) {
    ({
      switchDropdown: this.switchDropdown,
      updateFilter: this.updateFilter,
      committedSelections: this.committedSelections,
      inProgressSelections: this.inProgressSelections,
      keyboardNavigationHandler: this.keyboardNavigationHandler,
      placeholderText: this.placeholderText
    } = options);

    this.updateFilter = _.debounce(this.updateFilter, 150);

    this.listenTo(this.committedSelections, 'update reset', this._updatePlaceholder);
    this.listenTo(this.inProgressSelections, 'reset', this._updatePlaceholder);
  }

  onRender() {
    this._updatePlaceholder();
  }

  _updatePlaceholder() {
    this.ui.filterInput.val('');

    let placeholder = '';

    // Set placeholder based on how many communities are selected
    if (this.committedSelections.length === 1) {
      placeholder = this.committedSelections.at(0).getName();
    } else if (this.committedSelections.length > 1) {
      placeholder = I18n.t('general.numberSelected', { count: this.committedSelections.length });
    } else {
      placeholder = this.placeholderText || I18n.t('components.communityPicker.placeholderText');
    }

    this.ui.filterInput.attr('placeholder', placeholder);
  }

  _updateFilter() {
    const isSearching = this.ui.filterInput.val() !== '';
    this.updateFilter(this.ui.filterInput.val(), isSearching);
  }
}

module.exports = MultiselectFilterControlView;
