// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-reportcard" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('knowledge.title') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="highlights reportcard subhighlight clearfix">
      <div class="ax-grid">
        <div class="highlight subhighlight current_overall_average ax-grid__col--12 ax-grid__col--m-6">
          <div class="wrap clearfix">
            <div class="score reportcard ax-font ax-font__display ax-font--no-margin">&nbsp;</div>
            <div class="tag ax-font ax-font__caption ax-font--secondary"><%- t('knowledge.overallaverage') %></div>
          </div>
        </div>

        <div class="highlight subhighlight first graduated_topic_level reportcard ax-grid__col--12 ax-grid__col--m-6">
          <div class="wrap clearfix">
            <div class="score reportcard ax-font ax-font__display ax-font--no-margin">&nbsp;</div>
            <div class="tag reportcard ax-font ax-font__caption ax-font--secondary"><%- t('knowledge.graduatedTopicLevel') %></div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2><%- t('knowledge.recentanswerresults') %></h3>
      <div class="knowledge-wrapper">
       <button id="knowledge-table-toggle" class="axon-button white" aria-expanded="false"><%- t('knowledge.toggleTableRecentAnswerResults') %></button>
       <div id="knowledgechart" class="mobile"  aria-hidden="true" role="presentation"></div>
       <table id="knowledge-table"></table>
      </div>
      <h2><%- t('knowledge.topiclisttitle') %></h3>
      <div class="topic-search clearfix" role="search">
        <%= axSearch({
          ariaControls: 'knowledgemap',
          ariaLabel: t('assessments.select.searchLabel'),
          fullWidth: true,
          placeholder: t('assessments.select.searchLabel')
        }) %>
        <p class="off-screen" id="number-of-results" aria-live="polite"></p>
      </div>
      <div class="knowledgeitemlistcontainer">
        <ul id="knowledgemap" class="knowledgeitemlist">
          <li class="notopics hidden"><%-t('knowledge.notopics') %></li>
        </ul>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;