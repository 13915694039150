const logging = require('logging');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

require('@common/libs/behaviors/card/Card');

class ReferralAvailableView extends UIKit.View {
  className() {
    return 'modal';
  }

  get template() {
    return _.tpl(require('@training/apps/training/templates/ReferralAvailableView.html'));
  }

  get itemTemplate() {
    return _.tpl(require('@training/apps/training/templates/_referral_available_list_item.html'));
  }

  events() {
    return {'click .small-invite-buttonbar .js-bounty-clicked': 'bountyClicked'};
  }

  behaviors() {
    return {
      Card: {
        target: '.avail-invites-small'
      }
    };
  }

  initialize() {
    ({referrals: this.referrals, collection: this.collection, configuration: this.configuration} = this.options);
    this.bountyConfirmed = this.bountyConfirmed.bind(this);
    this.$el.html(this.template());
  }

  render() {
    this.availableBounties = this.collection;
    this.availableBounties.each((bountyUser, i) => {
      const user = bountyUser.getTarget().toJSON();
      const countLabel = I18n.t('referral.countLabel', {
        currentNum: i + 1,
        totalNum: this.availableBounties.length
      });

      const department
        = (user.department && user.department.name)
        || (user.location && user.location.name)
        || '';

      const availableReferralHtml = this.itemTemplate({
        points: bountyUser.get('initialPoints'),
        bountyId: bountyUser.id,
        name: user.displayName,
        department,
        countLabel
      });
      const $availableReferralHtml = $(availableReferralHtml);
      const $currentView = $availableReferralHtml.find('.small-name-logo');
      this.iconHandler = new ProfileIconHandler({user, profileElement: $currentView});
      return this.$('.available-list').append($availableReferralHtml);
    });
    return this;
  }

  bountyClicked(e) {
    // When the bounty is selected, a modal must be displayed
    // to confirm the selection of the user and to display a
    // call to action - Get that person to log in
    // get id of user
    const bountyId = $(e.currentTarget).data('bounty-id');
    const user = this.availableBounties.find((bounty) => {
      return bounty.id === bountyId;
    });
    const modalView = new AccessibleModalView({
      className: 'how-to-modal confirm-dialog-view'
    });
    window.app.layout.presentModal(modalView);
    modalView.setSubviewIn(new ConfirmBountyModal({
      user,
      configuration: this.configuration,
      onConfirm: this.bountyConfirmed,
      bountyId
    }),
    { transition: UIKit.View.Transitions.NONE });
  }

  bountyConfirmed(bountyId) {
    this.referrals.selectBounty(bountyId, {
      success: () => {
        this.unload();
      },
      error: (response) => {
        let errorMsg;
        const exception = AxonifyExceptionFactory.fromResponse(response);
        const errCode = exception.getErrorCode();
        if ([AxonifyExceptionCode.CLIENT_ERROR_BOUNTY_ALREADY_CLAIMED, AxonifyExceptionCode.CLIENT_ERROR_BOUNTY_INVALID_STATE,
          AxonifyExceptionCode.CLIENT_ERROR_BOUNTY_TOO_MANY_CLAIMED, AxonifyExceptionCode.CLIENT_ERROR_BOUNTY_CLOSED].includes(errCode)) {
          response.skipGlobalHandler = true;
          errorMsg = I18n.t(`referral.errors.${ errCode }`);
        } else {
          errorMsg = I18n.t('referral.errors.failedToSelectBounty');
        }
        logging.error(`error occured while selecting a tell-a-friend bounty for user id: ${ bountyId } with errorCode: ${ errCode }`);
        window.app.layout.flash.error(errorMsg);
      }
    });
  }
}

class ConfirmBountyModal extends UIKit.View {
  className() {
    return 'modal';
  }

  get template() {
    return _.tpl(require('@training/apps/training/templates/_referral_confirm_bounty_modal.html'));
  }

  initialize(options) {
    ({user: this.user, configuration: this.configuration, onConfirm: this.onConfirm, onCancel: this.onCancel, bountyId: this.bountyId} = options);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.target = this.user.getTarget();
  }

  render() {
    this.$el.html(this.template({
      name: this.target.toJSON().displayName,
      days: this.configuration.get('bountyDuration')
    }));

    this.actionBar.setActionBar({
      buttons: [
        {
          type: 'customText',
          text: I18n.t('referral.accept'),
          onClick: this.confirm
        },
        {
          type: 'customText',
          text: I18n.t('referral.decline'),
          onClick: this.cancel
        }
      ]
    });

    return this;
  }

  confirm() {
    // user confirmed this bounty so let the parent know
    if (typeof this.onConfirm === 'function') {
      this.onConfirm(this.bountyId);
    }
    this.dismiss();
  }

  cancel() {
    // user cancelled this bounty to let the parent know
    if (typeof this.onCancel === 'function') {
      this.onCancel();
    }
    this.dismiss();
  }
}

module.exports = ReferralAvailableView;
