const Marionette = require('Marionette');

const joinEventPrefix = function(prefix, event) {
  if (prefix != null ? prefix.length : undefined) {
    return `${ prefix }:${ event }`;
  }
  return event;

};

const EventHelpers = {
  // 'contextEventObject' and 'eventObject' should both have Backbone.Events mixed into them.
  // ie. View, Model, Collection, Marionette.Object, etc...
  //
  // 'eventPrefix' is the String used to prefix the events.
  proxyEvent(contextEventObject, eventObject, event, eventPrefix = '', injectEventObjects = true) {
    if (!contextEventObject || !eventObject || !event) {
      return;
    }

    contextEventObject.listenTo(eventObject, event, (...args) => {
      if (event === 'all') {
        const rootEvent = args[0];
        // args = ['event', arg1, ...]
        args.splice(0, 1, joinEventPrefix(eventPrefix, rootEvent));
      } else {
        // args = [arg1, ...]
        args.unshift(joinEventPrefix(eventPrefix, event));
      }

      // args = ['prefix:event', arg1, ...]

      if (injectEventObjects) {
        args.splice(1, 0, contextEventObject);
        // args = ['prefix:event', contextEventObject, arg1, ...]

        // Make sure the eventObject is included as the next arg if it's not already there.
        if (args[2] !== eventObject) {
          args.splice(2, 0, eventObject);
        }
      }
      // args = ['prefix:event', contextEventObject, eventObject, arg1, ...]

      Marionette.triggerMethodOn(contextEventObject, ...args);
    });
  },

  unproxyEvent(contextEventObject, eventObject, event) {
    if (!contextEventObject || !eventObject || !event) {
      return;
    }

    contextEventObject.stopListening(eventObject, event);
  }
};

module.exports = EventHelpers;
