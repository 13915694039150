// Module
var code = `<form>
  <%= axSelect({
    classNameSelect: 'qa-filter-type-select',
    data: {
      field: 'pageType',
      options: 'typeFilterSelectOptions',
      shallow: 'true',
      editor: 'AxonSelect'
    },
    id: 'filter-by-type',
    fullWidth: true,
    label: t('discover.insights.selectTypeLabel'),
    noFormEditorDefault: 'true'
  }) %>
</form>
`;
// Exports
module.exports = code;