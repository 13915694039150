const { history } = require('Backbone');

const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const TitledSectionControllerDefinition = require('@common/components/titledSection/TitledSectionControllerDefinitionFactory');
const I18n = require('@common/libs/I18n');

const HomepageLayoutController = require('@training/apps/home/layout/HomePageLayoutController');
const MainStatsDefinitionFactory = require('@common/components/stats/MainStatsDefinitionFactory').default;
const GreetingsDefinitionFactory = require('@training/apps/home/greeting/GreetingsDefinitionFactory');
const EventCheckinDefinitionFactory = require('@training/apps/home/eventSelfCheckin/EventCheckinDefinitionFactory');
const TodaysActivitiesDefinitionFactory = require('@training/apps/home/activities/TodaysActivitiesDefinitionFactory');
const RecommendedArticlesDefinitionFactory = require('@training/apps/home/recommendedArticles/RecommendedArticlesDefinitionFactory');
const SkipTrainingViewDefinitionFactory = require('@training/apps/home/skipTraining/SkipTrainingViewDefinitionFactory');
const QuickLinksDefinitionFactory = require('@training/apps/home/quickLinks/QuickLinksDefinitionFactory').default;
const HubTileIdentifiers = require('@common/data/enums/HubTileIdentifiers').default;

const { addTodayActivitiesRegionStyles } = require('@training/apps/home/layout/utils');

module.exports = (options = {}) => {
  const {
    availableTiles,
    model,
    discoveryZoneEnabled,
    recommendedArticlesEnabled,
    recommendedArticlesList,
    startTrainingCallback,
    skipTrainingCallback,
    complete,
    eventCheckinModel,
    brandingChannelModel,
    quickLinksList,
    quickLinksOnPaywallEnabled
  } = options;

  return () => {
    const childControllers = [
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('greeting'), GreetingsDefinitionFactory(model, window.apps.auth.session.user, false)),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('stats'), MainStatsDefinitionFactory(availableTiles)),
      TitledSectionControllerDefinition(I18n.t('start.homepage.todaysActivities'), TodaysActivitiesDefinitionFactory(model, complete, startTrainingCallback)),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('events'), EventCheckinDefinitionFactory(eventCheckinModel)),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('skip-training'), SkipTrainingViewDefinitionFactory(skipTrainingCallback)),
      TitledSectionControllerDefinition(I18n.t('start.homepage.recommendedArticlesHeader'), RecommendedArticlesDefinitionFactory(recommendedArticlesList, discoveryZoneEnabled, recommendedArticlesEnabled))
    ];

    const isQuickLinksOnPaywall = availableTiles[HubTileIdentifiers.TRAINING_PAYWALL]
          && quickLinksOnPaywallEnabled
          && quickLinksList != null
          && quickLinksList.length > 0;
    if (isQuickLinksOnPaywall) {
      childControllers.splice(2, 0, StackedLayoutViewController.createConfigObject(
        ViewOptionHelpers.classNameAppender('quickLinks'),
        QuickLinksDefinitionFactory(quickLinksList, 'startpage')
      ));
    }

    return HomepageLayoutController({
      brandingChannelModel,
      user: window.apps.auth.session.user,
      contentRegion: {
        ViewControllerClass: StackedLayoutViewController,
        viewDefinition: {
          className: 'home-page-layout home-page-layout--pre-training ax-container',
          childViewOptions: {
            className: 'home-page-layout__region'
          }
        },
        childControllers,
        delegateEvents: {
          'view:inflate': (controller, view) => {
            window.apps.base.timeLogController.bindPageViewLog(view, 'PreTrainingHub');
          },
          'view:attach': (controller, view) => {
            addTodayActivitiesRegionStyles(view, isQuickLinksOnPaywall);
          },
          'view:show': () => {
            window.app.layout.setTitle(I18n.t('hub.dailyActivites'));
            history.navigate('#daily-activities');
          }
        }
      }
    });
  };
};
