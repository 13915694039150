const Backbone = require('Backbone');
const logging = require('logging');
const $os = require('detectOS');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const {
  QuestionPreviewPage,
  QuestionItemView
} = require('@common/components/questions/views/QuestionPreviewPage/QuestionPreviewPage');
const { getSelectedMultiLangImage } = require('@common/libs/helpers/app/ImageHelpers');
const { isWithinRegion } = require('@common/libs/helpers/features/QuestionHelpers');

class HotspotQuestionPreviewPage extends QuestionPreviewPage {

  className() {
    return 'hotspot';
  }

  onShow() {
    super.onShow();
    this.listenTo(this, 'modal:open', () => {
      this.answerHeader.currentView.loadImages();
    });

    this.ui.answerOptionText.hide();
  }

  _getQuestionTextview() {
    return new HotspotQuestionItemView({
      model: this.model
    });
  }
}

class HotspotQuestionItemView extends QuestionItemView {
  constructor(...args) {
    super(...args);
    this.MARKER_TOP_OFFSET = 20;
    this.MARKER_LEFT_OFFSET = 20;

    this.clickTemplate = _.tpl(`\
<div
  class="hotspotmarkerwrapper"
  data-left="<%- absoluteX %>"
  data-top="<%- absoluteY %>">
  <div aria-label="<%- t('question.accessibility.hotspotMarker') %>" class="hotspotmarker"></div>
</div>\
`);

    this.regionTemplate = _.tpl(`\
<div class="hotspotanswer"
  data-top="<%- top %>"
  data-left="<%- left %>"
  data-height="<%- height %>"
  data-width="<%- width %>">
<div class="check"></div>
</div>\
`);
  }

  getTemplate() {
    return require('@common/components/questions/templates/hotspot/questionText.html');
  }

  ui() {
    return {
      hintText: '.hinttext',
      reasonText: '#reason',
      coachText: '.bonuscharacter div',
      hotspotImageWrapper: '.hotspotimagewrapper',
      answerwrapper: '.answerwrapper',
      answerOptionText: '.answer-option-text'
    };
  }

  initialize(options) {
    super.initialize(options);

    this.markerLimit = this.variant.get('regionCount');
    this.viewState = new ViewState();
    this.model = options.model;
    this.translatedLanguage = this.model.get('translatedLanguage');

    this.listenTo(this.viewState, 'change:scale', this.onScaleChange);
  }

  loadImages() {
    if (this.model.get('inlineMode') != null) {
      this.ui.answerOptionText.text(I18n.t('question.picture'));
    }
    this.onResize();
  }

  onAttach() {
    if ($os.mobile || (this.$el.height() > 0)) {
      this.loadImages();
    }
  }

  onImageLoadSuccess() {
    if (this.isDestroyed) {
      return;
    }

    this.$image = this.$el.find('.questionimage img').addClass('hotspotimage');
    const hotspot = this.model.get('hotspot');
    this.renderResult(hotspot);
    this.onResize();
    this.renderMarkers(hotspot);
  }

  _initializeImageViewers() {
    _.each(this._imageMediaInfo, (media) => {
      if (media != null) {
        this._imageViewers[media.id] = ImageViewerFactory.createViewerInstance({
          media,
          fitToScreen: true,
          $el: this.$el.find(`[data-media-id = '${ media.id }']`)
        });
        const questionImg = getSelectedMultiLangImage(this.questionImg, this.translatedLanguage, true);
        if (media.id === questionImg?.id) {
          this.listenTo(this._imageViewers[media.id], 'image:loaded', () => {
            this.trigger('image:loaded', this);
            this.onImageLoadSuccess();
          });
          this.listenTo(this._imageViewers[media.id], 'image:error', (e) => {
            this.onImageLoadError(e);
          });
        }
        this._imageViewers[media.id].render();
      } else {
        logging.warn('Media with some ID could not be located. Server failed to send it / markup error?');
      }
    });
  }

  onImageLoadError(e) {
    if (this.isDestroyed) {
      return;
    }

    // Since the hotspot question type doesn't work without the image we show a hard
    // failure with the Oops page and send the log
    // to the server so we have a record of it.
    throw new Error(`Hotspot image load failure for question id
"${ this.activity.get('body').question.id }" and src "${ $(e.currentTarget).attr('src') }".`);
  }

  renderResult(hotspot) {
    let $hotspotAnswer;
    const addRegion = (option) => {
      const imgHeight = this.$image[0].naturalHeight;
      const imgWidth = this.$image[0].naturalWidth;
      const scale = this.viewState.getScale();

      $hotspotAnswer = $(this.regionTemplate(option));
      $hotspotAnswer.css({
        top: (option.top * imgHeight * scale) + 3,
        left: (option.left * imgWidth * scale) + 3,
        height: (option.height * imgHeight * scale) - 6,
        width: (option.width * imgWidth * scale) - 6
      });

      this.ui.hotspotImageWrapper.append($hotspotAnswer);
      return $hotspotAnswer;
    };

    const { incorrectRegions = [] } = hotspot;

    // Option contains .height, .width, .top, .left scaled to 1.0 of the original height/width
    for (const option of incorrectRegions) {
      $hotspotAnswer = addRegion(option);
      $hotspotAnswer.addClass('correct');
    }
  }

  onResize() {
    const imgWidth = ((this.$image && this.$image[0]) || {}).naturalWidth || 0;

    if (imgWidth > 0) {
      // Scaling only to width
      const viewWidth = this.$('.answerwrapper').width();
      const scale = Math.min(viewWidth / imgWidth, 1.0);

      this.viewState.setScale(scale);
    }
  }

  renderMarkers({
    clicks = [],
    correctRegions = []
  }) {
    // get image width and height
    const img = this.$('.hotspotimage');
    const imageWidth = img.width();
    const imageHeight = img.height();
    const scale = this.viewState.getScale();

    // go through the clicks, determine the position and render the markers
    for (const click of clicks) {
      const xCoordinate = click.left * imageWidth;
      const yCoordinate = click.top * imageHeight;

      const position = {};
      position.x = xCoordinate;
      position.y = yCoordinate;
      position.absoluteX = Math.floor(xCoordinate / scale);
      position.absoluteY = Math.floor(yCoordinate / scale);
      const isCorrect = correctRegions.some((region) => {
        return isWithinRegion(click.left, click.top, region);
      });

      this.renderMarker(position, isCorrect);
    }
  }

  renderMarker(position, isCorrect) {
    // get the top left of the markers while thinking about the marker's offset
    const top = position.y - (this.MARKER_TOP_OFFSET / 2);
    const left = position.x - (this.MARKER_LEFT_OFFSET / 2);

    const $click = $(this.clickTemplate(position));
    const markerClasses = isCorrect ? 'icon-CorrectAnswerStreak1 marker-icon correct-marker' : 'icon-remove marker-icon incorrect-marker';
    $click.children().addClass(markerClasses);
    $click.css({
      top,
      left
    });

    logging.debug(`HotspotQuestionPreviewPage - Render Marker, left: ${ position.absoluteX } - top: ${ position.absoluteY }`);

    this.$('.hotspotimagewrapper').append($click);
  }

  onScaleChange() {
    const imgHeight = this.$image[0].naturalHeight;
    const imgWidth = this.$image[0].naturalWidth;
    const scale = this.viewState.getScale();

    const newHeight = scale * imgHeight;
    const newWidth = scale * imgWidth;

    this.$('.questionimage img, .hotspotimagewrapper').css({
      height: newHeight,
      width: newWidth
    });

    // Scale points
    this.$('.hotspotmarkerwrapper').each((index, hotspotMarkerWrapper) => {
      const $hotspotMarkerWrapper = $(hotspotMarkerWrapper);
      const markerPositioning = {
        top: ($hotspotMarkerWrapper.data('top') * scale) - (this.MARKER_TOP_OFFSET / 2),
        left: ($hotspotMarkerWrapper.data('left') * scale) - (this.MARKER_LEFT_OFFSET / 2)
      };
      return $hotspotMarkerWrapper.css(markerPositioning);
    });

    // Scale answer regions, too
    return this.$('.hotspotanswer').each((index, hotspotAnswer) => {
      const $hotspotAnswer = $(hotspotAnswer);
      const answerPositioning = {
        top: $hotspotAnswer.data('top') * newHeight,
        left: $hotspotAnswer.data('left') * newWidth,
        height: $hotspotAnswer.data('height') * newHeight,
        width: $hotspotAnswer.data('width') * newWidth
      };
      return $hotspotAnswer.css(answerPositioning);
    });
  }
}

// ViewState model to respond to changes in the markerCount and scale values.
class ViewState extends Backbone.Model {

  defaults() {
    return {
      scale: 1
    };
  }

  getScale() {
    return this.get('scale');
  }

  setScale(scale) {
    this.set('scale', scale);
  }
}

module.exports = HotspotQuestionPreviewPage;
