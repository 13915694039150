const SessionTrainingType = require('@common/data/enums/SessionTrainingType');
const AssessmentType = require('@common/data/enums/AssessmentType');
const ObjectiveFactory = require('@common/data/models/objectives/ObjectiveFactory');
const GuidedLearningOption = require('@common/data/models/assessments/GuidedLearningOption');

const { getTopicOptionModelForAssessmentType } = require('@common/data/models/assessments/AssessmentTopicOptionFactory');

const getGuidedLearningOption = (assessmentOption = {}) => {
  const { nextItem, program } = assessmentOption;

  if (program == null) {
    throw new Error('You must pass along a program so the id can be used to create a TopicOption model');
  }

  if (nextItem == null) {
    return null;
  }

  return new GuidedLearningOption(ObjectiveFactory(nextItem), program.id);
};

const getAssessmentTypeTopicOption = (assessmentType, assessmentOption = {}) => {
  return getTopicOptionModelForAssessmentType(assessmentType, assessmentOption.nextItem);
};

const sessionTypeTopicOptionMap = {
  [SessionTrainingType.GuidedLearningTraining]: getGuidedLearningOption,
  [SessionTrainingType.CertificationTraining]: getAssessmentTypeTopicOption.bind(null, AssessmentType.CertificationTraining),
  [SessionTrainingType.IntroductoryTraining]: getAssessmentTypeTopicOption.bind(null, AssessmentType.IntroductoryTraining),
  [SessionTrainingType.RefresherTraining]: getAssessmentTypeTopicOption.bind(null, AssessmentType.RefresherTraining),
  [SessionTrainingType.FormalExamTraining]: getAssessmentTypeTopicOption.bind(null, AssessmentType.FormalExamTraining),
  [SessionTrainingType.DailyTraining]: getAssessmentTypeTopicOption.bind(null, AssessmentType.DailyTraining)
};

const getModelForSessionType = (sessionType, assessmentOption = {}) => {
  return sessionTypeTopicOptionMap[sessionType](assessmentOption);
};

module.exports = {
  getGuidedLearningOption,
  getAssessmentTypeTopicOption,
  getModelForSessionType
};
