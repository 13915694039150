const logging = require('logging');
require('bluebird');

const XhrHeaders = require('XhrHeaders');

// Creates a query service good for a single user as required
class TincanQueryService {
  constructor(options = {}) {
    this._lrsConfiguration = options.lrsConfig;
    if (!this._lrsConfiguration) {
      throw new Error('A LRS config must be provided.');
    }
  }

  // SCORM Completion API Query
  isModuleCompletedFor(user, tincanPackage) {
    return new Promise((resolve, reject) => {
      this._queryStatements({
        verb: {
          id: 'http://adlnet.gov/expapi/verbs/completed'
        },
        activity: this._lrsConfiguration.getActivityIdFor(tincanPackage),
        agent: {
          mbox: this._lrsConfiguration.getActorBox()
        }
      }, (error, result) => {
        if (error != null) {
          return reject(error);
        }
        return resolve(result.statements.length > 0);
      });
    });
  }

  _queryStatements(lrsQuery = {}, callback = () => {}) {
    // The query is given in a TincanJS manner, so we need to make sure
    // that we can convert it to an acceptable format here first.
    const agent = JSON.stringify({
      objectType: 'Agent',
      mbox: lrsQuery.agent.mbox
    });
    const massagedQuery = {
      verb: lrsQuery.verb.id,
      agent,
      activity: lrsQuery.activity
    };

    return $.ajax({
      type: 'GET',
      url: `${ this._lrsConfiguration.getEndpoint() }statements`,
      shouldRetry: false,
      data: massagedQuery,
      skipGlobalHandler: true,
      headers: {
        [XhrHeaders.EXPERIENCE_API_VERSION]: this._lrsConfiguration.getLrsVersion()
      },
      success(data) {
        callback(null, data);
      },
      error(xhr) {
        callback('Failed to get the data', null);
        logging.error(xhr.responseText);
      }
    });
  }
}

module.exports = TincanQueryService;
