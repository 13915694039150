const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');

const STRING_KEY_CONFIGS = [
  {
    title: 'discover.insights.pages',
    description: 'discover.insights.howItWorks.pages'
  },
  {
    title: 'discover.insights.views',
    description: 'discover.insights.howItWorks.views'
  },
  {
    title: 'discover.insights.impressions',
    description: 'discover.insights.howItWorks.impressions'
  },
  {
    title: 'discover.insights.viewers',
    description: 'discover.insights.howItWorks.uniqueViews'
  },
  {
    title: 'discover.insights.reach',
    description: 'discover.insights.howItWorks.reach'
  }
];

class InsightsHowToModalView extends LayoutView {
  initialize(options = {}) {
    this.extendedContent = options.extendedContent || [];
    // Default title/description pairs to show in STRING_KEY_CONFIGS, extras in extendedContent
    this.stringConfigs = STRING_KEY_CONFIGS.concat(this.extendedContent);
  }

  getTemplate() {
    return `
      <div class="modalHeader">
        <h1><%- t('discover.insights.howItWorks.title') %></h1>
        <button class="icon-remove close" aria-label="<%- t('general.close') %>"></button>
      </div>
      <div class="page-insights-how-to-content ax-grid ax-grid--no-gutter">
        <% _.each(contentBlocks, function(block) { %>
          <div class="page-insights-how-to-content__item">
            <h2 class="ax-font--no-margin"><%- t(block.title) %></h2>
            <p class="page-insights-how-to-content__item--description"><%- t(block.description) %></p>
          </div>
        <% }) %>
      </div>
    `;
  }

  templateHelpers() {
    return {
      contentBlocks: this.stringConfigs
    };
  }

  ui() {
    return {
      closeIcon: '.icon-remove'
    };
  }

  events() {
    return {
      'click @ui.closeIcon': this.destroy.bind(this)
    };
  }

  onRender() {
    const buttons = [
      {
        type: 'customText',
        text: I18n.t('general.gotIt'),
        onClick: this.destroy.bind(this)
      }
    ];

    this.actionBar.setActionBar({ buttons });
  }
}

module.exports = InsightsHowToModalView;
