const existentialPush = (array, value, string) => {
  if (value != null) {
    array.push(string);
  }
};

module.exports = ({
  url = '',
  method = '',
  data,
  token,
  status,
  thrownError,
  errorCode,
  errorMessage
} = {}) => {
  const messages = [];

  existentialPush(messages, url, `Request URL: ${ url }`);
  existentialPush(messages, method, `Request Method: ${ method }`);
  existentialPush(messages, data, `Request Data: ${ data }`);
  existentialPush(messages, token, `Request Token: ${ token }`);
  existentialPush(messages, status, `Response Status: ${ status }`);
  existentialPush(messages, errorCode, `Response Error Code: ${ errorCode }`);
  existentialPush(messages, errorMessage, `Response Error Message: ${ errorMessage }`);
  existentialPush(messages, thrownError, `Thrown Error: ${ thrownError }`);

  return ['Ajax Error:', ...messages].join('\n  ');
};
