// Module
var code = `<span class="blankanswer inline-block qa-blankanswer">
  <button class="selection-box qa-selecton-box" aria-haspopup="listbox" aria-expanded="false" aria-label="<%= label %>">&nbsp;</button>
  <ul class="selectorlist qa-selectorlist" data-ordinal="<%- ordinal %>" role="listbox" tabindex="-1">
    <% _.each(options, function(option) { %>
      <li data-id="<%- option.id %>" role="option" tabindex="-1"><%= option.optionText %></li>
    <% }); %>
  </ul>
</span>
`;
// Exports
module.exports = code;