const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

// Pulled from https://stackoverflow.com/a/25575009
const punctuationRegex = new RegExp(/[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]/g);

class WindowFactory {
  constructor(win = null) {
    this._win = win != null ? win : window;
    this.href = 'about:blank';
    this.windowId = '_window';
  }

  create(windowOptions = {}) {
    const href = windowOptions.href != null ? windowOptions.href : this.href;
    let windowId = windowOptions.windowId != null ? windowOptions.windowId : this.windowId;
    const windowFeatures = Object.assign({}, windowOptions.windowFeatures);

    windowId = this._sanitizeWindowId(windowId);
    const features = BrowserHelpers.serializeWindowFeatures(windowFeatures);

    return this._win.open(href, windowId, features);
  }

  // IE9 doesn't like spaces, dashes or other punctuation so we're going to filter them out.
  _sanitizeWindowId(windowId = '') {
    return windowId.replace(punctuationRegex, '');
  }
}

module.exports = WindowFactory;
