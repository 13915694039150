const I18n = require('@common/libs/I18n');
const { ItemView } = require('Marionette');

require('@common/libs/behaviors/card/Card');
const {
  INTERACTIVE,
  SMALL_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');

class NavButtonItemView extends ItemView {
  ui() {
    return { logo: '.community-logo' };
  }

  triggers() {
    return { click: 'nav:button:clicked' };
  }

  className() {
    return 'search-community-list-item ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-4';
  }

  tagName() {
    return 'button';
  }

  attributes() {
    let nameKey = this.model.get('nameKey') || '';
    if (nameKey) {
      nameKey = I18n.t(nameKey);
    }

    return {
      type: 'button',
      'aria-label': nameKey
    };
  }

  getTemplate() {
    return `
      <div class="icon-angle_right row-arrow silver"></div>
      <div class="community-logo-wrapper">
        <div class="community-logo"></div>
      </div>
      <div class="text <% if (!meta) { %>vertical-absolute-middle<% } %>">
        <div title="<%- name %>" class="name"><%- name %></div>
        <% if (meta) { %>
        <div title="<%- meta %>" class="meta"><%- meta %></div>
        <% } %>
      </div>
    `;
  }

  templateHelpers() {
    return {
      name: I18n.t(this.model.get('nameKey')),
      meta: ''
    };
  }

  behaviors() {
    return {
      Card: {
        modifierClasses: [INTERACTIVE, SMALL_PADDING]
      }
    };
  }

  onShow() {
    this.ui.logo.html(`<div class="icon ${ this.model.get('icon') }"></div>`);
  }
}

module.exports = NavButtonItemView;
