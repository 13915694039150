const Promise = require('bluebird');

class PendingUserAgreementChecker {
  constructor(session, agreementListRepo) {
    this.session = session;
    this.agreementListRepo = agreementListRepo;

    if (!this.session) {
      throw new Error('You must pass in a valid session when you are constructoring a agreement checker');
    }

    if (!this.agreementListRepo) {
      throw new Error('You must pass in a valid agreement list repo when you are constructoring a agreement checker');
    }
  }

  check() {
    const hasPendingAggreements = this.session.hasPendingAggreements();

    if (!hasPendingAggreements) {
      return Promise.resolve({
        hasAgreements: false,
        agreements: []
      });
    }

    // We must dispatch if the hint is not giving us negatives
    return Promise.resolve(this.agreementListRepo.fetchPendingForActiveUser()).then(() => {
      return {
        hasAgreements: this.agreementListRepo.hasPendingAgreements(),
        agreements: this.agreementListRepo.models
      };
    });
  }
}

module.exports = PendingUserAgreementChecker;
