/**
 * This file contains helper functions for working with strings. As we convert files into Typescript, functions from StringHelpers.js will be moved here. The "_" prefix is to prevent name conflicts with the StringHelpers.js file.
 */
import { isEmpty } from '.';

export function isEmptyString(value: string | null | undefined): boolean {
  if (isEmpty(value)) {
    return true;
  }

  return value?.trim().length === 0;
}
