// Module
var code = `<h1 class="page-header">
  <div class="ax-container">
    <div class="page-header__content">
      <span class="page-header__icon icon-profile" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('settings.title')%></span>
    </div>
  </div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="settingswrapper">
      <div>
        <div class="settingslistwrapper" role="navigation" aria-label="<%- t('settings.navigation') %>">
          <ul class="settingslist list"></ul>
        </div>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;