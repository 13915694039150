const AssessmentType = require('@common/data/enums/AssessmentType');

const HeadlessAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/HeadlessAssessmentInitiatorController');
const CertificationTrainingAssessmentController = require('@training/apps/training/controllers/assessments/CertificationTrainingAssessmentController');
const IntroductoryTrainingAssessmentController = require('@training/apps/training/controllers/assessments/IntroductoryTrainingAssessmentController');
const RefresherTrainingAssessmentController = require('@training/apps/training/controllers/assessments/RefresherTrainingAssessmentController');
const FormalExamAssessmentController = require('@training/apps/training/controllers/assessments/FormalExamAssessmentController');

module.exports = {
  create(assessmentType, parentProcessor, sessionModel, assessmentFactory) {
    const AssessmentController = (() => {
      switch (assessmentType) {
        case AssessmentType.ExtraTraining:
        case AssessmentType.DailyTraining:
          return HeadlessAssessmentInitiatorController;
        case AssessmentType.CertificationTraining:
          return CertificationTrainingAssessmentController;
        case AssessmentType.IntroductoryTraining:
          return IntroductoryTrainingAssessmentController;
        case AssessmentType.RefresherTraining:
          return RefresherTrainingAssessmentController;
        case AssessmentType.FormalExamTraining:
          return FormalExamAssessmentController;
        default:
          throw new Error(
            'Invalid AssessmentType used when trying to create an AssessmentController instance!'
          );
      }
    })();

    return new AssessmentController(parentProcessor, {
      sessionModel,
      assessmentType,
      assessmentFactory
    });
  }
};
