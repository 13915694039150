const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

const CatalogFilterView = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogFilterView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const CatalogBrowseTypeEnum = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogBrowseTypeEnum');

class CatalogFilterButtonView extends Marionette.LayoutView {
  initialize(options) {
    ({
      filterCollection: this.filterCollection,
      filterURLBuilder: this.filterURLBuilder,
      filterSubjectList: this.filterSubjectList
    } = options);

    this.listenTo(this.filterCollection, 'sync', this._setUpAttributes);
  }

  getTemplate() {
    return `
      <span class="icon-filter"></span>
      <span class="filter-applied-dot"></span>
    `;
  }

  ui() {
    return {
      filterAppliedDot: '.filter-applied-dot'
    };
  }

  className() {
    return 'catalog-filter__button';
  }

  tagName() {
    return 'button';
  }

  events() {
    return {
      click: 'onClick'
    };
  }

  onClick() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal modal--s learner-training-tab-content--catalog__modal'
    });

    const modalChildView = new CatalogFilterView({
      filterCollection: this.filterCollection,
      filterURLBuilder: this.filterURLBuilder,
      filterSubjectList: this.filterSubjectList,
      isRenderedInModal: true
    });

    window.app.layout.presentModal(modalView);
    modalView.setSubviewIn(modalChildView);

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  onRender() {
    this._setUpAttributes(this.filterCollection);
  }

  _setUpAttributes(collection) {
    const isFilterApplied = collection.getBrowseType() !== CatalogBrowseTypeEnum.ALL;

    this._toggleFilterAppliedDot(isFilterApplied);
    this._updateAriaLabel(isFilterApplied);
  }

  _toggleFilterAppliedDot(isFilterApplied) {
    this.ui.filterAppliedDot.toggle(isFilterApplied);
  }

  _updateAriaLabel(isFilterApplied) {
    const filterAppliedLabel = I18n.t('learnerTrainingPage.catalog.ariaLabel.filterApplied');
    const filterButtonLabel = I18n.t('learnerTrainingPage.catalog.ariaLabel.filterButton');

    const ariaLabel = (isFilterApplied ? filterAppliedLabel : '') + filterButtonLabel;
    this.$el.attr('aria-label', ariaLabel);
  }
}

module.exports = CatalogFilterButtonView;
