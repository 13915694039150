const $os = require('detectOS');
const logging = require('logging');
const {
  defer,
  extend,
  isEmpty,
  isObject
} = require('underscore');
const {
  Wreqr,
  Events
} = require('Backbone');

const NavPosition = require('@common/data/enums/NavPosition');
const IntegrationTypeEnum = require('@common/data/enums/IntegrationTypeEnum');
const AppConfigTypeEnum = require('@common/data/enums/AppConfigTypeEnum');

const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');
const NativeAndroidHandler = require('@common/libs/NativeAndroidHandler');

class NativeBridge {

  constructor() {
    this.handleMessage = this.handleMessage.bind(this);
    this._canShowAttachments = this._canShowAttachments.bind(this);
    this._canDownload = this._canDownload.bind(this);
    this._canPrint = this._canPrint.bind(this);
    this._canStoreUserCredentials = this._canStoreUserCredentials.bind(this);
    this._canOpenUrl = this._canOpenUrl.bind(this);
    this._supportAutoSignIn = this._supportAutoSignIn.bind(this);
    this._supportAppConfig = this._supportAppConfig.bind(this);
    this._requestAppConfig = this._requestAppConfig.bind(this);
    this._shouldShowReset = this._shouldShowReset.bind(this);
    this._nativeAuthRequired = this._nativeAuthRequired.bind(this);
    this._canReceivePushNotifications = this._canReceivePushNotifications.bind(this);
    this._retrieveCredentials = this._retrieveCredentials.bind(this);
    this._supportedOAuthProviders = this._supportedOAuthProviders.bind(this);
    this._oauthLogin = this._oauthLogin.bind(this);
    this._reauthenticateOAuth = this._reauthenticateOAuth.bind(this);
    this._requestPushData = this._requestPushData.bind(this);
    this._notifyWrapperWeLoaded = this._notifyWrapperWeLoaded.bind(this);
    this._isInThirdPartyApp = this._isInThirdPartyApp.bind(this);
    this._canShowNativeAndroidVideoFullscreen = this._canShowNativeAndroidVideoFullscreen.bind(this);
    this._canOpenChildWebViews = this._canOpenChildWebViews.bind(this);
    this._getNavPosition = this._getNavPosition.bind(this);
    this._canShowMobileReviews = this._canShowMobileReviews.bind(this);
    this._getMobileAppInfo = this._getMobileAppInfo.bind(this);
    this._getIntegrationType = this._getIntegrationType.bind(this);
    this._getMsTeamsContentUrl = this._getMsTeamsContentUrl.bind(this);
    this._isMsTeamsIntegration = this._isMsTeamsIntegration.bind(this);
    this._setSupportedFeatures = this._setSupportedFeatures.bind(this);

    this.androidHandler = new NativeAndroidHandler(this);
    this.androidHandler.registerBackHandler(() => {
      if (window.history.length > 0) {
        window.history.back();
        return true;
      }

      return false;
    });

    // Initialize the versionData and supportedFeatures to default values
    this._setVersionData();

    this._setSupportedFeatures();

    // initialize native bridge channel and listen for messages
    this.nbChannel = Wreqr.radio.channel('nativeBridge');

    // vents
    this.nbChannel.vent.on('appHasLoaded', () => {
      this._notifyWrapperWeLoaded();
    });
    this.nbChannel.vent.on('resetApp', () => {
      this.sendMessage({
        action: 'resetApp'
      });
    });
    this.nbChannel.vent.on('enableScalingInWebView', () => {
      BrowserHelpers.setScalingAndZooming(true);
      this.sendMessage({
        action: 'enableScalingInWebView'
      });
    });
    this.nbChannel.vent.on('disableScalingInWebView', () => {
      BrowserHelpers.setScalingAndZooming(false);
      this.sendMessage({
        action: 'disableScalingInWebView'
      });
    });
    this.nbChannel.vent.on('requestNotificationPayload', () => {
      this.sendMessage({
        action: 'requestNotificationPayload'
      });
    });

    // reqres
    this.nbChannel.reqres.setHandler('isInApp', this._isInApp);
    this.nbChannel.reqres.setHandler('canDownload', this._canDownload);
    this.nbChannel.reqres.setHandler('canPrint', this._canPrint);
    this.nbChannel.reqres.setHandler('canShowAttachments', this._canShowAttachments);
    this.nbChannel.reqres.setHandler('canStoreUserCredentials', this._canStoreUserCredentials);
    this.nbChannel.reqres.setHandler('canOpenUrl', this._canOpenUrl);
    this.nbChannel.reqres.setHandler('supportAutoSignIn', this._supportAutoSignIn);
    this.nbChannel.reqres.setHandler('supportAppConfig', this._supportAppConfig);
    this.nbChannel.reqres.setHandler('requestAppConfig', this._requestAppConfig);
    this.nbChannel.reqres.setHandler('shouldShowReset', this._shouldShowReset);
    this.nbChannel.reqres.setHandler('nativeAuthRequired', this._nativeAuthRequired);
    this.nbChannel.reqres.setHandler('retrieveCredentials', this._retrieveCredentials);
    this.nbChannel.reqres.setHandler('supportedOAuthProviders', this._supportedOAuthProviders);
    this.nbChannel.reqres.setHandler('oauthLogin', this._oauthLogin);
    this.nbChannel.reqres.setHandler('reauthenticateOAuth', this._reauthenticateOAuth);
    this.nbChannel.reqres.setHandler('requestPushData', this._requestPushData);
    this.nbChannel.reqres.setHandler('isInThirdPartyApp', this._isInThirdPartyApp);
    this.nbChannel.reqres.setHandler('canShowNativeAndroidVideoFullscreen', this._canShowNativeAndroidVideoFullscreen);
    this.nbChannel.reqres.setHandler('canOpenChildWebViews', this._canOpenChildWebViews);
    this.nbChannel.reqres.setHandler('getNavPosition', this._getNavPosition);
    this.nbChannel.reqres.setHandler('canShowMobileReviews', this._canShowMobileReviews);
    this.nbChannel.reqres.setHandler('getMobileAppInfo', this._getMobileAppInfo);
    this.nbChannel.reqres.setHandler('getIntegrationType', this._getIntegrationType);
    this.nbChannel.reqres.setHandler('getMsTeamsContentUrl', this._getMsTeamsContentUrl);
    this.nbChannel.reqres.setHandler('isMsTeamsIntegration', this._isMsTeamsIntegration);
    this.nbChannel.commands.setHandler('setSupportedFeatures', this._setSupportedFeatures);

    // commands
    this.nbChannel.commands.setHandler('registerBackHandler', this.androidHandler.registerBackHandler);
    this.nbChannel.commands.setHandler('unregisterBackHandler', this.androidHandler.unregisterBackHandler);
    this.nbChannel.commands.setHandler('storeCredentials', (data = {}) => {
      this.sendMessage({
        action: 'storeCredentials',
        data
      });
    });
    this.nbChannel.commands.setHandler('clearCredentials', () => {
      return this.sendMessage({
        action: 'clearCredentials'
      });
    });
    this.nbChannel.commands.setHandler('openUrl', (data = {}) => {
      return this.sendMessage({
        action: 'openUrl',
        data
      });
    });
    this.nbChannel.commands.setHandler('authenticationRequired', (data = {}) => {
      return this.sendMessage({
        action: 'authenticationRequired',
        data
      });
    });
    // Tell the native wrapper to show "Review this app" modal
    this.nbChannel.commands.setHandler('showReviewMobileApp', () => {
      return this.sendMessage({
        action: 'showReviewMobileApp'
      });
    });
  }

  sendMessage(msg) {
    const webkit = window.webkit;
    if (webkit && webkit.messageHandlers && webkit.messageHandlers.axonifyMessageHandler) {
      // use the message handler if it exists
      window.webkit.messageHandlers.axonifyMessageHandler.postMessage(msg);

    } else if ($os.ios && ($os.browser !== 'safari') && ($os.browser !== 'chrome')) {
      // Check $os.browser to make sure we are in the native wrapper, not Mobile Safari.

      // message handler doesn't exists so use old way by creating a
      // request to pass the message to the wrapper
      let iframe = document.createElement('iframe');
      iframe.setAttribute('src', `js:${ encodeURIComponent(JSON.stringify(msg)) }`);
      document.documentElement.appendChild(iframe);
      iframe.parentNode.removeChild(iframe);
      iframe = null;
    } else if ($os.android) {
      try {
        window.AxonifyAndroid.sendMessage(JSON.stringify(msg));
      } catch (error) { /*yummy*/ }
    } else if ($os.windowsphone) {
      try {
        window.external.notify(JSON.stringify(msg));
      } catch (error) { /*yummy*/ }
    }
  }

  handleMessage(msg = {}) {
    switch (msg.action) {
      case 'appConfigResponse':
        if (isObject(msg.data)) {
          this.supportedFeatures.appConfig = msg.data;

          for (const [key, value] of Object.entries(msg.data)) {
            this.trigger(`appConfigResponse:${ key }`, value);
          }
        }
        break;
      case 'appDidBecomeActive':
        this.nbChannel.vent.trigger('appDidBecomeActive');
        break;
      case 'appDidEnterBackground':
        this.nbChannel.vent.trigger('appDidEnterBackground');
        break;
      case 'setPushData':
        this._setVersionData(msg.data.versionData);
        this.trigger('setPushData', msg.data);
        break;
      case 'backPressed':
        this.androidHandler.backPressed();
        break;
      case 'credentials':
        this.trigger('credentials', msg.data);
        break;
      case 'mobileAppInfo':
        this.trigger('mobileAppInfo', msg.data);
        break;
      case 'oauthAuthState': {
        const {
          error,
          exception
        } = msg.data != null ? msg.data : {};
        if (error != null) {
          logging.error(`oauthAuthState error = \`${ JSON.stringify(error) }\``);
        }
        if (exception != null) {
          logging.error(`oauthAuthState exception = \`${ exception }\``);
        }
        this.trigger('oauthAuthState', msg.data);
        break;
      }
      case 'urlClosed': {
        const {
          errorCode,
          errorName,
          errorDescription
        } = msg.data != null ? msg.data : {};
        if (errorCode != null) {
          let errorMsg = `closeUrl error with code:\`${ errorCode }\``;
          if (errorName != null) {
            errorMsg += ` errorName:\`${ errorName }\``;
          }
          if (errorDescription != null) {
            errorMsg += ` errorDescription:\`${ errorDescription }\``;
          }
          logging.error(errorMsg);
        }
        this.nbChannel.vent.trigger('urlClosed', msg.data);
        break;
      }
      case 'notificationPayload':
        this.nbChannel.vent.trigger('notificationPayload', msg.data);
        break;
      default:
        logging.warn(`NativeBridge - "${ msg.action }" case is not handled`);
    }
  }

  _isInApp() {
    return $os.isInMobileApp();
  }

  _canDownload() {
    return !this._isInApp() && !$os.msTeamsMobileApp;
  }

  _canPrint() {
    return !$os.mobile && !$os.tablet && !$os.msTeamsDesktopApp;
  }

  _canShowAttachments() {
    // Axonifylib provides this as a supported feature so we can bypass the rest
    if (this._isInApp() && this.supportedFeatures.fileUploadEnabled) {
      return true;
    }

    if ($os.android) {
      const isSupportedAndroid = this.versionData.buildVersionAPI > 19;
      return !this._isInApp() || ((this.versionData.appVersionCode >= 5) && isSupportedAndroid);
    }

    return true;
  }

  _canStoreUserCredentials() {
    return this.supportedFeatures.userCredentials;
  }

  _canOpenUrl() {
    return this._isInApp() && this.supportedFeatures.openUrl;
  }

  _supportAutoSignIn() {
    return this._isInApp() && (LocalStorageHelpers.supportsLocalStorage() || this._canStoreUserCredentials());
  }

  _supportAppConfig(key) {
    try {
      AppConfigTypeEnum.assertLegalValue(key);
    } catch (e) {
      logging.warn(`${ key } is not a valid App Config type.`);
      return false;
    }

    if (!this._isInApp()) {
      return false;
    }

    const appConfig = this.supportedFeatures.appConfig;

    return appConfig.enabled && (appConfig.supportsArbitraryKeys || appConfig.supportedKeys.includes(key));
  }

  _requestAppConfig(key) {
    return new Promise((resolve) => {
      if (this.supportedFeatures.appConfig[key] != null) {
        resolve(this.supportedFeatures.appConfig[key]);
      } else {
        this.listenToOnce(this, `appConfigResponse:${ key }`, (value) => {
          return resolve(value ?? []);
        });

        this.sendMessage({
          action: 'requestAppConfig',
          data: {
            appConfigKey: key
          }
        });
      }
    });
  }

  _shouldShowReset() {
    return this._isInApp() && this.supportedFeatures.shouldShowReset;
  }

  _nativeAuthRequired() {
    return this._isInApp() && (this.supportedFeatures.shouldShowLogin === false);
  }

  _canReceivePushNotifications() {
    return this._isInApp() && this.supportedFeatures.pushNotifications;
  }

  _canShowNativeAndroidVideoFullscreen() {
    return this._isInApp() && this.supportedFeatures.nativeAndroidFullscreenEnabled;
  }

  _canOpenChildWebViews() {
    return this._isInApp() && this.supportedFeatures.allowChildWebViews;
  }

  _getNavPosition() {
    return this.supportedFeatures.navPosition;
  }

  _canShowMobileReviews() {
    return this._isInApp() && this.supportedFeatures.mobileReviews === true;
  }

  // This is to distinguish between the Axonify Mobile App and tenants of whom
  // used our AxonifyLib SDK to incorporate Axonify within their app.
  _isInThirdPartyApp() {
    // To make it less confusing for client JS code, isInAxonifyApp is negated and
    // renamed to isInThirdPartyApp.
    return this._isInApp() && !this.supportedFeatures.isInAxonifyApp;
  }

  _retrieveCredentials() {
    const deferred = $.Deferred();

    this.listenToOnce(this, 'credentials', (credentials = {}) => {
      if (isObject(credentials) && !isEmpty(credentials)) {
        return deferred.resolve(credentials);
      }
      return deferred.reject(credentials);

    });

    this.sendMessage({
      action: 'retrieveCredentials'
    });

    return deferred.promise();
  }

  _supportedOAuthProviders() {
    //Check if app supports new Auth implementation
    if (this.supportedFeatures.arbitraryAuthenticationProviders) {
      if (this.supportedFeatures.nativeAuth.supportsArbitrarySchemes) {
        //This is for iOS case where this will be true
        return true;
      } else if (!this.supportedFeatures.nativeAuth.supportsArbitrarySchemes && this.supportedFeatures.nativeAuth.supportedSchemes) {
        //This will be Android where the specific providers will be provided
        return this.supportedFeatures.nativeAuth.supportedSchemes;
      }
    }
    //This is for legacy apps that will return a list of oauthProviders
    return this.supportedFeatures.oauthProviders;
  }

  _oauthLogin(data = {}) {
    const deferred = $.Deferred();

    this.listenToOnce(this, 'oauthAuthState', (authState = {}) => {
      if ((authState.error != null) || (authState.exception != null)) {
        return deferred.reject(authState);
      }
      return deferred.resolve(authState);

    });

    this.sendMessage({
      action: 'oauthLogin',
      data
    });

    return deferred.promise();
  }

  _reauthenticateOAuth(data = {}) {
    const deferred = $.Deferred();

    this.listenToOnce(this, 'oauthAuthState', (authState = {}) => {
      if ((authState.error != null) || (authState.exception != null)) {
        return deferred.reject(authState);
      }
      return deferred.resolve(authState);

    });

    this.sendMessage({
      action: 'reauthenticateOAuth',
      data
    });

    return deferred.promise();
  }

  _requestPushData() {
    const deferred = $.Deferred();

    if (this._canReceivePushNotifications()) {
      this.listenToOnce(this, 'setPushData', (data = {}) => {
        if (data.pushData != null) {
          return deferred.resolve(data);
        }
        return deferred.reject(data);

      });

      this.sendMessage({
        action: 'requestPushData'
      });
    } else {
      defer(() => {
        return deferred.reject({
          error: 'Push Notifications are either disabled or not supported on this device.'
        });
      });
    }

    return deferred.promise();
  }

  _getMobileAppInfo() {
    return new Promise((resolve, reject) => {
      if (this._canShowMobileReviews()) {
        this.listenToOnce(this, 'mobileAppInfo', (appInfo) => {
          if (isObject(appInfo) && !isEmpty(appInfo)) {
            resolve(appInfo);
          }
          reject(new Error('appInfo doesnt contain data'));
        });

        this.sendMessage({
          action: 'getMobileAppInfo'
        });
      } else {
        reject(new Error('Mobile reviews are not supported'));
      }
    });
  }

  _getIntegrationType() {
    return this.supportedFeatures.integrationType; // TODO: confirm if we should include 'this._isInApp() &&'
  }

  _getMsTeamsContentUrl() {
    return this.supportedFeatures.msTeamsContentUrl;
  }

  _isMsTeamsIntegration() {
    return this._getIntegrationType() === IntegrationTypeEnum.MSTEAMS;
  }

  // Tell the native wrapper the page loaded.
  _notifyWrapperWeLoaded() {
    this.sendMessage({
      action: 'hideLoadingView'
    });
    if ($os.mobile) {
      this.nbChannel.vent.trigger('disableScalingInWebView');
    }
  }

  _setVersionData(versionData = {}) {
    this.versionData = versionData;
    if (this.versionData.buildVersionAPI == null) {
      this.versionData.buildVersionAPI = -1;
    }
    if (this.versionData.appVersionCode == null) {
      this.versionData.appVersionCode = -1;
    }
  }

  _setSupportedFeatures() {
    let supportedFeatures, supportedFeaturesObj = {};
    try {
      supportedFeatures = sessionStorage.getItem('supportedFeatures');
      if (!supportedFeatures) {
        supportedFeatures = localStorage.getItem('supportedFeatures');
      }
      // Since query parameters are before the `#` and originally we used
      // `window.location.href` instead of `window.location.search` some data got
      // stored as `{...}#hub` which is invalid JSON at that point so we now need
      // to exclude the `#` and anything following it.
      const supportedFeaturesStr = (supportedFeatures != null ? supportedFeatures : '{}').replace(/#[^}]+$/, '');
      supportedFeaturesObj = JSON.parse(supportedFeaturesStr);
    } catch (e) {
      supportedFeaturesObj = {};
      logging.error(`Error reading supportedFeatures \`${ supportedFeatures }\` from localStorage: ${ e }`);
    }
    this._updateSupportedFeatures(supportedFeaturesObj);
  }

  _updateSupportedFeatures(supportedFeatures = {}) {
    this.supportedFeatures = supportedFeatures;
    if (this.supportedFeatures.userCredentials == null) {
      this.supportedFeatures.userCredentials = false;
    }
    if (this.supportedFeatures.oauthProviders == null) {
      this.supportedFeatures.oauthProviders = [];
    }
    if (this.supportedFeatures.openUrl == null) {
      this.supportedFeatures.openUrl = false;
    }
    if (this.supportedFeatures.shouldShowReset == null) {
      this.supportedFeatures.shouldShowReset = true;
    }
    if (this.supportedFeatures.shouldShowLogin == null) {
      this.supportedFeatures.shouldShowLogin = true;
    }
    if (this.supportedFeatures.pushNotifications == null) {
      this.supportedFeatures.pushNotifications = true;
    }
    if (this.supportedFeatures.isInAxonifyApp == null) {
      this.supportedFeatures.isInAxonifyApp = true;
    }
    if (this.supportedFeatures.fileUploadEnabled == null) {
      this.supportedFeatures.fileUploadEnabled = false;
    }
    if (this.supportedFeatures.nativeAndroidFullscreenEnabled == null) {
      this.supportedFeatures.nativeAndroidFullscreenEnabled = false;
    }
    if (this.supportedFeatures.navPosition == null) {
      this.supportedFeatures.navPosition = NavPosition.bottom;
    }
    if (this.supportedFeatures.mobileReviews == null) {
      this.supportedFeatures.mobileReviews = false;
    }
    if (this.supportedFeatures.integrationType == null) {
      this.supportedFeatures.integrationType = IntegrationTypeEnum.NONE;
    }
    if (this.supportedFeatures.appConfig == null) {
      this.supportedFeatures.appConfig = {};
    }
    if (this.supportedFeatures.nativeAuth == null) {
      this.supportedFeatures.nativeAuth = {};
    }
    if (this.supportedFeatures.appConfig.supportedKeys == null) {
      this.supportedFeatures.appConfig.supportedKeys = [];
    }
  }
}

extend(NativeBridge.prototype, Events);

module.exports = NativeBridge;
