const BLACKLIST_ERROR_ENDPOINTS = [
  new RegExp('tenant/.*/.*(js|imsmanifest.xml)')
];

module.exports = {
  isValid: (url) => {
    return BLACKLIST_ERROR_ENDPOINTS.some((regExp) => {
      return !regExp.test(url);
    });
  }
};
