import {
  createContext,
  useCallback,
  useContext,
  useRef,
  type PropsWithChildren
} from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import { getValue } from '@common/libs/helpers/types/ObjectHelpers';
import {
  get,
  isEmpty,
  isPlainObject,
  isString,
  set,
  type GetFieldType
} from 'lodash';

function deepMerge<T extends object>(targetState: T, srcState: Subset<T>): T {
  return isEmpty(srcState) ? targetState : Object.entries(srcState).reduce<T>((memo, [key, value]) => {
    // @ts-expect-error - not sure how to fix this yet
    const currentValue = memo[key];

    // @ts-expect-error - not sure how to fix this yet
    memo[key] = isPlainObject(value) && isPlainObject(currentValue) ? deepMerge(currentValue, value) : value;

    return memo;
  }, {...targetState});
}

// Ignoring this error to get better type inference
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function createContextHelper<Store extends IndexableObject<Store> = EmptyObject>(inState?: Store) {
  type UpdaterFunction = (store: Store) => Subset<Store>;
  type SetStoreValue = Subset<Store> | UpdaterFunction;
  type SetStoreOptions = { deep?: boolean };
  type Selector<Output> = (store: Store) => Output;
  type StoreKeys = StringKeys<Store> | `${ StringKeys<Store> }.${ string }`;

  function useStoreData(initState: Store) {
    const store = useRef(initState);
    const subscribers = useRef(new Set<() => void>());

    const getStoreData = useCallback(() => {
      return store.current;
    }, []);

    function setStoreData(path: SetStoreValue, value?: SetStoreOptions): void;
    function setStoreData<
      TPathValue extends (GetFieldType<Store, TPath> | unknown),
      TPath extends string
    >(path: TPath, value: TPathValue, options?: SetStoreOptions): void;
    function setStoreData<
      TPathValue extends (GetFieldType<Store, TPath> | unknown),
      TPath extends string
    >(path: TPath | SetStoreValue, value?: TPathValue | SetStoreOptions, options?: SetStoreOptions) {
      let opts: SetStoreOptions | undefined;
      let calcValue: Subset<Store>;
      
      if (typeof path === 'string') {
        calcValue = set<Subset<Store>>({}, path, value);
        opts = options;
      } else {
        calcValue = getValue(path, path, store.current);
        opts = value as SetStoreOptions | undefined;
      }
  
      store.current = (opts?.deep ?? true) ? deepMerge(store.current, calcValue) : {
        ...store.current,
        ...calcValue
      };

      subscribers.current.forEach((callback) => {
        return callback();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedSetStoreData = useCallback(setStoreData, []);

    const subscribeToStoreData = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => {
        return subscribers.current.delete(callback);
      };
    }, []);

    return {
      get: getStoreData,
      set: memoizedSetStoreData,
      subscribe: subscribeToStoreData
    };
  }

  type UseStoreDataReturnType = ReturnType<typeof useStoreData>;
  type SetStore = UseStoreDataReturnType['set'];
  const StoreContext = createContext<UseStoreDataReturnType | null>(null);
  let initState: Store;

  function Provider(props: PropsWithChildren<{ initialState?: Store }>): JSX.Element {
    const {
      children,
      initialState
    } = props;

    if (initialState != null) {
      initState = initialState;
    } else if (inState != null) {
      initState = inState;
    } else {
      throw new Error('\'initialState\' must be passed into the Provider if no initialState was passed to \'createContextHelper()\' initially.');
    }

    const value = useStoreData(initState);

    return (
      <StoreContext.Provider value={ value }>
        { children }
      </StoreContext.Provider>
    );
  }

  function useStore(): SetStore;
  function useStore<SelectorOutput extends GetFieldType<Store, TPath>, TPath extends StoreKeys>(selector: TPath): [SelectorOutput, SetStore];
  function useStore<SelectorOutput>(selector: Selector<SelectorOutput>): [SelectorOutput, SetStore];
  function useStore<SelectorOutput>(selector?: string | Selector<SelectorOutput>): SetStore | [SelectorOutput, SetStore] {
    const store = useContext(StoreContext);
    
    if (store == null) {
      throw new Error('Store not found');
    }

    let storeSelector;

    if (isString(selector)) {
      storeSelector = (st: Store) => {
        return get(st, selector);
      };
    } else if (selector != null) {
      storeSelector = selector;
    } else {
      // When selector is undefined, we return store.set since we only need to update the store. The selector needs to return a static value which is used by useSyncExternalStoreWithSelector to prevent a re-render.
      storeSelector = () => {
        return undefined;
      };
    }

    const state = useSyncExternalStoreWithSelector(
      store.subscribe,
      () => {
        return store.get();
      },
      () => {
        return initState;
      },
      storeSelector
    );

    if (selector === undefined) {
      return store.set;
    }

    return [
      state,
      store.set
    ];
  }

  return {
    Provider,
    useStore
  };
}
