import uiKit from '@common/modules/react/themes/UiKit';
import {
  Card as MuiCard,
  styled,
  type CardTypeMap as MuiCardTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { type CSSObject } from '@mui/system';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type CARD_VARIANTS =
  'default' |
  'informational' |
  'interactive';

export type CARD_PADDING =
  undefined |
  'none' |
  'small' |
  'medium' |
  'large';

export const getCardPadding = (paddingProp: CARD_PADDING): string => {
  let innerPadding = '0';

  if (paddingProp === 'small') {
    innerPadding = uiKit.cardSpacingS;
  } else if (paddingProp === 'medium') {
    innerPadding = uiKit.cardSpacingM;
  } else if (paddingProp === 'large') {
    innerPadding = uiKit.cardSpacingL;
  }

  return innerPadding;
};

declare module '@mui/material/Paper' {
  // Card inherits variants from Paper (a component we likely won't be using)
  export interface PaperPropsVariantOverrides {
    'default': true
    'informational': true
    'interactive': true
    'elevation': false
    'outlined': false
  }
}

type AxCardCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  variant?: CARD_VARIANTS
  padding?: CARD_PADDING
  tabIndex?: number
  role?: string
};

export type AxCardTypeMap<P = object, D extends React.ElementType = 'div'> = MuiCardTypeMap<P & AxCardCustomProps, D>;

export type AxCardProps<
  D extends ElementType = AxCardTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxCardTypeMap<P & AxCardCustomProps, D>, D>;

const styledOptions = {
  name: 'AxCard',
  shouldForwardProp: (prop: string) => {
    return prop !== 'variant' && prop !== 'padding';
  }
};

const StyledAxCard = styled(MuiCard, styledOptions)<AxCardProps>(({
  variant,
  padding,
  theme
}) => {
  const defaultStyles: CSSObject = {
    border: theme.uiKit.cardBorder,
    borderRadius: theme.uiKit.cardBorderRadius,
    boxShadow: theme.uiKit.cardBoxShadow,
    position: 'relative',
    padding: getCardPadding(padding),
    display: 'block'
  };

  if (variant === 'informational') {
    return {
      ...defaultStyles,
      boxShadow: theme.uiKit.cardBoxShadowInformational
    };
  }

  if (variant === 'interactive') {
    return {
      ...defaultStyles,
      WebkitFontSmoothing: 'subpixel-antialiased',
      backfaceVisibility: 'hidden',
      cursor: 'pointer',
      top: 0,
      transform: 'translateZ(0)',
      transition: theme.uiKit.cardInteractiveTransition,
      zIndex: 1,
      ...theme.mixins.retinaMediaQuery({ WebkitFontSmoothing: 'antialised' }),
      '&:hover': {
        boxShadow: theme.uiKit.cardBoxShadowInteractiveHover,
        top: theme.uiKit.cardInteractiveTopHover
      },
      '&:focus': theme.mixins.componentKeyboardFocusStyles()
    };
  }

  return defaultStyles;
});

export const AxCard: MuiOverridableComponent<AxCardTypeMap> = forwardRef(({
  children,
  onClick,
  padding = 'none',
  variant = 'default',
  ...otherProps
}: AxCardProps, ref: Ref<HTMLDivElement>) => {
  const cardProps: AxCardProps = {
    children,
    onClick,
    padding,
    variant,
    ...otherProps
  };

  return (
    <StyledAxCard { ...cardProps } ref={ ref }>
      { children }
    </StyledAxCard>
  );
});

export default AxCard;
