const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

require('chosen');
require('@common/vendor/chosen/chosen.css');
require('@common/components/forms/editors/axonSelect/AxonSelect.less');

Form.Editor.AxonSelect = class AxonSelect extends Form.Editor.Select {

  static blockArrowNavigationClassName = 'chosen-container';

  preinitialize(options) {
    super.preinitialize(options);

    // XXX - These are hard coded values pulled from AxonSelect.less styles to get the dynamic calculations right
    // when updating the containers width to be calculated like a native select elements width would be.
    this._dropdownSelectedItemLeftBorderWidth = 4;
    this._dropdownItemPaddingLeft = 6;
    this._dropdownItemPaddingRight = 6;

    this._selectedDisplayPadding = 8;
    this._selectedChevronIconWidth = 10;
    this._selectedDisplayPaddingLeft = this._selectedDisplayPadding;
    this._selectedDisplayPaddingRight = this._selectedDisplayPadding + this._selectedChevronIconWidth + this._selectedDisplayPadding;

    this._chosenBorderWidth = 2;

    // Proxy Chosen events to AxonSelect events using Marionette triggered methods
    this._chosenToAxonEventMap = {
      'chosen:ready': 'ready',
      'chosen:maxselected': 'max:selected',
      'chosen:showing_dropdown': 'show:dropdown',
      'chosen:hiding_dropdown': 'hide:dropdown',
      'chosen:no_results': 'no:results',
      'chosen:updated': 'update'
    };

    this._axonToChosenOptionKeyMap = {
      disableSearchThreshold: 'disable_search_threshold',
      inheritSelectClasses: 'inherit_select_classes',
      singlePlaceholderText: 'placeholder_text_single',
      multiplePlaceholderText: 'placeholder_text_multiple',
      noResultsText: 'no_results_text',
      disableSearch: 'disable_search',
      searchContains: 'search_contains'
    };

    this.setAxonSelectOptions(options != null && options.options != null ? options.options.axonSelectOptions : undefined);
  }

  initializeOptions(options) {
    if (options != null) {
      super.initializeOptions(options);
    } else if (this._axonSelectOptions.useChosen) {
      this._updateChosenWidth();
    } else {
      this._updateWidth();
    }
  }

  setAxonSelectOptions(options = {}) {
    this._axonSelectOptions = _.extend({}, _.result(this, 'defaultAxonSelectOptions'), this.axonSelectOptions, options);
  }

  defaultAxonSelectOptions() {
    return {
      disableSearchThreshold: 5,
      inheritSelectClasses: true,
      singlePlaceholderText: I18n.t('UIKit.Form.Editor.Select.select'),
      multiplePlaceholderText: I18n.t('UIKit.Form.Editor.AxonSelect.multiSelect'),
      noResultsText: I18n.t('UIKit.Form.Editor.AxonSelect.noResults'),
      rtl: I18n.isCurrentLanguageRtl(),
      searchContains: true,
      // Can be overidden to avoid using the chosen library, and a natural html <select> element is used (good for accessibility)
      useChosen: true,
      // 'auto' here means we do our own width calculations based on the contents of the list, just like a real select
      // would.
      width: 'auto'
    };
  }

  _setElement(element) {
    super._setElement(element);
    if (this._axonSelectOptions.useChosen) {
      this._initChosen();
    }
  }

  delegateEvents(...args) {
    super.delegateEvents(...args);
    if (this._axonSelectOptions.useChosen) {
      this._delegateChosenEvents();
    }
  }

  undelegateEvents(...args) {
    if (this._axonSelectOptions.useChosen) {
      this._undelegateChosenEvents();
    }
    super.undelegateEvents(...args);
  }

  renderOptions(...args) {
    super.renderOptions(...args);
    this.updateAxonSelect();
    if (this._axonSelectOptions.useChosen) {
      this._updateChosenWidth();
    } else {
      this._updateWidth();
    }
  }

  setValue(value) {
    super.setValue(value);
    this.updateAxonSelect();
  }

  disable(...args) {
    super.disable(...args);
    this.updateAxonSelect();
  }

  enable(...args) {
    super.enable(...args);
    this.updateAxonSelect();
  }

  setError() {
    if (this._axonSelectOptions.useChosen) {
      this._chosenObj.container.addClass('rederror');
    } else {
      this.$el.addClass('rederror');
    }
  }

  clearError() {
    if (this._axonSelectOptions.useChosen) {
      this._chosenObj.container.removeClass('rederror');
    } else {
      this.$el.removeClass('rederror');
    }
  }

  getSearchEl() {
    if (this._axonSelectOptions.useChosen) {
      return this._chosenObj['search_field'];
    }
    return undefined;
  }

  getDropdownEl() {
    if (this._axonSelectOptions.useChosen) {
      return this._chosenObj.dropdown;
    }
    return undefined;
  }

  isAutoWidth() {
    return this._axonSelectOptions.width === 'auto';
  }

  updateAxonSelect() {
    // Keep triggered events signature consistent with other axonSelect events.
    if (this._axonSelectOptions.useChosen) {
      this.$el.trigger('chosen:updated');
    }
  }

  destroy(...args) {
    // Doing this in `destroy` instead of `onBeforeDestroy` to ensure subclasses don't need to worry about calling
    // super in their handlers.
    if (this._axonSelectOptions.useChosen) {
      this.$el.chosen('destroy');
    }
    super.destroy(...args);
  }

  _initChosen() {
    this.$el.chosen(this._getChosenOptions());
    this._chosenObj = this.$el.data('chosen');

    if (!this._chosenObj) {
      // this means that chosen isn't supported.
      this._chosenObj = {
        container: this.$el,
        dropdown: $()
      };
      this._chosenObj['search_field'] = $();
    }
  }

  _updateChosenWidth() {
    if (!this.isMultiSelect && this.isAutoWidth()) {
      const dropDownStyleWidth = this._getDropdownStyleWidth();
      const selectedDisplayStyleWidth = this._getSelectedDisplayStyleWidth();

      const maxStyleWidth = Math.max(dropDownStyleWidth, selectedDisplayStyleWidth);

      const borderWidth = this._chosenBorderWidth;
      const maxOptionsTextWidth = this._getMaxOptionsTextWidth();
      this._chosenObj.container.width(borderWidth + maxStyleWidth + maxOptionsTextWidth + 1); //+1 for any potential rounding issues
    }
  }

  _updateWidth() {
    this.$el.width(this._axonSelectOptions.width);
  }

  _getDropdownStyleWidth() {
    const itemStyleMeasurements = this._getItemStyleMeasurements();
    const scrollbarWidth = BrowserHelpers.getScrollbarWidth();

    return itemStyleMeasurements + scrollbarWidth;
  }

  _getMaxOptionsTextWidth() {
    const optionText = _.reduce(this.$('option'), (memo, optionEl) => {
      return memo + `${ $(optionEl).text() }<br/>`;
    }, '');

    return HTMLHelpers.getTextWidth(optionText);
  }

  _getItemStyleMeasurements() {
    return this._dropdownSelectedItemLeftBorderWidth + this._dropdownItemPaddingLeft + this._dropdownItemPaddingRight;
  }

  _getSelectedDisplayStyleWidth() {
    return this._selectedDisplayPaddingLeft + this._selectedDisplayPaddingRight;
  }

  _getChosenOptions() {
    const chosenOptions = {};

    _.each(this._axonSelectOptions, (val, key) => {
      const chosenKey = this._axonToChosenOptionKeyMap[key] != null ? this._axonToChosenOptionKeyMap[key] : key;
      chosenOptions[chosenKey] = val;
    });

    return chosenOptions;
  }

  _getChosenEventMap() {
    return _.result(this, '_chosenToAxonEventMap');
  }

  _delegateChosenEvents() {
    if (this.$el != null) {
      _.each(this._getChosenEventMap(), (axonEvent, chosenEvent) => {
        const handler = (...args) => {
          this.triggerMethod(axonEvent, ...args);
        };

        this.delegate(chosenEvent, this.$el, _.bind(handler, this));
      });
    }
    return this;
  }

  _undelegateChosenEvents() {
    if (this.$el != null) {
      _.each(this._getChosenEventMap(), (axonEvent, chosenEvent) => {
        this.undelegate(chosenEvent);
      });
    }
    return this;
  }
};

module.exports = Form.Editor.AxonSelect;
