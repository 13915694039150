const _ = require('underscore');
const Polyglot = require('@common/data/models/translationStrings/Polyglot');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class TranslatableString extends Polyglot {
  initialize(attributes, options) {
    this.className = 'TranslableString';
    this.translationProperty = 'values';
    super.initialize(attributes, options);
  }

  toJSON(options) {
    const json = super.toJSON(options);

    _.each(this.get(this.translationProperty), (value, k) => {
      if (!HTMLHelpers.htmlHasSomeText(value)) {
        json.values[k] = '';
      }
    });

    return json;
  }

  getValues() {
    return this.get('values');
  }

  getAvailableTranslationLangCodes() {
    return Object.keys(this.getValues());
  }

  _getFallbackValue() {
    const attributes = this._safeGetTranslationAttributes();
    return attributes['EN'];
  }

  static fromString(str, language = 'EN') {
    const translatableStr = new TranslatableString();
    translatableStr.setValueForLanguage(language, str);
    return translatableStr;
  }

  static fromValue(value) {
    if (value == null) {
      return undefined;
    }

    if (_.isString(value)) {
      return this.fromString(value);
    } else if (_.isObject(value)) {
      return new TranslatableString(value);
    }

    return undefined;
  }
}

module.exports = TranslatableString;
