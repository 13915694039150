const {
  CollectionView,
  LayoutView
} = require('Marionette');
const CommunityNavButtonItemView = require('@common/components/discover/views/CommunityNavButtonItemView');

class RecentCommunitiesView extends LayoutView {
  className() {
    return 'recent-communities';
  }

  getTemplate() {
    return require('@training/apps/search/RecentCommunitiesView.html');
  }

  regions() {
    return {
      communitiesRegion: '.js-communities-region'
    };
  }

  onRender() {
    const colView = new CollectionView({
      className: 'search-communities-list ax-grid',
      collection: this.model.get('collection'),
      childView: CommunityNavButtonItemView,
      childViewOptions: {
        className: 'search-community-list-item ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-3'
      }
    });
    this.communitiesRegion.show(colView);
  }
}

module.exports = RecentCommunitiesView;
