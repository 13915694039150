const { LayoutView } = require('Marionette');

const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/pressedHoverButton/PressedHoverButtonBehavior');
require('@common/libs/behaviors/mutationObservable/MutationObservable');
const KeyCode = require('@common/data/enums/KeyCode');
const AxSpacingEnum = require('@common/data/enums/AxSpacingEnum');

const DROPDOWN_MENU_SELECTOR = '.js-header-dropdown-menu';
const SPACE_BUFFER_REM = 1;

class HeaderDropdownLayout extends LayoutView {
  initialize({ hideDownIndicator = false } = {}) {
    this.hideDownIndicator = hideDownIndicator;
  }

  getTemplate() {
    return `\
      <div class="header-dropdown-button-wrapper js-header-dropdown-button-wrapper ax-grid__col--auto-size" aria-expanded="false" role="button" tabindex="0">
        <div class="header-dropdown-button-grid ax-grid ax-grid--no-gutter ax-grid--align-items-center">
          <div class="header-dropdown-button js-header-dropdown-button ax-grid__col--auto-size"></div>
          <% if (showDownIndicator) { %><span class="item-icon icon-chevron_down header-down-indicator ax-grid__col--auto-size"></span><% } %>
        </div>
      </div>
      <div class="header-dropdown-menu js-header-dropdown-menu off-screen"></div>
    `;
  }

  templateHelpers() {
    return {
      showDownIndicator: !this.hideDownIndicator
    };
  }

  className() {
    return 'header-dropdown-wrapper ax-grid__col--auto-size ax-grid ax-grid--no-gutter ax-grid--justify-content-end';
  }

  regions() {
    return {
      dropdownButton: '.js-header-dropdown-button',
      dropdownMenu: DROPDOWN_MENU_SELECTOR
    };
  }

  events() {
    return {
      'click @ui.dropdownButtonWrapper': 'onClickButton',
      'mousedown @ui.dropdownMenu': 'onMousedownMenu',
      'click @ui.dropdownMenu': 'onClickMenu',
      focusout: 'onFocusOut',
      'focusin @ui.dropdownMenu': 'onFocusIn',
      'keydown @ui.dropdownButtonWrapper': 'onKeydownButton'
    };
  }

  collectionEvents() {
    return {
      'update reset': 'resetMenuButton'
    };
  }

  onRender() {
    this.resetMenuButton();
  }

  ui() {
    return {
      dropdownButtonWrapper: '.js-header-dropdown-button-wrapper',
      dropdownMenu: DROPDOWN_MENU_SELECTOR
    };
  }

  behaviors() {
    return {
      Resizable: {},
      MutationObservable: {
        target: DROPDOWN_MENU_SELECTOR,
        observeOptions: {
          childList: true,
          subtree: true
        }
      },
      PressedHoverButtonBehavior: {
        buttonTarget: '.js-header-dropdown-button-wrapper'
      }
    };
  }

  onDomMutate() {
    this._centerDropdownMenu();
    this._setMaxHeight();
  }

  onResize() {
    this._centerDropdownMenu();
    this._setMaxHeight();
  }

  onClickButton() {
    this._toggleMenuOpen();
  }

  onKeydownButton(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      this._toggleMenuOpen();
    }
  }

  onMousedownMenu(e) {
    e.preventDefault();
  }

  onClickMenu() {
    this._toggleMenuOpen();
  }

  onFocusOut(e) {
    if (this._menuOpen) {
      // keep menu open if focus is on one of the child elements
      if (this.$el[0].contains(e.relatedTarget)) {
        return;
      }
    }

    this._toggleMenuOpen(false);
  }

  onFocusIn() {
    this._toggleMenuOpen(true);
  }

  resetMenuButton() {
    this._toggleMenuEmpty(!this.collection || this.collection.length === 0);
  }

  _toggleMenuOpen(toggle = !this._menuOpen) {
    this._menuOpen = toggle;
    this.ui.dropdownMenu.toggleClass('off-screen', !toggle);
    this.$el.toggleClass('menu-open', toggle);
    this.ui.dropdownButtonWrapper.attr('aria-expanded', toggle);
  }

  _toggleMenuEmpty(toggle = false) {
    this.$el.toggleClass('menu-empty', toggle);
  }

  _centerDropdownMenu() {
    const $dropdownButton = this.ui.dropdownButtonWrapper;
    const $dropdownMenu = this.ui.dropdownMenu;
    const buttonLeftOffset = $dropdownButton.offset().left;
    const buttonWidth = $dropdownButton.width();
    const menuWidth = $dropdownMenu.outerWidth(true);
    const windowWidth = BrowserHelpers.windowWidth();

    let menuLeftPosition = (buttonWidth - menuWidth) / 2;

    const menuLeftOffset = buttonLeftOffset + menuLeftPosition;
    const menuRightOffset = menuLeftOffset + menuWidth;
    const menuWindowOverflowLeft = Math.max(0, -menuLeftOffset);
    const menuWindowOverflowRight = Math.max(0, menuRightOffset - windowWidth);

    menuLeftPosition = (menuLeftPosition + menuWindowOverflowLeft - menuWindowOverflowRight) * AxSpacingEnum.PX_TO_REM_MULTIPLIER;

    $dropdownMenu.css('left', `${ menuLeftPosition }rem`);
  }

  _setMaxHeight() {
    const $dropdownMenu = this.ui.dropdownMenu;
    const $dropdownButton = this.ui.dropdownButtonWrapper;
    const distanceFromTop = ($dropdownButton.offset().top + $dropdownButton.outerHeight()) * AxSpacingEnum.PX_TO_REM_MULTIPLIER;

    $dropdownMenu.find('ul').css('max-height', `calc(100% - ${ distanceFromTop }rem - ${ SPACE_BUFFER_REM }rem`);
  }
}

module.exports = HeaderDropdownLayout;
