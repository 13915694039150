const TopicCTAButtonHelper = require('@common/components/search/TopicCTAButtonHelper');
const { ItemView } = require('Marionette');
const AssessmentType = require('@common/data/enums/AssessmentType');
const { axButton } = require('@common/components/axButton/AxButtonTemplateHelpers');

const {
  RESUME: META_DATA_TYPE_RESUME
} = require('@common/components/trainingCards/enums/TrainingCardMetaDataTypes');

class DefaultActionButtonView extends ItemView {
  initialize(options = {}) {
    super.initialize(options);

    ({
      startTrainingFn: this.startTrainingFn = $.noop
    } = options);
  }

  getTemplate() {
    const action = this.model.get('action');
    const session = window.app.sessionController.session;
    const isStartable = TopicCTAButtonHelper.isStartable(action, session);
    const noActionTemplate = '<p class="no-action"><%- t("selfDirected.topicDetails.noAction") %></p>';
    const isNotResumableExtraTraining = action.assessmentType === AssessmentType.ExtraTraining && !app.sessionController.isExtraTrainingAvailable() && action.actionType !== META_DATA_TYPE_RESUME;
    let startableAction;

    if (!action.isActionable) {
      return '<span class="ax-hidden"></span>';
    }

    if (!isStartable) {
      startableAction = TopicCTAButtonHelper.findStartableTopicLevelAction(this.model, session);
    }
    if (startableAction != null) {
      this.model.set('action', startableAction);
    }

    if (isNotResumableExtraTraining) {
      return noActionTemplate;
    }

    if (isStartable || startableAction != null) {
      return axButton({
        label: TopicCTAButtonHelper.getButtonText(this.model.get('action')),
        className: 'cta-button',
        fullWidth: true,
        branded: true
      });
    }

    if (app.sessionController.isMinDailyTrainingFulfilled()) {
      return noActionTemplate;
    }

    return '<span class="ax-hidden"></span>';
  }

  templateHelpers() {
    return {
      actionType: this.model.get('action') && this.model.get('action').actionType
    };
  }

  events() {
    return {
      'click .cta-button': 'onClickCtaButton'
    };
  }

  onClickCtaButton() {
    this.startTrainingFn(this.model.get('action'));
  }
}

module.exports = DefaultActionButtonView;
