const logging = require('logging');
const _ = require('underscore');
const env = require('env');
const { Wreqr } = require('Backbone');

const { toggleSensitiveContent } = require('@common/libs/helpers/app/NativeBridgeHelpers');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const PasswordComplexityParser = require('@common/modules/auth/common/PasswordComplexityParser');
const PublicKey = require('@common/data/models/PublicKey');
const PasswordHelpers = require('@common/libs/helpers/app/PasswordHelpers');
const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');
const PasswordRulesetView = require('@common/components/passwordRuleset/PasswordRulesetView');
const AoiCheckboxlistView = require('@common/components/aoiCheckboxlist/AoiCheckboxlistView').default;

require('@common/components/forms/editors/password/Form.Editor.Password');

class ActivateAccountPage extends UIKit.View {

  template = _.tpl(require('@training/apps/auth/templates/ActivateAccountPage.html'));

  regions() {
    return {
      passwordRulesetRegion: '#js-password-ruleset',
      areasOfInterestRegion: '#areasofinterest'
    };
  }

  ui() {
    return {
      pageContent: '.page-content',
      highlightedForm: '.highlighted-form'
    };
  }

  events() {
    return {
      'click .checkbox, .checkbox-label': 'onSelect'
    };
  }

  initialize(options = {}) {
    ({
      title: this.title = I18n.t('activateaccount.title'),
      saveOnly: this.saveOnly = false,
      actionBar: this.actionBar
    } = options);

    this.activate = this.activate.bind(this);

    this.publicKey = new PublicKey();
    this.keyDeferred = this.publicKey.fetch({
      error: (model, jqXHR) => {
        if (jqXHR.status !== 0) {
          window.app.layout.flash.error(I18n.t('selfRegistration.errors.publicKey'));
        }
      }
    });

    this.complexPassword = TenantPropertyProvider.get().getProperty('complex');
    const complexityParser = new PasswordComplexityParser();
    this.complexityRules = complexityParser.parse(TenantPropertyProvider.get().getProperty('complexFormat'));
    this.minPasswordLength = this.complexityRules.getMinLength();
    this.minNumberOfCharacterGroups = this.complexityRules.getMinGroups();
  }

  render() {
    this.$el.html(this.template({
      pageTitle: this.title
    }));

    const passwordRulesetView = new PasswordRulesetView({
      complexPassword: this.complexPassword,
      complexityRules: this.complexityRules
    });

    this.aoiCheckboxlistView = new AoiCheckboxlistView();
    this.passwordRulesetRegion.show(passwordRulesetView);
    this.areasOfInterestRegion.show(this.aoiCheckboxlistView);

    this.accountForm = new UIKit.Form({
      el: this.$('#activateAccountForm'),
      context: {
        oldPasswordOptions: {
          autocomplete: 'on'
        },
        newPasswordOptions: {
          autocomplete: 'new-password'
        }
      }
    });

    window.app.layout.setTitle(I18n.t('settings.accounttitle'));

    toggleSensitiveContent(true);

    return this;
  }

  viewDidAppear() {
    this.$('input#oldpassword').trigger('focus');

    this.setActionBar();

    this.listenTo(Wreqr.radio.channel('nativeBridge').vent, 'appDidEnterBackground', () => {
      this.ui.highlightedForm.css({ opacity: 0 });
    });

    this.listenTo(Wreqr.radio.channel('nativeBridge').vent, 'appDidBecomeActive', () => {
      this.ui.highlightedForm.css({ opacity: 1 });
    });
  }

  setActionBar() {
    $.when(this.keyDeferred).done(() => {
      const buttonOptions = this.saveOnly ? {
        buttons: {
          type: 'profile-save',
          onClick: this.activate
        }
      }
        : {
          buttons: [{
            type: 'customText',
            text: I18n.t('activateaccount.btntitle'),
            onClick: this.activate
          }, {
            type: 'cancel',
            onClick: this.cancel
          }
          ]
        };

      this.actionBar.setActionBar(buttonOptions);
    });
  }

  activate() {
    Promise.resolve(this.keyDeferred).then(() => {
      return this.getNewAttributes();
    })
      .then((newAttributes) => {
        $.ajax({
          type: 'PUT',
          url: '/axonify/selfserve',
          data: JSON.stringify(newAttributes),
          skipGlobalHandler: true,
          success: () => {
            logging.info('Successfully activated account');
            window.app.layout.flash.success(I18n.t('activateaccount.activated'));

            if (!this.saveOnly) {
              // `apps.auth.redirect` will load the next page while preserving the query parameters (i.e. for language override)
              window.apps.auth.redirectToIndexPage();
            }
          },
          error: (xhr) => {
            const exception = AxonifyExceptionFactory.fromResponse(xhr);
            if (exception.getErrorCode() === AxonifyExceptionCode.RECYCLED_PASSWORD) {
              this.$('#newpassword').addClass('rederror');
              this.$('#newpassword input').attr('aria-invalid', 'true');
              return window.app.layout.flash.error(I18n.t('users.password.error.recycled'));
            } else if (exception.getErrorCode() === AxonifyExceptionCode.SECURITY_QUESTION_ANSWER_TOO_SHORT) {
              return window.app.layout.flash.error(I18n.t('users.securityAnswer.error.tooShort', {
                minLength: exception.response.minimumLength,
                actualLength: exception.response.actualLength
              }));
            } else if (exception.getErrorCode() === AxonifyExceptionCode.SECURITY_QUESTION_DUPLICATE_ANSWER) {
              return window.app.layout.flash.error(I18n.t('users.securityAnswer.error.duplicate'));
            }

            logging.error('Failed to activate account');
            return window.app.layout.flash.error(I18n.t('activateaccount.failedtoactivate'));
          }
        });
      });
  }

  getNewAttributes() {
    const errors = [];
    let hasError = false;
    const attributes = {};

    return new Promise((resolve) => {
      // Old password
      const oldpwd = this.$('#oldpassword input').val()
        .trim();
      if (oldpwd.length > 0) {
        this.$('#oldpassword').removeClass('rederror');
        this.$('#oldpassword input').removeAttr('aria-invalid');
      } else {
        this.$('#oldpassword').addClass('rederror');
        this.$('#oldpassword input').attr('aria-invalid', 'true');
        hasError = true;
      }

      // New password
      const pwd1 = this.$('#newpassword input').val()
        .trim();
      const pwd2 = this.$('#passwordagain input').val()
        .trim();

      if ((this.complexPassword && (pwd1.length < this.minPasswordLength)) || (pwd2.length < this.minPasswordLength)) {
        this.$('#newpassword').addClass('rederror');
        this.$('#newpassword input').attr('aria-invalid', 'true');

        this.$('#passwordagain').addClass('rederror');
        this.$('#passwordagain input').attr('aria-invalid', 'true');

        hasError = true;
        errors.push(I18n.t('resetpassword.invalidcomplexpasswordshort', {
          minPasswordLength: this.minPasswordLength
        }));
      }
      if (this.complexPassword && (PasswordHelpers.numberOfCharacterGroups(pwd1) < this.minNumberOfCharacterGroups)) {
        this.$('#newpassword').addClass('rederror');
        this.$('#newpassword input').attr('aria-invalid', 'true');

        this.$('#passwordagain').addClass('rederror');
        this.$('#passwordagain input').attr('aria-invalid', 'true');

        hasError = true;
        errors.push(I18n.t('resetpassword.invalidcomplexpasswordgroups', {
          minNumberOfCharacterGroups: this.minNumberOfCharacterGroups
        }));
      } else if (!this.complexPassword && ((pwd1.length < 8) || (pwd2.length < 8))) {
      // The only condition that doesn't apply when complex password is activated
        this.$('#newpassword').addClass('rederror');
        this.$('#newpassword input').attr('aria-invalid', 'true');

        this.$('#passwordagain').addClass('rederror');
        this.$('#passwordagain input').attr('aria-invalid', 'true');

        hasError = true;
        errors.push(I18n.t('resetpassword.invalidpassword', {
          minPasswordLength: this.minPasswordLength
        }));
      } else if (pwd1 !== pwd2) {
        this.$('#newpassword').addClass('rederror');
        this.$('#newpassword input').attr('aria-invalid', 'true');

        this.$('#passwordagain').addClass('rederror');
        this.$('#passwordagain input').attr('aria-invalid', 'true');

        hasError = true;
        errors.push(I18n.t('resetpassword.passwordmismatch'));
      } else if ((pwd1 === oldpwd) || (pwd2 === oldpwd)) {
        this.$('#newpassword').addClass('rederror');
        this.$('#newpassword input').attr('aria-invalid', 'true');

        this.$('#passwordagain').addClass('rederror');
        this.$('#passwordagain input').attr('aria-invalid', 'true');

        this.$('#oldpassword').addClass('rederror');
        this.$('#oldpassword input').attr('aria-invalid', 'true');

        hasError = true;
        errors.push(I18n.t('resetpassword.sameasoldpassword'));
      } else {
        this.$('#newpassword').removeClass('rederror');
        this.$('#newpassword input').removeAttr('aria-invalid');

        this.$('#passwordagain').removeClass('rederror');
        this.$('#passwordagain input').removeAttr('aria-invalid');
      }

      // Security questions & Answers
      const recoveryQuestions = [];

      const question1 = this.$('select#securityquestion1').val()
        .trim();
      const answer1 = this.$('#securityanswer1').val()
        .trim();

      if (answer1.length > 0) {
        this.$('#securityanswer1')
          .removeClass('rederror')
          .removeAttr('aria-invalid');

        recoveryQuestions.push(CryptographyHelper.encryptWithRSA(answer1, this.publicKey).then((rsaAnswer1) => {
          return {
            recoveryQuestion: question1,
            recoveryAnswer: rsaAnswer1
          };
        }));
      } else {
        this.$('#securityanswer1')
          .addClass('rederror')
          .attr('aria-invalid', 'true');

        hasError = true;
      }

      const question2 = this.$('select#securityquestion2').val()
        .trim();
      const answer2 = this.$('#securityanswer2').val()
        .trim();

      if (answer2.length > 0) {
        this.$('#securityanswer2')
          .removeClass('rederror')
          .removeAttr('aria-invalid');

        recoveryQuestions.push(CryptographyHelper.encryptWithRSA(answer2, this.publicKey).then((rsaAnswer2) => {
          return {
            recoveryQuestion: question2,
            recoveryAnswer: rsaAnswer2
          };
        }));
      } else {
        this.$('#securityanswer2')
          .addClass('rederror')
          .attr('aria-invalid', 'true');

        hasError = true;
      }

      const question3 = this.$('select#securityquestion3').val()
        .trim();
      const answer3 = this.$('#securityanswer3').val()
        .trim();

      if (answer3.length > 0) {
        this.$('#securityanswer3')
          .removeClass('rederror')
          .removeAttr('aria-invalid');

        recoveryQuestions.push(CryptographyHelper.encryptWithRSA(answer3, this.publicKey).then((rsaAnswer3) => {
          return {
            recoveryQuestion: question3,
            recoveryAnswer: rsaAnswer3
          };
        }));
      } else {
        this.$('#securityanswer3')
          .addClass('rederror')
          .attr('aria-invalid', 'true');

        hasError = true;
      }

      // Language
      const language = this.$('select#language').val();
      attributes['language'] = language;

      // Areas of Interest
      attributes['areasOfInterest'] = this.aoiCheckboxlistView.getSelected();


      // App name
      attributes['app'] = env.settings.app;

      logging.debug(`attributes: ${ JSON.stringify(attributes) }`);

      if (hasError) {
        if (errors.length > 0) {
          window.app.layout.flash.error(errors[0]);
        } else {
          window.app.layout.flash.error(I18n.t('flash.infoMissing'));
        }
      }

      if (hasError) {
        throw new Error('There was a validation problem');
      } else {
        Promise.all(recoveryQuestions).then((values) => {
          attributes['recoveryQuestions'] = values;
        })
          .then(() => {
            return CryptographyHelper.encryptWithRSA(oldpwd, this.publicKey);
          })
          .then((rsaString) => {
            attributes['oldPasswd'] = rsaString;
          })
          .then(() => {
            return CryptographyHelper.encryptWithRSA(pwd1, this.publicKey);
          })
          .then((rsaString) => {
            attributes['newPasswd'] = rsaString;
            resolve(attributes);
          });
      }
    });
  }

  onSelect(e) {
    const checkbox = $(e.target).parent()
      .children(':checkbox');
    const checkedState = checkbox.prop('checked');
    checkbox.attr('aria-checked', checkedState);
  }

  cancel() {
    window.apps.auth.logout();
  }

  onClose() {
    toggleSensitiveContent(false);

    this.publicKey.abortXHR(Sync.Method.READ);

    this.actionBar.setActionBar();

    super.onClose();
  }
}

module.exports = ActivateAccountPage;
