const Download = require('@common/data/models/Download');

class CommunityInsightsReportsDownload extends Download {
  defaults() {
    return {
      type: 'PageEngagementsExport',
      context: 'admin'
    };
  }

  apiEndpoint() {
    return 'report/export/PageEngagementsExport';
  }
}

module.exports = CommunityInsightsReportsDownload;
