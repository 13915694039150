const Marionette = require('Marionette');
const _ = require('underscore');
const Form = require('@common/components/forms/Form');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class QuestionCreateContentEditView extends Marionette.ItemView {
  tagName() {
    return 'form';
  }
  
  id() {
    return 'form-content';
  }

  getTemplate() {
    return `\
      <div id="content-container">
        <textarea placeholder="<%- t('discover.pageTypes.question.create.placeholder') %>" class="title-input qa-question-create-placeholder" data-field="title"></textarea>
        <div class="character-count">
          <div class="content">
            <%- t('discover.pageTypes.generic.characterCount') %>
            <span class="count"><%- maxTitleLength %></span>
          </div>
        </div>
      </div>\
    `;
  }

  events() {
    return {
      'keydown textarea': 'checkCharacterCount',
      'keyup textarea': 'checkCharacterCount',
      'change textarea': 'checkCharacterCount'
    };
  }

  ui() {
    return {
      characterCountStatus: '.character-count .count',
      inputTitle: '.title-input'
    };
  }


  checkCharacterCount(e) {
    const charactersRemaining = this.maxTitleLength - (e.target.value.length);
    const isCharacterRemaining = charactersRemaining >= 0;

    // Disable the warning if it is enabled when there is room for more text.
    if (isCharacterRemaining && this.isUIWarningActive) {
      this.ui.characterCountStatus.removeClass('warning');
      this.isUIWarningActive = false;
    }

    // Enables the warning if it is disabled when there is too much text.
    if (!isCharacterRemaining && !this.isUIWarningActive) {
      this.ui.characterCountStatus.addClass('warning');
      this.isUIWarningActive = true;
    }

    this.ui.characterCountStatus.text(charactersRemaining);
  }

  initialize(options = {}) {
    ({
      maxTitleLength: this.maxTitleLength
    } = options);

    // Flag to avoid repeatly setting and checking class on every event needlessly.
    this.isUIWarningActive = false;

    // Paste event happens before text is inserted so we need to defer the check.
    this.$el.on('paste', (e) => {
      _.defer(() => {
        if (this.isDestroyed) {
          return;
        }
        this.checkCharacterCount(e);
      });
    });
  }

  onDestroy() {
    this.$el.off('paste');
  }

  templateHelpers() {
    return {
      maxTitleLength: this.maxTitleLength
    };
  }

  decodeAndSetTitleInput() {
    this.ui.inputTitle.val(HTMLHelpers.htmlDecode(this.ui.inputTitle.val()));
  }

  onRender() {
    this.titleForm = new Form({
      el: this.$el,
      model: this.model
    });
  }

  commit() {
    this.ui.inputTitle.val(HTMLHelpers.htmlEncode(this.ui.inputTitle.val()));
    const errors = this.titleForm.commit();
    this.decodeAndSetTitleInput();
    return errors;
  }
}

module.exports = QuestionCreateContentEditView;
