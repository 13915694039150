import {
  type AxFabProps,
  AxIcon,
  useAxFabStyles
} from '@common/modules/react/themes/components/';

import {
  SpeedDialAction as MuiSpeedDialAction,
  type SpeedDialActionProps as MuiSpeedDialActionProps,
  styled
} from '@mui/material';

import {
  type Ref,
  forwardRef
} from 'react';

export type AxSpeedDialActionProps = MuiSpeedDialActionProps & {
  size?: AxFabProps['size'],
  /**
   * If a string is provided, it will be converted to an AxIcon component. The icon will be sized according to the size prop.
   * If a ReactNode is provided, it will be used as is. Consumer is responsible for sizing the icon.
   */
  icon?: AxFabProps['iconName'] | React.ReactNode
};

const styledOptions = {
  name: 'AxSpeedDialAction'
};

const StyledAxSpeedDialAction = styled(MuiSpeedDialAction, styledOptions)<MuiSpeedDialActionProps>(() => {
  return {};
});

/**
 * Additional action that can be added to a AxSpeedDial component.
 *
 * If the icon is a string, it will be converted to an AxIcon component.
 *
 * If the parent AxSpeedDial component has a size prop, it will be passed to this component.
 *
 */
export const AxSpeedDialAction = forwardRef(({
  size = 'medium',
  ...otherProps
}: AxSpeedDialActionProps, ref: Ref<HTMLDivElement>) => {
  const speedDialActionProps: AxSpeedDialActionProps = {
    ...otherProps
  };

  const {
    buttonStyles,
    iconStyles
  } = useAxFabStyles(size);

  // If the icon is a string, convert it to an AxIcon component
  // If consumer uses anything but AxIcon, they are responsible for the sizing styling
  if (speedDialActionProps.icon) {
    if (typeof speedDialActionProps.icon === 'string') {
      speedDialActionProps.icon = <AxIcon iconName={ speedDialActionProps.icon as AxFabProps['iconName'] } />;
    }
  }

  return (
    <StyledAxSpeedDialAction
      FabProps={{
        sx: buttonStyles,
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true
      }}
      sx={{
        '.MuiIcon-root': {
          fontSize: iconStyles.fontSize
        }
      }}
      { ...speedDialActionProps }
      ref={ ref }
    />
  );
});

export default AxSpeedDialAction;

