import {
  AxDialogConfirmationLayout,
  AxDialogDefaultLayout,
  type AxDialogConfirmationLayoutProps,
  type AxDialogDefaultLayoutProps
} from '@common/modules/react/themes/components';

export type AxDialogVariantLayoutProps = FlatDiscriminatingTypeUnion<'variant', {
  default: AxDialogDefaultLayoutProps
  confirmation: AxDialogConfirmationLayoutProps
}>;

export default function AxDialogVariantLayout(props: AxDialogVariantLayoutProps): JSX.Element {
  const VariantLayoutMap = {
    default: AxDialogDefaultLayout,
    confirmation: AxDialogConfirmationLayout
  };

  const {
    variant,
    qaClassName,
    ...layoutProps
  } = props;

  const Layout = VariantLayoutMap[variant];

  return <Layout { ...layoutProps } qaClassName={ `${ qaClassName }-${ variant }` }/>;
}
