const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const WindowFactory = require('@common/components/window/WindowFactory');

class ExternalWindowService {
  windowFactory = new WindowFactory();

  constructor(options = {}) {
    ({
      windowOptions: this.windowOptions,
      popupBlockedHandler: this.popupBlockedHandler
    } = options);
  }

  openWindow() {
    if (this.window == null || this.window.closed) {
      this.window = this.windowFactory.create( this.windowOptions );
    } else {
      this.window.focus();
    }

    if (this.window == null && _.isFunction(this.popupBlockedHandler)) {
      this.popupBlockedHandler(I18n.t('flash.externalWindowPopUpBlocked'));
    }
  }

  closeWindow() {
    if (this.window != null) {
      this.window.close();
    }
  }
}

module.exports = ExternalWindowService;
