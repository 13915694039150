// Module
var code = `<span class="blankanswer">
  <div class="fillintheblankcustomdrop">
    <div class="largedropdowncontainer" tabindex="0">
      <span class="selection" style="width:<%- maxWidth %>px;">&nbsp;</span>
        <div class="selectorlist" data-ordinal="<%- ordinal %>">
          <ul>
            <% _.each(options, function(option) { %>
              <li data-id="<%- option.id %>"><%= option.optionText %></li>
            <% }); %>
          </ul>
        </div>
      </div>
  </div>
</span>
`;
// Exports
module.exports = code;