const _ = require('underscore');
const { Model } = require('Backbone');
const { LayoutView } = require('Marionette');

const StatefulRegion = require('@common/libs/UI/StatefulRegion');

require('jquery.velocity');
require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/mutationObservable/MutationObservable');

require('@common/components/loading/wrapper/LoadingWrapperView.less');

const CONTENT_REGION = 'contentRegion';
const ANIMATION_DURATION = 300;
const ANIMATION_EASING = 'easeInOut';
const BLANKET_OPACITY = 0.7;

class LoadingWrapperView extends LayoutView {

  static CONTENT_REGION = CONTENT_REGION;

  className() {
    return 'loading-wrapper-container';
  }

  regions() {
    return {
      [CONTENT_REGION]: {
        selector: '> .loading-content-region',
        regionClass: StatefulRegion
      }
    };
  }

  behaviors() {
    return Object.assign({}, super.behaviors(), {
      Resizable: {},
      MutationObservable: {
        target: '> .loading-content-region',
        observeOptions: {
          attributes: true,
          childList: true,
          subtree: true
        }
      }
    });
  }

  ui() {
    return {
      content: '> .loading-content-region',
      positioner: '> .wrapper-positioner',
      wrapper: '> .wrapper-positioner .spinner-wrapper',
      blanket: '> .wrapper-positioner .spinner-blanket',
      spinner: '> .wrapper-positioner .loading-spinner'
    };
  }

  constructor(options = {}) {
    super(options);

    this.childViewEventPrefix = 'content';

    this._adjustDimensions = this._adjustDimensions.bind(this);

    (({
      animationDuration: this._animationDuration = ANIMATION_DURATION,
      blanketOpacity: this._blanketOpacity = BLANKET_OPACITY
    } = options));

    this._spinner = this._getDefaultSpinnerOptions(options.spinner);

    this._dimensionData = new Model();

    this._showState = this._setupShowState();
  }

  getTemplate() {
    return `
      <div class="no-spacing loading-content-region"></div>
      <div class="no-spacing wrapper-positioner">
        <div class="spinner-wrapper">
          <div class="spinner-blanket"></div>
          <div class="loading-spinner" style="display: block;"><div class="img"></div></div>
        </div>
      </div>
    `;
  }

  onRender() {
    this.ui.wrapper.css('opacity', 0);
    this.ui.blanket.css('opacity', this._blanketOpacity);

    this._renderSpinner();
  }

  onContentRender(loadingContent) {
    this.adjustDimensionsIfCurrentView(loadingContent);
  }

  onContentShow(loadingContent) {
    this.adjustDimensionsIfCurrentView(loadingContent);
  }

  onContentAttach(loadingContent) {
    this.adjustDimensionsIfCurrentView(loadingContent);
  }

  adjustDimensionsIfCurrentView(loadingContent) {
    if (loadingContent === this.contentRegion.currentView) {
      this._adjustDimensions();
    }
  }

  _renderSpinner() {
    if (this._spinner.enabled) {
      this.ui.spinner.addClass(this._spinner.type);
      this.ui.spinner.css(this._spinner.css);
    }
  }

  onBeforeAttach() {
    this.listenTo(this._dimensionData, 'change', this.onDimensionChange);
  }

  onResize() {
    this._adjustDimensions();
  }

  onDomMutate() {
    this._adjustDimensions();
  }

  onDimensionChange() {
    this.ui.positioner.css(this._dimensionData.pick('width'));
    this.ui.wrapper.css(this._dimensionData.toJSON());
  }

  onBeforeDestroy() {
    this.ui.wrapper.stop(true);
    this.ui.wrapper.velocity('stop');
  }

  onShowStateChange(stateModel, show) {
    const fade = stateModel.get('fade');

    if (show) {
      this._animateShow(fade);
    } else {
      this._animateHide(fade);
    }
  }

  hide(options = {}) {
    this._toggleShowState(false, options.fade);
  }

  show(options = {}) {
    this._toggleShowState(true, options.fade);
  }

  setContentEl($contentEl) {
    this._$contentEl = $contentEl;
    this._adjustDimensions();
  }

  getContentEl() {
    return this._$contentEl || this.contentRegion.$el;
  }

  _toggleShowState(show, fade) {
    this._showState.set({
      show,
      fade
    });

    if (!this._showState.hasChanged('show')) {
      this._adjustDimensions();
    }
  }

  _animateShow(fade) {
    this.ui.wrapper.stop(true);
    this.ui.wrapper.velocity('stop');

    const styles
      = {opacity: 1};

    if (fade === false) {
      this.ui.wrapper.css(styles);
      this.$el.addClass('stack-layers');
    } else {
      this.$el.addClass('stack-layers');
      this.ui.wrapper.velocity(styles, {
        easing: ANIMATION_EASING,
        duration: this._animationDuration
      });
    }

    return this._adjustDimensions();
  }

  _animateHide(fade) {
    this._adjustDimensions();

    this.ui.wrapper.stop(true);
    this.ui.wrapper.velocity('stop');

    const styles
      = {opacity: 0};

    if (fade === false) {
      this.$el.removeClass('stack-layers');
      this.ui.wrapper.css(styles);
    } else {
      this.ui.wrapper.velocity(styles, {
        duration: this._animationDuration,
        easing: ANIMATION_EASING,
        complete: () => {
          this.$el.removeClass('stack-layers');
        }
      });
    }
  }

  _setupShowState() {
    const state = new Model();
    this.listenTo(state, 'change:show', this.onShowStateChange);
    return state;
  }

  _getDefaultSpinnerOptions(spinnerOptions = {}) {
    const defaultOptions = {
      enabled: true,
      type: 'large',
      css: {}
    };

    if (_.isBoolean(spinnerOptions)) {
      _.extend(defaultOptions, {enabled: spinnerOptions});
    } else if (_.isObject(spinnerOptions)) {
      _.extend(defaultOptions, spinnerOptions);
    }

    return defaultOptions;
  }

  _adjustDimensions() {
    const isContentRegionViewVisible = _.result(this, 'contentRegion.currentView.isVisible', false);
    const isContentElVisible = this.getContentEl().is(':visible');

    if (!isContentRegionViewVisible || !isContentElVisible) {
      return;
    }

    const {
      left, width, height, bottom
    } = this.getBoundingClientRect(this.getContentEl());
    const {
      left: left2, bottom: bottom2
    } = this.getBoundingClientRect(this.contentRegion.$el);

    this._dimensionData.set({
      width,
      height,
      top: -height - Math.abs(bottom - bottom2),
      left: Math.abs(left - left2)
    });
  }
}

module.exports = LoadingWrapperView;
