const I18n = require('@common/libs/I18n');
const LearningObjectiveCardTagView = require('@common/components/learningObjectives/LearningObjectiveCardTagView');
const AssessmentType = require('@common/data/enums/AssessmentType');

const TAG_STRING_KEYS_BY_TYPE = {
  [AssessmentType.CertificationTraining]: 'GuidedLearning.icons.certification',
  [AssessmentType.IntroductoryTraining]: 'GuidedLearning.icons.topic',
  [AssessmentType.RefresherTraining]: 'GuidedLearning.icons.topic',
  [AssessmentType.FormalExamTraining]: 'GuidedLearning.icons.exam'
};

const CSS_CLASS_BY_TYPE = {
  [AssessmentType.CertificationTraining]: 'ax-tag--certification',
  [AssessmentType.IntroductoryTraining]: 'ax-tag--topic',
  [AssessmentType.RefresherTraining]: 'ax-tag--topic',
  [AssessmentType.FormalExamTraining]: 'ax-tag--exam'
};

class AssessmentCardTagView extends LearningObjectiveCardTagView {
  className() {
    const type = this.getOption('assessmentType');
    const typeClass = CSS_CLASS_BY_TYPE[type];

    return `ax-tag ${ typeClass }`;
  }

  templateHelpers() {
    const type = this.getOption('assessmentType');

    return {
      tagText: I18n.t(TAG_STRING_KEYS_BY_TYPE[type])
    };
  }
}

module.exports = AssessmentCardTagView;
