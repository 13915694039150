const { uniqueId } = require('underscore');
const {
  bindEntityEvents,
  unbindEntityEvents
} = require('Marionette');

const {
  proxyEvent,
  unproxyEvent
} = require('@common/libs/helpers/features/EventHelpers');

const BaseController = require('@common/libs/UI/controllers/BaseController');
const ViewControllerRegistry = require('@common/libs/UI/controllers/ViewControllerRegistry');

/*

  ViewController

  Controller class that wraps a View and is responsible for higher level application functionality like:
  - view business logic
  - data injection
  - binding view interaction with the rest of the application

*/

class ViewController extends BaseController {

  constructor(options = {}) {
    super(options);

    this.show = this.show.bind(this);
    this.findControllerById = this.findControllerById.bind(this);
    this.findControllerByView = this.findControllerByView.bind(this);

    this.vcid = uniqueId('vc');
    this.id = options.id || this.vcid;
    this._viewFactory = options.viewFactory;
    this._view = null;

    bindEntityEvents(this, this, this.getOption('delegateEvents'));

    // Register the controller instance with the ViewControllerRegistry once it's been fully
    // constructed and initialized.
    ViewControllerRegistry.register(this);
  }

  inflateView() {
    this.triggerMethod('before:view:inflate', this);

    // Initialize the View that this controller will be responsible for.
    const view = this._createView();

    this._setView(view);

    this._attachViewLifecycleEvents(view);

    this.triggerMethod('view:inflate', this, view);
  }

  deflateView() {
    if (this.isViewInflated()) {
      const view = this.getView();

      this.triggerMethod('before:deflate:view', this, view);

      // Remove the event listeners that were attached to the view during inflation
      this._detachViewLifecycleEvents(view);
      this._destroyView(view);
      this._view = null;

      this.triggerMethod('deflate:view', this, view);
    }
  }

  isViewInflated() {
    return this.getView() != null;
  }

  isViewRendered() {
    const view = this.getView();

    if (view != null) {
      return view.isRendered;
    }

    return false;
  }

  // Shows this controllers inflated view in a region
  // 'region' - Region instance where the view will get shown in
  // 'showOptions' - Extra options to be passed along to the region.show() call
  show(region, showOptions = {}) {
    if (this.isDestroyed) {
      throw new Error('You tried to show a view after this controller has already been destroyed!');
    }

    if ((region == null)) {
      throw new Error('Missing region arg!');
    }

    if (!this.isViewInflated()) {
      this.inflateView();
    }

    return region.show(this.getView(), showOptions);
  }

  getId() {
    return this.id;
  }

  getView() {
    return this._view;
  }

  findControllerById(id) {
    if (this.getId() === id) {
      return this;
    }
    return null;

  }

  findControllerByView(view) {
    if (this.getView() === view) {
      return this;
    }
    return null;

  }

  // Ensure the view is destroyed in case destroy was explicitly called and not invoked by the view's 'destroy' event.
  destroy(...args) {
    // Make sure the view is destroyed with the controller if it isn't already destroyed
    this.deflateView();

    super.destroy(...args);

    // Unregister the controller instance with the ViewControllerRegistry
    ViewControllerRegistry.unregister(this);

    unbindEntityEvents(this, this, this.getOption('delegateEvents'));

    return this;
  }

  _setView(view) {
    // Enforce the requirement that view exists.
    if (view == null) {
      throw new Error('Missing required param: \'view\'!');
    }

    this._view = view;
  }

  _createView() {
    const viewDefinition = this.viewDefinition || {};
    const viewDefinitionOverride = this.getOption('viewDefinition') || {};

    return this._viewFactory.create(viewDefinition, viewDefinitionOverride);
  }

  _attachViewLifecycleEvents(view) {
    // Proxy the 'all' event from the View and re-trigger the events on this controller with triggerMethod.
    // Also supports 'viewEvents' property for defining custom handlers like Marionette's 'modelEvents' and
    // 'collectionEvents'.
    proxyEvent(this, view, 'all', 'view');

    // Bind the controllers destroy lifecyle to the view's
    this.listenTo(view, 'destroy', this.destroy);
  }

  _detachViewLifecycleEvents(view) {
    this.stopListening(view, 'destroy', this.destroy);

    unproxyEvent(this, view, 'all', 'view');
  }

  _destroyView(view) {
    if (view != null && !view.isDestroyed) {
      view.destroy();
    }
  }
}

module.exports = ViewController;
