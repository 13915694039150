const PageHistoryController = require('@training/apps/articles/PageHistoryController');

const viewConfig = (model) => {
  return {
    ViewControllerClass: PageHistoryController,
    model: model
  };
};

module.exports = viewConfig;
