import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  AccordionActions as MuiAccordionActions,
  type AccordionActionsProps as MuiAccordionActionsProps
} from '@mui/material';

type AxAccordionActionsProps = MuiAccordionActionsProps;

const styledOptions = {
  name: 'AxAccordionActions'
};

const StyledAxAccordionActions = styled(MuiAccordionActions, styledOptions)<AxAccordionActionsProps>(() => {
  return {};
});

export const AxAccordionActions = forwardRef(({
  ...otherProps
}: AxAccordionActionsProps, ref: Ref<HTMLDivElement>) => {
  const AccordionActionsProps: AxAccordionActionsProps = {
    ...otherProps
  };

  return (
    <StyledAxAccordionActions { ...AccordionActionsProps } ref={ ref }/>
  );
});

export default AxAccordionActions;
