const Marionette = require('Marionette');

class KnowledgeItemAnswerItemEmpty extends Marionette.ItemView {
  tagName() {
    return 'tr';
  }

  getTemplate() {
    return `\
<td colspan="5"><%- t('knowledge.empty') %></td>
`;
  }
}

class KnowledgeItemAnswerItem extends Marionette.ItemView {
  tagName() {
    return 'tr';
  }

  getTemplate() {
    return `\
<td>
  <% if (answeredCorrect) { %>
    <%- t('knowledge.correct') %>
  <% } else { %>
    <%- t('knowledge.incorrect') %>
  <% } %>
</td>
<td nowrap>
  <%- answerDate %>
</td>
<td nowrap>
  <%- trendTotal %> <% if (answeredCorrect) { %>(+1)<% } else { %>(-1)<% } %>
</td>
<td>
  <%= questionVariantText %>
</td>
<td nowrap>
  <button class="axon-button white knowledge-view-question" data-id="<%- answerId %>">View question</button>
</td>
`;
  }
}

class KnowledgeItemAnswerListView extends Marionette.CompositeView {
  initialize(options = {}) {
    this.showQuestionAnswerById = options.showQuestionAnswerById;
    super.initialize(...arguments);
  }

  events() {
    return {
      'click .knowledge-view-question': 'onViewQuestion'
    };
  }

  getChildView() {
    return KnowledgeItemAnswerItem;
  }

  className() {
    return 'knowledge-item-answer-a11y';
  }

  tagName() {
    return 'table';
  }

  childViewContainer() {
    return 'tbody';
  }

  getTemplate() {
    return `\
<thead>
  <tr>
    <th><%- t('knowledge.correctness') %></th>
    <th><%- t('knowledge.date') %></th>
    <th><%- t('knowledge.trend') %></th>
    <th><%- t('knowledge.questionText') %></th>
    <th><%- t('knowledge.viewQuestion') %></th>
  </tr>
</thead>
<tbody></tbody>
`;
  }

  getEmptyView() {
    return KnowledgeItemAnswerItemEmpty;
  }

  onViewQuestion(e) {
    if (!this.showQuestionAnswerById) {
      return;
    }

    const questionId = $(e.target).data('id');
    this.showQuestionAnswerById(questionId);
  }
}

module.exports = KnowledgeItemAnswerListView;
