// Module
var code = `<div class="tile-top">
	<div class="topic">
    <span class="hidden icon" role="img"></span>
    <h2 class="topic-hierarchy-details" title="<%= t('assessments.item.catSub', {category: category, subject: subject}) %>">
      <%= t('assessments.item.catSub', {category: category, subject: subject}) %>
		</h2>
		<h3 class="topic-name qa-topic-name" title="<%- topic.name %>">
			<%- topic.name %> - <%- t('knowledge.level', {level: level}) %>
		</h3>
	</div>

	<div class="topic-statuses">

		<div class="grade">
			<p class="grade-value"></p>
			<p class="grade-text"></p>
		</div>

		<div class="lock-icon"></div>

	</div>

</div>

<div class="tile-bottom-wrapper">
	<div class="tile-bottom clearfix">

		<div class="tile-meta clearfix">

			<p class="first content"></p>
			<p class="last qa-due due"></p>
			<p class="last qa-rewards rewards" ></p>

		</div>

		<div class="start-button-wrapper">
			<button class="axon-button small start-button"
		    aria-label="<%- t('assessments.item.action.pass') %> <%- topic.name %> <%- t('knowledge.level', {level: level}) %>">
				<span class="icon-play" role="presentation" aria-hidden="true"></span>
				<span><%- t('assessments.item.action.pass') %></span>
			</button>
		</div>

	</div>
</div>

<div class="tile-print-certifications print-certifications-button-wrapper"></div>`;
// Exports
module.exports = code;