const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TagGroupFiltersEditContentView = require('@training/apps/search/communitiesManagement/tagGroupFilters/content/TagGroupFiltersEditContentView');
const I18n = require('@common/libs/I18n');
const TagsView = require('@common/components/discover/edit/TagsView');
const TagList = require('@common/components/discover/collections/TagList');
const CreatePostAccessibleModalView = require('@training/apps/timeline/CreatePostAccessibleModalView');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const UIKit = require('@training/widgets/UIKit');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

class TagGroupFiltersContentController extends LayoutController {
  initialize(options = {}) {
    this.model = options.model;
    this._onDoneFn = options.onDoneFn;
    this.viewDefinition = this.viewDefinition.bind(this);
    this.delegateEvents = this.delegateEvents.bind(this);
    this._clearActionBar = this._clearActionBar.bind(this);
    this._onFilterSave = this._onFilterSave.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onFilterDelete = this._onFilterDelete.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: TagGroupFiltersEditContentView,
      model: this.model
    };
  }

  regionControllers() {
    const tagList = new TagList([]);
    return {
      tagsRegion: {
        viewDefinition: {
          ViewClass: TagsView,
          model: this.model,
          tagList: tagList,
          hideLabel: true
        }
      }
    };
  }

  delegateEvents() {
    return {
      'view:before:destroy': this._clearActionBar,
      'view:save': this._onFilterSave,
      'view:cancel': this._onFilterCancel,
      'view:delete': this._onFilterDelete
    };
  }

  _clearActionBar() {
    window.app.layout.actionBar.setActionBar();
  }

  _onFilterSave() {
    const name = this.model.getName();
    if (!name || name === '') {
      window.app.layout.flash.error(I18n.t('communitiesManagement.filters.errors.needName'));
      return;
    }

    this.findControllerByRegion('tagsRegion').getView()
      .commit();
    this.model.save(null, {
      success: () => {
        window.app.layout.flash.success(I18n.t('communitiesManagement.filters.saveSuccess'));
        this._onDoneFn();
      },
      error: (model, response) => {
        response.skipGlobalHandler = true;

        const exception = AxonifyExceptionFactory.fromResponse(response);
        const errorCode = exception.getErrorCode();

        let errorMessage = I18n.t('communitiesManagement.filters.errors.generalSave');
        if (errorCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_FOUND) {
          errorMessage = I18n.t('communitiesManagement.filters.errors.3082');
        }
        window.app.layout.flash.error(errorMessage);
      }
    });
  }

  _onFilterCancel() {
    this._onDoneFn();
  }

  _onFilterDelete() {
    const modalView = new CreatePostAccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s'
    });

    const modalChildView = new ConfirmDialogView({
      confirmCallback: this._onDelete,
      title: I18n.t('communitiesManagement.filters.deleteModal.title'),
      confirmationText: I18n.t('communitiesManagement.filters.deleteModal.confirmationText', {
        filterName: this.model.getName()
      }),
      buttonText: I18n.t('general.delete'),
      model: this.model
    });

    window.app.layout.presentModal(modalView, { closeClick: false });

    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _onDelete() {
    this.model.destroy({
      success: () => {
        window.app.layout.flash.success(I18n.t('communitiesManagement.filters.deleteSuccess'));
      },
      error: (model, response) => {
        response.skipGlobalHandler = true;

        const exception = AxonifyExceptionFactory.fromResponse(response);
        const errorCode = exception.getErrorCode();

        let errorMessage = I18n.t('communitiesManagement.filters.errors.generalDelete');
        if (errorCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_FOUND) {
          errorMessage = I18n.t('communitiesManagement.filters.errors.3082');
        }
        window.app.layout.flash.error(errorMessage);
      }

    });

    window.app.layout.dismissModal();
  }
}

module.exports = TagGroupFiltersContentController;
