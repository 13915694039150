const Backbone = require('Backbone');
const logging = require('logging');
const _ = require('underscore');
const $ = require('jquery');
const Marionette = require('Marionette');
const ObjectHelpers = require('@common/libs/helpers/types/ObjectHelpers');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const I18n = require('@common/libs/I18n');
const StringValidators = require('@common/components/forms/validators/StringValidators');

class Form extends Marionette.ItemView {

  constructor(options) {
    super(options);

    this.update = this.update.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setModelValue = this.setModelValue.bind(this);
    this.commit = this.commit.bind(this);
    this.validate = this.validate.bind(this);
    this.reset = this.reset.bind(this);
    this.clearAllErrors = this.clearAllErrors.bind(this);
  }

  initialize(options = {}) {
    if (options.html) {
      this.$el.html(options.html);
    }
    this.context = options.context;

    // Parse the form for data fields
    this.fields = {};

    this.$('[data-field]').each((index, element) => {
      const tagName = $(element).prop('tagName')
        .toLowerCase();
      const fieldName = $(element).attr('data-field');
      const editorName = $(element).attr('data-editor');
      const optionsName = $(element).attr('data-options');

      let _Editor;

      if (editorName) {
        _Editor = Form.Editor[editorName];
      } else {
        switch (tagName) {
          case 'input':
            switch ($(element).attr('type')) {
              case 'number':
                _Editor = Form.Editor.Number;
                break;
              case 'password':
                _Editor = Form.Editor.Password;
                break;
              case 'checkbox':
                _Editor = Form.Editor.Checkbox;
                break;
              default:
                _Editor = Form.Editor.Text;
            }
            break;
          case 'textarea':
            _Editor = Form.Editor.TextArea;
            break;
          case 'select':
            _Editor = Form.Editor.Select;
            break;
          default:
            _Editor = Form.Editor.Text;
        }
      }

      const editor = new _Editor({
        el: element,
        options: (this.context != null ? this.context[optionsName] : undefined)
      });

      this.fields[fieldName] = {editor};

      // Relay the editor's events
      this.listenTo(editor, 'change', () => {
        this.trigger(`change:${ fieldName }`, this, editor);
        this.trigger('change', this, editor);
        this.onChange(fieldName, editor);
      });
      this.listenTo(editor, 'add', (addIndex) => {
        this.trigger(`add:${ fieldName }`, this, addIndex, editor);
      });
      this.listenTo(editor, 'remove', (removeIndex) => {
        this.trigger(`remove:${ fieldName }`, this, removeIndex, editor);
      });
      this.listenTo(editor, 'focus', () => {
        this.trigger(`focus:${ fieldName }`, this, editor);
      });
      this.listenTo(editor, 'blur', () => {
        this.trigger(`blur:${ fieldName }`, this, editor);
      });
    });

    // Update model
    if (this.model) {
      this.update(this.model);
    }
  }

  update(model) {
    this.model = model;
    this.setValue(this.model.toJSON());
    this.setAttributes(_.isFunction(this.model.getFormAttributes) ? this.model.getFormAttributes() : undefined);
  }

  setValue(attrs) {
    Object.keys(this.fields).forEach((field) => {
      const value = ObjectHelpers.getDescendantProp(attrs, field);
      this.fields[field].editor.setValue(value);
    });
  }

  setAttributes(attrs = {}) {
    Object.keys(this.fields).forEach((field) => {
      const attributes = ObjectHelpers.getDescendantProp(attrs, field);

      if (attributes != null) {
        this.fields[field].editor.setAttributes(attributes);
      }
    });
  }

  setModelValue(attrs) {
    const { model } = this;
    if (model != null) {
      Object.keys(attrs).forEach((fieldName) => {
        const value = attrs[fieldName];
        model.set(fieldName, value);
      });

      this.update(model);
    }
  }

  commit() {
    this.clearAllErrors();

    const attrs = $.extend(true, {}, this.model.attributes);
    const updatedAttrs = this.getUpdatedAttributes();

    let errors = [];
    Object.keys(this.fields).forEach((fieldName) => {
      const field = this.fields[fieldName];
      let value = field.editor.getValue();
      let fieldErrors = this.validate(fieldName, value, updatedAttrs);

      const dataError = field.editor.getError();
      if (dataError) {
        if (fieldErrors == null) {
          fieldErrors = [];
        }
        fieldErrors.push(dataError);
      }
      if (fieldErrors) {
        this.fields[fieldName].editor.setError(fieldErrors);
        errors = errors.concat(fieldErrors);
      } else {
        //check for fallback value
        if (field.editor.fallbackValue != null && field.editor.defaultValue === value) {
          value = field.editor.fallbackValue;
        }

        // get attribute updated
        ObjectHelpers.addValueToObj(fieldName, value, attrs);
      }
    });

    if (_.isEmpty(errors)) {
      this.model.set(attrs);
      return null;
    }
    return errors;

  }

  validate(fieldName, value, attrs) {
    const errors = [];
    const object = _.result(this.model, 'validators') || [];

    Object.keys(object).forEach((attribute) => {
      const validator = object[attribute];
      if (attribute !== fieldName) {
        return undefined;
      }

      if (Array.isArray(validator)) {
        _(validator).each((v) => {
          const error = this.test(fieldName, v, value, attrs);
          if (error) {
            errors.push(error);
          }
        });
      } else {
        const error = this.test(fieldName, validator, value, attrs);
        if (error) {
          errors.push(error);
        }
      }
      return undefined;
    });

    if (_.isEmpty(errors)) {
      return null;
    }
    return errors;

  }

  test(fieldName, validator, value, attrs) {
    let error = null;
    let valid = true;

    if (_.isString(validator)) {
      switch (validator) {
        case 'required':
          if (value == null) {
            valid = false;
          } else if (_.isString(value)) {
            valid = (value !== '');
          } else if (Array.isArray(value)) {
            valid = !_.isEmpty(value);
          } else if (_.isObject(value)) {
            if (_.isEmpty(value)) {
              valid = false;
            } else if (value.id != null) {
              valid = (value.id !== -1);
            } else {
              valid = true;
            }
          } else {
            valid = true;
          }
          if (!valid) {
            error = StringValidators.getRequiredValueErrorMessage(this.model.className, fieldName);
          }
          break;
        case 'email':
          if (value != null && value !== '') {
            valid = StringHelpers.isValidEmailAddress(value);
          }
          if (!valid) {
            error = I18n.t('UIKit.Form.errors.email.invalid');
          }
          break;
        case 'password':
          valid = (value != null ? value.length : undefined) >= 5;
          if (!valid) {
            error = I18n.t('UIKit.Form.errors.password.invalid');
          }
          break;
        case 'dateValidation':
          if (!this.fields[fieldName].editor.validateDate()) {
            error = I18n.t('UIKit.Form.errors.date.invalid');
          }
          break;
        case 'maxLength': {
          const textLength = this.model.get('textLength');
          const textMaxLength = textLength && textLength.maxLength;
          const valueLength = value && value.length;

          valid = valueLength <= textMaxLength;
          if (!valid) {
            error = I18n.t('UIKit.Form.errors.maxCharacters', {
              maxLength: this.model.get('textLength').maxLength
            });
          }
          break;
        }
        case 'url':
          if (value != null && value !== '') {
            valid = UrlHelpers.isUrl(value);
          }
          if (!valid) {
            const modelErrorKey = `errors.${ this.model.className }.url.${ fieldName }`;
            error = I18n.hasString(modelErrorKey) ? I18n.t(modelErrorKey) : I18n.t('UIKit.Form.errors.field.url');
          }
          break;
        case 'uri':
          if (value != null && value !== '') {
            valid = UrlHelpers.isUri(value);
          }
          if (!valid) {
            error = I18n.t('UIKit.Form.errors.field.uri');
          }
          break;
        default:
          logging.warn(`Form.js: validator ${ validator } is not handled.`);
      }
    } else if (_.isFunction(validator)) {
      error = validator(value, attrs, this.model.className, fieldName);
    }

    return error;
  }

  reset() {
    Object.keys(this.fields).forEach((fieldName) => {
      const field = this.fields[fieldName];
      field.editor.reset();
    });
  }

  getUpdatedAttributes() {
    const fieldValues = this.getAllFieldValues();
    return $.extend(true, {}, this.model != null ? this.model.attributes : undefined, fieldValues);
  }

  getAllFieldValues() {
    const fields = {};
    Object.keys(this.fields).forEach((fieldName) => {
      const field = this.fields[fieldName];
      const value = field.editor.getValue();
      fields[fieldName] = value;
    });
    return fields;
  }

  onChange(fieldName, editor) {
    editor.clearError();
    const value = editor.getValue();

    const updatedAttrs = this.getUpdatedAttributes();

    // validate with field values
    let errors = this.validate(fieldName, value, updatedAttrs);

    const dataError = this.fields[fieldName].editor.getError();
    if (dataError) {
      if (errors == null) {
        errors = [];
      }
      errors.push(dataError);
    }

    if (errors) {
      this.fields[fieldName].editor.setError(errors);
    } else if (this.model) {
      let left, left1;
      const attrs = $.extend(true, {}, this.model != null ? this.model.attributes : {});
      ObjectHelpers.addValueToObj(fieldName, value, attrs);
      this.model.set(attrs);

      const valueString = JSON.stringify({value});
      logging.debug(`#${ (left = this.$el.attr('id')) != null ? left : '' }.${ (left1 = this.$el.attr('class')) != null ? left1 : '' } form: ${ fieldName } changed - ${ valueString }`);
    }
  }

  clearAllErrors() {
    Object.keys(this.fields).forEach((fieldName) => {
      const field = this.fields[fieldName];
      field.editor.clearError();
    });
  }

  // Override default remove function in order to remove embedded views
  remove(options) {
    Object.keys(this.fields).forEach((fieldName) => {
      const field = this.fields[fieldName];
      field.editor.destroy();
    });
    super.remove(options);
  }
}

Form.Formatter = class Formatter {};

// Editor base class
Form.Editor = class Editor extends Marionette.ItemView {
  preinitialize(options) {
    super.preinitialize(options);

    this.defaultValue = null;

    // Used when field is optional and a value for it hasn't been selected
    this.fallbackValue = null;

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setError = this.setError.bind(this);
    this.clearError = this.clearError.bind(this);
    this.setFormattingOptions = this.setFormattingOptions.bind(this);
  }

  initialize() {
    this.setValue(this.defaultValue);
  }

  getTemplate() {
    return false;
  }

  events() {
    return {
      change: 'onChange',
      focus: 'onFocus',
      blur: 'onBlur'
    };
  }

  getValue() {
    return this.$el.val();
  }

  setValue(value) {
    if (value != null) {
      this.$el.val(value);
    }
  }

  setAttributes(attributes) {
    if (attributes != null) {
      this.$el.attr(attributes);
    }
  }

  reset() {
    this.setValue(this.defaultValue);
  }

  focus() {
    if (this.hasFocus) {
      return;
    }
    this.$el.trigger('focus');
  }

  blur() {
    if (!this.hasFocus) {
      return;
    }
    this.$el.trigger('blur');
  }

  onChange() {
    this.trigger('change', this);
  }

  onFocus() {
    this.trigger('focus', this);
  }

  onBlur() {
    this.trigger('blur', this);
  }

  setError() {
    this.$el.addClass('rederror').attr('aria-invalid', 'true');
  }

  clearError() {
    this.$el.removeClass('rederror').removeAttr('aria-invalid');
  }

  getError() {
    // Should be implemented by a child class.
  }

  validateFormEditor() {
    // Should be implemented by a child class.
  }

  setFormattingOptions(formattingOptions = {}) {
    this.formattingOptions = formattingOptions;
  }
};

// Text Editor
Form.Editor.Text = class Text extends Form.Editor {
  preinitialize(options) {
    super.preinitialize(options);

    this.defaultValue = '';
  }

  initialize(options = {}) {
    super.initialize(options);
    this.autoFocus = options && options.options ? options.options.autoFocus : false;

    _.defer(() => {
      if (this.autoFocus === true) {
        this.focus();
      }
    });
  }

  getValue() {
    if (typeof this.$el.val() === 'string') {
      return this.$el.val().trim();
    }
    return this.$el.val();
  }

  setValue(value = this.defaultValue) {
    super.setValue(value);
  }

  disable() {
    this.$el[0].disabled = true;
  }
};

// Number Editor
Form.Editor.Number = class Number extends Form.Editor {
  // Prevent non-numeric input
  onKeypress = () => {};

  constructor(options) {
    super(options);

    this.getValue = this.getValue.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  preinitialize(options) {
    super.preinitialize(options);

    this.defaultValue = 0;
  }

  events() {
    return {
      keypress: 'onKeypress'
    };
  }


  initialize(options) {
    super.initialize(options);
    // To trigger onChange in Chrome, we have to observe HTML5 'oninput' event.
    this.el.oninput = this.onChange;
  }

  // Get the whole new value
  getValue() {
    let value = this.$el.val();
    if (_.isString(value)) {
      value = parseInt(value, 10);
    }
    if (_.isNaN(value)) {
      value = null;
    }
    return value;
  }

  setValue(value) {
    if (_.isNumber(value)) {
      this.$el.val(value);
    } else if (_.isString(value)) {
      const valueInt = parseInt(value, 10);
      if (!_.isNaN(valueInt)) {
        this.$el.val(valueInt);
      }
    }
  }

  disable() {
    this.$el.prop('disabled', true);
  }

  enable() {
    this.$el.prop('disabled', false);
  }
};

// TextArea Editor
Form.Editor.TextArea = class TextArea extends Form.Editor {
  preinitialize(options) {
    super.preinitialize(options);

    this.defaultValue = '';
  }

  getValue() {
    return this.$el.val().trim();
  }

  setValue(value = this.defaultValue) {
    super.setValue(value);
  }
};

// Checkbox Editor
Form.Editor.Checkbox = class Checkbox extends Form.Editor {
  constructor(options) {
    super(options);

    this.getValue = this.getValue.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  preinitialize(options) {
    super.preinitialize(options);

    this.defaultValue = false;
  }

  getValue() {
    return this.$el.prop('checked');
  }

  setValue(value = this.defaultValue) {
    this.$el.prop('checked', value);
  }

  disable() {
    this.$el[0].disabled = true;
  }
};

// Select Editor
Form.Editor.Select = class Select extends Form.Editor {
  constructor(options) {
    super(options);

    this._deserializeMultipleValue = this._deserializeMultipleValue.bind(this);
    this._deserializeSingleValue = this._deserializeSingleValue.bind(this);
    this._serializeMultipleValue = this._serializeMultipleValue.bind(this);
    this._serializeSingleValue = this._serializeSingleValue.bind(this);
  }

  preinitialize(options) {
    super.preinitialize(options);

    this.optionTemplate = _.tpl(`\
      <option
        value="<%- id %>"
        <% if (typeof className !== 'undefined') { %>class="<%- className %>"<% } %>
        <% if (typeof title !== 'undefined') { %>title="<%- title %>"<% } %>
        <% if (typeof disabled !== 'undefined') {  %>data-disabled="disabled" disabled<% } %>
      ><%- value %></option>\
      `);
  }

  initialize(options = {}) {
    this.collection = options.options && options.options.collection ? options.options.collection : options.options;
    this.formattingOptions = {};
    this.optionsClass = options.options && options.options.optionsClass ? options.options.optionsClass : '';
    this.optionItemFormatter = options.options && options.options.optionItemFormatter ? options.options.optionItemFormatter : () => {};
    this.shouldParseInteger = options.options && 'shouldParseInteger' in options.options ? options.options.shouldParseInteger : true;

    this.addDefaultOption = (this.$el.attr('no-default') == null);
    this.shallowValue = (this.$el.attr('data-shallow') != null);
    this.isMultiSelect = (this.$el.attr('multiple') != null);
    this.isDisabledOptionsAllowed = (this.$el.attr('allow-disabled') != null);

    this.setSelectedDefaults();

    this.initializeOptions(this.collection != null ? this.collection : options.options);

    this.reset();
  }

  initializeOptions(options) {
    if (options != null) {
      this.setOptions(options);
    }
  }

  setFormattingOptions(options = {}) {
    this.formattingOptions = options;
  }

  setSelectedDefaults() {
    // If the form already has an option with the selected attribute treat that as the default
    const $option = this.$('option:selected');

    this.defaultValue = this._getDefaultValue($option);
    this.defaultText = this._getDefaultText($option);
  }

  _getDefaultValue($option = $()) {
    const value = $option.val();

    // Check if the option has a value, specifically a 'value' attribute
    // Give -1 as default value to the default option that does not have a value
    const defaultValue = value != null && $option.is('option[value]') ? this._parseIntValue(value) : -1;

    if (this.isMultiSelect) {
      return [].concat(defaultValue);
    }
    return defaultValue;

  }

  _getDefaultText($option = $()) {
    const text = $option.text();

    if ((text && text.length) > 0) {
      return text;
    }
    return I18n.t('UIKit.Form.Editor.Select.select');

  }

  _parseIntValue(value) {
    if (!this.shouldParseInteger) {
      return value;
    }

    const parsed = parseInt(value, 10);

    if (_.isNaN(parsed)) {
      return value;
    }
    return parsed;

  }

  setOptions(options) {
    if (this.options != null) {
      this.stopListening(this.options, 'reset update', this.onOptionsChange);
    }

    this.options = options;

    this.renderOptions();

    if (this.options instanceof Backbone.Collection) {
      this.listenTo(this.options, 'reset update', this.onOptionsChange);
    } else {
      logging.warn('Form.Editor.Select only supports Backbone.Collection. Please make sure the context option is set accordingly.');
    }
  }

  renderOptions() {
    this.$el.empty();

    const options = [];

    if (this.addDefaultOption) {
      options.push(this._createOptionElement(this.defaultValue, this.defaultText));
    }

    if (this.options instanceof Backbone.Collection) {
      this.options.each((model) => {
        const option = model.toOption(this.formattingOptions);
        options.push(this._createOptionElement(option.id, option.value, option.title, option.disabled));
      });
    }

    this.$el.append(options.join(''));

    this.render();
  }

  _createOptionElement(id, value, title, disabled) {
    let className = this.optionsClass;
    if (typeof this.optionsClass === 'function') {
      className = this.optionsClass(id, value, title);
    }
    return this.optionTemplate({
      id,
      value,
      title,
      disabled,
      className,
      optionItemFormatter: this.optionItemFormatter
    });
  }

  onOptionsChange() {
    const prevValue = this.value;

    this.renderOptions();

    this.setValue(prevValue);
  }

  onChange() {
    this.trigger('change', this);
    this.trigger('change:selection', this);
  }

  onRender() {
    this.setValue(this.value);
    this.trigger('change:selection', this);
  }

  getValue() {
    // this line is needed for AOI select,
    // where we have different types of options that can be set by sytem
    // and user is not allowed to chnage it, we enable options
    // to be able to get these values with val(), val() doesn't return disabled options
    this._toggleAllowedDisabledOptions(false);

    const value = this.$el.val();

    this._toggleAllowedDisabledOptions(true);

    if (this.isMultiSelect) {
      return this._deserializeMultipleValue(value);
    }
    return this._deserializeSingleValue(value);

  }

  _deserializeMultipleValue(vals) {
    const values = vals != null ? vals : [];
    return _.compact(_.map(values, this._deserializeSingleValue));
  }

  _deserializeSingleValue(value) {
    let valueParsed = this._parseIntValue(value);

    if (valueParsed === 'true') {
      valueParsed = true;
    } else if (valueParsed === 'false') {
      valueParsed = false;
    } else if (valueParsed === 'null') {
      valueParsed = null;
    }

    if (this.options instanceof Backbone.Collection) {
      if (valueParsed !== -1 || !this.addDefaultOption) {
        if (!this.shallowValue) {
          return {id: valueParsed};
        }

        return valueParsed;
      }

      return null;
    }

    return valueParsed;
  }

  _toggleAllowedDisabledOptions(disable) {
    if (this.isDisabledOptionsAllowed) {
      this.$('[data-disabled="disabled"]').prop('disabled', disable);
    }
  }

  setValue(value) {
    const valueToUse = this.isMultiSelect
      ? this._serializeMultipleValue(value)
      : this._serializeSingleValue(value);

    this.value = valueToUse;

    this.$el.val(valueToUse);
  }

  _serializeMultipleValue(vals) {
    const values = vals != null ? vals : this.defaultValue;
    return _.compact(_.map(values, this._serializeSingleValue));
  }

  _serializeSingleValue(val) {
    let value = val != null ? val : this.defaultValue;
    value = (value && value.id) || value;

    if (value === true) {
      value = 'true';
    } else if (value === false) {
      value = 'false';
    }

    return value;
  }

  disable() {
    this.$el.prop('disabled', true);
  }

  enable() {
    this.$el.prop('disabled', false);
  }

  setError() {
    this.$el.parent().addClass('rederror');
  }

  clearError() {
    this.$el.parent().removeClass('rederror');
  }
};

module.exports = Form;
