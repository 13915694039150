const logging = require('logging');
const Backbone = require('Backbone');
const _ = require('underscore');
const {
  ReferralBountyHunterList,
  ReferralAvailableUserList,
  ReferralBountyHunterModel
} = require('../collections/ReferralLists');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

class Referrals extends Backbone.Model {
  defaults() {
    return {
      captured: [],
      claimed: {},
      available: [],
      recent: []
    };
  }

  apiEndpoint() {
    return '/bounty/all';
  }

  initialize() {
    this.captured = new ReferralBountyHunterList();
    this.recent = new ReferralBountyHunterList();
    this.available = new ReferralAvailableUserList();
    this.claimed = new ReferralBountyHunterModel();

    this.listenTo(this, 'change:captured', (model, attr) => {
      this.captured.set(attr);
    });

    this.listenTo(this, 'change:recent', (model, attr) => {
      this.recent.set(attr);
    });

    this.listenTo(this, 'change:available', (model, attr) => {
      this.available.set(attr);
    });

    this.listenTo(this, 'change:claimed', (model, attr) => {
      this.claimed.set(attr);
    });
  }

  selectBounty(bountyId, options = {}) {
    const data = {};

    const onSuccess = options.success;
    const onError = options.error;
    return $.ajax(
      $.extend(true, options, {
        type: 'POST',
        apiEndpoint: `/bounty/${ bountyId }/claim`,
        skipGlobalHandler: true,
        data: JSON.stringify(data),
        success: (response) => {
          logging.info(`Successfully selected bounty for user ${ bountyId }`);
          this.trigger('bountySelected');
          if (_.isFunction(onSuccess)) {
            onSuccess(response);
          }
        },
        error: (xhr) => {
          logging.error(`Failed to select bounty for user ${ bountyId }`);
          this.trigger('bountySelectionError', this.parseXhrIntoBountyExceptionCode(xhr));
          if (_.isFunction(onError)) {
            onError(xhr);
          }
        }
      })
    );
  }

  removeBounty(id, options) {
    const onSuccess = options.success;
    const onError = options.error;
    return $.ajax(
      $.extend(true, options, {
        type: 'POST',
        apiEndpoint: `/bounty/${ id }/unclaim`,
        skipGlobalHandler: true,
        success: (response) => {
          logging.info(`Successfully unclaimed bounty ${ id }`);
          this.trigger('bountyDeleted');
          if (_.isFunction(onSuccess)) {
            onSuccess(response);
          }
        },
        error: (xhr) => {
          logging.error(`Failed to unclaimed bounty ${ id }`);
          this.trigger('bountyDeletedError', this.parseXhrIntoBountyExceptionCode(xhr));
          if (_.isFunction(onError)) {
            onError(xhr);
          }
        }
      })
    );
  }

  parseXhrIntoBountyExceptionCode(xhr) {
    try {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);

      if (exception.getErrorCode() === AxonifyExceptionCode.NO_ERROR_CODE) {
        return AxonifyExceptionCode.CLIENT_ERROR_BOUNTY_INVALID_STATE;
      }
      return exception.getErrorCode();

    } catch (error) {
      logging.error(
        'There was a problem parsing the response code from the server. Will just return some generic code.'
      );
      logging.error(xhr);
      logging.error(error);
      return AxonifyExceptionCode.CLIENT_ERROR_BOUNTY_INVALID_STATE;
    }
  }

  getRecentActivityUsers() {
    return this.recent;
  }

  getAvailableUsers() {
    return this.available;
  }

  getPendingUser() {
    return this.captured;
  }

  getInProgressUsers() {
    return this.captured;
  }
}

module.exports = Referrals;
