const _ = require('underscore');

const UIKit = require('@training/widgets/UIKit');

require('@common/libs/behaviors/card/Card');
const {
  INTERACTIVE,
  SMALL_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');

class GameItemView extends UIKit.View {
  className() {
    let className = 'game-wrapper';
    if (this.getOption('gridFullWidthMobile')) {
      className += ' ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--l-3';
    } else {
      className += ' ax-grid__col--4 ax-grid__col--l-3';
    }
    return className;
  }

  attributes() {
    return {
      role: 'listitem'
    };
  }

  getTemplate() {
    return _.tpl(`\
    <button class="game touchable clearfix" data-game-id="<%- game.id %>">
      <div class="game-container">
        <div class="type <%- game.type %>"></div>
        <div class="game-meta">
          <div class="game-name ax-font ax-font__display ax-font--no-margin"><%- t('games.' + game.type) %></div>
          <p class="game-type ax-font--no-margin ax-font--secondary"><%- t('games.genre.' + game.metadata.genre) %></p>
        </div>
        <div class="icon-angle_right row-arrow silver"></div>
      </div>
    </button>\
    `);
  }

  render() {
    this.$el.html(this.getTemplate()({game: this.model.toJSON()}));
    return this;
  }
}

class GameItemCard extends GameItemView {
  behaviors() {
    return {
      Card: {
        target: '.game',
        modifierClasses: [INTERACTIVE, SMALL_PADDING]
      }
    };
  }
}

module.exports = {
  GameItemView,
  GameItemCard
};
