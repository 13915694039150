// Module
var code = `<div class="user-messaging">
  <h1 class="page-header">
    <div class="ax-container">
      <div class="page-header__content page-header__content--justify-start">
        <span class="icon-bullhorn page-header__icon"></span>
        <div>
          <span class="page-header__title"><%- t('broadcastMessage.header') %></span>
        </div>
      </div>
    </div>
  </h1>

  <div class="ax-container">
    <div class="page-content">
      <div class="broadcastmessage">
        <div class="broadcast-message__header qa-broadcast-message-header">
          <div class="broadcast-message__header-icon qa-broadcast-message-header-icon">
            <span class=<%= communicationIcon %>></span>
          </div>
          <div class="broadcast-message__header-announcement qa-broadcast-message-header-announcement">
            <div><%= communicationTypeTitle %></div>
            <div class="broadcast-message__header-announcement-time qa-broadcast-message-header-announcement-time">
              <%- communicationTime %>
            </div>
          </div>
          <div class="broadcast-message__header-points qa-broadcast-message-header-points">
            <div><%- rewardPoints %></div>
          </div>
        </div>
        <h3 class="broadcast-message__body-subject qa-broadcast-message-subject"><%= subject %></h3>
        <div class="broadcast-message-video-wrapper <% if (hasVideo) { %> broadcast-message-video-wrapper--active <% } %>"></div>
        <article class="rte-message fr-view broadcast-message__body-message" aria-label="<%- t('broadcastMessage.messageBody') %>">
          <%= message %>
          <div class="clearfix"></div>
        </article>
        <% if (dzLink) { %>
        <div class="broadcast-message__link-content" role="region" aria-label="<%- t('appNames.knowledge') + ' ' +t('broadcastMessage.region.dzWholeRegion') %>"></div>
        <% } %>

        <div class="js-survey__answer-options"></div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;