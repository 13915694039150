const I18n = require('@common/libs/I18n');
const logging = require('logging');
const ExternalVideoView = require('@common/components/training_modules/video/ExternalVideoView');
const ExternalLinkDefaultTemplateHelpers = require('@training/apps/training/views/activities/pages/ExternalLinkDefaultTemplateHelpers');
const HeartbeatController = require('@common/libs/HeartbeatController');

const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');

require('@common/libs/behaviors/card/Card');

class ExternalVideoNewWindowView extends ExternalVideoView {
  initialize(options = {}) {
    ({
      complete: this.complete = () => {}
    } = options);

    this.activity = assertPropertyExists(options, 'activity');
    this.continueButtonFactory = assertPropertyExists(options, 'continueButtonFactory');

    const videoLink = this.activity.getData().urlLink;
    this._videoUrl = videoLink.url;

    this.heartbeatController = new HeartbeatController();
  }

  className() {
    return 'external-link-page ax-container';
  }

  getTemplate() {
    return require('@training/apps/training/templates/activities/pages/ExternalLinkPage.html');
  }

  templateHelpers() {
    return Object.assign({}, ExternalLinkDefaultTemplateHelpers.get(this.activity), {
      showLaunchButton: true
    });
  }

  ui() {
    return {
      viewButton: '#start'
    };
  }

  behaviors() {
    return {
      Card: {
        target: '.topic-tile'
      }
    };
  }

  launchNewWindowVideo() {
    super.launchNewWindowVideo();
    this._updateUiElementsModuleCompleted();
    this.heartbeatController.startHeartbeat();

    const button = this.getContinueButtonConfig();
    window.app.layout.actionBar.setActionBar({ buttons: button });
  }

  getContinueButtonConfig() {
    return this.continueButtonFactory(this.onNext.bind(this));
  }

  onNext() {
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    logging.info(`ExternalVideoNewWindowView - onNext ${ this.activity.id }`);

    const activityBody = { duration: this.activity.activityTimer.elapsedTime() };

    this.activity.setAction('VIDEOSAVE', activityBody, {
      success: () => {
        logging.info(`ExternalVideoNewWindowView - setAction success ${ this.activity.id }`);
        const points = this.activity.get('points');
        window.apps.auth.session.user.addPoints(points);
        this.complete();
      }
    });

    return undefined;
  }

  _updateUiElementsModuleCompleted() {
    this.ui.viewButton.text(I18n.t('general.relaunch'));
    this.ui.viewButton.toggleClass('white', true);
  }

  onDestroy() {
    super.onDestroy();

    window.app.layout.actionBar.setActionBar();
    this.heartbeatController.stopHeartbeat();
  }
}

module.exports = ExternalVideoNewWindowView;
