import {
  FormControlLabel as MuiFormControlLabel,
  styled,
  type FormControlLabelProps as MuiFormControlLabelProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

export type AxFormControlLabelProps = MuiFormControlLabelProps;

const styledOptions = {
  name: 'AxFormControlLabel'
};

const StyledAxFormControlLabel = styled(MuiFormControlLabel, styledOptions)<AxFormControlLabelProps>(({
  theme,
  label,
  labelPlacement = 'end'
}) => {
  let labelPadding = '0';

  if (label != null && label !== '') {
    if (labelPlacement === 'start') {
      labelPadding = `0 ${ theme.uiKit.spacingS } 0 0`;
    } else if (labelPlacement === 'end') {
      labelPadding = `0 0 0 ${ theme.uiKit.spacingS }`;
    } else if (labelPlacement === 'top') {
      labelPadding = `0 0 ${ theme.uiKit.spacingS } 0`;
    } else if (labelPlacement === 'bottom') {
      labelPadding = `${ theme.uiKit.spacingS } 0 0 0`;
    }
  }

  return {
    padding: `0 0 ${ theme.uiKit.spacingM } 0`,
    margin: 0,
    '&.MuiDisabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed'
    },
    '&.MuiFormControlLabel-labelPlacementTop, &.MuiFormControlLabel-labelPlacementBottom': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControlLabel-label': {
      padding: labelPadding
    },
    fontSize: theme.uiKit.fontSizeS,
    color: theme.uiKit.colorGrey80,
    lineHeight: theme.uiKit.lineHeightS,
    fontWeight: theme.uiKit.fontWeightRegular
  };
});

/**
 * AxFormControlLabel is a component in Material UI that combines a label with a form control
 * such as a checkbox, switch, or radio button. It links the label with the form control,
 * ensuring they are aligned and accessible.
 *
 * It is commonly used to display a label next to a form control, allowing users to
 * select or toggle an option with a clear description.
 *
 * @example
 * // A checkbox with a label
 * <AxFormControlLabel
 *   control={<AxCheckbox />}
 *   label="Accept Terms and Conditions"
 * />
 *
 */
export const AxFormControlLabel = forwardRef(({
  ...otherProps
}: AxFormControlLabelProps, ref: Ref<HTMLDivElement>) => {
  const FormControlLabelProps: AxFormControlLabelProps = {
    ...otherProps
  };

  return (
    <StyledAxFormControlLabel { ...FormControlLabelProps } ref={ ref } />
  );
});

export default AxFormControlLabel;
