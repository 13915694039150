const PdfContentShowView = require('./views/PdfContentShowView');

module.exports = (creationConfig) => {
  return {
    isMatch(mediaFile) {
      const filename = mediaFile.get('originalFile').originalFileName;
      const type = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
      return type === 'pdf';
    },

    build() {
      return new PdfContentShowView(creationConfig);
    }
  };
};
