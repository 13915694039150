const CommunityInsightsTableLayoutView = require('@training/apps/training/communitiesInsights/components/tables/CommunityInsightsTableLayoutView');

class CurrentPagesTableLayoutView extends CommunityInsightsTableLayoutView {
  initialize(options = {}) {
    super.initialize(options);
    this.listenTo(this.collection, 'stateTimeFrame:change', () => {
      this.render();
    });
  }
}

module.exports = CurrentPagesTableLayoutView;
