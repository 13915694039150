const Cocktail = require('backbone.cocktail');
const {
  CompositeView,
  ItemView
} = require('Marionette');

const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');

const ProfileIconHandler = require('@common/components/ProfileIconHandler');

const EmptyCollectionViewMixin = require('@common/mixins/empty_collection/EmptyCollectionViewMixin');

class PageHistoryContentView extends CompositeView {
  className() {
    return 'ax-container contributor-history';
  }

  getTemplate() {
    return `
    <div class="contributor-history-title">
      <h2 class="ax-font--no-margin">${ I18n.t('discover.pageView.history.versionHistory') }</h2>
    </div>
    <div class="contributor-history-list"></div>
    `;
  }

  childViewContainer() {
    return '.contributor-history-list';
  }

  getChildView() {
    return RevisionRowView;
  }
}

Cocktail.mixin(PageHistoryContentView, EmptyCollectionViewMixin);

class RevisionRowView extends ItemView {
  className() {
    return 'clearfix contributor-history-revision';
  }

  getTemplate() {
    return `
      <div aria-hidden="true" class="page-preview-meta-content__avatar" data-contributor-id="<%- author.user.id %>"></div>
      <div class="page-preview__meta-content-contributors">
        <div class="version"><%-t('discover.pageView.history.version', {revision:revision}) %></div>
        <div class="date"><%- createDate(author.timestamp) %></div>
        <div class="message"> <%- t('discover.pageView.history.updatedBy') %> <%- author.user.salutationName %></div>
        <% if (editComment) { %>
          <div class="comment">&ldquo;<%- editComment %>&rdquo;</div>
        <% } %>
      </div>
    `;
  }

  templateHelpers() {
    return {
      createDate: (timestamp) => {
        return dateHelpers.convertDateFormatToDefaultDate(timestamp);
      }
    };
  }

  onRender() {
    const contributor = this.model.toJSON();
    const $profileElement = this.$(`.page-preview-meta-content__avatar[data-contributor-id=${ contributor.author.user.id }]`);
    this._getProfileIcon(contributor.author.user, $profileElement);
  }

  _getProfileIcon(user, $profileElement) {
    return new ProfileIconHandler({
      user,
      profileElement: $profileElement
    });
  }
}

module.exports = PageHistoryContentView;
