// Module
var code = `<div id="resetPasswordForm">
  <div class="recovery-input">
    <div class="title-wrapper">
      <div class="title-block">
        <div class="recovery-icon icon-content"></div>
        <div class="recovery-title-wrapper">
            <div class="recovery-title"><%- t('login.passwordReset.reset.title') %></div>
            <div><%- t('login.passwordReset.reset.subTitle') %></div>
        </div>
      </div>
    </div>
    <div class="password">
      <label for="newpassword" style="display:block; border:0;">
        <%- t('login.passwordReset.reset.newPassword') %>
      </label>
      <div data-editor="Password" data-field="newpassword" id="newpassword" data-options="passwordOptions" autocomplete="off" class="password"></div>
    </div>

    <div class="password">
      <label for="confirmpassword">
        <%- t('login.passwordReset.reset.confirmPassword') %>
      </label>
      <div data-editor="Password" data-field="confirmpassword" id="confirmpassword" data-options="passwordOptions" autocomplete="off" class="password"></div>
    </div>
    <div class="password-rules">
      <div id="js-password-ruleset"></div>
    </div>
    <div class="recovery-submit">
      <a href="#" class="axon-button submit"><%- t('login.submit') %></a>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;