const ProgramOverviewIntroController = require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewIntroController');
const ProgramOverviewPathContentController = require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewPathContentController');
const ProgramOverviewProgressController = require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewProgressController');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');
const { ReactControllerDefinitionFactory } = require('@common/modules/react');
const AxonifyApps = require('@common/data/enums/AxonifyApps').default;
require('@common/libs/behaviors/scrollable/Scrollable');

class ProgramOverviewController extends StackedLayoutViewController {

  viewDefinition() {
    return Object.assign(super.viewDefinition(), {
      className: 'path-overview ax-grid'
    });
  }

  get childControllers() {

    const controllers = [
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('path-overview__intro ax-grid__col--12 ax-grid__col--m-8'),
        {
          ViewControllerClass: ProgramOverviewIntroController,
          model: this.options.model
        }),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('path-overview__sidebar ax-grid__col--12 ax-grid__col--m-4'),
        {
          ViewControllerClass: ProgramOverviewProgressController,
          model: this.options.model,
          showUnassign: this.options.showUnassign || false,
          objectiveStarter: this.options.objectiveStarter,
          objectiveValidator: this.options.objectiveValidator,
          sortedObjectives: this.options.sortedObjectives,
          isReadOnly: this.options.isReadOnly
        }),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('path-overview__content ax-grid__col--12 ax-grid__col--m-8'),
        {
          ViewControllerClass: ProgramOverviewPathContentController,
          viewDefinition: {
            model: this.options.model,
            objectiveStarter: this.options.objectiveStarter,
            objectiveValidator: this.options.objectiveValidator,
            sortedObjectives: this.options.sortedObjectives,
            viewTopicDrillDown: this.options.viewTopicDrillDown,
            isReadOnly: this.options.isReadOnly
          }
        })
    ];

    if (env.settings.app === AxonifyApps.MANAGER) {
      controllers.push(ReactControllerDefinitionFactory({
        component: import('@common/components/guidedLearning/objectives/reassignTraining/ReassignTrainingModal'),
        props: {
          userId: this.options.model.userId
        }
      }));
    }

    return controllers;
  }
}

module.exports = ProgramOverviewController;
