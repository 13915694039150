const Marionette = require('Marionette');
const Backbone = require('Backbone');
const logging = require('logging');

const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');

const EvaluationLastFailedForm = require('@training/apps/training/models/EvaluationLastFailedForm');

class AttemptDetailsCardInfoView extends Marionette.ItemView {

  className() {
    return 'attempt-details-info';
  }

  getTemplate() {
    return `\
      <% if (isPendingAfterRetry || evaluationDateText) { %>
        <div class="attempt-details-info__result">
          <% if (isPendingAfterRetry) { %>
            <button class="ax-button view-last-attempt">
              <%- t('general.viewLastAttempt') %>
            </button>
          <% } else if (evaluationDateText) { %>
            <span><%- t('GuidedLearning.evaluation.details.lastAttemptDate') %></span> <span class="attempt-details-info__result--evaluation-date"><%- evaluationDateText %></span>
          <% } %>
        </div>
      <% } %>
      <% if (!isViewingLastAttempt && rewardPointsText) { %>
        <div class="attempt-details-info__points">
          <p><%- rewardPointsText %></p>
        </div>
      <% } %>\
    `;
  }

  templateHelpers() {
    return {
      isPendingAfterRetry: this.getOption('evaluationStatus') === EvaluationObjectiveStatus.PENDING_AFTER_RETRY,
      evaluationDateText: this._getEvaluationDateText(),
      rewardPointsText: this._getPointsText(),
      isViewingLastAttempt: this.getOption('isViewingLastAttempt')
    };
  }

  events() {
    return {
      'click .view-last-attempt': '_onViewLastAttemptClick'
    };
  }

  _onViewLastAttemptClick() {
    const lastFailedFormId = this.getOption('lastFailedFormId');
    const evaluationDetailsRoute = this.getOption('evaluationDetailsRoute');

    if (lastFailedFormId == null) {
      logging.error('lastFailedFormId is invalid');
      return undefined;
    }

    const evaluationLastFailedForm = new EvaluationLastFailedForm({
      lastFailedFormId
    });

    return Promise.resolve(evaluationLastFailedForm.fetch()).then(() => {
      if (this.isDestroyed) {
        return;
      }

      window.app.layout.setView({
        ViewControllerClass: this.getOption('evaluationController'),
        evaluationOption: this.getOption('evaluationOption'),
        evaluationForm: evaluationLastFailedForm,
        isViewingLastAttempt: true
      });

      Backbone.history.navigate(UrlHelpers.combineUrlPaths(evaluationDetailsRoute, 'lastFailed', lastFailedFormId));
    });
  }

  _getEvaluationDateText() {
    const evaluationStatus = this.getOption('evaluationStatus');
    const evaluatedDate = this.getOption('evaluatedDate');
    const isViewingLastAttempt = this.getOption('isViewingLastAttempt');

    if (isViewingLastAttempt || [EvaluationObjectiveStatus.FAILED, EvaluationObjectiveStatus.PASSED].includes(evaluationStatus)) {
      return dateHelpers.convertDateFormatLongDateWithStrings(evaluatedDate);
    }

    return undefined;
  }

  _getPointsText() {
    const points = this.getOption('pointsAvailable');
    if (points > 0) {
      return I18n.t('GuidedLearning.evaluation.details.pointsAvailable', { points });
    }
    return undefined;
  }
}

module.exports = AttemptDetailsCardInfoView;
