const LearningEventsHelpers = {
  // learning event enrollments list API changed, so the server returns only fields that are needed
  // this function returns the user object as it was before the API change
  getEnrollmentUserObj(enrollment) {
    const {
      userId: id,
      name,
      fullname,
      nickname,
      salutationName,
      locationName: location,
      profileImage,
      employeeId,
      jobtitle
    } = enrollment;

    return {
      id,
      name,
      fullname,
      nickname,
      salutationName,
      location: {
        name: location
      },
      profileImage,
      employeeId,
      jobTitle: {
        name: jobtitle
      }
    };
  }
};

module.exports = LearningEventsHelpers;
