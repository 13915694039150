const _ = require('underscore');
const Backbone = require('Backbone');

class ProfileLanguageModel extends Backbone.Model {
  initialize() {
    this.idAttribute = 'userId';
    this.listenTo(this, 'sync', this._onSync);
  }

  url() {
    return '/axonify/selfserve/user/languages';
  }

  parse(res) {
    delete res.status;
    return res;
  }

  save(attributes, options) {
    const searchLanguages = new Set(this.get('preferredSearchLanguages'));

    searchLanguages.add(this.getPrimaryLanguageShortCode());
    this.set('preferredSearchLanguages', [...searchLanguages]);
    return super.save(attributes, options);
  }

  getPrimaryLanguageShortCode() {
    // The languages in the primary list can be XX-XX format,
    // the languages in the secondary list are only XX.
    // This functon transforms it into the necessary format for
    // comparisons and to add it to the list of search languages
    const primaryLanguageCode = this.get('primaryLanguage');
    if (primaryLanguageCode != null) {
      return primaryLanguageCode.split('-')[0];
    }
    return undefined;
  }

  getMatchingRootLanguage(rootLanguageList = []) {
    const primaryLanguage = this.get('primaryLanguage');
    if (rootLanguageList.includes(primaryLanguage)) {
      return primaryLanguage;
    }

    return this.getPrimaryLanguageShortCode();
  }

  _onSync(model) {
    this._primaryLanguageChanged = this._originalModel && this._originalModel.get('primaryLanguage') !== this.get('primaryLanguage');
    this._originalModel = model.clone();
  }

  isChangedByUser() {
    const primaryLanguageChanged = this._originalModel && this._originalModel.get('primaryLanguage') !== this.get('primaryLanguage');
    const searchLanguagesChanged = this._originalModel && !_.isEqual(this._originalModel.get('preferredSearchLanguages').sort(), this.get('preferredSearchLanguages').sort());

    return primaryLanguageChanged || searchLanguagesChanged;
  }

  didPrimaryLanguageChange() {
    return this._primaryLanguageChanged;
  }
}

module.exports = ProfileLanguageModel;
