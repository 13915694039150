const Backbone = require('Backbone');
const dateHelpers = require('@common/libs/dateHelpers');

const MEDIA_ID_FIELD_NAME = 'mediaId';
const MEDIA_PATH_FIELD_NAME = 'path';
const MEDIA_FIELD_NAME = 'media';
const FILE_FIELD_NAME = 'file';
const LANGUAGE_FIELD_NAME = 'language';
const LAST_MODIFY_TIME_FIELD_NAME = 'lastModifyTime';
const CREATE_TIME_FIELD_NAME = 'createTime';

class TaskAttachment extends Backbone.Model {
  defaults() {
    return {
      [MEDIA_ID_FIELD_NAME]: null,
      [LANGUAGE_FIELD_NAME]: null,
      [MEDIA_FIELD_NAME]: null,
      [LAST_MODIFY_TIME_FIELD_NAME]: dateHelpers.getTime()
    };
  }

  getMediaId() {
    return this.get(MEDIA_ID_FIELD_NAME);
  }

  getLanguage() {
    return this.get(LANGUAGE_FIELD_NAME);
  }

  setLanguage(language) {
    this.set(LANGUAGE_FIELD_NAME, language);
  }

  setMedia(media) {
    if (media) {
      this.set(MEDIA_ID_FIELD_NAME, media.id);
    }
    this.set(MEDIA_FIELD_NAME, media);
  }

  getMedia() {
    return this.get(MEDIA_FIELD_NAME);
  }

  setFile(file) {
    this.set(FILE_FIELD_NAME, file);
  }

  getFile() {
    return this.get(FILE_FIELD_NAME);
  }

  getMediaFilePath() {
    const media = this.getMedia();
    return media[MEDIA_PATH_FIELD_NAME];
  }

  getLastModifyTime() {
    return this.get(LAST_MODIFY_TIME_FIELD_NAME);
  }

  getCreateTime() {
    return this.get(CREATE_TIME_FIELD_NAME);
  }

  toJSON() {
    const json = super.toJSON();

    if (json[MEDIA_FIELD_NAME]) {
      delete json[MEDIA_FIELD_NAME].id;
    }

    return json;
  }
}

module.exports = TaskAttachment;
