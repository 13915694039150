const Promise = require('bluebird');

class StaticAssessmentInitiatorConfigurationProvider {
  constructor(options = {}) {
    this.options = options;
  }

  getConfigurationAsync() {
    return Promise.resolve(this.options);
  }
}

module.exports = StaticAssessmentInitiatorConfigurationProvider;
