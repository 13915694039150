const { LayoutView } = require('Marionette');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const InsightsHowToModalView = require('@training/apps/insights/InsightsHowToModalView');

class PageInsightsView extends LayoutView {

  get template() {
    return require('@training/apps/insights/PageInsightsTemplate.html');
  }

  regions() {
    return {
      breadcrumbRegion: '.js-breadcrumb-region',
      overviewRegion: '.js-team-insights-overview-region',
      engagementRegion: '.js-team-insights-engagement-region',
      subteamsRegion: '.js-team-insights-subteams-region'
    };
  }

  ui() {
    return {
      howToButton: '.js-how-to-link'
    };
  }

  events() {
    return {
      'click @ui.howToButton': this._onClickHowTo
    };
  }

  onAttach() {
    window.app.layout.setTitle(I18n.t('discover.insights.title'));
  }

  _onClickHowTo() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal page-insights-how-to confirm-dialog-view'
    });

    const modalChildView = new InsightsHowToModalView({});

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }
}

module.exports = PageInsightsView;
