const _ = require('underscore');

const Backbone = require('Backbone');

const GameChallenge = require('../models/GameChallenge');

class GameChallengesList extends Backbone.Collection {
  apiEndpoint() {
    return '/games/challenges';
  }

  preinitialize() {
    this.model = (challenge) => {
      // The server returns a simple `game` object inside each `challenge` but we
      // can get the full game object from the `allSupportedGames` collection.
      const gameForChallenge = this.allSupportedGames.get(challenge.game.id);
      const gameChallengeJSON = gameForChallenge ? gameForChallenge.toJSON() : {};

      const gameChallenge = new GameChallenge({game: gameChallengeJSON});
      return gameChallenge.set(gameChallenge.parse(challenge));
    };
  }

  initialize(models, options = {}) {
    ({allSupportedGames: this.allSupportedGames, maxChallenges: this.maxChallenges} = options);
  }

  parse(response = {}) {
    const results = response.results || [];

    const challengesForAvailableGames = results
      .filter(((challenge) => {
        return (this.allSupportedGames.get(challenge.game.id) != null);
      }));

    if (this.maxChallenges) {
      return _.first(challengesForAvailableGames, this.maxChallenges);
    }

    return challengesForAvailableGames;
  }
}
module.exports = GameChallengesList;
