// Module
var code = `<div class="winner-user-row">
  <div class="winner-user-wrapper clearfix">
    <div class="name-logo" role="presentation" aria-hidden="true"></div>
    <div class="winner-user-details">
      <div class="winner-user-name">
        <%- name %>
      </div>
      <div class="winner-user-department">
        <%- department %>
      </div>
    </div>
    <% if (!_.isEmpty(detail)) { %>
    <div class="winner-detail-area pull-right">
      <%- t('usermessage.prizeSummary.winnerDetail.' + gameType, detail) %>
    </div>
    <% } else { %>
      <div class="winner-detail-area pull-right boldfont">
          <%= t('auction.winner') %>
        </div>
    <% } %>
  </div>
</div>
`;
// Exports
module.exports = code;