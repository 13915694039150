const PageableCollection = require('@common/components/pageableList/PageableCollection');

class TopicSearchCollection extends PageableCollection {

  apiEndpoint() {
    return '/topics/search';
  }

  fetch(options = {}) {
    Object.assign(options, {
      data: {
        query: encodeURIComponent(this.searchQuery)
      }
    });
    return super.fetch(options);
  }

  search(searchString, pageNum, facets, options = {}) {
    return super.search(searchString, pageNum, options);
  }
}

module.exports = TopicSearchCollection;
