const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');
const TitledSectionControllerDefinition = require('@common/components/titledSection/TitledSectionControllerDefinitionFactory');

const MediaPreview = require('@common/components/mediaPreview/MediaPreview');
const ReadMoreView = require('@common/components/readMore/ReadMoreView');

const Marionette = require('Marionette');

class ProgramOverviewIntroController extends StackedLayoutViewController {

  get childControllers() {
    const children = [];
    if (this.getOption('model').getThumbnailImage() != null) {
      children.push(this._renderBannerView());
    }

    if (this.getOption('model').getDescription() != null) {
      children.push(
        TitledSectionControllerDefinition(I18n.t('general.overview'), this._renderDescription())
      );
    }

    return children;
  }

  _renderBannerView() {
    const image = this.getOption('model').getThumbnailImage();
    image.type = 'image';
    const model = MediaFactory.createMediaFromJSON(FileFactory, image);
    return {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: MediaPreview,
        model: model,
        maxWidth: 1000
      }
    };
  }

  _renderDescription() {
    return {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: ReadMoreView,
        enablePreviewGradient: true
      },
      regionControllers: {
        contentRegion: {
          viewDefinition: {
            ViewClass: Marionette.ItemView,
            template: this.getOption('model').getDescription()
          }
        }
      }
    };
  }
}

module.exports = ProgramOverviewIntroController;
