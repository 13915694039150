import HubTileIdentifiers from '@common/data/enums/HubTileIdentifiers';
import MainStatsSkeletonDefinition from '@common/components/stats/MainStatsSkeletonDefinitionFactory';
import { ReactControllerDefinitionFactory } from '@common/modules/react';

export default function MainStatsDefinitionFactory(availableTiles: Record<string, boolean>) {
  const isStatsDisabled = !availableTiles[HubTileIdentifiers.HUB_STATS];
  if (isStatsDisabled) {
    return null;
  }

  return () => {
    return ReactControllerDefinitionFactory({
      component: import('@common/components/stats/MainStatsView'),
      props: {
        classes: 'training_stats__list'
      },
      loadingControllerDefinition: MainStatsSkeletonDefinition()
    });
  };
}
