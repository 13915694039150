const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const TitledSectionControllerDefinition = require('@common/components/titledSection/TitledSectionControllerDefinitionFactory');
const ReadMoreView = require('@common/components/readMore/ReadMoreView');

class EvaluationDetailsDescriptionController extends StackedLayoutViewController {
  
  viewDefinition() {
    return Object.assign(super.viewDefinition(), {
      className: 'evaluation-details-description'
    });
  }
  
  childControllers() {
    const children = [];

    if (this.getOption('description')) {
      children.push(
        TitledSectionControllerDefinition(I18n.t('general.description.name'), this._renderDescription())
      );
    }

    return children;
  }

  _renderDescription() {
    return {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: ReadMoreView,
        enablePreviewGradient: true,
        shouldShowMoreContent: this.getOption('isNotStarted')
      },
      regionControllers: {
        contentRegion: {
          viewDefinition: {
            ViewClass: Marionette.ItemView,
            template: this.getOption('description')
          }
        }
      }
    };
  }
}

module.exports = EvaluationDetailsDescriptionController;
