const File = require('@common/libs/file/File');

class TinCanFile extends File {

  static SIZE_LIMIT = 1000;

  preinitialize(options) {
    super.preinitialize(options);

    this.sizeLimit = TinCanFile.SIZE_LIMIT; // in MB
  }

  initialize(attrs, options) {
    super.initialize(attrs, options);

    this.fileType = 'tincan';
    this.allowedExtensions = ['zip'];
  }
}

module.exports = TinCanFile;
