const I18n = require('@common/libs/I18n');
const Backbone = require('Backbone');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const RecoveryBaseView = require('@common/modules/auth/views/recovery/RecoveryBaseView');
const PasswordRecoveryViewFactory = require('@common/modules/auth/views/recovery/password/PasswordRecoveryViewFactory');
const AccountRecoveryService = require('@common/modules/auth/views/recovery/AccountRecoveryService');
const ResetPasswordService = require('@common/modules/auth/views/reset/ResetPasswordService');


class RecoverPasswordController extends LayoutController {
  viewDefinition() {
    return {
      ViewClass: RecoveryBaseView,
      tenantPropertyPool: this.tenantPropertyPool,
      navChannel: this.navChannel,
      languageStateModel: this._languageStateModel
    };
  }

  regionControllers() {
    return {
      recoveryRegion: this._getRecoveryRegionController()
    };
  }

  initialize(options = {}) {
    this.viewDefinition = this.viewDefinition.bind(this);

    ({
      navChannel: this.navChannel,
      tenantPropertyPool: this.tenantPropertyPool,
      token: this.token,
      flash: this.flash
    } = options);

    this._languageStateModel = new Backbone.Model({
      hasLanguageSelector: true
    });
    this._flowStateModel = new Backbone.Model({
      recoveryOptions: []
    });

    this.viewConfigOptions = this.getViewConfigOptions();

    this.accountRecoveryService = new AccountRecoveryService();
  }

  getViewConfigOptions() {
    return {
      INITIAL: {
        onViewSubmit: this.onViewUsernameSubmit.bind(this),
        beforeLoad: null
      },
      EMAIL: {
        onViewSubmit: null,
        beforeLoad: this.sendEmailToUser
      },
      RECOVERY_QUESTIONS: {
        onViewSubmit: this.submitAnswer.bind(this),
        beforeLoad: this.getSecurityQuestion
      },
      QUESTION_ERROR: {
        onViewSubmit: null,
        beforeLoad: null
      },
      CHANGE_PASSWORD: {
        viewOptions: {
          tenantPropertyPool: this.tenantPropertyPool,
          flash: this.flash,
          userName: this._flowStateModel.get('username')
        },
        onViewSubmit: this.submitPasswordChange.bind(this),
        beforeLoad: null
      },
      CHANGE_PASSWORD_SUCCESS: {
        onViewSubmit: null,
        beforeLoad: null
      }
    };
  }

  onViewChangeLanguage(controller, view, formEditor) {
    const lang = formEditor.getValue().id;
    I18n.setLocale(lang, {
      always: () => {
        if (this.isDestroyed) {
          return;
        }
        this._resetView();
      }
    });
  }

  _resetView() {
    this.getView().render();
    this.replaceRegionController('recoveryRegion', this._getRecoveryRegionController());
    this.showRegionController('recoveryRegion');
  }

  _getRecoveryRegionController() {
    const nextPage = this._flowStateModel.get('nextFlowState') || 'INITIAL';
    const configOptions = this.viewConfigOptions[nextPage];
    const eventsObj = {
      'view:error': this.onViewError.bind(this),
      'view:flow:next': this.onFlowNext.bind(this)
    };

    if (configOptions.onViewSubmit != null) {
      eventsObj['view:submit'] = configOptions.onViewSubmit;
    }

    let viewOptionsObj = {
      flowStateModel: this._flowStateModel
    };

    if (configOptions.viewOptions != null) {
      viewOptionsObj = $.extend(viewOptionsObj, configOptions.viewOptions);
    }

    return PasswordRecoveryViewFactory.getViewControllerFor(nextPage, viewOptionsObj, eventsObj);
  }

  onViewUsernameSubmit() {
    this.submitUsername().then((response) => {
      this._flowStateModel.set({
        recoveryOptions: response.options
      });
    });
  }

  submitUsername() {
    const username = this._flowStateModel.get('username');
    const recaptcha = this._flowStateModel.get('recaptcha');
    return this.accountRecoveryService.getRecoveryOptions(username, recaptcha)
      .fail(() => {
        this.flash.error({
          message: I18n.t('selfRegistration.errors.captchaInvalid')
        });
      });
  }

  onFlowNext() {
    let deferred;
    const nextPage = this._flowStateModel.get('nextFlowState');

    const configOptions = this.viewConfigOptions[nextPage];
    if (configOptions.beforeLoad) {
      deferred = configOptions.beforeLoad.apply(this);
    } else {
      deferred = $.Deferred().resolve();
    }

    deferred.then(() => {
      this._resetView();
      this._languageStateModel.set('hasLanguageSelector', (nextPage == null || nextPage === ''));
    });
  }

  onViewError(controller, view, message, doNotReturn) {
    this.flash.error({
      message: message
    });
    if (!doNotReturn) {
      this._returnToLogin();
    }
  }

  sendEmailToUser() {
    const username = this._flowStateModel.get('username');
    return this.accountRecoveryService.emailRecoveryByUsername(username);
  }

  getSecurityQuestion() {
    if (this._flowStateModel.get('securityQuestionToken') == null) {
      return this._fetchSecurityQuestionToken().then(() => {
        return this._fetchCurrentSecurityQuestion();
      });
    }
    return this._fetchCurrentSecurityQuestion();

  }

  _fetchSecurityQuestionToken() {
    const securityQuestionTokenDeferred = $.Deferred();

    const username = this._flowStateModel.get('username');
    this.accountRecoveryService.getSecurityQuestionToken(username).then((token) => {
      this._flowStateModel.set('securityQuestionToken', token);
      securityQuestionTokenDeferred.resolve();
    })
      .fail((isLockedOut) => {
        if (isLockedOut) {
          this._flowStateModel.set('nextFlowState', 'QUESTION_ERROR');
          this.onFlowNext();
        }
      });

    return securityQuestionTokenDeferred;
  }

  _fetchCurrentSecurityQuestion() {
    const securityQuestionDeferred = $.Deferred();
    const token = this._flowStateModel.get('securityQuestionToken');
    this.accountRecoveryService.geCurrentSecurityQuestion(token).then((questionText) => {
      this._flowStateModel.set('currentQuestion', questionText);
      securityQuestionDeferred.resolve();
    })
      .fail((isLockedOut) => {
        securityQuestionDeferred.reject();
        if (isLockedOut) {
          this._flowStateModel.set('nextFlowState', 'QUESTION_ERROR');
          this.onFlowNext();
        }
      });

    return securityQuestionDeferred;
  }

  submitAnswer(controller, view, answerHash) {
    const token = this._flowStateModel.get('securityQuestionToken');
    this.accountRecoveryService.answerCurrentSecurityQuestion(token, answerHash).then((answeredCorrectly) => {
      if (answeredCorrectly === true) {
        this._flowStateModel.set('nextFlowState', 'CHANGE_PASSWORD');
      } else {
        this.flash.error({
          message: I18n.t('login.forgotPassword.securityQuestions.incorrectAnswer')
        });
        this._flowStateModel.set('nextFlowState', 'RECOVERY_QUESTIONS');
      }
      this.onFlowNext();
    })
      .fail((isTokenExpired) => {
        if (isTokenExpired) {
          this.flash.error({
            message: I18n.t('login.forgotPassword.securityQuestions.invalidToken')
          });
          this._returnToLogin();
        }
      });
  }


  submitPasswordChange(controller, view, password) {
    const token = this._flowStateModel.get('securityQuestionToken');
    this.resetPasswordService = new ResetPasswordService();
    const resetPromise = this.resetPasswordService.changePasswordWithQuestionToken(password, token);
    resetPromise.then(() => {
      this.flash.success({
        message: I18n.t('login.passwordReset.reset.success')
      });
      this._returnToLogin();
    }, (error) => {
      switch (error) {
        case ResetPasswordService.PasswordError.INVALIDTOKEN:
        case ResetPasswordService.PasswordError.NOUSER:
          this.flash.error({
            message: I18n.t('login.passwordReset.reset.failure')
          });
          this._returnToLogin();
          break;
        case ResetPasswordService.PasswordError.INVALIDPASSWORD:
          this.flash.error({
            message: I18n.t('login.passwordReset.reset.failure')
          });
          break;
        case ResetPasswordService.PasswordError.PASSWORDRECYCLED:
          this.flash.error({
            message: I18n.t('users.password.error.recycled')
          });
          break;
        default:
          break;
      }
    });
  }

  _returnToLogin() {
    location.hash = '';
    this.navChannel.commands.execute('show:login', { loginPrompt: false });
  }
}

module.exports = RecoverPasswordController;
