const Backbone = require('Backbone');
const QuestionOptionList = require('@common/data/collections/questions/QuestionOptionList');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const TranslatableString = require('@common/data/models/translationStrings/TranslatableString');

class QuestionVariant extends Backbone.Model {
  constructor() {
    super(...arguments);
    this.toJSON = this.toJSON.bind(this);
    this.getFirstTranslationLanguageByValue = this.getFirstTranslationLanguageByValue.bind(this);
  }

  get className() {
    return 'QuestionVariant';
  }

  apiEndpoint() {
    return '/question_variants';
  }

  validators() {
    return {
      questionText(value) {
        if ( !($.trim(value).length > 0) ) {
          return I18n.t('flash.questionnotprovided');
        }
      },
      optionText(value) {
        if ( !($.trim(value).length > 0) ) {
          return I18n.t('flash.answernotprovided');
        }
      }
    };
  }

  defaults() {
    return {
      variantType: 0
    };
  }

  initialize() {
    if (!this.get('questionTextTranslation')) {
      this.set('questionTextTranslation', new TranslatableString());
    }
    if (!this.get('reasonTextTranslation')) {
      this.set('reasonTextTranslation', new TranslatableString());
    }
  }

  parse(res) {
    const json = (res && res.entity) || res || {};
    if (json.options) {
      if (this.options == null) {
        this.options = new QuestionOptionList();
      }
      this.options.reset(json.options, { parse: true });
    }
    return json;
  }

  toJSON() {
    if (this.options) {
      return Object.assign( {}, this.attributes, { options: this.options.toJSON() } );
    }
    return this.attributes;

  }

  getFirstTranslationLanguageByValue(orderedLanguageListByPriority = []) {
    const languageToSelect = _.find(orderedLanguageListByPriority, (langCode) => {
      return this.get('questionTextTranslation').getValueForLanguage(langCode);
    });

    return languageToSelect || orderedLanguageListByPriority[0];
  }

  getAvailableTranslationLangCodes() {
    return _.keys( this.get('translatableString').get('values') );
  }

  deepClone() {
    const clone = this.clone();
    clone.unset('id');
    clone.options = new QuestionOptionList();

    this.options.each((option) => {
      const n = option.clone();
      n.unset('id');
      clone.options.add(n);
    });
    return clone;
  }
}

module.exports = QuestionVariant;
