import {
  SwipeableDrawer as MuiSwipeableDrawer,
  styled,
  type SwipeableDrawerProps as MuiSwipeableDrawerProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

export type AxSwipeableDrawerProps = Omit<MuiSwipeableDrawerProps, 'elevation'>;

const styledOptions = {
  name: 'AxSwipeableDrawer'
};

const StyledAxSwipeableDrawer = styled(MuiSwipeableDrawer, styledOptions)<AxSwipeableDrawerProps>(() => {
  return {};
});

/**
 * The `AxSwipeableDrawer` component is a wrapper around the MUI `SwipeableDrawer` component.
 *
 * ### Links
 * MUI: [SwipeableDrawer](https://mui.com/material-ui/react-drawer/#swipeable)
 */
export const AxSwipeableDrawer = forwardRef(({
  ...otherProps
}: AxSwipeableDrawerProps, ref: Ref<HTMLDivElement>) => {
  const SwipeableDrawerProps: AxSwipeableDrawerProps = {
    ...otherProps
  };

  return (
    <StyledAxSwipeableDrawer { ...SwipeableDrawerProps } ref={ ref } />
  );
});

export default AxSwipeableDrawer;
