const PageEditViewLayout = require('@training/apps/articles/PageEditViewLayout');
const AxBreakpointEnum = require('@common/data/enums/AxBreakpointEnum').default;

const MOBILE_MAX_WIDTH = AxBreakpointEnum.MOBILE_MAX;

class QuestionPageEditViewLayout extends PageEditViewLayout {
  constructor(options = {}) {
    super(options);

    ({
      model: this.model,
      contentView: this.contentView,
      metadataController: this.metadataController
    } = options);
  }

  onRender() {
    this.contentRegion.show(this.contentView);
    this.metadataController.show(this.metadataRegion);
  }

  behaviors() {
    return {
      Scrollable: {
        scrollableContainer: '#metadata'
      }
    };
  }

  onResize(winHeight, winWidth) {
    const titleHeight = this.ui.titleContainer.outerHeight();
    const containerHeight = this.$el.height();
    const buttonBarHeight = this.ui.actionBar.outerHeight();
    const contentHeight = containerHeight - titleHeight - buttonBarHeight;

    this.ui.contentRow.css('height', `calc(100% - ${ titleHeight }px - ${ buttonBarHeight }px)`);

    // Setup new editor heights
    const $editor = $('#squire_editor');
    const toolbarHeight = $('#menu-container').outerHeight();
    const editorMargins = parseInt($editor.css('padding-top'), 10) + parseInt($editor.css('padding-bottom'), 10);
    const newEditorHeight = contentHeight - toolbarHeight - editorMargins;
    $editor.height(newEditorHeight);

    // Setup new editor heights
    const $froalaViewport = $('.fr-view');
    const froalaToolbarHeight = $('.fr-toolbar').outerHeight();
    const viewportMargins = parseInt($froalaViewport.css('padding-top'), 10) + parseInt($froalaViewport.css('padding-bottom'), 10);
    const newHeight = contentHeight - froalaToolbarHeight - viewportMargins;
    $editor.height(newHeight);

    this.metaDataContainerWidth = this.ui.metadata.outerWidth();
    const editorParentWidth = this.ui.editorPage.width();
    this.containerWidth = editorParentWidth - this.metaDataContainerWidth;

    // At the MAX_WIDTH value the metadata becomes permanently visible
    // so we clear out any transforms and make sure the two side-by-side
    // elements fit properly.
    if (winWidth > MOBILE_MAX_WIDTH) {
      this.metadataOpen = true;
      if (!this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', true);
      }
      this.metadataAlwaysOpen = true;
      this.ui.content.css({width: `${ this.containerWidth }px`});
      this.ui.metadata.css({
        [this._cssDirection]: `${ this.containerWidth }px`,
        transform: ''
      });
    } else {
      this.metadataOpen = false;
      if (this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', false);
      }
      this.metadataAlwaysOpen = false;
      this.ui.content.css({width: ''});
      this.ui.metadata.css({[this._cssDirection]: ''});
    }

    this.containerWidth = this.$el.width();
  }
}

module.exports = QuestionPageEditViewLayout;
