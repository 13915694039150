const Marionette = require('Marionette');
const _ = require('underscore');

const PersistentObject = require('@common/libs/UI/PersistentObject');
const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');

require('@common/components/searchBox/SearchBox.less');

class SearchBox extends Marionette.ItemView.extend(PersistentObject) {
  className() {
    return 'search-box qa-search-box';
  }

  getTemplate() {
    return require('./SearchBoxTemplate.html');
  }

  ui() {
    return {
      spinner: '.search-icon',
      input: ':input'
    };
  }

  events() {
    return {
      'keyup @ui.input': 'onKeyUp',
      'click .search-box__close': 'onClear'
    };
  }

  initialize(options = {}) {
    const placeholderText = options.placeholderText != null ? options.placeholderText : I18n.t('general.search');
    this.templateHelpers = _.extend({}, this.templateHelpers, {
      placeholderText,
      inputSize: options.inputSize || 'l'
    });
    this.getSearchQuery = options.getSearchQuery || _.noop;
    this.clearSearch = options.clearSearch || _.noop;
    this.handleQueryChange = options.handleQueryChange || undefined;
    this.debounceWaitTime = options.debounceWaitTime || 300;
    this.debouncedTriggerQueryChange = _.debounce(this.triggerQueryChange, this.debounceWaitTime);
  }

  onKeyUp(e) {
    this.$el.toggleClass('search-box--active', this.ui.input.val().trim() !== '');
    if (e.which === KeyCode.ENTER) {
      e.preventDefault();
      if (this.ui.input.val().trim() === '') {
        this.clearSearch();
      } else {
        this.onSearch();
      }
    } else {
      this.debouncedTriggerQueryChange();
    }
  }

  triggerQueryChange() {
    if (this.handleQueryChange && this.ui.input) {
      const searchTerm = this.ui.input.val().trim();
      this.handleQueryChange(searchTerm);
    }
  }

  onClear() {
    this.$el.removeClass('search-box--active');
    this.ui.input.val('');
    this.ui.input.trigger('focus');
    this.clearSearch();
  }

  onSearch() {
    const searchTerm = this.ui.input.val().trim();
    this.getSearchQuery(searchTerm);
  }
}

module.exports = SearchBox;
