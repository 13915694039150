const { LayoutView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const SectionControllerDefinition = (title, contentsControllerDefinition) => {
  if (!contentsControllerDefinition) {
    return null;
  }

  return () => {
    return {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        className: 'titled-section__region',
        ViewClass: LayoutView,
        template: `<div class="titled-section__region--title"><h2>${ title }</h2></div>
<div class="titled-section__region--content"></div>`,
        regions: {
          contentRegion: '.titled-section__region--content'
        }
      },
      regionControllers: {
        contentRegion: contentsControllerDefinition
      }
    };
  };
};

module.exports = SectionControllerDefinition;
