// Module
var code = `<div class="news-icon icon-<%- iconType %> white" role="img" aria-label="<%- iconLabel %>"></div>

<div class="story">

  <div id="<%- contentID %>" class="content" aria-hidden="<%- isMobile %>"><%= contentString %></div>

  <span class="timesince" aria-hidden="<%- isMobile %>"><%- timeSince %></span>

  <div class="likes">
    <button class="button-reset-style js-like-toggle" aria-label="<%= contentAriaLabel %>"></button>

    <span class="liketext"><%- likeText %></span>
  </div>
</div>
`;
// Exports
module.exports = code;