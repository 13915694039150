const $ = require('jquery');
const _ = require('underscore');
const logging = require('logging');
const Promise = require('bluebird');

// This class is responsible for injecting packages of CSS given their keyed packages and then
// allows them to be unloaded based on those keys, too. This allows for dynamically loading entire sections
// of the application and their related stylesheets with ease.
class CSSInjector {

  static NullPackage = {
    key: 'NULL',
    paths: []
  }

  constructor() {
    this._activePackages = {};
    this._pathToLinkMap = {};
    this._packageOrder = [];
  }

  inject(cssPackage = CSSInjector.NullPackage, pivot) {
    let {
      key,
      paths = []
    } = cssPackage;

    paths = _.compact(paths);

    const oldPackage = this._getPackageFromCache(key);

    const addedPaths = _.difference(paths, oldPackage.paths);
    const removedPaths = _.difference(oldPackage.paths, paths);
    const duplicatePaths = _.intersection(paths, oldPackage.paths);

    const oldElementMap = this._getCachedLinksMap(removedPaths);
    const duplicateElementMap = this._getCachedLinksMap(duplicatePaths);
    const existingPivots = this._getNearestExistingPivots(paths, duplicateElementMap);

    const $explicitPivot = this._getPivotElement(pivot);
    const $packageOrderPivot = this._getPackagePivotLink(key);

    this._addPackageToCache(cssPackage);

    const promises = addedPaths.map( (path) => {
      const $insertionPivot = $explicitPivot || existingPivots[path] || $packageOrderPivot;
      return this._injectCssPath(path, $insertionPivot);
    });

    // Don't fire until everything is loaded
    return Promise.all(promises).then(() => {
      this.remove(oldElementMap);
    })
      .catch(() => {
        logging.warn('The stylesheets for the application could not be loaded. This is potentially a fatal error.');
      });
  }

  eject(key) {
    const oldElementMap = this._getCachedLinksMap( this._getPackageFromCache(key) );
    this.remove(oldElementMap);
    delete this._activePackages[key];
    this._packageOrder = _.without(this._packageOrder, key);
  }

  remove(oldElementMap) {
    for (const path in oldElementMap) {
      const $link = oldElementMap[path];
      $link.remove();
      delete this._pathToLinkMap[path];
    }
  }

  _getPackageFromCache(key) {
    return this._activePackages[key] || CSSInjector.NullPackage;
  }

  _addPackageToCache(cssPackage) {
    const { key } = cssPackage;

    this._activePackages[key] = cssPackage;
    this._packageOrder = _.uniq(this._packageOrder.concat(key));
  }

  // XXX: This is the only true way to detect for some of those browsers that do this poorly... ;/
  _beginPollForStylesheet($link, resolve) {
    const rawLink = $link.get(0);

    for (let i = 0; i < document.styleSheets.length; i++) {
      const sheet = document.styleSheets[i];

      if (sheet.ownerNode === rawLink) {
        return resolve($link);
      }
    }

    // If we made it this far, we're going to have to poll again :(
    logging.info('There was no stylesheet in the list despite it should have been loaded -- falling back to a long poll');

    setTimeout(() => {
      this._beginPollForStylesheet($link, resolve);
    }, 25);
  }

  _injectCssPath(path, $insertionPivot = $()) {
    if ($insertionPivot.length > 1) {
      throw new Error('You have specified an insertion point for the injection point but it was ambigious as to where to place it. Please give a more specific selector.');
    }

    return new Promise((resolve, reject) => {
      var $newCssLink = $('<link>')
        .attr({type: 'text/css', rel: 'stylesheet'})
        .attr('href', path)
        .attr('data-path', path)
        .on('load', () => {
          this._beginPollForStylesheet($newCssLink, resolve);
        })
        .on('error', () => {
          return reject(new Error(`Failed to load CSS: ${path}`));
        });

      this._pathToLinkMap[path] = $newCssLink;

      if ($insertionPivot.length !== 0) {
        $newCssLink.insertBefore($insertionPivot);
      } else {
        $('head').append($newCssLink);
      }
    });
  }

  _getCachedLinksMap(paths = []) {
    return paths.reduce((memo, path) => {
      const $link = this._getLinkEl(path);

      if ($link != null) {
        memo[path] = $link;
      }

      return memo;
    }, {});
  }

  // Gets the cached $link for a path, falling back to look for one already present in the DOM starting with given path.
  _getLinkEl(path) {
    const $link = this._pathToLinkMap[path] || $(`link[href^='${ path }']`);

    if ($link.length > 0) {
      return $link;
    }

    return undefined;
  }

  // finds the closest pivot link from the set of available pivots.
  _getNearestExistingPivots(paths = [], availablePivots = {}) {
    let currentPivot;

    return [...paths].reverse().reduce((memo, path) => {
      if (availablePivots[path]) {
        currentPivot = availablePivots[path];
      }

      memo[path] = currentPivot;
      return memo;
    }, {});
  }

  _getPivotElement(pivot) {
    if (pivot != null) {
      return $(pivot);
    }

    return undefined;
  }

  // Finds the path after the given one and returns the pivot element for it.
  // If an existing once couldn't be found
  _getInjectedPathsPivotLink(path = '', injectedPaths = {}) {
    const index = injectedPaths.indexOf(path);
    let $link;

    if (index >= 0 && index < injectedPaths.length) {
      $link = this._getLinkEl(injectedPaths[index + 1]);
    } else {
      $link = this._getPivotElement(`link[href*='${ path }']`);
    }

    if ($link && $link.length > 0) {
      return $link;
    }

    return undefined;
  }

  // Finds the package injected after the given key and returns the first link as a pivot element.
  _getPackagePivotLink(key) {
    const index = this._packageOrder.indexOf(key);

    if (index >= 0 && index < this._packageOrder.length) {
      const nextPackageKey = this._packageOrder[index + 1];
      const pivotPackage = this._activePackages[nextPackageKey];

      if (pivotPackage) {
        const firstPath = pivotPackage.paths[0];
        return this._pathToLinkMap[firstPath];
      }
    }

    return undefined;
  }
}

module.exports = CSSInjector;
