class KeyboardNavigationScrollHelper {
  /**
   * adjustDropdownScrollTop is called whenever we need to check if we should adjust
   *  the dropdown to keep the highlighted item visible in the dropdown.
   *
   * @param {jQuery} $dropdownMenu
   * @param {jQuery} $menuItem
   */
  adjustDropdownScrollTop($dropdownMenu, $menuItem) {
    const scrollDirection = this._determineScrollDirection($dropdownMenu, $menuItem);

    let scrollTop = $dropdownMenu.scrollTop();

    switch (scrollDirection) {
      case -1:
        if ($menuItem.prev() && $menuItem.prev().length) {
          scrollTop = scrollTop - $menuItem.height() - $menuItem.prev().height();
        } else {
          scrollTop = 0;
        }
        break;
      case 1:
        scrollTop = scrollTop + $menuItem.height() + ($menuItem.next().height() || 0);
        break;
      default:
    }

    $dropdownMenu.scrollTop(scrollTop);
  }

  /**
   * _determineScrollDirection attempts to work out whether the currently highlighted
   *  item is at or past the visible area of the dropdown.
   *
   * @private
   * @param {jQuery} $dropdownMenu
   * @param {jQuery} $menuItem
   * @return {number} -1 means we need to scroll up;
   *                   0 means we don't need to scroll;
   *                   1 means we need to scroll down;
   */
  _determineScrollDirection($dropdownMenu, $menuItem) {
    // early exits tend to happen just before the dropdown is fully rendered.
    if (!$menuItem.offset() || !$dropdownMenu.offset()) {
      return 0;
    }

    const itemTopOffset = $menuItem.offset().top;
    const itemHeight = $menuItem.height();
    const dropdownTopOffset = $dropdownMenu.offset().top;
    const dropdownHeight = $dropdownMenu.height();

    let direction = 0;

    if (itemTopOffset - dropdownTopOffset < 0) {
      direction = -1;
    }

    if ((itemTopOffset + itemHeight - dropdownTopOffset) >= dropdownHeight) {
      direction = 1;
    }

    return direction;
  }

}

module.exports = KeyboardNavigationScrollHelper;
