const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

class ConfirmDialogView extends Marionette.LayoutView {
  initialize(options = {}) {
    ({
      confirmCallback: this.confirmCallback = () => {},
      model: this.model,
      title: this.title = '',
      confirmationText: this.confirmationText = '',
      buttonText: this.buttonText = '',
      buttonColorClassName: this.buttonColorClassName = 'red',
      iconClass: this.iconClass = '',
      buttonConfig: this.buttonConfig = [],
      extraContentView: this.extraContentView
    } = options);
  }

  className() {
    return 'confirm-dialog parent-height';
  }

  regions() {
    return {
      extraContentRegion: '.js-extra-content-region'
    };
  }

  onShow() {
    // because, this is optional and might not be defined
    if (this.extraContentView) {
      this.extraContentRegion.show(this.extraContentView);
    }
  }

  getTemplate() {
    return `
      <div class="<%- iconClass %> confirm-dialog__icon" aria-hidden="true" role="presentation"></div>
      <h1 class="confirm-dialog__title ax-font--no-margin"><%- title %></h1>
      <div class="confirm-dialog__phrase ax-font--secondary">
        <%= confirmationText %>
      </div>
      <div class="js-extra-content-region"></div>
    `;
  }

  templateHelpers() {
    return {
      title: this.title,
      confirmationText: this.confirmationText,
      iconClass: this.iconClass
    };
  }

  events() {
    return {
      'click .icon-remove': this.destroy.bind(this)
    };
  }

  onRender() {
    // This view is commonly used in a confirm-delete scenario so we allow the default buttons to be delete/cancel
    const buttons = this.buttonConfig.length > 0 ? this.buttonConfig : this._getDefaultConfirmButtonConfig();
    this.actionBar.setActionBar({buttons});
  }

  onConfirm() {
    this.confirmCallback();
    this.destroy();
  }

  setButtons(buttonConfig) {
    this.buttonConfig = buttonConfig;
  }

  _getDefaultConfirmButtonConfig() {
    return [
      {
        type: 'customText',
        text: I18n.t('general.cancel'),
        className: 'white',
        onClick: this.destroy.bind(this)
      },
      {
        type: 'customText',
        text: this.buttonText,
        className: this.buttonColorClassName,
        onClick: this.onConfirm.bind(this)
      }
    ];
  }
}

module.exports = ConfirmDialogView;
