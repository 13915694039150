const Backbone = require('Backbone');

class SelectOptionModel extends Backbone.Model {
  toOption() {
    return this.pick(['id', 'value', 'title', 'disabled', 'tagName']);
  }

  toOptionCheckbox(model = this) {
    return Object.assign({}, model.pick(['id', 'value', 'title', 'disabled', 'tagName']), {
      label: model.get('label') || model.get('value')
    });
  }
}

module.exports = SelectOptionModel;
