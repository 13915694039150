const _ = require('underscore');
const {
  LayoutView,
  CollectionView,
  ItemView
} = require('Marionette');

const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const AssessmentType = require('@common/data/enums/AssessmentType');
const LearningObjectiveCardActionType = require('@common/components/learningObjectives/LearningObjectiveCardActionType');
const ActionBarType = require('@common/components/actionBarButton/ActionBarType');
const ProgressCardView = require('@common/components/learningObjectives/ProgressCardView');
const LearningObjectiveProgressModel = require('@common/components/learningObjectives/LearningObjectiveProgressModel');

const AssessmentTypeIconFormatter = require('@training/apps/training/assessmentTitle/AssessmentTypeIconFormatter');

const {
  getSelectableAssessmentCardDefinition,
  getActionType
} = require('@training/apps/training/views/assessments/SelectableAssessmentCardDefinitionFactory');

class SelectAssessmentEmptyView extends ItemView {
  getTemplate() {
    return `<h2 class="notopics"><%-t('assessments.select.notopics') %></h2>`;
  }
}
class SelectAssessmentPage extends LayoutView {
  initialize(options = {}) {
    ({
      trainingType: this.trainingType = AssessmentType.IntroductoryTraining,
      actionButtonType: this.actionButtonType = ActionBarType.DONE,
      complete: this.complete = $.noop,
      onCloseCallback: this.onCloseCallback = $.noop,
      canContinue: this.canContinue = false
    } = options);

    this.finishedCount = this.collection.getFinishedCount();
    this.totalCount = Math.max(this.collection.getOriginalLength(), this.finishedCount, 1);

    this.listenTo(this.collection, 'sync change:finishCount', this.onCollectionChanged);
    this._wasClicked = false;

    this._getChildViewOptions = this._getChildViewOptions.bind(this);
  }

  className() {
    return 'select-assessment-page';
  }

  getTemplate() {
    return require('@training/apps/training/templates/assessments/SelectAssessmentPage.html');
  }

  templateHelpers() {
    return {
      trainingType: this.trainingType,
      instructionString: this._getInstructionString()
    };
  }

  ui() {
    return {
      assessmentIcon: '.js-icon',
      topicSearch: '.topic-search',
      noTopics: '.notopics',
      numberOfResults: '.js-number-of-results',
      searchInput: '[type=search]',
      instruction: '.js-instruction'
    };
  }

  regions() {
    return {
      progressContainer: '.progress-container',
      topicsContainer: '.js-topics-container'
    };
  }

  onRender() {
    this._renderTitle();
    this._renderOptionFilter();
    this._renderTopics();
    this._renderDone();
    this._renderProgress();

    this._attemptToHideInstruction();
  }

  _renderOptionFilter() {
    const options = {
      el: this.ui.topicSearch,
      minLength: 1
    };

    this.searchBox = new UIKit.Autocomplete(options);
    this.listenTo(this.searchBox, 'autocomplete search', this.onSearch);
    this.listenTo(this.searchBox, 'clearSearch', this.onClearSearch);
  }

  _renderTitle() {
    window.app.layout.setTitle(I18n.t(`assessments.select.header.title.${ this.trainingType }`));
    this.ui.assessmentIcon.addClass(AssessmentTypeIconFormatter(this.trainingType).iconClass);
  }

  _renderProgress() {
    const model = new LearningObjectiveProgressModel({
      finishedCount: this.finishedCount,
      availableCount: this.totalCount,
      percent: this._getProgressPercentage(),
      animate: true
    });

    this._progressCard = new ProgressCardView({
      model: model,
      shouldUsePercentage: false
    });

    this.progressContainer.show(this._progressCard);
  }

  _renderTopics() {
    const viewControllerFactory = new ViewControllerFactory();
    const options = {
      collection: this.collection,
      viewDefinition: {
        ViewClass: CollectionView,
        tagName: 'ul',
        attributes: {
          'aria-label': I18n.t('start.assessmentType.' + this.trainingType)
        },
        childViewOptions: this._getChildViewOptions,
        emptyView: SelectAssessmentEmptyView
      },
      ChildViewControllerDefinition: (model) => {
        return getSelectableAssessmentCardDefinition(model);
      }
    };

    const topicCollectionController = viewControllerFactory.create(CollectionLayoutViewController, options);
    topicCollectionController.show(this.topicsContainer);
  }

  _getProgressPercentage() {
    return Math.floor((this.finishedCount / this.totalCount) * 100);
  }

  _getChildViewOptions(model) {
    return {
      className: _.isFunction(model.getAssessmentType) && model.getAssessmentType() != null ? 'card' : '',
      tagName: 'li',
      attributes: {
        'aria-label': this._getChildViewAriaLabel(model)
      }
    };
  }

  _getChildViewAriaLabel(model) {
    const assessmentType = _.isFunction(model.getAssessmentType) ? model.getAssessmentType() : undefined;

    // No assessmentType if the search returns no results
    if (assessmentType == null) {
      return '';
    }

    const isFormalExam = assessmentType === AssessmentType.FormalExamTraining;
    const assessmentName = isFormalExam ? model.get('program').name : model.get('topic').name;
    const level = model.get('level');
    const isLocked = model.isLocked();
    const lastRelevantResult = model.get('lastRelevantResult');
    const passingGrade = model.get('passingGrade');
    const prereqTopic = model.get('prereqTopic');
    const lastScore = this._getAssessmentLastScore(lastRelevantResult);

    const requiredAttrs = {
      assessmentType: I18n.t(`assessments.types.${ assessmentType }`),
      assessmentName: I18n.t('assessments.item.title', {
        assessmentName,
        level
      })
    };

    if (lastRelevantResult == null && !isLocked) {
      return I18n.t('assessments.item.label.notCompleted', requiredAttrs);
    } else if (lastRelevantResult != null && !lastRelevantResult.passed) {
      return I18n.t('assessments.item.label.notPassed', Object.assign({
        score: lastScore,
        passingGrade: passingGrade != null
          ? passingGrade
          : TenantPropertyProvider.get().getProperty('introTrainingPassingGrade')
      }, requiredAttrs));
    } else if (lastRelevantResult != null) {
      return I18n.t('assessments.item.label.passed', Object.assign({
        score: lastScore
      }, requiredAttrs));
    } else if (isLocked && prereqTopic != null) {
      return I18n.t('assessments.item.label.locked', Object.assign({
        prereqTopic: prereqTopic.name
      }, requiredAttrs));
    }

    return I18n.t('assessments.item.label.cannotBegin', requiredAttrs);
  }

  _getAssessmentLastScore(lastRelevantResult) {
    let lastScore;

    if (lastRelevantResult != null && lastRelevantResult.score != null) {
      lastScore = lastRelevantResult.score;
    } else if (lastRelevantResult != null) {
      lastScore = Math.round((lastRelevantResult.correctAnswerCount / Math.max(1, lastRelevantResult.totalAnswerCount)) * 100);
    }

    return lastScore;
  }

  _getInstructionString() {
    const strPath = 'assessments.select.instructions.';
    const context = this.collection.length > 1 ? 'plural.' : 'singular.';
    return I18n.t(strPath + context + this.trainingType);
  }

  _renderDone() {
    if ((this.canContinue)) {
      this.actionBar.setActionButton({
        type: this.actionButtonType,
        onClick: () => {
          if (this._wasClicked === false) {
            this.complete();
          }
          this._wasClicked = true;
        }
      });
    }
  }

  _attemptToHideInstruction() {
    // We want to hide the "assessments available to you" prompt when there is nothing startable, so we filter for NONE
    // action types (NONE = can't start)
    const actions = this.collection.filter((assessment) => {
      const attemptsRemaining = this.trainingType === AssessmentType.FormalExamTraining ? assessment.get('attemptsRemaining') : undefined;
      const inProgressAssessmentGetter = assessment.get('inProgressAssessmentGetter');
      const inProgressAssessment = _.isFunction(inProgressAssessmentGetter) ? inProgressAssessmentGetter() : undefined;
      const lastRelevantResult = assessment.get('lastRelevantResult');

      const actionType = getActionType({
        assessmentType: this.trainingType,
        isLocked: assessment.isLocked(),
        attemptsRemaining,
        inProgressAssessment,
        lastRelevantResult
      });

      return actionType === LearningObjectiveCardActionType.NONE;
    });

    // If the number of NONE actions founds is the same length as the entire assessment collection, then all are NONE,
    // nothing to start, hide the prompt
    if (actions.length === this.collection.length) {
      this.ui.instruction.hide();
    }
  }

  onCollectionChanged() {
    this._renderProgress();
    this._renderDone();
  }

  onSearch(query) {
    this.collection.search(query);

    if (query != null) {
      this.ui.numberOfResults.text(I18n.t('search.numberOfResultsFor', {
        number: this.collection.length,
        query
      }));

      this.ui.searchInput.attr('aria-labelledby', 'number-of-results');
    }
  }

  onClearSearch() {
    this.ui.numberOfResults.text('');
    this.ui.searchInput.removeAttr('aria-labelledby')
      .trigger('focus');
    this.collection.clearSearch();
  }

  onDestroy() {
    this.actionBar.setActionButton();
    this.onCloseCallback();
  }
}

module.exports = SelectAssessmentPage;
