module.exports = {
  API_VERSIONS: {
    SCORM_1_2: 'API',
    SCORM_2004: 'API_1484_11'
  },

  MANIFEST_VERSIONS: {
    SCORM_1_2: '1.2',
    SCORM_2004_ORIGINAL: 'CAM 1.3',
    SCORM_2004_3_EDITION: '2004 3rd Edition',
    SCORM_2004_4_EDITION: '2004 4th Edition'
  }
};
