import type TimeLogController from '@common/components/time_logs/TimeLogController';
import type AuthUser from '@common/data/models/AuthUser';
import createContextHelper from '@common/libs/helpers/app/createContextHelper';
import { type AxDialogRendererProps } from '@common/modules/react/themes/components';
import type Tenant from '@common/data/models/Tenant';
import type TenantPropertyProvider from '@common/services/TenantPropertyProvider';

export interface AppContextState {
  defaultDialogProps?: NonNullable<AxDialogRendererProps['defaultDialogProps']>
  timeLogController?: TimeLogController
  authUser: AuthUser
  tenant: Tenant
  tenantProperties: ReturnType<typeof TenantPropertyProvider.get>
}

export const {
  Provider: AppContextProvider,
  useStore: useAppContext
} = createContextHelper<AppContextState>();

