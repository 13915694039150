const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

const {
  TOPIC_CERTIFICATION,
  TOPIC_EXTRA_TRAINING,
  TOPIC_REFRESHER,
  TOPIC_INTRO,
  PATH_ENROLLED,
  PATH_ASSIGNED
} = require('@common/components/trainingCards/enums/TrainingCardTypes');

const TAG_STRING_KEYS_BY_TYPE = {
  [TOPIC_CERTIFICATION]: 'training.cards.tags.certification',
  [TOPIC_EXTRA_TRAINING]: 'training.cards.tags.topic',
  [TOPIC_REFRESHER]: 'training.cards.tags.topic',
  [TOPIC_INTRO]: 'training.cards.tags.topic',
  [PATH_ASSIGNED]: 'training.cards.tags.path',
  [PATH_ENROLLED]: 'training.cards.tags.path'
};

const CSS_CLASS_BY_TYPE = {
  [TOPIC_CERTIFICATION]: 'ax-tag--certification',
  [TOPIC_EXTRA_TRAINING]: 'ax-tag--topic',
  [TOPIC_REFRESHER]: 'ax-tag--topic',
  [TOPIC_INTRO]: 'ax-tag--topic',
  [PATH_ASSIGNED]: 'ax-tag--path',
  [PATH_ENROLLED]: 'ax-tag--path'
};

class TrainingCardTagView extends ItemView {
  getTemplate() {
    return `
      <p class="ax-tag <%- tagCssClass %>"><%- tagText %></p>
      <p class="ax-tag <%- statusCssClass %>"><%- statusText %></p>
    `;
  }

  className() {
    return 'training-card__tags';
  }

  templateHelpers() {
    const trainingCardType = this.getOption('trainingCardType');
    const tagTextKey = TAG_STRING_KEYS_BY_TYPE[trainingCardType];
    const tagText = tagTextKey != null ? I18n.t(tagTextKey) : '';
    const tagCssClass = CSS_CLASS_BY_TYPE[trainingCardType] || 'hidden';

    let statusCssClass = 'hidden';
    let statusText = '';
    if (this.getOption('hasReassignedItems')) {
      statusCssClass = 'ax-tag--assigned';
      statusText = I18n.t('training.cards.tags.reassigned');
    } else if (this.getOption('isAssigned')) {
      statusCssClass = 'ax-tag--assigned';
      statusText = I18n.t('training.cards.tags.assigned');
    } else if (this.getOption('isEnrolled')) {
      statusCssClass = 'ax-tag--enrolled';
      statusText = I18n.t('training.cards.tags.enrolled');
    }

    return {
      tagText,
      tagCssClass,
      statusCssClass,
      statusText
    };
  }
}

module.exports = TrainingCardTagView;
