import {
  StepLabel as MuiStepLabel,
  type StepLabelProps as MuiStepLabelProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

export type AxStepLabelProps = MuiStepLabelProps;

const styledOptions = {
  name: 'AxStepLabel'
};

const StyledAxStepLabel = styled(MuiStepLabel, styledOptions)<AxStepLabelProps>(({theme}) => {
  return {
    '.MuiStepLabel-label': {
      color: theme.uiKit.colorGrey60,
      fontSize: theme.uiKit.fontSizeS,
      lineHeight: theme.uiKit.lineHeightS,
      '&.Mui-active': {
        color: theme.uiKit.colorGrey80
      },
      '&.Mui-error': {
        color: theme.uiKit.colorSemanticNegative
      },
      '&.Mui-disabled': {
        color: theme.uiKit.colorGrey40
      }
    },
    '.MuiStepLabel-iconContainer': {
      '&.Mui-error': {
        color: theme.uiKit.colorSemanticNegative
      },
      '&.Mui-disabled': {
        color: theme.uiKit.colorGrey40
      }
    }
  };
});

/**
 * A wrapper around MuiStepLabel that applies our custom styles.
 *
 * ### Links
 * - [MUI StepLabel API](https://mui.com/material-ui/api/step-label/)
 */
export const AxStepLabel = forwardRef(({
  ...otherProps
}: AxStepLabelProps, ref: Ref<HTMLDivElement>) => {

  return (
    <StyledAxStepLabel
      { ...otherProps }
      ref={ ref } />
  );
});

export default AxStepLabel;
