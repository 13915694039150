const _ = require('underscore');

const TrainingModuleType = require('@common/data/enums/TrainingModuleType');
const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');
const ExternalLinkPreviewView = require('@common/components/training_modules/external_link/ExternalLinkPreviewView');
const TinCanView = require('@common/components/training_modules/tincan/TinCanView');
const SCORMView = require('@common/components/training_modules/scorm/SCORMView');
const PDFContentView = require('@common/components/mediaFilePreview/views/PdfContentShowView');

// This class is responsible for creating the concrete implementations of a given training module, provided
// to be able to specify how to build something up...
class TrainingModuleViewFactory {
  constructor(options = {}) {
    ({
      trainingModule: this.trainingModule,
      previewingUser: this.previewingUser
    } = options);
  }

  createTrainingModulePreviewForLanguage(language, viewOptions = {}) {
    const deferred = $.Deferred();
    const type = this.trainingModule.get('moduleType');
    switch (type) {
      case TrainingModuleType.SCORM:
        this._getScormView(deferred, language, viewOptions);
        break;

      case TrainingModuleType.TINCAN:
        this._getTincanView(deferred, language);
        break;

      case TrainingModuleType.TRANSCODED_VIDEO: case TrainingModuleType.EXTERNAL_VIDEO:
        this._getVideoView(deferred, language, viewOptions);
        break;

      case TrainingModuleType.EXTERNAL_LINK:
        this._getExternalLinkView(deferred, language);
        break;

      case TrainingModuleType.PDF:
        this._getPDFView(deferred, language);
        break;
      default:
        deferred.reject();
    }

    return deferred.promise();
  }

  _getScormView(deferred, language, viewOptions) {
    const v = new SCORMView({
      model: this.trainingModule,
      language,
      previewingUser: this.previewingUser,
      ...viewOptions
    });

    return deferred.resolve(v);
  }

  _getTincanView(deferred, language) {
    const v = new TinCanView({
      model: this.trainingModule,
      language,
      previewingUser: this.previewingUser
    });

    return deferred.resolve(v);
  }

  _getVideoView(deferred, language, viewOptions) {
    const videoModule = this.trainingModule;
    const videoPackagesPromise = videoModule.getPackageLinks();
    return videoPackagesPromise.then((packageLinks) => {
      const pkg = _.find(packageLinks, (pkgObj) => {
        return pkgObj.language === language;
      });

      const videoPlayer = VideoPlayerFactory.createPlayerInstance({
        videoPackage: pkg,
        viewOptions: {
          userLanguage: language, // We can just use the video language in this case as the start language
          maxHeight: viewOptions.maxHeight || 420,
          deliveryMethod: videoModule.get('deliveryMethod'),
          moduleType: videoModule.get('moduleType')
        }
      });

      if (videoPlayer != null) {
        return deferred.resolve(videoPlayer);
      }
      return deferred.reject();

    }).fail(() => {
      return deferred.reject();
    });
  }

  _getExternalLinkView(deferred, language) {
    const v = new ExternalLinkPreviewView({
      model: this.trainingModule,
      language
    });

    return deferred.resolve(v);
  }

  _getPDFView(deferred, language) {
    const pkg = this.trainingModule.moduleLinks.getPackageForLanguage(language);

    if (pkg) {
      pkg.set('moduleType', TrainingModuleType.PDF);

      const v = new PDFContentView({
        model: pkg
      });

      return deferred.resolve(v);
    }

    return deferred.reject();
  }
}

module.exports = TrainingModuleViewFactory;
