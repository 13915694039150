const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');

const Tag = require('@common/components/discover/models/Tag');

class TagList extends InfiniteCollection {
  get model() {
    return Tag;
  }

  apiEndpoint() {
    return '/tags';
  }

  initialize() {
    this.fetchType = 'GET';
  }

  parseRecords(res) {
    return res.results;
  }
}
module.exports = TagList;
