const Marionette = require('Marionette');
const { INTERACTIVE } = require('@common/libs/behaviors/card/CardModifierClasses');

const _ = require('underscore');

class TrainingCardLayoutView extends Marionette.LayoutView {
  getTemplate() {
    return `
      <div class="js-top-region training-card__top-wrapper"></div>
      <div class="js-bottom-region training-card__bottom-wrapper ax-grid__col--auto-size"></div>
    `;
  }

  behaviors() {
    const cardOptions = [];
    const fullCardCallback = this.getOption('fullCardCallback');

    if (_.isFunction(fullCardCallback)) {
      cardOptions.push(INTERACTIVE);
    }

    return {
      Card: {
        modifierClasses: cardOptions
      }
    };
  }

  events() {
    const fullCardCallback = this.getOption('fullCardCallback');

    return {
      click: () => {
        if (_.isFunction(fullCardCallback)) {
          fullCardCallback();
        }
      }
    };
  }

  className() {
    return 'training-card ax-grid ax-grid--no-gutter qa-training-card';
  }

  regions() {
    return {
      topRegion: '.js-top-region',
      bottomRegion: '.js-bottom-region'
    };
  }
}

module.exports = TrainingCardLayoutView;
