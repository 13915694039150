const _ = require('underscore');
const Backbone = require('Backbone');

const LearningEvent = require('@training/apps/training/models/LearningEvent');

class LearningEventList extends Backbone.Collection {
  get model() {
    return LearningEvent;
  }

  apiEndpoint() {
    return '/learning-events/scheduled-events/all';
  }

  constructor(models, options = {}) {
    super(models, options);

    ({
      statusType: this.statusType,
      currentUserLanguage: this.currentUserLanguage,
      recordsPerPage: this.recordsPerPage = 10
    } = options);

    this.eventSearchValue = '';

    this.setDefaultParams();
  }

  fetchType() {
    return 'GET';
  }

  parse(resArg) {
    const res = resArg.results ?? resArg;

    _.each(res, (result) => {
      result.currentUserLanguage = this.currentUserLanguage;
    });

    return res;
  }

  _getSearchOptionData() {
    return {
      data: {
        search: this.eventSearchValue
      }
    };
  }

  fetch(optionsArg) {
    const options = $.extend(true, {}, optionsArg, this._getSearchOptionData(), {
      data: this.defaultParams,
      type: this.fetchType()
    });

    // NOTE: Keeping this in here in case future extending classes decide to override the default type with 'POST'
    if (options.type === 'POST') {
      options.data = JSON.stringify(options.data);
    }
    return super.fetch(options);
  }

  search(queryArg, dataOptions) {
    const query = queryArg?.trim() ?? '';
    this.eventSearchValue = query;
    return this.fetch(dataOptions);
  }

  setDefaultParams() {
    this.defaultParams = {
      rpp: this.recordsPerPage
    };
  }
}

class LearningEventUpcomingList extends LearningEventList {
  apiEndpoint() {
    return '/learning-events/scheduled-events/upcoming';
  }
}

class LearningEventFilteredList extends LearningEventList {
  constructor(models, options = {}) {
    super(models, options);

    ({
      eventId: this.eventId
    } = options);
  }

  apiEndpoint() {
    return `/learning-events/${ this.eventId }/scheduled-user-events`;
  }
}

class LearningEventScheduledList extends LearningEventList {
  apiEndpoint() {
    return '/learning-events/scheduled-events/scheduled';
  }
}

class LearningEventHistoryList extends LearningEventList {
  apiEndpoint() {
    return '/learning-events/scheduled-events/history';
  }
}

module.exports = {
  LearningEventUpcomingList,
  LearningEventScheduledList,
  LearningEventHistoryList,
  LearningEventFilteredList
};
