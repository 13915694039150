// Module
var code = `<%= axButton({
  label: t('communitiesManagement.filters.add'),
  iconClassLeft: 'icon-plus',
  className: 'js-new-button tag-group-filters-index__new-button',
  branded: true
}) %>
<div class="js-count tag-group-filters-index__count"></div>
<div class="js-tag-group-filters-list-region tag-group-filters-index__list-wrapper"></div>
`;
// Exports
module.exports = code;