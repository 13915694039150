const _ = require('underscore');
const { Collection } = require('Backbone');

const PageSharedCommunity = require('@common/components/discover/collections/PageSharedCommunity');

class PageSharedCommunitiesList extends Collection {
  initialize(models, options = {}) {
    ({
      pageId: this.pageId
    } = options);
  }

  apiEndpoint() {
    return `/pages/${ this.pageId }/communities`;
  }

  parse(data = {}) {
    const entities = data.entities || data || [];

    const homeCommunityData = _.findWhere(entities, { isHomeCommunity: true });
    if (homeCommunityData) {
      Object.assign(homeCommunityData, { pageId: this.pageId });
      this.homeCommunity = new PageSharedCommunity(homeCommunityData, { parse: true });
    }

    entities.forEach((entity) => {
      entity.pageId = this.pageId;
    });

    return _.where(entities, { isHomeCommunity: false });
  }

  get model() {
    return PageSharedCommunity;
  }

  comparator(pageShareA, pageShareB) {
    const nameA = pageShareA.community.getName().toLowerCase();
    const nameB = pageShareB.community.getName().toLowerCase();

    // When the names are alphabetically equivalent, compare the IDs
    if (nameA === nameB) {
      return pageShareA.community.get('id') < pageShareB.community.get('id') ? -1 : 1;
    }

    return nameA < nameB ? -1 : 1;
  }

  getHomeCommunity() {
    return this.homeCommunity;
  }
}

module.exports = PageSharedCommunitiesList;
