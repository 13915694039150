const logging = require('logging');

const {
  REMOTE_LOG_LEVELS,
  sendLog
} = require('LoggingService');

const DataFormatter = require('@common/prerequisites/libs/DataFormatter');

const ErrorDataValueMappers = require('@common/prerequisites/libs/error/ErrorDataValueMappers');
const ErrorDetailFormatters = require('@common/prerequisites/libs/error/formatters/ErrorDetailFormatters');

const errorDetailFormatOptions = {
  valueMappers: ErrorDataValueMappers,
  formatters: ErrorDetailFormatters,
  formatSequence: [
    'suppressed',
    'logMessage',
    'api',
    'error',
    'environment',
    'logs'
  ]
};

const ErrorPage = require('@common/prerequisites/libs/error/ErrorPage');
const UrlErrorBlacklist = require('@common/prerequisites/libs/error/UrlErrorBlacklist');
const WindowErrorValidator = require('@common/prerequisites/libs/error/WindowErrorValidator');

const getErrorLevel = (options = {}) => {
  if (!UrlErrorBlacklist.isValid(options.sourceURL)) {
    return REMOTE_LOG_LEVELS.WARN;
  }

  return options.level || REMOTE_LOG_LEVELS.ERROR;
};

const showErrorPage = (options = {}) => {
  // Show error page
  const errorPage = new ErrorPage(options);

  document.body.innerHTML = errorPage.render();
  document.body.style.background = '#FFF';
  document.getElementById('detailsToggle').onclick = errorPage.toggleDetails;
};

let errorCallback = () => {};

let errorHandled = false;

const ErrorHandler = {
  // Callback function so we can set Backbone.history.stop
  setOnErrorCallback(callback) {
    errorCallback = callback || errorCallback;
  },

  redirectOnNavigate() {
    if (window.addEventListener) {
      window.addEventListener('popstate', () => {
        return window.location.reload();
      }, false);
      history.pushState({}, '', window.location.toString());
    }
  },

  handleError(errorOptions = {}) {
    if (errorHandled) {
      return;
    }

    const {
      sendLog: shouldSendLog = true,

      api,
      error,

      showDetails,
      errorDetailsMessage = '',
      buttonText,
      showHeader,
      shouldShowErrorPage = true
    } = errorOptions;

    errorHandled = true;

    try {
      errorCallback();
    } catch (e) {
      logging.error(e);
    }

    // Want to assign listeners to redirect the page on browser navigation
    this.redirectOnNavigate();

    const data = {
      suppressed: false,
      error,
      api
    };

    // Log error
    if (shouldSendLog) {
      sendLog({
        level: getErrorLevel(errorOptions),
        logData: data
      });
    }

    const errorDetails = DataFormatter.format(data, errorDetailFormatOptions);
    if (shouldShowErrorPage) {
      showErrorPage({
        errorDetailsText: errorDetails,
        errorDetailsMessage,
        showDetails,
        buttonText,
        showHeader
      });
    }

    logging.error(errorDetailsMessage);
    console.log(errorDetails);
  },

  handleIgnoredError(errorOptions = {}, ignoreReason = '') {
    const {
      api,
      error
    } = errorOptions;

    const data = {
      suppressed: true,
      logMessage: ignoreReason,
      error,
      api
    };

    logging.info(ignoreReason);

    const errorDetails = DataFormatter.format(data, errorDetailFormatOptions);
    console.log(errorDetails);
  },

  onError(message, sourceURL, lineString, columnString, errorObject) {
    const line = parseInt(lineString, 10);
    const column = parseInt(columnString, 10);

    const error = {
      message,
      sourceURL,
      line: Number.isInteger(line) ? line : undefined,
      column: Number.isInteger(column) ? column : undefined,
      //XXX - some browsers return null which breaks the es6 destructuring defaults :S
      stackTrace: (errorObject || {}).stack
    };
    const options = { error };

    const validationResult = WindowErrorValidator.isValid(error);
    if (validationResult.isValid) {
      ErrorHandler.handleError(options);
    } else {
      ErrorHandler.handleIgnoredError(options, validationResult.message);
    }

    // Return false and supress error
    return false;
  }
};

module.exports = ErrorHandler;
