const MultipleChoiceQuestionPreviewPage = require('@common/components/questions/views/MultipleChoice/MultipleChoiceQuestionPreviewPage');
const MultipleAnswerQuestionPreviewPage = require('@common/components/questions/views/MultipleAnswer/MultipleAnswerQuestionPreviewPage');
const MultipleChoiceAdvancedQuestionPreviewPage = require('@common/components/questions/views/AdvancedMultipleChoice/MultipleChoiceAdvancedQuestionPreviewPage');
const MatchingQuestionPreviewPage = require('@common/components/questions/views/MatchingQuestion/MatchingQuestionPreviewPage');
const FillInTheBlanksQuestionPreviewPage = require('@common/components/questions/views/FillInTheBlank/FillInTheBlanksQuestionPreviewPage');
const HotspotQuestionPreviewPage = require('@common/components/questions/views/HotSpot/HotspotQuestionPreviewPage');

const VariantType = require('@common/data/enums/VariantType');

const QuestionTypeFactory = {
  getQuestionBody(answer) {
    return this._getQuestionView(answer);
  },

  _getQuestionView: (answer) => {
    let questionType;
    questionType = (() => {
      switch (answer.get('variant').get('variantType')) {
        case VariantType.MultipleChoice:
          return questionType = new MultipleChoiceQuestionPreviewPage({model: answer});
        case VariantType.AdvancedMultipleChoice:
          return questionType = new MultipleChoiceAdvancedQuestionPreviewPage({model: answer});
        case VariantType.MultipleAnswer:
          return questionType = new MultipleAnswerQuestionPreviewPage({model: answer});
        case VariantType.JumbledSentence:
          return questionType = new FillInTheBlanksQuestionPreviewPage({model: answer});
        case VariantType.Hotspot:
          return questionType = new HotspotQuestionPreviewPage({model: answer});
        case VariantType.Matching:
          return questionType = new MatchingQuestionPreviewPage({model: answer});
        default:
          throw new Error('A correct question type must be provided');
      }
    })();

    return questionType;
  }
};

module.exports = QuestionTypeFactory;
