// Module
var code = `<div class="modalHeader">
  <h1><%- modalHeader %></h1>
  <button class="close icon-remove close-api-modal js-close-api-modal" aria-label="<%- t('general.close') %>"></button>
</div>
<div class="createApiKeyModal">
  <form class="modal-form js-modal-form <%- ifNewClass() %>">
    <label for="name" class="name"><%- t('communities.layout.content.translations.default') %></label>
    <input  id="name" class="name-input" data-field="name" />
    <label class="owner"><%- t('communities.apiKeys.defaultOwner')%>
      <% if (isNew() ) { %>
      <%- t('communities.apiKeys.warning')%>
      <% } %>
    </label>
    <% if (isNew()) { %>
    <div class="user-container <%- ifNewClass() %>">
        <div class="search-container">
          <div class="autocomplete js-autocomplete">
            <%= axSearch({ name: 'searchstringinput', fullWidth: true, placeholder: t('communities.apiKeys.searchUser') }) %>
          </div>
        </div>
      </div>
    <% } else {%>
      <input class="user-name" value="<%- user.fullname %>" disabled />
    <% } %>
    <% if (!isNew()) { %>
      <label for="api" class="api"><%- t('communities.apiKeys.apiKey')%></label>
      <input id="api" class="api-input" data-field="apiKey" disabled />
    <% } %>
  </form>
</div>
`;
// Exports
module.exports = code;