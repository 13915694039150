const {ItemView} = require('Marionette');

const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

class ArticleContextMenuReportsButton extends ItemView {
  className() {
    return 'parent-height';
  }

  getTemplate() {
    return `\
        <div class="menu-reports-button__container">
            <a href=<%- link %> class="ax-button reports" aria-label="<%- t('discover.browse.reports.title') %>">
              <span class="page-preview__icon icon-reports"></span>
            </a>
        </div>\
          `;
  }

  ui() {
    return {
      reportsBtn: '.reports'
    };
  }

  templateHelpers() {
    return {
      link: `${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/reports`
    };
  }
}

module.exports = ArticleContextMenuReportsButton;
