const { Model } = require('Backbone');
const _ = require('underscore');

const PageInsightsReactionsCollection = require('@training/apps/insights/engagement/PageInsightsReactionsCollection');
const PageInsightsEngagementsCollection = require('@training/apps/insights/engagement/PageInsightsEngagementsCollection');

class PageInsightsEngagementModel extends Model {

  static ENGAGEMENT_KEYS = [
    'comments',
    'favourites',
    'impressions',
    'viewers',
    'views'
  ];

  initialize(options = {}) {
    ({
      pageId: this.pageId,
      teamId: this.teamId
    } = options);
  }

  defaults() {
    // Define 2 unique, single instances of a Collection under unique property names
    // These collections are passed in the Engagement view
    return {
      engagementsCollection: new PageInsightsEngagementsCollection(this.generateEngagementDefaults()),
      reactionsCollection: new PageInsightsReactionsCollection(),
      reactions: {}
    };
  }

  apiEndpoint() {
    return 'pageInsights/' + this.pageId;
  }

  fetch(options = {}) {
    options.data = options.data || {};

    if (this.teamId) {
      Object.assign(options.data, { locationId: this.teamId });
    }

    return super.fetch(options);
  }

  parse(resp) {
    const response = super.parse(resp);
    this.fulfillCollection(response.engagements, 'engagementsCollection');
    this.fulfillCollection(response.reactions.categories, 'reactionsCollection');

    // Delete the objects on the response after setting up the Models. These will no longer be needed, and shouldn't
    // Be kept around to confuse others
    delete response.reactions.categories;
    delete response.engagements;
    return response;
  }

  fulfillCollection(response, collection) {
    // Convert to array
    const array = _.map(response, (value, key) => {
      return new Model({key, value});
    });

    // Add the appropriate data to a collection
    this.get(collection).reset(array);
  }

  generateEngagementDefaults() {
    const engagements = [];

    PageInsightsEngagementModel.ENGAGEMENT_KEYS.forEach((key) => {
      engagements.push({
        key,
        value: '--'
      });
    });

    return engagements;
  }
}

module.exports = PageInsightsEngagementModel;
