const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  COMMUNITY: 'community',
  PENDING: 'pending',
  FAVORITES: 'favorites',
  ARTICLE_REPORTS: 'reports',
  TOP_PAGES: 'top_pages',
  TOP_KEYWORDS_IN_SEARCH: 'top_keywords_in_search',
  TOP_KEYWORDS_EMPTY_SEARCH: 'top_keywords_in_search_empty'
});
