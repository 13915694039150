const logging = require('logging');

const _ = require('underscore');
const Backbone = require('Backbone');

const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

const {
  LearningEventUpcomingList,
  LearningEventScheduledList,
  LearningEventHistoryList
} = require('@training/apps/training/collections/LearningEventList');
const LearningEventListView = require('@training/apps/training/views/LearningEventListView');
require('@common/libs/behaviors/msHideable/MsHideable');

class LearningEventsPage extends UIKit.View {

  static TAB_NAME = {
    UPCOMING: 'upcoming',
    SCHEDULED: 'scheduled',
    HISTORY: 'history'
  };

  get template() {
    return _.tpl(require('@training/apps/training/templates/LearningEventsPage.html'));
  }

  initialize(options = {}) {
    ({
      initialTab: this.initialTab = LearningEventsPage.TAB_NAME.UPCOMING
    } = options);

    const currentUserLanguage = window.apps.auth.session.user.get('language');

    this.enrollmentLists = {
      upcoming: new LearningEventUpcomingList([], { currentUserLanguage }),
      scheduled: new LearningEventScheduledList([], { currentUserLanguage }),
      history: new LearningEventHistoryList([], { currentUserLanguage })
    };

    this.tabHashes = {
      [LearningEventsPage.TAB_NAME.UPCOMING]: '#hub/events/upcoming',
      [LearningEventsPage.TAB_NAME.SCHEDULED]: '#hub/events/scheduled',
      [LearningEventsPage.TAB_NAME.HISTORY]: '#hub/events/history'
    };
  }

  render() {
    this.$el.html(this.template());
    this.tabs = new UIKit.TabBar({el: this.$('.subtabs')});
    this.listenTo(this.tabs, 'change:tabs', this.onChangeTabs);

    return this;
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  viewDidAppear() {
    logging.info('LearningEventsPage - viewDidAppear');
    window.app.layout.setTitle(I18n.t('hub.learningEvents'));

    this.tabs.clickTab(this.initialTab);
  }

  onChangeTabs(tabName) {
    _.each(LearningEventsPage.TAB_NAME, (tab) => {
      if (this.tabs[tab]) {
        this.tabs[tab].isActive = false;
      }
    });

    Backbone.history.navigate(this.tabHashes[tabName], {
      trigger: false,
      replace: true
    });

    let v = this.tabs[tabName];
    if (!v) {
      v = this._createListView(tabName);
      this.tabs[tabName] = v;
    }
    // set isActive for the currently active tab so loadNext isn't triggered unecessarily for other views
    this.tabs[tabName].isActive = true;

    this.$('.contentarea').hide();
    v.$el.fadeIn('fast');

    this.triggerAdjustment();
  }

  onClose() {
    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }

    // destroy each tab that was opened
    _.each(LearningEventsPage.TAB_NAME, (tabName) => {
      if (this.tabs[tabName]) {
        this.tabs[tabName].destroy();
      }
    });

    window.app.layout.resetLeftHeaderView();
    this.actionBar?.setActionButton(null);
    super.onClose();
  }

  _createListView(elementId) {
    const v = new LearningEventListView({
      eventList: this.enrollmentLists[elementId],
      $el: this.$(`.contentarea[data-tab='${ elementId }']`),
      isActive: true
    });

    v.render().viewDidAppear();

    return v;
  }
}

module.exports = LearningEventsPage;
