const Backbone = require('Backbone');

const AGREE_FIELD_NAME = 'agreed';

class UserAgreementViewState extends Backbone.Model {
  defaults() {
    return {
      [AGREE_FIELD_NAME]: false
    };
  }

  hasAgreed() {
    return this.get(AGREE_FIELD_NAME);
  }

  markAs(agreeed) {
    return this.set(AGREE_FIELD_NAME, agreeed);
  }
}

module.exports = UserAgreementViewState;
