const logging = require('logging');
const $ = require('jquery');
const RETRY_LIMIT = 3;

class ImageLoadRequest {
  constructor(src, options = {}) {
    this._onLoadSuccess = this._onLoadSuccess.bind(this);
    this._onLoadError = this._onLoadError.bind(this);

    this.src = src;
    this.retryCount = 0;
    this.retryLimit = options.retryLimit !== undefined
      ? this.retryLimit = options.retryLimit
      : RETRY_LIMIT;
    this.deferred = new $.Deferred();
    this.isLoaded = false;
  }

  load() {
    this._load();
    return this.deferred.promise();
  }

  _load() {
    this.isLoaded = false;

    const imageEl = new Image();

    this._attachHandlers(imageEl);
    this._startLoad(imageEl);
  }

  _startLoad(imageEl) {
    imageEl.src = this.src;
  }

  _attachHandlers(imageEl) {
    $(imageEl).on('load', this._onLoadSuccess);
    $(imageEl).on('error', this._onLoadError);
  }

  _detachHandlers(imageEl) {
    $(imageEl).off();
  }

  _onLoadSuccess(e) {
    const imageEl = e.currentTarget;

    this._detachHandlers(imageEl);

    this.isLoaded = true;

    // Check for the image size for 0s since that can happen if the image is corrupt
    // before resolving the deferred.
    if (this._isImageCorrupt(imageEl)) {
      logging.error(`Image (${ this.src }) load error; 0 size error!`);
      this._retryOrFail(e, imageEl);
    } else {
      logging.info(`Image (${ this.src }) load success!`);
      this._loadSuccess(e, imageEl);
    }
  }

  _loadSuccess(e, imageEl) {
    // Backfill the naturalHeight/Width values on the image for older non-HTML5 browsers
    this._backfillNaturalImageDimensions(imageEl);
    this.deferred.resolve(e, imageEl);
  }

  _loadError(e, imageEl) {
    this.deferred.reject(e, imageEl);
  }

  _isImageCorrupt(imageEl) {
    return this.isLoaded && (imageEl.width === 0) && (imageEl.height === 0);
  }

  _backfillNaturalImageDimensions(imageEl) {
    this._backfillNaturalImageHeight(imageEl);
    this._backfillNaturalImageWidth(imageEl);
  }

  _backfillNaturalImageHeight(imageEl) {
    if (imageEl.naturalHeight === null) {
      imageEl.naturalHeight = imageEl.height;
    }
  }

  _backfillNaturalImageWidth(imageEl) {
    if (imageEl.naturalWidth === null) {
      imageEl.naturalWidth = imageEl.width;
    }
  }

  _onLoadError(e) {
    const imageEl = e.currentTarget;
    this._detachHandlers(imageEl);
    this._retryOrFail(e, imageEl);
  }

  _retryOrFail(e, imageEl) {
    if (this._shouldRetry()) {
      logging.warn(`Image (${ this.src }) load error; retrying...`);
      this._retryLoad();
    } else {
      logging.error(`Image (${ this.src }) load error; Failed to load after ${ this.retryLimit } attempts, FATALITY!`);
      this._loadError(e, imageEl);
    }
  }

  _shouldRetry() {
    return this.retryCount < this.retryLimit;
  }

  _retryLoad() {
    this._incrementRetryCount();
    this._load();
  }

  _incrementRetryCount() {
    this.retryCount++;
  }
}

const ImageLoader = {
  load(src, options = {}) {
    const imageRequest = new ImageLoadRequest(src, options);
    return imageRequest.load();
  }
};

module.exports = ImageLoader;
