const GuidedLearningObjective = require('@common/data/models/objectives/GuidedLearningObjective');
const TopicGuidedLearningObjective = require('@common/data/models/objectives/TopicGuidedLearningObjective');
const TaskLearningObjective = require('@common/data/models/objectives/TaskLearningObjective');
const EventEnrollmentObjective = require('@common/data/models/objectives/EventEnrollmentObjective');
const CertificationLearningObjective = require('@common/data/models/objectives/CertificationLearningObjective');
const EvaluationLearningObjective = require('@common/data/models/objectives/EvaluationLearningObjective');

const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');

module.exports = (attrs, options) => {
  const abstractType = new GuidedLearningObjective(attrs);
  switch (abstractType.getType()) {
    case GuidedLearningObjectiveType.TOPIC_OBJECTIVE:
      return new TopicGuidedLearningObjective(attrs, options);
    case GuidedLearningObjectiveType.TASK_OBJECTIVE:
      return new TaskLearningObjective(attrs, options);
    case GuidedLearningObjectiveType.CERTIFICATION_OBJECTIVE:
      return new CertificationLearningObjective(attrs, options);
    case GuidedLearningObjectiveType.EVENT_OBJECTIVE:
      return new EventEnrollmentObjective(attrs, options);
    case GuidedLearningObjectiveType.EVALUATION_OBJECTIVE:
      return new EvaluationLearningObjective(attrs, options);
    default:
      throw new Error('The data element has to be implemented');
  }
};
