const {
  settings,
  invalidateCacheQuery
} = require('env');

const generateCSSLink = function(cssPath = '', media = '') {
  const attributes = {
    href: `${ settings.version }/${ cssPath }${ invalidateCacheQuery }`,
    rel: 'stylesheet',
    media: `${ media }`,
    type: 'text/css'
  };

  return $('<link />', attributes);
};

module.exports = {
  generateCSSLink
};
