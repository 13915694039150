const { CollectionView } = require('Marionette');
const PageableListViewController = require('@common/components/pageableList/PageableListViewController');

class PageableBrowseCommunitiesController extends PageableListViewController {
  _getListRegionViewDefinition() {
    return {
      viewDefinition: {
        ViewClass: CollectionView,
        itemView: this.itemViewClass,
        collection: this.collection,
        className: 'search-communities-list ax-grid',
        tagName: 'div',
        childView: this.itemViewClass,
        childViewOptions: this.itemViewOptions,
        emptyView: this.emptyViewClass,
        emptyViewOptions: this.emptyViewOptions
      }
    };
  }
}

module.exports = PageableBrowseCommunitiesController;
