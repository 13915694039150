const _ = require('underscore');
const Backbone = require('Backbone');

class CommunityUserCounts extends Backbone.Model {
  initialize(options = {}) {
    (({
      communityId: this.communityId
    } = options));

  }

  apiEndpoint() {
    return `/pageCommunities/${ this.communityId }/userCount`;
  }

  parse(data) {
    return { count: data.result };
  }

  fetch(options = {}) {
    // allows the communityId to be changed at fetch-time, which the community picker does
    if (options.data && options.data.communityId) {
      this.communityId = options.data.communityId;
    }
    return super.fetch(
      _.defaults(
        options,
        {showSpinner: false}
      )
    );
  }

}

module.exports = CommunityUserCounts;
