const logging = require('logging');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ActivitiesController = require('@training/apps/training/ActivitiesController');
const FeedbackModalFormView = require('@training/apps/training/views/FeedbackModalFormView');
const InGameQuestionView = require('@training/apps/training/views/InGameQuestionView');

class QuizManager extends ActivitiesController {
  preinitialize() {
    this.processActivities = this.processActivities.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);
  }

  getNextActivity() {
    return this.sessionModel.currentAssessment.activities.getNextQuestion();
  }

  processActivities() {
    logging.debug('Processing quiz activities');

    this.currentActivity = this.getNextActivity();

    let transition = UIKit.View.Transitions.FADE;
    if (!this.isShowingQuestions) {
      window.app.layout.setView(new UIKit.View(), UIKit.View.Transitions.NONE); // set a blank view so nothing is showing behind the questions
      window.app.layout.setTitle(I18n.t('selectGame.noGame.quiz'));

      transition = UIKit.View.Transitions.NONE;
      this.isShowingQuestions = true;

      logging.info('Presenting InGameQuestionView');

      const v = new InGameQuestionView();
      window.app.layout.presentModal(v, {
        closeClick: false,
        closeEsc: false
      });

      v.listenTo(apps.glChannel.vent, 'app:feedbackSubmitted', () => {
        v.actionBar.disableButton('feedback');
      });

      v.listenTo(apps.glChannel.vent, 'app:openFeedbackModal', () => {
        // Store a reference to the details view/layer (where the feedback modal will be displayed)
        const detailsView = window.app.layout.mainLayout.findControllerById('details-view').getView();
        const isFooterShowing = detailsView.isFooterShowing();

        // Create a blank view to contain the feedback form (in its own modal)
        const feedbackWrapper = new UIKit.View({
          className: 'feedback-modal-wrapper'
        });

        window.app.layout.pushDetailsView(feedbackWrapper, transition);
        // If the details view was previously showing the logo/footer - hide it for the feedback modal
        if (isFooterShowing) {
          detailsView.toggleFooter(false);
        }

        const feedbackModal = new AccessibleModalView({
          id: 'feedbackmodalview',
          className: 'modal feedback-modal',
          bottomOffset: 150,
          topOffset: 250
        });

        const cleanUpFeedbackModal = () => {
          detailsView.$el.toggleClass('feedback', false);
          // If the details view was previously showing the logo/footer - turn it back on
          if (isFooterShowing) {
            detailsView.toggleFooter(true);
          }
          window.app.layout.popDetailsView();
        };
        cleanUpFeedbackModal.bind(this);

        const feedbackModalFormView = new FeedbackModalFormView({
          feedbackContext: this.currentActivity,
          onClose: cleanUpFeedbackModal
        });

        detailsView.$el.toggleClass('feedback', true);

        feedbackWrapper.presentModal(feedbackModal, {
          closeEsc: true,
          closeClick: false,
          modalCSS: {
            bottom: 'unset'
          },
          onClose: cleanUpFeedbackModal
        });
        feedbackModal.setSubviewIn(feedbackModalFormView, transition);
      });
    }

    if (this.currentActivity) {
      this.showActivity(this.currentActivity, {
        transition,
        modal: true,
        complete: this.processActivities
      });
    } else {
      logging.info('Processing quiz activities done; dimissing modal.');
      window.app.layout.dismissModal(this.finishedProcessing);
    }
  }

  finishedProcessing() {
    logging.debug('Finished processing quiz');
    super.finishedProcessing();
  }
}

module.exports = QuizManager;
