// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-events" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('learningEvents.details.event') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content event-details">
    <ul class="subtabs events-tabs clearfix">
      <li data-tab="details" class="tab two-tab-view">
        <a href="#"><%- t('learningEvents.details.eventDetails')%></a>
      </li>
      <li data-tab="attendees" class="tab two-tab-view">
        <a href="#"><%- t('learningEvents.details.attendees')%></a>
      </li>
    </ul>
    <div class="tab-container">
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;