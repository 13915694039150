const _ = require('underscore');
const stringToPath = require('@common/configs/underscore/internal/stringToPath');
const toKey = require('@common/configs/underscore/internal/toKey');

/*
 * The following code was pretty much all ripped from the source code for Lodash.js
 * https://github.com/lodash/lodash/blob/2f79053d7bc7c9c9561a30dda202b3dcd2b72b90/toPath.js
*/

/**
 * Converts `value` to a property path array.
 *
 * @param {*} value The value to convert.
 * @returns {Array} Returns the new property path array.
 * @example
 *
 * toPath('a.b.c')
 * // => ['a', 'b', 'c']
 *
 * toPath('a[0].b.c')
 * // => ['a', '0', 'b', 'c']
 */
const toPath = (value) => {
  if (Array.isArray(value)) {
    return value.map(toKey);
  } else if (_.isSymbol(value)) {
    return [value];
  } else if (value == null) {
    return [''];
  }

  return [...stringToPath(value)];
};

_.mixin({ toPath });
