const $os = require('detectOS');
const logging = require('logging');
const { history } = require('Backbone');
const { View } = require('@training/widgets/UIKit');
const _ = require('underscore');

require('@common/libs/behaviors/resizable/Resizable');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');

const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');

const Game = require('@common/data/models/Game');
const GameChallenge = require('../models/GameChallenge');

const GameScoresDetailsView = require('./GameScoresDetailsView');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');

const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

class GameInfoPage extends View {

  get template() {
    return _.tpl(require('../templates/GameInfoPage.html'));
  }

  className() {
    return 'ax-container';
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  regions() {
    return {
      videoPlayerRegion: '.game-info-video-wrapper'
    };
  }

  events() {
    return {
      'click .js-play-now': 'onClickPlayNow',
      'click .js-challengebtn': 'onClickChallengeFriend',
      'click .js-accept-challenge': 'onClickAcceptChallenge',
      'click .js-decline-challenge': 'onClickDeclineChallenge'
    };
  }

  constructor(options = {}) {
    super(options);

    this._renderVideoPlayer = this._renderVideoPlayer.bind(this);
    this._renderGameScores = this._renderGameScores.bind(this);
    this._renderActionButtons = this._renderActionButtons.bind(this);
    this.onClickAcceptChallenge = this.onClickAcceptChallenge.bind(this);
    this.onClickDeclineChallenge = this.onClickDeclineChallenge.bind(this);
    this.onClickPlayNow = this.onClickPlayNow.bind(this);
    this.onClickChallengeFriend = this.onClickChallengeFriend.bind(this);

    (({
      gameId: this.gameId,
      challengeId: this.challengeId,
      selectGame: this.selectGame
    } = options));

    this.model = new Game({id: this.gameId});
    this.gameModelPromise = this.model.fetch();

    this.gameScoresDeferred = $.Deferred();
    this.gameModelPromise.done(() => {
      if (this.isDestroyed) {
        return;
      }
      this.model.getGameScores()
        .done(() => {
          return this.gameScoresDeferred.resolve();
        })
        .fail(() => {
          return this.gameScoresDeferred.reject();
        });
    });

    this.challengeDataDeferred = $.Deferred();
    this.gameModelPromise.done( () => {
      if (this.isDestroyed) {
        return;
      }
      if (this.challengeId != null) {
        this.challenge = new GameChallenge({
          id: this.challengeId,
          game: this.model.toJSON()
        });

        this.challenge.fetch().done( () => {
          this.challengeDataDeferred.resolve();
        })
          .fail( () => {
            this.challengeDataDeferred.reject();
          });
      } else {
        this.challengeDataDeferred.reject();
      }
    }).fail( () => {
      this.challengeDataDeferred.reject();
    });
  }

  viewDidAppear() {
    logging.info('GameInfoPage - viewDidAppear');

    // Set up navbar
    window.app.layout.showHeaderBackButton();

    this.$videowrapper = this.$('.game-info-video-wrapper');

    this.challengeDataDeferred.always(() => {
      if (this.isDestroyed) {
        return;
      }

      const game = this.model.toJSON();
      const gameMetadata = this.model.getGameMetadata();
      const challenge = this.challenge != null ? this.challenge.toJSON() : undefined;

      // We can only render the game page if the promise wasn't rejected
      if (this.gameModelPromise.state() !== 'rejected') {
        this.$el.html(this.template({
          game,
          gameMetadata,
          challenge
        }));
        if (challenge != null) {
          this.iconHandler = new ProfileIconHandler({
            user: challenge.challenger,
            profileElement: this.$('.small-name-logo')
          });
        }

        this.trigger('force:icon:reflow');
      } else {
        logging.warn('The game model call failed. This is likely because a 401 or similar error when trying to come to this page.');
      }

      this._renderVideoPlayer();
      this._renderGameScores();

      if ($os.mobile) {
        this._renderActionButtons();
      }
    });
  }

  _renderVideoPlayer() {
    return this.gameModelPromise.done(() => {
      if (this.isDestroyed) {
        return;
      }

      // Game video player setup
      const {
        poster,
        videoLinks
      } = this.model.getGameMetadata();
      const formats = _.map(videoLinks, (video) => {
        const mimeTypeToVideoType = {
          'video/mp4': 'mp4',
          'video/ogg': 'ogv'
        };

        return {
          type: mimeTypeToVideoType[video.type],
          file: {
            path: video.link
          }
        };
      });

      const mediaLink = {
        media: {
          transcoding: {
            formats,
            status: 'done'
          }
        }
      };

      // Poster images are 910x480 and the Video Player doesn't crop & scale, it
      // just scales the image while preserving the aspect ratio, so it's possible
      // to have the first screen of the video show up behind the poster image.
      const aspectRatio = 910 / 480;
      const maxHeight = this.calculatePlayerMaxHeight();

      this.videoPlayer = VideoPlayerFactory.createPlayerInstance({
        videoPackage: mediaLink,
        viewOptions: {
          userLanguage: window.apps.auth.session.user.get('language'),
          poster,
          aspectRatio,
          maxHeight
        }
      });

      this.videoPlayerRegion.show(this.videoPlayer);
    });
  }

  onResize() {
    if (this.videoPlayer != null) {
      this.setPlayerMaxHeight();
      this.videoPlayer.resize();
    }
  }

  calculatePlayerMaxHeight() {
    const parentHeight = $(BrowserHelpers.getScrollParent(this.el)).height();
    const videoWrapperPadding = this.$videowrapper.outerHeight(true) - this.$videowrapper.height();
    const allowableHeight = parentHeight - videoWrapperPadding;

    if (allowableHeight >= 385) {
      return 385;
    } else if (allowableHeight <= 200) {
      return 200;
    }
    return allowableHeight;

  }

  setPlayerMaxHeight() {
    const allowableHeight = this.calculatePlayerMaxHeight();
    this.videoPlayer.setMaxHeight(allowableHeight);
    this.$videowrapper.height(allowableHeight);
  }

  _renderGameScores() {
    this.gameScoresDeferred.done(() => {
      const gameScoresDetailsView = new GameScoresDetailsView({model: this.model});
      this.setSubviewIn(gameScoresDetailsView, {
        regionSelector: '.game-scores-details-view',
        transition: View.Transitions.NONE
      });
    });
  }

  _renderActionButtons() {
    this.challengeDataDeferred.done( () => {
      // Show challenge buttons
      const buttons = [{
        type: 'customText',
        text: I18n.t('selectGame.gameInfo.acceptChallenge'),
        onClick: this.onClickAcceptChallenge
      }, {
        type: 'customText',
        text: I18n.t('selectGame.gameInfo.declineChallenge'),
        onClick: this.onClickDeclineChallenge
      }];

      this.actionBar.setActionButton(buttons);
    }).fail( () => {
      // Show Play game buttons
      const buttons = [{
        type: 'customText',
        text: I18n.t('selectGame.gameInfo.playNow'),
        onClick: this.onClickPlayNow
      }];

      const game = this.model.toJSON();

      if (_.has(game.mechanics, 'challenge')) {
        buttons.push({
          type: 'customText',
          text: I18n.t('selectGame.gameInfo.challengeFriend'),
          onClick: this.onClickChallengeFriend
        });
      }

      this.actionBar.setActionButton(buttons);
    });
  }

  onClickAcceptChallenge() {
    this.selectGame(this.model.id, this.challenge.id);
  }

  onClickDeclineChallenge() {
    if (this.challengeAlreadyDeclined) {
      return;
    }
    this.challengeAlreadyDeclined = true;
    this.challenge.destroy().done(() => {
      history.navigate('#games', {trigger: true});
    });
  }

  onClickPlayNow() {
    this.selectGame(this.model.id);
  }

  onClickChallengeFriend() {
    history.navigate(`#games/${ this.model.id }/challenge`, {trigger: true});
  }

  onClose() {
    this.model.abortXHR(Sync.Method.READ);
    this.model.abortGameScores();

    if (this.challenge != null) {
      this.challenge.abortXHR(Sync.Method.READ);
    }

    window.app.layout.resetLeftHeaderView();

    if ($os.mobile) {
      this.actionBar?.setActionButton(null);
    }

    super.onClose();
  }
}

module.exports = GameInfoPage;
