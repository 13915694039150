const { defaults } = require('underscore');
const { Wreqr } = require('Backbone');
const DestroyableObject = require('@common/libs/DestroyableObject');

/*

  BaseController

  Basic Controller implementation which provides:
  - access to the global channel
  - 'namespace' information to inform the controller what context it's working in. ie. metrics, people, behaviors, etc.
  - utility function to set the 'namespace' as a default option property

*/

class BaseController extends DestroyableObject {

  constructor(options = {}) {
    super(options);

    this.namespace = options.namespace || 'app';
    this.glChannel = Wreqr.radio.channel('global');
    this.flashChannel = Wreqr.radio.channel('flash');
  }

  setDefaultOptions(options = {}) {
    return defaults({}, options, { namespace: this.namespace });
  }
}

module.exports = BaseController;
