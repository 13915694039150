// Module
var code = `<div class="multichoice">
  <div class="questionarea <%- getTranslationStatusCSS() %> clearfix">
    <div class="questionask">
      <% if(getQuestionImage()) {%>
        <div class="questionimage image-wrapper imgwrap" data-media-id="<%- getQuestionImage().id %>"></div>
      <%}%>
      <div class="question-text">
        <%- getTranslationStringIfNoText() %>
        <%= variant.get('questionText') %>
      </div>
      <span class="clear"></span>
    </div>
    <div class="bonuscharacter">
      <div class="<%= bonuscharacter %> ponder" aria-hidden="true"></div>
    </div>
  </div>
  <div class="hinttext <%- answerStatus %>">
    <div class="question-instruction clearfix">
      <span id="balance-points-wrapper" class="clearfix <%- answerStatus %>">
        <p class="correct-incorrect full-size"><%- answerStatusText %></p>
      </span>
    </div>
    <div id="reason" class="clearfix">
      <% if(getReasonImage()) {%>
        <div class="image-wrapper imgwrap reason-image" data-media-id="<%- reasonImage.id %>"></div>
      <%}%>
      <p class="ax-font--no-margin" id="reasonContent"><%= getReasonText() %></p>
    </div>
  </div>
</div>
<div class="zoom-placeholder hidden"></div>
`;
// Exports
module.exports = code;