const Marionette = require('Marionette');
const SCORMRTE = require('@common/libs/scorm/scorm-rte');
const PreviewUnavailableView = require('@common/components/training_modules/PreviewUnavailableView');
const $os = require('detectOS');
const ExternalWindowService = require('@common/services/externalWindow/ExternalWindowService');
const TrainingModuleDeliveryMethodEnum = require('@common/data/enums/TrainingModuleDeliveryMethodEnum');
const SCORMPreviewEmbeddedView = require('@common/components/training_modules/scorm/SCORMPreviewEmbeddedView');
const SCORMNewWindowCardCollectionView = require('@common/components/training_modules/scorm/SCORMNewWindowCardCollectionView');
const SCORMStatusEnum = require('@common/data/enums/SCORMStatusEnum');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const HeartbeatController = require('@common/libs/HeartbeatController');

require('@common/components/training_modules/scorm/SCORM.less');
require('@common/libs/behaviors/scrollable/Scrollable');

class SCORMView extends Marionette.LayoutView {
  className() {
    return 'full-height';
  }

  getTemplate() {
    const wrapperClass = $os.ios ? 'scorm-ios-wrapper-fix' : '';

    return `\
    <div class="${ wrapperClass } scorm-container full-height full-width"></div>
    <div class="completeScormMessage"><%- t('content.modulePreview.completed') %></div>\
    `;
  }

  ui() {
    return {
      completeScormMessage: '.completeScormMessage',
      scormContainer: '.scorm-container'
    };
  }

  regions() {
    return {
      scormRegion: '.scorm-container'
    };
  }

  initialize(options = {}) {
    ({
      language: this.language,
      previewingUser: this.previewingUser,
      scoHref: this.scoHref,
      scormVersion: this.scormVersion,
      launchData: this.launchData
    } = options);

    this._isEmbedded = options.isEmbedded ?? this.model.get('deliveryMethod') === TrainingModuleDeliveryMethodEnum.EMBEDDED;

    this.heartbeatController = new HeartbeatController();

    this._onLaunchClicked = this._onLaunchClicked.bind(this);
    this._showSCORMCompleteMessage = this._showSCORMCompleteMessage.bind(this);
  }

  onRender() {
    const { propertyObj } = TenantPropertyProvider.get();
    const fallbackLanguage = propertyObj && propertyObj?.length > 0
      ? TenantPropertyProvider.get().getProperty('defaultLanguage')
      : 'EN';
    const link = this.model.moduleLinks.getPackageForLanguage(this.language)
      ?? this.model.moduleLinks.getPackageForLanguage(fallbackLanguage);
    const scormPackage = link != null ? link.get('scormPackage') : undefined;

    if (scormPackage != null) {
      this.manifestURL = scormPackage.manifestDownloadUrl;

      if (this._isEmbedded) {
        this._renderEmbeddedSCORM();
      } else {
        this._renderNewWindowSCORM(scormPackage);
      }
    } else {
      this._showErrorMessage();
    }
  }

  _renderEmbeddedSCORM() {
    const embeddedSCORMView = new SCORMPreviewEmbeddedView();

    this.scormRegion.show(embeddedSCORMView);
    this.heartbeatController?.startHeartbeat();

    this._createSCORM({
      $contentFrame: embeddedSCORMView.ui.iframe,
      ajax: $.ajax,
      onComplete: this._showSCORMCompleteMessage,
      scoHref: this.scoHref,
      scormVersion: this.scormVersion,
      launchData: this.launchData
    });

    this.scormRte.options.$contentFrame.attr('src', `${ this.scormRte.scoHref }`);
  }

  _showSCORMCompleteMessage() {
    this.ui.completeScormMessage.animate({height: '50px'});
  }

  _renderNewWindowSCORM(scormPackage) {
    this.scorms = {};

    this.collectionView = new SCORMNewWindowCardCollectionView({
      scormPackage,
      childEvents: {
        'click:launch': this._onLaunchClicked
      }
    });

    this.scormRegion.show(this.collectionView);
  }

  _onLaunchClicked(childView, {model}) {
    // caching SCORMRTE instance instead of creating a new one each time.
    // This is done in order to save module progress (until next progress fetch).
    const scoHref = model.get('href');
    // using scoHref because it is unique
    if (this.scorms[scoHref] != null) {
      this.scormRte = this.scorms[scoHref];
    } else {
      this._createSCORM({
        scoHref,
        scormVersion: this.collectionView.scormVersion,
        launchData: this.collectionView.launchData,
        onInitialize: () => {
          if (model.get('status') !== SCORMStatusEnum.completed) {
            model.set('status', SCORMStatusEnum.started);
          }
        },
        onComplete: () => {
          model.set('status', SCORMStatusEnum.completed);
          if (this.collectionView.areAllSubModulesComplete()) {
            this._showSCORMCompleteMessage();
          }
        },
        onFinish: () => {
          this.heartbeatController?.stopHeartbeat();
        }
      });
      this.scorms[scoHref] = this.scormRte;
    }
    // closing module because there is a progress save when closing.
    this._closeExternalWindow();
    this._createExternalWindowService();
    this.heartbeatController?.startHeartbeat();
    this.SCORMExternalWindowService?.openWindow();
  }

  _createSCORM(options = {}) {
    const baseConfigs = {
      persistanceStrategy: {
        canSaveProgress() {
          return false;
        },
        saveProgress(body, callback) {
          return callback(new Date().getTime());
        }
      },
      manifestURL: this.manifestURL,
      scoValues: [],
      lang: this.language,
      student: {
        id: this.previewingUser.get('employeeId'),
        name: this.previewingUser.get('fullname')
      }
    };
    const scormConfig = Object.assign({}, baseConfigs, options);

    try {
      this.scormRte = new SCORMRTE(scormConfig);
    } catch (error) {
      this._showErrorMessage(I18n.t('scorm.errorLoading'));
    }
  }

  _createExternalWindowService() {
    try {
      this.SCORMExternalWindowService = new ExternalWindowService({
        windowOptions: {
          href: this.scormRte.scoHref,
          windowId: 'Axonify_SCORMView_External'
        },
        popupBlockedHandler: (message) => {
          this._showErrorMessage(message);
        }
      });
    } catch (error) {
      this._showErrorMessage(I18n.t('scorm.errorLoading'));
    }
  }

  _closeExternalWindow() {
    this.SCORMExternalWindowService?.closeWindow();
  }

  _showErrorMessage(message) {
    const preview = new PreviewUnavailableView({message}).render().$el;
    this.ui.scormContainer.replaceWith(preview);
  }

  onDestroy() {
    this.heartbeatController?.stopHeartbeat();
    this._closeExternalWindow();
  }
}

module.exports = SCORMView;
