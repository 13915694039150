const _ = require('underscore');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const { INFORMATIONAL } = require('@common/libs/behaviors/card/CardModifierClasses');

/*
 * In the DOM this is at the top of the guided Learning page, containing the tabs X days away, and the Milestone status
 * Created By: ProgramOverviewProgressController
 */
class MilestonesStatusView extends Marionette.ItemView {
  modelEvents() {
    return {
      change: 'render'
    };
  }

  behaviors() {
    return {
      Card: {
        modifierClasses: [INFORMATIONAL]
      }
    };
  }

  onRender() {
    if (this.model.get('status') === 'NotStarted') {
      this.$el.addClass('hidden');
    }

    if (this._isOverdue()) {
      this.$el.addClass('milestone-status--overdue');
    } else if (!this._isOnPace()) {
      this.$el.addClass('milestone-status--behind-pace');
    }
    _.noop();
  }

  getTemplate() {
    return require('./MilestonesStatusView.html');
  }

  className() {
    return 'milestone-status';
  }

  templateHelpers() {
    return {
      header: I18n.t('GuidedLearning.statusHeader.header'),
      nextMilestoneName: this._nextMilestoneName(),
      daysToNextMilestone: this._daysToNextMilestone(),
      paceMessage: this._createPaceMessage(),
      hidePaceMessage: !this._isOverdue() && !this._isOnPace() && this._isIncompletable() || this.getOption('isReadOnly')
    };
  }

  _nextMilestoneName() {
    return this.model.get('name') || '';
  }

  _daysToNextMilestone() {
    if (_.isNaN(Math.abs(this.model.get('timeRemaining')))) {
      return null;
    }

    const daysRemaining = Math.abs(this.model.get('timeRemaining'));

    if (this._isOverdue()) {
      if (daysRemaining === 1) {
        return I18n.t('GuidedLearning.statusHeader.behindHeaderSingular');
      }
      return I18n.t('GuidedLearning.statusHeader.behindHeader', {daysToNextIncompleteMilestone: daysRemaining});
    }

    switch (daysRemaining) {
      case 0:
        return I18n.t('GuidedLearning.statusHeader.dueToday');
      case 1:
        return I18n.t('GuidedLearning.statusHeader.daysAwayHeaderSingular');
      default:
        return I18n.t('GuidedLearning.statusHeader.daysAwayHeader', {daysRemaining: daysRemaining});
    }
  }

  _createPaceMessage() {
    if (!this.model.get('isEnrolled')) {
      return I18n.t('GuidedLearning.statusHeader.dueDateFromEnrollment', {daysAway: this.model.get('duration')});
    }

    if (this._isOverdue()) {
      return I18n.t('GuidedLearning.statusHeader.behindText');
    }

    if (this._isOnPace()) {
      return I18n.t('GuidedLearning.statusHeader.onTrackHeader');
    }

    const numItems = this._getNumberOfRemainingItems();

    if (numItems === 1) {
      return I18n.t('GuidedLearning.statusHeader.warningTextSingular');
    }

    return I18n.t('GuidedLearning.statusHeader.warningText', {items: numItems});
  }

  _isOverdue() {
    return this.model.get('timeRemaining') < 0;
  }

  _isOnPace() {
    const pace = this.getOption('stayOnTrackNumber') || 3;
    if (Math.abs(this.model.get('timeRemaining') <= pace)) {
      return false;
    }

    return true;
  }

  _getNumberOfRemainingItems() {
    return this.model.get('numItemsTotal') - this.model.get('numComplete');
  }

  _isIncompletable() {
    if (!this.options.objectives || !this.options.objectives.milestones.models.length) {
      return false;
    }

    const milestones = this.options.objectives.milestones.models;
    const latestMilestoneID = this.model.cid;
    const currentMilestone = milestones.find((MilestoneInterface) => {
      return MilestoneInterface.milestoneRowViewModel.cid === latestMilestoneID;
    });

    return currentMilestone ? !currentMilestone.isCompleteable() : true;
  }
}

module.exports = MilestonesStatusView;
