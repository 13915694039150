const I18n = require('@common/libs/I18n');

const {
  ActionBarType,
  createButtonType,
  createButtonWithTextAndIcon
} = require('@common/components/actionBarButton/ActionBarButtonFactory');

module.exports = (activitiesList) => {
  return (callback = () => {}) => {
    const currentActivity = activitiesList.getInProgressActivity() || {};
    const nextActivity = activitiesList.nextActivity((activity) => {
      return activity.id != currentActivity.id;
    });

    if (nextActivity == null) {
      return createButtonType(ActionBarType.COMPLETED_TRAINING, callback);
    } else if (nextActivity.isQuestionContentActivity()) {
      return createButtonWithTextAndIcon(I18n.t('training.takeQuiz'), 'icon-play', callback);
    }
    return createButtonType(ActionBarType.CONTINUE, callback);

  };
};
