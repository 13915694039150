const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');
const ObjectiveConnectorStatusCssClasses = require('@common/components/guidedLearning/objectives/ObjectiveConnectorStatusCssClasses');

const ariaLabelKeys = {
  done: 'GuidedLearning.icons.done',
  none: 'GuidedLearning.icons.none'
};

class LearningObjectiveProgressIndicatorView extends Marionette.ItemView {
  modelEvents() {
    return {
      change: 'render'
    };
  }

  className() {
    return 'learning-objectives__progress-wrapper';
  }

  getTemplate() {
    return require('./LearningObjectiveProgressIndicatorView.html');
  }

  templateHelpers() {
    const iconStatus = this.model.isComplete() ? 'done' : 'none';
    const iconType = this._getIconClassData();
    const previousStatus = this.model.getPreviousVisualConnectorStatus();
    const nextStatus = this.model.getNextVisualConnectorStatus();
    const ariaLabel = I18n.t(ariaLabelKeys[iconStatus]);

    return {
      iconType,
      previousConnectionCssClass: ObjectiveConnectorStatusCssClasses[previousStatus],
      nextConnectionCssClass: ObjectiveConnectorStatusCssClasses[nextStatus],
      ariaLabel
    };
  }

  _getIconClassData() {

    const isEventEnrolled = this.model.getType() === GuidedLearningObjectiveType.EVENT_OBJECTIVE && this.model.isEnrolled();

    const isEvaluation = this.model.getType() === GuidedLearningObjectiveType.EVALUATION_OBJECTIVE;
    const isEvaluationFailed = isEvaluation && this.model.isFailed();
    const isEvaluationPending = isEvaluation && this.model.isPending();

    if (this.model.isComplete()) {
      return 'learning-objectives__progress-indicator--complete';
    } else if (isEventEnrolled) {
      return 'learning-objectives__progress-indicator--event';
    } else if (isEvaluationFailed) {
      return 'learning-objectives__progress-indicator--failed';
    } else if (isEvaluationPending) {
      return 'learning-objectives__progress-indicator--event';
    }
    return '';
  }
}

module.exports = LearningObjectiveProgressIndicatorView;
