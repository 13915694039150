import { bigNumberFormatter } from '@common/libs/helpers/types/StringHelpers';
import {
  AxBox,
  AxButton
} from '@common/modules/react/themes/components';
import EmojiMap, { type EmojiKeyType } from '@common/modules/react/themes/components/EmojiReactionMap';
import { type CSSObject } from '@mui/system';
import type {
  MouseEventHandler,
  Ref
} from 'react';
import { forwardRef } from 'react';

import {
  styled,
  useTheme
} from '@mui/material';

export interface ReactionProps {
  /**
  *  If `true`, the reaction looks highlighted with an outline. This is meant to indicate that the user has expressed this reaction.
  *  @default false
  **/
  selected: boolean;
  /**
  *  One of the predefined keys, like "HAPPY" or "SAD" or "RAINBOW". Notice that setting the Control in Storybook you need to enclose the key in quotes; but when you're using the component in practice it's just a normal string without the quotes. It is case-sensitive, EmojiKeys are in all-caps.
  **/
  emojiKey: EmojiKeyType;
  /**
  *  The number that will be displayed in the reaction pill. This represents the count of reactions aggregated in this summary. If this is over 999 it will be shortened with a "K" suffix rounded to one decimal place.
  *  @default 0
  **/
  count: number;
  /**
  *  Size of the button.
  *  @default small
  **/
  size?: 'small' | 'medium' | 'large' | undefined;
  /**
  *  User's language. This is used as a locale to format the number, e.g. 'en' -> '1.2K', 'de' -> '1,2K', 'zh-hans-cn-u-nu-hanidec' -> '一.二k', 'ar-eg' -> '١٫٢k'.
  *  @default en
  **/
  locale: string;
  /**
  *  Since this is a button you likely want it to have an onclick action. The intent is that we do a POST or DELETE to add/remove the user's reaction.
  **/
  onClick?: MouseEventHandler;

  title?: string;

  disabled?: boolean;
}

const styledOptions = {
  name: 'Reaction',
  onclick,
  shouldForwardProp: (prop: string) => {
    // This callback determines whether a prop becomes an HTML attribute.
    // By returning false for some props, we avoid the HTML element having
    // <Button emojiKey="HAPPY" count="5">. Other attributes added to the
    // component do forward through as HTML attributes.
    return prop !== 'emojiKey' && prop !== 'count';
  }
};

const StyledReaction = styled(AxButton, styledOptions)<ReactionProps>(({
  selected,
  theme
}) => {
  const defaultStyles: CSSObject = {
    fontSize: theme.uiKit.fontSizeXS,
    background: selected ? theme.uiKit.colorGrey30 : theme.uiKit.colorGrey20,
    borderColor: selected ? theme.uiKit.colorBlue60 : theme.uiKit.colorGrey20,
    borderStyle: 'solid',
    borderRadius: '99rem', // round sides, flat top.
    marginRight: theme.uiKit.spacingXS,
    paddingTop: '0',
    paddingBottom: '0',
    paddingLeft: theme.uiKit.spacingXS,
    paddingRight: theme.uiKit.spacingXS
  };

  return defaultStyles;
});

export const Reaction = forwardRef(({
  selected = false,
  locale = 'en',
  size = 'small',
  onClick,
  ...otherProps
}: ReactionProps, ref: Ref<HTMLButtonElement>): JSX.Element => {
  const props = {
    selected,
    locale,
    size,
    onClick,
    ...otherProps
  };

  const theme = useTheme();

  const glyph = EmojiMap[props.emojiKey];

  return (
    <StyledReaction { ...props } ref={ ref }>
      <AxBox
        component='span'
      >
        { glyph }
      </AxBox>
      <AxBox
        component='span'
        sx={{
          marginLeft: theme.uiKit.spacingXS
        }}
      >
        { bigNumberFormatter(props.count, locale) }
      </AxBox>
    </StyledReaction>
  );
});

export default Reaction;
