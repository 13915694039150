const {
  triggerResize
} = require('@common/libs/helpers/app/BrowserHelpers');

const _ = require('underscore');
const logging = require('logging');

const Marionette = require('Marionette');

const Sync = require('@common/libs/Sync');

const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

const User = require('@common/data/models/AuthUser');

const LearningEventDetailSessionView = require('@training/apps/training/views/LearningEventDetailSessionView');
const UserView = require('@training/apps/training/views/UserView');
const LearningEventDetailsNoticeView = require('@training/apps/training/views/LearningEventDetailsNoticeView');

class ScheduledEventDetailsView extends Marionette.LayoutView {
  templateHelpers() {
    const localizableName = this.model.getLearningEventModel().get('localizableName');
    const localizableDescription = this.model.getLearningEventModel().get('localizableDescription');
    const currentUserLanguage = window.apps.auth.session.user.get('language');

    let enrollmentDescription = localizableDescription.getValueForLanguage(currentUserLanguage);
    enrollmentDescription = HTMLHelpers.lineBreakToBr(_.escape(enrollmentDescription));

    return {
      enrollmentTitle: localizableName.getValueForLanguage(currentUserLanguage),
      enrollmentDescription,
      enrollmentLink: {
        hasEnrollmentLink: this.model.hasEventUrl(),
        enrollmentLinkAddress: this.model.get('eventUrl')
      }
    };
  }

  getTemplate() {
    return require('@training/apps/training/templates/_learning_event_detail_details.html');
  }

  className() {
    return 'learning-events-details-page';
  }

  regions() {
    return {
      sessionsList: '.learningevent-sessionslist',
      instructor: '.instructor-container',
      eventNotice: '.learningevent-notice'
    };
  }

  modelEvents() {
    return {
      'enrollment:updated': 'toggleFullNoticeDisplay'
    };
  }

  initialize(options = {}) {
    ({
      learningEventSessions: this.learningEventSessions,
      enrollment: this.enrollment
    } = options);
  }

  onRender() {
    this._renderSessions();
    this._renderInstructor();
    this.toggleFullNoticeDisplay();
  }

  onClose() {
    super.onClose();

    this.learningEventSessions.abortXHR(Sync.Method.READ);
  }

  viewDidAppear() {
    logging.info('LearningEventDetailsView - viewDidAppear');
    triggerResize(true);
  }

  _renderSessions() {
    const sessionView = new Marionette.CollectionView({
      collection: this.learningEventSessions,
      childView: LearningEventDetailSessionView,
      collectionEvents: {
        'update reset': triggerResize.bind(null, true)
      }
    });

    this.getRegion('sessionsList').show(sessionView);
  }

  _renderInstructor() {
    const instructor = new User(this.model.get('instructor'));
    const instructorView = new UserView({
      model: instructor
    });

    this.getRegion('instructor').show(instructorView);
  }

  toggleFullNoticeDisplay() {
    if (this.model.isFull()) {
      const fullNoticeView = new LearningEventDetailsNoticeView({
        model: this.model,
        isEnrolled: this.enrollment.isEnrolled()
      });

      this.getRegion('eventNotice').show(fullNoticeView);
    } else {
      this.getRegion('eventNotice').reset();
    }

    triggerResize(true);
  }
}

module.exports = ScheduledEventDetailsView;
