const _ = require('underscore');
const Marionette = require('Marionette');

const AttachmentItemView = require('@common/components/media/AttachmentItemView');

const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');

class TaskDetailAttachmentListView extends Marionette.CollectionView {
  getChildView() {
    return AttachmentItemView;
  }

  buildChildView(taskMediaAttachmentDto, ChildViewClass, childViewOptions = {}) {
    const model = MediaFactory.createMediaFromTaskMediaAttachmentDto(FileFactory, taskMediaAttachmentDto, {
      documentsAllowed: true
    });
    const options = _.extend({}, childViewOptions, {
      model
    });

    const view = new ChildViewClass(options);
    return view;
  }

  className() {
    return 'task-detail-attachments';
  }
}

module.exports = TaskDetailAttachmentListView;
