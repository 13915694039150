import {
  Fade,
  Tooltip as MuiTooltip,
  styled,
  useTheme,
  type TooltipProps as MuiTooltipProps
} from '@mui/material';
import { forwardRef } from 'react';

export type AxTooltipProps = Omit<MuiTooltipProps, 'TransitionComponent' | 'TransitionProps'>;
type StyledTooltipProps = MuiTooltipProps;

const styledOptions = {
  name: 'AxTooltip'
};

const StyledAxTooltip = styled(MuiTooltip, styledOptions)<StyledTooltipProps>(() => {
  /**
   * Due to a MUI issue, we cannot style the tooltip using CSS classes.
   * We must use componentsProps to style the tooltip.
   * https://github.com/mui/material-ui/issues/28679
   */
  return {};
});

/**
 * Wraps the MUI Tooltip component to provide a consistent look and feel.
 *
 * ### Links
 * - [Tooltip | MUI](https://mui.com/material-ui/react-tooltip/)
 * - [UIKit Figma](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=11158-3957&m=dev)
 *
 * @example
 * <AxTooltip title='The text should be centered and should center below the icon and arrow'>
 *  <AxIconButton variant='ghost'>
 *   <DeleteIcon/>
 * </AxIconButton>
 * </AxTooltip>
 */
export const AxTooltip = forwardRef<unknown, AxTooltipProps>((props, ref) => {
  const theme = useTheme();

  const mergedComponentsProps = {
    tooltip: {
      sx: {
        textAlign: theme.uiKit.tooltip.textAlign,
        fontSize: theme.uiKit.tooltip.fontSize,
        lineHeight: theme.uiKit.tooltip.lineHeight,
        backgroundColor: theme.uiKit.tooltip.backgroundColor,
        padding: theme.uiKit.tooltip.padding
      }
    },
    arrow: {
      sx: {
        color: theme.uiKit.tooltip.backgroundColor
      }
    },
    ...props.componentsProps
  };

  return (
    <StyledAxTooltip
      ref={ ref }
      arrow
      componentsProps={ mergedComponentsProps }
      TransitionComponent={ Fade }
      { ...props }
    />
  );
});

export default AxTooltip;
