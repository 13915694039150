const $os = require('detectOS');

const AxonifyObject = require('@common/libs/AxonifyObject');

const ZoomImageModalView = require('@common/components/image/zoom/ZoomImageModalView');
const MobileZoomImageModal = require('@common/components/image/zoom/MobileZoomImageModalView');

const { extendLightboxOptionsWithAndroidBackDismiss } = require('@common/libs/helpers/app/NativeBridgeHelpers');
const { sendErrorLog } = require('LoggingService');

require('jquery.lightbox_me');

class ImageZoomController extends AxonifyObject {

  constructor(...args) {
    super(...args);
    this.initialize = this.initialize.bind(this);
    this.showImageZoomView = this.showImageZoomView.bind(this);
    this._getMobileDisplayOptions = this._getMobileDisplayOptions.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  initialize(options = {}) {
    (({
      zoomOptions: this.zoomOptions
    } = options));
    this.modalDefaults = options.extendedModalOptions;
  }

  showImageZoomView(media) {
    if ($os.mobile) {
      this.zoomView = new MobileZoomImageModal({
        media
      });
    } else {
      this.zoomView = new ZoomImageModalView({
        media
      });
    }

    let options = {
      default: true,
      zIndex: this.zoomOptions.zIndex,
      zoomContainer: this.zoomOptions.container,
      onLoad: () => {
        this.zoomView.triggerMethod('show');
      },
      onClose: () => {
        this.zoomView.destroy();
      }
    };

    options = $.extend(true, { sendErrorLog }, this.modalDefaults, options);

    if ($os.mobile) {
      options.container = options.container || '#root-view';
      options.modalCSS = $.extend(true, {}, options.modalCSS, this._getMobileDisplayOptions());
    }

    this.listenTo(this.zoomView, 'image:loaded', $(window).resize);

    options = extendLightboxOptionsWithAndroidBackDismiss(this.zoomView, options);

    this.zoomView.render().$el.lightbox_me(options);
  }

  _getMobileDisplayOptions() {
    return {
      left: 0,
      marginLeft: 0,
      width: '100%',
      height: $(window).height()
    };
  }

  closeModal() {
    return (this.zoomView != null ? this.zoomView.$el.trigger('close') : undefined);
  }
}

module.exports = ImageZoomController;
