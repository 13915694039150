const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const Form = require('@common/components/forms/Form');

require('@common/components/forms/editors/checkboxes/Form.Editor.Checkboxes');

class TagGroupModal extends AccessibleModalView {
  constructor(options) {
    super(options);

    this.onClickApply = this.onClickApply.bind(this);
    this.onClickClearAll = this.onClickClearAll.bind(this);

    ({
      applyCallback: this.applyCallback,
      clearCallback: this.clearCallback,
      name: this.name
    } = options);

    this.initialTags = this.model.get('selectedTags');
  }

  id() {
    return 'modalview';
  }

  className() {
    return 'modal tag-group-modal';
  }

  ui() {
    return Object.assign({}, super.ui(), {
      form: '.js-form',
      applyButton: '.js-tag-group__apply-button',
      clearAllButton: '.js-tag-group__clear-all-button'
    });
  }

  events() {
    return Object.assign({}, super.events(), {
      'click .axon-button.cancel': 'dismiss'
    });
  }

  modelEvents() {
    return {
      'change:selectedTags': 'onSelectedTagsChange'
    };
  }

  templateHelpers() {
    return {
      name: this.name
    };
  }

  onRender() {
    super.onRender();

    this._toggleButtonStates(this.model.get('selectedTags'));
  }

  onRenderContent() {
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        selectedTags: {
          collection: this.collection
        }
      }
    });
  }

  onRenderActionBar() {
    this.actionBar.setActionBar({
      buttons: [
        {
          type: 'customText',
          text: I18n.t('general.clearAll'),
          onClick: this.onClickClearAll,
          className: 'js-tag-group__clear-all-button'
        },
        {
          type: 'customText',
          text: I18n.t('general.apply'),
          onClick: this.onClickApply,
          className: 'js-tag-group__apply-button tag-group__apply-button'
        }
      ]
    });
  }

  contentTemplate() {
    const templateFn = _.tpl(require('@training/apps/search/tagGroupFilter/TagGroupModalTemplate.html'));
    return templateFn({
      name: this.name
    });
  }

  onSelectedTagsChange(model, selectedTags) {
    this._toggleButtonStates(selectedTags);
  }

  onClickApply() {
    this.applyCallback();
    this.dismiss();
  }

  onClickClearAll() {
    this.clearCallback();
    this.applyCallback();
    this.dismiss();
  }

  _hasSelectedTagChanges(selectedTags) {
    const selTagsSet = new Set(selectedTags);
    const initTagsSet = new Set(this.initialTags);

    return !_.isEqual(Array.from(selTagsSet), Array.from(initTagsSet));
  }

  _toggleButtonStates(selectedTags = []) {
    const hasNoSelectedTags = selectedTags.length === 0;
    const hasNoSelectedTagChanges = !this._hasSelectedTagChanges(selectedTags);

    this.ui.clearAllButton.toggleClass('disabled', hasNoSelectedTags);
    this.ui.applyButton.toggleClass('disabled', hasNoSelectedTagChanges);
  }
}

module.exports = TagGroupModal;
