// Module
var code = `<div>
  <div class="title-wrapper">
    <div class="title-block">
      <div class="recovery-icon icon-envelope_alt"></div>
      <div class="recovery-title-wrapper">
        <div class="recovery-title"><%- t('login.forgotPassword.emailSent.title') %></div>
        <div><%- t('login.forgotPassword.emailSent.subTitle') %></div>
      </div>
    </div>
  </div>
  <div class="recovery-success-message"><%= successMessage %></div>
  <div class="recovery-success-message"><%- t('login.forgotPassword.emailSent.successBody') %></div>
</div>
`;
// Exports
module.exports = code;