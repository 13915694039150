const I18n = require('@common/libs/I18n');
const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  MultipleChoice: 0,
  AdvancedMultipleChoice: 1,
  MultipleAnswer: 3,
  Matching: 4,
  JumbledSentence: 5,
  Hotspot: 7
}, {
  getQuestionTypeString(variantType) {
    switch (variantType) {
      case this.MultipleChoice:
        return I18n.t('question.multi');
      case this.AdvancedMultipleChoice:
        return I18n.t('question.advMulti');
      case this.MultipleAnswer:
        return I18n.t('question.multiAnswer');
      case this.JumbledSentence:
        return I18n.t('question.fill');
      case this.Hotspot:
        return I18n.t('question.hotspot');
      case this.Matching:
        return I18n.t('question.matching');
      default:
        throw new Error('A correct question type must be provided');
    }
  }
});
