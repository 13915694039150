// Module
var code = `<h1 class="page-header">
  <div class="ax-container">
    <div class="page-header__content">
      <span class="page-header__title"><%- msgTypeTitle %></span>
    </div>
  </div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <% if (hasSender) {%>
    <h2 class="from">
      <span class="label"><%- t('usermessage.fromLabel') %> </span><span><%- senderFullName %></span>
    </h2>
    <% } %>

    <div class="user-messaging" role="article">
      <div class="broadcastmessage <%- subType %>">
        <div class="broadcast">
          <pre><p><%- text %></p></pre>
        </div>
        <% if (hasMedia) { %>
          <div class="media">
          </div>
        <% } else if (hasVideo) { %>
          <div class="video">
            <div class="videowrapper vid"></div>
          </div>
        <% } %>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;