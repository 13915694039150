// Module
var code = `<div class="js-tag-group tag-group <%= tagGroupSelected %>">

  <button type="button" class="tag js-modal-launch tag-group__modal-launch <%- modalLaunchActiveClass %>"><%- name %><%- modalLaunchActiveCount %> <span class="tag-group__modal-launch-icon icon-chevron_down"></span></button>
  <div class="tag-group__desktop-view js-tag-group-desktop">
    <div class="js-tag-group__header tag-group__header ax-grid">
      <p class="js-tag-group-min-title tag-group__title" aria-hidden="true"><%- name %></p>
      <p class="js-tag-group-open-title qa-tag-group-open-title tag-group__title"><%- name %></p>
      <span class="ax-grid__col--auto-size js-toggle-expand-icon toggle-expand-icon <%= chevronStyle %>"></span>
      </div>
    <div class="js-tag-group__contents"><form class="js-form scrollable-tag-group scrollable auto vertical">
      <ul class="tag-group__checkboxes" data-options="selectedTags" data-field="selectedTags" data-editor="Checkboxes" data-shallow></ul>
      </form>
      <div class="tag-group__buttons  ax-grid__col--12 ax-grid__col--m-auto-size ax-grid ax-grid--no-gutter ax-grid--align-items-center ax-grid--justify-content-center">
        <button type="button" class="ax-button ax-button--branded js-tag-group__apply-button tag-group__apply-button ax-grid__col--auto-size">
          <%- t('general.apply') %>
        </button>
        <button type="button" class="ax-button js-tag-group__clear-all-button tag-group__clear-all-button ax-grid__col--auto-size">
          <%- t('general.clearAll') %>
        </button>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;