const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  ARTICLE: {
    serverId: 'ARTICLE',
    string: 'discover.dzArticleTypes.article'
  },
  POST: {
    serverId: 'POST',
    string: 'discover.insights.pageType.post'
  },
  QUESTION: {
    serverId: 'QUESTION',
    string: 'discover.dzArticleTypes.question'
  },
  REFERENCE: {
    serverId: 'REFERENCE',
    string: 'discover.insights.pageType.link'
  },
  TRAINING_ARTICLE: {
    serverId: 'TRAINING',
    string: 'discover.dzArticleTypes.training'
  }
});
