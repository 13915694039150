// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-rewards" aria-hidden="true"></span>
      <span class="page-header__title js-title"></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <ul class="subtabs rewards-tabs">
      <li data-tab="auction" class="selected"><a href="#" class="auction selected" aria-label="<%- t('auction.auction')%>"><span class="tab-icon icon-legal"></span><span class="tab-label"><%- t('auction.auction')%></span></a></li>
      <li data-tab="shop"><a href="#" class="shop" aria-label="<%- t('auction.shop')%>"><span class="tab-icon icon-shopping_cart"></span><span class="tab-label"><%- t('auction.shop')%></span></a></li>
      <li data-tab="raffle"><a href="#" class="raffle" aria-label="<%- t('auction.raffle')%>"><span class="tab-icon icon-ticket"></span><span class="tab-label"><%- t('auction.raffle')%></span></a></li>
      <li data-tab="spinToWin"><a href="#" class="slots" aria-label="<%- t('auction.spinToWin')%>"><span class="tab-icon icon-slots"></span><span class="tab-label"><%- t('auction.spinToWin')%></span></a></li>
    </ul>

    <div class="clear"></div>

    <div role="heading" aria-level="2" class="highlights clearfix points-balance">
      <div class="highlight subhighlight">
        <div class="wrap clearfix">
          <div class="score ax-font ax-font__display ax-font--no-margin"></div>
          <div class="tag title ax-font ax-font__caption ax-font--no-margin ax-font--secondary"><%- t('auction.pointBalance') %></div>
        </div>
      </div>
    </div>

    <div class="contentarea" data-tab="auction"></div>
    <div class="contentarea pad" data-tab="shop"></div>
    <div class="contentarea" data-tab="raffle"></div>
    <div class="contentarea" data-tab="spinToWin"></div>
    <div class="no-rewards clear"></div>
  </div>
</div>


`;
// Exports
module.exports = code;