// Module
var code = `<td class="table-layout__col1">
  <% if(!processing) { 
      qaClassName = 'qa-checkbox-' + languageName.toLowerCase().replace(/[^A-Za-z0-9!?]/g,'');
    %>
    <%= axCheckbox({ className: 'js-publish-checkbox', checked: checked, disabled: checked === null, label: languageName, classNameInput: qaClassName }) %>
  <% } else { %>
    <span><%- languageName %></span>
  <% } %>
</td>
<td class="table-layout__col2">
  <span class="js-article-status-<%- language %>"><%= formattedStatus %></span>
</td>
`;
// Exports
module.exports = code;