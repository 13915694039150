const LoadingController = require('@common/components/loading/LoadingController');

const { getEventsCardControllerDefinition } = require('@training/apps/home/hubPreviews/events/EventsCardControllerDefinitionFactory');

module.exports = (collection, click) => {
  return () => {
    collection.fetch({ showSpinner: false });

    const contentControllerDefinition = getEventsCardControllerDefinition({
      eventsCollection: collection,
      callback: click
    });

    return {
      ViewControllerClass: LoadingController.Wrapper,
      syncTracker: {
        entities: collection,
        loadOnSync: true
      },
      viewDefinition: {
        spinner: false
      },
      contentControllerDefinition
    };
  };
};
