const { LayoutView } = require('Marionette');
const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const InsightsUrlHelper = require('@training/apps/insights/InsightsUrlHelper');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const ReactionsController = require('@training/apps/search/ReactionsController');
const UserProfileIcon = require('@common/modules/main/header/userProfileIcon/UserProfileIcon');
const ArticleDetailsCommentRegionController = require('@training/apps/articles/ArticleDetailsCommentRegionController');
const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');
const dateHelpers = require('@common/libs/dateHelpers');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const TimelineCardContentView = require('@training/apps/timeline/TimelineCardContentView');
const PostCardContentView = require('@training/apps/timeline/PostCardContentView');
const Community = require('@common/data/models/Community');

const PostCreateModal = require('@training/apps/timeline/PostCreateModal');
const CreatePostAccessibleModalView = require('@training/apps/timeline/CreatePostAccessibleModalView');

const Page = require('@common/components/discover/models/Page');
const PageType = require('@common/components/discover/enums/PageType');
const PageFactory = require('@common/components/discover/factories/PageFactory');

const MediaFactory = require('@common/libs/file/MediaFactory');
const FileFactory = require('@common/libs/file/FileFactory');

const KeyCode = require('@common/data/enums/KeyCode');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');
const { persistTimelinePageData } = require('@training/apps/timeline/helpers/TimelineLocalStorageHelpers');

const {
  getLastEditorViewDefinition,
  getLastModifiedDateViewDefinition,
  getLastModifiedAndCommunityViewDefinition
} = require('@common/components/cardMetadata/MetadataStringHelper');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/impressionTracker/ImpressionTracker');

const SIZE_ARRAY_LENGTH = 4;

class TimelineCardViewController extends LayoutController {
  constructor(options = {}) {
    super(options);

    ({
      model: this.model,
      source: this.source,
      itemViewOptions: this.itemViewOptions = {},
      showComments: this.showComments,
      showFullContent: this.showFullContent
    } = options);

    this.showCommunityIfPossible = !this.getOption('hideCommunityOnCard') // optional argument
     && this.model.get('homeCommunityName') != null;

    this.modelCommunities = this.model.get('communities');
    this.communityId = this.model.get('homeCommunityId') || (this.modelCommunities && this.modelCommunities[0].community.id);

    const queryParams = UrlHelpers.getQueryParams(UrlHelpers.getQueryString(window.location.hash));
    this.fromDiscover = (queryParams.fromDiscover === 'true');

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  _getTemplate() {
    return `
      <button class="base-card__view-all ax-button ax-button--m off-screen" aria-label="${ this.model.getAriaTitle() }">
        <span><%- t('selfDirected.search.viewDetails') %></span>
      </button>
      <div class="header ax-grid ax-grid--no-gutter">
        <div class="profile-icon-region ax-grid__col--auto-size" aria-hidden="true"></div>
        <div class="author-info-right">
          <div class="metadata-region"></div>
          <div class="action-menu-region"></div>
        </div>
      </div>
      <div class="content-region"></div>
      <div class="reactions-region"></div>
      <div class="comments-region"></div>
    `;
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: this._getTemplate(),
      className: 'timeline-card qa-timeline-card card card--interactive',
      regions: {
        profileIconRegion: '.profile-icon-region',
        metadataRegion: '.metadata-region',
        contentRegion: '.content-region',
        reactionsRegion: '.reactions-region',
        commentsRegion: '.comments-region',
        actionMenuRegion: '.action-menu-region'
      },
      behaviors: {
        Resizable: {}
      },
      events: {
        click: this._onClickCard.bind(this),
        keypress: (e) => {
          e.stopPropagation();
          if (!$(e.target).is('button, a') && (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE)) {
            this._onClickCard();
          }
        }
      }
    };
  }

  /**
   * So there's no confusion here: there is a difference between being "enabled" and being "shown".
   * The reaction region could have comments enabled, but not be showing them (as in a card summary),
   * or the region could be showing them (like a post detail view) but not have them enabled (on the community).
   * Whatever the combination, there are certain things that shouldn't happen unless BOTH are true.
   */
  commentsEnabledAndShowing() {
    return this.showComments && this.areCommentsEnabledForThisCommunity();
  }

  shouldShowFavorites() {
    // posts are the only ones that shouldn't show faves
    return this.model.getType() !== PageType.POST;
  }

  areCommentsEnabledForThisCommunity() {
    return this.model.get('isCommentable');
  }

  regionControllers() {
    const regionControllers = {
      profileIconRegion: {
        viewDefinition: {
          ViewClass: UserProfileIcon,
          model: this._getUserProfileModel()
        }
      },
      metadataRegion: {
        viewDefinition: {
          ViewClass: StackedCollectionView,
          className: 'stacked-collection-view',
          viewConfigs: [
            this._getAuthorViewDefinition(),
            this._getCommunityAndDateViewDefinition()
          ]
        }
      },
      contentRegion: {
        viewDefinition: {
          ViewClass: this.model.getType() === PageType.POST ? PostCardContentView : TimelineCardContentView,
          model: this.model,
          showFullContent: this.showFullContent,
          searchPageState: this.itemViewOptions.searchPageState,
          behaviors: {
            ImpressionTracker: {
              objectId: this.model.get('pageId') ?? this.model.id,
              objectType: 'PAGE',
              source: this.source
            }
          }
        },
        delegateEvents: {
          'view:image:clicked': this._showMedia
        }
      },
      reactionsRegion: !this.itemViewOptions.showReactions ? {} : {
        ViewControllerClass: ReactionsController,
        favoriteModel: this.model.clone(),
        reactionsModel: this.model.clone(),
        showFavorites: this.shouldShowFavorites(),
        showComments: this.commentsEnabledAndShowing(), // reaction bar needs this, to know if the Comment button should scroll to a form
        itemViewOptions: {
          timelineId: this.itemViewOptions.timelineId,
          timelineTab: this.itemViewOptions.timelineTab,
          preserveTimelineScrollPosition: this.itemViewOptions.preserveTimelineScrollPosition,
          searchPageState: this.itemViewOptions.searchPageState,
          onReactToItem: this.itemViewOptions.onReactToItem,
          onFavoriteItem: this.itemViewOptions.onFavoriteItem
        },
        // If there is a better way of determing whether you're on an article details page vs a search result page, please
        // adjust this to suit!
        showReactionsModal: Boolean(this.model.get('id')),
        delegateEvents: {
          'view:scroll:comments': this.scrollToFormInput.bind(this)
        }
      }
    };

    if (this.commentsEnabledAndShowing()) {
      regionControllers.commentsRegion = {
        ViewControllerClass: ArticleDetailsCommentRegionController,
        articleData: this.model.clone(),
        showComments: true
      };
    }

    const actions = this.getEllipsisMenuActions();
    if (actions.length > 0) {
      regionControllers.actionMenuRegion = {
        viewDefinition: {
          ViewClass: MenuDropdown,
          buttonConfig: {
            buttonIcon: 'icon-ellipsis_horizontal',
            buttonAriaLabel: 'discover.posts.actions.accessibility.label',
            buttonAriaLabelContext: {
              datestamp: dateHelpers.timeFromEvent(this.model.get('createDateMillis'))
            },
            popupAlignment: MenuDropdownPositionEnum.RIGHT + MenuDropdownPositionEnum.BOTTOM
          },
          optionsListConfig: actions
        }
      };
    }

    return regionControllers;
  }

  getEllipsisMenuActions() {
    const actions = [
      {
        buttonText: 'discover.insights.title',
        buttonClass: 'page-insights-action',
        buttonIcon: 'icon-metrics',
        buttonAriaLabel: 'discover.posts.actions.edit.button.label',
        clickCallback: this.clickInsights.bind(this)
      }
    ];

    if (this.model.getType() !== PageType.POST) {
      return actions;
    }

    if (this._mayEditPost()) {
      actions.unshift({
        buttonText: 'discover.posts.actions.edit.button.label',
        buttonClass: 'comment-edit-action',
        buttonIcon: 'icon-pencil',
        buttonAriaLabel: 'discover.posts.actions.edit.button.label',
        clickCallback: this.clickEdit.bind(this)
      });
    }

    if (this._mayDeletePost()) {
      actions.push({
        buttonText: 'discover.posts.actions.delete.button.label',
        buttonClass: 'comment-delete-action',
        buttonIcon: 'icon-trash',
        buttonAriaLabel: 'discover.posts.actions.delete.button.label',
        clickCallback: this._clickDelete.bind(this)
      });
    }

    return actions;
  }

  clickInsights() {
    this.persistTimelineData();

    const pageId = this.model.get('pageId') || this.model.getId();
    Backbone.history.navigate(`${ InsightsUrlHelper.getPageInsightsUrl(pageId) }`, {
      trigger: true
    });
  }

  clickEdit() {
    const pageId = this.model.get('pageId') || this.model.getId();

    const modalView = new CreatePostAccessibleModalView({
      id: 'modalview',
      className: 'modal create-post-modal'
    });

    const pageType = PageType.POST;
    const page = new Page({
      id: pageId
    });
    page.set('type', pageType);

    return Promise.resolve(page.fetch().then( () => {
      const userLanguage = window.apps.auth.session.user.get('language');

      const pageFactory = new PageFactory();
      const typedPage = pageFactory.getTypedPage(page);

      typedPage.set('language', userLanguage);

      const modalChildView = new PostCreateModal({
        typedPage,
        isEditing: true
      });

      window.app.layout.presentModal(modalView, { closeClick: false });
      modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

      this.listenTo(modalChildView, 'post:saved', () => {
        this.itemViewOptions.onSaveEdit?.();
        if (this.itemViewOptions.searchPageState.get('forceFetchPost')
          || this.itemViewOptions.searchPageState.isEmpty()) {
          this.model.fetch().then( () => {
            const metaDataView = this.findControllerByRegion('metadataRegion').getView();
            metaDataView.resetViewConfigs([
              this._getAuthorViewDefinition(),
              this._getCommunityAndDateViewDefinition()
            ]);
          });
        } else {
          const collectionList = this.itemViewOptions.searchPageState.get('results');
          for (const collection in collectionList) {
            if (collectionList[collection]) {
              collectionList[collection].fetch();
            }
          }
        }
      });
    },
    (xhr) => {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      const errCode = exception.getErrorCode();

      if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) { // aka 3001
        xhr.skipGlobalHandler = true;
        window.app.layout.flash.error(I18n.t(`discover.pageTypes.${ this.model.get('type') }.error.${ errCode }`));
        window.app.layout.dismissModal();
        Backbone.history.navigate('#hub/timeline', true);
      }

      if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
        xhr.skipGlobalHandler = true;
        window.app.layout.flash.error(I18n.t('discover.pageAccess.error.3017'));
        window.app.layout.dismissModal();
      }

      if (errCode === AxonifyExceptionCode.CLIENT_ERROR_LANGUAGE_NOT_SUPPORTED) {
        xhr.skipGlobalHandler = true;
        window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.error.3134'));
        window.app.layout.dismissModal();

        Backbone.history.navigate('#hub/timeline', true);
      }
    }));
  }

  onLockError(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();

    if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.pageAccess.error.3017'));

    // page has been locked by someone else, so no need for oops screen
    // so skip global handler and go to page view
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_OBJECT_ALREADY_LOCKED || errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t(`discover.pageTypes.${ this.model.get('type') }.error.${ errCode }`));

      window.app.layout.dismissModal();
    }
  }

  _clickDelete() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s'
    });
    const modalChildView = new ConfirmDialogView({
      confirmCallback: this._deletePost.bind(this),
      model: this.model,
      title: I18n.t('discover.posts.delete.modal.title'),
      confirmationText: I18n.t('discover.posts.delete.modal.confirmation'),
      buttonText: I18n.t('general.delete'),
      iconClass: 'icon-trash red'
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _onDeletePostSuccess() {
    window.app.layout.flash.success(I18n.t('messages.PostModel.DELETE.success'));

    const isBrowsingCommunity = this.itemViewOptions.isBrowsingCommunity || this.fromDiscover;

    // Is deleting in Discover or Timeline, respectively
    if (isBrowsingCommunity) {
      Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ this.communityId }`, {
        trigger: true,
        replace: true
      });
    } else {
      this.itemViewOptions.onDeleteItem?.(this.model.id);

      Backbone.history.navigate('#hub/timeline', {
        trigger: true,
        replace: true
      });
    }
  }

  _onDeletePostError(model, response) {
    const isBrowsingCommunity = this.itemViewOptions.isBrowsingCommunity || this.fromDiscover;

    const exception = AxonifyExceptionFactory.fromResponse(response);
    if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      window.app.layout.flash.error(I18n.t('messages.PostModel.DELETE.3001'));

      if (isBrowsingCommunity) {
        Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ this.communityId }`, {
          trigger: true,
          replace: true
        });
      } else {
        Backbone.history.navigate('#hub/timeline', {
          trigger: true,
          replace: true
        });
      }
    } else {
      window.app.layout.flash.error(I18n.t('messages.PostModel.DELETE.error'));
    }
  }

  _deletePost() {
    // In the timeline view, the id attribute is returned as "pageId", but in the Post Detail, it's returned as
    // "id". Normally when the id attribute is non-standard we could define a "get idAttribute()" method on the model
    // to override it, but that causes whack-a-mole game between the two views. Instead, we will populate the "id" if
    // it's missing.
    if (!this.model.get('id')) {
      this.model.set('id', this.model.get('pageId'), {silent: true});
    }

    this.model.destroy({
      wait: true,
      dataType: 'text', // this prevents the system from trying to parse an empty string as JSON.
      sender: this.model,
      skipGlobalHandler: true,
      success: this._onDeletePostSuccess.bind(this),
      error: this._onDeletePostError.bind(this)
    });
  }


  scrollToFormInput() {
    this.findControllerByRegion('commentsRegion').scrollToCommentForm();
  }

  _onClickCard() {
    this.persistTimelineData();

    const encodedPageId = encodeURIComponent(this.model.get('pageId'));
    const searchString = encodeURIComponent(this.itemViewOptions.searchPageState.get('searchString'));
    const isBrowsingCommunity = Boolean(this.itemViewOptions.isBrowsingCommunity);

    Backbone.history.navigate(
      `${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ encodedPageId }?query=${ searchString }&fromDiscover=${ isBrowsingCommunity }`,
      { trigger: true }
    );
  }

  persistTimelineData() {
    const {
      timelineId,
      timelineTab,
      searchPageState,
      preserveTimelineScrollPosition
    } = this.itemViewOptions;

    let searchQuery;
    if (searchPageState?.get('searchString') != null) {
      searchQuery = encodeURIComponent(searchPageState.get('searchString'));
    }

    // Don't persist the page data if you're not drilling in from the timeline
    if (preserveTimelineScrollPosition) {
      persistTimelinePageData(timelineId, timelineTab, searchQuery, window.apps.auth.session.user.get('id'));
    }
  }

  /**
   * A simple grab of the Author ID from our two different data schemas, for when we are merely comparing the author
   * ID and don't need a full model like what's returned from _getUserProfileModel
   */
  _getAuthorId() {
    return this.model.get('authorUserId') || this.model.get('author').user.id;
  }

  /**
   * returns true if the user is allowed to Delete
   */
  _mayDeletePost() {
    return this.model.canDelete();
  }

  /**
   * returns true if the user is allowed to Edit
   */
  _mayEditPost() {
    return this.model.canEdit();
  }

  _getUserProfileModel() {
    if (this.model.get('author') && this.model.get('author').user) {
      // the model is a full post object containing an author
      return new Backbone.Model(this.model.get('author').user);
    }

    // if not, then the model is probably a timeline summary with an incomplete schema
    const userModel = new Backbone.Model({
      salutationName: this.model.get('authorName'),
      id: this.model.get('authorUserId'),
      profileImage: this.model.get('authorImage')
    });

    return userModel;
  }

  _buildSizesArray() {
    const sizeObject = { status: 'done' };
    const array = [];

    for (let i = 0; i < SIZE_ARRAY_LENGTH; i++) {
      // Use large icon path for retina screens. See ProfileIconHandler
      const path = i === 3 ? this.model.get('largeIconPath') : this.model.get('smallIconPath');
      Object.assign(sizeObject, {
        file: { path }
      });
      array.push(sizeObject);
    }

    return array;
  }

  _getAuthorViewDefinition() {
    if (this.itemViewOptions.showAuthor || this.model.getType() === PageType.POST) {
      return getLastEditorViewDefinition(this.model.get('authorName'));
    }

    return undefined;
  }

  getHomeCommunityName() {
    const currentUserLanguage = window.apps.auth.session.user.get('language');
    const localizableName = new LocalizableString(this.model.get('homeCommunityName'));
    return localizableName.getValueForLanguage(currentUserLanguage);
  }

  _getCommunityAndDateViewDefinition() {
    let communityName;
    let communityHref;

    if (this.showCommunityIfPossible) {
      // then our community is a model within a collection
      communityName = this.getHomeCommunityName();
      communityHref = `#hub/search/community-${ this.model.get('homeCommunityId') }`;

    } else if (this.model.get('communities')) {
      // community is a collection of properties within a single model attribute
      const community = new Community(this.model.get('communities')[0].community);
      communityName = community.getName();
      communityHref = `#hub/search/community-${ community.id }`;
    }

    if (communityName != null) {
      return getLastModifiedAndCommunityViewDefinition({
        name: communityName,
        href: communityHref,
        onClick: () => {
          this.persistTimelineData();
          Backbone.history.navigate(communityHref, { trigger: true });
        }
      }, (this.model.get('modifyTimestamp') || this.model.get('lastModified'))); //The models have different attr names for the same thing
    }

    return getLastModifiedDateViewDefinition((this.model.get('modifyTimestamp') || this.model.get('lastModified')));
  }

  _showMedia(controller, view, mediaId) {
    const postDetailsViewMedia = view.model.currentVersion.richContent._getMediaById(mediaId);
    const postTimelineViewMedia = view.model.get('media');
    const media = postDetailsViewMedia || postTimelineViewMedia[0];

    const mediaModel = MediaFactory.createMediaFromJSON(FileFactory, media);
    window.app.layout.showMedia(mediaModel);
  }
}

module.exports = TimelineCardViewController;
