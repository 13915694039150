export const restrictedWordsValidator = (restrictedWords: string[]) => {
  let memo: Record<string, boolean> = {};
  const restrictedWordsMap: Record<string, boolean> = {};

  restrictedWords.forEach((word) => {
    restrictedWordsMap[word.toLowerCase().trim()] = true;
  });

  const resetMemo = () => {
    memo = {
      ...restrictedWordsMap
    };
  };
  const MAX_MEMO_SIZE = Object.keys(restrictedWordsMap).length + 500;
  resetMemo();

  const checkWordForProfanity = (word: string) => {
    const wordWithoutPunctuation = word.replace(/[.,/#!$%^&*;:{}=\-_`~()@|<>?\\'"]/g, '');
    if (!wordWithoutPunctuation) {
      return false;
    }
    return memo[wordWithoutPunctuation.toLowerCase()];
  };

  return (inputValue: unknown): boolean => {
    let value = inputValue;
    if (typeof value === 'number') {
      value = `${ inputValue }`;
    }
    if (typeof value !== 'string') {
      return false;
    }
    if (memo[value] !== undefined) {
      return memo[value];
    }
    if (Object.keys(memo).length > MAX_MEMO_SIZE) {
      resetMemo();
    }
    const words = Array.from(new Set(value.split(/(\s+)/)));
    const isRestrictedWord = words.some((inputWord: string) => {
      return checkWordForProfanity(inputWord?.trim());
    });
    memo[value] = isRestrictedWord;
    return isRestrictedWord;
  };
};
