const { ItemView } = require('Marionette');

const I18n = require('@common/libs/I18n');

const CatalogBrowseTypeEnum = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogBrowseTypeEnum');

class CatalogListEmptyView extends ItemView {

  initialize(options = {}) {
    ({
      collection: this.collection
    } = options);
  }

  get template() {
    return `
    <p class="learner-training-tab-content__empty ax-font ax-font__s"><%- emptyMessage %></p>
    `;
  }

  templateHelpers() {
    return {
      emptyMessage: this._getEmptyMessage()
    }
  }

  _getEmptyMessage() {
    if (this.collection.getBrowseType() !== CatalogBrowseTypeEnum.ALL || this.collection.getQueryString()) {
      // When filtering or searching, we want a different error message to be displayed for an empty result list
      return I18n.t('learnerTrainingPage.catalog.empty');
    }

    return I18n.t('learnerTrainingPage.catalog.noTraining');
  }

  collectionEvents() {
    return {
      sync: 'render'
    }
  }
}

module.exports = CatalogListEmptyView;
