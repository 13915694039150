const Backbone = require('Backbone');
const _ = require('underscore');

const AuthUser = require('@common/data/models/AuthUser');
const Tenant = require('@common/data/models/Tenant');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const dateHelpers = require('@common/libs/dateHelpers');

class Session extends Backbone.Model {
  static FIELD_PENDING_AGREEMENT_COUNT = 'pendingAgreements';

  static FIELD_DZ_EDITOR = 'shouldShowDZEditor';

  static FIELD_IS_IMPERSONTATION = 'isImpersonation';

  get className() {
    return 'Session';
  }

  // Using getter for backwards compatibility
  get tenant() {
    return this._tenant.attributes;
  }

  get validators() {
    return {
      user: 'required',
      passwd: 'password'
    };
  }

  preinitialize() {
    this._tenant = new Tenant();
    this.user = new AuthUser();
  }

  defaults() {
    return {
      [Session.FIELD_IS_IMPERSONTATION]: false
    };
  }

  urlRoot() {
    return '/axonify/whoami';
  }

  parse(res = {}) {
    if (this._tenant) {
      this._tenant.set(res.tenant);
      this._setTenantProperties();
    }

    if (res.user) {
      this.user.set(res.user, { parse: true });
    }

    if (res.serverTimeMillis) {
      this.serverTimeMillis = res.serverTimeMillis;
      this.localTimeMillis = dateHelpers.getTime();
      this.serverTimeOffset = this.serverTimeMillis - this.localTimeMillis;
    }

    if (res.suggestedMobileLoginIntervalMillis) {
      this.suggestedMobileLoginIntervalMillis = res.suggestedMobileLoginIntervalMillis;
    }

    return res;
  }

  clear(options) {
    super.clear(options);
    this.user.clear();
  }

  // The reason we override `fetch` is so that any call to `Session` fetch will
  // execute `checkTimezoneOffset` before continuing with the flow and making
  // other API calls.
  fetch(fetchOptions = {}) {
    fetchOptions.success = _.wrap(fetchOptions.success, (onSuccess, model, response, options) => {
      this.user.checkTimezoneOffset().always(() => {
        return typeof onSuccess === 'function' ? onSuccess(model, response, options) : undefined;
      });
    });

    return super.fetch(fetchOptions);
  }

  hasPendingAggreements() {
    return this.get(Session.FIELD_PENDING_AGREEMENT_COUNT) > 0;
  }

  hasCustomTeamLeaderboard() {
    return this.tenant.teamLeaderboardsEnabled;
  }

  hasCustomTeamLeaderboardButNoTeam() {
    // Custom team leaderboards are enabled BUT your custom team is null
    return this.hasCustomTeamLeaderboard() && (this.user.get('customTeam') === null);
  }

  hasDZEditorAccess() {
    return this.get(Session.FIELD_DZ_EDITOR);
  }

  isImpersonation() {
    return this.get(Session.FIELD_IS_IMPERSONTATION);
  }

  _setTenantProperties() {
    const tenantStore = TenantPropertyProvider.get();
    tenantStore.setProperties(this.tenant);
  }
}

module.exports = Session;
