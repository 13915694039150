const Marionette = require('Marionette');
const Behaviors = require('@common/libs/behaviors/Behaviors');

const { getIntegrationType } = require('@common/libs/helpers/app/NativeBridgeHelpers');
const IntegrationTypeEnum = require('@common/data/enums/IntegrationTypeEnum');

const isMsTeamsIntegration = () => {
  return getIntegrationType() === IntegrationTypeEnum.MSTEAMS;
};

Behaviors.MsHideable = class MsHideable extends Marionette.Behavior {

  ui() {
    const selectorOption = this.getOption('selector');

    return {msHideable: selectorOption};
  }

  onRender() {
    if (isMsTeamsIntegration()) {
      this.ui.msHideable.toggleClass('hidden', true);
    }
  }
};
module.exports = Behaviors.MsHideable;
