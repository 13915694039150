// Module
var code = `<ul class="metadata-group">
  <% if (canEditPage() || shouldShowInsights()) { %>
    <li class="metadata-item">
      <div class="inline-button-group">
        <% if (canEditPage()) { %>
          <div class="edit__action inline-grouped-action">
            <%= axButton({ label: editButtonText, className: 'js-button-edit', branded: true }) %>
          </div>
        <% } %>
        <% if (shouldShowInsights()) { %>
          <div class="insights__action inline-grouped-action">
            <%= axButton({ label: t('discover.insights.title'), className: 'js-button-insights', branded: true }) %>
          </div>
        <% } %>
      </div>
    </li>
  <% } %>

  <% if (isReference()) { %>
    <li class="metadata-item">
      <h4><%- t('general.url') %></h4>
      <div class="metadata-value"><input class="parent-width normal-cursor" disabled="true" value="<%- url %>"/></div>
    </li>
  <% } %>
  <li class="metadata-item">
    <h4><%- t('discover.metadata.attachments') %> <span class="metadata-value">(<%- getDocumentCount() %>)</span></h4>
    <div class="metadata__attachments"></div>
  </li>
  <li class="metadata-item metadata-item--updated">
    <h4><%- t('discover.metadata.updated') %></h4>
    <div><%= getLastUpdatedDate() %></div>
  </li>
  <% if (shouldShowExpiryInfo()) { %>
    <li class="metadata-item ">
      <h4><%- t('discover.metadata.expiryOnDate') %></h4>
      <div><%- createDate(expiry.date) %></div>
      <% if (expiry && expiry.notes) { %>
        <div class="clearfix expiry-notes-author">
          <div aria-hidden="true" class="page-preview-meta-content__avatar" data-contributor-id="<%- expiry.author.id %>"></div>
          <div class="page-preview__meta-content-contributors">
            <div class="meta-content-contributors__author-text"><%- expiry.author.fullname %></div>
            <div class="meta-content-contributors__author-status"><%- t('discover.metadata.updatestring', { date: createDate(expiry.createdTimestamp) }) %></div>
            <div class="comment-value"><%- expiry.notes %></div>
          </div>
        </div>
      <% } %>
    </li>
  <% } %>
  <li class="metadata-item">
    <% if (shouldShowBundledLanguageChooser()) { %>
      <div class="js-language-chooser-region"></div>
    <% } else { %>
      <h4><%- t('general.language') %></h4>
      <div class="metadata-value"><%= getLanguage(language) %></div>
    <% } %>
  </li>
  <li class="metadata-item metadata-item--home-community hidden">
    <h4><%- t('discover.metadata.community') %></h4>
    <div class="metadata-home-community ax-grid"></div>
  </li>
  <li class="metadata-item metadata-item--shared-communities hidden">
    <h4><%- t('discover.share.title') %></h4>
    <div class="metadata-shared-communities"></div>
    <% if (canSharePage()) { %>
    <div>
      <a href="#" class="metadata-share-link"><%- t('discover.pageTypes.generic.share') %></a>
    </div>
    <% } %>
  </li>
  <li class="metadata-item">
    <h4><%- t('discover.metadata.tag.label') %></h4>
    <div class="metadata-value"><%- tagList() %></div>
  </li>
<% if (!isAuthorAndContributorInfoDisabled()) { %>
  <li class="metadata-item">
    <h4><%- t('discover.metadata.author') %></h4>
    <div class="clearfix">
      <div aria-hidden="true" class="page-preview-meta-content__avatar" data-contributor-id="<%- getAuthor().user.id %>"></div>
      <div class="page-preview__meta-content-contributors">
        <div class="meta-content-contributors__author-text"><%= getAuthor().user.salutationName %></div>
        <div class="meta-content-contributors__author-status"><%= getAuthorDate() %></div>
      </div>
    </div>
  </li>
  <li class="metadata-item">
    <h4><%- t('discover.metadata.contributors') %></h4>
    <div class="contributor-list"></div>
    <div>
      <a href="#" id="showhistory"><%- t('discover.metadata.showHistory') %></a>
    </div>
  </li>
  <% } %>
  <% if(canPrint && !isTraining()) { %>
  <li class="metadata-item">
    <div class="print__action">
      <%= axButton({ label: getPrintText(), className: 'qa-button-print js-button-print' }) %>
    </div>
  </li>
  <% } %>
  <% if(canReportPage()) { %>
  <li class="metadata-item report-page">
    <div class="report__action">
      <%= axButton({ label: getReportText(), className: 'qa-button-report js-button-report' }) %>
    </div>
  </li>
  <% } %>
  <% if (canDeletePage()) { %>
  <li class="metadata-item">
    <div class="delete__action">
      <%= axButton({ label: getDeleteText(), className: 'qa-button-delete js-button-delete', destructive: true }) %>
    </div>
  </li>
  <% } %>
</ul>
`;
// Exports
module.exports = code;