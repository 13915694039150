const ViewOptionHelpers = {
  appendClassName (options = {}, klassName) {
    const existingClass = options.className || '';
    const className = [existingClass, klassName].join(' ');

    return Object.assign({}, options, { className });
  },

  classNameAppender (className) {
    return (options = {}) => {
      return ViewOptionHelpers.appendClassName(options, className);
    };
  }
};

module.exports = ViewOptionHelpers;
