const Marionette = require('Marionette');

class InvalidTokenPage extends Marionette.LayoutView {
  getTemplate() {
    return require('./InvalidTokenPage.html');
  }

  triggers() {
    return {
      'click .submit': 'click:retry'
    };
  }
}

module.exports = InvalidTokenPage;
