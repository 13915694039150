const GuidedLearningObjective = require('@common/data/models/objectives/GuidedLearningObjective');
const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');

const TYPE_FIELD_NAME = 'type';
const COMPLETE_FILED_NAME = 'complete';
const NAME_FIELD_NAME = 'name';
const TOPIC_FIELD_NAME = 'topic';
const SUBJECT_NAME_FIELD = 'subject';
const CATEGORY_FIELD_NAME = 'category';
const TASK_ID_FIELD_NAME = 'taskId';

class TaskLearningObjective extends GuidedLearningObjective {
  defaults() {
    return {
      [COMPLETE_FILED_NAME]: true,
      [TYPE_FIELD_NAME]: GuidedLearningObjectiveType.TASK_OBJECTIVE
    };
  }

  getName() {
    return this.get(NAME_FIELD_NAME);
  }

  canBegin() {
    // A task has no notion of things beginning, so using not `complete` as a proxy
    return !this.isComplete();
  }

  getLinkedTaskId() {
    const details = this.getObjectiveDetails();
    return details[TASK_ID_FIELD_NAME];
  }

  getTopicName() {
    const details = this.getObjectiveDetails();
    return details[TOPIC_FIELD_NAME];
  }

  getSubjectName() {
    return this.getObjectiveDetails()[SUBJECT_NAME_FIELD];
  }

  getCategoryName() {
    return this.getObjectiveDetails()[CATEGORY_FIELD_NAME];
  }

  getType() {
    return GuidedLearningObjectiveType.TASK_OBJECTIVE;
  }

  isStartable() {
    return this.canBegin();
  }
}

module.exports = TaskLearningObjective;
