const Marionette = require('Marionette');
require('./MultilingualArticleBundledLanguageChooser.less');
require('./MultilingualArticleBundledLanguageChooserController.js');

class MultilingualArticleBundledLanguageChooserView extends Marionette.LayoutView {

  getTemplate() {

    return `
      <div class="metadata__input">
        <h3><%- t('general.languages') %><span class="numeric-indicator"><%- articleLanguages() %></span></h3>
        <div class="js-language-chooser-dropdown qa-language-chooser-dropdown"></div>
      </div>
    `;
  }

  templateHelpers() {
    return {
      articleLanguages: this._getArticleLanguages.bind(this)
    }
  }

  _getArticleLanguages() {
    return this.model.values().filter((v) => {
      return v != null && typeof v !== 'string'
    }).length;
  }

  regions() {
    return {
      bundledLanguageChooserDropdown: '.js-language-chooser-dropdown'
    };
  }

}

module.exports = MultilingualArticleBundledLanguageChooserView;
