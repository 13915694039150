const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const {
  TOPIC_OBJECTIVE,
  TASK_OBJECTIVE,
  EVENT_OBJECTIVE,
  CERTIFICATION_OBJECTIVE,
  EVALUATION_OBJECTIVE
} = require('@common/data/models/objectives/GuidedLearningObjectiveType');

const TAG_STRING_KEYS_BY_TYPE = {
  [TOPIC_OBJECTIVE]: 'GuidedLearning.icons.topic',
  [TASK_OBJECTIVE]: 'GuidedLearning.icons.task',
  [EVENT_OBJECTIVE]: 'GuidedLearning.icons.event_enrollment',
  [CERTIFICATION_OBJECTIVE]: 'GuidedLearning.icons.certification',
  [EVALUATION_OBJECTIVE]: 'GuidedLearning.icons.evaluation'
};

const CSS_CLASS_BY_TYPE = {
  [TOPIC_OBJECTIVE]: 'ax-tag--topic',
  [TASK_OBJECTIVE]: 'ax-tag--task',
  [EVENT_OBJECTIVE]: 'ax-tag--event',
  [CERTIFICATION_OBJECTIVE]: 'ax-tag--certification',
  [EVALUATION_OBJECTIVE]: 'ax-tag--evaluation'
};

class ObjectiveCardTagView extends Marionette.ItemView {
  getTemplate() {
    return '<%- tagText %>';
  }

  className() {
    const type = this.getOption('guidedLearningObjectiveType');
    const typeClass = CSS_CLASS_BY_TYPE[type];

    return `ax-tag ${ typeClass }`;
  }

  tagName() {
    return 'p';
  }

  templateHelpers() {
    const type = this.getOption('guidedLearningObjectiveType');

    return {
      tagText: I18n.t(TAG_STRING_KEYS_BY_TYPE[type])
    };
  }
}

module.exports = ObjectiveCardTagView;
