const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const AbstractAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/AbstractAssessmentInitiatorController');

const AssessmentType = require('@common/data/enums/AssessmentType');
const TopicSelectionForAssessmentInitiatorContext = require('@training/apps/training/controllers/assessments/TopicSelectionForAssessmentInitiatorContext');
const NullStartableAssessmentProvider = require('@training/apps/training/controllers/assessments/providers/NullStartableAssessmentProvider');

class HeadlessAssessmentInitiatorController extends AbstractAssessmentInitiatorController {
  constructor(parentProcessor, options = {}) {
    super(parentProcessor, options);

    ({
      assessmentFactory: this.assessmentFactory
    } = options);

    this.assessmentType = AssessmentType.assertLegalValue(options.assessmentType);
  }

  createAssessment(assessmentTopicOption) {
    const context = this.createInitiatorContext();

    return this.assessmentFactory.createForAssessmentTypeAsync(
      this.assessmentType,
      context,
      assessmentTopicOption.toAssessmentRequestJson()
    );
  }

  createInitiatorContext() {
    return new TopicSelectionForAssessmentInitiatorContext(this.assessmentType);
  }

  setConfigurationProvider(provider) {
    this.contextualConfigurationProvider = provider;
  }

  processSequenceFlow() {
    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  loadConfigurationAsync() {
    return this.contextualConfigurationProvider.getConfigurationAsync().then((configuration) => {
      this.canContinue = configuration.canContinue;
    });
  }

  hasSomethingToDoAsync() {
    return this.loadConfigurationAsync().then(() => {
      return !this.canContinue;
    });
  }

  getDiscriminator() {
    return this.assessmentType;
  }

  getNextItemProvider() {
    return new NullStartableAssessmentProvider();
  }

  processAssessment(assessment) {
    const assessmentController = this.getAssessmentProcessor({
      assessment,
      nextItemProvider: this.getNextItemProvider()
    });

    return assessmentController.processSequenceFlow();
  }
}

module.exports = HeadlessAssessmentInitiatorController;
