const { wrap } = require('underscore');
const Backbone = require('Backbone');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const ScheduledEvent = require('@training/apps/training/models/ScheduledEvent');
const EventEnrollmentStatus = require('@common/data/enums/EventEnrollmentStatus');

// Client side dummy status
const NotEnrolledStatus = 'NotEnrolled';

class LearningEventEnrollment extends Backbone.Model {
  static FIELDS = {
    STATUS: 'status'
  }

  apiEndpoint() {
    return `learning-event-enrollments/${ this.eventId }/${ this.scheduledEventId }`;
  }

  defaults() {
    return {
      [LearningEventEnrollment.FIELDS.STATUS]: NotEnrolledStatus
    };
  }

  initialize(attributes, options = {}) {
    super.initialize(attributes, options);

    ({
      eventId: this.eventId,
      scheduledEventId: this.scheduledEventId
    } = options);

    if (options.id != null) {
      this.set('id', options.id);
    } else if (options.status != null) {
      this.set(LearningEventEnrollment.FIELDS.STATUS, options.status);
    }
  }

  parse(res) {
    res.scheduledLearningEvent = new ScheduledEvent(res.scheduledLearningEvent);
    return res;
  }

  fetch(options = {}) {
    if (this.get('id') == null) {
      // when there's no id, have this become a successful noop instead of trying to fetch and invalid entity.
      return $.Deferred().resolve()
        .promise();
    }

    options.error = wrap(options.error, (onError = () => {}, model, xhr, ...args) => {
      onError(model, xhr, ...args);
    });

    return super.fetch(options);
  }

  isEnrolled() {
    return this.get(LearningEventEnrollment.FIELDS.STATUS) === EventEnrollmentStatus.Enrolled;
  }

  isOnWaitingList() {
    return this.get(LearningEventEnrollment.FIELDS.STATUS) === EventEnrollmentStatus.Waiting;
  }

  hasEnrollmentState() {
    const isWaiting = this.isOnWaitingList();
    const isEnrolled = this.isEnrolled();

    return isWaiting || isEnrolled;
  }

  setNotEnrolled() {
    this.set({
      id: undefined,
      [LearningEventEnrollment.FIELDS.STATUS]: NotEnrolledStatus
    });
  }

  destroy(options = {}) {
    options.success = wrap(options.success, (onSuccess = () => {}, ...args) => {
      this.setNotEnrolled();
      onSuccess(...args);
    });

    return super.destroy(options);
  }
}

module.exports = LearningEventEnrollment;
