const Backbone = require('Backbone');
const MobileDevice = require('@common/data/models/MobileDevice');

class MobileDeviceList extends Backbone.Collection {
  get model() {
    return MobileDevice;
  }

  parse(response) {
    return response.entities || response;
  }
}

module.exports = MobileDeviceList;
