const _ = require('underscore');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const ZoomOverlay = require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');
const Resizable = require('@common/libs/behaviors/resizable/Resizable');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const QuestionPreviewBasePage = require('@common/components/questions/views/QuestionPreviewPage/QuestionPreviewBasePage');
const { getSelectedMultiLangImage } = require('@common/libs/helpers/app/ImageHelpers');
const VariantType = require('@common/data/enums/VariantType');

//Dummy class requires to deal with training using common preview but not including bootstrap
let PopoverController;
let PopoverImageView;

class QuestionPreviewPage extends Marionette.LayoutView {

  getTemplate() {
    return `\
<div id="answer-header"></div>
<div class="answer-option-text"></div>
<div id="answer-body" class="answerwrapper"></div>\
`;
  }

  ui() {
    return {
      answerOptionText: '.answer-option-text'
    };
  }

  regions() {
    return {
      answerHeader: '#answer-header'
    };
  }

  initialize(options = {}) {
    ({
      model: this.model
    } = options);

    // TODO: Training does not include bootstrap.js yet so we can't use the popover files there
    // When training is updated than we can remove the dummy noop classes above and the below assignments
    ({
      PopoverController = require('@common/components/questions/views/popover/PopoverPlaceHolder'),
      PopoverImageView = require('@common/components/questions/views/popover/PopoverImageViewPlaceholder')
    } = this.options);

    this.listenTo(this.model, 'change', this.onShow);

    if (!this.model.hasAnswered) {
      this.$el.addClass('question-unanswered');
    }
  }

  onShow() {
    const questionTextView = this._getQuestionTextview();

    this.answerHeader.show(questionTextView);

    this.listenTo(questionTextView, 'image:clicked', (mediaId) => {
      this.trigger('show:zoom:modal', questionTextView.getImageMedia(mediaId));
    });

    this.listenTo(questionTextView, 'image:loaded', () => {
      this.trigger('image:loaded');
    });

    if (this.model.get('inlineMode') != null) {
      this.ui.answerOptionText.text(I18n.t('question.answers'));
    }
  }

  _getQuestionTextview() {
    return new QuestionItemView({
      model: this.model
    });
  }
}

class QuestionItemView extends QuestionPreviewBasePage {

  getTemplate() {
    return require('@common/components/questions/templates/questionText.html');
  }

  ui() {
    return {
      hintText: '.hinttext',
      reasonText: '#reason'
    };
  }

  behaviors() {
    return [{
      behaviorClass: Resizable
    }, {
      behaviorClass: ZoomOverlay,
      imageWrapper: '.image-wrapper'
    }];
  }

  templateHelpers() {
    const translatedLanguage = this.translatedLanguage;
    const variantType = this.variant.get('variantType');
    return {
      answerStatus: this.getAnswerStatusText(),
      answerStatusText: I18n.t(`question.${ this.getAnswerStatusText() }`),
      questionImage: getSelectedMultiLangImage(this.variant.get('questionImg'), translatedLanguage, variantType === VariantType.Hotspot),
      reasonImage: getSelectedMultiLangImage(this.variant.get('reasonImg'), translatedLanguage),
      getReasonText() {
        if (this.variant.get('reasonText') != null) {
          return this.variant.get('reasonText');
        }
        return undefined;
      },
      getQuestionImage() {
        return getSelectedMultiLangImage(this.variant.get('questionImg'), translatedLanguage, variantType === VariantType.Hotspot);
      },
      getReasonImage() {
        return getSelectedMultiLangImage(this.variant.get('reasonImg'), translatedLanguage);
      },
      getTranslationStatusCSS() {
        const hasText = this.variant.get('questionText') !== '';
        if (!hasText) {
          return 'translation-required';
        }
        return undefined;
      },
      getTranslationStringIfNoText() {
        const hasText = this.variant.get('questionText') !== '';
        if (!hasText) {
          return I18n.t('question.translationRequired');
        }
        return undefined;
      }
    };
  }

  initialize() {
    this.isAnsweredCorrectly = this.model.get('isAnsweredCorrectly');
    this.variant = this.model.get('variant');
    this.translatedLanguage = this.model.get('translatedLanguage');

    this._imageMediaInfo = {};
    this._imageViewers = {};
    this._createImageMediaInfo();
  }

  onRender() {
    this._displayHintTextArea();
  }

  hasImage() {
    return (this.variant.get('questionImg') != null);
  }

  getImageFile() {
    return this.variant.get('questionImg');
  }

  initPopoverController(options) {
    return new PopoverController(options);
  }

  initPopoverZoomView(options) {
    return new PopoverImageView(options);
  }

  onShow() {
    super.onShow();
    return this._initializeImageViewers();
  }

  onResize() {
    _.each(this._imageViewers, (imageViewer) => {
      return imageViewer.resize();
    });
  }

  getImageMedia(mediaId) {
    return this._imageMediaInfo[mediaId];
  }

  getAnswerStatusText() {
    if ((this.isAnsweredCorrectly == null)) {
      return 'explanation';
    } else if (this.isAnsweredCorrectly) {
      return 'correct';
    }
    return 'incorrect';

  }

  _displayHintTextArea() {
    this.ui.hintText.show();
    const reasonText = this.variant.get('reasonText');
    const hasReasonText = (reasonText != null) && (reasonText !== '');

    if (this.model.get('inlineMode') != null || (!hasReasonText && this.variant.get('reasonImg') == null)) {
      this.ui.hintText.addClass('empty'); // applies a unique class to admin app to overwrite .show()'s in line display: block style
    } else {
      this.ui.reasonText.show();
    }
  }

  _createImageMediaInfo() {
    this.questionImg = this.variant.get('questionImg');
    const questionImg = getSelectedMultiLangImage(this.questionImg, this.translatedLanguage, this.variant.get('variantType') === VariantType.Hotspot);
    const reasonImg = getSelectedMultiLangImage(this.variant.get('reasonImg'), this.translatedLanguage);

    if (questionImg != null) {
      this._imageMediaInfo[questionImg.id] = questionImg;
    }

    if (reasonImg != null) {
      this._imageMediaInfo[reasonImg.id] = reasonImg;
    }
  }

  _initializeImageViewers() {
    return _.each(this._imageMediaInfo, (media) => {
      this._imageViewers[media.id] = ImageViewerFactory.createViewerInstance({
        media,
        $el: this.$el.find(`[data-media-id = '${ media.id }']`)
      });

      this.listenTo(this._imageViewers[media.id], 'image:loaded', this.triggerAdjustment);

      this._imageViewers[media.id].render();
    });
  }

  serializeData() {
    const data = super.serializeData();

    const language = data.translatedLanguage;
    if (language) {
      data.variant.set('questionText', data.variant.get('questionTextTranslation').getValueForLanguage(language, false));
      // Advanced Multiple Choice type questions have their reason text set on the option list
      // while all other questions have reason text on the QuestionVariant
      // So add a guard for that situation
      if (data.variant.get('reasonTextTranslation') != null) {
        data.variant.set('reasonText', data.variant.get('reasonTextTranslation').getValueForLanguage(language, false));
      }
    }

    return data;
  }
}

class QuestionOptionItemView extends QuestionPreviewBasePage {
  className() {
    return 'answer touchable clearfix';
  }

  ui() {
    return {
      answerImage: '.answerimage'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      ZoomOverlay: {
        imageWrapper: '.image-wrapper'
      }
    };
  }

  templateHelpers() {
    return {
      questionImage: getSelectedMultiLangImage(this.model.get('optionImg'), this.translatedLanguage),
      getAnswerResult: () => {
        let text;
        if (this.model.get('correct')) {
          text = 'correct';
        }
        if (this.selectedAnswer && !this.model.get('correct')) {
          text = 'incorrect';
        }
        return text;
      },
      getTranslationStatusCSS() {
        const hasImage = (getSelectedMultiLangImage(this.optionImg, this.translatedLanguage) != null);
        const hasText = this.optionText !== '';
        if (!hasText && !hasImage) {
          return 'translation-required';
        }
        return undefined;
      },
      getTranslationStringIfNoText() {
        const hasImage = (getSelectedMultiLangImage(this.optionImg, this.translatedLanguage) != null);
        const hasText = this.optionText !== '';
        if (!hasText && !hasImage) {
          return I18n.t('question.translationRequired');
        }
        return undefined;
      }
    };
  }

  initialize(options = {}) {
    ({
      selectedAnswer: this.selectedAnswer,
      translatedLanguage: this.translatedLanguage,
      childViewIndex: this.childViewIndex
    } = options);
    this._imageMediaInfo = {};
    this._imageViewers = {};
    this._createImageMediaInfo();

    this._applyAnswerStatusClass();
  }

  _applyAnswerStatusClass() {
    if (this.selectedAnswer && !this.model.get('correct')) {
      this.$el.addClass('wrong');
    }
  }

  onRender() {
    this._hideAnswerImages();
  }

  onShow() {
    super.onShow();
    this._initializeImageViewers();
    this.$el.addClass(`option-answer${ this.childViewIndex }`);
  }

  hasImage() {
    return (this.model.get('optionImg') != null);
  }

  getImageFile() {
    return this.model.get('optionImg');
  }

  initPopoverController(options) {
    return new PopoverController(options);
  }

  initPopoverZoomView(options) {
    return new PopoverImageView(options);
  }

  getImageMedia(mediaId) {
    return this._imageMediaInfo[mediaId];
  }

  _createImageMediaInfo() {
    const optionImg = getSelectedMultiLangImage(this.model.get('optionImg'), this.translatedLanguage);
    if (optionImg != null) {
      this._imageMediaInfo[optionImg.id] = optionImg;
    }
  }

  _initializeImageViewers() {
    return _.each(this._imageMediaInfo, (media) => {
      this._imageViewers[media.id] = ImageViewerFactory.createViewerInstance({
        media,
        $el: this.$el.find(`[data-media-id = '${ media.id }']`)
      });

      this.listenTo(this._imageViewers[media.id], 'image:loaded', this.triggerAdjustment);

      this._imageViewers[media.id].render();
    });
  }

  _hideAnswerImages() {
    const optionImg = getSelectedMultiLangImage(this.model.get('optionImg'), this.translatedLanguage);
    if ((optionImg == null)) {
      this.ui.answerImage.hide();
    }
  }

  serializeData() {
    const data = super.serializeData();
    if (this.translatedLanguage) {
      data.optionText = data.optionTextTranslation.getValueForLanguage(this.translatedLanguage, false);
    }

    if (data.optionText == null) {
      data.optionText = '';
    }

    return data;
  }
}

class QuestionCollectionView extends Marionette.CollectionView {

  childView() {
    return QuestionOptionItemView;
  }

  childEvents() {
    return {
      'image:clicked': 'onImageClick'
    };
  }

  childViewOptions(model, index) {
    return {
      childViewIndex: index,
      translatedLanguage: this.options.model.get('translatedLanguage')
    };
  }

  initialize(options = {}) {
    const {
      model
    } = options;
    this.collection = model.get('variant').options;
  }

  onImageClick(itemView, mediaId) {
    this.trigger('image:clicked', itemView, mediaId);
  }
}

module.exports = {
  QuestionPreviewPage,
  QuestionItemView,
  QuestionOptionItemView,
  QuestionCollectionView
};
