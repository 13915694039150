const { ItemView } = require('Marionette');

const DATA_LOADING_SPINNER_ENABLED = 'dataLoadingSpinnerEnabled';
const DATA_LOADING_SPINNER_SHOW = 'dataLoadingSpinnerShow';
const DATA_LOADING_SPINNER_COUNTER = 'dataLoadingSpinnerCounter';
const DATA_LOADING_SPINNER_CONTENT_DEFINITION = 'dataLoadingSpinnerContentDefinition';

const getCounter = (contextModel) => {
  return contextModel.get(DATA_LOADING_SPINNER_COUNTER);
};

const incrementCounter = (contextModel) => {
  const incrementedCounter = getCounter(contextModel) + 1;
  contextModel.set(DATA_LOADING_SPINNER_COUNTER, incrementedCounter);
};

const decrementCounter = (contextModel) => {
  const decrementedCounter = Math.max(0, getCounter(contextModel) - 1);
  contextModel.set(DATA_LOADING_SPINNER_COUNTER, decrementedCounter);
};

const updateShow = (contextModel) => {
  const counter = getCounter(contextModel);
  contextModel.set(DATA_LOADING_SPINNER_SHOW, counter > 0);
};

module.exports = {
  Keys: {
    DATA_LOADING_SPINNER_ENABLED,
    DATA_LOADING_SPINNER_SHOW,
    DATA_LOADING_SPINNER_COUNTER,
    DATA_LOADING_SPINNER_CONTENT_DEFINITION
  },

  defaults() {
    return {
      [DATA_LOADING_SPINNER_ENABLED]: false,
      [DATA_LOADING_SPINNER_SHOW]: false,
      [DATA_LOADING_SPINNER_COUNTER]: 0,
      [DATA_LOADING_SPINNER_CONTENT_DEFINITION]: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'fit-parent',
          template: false
        }
      }
    };
  },

  toggleEnabled(contextModel, toggle) {
    contextModel.set(DATA_LOADING_SPINNER_ENABLED, Boolean(toggle));
  },

  isEnabled(contextModel) {
    return contextModel.get(DATA_LOADING_SPINNER_ENABLED);
  },

  isShowing(contextModel) {
    return contextModel.get(DATA_LOADING_SPINNER_SHOW);
  },

  showSpinner(contextModel) {
    incrementCounter(contextModel);
    updateShow(contextModel);
  },

  hideSpinner(contextModel) {
    decrementCounter(contextModel);
    updateShow(contextModel);
  },

  getContentControllerDefinition(contextModel) {
    return contextModel.get(DATA_LOADING_SPINNER_CONTENT_DEFINITION);
  },

  setContentControllerDefinition(contextModel, controllerDefinition, options = {}) {
    contextModel.set(DATA_LOADING_SPINNER_CONTENT_DEFINITION, controllerDefinition, options);
  },

  resetContentControllerDefinition(contextModel) {
    const defaultDefinition = this.defaults()[DATA_LOADING_SPINNER_CONTENT_DEFINITION];
    contextModel.set(DATA_LOADING_SPINNER_CONTENT_DEFINITION, defaultDefinition);
  }
};
