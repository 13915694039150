const _ = require('underscore');
const Marionette = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');
const KeyCode = require('@common/data/enums/KeyCode');

class ZoomOverlay extends Marionette.Behavior {
  overlayTemplate = '<div class="zoom-overlay hidden"><div class="zoom-icon"></div></div>';

  initialize(options = {}) {
    // This has to be specified ahead of time for the behaviour -- lame, I know
    this.events = {
      'click @ui.imageWrapper': 'onImageClick',
      'touchend @ui.imageWrapper': 'onImageTouch', // mouseenter is going to override click on iOS
      'mouseenter @ui.imageWrapper': 'onImageMouseEnter',
      'mouseleave @ui.imageWrapper': 'onImageMouseLeave',
      'keydown @ui.imageWrapper': 'onImageKeydown'
    };

    this.ui = {imageWrapper: options.imageWrapper != null ? options.imageWrapper : '.image-wrapper'};

    this.onImageClick = _.debounce(this.onImageClick, 300);
  }

  onImageTouch(event) {
    const e = event.originalEvent;
    const touches = e.targetTouches;

    if (touches.length > 0) {
      this.onImageClick(event);
    }
  }

  onImageKeydown(e) {
    if (e.which !== KeyCode.ENTER && e.which !== KeyCode.SPACE) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    this.onImageClick(e);
  }

  onImageClick(e) {
    const $zoomImg = this.$(e.currentTarget).find('[data-media-id]');

    if (!this._isReady($zoomImg)) {
      return;
    }

    this.view.trigger('image:clicked', $zoomImg.data('media-id'));
  }

  _isReady($zoomImg) {
    return $('.zoom-modal').not(':visible') && ($zoomImg.length > 0) && ($zoomImg.data('media-id') != null);
  }

  onImageMouseEnter(e) {
    const $currentTarget = this.$(e.currentTarget);

    const $zoomImg = $currentTarget.find('[data-media-id]');

    if (!this._isReady($zoomImg)) {
      return;
    }

    let $zoomOverlay = $currentTarget.find('.zoom-overlay');

    if ($zoomOverlay.length === 0) {
      $currentTarget.append($zoomOverlay = $(this.overlayTemplate));
    }

    const parentRect = this.view.getBoundingClientRect($currentTarget);
    const rect = this.view.getBoundingClientRect($zoomImg);
    const position = $zoomImg.position();
    const existingMargin = parseInt($zoomImg.css('margin-left'), 10);

    let baseCss = {};

    // For centre/right aligned images created with Froala (Broadcast Message is the only place this is currently possible)
    if ($zoomImg.hasClass('fr-dib') && !($zoomImg.hasClass('fr-fil')) && parentRect.left !== rect.left) {
      baseCss = {margin: `0px ${ Math.abs(existingMargin) }px`};
    }

    const css = _.extend(baseCss, _.pick(rect, 'width', 'height'), _.pick(position, 'top', 'left'));
    $zoomOverlay.css(css);

    $zoomOverlay.toggleClass('hidden', false);
  }

  onImageMouseLeave(e) {
    const $currentTarget = this.$(e.currentTarget);
    const $zoomOverlay = $currentTarget.find('.zoom-overlay');
    $zoomOverlay.toggleClass('hidden', true);
  }
}

Behaviors.ZoomOverlay = ZoomOverlay;

module.exports = Behaviors.ZoomOverlay;
