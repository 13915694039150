const Backbone = require('Backbone');

class ObjectiveResultModel extends Backbone.Model {
  defaults() {
    return {
      hasPassedLastAttempt: true,
      isRetake: false,
      launchContext: undefined,
      trainingType: undefined,
      hasAttempt: true,
      isComplete: true
    };
  }
}

module.exports = ObjectiveResultModel;
