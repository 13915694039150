const _ = require('underscore');

const logging = require('logging');
const env = require('env');
const $os = require('detectOS');

const definitions = require('@common/assets/defines/default.json');
const I18n = require('@common/libs/I18n');


const folder = `/training/${ env.settings.version }/images`;

const GameHelper = {

  isSupportedGameType(type) {
    const gameInfo = this.getGameInfo(type);
    if (!gameInfo) {
      return false;
    }

    return gameInfo.supported === true;
  },

  doesRequireTeam(type) {
    const gameInfo = this.getGameInfo(type);
    return gameInfo.requiresTeam === true;
  },

  getGameGenre(type) {
    if (!type) {
      logging.error('There was an bad type passed in for the game game genre. This is likely a mistake.');
      return null;
    }

    const gameInfo = this.getGameInfo(type);
    return gameInfo.genre;
  },

  getGameInfo(type) {
    if (type != null) {
      return GameInfo[type];
    } return GameInfo;
  },

  getGameInstructions(type) {
    if (type == null) {
      return '';
    }

    switch (type) {
      case 'AtomicAtoms': return I18n.t('games.atoms.instructions');
      case 'BallBob': return I18n.t('games.ballbob.instructions');
      case 'BattleFish': return I18n.t('games.battlefish.instructions');
      case 'BlackAway': return I18n.t('games.blackaway.texteTuto1');
      case 'Block': return I18n.t('games.blockrush.instructions');
      case 'BlockArt': return I18n.t('games.blockart.instructions');
      case 'Bubble': return I18n.t('games.bubbleshooter.instructions');
      case 'Breaker': return I18n.t('games.breaker.instructions');
      case 'Catcher': return I18n.t('games.catcher.instructions');
      case 'Cheese': return I18n.t('games.cheesequest.texteTuto');
      case 'Collisions': return I18n.t('games.collisions.instructions');
      case 'Curves': return I18n.t('games.curves.instructions');
      case 'FieldGoal': return I18n.t('games.FieldGoal.instructions');
      case 'Fishy': return I18n.t('games.fishy.instructions');
      case 'FoodSearch': return I18n.t('games.FoodSearch.instructions');
      case 'GemJam': return I18n.t('games.gem.instructions');
      case 'Golf': return I18n.t('games.golf.instructions');
      case 'Head2Head': return I18n.t('games.head2head.instructions');
      case 'Invasion': return I18n.t('games.invasion.instructions');
      case 'Jigsaw': return I18n.t('games.jigsaw.detailedInstructions');
      case 'LightsOut': return I18n.t('games.LightsOut.instructions');
      case 'Memory': return I18n.t('games.memory.instructions');
      case 'Monkey': return I18n.t('games.Monkey.instructions');
      case 'BumpOut': return I18n.t('games.BumpOut.instructions');
      case 'Monster': return I18n.t('games.monster.instructions');
      case 'Pinball': return I18n.t('games.pinball.instructions');
      case 'Pixel': return I18n.t('games.pixel.instructions');
      case 'Quiz': return I18n.t('games.Quiz.instructions');
      case 'Shapes': return I18n.t('games.shapes.instructions');
      case 'Shark': return I18n.t('games.shark.instructions');
      case 'Simon': return I18n.t('games.simonsays.instructions');
      case 'Simon2': return I18n.t('games.Simon2.instructions');
      case 'Snake': return I18n.t('games.snake.instructions');
      case 'Space': return I18n.t('games.Space.instructions');
      case 'SpaceRocks': return I18n.t('games.spacerocks.instructions');
      case 'Sum': return I18n.t('games.matchten.instructions');
      case 'Sushi': return I18n.t('games.Sushi.instructions');
      case 'TeamRunner': return I18n.t('games.TeamRunner.instructions');
      case 'Uni': return I18n.t('games.unirat.instructions');
      case 'Zombie': return I18n.t('games.zombietaps.instructions');
      case 'LightPuzzle': return I18n.t('games.LightPuzzle.instructions');
      case 'Curling': return I18n.t('games.Curling.instructions');
      case 'CurlingUpdate': return I18n.t('games.Curling.instructions');
      case 'Convergence': return I18n.t('games.Convergence.instructions');
      case 'Summer': return I18n.t('games.Summer.instructions');
      case 'Elements': return I18n.t('games.Elements.instructions');
      case 'TurtleHurtle': return I18n.t('games.TurtleHurtle.instructions');
      case 'Unicycle': return I18n.t('games.Unicycle.instructions');
      case 'Soccer': return I18n.t('games.Kick-Off.instructions');
      default:
        // Log an error for the missing game instructions and return an empty
        // string
        logging.error(`Missing instructions for game type ${ type }`);
        return '';
    }
  },

  getGamePage(type) {
    return `games/${ GameInfo[type].pageName }`;
  },

  getGameVideos(type) {
    const gameInfo = this.getGameInfo(type);
    return gameInfo.videoLinks;
  },

  mergeDefinitions(first, second) {
    const clone = $.extend({}, first);

    for (const key of _.keys(platforms)) {
      const value = platforms[key];
      $.each(first[value], (fkey, fval) => {
        if (second[value] != null) {
          return $.each(second[value], (skey, sval) => {
            if (fval.internal === sval.internal) {
              clone[value][fkey] = sval;
              return sval;
            }

            return null;
          });
        }
        return null;
      });
    }

    return clone;
  },

  getDetails() {
    return GameHelper.processDefinitions(definitions);
  },

  evaluateRequirements(definition, key) {
    // If no version info is available, permissable
    if (!$os.version) {
      logging.info(`GameHelper - OS version could not be determined; allowing through: ${ JSON.stringify($os) }`);
      return;
    }
    const version = parseFloat($os.version);

    GameInfo[key].supported = GameInfo[key].supported && ((version >= definition.baseVersion) && (definition.baseVersion !== 0));

    if (definition.exclusions.includes(version)) {
      GameInfo[key].supported = false;
    }
  },

  processDefinitions(gameData) {
    // Iterate over each game, and set flags accordingly
    logging.info(`GameHelper - Began processing definitions as browser ${ $os.browser } version ${ $os.version }`);

    for (const key of _.keys(GameInfo)) {

      let particularGameData = {};
      $.extend(true, particularGameData, gameData.default);

      // If this game has a key entry, merge their defintions
      if (gameData[key] != null) {
        particularGameData = this.mergeDefinitions(particularGameData, gameData[key]);

        // If the game is not supported anymore, the game is unsupported by the training console
        if (gameData[key].pendingRemoval) {
          GameInfo[key].supported = false;
        }

        // Deal with mobile apps first
        if ($os.isInMobileApp()) {
          $.each(particularGameData.mobileA, (k, value) => {

            // Android in app
            if ($os.android && (value.internal === 'android')) {
              this.evaluateRequirements(value, key);
            } else if ($os.ios && (value.internal === 'apple')) {
              this.evaluateRequirements(value, key);
            } else if ($os.blackberry && (value.internal === 'blackberry')) {
              this.evaluateRequirements(value, key);
            } else if ($os.windowsphone && (value.internal === 'winphone8')) {
              this.evaluateRequirements(value, key);
            }
          });

          // Move onto the desktop browsers
        } else if ($os.desktop) {
          $.each(particularGameData.desktop, (k, value) => {

            // Set the flags
            if (value.internal === $os.browser) {
              this.evaluateRequirements(value, key);
            }
          });

          // Now, we take care of the mobile browsers
        } else {
          $.each(particularGameData.mobileB, (k, value) => {

            // Take care of each browser which might have a possible blacklist
            if ($os.android && ($os.browser === 'chrome') && (value.internal === 'chrome-android')) {
              this.evaluateRequirements(value, key);
            } else if ($os.android && ($os.browser !== 'chrome') && (value.internal === 'androidbrowser')) {
              this.evaluateRequirements(value, key);
            } else if (($os.ios) && ($os.browser === 'safari') && (value.internal === 'safari-ios')) {
              this.evaluateRequirements(value, key);
            } else if (($os.ios) && ($os.browser === 'chrome') && (value.internal === 'chrome-ios')) {
              this.evaluateRequirements(value, key);
            }
          });
        }
      }
    }
  }
};


const baseGameInfo = {
  //Never override this when adding a new game to GameInfo, the filtering code controls this value.
  supported: true,
  requiresTeam: false
};

const platforms = ['desktop', 'mobileA', 'mobileB'];

const GameGenre = {
  ActionAdventure: 'ActionAdventure',
  BrainTeaser: 'BrainTeaser',
  MultiplayerBrainTeaser: 'MultiplayerBrainTeaser',
  Sports: 'Sports'
};


const GameInfo = {
  Bubble: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'bubbleshoot/views/BubbleShootPage',
    poster: `${ folder }/banners/bubble-shooter.png`,
    videoLinks: [{
      link: '/shared/videos/games/bubble/bubble.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/bubble/bubble.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Block: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'blockrush/views/BlockRushPage',
    poster: `${ folder }/banners/block-rush.png`,
    videoLinks: [{
      link: '/shared/videos/games/block/block.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/block/block.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  FieldGoal: _.extend({}, baseGameInfo, {
    genre: GameGenre.Sports,
    pageName: 'fieldGoal/FieldGoalPage',
    poster: `${ folder }/banners/field-goal.png`,
    videoLinks: [{
      link: '/shared/videos/games/fieldgoal/fieldgoal.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/fieldgoal/fieldgoal.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Quiz: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'quizshow/views/QuizShowPage',
    poster: `${ folder }/banners/quiz-show.png`,
    videoLinks: [{
      link: '/shared/videos/games/quiz/quiz.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/quiz/quiz.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Monkey: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'monkeybizness/MonkeyBiznessPage',
    poster: `${ folder }/banners/monkey.png`,
    videoLinks: [{
      link: '/shared/videos/games/monkey/monkey.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/monkey/monkey.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  LightsOut: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'lightsout/LightsOutPage',
    poster: `${ folder }/banners/lights-out.png`,
    videoLinks: [{
      link: '/shared/videos/games/lightsout/lightsout.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/lightsout/lightsout.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Simon: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'simon/SimonPage',
    poster: `${ folder }/banners/simon.png`,
    videoLinks: [{
      link: '/shared/videos/games/simon/simon.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/simon/simon.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Simon2: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'simon/SimonPage',
    poster: `${ folder }/banners/Simon2.png`,
    videoLinks: [{
      link: '/shared/videos/games/Simon2/Simon2.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/Simon2/Simon2.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Space: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'spaceattack/SpaceAttackPage',
    poster: `${ folder }/banners/space-attack.png`,
    videoLinks: [{
      link: '/shared/videos/games/space/space.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/space/space.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Curves: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'curves/CurvesPage',
    poster: `${ folder }/banners/learning-curves.png`,
    videoLinks: [{
      link: '/shared/videos/games/curves/curves.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/curves/curves.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  BlockArt: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'blockart/views/BlockArtPage',
    poster: `${ folder }/banners/block-art.png`,
    videoLinks: [{
      link: '/shared/videos/games/blockart/blockart.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/blockart/blockart.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Snake: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'snake/SnakePage',
    poster: `${ folder }/banners/snake.png`,
    videoLinks: [{
      link: '/shared/videos/games/snake/snake.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/snake/snake.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Pixel: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'ColorFill/ColorFillPage',
    poster: `${ folder }/banners/pixel.png`,
    videoLinks: [{
      link: '/shared/videos/games/pixel/pixel.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/pixel/pixel.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Memory: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'memory/MemoryPage',
    poster: `${ folder }/banners/memory.png`,
    videoLinks: [{
      link: '/shared/videos/games/memory/memory.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/memory/memory.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Uni: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'unirat/UniRatPage',
    poster: `${ folder }/banners/unicycle.png`,
    videoLinks: [{
      link: '/shared/videos/games/uni/uni.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/uni/uni.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Catcher: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'catcher/CatcherPage',
    poster: `${ folder }/banners/candy-catcher.png`,
    videoLinks: [{
      link: '/shared/videos/games/catcher/catcher.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/catcher/catcher.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  BattleFish: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'battlefish/BattleFishPage',
    poster: `${ folder }/banners/battle-fish.png`,
    videoLinks: [{
      link: '/shared/videos/games/battlefish/battlefish.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/battlefish/battlefish.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  AtomicAtoms: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'atomicatoms/AtomicAtomsPage',
    poster: `${ folder }/banners/extreme-atom.png`,
    videoLinks: [{
      link: '/shared/videos/games/atomicatoms/atomicatoms.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/atomicatoms/atomicatoms.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Collisions: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'collisions/CollisionsPage',
    poster: `${ folder }/banners/collisions.png?v_2`,
    videoLinks: [{
      link: '/shared/videos/games/collisions/collisions.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/collisions/collisions.ogg',
      type: 'video/ogg'
    }
    ]
  }),
  SpaceRocks: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'spacerocks/SpacerocksPage',
    poster: `${ folder }/banners/space-rocks.png`,
    videoLinks: [{
      link: '/shared/videos/games/spacerocks/spacerocks.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/spacerocks/spacerocks.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Sum: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'matchten/views/MatchTenPage',
    poster: `${ folder }/banners/sum-up.png`,
    videoLinks: [{
      link: '/shared/videos/games/sum/sum.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/sum/sum.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Zombie: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'zombietaps/ZombieTapsPage',
    poster: `${ folder }/banners/zombie-taps.png`,
    videoLinks: [{
      link: '/shared/videos/games/zombie/zombie.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/zombie/zombie.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Cheese: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'cheesequest/CheeseQuestPage',
    poster: `${ folder }/banners/cheese.png`,
    videoLinks: [{
      link: '/shared/videos/games/cheese/cheese.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/cheese/cheese.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Invasion: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'invasion/InvasionPage',
    poster: `${ folder }/banners/invasion.png`,
    videoLinks: [{
      link: '/shared/videos/games/invasion/invasion.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/invasion/invasion.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  BallBob: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'ballbob/BallBobPage',
    poster: `${ folder }/banners/ball-bob.png`,
    videoLinks: [{
      link: '/shared/videos/games/ballbob/ballbob.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/ballbob/ballbob.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Head2Head: _.extend({}, baseGameInfo, {
    genre: GameGenre.MultiplayerBrainTeaser,
    pageName: 'head2head/Head2HeadPage',
    poster: `${ folder }/banners/head-to-head.png`,
    videoLinks: [{
      link: '/shared/videos/games/head2head/head2head.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/head2head/head2head.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Shapes: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'shapes/ShapesPage',
    poster: `${ folder }/banners/shuffle.png`,
    videoLinks: [{
      link: '/shared/videos/games/shapes/shapes.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/shapes/shapes.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Shark: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'shark/SharkPage',
    poster: `${ folder }/banners/shark-swim.png`,
    videoLinks: [{
      link: '/shared/videos/games/shark/shark.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/shark/shark.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  BumpOut: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'bumpout/BumpOutPage',
    poster: `${ folder }/banners/bump-out.png`,
    videoLinks: [{
      link: '/shared/videos/games/bumpout/bumpout.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/bumpout/bumpout.ogg',
      type: 'video/ogg'
    }
    ]
  }),
  Monster: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'monster/MonsterPage',
    poster: `${ folder }/banners/monster-kick-out.png`,
    videoLinks: [{
      link: '/shared/videos/games/monster/monster.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/monster/monster.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  BlackAway: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'blackaway/BlackAwayPage',
    poster: `${ folder }/banners/sponge-toss.png`,
    videoLinks: [{
      link: '/shared/videos/games/blackaway/blackaway.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/blackaway/blackaway.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Fishy: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'fishy/FishyPage',
    poster: `${ folder }/banners/fishy.png`,
    videoLinks: [{
      link: '/shared/videos/games/fishy/fishy.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/fishy/fishy.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  GemJam: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'gem/GemPage',
    poster: `${ folder }/banners/gem-jam.png`,
    videoLinks: [{
      link: '/shared/videos/games/gemjam/gemjam.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/gemjam/gemjam.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Golf: _.extend({}, baseGameInfo, {
    genre: GameGenre.Sports,
    pageName: 'golf/GolfPage',
    poster: `${ folder }/banners/hole-in-one.png`,
    videoLinks: [{
      link: '/shared/videos/games/golf/golf.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/golf/golf.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  FoodSearch: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'foodSearch/FoodSearchPage',
    poster: `${ folder }/banners/food-search.png`,
    videoLinks: [{
      link: '/shared/videos/games/foodsearch/foodsearch.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/foodsearch/foodsearch.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Breaker: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'breaker/BreakerPage',
    poster: `${ folder }/banners/breaker.png`,
    videoLinks: [{
      link: '/shared/videos/games/breaker/breaker.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/breaker/breaker.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Jigsaw: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'jigsaw/JigsawPage',
    poster: `${ folder }/banners/jigsaw.png`,
    requiresTeam: true,
    videoLinks: [{
      link: '/shared/videos/games/jigsaw/jigsaw.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/jigsaw/jigsaw.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  TeamRunner: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'teamrunner/TeamSkyRunnerPage',
    poster: `${ folder }/banners/skyrun.png`,
    requiresTeam: true,
    videoLinks: [{
      link: '/shared/videos/games/teamrunner/teamrunner.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/teamrunner/teamrunner.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Pinball: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'pinball/PinballPage',
    poster: `${ folder }/banners/pinball.png`,
    videoLinks: [{
      link: '/shared/videos/games/pinball/pinball.m4v',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/pinball/pinball.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  LightPuzzle: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    locale: {
      en: {
        name: 'Light Puzzle'
      }
    },
    pageName: 'light_puzzle/LightPuzzlePage',
    poster: `${ folder }/banners/light-puzzle.png`,
    videoLinks: [{
      link: '/shared/videos/games/cave/cave.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/cave/cave.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  Curling: _.extend({}, baseGameInfo, {
    genre: GameGenre.Sports,
    pageName: 'curling/CurlingPage',
    poster: `${ folder }/banners/curling.png`,
    videoLinks: [{
      link: '/shared/videos/games/curling/curling.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/curling/curling.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  CurlingUpdate: _.extend({}, baseGameInfo, {
    genre: GameGenre.Sports,
    pageName: 'curlingUpdate/CurlingPage',
    poster: `${ folder }/banners/curlingUpdate.png`,
    videoLinks: [{
      link: '/shared/videos/games/curlingUpdate/curling.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/curlingUpdate/curling.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  Convergence: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'convergence/ConvergencePage',
    poster: `${ folder }/banners/Convergence.png`,
    videoLinks: [{
      link: '/shared/videos/games/convergence/convergence.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/convergence/convergence.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  Summer: _.extend({}, baseGameInfo, {
    genre: GameGenre.Sports,
    pageName: 'summergames/SummerPage',
    poster: `${ folder }/banners/Summer.png`,
    videoLinks: [{
      link: '/shared/videos/games/summer/summer.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/summer/summer.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  Sushi: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'Sushi/SushiPage',
    poster: `${ folder }/banners/sushi.png`,
    videoLinks: [{
      link: '/shared/videos/games/sushi/sushi.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/sushi/sushi.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  Elements: _.extend({}, baseGameInfo, {
    genre: GameGenre.BrainTeaser,
    pageName: 'elements/ElementsPage',
    poster: `${ folder }/banners/elements.png`,
    videoLinks: [{
      link: '/shared/videos/games/elements/elements.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/elements/elements.ogv',
      type: 'video/ogg'
    }
    ]
  }),

  TurtleHurtle: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'TurtleHurtle/TurtleHurtlePage',
    poster: `${ folder }/banners/TurtleHurtle.png`,
    videoLinks: [{
      link: '/shared/videos/games/turtlehurtle/turtlehurtle.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/turtlehurtle/turtlehurtle.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Unicycle: _.extend({}, baseGameInfo, {
    genre: GameGenre.ActionAdventure,
    pageName: 'Unicycle/UnicyclePage',
    poster: `${ folder }/banners/unicycle.png`,
    videoLinks: [{
      link: '/shared/videos/games/unicycle/unicycle.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/unicycle/unicycle.ogv',
      type: 'video/ogg'
    }
    ]
  }),
  Soccer: _.extend({}, baseGameInfo, {
    genre: GameGenre.Sports,
    pageName: 'soccer/SoccerPage',
    poster: `${ folder }/banners/Kick-Off.png`,
    videoLinks: [{
      link: '/shared/videos/games/soccer/soccer.mp4',
      type: 'video/mp4'
    },
    {
      link: '/shared/videos/games/soccer/soccer.ogv',
      type: 'video/ogg'
    }
    ]
  })
};

// Prepare
GameHelper.getDetails();

module.exports = GameHelper;
