const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const IconBadgeView = require('@common/components/iconBadge/IconBadgeView');
const IconBadgeClassModifiers = require('@common/components/iconBadge/IconBadgeClassModifiers').default;
const AchievementTypeEnum = require('@common/data/enums/AchievementTypeEnum').default;

const AchievementsCongratulatoryModalContentView = require('@training/apps/home/achievementsModal/AchievementsCongratulatoryModalContentView');

const achievementsModalKeyMap = {
  [AchievementTypeEnum.CORRECT_ANSWER_STREAK]: {
    currentBadgeKey: 'hub.achievementsModal.CorrectAnswerStreak.badgeName',
    nextBadgeKey: 'hub.achievementsModal.CorrectAnswerStreak.description',
    badgeModifierClasses: [IconBadgeClassModifiers.OK]
  },
  [AchievementTypeEnum.SESSION_STREAK]: {
    currentBadgeKey: 'hub.achievementsModal.SessionStreak.badgeName',
    nextBadgeKey: 'hub.achievementsModal.SessionStreak.description',
    badgeModifierClasses: [IconBadgeClassModifiers.ACHIEVEMENTS]
  },
  [AchievementTypeEnum.PARTICIPATION_FREQUENCY]: {
    currentBadgeKey: 'hub.achievementsModal.ParticipationFrequency.badgeName',
    nextBadgeKey: 'hub.achievementsModal.ParticipationFrequency.description',
    badgeModifierClasses: [IconBadgeClassModifiers.FREQUENT_TRAINING]
  },
  [AchievementTypeEnum.TOPIC_LEVEL_GRADUATION_STREAK]: {
    currentBadgeKey: 'hub.achievementsModal.TopicLevelGraduationStreak.badgeName',
    nextBadgeKey: 'hub.achievementsModal.TopicLevelGraduationStreak.description',
    badgeModifierClasses: [IconBadgeClassModifiers.GRADUATED]
  }
};

class AchievementsCongratulatoryModalContentController extends LayoutController {
  initialize(options = {}) {
    super.initialize(options);

    this.viewDefinition = this.viewDefinition.bind(this);
    this.model = options.model;
  }

  viewDefinition() {
    return {
      ViewClass: AchievementsCongratulatoryModalContentView,
      templateHelpers: {
        badgeNumber: this.model.get('currentMilestone')
      }
    };
  }

  regionControllers() {
    const achievementType = this.model.get('achievementType');
    const currentMilestone = this.model.get('currentMilestone');
    const nextMilestone = this.model.get('nextMilestone');

    const currentBadgeName = I18n.t(achievementsModalKeyMap[achievementType].currentBadgeKey);
    const nextBadgeName = I18n.t(achievementsModalKeyMap[achievementType].nextBadgeKey);
    const badgeModifierClasses = achievementsModalKeyMap[achievementType].badgeModifierClasses;

    const iconClass = `icon-${ achievementType }${ currentMilestone.toString() }`;

    return {
      modalBadgeRegion: {
        id: 'modal__badge-region',
        viewDefinition: {
          ViewClass: IconBadgeView,
          iconClass,
          badgeModifierClasses
        }
      },
      modalStringRegion: {
        id: 'modal__string-region',
        viewDefinition: {
          ViewClass: ItemView,
          className: 'modal__string-wrapper',
          template: `
            <h1 class="modal-title"><%- t('hub.achievementsModal.title') %></h1>
            <h2 class="current-badge" aria-label="<%- t('hub.achievementsModal.currentBadgeAriaLabel', {badgeName: currentBadgeName, currentMilestone: currentMilestone }) %>">
              <%- t('hub.achievementsModal.currentBadge', { badgeName: currentBadgeName }) %>
            </h2>
            <p class="next-badge"><%- t('hub.achievementsModal.nextBadge', { badgeNumber: nextMilestone, badgeName: nextBadgeName }) %></p>
          `,
          templateHelpers: () => {
            return {
              currentMilestone,
              nextMilestone,
              currentBadgeName,
              nextBadgeName
            };
          }
        }
      }
    };
  }
}

module.exports = AchievementsCongratulatoryModalContentController;
