const {
  send,
  LOG_LEVELS
} = require('@common/prerequisites/libs/logging/RemoteLogger');

const {
  format,
  applyDefaults,
  mergeFormatterOptions
} = require('@common/prerequisites/libs/DataFormatter');

const LogDetailFormatters = require('@common/prerequisites/libs/logging/LogDetailFormatters');
const LoggingDataValueMappers = require('@common/prerequisites/libs/logging/LoggingDataValueMappers');

const logDataFormatOptions = {
  valueMappers: LoggingDataValueMappers,
  formatters: LogDetailFormatters,
  formatSequence: [
    'suppressed',
    'logMessage',
    'api',
    'error'
  ]
};

const formatLogMessage = (logData = {}, formatterOptions = {}) => {
  const logFormatterOptions = mergeFormatterOptions(logDataFormatOptions, formatterOptions);
  return format(logData, logFormatterOptions);
};

const flattenObject = (obj, target = {}, prefix = '', separator = '_') => {
  return Object.entries(obj || {}).reduce((memo, [key, value] = []) => {
    const newKey = `${ prefix }${ key }`;

    if (value != null && typeof value === 'object' && Object.entries(value).length > 0) {
      flattenObject(value, target, `${ newKey }${ separator }`);
    } else {
      // eslint-disable-next-line no-param-reassign -- reduce
      memo[newKey] = String(value);
    }

    return memo;
  }, target);
};

const formatIndexedFields = (logData = {}) => {
  const normalizedData = applyDefaults(logData, logDataFormatOptions.valueMappers);
  return flattenObject(normalizedData);
};

const processLogOptions = (options = {}) => {
  const {
    logData = {},
    formatterOptions = {},
    dataFormatters: {
      message,
      indexedFields
    } = {}
  } = options;

  const sendOptions = Object.assign({}, options);
  sendOptions.message = options.message || message(logData, formatterOptions);
  sendOptions.indexedFields = options.indexedFields || indexedFields(logData);

  // need a constant field to hold the error string so that
  // the log dashboard does not need to combine them.
  if (options.level === LOG_LEVELS.ERROR) {
    let jsErrorMsg = sendOptions.message;

    if (logData.api) {
      jsErrorMsg = jsErrorMsg.replace(/\?\S+/, '?<params>');
      jsErrorMsg = jsErrorMsg.replace(/\/axonify\/(admin|teamadmin|technicaladmin|user|content|report|manager|selfregistered|guest|bucontent|facilitator)\//i, '/axonify/<userType>/');
      jsErrorMsg = jsErrorMsg.replace(/\/[0-9]+/g, '/<id>');
    }

    sendOptions.indexedFields.jsError = jsErrorMsg;
  }

  delete sendOptions.logData;
  delete sendOptions.formatterOptions;
  delete sendOptions.dataFormatters;

  return sendOptions;
};

const sendLog = (options = {}) => {
  Object.assign(options, {
    dataFormatters: Object.assign({
      message: formatLogMessage,
      indexedFields: formatIndexedFields
    }, options.dataFormatters)
  });

  const processedOptions = processLogOptions(options);

  send(processedOptions);
};

const sendWarnLog = (options = {}) => {
  sendLog(Object.assign({ level: LOG_LEVELS.WARN }, options));
};

const sendErrorLog = (options = {}) => {
  sendLog(Object.assign({ level: LOG_LEVELS.ERROR }, options));
};

module.exports = {
  REMOTE_LOG_LEVELS: LOG_LEVELS,
  sendLog,
  sendWarnLog,
  sendErrorLog,
  formatLogMessage
};
