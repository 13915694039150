const _ = require('underscore');
const Backbone = require('Backbone');
const Game = require('@common/data/models/Game');
class GamePlay extends Backbone.Model {
  apiEndpoint() {
    return '/gamePlays';
  }

  preinitialize() {
    this.performGameActions = this.performGameActions.bind(this);
  }

  parse(response = {}) {
    return response.entity != null ? response.entity : response;
  }

  initialize() {
    this.gameActionsPromises = [];
    this.game = new Game();
    this.listenTo(this, 'change:game', (gamePlayModel, game = {}) => {
      this.game.set(game);
    });
  }

  isInProgress() {
    const isActive = !this.isNew();

    let hasType = false;
    if (this.get('game') && this.get('game').type != null) {
      hasType = true;
    }

    const isComplete = this.get('isComplete') || false;
    return isActive && hasType && !isComplete;
  }

  startGamePlay() {
    if (this.isNew()) {
      return;
    }
    this.isPaused = false;
    this.timerId = window.apps.base.timeLogController.startGame(this.id);
  }

  pauseGamePlay() {
    if (this.timerId == null) {
      return;
    }
    this.isPaused = true;
    window.apps.base.timeLogController.pause(this.timerId);
  }

  resumeGamePlay() {
    if (this.timerId == null) {
      return;
    }
    this.isPaused = false;
    window.apps.base.timeLogController.resume(this.timerId);
  }

  stopGamePlay() {
    if (this.timerId == null) {
      return undefined;
    }
    this.isPaused = false;
    const timeLogEntry = window.apps.base.timeLogController.stop(this.timerId);
    delete this.timerId;
    return timeLogEntry;
  }

  restartGamePlay() {
    // Restart the game play; pause the game play if it was paused before
    if (this.isInProgress()) {
      this.startGamePlay();

      if (this.isPaused) {
        this.pauseGamePlay();
      }
    }
  }

  skipGame() {
    return this.updateGamePlay({isComplete: true});
  }

  sendVictoryMessage(options = {}, success = () => {}) {
    const {
      message,
      score,
      opponent
    } = options;

    const gameName = this.game.get('name');

    const msgObj = {
      simpleMessage: 'A user has defeated you in a game!',
      type: 'GAME_VICTORY_DECLARATION',
      message: {
        text: message,
        name: gameName,
        score: score,
        params: {
          gameName
        }
      },
      user: {
        id: opponent.id
      }
    };

    return $.ajax({
      type: 'POST',
      url: '/axonify/userMessages',
      data: JSON.stringify(msgObj),
      success
    });
  }

  performGameActions(data = {}, options = {}) {
    if (this.isNew()) {
      return $.Deferred.reject().promise();
    }

    options.success = _.wrap(options.success, (oldSuccess, response = {}) => {
      const { gamePlay } = response;
      if (gamePlay != null) {
        this.set(gamePlay);
      }
      return (typeof oldSuccess === 'function' ? oldSuccess(response) : undefined);
    });
    // Chain the game actions calls in case some of them are dependent on a prior
    // call being complete before the next one is executed
    return $.when(...Array.from(this.gameActionsPromises || [])).always(() => {
      return this.gameActionsPromises.push($.ajax(_.extend(options, {
        type: 'POST',
        url: `${ this.url() }/actions`,
        data: JSON.stringify(data)
      })));
    });
  }

  updateGamePlay(data, options = {}) {
    if (data == null || this.isNew()) {
      return $.Deferred.reject().promise();
    }

    // GamePlay duration is cumulative
    if (data.duration != null) {
      data.duration += (this.get('duration') || 0);
    }

    const updateOptions = _.extend({patch: true, type: 'PUT'}, options);
    // Wait until all game actions are complete before updating game play
    return $.when(...Array.from(this.gameActionsPromises || []))
      .always(() => {
        return this.save(data, updateOptions);
      });
  }
}
module.exports = GamePlay;
