const AssessmentTopicOption = require('@common/data/models/assessments/AssessmentTopicOption');
const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');

const ObjectiveToAssessmentTypeMap = require('@common/data/models/assessments/ObjectiveToAssessmentTypeMap');

class GuidedLearningOption extends AssessmentTopicOption {

  static ObjectiveToAssessmentTypeMap = ObjectiveToAssessmentTypeMap;

  constructor(objective, programId, ...args) {
    super(...args);
    const type = objective.getType();

    this.set({
      objective,
      programId,
      type,
      [AssessmentTopicOption.FIELDS.FOR_ASSESSMENT_TYPE]: ObjectiveToAssessmentTypeMap[type],
      id: objective.id
    });

    if (type === GuidedLearningObjectiveType.TOPIC_OBJECTIVE
        || type === GuidedLearningObjectiveType.CERTIFICATION_OBJECTIVE) {
      this.set({
        topic: {
          name: objective.getName(),
          id: objective.getObjectiveDetails().topicId
        },
        subject: {
          name: objective.getSubjectName()
        },
        category: {
          name: objective.getCategoryName()
        },
        pointsForPass: objective.getPointsAvailableForCompletion(),
        questionCount: objective.getNumberOfQuestions(),
        level: objective.getLevel(),
        isRetake: objective.isRetake(),
        launchContext: this.getLaunchContext()
      });
    } else if (type === GuidedLearningObjectiveType.TASK_OBJECTIVE) {
      this.set({
        task: {
          name: objective.getName(),
          id: objective.getLinkedTaskId()
        },
        topic: {
          name: objective.getTopicName()
        },
        subject: {
          name: objective.getSubjectName()
        },
        category: {
          name: objective.getCategoryName()
        },
        pointsAvailable: objective.getPointsAvailableForCompletion()
      });
    } else if (type === GuidedLearningObjectiveType.EVENT_OBJECTIVE) {
      this.set({
        event: {
          id: objective.getEventId(),
          name: objective.getName(),
          numScheduledLearningEventsAvailable: objective.getNumScheduledEventsAvailable()
        }
      });
    } else if (type === GuidedLearningObjectiveType.EVALUATION_OBJECTIVE) {
      this.set({
        evaluation: {
          id: objective.getEvaluationId(),
          name: objective.getName()
        },
        pointsForPass: objective.getPointsAvailableForCompletion()
      });
    }
  }

  getLaunchContext() {
    return AssessmentLaunchContext.GUIDED_LEARNING;
  }

  getObjective() {
    return this.get('objective');
  }

  getTaskId() {
    return this.get('task').id;
  }

  getEventId() {
    return this.get('event').id;
  }

  getEvaluationId() {
    return this.get('evaluation').id;
  }
}

module.exports = GuidedLearningOption;
