const Backbone = require('Backbone');

class LearningEventSessionList extends Backbone.Collection {
  apiEndpoint() {
    return `/learning-events/${ this.eventId }/scheduled-events/${ this.scheduledEventId }/sessions/all`;
  }

  initialize(models = [], options = {}) {
    ({eventId: this.eventId, scheduledEventId: this.scheduledEventId} = options);

    super.initialize(models, options);
  }

  parse(res) {
    return res.results;
  }
}

module.exports = LearningEventSessionList;
