const logging = require('logging');

const ActivityPageFactory = require('@training/apps/training/views/activities/ActivityPageFactory');
const ActivityTypePageFactories = require('@training/apps/training/views/activities/ActivityTypePageFactories');
const DefaultActivityPageOptionsFactory = require('@training/apps/training/views/activities/DefaultActivityPageOptionsFactory');

const ActivityPresenter = require('@training/apps/training/controllers/ActivityPresenter');

class ActivitiesController {

  // parentProcessor is used for the finishedCallback, if the current processor
  // is the root of the hierarchy then parent processor will be null/nothing
  constructor(parentProcessor, sessionModel) {
    this.processActivities = this.processActivities.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);
    this.parentProcessor = parentProcessor;
    this.sessionModel = sessionModel;

    const pageFactory = ActivityPageFactory(ActivityTypePageFactories, DefaultActivityPageOptionsFactory({
      trainingSession: this.sessionModel,
      // TODO pass the user in from higher up the class chain when there's time to do it.
      currentUser: window.apps.auth.session.user
    }));

    this.activityPresenter = new ActivityPresenter(pageFactory);
  }

  // The implementations will handle the activities that it cares about.
  processActivities() {
    logging.debug('Default process activities');
    const activity = this.getNextActivity();

    if (activity) {
      this.showActivity(activity, {
        complete: this.processActivities
      });
    } else {
      this.finishedProcessing();
    }
  }

  // Controller implementations can override this to let the base implementation
  // of processActivities work on a subset of activities
  getNextActivity() {
    logging.debug('Default next activity');
    return this.sessionModel.currentAssessment.activities.nextActivity();
  }

  showActivity(activity, options = {}) {
    this.activityPresenter.show(activity, options);
  }

  // The controller will call this on itself when it's done with everything that
  // it cares about. Its implementation should call 'super' at the end of the
  // method.
  finishedProcessing() {
    const {
      finishedProcessing = () => {}
    } = this.parentProcessor;

    finishedProcessing.call(this.parentProcessor);
  }
}

module.exports = ActivitiesController;
