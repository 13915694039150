const VariantType = require('@common/data/enums/VariantType');

module.exports = {
  [VariantType.MultipleChoice]: require('@training/apps/training/views/activities/pages/questions/MultipleChoiceQuestionPage'),
  [VariantType.AdvancedMultipleChoice]: require('@training/apps/training/views/activities/pages/questions/MultipleChoiceQuestionPage'),
  [VariantType.MultipleAnswer]: require('@training/apps/training/views/activities/pages/questions/MultipleAnswerQuestionPage'),
  [VariantType.Matching]: require('@training/apps/training/views/activities/pages/questions/MatchingQuestionPage'),
  [VariantType.JumbledSentence]: require('@training/apps/training/views/activities/pages/questions/FillInTheBlanksQuestionPage'),
  [VariantType.Hotspot]: require('@training/apps/training/views/activities/pages/questions/HotspotQuestionPage')
};
