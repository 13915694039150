// Module
var code = `<h1><%- t(gameOverStringId) %></h1>

<div class="endscore clearfix">
  <% if (_.isUndefined(challenge)) { %>
    <div class="no-challenge">
      <h2 class="score-title"><%- t('games.gamedone') %></h2>
      <%- numberWithCommas(score) %>
    </div>
  <% } else { %>
    <h2><%- t('games.outro.challenge') %></h2>
    <div class="challenge-end">
      <div class="challenge-region"></div>
    </div>
  <% } %>
</div>

<h2 class="top-players-title"><%- t('games.topplayers') %></h2>

<table class="podium">
  <tr class="user-1">
    <td class="rank-cell">
      <div class="position-1" role="img" aria-label="<%- t('achievements.iconLabels.GameHighScore1') %>"></div>
    </td>

    <% if(highScores[0]) { %>
      <td class="padded-cell">
        <div class="avatar small-name-logo" aria-hidden="true"></div>
        <div class="text-ellipsis"><%- highScores[0].name %></div>
      </td>
    <% } else { %>
      <td class="emptyslot padded-cell"><div class="text-ellipsis"><%- t('games.outro.emptyslot') %></div></td>
    <% } %>
    <td class="text-right score-cell"><% if(highScores[0]) { %><%- numberWithCommas(highScores[0].leadScore) %><% } %></td>
  </tr>

  <tr class="odd user-2">
    <td><div class="position-2" role="img" aria-label="<%- t('achievements.iconLabels.GameHighScore2') %>"></div></td>
    <% if(highScores[1]) { %>
      <td class="padded-cell">
        <div class="avatar small-name-logo" aria-hidden="true"></div>
        <div class="text-ellipsis"><%- highScores[1].name %></div>
      </td>
    <% } else { %>
      <td class="emptyslot padded-cell"><div class="text-ellipsis"><%- t('games.outro.emptyslot') %></div></td>
    <% } %>
    <td class="text-right" <% if(highScores[1] == null) { %> aria-hidden="true" <% } %>>
      <% if(highScores[1]) { %><%- numberWithCommas(highScores[1].leadScore) %><% } %>
    </td>
  </tr>

  <tr class="user-3">
    <td><div class="position-3"  role="img" aria-label="<%- t('achievements.iconLabels.GameHighScore3') %>"></div></td>
    <% if(highScores[2]) { %>
      <td class="padded-cell">
        <div class="avatar small-name-logo" aria-hidden="true"></div>
        <div class="text-ellipsis"><%- highScores[2].name %></div>
      </td>
    <% } else { %>
      <td class="emptyslot padded-cell"><div class="text-ellipsis"><%- t('games.outro.emptyslot') %></div></td>
    <% } %>
    <td class="text-right" <% if(highScores[2] == null) { %> aria-hidden="true" <% } %>>
      <% if(highScores[2]) { %><%- numberWithCommas(highScores[2].leadScore) %><% } %>
    </td>
  </tr>
</table>

<% if (questionsRemaining > 0) { %>
  <h2 class="questionsleft">
    <% if(questionsRemaining == 1) {%>
      <%- t('games.gameover.remainingquestion') %>
    <% } else { %>
      <%- t('games.gameover.remainingquestions', {questionsleft: questionsRemaining}) %>
    <% } %>
  </h2>
<% } %>
`;
// Exports
module.exports = code;