const { ItemView } = require('Marionette');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TrainingCardBottomRegionLayoutView = require('@common/components/trainingCards/TrainingCardBottomRegionLayoutView');
const TrainingCardBottomInfoView = require('@common/components/trainingCards/TrainingCardBottomInfoView');
const ProgressBar = require('@common/components/progress/progressBar/ProgressBar');

const getProgressRegionConfig = function({progressPercent} = {}) {
  let result;

  if (progressPercent || progressPercent === 0) {
    result = {
      viewControllerClass: ItemView,
      viewDefinition: {
        ViewClass: ProgressBar,
        value: progressPercent,
        animate: false
      }
    };
  }

  return result;
};

const getBottomInfoRegionConfig = function({
  trainingCardType,
  numAvailable,
  fullCardCallback,
  title,
  isStartable,
  actionCallback,
  actionMetaData,
  actionType,
  isRecommended,
  isOverdue,
  isDueToday,
  isComplete,
  numCompleted,
  total,
  daysUntilDue,
  isGraduatedFromAllLevel
} = {}) {
  return {
    viewControllerClass: ItemView,
    viewDefinition: {
      ViewClass: TrainingCardBottomInfoView,
      trainingCardType,
      numAvailable,
      fullCardCallback,
      title,
      isStartable,
      actionCallback,
      actionMetaData,
      actionType,
      isRecommended,
      isOverdue,
      isDueToday,
      isComplete,
      numCompleted,
      total,
      daysUntilDue,
      isGraduatedFromAllLevel
    }
  };
};

module.exports = {
  getTrainingCardBottomRegionConfig: ({
    trainingCardType,
    progressPercent,
    numAvailable,
    fullCardCallback,
    title,
    isStartable,
    actionCallback,
    actionMetaData,
    actionType,
    isRecommended,
    isOverdue,
    isDueToday,
    isComplete,
    numCompleted,
    total,
    daysUntilDue,
    isGraduatedFromAllLevel
  } = {}) => {

    const controllerDefinition = {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: TrainingCardBottomRegionLayoutView,
        progressPercent
      },
      regionControllers: {
        progressRegion: getProgressRegionConfig({progressPercent}),
        infoRegion: getBottomInfoRegionConfig({
          trainingCardType,
          numAvailable,
          fullCardCallback,
          title,
          isStartable,
          actionCallback,
          actionMetaData,
          actionType,
          isRecommended,
          isOverdue,
          isDueToday,
          isComplete,
          numCompleted,
          total,
          daysUntilDue,
          isGraduatedFromAllLevel
        })
      }
    };

    return controllerDefinition;
  }
};
