const Backbone = require('Backbone');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const TimelineCardViewController = require('@training/apps/timeline/TimelineCardViewController');

const searchPageState = new Backbone.Model();

const viewConfig = (articleData) => {

  const tenantPropertyProvider = TenantPropertyProvider.get();
  const showAuthor = tenantPropertyProvider.getProperty('dzShowAuthorAndContributorInfo');
  const showReactions = true; // we show reaction trays in post detail view
  const showFullContent = true; // we show the whole content, not a truncated preview, in post detail view

  // we need to massage the data a little, because the schema returned for the timeline view
  // isn't the same as what we get for a single Post.
  articleData.set('authorName', articleData.get('author').user.salutationName);
  articleData.set('lastModified', articleData.get('modifyTimestamp'));

  return {
    ViewControllerClass: TimelineCardViewController,
    viewDefinition: {
      className: 'post-container',
      attributes: {},
      events: {
        // override the click action on the card
        click: () => {}
      }
    },
    model: articleData,
    itemViewOptions: {
      searchPageState,
      showAuthor,
      showFullContent,
      showReactions
    },
    showFullContent: true, // in this view we show the entire content of a post
    showComments: true, // in the post details page, we show comments. This will be off in the timeline view
    delegateEvents: {
      'view:attach': () => {
        window.app.layout.showHeaderBackButton();
      },
      'view:render': (controller, view) => {
        apps.base.timeLogController.bindPageViewLog(view, 'ArticleDetailsPage', articleData.get('id'));
      }
    }
  };
};

module.exports = viewConfig;

