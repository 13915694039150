const logging = require('logging');
const _ = require('underscore');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const { sendLog } = require('LoggingService');

const SCORMAPIBuilder = require('@common/libs/scorm/SCORMAPIBuilder');
const SCORMManifestParser = require('@common/libs/scorm/SCORMManifestParser');
const SCORMManifestFetcher = require('@common/libs/scorm/SCORMManifestFetcher');
const SCORMCompletionStatusEnum = require('@common/data/enums/SCORMCompletionStatusEnum');
const Stopwatch = require('@common/libs/Stopwatch');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class SCORMRTE {
  constructor(options = {}) {
    this.isScoComplete = this.isScoComplete.bind(this);
    this.addToCommitQueue = this.addToCommitQueue.bind(this);
    this.pushToServer = this.pushToServer.bind(this);
    this.completeSco = this.completeSco.bind(this);

    this.options = options;

    ({
      onFinish: this.onFinish = () => {},
      onInitialize: this.onInitialize = () => {},
      onFinalizeCommitCache: this.onFinalizeCommitCache = () => {},
      ajax: this.ajax,
      scoHref: this.scoHref,
      scormVersion: this.scormVersion,
      launchData: this.launchData
    } = options);

    const {
      duration = 0
    } = options;

    logging.debug('SCORMRTE.init');

    // Resources
    this._persistenceStrategy = this.options.persistanceStrategy;
    this.student = this._parseStudent(this.options.student);

    // State
    this.commitCache = this.arrayToObject(this.options.scoValues);
    this.commitCacheFinal = {}; // commit cache sent to server

    // if this.scoHref is specified, we assume this.scormVersion & this.launchData are specified
    // and there is no need to fetch manifest
    if (this.scoHref == null) {
      this.setScoMap();
      for (const scoId in this.scoMap) {
        if (this.scoMap[scoId].href != null) {
          this.scoHref = this.scoMap[scoId].href;
        }
      }
    }
    this.lastError = 0;
    this.onCompleteShown = false;
    this.stopwatch = new Stopwatch();
    this.stopwatch.setElapsedTime(duration);
    this.forcePushToServer = false;

    // Setup
    this.createAPI();

    // Process Queue for pushing updates to server
    this.commitQueue = [];

    this.lastModify = this.options.lastModify;
    this.deferredProcessCommitQueue = _.throttle(this.processCommitQueue, 30000);

    if (this.isScoComplete(this.commitCache)) {
      sendLog({
        logData: {
          logMessage: `SCORM module was initialized as completed`
        }
      });
    }
  }

  updateScoIfComplete() {
    if (this.isScoComplete(this.commitCache)) {
      this.completeSco();
    }
  }

  _parseStudent(student) {
    const parsedStudent = Object.assign({}, student);
    const tenantPropertyPool = TenantPropertyProvider.get();
    if (tenantPropertyPool.hasProperties() && tenantPropertyPool.getProperty('scormCommaSeparatedFormat')) {
      const fullName = student.name;
      const {
        firstName,
        lastName
      } = StringHelpers.getFirstLastNames(fullName);
      parsedStudent.name = `${ lastName }, ${ firstName }`;
    }
    return parsedStudent;
  }

  launchInContentFrame($contentFrame) {
    $contentFrame.on('load', () => {
      $contentFrame.off('load');
      $contentFrame[0].contentWindow.location.replace(this.scoHref);
    });
  }

  setScoMap() {
    const scormManifestFetcher = new SCORMManifestFetcher({ajax: this.ajax});
    const {manifestURL} = this.options;

    // set the options for parsing the manifest and set the scoMap
    const options = {
      url: manifestURL,
      success: (data, status, jqXHR) => {
        // TODO: when DZ gets jquery updated, this will only need to use .responseXML, so remove .responseText.
        const manifestXML = $(jqXHR.responseXML || jqXHR.responseText);
        const parser = new SCORMManifestParser();
        ({
          scormVersion: this.scormVersion,
          scoMap: this.scoMap,
          launchData: this.launchData
        } = parser.parse(manifestXML, manifestURL));
      }
    };

    scormManifestFetcher.getManifest(options);
  }

  objectToArray(obj) {
    return _.map(obj, (value, key) => {
      return {
        scoKey: key,
        scoValue: value
      };
    });
  }

  arrayToObject(arr) {
    const obj = {};
    const reducer = function (memo, tuple) {
      memo[tuple.scoKey] = tuple.scoValue;
      return memo;
    };
    return _.reduce(arr, reducer, obj);
  }

  isScoComplete() {
    return window[this.scormVersion].IsScormComplete();
  }

  // since the same object is being sent to the server
  // there only needs to be one item in the queue
  addToCommitQueue(item = {}) {
    logging.info('SCORMRTE - addToCommitQueue');
    // there's no item so push onto the queue
    this.commitQueue.push(item);

    // ...and process the queue

    this.deferredProcessCommitQueue();
  }

  processCommitQueue() {
    logging.info('SCORMRTE - processCommitQueue');
    // return from this process if:
    //  1. there are no items in the queue
    //  2. currently being processed
    //  3. if Scorm is complete and completeCallback has not been defined
    //     this is to make sure the scorm page is the one that closes the
    //     activity
    //  4. activity is not underway
    if (
      this.commitQueue.length === 0
      || this.processingQueueItem
      || (this.isScoComplete() && this.completeCallback == null)
      || !this._persistenceStrategy.canSaveProgress()
    ) {
      return;
    }

    this.processingQueueItem = true;

    // again, because there is only going to be one item
    // in the queue,  clear the queue
    this.commitQueue = [];

    // now push to Server and call process queue
    // when it succeeds
    this.pushToServer(() => {
      logging.info('SCORMRTE - processCommitQueue (pushToServer callback)');
      // execute the callback if it exists
      if (typeof this.completeCallback === 'function') {
        logging.info('SCORMRTE - processCommitQueue (before completeCallback is called)');
        this.completeCallback();
      }

      // processing of queue is done so let next processing
      // go through
      this.processingQueueItem = false;

      if (this.forcePushToServer) {
        this.processCommitQueue()
        this.forcePushToServer = false;
      } else {
        this.deferredProcessCommitQueue();
      }
    });
  }

  completeProcessing(callback) {
    logging.info('SCORMRTE - completeProcessing');

    this.completeCallback = callback;

    this.commitQueue.push({});
    this.forcePushToServer = true;
    this.processCommitQueue();
  }

  pushToServer(callback) {
    const isScoComplete = this.isScoComplete(this.commitCacheFinal);

    const body = {
      scormScoId: this.options.scormScoId,
      scormModuleId: this.options.scormModuleId,
      scoStatus: isScoComplete ? SCORMCompletionStatusEnum.completed : SCORMCompletionStatusEnum.notCompleted,
      scoValues: this.objectToArray(this.commitCacheFinal),
      lastModify: this.lastModify,
      duration: this.stopwatch.elapsedTime()
    };

    logging.debug(`Pushing Update to server: ${ JSON.stringify(body, null, 2) }`);

    return this._persistenceStrategy.saveProgress(body, (lastModify) => {
      if (lastModify != null) {
        this.lastModify = lastModify;
      }
      return typeof callback === 'function' ? callback(isScoComplete) : undefined;
    });
  }

  finalize(commitCache) {
    $.extend(true, this.commitCacheFinal, commitCache);
    this.onFinalizeCommitCache(this.objectToArray(this.commitCacheFinal), this.lastModify, this.stopwatch.elapsedTime());

    if (this.isScoComplete(this.commitCacheFinal)) {
      this.completeSco();
    }
  }

  completeSco() {
    if (!this.onCompleteShown && this.options.onComplete != null) {
      this.options.onComplete();
      this.onCompleteShown = true;
    }
  }

  createAPI() {
    const interceptor = (commitCache) => {
      this.finalize(commitCache);

      // Push updates to server if SCORM is not complete
      if (!this.isScoComplete(commitCache)) {
        this.addToCommitQueue();
      }
    };

    // Implements of the various API stubs we need to support
    const LMSInitialize = () => {
      logging.debug('LMSInitialize');
      this.stopwatch.start();
      this.onInitialize();
      return 'true';
    };

    const LMSFinish = () => {
      logging.debug(`LMSFinish: ${ JSON.stringify(this.commitCache) }`);
      this.stopwatch.stop();
      this.finalize(this.commitCache);
      this.onFinish();
      return 'true';
    };

    // Error Handling
    const LMSGetLastError = () => {
      logging.debug('LMSGetLastError');
      return this.lastError;
    };

    const LMSGetDiagnostic = function () {
      logging.debug('LMSGetDiagnostic');
      return 'diagnostic string';
    };

    const LMSGetErrorString = function () {
      logging.debug('LMSGetErrorString');
      return 'error string';
    };

    const LMSCommit = (commitCache) => {
      return this.finalize(commitCache);
    };

    // This also creates the global object, since it knows how to based on the version you selected
    const options = {
      initialize: LMSInitialize,
      finish: LMSFinish,
      getLastError: LMSGetLastError,
      getErrorString: LMSGetErrorString,
      getDiagnostic: LMSGetDiagnostic,
      commit: LMSCommit
    };

    const API = SCORMAPIBuilder({
      versionKey: this.scormVersion,
      studentInfo: this.student,
      commitCache: this.commitCache
    }).createForVersion(options)
      .withValueInterceptor(interceptor)
      .withReaderForKey('cmi.launch_data', this.launchData)
      .build();

    window[this.scormVersion] = API;
  }
}

module.exports = SCORMRTE;
