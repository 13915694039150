// Module
var code = `<div class="squire-iframe squire-editor editor-container froala-editor" id="squire_editor" role="application" aria-label="<%- t('comms.posts.textEditor') %>"></div>
<div class="media-container"></div>
<form class="media-form">
  <div class="media-input-wrap">
    <input type="file" accept="<%- allowableMediaTypes %>" class="local-media" aria-label="<%- t('comms.posts.addMediaButton') %>"/>
  </div>
</form>
`;
// Exports
module.exports = code;