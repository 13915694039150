// Module
var code = `<td><%= getFileDownload() %></td>
<td><%- userFullName %></td>
<td><%- date %></td>
<td><%- statusPhrase %></td>
<td>
  <p><%- t('imports.result.rows_successful', { rows: totalSuccessfulRows }) %></p>
  <p><%- t('imports.result.rows_skipped', { rows: totalSkippedRows }) %></p>
  <p><%- t('imports.result.rows_failed', { rows: totalFailedRows }) %></p>
  <p><%- t('imports.result.rows_unchanged', { rows: totalUnchangedRows }) %></p>
</td>
<td>
  <div>
    <a href="<%= authFragment %>/report/bulkimport/audit?id=<%= id %>">
      <%- t('imports.audit.title') %>
    </a>
  </div>
  <div>
    <a href="#" class="js-view-errors"><%- t('imports.error.view_errors') %></a>
  </div>
</td>
`;
// Exports
module.exports = code;