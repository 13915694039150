const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const CommunitiesView = require('@training/apps/search/communitiesManagement/communities/CommunitiesView');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const PageableTableController = require('@common/components/pageableList/PageableTableController');
const { getTableHeaders } = require('@training/apps/search/communitiesManagement/CommunityManagementTableHelper');
const CommunitiesManagementTableEnum = require('@training/apps/training/enums/CommunitiesManagementTableEnum');
const CommunitiesTableItemView = require('@training/apps/search/communitiesManagement/communities/CommunitiesTableItemView');
const CommunitiesSearchView = require('@training/apps/search/communitiesManagement/communities/CommunitiesSearchView');

const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const FileFactory = require('@common/libs/file/FileFactory');
const ImportHelpers = require('@common/libs/helpers/features/ImportHelpers');

const IMPORT_TYPE = 'LOCATION_COMMUNITY_IMPORT';

class CommunitiesController extends LayoutController {
  initialize(options = {}) {
    ({
      managementPageState: this.managementPageState,
      communityList: this.communityList
    } = options);

    this.resetFileInput = this.resetFileInput.bind(this);
    this.uploadAndImport = this.uploadAndImport.bind(this);

    this.listenTo(this, 'view:set:import', (controller, view, importRef) => {
      this.importRef = importRef;
    });
    this.listenTo(this, 'view:select:file', (controller, view, file) => {
      this.uploadAndImport(file);
    });
    this.listenTo(this, 'view:reset:import', this.resetFileInput);
  }

  viewDefinition() {
    return {
      ViewClass: CommunitiesView,
      fileFactory: FileFactory
    };
  }

  regionControllers() {
    const isCommsEnabled = TenantPropertyProvider.get().getProperty('communicationsEnabled');
    const headerNames = getTableHeaders(CommunitiesManagementTableEnum.COMMUNITIES, isCommsEnabled);

    const regions = {
      searchRegion: {
        viewDefinition: {
          ViewClass: CommunitiesSearchView,
          managementPageState: this.managementPageState,
          isCommsEnabled,
          shouldShowEllipsisMenu: this.shouldShowEllipsisMenu()
        }
      },
      communitiesRegion: {
        ViewControllerClass: PageableTableController,
        itemViewClass: TableLayoutView,
        rowItemView: CommunitiesTableItemView,
        collection: this.communityList,
        headerNames: headerNames,
        delegateEvents: {
          'view:inflate': () => {
            this.listenTo(this.communityList, 'destroy', () => {
              this.communityList.search(this.managementPageState.get('searchData'), 0);
            });
          }
        }
      }
    };

    if (this.shouldShowEllipsisMenu()) {
      const ellipsisMenuRegion = {
        viewDefinition: {
          ViewClass: MenuDropdown,
          buttonConfig: {
            buttonIcon: 'icon-ellipsis_horizontal',
            buttonAriaLabel: 'communitiesManagement.communities.importExportDropdown.label',
            popupAlignment: MenuDropdownPositionEnum.RIGHT + MenuDropdownPositionEnum.BOTTOM
          },
          optionsListConfig: [
            {
              buttonText: 'communitiesManagement.communities.exportTeamCommunities.label',
              buttonClass: 'export-team-communities',
              buttonAriaLabel: 'communitiesManagement.communities.exportTeamCommunities.label',
              clickCallback: () => {
                window.location.href = '/axonify/admin/pageCommunities/config/team-communities-export.csv';
              }
            },
            {
              buttonText: 'communitiesManagement.communities.downloadTeamsTemplate.label',
              buttonClass: 'download-teams-template',
              buttonAriaLabel: 'communitiesManagement.communities.downloadTeamsTemplate.label',
              clickCallback: () => {
                window.location.href = '/axonify/admin/pageCommunities/config/team-communities-template-export.csv';
              }
            },
            {
              buttonText: 'communitiesManagement.communities.importTeamCommunities.label',
              buttonClass: 'import-team-communities',
              buttonAriaLabel: 'communitiesManagement.communities.importTeamCommunities.label',
              clickCallback: () => {
                // Open the file picker to choose an import file
                this.importRef.trigger('click');
              }
            }
          ]
        }
      };
      _.extend(regions, {ellipsisMenuRegion});
    }

    return regions;
  }

  shouldShowEllipsisMenu() {
    return TenantPropertyProvider.get().getProperty('communicationsEnabled');
  }

  uploadAndImport(file) {
    const importType = IMPORT_TYPE;

    if (file) {
      window.app.layout.showSpinner();
      file.save(null, {
        success: (model) => {
          ImportHelpers.bulkImport(importType, model.get('id'))
            .done(() => {
              window.app.layout.flash.success(I18n.t('communitiesManagement.communities.import.success'));
            })
            .fail((xhr) => {
              xhr.skipGlobalHandler = true;
              window.app.layout.flash.error(I18n.t('communitiesManagement.communities.import.fail', {id: model.get('id')}));
            })
            .always(() => {
              window.app.layout.hideSpinner();
              this.resetFileInput();
            });
        },
        error: (model, response) => {
          window.app.layout.hideSpinner();
          window.app.layout.flash.error(response.errMessage);
          this.resetFileInput();
        }
      });
    }
  }

  // Clear the input between attempts.
  resetFileInput() {
    this.importRef.val(null);
  }
}

module.exports = CommunitiesController;
