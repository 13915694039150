const { omit } = require('underscore');
const $os = require('detectOS');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

class CreatePostAccessibleModalView extends AccessibleModalView {

  behaviors() {
    return omit(super.behaviors(), 'ModalScrollIndicatorBehavior');
  }

  _setModalFocus() {
    const modalElement = this.$el;
    const modalHeader = modalElement.find('h1, h2')[0];

    if (modalHeader && $os.mobile) {
      $(modalHeader).attr('tabindex', '-1')
        .trigger('focus');
    } else {
      modalElement.attr('tabindex', '-1').trigger('focus');
    }

  }
}

module.exports = CreatePostAccessibleModalView;
