const Marionette = require('Marionette');
const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');

const {
  TOPIC_OBJECTIVE,
  TASK_OBJECTIVE,
  EVENT_OBJECTIVE,
  CERTIFICATION_OBJECTIVE,
  EVALUATION_OBJECTIVE
} = require('@common/data/models/objectives/GuidedLearningObjectiveType');

const ICON_CSS_CLASS_BY_TYPE = {
  [TOPIC_OBJECTIVE]: 'icon-topic',
  [TASK_OBJECTIVE]: 'icon-task',
  [EVENT_OBJECTIVE]: 'icon-events',
  [CERTIFICATION_OBJECTIVE]: 'icon-certifications',
  [EVALUATION_OBJECTIVE]: 'icon-evaluation'
};

class ObjectiveCardGraphicView extends Marionette.ItemView {
  initialize() {
    this.thumbnailImage = this.getOption('thumbnailImage');
  }

  getTemplate() {
    return `
      <div class="learning-objective-card__graphic-icon <%- iconCssClass %>" aria-hidden="true"></div>
      <div class="js-thumbnail-wrapper learning-objective-card__graphic-thumbnail <%- thumbnailCssClass %>" aria-hidden="true"></div>
    `;
  }

  attributes() {
    return {
      role: 'presentation'
    };
  }

  className() {
    return 'learning-objective-card__graphic';
  }

  ui() {
    return {
      thumbnailImageWrapper: '.js-thumbnail-wrapper'
    };
  }

  templateHelpers() {
    const type = this.getOption('guidedLearningObjectiveType');
    const thumbnailImage = this.getOption('thumbnailImage');

    const useThumbnailImage = (type !== TASK_OBJECTIVE) && thumbnailImage;

    return {
      iconCssClass: useThumbnailImage ? 'hidden' : ICON_CSS_CLASS_BY_TYPE[type],
      thumbnailCssClass: useThumbnailImage ? '' : 'hidden'
    };
  }

  onRender() {
    if (this.thumbnailImage) {
      this._renderThumbnailImage();
    }
  }

  _renderThumbnailImage() {
    const thumbnailPath = (ImageHelpers.getBiggestImageFileBySize(
      this.thumbnailImage.sizes,
      {
        maxHeight: 100,
        maxWidth: 100
      }
    ) || this.thumbnailImage.originalFile).path;

    this.ui.thumbnailImageWrapper.css('background-image', 'url(' + thumbnailPath + ')');
  }
}

module.exports = ObjectiveCardGraphicView;
