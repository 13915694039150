/**
 * This file contains helper functions for working with objects. As we convert files into Typescript, functions from ObjectHelpers.js will be moved here. The "_" prefix is to prevent name conflicts with the ObjectHelper.js file.
 */
import {
  camelCase,
  mapKeys,
  isPlainObject
} from '.';

type List = Parameters<typeof mapKeys>[0];
// Converts first level keys of an object to camel case.
export function mapKeysToCamelCase(obj: List): ReturnType<typeof mapKeys> {
  return mapKeys(obj, (_, val) => {
    return camelCase(val);
  });
}

// Converts all keys of an object to camel case.
export function mapAllKeysToCamelCase(obj: List): ReturnType<typeof mapKeys> {
  if (Array.isArray(obj)) {
    return obj.map(mapAllKeysToCamelCase);
  }

  if (isPlainObject(obj) && obj != null) {
    return Object.entries(obj).reduce(
      (result, [key, val]) => {
        const camelCaseKey = camelCase(key);

        return {
          ...result,
          [camelCaseKey]: mapAllKeysToCamelCase(val)
        };
      },
      {}
    );
  }

  return obj;
}
