const _ = require('underscore');
const { View, Model } = require('Backbone');

class ViewConfig extends Model {

  defaults() {
    return {
      id: _.uniqueId('viewconfig'),
      viewClass: View,
      viewOptions: {}
    };
  }

  validate(attrs = {}) {
    if (!(this.inheritsFrom(attrs.viewClass, View))) {
      return this.createValidationError('viewClass needs to be inherited from Backbone.View', attrs);
    }

    if (!_.isObject(attrs.viewOptions)) {
      return this.createValidationError('viewOptions needs to be a object', attrs);
    }
  }

  inheritsFrom(Class, BaseClass) {
    return (Class === BaseClass) || Class.prototype instanceof BaseClass;
  }

  createValidationError(errorMsg, attrs) {
    return { errorMsg, attrs };
  }
}

module.exports = ViewConfig;
