import {
  TableContainer as MuiTableContainer,
  styled,
  type TableContainerTypeMap as MuiTableContainerTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTableContainerCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableContainerTypeMap<P = object, D extends React.ElementType = 'div'> = MuiTableContainerTypeMap<P & AxTableContainerCustomProps, D>;

export type AxTableContainerProps<
  D extends ElementType = AxTableContainerTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableContainerTypeMap<P & AxTableContainerCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableContainer'
};

const StyledAxTableContainer = styled(MuiTableContainer, styledOptions)<AxTableContainerProps>(() => {
  return {};
});

/**
 * A wrapper that provides horizontally scrolling behavior for the `<AxTable />` component.
 *
 * ## Links
 * - • [MUI | Table Container API](https://mui.com/material-ui/api/table-container/)
 */
export const AxTableContainer: MuiOverridableComponent<AxTableContainerTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableContainerProps, ref: Ref<HTMLDivElement>) => {
  const tableContainerProps: AxTableContainerProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableContainer { ...tableContainerProps } ref={ ref }>
      { children }
    </StyledAxTableContainer>
  );
});

export default AxTableContainer;
