const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const ArticleDetailsCommentTitleView = require('@training/apps/articles/ArticleDetailsCommentTitleView');

class ArticleDetailsCommentTitleController extends LayoutController {

  initialize(options) {

    ({
      collection: this.collection
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: ArticleDetailsCommentTitleView,
      collection: this.collection
    };
  }

}

module.exports = ArticleDetailsCommentTitleController;
