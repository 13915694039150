const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TopicDetailsPageView = require('@training/apps/topics/TopicDetailsPageView');
const TitleHeaderDefinitionBuilder = require('@common/components/titleHeader/TitleHeaderDefinitionBuilder');
const TitleHeaderTypeEnum = require('@common/components/titleHeader/TitleHeaderTypeEnum');
const { getTopicPerformanceControllerDefinition } = require('@training/apps/topics/TopicPerformanceControllerDefinitionFactory');
const { getTopicDescriptionControllerDefinition } = require('@training/apps/topics/TopicDescriptionControllerDefinitionFactory');
const { getTopicLearningPathsControllerDefinition } = require('@training/apps/topics/TopicLearningPathsControllerDefinitionFactory');
const I18n = require('@common/libs/I18n');

const viewConfig = (topicData, teamScoreData, startTrainingFn) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: TopicDetailsPageView,
      model: topicData
    },
    regionControllers: {
      titleRegion: new TitleHeaderDefinitionBuilder({
        titleType: TitleHeaderTypeEnum.PRETEXT_TITLE,
        title: topicData.get('title'),
        titleTag: 'h1',
        pretext: topicData.get('subjectName'),
        isSemanticHeader: true
      }).build(),
      performanceRegion: getTopicPerformanceControllerDefinition(topicData, teamScoreData, startTrainingFn),
      descriptionRegion: getTopicDescriptionControllerDefinition(topicData, startTrainingFn),
      learningPathsRegion: getTopicLearningPathsControllerDefinition(topicData)
    },
    delegateEvents: {
      'view:inflate': () => {
        window.app.layout.setTitle(I18n.t('selfDirected.topicDetails.windowTitle'));
      },
      'view:render': (controller, view) => {
        window.apps.base.timeLogController.bindPageViewLog(view, 'TopicDetailsPage', topicData.get('id'));
      }
    }
  };
};

module.exports = viewConfig;

