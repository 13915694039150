/* eslint-disable no-invalid-this */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
const $ = require('jquery');

const ModernDeferred = jQuery.Deferred.bind({});

$.extend({
  ModernDeferred: ModernDeferred,
  Deferred: function( func ) {
    var tuples = [
        // action, add listener, listener list, final state
        ['resolve', 'done', jQuery.Callbacks('once memory'), 'resolved'],
        ['reject', 'fail', jQuery.Callbacks('once memory'), 'rejected'],
        ['notify', 'progress', jQuery.Callbacks('memory')]
      ],
      state = 'pending',
      promise = {
        state: function() {
          return state;
        },
        always: function() {
          deferred.done( arguments ).fail( arguments );
          return this;
        },
        catch: function( fn ) {
          return promise.then( null, fn );
        },
        then: function( /* fnDone, fnFail, fnProgress */ ) {
          let fns = arguments;
          return jQuery.Deferred(( newDefer ) => {
            jQuery.each( tuples, ( i, tuple ) => {
              const action = tuple[0],
                fn = typeof fns[i] === 'function' && fns[i];
              // deferred[ done | fail | progress ] for forwarding actions to newDefer
              deferred[tuple[1]](function() {
                const returned = fn && fn.apply( this, arguments );
                if ( returned && typeof returned.promise === 'function' ) {
                  returned.promise()
                    .done( newDefer.resolve )
                    .fail( newDefer.reject )
                    .progress( newDefer.notify );
                } else {
                  newDefer[action + 'With']( this === promise ? newDefer.promise() : this, fn ? [returned] : arguments );
                }
              });
            });
            fns = null;
          }).promise();
        },
        // Get a promise for this deferred
        // If obj is provided, the promise aspect is added to the object
        promise: function( obj ) {
          return obj != null ? jQuery.extend( obj, promise ) : promise;
        }
      },
      deferred = {};

    // Keep pipe for back-compat
    promise.pipe = promise.then;

    // Add list-specific methods
    jQuery.each( tuples, ( i, tuple ) => {
      const list = tuple[2],
        stateString = tuple[3];

      // promise[ done | fail | progress ] = list.add
      promise[tuple[1]] = list.add;

      // Handle state
      if ( stateString ) {
        list.add(() => {
          // state = [ resolved | rejected ]
          state = stateString;

        // [ reject_list | resolve_list ].disable; progress_list.lock
        }, tuples[i ^ 1][2].disable, tuples[2][2].lock );
      }

      // deferred[ resolve | reject | notify ]
      deferred[tuple[0]] = function() {
        deferred[tuple[0] + 'With']( this === deferred ? promise : this, arguments );
        return this;
      };
      deferred[tuple[0] + 'With'] = list.fireWith;
    });

    // Make the deferred a promise
    promise.promise( deferred );

    // Call given func if any
    if ( func ) {
      func.call( deferred, deferred );
    }

    // All done!
    return deferred;
  }
});

