const _ = require('underscore');
const Backbone = require('Backbone');
const { createUtcDate } = require('@common/libs/dateHelpers');

// Required to build profileImage sizes array and trick common ProfileIconHandler logic into working when
// only provided a profile image file path and not the entire object
const sizeObject = { status: 'done' };
const SIZE_ARRAY_LENGTH = 4;

class PagePreviewSearchResult extends Backbone.Model {
  get idAttribute() {
    return 'pageId';
  }

  get className() {
    return 'PagePreviewSearchResult';
  }

  toggleFavorite() {
    if (this.isStarToggleInProgress) {
      return;
    }

    this.isStarToggleInProgress = true;

    $.ajax({
      type: (this.get('existingFavoriteId') != null) ? 'DELETE' : 'PUT',
      url: `${ this.urlRoot() }/articles/${ this.getId() }/favorite`,
      success: (resp) => {
        let favoriteCount = this.get('favoriteCount');
        let existingFavoriteId = null;

        if (resp.entity != null) {
          existingFavoriteId = resp.entity.id;
          favoriteCount = favoriteCount + 1;
        } else {
          favoriteCount = favoriteCount - 1;
        }

        this.set('existingFavoriteId', existingFavoriteId);
        this.set('favoriteCount', favoriteCount);
      },

      error: () => {
        this.trigger('error:existingFavoriteId');
      },
      complete: () => {
        this.isStarToggleInProgress = false;
      }
    });
  }

  isVisible() {
    return true;
  }

  _buildSizesArray() {
    const array = [];
    for (let i = 0; i < SIZE_ARRAY_LENGTH; i++) {
      // Use large icon path for retina screens. See ProfileIconHandler
      const path = i === 3 ? this.get('largeIconPath') : this.get('smallIconPath');
      Object.assign(sizeObject, {
        file: { path }
      });
      array.push(sizeObject);
    }
    return array;
  }

  getLastEditor() {
    const lastEditor = {
      timestamp: this.get('lastModified'),
      user: {
        fullname: this.get('authorName'),
        id: this.get('authorUserId')
      }
    };

    if (this.get('smallIconPath') != null || this.get('largeIconPath') != null) {
      Object.assign(lastEditor.user, {
        profileImage: {
          sizes: this._buildSizesArray()
        }
      });
    }
    return lastEditor;
  }

  getAuthorId() {
    return this.get('authorUserId');
  }

  getTitle() {
    return this.get('title');
  }

  getFavoriteCount() {
    const favoriteCount = this.get('favoriteCount');
    if (favoriteCount === 0) {
      return '';
    }
    return favoriteCount;

  }

  getType() {
    return this.get('type');
  }

  getId() {
    return this.get('pageId');
  }

  getReferenceUrl() {
    return this.get('url');
  }

  getLanguage() {
    return this.get('language');
  }

  getTrainingModule() {
    return this.get('trainingModule');
  }

  getContent() {
    return this.get('previewContent');
  }

  // Needed for legacy API_VERSION. Can be removed when we've completely moved on to v2. Check usage in PageIndexController first
  // This is used in the special case that a question is launched in edit mode when no content (answer) exists yet
  getCurrentVersion() {
    return this.getContent();
  }

  getPublishTimestamp() {
    return this.get('publishTimestamp');
  }

  getPageReportType() {
    return this.get('reportType');
  }

  getStatus() {
    return this.get('status').toUpperCase();
  }

  getAgreeCount() {
    return this.get('agreeCount');
  }

  getMedia() {
    return this.get('media');
  }

  getFirstImage() {
    // find the first media--image element and get its media-id
    const $newContent = $('<tmp></tmp>').append(this.getContent());
    const imageMediaId = $newContent.find('.page__media.media--image').first()
      .data('mediaId');

    // if imageMediaId is not null, then there is an image, so return
    // the media element or null if it does not exist
    const imageMedia = _.find(this.getMedia(), (media) => {
      return media.id === imageMediaId;
    });

    return imageMedia;
  }

  getRecommendationId() {
    return this.get('recommendationId');
  }

  userHasAgreed() {
    return this.get('existingAgreeId') != null;
  }

  isFact() {
    return this.get('approvedAsFact');
  }

  hasPastPublishDate() {
    if (this.getPublishTimestamp() != null) {
      const currentDate = createUtcDate();
      const publishDate = createUtcDate(this.getPublishTimestamp());
      return publishDate.isBefore(currentDate);
    }
    return false;
  }

  hasFuturePublishDate() {
    if (this.getPublishTimestamp() != null) {
      const currentDate = createUtcDate();
      const publishDate = createUtcDate(this.getPublishTimestamp());
      return publishDate.isAfter(currentDate);
    }
    return false;
  }

  hasFirstImage() {
    return this.getFirstImage() != null;
  }

  canStar(user) {
    return !user.isGuestOrSuperuser();
  }
}

module.exports = PagePreviewSearchResult;
