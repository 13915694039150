const Promise = require('bluebird');
const Backbone = require('Backbone');
const Cocktail = require('backbone.cocktail');
const _ = require('underscore');
const logging = require('logging');

const I18n = require('@common/libs/I18n');
const { getMemoizedFunctionWithInvalidator } = require('@common/libs/helpers/app/MemoizeHelpers');
const { combineUrlPaths } = require('@common/libs/helpers/app/UrlHelpers');

const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');
const PersistentObject = require('@common/libs/UI/PersistentObject');
const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');

const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const AbstractAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/AbstractAssessmentInitiatorController');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');
const AssessmentType = require('@common/data/enums/AssessmentType');

const PathStatus = require('@common/data/models/PathStatus');
const AssessmentTopicOption = require('@common/data/models/assessments/AssessmentTopicOption');

const GuidedLearningCategoriesList = require('@common/components/guidedLearning/collections/GuidedLearningCategoriesList');

const GuidedLearningControllerState = require('./guidedLearning/GuidedLearningControllerState');
const GuidedLearningAssessmentInitiatorContext = require('./guidedLearning/GuidedLearningAssessmentInitiatorContext');
const GuidedLearningOption = require('@common/data/models/assessments/GuidedLearningOption');

const GuidedLearningLayoutView = require('@common/components/guidedLearning/GuidedLearningLayoutView');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const ProgramOverviewController = require('@common/components/guidedLearning/programs/ProgramOverviewController');
const AssessmentCompletionAction = require('@training/apps/training/views/assessments/AssessmentCompletionAction');
const ObjectiveResultPage = require('@training/apps/training/views/assessments/ObjectiveResultPage');
const ObjectiveResultModelFactory = require('@training/apps/training/ObjectiveResultModelFactory');

const TitleHeaderDefinitionBuilder = require('@common/components/titleHeader/TitleHeaderDefinitionBuilder');
const TitleHeaderTypeEnum = require('@common/components/titleHeader/TitleHeaderTypeEnum');

const {
  TopicDetailsModel,
  TeamScoreModel
} = require('@training/apps/topics/TopicDetailsApiModels');
const TopicDetailsPageControllerFactory = require('@training/apps/topics/TopicDetailsPageControllerFactory');
const TopicCTAButtonHelper = require('@common/components/search/TopicCTAButtonHelper');

const GuidedLearningNextStartableAssessmentProvider = require('@training/apps/training/controllers/guidedLearning/GuidedLearningNextStartableAssessmentProvider');

const PageHeaderDefinitionFactory = require('@training/widgets/pageHeader/PageHeaderDefinitionFactory');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');
const { GuidedLearningTraining } = require('@common/data/enums/SessionTrainingType');
const trainingIconFormatter = require('@common/data/enums/TrainingSessionTypeIconFormatter');

const MilestoneInterfaceFactory = require('@common/data/models/MilestoneInterfaceFactory');

const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');

const EvaluationDetailsController = require('@training/apps/training/views/evaluations/EvaluationDetailsController');

const EvaluationForm = require('@training/apps/training/models/EvaluationForm');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const { ReactControllerDefinitionFactory } = require('@common/modules/react');

class GuidedLearningController extends AbstractAssessmentInitiatorController {
  constructor(parentProcessor, taskPresenterService, eventPresenterService, assessmentFactory) {
    super(parentProcessor, { sessionModel: parentProcessor.session });

    this.storageNamespace = 'training/guided_learning';
    this.storageKey = 'controller';
    this.allowEmptyStorageEntries = true;
    this.urlSlug = 'guided';
    this.enableScrollToFirstButtonAvailable = true;

    this.processSequenceFlow = this.processSequenceFlow.bind(this);
    this.hasSomethingToDoAsync = this.hasSomethingToDoAsync.bind(this);
    this.startObjective = this.startObjective.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);
    this.onSaveInstanceState = this.onSaveInstanceState.bind(this);
    this.onRestoreInstanceState = this.onRestoreInstanceState.bind(this);
    this.finishGuidedLearning = this.finishGuidedLearning.bind(this);
    this.returnToGlDetails = this.returnToGlDetails.bind(this);
    this.showDetailsView = this.showDetailsView.bind(this);

    this.modelCollection = this.getModelCollection();

    this._objectiveStarting = false;
    this._currentContinuePredicate = _.noop;
    this._objectiveValidator = {};
    this._controllerState = new GuidedLearningControllerState({
      sessionId: this.sessionModel.id
    });

    this._taskPresenterService = taskPresenterService;
    this._eventPresenterService = eventPresenterService;
    this.assessmentFactory = assessmentFactory;

    const fetchCategories = () => {
      return Promise.resolve(this.modelCollection.fetch());
    };

    ({
      memoizedFn: this.getCategoriesAvailable,
      invalidateFn: this.invalidateCategoryCache
    } = getMemoizedFunctionWithInvalidator(fetchCategories));
  }

  getModelCollection() {
    return new GuidedLearningCategoriesList([], { sessionModel: this.sessionModel });
  }

  getDiscriminator() {
    return GuidedLearningAssessmentInitiatorContext.getType();
  }

  createInitiatorContext(json) {
    return new GuidedLearningAssessmentInitiatorContext(json.programId);
  }

  setConfigurationProvider(provider) {
    this.contextualConfigurationProvider = provider;
  }

  _getNextItemProviderForItem(programItem) {
    const programId = programItem.getProgramId();

    const initiatorContext = this.createInitiatorContext({
      programId
    });
    return this.getNextItemProvider(initiatorContext);
  }

  getNextItemProvider(assessmentInitiatorContext) {
    const newGuidedLearningCategory = this.modelCollection.add({
      id: assessmentInitiatorContext.getProgramId()
    });

    return new GuidedLearningNextStartableAssessmentProvider(newGuidedLearningCategory);
  }

  getSessionTrainingType() {
    return GuidedLearningTraining;
  }

  processSequenceFlow(options = {}) {
    logging.debug('Processing Guided Learning Flow Controller');

    ({
      continuePredicate: this._currentContinuePredicate = _.constant(true)
    } = options);

    return this.showPageFlow(options)
      .then((showPageSuccess) => {
        if (showPageSuccess) {
          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        }
        return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);

      });
  }

  showPageFlow(options = {}) {
    return this.hasSomethingToDoAsync()
      .then(() => {
        const {
          pageId,
          pageOptions
        } = this._getPageArgs(options);

        return this._showPageView(pageId, pageOptions)
          .then(_.constant(true), (error) => {
            logging.warn(error);
            logging.info(`The '${ pageId }' page for Guided Learning was unable to be shown. Falling back...`);
            throw error;
          });
      });
  }

  processTraining(guidedLearningOption) {
    return this._startTrainingOptionProcessFlow(guidedLearningOption);
  }

  loadConfigurationAsync() {
    return this.contextualConfigurationProvider.getConfigurationAsync()
      .then((configuration) => {
        this._routePrefix = configuration.routePrefix;
        this._objectiveValidator = configuration.objectiveValidator;
        this._currentContinuePredicate = () => {
          return configuration.canContinue;
        };
      });
  }

  // The server will always return what is requested, but there may be some cases where there is nothing available
  // to do, for example, there may be nothing startable
  hasSomethingToDoAsync() {
    // Loading this here since hasCategoriesAvailableAsync requires the _objectiveValidator reference that it sets.
    return this.loadConfigurationAsync()
      .then(this.hasCategoriesAvailableAsync.bind(this));
  }

  hasCategoriesAvailableAsync() {
    return this.getCategoriesAvailable().then(() => {
      return this._objectiveValidator.hasActionableItemsInSummary(this.modelCollection);
    });
  }

  clearState() {
    this._controllerState.clearState();
    this.saveInstanceState(LocalStorageHelpers.getStateStorageObject());
  }

  finishGuidedLearning() {
    logging.debug('Finished processing guided learning controller -- moving on');
    this.saveInstanceState(LocalStorageHelpers.getStateStorageObject());
    this.processParentFlow();
  }

  // Verifies the options have the required data to show a page and falls back to the
  // controllerState if it exists.
  _getPageArgs(options = {}) {
    if (options.pageId != null) {
      return options;
    }

    return this._getPageState();
  }

  _getPageState() {
    if (!this._controllerState.hasPageState()) {
      this.restoreInstanceState(LocalStorageHelpers.getStateStorageObject());
    }

    const state = this._controllerState.getPageState();

    return {
      pageId: state.pageId,
      pageOptions: _.omit(state, 'pageId')
    };
  }

  _showPageView(pageId, pageOptions = {}) {
    if (pageId != null) {
      return this._showGLPage(pageId, pageOptions);
    }
    this.finishGuidedLearning();
    return Promise.resolve();

  }

  _showGLPage(pageId, options = {}) {
    switch (GuidedLearningPageEnum.assertLegalValue(pageId)) {
      case GuidedLearningPageEnum.DetailsPage:
        return this.showDetailsView(options);
      case GuidedLearningPageEnum.TopicDetailsPage:
        return this.showTopicDetailsView(options);
      case GuidedLearningPageEnum.TaskDetailsPage:
        return this.showTaskDetailsView(options);
      case GuidedLearningPageEnum.EventSessionsPage:
        return this.showEventSessionsView(options);
      case GuidedLearningPageEnum.LearningEventDetailsPage:
        return this.showEventDetailsView(options);
      case GuidedLearningPageEnum.EvaluationDetailsPage:
        return this.showEvaluationDetailsView(options);
      default:
        return false;
    }
  }

  showDetailsView({
    programId,
    navigate = true
  } = {}) {
    const promise = this._getProgramDetails(programId);
    const canContinue = this._currentContinuePredicate();
    const modalPromise = this.getPathStatus();
    return Promise.all([promise, modalPromise])
      .then(([program, pathStatus]) => {
        this._controllerState.setDetailId(programId);

        if (navigate) {
          // This has to come first since menu changes are fired when `setView` is called...
          Backbone.history.navigate(this._getRoutePrefix(programId));
        }

        const enrollmentStatus = program.getEnrollmentStatus();
        const sortedObjectives = program.getSortedObjectives(new MilestoneInterfaceFactory({ enrollmentStatus }));
        const containsMilestones = sortedObjectives.milestones.length > 0;
        this.rootView = this.getRootLayout(containsMilestones);
        window.apps.base.timeLogController.bindPageViewLog(this.rootView, 'GuidedLearningProgramDetailsPage', programId);
        const titleController = this._createTitle(program);
        const detailView = this._getDetailViewForProgram(program, sortedObjectives);
        window.app.layout.setView({
          ViewControllerClass: LayoutController,
          viewDefinition: this.rootView,
          delegateEvents: {
            'view:show': () => {
              if (!canContinue) {
                ViewHelpers.showBackButtonWithReset({ view: this.rootView });
              }

              if (!pathStatus.get('hasSeenPathHelpText')) {
                pathStatus.markPathHelpTextAsSeen();
                this.displayGLExplanationModal();
              } else {
                //TODO: Reimplement Scroll to first availble
                //detailView.scrollToFirstButtonAvailable();
              }
            },
            'view:openGLModal': () => {
              this.displayGLExplanationModal();
            }
          },
          regionControllers: {
            titleRegion: titleController,
            contentRegion: detailView
          }
        });

        this.setTitle(program.get('name'));
      });
  }

  _createTitle(program) {
    return new TitleHeaderDefinitionBuilder({
      titleType: TitleHeaderTypeEnum.PRETEXT_TITLE,
      title: program.getName(apps.auth.session.user.get('language')),
      titleClass: 'page-header__title',
      badgeModifierClasses: ['page-header__icon'],
      iconClass: 'icon-guided_learning'
    }).build();
  }

  showTopicDetailsView({
    programId,
    topicId
  } = {}) {
    const topic = new TopicDetailsModel({
      id: topicId,
      pathId: programId
    }, { sessionModel: this.session });
    const teamScoreData = new TeamScoreModel({ id: topicId });

    return Promise.resolve(topic.fetch({
      skipGlobalHandler: true
    }).then(() => {
      this._controllerState.setDetailId(programId);
      teamScoreData.fetch({
        showSpinner: false,
        skipGlobalHandler: true
      });

      const actionCallback = (options = {}) => {
        const {
          topicId: actionTopicId,
          level,
          assessmentType,
          isRetake,
          isPopQuiz,
          launchContext,
          programId: actionProgramID
        } = options;

        const assessmentOptions = {
          topic: {
            id: actionTopicId
          },
          level,
          isPopQuiz,
          isRetake,
          launchContext,
          programId: actionProgramID
        };

        const assessmentTopicOption = new AssessmentTopicOption(assessmentOptions);
        assessmentTopicOption.setForAssessmentType(assessmentType);

        this.createAndProcessAssessment(assessmentTopicOption);
      };

      const topicDetailsStartTrainingCallback = (action) => {
        TopicCTAButtonHelper.handleActionForType(action, AssessmentLaunchContext.TOPIC_DETAILS, actionCallback);
      };

      window.app.layout.setView(TopicDetailsPageControllerFactory(topic, teamScoreData, topicDetailsStartTrainingCallback));
    },
    (model, xhr) => {
      // This is the fail condition - display an error message and navigate to the search page
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      logging.error(exception.getErrorMessage());

      // For now I'm going with a generic error
      window.app.layout.flash.error(I18n.t('selfDirected.topicDetails.errors.notAvailable', {topicId}));
    }));
  }

  showTaskDetailsView({
    programId,
    taskId,
    navigate = true
  } = {}) {
    const searchCriteria = {
      type: GuidedLearningObjectiveType.TASK_OBJECTIVE,
      locked: false,
      taskId
    };

    return this._getProgramTrainingOption(programId, searchCriteria)
      .then((guidedLearningOption) => {
        return this._processTaskCompletionFlow(guidedLearningOption, { navigate });
      });
  }

  showEventSessionsView({
    programId,
    eventId,
    navigate = true
  } = {}) {
    const searchCriteria = {
      type: GuidedLearningObjectiveType.EVENT_OBJECTIVE,
      locked: false,
      eventId
    };

    return this._getProgramTrainingOption(programId, searchCriteria)
      .then((guidedLearningOption) => {
        return this._processEventSessionSelection(guidedLearningOption, { navigate });
      });
  }

  showEventDetailsView({
    programId,
    eventId,
    scheduledEventId,
    enrollmentId,
    initialTab,
    navigate = true
  } = {}) {
    const searchCriteria = {
      type: GuidedLearningObjectiveType.EVENT_OBJECTIVE,
      locked: false,
      eventId
    };

    return this._getProgramTrainingOption(programId, searchCriteria)
      .then((guidedLearningOption) => {
        return this._processEventSessionEnrollment(guidedLearningOption, {
          scheduledEventId,
          enrollmentId,
          initialTab,
          navigate
        });
      });
  }

  showEvaluationDetailsView({
    programId,
    evaluationId,
    navigate = true
  } = {}) {
    const searchCriteria = {
      type: GuidedLearningObjectiveType.EVALUATION_OBJECTIVE,
      locked: false,
      evaluationId
    };

    return this._getProgramTrainingOption(programId, searchCriteria)
      .then((guidedLearningOption) => {
        return this._processEvaluationStartFlow(guidedLearningOption, { navigate });
      });
  }

  startObjective(programId, objective, startContext) {
    if (this._objectiveStarting) {
      return false;
    }
    this._objectiveStarting = true;

    this.invalidateCategoryCache();

    const guidedLearningOption = this.getProgramOptions(objective, programId);

    return this._startTrainingOptionProcessFlow(guidedLearningOption, startContext)
      .finally(() => {
        this._objectiveStarting = false;
      });
  }

  viewDrillDownPage(programId, topicId) {
    if (!programId || !topicId) {
      return;
    }

    Backbone.history.navigate(`#${ this._getRoutePrefix(programId) }/topicDetails/${ topicId }`, {
      trigger: true
    });
  }

  getProgramOptions(objective, programId) {
    return new GuidedLearningOption(objective, programId);
  }

  _startTrainingOptionProcessFlow(guidedLearningOption, startContext) {
    // NOTE: If this code gets any longer, try and find a way to decouple it from a switch statement
    // which can be pretty ugly, pretty fast
    const type = GuidedLearningObjectiveType.assertLegalValue(guidedLearningOption.getObjective().getType());
    switch (type) {
      case GuidedLearningObjectiveType.TOPIC_OBJECTIVE:
      case GuidedLearningObjectiveType.CERTIFICATION_OBJECTIVE:
        return this._processTopicTrainingFlow(guidedLearningOption, startContext);
      case GuidedLearningObjectiveType.TASK_OBJECTIVE:
        return this._processTaskCompletionFlow(guidedLearningOption, startContext);
      case GuidedLearningObjectiveType.EVENT_OBJECTIVE:
        return this._processEventEnrollmentFlow(guidedLearningOption, startContext);
      case GuidedLearningObjectiveType.EVALUATION_OBJECTIVE:
        return this._processEvaluationStartFlow(guidedLearningOption, startContext);
      default:
        return false;
    }
  }

  onSaveInstanceState(bundleJSON) {
    _.extendOwn(bundleJSON, this._controllerState.getState());
  }

  onRestoreInstanceState(bundleJSON) {
    // When we need to, we can restore the bundled JSON
    this._controllerState.setState(bundleJSON);
  }

  getOption(key) {
    return this[key];
  }

  getRootLayout(containsMilestones = false) {
    return new GuidedLearningLayoutView({
      continuePredicate: this._currentContinuePredicate,
      continueCallback: () => {
        return this.finishGuidedLearning();
      },
      containsMilestones
    });
  }

  setTitle(programName = '') {
    const tabTitle = `${ I18n.t('assessments.select.header.title.GuidedLearningTraining') } - ${ programName }`;
    window.app.layout.setTitle(tabTitle);
  }

  getDetailView() {
    return ProgramOverviewController;
  }

  _getProgramDetails(programId) {
    return this.getCategoriesAvailable().then(() => {
      const program = this.modelCollection.get(programId);

      if (program == null) {
        throw new Error('Program no longer exists.');
      }

      return program.fetch().then(() => {
        return program;
      });
    });
  }

  _getProgramTrainingOption(programId, objectiveSearchCriteria = {}) {
    return this._getProgramDetails(programId)
      .then((program) => {
        const objective = program.findMatchingObjective(objectiveSearchCriteria);

        if (objective == null) {
          throw new Error(`Objective doesn't exist in program ${ programId }`);
        }

        return this.getProgramOptions(objective, programId);
      });
  }

  _getDetailViewForProgram(program, sortedObjectives) {
    const DetailView = this.getDetailView();
    return {
      ViewControllerClass: DetailView,
      model: program,
      sortedObjectives,
      objectiveStarter: (objective) => {
        this.startObjective(program.id, objective);
      },
      objectiveValidator: (objective) => {
        return this._objectiveValidator.canActionOnObjective(objective);
      },
      viewTopicDrillDown: (topicId) => {
        this.viewDrillDownPage(program.id, topicId);
      },
      reloadCallback: this.showDetailsView
    };
  }

  _onErrorStartingObjective(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errorCode = exception.getErrorCode();

    if ([
      AxonifyExceptionCode.CLIENT_ERROR_EMPTY_ASSESSMENT,
      AxonifyExceptionCode.CLIENT_ERROR_ASSESSMENT_NOT_APPLICABLE,
      AxonifyExceptionCode.CLIENT_ERROR_ON_THE_CLOCK_REQUIRED
    ].includes(errorCode)) {
      logging.debug(`There was an exception trying to start the objective. ErrorCode: ${ errorCode }`);

      return this.hasSomethingToDoAsync()
        .done((somethingToDo) => {
          if (!somethingToDo) {
            this.processParentFlow();
          }
        });
    }

    return false;
  }

  processAssessment(assessment, context) {
    const programId = context.getProgramId();
    if (programId) {
      this._controllerState.setDetailId(programId);
    }

    const nextItemProvider = this.getNextItemProvider(context);
    const milestoneChecker = this._checkForMilestoneBreakpointBeforeNext.bind(this);
    const trainingTypeGetter = this.getSessionTrainingType;
    const doOnDone = () => {
      if (this.sessionModel?.hasCurrentAssessment()) {
        this.sessionModel.pauseAssessment()
          .then(() => {
            this.finishGuidedLearning();
          });
      } else {
        this.finishGuidedLearning();
      }
    }

    const assessmentResultHandler = (resultModel, resultItem, nextItem, processingContext, defaultHandler) => {

      // Check for a milestone
      const upcomingItem = milestoneChecker(resultItem, nextItem, nextItemProvider);
      const canGoBackAfterCompletion = nextItemProvider.canProduceNext();
      const sessionTrainingType = canGoBackAfterCompletion
        ? trainingTypeGetter() // only generates a value if the user can go back
        : undefined;

      const objectiveResultPage = new ObjectiveResultPage({
        model: resultModel,
        resultItem,
        upcomingItem,
        canGoBackAfterCompletion,
        sessionTrainingType,
        complete: (requestedAction) => {
          const isAssessmentRetake = requestedAction === AssessmentCompletionAction.Retake && AssessmentType.supportsQuestions(resultItem.getForAssessmentType());
          const isAssessmentStartNext = nextItem != null && requestedAction === AssessmentCompletionAction.StartNext && AssessmentType.supportsQuestions(nextItem.getForAssessmentType());
          processingContext.doneAssessmentProcessing = !isAssessmentStartNext && !isAssessmentRetake;

          defaultHandler(requestedAction, resultItem, nextItem);
        },
        doOnDone
      });

      return objectiveResultPage;
    }

    const trainingIcon = this.getTrainingIconData();
    const assessmentController = this.getAssessmentProcessor({
      assessment,
      nextItemProvider,
      pageHeaderDefinitionFactory: (options = {}) => {
        const headerOptions = _.defaults(options, {
          iconClass: trainingIcon.iconClass,
          iconLabel: trainingIcon.iconLabel,
          text: this.getTitleForAssessment()
        });
        return PageHeaderDefinitionFactory(headerOptions);
      },
      assessmentResultHandler
    });

    return assessmentController.processSequenceFlow();
  }

  getTrainingIconData() {
    return trainingIconFormatter(GuidedLearningTraining);
  }

  getTitleForAssessment() {
    return I18n.t('GuidedLearning.title');
  }

  createAssessment(assessmentTopicOption) {
    const programId = this._controllerState.getPageState().programId || assessmentTopicOption.getProgramId();
    const context = this.createInitiatorContext({
      programId
    });

    return this.assessmentFactory.createForAssessmentTypeAsync(
      assessmentTopicOption.getAssessmentType(),
      context,
      assessmentTopicOption.toAssessmentRequestJson()
    ).catch(() => {
      this.invalidateCategoryCache();
    });
  }

  _processTopicTrainingFlow(assessmentTopicOption) {
    if (assessmentTopicOption.getObjective().isStartable()) {
      return this.createAndProcessAssessment(assessmentTopicOption)
        .catch(this._onErrorStartingObjective);
    }
    return Promise.reject(new Error('Objective is not startable'));
  }

  _processTaskCompletionFlow(taskOption, { navigate } = {}) {
    const programId = taskOption.getProgramId();

    const doneCallback = this.returnToGlDetails.bind(this, programId);

    const taskCompletionRecordToggledCallback = (taskCompletionRecord) => {
      this.sessionModel.fetch().done(() => {
        if (taskCompletionRecord.doesRequireCompletion()) {
          doneCallback();
        } else {
          taskOption.set({ taskCompletionRecord: taskCompletionRecord.toJSON() });
          const objectiveResult = ObjectiveResultModelFactory.fromTaskCompletionRecord(taskCompletionRecord);

          this.showObjectiveResultPage(objectiveResult, {
            resultItem: taskOption,
            onYield: doneCallback
          });
        }
      });
    };

    return this._showTaskDetailsView(taskOption, {
      taskCompletionRecordToggledCallback,
      navigate
    });
  }

  _showTaskDetailsView(taskOption, {
    taskCompletionRecordToggledCallback,
    navigate
  } = {}) {
    const programId = taskOption.getProgramId();
    const taskId = taskOption.getTaskId();
    const routePrefix = this._getRoutePrefix(programId);

    this._controllerState.setTaskDetailsState(programId, taskId);

    return Promise.resolve(this._taskPresenterService({
      routePrefix,
      taskId,
      programId,
      taskCompletionRecordToggledCallback,
      navigate
    }));
  }

  _processEventEnrollmentFlow(eventOption, { navigate } = {}) {
    if (eventOption.getObjective().hasScheduledEventsAvailable()) {
      this._processEventSessionSelection(eventOption, { navigate });
    } else {
      const scheduledEventId = eventOption.getObjective().getScheduledEventId();
      const enrollmentId = eventOption.getObjective().getEnrollmentId();

      this._processEventSessionEnrollment(eventOption, {
        scheduledEventId,
        enrollmentId,
        navigate
      });
    }

    return Promise.resolve();
  }

  _processEventSessionSelection(eventOption, { navigate } = {}) {
    const programId = eventOption.getProgramId();

    const doneCallback = this.returnToGlDetails.bind(this, programId);

    const eventDetailsCallback = (options = {}) => {
      const context = Object.assign({ doneCallback }, _.pick(options, 'scheduledEventId', 'enrollmentId'));
      this._processEventSessionEnrollment(eventOption, context);
    };

    this._showFilteredEventSessionsView(eventOption, {
      eventDetailsCallback,
      doneCallback,
      navigate
    });
  }

  _processEventSessionEnrollment(eventOption, {
    scheduledEventId,
    enrollmentId,
    navigate,
    initialTab
  } = {}) {
    const programId = eventOption.getProgramId();

    const doneCallback = this.returnToGlDetails.bind(this, programId);

    const eventEnrollmentRecordCallback = (enrollmentRecord) => {
      this.sessionModel.fetch().done(() => {
        if (!enrollmentRecord.hasEnrollmentState()) {
          doneCallback();
        } else {
          eventOption.set('eventEnrollmentRecord', enrollmentRecord.toJSON());
          const objectiveResult = ObjectiveResultModelFactory.fromEventEnrollmentRecord(enrollmentRecord);

          this.showObjectiveResultPage(objectiveResult, {
            resultItem: eventOption,
            onYield: doneCallback
          });
        }
      });
    };

    this._showEventObjectiveDetails(eventOption, {
      scheduledEventId,
      enrollmentId,
      eventEnrollmentRecordCallback,
      navigate,
      initialTab
    });
  }

  _showFilteredEventSessionsView(eventOption, {
    doneCallback,
    eventDetailsCallback,
    navigate
  } = {}) {
    const programId = eventOption.getProgramId();
    const eventId = eventOption.getEventId();
    const routePrefix = this._getRoutePrefix(programId);

    this._controllerState.setEventSessionsState(programId, eventId);

    this._eventPresenterService().displayFilteredEventSummary({
      eventId,
      routePrefix,
      urlSlug: this.urlSlug,
      navigate,
      guidedLearningFlowCallback: doneCallback,
      eventDetailsCallback
    });
  }

  _showEventObjectiveDetails(eventOption, {
    scheduledEventId,
    enrollmentId,
    initialTab,
    navigate,
    eventEnrollmentRecordCallback
  } = {}) {
    const programId = eventOption.getProgramId();
    const eventId = eventOption.getEventId();
    const routePrefix = this._getRoutePrefix(programId);

    this._controllerState.setEventDetailsState(programId, eventId, scheduledEventId, enrollmentId, initialTab);

    this._eventPresenterService().displayFilteredEventDetails({
      eventId,
      scheduledEventId,
      enrollmentId,
      routePrefix,
      initialTab,
      eventEnrollmentRecordCallback,
      navigate
    });
  }

  _processEvaluationStartFlow(evaluationOption, { navigate = true } = {}) {
    const programId = evaluationOption.getProgramId();
    const evaluationId = evaluationOption.getEvaluationId();
    const userId = window.apps.auth.session.user.id;

    const doneCallback = this.returnToGlDetails.bind(this, programId);

    const startEvaluationCallback = (evaluationForm, apiEndpoint) => {
      return () => {
        const ajaxData = {
          typeId: evaluationId,
          programId,
          userId,
          mediaId: evaluationForm.getMediaId()
        };

        const startEvaluationAsync = $.ajax({
          type: 'POST',
          apiEndpoint,
          skipGlobalHandler: true,
          data: JSON.stringify(ajaxData),
          success: () => {
            logging.info(`Successfully start evaluation ${ evaluationId } in program ${ programId } for user ${ userId }`);
          },
          error: (response) => {
            const exception = AxonifyExceptionFactory.fromResponse(response);
            if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_EVALUATION_NO_ACTIVE_CONTENT) {
              window.app.layout.flash.error(I18n.t('GuidedLearning.evaluation.error.3134'));
            }

            logging.error(`Failed to start evaluation ${ evaluationId } in program ${ programId } for user ${ userId }`);
          }
        });

        return startEvaluationAsync.done((evaluationRecord) => {
          this.sessionModel.fetch().done(() => {
            const evaluationType = evaluationForm.getEvalType();
            evaluationOption.set('evaluationProgressRecord', evaluationRecord.progress);
            evaluationOption.set('evaluationType', evaluationType);
            const objectiveResult = ObjectiveResultModelFactory.fromEvaluationForm(evaluationForm);

            this.showObjectiveResultPage(objectiveResult, {
              resultItem: evaluationOption,
              onYield: doneCallback
            });
            const message = (evaluationForm.isVirtualEvaluation()) ? I18n.t(`assessments.item.evaluation.successVirtual`) : I18n.t(`assessments.item.evaluation.success`);
            window.app.layout.flash.success(message);
          });
        });
      };
    };

    return this._showEvaluationDetailsView(evaluationOption, {
      doneCallback,
      startEvaluationCallback,
      navigate
    });
  }

  _showEvaluationDetailsView(evaluationOption, {
    doneCallback,
    startEvaluationCallback,
    navigate
  } = {}) {
    const programId = evaluationOption.getProgramId();
    const evaluationId = evaluationOption.getEvaluationId();
    const userId = window.apps.auth.session.user.id;

    const routePrefix = this._getRoutePrefix(programId);
    const evaluationDetailsRoute = combineUrlPaths(routePrefix, 'evaluationDetails', evaluationId);

    const evaluationForm = new EvaluationForm({
      userId,
      evaluationId
    });

    this._controllerState.setEvaluationDetailsState(programId, evaluationId);

    return Promise.resolve(evaluationForm.fetch({
      skipGlobalHandler: true,
      error: (model, response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        logging.error(exception.getErrorMessage());

        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
          window.app.layout.flash.error(I18n.t('GuidedLearning.viewDeletedItem'));
        }
      }
    })).then(() => {
      window.app.layout.setView({
        ViewControllerClass: EvaluationDetailsController,
        guidedLearningFlowCallback: doneCallback,
        startEvaluationCallback,
        evaluationDetailsRoute,
        evaluationOption,
        evaluationForm,
        isViewingLastAttempt: false
      });

      if (navigate) {
        Backbone.history.navigate(evaluationDetailsRoute);
      }
    });
  }

  _getIndexInCollection(collection, item) {
    if (item == null) {
      return undefined;
    }
    let matchId;

    if (item.get('type') === 'TopicOption') {
      matchId = item.getTopicId();
      return collection?.findIndex((currentItem) => {
        return currentItem.entityId === matchId;
      });
    }

    matchId = item.get('id');
    return collection?.findIndex((currentItem) => {
      return currentItem.id === matchId;
    });
  }

  _checkForMilestoneBreakpointBeforeNext(resultItem, nextItem, nextItemProvider) {
    // If there is no next, don't bother checking
    if (nextItem == null) {
      return undefined;
    }

    const objectives = _.flatten(nextItemProvider?.guidedLearningCategory?.get('currentItems') || []);
    const resultItemIndex = this._getIndexInCollection(objectives, resultItem);
    const nextItemIndex = this._getIndexInCollection(objectives, nextItem);

    // NOTE: If the nextItem IS the next objective in the list, or if your "next" item is actually earlier than the current one
    //  - it will not be a milestone, so no need to check
    if (nextItemIndex > resultItemIndex + 1) {
      const objectivesToCheck = objectives.slice(resultItemIndex + 1, nextItemIndex);
      const milestoneIndex = objectivesToCheck.findIndex(nextItemProvider.guidedLearningCategory.isMilestone);

      if (milestoneIndex >= 0) {
        return new Backbone.Model(objectives[resultItemIndex + 1 + milestoneIndex]);
      }
    }

    // If no milestone item has been found, just return the nextItem
    return nextItem;
  }

  showObjectiveResultPage(objectiveResult, {
    resultItem,
    onYield = () => {}
  } = {}) {
    const nextItemProvider = this._getNextItemProviderForItem(resultItem);

    nextItemProvider
      .getNext()
      .catch((error) => {
        logging.error(error);
        logging.error('There was a problem getting the next item from the provider. Showing the user results without a next item');
        return null;
      })
      .then((nextItem = null) => {
        // Check if we've reached a milestone
        const upcomingItem = this._checkForMilestoneBreakpointBeforeNext(resultItem, nextItem, nextItemProvider);

        const viewDefinition = {
          ViewClass: ObjectiveResultPage,
          model: objectiveResult,
          resultItem,
          upcomingItem,
          sessionTrainingType: this.getSessionTrainingType(),
          canGoBackAfterCompletion: nextItemProvider.canProduceNext(),
          complete: (requestedAction) => {
            if (requestedAction === AssessmentCompletionAction.YieldControl) {
              onYield(requestedAction, nextItem);
            } else if (requestedAction === AssessmentCompletionAction.StartNext) {
              this.processTraining(nextItem);
            }
          },
          doOnDone: this.finishGuidedLearning
        };

        window.app.layout.setView({ viewDefinition });
      });
  }

  _getRoutePrefix(programId) {
    return combineUrlPaths(this._routePrefix, this.urlSlug, programId);
  }

  returnToGlDetails(programId) {
    return this.showPageFlow({
      pageId: GuidedLearningPageEnum.DetailsPage,
      pageOptions: {
        programId
      }
    });
  }

  displayGLExplanationModal() {
    const dialog = ViewControllerFactory.createLegacyView(ReactControllerDefinitionFactory({
      component: import('@training/apps/training/howTo/GuidedLearningHowToModal')
    }));
    this.rootView.howToModalRegion.show(dialog);
  }

  getPathStatus() {
    const pathStatus = new PathStatus();
    return (pathStatus.fetch().then(() => {
      return pathStatus;
    }));
  }
}

Cocktail.mixin(GuidedLearningController, PersistentObject);

module.exports = GuidedLearningController;
