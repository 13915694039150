const $os = require('detectOS');
const logging = require('logging');
const _ = require('underscore');
const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const dateHelpers = require('@common/libs/dateHelpers');
const MediaPreview = require('@common/components/mediaPreview/MediaPreview');
const MediaFactory = require('@common/libs/file/MediaFactory');
const FileFactory = require('@common/libs/file/FileFactory');

const ProfileUserSettingImageUploaderView = require('./ProfileUserSettingImageUploaderView');

class SelectOption extends Backbone.Model {
  toOption() {
    return {
      id: this.get('id'),
      value: this.get('value')
    };
  }
}

class SelectOptionCollection extends Backbone.Collection {
  initialize() {
    this.model = SelectOption;
  }
}

class ProfileUserSettingView extends UIKit.View {
  regions() {
    return {
      previewThumbnail: '.media-thumbnail-desktop'
    };
  }

  events() {
    return {
      'click @ui.removePhoto': 'onImageRemove',
      'submit @ui.userForm': 'onSubmit'
    };
  }

  ui() {
    return {
      thumbnailEditor: '.media-image-thumbnail',
      removePhoto: '.remove-wrap',
      adjustPhoto: '.adjust-photo',
      choosePhoto: '.choose-photo',
      userForm: '#user-form',
      addPhoto: '.image-thumbnail-overlay',
      cameraButton: '.camera',
      removeButton: '.remove',
      nameInput: '#name'
    };
  }

  constructor(options = {}) {
    super(options);

    this.setUpForm = this.setUpForm.bind(this);
    this.toggleThumbnail = this.toggleThumbnail.bind(this);
    this.createImageUploader = this.createImageUploader.bind(this);
    this.createModalImageUploader = this.createModalImageUploader.bind(this);
    this.setActionButton = this.setActionButton.bind(this);
    this.updatePreview = this.updatePreview.bind(this);
    this.onSave = this.onSave.bind(this);
    this.saveFailed = this.saveFailed.bind(this);
    this.saveComplete = this.saveComplete.bind(this);

    this.template = _.tpl(require('../templates/ProfileUserSettingView.html'));

    const tenantStore = TenantPropertyProvider.get();

    this.permissions = {
      showAttachments: this.nbChannel.reqres.request('canShowAttachments'),
      canHaveImage: tenantStore.getProperty('allowUserProfileImageChange'),
      canHaveNickName: tenantStore.getProperty('allowUserProfileNicknameChange'),
      canHaveGender: tenantStore.getProperty('allowUserProfileGenderChange'),
      canHaveBirthYear: tenantStore.getProperty('allowUserProfileBirthYearChange'),
      isSupported: this.browserSupportCheck()
    };
  }

  initialize() {
    logging.info('ProfileUserSettingView - initialize');

    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');

    this.model = window.apps.auth.session.user;
    this.modelDemo = this.model.clone();
    this.listenTo(this.modelDemo, 'change', this.onModelChange);

    this.imageMedia = MediaFactory.createMediaFromValue(FileFactory, this.modelDemo.get('profileImage'));

    this.hasSaved = false;
    this.actionBar = this.options.actionBar;

    this.formContext = {};

    this.requestedDimensions = [{
      width: 200,
      height: 200
    }, {
      width: 60,
      height: 60
    }, {
      width: 45,
      height: 45
    }, {
      width: 35,
      height: 35
    }];
  }

  render() {
    logging.info('ProfileUserSettingView - render');

    this.$el.html(this.template(this.templateHelpers()));
    window.app.layout.setTitle(I18n.t('settings.userTitle'));

    return this;
  }

  templateHelpers() {
    return this.permissions;
  }

  browserSupportCheck() {
    return (($os.browser !== 'ie') || ($os.version > 8))
    && (($os.browser !== 'firefox') || ($os.version > 37))
    && (!$os.android || ($os.version >= 5));
  }

  viewDidAppear() {
    logging.info('ProfileUserSettingView - viewDidAppear');
    const browserSupported = this.browserSupportCheck();

    if (!browserSupported) {
      this.ui.thumbnailEditor.hide();
      this.ui.nameInput.trigger('focus');
    }

    this.setUpForm();
    this.createImageUploader();

    if (browserSupported && !$os.mobile && !$os.ipad && this.permissions.canHaveImage) {
      this.toggleThumbnail(this.imageMedia != null);
      this.getRegion('previewThumbnail').show(this._getPreviewView());
      this.ui.thumbnailEditor.trigger('focus');
    }

    this.setActionButton();
  }

  setUpForm() {
    logging.info('ProfileUserSettingView - setUpForm');

    _(this.formContext).extend({
      genderSelectList: this._getGenderSelectList(),
      birthYearSelectList: this._getBirthYearSelectList()
    });

    this.userForm = new UIKit.Form({
      el: this.ui.userForm,
      model: this.modelDemo,
      context: this.formContext
    });
  }

  _getBirthYearSelectList() {
    const { currentYear } = dateHelpers.getCurrentDayMonthYear();
    const yearSpan = 88;
    const endYear = currentYear - 12;

    const birthYearSelectOptionsArray = Array.from( Array(yearSpan).keys() )
      .map((year) => {
        return {
          id: endYear - year,
          value: endYear - year
        };
      });
    return new SelectOptionCollection(birthYearSelectOptionsArray);
  }

  _getGenderSelectList() {
    const genderSelectOptionsArray = [{
      id: 'male',
      value: I18n.t('user.gender.optionMale')
    }, {
      id: 'female',
      value: I18n.t('user.gender.optionFemale')
    }, {
      id: 'unspecified',
      value: I18n.t('user.gender.optionUnspecified')
    }];

    return new SelectOptionCollection(genderSelectOptionsArray);
  }

  _getPreviewView() {
    this.mediaPreviewView = new MediaPreview({
      model: this.imageMedia,
      maxWidth: 200,
      minHeight: 200
    });
    return this.mediaPreviewView;
  }

  toggleThumbnail(show) {
    if (show) {
      this.ui.userForm.removeClass('no-image');
    } else {
      this.ui.userForm.addClass('no-image');
    }

    this.ui.removePhoto.toggle(show);
    this.ui.choosePhoto.toggle(!show);
    this.ui.adjustPhoto.toggle(show);
  }

  createImageUploader() {
    this.ui.addPhoto.on('click', this.createModalImageUploader);
  }

  createModalImageUploader() {
    let model;
    if (this.imageMedia) {
      model = this.imageMedia.clone();
    }

    this.profileUserSettingImageUploaderView = new ProfileUserSettingImageUploaderView({
      model,
      requestedDimensions: this.requestedDimensions
    });

    this.profileUserSettingImageUploaderView.on('add:image', (userClone) => {
      return this.updatePreview(userClone);
    });

    window.app.layout.presentModal(this.profileUserSettingImageUploaderView);
  }

  setActionButton() {
    const btns = [{
      type: 'profile-save',
      onClick: this.onSave,
      disabled: true
    }];

    this.actionBar.setActionButton(btns);
  }

  updatePreview(media) {
    this.profileUserSettingImageUploaderView.closeModal();
    this.imageMedia = media;
    this.modelDemo.set('profileImage', this.imageMedia);

    if (this.imageMedia) {
      this.getRegion('previewThumbnail').show(this._getPreviewView());
      this.toggleThumbnail(true);
    } else {
      this.getRegion('previewThumbnail').empty();
      this.toggleThumbnail(false);
    }

    this.userForm.update(this.modelDemo);
    if (this.profileUserSettingImageUploaderView != null) {
      this.profileUserSettingImageUploaderView.off('add:image');
    }

    if (this.actionBar) {
      this.actionBar.enableButton('profile-save');
    }
  }

  onSubmit(e) {
    e.preventDefault();
  }

  onImageRemove() {
    this.imageMedia = null;
    this.toggleThumbnail(false);
    this.getRegion('previewThumbnail').empty();
    this.modelDemo.set('profileImage', null);
    this.modelDemo.unset('media');
  }

  onModelChange() {
    // We handle profileImage changing on mobile separately to ensure a user save doesn't occur before a media save completes
    if (this.modelDemo.hasChanged()
        && !($os.mobile && this.modelDemo.changed.profileImage)
        && this._modelDifferent()) {
      if (this.actionBar) {
        this.actionBar.enableButton('profile-save');
      }
    } else if (this.actionBar) {
      this.actionBar.disableButton('profile-save');
    }
  }

  onComplete() {
    window.app.layout.hideSpinner();
  }

  onSave() {
    if (this.hasSaved) {
      return;
    }
    this.hasSaved = true;

    window.app.layout.showSpinner();
    const errors = this.userForm.commit();

    if (errors) {
      this.saveFailed(errors);
    } else {
      this.model.save(this.modelDemo.attributes, {
        success: () => {
          this.onComplete();
          let profileImage = null;

          if (this.imageMedia) {
            profileImage = this.imageMedia.toJSON();
          } else {
            this.imageMedia = null;
          }

          this.model.set('profileImage', profileImage);
          this.saveComplete();
        },
        error: () => {
          this.onComplete();
          this.saveFailed(I18n.t('settings.userSaveError'));
        }
      });
    }
  }

  saveFailed(errors = '') {
    this.hasSaved = false;
    this.actionBar.disableButton('profile-save');
    window.app.layout.flash.error(errors);
  }

  saveComplete() {
    this.hasSaved = false;
    this.actionBar.disableButton('profile-save');
    window.app.layout.flash.success(I18n.t('settings.userSaveSuccess'));
  }

  onClose(...args) {
    if (_.isFunction(this.options.complete)) {
      this.options.complete();
    }

    this.actionBar?.setActionButton(null);

    super.onClose(...args);
  }

  _modelDifferent() {
    return !_.isEqual(this.model.attributes, this.modelDemo.attributes);
  }
}

module.exports = ProfileUserSettingView;
