const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

const dateHelpers = require('@common/libs/dateHelpers');

class TaskDetailMainView extends Marionette.ItemView {
  getTemplate() {
    return require('@training/apps/legacyTasks/views/taskDetail/TaskDetailMainView.html');
  }

  className() {
    return 'task-detail-main';
  }

  templateHelpers() {
    return {
      completedMsg: this._getCompletedMsg()
    };
  }

  _getCompletedMsg() {
    const completionDate = this.model.get('completionDate');

    if (this.model.get('doesRequireCompletion') || !completionDate) {
      return null;
    }

    const completionDateString = dateHelpers.convertDateFormatLongDateWithStrings(completionDate);
    return I18n.t('tasks.details.completedMsg', { date: completionDateString });
  }
}

module.exports = TaskDetailMainView;
