const { LayoutView } = require('Marionette');

const PDFNewWindowView = require('@training/apps/training/views/activities/pages/pdf/PDFNewWindowView');
const PDFBaseViewController = require('@training/apps/training/views/activities/pages/pdf/PDFBaseViewController');

class PDFNewWindowController extends PDFBaseViewController {

  initialize(options = {}) {
    super.initialize(options);

    this.checkBoxState.set('isShown', false);
    this.listenTo(this.checkBoxState, 'change:isShown', this._setContinueButton);
    this.listenTo(this.checkBoxState, 'change:isChecked', this._setContinueButton);

    if (this.canSkip) {
      this._setSkipButton();
    }
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: '<div class="pdf-topic-card"></div>',
      regions: {
        pdfTopicCard: '.pdf-topic-card'
      }
    };
  }

  regionControllers() {
    return {
      pdfTopicCard: {
        viewDefinition: {
          ViewClass: PDFNewWindowView,
          checkBoxState: this.checkBoxState,
          activity: this.activity,
          canSkip: this.canSkip
        }
      }
    };
  }
}

module.exports = PDFNewWindowController;
