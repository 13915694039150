const { ItemView } = require('Marionette');

require('@common/modules/main/menu/item/selectable/SelectableBehavior');
require('@common/libs/behaviors/pressedHoverButton/PressedHoverButtonBehavior');

class OverflowMenuItem extends ItemView {
  tagName() {
    return 'li';
  }

  getTemplate() {
    if (this.href != null) {
      return `
        <a href="<%- href %>" class="bottom-overflow-menu-item <%- selectableClass %> <%- linkClass %>">\
          <span class="item-icon <%- iconClass %>"></span>\
          <span class="item-text"><%- text %></span>\
          <span class="item-icon icon-angle_right bottom-overflow-app-indicator"></span>\
        </a>
      `;
    }
    return `
        <button class="bottom-overflow-menu-item <%- selectableClass %> <%- linkClass %>">
          <span class="item-icon <%- iconClass %>"></span>\
          <span class="item-text"><%- text %></span>\
          <span class="item-icon icon-angle_right bottom-overflow-app-indicator"></span>\
        </button>
      `;

  }

  behaviors() {
    const behaviors = {
      PressedHoverButtonBehavior: {
        buttonTarget: '.bottom-overflow-menu-item'
      }
    };

    if (!this.getOption('isAppItem')) {
      Object.assign(behaviors, {
        SelectableBehavior: {
          selectableId: this.getOption('model').id
        }
      });
    }

    return behaviors;
  }

  constructor(options = {}) {
    const viewOptions = $.extend({ model: options.model}, options.model.get('viewOptions'));
    super(viewOptions);

    ({
      text: this.text,
      iconClass: this.iconClass,
      linkClass: this.linkClass,
      href: this.href
    } = viewOptions);
  }

  templateHelpers() {
    return {
      selectableClass: (!this.getOption('isAppItem') ? 'selectable' : ''),
      text: this.text,
      iconClass: this.iconClass,
      linkClass: this.linkClass,
      href: this.href
    };
  }
}

module.exports = OverflowMenuItem;
