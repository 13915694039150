const { LayoutView } = require('Marionette');
const UIKit = require('@training/widgets/UIKit');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const $os = require('detectOS');

require('@common/libs/behaviors/autosizable/Autosizable');

const ADD = true;
const REMOVE = false;
class ArticleDetailsCommentFormView extends LayoutView {
  initialize() {
    this.setupForm = this.setupForm.bind(this);
    this.toggleMobileWhitespace = this.toggleMobileWhitespace.bind(this);
  }

  getTemplate() {
    return require('@training/apps/articles/ArticleDetailsCommentFormTemplate.html');
  }

  ui() {
    return {
      commentInput: 'textarea.comment-textarea',
      commentForm: '#comment-form',
      inappropriateContentMsg: '.inappropriate-content-message',
      commentContainer: '.comment-form'
    };
  }

  templateHelpers() {
    return {
      isGuestOrSuperuser: window.apps.auth.session.user.isGuestOrSuperuser()
    };
  }

  behaviors() {
    return {
      Autosizable: {
        autosizableContainer: '.comment-textarea',
        resizeOnRender: true
      }
    };
  }

  events() {
    return {
      'submit @ui.commentForm': this.postComment,
      'blur textarea': () => {
        this.toggleMobileWhitespace(REMOVE);
      },
      'focus .comment-textarea': () => {
        this.toggleMobileWhitespace(ADD);
      }
    };
  }

  postComment(e) {
    e.preventDefault();

    this.ui.inappropriateContentMsg.addClass('hidden');
    this.ui.commentInput.removeClass('rederror');

    const errors = this.form.commit();
    if (!errors) {
      this.triggerMethod('post:comment');
    } else {
      window.app.layout.flash.error(errors);
    }
  }

  onRender() {
    this.setupForm();
  }

  setupForm() {
    this.form = new UIKit.Form({
      el: this.ui.commentForm,
      model: this.model
    });
  }

  onAttach() {
    if (this.shouldFormFocus()) {
      this.scrollToFormInput();
    }
  }

  shouldFormFocus() {
    const { hash } = window.location; // this can't be unit tested
    const queryParams = UrlHelpers.getQueryParams(UrlHelpers.getQueryString(hash));

    return queryParams.focus && queryParams.focus === 'form';
  }

  // If the page finished loading after attach (ex. image loaded post-facto), then we should attempt to re-focus and
  // scroll if we were directed to the page by the comment button.
  // If this method was triggered any other way, we should scroll/focus the form.
  scrollToFormInput(wasLoadDelayed = false) {
    if (wasLoadDelayed && !this.shouldFormFocus()) {
      return;
    }
    this.ui.commentInput.trigger('blur');
    this.ui.commentInput.trigger('focus');
  }

  showInappropriateContentErr() {
    this.ui.inappropriateContentMsg.removeClass('hidden');
    this.ui.commentInput.addClass('rederror');
  }

  clearInput() {
    this.ui.commentInput.val('');
    this.ui.commentInput.trigger('blur'); // this forces a textarea resize!
  }

  // SE-19077: Devices with virtual keyboards may have an issue where they cover the input field and limit scrolling.
  // This will extend the comment textarea with some extra whitespace at the bottom to allow the user to scroll up and
  // see what they're typing. The extra whitespace should disappear after clicking away.
  toggleMobileWhitespace(action = REMOVE) {
    if ($os.mobile) {
      this.ui.commentContainer.toggleClass('add-mobile-whitespace', action);
      BrowserHelpers.triggerResize();
    }
  }

}

module.exports = ArticleDetailsCommentFormView;
