const { ItemView } = require('Marionette');
const $os = require('detectOS');
const logging = require('logging');
const I18n = require('@common/libs/I18n');
const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const ExternalLinkHandlerType = require('@common/data/enums/ExternalLinkHandlerType');

const HeartbeatController = require('@common/libs/HeartbeatController');
const ExternalLinkDefaultTemplateHelpers
  = require('@training/apps/training/views/activities/pages/ExternalLinkDefaultTemplateHelpers');

const {
  ActionBarType,
  createButtonType
} = require('@common/components/actionBarButton/ActionBarButtonFactory');

require('@common/libs/behaviors/card/Card');

class ExternalLinkPage extends ItemView {
  className() {
    return 'external-link-page ax-container';
  }

  getTemplate() {
    return require('@training/apps/training/templates/activities/pages/ExternalLinkPage.html');
  }

  events() {
    return {
      'click #start': 'navigateToLink'
    };
  }

  behaviors() {
    return {
      Card: {
        target: '.topic-tile'
      }
    };
  }

  constructor(options = {}) {
    super(options);

    this.templateHelpers = this.templateHelpers.bind(this);
    this.navigateToLink = this.navigateToLink.bind(this);
    this.displayContinueButton = this.displayContinueButton.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.onNext = this.onNext.bind(this);

    this.activity = assertPropertyExists(options, 'activity');
    this.continueButtonFactory = assertPropertyExists(options, 'continueButtonFactory');

    ({
      isRetake: this.isRetake = false,
      complete: this.complete = () => {}
    } = options);

    this.heartbeatController = new HeartbeatController();

    // Load data
    this.externalLink = this.activity.getExternalLinkPackage();
  }

  templateHelpers() {
    return Object.assign({}, ExternalLinkDefaultTemplateHelpers.get(this.activity), {
      externalLink: this.externalLink
    });
  }

  onRender() {
    return $(window).on('focus', this.resetTimer);
  }

  onShow() {
    logging.info(`ExternalLinkPage - viewDidAppear ${ this.activity.id }`);
    window.app.layout.setTitle(I18n.t('externalLink.title'));
    this._configureSkippingButtons();

    // Now, show the continue button to the user if they have already prior clicked, overriding skip
    if (this.activity.get('body').hasOpenedBefore) {
      this.displayContinueButton();
    }
  }

  navigateToLink(e) {
    let externalLinkHandler;
    try {
      externalLinkHandler = ExternalLinkHandlerType.assertLegalValue(TenantPropertyProvider.get().getProperty('openExternalLinks'));
    } catch (e) {
      externalLinkHandler = ExternalLinkHandlerType.getDefaultValue();
    }

    if (externalLinkHandler === ExternalLinkHandlerType.DISABLED) {
      window.app.layout.flash.error(I18n.t('flash.externalLinkNotSupported'));
      this.displayContinueButton();
      return window.apps.base.cancelAction(e);
    }

    const containsHostName = this.externalLink.url.indexOf(window.location.hostname) !== -1;
    if ($os.isInMobileApp() && containsHostName) {
      // We want to preseve from domain cookies where possible
      window.apps.base.renderIFrame(this.externalLink.url);
      window.apps.base.cancelAction(e);
    } else {
      window.apps.base.openUrl(e);
    }

    this.heartbeatController.startHeartbeat();

    // No need to send the action again if it was already actioned against
    if (!this.activity.get('body').hasOpenedBefore) {
      this.activity.setAction('EXTERNALLINKOPENED').then(() => {
        this.displayContinueButton();
      }, (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);

        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_SESSION_STATE) {
          xhr.skipGlobalHandler = true;
          this.displayContinueButton();
        } else {
          logging.error('An error occured while setting EXTERNALLINKOPENED action.');
        }
      });
    }
    return true;
  }

  _configureSkippingButtons() {
    this.actionBar.setActionButton(this._createSkipButton());
  }

  _createSkipButton() {
    let skipButton;

    if (this.activity.isBypassable()) {
      skipButton = createButtonType(ActionBarType.SKIP, this.onSkip);
    } else if (this.isRetake) {
      skipButton = createButtonType(ActionBarType.SKIP, this.onNext);
    }

    return skipButton;
  }

  displayContinueButton() {
    this.actionBar.setActionButton(this.continueButtonFactory(this.onNext));
  }

  onSkip() {
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    this.activity.skipActivity().done(() => {
      logging.info('This external link was skipped.');
      this.complete();
    })
      .fail(() => {
        logging.error('There was a problem skipping this module. The server may have rejected it or it\'s not skippable.');
      });
    return true;
  }

  onNext() {
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    logging.info(`ExternalLinkPage - onNext ${ this.activity.id }`);

    this.activity.setAction('EXTERNALLINKSAVE').then(() => {
      this.complete();
    })
      .fail((xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);

        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_SESSION_STATE) {
          xhr.skipGlobalHandler = true;
          this.complete();
        } else {
          logging.error('An error occured while setting EXTERNALLINKSAVE action.');
        }
      });
    return true;
  }

  onDestroy() {
    // Stop the heartbeat
    this.heartbeatController.stopHeartbeat();

    $(window).off('focus', this.resetTimer);
    this.actionBar?.setActionButton(null);
  }
}

module.exports = ExternalLinkPage;
