module.exports = ({
  message,
  sourceURL,
  line,
  column,
  stackTrace
} = {}) => {
  const messages = [];

  if (sourceURL != null) {
    messages.push(`Source: ${ sourceURL }`);
  }

  if (line != null) {
    messages.push(`Line: ${ line }`);
  }

  if (column != null) {
    messages.push(`Column: ${ column }`);
  }

  if (stackTrace != null) {
    messages.push(['Stack:', ...stackTrace].join('\n    '));
  }

  if (messages.length > 0) {
    messages.unshift(message || 'Error:');
  } else if (message != null) {
    messages.unshift(message);
  }

  return messages.join('\n  ');
};
