const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const Backbone = require('Backbone');

const { createButtonWithCustomText } = require('@common/components/actionBarButton/ActionBarButtonFactory');

class AchievementsCongratulatoryModalContentView extends Marionette.LayoutView {
  className() {
    return 'modal__content';
  }

  regions() {
    return {
      modalBadgeRegion: '.modal__badge-region',
      modalStringRegion: '.modal__string-region'
    };
  }

  getTemplate() {
    return `
      <div class="modal__ribbon-container">
        <div class="modal__ribbon" aria-hidden="true"></div>
      </div>

      <div class="modal__pop-wrapper">
        <div class="modal__pop-container-1">
          <div class="icon-pop" aria-hidden="true"></div>
        </div>
        <div class="modal__pop-container-2">
          <div class="icon-pop" aria-hidden="true"></div>
        </div>

        <div class="modal__badge-wrapper ax-grid ax-grid--direction-column">
          <div class="modal__badge-region"></div>
          <p class="modal__badge-number" aria-hidden="true"><%- badgeNumber %></p>
        </div>

        <div class="modal__pop-container-3">
          <div class="icon-pop" aria-hidden="true"></div>
        </div>
        <div class="modal__pop-container-4">
          <div class="icon-pop" aria-hidden="true"></div>
        </div>
      </div>

      <div class="modal__string-region"></div>
    `;
  }

  onRender() {
    const viewAllButtonConfig = createButtonWithCustomText(
      I18n.t('hub.achievementsModal.viewAll'),
      () => {
        Backbone.history.navigate('#hub/achievements/progress', true);
      }
    );

    const gotItButtonConfig = createButtonWithCustomText(
      I18n.t('general.gotIt'),
      () => {
        this.triggerMethod('show:next:badge');
      }
    );

    this.actionBar.setActionBar({
      buttons: [
        Object.assign(viewAllButtonConfig, {
          className: 'view-all white'
        }),
        Object.assign(gotItButtonConfig, {
          className: 'got-it'
        })
      ]
    });
  }
}

module.exports = AchievementsCongratulatoryModalContentView;
