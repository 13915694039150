const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const Sync = require('@common/libs/Sync');
const ChallengeStatsModel = require('../models/ChallengeStatsModel');

class ChallengeStatsView extends UIKit.View {
  template = _.tpl(`\
    <% if (numChallenges > 0) { %>
      <div class="highlights subhighlight clearfix">
        <div class="highlight subhighlight game-scores">
          <div class="wrap clearfix" role="heading" aria-level="3">
            <div class="score ax-font ax-font__display ax-font--no-margin"><%- t('recentChallenges.stats.challengesWon', {"numWins": numWins, "numChallenges": numChallenges}) %></div>
            <span class="tag ax-font ax-font__caption ax-font--secondary"><%- t('recentChallenges.stats.totalChallengesWon') %></span>
          </div>
        </div>
      </div>
    <% } %>\
  `);

  className() {
    return 'challenge-stats';
  }

  initialize() {
    this.model = new ChallengeStatsModel();
    this.modelPromise = this.model.fetch();
  }

  render() {
    this.modelPromise.done(() => {
      return this.$el.html(this.template(this.model.toJSON()));
    });
    return this;
  }

  onClose(...args) {
    this.model.abortXHR(Sync.Method.READ);
    super.onClose(...args);
  }
}
module.exports = ChallengeStatsView;
