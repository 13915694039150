const _ = require('underscore');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');

// A class responsible for serializing and deserializing a user to the disk on the browser.
class LocalStorageUserCredentialsRepo {
  storeCredentials(credentials = {}) {
    if (LocalStorageHelpers.supportsLocalStorage()) {
      return localStorage.setItem('user', JSON.stringify(credentials));
    }
  }

  clearCredentials() {
    if (LocalStorageHelpers.supportsLocalStorage()) {
      return localStorage.removeItem('user');
    }
  }

  retrieveCredentials() {
    const deferred = $.Deferred();

    _.defer(() => {
      let user = null;
      if (LocalStorageHelpers.supportsLocalStorage() && localStorage.getItem('user')) {
        try {
          user = JSON.parse(localStorage.getItem('user'));
        } catch (error) { /* Error Message */ }
      }

      if (_.isObject(user) && !_.isEmpty(user)) {
        return deferred.resolve(user);
      }
      return deferred.reject({});
    });

    return deferred.promise();
  }
}

module.exports = LocalStorageUserCredentialsRepo;
