const Backbone = require('Backbone');
const TopicStatus = require('@common/data/enums/TopicStatus');
const _ = require('underscore');
const KnowledgeItemWarningLevel = require('@training/apps/training/enums/KnowledgeItemWarningLevel');
const KnowledgeItemWarningThreshold = require('@training/apps/training/enums/KnowledgeItemWarningThreshold');
const KnowledgeItemRecommendationFactory = require('@training/apps/training/models/KnowledgeItemRecommendationFactory');

class KnowledgeItem extends Backbone.Model {
  get idAttribute() {
    return 'generatedId';
  }

  parse(data) {
    data.generatedId = this._deriveGeneratedId(data);
    data.topicStatus = this._deriveTopicStatus(data);
    data.warningLevel = this._deriveWarningLevel(data);
    data.onlyTraining = this._deriveOnlyTraining(data);
    if (this._hasRecommendationData(data.recommendation)) {
      const recommendationReasonKey = data.recommendation.mostImpactingMetric;
      data.recommendationReason = KnowledgeItemRecommendationFactory.getReasonString(recommendationReasonKey);
    }
    return data;
  }

  _deriveGeneratedId(data) {
    return `${ data.topic.id }-${ data.level }`;
  }

  // topicStatus is an abstraction of progress within a topic, based on
  // performance and the level achieved by the user
  _deriveTopicStatus(data) {
    if ([TopicStatus.NEEDS_COACHING, TopicStatus.OVERCONFIDENT].includes(data.performance)) {
      return data.performance;
    }
    return data.progressLevel;

  }

  // warningLevel is based on how the currentMetrics.percentCorrect fits into the various thresholds
  // Low (0 - 50), Medium (51 - 79), High (80 - 100)
  _deriveWarningLevel(data) {
    if (data.currentMetrics.percentCorrect <= KnowledgeItemWarningThreshold.Low) {
      return KnowledgeItemWarningLevel.Low;
    } else if (data.currentMetrics.percentCorrect < KnowledgeItemWarningThreshold.Medium) {
      return KnowledgeItemWarningLevel.Medium;
    }
    return KnowledgeItemWarningLevel.High;

  }

  _deriveOnlyTraining(data) {
    return data.trainingAvailable && !data.questionsAvailable;
  }

  // should the item be in the Extra Training "Recommended Topics" list
  isRecommended() {
    return this._hasRecommendationData();
  }

  _hasRecommendationData(recommendationAttr = this.get('recommendation')) {
    return _.isObject(recommendationAttr);
  }
}
module.exports = KnowledgeItem;
