const env = require('env');
const _ = require('underscore');
const Marionette = require('Marionette');
const logging = require('logging');

const AxonifyUpdateService = require('@common/modules/updates/services/AxonifyUpdateService');

/**
 * This module is more or less responsible for bootstrapping the update checks on network requests.
 * It performs network inspection on headers to trigger handled installers to push out updates to the
 * users of the software who are running it.
 */
class UpdateApp extends Marionette.Module {
  initialize(moduleName, app, options) {
    this.updateService = new AxonifyUpdateService(env.settings.version);
    this.handlers = this._getHandlers(options.handlers);

    this.onRequestError = this.onRequestError.bind(this);
    this.onRequestSuccess = this.onRequestSuccess.bind(this);
    this.checkClientVersion = this.checkClientVersion.bind(this);
  }

  onStart() {
    logging.info('Update module has started. Installing update hooks...');
    try {
      this.initializeAjax();
      logging.info('Update module has installed. Updates are being tracked.');
    } catch (exception) {
      logging.error('The update hooks failed to install. System updates have been disabled.', exception);
    }
  }

  onStop() {
    this.teardownAjax();
  }

  initializeAjax() {
    $(document).ajaxSuccess(this.onRequestSuccess);
    $(document).ajaxError(this.onRequestError);
  }

  teardownAjax() {
    $(document).off('ajaxError', this.onRequestError);
    $(document).off('ajaxSuccess', this.onRequestSuccess);
  }

  onRequestSuccess(event, xhr) {
    this.checkClientVersion(xhr);
  }

  onRequestError(event, xhr) {
    this.checkClientVersion(xhr);
  }

  _getHandlers(handlers) {
    if (_.isEmpty(handlers)) {
      logging.warn('Registered an update module but no handlers specified. Updates will not be published.');
    }

    return handlers;
  }

  checkClientVersion(xhr) {
    const upgradeStatus = this.updateService.performUpgradedVersionFromRequestCheck(xhr);

    try {
      const updateHandler = this.handlers[upgradeStatus];

      if (updateHandler) {
        updateHandler.notify();
      }
    } catch (exception) {
      logging.error('Failed to dispatch the notification for update. User will not be notified of upgrade.', exception);
    }
  }
}

module.exports = UpdateApp;
