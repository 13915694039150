const Marionette = require('Marionette');

class RecoverUsernamePage extends Marionette.LayoutView {
  getTemplate() {
    return require('./RecoverUsernamePage.html');
  }

  ui() {
    return {
      username: '#user-recovery-username'
    };
  }

  triggers() {
    return {
      'click .submit': 'click:submit'
    };
  }

  onClickSubmit() {
    const username = this.ui.username.val();
    this.trigger('username:submit', {value: username});
  }
}

module.exports = RecoverUsernamePage;
