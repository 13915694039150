const env = require('env');
const $os = require('detectOS');

const { compact } = require('underscore');

const AxonifyApps = require('@common/data/enums/AxonifyApps').default;

const UserHelpers = require('@common/libs/helpers/app/UserHelpers');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const { getMenuAppItemData } = require('@common/modules/base/AppMenuDataFactory');

const {
  createAppItemViewDefinition
} = require('@common/modules/main/menu/item/MenuItemFactory');

const isCurrentApp = (app) => {
  return env.settings.app === app;
};

const getAdminZoneItemConfig = (authSession) => {
  const isSelfApp = isCurrentApp(AxonifyApps.ADMIN);
  const hasContent = authSession.get('shouldShowContent');
  const isMobile = $os.mobile || $os.tablet || $os.isInMobileApp();
  const useStrictAdminAppAccessGroup = TenantPropertyProvider.get().getProperty('blockAdminAccessForReportUsers');

  let hasUserTypeAccess;
  if (useStrictAdminAppAccessGroup) {
    hasUserTypeAccess = authSession.user.hasStrictAdminAppAccess();
  } else {
    hasUserTypeAccess = authSession.user.hasAdminAppAccess();
  }

  if (!isSelfApp && !isMobile && (hasUserTypeAccess || hasContent)) {
    return createAppItemViewDefinition(getMenuAppItemData(AxonifyApps.ADMIN));
  }
  return undefined;
};

const getLeaderZoneItemConfig = (authSession) => {
  const isSelfApp = isCurrentApp(AxonifyApps.MANAGER);
  const teamlinkLaunchableFromHub = TenantPropertyProvider.get().getProperty('teamlinkLaunchableFromHub');
  const hasUserTypeAccess = authSession.user.hasManagerAppAccess();

  if (!isSelfApp && teamlinkLaunchableFromHub && hasUserTypeAccess) {
    return createAppItemViewDefinition(getMenuAppItemData(AxonifyApps.MANAGER));
  }
  return undefined;
};

const getLearnerZoneItemConfig = (authSession) => {
  const isSelfApp = isCurrentApp(AxonifyApps.TRAINING);
  const isSuperUser = UserHelpers.isSuperuser(authSession.user);
  const hasUserTypeAccess = authSession.user.hasTrainingAppAccess();

  if (!isSelfApp && !isSuperUser && hasUserTypeAccess) {
    return createAppItemViewDefinition(getMenuAppItemData(AxonifyApps.TRAINING));
  }
  return undefined;
};

const factory = (authSession) => {
  return () => {
    return compact([
      getLearnerZoneItemConfig(authSession),
      getLeaderZoneItemConfig(authSession),
      getAdminZoneItemConfig(authSession)
    ]);
  };
};

module.exports = Object.assign(factory, {
  getAdminZoneItemConfig,
  getLeaderZoneItemConfig,
  getLearnerZoneItemConfig
});
