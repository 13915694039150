const I18n = require('@common/libs/I18n');
const TabController = require('@common/components/tabbed_layout/TabController');
const TimeLineCollection = require('@training/apps/timeline/TimelineCollection');

const {
  isAllTab,
  isStateMatchingTab
} = require('@training/apps/search/CommunityTabConfigHelper');

class CommunitySearchTabController extends TabController {
  initialize(options) {
    super.initialize();

    ({
      searchPageState: this.searchPageState,
      tabConfigs: this.tabConfigs
    } = options);

    this.listenTo(this.searchPageState, 'change:communitySearchCategory', () => {
      this.searchPageState.set('pageNum', 0);
      this._searchByTab();
    });
  }

  _onViewShowTab(tabName) {
    if (tabName === this.searchPageState.get('communitySearchCategory')) {
      this._searchByTab();
    } else {
      this.searchPageState.set('communitySearchCategory', tabName);
    }

    super._onViewShowTab(tabName);
  }

  _searchByTab() {
    const searchesArray = this._getPageResults();

    this.searchPageState.set('searchesArray', searchesArray);
  }

  _getPageResults() {
    const searchesArray = [];
    const community = this.searchPageState.get('community');

    this.tabConfigs.forEach((tabConfig) => {
      const tabName = tabConfig.tab.tabName;
      const isCurrentTab = isAllTab(tabName, this.searchPageState) || isStateMatchingTab(tabName, this.searchPageState);
      const pageNum = isCurrentTab ? this.searchPageState.get('pageNum') : 0;

      if (!isCurrentTab && !tabConfig.tab.showCount) {
        return;
      }

      const currentCollection = this.searchPageState.get('results')[tabName];
      currentCollection.state.pageSize = 12;

      this._setCountOnly(isCurrentTab, currentCollection, community);

      let searchItem;

      if (currentCollection instanceof TimeLineCollection) {
        searchItem = currentCollection.search(
          this.searchPageState.get('searchString'),
          pageNum,
          { error: () => {
            window.app.layout.flash.error(I18n.t('selfDirected.search.errors.communityNotFound'));
          }}
        );
      } else {
        searchItem = currentCollection.search(
          this.searchPageState.get('searchString'),
          this.searchPageState.get('articleUrlTags'),
          pageNum,
          undefined,
          {
            reset: true,
            error: () => {
              window.app.layout.flash.error(I18n.t('selfDirected.search.errors.communityNotFound'));
            }
          }
        );
      }

      searchesArray.push(searchItem);
    });

    return searchesArray;
  }

  _setCountOnly(isCurrentTab, currentCollection, community) {
    if (isCurrentTab) {
      currentCollection.changeExtraParameters({
        communityId: community.id,
        countOnly: false
      }, {
        fetch: false
      });
    } else {
      currentCollection.changeExtraParameters({
        communityId: community.id,
        countOnly: true
      }, {
        fetch: false
      });
    }
  }
}

module.exports = CommunitySearchTabController;
