const Marionette = require('Marionette');
const ReportModelChartDataSummarizer = require('@training/apps/search/reports/pages/dashboard/previews/pages_by_status/ReportModelChartDataSummarizer');
const I18n = require('@common/libs/I18n');

class ChartDataSource extends Marionette.Object {
  initialize(options = {}) {
    this._collection = options.collection;
    this.listenTo(this._collection, 'reset change sync', () => {
      this.trigger('change');
    });
  }

  getSeriesData() {
    const series = [];
    const pageSeries = {
      name: I18n.t('discover.browse.reports.pages.PAGES_BY_STATUS.series.title'),
      data: []
    };
    series.push(pageSeries);
    this._collection.each((dataPoint) => {
      const summarizer = new ReportModelChartDataSummarizer();
      const y = summarizer.getSummarizedValueForReportModel(dataPoint);
      if (y <= 0) {
        return;
      }
      const pageStatus = dataPoint.get('page_status');
      const pageType = dataPoint.get('page_type');
      const color = '';
      pageSeries.data.push({
        y,
        name: I18n.t((`discover.browse.reports.pages.PAGES_BY_STATUS.series.${ pageType }.${ pageStatus }`)),
        color
      });
    });
    return series;
  }

  getNumberOfSeries() {
    return this._collection.length;
  }

  isDataAvailable() {
    return this._collection.length > 0;
  }
}
module.exports = ChartDataSource;
