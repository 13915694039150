const _ = require('underscore');
const NotificationsController = require('@training/apps/training/controllers/NotificationsController');
const LearningEventsNotificationsPage = require('@training/apps/training/views/LearningEventsNotificationsPage');
const EventNotificationList = require('@training/apps/training/collections/EventNotificationList');
const { LEARNING_EVENTS } = require('@common/data/enums/HubTileIdentifiers').default;

class EventNotificationsController extends NotificationsController {
  constructor(...args) {
    super(...args);

    this.notificationsGroup = 'LEARNING_EVENTS';
    this.displayClass = LearningEventsNotificationsPage;

    this.notificationsList = new EventNotificationList([],
      {notificationsGroup: this.notificationsGroup});

    this.getNotifications = _.memoize(() => {
      const hubController = window.app.sessionController.getHubController();

      if (hubController.isHubTileEnabled(LEARNING_EVENTS)) {
        return this.notificationsList.fetch({showSpinner: false});
      }
      return Promise.resolve();

    });
  }
}

module.exports = EventNotificationsController;
