const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const RewardTypesEnum = require('@training/apps/home/hubPreviews/rewards/RewardTypesEnum');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
const {
  BADGE_MODIFIERS,
  TitleHeaderTypeEnum,
  TitleHeaderDefinitionBuilder
} = require('@common/components/titleHeader/TitleHeader');
const logging = require('logging');
const NoRewardsView = require('@training/apps/home/hubPreviews/rewards/NoRewardsView');

const getRewardTitleData = (model) => {
  const {
    title,
    endDate,
    totalRewards,
    winning,
    numberOfPrizes
  } = model.toJSON();

  switch (model.get('summaryType')) {
    case RewardTypesEnum.AUCTION:
      return {
        iconClass: 'icon-legal',
        title: getMiddotSeperatedText(I18n.t('auction.auction'), title),
        subTitle: getAuctionStatusText(endDate, winning)
      };
    case RewardTypesEnum.SLOTS:
      return {
        iconClass: 'icon-slots',
        title: I18n.t('auction.spinToWin'),
        subTitle: I18n.t(
          (totalRewards === 1 ? 'hub.postTraining.previews.rewards.rewardsAvailable.singular' : 'hub.postTraining.previews.rewards.rewardsAvailable.plural'),
          { rewardsAvailable: totalRewards }
        )
      };
    case RewardTypesEnum.RAFFLE:
      return {
        iconClass: 'icon-ticket',
        title: getMiddotSeperatedText(I18n.t('auction.raffle'), title),
        subTitle: getAuctionClosingText(endDate)
      };
    case RewardTypesEnum.BUYABLES:
      return {
        iconClass: 'icon-rewards',
        title: I18n.t('auction.shop'),
        subTitle: I18n.t(
          (numberOfPrizes === 1 ? 'hub.postTraining.previews.rewards.buyables.available.singular' : 'hub.postTraining.previews.rewards.buyables.available.plural'),
          { numberOfPrizes }
        )
      };
    default:
      return {};
  }
};

const getMiddotSeperatedText = (...parts) => {
  return parts.join(' · ');
};

const getAuctionStatusText = (endDate, winning) => {
  const closingText = getAuctionClosingText(endDate);

  if (winning) {
    return getMiddotSeperatedText( closingText, I18n.t('hub.postTraining.previews.rewards.auction.winning') );
  }
  return closingText;

};

const getAuctionClosingText = (endDate) => {
  if (endDate > Date.now()) {
    return I18n.t('hub.postTraining.previews.rewards.auction.closes.future', {timePhrase: dateHelpers.timeFromEvent(endDate)});
  }
  return I18n.t('hub.postTraining.previews.rewards.auction.closes.past', {timePhrase: dateHelpers.timeFromEvent(endDate)});

};

const getRewardsCardControllerDefinition = (options = {}) => {

  if (!options.rewardsCollection) {
    logging.error('The rewardsCollection object is required to generate the RewardsCardControllerDefinition');
    return null;
  }

  return getBaseCardControllerDefinition({
    cardClass: 'rewards-view',
    titleOptions: {
      title: I18n.t('hub.postTraining.previews.rewards.title')
    },
    linkOptions: {
      target: 'fullCard',
      linkTextKeyOverride: 'hub.postTraining.previews.rewards.linkText',
      linkAriaLabel: I18n.t('hub.postTraining.previews.rewards.linkText'),
      callback: options.callback
    },
    hasLink: true,
    tabbableLinkClickableCard: true,
    bottomWrapperControllerDefinition: {
      ViewControllerClass: CollectionLayoutViewController,
      collection: options.rewardsCollection,
      viewDefinition: {
        emptyView: NoRewardsView,
        tagName: 'ul',
        className: 'rewards__list full-width',
        childViewOptions: {
          tagName: 'li',
          className: 'rewards__row-region'
        }
      },
      ChildViewControllerDefinition: (model) => {
        const {
          iconClass,
          title,
          subTitle
        } = getRewardTitleData(model);

        return new TitleHeaderDefinitionBuilder({
          badgeModifierClasses: [BADGE_MODIFIERS.MEDIUM, BADGE_MODIFIERS.APP_ZONE],
          titleType: TitleHeaderTypeEnum.SUBTEXT_TITLE,
          iconClass,
          title,
          subTitle
        }).build();
      }
    }
  });
};

module.exports = {
  getRewardsCardControllerDefinition
};
