const PageableCollection = require('@common/components/pageableList/PageableCollection');
const TagGroupListItemModel = require('@training/apps/search/communitiesManagement/models/TagGroupListItemModel');

const LIST_SIZE = 9999;

class TagGroupFilterList extends PageableCollection {
  initialize(models, options = {}) {
    Object.assign(options, {
      fetchType: 'GET'
    }, options);

    super.initialize(models, options);
    this.state.pageSize = LIST_SIZE;
  }

  apiEndpoint() {
    return '/tagGroup';
  }

  get model() {
    return TagGroupListItemModel;
  }

  search(searchQuery, pageNum, options = {}) {
    this.searchQuery = searchQuery || '';

    return this.getPage(pageNum || 0, options);
  }

  triggerSelectedModel(selectedID) {
    const selectedModel = this.get(selectedID);
    if (selectedModel) {
      selectedModel.trigger('selected');
    }
  }
}

module.exports = TagGroupFilterList;
