const GuidedLearningObjective = require('@common/data/models/objectives/GuidedLearningObjective');
const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');
const EventObjectiveEnrollmentType = require('@common/data/enums/EventObjectiveEnrollmentType');

const TYPE_FIELD_NAME = 'type';
const COMPLETE_FILED_NAME = 'complete';
const STARTABLE_FIELD_NAME = 'startable';
const NAME_FIELD_NAME = 'name';
const SCHEDULED_EVENT_START_DATE = 'scheduledLearningEventStartDate';
const SCHEDULED_EVENT_ID = 'scheduledLearningEventId';
const NUM_SCHEDULED_EVENTS_AVAILABLE = 'numScheduledLearningEventsAvailable';
const ENROLLMENT_TYPE = 'enrollmentType';
const ENROLLMENT_ID = 'enrollmentId';
const IS_ENROLLED = 'isEnrolled';
const EVENT_ID = 'eventId';

class EventEnrollmentObjective extends GuidedLearningObjective {
  defaults() {
    return {
      [COMPLETE_FILED_NAME]: true,
      [TYPE_FIELD_NAME]: GuidedLearningObjectiveType.EVENT_OBJECTIVE
    };
  }

  isComplete() {
    return this.get(COMPLETE_FILED_NAME) === true;
  }

  isStartable() {
    return this.get(STARTABLE_FIELD_NAME);
  }

  getName() {
    return this.get(NAME_FIELD_NAME);
  }

  canBegin() {
    // An event has no notion of things beginning, so there is no need to worry about it being started
    return false;
  }

  canDrillDown() {
    const type = this.getEnrollmentType();
    return super.canDrillDown()
      && !(type === EventObjectiveEnrollmentType.NoScheduledLearningEventsAvailable
        || type === EventObjectiveEnrollmentType.ManagerEnrollmentRequired);
  }

  isEnrolled() {
    return this.getObjectiveDetails()[IS_ENROLLED];
  }

  isPending() {
    const type = this.getEnrollmentType();
    return this.getObjectiveDetails()[IS_ENROLLED] && type !== EventObjectiveEnrollmentType.Attended;
  }

  getEnrollmentType() {
    return this.getObjectiveDetails()[ENROLLMENT_TYPE];
  }

  getNumScheduledEventsAvailable() {
    return this.getObjectiveDetails()[NUM_SCHEDULED_EVENTS_AVAILABLE];
  }

  getScheduledEventStartDate() {
    return this.getObjectiveDetails()[SCHEDULED_EVENT_START_DATE];
  }

  getEventId() {
    return this.getObjectiveDetails()[EVENT_ID];
  }

  getScheduledEventId() {
    return this.getObjectiveDetails()[SCHEDULED_EVENT_ID];
  }

  getEnrollmentId() {
    return this.getObjectiveDetails()[ENROLLMENT_ID];
  }

  hasScheduledEventsAvailable() {
    return this.getEnrollmentType() === EventObjectiveEnrollmentType.ScheduledLearningEventsAvailable;
  }
}

module.exports = EventEnrollmentObjective;
