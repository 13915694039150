import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type AxonifyAppsType = EnumType & {
  ADMIN: string,
  TRAINING: string,
  MANAGER: string,
};

export default Enum({
  ADMIN: 'admin',
  TRAINING: 'training',
  MANAGER: 'teamlink'
}) as AxonifyAppsType;
