import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitSkeleton(cssVars: CSSStyleDeclaration) {
  return {
    skeleton: {
      backgroundColor: getCssValue('--ax-skeleton-background-color', cssVars),
      borderRadius: getCssValue('--ax-skeleton-border-radius', cssVars)
    }
  };
}
