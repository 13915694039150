module.exports = {
  // General Headers
  CONTENT_TYPE: 'Content-Type',

  // Axonify Headers
  REQUEST_TOKEN: 'X-Axonify-Request-Token',
  SERVER_VERSION: 'X-Axonify-Version',
  AVAILABLE_VERSION: 'X-Axonify-Available-Version',
  CLIENT_VERSION: 'X-Axonify-Client-Version',
  USER_AGENT: 'X-Axonify-User-Agent',
  FROM_MOBILE_APP: 'X-Axonify-From-Mobile-App',
  BROWSER_INSTANCE: 'X-Axonify-Browser-Instance',
  APP: 'X-Axonify-App',
  XSRF: 'X-XSRF-Header',
  EXPERIENCE_API_VERSION: 'X-Experience-API-Version',
  MIME_TYPE: 'X-Mime-Type',
  REQUEST_ORIGIN: 'X-Axonify-Request-Origin',
  CLIENT_BUILD_ID: 'X-Axonify-Client-Build-Id',

  // TEMPORARY to help with 500s in GCP/Akamai world
  RETURN_ENCRYPTED: 'X-Return-Encrypted-Headers',
  ENCRYPTED_DEBUG: 'X-Encrypted-Debug-Headers',

  // 3rd Party Headers
  BEAMER_API_KEY: 'Beamer-Api-Key'
};
