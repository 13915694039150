const Marionette = require('Marionette');

const ReactionDetailView = require('@training/apps/search/modals/reactionsModal/ReactionDetailView');
const LastItemReactionDetailView = require('@training/apps/search/modals/reactionsModal/LastItemReactionDetailView');
const NoReactionsMessage = require('@training/apps/search/modals/reactionsModal/NoReactionsMessage');

class ReactionModalTabContentCollectionView extends Marionette.CollectionView {
  tagName() {
    return 'ul';
  }

  className() {
    return 'reaction-collection';
  }

  onRender() {
    if (this.collection.length === 0) {
      this._addChildView(new NoReactionsMessage({tabName: this.options.tabName}));
    } else {
      this._addChildView(new LastItemReactionDetailView({ reactionsCount: this.collection.length }));
    }
  }

  get childView() {
    return ReactionDetailView;
  }
}

module.exports = ReactionModalTabContentCollectionView;
