const _ = require('underscore');
const Form = require('@common/components/forms/Form');
const CheckboxState = require('@common/components/forms/editors/checkboxes/CheckboxState');

Form.Editor.CascadingSelectCheckboxes = class CascadingSelectCheckboxes extends Form.Editor.Checkboxes {
  preinitialize(options) {
    super.preinitialize(options);
    this.default = [];
    this.optionTemplate = `\
      <div data-item-id="<%- id %>">
        <%= axCheckbox({
          name: 'checkbox-group-' + id,
          value: id,
          inline: true,
          ariaLabel: columnHeader || ''
        }) %>
      </div>\
    `;
  }

  events() {
    return Object.assign(super.events(), {
      'click input[type="checkbox"]': '_onClickCheckbox'
    });
  }

  _onClickCheckbox(e) {
    let selectedValue = this._getSerializedValueFromElement(e.currentTarget);
    const isChecked = $(e.currentTarget).prop('checked');
    const values = this.getOptions();
    const currentValIndex = values.indexOf(selectedValue);
    const $currentCheckbox = $(this.$(':checkbox').get(currentValIndex));
    const $previousCheckbox = $(this.$(':checkbox').get(currentValIndex - 1));

    // When a checkbox is deselected and it's not the first in a sequence, we set the previous checkbox as the selected
    // value and enable it. When the first checkbox in sequence is deselected, value is made to be undefined
    if (!isChecked && currentValIndex > 0) {
      selectedValue = values[currentValIndex - 1];

      $previousCheckbox.prop('disabled', false);

      // Deselect current checkbox and select the previous
      this._setCheckboxProps($currentCheckbox, false, false);
      this._setCheckboxProps($previousCheckbox, true, false);
    } else if (!isChecked && currentValIndex === 0) {
      selectedValue = undefined;
      this._setCheckboxProps($currentCheckbox, false, false);
    } else {
      this._setCheckboxProps($currentCheckbox, true, false);
    }

    if (isChecked && currentValIndex > 0) {
      this._cascadeSelections(selectedValue);
    }
  }

  setValue(value = this.default) {
    super.setValue(value);
    this._cascadeSelections(value);
  }

  setState(state = CheckboxState.DISABLED_SELECTED, values = []) {
    const isDisabled = state === CheckboxState.DISABLED_SELECTED;
    const isInactive = state === CheckboxState.OVERRIDDEN;

    _.each(values, (value) => {
      const index = this.getOptions().indexOf(value);
      const $checkbox = $(this.$(':checkbox')[index]);

      $checkbox.prop('disabled', true);
      this._setCheckboxProps($checkbox, true, isDisabled, isInactive);
    });
  }

  clearState() {
    this.$(':checkbox').each((index, element) => {
      $(element).prop('disabled', false);
      this._setCheckboxProps($(element), false);
    });
  }

  getValue() {
    const values = super.getValue();
    if (values.length === 0) {
      return undefined;
    }

    return _.max(values);
  }

  getOptions() {
    const values = [];
    this.$(':checkbox').each((index, element) => {
      values.push(this._getSerializedValueFromElement(element));
    });

    return values;
  }

  renderCheckedOptions() {
    if (this.collection && this.collection.length) {
      const ids = this.shallowValue ? this.value : _.pluck(this.value, 'id');

      this.$(':checkbox').each((index, element) => {
        const parsedValue = this._getSerializedValueFromElement(element);
        const checked = Array.from(ids).includes(parsedValue);
        $(element).prop('checked', checked);
      });
    }
  }

  _cascadeSelections(selectedValue) {
    this.$(':checkbox').each((index, element) => {
      const parsedValue = this._getSerializedValueFromElement(element);
      const checked = parsedValue <= selectedValue;
      const inactive = parsedValue < selectedValue;
      const $el = $(element);

      $el.prop('checked', checked).prop('disabled', inactive);
      $el.closest('.ax-checkbox').toggleClass('ax-checkbox--partial', inactive);

      this._setCheckboxProps($el, checked, false, inactive);
    });
  }

  _setCheckboxProps($checkbox, checked, disabled, inactive = false) {
    $checkbox.prop('checked', checked);
    $checkbox.prop('disabled', disabled || inactive);
    $checkbox.closest('.ax-checkbox').toggleClass('ax-checkbox--partial', disabled || inactive);
  }
};

module.exports = Form.Editor.CascadingSelectCheckboxes;
