// Module
var code = `<ul class="auctionlist ax-grid">
  <% _.each(items, function (item) { %>
  <li
    class="auctionitem ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-4 <% if(item.rewardProgramPrize.isLocked) { %>itemlocked<% } else { %>itemunlocked<% } %>"
    data-auction-id="<%-item.rewardProgramPrize.id %>"
    id="auction<%-item.rewardProgramPrize.id %>">

    <div class="wrap">
      <div class="auctionimage">
        <div
          role="button"
          tabindex="0"
          aria-label="<%= t('auction.imageZoomLabel', { prizeName: item.rewardProgramPrize.prize.name }) %>"
          class="auctionitemimage image-wrapper"
        ></div>
        <div class="locked"></div>
      </div>

      <h2 class="qa-auction-title auctiontitle ax-font ax-font__display" title="<%= item.rewardProgramPrize.prize.name %>">
        <%= item.rewardProgramPrize.prize.name %>
      </h2>

      <p class="auctiontime ax-font--no-margin ax-font--secondary">
        <% if(item.rewardProgramPrize.isLocked) { %>
        <%- t('auction.locked') %> <span class="unlocksin"></span><br />
        <% if(item.rewardProgramPrize.showReasonIfLocked) { %>
        <span class="prizeLockedReason"><%- item.rewardProgramPrize.prizeLockedReason %></span>
        <% } %>
        <% } else { %>
        <%- t('auction.timeremaining') %> <span class="timeleft"></span>
        <% } %>
        </p>

      <% if (item.rewardProgramPrize.rewardProgram.showBidInfo && 0 < item.bidCount) { %>
      <% if(item.isWinning) { %>
      <p class="winningbid" aria-live="assertive"><%- t('auction.winningbid') %></p>
      <% } else if(item.bid) { %>
      <p class="outbid" aria-live="assertive"><%- t('auction.outbid') %></p>
      <% } %>

      <div class="biddetails">
        <div class="biddetailsinner ax-grid ax-grid--no-gutter clearfix">
          <div class="biddetail bids">
            <div class="bidcount ax-font ax-font__m ax-font--no-margin">
              <%- numberWithCommas(item.bidCount) %>
            </div>
            <div class="ax-font ax-font__caption"><%- t('auction.bidcount') %></div>
          </div>
          <div class="biddetail currentbid">
            <div class="amountbid ax-font ax-font__m ax-font--no-margin">
              <% if(item.isWinning) { %>
              <%- numberWithCommas(item.bid.points) %>
              <% } else { %>
              <%- numberWithCommas(item.minWinningBid) %>
              <% } %>
            </div>
            <div class="ax-font ax-font__caption"><%- t('auction.current') %></div>
          </div>
        </div>
      </div>
      <% } %>
    </div>

    <div class="wrapbuttons">
      <div class="auctionbuttons">
        <% if(item.bid) { %>
          <%= axButton({
            id: 'auctionButton' + item.rewardProgramPrize.id,
            className: 'auction editbid js-placebid',
            label: t('auction.editbid') + '<span class="minbid">' + numberWithCommas(item.bid.points) + '</span>',
            ariaLabel: t('auction.editbid') + ' ' + numberWithCommas(item.bid.points) + ' ' + item.rewardProgramPrize.prize.name
          }) %>
        <% } else { %>
          <%= axButton({
            id: 'auctionButton' + item.rewardProgramPrize.id,
            className: 'auction js-placebid',
            label: t('auction.placebid') + '<span class="minbid">' + numberWithCommas(item.minWinningBid || item.rewardProgramPrize.minBid) + '</span>',
            ariaLabel: t('auction.placebid') + ' ' + numberWithCommas(item.minWinningBid || item.rewardProgramPrize.minBid) + ' ' + item.rewardProgramPrize.prize.name
          }) %>
        <% } %>
      </div>
    </div>

  </li>
  <% }); %>
</ul>
`;
// Exports
module.exports = code;