import I18n from '@common/libs/I18n';
import { ReactControllerDefinitionFactory } from '@common/modules/react';
import type ActionBar from '@training/apps/main/views/ActionBar';
import { type PinMessageType } from '@training/apps/recognitions/notifications/RecognitionPinsUserMsgView';

type ReactControllerDefinitionFactoryProps = {
  messages: PinMessageType[],
  actionBar: ActionBar,
  complete: () => void,
};

export default function RecognitionPinsUserMsgFactory(options: ReactControllerDefinitionFactoryProps) {
  const {
    messages,
    actionBar,
    complete
  } = options;

  return {
    ...ReactControllerDefinitionFactory({
      component: import('@training/apps/recognitions/notifications/RecognitionPinsUserMsgView'),
      props: {
        messages
      }
    }),
    delegateEvents: {
      'view:show': () => {
        actionBar.setActionButton({
          type: 'continue',
          onClick: complete
        });

        window.app.layout.setTitle(I18n.t('recognitionPins.notifications.title'));
      },
      'view:before:destroy': () => {
        actionBar?.setActionButton(null);
        actionBar?.hide();
      }
    }
  };
}
