const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const PageTitleBarContextConfig = require('@training/apps/training/pageTitleBar/PageTitleBarContextConfig');
const PageTitleBarLayoutDefinition = require('@training/apps/training/pageTitleBar/PageTitleBarLayoutDefinition');

module.exports = (contextModel) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: PageTitleBarLayoutDefinition,
    regionControllers: {
      leftRegion: () => {
        return PageTitleBarContextConfig.getLeftDefinition(contextModel);
      },
      rightRegion: () => {
        return PageTitleBarContextConfig.getRightDefinition(contextModel);
      },
      bailRegion: () => {
        return PageTitleBarContextConfig.getBailDefinition(contextModel);
      }
    },
    delegateEvents: {
      'view:inflate': (controller) => {
        controller.listenTo(contextModel, `change:${ PageTitleBarContextConfig.Keys.LEFT_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('leftRegion', controllerDefinition, options.showOptions);
        });

        controller.listenTo(contextModel, `change:${ PageTitleBarContextConfig.Keys.RIGHT_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('rightRegion', controllerDefinition, options.showOptions);
        });

        controller.listenTo(contextModel, 'change:pageTitleBarBailDefinition', (state, controllerDefinition, options) => {
          controller.swapRegionController('bailRegion', controllerDefinition, options.showOptions);
        });
      }
    }
  };
};
