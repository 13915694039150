const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const logging = require('logging');

const {
  ActionBarType,
  createButtonType,
  createButtonWithCustomText,
  toggleButtonSecondary
} = require('@common/components/actionBarButton/ActionBarButtonFactory');
const BehaviourTypeEnum = require('@common/data/enums/BehaviourTypeEnum');
const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');
const EvaluationSubmissionUploadModal = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionUploadModal');

class EvaluationDetailsLayoutView extends LayoutView {

  initialize(options = {}) {
    ({
      evaluationForm: this.evaluationForm,
      guidedLearningFlowCallback: this.guidedLearningFlowCallback,
      startEvaluationCallback: this.startEvaluationCallback,
      isViewingLastAttempt: this.isViewingLastAttempt
    } = options);

    this.evaluationTitle = this.evaluationForm.getEvaluationTitle();
    this.evaluationStatus = this.evaluationForm.getEvaluationStatus();

    this.backToList = this.backToList.bind(this);
  }

  getTemplate() {
    return require('./EvaluationDetailsLayoutTemplate.html');
  }

  templateHelpers() {
    return {
      descriptionClass: this.evaluationForm.getDescription() ? '' : 'no-description',
      isVirtual: this.evaluationForm.getEvalType() === BehaviourTypeEnum.VirtualEvaluation
    };
  }

  regions() {
    return {
      titleRegion: '.evaluation-details__header',
      descriptionRegion: '.evaluation-details__description',
      infoRegion: '.evaluation-details__info',
      contentRegion: '.evaluation-details__content',
      submissionUploadRegion: '.evaluation-details__submission-upload'
    };
  }

  onRender() {
    this.actionBar.setActionButton(this._getActionButtonConfigs());
  }

  viewDidAppear() {
    logging.info('EvaluationDetailsPage - viewDidAppear');


    this.setTitle();
  }

  setTitle() {
    const tabTitle = `${ I18n.t('assessments.select.header.title.EvaluationTraining') } - ${ this.evaluationTitle }`;
    window.app.layout.setTitle(tabTitle);
  }

  _getActionButtonConfigs() {
    if (this.isViewingLastAttempt) {
      return [];
    }

    const isEvaluationNotStarted = this.evaluationStatus === EvaluationObjectiveStatus.NOT_STARTED;
    const isEvaluationFailed = this.evaluationStatus === EvaluationObjectiveStatus.FAILED;

    // View Path
    const buttonConfigs = [
      toggleButtonSecondary(createButtonWithCustomText(I18n.t('assessments.select.viewPage.GuidedLearningTraining'), this.backToList))
    ];

    if (isEvaluationNotStarted) {
      if (this.evaluationForm.isVirtualEvaluation()) {
        // Upload a File
        buttonConfigs.push(
          createButtonWithCustomText(I18n.t('GuidedLearning.evaluation.details.uploadAFile'), () => {
            this.listenToOnce(this.submissionUploadRegion.currentView, 'file:upload:ready', this._openFileSubmissionModal);
            this.submissionUploadRegion.currentView.ui.clickTarget.trigger('click');
          })
        );
      } else {
        // Continue
        buttonConfigs.push(
          createButtonType(ActionBarType.CONTINUE, this.startEvaluationCallback(this.evaluationForm, '/evaluations/ready'))
        );
      }
    } else if (isEvaluationFailed) {
      if (this.evaluationForm.getEvalType() === BehaviourTypeEnum.VirtualEvaluation) {
        // Upload a File
        buttonConfigs.push(
          createButtonWithCustomText(I18n.t('general.retry'), () => {
            this.listenToOnce(this.submissionUploadRegion.currentView, 'file:upload:ready', this._openFileSubmissionModal);
            this.submissionUploadRegion.currentView.ui.clickTarget.trigger('click');
          })
        );
      } else {
        // Retry
        buttonConfigs.push(
          createButtonWithCustomText(I18n.t('general.retry'), this.startEvaluationCallback(this.evaluationForm, '/evaluations/retry'))
        );
      }
    }

    return buttonConfigs;
  }

  _openFileSubmissionModal(submissionFile) {
    if (submissionFile) {

      const callbackTarget = this.evaluationStatus === EvaluationObjectiveStatus.FAILED
        ? '/evaluations/retry'
        : '/evaluations/ready';
      const onSubmitAction = this.startEvaluationCallback(this.evaluationForm, callbackTarget);

      this.submitModal = EvaluationSubmissionUploadModal.displayModal(
        window.app.layout,
        this.evaluationForm,
        submissionFile,
        onSubmitAction
      );

      this.listenTo(this.submitModal, 'destroy', () => {
        this.submitModal = undefined;
      });
    }
  }

  backToList() {
    if (_.isFunction(this.guidedLearningFlowCallback)) {
      this.guidedLearningFlowCallback();
    }
  }

  onDestroy() {
    this.actionBar.setActionBar();
  }
}

module.exports = EvaluationDetailsLayoutView;
