import {
  ListItem as MuiListItem,
  styled,
  type ListItemBaseProps
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { merge } from 'lodash';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxListItemCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * Whether the list item has padding.
   * @default false
   */
  padded?: boolean
};

type PropsToOmit = 'disablePadding';

export type AxListItemTypeMap<P = object, D extends React.ElementType = 'li'> = {
  props: P & Omit<ListItemBaseProps, PropsToOmit> & AxListItemCustomProps,
  defaultComponent: D
};

export type AxListItemProps<
  D extends ElementType = AxListItemTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxListItemTypeMap<P & AxListItemCustomProps, D>, D>;

const styledOptions = {
  name: 'AxListItem',
  shouldForwardProp: (prop: string) => {
    return prop !== 'padded';
  }
};

/**
* A list item component that can be used in a list.
*
* ### Links
* - • [MUI ListItem | API](https://mui.com/material-ui/api/list-item/)
*/
const StyledAxListItem = styled(MuiListItem, styledOptions)<AxListItemProps>(({
  padded,
  theme
}) => {
  return merge(
    {
      margin: 0,
      '.MuiListItemText-root': {
        lineHeight: theme.uiKit.lineHeightXS,
        color: theme.uiKit.colorGrey70,
        fontSize: theme.uiKit.fontSizeXS,
        fontWeight: theme.uiKit.fontWeightMedium
      },
      '.MuiListItemText-secondary': {
        lineHeight: theme.uiKit.lineHeightXS,
        color: theme.uiKit.colorGrey60,
        fontSize: theme.uiKit.fontSizeXS,
        fontWeight: theme.uiKit.fontWeightRegular
      },
      '.MuiListSubheader-root': {
        fontSize: theme.uiKit.fontSizeXS
      }
    },
    padded ? {
      padding: `${ theme.uiKit.spacingS } ${ theme.uiKit.spacingM }`
    } : {
      padding: 0
    }
  );
});

/**
* A list item component that can be used in a list.
*
* @example
* <AxList>
*  <AxListItem>
*    <AxListItemText primary="Item 1" secondary="Secondary text" />
*  </AxListItem>
*  <AxListItem>
*    <AxListIcon>
*     <AxIcon icon="check" />
*   </AxListIcon>
*    <AxListItemText primary="Item 2" secondary="Secondary text" />
*  </AxListItem>
* </AxList>
*/
export const AxListItem: MuiOverridableComponent<AxListItemTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxListItemProps, ref: Ref<HTMLLIElement>) => {
  const ListItemProps: AxListItemProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItem
      { ...ListItemProps }
      ref={ ref }
    >
      { children }
    </StyledAxListItem>
  );
});

export default AxListItem;
