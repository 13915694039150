import {
  Model,
  Collection
} from 'backbone';

const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
import { ItemView } from 'Marionette';
import { getBaseCardControllerDefinition } from '@common/components/baseCard/BaseCardControllerDefinitionFactory';

function _generateCollection() {
  const models = [];
  for (let i = 0; i < 5; i++) {
    models.push(new Model());
  }
  return new Collection(models);
}

export default function QuickLinksCardSkeletonDefintion() {
  const defaultOptions = {
    cardClass: 'base-card--achievements-skeleton quick-links-card',
    titleOptions: {
      title: '<div class="skeleton skeleton--sm" style="transform: scale(1, 0.8);"></div>'
    },
    templateOverride: `
      <div class="base-card__wrapper base-card__wrapper--upper quick-links-card__upper">
        <div class="base-card__title"></div>
        <div class="base-card__view-all">
          <div class="skeleton skeleton--sm quick-links-card__view-all--btn" style="transform: scale(1, 0.8);"></div>
        </div>
      </div>
      <div class="base-card__wrapper base-card__wrapper--lower quick-links-card__lower"></div>
    `,
    bottomWrapperControllerDefinition: {
      ViewControllerClass: CollectionLayoutViewController,
      collection: _generateCollection(),
      viewDefinition: {
        tagName: 'ul',
        className: 'quick-links-card__list',
        childViewOptions: {
          template: false,
          tagName: 'li'
        }
      },
      ChildViewControllerDefinition: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'quick-links-card__list--item',
          template: `
            <div class="skeleton skeleton__badge--lg quick-links-card__list--item-badge" style="transform: scale(1);"></div>
            <div class="skeleton skeleton--sm" style="transform: scale(1, 0.8);"></div>
          `
        }
      }
    }
  };

  return getBaseCardControllerDefinition(defaultOptions);
}
