// Module
var code = `<div class="datetime-popdown popdown">
  <div class="now-container">
    <a href="#" id="now"><%- t('UIKit.Form.Editor.DateTime.now') %></a>
  </div>
  <div class="date"></div>
  <div class="go-to-timepicker">
    <span class="icon-time"></span>
    <span><%- t('UIKit.Form.Editor.DateTime.setTime') %></span>
  </div>
  <div>
    <div class="time-picker clearfix">
      <div class="back-to-calendar">
        <span class="icon-calendar_empty"></span>
        <span><%- t('UIKit.Form.Editor.DateTime.setDate') %></span>
      </div>
      <div class="time-picker-wrap">
        <select class="time-select" name="hours">
          <% for(var i = 1; i < 13; i++) { %>
            <option value="<%- i %>" selected><%- i %></option>
          <% } %>
        </select>

        <select class="time-select" name="minutes">
          <% for(var i = 0; i < 60; i+= minuteSelectorInterval) { %>
            <option value="<%- i %>"><%- i >= 10 ? i  : '0' + i%></option>
          <% } %>
        </select>

        <select class="time-select" name="ampm">
          <option value="AM" selected><%- t('UIKit.Form.Editor.DateTime.AM') %></option>
          <option value="PM"><%- t('UIKit.Form.Editor.DateTime.PM') %></option>
        </select>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;