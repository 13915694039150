const Backbone = require('Backbone');

class CommunityContentCount extends Backbone.Model {
  initialize(options = {}) {
    (({
      communityId: this.communityId
    } = options));

  }

  apiEndpoint() {
    return `/pageCommunities/config/${ this.communityId }/contentCount`;
  }

  parse(data) {
    return { count: data.result };
  }

  fetch(options = {}) {
    // allows the communityId to be changed at fetch-time
    if (options.data && options.data.communityId) {
      this.communityId = options.data.communityId;
    }
    return super.fetch(options);
  }
}

module.exports = CommunityContentCount;
