import { without } from 'lodash';

import { type AxThemedDialogProps } from '@common/modules/react/themes/components';

import createContextHelper from '@common/libs/helpers/app/createContextHelper';

export interface AxDialogContextState {
  rootModals: AxThemedDialogProps[]
  pushModals: AxThemedDialogProps[]
}

export const {
  Provider: AxDialogContextProvider,
  useStore: useAxDialogContext
} = createContextHelper<AxDialogContextState>({
  rootModals: [],
  pushModals: []
});

export function showModal(store: AxDialogContextState, dialog: AxThemedDialogProps): Partial<AxDialogContextState> {
  return {
    rootModals: [...without(store.rootModals, dialog), dialog]
  };
}

export function closeModal(store: AxDialogContextState, dialog: AxThemedDialogProps): Partial<AxDialogContextState> {
  return {
    rootModals: without(store.rootModals, dialog),
    pushModals: without(store.pushModals, dialog)
  };
}

export function pushModal(store: AxDialogContextState, dialog: AxThemedDialogProps): Partial<AxDialogContextState> {
  const exists = store.pushModals.indexOf(dialog) !== -1;

  return exists ? store : {
    pushModals: [...store.pushModals, dialog]
  };
}
