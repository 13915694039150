const logging = require('logging');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const KnowledgeItemView = require('@training/apps/training/views/KnowledgeItemView');

const QuestionPreviewController = require('@common/components/questions/QuestionPreviewController');
const TrainingModuleType = require('@common/data/enums/TrainingModuleType');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const TrainingModulePreviewView = require('@training/apps/topics/TrainingModulePreviewView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const TrainingImageZoom = require('@training/apps/training/TrainingImageZoom');

const {
  createButtonWithCustomText,
  createButtonType,
  ActionBarType
} = require('@common/components/actionBarButton/ActionBarButtonFactory');
const KnowledgeModulePreview = require('@training/apps/training/models/KnowledgeModulePreview');
const AxonifyExceptionFactory = require('@common/services/error/AxonifyExceptionFactory');
const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');

class KnowledgeDetailPage extends KnowledgeItemView {

  constructor(options) {
    super(options);

    this.onQuestionClicked = this.onQuestionClicked.bind(this);
    this.showQuestionAnswerById = this.showQuestionAnswerById.bind(this);
    this.viewTraining = this.viewTraining.bind(this);
    this.previewTraining = this.previewTraining.bind(this);
    this.confirmPreview = this.confirmPreview.bind(this);
  }

  className() {
    return 'extratrainingdetails';
  }

  getTemplate() {
    return require('../templates/_knowledge_item.html');
  }

  templateHelpers() {
    return Object.assign({}, super.templateHelpers(), {
      headerTitle: I18n.t('knowledge.title'),
      headerIconClass: 'icon-reportcard'
    });
  }

  events() {
    return {
      'click .questionblock': 'onQuestionClicked',
      'touchstart .questionblock': 'onQuestionClicked'
    };
  }

  questionTemplate = _.tpl(require('@training/apps/training/templates/_knowledge_question_item.html'));

  initialize(options) {
    super.initialize(options);

    this.complete = this.options.complete;
    this.questions = this.options.questions;
    this.answers = {};
  }

  onRender() {
    this._renderNavBar();
    this._renderQuestions();
    this._renderActionBar();
  }

  _renderQuestions() {
    const $questionswrap = this.$el.find('.questionswrap');
    $questionswrap.find('.questionblock').remove();

    $questionswrap.find('.noquestions').toggleClass('hidden', this.questions.answerHistory.length > 0);

    const answerHistory = _.chain(this.questions.answerHistory).groupBy('questionId')
      .map((value) => {
        return {answerHistory: value};
      })
      .value();

    const result = [];
    for (const answerNodes of Array.from(answerHistory)) {
      const answerNode = answerNodes.answerHistory;
      result.push($questionswrap.append(this.questionTemplate({answerNode})));
    }
    return result;
  }

  _renderNavBar() {
    window.app.layout.showHeaderBackButton();
  }

  _renderActionBar() {
    const assessmentInProgress = this.model.get('inProgressAssessmentGetter')();
    const trainingAvailable = this.model.get('trainingAvailable') || this.model.get('questionsAvailable');
    const {
      topicId,
      topicLevel
    } = this._getTopicAndLevel();

    const { isExtraTrainingEnabled } = this.options;
    const alreadyDoneExtraTraining = window.app.sessionController.session.hasExtraQuestionsAssessmentsForTopicIdLevel(topicId, topicLevel);

    const actionBtns = [];
    const trainingModule = this.model.get('trainingModule');

    if (trainingModule != null && TenantPropertyProvider.get().getProperty('trainingModulePreviewEnabled')) {
      const moduleType = trainingModule.moduleType.toLowerCase();

      switch (moduleType) {
        case TrainingModuleType.EXTERNAL_LINK.toLowerCase():
          actionBtns.push(createButtonType(ActionBarType.PREVIEW_LINK, this.confirmPreview));
          break;
        case TrainingModuleType.PDF.toLowerCase():
          actionBtns.push(createButtonType(ActionBarType.PREVIEW_PDF, this.confirmPreview));
          break;
        case TrainingModuleType.EXTERNAL_VIDEO.toLowerCase():
        case TrainingModuleType.TRANSCODED_VIDEO.toLowerCase():
          actionBtns.push(createButtonType(ActionBarType.PREVIEW_VIDEO, this.previewTraining));
          break;
        default:
          actionBtns.push(createButtonType(ActionBarType.PREVIEW_MODULE, this.previewTraining));
      }
    }

    if (assessmentInProgress != null) {
      actionBtns.push(createButtonWithCustomText(I18n.t('assessments.item.action.resume'), this.viewTraining));
    } else if (isExtraTrainingEnabled && trainingAvailable && !alreadyDoneExtraTraining) {
      actionBtns.push(createButtonType(ActionBarType.VIEW_TRAINING, this.viewTraining));
    }

    if (actionBtns.length > 0) {
      this.actionBar.setActionButton(actionBtns);
    }
  }

  onAttach() {
    logging.info('KnowledgeDetailPage (Mobile) - viewDidAppear');
  }

  onQuestionClicked(e) {
    const $questionblock = $(e.currentTarget);
    const answerId = $questionblock.data('answer-id');
    this.showQuestionAnswerById(answerId);
    return false;
  }

  showQuestionAnswerById(answerId) {
    if (this.modalInProgress) {
      return;
    }
    this.modalInProgress = true;
    this.questionPreview = new QuestionPreviewController({
      container: '#details-view',
      imageZoom: TrainingImageZoom
    });
    this.questionPreview.loadQuestionPreview(answerId);

    this.listenTo(this.questionPreview.questionLayoutWrapper, 'destroy', () => {
      this.modalInProgress = false;
    });

    this.listenTo(this.questionPreview, 'invalid:answer', () => {
      this.modalInProgress = false;
      window.app.layout.flash.error(I18n.t('question.previewUnavailable'));
    });
  }

  previewTraining() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view'
    });

    const modalChildView = new ConfirmDialogView({
      confirmCallback: this.confirmPreview,
      model: this.model,
      title: I18n.t('trainingPreview.videoConfirmationModal.title'),
      confirmationText: I18n.t('trainingPreview.videoConfirmationModal.description'),
      buttonText: I18n.t('general.continue'),
      buttonColorClassName: 'default'
    });

    window.app.layout.presentModal(modalView, { closeClick: true });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  confirmPreview() {
    window.app.layout.dismissModal();

    const modalView = new AccessibleModalView({
      id: 'modalView',
      className: 'modal training-preview-modal modal--s'
    })

    const trainingModulePreview = new KnowledgeModulePreview({ moduleId: this.model.get('trainingModule').id });
    trainingModulePreview.fetch({
      success: () => {
        const trainingModule = trainingModulePreview.get('trainingModule');
        if (!trainingModule.id) {
          // If tenant property is disabled between knowledge call and preview call, then we can get an empty module returned
          window.app.layout.flash.error(I18n.t('trainingPreview.errors.notAuthorized'));
          return;
        }

        const modalChildView = new TrainingModulePreviewView({ trainingModule });

        window.app.layout.presentModal(modalView, { closeClick: true });
        modalView.setSubviewIn(modalChildView);

        this.listenToOnce(modalChildView, 'destroy', () => {
          window.app.layout.dismissModal();
        });
      },
      error: (model, error) => {
        const exception = AxonifyExceptionFactory.fromResponse(error);
        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
          error.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('trainingPreview.errors.notAuthorized'));
        }
      }
    });
  }

  viewTraining() {
    if (apps.base.checkDoubleSubmit() || this.startClicked) {
      return false;
    }
    this.startClicked = true;

    const {
      topicId,
      topicLevel
    } = this._getTopicAndLevel();
    this.options.takeAction(topicId, topicLevel, this.complete);

    return true;
  }

  _getTopicAndLevel() {
    const topicId = this.model.get('topic').id;
    const topicLevel = this.model.get('level');
    return {
      topicId,
      topicLevel
    };
  }

  onDestroy() {
    if (this.questionPreview != null) {
      this.questionPreview.destroy();
    }

    this.actionBar?.setActionButton(null);
    this.actionBar?.hide();

    window.app.layout.resetLeftHeaderView();
  }
}

module.exports = KnowledgeDetailPage;
