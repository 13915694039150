const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const TodaysActivitiesDefinitionFactory = require('@training/apps/home/activities/TodaysActivitiesDefinitionFactory');
const SkipTrainingViewDefinitionFactory = require('@training/apps/home/skipTraining/SkipTrainingViewDefinitionFactory');

module.exports = (options = {}) => {
  const {
    session,
    complete,
    startCurrentSessionTraining,
    skipTrainingCallback
  } = options;

  return () => {
    return {
      ViewControllerClass: StackedLayoutViewController,
      childControllers: [
        StackedLayoutViewController.createConfigObject(
          ViewOptionHelpers.classNameAppender('dailyTraining'),
          TodaysActivitiesDefinitionFactory(session, complete, startCurrentSessionTraining)
        ),
        StackedLayoutViewController.createConfigObject(
          ViewOptionHelpers.classNameAppender('skip-training'),
          SkipTrainingViewDefinitionFactory(skipTrainingCallback)
        )
      ]
    };
  };
};
