module.exports = (options = {}) => {
  const {
    videoPlayer,
    mediaLink = {},
    frequency = 1000,
    preventSeekForward = false
  } = options;

  if (videoPlayer == null) {
    throw new Error('"videoPlayer" is required');
  }

  let previousTimeStamp = null;
  let intervalId = null;
  let videoId = null;
  if (mediaLink.media) {
    videoId = mediaLink.media.id;
  }

  const saveCurrentTime = () => {
    const isSeeking = videoPlayer.isSeeking();

    if (preventSeekForward && isSeeking) {
      return;
    }

    let currentTimeStamp = Math.ceil(videoPlayer.getCurrentTime());
    if (currentTimeStamp === Math.ceil(videoPlayer.getDuration())) {
      currentTimeStamp = 0;
    }

    //check to make sure we are not sending the same data on future api calls, preventing unnecessary calls
    if (previousTimeStamp !== currentTimeStamp) {
      previousTimeStamp = currentTimeStamp;
      $.ajax({
        url: `/axonify/media/video/progress/${ videoId }`,
        type: 'PUT',
        shouldRetry: false,
        data: JSON.stringify({
          progress: currentTimeStamp
        }),
        skipGlobalHandler: true
      });
    }
  };

  videoPlayer.listenTo(videoPlayer, 'player:play', () => {
    if (intervalId != null) {
      return;
    }
    intervalId = setInterval(saveCurrentTime, frequency);
  });

  videoPlayer.listenTo(videoPlayer, 'destroy player:pause video:seek', () => {
    clearInterval(intervalId);
    intervalId = null;

    if (mediaLink.media && videoId) {
      saveCurrentTime();
    }
  });
};
