// Module
var code = `<div class="cropped-image-upload ax-grid ax-grid--no-gutter">
  <div class="adjust-photo-wrap ax-grid__col--7">
    <div class="adjust-photo">
      <label class="adjust-photo-title"><%- t('photoCrop.adjustPhoto.title') %></label>
      <div class="clearfix">
        <div class="adjust-photo-subTitle floatleft"><%- t('photoCrop.adjustPhoto.subTitle') %></div>
      </div>
    </div>
    <div class="media-thumbnail-region"></div>
    <div class="adjust-photo-change-button js-camera-button qa-change-photo-button ax-button ax-button--s ax-button--branded-secondary" role="button" tabindex="0">
      <%- t('photoCrop.adjustPhoto.button.title') %>
    </div>
  </div>
  <div class="media-previews"></div>
</div>`;
// Exports
module.exports = code;