const { CollectionView } = require('Marionette');
const OffscreenMeasurementMenuItemView = require('@common/modules/main/menu/offscreenMeasurement/OffscreenMeasurementMenuItemView');
const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');

module.exports = (contextModel) => {
  const itemCollection = MenuContextConfig.getItems(contextModel);

  return {
    viewDefinition: {
      ViewClass: CollectionView,
      tagName: 'ul',
      className: 'menu-list menu-list-top',
      childView: OffscreenMeasurementMenuItemView,
      collection: itemCollection
    }
  };
};
