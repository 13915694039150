const {
  CollectionView,
  ItemView
} = require('Marionette');
const { Collection } = require('Backbone');

/**
 * ButtonGroupView
 * takes in an array of button configurations with: a title, value, and optionally isActive and ariaLabel properties.
 * in return you recive a group of buttons in one UI. Clicking on a button will bubble an event up containing the
 * value of the button clicked
 */

class ButtonGroupView extends CollectionView {
  initialize(options = {}) {
    this.collection = new Collection(options.buttonsConfig || []);
    this.childClass = options.childClass || '';
    this.childView = ButtonGroupItemView;
  }

  childEvents() {
    return {
      'button:click': (view, value) => {
        this.trigger('buttonGroup:click', value);
      }
    };
  }

  childViewOptions() {
    return {
      childClass: this.childClass
    };
  }
}

class ButtonGroupItemView extends ItemView {
  initialize() {
    this._handleStateChange = this._handleStateChange.bind(this);
    this.listenTo(this.model, 'change', this._handleStateChange);
    this._setActiveState(this.model.get('isActive'));
  }

  tagName() {
    return 'button';
  }

  className() {
    return `ax-button ${ this.options.childClass }`;
  }

  getTemplate() {
    return '<%- title %>';
  }

  attributes() {
    return {
      'aria-label': this.model.get('ariaLabel') || ''
    };
  }

  events() {
    return {
      click: this._handleClick
    };
  }

  _handleClick() {
    this.trigger('button:click', this.model.get('value'));
  }

  _handleStateChange() {
    this._setActiveState(this.model.get('isActive'));
  }

  _setActiveState(bool) {
    let state = bool;
    if (state === undefined) {
      state = false;
    }
    this.$el.toggleClass('ax-button--branded', state);
    this.$el.attr('aria-pressed', state);
  }
}

module.exports = ButtonGroupView;
