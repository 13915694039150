const { LayoutView } = require('Marionette');

class DiscoverTrainingCardBottomRegionLayoutView extends LayoutView {
  getTemplate() {
    return `
      <div class="js-progress-region training-card__progress-bar-wrapper"></div>
      <div class="js-info-region training-card__bottom-info-wrapper"></div>
    `;
  }

  className() {
    return 'training-card__discover-bottom';
  }

  regions() {
    return {
      progressRegion: '.js-progress-region',
      infoRegion: '.js-info-region'
    };
  }
}

module.exports = DiscoverTrainingCardBottomRegionLayoutView;
