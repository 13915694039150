const { Model } = require('Backbone');
const { extend, pick, some, keys } = require('underscore');

const dimensionDefaults = {
  width: 0,
  height: 0,
  top: 0,
  left: 0
};

const dimensionKeys = keys(dimensionDefaults);

const overlayVisibilityDefaults = {
  overlayVisibility: false
};

const overlayVisibilityKey = 'overlayVisibility';

class LayeredLayoutState extends Model {

  defaults() {
    return extend({}, dimensionDefaults, overlayVisibilityDefaults);
  }

  setDimensionCss(cssOptions = {}) {
    this.set(pick(cssOptions, ...dimensionKeys));
  }

  hasDimensionChanged() {
    return some(dimensionKeys, this.hasChanged.bind(this));
  }

  getDimensionCss() {
    return this.pick(...dimensionKeys);
  }

  hasOverlayVisibilityChanged() {
    return this.hasChanged(overlayVisibilityKey);
  }

  isOverlayVisible() {
    return this.get(overlayVisibilityKey);
  }

  toggleOverlayVisibility(toggle = false) {
    this.set(overlayVisibilityKey, toggle);
  }
}

module.exports = LayeredLayoutState;
