const {
  extend
} = require('underscore');
const {
  Behavior,
  isNodeAttached,
  Renderer
} = require('Marionette');

const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

const Behaviors = require('@common/libs/behaviors/Behaviors');
const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');
const ScrollEvents = require('@common/libs/behaviors/scrollable/ScrollEvents');

const KeyCode = require('@common/data/enums/KeyCode');

require('@common/components/modal/ModalScrollIndicator.less');

Behaviors.ModalScrollIndicatorBehavior = class ModalScrollIndicatorBehavior extends Behavior {

  initialize() {
    this._viewTransitionDuration = 160;
    this.isShrinking = false;
    this.isGrowing = false;
    this.isShrunk = true;
  }

  ui() {
    return {
      contentScrollContainer: '.content-scroll-down-container',
      contentScroll: '.content-scroll-down',
      scrollButton: '.content-scroll-down button'
    };
  }

  events() {
    return {
      'click @ui.scrollButton': 'onClickScrollDown',
      'keydown @ui.scrollButton': 'onKeydownScrollDown'
    };
  }

  behaviors() {
    return {
      ScrollEvents: {}
    };
  }

  onRender() {
    const target = this.getOption('downScrollerTarget');

    if (target != null) {
      const $target = this.$(target);

      const html = Renderer.render(this._getDownScrollerTemplate());
      $target.after(html);

      this.view.bindUIElements();

      this.ui.contentScroll.hide();

      this.ui.contentScrollContainer.prev().css({
        position: 'relative',
        'z-index': 1
      });

      this.ui.contentScrollContainer.css({
        'z-index': 2
      });

      this.ui.contentScrollContainer.next().css({
        position: 'relative',
        'z-index': 3
      });

      const paddingProp = I18n.isCurrentLanguageRtl() ? 'padding-left' : 'padding-right';
      const padding = `${ BrowserHelpers.getScrollbarWidth() }px`;
      this.ui.contentScroll.css(paddingProp, padding);
    }
  }

  onScroll(scrollData, scrollDataChange) {
    if (!isNodeAttached(this.ui.contentScroll[0])) {
      return;
    }

    if (this.downScrollerHeight == null) {
      this.downScrollerHeight = this.view.getOuterHeight(this.ui.contentScroll, false, true);
    }

    if (scrollData[ScrollEvents.ScrollEventKeys.IS_VERTICAL_OVERFLOW]) {
      const isOverflowChange = ScrollEvents.hasDataChanges(scrollDataChange, ScrollEvents.ScrollEventKeys.IS_VERTICAL_OVERFLOW);
      const scrollBottom = scrollData[ScrollEvents.ScrollEventKeys.SCROLL_BOTTOM];
      const scrollBottomChange = (this._prevScrollBottom || 0) - scrollBottom;
      const isScrollingUp = scrollBottomChange < 0;
      const isScrollingDown = scrollBottomChange > 0;
      const isNotAtBottom = scrollBottom > 0;
      const isBottomBehindDownScroller = scrollBottom < this.downScrollerHeight;

      // When the scroll diff is smaller than the height of the $downScroller and the overall height difference
      // between content and container is greater than the $downScroller then we start to hide it
      // by shrinking its size and turning its opacity down
      if ((isOverflowChange || isScrollingUp) && isNotAtBottom) {
        this._growDownScroller();
      } else if (isScrollingDown && isBottomBehindDownScroller) {
        this._shrinkDownScroller();
      }

      this._prevScrollBottom = scrollBottom;
    } else {
      this._shrinkDownScroller();
    }
  }

  onClickScrollDown() {
    this._scrollPageDown();
  }

  onKeydownScrollDown(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      e.preventDefault();
      this._scrollPageDown();
    }
  }

  onBeforeDestroy() {
    this.ui.contentScroll.velocity('stop');
  }

  _getDownScrollerTemplate() {
    return `
      <div class="content-scroll-down-container">
        <div class="content-scroll-down">
          <button class="ax-button ax-button--floating">
            <span class="content-scroll-down-arrow icon-chevron_down"></span>
            <span class="content-scroll-down-inner"><%- t('general.scrollDown') %></span>
            <span class="content-scroll-down-arrow icon-chevron_down"></span>
          </button>
        </div>
      </div>
    `;
  }

  _growDownScroller() {
    if ((this.isShrunk && !this.isGrowing) || this.isShrinking) {
      this.isGrowing = true;
      this.isShrinking = false;

      const animationValues = {
        height: this.downScrollerHeight,
        opacity: [1, 0],
        // prevents velocity from overriding the existing translate value during the animation
        translateX: this._getTranslateXForcefeedValues()
      };

      this.ui.contentScroll.show();
      this.ui.contentScroll.velocity('stop');
      this.ui.contentScroll.velocity(animationValues, {
        duration: this._viewTransitionDuration,
        easing: 'ease-in-out',
        complete: () => {
          this.isGrowing = false;
          this.isShrunk = false;
        }
      });
    }
  }

  _shrinkDownScroller() {
    if ((!this.isShrunk && !this.isShrinking) || this.isGrowing) {
      this.isShrinking = true;
      this.isGrowing = false;

      const animationValues = {
        height: 0,
        opacity: [0, 1],
        // prevents velocity from overriding the existing translate value during the animation
        translateX: this._getTranslateXForcefeedValues()
      };

      this.ui.contentScroll.velocity('stop');
      this.ui.contentScroll.velocity(animationValues, {
        duration: this._viewTransitionDuration,
        easing: 'ease-in-out',
        complete: () => {
          this.ui.contentScroll.hide();
          this.isShrinking = false;
          this.isShrunk = true;
        }
      });
    }
  }

  _getTranslateXForcefeedValues() {
    const xValue = I18n.isCurrentLanguageRtl() ? '50%' : '-50%';
    return [xValue, xValue];
  }

  _scrollPageDown() {
    const scrollData = ScrollEvents.getScrollEventData(this);
    Scrollable.scrollToTop(this.view, scrollData[ScrollEvents.ScrollEventKeys.SCROLL_TOP] + scrollData[ScrollEvents.ScrollEventKeys.CONTAINER_HEIGHT], { animate: true });
  }
};

module.exports = Behaviors.ModalScrollIndicatorBehavior;
