const Backbone = require('Backbone');
const Marionette = require('Marionette');
const SCORMNewWindowCardView = require('@common/components/training_modules/scorm/SCORMNewWindowCardView');
const SCORMManifestParser = require('@common/libs/scorm/SCORMManifestParser');
const SCORMManifestFetcher = require('@common/libs/scorm/SCORMManifestFetcher');
const SCORMStatusEnum = require('@common/data/enums/SCORMStatusEnum');

class SCORMNewWindowCardCollectionView extends Marionette.CollectionView {
  constructor(options = {}) {
    super(options);

    const {
      scormPackage
    } = options;

    this._createCollection(scormPackage);
  }

  get childView() {
    return SCORMNewWindowCardView;
  }

  childViewOptions(model, index) {
    return {
      scormVersion: this.scormVersion,
      index
    };
  }

  className() {
    return 'scorm-list';
  }

  _createCollection(scormPackage) {
    const scormManifestFetcher = new SCORMManifestFetcher();
    const manifestURL = scormPackage.manifestDownloadUrl;

    const options = {
      url: manifestURL,
      success: (data, status, jqXHR) => {
        const manifestXML = $(jqXHR.responseXML);
        const parser = new SCORMManifestParser();
        ({
          scormVersion: this.scormVersion,
          scoMap: this.scoMap,
          launchData: this.launchData
        } = parser.parse(manifestXML, manifestURL));

        this.collection = new Backbone.Collection(Object.values(this.scoMap));
        // When not Multi SCORM
        if (this.collection.length === 1) {
          if (scormPackage.title != null) {
            const title = scormPackage.title;

            this.collection.first().set('title', title);
          }
          if (scormPackage.scoItems != null) {
            this.collection.first().set('scoItem', scormPackage.scoItems[0]);
          }
        } else {
          this.collection.each((model) => {
            if (scormPackage.scoItems != null) {
              const scoItem = scormPackage.scoItems.find((packageScoItem) => {
                return packageScoItem.identifier === model.get('serverIdentifier');
              });
              model.set('scoItem', scoItem);
            }
          });
        }
      }
    };

    scormManifestFetcher.getManifest(options);
  }

  areAllSubModulesComplete() {
    return this.collection.all((model) => {
      return model.get('status') === SCORMStatusEnum.completed;
    });
  }
}

module.exports = SCORMNewWindowCardCollectionView;
