const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'DAILY_REINFORCEMENT',
  'TOPIC_CERTIFICATION',
  'TOPIC_EXTRA_TRAINING',
  'TOPIC_REFRESHER',
  'TOPIC_INTRO',
  'TOPIC_DEFAULT',
  'PATH_ENROLLED',
  'PATH_ASSIGNED',
  'SUMMARY_TASKS',
  'SUMMARY_EXAMS',
  'SUMMARY_INTRO',
  'SUMMARY_REFRESHER',
  'SUMMARY_CERTIFICATIONS',
  'DISCOVER_CARD',
  'TRAINING_PAGE'
]);
