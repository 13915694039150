const { Model } = require('Backbone');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const FileUploader = require('@common/libs/file/uploader/FileUploader');
const I18n = require('@common/libs/I18n');

class File extends Model {

  urlRoot = '/axonify/media/file';

  get idAttribute() {
    return 'uuid';
  }

  parse(res) {
    return res.file;
  }

  save(key, value, options) {
    let attrs, left, left1;
    let optionsToUse = options;
    if ((key === null) || (typeof key === 'object')) {
      attrs = key;
      optionsToUse = value != null ? value : {};
    } else {
      (attrs = {})[key] = value;
    }

    //Deferred to allow cancelling of upload
    const xhr = $.Deferred();
    this.uploader = new FileUploader({
      url: this._getUploadURL(this),
      fileType: (left = this.get('file-type')) != null ? left : attrs['file-type'],
      sizeLimit: optionsToUse.sizeLimit,
      allowedExtensions: optionsToUse.allowedExtensions != null ? optionsToUse.allowedExtensions : ['*'],

      onProgress: (id, name, loaded, total) => {
        return (optionsToUse.progress != null ? optionsToUse.progress.call(this, loaded, total) : undefined);
      },
      onComplete: (id, fileName, response) => {
        this.set(response);
        this.unset('file');
        if (typeof optionsToUse.success === 'function') {
          optionsToUse.success(this);
        }
        return xhr.resolve(id, fileName, response);
      },
      onError: (id, fileName, response = {}) => {
        // We need to handle the server sided cases as well
        if (response.status === 400) {
          response.skipGlobalHandler = true;

          const exception = AxonifyExceptionFactory.fromResponse(response);
          const errCode = exception.getErrorCode();

          if (errCode === AxonifyExceptionCode.CLIENT_ERROR_FILE_TOO_LARGE) {
            response.errMessage = I18n.t('fileUploader.fileTooBig', {maximumSize: optionsToUse.sizeLimit});
          } else {
            response.errMessage = I18n.t('flash.simpleFileUploadRetry');
          }
        }

        xhr.reject(id, fileName, response);
        return (optionsToUse.error != null ? optionsToUse.error.call(this, this, response, optionsToUse) : undefined);
      }
    });

    this.uploader.upload((left1 = this.get('file')[0]) != null ? left1 : attrs.file[0]);

    const uploadObject = {
      abort: () => {
        return this.uploader.handler.cancelAll();
      }
    };

    return xhr.promise(uploadObject);
  }

  _getUploadURL(model) {
    return `${ model.url() }/uploadSecureContent`;
  }
}

module.exports = File;
