import {
  useMediaQuery,
  type Theme,
  type Breakpoints
} from '@mui/material';

type BreakpointOperations = Pick<Breakpoints, 'up' | 'down' | 'only' | 'not' | 'between'> ;

export const useScreenSize = <K extends keyof BreakpointOperations>(breakpointOperation: K, ...args: Parameters<BreakpointOperations[K]>): boolean => {
  return useMediaQuery((theme: Theme) => {
    // @ts-expect-error - not sure how to type this
    return theme.breakpoints[breakpointOperation](...args);
  });
};

export function useIsMobile() {
  return useScreenSize('down', 'md');
}

export function useIsTablet() {
  return useScreenSize('between', 'md', 'lg');
}

export function useIsDesktop() {
  return useScreenSize('up', 'lg');
}
