const I18n = require('@common/libs/I18n');
const NavButtonItemView = require('@common/components/navButton/NavButtonItemView');

class NeedsReviewNavButtonItemView extends NavButtonItemView {
  triggers() {
    return {click: 'needsreview:item:clicked'};
  }

  attributes() {
    return Object.assign(super.attributes(), {
      'aria-label': I18n.t('discover.browse.needsReview')
    });
  }

  className() {
    return super.className() + ' search-community-list-item__needsreview';
  }

  getTemplate() {
    return require('./NeedsReviewNavButtonItemView.html');
  }

  ui() {
    return Object.assign(super.ui(), {
      reportedText: '.reported',
      pendingText: '.meta.needs-review'
    });
  }

  onRender() {
    this.ui.reportedText.toggleClass('hidden', this.model.get('reportedCount') === 0);
    this.ui.pendingText.toggleClass('hidden', this.model.get('reviewCount') === 0);
  }
}

module.exports = NeedsReviewNavButtonItemView;
