/**
 * This service is a thin wrapper over top of creating captions in order to make it easier to share the logic that
 * checks to see if something requires captioning and picking the best method inside of the application.
 */
class VideoCaptioningService {
  constructor(videoCaptioningStrategySupplier, mediaHelpers) {
    this.videoCaptioningStrategySupplier = videoCaptioningStrategySupplier;
    this.mediaHelpers = mediaHelpers;


    if (!this.videoCaptioningStrategySupplier) {
      throw new Error('You have to provide some kind of video captioning strategy supplier in order to use this');
    }

    if (!this.mediaHelpers) {
      throw new Error('You must provide a media service that can interact with media to be able to use this service');
    }
  }

  generateMissingCaptions(videoMedia, language) {
    // Take care of the early bail case
    if (videoMedia.hasTranscriptionForLanguage(language)) {
      return Promise.resolve();
    }

    const bestMethodForLanguage = this.videoCaptioningStrategySupplier.getBestMethodForSourceLanguage(language);
    return Promise.resolve(this.mediaHelpers.addVideoCaptions(videoMedia, language, bestMethodForLanguage));
  }
}

module.exports = VideoCaptioningService;
