/*
  NamedParameterClassFactory

  Based off how Marionette creates Region instances but made generic so any Class hierachy can be specified.

  Initializing a factory accepts 3 params:
    BaseClass (Default: Object) - The root class of the inheritance hierachy this builder will be responsible for.
    detaultOptions: (Default: {}) - Any default options that should get passed in when instantiating the class
    definitionClassProp: (Default: 'Class') - Name of the key in the definition object that defines what sub class of
      the BaseClass will be built when calling build().

  Definition object can be either:
    1) an instance object that inherits from the BaseClass
    2) a function
    2a) a 'Class' constructor function that creates instances of BaseClass
    2b) a function that returns a new definition object or instance that is/extends BaseClass
    3) a definition object that defines the 'Class' to be built along side any constructor options

  Examples of Definitions:

    viewbuilder = new NamedParameterClassFactory
      BaseClass: Backbone.View
      definitionClassProp: 'ViewClass'

    view = new View
      className: 'my-view'
      model: new Backbone.Model
      ...

    viewBuilder.create(view)

    NOTE: Use mainly for legacy code that already has instantiated objects to work with and isn't easily converted to
    use the Object form below. Since it's an instance, the definition can't be reused to create multiple instances like
    the object form below can.

  or

    viewbuilder.create
      ViewClass: View
      className: 'my-view'
      model: new Backbone.Model
      ...

  or

    viewBuilder.create View,
      className: 'your-class'
      model: new Backbone.Model

  or

    viewBuilder.create (options) ->
      if options.foo
        return {
          ViewClass: Marionette.ItemView
          className: 'foo-view'
          ....
        }
      else if options.bar
        return new Marionette.LayoutView
          className: 'bar-class'
          template: 'some template string'

*/

const {
  omit,
  isFunction,
  isObject,
  isArray,
  chain,
  findLastIndex,
  extend
} = require('underscore');

class NamedParameterClassFactory {
  definitionClassProp = 'Class';

  defaultDefinition = {
    Class: Object
  };

  constructor(options = {}) {
    this.createDefinitionObject = this.createDefinitionObject.bind(this);
    this.isSubclassInstance = this.isSubclassInstance.bind(this);

    this._setDefinitionClassProp(options.definitionClassProp);
    this._setDefaultDefinition(options.defaultDefinition);

    this._setBaseClass();
  }

  create(...args) {
    const mergedDefinition = this.buildDefinition(...args);
    return this.createWithDefinitionObject(mergedDefinition);
  }

  buildDefinition(...args) {
    const definitionObjects = this.convertToDefinitionObjects(args);
    return this.mergeDefinitionObjects(this._getDefaultDefinitionObject(), ...definitionObjects);
  }

  convertToDefinitionObjects(definitions) {
    return chain(definitions)
      .compact()
      .map(this.createDefinitionObject)
      .value();
  }

  createDefinitionObject(definition = {}) {
    if (this.isSubclassInstance(definition)) {
      return this.createSubclassInstanceDefinitionWrapper(definition);
    } else if (this.isDefinitionObject(definition)) {
      return definition;
    } else if (isFunction(definition)) {
      return this.createDefinitionObjectFromFunction(definition);
    }
    throw new Error(`Tried to create instance from an invalid definition: ${ definition }!`);

  }

  createDefinitionObjectFromFunction(definitionFunction) {
    let definitionObject = {};

    if (this.isSubclassConstructor(definitionFunction)) {
      definitionObject[this._definitionClassProp] = definitionFunction;
    } else {
      definitionObject = definitionFunction();
    }

    return this.createDefinitionObject(definitionObject);
  }

  createSubclassInstanceDefinitionWrapper(instance) {
    return {
      [this._definitionClassProp]: function() {
        return instance;
      }
    };
  }

  getOverridingInstance(definitionObjects = []) {
    const lastInstanceIndex = findLastIndex(definitionObjects, this.isSubclassInstance);

    if (lastInstanceIndex > -1) {
      return definitionObjects[lastInstanceIndex];
    }
    return null;

  }

  mergeDefinitionObjects(...definitionObjects) {
    return extend({}, ...definitionObjects);
  }

  createWithDefinitionObject(definitionObject = {}) {
    const Class = this._getDefinitionClass(definitionObject);
    const options = this._getDefinitionOptions(definitionObject);

    return this.instantiateDefinitionClass(Class, options);
  }

  instantiateDefinitionClass(DefinitionClass, options) {
    return new DefinitionClass(options);
  }

  isDefinitionObject(definition = {}) {
    const isObj = isObject(definition);
    const isFunc = isFunction(definition);
    const isArr = isArray(definition);

    return isObj && !isFunc && !isArr;
  }

  isSubclassConstructor(DefinitionClass) {
    return this._isSubclassConstructor(DefinitionClass, this._BaseClass);
  }

  isSubclassInstance(definitionObject) {
    return this._BaseClass && definitionObject instanceof this._BaseClass;
  }

  _isSubclassConstructor(Class, SuperClass) {
    return (Class === SuperClass) || Class.prototype instanceof SuperClass;
  }

  _getDefinitionClass(definitionObject) {
    return definitionObject[this._definitionClassProp] != null ? definitionObject[this._definitionClassProp] : this._BaseClass;
  }

  _getDefinitionOptions(definitionObject) {
    return omit(definitionObject, this._definitionClassProp);
  }

  _getDefaultDefinitionObject() {
    return this.createDefinitionObject(this._defaultDefinition);
  }

  _setDefinitionClassProp(definitionClassProp) {
    return this._definitionClassProp = definitionClassProp != null ? definitionClassProp : this.definitionClassProp;
  }

  _setDefaultDefinition(defaultDefinition) {
    return this._defaultDefinition = defaultDefinition != null ? defaultDefinition : this.defaultDefinition;
  }

  _setBaseClass() {
    this._BaseClass = this._getDefaultDefinitionObject()[this._definitionClassProp];

    if (!isFunction(this._BaseClass)) {
      throw new Error('Default BaseClass is not a function and can therefore not be used as a constructor!');
    }
  }
}

module.exports = NamedParameterClassFactory;
