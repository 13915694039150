const { prependBaseScriptUrl } = require('@common/libs/helpers/app/UrlHelpers');

const CSSInjectorPackageFactory = require('@common/services/cssLoader/CSSInjectorPackageFactory');
const NullCSSInjectorPackageFactory = require('@common/services/cssLoader/NullCSSInjectorPackageFactory');

class VersionedCSSInjectorPackageFactory extends CSSInjectorPackageFactory {

  constructor(key, packageFactory) {
    super(key);

    this._packageFactory = packageFactory || new NullCSSInjectorPackageFactory();
  }

  create(options) {
    const { key, paths } = this._packageFactory.create(options);

    return {
      key,
      paths: paths.map(prependBaseScriptUrl)
    };
  }
}

module.exports = VersionedCSSInjectorPackageFactory;
