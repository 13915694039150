const Backbone = require('Backbone');
const BehaviourTypeEnum = require('@common/data/enums/BehaviourTypeEnum');

const PROGRESS_FIELD = 'progress';
const STATUS_FIELD = 'pathStatus';
const ATTEMPT_FIELD = 'attempt';
const LAST_FAILED_FORM_ID_FIELD = 'lastFailedFormId';

const FORM_FIELD = 'form';
const TYPE_FIELD = 'type';
const EVAL_TYPE_FIELD = 'type';
const NAME_FIELD = 'name';
const PASSING_SCORE_FIELD = 'passingScore';
const DESCRIPTION_FIELD = 'description';
const OBSERVER_FIELD = 'observer';
const SCORE_FIELD = 'score';
const EVALUATED_DATE_FIELD = 'effectiveDate';
const DEPARTMENT_FIELD = 'department';
const PRIVACY_FIELD = 'employeeIdPrivacyEnabled';
const OBSERVATIONS_FIELD = 'observations';
const MEDIA_FIELD = 'media';
const MEDIA_ID_FIELD = 'id';

class EvaluationForm extends Backbone.Model {
  constructor(options = {}) {
    super(options);

    ({
      userId: this.userId,
      evaluationId: this.evaluationId
    } = options);
  }

  apiEndpoint() {
    return `/evaluations/user/${ this.userId }/type/${ this.evaluationId }`;
  }

  _getForm() {
    return this.get(FORM_FIELD);
  }

  _getType() {
    return this._getForm()[TYPE_FIELD];
  }

  getEvaluationTitle() {
    return this._getType()[NAME_FIELD];
  }

  getPassingScore() {
    return this._getType()[PASSING_SCORE_FIELD];
  }

  getDescription() {
    return this._getType()[DESCRIPTION_FIELD];
  }

  getEvalType() {
    return this._getType()[EVAL_TYPE_FIELD];
  }

  isVirtualEvaluation() {
    return this.getEvalType() === BehaviourTypeEnum.VirtualEvaluation;
  }

  getEvaluator() {
    return this._getForm()[OBSERVER_FIELD];
  }

  getEvaluatedDate() {
    return this._getForm()[EVALUATED_DATE_FIELD];
  }

  _getProgress() {
    return this.get(PROGRESS_FIELD);
  }

  getEvaluationStatus() {
    return this._getProgress()[STATUS_FIELD];
  }

  getAttempt() {
    return this._getProgress()[ATTEMPT_FIELD];
  }

  getEvaluationScore() {
    return this._getProgress()[SCORE_FIELD];
  }

  getLastFailedFormId() {
    const id = this._getProgress()[LAST_FAILED_FORM_ID_FIELD];

    return id != null ? id : null;
  }

  getObservations() {
    return this._getForm()[OBSERVATIONS_FIELD];
  }

  getMedia() {
    return this._getForm()[MEDIA_FIELD];
  }

  getMediaId() {
    return (this.getMedia() || {})[MEDIA_ID_FIELD];
  }

  setMedia(media) {
    this._getForm()[MEDIA_FIELD] = media;
  }

  getDepartment() {
    return this._getForm()[DEPARTMENT_FIELD];
  }

  getIdPrivacyEnabled() {
    return this._getForm()[PRIVACY_FIELD];
  }
}

module.exports = EvaluationForm;
