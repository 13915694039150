const _ = require('underscore');
const Backbone = require('Backbone');

const AuctionItem = require('@common/data/models/AuctionItem');

class AuctionItemList extends Backbone.Collection {
  get model() {
    return AuctionItem;
  }

  apiEndpoint() {
    return '/rewards/auctions';
  }

  parse(response) {
    return response.items;
  }

  comparator(model) {
    return model.get('rewardProgramPrize').nextEndMillis;
  }
}

class AuctionBuyNowList extends AuctionItemList {
  apiEndpoint() {
    return '/rewards/buyables';
  }

  comparator(model) {
    return model.get('nextEndMillis');
  }
}

class AuctionSlotsList extends AuctionItemList {
  apiEndpoint() {
    return '/rewards/slots/prizes';
  }

  comparator(model) {
    return model.get('prize').nextEndMillis;
  }

  spinReels(options = {}) {
    return $.ajax(_.extend(options, {
      type: 'POST',
      apiEndpoint: '/rewards/slots/spins',
      showSpinner: false
    }));
  }

  getSpinsCount(options) {
    return $.ajax(_.extend(options, {
      type: 'GET',
      apiEndpoint: '/rewards/slots/spinCount'
    }));
  }
}

module.exports = {AuctionItemList, AuctionBuyNowList, AuctionSlotsList};
