const TimeLogType = (require('@common/data/enums/TimeLogType')).training;

module.exports = {
  Session: {
    startEventType: TimeLogType.Session.Start,
    type: TimeLogType.Session.End
  },
  Assessment: {
    startEventType: TimeLogType.Assessment.Start,
    type: TimeLogType.Assessment.End
  },
  Game: {
    startEventType: TimeLogType.Game.Start,
    type: TimeLogType.Game.End
  },
  DeepLinkTraining: {
    type: TimeLogType.DeepLinkTraining
  },
  ClickCommunicationsNudgehyperlink: {
    type: TimeLogType.ClickCommunicationsNudgehyperlink
  },
  Question: {
    type: TimeLogType.Question,
    logOnlyWhenComplete: true
  },
  SurveyQuestion: {
    type: TimeLogType.SurveyQuestion,
    logOnlyWhenComplete: true
  },
  TimeSpent: {
    type: TimeLogType.TimeSpent
  },
  TrainingModule: {
    type: TimeLogType.TrainingModule,
    logOnlyWhenComplete: true
  },
  TimelineUpdateIsUpdate: {
    type: TimeLogType.TimelineUpdateIsUpdate
  },
  PageView: {
    Achievements: {
      type: TimeLogType.PageView.Achievements
    },
    AutomaticCertificationTraining: {
      type: TimeLogType.PageView.AutomaticCertificationTraining
    },
    AutomaticIntroductoryTraining: {
      type: TimeLogType.PageView.AutomaticIntroductoryTraining
    },
    AutomaticRefresherTraining: {
      type: TimeLogType.PageView.AutomaticRefresherTraining
    },
    HubRefresherTraining: {
      type: TimeLogType.PageView.HubRefresherTraining
    },
    AutomaticFormalExamTraining: {
      type: TimeLogType.PageView.AutomaticFormalExamTraining
    },
    AutomaticTellAFriend: {
      type: TimeLogType.PageView.AutomaticTellAFriend
    },
    BroadcastMessage: {
      type: TimeLogType.PageView.BroadcastMessage
    },
    ExtraTraining: {
      type: TimeLogType.PageView.ExtraTraining
    },
    Training: {
      type: TimeLogType.PageView.Training
    },
    GameScores: {
      type: TimeLogType.PageView.GameScores
    },
    HubCertificationTraining: {
      type: TimeLogType.PageView.HubCertificationTraining
    },
    HubIntroductoryTraining: {
      type: TimeLogType.PageView.HubIntroductoryTraining
    },
    HubFormalExamTraining: {
      type: TimeLogType.PageView.HubFormalExamTraining
    },
    Feed: {
      type: TimeLogType.PageView.Feed
    },
    FeedMessages: {
      type: TimeLogType.PageView.FeedMessages
    },
    ReportCard: {
      type: TimeLogType.PageView.ReportCard
    },
    Rewards: {
      type: TimeLogType.PageView.Rewards
    },
    SuggestQuestion: {
      type: TimeLogType.PageView.SuggestQuestion
    },
    TellAFriend: {
      type: TimeLogType.PageView.TellAFriend
    },
    TopPerformers: {
      type: TimeLogType.PageView.TopPerformers
    },
    UserMessage: {
      type: TimeLogType.PageView.UserMessage
    },
    LearningEvents: {
      type: TimeLogType.PageView.LearningEvents
    },
    GuidedLearningProgramsPage: {
      type: TimeLogType.PageView.GuidedLearningProgramsPage
    },
    GuidedLearningProgramDetailsPage: {
      type: TimeLogType.PageView.GuidedLearningProgramDetailsPage
    },
    TaskSummaryPage: {
      type: TimeLogType.PageView.TaskSummaryPage
    },
    TaskDetailsPage: {
      type: TimeLogType.PageView.TaskDetailsPage
    },
    Profile: {
      type: TimeLogType.PageView.Profile
    },
    LanguageSelection: {
      type: TimeLogType.PageView.LanguageSelection
    },
    CoachSelection: {
      type: TimeLogType.PageView.CoachSelection
    },
    PreTrainingHub: {
      type: TimeLogType.PageView.PreTrainingHub
    },
    GameSelection: {
      type: TimeLogType.PageView.GameSelection
    },
    GameDetails: {
      type: TimeLogType.PageView.GameDetails
    },
    GameChallengeSelection: {
      type: TimeLogType.PageView.GameChallengeSelection
    },
    GameChallengeDetails: {
      type: TimeLogType.PageView.GameChallengeDetails
    },
    PostTrainingHub: {
      type: TimeLogType.PageView.PostTrainingHub
    },
    TopicDetailsPage: {
      type: TimeLogType.PageView.TopicDetailsPage
    },
    Timeline: {
      main: {
        type: TimeLogType.PageView.Timeline.main
      },
      priority: {
        type: TimeLogType.PageView.Timeline.priority
      },
      all: {
        type: TimeLogType.PageView.Timeline.all
      }
    },
    TimelineToBroadcasts: {
      type: TimeLogType.PageView.TimelineToBroadcasts
    },
    Discovery: {
      type: TimeLogType.PageView.Discovery
    },
    DiscoveryArticlesTab: {
      type: TimeLogType.PageView.DiscoveryArticlesTab
    },
    DiscoveryTopicsTab: {
      type: TimeLogType.PageView.DiscoveryTopicsTab
    },
    DiscoveryPathsTab: {
      type: TimeLogType.PageView.DiscoveryPathsTab
    },
    DiscoveryCommunity: {
      type: TimeLogType.PageView.DiscoveryCommunity
    },
    ArticleDetailsPage: {
      type: TimeLogType.PageView.ArticleDetailsPage
    },
    TaskListView: {
      type: TimeLogType.PageView.TaskListView
    },
    GroupSelectionView: {
      type: TimeLogType.PageView.GroupSelectionView
    },
    TaskCompletion: {
      type: TimeLogType.PageView.TaskCompletion
    },
    TaskDetails: {
      type: TimeLogType.PageView.TaskDetails
    },
    NudgeDetails: {
      type: TimeLogType.PageView.NudgeDetails
    },
    TaskCreation: {
      type: TimeLogType.PageView.TaskCreation
    },
    CompletedNudges: {
      type: TimeLogType.PageView.CompletedNudges
    },
    // PathDetailsPage: {
    //   type: TimeLogType.PageView.PathDetailsPage
    // }
    ConversationalSearch: {
      type: TimeLogType.PageView.ConversationalSearch
    },
    RecognitionPinNotifications: {
      type: TimeLogType.PageView.RecognitionPinNotifications
    },
    RecognitionPinSendModal: {
      type: TimeLogType.PageView.RecognitionPinSendModal
    },
    RecognitionPinPinHistory: {
      type: TimeLogType.PageView.RecognitionPinPinHistory
    },
    RecognitionPinSummaryList: {
      type: TimeLogType.PageView.RecognitionPinSummaryList
    }
  }
};
