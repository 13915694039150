// Module
var code = `<% if (linkColour) { %>
  [dir] .ax-radio input[type="radio"]:checked + label:before {
    border-color: <%- linkColour %>;
  }

  [dir] .ax-radio input[type="radio"]:checked + label:after,
  [dir] .ax-checkbox input[type="checkbox"]:checked + label:before,
  [dir] .ax-checkbox--partial input[type="checkbox"] + label:before,
  [dir] .ax-toggle input[type=checkbox]:checked + label:before,
  [dir] .ax-toggle input[type=checkbox]:checked:disabled + label:before {
    background-color: <%- linkColour %>;
  }

  [dir] .ax-button--ghost-link-branded {
    color: <%- linkColour %>;
  }

  [dir] .ax-button--ghost-link-branded:hover,
  [dir] .ax-button--ghost-link-branded.pressed {
    border-color: <%- linkColour %>;
  }

  :root {
    --ax-link-color: <%- linkColour %>
  }
<% } %>
`;
// Exports
module.exports = code;