const { once } = require('underscore');
const { LayoutView } = require('Marionette');

const I18n = require('@common/libs/I18n');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

const {
  BADGE_MODIFIERS,
  TitleHeaderTypeEnum
} = require('@common/components/titleHeader/TitleHeader');
const ProgressBar = require('@common/components/progress/progressBar/ProgressBar');

const { createButtonWithTextAndIcon } = require('@common/components/actionBarButton/ActionBarButtonFactory');

const trainingIconFormatter = require('@common/data/enums/TrainingSessionTypeIconFormatter');
const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');

const GuidedLearningSubtextHeaderTitle = require('@common/components/guidedLearning/GuidedLearningSubtextHeaderTitle');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const { getMetaDataViewDefinition } = require('@common/components/cardMetadata/MetadataControllerDefinitionFactory');
const { getLowerTitleDefinitionForSessionType } = require('@common/components/todaysActivities/TodaysActivitiesCardHelper');
const TodaysActivitiesButtonStringProvider = require('@common/components/todaysActivities/TodaysActivitiesButtonStringProvider');

require('@common/components/todaysActivities/TodaysActivities.less');

const lowerActivityTileTemplate = `
  <div>
    <div class="lowerTitleRegion"></div>
    <div class="metaRegion base-card__meta"></div>
  </div>
  <button class="axon-button btn btn-primary qa-todays-activities-card-button" aria-label="<%- btnAriaLabel %>" type="button"><%= btnText %></button>
`;

const sessionTypeBadgeColorMap = {
  [SessionTrainingType.DailyTraining]: BADGE_MODIFIERS.REINFORCEMENT,
  [SessionTrainingType.CertificationTraining]: BADGE_MODIFIERS.CERTIFICATION,
  [SessionTrainingType.IntroductoryTraining]: BADGE_MODIFIERS.INTRO,
  [SessionTrainingType.GuidedLearningTraining]: BADGE_MODIFIERS.GUIDED_LEARNING,
  [SessionTrainingType.RefresherTraining]: BADGE_MODIFIERS.REFRESHER,
  [SessionTrainingType.FormalExamTraining]: BADGE_MODIFIERS.EXAM
};

const _getBadgeColourModifier = (sessionType) => {
  return sessionTypeBadgeColorMap[sessionType];
};

const _getTrainingItemsAvailable = (sessionType, currentAssessmentOptionsModel) => {
  if (sessionType === SessionTrainingType.GuidedLearningTraining && currentAssessmentOptionsModel.get('program') != null) {
    return currentAssessmentOptionsModel.get('program').count;
  }
  return currentAssessmentOptionsModel.get('itemsAvailable');

};

// Required b/c icon formatter returns object keys that the TitleHeaderBuilder is not expecting
// i.e. obj.iconLabel and obj.title represent the same thing
const _generateTitleBuilderObjFromFormatter = (formattedObj, sessionType, assessmentOption) => {
  const titleOptions = {
    iconClass: formattedObj.iconClass,
    badgeModifierClasses: [BADGE_MODIFIERS.LARGE, _getBadgeColourModifier(sessionType)]
  };

  // Get definition that includes ProgressBar for GuidedLearning type main title
  if (sessionType === SessionTrainingType.GuidedLearningTraining) {
    Object.assign(titleOptions, {
      titleControllerDefinition: _getGuidedLearningTitleControllerDefinition(assessmentOption.program)
    });
  } else {
    Object.assign(titleOptions, {
      title: formattedObj.iconLabel
    });

    const itemsAvailable = assessmentOption.itemsAvailable;

    if (itemsAvailable > 1) {
      Object.assign(titleOptions, {
        subTitle: I18n.t('hub.activitiesCard.numAvailable', { number: itemsAvailable }),
        titleType: TitleHeaderTypeEnum.SUBTEXT_TITLE
      });
    }
  }

  return { titleOptions };
};

const _getGuidedLearningTitleControllerDefinition = (program) => {
  let progressBarDefinition = {};
  let itemsMessage = '';
  if (program != null && program.completedCount != null && program.count != null && program.progressToCompletion != null) {
    progressBarDefinition = {
      ViewClass: ProgressBar,
      value: program.progressToCompletion,
      animate: true
    };

    itemsMessage = I18n.t('hub.activitiesCard.itemsCompleted', {
      number: I18n.t('GuidedLearning.totalVersusAvailable', {
        completed: program.completedCount,
        available: program.count
      })
    });
  }

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: GuidedLearningSubtextHeaderTitle,
      title: I18n.t('GuidedLearning.title'),
      subtext: program ? program.name : '',
      itemsMessage
    },
    regionControllers: {
      progressBarRegion: {
        viewDefinition: progressBarDefinition
      }
    }
  };
};

const _getLowerActivityTileViewDefinition = (buttonConfig, startTrainingCallback) => {
  return {
    ViewClass: LayoutView,
    className: 'todays-activities__lower--region',
    template: lowerActivityTileTemplate,
    templateHelpers: {
      btnAriaLabel: buttonConfig.ariaLabel,
      btnText: buttonConfig.text
    },
    regions: {
      metaRegion: '.metaRegion',
      lowerTitleRegion: '.lowerTitleRegion'
    },
    events: {
      'click button': once(startTrainingCallback)
    }
  };
};

const _getActivityTileButtonConfig = (sessionType, nextStartableItem) => {
  const buttonStr = TodaysActivitiesButtonStringProvider.getString(sessionType, nextStartableItem);
  return createButtonWithTextAndIcon(buttonStr, 'icon-play');
};

const _generateViewAllLinkConfigs = (sessionType, currentAssessmentOptionModel, viewAllCallback, assessmentOption) => {
  const hasViewAllLink = _getTrainingItemsAvailable(sessionType, currentAssessmentOptionModel) > 1;
  const isGuidedLearning = sessionType === SessionTrainingType.GuidedLearningTraining;

  if (hasViewAllLink && !(isGuidedLearning && assessmentOption.itemsCompleted === 0)) {
    const linkOptions = {
      linkAriaLabel: I18n.t('hub.activitiesCard.viewAllTrainingItems')
    }

    const configs = {
      hasLink: true,
      linkOptions
    };

    if (isGuidedLearning) {
      configs.linkOptions.callback = _generateGuidedLearningViewAllCallback(currentAssessmentOptionModel, viewAllCallback);
    } else {
      configs.linkOptions.callback = viewAllCallback;
    }

    if (!currentAssessmentOptionModel.has('nextItem')) {
      configs.linkOptions.target = 'fullCard';
    }

    return configs;
  }

  return undefined;
};

const _generateGuidedLearningViewAllCallback = (currentAssessmentOptionModel, viewAllCallback) => {
  const programId = currentAssessmentOptionModel.get('program').id;
  return viewAllCallback.bind({}, {
    pageId: GuidedLearningPageEnum.DetailsPage,
    pageOptions: {
      programId
    }
  });
};

const _generateBottomWrapperConfigs = (sessionType, currentAssessmentOptionModel, startTrainingCallback, assessmentOption, viewAllCallback) => {
  let result;

  if (sessionType === SessionTrainingType.GuidedLearningTraining && assessmentOption.itemsCompleted === 0) {
    result = _generateBottomNewGuidedLearningConfigs(currentAssessmentOptionModel, viewAllCallback);
  } else {
    result = _generateBottomNextItemConfigs(sessionType, currentAssessmentOptionModel, startTrainingCallback);
  }
  return result;
};

const _generateBottomNewGuidedLearningConfigs = (currentAssessmentOptionModel, viewAllCallback) => {
  const buttonStr = I18n.t('hub.viewPath');
  const buttonConfig = createButtonWithTextAndIcon(buttonStr, 'hidden');
  const callback = _generateGuidedLearningViewAllCallback(currentAssessmentOptionModel, viewAllCallback);
  buttonConfig.ariaLabel = I18n.t('hub.viewPathAriaLabel', {programName: currentAssessmentOptionModel.get('program')?.name}) || buttonStr;

  return {
    bottomWrapperControllerDefinition: {
      ViewControllerClass: LayoutController,
      viewDefinition: _getLowerActivityTileViewDefinition(buttonConfig, callback),
      regionControllers: {
        metaRegion: {
          viewDefinition: getMetaDataViewDefinition(SessionTrainingType.GuidedLearningTraining, currentAssessmentOptionModel.toJSON())
        }
      }
    }
  };
};

const _generateBottomNextItemConfigs = (sessionType, currentAssessmentOptionModel, startTrainingCallback) => {
  const nextStartableItem = currentAssessmentOptionModel.get('nextItem');

  if (nextStartableItem != null) {
    nextStartableItem.setLaunchContext(AssessmentLaunchContext.START_PAGE);

    const nextStartableItemData = nextStartableItem.toJSON();
    const buttonConfig = _getActivityTileButtonConfig(sessionType, nextStartableItemData);

    if (nextStartableItemData.inProgressAssessmentGetter() != null) {
      buttonConfig.ariaLabel = I18n.t('assessments.item.action.resumeAriaLabel', {programName: currentAssessmentOptionModel.get('program')?.name});
    }

    return {
      bottomWrapperControllerDefinition: {
        ViewControllerClass: LayoutController,
        viewDefinition: _getLowerActivityTileViewDefinition(buttonConfig, startTrainingCallback.bind({}, nextStartableItem), sessionType),
        regionControllers: {
          metaRegion: {
            viewDefinition: getMetaDataViewDefinition(sessionType, currentAssessmentOptionModel.toJSON())
          },
          lowerTitleRegion: getLowerTitleDefinitionForSessionType(sessionType, nextStartableItemData)
        }
      }
    };
  }

  return undefined;
};

const getTodaysActivitiesCardControllerDefinition = (activityData = {}, cardOptions = {}) => {
  const {
    sessionType,
    currentAssessmentOptionModel,
    complete: viewAllCallback,
    startTrainingCallback
  } = activityData;

  const defaultOptions = {
    cardClass: 'todays-activities__card'
  };

  const assessmentOption = currentAssessmentOptionModel.toJSON();

  // Get title and icon config based on session type
  Object.assign(defaultOptions, _generateTitleBuilderObjFromFormatter(trainingIconFormatter(sessionType), sessionType, assessmentOption));

  // generate the view all text link configs
  Object.assign(defaultOptions, _generateViewAllLinkConfigs(sessionType, currentAssessmentOptionModel, viewAllCallback, assessmentOption));

  // generate the bottom regions next item config
  Object.assign(defaultOptions, _generateBottomWrapperConfigs(sessionType, currentAssessmentOptionModel, startTrainingCallback, assessmentOption, viewAllCallback));

  return getBaseCardControllerDefinition(Object.assign(defaultOptions, cardOptions));
};

module.exports = {
  getTodaysActivitiesCardControllerDefinition
};
