const { MessageItemView } = require('@training/apps/training/views/MessageItemView');

class CardMessageItemView extends MessageItemView {
  tagName() {
    return 'li';
  }

  getTemplate() {
    return require('@training/apps/training/views/CardMessageItemView.html');
  }

  templateHelpers() {
    const helpers = super.templateHelpers(...arguments);
    delete helpers.message;
    helpers.timeSince = this.getTimeSince(false);

    return helpers;
  }

  events() {
    return {};
  }
}

module.exports = CardMessageItemView;
