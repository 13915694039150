const {
  omit,
  extend
} = require('underscore');

const I18n = require('@common/libs/I18n');

const AxonifyApps = require('@common/data/enums/AxonifyApps').default;

const appMenuConfigMap = {
  [AxonifyApps.ADMIN]: {
    configId: 'app-item-admin',
    iconClass: 'icon-admin_zone icon-qa-administration',
    textKey: 'menu.apps.admin',
    appColorClass: 'admin',
    isAppItem: true,
    hideMsTeams: true,
    href: '/admin'
  },
  [AxonifyApps.TRAINING]: {
    configId: 'app-item-training',
    iconClass: 'icon-learnerzone icon-qa-home',
    textKey: 'menu.apps.training',
    appColorClass: 'training',
    isAppItem: true,
    msTeamsHideTitle: true,
    href: '/training'
  },
  [AxonifyApps.MANAGER]: {
    configId: 'app-item-manager',
    iconClass: 'icon-leaderzone icon-dashboard-qa-reports',
    textKey: 'menu.apps.teamLink',
    appColorClass: 'manager',
    isAppItem: true,
    msTeamsHideTitle: true,
    href: '/teamlink'
  }
};

module.exports = {
  getMenuAppItemData: (app) => {
    const config = appMenuConfigMap[AxonifyApps.assertLegalValue(app)];
    return omit( extend( { text: I18n.t(config.textKey) }, config ), 'textKey' );
  }
};
