const Backbone = require('Backbone');
const _ = require('underscore');
const File = require('@common/data/models/media/File');
const VideoFileHelpers = require('@common/libs/helpers/app/VideoFileHelpers');

class VideoFile extends File {

  constructor(attrs, options) {
    super(attrs, options);
    this.save = this.save.bind(this);
  }

  initialize() {
    this.videoMediaModel = new VideoMedia();
  }

  parse(res) {
    return res;
  }

  save(key, value, options = {}) {
    let attrs = {};
    if ((key === null) || (typeof key === 'object')) {
      attrs = key;
      // eslint-disable-next-line no-param-reassign
      options = value || {};
    } else {
      attrs[key] = value;
    }

    if (!options.mediaOptions) {
      options.mediaOptions = {};
    }

    options.allowedExtensions = VideoFileHelpers.allowedVideoExtensions;
    options.sizeLimit = 2000;
    options.success = _.wrap(options.success, (onSuccess = $.noop, response) => {
      this.createMedia(response, options.mediaOptions).then(
        () => {
          onSuccess(this);
        },
        () => {
          if (_.isFunction(options.error)) {
            options.error(this, response, options);
          }
        }
      );
    });

    return super.save(attrs, options);
  }

  createMedia(data, mediaOptions) {
    const deferred = $.Deferred();

    if (data == null) {
      deferred.reject();
    }

    this.videoMediaModel.set('originalFile', data);

    if (mediaOptions.language != null) {
      this.videoMediaModel.set('language', mediaOptions.language);
    }

    if (this.videoMediaModel.isNew()) {
      this.videoMediaModel.save().then(
        deferred.resolve,
        deferred.reject
      );
    } else {
      deferred.resolve();
    }

    return deferred.promise();
  }

  getMedia() {
    return this.videoMediaModel.toJSON();
  }
}

/**
 * This video media is deprecated, You should be using the one in libs/file/VideoMedia instead
 */
class VideoMedia extends Backbone.Model {
  get idAttribute() {
    return 'uuid';
  }

  constructor(attrs, options) {
    super(attrs, options);
    this.requestClosedCaptions = this.requestClosedCaptions.bind(this);
  }

  url() {
    return '/axonify/media/video';
  }

  requestClosedCaptions(language, serviceType) {
    const options = {
      language,
      method: serviceType
    };
    const def = $.ajax({
      type: 'POST',
      url: `${ this.url }/${ this.id }/cc`,
      data: JSON.stringify(options)
    });
    return def;
  }

  hasTranscriptionForLanguage(language) {
    const captions = this.get('closedCaptions');
    return _.some(captions, (caption) => {
      return caption.language === language;
    });
  }

  toJSON(...args) {
    const json = super.toJSON(...args);

    if (json.originalFile && _.isFunction(json.originalFile.toJSON)) {
      json.originalFile = json.originalFile.toJSON();
    }

    return json;
  }
}

module.exports = {VideoFile, VideoMedia};
