const logging = require('logging');
const $os = require('detectOS');

const _ = require('underscore');
const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const {
  AuctionItemList,
  AuctionBuyNowList,
  AuctionSlotsList
} = require('../collections/AuctionItemList');
const RaffleList = require('../collections/RaffleList');
const AuctionBidView = require('./AuctionBidView');
const AuctionBuyNowView = require('./AuctionBuyNowView');
const AuctionRaffleView = require('./AuctionRaffleView');
const AuctionSlotsView = require('./AuctionSlotsView');

class RewardsPage extends UIKit.View {

  static TAB_NAME = {
    AUCTION: 'auction',
    SHOP: 'shop',
    RAFFLE: 'raffle',
    SPIN_TO_WIN: 'spinToWin'
  }

  get template() {
    return _.tpl(require('../templates/RewardsPage.html'));
  }

  initialize(options = {}) {
    ({
      initialTab: this.initialTab = RewardsPage.TAB_NAME.AUCTION
    } = options);

    // Load the basic template with localization support
    this.$el.html(this.template());

    this.removeBidInProgress = {};

    this.availableTabs = {};

    this.tabHashes = {
      [RewardsPage.TAB_NAME.AUCTION]: '#hub/rewards/auction',
      [RewardsPage.TAB_NAME.SHOP]: '#hub/rewards/shop',
      [RewardsPage.TAB_NAME.RAFFLE]: '#hub/rewards/raffle',
      [RewardsPage.TAB_NAME.SPIN_TO_WIN]: '#hub/rewards/spinToWin'
    };

    this.auctionItemList = new AuctionItemList();
    this.auctionBuyNowItemList = new AuctionBuyNowList();
    this.raffleList = new RaffleList();
    this.auctionSlotsList = new AuctionSlotsList();

    this.prizeListsLoaded = Promise.all([
      this.auctionItemList.fetch(),
      this.auctionBuyNowItemList.fetch(),
      this.raffleList.fetch(),
      this.auctionSlotsList.fetch()
    ]);

    this.listenTo(this.auctionItemList, 'sync', this.setAvailablePoints);
  }

  onRender() {
    this.trigger('force:icon:reflow');
  }

  configureAvailableTabs() {
    this.availableTabs[RewardsPage.TAB_NAME.AUCTION] = (this.auctionItemList.length !== 0);
    this.availableTabs[RewardsPage.TAB_NAME.SHOP] = (this.auctionBuyNowItemList.length !== 0);
    this.availableTabs[RewardsPage.TAB_NAME.RAFFLE] = (this.raffleList.length !== 0);
    this.availableTabs[RewardsPage.TAB_NAME.SPIN_TO_WIN] = (this.auctionSlotsList.length !== 0);
  }

  removeTabs() {
    _.each(this.availableTabs, (isVisible, tabName) => {
      if (!isVisible) {
        this.$(`li[data-tab='${ tabName }']`).remove();
      }
    });

    // Tabs
    this.tabs = new UIKit.TabBar({el: this.$('.subtabs')});
    this.tabs.on('change:tabs', this.onChangeTabs.bind(this));

    // Scenario where cron may still be updating previous purchases
    // even if there is items it will appear (until cron finishes) that there are 0 items
    if (this.tabs.length === 0) {
      this.$('.no-rewards').html(I18n.t('auction.noRewards'));
    }

    // Want to hide the tabs until its been determined which will be available
    if ((this.tabs.length > 1) || !$os.mobile) {
      this.tabs.$el.removeClass('hide');
    }

    if ($os.mobile && (this.tabs.length === 2)) {
      this.tabs.$('li').addClass('two-tabs');
    }
  }

  clickDefaultTab() {
    let tabName = this.initialTab;

    if (!this.availableTabs[this.initialTab]) {
      tabName = this.tabs.tabNames[0];
    }

    if (tabName != null) {
      this.tabs.clickTab(tabName);
    }
  }

  hidePointBalanceHeader() {
    this.$('.highlights.points-balance').hide();
  }

  viewDidAppear() {
    this.setAvailablePoints(this.auctionItemList);

    logging.info('RewardsPage - viewDidAppear');
    this.setPageHeader('training.menu.auctions');

    this.prizeListsLoaded.then(() => {
      if (this.isDestroyed) {
        return;
      }
      logging.info('Auction items returned');
      this.configureAvailableTabs();
      this.removeTabs();
      this.render();
      this.clickDefaultTab();
    });
  }

  setAvailablePoints(list) {
    const escrowPoints = list.reduce((memo, auctionItem) => {
      const bid = auctionItem.get('bid');
      return bid ? memo + bid.points : memo;
    }, 0);

    window.apps.auth.session.user.setEscrowPoints(escrowPoints);

    return this.$('.points-balance .score').text(I18n.numberWithCommas(apps.auth.session.user.availablePoints()));
  }

  onChangeTabs(tabName) {
    this.$('.highlights.points-balance').show();
    this.setPageHeader(`auction.${ tabName }`);

    Backbone.history.navigate(this.tabHashes[tabName], {
      trigger: false,
      replace: true
    });

    if (tabName === 'spinToWin') {
      this.hidePointBalanceHeader();
    } else {
      this.setAvailablePoints(this.auctionItemList);
    }

    let v = this.tabs[tabName];
    if (!v) {
      switch (tabName) {
        case 'auction':
          v = new AuctionBidView({
            el: this.$(`.contentarea[data-tab='${ tabName }']`),
            collection: this.auctionItemList,
            super: this
          });
          break;
        case 'shop':
          v = new AuctionBuyNowView({
            el: this.$(`.contentarea[data-tab='${ tabName }']`),
            collection: this.auctionBuyNowItemList,
            super: this
          });
          break;
        case 'raffle':
          v = new AuctionRaffleView({
            el: this.$(`.contentarea[data-tab='${ tabName }']`),
            collection: this.raffleList
          });
          break;
        case 'spinToWin':
          this.hidePointBalanceHeader();
          v = new AuctionSlotsView({
            el: this.$(`.contentarea[data-tab='${ tabName }']`),
            collection: this.auctionSlotsList,
            super: this
          });
          break;
        default:
          break;
      }

      this.tabs[tabName] = v;
    }

    if (v && (tabName === 'spinToWin')) {
      //Update the spin count on tab navigation
      const slotMachine = {
        costPerSpin: TenantPropertyProvider.get().getProperty('pointCostPerSlotSpin'),
        pointsBalance: window.apps.auth.session.user.availablePoints(),
        maxSpins: TenantPropertyProvider.get().getProperty('maxSlotSpinsPerSession'),
        spinCount: 0
      };

      v.getSpinCount(slotMachine);
    }

    this.$('.contentarea').hide();
    v.$el.fadeIn('fast');
    //defer rendering the view until we've started the fade-in so we know the element is in the DOM.
    if (tabName !== 'spinToWin') {
      v.render();
    }

    return this.triggerAdjustment();
  }

  setPageHeader(key) {
    const title = I18n.t(key);
    this.$('.js-title').html(title);
    return window.app.layout.setTitle(title);
  }

  onClose(...args) {
    if (this.tabs != null) {
      this.tabs.unload();

      if (this.tabs['auction']) {
        this.tabs['auction'].unload();
      }
      if (this.tabs['shop']) {
        this.tabs['shop'].unload();
      }
      if (this.tabs['raffle']) {
        this.tabs['raffle'].unload();
      }
      if (this.tabs['spinToWin']) {
        this.tabs['spinToWin'].unload();
      }
    }

    this.auctionItemList.abortXHR(Sync.Method.READ);
    this.auctionBuyNowItemList.abortXHR(Sync.Method.READ);
    this.raffleList.abortXHR(Sync.Method.READ);
    this.auctionSlotsList.abortXHR(Sync.Method.READ);

    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }

    super.onClose(...args);
  }
}

module.exports = RewardsPage;
