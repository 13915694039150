const AxonifyExceptionCode = require('AxonifyExceptionCode');
const { AxonifyException } = require('@common/services/error/AxonifyException');

class AxonifyExceptionFactory {
  static fromResponse(response) {
    let responseJson = response?.responseJSON;

    // Check to see if this is the GCP issue where 500 status is returned to browser
    // without the request ever reaching Axonify's app server
    // Let's check if it contains 'Reference&#32' string
    if (response?.responseText?.indexOf('Reference&#32;&#35;') >= 0) {
      responseJson = {
        errCode: AxonifyExceptionCode.GCP_REFERENCE_ERROR,
        errMessage: `GCP Reference Error - ${ response?.responseText }`
      };
    } else if (responseJson == null) {
      try {
        responseJson = JSON.parse(response.responseText);
      } catch (error) {
        responseJson = {
          errCode: AxonifyExceptionCode.PARSE_ERROR_CODE,
          errMessage: `Non parsable response - ${ error }`
        };
      }
    }

    return new AxonifyException(responseJson);
  }
}

module.exports = AxonifyExceptionFactory;
