// Module
var code = `<div class="adjust-photo-preview ax-grid__col--auto-size">
  <label class="adjust-photo-title"><%- t('photoCrop.preview.title') %></label>
  <div class="adjust-photo-subTitle"><%- t('photoCrop.preview.subTitle') %></div>
  <div class="preview-wrap <% if (isImageRequired) { %> hidden <% } %>">
    <% if (showLargeThumbnail) { %>
      <div class="thumbnail-preview">
        <img src="" alt="<%- t('photoCrop.preview.largeThumbnailLabel') %>"/>
      </div>
    <% } %>

    <div class="small-previews-wrap clearfix">
      <% if (showMediumThumbnail) { %>
        <div class="thumbnail-preview-size-1 thumbnail-preview-size floatleft">
          <img src="" alt="<%- t('photoCrop.preview.mediumThumbnailLabel') %>"/>
        </div>
      <% } %>
      <% if (showSmallThumbnail) { %>
        <div class="thumbnail-preview-size-2 thumbnail-preview-size floatleft">
          <img src="" alt="<%- t('photoCrop.preview.smallThumbnailLabel') %>" />
        </div>
      <% } %>
    </div>
  </div>
  <p class="adjust-photo-preview--no-image <% if (!isImageRequired) { %> hidden <% } %>">
    <%- t('photoCrop.preview.noImage') %>
  </p>
</div>
`;
// Exports
module.exports = code;