const { Model } = require('Backbone');

const Enum = require('@common/data/enums/Enum');

const DataKeys = Enum({
  SCROLL_BOTTOM: 'scrollBottom',
  SCROLL_TOP: 'scrollTop',
  SCROLL_LEFT: 'scrollLeft',
  SCROLL_RIGHT: 'scrollRight',
  CONTAINER_HEIGHT: 'containerHeight',
  CONTAINER_WIDTH: 'containerWidth',
  CONTENT_HEIGHT: 'contentHeight',
  CONTENT_WIDTH: 'contentWidth'
});

const StructuralDataKeys = [
  DataKeys.CONTENT_HEIGHT,
  DataKeys.CONTENT_WIDTH,
  DataKeys.CONTAINER_HEIGHT,
  DataKeys.CONTAINER_WIDTH
];

const ScrollDataKeys = [
  DataKeys.SCROLL_BOTTOM,
  DataKeys.SCROLL_TOP,
  DataKeys.SCROLL_LEFT,
  DataKeys.SCROLL_RIGHT
];

class ScrollDataModel extends Model {

  static Keys = DataKeys;

  static StructuralKeys = StructuralDataKeys;

  static ScrollKeys = ScrollDataKeys;

  defaults() {
    return {
      [DataKeys.SCROLL_BOTTOM]: 0,
      [DataKeys.SCROLL_TOP]: 0,
      [DataKeys.SCROLL_LEFT]: 0,
      [DataKeys.SCROLL_RIGHT]: 0,
      [DataKeys.CONTAINER_HEIGHT]: 0,
      [DataKeys.CONTAINER_WIDTH]: 0,
      [DataKeys.CONTENT_HEIGHT]: 0,
      [DataKeys.CONTENT_WIDTH]: 0
    };
  }

  hasEventListeners() {
    return Object.keys(this._events || {}).length !== 0;
  }
}

module.exports = ScrollDataModel;
