const Backbone = require('Backbone');
const Marionette = require('Marionette');

const PasswordComplexityParser = require('@common/modules/auth/common/PasswordComplexityParser');
const I18n = require('@common/libs/I18n');

const Form = require('@common/components/forms/Form');
const { NullValueSerializer } = require('@common/components/forms/editors/password/PasswordSerializer');
const PasswordRulesetView = require('@common/components/passwordRuleset/PasswordRulesetView');

require('@common/components/forms/editors/password/Form.Editor.Password');
require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');

class ResetPasswordPage extends Marionette.LayoutView {
  getTemplate() {
    return require('./ResetPasswordPage.html');
  }

  regions() {
    return {
      passwordRulesetRegion: '#js-password-ruleset'
    };
  }

  events() {
    return {
      'click .submit': 'onClickSubmit'
    };
  }

  initialize(options = {}) {
    ({
      tenantPropertyPool: this.tenantPropertyPool,
      flash: this.flash
    } = options);

    this.passwordModel = new Backbone.Model({
      newpassword: '',
      confirmpassword: ''
    });

    this.complexPassword = this.tenantPropertyPool.getProperty('complex');
    const complexityParser = new PasswordComplexityParser();
    this.complexityRules = complexityParser.parse(this._getComplexityString());
  }

  onRender() {
    this.form = new Form({
      el: this.$el,
      model: this.passwordModel,
      context: {
        passwordOptions: {
          serializer: new NullValueSerializer(),
          autocomplete: 'new-password'
        }
      }
    });

    const passwordRulesetView = new PasswordRulesetView({
      complexPassword: this.complexPassword,
      complexityRules: this.complexityRules
    });

    this.passwordRulesetRegion.show(passwordRulesetView);

  }

  onClickSubmit() {
    if (this._validateInputs()) {
      this.trigger('submit', this.passwordModel.get('newpassword'));
    }
  }

  _validateInputs() {
    const errors = [];
    const pwd1 = this.passwordModel.get('newpassword');
    const pwd2 = this.passwordModel.get('confirmpassword');

    const passwordValidationResult = this.complexityRules.validateAgainstPassword(pwd1);

    if (passwordValidationResult.isTooShort()) {
      errors.push(I18n.t('login.passwordReset.reset.invalidcomplexpasswordshort', {minPasswordLength: this.complexityRules.getMinLength()}));
    }

    if (passwordValidationResult.isWithoutEnoughGroups()) {
      errors.push(I18n.t('login.passwordReset.reset.invalidcomplexpasswordgroups', {minNumberOfCharacterGroups: this.complexityRules.getMinGroups()}));
    }

    if (pwd1 !== pwd2) {
      errors.push(I18n.t('login.passwordReset.reset.passwordmismatch'));
    }

    if (errors.length > 0) {
      this.flash.error({message: errors});
      return false;
    }
    return true;

  }

  _getComplexityString() {
    const complexFormat = this.tenantPropertyPool.getProperty('complexFormat');
    return (complexFormat === 'N/A' || complexFormat == null) ? PasswordComplexityParser.getStringForMinCharactersDefault() : complexFormat;
  }
}

module.exports = ResetPasswordPage;
