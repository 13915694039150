const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

class PageInsightsOverviewTileView extends ItemView {
  modelEvents() {
    return {
      change: 'render'
    };
  }

  getTemplate() {
    return require('@training/apps/insights/overview/PageInsightsOverviewTileView.html');
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      date: this.model.get('date'),
      leftIcon: 'icon-reach',
      rightIcon: 'icon-viewers',
      leftStatLabel: I18n.t('discover.insights.reach'),
      rightStatLabel: I18n.t('discover.insights.viewers'),
      leftStatValue: this.model.get('viewers') && this.model.get('reachPercentage')
        ? `${ this.model.get('reachPercentage') }%` : '--%',
      rightStatValue: this.model.get('viewers') || '-'
    };
  }
}

class PageInsightsOverviewTimelineTileView extends PageInsightsOverviewTileView {
  templateHelpers() {
    return Object.assign(super.templateHelpers(), {
      leftIcon: 'icon-frequenttraining',
      rightIcon: 'icon-content',
      leftStatLabel: I18n.t('discover.metadata.publishDate'),
      rightStatLabel: I18n.t('discover.insights.lastModified'),
      leftStatValue: this.model.get('publishDateString'),
      rightStatValue: this.model.get('lastModifyDateString')
    });
  }
}

module.exports = {
  PageInsightsOverviewTileView,
  PageInsightsOverviewTimelineTileView
};
