const { getEventCheckinCardControllerDefinition } = require('@training/apps/home/eventSelfCheckin/EventCheckinCardControllerDefinitionFactory');

module.exports = (eventCheckinModel) => {
  if (!eventCheckinModel.get('checkInAvailable')) {
    return null;
  }

  return () => {
    return getEventCheckinCardControllerDefinition();
  };
};
