// Module
var code = `<div class="ax-container">
  <div class="page-header__content" role="banner">
    <div class="page-title-region-wrapper ax-grid ax-grid--no-gutter ax-grid--align-items-center">
      <div class="page-title-left-region"></div>
      <div class="page-title-bail-region ax-grid__col--m-order-3 ax-grid__col--auto-size"></div>
      <div class="page-title-right-region ax-grid__col--12 ax-grid__col--m-4"></div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;