const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const {
  LayoutView
} = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

const { getPageSubmitButtonDefinition } = require('@training/apps/search/PageSubmitButtonConfigHelper');

class ArticleEditButtonBarController extends LayoutController {
  initialize(options = {}) {
    ({
      originatingCommunity: this.originatingCommunity = null,
      model: this.model,
      languageData: this.languageData,
      saveCallback: this.saveCallback = () => {},
      publishAllCallback: this.publishAllCallback = () => {}
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
    if (this.originatingCommunity) {
      this.originatingCommunityId = this.originatingCommunity.get('id');
    }
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'article-edit-button-bar ax-grid ax-grid--no-gutter',
      template: `
        <div class="qa-main-action js-main-action-region ax-grid__col--auto-size"></div>
        <div class="ax-grid__col--auto-size">
          <%= axButton({
            className: 'qa-page-edit-cancel-button js-page-edit-cancel-button',
            label: "${ I18n.t('general.cancel') }",
            ariaLabel: "${ I18n.t('general.cancel') }",
            brandedSecondary: true
          }) %>
        </div>
        <div class="qa-delete-action js-delete-action-region ax-grid__col--auto-size"></div>
        `,
      regions: {
        mainActionButtonRegion: '.js-main-action-region',
        deleteActionButtonRegion: '.js-delete-action-region'
      },
      events: {
        'click .js-page-edit-cancel-button': this.cancelClicked.bind(this)
      }
    };
  }

  regionControllers() {
    const controllers = {
      mainActionButtonRegion: getPageSubmitButtonDefinition(this.originatingCommunity, this.model, this.saveCallback, this.publishAllCallback)
    };
    return controllers;
  }

  renderButtonsForCommunity(community, page) {
    const buttonDefinition = getPageSubmitButtonDefinition(community, page, this.saveCallback, this.publishAllCallback);
    this.swapRegionController('mainActionButtonRegion', buttonDefinition);
  }

  cancelClicked() {
    if (this.model && this.model.get('id')) {
      Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ this.model.get('id') }`, {
        trigger: true
      });
    } else if (this.originatingCommunityId) {
      Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ this.originatingCommunityId }/`, {
        trigger: true
      });
    } else {
      Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/type-all/1/`, {
        trigger: true
      });
    }
  }
}

module.exports = ArticleEditButtonBarController;
