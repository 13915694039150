const Marionette = require('Marionette');

class PrintFormButtonView extends Marionette.ItemView {

  className() {
    return 'evaluation-print-form';
  }

  getTemplate() {
    return `\
      <button type="button" class="ax-button ax-button--icon-left print-button">
        <span class="icon-print"></span>
        <span><%- t('general.printForm') %></span>
      </button>\
    `;
  }

  triggers() {
    return {
      'click .print-button': 'print:clicked'
    };
  }

}

module.exports = PrintFormButtonView;
