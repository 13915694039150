import {
  MobileStepper as MuiMobileStepper,
  type MobileStepperProps as MuiMobileStepperProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

export type AxMobileStepperProps = MuiMobileStepperProps;

const styledOptions = {
  name: 'AxMobileStepper'
};

const StyledAxMobileStepper = styled(MuiMobileStepper, styledOptions)<AxMobileStepperProps>(() => {
  return {};
});

/**
 * A wrapper around the MUI `MobileStepper` component
 *
 * ### Links
 * - [MUI MobileStepper API](https://mui.com/material-ui/api/mobile-stepper/)
 */
export const AxMobileStepper = forwardRef(({
  ...otherProps
}: AxMobileStepperProps, ref: Ref<HTMLDivElement>) => {
  const MobileStepperProps: AxMobileStepperProps = {
    ...otherProps
  };

  return (
    <StyledAxMobileStepper { ...MobileStepperProps } ref={ ref } />
  );
});

export default AxMobileStepper;
