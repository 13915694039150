const Backbone = require('Backbone');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class ModuleLinksCollection extends Backbone.Collection {
  getPackageForLanguage(language) {
    const languagePackage = this.find((pkg) => {
      return pkg.get('language') === language;
    });
    if (languagePackage) {
      return languagePackage;
    }
    // If we get here, we the selected language is not supported, so use the fallback language
    return this._getPackageForFallbackLanguage();
  }

  _getPackageForFallbackLanguage() {
    // Use the tenant's default language as the fallback language
    const langCode = TenantPropertyProvider.get().getProperty('defaultLanguage');
    const fallbackPackage = this.find((pkg) => {
      return pkg.get('language') === langCode;
    });
    if (fallbackPackage) {
      return fallbackPackage;
    }
    // If we still don't have a package - just take whatever we can get to avoid errors
    return this.first();
  }
}

module.exports = ModuleLinksCollection;
