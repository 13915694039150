const PasswordHelpers = require('@common/libs/helpers/app/PasswordHelpers');
const PasswordComplexityValidationResult = require('@common/modules/auth/common/PasswordComplexityValidationResult');

class PasswordComplexityRules {
  constructor(minLength, minGroups) {
    this.minLength = minLength;
    this.minGroups = minGroups;
  }

  getMinLength() {
    return this.minLength;
  }

  getMinGroups() {
    return this.minGroups;
  }

  /**
   * Validates a password conforms to the rules specified inside of the password complexity rules
   * @param {string} rawPassword The stirng that is a raw, read not MD5, string of the password to check against
   */
  validateAgainstPassword(rawPassword) {
    const isProperLength = (s) => {
      return s.length >= this.getMinLength();
    };
    const isMatchingMinGroups = (s) => {
      return PasswordHelpers.numberOfCharacterGroups(s) >= this.getMinGroups();
    };

    return new PasswordComplexityValidationResult(
      !isProperLength(rawPassword),
      !isMatchingMinGroups(rawPassword)
    );
  }
}

module.exports = PasswordComplexityRules;
