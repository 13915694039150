const logging = require('logging');
const {
  isEmpty,
  once,
  pick,
  get
} = require('underscore');
const {
  Behavior,
  isNodeAttached
} = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');
const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');
const ScrollEventState = require('@common/libs/behaviors/scrollable/ScrollEventState');

/*
  ScrollableEvents is a behavior for triggering events whenever certain scroll related conditions are met. These events
  are fired on the host View and also call any on* hook methods if they're present.

  Static enums and helper methods:
    -provided to allow child Views to interact with its parent Scrollable

    Enums:
      'Options': defines all valid options that can be passed in as constructor options.

        'TOP_BUFFER': (int - 1) Determines how close the content top needs to be to the container top before the
          'scrollTopBuffer' event is fired.
        'BOTTOM_BUFFER': (int - 1) Determines how close the content bottom needs to be to the container bottom before
          the 'scrollBottomBuffer' event is fired.
        'LEFT_BUFFER': (int - 1) Determines how close the content left needs to be to the container left before the
          'scrollLeftBuffer' event is fired.
        'RIGHT_BUFFER': (int - 1) Determines how close the content right needs to be to the container right before the
          'scrollRightBuffer' event is fired.

    'DataKeys': defines all valid attribute keys that correspond to the various scroll event data that is maintained in
      the behavior.

      'IS_VERTICAL_OVERFLOW': (boolean) wheather the content is vertically overflowing it's container or not
      'IS_HORIZONTAL_OVERFLOW': (boolean) wheather the content is horizontally overflowing it's container or not

      'AT_TOP_EDGE': (boolean) whether the contents top edge is up against the containers top edge.
      'AT_BOTTOM_EDGE': (boolean) whether the contents bottom edge is up against the containers bottom edge.
      'AT_LEFT_EDGE': (boolean) whether the contents left edge is up against the containers left edge.
      'AT_RIGHT_EDGE': (boolean) whether the contents right edge is up against the containers right edge.
      'IN_TOP_BUFFER': (boolean) whether the contents top edge is within the defined buffer distance to the  containers top edge.
      'IN_BOTTOM_BUFFER': (boolean) whether the contents bottom edge is within the defined buffer distance to the  containers bottom edge.
      'IN_LEFT_BUFFER': (boolean) whether the contents left edge is within the defined buffer distance to the  containers left edge.
      'IN_RIGHT_BUFFER': (boolean) whether the contents right edge is within the defined buffer distance to the  containers right edge.

    *** These keys come from Scrollable.Keys ***
      'SCROLL_BOTTOM': how far the bottom of the content is from the bottom of container
      'SCROLL_TOP': how far the top of the content is from the top of container
      'SCROLL_LEFT': how far the left of the content is from the left of container
      'SCROLL_RIGHT': how far the right of the content is from the right of container
      'CONTAINER_HEIGHT': height of the scrollable container
      'CONTAINER_WIDTH': width of the scrollable container
      'CONTENT_HEIGHT': height on the content
      'CONTENT_WIDTH': width of the content

    Methods:

      'getScrollEventData': Explicitly request the scroll event data if it's needed outside of a given event.
        Pass in an optional ScrollEventKey enum to get a specific piece of data.

    Events: All events have a copy of all event and scroll data as their only arg.

      'DataKey Changes': all changes to the EventState data keys (doesn't include changes to the Scrollable.Keys, see next)
      'POSTIION_CHANGE': fired whenever any of the scrollTop/Bottom/Left/Right position data changes.
      'CONTENT_CHANGE': fired whenever the dimensions of the content has changed, either due resize events or DOM changes.
      'CONTAINER_CHANGE': fired whenever the dimensions of the container has changed, either due resize events or DOM changes.
      'STRUCTURAL_CHANGE': fired anytime either the content or the container have dimension changes.
      'SCROLL': fired whenever any of the Scrollable scroll data changes.

*/

const Events = {
  SCROLL: 'scroll',
  POSITION_CHANGE: 'scroll:position:change',
  CONTENT_CHANGE: 'scroll:content:change',
  CONTAINER_CHANGE: 'scroll:container:change',
  STRUCTURAL_CHANGE: 'scroll:structural:change'
};

// Utility to get any of the scroll event data on a view that has ScrollEvent behavior applied
const getScrollEventData = (view, key) => {
  let data = {};

  view.$el.trigger('getScrollEventData.scrollEvents', [(scrollData) => {
    data = scrollData;
  }]);

  if (!isNodeAttached(view.el)) {
    logging.warn('Passed in `view` is not attached to the DOM yet! Are you calling `getScrollEventData()` this from the right place in the View lifecycle?');
  } else if (isEmpty(data)) {
    logging.warn('No scroll event data was returned, most likely because there was no View that uses ScrollEvents higher up in the View hierarchy.');
  }

  if (key != null) {
    ScrollEventState.Keys.assertLegalValue(key);
    return get(data, key);
  }

  return data;
};

class ScrollEvents extends Behavior {

  static ScrollEventKeys = ScrollEventState.Keys;

  static EventKeys = Events;

  static Options = ScrollEventState.Options;

  static getScrollEventData = getScrollEventData;

  static getChanges = ScrollEventState.getChanges;

  static hasDataChanges = ScrollEventState.hasDataChanges;

  static getEventStateChanges = ScrollEventState.getEventStateChanges;

  static hasContainerChanges = ScrollEventState.hasContainerChanges;

  static getContainerChanges = ScrollEventState.getContainerChanges;

  static hasContentChanges = ScrollEventState.hasContentChanges;

  static getContentChanges = ScrollEventState.getContentChanges;

  static hasStructuralChanges = ScrollEventState.hasStructuralChanges;

  static getStructuralChanges = ScrollEventState.getStructuralChanges;

  static hasScrollPositionChanges = ScrollEventState.hasScrollPositionChanges;

  static getScrollPositionChanges = ScrollEventState.getScrollPositionChanges;

  events() {
    return {
      'getScrollEventData.scrollEvents': 'onGetScrollEventData'
    };
  }

  initialize() {
    this._onScrollDataChange = this._onScrollDataChange.bind(this);

    this._setupScrollableHooks = once(this._setupScrollableHooks.bind(this));

    this.scrollEventState = new ScrollEventState({}, pick(this.options, ScrollEventState.Options.values()));

    this._initializeState();
  }

  onRender() {
    this._initializeState();
  }

  onAttach() {
    this._initializeState();
  }

  onGetScrollEventData(e, callback = () => {}) {
    this._initializeState();
    callback(this.scrollEventState.toJSON());
    return false;
  }

  _initializeState() {
    if (isNodeAttached(this.view.el)) {
      this._setupScrollableHooks();
    }
  }

  _setupScrollableHooks() {
    Scrollable.registerEventHandler(this.view, this._onScrollDataChange);
    this._onScrollDataChange(Scrollable.getScrollData(this.view));
  }

  _onScrollDataChange(scrollData) {
    this.scrollEventState.updateFromScrollData(scrollData);

    const scrollEventData = this.scrollEventState.toJSON();
    const hasContainerChanges = this.scrollEventState.hasContainerChanges();
    const containerChanges = this.scrollEventState.getContainerChanges();
    const hasContentChanges = this.scrollEventState.hasContentChanges();
    const contentChanges = this.scrollEventState.getContentChanges();
    const hasScrollPositionChanges = this.scrollEventState.hasScrollPositionChanges();
    const scrollPositionChanges = this.scrollEventState.getScrollPositionChanges();
    const hasStructuralChanges = this.scrollEventState.hasStructuralChanges();
    const structuralChanges = this.scrollEventState.getStructuralChanges();
    const eventStateChanges = this.scrollEventState.getEventStateChanges();

    Object.entries(eventStateChanges).forEach(([key, value]) => {
      this.view.triggerMethod(key, scrollEventData, value);
    });

    if (hasContainerChanges) {
      this.view.triggerMethod(Events.CONTAINER_CHANGE, scrollEventData, containerChanges);
    }

    if (hasContentChanges) {
      this.view.triggerMethod(Events.CONTENT_CHANGE, scrollEventData, contentChanges);
    }

    if (hasStructuralChanges) {
      this.view.triggerMethod(Events.STRUCTURAL_CHANGE, scrollEventData, structuralChanges);
    }

    if (hasScrollPositionChanges) {
      this.view.triggerMethod(Events.POSITION_CHANGE, scrollEventData, scrollPositionChanges);
    }

    this.view.triggerMethod(Events.SCROLL, scrollEventData, eventStateChanges);
  }
}

Behaviors.ScrollEvents = (options, view) => {
  // Make sure only 1 ScrollEvents bahavior is attached to a given View to avoid multiple events getting triggered
  // for a given scroll change.
  const ScrollEventsClass = view._hasScrollEvents ? Behavior : ScrollEvents;
  view._hasScrollEvents = true;
  return new ScrollEventsClass(options, view);
};

module.exports = ScrollEvents;
