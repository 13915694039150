const PageableCollection = require('@common/components/pageableList/PageableCollection');
const PageInsightsSubteamsModel = require('@training/apps/insights/subteams/PageInsightsSubteamsModel');

const PAGE_SIZE = 25;

class PageInsightsSubteamsCollection extends PageableCollection {
  initialize(models, options = {}) {
    ({
      pageId: this.pageId,
      teamId: this.teamId
    } = options);

    super.initialize(models, options);
    this.state.pageSize = PAGE_SIZE;
  }

  model(attrs, options = {}) {
    return new PageInsightsSubteamsModel(attrs, options);
  }

  parse(res = {}) {
    this.state.totalRecords = res.totalSearchResults;
    this.state.totalPages = Math.ceil(res.totalSearchResults / this.state.pageSize);
    this.state.lastPage = this.state.totalPages - 1;
    return super.parse(res);
  }

  apiEndpoint() {
    return `pageInsights/${ this.pageId }/subteamEngagement`;
  }

  fetch(options = {}) {
    options.data = options.data || {};
    options.type = 'GET';
    if (this.teamId) {
      Object.assign(options.data, { locationId: this.teamId });
    }
    return super.fetch(options);
  }
}

module.exports = PageInsightsSubteamsCollection;
