const Backbone = require('Backbone');

class BroadcastMessage extends Backbone.Model {
  defaults() {
    return {delivered: false};
  }

  markAsRead() {
    this.set({delivered: true});

    return $.ajax({
      apiEndpoint: '/broadcastMessageViews',
      type: 'POST',
      skipGlobalHandler: true,
      data: JSON.stringify({
        broadcastMessage: {
          id: this.get('id')
        }
      })
    });
  }
}

module.exports = BroadcastMessage;
