/*
# CollectionLayoutViewController

## Availible Options

`childControllers`: (Reguired) Array of Controller Definition Objects. (Can also be implemented by a sub class)

`viewDefiniton`: (Optional) View Definition Object for overriding any CollectionView options. (`ViewClass` can only be CollectionView or a subclass there of.)

  {
    tagName: 'ul',
    className: 'example-class',
    formatter: thisIsJustOnePropertyYouMightUse,
    etc...
  }


## Full Usage Example

class ExampleController extends LayoutController {
  ...
  regionControllers() {
    return {
      someRegionName: {
        ViewControllerClass: StackedLayoutViewController,
        viewDefinition: {
          // override any CollectionView options here
          tagName: 'ul',
          className: 'example-css-class',
          childViewOptions: {
            tagName: 'li',
            // Recommend adding `-region` to the end of the className since the child views act as regions. This helps
            // when devs are reading the DOM explorer in the browser
            className: 'li-class-name-region'
          },
          emptyView: SomeEmptyViewClass,
        },
        childControllers: [
          {
            ViewControllerClass: LayoutController,
            viewDefinition: {
              tagName: 'span'
            }
          }, () => {
            return {
              ViewControllerClass: LayoutController,
              viewDefinition: {
                className: 'some-class-name'
              }
            }
          }
        ]
      }
    }
  }
  ...
}
*/

const {
  isFunction,
  uniqueId,
  omit,
  compact
} = require('underscore');
const { Model, Collection } = require('Backbone');
const {
  LayoutView
} = require('Marionette');

const { getValue } = require('@common/libs/helpers/types/ObjectHelpers');

const ViewController = require('@common/libs/UI/controllers/ViewController');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');

const DEFINITION_KEY = 'viewControllerDefinition';
const VIEW_REGION_OPTIONS_KEY = 'viewRegionOptions';

class ViewControllerConfig extends Model {

  constructor(attrs, options = {}) {
    // Make sure the data always gets parsed.
    super(attrs, Object.assign({}, options, { parse: true }));
  }

  defaults() {
    return {
      id: uniqueId('viewControllerConfig'),
      [DEFINITION_KEY]: {
        ViewControllerClass: ViewController
      }
    };
  }

  parse(attrs = {}) {
    if (isFunction(attrs) || !attrs[DEFINITION_KEY]) {
      return { [DEFINITION_KEY]: attrs };
    }

    return attrs;
  }

  set(models, options) {
    // Make sure the data always gets parsed.
    Object.assign(options, { parse: true });
    super.set(models, options);
  }
}

class ViewRegion extends LayoutView {
  constructor(options = {}) {
    const viewRegionOptions = options.model.get(VIEW_REGION_OPTIONS_KEY);
    const calculatedViewRegionOptions = getValue(viewRegionOptions, null, options);
    // Make sure the template is always false as anything that does get rendered inside here
    // will be wiped out since it's acting as a region.
    const extendedOptions = Object.assign({}, options, calculatedViewRegionOptions, { template: false });
    super(extendedOptions);
  }
}

class StackedLayoutViewController extends CollectionLayoutViewController {
  static createConfigObject = (viewRegionOptions, definition) => {
    if (!definition) {
      return null;
    }

    return {
      [DEFINITION_KEY]: definition,
      [VIEW_REGION_OPTIONS_KEY]: viewRegionOptions
    };
  }

  constructor(options = {}) {
    // Making sure CollectionLayoutViewController options are always defined in here
    // and can't be overriden via passed in options.
    super(omit(options, 'ChildViewControllerDefinition', 'collection'));

    Object.assign(this, {
      ChildViewControllerDefinition: this._initializeChildViewControllerDefinition(options),
      collection: this._initializeChildControllersCollection(options)
    });
  }

  viewDefinition() {
    return Object.assign({}, super.viewDefinition(), {
      childView: ViewRegion
    });
  }

  _initializeChildViewControllerDefinition() {
    return (model) => {
      return () => {
        return this.getRegionControllerManager().getViewControllerFactory()
          .buildDefinition( model.get(DEFINITION_KEY) );
      };
    };
  }

  _initializeChildControllersCollection(options = {}) {
    const childControllers = getValue(this.getOption('childControllers'), this, omit(options, 'childControllers'));

    if (!Array.isArray(childControllers)) {
      throw new Error('Invalid childControllers, needs to be an Array or a function that produces an Array!');
    }

    return new Collection(compact(childControllers), { model: ViewControllerConfig });
  }
}

module.exports = StackedLayoutViewController;
