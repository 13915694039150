const {
  extend,
  forEach,
  keys
} = require('underscore');

const { assertLegalValue } = require('@common/data/enums/ActivityType');

module.exports = (ActivityPageFactories = {}, defaultOptionFactory = () => {}) => {
  forEach(keys(ActivityPageFactories), assertLegalValue);

  return {
    create(activity, options = {}) {
      const type = activity.get('type');

      assertLegalValue(type);

      const createActivityPage = ActivityPageFactories[type];
      const pageOptions = extend({}, defaultOptionFactory(activity), options);

      return createActivityPage(pageOptions);
    }
  };
};
