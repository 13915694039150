const Marionette = require('Marionette');
const Tenant = require('@common/data/models/Tenant');

const UserAgreementList = require('@common/data/collections/UserAgreementList');
const UserAgreementService = require('@common/modules/auth/views/agreement/UserAgreementService');
const UserAgreementAcceptancePage = require('@common/modules/auth/views/agreement/UserAgreementAcceptancePage');

const CommonFlashAdapter = require('@training/apps/training/CommonFlashAdapter');

// This wrapper is needed since we need something to control the loading and training uses a route based flow
// so we cannot just hold up the route since people could interupt it
class UserAgreementAcceptanceWrapperView extends Marionette.LayoutView {
  getTemplate() {
    return `
      <div class="wrapper-container parent-height"></div>
    `;
  }

  regions() {
    return {
      wrapperContainer: '.wrapper-container'
    };
  }

  initialize(options = {}) {
    // This is used to trigger when the page is done showing agreements
    ({
      callback: this.callback,
      session: this.session
    } = options);

    if (!this.callback) {
      throw new Error('You provide a legal callback in order to create this wrapper view');
    }
  }

  onShow() {
    const pendingAgreements = new UserAgreementList();
    return Promise.resolve(pendingAgreements.fetchPendingForActiveUser()).then(() => {
      const agreements = pendingAgreements.models;
      return Promise.mapSeries(agreements, (agreement) => {
        return new Promise((done) => {
          this.showUserAgreementPage(agreement, done, done);
        });
      }).then(this.callback.bind(this));
    });
  }

  onDestroy() {
    window.app.layout.toggleFooter(true);
  }

  showUserAgreementPage(agreement, callback, failureCallback) {
    const agreementPage = new UserAgreementAcceptancePage({
      agreementModel: agreement,
      agreementService: new UserAgreementService(),
      flashService: CommonFlashAdapter,
      success: callback,
      failure: failureCallback,
      user: this.session.user,
      tenant: new Tenant(this.session.tenant)
    });

    this.wrapperContainer.show(agreementPage);
  }
}

module.exports = UserAgreementAcceptanceWrapperView;
