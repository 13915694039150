const Sync = require('@common/libs/Sync');
const UIKit = require('@training/widgets/UIKit');
const dateHelpers = require('@common/libs/dateHelpers');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const FilteredEventLayoutView = require('@training/apps/events/filteredEvents/FilteredEventLayoutView');
const FilteredEventListItemView = require('@training/apps/events/filteredEvents/FilteredEventListItemView');
const LearningEventListHeaderView = require('@training/apps/training/views/LearningEventListHeaderView');
const EmptyLearningEventListView = require('@training/apps/training/views/EmptyLearningEventListView');

const { LearningEventFilteredList } = require('@training/apps/training/collections/LearningEventList');

class FilteredEventController extends LayoutController {
  initialize(options = {}) {
    ({
      eventId: this.eventId,
      guidedLearningFlowCallback: this.guidedLearningFlowCallback,
      eventDetailsCallback: this.eventDetailsCallback,
      urlSlug: this.urlSlug
    } = options);

    this._collection = new LearningEventFilteredList([], {
      currentUserLanguage: window.apps.auth.session.user.get('language'),
      eventId: this.eventId,
      recordsPerPage: 20
    });
    this._collection.fetch();

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: FilteredEventLayoutView,
      collection: this._collection,
      guidedLearningFlowCallback: this.guidedLearningFlowCallback,
      urlSlug: this.urlSlug
    };
  }

  regionControllers() {
    return {
      filteredEventRegion: {
        viewDefinition: {
          ViewClass: UIKit.ListView,
          disableGrid: true,
          collection: this._collection,
          EmptyView: EmptyLearningEventListView,
          FirstItemView: (options = {}) => {
            return new FilteredEventListItemView(Object.assign({
              eventDetailsCallback: this.eventDetailsCallback
            }, options));
          },
          ModelView: (options = {}) => {
            return new FilteredEventListItemView(Object.assign({
              eventDetailsCallback: this.eventDetailsCallback
            }, options));
          },
          HeaderView: LearningEventListHeaderView,
          groupBy(model) {
            return dateHelpers.convertDateFormatCustom(model.get('scheduledEvent').startDate, 'epoch', 'MMMM, YYYY');
          }
        }
      }
    };
  }

  onViewInflate(controller, viewInstance) {
    window.apps.base.timeLogController.bindPageViewLog(viewInstance, 'FilteredEventController');
  }

  onDestroy() {
    this._collection.abortXHR(Sync.Method.READ);
  }
}

module.exports = FilteredEventController;
