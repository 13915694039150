// Module
var code = `<form class="js-form general-settings-form">
  <div
    class="general-settings-form__name"
    data-field="names"
    data-editor="TranslatableName"
    data-options="translatableNameOptions">
  </div>
</form>

<h2><%- t('communitiesManagement.filters.tagsHeader') %></h2>

<div id="tags_region"></div>

<div class="tags_warning ax-grid ax-grid--no-gutter">
  <div class="icon-exclamation ax-grid__col--auto-size" aria-hidden="true" role="presentation"></div>
  <p><%- t('communitiesManagement.filters.tagWarning') %></p>
</div>

<div class="tags-actions ax-grid ax-grid--justify-content-space-between">
  <div class="ax-grid__col--8">
    <%= axButton({ label: t('general.save'), className: 'js-tag-save', branded: true }) %>
    <%= axButton({ label: t('general.cancel'), className: 'js-tag-cancel' }) %>
  </div>
  <%= axButton({ label: t('general.delete'), className: 'js-tag-delete ax-grid__col--3', destructive: true }) %>
</div>

`;
// Exports
module.exports = code;