const _ = require('underscore');

class InitiatorStorageEntry {
  constructor(assessmentId, context, timestamp) {
    this.assessmentId = assessmentId;
    this.context = context;
    this.timestamp = timestamp;

    if (!this.context) {
      throw new Error('There is no context passed in -- but it is required');
    }
    if (!this.timestamp) {
      throw new Error('There is no timestamp passed in -- but it is required');
    }
    if (!_.isNumber(assessmentId)) {
      throw new Error('There is no context passed in -- but it is required');
    }
  }

  getAssessmentId() {
    return this.assessmentId;
  }

  getContext() {
    return this.context;
  }

  getTimestamp() {
    return this.timestamp;
  }
}

module.exports = InitiatorStorageEntry;
