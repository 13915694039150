const {RefresherTraining} = require('@common/data/enums/SessionTrainingType');

const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');

const QuizboardSelectableAssessmentController = require('@training/apps/training/controllers/assessments/QuizboardSelectableAssessmentController');

class RefresherTrainingAssessmentController extends QuizboardSelectableAssessmentController {
  getTimeLogOptions(options = {}) {
    const { isFromHub = false } = options;

    if (isFromHub) {
      return TimeLogConfig.PageView.HubRefresherTraining;
    }
    return TimeLogConfig.PageView.AutomaticRefresherTraining;

  }

  getSessionTrainingType() {
    return RefresherTraining;
  }
}

module.exports = RefresherTrainingAssessmentController;
