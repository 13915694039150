// Module
var code = `<div class="characterarea">
  <div class="bonuscharacter" <% if (bonuscharacter === 'NoCoach') { %>aria-hidden="true" role="presentation"<% } %>>
    <div role="img" class="<%- bonuscharacter %>" aria-label="<%- t('coaches.hello') %>"></div>
  </div>
</div>

<div class="starttext">
  <h1 class="welcome-heading"><%= t('start.greeting', {fullname: user.displayName}) %></h1>
  <div class="earned">
    <% if (questionCount === 1) { %>
      <%- t('assessments.deeplink.subheading.singular') %>
    <% } else { %>
      <%- t('assessments.deeplink.subheading.plural', {questionCount: questionCount}) %>
    <% } %>
  </div>
</div>

<a href="#" class="large-block-button assessment ax-button--branded qa-assessment-button"
  aria-label="<%- t('assessments.deeplink.button') %>" role="button">
  <span class="large-block-button-icon icon-play"></span>
  <span class="large-block-button-label"><%- t('assessments.deeplink.button') %></span>
</a>
`;
// Exports
module.exports = code;