const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

const {
  INFORMATIONAL,
  SMALL_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');

const { canDownload } = require('@common/libs/helpers/app/NativeBridgeHelpers');

class AttachmentCardView extends Marionette.ItemView {

  behaviors() {
    return {
      Card: {
        modifierClasses: [SMALL_PADDING, INFORMATIONAL]
      }
    };
  }

  className() {
    return 'attachment-card';
  }

  getTemplate() {
    return require('@training/apps/training/templates/AttachmentCardTemplate.html');
  }

  templateHelpers() {
    return {
      canDownload: canDownload(),
      linkHtml: `<span class="icon-download"></span><span>${ I18n.t('broadcastMessage.dzArticleMediaDownload') }</span>`
    };
  }
}
module.exports = AttachmentCardView;
