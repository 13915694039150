import {
  AxBox,
  AxStack,
  AxTypography
} from '@common/modules/react/themes/components';
import {
  AccordionSummary as MuiAccordionSummary,
  styled,
  type AccordionSummaryTypeMap as MuiAccordionSummaryTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type PropsToOmit = 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'title';

type AxAccordionSummaryCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required,
  /**
   * The title of the header
   */
  heading?: string | React.ReactNode;
  /**
   * The subheading of the header
   */
  subheading?: string | React.ReactNode;
  /**
   * The action to be displayed on the right side of the header
   */
  headerAction?: React.ReactNode;
  // TODO: expandIcon should be boolean and default node should be <ExpandMoreIcon />
};

export type AxAccordionSummaryTypeMap<P = object, D extends React.ElementType = 'div'> = {
  defaultComponent: D;
  props: P & Omit<MuiAccordionSummaryTypeMap<P, D>['props'], PropsToOmit> & AxAccordionSummaryCustomProps;
};

export type AxAccordionSummaryProps<
  D extends ElementType = AxAccordionSummaryTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxAccordionSummaryTypeMap<P & AxAccordionSummaryCustomProps, D>, D>;

const styledOptions = {
  name: 'AxAccordionSummary'
};

const StyledAxAccordionSummary = styled(MuiAccordionSummary, styledOptions)<AxAccordionSummaryProps>(({theme}) => {
  return {
    flexDirection: 'row-reverse',
    color: theme.uiKit.colorGrey80,
    padding: `0 ${ theme.uiKit.spacingS }`,
    lineHeight: theme.uiKit.lineHeightXS,
    '.MuiAccordionSummary-expandIconWrapper > *': {
      color: theme.uiKit.colorGrey80,
      fontSize: theme.uiKit.fontSizeXL
    },
    '.MuiAccordionSummary-heading': {
      lineHeight: theme.uiKit.lineHeightXS,
      fontSize: theme.uiKit.fontSizeXS,
      fontWeight: theme.uiKit.fontWeightMedium
    },
    '.MuiAccordionSummary-subheading': {
      lineHeight: theme.uiKit.lineHeightXS,
      fontSize: theme.uiKit.fontSizeXS,
      color: theme.uiKit.colorGrey60
    },
    '.MuiAccordionSummary-content': {
      flexDirection: 'column',
      marginLeft: theme.uiKit.spacingS,
      '&.Mui-expanded': {
        marginLeft: theme.uiKit.spacingS
      }
    }
  };
});

/**
 * The `AxAccordionSummary` component is used to display a summary of the content within an `AxAccordion`.
 */
export const AxAccordionSummary: MuiOverridableComponent<AxAccordionSummaryTypeMap> = forwardRef(({
  heading,
  subheading,
  headerAction,
  children,
  ...otherProps
}: AxAccordionSummaryProps, ref: Ref<HTMLDivElement>) => {
  
  const headingElement = typeof heading === 'string' ? (
    <AxTypography className='MuiAccordionSummary-heading'>
      { heading }
    </AxTypography>
  ) : heading;

  const subheadingElement = typeof subheading === 'string' ? (
    <AxTypography className='MuiAccordionSummary-subheading'>
      { subheading }
    </AxTypography>
  ) : subheading;

  const defaultContentElement = (
    <AxStack
      direction={ 'row' }
      justifyContent={ 'space-between' }
      alignItems={ 'center' }
    >
      <AxBox>
        { headingElement }
        { subheadingElement }
      </AxBox>
      { headerAction }
    </AxStack>
  );

  return (
    <StyledAxAccordionSummary
      { ...otherProps }
      ref={ ref }
    >
      { children || defaultContentElement }
    </StyledAxAccordionSummary>
  );
});

export default AxAccordionSummary;
