const UserCardView = require('./UserCardView');
const I18n = require('@common/libs/I18n');

class ChallengeUserCardView extends UserCardView {
  tagName() {
    return 'li';
  }

  _getBottomBarData(...args) {
    const bottomBar = super._getBottomBarData(...args);
    $.extend(true, bottomBar, {
      buttonText: I18n.t('selectGame.gameChallenge.user.buttonText'),
      data: {
        id: this.model.id
      }
    });

    const meta = this._getMetaData(bottomBar.meta);
    if (meta != null) {
      bottomBar.meta = meta;
    }

    return bottomBar;
  }

  _getMetaData(meta = {}) {
    const smallStreak = 2;
    const bigStreak = 5;
    let gameChallengeStreak = this.model.get('gameChallengeStreak');
    let className = '';
    let html = '';

    if (gameChallengeStreak > 0) {
      // winning streak
      if (gameChallengeStreak >= smallStreak) {
        className = 'winning-streak';
        html = `<span>${ I18n.t('selectGame.gameChallenge.user.meta.winningStreak', {value: gameChallengeStreak}) }</span>`;

        if (gameChallengeStreak >= bigStreak) {
          html = `<span class="streak-icon icon-fire ax-grid__col--auto-size"></span><span><span class="hot-streak">${ I18n.t('selectGame.gameChallenge.user.meta.hotStreak') }</span>&mdash;` + html + `</span>`;
        }
      }

    } else if (gameChallengeStreak < 0) {
      // losing streak
      gameChallengeStreak = Math.abs(gameChallengeStreak);
      if (gameChallengeStreak >= smallStreak) {
        className = 'losing-streak';
        html = `<span>${ I18n.t('selectGame.gameChallenge.user.meta.losingStreak', {value: gameChallengeStreak}) }</span>`;

        if (gameChallengeStreak >= bigStreak) {
          html = `<span class="streak-icon icon-snowflake ax-grid__col--auto-size"></span><span><span class="cold-streak">${ I18n.t('selectGame.gameChallenge.user.meta.coldStreak') }</span>&mdash;` + html + `</span>`;
        }
      }
    }

    return $.extend(true, meta, {className, html});
  }

  onButtonClick() {
    this.trigger('user:challenge:selected', this.model);
  }
}

module.exports = ChallengeUserCardView;
