const { Model } = require('Backbone');

class ImageCropImageState extends Model {
  static FIELDS = {
    IMAGE_STATE: 'imageState'
  };

  setState(image) {
    this.set(ImageCropImageState.FIELDS.IMAGE_STATE, image);
  }

  getState() {
    return this.get(ImageCropImageState.FIELDS.IMAGE_STATE);
  }
}

module.exports = ImageCropImageState;
