// Module
var code = `<li title="<%- t('question.clicktoselect') %>">
  <div id="<%- htmlId %>" data-id="<%- id %>" class="mq-option" aria-selected="false" role="button" tabindex="0">
    <% if (displayImg) { %>
      <div class="imgwrap zoom-image-wrap" data-media-id="<%- imgId %>"></div>
    <% } %>
    <div class="off-screen">
      <span class="mq-a11y-note-selected"><%- t('question.accessibility.matchingQuestion.selected') %></span>
      <span class="mq-a11y-note-correct"><%- t('question.accessibility.matchingQuestion.correct') %></span>
      <span class="mq-a11y-note-incorrect"><%- t('question.accessibility.matchingQuestion.incorrect') %></span>
      <span class="mq-a11y-note-paired"><%- t('question.accessibility.matchingQuestion.matched') %></span>
      <span class="mq-a11y-note-unpaired"><%- t('question.accessibility.matchingQuestion.unmatched') %></span>
    </div>
    <% if (displayText) { %>
      <div class="mq-option-text <% if (displayImg) { %>mq-text-and-image<% } %>">
        <%= text %>
      </div>
    <% } %>
    <div class="mq-triangle-wrapper">
      <div class="mq-triangle-border" aria-hidden="true" role="presentation"></div>
      <div class="mq-triangle-filler" aria-hidden="true" role="presentation"></div>
      <div class="mq-triangle-bar" aria-hidden="true" role="presentation"></div>
    </div>
    <div class="mq-correctness-indicator" aria-hidden="true" role="presentation"></div>
  </div>
</li>
`;
// Exports
module.exports = code;