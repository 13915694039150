const { LayoutView } = require('Marionette');

class TagGroupFiltersEmptyContentView extends LayoutView {
  get template() {
    return require('@training/apps/search/communitiesManagement/tagGroupFilters/content/TagGroupFiltersEmptyContentView.html');
  }

  className() {
    return 'tag-group-filters-empty-content';
  }
}

module.exports = TagGroupFiltersEmptyContentView;
