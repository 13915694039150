const Marionette = require('Marionette');
const {
  Wreqr,
  Model
} = require('Backbone');
const I18n = require('@common/libs/I18n');

const { toggleSensitiveContent } = require('@common/libs/helpers/app/NativeBridgeHelpers');
const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');

require('jquery.velocity');

class LoginPageLayout extends Marionette.LayoutView {
  behaviors() {
    return {
      Scrollable: {
        [Scrollable.Options.SCROLLABLE_CONTAINER]: '.login-scroll-container',
        [Scrollable.Options.CONTENT_DIMENSIONS_CALCULATOR]: this.getScrollContentDimensions.bind(this)
      }
    };
  }

  preinitialize() {
    this.model = new Model({
      loginBgLowResPath: null,
      loginBgFullResPath: null
    });
  }

  constructor(options) {
    super(options);

    this.loginFormView = options.loginFormView;
    this.loginPrompt = options.loginPrompt;

    this.brandingChannel = Wreqr.radio.channel('branding');
    this.listenTo(this.brandingChannel.vent, 'style:updated', (brandingInfo) => {
      this.model.set({
        loginBgLowResPath: brandingInfo.loginBgLowResPath,
        loginBgFullResPath: brandingInfo.loginBgFullResPath
      });
    });
  }

  modelEvents() {
    return {
      change: '_fadeInBackgrounds'
    };
  }

  className() {
    return 'login-layout';
  }

  getTemplate() {
    return `\
      <div class="login-background-container">
        <div class="login-background-default"></div>
        <div class="login-background-color"></div>
        <div class="login-background-low-res js-login-background-low-res"></div>
        <div class="login-background-full-res js-login-background-full-res"></div>
        <% if (disclaimerMsg) { %>
          <div class="login-terms-of-service-region-desktop js-terms-of-service"><%= disclaimerMsg %></div>
        <% } %>
      </div>
      <div class="js-login-scroll-container login-scroll-container <% if (loginPrompt === false) { %> login-no-animation <% } %>">
        <div class="js-login-form-container login-form-container">
          <div class="login-logo" alt="<%= logoAltText %>"></div>
          <button class="axon-button" id="go-to-login"><%- goToLoginButtonText %></button>
          <div class="login-form-region js-login-form-region"></div>
          <% if (disclaimerMsg) { %>
            <div class="login-terms-of-service-region-mobile js-terms-of-service"><%= disclaimerMsg %></div>
          <% } %>
        </div>
      </div>
    `;
  }

  regions() {
    return {
      loginForm: '.js-login-form-region'
    };
  }

  ui() {
    return {
      bgLowRes: '.js-login-background-low-res',
      bgFullRes: '.js-login-background-full-res',
      goToLoginButton: '#go-to-login',
      loginFormContainer: '.js-login-form-container',
      loginScrollContainer: '.js-login-scroll-container',
      loginFormRegion: '.js-login-form-region',
      termsOfService: '.js-terms-of-service'
    };
  }

  events() {
    return {
      'click @ui.goToLoginButton': 'onClickGoToLoginButton'
    };
  }

  templateHelpers() {
    return {
      disclaimerMsg: I18n.t('login.disclaimer'),
      goToLoginButtonText: I18n.t('login.loginbtn'),
      loginPrompt: this.loginPrompt,
      logoAltText: I18n.t('header.logo.alt')
    };
  }

  onRender() {
    this._fadeInBackgrounds();

    if (this.loginFormView) {
      this.getRegion('loginForm').show(this.loginFormView);

      this.listenTo(this.loginFormView, 'change:lang', () => {
        this._updateTermsOfServiceText();
      });
    }

    toggleSensitiveContent(true);
    this.listenTo(Wreqr.radio.channel('nativeBridge').vent, 'appDidEnterBackground', () => {
      this.ui.loginFormRegion.css({ opacity: 0 });
    });

    this.listenTo(Wreqr.radio.channel('nativeBridge').vent, 'appDidBecomeActive', () => {
      this.ui.loginFormRegion.css({ opacity: 1 });
    });
  }

  onClickGoToLoginButton() {
    this.ui.goToLoginButton.hide();
    this.ui.loginFormRegion.show();

    this.ui.loginScrollContainer.velocity({ top: 0 }, { duration: 500 });
  }

  getScrollContentDimensions() {
    return {
      height: this.getOuterHeight(this.ui.loginFormContainer, true),
      width: this.getOuterWidth(this.ui.loginFormContainer, true)
    };
  }

  _fadeInBackgrounds() {
    this._setupBgFadeIn(this.ui.bgLowRes, this.model.get('loginBgLowResPath'), 200);
    this._setupBgFadeIn(this.ui.bgFullRes, this.model.get('loginBgFullResPath'), 800);
  }

  _setupBgFadeIn($bgEl, imgPath, timeMs) {
    if (!$bgEl || $bgEl.length === 0) {
      return;
    }

    $bgEl.hide();

    setTimeout(() => {
      if (imgPath) {
        $('<img>').attr('src', imgPath)
          .on('load', () => {
            $bgEl.velocity('fadeIn', { duration: timeMs} ); // start fade in once image is loaded in memory
          });
      } else {
        $bgEl.velocity('fadeIn', { duration: timeMs} ); // fallback, safe to display div even if image isn't set
      }
    }, 1);
  }

  _updateTermsOfServiceText() {
    this.ui.termsOfService.html(I18n.t('login.disclaimer'));
  }
}

module.exports = LoginPageLayout;
