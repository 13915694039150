// Module
var code = `<div class="ax-grid">
  <div class="js-search-bar-wrapper">
    <div class="search-input-view ax-grid ax-grid--no-gutter ax-grid--align-items-center">
      <%= axSearch({
        fullWidth: true,
        iconClassRight: 'qa-input-search-clear icon-remove js-remove ' + clearSearchClass,
        placeholder: t('communitiesManagement.tags.inputPlaceholder'),
        size: 'l',
        value: searchQuery
      }) %>
    </div>
  </div>
  <div class="tags-search__count">
    <div class="ax-grid ax-grid--no-gutter ax-grid--justify-content-end">
      <div class="tags-count-text ax-grid__col--auto-size"></div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;