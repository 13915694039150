const Backbone = require('Backbone');

class SelfRegistrationCode extends Backbone.Model {
  className = 'SelfRegistrationCode';

  idAttribute = 'token';

  urlRoot() {
    return '/axonify/register';
  }

  parse(res = {}) {
    return res.entity || res;
  }

  isEmailOptional() {
    return this.get('email').optional === true;
  }
}

module.exports = SelfRegistrationCode;
