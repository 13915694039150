function getProfileColorByNumber(number) {
  // make sure the number is a number or return
  // undefined otherwise
  if (isNaN(number)) {
    return undefined;
  }

  // make sure the number is positive
  const absNumber = Math.abs(number);

  const availableColors = [
    '#D33F1C',
    '#7B63D8',
    '#377F5D',
    '#1079AF',
    '#A0662C',
    '#9E4DA0',
    '#0A33B5',
    '#BF30C2',
    '#646464',
    '#6032D1',
    '#275C44',
    '#9E2216'
  ];

  const index = absNumber % (availableColors.length);

  // return the color
  return availableColors[index];
}

module.exports = getProfileColorByNumber;
