const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const _ = require('underscore');

class CommunityInsightsTableLayoutView extends TableLayoutView {
  initialize(options = {}) {
    ({
      collection: this.collection,
      rowItemView: this.rowItemView,
      headerNames: this.headerNames
    } = options);
  }

  className() {
    return 'community-insights__table community-insights__table--no-shrink';
  }

  getTemplate() {
    return `
      <thead>
        <tr>
        <% for (i = 0; i < headerNames.length; i++) { %>
          <% if (typeof headerNames[i] === 'object') { %>
            <%= buildWithOptions(headerNames[i]) %>
          <% } else { %>
            <th <%= hasMultipleRows ? 'rowspan="2" scope="rowgroup"' : '' %> class="ax-font ax-font__caption ax-font__caption--bold"> <%- headerNames[i] %></th>
          <% } %>
        <% } %>
        </tr>
        <% if (hasMultipleRows) { %>
          <tr>
            <% for (i = 0; i < headerNames.length; i++) { %>
              <% if (typeof headerNames[i] === 'object') { %>
                <%= buildSubTableHeader(headerNames[i]) %>
              <% } %>
            <% } %>
          </tr>
        <% } %>
      </thead>
      <tbody></tbody>
    `;
  }

  templateHelpers() {
    return {
      headerNames: this.headerNames,
      hasMultipleRows: this.headerNames.some((header) => {
        return header.type != null;
      }),
      buildWithOptions: (options) => {
        if (options.type === 'super') {
          return this._buildSuperScriptTableHeader(options);
        }
        return _.template(`
        <th class="ax-font ax-font__caption <%- options.className %>">
            <p><%- options.value %></p>
        </th>
      `)({options: options});
      },
      buildSubTableHeader: (names) => {
        if (!names.columns) {
          return '';
        }

        return _.template(`
        <% for (y = 0; y < titles.length; y++) { %>
          <th class="reports-table__value-column ax-font ax-font__caption ax-font__caption--bold<% if (y == 1 ) { %> recent-pages-table__border <% } %>">
            <%- titles[y] %>
          </th>
        <% } %>
        `)({titles: names.columns});
      }
    };
  }

  _buildSuperScriptTableHeader(subheader) {
    const colAttrs = subheader.columns.length ? 'colspan="2" scope="colgroup"' : '';
    return _.template(`
      <th <%= colAttrs %> class="ax-font ax-font__caption recent-pages-table__border community-insights-table__subheader <%- subheader.className %>">
          <%- subheader.title %>
      </th>
    `)({
      subheader,
      colAttrs
    });
  }
}

module.exports = CommunityInsightsTableLayoutView;
