const Backbone = require('Backbone');
const logging = require('logging');
const Promise = require('bluebird');

const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const AbstractAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/AbstractAssessmentInitiatorController');
const NullAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/NullAssessmentInitiatorController');

const DeepLinkingSplashPageView = require('./DeepLinkingSplashPageView');
const TopicOptionFromDeepLinkingQueryParametersFactory = require('./TopicOptionFromDeepLinkingQueryParametersFactory');
const DeepLinkingAssessmentInitiatorContext = require('./DeepLinkingAssessmentInitiatorContext');
const DeepLinkingNextStartableAssessmentProvider = require('./DeepLinkingNextStartableAssessmentProvider');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const AssessmentCreationError = () => {};
AssessmentCreationError.prototype = Object.create(Error.prototype);

class DeepLinkingAssessmentInitiator extends AbstractAssessmentInitiatorController {
  constructor(parentProcessor, options = {}) {
    super(parentProcessor, options);

    ({
      assessmentFactory: this.assessmentFactory,
      getDailyTrainingSessionInitiatorProvider: this.getDailyTrainingSessionInitiatorProvider
    } = options);
  }

  createAssessment(assessmentTopicOption) {
    const context = this.createInitiatorContext({
      topicId: assessmentTopicOption.toAssessmentRequestJson().topicId,
      level: assessmentTopicOption.toAssessmentRequestJson().level,
      programId: assessmentTopicOption.getProgramId()
    });

    return this.assessmentFactory.createForAssessmentTypeAsync(
      assessmentTopicOption.getAssessmentType(),
      context,
      assessmentTopicOption.toAssessmentRequestJson(),
      {deepLink: true}
    ).catch((jqXHR = {}) => {
      const exception = AxonifyExceptionFactory.fromResponse(jqXHR);

      const {
        topicId,
        level,
        programId,
        assessmentType
      } = assessmentTopicOption.toAssessmentRequestJson();
      logging.warn(`Unable to create an '${ assessmentType }' Assessment for topicId ${ topicId }, level ${ level }, programId ${ programId }; errCode = ${ exception.getErrorCode() }`);

      throw new AssessmentCreationError();
    });
  }

  // Override
  createInitiatorContext(json) {
    const {
      topicId,
      level,
      programId,
      showSplashPage
    } = json;

    return new DeepLinkingAssessmentInitiatorContext(topicId, level, programId, showSplashPage);
  }

  // Override
  getDiscriminator() {
    return DeepLinkingAssessmentInitiatorContext.getType();
  }

  getNextItemProvider(assessmentInitiatorContext) {
    return this._findDesirableInitiatorToChainIntoAsync()
      .then((initiatorController) => {
        const nextItemProvider = initiatorController.getNextItemProvider(assessmentInitiatorContext);
        return new DeepLinkingNextStartableAssessmentProvider(nextItemProvider);
      });
  }

  processSequenceFlow(options = {}) {
    const {
      queryParams
    } = options;

    try {
      const assessmentTopicOption = TopicOptionFromDeepLinkingQueryParametersFactory(queryParams);
      return this.createAssessment(assessmentTopicOption)
        .then(({
          assessment,
          context
        }) => {
          Backbone.history.navigate('', { replace: true });
          this.processAssessment(assessment, context);
          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        })
        .catch(AssessmentCreationError, () => {
          // remove the query parameters and the hash for `assessmentLink` by redirecting to the index page
          // XXX: this is so dirty but even `apps.auth.redirectToIndexPage()` doesn't work correctly because the
          // underlying `redirect` preserves the query params
          Backbone.history.navigate('#index');
          return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
        });
    } catch (error) {
      // Throws when there is no topicId, that's required for deep link otherwise there's nothing to do here.
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    }
  }

  createAndProcessAssessment(assessmentTopicOption) {
    return this._findDesirableInitiatorToChainIntoAsync()
      .then((initiatorController) => {
        return initiatorController.createAndProcessAssessment(assessmentTopicOption);
      });
  }

  // Override
  processAssessment(assessment, context) {
    // Mark this as a "deep link training" because it's being processed by the DeepLinkingAssessmentInitiator
    // TODO: there's gotta be a better way to tracks "event" time logs, `startEventType` isn't what we want either...
    window.apps.base.timeLogController.startDeepLinkTraining(assessment.id);
    window.apps.base.timeLogController.stopDeepLinkTraining();

    return this.getNextItemProvider(context)
      .then((nextItemProvider) => {
        // Instace of assessmentController is required/used by the AbstractAssessmentInitiatorController
        const assessmentController = this.getAssessmentProcessor({
          assessment,
          nextItemProvider: nextItemProvider
        });

        this.assessmentController.showAssessmentPageTitle();
        this.assessmentController.showAssessmentPageTitleProgress();
        window.app.layout.togglePageHeader(true);

        return this.showSplashPageAsync(assessment, context)
          .then(() => {
            return assessmentController.processSequenceFlow();
          });
      });
  }

  showSplashPageAsync(assessment, context = {}) {
    const {
      showSplashPage = true
    } = context;

    // The requirement is to only show the splash page in the "questions-only" case when the user has not answered a question.
    if (showSplashPage && assessment.hasOnlyQuestionActivities() && !assessment.hasAnsweredAnyQuestions()) {
      return new Promise((resolve) => {
        const splashView = new DeepLinkingSplashPageView({
          onComplete: resolve,
          assessmentQuestionCount: assessment.getUnansweredQuestionCount()
        });
        window.app.layout.setView(splashView);
      }).then(() => {
        // Mark splash page as no longer needing to be shown on the assessment context object for scenarios that could
        // lead to the it being unnecessarily shown a second time. Changes to context means tracker must re-track
        context.showSplashPage = false;
        this.assessmentFactory.beginTracking(assessment, context);
      });
    }
    return Promise.resolve();

  }

  _findDesirableInitiatorToChainIntoAsync() {
    const sessionType = this.sessionModel.getTrainingSessionType();
    const WHITELIST_PROXY_TYPES = [
      SessionTrainingType.GuidedLearningTraining,
      SessionTrainingType.CertificationTraining,
      SessionTrainingType.IntroductoryTraining,
      SessionTrainingType.RefresherTraining
    ];

    return Promise
      .try(() => {
        if (WHITELIST_PROXY_TYPES.includes(sessionType)) {
          // `getInitiatorForTypeAsync` may return `null` as well.
          return this.getDailyTrainingSessionInitiatorProvider().getInitiatorForTypeAsync(sessionType);
        }
        return null;

      })
      .then((initiator = null) => {
        return initiator || new NullAssessmentInitiatorController(this.parentProcessor, {
          sessionModel: this.sessionModel
        });
      });
  }
}

module.exports = DeepLinkingAssessmentInitiator;
