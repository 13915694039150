const Enum = require('@common/data/enums/Enum');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');

module.exports = Enum.fromStringArray([
  'TIMELINE',
  'COMMUNITY_TIMELINE',
  'COMMUNITY_ARTICLES',
  'COMMUNITY_POSTS',
  'ARTICLE_SEARCH_RESULTS'
], {
  getEnumFromSearchCategory(searchCategory) {
    const lookupTable = {
      [SearchCategoryEnum.ALL]: this.COMMUNITY_TIMELINE,
      [SearchCategoryEnum.POSTS]: this.COMMUNITY_POSTS,
      [SearchCategoryEnum.ARTICLES]: this.COMMUNITY_ARTICLES,
      [SearchSubCategoryEnum.FAVORITES]: this.COMMUNITY_ARTICLES,
      searchResults: this.ARTICLE_SEARCH_RESULTS,
      timeline: this.TIMELINE
    };
    return lookupTable[searchCategory];
  }
});
