// Module
var code = `<div tabindex="0" aria-label="<%= notificationAriaLabel %>" class="learning-event-notification-item full-width <% if (removedEvent) { %>disabled<% } %>">
  <div class="ax-grid ax-grid--m-align-items-center">
    <div class="ax-grid__col--auto-size">
      <div class="learning-event-notification-user">
        <div class="name-logo" aria-hidden="true" role="presentation">
          <div class="learning-events-user-placeholder"></div>
        </div>
      </div>
    </div>

    <div class="learning-event-notification-info">
      <% if (removedEvent) { %><div class="learning-event-removed ax-font ax-font__caption ax-font--no-margin"><%- t('learningEvents.notification.retiredWarning') %></div><% } %>
      <div class="ax-font ax-font__caption notification-info__caption"><%= notificationText %></div>
      <h4 class="ax-font--no-margin"><%= itemTitle %></h4>
      <p class="learning-event-notification-eventdate ax-font--no-margin">
        <span class="event-notification-date"><%- itemDate %></span>
        <span class="bullet-point">&bull;</span>
        <span class="event-notification-location"><%= itemLocation %></span>
      </p>
    </div>

    <div class="ax-grid__col--12 ax-grid__col--m-auto-size ax-grid__col--order-4 ax-grid__col--m-order-3">
      <div class="learning-event-notification-date ax-font ax-font__caption ax-font--no-margin"><%- itemCreatedDate %></div>
    </div>

    <span class="icon-angle_right ax-grid__col--auto-size ax-grid__col--order-3 ax-grid__col--m-order-4" role="presentation" aria-hidden="true"></span>
  </div>
</div>
`;
// Exports
module.exports = code;