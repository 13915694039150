const {
  LayoutView,
  ItemView
} = require('Marionette');
const Cocktail = require('backbone.cocktail');

const I18n = require('@common/libs/I18n');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

require('@common/libs/behaviors/scrollable/Scrollable');

const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');

const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');

const ImportHistoryErrorsCollection = require('@training/apps/search/communitiesManagement/ImportHistoryErrorsCollection');

const RESULTS_PER_PAGE = 40;

class ImportHistoryErrorsModalView extends LayoutView {
  initialize(options = {}) {
    this.rpp = RESULTS_PER_PAGE;
    this.collection = new ImportHistoryErrorsCollection();
    this.fetchDataOptions = {
      id: options.id
    };

    this.fetchNext();

    this.errorsTable = new TableLayoutView({
      collection: this.collection,
      headerNames: [
        I18n.t('imports.error.row'),
        I18n.t('imports.error.error_message')
      ],
      rowItemView: ImportHistoryErrorRowView
    });
  }

  getTemplate() {
    return `
      <div class="modalHeader">
        <h1><%- t('imports.error.import_errors') %></h1>
        <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
      </div>
      <div class="js-errors-table errors-table"></div>
    `;
  }

  regions() {
    return {
      errorsTableRegion: '.js-errors-table'
    };
  }

  ui() {
    return {
      errorsTable: '.js-errors-table'
    };
  }

  behaviors() {
    return {
      Scrollable: {
        scrollableContainer: '.js-errors-table'
      }
    };
  }

  onAttach() {
    this.errorsTableRegion.show(this.errorsTable);
    this.$scrollContainer = this.ui.errorsTable;
  }

  onLoadNext() {
    this.fetchNext();
  }

  fetchSuccess(response) {
    if (this.noMorePages) {
      return;
    }

    // Set no more pages if length returned is less than results per page
    if (response.results.length < this.rpp && this.collection.length !== 0) {
      this.noMorePages = true;
    }

    triggerResize(true);
  }
}

class ImportHistoryErrorRowView extends ItemView {
  tagName() {
    return 'tr';
  }

  getTemplate() {
    return `
      <td width="15%"><%= rowNumber %></td>
      <td><%= message %></td>
    `;
  }
}

Cocktail.mixin(ImportHistoryErrorsModalView, InfiniteScrollViewMixin);
module.exports = ImportHistoryErrorsModalView;
