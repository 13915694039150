// Module
var code = `<div class="user-messaging">
  <h1 class="page-header">
    <div class="ax-container">
      <div class="page-header__content page-header__content--justify-start">
        <span class="icon-bullhorn page-header__icon"></span>
        <div>
          <span class="page-header__kicker"><%- t('broadcastMessage.kicker') %></span>
          <span class="page-header__title"><%- subject %></span>
        </div>
      </div>
    </div>
  </h1>

  <div class="ax-container">
    <div class="page-content">
      <h2 class="section-title"><%= subject %></h2>
      <div class="broadcastmessage">
        <div class="broadcast-message-video-wrapper <% if (hasVideo) { %> broadcast-message-video-wrapper--active <% } %>"></div>
        <article class="rte-message fr-view" aria-label="<%- t('broadcastMessage.messageBody') %>">
          <%= message %>
          <div class="clearfix"></div>
        </article>
        <% if (dzLink) { %>
          <div class="broadcast-message__link-content" role="region" aria-label="<%- t('appNames.knowledge') + ' ' +t('broadcastMessage.region.dzWholeRegion') %>"></div>
        <% } %>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;