const Backbone = require('Backbone');
const GuidedLearningCategory = require('@common/components/guidedLearning/models/GuidedLearningCategory');

class GuidedLearningCategoriesList extends Backbone.Collection {

  preinitialize(models, options = {}) {
    ({
      sessionModel: this.sessionModel
    } = options);
  }

  apiEndpoint() {
    return '/assignedProgram/available/program';
  }

  get model() {
    return (attrs, options) => {
      return new GuidedLearningCategory(attrs, Object.assign({}, options, { sessionModel: this.sessionModel }));
    };
  }

  hasStartableItems() {
    return this.any((category) => {
      return category.hasStartableItems();
    });
  }
}

module.exports = GuidedLearningCategoriesList;
