const Marionette = require('Marionette');
const TagBarItem = require('@common/components/discover/views/TagBarItem');
const PersistentObject = require('@common/libs/UI/PersistentObject');

class TagBar extends Marionette.CompositeView.extend(PersistentObject) {
  get template() {
    return `
      <ul class="tag-bar__list"></ul>
    `;
  }

  get childViewContainer() {
    return '.tag-bar__list';
  }

  get childView() {
    return TagBarItem;
  }

  constructor(options = {}) {
    super(options);

    ({
      formattingOptions: this.formattingOptions
    } = options);

    this.childViewOptions = this.childViewOptions.bind(this);
  }

  childViewOptions() {
    return {
      formattingOptions: this.formattingOptions
    };
  }

  onChildviewFacetClicked(view) {
    return this.trigger('facet:clicked', view.model);
  }

  onSaveInstanceState(bundleJSON) {
    bundleJSON.facets = this.collection.getSelectedFacets();
    return bundleJSON.facets;
  }

  onRestoreInstanceState(bundleJSON) {
    return this.collection.set(bundleJSON.facets);
  }
}

module.exports = TagBar;
