const logging = require('logging');

const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');

class AssessmentInitiatorWithPreludeViewDecorator extends FlowController {

  constructor(decoratedClass, preludeView) {
    super(decoratedClass.parentProcessor);

    this.decoratedClass = decoratedClass;
    this.preludeView = preludeView;

    if (!this.decoratedClass) {
      throw new Error('There must be some kind of decorated class to decorate');
    }

    if (!this.preludeView) {
      throw new Error('There must be some kind of prelude view passed inside');
    }
  }

  processSequenceFlow() {
    logging.debug('AssessmentInitiatorWithPreludeViewDecorator - Show prelude page before the flow controller');

    const callback = this.decoratedClass.processSequenceFlow.bind(this.decoratedClass);

    this.showPreludeView((options = {}) => {
      callback(options)
        .then(() => {
          logging.info('Showing the assessment controller coming from the start page now');
          return this.parentProcessor.processSequenceFlow();
        })
        .catch(Promise.OperationalError, () => {
          logging.warn('Nothing to do, nom nom nom.');
        })
        .catch((ex) => {
          logging.warn(`There was an exception moving from start page to controller ${ ex }`);
          return this.parentProcessor.processSequenceFlow();
        });
    });

    return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
  }

  processTraining(trainingOption) {
    return this.decoratedClass.processTraining(trainingOption);
  }

  createAndProcessAssessment(assessmentTopicOption) {
    return this.decoratedClass.createAndProcessAssessment(assessmentTopicOption);
  }

  createAssessment(assessmentTopicOption) {
    return this.decoratedClass.createAssessment(assessmentTopicOption);
  }

  processAssessment(assessmentTopicOption) {
    return this.decoratedClass.processAssessment(assessmentTopicOption);
  }

  showPreludeView(complete) {
    window.app.layout.resetLeftHeaderView();

    window.app.layout.setView(this.preludeView(complete));
  }
}

module.exports = AssessmentInitiatorWithPreludeViewDecorator;
