import type { UploadProgressStatus } from '@common/interfaces/SharedTypes';
import I18n from '@common/libs/I18n';
import {
  AxBox,
  AxCircularProgress,
  AxIcon,
  AxIconButton,
  AxTooltip
} from '@common/modules/react/themes/components';
import {
  styled,
  useTheme
} from '@mui/material';
import { type CSSObject } from '@mui/system';
import type {
  MouseEventHandler,
  PropsWithChildren,
  Ref
} from 'react';
import { forwardRef } from 'react';

export interface ImageUploadThumbnailProps extends PropsWithChildren {
  /**
  *  This shows the upload & processing status of the image
  *  @default 'waiting'
  **/
  status: UploadProgressStatus | null;
  /**
  *  image bits
  **/
  imageUrl: string | null;
  /**
  *  Presumably if you click on it you'll see a full sized preview
  **/
  onClickImage?: MouseEventHandler;
  onClickRemoveButton?: MouseEventHandler;
}

const styledOptions = {
  name: 'ImageUploadThumbnail',
  onclick,
  shouldForwardProp: (prop: string) => {
    // This callback determines whether a prop becomes an HTML attribute.
    return prop !== 'status' && prop !== 'count';
  }
};

const StyledImageUploadThumbnail = styled(AxBox, styledOptions)<ImageUploadThumbnailProps>(({
  theme,
  status
}) => {
  const defaultStyles: CSSObject = {
    fontSize: theme.uiKit.fontSizeXS,
    borderColor: theme.uiKit.colorGrey40,
    backgoundColor: (status && status !== 'done') ? theme.uiKit.colorGrey20 : '  transparent',
    width: '10rem',
    height: '10rem',
    borderStyle: 'solid',
    borderRadius: theme.uiKit.spacingS,
    marginRight: theme.uiKit.spacingXS,
    position: 'relative',
    padding: '0'
  };

  return defaultStyles;
});

export const ImageUploadThumbnail = forwardRef(({
  status = 'waiting',
  imageUrl,
  onClickImage,
  onClickRemoveButton,
  ...otherProps
}: ImageUploadThumbnailProps, ref: Ref<HTMLDivElement>): JSX.Element => {
  const props = {
    status,
    imageUrl,
    onClickImage,
    onClickRemoveButton,
    ...otherProps
  };

  const theme = useTheme();

  return (
    <StyledImageUploadThumbnail { ...props } ref={ ref }>

      {
        (status === 'done')
        && <AxBox
          onClick={ onClickImage }
          component='img'
          src={ imageUrl || '' }
          sx={{
            borderRadius: theme.uiKit.spacingXS,
            position: 'relative',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            cursor: 'pointer'
          }}
        ></AxBox>
      }

      {
        (status && status !== 'done')
        && <AxBox sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <AxCircularProgress
            color='positive'
            size={ 35 }
            sx={{
              position: 'absolute'
            }}
          />
          <AxBox
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {
              (status === 'uploading')
              && <AxIcon
                className='icon-exchange'
              />
            }
            {
              (status === 'storing')
              && <AxIcon
                className='icon-link'
              />
            }
            {
              (status === 'waiting')
              && <AxIcon
                className='icon-pending'
              />
            }
            {
              (status === 'processing')
              && <AxIcon
                className='icon-cog'
              />
            }
          </AxBox>
        </AxBox>
      }

      <AxTooltip
        title={ I18n.t('general.remove') }
        placement='top'
        arrow
      >
        <AxIconButton
          onClick={ onClickRemoveButton }
          size='small'
          variant='floating'
          sx={{
            position: 'absolute',
            top: '-1rem',
            right: '-1rem'
          }}
        ><AxIcon className='icon-remove'/></AxIconButton>
      </AxTooltip>
    </StyledImageUploadThumbnail>
  );
});

export default ImageUploadThumbnail;
