const dateHelpers = require('@common/libs/dateHelpers');
const Form = require('@common/components/forms/Form');
require('@common/components/forms/editors/date/Form.Editor.DateTime');

Form.Editor.Date = class Date extends Form.Editor.DateTime {
  initialize(options = {}) {
    if (options.options == null) {
      options.options = {};
    }
    options.options.showTimeControls = false;
    options.options.placeholder = options.options.placeholder != null ? options.options.placeholder : dateHelpers.getFormatToDefaultDate();
    options.options.format = (date) => {
      return dateHelpers.convertDateFormatToDefaultDate(date);
    };
    super.initialize(options);
  }
};

module.exports = Form.Editor.Date;
