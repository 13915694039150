const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const logging = require('logging');
const Backbone = require('Backbone');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const DetailViewConfig = require('@common/components/details/DetailViewConfig');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TitleHeaderTypeEnum = require('@common/components/titleHeader/TitleHeaderTypeEnum');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const { getNumberOfItemViewDefinition } = require('@common/components/cardMetadata/MetadataStringHelper');
const PathProgramTypeEnum = require('@training/apps/training/enums/PathProgramTypeEnum');
const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');
const { combineUrlPaths } = require('@common/libs/helpers/app/UrlHelpers');

const programConfigMap = {};
programConfigMap[PathProgramTypeEnum.Path] = {
  pretext: 'assessments.select.header.title.SelfDirectedTraining'
};
programConfigMap[PathProgramTypeEnum.GuidedLearning] = {
  pretext: 'assessments.select.header.title.GuidedLearningTraining'
};

const _getBottomTileViewDefinition = (resultData) => {

  return {
    bottomWrapperControllerDefinition: {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: LayoutView,
        className: 'search-result__lower full-width',
        template: `
          <div class="metaRegion tile__meta"></div>
        `,
        regions: {
          metaRegion: '.metaRegion'
        }
      },
      regionControllers: {
        metaRegion: {
          viewDefinition: {
            ViewClass: StackedCollectionView,
            viewConfigModel: DetailViewConfig,
            className: 'stacked-collection-view',
            viewConfigs: [
              getNumberOfItemViewDefinition(resultData.get('itemCount'))
            ]
          }
        }
      }
    }
  };
};

const _getPretext = (programType) => {
  return I18n.t(`${ programConfigMap[programType].pretext }`);
};

const _getTitleOptions = (resultData, itemViewOptions) => {
  return {
    titleOptions: {
      title: resultData.get('title'),
      pretext: _getPretext(resultData.get('programType')),
      titleType: TitleHeaderTypeEnum.PRETEXT_TITLE,
      titleTag: itemViewOptions.titleTag
    }
  };
};

const _getIsAssigned = (resultData) => {
  return resultData.get('assigned');
};

const _getTemplateHelpers = (resultData) => {
  return {
    linkText: I18n.t('selfDirected.search.viewDetails'),
    isAssigned: _getIsAssigned(resultData),
    linkAttributes: `aria-label="${ I18n.t('selfDirected.search.viewPathDetailsAria', {title: resultData.get('title')}) }"`
  };
};

const _getImageView = (resultData) => {
  return {
    delegateEvents: {
      'view:attach': (controller, view) => {
        const media = controller.options.media;
        const parent = view.$el;
        let topicImage = null;

        if (!_.isEmpty(media)) {
          topicImage = ImageViewerFactory.createViewerInstance({
            media,
            $el: parent.find('.base-card__image')
          });

          if (topicImage != null) {
            view.listenTo(topicImage, 'image:loaded', () => {
              view.trigger('image:loaded');
            });
            topicImage.render();
            view.onResize = () => {
              topicImage.resize();
            };
          }
        }
      }
    },
    media: resultData.get('media'),
    behaviors: {
      Resizable: {}
    }
  };
};

const pathSearchResultControllerDefinition = (resultData, itemViewOptions ) => {
  /* Log an error if:
   * if we don't have resultData provided;
   */
  if (!resultData) {
    logging.warn('No topic data returned by the search');
    window.app.layout.flash.error(I18n.t('selfDirected.search.errors.noSearchData'));
  }

  const defaultTileOptions = {
    templateOverride: require('@training/apps/search/PathSearchResultTileView.html'),
    templateHelpersOverride: _getTemplateHelpers(resultData),
    tabbableLinkClickableCard: true,
    attributesOverride: {
      'aria-label': resultData.get('title')
    },
    cardClass: 'search-result__tile',
    hasLink: true,
    linkOptions: {
      target: 'fullCard',
      callback: () => {
        if (resultData.get('programType') === PathProgramTypeEnum.Path) {
          Backbone.history.navigate(combineUrlPaths('#hub/paths', resultData.get('id')), {
            trigger: true
          });
        } else {
          window.app.sessionController.redirectGL({
            pageId: GuidedLearningPageEnum.DetailsPage,
            pageOptions: { programId: resultData.get('id') }
          });
        }
      }
    }
  };

  Object.assign(defaultTileOptions, _getTitleOptions(resultData, itemViewOptions), _getBottomTileViewDefinition(resultData));

  //Extend the tile options with events and behaviours related to rendering an image, used to extend the base card functionality behind it's original use
  return Object.assign(getBaseCardControllerDefinition(defaultTileOptions), _getImageView(resultData));
};

module.exports = {
  pathSearchResultControllerDefinition
};
