// Module
var code = `<% if (mainHeaderColour) { %>
  [dir] #header:not(.header-slideout__menu),
  [dir] .navbar {
    background: <%- mainHeaderColour %>;
  }

  [dir] tr.ranked-row.you .rank-wrapper .rank {
    border-color: <%- mainHeaderColour %>;
  }
<% } %>

<% if (headerImageDesktop && headerImageMobile) { %>
  [dir] #header .logo,
  [dir] .navbar .logo {
    background-image: url('<%= headerImageMobile.file.path %>');
  }

  [dir] #header.header-top .logo {
    background-image: url('<%= headerImageDesktop.file.path %>');
  }

  [dir] .navbar .logo {
    width: 63%;
    background-size: contain;
  }
<% } %>

<% if (headerImageDesktop) { %>
  [dir] .login-logo {
    background-image: url('<%= headerImageDesktop.file.path %>');
  }
<% } %>
`;
// Exports
module.exports = code;