const MediaModel = require('@common/libs/file/MediaModel');
const VideoMedia = require('@common/libs/file/VideoMedia');
const ImageMedia = require('@common/libs/file/ImageMedia');
const DocumentMedia = require('@common/libs/file/DocumentMedia');
const SCORMMedia = require('@common/libs/file/SCORMMedia');
const TinCanMedia = require('@common/libs/file/TinCanMedia');

const File = require('@common/libs/file/File');

const { ImageMedia: LegacyImageMedia } = require('@common/data/models/media/ImageMedia');
const { VideoMedia: LegacyVideoMedia } = require('@common/data/models/media/VideoMedia');
const { DocumentMedia: LegacyDocumentMedia } = require('@common/data/models/media/DocumentMedia');

const createMediaFromInput = function(fileFactory, $input, fileOptions = {}) {
  const originalFile = fileFactory.createFileFromInput($input, fileOptions);
  const type = originalFile.fileType;

  return createMedia(type, { originalFile });
};

const createMediaFromJSON = function(fileFactory, json = {}, fileOptions = {}) {
  const jsonClone = {};
  $.extend(true, jsonClone, json);
  const { type } = jsonClone;

  if (!(jsonClone.originalFile instanceof File)) {
    jsonClone.originalFile = fileFactory.createFileFromJSON(type, jsonClone.originalFile, fileOptions);
  }

  return createMedia(type, jsonClone);
};

const createMediaFromFile = (file, fileOptions) => {
  return createMedia(file.fileType, {originalFile: file}, fileOptions);
};

const createMediaFromTaskMediaAttachmentDto = (fileFactory, taskMediaAttachmentDtoModel, fileOptions = {}) => {
  const {
    id,
    type
  } = taskMediaAttachmentDtoModel.get('media');

  const mediaJSON = {
    id,
    originalFile: taskMediaAttachmentDtoModel.get('file'),
    type
  };

  return createMediaFromJSON(fileFactory, mediaJSON, fileOptions);
};

const createMediaFromValue = function(fileFactory, value, fileOptions = {}) {
  if (value instanceof MediaModel) {
    return value;
  } else if (isLegacyMediaModel(value)) {
    return createMediaFromJSON(fileFactory, value.toJSON(), fileOptions);
  } else if ($(value).is('input[type="file"]')) {
    return createMediaFromInput(fileFactory, value, fileOptions);
  } else if (value != null) {
    return createMediaFromJSON(fileFactory, value, fileOptions);
  }
  return null;

};

const createMedia = function(type, attrs, options) {
  const MediaClass = getMediaClassForType(type);
  return new MediaClass(attrs, options);
};

const getMediaClassForType = function(mediaType) {
  // TODO: consolidate file types so that file and document are one in the same?
  switch (mediaType) {
    case 'video': return VideoMedia;
    case 'image': return ImageMedia;
    case 'file':
    case 'document': return DocumentMedia;
    case 'scorm': return SCORMMedia;
    case 'tincan': return TinCanMedia;
    default: throw new Error(`Invalid mediaType: ${ mediaType }`);
  }
};

const isLegacyMediaModel = (model) => {
  return (model instanceof LegacyImageMedia) || (model instanceof LegacyVideoMedia) || (model instanceof LegacyDocumentMedia);
};

module.exports = {
  createMediaFromInput,
  createMediaFromJSON,
  createMediaFromTaskMediaAttachmentDto,
  createMediaFromValue,
  createMediaFromFile
};
