// Module
var code = `<div role="region" aria-label="<%- t('referral.recentInvites') %>">
  <!-- Recently Joined -->
  <h3 id="recent-invites-label" class="ax-font--no-margin"><%- t('referral.recentInvites') %></h3>

  <ul class="recent-invites" aria-labelledby="recent-invites-label">
    <li class="empty-referral">
      <%- t('referral.recentActivityEmpty') %>
    </li>
  </ul>
</div>
`;
// Exports
module.exports = code;