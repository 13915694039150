const _ = require('underscore');

const $os = require('detectOS');
const I18n = require('@common/libs/I18n');
const logging = require('logging');
const KeyCode = require('@common/data/enums/KeyCode');

const QuestionView = require('@training/apps/training/views/activities/pages/questions/QuestionView');

class MultipleAnswerQuestionPage extends QuestionView {
  events() {
    return {
      'click .answer': 'selectAnswer',
      'keydown .answer': '_keydownAnswer'
    };
  }

  ui() {
    return {
      accessibilityNote: '#accessibility-note',
      selectAllNote: '#select-all-note'
    };
  }


  constructor(...args) {
    super(...args);

    this.template = _.tpl(require('@training/apps/training/templates/activities/pages/questions/MultipleAnswerQuestionPage.html'));
    this.rowTemplate = _.tpl(require('@training/apps/training/templates/activities/pages/questions/_multianswer_question_row.html'));
  }

  initialize(...args) {
    super.initialize(...args);

    // Page state, used for rerendering
    this.answerMargin = 10;
    this.hasAnimated = false;
    this.answerOptions = {};


    this.questionOptions = this.variant.options;
    this.questionOptions.sort(() => {
      return 0.5 - Math.random();
    });

    this.correctOptionCount = this.variant.correctOptionCount;
  }

  render() {
    this.$el.html(this.template({
      variant: this.variant
    }));

    this.$('a[href^=\'http\']').attr('target', '_blank');

    this.setupActionBarWithConfidence();

    this._renderOptionRows();

    return this;
  }

  _renderOptionRows() {
    const $wrapper = this.$('.answerwrapper');
    for (let i = 0; i < this.questionOptions.length; i++) {
      const option = this.questionOptions[i];
      const letterlabel = 'abcdefghijklmnopqrstuvwxyz'.charAt(i);
      const html = this.rowTemplate({
        option,
        optionText: this._generateOptionText(option),
        showOptionReason: false,
        showCheck: true,
        letterlabel
      });
      $wrapper.append(html);
    }
  }

  viewDidAppear() {
    logging.info('MultipleAnswerQuestionPage - viewDidAppear');

    //Add class to parent to fix scrolling
    $('#root-view .contentwrapper, #modalview .modal-content-wrapper').addClass('matchwrap');

    this._focusQuestionHeading();
    super.viewDidAppear();
  }

  optionById(id) {
    return _(this.questionOptions).find((option) => {
      return option.id === id;
    });
  }

  answersTop() {
    return this.$('.hinttext').outerHeight() + this.answerMargin;
  }

  // Shows the results of the selected answers
  showResults(points) {
    // Disable clicks
    const newPoints = points == null ? 0 : points;
    this.detachEvent('click', '.answer');

    let reasonHeight = this.answersTop();
    let allCorrect = true;

    // Determine completeness of the question, calculate total height for reason text and set row classes
    for (let i = 0; i < this.questionOptions.length; i++) {
      const option = this.questionOptions[i];
      const $optionsRow = this.$(`[data-answer-option=${ option.id }]`);
      const outerHeight = $optionsRow.outerHeight();

      if (option.correct) {
        reasonHeight = reasonHeight + outerHeight + this.answerMargin;

        if (this.answerOptions[option.id]) {
          $optionsRow.removeClass('choice').addClass('correct');
        } else {
          $optionsRow.addClass('correct');
          allCorrect = false;
        }

      } else if (this.answerOptions[option.id]) {
        reasonHeight = reasonHeight + $optionsRow.outerHeight() + this.answerMargin;
        allCorrect = false;
        $optionsRow.removeClass('choice').addClass('incorrect wrong');
      } else {
        $optionsRow.removeClass('choice').addClass('notselected wrong');
      }
    }

    if (allCorrect) {
      // Correct answer
      this.correct = true;
      this._changeCoachCorrect();

      if (this.gameManager != null) {
        this.gameManager.questionAnsweredCorrect();
      }
    } else {
      // Incorrect answer
      this.correct = false;
      this._changeCoachIncorrect();

      if (this.gameManager != null) {
        this.gameManager.questionAnsweredIncorrect();
      }
    }

    this.ui.selectAllNote.hide();
    this.showPointsArea(this.correct, newPoints);
    window.apps.auth.session.user.addPoints(newPoints);
  }

  selectAnswer(e) {
    if ($(e.target).is('.zoom-overlay, .zoom-icon, .zoom-image-wrap, img')) {
      return;
    }

    const $target = this.$(e.currentTarget);

    const questionOptionId = parseInt($target.attr('data-answer-option'), 10);

    const clickedOption = this.optionById(questionOptionId);

    if (!this.answerOptions[questionOptionId]) {
      this.answerOptions[questionOptionId] = clickedOption;
    } else {
      delete this.answerOptions[questionOptionId];
    }

    $target.toggleClass('choice');
    $target.attr('aria-checked', !($target.attr('aria-checked') === 'true'));
    $target.find('.multicheck').toggleClass('checked');

    // Enable/disable submit button
    const selectedAnswerCount = _.size(this.answerOptions);
    if ((this.hasSelectedAnswers = selectedAnswerCount > 0)) {
      this.showActionBarWithConfidence();
    } else {
      this.hideActionBar();
    }

    this._updateAccessibilityNote();

    // add class to update the colour of the hint text when the user has at least 1 selected answer
    if (selectedAnswerCount > 0) {
      this.$el.find('.hinttext').addClass('enabled');
    } else {
      this.$el.find('.hinttext').removeClass('enabled');
    }
  }

  _updateAccessibilityNote() {
    if (this.$('.multicheck.checked').length) {
      this.ui.accessibilityNote.hide();
    } else {
      this.ui.accessibilityNote.show();
    }
  }

  _updateAnswerAriaLabelForCorrectness($answerLetter, isCorrect) {
    const curLabel = $answerLetter.attr('aria-label');
    const isChecked = $answerLetter.attr('aria-checked') === 'true';
    if (isCorrect && isChecked) {
      $answerLetter.attr('aria-label', `${ I18n.t('question.accessibility.correctlyChecked') } ${ curLabel }`);
    } else if (isCorrect && !isChecked) {
      $answerLetter.attr('aria-label', `${ I18n.t('question.accessibility.incorrectlyUnchecked') } ${ curLabel }`);
    } else if (!isCorrect && isChecked) {
      $answerLetter.attr('aria-label', `${ I18n.t('question.accessibility.incorrectlyChecked') } ${ curLabel }`);
    } else if (!isCorrect && !isChecked) {
      $answerLetter.attr('aria-label', `${ I18n.t('question.accessibility.correctlyUnchecked') } ${ curLabel }`);
    }
  }

  onSubmit(confidenceLevel) {
    if (this.hasAnswered || !this.hasSelectedAnswers) {
      return;
    }

    this.hasAnswered = true;

    const optionIds = [];
    for (const idStr of _.keys(this.answerOptions)) {
      optionIds.push(parseInt(idStr, 10));
    }

    // Submit answer and load next activity JSON
    const activityBody = {
      questionVariantId: this.activity.get('body').question.variants[0].id,
      questionOptionIds: optionIds,
      confidenceLevel
    };

    this.actionBar.disableButton('submitmultianswer');

    const { hideAnswersAndReason } = this.options;
    this.activity.setAction('ANSWERQUESTION', activityBody, {
      success: (actionResponse) => {
        this.hasGotServerResponse = true;
        if (hideAnswersAndReason) {
          this.next();
        } else {
          const question = actionResponse.answer.question.variants[0];
          const {
            options
          } = question;
          for (const option of options) {
            const o = this.optionById(option.id);
            if (option.reasonText) {
              o.reasonText = option.reasonText;
            }
            o.correct = option.correct;
            this._updateAnswerAriaLabelForCorrectness(this.$(`[data-answer-option="${ option.id }"]`), option.correct);
          }

          this.createReason(question);

          this.$('.answer').removeClass('choice')
            .attr({
              'aria-disabled': true,
              tabindex: -1
            });
          this.showResults(actionResponse.pointsEarned);

          if ($os.mobile) {
            $('#root-view .contentwrapper, #modalview .modal-content-wrapper').removeClass('matchwrap');
          }

          this.setupAndShowActionBarWithContinue();

          this.scrollResultBannerIntoView(this.setFocusOnCorrectIncorrect);
        }
      }
    });
  }

  onNext() {
    if (_.isFunction(this.options.complete)) {
      this.options.complete();
    }
  }

  _keydownAnswer(e) {
    if (this.hasAnswered) {
      return;
    }

    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      this.selectAnswer(e);
    }
  }

  _changeCoachCorrect() {
    const altText = I18n.t('coaches.correctAnswer');

    this.$('.bonuscharacter div, .hinttext').removeClass('ponder')
      .addClass('correct');

    this.$('.bonuscharacter div').attr('aria-label', altText);
  }

  _changeCoachIncorrect() {
    const altText = I18n.t('coaches.incorrectAnswer');

    this.$('.bonuscharacter div, .hinttext').removeClass('ponder')
      .addClass('incorrect');

    this.$('.bonuscharacter div').attr('aria-label', altText);
  }

  _focusQuestionHeading() {
    this.$('.questionask').attr('tabindex', '-1')
      .trigger('focus');
  }
}

module.exports = MultipleAnswerQuestionPage;
