const _ = require('underscore');

const TrainingModuleType = require('@common/data/enums/TrainingModuleType');

const ContentView = require('@common/components/discover/views/ContentView');
const TrainingModuleViewFactory = require('@common/components/training_modules/TrainingModuleViewFactory');
const PreviewTrainingModule = require('@common/components/training_modules/PreviewTrainingModule');
const TrainingModuleDeliveryMethodEnum = require('@common/data/enums/TrainingModuleDeliveryMethodEnum');

const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const I18n = require('@common/libs/I18n');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');

class TrainingModuleContentView extends ContentView {
  ui() {
    return {
      pageContainer: '#page-container .page-content',
      moduleContainer: '#page-container .page-module',
      articleFooter: '.article-footer'
    };
  }

  getTemplate() {
    return require('@common/components/discover/views/TrainingModuleContentViewTemplate.html');
  }

  templateHelpers() {
    const {
      moduleType,
      deliveryMethod
    } = this.model.get('trainingModule');

    let pageContainerClass = 'full-height';
    // When NewWindow SCORM, matching height to content because we don't know how many sub modules there are
    if (moduleType === TrainingModuleType.SCORM && deliveryMethod === TrainingModuleDeliveryMethodEnum.NEW_WINDOW) {
      pageContainerClass = '';
    }

    return Object.assign(super.templateHelpers(), {
      pageContainerClass,
      moduleClass: moduleType,
      isEditing: this.isEditing,
      getLockedMessage: () => {
        return I18n.t('discover.pageTypes.article.actionText.locked', {
          lockedBy: this.model.get('lockedBy').get('salutationName')
        });
      }
    });
  }

  initialize(options = {}) {
    super.initialize(options);

    this.isEditing = options.isEditing || false;

    const currentLanguage = this.model.get('language');
    this.trainingModule = new PreviewTrainingModule(this.model.get('trainingModule'), {
      language: currentLanguage,
      deliveryMethod: this.model.get('trainingModule').deliveryMethod
    });

    const factory = new TrainingModuleViewFactory({
      trainingModule: this.trainingModule,
      previewingUser: this.sessionUser
    });

    this.viewPromise = factory.createTrainingModulePreviewForLanguage(currentLanguage);
  }

  onRender() {
    this._toggleReportedBanner();
  }

  onAttach() {
    super.onAttach();

    const convertedDesc = _.escape(this.trainingModule.get('translatableDesc').getValueForLanguage(this.model.get('language')));
    this.ui.pageContainer.html(HTMLHelpers.lineBreakToBr(convertedDesc));

    this.viewPromise.then((view) => {
      if (this.isDestroyed) {
        return;
      }

      this.moduleView = view;
      view.setElement(this.ui.moduleContainer);
      view.render();
    });
  }

  onResize() {
    super.onResize();

    if (this.moduleView && !this.moduleView.isDestroyed && [TrainingModuleType.TRANSCODED_VIDEO, TrainingModuleType.EXTERNAL_VIDEO].includes(this.trainingModule.get('moduleType'))) {
      this.moduleView.resize();
    }
  }

  onBeforeDestroy() {
    super.onBeforeDestroy();

    if (this.moduleView) {
      this.moduleView.destroy();
    }
  }
}

module.exports = TrainingModuleContentView;
