const Backbone = require('Backbone');
const _ = require('underscore');

const TranslatableStringValidators = require('@common/components/forms/validators/TranslatableStringValidators');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');

const NAME_FIELD_NAME = 'name';
const DESCRIPTION_FIELD_NAME = 'description';
const ACTIVE_FIELD_NAME = 'active';
const TARGET_GROUPS_FIELD_NAME = 'groups';
const VERSION_FIELD_NAME = 'version';

const MAX_NAME_LENGTH = 512;
const DESCRIPTION_MAX_LENGTH = 40000;

class UserAgreement extends Backbone.Model {
  apiEndpoint() {
    return '/agreements';
  }

  defaults() {
    return {
      [NAME_FIELD_NAME]: new LocalizableString(),
      [DESCRIPTION_FIELD_NAME]: new LocalizableString(),
      [ACTIVE_FIELD_NAME]: false,
      [TARGET_GROUPS_FIELD_NAME]: [],
      [VERSION_FIELD_NAME]: 0
    };
  }

  validators() {
    return {
      [NAME_FIELD_NAME]: [TranslatableStringValidators.maxLength(MAX_NAME_LENGTH), TranslatableStringValidators.required],
      [DESCRIPTION_FIELD_NAME]: [TranslatableStringValidators.maxLength(DESCRIPTION_MAX_LENGTH), TranslatableStringValidators.required]
    };
  }

  initialize(attributes = {}, options = {}) {
    this.className = 'UserAgreement';
    super.initialize(attributes, options);
  }

  parse(json) {
    const entity = json.entity || json;

    if (entity['localizableBody']) {
      entity['localizableBody'].default = null;
    }

    entity[NAME_FIELD_NAME] = new LocalizableString(entity['localizableTitle']);
    entity[DESCRIPTION_FIELD_NAME] = new LocalizableString(entity['localizableBody']);

    // This is for view compatability unfortunately
    entity['titleString'] = (entity['localizableTitle']);
    entity['messageString'] = (entity['localizableBody']);

    return entity;
  }

  getDescription() {
    return this.get(DESCRIPTION_FIELD_NAME);
  }

  getName() {
    return this.get(NAME_FIELD_NAME);
  }

  getTargetGroups() {
    return this.get(TARGET_GROUPS_FIELD_NAME);
  }

  getTranslatableDataFields() {
    return {
      titleString: {
        validatorType: 'string',
        valueKey: 'translations'
      },
      messageString: {
        validatorType: 'richtext',
        valueKey: 'translations'
      }
    };
  }

  incrementVersion() {
    const currentVersion = this.get(VERSION_FIELD_NAME);
    const newVersion = currentVersion + 1;
    this.set(VERSION_FIELD_NAME, newVersion);
    return newVersion;
  }

  isActive() {
    return this.get(ACTIVE_FIELD_NAME) === true;
  }

  toJSON() {
    const json = super.toJSON();
    delete json[NAME_FIELD_NAME];
    delete json[DESCRIPTION_FIELD_NAME];

    json[TARGET_GROUPS_FIELD_NAME] = json[TARGET_GROUPS_FIELD_NAME].map((group) => {
      return _.pick(group, 'id');
    });

    // This is due to some editor things that happen behind the scenes,
    // namely the lack of proper localizable string binding going on
    // in those cases
    if (json['titleString']) {
      json['localizableTitle'] = json['titleString'];
      if (json['localizableTitle']) {
        json['localizableTitle'].default = '';
        this._trimNullsFromJson(json['localizableTitle'].translations);
      }
      delete json['titleString'];
    }

    if (json['messageString']) {
      json['localizableBody'] = json['messageString'];

      if (json['localizableBody']) {
        json['localizableBody'].default = null;
        this._trimNullsFromJson(json['localizableBody'].translations);
      }
      delete json['messageString'];
    }

    if (json[VERSION_FIELD_NAME] != null && this.isNew()) {
      delete json[VERSION_FIELD_NAME];
    }

    // The server sends the ID downn but does not want it sent up
    delete json.id;

    return json;
  }

  _trimNullsFromJson(json) {
    for (const languageCode in json) {
      const value = json[languageCode];
      if (!value) {
        delete json[languageCode];
      }
    }
  }
}

module.exports = UserAgreement;
