const { ItemView } = require('Marionette');
const $os = require('detectOS');
const moment = require('moment');
const KeyCode = require('@common/data/enums/KeyCode');
const PageType = require('@training/apps/training/communitiesInsights/components/TypeFilterEnum');
const I18n = require('@common/libs/I18n');
const SearchRoutesEnum = require('@training/apps/training/enums/SearchRoutesEnum');
const Backbone = require('Backbone');
const NumberHelpers = require('@common/libs/helpers/types/NumberHelpers');
const FormatCommunityNumbers = require('@training/apps/training/communitiesInsights/components/FormatCommunityNumbers');

class CommunityInsightsTableItemView extends ItemView {
  tagName() {
    return 'tr';
  }

  templateHelpers() {
    return {
      timeAgo: () => {
        const momentPublishTime = moment(this.model.get('datePublished') * 1000);
        const fiveDaysAgo = moment().subtract(5, 'days');

        if (momentPublishTime.isAfter(fiveDaysAgo)) {
          return `<p>${ momentPublishTime.fromNow() }</p>`;
        }

        return `<p>${ momentPublishTime.format('DD/MM/YYYY') }</p><p>${ momentPublishTime.format('h:mm a') }</p>`;
      },
      getPercent: (number) => {
        if (typeof number !== 'number') {
          return '---';
        }

        return I18n.t('general.percentValueWithSign', {percent: parseFloat( NumberHelpers.toFixed( Math.max( ((number * 100)), 0 ), 2) )});
      },
      typeText: this.model.get('type') ? I18n.t(PageType[this.model.get('type')].string) : '',
      truncatedText: (text) => {
        const truncateLimit = $( window ).width() > 500 ? 80 : 35;

        return text.length > truncateLimit ? text.substring(0, truncateLimit) + '...' : text;
      },
      formatNumber: (number) => {
        return FormatCommunityNumbers(number);
      },
      pageHref: `#${ SearchRoutesEnum.SEARCH_ARTICLE_ROUTE }/${ this.model.get('pageId') }`,
      isInMobileApp: $os.isInMobileApp()
    };
  }

  events() {
    return {
      'click .js-view-insights': () => {
        this._handleSelectRow();
      },
      'keypress .js-view-insights': (e) => {
        if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
          this._handleSelectRow();
        }
      }
    };
  }

  _handleSelectRow() {
    Backbone.history.navigate(`${ SearchRoutesEnum.SEARCH_ARTICLE_ROUTE }/${ this.model.get('pageId') }/insights`, {
      trigger: true
    });
  }
}

module.exports = CommunityInsightsTableItemView;
