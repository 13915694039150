/* eslint-disable */


/**
 * Why does this exist?
 * The following is rewriting the jquery show hide and toggle methods to us the version found in jQuery 1.10
 * The reason we are doing this is to avoid breaking changes in jQuery 3.5.0. Developers should be advised to not use
 * these methods in the future and rather use the toggleClass( example toggleClass('hidden', true)) method instead to
 * leave the burden of responsibility on CSS only. We want to avoid making inline visibility changes in the future.
 *
 * If you do have a reason for using the jQuery 3.5.0 version of show hide or toggle then we've added a alias version
 * using 'modern' as the prefix.
 */

const $ = require('jquery');


var elemdisplay = {
  BODY: 'block',
  FOOTER: 'block'
};
var isAttached = function( elem ) {
  return jQuery.contains( elem.ownerDocument, elem );
};

function isHidden( elem, el ) {
  // isHidden might be called from jQuery#filter function;
  // in that case, element will be second argument
  elem = el || elem;
  return jQuery.css( elem, 'display' ) === 'none' || !jQuery.contains( elem.ownerDocument, elem );
}

function showHide( elements, show ) {
  var display, elem, hidden,
    values = [],
    index = 0,
    length = elements.length;

  for ( ; index < length; index++ ) {
    elem = elements[index];
    if ( !elem.style ) {
      continue;
    }

    values[index] = jQuery._data( elem, 'olddisplay' );
    display = elem.style.display;
    if ( show ) {
      // Reset the inline display of this element to learn if it is
      // being hidden by cascaded rules or not
      if ( !values[index] && display === 'none' ) {
        elem.style.display = '';
      }

      // Set elements which have been overridden with display: none
      // in a stylesheet to whatever the default browser style is
      // for such an element
      if ( elem.style.display === '' && isHidden( elem ) ) {
        values[index] = jQuery._data( elem, 'olddisplay', css_defaultDisplay(elem.nodeName) );
      }
    } else {

      if ( !values[index] ) {
        hidden = isHidden( elem );

        if ( display && display !== 'none' || !hidden ) {
          jQuery._data( elem, 'olddisplay', hidden ? display : jQuery.css( elem, 'display' ) );
        }
      }
    }
  }

  // Set the display of most of the elements in a second loop
  // to avoid the constant reflow
  for ( index = 0; index < length; index++ ) {
    elem = elements[index];
    if ( !elem.style ) {
      continue;
    }
    if ( !show || elem.style.display === 'none' || elem.style.display === '' ) {
      elem.style.display = show ? values[index] || '' : 'none';
    }
  }

  return elements;
}

// Called ONLY from within css_defaultDisplay
function actualDisplay( name, doc ) {
  var elem = jQuery( doc.createElement( name ) ).appendTo( doc.body ),
    display = jQuery.css( elem[0], 'display' );
  elem.remove();
  return display;
}

// Try to determine the default display value of an element
function css_defaultDisplay( nodeName ) {
  var doc = document,
    display = elemdisplay[nodeName];

  if ( !display ) {
    display = actualDisplay( nodeName, doc );

    // If the simple way fails, read from inside an iframe
    if ( display === 'none' || !display ) {
      // Use the already-created iframe if possible
      iframe = ( iframe ||
        jQuery('<iframe frameborder=\'0\' width=\'0\' height=\'0\'/>').css( 'cssText', 'display:block !important' )
      ).appendTo( doc.documentElement );

      // Always write a new HTML skeleton so Webkit and Firefox don't choke on reuse
      doc = ( iframe[0].contentWindow || iframe[0].contentDocument ).document;
      doc.write('<!doctype html><html><body>');
      doc.close();

      display = actualDisplay( nodeName, doc );
      iframe.detach();
    }

    // Store the correct default display
    elemdisplay[nodeName] = display;
  }

  return display;
}

var isHiddenWithinTree = function( elem, el ) {

  // isHiddenWithinTree might be called from jQuery#filter function;
  // in that case, element will be second argument
  elem = el || elem;

  // Inline style trumps all
  return elem.style.display === 'none' ||
    elem.style.display === '' &&

    // Otherwise, check computed style
    // Support: Firefox <=43 - 45
    // Disconnected elements can have computed display: none, so first confirm that elem is
    // in the document.
    isAttached( elem ) &&

    jQuery.css( elem, 'display' ) === 'none';
};

const modernShow = $.fn.show.bind({});
const modernHide = $.fn.hide.bind({});
const modernToggle = $.fn.toggle.bind({});

$.fn.extend( {
  modernShow,
  modernHide,
  modernToggle,
  show: function() {
    return showHide( this, true );
  },
  hide: function() {
    return showHide( this );
  },
  toggle: function( state ) {
    if ( typeof state === 'boolean' ) {
      return state ? this.show() : this.hide();
    }

    return this.each( function() {
      if ( isHiddenWithinTree( this ) ) {
        jQuery( this ).show();
      } else {
        jQuery( this ).hide();
      }
    } );
  }
} );
