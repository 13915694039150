const StringHelpers = require('@common/libs/helpers/types/StringHelpers');

const getFileExtension = (filename) => {
  const fileExt = filename.substr(filename.lastIndexOf('.') + 1);
  return fileExt.toLowerCase();
};

const FileHelpers = {
  getFileExtension,

  getFileType(filename) {
    const fileExt = getFileExtension(filename);
    let type = 'file';
    if (fileExt === 'pdf') {
      type = 'pdf';
    } else if (Array.from(StringHelpers.docExtensionList).includes(fileExt)) {
      type = 'doc';
    } else if (Array.from(StringHelpers.spreadsheetExtensionList).includes(fileExt)) {
      type = 'spreadsheet';
    } else if (Array.from(StringHelpers.slideExtensionList).includes(fileExt)) {
      type = 'slide';
    } else if (Array.from(StringHelpers.imageExtensionList).includes(fileExt)) {
      type = 'image';
    } else if (Array.from(StringHelpers.audioExtensionList).includes(fileExt)) {
      type = 'audio';
    } else if (Array.from(StringHelpers.videoExtensionList).includes(fileExt)) {
      type = 'video';
    } else if (fileExt === 'txt') {
      type = 'text';
    } else if (Array.from(StringHelpers.zipExtensionList).includes(fileExt)) {
      type = 'zip';
    }
    return type;
  },

  // Accpets ['pdf', 'docx'], returns '.pdf, .docx'
  getFileExtensionsStringFromArray(extensionArray = []) {
    return extensionArray.map((extension) => {
      return `.${ extension }`;
    }).join(', ');
  }
};

module.exports = FileHelpers;
