const Backbone = require('Backbone');
const { LayoutView } = require('Marionette');

const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');
const { canDownload } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const MediaViewModel = require('@common/components/media/MediaViewModel');
const AttachmentItemView = require('@common/components/media/AttachmentItemView');
const MediaPreview = require('@common/components/mediaPreview/MediaPreview');

require('@common/components/submission/Submission.less');

const MEDIA_MAX_HEIGHT = 420;

class InlineUserSubmissionView extends LayoutView {

  initialize(options = {}) {
    ({
      evaluationData: this.evaluationData
    } = options);

    this._getDownloadView = this._getDownloadView.bind(this);
    this._getMediaView = this._getMediaView.bind(this);
    this.media = this.evaluationData.getMedia();
    this.mediaModel = MediaFactory.createMediaFromJSON(FileFactory, this.media, {
      videosAllowed: true,
      documentsAllowed: true
    });
  }

  className() {
    return 'inline-user-submission';
  }

  getTemplate() {
    return `\
      <h2 class="inline-user-submission__title ax-font ax-font__s"><%- t('submission.header') %></h2>
      <% if (showInfo) { %>
        <div class="inline-user-submission__info">
          <span class="icon-info_sign"></span>
          <span class="inline-user-submission__info-text"><%- t('submission.infoCard') %></span>
        </div>
      <% } %>
      <div class="inline-user-submission__download-container"></div>
      <div class="inline-user-submission__preview-container"></div>\
    `;
  }

  ui() {
    return {
      downloadContainer: '.inline-user-submission__download-container',
      previewContainer: '.inline-user-submission__preview-container'
    };
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  regions() {
    return {
      downloadRegion: '.inline-user-submission__download-container',
      previewRegion: '.inline-user-submission__preview-container'
    };
  }

  templateHelpers() {
    const viewModel = MediaViewModel.fromMedia(this.mediaModel);
    const fileType = viewModel.getFileType();
    const isViewable = (fileType === 'pdf');
    const isDownloadable = canDownload();
    /**
     * We use fileType to check if the file type is 'pdf' via isViewable because this.media.type returns 'document' as a type for 'pdf
     * We prefer to use simply this.media.type to check 'video' and 'image' file types instead of fileType via MediaViewModel
     * Because MediaViewModel has different types such as 'audio','slide','zip' etc. and it returns audio for mp4s for instance
     */
    const isPreviewable = ['video', 'image'].includes(this.media.type) || isViewable;

    return {
      showInfo: !isPreviewable && !isDownloadable
    };
  }

  onAttach() {
    this._getDownloadView(this.mediaModel);
    this._getMediaView(this.media, this.mediaModel);
  }

  onResize() {
    if (this.videoPlayer && this.videoPlayer.resize) {
      this.videoPlayer.resize();
    }
  }

  _getDownloadView(mediaModel) {
    this.downloadView = new AttachmentItemView({
      model: mediaModel,
      showInfoSection: true
    });

    this.listenTo(this.downloadView, 'click:attachment', () => {
      /**
       * Submission Info Section is only used in LeaderZone and LearnerZone.
       * We use TrainingLayoutAdapter as window.app.layout,to show/remove media in Learner Zone
       * On the other hand we use app.js and glChannel listeners to show/remove media in Manager Zone.
       * If we need to use it in other zones in the future, we should either need to add another condition, or match one of those two use cases.
       */
      if (app.layout) {
        window.app.layout.showMedia(mediaModel);
      } else {
        if (!this.glChannel) {
          this.glChannel = Backbone.Wreqr.radio.channel('global');
        }
        this.glChannel.commands.execute('app:media:show', mediaModel);
      }
    });

    this.downloadRegion.show(this.downloadView);
  }

  _getMediaView(media, mediaModel) {
    if (media.originalFile != null) {
      this.mediaPreview = new MediaPreview({
        model: mediaModel,
        maxHeight: MEDIA_MAX_HEIGHT,
        maxWidth: this.ui.previewContainer.width()
      });

      this.previewRegion.show(this.mediaPreview);
    }
  }
}

module.exports = InlineUserSubmissionView;
