import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  type CARD_PADDING,
  getCardPadding
} from './AxCard';
import {
  styled,
  CardActionArea as MuiCardActionArea,
  type CardActionAreaTypeMap as MuiCardActionAreaTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxCardActionAreaCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  padding?: CARD_PADDING
  hoverEffect?: boolean
};

export type AxCardActionAreaTypeMap<P = object, D extends React.ElementType = 'button'> = MuiCardActionAreaTypeMap<P & AxCardActionAreaCustomProps, D>;

export type AxCardActionAreaProps<
  D extends ElementType = AxCardActionAreaTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxCardActionAreaTypeMap<P & AxCardActionAreaCustomProps, D>, D>;

const styledOptions = {
  name: 'AxCardActionArea',
  shouldForwardProp: (prop: string) => {
    return prop !== 'padding' && prop !== 'hoverEffect';
  }
};

const StyledAxCardActionArea = styled(MuiCardActionArea, styledOptions)<AxCardActionAreaProps>(({
  theme,
  padding,
  hoverEffect
}) => {

  const defaultStyles = {
    padding: getCardPadding(padding),
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.common.white,
      '&:focus': {
        ...theme.mixins.componentFocusInsetStyles()
      }
    },
    '.MuiCardActionArea-focusHighlight': {
      backgroundColor: theme.palette.common.white
    }
  };

  if (hoverEffect) {
    return {
      ...defaultStyles,
      '&:hover': {
        backgroundColor: theme.uiKit.colorGrey10
      }
    };
  }

  return defaultStyles;
});

export const AxCardActionArea: MuiOverridableComponent<AxCardActionAreaTypeMap> = forwardRef(({
  children,
  disableRipple = true,
  disableTouchRipple = true,
  hoverEffect = true,
  padding = 'none',
  ...otherProps
}: AxCardActionAreaProps, ref: Ref<HTMLButtonElement>) => {
  const cardActionAreaProps: AxCardActionAreaProps = {
    children,
    disableRipple,
    disableTouchRipple,
    hoverEffect,
    padding,
    ...otherProps
  };

  return (
    <StyledAxCardActionArea { ...cardActionAreaProps } ref={ ref }>
      { children }
    </StyledAxCardActionArea>
  );
});

export default AxCardActionArea;
