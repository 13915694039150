const { ItemView } = require('Marionette');

module.exports = (recommendedArticlesList, discoveryZoneEnabled, recommendedArticlesEnabled) => {
  if (!discoveryZoneEnabled || (recommendedArticlesEnabled && recommendedArticlesList.length !== 0)) {
    return null;
  }

  return {
    viewDefinition: {
      ViewClass: ItemView,
      className: 'dz-button-container',
      template: `
        <a href="#hub/search/all/1/" aria-label="<%- t('hub.discoveryZoneButton.general') %>" role="button"
          class="large-block-button discovery-zone-button ax-button--branded qa-discovery-zone-button">
          <span class="large-block-button-icon icon-search"></span>
          <span class="off-screen"><%- t('hub.discoveryZoneButton.general') %></span>
          <span class="large-block-button-label"><%- t('hub.discoveryZoneButton.general') %></span>
        </a>
      `
    }
  };
};
