const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const { ItemView } = require('Marionette');

const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

class TagsTableItemView extends ItemView {
  tagName() {
    return 'tr';
  }

  modelEvents() {
    return {change: 'render'};
  }

  templateHelpers() {
    const filter = this.model.get('filter');
    return {
      filterName: filter || ''
    };
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/tags/TagsTableItemViewTemplate.html');
  }

  events() {
    return {
      'click .js-delete-tag': this._onClickDelete
    };
  }

  _onClickDelete() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s'
    });

    const modalChildView = new ConfirmDialogView({
      confirmCallback: this._deleteTag,
      title: I18n.t('communitiesManagement.tags.deleteModal.title'),
      confirmationText: I18n.t('communitiesManagement.tags.deleteModal.confirmationText', {
        tagName: this.model.get('name')
      }),
      buttonText: I18n.t('general.delete'),
      iconClass: 'icon-trash red',
      model: this.model
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _deleteTag() {
    this.model.destroy({
      wait: true,
      dataType: 'text', // this prevents the system from trying to parse an empty string as JSON.
      success() {
        const successText = I18n.t('communitiesManagement.tags.success');
        window.app.layout.flash.success(successText);
      }
    }).fail( (xhr) => {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      const errCode = exception.getErrorCode();

      if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
        window.app.layout.flash.error(I18n.t(`errors.Tag.3001`));
        xhr.skipGlobalHandler = true;
      } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED ) {
        window.app.layout.flash.error(I18n.t(`discover.pageAccess.tag.error.3017`));
        xhr.skipGlobalHandler = true;
      }
    });
  }
}

module.exports = TagsTableItemView;
