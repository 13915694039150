const Backbone = require('Backbone');

const BroadcastMessage = require('../models/BroadcastMessage');

class BroadcastMessageList extends Backbone.Collection {
  get model() {
    return BroadcastMessage;
  }

  apiEndpoint() {
    return '/broadcastMessages/inbox';
  }

  parse(response) {
    return response.entities;
  }

  getUnreadBroadcasts() {
    return this.where({delivered: false});
  }
}

module.exports = BroadcastMessageList;
