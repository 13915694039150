const logging = require('logging');
const _ = require('underscore');
const SCORMHelpers = require('@common/libs/helpers/app/SCORMHelpers');

const SCORMMagicConstants = require('./SCORMMagicConstants');
const NumberHelpers = require('@common/libs/helpers/types/NumberHelpers');

const COMMON_REQUIRED = [
  'initialize',
  'finish',
  'commit',
  'getLastError',
  'getErrorString',
  'getDiagnostic'
];

const SCORM_DEFAULT_VALUES = {
  // SCORM 1.2
  'cmi.core._children': 'student_id,student_name,lesson_location,credit,lesson_status,entry,score,exit,lesson_mode',

  'cmi.core.lesson_location': '',
  'cmi.core.lesson_mode': 'normal',
  'cmi.core.entry': 'ab-initio',
  'cmi.core.score.raw': '',
  'cmi.core.score._children': 'raw',
  'cmi.core.total_time': '0000:00:00.00',
  'cmi.core.credit': 'credit',
  'cmi.core.lesson_status': 'not attempted',

  // SCORM 2004 values here for the API
  'cmi.location': '',
  'cmi.mode': 'normal',
  'cmi.entry': 'ab-initio',
  'cmi.score.raw': '',
  'cmi.score._children': 'raw',
  'cmi.credit': 'credit',

  // lesson_status is split into two values for SCORM 2004
  'cmi.completion_status': 'not attempted',
  'cmi.success_status': 'unknown'
};

const COUNT_SUFFIX = '._count';

class BuilderFragment {
  constructor(options = {}) {
    this._getValue = this._getValue.bind(this);
    this._setValue = this._setValue.bind(this);
    this._commit = this._commit.bind(this);

    this.CMI_READONLY_SERVICER = {
      // SCORM 1.2
      'cmi.core.student_id': () => {
        return this._studentInfo.id;
      },

      'cmi.core.student_name': () => {
        return this._studentInfo.name;
      },

      // SCORM 2004, for maximum support we have supported all the common ones, but this is not exhaustive
      // For example, we do NOT support adl.nav.request_valid and the ADL extensions and others etc
      'cmi.learner_id': () => {
        return this._studentInfo.id;
      },

      'cmi.learner_name': () => {
        return this._studentInfo.name;
      },

      'cmi._version': () => {
        return '3.4'; // This is the revision of SCORM 2004 we support
      }
    };

    this._versionAPIKey = '';
    this._API = {};
    this._interceptor = _.noop;
    this._commitCache = options.commitCache != null ? options.commitCache : {};
    this._studentInfo = options.studentInfo;
    this._commiter = _.noop;
    this._validateAndSetAPIKey(options.versionKey);
    this.defaultValueOverrides = {};

    this._studentInfo = _.defaults(this._studentInfo, {
      name: 'Axonify User',
      id: -1
    });
  }

  withValueInterceptor(interceptor) {
    this._interceptor = interceptor;
    return this;
  }

  /**
   * Let's clients of the builder configure their own values for keys.
   *
   * A {reader} can be a opaque value or a function that returns one.
   */
  withReaderForKey(key, reader) {
    this.CMI_READONLY_SERVICER[key] = reader;
    return this;
  }

  createForVersion(config) {
    switch (this._versionAPIKey) {
      case SCORMMagicConstants.API_VERSIONS.SCORM_1_2:
        return this.createAPIForScorm12(config);
      default:
        return this.createAPIForScorm2004(config);
    }
  }

  createAPIForScorm12(config = {}) {
    if (this._versionAPIKey !== SCORMMagicConstants.API_VERSIONS.SCORM_1_2) {
      throw new Error('You have not set a version that is mapping to this API.');
    }

    this._assertHasAllFieldsRequired(config);

    this._API['LMSInitialize'] = this._getInitializeCb(config['initialize']);
    this._API['LMSFinish'] = config['finish'];

    // We can hide these internally so that you can get something to back the service with
    // and there is no need for the user to have to figure this out... we can service both as needed
    this._API['LMSGetValue'] = this._getValue;
    this._API['LMSSetValue'] = this._setValue;

    this._API['LMSCommit'] = this._commit;
    this._API['LMSGetLastError'] = config['getLastError'];
    this._API['LMSGetErrorString'] = config['getErrorString'];
    this._API['LMSGetDiagnostic'] = config['getDiagnostic'];

    this._commiter = config['commit'];

    return this;
  }

  createAPIForScorm2004(config = {}) {
    if (this._versionAPIKey !== SCORMMagicConstants.API_VERSIONS.SCORM_2004) {
      throw new Error('You have not set a version that is mapping to this API.');
    }

    this._assertHasAllFieldsRequired(config);

    this._API['Initialize'] = this._getInitializeCb(config['initialize']);
    this._API['Terminate'] = config['finish'];

    this._API['GetValue'] = this._getValue;
    this._API['SetValue'] = this._setValue;

    this._API['Commit'] = this._commit;
    this._API['GetLastError'] = config['getLastError'];
    this._API['GetErrorString'] = config['getErrorString'];
    this._API['GetDiagnostic'] = config['getDiagnostic'];

    this._commiter = config['commit'];

    return this;
  }

  _getInitializeCb(configFinishCb = () => {}) {
    return () => {
      this._updateDefaultValues();
      return configFinishCb();
    };
  }

  _assertHasAllFieldsRequired(config) {
    Object.keys(config).forEach((key) => {
      const value = config[key];
      if (!(Array.from(COMMON_REQUIRED).includes(key)) && _.isFunction(value)) {
        throw new Error('There was an issue setting up the API. Make sure all keys required are present and functions.');
      }
    });
  }

  _validateAndSetAPIKey(key) {
    if ((key !== SCORMMagicConstants.API_VERSIONS.SCORM_1_2) && (key !== SCORMMagicConstants.API_VERSIONS.SCORM_2004)) {
      throw new Error('This is not a valid API key');
    }
    this._versionAPIKey = key;
  }

  _getValue(key) {
    let data;
    if (this.CMI_READONLY_SERVICER[key] != null) {
      data = this._getReadOnlyValue(key);
    } else if (key.endsWith(COUNT_SUFFIX)) {
      data = this._getCountValue(key);
    } else {
      data = ((key in this._commitCache) ? this._commitCache[key] : this._getDefaultValue([key]));
    }

    // We can print out the value before we turn it into a string so we can see the tru evalue, before
    // we sent it to the LMS, so this is intentional
    logging.debug(`GetValue ("${ key }"): ${ data }`);

    return data != null ? data : (data = '');
  }

  _updateDefaultValues() {
    // if the user already viewed the module, we should resume from where they left off.
    if (!_.isEmpty(this._commitCache)) {
      this.defaultValueOverrides['cmi.core.entry'] = 'resume';
      this.defaultValueOverrides['cmi.entry'] = 'resume';
    }
  }

  _getDefaultValue(key) {
    return Object.assign({}, SCORM_DEFAULT_VALUES, this.defaultValueOverrides)[key];
  }

  _getReadOnlyValue(key) {
    const dataObj = {
      dataKey: this.CMI_READONLY_SERVICER[key]
    };
    return _.result(dataObj, 'dataKey');
  }

  _getCountValue(key) {
    // (Example) key = 'cmi.interactions.0.correct_responses._count'
    const countPrefix = key.substring(0, key.length - COUNT_SUFFIX.length);
    // (Example) countPrefix = 'cmi.interactions.0.correct_responses'
    const uniqueIndexSet = new Set();
    // (Example) uniqueIndexSet = []
    Object.keys(this._commitCache).forEach((commitCacheKey) => {
      // (Example) commitCacheKey = 'cmi.interactions.0.correct_responses.0.pattern'
      if (commitCacheKey.startsWith(countPrefix)) {
        const commitCacheKeySuffix = commitCacheKey.substring(countPrefix.length + 1);
        // (Example) commitCacheKeySuffix = '0.pattern'
        const index = commitCacheKeySuffix.substring(0, commitCacheKeySuffix.indexOf('.'));
        // (Example) index = '0'
        if (NumberHelpers.areAllCharsDigits(index)) {
          uniqueIndexSet.add(index);
          // (Example) uniqueIndexSet = ['0']
        }
      }
    });
    return uniqueIndexSet.size;
    // (Example) returns 1
  }

  _setValue(key, value) {
    this._commitCache[key] = value;
    this._interceptor(this._commitCache, key, value);

    return 'true';
  }

  _commit() {
    logging.debug(`Commiting: ${ JSON.stringify(this._commitCache) }`);
    this._commiter(this._commitCache);

    return 'true';
  }

  build() {
    // We inject a custom API here to just allow us to insulate against API changes, not elegant but minimal
    // hacks needed are nice.
    this._API['IsScormComplete'] = () => {
      return SCORMHelpers.isScormComplete(this._versionAPIKey, this._getValue);
    };

    return this._API;
  }
}

module.exports = (options) => {
  return new BuilderFragment(options);
};
