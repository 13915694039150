const Backbone = require('Backbone');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const {
  Form,
  Autocomplete
} = require('@training/widgets/UIKit');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

class ApiModalView extends Marionette.LayoutView {
  getTemplate() {
    return require('@training/apps/search/communitiesManagement/integration/ApiModalViewTemplate.html');
  }

  templateHelpers() {
    return {
      isNew: () => {
        return this.model.isNew();
      },
      ifNewClass: () => {
        return this.model.isNew() ? 'is-new' : '';
      },
      modalHeader: I18n.t('communities.apiKeys.dzApiKey')
    };
  }

  className() {
    return 'create-api-key-modal';
  }

  events() {
    return {
      'click .js-close-api-modal': 'destroy'
    };
  }

  ui() {
    return {
      formRegion: '.js-modal-form',
      autoCompleteRegion: '.js-autocomplete'
    };
  }

  initialize(options = {}) {
    super.initialize(options);
    this.communityId = options.communityId;
    this.userList = options.userList;

    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRender = this.onRender.bind(this);
  }

  onDelete() {
    window.app.layout.showSpinner(true);
    this.model.destroy({
      success: () => {
        window.app.layout.flash.success(I18n.t('communities.apiKeys.deleted'));
        this.collection.remove(this.model.get('id'));
        this.destroy();
      }
    }).then(() => {
      window.app.layout.hideSpinner();
    });
  }

  onSave() {
    const isNew = this.model.isNew();
    let errorMsg = this.form.commit();

    if (errorMsg) {
      window.app.layout.flash.error(errorMsg);
    }
    if (!this.model.get('user')) {
      errorMsg = I18n.t('communities.apiKeys.specifyUser');
      window.app.layout.flash.error(errorMsg);
    }
    if (!errorMsg) {
      this.model.save()
        .fail((xhr) => {
          const exception = AxonifyExceptionFactory.fromResponse(xhr);

          if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_DUPLICATE_ENTITY) {
            xhr.skipGlobalHandler = true;
            window.app.layout.flash.error(I18n.t('communities.apiKeys.duplicate'));
          }
        })
        .then(() => {
          if (isNew) {
            this.collection.add(this.model);
          } else {
            this.collection.get(this.model.get('id')).set(this.model.toJSON());
          }
          this.render();
          window.app.layout.flash.success(I18n.t(`communities.apiKeys.${ isNew ? 'created' : 'updated' }`));
        });
    }
  }

  createButton(text, callback, className) {
    return {
      type: 'customText',
      text,
      onClick: callback,
      className
    };
  }

  onRender() {
    const isNew = this.model.isNew();
    let buttons = this.createButton(I18n.t('communities.apiKeys.createApiKey'), this.onSave, 'button-create');
    if (!isNew) {
      buttons = [
        this.createButton(I18n.t('general.update'), this.onSave, 'update'),
        this.createButton(I18n.t('general.delete'), this.onDelete, 'delete')
      ];
    }

    this.actionBar.setActionBar({buttons});

    this.form = new Form({
      el: this.ui.formRegion,
      model: this.model
    });

    this.autoComplete = new Autocomplete({
      nameParam: 'fullname',
      minLength: 1,
      el: this.ui.autoCompleteRegion
    });

    this.listenTo(this.autoComplete, 'autocomplete', (searchTerm) => {
      return this.userList.search(searchTerm)
        .then((json) => {
          this.autoComplete.suggestions = new Backbone.Collection(json.results);
          return this.autoComplete.render();
        });
    });

    this.listenTo(this.autoComplete, 'search', (val, id) => {
      return this.model.set('user', this.userList.get(id));
    });

    this.listenTo(this.autoComplete, 'clearSearch', () => {
      return this.model.set('user', false);
    });
  }
}

module.exports = ApiModalView;
