// Module
var code = `<ul class="ax-font ax-font__p ax-font--no-margin">
  <li class="sessioninfo">
    <span class="sessioninfo-cell"><%- t('learningEvents.details.session.date') %></span>
    <span class="sessioninfo-cell"><%= sessionDate %></span>
  </li>
  <li class="sessioninfo">
    <span class="sessioninfo-cell"><%- t('learningEvents.details.session.time') %></span>
    <span class="sessioninfo-cell"><%= sessionStartTime %> - <%= sessionEndTime %></span>
  </li>
  <li class="sessioninfo">
    <span class="sessioninfo-cell"><%- t('learningEvents.details.session.language') %></span>
    <span class="sessioninfo-cell"><%= sessionLanguage %></span>
  </li>
  <li class="sessioninfo">
    <span class="sessioninfo-cell"><%- t('learningEvents.details.session.location') %></span>
    <span class="sessioninfo-cell"><%= sessionLocation %></span>
  </li>
  <% if (sessionRoom) { %>
  <li class="sessioninfo">
    <span class="sessioninfo-cell"><%- t('learningEvents.details.session.room') %></span>
    <span class="sessioninfo-cell"><%= sessionRoom %></span>
  </li>
  <% } %>
</ul>
`;
// Exports
module.exports = code;