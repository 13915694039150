const AssessmentType = require('@common/data/enums/AssessmentType');

const ObjectiveResultModel = require('@training/apps/training/models/ObjectiveResultModel');
const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');

module.exports = {
  fromTaskCompletionRecord(taskCompletionRecord) {
    const attributes = {
      isComplete: !taskCompletionRecord.doesRequireCompletion(),
      assessmentType: AssessmentType.TaskTraining
    };

    return new ObjectiveResultModel(attributes);
  },

  fromEventEnrollmentRecord(enrollmentCompletionRecord) {
    const attributes = {
      isComplete: enrollmentCompletionRecord.hasEnrollmentState(),
      assessmentType: AssessmentType.EventEnrollment
    };

    return new ObjectiveResultModel(attributes);
  },

  fromAssessmentTopicOption(assessmentTopicOption) {
    const attributes = {
      hasPassedLastAttempt: assessmentTopicOption.hasPassedLastAttempt(),
      isRetake: assessmentTopicOption.isRetake(),
      launchContext: assessmentTopicOption.get('launchContext'),
      assessmentType: assessmentTopicOption.getAssessmentType(),
      hasAttempt: assessmentTopicOption.hasAttempt(),
      isComplete: assessmentTopicOption.get('lastRelevantResult').completed
    };

    return new ObjectiveResultModel(attributes);
  },

  fromEvaluationForm(evaluationForm) {
    const evaluationStatus = evaluationForm.getEvaluationStatus();

    const attributes = {
      isComplete: evaluationStatus === EvaluationObjectiveStatus.PASSED,
      assessmentType: AssessmentType.EvaluationTraining
    };

    return new ObjectiveResultModel(attributes);
  }
};
