const logging = require('logging');
const Backbone = require('Backbone');
const Marionette = require('Marionette');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');

require('@common/libs/behaviors/card/Card');
const {
  INTERACTIVE,
  MEDIUM_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');

const EventStatus = require('@common/data/enums/EventStatus');
const KeyCode = require('@common/data/enums/KeyCode');

const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');

const ProfileIconHandler = require('@common/components/ProfileIconHandler');

const LearningEventEnrollment = require('@training/apps/training/models/LearningEventEnrollment');

class LearningEventNotificationView extends Marionette.ItemView {
  constructor(...args) {
    super(...args);
    this.render = this.render.bind(this);
    this.templateHelpers = this.templateHelpers.bind(this);
    this.sharedFlags = {
      isCheckingEnrollment: false
    };
  }

  getTemplate() {
    return require('@training/apps/training/templates/_learning_event_notified.html');
  }

  className() {
    return 'event-item learningevent-item';
  }

  tagName() {
    return 'li';
  }

  events() {
    return {
      click: 'onClickEvent',
      keydown: 'onKeydownEvent'
    };
  }

  templateHelpers() {
    return {
      itemTitle: this._getItemTitle(),
      itemDate: this._getItemDate(),
      itemLocation: this.item.scheduledLearningEvent.location,
      notificationText: this._getNotificationText(),
      itemCreatedDate: this._getCreatedText(),
      removedEvent: this._itemIsRetired(this.item),
      notificationAriaLabel: this._generateAriaLabel()
    };
  }

  behaviors() {
    return {
      Card: {
        target: '.learning-event-notification-item',
        modifierClasses: [INTERACTIVE, MEDIUM_PADDING]
      }
    };
  }

  _generateAriaLabel() {
    return this._getNotificationText() + ' ' + this._getItemTitle() + '. ' + this._getItemDate() + '. ' + I18n.t('accessibility.clickPrompt');
  }

  initialize(...args) {
    super.initialize(...args);
    this.currentUserLanguage = window.apps.auth.session.user.get('language');
    this.item = this.model.get('referencedItem');
  }

  onRender() {
    this._renderIcon(this.item.enrollerUser);

    return this;
  }

  onAttach() {
    this.$el.attr('aria-label', this._generateAriaLabel());
  }

  onClickEvent() {
    if (this.sharedFlags.isCheckingEnrollment || this._itemIsRetired(this.item) || !this.item) {
      return;
    }

    const scheduledEvent = this.item.scheduledLearningEvent;
    this.sharedFlags.isCheckingEnrollment = true;
    const enrollmentId = this.item.id;
    const scheduledEventId = scheduledEvent.id;
    const eventId = scheduledEvent.learningEvent.id;

    this._getEnrollment(eventId, scheduledEventId, enrollmentId).fail((xhr) => {
      this._showEnrollmentWarning(xhr);
    })
      .done(() => {
        Backbone.history.navigate(`#eventDetails/${ eventId }-${ scheduledEventId }-${ enrollmentId }`, {trigger: true});
      })
      .always(() => {
        this.sharedFlags.isCheckingEnrollment = false;
      });
  }

  onKeydownEvent(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      this.onClickEvent();
    }
  }

  _itemIsRetired(item) {
    const scheduledLearningEvent = item.scheduledLearningEvent;
    const learningEvent = scheduledLearningEvent.learningEvent;

    return (learningEvent.status === EventStatus.Retired || !learningEvent.isActive || !scheduledLearningEvent.isActive);
  }

  _getEnrollment(eventId, scheduledEventId, enrollmentId) {
    this.enrollment = new LearningEventEnrollment({}, {
      id: enrollmentId,
      eventId,
      scheduledEventId
    });

    return this.enrollment.fetch();
  }

  _renderIcon(user) {
    let profileIcon;

    if ((user != null) && (this.model.get('type') !== 'EnrollmentReminder')) {
      profileIcon = new ProfileIconHandler({
        user,
        profileElement: this.$('.name-logo')
      });
    }

    return profileIcon;
  }

  _getItemTitle() {
    let { localizableName } = this.item.scheduledLearningEvent.learningEvent;
    localizableName = new LocalizableString(localizableName);
    return localizableName.getValueForLanguage(this.currentUserLanguage);
  }

  _getItemDate() {
    return (this.item.scheduledLearningEvent.startDate != null)
      ? dateHelpers.convertDateFormatToDefaultDateTime(this.item.scheduledLearningEvent.startDate) : undefined;
  }

  _getCreatedText() {
    const createDate = this.model.get('createDate');
    if (createDate) {
      return dateHelpers.convertDateFormatLongDateWithStrings(createDate);
    }
    return '';
  }

  _getNotificationText() {
    let statusText = '';

    switch (this.model.get('type')) {
      case 'Enrolled': case 'MovedFromWaitingList':
        statusText = I18n.t('learningEvents.notification.enrolledIn', {
          userFullName: (this.item.enrollerUser != null ? this.item.enrollerUser.salutationName : undefined)
        });
        break;
      case 'AddedToWaitingList':
        statusText = I18n.t('learningEvents.notification.addedToWaitingList', {
          userFullName: (this.item.enrollerUser != null ? this.item.enrollerUser.salutationName : undefined)
        });
        break;
      case 'EnrollmentReminder': {
        const eventTime = this.item.scheduledLearningEvent.startDate;
        const rightNow = dateHelpers.getTime();
        const isEventOver = dateHelpers.compareDates(eventTime, 'isAfter', rightNow);

        if (isEventOver) {
          statusText = I18n.t('learningEvents.notification.starts', {
            relativeDateString: dateHelpers.timeFromEvent(eventTime)
          });
        } else {
          statusText = I18n.t('learningEvents.notification.ended', {
            relativeDateString: dateHelpers.timeFromEvent(eventTime)
          });
        }
        break;
      }
      default:
        statusText = I18n.t('learningEvents.notification.updatedEvent', {
          userFullName: (this.item.enrollerUser != null ? this.item.enrollerUser.salutationName : undefined)
        });
        logging.error(`Event Notification Id ${ this.model.get('id') } has an unhandled status.`);
    }
    return statusText;
  }

  _showEnrollmentWarning(xhr) {
    // handles an edge case where the enrollment has been deleted since the notification creation time.
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();

    if ([AxonifyExceptionCode.EVENT_MGMT_WOULD_EXCEED_CAPACITY,
      AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY,
      AxonifyExceptionCode.EVENT_MGMT_USER_NOT_QUALIFIED].includes(errCode)) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t(`learningEvents.details.errors.${ errCode }`));
    }
  }
}

module.exports = LearningEventNotificationView;
