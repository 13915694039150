const {
  LayoutView,
  CollectionView
} = require('Marionette');
const $os = require('detectOS');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const PageShareItemView = require('@common/components/discover/views/PageShareItemView');

class CommunitySelectorView extends LayoutView {
  initialize(options = {}) {
    ({
      currentUser: this.currentUser,
      authChannel: this.authChannel,
      glChannel: this.glChannel,
      pageSharedCommunitiesList: this.pageSharedCommunitiesList
    } = options);

    if (this.pageSharedCommunitiesList) {
      this.listenTo(this.pageSharedCommunitiesList, 'sync', this._updateSharedCommunitiesList);
    }
  }

  getTemplate() {
    return require('@common/components/discover/edit/CommunitySelectorViewTemplate.html');
  }

  ui() {
    return {
      communityForm: '.js-community-form',
      sharedCommunitiesWrapper: '.js-shared-communities',
      sharedCommunities: '.js-metadata-shared-communities'
    };
  }

  templateHelpers() {
    return {
      canChangeRecommendable: this._canChangeRecommendable.bind(this)
    };
  }

  onRender() {
    this.communityForm = new Form({
      el: this.ui.communityForm,
      model: this.model,
      context: {
        switchOptions: {
          enabled: true,
          ariaLabel: I18n.t('discover.metadata.recommendations.subtitle')
        }
      }
    });
  }

  commit() {
    const errors = [];
    if (this.model.get('community') == null || this.model.get('community').get('id') == null) {
      errors.push(I18n.t('errors.Article.required.community'));
    }

    return errors;
  }

  _canChangeRecommendable() {
    return $os.desktop && this.currentUser.isAdminUser();
  }

  _updateSharedCommunitiesList() {
    if (this.pageSharedCommunitiesList.length) {
      this.ui.sharedCommunitiesWrapper.removeClass('hidden');
      const pageShareListView = new CollectionView({
        childView: PageShareItemView,
        childViewOptions: {
          authChannel: this.authChannel,
          glChannel: this.glChannel,
          viewOnly: true
        },
        collection: this.pageSharedCommunitiesList,
        el: this.ui.sharedCommunities
      });
      pageShareListView.render();
    }
  }
}

module.exports = CommunitySelectorView;
