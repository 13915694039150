import {
  Paper as MuiPaper,
  styled,
  type PaperTypeMap as MuiPaperTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { merge } from 'lodash';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';


type AxPaperCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * The variant to use.
   * @default 'elevation'
   */
  variant?: 'elevation' | 'outlined' | 'flat';
};

/**
 * Remove `elevation` and `variant` from MuiPaperTypeMap
 * `elevation`: we want to keep a consistent elevation style
 * `variant`: removes the `variant` that comes from PaperPropsVariantOverrides, as AxCard uses it to define its own set of variants
 */
type PropsToRemove = 'elevation' | 'variant';

export type AxPaperTypeMap<P = object, D extends React.ElementType = 'div'> = {
  props: P & Omit<MuiPaperTypeMap['props'], PropsToRemove> & AxPaperCustomProps;
  defaultComponent: D;
};

// Re-adds our `variant` to `AxPaperTypeMap`
export type AxPaperProps<
  D extends ElementType = AxPaperTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxPaperTypeMap<P & AxPaperCustomProps, D>, D>;

const styledOptions = {
  name: 'AxPaper'
};

const StyledAxPaper = styled(MuiPaper, styledOptions)<AxPaperProps>((props) => {
  const {
    theme,
    variant
  } = props;

  const styles = {
    '&.MuiPaper-rounded': {
      borderRadius: theme.uiKit.borderRadiusM
    }
  };
  
  return merge(styles,
    (variant === 'flat' && {
      boxShadow: 'none'
    }),
    (variant === 'elevation' && {
      boxShadow: theme.uiKit.boxShadowS
    }));
});

/**
 * Utility component to create a elevated surfaces. <br /><br />
 * 🚫 **Dont's**:
 * -  → use it as replacement for `AxCard` as the two components could evolve differently.
 * -  → set different `box-shadow` styles, otherwise the component will lose its consistency.
 */
export const AxPaper: MuiOverridableComponent<AxPaperTypeMap> = forwardRef(({
  children,
  variant = 'elevation',
  ...otherProps
}: AxPaperProps, ref: Ref<HTMLDivElement>) => {
  const PaperProps: AxPaperProps = {
    children,
    variant,
    ...otherProps
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledAxPaper { ...PaperProps } ref={ ref }>
      { children }
    </StyledAxPaper>
  );
});

export default AxPaper;
