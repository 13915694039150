const { has } = require('underscore');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const RecognitionPinDefinitionFactory = require('@training/apps/home/performance/recognitions/RecognitionPinsDefinitionFactory');
const PerformanceCarouselDefinitionFactory = require('@training/apps/home/performance/PerformanceCarouselDefinitionFactory');

const {
  RECOGNITION_PINS,
  REPORT_CARD,
  ACHIEVEMENTS
} = require('@common/data/enums/HubTileIdentifiers').default;

module.exports = (availableTiles, topicProgressList, session) => {
  const isRecognitionPinsCardEnabled = has(availableTiles, RECOGNITION_PINS);
  const areAchievementReportCardEnabled = has(availableTiles, ACHIEVEMENTS) || has(availableTiles, REPORT_CARD);

  return () => {
    return {
      ViewControllerClass: StackedLayoutViewController,
      viewDefinition: {
        className: 'hub-performance__wrapper',
        childViewOptions: {
          className: 'hub-performance__items-region'
        }
      },
      childControllers: [
        isRecognitionPinsCardEnabled ? RecognitionPinDefinitionFactory(availableTiles) : null,
        areAchievementReportCardEnabled ? PerformanceCarouselDefinitionFactory(availableTiles, topicProgressList, session) : null
      ]
    };
  };
};
