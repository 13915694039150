const { extend } = require('underscore');

const ContinueButtonFactory = require('@training/apps/training/views/activities/ContinueButtonFactory');

const ActivityType = require('@common/data/enums/ActivityType');
const AssessmentType = require('@common/data/enums/AssessmentType');

module.exports = ({
  trainingSession,
  currentUser
} = {}) => {
  return (activity) => {
    const currentAssessment = trainingSession.getCurrentAssessment();

    const options = { activity };

    if (activity.isTrainingContentActivity()) {
      extend(options, {
        isExtraTraining: currentAssessment.isExtraTraining(),
        isRetake: currentAssessment.isRetake(),
        continueButtonFactory: ContinueButtonFactory(currentAssessment.activities)
      });
    }

    if (activity.get('type') === ActivityType.VIDEO_MODULE) {
      extend(options, {
        storageNamespace: 'activity',
        storageKey: `${ ActivityType.VIDEO_MODULE }-${ currentUser.id }`,
        allowEmptyStorageEntries: true
      });
    }

    if (currentAssessment.get('type') === AssessmentType.FormalExamTraining) {
      extend(options, {
        hideAnswersAndReason: currentAssessment.get('hideAnswersAndReason'),
        hideConfidenceIndex: currentAssessment.get('hideConfidenceIndex')
      } );
    }

    return options;
  };
};
