import {
  Step as MuiStep,
  styled,
  type StepTypeMap as MuiStepTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxStepCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxStepTypeMap<P = object, D extends React.ElementType = 'div'> = MuiStepTypeMap<P & AxStepCustomProps, D>;

export type AxStepProps<
  D extends ElementType = AxStepTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxStepTypeMap<P & AxStepCustomProps, D>, D>;

const styledOptions = {
  name: 'AxStep'
};

const StyledAxStep = styled(MuiStep, styledOptions)<AxStepProps>(() => {
  return {};
});

/**
 * A wrapper around MUI `Step` that applies our custom styles.
 *
 * ### Links
 * - [MUI Step API](https://mui.com/material-ui/api/step/)
 */
export const AxStep: MuiOverridableComponent<AxStepTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxStepProps, ref: Ref<HTMLDivElement>) => {
  
  const stepProps: AxStepProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxStep { ...stepProps } ref={ ref } >
      { children }
    </StyledAxStep>
  );
});

export default AxStep;
