const Backbone = require('Backbone');
const _ = require('underscore');

const I18n = require('@common/libs/I18n');

class FeedbackType extends Backbone.Model {
  constructor(arg) {
    let attributes = {};
    if (_.isString(arg)) {
      attributes = { feedbackType: arg.toString() };
    }
    super(attributes, {});
  }

  toOption() {
    const feedbackType = this.get('feedbackType');
    return {
      id: feedbackType,
      value: I18n.t(`feedback.type.${ feedbackType }`)
    };
  }
}
module.exports = FeedbackType;
