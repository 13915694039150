const AdjustableModalView = require('@training/apps/main/views/AdjustableModalView');
const FlexibleActionBar = require('@training/apps/main/views/FlexibleActionBar');

class InGameQuestionView extends AdjustableModalView {

  constructor(options = {}) {
    if (!options.ActionBarClass) {
      options.ActionBarClass = FlexibleActionBar;
    }

    super(options);
  }

  id() {
    return 'modalview';
  }

  className() {
    return 'in-game-question-modal';
  }

  viewDidAppear() {
    // Focus the question text for accessibility purposes
    this.$('.questionask').attr('tabindex', -1)
      .trigger('focus');
    super.viewDidAppear();
  }
}

module.exports = InGameQuestionView;
