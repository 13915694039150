const _ = require('underscore');

class SelectedNodeTreeSerializer {

  constructor(options = {}) {
    this._isMultiSelect = options.isMultiSelected != null ? options.isMultiSelected : false;
  }

  // Takes a form value and serializes the state into the TreeEditor
  deserialize(formValue) {
    const keys = this._isMultiSelect && ((formValue != null ? formValue.ids : undefined) != null)
      ? formValue.ids
      : ((formValue != null ? formValue.id : undefined) != null)
        ? [formValue.id]
        : (formValue != null)
          ? [].concat(formValue)
          : [];

    return _.map(_.compact(keys), (key) => {
      return `${ key }`;
    });
  }

  // Used for piping the internal check state and pipes it out into an acceptable
  // format
  serialize(treeEditor) {
    const selectedNodes = treeEditor.getSelectedNodes();
    if (this._isMultiSelect) {
      const ids = _.map(selectedNodes, (node) => {
        return parseInt(node.key, 10);
      });
      return {ids};
    }
    return {id: (parseInt(selectedNodes[0] != null ? selectedNodes[0].key : undefined, 10)) || null};

  }
}

module.exports = SelectedNodeTreeSerializer;
