import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import {
  AxBox,
  AxCircularProgress,
  AxTag,
  AxTextField,
  type AxTextFieldProps
} from '@common/modules/react/themes/components';
import {
  Autocomplete as MuiAutocomplete,
  styled,
  useControlled,
  type AutocompleteRenderInputParams,
  type AutocompleteProps as MuiAutocompleteProps
} from '@mui/material';
import {
  filter,
  isArray,
  isObject,
  isString,
  omit
} from 'lodash';
import {
  forwardRef,
  useRef,
  type Ref
} from 'react';

// These are repeated here from globals.d.ts otherwise storybook doesn't generate the controls tabled properly.
type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;
type Override<T extends IndexableObject<object>, R extends IndexableObject<object>> = DistributiveOmit<T, Extract<keyof R, keyof T>> & R;

type AxBaseAutocompleteProps<T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = Override<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, {
  renderInput?: (params: AutocompleteRenderInputParams) => JSX.Element
  size?: 'medium' | 'large'
}>;

const StyledAxAutocomplete = styled(MuiAutocomplete, {
  name: 'AxAutocomplete',
  shouldForwardProp: (prop: string) => {
    return !['qaClassName', 'textFieldProps'].includes(prop);
  }
})(({ theme }) => {
  return {
    '.MuiInputBase-root': {
      padding: 0,
      '.MuiAutocomplete-endAdornment': {
        position: 'static',
        transform: 'none',
        display: 'flex',
        ...theme.mixins.useGapPolyfillStyles('row', theme.uiKit.textFieldPaddingYM)
      }
    },
    '.MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: `${ theme.uiKit.textFieldPaddingYM } ${ theme.uiKit.textFieldPaddingYL }`,
      minWidth: '10rem'
    },
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
      paddingRight: theme.uiKit.textFieldPaddingYL
    },
    '.MuiTextField-sizeLarge .MuiInputBase-input.MuiAutocomplete-input': {
      paddingBottom: theme.uiKit.textFieldPaddingYL,
      paddingTop: theme.uiKit.textFieldPaddingYL
    }
  };
}) as <T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AxBaseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, ref: Ref<HTMLDivElement>) => JSX.Element;


export type AxAutocompleteProps<T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = Override<AxBaseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, {
  /**
   * A class name used for quality assurance testing that can be applied automatically to the appropriate sub component that needs it.
   */
  qaClassName: `qa-${ string }`

  /**
   * Props applied to the default AxTextField in the default renderInput implementation.
   */
  textFieldProps?: DistributiveOmit<AxTextFieldProps, 'qaClassName'>
}>;

export const AxAutocomplete = forwardRef(<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
    slotProps,
    qaClassName,
    textFieldProps,
    value,
    defaultValue,
    multiple,
    size,
    loading,
    ...otherProps
  }: AxAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, ref: Ref<HTMLDivElement>): JSX.Element => {

  const {
    current: isControlled
  } = useRef(value !== undefined);

  const [valueState, setValueState] = useControlled({
    name: 'AxAutocomplete',
    controlled: value,
    default: defaultValue
  });

  const renderInput = otherProps.renderInput ?? ((props) => {
    const fieldProps: AxTextFieldProps = Object.assign(props, textFieldProps, {
      size: textFieldProps?.size ?? size ?? 'medium',
      qaClassName: `${ qaClassName }-input`,
      InputLabelProps: {
        ...props.InputLabelProps,
        ...textFieldProps?.InputLabelProps
      },
      InputProps: {
        ...props.InputProps,
        ...textFieldProps?.InputProps,
        ...(loading && {
          endAdornment: (
            <>
              { loading ? <AxCircularProgress color='inherit' size={ 20 } /> : null }
              { props.InputProps.endAdornment }
            </>
          )
        })
      },
      inputProps: {
        ...props.inputProps,
        ...textFieldProps?.inputProps
      }
    });

    return <AxTextField { ...fieldProps } />;
  });

  const getOptionLabel = otherProps.getOptionLabel ?? ((option) => {
    if (isString(option)) {
      return option as string;
    } else if (isObject(option) && 'label' in option) {
      return option.label as string;
    }

    return '';
  });

  const getOptionDisabled = otherProps.getOptionDisabled ?? ((option) => {
    return isObject(option) && 'disabled' in option && option.disabled as boolean;
  });

  const renderTags = otherProps.renderTags ?? ((tagValue, getTagProps) => {
    const tags = tagValue.map((option, index) => {
      const disabled = getOptionDisabled(option);
      const axTagProps = getTagProps({ index });

      return (
        <AxTag
          label={ getOptionLabel(option) }
          variant='autocomplete'
          { ...axTagProps }
          disabled={ disabled }
          key={ axTagProps.key }
          onDelete={ disabled ? undefined : axTagProps.onDelete }
          className={ joinClassNames(`${ qaClassName }-tag`, axTagProps.className) }
        />
      );
    });

    return <AxBox>
      { tags }
    </AxBox>;
  });

  const onChange: AxAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['onChange'] = multiple ? ((e, newValue, reason, details) => {
    if (reason === 'removeOption' && details && getOptionDisabled(details.option)) {
      return;
    }

    type Value = typeof newValue;
    let newVal = newValue;

    if (reason === 'clear') {
      if (isControlled && isArray(value)) {
        newVal = filter(value, getOptionDisabled) as Value;
      } else if (isArray(valueState)) {
        newVal = filter(valueState, getOptionDisabled) as Value;
      }
    }

    if (isControlled) {
      otherProps.onChange?.(e, newVal ?? newValue, reason, details);
    } else {
      setValueState(newVal ?? newValue);
    }
  }) : otherProps.onChange;

  const AutocompleteProps = {
    className: joinClassNames(qaClassName, otherProps.className),
    renderInput,
    getOptionLabel,
    getOptionDisabled,
    renderTags,
    multiple,
    value: valueState,
    size,
    onChange,
    loading,
    slotProps: {
      ...slotProps,
      popper: {
        ...slotProps?.popper,
        className: joinClassNames(`${ qaClassName }-dropdown`, slotProps?.popper?.className),
        sx: {
          ...slotProps?.popper?.sx,
          '.MuiAutocomplete-listbox': {
            opacity: loading ? 0.5 : 1
          }
        },
        // Moves the dropdown down slightly to avoid cutting off the blue focus border
        modifiers: [
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 2]
            }
          }
        ]
      }
    },
    ...omit(otherProps, 'className', 'renderInput', 'getOptionLabel', 'getOptionDisabled', 'renderTags', 'onChange')
  };

  return (
    <StyledAxAutocomplete { ...AutocompleteProps } ref={ ref }/>
  );
});

export default AxAutocomplete;
