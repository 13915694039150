const _ = require('underscore');
const Backbone = require('Backbone');

class FlowController {
  // parentProcessor is used for the finishedCallback, if the current processor
  // is the root of the hierarchy then parent processor will be null/nothing
  constructor(parentProcessor) {
    this.parentProcessor = parentProcessor;
  }

  // The implementations will handle the control flow.
  processSequenceFlow() {
    throw new Error('Need to define `processSequenceFlow` in the subclass');
  }

  // The controller will call this on itself when it's done with everything that
  // it cares about. Its implementation should call 'super' at the end of the
  // method.
  finishedProcessing() {
    if (this.parentProcessor) {
      this.parentProcessor.finishedProcessing();
    }
  }
}

_.extend(FlowController.prototype, Backbone.Events);

module.exports = FlowController;
