const _ = require('underscore');
const Backbone = require('Backbone');
const Marionette = require('Marionette');

const PdfMediaPreviewerPredicateMatcher = require('@common/components/mediaFilePreview/PdfMediaPreviewerPredicateMatcher');
const MediaPreviewerFactoryPredicateBuilder = require('@common/components/mediaFilePreview/MediaPreviewerFactoryPredicateBuilder');

const MODAL_CLOSE_BUTTON_HEIGHT = 40;

require('@common/components/mediaPreview/file/FilePreview.less');

class FilePreview extends Marionette.LayoutView {

  className() {
    return 'file-preview fit-parent';
  }

  getTemplate() {
    return '<div class="file-wrapper"></div>';
  }

  ui() {
    return {
      file: '.file-wrapper'
    };
  }

  events() {
    return {
      click: (e) => {
        return e.stopPropagation();
      }
    };
  }

  regions() {
    return {
      file: '.file-wrapper'
    };
  }

  initialize() {
    this._maxDimensions = this._setupMaxDimensionState(this.getOption('maxDimensions'));
  }

  onAttach() {
    this._updateWrapperMaxDimensions(this.ui.file);

    this.model.fetch({
      showSpinner: false
    }).then(() => {
      if (this.isDestroyed) {
        return;
      }

      const fakeMedia = new Backbone.Model({
        originalFile: this.model.toJSON()
      });

      const preview = this._getPreviewViewForAttachment(fakeMedia, {
        model: fakeMedia
      });
      this.showChildView('file', preview);

      this.triggerMethod('load');
    });
  }

  onDestroy() {
    this.model.abortXHR();
  }

  onMaxDimensionsChange() {
    if (!this.isRendered) {
      return;
    }

    this._updateWrapperMaxDimensions(this.ui.file);
  }

  _setupMaxDimensionState(maxDimensions = new Backbone.Model()) {
    this.listenTo(maxDimensions, 'change', this.onMaxDimensionsChange);
    return maxDimensions;
  }

  _updateWrapperMaxDimensions($file) {
    const newDimensions = {};
    if (this._maxDimensions.has('maxWidth')) {
      _.extend(newDimensions, {
        width: this._maxDimensions.get('maxWidth')
      });
    }
    if (this._maxDimensions.has('maxHeight')) {
      _.extend(newDimensions, {
        height: Math.max(0, this._maxDimensions.get('maxHeight') - (MODAL_CLOSE_BUTTON_HEIGHT * 2))
      });
    }

    $file.css(newDimensions);
  }

  _getPreviewViewForAttachment(attachmentMedia, options = {}) {
    const pdfMatcher = PdfMediaPreviewerPredicateMatcher(options);

    const matcher = new MediaPreviewerFactoryPredicateBuilder()
      .withMatcher(pdfMatcher)
      .build();

    return matcher(attachmentMedia);
  }
}

module.exports = FilePreview;
