const CSSInjector = require('@common/services/cssLoader/CSSInjector');
const CSSInjectorPackageFactory = require('@common/services/cssLoader/CSSInjectorPackageFactory');

class NullCSSInjectorPackageFactory extends CSSInjectorPackageFactory {
  constructor() {
    super(CSSInjector.NullPackage.key);
  }

  create() {
    return CSSInjector.NullPackage;
  }
}

module.exports = NullCSSInjectorPackageFactory;
