const SCORMMagicConstants = require('./SCORMMagicConstants');

// This class is responsible for parsing data from a SCORM Manifest and serving it back up but that's it.
// It has no responsibility for rendering it.
class SCORMManifestParser {
  _getLaunchData($manifestXML) {
    return $manifestXML.find('manifest > organizations adlcp\\:datafromlms').text();
  }

  _getScormVersion($manifestXML) {
    let localVersion = $manifestXML.find('manifest > metadata schemaversion').text();

    // To maintain compatability, we can assume SCORM 1.2 if we cannot figure it out
    if (!localVersion) {
      localVersion = '1.2';
    }

    // There are 3 versions, so we can go ahead and just try them all in rote if we can
    // https://support.scorm.com/hc/en-us/articles/206167326-What-schemaversion-should-I-list-in-my-imsmanifest-file-
    switch (localVersion.trim()) {
      case SCORMMagicConstants.MANIFEST_VERSIONS.SCORM_1_2:
        return SCORMMagicConstants.API_VERSIONS.SCORM_1_2;
      case SCORMMagicConstants.MANIFEST_VERSIONS.SCORM_2004_ORIGINAL:
        return SCORMMagicConstants.API_VERSIONS.SCORM_2004;
      case SCORMMagicConstants.MANIFEST_VERSIONS.SCORM_2004_3_EDITION:
      case SCORMMagicConstants.MANIFEST_VERSIONS.SCORM_2004_4_EDITION:
        return SCORMMagicConstants.API_VERSIONS.SCORM_2004;
      default:
      // If we don't know, we should hard crash and let anyone else deal with it as needed
        throw new Error('The version of SCORM you are trying to play with is unknown. Contact Axonify.');
    }
  }

  _mapScos($manifestXML, resourceURL) {
    const resourcesXML = $manifestXML.find('resources resource');
    const resourceMap = {};

    for (const resource of Array.from(resourcesXML)) {
      const $resource = $(resource);

      // There are some values that are lowercase in these cases -- especially a lot of SCORM 2004 authoring
      // tools, so we will check for both forms. Technically, XML is case-sensitive and adlcp:scormtype is proper
      // but we will support both since there is more than one module that will refuse to play otherwise...
      let resourceValue
        = resource.attributes['adlcp:scormtype'] != null ? resource.attributes['adlcp:scormtype'].value : undefined;
      if (resourceValue == null) {
        resourceValue
          = resource.attributes['adlcp:scormType'] != null ? resource.attributes['adlcp:scormType'].value : undefined;
      }

      const href = $resource.attr('href');

      // launchable resources must have href
      if ((resourceValue !== 'sco' && resourceValue !== 'asset') || href == null) {
        continue;
      }

      const identifier = $resource.attr('identifier');
      const dirPrefix = $resource.attr('xml:base');

      resourceMap[identifier] = {
        href: resourceURL + (dirPrefix || '') + href
      };
    }

    // When there are multiple sub modules (Multi SCORM), need to get each module title
    if (Object.keys(resourceMap).length > 1) {
      const itemXML = $manifestXML.find('organizations organization item');
      for (const item of Array.from(itemXML)) {
        const $item = $(item);

        // identifierref is used to link between a resource and organization item in the SCORM xml
        const identifierref = $item.attr('identifierref');
        // identifier is the organization item identifier, this is what the server sends us
        const identifier = $item.attr('identifier');
        const title = $item.find('title').first()
          .text();
        if (resourceMap[identifierref] != null && title != null) {
          resourceMap[identifierref]['title'] = title;
        }
        if (resourceMap[identifierref] != null && identifier != null) {
          resourceMap[identifierref]['serverIdentifier'] = identifier;
        }
      }
    }

    return { scoMap: resourceMap };
  }

  parse($manifest, manifestURL = '') {
    const resourceURL = manifestURL.substring(
      0,
      manifestURL.indexOf('imsmanifest.xml')
    );

    const launchData = this._getLaunchData($manifest);
    const { scoMap } = this._mapScos($manifest, resourceURL);
    return {
      scormVersion: this._getScormVersion($manifest),
      scoMap,
      launchData
    };
  }
}

module.exports = SCORMManifestParser;
