const { Model } = require('Backbone');

class PageableModel extends Model {

  getFirstPage() {
    this.get('pageableList').getFirstPage();
  }

  getLastPage() {
    this.get('pageableList').getLastPage();
  }

  getNextPage() {
    this.get('pageableList').getNextPage();
  }

  getPreviousPage() {
    this.get('pageableList').getPreviousPage();
  }

  getPage(pageNum, options = {}) {
    this.get('pageableList').getPage(pageNum, options);
  }

  getCurrentPage() {
    return this.get('pageableList').state.currentPage;
  }
}

module.exports = PageableModel;
