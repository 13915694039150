const QuestionHelpers = {
  isWithinRegion(x, y, region) {
    const {
      left,
      top,
      width,
      height
    } = region;
    return x >= left && x <= (left + width) && y >= top && y <= (top + height);
  }
};

module.exports = QuestionHelpers;
