const Backbone = require('Backbone');

class FormalExamResult extends Backbone.Model {
  apiEndpoint() {
    return '/formalExamProgramResult';
  }

  defaults() {
    return {pointsEarned: 0};
  }

  constructor(attributes = {}, options = {}) {
    super(attributes, options);
    this.idAttribute = 'programId';
  }

  parse(response = {}) {
    return response.result || response;
  }
}

module.exports = FormalExamResult;
