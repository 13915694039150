const Backbone = require('Backbone');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const PageViewLayout = require('@common/components/discover/views/PageViewLayout');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');
const ArticlePrintHelpers = require('@common/components/discover/factories/ArticlePrintHelpers');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const InsightsUrlHelper = require('@training/apps/insights/InsightsUrlHelper');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ArticleReportModal = require('@training/apps/articles/modals/ArticleReportModal');
const PageReportTypeCollection = require('@common/data/collections/PageReportTypeCollection');
const PageReportTypes = require('@common/data/enums/PageReportTypes');
const PageReport = require('@common/data/models/PageReport');
const LockController = require('@training/apps/articles/LockController');
const AxBreakpointEnum = require('@common/data/enums/AxBreakpointEnum').default;

const ArticleShareModal = require('@training/apps/articles/modals/ArticleShareModal');
const ShareableCommunitiesList = require('@common/components/discover/collections/ShareableCommunitiesList');
const {
  handlePdfClicked,
  handleExternalLinkMedia
} = require('@training/apps/articles/ExternalLinkMediaHandler');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/scrollable/Scrollable');

const MOBILE_MAX_WIDTH = AxBreakpointEnum.MOBILE_MAX;

class ArticleDetailsPageView extends PageViewLayout {
  initialize(options = {}) {
    ({
      userId: this.userId,
      pageSharedCommunitiesList: this.pageSharedCommunitiesList
    } = options);
    this.glChannel = Backbone.Wreqr.radio.channel('global');

    this._saveModel = this._saveModel.bind(this);

    this.lockController = new LockController({model: this.model});
    this.listenTo(this.lockController, 'lock:error', this.genericErrorHanlder);
  }

  className() {
    return 'article-details';
  }

  getTemplate() {
    return `
      <div class="breadcrumb-path" role="navigation">
        <div class="header-breadcrumbs"></div>
        <div class="article-details__header--sub article-details__header--sub-mobile"></div>
      </div>
      <div class="article-details__header page-header page-header--fixed">
        <div class="article-details__header--title"></div>
        <div class="article-details__header--sub article-details__header--sub-desktop"></div>
      </div>
      <div class="article-details__content">
        <div class="article-details__region article-details__region--content scrollable auto vertical"></div>
        <div class="article-details__region article-details__region--metadata scrollable auto vertical"></div>
      </div>
    `;
  }

  regions() {
    return {
      breadcrumbRegion: '.header-breadcrumbs',
      titleRegion: '.article-details__header--title',
      mobileSubRegion: '.article-details__header--sub-mobile',
      desktopSubRegion: '.article-details__header--sub-desktop',
      contentRegion: '.article-details__region--content',
      metadataRegion: '.article-details__region--metadata'
    };
  }

  ui() {
    return {
      contentRow: '.article-details__content',
      content: '.article-details__region--content',
      metadata: '.article-details__region--metadata',
      titleContainer: '.article-details__header',
      breadcrumbContainer: '.breadcrumb-path'
    };
  }

  childEvents() {
    return {
      'page:print': this._printPage,
      'page:report': this._reportPage,
      'page:delete': this._deletePage,
      'page:edit': this._editPage,
      'page:insights': this._pageInsights,
      'page:publish': this._publishPage,
      'page:share': this._sharePage,
      'page:history': this._showHistory,
      'click:more': this.toggleMetadata,
      'click:pdfAttachment': this._onPdfAttachmentClick,
      'link:clicked': this._onContentClickLink
    };
  }

  onRender() {
    window.app.layout.togglePageHeader(false);
  }

  onAttach() {
    this.media = this.model.get('media');
    this.parent = this.$el;
    const winWidth = BrowserHelpers.windowDimensions().width;

    if (!_.isEmpty(this.media)) {
      this.articleImage = ImageViewerFactory.createViewerInstance({
        media: this.media,
        $el: this.parent.find('.article-details__region--image')
      });

      if (this.articleImage != null) {
        this.listenTo(this.articleImage, 'image:loaded', () => {
          this.trigger('image:loaded');
        });
        this.articleImage.render();

        this.onResize = () => {
          return this.articleImage.resize();
        };
      }
    }

    if (winWidth < MOBILE_MAX_WIDTH) {
      this.metadataOpen = false;
      this.ui.metadata.css({[this._cssDirection]: ''});
    }

    ViewHelpers.showBackButtonWithReset({ view: this });
  }

  onResize(winHeight, winWidth) {
    this.containerWidth = this.$el.width();
    this.metaDataContainerWidth = this.ui.metadata.outerWidth();

    // This is to get the content sizing to work - there may be a better way.
    const adjustableContainerHeight = $('.adjustable-container').outerHeight();
    const footerHeight = $('footer').outerHeight();
    const titleHeight = this.ui.titleContainer.outerHeight();
    const breadcrumbHeight = this.ui.breadcrumbContainer.outerHeight();

    // At the MAX_WIDTH value the metadata becomes permanently visible
    // so we clear out any transforms and make sure the two side-by-side
    // elements fit properly.
    if (winWidth > MOBILE_MAX_WIDTH) {
      const contentWidth = this.containerWidth - this.metaDataContainerWidth;
      this.metadataOpen = true;
      if (!this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', true);
      }
      this.metadataAlwaysOpen = true;
      this.ui.content.css({width: `${ contentWidth }px`});
      this.ui.metadata.css({
        [this._cssDirection]: `${ contentWidth }px`,
        transform: ''
      });

      // Also set the height of the content
      const contentHeight = adjustableContainerHeight - titleHeight - footerHeight - breadcrumbHeight - 2; // The -2 is for borders
      this.ui.contentRow.height(contentHeight);

    } else {
      if (this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', false);
      }
      this.metadataAlwaysOpen = false;
      this.ui.content.css({width: ''});

      // Also set the height of the content
      const contentHeight = adjustableContainerHeight - titleHeight - breadcrumbHeight - 2; // The -2 is for borders
      this.ui.contentRow.height(contentHeight);
    }
  }

  onBack() {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/type-all/1/`, {
      trigger: true
    });
  }

  _sharePage() {
    const bundleId = this.model.get('bundleId');
    this.shareableCommunitiesList = new ShareableCommunitiesList(null, {
      bundleId
    });

    this.shareableCommunitiesList.fetch({
      error: (model, xhr) => {
        this.genericErrorHanlder(xhr);
      }
    }).done(() => {
      const modalView = new AccessibleModalView({
        id: 'modalview',
        className: 'modal article-share-modal'
      });

      const modalChildView = new ArticleShareModal({
        shareableCommunitiesList: this.shareableCommunitiesList
      });

      window.app.layout.presentModal(modalView, { closeClick: false });
      modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

      this.listenToOnce(modalChildView, 'destroy', () => {
        this.pageSharedCommunitiesList.fetch({
          error: (model, xhr) => {
            this.genericErrorHanlder(xhr);
          }
        }).done(() => {
          window.app.layout.dismissModal();
          this.openMetadata();
        });
      });
    });
  }

  _getIsDownloadEnabled = (tenantPropertyProvider, user) => {
    if (
      tenantPropertyProvider.getProperty('enableDownloadForGuests') === false
      && user.isGuestOrSuperuser()
    ) {
      return false;
    }
    return true;
  };

  _showHistory() {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ this.model.get('id') }/history`, {
      trigger: true
    });
  }

  _printPage() {
    ArticlePrintHelpers.triggerPrint(this.glChannel, this, this.contentRegion.currentView);
  }

  _reportPage() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal article-report-modal'
    });
    const modalChildView = new ArticleReportModal({
      collection: new PageReportTypeCollection(PageReportTypes),
      model: new PageReport(),
      articleModel: this.model
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(this.model, 'change:currentVersion', () => {
      if (this.model.getCurrentVersion().pageReport != null ) {
        this.trigger('article:reported');
      }
    });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _onPdfAttachmentClick(view, mediaId) {
    const articleId = this.model.get('id');
    const currentVersion = this.model.get('currentVersion');
    handlePdfClicked(mediaId, articleId, currentVersion);
  }

  _onContentClickLink(view, e) {
    handleExternalLinkMedia(e, this.model.get('id'), this.model.get('currentVersion'));
  }

  _deletePage() {
    if ((this._deleteDfr != null ? this._deleteDfr.state() : undefined) === 'pending') {
      return;
    }

    this._deleteDfr = this.model.delete().done(() => {
      window.app.layout.flash.success(
        I18n.t(`discover.pageTypes.${ this.model.get('type') }.delete.success`, {
          title: this.model.getTitle()
        })
      );

      this.onBack();
    })
      .fail((xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const errCode = exception.getErrorCode();

        // Users access rights have changed, flash error and reload page
        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
          this.onPageAccessError(xhr, 'discover.pageTypes.generic.error.3017');

          window.location.reload(true);

          // Page either didn't exist or has already been deleted. Show success anyways.
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_VALID_ENTITY) {
          xhr.skipGlobalHandler = true;

          window.app.layout.flash.success(
            I18n.t(`discover.pageTypes.${ this.model.get('type') }.delete.success`, this.model.getTitle())
          );
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
          this.onPageAccessError(xhr, `discover.pageTypes.${ this.model.get('type') }.error.${ errCode }`);
        }
        this.onBack();
      });
  }

  _editPage() {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ this.model.get('id') }/edit`, {
      trigger: true
    });
  }

  _pageInsights() {
    Backbone.history.navigate(`${ InsightsUrlHelper.getPageInsightsUrl(this.model.get('id')) }`, {
      trigger: true
    });
  }


  _publishPage() {
    if (!this.model.isLocked(this.userId)) {
      this.lockController.lockPage().done(() => {
        this._saveModel()
          .done(() => {
            this.trigger('article:reloadContentRegion');
          });
      });
    } else {
      window.app.layout.flash.error(I18n.t('discover.pageTypes.article.error.3059'));
    }
  }

  _saveModel() {
    this.model.set('markFact', true);
    this.model.currentVersion.unset('id');
    this.model.currentVersion.richContent.unset('id');

    return this.model.save().done(() => {
      window.app.layout.flash.success(I18n.t(`discover.pageTypes.${ this.model.get('type') }.save.success`, { title: this.model.getTitle() }));
    })
      .fail( (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const errCode = exception.getErrorCode();

        if (errCode === AxonifyExceptionCode.CONTRACT_ERROR_VALUE_TOO_LONG) {
          this.onPageAccessError(xhr, `discover.pageTypes.generic.${ errCode }`);
        }

        // Deleted article, flash error and go back to listing
        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
          this.onPageAccessError(xhr, `discover.pageTypes.${ this.model.get('type') }.error.${ errCode }`);
          Backbone.history.history.back();

        // Users access rights have changed, flash error and go back to viewing only
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
          this.onPageAccessError(xhr, 'discover.pageAccess.error.3017');
          Backbone.history.history.back();
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_LANGUAGE_NOT_SUPPORTED) {
          this.onPageAccessError(xhr, 'discover.pageTypes.generic.error.3134');
          Backbone.history.history.back();
        }
        // Missing lock, try to grab it now and reattempt save
        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_OPERATION_REQUIRES_LOCK) {
          xhr.skipGlobalHandler = true;
          this.lockController.lockPage().done(this._saveModel());
        }
      })
      .always(() => {
        // remove the lock when publishing from the banner
        this.removeLock();
      });
  }

  onDestroy() {
    window.app.layout.dismissMedia();
    this.removeLock();
  }

  removeLock() {
    if (this.lockController) {
      this.lockController.destroy();
    }
  }

  onPageAccessError(xhr, errMsgKey) {
    xhr.skipGlobalHandler = true;
    window.app.layout.flash.error(I18n.t(errMsgKey));
  }

  genericErrorHanlder(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();

    // Users access rights have changed, flash error and go back to viewing only
    if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      this.onPageAccessError(xhr, 'discover.pageAccess.error.3017');
      Backbone.history.history.back();

    // page has been locked by someone else, so no need for oops screen
    // so skip global handler and go to page view
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_OBJECT_ALREADY_LOCKED
      || errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      this.onPageAccessError(xhr, `discover.pageTypes.${ this.model.get('type') }.error.${ errCode }`);
      Backbone.history.history.back();
    }
  }
}

module.exports = ArticleDetailsPageView;
