const MenuItemView = require('@common/modules/main/menu/item/MenuItemView');

class MenuAppItemView extends MenuItemView {

  constructor(options) {
    super(options);

    this.linkClass = `${ this.linkClass } menu-app-item ${ this.getOption('appColorClass') } selectable clickable`;
  }

  getTemplate() {
    return `
      <a href="<%- href %>" class="js-menu-item-link <%- linkClass %>">
        <span class="item-icon item-app-icon <%- iconClass %>"></span><span class="item-text"><%- text %></span>
      </a>
    `;
  }
}

module.exports = MenuAppItemView;
