const Backbone = require('Backbone');

class RaffleModel extends Backbone.Model {
  buyNow(ticketCount = 1, options = {}) {
    const data = {
      ticketCount,
      rewardProgramPrize: {
        id: this.id
      }
    };
    return $.ajax($.extend(true, options, {
      apiEndpoint: '/rewards/raffle',
      type: 'POST',
      data: JSON.stringify(data)
    }));
  }
}

module.exports = RaffleModel;
