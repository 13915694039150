const { Collection } = require('Backbone');
const TrainingCardModel = require('@common/components/trainingCards/TrainingCardModel');
const {
  PATH_ENROLLED,
  PATH_ASSIGNED
} = require('@common/components/trainingCards/enums/TrainingCardTypes');

class PathTrainingCardsCollection extends Collection {
  constructor(models, options = {}) {
    super(models, options);

    this.userId = options.userId;
  }

  get model() {
    return TrainingCardModel;
  }

  apiEndpoint() {
    if (this.userId) {
      return `/training/paths/${ this.userId }`;
    }
    return '/training/paths';
  }

  parse(resp = []) {
    resp.forEach((card) => {
      const isAssigned = card[TrainingCardModel.FIELDS.IS_ASSIGNED];
      card[TrainingCardModel.FIELDS.CARD_TYPE] = isAssigned ? PATH_ASSIGNED : PATH_ENROLLED;
    });

    return resp;
  }

  search(query) {
    return this.fetch({
      data: {
        search: query
      }
    });
  }

  fetch(options = {}) {
    return super.fetch(Object.assign({ showSpinner: false, reset: true }, options));
  }
}

module.exports = PathTrainingCardsCollection;
