const LEFT_DEFINITION = 'pageTitleBarLeftDefinition';
const RIGHT_DEFINITION = 'pageTitleBarRightDefinition';
const BAIL_DEFINITION = 'pageTitleBarBailDefinition';

module.exports = {
  Keys: {
    LEFT_DEFINITION,
    RIGHT_DEFINITION,
    BAIL_DEFINITION
  },

  getLeftDefinition(contextModel) {
    contextModel.get(LEFT_DEFINITION);
  },

  setLeftDefinition(contextModel, controllerDefinition, options) {
    contextModel.set(LEFT_DEFINITION, controllerDefinition, options);
  },

  getRightDefinition(contextModel) {
    contextModel.get(RIGHT_DEFINITION);
  },

  setRightDefinition(contextModel, controllerDefinition, options) {
    contextModel.set(RIGHT_DEFINITION, controllerDefinition, options);
  },

  getBailDefinition(contextModel) {
    contextModel.get(BAIL_DEFINITION);
  },

  setBailDefinition(contextModel, controllerDefinition, options) {
    contextModel.set(BAIL_DEFINITION, controllerDefinition, options);
  }
};
