const _ = require('underscore');
const Page = require('@common/components/discover/models/Page');

class PostModel extends Page {
  apiEndpoint() {
    return '/posts';
  }

  get className() {
    return 'PostModel';
  }

  defaults() {
    return Object.assign(super.defaults(), {
      title: ''
    });
  }

  validators() {
    return {
      language: 'required',
      community: 'required'
    };
  }

  repairTranslationCorruptedEntities(content) {
    // we check this because the content could be null
    if (content && _.isString(content)) {
      return content
        .replace(/& lt;/g, '&lt;')
        .replace(/& lt ;/g, '&lt;')
        .replace(/& gt;/g, '&gt;')
        .replace(/& gt ;/g, '&gt;')
        .replace(/& amp;/g, '&amp;')
        .replace(/& amp ;/g, '&amp;')
        .replace(/& nbsp;/g, '&nbsp;')
        .replace(/& nbsp ;/g, '&nbsp;');
    }
    return content;
  }

  save(...args) {
    this.unset('autoTranslatedContent');
    return super.save(...args);
  }

  /*
   * Posts are being translated and coming back with mangled entities like "& gt;" and "& lt;"
   * We'll intercept those problems in the parse and de-screwify those
   */
  parse(resp) {
    const response = super.parse(resp);
    if (response.autoTranslatedContent) {
      response.autoTranslatedContent.translatedContent = this.repairTranslationCorruptedEntities(response.autoTranslatedContent.translatedContent);
    }
    return response;
  }
}

module.exports = PostModel;
