const Backbone = require('Backbone');

class MobileDevice extends Backbone.Model {
  urlRoot() {
    return '/axonify/mobileDevices';
  }

  parse(response = {}) {
    return response.entity || response;
  }
}

module.exports = MobileDevice;
