class AbstractProgressConfig {

  isValid() {
    throw new Error('"isValid" needs to be implements by the subclass');
  }

  getSegmentLabelText() {
    throw new Error('"getSegmentLabelText" needs to be implements by the subclass');
  }

  getSegmentValue() {
    throw new Error('"getSegmentValue" needs to be implements by the subclass');
  }

  getMarkerOptions() {
    throw new Error('"getMarkerOptions" needs to be implements by the subclass');
  }

}

module.exports = AbstractProgressConfig;
