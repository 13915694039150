const Backbone = require('Backbone');
const Marionette = require('Marionette');

const I18n = require('@common/libs/I18n');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const PageType = require('@common/components/discover/enums/PageType');

require('@common/libs/behaviors/resizable/Resizable');

class TitleView extends Marionette.ItemView {
  constructor(...args) {
    super(...args);
    this._getFavoriteCount = this._getFavoriteCount.bind(this);
  }

  className() {
    return this.options.fullWidth ? 'full-width' : 'ax-container';
  }

  getTemplate() {
    return `\
      <div class='title__main-container title-view'>
        <div class="page-header__content">
          <h1>
            <span class="icon-<%- getType() %> page-header__icon"></span>
            <span class="title page-header__title">
              <% if (isReference()) { %>
                <a href="<%- url %>" target="<%- getReferenceURLTargetValue() %>" title="<%- getTitle() %>"><%- getTitle() %></a>
              <% } else { %>
                <span class="title__text" title="<%- getTitle() %>"><%- getTitle() %></span>
              <% } %>
            </span>
          </h1>

          <div class="title__sub-container">
            <div class="star-container page-header__action-container">
              <div class="star-icon icon-star-outline blue pull-left"></div>
              <div class="star-count"><%- getFavoriteCount() %></div>
            </div>
            <div class="more-container">
              <button class="hidden js-more-button more-button <%- getMoreButtonClass() %>">
                <div class="page-header__action-container">
                  <span class="icon icon-paper_clip"></span>
                  <span class="icon icon-ellipsis_horizontal"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>\
    `;
  }

  ui() {
    return {
      btnMore: '.js-more-button',
      favoriteCount: '.star-count',
      starIcon: '.star-icon',
      starContainer: '.star-container'
    };
  }

  events() {
    return {
      'click @ui.starIcon': '_onStarClick',
      'click @ui.btnMore': '_onClickMore'
    };
  }

  modelEvents() {
    return {
      'change:existingFavoriteId': '_onExistingFavoriteIdChange',
      'error:existingFavoriteId': '_onExistingFavoriteIdChange',
      'change:favoriteCount': '_onFavoriteCountChange'
    };
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  templateHelpers() {
    return {
      getFavoriteCount: this._getFavoriteCount,
      doesPageHaveReport: () => {
        return this.model.currentVersion.has('pageReport');
      },
      getMoreButtonClass: () => {
        if (this.model.currentVersion.richContent.getDocumentCount() > 0) {
          return 'attachment-icon';
        }
        return '';

      },
      isReference: () => {
        if (this.removeReference) {
          return false;
        }
        return this.model.get('type') === PageType.REFERENCE;

      },
      getReferenceURLTargetValue: () => {
        return UrlHelpers.isExternalLink(this.model.get('url')) ? '_blank' : '_self';
      },
      getType: () => {
        const type = this.model.get('type');
        if (type === PageType.TRAINING) {
          return 'extratraining';
        } else if (type === PageType.ARTICLE) {
          return 'create-article-nobg';
        } else if (type === PageType.REFERENCE) {
          return 'create-link-nobg';
        } else if (type === PageType.QUESTION) {
          return 'create-question-nobg';
        }

        return type;
      },
      getTitle: () => {
        return this.title || this.model.getTitle();
      }
    };
  }

  initialize(options = {}) {
    ({
      title: this.title,
      userId: this.userId,
      canStar: this.canStar,
      authChannel: this.authChannel = Backbone.Wreqr.radio.channel('auth'),
      removeReference: this.removeReference,
      fullWidth: this.fullWidth = false
    } = options);

    this.hideMoreButton = options.hideMoreButton || false;
    this.isMetaPaneOpen = false;
  }

  _onClickMore() {
    this.triggerMethod('click:more');

    this.isMetaPaneOpen = !this.isMetaPaneOpen;

    if (!this.hideMoreButton) {
      this._setMoreButtonAriaAttributes();
    }
  }

  _setMoreButtonAriaAttributes() {
    const ariaLabel = this.isMetaPaneOpen
      ? I18n.t('discover.pageView.moreMenu.ariaLabel.hide')
      : I18n.t('discover.pageView.moreMenu.ariaLabel.show');

    this.ui.btnMore.attr({
      'aria-label': ariaLabel,
      'aria-pressed': this.isMetaPaneOpen
    });
  }

  onRender() {
    if (this.model.get('existingFavoriteId') != null) {
      this.ui.starIcon.addClass('icon-star-solid');
    }
    this.ui.btnMore.toggleClass('hidden', this.hideMoreButton);
    this.ui.starContainer.toggleClass('hidden', !this.canStar);

    this._setMoreButtonAriaAttributes();
  }

  _getFavoriteCount() {
    const favoriteCount = this.model.get('favoriteCount');
    if (favoriteCount === 0) {
      return '';
    }
    return favoriteCount;

  }

  _updateHeader() {
    this._onFavoriteCountChange();
  }

  _onStarClick(e) {
    e.stopPropagation();
    this.model.toggleFavorite();
  }

  _onExistingFavoriteIdChange() {
    if (this.model.get('existingFavoriteId') != null) {
      this.ui.starIcon.addClass('icon-star-solid').removeClass('icon-star-outline');
    } else {
      this.ui.starIcon.removeClass('icon-star-solid').addClass('icon-star-outline');
    }
  }

  _onFavoriteCountChange() {
    this.ui.favoriteCount.html(this._getFavoriteCount());
  }
}

module.exports = TitleView;
