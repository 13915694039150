const $ = require('jquery');
const _ = require('underscore');

const DEFAULT_INACTIVITY_TIMEOUT_MINUTES = 60;

const DEFAULT_EVENTS = [
  'heartbeat',
  'mousedown',
  'mousemove',
  'mouseup',
  'keydown',
  'keyup',
  'touchstart',
  'touchmove',
  'touchend'
];

class InactivityMonitor {

  constructor(options = {}) {
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this._inactivityCallback = this._inactivityCallback.bind(this);
    this._startIdleTimeout = this._startIdleTimeout.bind(this);
    this._clearIdleTimeout = this._clearIdleTimeout.bind(this);
    this._triggerCallbacks = this._triggerCallbacks.bind(this);

    this._eventNamespace = 'inactivityMonitor';
    this._callbacks = {};

    this.setIdleTimout(options.inactivityTimeoutMinutes);

    this._setTarget(options.$target);
    this._setMonitoredEvents(options.events);
  }


  /* Public methods */

  start() {
    if (this._isStarted) {
      return;
    }
    this._isStarted = true;

    const debounceInactivityCallback = _.debounce(this._inactivityCallback, 1000, true);
    debounceInactivityCallback();

    this._$target.on(this._getEventsString(), debounceInactivityCallback);
  }

  stop() {
    if (!this._isStarted) {
      return;
    }
    this._isStarted = false;

    this._clearIdleTimeout();

    this._$target.off(this._getEventsString());
  }

  registerTimeoutCallback(id, callback) {
    this._callbacks[id] = callback;
  }

  unregisterTimeoutCallback(id) {
    delete this._callbacks[id];
  }

  /* Public setters */

  setIdleTimout(inactivityTimeoutMinutes = DEFAULT_INACTIVITY_TIMEOUT_MINUTES) {
    this._idleTimeout = inactivityTimeoutMinutes * 60 * 1000;
  }


  /* Private setter methods */

  _setTarget(_$target = $(document)) {
    this._$target = _$target;
  }

  _setMonitoredEvents(events = []) {
    this._events = DEFAULT_EVENTS.concat(events);
  }


  /* Private utility methods */

  _getEventsString() {
    return this._events.join(`.${ this._eventNamespace } `) + `.${ this._eventNamespace }`;
  }

  _inactivityCallback() {
    this._clearIdleTimeout();
    this._startIdleTimeout();
  }

  _startIdleTimeout() {
    this._idleTimeoutId = setTimeout(this._triggerCallbacks, this._idleTimeout);
  }

  _clearIdleTimeout() {
    if (this._idleTimeoutId != null) {
      clearTimeout(this._idleTimeoutId);
    }
  }

  _triggerCallbacks() {
    for (const key in this._callbacks) {
      if (Object.prototype.hasOwnProperty.call(this._callbacks, key)) {
        const callback = this._callbacks[key];

        if (_.isFunction(callback)) {
          callback();
        }
      }
    }
  }
}

module.exports = InactivityMonitor;
