const logging = require('logging');

const _ = require('underscore');
const Backbone = require('Backbone');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');

const FileHelpers = require('@common/libs/helpers/app/FileHelpers');

const AjaxUpload = require('@common/libs/file/uploader/AjaxUpload');
const FileStatusState = require('@common/libs/file/FileStatusState');

let URL;

class File extends Backbone.Model {
  static SIZE_LIMIT = 25; // in MB

  get idAttribute() {
    return 'uuid';
  }

  constructor(attrs, options) {
    super(attrs, options);

    this._validateFileInput(this.fileInput);
  }

  initialize(attrs, options = {}) {
    //These fields are only set when creating a file from an Input,
    //other fields are set by the parse function when creating from server/JSON
    ({
      fileInput: this.fileInput = null,
      nativeFileAPI: this.nativeFileAPI = null,
      name: this.name = '',
      allowedExtensions: this.allowedExtensions = ['*'],
      isSecureUpload: this.isSecureUpload = true,
      sizeLimit: this.sizeLimit = File.SIZE_LIMIT
    } = options);

    this.fileType = 'file';

    this._generatedId = this.id || this.cid;

    URL = this.getUrlApi();
  }

  urlRoot() {
    return '/axonify/media/file';
  }

  getGeneratedId() {
    return this._generatedId;
  }

  isDoneProcessing() {
    return this.get('status') === FileStatusState.DONE;
  }

  getName() {
    return this.get('originalFileName') || this.name;
  }

  getSize(index) {
    if (this.nativeFileAPI != null) {
      return this.fileInput[index].size;
    }

    return undefined;
  }

  destroyFileInput() {
    this.fileInput = null;
  }

  // If this File object represents a file input return the native File Object
  // from the browser (if the browser supports it), otherwise undefined.
  getNativeFile() {
    if (this.nativeFileAPI && this.fileInput) {
      return this.fileInput.files[0];
    }

    return undefined;
  }

  getUrlApi() {
    // normalize window.URL if there is a vendor prefix
    // (IE9 won't have either of these)
    return window.URL != null ? window.URL : window.webkitURL;
  }

  hasNativeFile() {
    return (this.getUrlApi() != null) && this.nativeFileAPI && this.fileInput;
  }

  isPreviewable() {
    return false;
  }

  /**
   * Important!! - if you call this, make sure to call releaseUrl() when you're done with the
   * image that's using the url. ie. when view gets destroyed or image is done loading depending
   * on your use case.
   *
   * @returns {string}
   */
  acquireUrl() {
    if (this._objectUrl != null) {
      return this._objectUrl;
    } else if (this.hasNativeFile()) {
      this._objectUrl = URL.createObjectURL(this.getNativeFile());
      return this._objectUrl;
    }

    return this.get('path');
  }

  releaseUrl() {
    if (this._objectUrl != null) {
      URL.revokeObjectURL(this._objectUrl);
      this._objectUrl = null;
    }
  }

  sync(method, model, options = {}) {
    if (method === 'create') {
      return this._upload(model, options);
    }
    return super.sync(method, model, options);

  }

  abortUpload() {
    return this.abortXHR(Sync.Method.CREATE);
  }

  _validateFileInput() {
    const fileObj = this.getNativeFile();

    if (fileObj != null) {
      this._validateFile(this.getName(), fileObj.size);
    }
  }

  _validateFile(name, size) {
    if (!this._isAllowedExtension(name)) {
      logging.debug(`File type is not allowed. ${ name }`);
      throw new Error(I18n.t('fileUploader.fileTypeNotAllowed'));
    } else if (size === 0) {
      logging.debug(`File is empty. ${ name }`);
      throw new Error(I18n.t('fileUploader.fileEmpty'));
    } else if (size > (this.sizeLimit * 1024 * 1024)) {
      logging.debug(`File is too large. ${ name }`);
      throw new Error(I18n.t('fileUploader.fileTooBig', {maximumSize: this.sizeLimit}));
    }
  }

  _isAllowedExtension(filename) {
    return this.allowedExtensions.includes('*') || this.allowedExtensions.includes(FileHelpers.getFileExtension(filename));
  }

  _upload(model, options = {}) {
    const uploadOptions = _.defaults(options, {
      url: this._getUploadURL(model),
      sizeLimit: model.sizeLimit,
      allowedExtensions: model.allowedExtensions,
      fileType: model.fileType
    });

    uploadOptions.progress = _.wrap(options.progress, (onProgress, ...args) => {
      if (typeof onProgress === 'function') {
        onProgress(model, ...args);
      }
      return model.trigger('progress', model, ...args);
    });

    Sync.actions.extendRequestOptions(Sync.Method.CREATE, model, uploadOptions);

    const request = AjaxUpload.upload(model.fileInput, uploadOptions);

    model.trigger('request', model, request, uploadOptions);

    return request;
  }

  _getUploadURL(model) {
    let urlSuffix;
    if (this.isSecureUpload) {
      urlSuffix = '/uploadSecureContent';
    } else {
      urlSuffix = '/upload';
    }
    return `${ model.url() }${ urlSuffix }`;
  }
}

module.exports = File;
