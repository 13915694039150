// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__title"><%- t('usermessage.prizeLoser.title') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="user-messaging prize-loser">
      <div class="broadcastmessage">

        <h1><%- t('auction.outbid', {"name": name}) %></h1>

        <div class="winning-bid-wrap clearfix">
        <% if (hasSingleWinningBidder) { %>

          <div class="broadcast">
            <div class="prize-winner-title"><%- t('usermessage.prizeLoser.singleWinningBidder') %></div>

            <div class="winner-list">
              <p><%- singleWinningBidder.user.salutationName %></p>
              <p class="winner-area"><%- singleWinningBidder.user.location.name %></p>
              <div class="point-bid"><%- singleWinningBidder.points %> point bid</div>
            </div>
          </div>

        <% } else { %>

          <h3><%- t('usermessage.prizeLoser.prizeWinners',{"name": name}) %></h3>

          <% if (hasHighestWinningBidder) { %>
            <div class="broadcast biddertableleft">
              <div class="prize-winner-title"><%- t('usermessage.prizeLoser.highestWinningBidder') %></div>

              <div class="winner-list">
                <p><%- highestWinningBidder.user.salutationName %></p>
                <p class="winner-area"><%- highestWinningBidder.user.location.name %></p>
                <div class="point-bid"><%- t('usermessage.prizeLoser.pointBid', {'points': highestWinningBidder.points}) %></div>
              </div>
          </div>
          <% } %>

          <% if (hasLowestWinningBidder) { %>
            <div class="broadcast biddertableright">
              <div class="prize-winner-title"><%- t('usermessage.prizeLoser.lowestWinningBidder') %></div>

              <div class="winner-list">
                <p><%- lowestWinningBidder.user.salutationName %></p>
                <p class="winner-area"><%- lowestWinningBidder.user.location.name %></p>
                <div class="point-bid"><%- t('usermessage.prizeLoser.pointBid', {'points': lowestWinningBidder.points}) %></div>
              </div>
            </div>
          <% } %>

        <% } %>
        </div>

        <% if (locationWinnerCount > 0) { %>
        <div class="broadcast" style="margin-top:3em;">
          <div class="prize-winner-title"><%- t('usermessage.prizeLoser.winnersInYourLocation') %></div>

          <% _.each(locationWinners, function(locationWinner) { %>
            <div class="winner-list">
              <p><%- locationWinner.user.salutationName %></p>
              <p class="winner-area"><%- locationWinner.user.location.name %></p>

              <div class="point-bid"><%- locationWinner.points %> <%- t('usermessage.prizeLoser.point') %>
              <%- t('usermessage.prizeLoser.bidType.' + locationWinner.type) %></div>
            </div>
          <% }); %>
        </div>
        <% } %>

        <% if (hasMedia) { %>
        <div class="media">
          <img src="<%- r(media.download) %>" alt="<%- name %>" />
        </div>
        <% } %>

      </div>
    </div>

  </div>
</div>
`;
// Exports
module.exports = code;