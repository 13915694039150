const { Collection } = require('Backbone');
const Community = require('@common/data/models/Community');
const CommunityType = require('@training/apps/training/enums/CommunityType');
const MAX_COMMUNITIES_PER_TYPE = 4;

class RecentlyUpdatedCommunityList extends Collection {
  initialize(models, options) {
    if (options != null) {
      ({extraParams: this.extraParams} = options);
    }
  }

  apiEndpoint() {
    return '/pageCommunities/recent';
  }

  parse(data) {
    //organize communities by type
    const communities = CommunityType.values().reduce((results, type) => {
      const collection = new Collection(data.results
        .filter((item) => {
          return (item.type?.toLowerCase() || CommunityType.OTHER) === type; //ensure type is OTHER if null or undefined (default)
        })
        .map((item) => {
          return new Community(item)
        }));
      if (collection.length > 0) {
        results.push({
          singularType: false,
          type,
          collection
        });
      }
      return results;
    }, []);

    if (communities.length === 1) {
      communities[0].singularType = true;
    } else if (communities.length > 1) {
      communities.forEach((item) => {
        item.collection = new Collection(item.collection.first(MAX_COMMUNITIES_PER_TYPE))
      });
    }

    return communities;
  }

  fetch(options = {}) {
    return super.fetch($.extend(true, {}, { data: this.extraParams }, options));
  }

  comparator(community) {
    return community.getName();
  }
}

module.exports = RecentlyUpdatedCommunityList;
