// Module
var code = `<div class="tile-top">
	<apan class="icon-milestone<%- objectiveDetails?.completedOnTime ? ' ontime' : '' %>" />
</div>

<div class="tile-bottom-wrapper">
	<h2><%- name %></h2>
	<h3><%- t('GuidedLearning.milestoneReachedMessage') %></h3>
	<div class="milestone-points<%- pointsClass %>"><%- pointsMessage %></div>
</div>
`;
// Exports
module.exports = code;