const GuidedLearningContextualAvailabilityFactory = require('@training/apps/training/controllers/guidedLearning/GuidedLearningContextualAvailabilityFactory');

const getObjectiveValidatorForCompletion = (session, requirementsComplete) => {
  const objectiveValidatorForSession = GuidedLearningContextualAvailabilityFactory(session)
    .createForGuidedLearningSessionType(requirementsComplete);

  return objectiveValidatorForSession;
};

/**
 * This class encapsulates the configuration the Guided Learning controller gets when it requests it.
 * It takes in the requirement checker since it is needs to query this for whether or not you should get an "after training"
 * experience or not.
 */
class GuidedLearningSessionConfigurationProvider {
  constructor(trainingSession, mandatoryRequirementChecker) {
    this.mandatoryRequirementChecker = mandatoryRequirementChecker;
    this.trainingSession = trainingSession;

    if (!this.mandatoryRequirementChecker) {
      throw new Error('You provide a requirement checker');
    }

    if (!this.trainingSession) {
      throw new Error('You provide a training session');
    }
  }

  getConfigurationAsync() {
    return Promise.resolve(this.mandatoryRequirementChecker()).then((areRequirementsNotYetCompleted) => {
      return {
        objectiveValidator: getObjectiveValidatorForCompletion(this.trainingSession, !areRequirementsNotYetCompleted),
        canContinue: !areRequirementsNotYetCompleted
      };
    });
  }
}

module.exports = GuidedLearningSessionConfigurationProvider;
