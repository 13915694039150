const _ = require('underscore');
const $os = require('detectOS');

const KeyCode = require('@common/data/enums/KeyCode');
const Form = require('@common/components/forms/Form');

Form.Editor.Location = class Location extends Form.Editor {
  events() {
    return {
      'click input': 'chooseLocation',
      'click .close': 'hideTree',
      'click .location-item': 'onLocationClicked'
    };
  }

  initialize(options = {}) {
    this.locationList = this.options.locationList || options.options;

    this.fullPath = Boolean(this.$el.data('full-path'));
    this.idMap = {};
    this.locationChildTemplate = _.tpl(require('@training/apps/auth/templates/_child_location_item.html'));

    this.treeData = this.createTreeData(null, this.locationList.toJSON());

    this.$('input').on('keyup', (e) => {
      if (!this.$itemChooser) {
        return;
      }

      if (e.which === KeyCode.BACKSPACE) {
        this.setValue();
        this.hideTree();
      }
    });

    this.$el.append('<span id="textRuler" style="visibility: hidden; white-space: nowrap; display: inline; width: auto;"></span>');
  }

  showTree() {
    this.$treeItems.empty();
    this.$itemChooser.show();
    this.shown = true;

    for (const location of this.treeData) {
      this._renderChild(this.$treeItems, location);
    }

    if ($os.mobile) {
      window.scrollTo(0, 0);
    }
  }

  _renderChild(wrapper, location) {
    const html = this.locationChildTemplate({
      name: location.title,
      id: location.id
    });

    const $fragment = $(html);
    const $fragmentWrapper = $fragment.find('.location-children');
    wrapper.append($fragment);

    const children = location.children || [];
    children.forEach((child) => {
      return this._renderChild($fragmentWrapper, child);
    });
  }

  onLocationClicked(e) {
    const $target = $(e.currentTarget);
    const id = $target.data('id');

    this.setValue(this.idMap[id]);
    this.hideTree();

    return false;
  }

  hideTree() {
    this.$itemChooser.hide();
    this.shown = false;
  }

  chooseLocation() {
    if (this.$itemChooser == null) {
      this.$itemChooser = this.$('.filteritemchooser');
    }
    if (this.$treeItems == null) {
      this.$treeItems = this.$itemChooser.find('.moreitems');
    }

    if (this.shown) {
      this.hideTree();
    } else {
      this.showTree();
    }

    return false;
  }

  createTreeData(parentName = null, collection) {
    const result = [];

    // done in place
    collection.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    _.each(collection, (model) => {
      let { name } = model;

      if (this.fullPath && (parentName != null)) {
        name = `${ parentName }, ` + name;
      }

      const data = {
        name,
        title: model.name,
        id: model.id,
        key: model.id,
        icon: false
      };

      this.idMap[model.id] = data;

      const children = model.children || [];
      data.children = this.createTreeData(name, children);

      result.push(data);
    });
    return result;
  }

  getValue() {
    const locationId = this.$('input').data('id');

    if (this.$('input').val() === '') {
      return null;
    } else if (locationId != null) {
      if (!this.shallowValue) {
        return {id: parseInt(locationId, 10)};
      }
      return parseInt(locationId, 10);

    }
    return null;

  }

  setValue(location) {
    if (location != null) {
      this.$('input').val((this.idMap[location.id] != null ? this.idMap[location.id].name : undefined) != null ? (this.idMap[location.id] != null ? this.idMap[location.id].name : undefined) : location.name);
      this.$('input').data('id', location.id);
    } else {
      this.reset();
    }
  }

  reset() {
    this.$('input').val('');
    this.$('input').data('id', null);
  }
};

module.exports = Form.Editor.Location;
