// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
			<span class="page-header__icon icon-profile" aria-hidden="true"></span>
      <span class="page-header__title"><%- pageTitle %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="ax-grid">
    <div class="page-content ax-grid__col--12 ax-grid__col--m-8 ax-grid__col--l-6">
      <p class="expired"><%= t('activateaccount.msg') %></p>
      <form id="activateAccountForm" class="highlighted-form">
        <h2 class="update-password"><%= t('activateaccount.updatepassword') %></h2>
        <div>
          <label for="oldpassword"><%- t('activateaccount.oldpassword') %></label>
          <div data-editor="Password" data-field="oldpassword" data-options="oldPasswordOptions" id="oldpassword" placeholder="<%- t('activateaccount.enteroldpassword') %>"></div>
        </div>

        <div>
          <label for="newpassword"><%- t('activateaccount.newpassword') %></label>
          <div data-editor="Password" data-field="newpassword" data-options="newPasswordOptions" id="newpassword" placeholder="<%- t('activateaccount.enternewpassword') %>"></div>
        </div>

        <div>
          <label for="passwordagain"><%- t('activateaccount.passwordagain') %></label>
          <div data-editor="Password" data-field="passwordagain" data-options="newPasswordOptions" id="passwordagain" placeholder="<%- t('activateaccount.confirmnewpassword') %>"></div>
        </div>

        <div class="password-rules">
          <div id="js-password-ruleset"></div>
        </div>

        <h2 class="update-questions"><%= t('activateaccount.updatesecurityquestion') %></h2>
        <div>
          <%= axSelect({
            ariaLabel: t('activateaccount.securityquestion', {i: 1}),
            classNameSelect: 'qa-securityquestion1',
            fullWidth: true,
            id: 'securityquestion1',
            name: 'securityquestion1',
            options: [
              { label: t('securityQuestions.q1') },
              { label: t('securityQuestions.q2') },
              { label: t('securityQuestions.q3') },
              { label: t('securityQuestions.q4') },
              { label: t('securityQuestions.q5') }
            ]
          }) %>
        </div>

        <div>
          <%= axInput({
            ariaLabel: t('activateaccount.securityanswer', {i: 1}),
            autocomplete: 'off',
            classNameInput: 'qa-input-securityanswer1',
            data: {
              field: 'securityanswer1'
            },
            id: 'securityanswer1',
            placeholder: t('activateaccount.entersecurityanswer')
          }) %>
        </div>

        <div>
          <%= axSelect({
            ariaLabel: t('activateaccount.securityquestion', {i: 2}),
            classNameSelect: 'qa-securityquestion2',
            fullWidth: true,
            id: 'securityquestion2',
            name: 'securityquestion2',
            options: [
              { label: t('securityQuestions.q6') },
              { label: t('securityQuestions.q7') },
              { label: t('securityQuestions.q8') },
              { label: t('securityQuestions.q9') },
              { label: t('securityQuestions.q10') }
            ]
          }) %>
        </div>

        <div>
          <%= axInput({
            ariaLabel: t('activateaccount.securityanswer', {i: 2}),
            autocomplete: 'off',
            classNameInput: 'qa-input-securityanswer2',
            data: {
              field: 'securityanswer2'
            },
            id: 'securityanswer2',
            placeholder: t('activateaccount.entersecurityanswer')
          }) %>
        </div>

        <div>
          <%= axSelect({
            ariaLabel: t('activateaccount.securityquestion', {i: 3}),
            classNameSelect: 'qa-securityquestion3',
            fullWidth: true,
            id: 'securityquestion3',
            name: 'securityquestion3',
            options: [
              { label: t('securityQuestions.q11') },
              { label: t('securityQuestions.q12') },
              { label: t('securityQuestions.q13') },
              { label: t('securityQuestions.q14') },
              { label: t('securityQuestions.q15') },
            ]
          }) %>
        </div>

        <div>
          <%= axInput({
            ariaLabel: t('activateaccount.securityanswer', {i: 3}),
            autocomplete: 'off',
            classNameInput: 'qa-input-securityanswer3',
            data: {
              field: 'securityanswer3'
            },
            id: 'securityanswer3',
            placeholder: t('activateaccount.entersecurityanswer')
          }) %>
        </div>

        <div id="areasofinterest"></div>
      </form>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;