const VIEWPORT_CONTROLLER_DEFINITION = 'viewportControllerDefinition';

module.exports = {
  Keys: {
    VIEWPORT_CONTROLLER_DEFINITION
  },

  getFlashWrapperControllerDefinition(contextModel) {
    return contextModel.get(VIEWPORT_CONTROLLER_DEFINITION);
  },

  setFlashWrapperControllerDefinition(contextModel, controllerDefinition, options = {}) {
    contextModel.set(VIEWPORT_CONTROLLER_DEFINITION, controllerDefinition, options);
  }
};

