const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
const TodaysActivitesHelpers = require('@training/apps/home/activities/TodaysActivitiesHelpers');
const { getTodaysActivitiesCardControllerDefinition } = require('@common/components/todaysActivities/TodaysActivitiesCardControllerDefinitionFactory');

module.exports = (session, complete, startTrainingCallback) => {
  return () => {
    const currentAssessmentsCollection = session.getAssessmentOptionsLists();

    if (currentAssessmentsCollection == null) {
      throw new Error('No currentAssessmentOptions exist!');
    }

    return {
      ViewControllerClass: CollectionLayoutViewController,
      collection: currentAssessmentsCollection,
      viewDefinition: {
        childViewOptions: {
          className: 'continue-training__assessment-options-region'
        }
      },
      ChildViewControllerDefinition: (currentAssessmentOptionsList) => {
        const listCollection = currentAssessmentOptionsList.get('values');
        const listType = currentAssessmentOptionsList.get('type');
        return {
          ViewControllerClass: CollectionLayoutViewController,
          collection: listCollection,
          viewDefinition: {
            tagName: 'ul',
            behaviors: {
              Carousel: {
                keyboard: false,
                containerClass: TodaysActivitesHelpers.CONTAINER_CLASSES.STANDARD_GAP,
                slidesPerViewCountConfig: TodaysActivitesHelpers.SLIDES_PER_VIEW_BREAKPOINTS_CONFIG
              }
            },
            childViewOptions: {
              tagName: 'li',
              className: 'continue-training__item-region'
            },
            viewComparator: TodaysActivitesHelpers.getSessionTypeViewComparator(listType)
          },
          ChildViewControllerDefinition: (currentAssessmentOptionModel) => {
            return getTodaysActivitiesCardControllerDefinition({
              sessionType: listType,
              currentAssessmentOptionModel,
              complete,
              startTrainingCallback
            });
          }
        };
      }
    };
  };
};

