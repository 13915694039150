// Module
var code = `<tbody>
  <tr>
    <td colspan="2">
      <div class="behaviour-name"><%= behaviour.name %></div>
      <div class="behaviour-description"><%= behaviour.description %></div>
    </td>
    <td class="behaviour-icons"><span class="behaviour-icon yes"><span class='<%= yesIconClass %> clearfix'></span><%= t('general.yes') %></span><span class="behaviour-icon no"><span class='<%= noIconClass %> clearfix'></span><%= t('general.no') %></span></td>
  </tr>
  <tr>
    <td colspan="3"><div class="behaviour-comment"><%= t('observations.details.labels.notes') %><br /></div></td>
  </tr>
</tbody>
`;
// Exports
module.exports = code;