const { ReactControllerDefinitionFactory } = require('@common/modules/react');

const AchievementsCardSkeletonDefinition = require('@training/apps/home/performance/achievements/AchievementsCardSkeletonDefinitionFactory').default;
const { has } = require('underscore');
const { ACHIEVEMENTS } = require('@common/data/enums/HubTileIdentifiers').default;

module.exports = (availableTiles, session) => {
  if (!has(availableTiles, ACHIEVEMENTS)) {
    return null;
  }

  return () => {
    return ReactControllerDefinitionFactory({
      component: import('@training/apps/home/performance/achievements/AchievementsCard'),
      props: {
        sessionStartDate: session.get('startDate'),
        sessionEndDate: session.get('expiryDate')
      },
      loadingControllerDefinition: AchievementsCardSkeletonDefinition
    })
  };
};
