// Module
var code = `<div class="learning-event-item fit-parent clearfix">
  <div class="ax-grid ax-grid--no-gutter ax-grid--m-align-items-center">
    <div class="ax-grid ax-grid--m-align-items-center">
      <div class="ax-grid__col--12 ax-grid__col--m-1">
        <p class="learning-event-item-date ax-font--no-margin">
          <span><%= itemDayOfWeek %></span>
          <span><%= itemDayOfMonth %></span>
        </p>
      </div>
      <div class="details-container text-ellipsis-softwrap">
          <div class="ax-font ax-font__display ax-font--no-margin text-ellipsis-softwrap"><%= itemTitle %></div>
          <p class="ax-font--no-margin ax-font--secondary">
            <span class="learning-event-item-time learningevent-secondarytext"><%- itemStartTime %> – <%- itemEndTime %></span>
            <span class="learning-event-item-language learningevent-secondarytext">&bull; <%- itemLanguage %></span>
            <span class="learning-event-item-room learningevent-secondarytext"> - <%= itemLocation %></span>
          </p>
      </div>
      <div class="ax-grid__col--12 ax-grid__col--m-auto-size <%- itemStatusClass %>">
        <div class="ax-font ax-font__caption ax-font__caption--bold ax-font--no-margin">
          <%- itemStatus %>
        </div>
      </div>
    </div>
    <div class="icon-angle_right row-arrow ax-grid__col--auto-size" aria-hidden="true"></div>
  </div>
</div>
`;
// Exports
module.exports = code;