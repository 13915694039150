const NumberHelpers = require('@common/libs/helpers/types/NumberHelpers');
const I18n = require('@common/libs/I18n');

module.exports = (number) => {
  const tenMillion = 10000000;
  const billion = 1000000000;

  let formatedNumber = number;
  if (formatedNumber >= billion) {
    formatedNumber = NumberHelpers.toFixed(formatedNumber / billion, 1);
    return formatedNumber + I18n.t('discover.insights.abrevated.billion');
  } else if (formatedNumber >= tenMillion) {
    formatedNumber = NumberHelpers.toFixed(formatedNumber / 1000000, 1);
    return formatedNumber + I18n.t('discover.insights.abrevated.million');
  }
  return formatedNumber.toLocaleString();
};
