const Backbone = require('Backbone');
class BreadcrumbCollection extends Backbone.Collection {
  setRootBreadcrumb(rootCrumb) {
    return this.rootCrumb = rootCrumb;
  }

  setBreadcrumbs(breadcrumbList) {
    if (this.rootCrumb != null) {
      breadcrumbList.unshift(this.rootCrumb);
    }

    return this.reset(breadcrumbList);
  }
}

module.exports = BreadcrumbCollection;
