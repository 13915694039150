const _ = require('underscore');
const {
  QuestionPreviewPage,
  QuestionOptionItemView,
  QuestionCollectionView
} = require('@common/components/questions/views/QuestionPreviewPage/QuestionPreviewPage');

class MultipleChoiceQuestionPreviewPage extends QuestionPreviewPage {

  className() {
    return 'multiple-choice-view';
  }

  regions(options) {
    return _.extend(super.regions(options), {
      answerOptions: '#answer-body'
    });
  }

  onShow() {
    super.onShow();
    const optionTextView = this._getOptionCollectionView();
    this.answerOptions.show(optionTextView);

    this.listenTo(optionTextView, 'image:clicked', (itemView, mediaId) => {
      this.trigger('show:zoom:modal', itemView.getImageMedia(mediaId));
    });
  }

  _getOptionCollectionView() {
    return new MultipleChoiceQuestionCollectionView({
      model: this.model
    });
  }
}

class MultipleChoiceQuestionOptionItemView extends QuestionOptionItemView {

  getTemplate() {
    return require('@common/components/questions/templates/multipleChoice/answerOptionItem.html');
  }

  templateHelpers() {
    return _.extend(super.templateHelpers(), {
      selectedStatus: this.selectedAnswer ? 'selected' : ''
    });
  }
}

class MultipleChoiceQuestionCollectionView extends QuestionCollectionView {

  get childView() {
    return MultipleChoiceQuestionOptionItemView;
  }

  childViewOptions(model, index) {
    return _.extend(super.childViewOptions(model, index), {
      selectedAnswer: model.get('id') === parseInt(this.options.model.get('questionOptionList'), 10)
    });
  }
}

module.exports = MultipleChoiceQuestionPreviewPage;
