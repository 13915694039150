const I18n = require('@common/libs/I18n');
const Marionette = require('Marionette');
const ExternalWindowService = require('@common/services/externalWindow/ExternalWindowService');

class ExternalVideoView extends Marionette.ItemView {
  initialize(options) {
    ({
      model: this.model
    } = options);

    this._videoUrl = this.model.getUrl();
  }

  className() {
    return 'full-height';
  }

  getTemplate() {
    return `\
    <div class="row btn-row">
      <div class="col-xs-12 url-panel full-width text-center">
        <%= axButton({ label: t('general.view'), className: 'js-button-view-video axon-button external-link-button', size: 'm' }) %>\
      </div>
    </div>
    `;
  }

  ui() {
    return {
      viewButton: '.js-button-view-video'
    };
  }

  events() {
    return {
      'click @ui.viewButton': 'launchNewWindowVideo'
    };
  }

  launchNewWindowVideo() {
    try {
      this.VideoExternalWindowService = new ExternalWindowService({
        windowOptions: {
          href: this._videoUrl,
          windowId: 'Axonify_External_Video'
        },
        popupBlockedHandler: (message) => {
          window.app.layout.flash.error(message);
        }
      });

      this.VideoExternalWindowService.openWindow();
    } catch (error) {
      window.app.layout.flash.error(I18n.t('errors.VideoPlayer.newWindow'));
    }

  }

  resize() {
    return true;
  }

  onDestroy() {
    if (this.VideoExternalWindowService != null) {
      this.VideoExternalWindowService.closeWindow();
    }
  }
}

module.exports = ExternalVideoView;
