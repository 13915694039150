const Backbone = require('Backbone');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const _ = require('underscore');

const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');

const PageInsightsController = require('@training/apps/insights/PageInsightsController');

const {
  PAGE_INSIGHTS
} = require('@common/data/enums/SearchPageEnum');

class InsightsFlowController extends FlowController {
  constructor(...args) {
    super(...args);

    this.pageState = {};
  }

  processSequenceFlow(options) {
    return this.showPage(options).then((handled) => {
      if (handled) {
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  showPage(options = {}) {
    const {
      pageId,
      pageOptions
    } = this._getPageArgs(options);

    if (options.pageId == null) {
      return this._attemptToRestoreDiscoverPage().then(() => {
        return true;
      },
      Promise.resolve.bind(null, false));
    }

    return this._showPageView(pageId, pageOptions).then(() => {
      this._clearPageState();
      return true;
    },
    Promise.resolve.bind(null, false));
  }

  _getPageArgs(options = {}) {
    if (options.pageId != null) {
      return options;
    }

    return Object.assign(options, {
      pageId: this.pageState.pageId,
      pageOptions: _.omit(this.pageState.pageOptions || {}, 'navigate')
    });
  }

  _clearPageState() {
    this.pageState = {};
  }

  _showPageView(pageId, pageOptions) {
    switch (pageId) {
      case PAGE_INSIGHTS:
        return this.showInsightsPage(pageOptions);
      default:
        return Promise.reject(Promise.OperationalError('No discover flow matched...'));
    }
  }

  _attemptToRestoreDiscoverPage() {
    const hash = UrlHelpers.getLocationHash();

    if (hash.includes('hub/search') || hash.includes('hub/paths')) {
      _.defer(() => {
        Backbone.history.loadUrl(hash);
      });
      return Promise.resolve();
    }
    return Promise.reject(Promise.OperationalError('No discover flow matched...'));

  }

  showInsightsPage(pageOptions = {}) {
    ({
      pageId: this.pageId,
      teamId: this.teamId
    } = pageOptions);

    window.app.layout.setView({
      ViewControllerClass: PageInsightsController,
      pageId: this.pageId,
      teamId: this.teamId
    }, 'fade');

    return Promise.resolve();

  }
}


module.exports = InsightsFlowController;
