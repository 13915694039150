const _ = require('underscore');
const dateHelpers = require('@common/libs/dateHelpers');

/*
  This is a marker interface to let you know what you have to adhere to some opaque context that you have to extend.
  This ensures the users declare a schema that is known ahead of time and the users working with it know what to expect.

  You will never constructor this on it's own as it will not provide value.
*/
class AssessmentInitatorContext {
  constructor() {
    this.timestamp = dateHelpers.getTime();
  }

  toJSON() {
    const json = _.extendOwn({}, this);

    // simulate generics inside of JS by allowing someone to override and specify constructor
    // prototype code, i.e: specify the instance method of any generic variant X to allow one to type the result
    // according to X
    if (this.getType) {
      json.type = this.getType();
    } else {
      json.type = this.constructor.getType();
    }

    return json;
  }

  getTimestamp() {
    return this.timestamp;
  }
}

module.exports = AssessmentInitatorContext;
