const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');

class NullValueSerializer {
  serialize(passwordEditor) {
    return passwordEditor.ui.passwordInput.val().trim();
  }
}

class MD5Serializer {
  serialize(passwordEditor) {
    return CryptographyHelper.getMD5Hash(passwordEditor.ui.passwordInput.val().trim());
  }
}

module.exports = { NullValueSerializer, MD5Serializer };
