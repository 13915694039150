const TrainingModuleDeliveryMethodEnum = require('@common/data/enums/TrainingModuleDeliveryMethodEnum');
const VideoPage = require('@training/apps/training/views/activities/pages/VideoPage');
const {
  isInApp,
  canOpenChildWebViews,
  isMsTeamsIntegration
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

const VideoActivityViewFactory = (ExternalVideoDeliveryMethodViews = {}) => {
  return (options = {}) => {
    const { activity } = options;

    // We are only concerned with delivery method for external videos
    if (!activity.isExternalVideo()) {
      return new VideoPage(options);
    }

    let ExternalVideoDeliveryMethod = activity.getDeliveryMethod() || TrainingModuleDeliveryMethodEnum.EMBEDDED;

    // not all mobile app versions support opening child windows
    if (isInApp() && !canOpenChildWebViews() || isMsTeamsIntegration()) {
      ExternalVideoDeliveryMethod = TrainingModuleDeliveryMethodEnum.EMBEDDED;
    }

    const ViewClass = ExternalVideoDeliveryMethodViews[ExternalVideoDeliveryMethod];

    return new ViewClass(options);
  };
};

module.exports = VideoActivityViewFactory;
