// Module
var code = `<div class="page-header">
  <div class="ax-container relative page-header__breadcrumbs">
    <div class="breadcrumb-path" role="navigation">
      <div class="js-breadcrumb-region header-breadcrumbs"></div>
    </div>
    <div class="page-header__content">
      <span class="page-header__icon icon-metrics" aria-hidden="true"></span>
      <h1 class="page-header__title"><%- t('discover.insights.title') %></h1>
      <button
        class="js-how-to-link how-to-link ax-button ax-button--m ax-button--icon-left"
        aria-label="<%- t('discover.insights.howItWorks.title') %>"
      >
        <span class="icon-question_sign icon--reset-styles"></span>
        <span class="text"><%- t('discover.insights.howItWorks.title') %></span>
      </button>
    </div>
  </div>
</div>
<div class="ax-container">
  <div class="page-content">
    <div class="js-team-insights-overview-region overview-region margin-bottom-lrg"></div>
    <div class="js-team-insights-engagement-region"></div>
    <div class="js-team-insights-subteams-region subteams-region"></div>
  </div>
</div>
`;
// Exports
module.exports = code;