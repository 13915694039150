const MenuItemCollectionView = require('@common/modules/main/menu/MenuItemCollectionView');
const MenuItemView = require('@common/modules/main/menu/item/MenuItemView');
const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');

module.exports = (contextModel, menuPosition) => {
  const itemCollection = MenuContextConfig.getItemsDisplay(contextModel);

  return {
    viewDefinition: {
      ViewClass: MenuItemCollectionView,
      childView: MenuItemView,
      collection: itemCollection,
      menuPosition
    },
    delegateEvents: {
      'view:render': (controller, view) => {
        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.CURRENT_MENU_POSITION }`, (state, contextMenuPosition) => {
          view.toggleHidden(contextMenuPosition !== menuPosition);
        });

        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.IS_LOCKED_TO_TOP }`, (state, contextLockedToTop) => {
          view.toggleLockedToTop(contextLockedToTop);
        });

        view.toggleHidden(MenuContextConfig.getCurrentMenuPosition(contextModel) !== menuPosition);
        view.toggleLockedToTop(MenuContextConfig.getIsLockedToTop(contextModel));
      }
    }
  };
};
