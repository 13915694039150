const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const TitleHeaderLayout = require('@common/components/titleHeader/TitleHeaderLayout');

class TitleHeaderController extends LayoutController {

  get viewDefinition() {
    return TitleHeaderLayout;
  }

  regionControllers() {
    const regionControllers = {};

    const badgeControllerDefinition = this.getOption('badgeControllerDefinition');
    const titleControllerDefinition = this.getOption('titleControllerDefinition');

    if (badgeControllerDefinition != null) {
      Object.assign(regionControllers, {
        badgeRegion: badgeControllerDefinition
      });
    }

    if (titleControllerDefinition != null) {
      Object.assign(regionControllers, {
        titleRegion: titleControllerDefinition
      });
    }

    return regionControllers;
  }
}

module.exports = TitleHeaderController;
