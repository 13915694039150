const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

const TabController = require('@common/components/tabbed_layout/TabController');

const { getTrainingCardDefinition } = require('@common/components/trainingCards/TrainingCardFactory');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const TopicTabFactory = require('@training/apps/learnerTraining/learnerTrainingTab/TopicTabFactory');
const PathTabFactory = require('@training/apps/learnerTraining/learnerTrainingTab/PathTabFactory');
const CatalogTabFactory = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogTabFactory');

const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const TAB_NAME = require('@training/apps/learnerTraining/LearnerTrainingTabsEnum');

const TrainingPageDataHelpers = require('@training/apps/learnerTraining/learnerTrainingTab/TrainingPageDataHelpers');
const CatalogBrowseTypeEnum = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogBrowseTypeEnum');

const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');
const AxonifyExceptionFactory = require('@common/services/error/AxonifyExceptionFactory');

class LearnerTrainingTabController extends TabController {
  constructor(options = {}) {
    super(options);

    this.searchQuery = options.searchQuery;
    this.pageNum = options.pageNum;
    this.browseType = options.browseType;

    this.tabCollections = {
      topics: {
        init: false,
        data: options.topicTrainingCardsCollection
      },
      paths: {
        init: false,
        data: options.pathTrainingCardsCollection
      },
      catalog: {
        init: false,
        data: options.catalogTrainingCardsCollection
      }
    };

    this.tabHashes = {
      [TAB_NAME.TOPICS]: '#hub/training/topics',
      [TAB_NAME.PATHS]: '#hub/training/paths'
    };

    if (TenantPropertyProvider.get().getProperty('trainingCatalogEnabled')) {
      this._updateCatalogTabHash();
    }

    this.catalogFilterSubjectList = options.catalogFilterSubjectList;

    this._handleTopicTabCollectionSync = this._handleTopicTabCollectionSync.bind(this);
  }

  inflateView() {
    super.inflateView();

    this.tabCollections.paths.data.fetch()
      .fail(this._onServerError.bind(this));

    if (TenantPropertyProvider.get().getProperty('trainingCatalogEnabled')) {
      const browseType = this.browseType;
      this.tabCollections.catalog.data.search(this.searchQuery, this.pageNum, { browseType })
        .fail(this._onServerError.bind(this));
      this.catalogFilterSubjectList.fetch();
      this.listenTo(this.tabCollections.catalog.data, 'sync', this._updateCatalogTabHash);
    }

    this.listenTo(this.tabCollections.topics.data, 'sync', this._handleTopicTabCollectionSync);
    this.listenTo(this.tabCollections.paths.data, 'sync', this._handlePathsTabCollectionSync);
  }

  _onServerError(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    if (exception.getErrorCode() === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.access.error.2012'));

      Backbone.history.navigate('hub', {
        replace: true,
        trigger: true
      });
    }
  }

  onViewBeforeDestroy() {
    this.tabCollections.topics.data.resetSearchParams();
    this.tabCollections.topics.data.abortXHR();
    this.tabCollections.paths.data.abortXHR();
  }

  tabConfigs(options) {
    const startAssessment = (model) => {
      options.assessmentCallback({
        topicId: model.get('action').topicLevel.topicId,
        level: model.get('action').topicLevel.level,
        assessmentType: model.get('action').assessmentType,
        programId: model.get('action').programId,
        launchContext: AssessmentLaunchContext.EXTRA_TRAINING
      });
    };

    const buildCardView = (model) => {
      return getTrainingCardDefinition(TrainingPageDataHelpers.getData(model, startAssessment));
    };

    const tabConfigs = [
      TopicTabFactory(options.topicTrainingCardsCollection, buildCardView),
      PathTabFactory(options.pathTrainingCardsCollection, buildCardView)
    ];

    if (TenantPropertyProvider.get().getProperty('trainingCatalogEnabled')) {
      tabConfigs.push(CatalogTabFactory(options.catalogTrainingCardsCollection, options.catalogFilterSubjectList, buildCardView));
    }

    return tabConfigs;
  }

  // Don't fetch data until its loaded
  _onViewShowTab(tabName) {
    super._onViewShowTab(tabName);

    Backbone.history.navigate(this.tabHashes[tabName], {
      trigger: false,
      replace: false
    });

    if (!this.tabCollections[tabName].init) {
      triggerResize(true);
      this.tabCollections[tabName].init = true;
    }
  }

  _handleTopicTabCollectionSync() {
    if (!this.tabCollections.topics.data.hasNext()) {
      this.tabCollections.topics.data.push(TrainingPageDataHelpers.generateDiscoverCardData());
    }
  }

  _handlePathsTabCollectionSync() {
    const hasInprogress = this.tabCollections.paths.data.some((item) => {
      return !item.get('completed');
    });

    if (!hasInprogress) {
      this.tabCollections.paths.data.add(TrainingPageDataHelpers.generateDiscoverCardData(false));
    }
  }

  _updateCatalogTabHash() {
    const type = this.tabCollections.catalog.data.getBrowseType() || CatalogBrowseTypeEnum.ALL;
    const pageNum = this.tabCollections.catalog.data.state.currentPage || 0;
    const searchQuery = this.tabCollections.catalog.data.getQueryString() || '';
    const urlSearchQuery = encodeURIComponent(encodeURIComponent(searchQuery));

    this.tabHashes[TAB_NAME.CATALOG] = UrlHelpers.combineUrlPaths('#hub/training/catalog', 'type-' + type, pageNum.toString(), urlSearchQuery);
  }
}

module.exports = LearnerTrainingTabController;
