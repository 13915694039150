const Promise = require('bluebird');

const NextStartableAssessmentProvider = require('@training/apps/training/controllers/assessments/providers/NextStartableAssessmentProvider');

/*
  This provider is used in the case where you never want something to show up in next. This is useful in the case where
  you have a bunch of one off assessments you want to do that are not part of a broader picture.
*/
class NullStartableAssessmentProvider extends NextStartableAssessmentProvider {
  canProduceNext() {
    return false;
  }

  getNext() {
    return Promise.resolve(null);
  }
}

module.exports = NullStartableAssessmentProvider;
