const logging = require('logging');

const CookieUtil = {
  set(cname, cvalue, date, path, domain, secure) {
    const cookieName = cname == null ? '' : cname;
    const cookieValue = cvalue == null ? '' : cvalue;
    const cookiePath = path == null ? '/' : path;
    const cookieDomain = domain == null ? document.domain : domain;
    const cookieSecure = secure == null ? true : secure;

    let cookie = ` ${ encodeURIComponent(cookieName) }=${ encodeURIComponent(cookieValue) };`;
    if (date != null) {
      if ((date.toUTCString == null)) {
        throw new Error('The argument for `date` is not a valid Date object.');
      }
      cookie += ` expires=${ date.toUTCString() };`;
    }
    cookie += ` path=${ cookiePath };`;
    cookie += ` domain=${ cookieDomain };`;
    cookie += ` secure=${ cookieSecure };`;

    document.cookie = cookie;
  },

  get(cname) {
    const name = `${ encodeURIComponent(cname) }=`;
    const ca = document.cookie.split(';');
    for (let i = 0, end = ca.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return decodeURIComponent(c.substring(name.length, c.length));
      }
    }

    return '';
  },

  unset(cname, path, domain, secure) {
    this.set(cname, '', new Date(0), path, domain, secure);
  },

  getAll() {
    return document.cookie.split(/; */)
      .reduce((memo, cookieRecord) => {
        const [key, value] = cookieRecord.split('=');

        return Object.assign(memo, {
          [decodeURIComponent(key)]: decodeURIComponent(value)
        });
      }, {});
  },

  // Pulled from Modernizr at https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
  isEnabled() {
    let ret, SameSite;
    const logs = [];
    // navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
    // configurations. For example, when blocking cookies via the Advanced
    // Privacy Settings in IE9, it always returns true. And there have been
    // issues in the past with site-specific exceptions.
    // Don't rely on it.

    // try..catch because some in situations `document.cookie` is exposed but throws a
    // SecurityError if you try to access it; e.g. documents created from data URIs
    // or in sandboxed iframes (depending on flags/context)
    try {
      // Cookies must be flagged with 'SameSite=None; Secure' when used in a cross-site context (ie. an iframe)
      SameSite = window.self !== window.top ? 'SameSite=None; Secure' : 'SameSite=Lax';
      logs.push('SameSite ' + SameSite);
      // Create cookie
      document.cookie = 'cookietest=1; ' + SameSite;
      logs.push('Cookie test: ' + document.cookie);
      ret = document.cookie.indexOf('cookietest=') !== -1;
      // Delete cookie
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT; ' + SameSite;
    } catch (e) {
      logs.push('Error thrown while testing for cookies enabled: ' + e.message);
      ret = false;
    }

    logs.push('Cookies enabled: ' + ret);

    if (!ret) {
      logs.forEach((log) => {
        logging.debug(log);
      });
    }
    return ret;
  }
};

module.exports = CookieUtil;
