const I18n = require('@common/libs/I18n');
const logging = require('logging');

module.exports = {
  restrictedCharacters(blacklistedCharacters, message) {
    return (value) => {
      const hasBlacklistedCharacters = value.split('').some((c) => {
        return blacklistedCharacters.includes(c);
      });
      return hasBlacklistedCharacters ? (message || I18n.t('errors.validators.string.general')) : undefined;
    };
  },

  maxLength(maxLength, context) {
    // In a form, typically, the context is the input field name
    return (value) => {
      if (value != null && value.length > maxLength) {
        return I18n.t('UIKit.Form.errors.maxCharactersNamed', {
          maxLength,
          context: context || value
        });
      }
      return undefined;
    };
  },

  getRequiredValueErrorMessage(className, fieldName) {
    const modelErrorKey = `errors.${ className }.required.${ fieldName }`;
    const labelKey = `formLabels.${ className }.${ fieldName }`;

    if (I18n.hasString(modelErrorKey)) {
      return I18n.t(modelErrorKey);
    } else if (I18n.hasString(labelKey)) {
      return I18n.t('UIKit.Form.errors.field.required', {
        label: I18n.t(labelKey)
      });
    }
    logging.warn(`"${ fieldName }" has no error message for validator "required"`);
    return undefined;
  }
};
