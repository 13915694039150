const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

class NoReactionsMessage extends Marionette.ItemView {

  constructor(options = {}) {
    super(options);
    ({ tabName: this.tabName } = options);

    this.i18nArgs = [];

    if (this.tabName === 'all') {
      this.i18nArgs.push('discover.insights.noReactions');
    } else {
      this.i18nArgs.push('discover.insights.noReactionsInTab');
      this.i18nArgs.push({
        tabName: this.tabName,
        tabNameCapitalized: this.tabName.toUpperCase()
      });
    }
  }

  getTemplate() {
    return `<div class="collection-footer"><%= message %></div>`;
  }

  templateHelpers() {
    return {
      message: I18n.t(...this.i18nArgs)
    };
  }

  className() {
    return 'userview-reaction';
  }
}

module.exports = NoReactionsMessage;
