// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-games" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('gamescores.title')%></span>
		</div>
	</div>
</h1>


<div class="ax-container">
  <div class="page-content">
    <div class="game-scores ax-grid">
      <div class="all-games ax-grid__col--12 ax-grid__col--m-8">
        <h2><%- t('gamescores.allgames')%></h2>
        <div class="gameslist"></div>
      </div>
      <div class="challenge-info ax-grid__col--12 ax-grid__col--m-4">
        <div class="challenge-stats-region" role="region" aria-label="<%- t('recentChallenges.stats.totalChallengesWon') %>"></div>
        <div class="recent-challenges-region" role="region" aria-label="<%- t('recentChallenges.heading') %>"></div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;