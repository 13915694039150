const { Wreqr } = require('Backbone');

const I18n = require('@common/libs/I18n');

const { createMenuItemViewDefinition } = require('@common/modules/main/menu/item/MenuItemFactory');

module.exports = (logoutFunction) => {
  return () => {
    const nbChannel = Wreqr.radio.channel('nativeBridge');
    // Don't show the logout button if we don't want to show the login page
    const logoutEnabled = !nbChannel.reqres.request('nativeAuthRequired');
    const sessionMenuConfig = [];

    if (logoutEnabled) {
      sessionMenuConfig.push(
        createMenuItemViewDefinition({
          configId: 'session-logout',
          text: I18n.t('session.logout'),
          iconClass: 'icon-logout',
          isProfileItem: true,
          hideMsTeams: true,
          events: {
            click: logoutFunction
          }
        })
      );
    }

    return sessionMenuConfig;
  };
};
