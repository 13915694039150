const ActivityType = require('@common/data/enums/ActivityType');

const TinCanPage = require('@training/apps/training/views/activities/pages/TinCanPage');
const SurveyQuestionPage = require('@training/apps/training/views/activities/pages/SurveyQuestionPage');
const ExternalLinkPage = require('@training/apps/training/views/activities/pages/ExternalLinkPage');

const PDFDeliveryMethodControllers = require('@training/apps/training/views/activities/pages/pdf/PDFDeliveryMethodControllers');
const SCORMDeliveryMethodViews = require('@training/apps/training/views/activities/pages/scorm/SCORMDeliveryMethodViews');
const QuestionVariantTypePages = require('@training/apps/training/views/activities/pages/questions/QuestionVariantTypePages');
const ExternalVideoDeliveryMethodViews = require('@training/apps/training/views/activities/pages/videoLink/ExternalVideoDeliveryMethodViews');

const DefaultActivityPageFactory = (ViewClass) => {
  return (options = {}) => {
    return new ViewClass(options);
  };
};

const PDFActivityViewFactory = require('@training/apps/training/views/activities/PDFActivityViewFactory');
const SCORMActivityViewFactory = require('@training/apps/training/views/activities/SCORMActivityViewFactory');
const QuestionActivityPageFactory = require('@training/apps/training/views/activities/QuestionActivityPageFactory');
const VideoActivityViewFactory = require('@training/apps/training/views/activities/VideoActivityViewFactory');

module.exports = {
  [ActivityType.PDF_MODULE]: PDFActivityViewFactory(PDFDeliveryMethodControllers),
  [ActivityType.SCORM_MODULE]: SCORMActivityViewFactory(SCORMDeliveryMethodViews),
  [ActivityType.TINCAN_MODULE]: DefaultActivityPageFactory(TinCanPage),
  [ActivityType.VIDEO_MODULE]: VideoActivityViewFactory(ExternalVideoDeliveryMethodViews),
  [ActivityType.SURVEY_QUESTION]: DefaultActivityPageFactory(SurveyQuestionPage),
  [ActivityType.EXTERNAL_LINK_MODULE]: DefaultActivityPageFactory(ExternalLinkPage),
  [ActivityType.QUESTION]: QuestionActivityPageFactory(QuestionVariantTypePages)
};
