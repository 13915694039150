const FixedLayout = require('@common/components/fixedLayout/FixedLayout');

class FixedFooterLayout extends FixedLayout {
  getTemplate() {
    return `
      <div class="js-content fixed-content-region parent-width no-spacing"></div>
      <div class="js-fixed fixed-footer-region parent-width no-spacing"></div>
    `;
  }

  regions() {
    return {
      fixedFooterRegion: '.js-fixed',
      fixedContentRegion: '.js-content'
    };
  }

  toggleFooter(show) {
    this.toggleFixed(show);
  }
}

module.exports = FixedFooterLayout;
