const Marionette = require('Marionette');
const MediaViewModel = require('@common/components/media/MediaViewModel');
const $os = require('detectOS');

require('@common/components/mediaPreview/fileName/FileName.less');

class FileNameView extends Marionette.ItemView {
  preinitialize(options) {
    super.preinitialize(options);

    ({
      shouldBeViewable: this.shouldBeViewable = false
    } = options);
  }

  constructor(options = {}) {
    super(options);

    ({
      maxDimensions: this.maxDimensions,
      model: this.model,
      showDownloadLink: this.showDownloadLink = false,
      viewCb: this.viewCb = () => {}
    } = options);

    this.file = this.model.get('originalFile');
  }

  getTemplate() {
    return `
      <% if (isViewable) { %>
        <button class="filename-card ax-button ax-button--ghost ax-button--ghost-parent-hover"
          type="button" aria-label="<%- t('attachments.preview', {name: name}) %>">
      <% } else { %>
        <div class="filename-card">
      <% } %>

      <span class="ax-grid ax-grid--no-gutter ax-grid--direction-row">
        <span class="ax-grid__col--auto-size">
          <span class="attachment-icon icon-<%- fileType %>"></span>
        </span>
        <span class="filename ax-grid__col--auto-size">
          <span><%- name %></span>
          <% if (isViewable) { %>
            <span class="subtitle"><%- t('trainingPreview.pdfButtonLabel') %></span>
          <% } else if (showDownloadLink) { %>
            <% if (!isInMobileApp) { %>
              <%= downloadLink(uuid, name, {
                text: t('general.download')
              }) %>
            <% } else { %>
              <span class="download-unavailable"><%- t('general.downloadUnavailableInMobileApp') %></span>
            <% } %>
          <% } %>
        </span>
      </span>

      <% if (isViewable) { %>
        </button>
      <% } else { %>
        </div>
      <% } %>
    `;
  }

  ui() {
    return {
      grid: '.ax-grid'
    };
  }

  modelEvents() {
    return {
      sync: 'render'
    };
  }

  events() {
    const events = {};

    if (this._isViewable()) {
      events.click = 'viewFile';
    }

    return events;
  }

  _isViewable() {
    let fileType = '';

    if (this.model.get('originalFile')?.get('originalFileName')) {
      const viewModel = MediaViewModel.fromMedia(this.model);
      fileType = viewModel.getFileType();
    }

    return this.shouldBeViewable && (fileType === 'pdf');
  }

  viewFile() {
    this.viewCb(this.model);
  }

  templateHelpers() {
    let fileType = '';
    let name = this.file.name;
    let uuid = '';
    let showDownloadLink = false;
    let isViewable = false;

    if (this.model.isSynced() || (this.model.get('originalFile')?.get('uuid') && this.model.get('originalFile')?.get('originalFileName'))) {
      const viewModel = MediaViewModel.fromMedia(this.model);
      fileType = viewModel.getFileType();
      name = viewModel.getName();
      uuid = viewModel.getUUID();
      showDownloadLink = this.showDownloadLink;
      isViewable = this._isViewable();
    }

    return {
      name,
      fileType,
      uuid,
      showDownloadLink,
      isInMobileApp: $os.isInMobileApp(),
      isViewable
    };
  }

  onRender() {
    this.ui.grid.css('height', this.maxDimensions.get('maxHeight'));
  }
}

module.exports = FileNameView;
