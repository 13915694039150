const { app } = window;

module.exports = {
  showImageZoomView: (media) => {
    window.app.layout.showMedia(media);
  },
  closeModal: () => {
    window.app.layout.dismissMedia();
  }
};
