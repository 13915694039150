import {
  ListItemButton as MuiListItemButton,
  styled,
  type ListItemButtonTypeMap as MuiListItemButtonTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type PropsToOmit = 'disableRipple' | 'disableTouchRipple' | 'focusRipple' | 'TouchRippleProps' | 'touchRippleRef' | 'centerRipple';
type AxListItemButtonCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxListItemButtonTypeMap<P = object, D extends React.ElementType = 'div'> = {
  defaultComponent: D;
  props: P & Omit<MuiListItemButtonTypeMap['props'], PropsToOmit> & AxListItemButtonCustomProps;
};


export type AxListItemButtonProps<
  D extends ElementType = AxListItemButtonTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxListItemButtonTypeMap<P & AxListItemButtonCustomProps, D>, D>;

const styledOptions = {
  name: 'AxListItemButton'
};

const StyledAxListItemButton = styled(MuiListItemButton, styledOptions)<AxListItemButtonProps>(() => {
  return {};
});

/**
 * An action element to be used inside a list item.
 * Makes the entire list item clickable.
 *
 * ### Links
 * - • [MUI ListItemButton | API](https://mui.com/material-ui/api/list-item-button/)
 *
 * @example
 * <AxListItemButton>
 *    <AxListItemText primary="Spam" />
 * </AxListItemButton>
 */
export const AxListItemButton: MuiOverridableComponent<AxListItemButtonTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxListItemButtonProps, ref: Ref<HTMLDivElement>) => {
  const ListItemButtonProps: AxListItemButtonProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemButton
      { ...ListItemButtonProps }
      ref={ ref }
      disableRipple
      disableTouchRipple
    >
      { children }
    </StyledAxListItemButton>
  );
});

export default AxListItemButton;
