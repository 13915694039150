import { DialogTitle } from '@mui/material';
import { type PropsWithChildren } from 'react';

import {
  AxDialogCloseButton,
  AxDialogDefaultTitle,
  AxStack
} from '@common/modules/react/themes/components';
import AxDialogBackButton from '../AxDialogBackButton';

import { type AxTypographyProps } from '@common/modules/react/themes/components/data-display';

export type AxDialogDefaultHeaderProps = PropsWithChildren<{
  qaClassName: `qa-${ string }`
  onClose?: () => void,
  onBack?: () => void
  titleProps?: AxTypographyProps
}>;

export default function AxDialogDefaultHeader(props: AxDialogDefaultHeaderProps): JSX.Element {
  const {
    children,
    qaClassName,
    onClose,
    onBack,
    titleProps
  } = props;

  const hasTitle = children != null;
  const closable = onClose != null;
  const backable = onBack != null;

  const backComp = backable && <AxDialogBackButton onBack={ onBack } qaClassName={ `${ qaClassName }-back` }/>;
  const titleComp = hasTitle && <AxDialogDefaultTitle { ...titleProps } qaClassName={ `${ qaClassName }-title` } >{ children }</AxDialogDefaultTitle>;
  const closeComp = closable && <AxDialogCloseButton onClose={ onClose } qaClassName={ `${ qaClassName }-close` }/>;

  return (hasTitle || closable) ? <DialogTitle component='div' className={ qaClassName }>
    <AxStack
      flexDirection='row'
      justifyContent={ !backable && !hasTitle && closable ? 'flex-end' : 'space-between' }
      alignItems='center'
      minHeight='3.6rem'
    >
      <AxStack
        direction='row'
        spacing={ 1 }
        alignItems='center'
        maxWidth='90%'>
        { backComp }
        { titleComp }
      </AxStack>
      { closeComp }
    </AxStack>
  </DialogTitle> : <></>;
}
