import { useIsMobile } from '@common/util/useScreenSize';
import {
  Pagination as MuiPagination,
  styled,
  type PaginationProps as MuiPaginationProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type StyledPaginationProps = MuiPaginationProps;
type PropsToOmit = 'color' | 'shape' | 'size' | 'variant';
export type AxPaginationProps = Omit<MuiPaginationProps, PropsToOmit>;

const styledOptions = {
  name: 'AxPagination'
};

const StyledAxPagination = styled(MuiPagination, styledOptions)<StyledPaginationProps>(({theme}) => {
  return {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: theme.uiKit.pagination.pageItem.selected.background,
        border: theme.uiKit.pagination.pageItem.selected.border
      }
    },
    '& .MuiPaginationItem-page': {
      padding: theme.uiKit.pagination.pageItem.padding,
      fontWeight: theme.uiKit.pagination.pageItem.fontWeight,
      color: theme.uiKit.pagination.pageItem.color
    }
  };
});

/**
 *
 * A wrapper around the MUI Pagination component.
 *
 * The component will automatically adjust its appearance based on the screen size.
 *
 * > ℹ️ NOTE <br />
 * > Backbokne legacy component has slightly different behavior.
 *
 * ### Links
 * [MUI](https://mui.com/components/pagination/) <br />
 * [Figma](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=7246-4620&t=BPPgE7IOGBGfoTJy-0)
*/
export const AxPagination = forwardRef(({
  ...otherProps
}: AxPaginationProps, ref: Ref<HTMLDivElement>) => {
  const isMobile = useIsMobile();

  const PaginationProps: StyledPaginationProps = {
    size: isMobile ? 'small' : 'medium',
    siblingCount: isMobile ? 0 : 1,
    showFirstButton: !isMobile,
    showLastButton: !isMobile,
    ...otherProps
  };

  return (
    <StyledAxPagination
      { ...PaginationProps }
      ref={ ref }
      shape='rounded'/>
  );
});

export default AxPagination;
