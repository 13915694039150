const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  // this describes a two-bit binary bitmask
  // if you are wondering how this works, ask Geordie he loves these things

  // LEFT and DOWN alignment is the default, so if omitted (ie zero) then that's how things will align
  // these really don't need to be defined, but for code readability it is nice to have them
  LEFT: 0,
  BOTTOM: 0,

  // bits in ascending powers of two for RIGHT and TOP.
  RIGHT: 1,
  TOP: 2

  // So, if you want the popup to go RIGHT and TOP, then that's (RIGHT + TOP) which = 3
  // So, if you want the popup to go LEFT and TOP, then that's (LEFT + TOP) which = 2
  // So, if you want the popup to go RIGHT and DOWN, then that's (RIGHT + DOWN) which = 1
  // So, if you want the popup to go LEFT and DOWN, then that's (LEFT + DOWN) which = 0

});
