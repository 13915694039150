const _ = require('underscore');
const { LayoutView } = require('Marionette');

const LoadingController = require('@common/components/loading/LoadingController');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

require('@common/libs/behaviors/card/Card');
const { INFORMATIONAL, MEDIUM_PADDING } = require('@common/libs/behaviors/card/CardModifierClasses');

const DefaultActionButtonView = require('./TopicDetailsDefaultActionButton');
const UserTopicScore = require('./UserTopicScore');
const AverageTopicScore = require('./AverageTopicScore');

const getTopicPerformanceControllerDefinition = (topicData = {}, teamScoreData = {}, startTrainingFn) => {

  if (_.isEmpty(topicData)) {
    return {};
  }

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LayoutView,
      className: 'topic-performance',
      template: `
        <div class="performance ax-grid ax-grid--justify-content-center ax-grid--align-items-center ax-grid--no-gutter ax-grid--m-direction-column">
          <h2 class="regularfont title ax-grid__col--m-3"><%= t('general.performance') %></h2>
          <div class="user-stats-region ax-grid ax-grid--justify-content-center ax-grid--no-gutter ax-grid--m-direction-column"></div>
          <div class="team-stats-region ax-grid ax-grid--justify-content-center ax-grid--no-gutter ax-grid--m-direction-column"></div>
        </div>
        <div class="cta-button-container ax-grid ax-grid--justify-content-center"></div>
      `,
      regions: {
        userScoreRegion: '.user-stats-region',
        teamScoreRegion: '.team-stats-region',
        ctaButtonContainer: '.cta-button-container'
      },
      behaviors: {
        Card: {
          target: '.performance',
          modifierClasses: [INFORMATIONAL, MEDIUM_PADDING]
        }
      }
    },
    regionControllers: {
      userScoreRegion: {
        viewDefinition: {
          ViewClass: UserTopicScore,
          model: topicData
        }
      },
      teamScoreRegion: {
        ViewControllerClass: LoadingController.Wrapper,
        syncTracker: {
          entities: teamScoreData,
          loadOnSync: true
        },
        viewDefinition: {
          spinner: {
            type: 'small'
          }
        },
        contentControllerDefinition: {
          viewDefinition: {
            ViewClass: AverageTopicScore,
            model: teamScoreData
          }
        }
      },
      ctaButtonContainer: {
        viewDefinition: {
          ViewClass: DefaultActionButtonView,
          model: topicData,
          startTrainingFn
        }
      }
    },
    delegateEvents: {
      'view:render': (controller, view) => {
        const hasQuestionsArray = _.filter(topicData.get('topicLevels'), (topic) => {
          return topic.questionCount > 0;
        });

        //No topic level has questions, thus hide performance region
        if (_.isEmpty(hasQuestionsArray)) {
          view.$el.find('.performance').addClass('hidden');
        }
      }
    }
  };
};

module.exports = {
  getTopicPerformanceControllerDefinition
};
