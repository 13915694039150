const logging = require('logging');

const SessionTrainingType = require('@common/data/enums/SessionTrainingType');
const InitialDailyTrainingAssessmentInitiatorConfig = require('./InitialDailyTrainingAssessmentInitiatorConfig');
const AssessmentInitiatorWithPreludeViewDecorator = require('./AssessmentInitiatorWithPreludeViewDecorator');

/**
 * It is the responsibility of this class to find you an initiator for the training you should be completing
 * given your SessionTrainingType -- and according to the requirements for showing it.
 *
 * For example, if you SessionTrainingType is DailyTraining and you have not completed it yet, it will serve you
 * a controller to do this type of training.
 *
 */
class TrainingSessionAssessmentInitiatorProvider {
  constructor(trainingSession, initiatorRegistry, startPageFactory) {
    this.trainingSession = trainingSession;
    this.initiatorRegistry = initiatorRegistry;
    this.startPageFactory = startPageFactory;

    // this is used to store the mapping
    this.mapping = {};
  }

  registerInitiatorForSessionType(sessionType, initiatorController, initiatorShouldBeShownPredicate, configurationProvider) {
    if (this.mapping[sessionType]) {
      throw new Error('You may only register one initiator config for a given session type');
    }

    this.mapping[sessionType] = new InitialDailyTrainingAssessmentInitiatorConfig(
      sessionType,
      initiatorController,
      initiatorShouldBeShownPredicate,
      configurationProvider
    );
  }

  getInitiatorAsync(pageFactory, useType) {
    const sessionTrainingType = useType || this.trainingSession.getTrainingSessionType();

    return Promise
      .try(() => {
        return this._getConfigForTrainingType(sessionTrainingType);
      })
      .then((candidateConfiguration) => {
        const intitiator = this._getConfiguredInitiatorFromCandidate(candidateConfiguration);
        const candidatePredicateAsync = candidateConfiguration.getAsyncPredicate();
        return candidatePredicateAsync().then((isAwaitingCompletion) => {
          if (isAwaitingCompletion) {
            return new AssessmentInitiatorWithPreludeViewDecorator(
              intitiator,
              pageFactory || this.startPageFactory
            );
          }
          return null;

        });
      })
      .catch(() => {
        return null;
      });
  }

  getInitiatorForTypeAsync(sessionTrainingType) {
    return Promise
      .try(() => {
        return this._getConfigForTrainingType(sessionTrainingType);
      })
      .then((candidateConfiguration) => {
        return this._getConfiguredInitiatorFromCandidate(candidateConfiguration);
      })
      .catch(() => {
        return null;
      });
  }

  _getConfiguredInitiatorFromCandidate(candidate) {
    const initiator = candidate.getInitiator();
    initiator.setConfigurationProvider(candidate.getConfigurationProviderToInject());

    return initiator;
  }

  _getConfigForTrainingType(sessionTrainingType) {
    const candidateConfiguration = this.mapping[
      SessionTrainingType.assertLegalValue(sessionTrainingType)
    ];

    if (!candidateConfiguration) {
      logging.warn(`Detected a training session type of ${ sessionTrainingType } but nothing was provided that was capable of handling it.
        Did you forget to register an initiator to handle it?`);

      throw new Error();
    }

    return candidateConfiguration;
  }
}

module.exports = TrainingSessionAssessmentInitiatorProvider;
