const Backbone = require('Backbone');
const CustomerApiKey = require('@common/data/models/CustomerApiKey');

class CustomerApiKeyList extends Backbone.Collection {
  get model() {
    return CustomerApiKey;
  }

  apiEndpoint() {
    return `pageCommunities/${ this.communityId }/customerKeys`;
  }

  initialize(models = [], options = {}) {
    this.communityId = options.communityId;
    super.initialize(models, options);
  }

  parse(json) {
    return json.results;
  }
}

module.exports = CustomerApiKeyList;
