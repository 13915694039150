// Module
var code = `<% if (completedMsg) { %>
  <div class="task-completed-msg">
      <span class="task-completed-indicator">
        <span class="icon-ok" role="presentation" aria-hidden="true"></span>
      </span>
      <p><%- completedMsg %></p>
  </div>
<% } %>
<p class="task-description"><%= taskDescription %></p>
`;
// Exports
module.exports = code;