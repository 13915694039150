// Module
var code = `<% if (linkColour) { %>
  a,
  a.regular-crumb {
    color: <%- linkColour %>;
  }

  /*content drill ins*/
  [dir] .show-on-parent-hover:hover,
  [dir] .show-on-parent-hover:hover {
    border-color: <%- linkColour %>;
  }
  .show-on-parent-hover:hover,
  .show-on-parent-hover:hover {
    color: <%- linkColour %>;
  }
<% } %>
`;
// Exports
module.exports = code;