// Module
var code = `<!--QUESTION-->
<div class="fitb">

  <!--ANSWERS-->
  <div class="questionarea">
    <div class="answerwrapper">

      <div class="fillintheblanks">
        <div class="fitbleftside">
          <% if(getQuestionImage()) {%>
              <div class="fitbimage questionimage image-wrapper imgwrap " data-media-id="<%- getQuestionImage().id %>"></div>
          <%}%>
        </div>
        <div class="fitbrightside">
          <div class="questionask">
            <div id="fitb-question-text"></div>
          </div>
        </div>

      </div>

    </div>
    <div class="bonuscharacter">
      <div class="<%- bonuscharacter %> ponder" aria-hidden="true"></div>
    </div>
  </div>

  <div class="hinttext <%- answerStatus %>">
    <div class="question-instruction clearfix">
      <span id="balance-points-wrapper" class="clearfix <%- answerStatus %>">
        <span class="correct-incorrect full-size"><%- answerStatusText %></span>
      </span>
    </div>
    <div id="reason" class="clearfix">
      <% if(reasonImage) {%>
        <div class="image-wrapper imgwrap reason-image" data-media-id="<%- reasonImage.id %>"></div>
      <%}%>
      <div id="reasonContent"><%= getReasonText() %></div>
    </div>

  </div>

</div>
`;
// Exports
module.exports = code;