// Module
var code = `<div class="task-detail-info-wrapper">
  <h3 class="task-detail-label"><%- t('tasks.details.subjectHeader') %></h3>
  <h3 class="task-detail-name"><%- subjectName %></h3>
</div>
<div class="task-detail-info-wrapper">
  <h3 class="task-detail-label"><%- t('tasks.details.topicHeader') %></h3>
  <h3 class="task-detail-name"><%- topicName %></h3>
</div>
`;
// Exports
module.exports = code;