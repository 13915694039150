const Started = {
  iconState: 'active',
  iconClass: 'play'
};

const Done = {
  iconState: 'active',
  iconClass: 'ok'
};

const Failed = {
  iconState: 'failed',
  iconClass: 'remove'
};

module.exports = {
  Started,
  Done,
  Failed
};
