const { Model } = require('Backbone');

class TrainingCardModel extends Model {
  static FIELDS = {
    ACTION: 'action',
    ASSESSMENT_TYPE: 'assessmentType',
    CARD_ACTION_META_DATA: 'actionMetaData',
    CARD_TYPE: 'cardType',
    META_DATA: 'metadata',
    IS_ASSIGNED: 'assigned',
    IS_ENROLLED: 'enrolled',
    IS_RECOMMENDED: 'recommended',
    TITLE: 'title',
    SUBJECT_NAME: 'subjectName',
    THUMBNAIL_IMAGE: 'thumbnailImage',
    MEDIA: 'media',
    NAME: 'name',
    DAYS_UNTIL_DUE: 'daysUntilMilestoneDue',
    IS_COMPLETE: 'completed',
    NUM_COMPLETE: 'completedCount',
    TOTAL: 'count',
    PROGRESS: 'progressToCompletion',
    TOPIC_LEVELS: 'topicLevels',
    HAS_REASSIGNED_ITEMS: 'hasReassignedItems'
  };

  getCardType() {
    return this.get(TrainingCardModel.FIELDS.CARD_TYPE);
  }

  getCardActionMetaData() {
    return this.get(TrainingCardModel.FIELDS.CARD_ACTION_META_DATA);
  }

  getAction() {
    return this.get(TrainingCardModel.FIELDS.ACTION);
  }

  getActionType() {
    const action = this.getAction();
    if (action && action.actionType) {
      return action.actionType;
    }

    return null;
  }

  getIsAssigned() {
    return this.get(TrainingCardModel.FIELDS.IS_ASSIGNED);
  }

  getIsEnrolled() {
    return this.get(TrainingCardModel.FIELDS.IS_ENROLLED);
  }

  getIsRecommended() {
    return this.get(TrainingCardModel.FIELDS.IS_RECOMMENDED);
  }

  getTitle() {
    return this.get(TrainingCardModel.FIELDS.TITLE);
  }

  getSubjectName() {
    return this.get(TrainingCardModel.FIELDS.SUBJECT_NAME);
  }

  getProgress() {
    return this.get(TrainingCardModel.FIELDS.PROGRESS);
  }

  getThumbnailImage() {
    const media = this.get(TrainingCardModel.FIELDS.MEDIA);
    if (media) {
      return media;
    }

    return this.get(TrainingCardModel.FIELDS.THUMBNAIL_IMAGE);
  }

  getName() {
    return this.get(TrainingCardModel.FIELDS.NAME);
  }

  getDaysUntilDue() {
    return this.get(TrainingCardModel.FIELDS.DAYS_UNTIL_DUE);
  }

  getIsComplete() {
    return this.get(TrainingCardModel.FIELDS.IS_COMPLETE);
  }

  getNumCompleted() {
    return this.get(TrainingCardModel.FIELDS.NUM_COMPLETE);
  }

  getTotal() {
    return this.get(TrainingCardModel.FIELDS.TOTAL);
  }

  getTopicLevels() {
    return this.get(TrainingCardModel.FIELDS.TOPIC_LEVELS);
  }

  hasReassignedItems() {
    return this.get(TrainingCardModel.FIELDS.HAS_REASSIGNED_ITEMS);
  }
}

module.exports = TrainingCardModel;
