const Backbone = require('Backbone');
const QuestionOption = require('@common/data/models/questions/QuestionOption');

class QuestionOptionList extends Backbone.Collection {

  model(attrs, options) {
    return new QuestionOption(attrs, options);
  }
}

module.exports = QuestionOptionList;
