const Backbone = require('Backbone');
const Marionette = require('Marionette');
const {
  defaults,
  partial,
  clone,
  isFunction,
  wrap
} = require('underscore');

const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

const DEFAULT_REGION_EL_SELECTOR = '$el';
const VIEW_TRANSITION_DURATION = 100;
const Transitions = {
  FADE: 'fade',
  NONE: ''
};

// DEPRECATED - DO NOT USE FOR NEW CODE!!!
// This is only here to support legacy Views that haven't been upgraded to straight up Marionette Views.
// A subclass of Marionette.LayoutView that supports legacy view hierarchies
class LegacyUIKitView extends Marionette.LayoutView {
  static Transitions = Transitions;

  useMarionetteRender() {
    return false;
  }

  preinitialize() {
    this.destroyImmediate = true;
    this.subviewRegions = [];
    this.fadingElements = new Set();
    this.waitingSubviews = new Set();

    this.setSubviewIn = this.setSubviewIn.bind(this);
    this._setSubviewIn = this._setSubviewIn.bind(this);
    this.unload = this.unload.bind(this);
    this.getErrors = this.getErrors.bind(this);
  }

  _initializeRegions(options) {
    /// We override this function since it allows us to hook into the render pipeline
    // early enough that render has not been found and we can still do direct comparisons
    // to the render function itself.

    // Make sure the use of Marionettes' render pipeline is explicitly opt-in for UIKit.View sub classes
    if (!Marionette._getValue(options.useMarionetteRender || this.useMarionetteRender)) {
      // If sub class' render method is LayoutView::render method then we want to replace it with
      // Backbone.View::render since useMarionetteRender is false. If it's not LayoutView::render method
      // then we leave it alone since it's been explicitly overridden by the sub class.
      // (Covers the case of all old UIKitView sub classes that implement their own render.
      const renderOverride = this.render === Marionette.LayoutView.prototype.render
        ? Backbone.View.prototype.render : this.render;
      this.render = partial(this._UIKitRender, renderOverride);
    }

    super._initializeRegions(options);
  }

  _UIKitRender(render, ...args) {
    this._ensureViewIsIntact();

    // Preserve render functionality from Marionette.LayoutView.render v2.4.7
    if (this._firstRender) {
      this._firstRender = false;
    } else {
      this._reInitializeRegions();
    }

    // Preserve functionality  from Marionette.ItemView.render v2.4.7 but use
    // the passed in render method instead of _renderTemplate.
    this.triggerMethod('before:render', this);

    render.apply(this, args);

    this.isRendered = true;

    this.bindUIElements();
    this.triggerMethod('render', this);

    return this;
  }

  _reInitializeRegions() {
    this.subviewRegions.forEach((regionName) => {
      if (this.getRegion(regionName) != null) {
        this.removeRegion(regionName);
      }
    });
    this.subviewRegions = [];
    super._reInitializeRegions();
  }

  // Fade in a subview
  setSubviewIn(v, options = {}) {
    const {
      transition = Transitions.FADE,
      regionSelector,
      fadeEl
    } = options;

    switch (transition) {
      case Transitions.FADE:
        this.waitingSubviews.add(v);
        this.fade(0, {
          fadeEl,
          complete: () => {
            this._setSubviewIn(v, regionSelector);
            this.waitingSubviews.delete(v);
            this.fade(1, {
              fadeEl
            });
          }
        });
        break;
      default:
        this._setSubviewIn(v, regionSelector);
    }
  }

  fade(opacity, options = {}) {
    defaults(options, { duration: VIEW_TRANSITION_DURATION });

    const $fadingElement = this.getFadingEl(options);
    this.fadingElements.add($fadingElement);

    options.complete = wrap(options.complete, (origComplete, ...args) => {
      this.fadingElements.delete($fadingElement);
      origComplete?.(...args);
    });
    $fadingElement.velocity({opacity}, options);
  }

  getFadingEl({ fadeEl } = {}) {
    return this.$(fadeEl) || this.$el;
  }

  // Set a subview in a container div
  _setSubviewIn(v, regionSelector = DEFAULT_REGION_EL_SELECTOR) {
    // Check if there's already a Region for the given container or generate it doesn't exist yet.
    const existingRegion = this.getRegion(regionSelector);
    const regionEl = regionSelector === DEFAULT_REGION_EL_SELECTOR ? this.$el : regionSelector;
    const region = existingRegion != null ? existingRegion : this.addRegion(regionSelector, { el: this.$(regionEl) });

    this.subviewRegions.push(regionSelector);

    // Attach the superview and container props to maintain backwards compatability
    v.superview = this;
    v.container = region.$el;

    this.triggerMethod('before:set:subview:in', v, regionSelector);

    // Show the given view in the containers Region which makes sure the previous view is destroyed.
    region.show(v);

    // Execute viewDidAppear to maintain backwards compatability.
    if (isFunction(v.viewDidAppear)) {
      v.viewDidAppear();
    }

    this.triggerMethod('set:subview:in', v, regionSelector);

    // Notify any views that care about element size changes to readjust themselves.
    this.triggerAdjustment();
  }

  removeSubview(regionSelector = DEFAULT_REGION_EL_SELECTOR) {
    if (regionSelector != null) {
      const region = this.getRegion(regionSelector);
      if (region) {
        region.empty();
      }
    }
  }

  unload() {
    this.destroy();
  }

  destroy() {
    // Stop the fade animations if destroyed
    this.cancelAnimation();

    // Prevent the setup of subviews if destroyed
    this.stopSubviewIn();

    // Remove the DOM element
    super.destroy();

    // Cleanup the references that were set on the view in _setSubviewIn as a precaution.
    delete this.superview;
    delete this.container;

    this.isDestroyed = true;

    // Call onClose() if specified
    if (isFunction(this.onClose)) {
      this.onClose();
    }
  }

  cancelAnimation() {
    this.fadingElements.forEach((element) => {
      element.velocity('stop');
    })
    this.fadingElements.clear();
  }

  stopSubviewIn() {
    this.waitingSubviews.forEach((subview) => {
      subview.destroy();
    });
    this.waitingSubviews.clear();
  }

  remove() {
    // Detach all event handlers
    this.unbind();
    return super.remove();
  }

  // This triggers a change for the case when data changes affect the layout,
  // ie. KM and News (#801)
  triggerAdjustment() {
    triggerResize(true);
  }

  viewDidAppear() {
    // to be implemented in a subclass
  }

  getErrors() {
    return (this.errors != null ? this.errors.length : undefined);
  }

  onClose() {
    // to be implemented in a subclass
  }

  onShow() {
    // We don't proxy this for things with superviews -- we allow their parents to call it on them
    if (this.superview == null) {
      this.viewDidAppear();
    }
  }

  attachEvent(eventType, selector, handler) {
    this.undelegateEvents();
    this.events = clone(this.events);
    this.events[`${ eventType } ${ selector }`] = handler;
    this.delegateEvents();
  }

  detachEvent(eventType, selector) {
    this.undelegateEvents();
    this.events = clone(this.events);
    delete this.events[`${ eventType } ${ selector }`];
    this.delegateEvents();
  }
}

module.exports = LegacyUIKitView;
