const Backbone = require('Backbone');

/*
  - active
  - daysSinceLastLogin
  - bountyDuration
  - initialLoginPoints
  - subsequentLoginPoints
  - bonusPointsDuration
  - referralTarget
    - One of: OwnTeam, ParentTeam, Everyone
*/

class BountyConfiguration extends Backbone.Model {
  apiEndpoint() {
    return '/bounty/config';
  }

  parse(res) {
    // when we save this model, the server returns 'status:ok'
    // which should not be part of the model so delete it
    // and return the res
    delete res.status;
    return res;
  }

  save(attributes = {}, options = {}) {
    // The API server only supports 'PUT'
    // for this call
    options.type = 'PUT';

    return super.save(attributes, options);
  }
}

module.exports = BountyConfiguration;
