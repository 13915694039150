import {
  AxIcon,
  type AxIconProps
} from '@common/modules/react/themes/components';
import { useTheme } from '@mui/material';

export default function DangerIcon(props: AxIconProps) {
  const {
    sx = {},
    ...otherProps
  } = props;

  const theme = useTheme();
  
  return (
    <AxIcon
      className='icon-warning_sign'
      sx={{
        color: theme.uiKit.colorSemanticNegative,
        ...sx
      }}
      { ...otherProps }
    />
  );
}
