// Module
var code = `<div class="page-header">
	<div class="ax-container relative">
		<div class="ax-grid ax-grid--no-gutter ax-grid--align-items-center">
			<h1 class="page-header__content">
				<span class="page-header__icon icon-training" aria-hidden="true"></span>
				<span class="page-header__title"><%- t('learnerTrainingPage.title') %></span>
			</h1>
			<div class="ax-grid__col--auto-size">
				<%= axButton({
					className: 'qa-how-to-link js-how-to-link how-to-link',
					ariaLabel: t('general.howItWorks'),
					iconClassLeft: 'icon-question_sign icon--reset-styles',
					label: t('general.howItWorks'),
					size: 'm'
				}) %>
				<div class="js-how-to__modal hidden"></div>
			</div>
		</div>
	</div>
</div>

<div class="ax-container">
	<div class="page-content">
		<div class="learner-daily-training"></div>
		<% if (!app.sessionController.isMinDailyTrainingFulfilled()) { %>
			<p class="ax-font--bold"><%- t('learnerTrainingPage.extraTrainingAvailabilityMessage') %></p>
		<% } %>
		<div class="learner-training-section-tab"></div>
	</div>
</div>

`;
// Exports
module.exports = code;