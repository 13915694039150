const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const ArticleDetailsCommentFormView = require('@training/apps/articles/ArticleDetailsCommentFormView');
const Comment = require('@training/apps/articles/ArticleDetailsCommentModel');
const I18n = require('@common/libs/I18n');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
class ArticleDetailsCommentFormController extends LayoutController {

  initialize(options) {
    ({
      collection: this.collection
    } = options);

    this.initializeModel = this.initializeModel.bind(this);
    this.model = this.initializeModel();

    this.viewDefinition = this.viewDefinition.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.onSaveError = this.onSaveError.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: ArticleDetailsCommentFormView,
      model: this.model
    };
  }

  scrollToFormInput(wasLoadDelayed = false) {
    this.getView().scrollToFormInput(wasLoadDelayed);
  }

  initializeModel() {
    const authorUserId = window.apps.auth.session.user.id;
    const pageId = this.collection.pageId;
    return new Comment({
      authorUserId,
      pageId
    });
  }

  onSaveSuccess(view, result) {
    const newModel = this.initializeModel();
    window.app.layout.flash.success(I18n.t('messages.ArticleDetailsCommentModel.POST.success'));
    this.collection.add(result);
    this._view.form.update(newModel);
    this.model = newModel;
    view.clearInput();
  }

  onSaveError(view, model, response) {
    const exception = AxonifyExceptionFactory.fromResponse(response);

    response.skipGlobalHandler = true;

    const errCode = exception.getErrorCode();

    if (errCode === AxonifyExceptionCode.CLIENT_ERROR_INVALID_INPUT) {
      window.app.layout.flash.error(I18n.t('messages.ArticleDetailsCommentModel.POST.error.3016'));
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_PROFANE_LANGUAGE) {
      view.showInappropriateContentErr();
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_UNSAFE_CONTENT) {
      window.app.layout.flash.error(I18n.t('messages.ArticleDetailsCommentModel.POST.error.3064'));
    } else {
      window.app.layout.flash.error(I18n.t('messages.ArticleDetailsCommentModel.POST.error.default'));
    }
  }

  onViewPostComment(controller, view) {
    this.model.save({}, {
      sender: this.model,
      success: this.onSaveSuccess.bind(this, view),
      error: this.onSaveError.bind(this, view)
    });
  }

}

module.exports = ArticleDetailsCommentFormController;
