const Marionette = require('Marionette');


/*
 * In the DOM this is at the top of the guided Learning page, containing the tabs X days away, and the Milestone status
 * Created By: ProgramOverviewProgressController
 */
class EnrollmentSelectorView extends Marionette.LayoutView {
  getTemplate() {
    return require('@training/apps/training/views/selfDirectedPaths/programs/EnrollmentSelectorView.html');
  }

  ui() {
    return {
      enrollButton: '.js-enroll',
      unenrollDropdown: '.unenroll'
    };
  }

  regions() {
    return {
      unenrollTrigger: '.unenroll__trigger'
    };
  }

  className() {
    return 'enroll';
  }

  triggers() {
    return {
      'click .js-enroll': 'enroll'
    };
  }

  onRender() {
    const isEnrolled = this.model.getEnrollmentStatus();
    this._toggleEnrollButton(isEnrolled);
  }

  _toggleEnrollButton(isEnrolled) {
    this.ui.enrollButton.toggleClass('hidden', isEnrolled);
    this.ui.unenrollDropdown.toggleClass('hidden', !isEnrolled);
  }
}

module.exports = EnrollmentSelectorView;
