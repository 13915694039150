const { extend } = require('underscore');
const { Model } = require('Backbone');
const { LayoutView } = require('Marionette');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/mutationObservable/MutationObservable');

class FixedLayout extends LayoutView {
  getTemplate() {
    return `
      <div class="js-fixed"></div>
      <div class="js-content"></div>
    `;
  }

  behaviors() {
    return extend(super.behaviors(), {
      Resizable: {},
      MutationObservable: {
        target: '> .js-fixed',
        observeOptions: {
          attributes: true,
          childList: true,
          subtree: true
        }
      }
    });
  }

  childEvents() {
    return {
      attach: 'onChildAttach'
    };
  }

  ui() {
    return {
      fixed: '.js-fixed',
      content: '.js-content'
    };
  }

  constructor(options) {
    super(options);

    this.contentDimensionState = new Model({
      fixedHeight: null
    });

    this.listenTo(this.contentDimensionState, 'change:fixedHeight', this.onChangeFixedHeight);
  }

  onRender() {
    this.$el.addClass('fixed-region-layout fit-parent');
  }

  refreshContentHeight() {
    const fixedHeight = this.getHeight(this.ui.fixed);

    this.contentDimensionState.set('fixedHeight', fixedHeight);
  }

  toggleFixed(show = true) {
    this.ui.fixed.toggleClass('hidden', !show);
    this.refreshContentHeight();
  }

  onResize() {
    this.refreshContentHeight();
  }

  onDomMutate() {
    this.refreshContentHeight();
  }

  onChildAttach(childView) {
    const fixedRegion = this.fixedHeaderRegion || this.fixedFooterRegion;

    if (!fixedRegion || !this.fixedContentRegion) {
      return;
    }

    if (childView === fixedRegion.currentView || childView === this.fixedContentRegion.currentView) {
      this.refreshContentHeight();
    }
  }

  onChangeFixedHeight(model, fixedHeight) {
    this.ui.content.css('height', `calc(100% - ${ fixedHeight }px)`);
  }
}

module.exports = FixedLayout;
