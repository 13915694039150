const MediaModel = require('@common/libs/file/MediaModel');

class SCORMMedia extends MediaModel {

  defaults() {
    return {type: 'scorm'};
  }

  get idAttribute() {
    return 'id';
  }

  urlRoot() {
    return this.apiEndpointUrl();
  }
  
  apiEndpoint() {
    return '/scorm';
  }

  getFile() {
    return this.get('scormFile');
  }
}

module.exports = SCORMMedia;
