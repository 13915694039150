const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

// Generate a detailed reported banner view. Reported banners are unique from others
// in that they have a "reason" and "explanation" shown, and a "reportedBy"
const generateReportedArticleBannerView = (options = {}) => {
  return new ItemView({
    className: 'banner-wrapper',
    template: `
      <div id="reported-container" class="notify-container clearfix ${ options.className }">
        <div class="banner-context">
          <i class="icon-${ options.icon }"></i>
          <div class="info">
            <h3>${ options.reason }</h3>
            <div class="explanation">${ options.explanation }</div>
            <div class="by">${ options.reportedBy }</div>
          </div>
        </div>
      </div>
      `
  });
};

const generateInReviewArticleBannerView = (options = {}) => {
  return new ItemView({
    className: 'banner-wrapper',
    template: `
      <div class="notify-container ${ options.className }">

        <div class="banner-context">
          <i class="icon-${ options.icon }"></i>
          <div class="info">
            <h3>${ options.reason }</h3>
            <div class="explanation">${ options.explanation }</div>
          </div>
        </div>

        <% if (${ options.showButton }) { %>
          <button class="ax-button ax-button--branded js-button-publish qa-button-publish" type="button">${ I18n.t('general.publish') }</button>
        <% } %>

      </div>\
      `
  });
};

// Generate a simple banner view
const generateArticleBannerView = (options = {}) => {
  return new ItemView({
    className: 'banner-wrapper',
    template: `
      <div class="notify-container clearfix ${ options.className }">
        <div class="banner-context">
          <i class="icon-${ options.icon }"></i>
          <div class="info">
          <% if (${ options.showDeclarationText }) { %>
            <h3 class="banner-declaration ${ options.declarationClass }"> ${ options.declarationText } </h3>
          <% } %>
          <p>${ options.text }</p>
          </div>
        </div>
      </div>
    `
  });
};

module.exports = {
  generateArticleBannerView,
  generateReportedArticleBannerView,
  generateInReviewArticleBannerView
};
