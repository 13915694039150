const TopicSelectionForAssessmentInitiatorContext = require('@training/apps/training/controllers/assessments/TopicSelectionForAssessmentInitiatorContext');

class SelfDirectedAssessmentInitiatorContext extends TopicSelectionForAssessmentInitiatorContext {
  static SELF_DIRECTED_CONTEXT_TYPE = 'SelfDirectedContext';

  getType() {
    return SelfDirectedAssessmentInitiatorContext.SELF_DIRECTED_CONTEXT_TYPE;
  }
}

module.exports = SelfDirectedAssessmentInitiatorContext;
