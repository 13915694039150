const { LayoutView } = require('Marionette');

class TrainingCardBottomRegionLayoutView extends LayoutView {
  getTemplate() {
    return `
      <div class="js-progress-region training-card__progress-bar-wrapper <%- recommendedTagCssClass %>"></div>
      <div class="js-info-region training-card__bottom-info-wrapper"></div>
    `;
  }

  className() {
    return 'training-card__bottom';
  }

  templateHelpers() {
    const progress = this.getOption('progressPercent');
    const recommendedTagCssClass = progress === 0 || progress ? '' : 'hidden';

    return {
      recommendedTagCssClass
    };
  }

  regions() {
    return {
      progressRegion: '.js-progress-region',
      infoRegion: '.js-info-region'
    };
  }
}

module.exports = TrainingCardBottomRegionLayoutView;
