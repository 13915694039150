// Module
var code = `<div class="js-menu-dropdown-container menu-dropdown__container">
  <div class="js-menu-dropdown-ellipsis-container menu-dropdown__button-container">
    <button
      class="ax-button ax-button--full-width ax-button--text-align-left js-menu-dropdown-toggle-button qa-language-chooser-menu-dropdown-toggle-button"
      aria-label="<%= buttonConfig.parsedAriaLabel %>"
    >
      <div class="menu-dropdown-button-inner-wrapper">
        <span class="language-name"><%= buttonConfig.parsedButtonText %></span>
        <% if (buttonConfig.buttonPublishStateLabel !== '') { %>
        <span class="pill <%= buttonConfig.buttonPublishStateClass %>"><%= buttonConfig.buttonPublishStateLabel %></span>
        <% } %>
        <span class="icon-caret_down menu-dropdown__caret-down"></span>
      </div>
    </button>

  </div>

  <div class="js-menu-dropdown-popover menu-dropdown__popover mobile-overlay hidden">
    <ul>
    <% for (var i = 0; i < optionsListConfig.length; i++) { %>
      <li class="clear js-menu-dropdown-popover-option-<%- i %>">
        <button
          class="qa-language-chooser-popup-action-<%- i %> js-menu-dropdown-popover-action <%- optionsListConfig[i].buttonClass %> inline-block"
          aria-label="<%- optionsListConfig[i].parsedAriaLabel %>">

          <div class="menu-dropdown-button-inner-wrapper">
            <span class="dropdown-button-text"><%= optionsListConfig[i].parsedButtonText %></span>

            <% if (optionsListConfig[i].buttonPublishStateLabel !== '') { %>
              <span class="pill <%= optionsListConfig[i].buttonPublishStateClass %>"><%= optionsListConfig[i].buttonPublishStateLabel %></span>
            <% } %>
          </div>
        </button>
      </li>
    <% } %>
    </ul>
  </div>
</div>
`;
// Exports
module.exports = code;