const localeMapNoStrings = {
  'en-au': 'en', // English Australia (en-au) is only used for string overrides, English (en) should be used instead if loading a string bundle is required
  'en-gb': 'en' // English UK (en-gb) is only used for string overrides, English (en) should be used instead if loading a string bundle is required
};

module.exports = {
  getLocaleMappedForNoStrings: (locale) => {
    return localeMapNoStrings[locale] || locale;
  }
};
