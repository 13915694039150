class TaskIndexState {
  constructor() {
    this._backButtonCallbacks = {
      forDetailView: null
    };

    this._taskCompletionRecordToggledCallback = null;
    this._routePrefix = null;
  }

  getBackButtonCallbackForDetailView() {
    return this._backButtonCallbacks.forDetailView;
  }

  getRoutePrefix() {
    return this._routePrefix;
  }

  getTaskCompletionRecordToggledCallback() {
    return this._taskCompletionRecordToggledCallback;
  }

  update({
    detailViewBackButtonCallback,
    taskCompletionRecordToggledCallback,
    routePrefix
  }) {
    const routeChanged = this.getRoutePrefix() !== routePrefix;

    if (routeChanged) {
      this._backButtonCallbacks = {
        forDetailView: detailViewBackButtonCallback
      };
    } else {
      this._backButtonCallbacks = {
        forDetailView: detailViewBackButtonCallback || this._backButtonCallbacks.forDetailView
      };
    }

    this._taskCompletionRecordToggledCallback = taskCompletionRecordToggledCallback || this._taskCompletionRecordToggledCallback;
    this._routePrefix = routePrefix;
  }
}

function getTaskActionBarDetails(doesNeedCompletion) {
  const completeStringKey = doesNeedCompletion ? 'tasks.details.markComplete' : 'tasks.details.markIncomplete';
  const completeIcon = doesNeedCompletion ? 'icon-check' : 'icon-undo';

  return {
    completeStringKey,
    completeIcon
  };
}

module.exports = {
  TaskIndexState,
  getTaskActionBarDetails
};
