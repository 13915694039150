const GuidedLearningNextStartableAssessmentProvider = require('@training/apps/training/controllers/guidedLearning/GuidedLearningNextStartableAssessmentProvider');
const SelfDirectedPathOption = require('@common/data/models/assessments/SelfDirectedPathOption');

class SelfDirectedPathNextStartableAssessmentProvider extends GuidedLearningNextStartableAssessmentProvider {
  getOptionInstance(nextStartableItem, categoryId) {
    return new SelfDirectedPathOption(nextStartableItem, categoryId);
  }
}

module.exports = SelfDirectedPathNextStartableAssessmentProvider;
