const { ItemView } = require('Marionette');

class TagGroupTagItem extends ItemView {
  getTemplate() {
    return '<%- name %>';
  }

  tagName() {
    return 'li';
  }

  templateHelpers() {
    return {
      name: this.model.get('tag')
    };
  }
}

module.exports = TagGroupTagItem;
