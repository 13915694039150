import AxonifyExceptionCode, { type ValidAxonifyExceptionCodeValues } from '@common/services/error/AxonifyExceptionCode';
import { invert } from 'underscore';

export type ApiErrorResponse<ErrCode extends ValidAxonifyExceptionCodeValues = ValidAxonifyExceptionCodeValues, ErrorData extends object = EmptyObject> = {
  errCode: ErrCode;
  errMessage?: string;
  status?: 'OK' | 'KO';
} & ErrorData;

export class AxonifyException<ErrCode extends ValidAxonifyExceptionCodeValues = ValidAxonifyExceptionCodeValues, ErrorData extends object = EmptyObject> extends Error {
  errCode: ErrCode;

  errMessage: string;

  response: ApiErrorResponse<ErrCode, ErrorData>;

  constructor(response: ApiErrorResponse<ErrCode, ErrorData>) {
    const {
      errCode = AxonifyExceptionCode.NO_ERROR_CODE,
      errMessage = 'No error message specified'
    } = response;

    super(errMessage);

    this.name = 'AxonifyException';
    this.errCode = errCode as ErrCode;
    this.errMessage = errMessage;
    this.response = response;
  }

  getErrorCode() {
    return this.errCode;
  }

  /*
    ****** IMPORTANT NOTE ******

    The following method is for development usage only.

    Please consider the following when using it:
    1) All error messages are in English
    2) This message may not be included in production
  */
  getErrorMessage() {
    return this.errMessage;
  }

  getResponse() {
    return this.response;
  }

  /*
    ****** IMPORTANT NOTE ******

    The following method is for development usage only.

    Please consider the following when using it:
    1) All error messages are in English
  */
  getErrorKey() {
    const inversion = invert(AxonifyExceptionCode);
    return inversion[this.errCode] || 'No error key established';
  }
}

