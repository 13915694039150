const logging = require('logging');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const I18n = require('@common/libs/I18n');


const MfaHelpers = {
  mfaVerify(params = {}, options = {}) {
    const {
      error = () => {},
      complete = () => {},
      success = () => {},
      processParse = () => {},
      generateAndStoreReauthTokenAsyncCb = () => {},
      displayError = () => {},
      clearCredentialsCb = () => {},
      redirectCb = () => {},
      showSuperUserManagementPageCb = () => {},
      redirectToLoginCb = () => {},
      redirectToIndexCb = () => {}
    } = options;
    return $.ajax({
      type: 'POST',
      url: '/axonify/login/mfa/challenge',
      data: JSON.stringify(params),
      skipGlobalHandler: true
    })
      .then((data, status, xhr) => {
        return generateAndStoreReauthTokenAsyncCb()
          .finally(() => {
            const exception = AxonifyExceptionFactory.fromResponse(xhr);
            const errCode = exception.getErrorCode();
    
            processParse(data);
    
            if (errCode === AxonifyExceptionCode.CLIENT_ERROR_EXPIRED_PASSWORD) {
              redirectCb('#activate-account');
            } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_SUPERUSER_PASSWORD_EXPIRED) {
              const {superManagementAppUrl} = exception.getResponse();
              showSuperUserManagementPageCb(superManagementAppUrl);
            } else if (options.success) {
              success();
            } else {
              redirectToIndexCb()
            }
          });
      }, (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        logging.error(`auth App::login error. xhr.status = ${ xhr.status }, xhr.statusText = ${ xhr.statusText }`);
    
        switch (xhr.status) {
          case 400:
          case 401:
          case 302:
            if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_SUSPENDED_ACCOUNT) {
              displayError(I18n.t('flash.userSuspended'));
            } else if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_LOCKED_ACCOUNT) {
              displayError(I18n.t('flash.lockedOut'));
            } else {
              displayError(I18n.t('flash.mfaInvalid'));
            }
            break;
          default:
            displayError(I18n.t('flash.serviceDown'));
        }
        clearCredentialsCb(xhr);
        if (options.error) {
          error();
        } else {
          redirectToLoginCb();
        }
      })
      .always(() => {
        complete();
      });
  }
};

module.exports = MfaHelpers;
