const Marionette = require('Marionette');
const MediaPreview = require('@common/components/mediaPreview/MediaPreview');
const Form = require('@common/components/forms/Form');
const MediaInput = require('@common/components/forms/editors/mediaThumbnail/Form.Editor.MediaInput');

require('@common/components/forms/editors/mediaThumbnail/MediaThumbnail.less');

class MediaThumbnailLayout extends Marionette.LayoutView {
  getTemplate() {
    return `\
      <div class="input-container"></div>
      <div class="preview-container"></div>
      <div class="remove"></div>\
    `;
  }

  regions() {
    return {
      inputContainer: '.input-container',
      previewContainer: '.preview-container'
    };
  }

  triggers() {
    return {
      'click .remove': 'click:remove'
    };
  }
}

Form.Editor.MediaThumbnail = class MediaThumbnail extends Form.Editor {
  value = null;

  forceRender = false;

  initialize(options = {}) {
    this.options = options.options || options;
    this.maxWidth = this.$el.attr('maxWidth');
    this.maxHeight = this.$el.attr('maxHeight');

    this.fileFactory = this.getOption('fileFactory');
    this.fileOptions = this.getOption('fileOptions');
    this.useCaptureAttr = this.getOption('useCaptureAttr');

    this.zoomImageHandler = this.getOption('zoomImageHandler');
    this.loadingPredicate = this.getOption('loadingPredicate');
    this.skipGlobalHandler = this.getOption('skipGlobalHandler') || false;
    this.filenameViewTypes = this.getOption('filenameViewTypes');

    this.$cameraButton = this.getOption('$cameraButton');

    this.forceRender = this.getOption('forceRender');

    this.$el.addClass('media-thumbnail-container');

    this._layout = this._getLayout();
    this._mediaInputs = this._setupInputs(this.getOption('value'));

    // XXX - Need to be able to render this if it's being initialized from within a form or wait
    // till it's naturally rendered in side of any other view/region.
    if (this.forceRender) {
      this._renderLayout();
      this._showMediaInput();
      this._showMediaPreview(this.getValue());
    }
  }

  onRender() {
    this._renderLayout();
  }

  onBeforeShow() {
    this._showMediaInput();
  }

  onAttach() {
    this._showMediaPreview(this.getValue());
  }

  onClickRemove() {
    this.reset();
  }

  setValue(value) {
    this._mediaInputs.setValue(value);

    this._showMediaPreview(this.getValue());
    this._toggleVisibility(this.hasValue());
  }

  getValue() {
    return this._mediaInputs.getValue();
  }

  hasValue() {
    return this.getValue() != null;
  }

  reset() {
    this.setValue(null);
    this.onChange();
  }

  getError() {
    return this._mediaInputs.getError();
  }

  setError(error) {
    this._mediaInputs.setError(error);
  }

  clearError() {
    super.clearError();
    this._mediaInputs.clearError();
  }

  onDestroy() {
    this._layout.destroy();
  }

  _renderLayout() {
    this._layout.render();
  }

  _showMediaInput() {
    this._layout.inputContainer.show(this._mediaInputs);
  }

  _showMediaPreview(mediaModel) {
    if (mediaModel != null) {
      const preview = this._getPreviewView(mediaModel);

      this.listenTo(preview, 'load', (e, img) => {
        this.triggerMethod('image:loaded', img);
      });
      this.listenTo(preview, 'error', (e, img) => {
        this.triggerMethod('image:error', img);
      });

      this._layout.previewContainer.show(preview);
    } else {
      this._layout.previewContainer.empty();
    }
  }

  _setupInputs(initialValue) {
    const inputs = new MediaInput({
      fileFactory: this.fileFactory,
      $cameraButton: this.$cameraButton,
      useCaptureAttr: this.useCaptureAttr,
      fileOptions: this.fileOptions
    });

    inputs.setValue(initialValue);

    this.listenTo(inputs, 'change', (editor) => {
      this.setError(editor.getError());

      if (editor.getError() == null) {
        this.setValue(editor.getValue());
      }

      this.onChange();
    });

    return inputs;
  }

  _getLayout() {
    const layout = new MediaThumbnailLayout({
      el: this.$el
    });

    this.listenTo(layout, 'click:remove', this.onClickRemove);

    return layout;
  }

  _getPreviewView(mediaModel) {
    return new MediaPreview({
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight,
      model: mediaModel,
      zoomImageHandler: this.zoomImageHandler,
      loadingPredicate: this.loadingPredicate, // callback determines whether to hide the loading spinner.
      skipGlobalHandler: this.skipGlobalHandler,
      filenameViewTypes: this.filenameViewTypes
    });
  }

  _toggleVisibility(show = false) {
    if (show) {
      this.$el.css('visibility', 'visible');
    } else {
      this.$el.css('visibility', 'hidden');
    }
  }
};

module.exports = Form.Editor.MediaThumbnail;
