// Module
var code = `<div id="answer_option<%- id %>" data-answer-option="<%- id %>" data-answer-index="<%- i %>" data-letter-label="<%- label %>" class="<%- classname %> answer touchable clearfix">
  <div class="answerspacer">
    <div class="table-helper" cellpadding="0px">
      <div class="table-row-helper">
        <div class="table-cell-helper answerradio" tabindex="-1" role="radio" aria-label="<%= option %>" aria-checked="false">
          <div class="radiobutton"></div>
        </div>
      <% if (displayAsColumns === true) { %>
      </div>
      <div class="table-row-helper">
      <% } %>
        <div class="table-cell-helper answertext" aria-hidden="true" role="presentation">
          <label aria-hidden="true" role="presentation"><%- option %></label>
        </div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;