const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const LoadingWrapperView = require('@common/components/loading/wrapper/LoadingWrapperView');

const BlanketContextConfig = require('@common/modules/main/blanket/BlanketContextConfig');

const BlanketViewDefinitionFactory = () => {
  return {
    ViewClass: LoadingWrapperView,
    spinner: false
  };
};

const BlanketRegionControllerDefinitionFactory = (contextModel) => {
  return {
    contentRegion: () => {
      return BlanketContextConfig.getContentControllerDefinition(contextModel);
    }
  };
};

module.exports = (contextModel) => {
  return {
    id: 'content-blanket',
    ViewControllerClass: LayoutController,
    viewDefinition: BlanketViewDefinitionFactory(contextModel),
    regionControllers: BlanketRegionControllerDefinitionFactory(contextModel),
    delegateEvents: {
      'view:attach': (controller) => {
        controller.listenTo(contextModel, `change:${ BlanketContextConfig.Keys.BLANKET_CONTENT_CONTROLLER_DEFINITION }`, (state, definition, options = {}) => {
          controller.swapRegionController('contentRegion', definition, options.showOptions);
        });
      }
    },
    contextModel: contextModel
  };
};
