// Module
var code = `<form class="js-form translations-settings-form">
  <hr aria-hidden="true"/>
  <div class="translations-form__switch ax-grid ax-grid--no-gutter ax-grid--align-items-center">
    <h2><%- t('communitiesManagement.settings.enableTranslationsSwitch') %></h2>
    <div
      class="ax-grid__col--auto-size"
      data-editor="Switch"
      data-field="AUTO_TRANSLATIONS_ENABLED"
      data-options="enableTranslationsOptions"
    >
    </div>
  </div>
  <div class="ax-font ax-font--secondary ax-font__caption"><%- t('communitiesManagement.settings.enableTranslationsSwitchDescription') %></div>
  <div class="disclaimer__wrapper">
    <div class="disclaimer__google-disclaimer" aria-hidden="true"></div>
    <span class="js-info-icon icon-info_sign" tabindex="0" role="button" aria-label="<%- t('communitiesManagement.settings.googleDisclaimerButtonAriaLabel') %>"></span>
      <div class="js-disclaimer disclaimer hidden" tabindex="0">
        <h3><%- t('communitiesManagement.settings.enableTranslationsDisclaimer') %></h3>
        <p><%- t('communitiesManagement.settings.enableTranslationsDisclaimerContent') %></p>
      </div>
    </div>
  <hr aria-hidden="true"/>
</form>
`;
// Exports
module.exports = code;