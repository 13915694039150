import { Model } from 'backbone';
import { isArray } from 'lodash';
import TodaysActivitiesDefaultOrdering from '@common/data/TodaysActivitiesDefaultOrdering';

export default class TodaysActivitiesOrderingModel extends Model {
  defaults() {
    return {
      ordering: TodaysActivitiesDefaultOrdering
    };
  }

  apiEndpoint() {
    return '/todaysActivitiesOrdering';
  }

  parse(resp: { ordering: typeof TodaysActivitiesDefaultOrdering[] }) {
    if (isArray(resp.ordering) && resp.ordering.length === 0) {
      return { ordering: TodaysActivitiesDefaultOrdering };
    }

    return resp;
  }
}
