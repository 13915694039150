// Module
var code = `<div class="hotspotanswer" 
	data-top="<%- top %>" 
	data-left="<%- left %>"
	data-height="<%- height %>" 
	data-width="<%- width %>">
	<div class="check">

	</div>
</div>`;
// Exports
module.exports = code;