const Marionette = require('Marionette');

class SCORMPreviewEmbeddedView extends Marionette.ItemView {
  getTemplate() {
    return '<iframe class="scorm-content full-width parent-height full-height" name="scorm-content"></iframe>';
  }

  className() {
    return 'full-height';
  }

  ui() {
    return {
      iframe: 'iframe'
    };
  }
}

module.exports = SCORMPreviewEmbeddedView;
