const { CollectionView, LayoutView } = require('Marionette');
const { Collection } = require('Backbone');
const EvaluationCriteriaCardView = require('@training/apps/training/views/evaluations/EvaluationCriteriaCardView');
const EvaluationCriteriaCommentView = require('@training/apps/training/views/evaluations/EvaluationCriteriaCommentView');
const EvaluationCriteriaCardEmptyView = require('@training/apps/training/views/evaluations/EvaluationCriteriaCardEmptyView');

class EvaluationDetailsContentView extends LayoutView {
  className() {
    return 'evaluation-details-content';
  }

  getTemplate() {
    return `
      <h2 class="evaluation-details__content-title ax-font ax-font__l"><%- t('GuidedLearning.evaluation.criteria.label') %></h2>
      <div class="js-criteria-region"></div>
      <div class="js-general-notes-region"></div>
    `;
  }

  regions() {
    return {
      criteriaRegion: '.js-criteria-region',
      generalNotesRegion: '.js-general-notes-region'
    };
  }

  onRender() {
    this._renderEvaluationCriteria();
    this._renderGeneralNotes();
  }

  _renderEvaluationCriteria() {
    const observations = (this.model.get('form') || {}).observations;
    if (observations) {
      const criteriaView = new CollectionView({
        tagName: 'ul',
        className: 'evaluation-criteria-list',
        collection: new Collection(observations),
        childView: EvaluationCriteriaCardView,
        emptyView: EvaluationCriteriaCardEmptyView
      });
      this.criteriaRegion.show(criteriaView);
    }
  }

  _renderGeneralNotes() {
    const generalNotes = (this.model.get('form') || {}).generalNotes;

    if (generalNotes) {
      const generalNotesView = new EvaluationCriteriaCommentView({
        comment: generalNotes,
        isGeneralNotes: true
      });
      this.generalNotesRegion.show(generalNotesView);
    }
  }
}

module.exports = EvaluationDetailsContentView;
