const logging = require('logging');

const XhrHeaders = require('XhrHeaders');

const UpdateStatus = require('@common/modules/updates/UpdateStatus');

/**
 * This service is responsible for performing check(s) against headers and storing state about whether or not an upgrade
 * is ready.
 */
class AxonifyUpdateService {
  // This is the version the server is advertising as available, even if you are not talking with it right now
  static ADVERTISED_VERSION_FIELD_NAME = XhrHeaders.AVAILABLE_VERSION;

  // This is the version of software you talking with right this moment
  static CURRENT_VERSION_FIELD_NAME = XhrHeaders.SERVER_VERSION;

  hasSeenUpgradedVersionBefore = false;

  isCommunicatingWithWithNewerVersion = false;

  constructor(currentApplicationVersion) {
    this.currentApplicationVersion = currentApplicationVersion;

    if (!this.currentApplicationVersion) {
      throw new Error('There was no current application version specified. You need to provide one.');
    }
  }

  performUpgradedVersionFromRequestCheck(xhr) {
    this.hasSeenUpgradedVersionBefore |= this._checkAdvertisedVersion(xhr);
    this.isCommunicatingWithWithNewerVersion |= this._checkCommunicationVersion(xhr);

    if (this.isCommunicatingWithWithNewerVersion) {
      return UpdateStatus.Required;
    } else if (this.hasSeenUpgradedVersionBefore) {
      return UpdateStatus.Inessential;
    }
    return UpdateStatus.Unavailable;

  }

  _checkAdvertisedVersion(xhr) {
    const isAdvertisingNewVersion = this._isRequestHeaderClaimingDifferentVersion(xhr, AxonifyUpdateService.ADVERTISED_VERSION_FIELD_NAME);

    if (isAdvertisingNewVersion && !this.hasSeenUpgradedVersionBefore) {
      logging.info('AxonifyUpdateService - New version has been advertised, service will begin responding to queries as such');
    }

    return isAdvertisingNewVersion || this.hasSeenUpgradedVersionBefore;
  }

  _checkCommunicationVersion(xhr) {
    const isTalkingWithNewVersion = this._isRequestHeaderClaimingDifferentVersion(xhr, AxonifyUpdateService.CURRENT_VERSION_FIELD_NAME);

    if (isTalkingWithNewVersion && !this.isCommunicatingWithWithNewerVersion) {
      logging.info('AxonifyUpdateService - New version has been force served, notifiying parties immediately');
    }

    return isTalkingWithNewVersion || this.isCommunicatingWithWithNewerVersion;
  }

  _isRequestHeaderClaimingDifferentVersion(xhr, header) {
    const advertisedVersion = this._getVersionFromHeader(xhr, header);
    const currentVersion = this.currentApplicationVersion;

    if (advertisedVersion) {
      return advertisedVersion !== currentVersion;
    }
    return false; // no advertisement is not a new version

  }

  _getVersionFromHeader(xhr, header) {
    const versionHeader = xhr.getResponseHeader(header);
    return versionHeader || null;
  }
}

module.exports = AxonifyUpdateService;
