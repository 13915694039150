const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');

const { ReactControllerDefinitionFactory } = require('@common/modules/react');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const TrainingActivitiesDefinitionFactory = require('@training/apps/home/activities/TrainingActivitiesDefinitionFactory');
const EventCheckinDefinitionFactory = require('@training/apps/home/eventSelfCheckin/EventCheckinDefinitionFactory');

const TodaysActivitiesOrderingEnums = require('@common/data/enums/TodaysActivitiesOrderingEnums').default;
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

module.exports = (options = {}) => {
  const {
    session,
    startCurrentSessionTraining,
    eventCheckinModel,
    isDailyTrainingApplicable,
    isConsolidatedCommsApplicable,
    isTimelineEnabled,
    areNudgeTasksApplicable,
    areNudgeOffersApplicable,
    nudgeId,
    complete,
    skipTrainingCallback,
    todaysActivitiesOrderingModel,
    timelineNotifier,
    hasTasks
  } = options;
  const newTasksEnabled = TenantPropertyProvider.get().getProperty('tasksEnabled')

  const _loadTaskComponent = (newTasksEnabled) => {
    //TODO: Remove this once we have the new Tasks enabled & Delete the old TaskDailyActivities
    return newTasksEnabled ? import('@training/apps/opsTasks/home/DueTaskFeedView') : import('@training/apps/home/activities/TaskDailyActivities')
  }

  const isCampaignsActivityApplicable = areNudgeOffersApplicable
    || (isConsolidatedCommsApplicable && isTimelineEnabled);

  const activityConfigMapping = {
    [TodaysActivitiesOrderingEnums.LEARNING]: {
      isApplicable: isDailyTrainingApplicable,
      className: 'training-daily-activities',
      factoryFn: TrainingActivitiesDefinitionFactory,
      options: {
        session,
        complete,
        startCurrentSessionTraining,
        skipTrainingCallback
      }
    },
    [TodaysActivitiesOrderingEnums.TASKS]: {
      // isApplicable was set to areNudgeTasksApplicable;
      // assign it to false for now since we don't want to show it when rebuilding new Tasks
      isApplicable: newTasksEnabled || areNudgeTasksApplicable,
      className: 'task-daily-activities',
      factoryFn: ReactControllerDefinitionFactory,
      options: {
        component: hasTasks ? _loadTaskComponent(newTasksEnabled) : undefined
      }
    },
    [TodaysActivitiesOrderingEnums.CAMPAIGNS]: {
      isApplicable: isCampaignsActivityApplicable,
      className: 'nudge-daily-activities',
      factoryFn: ReactControllerDefinitionFactory,
      options: {
        component: isCampaignsActivityApplicable
          ? import('@training/apps/home/activities/CampaignsDailyActivities')
          : undefined,
        props: {
          nudgeId,
          sessionStartDate: session.get('startDate'),
          sessionEndDate: session.get('expiryDate'),
          areNudgeOffersApplicable,
          isConsolidatedCommsApplicable,
          isTimelineEnabled,
          timelineNotifier
        }
      }
    }
  };

  const _getActivityChildController = (activityConfig = {}) => {
    if (activityConfig == null || !activityConfig.isApplicable) {
      return null;
    }

    const {
      className,
      factoryFn,
      options
    } = activityConfig;

    return StackedLayoutViewController.createConfigObject(
      ViewOptionHelpers.classNameAppender(className),
      factoryFn(options)
    );
  };

  const activityOrdering = todaysActivitiesOrderingModel.get('ordering');
  const childControllers = [];

  if (eventCheckinModel.get('checkInAvailable')) {
    childControllers.push(StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('events'), EventCheckinDefinitionFactory(eventCheckinModel)));
  }

  // Loop the today's activities ordering provided and add child controllers with respect to the ordering
  activityOrdering.forEach((activityItem) => {
    if (activityItem === 'TASKS' && !hasTasks) {
      return;
    }
    const childController = _getActivityChildController(activityConfigMapping[activityItem]);
    if (childController != null) {
      childControllers.push(_getActivityChildController(activityConfigMapping[activityItem]));
    }
  });

  if (childControllers.length > 0) {
    return () => {
      return {
        ViewControllerClass: StackedLayoutViewController,
        viewDefinition: {
          className: 'post-training__daily-activities',
          childViewOptions: {
            className: 'daily-activity'
          }
        },
        childControllers
      };
    };
  }

  return null;
};
