const Backbone = require('Backbone');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
class BreadcrumbDecoratedLinkedItemView extends Marionette.ItemView {
  constructor(...args) {
    super(...args);
    this.onClickCrumb = this.onClickCrumb.bind(this);
    this.contentChannel = Backbone.Wreqr.radio.channel('content');
  }

  initialize(options) {
    // NOTE: If you don't provide a doOnClick method when the class is initialized (or override the onClickCrumb method),
    // the links won't do anything. By default, the passed doOnClick method takes the view model as an argument.
    ({
      doOnClick: this.doOnClick = () => {}
    } = options);
  }

  tagName() {
    return 'span';
  }

  className() {
    return 'pull-left clickable';
  }

  getTemplate() {
    return `\
      <span class="separator" aria-hidden="true">></span>
      <span class="<%- getDecoratorClass() %>"
        title="<%- getDecoratorTooltipText() %>">
      </span>
      <% if ( shortened = titleShort() ) { %>
        <button class="regular-crumb" title="<%= escapedTitle() %>">
          <%= shortened %>
        </button>
      <% } else { %>
        <button class="regular-crumb">
          <%= escapedTitle() %>
        </button>
      <% } %>\
    `;
  }

  events() {
    return {
      'click button': 'onClickCrumb'
    };
  }

  templateHelpers() {
    return {
      titleShort: () => {
        const full = this.model.get('title');
        const trunc = HTMLHelpers.summarizeHTMLToRange( full, 20 );
        if (trunc === full) {
          return '';
        }
        return HTMLHelpers.stripHtmlForDisplay(trunc);
      },

      titleMedium: () => {
        const full = this.model.get('title');
        const trunc = HTMLHelpers.summarizeHTMLToRange( full, 60 );
        if (trunc === full) {
          return '';
        }
        return HTMLHelpers.stripHtmlForDisplay(trunc);
      },

      escapedTitle: () => {
        const title = this.model.get('title');
        return HTMLHelpers.stripHtmlForDisplay(title);
      },

      getDecoratorClass: () => {
        // This is guaranteed to be correct because of a public interface higher up
        return `icon-${ this.model.get('type') }`;
      },

      getDecoratorTooltipText: () => {
        // Search has no decorator, therefore no alt text
        const modelType = this.model.get('type');
        if (modelType === 'search' || modelType === '' || modelType == null) {
          return '';
        }
        return I18n.t(`content.${ modelType }`);
      }
    };
  }

  onClickCrumb() {
    this.doOnClick(this.model);
  }

  onRender() {
    BrowserHelpers.triggerResize(true);
  }
}

class BreadcrumbDecoratedUnLinkedItemView extends BreadcrumbDecoratedLinkedItemView {
  events() {
    return {}; // override parent's events, avoid onClick event for unlinked breadcrumb
  }

  className() {
    return 'pull-left';
  }

  getTemplate() {
    return `\
<span class="separator" aria-hidden="true">></span>
<% if ( getDecoratorClass() ) { %>
<span class="<%- getDecoratorClass() %>"
  title="<%- getDecoratorTooltipText() %>">
</span>
<% } %>
<span class="no-link">
  <% if ( shortened = titleMedium() ) { %>
    <span class="regular-crumb" title="<%= escapedTitle() %>">
      <%= shortened %>
    </span>
  <% } else { %>
    <span class="regular-crumb"><%= escapedTitle() %></span>
  <% } %>
</span>\
    `;
  }
}

class BreadcrumbSeparatorOnlyView extends BreadcrumbDecoratedUnLinkedItemView {
  getTemplate() {
    return '<span class="separator" aria-hidden="true">></span>';
  }
}

class BreadcrumbRootItemView extends BreadcrumbDecoratedLinkedItemView {
  getTemplate() {
    return `\
<button class="all-crumb" title="<%= title %>">
  <%= limitToCharacters(title, 20) %>
</button>\
`;
  }
}

const BreadcrumbItemViewFactory = {
  getViewClass(collection, breadcrumbModel, hideLastCrumb, linkLastCrumb) {
    if (collection.findLastIndex(breadcrumbModel) === 0) {
      return BreadcrumbRootItemView;
    }

    if (collection.findLastIndex(breadcrumbModel) === (collection.length - 1)) {
      if (hideLastCrumb) {
        return BreadcrumbSeparatorOnlyView;
      } else if (linkLastCrumb) {
        return BreadcrumbDecoratedLinkedItemView;
      }
      return BreadcrumbDecoratedUnLinkedItemView;
    }

    return BreadcrumbDecoratedLinkedItemView;
  }
};

class BreadcrumbCollectionView extends Marionette.CollectionView {

  // NOTE: Pass in a method to call when a breadcrumb item is clicked.
  // The method you provide will be passed the model associated with the clicked breadcrumb item as an argument when clicked
  initialize(options = {}) {
    ({
      doOnClick: this.doOnClick,
      hideLastCrumb: this.hideLastCrumb,
      linkLastCrumb: this.linkLastCrumb
    } = options);
  }

  tagName() {
    return 'div';
  }

  className() {
    return 'main-crumbs';
  }

  onRender() {
    this.$el.toggle(this.collection.length !== 0);
  }

  getChildView(breadcrumbModel) {
    return BreadcrumbItemViewFactory.getViewClass(this.collection, breadcrumbModel, this.hideLastCrumb, this.linkLastCrumb);
  }

  childViewOptions() {
    return {
      doOnClick: this.doOnClick
    };
  }
}

module.exports = {
  BreadcrumbCollectionView,
  BreadcrumbDecoratedLinkedItemView,
  BreadcrumbDecoratedUnLinkedItemView,
  BreadcrumbSeparatorOnlyView,
  BreadcrumbRootItemView,
  BreadcrumbItemViewFactory
};
