const Backbone = require('Backbone');


class EventNotificationsProperty extends Backbone.Model {
  initialize() {
    this.listenTo(this, 'sync', this._onSync);
  }

  url() {
    return `/axonify/selfserve/user/managernotifications`;
  }

  isChangedByUser() {
    const eventEnrollmentChanged = this._originalModel && this._originalModel.get('entity').managerNotificationSettings.EVENT_ENROLLMENT !== this.get('entity').managerNotificationSettings.EVENT_ENROLLMENT;
    return eventEnrollmentChanged;
  }

  _onSync(model) {
    this._eventEnrollmentChanged = this._originalModel && this._originalModel.get('entity').managerNotificationSettings.EVENT_ENROLLMENT !== this.get('entity').managerNotificationSettings.EVENT_ENROLLMENT;
    this._originalModel = model.clone();
  }

  save(options = {}) {
    options.contentType = 'application/json';
    options.type = 'PUT';
    options.data = JSON.stringify(this.attributes.entity);
    return super.fetch(options);
  }
}

module.exports = EventNotificationsProperty;
