const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

class LearningEventDetailsNoticeView extends Marionette.ItemView {
  getTemplate() {
    return `<div class="learningevent-full-notice">
  <%- noticeText %>
  <%- waitingListText %>
</div>`;
  }

  templateHelpers() {
    const waitingListText = this._getWaitingListText();
    const noticeText = I18n.t('learningEvents.details.thisEventFull');

    return {
      noticeText,
      waitingListText
    };
  }

  _getWaitingListText() {
    if (!this.model.get('enableWaitingList') && !this.options.isEnrolled) {
      return '';
    }

    if (this.options.isEnrolled) {
      return I18n.t('learningEvents.details.userEnrolled');
    }

    if (this.model.get('numWaiting') === 1) {
      return I18n.t('learningEvents.details.personWaiting');
    }
    return I18n.t('learningEvents.details.peopleWaiting', {numWaiting: this.model.get('numWaiting')});

  }
}

module.exports = LearningEventDetailsNoticeView;
