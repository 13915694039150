const { ItemView } = require('Marionette');

require('@common/modules/main/menu/item/selectable/SelectableBehavior');
require('@common/libs/behaviors/pressedHoverButton/PressedHoverButtonBehavior');

class HeaderDropdownItem extends ItemView {
  tagName() {
    return 'li';
  }

  getTemplate() {
    if (this.href != null) {
      return `
        <a href="<%- href %>" class="header-dropdown-item <%- selectableClass %> <%- linkClass %>">
          <span class="item-icon <%- iconClass %>"></span><span class="item-text"><%- text %></span>
        </a>
      `;
    }
    // Put an extra span in button tag for its children to be rendered in flex on Firefox 38
    // https://github.com/philipwalton/flexbugs#flexbug-9
    return `
        <button class="header-dropdown-item <%- selectableClass %> <%- linkClass %>">
          <span class="header-dropdown-item-wrapper">
            <span class="item-icon <%- iconClass %>"></span><span class="item-text"><%- text %></span>
          </span>
        </button>
      `;

  }

  behaviors() {
    const behaviors = {
      PressedHoverButtonBehavior: {
        buttonTarget: '.header-dropdown-item'
      }
    };

    if (!this.getOption('isAppItem')) {
      Object.assign(behaviors, {
        SelectableBehavior: {
          selectableId: this.getOption('model').id
        }
      });
    }

    return behaviors;
  }

  constructor(options = {}) {
    const viewOptions = $.extend({ model: options.model}, options.model.get('viewOptions'));
    super(viewOptions);

    ({
      text: this.text,
      iconClass: this.iconClass,
      linkClass: this.linkClass,
      href: this.href

    } = viewOptions);
  }

  modelEvents() {
    return {'change:includeDivider': 'onIncludeDividerChange'};
  }

  templateHelpers() {
    return {
      selectableClass: (!this.getOption('isAppItem') ? 'selectable' : ''),
      text: this.text,
      iconClass: this.iconClass,
      linkClass: this.linkClass,
      href: this.href
    };
  }

  onRender() {
    this._toggleDivider(this.model.get('includeDivider'));
  }

  onIncludeDividerChange(model, includeDivider) {
    this._toggleDivider(includeDivider);
  }

  _toggleDivider(includeDivider) {
    this.$el.toggleClass('header-dropdown-divider', includeDivider);
  }
}

module.exports = HeaderDropdownItem;
