const { LayoutView } = require('Marionette');
const { getScrollParent } = require('@common/libs/helpers/app/BrowserHelpers');

require('@common/libs/behaviors/scrollable/Scrollable');

const Cocktail = require('backbone.cocktail');
const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');

class ReportsPageLayoutView extends LayoutView {
  initialize(options = {}) {
    ({
      pageTitle: this.pageTitle,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount,
      collection: this.collection
    } = options);
  }

  className() {
    return 'reports-view parent-height';
  }

  getTemplate() {
    return `
    <table class="reports-dashboard-table">
      <caption class="ax-font ax-font__s align-left"><%- t(pageTitle) %></caption>
      <thead>
        <tr>
          <th width="80%"><%- t(tableTitle) %></th>
          <th class="right-align" width="20%"><%- t(tableCount) %></th>
        </tr>
      </thead>
    </table>
    <table class="collection reports-dashboard-table">
    </table>`;
  }

  regions() {
    return {
      tableRegion: '.collection'
    };
  }

  templateHelpers() {
    return {
      pageTitle: this.pageTitle,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount
    };
  }

  onAttach() {
    this.$scrollContainer = $(getScrollParent(this.el));
  }

  onLoadNext() {
    if (!this.collection.hasNext() || this.collection.isLoading) {
      return;
    }
    this.collection.getNextPage();
  }
}

Cocktail.mixin(ReportsPageLayoutView, InfiniteScrollViewMixin);
module.exports = ReportsPageLayoutView;
