const { TOPIC_OBJECTIVE, CERTIFICATION_OBJECTIVE } = require('@common/data/models/objectives/GuidedLearningObjectiveType');
const { IntroductoryTraining, CertificationTraining } = require('@common/data/enums/AssessmentType');

const topicSearchCriteria = (item) => {
  return {
    type: IntroductoryTraining,
    topicId: item.topicId,
    level: item.objectiveDetails.topicLevelProgress.level
  };
};

const certSearchCriteria = (item) => {
  return {
    type: CertificationTraining,
    topicId: item.topicId,
    level: item.objectiveDetails.level
  };
};

const objectiveCriteriaMapping = {
  [TOPIC_OBJECTIVE]: topicSearchCriteria,
  [CERTIFICATION_OBJECTIVE]: certSearchCriteria
};

module.exports = {
  createCriteria: (objective) => {
    const createCriteria = objectiveCriteriaMapping[objective.type] || (() => {});
    return createCriteria(objective);
  }
};
