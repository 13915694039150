const { ItemView } = require('Marionette');

const CONTENT_CONTROLLER_DEFINITION = 'contentLayerControllerDefinition';
const OVERLAY_CONTROLLER_DEFINITION = 'overlayLayerControllerDefinition';

module.exports = {
  Keys: {
    CONTENT_CONTROLLER_DEFINITION,
    OVERLAY_CONTROLLER_DEFINITION
  },

  defaults() {
    return {
      [CONTENT_CONTROLLER_DEFINITION]: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'fit-parent',
          template: false
        }
      }
    };
  },

  getContentControllerDefinition(contextModel) {
    return contextModel.get(CONTENT_CONTROLLER_DEFINITION);
  },

  getOverlayControllerDefinition(contextModel) {
    return contextModel.get(OVERLAY_CONTROLLER_DEFINITION);
  },

  setContentControllerDefinition(contextModel, controllerDefinition, options = {}) {
    contextModel.set(CONTENT_CONTROLLER_DEFINITION, controllerDefinition, options);
  },

  resetContentControllerDefinition(contextModel) {
    const defaultDefinition = this.defaults()[CONTENT_CONTROLLER_DEFINITION];
    contextModel.set(CONTENT_CONTROLLER_DEFINITION, defaultDefinition);
  },

  setOverlayControllerDefinition(contextModel, controllerDefinition, options = {}) {
    contextModel.set(OVERLAY_CONTROLLER_DEFINITION, controllerDefinition, options);
  }
};
