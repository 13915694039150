const ImageLoader = require('@common/libs/ImageLoader');
const MediaStatus = require('@common/libs/file/MediaStatus');
const _ = require('underscore');

const getBiggestImageBySize = function(imgSizes, maxDimensions = {}, pxlRatio) {
  let imageSizes = imgSizes;
  let pixelRatio = pxlRatio;

  if (imageSizes == null) {
    imageSizes = [];
  } // defaulting parameter doesn't catch null case
  if (pixelRatio == null) {
    pixelRatio = window.devicePixelRatio != null ? window.devicePixelRatio : 1;
  }

  if (imageSizes.length === 0) {
    return undefined;
  }
  // First get rid of all images that aren't done processing yet
  const doneSizes = _.reject(imageSizes, (imageSize) => {
    return imageSize.status !== MediaStatus.DONE;
  });
  if (doneSizes.length === 0) {
    return undefined;
  }
  // Adjust the maxWidth and maxHeight to account for the devices pixel ratio
  const {
    maxWidth,
    maxHeight
  } = maxDimensions;
  // if either maxDimensions is empty or they're both 0, return the smallest size
  const bestImageSize = (() => {
    if (((maxWidth == null) && (maxHeight == null)) || ((maxHeight === 0) && (maxWidth === 0))) {
      return _.min(doneSizes, (imageSize) => {
        return imageSize.actualDimensions.width;
      });
      // else find the best fit image
    }
    pixelRatio = Math.max(pixelRatio, 0);
    const adjustedHeight = (maxHeight != null) ? maxHeight * pixelRatio : Infinity;
    const adjustedWidth = (maxWidth != null) ? maxWidth * pixelRatio : Infinity;
    // Calculate the scaling value from the image size to the adjusted max dimensions and compose it with its imageSize obj
    let scaleSizeData = _.map(doneSizes, (imageSize) => {
      const wRatio = adjustedWidth / imageSize.actualDimensions.width;
      const hRatio = adjustedHeight / imageSize.actualDimensions.height;
      const scale = Math.min(wRatio, hRatio);
      return {
        scale,
        imageSize
      };
    });
      // Split the scaleSizeData into 2 sets, one whose scale is greater than 1 and thus smaller than we're looking for
      // and one whose scale is less than 1 and thus larger than we're looking for.
    const partictionedScaleSizes = _.partition(scaleSizeData, (data) => {
      return data.scale > 1;
    });
    const tooSmall = partictionedScaleSizes[0];
    const tooLarge = partictionedScaleSizes[1];
    // Take the scaleSizeData whose scale is closest to 1 without going over. If no such imageSize exists, get the
    // scaleSizeData whose scale is greater than 1 but closest to 1.
    const scaleIteratee = (data) => {
      return data.scale;
    };
    scaleSizeData = _.isEmpty(tooLarge) ? _.min(tooSmall, scaleIteratee) : _.max(tooLarge, scaleIteratee);
    return scaleSizeData.imageSize;

  })();
  return bestImageSize;
};

const getBiggestImageFileBySize = function(imageSizes, maxDimensions, { pixelRatio } = {}) {
  const result = getBiggestImageBySize(imageSizes, maxDimensions, pixelRatio);
  return result ? result.file : result;
};

const getMaxDimensions = function(elWidth = 0, elHeight = 0, maxWidth = 0, maxHeight = 0) {
  const validMaxDimensions = (maxWidth > 0) || (maxHeight > 0);
  const validElDimensions = (elWidth > 0) || (elHeight > 0);
  const generateMaxDimensions = function(maxWidth, maxHeight) {
    const maxDimensions = {};
    if (maxWidth > 0) {
      maxDimensions.maxWidth = maxWidth;
    }
    if (maxHeight > 0) {
      maxDimensions.maxHeight = maxHeight;
    }
    return maxDimensions;
  };

  // if there are valid dimensions to compare
  if (validElDimensions && validMaxDimensions) {
    // clamp the max dimensions to the passed in max values if either of them are smaller than
    // the elements dimensions
    if ((elWidth > maxWidth) || (elHeight > maxHeight)) {
      return generateMaxDimensions(maxWidth, maxHeight);
    }
    return generateMaxDimensions(elWidth, elHeight);

  // valid max dimensions are picked before valid el dimensions
  } else if (validMaxDimensions) {
    return generateMaxDimensions(maxWidth, maxHeight);
  } else if (validElDimensions) {
    return generateMaxDimensions(elWidth, elHeight);
  }
  return {};

};

const getAverageColour = function(image) {
  if (!image || !image.colour) {
    return undefined;
  }
  return image.colour.average;
};

const loadFileImage = function(imageFile) {
  const url = imageFile.acquireUrl();
  return ImageLoader.load(url)
    .always(() => {
      return imageFile.releaseUrl();
    });
};

const getNaturalDimensions = (imageFile) => {
  return loadFileImage(imageFile)
    .then( (event, imageTag) => {
      return new $.Deferred().resolve(imageTag.naturalWidth, imageTag.naturalHeight)
        .promise();
    });
};

const getSelectedMultiLangImage = (image, language, useDefault = false) => {
  if (image != null) {
    if (language == null) {
      return image.preferred;
    }

    if (useDefault) {
      return image.default;
    }

    return image.translations[language];
  }

  return null;
}

module.exports = {
  getBiggestImageFileBySize,
  getBiggestImageBySize,
  getMaxDimensions,
  loadFileImage,
  getNaturalDimensions,
  getAverageColour,
  getSelectedMultiLangImage
};
