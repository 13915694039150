import {
  TableRow as MuiTableRow,
  styled,
  type TableRowTypeMap as MuiTableRowTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTableRowCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableRowTypeMap<P = object, D extends React.ElementType = 'tr'> = MuiTableRowTypeMap<P & AxTableRowCustomProps, D>;

export type AxTableRowProps<
  D extends ElementType = AxTableRowTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableRowTypeMap<P & AxTableRowCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableRow'
};

/**
 * A component that renders a row in a table.
 *
 * ## Links
 * - • [MUI | Table Row API](https://mui.com/material-ui/api/table-row/)
 */
const StyledAxTableRow = styled(MuiTableRow, styledOptions)<AxTableRowProps>(() => {
  return {};
});

export const AxTableRow: MuiOverridableComponent<AxTableRowTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableRowProps, ref: Ref<HTMLTableRowElement>) => {
  const tableRowProps: AxTableRowProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableRow { ...tableRowProps } ref={ ref }>
      { children }
    </StyledAxTableRow>
  );
});

export default AxTableRow;
