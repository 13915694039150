const Marionette = require('Marionette');

class SCORMErrorView extends Marionette.ItemView {

  getTemplate() {
    return require('@training/apps/training/views/activities/pages/scorm/SCORMError.html');
  }

  className() {
    return 'scorm-error-body';
  }

  events() {
    return {
      'click button.retry': 'onClickRetry'
    };
  }

  onClickRetry() {
    window.location.reload();

    return false;
  }
}

module.exports = SCORMErrorView;
