const Backbone = require('Backbone');
const {
  View,
  Autocomplete
} = require('@training/widgets/UIKit');

const $os = require('detectOS');
const _ = require('underscore');
const logging = require('logging');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');
const dateHelpers = require('@common/libs/dateHelpers');

const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/card/Card');
const { INFORMATIONAL } = require('@common/libs/behaviors/card/CardModifierClasses');

const LoadingRowView = require('@common/mixins/data_loading/LoadingRowView');

const QuestionPreviewController = require('@common/components/questions/QuestionPreviewController');

const KnowledgeItemList = require('@training/apps/training/collections/KnowledgeItemList');
const KnowledgeItemAnswersList = require('@training/apps/training/collections/KnowledgeItemAnswersList');

const KnowledgeItemAnswerListView = require('@training/apps/training/views/KnowledgeItemAnswerListView');
const KnowledgeItemListView = require('@training/apps/training/views/KnowledgeItemListView');

const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');

const HubHelper = require('@training/libs/HubHelper');

const TrainingImageZoom = require('@training/apps/training/TrainingImageZoom');
const UserKnowledgeScore = require('@training/apps/training/models/UserKnowledgeScore');
require('@common/libs/behaviors/msHideable/MsHideable');

class KnowledgePage extends View {

  getTemplate() {
    return _.tpl(require('@training/apps/training/templates/KnowledgePage.html'));
  }

  events() {
    return {
      'click .knowledgeitem': 'showQuestions',
      'click .questionblock': 'showQuestionAnswer',
      'click .knowledgeitem .questions .close': 'hideQuestionAnswer',
      'click .knowledgeitem .questions': 'stopPropagation',
      'click @ui.toggleKnowledgeTable': 'onToggleKnowledgeTable'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      MsHideable: { selector: '.page-header' },
      Card: {
        target: '.knowledge-wrapper',
        modifierClasses: [INFORMATIONAL]
      }
    };
  }

  ui() {
    return {
      knowledgeChart: '#knowledgechart',
      knowledgeTable: '#knowledge-table',
      knowledgeWrapper: '.knowledge-wrapper',
      listContainer: '#knowledgemap',
      searchBox: '.topic-search',
      toggleKnowledgeTable: '#knowledge-table-toggle'
    };
  }

  constructor(options = {}) {
    super(options);

    ({
      sessionController: this.sessionController,
      complete: this.complete = () => {}
    } = options);

    this.userKnowledgeScore = new UserKnowledgeScore();
    this.renderKnowledgeStats = this.renderKnowledgeStats.bind(this);
    this.renderKnowledgeChart = this.renderKnowledgeChart.bind(this);
    this.takeAction = this.takeAction.bind(this);

    this.numberOfQuestionsInKnowledgeChart = 30;

    this.questionTemplate = _.tpl(require('@training/apps/training/templates/_knowledge_question_item.html'));

    // Page state
    this.isLoadingPage = false;
    this.levelNode = {}; // by topic id and level
    this.answers = {}; // by answer id

    this.knowledgeItemList = new KnowledgeItemList([], {
      includeTrainingModuleData: true,
      sessionModel: this.sessionController.session
    });
    this.knowledgeItemAnswerList = new KnowledgeItemAnswersList();
  }

  render() {
    this.$el.html(this.getTemplate()());
    return this;
  }

  onRender() {
    this.ui.knowledgeChart.append((new LoadingRowView().render()).el);

    this._renderKnowledgeItemListView();

    this.searchBox = new Autocomplete({
      el: this.ui.searchBox,
      minLength: 1
    });
  }

  _renderKnowledgeItemListView() {
    // KnowledgeItemListView
    this.knowledgeItemListView = new KnowledgeItemListView({
      el: this.ui.listContainer,
      collection: this.knowledgeItemList,
      isExtraTrainingEnabled: this.sessionController.isExtraTrainingAvailable(),
      takeAction: this.takeAction
    });
  }

  viewDidAppear() {
    logging.info('KnowledgePage - viewDidAppear');

    window.app.layout.setTitle(I18n.t('knowledge.title'));

    this.listenTo(this.searchBox, 'autocomplete search', this.onSearch);
    this.listenTo(this.searchBox, 'clearSearch', this.onClearSearch);

    // Load topics
    this.getKnowledgeMap();
  }

  onResize() {
    if (this.ui.knowledgeChart.highcharts) {
      const highcharts = this.ui.knowledgeChart.highcharts();
      if (highcharts && highcharts.setSize) {
        highcharts.setSize(null, null, false);
      }
    }
  }

  _fetchKnowledgeItemAnswers() {
    // Load the last N number of questions needed for the +/- chart
    return this.knowledgeItemAnswerList.fetch({
      showSpinner: false,
      data: {
        numberQuestions: this.numberOfQuestionsInKnowledgeChart
      }
    });
  }

  _fetchKnowledgeItems() {
    // Load the knowledge map to populate the topic list and stats at the top
    return this.knowledgeItemList.fetch();
  }

  getKnowledgeMap() {
    Promise.all([
      import('@common/configs/highcharts'),
      this._fetchKnowledgeItemAnswers()
    ]).then(() => {
      this.renderKnowledgeStatsTable();
      this.renderKnowledgeChart();
    });

    Promise.all([
      this._fetchTrainingScores(),
      this._fetchKnowledgeItems()
    ]).then(this.renderKnowledgeStats);
  }

  _fetchTrainingScores() {
    return this.userKnowledgeScore.fetch({
      showSpinner: false,
      success() {
        logging.info('Successfully loaded knowledge scores');
      },
      error() {
        logging.error('Failed to load knowledge scores');
      }
    });
  }

  renderKnowledgeStatsTable() {
    if (this.isDestroyed) {
      return;
    }

    const knowledgeItemAnswers = [];
    let trendTotal = 0;

    this.knowledgeItemAnswerList.each((knowledgeItem) => {
      const answeredCorrect = knowledgeItem.get('answeredCorrect');

      trendTotal = trendTotal + (answeredCorrect ? 1 : -1);

      knowledgeItemAnswers.push({
        answerId: knowledgeItem.get('answerId'),
        answerDate: dateHelpers.convertDateFormatToDefaultDate(knowledgeItem.get('answerDate')),
        answeredCorrect,
        trendTotal,
        questionVariantText: knowledgeItem.get('questionVariantText')
      });
    });

    knowledgeItemAnswers.reverse();

    const tableView = new KnowledgeItemAnswerListView({
      el: this.ui.knowledgeTable,
      collection: new Backbone.Collection(knowledgeItemAnswers),
      showQuestionAnswerById: this.showQuestionAnswerById
    });
    tableView.render();
  }

  renderKnowledgeStats() {
    if (this.isDestroyed) {
      return;
    }

    const [overallAverage, totalAnswerCount] = Array.from(HubHelper.knowledgeStats(this.knowledgeItemList.toJSON()));

    if (totalAnswerCount > 0) {
      // Overall average class for styling
      const overallAverageClass = HubHelper.getOverallAverageClass(overallAverage, totalAnswerCount);
      const currentAverageText = `${ this.userKnowledgeScore.get('overallCurrentScore') }%`;
      this.$('.highlights .current_overall_average .score').html(currentAverageText)
        .addClass(overallAverageClass);
    } else {
      this.$('.highlights .current_overall_average .score').html(I18n.t('knowledge.notAvailable'));
    }

    const topicLevelScoreText = I18n.t('general.partOfTotal', {
      part: this.knowledgeItemList.levelsGraduatedCount,
      total: this.knowledgeItemList.levelsAvailableCount
    });

    this.$('.highlights .graduated_topic_level .score').text(topicLevelScoreText);

    this.triggerAdjustment();
  }

  showQuestions(e) {
    const $knowledgeitem = $(e.currentTarget).closest('.knowledgeitem');
    const $questions = $knowledgeitem.find('.questions');
    const $arrow = $knowledgeitem.find('.droparrow');
    const topicId = $knowledgeitem.data('topic-id');
    const level = $knowledgeitem.data('level');

    // if the clicked knowledge item is already expanded
    // just slide up the questions and remove 'expanded' class
    if ($knowledgeitem.find('.questions:not(.questions:hidden)').length > 0) {
      $questions.stop(true, true).slideUp(200, this.triggerAdjustment);
      $arrow.removeClass('expanded icon-angle_down').addClass('icon-angle_right')
        .attr('aria-expanded', 'false');
    } else {
      // to make the element stay in the same position on the page as to when
      // the user clicked on it, the page needs to scroll the amount of the
      // question area of the previously selected knowledge item
      let currentDisplayedKItemTop = -1;
      let currentDisplayedQuestionHeight = -1;
      const currentlySelectedKItem = $('.questions:not(.questions:hidden)');
      if (currentlySelectedKItem) {
        const closest = currentlySelectedKItem.closest('.knowledgeitem');
        const outerHeight = currentlySelectedKItem.outerHeight(true);
        if (closest && closest.offset() && closest.offset().top) {
          currentDisplayedKItemTop = closest.offset().top;
        }

        if (outerHeight) {
          currentDisplayedQuestionHeight = outerHeight;
        }

      }

      // new scroll top needs to be determined before we start the animation
      // window is used as it's the only thing that all browsers support when getting the current scroll position
      const newScrollTop = $(window).scrollTop() - Math.max(currentDisplayedQuestionHeight, 0);

      // slide up all questions and remove 'expanded' class from all arrows
      this.$('.questions').stop(true, true)
        .slideUp(200, this.triggerAdjustment);
      this.$('.droparrow').removeClass('expanded icon-angle_down')
        .addClass('icon-angle_right')
        .attr('aria-expanded', 'false');

      // slide down the questions that are children of the knowledge item
      $questions.stop(true, true).slideDown(200, this.triggerAdjustment);
      // add expanded class to drop arrow
      $arrow.addClass('expanded icon-angle_down').removeClass('icon-angle_right')
        .attr('aria-expanded', 'true');

      //scroll up if an item above was collapsed
      if ((currentDisplayedKItemTop > -1) && ($knowledgeitem.offset().top > currentDisplayedKItemTop)) {
        // 'html, body' is used to set as it's the way to make it cross browser compatible
        $('html, body').animate({scrollTop: newScrollTop}, 200);
      }

      this.getKnowledgeData(topicId, level, (levelNode) => {
        this.renderQuestions($knowledgeitem, levelNode);
      });
    }
  }

  getKnowledgeData(topicId, level, complete = () => {}) {
    // Check the cache for this topic level node and execute the callback.
    // Otherwise fetch the data, cache it, and execute the callback.
    if ((this.levelNode[topicId] != null ? this.levelNode[topicId][level] : undefined) != null) {
      complete(this.levelNode[topicId][level]);
    } else {
      if (this.isLoadingPage) {
        return;
      }

      this.isLoadingPage = true;

      $.ajax({
        type: 'GET',
        apiEndpoint: '/knowledge/topiclevel',
        data: {
          topicId,
          level
        },
        complete: () => {
          this.isLoadingPage = false;
        },
        error() {
          logging.error(`Failed to get topic level results for topicId \`${ topicId }\`, level \`${ level }\``);
        },
        success: (response) => {
          if (!this.levelNode[topicId]) {
            this.levelNode[topicId] = {};
          }
          this.levelNode[topicId][level] = response.result.levelNode != null ? response.result.levelNode : {};
          complete(this.levelNode[topicId][level]);
        }
      });
    }
  }

  renderQuestions($knowledgeitem, levelNode) {
    const $questionswrap = $knowledgeitem.find('.questionswrap');
    $questionswrap.find('.answerblock').remove();

    const answerHistory = Array.from(
      _.chain(levelNode.answerHistory).groupBy('questionId')
        .map((value) => {
          return {answerHistory: value};
        })
        .value()
    );

    $questionswrap.find('.noquestions').toggleClass('hidden', answerHistory.length !== 0);

    for (const answerNodes of Array.from(answerHistory)) {
      const answerNode = answerNodes.answerHistory;
      _.each(answerNode, (answer) => {
        answer.answerDate = dateHelpers.convertDateFormatToDefaultDate(answer.answerDate);
      });
      $questionswrap.append(this.questionTemplate({answerNode}));
    }

    this.triggerAdjustment();
  }

  showQuestionAnswer(e) {
    const $questionblock = $(e.currentTarget);
    const answerId = $questionblock.data('answer-id');
    this.showQuestionAnswerById(answerId);
    return false;
  }

  showQuestionAnswerById(answerId) {
    if (this.modalInProgress) {
      return;
    }

    this.modalInProgress = true;
    this.questionPreview = new QuestionPreviewController({
      imageZoom: TrainingImageZoom
    });
    this.questionPreview.loadQuestionPreview(answerId);

    this.listenTo(this.questionPreview.questionLayoutWrapper, 'destroy', () => {
      this.modalInProgress = false;
    });

    this.listenTo(this.questionPreview, 'invalid:answer', () => {
      this.modalInProgress = false;
      window.app.layout.flash.error(I18n.t('question.previewUnavailable'));
    });
  }

  hideQuestionAnswer(e) {
    const $questions = $(e.currentTarget).closest('.questions');
    $questions.stop(true, true).slideUp(150);
    e.stopPropagation();
    return false;
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  renderKnowledgeChart() {
    if (this.isDestroyed) {
      return;
    }

    let currentSum = 0;
    let maxY = 0;
    let minY = 0;
    let maxX = 0;
    let minX = 0;

    const data = [{
      x: 0,
      y: currentSum,
      marker: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    }];

    // Each question answer
    this.knowledgeItemAnswerList.each((questionAnswer, i) => {
      const dataItem = $.extend(true, {}, questionAnswer.toJSON(), {x: i + 1});

      if (dataItem.answeredCorrect) {
        currentSum += 1;
        dataItem.marker
            = {
            fillColor: '#029B55',
            lineWidth: 2,
            lineColor: '#FFFFFF',
            radius: 7
          };
      } else {
        currentSum -= 1;
        dataItem.marker
            = {
            fillColor: '#BA0C2F', // @ax-color-red-60
            lineWidth: 2,
            lineColor: '#FFFFFF',
            radius: 7
          };
      }

      dataItem.y = currentSum;

      // Y bounds
      if (dataItem.y > maxY) {
        maxY = dataItem.y;
      } else if (dataItem.y < minY) {
        minY = dataItem.y;
      }

      // X bounds
      if (dataItem.x > maxX) {
        maxX = dataItem.x;
      } else if (dataItem.x < minX) {
        minX = dataItem.x;
      }

      dataItem.description = questionAnswer.get('questionVariantText');

      return data.push(dataItem);
    });

    if (this.knowledgeItemAnswerList.length === 0) {
      this.ui.knowledgeChart.hide();
      this.ui.toggleKnowledgeTable.hide();
    } else {
      const self = this;

      this.ui.knowledgeChart.highcharts({
        chart: {
          type: 'line',
          gridLineColor: '#ececec',
          borderColor: '#ececec',
          backgroundColor: '#ffffff',
          alternateGridColor: '#ffffff'
        },
        credits: {
          enabled: false
        },

        title: {
          text: ''
        },

        legend: {
          enabled: false
        },

        xAxis: {
          tickLength: 0,
          lineColor: '#ececec',
          backgroundColor: '#595959',
          labels: {
            style: {
              display: 'none'
            }
          }
        },

        yAxis: {
          allowDecimals: false,
          title: {
            text: I18n.t('knowledge.plusminus')
          },
          min: minY - .5,
          max: maxY + .5,
          gridLineColor: '#ececec',
          alternateGridColor: '#f8f8f8'
        },

        plotOptions: {
          series: {
            color: '#595959',
            lineWidth: 2,
            cursor: 'pointer',
            point: {
              events: {
                click() {
                  if (this.answerId != null) {
                    self.showQuestionAnswerById(this.answerId);
                  }
                  return false;
                }
              }
            }
          }
        },

        tooltip: {
          useHTML: true,
          formatter() {
            if ($os.mobile || this.point == null || this.point.options == null || this.point.options.questionId == null) {
              return false;
            }
            const questionText = this.point.questionVariantText || '';
            return HTMLHelpers.stripHtmlForDisplay(questionText);

          }
        },
        series: [{
          name: 'Questions',
          data
        }],
        accessibility: {
          point: {
            descriptionFormatter: (point) => {
              return point.questionVariantText;
            }
          }
        }
      });
    }

    this.triggerAdjustment();
  }

  takeAction(topicId, level) {
    this.complete();
    this.trigger('assessment:extraTraining', topicId, level, AssessmentLaunchContext.REPORT_CARD);
  }

  onClose() {
    this.knowledgeItemList.abortXHR(Sync.Method.READ);
    this.knowledgeItemAnswerList.abortXHR(Sync.Method.READ);

    if (this.questionPreview != null) {
      this.questionPreview.destroy();
    }

    logging.debug('KnowledgePage onClose');

    super.onClose();

    this.complete();
  }

  onSearch(query) {
    this.knowledgeItemList.search(query);

    this.knowledgeItemListView.render();

    if (query != null) {
      this.$('#number-of-results').text(I18n.t('search.numberOfResultsFor', {
        number: this.knowledgeItemList.length,
        query
      }));

      this.$('[type=search]').attr('aria-labelledby', 'number-of-results');
    }
  }

  onClearSearch() {
    this.$('#number-of-results').text('');
    this.$('[type=search]').removeAttr('aria-labelledby')
      .trigger('focus');
    this.knowledgeItemList.clearSearch();
    this.knowledgeItemListView.render();
  }

  onToggleKnowledgeTable() {
    this.ui.knowledgeWrapper.toggleClass('knowledge-show-table');
    this.ui.toggleKnowledgeTable.attr('aria-expanded', this.ui.knowledgeWrapper.hasClass('knowledge-show-table'));
    this.triggerAdjustment();
  }
}

module.exports = KnowledgePage;
