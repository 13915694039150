// Module
var code = `<li class="avail-invites-small ax-grid__col--12 ax-grid__col--l-6" aria-label="<%- countLabel %>">
  <div class="avail-small-wrapper clearfix">
    <div class="small-claim-left">
      <div class="small-name-logo" aria-hidden="true"></div>
    </div>
    <div class="small-claim-right">
      <div class="small-invites-details">
        <h2 class="name text-ellipsis ax-font ax-font__display ax-font--no-margin"><%- name %></h2>
        <p class="small-invite-meta ax-font--no-margin ax-font--secondary"><%- department %></p>
      </div>
    </div>
  </div>
  <div class="small-invite-buttonbar ax-grid ax-grid--align-items-center ax-grid--no-gutter">
    <div class="worth ax-font ax-font__caption"><%- t('referral.pointsAvailable', {points: points}) %></div>
    <div class="ax-grid__col--auto-size">
      <%= axButton({
        className: 'js-bounty-clicked',
        label: t('referral.select'),
        ariaLabel: t('referral.selectWho', {name: name}),
        size: 'm',
        data: { bountyId:bountyId }
      }) %>
    </div>
  </div>
</li>
`;
// Exports
module.exports = code;