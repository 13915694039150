import {
  Divider as MuiDivider,
  styled,
  type DividerTypeMap as MuiDividerTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxDividerCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxDividerTypeMap<P = object, D extends React.ElementType = 'hr'> = MuiDividerTypeMap<P & AxDividerCustomProps, D>;

export type AxDividerProps<
  D extends ElementType = AxDividerTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxDividerTypeMap<P & AxDividerCustomProps, D>, D>;

const styledOptions = {
  name: 'AxDivider'
};

const StyledAxDivider = styled(MuiDivider, styledOptions)<AxDividerProps>(({
  orientation,
  theme
}) => {
  const key = orientation === 'vertical' ? 'borderLeftWidth' : 'borderBottomWidth';

  return {
    [key]: '0.1rem',
    borderColor: theme.uiKit.divider.borderColor,
    // overrides textAlign prop border color
    '&::before, &::after': {
      borderColor: theme.uiKit.divider.borderColor
    }
  };
});

export const AxDivider: MuiOverridableComponent<AxDividerTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxDividerProps, ref: Ref<HTMLHRElement>) => {
  const DividerProps: AxDividerProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxDivider { ...DividerProps } ref={ ref }>
      { children }
    </StyledAxDivider>
  );
});

export default AxDivider;
