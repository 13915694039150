const logging = require('logging');

const BEACON_DATA_TYPE = 'beacon';
const SUCCESS_STATUS_TEXT = 'queueSuccess';
const FAILURE_STATUS_TEXT = 'queueFailure';

const HTTP_SUCCESS_CODE = 200;
const HTTP_ABORT_CODE = 0;

const Transport = ({ navigator } = window) => {
  return (options = {}) => {
    return {
      send(headers, doneCallback = () => {}) {
        const { url, data } = options;

        logging.info(`Sending Beacon to url: ${ url } with data: ${ JSON.stringify(data) }`);

        const wasAbleToQueue = navigator.sendBeacon(url, data);

        if (wasAbleToQueue) {
          doneCallback(HTTP_SUCCESS_CODE, SUCCESS_STATUS_TEXT);
        } else {
          doneCallback(HTTP_ABORT_CODE, FAILURE_STATUS_TEXT);
        }
      },
      abort() {
        // noop since beacon calls are fire and forget.
      }
    };
  };
};

const Prefilter = (hasBeaconSupport = window.navigator.sendBeacon != null) => {
  return (settings = {}, originalSettings) => {
    if (settings.useBeacon) {
      if (hasBeaconSupport) {
        return BEACON_DATA_TYPE;
      }

      Object.assign(settings, { async: false }, originalSettings);
    }

    return undefined;
  };
};

const setup = ($) => {
  $.ajaxPrefilter('json', Prefilter());
  $.ajaxTransport(BEACON_DATA_TYPE, Transport());
  $.ajaxSetup({
    converters: {
      'beacon json': function(data) {
        // Our beacon may return 'undefined', so no reason to throw an error.
        if (data === undefined) {
          return {};
        }
        try {
          return JSON.parse(data);
        } catch (e) {
          logging.error(`Error Parsing JSON:\`${ data }\``);
          throw e;
        }
      }
    }
  });
};

module.exports = {
  DataType: BEACON_DATA_TYPE,
  StatusText: {
    Success: SUCCESS_STATUS_TEXT,
    Failure: FAILURE_STATUS_TEXT
  },
  Transport,
  Prefilter,
  setup
};
