const Backbone = require('Backbone');

class ChallengeStatsModel extends Backbone.Model {
  apiEndpoint() {
    return '/games/challenges/stats';
  }

  parse(response = {}) {
    return response.entity || response;
  }
}

module.exports = ChallengeStatsModel;
