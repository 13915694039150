const { LayoutView } = require('Marionette');

const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const KeyCode = require('@common/data/enums/KeyCode');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const TitleHeaderDefinitionBuilder = require('@common/components/titleHeader/TitleHeaderDefinitionBuilder');
const TitleHeaderTypeEnum = require('@common/components/titleHeader/TitleHeaderTypeEnum');
const BADGE_MODIFIERS = require('@common/components/iconBadge/IconBadgeClassModifiers').default;

require('@common/libs/behaviors/card/Card');
const { INTERACTIVE } = require('@common/libs/behaviors/card/CardModifierClasses');

/*
 * USAGE:
 *
 * Call the exported getBaseCardControllerDefinition(cardOptions) method and provide the following options:
 *
 * cardOptions = {
 *
 *   // To use the default layout/templates but provide values for them, use the following options:
 *
 *   cardClass: 'Optional class name(s) (in addition to the default/standard "base-card" class) to add to the class attribute',
 *      // NOTE: if you want to declaratively state if there should be a divider line between the top/bottom sections, use the classes:
 *      // 'divided' or 'not-divided' (in addition to any other classes you want to add)
 *
 *   titleOptions: { // This is a config object that will be passed into the TitleHeaderDefinitionBuilder class.
 *      // NOTE: Almost all cards should have a title and most an iconClass, at least. Optional example values shown.
 *     iconClass: 'a-class-attribute-value-for-css-styling-hooks', // If empty/missing, there will be no icon image
 *     title: 'Card Title String', // Technically optional... not sure when you wouldn't want one, though
 *     titleTag: HTML tag for title, default depends on title type
 *     subTitle: 'Card sub-title string', // Optional. If empty/missing, there will be no subTitle row
 *     pretext: 'Card pretext/superscript string (above the title)', // Optional. Will not show up if there is also a subTitle provided
 *     badgeModifierClasses: [] // An array of value options from the IconBadgeClassModifiers enum. Defaults: [BADGE_MODIFERS.LARGE, BADGE_MODIFIERS.APP_ZONE]
 *   }, // NOTE: there are other possible option attributes you can provide, but these are the main ones
 *
 *   hasLink: true || false, // Will you have/want a 'View All' type link on the card? Default is false/no
 *
 *   linkOptions: { // Optional, but functionally required if 'hasLink' is true (otherwise the link will do nothing)
 *     linkTextKeyOverride: 'str.js.key.for.link.text', // Default text will be 'View all' - for no text, set linkTextKeyOverride = ''
 *
 *     callback: () => {}, // This is where you will set what the link click event does. (Default will be to do nothing.)
 *
 *     target: 'link' || 'fullCard' || 'topRegion'
 *      // 'link' will trigger the callback on the click event of the 'View All' type button/link only (if there is one)
 *      // 'fullCard' will trigger the callback on the click event of the entire card
 *      // 'topRegion' will trigger the callback on the click event of the top region
 *      // Default: 'topRegion' if hasLink is true or 'fullCard' otherwise
 *
 *   }, // NOTE: You could also/instead override the entire events block (see 'eventsOverride' below)
 *      // Overriding is required if you want to trigger on non-standard template nodes
 *
 *   bottomWrapperControllerDefinition: {
 *     // Provide a controller definition object for the bottomWrapperRegion - otherwise, it will be left blank
 *   }
 *
 *
 *   // To make more drastic changes to a card, use the following options:
 *
 *   templateOverride: `
 *     <h1>This is optional</h1>
 *     <div>
 *       <span class="exampleRegion">Optionally override of the entire template to use, but bear in mind if you do more
 *         than reorganize the expected nodes, you will likely also need to override the regions and regionControllers
 *         as well.</span>
 *       <span>The default is to reference either 'BaseCardTemplate.html' or 'BaseCardTemplateWithLink',
 *         depending on whether a 'View All' type link is desired (see below)</span>
 *     </div>`,
 *
 *   templateHelpersOverride: {
 *     helperValue: 'some value passed to the template above',
 *     optional: 'If you provide (or reference) a template above that needs template helpers, pass them here'
 *   },
 *
 *   regionsOverride: { // Declare names/selectors for regions in your new template (or link the default regions to new/different selectors)
 *     exampleRegion: '.example-class-selector',
 *     optional: '.if-you-provide-a-different-template-above',
 *     continued: '.you-can-declare-selectors-for-the-new-regions-here',
 *       // Default regions are:
 *     titleDiv: '.base-card__title',
 *     bottomWrapper: '.base-card__wrapper--lower'
 *   },
 *
 *   eventsOverride: { // Event handlers for overridden templates (examples provided below)
 *     'click .node-specific': handlerFunction  // This only listens for clicks on the event designated in the selector/node
 *     click: handlerFunction2,   // This will listen for click events anywhere on the card
 *   },
 *
 *   regionControllersOverride: {
 *     exampleRegion: {
 *       // If you provide overridden regions above and want to declare controllers for the new regions, do it here
 *     }
 *   },
 *
 *   attributesOverride: {  // This will add attributes to the actual card element in the DOM
 *     tabindex: 0, // This would make the whole card focusable (allowing you to attach keypress events)
 *     attributeName: 'attribute value'
 *   }
 *
 * }
 *
 */

const getBaseCardControllerDefinition = (cardOptions = {}) => {

  const cardConfig = {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: _getViewClass(cardOptions),
      className: _getClassNameString(cardOptions),
      template: _getCardTemplate(cardOptions),
      templateHelpers: _getCardTemplateHelpers(cardOptions),
      regions: _getCardRegions(cardOptions),
      attributes: _getCardAttributes(cardOptions),
      data: _getCardData(cardOptions),
      behaviors: _getCardBehavior(cardOptions),
      extraOptions: _getExtraOptions(cardOptions)
    },
    regionControllers: _getRegionControllers(cardOptions)
  };

  const events = _getEventsBlock(cardOptions);
  if (events != null) {
    Object.assign(cardConfig.viewDefinition, { events });
  }

  return cardConfig;
};

// INTERNAL HELPER FUNCTIONS

const _getExtraOptions = (cardOptions = {}) => {
  return cardOptions.extraOptions;
};

const _getCardBehavior = (cardOptions = {}) => {
  const behaviors = {};
  const cardBehavior = {
    Card: {
      modifierClasses: _isFullCardClickable(cardOptions) ? [INTERACTIVE] : []
    }
  };

  if (cardOptions.behaviors != null) {
    Object.assign(behaviors, cardOptions.behaviors);
  }

  return Object.assign(behaviors, cardBehavior);
};

const _isFullCardClickable = (cardOptions = {}) => {
  let cardClickable = false;

  if ( cardOptions.linkOptions && cardOptions.linkOptions.callback ) { // If there is no callback, there will be no link
    if (
      cardOptions.linkOptions.target === 'fullCard'
      || (!cardOptions.hasLink && cardOptions.linkOptions.target !== 'topRegion')
    ) {
      cardClickable = true;
    }
  }

  return cardClickable;
};

const _getViewClass = (cardOptions = {}) => {
  return cardOptions.viewClass ? cardOptions.viewClass : LayoutView;
};

const _getClassNameString = (cardOptions = {}) => {
  let className = 'base-card'; // The base/default card class attribute value

  // If the user provided a cardClass string, append that to the class attribute value
  if (cardOptions.cardClass) {
    className += ' ' + cardOptions.cardClass;
  }

  // If the user is not overriding the standard regions but does not provide a definition for the bottom wrapper region
  // it will be empty - so add the 'card--bottom-empty' class attribute to remove any extra visual spacing
  if ( !cardOptions.regionsOverride && !cardOptions.bottomWrapperControllerDefinition) {
    className += ' base-card--bottom-empty';
  }

  // If the whole card is going to be clickable, add the clickable style
  if (_isFullCardClickable(cardOptions)) {
    className += ' clickable';
  }

  return className;
};

const _getCardTemplate = (cardOptions = {}) => {

  // If the user provides a template, use that
  if (cardOptions.templateOverride) {
    return cardOptions.templateOverride;
  }

  // Otherwise, base the template on whether or not there is a link provided
  if (!cardOptions.hasLink || (cardOptions.linkOptions && !cardOptions.linkOptions.callback)) {
    return require('@common/components/baseCard/BaseCardTemplate.html');
  }
  return require('@common/components/baseCard/BaseCardTemplateWithLink.html');

};

const _getCardTemplateHelpers = (cardOptions = {}) => {

  // If the user provides their own helpers, use them
  if (cardOptions.templateHelpersOverride) {
    return cardOptions.templateHelpersOverride;
  }

  // Otherwise, set up the template helpers with either provided or default values
  const templateHelpers = {};
  const linkOptions = cardOptions.linkOptions || {};

  // Set the default values as if nothing is going to be clickable
  templateHelpers.topRegionClass = '';
  templateHelpers.topRegionAttributes = '';
  templateHelpers.linkAttributes = '';

  // If there's a link, determine the text key to use for the label
  if (cardOptions.hasLink) {
    templateHelpers.linkText
      = linkOptions.linkTextKeyOverride === ''
        ? ''
        : I18n.t(linkOptions.linkTextKeyOverride || 'general.viewAll');
    templateHelpers.linkAriaLabel = linkOptions.linkAriaLabel ? linkOptions.linkAriaLabel : templateHelpers.linkText;
  }

  // If Something is going to be clickable...
  if (linkOptions.callback && !cardOptions.tabbableLinkClickableCard) {

    // If the whole card is clickable, add a presentation role and disablble tabbing for the View All link (if any)
    if (_isFullCardClickable(cardOptions) && cardOptions.hasLink) {
      templateHelpers.linkAttributes = 'tabindex=-1 role=presentation';

    // If the top region is going to be clickable, add a topRegionClass, and region/link attributes
    } else if (
      (linkOptions.target === 'topRegion')
      || (cardOptions.hasLink && !linkOptions.target)
    ) {
      templateHelpers.topRegionClass = 'clickable';
    }
    // If the 'View All' button only is clickable - everything should be OK already (no changes needed)

  }

  return templateHelpers;
};

const _getCardRegions = (cardOptions = {}) => {

  // If the user provided a regions block, use it
  if (cardOptions.regionsOverride) {
    return cardOptions.regionsOverride;

  // Otherwise, provide the regions block for the default template(s)
  }
  return {
    titleRegion: '.base-card__title',
    bottomWrapperRegion: '.base-card__wrapper--lower'
  };

};

const _getEventsBlock = (cardOptions = {}) => {

  // If the user provided an events object block, use it
  if (cardOptions.eventsOverride) {
    return cardOptions.eventsOverride;
  }

  // If no override was provided, look for linkOptions
  const events = {};

  if (cardOptions.linkOptions) { // If there are linkOptions...

    if (cardOptions.linkOptions.callback) { // Make sure a callback function is provided. Otherwise, nothing to trigger on events (so no point).

      const fullCardClick = _isFullCardClickable(cardOptions);

      // Allow the event to be attached to the top region by default
      let eventSelector = 'click .js-base-card__wrapper--upper';
      if (fullCardClick) {
        // If the full card is clickable, add the 'click' event to the whole card
        eventSelector = 'click';
      } else if (cardOptions.linkOptions.target === 'link') {
        // NOTE: the default button class may be overwritten so we shouldn't select on the button's class
        eventSelector = 'click button';
      }

      events[eventSelector] = cardOptions.linkOptions.callback;

      // Add a corresponding keypress event for the enter key
      eventSelector = eventSelector.replace('click', 'keypress');
      events[eventSelector] = (e) => {
        if (e.which === KeyCode.ENTER) {
          cardOptions.linkOptions.callback(e);
        }
      };
    }
  }

  // If events is still empty - return a null events object - otherwise, return the object.
  if ( _.isEmpty(events) ) {
    return null;
  }

  return events;
};

const _getRegionControllers = (cardOptions = {}) => {

  // If the user provided a regionControllers block, use it
  if (cardOptions.regionControllersOverride) {
    return cardOptions.regionControllersOverride;

  // Otherwise, provide a default regionControllers block for the two default regions
  // (Including any overrides/details for the standard Title block and bottom wrapper controllerDefinition object)
  }
  const defaultTitleOptions = {};

  // The TitleHeaderDefinitionBuilder class has three options for Type: Basic (default), Subtext and Pretext
  if (cardOptions.titleOptions.subTitle) {
    defaultTitleOptions.titleType = TitleHeaderTypeEnum.SUBTEXT_TITLE;
  } else if (cardOptions.titleOptions.pretext) {
    defaultTitleOptions.titleType = TitleHeaderTypeEnum.PRETEXT_TITLE;
  } else {
    defaultTitleOptions.titleType = TitleHeaderTypeEnum.BASIC_TITLE;
  }

  // The default for badge modifiers is [BADGE_MODIFIERS.LARGE, BADGE_MODIFIERS.APP_ZONE] unless something else provided
  if (cardOptions.titleOptions.iconClass) {
    defaultTitleOptions.badgeModifierClasses = [BADGE_MODIFIERS.LARGE, BADGE_MODIFIERS.APP_ZONE];
  }

  const regionControllers = {
    titleRegion: new TitleHeaderDefinitionBuilder(Object.assign(defaultTitleOptions, cardOptions.titleOptions)).build()
  };

  if (cardOptions.bottomWrapperControllerDefinition) {
    Object.assign(regionControllers, { bottomWrapperRegion: cardOptions.bottomWrapperControllerDefinition });
  }

  return regionControllers;

};

const _getCardAttributes = (cardOptions = {}) => {

  // If the user provided an attributesOverride, use it
  if (cardOptions.attributesOverride) {
    return cardOptions.attributesOverride;
  }

  // Otherwise, determine if the full card will be clickable - if so, add a tabindex attribute and a role attribute
  let attributes;

  // If a card contains information that should be accessible by traversing a screenreader, set tabbableLinkClickableCard to prevent role=button
  if (_isFullCardClickable(cardOptions) && !cardOptions.tabbableLinkClickableCard) {
    attributes = {
      tabindex: 0,
      role: 'button'
    };
  }

  return attributes;
};

const _getCardData = (cardOptions = {}) => {
  const dataModel = cardOptions.data ? cardOptions.data.model : undefined;
  return {
    model: dataModel
  };
};

module.exports = {
  getBaseCardControllerDefinition
};
