const {
  filter,
  reduce,
  extend
} = require('underscore');

const {
  createProgressMarkerViewConfig,
  createProgressBarViewConfig
} = require('@training/apps/training/assessmentProgress/ProgressViewConfigFactory');

const getProgressSectionWidthClass = (configCount) => {
  if (configCount === 2) {
    return 'split-width';
  }
  return 'full-width';

};

class ProgressSectionBuilder {
  _configs = [];

  addProgressConfig(progressConfig) {
    this._configs.push(progressConfig);
  }

  build() {
    const filteredConfigs = filter(this._configs, (progressConfig) => {
      return progressConfig.isValid();
    });
    const sectionWidthClass = getProgressSectionWidthClass(filteredConfigs.length);

    return reduce(filteredConfigs, (configs, progressConfig) => {
      const progressSegment = createProgressBarViewConfig(extend(progressConfig.getProgressBarOptions(), { sectionWidthClass }));
      const marker = createProgressMarkerViewConfig(progressConfig.getMarkerOptions());

      return configs.concat(progressSegment, marker);
    }, []);
  }
}

module.exports = ProgressSectionBuilder;
