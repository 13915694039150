const {ItemView} = require('Marionette');

class KeyItemView extends ItemView {
  initialize() {
    this.listenTo(this.model, 'sync add change remove', this.render);
  }

  tagName() {
    return 'tr';
  }

  templateHelpers() {
    return {
      collection: this.model.get('name'),
      id: this.model.get('id')
    };
  }

  getTemplate() {
    return `
      <td id="<%- id %>" width="100%"><%- collection %></td>
    `;
  }

  events() {
    return {
      'click td': 'showModal'
    };
  }

  showModal(e) {
    this.trigger('show:modal', e.target.id);
  }
}

module.exports = KeyItemView;
