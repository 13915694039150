const FileUploadInput = require('@common/components/forms/editors/fileUpload/Form.Editor.FileUploadInput');

class EvaluationSubmissionUploadFileLinkView extends FileUploadInput {
  className() {
    return 'evaluation-submission-upload';
  }

  getTemplate() {
    return `<input class="file-input" type="file" accept="<%- acceptedTypes %>" id="<%= fileInputId %>" tabindex="-1" />`;
  }

  ui() {
    return Object.assign({}, super.ui(), {
      clickTarget: '.file-input'
    });
  }

  onRender() {
    this.$fileUploadButton = this.ui.clickTarget;
    super.onRender();
  }

  onChange() {
    const file = this.getValue();
    if (file) { // has to have selected a file, null shows no file selected now
      return this.trigger('select:file', file);
    }
    return undefined;
  }

  showErrorFlash(message) {
    window.app.layout.flash.error(message);
  }
}

module.exports = EvaluationSubmissionUploadFileLinkView;
