const {ItemView} = require('Marionette');
const Form = require('@common/components/forms/Form');
require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');

class LanguageView extends ItemView {
  getTemplate() {
    return `<form id="language_form">
      <div id="language-select" class="metadata__input">
        <h4><%- t('general.language') %></h4>
        <div>
          <%= axSelect({
            ariaLabel: t('general.language'),
            classNameSelect: 'js-change-selection qa-language-select',
            data: {
              editor: 'AxonSelect',
              field: '${ this.modelAttribute }',
              options: 'languageListOptions',
              shallow: 'true'
            },
            id: 'language-selector',
            name: 'languageSelector'
           }) %>
        </div>
      </div>
    </form>`;
  }

  initialize(options = {}) {
    ({
      languageList: this.languageList,
      modelAttribute: this.modelAttribute,
      width: this.width
    } = options);
  }

  modelEvents() {
    return {change: 'render'};
  }

  ui() {
    return {
      languageForm: '#language_form'
    };
  }

  onRender() {
    this.languageForm = new Form({
      el: this.ui.languageForm,
      model: this.model,
      context: {
        languageListOptions: {
          collection: this.languageList,
          axonSelectOptions: {
            width: this.width,
            useChosen: false
          }
        }
      }
    });
  }

  commit() {
    return this.languageForm.commit();
  }
}

module.exports = LanguageView;
