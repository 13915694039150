const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');

class GameScoresDetailsView extends UIKit.View {
  constructor(...args) {
    super(...args);
    this.gameDetailsTemplate = _.tpl(require('@training/apps/training/templates/_game_scores_details.html'));
    this.gameDetailsTemplateTeam = _.tpl(require('@training/apps/training/templates/_game_scores_team_details.html'));
  }

  render() {
    let additionalDetails, gameDetails, template;
    const game = this.model.toJSON().scores;

    // The API returns `singlePlayerScores` and `teamGameScores`, right now
    const {
      singlePlayerScores,
      teamGameScores
    } = game;

    // If we have the single player scores, render that
    if (singlePlayerScores) {
      gameDetails = singlePlayerScores;
      template = this.gameDetailsTemplate;
    } else if (teamGameScores) {
      // To keep the logic out of the template, we will find the index of the
      // current team and pass it in instead, here if we can
      const currentTeam = _.find(teamGameScores, (team) => {
        return team.current;
      });

      // Assign the details before giving it to the template for easy access
      // lookup if it exists
      if (currentTeam) {
        additionalDetails = {
          wins: currentTeam.data.wins,
          losses: currentTeam.data.losses
        };
      }

      gameDetails = teamGameScores;
      template = this.gameDetailsTemplateTeam;
    }

    this.$el.html(template({
      gameDetails,
      additionalDetails,
      teamName: window.apps.auth.session.user.get('location').name,
      timeFrame: I18n.t(`stats.timeframe.last7Days`)
    }));

    return this;
  }
}

module.exports = GameScoresDetailsView;
