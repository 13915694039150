const env = require('env');
const logging = require('logging');

// To keep the logs to a max of 1MB in data, we're calculating the max line length allowed and truncating anything above that limit.
const MAX_LOG_SIZE = 1048576; //1 MB
const MAX_LOG_LINE_CHARACTER_LENGTH = MAX_LOG_SIZE / logging.logMaxEntries;

const extractLineColumn = (stack = '') => {
  const [, line, column] = stack.match(/.*:(\d*):(\d*)/) || [];
  return {
    line,
    column
  };
};

const extractStackList = (message = '', stack) => {
  if (stack == null) {
    return undefined;
  }

  let stackList = stack.split('\n');
  const [first, ...tailList] = stackList;

  // Chrome prints out the error message with the stack trace, this gets rid of it since we log it separately.
  stackList = message.indexOf(first) > -1 ? tailList : stackList;

  return stackList.map((line) => {
    return line.trim();
  });
};

const getExceptionData = (exception) => {
  let {
    stack,
    name,
    message
  } = exception;

  message = `${ name }: ${ message }`;

  const {
    line,
    column
  } = extractLineColumn(stack);

  const stackTrace = extractStackList(message, stack);

  return {
    message,
    line,
    column,
    stackTrace
  };
};

const getGlobalErrorData = (errorData = {}) => {
  let {
    message,
    sourceURL,
    line,
    column,
    stackTrace
  } = errorData;

  message = message || undefined;
  sourceURL = sourceURL || undefined;
  line = line || undefined;
  column = column || undefined;
  stackTrace = extractStackList(message, stackTrace);

  return {
    message,
    sourceURL,
    line,
    column,
    stackTrace
  };
};

const truncateString = (str = '', numChars = 0) => {
  if (str.length <= numChars) {
    return str;
  }

  return `${ str.slice(0, numChars) }...`;
};

module.exports = {
  environment: (environment = {}) => {
    const defaults = {
      platform: window.navigator.platform,
      href: window.location.href,
      userAgent: window.navigator.userAgent,
      buildId: env.settings.buildId,
      instanceId: env.instanceId
    };

    return Object.assign(defaults, environment);
  },

  error: (errorObj) => {
    if (errorObj == null) {
      return undefined;
    } else if (errorObj instanceof Error) {
      return getExceptionData(errorObj);
    }
    return getGlobalErrorData(errorObj);

  },

  logs: (logs) => {
    if (logs === false) {
      return undefined;
    }

    return (logs || logging.latestEntries())
      .map((logLine) => {
        return truncateString(logLine, MAX_LOG_LINE_CHARACTER_LENGTH);
      });
  },

  suppressed: (isSuppressed) => {
    if (isSuppressed) {
      return 'Ignored!';
    }
    return undefined;
  }
};
