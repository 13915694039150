const File = require('@common/libs/file/File');

const { imageExtensionList } = require('@common/libs/helpers/types/StringHelpers');

class ImageFile extends File {

  static SIZE_LIMIT = 15; // in MB

  initialize(attrs, options = {}) {
    super.initialize(attrs, options);

    this.sizeLimit = ImageFile.SIZE_LIMIT;
    this.fileType = 'image';
    this.allowedExtensions = options.allowedExtensions || imageExtensionList;
  }

  isPreviewable() {
    return this.hasNativeFile() || this.has('path');
  }
}

module.exports = ImageFile;
