const _ = require('underscore');

const defaultButtonProps = {
  id: '',
  label: '',
  ariaLabel: '', // Only required if no label or different from visible label
  ariaExpanded: null, // Boolean, only required for buttons with expandable content (dropdowns)
  className: '',
  data: {}, // Optional data- properties
  type: 'button',

  // Modifiers
  size: 'l', // s/m/l support (small only for floating buttons)
  fullWidth: false,
  branded: false,
  brandedSecondary: false,
  disableKeyboardNav: false,
  ghost: false,
  ghostParentHover: false, // Behaves like ghost button, also highlights on parent element hover
  ghostLinkBranded: false,
  destructive: false,
  iconClass: '',
  iconClassLeft: '',
  iconClassRight: '',
  floating: false,
  autosize: false // this shrinks the button somewhat, suitable for when it's part of an actionableMenuDropdown
};

const buttonTemplate = (props) => {
  const classDefault = 'ax-button';
  let classList = classDefault;
  let dataProperties = '';

  if (props.data) {
    _.mapObject(props.data, (val, key) => {
      const hyphenKey = key.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
      dataProperties += ` data-${ hyphenKey }="${ val }"`;
    });
  }

  if (props.className ) {
    classList += ` ${ props.className }`;
  }

  if (props.iconClassRight) {
    classList += ` ${ classDefault }--icon-right`;
  }

  if (props.size !== 'l' ) {
    classList += ` ${ classDefault }--${ props.size }`;
  }

  if (props.fullWidth) {
    classList += ` ${ classDefault }--full-width`;
  }

  if (props.branded) {
    classList += ` ${ classDefault }--branded`;
  }

  if (props.brandedSecondary) {
    classList += ` ${ classDefault }--branded-secondary`;
  }

  if (props.ghost) {
    classList += ` ${ classDefault }--ghost`;
  }

  if (props.ghostParentHover) {
    classList += ` ${ classDefault }--ghost-parent-hover`;
  }

  if (props.ghostLinkBranded) {
    classList += ` ${ classDefault }--ghost-link-branded`;
  }

  if (props.destructive) {
    classList += ` ${ classDefault }--destructive`;
  }

  if (props.floating) {
    classList += ` ${ classDefault }--floating`;
  }

  if (props.iconClass) {
    classList += ` ${ classDefault }--icon`;
  }

  if (props.iconClassLeft) {
    classList += ` ${ classDefault }--icon-left`;
  }

  if (props.iconClassRight) {
    classList += ` ${ classDefault }--icon-right`;
  }

  if (props.autosize) {
    classList += ` ax-grid__col--auto-size`;
  }

  return _.tpl(`
    <button
      class="${ classList }"
      ${ props.id ? 'id="' + props.id + '"' : '' }
      ${ props.disabled ? 'disabled' : '' }
      ${ props.ariaLabel ? 'aria-label="' + props.ariaLabel + '"' : '' }
      ${ props.ariaExpanded !== null ? 'aria-expanded="' + props.ariaExpanded + '"' : '' }
      ${ props.ariaExpanded !== null ? 'aria-haspopup="' + props.ariaExpanded + '"' : '' }
      type="${ props.type }"
      ${ dataProperties }
      ${ props.disableKeyboardNav ? 'tabindex="-1" role="presentation"' : '' }
    >
      ${ props.iconClassLeft ? `<span class="${ props.iconClassLeft }" aria-hidden="true"></span>` : '' }
      ${ props.iconClass ? `<span class="${ props.iconClass }"></span>` : '' }
      ${ props.label ? `<span class="ax-button__label qa-button-label">${ props.label }</span>` : '' }
      ${ props.iconClassRight ? `<span class="${ props.iconClassRight }" aria-hidden="true"></span>` : '' }
    </button>
  `)(props);
};

const AxButtonTemplateHelpers = {
  axButton(props) {
    _.defaults(props, defaultButtonProps);
    return buttonTemplate(props);
  }
};

module.exports = AxButtonTemplateHelpers;
