import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitTag(cssVars: CSSStyleDeclaration) {
  return {
    tagBorderColor: getCssValue('--axTag-border-color', cssVars),

    tagArticleBackgroundColor: getCssValue('--axTag-article-background-color', cssVars),
    tagArticleTextColor: getCssValue('--axTag-article-text-color', cssVars),

    tagRecommendationBackgroundColor: getCssValue('--axTag-recommendation-background-color', cssVars),
    tagRecommendationTextColor: getCssValue('--axTag-recommendation-text-color', cssVars),
    tagRecommendationBorderColor: getCssValue('--axTag-recommendation-border-color', cssVars),

    tagBetaBackgroundColor: getCssValue('--axTag-beta-background-color', cssVars),
    tagBetaTextColor: getCssValue('--axTag-beta-text-color', cssVars),
    tagBetaBorderColor: getCssValue('--axTag-beta-border-color', cssVars),

    tagCertificationBackgroundColor: getCssValue('--axTag-certification-background-color', cssVars),
    tagCertificationTextColor: getCssValue('--axTag-certification-text-color', cssVars),

    tagEvaluationBackgroundColor: getCssValue('--axTag-evaluation-background-color', cssVars),
    tagEvaluationTextColor: getCssValue('--axTag-evaluation-text-color', cssVars),

    tagEventBackgroundColor: getCssValue('--axTag-event-background-color', cssVars),
    tagEventTextColor: getCssValue('--axTag-event-text-color', cssVars),

    tagExamBackgroundColor: getCssValue('--axTag-exam-background-color', cssVars),
    tagExamTextColor: getCssValue('--axTag-exam-text-color', cssVars),

    tagInReviewBackgroundColor: getCssValue('--axTag-in-review-background-color', cssVars),
    tagInReviewTextColor: getCssValue('--axTag-in-review-text-color', cssVars),

    tagLinkBackgroundColor: getCssValue('--axTag-link-background-color', cssVars),
    tagLinkTextColor: getCssValue('--axTag-link-text-color', cssVars),

    tagMilestoneBackgroundColor: getCssValue('--axTag-milestone-background-color', cssVars),
    tagMilestoneTextColor: getCssValue('--axTag-milestone-text-color', cssVars),

    tagModuleBackgroundColor: getCssValue('--axTag-module-background-color', cssVars),
    tagModuleTextColor: getCssValue('--axTag-module-text-color', cssVars),
    tagModuleBorderColor: getCssValue('--axTag-module-border-color', cssVars),

    tagPathBackgroundColor: getCssValue('--axTag-path-background-color', cssVars),
    tagPathTextColor: getCssValue('--axTag-path-text-color', cssVars),

    tagPublishedBackgroundColor: getCssValue('--axTag-published-background-color', cssVars),
    tagPublishedTextColor: getCssValue('--axTag-published-text-color', cssVars),

    tagQuestionBackgroundColor: getCssValue('--axTag-question-background-color', cssVars),
    tagQuestionTextColor: getCssValue('--axTag-question-text-color', cssVars),

    tagReportedBackgroundColor: getCssValue('--axTag-reported-background-color', cssVars),
    tagReportedTextColor: getCssValue('--axTag-reported-text-color', cssVars),

    tagTaskBackgroundColor: getCssValue('--axTag-task-background-color', cssVars),
    tagTaskTextColor: getCssValue('--axTag-task-text-color', cssVars),

    tagTopicBackgroundColor: getCssValue('--axTag-topic-background-color', cssVars),
    tagTopicTextColor: getCssValue('--axTag-topic-text-color', cssVars),

    tagValidationFailedBackgroundColor: getCssValue('--axTag-validation-failed-background-color', cssVars),
    tagValidationFailedTextColor: getCssValue('--axTag-validation-failed-text-color', cssVars),
    tagValidationPassedBackgroundColor: getCssValue('--axTag-validation-passed-background-color', cssVars),
    tagValidationPassedTextColor: getCssValue('--axTag-validation-passed-text-color', cssVars),
    tagValidationPendingBackgroundColor: getCssValue('--axTag-validation-pending-background-color', cssVars),
    tagValidationPendingTextColor: getCssValue('--axTag-validation-pending-text-color', cssVars),

    tagSurveyBackgroundColor: getCssValue('--axTag-survey-background-color', cssVars),
    tagSurveyTextColor: getCssValue('--axTag-survey-text-color', cssVars),
    tagSurveyBorderColor: getCssValue('--axTag-survey-border-color', cssVars),

    tagReassignedBackgroundColor: getCssValue('--axTag-reassigned-background-color', cssVars),
    tagReassignedTextColor: getCssValue('--axTag-reassigned-text-color', cssVars),
    tagReassignedBorderColor: getCssValue('--axTag-reassigned-border-color', cssVars),

    tagExpiredBackgroundColor: getCssValue('--axTag-expired-background-color', cssVars),
    tagExpiredTextColor: getCssValue('--axTag-expired-text-color', cssVars),

    tagDraftBackgroundColor: getCssValue('--axTag-draft-background-color', cssVars),
    tagDraftTextColor: getCssValue('--axTag-draft-text-color', cssVars),
    tagDraftBorderColor: getCssValue('--axTag-draft-border-color', cssVars)
  };
}
