const { VirtualCollection } = require('Backbone');
const { ItemView } = require('Marionette');

const I18n = require('@common/libs/I18n');

const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');

const SearchBox = require('@common/components/searchBox/SearchBox');
const LoadingController = require('@common/components/loading/LoadingController');

class LearningPathExplorerController extends StackedLayoutViewController {
  initialize(options = {}) {
    super.initialize(options);

    this.pathCollection = options.pathCollection;
    this.getCardViewDefinitionFn = options.getCardViewDefinitionFn;

    this.inProgressCollection = new VirtualCollection(this.pathCollection, {
      filter: (model) => {
        return !model.get('completed');
      }
    });

    this.completedCollection = new VirtualCollection(this.pathCollection, {
      filter: (model) => {
        return model.get('completed');
      }
    });
  }

  viewDefinition() {
    return Object.assign({}, super.viewDefinition(), {
      className: 'learning-path-explorer',
      behaviors: {
        MutationObservable: {
          observeOptions: {
            childList: true,
            subtree: true
          }
        }
      }
    });
  }

  childControllers() {
    return [
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learning-path-explorer__search-box ax-grid ax-grid--no-gutter'), {
        viewDefinition: {
          ViewClass: SearchBox,
          placeholderText: I18n.t('learnerTrainingPage.search.paths'),
          className: 'search-box ax-grid__col--12 ax-grid__col--m-6',
          getSearchQuery: (searchTerm) => {
            this.pathCollection.search(searchTerm);
          },
          clearSearch: () => {
            this.pathCollection.fetch();
          }
        },
        delegateEvents: {
          'view:inflate': (controller, view) => {
            if (this.pathCollection.searchQuery) {
              view.clearSearch();
            }
          }
        }
      }),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learning-path-explorer__stacked-content'), {
        ViewControllerClass: LoadingController.Skeleton,
        [LoadingController.Skeleton.Options.SYNC_TRACKER_CONFIG]: {
          entities: this.pathCollection
        },
        [LoadingController.Skeleton.Options.CONTENT_CONTROLLER_DEFINITION]: {
          ViewControllerClass: StackedLayoutViewController,
          childControllers: [
            StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learning-path-explorer__stacked-content'), {
              viewDefinition: {
                ViewClass: ItemView,
                tagName: 'h3',
                className: 'learning-path-explorer__header',
                template: I18n.t('learnerTrainingPage.tab.path.inProgress')
              }
            }),
            StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learning-path-explorer__stacked-content ax-grid ax-grid--no-gutter'), {
              ViewControllerClass: LoadingController.CollectionView,
              [LoadingController.CollectionView.Options.COLLECTION]: this.pathCollection,
              [LoadingController.CollectionView.Options.CONTENT_CONTROLLER_DEFINITION]: {
                ViewControllerClass: CollectionLayoutViewController,
                collection: this.inProgressCollection,
                viewDefinition: {
                  tagName: this.inProgressCollection.length === 0 ? 'div' : 'ul',
                  className: 'ax-grid search__results',
                  childViewOptions: {
                    tagName: 'li',
                    className: 'ax-grid__col--12 ax-grid__col--m-4'
                  },
                  emptyView: ItemView,
                  emptyViewOptions: {
                    template: require('@common/components/learningPathExplorer/PathTabEmptyInprogressTemplate.html')
                  }
                },
                ChildViewControllerDefinition: this.getCardViewDefinitionFn
              }
            }),
            StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learning-path-explorer__stacked-content ax-grid ax-grid--no-gutter'), {
              viewDefinition: {
                ViewClass: ItemView,
                tagName: 'h3',
                className: 'learning-path-explorer__header',
                template: I18n.t('learnerTrainingPage.tab.path.completed')
              }
            }),
            StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('learning-path-explorer__stacked-content ax-grid ax-grid--no-gutter'), {
              ViewControllerClass: LoadingController.CollectionView,
              [LoadingController.CollectionView.Options.COLLECTION]: this.pathCollection,
              [LoadingController.CollectionView.Options.CONTENT_CONTROLLER_DEFINITION]: {
                ViewControllerClass: CollectionLayoutViewController,
                collection: this.completedCollection,
                viewDefinition: {
                  tagName: this.completedCollection.length === 0 ? 'div' : 'ul',
                  className: 'ax-grid search__results',
                  childViewOptions: {
                    tagName: 'li',
                    className: 'ax-grid__col--12 ax-grid__col--m-4'
                  },
                  emptyView: ItemView,
                  emptyViewOptions: {
                    template: require('@common/components/learningPathExplorer/PathTabEmptyCompletedTemplate.html')
                  }
                },
                ChildViewControllerDefinition: this.getCardViewDefinitionFn
              }
            })
          ]
        }
      })
    ];
  }

  onViewDomMutate() {
    triggerResize();
  }
}

module.exports = LearningPathExplorerController;
