import {
  StepConnector as MuiStepConnector,
  type StepConnectorProps as MuiStepConnectorProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

export type AxStepConnectorProps = MuiStepConnectorProps;

const styledOptions = {
  name: 'AxStepConnector'
};

const StyledAxStepConnector = styled(MuiStepConnector, styledOptions)<AxStepConnectorProps>(({theme}) => {
  return {
    '.MuiStepConnector-line': {
      borderColor: theme.uiKit.colorGrey40
    }
  };
});

export const AxStepConnector = forwardRef(({
  ...otherProps
}: AxStepConnectorProps, ref: Ref<HTMLDivElement>) => {
  const stepConnectorProps: AxStepConnectorProps = {
    ...otherProps
  };

  return (
    <StyledAxStepConnector
      { ...stepConnectorProps }
      ref={ ref }
    />
  );
});

export default AxStepConnector;
