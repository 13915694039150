// Module
var code = `<div class="referral-confirmation">
  <div class="characterarea">
    <div class="bonuscharacter">
      <div class="<%- bonuscharacter %>" role="img"
        <% if (bonuscharacter !== 'NoCoach') { %>
          aria-label="<%- t('coaches.hello') %>"
        <% } %>
      ></div>
    </div>
  </div>
  <h1 class="messaging text-ellipsis-softwrap">
    <% if (days == 1) { %>
      <%= t('referral.confirmationText', {days: days, name: name}) %>
    <% } else { %>
      <%= t('referral.confirmationTextPlurial', {days: days, name: name}) %>
    <% } %>
  </h1>
  <p class="go-messaging">
    <%- t('referral.confirmationCallToAction') %>
  </p>
</div>
`;
// Exports
module.exports = code;