const Marionette = require('Marionette');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const QuestionPageEditViewLayout = require('@training/apps/articles/QuestionPageEditViewLayout');
const QuestionCreateContentEditView = require('@training/apps/discover/QuestionCreateContentEditView');
const ArticleMetadataEditController = require('@training/apps/articles/ArticleMetaDataEditController');

const TagList = require('@common/components/discover/collections/TagList');
const LanguageList = require('@common/data/collections/LanguageList');

class QuestionCreateModal extends Marionette.LayoutView {
  getTemplate() {
    return `
      <div class="modalHeader">
        <h1><%- t('discover.pageTypes.question.create.title') %></h1>
        <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
      </div>
      <div class="question-create-region"></div>\
    `;
  }

  regions() {
    return {
      questionCreateRegion: '.question-create-region'
    };
  }

  onRender() {
    this.questionCreateRegion.show(new QuestionPageEditViewLayout({
      model: this.model,
      contentView: this._getContentView(),
      metadataController: this._getMetadataController()
    }));

    this._setButtonBarView();
  }

  _getContentView() {
    const authSession = window.apps.auth.session;

    this.questionContentView = new QuestionCreateContentEditView({
      model: this.model,
      maxTitleLength: authSession.get('discoveryZonePageTitleMaxLength')
    });

    return this.questionContentView;
  }

  _getMetadataController() {
    const viewControllerFactory = new ViewControllerFactory();
    const options = {
      model: this.model,
      communityList: this.communityList,
      tagList: new TagList(),
      languageList: LanguageList.fromLanguageCodeList(TenantPropertyProvider.get().getProperty('languages')),
      isMediaAttachmentsEnabled: false,
      currentUser: window.apps.auth.session.user,
      isInsideQuestionCreateModal: true
    };

    this.metadataController = viewControllerFactory.create(ArticleMetadataEditController, options);
    return this.metadataController;
  }

  _setButtonBarView() {
    const buttons = [
      {
        type: 'customText',
        text: I18n.t('general.cancel'),
        className: 'white',
        onClick: this.destroy.bind(this)
      },
      {
        type: 'customText',
        text: I18n.t('discover.pageTypes.question.create.button'),
        onClick: this._saveClicked.bind(this)
      }
    ];

    this.actionBar.setActionBar({ buttons });
  }

  _saveClicked() {
    let errors = [];

    errors = errors.concat(this.questionContentView.commit());
    errors = errors.concat(this.metadataController.commit());
    errors = _.compact(errors);

    if (errors.length > 0) {
      window.app.layout.flash.error(errors);
    } else {
      this._savePage();
    }
  }

  _savePage() {
    return this.model.save().done( () => {
      window.app.layout.flash.success(I18n.t(`discover.pageTypes.${ this.model.get('type') }.save.success`, { title: this.model.get('title')}));
      this.destroy();
    })
      .fail( (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const errCode = exception.getErrorCode();

        if (errCode === AxonifyExceptionCode.CONTRACT_ERROR_VALUE_TOO_LONG) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t(`discover.pageTypes.generic.${ errCode }`));
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED
          || errCode === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.3017'));
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_LANGUAGE_NOT_SUPPORTED) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.error.3134'));
        }
      });
  }
}

module.exports = QuestionCreateModal;
