const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');

class EditList extends InfiniteCollection {
  apiEndpoint() {
    return `/articles/${ this.id }/revisions`;
  }

  constructor(models, options) {
    const newOptions = Object.assign({
      state: {
        pageSize: 20
      },
      fetchType: 'GET'
    }, options);

    super(models, newOptions);

    ({
      id: this.id
    } = options);
  }

  fetch(options = {}) {
    return super.fetch(Object.assign({ showSpinner: false }, options));
  }
}

module.exports = EditList;
