const Backbone = require('Backbone');
const _ = require('underscore');
const logging = require('logging');

const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

//This class exists to ease the transition from TranslatableStrings to LocalizableStrings
class Polyglot extends Backbone.Model {
  initialize(attributes = {}) {
    if (!this.translationProperty) {
      logging.error('Please implement a translationProperty property.');
    }

    if (!_.isEmpty(attributes[this.translationProperty])) {
      this.set(this.translationProperty, attributes[this.translationProperty]);
    } else {
      this.set(this.translationProperty, {});
    }
  }

  setValueForLanguage(langCode, newValue) {
    const value = this.get(this.translationProperty);
    value[langCode] = newValue;
    this.set(this.translationProperty, value);
    this.trigger('change');
  }

  setValue(options) {
    this.set(options);
  }

  getValues() {
    throw new Error('Subclasses need to implement getValues.');
  }

  matches(regexp) {
    const values = this.get(this.translationProperty);
    const matches = _.mapObject(values, (value) => {
      return regexp.exec(value);
    });
    return _.pick(matches, (x) => {
      return x != null;
    });
  }

  numberOfTranslations() {
    return _.keys(this.get(this.translationProperty)).length;
  }

  getLanguages() {
    return _.keys(this.get(this.translationProperty));
  }

  _safeGetTranslationAttributes() {
    if (this.translationProperty) {
      const attributes = this.get(this.translationProperty);
      return attributes;
    }

    return {};
  }

  hasLanguage(langCode) {
    const attributes = this._safeGetTranslationAttributes();
    return attributes[langCode] != null;
  }

  hasSomeValue() {
    return _.some(this.get(this.translationProperty), (value) => {
      return value !== '';
    });
  }

  hasSomeTextualValue(lang) {
    if (lang) {
      const attributes = this._safeGetTranslationAttributes();
      return HTMLHelpers.htmlHasSomeText(attributes[lang]);
    } //check all languages
    return _.some(this.get(this.translationProperty), HTMLHelpers.htmlHasSomeText);

  }

  sanitizeValues(sanitizeOptions = {}) {
    const values = this.get(this.translationProperty);
    for (const k in values) {
      const v = values[k];
      values[k] = HTMLHelpers.sanitizeString(v, sanitizeOptions);
    }
    this.set(this.translationProperty, values);
  }

  isTheSameByOrdinal(translatableString) {
    return _.isEqual(translatableString.get(this.translationProperty), this.get(this.translationProperty));
  }

  clone() {
    const attrs = $.extend(true, {}, this.attributes);
    return new this.constructor(attrs);
  }

  getValueForLanguage(langCode, useFallBackLanguage = true) {
    const attributes = this._safeGetTranslationAttributes();
    const value = attributes[langCode];
    let currentString = value || '';

    if (useFallBackLanguage && (currentString === '')) {
      currentString = this._getFallbackValue();
      if ((currentString != null) && (currentString !== '')) {
        return currentString;
      }

      const object = this.get(this.translationProperty);
      for (const k in object) {
        const v = object[k];
        currentString = v;
        if ((currentString != null) || (currentString === '')) {
          break;
        }
      }
    }

    return currentString;
  }

  _getFallbackValue() {
    logging.error('Please implement a _getFallbackValue method in your subclass.');
  }
}

module.exports = Polyglot;
