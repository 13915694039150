const logging = require('logging');

const XhrHelpers = require('XhrHelpers');

const shouldRetry = require('@common/libs/ajax/ShouldRetryAjax');

module.exports = {
  shouldRetry,
  headers: XhrHelpers.defaultHeaders(),
  cache: false, // Disable JSON caching on IE
  timeout: 30000, // 30s
  contentType: 'application/json',
  dataType: 'json',
  loading: true,
  globals: true,
  converters: {
    'text json': function (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        logging.warn(`Error Parsing JSON: \`${ data }\``);
        return {};
      }
    }
  }
};
