const _ = require('underscore');
const Backbone = require('Backbone');

const Game = require('@common/data/models/Game');

class GameList extends Backbone.Collection {
  get model() {
    return Game;
  }

  initialize(models, options = {}) {
    this.hideTeamGames = options.hideTeamGames != null ? options.hideTeamGames : false;
  }

  getSupportedGames(length) {
    let supportedGames = this.filter((game) => {
      // Exclude team games if they're not supported
      if (this.hideTeamGames && game.isTeamGame()) {
        return false;
      }
      return game.isSupported();
    });

    if (length != null) {
      supportedGames = _.first(supportedGames, length);
    }

    const newCollection = this.clone();
    newCollection.reset(supportedGames);
    return newCollection;
  }
}

class AllGamesList extends GameList {
  comparator(model) {
    return model.get('name');
  }
}
module.exports = {AllGamesList, GameList};
