const { Model } = require('Backbone');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class SettingsTabModel extends Model {
  defaults() {
    return {
      AUTO_TRANSLATIONS_ENABLED: TenantPropertyProvider.get().getProperty('autoTranslationsEnabled')
    };
  }

  saveProperties() {
    return $.ajax({
      type: 'PUT',
      apiEndpoint: 'tenant/current/propertyValues',
      data: JSON.stringify(this.toJSON()),
      dataType: 'json'
    });
  }
}

module.exports = SettingsTabModel;
