const _ = require('underscore');

const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');

const RedirectingAbortedAuthentication = require('@training/apps/auth/exceptions/RedirectingAbortedAuthentication');

class SelfRegistrationCompletePage extends UIKit.View {
  template = _.tpl(require('../templates/SelfRegistrationCompletePage.html'));

  render() {
    this.$el.html(this.template());
    return this;
  }

  viewDidAppear() {
    this.actionBar.setActionBar({
      buttons: {
        type: 'customText',
        text: I18n.t('selfRegistration.complete.gotoLoginBtn'),
        onClick: this.gotoLogin.bind(this)
      }
    });
  }

  gotoLogin() {
    RedirectingAbortedAuthentication.catchAndLog(() => {
      window.apps.auth.redirectToLoginPage({ loginPrompt: false });
    });
  }

  onClose() {
    this.actionBar.setActionBar();
  }
}

module.exports = SelfRegistrationCompletePage;
