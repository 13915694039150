const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const DetailViewConfig = require('@common/components/details/DetailViewConfig');
const TopicLevelRowView = require('@training/apps/topics/TopicLevelRowView');
const CertificationStatus = require('@training/apps/training/enums/CertificationStatus');
const { ReactControllerDefinitionFactory } = require('@common/modules/react');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const {
  getDueDateViewDefinition,
  getModulesViewDefinition,
  getQuestionsViewDefinition
} = require('@common/components/cardMetadata/MetadataStringHelper');

class TopicLevelRowViewController extends LayoutController {
  constructor(options = {}) {
    super(options);

    ({
      topicId: this.topicId,
      model: this.model,
      startTrainingFn: this.startTrainingFn
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: TopicLevelRowView,
      model: this.model,
      startTrainingFn: this.startTrainingFn,
      topicId: this.topicId,
      regions: {
        metaRegion: '.title-container__meta',
        printCertificationButtonRegion: '.print-certifications-button-wrapper'
      }
    };
  }

  regionControllers() {
    return {
      metaRegion: {
        viewDefinition: {
          ViewClass: StackedCollectionView,
          viewConfigModel: DetailViewConfig,
          className: 'stacked-collection-view',
          viewConfigs: [
            this._getDueDateViewDefinition(),
            getModulesViewDefinition(this.model.toJSON()),
            getQuestionsViewDefinition(this.model.get('questionCount'))
          ]
        }
      },
      printCertificationButtonRegion: this.canShowPrintCertifications()
        ? ReactControllerDefinitionFactory({
          component: import('@training/apps/topics/printCertifications/PrintCertificationsButtonContextWrapper'),
          props: {
            user: window.apps.auth.session.user,
            buttonVariant: 'branded-secondary',
            topicId: this.topicId,
            level: this.model.get('level')
          }
        }) : null
    };
  }

  _getDueDateViewDefinition () {
    const certDetails = this.model.get('certDetails');
    if (certDetails == null || certDetails.dueDate == null || certDetails.status === CertificationStatus.CERTIFIED) {
      return null;
    }

    return getDueDateViewDefinition(certDetails.dueDate);
  }

  canShowPrintCertifications() {
    return TenantPropertyProvider.get().getProperty('isPrintCertificationsEnabled') && this.model.get('certDetails')?.status === CertificationStatus.CERTIFIED;
  }
}

module.exports = TopicLevelRowViewController;
