const Backbone = require('Backbone');

const Group = require('@training/apps/search/communitiesManagement/Group');
const GroupType = require('@common/data/enums/GroupType');

class GroupList extends Backbone.Collection {
  get model() {
    return Group;
  }

  apiEndpoint() {
    return GroupType.getGroupEndpointByType(GroupType.TrainingGroup);
  }

  comparator(model) {
    return model.get('name').toUpperCase();
  }

  parse(res) {
    return res.entities || res;
  }
}

module.exports = GroupList;
