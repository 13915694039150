const { Model } = require('Backbone');

const PageSharedCommunitiesList = require('@common/components/discover/collections/PageSharedCommunitiesList');

class PageMetadataModel extends Model {
  initialize(options = {}) {
    ({
      pageId: this.pageId
    } = options);
  }

  apiEndpoint() {
    return `/pages/${ this.pageId }/metadata`;
  }

  parse(data = {}) {
    this.pageSharedCommunitiesList = new PageSharedCommunitiesList(data.communities, {
      pageId: this.pageId,
      parse: true
    });
    this.title = data.title;
  }

  getHomeCommunity() {
    return this.pageSharedCommunitiesList.getHomeCommunity();
  }
}

module.exports = PageMetadataModel;
