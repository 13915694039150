const KnowledgeItemList = require('@training/apps/training/collections/KnowledgeItemList');
const { Model } = require('Backbone');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const TopicStatus = require('@common/data/enums/TopicStatus');

class TopicProgressItemList extends KnowledgeItemList {

  get model() {
    return Model;
  }

  parse(response = {}) {
    if (!response.result) {
      response.result = {};
    }
    return this.categorizeResults(response.result.knowledgeMapResults);
  }

  categorizeResults(knowledgeMapResults = []) {
    return [
      {
        id: 'not-started', // NOTE: used to select the correct icon class
        name: I18n.t('hub.topicLevelStatus.notStarted'),
        value: _.where(knowledgeMapResults, {progressLevel: TopicStatus.NOT_STARTED}).length
      }, {
        id: 'beginner',
        name: I18n.t('hub.topicLevelStatus.beginner'),
        value: _.where(knowledgeMapResults, {progressLevel: TopicStatus.BEGINNER}).length
      }, {
        id: 'intermediate',
        name: I18n.t('hub.topicLevelStatus.intermediate'),
        value: _.where(knowledgeMapResults, {progressLevel: TopicStatus.INTERMEDIATE}).length
      }, {
        id: 'graduate',
        name: I18n.t('hub.topicLevelStatus.graduate'),
        value: _.where(knowledgeMapResults, {progressLevel: TopicStatus.GRADUATE}).length
      }, {
        id: 'expert',
        name: I18n.t('hub.topicLevelStatus.expert'),
        value: _.where(knowledgeMapResults, {progressLevel: TopicStatus.EXPERT}).length
      }
    ];
  }

  comparator() {
    return 0;
  }
}

module.exports = TopicProgressItemList;
