import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type IconBadgeClassModifierType = EnumType & {
  MEDIUM: string,
  LARGE: string,
  APP_ZONE: string,
  REINFORCEMENT: string,
  CERTIFICATION: string,
  INTRO: string,
  GUIDED_LEARNING: string,
  REFRESHER: string,
  EXAM: string,
  SELF_DIRECTED_PATHS: string,
  TROPHY: string,
  OK: string,
  GRADUATED: string,
  FREQUENT_TRAINING: string,
  ACHIEVEMENTS: string,
  PAGE_HEADER_ICON: string
};

const IconBadgeClassModifiersEnum = Enum({
  MEDIUM: 'icon--badge-md',
  LARGE: 'icon--badge-lg',
  APP_ZONE: 'icon--badge-app-zone',
  REINFORCEMENT: 'icon--badge-reinforcement',
  CERTIFICATION: 'icon--badge-certification',
  INTRO: 'icon--badge-intro-training',
  GUIDED_LEARNING: 'icon--badge-guided-learning',
  REFRESHER: 'icon--badge-refresher',
  EXAM: 'icon--badge-exam',
  SELF_DIRECTED_PATHS: 'icon--badge-self-directed-paths',
  TROPHY: 'icon--badge-trophy',
  OK: 'icon--badge-ok',
  GRADUATED: 'icon--badge-graduated',
  FREQUENT_TRAINING: 'icon--badge-frequent-training',
  ACHIEVEMENTS: 'icon--badge-achievements',
  PAGE_HEADER_ICON: 'page-header__icon'
}) as IconBadgeClassModifierType;

export default IconBadgeClassModifiersEnum;
