const DataLoadingSpinnerController = require('@common/components/dataLoadingSpinner/DataLoadingSpinnerController');

const DataLoadingSpinnerContextConfig = require('@common/components/dataLoadingSpinner/DataLoadingSpinnerContextConfig');
module.exports = (contextModel) => {
  return {
    id: 'loading-spinner',
    ViewControllerClass: DataLoadingSpinnerController,
    contextModel,
    regionControllers: {
      contentRegion: () => {
        return DataLoadingSpinnerContextConfig.getContentControllerDefinition(contextModel);
      }
    },
    delegateEvents: {
      'view:inflate': (controller) => {
        controller.listenTo(contextModel, `change:${ DataLoadingSpinnerContextConfig.Keys.DATA_LOADING_SPINNER_CONTENT_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('contentRegion', controllerDefinition, options.showOptions);
        });
      }
    }
  };
};
