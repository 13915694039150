const $os = require('detectOS');

const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const FileHelpers = require('@common/libs/file/FileHelpers');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');

const { axButton } = require('@common/components/axButton/AxButtonTemplateHelpers');
const {
  axCheckbox,
  axRadio,
  axToggle,
  axInput,
  axSelect
} = require('@common/components/axForm/AxFormTemplateHelpers');

const ViewHelpers = {
  t(...args) {
    return I18n.t(...args);
  },

  langName(code) {
    return I18n.languageNameFromCurrentLocale(code);
  },

  numberWithCommas(...args) {
    return I18n.numberWithCommas(...args);
  },

  cleanImageUrl(imageUrl = '') {
    return imageUrl.replace(/_(thumb|desktop_thumb|mobile_thumb|mobile|desktop)/, '');
  },

  // Desktop Image Size for Questions
  desktop(imageUrl) {
    if (!imageUrl) {
      return '';
    }
    const cleanUrl = this.cleanImageUrl(imageUrl);
    const parts = cleanUrl.split('.');
    return parts.slice(0, -1).join('.') + '_desktop.' + parts.slice(-1);
  },

  limitToCharacters(s = '', len) {
    if (s.length > len) {
      return `${ s.substring(0, len) }...`;
    }
    return s;

  },

  // Update image url according to the device type
  r(imageUrl) {
    if (!imageUrl) {
      return '';
    }
    const cleanUrl = this.cleanImageUrl(imageUrl);
    const parts = cleanUrl.split('.');
    if ($os.mobile) {
      return parts.slice(0, -1).join('.') + '_mobile.' + parts.slice(-1);
    } else if ($os.desktop) {
      return parts.slice(0, -1).join('.') + '_desktop.' + parts.slice(-1);
    }
    return cleanUrl;

  },

  axButton(props) {
    return axButton(props);
  },

  axCheckbox(props) {
    return axCheckbox(props);
  },

  axRadio(props) {
    return axRadio(props);
  },

  axToggle(props) {
    return axToggle(props);
  },

  axInput(props) {
    return axInput(props);
  },

  axSelect(props) {
    return axSelect(props);
  },

  axSearch(props) {
    const searchProps = Object.assign({}, props, { type: 'search' });
    return axInput(searchProps);
  },

  axTextarea(props) {
    const textareaProps = Object.assign({}, props, { type: 'textarea' });
    return axInput(textareaProps);
  },

  bonuscharacter: 'NoCoach',
  HTMLHelpers,
  StringHelpers,
  dateHelpers,
  downloadLink: FileHelpers.downloadLink,

  showBackButtonWithReset ({ view }) {
    window.app.layout.showHeaderBackButton();

    view.listenTo(view, 'destroy', () => {
      window.app.layout.resetLeftHeaderView();
    });
  }
};

module.exports = ViewHelpers;
