const Backbone = require('Backbone');
const logging = require('logging');
const _ = require('underscore');
const File = require('@common/data/models/media/File');
const I18n = require('@common/libs/I18n');

class DocumentFile extends File {

  constructor(attrs, options) {
    super(attrs, options);
    this.save = this.save.bind(this);
    this.ping = this.ping.bind(this);
    this.poll = this.poll.bind(this);
    this.mediaFileStatusPoll = this.mediaFileStatusPoll.bind(this);
    this.handleTotalTimeWating = this.handleTotalTimeWating.bind(this);
    this.createMedia = this.createMedia.bind(this);
  }

  initialize() {
    this.currentTimeout = null;
    this.totalTimeout = 60000;
    this.totalTimeoutId = null;
    this.timeout = 250;
  }

  parse(res) {
    return res;
  }

  save(key, value, options = {}) {
    let attrs = {};
    if ((key === null) || (typeof key === 'object')) {
      attrs = key;
      // eslint-disable-next-line no-param-reassign
      options = value || {};
    } else {
      attrs[key] = value;
    }

    options.allowedExtensions = options.allowedExtensions || ['*'];
    options.sizeLimit = options.sizeLimit || 1000;

    options.success = _.wrap(options.success, (onSuccess = $.noop, response) => {
      this.mediaFileStatusPoll(response.id, onSuccess, options.error);
    });

    return super.save(attrs, options);
  }

  ping() {
    return $.ajax({
      type: 'GET',
      url: `/axonify/media/file/${ this.requestID }`,
      success: (resp) => {
        if (resp.status !== 'done') {
          this.poll(resp.uuid);
        } else {
          if (this.currentTimeout != null) {
            clearTimeout(this.currentTimeout);
          }
          if (this.totalTimeoutId != null) {
            clearTimeout(this.totalTimeoutId);
          }
          this.set(resp);
          if (_.isFunction(this.successCallback)) {
            this.successCallback(this);
          }
        }
      },
      error: (resp) => {
        logging.error(`Error happened on ping: ${ resp }`);
        if (this.currentTimeout != null) {
          clearTimeout(this.currentTimeout);
        }
        if (this.totalTimeoutId != null) {
          clearTimeout(this.totalTimeoutId);
        }
        if (_.isFunction(this.errorCallback)) {
          this.errorCallback(this, resp);
        }
      }
    });
  }

  poll(id) {
    this.requestID = id;
    if (this.currentTimeout != null) {
      clearTimeout(this.currentTimeout);
    }
    this.currentTimeout = setTimeout(this.ping, this.timeout);
  }

  mediaFileStatusPoll(fileId, successCallback, errorCallback) {
    this.successCallback = successCallback;
    this.errorCallback = errorCallback;
    this.requestID = fileId;
    this.totalTimeoutId = setTimeout(this.handleTotalTimeWating, this.totalTimeout);
    return this.ping();
  }

  handleTotalTimeWating() {
    logging.error(`mediaFileStatusPoll took ${ this.totalTimeout }`);
    if (this.currentTimeout != null) {
      clearTimeout(this.currentTimeout);
    }
    if (this.totalTimeoutId != null) {
      clearTimeout(this.totalTimeoutId);
    }
    if (_.isFunction(this.errorCallback)) {
      this.errorCallback(this, {errMessage: I18n.t('flash.serviceDown')}, {});
    }
  }

  createMedia() {
    const deferred = $.Deferred();

    this.documentMediaModel = new DocumentMedia();

    this.documentMediaModel.set('originalFile', this.attributes);

    if (this.documentMediaModel.get('originalFile') != null) {
      if (this.documentMediaModel.isNew()) {
        this.documentMediaModel.save().then(deferred.resolve, deferred.reject);
      } else {
        deferred.resolve();
      }
    } else {
      deferred.reject();
    }

    return deferred.promise();
  }

  getMedia() {
    return this.documentMediaModel.toJSON();
  }
}


class DocumentMedia extends Backbone.Model {

  get idAttribute() {
    return 'uuid';
  }

  urlRoot() {
    return '/axonify/media/document';
  }

  toJSON(...args) {
    const json = super.toJSON(...args);

    if (json.originalFile && _.isFunction(json.originalFile.toJSON)) {
      json.originalFile = json.originalFile.toJSON();
    }

    return json;
  }
}

module.exports = {DocumentFile, DocumentMedia};
