const _ = require('underscore');

const EventEnrollmentStatus = {
  Unenrolled: 'Unenrolled'
};

const NotificationList = require('@training/apps/training/collections/NotificationList');

class EventNotificationList extends NotificationList {
  parse(res) {
    if (res.results.length === 0) {
      return res.results;
    }

    let filteredNotifications = [];
    const results = _.chain(res.results)
      .filter((result) => {
        return result.group === this.notificationsGroup;
      })
      .groupBy((notification) => {
        return notification.referencedItem.scheduledLearningEvent.id;
      })
      .value();

    for (const key in results) {
      // sort by date, latest at [0]
      let value = results[key];
      value = _.sortBy(value, (item) => {
        return item.lastModify;
      });

      // If the last event in sequence is an unenrolled, then discard the rest
      // assuming this is the only notification left (last is most recent)
      if (_.last(value).type !== EventEnrollmentStatus.Unenrolled) {
        // Otherwise, we can go ahead and add the new values to the list
        const validNotifications = _.chain(value)
          .groupBy((notification) => {
            return notification.type;
          })

          // .. and nix any unenrolled events
          .omit(EventEnrollmentStatus.Unenrolled)

          // Only include the first of each type
          .map((v) => {
            return _.first(v);
          })
          .value();

        filteredNotifications = filteredNotifications.concat(validNotifications);
      }
    }

    return filteredNotifications;
  }
}

module.exports = EventNotificationList;
