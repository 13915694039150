const { isFunction } = require('underscore');
const I18n = require('@common/libs/I18n');
const AssessmentType = require('@common/data/enums/AssessmentType');
const TopicLevelActionType = require('@common/data/enums/TopicLevelActionType');
const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');

const _goToPathsPage = (action) => {
  const hubController = window.app.sessionController.getHubController();

  if (action.forGL) {
    window.app.sessionController.redirectGL({
      pageId: GuidedLearningPageEnum.DetailsPage,
      pageOptions: { programId: action.programId }
    });
  } else {
    hubController.showIntroTraining();
  }
};

const _findStartableLevel = (topicLevels = [], session) => {
  for (let i = 0; i < topicLevels.length; i++) {
    if (isStartable(topicLevels[i].action, session)) {
      return topicLevels[i];
    }
  }
  return undefined;
};

const _isAlreadyPerformed = (action, session) => {
  const topicLevel = action.topicLevel;
  let alreadyPerformed = true;

  if (action.actionType === TopicLevelActionType.VISIT_PATH) {
    return false;
  } else if (topicLevel != null && topicLevel.topicId != null && topicLevel.level != null) {
    if (isFunction(session.hasExtraQuestionsAssessmentsForTopicIdLevel)) {
      // Return false/hide button if the data isn't available to determine if this training has already been performed today
      alreadyPerformed = session.hasExtraQuestionsAssessmentsForTopicIdLevel(topicLevel.topicId, topicLevel.level);
    }
  }

  return alreadyPerformed;
};

const isStartable = (action = {}, session = {}) => {
  const actionType = action.actionType || '';

  if ([TopicLevelActionType.NONE, TopicLevelActionType.LOCKED, ''].includes(actionType)) {
    return false;
  } else if (action.assessmentType != null && action.assessmentType !== AssessmentType.ExtraTraining) {
    return true;
  }
  return !_isAlreadyPerformed(action, session);
};

// Fallback for when primary action is on a level that has reached its daily extra training limit
// Server doesn't have visibility into extra training limit, so we try to point primary action to a topic level that is startable
const findStartableTopicLevelAction = (resultData, session) => {
  let startableTopicLevel;

  if (resultData != null) {
    startableTopicLevel = _findStartableLevel(resultData.get('topicLevels'), session);
  }

  if (startableTopicLevel != null) {
    return Object.assign({}, startableTopicLevel.action, {actionType: TopicLevelActionType.QUICK_QUIZ});
  }

  return undefined;
};

const handleActionForType = (action, launchContext, startTrainingFn, pageContext = {}) => {
  if (action != null && action.actionType != null) {
    if (action.actionType === TopicLevelActionType.VISIT_PATH) {
      _goToPathsPage(action);
    } else if (isFunction(startTrainingFn)) {
      const trainingOptions = {};

      if (action.topicLevel != null) {
        trainingOptions.topicId = action.topicLevel.topicId;
        trainingOptions.level = action.topicLevel.level;
      }

      if (action.programId != null) {
        trainingOptions.programId = action.programId;
      }

      if (action.assessmentType === AssessmentType.ExtraTraining) {
        trainingOptions.isPopQuiz = action.actionType !== TopicLevelActionType.RETAKE;
      }

      if (AssessmentType.supportsHistoricalRetake(action.assessmentType)) {
        trainingOptions.isRetake = action.actionType === TopicLevelActionType.RESTART;
      }

      trainingOptions.assessmentType = AssessmentType.assertLegalValue(action.assessmentType);
      trainingOptions.launchContext = launchContext;

      startTrainingFn(trainingOptions, pageContext);
    }
  }
};

const getButtonText = (action) => {
  if (action != null && action.actionType != null) {
    switch (action.actionType) {
      case TopicLevelActionType.START_CERT:
        return I18n.t('start.startButton.certification');
      case TopicLevelActionType.VISIT_PATH:
        return I18n.t('selfDirected.viewPathBtn');
      case TopicLevelActionType.START:
        return I18n.t('start.startButton.start');
      case TopicLevelActionType.RESTART:
        return I18n.t('general.continue');
      case TopicLevelActionType.RETAKE:
        return I18n.t('assessments.item.action.fail');
      case TopicLevelActionType.RESUME:
        return I18n.t('assessments.item.action.resume');
      case TopicLevelActionType.QUICK_QUIZ:
        return `<span class="icon-bolt"></span>${ I18n.t('general.boost') }`;
      default:
        return I18n.t('start.startButton.start');
    }
  }
  return '';
};

module.exports = {
  findStartableTopicLevelAction,
  isStartable,
  handleActionForType,
  getButtonText
};
