const Marionette = require('Marionette');
const _ = require('underscore');
const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');

class AxonifyTemplateCache extends Marionette.TemplateCache {
  static get(templateId = '') {
    const cachedTemplate = this.templateCaches[templateId];
    if (cachedTemplate) {
      return cachedTemplate.load();
    }
    const newTemplate = new AxonifyTemplateCache(templateId);
    AxonifyTemplateCache.templateCaches[templateId] = newTemplate;
    return newTemplate.load();
  }

  constructor(templateId) {
    super(CryptographyHelper.getMD5Hash(templateId));
    this.rawTemplate = templateId;
  }

  loadTemplate() {
    const tpl = this.rawTemplate;
    delete this.rawTemplate;
    return tpl;
  }

  compileTemplate(rawTemplate) {
    return _.template(rawTemplate);
  }
}
module.exports = AxonifyTemplateCache;
