import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type TranscriptionMethodsType = EnumType & {
  MECHANICAL: string,
  MANUAL: string,
  PROFESSIONAL: string,
  NONE: string
};

const TranscriptionMethods = Enum({
  MECHANICAL: 'mechanical', // transcription
  MANUAL: 'manual', // user input
  PROFESSIONAL: 'professional', // translation
  NONE: 'none'
}) as TranscriptionMethodsType;

export default TranscriptionMethods;
