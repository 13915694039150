const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TagGroupFiltersEmptyContentView = require('@training/apps/search/communitiesManagement/tagGroupFilters/content/TagGroupFiltersEmptyContentView');

class TagGroupFiltersEmptyContentController extends LayoutController {
  viewDefinition() {
    return TagGroupFiltersEmptyContentView;
  }
}

module.exports = TagGroupFiltersEmptyContentController;
