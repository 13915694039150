// Module
var code = `<h1 class="page-header">
  <div class="ax-container">
    <div class="page-header__content">
      <span class="page-header__icon icon-profile" aria-hidden="true"></span>
      <div class="page-header__title"><%- t('settings.title')%></div>
    </div>
  </div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="settingswrapper ax-grid">
      <div class="left ax-grid__col--12 ax-grid__col--m-3">
        <div class="settingslistwrapper" role="navigation" aria-label="<%- t('settings.navigation') %>">
          <ul class="settingslist list"></ul>
        </div>
      </div>
      <div class="right ax-grid__col--12 ax-grid__col--m-9">
      </div>
    </div>
  </div>
</div>


`;
// Exports
module.exports = code;