const $os = require('detectOS');
const logging = require('logging');
const { View } = require('@training/widgets/UIKit');
const env = require('env');

class GamePlayFrame extends View {
  tagName() {
    return 'iframe';
  }

  className() {
    return 'game-frame fit-parent';
  }

  attributes() {
    return {
      tabindex: '-1'
    };
  }

  constructor(options = {}) {
    super(options);

    logging.debug('GamePlayFrame - Initializing');

    ({gameType: this.gameType} = options);

    this.showView = this.showView.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this._onWindowMessage = this._onWindowMessage.bind(this);

    this._gameApi = options.gameApi;

    this.destroyImmediate = false;
  }

  viewDidAppear() {
    logging.debug('GamePlayFrame - ViewDidAppear');

    window.app.layout.toggleFooter(false);

    if ($os.mobile) {
      window.app.layout.toggleFullScreen(true);
    }

    window.app.layout.toggleFullPage(true);

    if ($os.windowsphone) {
      $('.contentwrapper').css('-ms-touch-action', 'none');
    }

    // on resize, have to make sure the elements are resized
    this.showView();
  }

  render() {
    // Added for debugging missing/null/undefined iframe issue
    logging.debug('GamePlayFrame - Rendering');
    return this;
  }

  showView() {
    $(this.$el.parent()).css('overflow', 'hidden');
    this.loadUrl();
  }

  loadUrl() {
    this.$el.on('load', this.onLoad);

    const rootPath = `/games/${ env.settings.version }/index.html`;

    // set the iframe's source, cache bump with the build timestamp to bypass
    // cloudfront cache after each build, this is only a problem for hotfixes in
    // which case we keep the old `settings.version` instead of version increment
    this.$el.attr('src', `${ rootPath }${ env.invalidateCacheQuery }#${ this.gameType }`);
  }

  setFocus() {
    logging.debug('GamePlayFrame - Setting iframe focus');

    const iframe = this.$el[0];

    if ((iframe != null ? iframe.contentWindow : undefined) != null) {
      iframe.contentWindow.focus();
    } else {
      logging.debug('Game iFrame is null');
    }
  }

  setGameApi(api) {
    this.$el[0].contentWindow.apps.GameApi = api;
    window.addEventListener('message', this._onWindowMessage); // Fixes a timing issue in IE11
  }

  onLoad() {
    this.setGameApi(this._gameApi);
  }

  onClose() {
    window.app.layout.toggleFooter(true);

    if ($os.mobile) {
      window.app.layout.toggleFullScreen(false);
    }

    window.app.layout.toggleFullPage(false);

    $(this.$el.parent()).css('overflow', '');
  }

  onDestroy() {
    window.removeEventListener('message', this._onWindowMessage);
  }

  _onWindowMessage(event) {
    if (this.isDestroyed) {
      return;
    }

    if (event.data === 'game-api-missing') {
      this.$el[0].contentWindow.apps.GameApi = this._gameApi;
    }
  }
}

module.exports = GamePlayFrame;
