const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const {
  LayoutView,
  ItemView
} = require('Marionette');
const PageableListViewController = require('@common/components/pageableList/PageableListViewController');
const {
  NoSearchResultsView
} = require('@training/apps/search/SearchResultEmptyViews');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const ImpressionTrackerEnum = require('@training/apps/common/enums/ImpressionTrackerEnum');
const PageStatus = require('@common/components/discover/enums/PageStatus');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const { articleSearchResultControllerDefinition } = require('@training/apps/search/ArticleSearchResultControllerDefinitionFactory');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const Backbone = require('Backbone');

const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const { parseBreadcrumbsFromSearchPageState } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');

/**
 * Represents a simple list of article results, meaning no tags component or create button.
 * See ArticleSearchResultsViewController.js for more
 */
class SimpleArticleSearchResultsViewController extends LayoutController {

  initialize(options = {}) {
    ({
      searchPageState: this.searchPageState,
      title: this.title
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
    this.regionControllers = this.regionControllers.bind(this);
    this._getViewEvents = this._getViewEvents.bind(this);

    this.isGrid = (this.searchPageState.get('searchString') === '');
    this.searchPageState.set('isGrid', this.isGrid);

    this.gridElementClasses = [
      'ax-grid__col--12',
      'ax-grid__col--m-6',
      'ax-grid__col--l-3'
    ];
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'article-list-view',
      template: `
        <div class="ax-grid ax-grid--m-align-items-end ax-grid--direction-column ax-grid--m-direction-row">

          <div class="breadcrumb-title-wrapper ax-grid ax-grid__col--grow ax-grid--no-gutter ax-grid--direction-column ax-grid--m-direction-row">

            <div class="breadcrumb-path ax-grid__col--auto-size">
              <div class="header-breadcrumbs" role="navigation"></div>
            </div>
            <div class="title-region ax-grid__col--auto-size"></div>

          </div>

          <div class="article-search__view-type-selector${ this.isGrid ? ' grid-view' : '' } ax-grid ax-grid__col--auto-size ax-grid__col--m-order-3"></div>

          <div class="js-filter-region ax-grid ax-grid__col--auto-size ax-grid__col--m-order-2 ax-grid--no-gutter"></div>

        </div>
        <div class="article-search-wrapper ax-grid__col--12 ax-grid__col--m-8 ax-grid__col--l-12 ax-grid__col--m-order-2">
          <div class="article-search__results-region${ this.isGrid ? ' grid-view' : '' }"></div>
        </div>
        `,
      regions: {
        breadcrumbRegion: '.header-breadcrumbs',
        titleRegion: '.title-region',
        filterRegion: '.js-filter-region',
        buttonsRegion: '.article-search__view-type-selector',
        resultsRegion: '.article-search__results-region'
      }
    };
  }

  regionControllers() {
    const searchPageState = this.searchPageState;
    const showAuthor = TenantPropertyProvider.get().getProperty('dzShowAuthorAndContributorInfo');
    const showReactions = searchPageState.get('subCategory') !== SearchSubCategoryEnum.PENDING;

    let userId;
    if (searchPageState.get('subCategory') === SearchSubCategoryEnum.PENDING) {
      userId = window.apps.auth.session.user.id;
    }

    const controllers = {
      breadcrumbRegion: {
        viewDefinition: {
          ViewClass: BreadcrumbCollectionView,
          collection: parseBreadcrumbsFromSearchPageState(this.searchPageState),
          searchPageState,
          doOnClick: (model) => {
            if (model && model.get('target')) {
              Backbone.history.navigate(model.get('target'), { trigger: true });
            }
          },
          hideLastCrumb: true
        }
      },
      titleRegion: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'community-title-wrapper',
          template: `
            <h2>
              <div class="community-title">${ this.title }</div>
            </h2>
          `
        }
      },
      buttonsRegion: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'ax-grid ax-grid--justify-content-end ax-grid--m-justify-content-center ax-grid--no-gutter',
          template: `
<button type="button" class="axon-button white grid-view-button ax-grid__col--auto-size">
  <span role="presentation" aria-hidden="true" class="icon-th_large"></span><span class="grid-view-text"><%- t('selfDirected.search.viewTypes.gridView') %></span>
</button>
<button type="button" class="axon-button white list-view-button ax-grid__col--auto-size">
  <span role="presentation" aria-hidden="true" class="icon-list"></span><span class="list-view-text"><%- t('selfDirected.search.viewTypes.listView') %></span>
</button>`,
          events: {
            'click .grid-view-button': () => {
              this._toggleGridview(true);
            },
            'click .list-view-button': () => {
              this._toggleGridview(false);
            }
          }
        },
        delegateEvents: {
          'view:attach': (controller, view) => {
            this._toggleGridview(this.isGrid);
            view.listenTo(this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES], 'sync', (collection) => {
              const buttonsRegionEl = this.getView().buttonsRegion.$el;
              if (collection.length) {
                $('button', buttonsRegionEl).toggleClass('hidden', false);
              } else {
                $('button', buttonsRegionEl).toggleClass('hidden', true);
              }
            });
          }
        }
      },
      resultsRegion: {
        ViewControllerClass: PageableListViewController,
        collection: this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES],
        childViewControllerDefinitionFn: articleSearchResultControllerDefinition,
        itemViewOptions: {
          searchPageState: this.searchPageState,
          userId,
          showAuthor,
          showReactions,
          source: ImpressionTrackerEnum.getEnumFromSearchCategory(this.searchPageState.get('subCategory'))
        },
        viewDefinition: {
          behaviors: {
            MutationObservable: {
              observeOptions: {
                childList: true,
                subtree: true
              }
            }
          }
        },
        emptyViewClass: NoSearchResultsView,
        emptyViewOptions: {
          searchString: this.searchPageState.get('searchString')
        },
        delegateEvents: this._getViewEvents(
          this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES],
          this.searchPageState
        )
      }
    };

    if (searchPageState.get('subCategory') === SearchSubCategoryEnum.PENDING) {
      controllers.filterRegion = {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'qa-pending-filter',
          template: `<%= axSelect({
            options: filterOptions,
            classNameSelect: 'qa-pending-page-filter js-pending-page-filter',
            noFormEditorDefault: 'true',
            fullWidth: true
          }) %>`,
          templateHelpers: () => {
            return {
              filterOptions: [
                {
                  value: 'ALL',
                  label: I18n.t('discover.browse.allNotices')
                },
                {
                  value: PageStatus.REVIEW,
                  label: I18n.t('discover.publishFlow.publishState.inReview')
                },
                {
                  value: PageStatus.QUARANTINE,
                  label: I18n.t('discover.browse.reportedItems')
                },
                {
                  value: PageStatus.SCHEDULED,
                  label: I18n.t('learningEvents.scheduled')
                },
                {
                  value: PageStatus.EXPIRED,
                  label: I18n.t('discover.publishFlow.publishState.expired')
                }
              ]
            }
          },
          events: () => {
            return {
              'change .js-pending-page-filter': (event) => {
                const val = event.target.value;
                if (val === 'ALL') {
                  return this._applyPendingFilter([PageStatus.QUARANTINE, PageStatus.REVIEW, PageStatus.SCHEDULED, PageStatus.EXPIRED]);
                }

                return this._applyPendingFilter(val);
              }
            }
          }
        }
      };
    }

    return controllers;
  }

  _applyPendingFilter(filter) {
    const collection = this.searchPageState.get('results')[SearchCategoryEnum.ARTICLES];
    collection.pendingFilter = filter;
    collection.fetch();
  }

  _toggleGridview(isGrid) {
    this.isGrid = isGrid;
    const view = this.getView();
    this.searchPageState.set('isGrid', isGrid);

    view.buttonsRegion.$el.toggleClass('grid-view', this.isGrid);
    view.resultsRegion.$el.toggleClass('grid-view', this.isGrid);
    view.resultsRegion.$el.find('ul.search__results').toggleClass('ax-grid', this.isGrid);
    view.resultsRegion.$el.find('li').toggleClass(this.gridElementClasses.join(' '), this.isGrid);
    view.buttonsRegion.$el.find('.list-view-button').attr('aria-pressed', !isGrid);
    view.buttonsRegion.$el.find('.grid-view-button').attr('aria-pressed', isGrid);
  }

  _toggleGridResults(view, isGrid = false) {
    view.$el.find('ul.search__results').toggleClass('ax-grid', isGrid);
    view.$el.find('li').not('.empty-search-results')
      .toggleClass(this.gridElementClasses.join(' '), isGrid);
  }

  _getViewEvents(results, searchPageState) {
    const domHandler = {
      'view:dom:mutate': () => {
        triggerResize(true);
      }
    };
    const viewAttachEvent = {
      'view:attach': (controller, view) => {
        this._toggleGridResults(view, this.isGrid);

        view.listenTo(results, 'sync', () => {
          const newHash = SearchUrlHelper.getHashForCurrentTabState(searchPageState, SearchCategoryEnum.ARTICLES, true);
          Backbone.history.navigate(
            newHash,
            { trigger: false }
          );

          if (!results.length) {
            $('.tags-region').addClass('hidden'); // Temporary fix because elasticsearch returns tags even when there is no articles
          }

          this._toggleGridResults(view, this.isGrid);
        });
        view.listenTo(Backbone.Wreqr.radio.channel('global').vent, 'pagination:clicked', () => {
          $('#page-view > .adjustable-container').scrollTop(0);
        });
      }
    };

    return Object.assign({}, domHandler, viewAttachEvent);
  }
}

module.exports = SimpleArticleSearchResultsViewController;
