const _ = require('underscore');
const {
  QuestionPreviewPage,
  QuestionOptionItemView,
  QuestionCollectionView
} = require('@common/components/questions/views/QuestionPreviewPage/QuestionPreviewPage');

class MultipleAnswerQuestionPreviewPage extends QuestionPreviewPage {

  className() {
    return 'multianswer';
  }

  regions(options) {
    return _.extend(super.regions(options), {
      answerOptions: '#answer-body'
    });
  }

  onShow() {
    super.onShow();
    const optionTextView = this._getOptionCollectionView();
    this.answerOptions.show(optionTextView);

    this.listenTo(optionTextView, 'image:clicked', (itemView, mediaId) => {
      this.trigger('show:zoom:modal', itemView.getImageMedia(mediaId));
    });
  }

  _getOptionCollectionView() {
    return new MultipleAnswerQuestionCollectionView({
      model: this.model
    });
  }
}

class MultipleAnswerQuestionOptionItemView extends QuestionOptionItemView {

  getTemplate() {
    return require('@common/components/questions/templates/multipleAnswer/answerOptionItem.html');
  }

  templateHelpers() {
    return _.extend(super.templateHelpers(), {
      selectedStatus: this.selectedAnswer ? 'checked' : ''
    });
  }
}

class MultipleAnswerQuestionCollectionView extends QuestionCollectionView {

  get childView() {
    return MultipleAnswerQuestionOptionItemView;
  }

  childViewOptions(model, index) {
    let selectedAnswer;
    let answerOptionsList = this.options.model.get('questionOptionList') || '';

    if (answerOptionsList.length > 0) {
      answerOptionsList = answerOptionsList.split(',');
    }

    for (const answerOption of answerOptionsList) {
      selectedAnswer = model.get('id') === parseInt(answerOption, 10);
      if (selectedAnswer === true) {
        break;
      }
    }

    return _.extend(super.childViewOptions(model, index), {
      selectedAnswer
    });
  }
}

module.exports = MultipleAnswerQuestionPreviewPage;
