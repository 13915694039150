const Backbone = require('Backbone');
const Marionette = require('Marionette');
const LoadingRowView = require('@common/mixins/data_loading/LoadingRowView');

const LoadingCollectionViewMixin = {
  defaultLoadingView: LoadingRowView,

  getCollectionLoadingView() {
    const loadingView = Marionette.getOption(this, 'loadingView');
    if (loadingView) {
      return loadingView;
    }
    return this.defaultLoadingView;
  },

  getLoadingViewOptions() {
    return Marionette.getOption(this, 'loadingViewOptions');
  },

  showCollectionLoadingView() {
    if (!this._showingLoadingView) {
      this.destroyEmptyView();
      this._showingLoadingView = true;
      const LoadingView = this.getCollectionLoadingView();
      const loadingViewOptions = this.getLoadingViewOptions();

      this._loadingView = this.buildChildView(new Backbone.Model(), LoadingView, loadingViewOptions);

      // increment indices of views after this one
      this._updateIndices(this._loadingView, true);

      this.triggerMethod('before:add:child', this._loadingView);
      this._addChildView(this._loadingView);
      this.triggerMethod('add:child', this._loadingView);

      this._loadingView._parent = this;
    }
  },

  closeCollectionLoadingView() {
    if (this._showingLoadingView) {
      this.removeChildView(this._loadingView);
      this.checkEmpty();
      delete this._loadingView;
      delete this._showingLoadingView;
    }
  },

  checkEmpty() {
    if (!this._showingLoadingView && this.isEmpty(this.collection)) {
      this.showEmptyView();
    }
  }
};

module.exports = LoadingCollectionViewMixin;
