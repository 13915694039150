const { some } = require('underscore');

const MediaModel = require('@common/libs/file/MediaModel');
const MediaStatus = require('@common/libs/file/MediaStatus');

class ImageMedia extends MediaModel {

  urlRoot() {
    return `${ super.urlRoot() }/image`;
  }

  defaults() {
    return {
      type: 'image',
      fixedSizes: [
        {
          width: 1200,
          height: 1200
        },
        {
          width: 600,
          height: 600
        },
        {
          width: 300,
          height: 300
        },
        {
          width: 120,
          height: 120
        },
        {
          width: 80,
          height: 80
        }
      ]
    };
  }

  initialize() {
    if (!this.has('maintainAspectRatio')) {
      this.set('maintainAspectRatio');
    }
  }

  // Return `true` if all sizes are `done` processing
  // Return `false` as soon as one is found that is not `done` processing
  isDoneProcessingAllSizes() {
    return !some(this.getSizes(), (imageSize) => {
      return imageSize.status !== MediaStatus.DONE;
    });
  }

  isProcessed() {
    return this.isDoneProcessingAllSizes();
  }

  getSizes() {
    return this.get('sizes') || [];
  }

  addFixedSize(width, height) {
    this.get('fixedSizes').push({
      width,
      height
    });
  }
}

module.exports = ImageMedia;
