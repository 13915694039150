const $os = require('detectOS');
const _ = require('underscore');
const Backbone = require('Backbone');
const { Model } = require('Backbone');
const I18n = require('@common/libs/I18n');
const { LayoutView } = require('Marionette');

const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const MediaHelpers = require('@common/libs/file/MediaHelpers');
const FroalaRichContentHelper = require('@common/libs/froala/FroalaRichContentHelper');
const FileFactoryMedia = require('@common/data/models/media/FileFactory');
const UserProfileIcon = require('@common/modules/main/header/userProfileIcon/UserProfileIcon');
const VideoCaptioningService = require('@common/services/media/VideoCaptioningService');
const TranscriptionMethodSupplierTenantStrategy = require('@common/services/media/TranscriptionMethodSupplierTenantStrategy');

const PostRteActionBar = require('@training/apps/timeline/PostRteActionBar');
const PostCreateFilterableCommunityController = require('@training/apps/timeline/PostCreateFilterableCommunityController');

const PostRteView = require('@training/apps/timeline/PostRteView');

const LockController = require('@training/apps/articles/LockController');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const PageType = require('@common/components/discover/enums/PageType');
const CommunityAction = require('@common/data/enums/CommunityAction');
const Community = require('@common/data/models/Community');

require('@common/libs/behaviors/scrollable/Scrollable');
class PostCreateModal extends LayoutView {
  behaviors() {
    return {
      Scrollable: {
        scrollableContainer: ($os.mobile) ? '.modal-body' : '.post-body'
      }
    };
  }

  getTemplate() {
    return `
    <div class="modalHeader">
      <button class="icon-remove js-close-modal post-modal-mobile-close" aria-label="<%- t('general.close') %>"></button>
      <h1><%- modalTitle %></h1>
      <button class="axon-button post-button-mobile js-post-button"><%- t('comms.posts.postButton') %></button>
      <button class="icon-remove js-close-modal post-modal-desktop-close" aria-label="<%- t('general.close') %>"></button>
    </div>
    <div class="modal-body">
      <div class="inappropriate-content-message qa-inappropriate-content-message-post hidden ax-grid ax-grid--no-gutter">
        <span class="icon-exclamation_sign ax-grid__col--auto-size"></span>
        <p><%- t('comms.posts.inappropriateContentMsg') %></p>
      </div>
      <div class="picker-region ax-grid ax-grid--no-gutter">
        <div class="profile-icon-region ax-grid__col--auto-size" aria-hidden="true"></div>
        <div class="post-create-modal__picker-region__community-picker-region" >
          <label id="share-to-header"><%- t('comms.posts.shareToHeader') %></label>
          <div class="community-picker"></div>
        </div>
      </div>
      <div class="post-body"></div>
      <div class="button-container ax-grid ax-grid--no-gutter">
        <div class="rte-button-container ax-grid__col"></div>
        <div class="post-button-container ax-grid__col--auto-size">
          <button class="axon-button post-button-desktop js-post-button"><%- t('comms.posts.postButton') %></button>
        </div>
      </div>
    </div>
    `;
  }

  className() {
    return 'create-post-modal-content';
  }

  templateHelpers() {
    return {
      modalTitle: I18n.t(this.isEditing ? 'comms.posts.editModalTitle' : 'comms.posts.createModalTitle')
    };
  }

  events() {
    return {
      'click .js-close-modal': () => {
        window.app.layout.dismissModal();
      },
      'click .js-post-button': '_onClickPost'
    };
  }

  regions() {
    return {
      communityPicker: '.community-picker',
      postBody: '.post-body',
      profileIconRegion: '.profile-icon-region',
      rteButtons: '.rte-button-container'
    };
  }

  ui() {
    return {
      inappropriateContentMessage: '.inappropriate-content-message'
    };
  }

  initialize(options = {}) {
    super.initialize(options);
    ({
      isEditing: this.isEditing,
      community: this.community, // optional argument
      isBrowsingCommunity: this.isBrowsingCommunity // optional argument
    } = options);

    this._setCommunity = this._setCommunity.bind(this);
    this._setupHandlers = this._setupHandlers.bind(this);
    this._toggleAccessibilityFocus = this._toggleAccessibilityFocus.bind(this);
    this._saveModel.bind(this);
    this._captionRequestsSent = {};
  }

  onShow() {
    this.typedPage = this.options.typedPage || new Model();
    this.originalModel = this.typedPage;

    this.typedPage = this.typedPage.clone({deepClone: true});
    this.typedPage.currentVersion.unset('id');
    this.typedPage.currentVersion.richContent.unset('id');

    const typedPageCommunities = this.typedPage.get('communities');
    let typedPageCommunity = typedPageCommunities && (typedPageCommunities[0] && typedPageCommunities[0].community);
    // typedPageCommunity will be a raw object representing a Community, but we need a backbone Model
    if (typedPageCommunity) {
      typedPageCommunity = new Community(typedPageCommunity);
    }
    const transcriptionMethodSupplier = TranscriptionMethodSupplierTenantStrategy.fromTenant();

    this.captioningService = new VideoCaptioningService(
      transcriptionMethodSupplier,
      MediaHelpers
    );
    this.fileFactoryMedia = new FileFactoryMedia();

    if (this.typedPage.currentVersion != null ) {
      this.richContent = this.typedPage.currentVersion.richContent || {};
    } else {
      this.richContent = {};
    }

    if (typedPageCommunity == null && this.community) {
      this._setCommunity(this.community);
    }

    this.listenTo(this.typedPage, 'change', () => {
      this.$('.add-media-text').text(
        this.richContent.get('media').length ? I18n.t('comms.posts.replaceMediaButton') : I18n.t('comms.posts.addMediaButton')
      );
    });

    const rteView = new PostRteView({
      model: this.richContent,
      userLanguage: this.typedPage.get('language'),
      setupHandlers: this._setupHandlers,
      toggleAccessibilityFocus: this._toggleAccessibilityFocus,
      fileFactory: (fileType, fileOptions) => {
        return this._createMediaFromFile(fileType, fileOptions);
      },
      imageSelector: '.local-media',
      videoSelector: '.local-media'
    });

    const actionBar = new PostRteActionBar(_.extend({ buttonText: this.richContent.get('media').length ? I18n.t('comms.posts.replaceMediaButton') : I18n.t('comms.posts.addMediaButton') }, this.actionBarHandlers ));

    this.rteButtons.show(actionBar);

    this.lockController = new LockController({model: this.originalModel});
    this.listenTo(this.lockController, 'lock:error', this.onLockError);

    if (!this.typedPage.isNew()) {
      this.lockController.lockPage().done(() => {
        return !this.isDestroyed;
      });
    }

    const userProfileIcon = new UserProfileIcon({ model: window.apps.auth.session.user });
    this.profileIconRegion.show(userProfileIcon);

    const filterableCommunity = this._getFilterableCommunity(typedPageCommunity);
    filterableCommunity.show(this.communityPicker);
    this.postBody.show(rteView);
  }

  _setupHandlers(options = {}) {
    this.actionBarHandlers = options;
  }

  _getFilterableCommunity(typedPageCommunity) {
    const communityAction = this.isEditing ? CommunityAction.PUBLISH_ACCESS : CommunityAction.AUTHOR_PAGETYPE;

    const viewControllerFactory = new ViewControllerFactory();
    const options = {
      setCommunityHandler: this._setCommunity,
      community: this.community || typedPageCommunity,
      contextModel: this.typedPage,
      pageType: PageType.POST,
      communityAction
    };

    return viewControllerFactory.create(PostCreateFilterableCommunityController, options);
  }

  _setCommunity(community) {
    this.typedPage.set('community', community);
  }

  _createMediaFromFile(fileType, fileData, fileOptions = {}) {
    const $input = fileData.file;
    const options = _.extend({}, fileOptions, { fileType });
    return this.fileFactoryMedia.createFileFromInput($input, options, fileData);
  }

  _communitySelected() {
    // The model looks slightly different based on what API we are calling, so this logic compensates for both formats,
    // OR it returns false if niether of these objects are present as a fallback.
    const community = this.typedPage.get('community');
    const communityObject = this.typedPage.get('communities') && this.typedPage.get('communities')[0];

    if (community) {
      return community && community.id;
    } else if (communityObject) {
      return communityObject.community && communityObject.community.id;
    }
    return false;
  }

  _toggleAccessibilityFocus(e) {
    this.$('#insert-media').toggleClass('focus-ring', $(e.target).hasClass('focus-visible'));
  }

  _onClickPost() {
    const errors = [];

    const currentRequest = this._currentRequest != null ? this._currentRequest.state() : undefined;
    if (currentRequest === 'pending') {
      return false;
    }
    const view = this.postBody.currentView;
    if (!view.editor) {
      return false;
    }

    this._toggleInappropriateContentMessage(false);

    let { content } = FroalaRichContentHelper.cleanContent(this.postBody.currentView.editor.html.get());

    // Fixes issue on mobile devices, where the content is not wrapped in a <div> tag when you start typing after deleting all the content
    if (content.indexOf('<div>') !== 0) {
      content = '<div>' + content + '</div>';
    }

    if (!this._communitySelected()) {
      errors.push(I18n.t('errors.Article.required.community'));
    }
    if (!FroalaRichContentHelper.hasContent(content, view.model.get('media'))) {
      errors.push(I18n.t('errors.noContent'));
    }

    if (errors.length) {
      window.app.layout.flash.error(errors);

      return false;
    }
    // To be explicit, this changes and triggers the base model as `richContent` is a part of `typedPaged`
    this.richContent.set('content', content);

    this._currentRequest = this._processMediaElementsForVideo();

    return this._currentRequest.done( () => {
      this._currentRequest = this._saveModel();
      return this._currentRequest;
    }).fail( (xhr) => {
      xhr.skipGlobalHandler = true;
      this._currentRequest = this._saveModel();
    });
  }

  _processMediaElementsForVideo() {
    const richContent = this.typedPage.currentVersion.get('richContent');
    const mediaList = richContent != null ? richContent.media : [];

    const deferreds = [];
    for (const mediaItem of mediaList) {
      if (mediaItem.type === 'video') {
        const videoMedia = this.fileFactoryMedia.createMediaFromJSON(mediaItem);
        const deferred = this._processVideo(videoMedia);
        if (deferred) {
          deferreds.push(deferred);
        }
      }
    }

    // We need every media to resolve before we can do anything about this
    return $.when.apply(...deferreds);
  }

  _processVideo(mediaVideo) {
    const videoId = mediaVideo.id;
    const articleLanguage = this.typedPage.get('language');
    const languagesMatch = articleLanguage === mediaVideo.get('language');

    if (languagesMatch && !this._captionRequestsSent[videoId]) {
      this.captioningService.generateMissingCaptions(mediaVideo, articleLanguage);
      this._captionRequestsSent[videoId] = true;
    }
  }

  _saveModel() {
    return this.typedPage.save().done( () => {
      if (!this.isEditing) {
        if (this.isBrowsingCommunity) {
          Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ this.community.get('id') }`, {
            trigger: true,
            replace: true
          });
        } else {
          Backbone.history.navigate('#hub/timeline', {
            trigger: true,
            replace: true
          });
        }
      }
      this.trigger('post:saved');
      window.app.layout.dismissModal();
      window.app.layout.flash.success(I18n.t(this.isEditing ? 'comms.posts.editSuccess' : 'comms.posts.createSuccess'));
    })
      .fail( (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const errCode = exception.getErrorCode();

        if (errCode === AxonifyExceptionCode.CONTRACT_ERROR_INVALID_PARAMETER_MISSING) {
          // Missing required parameter
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t(`discover.pageTypes.${ this.typedPage.get('type') }.error.${ errCode }`));
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
          // this would only happen if the user opens the Edit modal, then the post is deleted, then they try to save
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t(`discover.pageTypes.${ this.typedPage.get('type') }.error.${ errCode }`));
          this._navigateBack();
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
          // Users access rights have changed
          // This only happens on post create. Editing is continued to be allowed for posts you previously authored
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('discover.pageAccess.error.3017'));
          this.typedPage.unset('community');
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_LANGUAGE_NOT_SUPPORTED) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.error.3134'));
          this._navigateBack();
        }

        // Posts have been disabled
        if (errCode === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t(`discover.pageTypes.${ this.typedPage.get('type') }.error.${ errCode }`));
          this.typedPage.unset('community');
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_PROFANE_LANGUAGE) {
          xhr.skipGlobalHandler = true;
          this._toggleInappropriateContentMessage(true);
        }
      });
  }

  _navigateBack() {
    if (this.isBrowsingCommunity) {
      Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ this.community.get('id') }`, true);
    } else {
      Backbone.history.navigate('#hub/timeline', true);
    }
  }

  _toggleInappropriateContentMessage(value) {
    this.ui.inappropriateContentMessage.toggleClass('hidden', !value);
  }

  onDestroy() {
    // remove the lock
    if (this.lockController) {
      this.lockController.destroy();
    }
  }
}

module.exports = PostCreateModal;
