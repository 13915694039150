import {
  AxBox,
  AxCheckbox,
  AxFormControlLabel,
  AxFormHelperText,
  AxStack,
  AxTypography,
  type AxFormControlLabelProps
} from '@common/modules/react/themes/components';
import FieldRequiredAsterisk from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import { type SyntheticEvent } from 'react';

export type CheckboxWithTwoLineLabelProps = {
  primary: string,
  secondary: string,
  onChange: (checked:boolean) => void,
  isChecked: boolean,
  required?: boolean,
  className?: string,
  disabled?: boolean,
} & Pick<AxFormControlLabelProps, 'sx'>;

export default function CheckboxWithTwoLineLabel(props: CheckboxWithTwoLineLabelProps): JSX.Element {
  const {
    primary,
    secondary,
    onChange,
    isChecked,
    required,
    className,
    disabled,
    sx
  } = props;

  return <AxFormControlLabel
    labelPlacement='end'
    sx={ sx }
    checked={ isChecked }
    onChange={ (event: SyntheticEvent<Element, Event>, checked: boolean) => {
      onChange(checked);
    } }
    label={ <AxBox>
      <AxStack direction='row'>
        <AxTypography>{ primary }</AxTypography>
        { required && <FieldRequiredAsterisk /> }
      </AxStack>
      
      <AxFormHelperText>{ secondary }</AxFormHelperText>
    </AxBox>
    }
    control={
      <AxCheckbox
        className={ className }
        sx={{
          alignSelf: 'self-start',
          marginTop: '0.2rem'
        }}
        disabled = { disabled }
      ></AxCheckbox>
    }
  />;
}
