// Module
var code = `<% if (pageHeaderBackgroundColour) { %>
  [dir] .page-header,
  [dir] .page-title-bar-layout,
  [dir] .row-facets,
  [dir] .article-details .breadcrumb-path,
  [dir] .page-header__breadcrumbs .breadcrumb-path {
    background-color: <%- pageHeaderBackgroundColour %>;
  }

  :root {
    --page-header-branded-background: <%- pageHeaderBackgroundColour %>;
  }
<% } %>

<% if (bannerTextColour) { %>
  h1.page-header__title,
  .page-header__title,
  .page-header__kicker,
  h1 .page-header__title a,
  .page-header .page-header__icon,
  .page-header__how-to .icon,
  .search-by-tag-title,
  .page-header__title--sub-title,
  #page-header-extra-details.page-header__title--sub-title,
  .progress-label,
  .greeting-view,
  .stats .stat-item__value,
  .stats .stat-item,
  .titled-section__region--overlay,
  .no-training-message-integration,
  .stats__empty,
  .article-details .breadcrumb-path .header-breadcrumbs .main-crumbs button,
  .article-details .breadcrumb-path .header-breadcrumbs .main-crumbs .separator,
  .page-header__breadcrumbs .breadcrumb-path .header-breadcrumbs .main-crumbs button,
  .page-header__breadcrumbs .breadcrumb-path .header-breadcrumbs .main-crumbs .separator {
    color: <%- bannerTextColour %>;
  }

  <% // Unfortunately !important is needed here, unless we refactor a bunch %>
  .page-header .page-header__icon.icon-non_participants,
  .page-header .page-header__icon.icon-overconfident,
  .page-header .page-header__icon.icon-underconfident {
    color: <%- bannerTextColour %> !important;
  }

  :root {
    --page-header-branded-text-color: <%- bannerTextColour %>;
  }
<% } %>

<% if (bannerBadgeBackgroundColour) { %>
  [dir] .page-header .page-header__icon:not(.page-header__icon--custom-icon):not(.game-type-icon),
  [dir] .assessment-progress-bar .progress-section .progress-bar,
  [dir] .stats__tile {
    background-color: <%- bannerBadgeBackgroundColour %>;
  }

  :root {
    --page-header-badge-background: <%- bannerBadgeBackgroundColour %>;
  }
<% } %>

<% if (bannerOverlayColour) { %>
  [dir] .branded-banner__overlay {
    background-color: <%- bannerOverlayColour %>;
  }
<% } %>

<% if (bannerImageLowResPath) { %>
  [dir] .branded-banner__low-res {
    background-image: url('<%= bannerImageLowResPath %>');
  }
<% } %>

<% if (bannerImageFullResPath) { %>
  [dir] .branded-banner__high-res {
    background-image: url('<%= bannerImageFullResPath %>');
  }
<% } %>

<% if (bannerImageLowResPath || bannerImageFullResPath) { %>
  [dir] .stats__list {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }

  @media only screen and (max-width: 679px) {
    [dir] .training_stats__list.stats__list {
      -webkit-backdrop-filter: blur(12px);
      backdrop-filter: blur(12px);
    }

    [dir] .integration_stats__list .stats__tile {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }
  }
<% } %>

<% if (linkColour) { %>
  a .icon-create-dz {
    color: <%- linkColour %>;
  }
<% } %>
`;
// Exports
module.exports = code;