const Marionette = require('Marionette');

class ArticleDetailsNoCommentView extends Marionette.LayoutView {
  getTemplate() {
    return require('@training/apps/articles/ArticleDetailsNoCommentTemplate.html');
  }

  className() {
    return 'comments-nope';
  }

}

module.exports = ArticleDetailsNoCommentView;
