const logging = require('logging');

const getLoggingData = (cachableObject, { force } = {}) => {
  let deltaString = '';

  const now = Date.now();
  const bestBefore = cachableObject.getExpiryDate();

  // construct some data for logging
  if (bestBefore != null) {
    let delta = bestBefore - now;
    delta = Math.abs(delta);
    const min = Math.floor(delta / (60 * 1000));
    delta = delta % (60 * 1000);
    const sec = Math.floor(delta / 1000);
    delta = delta % 1000;
    const timeString = `${ min != null ? min : 0 }m${ sec != null ? sec : 0 }s${ delta }ms`;
    deltaString = delta < 0 ? `expired ${ timeString } ago` : `expires in ${ timeString }`;
  }

  return `force = ${ force }, now = ${ now }, best before = ${ bestBefore }, ${ deltaString }`;
};

const defaultShouldRefresh = ({ force, isExpired } = {}) => {
  return force || isExpired;
};

const CachableObjectMixin = {
  _cacheTimeInMs: 5 * 60 * 1000, // by default data expires in 5 mins
  _defaultShouldRefresh: defaultShouldRefresh,

  initialize() {
    this.expire();

    this._shouldRefresh = this.shouldRefresh || defaultShouldRefresh;
    this._doRefresh = this.doRefresh || (() => {});
  },

  expire() {
    this.bestBefore = Date.now() - 1;
  },

  setExpiryDate(now = Date.now()) {
    this.bestBefore = now + this._cacheTimeInMs;
  },

  getExpiryDate() {
    return this.bestBefore;
  },

  isExpired() {
    return Date.now() > this.getExpiryDate();
  },

  refresh(options = {}) {
    const refreshOptions = Object.assign({
      force: false,
      isExpired: this.isExpired()
    }, options);

    const loggingData = getLoggingData(this, refreshOptions);
    const classType = this._classType != null ? this._classType : 'CachableObjectMixin';

    // if the fetch was forced or if the last fetch was a while ago
    if (this._shouldRefresh(refreshOptions)) {
      try {
        logging.info(`${ classType } - refreshing (${ loggingData })`);
        this._doRefresh(refreshOptions);
        this.setExpiryDate();
      } catch (e) {
        logging.error(`Error refreshing ${ classType }: ${ e.message }`);
      }
    } else {
      logging.info(`${ classType } - not refreshing, using old data (${ loggingData })`);
    }
  }
};

module.exports = CachableObjectMixin;
