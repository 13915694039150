const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');
const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const PageType = require('@common/components/discover/enums/PageType');
const Question = require('@common/components/discover/models/Question');
const Community = require('@common/data/models/Community');

const QuestionCreateModal = require('@training/apps/articles/modals/QuestionCreateModal');
const AskAQuestionAccessibleModalView = require('@training/apps/discover/AskAQuestionAccessibleModalView');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');

const getCreateMenuRegionController = (communityId = null, communityData = null) => {

  const authorablePageTypes = communityData.get('authorablePageTypes') || {};
  const optionsListConfig = [];
  const communityIdQueryParam = communityId ? `?communityId=${ communityId }` : '';

  if (authorablePageTypes.ARTICLE) {
    optionsListConfig.push({
      buttonText: 'discover.browse.create.article',
      buttonClass: 'add-article',
      buttonIcon: 'icon-create-article-nobg',
      buttonAriaLabel: 'discover.browse.create.article',
      clickCallback: () => {
        Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/create/new-${ PageType.ARTICLE }${ communityIdQueryParam }`, {
          trigger: true
        });
      }
    });
  }

  if (authorablePageTypes.REFERENCE) {
    optionsListConfig.push({
      buttonText: 'discover.browse.create.reference',
      buttonClass: 'add-link',
      buttonIcon: 'icon-external_link',
      buttonAriaLabel: 'discover.browse.create.reference',
      clickCallback: () => {
        Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/create/new-${ PageType.REFERENCE }${ communityIdQueryParam }`, {
          trigger: true
        });
      }
    });
  }

  if (authorablePageTypes.QUESTION) {
    // On the main articles tab, communityData will be an instance of CommunitiesMetadata to provide permission data
    // Once drilled into a community, communityData will be an instance of Community (the selected community)
    let selectedCommunity;
    if (communityData instanceof Community) {
      selectedCommunity = communityData;
    }

    optionsListConfig.push({
      buttonText: 'discover.browse.create.question',
      buttonClass: 'add-question',
      buttonIcon: 'icon-create-question-nobg',
      buttonAriaLabel: 'discover.browse.create.question',
      clickCallback: () => {
        _showQuestionCreateModal(selectedCommunity);
      }
    });
  }

  let regionController = {};
  if (optionsListConfig.length) {
    regionController = {
      viewDefinition: {
        ViewClass: MenuDropdown,
        buttonConfig: {
          buttonIcon: 'icon-create-dz',
          buttonAriaLabel: 'discover.browse.addEntryMenu',
          buttonClass: 'create-page-button ax-button--branded',
          buttonText: 'discover.browse.add',
          popupAlignment: MenuDropdownPositionEnum.RIGHT + MenuDropdownPositionEnum.BOTTOM
        },
        optionsListConfig
      }
    };
  }

  return regionController;
};

const _showQuestionCreateModal = (selectedCommunity) => {
  const model = new Question({
    currentVersion: {
      richContent: {
        media: []
      }
    },
    type: PageType.QUESTION
  });

  if (selectedCommunity != null) {
    model.set('community', selectedCommunity);
  }

  const modalView = new AskAQuestionAccessibleModalView({
    id: 'modalview',
    className: 'modal question-create-modal'
  });
  const modalChildView = new QuestionCreateModal({
    model,
    className: 'question-create page-wrapper parent-height'
  });

  window.app.layout.presentModal(modalView, { closeClick: false });
  modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

  modalView.listenToOnce(modalChildView, 'destroy', () => {
    window.app.layout.dismissModal();
  });
};

module.exports = {
  getCreateMenuRegionController
};
