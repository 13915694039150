const { extend } = require('underscore');
const { View } = require('Marionette');

const NamedParameterClassFactory = require('@common/libs/NamedParameterClassFactory');

class MarionetteViewFactory extends NamedParameterClassFactory {

  constructor(options = {}) {
    const defaultViewDefinition = {
      ViewClass: View
    };

    const updatedOptions = {
      definitionClassProp: options.definitionClassProp || 'ViewClass',
      defaultDefinition: extend({}, defaultViewDefinition, options.defaultDefinition)
    };

    super(updatedOptions);
  }
}

module.exports = MarionetteViewFactory;
