const _ = require('underscore');

module.exports = {
  /**
   * This method is designed to help combine TimeLogs of the supplied type into single entries. What this means is
   * that given a number of similar TimeLog entries, we sum up the total number of seconds spent per timelog per
   * community, then ensure only a single TimeLog is written out.
   *
   * Some important considerations:
   * * each entry has a recordDate attribute. In combining the multiple entries, we will keep only the first recordDate
   * * the seconds attribute will contain the sum of all recorded matching entries.
   *
   * Example:
   *
   * The following set of timelog entries will be combined from this:
   * [ ...,
   *  { "type": "PageView_Discovery_Community", "referenceId": 116, "seconds": 8, "appStartDate": 1631908956621, "appIdentifier": "training", "recordDate": 1631909004049 }
   *  { "type": "PageView_Discovery_Community", "referenceId": 116, "seconds": 8, "appStartDate": 1631908956621, "appIdentifier": "training", "recordDate": 1631909005049 }
   * ...]
   *
   * to this:
   *
   * [ ...,
   *  { "type": "PageView_Discovery_Community", "referenceId": 116, "seconds": 16, "appStartDate": 1631908956621, "appIdentifier": "training", "recordDate": 1631909004049 }
   * ...]
   *
   * (note the seconds attribute and the recordDate attribute changes)
   *
   * TODO: If you have a need to add more cleanup methods to the timelogs, please talk to Phil about using a more robust
   * TODO: pattern. This is a minimum viable solution and should be treated as a one-off.
   *
   * @param {Array[Object]} timeLogs timelog data captured by this class
   * @param {Array[String]} timeLogTypes the type of timelogs to deduplicate
   *
   * @returns {Object} cleaned up timelog data.
   */
  combineDuplicateTimelogs(timeLogs, timeLogTypes) {
    return _.reduce(timeLogs, (deduplicatedTimeLogs, timeLog) => {
      // first time through, deduplicatedTimeLogs is empty, so lastTimeLog will be undefined. This is why we need the _.get() call later.
      const lastTimeLog = _.last(deduplicatedTimeLogs);

      // If `timelog` is a duplicate of `lastTimeLog` (and is not undefined), combine the time spent for this type.
      // Note that `lastTimelog` is a reference to the object that is in the `deduplicatedTimeLogs` so what we're doing is updating it.
      if (
        timeLog.type === _.get(lastTimeLog, 'type') // do the types match between the current and last timeLog?
        && timeLog.referenceId === _.get(lastTimeLog, 'referenceId') // do the referenceIds match too?
        && timeLogTypes.indexOf(timeLog.type) >= 0 // is this a timelog type we want to deduplicate?
      ) {
        lastTimeLog.seconds += timeLog.seconds;
      } else {
        // It's not a duplicate, so add the `timelog` to the `deduplicatedTimeLogs` array.
        deduplicatedTimeLogs.push(timeLog);
      }

      return deduplicatedTimeLogs;
    }, []);
  }
};
