const _ = require('underscore');
const MilestoneProgress = require('@common/data/models/MilestoneProgress');
const MilestoneInterface = require('@common/data/models/MilestoneInterface');

/*
 * Factory for producing MilestoneInterfaces
 * Created By: ProgramOverviewView
 */
class MilestoneInterfaceFactory {
  constructor(options = {}) {
    ({ enrollmentStatus: this.enrollmentStatus} = options);

    // If enrollmentStatus isn't supplied it's probably a GL program, so set enrollmentStatus
    // to true since no one can opt out of it.
    this.enrollmentStatus = _.isBoolean(this.enrollmentStatus) ? this.enrollmentStatus : true;
  }

  _createMilestoneProgress(milestone) {
    const newMilestone = new MilestoneProgress(milestone);
    return newMilestone;
  }

  createMilestoneInterface(prerequisites, milestoneObjective = {}) {
    if (this.enrollmentStatus && milestoneObjective.objectiveDetails && milestoneObjective.objectiveDetails.milestoneId) {
      return new MilestoneInterface(prerequisites, milestoneObjective, this._createMilestoneProgress(milestoneObjective.objectiveDetails));
    }
    return new MilestoneInterface(prerequisites, milestoneObjective, new MilestoneProgress());
  }
}

module.exports = MilestoneInterfaceFactory;
