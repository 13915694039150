const { isArray, isObject } = require('underscore');
const Marionette = require('Marionette');

const _behaviorsCache = {};

Marionette.Behaviors.behaviorsLookup = () => {
  return _behaviorsCache;
};

Marionette.Behaviors.convertConfigObjectToArray = (configObject = {}) => {
  if (isObject(configObject)) {

    return Object.entries(configObject)
      .map(([behaviorClassName, behaviorOptions]) => {
        const behaviorClass = _behaviorsCache[behaviorClassName];
        return Object.assign({ behaviorClass }, behaviorOptions);
      });

  } else if (isArray(configObject)) {
    return configObject;
  }

  throw new Error(`Invalid configObject type passed in: '${ typeof configObject }'`);
};

module.exports = _behaviorsCache;
