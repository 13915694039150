const Marionette = require('Marionette');

class AttemptDetailsCardView extends Marionette.LayoutView {

  className() {
    return 'evaluation-attempt-details card card--informational';
  }

  getTemplate() {
    return `
      <div class="evaluation-attempt-details__header--wrapper"></div>
      <div class="evaluation-attempt-details__score--wrapper"></div>
      <div class="evaluation-attempt-details__evaluator--wrapper"></div>
      <div class="evaluation-attempt-details__info--wrapper"></div>
    `;
  }
  
  regions() {
    return {
      header: '.evaluation-attempt-details__header--wrapper',
      evaluationScore: '.evaluation-attempt-details__score--wrapper',
      evaluator: '.evaluation-attempt-details__evaluator--wrapper',
      info: '.evaluation-attempt-details__info--wrapper'
    };
  }
}

module.exports = AttemptDetailsCardView;
