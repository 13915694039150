// Module
var code = `<div class="message-row">
  <div class="icon-bullhorn pull-left"></div>
  <div class="message">
    <div class="ax-button ax-button--m ax-button--ghost ax-button--ghost-parent-hover ax-button--icon row-arrow-link" role="link" tabindex="0" aria-label="<%= linkAriaLabel %>">
      <div class="icon-angle_right row-arrow silver"></div>
    </div>
    <div class="name semiboldfont">
      <%- t('news.messageDetails.title') %>
      <span class="time-since regularfont"><%= timeSince %></span>
    </div>
    <h2 class="message-title text-ellipsis"><%- title %></h2>
    <div class="content"><%= message %></div>
  </div>
</div>
`;
// Exports
module.exports = code;