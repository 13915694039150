const logging = require('logging');
const _ = require('underscore'); // import the whole function for TPL, so there is no guarnetee the mixin will have run
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const UIKit = require('@training/widgets/UIKit');
const KeyCode = require('@common/data/enums/KeyCode');

class CompleteProfilePage extends UIKit.View {
  getTemplate() {
    return _.tpl(require('../templates/CompleteProfilePage.html'));
  }

  events() {
    return {
      'click .language': 'selectLanguage',
      'keypress .language': 'onKeyPress'
    };
  }

  attributes() {
    return {
      role: 'radiogroup',
      'aria-labelledby': 'chooselang'
    };
  }

  constructor(...args) {
    super(...args);

    this.onNext = this.onNext.bind(this);
    this.hasSaved = false;
  }

  render() {
    this.$el.html(this.getTemplate()({
      I18n,
      languages: TenantPropertyProvider.get().getProperty('languages')
    }));

    return this;
  }

  viewDidAppear() {
    logging.info('CompleteProfilePage - viewDidAppear');
  }

  selectLanguage(e) {
    const targetLanguage = $(e.target).closest('.language');
    if (targetLanguage != null) {
      targetLanguage.addClass('selected').children('.radioinputcontainer')
        .attr('aria-checked', 'true');
      targetLanguage.siblings().removeClass('selected')
        .children('.radioinputcontainer')
        .attr('aria-checked', 'false');
    }

    this.actionBar.setActionButton({
      type: 'continue',
      onClick: this.onNext
    });

    this.triggerAdjustment();
  }

  onNext() {
    if (this.hasSaved) {
      return;
    }
    this.hasSaved = true;

    // Save the language setting to the server
    const oldLang = window.apps.auth.session.user.get('language');
    const langCode = this.$('.language.selected').attr('data-lang-code');

    $.ajax({
      type: 'PUT',
      url: '/axonify/selfserve/lang',
      data: JSON.stringify({language: langCode}),
      skipGlobalHandler: true,
      success: () => {
        logging.info('Successfully saved language preference');
        // If there was no change in the language selected and there's a `complete` callback then continue.
        // If the user selected a different language then we need to force a reload because even though we can load
        // new languages using I18n.setLocale there's no way for us to redraw the text that's currently on the screen.
        if ((oldLang === langCode) && _.isFunction(this.options.complete)) {
          this.options.complete();
        } else {
          window.location.reload();
        }
      },
      error() {
        logging.error('Failed to save language preference');
        window.app.layout.flash.error(I18n.t('completeprofile.failedtosave'));
      }
    });
  }

  onKeyPress(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      e.preventDefault();
      if (!$(e.target).closest('.language')
        .hasClass('selected')) {
        this.selectLanguage(e);
      }
    }
  }

  onClose() {
    this.actionBar.setActionButton(null);
    super.onClose();
  }
}

module.exports = CompleteProfilePage;
