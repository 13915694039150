const Marionette = require('Marionette');
const _ = require('underscore');
const Backbone = require('Backbone');
const MediaHelpers = require('@common/libs/file/MediaHelpers');
const FilePreview = require('@common/components/mediaPreview/file/FilePreview');
const ImagePreview = require('@common/components/mediaPreview/image/ImagePreview');
const VideoPreview = require('@common/components/mediaPreview/video/VideoPreview');
const PreviewPlaceholder = require('@common/components/mediaPreview/placeholder/PreviewPlaceholder');
const LoadingWrapperView = require('@common/components/loading/wrapper/LoadingWrapperView');
const MediaTypesEnum = require('@common/components/mediaPreview/MediaTypesEnum').default;
const FileNameView = require('@common/components/mediaPreview/fileName/FileNameView');

class MediaPreview extends Marionette.LayoutView {
  getTemplate() {
    return '<div class="media-region fit-parent"></div>';
  }

  className() {
    return 'media-preview fit-parent';
  }

  regions() {
    return {mediaRegion: '.media-region'};
  }

  modelEvents() {
    return {
      'change:sizes': 'onChangeMediaSizes',
      change: 'onMediaChange'
    };
  }

  loadingPredicate() {
    // This function is executed when a loadingPredicate callback isn't provided in the options.
    // returning false means that the "load" trigger should hide the spinner, and does so without looking at
    // the model to understand if the media is loaded
    return false;
  }

  initialize() {
    const maxWidth = this.getOption('maxWidth');
    const maxHeight = this.getOption('maxHeight');
    this._zoomHandler = this.getOption('zoomImageHandler');
    this._loadingPredicate = this.getOption('loadingPredicate');
    this.hideSpinner = this.getOption('hideSpinner') ?? false;
    this.skipGlobalHandler = this.getOption('skipGlobalHandler');
    this.filenameViewTypes = this.getOption('filenameViewTypes') ?? [];
    this.filenameViewShowDownloadLink = this.getOption('filenameViewShowDownloadLink') ?? false;
    this.filenameShouldBeViewable = this.getOption('filenameShouldBeViewable') ?? false;
    this.filenameViewCb = this.getOption('filenameViewCb') ?? (() => {});
    this.videoPlayerOptions = this.getOption('videoPlayerOptions') ?? {};

    this._loadingView = this._setupLoadingView();
    this._previewState = this._setupPreviewState();
    this._maxDimensionState = this._setupMaxDimensionState(maxWidth, maxHeight);

    this.getPreviewViewByMediatype = this.getPreviewViewByMediatype.bind(this);
  }

  onChangeMediaSizes() {
    this._updatePreviewState();
  }

  onMediaChange() {
    this._updateMediaLoadingShowState();
  }

  onBeforeShow() {
    this._showLoadingView();
    this._updatePreviewState();
    if (this.hideSpinner) {
      this._toggleLoadingWrapper(false);
    }
  }

  onLoad() {
    this._updateMediaLoadingShowState();
  }

  onError() {
    this._showPlaceholderView();
    this._toggleLoadingWrapper(false);
  }

  onPreviewStateChange(stateModel) {
    if (!(stateModel.hasChanged('fileId') || stateModel.hasChanged('previewable'))) {
      return;
    }
    const file = stateModel.get('file');
    if ((file == null)) {
      this._clearMediaPreviewView();
    } else {
      this._showMediaFile();
    }
  }

  onMaxDimensionsChange() {
    this._updatePreviewState();
  }

  setMaxDimensions(maxWidth, maxHeight) {
    this._maxDimensionState.set({
      maxWidth,
      maxHeight
    });
  }

  _setupPreviewState() {
    const state = new Backbone.Model();
    this.listenTo(state, 'change', this.onPreviewStateChange);
    return state;
  }

  _setupMaxDimensionState(maxWidth, maxHeight) {
    const dimensions = this._parseMaxDimensions(maxWidth, maxHeight);
    const state = new Backbone.Model(dimensions);
    this.listenTo(state, 'change', this.onMaxDimensionsChange);
    return state;
  }

  _setupLoadingView() {
    return new LoadingWrapperView({
      spinner: {
        type: 'small'
      }
    });
  }

  _showLoadingView() {
    this.mediaRegion.show(this._loadingView);
  }

  _isPreviewable(newFile) {
    const isFilePreviewable = Boolean(newFile != null ? newFile.isPreviewable() : undefined);

    return isFilePreviewable || this._shouldPreviewOnlyFilename();
  }

  _updatePreviewState() {
    const maxDimensions = this._getMaxDisplayDimensions();
    const newFile = MediaHelpers.getDisplayFile(this.model, maxDimensions);
    this._previewState.set({
      fileId: (newFile != null ? newFile.getGeneratedId() : undefined),
      file: newFile,
      previewable: this._isPreviewable(newFile) || this.model?.isProcessed()
    });
  }

  _clearMediaPreviewView() {
    return this._loadingView.contentRegion.empty();
  }

  _showMediaFile() {
    const altTextObject = this.model.get('altText') || {};
    const file = this._previewState.get('file');
    const previewable = this._previewState.get('previewable');

    if (previewable) {
      if (this._previewState.hasChanged('previewable')) {
        this._toggleLoadingWrapper(true);
        this._showMediaPreviewView(this.model, file, altTextObject);
      } else {
        this._loadingView.contentRegion.currentView.updateFile(file, altTextObject);
      }
    } else {
      this._showPlaceholderView(file);
      this._updateMediaLoadingShowState();
    }
  }

  _shouldPreviewOnlyFilename() {
    return this.model && this.filenameViewTypes.includes(this.model.get('type'));
  }

  /**
   * The whole point of this method is that each Preview type (VideoPreview, FilePreview, ImagePreview... others may be
   * added) has distinct properties needed in order to function. Returns a View, ready for having triggers attached and
   * being shown in a region.
   */
  getPreviewViewByMediatype(model, file, altTextObject) {
    const commonProperties = {
      altTextObject,
      zoomable: (this._zoomHandler != null),
      maxDimensions: this._maxDimensionState
    };

    if (this._shouldPreviewOnlyFilename()) {
      return new FileNameView(Object.assign(commonProperties, {
        model,
        showDownloadLink: this.filenameViewShowDownloadLink,
        shouldBeViewable: this.filenameShouldBeViewable,
        viewCb: this.filenameViewCb
      }));
    }

    // videos could be a BLOB with no file, or a file with a type of Video. Both are handled by the VideoPreview class.
    if (
      model.get('type') === MediaTypesEnum.VIDEO
      || file.fileType === MediaTypesEnum.VIDEO
    ) {
      return new VideoPreview(Object.assign(commonProperties, {
        model,
        videoPlayerOptions: this.videoPlayerOptions
      }));
    }

    if (
      file.fileType === MediaTypesEnum.FILE
      || file.fileType === MediaTypesEnum.DOCUMENT
    ) {
      return new FilePreview(Object.assign(commonProperties, {
        model: file
      }));
    }

    if (file.fileType === MediaTypesEnum.IMAGE) {
      return new ImagePreview(Object.assign(commonProperties, {
        model: file,
        skipGlobalHandler: this.skipGlobalHandler
      }));
    }
    return undefined;
  }

  _showMediaPreviewView(model, file, altTextObject) {
    const view = this.getPreviewViewByMediatype(model, file, altTextObject);
    view.listenTo(view, 'load', _.bind(this.triggerMethod, this, 'load'));
    view.listenTo(view, 'error', _.bind(this.triggerMethod, this, 'error'));
    view.listenTo(view, 'image:clicked', _.bind(this._zoomHandler != null ? this._zoomHandler : $.noop, this, this.model));
    view.listenTo(view, 'destroy', () => {
      Backbone.Wreqr.radio.channel('global').commands.execute('app:media:clear');
      this.triggerMethod('toggle:zoomable', false);
    });
    this.triggerMethod('toggle:zoomable', file.fileType === MediaTypesEnum.IMAGE);
    this._loadingView.contentRegion.show(view);
  }

  _showPlaceholderView(file) {
    const view = new PreviewPlaceholder({ model: file });
    this._loadingView.contentRegion.show(view);
  }

  _updateMediaLoadingShowState() {
    // _loadingPredicate is provided in the options for MediaPreview. Given a model representing a media element, it
    // understands if the media is loading (show the spinner) or has completed loading (hide it).
    const shouldShowLoadingSpinner = this._loadingPredicate(this.model);
    this._toggleLoadingWrapper(shouldShowLoadingSpinner);
  }

  _toggleLoadingWrapper(toggle) {
    if (toggle) {
      this._loadingView.show({ fade: false });
    } else {
      this._loadingView.hide();
    }
  }

  _getMaxDisplayDimensions() {
    const maxDimensions = this._maxDimensionState.toJSON();
    if (_.isEmpty(maxDimensions)) {
      return {
        maxWidth: this.getWidth(),
        maxHeight: this.getHeight()
      };
    }
    return maxDimensions;

  }

  _parseMaxDimensions(maxWidth, maxHeight) {
    const maxDimensions = {};
    if (maxWidth) {
      maxDimensions.maxWidth = parseInt(maxWidth, 10);
    }
    if (maxHeight) {
      maxDimensions.maxHeight = parseInt(maxHeight, 10);
    }
    return maxDimensions;
  }
}

module.exports = MediaPreview;
