const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');

class TagBarItem extends Marionette.ItemView {
  tagName() {
    return 'li';
  }

  ui() {
    return {
      tagButton: '.tag'
    };
  }

  getTemplate() {
    return `\
    <button class="tag" aria-label="<%- t('discover.tagBarPrompt') %> <%- tag %>">
      <span><%- tag %></span>
      <span class="tag__remove icon-remove"></span>
    </button>\
    `;
  }

  events() {
    return {
      'click @ui.tagButton': this.onClickTag,
      'keyup @ui.tagButton': this.onKeyupTag
    };
  }

  initialize(options = {}) {
    return ({formattingOptions: this.formattingOptions} = options);
  }

  onRender() {
    if (this.model.get('isSelected')) {
      this.ui.tagButton.addClass('tag__selected')
        .attr('aria-label', `${ I18n.t('discover.tagBar.removeTagFilter') } ${ this.model.get('tag') }`);
    }
  }

  onClickTag() {
    if (this.hasBeenClicked == null) {
      this.trigger('facet:clicked');
    }
    this.hasBeenClicked = true;
  }

  onKeyupTag(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      this.onClickTag();
    }
  }
}

module.exports = TagBarItem;
