// Module
var code = `<% if (primaryButtonDefaultHoverColour) { %>
  [dir] .axon-button:hover,
  [dir] .axon-button:focus,
  [dir] .axon-button.pressed,
  [dir] .btn-primary:hover,
  [dir] .btn-primary:focus,
  [dir] .btn-primary.pressed {
    background-color: <%- primaryButtonDefaultHoverColour %>;
    border-color: <%- primaryButtonDefaultHoverColour %>;
  }
<% } %>

<% if (primaryButtonTextColour) { %>
  .axon-button,
  .axon-button,
  .axon-button [class*="icon-"] {
    color: <%- primaryButtonTextColour %>;
  }
<% } %>

<% if (linkColour) { %>
  [dir] .axon-link-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    color: <%- linkColour %>;
    border: none;
    background: none;
  }
  [dir] .axon-link-button:focus { box-shadow: inset 0px 0px 2px 2px <%- linkColour %>; }
  .axon-link-button:hover { text-decoration: underline; }
<% } %>

<% if (primaryButtonHoverTextColour) { %>
  :not(#page-header-right-button) > .axon-button:not(.white):not(.red):not(.disabled):not(.grey):not(.axon-button--no-branding):hover,
  :not(#page-header-right-button) > .axon-button:not(.white):not(.red):not(.disabled):not(.grey):not(.axon-button--no-branding):focus,
  :not(#page-header-right-button) > .axon-button:not(.white):not(.red):not(.disabled):not(.grey):not(.axon-button--no-branding).pressed,
  :not(#page-header-right-button) > .axon-button:not(.white):not(.red):not(.disabled):not(.grey):not(.axon-button--no-branding):hover [class*="icon-"],
  :not(#page-header-right-button) > .axon-button:not(.white):not(.red):not(.disabled):not(.grey):not(.axon-button--no-branding):focus [class*="icon-"],
  :not(#page-header-right-button) > .axon-button:not(.white):not(.red):not(.disabled):not(.grey):not(.axon-button--no-branding).pressed [class*="icon-"] {
    color: <%- primaryButtonHoverTextColour %>;
  }
<% } %>

`;
// Exports
module.exports = code;