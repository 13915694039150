const GameManager = require('./GameManager');
const TeamGameManager = require('./TeamGameManager');

class GameManagerFactory {
  createGameManager(questionCtrl, sessionModel) {

    // to find out which manager to return,
    // we need to check if the game is a team game by
    // checking if it has the teamGame mechanic
    const { game } = sessionModel.currentAssessment.gamePlay;

    if (game.getMechanic('teamGame') != null) {
      return new TeamGameManager(questionCtrl, sessionModel);
    }
    // game is not a teamGame so return a new game manager
    return new GameManager(questionCtrl, sessionModel);

  }
}


module.exports = GameManagerFactory;
