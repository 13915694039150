const { LayoutView } = require('Marionette');

const I18n = require('@common/libs/I18n');
const AxBreakpointEnum = require('@common/data/enums/AxBreakpointEnum').default;

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/scrollable/Scrollable');

const MOBILE_MAX_WIDTH = AxBreakpointEnum.MOBILE_MAX;

class PageViewLayout extends LayoutView {
  className() {
    return 'knowledge-details-layout parent-height';
  }

  getTemplate() {
    return require('@common/components/discover/views/PageViewLayoutTemplate.html');
  }

  regions() {
    return {
      titleRegion: '#title',
      contentRegion: '#content',
      metadataRegion: '#metadata',
      actionBarRegion: '#action-bar'
    };
  }

  ui() {
    return {
      contentRow: '.row-content',
      actionBar: '#action-bar',
      content: '#content',
      metadata: '#metadata',
      editorPage: '#editor-page',
      titleContainer: '#title'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      Scrollable: {
        scrollableContainer: '#content, #metadata'
      }
    };
  }

  constructor(...args) {
    super(...args);

    const isRtl = I18n.isCurrentLanguageRtl();
    this._cssDirection = isRtl ? 'right' : 'left';
    this._translateXDirection = isRtl ? '-' : '';
    this._oppositeTranslateXDirection = isRtl ? '' : '-';
  }

  onClickInfo() {
    this.toggleMetadata();
  }

  onResize(winHeight, winWidth) {
    const titleHeight = this.ui.titleContainer.outerHeight();
    const containerHeight = this.$el.height();
    const buttonBarHeight = this.ui.actionBar.outerHeight();
    const contentHeight = containerHeight - titleHeight - buttonBarHeight;
    this.ui.contentRow.css('height', `calc(100% - ${ titleHeight }px - ${ buttonBarHeight }px)`);

    // set the height of the content
    this.ui.content.height(contentHeight);

    this.metaDataContainerWidth = this.ui.metadata.outerWidth();
    const editorParentWidth = this.ui.editorPage.width();
    this.containerWidth = editorParentWidth - this.metaDataContainerWidth;

    // At the MAX_WIDTH value the metadata becomes permanently visible
    // so we clear out any transforms and make sure the two side-by-side
    // elements fit properly.
    if (winWidth > MOBILE_MAX_WIDTH) {
      this.metadataOpen = true;
      if (!this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', true);
      }
      this.metadataAlwaysOpen = true;
      this.ui.content.css({width: `${ this.containerWidth }px`});
      this.ui.metadata.css({
        [this._cssDirection]: `${ this.containerWidth }px`,
        transform: ''
      });
    } else {
      this.metadataOpen = false;
      if (this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', false);
      }
      this.metadataAlwaysOpen = false;
      this.ui.content.css({width: ''});
      this.ui.metadata.css({[this._cssDirection]: ''});
    }
  }

  openMetadata() {
    if (this.metadataAlwaysOpen) {
      return;
    }
    $(this.ui.metadata).scrollTop(0);
    this.metadataOpen = true;
    this.trigger('metadata:isDisplayed', true);
    this._animateOpenMetadata();
  }

  closeMetadata() {
    if (this.metadataAlwaysOpen) {
      return;
    }
    this.metadataOpen = false;
    this.trigger('metadata:isDisplayed', false);
    this._animateCloseMetadata();
  }

  toggleMetadata() {
    if (this.metadataAlwaysOpen) {
      return;
    }
    if (this.metadataOpen) {
      this.closeMetadata();
    } else {
      this.openMetadata();
    }
  }

  _animateOpenMetadata() {
    // any browser that doesn't support transitions needs to have the lft prop animated
    const options = ($.support.transition == null)
      ? {[this._cssDirection]: this.containerWidth - this.metaDataContainerWidth}
      : {
        translateZ: 0, // Triggers 3d acceleration when available
        translateX: parseInt(`${ this._oppositeTranslateXDirection }${ this.metaDataContainerWidth }`, 10)
      };

    this.ui.metadata.velocity('stop');
    this.ui.metadata.velocity(options, () => {
      // Find the edit and insight button, and the first header
      const editButton = $('.knowledge-item-metadata-view .js-button-edit');
      const insightButton = $('.knowledge-item-metadata-view .js-button-insights');
      const firstMetaDataHeader = $('.knowledge-item-metadata-view h3').first();

      // The edit button comes first, followed by the insights button, but both may not be available
      // If that's the case, focus on the header as a fallback
      if (editButton.length > 0) {
        $(editButton).trigger('focus');
      } else if (insightButton.length > 0) {
        $(insightButton).trigger('focus');
      } else {
        $(firstMetaDataHeader).attr('tabindex', '-1')
          .trigger('focus');
      }
    });
  }

  _animateCloseMetadata() {
    // any browser that doesn't support transitions needs to have the lft prop animated
    const options = ($.support.transition == null)
      ? {[this._cssDirection]: this.containerWidth}
      : {
        translateZ: 0, // Triggers 3d acceleration when available
        translateX: parseInt(`${ this._translateXDirection }${ this.metaDataContainerWidth }`, 10)
      };

    this.ui.metadata.velocity('stop');
    this.ui.metadata.velocity(options);
  }
}

module.exports = PageViewLayout;
