const _ = require('underscore');


module.exports = {
  // here's a method to get the value of object using a
  // string with dot notation
  // http://stackoverflow.com/a/8052100/2996753
  getDescendantProp(obj = {}, desc = '') {
    return _.get(obj, desc);
  },

  // This method will created an object from string notation
  // and set it's value
  // http://stackoverflow.com/a/14891339/2996753
  addValueToObj(name, value, context = {}) {
    const parts = name.split('.');
    const p = parts.pop();
    let i = 0;
    let j;

    let tempContext = context;
    while (tempContext && (j = parts[i])) {
      tempContext = j in tempContext ? tempContext[j] : (tempContext[j] = {});
      i++;
    }

    if (tempContext && p) {
      tempContext[p] = value;
      return tempContext[p];
    }

    return undefined;

  }, // Object

  // Do we prefer using _.some than the current style?
  // https://stackoverflow.com/questions/28835732/underscore-check-if-key-exists-in-array-of-objects
  assertPropertyExists(obj = {}, propName = '', message) {
    if (!(propName in obj)) {
      const errorMessage = message || `"${ propName }" missing from object!`;

      throw new Error(errorMessage);
    }

    return obj[propName];
  },

  assertIsArray(value) {
    const isValueArray = Array.isArray(value);

    if (!isValueArray) {
      throw new Error(`The value that was passed in, ${ value } is not an array but the caller required it to be`);
    }

    return value;
  },

  // evaluates the value as a function with the given context and params or returns the value if it's an object
  getValue(value, context, ...params) {
    let tempValue = value;
    if (_.isFunction(value)) {
      tempValue = value.apply(context, params);
    }

    return tempValue;
  },

  setPropertyReadOnly(obj, key, value) {
    if (!Object.hasOwnProperty.call(obj, key) || Object.getOwnPropertyDescriptor(obj, key).writable) {
      Object.defineProperty(obj, key, {
        value: value,
        configurable: false,
        writable: false
      });
    }
  },

  // flattens a nested object through recursion
  flatten(nestedObj, separator = '.') {
    const flatObj = {};
    Object.entries(nestedObj).forEach(([key, value]) => {
      if (_.isObject(nestedObj[key])) {
        const flatObject = this.flatten(nestedObj[key], separator);
        Object.entries(flatObject).forEach(([path, val]) => {
          flatObj[`${ key }${ separator }${ path }`] = val;
        });
      } else {
        flatObj[key] = value;
      }
    });
    return flatObj;
  }
};
