// Module
var code = `<% if (primaryButtonDefaultColour) { %>
  [dir] .ax-button--branded {
    background-color: <%- primaryButtonDefaultColour %>;
    border-color: <%- primaryButtonDefaultColour %>;
  }

  [dir] .ax-button--branded-secondary,
  [dir] .ax-button--ghost:hover,
  [dir] .ax-button--ghost.pressed,
  [dir] .ax-button--ghost-parent-hover:hover,
  [dir] .ax-button--ghost-parent-hover.pressed,
  [dir] .filterable-selector-item.selected,
  [dir] .filterable-selector-item.selected:hover {
    border-color: <%- primaryButtonDefaultColour %>;
  }

  :root {
    --primary-button-default-colour:<%- primaryButtonDefaultColour %>;

    --axButton-branded-background: <%- primaryButtonDefaultColour %>;
  }
<% } %>

<% if (primaryButtonDefaultHoverColour) { %>
  [dir] .ax-button--branded:hover,
  [dir] .ax-button--branded.pressed,
  [dir] .ax-button--branded-secondary:hover,
  [dir] .ax-button--branded-secondary.pressed {
    background-color: <%- primaryButtonDefaultHoverColour %>;
    border-color: <%- primaryButtonDefaultHoverColour %>;
  }

  :root {
    --axButton-branded-background-hover: <%- primaryButtonDefaultHoverColour %>;
  }
<% } %>

<% if (primaryButtonTextColour) { %>
  [dir] .ax-button--branded {
    color: <%- primaryButtonTextColour %>;
  }

  :root {
    --axButton-branded-color: <%- primaryButtonTextColour %>;
  }
<% } %>

<% if (primaryButtonHoverTextColour) { %>
  [dir] .ax-button--branded:hover,
  [dir] .ax-button--branded.pressed {
    color: <%- primaryButtonHoverTextColour %>;
  }

  :root {
    --axButton-branded-color-hover: <%- primaryButtonHoverTextColour %>;
  }
<% } %>
`;
// Exports
module.exports = code;