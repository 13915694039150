import {
  Backdrop as MuiBackdrop,
  styled,
  type BackdropTypeMap as MuiBackdropTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxBackdropCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxBackdropTypeMap<P = object, D extends React.ElementType = 'div'> = MuiBackdropTypeMap<P & AxBackdropCustomProps, D>;

export type AxBackdropProps<
  D extends ElementType = AxBackdropTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxBackdropTypeMap<P & AxBackdropCustomProps, D>, D>;

const styledOptions = {
  name: 'AxBackdrop'
};

const StyledAxBackdrop = styled(MuiBackdrop, styledOptions)<AxBackdropProps>(({theme}) => {
  return {
    backgroundColor: theme.uiKit.backdrop.backgroundColor
  };
});

/**
 * A wrapper for the MuiBackdrop component with Axonify theming
 *
 * ℹ️ The background color uses the same value from MUI's backdrop component
 */
export const AxBackdrop: MuiOverridableComponent<AxBackdropTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxBackdropProps, ref: Ref<unknown>) => {
  const BackdropProps: AxBackdropProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxBackdrop { ...BackdropProps } ref={ ref }>
      { children }
    </StyledAxBackdrop>
  );
});

export default AxBackdrop;
