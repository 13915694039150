const { ReactControllerDefinitionFactory } = require('@common/modules/react');

const RecognitionPinsCardSkeleton = require('@training/apps/recognitions/widget/RecognitionPinsCardSkeleton');
const { has } = require('underscore');
const { RECOGNITION_PINS } = require('@common/data/enums/HubTileIdentifiers').default;

module.exports = (availableTiles) => {
  if (!has(availableTiles, RECOGNITION_PINS)) {
    return null;
  }

  return () => {
    return ReactControllerDefinitionFactory({
      component: import('@training/apps/recognitions/widget/RecognitionPinsCard'),
      loadingControllerDefinition: ReactControllerDefinitionFactory({
        component: RecognitionPinsCardSkeleton
      })
    })
  };
};
