const {
  intersection,
  values,
  result,
  isFunction
} = require('underscore');

const { Model } = require('Backbone');

class ContextModel extends Model {

  setContextConfig(stateConfig) {
    const duplicateKeys = intersection( values(stateConfig.Keys), this.keys() );

    if (duplicateKeys.length > 0) {
      throw new Error(`The stateConfig you're trying to register has keys that already exist in the ContextModel. Duplicate keys: ${ duplicateKeys.join(', ') }`);
    }

    this.set( result(stateConfig, 'defaults'), { silent: true } );

    if (isFunction(stateConfig.init)) {
      stateConfig.init(this);
    }
  }
}

module.exports = ContextModel;
