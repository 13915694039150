const logging = require('logging');
const _ = require('underscore');

const { getMemoizedFunctionWithInvalidator } = require('@common/libs/helpers/app/MemoizeHelpers');

const AssessmentOptionListFactory = require('@training/apps/training/collections/assessments/AssessmentOptionListFactory');
const SelectAssessmentPage = require('@training/apps/training/views/assessments/SelectAssessmentPage');

const QuizboardNextStartableAssessmentProvider = require('@training/apps/training/controllers/assessments/providers/QuizboardNextStartableAssessmentProvider');
const TopicSelectionForAssessmentInitiatorContext = require('@training/apps/training/controllers/assessments/TopicSelectionForAssessmentInitiatorContext');

const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const AbstractAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/AbstractAssessmentInitiatorController');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');
const AssessmentLaunchContextFactory = require('@common/data/models/assessments/AssessmentLaunchContextFactory');

class QuizboardSelectableAssessmentController extends AbstractAssessmentInitiatorController {
  constructor(parentProcessor, options = {}) {
    super(parentProcessor, options);

    this.getTimeLogOptions = this.getTimeLogOptions.bind(this);
    this.onSelectAssessmentPageComplete = this.onSelectAssessmentPageComplete.bind(this);
    this.onSelectAssessmentPageClosed = this.onSelectAssessmentPageClosed.bind(this);
    this.onCreateAssessmentFail = this.onCreateAssessmentFail.bind(this);

    ({
      assessmentType: this.assessmentType,
      assessmentFactory: this.assessmentFactory,
      contextualConfigurationProvider: this.contextualConfigurationProvider
    } = options);

    this.setupAssessmentOptionList();
    this.nextItemProvider = this.getNextItemProvider();
  }

  setupAssessmentOptionList() {
    logging.debug(`QuizboardSelectableAssessmentController - Initializing assessment option list for type: ${ this.assessmentType }`);
    const assessmentOptionListOptions = this.getAssessmentOptionListOptions();
    this.assessmentOptionList = AssessmentOptionListFactory.create(this.assessmentType, [], assessmentOptionListOptions);

    const fetchOptionsList = () => {
      return Promise.resolve(this.assessmentOptionList.fetch()
        .done(() => {
          try {
            logging.info(`QuizboardSelectableAssessmentController - initial options list: ${ this.assessmentOptionList.stringifyOriginalList() }`);
          } catch (error) {
            /* nomnomnom */
          }
        }));
    };

    ({
      memoizedFn: this.fetchAssessmentOptionsList,
      invalidateFn: this.invalidateAssessmentOptionsList
    } = getMemoizedFunctionWithInvalidator(fetchOptionsList));

    this.listenTo(this.assessmentOptionList, 'start:training', this.onSelectedItemToStart);
  }

  getAssessmentOptionListOptions() {
    return {
      passDateThreshold: this.sessionModel.get('startDate'),
      sessionModel: this.sessionModel
    };
  }

  setConfigurationProvider(provider) {
    this.contextualConfigurationProvider = provider;
  }

  processSequenceFlow(options = {}) {
    if (this.startAssessentOptions == null) {
      this.startAssessentOptions = options;
    }

    logging.debug(`QuizboardSelectableAssessmentController - Processing assessment creation for type: ${ this.assessmentType }`);

    return this.hasSomethingToDoAsync().then((somethingToDo) => {
      if (somethingToDo) {
        this.startAssessmentSelection();
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);

    });
  }

  loadConfigurationAsync() {
    return this.contextualConfigurationProvider.getConfigurationAsync().then((configuration) => {
      this.canContinue = configuration.canContinue;
    });
  }

  hasSomethingToDoAsync() {
    return this.loadConfigurationAsync().then(() => {
      return this.fetchAssessmentOptionsList().then(() => {
        return this.hasAssessmentOptions();
      });
    });
  }

  startAssessmentSelection() {
    // Make sure the dependant list is fetched before trying to show the select page, even if it's already cached.
    this.fetchAssessmentOptionsList().then(() => {
      this.startTrackingAssessmentSelectionTime(this.startAssessentOptions);
      this.showSelectAssessmentPage(this.startAssessentOptions);
    });
  }

  showSelectAssessmentPage(options = {}) {
    const selectAssessmentPageOptions = _.extend({}, options, this.getSelectAssessmentPageOptions());
    const selectAssessmentPage = new SelectAssessmentPage(selectAssessmentPageOptions);

    window.app.layout.setView(selectAssessmentPage);

    if (!this.canContinue) {
      ViewHelpers.showBackButtonWithReset({view: selectAssessmentPage});
    }
  }

  onSelectAssessmentPageComplete() {
    logging.debug(`QuizboardSelectableAssessmentController - Select assessment page complete for type: ${ this.assessmentType }`);
    this.processParentFlow();
  }

  onSelectAssessmentPageClosed() {
    logging.debug(`QuizboardSelectableAssessmentController - Select assessment page closed for type: ${ this.assessmentType }`);
    this.stopTackingAssessmentSelectionTime();
  }

  // This is fired when the user clicks on a training item
  onSelectedItemToStart(topicOption) {
    topicOption.setLaunchContext(AssessmentLaunchContextFactory(this.assessmentType));
    this.startAssessmentCreation(topicOption);
  }

  startAssessmentCreation(assessmentOption) {
    if (this.sessionModel.hasCurrentAssessment() || (this.createAssessmentDeferred && this.createAssessmentDeferred.isPending() === 'pending')) {
      return undefined;
    }

    logging.debug(`QuizboardSelectableAssessmentController - Assessment creation started for type: ${ this.assessmentType }`);

    this.createAssessmentPromise = this.createInitiatorContextAsync(assessmentOption)
      .then((context) => {
        this.invalidateAssessmentOptionsList();

        return this.assessmentFactory.createForAssessmentTypeAsync(this.assessmentType, context, assessmentOption.toAssessmentRequestJson())
          .then((data = {}) => {
            window.app.layout.showEmptyView();
            this.processAssessment(data.assessment, data.context);
          });
      });

    return this.createAssessmentPromise.catch(this.onCreateAssessmentFail.bind(this));
  }

  onCreateAssessmentFail(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errorCode = exception.getErrorCode();

    if ([
      AxonifyExceptionCode.CLIENT_ERROR_EMPTY_ASSESSMENT,
      AxonifyExceptionCode.CLIENT_ERROR_ASSESSMENT_NOT_APPLICABLE,
      AxonifyExceptionCode.CLIENT_ERROR_ON_THE_CLOCK_REQUIRED
    ].includes(errorCode)) {
      logging.debug(`QuizboardSelectableAssessmentController - Assessment creation failed for type: ${ this.assessmentType }, errorCode: ${ errorCode }`);

      return this.hasSomethingToDoAsync()
        .done((somethingToDo) => {
          if (!somethingToDo) {
            this.processParentFlow();
          }
        });
    }

    return undefined;
  }

  // convinience method delegating to the above -- there is no requirement interfaces implement this
  createInitiatorContextAsync(topicOption) {
    return this.isAssessmentRetakeAsync(topicOption).then(() => {
      return this.createInitiatorContext({ assessmentType: this.assessmentType });
    });
  }

  hasAssessmentOptions() {
    return this.assessmentOptionList.getOriginalLength() > 0;
  }

  isAssessmentRetakeAsync(assessmentOption) {
    const topicId = assessmentOption.getTopicId();
    const level = assessmentOption.getTopicLevel();

    return this.fetchAssessmentOptionsList().then(() => {
      const optionModel = this.assessmentOptionList.findTopicOption(topicId, level);
      return optionModel && optionModel.isRetake();
    });
  }

  getSelectAssessmentPageOptions() {
    return {
      collection: this.assessmentOptionList,
      trainingType: this.assessmentType,
      complete: this.onSelectAssessmentPageComplete,
      canContinue: this.canContinue,
      onCloseCallback: this.onSelectAssessmentPageClosed
    };
  }

  startTrackingAssessmentSelectionTime(options = {}) {
    logging.debug(`QuizboardSelectableAssessmentController - Started tracking selection time for assessment type: ${ this.assessmentType }`);
    const timeLogOptions = this.getTimeLogOptions(options);
    this.assesmentTimeLogId = window.apps.base.timeLogController.start(timeLogOptions, this.sessionModel.get('id'));
  }

  stopTackingAssessmentSelectionTime() {
    logging.debug(`QuizboardSelectableAssessmentController - Stopped tracking selection time for assessment type: ${ this.assessmentType }`);
    try {
      window.apps.base.timeLogController.stop(this.assesmentTimeLogId);
    } catch (e) {
      logging.error(e);
    }
  }

  getTimeLogOptions() {
    throw new Error('Subclasses are required to override this and return the right time log config.');
  }

  getSessionTrainingType() {
    throw new Error('Subclasses are required to override this and return the "session tyraining type"');
  }

  // begin assessment initiator methods
  processAssessment(assessment, context) {
    const assessmentController = this.getAssessmentProcessor({
      assessment,
      nextItemProvider: this.getNextItemProvider(context)
    });

    return assessmentController.processSequenceFlow();
  }

  createAssessment(assessmentOption) {
    const assessmentType = assessmentOption.getAssessmentType();
    const assessmentOptions = assessmentOption.toAssessmentRequestJson();

    logging.debug(`QuizboardSelectableAssessmentController - Creating assessment of type: ${ assessmentType }`);

    return this.createInitiatorContextAsync(assessmentOption)
      .then((context) => {
        return this.assessmentFactory.createForAssessmentTypeAsync(assessmentType, context, assessmentOptions)
          .catch((xhr) => {
            this.invalidateAssessmentOptionsList();

            return Promise.reject(xhr);
          });
      });
  }

  createInitiatorContext(json) {
    return new TopicSelectionForAssessmentInitiatorContext(json.assessmentType);
  }

  getDiscriminator() {
    return this.assessmentType;
  }

  getNextItemProvider() {
    return new QuizboardNextStartableAssessmentProvider(this.assessmentOptionList);
  }

  setNextItemProvider(nextItemProvider) {
    this.nextItemProvider = nextItemProvider;
  }
}

module.exports = QuizboardSelectableAssessmentController;
