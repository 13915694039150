// Module
var code = `<div class="modalHeader">
  <h1 class="qa-feedback-modal-header"><%- t('feedback.modalHeader') %></h1>
  <button class="close icon-remove js-close-feedback-modal qa-close-feedback-modal" aria-label="<%- t('general.close') %>"></button>
</div>
<form id="feedback-form">
  <div class="feedback-context-wrapper">
    <label for="feedback-context"><%- feedbackContextType %></label>
    <p id="feedback-context qa-feedback-context"><%= feedbackContextDetail %></p>
  </div>
  <fieldset class="feedback-type-wrapper">
    <label for="feedback-type-select"><%- feedbackTypePrompt %></label>
    <div id="feedback-type-select">
      <ul
        class="pill-container qa-pill-container"
        role="radiogroup"
        name="feedbackTypeSelector"
        data-field="issueType"
        data-options="feedbackTypeListOptions"
        data-editor="PillSelect">
      </ul>
    </div>
  </fieldset>
  <div class="feedback-comment-wrapper">
    <label for="feedback-comment"><%- t('feedback.commentLabel') %></label>
    <textarea id="feedback-comment" class="qa-feedback-comment" data-field="additionalDetails" maxlength="2048"></textarea>
  </div>
</form>
`;
// Exports
module.exports = code;