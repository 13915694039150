const { Collection } = require('Backbone');

const ReactionsEnum = require('@common/data/enums/ReactionsEnum');

const REACTION_SORT_ORDER = {
  [ReactionsEnum.LIKE]: 0,
  [ReactionsEnum.LOVE]: 1,
  [ReactionsEnum.CELEBRATE]: 2,
  [ReactionsEnum.THINKING]: 3,
  [ReactionsEnum.UPSET]: 4
};

class PageInsightsReactionsCollection extends Collection {
  comparator(modelA, modelB) {
    return REACTION_SORT_ORDER[modelA.get('key')] - REACTION_SORT_ORDER[modelB.get('key')];
  }
}

module.exports = PageInsightsReactionsCollection;
