// Module
var code = `<div class="bonuscharacter assessment">
  <div class="<%- bonuscharacter %>" aria-hidden="true"></div>
</div>

<div class="current-result-container" tabindex="-1" role="region" aria-label="<%- t('assessments.result.current') %>">
  <div class="result-container"></div>
  <div class="result-message"><h2 class="center"></h2></div>
</div>

<div class="next-result-container" role="region" aria-label="<%- t('assessments.result.next') %>">
  <h2 ><%- t('assessments.select.header.upNext') %></h2>
  <div class="result-container"></div>
</div>
`;
// Exports
module.exports = code;