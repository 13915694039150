const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const { MD5Serializer } = require('./PasswordSerializer');

require('@common/components/forms/editors/password/Password.less');

// Why is my password field auto-completing?  I set auto-complete to off!
// Chromium standards: for a form that has a password field that should not be autofilled,
// the value of the autocomplete attribute should be "new-password"
// See https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
// This is just the beginning, and autocomplete should be using the correct value, as shown here:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
// If you don't find a match there, probably just set it to 'off'

Form.Editor.Password = class Password extends Form.Editor {
  defaultValue = '';

  initialize({options} = {}) {
    ({
      autocorrect: this.autocorrect = 'off',
      autocomplete: this.autocomplete = 'off',
      autocapitalize: this.autocapitalize = 'off',
      placeholder: this.placeholder = this.$el.attr('placeholder'),
      id: this.id = this.$el.attr('id'),
      serializer: this._serializer = new MD5Serializer()
    } = options || {});

    this._isPasswordVisible = false;

    this.render();
  }

  getTemplate() {
    return `\
      <span class="input-wrapper">
        <%= axInput({
          ariaLabel: t('login.password'),
          autocapitalize: autocapitalize,
          autocomplete: autocomplete,
          autocorrect: autocorrect,
          classNameInput: 'qa-input-password',
          fullWidth: true,
          iconAriaLabelRight: t('login.showPassword'),
          iconClassRight: 'eye-icon-button icon-eye_' + eyeIconStatus,
          id: id,
          name: name,
          placeholder: placeholder,
          type: type,
          value: value
        })  %>
      </span>
    `;
  }

  templateHelpers() {
    return {
      type: this._isPasswordVisible ? 'text' : 'password',
      eyeIconStatus: this._isPasswordVisible ? 'close' : 'open',
      value: this.value,
      autocorrect: this.autocorrect,
      autocomplete: this.autocomplete,
      autocapitalize: this.autocapitalize,
      placeholder: this.placeholder,
      id: `${ this.id }Input`,
      name: this.id
    };
  }

  ui() {
    return {
      passwordInput: 'input',
      passwordToggler: '.eye-icon-button'
    };
  }

  events() {
    return {
      'click @ui.passwordToggler': 'onToggle',
      'focus @ui.passwordInput': 'onFocus',
      'blur @ui.passwordInput': 'onBlur',
      'change @ui.passwordInput': 'onChange'
    };
  }

  getValue() {
    if (this.isDestroyed) {
      return undefined;
    }
    return this._serializer.serialize(this);
  }

  setValue(value = this.defaultValue) {
    this.value = value;
  }

  setError() {
    this.$el.addClass('rederror');
    this.ui.passwordInput.attr('aria-invalid', 'true');
  }

  clearError() {
    this.$el.removeClass('rederror');
    this.ui.passwordInput.removeAttr('aria-invalid');
  }

  onToggle() {
    this._isPasswordVisible = !this._isPasswordVisible;
    this.value = this.ui.passwordInput.val().trim();

    this.render();

    this.ui.passwordToggler.attr('aria-label', this._isPasswordVisible ? I18n.t('login.hidePassword') : I18n.t('login.showPassword'));
    this.ui.passwordInput.trigger('focus');
  }

  onFocus() {
    this.$el.addClass('focused');
  }

  onBlur() {
    this.$el.removeClass('focused');
  }
};

module.exports = Form.Editor.Password;
