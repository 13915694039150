// Module
var code = `<div class="translatable-name">
  <%= axSelect({
    classNameSelect: 'js-language-selector qa-language-select',
    data: {
      shallow: 'true'
    },
    id: 'language',
    label: t('general.language'),
    name: 'language',
    noFormEditorDefault: 'true'
  }) %>
  <label for="name"><%= label %></label>
  <div class="js-translatable-field" name="name"></div>
  <p class="translatable-name__limit"><%= limit %></p>
  <div class="ax-grid ax-grid--no-gutter">
    <div class="icon-exclamation ax-grid__col--auto-size" aria-hidden="true" role="presentation"></div>
    <p class="ax-font--no-margin"><%= inputNote %></p>
  </div>
</div>
`;
// Exports
module.exports = code;