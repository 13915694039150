const $os = require('detectOS');

const _ = require('underscore');
const Backbone = require('Backbone');

const { windowDimensions } = require('@common/libs/helpers/app/BrowserHelpers');

const _winDimensions = new Backbone.Model(windowDimensions());

// Make sure the browser has fully finished painting the DOM before
// other View code reacts to the changes. See: https://stackoverflow.com/q/3485365
_winDimensions.on('change', () => {
  return $win.offsetHeight;
});

const _throttledResize = _.throttle((event, force = false) => {
  // Save dimension state
  _winDimensions.set(windowDimensions());

  // Trigger change event even if no dimensions changed if force is passed in.
  // XXX This is to accomodate legacy View's that manually trigger resize adjustments to produce side effects.
  if (!_winDimensions.hasChanged() && force) {
    _winDimensions.trigger('change', _winDimensions);
  }
}, 50);

const $win = $(window);
$win.on('resize.resizable', _throttledResize);

if ($os.ios) {
  $win.on('orientationchange', _throttledResize);
}

module.exports = _winDimensions;
