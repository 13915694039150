const _ = require('underscore');
const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const PageReportedReasonsEnum = require('../enums/PageReportedReasonsEnum');

/**
 * This does everything needed to show the little banner stripe in the needs review cards
 */
const getNeedsReviewCardBannerView = (model) => {
  const reportedCount = model.get('reportedCount') || 0;
  const inReviewCount = model.get('needsReviewCount') || 0;
  const scheduledCount = model.get('scheduledCount') || 0;
  const expiredCount = model.get('expiredCount') || 0;

  const noticesCount = reportedCount + inReviewCount + scheduledCount + expiredCount;
  if (noticesCount === 0) {
    // well, if this card is being shown on the "needs attention" page, then there should be something here, a
    // notice of some sort. But in case there isn't, our view will fail gracefully, showing no banner.
    return null;
  }

  const bannerOptions = {
    className: '',
    icon: '',
    text: ''
  };

  // if notices exist in more than one type, then we show a gray banner with "X Notices on this Article"
  const noticeTypes = [reportedCount, inReviewCount, scheduledCount, expiredCount];
  const typeCount = _.reduce(
    noticeTypes,
    (memo, num) => {
      if (num > 0) {
        return memo + 1;
      }
      return memo;
    },
    0
  );
  if (typeCount > 1) {
    // show a gray banner with "X Notices on this Article" where X is the noticesCount. This isn't the cardinality of
    // types, it is the cardinality of *notices*
    bannerOptions.className = 'multiple-banner';
    bannerOptions.text = I18n.t('discover.pageTypes.generic.banners.multipleNotices', {noticesCount});
    return _generateNeedsReviewCardBannerItemView(bannerOptions);
  }

  // evidently if we reach here, there is only one type of notice.
  // The banner can take two forms, if there is just one article with a notice or if there are multiple variants in
  // the bundle.
  if (expiredCount > 0) {
    bannerOptions.className = 'expired-banner';
    bannerOptions.icon = 'exclamation_sign-outline';
    if (expiredCount > 1) {
      bannerOptions.text = I18n.t('discover.pageTypes.generic.banners.multilingual.expired', {expiredCount});
      bannerOptions.icon = 'exclamation_sign-outline';
    } else {
      bannerOptions.text = I18n.t('discover.pageTypes.generic.expiryDate', {
        expiryDate: dateHelpers.convertDateFormatShortDateWithStringsAndNoYear(model.get('expiryTimestamp'))
      });
    }
  } else if (scheduledCount > 0) {
    bannerOptions.className = 'scheduled-banner';
    bannerOptions.icon = 'exclamation_sign-outline';
    if (scheduledCount > 1) {
      bannerOptions.text = I18n.t('discover.pageTypes.generic.banners.multilingual.scheduled', {scheduledCount});
    } else {
      bannerOptions.text = I18n.t('discover.pageTypes.generic.scheduledDate', {
        publishDate: dateHelpers.convertDateFormatShortDateWithStringsAndNoYear(model.get('publishTimestamp'))
      });
    }
  } else if (inReviewCount > 0) {
    bannerOptions.className = 'inReview-banner';
    bannerOptions.icon = 'check';
    if (inReviewCount > 1) {
      bannerOptions.text = I18n.t('discover.pageTypes.generic.banners.multilingual.inReview', {inReviewCount});
    } else {
      bannerOptions.text = I18n.t('discover.pageTypes.inReview.title');
    }
  } else if (reportedCount > 0) {
    bannerOptions.className = 'reported-banner';
    bannerOptions.icon = 'exclamation_sign-outline';
    if (reportedCount > 1) {
      bannerOptions.text = I18n.t('discover.pageTypes.generic.banners.multilingual.reported', {reportedCount});
    } else {
      const reportType = model.get('reportType');
      switch (reportType) {
        case PageReportedReasonsEnum.INCORRECT:
          bannerOptions.text = I18n.t('discover.pageTypes.generic.reported.reasons.incorrect');
          break;
        case PageReportedReasonsEnum.OFFENSIVE:
          bannerOptions.text = I18n.t('discover.pageTypes.generic.reported.reasons.offensive');
          break;
        case PageReportedReasonsEnum.OUTDATED:
          bannerOptions.text = I18n.t('discover.pageTypes.generic.reported.reasons.outdated');
          break;
        case PageReportedReasonsEnum.SPAM:
          bannerOptions.text = I18n.t('discover.pageTypes.generic.reported.reasons.spam');
          break;
        default: // this includes the reason named "other"
          bannerOptions.text = I18n.t('discover.pageTypes.generic.reported.reasons.unspecified');
      }
    }
  }

  return _generateNeedsReviewCardBannerItemView(bannerOptions);
};

// Generate a simple banner view when provided the options below
const _generateNeedsReviewCardBannerItemView = (options = {}) => {
  return new ItemView({
    className: 'banner-wrapper',
    template: `
      <div class="notify-container clearfix ${ options.className }">
        <i class="icon-${ options.icon }"></i>
        <div class="info">
          <p>${ options.text }</p>
        </div>
      </div>\
    `
  });
};

module.exports = {
  getNeedsReviewCardBannerView
};
