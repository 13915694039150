const { ItemView } = require('Marionette');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TrainingCardTopRegionLayoutView = require('@common/components/trainingCards/TrainingCardTopRegionLayoutView');
const TrainingCardHeaderInfoView = require('@common/components/trainingCards/TrainingCardHeaderInfoView');
const TrainingCardGraphicView = require('@common/components/trainingCards/TrainingCardGraphicView');
const TrainingCardTagView = require('@common/components/trainingCards/TrainingCardTagView');

const getGraphicRegionConfig = function({
  trainingCardType,
  thumbnailImage
} = {}) {
  return {
    viewControllerClass: ItemView,
    viewDefinition: {
      ViewClass: TrainingCardGraphicView,
      trainingCardType,
      thumbnailImage
    }
  };
};

const getHeaderInfoRegion = function({
  title,
  subjectName
}) {
  return {
    viewControllerClass: ItemView,
    viewDefinition: {
      ViewClass: TrainingCardHeaderInfoView,
      title,
      subjectName
    }
  };
};

const getHeaderTagRegion = function({
  trainingCardType,
  isAssigned,
  isEnrolled,
  hasReassignedItems
} = {}) {
  return {
    viewControllerClass: ItemView,
    viewDefinition: {
      ViewClass: TrainingCardTagView,
      trainingCardType,
      isAssigned,
      isEnrolled,
      hasReassignedItems
    }
  };
};

module.exports = {
  getTrainingCardTopRegionConfig: ({
    title = '',
    subjectName,
    trainingCardType,
    thumbnailImage,
    isAssigned,
    isEnrolled,
    isRecommended,
    hasReassignedItems
  } = {}) => {

    const controllerDefinition = {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: TrainingCardTopRegionLayoutView,
        isRecommended
      },
      regionControllers: {
        graphicRegion: getGraphicRegionConfig({
          trainingCardType,
          thumbnailImage
        }),
        headerInfoRegion: getHeaderInfoRegion({
          title,
          subjectName
        }),
        tagRegion: getHeaderTagRegion({
          trainingCardType,
          isAssigned,
          isEnrolled,
          hasReassignedItems
        })
      }
    };

    return controllerDefinition;
  }
};
