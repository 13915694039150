const _ = require('underscore');
const {
  QuestionPreviewPage,
  QuestionOptionItemView,
  QuestionCollectionView
} = require('@common/components/questions/views/QuestionPreviewPage/QuestionPreviewPage');
const { getSelectedMultiLangImage } = require('@common/libs/helpers/app/ImageHelpers');

class MatchingQuestionPreviewPage extends QuestionPreviewPage {
  constructor(...args) {
    super(...args);
    this.onShow = this.onShow.bind(this);
    this._getOptionCollectionView = this._getOptionCollectionView.bind(this);
  }

  className() {
    return 'matching';
  }

  regions() {
    return _.extend(super.regions(), {
      answerOptions: '#answer-body'
    });
  }

  onShow() {
    super.onShow();
    this.optionTextView = this._getOptionCollectionView();
    this.answerOptions.show(this.optionTextView);

    this.listenTo(this.optionTextView, 'image:clicked', (itemView, mediaId) => {
      this.trigger('show:zoom:modal', itemView.getImageMedia(mediaId));
    });
  }

  _getOptionCollectionView() {
    return new MatchingQuestionCollectionView({model: this.model});
  }
}

class MatchingQuestionOptionItemView extends QuestionOptionItemView {
  constructor(...args) {
    super(...args);
    this.initialize = this.initialize.bind(this);
    this.templateHelpers = this.templateHelpers.bind(this);
    this.onShow = this.onShow.bind(this);
    this.formatRows = this.formatRows.bind(this);
    this.setRowMaxHeight = this.setRowMaxHeight.bind(this);
    this._getAnswerResult = this._getAnswerResult.bind(this);
    this.serializeData = this.serializeData.bind(this);

    this.MIN_ROW_HEIGHT = 86;
  }

  ui() {
    return {
      table: '.cell table',
      answerImage: '.answerimage'
    };
  }

  tagName() {
    return 'li';
  }

  className() {
    return 'matched';
  }

  getTemplate() {
    return require('@common/components/questions/templates/matchingQuestion/answerOptionItem.html');
  }

  initialize(options = {}) {
    (({
      selectedAnswer: this.selectedAnswer,
      optionList: this.optionList,
      itemIndex: this.itemIndex,
      translatedLanguage: this.translatedLanguage
    } = options));

    super.initialize(options);
  }

  templateHelpers() {
    return {
      isAnswered: this.optionList.isAnswered,

      renderRow: this.itemIndex < this.optionList.numberOfRows,

      getLeftImage: () => {
        const itemIndex = this.optionList.options[this.itemIndex];
        if (itemIndex != null) {
          return getSelectedMultiLangImage(itemIndex.optionImg, this.translatedLanguage);
        }
        return null;
      },

      getLeftText: () => {
        const options = this.optionList.options[this.itemIndex];
        if (options != null) {
          if (this.translatedLanguage && options.optionTextTranslation != null) {
            return options.optionTextTranslation.getValueForLanguage(this.translatedLanguage, false);
          }
          if (options.optionText != null) {
            return options.optionText;
          }
        }
        return '';
      },

      getRightImage: () => {
        if (this.optionList.secondOptions[this.itemIndex] != null) {
          return getSelectedMultiLangImage(this.optionList.secondOptions[this.itemIndex].secondImg, this.translatedLanguage);
        }
        return null;
      },

      getRightText: () => {
        const secondOptions = this.optionList.secondOptions[this.itemIndex];
        if (secondOptions != null) {
          if (this.translatedLanguage && secondOptions.secondTextTranslation != null) {
            return secondOptions.secondTextTranslation.getValueForLanguage(this.translatedLanguage, false);
          }
          if (secondOptions.secondText != null) {
            return secondOptions.secondText;
          }
        }
        return '';
      },

      getAnswerResult: () => {
        return this._getAnswerResult();
      },

      getLeftCellSyles() {
        const optionText = this.getLeftText();
        const optionImage = this.getLeftImage();
        const styles = [];
        styles.push(this.getAnswerResult());

        if (!optionImage) {
          styles.push('textonly');
        } else if (optionText === '' && optionImage) {
          styles.push('imageonly');
        } else {
          styles.push('textandimage');
        }

        return styles.join(' ');
      },

      getRightCellSyles() {
        const secondText = this.getRightText();
        const secondImage = this.getRightImage();
        const styles = [];
        styles.push(this.getAnswerResult());

        if (!secondImage) {
          styles.push('textonly');
        } else if (secondText === '' && secondImage) {
          styles.push('imageonly');
        } else {
          styles.push('textandimage');
        }
        return styles.join(' ');
      }
    };
  }

  onResize() {
    this.formatRows();
  }

  onShow() {
    this.formatRows();
    this.listenTo(this, 'image:loaded', this.formatRows);

    super.onShow();
  }

  _createImageMediaInfo() {
    const optionImg = this.optionList.options[this.itemIndex] != null ? this.optionList.options[this.itemIndex].optionImg : undefined;
    const selectedOptionImg = getSelectedMultiLangImage(optionImg, this.translatedLanguage);
    const secondImg = this.optionList.secondOptions[this.itemIndex] != null ? this.optionList.secondOptions[this.itemIndex].secondImg : undefined;
    const selectedSecondImg = getSelectedMultiLangImage(secondImg, this.translatedLanguage);

    if (selectedOptionImg != null) {
      this._imageMediaInfo[selectedOptionImg.id] = selectedOptionImg;
    }
    if (selectedSecondImg != null) {
      this._imageMediaInfo[selectedSecondImg.id] = selectedSecondImg;
    }
  }

  formatRows() {
    if (this.isDestroyed) {
      return;
    }
    this.setRowMaxHeight();
  }

  setRowMaxHeight() {
    this.ui.table.css('height', 'auto');
    for (let i = 0; i < this.ui.table.length; i++) {
      let maxRowHeight = Math.max.apply(null, this.ui.table.map(() => {
        return this.$el.outerHeight(true);
      }).get());
      maxRowHeight = Math.max(maxRowHeight, this.MIN_ROW_HEIGHT);
      this.ui.table.css('height', maxRowHeight);
    }
  }

  _getAnswerResult() {
    let text;
    if ((this.optionList.options[this.itemIndex] != null ? this.optionList.options[this.itemIndex].id : undefined) === (this.optionList.secondOptions[this.itemIndex] != null ? this.optionList.secondOptions[this.itemIndex].id : undefined)) {
      text = 'correct';
    } else {
      text = 'incorrect';
    }

    return text;
  }

  serializeData() {
    const data = super.serializeData();

    if (this.translatedLanguage) {
      data.optionText = data.optionTextTranslation != null ? data.optionTextTranslation.getValueForLanguage(this.translatedLanguage, false) : undefined;
      data.reasonText = data.reasonTextTranslation != null ? data.reasonTextTranslation.getValueForLanguage(this.translatedLanguage, false) : undefined;
    }

    return data;
  }
}

class MatchingQuestionCollectionView extends QuestionCollectionView {
  constructor(...args) {
    super(...args);
    this.childViewOptions = this.childViewOptions.bind(this);
  }

  tagName() {
    return 'ul';
  }

  className() {
    return 'optionsarea mq-preview';
  }

  getChildView() {
    return MatchingQuestionOptionItemView;
  }

  childViewContainer() {
    return 'li';
  }

  childViewOptions(model, index) {
    let optionList, selectedAnswer = false;
    let answerOptionsList = this.options.model.get('questionOptionList');
    // Admin/Content Questions have no concept of an answered question
    // Also in Admin/Content we need to display ALL the rows not just the ones answered
    if (answerOptionsList.length <= 0) {
      answerOptionsList = [];
      const variant = this.options.model.get('variant');
      const unAnsweredOptions = variant.get('unAnsweredOptions');
      const optionListToUse = (unAnsweredOptions) != null ? unAnsweredOptions : variant.get('options');
      // Need to create an interlacing array of optionId, secondId, optionId, secondId etc
      _.each(optionListToUse, (option) => {
        answerOptionsList.push(option.previewId != null ? option.previewId : option.id);
        answerOptionsList.push(option.previewSecondId != null ? option.previewSecondId : option.secondId);
      });

      optionList = this.getCollectionRowCount(answerOptionsList, this.options.model.get('variant'), false);
    } else {
      answerOptionsList = answerOptionsList.split(',');
      optionList = this.getCollectionRowCount(answerOptionsList, this.options.model.get('variant'));

      for (let i = 0; i < optionList.numberOfRows; i++) {
        const options = optionList.options[i];
        const secondOptions = optionList.secondOptions[i];
        if (options != null && secondOptions != null && options.id === secondOptions.id) {
          selectedAnswer = true;
          break;
        }
      }
    }

    return {
      translatedLanguage: this.options.model.get('translatedLanguage'),
      selectedAnswer,
      optionList,
      itemIndex: index
    };
  }

  getCollectionRowCount(answerOptionsList, variant, isAnsweredOption) {
    const isAnswered = isAnsweredOption ?? true;
    const options = [];
    const secondOptions = [];
    const questionOptionList = answerOptionsList.map((id) => {
      return parseInt(id, 10);
    });

    for (let j = 0; j < questionOptionList.length; j += 2) {
      const optionId = questionOptionList[j];
      const unAnsweredOptions = variant.get('unAnsweredOptions');
      const optionsToUse = (unAnsweredOptions != null ? unAnsweredOptions : variant.get('options'));
      options.push( _.find(optionsToUse, (option) => {
        if (option.previewId != null) {
          return option.previewId === optionId;
        }
        return option.id === optionId;

      }));
      const secondOptionId = questionOptionList[j + 1];
      secondOptions.push( _.find(optionsToUse, (option) => {
        if (option.previewSecondId != null) {
          return option.previewSecondId === secondOptionId;
        }
        return option.secondId === secondOptionId;

      }));
    }

    const numberOfRows = Math.max(options.length, secondOptions.length);

    return {
      numberOfRows,
      options,
      secondOptions,
      isAnswered
    };
  }
}

module.exports = MatchingQuestionPreviewPage;
