const { ItemView } = require('Marionette');

const I18n = require('@common/libs/I18n');

require('@common/libs/behaviors/pressedHoverButton/PressedHoverButtonBehavior');

class BackButton extends ItemView {

  tagName() {
    return 'a';
  }

  className() {
    // 'inline-block' is used for this link since it would normally be inline and not take on the right dimensions otherwise.
    return 'header-button back-button inline-block clickable';
  }

  attributes() {
    return {
      href: '#',
      role: 'button',
      'aria-label': I18n.t('general.back')
    };
  }

  getTemplate() {
    return `
      <div class="content-wrapper">
        <span class="icon-chevron_left"></span><span class="back-text"><%- t('general.back') %></span>
      </div>
    `;
  }

  behaviors() {
    return {
      PressedHoverButtonBehavior: {}
    };
  }
}

module.exports = BackButton;
