const ArticlePrintFactory = require('@common/components/discover/factories/ArticlePrintFactory');
const { getStylePath } = require('@common/libs/helpers/app/CompilationInfoHelpers');

const triggerPrint = function(glChannel, header, content) {
  const printConfig = {
    cssPaths: getStylePath(process.styleSourceKey.printCss),
    view: ArticlePrintFactory.create(header, content)
  };

  glChannel.commands.execute('app:print', `print-article-${ header.model.id }`, printConfig);
};

module.exports = {
  triggerPrint
};
