const { size, isFunction } = require('underscore');

/*

  ViewControllerRegistry

  Simple registry of all viewControllers and subclasses to facilitate ViewController lifecyle testing to
  ensure all controllers are destroyed with their view's.

*/

const _registry = {};

const ViewControllerRegistry = {

  register(instance, id) {
    _registry[id] = instance;
  },

  unregister(instance, id) {
    delete _registry[id];
  },

  resetRegistry() {
    const oldCount = this.getRegistrySize();

    for (const key in _registry) {
      const controller = _registry[key];
      if (controller && isFunction(controller.getView)) {
        const view = controller.getView();

        if (!view.isDestroyed) {
          view.destroy();
        }
      }
    }

    const msg = `There were ${ oldCount } controllers in the registry, there are now ${ this.getRegistrySize() }`;

    if (this.getRegistrySize() > 0) {
      window.console.warn(msg, _registry);
    } else {
      window.console.log(msg);
    }
  },

  getRegistrySize() {
    return size(_registry);
  }
};

module.exports = window.ViewControllerRegistry = ViewControllerRegistry;
