const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  Locations: 'locations',
  LocationsFilterRule: 'locationsFilterRule',
  JobTitles: 'jobTitles',
  GroupAreasOfInterest: 'groupAreasOfInterest',
  DateCondition: 'dateCondition',
  Departments: 'departments',
  LinesOfBusiness: 'linesOfBusiness',
  LocationTypes: 'locationTypes',
  LinesofBusinessFilterRule: 'linesOfBusinessFilterRule',
  JobTitlesFilterRule: 'jobTitlesFilterRule',
  LocationTypesFilterRule: 'locationTypesFilterRule',
  DepartmentsFilterRule: 'departmentsFilterRule',
  GroupTeamAttributes: 'groupTeamAttributes'
});
