const ListView = require('./ListView');

const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');

class ScrollingListView extends ListView {

  getTemplate() {
    return false;
  }

  behaviors() {
    const direction = this.getOption('direction') || 'horizontal';

    return {
      Scrollable: {
        [Scrollable.Options.VERTICAL]: direction === 'vertical',
        [Scrollable.Options.HORIZONTAL]: direction === 'horizontal',
        [Scrollable.Options.CONTENT_DIMENSIONS_CALCULATOR]: this.getScrollContentDimensions.bind(this)
      }
    };
  }

  renderList() {
    super.renderList();
  }

  getListEl() {
    return this.$el;
  }

  getScrollContentDimensions() {
    return {
      height: this.getOuterHeight(this.getListEl(), true),
      width: this.getOuterWidth(this.getListEl(), true)
    };
  }
}

module.exports = ScrollingListView;
