const _ = require('underscore');

const View = require('./View');
const KeyCode = require('@common/data/enums/KeyCode');

// TabBar
class TabBar extends View {
  constructor(...args) {
    super(...args);
    this.reset = this.reset.bind(this);
    this.addTab = this.addTab.bind(this);
    this.renameTab = this.renameTab.bind(this);
    this.updateItemCount = this.updateItemCount.bind(this);
    this.removeTab = this.removeTab.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.clickTab = this.clickTab.bind(this);
    this.clickTabAtIndex = this.clickTabAtIndex.bind(this);
    this.clickDefaultTab = this.clickDefaultTab.bind(this);
    this.setViewForTabName = this.setViewForTabName.bind(this);
    this.setViewForCurrentTab = this.setViewForCurrentTab.bind(this);
    this.showTab = this.showTab.bind(this);
    this.selectedTabName = this.selectedTabName.bind(this);
    this.selectedTabIndex = this.selectedTabIndex.bind(this);
  }

  initialize(options) {
    this.tabTemplate = _.tpl(`\
<li data-tab="<%- tabName %>">
  <a href="#">
    <span class="tabTitleWrapper">
      <span class="tabtitle"><%- title %></span>
      <% if(showCount) { %>
      <span class="itemCountWrapper">(<span class="itemCount">0</span>)</span>
      <% } %>
    </span>
  </a>
</li>\
`);
    this.views = {};
    this.tabNames = [];
    this.tabPages = [];
    if (options.container) {
      this.$tabPanelsWrapper = options.container;
    }
    if (options.tabTemplate) {
      this.tabTemplate = options.tabTemplate;
    }
    this.fadeDuration = options.fadeDuration != null ? options.fadeDuration : 'fast';
    this.showCount = options.showCount || false;

    this.$('li[data-tab]').each((index, element) => {
      const $tab = $(element);
      const tabName = $tab.attr('data-tab');
      this.tabNames.push(tabName);
      this.tabPages.push($tab);
    });
    this.length = this.tabNames.length;
    this._updateAccessibilityAttributes();
    this._updateTabCount();
  }

  events() {
    return {
      // IE7 expands the href to full path, so we need to use "href$='#'".
      'click li[data-tab] a[href$=\'#\']': 'onChangeTab',
      'keydown li[data-tab] a[href$=\'#\']': '_handleKeyInputTab'
    };
  }

  render() {
    return this;
  }

  _handleKeyInputTab(e) {
    const tabCount = this.tabNames.length;
    if (!tabCount || tabCount === 1) {
      return;
    }

    if (e.which === KeyCode.LEFT || e.which === KeyCode.RIGHT) { // for accessibility and keyboard nav
      const indexBefore = this.selectedTabIndex();
      let indexAfter = (e.which === KeyCode.LEFT) ? indexBefore - 1 : indexBefore + 1;
      if (indexAfter >= tabCount) {
        indexAfter = 0;
      } else if (indexAfter < 0) {
        indexAfter = tabCount - 1;
      }

      this.focusTabAtIndex(indexAfter);
      this.clickTabAtIndex(indexAfter);
    }
  }

  reset() {
    for (const key in this.views) {
      if (this.views[key]) {
        this.views[key].remove();
      }
    }
    this.currentTabName = null;
    this.views = {};
    this.tabNames = [];
    this.tabPages = [];
    this.$('li[data-tab]').remove();
    this.length = 0;
    this._updateTabCount();
  }

  // Add a new tab
  addTab(tabName, title) {
    const $newTab = $(this.tabTemplate({
      tabName,
      title,
      showCount: this.showCount
    }));

    if (this.$('[data-tab]').length > 0) {
      this.$('[data-tab]:last').after($newTab);
    } else {
      this.$el.prepend($newTab);
    }

    this.tabPages.push($newTab);
    this.tabNames.push(tabName);
    this.length = this.length + 1;
    this._updateAccessibilityAttributes();
    this._updateTabCount();
  }

  renameTab(index, tabName) {
    const element = this.tabPages[index];
    $(element).find('span.tabtitle')
      .text(tabName);
  }

  updateItemCount(index, count) {
    if (!this.showCount) {
      return;
    }
    const element = this.tabPages[index];
    $(element).find('span.itemCount')
      .text(count);
  }

  removeTab(tabName) {
    const index = this.tabNames.indexOf(tabName);
    this.tabNames.splice(index, index - index + 1, ...[].concat([]));
    this.tabPages.splice(index, index - index + 1, ...[].concat([]));
    this.$(`[data-tab]:nth-child(${ index + 1 })`).remove();
    if (this.views[tabName] != null) {
      this.views[tabName].remove();
    }
    delete this.views[tabName];
    this.length = this.length - 1;
    this._updateAccessibilityAttributes();
    this._updateTabCount();
  }

  _updateTabCount() {
    const classToAdd = `tab-count-${ this.length }`;
    const classToRemove = this._previousCountClass != null ? this._previousCountClass : '';
    this._previousCountClass = classToAdd;

    this.$el.removeClass(classToRemove);
    this.$el.addClass(classToAdd);
  }


  onChangeTab(e) {
    const tabName = $(e.target).closest('li')
      .attr('data-tab');

    const { currentTabName } = this;

    if (currentTabName !== tabName) {
      this.selectTab(tabName, currentTabName);
      this.showTab(tabName);

      this.currentTabName = this.selectedTabName();
    }
  }

  _highlightTab($li, $a) {
    $li.addClass('selected');
    $a.attr('aria-selected', 'true')
      .addClass('selected');
  }

  _unhighlightAllTabs() {
    this.$('li').removeClass('selected border-black');
    this.$('a').attr('aria-selected', 'false')
      .removeClass('selected border-black');
  }

  // Turn a tab into the 'selected' state and trigger 'change:tabs' event
  selectTab(tabName, currentTabName) {
    const $selectedLi = this.$(`li[data-tab='${ tabName }']`);
    const $selectedA = $selectedLi.find('a');

    this._unhighlightAllTabs();
    this._highlightTab($selectedLi, $selectedA);

    this.trigger('change:tabs', tabName, currentTabName);
  }

  // Simulate clicking on a tab
  clickTab(tabName) {
    this.$(`li[data-tab='${ tabName }'] a`).trigger('click');
  }

  focusTab(tabName) {
    this.$(`li[data-tab='${ tabName }'] a`).trigger('focus');
  }

  focusTabAtIndex(index) {
    const tabName = this.tabNames[index];
    this.focusTab(tabName);
  }

  // Click a tab based on the tab index
  clickTabAtIndex(index) {
    const tabName = this.tabNames[index];
    this.clickTab(tabName);
  }

  // Simulate clicking on the first tab
  clickDefaultTab() {
    this.$('li:first a').trigger('click');
  }

  setViewForTabName(v, tabName) {
    const cached = this.views[tabName];
    this.views[tabName] = v;

    this._updateAccessibilityAttributes();

    if ((tabName === this.currentTabName) && cached) {
      // Fade out the current view in the tab
      cached.$el.css({position: 'absolute'});
      cached.$el.fadeOut(this.fadeDuration, () => {
        cached.$el.css({position: ''});
        if (typeof cached.unload === 'function') {
          cached.unload();
        }
        return cached.remove();
      });

      // Append and fade in the new tab, height stuff like in showTab() aren't needed here cause
      // the new view will always come below the old one which doens't cause flashing effects.
      this.$tabPanelsWrapper.append(v.el);
      return v.$el.fadeIn(this.fadeDuration, () => {
        return (typeof v.viewDidAppear === 'function' ? v.viewDidAppear() : undefined);
      });
    }
    this.$tabPanelsWrapper.append(v.el);
    return (typeof v.viewDidAppear === 'function' ? v.viewDidAppear() : undefined);

  }

  _updateAccessibilityAttributes() {
    this.$el.attr('role', 'tablist');

    this.tabNames.forEach((tabName, index) => {
      const $tabEl = $(this.tabPages[index]);
      if ($tabEl) {
        const $tabLink = $tabEl.find('a');
        const $tabPanelView = (this.views[tabName]) ? this.views[tabName].$el : undefined;

        $tabEl.attr('role', 'presentation');
        $tabLink.attr('role', 'tab');

        if ($tabPanelView) {
          const tabId = this._tryGetOrSetId($tabLink, 'tab_');
          const tabPanelId = this._tryGetOrSetId($tabPanelView, 'tab_panel_');

          $tabPanelView.attr('role', 'tabpanel');
          $tabLink.attr('aria-controls', tabPanelId);
          $tabPanelView.attr('aria-labelledby', tabId);
        }
      }
    });
  }

  _tryGetOrSetId($el, idPreface) {
    let id;
    if (!$el.attr('id')) {
      id = _.uniqueId(idPreface);
      $el.attr('id', id);
    } else {
      id = $el.attr('id');
    }
    return id;
  }

  setViewForCurrentTab(v) {
    const currentTabName = this.selectedTabName();
    return this.setViewForTabName(v, currentTabName);
  }

  showTab(tabName) {
    // Hide all other views
    let currentHeight;
    for (const key in this.views) {
      if (this.views[key] && key !== this.currentTabName) {
        this.views[key].$el.hide();
      }
    }

    const currView = this.views[this.currentTabName];
    const newView = this.views[tabName];

    if (currView) {
      currentHeight = currView.$el.height();
      //fade out the current view if it exists
      currView.$el.css({position: 'absolute'});
      currView.$el.fadeOut(this.fadeDuration, () => {
        return currView.$el.css({position: ''});
      });
    }

    if (newView) {
      // Use the new view's height to ensure it's container is the right height since
      // we'll be setting both views that we're switching out to position: absolute. Without this
      // the container would think it's empty and shrink to zero height which can cause weird affects if the
      // views rely on the container for a background or something.
      newView.$el.show();

      this.triggerAdjustment();

      const newHeight = newView.$el.height();

      // Use the larger of the two heights to maintain the container size when the new height is smaller than the
      // current height.
      if (this.$tabPanelsWrapper != null) {
        this.$tabPanelsWrapper.css({height: Math.max(currentHeight, newHeight)});
      }

      newView.$el.hide();

      // Fade in the new view
      newView.$el.css({position: 'absolute'});
      newView.$el.fadeIn(this.fadeDuration, () => {
        newView.$el.css({position: ''});
        if (this.$tabPanelsWrapper != null) {
          this.$tabPanelsWrapper.css({height: ''});
        }
        return (typeof newView.viewDidAppear === 'function' ? newView.viewDidAppear() : undefined);
      });
    }
  }

  selectedTabName() {
    return this.$('li.selected').attr('data-tab');
  }

  selectedTabIndex() {
    return this.$('li.selected').index();
  }

  onDestroy() {
    // destroying all content views
    Object.values(this.views).forEach((view) => {
      return view.destroy();
    });
  }
}

module.exports = TabBar;
