import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitIcon(cssVars: CSSStyleDeclaration) {
  return {
    icon: {
      fontSize: {
        s: getCssValue('--ax-icon-fontSize-s', cssVars),
        m: getCssValue('--ax-icon-fontSize-m', cssVars),
        l: getCssValue('--ax-icon-fontSize-l', cssVars),
        xl: getCssValue('--ax-icon-fontSize-xl', cssVars)
      }
    }
  };
}
