const { Behavior } = require('Marionette');
const Behaviors = require('@common/libs/behaviors/Behaviors');
const TranslationStateEnum = require('@training/apps/training/enums/TranslationStateEnum');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');

/**
 * TranslationToggler Behavior
 * This behavior is intended to allow toggling between google auto-translated content and original, untranslated content
 * for display.
 *
 * Parameters:
 * rtlClassname (OPTIONAL) - A CSS classname applied to translated content to be displayed in RTL direction. Will
 *                             default to 'rtl-content' when not provided
 * ltrClassname (OPTIONAL) - A CSS classname applied to content to be displayed in LTR direction. Will default to
 *                             'ltr-content' when not provided. Useful when the original user/tenant language was RTL,
 *                             and content was auto-translated to English, for example.
 * htmlEncode (OPTIONAL) - set to true if the content should be encoded. Defaults to false. This encodes both the
 *                        original and the translated content. If the encoding is inconsistent then it's better to
 *                        take care of that in the translationModel before passing it in
 * clickSelector (REQUIRED) - A CSS selector for the toggle button in the associated view's template that when
 *                              clicked, content will be toggled. Used for setting up the click callback here
 * $content (REQUIRED) - A jquery element wrapping the content to be displayed. Used to toggle
 *                                what text is displayed within this element
 * translationModel (REQUIRED) - Model class with the following attributes/structure:
 *  {
 *    autoTranslatedContent: {
 *      translatedContent: 'blah' // The auto-translated text content
 *      translatedLanguage: 'EN' // The lang code that the content was translated into
 *    }
 *    originalContent {
 *      content: 'test' // Original text content
 *      language: 'EN', // The lang code associated with the original text content
 *    }
 *  }
 */
Behaviors.TranslationToggler = class TranslationToggler extends Behavior {
  initialize(options = {}) {
    ({
      translationModel: this.translationModel,
      ltrClassname: this.ltrClassname = 'ltr-content',
      rtlClassname: this.rtlClassname = 'rtl-content',
      htmlEncode: this.htmlEncode = false
    } = options);

    // When the user has a RTL language, they are served a CSS stylesheet named app-desktop-rtl.css. It literally
    // redefines the .ltr-content and .rtl-content classes so that they are flipped in reverse from what they would
    // be for a user whose language is LTR. Changing them in the CSS is going to break stuff so instead, we'll
    // detect if the user has a RTL language and then swap the two classnames used by the toggler.
    if (I18n.isRtlLanguage(apps.auth.session.user.get('language'))) {
      [this.ltrClassname, this.rtlClassname] = [this.rtlClassname, this.ltrClassname]; // destructuring assignment FTW
    }

    this._onToggleClick = this._onToggleClick.bind(this);

    // We should never be applying this behaviour unless there's a translation in the model. Let's enforce that.
    if (this.translationModel == null
      || this.translationModel.get('autoTranslatedContent') == null
      || this.translationModel.get('originalContent') == null) {
      throw new Error('You must provide the TranslationToggler behavior with a model containing autoTranslatedContent and originalContent attributes');
    }

    // since we know that a translation was provided, the initial state will be to show the translated content
    this.currentlyShowing = TranslationStateEnum.TRANSLATION_STATE;

    this.$content = this.getOption('$content');
  }

  ui() {
    return {
      toggleBtn: this.getOption('clickSelector')
    };
  }

  events() {
    return {
      'click @ui.toggleBtn': this._onToggleClick,
      'keydown @ui.toggleBtn': (e) => {
        if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
          this._onToggleClick(e);
        }
      }
    };
  }

  onAttach() {
    this._translateContent();
  }

  _onToggleClick(e) {
    e.stopPropagation();

    // flip the state
    this.currentlyShowing = (this.currentlyShowing === TranslationStateEnum.ORIGINAL_STATE)
      ? TranslationStateEnum.TRANSLATED_STATE
      : TranslationStateEnum.ORIGINAL_STATE;

    this._translateContent();
  }

  _translateContent() {
    this._toggleContent();
    this._toggleRtl();
    this._toggleTranslationBtnText();
  }

  _toggleRtl() {
    let isRtl = false;
    if (this.currentlyShowing === TranslationStateEnum.ORIGINAL_STATE) {
      isRtl = I18n.isRtlLanguage(this.translationModel.get('originalContent').language);
    } else {
      isRtl = I18n.isRtlLanguage(this.translationModel.get('autoTranslatedContent').translatedLanguage);
    }
    this.$content.toggleClass(this.ltrClassname, !isRtl);
    this.$content.toggleClass(this.rtlClassname, isRtl);
  }

  _toggleContent() {
    let content = '';
    if (this.currentlyShowing === TranslationStateEnum.ORIGINAL_STATE) {
      content = this.translationModel.get('originalContent').content;
    } else {
      content = this.translationModel.get('autoTranslatedContent').translatedContent;
    }

    if (this.htmlEncode) {
      content = HTMLHelpers.htmlEncode(content);
    }

    content = HTMLHelpers.lineBreakToBr(content);

    this.$content.html(content);
  }

  _toggleTranslationBtnText() {
    const buttonLabel = (this.currentlyShowing === TranslationStateEnum.ORIGINAL_STATE)
      ? I18n.t('contentTranslation.seeTranslation')
      : I18n.t('contentTranslation.seeOriginal');
    const buttonAriaLabel = (this.currentlyShowing === TranslationStateEnum.ORIGINAL_STATE)
      ? I18n.t('contentTranslation.seeTranslationAriaLabel')
      : I18n.t('contentTranslation.seeOriginalAriaLabel');

    this.ui.toggleBtn.text(buttonLabel);
    this.ui.toggleBtn.attr('aria-label', buttonAriaLabel);
  }

};

module.exports = Behaviors.TranslationToggler;
