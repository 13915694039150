const Backbone = require('Backbone');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const BreadcrumbCollection = require('@common/data/collections/BreadcrumbCollection');
const { createBaseCommunityBreadcrumb } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');
const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const CommunityHeaderSimpleView = require('@training/apps/search/CommunityHeaderSimpleView');

class CommunityHeaderSimpleController extends LayoutController {
  initialize(options = {}) {
    ({
      community: this.community
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: CommunityHeaderSimpleView,
      model: this.community
    };
  }

  regionControllers() {
    return {
      breadcrumb: {
        viewDefinition: {
          ViewClass: BreadcrumbCollectionView,
          collection: new BreadcrumbCollection(createBaseCommunityBreadcrumb()),
          doOnClick: (model) => {
            if (model && model.get('target')) {
              Backbone.history.navigate(model.get('target'), { trigger: true });
            }
          },
          hideLastCrumb: true
        }
      }
    };
  }
}

module.exports = CommunityHeaderSimpleController;
