const Backbone = require('Backbone');

const File = require('@common/libs/file/File');
const FileHelpers = require('@common/libs/helpers/app/FileHelpers');

class MediaViewModel extends Backbone.Model {
  static fromMedia(mediaFile) {
    if (!mediaFile) {
      throw new Error('The media file provided is missing.');
    }

    const file = mediaFile.get('originalFile') instanceof File ? mediaFile.get('originalFile').toJSON() : mediaFile.get('originalFile');
    const uuid = file.uuid;
    const name = file.originalFileName;
    const type = MediaViewModel.inferTypeFromName(name);
    const icon = MediaViewModel.withIconFromType(type);
    const path = file.path;

    return new MediaViewModel({
      uuid,
      name,
      icon,
      type,
      path
    });
  }

  static withIconFromType(type) {
    const icon = `icon-${ type }`;
    return icon;
  }

  static inferTypeFromName(name) {
    return FileHelpers.getFileType(name);
  }

  getName() {
    return this.get('name');
  }

  getIcon() {
    return this.get('icon');
  }

  getFileType() {
    return this.get('type');
  }

  getPath() {
    return this.get('path');
  }

  getUUID() {
    return this.get('uuid');
  }
}

module.exports = MediaViewModel;
