const { ItemView } = require('Marionette');

const template = `
  <% if (showIcon) { %>
    <span role="img" class="page-header__icon <%- iconClass %>"
      <% if (showIconLabel) { %>
        aria-label="<%- iconLabel %>"
      <% } else { %>
        aria-hidden="true"
      <% } %>
    ></span>
  <% } %>

  <div class="page-header__title <%- textClass %>">
    <h1 class="inner-heading"><%- text %></h1>

    <% if (showTooltip) { %>
      <button class="page-header__how-to js-how-to-link" aria-label="<%- ariaLabel %>">
        <div>
          <span class="icon icon-question_sign"></span>
          <% if (showTooltipLabel) { %>
            <span class="page-header__how-to-tip-label"><%- tooltipLabel %></span>
          <% } %>
        </div>
      </button>
    <% } %>
  </div>
`;

module.exports = (options = {}) => {
  const {
    pageClassName = '',
    iconClass = '',
    iconLabel = '',
    text = '',
    textClass = '',
    showTooltip = false,
    tooltipLabel = '',
    tooltipEvents = {},
    ariaLabel = ''
  } = options;

  return {
    viewDefinition: {
      ViewClass: ItemView,
      tagName: 'div',
      className: `page-header ${ pageClassName }`,
      template,
      templateHelpers: {
        showIcon: iconClass.length > 0,
        iconClass,
        showIconLabel: iconLabel.length > 0,
        iconLabel,
        text,
        textClass,
        showTooltip,
        showTooltipLabel: tooltipLabel.length > 0,
        tooltipLabel,
        ariaLabel
      },
      events: tooltipEvents
    }
  };
};
