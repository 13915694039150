const Marionette = require('Marionette');
const _ = require('underscore');
const Backbone = require('Backbone');
const $os = require('detectOS');
const KeyCode = require('@common/data/enums/KeyCode');

const FilterableSelectorEmptyView = require('@common/components/filterable_selector/FilterableSelectorEmptyView');
const FilterableSelectorItem = require('@common/components/filterable_selector/FilterableSelectorItem');

require('@common/libs/behaviors/scrollable/Scrollable');


class FilterableSelector extends Marionette.CompositeView {
  constructor(options = {}) {
    options.origCollection = options.collection;
    options.collection = new Backbone.VirtualCollection(options.collection,
      {destroy_with: options.origCollection});

    super(options);
    this.closeMenu = this.closeMenu.bind(this);
    ({
      commands: this.commands,
      vent: this.vent,
      emptyText: this.emptyText,
      noDefault: this.noDefault,
      labelText: this.labelText = '',
      titleText: this.titleText = '',
      placeholderText: this.placeholderText = '',
      showDropdownIcon: this.showDropdownIcon = false,
      labelId: this.labelId
    } = options);
    // Must be null, some pages (ex. LeaderZone > Compare) will not load properly if non-null.
    this.filterText = null;

    this.setFilterText = _.debounce(this.setFilterText, 300);
    this.onShowDropdown = _.throttle(this.onShowDropdown, 300, {trailing: false});
  }

  getTemplate() {
    return `\
<label for="<%- id %>" class="filterable-selector-label"><%- labelText %></label>

<div class="filterable-selector">
  <div class="input-container">
    <div class="title-wrapper">
      <a class="css-close-filter js-close-filter" href="#"></a>
      <div><%- titleText %></div>
    </div>
    <div class="input-wrapper clearfix">
      <% if (${ this.showDropdownIcon }) { %>
        <%= axSearch({
          classNameInput: 'filter-current-text qa-input-filter',
          fullWidth: true,
          placeholder: placeholderText,
          iconClassLeft: 'icon-filter',
          iconClassRight: 'icon-caret_down',
          showDropdownIcon: ${ this.showDropdownIcon },
          id: id
        }) %>
      <% } else { %>
        <%= axSearch({
          classNameInput: 'filter-current-text qa-input-filter',
          fullWidth: true,
          placeholder: placeholderText,
          iconClassLeft: 'icon-filter'
        }) %>
      <% }%>
    </div>
  </div>
  <div class="dropdown-container absolute-fit-parent">
    <ul class="js-collection-view dropdown dropdown-listing"></ul>
  </div>
</div>
<div class="filter-content-filler"></div>\
`;
  }

  initialize(options = {}) {
    this.configureCollection = this.configureCollection.bind(this);
    this._collection = options.origCollection;
    this._selectedState = options.selectedState || this._collection;
    this.configureCollection(options);
    this.collection = options.collection;
    this._selectInitialItemById(options.initSelectedItemId);
    super.initialize(options);

    this.listenTo(this._selectedState, 'selected', this.onStateSelected);
    this.listenTo(this._selectedState, 'selected:item:change', this.onSelectedStateChange);
  }

  ui() {
    return {
      childViewContainer: '.js-collection-view',
      inputWrapper: '.input-wrapper',
      searchIcon: '.icon',
      textInput: '.filter-current-text',
      inputContainer: '.input-container',
      dropdownContainer: '.dropdown-container',
      selector: '.filterable-selector',
      contentFiller: '.filter-content-filler',
      label: '.filterable-selector-label'
    };
  }

  events() {
    return {
      'click .input-container': 'onShowDropdown',
      'click .js-clear': 'onClearInput',
      'click .js-close-filter': 'onCloseFilter',
      'click .filterable-selector-item a': 'onClickItem',
      'click .dropdown-container': 'onDropdownContainerClick',

      'keydown .filter-current-text': 'onInputKeydown',
      'keyup .filter-current-text': 'onInputKeyup',

      'touchstart .js-clear-input': 'onClearInput',
      'touchstart .dropdown-container': 'blurInput',

      'blur .filter-current-text': 'onInputBlur',
      'focus .filter-current-text': 'onInputFocus'
    };
  }

  collectionEvents() {
    return {'update reset': 'onCollectionResetUpdate'};
  }

  behaviors() {
    return {
      Scrollable: {
        scrollableContainer: '.dropdown-container'
        // TODO - Niaeve approach to just rely on $os.mobile, need to consider a more flexible solution.
        // TODO - Disabling entirely until a better UX implementation can be figured out to avoid scroll bar jumping
        // triggerFreezeOnHover: !$os.mobile
      }
    };
  }

  childViewContainer() {
    return '.js-collection-view';
  }

  // Explicitly declaring getters/setters to fix issues with the Manager/Behaviors page
  get childView() {
    return FilterableSelectorItem;
  }

  set childView(view) {
    if (view) {
      // Ignore this - childView will always be FilterableSelectorItem
    }
  }

  get emptyView() {
    return FilterableSelectorEmptyView;
  }

  set emptyView(view) {
    if (view) {
      // Ignore this - emptyView will always be FilterableSelectorEmptyView
    }
  }

  configureCollection(options) {
    if (!options.collection.collection) {
      options.collection = new Backbone.VirtualCollection(options.collection,
        {destroy_with: this});
    }
  }

  templateHelpers() {
    return {
      labelText: this.labelText,
      titleText: this.titleText,
      placeholderText: this.placeholderText,
      id: this.labelId
    };
  }

  childViewOptions(item, index) {
    // Determine if this is the emptyView and provide the appropriate viewOptions
    if ((index === 0) && (this._collection.length === 0)) {
      return {emptyText: this.emptyText};
    }
    return undefined;
  }

  onRenderTemplate() {
    this.enableLabel();
    this.resetSelectedItem();
  }

  onShowDropdown() {
    this._showing = true;

    if (!this._opened) {
      this.openMenu();
    }

    if (this.ui.inputWrapper) {
      if ($os.ios) {
        // bluring and focusing to prevent screen moving up when iOS keyboard is shown
        this.blurInput();
        this.ui.textInput.trigger('focus');
      }
      this.ui.inputWrapper.addClass('focused');
    }

    this._showing = false;
    // This is a required return (LeaderZone > Compare doesn't work without it)
    return this._showing;
  }

  onInputFocus() {
    this.onShowDropdown();
  }

  onInputBlur() {
    this.ui.inputWrapper.removeClass('focused');
  }

  onHideDropdown() {
    if (!this._showing) {
      this.restoreMenu();
    }
  }

  onClearInput(e) {
    if (!this._opened) {
      this.selectItem(null);
    } else {
      this.clearInput();
    }

    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  onCloseFilter(e) {
    this.clearInput();
    this.restoreMenu();

    e.preventDefault();
    e.stopPropagation();
  }

  onInputKeydown(e) {
    if (e.which === KeyCode.UP) {
      if (!e.shiftKey) {
        return false;
      }
    } else if (e.which === KeyCode.DOWN) {
      if (!e.shiftKey) {
        return false;
      }
    } else if (e.which === KeyCode.TAB) {
      // One way or another (tab-navigating within the page, alt-tabbing between pages, etc) we should be leaving the selector - so close it
      this.restoreMenu();
    } else if (e.which === KeyCode.ENTER) {
      this.onEnter();
    }
    return undefined;
  }

  onInputKeyup(e) {
    if (!this._opened) {
      this.openMenu();
    }
    const invalidKeys = [KeyCode.LEFT, KeyCode.RIGHT, KeyCode.SHIFT, KeyCode.TAB];

    const textInput = (this.ui.textInput && this.ui.textInput.val()) || '';
    this._toggleClearInput(textInput.length > 0);

    if (e.which === KeyCode.UP) {
      if (!e.shiftKey) {
        this.onUpArrow(e);
      }
    } else if (e.which === KeyCode.DOWN) {
      if (!e.shiftKey) {
        this.onDownArrow(e);
      }
    } else if (e.which === KeyCode.ESCAPE) {
      this.onCloseFilter(e);
    } else if (invalidKeys.indexOf(e.which) === -1) {
      // Filter if the user simply isn't navigating the string in the search field
      this.onFilter();
    }
  }

  onClickItem(e) {
    const modelCid = $(e.currentTarget).data('cid');
    const model = this._collection.get(modelCid);

    this.selectItem(model);
    this.closeMenu();

    e.preventDefault();
    e.stopPropagation();
  }

  // This is required so clicks inside the dropdown (on tree scrollbar for example)
  // don't trigger a 'rootClicked' event in ManagerBaseApp causing dropdown to close
  onDropdownContainerClick(e) {
    e.stopPropagation();
  }

  onCollectionResetUpdate() {
    if (this.filterText == null) {
      this.resetSelectedItem();
    }
  }

  onFilter() {
    this.setFilterText(this.ui.textInput.val());
  }

  onUpArrow(e) {
    e.preventDefault();
    e.stopPropagation();

    const $currentLi = this.getCurrentItemEl();
    const $prevLi = this.getPreviousItemEl();

    $currentLi.removeClass('selected ax-button--branded');
    $prevLi.addClass('selected ax-button--branded');

    this._scrollIntoView($prevLi);
  }

  onDownArrow(e) {
    e.preventDefault();
    e.stopPropagation();

    const $currentLi = this.getCurrentItemEl();
    const $nextLi = this.getNextItemEl();
    $currentLi.removeClass('selected ax-button--branded');
    $nextLi.addClass('selected ax-button--branded');

    this._scrollIntoView($nextLi);
  }

  onEnter() {
    const $selectedItem = this.getSelectedItemEl();

    if ($selectedItem.length === 0) {
      this.restoreMenu();
    } else {
      const selectedCid = $selectedItem.find('a').data('cid');
      const model = this._collection.get(selectedCid);

      if (model) {
        this.selectItem(model);
        this.closeMenu();
      }
    }
  }

  onStateSelected() {
    this.setSelectedItemInput();
    this.trigger('change');
  }

  onSelectedStateChange() {
    this.setSelectedItemInput();
    this.trigger('change');
  }

  getSelectableItemEls() {
    return this.$('.filterable-selector-item');
  }

  getFirstItemEl() {
    return this.getSelectableItemEls().filter(':first');
  }

  getSelectedItemEl() {
    return this.getSelectableItemEls().filter('.selected');
  }

  getCurrentItemEl() {
    let $currentItem = this.getSelectedItemEl();

    if ($currentItem.length === 0) {
      $currentItem = this.getFirstItemEl();
    }

    return $currentItem;
  }

  getNextItemEl() {
    const $items = this.getSelectableItemEls();

    let selectedIndex = $items.index(this.getCurrentItemEl());
    if (selectedIndex === ($items.length - 1)) {
      selectedIndex = -1;
    }

    return $($items.get(selectedIndex + 1));
  }

  getPreviousItemEl() {
    const $items = this.getSelectableItemEls();

    const selectedIndex = $items.index(this.getCurrentItemEl()) || $items.length;

    return $($items.get(selectedIndex - 1));
  }

  getSelectedItem() {
    return this._selectedState.getSelected();
  }

  getFallbackSelectedItem() {
    if (this.noDefault) {
      return this.collection.at(0);
    }

    return null;
  }

  selectItem(item) {
    this.setSelectedItem(item);
    this.setSelectedItemInput(item);
    this.updateClearInput(item);
  }

  unselectItem(item) {
    this.unsetSelectedItem(item);
    this.setSelectedItemInput(item);
    this.updateClearInput(item);
  }

  // Saves the selected item on the base collection for when it's needed again
  // and fires the change event for the form editors.
  setSelectedItem(item) {
    if (item) {
      this._selectedState.setSelected(item);
    } else {
      this.unsetSelectedItem(item);
    }
  }

  unsetSelectedItem(item) {
    this._selectedState.unsetSelected(item);
  }

  enableLabel() {
    if (this.labelText && this.labelText.length) {
      this.ui.label.addClass('enabled');
    }
  }

  resetSelectedItem() {
    let item = this.getSelectedItem();
    if (!item) {
      item = this.getFallbackSelectedItem();
    }
    this.selectItem(item);
  }

  setFilterText(text = '') {
    const oldText = this.filterText;
    this.filterText = text;
    if (oldText !== text) {
      this.filterChildren(text);
    }
  }

  setSelectedItemInput(item = this.getSelectedItem()) {
    this.setInputText((item && _.isFunction(item.toOption) && item.toOption().value) || null);
  }

  openMenu() {
    this._opened = true;

    this.listenTo(this.vent, 'rootClicked', (e) => {
      if ((e || {}).target !== this.ui.selector[0]) {
        this.restoreMenu();
      }
    });
    this.listenTo(this.vent, 'filterableSelector:show:dropdown', (filterableSelector) => {
      if (filterableSelector !== this) {
        this.restoreMenu();
      }
    });

    this.ui.contentFiller.css({
      height: this.ui.inputContainer.outerHeight()
    });

    this.$el.addClass('opened');

    // Highlight all the text automatically
    this.setInputText(this.filterText);
    this._toggleClearInput((this.filterText && this.filterText.length) > 0);
    this.selectInput();

    this.adjustDropdownPosition();

    this.vent.trigger('filterableSelector:show:dropdown', this);
    if ($os.mobile) {
      this.triggerMethod('freeze:scrollable');
    }
  }

  restoreMenu() {
    if (this._opened) {
      // Restore selected item
      this.setSelectedItemInput();
      this.closeMenu();
    }
  }

  closeMenu() {
    this._opened = false;
    this.stopListening(this.vent, 'rootClicked');
    this.$el.removeClass('opened');
    if (!this.hasAdvancedFilterApplied) {
      this.updateClearInput(this.getSelectedItem());
    }
    if (this.ui.textInput) {
      this.blurInput();
    }
    this.vent.trigger('filterableSelector:hide:dropdown', this);
    this.triggerMethod('unfreeze:scrollable');
  }

  clearInput() {
    this.resetScroll();
    this.setFilterText();
    this.setInputText();
    this._toggleClearInput(false);
    this.selectInput();
    this.ui.textInput.trigger('focus');
  }

  setInputText(text) {
    // NOTE: Sometimes the 'text' parameter will explicitly be null (rather than undefined)
    // so a default value in the declaration will not work
    const newText = text || '';
    if (this.ui.textInput && this.ui.textInput.val() !== newText) {
      this.ui.textInput.val(newText);
    }
  }

  selectInput() {
    if (this.ui.textInput && this.ui.textInput.val().length > 0) {
      this.ui.textInput.trigger('select');
    }
  }

  blurInput() {
    if (this.ui.textInput) {
      this.ui.textInput.trigger('blur'); // Defocuses textbox too; needed since we use 'mousedown'
    }
  }

  resetScroll() {
    if (this.ui && this.ui.dropdownContainer) {
      this.ui.dropdownContainer.scrollTop(0);
    }
  }

  adjustDropdownPosition() {
    if (this.ui && this.ui.dropdownContainer) {
      this.ui.dropdownContainer.css({
        top: this.ui.inputContainer.outerHeight()
      });
    }
  }

  filterChildren(...args) {
    if (!this._filterChildren) {
      this._filterChildren = _.debounce((filterText = '') => {
        this.resetScroll();
        this.collection.updateFilter((item) => {
          let itemMatches = true;
          if (_.isFunction(item.matches)) {
            itemMatches = item.matches(filterText);
          }
          return itemMatches;
        });
      }, 300);
    }

    this._filterChildren(...args || []);
  }

  updateClearInput(item) {
    this._toggleClearInput((item && !this.noDefault) || false);
  }

  _toggleClearInput(toggle) {
    if (this.ui.selector) {
      this.ui.selector.toggleClass('show-clear', toggle);
    }
  }

  _scrollIntoView($item) {
    if (!$item || $item.length === 0) {
      return;
    }
    if ($os.browser === 'safari' || $os.browser === 'ie'
      || ($os.browser === 'firefox' && Number($os.version) < 58)) {
      $item[0].scrollIntoView(false);
    } else {
      $item[0].scrollIntoView({
        behavior: 'auto',
        block: 'center'
      });
    }
  }

  _selectInitialItemById(id) {
    if (!id) {
      return;
    }
    const selectedModel = this.collection.get(id);
    this.selectItem(selectedModel);
  }

  onDestroy() {
    // Clean up the listener set in openMenu
    this.stopListening(this.vent, 'filterableSelector:show:dropdown');
  }
}

module.exports = FilterableSelector;
