const logging = require('logging');
const Backbone = require('Backbone');
const { ItemView } = require('Marionette');

const AxonifyExceptionCode = require('AxonifyExceptionCode');

const I18n = require('@common/libs/I18n');
const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const RedirectingAbortedAuthentication = require('@training/apps/auth/exceptions/RedirectingAbortedAuthentication');

class LoginErrorPage extends ItemView {
  get template() {
    return require('@training/apps/auth/templates/LoginErrorPage.html');
  }

  events() {
    return {'click .axon-button.continue': 'continue'};
  }

  initialize() {
    // get query string params
    const searchQueryParams = UrlHelpers.getQueryParams(window.location.search != null ? window.location.search : '');
    const hashQueryParams = UrlHelpers.getQueryParams(window.location.hash != null ? window.location.hash : '');
    const queryParams = $.extend(true, {}, searchQueryParams, hashQueryParams);

    // reset the hash to prevent reload from showing it again.
    Backbone.history.navigate('', { replace: true });

    this.errorCode = queryParams.errorCode;
    this.redirectURL = queryParams.redirectURL;
    this.loginPrompt = queryParams.loginPrompt;

    // get text related to errorCode unless its generic 9028
    if (this.errorCode != null && parseInt(this.errorCode, 10) !== AxonifyExceptionCode.SERVER_ERROR_SSO_GENERIC_ERROR) {
      this.errorMsg = I18n.t(`errors.auth.${ this.errorCode }`);
    }

    // if errorMsg is null, set it to the generic msg
    // this can occur if no errorCode was passed in or
    // the string file does not contain a msg for the passed in
    // errorCode
    if (this.errorMsg == null) {
      this.errorMsg = I18n.t('errors.auth.genericError');
    }
  }

  templateHelpers() {
    return {
      errorMsg: this.errorMsg
    };
  }

  onShow() {
    logging.info('LoginErrorPage - onShow');
    window.app.layout.setTitle(I18n.t('menu.apps.training'));
  }

  // this method will make the user get redirected to
  // the passed in url or back to login if no url was
  // passed in
  continue() {
    if (NativeBridgeHelpers.nativeAuthRequired()) {
      const params = {};

      if (this.errorCode != null) {
        params.errorCode = this.errorCode;
      }

      NativeBridgeHelpers.authenticationRequired(params);

      // check for passed in url
    } else if (this.redirectURL != null && this.redirectURL.length > 0) {
      UrlHelpers.replaceUrl(this.redirectURL);
    } else {
      RedirectingAbortedAuthentication.catchAndLog(() => {
        window.apps.auth.redirectToLoginPage({ loginPrompt: this.loginPrompt });
      });
    }

    return false;
  }
}

module.exports = LoginErrorPage;
