const Marionette = require('Marionette');
const logging = require('logging');
const PreviewUnavailableView = require('@common/components/training_modules/PreviewUnavailableView');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

class TinCanView extends Marionette.ItemView {
  className() {
    return 'full-height tincan-wrap full-height';
  }

  getTemplate() {
    return '\
    <iframe class="tincan-content full-width full-height" name="tincan-content"></iframe>\
    ';
  }

  ui() {
    return {
      iframe: 'iframe'
    };
  }

  constructor(...args) {
    super(...args);
    this._getPackage = this._getPackage.bind(this);
  }

  initialize(options = {}) {
    this.language = options.language;
    this.previewingUser = options.previewingUser;
    this.packageProvider = options.packageProvider;
    if (this.packageProvider == null) {
      this.packageProvider = this._getPackage;
    }

    this._cleanupLectoraStorage();
  }

  _cleanupLectoraStorage() {
    let e, key, keyIndex, keysToDelete;
    let c = null;
    const date = new Date();
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
    const arCookies = document.cookie.split(';');

    // delete cookie
    for (let x = 0; x < arCookies.length; x++) {
      c = arCookies[x] || '';
      if (c.indexOf('Lectora') > -1) {
        document.cookie = c + '=; expires=' + date.toGMTString();
      }
    }

    // delete sessionStorage
    if ((typeof (Storage) !== 'undefined') && window.sessionStorage) {
      try {
        keysToDelete = [];
        for (keyIndex = 0; keyIndex < window.sessionStorage.length; keyIndex++) {
          key = window.sessionStorage.key(keyIndex) || '';
          if (key.indexOf('Lectora') > -1) {
            keysToDelete.push(key);
          }
        }

        for (key of Array.from(keysToDelete)) {
          window.sessionStorage.removeItem(key);
        }
      } catch (error) {
        e = error;
        logging.error(e);
      }
    }

    // delete localStorage
    if ((typeof (Storage) !== 'undefined') && window.localStorage) {
      try {
        keysToDelete = [];
        for (keyIndex = 0; keyIndex < window.localStorage.length; keyIndex++) {
          key = window.localStorage.key(keyIndex);
          if (key.indexOf('Lectora') > -1) {
            keysToDelete.push(key);
          }
        }

        for (key of Array.from(keysToDelete)) {
          window.localStorage.removeItem(key);
        }
      } catch (error1) {
        e = error1;
        logging.error(e);
      }
    }
  }

  _getPackage() {
    const links = this.model.moduleLinks;
    const link = links.getPackageForLanguage(this.language);
    return link.get('tincanPackage');
  }

  onRender() {
    const tincanPackage = this.packageProvider();
    if (tincanPackage != null) {
      const domain = `${ window.location.hostname }`;
      const baseURL = `${ window.location.protocol }//${ domain }`;

      const endpoint = `https://${ domain }/axonify/xapi/`;
      const actor = `{"objectType":"Agent","name":"${ this.previewingUser.get('fullname') }","mbox":"mailto://${ this.previewingUser.id }@${ domain }"}`;
      const activityId = `${ UrlHelpers.getLocationOrigin() }/tincan/id/${ tincanPackage.id }`;
      const time = new Date().getTime();

      const launchURL = `${ baseURL }${ tincanPackage.launch }?endpoint=${ encodeURIComponent(endpoint) }`
        + `&activity_id=${ encodeURIComponent(activityId) }&actor=${ encodeURIComponent(actor) }`;

      this.ui.iframe.attr('src', `${ launchURL }&cacheBuster=${ time }`);
    } else {
      const preview = new PreviewUnavailableView().render().$el;
      this.ui.iframe.replaceWith(preview);
    }
  }
}

module.exports = TinCanView;
