const env = require('env');
const logging = require('logging');
const { sendLog } = require('LoggingService');

const { Collection } = require('Backbone');
const {
  ItemView,
  isNodeAttached
} = require('Marionette');
const { intersection } = require('underscore');

const I18n = require('@common/libs/I18n');

const Language = require('@common/data/models/Language');

const AxonSelect = require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');

class DisabledCookiesWarningView extends ItemView {
  id() {
    return 'disabled-cookies-wrapper';
  }

  getTemplate() {
    return require('./DisabledCookiesWarningTemplate.html');
  }

  templateHelpers() {
    return {
      showLanguageSelector: this.showLanguageSelector,
      isInApp: this.isInApp,
      helpContact: (() => {
        let helpEmail = this.tenantPropertyPool.getProperty('helpEmail');
        const helpPhone = this.tenantPropertyPool.getProperty('helpPhone');
        if (helpEmail) {
          helpEmail = `<a href="mailto:${ helpEmail }">${ helpEmail }</a>`;
        }
        if (helpEmail && helpPhone) {
          return I18n.t('login.helpEmailAndPhone', {
            helpEmail: `<span>${ helpEmail }</span>`,
            helpPhone: `<span>${ helpPhone }</span>`
          });
        } else if (helpEmail || helpPhone) {
          return I18n.t('login.helpEmailOrPhone',
            { helpEmailOrPhone: `<span>${ helpEmail || helpPhone }</span>` });
        }
        return '';

      })()
    };
  }

  ui() {
    return {
      langSelector: '.language-selector'
    };
  }

  triggers() {
    return {
      'click .retry': 'click:retry'
    };
  }

  constructor(options = {}) {
    super(options);

    ({
      tenantPropertyPool: this.tenantPropertyPool,
      isInApp: this.isInApp
    } = options);

    this.selectedLang = I18n.getLocale();
    this.supportedLanguages = intersection(
      this.tenantPropertyPool.getProperty('languages'), env.settings.supportedLocales
    );
    this.showLanguageSelector = this.supportedLanguages.length > 1;

    sendLog({
      message: 'Browser Cookies disabled!'
    });

    logging.warn('The browser doesn\'t have Cookies enabled for Axonify');
  }

  onRender() {
    if (this.showLanguageSelector && isNodeAttached(this.$el[0])) {
      this.renderLangSelector();
    }
  }

  onAttach() {
    if (this.showLanguageSelector) {
      this.renderLangSelector();
    }
  }

  renderLangSelector() {
    if (this.langSelector != null) {
      this.stopListening(this.langSelector);
      this.langSelector.destroy();
    }

    this.langSelector = new AxonSelect({
      el: this.ui.langSelector,
      options: {
        collection: new Collection(this.supportedLanguages, { model: Language })
      }
    });

    this.langSelector.render();
    this.langSelector.setValue(this.selectedLang);

    this.listenTo(this.langSelector, 'change:selection', (formEditor) => {
      this.selectedLang = formEditor.getValue().id;
      I18n.setLocale(this.selectedLang, {
        always: () => {
          if (this.isDestroyed) {
            return;
          }
          this.render();
        }
      });
    });
  }
}

module.exports = DisabledCookiesWarningView;
