const Backbone = require('Backbone');

class Group extends Backbone.Model {
  parse(res) {
    return res.entity || res;
  }

  toOption() {
    return {
      id: this.id,
      value: this.id,
      label: this.get('name')
    };
  }
}

module.exports = Group;
