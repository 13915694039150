// Module
var code = `<ul class="metadata-group">
  <% if (isReference()) { %>
    <li class="metadata-item">
      <h3><%- t('general.url') %></h3>
      <div class="metadata-value"><input class="parent-width normal-cursor" disabled="true" value="<%- url %>"/></div>
    </li>
  <% } %>
  <li class="metadata-item">
    <h3><%- t('discover.metadata.attachments') %> <span class="metadata-value">(<%- getDocumentCount() %>)</span></h3>
    <div class="metadata__attachments"></div>
  </li>
  <li class="metadata-item">
    <h3><%- t('general.language') %></h3>
    <div class="metadata-value"><%= getLanguage(language) %></div>
  </li>
  <li class="metadata-item metadata-item--home-community hidden">
    <h3><%- t('discover.metadata.community') %></h3>
    <div class="metadata-home-community"></div>
  </li>
  <li class="metadata-item metadata-item--shared-communities hidden">
    <h3><%- t('discover.share.title') %></h3>
    <div class="metadata-shared-communities"></div>
    <% if (canSharePage()) { %>
    <div>
      <a href="#" class="metadata-share-link"><%- t('discover.pageTypes.generic.share') %></a>
    </div>
    <% } %>
  </li>
  <li class="metadata-item metadata-item--publish-date hidden">
    <h3><%- t('discover.metadata.publishDate') %></h3>
    <div class="metadata-value"><%= getPublishDate() %></div>
    <div class="metadata-value"><%= getPublishTimeZone() %></div>
  </li>
  <li class="metadata-item">
    <h3><%- t('discover.metadata.tag.label') %></h3>
    <div class="metadata-value"><%- tagList() %></div>
  </li>
<% if (!isAuthorAndContributorInfoDisabled()) { %>
  <li class="metadata-item">
    <h3><%- t('discover.metadata.author') %></h3>
    <div class="clearfix">
      <div aria-hidden="true" class="page-preview-meta-content__avatar" data-contributor-id="<%- getAuthor().user.id %>"></div>
      <div class="page-preview__meta-content-contributors">
        <div class="meta-content-contributors__author-text"><%= getAuthor().user.fullname %></div>
        <div class="meta-content-contributors__author-status"><%= getAuthorDate() %></div>
      </div>
    </div>
    <div class="metadata-value"></div>
  </li>
  <li class="metadata-item">
    <h3><%- t('discover.metadata.contributors') %></h3>
    <div class="contributor-list"></div>
    <div>
      <a href="#" id="showhistory"><%- t('discover.metadata.showHistory') %></a>
    </div>
  </li>
  <% } %>
  <% if(canPrint && !isTraining()) { %>
  <li class="metadata-item">
    <div class="print__action">
      <%= axButton({ label: getPrintText(), className: 'qa-button-print js-button-print' }) %>
    </div>
  </li>
  <% } %>
  <% if(canReportPage()) { %>
  <li class="metadata-item">
    <div class="report__action">
      <%= axButton({ label: getReportText(), className: 'qa-button-report js-button-report' }) %>
    </div>
  </li>
  <% } %>
  <% if (canDeletePage()) { %>
  <li class="metadata-item">
    <div class="delete__action">
      <%= axButton({ label: getDeleteText(), className: 'qa-button-delete js-button-delete', destructive: true}) %>
    </div>
  </li>
  <% } %>
</ul>
`;
// Exports
module.exports = code;