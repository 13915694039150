const $os = require('detectOS');

const ContextModel = require('@common/data/models/ContextModel');

const MediaZoomControllerFactory = require('@common/components/mediaZoom/MediaZoomControllerFactory');

const AppControllerDefinitionFactory = require('@common/modules/main/app/AppControllerDefinitionFactory');

const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');
const HeaderContextConfig = require('@common/modules/main/header/HeaderContextConfig');
const ContentContextConfig = require('@common/modules/main/content/ContentContextConfig');
const BlanketContextConfig = require('@common/modules/main/blanket/BlanketContextConfig');
const FlashWrapperContextConfig = require('@common/modules/main/flashWrapper/FlashWrapperContextConfig');
const LayerContextConfig = require('@common/modules/main/layer/LayerContextConfig');

const HeaderButtonFactory = require('@common/modules/main/header/HeaderButtonFactory');
const HeaderLogoVisibilityPredicate = require('@common/modules/main/header/HeaderLogoVisibilityPredicate');
const HeaderItemFactory = require('@common/modules/main/header/HeaderItemFactory');

const FlashWrapperControllerDefinitionFactory = require('@common/modules/main/flashWrapper/FlashWrapperControllerDefinitionFactory');
const BlanketControllerDefinitionFactory = require('@common/modules/main/blanket/BlanketControllerDefinitionFactory');
const LayerControllerDefinitionFactory = require('@common/modules/main/layer/LayerControllerDefinitionFactory');
const HeaderControllerDefinitionFactory = require('@common/modules/main/header/HeaderControllerDefinitionFactory');
const MsTeamsHeaderControllerDefinitionFactory = require('@common/modules/main/msTeams/MsTeamsHeaderControllerDefinitionFactory');
const {
  isMsTeamsIntegration,
  getNavPosition
} = require('@common/libs/helpers/app/NativeBridgeHelpers');
const NavPosition = require('@common/data/enums/NavPosition');

const isHeaderBackButtonEnableOverriden = () => {
  return $os.isInMobileApp() || isMsTeamsIntegration();
};

module.exports = (options = {}) => {
  const { viewControllerFactory } = options;

  const contextModel = new ContextModel();

  contextModel.setContextConfig( MenuContextConfig );
  contextModel.setContextConfig( ContentContextConfig );
  contextModel.setContextConfig( HeaderContextConfig );
  contextModel.setContextConfig( BlanketContextConfig );
  contextModel.setContextConfig( FlashWrapperContextConfig );
  contextModel.setContextConfig( LayerContextConfig );

  if (isMsTeamsIntegration() || getNavPosition() === NavPosition.hamburger) {
    ContentContextConfig.setContentHeaderDefinition(contextModel, MsTeamsHeaderControllerDefinitionFactory(contextModel) );
  } else {
    ContentContextConfig.setContentHeaderDefinition(contextModel, HeaderControllerDefinitionFactory(contextModel) );
  }

  ContentContextConfig.setContentControllerDefinition(contextModel, BlanketControllerDefinitionFactory(contextModel) );
  BlanketContextConfig.setContentControllerDefinition(contextModel, FlashWrapperControllerDefinitionFactory(contextModel) );
  FlashWrapperContextConfig.setFlashWrapperControllerDefinition(contextModel, LayerControllerDefinitionFactory(contextModel) );

  let appController;

  return {
    setMenuItemDefinitions: (itemViewDefinitions = [], setOptions = {}) => {
      MenuContextConfig.setMenuItemDefinitions(contextModel, itemViewDefinitions, setOptions);
    },

    setSelectedMenuItem: (menuItemId) => {
      MenuContextConfig.setSelectedMenuItem(contextModel, menuItemId);
    },

    toggleHeaderDesktopHideable: (toggle) => {
      HeaderContextConfig.setHeaderDesktopHideable(contextModel, toggle);
    },

    toggleHeaderLogo: (toggle) => {
      HeaderContextConfig.setLogoVisibility(contextModel, toggle || HeaderLogoVisibilityPredicate());
    },

    toggleFullScreen: (toggle = true) => {
      ContentContextConfig.toggleHeader(contextModel, !toggle);
    },

    toggleMenuMinimalDisplay: (toggle) => {
      MenuContextConfig.toggleMinimalLayout(contextModel, toggle);
    },

    toggleMenuHiddenDisplay: (toggle) => {
      MenuContextConfig.toggleHiddenLayout(contextModel, toggle);
    },

    createBackButtonViewDefinition(backClickHandler) {
      return HeaderButtonFactory.createBackButton(backClickHandler);
    },

    showHeaderBackButton: () => {
      const backButtonConfig = HeaderButtonFactory.createBackButton(() => {
        window.history.back();
      });
      HeaderContextConfig.setLeftHeaderViewDefinition(contextModel, backButtonConfig);
    },

    enableHeaderBackButton: (enable) => {
      MenuContextConfig.toggleAlwaysShowBackButton(contextModel, enable || isHeaderBackButtonEnableOverriden());
    },

    debugLockTopNav: (enable = true) => {
      MenuContextConfig.toggleLockedToTop(contextModel, enable);
    },

    setLeftHeaderViewDefinition: (viewDefinition = null) => {
      HeaderContextConfig.setLeftHeaderViewDefinition(contextModel, viewDefinition);
    },

    resetLeftHeaderView: () => {
      HeaderContextConfig.setLeftHeaderViewDefinition(contextModel, null);
    },

    setRightHeaderControllerDefinition: (controllerDefinition) => {
      HeaderContextConfig.setRightHeaderControllerDefinition(contextModel, controllerDefinition);
    },

    setChatHeaderControllerDefinition: (controllerDefinition) => {
      HeaderContextConfig.setChatHeaderControllerDefinition(contextModel, controllerDefinition);
    },

    showPageDefinition: (pageControllerDefinition, showOptions = {}) => {
      LayerContextConfig.setContentControllerDefinition(contextModel, pageControllerDefinition, { showOptions });
    },

    resetPageDefinition: () => {
      LayerContextConfig.resetContentControllerDefinition(contextModel);
    },

    showMedia: (mediaModel) => {
      let maxDimensions = {};

      if (appController != null) {
        const layerController = appController.findControllerById('content-layers');
        if (layerController != null) {
          const layerLayout = layerController.getView();
          maxDimensions = {
            maxWidth: layerLayout.getWidth(),
            maxHeight: layerLayout.getHeight()
          };
        }
      }

      const mediaZoomDefinition = MediaZoomControllerFactory(mediaModel, maxDimensions);
      LayerContextConfig.setOverlayControllerDefinition(contextModel, mediaZoomDefinition);
    },

    dismissMedia: () => {
      LayerContextConfig.setOverlayControllerDefinition(contextModel, null);
    },

    setOverlayControllerDefinition: (definition) => {
      LayerContextConfig.setOverlayControllerDefinition(contextModel, definition);
    },

    findControllerById: (controllerId) => {
      if (appController != null) {
        const layerController = appController.findControllerById('content-layers');
        if (layerController != null) {
          return layerController.findControllerById(controllerId);
        }
      }

      return null;
    },

    show: (region, showOptions) => {
      appController = viewControllerFactory.create( AppControllerDefinitionFactory(contextModel) );
      appController.show(region, showOptions);
    },

    displayFlash(opts = {}) {
      if (appController != null) {
        const flashController = appController.findControllerById('content-flash-wrapper');
        flashController.getView().display(opts);
      }
    },

    clearFlash() {
      if (appController != null) {
        const flashController = appController.findControllerById('content-flash-wrapper');
        flashController.getView().clear();
      }
    },

    setDocumentTitle(title) {
      document.title = title;
    },

    showHeaderUserProfileIcon(user) {
      const isMsTeams = isMsTeamsIntegration();
      const userIsLoggedIn = user.isLoggedIn();
      // user profile icon should not be displayed when the user is not logged in.
      // NOTE: there might be a better place for this check.
      if (userIsLoggedIn && isMsTeams || userIsLoggedIn && getNavPosition() === NavPosition.hamburger) {
        const userProfileIconDefinition = HeaderItemFactory.createUserProfileIcon({ user });

        this.setRightHeaderControllerDefinition(userProfileIconDefinition);
      } else if (userIsLoggedIn) {
        const profileMenuItems = MenuContextConfig.getItemsProfile(contextModel);
        const userProfileIconDefinition = HeaderItemFactory.createUserProfileIcon({ user });
        const profileDropDown = HeaderItemFactory.createDropdown({
          buttonContentsViewDefinition: userProfileIconDefinition,
          dropdownMenuItems: profileMenuItems
        });

        this.setRightHeaderControllerDefinition(profileDropDown);
      }
    }
  };
};

