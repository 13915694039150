const $os = require('detectOS');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

const ActivityType = require('@common/data/enums/ActivityType');

const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');
require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const FeedbackSettingsModel = require('@common/data/models/FeedbackSettingsModel');

const QuestionActionButtonFactory = require('@training/apps/training/views/activities/pages/questions/QuestionActionButtonFactory');
const FlexibleActionBar = require('@training/apps/main/views/FlexibleActionBar');

class QuestionView extends UIKit.View {
  behaviors() {
    return {
      ZoomOverlay: {
        imageWrapper: '.zoom-image-wrap'
      }
    };
  }

  className() {
    return 'question-view';
  }

  initialize() {
    this.next = this.next.bind(this);
    this.trySetFocusOnContinue = this.trySetFocusOnContinue.bind(this);
    this.setFocusOnCorrectIncorrect = this.setFocusOnCorrectIncorrect.bind(this);
    const feedbackSettings = new FeedbackSettingsModel();

    this.isFeedbackEnabled = false;

    feedbackSettings.fetch().then(() => {
      this.isFeedbackEnabled = feedbackSettings.get('questionFeedback');
    });

    this.activity = this.options.activity;
    this.gameManager = this.options.gameManager != null ? this.options.gameManager : null;
    this.variant = this.getQuestionContent(this.activity);

    this._imageMediaInfo = {};
    this._imageViewers = {};

    this._createImageMediaInfo();

    this.listenTo(this, 'image:clicked', (mediaId) => {
      window.app.layout.showMedia(this._imageMediaInfo[mediaId]);
    });
  }

  viewDidAppear() {
    this._initializeImageViewers();
  }

  onNext() {
    //this function should be empty;
  }

  _getImageZoomOptions(options) {
    const {
      zoomOptions = {},
      zoomContainer
    } = options;
    const { zIndex } = zoomOptions;
    return {
      container: zoomContainer != null ? zoomContainer : '#page-view',
      zIndex: zIndex != null ? zIndex : 900
    };
  }

  getQuestionContent(activityModel) {
    return activityModel.get('body').question.variants[0];
  }

  getActionBarOptions() {
    const { hideConfidenceIndex } = this.options;
    if (hideConfidenceIndex) {
      return QuestionActionButtonFactory.getSubmitAnswersButton(this.onSubmit.bind(this));
    }

    return QuestionActionButtonFactory.getConfidenceButtons(this.onSubmit.bind(this));
  }

  setupActionBarWithConfidence() {
    this.actionBar.setActionBar(this.getActionBarOptions());
  }

  showActionBarWithConfidence() {
    this.actionBar.show({
      animate: !$os.mobile
    });
  }

  setupAndShowActionBarWithContinue(options = {}) {
    const continueAndFeedbackOptions = {
      title: null,
      buttons: [
        {
          type: 'continue',
          onClick: this.next,
          bin: FlexibleActionBar.CENTER
        }
      ]
    };

    if (this.isFeedbackEnabled && this.activity.getType() !== ActivityType.SURVEY_QUESTION) {
      const feedbackTrigger = () => {
        window.apps.glChannel.vent.trigger('app:openFeedbackModal', {
          view: this,
          viewOptions: this.options
        });
      };
      continueAndFeedbackOptions.buttons.unshift({
        type: 'feedback',
        ariaLabel: I18n.t('question.accessibility.feedback'),
        tooltip: I18n.t('question.accessibility.feedback'),
        onClick: feedbackTrigger,
        bin: FlexibleActionBar.LEFT
      });
    }

    this.actionBar.setActionBar($.extend(true, continueAndFeedbackOptions, options));
    if (this.isFeedbackEnabled) {
      this.actionBar.trySetFocus('feedback');
    }
  }

  trySetFocusOnContinue() {
    this.actionBar.trySetFocus('continue');
  }

  setFocusOnCorrectIncorrect() {
    this.$('.correct-incorrect').trigger('focus');
  }

  hideActionBar(options = {}) {
    this.actionBar.hide($.extend(true, {
      animate: !$os.mobile
    }, options));
  }

  createReason(question) {
    const $parent = this.$('.hinttext');

    if (this.renderReasonDetails(question, $parent)) {
      const $reason = this.$('#reason');
      if ($('.bonuscharacter .NoCoach, .bonuscharacter .nocoach').length > 0) {
        $reason.addClass('NoCoachHint');
      }
      $reason.show();
      $parent.removeClass('empty');
    } else {
      // give parent empty class
      $parent.addClass('empty');
    }

    $parent.show();
  }

  scrollResultBannerIntoView(callbackFn = () => {}) {
    Scrollable.scrollIntoView(this, this.$('#balance-points-wrapper'), {
      animate: {
        complete: () => {
          this.triggerAdjustment();
          callbackFn();
        }
      }
    });
  }

  // This method will add the reason text and image to the html
  renderReasonDetails(question, parent) {
    let isReasonShow = false;
    const {
      reasonText,
      reasonImg
    } = question;

    if ((reasonText != null) && (reasonText.trim().length > 0)) {
      isReasonShow = true;
      parent.append(`<div id="reason" class="clearfix"><div id="reasonContent">${ reasonText }</div></div>`);
    }

    if (reasonImg != null) {
      if (isReasonShow) {
        parent.find('#reason').prepend('<button class="image-wrapper imgwrap zoom-image-wrap reason-image"></button>');
      } else {
        parent.append('<div id="reason" class="clearfix"><div class="image-wrapper imgwrap zoom-image-wrap reason-image"></div>');
      }

      this._imageMediaInfo[reasonImg.preferred.id] = reasonImg.preferred;
      this._imageViewers[reasonImg.preferred.id] = ImageViewerFactory.createViewerInstance({
        media: reasonImg.preferred,
        $el: parent.find('.reason-image')
      });
      this.listenTo(this._imageViewers[reasonImg.preferred.id], 'image"loaded', this.triggerAdjustment);
      this._imageViewers[reasonImg.preferred.id].render();

      isReasonShow = true;
    }

    return isReasonShow;
  }

  createImageViewer(media, $el) {
    this._imageViewers[media.id] = ImageViewerFactory.createViewerInstance({
      media,
      $el
    });
    this.listenTo(this._imageViewers[media.id], 'image:loaded', this.triggerAdjustment);
    this._imageViewers[media.id].render();
  }

  showPointsArea(correct, points, a11yFeedbackString = null, animate = true) {
    const $wrp = $('<span id="balance-points-wrapper" class="clearfix"><div class="correct-incorrect-wrapper"><p class="correct-incorrect" tabindex="-1"></p></div><span class="balance-points"><span class="points"></span> <span class="balance"></span></span></span>');
    const $qinst = $('.hinttext .question-instruction');

    $qinst.append($wrp);

    const $corincor = $wrp.find('.correct-incorrect');
    const $corincorWrapper = $wrp.find('.correct-incorrect-wrapper');
    const $balancept = $wrp.find('.balance-points');

    if (correct) {
      $corincor.html(I18n.t('layout.correct'));
      $wrp.addClass('correct');
    } else {
      $corincor.html(I18n.t('layout.incorrect'));
      $wrp.addClass('incorrect');
    }

    if (points !== 0) {
      $balancept.find('.balance').html(I18n.t('layout.balance'));
      $balancept.find('.points').html(I18n.t('layout.earned', {
        points
      }));
    } else {
      $balancept.css('display', 'none');
      $corincor.addClass('full-size');
    }

    if (a11yFeedbackString) {
      $corincorWrapper.append($(`<span class="off-screen a11y-question-feedback-instructions">${ a11yFeedbackString }</span>`));
    }

    if (animate) {
      const height = $wrp.height();
      $wrp.css('height', 0);
      $wrp.animate({
        height
      }, 400);
    }

    if ($os.ieVersion < 8) {
      const $qinstW = $qinst.outerWidth() < 180 ? 180 : $qinst.outerWidth();
      $wrp.css('width', $qinstW);
    }
  }

  _generateOptionText(option) {
    if (option.optionImg != null && option.optionImg.preferred.altText) {
      return I18n.t('question.accessibility.graphic') + ' ' + option.optionImg.preferred.altText.preferred + '. ' + (option.optionText || '');
    }
    return option.optionText || '';

  }

  _createImageMediaInfo() {
    if (this.variant.questionImg != null) {
      this._imageMediaInfo[this.variant.questionImg.preferred.id] = this.variant.questionImg.preferred;
    }

    if (this.variant.options != null) {
      for (let i = 0; i < this.variant.options.length; i++) {
        const option = this.variant.options[i];
        if (option.optionImg != null) {
          this._imageMediaInfo[option.optionImg.preferred.id] = option.optionImg.preferred;
        }
      }
    }

    if (this.variant.secondOptions != null) {
      for (let i = 0; i < this.variant.secondOptions.length; i++) {
        const option = this.variant.secondOptions[i];
        if (option.secondImg != null) {
          this._imageMediaInfo[option.secondImg.preferred.id] = option.secondImg.preferred;
        }
      }
    }
  }

  _initializeImageViewers() {
    _.each(this._imageMediaInfo, (media) => {
      this.createImageViewer(media, this.$el.find(`[data-media-id = '${ media.id }']`));
    });
  }


  next() {
    if (apps.base.checkDoubleSubmit() || this.nextClicked || !this.hasGotServerResponse) {
      return false;
    }
    this.hideActionBar();
    this.onNext();
    this.nextClicked = true;
    return undefined;
  }
}

module.exports = QuestionView;
