const Backbone = require('Backbone');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const TabController = require('@common/components/tabbed_layout/TabController');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const {triggerResize} = require('@common/libs/helpers/app/BrowserHelpers');
const ArticleSearchCollection = require('@training/apps/search/ArticleSearchCollection');

class SearchTabController extends TabController {

  constructor(options = {}) {
    super(options);
    ({
      searchPageState: this.pageState
    } = options);

    this._searchByTab();

    this.listenTo(this.pageState, 'change:searchCategory', () => {
      if (!this.pageState.get('community')) {
        this.pageState.set('pageNum', 0);
        this._searchByTab();
      }
    });
  }

  _buildUrlHash(tabName) {
    // If we're here because the page searched for is not available, don't change the URL hash
    if (this.pageState.get('showNotAvailable')) {
      return Backbone.history.location.hash;

      // Otherwise, update the URL hash with the correct tab/page/query info
    }
    return SearchUrlHelper.getHashForCurrentTabState(this.pageState, tabName);

  }

  _onViewShowTab(tabName = SearchCategoryEnum.ALL) {
    this.pageState.set('searchCategory', tabName);
    const hash = this._buildUrlHash(tabName);
    const replace = Backbone.history.location.hash === SearchUrlHelper.BASE_SEARCH_HASH;

    if (Backbone.history.location.hash !== hash) {
      Backbone.history.navigate(hash, {
        trigger: false,
        replace
      });
    }

    triggerResize(true);
    super._onViewShowTab(tabName);
  }

  _searchByTab() {
    let searchesArray = [];
    const tabTypes = this._returnAccessibleTabs();

    //Different Tabs and their respective collection names
    if (this.pageState.get('searchString') !== '') {
      searchesArray = this._setSearchQueryResultsPerAccessibleTab(tabTypes);
    } else if (this.pageState.get('subCategory') === SearchSubCategoryEnum.FAVORITES) {
      searchesArray = this._setFavoritesFilterResultsForArticlesTab();
    } else if (this.pageState.get('subCategory') === SearchSubCategoryEnum.PENDING) {
      searchesArray = this._setPendingFilterResultsForArticlesTab();
    }

    this.pageState.set('searchesArray', searchesArray);
  }

  _returnAccessibleTabs() {
    const tabTypes = [];
    const hubController = window.app.sessionController.getHubController();
    const propertyProvider = TenantPropertyProvider.get();
    const dzTenantValue = propertyProvider.getProperty('discoveryZoneEnabled');
    const isDZGuest = window.apps.auth.session.user.isGuestOrSuperuser();
    const dzEnabled = dzTenantValue && (hubController.hasSomeDiscoveryZoneCommunityAvailable() || isDZGuest);
    const etEnabled = hubController.isExtraTrainingEnabled();

    if (etEnabled) {
      tabTypes.unshift(SearchCategoryEnum.PATHS);
      tabTypes.unshift(SearchCategoryEnum.TOPICS);
    }
    if (dzEnabled) {
      tabTypes.unshift(SearchCategoryEnum.ARTICLES);
    }

    return tabTypes;
  }

  _setSearchQueryResultsPerAccessibleTab(tabTypes) {
    const searchesArray = [];

    tabTypes.forEach((tabType) => {
      const currentCollection = this.pageState.get('results')[tabType];
      const searchCategory = this.pageState.get('searchCategory');

      if (searchCategory === SearchCategoryEnum.ALL) {
        // Always pull actual results for the ALL tab (because they get displayed)
        currentCollection.changeExtraParameters({
          countOnly: false
        }, {
          fetch: false
        });
        // Only the first three results get displayed
        currentCollection.state.pageSize = 3;
        // NOTE: Only/always pull the first page for the All tab
        searchesArray.push(
          this._searchCurrentCollection(currentCollection, 0)
        );
      } else {
        const isCurrentTab = searchCategory === tabType;

        // If we are pulling for a tab other than the current one, only pull the count (only needed to update the tab heading)
        if (isCurrentTab) {
          currentCollection.changeExtraParameters({
            countOnly: false
          }, {
            fetch: false
          });
        } else {
          currentCollection.changeExtraParameters({
            countOnly: true
          }, {
            fetch: false
          });
        }

        currentCollection.state.pageSize = 12;

        // Only let page numbers > 0 for current search category (currently selected tab)
        // Others can always fetch first page in the background for purpose of displaying total results count
        const pageNum = isCurrentTab ? this.pageState.get('pageNum') : 0;

        searchesArray.push(
          this._searchCurrentCollection(currentCollection, pageNum)
        );
      }
    });

    return searchesArray;
  }

  _setFavoritesFilterResultsForArticlesTab() {
    const searchesArray = [];

    const articleCollection = this.pageState.get('results')[SearchCategoryEnum.ARTICLES];
    articleCollection.state.pageSize = 12;
    articleCollection.changeExtraParameters({
      pageStatus: 'fact'
    }, {
      fetch: false
    });

    const pageNum = this.pageState.get('pageNum') || 0;

    searchesArray.push(
      this._searchCurrentCollection(articleCollection, pageNum)
    );

    return searchesArray;
  }

  _setPendingFilterResultsForArticlesTab() {
    const searchesArray = [];

    const articleCollection = this.pageState.get('results')[SearchCategoryEnum.ARTICLES];
    articleCollection.state.pageSize = 12;

    const pageNum = this.pageState.get('pageNum') || 0;

    searchesArray.push(
      this._searchCurrentCollection(articleCollection, pageNum)
    );

    return searchesArray;
  }

  _searchCurrentCollection(currentCollection, pageNum = 0) {
    // The undefined param below is selected search facets. These don't get set unless a tag is clicked which is
    // handled in a callback in ArticleSearchCollection where search() is called
    if (currentCollection instanceof ArticleSearchCollection) {
      return currentCollection.search(
        this.pageState.get('searchString'),
        this.pageState.get('urlTags'),
        pageNum
      );
    }

    return currentCollection.search(
      this.pageState.get('searchString'),
      pageNum
    );
  }
}

module.exports = SearchTabController;
