const { LayoutView } = require('Marionette');
const { Collection } = require('Backbone');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
const TopicLevelRowViewController = require('@training/apps/topics/TopicLevelRowViewController');
const TitledSectionControllerDefinition = require('@common/components/titledSection/TitledSectionControllerDefinitionFactory');

const getTopicDescriptionControllerDefinition = (topicData = {}, startTrainingFn) => {
  if (_.isEmpty(topicData)) {
    return;
  }

  const topicLevelCollection = new Collection(topicData.get('topicLevels'));
  const topicId = topicData.get('id');

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LayoutView,
      model: topicData,
      className: 'topic-description',
      template: `
      <% if (description) { %>
        <div class="description-text regularfont"><%- description %></div>
      <% } %>
        <div class="topic-level-region"></div>
      `,
      regions: {
        topicLevels: '.topic-level-region'
      }
    },
    regionControllers: {
      topicLevels: TitledSectionControllerDefinition(I18n.t('selfDirected.topicDetails.topicLevels.title'), () => {
        return {
          ViewControllerClass: CollectionLayoutViewController,
          collection: topicLevelCollection,
          viewDefinition: {
            tagName: 'ul',
            className: 'topic-level-list',
            childViewOptions: {
              tagName: 'li'
            }
          },
          ChildViewControllerDefinition: {
            ViewControllerClass: TopicLevelRowViewController,
            topicId,
            startTrainingFn
          }
        };
      })
    }
  };
};

module.exports = {
  getTopicDescriptionControllerDefinition
};
