const Marionette = require('Marionette');

const EmptyRowView = require('@common/mixins/empty_collection/EmptyRowView');

const EmptyCollectionViewMixin = {
  defaultEmptyView: EmptyRowView,

  getEmptyView() {
    return Marionette.getOption(this, 'emptyView') || this.defaultEmptyView;
  }
};

module.exports = EmptyCollectionViewMixin;
