const I18n = require('@common/libs/I18n');
const Page = require('@common/components/discover/models/Page');

class Question extends Page {
  apiEndpoint() {
    return '/posedQuestions';
  }

  get className() {
    return 'Question';
  }

  validators() {
    return {
      title: ['required', function(value) {
        if (value.length > Question.MAX_TITLE_LENGTH) {
          return I18n.t('discover.errors.Question.title.length', {maxTitleLength: Question.MAX_TITLE_LENGTH});
        }
      }
      ],
      language: 'required',
      community: 'required'
    };
  }

  _getActionText() {
    if ((this.get('status') === 'review') && (this.get('currentVersion') == null)) {
      return I18n.t('discover.pageTypes.question.actionText.write');
    }
    return null;

  }
}

module.exports = Question;
