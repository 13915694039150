const logging = require('logging');

const getProfileColorByNumber = require('@common/libs/getProfileColorByNumberHelper');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const {
  getPhotoUrl
} = require('@common/components/ProfileIconHelpers');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const {
  some,
  without
} = require('underscore');

const getProfileUserName = (user) => {
  if (user.salutationName) {
    return user.salutationName;
  } else if (user.nickname) {
    return user.nickname;
  } else if (user.fullname) {
    return user.fullname;
  } else if (user.name) {
    return user.name;
  }

  return '';
};

const getProfileUserInitials = (user) => {
  return StringHelpers.getInitials(getProfileUserName(user));
};

const getBestImageSize = (sizesData, preferredSizeIndex) => {
  // If the size we want is available, we should use that
  const preferredSize = sizesData[preferredSizeIndex];
  if (preferredSize && preferredSize.status === 'done') {
    return preferredSize;
  }

  // Otherwise, let's traverse downwards until we find something we can use
  // even if it not the optimal resolution
  const leftovers = without(sizesData, preferredSize);
  return find(leftovers, (size) => {
    return size.status === 'done';
  });
};

const hasValidProfileImageSize = (profileImage) => {
  return some(profileImage.sizes, (size) => {
    return size.status === 'done';
  });
}

class ProfileIconHandler {
  static getProfileUserName = getProfileUserName;

  static getProfileUserInitials = getProfileUserInitials;

  static getBestImageSize = getBestImageSize;

  static hasValidProfileImageSize = hasValidProfileImageSize;

  constructor(options = {}) {
    const {
      user,
      profileElement,
      setBackgroundColor
    } = options;

    this.tenantStore = TenantPropertyProvider.get();

    this.updateImage(user, profileElement, setBackgroundColor);
  }

  updateImage(user, $profileElement, setBackgroundColor = true) {
    const initials = getProfileUserInitials(user.user ?? user);
    this.backgroundColor = this.getColorByNumber(user.id) || '#000';
    const allowUserProfileImageChange = this._getTenantImageProperty();

    if (setBackgroundColor) {
      $profileElement.css('backgroundColor', this.backgroundColor);
    }

    $profileElement.text(initials);

    if ((user.profileImage != null) && allowUserProfileImageChange && hasValidProfileImageSize(user.profileImage)) {
      this.setPhotoTemplate(user, $profileElement);
    } else {
      this.unsetPhotoTemplate($profileElement);
    }
  }

  setPhotoTemplate(user, $profileElement) {
    const profileImage = getPhotoUrl(user);

    $profileElement.css({
      'background-repeat': 'no-repeat',
      'background-position': 'center',
      'background-size': '100%',
      'background-image': `url(${ profileImage })`,
      'text-indent': '-99999px'
    });
  }

  unsetPhotoTemplate($profileElement) {
    $profileElement.css({
      'background-repeat': '',
      'background-position': '',
      'background-size': '',
      'background-image': '',
      'text-indent': ''
    });
  }

  getColorByNumber(number) {
    return getProfileColorByNumber(number);
  }

  _getTenantImageProperty() {
    let allowUserProfileImageChange = this.tenantStore.getProperty('allowUserProfileImageChange');

    if ((allowUserProfileImageChange == null)) {
      // Log an error if there allowUserProfileImageChange is undefined or null
      // and then default it to false.
      logging.error('ProfileIconHandler: allowUserProfileImageChange was not set');
      allowUserProfileImageChange = false;
    }

    return allowUserProfileImageChange;
  }
}

module.exports = ProfileIconHandler;
