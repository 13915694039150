const Form = require('@common/components/forms/Form');
const _ = require('underscore');
const Backbone = require('Backbone');
const KeyCode = require('@common/data/enums/KeyCode');


const TranslatableString = require('@common/data/models/translationStrings/TranslatableString');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');

/**
 * See Form.Editor.TranslationStrings.js in admin. This file is much the same, but this class'
 * purpose is to provide a translation input component without the dynamic abililty to be a textarea like
 * TranslationStrings. This allows us to use TranslationInput across each app without TranslationStrings' dependencies
 * that include RichTextEditor and the libraries it depends on that aren't packaged in training (for example).
 */
Form.Editor.TranslationInput = class TranslationInput extends Form.Editor {
  initialize(options = {}) {

    ({
      languageList: this.languageList,
      dataEditor: this.dataEditor,
      isLocalizableString: this.isLocalizableString,
      translatedLanguage: this.translatedLanguage,
      placeHolder: this.placeHolder,
      maxLength: this.maxLength
    } = options.options);

    this.formOptions = options.options;

    this.template = _.tpl(this.getTranslationTemplate());

    if (this.placeHolder == null) {
      this.placeHolder = '';
    }
    if (this.maxLength == null) {
      this.maxLength = 524288;
    } // default maxlength size https://www.w3schools.com/tags/att_input_maxlength.asp

    this.translationString = this._getDefaultForLanguage(this.translatedLanguage);
    this.state = new Backbone.Model();

    this.listenTo(this.state, 'change:value', (model, value) => {
      if (value === '') {
        delete this.translationString.getValues()[this.translatedLanguage];
      } else {
        this.translationString.setValueForLanguage(this.translatedLanguage, value);
      }
    });

    this._renderInputAreaTranslation();
  }

  getTranslationTemplate() {
    return `\
    <input class="language-input translation-values"
      data-options="formOptions"
      type="text"
      id="name"
      data-field="value"
      placeholder="<%- placeholder %>"
      maxlength="<%- maxlength %>" />\
    `;
  }

  events() {
    const events = super.events();
    events['keypress .language-input'] = (e) => {
      // This bit of code overrides the browser default behaviour where, for forms with a single text input,
      // pressing 'enter' would submit the form. This should be easily overridable if required.
      if (e.which === KeyCode.ENTER) {
        e.preventDefault();
      }
    };

    return events;
  }


  updateSelectedLanguage(selectedLanguage) {
    this.translatedLanguage = selectedLanguage;
    if (this.translationString === this.defaultValue) {
      this.translationString = this._getDefaultForLanguage(this.translatedLanguage);
    }
    const translationValue = this.translationString.getValueForLanguage(this.translatedLanguage, false);

    this.updateDataEditorFields(translationValue);
  }

  setValue(value) {
    if (value != null) {
      this.translationString.set(value.attributes);
    } else {
      this.translationString = this._getDefaultForLanguage(this.translatedLanguage);
    }
    const translationValue = this.translationString.getValueForLanguage(this.translatedLanguage, false);

    this.updateDataEditorFields(translationValue);
  }

  _getDefaultForLanguage(langCode) {
    let StringType;
    if (this.isLocalizableString === true) {
      StringType = LocalizableString;
    } else {
      StringType = TranslatableString;
    }

    const defaultValue = new StringType();
    defaultValue.setValueForLanguage(langCode, '');
    return defaultValue;
  }

  updateDataEditorFields(translationValue) {
    const editor = this.form.fields.value.editor;
    editor.setValue(translationValue);

    if ((this.dataEditor != null) && (this.form != null)) {
      editor.$el.data('field', translationValue);
      editor.updateTranslationValue(translationValue);
    }

    this.state.set('value', translationValue);
  }

  getValue() {
    this.form.commit();
    return this.translationString;
  }

  _renderInputAreaTranslation() {
    this.form = new Form({
      html: this.template({
        placeholder: this.placeHolder,
        maxlength: this.maxLength
      }),
      model: this.state,
      context: {
        formOptions: this.formOptions
      }
    });

    this.$el.html(this.form.el);
  }

  onDestroy() {
    if (this.form != null) {
      this.form.destroy();
    }
  }
};

module.exports = Form.Editor.TranslationInput;
