const memoizeCapped = require('@common/configs/underscore/internal/memoizeCapped');

/*
 * The following code was pretty much all ripped from the source code for Lodash.js
 * https://github.com/lodash/lodash/blob/2f79053d7bc7c9c9561a30dda202b3dcd2b72b90/.internal/stringToPath.js
*/

const charCodeOfDot = '.'.charCodeAt(0);
const reEscapeChar = /\\(\\)?/g;
const rePropName = RegExp(
  // Match anything that isn't a dot or bracket.
  '[^.[\\]]+|'
  // Or match property names within brackets.
  + '\\[(?:'
    // Match a non-string expression.
    + '([^"\'][^[]*)|'
    // Or match strings (supports escaping characters).
    + '(["\'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2'
  + ')\\]|'
  // Or match "" as the space between consecutive dots or empty brackets.
  + '(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))'
  , 'g'
);

/**
 * Converts `string` to a property path array.
 *
 * @param {string} string The string to convert.
 * @returns {Array} Returns the property path array.
 */
const stringToPath = memoizeCapped((string) => {
  const result = [];

  if (string.charCodeAt(0) === charCodeOfDot) {
    result.push('');
  }

  string.replace(rePropName, (match, expression, quote, subString) => {
    let key = match;
    if (quote) {
      key = subString.replace(reEscapeChar, '$1');
    } else if (expression) {
      key = expression.trim();
    }
    result.push(key);
  });

  return result;
});

module.exports = stringToPath;
