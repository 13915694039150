const EventEnrollmentStatus = require('@common/data/enums/EventEnrollmentStatus');
const I18n = require('@common/libs/I18n');

module.exports = {
  getLearningEventStatusDisplayInfo(model, alwaysShowRemaining = false) {
    const statusObject = {
      text: '',
      class: '',
      color: ''
    };

    if ((model.get('enrollment') == null)) {
      statusObject.text = this.getUnenrolledStatusText(model, alwaysShowRemaining);
      statusObject.color = 'red';

    } else if (model.get('enrollment').status === EventEnrollmentStatus.Enrolled) {
      statusObject.text = I18n.t('learningEvents.listStatus.enrolled');
      statusObject.color = 'green';

    } else if (model.get('enrollment').status === EventEnrollmentStatus.Waiting) {
      const position = model.get('numWaiting');
      statusObject.text = I18n.t('learningEvents.listStatus.onWaitingList', {waitingPosition: position});
      statusObject.color = 'green';
    }

    if (statusObject.text === '') {
      statusObject.class = 'hidden';
    }

    return statusObject;
  },

  getUnenrolledStatusText(model, alwaysShowRemaining = false) {
    const seatsRemaining = model.getRemainingSeatCount();

    if (!model.hasSeatsRemaining()) {
      if (model.get('scheduledEvent').enableWaitingList) {
        return I18n.t('learningEvents.listStatus.fullWaitingList');
      }
      return I18n.t('learningEvents.listStatus.full');


    } else if (alwaysShowRemaining || model.hasLowSeatInventory()) {
      if (seatsRemaining === 1) {
        return I18n.t('learningEvents.listStatus.spotLeft', {numSeats: seatsRemaining});
      }
      return I18n.t('learningEvents.listStatus.spotsLeft', {numSeats: seatsRemaining});

    }

    return '';
  },

  getAttendedDisplayInfo(model) {
    const statusObject = {
      text: '',
      class: 'attended'
    };

    const enrollment = model.get('enrollment') || {};

    if (enrollment.attended) {
      statusObject.text = I18n.t('learningEvents.listStatus.attended');
    } else if (model.get('scheduledEvent').attendanceTaken) {
      statusObject.text = I18n.t('learningEvents.listStatus.notAttended');
    } else {
      statusObject.text = I18n.t('learningEvents.listStatus.attendanceNotTaken');
    }

    return statusObject;
  }
};
