const logging = require('logging');
const Backbone = require('Backbone');

class ReferralStatus extends Backbone.Model {
  defaults() {
    return {
      hasSeenReferralHelpText: false
    };
  }

  apiEndpoint() {
    return '/bounty/status';
  }

  parse(response) {
    return response.entity;
  }

  setDueToSeeInitialReferralPage() {
    this.set('dueToSeeInitialReferralPage', false);

    // let server know that the user has seen initial referral page
    const data
      = {dueToSeeInitialReferralPage: false};

    // We have to do an explicit ajax call since this model doesn't have an `id`
    // and `isNew` returns false; `@save` would do a POST instead of PUT
    return $.ajax({
      type: 'PUT',
      url: this.url(),
      data: JSON.stringify(data),
      skipGlobalHandler: true,
      success() {
        logging.info('Successfully saved dueToSeeInitialReferralPage status');
      },
      error() {
        logging.error('Failed to save dueToSeeInitialReferralPage status');
      }
    });
  }

  setHasSeenReferralHelpText() {
    this.set('hasSeenReferralHelpText', true);

    // let server know that the user will have seen the modal
    const data
      = {hasSeenReferralHelpText: true};

    // We have to do an explicit ajax call since this model doesn't have an `id`
    // and `isNew` returns false; `@save` would do a POST instead of PUT
    return $.ajax({
      type: 'PUT',
      url: this.url(),
      data: JSON.stringify(data),
      skipGlobalHandler: true,
      success() {
        logging.info('Successfully saved hasSeenReferralHelpText status');
      },
      error() {
        logging.error('Failed to save hasSeenReferralHelpText status');
      }
    });
  }
}

module.exports = ReferralStatus;
