const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { LayoutView } = require('Marionette');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const ReportDataList = require('@training/apps/search/reports/domain/ReportDataList');

class ReportsTableLayoutController extends LayoutController {
  initialize(options = {}) {
    ({
      pageTitle: this.pageTitle,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount,
      collectionClass: this.collectionClass,
      reportType: this.reportType,
      hasResult: this.hasResult
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: `\
        <div class="reports-card__header-wrapper">
          <h3 class="ax-font__s ax-grid__col--auto-size"><%- t(pageTitle) %></h3>
          <div class="button-wrapper">
            <button class="base-card__view-all ax-grid__col--auto-size off-screen" aria-label="<%- t('selfDirected.search.viewDetails') %>" >
              <span><%- t('selfDirected.search.viewDetails') %></span>
              <span class="icon-chevron_right"></span>
            </button>
          </div>
        </div>
        <table class="reports-dashboard-table">
          <caption class="off-screen"><%- t(pageTitle) %></caption>
          <thead>
            <tr>
              <th width="80%"><%- t(tableTitle) %></th>
              <th class="right-align" width="20%"><%- t(tableCount) %></th>
            </tr>
          </thead>
        </table>
        <table class="collection reports-dashboard-table">
        </table>\
     `,
      templateHelpers: {
        pageTitle: this.pageTitle,
        tableTitle: this.tableTitle,
        tableCount: this.tableCount
      },
      regions: {
        tableRegion: '.collection'
      }
    };
  }

  _updateTable(filterContext, ViewClass) {
    this.param.communityId = filterContext.get('community').get('id');

    if (this.param.communityId != null) {
      this.pagesCollection.reset();
      this.pagesCollection.fetch({ error: this._onUpdateTableFail});
    }

    this.listenTo(this.pagesCollection, 'sync', () => {
      this.viewClass = new ViewClass({
        collection: this.pagesCollection,
        tableTitle: this.tableTitle,
        tableCount: this.tableCount
      });
      this.getView().tableRegion.show(this.viewClass);
    });
  }

  _onUpdateTableFail(collection, xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.pageAccess.community.error.3017'));
    }
  }

  regionControllers() {
    this.param = {};
    if (this.hasResult !== null) {
      this.param.hasResult = false;
    }

    this.pagesCollection = new ReportDataList([], {
      reportType: this.reportType,
      additionalOptions: this.param
    });

    this.pagesCollection.fetch({ error: this._onUpdateTableFail});

    return {
      tableRegion: {
        viewDefinition: {
          ViewClass: this.collectionClass,
          collection: this.pagesCollection,
          tableTitle: this.tableTitle,
          tableCount: this.tableCount
        }
      }
    };
  }
}

module.exports = ReportsTableLayoutController;

