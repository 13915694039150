const logging = require('logging');
const _ = require('underscore');
const { history } = require('Backbone');

const {
  View,
  ListView
} = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');

const GameScoresList = require('../collections/GameScoresList');
const { GameItemCard } = require('@training/apps/training/views/GameItem');
const RecentChallengesView = require('@training/apps/training/views/RecentChallengesView');
const ChallengeStatsView = require('@training/apps/training/views/ChallengeStatsView');
require('@common/libs/behaviors/msHideable/MsHideable');

class EmptyGamesListView extends View {
  getTemplate() {
    return _.tpl('<p class="nogames"><%-t(\'gamescores.nogames\') %></p>');
  }

  render() {
    this.$el.html(this.getTemplate());
    return this;
  }
}

class GameScoresPage extends View {
  getTemplate() {
    return _.tpl(require('../templates/GameScoresPage.html'));
  }

  events() {
    return {
      'click .game': 'showGameDetails'
    };
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  constructor(...args) {
    super(...args);

    this.onClose = this.onClose.bind(this);
    this.showGameDetails = this.showGameDetails.bind(this);

    this.collection = new GameScoresList();

    this.gamesListView = new ListView({
      collection: this.collection,
      EmptyView: EmptyGamesListView,
      ModelView: GameItemCard.extend({
        gridFullWidthMobile: true
      })
    });
    this.challengeStatsView = new ChallengeStatsView();
    this.recentChallengesView = new RecentChallengesView();
  }

  render() {
    this.$el.html(this.getTemplate());

    this.setSubviewIn(this.gamesListView, {
      regionSelector: '.all-games .gameslist',
      transition: View.Transitions.NONE
    });
    this.setSubviewIn(this.challengeStatsView, {
      regionSelector: '.challenge-stats-region',
      transition: View.Transitions.NONE
    });
    this.setSubviewIn(this.recentChallengesView, {
      regionSelector: '.recent-challenges-region',
      transition: View.Transitions.NONE
    });

    return this;
  }

  viewDidAppear() {
    logging.info('GameScoresPage - viewDidAppear');
    window.app.layout.setTitle(I18n.t('gamescores.title'));

    // Get games
    this.collection.getGamesWithScoring();
  }

  onClose(...args) {
    this.collection.abortXHR(Sync.Method.READ);

    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }

    super.onClose(...args);
  }

  showGameDetails(e) {
    const gameId = $(e.currentTarget).data('game-id');

    history.navigate(`#hub/gameScores/${ gameId }`, {trigger: true});

    return false;
  }
}

module.exports = GameScoresPage;
