const Flash = require('@common/components/flash/Flash');

const FlashWrapperContextConfig = require('@common/modules/main/flashWrapper/FlashWrapperContextConfig');

module.exports = (contextModel) => {
  return {
    viewportRegion: () => {
      return FlashWrapperContextConfig.getFlashWrapperControllerDefinition(contextModel);
    },
    flashRegion: {
      id: 'content-flash-wrapper',
      viewDefinition: Flash
    }
  };
};
