const $os = require('detectOS');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const FileFactory = require('@common/libs/file/FileFactory');
const MediaHelpers = require('@common/libs/file/MediaHelpers');
const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');
const DesktopProfileUserSettingView = require('@training/apps/training/views/ProfileUserSettingView');

require('@common/components/forms/editors/mediaThumbnail/Form.Editor.MediaThumbnail');

class ProfileUserSettingView extends DesktopProfileUserSettingView {
  constructor(options) {
    super(options);
    this.createImageUploader = this.createImageUploader.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.saveMedia = this.saveMedia.bind(this);
    this.setImageReady = this.setImageReady.bind(this);
    this.imageShowHide = this.imageShowHide.bind(this);
  }

  events() {
    return {'click @ui.addPhoto': 'onClickAddPic'};
  }

  viewDidAppear() {
    if (!$os.ipad) {
      window.app.layout.showHeaderBackButton();
    }

    _(this.formContext).extend({
      mediaThumbnailOptions: {
        $cameraButton: this.ui.cameraButton,
        fileFactory: FileFactory,
        fileOptions: {
          videoAllowed: false,
          isSecureUpload: false
        },
        forceRender: true,
        loadingPredicate: () => {
          return !this.imageReady;
        }
      }
    });

    super.viewDidAppear();
  }

  onRender() {
    window.app.layout.setTitle(I18n.t('settings.userTitle'));
  }

  createImageUploader() {
    this.listenTo(this.userForm, 'change:profileImage', this.onUpload);

    if (this.userForm.fields.profileImage != null ? this.userForm.fields.profileImage.editor.getValue() : undefined) {
      this.imageShowHide(true);
      this.setImageReady(true);
    } else {
      this.imageShowHide(false);
      this.setImageReady(false);
    }
  }

  onUpload(form) {
    this.imageMedia = form.fields.profileImage.editor.getValue();
    if (this.imageMedia) {
      this.setImageReady(false);
      this.ui.removeButton.hide();
      ImageHelpers.getNaturalDimensions(this.imageMedia.getFile())
        .then(this.calculateMobileImageCrop)
        .then(this.saveMedia);
    } else {
      const errors = form.fields.profileImage.editor.getError();

      if (errors) {
        window.app.layout.flash.error(errors);
      }

      this.modelDemo.set('profileImage', null);
      this._profileImageReset();
      this.setImageReady(this.model.get('profileImage') != null);
    }
  }

  saveMedia(cropped) {
    const mediaPollSuccess = () => {
      window.app.layout.hideSpinner();
      if (this.isDestroyed) {
        return;
      }

      const file = this.imageMedia.get('originalFile');
      file.destroyFileInput();
      this.imageShowHide(true);
      this.setImageReady(true);
      this.modelDemo.set('profileImage', this.imageMedia);
      this.userForm.update(this.modelDemo);
    };

    const mediaPollError = () => {
      window.app.layout.hideSpinner();

      if (this.isDestroyed) {
        return;
      }

      window.app.layout.flash.error(I18n.t('general.upload.file.error'));
      this._profileImageReset();
    };

    const pollForMediaSize = () => {
      window.app.layout.showSpinner();
      MediaHelpers.mediaImageFileSizesStatusPoll(this.imageMedia, mediaPollSuccess, mediaPollError);
    };

    const error = this.userForm.fields.profileImage != null ? this.userForm.fields.profileImage.editor.getError() : undefined;

    if (this.imageMedia && !error) {
      this.imageMedia.set('cropped', cropped);
      window.app.layout.showSpinner();
      MediaHelpers.saveMedia(this.imageMedia).then(pollForMediaSize)
        .always(() => {
          window.app.layout.hideSpinner();
        });
    } else {
      if (error) {
        window.app.layout.flash.error(error);
        if (this.userForm.fields.profileImage != null) {
          this.userForm.fields.profileImage.editor.clearError();
        }
      }
      this.imageShowHide(false);
    }
  }

  setImageReady(isReady) {
    this.imageReady = isReady;
    if (isReady) {
      this.actionBar.enableButton('profile-save');
      this.ui.removeButton.show();
    } else {
      this.actionBar.disableButton('profile-save');
      this.ui.removeButton.hide();
    }
  }

  imageShowHide(show) {
    this.ui.adjustPhoto.toggle(show);
    this.ui.choosePhoto.toggle(!show);
  }

  calculateMobileImageCrop(width, height) {
    let newHeight, newWidth, newX, newY;
    if (width > height) {
      newWidth = (newHeight = height);
      newX = Math.floor((width - height) / 2);
      newY = 0;
    } else {
      newWidth = (newHeight = width);
      newY = Math.floor((height - width) / 2);
      newX = 0;
    }

    return {
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight
    };
  }

  _profileImageReset() {
    this.imageMedia = null;
    this.imageShowHide(false);
    this.setImageReady(false);
  }
}

module.exports = ProfileUserSettingView;
