const Backbone = require('Backbone');
const _ = require('underscore');

const { COMPLETE, INCOMPLETE, NONE } = require('@common/data/models/objectives/ObjectiveConnectorStatus');
const PREVIOUS_CONNECTOR_STATUS_FIELD_NAME = 'previousConnectionStatus';
const NEXT_CONNECTOR_STATUS_FIELD_NAME = 'nextConnectorStatus';

/*
 * Interface for retrieving milestone data from the guided learning Item set and the milestone progress DTO
 * Generates the model for the MilestoneRowView
 * One MilestoneInterface per milestone in the guided learning path
 * Created By: MilestoneInterfaceFactory
 */
class MilestoneInterface extends Backbone.Model {
  initialize(prereqs, milestoneObjective, milestoneProgress) {
    this.milestoneProgress = milestoneProgress;
    this.prereqs = prereqs;
    this.milestoneObjective = milestoneObjective;

    // Empty check for case where items are linked to the last milestone
    if (!_.isEmpty(milestoneObjective)) {
      this.milestoneRowViewModel = new Backbone.Model({
        prerequisiteId: this.milestoneObjective.prerequisiteId,
        isMilestoneComplete: this.isMilestoneComplete(),
        isGrandfathered: this.milestoneProgress.getStatus() === 'Grandfathered',
        status: this.milestoneProgress.getStatus(),
        pointsAvailable: this.milestoneObjective.pointsAvailable,
        pointsEarned: this.milestoneObjective.objectiveDetails.pointsEarned,
        bonusPointsAvailable: this.milestoneObjective.objectiveDetails.bonusPointsAvailable,
        bonusPointsEarned: this.milestoneObjective.objectiveDetails.bonusPointsEarned,
        name: this.milestoneObjective.name,
        completionDate: this.milestoneObjective.completionDate,
        dueDate: this.milestoneProgress.getDueDate(),
        // If enrollmentStatus is undefined (because it's a Guided Learning program)
        // then we want the status to behave as if it were enrolled.
        isEnrolled: this.milestoneObjective.enrolled !== undefined ? this.milestoneObjective.enrolled : true,
        duration: this.milestoneObjective.objectiveDetails.duration,
        startDate: this.milestoneProgress.getStartDate(),
        timeRemaining: this.milestoneProgress.getTimeRemaining(),
        completeOnTime: this.milestoneProgress.getCompletedOnTime(),
        previousVisualConnectorStatus: this.getPreviousVisualConnectorStatus(),
        nextVisualConnectorStatus: this.getNextVisualConnectorStatus()
      });

      this.milestoneProgress.on('change', () => {
        this.trigger('change');
        this.milestoneRowViewModel.set({
          isMilestoneComplete: this.isMilestoneComplete(),
          status: this.milestoneProgress.getStatus(),
          isGrandfathered: this.milestoneProgress.getStatus() === 'Grandfathered',
          completionDate: this.milestoneProgress.getCompletionDate(),
          dueDate: this.milestoneProgress.getDueDate(),
          startDate: this.milestoneProgress.getStartDate(),
          completeOnTime: this.milestoneProgress.getCompletedOnTime(),
          timeRemaining: this.milestoneProgress.getTimeRemaining()
        });
      });

      this.on('change', () => {
        this.milestoneRowViewModel.set({
          numComplete: this.get('numComplete'),
          numItemsTotal: this.get('numItemsTotal'),
          previousVisualConnectorStatus: this.getPreviousVisualConnectorStatus(),
          nextVisualConnectorStatus: this.getNextVisualConnectorStatus()
        });
      });
    }
  }

  isMilestoneComplete() {
    return this.milestoneObjective.completionDate != null;
  }

  hasMilestone() {
    return !(_.isEmpty(this.milestoneObjective));
  }

  isMilestoneLinked() {
    return this.milestoneObjective.prerequisiteId != null;
  }

  isFirstPrerequisiteLinkedAbove() {
    return this.prereqs[0][0].prerequisiteId != null;
  }

  getPrereqs() {
    return this.prereqs;
  }

  getMilestoneRowViewModel() {
    if (this.milestoneRowViewModel === undefined) {
      throw new Error('To get the MilestoneRowViewModel it must first be defined');
    }
    return this.milestoneRowViewModel;
  }

  getPreviousVisualConnectorStatus() {
    return this.get(PREVIOUS_CONNECTOR_STATUS_FIELD_NAME) || NONE;
  }

  setPreviousVisualConnectorStatus(newStatus) {
    if ([COMPLETE, INCOMPLETE, NONE].includes(newStatus)) {
      this.set(PREVIOUS_CONNECTOR_STATUS_FIELD_NAME, newStatus);
    }
  }

  getNextVisualConnectorStatus() {
    return this.get(NEXT_CONNECTOR_STATUS_FIELD_NAME) || NONE;
  }

  setNextVisualConnectorStatus(newStatus) {
    if ([COMPLETE, INCOMPLETE, NONE].includes(newStatus)) {
      this.set(NEXT_CONNECTOR_STATUS_FIELD_NAME, newStatus);
    }
  }

  isCompleteable() {
    let keys = Object.keys(this.attributes);
    let isCompleteable = true;


    keys = keys.filter((value) => {
      return !_.isNaN(parseInt(value, 10));
    });

    for (const key of keys) {
      for (const item of this.attributes[key]) {
        if (item.locked) {
          continue;
        }
        isCompleteable = item.actionItem !== null && item.complete === false;
      }
    }

    return isCompleteable;
  }
}

module.exports = MilestoneInterface;
