import {
  Rating as MuiRating,
  styled,
  type RatingProps as MuiRatingProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxRatingProps = MuiRatingProps & {
  /**
   * The color of the filled icons.
  */
  filledColor?: string
  /**
   * The color of the empty icons.
  */
  emptyColor?: string
};

const styledOptions = {
  name: 'AxRating'
};

const StyledAxRating = styled(MuiRating, styledOptions)<AxRatingProps>(({
  theme,
  filledColor,
  emptyColor
}) => {
  return {
    label: {
      marginBottom: 0
    },
    '.MuiRating-iconFilled': {
      color: filledColor ?? theme.uiKit.colorGold
    },
    '.MuiRating-iconEmpty': {
      color: emptyColor ?? theme.uiKit.colorGrey60
    }
  };
});

/**
 * A wrapper around the MUI Rating component.
 *
 * ### Links
 * - [Rating | MUI](https://mui.com/components/rating/)
 */
export const AxRating = forwardRef(({
  ...otherProps
}: AxRatingProps, ref: Ref<HTMLSpanElement>) => {
  const RatingProps: AxRatingProps = {
    ...otherProps
  };

  return (
    <StyledAxRating { ...RatingProps } ref={ ref } />
  );
});

export default AxRating;
