const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');
const AssessmentType = require('@common/data/enums/AssessmentType');

module.exports = {
  [GuidedLearningObjectiveType.TOPIC_OBJECTIVE]: AssessmentType.IntroductoryTraining,
  [GuidedLearningObjectiveType.CERTIFICATION_OBJECTIVE]: AssessmentType.CertificationTraining,
  [GuidedLearningObjectiveType.TASK_OBJECTIVE]: AssessmentType.TaskTraining,
  [GuidedLearningObjectiveType.EVENT_OBJECTIVE]: AssessmentType.EventEnrollment,
  [GuidedLearningObjectiveType.EVALUATION_OBJECTIVE]: AssessmentType.EvaluationTraining
};
