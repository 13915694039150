const DetailItem = require('@common/components/details/item/DetailItem');
const _ = require('underscore');

class MetadataDetailItemView extends DetailItem {
  initialize(options = {}) {
    super.initialize(options);
    ({
      bold: this.bold = false,
      valueClass: this.valueClass,
      iconClass: this.iconClass
    } = options);
  }

  className() {
    return 'metadata-detail-item text-muted ax-font__caption';
  }

  getTemplate() {
    return `
<% if (iconClass) { %><span class="qa-metadata-detail-item-icon <%- iconClass %>"></span><% } %>
<span class="value <%- valueClass %>"><%= getFormattedValue() %></span>
`;
  }

  templateHelpers() {
    return _.extend(super.templateHelpers(), {
      iconClass: this.iconClass,
      valueClass: this._getValueClass()
    });
  }

  _getValueClass() {
    let valueClass = 'ax-font__caption';
    if (this.valueClass) {
      valueClass += ` ${ this.valueClass }`;
    }
    if (this.bold) {
      valueClass += ' boldfont ax-font__caption--bold';
    }
    return valueClass;
  }
}

module.exports = MetadataDetailItemView;
