const _ = require('underscore');
const logging = require('logging');
const { guard } = require('DecaffeinateHelpers');

const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');

const FileFactory = require('@common/libs/file/FileFactory');
const FileHelpers = require('@common/libs/file/FileHelpers');
const MediaHelpers = require('@common/libs/file/MediaHelpers');
const MediaInput = require('@common/components/forms/editors/mediaThumbnail/Form.Editor.MediaInput');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const ActionBarButtonFactory = require('@common/components/actionBarButton/ActionBarButtonFactory');

require('jcrop');

const RequestedThumbnailDimensions = {
  height: 320,
  width: 460
};

const ASPECT_RATIO = 1;

class ProfileUserSettingImageUploaderView extends AccessibleModalView {
  id() {
    return 'modalview';
  }

  className() {
    return 'modal profile-image-upload';
  }

  events() {
    return Object.assign({}, super.events(), {
      'click @ui.removePhoto': 'onImageRemove',
      'keypress @ui.addPhoto, @ui.changePhoto': 'onKeyPress'
    });
  }

  regions() {
    return Object.assign({}, super.regions(), {
      fileInput: '.media-thumbnail-input'
    });
  }

  ui() {
    return Object.assign({}, super.ui(), {
      addPhoto: '.add-photo',
      changePhoto: '.change-photo',
      adjustPhoto: '.adjust-photo',
      choosePhoto: '.choose-photo',
      removePhoto: '.remove-wrap',
      choosePhotoThumbnail: '#choose-photo-thumbnail',
      cameraButton: '.camera-button',
      applyButton: '.add',
      previewWrap: '.preview-wrap',
      thumbnailWrap: '.thumbnail-wrap',
      mediaThumbnailWrap: '.media-thumbnail-wrap',
      thumbnailPreviews: '.thumbnail-preview, .thumbnail-preview-size'
    });
  }

  constructor(options = {}) {
    super(options);

    this.toggleThumbnail = this.toggleThumbnail.bind(this);
    this.hasValidImage = this.hasValidImage.bind(this);
    this.onNewUpload = this.onNewUpload.bind(this);
    this.onFailUpload = this.onFailUpload.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.calculateInitialSelect = this.calculateInitialSelect.bind(this);
    this.removeJcrop = this.removeJcrop.bind(this);
    this.getImageDimensions = this.getImageDimensions.bind(this);
    this.enableApplyButton = this.enableApplyButton.bind(this);
    this.closeModal = this.closeModal.bind(this);

    logging.info('ProfileUserSettingImageUploaderView - initialize');

    (({
      model: this.model,
      requestedDimensions: this.requestedDimensions
    } = options));

    this.jcrop = null;
    this.isRtl = I18n.isCurrentLanguageRtl();
  }

  contentTemplate() {
    const templateFn = _.tpl(require('../templates/ProfileUserSettingImageUploaderView.html'));
    return templateFn();
  }

  onRenderActionBar() {
    const button = ActionBarButtonFactory.createButtonWithCustomText(
      I18n.t('general.apply'),
      this.onClickSend.bind(this)
    );

    this.actionBar.setActionButton(button);
    this.actionBar.disableButton(ActionBarButtonFactory.ActionBarType.CUSTOM_TEXT);
  }

  viewDidAppear() {
    logging.info('ProfileUserSettingImageUploaderView - viewDidAppear');

    this.setupThumbnail();

    this.imageDimensions = this.getImageDimensions(this.ui.mediaThumbnailWrap);

    this.toggleThumbnail(this.hasValidImage());

    if (this.browserNotSupported) {
      this.ui.cameraButton.hide();
    }

    super.viewDidAppear();
  }

  toggleThumbnail(show) {
    if (show) {
      this.ui.previewWrap.removeClass('no-image');
    } else {
      this.ui.previewWrap.addClass('no-image');
    }

    this.ui.removePhoto.toggleClass('hidden', !show);
    this.ui.choosePhoto.toggle(!show);
    this.ui.adjustPhoto.toggle(show);
    this.ui.choosePhotoThumbnail.toggle(show);
  }

  hasValidImage() {
    return (this.model != null) && !guard(this.model.getFile(), (x) => {
      return x.isNew();
    });
  }

  setupThumbnail() {
    logging.info('ProfileUserSettingImageUploaderView - setupThumbnail');

    this.getRegion('fileInput').show(this._getEditorView());

    if (this.hasValidImage()) {
      this.showMedia();
    }

    this.listenTo(this.editorView, 'change', this.onChangeMedia);
  }

  onChangeMedia(changedMediaInput) {
    // Immediately send original file to server if this is a new upload or changed image or allow failure to be caught if error already exists
    if (!this.hasValidImage() || ((this.imageMedia !== changedMediaInput.getValue()) && (this.imageMedia !== undefined)) || this.editorView.getError()) {
      const changedMedia = changedMediaInput.getValue();
      const error = this.editorView.getError();

      if (changedMedia) {
        this.imageMedia = changedMedia;
      }
      if (this.imageMedia) {
        this.model = this.imageMedia;
      }

      if (!error) {
        window.app.layout.showSpinner();
        FileHelpers.saveFile(this.imageMedia.getFile()).then(this.onNewUpload, this.onFailUpload);
      } else {
        window.app.layout.flash.error(error);
        this.editorView.clearError();
        this.enableApplyButton();
      }
    } else {
      this.showMedia();
    }
  }

  onNewUpload() {
    window.app.layout.hideSpinner();
    if (this.isDestroyed) {
      return;
    }
    this.enableApplyButton();
    this.toggleThumbnail(true);
    this.model = this.imageMedia;
    this.addJcrop();
  }

  onFailUpload(file, xhr) {
    window.app.layout.hideSpinner();
    let message = I18n.t('general.upload.file.error');
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    if (exception.getErrorCode() === AxonifyExceptionCode.SIMPLE_FILE_UPLOAD_RETRY) {
      message = I18n.t('flash.simpleFileUploadRetry');
    }
    window.app.layout.flash.error(message);
    this.enableApplyButton();
  }

  showMedia() {
    this.imageMedia = this.model;
    this.toggleThumbnail(true);
    this.enableApplyButton();
    this.addJcrop();
  }

  showPreview(coordsReal) {
    this.selectedX = Math.round(coordsReal.x);
    this.selectedY = Math.round(coordsReal.y);
    this.selectedWidth = Math.round(coordsReal.w);
    this.selectedHeight = Math.round(coordsReal.h);

    if (this.jcrop == null) {
      return;
    }

    this.ui.thumbnailPreviews.each((index, element) => {
      const rx = $(element).width() / coordsReal.w;
      const ry = $(element).height() / coordsReal.h;
      const width = Math.round(rx * this.imageOriginalWidth);
      const height = Math.round(ry * this.imageOriginalHeight);
      const left = Math.round(rx * coordsReal.x);
      const right = width - Math.round(rx * (coordsReal.x + coordsReal.w));
      const top = Math.round(ry * coordsReal.y);
      $(element).find('img')
        .css({
          width: `${ width }px`,
          height: `${ height }px`,
          marginLeft: this.isRtl ? null : `-${ left }px`,
          marginRight: this.isRtl ? `-${ right }px` : null,
          marginTop: `-${ top }px`
        });
    });
  }

  setImageThumbnailDimensions($img) {
    const {
      naturalHeight,
      naturalWidth
    } = $img[0];

    const availableHeight = RequestedThumbnailDimensions.height;
    const availableWidth = RequestedThumbnailDimensions.width;

    const widthRatio = availableWidth / naturalWidth;
    const heightRatio = availableHeight / naturalHeight;

    const scale = Math.min(widthRatio, heightRatio);

    const width = naturalWidth * scale;
    const height = naturalHeight * scale;

    $img.css({
      width,
      height
    });
  }

  _getEditorView() {
    this.editorView = new MediaInput({
      $cameraButton: this.ui.cameraButton,
      fileFactory: FileFactory,
      fileOptions: {
        videoAllowed: false,
        isSecureUpload: false
      }
    });

    return this.editorView;
  }

  addJcrop() {
    this.removeJcrop();

    ImageHelpers.loadFileImage(this.model.getFile()).then((e, image) => {
      if (this.isDestroyed || !this.hasValidImage()) {
        return;
      }

      this.imageOriginalHeight = image.naturalHeight;
      this.imageOriginalWidth = image.naturalWidth;

      const $image = $(image);
      $image.addClass('image-thumbnail');

      this.ui.thumbnailWrap.append($image);

      this.setImageThumbnailDimensions($image);

      const path = this.model.getFile().acquireUrl();
      this.ui.previewWrap.find('img').attr('src', path)
        .show();

      this.jcrop = $.Jcrop($image);
      this.jcrop.setOptions({
        onChange: this.showPreview,
        onSelect: this.showPreview,
        trueSize: [this.imageOriginalWidth, this.imageOriginalHeight],
        allowSelect: false,
        aspectRatio: ASPECT_RATIO,
        setSelect: this.calculateInitialSelect()
      });

      this.$('.jcrop-holder').attr('aria-hidden', 'true');
    });
  }

  calculateInitialSelect() {
    let maxSelectedH, maxSelectedW, minSelectedH, minSelectedW;

    const cropped = this.model != null ? this.model.get('cropped') : undefined;

    if (cropped) {
      minSelectedW = cropped.x;
      maxSelectedW = cropped.x + cropped.width;
      minSelectedH = cropped.y;
      maxSelectedH = cropped.y + cropped.height;
      return [minSelectedW, minSelectedH, maxSelectedW, maxSelectedH];
    }

    const centerW = this.imageOriginalWidth / 2;
    const centerH = this.imageOriginalHeight / 2;

    if (this.imageOriginalWidth > this.imageOriginalHeight) {
      const widthR = this.imageOriginalWidth / 4;

      minSelectedW = centerW - widthR;
      maxSelectedW = centerW + widthR;
      minSelectedH = centerH - widthR;
      maxSelectedH = centerH + widthR;
    } else {
      const heightR = this.imageOriginalHeight / 4;

      minSelectedW = centerW - heightR;
      maxSelectedW = centerW + heightR;
      minSelectedH = centerH - heightR;
      maxSelectedH = centerH + heightR;
    }

    return [minSelectedW, minSelectedH, maxSelectedW, maxSelectedH];
  }

  removeJcrop() {
    if (this.ui.previewWrap != null) {
      this.ui.previewWrap.find('img').hide()
        .attr('src', '')
        .css({
          width: '',
          height: '',
          marginLeft: '',
          marginTop: ''
        });
    }

    if (this.jcrop != null) {
      this.jcrop.destroy();
      this.jcrop = null;
    }
  }

  resetImage() {
    this.removeJcrop();
    this.imageMedia = null;
    this.model.clear({silent: true});
    this.toggleThumbnail(false);
    this.enableApplyButton();
  }

  onImageRemove() {
    this.resetImage();
  }

  getImageDimensions($image) {
    return {
      width: $image.width(),
      height: $image.height()
    };
  }

  enableApplyButton() {
    this.actionBar.enableButton(ActionBarButtonFactory.ActionBarType.CUSTOM_TEXT);
    this.ui.applyButton.removeClass('disabled').prop('disabled', false);
  }

  onClickSend() {
    let cropped;

    if (!this.imageMedia) {
      this.trigger('add:image', null);
      return;
    }

    if (!isNaN(this.selectedX) && !isNaN(this.selectedY) && !isNaN(this.selectedWidth) && !isNaN(this.selectedHeight)) {
      cropped = {
        x: (this.selectedX > 0) ? this.selectedX : 0,
        y: (this.selectedY > 0) ? this.selectedY : 0,
        width: this.selectedWidth,
        height: this.selectedHeight
      };
    }

    window.app.layout.showSpinner();

    this.model.unset('uuid');
    this.model.set('cropped', cropped);

    const mediaPollSuccess = () => {
      window.app.layout.hideSpinner();
      if (this.isDestroyed) {
        return;
      }
      this.enableApplyButton();
      const file = this.model.getFile();
      file.destroyFileInput();
      this.trigger('add:image', this.model);
    };

    const mediaPollError = () => {
      window.app.layout.hideSpinner();
      if (this.isDestroyed) {
        return;
      }
      this.enableApplyButton();
      window.app.layout.flash.error(I18n.t('general.upload.file.error'));
    };

    const pollForMediaSize = () => {
      return MediaHelpers.mediaImageFileSizesStatusPoll(this.model, mediaPollSuccess, mediaPollError);
    };

    MediaHelpers.saveMedia(this.model).then(pollForMediaSize, mediaPollError);
  }

  onKeyPress(e) {
    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      $(e.target).trigger('click');
    }
  }

  closeModal() {
    this.dismiss();
    return true;
  }

  onClose() {
    super.onClose();
    return true;
  }
}

module.exports = ProfileUserSettingImageUploaderView;
