const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');

class GuidedLearningPathLinkView extends ItemView {

  getTemplate() {
    return `<span class="icon--badge icon--badge-md icon--badge-guided-learning ${ this._getIcon() }"></span>
  <span class="learning-path-item__text">${ this._constructPathMarkup() }</span>`;
  }

  tagName() {
    return 'li';
  }

  className() {
    return 'learning-path-block';
  }

  events() {
    return {
      'click .path': 'onClickPath'
    };
  }

  _getIcon() {
    return 'icon-guided_learning';
  }

  _constructPathMarkup() {
    const pathName = `<a class="path" href="#">${ this.model.get('name') }</a>`;
    return I18n.t('selfDirected.topicDetails.topicPath', { pathName });
  }

  onClickPath() {
    window.app.sessionController.redirectGL({
      pageId: GuidedLearningPageEnum.DetailsPage,
      pageOptions: { programId: this.model.get('id') }
    });
  }

}

module.exports = GuidedLearningPathLinkView;
