const PageableCollection = require('@common/components/pageableList/PageableCollection');

class CommunityInsightsRecentPagesCollection extends PageableCollection {
  constructor(models, options = {}) {
    options.pageSize = 10;
    super(models, options);
    this.setTeamBasedSelected(options.includeTeamBasedCommunities);
  }

  apiEndpoint() {
    return '/page/engagement/initial';
  }

  setTeamBasedSelected(isSelected) {
    this.extraParams['includeTeamBasedCommunities'] = isSelected;
  }

  getTeamBasedSelected() {
    return this.extraParams['includeTeamBasedCommunities'];
  }
}

module.exports = CommunityInsightsRecentPagesCollection;
