const _ = require('underscore');
const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const CommunitiesManagementAccessLevelHelpers = require('@training/apps/search/communitiesManagement/CommunitiesManagementAccessLevelHelpers');

class TeamAccessTableView extends TableLayoutView {
  initialize(options = {}) {
    super.initialize(options);

    ({
      values: this.values
    } = options);
    this.updateAccessLevel = this.updateAccessLevel.bind(this);
  }

  collectionEvents() {
    return {
      'change:accessLevel': this._onChangeUserTypeAccessLevel
    };
  }

  onAttach() {
    BrowserHelpers.triggerResize(true);
    this._applyInheritenceOnPageLoad();
  }

  // Driver of user type access value inheritence logic
  _onChangeUserTypeAccessLevel(userTypeModel, selectedValue) {
    const index = this.collection.indexOf(userTypeModel);

    // Always reset the state of the current row's checkboxes first
    this._setRowCheckboxState(userTypeModel, undefined, undefined);

    if (userTypeModel.get('accessLevel') != null) {
      // Look for a value to inherit in a row above
      this._handleValueToInherit(index, userTypeModel);
    }

    // Impose the currently selected value downwards on rows below
    this._enforceValueDownward(index, selectedValue);

    this.updateAccessLevel();
  }

  _applyInheritenceOnPageLoad() {
    // Find a value to inherit and apply it from that row's value downward
    const finalRowModel = this.collection.at(this.collection.length - 1);
    const modelToInherit = this._findModelToInherit(finalRowModel);
    if (modelToInherit != null) {
      const index = this.collection.indexOf(modelToInherit);
      this._enforceValueDownward(index, modelToInherit.get('accessLevel'));
    }
  }

  _findModelToInherit(model) {
    let modelToInherit = model;
    let valueToInherit = 0;

    const index = this.collection.indexOf(model);
    for (let i = index - 1; i >= 0; i--) {
      const userTypeModel = this.collection.at(i);
      const level = userTypeModel.get('accessLevel');
      if (level > valueToInherit) {
        valueToInherit = level;
        modelToInherit = userTypeModel;
      }
    }

    // If the modelToInherit is the same as the current model that was passed in, there's nothing to inherit & thus
    // return undefined
    return modelToInherit === model ? undefined : modelToInherit;
  }

  _handleValueToInherit(index, userTypeModel) {
    let inactive, valueToInherit;
    const currentAccessLevel = userTypeModel.get('accessLevel');

    // Check for model value to inherit in rows above the current row.
    // This scenario is really only in play when deselecting a checkbox
    const modelToInherit = this._findModelToInherit(userTypeModel);
    if (modelToInherit != null) {
      valueToInherit = modelToInherit.get('accessLevel');
    }

    // When a value to inherit is found to be greater than the current row's accessLevel, we set the current row's
    // checkboxes to inactive
    if (valueToInherit >= currentAccessLevel) {
      const valuesClone = [...this.values];
      const accessLevelIndex = valuesClone.indexOf(currentAccessLevel);
      inactive = valuesClone.splice(0, accessLevelIndex + 1);

      // Defer because the ordering of event callbacks differ in IE, and the visuals for the checkbox are altered in an
      // event callback responding to the checkboxState data changes. See TeamAccessItemView
      _.defer(this._setRowCheckboxState, userTypeModel, inactive, undefined, false);
    }
  }

  _enforceValueDownward(index, selectedValue) {
    let inactive, disabled;

    for (let i = index + 1; i < this.collection.length; i++) {
      const rowModel = this.collection.at(i);
      const level = rowModel.get('accessLevel') || 0;

      // Anytime a row's access level is less than the selected value, we set the existing access level checkbox
      // selections to inactive and the remaining inherited ones to disabled
      if (level <= selectedValue) {
        const valuesClone = [...this.values];
        const accessLevelIndex = valuesClone.indexOf(level);
        inactive = valuesClone.splice(0, accessLevelIndex + 1);
        const inheritedIndex = valuesClone.indexOf(selectedValue);
        disabled = valuesClone.splice(0, inheritedIndex + 1);

        this._setRowCheckboxState(rowModel, inactive, disabled);
      } else if (i === 1 && level > 0) {
        // This is to prevent the third row from always inheriting the first. If there is a value in the second row,
        // the third row needs to respect the second, so no sense continuing the loop after the second row is handled.
        // This case really only comes up when an 'everyone' permission is deselected and there's an existing
        // selection in the 'report' row
        this._setRowCheckboxState(rowModel, inactive, disabled);
        break;
      } else if (level > selectedValue) {
        // Special case where we need to make absolutely sure that the checkbox corresponding to a row's highest
        // access level is enabled when this level is no longer being inherited by another rows higher selection.
        // Easily done by running this row's logic to set its access-level value again
        const childView = this.children.findByModel(rowModel);
        childView.setValue(level);
      } else {
        this._setRowCheckboxState(rowModel, inactive, disabled);
      }
    }
  }

  _setRowCheckboxState(rowModel, inactiveValues, disabledValues, shouldSetDisabled = true) {
    let disabled;

    // Maintain disabled as a null value if it happens to be an empty array. Otherwise set it accordingly
    if (disabledValues != null && disabledValues.length > 0) {
      disabled = disabledValues;
    }

    const checkboxState = {
      inactive: inactiveValues
    };
    if (shouldSetDisabled) {
      Object.assign(checkboxState, { disabled });
    }

    // We force trigger a change on checkbox state b/c we rely on this event being triggered even when the changing
    // value is actually the same
    rowModel.set({ checkboxState }, { silent: true });
    rowModel.trigger('change:checkboxState', rowModel, rowModel.get('checkboxState'));
  }

  // For team communities, we need to update access levels on the model on check/uncheck
  // Need to update all because of the forced inheritance behaviour
  updateAccessLevel() {
    // We should only have 'Everyone', 'Report', and 'Managers'.
    const models = this.collection.models;

    // Everyone - models[0]
    this.model.set('memberAccessLevel', CommunitiesManagementAccessLevelHelpers.toString(models[0].get('accessLevel')));
    // Report - models[1]
    this.model.set('leaderAccessLevel', CommunitiesManagementAccessLevelHelpers.toString(models[1].get('accessLevel')));
    // Managers - models[2]
    this.model.set('managerAccessLevel', CommunitiesManagementAccessLevelHelpers.toString(models[2].get('accessLevel')));
  }
}

module.exports = TeamAccessTableView;
