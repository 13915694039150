const Marionette = require('Marionette');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');

const BehaviourTypeEnum = require('@common/data/enums/BehaviourTypeEnum');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const DetailViewConfig = require('@common/components/details/DetailViewConfig');

const create = (behaviourForm) => {
  return new StackedCollectionView({className: `review-print-form evaluation-form`,
    model: behaviourForm,
    viewConfigs: _.flatten([
      getFormTitle(behaviourForm),
      getDescriptionView(behaviourForm),
      getTypeView(),
      getDetailsView(behaviourForm),
      behaviourForm.getObservations().map((behaviourItem) => {
        return getEvaluationRecordItem(behaviourItem);
      }),
      getGeneralNotesView(),
      getResultsView()
    ])});
};

const getFormTitle = (behaviourForm) => {
  return {
    viewClass: Marionette.ItemView,
    viewOptions: {
      tagName: 'h1',
      className: 'behavior-form-title',
      template: behaviourForm.getEvaluationTitle()
    }
  };
};

const getDescriptionView = (behaviourForm) => {
  if (behaviourForm.getDescription()) {
    return {
      viewClass: Marionette.ItemView,
      viewOptions: {
        className: 'behavior-form-description',
        template: behaviourForm.getDescription()
      }
    };
  }

  return undefined;
};

const getTypeView = () => {
  const formTypeKey = `observations.print.userBehaviour${ BehaviourTypeEnum.Evaluation }`;
  return {
    viewClass: Marionette.ItemView,
    viewOptions: {
      className: 'behavior-form-type clearfix',
      template: `<div class="detail-item">
  <span class="label">${ I18n.t('observations.details.labels.formType') }</span>
  <span class="underline value">${ I18n.t(formTypeKey) }</span>
</div>`
    }
  };
};

const getDetailsView = (behaviourForm) => {
  return {
    viewClass: StackedCollectionView,
    viewOptions: {
      className: 'stacked-collection-view behavior-form-details clearfix',
      viewConfigModel: DetailViewConfig,
      viewConfigs: _.flatten([
        getObserverDetailConfig(),
        getObserveeDetailConfig(behaviourForm),
        getJobTitleDetailConfig(),
        getTeamDetailConfig(),
        getDepartmentDetailConfig(behaviourForm),
        getEffectiveDateDetailConfig()
      ])
    }
  };
};

const getObserverDetailConfig = () => {
  const labelKey = 'observations.details.labels.evaluator';
  return {
    id: 'observer',
    viewOptions: {
      label: I18n.t(labelKey),
      value: ''
    }
  };
};

const getObserveeDetailConfig = (behaviourForm) => {
  const details = [{
    id: 'observee',
    viewOptions: {
      label: I18n.t('observations.details.labels.observee'),
      key: 'form.observee.fullname'
    }
  }];

  if (!behaviourForm.getIdPrivacyEnabled()) {
    details.push({
      id: 'observeeId',
      viewOptions: {
        label: I18n.t('observations.details.labels.employeeId'),
        key: 'form.observee.employeeId'
      }
    });
  }

  return details;
};

const getTeamDetailConfig = () => {
  return {
    id: 'team',
    viewOptions: {
      label: I18n.t('observations.details.labels.team'),
      key: 'form.location.name'
    }
  };
};

const getDepartmentDetailConfig = (behaviourForm) => {
  return {
    id: 'department',
    viewOptions: {
      label: I18n.t('observations.details.labels.department'),
      key: (behaviourForm.getDepartment() ? 'form.department.name' : undefined),
      value: (behaviourForm.getDepartment() ? undefined : '—')
    }
  };
};

const getJobTitleDetailConfig = () => {
  return {
    id: 'jobTitle',
    viewOptions: {
      label: I18n.t('observations.details.labels.jobTitle'),
      key: 'form.jobTitle.name'
    }
  };
};

const getEffectiveDateDetailConfig = () => {
  return {
    id: 'effectiveDate',
    viewOptions: {
      label: I18n.t('GuidedLearning.completionDate', { completed: '' }),
      value: ''
    }
  };
};

const getEvaluationRecordItem = (evaluation) => {
  return {
    viewClass: Marionette.ItemView,
    viewOptions: {
      model: new Backbone.Model(evaluation),
      tagName: 'table',
      className: 'observed-behaviours evaluation avoid-page-break-inside',
      template: require('@training/apps/common/evaluations/EvaluationItemTemplatePrint.html'),
      templateHelpers: {
        yesIconClass: 'icon-empty_circle',
        noIconClass: 'icon-empty_circle'
      }
    }
  };
};

const getGeneralNotesView = () => {
  const formType = BehaviourTypeEnum.Evaluation.toLowerCase();
  return {
    viewClass: Marionette.ItemView,
    viewOptions: {
      tagName: 'table',
      className: `behaviour-form-notes ${ formType } avoid-page-break-inside`,
      template: `\
<tbody>
  <tr><td class="behaviour-name">${ I18n.t('observations.details.labels.generalNotes') }</td></tr>
  <tr><td>
    <div class="behaviour-comment">${ I18n.t('observations.details.labels.notes') }<br/></div>
  </td></tr>
</tbody>`
    }
  };
};

const getResultsView = () => {
  // Generate the status/score markup strings
  const statusMarkup = `\
<input type="radio" id="radioPass" disabled /><label for="radioPass">${ I18n.t('observations.details.labels.pass') }</label>
<input type="radio" id="radioFail" disabled /><label for="radioFail">${ I18n.t('observations.details.labels.fail') }</label>`;
  const scoreMarkup = '<span class="blank-field"></span>';

  return {
    viewClass: Marionette.ItemView,
    viewOptions: {
      className: 'behavior-form-results clearfix avoid-page-break-inside',
      template: `\
<h2>${ I18n.t('observations.details.labels.evaluationResults') }</h2>
<div>
  <div class="status">
    <div class="label">${ I18n.t('observations.details.labels.status') }</div>
    <div class="value">${ statusMarkup }</div>
  </div>
  <div class="score">
    <div class="label">${ I18n.t('observations.details.labels.evaluationScore') }</div>
    <div class="value">${ scoreMarkup }</div>
  </div>
</div>`
    }
  };

};

module.exports = {
  create
};
