const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const LayeredLayout = require('@common/components/layeredLayout/LayeredLayout');
const ZoomOverlayContentRegionController = require('./ZoomOverlayContentRegionController');
const ZoomOverlayRegionController = require('./ZoomOverlayRegionController');


class ZoomOverlayController extends LayoutController {
  constructor(options = {}) {
    super(options);
    ({
      media: this.media,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight,
      onClick: this.onClick = () => {}
    } = options);
  }

  viewDefinition() {
    return LayeredLayout;
  }

  regionControllers() {
    return {
      contentRegion: {
        ViewControllerClass: ZoomOverlayContentRegionController,
        media: this.media,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight
      },
      overlayRegion: {
        ViewControllerClass: ZoomOverlayRegionController,
        media: this.media,
        onClick: this.onClick
      }
    };
  }

  onViewInflate(controller, view) {
    view.toggleOverlay(true);
  }
}

module.exports = ZoomOverlayController;
