const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  BLOB: 'blob',
  TRANSCODED: 'transcoded',
  WMP: 'wmp',
  OTHER: 'other'
});
