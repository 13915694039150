// Module
var code = `<div class="media-thumbnail-input"></div>
<div class="cropped-image-upload ax-grid ax-grid--no-gutter">
  <div class="adjust-photo-wrap ax-grid__col--7">
    <div class="choose-photo">
      <label class="adjust-photo-title"><%-  t('photoCrop.choosePhoto.title')  %></label>
      <div class="adjust-photo-subTitle">
        <%=  t('photoCrop.choosePhoto.subTitle', { maximumImageSize: maximumImageSize })  %>
      </div>
      <div class="axon-button normal js-camera-button qa-add-photo-button" role="button" tabindex="0">
        <%-  t('photoCrop.choosePhoto.button.title')  %>
      </div>
    </div>
  </div>
  <div class="media-previews"></div>
</div>
`;
// Exports
module.exports = code;