const Backbone = require('Backbone');
const Marionette = require('Marionette');
const DateHelpers = require('@common/libs/dateHelpers');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const KeyCode = require('@common/data/enums/KeyCode');
const I18n = require('@common/libs/I18n');

class MessageItemView extends Marionette.ItemView {

  getTemplate() {
    return require('@training/apps/training/templates/_message_item.html');
  }

  className() {
    return 'message-item clearfix';
  }

  attributes() {
    return {
      role: 'article',
      'aria-label': this.generateAriaLabel()
    };
  }

  ui() {
    return {
      timeSince: '.time-since'
    };
  }

  events() {
    return {
      click: 'onMessageItemClicked',
      'keydown .row-arrow-link': 'onMessageItemKeydown'
    };
  }

  modelEvents() {
    return {
      change: 'render'
    };
  }

  templateHelpers() {
    const parentMessage = this.model.get('parentInfo');
    const title = parentMessage ? parentMessage.subject : '';
    const message = parentMessage ? HTMLHelpers.stripHtmlForDisplay(parentMessage.excerpt) : '';
    const timeSince = this.getTimeSince(true);
    const linkAriaLabel = this.generateButtonAriaLabel();

    return {
      title,
      message,
      timeSince,
      linkAriaLabel
    };
  }

  onMessageItemClicked() {
    const parentMessage = this.model.get('parentInfo');

    const messageId = parentMessage ? parentMessage.typeId : '';
    const messageLanguage = this.model.get('language');

    Backbone.history.navigate(`#hub/message/${ messageId }-${ messageLanguage }`, { trigger: true });
  }

  onMessageItemKeydown(e) {
    if (e.which === KeyCode.ENTER) {
      this.onMessageItemClicked();
    }
  }

  getTimeSince(isForDisplay) {
    const viewDate = this.model.get('viewDate');
    const viewDateClient = viewDate - window.apps.auth.session.serverTimeOffset;
    const newsAge = DateHelpers.timeFromEvent(viewDateClient);

    if (isForDisplay) {
      return `&nbsp; &#9679; &nbsp; ${ newsAge }`;
    }
    return newsAge;

  }

  generateAriaLabel() {
    const parentMessage = this.model.get('parentInfo');
    const title = parentMessage ? parentMessage.subject : '';
    const timeSince = this.getTimeSince();

    return I18n.t('news.messageDetails.title') + '. ' + title + '. ' + timeSince + '.';
  }

  generateButtonAriaLabel() {
    const parentMessage = this.model.get('parentInfo');
    const title = parentMessage ? parentMessage.subject : '';
    const timeSince = this.getTimeSince();

    return I18n.t('news.messageDetails.title') + '. ' + title + '. ' + timeSince + '. ' + I18n.t('accessibility.clickPrompt');
  }
}

class NoMessagesItem extends Marionette.ItemView {
  getTemplate() {
    return '<p class="nonews"><%- t("news.noMessages") %></p>';
  }
}

class NoMoreMessages extends Marionette.ItemView {
  getTemplate() {
    return '<p class="nonews"><%- t("news.noMoreMessages") %></p>';
  }
}

module.exports = {
  MessageItemView,
  NoMessagesItem,
  NoMoreMessages
};
