import {
  SvgIcon,
  useTheme
} from '@mui/material';

type CheckProps = {
  disabled?: boolean;
  backgroundColor?: string;
};
export function Check(props: CheckProps) {
  const theme = useTheme();
  const {
    disabled = false,
    backgroundColor = theme.uiKit.linkColor
  } = props;

  return disabled ? (
    <SvgIcon
      viewBox='0 0 20 20'
      sx={{
        fill: 'none'
      }}>
      <rect
        x='0.5'
        y='0.5'
        width='19'
        height='19'
        rx='3.5'
        fill={ theme.uiKit.colorGrey20 }/>
      <rect
        x='0.5'
        y='0.5'
        width='19'
        height='19'
        rx='3.5'
        stroke={ theme.uiKit.colorGrey30 }/>
      <path d='M14.6601 6.67356C14.5955 6.60847 14.5187 6.5568 14.4341 6.52155C14.3495 6.48629 14.2587 6.46814 14.167 6.46814C14.0753 6.46814 13.9846 6.48629 13.9 6.52155C13.8153 6.5568 13.7385 6.60847 13.674 6.67356L8.50036 11.8541L6.32675 9.67356C6.25972 9.60881 6.18059 9.55789 6.09389 9.52372C6.00718 9.48955 5.9146 9.4728 5.82142 9.47441C5.72823 9.47602 5.63628 9.49597 5.55081 9.53312C5.46534 9.57027 5.38802 9.62389 5.32328 9.69092C5.25853 9.75795 5.20761 9.83707 5.17344 9.92378C5.13927 10.0105 5.12252 10.1031 5.12413 10.1962C5.12574 10.2894 5.14569 10.3814 5.18284 10.4669C5.21999 10.5523 5.27361 10.6296 5.34064 10.6944L8.0073 13.3611C8.07186 13.4261 8.14867 13.4778 8.23329 13.5131C8.31792 13.5483 8.40868 13.5665 8.50036 13.5665C8.59203 13.5665 8.6828 13.5483 8.76743 13.5131C8.85205 13.4778 8.92886 13.4261 8.99341 13.3611L14.6601 7.69439C14.7306 7.62936 14.7868 7.55043 14.8253 7.46259C14.8638 7.37474 14.8836 7.27988 14.8836 7.18397C14.8836 7.08807 14.8638 6.9932 14.8253 6.90536C14.7868 6.81751 14.7306 6.73858 14.6601 6.67356Z' fill={ theme.uiKit.colorGrey50 }/>
    </SvgIcon>
  ) : (
    <SvgIcon
      viewBox='0 0 20 20'
      sx={{
        fill: 'none'
      }}>
      <rect
        width='20'
        height='20'
        rx='4'
        fill={ backgroundColor }/>
      <path d='M14.6601 6.67356C14.5955 6.60847 14.5187 6.5568 14.4341 6.52155C14.3495 6.48629 14.2587 6.46814 14.167 6.46814C14.0753 6.46814 13.9846 6.48629 13.9 6.52155C13.8153 6.5568 13.7385 6.60847 13.674 6.67356L8.50036 11.8541L6.32675 9.67356C6.25972 9.60881 6.18059 9.55789 6.09389 9.52372C6.00718 9.48955 5.9146 9.4728 5.82142 9.47441C5.72823 9.47602 5.63628 9.49597 5.55081 9.53312C5.46534 9.57027 5.38802 9.62389 5.32328 9.69092C5.25853 9.75795 5.20761 9.83707 5.17344 9.92378C5.13927 10.0105 5.12252 10.1031 5.12413 10.1962C5.12574 10.2894 5.14569 10.3814 5.18284 10.4669C5.21999 10.5523 5.27361 10.6296 5.34064 10.6944L8.0073 13.3611C8.07186 13.4261 8.14867 13.4778 8.23329 13.5131C8.31792 13.5483 8.40868 13.5665 8.50036 13.5665C8.59203 13.5665 8.6828 13.5483 8.76743 13.5131C8.85205 13.4778 8.92886 13.4261 8.99341 13.3611L14.6601 7.69439C14.7306 7.62936 14.7868 7.55043 14.8253 7.46259C14.8638 7.37474 14.8836 7.27988 14.8836 7.18397C14.8836 7.08807 14.8638 6.9932 14.8253 6.90536C14.7868 6.81751 14.7306 6.73858 14.6601 6.67356Z' fill={ theme.uiKit.colorWhite }/>
    </SvgIcon>
  );
}
