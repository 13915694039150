const logging = require('logging');
const TincanCompletionTracker = require('./TincanCompletionTracker');
require('bluebird');

class NullTincanCompletionTracker extends TincanCompletionTracker {
  startTracking(tincanPackage, user) {
    logging.info(
      'TincanCompletionTracker - Logging a completion for the user: '
        + JSON.stringify(user?.pick('id', 'language'))
    );
    return Promise.resolve(true);
  }

  stopTracking() {
    // You can do nothing because there is nothing to stop
    // for the null implementation of this
  }
}

module.exports = NullTincanCompletionTracker;
