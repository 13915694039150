const I18n = require('@common/libs/I18n');
const Backbone = require('backbone');
const { LayoutView } = require('Marionette');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const KeyCode = require('@common/data/enums/KeyCode');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/mutationObservable/MutationObservable');

const HEADER_PROFILE_DROPDOWN_REGION_SELECTOR = '.header-profile-dropdown-region';
const LEFT_HEADER_REGION_SELECTOR = '.left-header-region';
const MENU_OVERFLOW_SELECTOR = '.menu-overflow-region';

class HeaderBarView extends LayoutView {
  initialize() {
    this.glChannel = Backbone.Wreqr.radio.channel('global');
  }

  id() {
    return 'header';
  }

  className() {
    return 'js-header-container ax-grid ax-grid--align-items-center ax-grid--no-gutter';
  }

  getTemplate() {
    return require('@common/modules/main/header/HeaderBarTemplate.html');
  }

  regions() {
    return {
      leftHeaderRegion: LEFT_HEADER_REGION_SELECTOR,
      chatHeaderNavButtonRegion: '.js-chat-header-nav-button-region',
      rightHeaderRegion: HEADER_PROFILE_DROPDOWN_REGION_SELECTOR,
      menuRegion: '@ui.menuRegion',
      menuOverflow: '@ui.menuOverflow',
      offscreenMenuMeasurements: '.off-screen-menu-measurements'
    };
  }

  ui() {
    return {
      leftHeaderRegion: LEFT_HEADER_REGION_SELECTOR,
      logo: '.logo',
      menuRegion: '.menu-region',
      menuOverflow: MENU_OVERFLOW_SELECTOR
    };
  }

  events() {
    return {
      'click @ui.logo': this.onClickLogo,
      'keydown @ui.logo': this.onKeydownLogo
    };
  }

  behaviors() {
    return {
      Resizable: {},
      MutationObservable: {
        target: `${ LEFT_HEADER_REGION_SELECTOR } ${ HEADER_PROFILE_DROPDOWN_REGION_SELECTOR } ${ MENU_OVERFLOW_SELECTOR }`,
        observeOptions: {
          childList: true,
          subtree: true
        }
      }
    };
  }

  onAttach() {
    const isUserLoggedIn = apps.auth.session.user.isLoggedIn();

    this.ui.logo.toggleClass('clickable', isUserLoggedIn);
    this.ui.logo.attr({
      role: isUserLoggedIn ? 'link' : 'presentation',
      tabindex: isUserLoggedIn ? 0 : -1,
      'aria-hidden': !isUserLoggedIn,
      'aria-label': isUserLoggedIn ? I18n.t('header.logo.aria') : ''
    })
  }

  _triggerLogoClick() {
    this.glChannel.commands.execute('app:logoClicked');
  }

  onClickLogo() {
    this._triggerLogoClick();
  }

  onKeydownLogo(e = {}) {
    if (e.which === KeyCode.SPACE || e.which === KeyCode.ENTER) {
      this._triggerLogoClick();
    }
  }

  toggleLogo(toggle = true) {
    this.ui.logo.toggleClass('hidden', !toggle);
  }

  toggleDesktopHideable(toggle = false) {
    this.$el.toggleClass('desktop-hideable', toggle);
  }

  toggleTopPosition(toggle = false) {
    this.$el.toggleClass('header-top', toggle);
  }

  toggleLockedToTop(toggle = false) {
    this.$el.toggleClass('header-locked-to-top', toggle);
    BrowserHelpers.triggerResize(true);
  }

  toggleLeftRegionAlwaysShow(toggle = true) {
    this.ui.leftHeaderRegion.toggleClass('show-back-button', toggle);
  }

  toggleMenuOverflow(toggle = false) {
    this.ui.menuOverflow.toggleClass('hidden', !toggle);
  }

  toggleNavigationRole(toggle = false) {
    this.$el.attr('role', toggle ? 'navigation' : null);
  }

  getMenuWidthAvailable() {
    return this.ui.menuRegion.width();
  }
}

module.exports = HeaderBarView;
