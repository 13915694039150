const Backbone = require('Backbone');
const SelfDirectedPathCategory = require('@common/components/guidedLearning/selfDirectedPaths/models/SelfDirectedPathCategory');

class SelfDirectedPathCategoriesList extends Backbone.Collection {

  preinitialize(models, options = {}) {
    ({
      sessionModel: this.sessionModel
    } = options);
  }

  apiEndpoint() {
    return '/selfDirected/available/program';
  }

  get model() {
    return (attrs, options) => {
      return new SelfDirectedPathCategory(attrs, Object.assign({}, options, { sessionModel: this.sessionModel }));
    };
  }

  hasStartableItems() {
    return this.any((category) => {
      return category.hasStartableItems();
    });
  }
}

module.exports = SelfDirectedPathCategoriesList;
