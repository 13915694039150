const I18n = require('@common/libs/I18n');
const Marionette = require('Marionette');

const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class SecurityQuestionErrorPage extends Marionette.LayoutView {
  getTemplate() {
    return require('./SecurityQuestionErrorPage.html');
  }

  templateHelpers() {
    const username = this._flowStateModel.get('username');
    return {
      failureMessage: I18n.t('login.forgotPassword.incorrectAnswers.message', {username: `<strong>${ HTMLHelpers.stripHtmlForDisplay(username) }</strong>`})
    };
  }

  triggers() {
    return {
      'click .submit': 'submit'
    };
  }

  initialize(options = {}) {
    ({
      flowStateModel: this._flowStateModel
    } = options);
  }
}

module.exports = SecurityQuestionErrorPage;
