const ArticleDetailsCommentCollectionView = require('@training/apps/articles/ArticleDetailsCommentCollectionView');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');

class ArticleDetailsCommentCollectionController extends CollectionLayoutViewController {

  initialize(options) {

    ({
      collection: this.collection
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: ArticleDetailsCommentCollectionView,
      collection: this.collection,
      showComments: this.showComments,
      commentsEnabled: this.commentsEnabled,
      className: 'comment-view-container'
    };
  }

}

module.exports = ArticleDetailsCommentCollectionController;
