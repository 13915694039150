const Promise = require('bluebird');

const {FormalExamTraining} = require('@common/data/enums/SessionTrainingType');

const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');

const QuizboardSelectableAssessmentController = require('@training/apps/training/controllers/assessments/QuizboardSelectableAssessmentController');

class FormalExamAssessmentController extends QuizboardSelectableAssessmentController {
  processAssessment(assessment, context) {
    this.listenToOnce(assessment, 'destroy', () => {
      this.assessmentController.getAssessmentResult();
    });

    return super.processAssessment(assessment, context);
  }

  getTimeLogOptions(options = {}) {
    const isFromHub = options.isFromHub != null ? options.isFromHub : false;

    if (isFromHub) {
      return TimeLogConfig.PageView.HubFormalExamTraining;
    }

    return TimeLogConfig.PageView.AutomaticFormalExamTraining;
  }

  isAssessmentRetakeAsync(assessmentOption) {
    return new Promise((resolve) => {
      resolve(assessmentOption.isRetake());
    });
  }

  getSessionTrainingType() {
    return FormalExamTraining;
  }
}

module.exports = FormalExamAssessmentController;
