const { Model } = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');
require('@common/libs/behaviors/scrollable/ScrollEvents');

const CONTENT_SELECTOR = '.adjustable-content';

class AdjustableLayout extends UIKit.View {

  static CONTENT_SELECTOR = CONTENT_SELECTOR;

  behaviors() {
    return Object.assign({}, super.behaviors(), {
      Scrollable: {
        [Scrollable.Options.SCROLLABLE_CONTAINER]: '.adjustable-container',
        [Scrollable.Options.CONTENT_DIMENSIONS_CALCULATOR]: this.getScrollContentDimensions.bind(this)
      },
      ScrollEvents: {}
    });
  }

  getTemplate() {
    return `
    <div class="adjustable-container">
      <div class="adjustable-content-wrapper">
        <div class="adjustable-content"></div>
      </div>
    </div>`;
  }

  useMarionetteRender() {
    return true;
  }

  regions() {
    return {
      [CONTENT_SELECTOR]: CONTENT_SELECTOR
    };
  }

  ui() {
    return {
      container: '.adjustable-container',
      contentWrapper: '.adjustable-content-wrapper',
      content: CONTENT_SELECTOR
    };
  }

  viewStateEvents() {
    return {
      'change:availableHeight change:contentHeight': 'onAdjustableViewStateChange',
      'change:containerHeight': 'onContainerHeightChange'
    };
  }

  preinitialize(options) {
    super.preinitialize(options);

    this._viewState = new Model(this.getDefaultViewState());
  }

  constructor(options = {}) {
    super(options);

    this.baseClassName = 'adjustable-layout';
  }

  getDefaultViewState() {
    return {
      isContentOverflow: false
    };
  }

  delegateEvents() {
    super.delegateEvents();

    this.bindEntityEvents(this._viewState, this.getOption('viewStateEvents'));
  }

  undelegateEvents() {
    super.undelegateEvents();

    this.unbindEntityEvents(this._viewState, this.getOption('viewStateEvents'));
  }

  onRender() {
    this.$el.addClass(this.baseClassName);
  }

  onScroll(scrollData) {
    this._setScrollData(scrollData);
    this.updateStructuralViewState();
  }

  onAdjustableViewStateChange() {
    this.updateIsContentOverflow();
    this.updateContainerHeight();
  }

  onContainerHeightChange(model, containerHeight) {
    this.getContainer().css('height', containerHeight);
  }

  updateIsContentOverflow() {
    const contentHeight = this.getContentHeight();
    const availableHeight = this.getAvailableHeight();

    if (contentHeight != null && availableHeight != null) {
      const isContentOverflow = contentHeight > availableHeight;
      this._viewState.set({ isContentOverflow });
    }
  }

  isContentOverflow() {
    return this._viewState.get('isContentOverflow');
  }

  updateContainerHeight() {
    const containerHeight = this.calculateContainerHeight();
    this._viewState.set({ containerHeight });
  }

  // Calculate the desired height of the container
  calculateContainerHeight() {
    return this.isContentOverflow() ? this.getAvailableHeight() : '';
  }

  // Content height here refers to the height that the contents of the View wants to be if it wasn't in a scroll container
  calculateContentHeight() {
    if (this._viewState.has('scrollData')) {
      return this._viewState.get('scrollData').contentHeight;
    }

    return 0;
  }

  getScrollContentDimensions() {
    const contentHeight = this.getOuterHeight(this.getContentWrapper(), true);
    const contentWidth = this.getOuterWidth(this.getContentWrapper(), true);

    return {
      contentHeight,
      contentWidth
    };
  }

  getContentHeight() {
    return this._viewState.get('contentHeight');
  }

  updateStructuralViewState() {
    const availableHeight = this.calculateAvailableHeight();
    const contentHeight = this.calculateContentHeight();

    this._viewState.set({ availableHeight, contentHeight });
  }

  calculateAvailableHeight() {
    return this.getInnerHeight();
  }

  getAvailableHeight() {
    return this._viewState.get('availableHeight');
  }

  setSubviewIn(v, options = {}) {
    super.setSubviewIn(v, Object.assign({ regionSelector: CONTENT_SELECTOR }, options));
  }

  removeSubview(regionSelector = CONTENT_SELECTOR) {
    super.removeSubview(regionSelector);
  }

  /* Getters */

  getContainer() {
    return this.ui.container;
  }

  getContentWrapper() {
    return this.ui.contentWrapper;
  }

  getContent() {
    return this.ui.content;
  }

  _setScrollData(scrollData) {
    this._viewState.set({ scrollData }, { silent: true });
  }
}

module.exports = AdjustableLayout;
