const { result, extend } = require('underscore');
const { View, CollectionView, LayoutView } = require('Marionette');

/*
 * XXX - Make it possibe for all Marionette.View's to accept `behaviors` as part of their
 * initialization options instead of having to be defined at class definition time.
 */
extend(View.prototype, {
  behaviors() {
    return result(this.options, 'behaviors', {});
  },
  triggers() {
    return result(this.options, 'triggers', {});
  }
});

extend(CollectionView.prototype, {
  childEvents() {
    return result(this.options, 'childEvents', {});
  }
});

extend(LayoutView.prototype, {
  childEvents() {
    return result(this.options, 'childEvents', {});
  }
});
