const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

class TagGropuFiltersCountView extends ItemView {
  tagName() {
    return 'p';
  }

  className() {
    return 'tag-group-filters-index__count';
  }

  getTemplate() {
    return '<%- countString %>';
  }

  templateHelpers() {
    const count = this.collection.length;
    const countString = count === 1 ? I18n.t('communitiesManagement.filters.countSingle')
      : I18n.t('communitiesManagement.filters.count', { count });

    return {
      countString
    };
  }
}

module.exports = TagGropuFiltersCountView;
