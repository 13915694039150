const { intersection } = require('underscore');
const ErrorHandler = require('ErrorHandler');

const I18n = require('@common/libs/I18n');

const AjaxHelpers = require('@common/libs/ajax/AjaxHelpers');

const invalidPathFragments = ['null', 'undefined', 'NaN'];

const containsInvalidPathFragments = (url = '') => {
  const pathFragments = url.split('/');

  return intersection(pathFragments, invalidPathFragments).length > 0;
};

module.exports = (settings, originalSettings, jqXHR) => {
  if (containsInvalidPathFragments(settings.url)) {
    jqXHR.abort();

    // This has to be done here explicitly since the prefilters run before jquery.ajax() figures out it needs to
    // trigger global events which mean we can't rely on the globalErrorHandler
    ErrorHandler.handleError({
      errorDetailsMessage: I18n.t('errors.genericError'),
      api: AjaxHelpers.getApiErrorData({
        settings,
        thrownError: 'invalidUrl'
      })
    });
  }
};
