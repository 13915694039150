// Module
var code = `<div class="page-content-notifications">
  <div class="scheduled-banner-container"></div>
  <div class="expired-banner-container"></div>
  <div class="reported-banner-container"></div>
  <div class="inReview-banner-container"></div>
  <% if (isLockedForEditing()) { %>
    <div class="warning-container notify-container clearfix">
      <div class="icon icon-exclamation_sign-outline"></div>
      <div class="info"><%- getLockedMessage() %></div>
    </div>
  <% } %>
</div>
<div id="page-content-view">
  <div id="page-container" class="squire-editor fr-view full-width"></div>
  <div class="article-footer">
    <div class="reactions-region"></div>
  </div>
  <div class="comments-region"></div>
</div>
`;
// Exports
module.exports = code;