const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

class UnenrollModalView extends Marionette.ItemView {
  className() {
    return 'unenroll-modal';
  }

  getTemplate() {
    return require('@common/components/guidedLearning/selfDirectedPaths/programs/UnenrollModalView.html');
  }

  templateHelpers() {
    return {
      header: I18n.t('SelfDirectedPaths.unenrollModal.header', {pathName: this.model.getName()})
    };
  }

}

module.exports = UnenrollModalView;
