const EventHelpers = require('@common/libs/helpers/features/EventHelpers');
const SelectedState = require('@common/components/selectable_list/SelectedState');
const _ = require('underscore');

const SelectableListMixin = {
  // selectedModel is null to begin since we don't have a selected filter
  _selectedState: null,

  initialize(models, options = {}) {
    const stateOptions = _.extend({ model: this.model }, options);
    this._selectedState = options.selectedState || new SelectedState(stateOptions);

    _.each(SelectedState.TriggeredEventList, (event) => {
      EventHelpers.proxyEvent(this, this._selectedState, event, null, false);
    });
  },

  // set the selected item
  setSelected(model, force, options) {
    let selectedModel = model;
    if (!force && this.get(model) != null) {
      selectedModel = this.get(model);
    }

    this._selectedState.setSelected(selectedModel, options);
  },

  unsetSelected(model, options) {
    this._selectedState.unsetSelected(model, options);
  },

  updateSelected(models, options) {
    this._selectedState.updateSelected(models, options);
  },

  getSelected() {
    return this._selectedState.getSelected();
  },

  hasSelection() {
    return this._selectedState.hasSelection();
  }
};

module.exports = SelectableListMixin;
