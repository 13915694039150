// Module
var code = `<span class="blankanswer">
  <select name="blank">
    <option value="-1"></option>
      <% _.each(options, function(option) { %>
       <option value="<%- option.previewId %>" class="<% if(option.correct) {%> correct-answer <%}%>">
        <%= option.optionText %>
      </option>
    <% }); %>
  </select>
</span>
`;
// Exports
module.exports = code;