const Backbone = require('Backbone');

class SelfServeAreasOfInterest extends Backbone.Collection {
  url() {
    return '/axonify/selfserve?type=aoi';
  }

  comparator(model) {
    return model.get('name').toLowerCase();
  }

  parse(res = {}) {
    if (!res.entities) {
      return res;
    }

    if (res.currentAois) {
      res.entities.forEach((item) => {
        item.selected = res.currentAois.includes(item.id);
      });
    }
    
    return res.entities;
  }
}

module.exports = SelfServeAreasOfInterest;
