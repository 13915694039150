const {ItemView} = require('Marionette');
const CustomerApiKey = require('@common/data/models/CustomerApiKey');

class ApiKeyView extends ItemView {
  initialize(options = {}) {
    ({
      context: this.context,
      collection: this.customerApiKeyList,
      userList: this.userList
    } = options);

    this.setCommunityId();

    this.listenTo(this.context, 'change:community update:community sync:community', this.setCommunityId);
  }


  getTemplate() {
    return `
      <h2><%- t('communities.apiKeys.dzApiKeys')%></h2>
      <button class="add-btn ax-button ax-button--m ax-button--branded"><%- t('general.addNew')%></button>
      `;
  }

  events() {
    return {
      'click .add-btn': 'showNewModal'
    };
  }

  setCommunityId() {
    this.community = this.context.get('community');
    if (this.community) {
      this.communityId = this.context.get('community').get('id');
    }
  }

  showNewModal() {
    return this.trigger('show:modal', new CustomerApiKey({communityId: this.communityId}));
  }
}

module.exports = ApiKeyView;
