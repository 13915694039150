const $os = require('detectOS');
const { sendErrorLog } = require('LoggingService');
const Marionette = require('Marionette');

const I18n = require('@common/libs/I18n');
const ImageLoader = require('@common/libs/ImageLoader');

const { extendLightboxOptionsWithAndroidBackDismiss } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const TabTrapper = require('@common/libs/behaviors/tabTrapper/TabTrapper');
const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');
require('@common/libs/behaviors/scrollable/ScrollEvents');

require('@common/components/modal/ModalScrollIndicatorBehavior');

require('backbone.cocktail');
require('jquery.lightbox_me');

require('@common/components/modal/modal.less');
require('@common/components/modal/question.less');

class QuestionLayoutWrapper extends Marionette.LayoutView {
  getTemplate() {
    return `\
    <div class="question-preview-container">
      <div class="question-preview-content">
        <div class="questionpreview modal-header modal-header-details clearfix"></div>
        <div class="modal-header title clearfix" tabindex="-1" id="modal-label" role="heading" aria-level="2"></div>
        <button class="close" aria-label="<%- t('general.close') %>"></button>
        <div class="contentwrapper">
          <div class="body-content"></div>
        </div>
        <div class="questionpreview modal-footer modal-footer-details clearfix"></div>
      </div>
    </div>\
    `;
  }

  id() {
    return 'modalview';
  }

  className() {
    return 'question-preview-modal questionanswermodal initial-min-height';
  }

  regions() {
    return {
      contentRegion: '.body-content',
      headerRegion: '.modal-header-details',
      footerRegion: '.modal-footer-details'
    };
  }

  ui() {
    return {
      modalHeaderTitle: '.modal-header.title',
      modalHeaderDetails: '.modal-header-details',
      modalFooterDetails: '.modal-footer-details',
      bodyContent: '.body-content',
      container: '.question-preview-container',
      content: '.question-preview-content',
      contentScroll: '.content-scroll-down',
      closeButton: '.close',
      contentWrapper: '.contentwrapper'
    };
  }

  attributes() {
    return {
      'aria-labelledby': 'modal-label',
      role: 'dialog'
    };
  }

  behaviors() {
    return {
      TabTrapper: {},
      ModalScrollIndicatorBehavior: {
        downScrollerTarget: '.contentwrapper'
      },
      Scrollable: {
        [Scrollable.Options.SCROLLABLE_CONTAINER]: '.contentwrapper',
        [Scrollable.Options.CONTENT_DIMENSIONS_CALCULATOR]: this.getScrollContentDimensions.bind(this)
      },
      ScrollEvents: {}
    };
  }

  initialize(options = {}) {
    this.modalDefaults = options.extendedModalOptions;
    this.listenTo(this.contentRegion, 'show', this._displayModal);
  }

  onScrollableResize() {
    if (this.isDestroyed) {
      return;
    }
    this.adjustWidth();
    this.adjustHeight();
  }

  adjustHeight() {
    if (this.ui.container.height() === 0) {
      return;
    }

    if (this.isBodyContentOverflow()) {
      const maxHeight = this.getMaxHeight();
      const headerFooterHeight = this._getHeaderFooterHeight();
      const topBottomHeights = this._getTopBottomCombinedHeight();
      const newWrapperHeight = maxHeight - headerFooterHeight - topBottomHeights;

      this.ui.contentWrapper.css('height', newWrapperHeight);
    } else {
      this.ui.contentWrapper.css('height', '');
    }
  }

  isBodyContentOverflow() {
    const contentHeight = Scrollable.getScrollData(this, Scrollable.ScrollDataKeys.CONTENT_HEIGHT);
    const headerFooterHeight = this._getHeaderFooterHeight();
    const topBottomHeights = this._getTopBottomCombinedHeight();
    const footerHeaderMaxHeight = contentHeight + headerFooterHeight + topBottomHeights;
    const maxHeight = this.getMaxHeight();

    return footerHeaderMaxHeight > maxHeight;
  }

  _getMobileDisplayOptions() {
    const options = {
      left: 0,
      marginLeft: 0,
      width: '100%',
      top: 0,
      bottom: 0
    };

    if (I18n.isCurrentLanguageRtl()) {
      Object.assign(options, {
        marginRight: 0,
        right: 0
      });
    }

    return options;
  }

  getContentHeight() {
    return this.ui.content.outerHeight(true);
  }

  getMaxHeight() {
    // Get the max height that the modal's content can actually be
    // Max height is the total height of the modal offsetParent
    // It shouldn't be able to grow larger than the viewed window
    return this.$el.offsetParent().height();
  }

  adjustWidth() {
    this.$el.trigger('reposition');
  }

  getContainer() {
    return this.ui.container;
  }

  getContentWrapper() {
    return this.ui.content;
  }

  getScrollContentDimensions() {
    const contentHeight = this.getOuterHeight(this.ui.bodyContent, true);
    const contentWidth = this.getOuterWidth(this.ui.bodyContent, true);

    return {
      contentHeight,
      contentWidth
    };
  }

  showQuestionPreviewView(isUpdatedView) {
    if (isUpdatedView) {
      return this._showUpdatedQuestionView(isUpdatedView);
    }
    return this.contentRegion.show(this.view);

  }

  _getTopBottomHeight() {
    return parseInt(this.$el.css('top'), 10);
  }

  _getTopBottomCombinedHeight() {
    return this._getTopBottomHeight() * 2;
  }

  _getHeaderFooterHeight() {
    return this.ui.modalHeaderDetails.height() + this.ui.modalFooterDetails.height() + this.ui.modalHeaderTitle.outerHeight();
  }

  _showUpdatedQuestionView(isUpdatedView) {
    this.isUpdatedView = isUpdatedView;
    this.contentRegion.show(this.view);
    return this._setupModalListeners();
  }

  showQuestionTitle() {
    return this.ui.modalHeaderTitle.text(this.title);
  }

  showQuestionHeader(headerView) {
    this.headerRegion.show(headerView);
    return this.ui.modalHeaderDetails.show();
  }

  showQuestionFooter(footerView) {
    this.footerRegion.show(footerView);
    return this.ui.modalFooterDetails.show();
  }

  hideModalDefaultCloseButton() {
    return this.ui.closeButton.hide();
  }

  _displayModal() {
    if (this.isUpdatedView) {
      return;
    }

    const previousActiveElement = this.modalDefaults.previousActiveElement || document.activeElement;
    let $mainContent, $actionBar;

    let options = {
      onClose: () => {
        TabTrapper.toggleTrapper(this, false);

        if ($mainContent != null) {
          $mainContent.attr('aria-hidden', false);
        }

        if ($os.mobile) {
          $actionBar.attr('aria-hidden', false);
        }

        $(previousActiveElement).trigger('focus');

        this.destroy();
      },
      onLoad: () => {
        if (this.isDestroyed) {
          return;
        }

        TabTrapper.toggleTrapper(this, true);

        this.triggerMethod('attach', this);

        this.$el.attr('aria-hidden', false);
        $mainContent = this.$el.siblings('.adjustable-container');
        $mainContent.attr('aria-hidden', true);

        if ($os.mobile) {
          $actionBar = this.$el.siblings('.page-action-bar');
          $actionBar.attr('aria-hidden', true);
        }

        this._setupModalListeners();
        if (!$os.mobile) {
          this.onScrollableResize();
        }
      }
    };

    this._resizeOnImageLoad();

    options = extendLightboxOptionsWithAndroidBackDismiss(this, $.extend(true, { sendErrorLog }, this.modalDefaults, options));

    this.$el.lightbox_me(options);
  }

  setQuestionTypeView(view) {
    this.view = view;
  }

  setQuestionTypeTitle(title) {
    this.title = title;
  }

  _setupModalListeners() {
    Scrollable.scrollToTop(this);

    if (!$os.mobile) {
      this.view.trigger('modal:open');
    }

    this.ui.modalHeaderTitle.trigger('focus');

    this.listenTo(this.view, 'image:loaded', this.onScrollableResize);

    this.listenTo(this.view, 'show', this.onScrollableResize);

    this.listenTo(this.view, 'childview:show', () => {
      this._resizeOnImageLoad();
    });

    // Mobile version has 0 delays for lightbox speed and display speed
    // So we want to listen to just the onShow event (above)
    this._resizeOnImageLoad();
  }

  _resizeOnImageLoad() {
    this.$('img').each((index, img) => {
      ImageLoader
        .load(img.src)
        .done(this.onResize);
    });
  }

  closeModal() {

    this.$el.trigger('close');
    if (this.contentRegion != null ) {
      this.contentRegion.empty();
    }
  }

  _getHasHeaderOrFooter() {
    const modalFooterOptions = this.getOption('modalFooterOptions');
    const modalHeaderOptions = this.getOption('modalHeaderOptions');

    return (modalHeaderOptions != null) || (modalFooterOptions != null);
  }
}

module.exports = QuestionLayoutWrapper;
