const PageRequestData = require('@common/data/PageRequestData');

const PageableCollection = require('@common/components/pageableList/PageableCollection');

class InfiniteCollection extends PageableCollection {

  initialize(models, options) {
    super.initialize(models, options);

    this._pageRequestData = new PageRequestData();
  }

  defaultFetchOptions() {
    return Object.assign({}, super.defaultFetchOptions(), {
      remove: false
    });
  }

  search(query, pageNum, options = {}) {
    this.searchQuery = query;
    return this.getFirstPage(options);
  }

  getPage(index, options = {}) {
    let pageIndex = index;
    let fetchOptions = options;

    if (this._pageRequestData.isEmpty()) {
      pageIndex = 'first';
    }

    const {
      firstPage,
      currentPage,
      lastPage
    } = this.state;

    switch (pageIndex) {
      case 'first': pageIndex = firstPage; break;
      case 'prev': pageIndex = currentPage - 1; break;
      case 'next': pageIndex = currentPage + 1; break;
      case 'last': pageIndex = lastPage; break;
      default: pageIndex = parseInt(pageIndex, 10);
    }

    if (pageIndex === firstPage) {
      this.resetPageState();

      fetchOptions = Object.assign({
        reset: true,
        silent: false
      }, fetchOptions);
    } else if (this._pageRequestData.hasData(pageIndex)) {
      return this._pageRequestData.getRequest(pageIndex);
    }

    const xhr = super.getPage(pageIndex, fetchOptions);
    this._pageRequestData.setRequest(pageIndex, xhr);
    return xhr;
  }

  onReset(collectionModels, options = {}) {
    // Make sure this only gets reset if it's an explicit call to reset() and not due to a network request
    if (options.xhr == null) {
      this.resetPageState();
    }
  }

  resetPageState() {
    this.setHasNext(true);
    this._pageRequestData.clearData();
  }

  restoreToPage(page = 0) {
    const currentPageSize = this.state.pageSize;
    const expandedPageSize = (page + 1) * currentPageSize;

    // Set the expanded page size which will automatically fetch the first page with that size.
    this.setPageSize(expandedPageSize, { remove: true });

    // Set the current page to the page we want since setPageSize resets it to 0
    this.state.currentPage = page;

    // Reset the pageSize to the default defined above without triggering another fetch
    this.state.pageSize = currentPageSize;
  }
}

module.exports = InfiniteCollection;
