const Backbone = require('Backbone');
const logging = require('logging');
const _ = require('underscore');
const FlowController = require('./FlowController');
const BroadcastMessageList = require('../collections/BroadcastMessageList');
const BroadcastMessagePage = require('../views/BroadcastMessagePage');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const UIKit = require('@training/widgets/UIKit');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');
const I18n = require('@common/libs/I18n')

class BroadcastMessageController extends FlowController {
  constructor(...args) {
    super(...args);

    this.broadcastMessageList = new BroadcastMessageList();

    this._getBroadcastMessages = _.memoize(() => {
      return Promise.resolve(this.broadcastMessageList.fetch({showSpinner: false}));
    }, (messageId) => {
      return messageId
    });
  }

  getBroadcastMessages(messageId) {
    return this._getBroadcastMessages(messageId);
  }

  hasBroadcastMessages() {
    return this.broadcastMessageList.getUnreadBroadcasts().length > 0;
  }

  removeBroadcastFromQueue(id) {
    this.broadcastMessageList.remove(id)
  }

  findBroadcastMessage(unreadBroadcasts, notificationId) {
    if (notificationId) {
      return unreadBroadcasts.find((message) => {
        return message?.get('id') === notificationId
      });
    }
    return _.first(unreadBroadcasts);
  }

  fetchTimelineNotifier() {
    if (this.parentProcessor.getHubController().isTimelineEnabled() && !(this.hasBroadcastMessages())) {
      this.parentProcessor.timelineNotifier.fetch();
    }
  }

  showBroadcastMessage(broadcastMessage) {
    let timeLogId = null;
    this.show(broadcastMessage, {
      onShow: () => {
        timeLogId = window.apps.base.timeLogController.startPageViewBroadcastMessage(broadcastMessage.get('id'));
        this.parentProcessor.currentNotificationData = {};
      },
      complete: (shouldMarkAsRead = true) => {
        try {
          window.apps.base.timeLogController.stop(timeLogId);
        } catch (e) {
          logging.error(e);
        }

        if (shouldMarkAsRead) {
          broadcastMessage.markAsRead()
            .done(() => {
              const points = broadcastMessage.get('points');
              if (points) {
                const pointsEarned = points === 1
                  ? I18n.t('communications.pointsEarned.singular')
                  : I18n.t('communications.pointsEarned.plural', { points: points });

                window.app.layout.flash.success(pointsEarned);
              }
            })
            .then(this.fetchTimelineNotifier.bind(this))
            .fail((e) => {
              logging.error(e);
              window.app.layout.flash.error(I18n.t('news.messageNotFound'));
            });
        } else {
          this.fetchTimelineNotifier();
        }

        this.finishedProcessing();
      }
    });
  }

  showAnnouncement(notificationId) {
    const timelineReferenceItemIds = {
      [window.apps.auth.session.user.id]: {
        tab: 'all',
        item: `${ notificationId }-COMMUNICATION`
      }
    };
    LocalStorageHelpers.setItem('timelineReferenceItemIds', timelineReferenceItemIds);

    // need both navigate and loadUrl if user already is on timeline
    // could possibly rely on query params at a future time
    Backbone.history.navigate('#hub/timeline');
    Backbone.history.loadUrl('#hub/timeline');

    this.parentProcessor.currentNotificationData = {};
  }

  // notificationMessageId is for handling notifications for older mobile versions
  // otherwise currentNotificationData is used
  processSequenceFlow(notificationMessageId) {
    logging.debug('Processing Broadcast Messages');
    const notificationId = notificationMessageId || this.parentProcessor.currentNotificationData.id;

    return this.getBroadcastMessages(notificationId).then(() => {
      let broadcastMessage;
      if (this.hasBroadcastMessages()) {
        const unreadBroadcasts = this.broadcastMessageList.getUnreadBroadcasts();
        const broadcastMessage = this.findBroadcastMessage(unreadBroadcasts, notificationId);

        if (broadcastMessage) {
          this.showBroadcastMessage(broadcastMessage);

          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        }
      }

      // display an acknowledged broadcast message or announcement
      if (!broadcastMessage && notificationId) {
        this.showAnnouncement(notificationId);

        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }

      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  show(model, options = {}) {
    const transition = options.transition != null ? options.transition : UIKit.View.Transitions.FADE;
    const modal = options.modal != null ? options.modal : false;
    const complete = options.complete != null ? options.complete : null;
    const onShow = options.onShow != null ? options.onShow : null;

    logging.info(`Showing BroadcastMessagePage - id: ${ model.get('id') } - type: ${ model.get('type') } - transition: ${ transition } - modal: ${ modal }`);

    const broadcastMessagePage = new BroadcastMessagePage({
      model,
      complete(shouldMarkAsRead) {
        logging.info(`Completing Activity - id: ${ model.get('id') }`);

        return _.isFunction(complete) ? complete(shouldMarkAsRead) : undefined;
      },
      removeBroadcastFromQueue: this.removeBroadcastFromQueue.bind(this)
    });

    if (modal) {
      window.app.layout.setViewInModal(broadcastMessagePage, transition);
    } else {
      window.app.layout.setView(broadcastMessagePage, transition);
    }

    return _.isFunction(onShow) ? onShow() : undefined;
  }

  finishedProcessing() {
    logging.debug('Finished processing broadcast messages');
    window.location.hash = '';
    this.parentProcessor.processSequenceFlow();
  }
}
module.exports = BroadcastMessageController;
