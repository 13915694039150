// Module
var code = `<div class="answerspacer <%- getAnswerResult() %> <%- selectedStatus %> <%- getTranslationStatusCSS() %> answer-text-limit-max-width">
  <div class="answer-content table-helper">
    <div class="answer-row table-row-helper">
      <div class="answerradio table-cell-helper" tabindex="0">
        <div class="radiobutton"></div>
        <% if(getAnswerResult() === 'correct') {%>
          <span class="icon-ok_sign"></span>
        <%} else {%>
          <span class="icon-ban_circle"></span>
        <% } %>
      </div>
      <div class="answerimage touchable table-cell-helper">
        <% if(questionImage) {%>
          <div class="image-wrapper imgwrap" data-media-id="<%- questionImage.id %>"></div>
        <%}%>
      </div>
      <div class="answertext table-cell-helper">
        <% if(selectedStatus === 'selected') { %>
          <p class="off-screen"><%- t('question.accessibility.selected') %></p>
        <% } else { %>
          <p class="off-screen"><%- t('question.accessibility.notSelected') %></p>
        <% } %>

        <% if(getAnswerResult() === 'correct') {%>
          <p class="off-screen"><%- t('question.accessibility.correct') %></p>
        <% } else if(getAnswerResult() === 'incorrect') { %>
          <p class="off-screen"><%- t('question.accessibility.incorrect') %></p>
        <% } %>
        <span>
          <%= HTMLHelpers.lineBreakToBr(optionText) %>
          <%- getTranslationStringIfNoText() %>
        </span>
      </div>
    </div>

    <% if(hasExplanation()) {%>
    <div class="explanation-row table-row-helper">
      <div class="empty-left-cell table-cell-helper"></div>
      <div class="reason-image touchable table-cell-helper">
        <% if(getReasonImage()) {%>
          <div class="image-wrapper imgwrap" data-media-id="<%- getReasonImage().id %>"></div>
        <%}%>
      </div>
      <div class="explanationtext table-cell-helper" <% if(!getReasonImage()) {%> colspan="2" <% } %>>
        <div class="optionReasonText"><%= HTMLHelpers.lineBreakToBr(getReasonText()) %></div>
      </div>
    </div>
    <% }; %>
  </div>
</div>
<div class="zoom-placeholder hidden"></div>
<div class="reason-zoom-placeholder hidden"></div>
`;
// Exports
module.exports = code;