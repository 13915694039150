import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitPagination(cssVars: CSSStyleDeclaration) {
  return {
    pagination: {
      pageItem: {
        color: getCssValue('--ax-pagination-pageItem-color', cssVars),
        fontWeight: getCssValue('--ax-pagination-pageItem-fontWeight', cssVars),
        padding: getCssValue('--ax-pagination-pageItem-padding', cssVars),
        selected: {
          background: getCssValue('--ax-pagination-pageItem-selected-background', cssVars),
          border: getCssValue('--ax-pagination-pageItem-selected-border', cssVars)
        },
        icon: {
          fontSize: getCssValue('--ax-pagination-pageItem-icon-fontSize', cssVars),
          padding: getCssValue('--ax-pagination-pageItem-icon-padding', cssVars)
        },
        height: {
          md: getCssValue('--ax-pagination-pageItem-height-md', cssVars),
          sm: getCssValue('--ax-pagination-pageItem-height-sm', cssVars)
        }
      }
    }
  };
}
