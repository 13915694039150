import {
  InputAdornment as MuiInputAdornment,
  styled,
  type InputAdornmentTypeMap as MuiInputAdornmentTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

export type AxInputAdornmentTypeMap<P = object, D extends React.ElementType = 'div'> = MuiInputAdornmentTypeMap<P, D>;

export type AxInputAdornmentProps<
  D extends ElementType = AxInputAdornmentTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxInputAdornmentTypeMap<P, D>, D>;

const styledOptions = {
  name: 'AxInputAdornment'
};

const StyledAxInputAdornment = styled(MuiInputAdornment, styledOptions)<AxInputAdornmentProps>(() => {
  return {
    marginLeft: 0,
    marginRight: 0
  };
});

export const AxInputAdornment: MuiOverridableComponent<AxInputAdornmentTypeMap<AxInputAdornmentProps, React.ElementType>> = forwardRef(({
  children,
  ...otherProps
}: AxInputAdornmentProps, ref: Ref<HTMLDivElement>) => {
  const buttonProps: AxInputAdornmentProps = {
    ...otherProps
  };

  return (
    <StyledAxInputAdornment { ...buttonProps } ref={ ref }>
      { children }
    </StyledAxInputAdornment>
  );
});

export default AxInputAdornment;
