const Marionette = require('Marionette');

const ProfileIconHandler = require('@common/components/ProfileIconHandler');

class UserView extends Marionette.ItemView {
  getTemplate() {
    return require('@training/apps/training/templates/_user_view.html');
  }

  className() {
    const extraClass = this.getOption('extraClass');
    return `userview-user ${ extraClass }`;
  }

  onRender() {
    this.iconHandler = new ProfileIconHandler({
      user: this.model.toJSON(),
      profileElement: this.$('.name-logo')
    });
  }
}
module.exports = UserView;
