const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');

class EvaluationCriteriaCardDetailsView extends Marionette.LayoutView {
  className() {
    return 'evaluation-criteria-card';
  }

  tagName() {
    return 'div';
  }

  getTemplate() {
    return `
    <div class="evaluation-criteria-card__details-wrapper"></div>
    <div class="evaluation-criteria-card__status-wrapper"></div>
    `;
  }

  regions() {
    return {
      detailsRegion: '.evaluation-criteria-card__details-wrapper',
      statusRegion: '.evaluation-criteria-card__status-wrapper'
    };
  }

  onRender() {
    this._renderDetails();
    this._renderStatus();
  }

  _renderDetails() {
    const detailsView = new Marionette.ItemView({
      className: 'behaviour-details',
      template: `\
<div class="behaviour-name ax-font ax-font__m">${ this.model.get('behaviour').name }</div>
<div class="behaviour-description ax-font ax-font__p ax-font--no-margin">${ this.model.get('behaviour').description }</div>`
    });

    this.detailsRegion.show(detailsView);
  }

  _renderStatus() {
    const results = this._getResults();
    const statusView = new Marionette.ItemView({
      className: `criteria-status ${ results.cssClass }`,
      template: `\
  <span class="item-icon icon-${ results.icon }"></span>
  <div>${ results.message }</div>`
    });

    this.statusRegion.show(statusView);
  }

  _getResults() {
    const pos = this.model.get('positiveObservations');
    const neg = this.model.get('negativeObservations');
    const results = {};
    if (pos) {
      results.cssClass = 'passed';
      results.message = I18n.t(`GuidedLearning.evaluation.status.${ EvaluationObjectiveStatus.PASSED }`);
      results.icon = 'ok_circle';
    } else if (neg) {
      results.cssClass = 'not-passed';
      results.message = I18n.t(`GuidedLearning.evaluation.status.${ EvaluationObjectiveStatus.FAILED }`);
      results.icon = 'remove_circle';
    } else {
      results.cssClass = 'pending';
      results.message = I18n.t(`GuidedLearning.evaluation.status.${ EvaluationObjectiveStatus.PENDING }`);
      results.icon = 'pending';
    }

    return results;
  }
}

module.exports = EvaluationCriteriaCardDetailsView;
