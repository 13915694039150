const { ItemView } = require('Marionette');

const CONTENT_HEADER_VISIBLE = 'contentHeaderVisible';
const CONTENT_CONTROLLER_DEFINITION = 'contentControllerDefinition';
const CONTENT_HEADER_DEFINITION = 'contentHeaderDefinition';

module.exports = {
  Keys: {
    CONTENT_HEADER_VISIBLE,
    CONTENT_CONTROLLER_DEFINITION,
    CONTENT_HEADER_DEFINITION
  },

  defaults() {
    return {
      [CONTENT_HEADER_VISIBLE]: true,
      [CONTENT_CONTROLLER_DEFINITION]: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'fit-parent',
          template: false
        }
      },
      [CONTENT_HEADER_DEFINITION]: {
        viewDefinition: {
          ViewClass: ItemView,
          template: false
        }
      }
    };
  },

  toggleHeader(contextModel, toggle = true) {
    contextModel.set(CONTENT_HEADER_VISIBLE, toggle);
  },

  getContentControllerDefinition(contextModel) {
    return contextModel.get(CONTENT_CONTROLLER_DEFINITION);
  },

  setContentControllerDefinition(contextModel, controllerDefinition, options = {}) {
    contextModel.set(CONTENT_CONTROLLER_DEFINITION, controllerDefinition, options);
  },

  getContentHeaderDefinition(contextModel) {
    return contextModel.get(CONTENT_HEADER_DEFINITION);
  },

  setContentHeaderDefinition(contextModel, headerDefinition, options = {}) {
    contextModel.set(CONTENT_HEADER_DEFINITION, headerDefinition, options);
  }
};
