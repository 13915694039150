const {
  extend,
  wrap,
  isEmpty,
  get
} = require('underscore');

const { Model } = require('Backbone');
const Stopwatch = require('@common/libs/Stopwatch');

const { sendLog } = require('LoggingService');
const ActivityStatus = require('@common/data/enums/ActivityStatus');
const ActivityType = require('@common/data/enums/ActivityType');
const TrainingModuleType = require('@common/data/enums/TrainingModuleType');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

class Activity extends Model {
  apiEndpoint = '/activities';

  activityTimer = new Stopwatch();

  parse(response = {}) {
    return response.activity || response.entity || response;
  }

  setAction(actionType, actionBody = {}, options = {}) {
    actionBody.duration = actionBody.duration ?? this.activityTimer.elapsedTime();

    return $.ajax(extend(options, {
      type: 'POST',
      url: `${ this.url() }/actions`,
      data: JSON.stringify({
        actionType,
        actionBody
      }),
      success: wrap(options.success, (onSuccess = () => {}, response) => {
        this.set(response.results.activity);

        if (this.isComplete()) {
          if (this.isQuestionType()) {
            const referenceIdOverride = response.results.actionResponse.answer.id;
            window.apps.base.timeLogController.complete(this.timerId, referenceIdOverride);
          } else {
            window.apps.base.timeLogController.complete(this.timerId);
          }
        }

        onSuccess(response.results.actionResponse);
      }),
      error: (xhr, status, error) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const isActivityNotUnderwayError = exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_ACTIVITY_NOT_UNDERWAY;
        // Not showing oops page or sending error log when user answers same question more than once
        if (isActivityNotUnderwayError) {
          xhr.skipGlobalHandler = true;
          window.location.reload();
        } else {
          this.trigger('error:action', this, xhr, status, error);
        }
      }
    }));
  }

  startTimer() {
    this._setActivityTimerElapsedTime();
    this.activityTimer.start();

    if (this.isSurveyType()) {
      this.timerId = window.apps.base.timeLogController.startSurveyQuestion(this.get('objectId'));
    } else if (this.isQuestionType()) {
      // We need to give `startQuestion` a reference id due to the start API but
      // we will override the reference id on stop
      this.timerId = window.apps.base.timeLogController.startQuestion(this.id);
    } else if (this.isTrainingContentActivity()) {
      this.timerId = window.apps.base.timeLogController.startTrainingModule(this.get('objectId'));
    }
  }

  restartTimer() {
    // Call this method to reset the timer if it's been started before, e.g. if
    // you background the app while answering a question and re-enter/resume
    this.activityTimer.reset();
    this.startTimer();
  }

  stopTimer() {
    this.activityTimer.stop();

    window.apps.base.timeLogController.stop(this.timerId);

    delete this.timerId;
  }

  skipActivity(options = {}) {
    if (this.isBypassable()) {
      sendLog({
        logData: {
          logMessage: 'Skipping Activity!'
        }
      });

      const attrs = { status: ActivityStatus.SKIPPED };
      return this.save(attrs, Object.assign({}, options, { attrs }));
    }
    return $.Deferred().reject()
      .promise();

  }

  getBody() {
    return this.get('body');
  }

  getType() {
    return this.get('type');
  }

  isComplete() {
    return this.get('status') === ActivityStatus.CLOSED;
  }

  isSkipped() {
    return this.get('status') === ActivityStatus.SKIPPED;
  }

  isUnderway() {
    return this.get('status') === ActivityStatus.UNDERWAY;
  }

  isInProgress() {
    return this.timerId != null;
  }

  isTrainingContentActivity() {
    return ActivityType.getTrainingContentTypes().includes(this.getType());
  }

  isQuestionContentActivity() {
    return ActivityType.getQuestionContentTypes().includes(this.getType());
  }

  isQuestionType() {
    return this.getType() === ActivityType.QUESTION;
  }

  isQuestionActivityActive() {
    const data = this.getData();

    if (this.isQuestionType() && !isEmpty(data)) {
      return data.variants[0].isActive;
    }

    return false;
  }

  isSurveyType() {
    return this.getType() === ActivityType.SURVEY_QUESTION;
  }

  isBypassable() {
    return this.get('canBypass');
  }

  isClosed() {
    return this.isComplete() || this.isSkipped();
  }

  isExternalVideo() {
    const data = this.getData();
    return this.getType() === ActivityType.VIDEO_MODULE
      && !isEmpty(data)
      && data.moduleType === TrainingModuleType.EXTERNAL_VIDEO;
  }

  getQuestionId() {
    const data = this.getData();

    if (this.isQuestionType() && !isEmpty(data)) {
      return data.id;
    }

    return null;
  }

  getQuestionVariantType() {
    const data = this.getData();

    if (this.isQuestionType() && !isEmpty(data)) {
      return data.variants[0].variantType;
    }

    return null;
  }

  getTincanPackage() {
    const data = this.getData();

    if (this.getType() === ActivityType.TINCAN_MODULE && !isEmpty(data)) {
      return data.packageLink.tincanPackage;
    }

    return null;
  }

  getScormPackage() {
    const data = this.getData();

    if (this.getType() === ActivityType.SCORM_MODULE && !isEmpty(data) && data.packageLink != null) {
      return data.packageLink.scormPackage;
    }

    return null;
  }

  getDeliveryMethod() {
    const data = this.getData();

    if ((this.getType() === ActivityType.SCORM_MODULE || this.getType() === ActivityType.PDF_MODULE || this.isExternalVideo())
      && !isEmpty(data)) {
      return data.deliveryMethod;
    }

    return null;
  }

  getExternalLinkPackage() {
    const data = this.getData();

    if (this.getType() === ActivityType.EXTERNAL_LINK_MODULE && !isEmpty(data)) {
      return data.packageLink.externalLinkPackage;
    }

    return null;
  }

  getModuleName() {
    const data = this.getData();

    if (this.isTrainingContentActivity()) {
      return data.name;
    }

    return null;
  }

  getModuleDescription() {
    const data = this.getData();

    if (this.isTrainingContentActivity()) {
      return data.desc;
    }

    return null;
  }

  getTopicName() {
    const data = this.getData();

    if (!isEmpty(data)) {
      return data.topic.name;
    }
    return null;
  }

  getTopicLevel() {
    const data = this.getData();

    if (!isEmpty(data)) {
      return data.level;
    }
    return null;
  }

  getData() {
    const body = this.getBody() || {};

    if (this.isQuestionContentActivity() && !isEmpty(body)) {
      return body.question;
    } else if (this.getType() === ActivityType.EXTERNAL_LINK_MODULE) {
      return body.module;
    }
    return body;

  }

  _setActivityTimerElapsedTime() {
    // we save scorm activity duration on the server to properly calculate duration when activity
    // is interupted by a browser refresh.
    const scormPackage = this.getScormPackage();
    if (scormPackage != null) {
      const totalDuration = scormPackage.scoItems.reduce((memo, scoItem) => {
        const duration = get(scoItem, 'scormModuleAnswer.duration', 0);
        return memo + duration;
      }, 0);
      this.activityTimer.setElapsedTime(totalDuration);
    }
  }
}

module.exports = Activity;
