const LoadingController = require('@common/components/loading/LoadingController');

const { getRewardsCardControllerDefinition } = require('@training/apps/home/hubPreviews/rewards/RewardsCardControllerDefinitionFactory');

module.exports = (collection, click) => {
  return () => {
    collection.fetch({
      showSpinner: false,
      data: { maxResults: 3 },
      error: (model, response) => {
        response.skipGlobalHandler = true;
      }
    });

    const contentControllerDefinition = getRewardsCardControllerDefinition({
      rewardsCollection: collection,
      callback: click
    });

    return {
      ViewControllerClass: LoadingController.Wrapper,
      syncTracker: {
        entities: collection,
        loadOnSync: true
      },
      viewDefinition: {
        spinner: false
      },
      contentControllerDefinition
    };
  };
};

