const { Model } = require('Backbone');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');
const {
  ActionBarType,
  createButtonType
} = require('@common/components/actionBarButton/ActionBarButtonFactory');

class PDFBaseViewController extends LayoutController {

  initialize(options = {}) {
    this.checkBoxState = new Model({
      isChecked: false
    });

    this.activity = assertPropertyExists(options, 'activity');
    this.continueButtonFactory = assertPropertyExists(options, 'continueButtonFactory');

    ({
      isExtraTraining: this.isExtraTraining,
      complete: this.complete = () => {}
    } = options);

    this.canSkip = this.activity.isBypassable() || this.isExtraTraining;
  }

  _setSkipButton() {
    const skipButton = createButtonType(ActionBarType.SKIP, this.onNext.bind(this));
    window.app.layout.actionBar.setActionBar({ buttons: skipButton });
  }

  _setContinueButton() {
    const button = this.getContinueButtonConfig();
    button.disabled = !this.checkBoxState.get('isChecked');

    window.app.layout.actionBar.setActionBar({ buttons: button });
  }

  getContinueButtonConfig() {
    return this.continueButtonFactory(this.onNext.bind(this));
  }

  onNext() {
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    this.activity.setAction('PDFSAVE', {}, {
      success: () => {
        this.complete();
      }
    });

    return undefined;
  }

  onDestroy() {
    window.app.layout.actionBar.setActionBar();
  }
}

module.exports = PDFBaseViewController;
