import type { SxProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

interface PdfIconProps {
  sxProps: SxProps
}

const PdfIcon: FC<PdfIconProps> = ({ sxProps }) => {
  return (
    <SvgIcon
      sx={ sxProps }
      viewBox='0 0 30 30'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 3a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V6.652a1 1 0 0 0-.338-.75L20.656 3.25a1 1 0 0 0-.661-.25H8ZM5 4a3 3 0 0 1 3-3h11.995a3 3 0 0 1 1.985.75l3.005 2.653A3 3 0 0 1 26 6.653V26a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V4Zm6 5a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Zm-1 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm1 2a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z'
      />
      <mask
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='5'
        y='1'
        width='21'
        height='28'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 3a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V6.652a1 1 0 0 0-.338-.75L20.656 3.25a1 1 0 0 0-.661-.25H8ZM5 4a3 3 0 0 1 3-3h11.995a3 3 0 0 1 1.985.75l3.005 2.653A3 3 0 0 1 26 6.653V26a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V4Zm6 5a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Zm-1 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm1 2a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8Z'
        />
      </mask>
    </SvgIcon>
  );
};

export default PdfIcon;
