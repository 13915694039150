const logging = require('logging');

/*
  Silently notifies and updates the application. It does this using window reloading logic, by default. It is possible
  that other silent reloading logic could be injected but often you want the defaults.
*/
class FlashAxonifyUpdateNotificationStrategy {
  isStillNagging = false;

  constructor(updater, flashService, message) {
    if (!updater) {
      throw new Error('You have to specify some kind of update strategy');
    }

    if (!flashService) {
      throw new Error('You need to specify a flash service in order to use this notifier');
    }

    if (!message) {
      throw new Error('You need to specify a message for the application to show');
    }

    this.message = message;
    this.applicationUpdater = updater;
    this.flashService = flashService;
  }

  notify() {
    if (this.isStillNagging) {
      return;
    }

    logging.info('Alerting the user of an application update; letting them know.');

    this.isStillNagging = true;
    this.flashService.info({
      message: this.message,
      onHidden: this.onMessageHidden.bind(this),
      onclick: this.onMessageClick.bind(this)
    });
  }

  onMessageHidden() {
    this.isStillNagging = false;
  }

  onMessageClick() {
    // Perform the update when the user has clicked
    this.applicationUpdater();
  }
}

module.exports = FlashAxonifyUpdateNotificationStrategy;
