const logging = require('logging');

const FlowController = require('@training/apps/training/controllers/FlowController');

const AssessmentProcessingController = require('@training/apps/training/controllers/assessments/AssessmentProcessingController');

class AbstractAssessmentInitiatorController extends FlowController {

  constructor(parentProcessor, options = {}) {
    super(parentProcessor);

    ({
      sessionModel: this.sessionModel
    } = options);
  }

  getDiscriminator() {
    throw new Error('You have to implement this getDiscriminator method in any class that overrides this');
  }

  createInitiatorContext() {
    throw new Error('You have to implement this createInitiatorContext method in any class that overrides this');
  }

  createAssessment() {
    throw new Error('createAssessment required');
  }

  getNextItemProvider() {
    throw new Error('getNextItemProvider needs to be implemented in any class that extends AbstractAssessmentInitiatorController');
  }

  processAssessment() {
    throw new Error('You have to implement to the method inside of the initiator');
  }

  getAssessmentProcessor(options) {
    if (this.assessmentController && this.assessmentController.isInProgress) {
      return this.assessmentController;
    }

    const processingOptions = Object.assign({}, {
      sessionModel: this.sessionModel
    }, options);

    this.assessmentController = new AssessmentProcessingController(this, processingOptions);

    return this.assessmentController;
  }

  getQuestionsController() {
    return this.assessmentController.getQuestionsController();
  }

  hasSomethingToDoAsync() {
    return Promise.resolve(false);
  }

  processTraining(trainingOption) {
    return this.createAndProcessAssessment(trainingOption);
  }

  createAndProcessAssessment(assessmentTopicOption) {
    return this.createAssessment(assessmentTopicOption)
      .then(({
        assessment,
        context
      }) => {
        return this.processAssessment(assessment, context);
      });
  }

  processParentFlow() {
    return this.parentProcessor.processSequenceFlow()
      .then(() => {
        logging.info('AssessmentInitiator has ended. Conceded control back up the chain.');
      });
  }

  finishedProcessing() {
    this.processSequenceFlow().then(() => {
      this.processParentFlow();
    });
  }
}

module.exports = AbstractAssessmentInitiatorController;
