const ContentContextConfig = require('@common/modules/main/content/ContentContextConfig');

module.exports = (contextModel) => {
  return {
    fixedHeaderRegion: () => {
      return ContentContextConfig.getContentHeaderDefinition(contextModel);
    },
    fixedContentRegion: () => {
      return ContentContextConfig.getContentControllerDefinition(contextModel);
    }
  };
};
