const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');
const BehaviourTypeEnum = require('@common/data/enums/BehaviourTypeEnum');

class CoachMessageCardView extends Marionette.ItemView {

  className() {
    const evaluationStatus = this.getOption('evaluationStatus');
    const isViewingLastAttempt = this.getOption('isViewingLastAttempt');

    let className = 'evaluation-coach-message card card--informational';

    if (!isViewingLastAttempt && evaluationStatus === EvaluationObjectiveStatus.FAILED) {
      className += ' failed';
    }

    return className;
  }

  getTemplate() {
    return `\
      <% if (isViewingLastAttempt || coachType === 'NoCoach') { %>
        <div class="evaluation-coach-message__icon">
          <div class="icon--badge icon-info_sign"></div>
        </div>
      <% } else { %>
        <div class="evaluation-coach-message__coach">
          <div class="characterarea">
            <div class="bonuscharacter">
              <div class="<%- coachType %><%- coachClass ? ' ' : '' %><%- coachClass %>" role="img" aria-label="<%- coachLabel %>"></div>
            </div>
          </div>
        </div>
      <% } %>
      <p class="evaluation-coach-message__text ax-font ax-font__s ax-font--no-margin">
        <%- messageText %>
      </p>\
    `;
  }

  templateHelpers() {
    const evaluationStatus = this.getOption('evaluationStatus');
    const isViewingLastAttempt = this.getOption('isViewingLastAttempt');
    const coachType = this.getOption('coachType');
    const passingScore = this.getOption('passingScore');
    const coachOptions = this._getCoachOptions(evaluationStatus);

    return {
      isViewingLastAttempt,
      coachType,
      coachClass: coachOptions['coachClass'],
      coachLabel: coachOptions['coachLabel'],
      messageText: this._getMessageText(evaluationStatus, isViewingLastAttempt, passingScore)
    };
  }

  _getCoachOptions(evaluationStatus) {
    let coachClass;
    let coachLabel = '';

    switch (evaluationStatus) {
      case EvaluationObjectiveStatus.NOT_STARTED:
        coachClass = 'welcomeback';
        coachLabel = I18n.t('coaches.hello');
        break;
      case EvaluationObjectiveStatus.PENDING:
      case EvaluationObjectiveStatus.PENDING_AFTER_RETRY:
        coachClass = 'ponder';
        coachLabel = I18n.t('coaches.thinking');
        break;
      case EvaluationObjectiveStatus.FAILED:
        coachClass = 'incorrect';
        coachLabel = I18n.t('coaches.fail');
        break;
      case EvaluationObjectiveStatus.PASSED:
        coachClass = 'correct';
        coachLabel = I18n.t('coaches.pass');
        break;
      default:
    }

    return {
      coachClass,
      coachLabel
    };
  }

  _getMessageText(evaluationStatus, isViewingLastAttempt, passingScore) {
    let messageText = '';

    if (isViewingLastAttempt) {
      messageText = I18n.t('GuidedLearning.evaluation.details.coachMessage.lastAttempt');
    } else {
      switch (evaluationStatus) {
        case EvaluationObjectiveStatus.NOT_STARTED:
          if (this.getOption('evaluationType') === BehaviourTypeEnum.VirtualEvaluation) {
            messageText = I18n.t('GuidedLearning.evaluation.details.coachMessage.notSubmitted');
          } else {
            messageText = I18n.t('GuidedLearning.evaluation.details.coachMessage.notStarted');
          }
          break;
        case EvaluationObjectiveStatus.PENDING:
        case EvaluationObjectiveStatus.PENDING_AFTER_RETRY:
          messageText = I18n.t('GuidedLearning.evaluation.pending');
          break;
        case EvaluationObjectiveStatus.FAILED:
          messageText = I18n.t('GuidedLearning.evaluation.details.coachMessage.failed', { passingScore });
          break;
        case EvaluationObjectiveStatus.PASSED:
          messageText = I18n.t('GuidedLearning.evaluation.details.coachMessage.passed');
          break;
        default:
      }
    }

    return messageText;
  }
}

module.exports = CoachMessageCardView;
