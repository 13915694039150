import {
  StepIcon as MuiStepIcon,
  type StepIconProps as MuiStepIconProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

export type AxStepIconProps = MuiStepIconProps;

const styledOptions = {
  name: 'AxStepIcon'
};

const StyledAxStepIcon = styled(MuiStepIcon, styledOptions)<AxStepIconProps>(({theme}) => {
  return {
    '.Mui-error': {
      color: theme.uiKit.colorSemanticNegative
    }
  };
});

/**
 * A wrapper around the MUI `StepIcon` component
 *
 * `AxStepLabel` is responsible to add stateful styles to this component.
 * In isolation, `AxStepIcon` may not render correctly.
 * It is recommended to use `AxStepLabel` as a wrapper.
 *
 * ### Links
 * - [MUI StepIcon API](https://mui.com/material-ui/api/step-icon/)
 */
export const AxStepIcon = forwardRef(({
  ...otherProps
}: AxStepIconProps, ref: Ref<HTMLDivElement>) => {
  
  const stepIconProps: AxStepIconProps = {
    ...otherProps
  };

  return (
    <StyledAxStepIcon
      { ...stepIconProps }
      ref={ ref } />
  );
});

export default AxStepIcon;
