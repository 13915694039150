import { capitalizeFirstCharacter } from '@common/libs/helpers/types/StringHelpers';
import {
  Select as MuiSelect,
  styled,
  type SelectProps as MuiBaseSelectProps
} from '@mui/material';
import { merge } from 'lodash';
import {
  forwardRef,
  type Ref
} from 'react';

declare module '@mui/material/InputBase' {
  export interface InputBasePropsSizeOverrides {
    'small': false
    'medium': true
    'large': true
  }
}

type PropsToOmit = 'color'|'notched'|'variant';

export type AxSelectProps = Omit<MuiBaseSelectProps, PropsToOmit>;

const styledOptions = {
  name: 'AxSelect'
};

const StyledAxSelect = styled(MuiSelect, styledOptions)<AxSelectProps>(({
  theme,
  size = 'medium'
}) => {
  const defaultStyles = {
    borderColor: theme.uiKit.colorGrey30,
    '& .MuiSelect-select': {
      minWidth: '19.8rem',
      padding: `${ theme.uiKit.spacingXS } ${ theme.uiKit.spacingS }`
    },

    // focused styling
    '&.Mui-focused, \
     &.Mui-focused.Mui-error': {
      ' .MuiOutlinedInput-notchedOutline, \
        &:hover .MuiOutlinedInput-notchedOutline': {
        ...theme.mixins.componentFocusInputStyles(),
        borderWidth: `.1rem`
      },
      ' .focus-visible': {
        // Remove default ax-form input focus state
        borderColor: 'transparent',
        boxShadow: 'none'
      }
    },
    // error styling
    '&.Mui-error .MuiOutlinedInput-notchedOutline, \
     &.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
      ...theme.mixins.componentErrorInputStyles()
    },
    // hover styling
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.uiKit.colorGrey50
    },
    // disabled styling
    '&.Mui-disabled': {
      backgroundColor: theme.uiKit.colorGrey20,
      '.MuiOutlinedInput-notchedOutline, \
       &:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.uiKit.colorGrey30
      },
      '.MuiSelect-icon': {
        color: theme.uiKit.colorGrey50
      }
    },
    // icon styling
    '& .MuiSelect-icon': {
      color: theme.uiKit.colorGrey80
    }
  };

  if (size === 'medium') {
    return {
      ...defaultStyles,
      '& .MuiOutlinedInput-input': {
        height: theme.uiKit.lineHeightXL
      }
    };
  } else if (size === 'large') {
    return {
      ...defaultStyles,
      height: '4rem'
    };
  }

  return defaultStyles;
});

export const DefaultAxSelectProps = {
  sx: {
    '& .MuiSelect-select': {
      paddingRight: '3.8rem'
    }
  },
  MenuProps: {
    transitionDuration: 160
  }
};

/**
 * The `AxSelect` component is a styled `Select` component from MUI.
 *
 * ### Links
 * - [MUI Select API](https://mui.com/api/select/)
 * - [Figma](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=5099-6134&m=dev)
 */
export const AxSelect = forwardRef(({
  inputProps,
  size = 'large',
  ...otherProps
}: AxSelectProps, ref: Ref<HTMLDivElement>) => {
  const SelectProps: AxSelectProps = merge({
    size,
    inputProps,
    ...otherProps
  }, DefaultAxSelectProps, otherProps);

  if (size) {
    SelectProps.className += ` MuiSelect-size${ capitalizeFirstCharacter(size) }`;
  }

  return (
    <StyledAxSelect { ...SelectProps } ref={ ref } />
  );
});

export default AxSelect;
