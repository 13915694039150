const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const EditList = require('@common/data/collections/EditList');
const PageHistoryLayout = require('@training/apps/articles/PageHistoryLayout');
const PageHistoryContentView = require('@training/apps/articles/PageHistoryContentView');
const TitleView = require('@common/components/discover/views/TitleView');
const LoadingController = require('@common/components/loading/LoadingController');

class PageHistoryController extends LayoutController {
  initialize(options = {}) {
    this.model = options.model;

    this.editList = new EditList(null, {
      id: this.model.get('id'),
      queryParams: {
        startingRevision: this.model.get('currentVersion') && this.model.get('currentVersion').revision || 1
      }
    });
  }

  viewDefinition() {
    return {
      ViewClass: PageHistoryLayout
    };
  }

  regionControllers() {
    return {
      titleRegion: {
        viewDefinition: {
          ViewClass: TitleView,
          model: this.model,
          userId: window.apps.auth.session.user.id,
          hideMoreButton: true
        }
      },
      contentRegion: {
        ViewControllerClass: LoadingController.InfiniteCollectionView,
        collection: this.editList,
        contentControllerDefinition: {
          viewDefinition: {
            ViewClass: PageHistoryContentView,
            collection: this.editList
          }
        }
      }
    };
  }
}

module.exports = PageHistoryController;
