const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TagGroupFiltersCountView = require('@training/apps/search/communitiesManagement/tagGroupFilters/index/TagGroupFiltersCountView');
const TagGroupFiltersIndexView = require('@training/apps/search/communitiesManagement/tagGroupFilters/index/TagGroupFiltersIndexView');
const SelectableCollectionView = require('@common/components/selectable_collection_list/SelectableCollectionView');
require('@common/libs/behaviors/scrollable/Scrollable');

class TagGroupFiltersIndexController extends LayoutController {
  initialize(options = {}) {
    this._tagGroupFilterList = options.tagGroupFilterList;
    this._onTagGroupFilterItemClickedFn = options.onTagGroupFilterItemClickedFn;
    this._onTagGroupFilterCreateFn = options.onTagGroupFilterCreateFn;
    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: TagGroupFiltersIndexView
    };
  }

  delegateEvents() {
    return {
      'view:item:new': () => {
        this._onTagGroupFilterCreateFn();
      }
    };
  }

  regionControllers() {
    const tagGroupFilterList = this._tagGroupFilterList;

    return {
      tagGroupFilterList: {
        viewDefinition: {
          ViewClass: SelectableCollectionView,
          collection: tagGroupFilterList,
          showDeleteButton: false,
          autoSelect: false
        },
        delegateEvents: {
          'view:item:selected': (controller, view, model) => {
            this._onTagGroupFilterItemClickedFn(model.get('id'));
          }
        }
      },
      tagGroupFilterCount: {
        viewDefinition: {
          ViewClass: TagGroupFiltersCountView,
          collection: tagGroupFilterList
        },
        delegateEvents: {
          'view:inflate': (controller, view) => {
            view.listenTo(tagGroupFilterList, 'sync add remove', () => {
              view.render();
            });
          }
        }
      }
    };
  }
}


module.exports = TagGroupFiltersIndexController;
