// Module
var code = `<div>
  <div class="title-wrapper">
    <div class="title-block">
      <div class="recovery-icon icon-envelope_alt"></div>
      <div class="recovery-title-wrapper">
          <div class="recovery-title"><%- t('login.forgotUsername.successTitle') %></div>
          <div><%- t('login.forgotUsername.successSubtitle') %></div>
      </div>
    </div>
  </div>
  <div class="recovery-success-message"><%= successMessage %></div>
  <div class="recovery-success-message"><%- t('login.forgotUsername.successFurtherInformation') %></div>
</div>
`;
// Exports
module.exports = code;