const logging = require('logging');
const {
  compact,
  defaults,
  isArray
} = require('underscore');

const {
  CollectionView,
  ItemView
} = require('Marionette');
const {
  Collection,
  ChildViewContainer
} = require('Backbone');

const ViewConfig = require('@common/components/view/ViewConfig');

class StackedCollectionView extends CollectionView {
  className() {
    return 'stacked-collection-view';
  }

  collectionEvents() {
    return {
      change: 'onChangeViewConfig',
      invalid: 'onInvalidViewConfig'
    };
  }

  constructor(options = {}) {
    super(options);

    (({
      childView: this.childView = ItemView
    } = options));

    this.addViewConfigs = this.addViewConfigs.bind(this);
    this.resetViewConfigs = this.resetViewConfigs.bind(this);

    this._initializeViewConfigCollection(options);

    this._stackedChildren = new ChildViewContainer();

    this._setInitialViewConfigs(this.getOption('viewConfigs'));
  }

  childViewOptions(viewConfig) {
    let defaultOptions;
    if (this.model != null) {
      defaultOptions = { model: this.model };
    }
    const viewOptions = viewConfig.get('viewOptions');
    return $.extend(true, {}, defaultOptions, viewOptions);
  }

  getChildView(viewConfig) {
    return viewConfig.get('viewClass') || super.getChildView(viewConfig);
  }

  // Get child view instance by unique id
  getView(id) {
    return this._stackedChildren.findByCustom(id);
  }

  // Ensure the views are added to the @children container using the key as a custom index for later retrieval
  // in onChangeViewConfig.
  addChild(viewConfig, ConfigView, index) {
    const view = super.addChild(viewConfig, ConfigView, index);
    this._stackedChildren.add(view, viewConfig.id || viewConfig.cid);
    return view;
  }

  // Ensure any child view that's removed from the CollectionView is also removed from the custom @_stackedChildren
  // container.
  removeChildView(view) {
    this._stackedChildren.remove(view);
    return super.removeChildView(view);
  }

  addViewConfigs(viewConfigs = [], options = {}) {
    const compactViewConfigs = compact([].concat(viewConfigs));
    const collectionOptions = defaults(options, {
      add: true,
      remove: false,
      merge: true,
      validate: true
    });

    return this.collection.set(compactViewConfigs, collectionOptions);
  }

  resetViewConfigs(viewConfigs = [], options = {}) {
    const compactViewConfigs = compact([].concat(viewConfigs));
    const collectionOptions = defaults(options, {validate: true});

    return this.collection.reset(compactViewConfigs, collectionOptions);
  }

  onChangeViewConfig(viewConfig) {
    const view = this.getView(viewConfig.id) || this.getView(viewConfig.cid);
    this.removeChildView(view);
    const ChildView = this.getChildView(viewConfig);
    const index = this.collection.indexOf(viewConfig);
    this.addChild(viewConfig, ChildView, index);
  }

  onInvalidViewConfig(collection, validationError = {}, options = {}) {
    logging.warn(validationError.errorMsg, validationError.attrs, options);
  }

  _initializeViewConfigCollection(options = {}) {
    const {
      collection,
      viewConfigCollection,
      viewConfigModel
    } = options;

    if (collection == null) {
      const ViewConfigCollection = viewConfigCollection || Collection;
      const ViewConfigModel = viewConfigModel || ViewConfig;

      this.collection = new ViewConfigCollection([], {model: ViewConfigModel});
    }
  }

  _setInitialViewConfigs(viewConfigs = []) {
    if (!isArray(viewConfigs)) {
      throw new Error('Invalid viewConfigs options, needs to be an Array!');
    }

    this.resetViewConfigs(viewConfigs);
  }
}

module.exports = StackedCollectionView;
