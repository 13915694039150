export default [
  {
    id: 'groups',
    enabled: true
  },
  {
    id: 'communications',
    enabled: true
  },
  {
    id: 'discover',
    enabled: true
  },
  {
    id: 'operations',
    enabled: true
  },
  {
    id: 'trainingEvents',
    enabled: true
  },
  {
    id: 'trainingContent',
    enabled: true
  },
  {
    id: 'trainingPrograms',
    enabled: true
  },
  {
    id: 'gamesAndRewards',
    enabled: true
  },
  {
    id: 'reporting',
    enabled: true
  },
  {
    id: 'platformSettings',
    enabled: true
  },
  {
    id: 'users',
    enabled: true
  },
  {
    id: 'editAdmin',
    parentId: 'users',
    enabled: true
  }
];
