const $os = require('detectOS');

const CSSInjectorPackageFactory = require('@common/services/cssLoader/CSSInjectorPackageFactory');

const { getStylePath } = require('@common/libs/helpers/app/CompilationInfoHelpers');

const {
  getMediaElementSheets
} = require('@common/libs/helpers/features/CSSPackageHelpers');

const getDesktopOrMobileSheets = () => {
  const paths = [];

  if ($os.mobile) {
    paths.push(getStylePath(process.styleSourceKey.trainingMobileCss));
  } else {
    paths.push(getStylePath(process.styleSourceKey.trainingDesktopCss));
    paths.push(getStylePath(process.styleSourceKey.jcropCss));
  }

  return paths;
};

const KEY = 'training';

class TrainingCSSLoaderPackageFactory extends CSSInjectorPackageFactory {
  create(options = {}) {
    const {
      key,
      paths
    } = super.create(options);

    return {
      key,
      paths: [
        ...paths,
        ...getDesktopOrMobileSheets(),
        ...getMediaElementSheets()
      ]
    };
  }
}

module.exports = {
  KEY,
  Factory: TrainingCSSLoaderPackageFactory.bind(TrainingCSSLoaderPackageFactory, KEY)
};
