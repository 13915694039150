const I18n = require('@common/libs/I18n');

const CommonLocaleBundle = require('@common/libs/locale/CommonLocaleBundle');
const CommonNonTranslatableLocaleBundle = require('@common/libs/locale/CommonNonTranslatableLocaleBundle');
const ProjectLocaleBundle = require('@common/libs/locale/ProjectLocaleBundle');
const TenantLocaleBundle = require('@common/libs/locale/TenantLocaleBundle');

I18n.registerBundle(new CommonLocaleBundle());
I18n.registerBundle(new CommonNonTranslatableLocaleBundle());
I18n.registerBundle(new ProjectLocaleBundle());
I18n.registerBundle(new TenantLocaleBundle());
