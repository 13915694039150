const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const propsDefault = () => {
  return {
    // Common props for all input, select, textarea
    ariaControls: '',
    ariaLabel: '', // Only required if no label,
    className: '', // Container class (default)
    classNameLabel: '',
    data: {}, // Optional data- properties
    disabled: false,
    error: false,
    fullWidth: false,
    id: '',
    label: '',
    name: '',
    placeholder: '',
    required: false,
    size: ''
  };
};

const propsInput = () => {
  return _.defaults({
    autocapitalize: '',
    autocomplete: '',
    autocorrect: '',
    classNameInput: '',
    iconAriaLabelRight: '',
    iconClassLeft: '',
    iconClassRight: '',
    showDropdownIcon: false, // Icon for filter inputs (SE-21180)
    tagName: 'input',
    type: 'text',
    value: '',
    qaClassNameInput: 'qa-input'
  }, propsDefault());
};

const propsInputNumber = () => {
  return _.defaults({
    max: null,
    min: null,
    step: 1
  }, propsInput());
};

const propsInputSearch = () => {
  return _.defaults({
    autocapitalize: 'off',
    autocomplete: 'off',
    autocorrect: 'off',
    iconAriaLabelRight: I18n.t('general.clearSearch'),
    iconClassLeft: 'icon-search',
    iconClassRight: 'qa-input-search-clear js-clear icon-remove',
    placeholder: I18n.t('general.search'),
    type: 'search',
    qaClassNameInput: 'qa-input-search'
  }, propsInput());
};

const getPropsSelect = () => {
  return _.defaults({
    classNameSelect: '',
    options: {},
    noFormEditorDefault: false, // Add the `no-default` property
    tagName: 'select',
    type: 'select',
    qaClassNameSelect: 'qa-select'
  }, propsDefault());
};

const propsTextarea = () => {
  return _.defaults({
    rows: 3,
    tagName: 'textarea'
  }, propsInput());
};

const classListContainerDefault = (classDefault = '', props = {}) => {
  // Common classes for all input, select, textarea
  let classList = classDefault;

  if (!props.id) {
    // Placeholder ID for accessibility
    props.id = `i${ btoa(Math.random()).substring(0, 24) }`;
  }

  if (props.className) {
    classList += ` ${ props.className }`;
  }

  if (!props.label) {
    classList += ` ${ classDefault }--no-label`;
  }

  if (props.error) {
    classList += ` ${ classDefault }--error`;
  }

  if (props.size) {
    classList += ` ${ classDefault }--${ props.size }`;
  }

  if (props.fullWidth) {
    classList += ` ${ classDefault }--full-width`;
  }

  return classList;
};

const classListContainerInput = (classDefault = '', props = {}) => {
  let classList = classListContainerDefault(classDefault, props);

  if (props.iconClassRight) {
    classList += ` ${ classDefault }--icon-right`;
  }

  if (props.iconClassLeft) {
    classList += ` ${ classDefault }--icon-left`;
  }

  return classList;
};

const classListDefault = (qaClassNames = '', classNames = '') => {
  if (!qaClassNames && !classNames) {
    return '';
  }

  let classList = qaClassNames;

  if (classNames) {
    classList += ` ${ classNames }`;
  }

  return ` class="${ classList }" `;
};

const dataListDefault = (props) => {
  let dataProperties = '';
  _.mapObject(props.data, (val, key) => {
    const hyphenKey = key.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
    dataProperties += ` data-${ hyphenKey }="${ val }"`;
  });
  return dataProperties;
};

const getPropsInput = (customProps) => {
  let defaultProps = propsInput();

  switch (customProps.type) {
    case 'search':
      defaultProps = propsInputSearch();
      break;
    case 'number':
      defaultProps = propsInputNumber();
      break;
    case 'textarea':
      defaultProps = propsTextarea();
      break;
    default:
      break;
  }

  return defaultProps;
};

module.exports = {
  classListContainerDefault: classListContainerDefault,
  classListContainerInput: classListContainerInput,
  classListDefault: classListDefault,
  dataListDefault: dataListDefault,
  getPropsInput: getPropsInput,
  getPropsSelect: getPropsSelect
};
