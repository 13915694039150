const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

class InitialDailyTrainingAssessmentInitiatorConfig {
  constructor(trainingType, initiator, predicate, configurationProvider) {
    this.trainingType = SessionTrainingType.assertLegalValue(trainingType);
    this.initiator = initiator;
    this.predicate = predicate;
    this.configurationProvider = configurationProvider;

    if (!this.initiator) {
      throw new Error('You must provide a initiator');
    }

    if (!this.predicate) {
      throw new Error('You must provide a predicate');
    }

    if (!this.configurationProvider) {
      throw new Error('You must provide a configuration provider, even if it is a null implementation');
    }
  }

  getAsyncPredicate() {
    return this.predicate;
  }

  getTrainingType() {
    return this.trainingType;
  }

  getInitiator() {
    return this.initiator;
  }

  getConfigurationProviderToInject() {
    return this.configurationProvider;
  }
}

module.exports = InitialDailyTrainingAssessmentInitiatorConfig;
