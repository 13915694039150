const _ = require('underscore');

const defaultProps = {
  ariaLabel: '', // Only required if no label,
  ariaLabelledby: '', //only required if want to override the label
  checked: false,
  className: '', // Container class (default)
  classNameInput: '',
  classNameLabel: '',
  data: {}, // Optional data- properties
  disabled: false,
  id: '',
  inline: false,
  label: '',
  tagName: 'div',
  value: '<%= id %>',
  tabindex: ''
};

const defaultCheckboxProps = _.defaults({
  partial: false
}, defaultProps);

const defaultRadioProps = _.defaults({
  name: ''
}, defaultProps);

const defaultToggleProps = _.defaults({
  role: 'switch'
}, defaultProps);

const defaultTemplate = (containerClass, type, props) => {
  const containerClassDefault = containerClass.replace('.', '');
  let containerClassList = containerClassDefault;
  let dataProperties = '';

  if (props.data) {
    _.mapObject(props.data, (val, key) => {
      const hyphenKey = key.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
      dataProperties += ` data-${ hyphenKey }="${ val }"`;
    });
  }

  if (!props.id) {
    // Placeholder ID so click on label (that looks like input) changes the hidden input state
    props.id = `i${ btoa(Math.random()).substring(0, 24) }`;
  }

  if (props.className) {
    containerClassList += ` ${ props.className }`;
  }

  if (!props.label) {
    containerClassList += ` ${ containerClassDefault }--no-label`;
  }

  if (props.inline) {
    containerClassList += ` ${ containerClassDefault }--inline`;
  }

  if (props.partial) {
    containerClassList += ` ${ containerClassDefault }--partial`;
  }

  return _.tpl(`
    <${ props.tagName } class="${ containerClassList }">
      <input
        type="${ type }"
        id="${ props.id }"
        ${ props.checked ? 'checked' : '' }
        ${ props.disabled ? 'disabled' : '' }
        ${ props.ariaLabel ? 'aria-label="' + props.ariaLabel + '"' : '' }
        ${ props.ariaLabelledby ? 'aria-labelledby="' + props.ariaLabelledby + '"' : '' }
        ${ props.value ? 'value="' + props.value + '"' : '' }
        ${ props.name ? 'name="' + props.name + '"' : '' }
        ${ props.role ? 'role="' + props.role + '"' : '' }
        ${ props.classNameInput ? 'class="' + props.classNameInput + '"' : '' }
        ${ props.tabindex ? 'tabindex=' + props.tabindex + '"' : '' }
        ${ dataProperties }
      >
      <label
        for="${ props.id }"
        ${ props.classNameLabel ? 'class="' + props.classNameLabel + '"' : '' }
      >${ props.label }</label>
    </${ props.tagName }>
  `)(props);
};

const radioContainer = '.ax-radio';
const checkboxContainer = '.ax-checkbox';
const toggleContainer = '.ax-toggle';

const AxFormElementHelpers = {
  axFormSelectors: {
    radioContainer: radioContainer,
    radio: radioContainer + ' input',
    checkboxContainer: checkboxContainer,
    checkbox: checkboxContainer + ' input',
    toggleContainer: toggleContainer,
    toggle: toggleContainer + ' input'
  },

  axRadio(props = {}) {
    _.defaults(props, defaultRadioProps);
    return defaultTemplate(radioContainer, 'radio', props);
  },

  axCheckbox(props = {}) {
    _.defaults(props, defaultCheckboxProps);
    return defaultTemplate(checkboxContainer, 'checkbox', props);
  },

  axToggle(props = {}) {
    _.defaults(props, defaultToggleProps);
    return defaultTemplate(toggleContainer, 'checkbox', props);
  }
};

module.exports = AxFormElementHelpers;
