const _ = require('underscore');

const I18n = require('@common/libs/I18n');

const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');
const BehaviourTypeEnum = require('@common/data/enums/BehaviourTypeEnum');

const AssessmentTopicItemView = require('@training/apps/training/views/assessments/AssessmentTopicItemView');

class EvaluationItemView extends AssessmentTopicItemView {
  template = _.tpl(require('@training/apps/training/templates/assessments/EvaluationItemView.html'));

  render(...args) {
    super.render(...args);

    return this;
  }

  _renderLastResult() {
    /**
     * For the case of Evaluation we don't display the green top edge because there is no pass state.
     * We always style it without any color indication
     */

    return undefined;
  }

  _renderAriaLabel() {
    return undefined;
  }

  _renderRewardPointsString() {
    const evaluationProgressRecord = this.model.get('evaluationProgressRecord');

    const points = this.model.get('pointsForPass');
    const $rewardStringDiv = this.$('.tile-meta .rewards');

    if (evaluationProgressRecord == null && points !== 0) {
      $rewardStringDiv.text(I18n.t('assessments.item.rewardPoints.possible', { points }));
    } else {
      $rewardStringDiv.hide();
    }
  }

  _renderContentString() {
    const evaluationType = this.model.get('evaluationType');
    const evaluationProgressRecord = this.model.get('evaluationProgressRecord');
    const status = evaluationProgressRecord && evaluationProgressRecord.pathStatus;

    const isEvaluationPending = (status === EvaluationObjectiveStatus.PENDING) || (status === EvaluationObjectiveStatus.PENDING_AFTER_RETRY);
    const $statusStringDiv = this.$('.tile-meta .content');

    const statusTemplate = _.tpl(`\
      <span class="icon-pending"></span>
      <span><%= statusMessage %></span>\
    `);
    const statusMessage = (evaluationType === BehaviourTypeEnum.VirtualEvaluation) ? I18n.t('assessments.item.evaluation.pendingVirtual') : I18n.t('assessments.item.evaluation.pending');

    if (isEvaluationPending) {
      $statusStringDiv.html(statusTemplate({ statusMessage }));
    }
  }

  _renderIcon() {
    this.$('.topic .icon')
      .addClass('icon-evaluation')
      .removeClass('hidden')
      .attr('aria-label', I18n.t('GuidedLearning.icons.evaluation'));
  }
}

module.exports = EvaluationItemView;
