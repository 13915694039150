const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');

require('@common/libs/behaviors/resizable/Resizable');

class MultiColumnListView extends UIKit.View {
  get behaviors() {
    return {
      Resizable: {}
    };
  }

  className() {
    return 'multi-column-list';
  }

  initialize(options = {}) {
    this.EmptyView = options.EmptyView || UIKit.View;
    this.ModelView = options.ModelView || UIKit.View;
    this.columns = options.columns || 2;

    this.isRtl = I18n.isCurrentLanguageRtl();
    this.cssDirection = this.isRtl ? 'right' : 'left';

    this.listenTo(this.collection, 'change', this.rebalanceColumns);

    this.listenTo(this.collection, 'sync', () => {
      this.$el.empty();

      // Set empty view if the collection is empty
      if (this.collection.length === 0) {
        const emptyView = new this.EmptyView();
        this.addItemView(emptyView);
      } else {
        this.collection.each((model) => {
          const listItem = new this.ModelView({model});

          // Event propagation since the MultiColumnListView owns the ModelView
          this.listenTo(listItem, 'all', (event, ...args) => {
            args.unshift(event, listItem);
            this.trigger.apply(this, args);
          });

          this.addItemView(listItem);
        });
      }

      this.triggerAdjustment();
    });
  }

  render() {
    this.$el.addClass(this.className);
    return this;
  }

  onResize() {
    this.rebalanceColumns();
  }

  addItemView(itemView) {
    this.$el.append(itemView.render().el);
    this.rebalanceColumns();
  }

  rebalanceColumns() {
    let columnHeight = Math.floor(this.collection.length / this.columns);
    if ((this.collection.length % this.columns) > 0) {
      columnHeight++;
    }

    const columnNames = (__range__(1, this.columns, true).map((number) => {
      return `column-${ number }`;
    }));

    const listItems = this.$('li');
    listItems.removeClass(columnNames.join(' '));

    let columnHeightPx = 0;
    const columnWidthPercent = 100 / this.columns;

    for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
      const columnName = columnNames[columnIndex];
      const startIndex = columnIndex * columnHeight;
      const endIndex = startIndex + columnHeight;
      var column = listItems.slice(startIndex, endIndex);

      column.addClass(columnName);
      column.css({
        [`margin-${ this.cssDirection }`]: `${ columnIndex * columnWidthPercent }%`,
        width: `${ columnWidthPercent }%`
      });

      // calculate the height of the first column
      if (columnIndex === 1) {
        this.$(`.${ columnNames[columnIndex - 1] }`).each(function() {
          const $listItem = $(this);
          const outerHeight = $listItem.outerHeight();
          columnHeightPx += outerHeight;
        });
      }

      // set the margin-top on the list items for columns 1..n
      if (columnIndex > 0) {
        column.each((rowIndex, elem) => {
          const $elem = $(elem);
          let marginTop = -columnHeightPx;
          if (rowIndex > 0) {
            const $prevElem = $(column[rowIndex - 1]);
            let prevMarginTop = $prevElem.css('margin-top');
            prevMarginTop = parseInt(prevMarginTop, 10);
            const outerHeight = $prevElem.outerHeight();
            marginTop = prevMarginTop + outerHeight;
          }
          $elem.css({'margin-top': `${ marginTop }px`});
        });
      }
    }
  }
}

module.exports = MultiColumnListView;

function __range__(left, right, inclusive) {
  const range = [];
  const ascending = left < right;
  const end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
