const _ = require('underscore');

const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');

const EventEnrollmentStatus = require('@common/data/enums/EventEnrollmentStatus');

const AssessmentTopicItemView = require('@training/apps/training/views/assessments/AssessmentTopicItemView');

class EventEnrollmentItemView extends AssessmentTopicItemView {
  template = _.tpl(require('@training/apps/training/templates/assessments/EventEnrollmentItemView.html'));

  render() {
    super.render(...arguments);
    this._renderStatusString();

    return this;
  }

  _renderLastResult() {
    const eventEnrollmentRecord = this.model.get('eventEnrollmentRecord');
    const status = eventEnrollmentRecord && eventEnrollmentRecord.status;
    let resultClass;

    if (status === EventEnrollmentStatus.Enrolled || status === EventEnrollmentStatus.Waiting) {
      resultClass = this._getResultClass(eventEnrollmentRecord);

      this._renderPassedState(resultClass);
    }
  }

  _renderAriaLabel() {}

  _renderRewardPointsString() {}

  _getResultClass() {
    // For the case of Events we only display the Result Page when it's "enrolled" so we always style it as "passed"
    return 'pass';
  }

  _renderPassedState(resultClass) {
    this.$el.addClass(resultClass);
  }

  _renderContentString() {
    const eventEnrollmentRecord = this.model.get('eventEnrollmentRecord');
    const status = eventEnrollmentRecord && eventEnrollmentRecord.status;
    const numScheduledLearningEventsAvailable = this.model.get('event').numScheduledLearningEventsAvailable;

    const $contentStringDiv = this.$('.tile-meta .content');

    const contentString = (() => {
      if (status === EventEnrollmentStatus.Enrolled || status === EventEnrollmentStatus.Waiting) {
        return this._getScheduledEventStartDate(eventEnrollmentRecord);
      } else if (numScheduledLearningEventsAvailable) {
        if (numScheduledLearningEventsAvailable === 1) {
          return I18n.t('assessments.item.event.scheduledEvents.singular');
        }
        return I18n.t('assessments.item.event.scheduledEvents.plural', {count: numScheduledLearningEventsAvailable});

      }
      return '';

    })();

    $contentStringDiv.attr('title', contentString);
    $contentStringDiv.text(contentString);
  }

  _getScheduledEventStartDate(item) {
    const startDate = item.scheduledLearningEvent.get('startDate');
    return (startDate != null) ? dateHelpers.convertDateFormatLongDateWithStrings(startDate) : '';
  }

  _renderStatusString() {
    const eventEnrollmentRecord = this.model.get('eventEnrollmentRecord');
    const status = eventEnrollmentRecord && eventEnrollmentRecord.status;

    const $statusStringDiv = this.$('.tile-meta .status');

    if (status === EventEnrollmentStatus.Enrolled) {
      $statusStringDiv.text(I18n.t('assessments.item.event.enrolled'));
    } else if (status === EventEnrollmentStatus.Waiting) {
      $statusStringDiv.text(I18n.t('assessments.item.event.onWaitList'));
    } else {
      $statusStringDiv.hide();
    }
  }

  _renderIcon() {
    this.$('.topic .icon')
      .addClass('icon-events')
      .removeClass('hidden')
      .attr('aria-label', I18n.t('GuidedLearning.icons.event_enrollment'));
  }
}

module.exports = EventEnrollmentItemView;
