const LocaleBundle = require('./LocaleBundle');
const { getLocaleMappedForNoStrings } = require('@common/libs/locale/LocaleBundleHelper');

// Include the default language in our bundle
require(`@common/assets/strings/translatable/${ process.env.defaultLocale }/str.js`);

class CommonLocaleBundle extends LocaleBundle {
  load(localeCode) {
    const code = getLocaleMappedForNoStrings(localeCode);
    return code === process.env.defaultLocale ? this._importDefault() : [
      this._importDefault(),
      this._importLocale(code)
    ];
  }

  _importDefault() {
    return import(`@common/assets/strings/translatable/${ process.env.defaultLocale }/str.js`);
  }

  _importLocale(localeCode) {
    return import(`@common/assets/strings/translatable/${ localeCode }/str.js`);
  }
}

module.exports = CommonLocaleBundle;
