const {
  Behavior,
  isNodeAttached
} = require('Marionette');

const Enum = require('@common/data/enums/Enum');

const Behaviors = require('@common/libs/behaviors/Behaviors');

const _winDimensions = require('@common/libs/behaviors/resizable/WinDimensions');

const Options = Enum({
  ENABLED: 'enabled',
  TRIGGERED_EVENT: 'event'
});

Behaviors.Resizable = class Resizable extends Behavior {
  static Options = Options;

  static toggleResizable(view, toggle) {
    return view.trigger('enable:resizable', toggle);
  }

  defaults() {
    return {
      [Options.ENABLED]: true,
      [Options.TRIGGERED_EVENT]: 'resize'
    };
  }

  initialize() {
    this._isEnabled = this.getOption(Options.ENABLED);
    this._triggeredEvent = this.getOption(Options.TRIGGERED_EVENT);
    this.listenToOnce(this.view, 'render', this.onFirstRender);
    this.listenTo(this.view, 'enable:resizable', this._enableResize);
  }

  onFirstRender() {
    this.listenTo(_winDimensions, 'change', this._triggerResize);
    this._triggerInitialResize();
  }

  onAttach() {
    this._triggerInitialResize();
  }

  onShow() {
    this._triggerInitialResize();
  }

  onDestroy() {
    this._enableResize(false);
  }

  getWinWidth() {
    return _winDimensions.get('width');
  }

  getWinHeight() {
    return _winDimensions.get('height');
  }

  _enableResize(toggle = true) {
    this._isEnabled = toggle;
  }

  _triggerInitialResize() {
    if (this._initResizeCalled || !isNodeAttached(this.view.el)) {
      return;
    }

    this._initResizeCalled = true;
    this._triggerResize();
  }

  _triggerResize() {
    if (this._isEnabled) {
      this.view.triggerMethod(this._triggeredEvent, this.getWinHeight(), this.getWinWidth());
    }
  }
};

module.exports = Behaviors.Resizable;
