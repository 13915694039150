import { styled } from '@mui/material';
import {
  TabContext as MuiTabContext,
  type TabContextProps as MuiTabContextProps
} from '@mui/lab';

export type AxTabContextProps = MuiTabContextProps;

const styledOptions = {
  name: 'AxTabContext'
};

const StyledAxTabContext = styled(MuiTabContext, styledOptions)<AxTabContextProps>(() => {
  return {};
});

export const AxTabContext = ({
  ...otherProps
}: AxTabContextProps) => {
  const TabContextProps: AxTabContextProps = {
    ...otherProps
  };

  return (
    <StyledAxTabContext { ...TabContextProps }/>
  );
};

export default AxTabContext;
