const I18n = require('@common/libs/I18n');
const { getValue } = require('@common/libs/helpers/types/ObjectHelpers');

const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

const {
  isTopicRetake,
  isCertificationOption,
  isGuidedTopicRetake,
  isTask,
  isEvent,
  isEvaluation
} = require('@common/components/todaysActivities/TodaysActivitiesCardHelper');

const certificationsButtonString = (nextStartableItem) => {
  return isTopicRetake(nextStartableItem) ? 'start.startButton.certRetake' : 'start.startButton.certification';
};

const topicButtonString = (nextStartableItem) => {
  return isTopicRetake(nextStartableItem) ? 'start.startButton.topicRetake' : 'start.startButton.topic';
};

const guidedButtonString = (nextStartableItem) => {
  let stringKey = 'start.startButton.topic';

  if (isCertificationOption(nextStartableItem)) {
    if (isGuidedTopicRetake(nextStartableItem)) {
      stringKey = 'start.startButton.certRetake';
    }

    stringKey = 'start.startButton.certification';
  } else if (isGuidedTopicRetake(nextStartableItem)) {
    stringKey = 'start.startButton.topicRetake';
  } else if (isTask(nextStartableItem)) {
    stringKey = 'start.startButton.viewTask';
  } else if (isEvent(nextStartableItem)) {
    stringKey = 'start.startButton.enroll';
  } else if (isEvaluation(nextStartableItem)) {
    stringKey = 'start.startButton.evaluation';
  }

  return stringKey;
};

const sessionTypeButtonTextMap = {
  [SessionTrainingType.DailyTraining]: 'start.startButton.start',
  [SessionTrainingType.CertificationTraining]: certificationsButtonString,
  [SessionTrainingType.IntroductoryTraining]: topicButtonString,
  [SessionTrainingType.GuidedLearningTraining]: guidedButtonString,
  [SessionTrainingType.RefresherTraining]: topicButtonString,
  [SessionTrainingType.FormalExamTraining]: 'start.startButton.exam'
};

const getString = (sessionType, nextStartableItem) => {
  if (nextStartableItem.inProgressAssessmentGetter() != null) {
    return I18n.t('assessments.item.action.resume');
  }

  SessionTrainingType.assertLegalValue(sessionType);

  return I18n.t(getValue(sessionTypeButtonTextMap[sessionType], null, nextStartableItem));
};

module.exports = {
  getString
};
