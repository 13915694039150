const { extend, isFunction } = require('underscore');

const NamedParameterClassFactory = require('@common/libs/NamedParameterClassFactory');

const ViewController = require('@common/libs/UI/controllers/ViewController');
const RegionControllerManager = require('@common/libs/UI/controllers/RegionControllerManager');

const MarionetteViewFactory = require('@common/libs/UI/views/MarionetteViewFactory');

class ViewControllerFactory extends NamedParameterClassFactory {

  /*
    Utility function for building out a ViewController hierarchy and getting back the root View.
    XXX ONLY neded when the root view needs to be used outside of a larger ViewController hierarchy.
    ie. dealing with legacy Views/Layouts
  */
  static createLegacyView(...definitionProperties) {
    const viewControllerFactory = new ViewControllerFactory();
    const controller = viewControllerFactory.create(...definitionProperties);
    controller.inflateView();
    return controller.getView();
  }

  constructor(options = {}) {
    const defaultViewControllerDefinition = {
      ViewControllerClass: ViewController,
      viewFactory: new MarionetteViewFactory()
    };

    const updatedOptions = {
      definitionClassProp: options.definitionClassProp || 'ViewControllerClass',
      defaultDefinition: extend({}, defaultViewControllerDefinition, options.defaultDefinition)
    };

    super(updatedOptions);
  }

  create(...args) {
    const regionControllerManager = new RegionControllerManager({ viewControllerFactory: this });
    const controller = super.create(...args, { regionControllerManager });

    if (isFunction(controller.initializeRegionControllers)) {
      controller.initializeRegionControllers();
    }

    return controller;
  }
}

module.exports = ViewControllerFactory;
