// Module
var code = `<div class="table-helper answer-text-limit-max-width">
  <div class="answerspacer table-row-helper <%- getAnswerResult() %> <%- getTranslationStatusCSS() %> <%- selectedStatus %>">
    <div class="answerradio table-cell-helper">
      <div class="radiobutton"></div>
      <% if(getAnswerResult() === 'correct') {%>
        <span class="icon-ok_sign"></span>
      <%} else {%>
        <span class="icon-ban_circle"></span>
      <% } %>
    </div>
    <div class="answerimage touchable table-cell-helper">
      <% if(questionImage) {%>
        <div class="questionimage image-wrapper imgwrap" data-media-id="<%- questionImage.id %>"></div>
      <%}%>
    </div>
    <div class="answertext table-cell-helper">
      <% if(selectedStatus === 'selected') { %>
        <p class="off-screen"><%- t('question.accessibility.selected') %></p>
      <% } else { %>
        <p class="off-screen"><%- t('question.accessibility.notSelected') %></p>
      <% } %>

      <% if(getAnswerResult() === 'correct') {%>
        <p class="off-screen"><%- t('question.accessibility.correct') %></p>
      <% } else if(getAnswerResult() === 'incorrect') { %>
        <p class="off-screen"><%- t('question.accessibility.incorrect') %></p>
      <% } %>
      <span>
        <%- getTranslationStringIfNoText() %>
        <%= optionText %>
      </span>
    </div>
  </div>
</div>
<div class="zoom-placeholder hidden">
`;
// Exports
module.exports = code;