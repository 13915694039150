const CommunityInsightsTableItemView = require('@training/apps/training/communitiesInsights/components/tables/CommunityInsightsTableItemView');
const I18n = require('@common/libs/I18n');
class CurrentPagesTableItemView extends CommunityInsightsTableItemView {
  getTemplate() {
    return `
      <td class="recent-pages-table__desktop-column">
        <div class="community-insights__desktop-time">
          <%= timeAgo() %>
        </div>
      </td>
      <td class="reports-table__title-column">
        <a href="<%- pageHref %>"
        <% if (!isInMobileApp) { %> target="_blank" <% } %>
        class="community-insights__desktop-title"
        aria-label="${ I18n.t('discover.insights.recentpagesAria', { title: this.model.get('title') }) }"
        >
          <%= truncatedText(title) %>
        </a>
        <p class="community-insights__desktop-community"><%= truncatedText(community) %></p>
      </td>
      <td class="reports-table__type-column">
        <%- typeText %>
      </td>
      <td class="reports-table__value-column">
        <%- formatNumber(metrics.impressions.value) %>
      </td>
      <td class="reports-table__value-column">
        <%- formatNumber(metrics.engagements.value) %>
      </td>
      <td class="reports-table__value-column">
        <%- formatNumber(metrics.views.value) %>
      </td>
      <td class="reports-table__value-column">
        <%- formatNumber(metrics.viewers.value) %>
      </td>
      <td class="community-insights__navigate-column">
        <span class="community-insights__navigate-column--link icon-chevron_right js-view-insights"
        title="${ I18n.t('discover.insights.viewInsights', { title: this.model.get('title') }) }"
        role="button"
        tabindex="0">
        </span>
      </td>
    `;
  }
}

module.exports = CurrentPagesTableItemView;
