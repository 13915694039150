const { ItemView } = require('Marionette');

class LoadingRowView extends ItemView {

  constructor(options) {
    super(Object.assign({}, options, {
      tagName: 'DIV'
    }));

    this.$el.addClass('loading-row');
  }

  get template() {
    return '<div class="loading-spinner"></div>';
  }
}

module.exports = LoadingRowView;
