import {
  Box as MuiBox,
  styled,
  type BoxTypeMap as MuiBoxTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxBoxCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxBoxTypeMap<P = object, D extends React.ElementType = 'div'> = MuiBoxTypeMap<P & AxBoxCustomProps, D>;

export type AxBoxProps<
  D extends ElementType = AxBoxTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxBoxTypeMap<P & AxBoxCustomProps, D>, D>;

const styledOptions = {
  name: 'AxBox'
};

const StyledAxBox = styled(MuiBox, styledOptions)<AxBoxProps>(() => {
  return {};
});

export const AxBox: MuiOverridableComponent<AxBoxTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxBoxProps, ref: Ref<unknown>) => {
  const BoxProps: AxBoxProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxBox { ...BoxProps } ref={ ref }>
      { children }
    </StyledAxBox>
  );
});

export default AxBox;
