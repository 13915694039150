const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');

const PageInsightsEngagementTileController = require('@training/apps/insights/engagement/PageInsightsEngagementTileController');
const PageInsightsReactionsTileController = require('@training/apps/insights/engagement/PageInsightsReactionsTileController');
const PageInsightsEngagementHeaderView = require('@training/apps/insights/engagement/PageInsightsEngagementHeaderView');

class PageInsightsEngagementController extends StackedLayoutViewController {
  initialize(options = {}) {
    ({
      model: this.model,
      pageId: this.pageId,
      teamId: this.teamId,
      reactionsErrorHandler: this.reactionsErrorHandler
    } = options);

    this.includeSubTeams = true;
  }

  get childControllers() {
    const configObjects = [
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('ax-grid__col--12'),
        {
          viewDefinition: {
            ViewClass: PageInsightsEngagementHeaderView,
            model: this.model,
            pageId: this.pageId,
            teamId: this.teamId,
            includeSubTeams: this.includeSubTeams
          },
          delegateEvents: {
            'view:toggle:includeSubTeams': () => {
              this.includeSubTeams = !this.includeSubTeams;
              this.findControllerById('reactions-tile-controller').getView().includeSubTeams = this.includeSubTeams;
            }
          }
        }),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('ax-grid__col--l-6 page-insights-engagement__wrapper'),
        {
          ViewControllerClass: PageInsightsEngagementTileController,
          model: this.model
        }),
      StackedLayoutViewController.createConfigObject(
        ViewOptionHelpers.classNameAppender('page-insights-reactions__wrapper ax-grid__col--12 ax-grid__col--l-6 card card--interactive hidden'),
        {
          ViewControllerClass: PageInsightsReactionsTileController,
          id: 'reactions-tile-controller',
          collection: this.model.get('reactionsCollection'),
          model: this.model,
          pageId: this.pageId,
          teamId: this.teamId,
          includeSubTeams: this.includeSubTeams,
          reactionsErrorHandler: this.reactionsErrorHandler
        }
      )
    ];

    return configObjects;
  }
}

module.exports = PageInsightsEngagementController;
