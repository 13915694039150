const { ItemView } = require('Marionette');

class BasicHeaderTitle extends ItemView {

  className() {
    return 'basic-header-title qa-header';
  }

  getTemplate() {
    return `
      <<%= titleTag %> class="<%= titleClass %>"><%= title %></<%= titleTag %>>
    `;
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      titleTag: this.getOption('titleTag') || 'p',
      titleClass: this._getTitleClass()
    };
  }

  _getTitleClass() {
    let titleClass = 'qa-header-title';
    if (this.getOption('titleClass')) {
      titleClass += ` ${ this.getOption('titleClass') }`;
    }
    return titleClass;
  }
}

module.exports = BasicHeaderTitle;
