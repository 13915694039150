const { ItemView } = require('Marionette');
require('jquery.dotdotdot'); // Make sure the project does have dot dot dot available

class TrainingCardHeaderInfoView extends ItemView {
  getTemplate() {
    return `
      <p class="training-card__header-info-subject <%- subjectNameCssClass %>"><%- subjectName %></p>
      <<%- titleTag %> role="heading" aria-level="3" class="training-card__header-info-title"><%- title %></<%- titleTag %>>
    `;
  }

  className() {
    return 'training-card__header-info';
  }

  ui() {
    return {
      cardTitle: '.training-card__header-info-title'
    };
  }

  onAttach() {
    const lineHeight = parseFloat(this.ui.cardTitle.css('lineHeight'), 10);
    this.ui.cardTitle.dotdotdot({
      height: lineHeight * 2,
      watch: true
    });
  }

  templateHelpers() {
    const title = this.getOption('title');
    const titleTag = this.getOption('titleTag') || 'p';
    const subjectName = this.getOption('subjectName');

    const subjectNameCssClass = subjectName ? '' : 'hidden';

    return {
      title,
      titleTag,
      subjectName,
      subjectNameCssClass
    };
  }
}

module.exports = TrainingCardHeaderInfoView;
