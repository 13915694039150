// Module
var code = `<div class="table-row-helper explanation-row">
  <div class="table-cell-helper"></div>
  <div class="table-cell-helper touchable <%- reasonImgContainerClass %>">
    <% if (option.reasonImg) { %>
      <button class="image-wrapper imgwrap zoom-image-wrap reason-image"></button>
    <% } %>
    </div>
  <div class="table-cell-helper explanationtext">
    <% if (option.reasonText && option.reasonText.length > 0) { %>
      <p aria-hidden="true" role="presentation" class="optionReasonText" id="reason-<%= option.id %>"><%= option.reasonText %></p>
    <% } %>
  </div>
</div>
`;
// Exports
module.exports = code;