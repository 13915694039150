const I18n = require('@common/libs/I18n');
const GuidedLearningLayoutView = require('@common/components/guidedLearning/GuidedLearningLayoutView');

class SelfDirectedPathLayoutView extends GuidedLearningLayoutView {
  _getTitle() {
    return I18n.t('SelfDirectedPaths.title');
  }

  _getIcon() {
    return 'icon-path';
  }
}

module.exports = SelfDirectedPathLayoutView;
