const { assertIsArray } = require('@common/libs/helpers/types/ObjectHelpers');

const TranscriptionMethods = require('@common/libs/file/TranscriptionMethods').default;
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');


class TranscriptionMethodSupplierTenantStrategy {
  constructor(requiredServiceType, supportedMechanicalTranscriptionLanguages, supportedMechanicalTranslationLanguages) {
    this.requiredServiceType = TranscriptionMethods.assertLegalValue(requiredServiceType);
    this.supportedMechanicalTranscriptionLanguages = assertIsArray(supportedMechanicalTranscriptionLanguages);
    this.supportedMechanicalTranslationLanguages = assertIsArray(supportedMechanicalTranslationLanguages);
  }

  /**
   * This uses the default properties off the tenant, though you could have another constructor if you wanted
   * that used anything else instead.
   */
  static fromTenant() {
    const serviceType = TenantPropertyProvider.get().getProperty('automaticVideoClosedCaptionGeneratedType');
    const mechanicalTranscriptionSupportedLanguages = TenantPropertyProvider.get().getProperty('videoClosedCaptionsMechanicalTranscriptionSupportedLanguages');
    const mechanicalTranslationSupportedLanguages = TenantPropertyProvider.get().getProperty('videoClosedCaptionsMechanicalTranslationSupportedLanguages');

    return new TranscriptionMethodSupplierTenantStrategy(
      serviceType,
      mechanicalTranscriptionSupportedLanguages,
      mechanicalTranslationSupportedLanguages
    );
  }

  getBestMethodForSourceLanguage(sourceLanguage) {
    if (this.requiredServiceType === TranscriptionMethods.NONE) {
      return null;
    }

    const mechanicalTranslationSupported = this._isMechanicalTranslationSupportedForLanguage(sourceLanguage);
    const mechanicalTranscriptionSupported = this._isMechanicalTranscriptionSupportedForLanguage(sourceLanguage);

    if (mechanicalTranslationSupported && this.requiredServiceType === TranscriptionMethods.PROFESSIONAL) {
      return TranscriptionMethods.PROFESSIONAL;
    } else if (mechanicalTranscriptionSupported && this.requiredServiceType === TranscriptionMethods.MECHANICAL) {
      return TranscriptionMethods.MECHANICAL;
    }

    return null;
  }

  // implementation details below
  _isMechanicalTranslationSupportedForLanguage(language) {
    return this.supportedMechanicalTranslationLanguages.some((langCode) => {
      return langCode === language;
    });
  }

  _isMechanicalTranscriptionSupportedForLanguage(language) {
    return this.supportedMechanicalTranscriptionLanguages.some((langCode) => {
      return langCode === language;
    });
  }
}

module.exports = TranscriptionMethodSupplierTenantStrategy;
