const LocaleBundle = require('./LocaleBundle');

const $ = require('jquery');

class TenantLocaleBundle extends LocaleBundle {
  load(localeCode) {
    return $.ajax({
      type: 'GET',
      url: `/axonify/lang/${ localeCode }/str.json`
    }).then((json) => {
      return json;
    });
  }
}

module.exports = TenantLocaleBundle;
