const _ = require('underscore');
const {
  classListContainerDefault,
  classListDefault,
  dataListDefault,
  getPropsSelect
} = require('./AxFormPropertyHelpers');

const selectTemplate = (containerClass, props) => {
  const classDefault = containerClass.replace('.', '');
  let optionProperties = '';

  if (props.options) {
    for (let i = 0; i < props.options.length; i++) {
      optionProperties += `<option ${ props.options[i].value ? 'value="' + props.options[i].value + '"' : '' }> ${ props.options[i].label }</option>`;
    }
  }

  return _.tpl(`
    <div class="${ classListContainerDefault(classDefault, props) }">
      ${ props.label ? `
        <label
          for="${ props.id }"
          class="${ props.classNameLabel ? props.classNameLabel + ' ' : '' } ${ props.required ? 'label--required' : '' }"
        >
          ${ props.label }
        </label>` : '' }
      <div class="${ classDefault }__container">
        <select
          id="${ props.id }"
          ${ props.ariaLabel ? 'aria-label="' + props.ariaLabel + '"' : '' }
          ${ props.disabled ? 'disabled' : '' }
          ${ props.required ? 'required' : '' }
          ${ props.name ? 'name="' + props.name + '"' : '' }
          ${ props.noFormEditorDefault ? 'no-default' : '' }
          ${ classListDefault(props.qaClassNameSelect, props.classNameSelect) }
          ${ dataListDefault(props) }
        >
          ${ props.placeholder ? '<option class="placeholder" disabled selected>' + props.placeholder + '</option>' : '' }
          ${ optionProperties }
        </select>
      </div>
    </div>
  `)(props);
};

const selectContainer = '.ax-select';

const AxFormSelectHelpers = {
  axFormSelectors: {
    selectContainer: selectContainer,
    select: selectContainer + ' select'
  },

  axSelect(customProps) {
    return selectTemplate(selectContainer, _.defaults(customProps, getPropsSelect()));
  }
};

module.exports = AxFormSelectHelpers;
