const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const $os = require('detectOS');

const PageableTableController = require('@common/components/pageableList/PageableTableController');
const CommunityInsightsTableLayoutView = require('@training/apps/training/communitiesInsights/components/tables/CommunityInsightsTableLayoutView');
const InitialPagesTableItemView = require('@training/apps/training/communitiesInsights/components/tables/InitialPagesTableItemView');

class InitialInsightsLayoutController extends LayoutController {
  initialize(options = {}) {
    this.collection = options.collection;
    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: require('@training/apps/training/communitiesInsights/components/InsightsLayoutTemplate.html'),
      templateHelpers: {
        isMobile: $os.mobile
      },
      regions: {
        table: '.js-insights__table'
      },
      events: {
        'click .js-insights__export': () => {
          this.trigger('request:export');
        }
      }
    };
  }

  regionControllers() {
    return {
      table: this._getTableDefinition()
    };
  }

  _getTableDefinition() {
    return {
      ViewControllerClass: PageableTableController,
      itemViewClass: CommunityInsightsTableLayoutView,
      tableViewClass: CommunityInsightsTableLayoutView,
      collection: this.collection,
      rowItemView: InitialPagesTableItemView,
      headerNames: [
        I18n.t('general.published'),
        I18n.t('general.page'),
        I18n.t('general.type'),
        {
          type: 'super',
          title: I18n.t('discover.insights.firstHours'),
          columns: [
            I18n.t('discover.insights.reach'),
            I18n.t('discover.insights.engagmentRate')
          ],
          className: ''
        },
        {
          type: 'super',
          title: I18n.t('discover.insights.firstDays'),
          columns: [
            I18n.t('discover.insights.reach'),
            I18n.t('discover.insights.engagmentRate')
          ],
          className: ''
        },
        {
          // Add empty header above the external link icon column
          type: 'super',
          title: I18n.t('discover.insights.pageInsights'),
          columns: [''],
          className: 'off-screen'
        }
      ]
    };
  }
}

module.exports = InitialInsightsLayoutController;
