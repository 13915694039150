import {
  Slider as MuiSlider,
  styled,
  type SliderTypeMap as MuiSliderTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxSliderCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxSliderTypeMap<D extends React.ElementType = 'span', P = object> = {
  props: P & Omit<MuiSliderTypeMap['props'], 'color'> & AxSliderCustomProps;
  defaultComponent: D;
};

export type AxSliderProps<
  D extends ElementType = AxSliderTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxSliderTypeMap<D, P & AxSliderCustomProps>, D>;

const StyledAxSlider = styled(MuiSlider, { name: 'AxSlider' })(() => {
  // TODO - update to use common variables
  return {};
});

/**
 * Wrapper for the MUI `Slider` component.
 *
 * ℹ️ Note: Styling by MUI classes is not supported. Check the `Customization` story for an example of how to customize the `AxSlider` component.
 *
 * ### Links
 * - [Slider | MUI](https://mui.com/material-ui/react-slider/)
 */

export const AxSlider: MuiOverridableComponent<AxSliderTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxSliderProps, ref: Ref<HTMLSpanElement>) => {
  const buttonProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxSlider
      { ...buttonProps }
      ref={ ref }
      color='primary'>
      { children }
    </StyledAxSlider>
  );
});

export default AxSlider;
