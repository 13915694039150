const logging = require('logging');

const _ = require('underscore');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');

const TOTAL_TIMEOUT = 60000;
const TIMEOUT = 250;

const startPoll = function(entity, options = {}) {
  // We're going to create a closure with this object, so the timeouts can be
  // tracked for retries and cancelled if the totalTimeout is met
  const pollState = {
    entity,
    successCallback: options.successCallback,
    errorCallback: options.errorCallback,
    pollPredicate: options.pollPredicate ?? _.constant(false),
    timeout: options.timeout || TIMEOUT,
    totalTimeout: options.totalTimeout || TOTAL_TIMEOUT,
    showSpinner: options.showSpinner,
    currentTimeoutId: null,
    totalTimeoutId: null
  };

  Object.assign(pollState, {
    successCallback: wrapSuccessCallback(pollState),
    errorCallback: wrapErrorCallback(pollState),
    totalTimeoutId: setTotalTimeout(pollState)
  });

  addPollState(pollState);
  pollEntity(pollState);
};

const stopPoll = function(entity) {
  const pollState = getPollStateForEntity(entity);

  if (pollState != null) {
    abortPoll(pollState);
    cleanupPollState(pollState);
  }
};

/* Private functions */

let pollStates = [];

const addPollState = (pollState) => {
  pollStates.push(pollState);
};

const removePollState = (pollState) => {
  pollStates = _.without(pollStates, pollState);
};

const getPollStateForEntity = (entity) => {
  return _.find(pollStates, (pollState) => {
    return pollState.entity === entity;
  });
};

const wrapSuccessCallback = function(pollState = {}) {
  const {
    pollPredicate,
    successCallback
  } = pollState;

  return function(...args) {
    if (pollPredicate(...args)) {
      poll(pollEntity, pollState);
    } else {
      if (_.isFunction(successCallback)) {
        successCallback(...args);
      }

      cleanupPollState(pollState);
    }
  };
};

const poll = function(ping, pollState) {
  clearTimeouts(_.pick(pollState, 'currentTimeoutId'));

  const pinger = _.partial(ping, pollState);
  pollState.currentTimeoutId = setTimeout(pinger, pollState.timeout);
};

const pollEntity = function(pollState = {}) {
  const {
    entity,
    successCallback,
    errorCallback,
    showSpinner
  } = pollState;

  entity.fetch({
    success: successCallback,
    error: errorCallback,
    showSpinner
  });
};

const clearTimeouts = function(pollState = {}) {
  clearTimeout(pollState.totalTimeoutId);
  clearTimeout(pollState.currentTimeoutId);
};

const wrapErrorCallback = function(pollState = {}) {
  const { errorCallback } = pollState;

  return function(...args) {
    if (_.isFunction(errorCallback)) {
      errorCallback(...args);
    }
    cleanupPollState(pollState);
  };
};

const cleanupPollState = function(pollState) {
  clearTimeouts(pollState);
  removePollState(pollState);
};

const setTotalTimeout = function(pollState) {
  const handleTotalTimeWaiter = _.partial(handleTotalTimeWaiting, pollState);
  return setTimeout(handleTotalTimeWaiter, pollState.totalTimeout);
};

const handleTotalTimeWaiting = function(pollState) {
  logging.error(`poll took ${ pollState.totalTimeout }!`);

  abortPoll(pollState, {
    status: 0,
    statusText: 'timeout',
    errMessage: I18n.t('errors.timeout')
  });
  cleanupPollState(pollState);
};

const abortPoll = function(pollState, errorResponseObj = {}) {
  const {
    entity,
    errorCallback
  } = pollState;

  entity.abortXHR(Sync.Method.READ);

  const options = entity.getXHRAjaxOptions(Sync.Method.READ);
  const response = Object.assign({
    status: 0,
    statusText: 'abort'
  }, errorResponseObj);

  errorCallback?.(entity, {
    responseJSON: response,
    ...response
  }, options);
};

module.exports = {
  startPoll,
  stopPoll
};
