const Backbone = require('Backbone');
const _ = require('underscore');
const Cocktail = require('backbone.cocktail');
const SelectableListMixin = require('@common/components/selectable_list/SelectableListMixin');

const Team = require('@common/components/teamHierarchySelector/Team');

class TeamsList extends Backbone.Collection {
  apiEndpoint() {
    return '/locations';
  }

  get model() {
    return Team;
  }

  get _classType() {
    return 'TeamsList';
  }

  fetch(options = {}) {
    const newOptions = $.extend(
      true,
      {
        data: {
          ancestorBusinessUnit: true,
          cached: true
        }
      },
      options
    );
    return super.fetch(newOptions);
  }

  comparator(team) {
    return team.get('name').toLowerCase();
  }

  parse(res) {
    return res && res.entities ? res.entities : res;
  }

  doRefresh() {
    this.fetch({reset: true});
  }

  set(argModels, options) {
    const models = super.set(argModels, options);
    for (const model of _.compact([].concat(models))) {
      this._indexChildrenAdditions(model.get('children'));
    }
    return models;
  }

  _indexChildrenAdditions(childrenCollection = []) {
    _.each(childrenCollection, (child) => {
      const childModel = this._prepareModel(child);
      this._addReference(childModel);
      this._indexChildrenAdditions(childModel.get('children'));
    });
  }

  remove(argModels, options) {
    const models = super.remove(argModels, options);
    for (const model of [].concat(models)) {
      this._indexChildrenRemovals(model.get('children'));
    }
    return models;
  }

  _indexChildrenRemovals(childrenCollection = []) {
    _.each(childrenCollection, (child) => {
      const childModel = this._prepareModel(child);
      this._indexChildrenRemovals(childModel.get('children'));
      this._removeReference(childModel);
    });
  }
}

Cocktail.mixin(TeamsList, SelectableListMixin);

module.exports = TeamsList;
