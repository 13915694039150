const { LayoutView } = require('Marionette');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const UIKit = require('@training/widgets/UIKit');
const InsightsHowToModalView = require('@training/apps/insights/InsightsHowToModalView');
const I18n = require('@common/libs/I18n');

class CommunityInsightsHowItWorksView extends LayoutView {
  tagName() {
    return 'button';
  }

  className() {
    return 'ax-button ax-button--m ax-button--icon-left how-to-link ax-grid__col--auto-size';
  }

  getTemplate() {
    return `<span class="icon-question_sign icon--reset-styles"></span>
    <span class="text"><%- t('referral.howTo.link') %></span>`;
  }

  attributes() {
    return {
      'aria-label': I18n.t('referral.howTo.link')
    };
  }

  events() {
    return {
      click: 'displayHowToModal'
    };
  }

  displayHowToModal() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal page-insights-how-to confirm-dialog-view'
    });

    const modalChildView = new InsightsHowToModalView({
      extendedContent: [
        {
          title: 'discover.insights.engagements',
          description: 'discover.insights.howItWorks.engagements'
        },
        {
          title: 'discover.insights.engagmentRate',
          description: 'discover.insights.howItWorks.engagmentRate'
        }
      ]
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }
}

module.exports = CommunityInsightsHowItWorksView;
