const Backbone = require('Backbone');
const Marionette = require('Marionette');
const _ = require('underscore');
const logging = require('logging');
const Language = require('@common/data/models/Language');
const env = require('env');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');

const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');

const UserAgreementViewState = require('@common/modules/auth/views/agreement/UserAgreementViewState');
const UserAgreementService = require('@common/modules/auth/views/agreement/UserAgreementService');

class UserAgreeementAcceptancePage extends Marionette.ItemView {
  getTemplate() {
    return require('./UserAgreementAcceptancePage.html');
  }

  events() {
    return {
      'click #agree-button:not(.disabled)': 'onClickAgreeButton',
      'change input[type="checkbox"]': 'onChangeAgreeState'
    };
  }

  ui() {
    return {
      agreeButton: '#agree-button',
      agreeCheckbox: 'input'
    };
  }

  className() {
    return 'parent-height';
  }

  templateHelpers() {
    const localeCode = I18n.getLocale();

    return {
      hasAgreed: this.model.hasAgreed(),
      agreeementHtml: this.agreementModel.getDescription().getValueForLanguage(localeCode)
    };
  }

  initialize(options = {}) {
    ({
      tenant: this.tenant,
      user: this.user
    } = options);
    this.supportedLanguages = _.intersection(this.tenant.get('languages'), env.settings.supportedLocales);

    this.defaultViewLanguage = this._getDefaultViewLanguage();
    this.model = new UserAgreementViewState();
    this.pendingClick = false;

    this.flashService = options.flashService;
    if (!this.flashService) {
      throw new Error(
        'You must provide a valid flash service in order to construct this view.'
      );
    }

    this.agreementService = options.agreementService;
    if (!this.agreementService) {
      throw new Error(
        'You must provide a valid agreement service in order to construct this view.'
      );
    }

    this.agreementModel = options.agreementModel;
    if (!this.agreementModel) {
      throw new Error(
        'You must provide a valid agreement in order to construct this view.'
      );
    }

    this.successCallback = options.success || _.noop;
    this.failureCallback = options.failure || _.noop;

    this.listenTo(this.model, 'change', this.onChangeUserHasAgreedState);
  }

  _getDefaultViewLanguage() {
    const userLanguage = this.user.get('language');
    if (userLanguage != null && userLanguage !== 'XX') {
      return userLanguage;
    }

    return this._getLanguageFromLocalStorage() || this.tenant.get('defaultLanguage');
  }

  _getLanguageFromLocalStorage() {
    const storageObj = LocalStorageHelpers.getStateStorageObject();
    return storageObj.userSelectedLanguage;
  }

  _setViewLanguage(lang) {
    I18n.setLocale(lang, {
      always: () => {
        if (this.isDestroyed) {
          return;
        }
        this.render();
      }
    });
  }

  onRender() {
    // we check whether it's in the supported languages because admin doesn't support all the languages.
    if (this.defaultViewLanguage !== I18n.getLocale() && this.supportedLanguages.includes(this.defaultViewLanguage)) {
      this._setViewLanguage(this.defaultViewLanguage);
      return;
    }

    if (this.form) {
      this.stopListening(this.form, 'change:language');
      this.form.destroy();
    }


    const languageList = new Backbone.Collection(this.supportedLanguages, { model: Language });

    this.form = new Form({
      el: this.$('.agreement-page'),
      model: new Backbone.Model({language: this.defaultViewLanguage}),
      context: {
        languages: {
          collection: languageList
        }
      }
    });

    this.listenTo(this.form, 'change:language', (form, editor) => {
      const lang = editor.getValue().id;
      this.defaultViewLanguage = lang;
      this._setViewLanguage(lang);
    });

    this.onChangeUserHasAgreedState();
  }

  onChangeUserHasAgreedState() {
    const userHasAgreed = this.model.hasAgreed();
    this.ui.agreeButton.attr('aria-disabled', !userHasAgreed);
    if (userHasAgreed) {
      this.ui.agreeButton.removeAttr('aria-labelledby');
    } else {
      this.ui.agreeButton.attr('aria-labelledby', 'agree-button agree-hint');
    }
    this.ui.agreeButton.toggleClass('disabled', !userHasAgreed);
  }

  onClickAgreeButton() {
    if (this.pendingClick) {
      return;
    }

    this.pendingClick = true;

    this.agreementService.acceptAgreement(this.agreementModel, this.model).always((result) => {
      this.pendingClick = false;

      switch (result) {
        case UserAgreementService.SUCCESS_AGREEMENT_SIGNED:
        case UserAgreementService.ERROR_AGREEMENT_ALREADY_SIGNED:
          logging.info('The user agreement was signed. Success.');
          this.successCallback();
          break;
        default:
          this.flashService.error({
            message: I18n.t('login.agreement.failure')
          });

          logging.error('The user agreement was failed to be signed. Signaling an error.');
          this.failureCallback();
          break;
      }
    });
  }

  onChangeAgreeState() {
    const hasUserClickedAgree = this.ui.agreeCheckbox.prop('checked');
    this.model.markAs(hasUserClickedAgree);
  }
}

module.exports = UserAgreeementAcceptancePage;
