import { extractFragmentChildren } from '@common/libs/helpers/app/ReactHelpers';
import {
  AxIcon,
  AxSpeedDialAction,
  useAxFabStyles
} from '@common/modules/react/themes/components';

import {
  SpeedDial as MuiSpeedDial,
  type SpeedDialProps as MuiSpeedDialProps,
  styled,
  useTheme
} from '@mui/material';

import {
  type ReactElement,
  type Ref,
  cloneElement,
  forwardRef,
  isValidElement
} from 'react';

export type AxSpeedDialProps = MuiSpeedDialProps & {
  size?: 'small' | 'medium' | 'large'
};

const styledOptions = {
  name: 'AxSpeedDial'
};

const StyledAxSpeedDial = styled(MuiSpeedDial, styledOptions)<AxSpeedDialProps>(() => {
  return {};
});

/**
 * A floating action button that expands to reveal additional actions.
 *
 * If the child is a AxSpeedDialAction, the size prop will be passed to it.
 *
 * @example
 * <AxSpeedDial size='small'>
 *  <AxSpeedDialAction icon='icon-plus' tooltipTitle='Add' />
 *  <AxSpeedDialAction icon='icon-edit' tooltipTitle='Edit' />
 * </AxSpeedDial>
 */
export const AxSpeedDial = forwardRef(({
  size = 'medium',
  children,
  ...otherProps
}: AxSpeedDialProps, ref: Ref<HTMLDivElement>) => {
  const theme = useTheme();
  
  const SpeedDialProps: AxSpeedDialProps = {
    ...otherProps
  };
  
  const {
    buttonStyles,
    iconStyles
  } = useAxFabStyles(size);

  return (
    <StyledAxSpeedDial
      { ...SpeedDialProps }
      ref={ ref }
      icon={
        <AxIcon
          iconName='icon-plus'
          sx={{
            color: theme.uiKit.colorGrey80,
            fontSize: iconStyles.fontSize
          }}
        /> }
      FabProps={{sx: buttonStyles}}
    >
      {
        //if the child is a AxSpeedDialAction, add the size prop
        extractFragmentChildren(children).map((child, index) => {
          // check if the child is a AxSpeedDialAction
          if (isValidElement(child) && child.type === AxSpeedDialAction) {
            return cloneElement(child as ReactElement, {
              size: size,
              key: index
            });
          }
          return child;
        })
      }
    </StyledAxSpeedDial>
  );
});

export default AxSpeedDial;

