const _ = require('underscore');
const { LayoutView } = require('Marionette');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const PageStatus = require('@common/components/discover/enums/PageStatus');

const {
  getNeedsReviewCardBannerView
} = require('@common/components/discover/article/ArticleNeedsReviewCardBannerHelper');

const DEFAULT_BANNER_MARGIN = 8;

class ArticleSearchResultTileView extends LayoutView {

  constructor(options = {}) {
    super(options);

    if (options.extraOptions != null) {
      this.searchPageState = options.extraOptions.searchPageState;
      this.userId = options.extraOptions.userId;

      this.listenTo(this.searchPageState, 'change:isGrid', this._onLayoutTypeChange);
    }
  }

  getTemplate() {
    return require('@training/apps/search/ArticleSearchResultTileView.html');
  }

  regions() {
    return {
      gridBanner: '.grid-banner-region',
      listBanner: '.list-banner-region'
    };
  }

  ui() {
    return {
      articleImageContainer: '.base-card__image'
    };
  }

  onAttach() {
    this._getMedia();
    this._toggleBannerRegions();
  }

  onResize() {
    if (this.searchPageState != null) {
      _.defer(() => {
        this._adjustBanners(this.searchPageState.get('isGrid'));
      });
    }
  }

  initialize(options = {}) {
    this.model = options.data.model;
  }

  _isShowingStatusBanner() {
    // in order to show the banner, we should be dealing with a model that has these methods. Won't it be
    // great when we have TypeScript types for this stuff? yeah it will
    if (!_.isFunction(this.model.getPageReportType) || !_.isFunction(this.model.getStatus)) {
      return false;
    }

    const pageStatus = this.model.getStatus().toUpperCase();
    if (pageStatus === PageStatus.EXPIRED) {
      return true;
    }
    if (pageStatus === PageStatus.REVIEW) {
      return true;
    }
    if (pageStatus === PageStatus.QUARANTINE) {
      return true;
    }
    if (pageStatus === PageStatus.SCHEDULED) {
      return true;
    }
    return false;
  }

  _isRequiringApproval() {
    return this.model.getStatus() === PageStatus.REVIEW
      && !this.model.isFact();
  }

  _getMedia() {
    const media = this._getFirstImage();

    if (!_.isEmpty(media)) {
      this.articleImage = ImageViewerFactory.createViewerInstance({
        media,
        $el: this.ui.articleImageContainer
      });

      if (this.articleImage != null) {
        this.listenTo(this.articleImage, 'image:loaded', () => {
          this.trigger('image:loaded');
        });

        this.articleImage.render();
      }
    } else {
      this.$el.toggleClass('no-image', true);
    }
  }

  _getFirstImage() {
    // find the first media--image element and get its media-id
    const previewContent = this.model.get('previewContent');
    const $newContent = $('<tmp></tmp>').append(previewContent);
    const imageMediaId = $newContent.find('.page__media.media--image').first()
      .data('mediaId');

    //return the media element or null if it does not exist
    const media = this.model.get('media');
    const imageMedia = _.find(media, (mediaObj) => {
      return mediaObj.id === imageMediaId;
    });

    return imageMedia;
  }

  _adjustBanners(isGrid) {
    if (!this._isShowingStatusBanner()) {
      return;
    }

    if (!_.isEmpty(this._getFirstImage())) {
      // Banner needs to overlap the image if one exists and nothing more needs to be done
      const position = isGrid ? 'absolute' : 'static';
      this.$('.notify-container').parent()
        .css('position', position);
    } else {
      let margin = DEFAULT_BANNER_MARGIN;

      if (isGrid) {
        // Calculate a top margin for the absolutely positioned article-icon to account for banners
        this.$('.notify-container').each((index, bannerEl) => {
          margin += parseInt($(bannerEl).outerHeight(true), 10);
        });
      }

      this.$('.article-type-icon').css('margin-top', margin);
    }
  }

  _isBundled() {
    return this._getAttributeAsNumber('reportedCount') + this._getAttributeAsNumber('needsReviewCount') > 1;
  }

  _getAttributeAsNumber(attrName) {
    const attrValue = this.model.get(attrName);
    return _.isNumber(attrValue) ? attrValue : 0;
  }

  _toggleBannerRegions() {
    if (this._isShowingStatusBanner()) {
      const isGrid = this.searchPageState.get('isGrid');
      const regionToShow = isGrid ? this.gridBanner : this.listBanner;
      const regionToHide = isGrid ? this.listBanner : this.gridBanner;
      regionToHide.empty();

      const needsReviewCardBannerView = getNeedsReviewCardBannerView(this.model);
      // this condition is needed because that method could return null if there are no banners to show
      if (needsReviewCardBannerView) {
        regionToShow.show(getNeedsReviewCardBannerView(this.model));
      }

      _.defer(() => {
        this._adjustBanners(isGrid);
      });
    }
  }

  _onLayoutTypeChange() {
    this._toggleBannerRegions();
  }
}

module.exports = ArticleSearchResultTileView;
