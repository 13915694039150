const Backbone = require('Backbone');

class RewardsSummarySampleCollection extends Backbone.Collection {

  apiEndpoint() {
    return '/rewards/summary';
  }

}

module.exports = RewardsSummarySampleCollection;

