const Marionette = require('Marionette');
const _ = require('underscore');
const ObjectHelpers = require('@common/libs/helpers/types/ObjectHelpers');

class DetailItem extends Marionette.ItemView {
  initialize(options = {}) {
    ({
      label: this.label,
      key: this.key,
      value: this.value,
      data: this.data
    } = options);
  }

  className() {
    return 'detail-item';
  }

  getTemplate() {
    return `\
<span class="label"><%- getFormattedLabel() %></span>
<span class="underline value"><%= getFormattedValue() %></span>\
`;
  }

  templateHelpers() {
    return {
      getKey: this.getKey.bind(this),
      getFormattedLabel: this.getFormattedLabel.bind(this),
      getFormattedValue: this.getFormattedValue.bind(this)
    };
  }

  serializeData() {
    return $.extend(true, {}, super.serializeData(...arguments), this.data);
  }

  getKey() {
    return this.key != null ? this.key : '';
  }

  getValue() {
    const key = this.getKey();
    const data = this.serializeData();

    if (key.length === 0) {
      return data;
    }

    return ObjectHelpers.getDescendantProp(this.serializeData(), this.getKey());
  }

  getFormattedLabel() {
    return this.label;
  }

  getFormattedValue() {
    const value = this.getValue();

    if ((value == null) || _.isObject(value)) {
      return this.value != null ? this.value : '';
    }
    return value;

  }
}

module.exports = DetailItem;
