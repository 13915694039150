const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TagGroupFiltersTabView = require('@training/apps/search/communitiesManagement/tagGroupFilters/TagGroupFiltersTabView');
const TagGroupFiltersIndexController = require('@training/apps/search/communitiesManagement/tagGroupFilters/index/TagGroupFiltersIndexController');
const TagGroupFiltersEditContentController = require('@training/apps/search/communitiesManagement/tagGroupFilters/content/TagGroupFiltersEditContentController');
const TagGroupFilterList = require('@training/apps/search/communitiesManagement/collections/TagGroupFilterList');
const TagGroupFilterModel = require('@training/apps/search/communitiesManagement/models/TagGroupFilterModel');
const TagGroupFiltersEmptyContentController = require('@training/apps/search/communitiesManagement/tagGroupFilters/content/TagGroupFiltersEmptyContentController');
const { getScrollParent } = require('@common/libs/helpers/app/BrowserHelpers');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const FILTERS_URL = `${ SearchUrlHelper.BASE_SEARCH_HASH }/${ SearchCategoryEnum.COMMUNITIES_MANAGEMENT }/filters/`;

class TagGroupFiltersTabController extends LayoutController {
  initialize({filterId}) {
    this.initFilterId = filterId;
    this._tagGroupFilterList = new TagGroupFilterList();
    this._onTagGroupFilterCreateFn = this._onTagGroupFilterCreateFn.bind(this);
    this._onTagGroupFilterItemClickedFn = this._onTagGroupFilterItemClickedFn.bind(this);
    this._setEmptyContentView = this._setEmptyContentView.bind(this);
    this._clearSelectedListItem = this._clearSelectedListItem.bind(this);
    this._resetView = this._resetView.bind(this);

    this.listenToOnce(this._tagGroupFilterList, 'sync', () => {
      if (this.initFilterId) {
        this._onTagGroupFilterItemClickedFn(this.initFilterId);
        this._tagGroupFilterList.triggerSelectedModel(this.initFilterId);
      }
    });
  }

  viewDefinition() {
    return TagGroupFiltersTabView;
  }

  delegateEvents() {
    return {
      'view:inflate': () => {
        this._tagGroupFilterList.fetch();
      }
    };
  }

  regionControllers() {
    return {
      index: {
        ViewControllerClass: TagGroupFiltersIndexController,
        tagGroupFilterList: this._tagGroupFilterList,
        onTagGroupFilterItemClickedFn: this._onTagGroupFilterItemClickedFn,
        onTagGroupFilterCreateFn: this._onTagGroupFilterCreateFn
      },
      content: TagGroupFiltersEmptyContentController
    };
  }

  _onTagGroupFilterItemClickedFn(tagGroupListItemModelId) {
    const tagGroupFilterModel = new TagGroupFilterModel({
      id: tagGroupListItemModelId
    });

    tagGroupFilterModel.fetch({
      success: () => {
        this.listenToOnce(tagGroupFilterModel, 'change:names', () => {
          this._tagGroupFilterList
            .get(tagGroupFilterModel.get('id'))
            .setName(tagGroupFilterModel.getName());
        });

        this.listenToOnce(tagGroupFilterModel, 'destroy', () => {
          this._resetView();
          this._tagGroupFilterList.remove(tagGroupFilterModel.get('id'));
        });

        this._setEditContentRegion(tagGroupFilterModel);
        $(getScrollParent(this.getView().el)).scrollTop(0);

        Backbone.history.navigate(`${ FILTERS_URL }${ tagGroupFilterModel.get('id') }`, {
          trigger: false,
          replace: true
        });
      },
      error: (model, response) => {
        if (response.status === 404) {
          response.skipGlobalHandler = true;
          this._resetView();
          window.app.layout.flash.error(I18n.t('communitiesManagement.filters.errorFilterNotFound'));
        }
      }
    });
  }

  _onTagGroupFilterCreateFn() {
    const newModel = new TagGroupFilterModel();

    this._setEditContentRegion(newModel);
    this._clearSelectedListItem();

    this.listenToOnce(newModel, 'sync', () => {
      this._resetView();
    });
  }

  _setEmptyContentView() {
    this.swapRegionController('content', {
      ViewControllerClass: TagGroupFiltersEmptyContentController
    });
  }

  _clearSelectedListItem() {
    this._tagGroupFilterList.trigger('clear:selected');
  }

  _setEditContentRegion(tagGroupFilterModel) {
    this.swapRegionController('content', {
      ViewControllerClass: TagGroupFiltersEditContentController,
      model: tagGroupFilterModel,
      onDoneFn: this._resetView
    });
  }

  _resetView() {
    this._setEmptyContentView();
    this._clearSelectedListItem();
    this._tagGroupFilterList.fetch();

    Backbone.history.navigate(`${ FILTERS_URL }`, {
      trigger: false,
      replace: true
    });
  }
}


module.exports = TagGroupFiltersTabController;
