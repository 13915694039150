const $os = require('detectOS');

const ModalSettings = {
  overlaySpeed: 0,
  lightboxSpeed: !$os.mobile ? 200 : 0,
  centered: false,
  container: '#page-view',
  zIndex: 800, // use the index trick to keep the header visible since it avoids scrollbar flicker
  modalCSS: {
    position: 'absolute',
    top: !$os.mobile ? 25 : 0
  },
  overlayCSS: {
    background: 'black',
    opacity: .8
  },
  closeSelector: '.close',
  destroyOnClose: true
};

module.exports = ModalSettings;
