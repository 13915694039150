const { CommunityItemView } = require('@common/components/filterableCommunity/CommunityItemView');

class MultiselectCommunityItemView extends CommunityItemView {
  labelTemplate(name) {
    return `
      <div class="js-community-icon community-icon community-icon__multiselect ax-grid__col--auto-size" aria-hidden="true"></div>
      <div class="community-name__multiselect ax-grid__col--grow-2">${ name }</div>
    `;
  }

  getTemplate() {
    return `
      <%= axCheckbox({
        className: 'pull-left community-checkbox',
        classNameInput: 'left-list-checkbox',
        id: id,
        label: labelTemplate(name),
        tabindex: -1 }) %>
    `;
  }

  templateHelpers() {
    return {
      id: this.model.get('id'),
      name: this.model.getName(),
      labelTemplate: this.labelTemplate
    };
  }

  events() {
    return {
      'mousedown .community-checkbox': this._setCommunity
    };
  }

  // trigger _onCommunityClicked in the parent
  _setCommunity() {
    this.trigger('community:clicked', this.model);
  }

  setSelected(selectValue) {
    $(`#${ this.model.get('id') }`).prop('checked', selectValue);
  }

  onRender() {
    this.getCommunityIcon();
  }
}

module.exports = MultiselectCommunityItemView;
