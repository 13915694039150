const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const AbstractAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/AbstractAssessmentInitiatorController');
const NullStartableAssessmentProvider = require('@training/apps/training/controllers/assessments/providers/NullStartableAssessmentProvider');

/**
 * This class is used only when you cannot figure out who the initiator of an assessment came from.
 * It removes all decisions, thus making it unambigious since it behaves with a standard flow.
 */
class NullAssessmentInitiatorController extends AbstractAssessmentInitiatorController {

  setConfigurationProvider() {
    /* nomnomnom */
  }

  getNextItemProvider() {
    return new NullStartableAssessmentProvider();
  }

  getDiscriminator() {
    return 'NullAssessmentInitiatorProvider';
  }

  createInitiatorContext() {
    return {};
  }

  processSequenceFlow() {
    return this.hasSomethingToDoAsync().then((somethingToDo) => {
      if (somethingToDo) {
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);

    });
  }

  createAssessment() {
    return Promise.reject(new Error('OperationNotSupported'));
  }

  processAssessment(assessment) {
    const assessmentController = this.getAssessmentProcessor({ assessment });
    return assessmentController.processSequenceFlow();
  }

  getAssessmentProcessor(options, context) {
    return super.getAssessmentProcessor(Object.assign({}, {
      nextItemProvider: this.getNextItemProvider(context)
    }, options));
  }

  getQuestionsController() {
    const assessmentController = this.getAssessmentProcessor();
    return assessmentController.getQuestionsController();
  }
}

module.exports = NullAssessmentInitiatorController;
