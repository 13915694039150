// Module
var code = `<div id ="self-registration-view">
  <h1 class="page-header">
    <div class="ax-container">
      <div class="page-header__content">
        <span class="page-header__icon icon-profile" aria-hidden="true"></span>
        <span class="page-header__title"><%- t('selfRegistration.title') %></span>
      </div>
    </div>
  </h1>

  <div class="ax-container">
    <div class="page-content">
      <form id="registerForm" class="highlighted-form ax-grid ax-grid--no-gutter" autocomplete="off">
        <div class="ax-grid__col--12 ax-grid__col--m-8 ax-grid__col--l-6">

        <a href="#" id="gotoLoginLink"><%- t('selfRegistration.alreadyPrompt') %></a>

        <div class="clear"></div>

        <div id="container-languages">
          <%= axSelect({
            autocomplete: 'off',
            classNameSelect: 'qa-language-select',
            data: { field: 'language', options: 'languages' },
            fullWidth: true,
            id: 'language',
            label: t('selfRegistration.language'),
            name: 'language',
            noFormEditorDefault: 'true'
          }) %>
        </div>

        <div class="clear"></div>

        <div class="large">
          <label for="full-name"><%- t('selfRegistration.fullName') %></label>
          <input data-field="fullname" id="full-name" name="full-name" type="text" autocomplete="off" placeholder="<%- t('selfRegistration.fullNamePlaceHolder') %>">
        </div>

        <div class="large">
          <label for="employee-id"><%- t('selfRegistration.employeeId') %></label>
          <input data-field="employeeId" id="employee-id" name="employee-id" type="text" autocomplete="off" aria-describedby="explain-username">
          <p class="ax-font ax-font__caption ax-font--no-margin ax-font--secondary field-description" id="explain-username"><%= t('selfRegistration.explainUsername') %></p>
        </div>
        <div class="clear"></div>

        <div id="container-locations">
          <label for="location"><%- t('selfRegistration.location') %></label>
            <div id="location" type="text" data-field="location" data-editor="Location" data-options="locations" data-full-path="true" data-resize="false" class="location-wrapper largefield dropselect filtering">
                <input id="location-input" class="largefield dropselect"  readonly />
                <div class="filteritemchooser" style="position:absolute;">
                    <div class="itemheader clearfix">
                        <h2><%- t('selfRegistration.locationModalTitle') %></h2>
                        <div class="x close" tabindex="0" ></div>
                    </div>
                    <ul class="moreitems">
                    </ul>
                </div>
            </div>
        </div>

        <div class="clear"></div>

        <div id="container-lineOfBusinesses">
          <%= axSelect({
            autocomplete: 'off',
            classNameSelect: 'qa-line-of-business-select',
            data: {
              field: 'lineOfBusiness',
              options: 'lineOfBusinesses'
            },
            fullWidth: true,
            id: 'lineOfBusiness',
            label: t('selfRegistration.lineOfBusiness'),
            name: 'lineOfBusiness'
          }) %>
        </div>

        <div id="container-jobTitles">
          <%= axSelect({
            classNameSelect: 'qa-job-title-select',
            data: {
              field: 'jobTitle',
              options: 'jobTitles'
            },
            fullWidth: true,
            id: 'jobTitle',
            label: t('selfRegistration.jobTitle'),
            name: 'jobTitle'
          }) %>
        </div>

        <div id="container-departments">
          <%= axSelect({
            autocomplete: 'off',
            classNameSelect: 'qa-department-select',
            data: {
              field: 'department',
              options: 'departments'
            },
            fullWidth: true,
            id: 'department',
            label: t('selfRegistration.department'),
            name: 'department'
          }) %>
        </div>

        <div class="clear"></div>

        <div>
          <label for="email"><%- t('selfRegistration.email') %></label>
          <input data-field="email" id="email" name="email" type="text" autocomplete="off">
        </div>

        <div class="large">
          <label for="newpassword"><%- t('selfRegistration.newPassword') %></label>
          <div data-editor="Password" data-options="passwordOptions" data-field="passwordPlain" id="newpassword" autocomplete="off" placeholder="<%- t('selfRegistration.enterPassword') %>"></div>
        </div>

        <div class="large">
          <label for="passwordagain"><%- t('selfRegistration.passwordAgain') %></label>
          <div data-editor="Password" data-options="passwordOptions" data-field="passwordRepeated" id="passwordagain" autocomplete="off"
            placeholder="<%- t('selfRegistration.confirmPassword') %>"></div>
            <div id="js-password-ruleset"></div>

        </div>

        <h2 id="security-question-title"><%- t('selfRegistration.updateSecurityQuestion') %></h2>
        <div id="recovery-container" role="group" aria-labelledby="security-question-title"></div>

        <div class="captcha-container">
          <div data-editor="Captcha" data-field="recaptcha" data-options="captchaOptions"></div>
        </div>
        </div>
      </form>
    </div>
  </div>


</div>
`;
// Exports
module.exports = code;