import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  type Ref
} from 'react';

import ViewControllerFactory from '@common/libs/UI/controllers/ViewControllerFactory';
import { type AxBoxProps } from '@common/modules/react/themes/components';
import { Box } from '@mui/material';
import { Region } from 'backbone.marionette';

const viewControllerFactory = new ViewControllerFactory();

interface ReactViewControllerWrapperProps extends AxBoxProps {
  controllerDefinition: object | (() => object)
  showOptions?: object
}

/**
 * Component to be used when you want to nest any BB/Marionette/ViewController inside a React component/layout.
 */
export default forwardRef((props: ReactViewControllerWrapperProps, ref: Ref<HTMLDivElement | null>) => {
  const {
    controllerDefinition,
    showOptions,
    ...boxProps
  } = props;
  
  const el = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => {
    return el.current;
  });

  useEffect(() => {
    const region = new Region({ el: el.current });

    const controller = viewControllerFactory.create(controllerDefinition);
    controller.show(region, showOptions);

    return () => {
      region.empty();
    };
  }, [controllerDefinition, showOptions]);

  // using dangerouslySetInnerHTML so that React ignores everything inside the Box when it does it's VDOM diffs
  return <Box
    ref={ el }
    dangerouslySetInnerHTML={{ __html: '' }}
    { ...boxProps } />;
});
