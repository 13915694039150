const { navigator } = window;

const $os = require('detectOS');
const env = require('env');

const XhrHeaders = require('XhrHeaders');

const XhrHelpers = {
  defaultHeaders: (headers = {}) => {
    // TEMPORARY to help with 500s in GCP/Akamai world
    if (window.location.hostname === ('synthetics-eu.axonify.com')) {
      headers[XhrHeaders.RETURN_ENCRYPTED] = 'all';
    }

    return Object.assign({
      'cache-control': 'no-cache', // Prevent iOS6 from caching AJAX POST requests
      [XhrHeaders.XSRF]: 'X', // Prevent CSRF attacks
      [XhrHeaders.CLIENT_VERSION]: `${ env.settings.version }`,
      [XhrHeaders.USER_AGENT]: `${ navigator.userAgent }`,
      [XhrHeaders.FROM_MOBILE_APP]: `${ $os.isInMobileApp() }`,
      [XhrHeaders.BROWSER_INSTANCE]: env.instanceId,
      [XhrHeaders.APP]: env.settings.app,
      [XhrHeaders.CLIENT_BUILD_ID]: env.settings.buildId,
      [XhrHeaders.REQUEST_ORIGIN]: window.self !== window.top ? 'cross-site' : 'same-origin'
    }, headers);
  }
};

module.exports = XhrHelpers;
