// Module
var code = `<div class="base-card__wrapper base-card__wrapper--upper js-base-card__wrapper--upper">
  <div class="base-card__title"></div>
</div>
<div class="base-card__wrapper base-card__wrapper--lower">
  <div class="form-wrapper">
    <input placeholder="<%- t('eventCheckin.prompt') %>" aria-label="<%= ariaInputLabel %>"></input>
    <button type="button" class="axon-button white js-submit-button"><%- t('eventCheckin.submit') %></button>
    <p class="hidden text-muted js-none-info"><%- t('eventCheckin.none') %></p>
  </div>
</div>
`;
// Exports
module.exports = code;