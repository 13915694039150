const Backbone = require('Backbone');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

class PageShareItemView extends Marionette.ItemView {
  getTemplate() {
    return `
      <p class="metadata-value"><%- communityName %></p>
      <div class="js-pin-icon pin-icon ax-grid__col--auto-size <%= getPinClass() %>"
        role="button"
        aria-label="<%- pinAriaLabel %>"
        aria-disabled="<%- isPinDisabled %>"
        <%- tabIndexString %>
      ></div>
    `;
  }

  triggers() {
    return {
      'click .js-pin-icon:not(.disabled)': 'click:pin'
    };
  }

  events() {
    return {
      'keydown .js-pin-icon:not(.disabled)': '_onKeydownPin'
    };
  }

  templateHelpers() {
    const isPinDisabled = this.viewOnly === true || this.model.get('userCanPin') === false;
    const tabIndexString = isPinDisabled ? '' : 'tabindex=0';

    const communityName = this._getCommunityName();
    const pinAriaLabel = this.model.get('isPinned')
      ? I18n.t('discover.pageTypes.generic.pinned.label', { communityName })
      : I18n.t('discover.pageTypes.generic.unpinned.label', { communityName });

    return {
      getPinClass: () => {
        const pinClasses = ['blue'];
        if (this.model.get('isPinned')) {
          pinClasses.push('icon-thumbtack');
        } else {
          pinClasses.push('icon-thumbtack-outline');
        }
        if ((this.viewOnly === true) || (this.model.get('userCanPin') === false)) {
          pinClasses.push('disabled');
          if (this.model.get('isPinned') === false) {
            pinClasses.push('hidden');
          }
        }
        return pinClasses.join(' ');
      },
      pinAriaLabel,
      isPinDisabled,
      tabIndexString,
      communityName
    };
  }

  initialize(options = {}) {
    ({
      authChannel: this.authChannel = Backbone.Wreqr.radio.channel('auth'),
      glChannel: this.glChannel = Backbone.Wreqr.radio.channel('global')
    } = options);

    this.viewOnly = options.viewOnly != null ? options.viewOnly : false;
    this.usersLanguage = this.authChannel.reqres.request('session:user:language');
  }

  _getCommunityName() {
    return this.model.community.getName(this.usersLanguage);
  }

  _onKeydownPin(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      e.preventDefault();
      this.onClickPin();
    }
  }

  onClickPin() {
    // wasPinned captures the "before" state of the model
    const wasPinned = this.model.get('isPinned');
    const communityName = this.model.community.getName(this.usersLanguage);

    this.model.togglePin()
      .done(() => {
        // changing the isPinned property must only happen on a successful save, that's why it is
        // being set here and not in the model.togglePin method. isPinned is used by the template and it does
        // get returned from the PageSharedCommunitiesList API, but it isn't really a state in the model
        // it's more of a view state for rendering the pin icon
        this.model.set('isPinned', !wasPinned);

        if (wasPinned) {
          const message = I18n.t('discover.pageTypes.generic.unpin.success', {
            community: communityName
          });
          window.app.layout.flash.success(message);
        } else {
          const message = I18n.t('discover.pageTypes.generic.pin.success', {
            community: communityName
          });
          window.app.layout.flash.success(message);
        }
      })
      .fail((xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const errcode = exception.getErrorCode();

        if (!wasPinned) {
          this.model.unset('communityPin');
        }

        if (errcode === AxonifyExceptionCode.CLIENT_ERROR_DUPLICATE_ENTITY) {
          xhr.skipGlobalHandler = true;
          // this was a pin attempt where the page is already pinned
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.pin.error.3002'));
          this.model.set('isPinned', true);
          if (this.model.get('communityPin') === undefined) {
            // recreate the pin that should be in the model anyways
            this.model.initializePin();
          }
          return;
        }

        if (errcode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
          xhr.skipGlobalHandler = true;
          if (wasPinned) {
            // this was an unpin attempt where the page was not pinned. Here the message can be
            // something like "this page is already unpinned"
            window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.unpin.error.3001'));
            this.model.set('isPinned', false);
            this.model.unset('communityPin');
          } else {
            // we get a 3001 code when pinning, if the community has been archived. Ugh!
            // let's not make a special i18n message for that, let's just use the generic error
            window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.pin.error.generic'));
            this.model.set('isPinned', false);
            this.model.set('userCanPin', false);
            this.model.unset('communityPin');
          }
          return;
        }

        if (errcode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
          xhr.skipGlobalHandler = true;
          if (wasPinned) {
            window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.unpin.error.3017', {
              community: communityName
            }));
          } else {
            window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.pin.error.3017', {
              community: communityName
            }));
          }
          this.model.set('isPinned', wasPinned);
          this.model.set('userCanPin', false);
          return;
        }

        // this was a fail for some other reason; set the pin state back to whatever it was
        this.model.set('isPinned', this.model.previous('isPinned'));

        if (wasPinned) {
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.unpin.error.generic'));
        } else {
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.pin.error.generic'));
        }
      })
      .always(() => {
        this.render();
      });
  }
}

module.exports = PageShareItemView;
