const { Model } = require('Backbone');
const CommunityInsightsOverviewTimeFrameEnum = require('@training/apps/training/communitiesInsights/CommunityInsightsOverviewTimeFrameEnum');

class CommunityInsightsOverviewModel extends Model {

  initialize(models, options = {}) {
    ({teamBasedCommunityFilter: this.teamBasedCommunityFilter} = options);
    this.dateRange = this.getDefaultDateRange();
  }

  apiEndpoint() {
    return `/page/engagement/overview?includeTeamBasedCommunities=${ this.teamBasedCommunityFilter ? 'true' : 'false' }`;
  }

  getDefaultDateRange() {
    return CommunityInsightsOverviewTimeFrameEnum.LAST7;
  }

  setDateRange(dateRange) {
    this.dateRange = dateRange;
  }

  getCurrentOverviewModel() {
    return this.get('metrics')[this.dateRange];
  }

  getCurrentDateStamps() {
    return this.get('periods')[this.dateRange];
  }

  resetTeamBasedCommunityFilter(teamBasedCommunityFilter) {
    this.teamBasedCommunityFilter = teamBasedCommunityFilter;
    return this.fetch();
  }
}

module.exports = CommunityInsightsOverviewModel;
