const logging = require('logging');
const UIKit = require('@training/widgets/UIKit');

class ActivityPresenter {
  constructor(pageFactory) {
    this.pageFactory = pageFactory;
  }

  show(activity, options = {}) {
    const {
      transition = UIKit.View.Transitions.FADE,
      modal = false,
      gameManager = null,
      complete = () => {},
      onShow = () => {}
    } = options;

    activity.startTimer();

    const completeCallback = () => {
      logging.info(`Completing Activity - id: ${ activity.id }`);

      activity.stopTimer();

      window.app.layout.flash.clear();

      complete();
    };

    const page = this.pageFactory.create(activity, { gameManager, complete: completeCallback });

    if (modal) {
      window.app.layout.setViewInModal(page, transition);
    } else {
      window.app.layout.setView(page, transition);
    }

    onShow();
  }

  _logShowingActivity(activity, transition, modal) {
    const type = activity.get('type');
    let typeLogMsg = `${ type }`;

    if (activity.isQuestionType()) {
      const variantType = activity.getQuestionVariantType();
      const questionId = activity.getQuestionId();
      typeLogMsg += ` - variant: ${ variantType } - question_id: ${ questionId }`;
    }

    logging.info(`Showing Activity - id: ${ activity.id } - type: ${ typeLogMsg } - transition: ${ transition } - modal: ${ modal }`);
  }
}

module.exports = ActivityPresenter;
