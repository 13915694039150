const { Model } = require('Backbone');

class CommunityAccessModel extends Model {
  toOption() {
    return {
      id: this.get('level'),
      value: this.get('level'),
      columnHeader: this.get('columnHeader')
    };
  }
}

module.exports = CommunityAccessModel;
