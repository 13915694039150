const { LayoutView } = require('Marionette');

class MsTeamsHeaderBarLayout extends LayoutView {

  id() {
    return 'header';
  }

  attributes() {
    return {
      role: 'banner'
    };
  }

  className() {
    return 'msteams-header';
  }

  getTemplate() {
    return require('@common/modules/main/msTeams/MsTeamsHeaderBarTemplate.html');
  }

  regions() {
    return {
      leftHeaderRegion: '@ui.leftHeaderRegion',
      menuRegion: '@ui.menuRegion',
      rightHeaderRegion: '.js-right-header-region'
    };
  }

  ui() {
    return {
      leftHeaderRegion: '.js-left-header-region',
      menuRegion: '.js-menu-region'
    };
  }

  toggleLeftRegionAlwaysShow(toggle) {
    this.ui.leftHeaderRegion.toggleClass('show-back-button', toggle);
  }

  toggleDesktopHideable(toggle = false) {
    this.$el.toggleClass('desktop-hideable', toggle);
  }
}

module.exports = MsTeamsHeaderBarLayout;
