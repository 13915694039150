const Backbone = require('Backbone');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const _ = require('underscore');

const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const CommunitiesManagementController = require('@training/apps/search/communitiesManagement/CommunitiesManagementController');

const CommunityManagementController = require('@training/apps/search/communitiesManagement/CommunityManagementController');

const {
  COMMUNITIES,
  IMPORT_HISTORY,
  COMMUNITY_MANAGEMENT,
  FILTERS,
  PROFANITY_FILTER,
  SETTINGS,
  TAGS
} = require('@common/data/enums/SearchPageEnum');

class CommunitiesManagementFlowController extends FlowController {
  constructor(...args) {
    super(...args);

    this.pageState = {};
  }

  processSequenceFlow(options) {
    return this.showPage(options).then((handled) => {
      if (handled) {
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  showPage(options = {}) {
    const {
      pageId,
      pageOptions
    } = this._getPageArgs(options);

    if (options.pageId == null) {
      return this._attemptToRestoreDiscoverPage().then(() => {
        return true;
      },
      Promise.resolve.bind(null, false));
    }

    return this._showPageView(pageId, pageOptions).then(() => {
      this._clearPageState();
      return true;
    },
    Promise.resolve.bind(null, false));
  }

  _getPageArgs(options = {}) {
    if (options.pageId != null) {
      return options;
    }

    return Object.assign(options, {
      pageId: this.pageState.pageId,
      pageOptions: _.omit(this.pageState.pageOptions || {}, 'navigate')
    });
  }

  _clearPageState() {
    this.pageState = {};
  }

  _showPageView(pageId, pageOptions) {
    switch (pageId) {
      case COMMUNITIES:
        pageOptions.initialTab = COMMUNITIES;
        return this.showCommunitiesPage(pageOptions);
      case IMPORT_HISTORY:
        return this.showImportHistoryPage(pageOptions);
      case COMMUNITY_MANAGEMENT:
        return this.showCommunityManagementPage(pageOptions);
      case FILTERS:
        pageOptions.initialTab = FILTERS;
        return this.showCommunitiesPage(pageOptions);
      case SETTINGS:
        return this.showSettingsPage(pageOptions);
      case PROFANITY_FILTER:
        pageOptions.initialTab = FILTERS;
        return this.showProfanityFilterPage(pageOptions);
      case TAGS:
        pageOptions.initialTab = TAGS;
        return this.showTagsPage(pageOptions);
      default:
        return Promise.reject(Promise.OperationalError('No discover flow matched...'));
    }
  }

  _attemptToRestoreDiscoverPage() {
    const hash = UrlHelpers.getLocationHash();

    if (hash.includes('hub/search') || hash.includes('hub/paths')) {
      _.defer(() => {
        Backbone.history.loadUrl(hash);
      });
      return Promise.resolve();
    }
    return Promise.reject(Promise.OperationalError('No discover flow matched...'));

  }

  showCommunitiesPage(pageOptions = {}) {
    ({
      pageNum: this.pageNum,
      searchString: this.searchString,
      initialTab: this.initialTab,
      filterId: this.filterId
    } = pageOptions);

    if (this.pageNum == null || this.pageNum < 1) {
      this.pageNum = 1;
    }

    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      initialTab: this.initialTab,
      pageNum: this.pageNum,
      searchString: this.searchString,
      filterId: this.filterId
    }, 'fade');

    return Promise.resolve();
  }

  showImportHistoryPage(pageOptions = {}) {
    ({
      pageNum: this.pageNum
    } = pageOptions);

    if (this.pageNum == null || this.pageNum < 0) {
      this.pageNum = 0;
    }

    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      initialTab: IMPORT_HISTORY,
      pageNum: this.pageNum
    }, 'fade');

    return Promise.resolve();
  }

  showCommunityManagementPage(pageOptions) {
    window.app.layout.setView({
      ViewControllerClass: CommunityManagementController,
      communityId: pageOptions.communityId
    }, 'fade');

    return Promise.resolve();
  }

  showSettingsPage() {
    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      initialTab: SETTINGS,
      pageNum: this.pageNum
    }, 'fade');

    return Promise.resolve();
  }

  showProfanityFilterPage() {
    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      initialTab: PROFANITY_FILTER,
      pageNum: this.pageNum
    }, 'fade');

    return Promise.resolve();
  }

  showTagsPage(pageOptions = {}) {
    ({
      pageNum: this.pageNum
    } = pageOptions);

    if (this.pageNum == null || this.pageNum < 1) {
      this.pageNum = 1;
    }

    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      initialTab: TAGS,
      pageNum: this.pageNum
    }, 'fade');

    return Promise.resolve();
  }
}


module.exports = CommunitiesManagementFlowController;
