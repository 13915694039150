const logging = require('logging');
const _ = require('underscore');
const VideoPlayer = require('@common/components/video/players/VideoPlayer');
class YouTubePlayer extends VideoPlayer {
  constructor() {
    super(...arguments);
    this.template = _.tpl(` <iframe
      width="<%= width %>"
      height="<%= height %>"
      src="<%= url %>"
      frameborder="0"
      allowfullscreen>
    </iframe>\
    `);
  }

  initialize(options = {}) {
    super.initialize(...arguments);
    ({ userLanguage: this.userLanguage } = options);
  }

  renderPlayer() {
    this._$iframe = this.$('iframe');
    this._triggerLoad();
    return this._triggerEnd();
  }

  getTemplateOptions() {
    return _.extend(super.getTemplateOptions(), {url: this.getVideoUrl()});
  }

  getVideoUrl() {
    const youtubeKey = this.model.getYouTubeKey();
    logging.debug(`YouTube video ${ this.model.get('url') } -- ${ youtubeKey }`);
    let url = `//www.youtube-nocookie.com/embed/${ youtubeKey }?rel=0&modestbranding=1&enablejsapi=1&autohide=1`;
    if (this.autoplay) {
      url += '&autoplay=1';
    }
    if (this.userLanguage) {
      url += `&hl=${ this.userLanguage }`;
    }
    return url;
  }

  removePlayer() {
    this._$iframe.attr('src', '');
    return this._$iframe.attr('src', this.getVideoUrl());
  }

  _setAspectRatio() {
    return super._setAspectRatio(16 / 9);
  }
}
module.exports = YouTubePlayer;
