// Module
var code = `<div class="search-results-message-region"></div>
<h2 class="category-sub-heading <% if (!dzEnabled) { %> hidden <% } %>">
  <span class="semiboldfont"><%- t('selfDirected.search.categoryNames.articles') %> • </span>
  <span class="totalArticleCount"></span>
  <span><%- t('selfDirected.search.itemsFound') %></span>
</h2>
<div class="search__article-results-region <% if (!dzEnabled) { %> hidden <% } %>"></div>
<div class="see-articles-region"></div>
<h2 class="category-sub-heading <% if (!etEnabled) { %> hidden <% } %>">
  <span class="semiboldfont"><%- t('selfDirected.search.categoryNames.topics') %> •</span>
  <span class="totalTopicCount"></span>
  <span><%- t('selfDirected.search.itemsFound') %></span>
</h2>
<div class="search__topic-results-region <% if (!etEnabled) { %> hidden <% } %>"></div>
<div class="see-topics-region"></div>
<h2 class="category-sub-heading <% if (!etEnabled) { %> hidden <% } %>">
  <span class="semiboldfont"><%- t('selfDirected.search.categoryNames.paths') %> •</span>
  <span class="totalPathCount"></span>
  <span><%- t('selfDirected.search.itemsFound') %></span>
</h2>
<div class="search__path-results-region <% if (!etEnabled) { %> hidden <% } %>"></div>
<div class="see-paths-region"></div>
`;
// Exports
module.exports = code;