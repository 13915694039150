const Backbone = require('Backbone');
const _ = require('underscore');
const Community = require('@common/data/models/Community');

class FilterableCommunityList extends Backbone.Collection {
  get apiEndpoint() {
    return '/pageCommunities/search';
  }

  get model() {
    return Community;
  }

  parse(data) {
    return data.results;
  }

  fetch(options = {}) {
    options.data = _.isObject(options.data) ? JSON.stringify(options.data) : options.data;
    return super.fetch(_.extend({ type: 'POST' }, options));
  }

  fetchAll(accessLevel, pageType) {
    const options = {};

    options.data = {
      accessLevel,
      p: 0,
      rpp: 999999
    };

    if (pageType) {
      options.data.pageType = pageType;
    }

    return this.fetch(options);
  }

  comparator(community) {
    return community.getName();
  }
}

module.exports = FilterableCommunityList;
