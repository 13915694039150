const _ = require('underscore');

const Backbone = require('Backbone');

class NotificationList extends Backbone.Collection {
  apiEndpoint() {
    return '/notifications/feed';
  }

  initialize(model, options = {}) {
    ({
      notificationsGroup: this.notificationsGroup
    } = options);

    this.params = {
      p: 0,
      rpp: 9999,
      group: this.notificationsGroup
    };
  }

  fetch(options) {
    return super.fetch($.extend(true, {}, options, {
      data: this.params
    }));
  }

  clearEvents(options = {}) {
    return $.ajax(_.extend(options, {
      type: 'DELETE',
      apiEndpoint: `/notifications/feed?group=${ this.notificationsGroup }`,
      success: () => {
        this.reset();
      }
    }));
  }

  parse(res) {
    return res.results;
  }
}

module.exports = NotificationList;
