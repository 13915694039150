const env = require('env');

const Backbone = require('backbone');
const Language = require('@common/data/models/Language');

class LanguageList extends Backbone.Collection {
  comparator(model) {
    return model.get('name');
  }

  initialize(...args) {
    this.model = Language;
    super.initialize(...args);
  }

  static fromLanguageCodeList(languageCodeList) {
    const languageObjects = languageCodeList.map((language) => {
      return {
        langCode: language,
        id: language
      };
    });

    return new LanguageList(languageObjects);
  }

  static fromAllSystemLanguages() {
    return LanguageList.fromLanguageCodeList(env.settings.allLocales);
  }
}

module.exports = LanguageList;
