const AssessmentTopicOption = require('./AssessmentTopicOption');

class DailyTrainingAssessmentTopicOption extends AssessmentTopicOption {
  toAssessmentRequestJson() {
    return {
      launchContext: this.get('launchContext')
    };
  }
}

module.exports = DailyTrainingAssessmentTopicOption;
