const env = require('env');
const $os = require('detectOS');
const _ = require('underscore');
const CookieUtil = require('CookieUtil');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const AuthHelpers = require('@common/libs/helpers/app/AuthHelpers');

const Backbone = require('Backbone');
const Marionette = require('Marionette');
const { intersection } = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const SupportHelper = require('@common/libs/helpers/features/SupportHelper');
const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');

const AuthenticationScheme = require('@common/data/enums/AuthenticationScheme');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const NativeBridgeHelpers = require('@common/libs/helpers/app/NativeBridgeHelpers');
const AppConfigTypeEnum = require('@common/data/enums/AppConfigTypeEnum');

const Language = require('@common/data/models/Language');
const LoginViewModel = require('@common/modules/auth/views/login/LoginViewModel');
const OAuthButtonView = require('@common/components/oauth/OAuthButtonView');

require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');
require('@common/components/forms/editors/password/Form.Editor.Password');

const RedirectingAbortedAuthentication = require('@training/apps/auth/exceptions/RedirectingAbortedAuthentication');

class LoginPage extends UIKit.View {
  className() {
    return 'page';
  }

  getTemplate() {
    return require('../templates/LoginPage.html');
  }

  events() {
    return {
      'click .login': 'onLogin',
      'click .verify': 'onVerify',
      'click .saml-login .axon-button': 'onSAMLClick',
      'click #activation-link': 'resetApp',
      'click #forgotUsernameLink': 'onForgotUsernameClick',
      'click #forgotPasswordLink': 'onForgotPasswordClick'
    };
  }

  ui() {
    return {
      verify: '.verify',
      loginWrapper: '#login-wrapper',
      code: '.mfa-form__code',
      rememberMe: '.mfa-form__checkbox input',
      loginBranding: '#login-branding',
      mfaBranding: '#mfa-branding',
      loginHeading: '.login-heading'
    }
  }

  constructor(...args) {
    super(...args);
    this.onClose = this.onClose.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.resetApp = this.resetApp.bind(this);
    this.resetLoginButton = this.resetLoginButton.bind(this);
    this.resetVerifyButton = this.resetVerifyButton.bind(this);

    this.viewModel = new LoginViewModel({
      samlSettings: TenantPropertyProvider.get().getProperty('samlSettings'),
      authenticationSchemes: TenantPropertyProvider.get().getProperty('authenticationSchemes')
    });
    this.schemes = this.viewModel.getSchemes();

    if (!this._shouldShowLoginInputFields() && _.has(this.options.query, 'alt-login')) {
      this.schemes = [AuthenticationScheme.URIToken];
      CookieUtil.set('redirectURL', window.location.href);
    } else {
      CookieUtil.unset('redirectURL');
    }

    // Create the login form
    this.model = new Backbone.Model({
      lang: {id: I18n.getLocale()}
    });

    this.supportedLanguages = intersection(TenantPropertyProvider.get().getProperty('languages'), env.settings.supportedLocales);
    this.showLanguageSelector = this.supportedLanguages.length > 1;

    this.ssoSamlButtonTemplate = `
      <% if (schemes.includes(AuthenticationScheme.SAML) && ssoSamlSettings.length > 0) { %>
        <div class="buttonbar saml-login <%- shouldShowLoginInputFields ? 'top-border' : '' %>">
          <% _.each(ssoSamlSettings, function(settings) { %>
          <a class="axon-button" href="<%- settings.url %>"><%- settings.label %></a>
          <% }); %>
        </div>
      <% } %>
    `;
  }

  renderSsoSamlButtons() {
    const ssoSamlButton = _.tpl(this.ssoSamlButtonTemplate)({
      schemes: this.schemes,
      AuthenticationScheme,
      ssoSamlSettings: this.viewModel.aggregateSamlSettings(),
      shouldShowLoginInputFields: this._shouldShowLoginInputFields()
    });

    this.$('.saml-button-wrapper').html(ssoSamlButton);
  }

  render() {
    this.oauthSettings = AuthHelpers.getOAuthSettings();
    const isInApp = NativeBridgeHelpers.isInApp();

    // Ensures that the password field is cleared out when page re-rendering happens. For example,
    // when the user changes languages.  Otherwise the input just re-hashes the already hashed value
    this.model.unset('passwd');

    const helpEmail = TenantPropertyProvider.get().getProperty('helpEmail');
    const helpPhone = TenantPropertyProvider.get().getProperty('helpPhone');
    this.helpContact = SupportHelper.getSupportHtml(helpEmail, helpPhone);

    this.$el.html(Marionette.Renderer.render(this.getTemplate(), {
      schemes: this.schemes,
      superUserRememberMeDuration: window.apps.auth.session.get('superUserRememberMeDuration'),
      loginWrapperClasses: this._getLoginWrapperClasses(),
      AuthenticationScheme,
      oauthSettings: this.oauthSettings,
      shouldShowLoginInputFields: this._shouldShowLoginInputFields(),
      isAuthSchemesOAuthEnabled: this.viewModel.isAuthSchemesOAuthEnabled(),
      languageNameNative: I18n.languageNameNative,
      showLanguageSelector: this.showLanguageSelector,
      currentLocale: I18n.getLocale(),
      inApp: isInApp,
      helpContact: this.helpContact,
      queryParams: this.options.query,
      ssoSamlSettings: this.viewModel.aggregateSamlSettings(),
      workplaceUrl: UrlHelpers.getSubdomain()
    }));

    if (this.viewModel.isAuthSchemesOAuthEnabled()) {
      const oAuthButtonCollectionView = new Marionette.CollectionView({
        el: this.$('.oauth-providers'),
        collection: new Backbone.Collection(this.oauthSettings),
        childView: OAuthButtonView,
        childViewOptions: {
          language: this.model.get('lang').id,
          loginWithOAuth: apps.auth.loginWithOAuth,
          showSpinner: () => {
            app.layout.showSpinner();
          },
          hideSpinner: () => {
            app.layout.hideSpinner();
          },
          showFlashError: (message) => {
            app.layout.flash.error(message);
          }
        }
      });
      oAuthButtonCollectionView.render();
    }

    const languageList = new Backbone.Collection(this.supportedLanguages, { model: Language });

    this.form = new UIKit.Form({
      el: this.$('#login-wrapper'),
      model: this.model,
      context: {
        languages: {
          collection: languageList
        },
        passwordOptions: {
          autocomplete: 'current-password'
        }
      }
    });

    if (LocalStorageHelpers.supportsLocalStorage()) {
      const storageObj = LocalStorageHelpers.getStateStorageObject();
      storageObj.userSelectedLanguage = this.model.get('lang').id;
    }

    if (this.viewModel.getSamlOverrides() == null && NativeBridgeHelpers.supportAppConfig(AppConfigTypeEnum.SAML_OVERRIDES)) {
      NativeBridgeHelpers.requestAppConfig(AppConfigTypeEnum.SAML_OVERRIDES).then((samlOverrides) => {
        this.viewModel.setSamlOverrides(samlOverrides);
        this.renderSsoSamlButtons();
      });
    } else {
      this.renderSsoSamlButtons();
    }

    if (this.showLanguageSelector) {
      this.form.fields.lang.editor.on('change:selection', (formEditor) => {
        const lang = formEditor.getValue();
        if (!lang) {
          return;
        }
        I18n.setLocale(lang.id, {
          always: () => {
            if (this.isDestroyed) {
              return;
            }
            this.render();
            this.viewDidAppear();
            this.trigger('change:lang');
          }
        });
      });
    }

    return this;
  }

  viewDidAppear() {
    const loginPageTitle = `${ I18n.t('menu.apps.training') } - ${ I18n.t('login.loginbtn') }`;
    window.app.layout.setTitle(loginPageTitle);

    if ($os.mobile) {
      window.app.layout.resetLeftHeaderView();
    } else {
      // can't set focus on mobile because iOS will hide the "Username"
      // placeholder text and it won't bring up the keyboard so the user has to
      // click on the input field anyway
      this.$('input:first').trigger('focus');

      // TODO: login disclaimer is a desktop-layout-only thing and should be
      // outside the login page if possible, IIRC this is a HANCOCK thing.
      window.app.layout.setLoginDisclaimer();
    }

    if (NativeBridgeHelpers.isInApp()) {
      if (NativeBridgeHelpers.shouldShowReset()) {
        this.$('#activation-link').show();
      }

      if (AuthHelpers.shouldDisplayGoogleOAuthWarning()) {
        const $deprecationWarning = this.$('p.hidden');
        $deprecationWarning.removeClass('hidden');
        // We can't link to the Google Play Store because it will open the link
        // inside our web view instead of launching the Play Store app
        if ($os.ios) {
          $deprecationWarning.append(`<div><a href="https://itunes.apple.com/us/app/axonify-mobile/id599679030?mt=8">${ I18n.t('login.upgradeCallToAction') }</a></div>`);
        }
      }
    }
  }

  onClose() {
    if (!$os.mobile) {
      window.app.layout.setGeneralDisclaimer();
    }
    return super.onClose();
  }

  onLogin(e) {
    e.preventDefault();
    if (this.mfaEnabled) {
      this.onVerify(e);
      return false;
    }

    if (this.loginButtonDisabled) {
      return false;
    }

    // Disable the login button
    this.loginButtonDisabled = true;
    this.$('.login').addClass('grey');

    // Fixed an issue on iOS6 that  the window slides down after closing keyboard
    if ($os.ios) {
      window.scrollTo(0, 0);
    }

    // Short circuit the flow if the scheme is SAML and the user clicked on the button
    if (this.schemes.length === 1 && this.schemes.includes(AuthenticationScheme.SAML)) {
      return true;
    }

    // Validate the fields and update the model
    // Note that an empty password's MD5 hash is 'd41d8cd98f00b204e9800998ecf8427e'.
    this.form.commit();
    if ((this.model.get('user').length === 0) || (this.model.get('passwd') === CryptographyHelper.EMPTY_STRING_MD5)) {
      window.app.layout.flash.error(I18n.t('flash.invalidLogin'));
      this.resetLoginButton();
    } else {
      window.apps.auth.login({
        username: this.model.get('user'),
        password: this.model.get('passwd')
      }, {
        error: (options = {}) => {
          if (options.mfaEnabled) {
            this.showLanguageSelector = false;
            this.render();
            this.ui.loginBranding.hide();
            this.ui.mfaBranding.toggleClass('hidden', false);
            this.mfaEnabled = true;
            this.ui.loginHeading.text(I18n.t('login.mfa.mfainput'));
          } else {
            this.ui.loginWrapper.addClass('login-error');
          }
        }
      }).always(this.resetLoginButton);
    }
    return false;
  }

  onVerify(e) {
    e.preventDefault();

    if (this.verifyButtonDisabled) {
      return;
    }

    this.verifyButtonDisabled = true;
    this.ui.verify.addClass('grey');

    apps.auth.mfaVerify({
      code: this.ui.code.val(),
      rememberMe: this.ui.rememberMe.prop('checked')
    }, {
      error: () => {
        this.ui.loginWrapper.addClass('login-error');
      }
    }).always(this.resetVerifyButton);
  }

  onSAMLClick(e) {
    if (apps.base.checkDoubleSubmit(2000)) {
      e.preventDefault();
      return false;
    }

    window.apps.auth.saveDeepLinkHashInCookie();

    // check tenant property to determine if we should open saml url in edge
    const loginWithEdge = TenantPropertyProvider.get().getProperty('samlRedirectWithSecureUrl');

    // if we're in mobile app and saml site should open in Edge,
    // then check if app supports it and if it does, let the app open it!
    if (loginWithEdge && NativeBridgeHelpers.isInApp() && NativeBridgeHelpers.canOpenUrl()) {
      const $curTarget = $(e.currentTarget);

      // check anchor's href value and prefix the edge custom scheme to the href
      let href = 'microsoft-edge';
      if ($os.ios) {
        href += '-';
      } else {
        // Android
        href += ':';
      }
      href += $curTarget.prop('href');

      NativeBridgeHelpers.registerOnceUrlClosed( (data) => {
        // if there is an errorCode, flash the message
        const {errorCode} = data || {};
        if (errorCode != null) {
          const errorStringKey = 'errors.nativeWrapper.1000-microsoft-edge';
          window.app.layout.flash.error(I18n.t(errorStringKey));
        }
      });

      NativeBridgeHelpers.openUrl({
        url: href,
        title: ''
      });

      e.preventDefault();
      return false;
    }

    CookieUtil.set('isSAMLLogin', 'true');

    return true;
  }

  resetLoginButton() {
    this.$('.login').removeClass('grey');
    this.loginButtonDisabled = false;
  }

  resetVerifyButton() {
    this.ui.verify.removeClass('grey');
    this.verifyButtonDisabled = false;
  }

  resetApp() {
    this.dismiss();

    // Before we need to unregister the mobile device from the system if there is one.
    window.apps.auth.session.user.unregisterCurrentDevice(() => {
      setTimeout(() => {
        window.apps.auth.logout();
        NativeBridgeHelpers.resetApp();
      }, 200);
    });

    return false;
  }

  onForgotUsernameClick() {
    RedirectingAbortedAuthentication.catchAndLog(() => {
      window.apps.auth.redirectToAuthPageFrag('recover-username');
    });
  }

  onForgotPasswordClick() {
    RedirectingAbortedAuthentication.catchAndLog(() => {
      window.apps.auth.redirectToAuthPageFrag('recover-password');
    });
  }

  _getLoginWrapperClasses() {
    const loginWrapperClasses = ['clearfix'];

    if (this.helpContact.length) {
      loginWrapperClasses.push('help-contact');
    }
    if (this.oauthSettings.length) {
      loginWrapperClasses.push('oauth');
    }

    return loginWrapperClasses.join(' ');
  }

  _shouldShowLoginInputFields() {
    const validSchemeArray = [AuthenticationScheme.LocalDB, AuthenticationScheme.SelfServe,
      AuthenticationScheme.URIToken];

    return _.intersection(this.schemes, validSchemeArray).length > 0;
  }
}

module.exports = LoginPage;
