// Module
var code = `<div class="external-link-wrapper">
  <div class="panel">
    <div class="panel-heading clearfix">
        <h2><%- nameString %></h2>
      <div class="row margin-top-med">
        <div class="col-xs-12">
          <strong><label><%- t('content.externalLink.descriptionLabel') %></label></strong>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 desc">
          <%= HTMLHelpers.lineBreakToBr(HTMLHelpers.htmlEncode(descString)) %>
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="row url-row">
        <div class="col-xs-12 url-panel full-width text-center">
          <a href="<%- url %>" target="_blank"><%- url %></a>
        </div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;