const I18n = require('@common/libs/I18n');
const FileFactory = require('@common/data/models/media/FileFactory');

const FixedFooterLayout = require('@common/components/fixedLayout/FixedFooterLayout');
const PdfContentView = require('@common/components/mediaFilePreview/views/PdfContentShowView');
const PDFComplianceView = require('@training/apps/training/views/activities/pages/pdf/PDFComplianceView');
const PDFBaseViewController = require('@training/apps/training/views/activities/pages/pdf/PDFBaseViewController');

class PDFEmbeddedController extends PDFBaseViewController {

  viewDefinition() {
    return {
      ViewClass: FixedFooterLayout,
      className: 'pdf-content-holder fit-parent'
    };
  }

  regionControllers() {
    const fileLink = this.activity.get('body').fileLink;

    const fileFactory = new FileFactory();
    const documentMedia = fileFactory.createMediaFromJSON(fileLink);

    return {
      fixedContentRegion: {
        viewDefinition: {
          ViewClass: PdfContentView,
          model: documentMedia
        }
      },
      fixedFooterRegion: {
        viewDefinition: {
          ViewClass: PDFComplianceView,
          model: this.checkBoxState
        }
      }
    };
  }

  onViewBeforeAttach() {
    window.app.layout.toggleFullPage(true);
    window.app.layout.toggleFooter(false);
  }

  onViewShow() {
    window.app.layout.setTitle(I18n.t('pdf.title'));

    if (this.canSkip) {
      this._setSkipButton();
      this.getRegion('fixedFooterRegion').empty();
    } else {
      this.listenTo(this.checkBoxState, 'change:isChecked', this._setContinueButton);
      this._setContinueButton();
    }
  }

  onViewBeforeDestroy() {
    window.app.layout.toggleFullPage(false);
    window.app.layout.toggleFooter(true);
  }
}

module.exports = PDFEmbeddedController;
