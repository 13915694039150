const { LayoutView } = require('Marionette');
const $os = require('detectOS');
const I18n = require('@common/libs/I18n');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const PageType = require('@common/components/discover/enums/PageType');

const {
  isEmpty,
  find
} = require('underscore');

class TimelineCardContentView extends LayoutView {
  className() {
    return 'timeline-card-content ax-grid ax-grid--no-gutter ax-grid--align-items-center';
  }

  getTemplate() {
    return require('@training/apps/timeline/TimelineCardContentViewTemplate.html');
  }

  ui() {
    return {
      imageContainer: '.image-container',
      previewContent: '.content',
      title: '.title'
    };
  }

  templateHelpers() {
    return {
      getPageTypeString: () => {
        const pageTypeStringMap = {
          [PageType.REFERENCE]: I18n.t('discover.dzArticleTypes.link'),
          [PageType.QUESTION]: I18n.t('discover.dzArticleTypes.question'),
          [PageType.TRAINING]: I18n.t('discover.dzArticleTypes.module')
        };
        return pageTypeStringMap[this.model.get('type')];
      },
      getPreviewContent: () => {
        return HTMLHelpers.stripHtmlForTimelinePreview(this.model.get('previewContent'));
      },
      isReference: () => {
        return this.model.getType() === PageType.REFERENCE;
      },
      isArticle: () => {
        return this.model.getType() === PageType.ARTICLE;
      },
      getTitle: () => {
        return this.model.getTitle();
      }
    };
  }

  events() {
    return {
      'click .title.reference': this._onClickLink,
      'click a': this._onClickALink()
    };
  }

  onAttach() {
    const titleHeight = parseInt(this.ui.title.height(), 10);
    const titleLineHeight = parseInt(this.ui.title.css('line-height'), 10);
    const textHeight = parseInt(this.ui.previewContent.height(), 10);
    const longTitleLineMultiplier = $os.mobile ? 1 : 2;
    const shortTitleLineMultiplier = $os.mobile ? 2 : 3;

    // Limit title to a max of two lines
    this.ui.title.css({
      '-webkit-line-clamp': '2',
      height: titleLineHeight * 2
    });

    // If article title is more than 1 line, show 2 lines of preview text. Otherwise show 3 lines of text
    // Mobile preview text should be one line shorter. See above multiplier values
    const newLineHeight = titleHeight > titleLineHeight
      ? textHeight * longTitleLineMultiplier
      : textHeight * shortTitleLineMultiplier;
    const newLineNum = titleHeight > titleLineHeight ? longTitleLineMultiplier : shortTitleLineMultiplier;

    this.ui.previewContent.css({
      '-webkit-line-clamp': `${ newLineNum }`,
      height: newLineHeight
    });
  }

  onShow() {
    if ($os.mobile && this.model.get('type') === PageType.ARTICLE) {
      this.ui.previewContent.show();
    }

    this._renderCardImage();
  }

  //Posts will already have the media object, so we can pass it straight through and skip the extra computations
  _renderCardImage(postMedia = null) {
    const media = postMedia || this._getFirstImage();

    if (!isEmpty(media)) {
      this.cardImage = ImageViewerFactory.createViewerInstance({
        media,
        $el: this.ui.imageContainer
      });

      if (this.cardImage != null) {
        this.ui.imageContainer.removeClass('hidden');
        this.listenTo(this.cardImage, 'image:loaded', () => {
          this.trigger('image:loaded');
        });

        this.cardImage.render();
      }
    }
  }

  _getFirstImage() {
    // find the first media--image element and get its media-id
    const previewContent = this.model.get('previewContent');
    const $newContent = $('<tmp></tmp>').append(previewContent);
    const imageMediaId = $newContent.find('.page__media.media--image').first()
      .data('mediaId');

    //return the media element or null if it does not exist
    const media = this.model.get('media');
    const imageMedia = find(media, (mediaObj) => {
      return mediaObj.id === imageMediaId;
    });

    return imageMedia;
  }

  // Required instead of standard href attribute because the click was propagating up to the full card click handler
  _onClickLink(e) {
    e.stopPropagation();

    const href = this.model.getReferenceUrl();
    if (href != null) {
      window.open(href);
    }

    return false;
  }

  _onClickALink(e) {
    if (e != null) {
      e.stopPropagation();
    }
  }
}

module.exports = TimelineCardContentView;
