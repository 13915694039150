const PdfViewerHelpers = {
  // Pinch-to-Zoom Implementation
  // Author/Editor for version used: https://gist.github.com/larsneo
  // Github: https://gist.github.com/larsneo/bb75616e9426ae589f50e8c8411020f6
  addPinchToZoomListeners(iframe) {
    const MIN_SCALE = 0.1;

    iframe.addEventListener('load', () => {
      const pdfApp = iframe.contentWindow.PDFViewerApplication;
      if (pdfApp) {
        const pdfDocument = iframe.contentWindow.document;

        let startX = 0, startY = 0;
        let initialPinchDistance = 0;
        let pinchScale = 1;

        const viewer = pdfDocument.getElementById('viewer');
        const container = pdfDocument.getElementById('viewerContainer');
        const reset = () => {
          startX = startY = initialPinchDistance = 0; pinchScale = 1;
        };

        // Prevent native iOS page zoom
        //pdfDocument.addEventListener("touchmove", (e) => { if (e.scale !== 1) { e.preventDefault(); } }, { passive: false });

        pdfDocument.addEventListener('touchstart', (e) => {
          if (e.touches.length > 1) {
            startX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
            startY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
            initialPinchDistance = Math.hypot((e.touches[1].pageX - e.touches[0].pageX), (e.touches[1].pageY - e.touches[0].pageY));
          } else {
            initialPinchDistance = 0;
          }
        });

        pdfDocument.addEventListener('touchmove', (e) => {
          if (initialPinchDistance <= 0 || e.touches.length < 2) {
            return;
          }

          if (e.scale !== 1) {
            e.preventDefault();
          }
          const pinchDistance = Math.hypot((e.touches[1].pageX - e.touches[0].pageX), (e.touches[1].pageY - e.touches[0].pageY));
          const originX = startX + container.scrollLeft;
          const originY = startY + container.scrollTop;
          pinchScale = pinchDistance / initialPinchDistance;
          viewer.style.transform = `scale(${ pinchScale } )`;
          viewer.style.transformOrigin = `${ originX }px ${ originY }px`;
        }, { passive: false });

        pdfDocument.addEventListener('touchend', () => {
          if (initialPinchDistance <= 0) {
            return;
          }

          viewer.style.transform = `none`;
          viewer.style.transformOrigin = `unset`;

          if (pdfApp.pdfViewer) {
            pdfApp.pdfViewer.currentScale = Math.max(MIN_SCALE, pdfApp.pdfViewer.currentScale * pinchScale);
          }
          const rect = container.getBoundingClientRect();
          const dx = startX - rect.left;
          const dy = startY - rect.top;
          container.scrollLeft += dx * (pinchScale - 1);
          container.scrollTop += dy * (pinchScale - 1);
          reset();
        });
      }
    });
  },

  hidePrintButton(iframe) {
    iframe.addEventListener('load', () => {
      const $iframeContentDocument = $(iframe).contents();
      $iframeContentDocument.find('#outerContainer .toolbarButton.print').hide(); // desktop
      $iframeContentDocument.find('#outerContainer .secondaryToolbarButton.print').hide(); // mobile
    });
  }
};

module.exports = PdfViewerHelpers;
