import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type TodaysActivitiesOrderingType = EnumType & {
  LEARNING: string,
  TASKS: string,
  CAMPAIGNS: string
};

const TodaysActivitiesOrderingEnums = Enum.fromStringArray([
  'LEARNING',
  'TASKS',
  'CAMPAIGNS'
]) as TodaysActivitiesOrderingType;

export default TodaysActivitiesOrderingEnums;
