const I18n = require('@common/libs/I18n');
const { ItemView } = require('Marionette');
const DateHelpers = require('@common/libs/dateHelpers');

const PageStatus = require('@common/components/discover/enums/PageStatus');

class ArticlePublishingItemView extends ItemView {
  tagName() {
    return 'tr';
  }

  getTemplate() {
    return require('@training/apps/articles/publishAll/ArticlePublishingItemView.html');
  }

  templateHelpers() {
    return {
      formattedStatus: this._getPublishStatus()
    };
  }

  ui() {
    return {
      publishCheckbox: '.js-publish-checkbox'
    };
  }

  onRender() {
    this.ui.publishCheckbox.on('change', this._onChangeCheckboxState.bind(this));
  }

  onDestroy() {
    this.ui.publishCheckbox.off('change');
  }

  _hasFuturePublishDate() {
    const publishTime = this.model.get('publishTimestamp');
    const currentTime = DateHelpers.getTime();
    return publishTime && publishTime > currentTime;
  }

  _getScheduledStatus() {
    const publishDate = DateHelpers.convertDateFormatShortDateWithStrings(
      this.model.get('publishTimestamp')
    );
    return I18n.t('discover.pageTypes.generic.scheduledDate', { publishDate });
  }

  _getPublishStatus() {
    if (this.model.get('processing')) {
      return I18n.t('general.processing');
    }

    if (this.model.get('lockingUser')) {
      return I18n.t('discover.publishFlow.publishState.locked');
    }

    if (this._hasFuturePublishDate()) {
      return this._getScheduledStatus();
    }

    return I18n.t(this._getI18nKeyForPageStatus(this.model.get('pageStatus').toUpperCase()));
  }

  _getI18nKeyForPageStatus(pageStatus) {
    switch (pageStatus) {
      case PageStatus.FACT:
        return 'discover.publishFlow.publishState.published';
      case 'INPROGRESS':
        return 'discover.publishFlow.publishState.inProgress';
      case 'MODIFIED':
        return 'discover.publishFlow.publishState.modified';
      case PageStatus.QUARANTINE:
        return 'discover.browse.reportedItems';
      case PageStatus.EXPIRED:
        return 'discover.publishFlow.publishState.expired';
      default:
        return 'discover.publishFlow.publishState.inReview';
    }
  }

  _onChangeCheckboxState() {
    const checked = !this.model.get('checked');
    this.model.set('checked', checked);

    if (this._hasFuturePublishDate()) {
      const status = checked ? I18n.t('discover.publishFlow.ignoringScheduled') : this._getScheduledStatus();
      this.$(`.js-article-status-${ this.model.get('language') }`).html(status);
    }
  }
}

module.exports = ArticlePublishingItemView;
