const Marionette = require('Marionette');
const $os = require('detectOS');

const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const KeyCode = require('@common/data/enums/KeyCode');

const PageType = require('@common/components/discover/enums/PageType');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

require('@common/components/titleHeader/TitleHeader.less');

class TitleEditView extends Marionette.ItemView {
  initialize(options = {}) {
    ({
      canEditTitle: this.canEditTitle,
      alternateLanguage: this.alternateLanguage
    } = options);
    this.isMetaPaneOpen = false;
  }

  events() {
    return {
      'submit #title-form': '_onTitleFormSubmit',
      'input @ui.inputTitle': '_tryToggleTitleLabel',
      'keyup @ui.inputTitle': '_onTitleKeyUp',
      'change @ui.inputTitle': '_tryToggleTitleLabel',
      'click @ui.btnMore': '_onClickMore'
    };
  }

  ui() {
    return {
      btnMore: '.js-more-button',
      containerTitle: '#title-container',
      inputTitle: '#title-input',
      labelTitle: '#title-label'
    };
  }

  className() {
    return 'full-width';
  }

  getTemplate() {
    return this.getOption('template') || `
    <div class="title__main-container title-edit-view">
      <div class="page-header__content">
        <form id="title-form" class="full-width">
          <div id="title-container">
            <% if (canEditTitle) { %>
            <h1 class="off-screen"><%- getPlaceholderText(type) %></h1>
            <label id="title-label" for="title-input" class="hidden"><%- getPlaceholderText(type) %></label>
            <input id="title-input" type="text" class="full-width page-header__input" placeholder="<%- getPlaceholderText(type) %>" data-field="title" />
            <% } else { %>
              <div class="title-header__title-region">
                <div class="page-header__title"><%- title %></div>
              </div>
            <% } %>
          </div>
        </form>
        <div class="title__sub-container clearfix">
          <div class="more-container visible-xs">
            <button class="js-more-button more-button">
              <div class="page-header__action-container">
                <span class="icon icon-ellipsis_horizontal"></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

`;
  }

  templateHelpers() {
    return {
      getPlaceholderText: (type) => {
        let placeholder = I18n.t(`discover.pageTypes.${ type }.title.placeholder`);
        const langCode = this.model.get('language').toUpperCase();
        if (this.alternateLanguage && this.model.get('type') === PageType.ARTICLE) {
          placeholder += ` - ${ I18n.t('languages.' + langCode.replace(/-|_/g, '')) }`;
        }
        return placeholder;
      },
      canEditTitle: this.canEditTitle
    };
  }

  onRender() {
    const modelType = this.model.get('type');
    const shouldShowLabel = ( modelType === PageType.ARTICLE || modelType === PageType.REFERENCE );
    if (!$os.placeholder && shouldShowLabel) {
      this.ui.containerTitle.addClass('enable-label');

      if (!this.model.get('title')) {
        this._tryToggleTitleLabel();
      }
    }

    this.titleForm = new Form({
      el: this.$('#title-form'),
      model: this.model
    });

    this.decodeAndSetTitleInput();
    this._setMoreButtonAriaAttributes();
  }

  commit() {
    this.ui.inputTitle.val(HTMLHelpers.htmlEncode(this.ui.inputTitle.val()));
    const errors = this.titleForm.commit();
    this.decodeAndSetTitleInput();
    return errors;
  }

  decodeAndSetTitleInput() {
    this.ui.inputTitle.val(this.model.getTitle());
  }

  _onTitleFormSubmit(e) {
    e.preventDefault();
  }

  _onTitleKeyUp(e) {
    // handle backspace and delete which do not trigger input event in ie8
    // only relying on keyup (instead of also input) was not responsive enough
    if (e.which === KeyCode.BACKSPACE || e.which === KeyCode.DELETE) {
      this._tryToggleTitleLabel();
    }
  }

  _tryToggleTitleLabel() {
    this.ui.labelTitle.toggleClass('hidden', Boolean(this.ui.inputTitle.val()));
  }

  _onClickMore() {
    this.triggerMethod('click:more');

    this.isMetaPaneOpen = !this.isMetaPaneOpen;
    this._setMoreButtonAriaAttributes();
  }

  _setMoreButtonAriaAttributes() {
    const ariaLabel = this.isMetaPaneOpen
      ? I18n.t('discover.pageView.moreMenu.ariaLabel.hide')
      : I18n.t('discover.pageView.moreMenu.ariaLabel.show');

    this.ui.btnMore.attr({
      'aria-label': ariaLabel,
      'aria-pressed': this.isMetaPaneOpen
    });
  }
}

module.exports = TitleEditView;
