import type { SxProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

interface PlayIconProps {
  sxProps: SxProps
}

const PlayIcon: FC<PlayIconProps> = ({ sxProps }) => {
  return (
    <SvgIcon
      sx={ sxProps }
      viewBox='0 0 30 30'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 27c6.627 0 12-5.373 12-12S21.627 3 15 3 3 8.373 3 15s5.373 12 12 12Zm0 2c7.732 0 14-6.268 14-14S22.732 1 15 1 1 7.268 1 15s6.268 14 14 14Zm5.422-13.149a1 1 0 0 0 0-1.702l-7.897-4.872a1 1 0 0 0-1.525.851v9.744a1 1 0 0 0 1.525.85l7.897-4.871Z'
        fill='#fff'
      />
      <mask
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='1'
        y='1'
        width='28'
        height='28'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15 27c6.627 0 12-5.373 12-12S21.627 3 15 3 3 8.373 3 15s5.373 12 12 12Zm0 2c7.732 0 14-6.268 14-14S22.732 1 15 1 1 7.268 1 15s6.268 14 14 14Zm5.422-13.149a1 1 0 0 0 0-1.702l-7.897-4.872a1 1 0 0 0-1.525.851v9.744a1 1 0 0 0 1.525.85l7.897-4.871Z'
          fill='#fff'
        />
      </mask>
    </SvgIcon>
  );
};

export default PlayIcon;
