const logging = require('logging');
const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const I18n = require('@common/libs/I18n');
const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');
const { combineUrlPaths } = require('@common/libs/helpers/app/UrlHelpers');

const TaskCompletionRecord = require('@training/apps/legacyTasks/models/TaskCompletionRecord');
const TaskIndexLayoutView = require('@training/apps/legacyTasks/views/TaskIndexLayoutView');
const TaskDetailLayoutView = require('@training/apps/legacyTasks/views/taskDetail/TaskDetailLayoutView');

const ActionBarButtonFactory = require('@common/components/actionBarButton/ActionBarButtonFactory');
const {
  TaskIndexState,
  getTaskActionBarDetails
} = require('@training/apps/legacyTasks/TaskIndexHelpers');

class TaskIndex {
  constructor() {
    this.showDetailView = this.showDetailView.bind(this);
    this._activeTimelogReference = null;
    this._state = new TaskIndexState();
  }

  showDetailView({
    taskId,
    programId,
    backButtonCallback = () => {
      window.history.back();
    },
    taskCompletionRecordToggledCallback,
    routePrefix = '',
    navigate = true
  } = {}) {
    this._state.update({
      detailViewBackButtonCallback: backButtonCallback,
      taskCompletionRecordToggledCallback,
      routePrefix
    });

    // `toggleTaskCompletionRecordCallback` is an option that can be passed in, for example in the Guided Learning case
    // where we would like to display the "Result page" after completing a Task.
    const newTaskCompletionRecordToggledCallback = () => {
      const detailBackFn = this._state.getBackButtonCallbackForDetailView();

      if (detailBackFn) {
        detailBackFn();
      }
    };

    const taskCompletionRecord = new TaskCompletionRecord({
      task: {
        id: taskId
      },
      program: {
        id: programId
      }
    });

    const rootView = this._getResetRootLayout();

    return taskCompletionRecord.fetch({skipGlobalHandler: true}).then(() => {
      if (rootView.isDestroyed) {
        return;
      }
      const taskDetailView = new TaskDetailLayoutView({
        model: taskCompletionRecord
      });

      window.apps.base.timeLogController.bindPageViewLog(taskDetailView, 'TaskDetailsPage', taskId);

      rootView.showInContentRegion(taskDetailView);
      rootView.showDetailHeader();
      if (taskCompletionRecord.isActionable()) {
        const actionSettings = this._getTaskActionBarSettings(
          taskCompletionRecord,
          this._getToggleTaskCompletionRecordFn(taskCompletionRecord, programId, taskCompletionRecordToggledCallback || newTaskCompletionRecordToggledCallback)
        );
        rootView.registerActionBar(actionSettings);
      }

      this._setupAppLayoutHeader(taskDetailView);

      if (navigate) {
        this._updateRoute(`taskDetails/${ programId }-${ taskId }`);
      }
    }, (error) => {
      logging.error(error);
      window.app.layout.flash.error(I18n.t('tasks.details.loadingError'));
      backButtonCallback();
    });
  }

  _getToggleTaskCompletionRecordFn(taskCompletionRecord, programId, taskCompletionRecordToggledCallback) {
    return () => {
      taskCompletionRecord.toggleCompletion(programId)
        .then(() => {
          taskCompletionRecordToggledCallback(taskCompletionRecord);
        }, (xhr) => {
          const exception = AxonifyExceptionFactory.fromResponse(xhr);
          const errCode = exception.getErrorCode();

          logging.error(`TaskIndex - Failed to toggle task completion: ${ errCode }`);

          if (errCode === AxonifyExceptionCode.CLIENT_ERROR_ON_THE_CLOCK_REQUIRED) {
            xhr.skipGlobalHandler = true;
            const {
              string,
              timeout
            } = AxonifyExceptionCode.onTheClockRequiredErrorFlashOptions();
            window.app.layout.flash.error(string, timeout);
          } else {
            window.app.layout.flash.error(I18n.t('tasks.details.toggleCompletionError'));
          }
        });
    };
  }

  _getOnSelectTaskFn() {
    const routePrefix = this._state.getRoutePrefix();
    const taskCompletionRecordToggledCallback = this._state.getTaskCompletionRecordToggledCallback();
    return (taskId, programId) => {
      this.showDetailView({
        taskId,
        programId,
        routePrefix,
        taskCompletionRecordToggledCallback
      });
    };
  }

  _getTaskActionBarSettings(taskCompletionRecord, toggleTaskCompletionRecordFn) {
    const doesNeedCompletion = taskCompletionRecord.doesRequireCompletion();
    const {
      completeStringKey,
      completeIcon
    } = getTaskActionBarDetails(doesNeedCompletion);
    const toggleCompletionButton = ActionBarButtonFactory.createButtonWithTextAndIcon(
      I18n.t(completeStringKey),
      completeIcon,
      toggleTaskCompletionRecordFn
    );
    const actionBarSettings = {
      buttons: [toggleCompletionButton]
    };

    return actionBarSettings;
  }

  _getResetRootLayout() {
    const taskLayoutView = new TaskIndexLayoutView();
    window.app.layout.setView(taskLayoutView, UIKit.View.Transitions.NONE);
    return taskLayoutView;
  }

  _setupAppLayoutHeader(view) {
    ViewHelpers.showBackButtonWithReset({view});
  }

  _updateRoute(route, options) {
    const routePrefix = this._state.getRoutePrefix();
    const navRoute = combineUrlPaths(routePrefix, route);
    Backbone.history.navigate(navRoute, options);
  }
}

module.exports = TaskIndex;
