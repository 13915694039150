const PageableCollection = require('@common/components/pageableList/PageableCollection');
const Page = require('@common/components/discover/models/Page');
const PageType = require('@common/components/discover/enums/PageType');
const PostModel = require('@common/components/discover/models/PostModel');

const TagBarFacetList = require('@common/components/discover/collections/TagBarFacetList');

class TimeLineCollection extends PageableCollection {
  initialize(models, options = {}) {
    super.initialize(models, options);
    this.state.pageSize = 12;
    this.filter = options.filter;
  }

  parse(res) {
    this.facetList = new TagBarFacetList(res.facets.tags);
    this.state.totalRecords = res.totalSearchResults;
    this.state.totalPages = Math.ceil(res.totalSearchResults / this.state.pageSize);
    this.state.lastPage = this.state.totalPages - 1;
    return super.parse(res);
  }

  apiEndpoint() {
    if (this.searchQuery !== '') {
      return `/pages/search`;
    } else if (this.extraParams.communityId) {
      return `/pages/community/${ this.extraParams.communityId }`;
    }
    return '/pages/timeline';
  }

  model(attr, options) {
    switch (attr.type) {
      case PageType.POST:
        return new PostModel(attr, options);
      default:
        return new Page(attr, options);
    }
  }

  search(searchQuery, pageNum) {
    this.searchQuery = searchQuery || '';

    return this.getPage(pageNum || 0);
  }

  fetch(options = {}) {
    const data = {};

    if (this.filter) {
      data.filter = this.filter;
    }

    if (this.searchQuery === '') {
      options.type = 'GET';
    } else {
      options.type = 'POST';
      data.query = this.searchQuery;
    }

    return super.fetch($.extend(true, { data }, options));
  }
}

module.exports = TimeLineCollection;
