const { LayoutView } = require('Marionette');

class ReportsPageIndexLayout extends LayoutView {
  className() {
    return 'reports-view parent-height';
  }

  getTemplate() {
    return `
    <div id="content-section" class="row-content">
      <div id="js-container" class="ax-container">
        <div class="page-content">
          <div class="ax-grid ax-grid--align-items-center report-header-wrapper">
            <div class="breadcrumb-title-wrapper reports-breadcrumb" role="navigation">
              <div class="ax-grid ax-grid--no-gutter ax-grid--align-items-center">
                <div class="breadcrumb-path ax-grid__col--auto-size">
                  <div class="header-breadcrumbs"></div>
                </div>
                <div class="title-region ax-grid__col--auto-size"></div>
              </div>
            </div>
            <div class="region-filters reports-filters clearfix ax-grid__col--12 ax-grid__col--m-auto-size">
              <div class="ax-grid ax-grid--no-gutter">
                <div class="region-filters-community ax-grid__col--12 ax-grid__col--m-auto-size"></div>
                <div class="region-filters-time ax-grid__col--12 ax-grid__col--m-auto-size"></div>
              </div>
            </div>
          </div>
          <div id="region-viewport">
            <div class="top-pages"></div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  regions() {
    return {
      breadcrumbRegion: '.header-breadcrumbs',
      titleRegion: '.title-region',
      time: '.region-filters-time',
      community: '.region-filters-community',
      topPages: '.top-pages'
    };
  }
}

module.exports = ReportsPageIndexLayout;
