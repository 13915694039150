const { Collection } = require('Backbone');
const ButtonGroupView = require('@common/components/buttonGroup/ButtonGroupView');

/**
 * ButtonGroupRadioView
 * Useful when you need to display a group of buttons that act like radio form group but you don't want to use radio buttons
 */


class ButtonGroupRadioView extends ButtonGroupView {
  initialize(options = {}) {
    super.initialize(options);

    this.collection = options.collection || new Collection(options.buttonsConfig || []);
  }

  childEvents() {
    return {
      'button:click': (view, value) => {
        const previousActive = this.collection.findWhere({isActive: true});
        if (previousActive) {
          previousActive.set('isActive', false);
        }

        view.model.set('isActive', true);

        this.trigger('buttonGroup:click', value);
      }
    };
  }
}

module.exports = ButtonGroupRadioView;
