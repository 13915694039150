const I18n = require('@common/libs/I18n');

module.exports = (assessment) => {
  const activity = assessment.activities.nextActivity();

  if (assessment.isDailyTraining()) {
    return I18n.t('start.assessmentType.DailyTraining');
  } else if (assessment.isFormalExamTraining()) {
    return I18n.t('start.assessmentType.FormalExamTraining');
  } else if (activity != null) {
    if (activity.isTrainingContentActivity()) {
      return activity.getModuleName();
    }
    const topicName = activity.getTopicName();
    const level = activity.getTopicLevel();

    return `${ topicName } - ${ I18n.t('knowledge.level', { level }) }`;

  }
  return I18n.t(`start.assessmentType.${ assessment.getType() }`);

};
