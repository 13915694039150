const Marionette = require('Marionette');

require('jquery.dotdotdot');

const DEFAULT_ARIA_HEADER_LEVEL = 3;

class ObjectiveCardLayoutView extends Marionette.LayoutView {
  getTemplate() {
    return `
      <div class="js-graphic-region learning-objective-card__graphic-wrapper"></div>
      <div class="learning-objective-card__content-wrapper">
        <div class="learning-objective-card__title-tag-wrapper">
          <% if (parentName) { %>
            <div class="js-parent-title learning-objective-card__parent-title"><%= parentName %></div>
          <% } %>
          <div role="heading" aria-level="<%= ariaHeaderLevel %>" class="js-title learning-objective-card__title"><%= name %></div>
          <div class="js-tag-region learning-objective-card__tag-wrapper"></div>
        </div>
        <div class="learning-objective-card__meta-action-wrapper">
          <div class="js-meta-data-region learning-objective-card__meta-data-wrapper"></div>
          <div class="js-action-region-mobile learning-objective-card__action-wrapper learning-objective-card__action-wrapper--mobile"></div>
        </div>
      </div>
      <div class="qa-action-region js-action-region learning-objective-card__action-wrapper"></div>
    `;
  }

  className() {
    const interactiveCssClass = this.getOption('actionCallback') && !this.getOption('isDisabled')
      ? 'learning-objective-card--interactive' : '';
    const warningCssClass = this.getOption('isOverdue') || this.getOption('isFailed') ? 'learning-objective-card--overdue' : '';

    return `learning-objective-card qa-learning-objective-card ${ interactiveCssClass } ${ warningCssClass }`;
  }

  ui() {
    return {
      title: '.js-title'
    };
  }

  events() {
    return {
      click: '_onClick'
    };
  }

  regions() {
    return {
      graphicRegion: '.js-graphic-region',
      tagRegion: '.js-tag-region',
      metaDataRegion: '.js-meta-data-region',
      actionRegion: '.js-action-region',
      actionRegionMobile: '.js-action-region-mobile'
    };
  }

  templateHelpers() {
    return {
      name: this.getOption('name'),
      parentName: this.getOption('parentName'),
      ariaHeaderLevel: this.getOption('ariaHeaderLevel') || DEFAULT_ARIA_HEADER_LEVEL
    };
  }

  onAttach() {
    // add ellipses for long titles
    this.ui.title.dotdotdot({
      height: 40
    });
  }

  _onClick() {
    if (this.getOption('isDisabled')) {
      return;
    }

    const actionCallback = this.getOption('actionCallback');

    if (actionCallback) {
      actionCallback();
    }
  }
}

module.exports = ObjectiveCardLayoutView;
