const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');

class NoSearchResultsView extends ItemView {
  initialize(options = {}) {
    super.initialize(options);
    ({
      searchString: this.searchString,
      searchCategory: this.searchCategory // optional argument
    } = options);

    const noneAvailableMessages = {
      [SearchCategoryEnum.ALL]: I18n.t('selfDirected.search.noContentAvailable'),
      [SearchCategoryEnum.ARTICLES]: I18n.t('selfDirected.search.noArticlesAvailable'),
      [SearchCategoryEnum.POSTS]: I18n.t('selfDirected.search.noPostsAvailable'),
      [SearchCategoryEnum.TIMELINE]: I18n.t('selfDirected.search.noItemsAvailableGeneric')
    };

    this.noneAvailableMessage = noneAvailableMessages[this.searchCategory] || noneAvailableMessages['articles'];
  }

  tagName() {
    return 'li';
  }

  className() {
    return 'empty-search-results empty-search-padding';
  }

  attributes() {
    //alerts screenreader users that search results are empty
    return {
      'aria-live': 'assertive'
    };
  }

  getTemplate() {
    return this.searchString === '' ? this.noneAvailableMessage : I18n.t('selfDirected.search.noResults', {searchString: this.searchString});
  }
}

class NotAvailableView extends ItemView {
  tagName() {
    return 'li';
  }

  className() {
    return 'not-available';
  }

  getTemplate() {
    return `
      <div role="img" aria-hidden="true" class="placeholder-search-image"></div>
      <p class="not-available__prompt">${ I18n.t('selfDirected.search.notAvailable.primaryMessage') }</p>
      <p class="not-available__prompt not-available__prompt--small">${ I18n.t('selfDirected.search.notAvailable.secondaryMessage') }</p>
    `;
  }

  onRender() {
    this.$el.toggleClass('hidden', this.searchString === '');
  }
}

class SearchPromptView extends ItemView {
  initialize(options = {}) {
    super.initialize(options);
    ({
      searchPageState: this.searchPageState
    } = options);
  }

  className() {
    return 'no-search';
  }

  getTemplate() {
    return `
      <div role="img" aria-hidden="true" class="placeholder-search-image"></div>
      <p class="no-search__prompt"><%= message %></p>
    `;
  }

  templateHelpers() {
    return {
      message: this._getMessage()
    };
  }

  _getMessage() {
    if (this.searchPageState.get('searchCategory') === SearchCategoryEnum.ALL) {
      return I18n.t('selfDirected.search.promptType', { type: '' });
    }
    return I18n.t('selfDirected.search.promptType', {
      type: I18n.t(`selfDirected.search.categoryNames.${ this.searchPageState.get('searchCategory').toLowerCase() }`)
    });

  }
}

module.exports = {
  NoSearchResultsView,
  NotAvailableView,
  SearchPromptView
};
