const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'SELF_DIRECTED_PATHS',
  'SELF_DIRECTED', // Deprecated and should not be used to create an assessment
  'TOPIC_SEARCH',
  'TOPIC_DETAILS',
  'START_PAGE',
  'GUIDED_LEARNING',
  'NEW_TOPIC',
  'REFRESHER',
  'CERTIFICATION',
  'EXTRA_TRAINING',
  'DEEP_LINK',
  'EXAM',
  'REPORT_CARD'
]);
