const Backbone = require('Backbone');

const Sync = require('@common/libs/Sync');

const File = require('@common/libs/file/File');

class MediaModel extends Backbone.Model {

  get idAttribute() {
    return 'uuid';
  }

  urlRoot() {
    return '/axonify/media';
  }

  getFile() {
    return this.get('originalFile');
  }

  parse(res) {
    res.originalFile = this._parseOriginalFile(res.originalFile);
    return res;
  }

  uploadInProgress() {
    return this.isXHRPending(Sync.Method.CREATE);
  }

  uploadFailed() {
    return this.isXHRFailed(Sync.Method.CREATE);
  }

  isProcessed() {
    return false;
  }

  _parseOriginalFile(originalFile = {}) {
    //XXX If the MediaModal already has a file with a fileInput, don't accept the one from the server.
    if (this.getFile() instanceof File) {
      return this.getFile();
    }
    return originalFile;

  }
}

module.exports = MediaModel;
