// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-rewards" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('usermessage.prizeLoser.title') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="user-messaging winner">
      <div class="broadcastmessage">
        <h2><%- t('usermessage.prizeWinner.' + gameType) %></h2>
        <% if (hasMedia) { %>
        <div class="media">
          <img src="<%- r(media.download) %>" alt="<%- name %>"/>
        </div>
        <% } %>
        <div class="broadcast">
          <h3><%- name %></h3>
          <p class="fulfillment"><%- fulfillment %></p>
        </div>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;