class NextStartableAssessmentProvider {
  canProduceNext() {
    throw new Error('There was nothing implemented here -- this is an abstract class meant to be extended');
  }

  getNext() {
    throw new Error('There was nothing implemented here -- this is an abstract class meant to be extended');
  }
}

module.exports = NextStartableAssessmentProvider;
