const _ = require('underscore');

const Enum = require('@common/data/enums/Enum');
const FilterableAttributesEnum = require('@common/data/enums/FilterableAttributesEnum');

const GroupType = Enum({
  TrainingGroup: 0,
  ReportFilterGroup: 1,
  AuthGroup: 2,
  CourseGroup: 3,
  TeamFilter: 4
}, {
  getGroupEndpointByType(type) {
    if (type === this.ReportFilterGroup) {
      return '/reportFilters';
    } else if (type === this.AuthGroup) {
      return '/reportAuthGroups';
    } else if ([this.TrainingGroup, this.CourseGroup].includes(type)) {
      return '/trainingGroups';
    } else if (type === this.TeamFilter) {
      return '/locations';
    }

    throw new Error(`Invalid Group Type (${ type })`);
  },

  createTeamFilter(entity, filterableAttribute) {
    FilterableAttributesEnum.assertLegalValue(filterableAttribute);

    return Object.assign({}, entity, {
      type: GroupType.TeamFilter,
      [filterableAttribute]: [
        _.pick(entity, 'id')
      ]
    });
  }
});

module.exports = GroupType;
