const { LayoutView } = require('Marionette');

class MultiselectCommunityActionView extends LayoutView {
  className() {
    return 'filter-actions-wrapper';
  }

  getTemplate() {
    return `
      <button class="ax-button filter-actions__button--apply js-community-filter__apply"><%= t('general.apply') %></button>
      <button class="ax-button filter-actions__button--cancel js-community-filter__cancel"><%= t('general.cancel') %></button>
    `;
  }

  triggers() {
    return {
      'click .js-community-filter__apply': 'click:apply',
      'click .js-community-filter__cancel': 'click:cancel',
      'blur .js-community-filter__cancel': 'blur:cancel'
    };
  }

  ui() {
    return {
      apply: '.js-community-filter__apply'
    };
  }

  isApplyFocused() {
    return this.ui.apply.is(':focus');
  }
}

module.exports = MultiselectCommunityActionView;
