import {
  Stack as MuiStack,
  styled,
  type StackTypeMap as MuiStackTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxStackCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxStackTypeMap<P = object, D extends React.ElementType = 'div'> = {
  props: P & AxStackCustomProps & Omit<MuiStackTypeMap['props'], 'gap'>;
  defaultComponent: D;
};

export type AxStackProps<
  D extends ElementType = AxStackTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxStackTypeMap<P & AxStackCustomProps, D>, D>;

const styledOptions = {
  name: 'AxStack'
};

const StyledAxStack = styled(MuiStack, styledOptions)<AxStackProps>(() => {
  return {};
});

export const AxStack: MuiOverridableComponent<AxStackTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxStackProps, ref: Ref<unknown>) => {
  const StackProps: AxStackProps = {
    children,
    ...otherProps
  };
  
  return (
    <StyledAxStack { ...StackProps } ref={ ref }>
      { children }
    </StyledAxStack>
  );
});

export default AxStack;
