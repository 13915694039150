const { Collection } = require('Backbone');
const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const Form = require('@common/components/forms/Form');
const GroupAccessLayout = require('@training/apps/search/communitiesManagement/accessSettings/GroupAccessLayout');
const TeamAccessLayout = require('@training/apps/search/communitiesManagement/accessSettings/TeamAccessLayout');
const CommunityAccessType = require('@training/apps/training/enums/CommunityAccessType');

require('@common/components/forms/editors/radioGroup/Form.Editor.RadioGroup');

class AccessSettingsTabView extends LayoutView {
  initialize(options = {}) {
    ({
      groupList: this.groupList,
      teamsList: this.teamsList
    } = options);

    this.isCommsEnabled = TenantPropertyProvider.get().getProperty('communicationsEnabled');
  }

  className() {
    return 'access-settings-form';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/accessSettings/AccessSettingsTabView.html');
  }

  ui() {
    return {
      communityAccessTypeToggle: '.js-community-type-toggle'
    };
  }

  templateHelpers() {
    return {
      isCommsEnabled: this.isCommsEnabled
    };
  }

  regions() {
    return {
      tableRegion: '.js-table-region'
    };
  }

  onRender() {
    if (this.isCommsEnabled) {
      this._setupCommunityTypeForm();
    }

    this._renderTable(this.model.get('communityAccessType'));
  }

  _setupCommunityTypeForm() {
    const communityAccessTypeCollection = new Collection([
      {
        id: CommunityAccessType.GROUP,
        ariaLabelledby: 'group-type-extra-info',
        label: I18n.t('communitiesManagement.community.groupType')
      },
      {
        id: CommunityAccessType.TEAM,
        ariaLabelledby: 'team-type-extra-info',
        label: I18n.t('communitiesManagement.community.teamType')
      }
    ]);

    const communityAccessTypeForm = new Form({
      el: this.ui.communityAccessTypeToggle,
      model: this.model,
      context: {
        communityAccessTypes: communityAccessTypeCollection
      }
    });

    this.listenTo(communityAccessTypeForm, 'change:communityAccessType', (form, editor) => {
      this._renderTable(editor.getValue());
    });
  }

  _renderTable(communityAccessType) {
    if (communityAccessType === CommunityAccessType.TEAM) {
      this._renderTeamTable();
    } else {
      this._renderGroupTable();
    }
  }

  _renderGroupTable() {
    this.tableRegion.show(new GroupAccessLayout({
      groupList: this.groupList,
      model: this.model
    }));
  }

  _renderTeamTable() {
    this.tableRegion.show(new TeamAccessLayout({
      model: this.model,
      teamsList: this.teamsList
    }));
  }
}

module.exports = AccessSettingsTabView;
