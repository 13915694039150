const { ItemView } = require('Marionette');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const DiscoverTrainingCardBottomRegionLayoutView = require('@common/components/trainingCards/DiscoverTrainingCardBottomRegionLayoutView');
const DiscoverTrainingCardBottomInfoView = require('@common/components/trainingCards/DiscoverTrainingCardBottomInfoView');

const getBottomInfoRegionConfig = function({
  actionCallback
} = {}) {
  return {
    viewControllerClass: ItemView,
    viewDefinition: {
      ViewClass: DiscoverTrainingCardBottomInfoView,
      actionCallback
    }
  };
};

module.exports = {
  getDiscoverCardBottomRegionConfig: ({
    actionCallback
  } = {}) => {
    const controllerDefinition = {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: DiscoverTrainingCardBottomRegionLayoutView
      },
      regionControllers: {
        infoRegion: getBottomInfoRegionConfig({
          actionCallback
        })
      }
    };

    return controllerDefinition;
  }
};
