const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'DetailsPage',
  'TaskDetailsPage',
  'EventSessionsPage',
  'LearningEventDetailsPage',
  'TopicDetailsPage',
  'EvaluationDetailsPage'
]);
