const _ = require('underscore');

// A basic tree formatter that has very limited functionality -- only returns
// the bare name of the object according to some property, defaulting to
// 'name'
class TreeNodeFormatter {
  constructor(options = {}) {
    this.formatNodeElement = this.formatNodeElement.bind(this);
    this._labelProperty = options.labelProperty != null ? options.labelProperty : 'name';
  }

  // Return an HTML string representing the formatted data
  formatNodeElement(treeNode) {
    let value;
    const context = treeNode.data.model;

    if (_.isFunction(this._labelProperty)) {
      value = this._labelProperty(context);
    } else {
      value = context[this._labelProperty];
    }

    if (value != null) {
      return value;
    }
    throw new Error(`The given property ${ this._labelProperty } could not be found on the provided node given it's context.`);

  }

  /**
   * You can override this to return the icon the node should have in your own formatter.
   *
   * You can return undefined to specify that there should be no icon provided.
   */
  getIconForNode() {
    return undefined;
  }
}

module.exports = TreeNodeFormatter;
