const { LayoutView } = require('Marionette');
const UIKit = require('@training/widgets/UIKit');
const Backbone = require('Backbone');
const KeyCode = require('@common/data/enums/KeyCode');

class TeamFilterView extends LayoutView {
  initialize(options = {}) {
    this.filterModel = new Backbone.Model({teamFilter: options.value});
    this.listenTo(this.filterModel, 'change', () => {
      this.trigger('changeTeamBasedSelect', this.filterModel.get('teamFilter'));
    });
  }

  className() {
    return 'ax-grid ax-grid--no-gutter';
  }

  getTemplate() {
    return require('@training/apps/training/communitiesInsights/components/TeamFilterTemplate.html');
  }

  ui() {
    return {
      checkbox: '.js-team-based-checkbox',
      form: 'form'
    };
  }

  events() {
    return {
      'keypress @ui.form': 'onKeypress'
    };
  }

  onKeypress(e) {
    if (e.which === KeyCode.ENTER) {
      this.teamfilter.setModelValue({teamFilter: !this.teamfilter.model.get('teamFilter')});
    }
  }

  onRender() {
    this.teamfilter = new UIKit.Form({
      el: this.ui.form,
      model: this.filterModel
    });
  }

  reset() {
    this.ui.checkbox.prop('checked', false);
    this.filterModel.set('teamFilter', false, { silent: true });
  }
}

module.exports = TeamFilterView;
