const { ItemView } = require('Marionette');

class SubtextHeaderTitle extends ItemView {
  className() {
    return 'subtext-header-title qa-subtext';
  }

  getTemplate() {
    return `
      <<%= titleTag %> class="<%= titleClass %>"><%= title %></<%= titleTag %>>
      <p class="<%= subtextClass %> qa-subtext-text"><%= subtext %></p>
    `;
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      titleClass: this._getTitleClass(),
      titleTag: this.getOption('titleTag') || 'h4',
      subtext: this.getOption('subtext'),
      subtextClass: this.getOption('subtextClass')
    };
  }

  _getTitleClass() {
    let titleClass = 'qa-subtext-title';
    if (this.getOption('titleClass')) {
      titleClass += ` ${ this.getOption('titleClass') }`;
    }
    return titleClass;
  }
}

module.exports = SubtextHeaderTitle;
