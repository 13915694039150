const Backbone = require('Backbone');
const User = require('@common/data/models/AuthUser');
class GameChallengesUsersList extends Backbone.Collection {
  get model() {
    return User;
  }

  apiEndpoint() {
    return '/games/challenges/users';
  }

  parse(response = {}) {
    return response.results || [];
  }

  initialize() {
    this.params = {
      p: 0,
      rpp: 10
    };
  }

  fetch(options = {}) {
    options = $.extend(true, {}, options, {data: this.params});

    return super.fetch(options);
  }

  search(query) {
    this.params.search = query;
    return this.fetch();
  }

  clearSearch() {
    delete this.params.search;
    return this.fetch();
  }
}
module.exports = GameChallengesUsersList;
