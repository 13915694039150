import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitCard(cssVars: CSSStyleDeclaration) {
  return {
    cardBorder: getCssValue('--axCard-border', cssVars),
    cardBorderRadius: getCssValue('--axCard-border-radius', cssVars),
    cardBoxShadowInformational: getCssValue('--axCard-box-shadow-informational', cssVars),
    cardBoxShadow: getCssValue('--axCard-box-shadow-default', cssVars),
    cardBoxShadowInteractiveHover: getCssValue('--axCard-box-shadow-default-hover', cssVars),
    cardInteractiveTopHover: getCssValue('--axCard-interactive-top-hover', cssVars),
    cardInteractiveTransition: getCssValue('--axCard-interactive-transition', cssVars),
    cardSpacingL: getCssValue('--axCard-spacing-l', cssVars),
    cardSpacingM: getCssValue('--axCard-spacing-m', cssVars),
    cardSpacingS: getCssValue('--axCard-spacing-s', cssVars)
  };
}
