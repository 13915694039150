class LrsConfiguration {
  constructor(tenantName, actor) {
    this._tenantName = tenantName;
    this._actor = actor;

    if (actor == null) {
      throw new Error('You must provide an actor.');
    }
    if (tenantName == null) {
      throw new Error('You must provide an endpoint.');
    }
  }

  getEndpoint() {
    // You need to use the tenant name because tenant aliases exist. The domain name is not a good enough
    // proxy for what needs to happen.
    return `https://${ this._tenantName }.axonify.com/axonify/xapi/`;
  }

  getLrsVersion() {
    return '1.0.0';
  }

  getActorName() {
    return this._actor.name;
  }

  getActorBox() {
    return `mailto://${ this._actor.id }@${ this._tenantName }.axonify.com`;
  }

  getActivityIdFor(tincanPackage) {
    return tincanPackage.activityId;
  }
}

module.exports = LrsConfiguration;
