const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');
const LearningEventsHelpers = require('@common/libs/helpers/app/LearningEventsHelpers');

const User = require('@common/data/models/AuthUser');

class LearningEventAttendeesList extends InfiniteCollection {
  get model() {
    return User;
  }

  apiEndpoint() {
    return `/learning-event-enrollments/${ this.eventId }/${ this.scheduledEventId }/all/Enrolled`;
  }

  constructor(models, optionsArg = {}) {
    const options = Object.assign({}, {
      state: {
        pageSize: optionsArg.pageSize
      },
      fetchType: 'GET'
    }, optionsArg);

    super(models, options);

    ({
      eventId: this.eventId,
      scheduledEventId: this.scheduledEventId
    } = options);
  }

  sync(method, model, options = {}) {
    if (!options.data) {
      options.data = {};
    }
    options.data.learnerAccessOnly = true;
    super.sync(method, model, options);
  }

  parse(res) {
    this.listCount = res.count;
    this.totalCount = res.totalCount;
    return res.enrollments.map((enrollment) => {
      return LearningEventsHelpers.getEnrollmentUserObj(enrollment);
    });
  }
}
module.exports = LearningEventAttendeesList;
