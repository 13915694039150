import {
  SvgIcon,
  type SxProps
} from '@mui/material';
import type { FC } from 'react';

interface LinkIconProps {
  sxProps: SxProps
}

const LinkIcon: FC<LinkIconProps> = ({ sxProps }) => {
  return (
    <SvgIcon
      sx={ sxProps }
      viewBox='0 0 30 30'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 27c6.627 0 12-5.373 12-12 0-1.773-.385-3.457-1.075-4.972.448 1.208.575 1.972.575 1.972l-1 1v2l-1 1.5V18l-1 2-2 2-1-2 .5-1v-3l-1-1h-2.5L16 13l.5-2 1-1.5h2l1 1H22l1.5-.5L22 8.5l-2-1-2.5 1v-1l2-1v-1s1.268-.254 2.54-.219A11.946 11.946 0 0 0 15 3c-2.39 0-4.618.7-6.49 1.904L10 5.5h3V7L9.5 8.5 8 11l-2-.5-.5 1.5 1 .5 1 2L5 13s-.733-1.284-.979-2.851A11.958 11.958 0 0 0 3 15c0 6.627 5.373 12 12 12Zm0 2c7.732 0 14-6.268 14-14S22.732 1 15 1 1 7.268 1 15s6.268 14 14 14ZM11 4.5l2-1V5l-2-.5ZM8.5 14l2.5.5 1.5 2 2 .5v1.5l-1 1.5-1 1.5-.5 1-1 .5.5 1.5L10 24l-.5-3v-1.75l-2-.75L7 16l1.5-2Z'
      />
    </SvgIcon>
  );
};

export default LinkIcon;
