const LegacyUIKitView = require('@common/components/view/LegacyUIKitView');
const dateHelpers = require('@common/libs/dateHelpers');
const logging = require('logging');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');

//Note: Months are from 0-11 in this class
class MonthYearPicker extends LegacyUIKitView {

  events() {
    return {
      'change #yearPicker': '_onYearChange',
      'change #monthPicker': '_onMonthChange',
      'click #prevButton:not(.button-disabled)': '_onPrevClick',
      'click #nextButton:not(.button-disabled)': '_onNextClick'
    };
  }

  constructor(options = {}) {
    super(options);

    this.el = options.el;
    this.currentDate = dateHelpers.getCurrentDayMonthYear();
    this.date = {
      month: (options.currentDate != null ? options.currentDate.month : undefined) || (this.currentDate.currentMonth - 1),
      year: (options.currentDate != null ? options.currentDate.year : undefined) || this.currentDate.currentYear
    };
    this.onDateChangeCallback = options.onDateChanged;

    this.monthNames = dateHelpers.getMonthNames();
    this.maxYear = options.maxYear || this.date.year;
    this.maxMonth = options.maxMonth || this.date.month;
    this.minYear = options.minYear || 0;

    this.formTemplate = _.tpl(require('./MonthYearPicker.html'));
    this.dateOptionTemplate = _.tpl('<option value="<%= dateItemValue %>"><%- dateItem %></option>');
  }

  renderMonthOptions() {
    const $monthPicker = this.$('#monthPicker');
    $monthPicker.empty();
    let lastMonth = 11;
    if (this.maxYear === this.date.year) {
      lastMonth = this.maxMonth;
    }
    for (let i = 0; i <= lastMonth; i++) {
      const optionItem = this.dateOptionTemplate({
        dateItem: this.monthNames[i],
        dateItemValue: i
      });
      $monthPicker.append(optionItem);
    }
    $monthPicker.val(this.date.month);
  }

  renderYearOptions() {
    const $yearPicker = this.$('#yearPicker');
    $yearPicker.empty();
    const minYearOption = ((this.date.year - 10) < this.minYear) ? this.minYear : this.date.year - 10;
    const maxYearOption = ((this.date.year + 10) < this.maxYear) ? this.date.year + 10 : this.maxYear;
    for (let i = minYearOption; i <= maxYearOption; i++) {
      const optionItem = this.dateOptionTemplate({
        dateItem: i,
        dateItemValue: i
      });
      $yearPicker.append(optionItem);
    }
    $yearPicker.val(this.date.year);
  }

  _validateMonth(month, year) {
    let newMonth = month;

    if ((year === this.maxYear) && (newMonth >= this.maxMonth)) {
      return this.maxMonth;
    } else if (newMonth > 11) {
      newMonth = 11;
    } else if (newMonth < 0) {
      newMonth = 0;
    }

    return newMonth;
  }

  _validateYear(year) {
    let newYear = year;

    logging.info(`MonthYearPicker - Going to validate ${ year } year with a max of ${ this.maxYear } and a min of ${ this.minYear }`);
    if (newYear > this.maxYear) {
      return this.maxYear;
    } else if (newYear < this.minYear) {
      newYear = this.minYear;
    }

    return newYear;
  }

  _onMonthChange(event) {
    const val = $(event.currentTarget).val();
    const month = parseInt(val, 10);
    logging.info(`MonthYearPicker - Month has changed, requesting a change to ${ val }`);

    this.setDate({
      month,
      year: this.date.year
    });
  }

  _onYearChange(event) {
    const val = $(event.currentTarget).val();
    const year = parseInt(val, 10);
    logging.info(`MonthYearPicker - Year has changed, requesting a change due to value of ${ val }`);

    this.setDate({
      month: this.date.month,
      year
    });
  }

  _onNextClick() {
    logging.info('MonthYearPicker - The user requested to click next.');
    if ((this.date.month === this.maxMonth) && (this.date.year === this.maxYear)) {
      return;
    }
    let newMonth = this.date.month + 1;
    if (newMonth >= 12) {
      newMonth = 0;
      const newYear = this.date.year + 1;
      this.setDate({
        month: newMonth,
        year: newYear
      });
    } else {
      this.setDate({
        month: newMonth,
        year: this.date.year
      });
    }
  }

  _onPrevClick() {
    logging.info('MonthYearPicker - The user requested to click previous.');

    if ((this.date.month === 0) && (this.date.year === this.minYear)) {
      return;
    }

    let newMonth = this.date.month - 1;
    if (newMonth < 0) {
      newMonth = 11;
      const newYear = this.date.year - 1;
      this.setDate({
        month: newMonth,
        year: newYear
      });
    } else {
      this.setDate({
        month: newMonth,
        year: this.date.year
      });
    }
  }

  setDate(newDate) {
    const newYear = this._validateYear(newDate.year);
    if (isNaN(newYear) || newYear == null) {
      logging.error(`MonthYearPicker - Trying to set an invalid year. ${ newDate.year }`);
      window.app.layout.flash.error(I18n.t('UIKit.Form.errors.date.invalid'));
      this.renderYearOptions();
      return;
    }

    const newMonth = this._validateMonth(newDate.month, newYear);
    if (isNaN(newMonth) || newMonth == null ) {
      logging.error(`MonthYearPicker - Trying to set an invalid month. ${ newDate.month }`);
      window.app.layout.flash.error(I18n.t('UIKit.Form.errors.date.invalid'));
      this.renderMonthOptions();
      return;
    }

    this.date.year = newYear;
    this.date.month = newMonth;
    this.renderYearOptions();
    this.renderMonthOptions();
    this.disableButtons();
    this.onDateChangeCallback(this.date);
  }

  disableButtons() {
    this.$('.direction-button').removeClass('button-disabled')
      .prop('disabled', false)
      .attr('tabindex', '0');
    if ((this.date.month === this.maxMonth) && (this.date.year === this.maxYear)) {
      this.$('.next-button').addClass('button-disabled')
        .prop('disabled', true)
        .attr('tabindex', '-1');
    } else if ((this.date.month === 0) && (this.date.year === this.minYear)) {
      this.$('.prev-button').addClass('button-disabled')
        .prop('disabled', true)
        .attr('tabindex', '-1');
    }
  }

  render() {
    this.el.html(this.formTemplate);
    this.renderMonthOptions();
    this.renderYearOptions();
    this.disableButtons();

    return this;
  }
}

module.exports = MonthYearPicker;
