import $os from 'detectOS';
import {
  Model,
  Collection
} from 'backbone';
import {
  CollectionView,
  ItemView
} from 'Marionette';

function _generateCollection() {
  const MAX_STATS = $os.mobile ? 2 : 4;
  const models = [];
  for (let i = 0; i < MAX_STATS; i++) {
    models.push(new Model());
  }
  return new Collection(models);
}

class MainStatsSkeletonItemView extends ItemView {
  tagName() {
    return 'li';
  }

  className() {
    return 'stats__tile full-width';
  }

  getTemplate() {
    return `\
      <div class="stat-item stat-item--skeleton">
        <p class="stat-item__value"><span class="skeleton" style="width: 25%;"></span></p>
        <p class="stat-item__value"><span class="skeleton" style="width: 75%;"></span></p>
      </div>\
    `;
  }
}

export default function MainStatsSkeletonDefinition() {
  return {
    viewDefinition: {
      ViewClass: CollectionView,
      childView: MainStatsSkeletonItemView,
      collection: _generateCollection(),
      tagName: 'ul',
      className: 'stats__list full-width'
    }
  };
}
