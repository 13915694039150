const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const { LayoutView } = require('Marionette');

const { axFormSelectors } = require('@common/components/axForm/AxFormTemplateHelpers');

class TagsSearchView extends LayoutView {
  initialize(options = {}) {
    ({
      managementPageState: this.managementPageState,
      tagList: this.tagList
    } = options);

    this.searchData = this.managementPageState.get('searchData');

    this.listenTo(this.tagList, 'sync', this._updateTagsLabel);
    this.listenTo(this.tagList, 'update', this._updateTagsCount);
  }

  className() {
    return 'tags-search';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/tags/TagsSearchView.html');
  }

  templateHelpers() {
    return {
      searchQuery: this.searchData.get('searchString'),
      clearSearchClass: this.getClearSearchClass()
    };
  }

  events() {
    return {
      'keyup @ui.searchInput': _.debounce(_.bind(this.onSearch, this), 300),
      'focus @ui.searchInput': this.onSearchFocus,
      'blur @ui.searchInput': this.onSearchBlur,
      'click @ui.removeIcon': this.clearSearchString
    };
  }

  ui() {
    return {
      searchInputView: '.search-input-view',
      searchInput: axFormSelectors.input,
      removeIcon: '.js-remove',
      tagsCountText: '.tags-count-text'
    };
  }

  onShow() {
    this._updateTagsLabel();
  }

  getClearSearchClass() {
    return this.searchData.get('searchString') ? '' : 'hidden';
  }

  onSearchFocus() {
    this.ui.searchInputView.toggleClass('focused', true);
  }

  onSearchBlur() {
    this.ui.searchInputView.toggleClass('focused', false);
  }

  onSearch() {
    const searchString = this.ui.searchInput.val().trim();
    const previousSearch = this.searchData.get('searchString');

    if (searchString !== previousSearch) {
      this.updateSearch(searchString);

      if (searchString) {
        this.ui.removeIcon.toggleClass('hidden', false);
      } else {
        this.ui.removeIcon.toggleClass('hidden', true);
      }
    }
  }

  clearSearchString() {
    this.ui.searchInput.val('');
    this.ui.removeIcon.toggleClass('hidden', true);

    this.updateSearch();
  }

  updateSearch(searchString = '') {
    this.managementPageState.set({
      pageNum: 1
    });
    this.searchData.set('searchString', searchString);
    this.tagList.search(this.searchData, 0);
  }

  _updateFilter(filterName) {
    const searchString = this.ui.searchInput.val().trim();

    this.searchData.set(filterName, this.ui[filterName][0].checked);

    this.updateSearch(searchString);
  }

  _updateTagsCount() {
    this.tagList.state.totalRecords = this.tagList.state.totalRecords - 1;
    this._updateTagsLabel();
  }

  _updateTagsLabel() {
    this.ui.tagsCountText.text(I18n.t('communitiesManagement.tags.tagsCount', {
      currentTagsList: this.tagList.length,
      totalTags: this.tagList.state.totalRecords
    }));
  }
}

module.exports = TagsSearchView;
