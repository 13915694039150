const { intersection } = require('underscore');
const env = require('env');

const Marionette = require('Marionette');
const Backbone = require('Backbone');

const Form = require('@common/components/forms/Form');
const I18n = require('@common/libs/I18n');
const SupportHelper = require('@common/libs/helpers/features/SupportHelper');

const Language = require('@common/data/models/Language');

class RecoveryBaseView extends Marionette.LayoutView {
  getTemplate() {
    return require('./RecoveryBaseView.html');
  }

  templateHelpers() {
    return {
      showLanguageSelector: this.showLanguageSelector,
      helpContact: this._getHelpContact()
    };
  }

  events() {
    return {
      'click .cancel': this.onClickCancel
    };
  }

  regions() {
    return {
      recoveryRegion: '#recovery-region'
    };
  }

  initialize(options = {}) {
    ({
      tenantPropertyPool: this.tenantPropertyPool,
      navChannel: this.navChannel,
      languageStateModel: this._languageStateModel
    } = options);

    this.languageModel = new Backbone.Model({
      lang: I18n.getLocale()
    });

    if (!this._languageStateModel) {
      this._languageStateModel = new Backbone.Model({hasLanguageSelector: true});
    }

    this.supportedLanguages = intersection(
      this.tenantPropertyPool.getProperty('languages'), env.settings.supportedLocales
    );
    this.showLanguageSelector = (this.supportedLanguages.length > 1 && this._languageStateModel.get('hasLanguageSelector'));

    this.listenTo(this._languageStateModel, 'change', this.onLanguageStateModelChange);
  }

  onBeforeRender() {
    this.showLanguageSelector = (this.supportedLanguages.length > 1 && this._languageStateModel.get('hasLanguageSelector'));
  }

  onRender() {
    if (this.showLanguageSelector) {
      const languageList = new Backbone.Collection(this.supportedLanguages, { model: Language });

      this.languageForm = new Form({
        el: this.$('.language-form'),
        model: this.languageModel,
        context: {
          languages: {
            collection: languageList,
            axonSelectOptions: {
              useChosen: false
            }
          }
        }
      });

      this.languageForm.fields.lang.editor.on('change:selection', (formEditor) => {
        this.trigger('change:language', formEditor);
      });
    }
  }

  onClickCancel() {
    location.hash = '';
    this.navChannel.commands.execute('show:login', { loginPrompt: false });
  }

  onLanguageStateModelChange() {
    const showSelector = (this.showLanguageSelector && this._languageStateModel.get('hasLanguageSelector'));
    this.$el.find('.language-form').toggle(showSelector);
  }

  _getHelpContact() {
    const helpEmail = this.tenantPropertyPool.getProperty('helpEmail');
    const helpPhone = this.tenantPropertyPool.getProperty('helpPhone');

    return SupportHelper.getSupportHtml(helpEmail, helpPhone);
  }
}

module.exports = RecoveryBaseView;
