const PageableListViewController = require('@common/components/pageableList/PageableListViewController');
const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');

class PageableTableController extends PageableListViewController {
  initialize(options = {}) {
    ({
      tableViewClass: this.tableViewClass,
      headerNames: this.headerNames,
      headerClasses: this.headerClasses,
      noHeaderSpan: this.noHeaderSpan,
      rowItemView: this.rowItemView
    } = options);
  }

  _getListRegionViewDefinition() {
    return {
      viewDefinition: {
        ViewClass: this.tableViewClass || TableLayoutView,
        collection: this.collection,
        headerNames: this.headerNames,
        headerClasses: this.headerClasses,
        noHeaderSpan: this.noHeaderSpan,
        rowItemView: this.rowItemView,
        emptyViewClass: this.emptyViewClass,
        emptyViewOptions: this.emptyViewOptions,
        itemViewOptions: this.itemViewOptions,
        childViewOptions: this.itemViewOptions
      }
    };
  }
}

module.exports = PageableTableController;
