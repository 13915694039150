const { Model } = require('Backbone');
const TopicProgressEnum = require('@common/data/enums/TopicProgressEnum');
const TopicLevelActionType = require('@common/data/enums/TopicLevelActionType');
const AssessmentType = require('@common/data/enums/AssessmentType');

class TeamScoreModel extends Model {

  fetch(options = {}) {
    Object.assign(options, {url: `${ this.urlRoot() }/topicDetail/${ this.id }/userLocationScore`});
    return super.fetch(options);
  }
}

class TopicDetailsModel extends Model {

  apiEndpoint() {
    return '/topicDetail';
  }

  defaults() {
    return {
      description: ''
    };
  }

  parse(data) {
    if (data != null && data.topicLevels != null) {
      const startableTypes = [TopicProgressEnum.NotStarted, TopicProgressEnum.Beginner];
      let needsLock = false;

      data.topicLevels.forEach((topicLevel) => {
        const assessment = topicLevel.action != null ? topicLevel.action.assessmentType : undefined;
        const actionType = topicLevel.action != null ? topicLevel.action.actionType : undefined;
        const progress = topicLevel.progress != null ? topicLevel.progress.toLowerCase() : null;
        const isCert = assessment === AssessmentType.CertificationTraining;
        // Certs take priority and should never be locked
        // if not a cert and the progress is "not started", allow the item to be locked
        if (!isCert && progress === TopicProgressEnum.NotStarted || progress === null) {
          topicLevel.isLocked = needsLock;
        }

        if (topicLevel.progress == null || startableTypes.includes(progress)) {
          needsLock = true;
        }

        // If the server knows better then me, allow it.
        if (actionType === TopicLevelActionType.LOCKED) {
          topicLevel.isLocked = true;
        }

        if (assessment === AssessmentType.ExtraTraining && !app.sessionController.isExtraTrainingAvailable()) {
          topicLevel.action = TopicLevelActionType.NONE;
        }

      });
    }

    return data;
  }

  fetch(options = {}) {
    const data = options.data || {};

    if (this.get('pathId')) {
      data.pathId = this.get('pathId');
      options.data = data;
    }

    return super.fetch(options);
  }
}

module.exports = { TopicDetailsModel, TeamScoreModel };
