const _ = require('underscore');
const Marionette = require('Marionette');

const Sync = require('@common/libs/Sync');

const DestroyableObject = require('@common/libs/DestroyableObject');

const Enum = require('@common/data/enums/Enum');

/*
  EntitySyncTracker

  Static enums and helper methods:

    Enums:
      'Options': defines all valid options that can be passed in as constructor options.

        'SYNC_METHODS': ( [Sync.Methods] = [Sync.Method.READ]) the set of Sync.Methods to react to for the given entities.
            (Defaults: ) See `Sync.js` for entire set of Methods.
        'LOAD_ON_SYNC' ( Boolean = false) flag to control whether to listen to entities Sync events after the
            SyncTracker is started or continuously until the Tracker is stopped.
        'ENTITIES: ( EntityConfig  = []) Array of Entities or EntityConfigs
            Ex1. config = {entities: [entity1, entity2], syncMethods: ['read', 'update']}
            normalizedEntities = [{entity1, 'read'}, {entity1, 'update'}, {entity2, 'read'}, {entity2, 'update'}]

            Each entity can also be a config object from the get go to configure methods on a per entity basis.

            Ex2. config = {entities: [{entity1, ['create', 'delete']}, entity2], syncMethods: ['read']}
            normalizedEntities = [{entity1, 'create'}, {entity1, 'delete'}, {entity2, 'read'}]
        'EVENTS_RECEIVER': Any object that wanted to handle the triggered event methods and has Backbone.Events mixed into it.

*/

const OptionKeys = Enum({
  SYNC_METHODS: 'syncMethods',
  LOAD_ON_SYNC: 'loadOnSync',
  ENTITIES: 'entities',
  EVENTS_RECEIVER: 'eventsReceiver'
});

class EntitySyncTracker extends DestroyableObject {

  static Options = OptionKeys;

  constructor(options = {}) {
    super(options);

    this.triggerLoadingStart = this.triggerLoadingStart.bind(this);
    this.triggerLoadingStop = this.triggerLoadingStop.bind(this);
    this.triggerLoadingSuccess = this.triggerLoadingSuccess.bind(this);
    this.triggerLoadingError = this.triggerLoadingError.bind(this);
    this._startPendingLoad = this._startPendingLoad.bind(this);

    ({
      [OptionKeys.SYNC_METHODS]: this._syncMethods = [Sync.Method.READ],
      [OptionKeys.LOAD_ON_SYNC]: this._loadOnSync = false,
      [OptionKeys.ENTITIES]: this._entities = [],
      [OptionKeys.EVENTS_RECEIVER]: this._eventsReceiver
    } = options);

    this._normalizeEntityConfigs();
  }

  // Starts the listeners and triggers any loading events for already pending entities.
  start() {
    this._setupSyncListeners();
    this._startPendingLoad();
  }

  // Stops any listeners that are waiting for sync events from the entities.
  stop() {
    this._teardownSyncListeners();
  }

  // Triggers the loading wrapper and if the loading is already showing,
  // reject the previous loadingDeferred and and start a new one
  // with the passed in set of entities.
  triggerLoadingStart(entities, options = {}) {
    if (this._loadingDeferred != null) {
      this._loadingDeferred.isActive = false;
      delete this._loadingDeferred;
    } else {
      Marionette.triggerMethodOn(this._eventsReceiver, 'start:loading', this, options);
    }

    const deferred = this._loadingDeferred = new $.Deferred((def) => {
      // Since promises can't be aborted/cancelled, we add an active flag that can be used to deactivate the Deferred from
      // a previously triggered sync.
      def.isActive = true;
    });

    const isActiveGate = (gatedAction) => {
      return (...args) => {
        if (deferred.isActive) {
          gatedAction(...args);
        }
      };
    };

    deferred.then(this.triggerLoadingSuccess, this.triggerLoadingError);
    $.when(...this._getEntitiesXhrs(this._entities))
      .done(isActiveGate(deferred.resolve.bind(deferred)))
      .fail(isActiveGate(deferred.reject.bind(deferred)))
      .always(isActiveGate((...args) => {
        delete this._loadingDeferred;
        this.triggerLoadingStop(...args);
      }));
  }

  triggerLoadingStop(...args) {
    Marionette.triggerMethodOn(this._eventsReceiver, 'stop:loading', this, ...args);
  }

  triggerLoadingSuccess(...args) {
    Marionette.triggerMethodOn(this._eventsReceiver, 'success:loading', this, ...args);
  }

  triggerLoadingError(...args) {
    Marionette.triggerMethodOn(this._eventsReceiver, 'error:loading', this, ...args);
  }

  areEntitiesPending() {
    return this._areEntitiesPending(this._entities);
  }

  areEntitiesResolved() {
    return _.all(this._entities, (entityConfig) => {
      return entityConfig.entity.isXHRDone();
    });
  }

  areEntitiesRejected() {
    return _.some(this._entities, (entityConfig) => {
      return entityConfig.entity.isXHRFailed();
    });
  }

  // This sets up listeners for each syncMethod in the entityConfigs which
  // triggers the loading wrapper for those methods.
  _setupSyncListeners() {
    if (!_.isEmpty(this._syncMethods)) {
      for (const entityConfig of this._entities) {
        const {method, entity, loadOnSync} = entityConfig;

        if (loadOnSync) {
          this.listenTo(entity, `sync:start:${ method }`, (eventEntity, options = {}) => {
            if (options.loading && (eventEntity === entity)) {
              this.triggerLoadingStart(entity, options);
            }
          });
        }
      }
    }
  }

  // Reciprocol to the _setupSyncListeners where the listeners are stopped.
  _teardownSyncListeners() {
    if (!_.isEmpty(this._syncMethods)) {
      for (const entityConfig of this._entities) {
        const {method, entity, loadOnSync} = entityConfig;

        if (loadOnSync) {
          this.stopListening(entity, `sync:start:${ method }`);
        }
      }
    }
  }

  // Trigger the startLoading if this controller was initialized while entities were already pending.
  _startPendingLoad() {
    if (this.areEntitiesPending()) {
      this.triggerLoadingStart(this._entities, { inProgress: true });
    }
  }

  // Retrieve all the xhr objects for the given entityConfigs
  _getEntitiesXhrs(entityConfigs = []) {
    return entityConfigs.map(({ entity, method } = {}) => {
      return entity.getXHR(method);
    });
  }

  // Checks if there's at least one xhr pending
  _areEntitiesPending(entityConfigs = []) {
    return _.some(entityConfigs, (entityConfig) => {
      const pending = entityConfig.entity.isXHRPending();
      const loading = !(entityConfig.entity.getXHRAjaxOptions().loading === false);

      return pending && loading;
    });
  }

  /*
   * This normalizes the passed in entities and syncMethods from the config into an array of
   * {entity, method} objects. There will be one object for every entity-method combination.
   *
   * Ex1. config = {entities: [entity1, entity2], syncMethods: ['read', 'update']}
   * normalizedEntities = [{entity1, 'read'}, {entity1, 'update'}, {entity2, 'read'}, {entity2, 'update'}]
   *
   * Each entity can also be a config object from the get go to configure methods on a per entity basis.
   *
   * Ex2. config = {entities: [{entity1, ['create', 'delete']}, entity2], syncMethods: ['read']}
   * normalizedEntities = [{entity1, 'create'}, {entity1, 'delete'}, {entity2, 'read'}]
   */
  _normalizeEntityConfigs() {
    // Ensure the entities are in an array format before normalizing
    const entities = [].concat(this._entities);

    this._entities = _.chain(entities)
      .unique()
      .compact()
      .map((entity) => {
        // Checks to see if the entity item is a config object already which takes presedence over
        // the defaults.
        return {
          entity: entity.entity || entity,
          syncMethods: [].concat(entity.syncMethods || this._syncMethods),
          loadOnSync: entity.loadOnSync || this._loadOnSync
        };
      })
      .reduce((memo, { entity, syncMethods, loadOnSync }) => {
        // Populate the expanded array of entity/method combo objects.
        const expandedComboConfigs = syncMethods.map((method) => {
          return {
            entity,
            method,
            loadOnSync
          };
        });

        return [...memo, ...expandedComboConfigs];
      }, [])
      .value();
  }

  destroy(options) {
    if (this._loadingDeferred != null) {
      this._loadingDeferred.isActive = false;
    }

    super.destroy(options);
  }
}

module.exports = EntitySyncTracker;
