// Module
var code = `<% if (primaryButtonDefaultColour) { %>
  [dir] .axon-button,
  [dir] .btn-primary {
    background-color: <%- primaryButtonDefaultColour %>;
    border-color: <%- primaryButtonDefaultColour %>;
  }

  /*Reports Timeframe Switchers*/
  [dir] .timeframe a, 
  [dir] .timeframe a.last,
  [dir] .leftcol .listwrapper .toolbar .choice, 
  [dir] .profileview .listwrapper .toolbar .choice,
  [dir] .timeframe a.selected.last {
    border-color: <%- primaryButtonDefaultColour %>;
  }

  [dir] .timeframe a.selected,
  [dir] .leftcol .listwrapper .toolbar .choice.selected, 
  [dir] .profileview .listwrapper .toolbar .choice.selected {
    background-color: <%- primaryButtonDefaultColour %>;
  }
<% } %>
`;
// Exports
module.exports = code;