const Backbone = require('Backbone');

const MILESTONE_START_DATE = 'startDateTimestampMilli';
const MILESTONE_DUE_DATE = 'dueDateTimestampMilli';
const MILESTONE_STATUS = 'status';
const MILESTONE_COMPLETION_DATE = 'completionDateTimestampMilli';
const MILESTONE_COMPLETED_ON_TIME = 'completedOnTime';
const MILESTONE_TIME_REMAINING = 'timeRemainingDays';

/*
 * Model holding all of user's progress on the milestone
 * Created By: MilestoneInterfaceFactory
 */
class MilestoneProgress extends Backbone.Model {
  getStartDate() {
    return this.get(MILESTONE_START_DATE);
  }

  getDueDate() {
    return this.get(MILESTONE_DUE_DATE);
  }

  getCompletionDate() {
    return this.get(MILESTONE_COMPLETION_DATE);
  }

  getCompletedOnTime() {
    return this.get(MILESTONE_COMPLETED_ON_TIME);
  }

  getStatus() {
    return this.get(MILESTONE_STATUS);
  }

  getTimeRemaining() {
    return this.get(MILESTONE_TIME_REMAINING);
  }
}

module.exports = MilestoneProgress;
