const Form = require('@common/components/forms/Form');
const KeyCode = require('@common/data/enums/KeyCode');

require('@common/components/forms/editors/checkboxes/Form.Editor.Checkboxes');
require('@common/components/forms/editors/pillSelect/PillSelect.less');

Form.Editor.PillSelect = class PillSelect extends Form.Editor.Checkboxes {
  preinitialize(options) {
    super.preinitialize(options);
    this.default = [];

    this.optionTemplate = `\
      <span class="pill__item" data-item-id="<%- id %>">
        <input type="checkbox"
          class="qa-pill-item-input"
          autocomplete="off"
          id="<%- id %>"
          name="checkbox-group-<%- id %>"
          value="<%- id %>"
          aria-checked="false"
          role="radio"
        />
        <label for="<%- id %>"><%- value %></label>
      </span>\
    `;
  }


  initialize(options = {}) {
    this.shallowValue = true;

    if (options.options != null) {
      this.collection = options.options.collection || options.options;

      if (options.options.pillOptions != null) {
        this.isSingleSelect = options.options.pillOptions.isSingleSelect;
      }
    }

    if (this.collection != null) {
      this.setOptions(this.collection);
    }
  }

  events() {
    return Object.assign(super.events(), {
      'click input': '_onClick'
    });
  }

  getValue() {
    const value = [];

    this.$(':checked').each((index, element) => {
      const id = this._getSerializedValueFromElement(element);
      value.push(id);
    });

    if (this.isSingleSelect) {
      return value[0];
    }
    return value;

  }

  _onKeyDown(e) {
    if (e.which === KeyCode.ENTER) {
      this._onClick(e);
    }
  }

  _onClick(e) {
    if (this.isSingleSelect) {
      const selectedId = $(e.target).attr('id');
      const others = this.$(':checked').not(`#${ selectedId }`);
      $(others).removeClass('selected')
        .attr('aria-checked', false)
        .prop('checked', false);
    }

    $(e.target).toggleClass('selected')
      .attr('aria-checked', $(e.target).is(':checked'));
  }
};

module.exports = Form.Editor.PillSelect;
