import {
  AlertTitle as MuiAlertTitle,
  type AlertTitleProps as MuiAlertTitleProps,
  type TypographyProps as MuiTypographyProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxAlertTitleProps = MuiAlertTitleProps & MuiTypographyProps;

const styledOptions = {
  name: 'AxAlertTitle'
};

const StyledAxAlertTitle = styled(MuiAlertTitle, styledOptions)<AxAlertTitleProps>(() => {
  // Title styles depend on the alert variant, so they are defined in AxAlert
  return {};
}) as typeof MuiAlertTitle;

/**
 * `AxAlertTitle` is styled based on the alert variant, so the styles are defined in `AxAlert`
 *
 * ### Links
 * - • [MUI API](https://mui.com/material-ui/api/alert-title/)
 * - • [Mockup](https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7244-5616&mode=design&t=hVEcz054xk5GGZgJ-0)
 */
export const AxAlertTitle = forwardRef(({
  ...otherProps
}: AxAlertTitleProps, ref: Ref<HTMLDivElement>) => {
  const alertTitleProps: AxAlertTitleProps = {
    ...otherProps
  };

  return (
    <StyledAxAlertTitle
      { ...alertTitleProps }
      ref={ ref }
    />
  );
});

export default AxAlertTitle;
