const HierarchySelector = require('@common/components/hierarchy_selector/HierarchySelector');
const TeamsList = require('@common/components/teamHierarchySelector/TeamsList');

class TeamHierarchySelector extends HierarchySelector {
  constructor(options = {}) {
    const sourceCollectionJSON = (options.collection && options.collection.toJSON()) || [];
    super(options);
    this.sourceCollectionJSON = sourceCollectionJSON;
  }

  configureCollection(options) {

    const useOptions = Object.assign({}, this.options, options);

    if (this._treeEditor != null) {
      let firstMatch, filterChildren;

      // If you are using a global BU, all teams will match - so declare all teams as matches.
      if (useOptions.matchBU == null) {
        filterChildren = (teamObj) => {
          if (!firstMatch) {
            firstMatch = true;
            teamObj.isSelectedInTree = true;
          }
          teamObj.isUnselectable = false;
          return true;
        };

      // Otherwise, filter based on the provided BU of the template form you're configuring for
      } else {
        filterChildren = (teamObj) => {
          const isBUMatches = teamObj.businessUnitId === useOptions.matchBU || teamObj.ancestorBusinessUnitId === useOptions.matchBU;

          // If this is the first match, set it as the default selected item in the tree
          if (isBUMatches && !firstMatch) {
            firstMatch = true;
            teamObj.isSelectedInTree = true;
          }

          // If it is not a match with the target BU, set it as unselectable in the tree
          teamObj.isUnselectable = !isBUMatches;

          // If the current object is not global, we can stop checking because all of its children will either match or not match based on this one's BU.
          if (teamObj.businessUnitId != null || teamObj.ancestorBusinessUnitId != null) {
            return isBUMatches;
          }

          // Otherwise (if this is a global node) check any children individually to see if they match
          if (teamObj.children != null) {
            teamObj.children = teamObj.children.filter(filterChildren);
          }

          return isBUMatches || (teamObj.children != null && teamObj.children.length > 0);
        };

      }

      const teamArray = JSON.parse(JSON.stringify(this.sourceCollectionJSON)).filter(filterChildren);
      this._treeEditor.setCollection(new TeamsList(teamArray));
      this._treeEditor.render();

      // After rendering, make sure the first matching option in the tree (if any) is selected and visible
      const firstNode = this._treeEditor.getSelectedNodes()[0];
      if (firstNode) {
        firstNode.makeVisible();
        this.setSelectedItem(firstNode.data.model);
      }
    }
  }

}

module.exports = TeamHierarchySelector;
