const { CollectionView } = require('Marionette');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const { NoMessagesItem } = require('@training/apps/training/views/MessageItemView');
const CardMessageItemView = require('@training/apps/training/views/CardMessageItemView');
const I18n = require('@common/libs/I18n');
const logging = require('logging');

const getMessagesCardControllerDefinition = (data = {}, cardOptions = {}) => {
  // Make sure we have the required details
  if (!data.messageCollection) {
    logging.error('The messageCollection object is required to generate the MessagesCardControllerDefinition');
    return null;
  }

  const defaultOptions = {
    cardClass: 'base-card--messages',
    titleOptions: {
      title: I18n.t('hub.latestMessages')
    },
    hasLink: true,
    tabbableLinkClickableCard: true,
    linkOptions: {
      target: 'fullCard',
      linkTextKeyOverride: 'hub.latestMessagesLinkText',
      linkAriaLabel: I18n.t('hub.latestMessagesLinkText'),
      callback: data.callback
    },
    bottomWrapperControllerDefinition: {
      viewDefinition: {
        ViewClass: CollectionView,
        collection: data.messageCollection,
        tagName: 'ul',
        className: 'messages__list full-width',
        childView: CardMessageItemView,
        emptyView: NoMessagesItem,
        childViewOptions: {
          tagName: 'li',
          className: 'messages__row',
          // unset the attributes so the a11y values aren't uses in this use case
          attributes: {}
        }
      }
    }
  };

  // Load the config object for this particular ToDo card
  return getBaseCardControllerDefinition(Object.assign(defaultOptions, cardOptions));
};

module.exports = {
  getMessagesCardControllerDefinition
};
