/**
 * This is a collection of behaviors that are used to help track impressions of DOM elements.
 *
 * WHAT DATA ARE WE TRACKING
 * =========================
 *
 * As per the spec, we are only interested in the following data:
 *
 * - `pageId`:           the pageId the user sees. This could be a post/article/link's `pageId` for example.
 * - `impressionDateMs`: the time (in ms) the impression occured.
 * - `source`:           an indication of where the impression occured. This value is constrained by an Enum, which
 *                       is agreed upon by client and server.
 *
 * Once we collect these impressions, we batch them up and periodically send them to the server.
 *
 * ENABLING THE IMPRESSION TRACKER
 * ===============================
 *
 * The ImpressionTracker is enabled by instantiating an IntersectionObserver. We must do this where we create the root
 * view that the IntersectionObserver will be based on. This is set up as a `ImpressionTrackerViewPort` behavior.
 *
 * 1. Determine the root view to use as the IntersectionObserver's viewport (we chose
 *    PageLayoutControllerDefinitionFactory, and that work has been done already)
 * 2. In that file, require `ImpressionTracker`
 * 3. Update the viewDefinition to include the `ImpressionTrackerViewPort` behavior, and pass in a CSS selector resolving
 *    to the viewport:
 *
 * Example:
 *
 *   behaviors: {
 *     ImpressionTrackerViewPort: {
 *       root: '#page-view'
 *     }
 *   }
 *
 * INTEGRATING THE BEHAVIOR
 * =========================
 *
 * 1. Locate the ViewDefinition you want to add the behavior to.
 * 2. In that file, require this behavior.
 * 3. In the view definition, add or modify the behavior property to include this behavior:
 *
 * Example:
 *
 *   behaviors: {
 *     ImpressionTracker: {
 *       model: this.model,  // this is the model that we're using to render the view.
 *       source: this.source // this is a string representing the source of the impression. Read on for further discussion.
 *     }
 *   },
 *
 * SOME NOTES ABOUT INTEGRATING THE SOURCE PROPERTY
 * ================================================
 *
 * The source property drives the workings of this Behavior
 * --------------------------------------------------------
 *
 * Since the source property is required to submit a complete impression to track, we are using it as a way to indicate
 * whether to _actually_ track an element. I'll explain with an example:
 *
 * Example:
 *   TimelineCardViewController has been identified as the best place to take the ImpressionTracker Behavior. It is
 *   currently being used in 3 places:
 *     - the Global Timeline
 *     - the Community Timeline
 *     - the Post Detail page.
 *
 *   Our requirements are that we only track impressions on the Global and Community Timelines ONLY. Since we're passing
 *   in the source from their respective classes, but we're NOT passing in a source from the Post Detail page, the
 *   ImpressionTracker checks the source property to decide whether it should observe the TimelineCard.
 *
 * Tips on specifying the source
 * -----------------------------
 *
 * Depending on your code, you may also need to modify the constructor() or initialize() method to take a
 * `source` property:
 *
 * - If you can determine the source from the view definition, modify the behavior object to pass a string:
 *
 *   Example:
 *     behaviors: {
 *       ImpressionTracker: {
 *         model: this.model,
 *         source: 'TODAYS_TOPICS' // assuming that's an Enum value we're using.
 *       }
 *     },
 * - If you CAN'T determine the source from the view definition, adjust the codebase to pass in that source from a
 *   location where you CAN.
 *
 *   Example:
 *     The global and community timelines both use the TimelineCardViewController to render the cards. This class is
 *     where we need to add the Behavior, but at this level, we can't determine which class is using it.
 *
 *     To help the behavior along, we set the source property as an option to TimelineCardViewController in the classes
 *     using TimelineCardViewController. Then in TimelineCardViewController, we capture that option as a class attribute,
 *     then pass that attribute into the Behavior.
 */

const { Behavior } = require('Marionette');
const Behaviors = require('@common/libs/behaviors/Behaviors');
const {
  startFlushInterval,
  observeElement,
  unobserveElement,
  createObserver
} = require('@common/libs/behaviors/impressionTracker/ImpressionTrackerState');

Behaviors.ImpressionTrackerViewPort = class ImpressionTrackerViewPort extends Behavior {
  onAttach() {
    createObserver($(this.getOption('root'))[0], this.getOption('timeOffset'));
  }
};

Behaviors.ImpressionTracker = class ImpressionTracker extends Behavior {
  initialize() {
    this.objectId = this.getOption('objectId');
    this.objectType = this.getOption('objectType');
    this.source = this.getOption('source');

    startFlushInterval();
  }

  onRender() {
    if (!this.source) {
      return;
    }

    this.trackingZoneEl = $(this.view.el).find('.js-impression-tracking-zone')[0];

    observeElement(this.trackingZoneEl, this.objectId, this.objectType, this.source);
  }

  onDestroy() {
    if (!this.source) {
      return;
    }

    unobserveElement(this.trackingZoneEl)
  }
};

module.exports = {
  ImpressionTracker: Behaviors.ImpressionTracker,
  ImpressionTrackerViewPort: Behaviors.ImpressionTrackerViewPort
};

