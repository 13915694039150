import {
  FormControl as MuiFormControl,
  styled,
  type FormControlTypeMap as MuiFormControlTypeMap
} from '@mui/material';
import { type OverrideProps as MuiOverrideProps } from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type PropsToOmit = 'color'
  | 'margin'
  | 'variant'
  | 'size'; // only AxTextField uses size and it's controlled there

type AxFormControlCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxFormControlTypeMap<P = object, D extends React.ElementType = 'div'> = MuiFormControlTypeMap<P & AxFormControlCustomProps, D>;

/**
 * FormControl is a wrapper component in Material UI used to structure and manage
 * form inputs like text fields, selects, checkboxes, and more. It provides
 * consistent layout, labeling, and validation features.
 *
 * This component ensures proper alignment of input fields, labels, and helper text
 * while handling form states such as `error`, `required`, and `disabled`.
 *
 */
export type AxFormControlProps<
  D extends ElementType = AxFormControlTypeMap['defaultComponent'],
  P = object,
> = Omit<MuiOverrideProps<AxFormControlTypeMap<P & AxFormControlCustomProps, D>, D>, PropsToOmit>;

const styledOptions = {
  name: 'AxFormControl'
};

const StyledAxFormControl = styled(MuiFormControl, styledOptions)<AxFormControlProps>(() => {
  return {};
});

export const AxFormControl = forwardRef(({
  children,
  ...otherProps
}: AxFormControlProps, ref: Ref<HTMLDivElement>) => {
  const FormControlProps: AxFormControlProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxFormControl { ...FormControlProps } ref={ ref }>
      { children }
    </StyledAxFormControl>
  );
});

export default AxFormControl;
