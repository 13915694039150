const { ItemView } = require('Marionette');

class SubmissionPreviewPlaceholder extends ItemView {

  className() {
    return 'submission-preview-placeholder ax-grid ax-grid--justify-content-center';
  }

  getTemplate() {
    return `<span class="attachment-icon <%- iconClass %> blue ax-grid__col--auto-size"></span>`;
  }

  constructor(options) {
    super(options);

    this.templateHelpers = this.templateHelpers.bind(this);
  }

  templateHelpers() {

    const type = this.model && this.model.fileType;

    const iconClass = type ? `icon-${ type }` : 'icon-upload';

    return {
      iconClass
    };
  }
}

module.exports = SubmissionPreviewPlaceholder;
