// Module
var code = `<div class="modalHeader">
  <h1><%- t('groups.add') %></h2>
  <button
    type="button"
    class="icon-remove js-close-button"
    aria-label="<%- t('general.close') %>">
  </button>
</div>
<form class="js-form">
  <div class="js-group-list group-list">
    <div class="listselectall clearfix">
      <div class="searchstring">
        <%= axSearch({
          classNameInput: 'search-input qa-input-search',
          placeholder: t('communitiesManagement.accessSettings.searchForGroupsLabel'),
          ariaLabel: t('communitiesManagement.accessSettings.searchForGroupsLabel')
        }) %>
      </div>
      <%= axCheckbox({id: 'select-all', label: t('general.selectAll'), ariaLabel: t('groups.selectAllAria')}) %>
    </div>
    <ul class="selectableList nomargin" data-field="groups" data-options="groupList" data-editor="Checkboxes"></ul>
  </div>
</form>
`;
// Exports
module.exports = code;