const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const I18n = require('@common/libs/I18n');
const Backbone = require('Backbone');

const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');

const UserProfileIcon = require('@common/modules/main/header/userProfileIcon/UserProfileIcon');
const {
  BADGE_MODIFIERS,
  TitleHeaderDefinitionBuilder,
  TitleHeaderTypeEnum
} = require('@common/components/titleHeader/TitleHeader');
const InlineUserSubmissionView = require('@common/components/submission/InlineUserSubmissionView');

const { canPrint } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const EvaluationDetailsInfoLayout = require('@training/apps/training/views/evaluations/EvaluationDetailsInfoLayout');
const CoachMessageCardView = require('@training/apps/training/views/evaluations/CoachMessageCardView');
const AttemptDetailsCardView = require('@training/apps/training/views/evaluations/AttemptDetailsCardView');
const PrintFormButtonView = require('@training/apps/training/views/evaluations/PrintFormButtonView');
const AttemptDetailsCardHeaderView = require('@training/apps/training/views/evaluations/AttemptDetailsCardHeaderView');
const AttemptDetailsCardInfoView = require('@training/apps/training/views/evaluations/AttemptDetailsCardInfoView');

class EvaluationDetailsInfoController extends LayoutController {

  viewDefinition() {
    return {
      ViewClass: EvaluationDetailsInfoLayout
    };
  }

  regionControllers() {
    const model = this.getOption('model');
    const evaluationOption = this.getOption('evaluationOption');
    const evaluationController = this.getOption('evaluationController');
    const evaluationDetailsRoute = this.getOption('evaluationDetailsRoute');
    const isViewingLastAttempt = this.getOption('isViewingLastAttempt');

    const evaluationStatus = model.getEvaluationStatus();
    const evaluationType = model.getEvalType();
    const score = model.getEvaluationScore();
    const attempt = model.getAttempt();
    const evaluator = model.getEvaluator();
    const passingScore = model.getPassingScore();

    const detailsInfoOptions = {
      evaluationForm: model,
      evaluationStatus,
      evaluationOption,
      evaluationController,
      evaluationDetailsRoute,
      isViewingLastAttempt
    };

    const regionControllers = {
      coachMessageRegion: {
        viewDefinition: {
          ViewClass: CoachMessageCardView,
          evaluationStatus,
          evaluationType,
          coachType: window.apps.auth.session.user.getCoachType(),
          isViewingLastAttempt,
          passingScore
        }
      },
      attemptDetailsRegion: {
        ViewControllerClass: LayoutController,
        viewDefinition: {
          ViewClass: AttemptDetailsCardView,
          model
        },
        regionControllers: {
          header: this._getHeaderController(evaluationStatus, attempt),
          evaluationScore: this._getEvaluationScoreController(evaluationStatus, score, passingScore),
          evaluator: this._getEvaluatorController(evaluationStatus, evaluator),
          info: this._getDetailsInfoController(detailsInfoOptions)
        }
      }
    };

    if (model.isVirtualEvaluation() && evaluationStatus !== EvaluationObjectiveStatus.NOT_STARTED) {
      regionControllers.submissionPreviewRegion = {
        viewDefinition: {
          ViewClass: InlineUserSubmissionView,
          evaluationData: model
        }
      };
    }

    if (canPrint()) {
      regionControllers.printFormRegion = {
        viewDefinition: {
          ViewClass: PrintFormButtonView
        }
      };
    }

    return regionControllers;
  }

  _getHeaderController(evaluationStatus, attemptNumber) {
    return {
      viewDefinition: {
        ViewClass: AttemptDetailsCardHeaderView,
        attemptNumber,
        evaluationStatus
      }
    };
  }

  _getEvaluationScoreController(status, score, passingScore) {
    const evaluationScoreOptions = this._getEvaluationScoreOptions(status, score, passingScore);
    const title = evaluationScoreOptions['title'];
    const iconClass = evaluationScoreOptions['iconClass'];
    const subTitle = evaluationScoreOptions['subTitle'];

    return new TitleHeaderDefinitionBuilder({
      isSemanticHeader: false,
      titleType: TitleHeaderTypeEnum.SUBTEXT_TITLE,
      badgeModifierClasses: [BADGE_MODIFIERS.MEDIUM],
      iconClass,
      title,
      titleClass: 'evaluation-score-title ax-font ax-font__p ax-font--no-margin',
      subTitle
    }).build();
  }

  _getEvaluationScoreOptions(status, score, passingScore) {
    const options = {
      iconClass: '',
      title: I18n.t(`GuidedLearning.evaluation.status.${ status }`),
      subTitle: ''
    };

    switch (status) {
      case EvaluationObjectiveStatus.NOT_STARTED:
        if (this.getOption('model').isVirtualEvaluation()) {
          options.title = I18n.t('GuidedLearning.evaluation.status.NOT_SUBMITTED');
        }
        options.iconClass = 'icon-unlock';
        options.subTitle = I18n.t('observations.details.evaluationScore.pending', { passingScore: passingScore });
        break;
      case EvaluationObjectiveStatus.PENDING:
      case EvaluationObjectiveStatus.PENDING_AFTER_RETRY:
        options.iconClass = 'icon-pending';
        options.title = I18n.t('GuidedLearning.evaluation.status.ATTEMPT_PENDING');
        options.subTitle = I18n.t('observations.details.evaluationScore.pending', { passingScore: passingScore });
        break;
      case EvaluationObjectiveStatus.FAILED:
        options.iconClass = 'icon-exclamation';
        options.subTitle = I18n.t('observations.details.evaluationScore.complete', { evaluationScore: score });
        break;
      case EvaluationObjectiveStatus.PASSED:
        options.iconClass = 'icon-ok_circle';
        options.subTitle = I18n.t('observations.details.evaluationScore.complete', { evaluationScore: score });
        break;
      default:
        return {
          iconClass: '',
          title: '',
          subTitle: ''
        };
    }

    return options;
  }

  _getEvaluatorController(status, evaluator) {
    const isNotStartedOrPending = this._isNotStartedOrPending(status);
    const evaluatorOptions = this._getEvaluatorOptions(isNotStartedOrPending, evaluator);

    const title = evaluatorOptions.title;
    const badgeControllerDefinition = evaluatorOptions.badgeControllerDefinition;
    const iconClass = evaluatorOptions.iconClass;

    return new TitleHeaderDefinitionBuilder({
      isSemanticHeader: false,
      titleType: TitleHeaderTypeEnum.SUBTEXT_TITLE,
      badgeModifierClasses: [BADGE_MODIFIERS.MEDIUM],
      iconClass,
      title,
      titleClass: 'evaluator-title ax-font ax-font__label ax-font--no-margin',
      subTitle: I18n.t('behaviours.labels.evaluator'),
      badgeControllerDefinition
    }).build();
  }

  _getEvaluatorOptions(isNotStartedOrPending, evaluator) {
    const evaluatorOptions = {
      title: I18n.t('GuidedLearning.evaluation.details.toBeAssigned'),
      badgeControllerDefinition: undefined,
      iconClass: ''
    };

    if (!isNotStartedOrPending && evaluator) {
      const evaluatorNickname = evaluator['nickname'];
      const evaluatorName = evaluatorNickname || evaluator['fullname'];

      if (evaluatorName) {
        evaluatorOptions.title = evaluatorName;

        const evaluatorModel = new Backbone.Model({
          fullname: evaluatorName,
          id: evaluator['id']
        });

        const profileImage = evaluator['profileImage'];
        if (profileImage) {
          evaluatorModel.set('profileImage', profileImage);
        }

        evaluatorOptions.badgeControllerDefinition = {
          viewDefinition: {
            ViewClass: UserProfileIcon,
            model: evaluatorModel
          }
        };
      }
    }

    if (isNotStartedOrPending || !evaluatorOptions.badgeControllerDefinition) {
      evaluatorOptions.iconClass = 'icon-user';
    }

    return evaluatorOptions;
  }

  _getDetailsInfoController({
    evaluationStatus,
    evaluationOption,
    evaluationForm,
    evaluationController,
    evaluationDetailsRoute,
    isViewingLastAttempt
  }) {
    const objective = evaluationOption.getObjective();

    return {
      viewDefinition: {
        ViewClass: AttemptDetailsCardInfoView,
        evaluationStatus,
        evaluationOption,
        evaluationController,
        evaluationDetailsRoute,
        isViewingLastAttempt,
        pointsAvailable: objective.getPointsAvailableForCompletion(),
        lastFailedFormId: evaluationForm.getLastFailedFormId(),
        evaluatedDate: evaluationForm.getEvaluatedDate()
      }
    };
  }

  _isNotStartedOrPending(status) {
    return [EvaluationObjectiveStatus.NOT_STARTED, EvaluationObjectiveStatus.PENDING, EvaluationObjectiveStatus.PENDING_AFTER_RETRY].includes(status);
  }
}

module.exports = EvaluationDetailsInfoController;
