const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const { axCheckbox } = require('@common/components/axForm/AxFormTemplateHelpers');

class SharedCommunityItemView extends ItemView {
  tagName() {
    return 'li';
  }

  getTemplate() {
    return `
    <div class="js-shared-community-checkbox">
      ${ axCheckbox({ label: this.model.secondaryCommunity.getName()}) }
    </div>
    `;
  }

  ui() {
    return {
      communityCheckbox: '.js-shared-community-checkbox input'
    };
  }

  events() {
    return {
      'focus @ui.communityCheckbox': this._toggleAccessibleFocusOutline,
      'blur @ui.communityCheckbox': this._toggleAccessibleFocusOutline,
      'click @ui.communityCheckbox': this._toggleCommunityShared
    };
  }

  onRender() {
    this._setCheckboxState();
  }

  _setCheckboxState() {
    this.ui.communityCheckbox.prop('checked', this.model.get('isActive'));
  }

  _toggleAccessibleFocusOutline(e) {
    // Apply blue outline to checkbox + label when the checkbox gains keyboard focus
    const $checkbox = $(e.currentTarget);
    const $label = $checkbox.parent();
    $label.toggleClass('focus-visible', $checkbox.hasClass('focus-visible'));
  }

  _toggleCommunityShared() {
    if (this.isSharingInProgress) {
      return;
    }

    const isActive = this.model.get('isActive');
    const shareMethod = isActive ? this.model.unshare : this.model.share;
    const shareSuccessCallback = isActive ? this._onUnshareSuccess.bind(this) : this._onShareSuccess.bind(this);
    this.isSharingInProgress = true;

    shareMethod()
      .done(shareSuccessCallback)
      .fail(this._onShareError.bind(this));
  }

  _onShareSuccess(response) {
    this.isSharingInProgress = false;
    this.model.set({
      id: response.entity.id,
      isActive: true
    });
    window.app.layout.flash.success(I18n.t('discover.share.success'));
  }

  _onShareError(xhr) {
    this.isSharingInProgress = false;
    const exception = AxonifyExceptionFactory.fromResponse(xhr);

    if ([AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY, AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED]
      .includes(exception.getErrorCode())) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.share.error'));
      this._setCheckboxState();
    }
  }

  _onUnshareSuccess() {
    this.isSharingInProgress = false;
    this.model.unset('id');
    this.model.set({
      isActive: false
    });
    window.app.layout.flash.success(I18n.t('discover.unshare.success'));
  }
}

module.exports = SharedCommunityItemView;
