const _ = require('underscore');
const Backbone = require('Backbone');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
require('@common/libs/behaviors/resizable/Resizable');

const ABS_MIN_WIDTH = 200;
const ABS_MIN_HEIGHT = 200;
const ABS_MAX_HEIGHT = 720;

class VideoPlayer extends Marionette.ItemView {
  get behaviors() {
    return {Resizable: {}};
  }

  template() {
    return 'VideoPlayer type not found for given VideoPackage!';
  }

  constructor(options = {}) {
    super(options);

    this._triggerLoad = this._triggerLoad.bind(this);
    this._triggerEnd = this._triggerEnd.bind(this);
    this._triggerError = this._triggerError.bind(this);
    this._triggerPlay = this._triggerPlay.bind(this);
    this._triggerPause = this._triggerPause.bind(this);
    this._triggerTimeUpdate = this._triggerTimeUpdate.bind(this);
    this._triggerCanPlay = this._triggerCanPlay.bind(this);

    const defaultOptions = this.defaultOptions();
    _.extend(this, _.pick(_.defaults(options, defaultOptions), _.keys(defaultOptions)));
    this._videoPlayerState = new Backbone.Model();

    this._setMinWidth(options.minWidth);
    this.setMaxWidth(options.maxWidth);
    this._setMinHeight(options.minHeight);
    this.setMaxHeight(options.maxHeight);
    this._setAspectRatio(options.aspectRatio);
    this._initializeStateListeners();
  }

  defaultOptions() {
    return {
      autoplay: false,
      centerPlayer: true,
      startTime: 0
    };
  }

  render() {
    this._maintainAspectRatio();
    const templateOptions = this.getTemplateOptions();
    const templateStr = this.template(templateOptions);
    this._$playerWrapper = $('<div/>', {
      class: 'player-wrapper',
      role: 'region',
      'aria-label': I18n.t('trainingModule.videoPlayer')
    });
    if (this.centerPlayer) {
      this._$playerWrapper.css('margin', '0 auto');
    }
    this.$el.html(this._$playerWrapper.html(templateStr));
    this._$playerWrapper = this.$('.player-wrapper');
    this.renderPlayer();
    this.triggerMethod('render', this);
  }

  renderPlayer() {
    // Trigger the error event in the base class since this should only occur if
    // an invalid video type was used.
    this._triggerError();
  }

  getTemplateOptions() {
    return {
      id: this.model.id,
      width: this._getWidth(),
      height: this._getHeight()
    };
  }

  removePlayer() {
    this.$el.empty();
  }

  remove() {
    this.removePlayer();
    super.remove();
    this.isDestroyed = true;
    return this;
  }

  // Used when the video should be resized for whatever reason making sure
  // the max width/height are adjusted accordingly and then the video's
  // width/height are adjusted to fit maintaining the video's aspect ratio.
  resize() {
    this._maintainAspectRatio();
    return (this._$playerWrapper != null ? this._$playerWrapper.css({
      width: this._getWidth(),
      height: this._getHeight()
    }) : undefined);
  }

  // This value only works if it's set before the video's ever been started before.
  // For changing the playback position after that point, use setCurrentTime().

  // eslint-disable-next-line no-empty-function
  setStartTime() {}

  isSeeking() {
    return false;
  }

  // eslint-disable-next-line no-empty-function
  play() {}

  // eslint-disable-next-line no-empty-function
  pause() {}

  // eslint-disable-next-line no-empty-function
  exitFullScreen() {}

  onResize() {
    this.resize();
  }

  getDuration() {
    return 0;
  }

  getCurrentTime() {
    return 0;
  }

  // eslint-disable-next-line no-empty-function
  setCurrentTime() {}

  isLoaded() {
    return false;
  }

  isPlaying() {
    return false;
  }

  _initializeStateListeners() {
    this.listenTo(this._videoPlayerState, 'change:maxHeight change:maxWidth', this.resize);
  }

  // Utility method to adjust the video's width and height to fit within the bounds
  // of the max width/height while maintaining it's aspect ratio.
  _maintainAspectRatio() {
    const aspectRatio = this._getAspectRatio();
    const maxHeight = this._getMaxHeight();
    const width = this._setWidth(maxHeight * aspectRatio);
    this._setHeight(width / aspectRatio);
  }

  // Setter for the video's aspect ratio making sure the native ratio
  // trumps any custom setting.
  _setAspectRatio(aspectRatio = 16 / 9) {
    const aspRatio = this.model.getNativeAspectRatio() || aspectRatio;
    this._videoPlayerState.set({aspectRatio: aspRatio});
  }

  // Getter for the video's aspect ratio.
  _getAspectRatio() {
    return this._videoPlayerState.get('aspectRatio');
  }

  // Private method used to set the width of the video making sure it's
  // clamped between the min/max presets.
  _setWidth(widthArg = null) {
    const minWidth = this._getMinWidth();
    const maxWidth = this._getMaxWidth();
    let width = widthArg;

    if (widthArg == null || widthArg > maxWidth) {
      width = maxWidth;
    } else if (widthArg < minWidth) {
      width = minWidth;
    }

    width = Math.floor(width);
    this._videoPlayerState.set({width: width});
    return width;
  }

  // Private getter for the width
  _getWidth() {
    return this._videoPlayerState.get('width');
  }

  // Private method used to set the minWidth available to the video player. Makes
  // sure it can't be set to be below the absolute min value.
  _setMinWidth(minWidthArg = 0) {
    const minWidth = Math.floor(Math.max(minWidthArg, ABS_MIN_WIDTH));
    this._videoPlayerState.set({minWidth});
  }

  // Private getter for the minWidth
  _getMinWidth() {
    return this._videoPlayerState.get('minWidth');
  }

  // Public method used to set the maxWidth available to the video player.
  setMaxWidth(maxWidth) {
    if (maxWidth != null) {
      this._videoPlayerState.set({maxWidth: Math.floor(maxWidth)});
    }
  }

  // Private getter for the maxWidth. Makes sure the returned value is the smaller of
  // the set maxWidth, video's nativeHeight and this view's current width but bigger
  // than the minWidth to account for invalid initial options.
  _getMaxWidth() {
    const maxWidth = this._videoPlayerState.get('maxWidth');
    const minWidth = this._videoPlayerState.get('minWidth');
    const nativeWidth = this.model.getNativeWidth();
    const containerWidth = this.$el.width();
    return Math.floor(Math.max(Math.min.apply(this, _.compact([maxWidth, nativeWidth, containerWidth]))), minWidth);
  }

  // Private method used to set the height of the video making sure it's
  // clamped between the min/max presets.
  _setHeight(heightArg = null) {
    let height = heightArg;

    const minHeight = this._getMinHeight();
    const maxHeight = this._getMaxHeight();

    if (heightArg == null || heightArg > maxHeight) {
      height = maxHeight;
    } else if (heightArg < minHeight) {
      height = minHeight;
    }

    height = Math.floor(height);
    this._videoPlayerState.set({height});
  }

  // Private getter for the height
  _getHeight() {
    return this._videoPlayerState.get('height');
  }

  // Private method used to set the minHeight available to the video player. Makes
  // sure it can't be set to be below the absolute min value.
  _setMinHeight(minHeightArg = 0) {
    const minHeight = Math.floor(Math.max(minHeightArg, ABS_MIN_HEIGHT));
    this._videoPlayerState.set({minHeight});
  }

  // Private getter for the minHeight
  _getMinHeight() {
    return this._videoPlayerState.get('minHeight');
  }

  // Public method used to set the maxHeight available to the video player.
  setMaxHeight(maxHeightArg) {
    const maxHeight = Math.floor(Math.min.apply(this, _.compact([maxHeightArg, ABS_MAX_HEIGHT])));
    if (maxHeight != null) {
      this._videoPlayerState.set({maxHeight});
    }
  }

  // Private getter for the maxHeight. Makes sure the returned value is the smaller of
  // the set maxHeight and the video's nativeHeight but bigger than the minHeight to
  // account for invalid initial options.
  _getMaxHeight() {
    const maxHeight = this._videoPlayerState.get('maxHeight');
    const minHeight = this._videoPlayerState.get('minHeight');
    const nativeHeight = this.model.getNativeHeight();
    return Math.floor(Math.max(Math.min.apply(this, _.compact([maxHeight, nativeHeight]))), minHeight);
  }

  setMaxDimensions(maxWidth, maxHeight) {
    const maxW = Math.floor(maxWidth);
    const maxH = Math.floor(Math.min.apply(this, _.compact([maxHeight, ABS_MAX_HEIGHT])));
    const data = {};

    if (maxW != null) {
      Object.assign(data, {
        maxWidth: maxW
      });
    }

    if (maxH != null) {
      Object.assign(data, {
        maxHeight: maxH
      });
    }

    this._videoPlayerState.set(data);
  }

  _triggerLoad(options) {
    this.trigger('player:load', options);
  }

  _triggerEnd(options) {
    this.trigger('player:end', options);
  }

  _triggerError(options) {
    this.trigger('player:error', options);
  }

  _triggerPlay(options) {
    this.trigger('player:play', options);
  }

  _triggerPause(options) {
    this.trigger('player:pause', options);
  }

  _triggerTimeUpdate(options) {
    this.trigger('player:timeUpdate', options);
  }

  _triggerCanPlay(options) {
    this.trigger('player:canPlay', options);
  }
}

module.exports = VideoPlayer;
