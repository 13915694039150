const logging = require('logging');
const _ = require('underscore');
const Marionette = require('Marionette');
const KeyCode = require('@common/data/enums/KeyCode');

const PersistentObject = require('@common/libs/UI/PersistentObject');

require('@common/components/tabbed_layout/tabbed-layout.less');

class TabbedLayout extends Marionette.LayoutView.extend(PersistentObject) {
  getTemplate() {
    return `\
      <div class="tab-scroll-gradient"></div>
      <ul class="tab-container tab-bar ax-grid ax-grid--no-gutter clearfix" role="tablist"></ul>
      <div class="tab-content-container margintop"></div>\
    `;
  }

  regions() {
    return {
      tabContentRegion: '.tab-content-container'
    };
  }

  events() {
    return {
      'click .tab': 'onClickTab',
      'keydown .tab-content': 'onKeydownTab'
    };
  }

  ui() {
    return {
      tabContainer: '.tab-container'
    };
  }

  constructor(options = {}) {
    super(options);

    this._$tabs = {};
    this._tabTemplate = options.tabbedLayoutTemplate || `\
      <li class="tab <%- tabClass || tabName %>" data-tab="<%- tabName %>" role="presentation">
      <a class="tab-content" href="#" role="tab" aria-selected="false">
      <span class="<%- tabIcon %>"></span>
      <span><%- tabText %></span>
      </a>
      </li>\
    `;
    this.storageKey = 'tabbedLayout';

    this.setInitialTabConfigs(options.initialTabConfigs);

    let initialTab = options.initialTab;
    this.showLoneTab = options.showLoneTab || false;

    if (initialTab == null) {
      const firstTabConfigs = _.first(this.getInitialTabConfigs()) || {};
      initialTab = firstTabConfigs.tabName;
    }
    this.setInitialTab(initialTab);

    this.listenTo(this.tabContentRegion, 'show', (childView) => {
      this._updateAccessibilityAttributes(childView);
    });

    this.addTab = this.addTab.bind(this);
    this._addTabButton = this._addTabButton.bind(this);
  }

  onRender() {
    this.$el.addClass('tabbed-layout');
    this._renderTabs();
    if (this._activeTab != null) {
      this._showTab(this._activeTab);
    }
    this._toggleTabBar();
  }

  onShow() {
    this.setInitialActiveTab(this.getInitialTab());
  }

  onRestoreInstanceState(bundleJSON) {
    let activeTab;
    if ((activeTab = bundleJSON._activeTab) != null) {
      this.setInitialTab(activeTab);
      this.setActiveTab(activeTab);
    }
  }

  onClickTab(e) {
    const tabName = $(e.currentTarget).data('tab');
    this.setActiveTab(tabName);
  }

  onKeydownTab(e) {
    const tabCount = this._tabs.length;
    if (!tabCount || tabCount === 1) {
      return;
    }

    // for accessibility and keyboard nav
    if (e.which === KeyCode.LEFT || e.which === KeyCode.RIGHT) {
      const indexBefore = this.$('li.selected').index();
      let indexAfter = (e.which === KeyCode.LEFT) ? indexBefore - 1 : indexBefore + 1;
      if (indexAfter >= tabCount) {
        indexAfter = 0;
      } else if (indexAfter < 0) {
        indexAfter = tabCount - 1;
      }

      const tabName = this._tabs[indexAfter].tabName;
      this.setActiveTab(tabName);
    } else if (e.which === KeyCode.SPACE) {
      const tabName = $(e.target).parent()
        .data('tab');
      if (tabName != null) {
        this.setActiveTab(tabName);
      }
    }
  }

  setInitialTabConfigs(initialTabConfigs) {
    this._initialTabConfigs = _.isArray(initialTabConfigs) || _.isObject(initialTabConfigs)
      ? [].concat(initialTabConfigs)
      : [];

    this._tabs = [].concat(this._initialTabConfigs);
  }

  getInitialTabConfigs() {
    return this._initialTabConfigs;
  }

  setInitialTab(initialTab) {
    this._initialTab = _.isString(initialTab)
      ? initialTab
      : '';
  }

  getInitialTab() {
    return this._initialTab;
  }

  setActiveTab(tabName) {
    if (!this.isValidTabName(tabName) || (tabName === this._activeTab)) {
      return;
    }

    if (this._activeTab != null) {
      this._hideTab(this._activeTab);
    }
    this._activeTab = tabName;
    this._showTab(tabName);
  }

  setInitialActiveTab(initialTab) {
    if ((this._activeTab == null)) {
      this.setActiveTab(initialTab);
    }
  }

  getActiveTab() {
    return this._activeTab;
  }

  addTab(tabOption = {}) {
    if (!this._isValidTabOption(tabOption)) {
      return;
    }

    this._tabs.push(tabOption);

    this._addTabButton(tabOption);

    this._toggleTabBar();

    this.setInitialActiveTab(this.getInitialTab());
  }

  addTabs(tabConfigs = []) {
    _.each(tabConfigs, this.addTab);
  }

  removeTab(tabName) {
    if (!this.isValidTabName(tabName)) {
      return;
    }

    this._removeTabButton(tabName);

    this._tabs = _.reject(this._tabs, (tabOption) => {
      return tabOption.tabName === tabName;
    });
  }

  showTabBar(show = true) {
    this.ui.tabContainer.toggleClass('hidden', !show);
  }

  getTabCount() {
    return this._tabs.length;
  }

  isValidTabName(tabName) {
    const valid = (this._getTabButtonUI(tabName) != null);
    if (!valid) {
      logging.warn(`Invalid tab name: ${ tabName }`);
    }
    return valid;
  }

  onSaveInstanceState(bundleJSON) {
    bundleJSON['_activeTab'] = this._activeTab;
  }

  _renderTabs() {
    _.each(this._tabs, this._addTabButton);
  }

  _addTabButton(tabOptions = {}) {
    const {tabName} = tabOptions;

    this._setTabButtonUI(tabName, this._createTabElement(tabOptions));
  }

  _removeTabButton(tabName) {
    this._setTabButtonUI(tabName);
  }

  _toggleTabBar() {
    this.showTabBar(this.showLoneTab || this.getTabCount() > 1);
  }

  _showTab(tabName) {
    this._selectTab(tabName);

    this.trigger('show:tab', tabName);
  }

  _hideTab(tabName) {
    this.tabContentRegion.empty();

    this.trigger('hide:tab', tabName);
  }

  _selectTab(tabName) {
    const $selected = this.$('.tab.selected');
    $selected.find('a').attr('aria-selected', false);
    $selected.removeClass('selected');

    this._getTabButtonUI(tabName).addClass('selected')
      .find('a')
      .attr('aria-selected', true)
      .trigger('focus');
  }

  _setTabButtonUI(tabName, $tab = null) {
    if ($tab != null) {
      this._$tabs[`tab-${ tabName }`] = $tab;
      this.ui.tabContainer.append($tab);
    } else {
      this._$tabs[`tab-${ tabName }`].remove();
      delete this._$tabs[`tab-${ tabName }`];
    }
  }

  _getTabButtonUI(tabName) {
    return this._$tabs[`tab-${ tabName }`];
  }

  _createTabElement(tabOptions = {}) {
    _.defaults(tabOptions, {
      tabText: '',
      tabIcon: 'hidden',
      tabClass: 'ax-grid__col--auto-size'
    });

    return $(Marionette.Renderer.render(this._tabTemplate, tabOptions, this));
  }

  _isValidTabOption(tabOptions) {
    return !_.isEmpty(tabOptions) && (tabOptions.tabName != null);
  }

  _tryGetOrSetId($el, idPreface) {
    let id;
    if (!$el.attr('id')) {
      id = _.uniqueId(idPreface);
      $el.attr('id', id);
    } else {
      id = $el.attr('id');
    }
    return id;
  }

  // Update accessibility attributes that help associate a tab with its child view for a screen reader
  _updateAccessibilityAttributes(childView) {
    const $tab = this.$('.tab.selected').find('a');
    const $tabPanel = childView.$el;

    const tabId = this._tryGetOrSetId($tab, 'tab_');
    const tabPanelId = this._tryGetOrSetId($tabPanel, 'tab_panel_');

    $tab.attr('aria-controls', tabPanelId);
    $tabPanel.attr('role', 'tabpanel').attr('aria-labelledby', tabId);
  }
}

module.exports = TabbedLayout;
