// Module
var code = `<div class="full-width relative">
  <div class="ax-container">
    <div class="page-content">
      <div class="message"><%- t('scorm.errorLoading') %></div>

      <div class="buttonbar">
        <button class="ax-button ax-button--branded retry"><%- t('general.retry') %></button>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;