const Marionette = require('Marionette');

const I18n = require('@common/libs/I18n');

require('@common/libs/behaviors/card/Card');
const {
  INFORMATIONAL,
  SMALL_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');

const { canDownload } = require('@common/libs/helpers/app/NativeBridgeHelpers');
const MediaViewModel = require('@common/components/media/MediaViewModel');

require('@common/components/media/AttachmentItemView.less');

class AttachmentItemView extends Marionette.ItemView {
  behaviors() {
    return {
      Card: {
        target: '.attachment__container',
        modifierClasses: [SMALL_PADDING, INFORMATIONAL]
      }
    };
  }

  constructor(options = {}) {
    super(options);

    ({
      enableDownload: this.enableDownload = true
    } = options);
  }

  triggers() {
    return {
      'click .attachment--viewable': 'click:attachment'
    };
  }

  get template() {
    return require('./AttachmentItemViewTemplate.html');
  }

  templateHelpers() {
    const viewModel = MediaViewModel.fromMedia(this.model);
    const uuid = viewModel.getUUID();
    const fileType = viewModel.getFileType();
    const isViewable = (fileType === 'pdf');
    const isDownloadable = canDownload() && this.enableDownload;
    const name = viewModel.getName();
    const ariaLabel = I18n.t('attachments.download', { name });

    return {
      id: this.model.get('id'),
      type: fileType,
      icon: viewModel.getIcon(),
      name,
      isViewable,
      isDownloadable,
      uuid,
      ariaLabel
    };
  }
}

module.exports = AttachmentItemView;
