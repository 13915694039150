const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  CONTINUE: 'continue',
  SKIP: 'skip',
  START_GAME: 'start-game',
  SUBMIT: 'submit',
  SUBMIT_GENERIC: 'submitgeneric',
  SUBMIT_MULTI_ANSWER: 'submitmultianswer',
  SUBMIT_HOTSPOT: 'submithotspot',
  SUBMIT_PUZZLE: 'submitpuzzle',
  HINT: 'hint',
  FINISH: 'finish',
  SHOW_CORRECT_MATCHES: 'showcorrectmatches',
  RIGHT_ARROW: 'rightarrow',
  BACK_HUB: 'back-hub',
  PROFILE_SAVE: 'profile-save',
  DONE_GAME: 'donegame',
  PREVIEW_LINK: 'previewLink',
  PREVIEW_PDF: 'previewPDF',
  PREVIEW_VIDEO: 'previewVideo',
  PREVIEW_MODULE: 'previewModule',
  VIEW_TRAINING: 'viewTraining',
  COMPLETED_TRAINING: 'completedTraining',
  SKIP_TRAINING: 'skipTraining',
  DONE: 'done',
  DISMISS_MODAL: 'dismiss-modal',
  DISMISS_UNENROLL_MODAL: 'dismiss-unenroll-modal',
  UNENROLL: 'unenroll',
  CANCEL: 'cancel',
  FEEDBACK: 'feedback',
  CUSTOM_TEXT: 'customText'
});

