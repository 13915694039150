// Module
var code = `<ul class="auctionlist ax-grid">
  <% _.each(items, function (item) { %>
  <li
    class="auctionitem ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-4 <% if(item.isLocked) { %>itemlocked<% } else { %>itemunlocked<% } %>"
    data-raffle-id="<%- item.id %>"
    id="raffle-<%- item.id %>">
    <div class="wrap">

      <% if(item.raffleTicketCount > 0) { %>
      <p class="boughtitem" aria-live="assertive"><%- t('auction.purchased') %></p>
      <% } else { %>
      <p class="boughtitem" style="display:none;" aria-live="assertive"><%- t('auction.purchased') %></p>
      <% } %>

      <div class="auctionimage">
        <div
          role="button"
          tabindex="0"
          aria-label="<%= t('auction.imageZoomLabel', { prizeName: item.prize.name }) %>"
          class="auctionitemimage image-wrapper"
        ></div>
        <div class="locked"></div>
      </div>

      <h2 class="qa-auction-title auctiontitle ax-font ax-font__display" title="<%= item.prize.name %>"><%= item.prize.name %></h2>

      <p class="auctiontime ax-font--no-margin ax-font--secondary">
        <% if(item.isLocked) { %>
        <%- t('auction.locked') %> <span class="unlocksin"></span><br />
        <% if(item.showReasonIfLocked) { %>
        <span class="prizeLockedReason"><%- item.prizeLockedReason %></span>
        <% } %>
        <% } else { %>
        <%- t('auction.timeremaining') %> <span class="timeleft"></span>
        <% } %>
      </p>

      <div class="auctionCost">
        <div class="amountbid ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(item.raffleTicketCount) %></div>
        <span class="pointBalance ax-font ax-font__caption"><%- t('auction.raffleItem.ticketsPurchased') %></span>
      </div>

    </div>

    <div class="wrapbuttons">
      <div class="auctionbuttons">
        <%= axButton({
          className: 'auction js-buynow',
          label: t('auction.raffleItem.buybtn'),
          ariaLabel: t('auction.raffleItem.buybtn') + ' ' + item.prize.name
        }) %>
      </div>
    </div>
  </li>
  <% }); %>
</ul>
`;
// Exports
module.exports = code;