const createResult = (isValid, message = '') => {
  return { isValid, message };
};

module.exports = (validationRules = []) => {
  return (options) => {
    for (const validator of validationRules) {
      if (!validator.isValid(options)) {
        return createResult(false, validator.resultMessage);
      }
    }

    return createResult(true);
  };
};
