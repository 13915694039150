const Enum = require('@common/data/enums/Enum');
const I18n = require('@common/libs/I18n');

const userTypeEqualityFn = (typeEnum) => {
  return (userType) => {
    return userType === typeEnum;
  };
};

const userTypeInGroupFn = (groupSet) => {
  return (userType) => {
    return groupSet.has(userType);
  };
};

function getUserTypeLabel(userType) {
  switch (this.assertLegalValue(userType)) {
    case this.DZGuest:
      return I18n.t('users.edit.profile.userType.options.dzGuest');
    case this.User:
      return I18n.t('users.edit.profile.userType.options.user');
    case this.SelfRegistered:
      return I18n.t('users.edit.profile.userType.options.self');
    case this.Report:
      return I18n.t('users.edit.profile.userType.options.report');
    case this.Content:
      return I18n.t('users.edit.profile.userType.options.content');
    case this.Manager:
      return I18n.t('users.edit.profile.userType.options.manager');
    case this.BuAdmin:
      return I18n.t('users.edit.profile.userType.options.teamAdmin');
    case this.BuContent:
      return I18n.t('users.edit.profile.userType.options.buContent');
    case this.Admin:
      return I18n.t('users.edit.profile.userType.options.admin');
    case this.Facilitator:
      return I18n.t('users.edit.profile.userType.options.facilitator');
    case this.TechAdmin:
      return I18n.t('users.edit.profile.userType.options.techAdmin');
    default:
      return '';
  }
}

const UserTypes = {
  Admin: 'Admin',
  BuAdmin: 'TeamAdmin',
  TechAdmin: 'TechnicalAdmin',
  User: 'User',
  Content: 'Content',
  Report: 'Report',
  Manager: 'Manager',
  SelfRegistered: 'SelfRegistered',
  DZGuest: 'Guest',
  BuContent: 'BuContent',
  Facilitator: 'Facilitator'
};

// Feature Level Access Groups
const AdminGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin
]);

const CustomAccessGroup = new Set([
  UserTypes.Report,
  UserTypes.Manager,
  UserTypes.Facilitator
]);

const CustomAccessReportAllGroup = new Set([
  UserTypes.Manager,
  UserTypes.BuAdmin
]);

const BuAssignableGroup = new Set([
  UserTypes.BuAdmin,
  UserTypes.BuContent
]);

const UserImporterGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Manager
]);

const UsersAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Manager
]);

const ExportsAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Content,
  UserTypes.BuContent,
  UserTypes.Report,
  UserTypes.Facilitator,
  UserTypes.Manager
]);

const ExportSubscriptionsAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Report,
  UserTypes.Facilitator,
  UserTypes.Manager
]);

const ScheduledExportsAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Report,
  UserTypes.Facilitator,
  UserTypes.Manager
]);

const ReportGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Manager,
  UserTypes.Report
]);

const ContentExchangeAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Content,
  UserTypes.BuContent
]);

const ContentAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Content,
  UserTypes.BuContent
]);

const FeedbackAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Content,
  UserTypes.BuContent
]);

const EventFacilitatorUserGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Manager,
  UserTypes.Report,
  UserTypes.Facilitator
]);

const EventsAdminUserGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Facilitator
]);

const ContentReportExportAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Content,
  UserTypes.BuContent
]);

const FeedbackOpenCountAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Content,
  UserTypes.BuContent
]);

const TasksManagerAcessGroup = new Set([
  UserTypes.Facilitator,
  UserTypes.Report,
  UserTypes.Manager,
  UserTypes.Admin,
  UserTypes.BuAdmin
]);

const SupportAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.Manager,
  UserTypes.Report,
  UserTypes.Facilitator,
  UserTypes.Content,
  UserTypes.BuAdmin,
  UserTypes.BuContent,
  UserTypes.SelfRegistered,
  UserTypes.DZGuest
]);

const SettingsAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.TechAdmin
]);

const ContentUsageReportExportAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin
]);

const UserImpersonationGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin
]);

// App Level Access Groups
const AdminAppAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.TechAdmin,
  UserTypes.Manager,
  UserTypes.Report,
  UserTypes.Facilitator,
  UserTypes.Content,
  UserTypes.BuContent
]);

const StrictAdminAppAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.TechAdmin,
  UserTypes.Manager,
  UserTypes.Facilitator,
  UserTypes.Content,
  UserTypes.BuContent
]);

const ManagerAppAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.BuAdmin,
  UserTypes.Manager,
  UserTypes.Report,
  UserTypes.Facilitator
]);

const TrainingAppAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.User,
  UserTypes.Manager,
  UserTypes.Report,
  UserTypes.Facilitator,
  UserTypes.Content,
  UserTypes.BuAdmin,
  UserTypes.BuContent,
  UserTypes.SelfRegistered,
  UserTypes.DZGuest
]);

const KnowledgeAppAccessGroup = new Set([
  UserTypes.Admin,
  UserTypes.User,
  UserTypes.Manager,
  UserTypes.Report,
  UserTypes.Facilitator,
  UserTypes.Content,
  UserTypes.BuAdmin,
  UserTypes.BuContent,
  UserTypes.SelfRegistered,
  UserTypes.DZGuest
]);

// Various UserType permission groupings
const userTypeHelpers = {
  AdminAppAccessGroup,
  StrictAdminAppAccessGroup,
  TrainingAppAccessGroup,
  ManagerAppAccessGroup,
  KnowledgeAppAccessGroup,

  AdminGroup,
  ReportGroup,

  UserImporterGroup,
  ContentExchangeAccessGroup,
  CustomAccessGroup,
  CustomAccessReportAllGroup,
  BuAssignableGroup,
  ScheduledExportsAccessGroup,
  EventFacilitatorUserGroup,
  ContentReportExportAccessGroup,

  isAdminUser: userTypeEqualityFn(UserTypes.Admin),
  isBuAdminUser: userTypeEqualityFn(UserTypes.BuAdmin),
  isTechAdminUser: userTypeEqualityFn(UserTypes.TechAdmin),
  isUserUser: userTypeEqualityFn(UserTypes.User),
  isSelfRegisteredUser: userTypeEqualityFn(UserTypes.SelfRegistered),
  isManagerUser: userTypeEqualityFn(UserTypes.Manager),
  isContentUser: userTypeEqualityFn(UserTypes.Content),
  isReportUser: userTypeEqualityFn(UserTypes.Report),
  isDZGuestUser: userTypeEqualityFn(UserTypes.DZGuest),
  isBuContentUser: userTypeEqualityFn(UserTypes.BuContent),
  isFacilitatorUser: userTypeEqualityFn(UserTypes.Facilitator),

  hasAdminAppAccess: userTypeInGroupFn(AdminAppAccessGroup),
  hasStrictAdminAppAccess: userTypeInGroupFn(StrictAdminAppAccessGroup),
  hasTrainingAppAccess: userTypeInGroupFn(TrainingAppAccessGroup),
  hasManagerAppAccess: userTypeInGroupFn(ManagerAppAccessGroup),
  hasKnowledgeAppAccess: userTypeInGroupFn(KnowledgeAppAccessGroup),
  hasFeedbackOpenCountAccess: userTypeInGroupFn(FeedbackOpenCountAccessGroup),
  hasAdminGroupAccess: userTypeInGroupFn(AdminGroup),
  hasReportGroupAccess: userTypeInGroupFn(ReportGroup),

  hasUserImporterGroupAccess: userTypeInGroupFn(UserImporterGroup),
  hasUsersGroupAccess: userTypeInGroupFn(UsersAccessGroup),
  isCustomAccessUser: userTypeInGroupFn(CustomAccessGroup),
  hasContentExchangeAccess: userTypeInGroupFn(ContentExchangeAccessGroup),
  hasContentGroupAccess: userTypeInGroupFn(ContentAccessGroup),
  hasFeedbackGroupAccess: userTypeInGroupFn(FeedbackAccessGroup),
  hasSupportGroupAccess: userTypeInGroupFn(SupportAccessGroup),
  hasSettingsGroupAccess: userTypeInGroupFn(SettingsAccessGroup),
  hasEventsAdminGroupAccess: userTypeInGroupFn(EventsAdminUserGroup),
  canSetAllReportCustomAccess: userTypeInGroupFn(CustomAccessReportAllGroup),
  isBuAssignable: userTypeInGroupFn(BuAssignableGroup),
  hasExportsAccess: userTypeInGroupFn(ExportsAccessGroup),
  hasExportSubscriptionsAccess: userTypeInGroupFn(ExportSubscriptionsAccessGroup),
  hasScheduledExportsAccess: userTypeInGroupFn(ScheduledExportsAccessGroup),
  isValidEventsFacilitatorUser: userTypeInGroupFn(EventFacilitatorUserGroup),
  canAccessContentReportExport: userTypeInGroupFn(ContentReportExportAccessGroup),
  canAccessContentUsageReportExport: userTypeInGroupFn(ContentUsageReportExportAccessGroup),
  canImpersonateUsers: userTypeInGroupFn(UserImpersonationGroup),
  hasTasksManagerAccess: userTypeInGroupFn(TasksManagerAcessGroup),

  getUserTypeLabel
};

module.exports = Enum(UserTypes, userTypeHelpers);
