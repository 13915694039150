// Module
var code = `<div class="modalHeader">
  <h1><%- t('auction.placebid') %></h1>
  <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
</div>
<div class="placebid">
  <% if (model.rewardProgramPrize.prize.prizeImg) { %>
    <div
      role="button"
      tabindex="0"
      aria-label="<%= t('auction.imageZoomLabel', { prizeName: model.rewardProgramPrize.prize.name }) %>"
      class="ax-button ax-button--ghost itemimage image-wrapper"
    ></div>
  <% } %>

  <div class="title ax-font ax-font__display"><%= model.rewardProgramPrize.prize.name %></div>

  <div class="bid__input--wrapper">
    <label for="bid"><%- t('auction.bidLabel') %></label>
    <input aria-label="<%- t('auction.yourbid') %>" id="bid" type="number" onPaste="return false" min="1"<% if(model.bid) { %> value="<%- model.bid.points %>"<% } %>>
  </div>

  <div class="notice" aria-live="assertive"></div>

  <% if(!model.bid) { %>
  <p class="minbid">
    <%- numberWithCommas(model.minWinningBid || model.rewardProgramPrize.minBid) %> <%- t('auction.minimum') %>
  </p>
  <% } %>
</div>

<% if (model.rewardProgramPrize.rewardProgram.showBidInfo) { %>
<div class="biddetails biddetails--with-border show-bid-info">
  <div class="biddetailsinner ax-grid ax-grid--no-gutter clearfix">

    <p class="biddetail bids">
      <span class="biddetail-label ax-font ax-font__caption"><%- t('auction.bidcount') %></span>
      <span class="bidcount ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(model.bidCount) %></span>
    </p>

    <p class="biddetail currentbid">
      <span class="biddetail-label ax-font ax-font__caption"><%- t('auction.current') %></span>
      <span class="amountbid ax-font ax-font__m ax-font--no-margin">
        <% if(model.isWinning) { %>
          <%- numberWithCommas(model.bid.points) %>
        <% } else if (model.bidCount > 0) { %>
          <%- numberWithCommas(model.minWinningBid) %>
        <% } else { %>
          0
        <% } %>
        </span>
    </p>
<% } else { %>

<div class="biddetails">
  <div class="biddetailsinner ax-grid ax-grid--no-gutter clearfix">

<% } %>

    <p class="biddetail balance ax-grid__col--12 ax-grid__col--m-4">
      <span class="biddetail-label ax-font ax-font__caption"><%- t('auction.pointBalance') %></span>
      <span class="amountbid ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(pointBalance) %></span>
    </p>

  </div>

</div>
`;
// Exports
module.exports = code;