const _ = require('underscore');
const AdjustableModalView = require('@training/apps/main/views/AdjustableModalView');

class GameErrorModal extends AdjustableModalView {

  get id() {
    return 'game-error-modal';
  }

  contentTemplate() {
    const templateFn = _.tpl(`\
      <h1 class="games-error-name center"><%- t('games.errors.limit.title')%></h1>
      <div class="game-error-content center"><span><%- t('games.errors.limit.description')%></span></div>
      <div class="clear"></div>\
    `);

    return templateFn();
  }

  onRenderActionBar() {
    this.actionBar.setActionButton({
      type: 'finish',
      onClick: this.onClose.bind(this)
    });
  }

  onClose() {
    this.dismiss();
    super.onClose();
  }
}

module.exports = GameErrorModal;
