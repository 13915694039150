const {
  CollectionView,
  ItemView
} = require('Marionette');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const I18n = require('@common/libs/I18n');
const logging = require('logging');


const getTopicProgressCardControllerDefinition = (data = {}, cardOptions = {}) => {

  // Make sure we have the required details
  if (!data.topicProgressItemList) {
    logging.error('The TopicProgressItemList object is required to generate the TopicProgressCardControllerDefinition');
    return null;
  }

  // Set the default options for a TopicProgress card
  const defaultOptions = {
    cardClass: 'base-card--topic-progress',
    titleOptions: {
      title: I18n.t('hub.topicProgress')
    },
    hasLink: true,
    linkOptions: {
      target: 'fullCard',
      linkTextKeyOverride: 'hub.topicProgressLinkText',
      linkAriaLabel: I18n.t('hub.topicProgressLinkText'),
      callback: data.callback
    },
    tabbableLinkClickableCard: true,
    bottomWrapperControllerDefinition: {
      viewDefinition: {
        ViewClass: CollectionView,
        collection: data.topicProgressItemList,
        tagName: 'ul',
        className: 'topic-progress__list flex-fill-height-or-more full-width',
        childView: ItemView,
        childViewOptions: {
          tagName: 'li',
          className: 'topic-progress__row',
          template: `
            <div class="topic-progress__row--wrapper">
              <div class="icon-<%= id %>" role="presentation" aria-hidden="true"></div>
              <span class="name"><%= name %></span>
              <span class="value semiboldfont"><%= value %></span>
            </div>
          `
        }
      }
    }
  };

  // Load/return the config object for this particular card
  return getBaseCardControllerDefinition(Object.assign(defaultOptions, cardOptions));
};


module.exports = {
  getTopicProgressCardControllerDefinition
};
