const Behaviors = require('@common/libs/behaviors/Behaviors');
const ZoomOverlay = require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');

class TimelineZoomOverlay extends ZoomOverlay {
  onImageMouseEnter(e) {
    const $currentTarget = this.$(e.currentTarget);

    const $zoomImg = $currentTarget.find('[data-media-id]');

    if (!this._isReady($zoomImg)) {
      return;
    }

    let $zoomOverlay = $currentTarget.find('.zoom-overlay');

    if ($zoomOverlay.length === 0) {
      $currentTarget.append($zoomOverlay = $(this.overlayTemplate));
    }

    $zoomOverlay.toggleClass('hidden', false);
  }
}

Behaviors.TimelineZoomOverlay = TimelineZoomOverlay;

module.exports = Behaviors.TimelineZoomOverlay;
