// Module
var code = `<form>
  <% if (gameChallengeWager > 0) { %>
    <div class="card card--medium challenge__wager-card js-wager-card">
      <%= axCheckbox({
        id: 'challenge-chkbox',
        label: '<span><span class="wager-meta">' + t('selectGame.gameChallenge.wagerLabel') + ' </span> ' + t('selectGame.gameChallenge.wagerInfo', {"wager": gameChallengeWager}) + '</span>'
      }) %>
    </div>
  <% } %>

  <div class="user-search clearfix" role="search">
    <%= axSearch({
      ariaControls: 'challengee-list',
      fullWidth: true,
      placeholder: t('selectGame.gameChallenge.searchLabel')
    }) %>
    <p class="off-screen" id="number-of-results" aria-live="polite"></p>
  </div>
</form>

<ul class="challengee-list" id="challengee-list"></ul>
`;
// Exports
module.exports = code;