// Module
var code = `<div class="froala-editor"></div>
<div class="group group--image">
  <form>
    <div class="media-input-wrap hidden">
      <input type="file" name="imageFile" accept="image/*" id="local-image" />
    </div>
  </form>
</div>
<div class="group group--video">
  <form>
    <div class="media-input-wrap hidden">
      <input type="file" accept="<%- allowedVideoMimeTypes %>" id="local-video" />
    </div>
  </form>
</div>
`;
// Exports
module.exports = code;