const _ = require('underscore');
const AdjustableModalView = require('@training/apps/main/views/AdjustableModalView');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');

class ChallengeSelectionModal extends AdjustableModalView {
  id() {
    return 'challenge-selection-modal';
  }

  events() {
    return Object.assign({}, super.events(), {
      'click .challenge': 'selectChallenge'
    });
  }

  constructor(options = {}) {
    super(options);

    (({
      challenges: this.activeChallenges,
      availableOpponents: this.availableOpponents,
      currentTeam: this.currentTeam,
      complete: this.complete = () => {}
    } = options));
  }

  contentTemplate() {
    const templateFn = _.tpl(require('../templates/ChallengeSelectionModal.html'));
    return templateFn(this.computeData());
  }

  computeData() {
    // Setup data for template
    this.challengeList = this.activeChallenges.map((challenge) => {
      // get challenge id, team names and scores
      const newChallenge = {
        id: challenge.id,
        opponent: {},
        currentTeam: {},
        timeClass: ''
      };

      // determine time remaining
      const timeRemaining = challenge.gameEndsInMillis;
      if (timeRemaining < 0) {
        newChallenge.timeRemaining = I18n.t('games.teamGame.challengeSelection.overtime');
        newChallenge.timeClass = 'overtime';
      } else {
        const gameEndsAtTimestamp = Date.now() + timeRemaining;
        newChallenge.timeRemaining = dateHelpers.timeFromEvent(gameEndsAtTimestamp, {withSuffix: false});

        // if time remaining is less than 1 day, set the timeClass to 'amber'
        if (timeRemaining < 86400000) {
          newChallenge.timeClass = 'amber';
        }
      }

      // go through participants to get required data
      challenge.participants.forEach((participant) => {
        const participantScore = _.find(challenge.currentScore.participantScores, (score) => {
          return score.participantId === participant.id;
        });

        if (this.currentTeam.id === participant.team.id) {
          newChallenge.currentTeam.team = this.currentTeam;
          newChallenge.currentTeam.score = participantScore.score;
          return;
        }

        // this is the opponent so get the id, team and score
        newChallenge.opponent.id = participant.team.id;
        newChallenge.opponent.team = participant.team;
        newChallenge.opponent.score = participantScore.score;
      });

      return newChallenge;
    });

    // round up games to 10 with available opponents
    let i = 0;
    while ((this.challengeList.length < 10) && (this.availableOpponents.length > 0)) {
      const opponent = this.availableOpponents.splice(0, 1)[0];
      this.challengeList.push({
        id: --i,
        currentTeam: {
          team: this.currentTeam,
          score: 0
        },
        timeRemaining: '-',
        opponent: {
          id: opponent.id,
          team: opponent,
          score: 0
        }
      });
    }

    return {challenges: this.challengeList};
  }

  selectChallenge(e) {
    // get the challenge id and let game manager know that
    // a challenge has been selected
    const challengeId = $(e.target).closest('.challenge')
      .data('challenge-id');

    // get challenge
    const challenge = _.find(this.challengeList, (chall) => {
      return chall.id === challengeId;
    });

    this.dismiss();
    return (typeof this.complete === 'function' ? this.complete(challenge, this.options) : undefined);
  }
}

module.exports = ChallengeSelectionModal;
