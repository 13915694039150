const { ReactControllerDefinitionFactory } = require('@common/modules/react');

module.exports = (authUser, callback) => {
  return () => {
    return ReactControllerDefinitionFactory({
      component: import('@training/apps/home/hubPreviews/leaderboard/LeaderboardCard'),
      props: {
        authUserId: authUser.id,
        callback
      }
    });
  };
};
