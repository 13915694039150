/*
  This job is "fire and forget" and there is no waiting, even if some of the operations are not fire and forget
*/


const getCurrentAssessmentId = (session) => {
  const currentAssessment = session.getCurrentAssessment();
  return currentAssessment.id;
};

const isTimestampPastSessionBoundaryTiming = (timestamp, session) => {
  const sessionCreationDate = session.getCreateDate();
  const timestampWasBeforeSession = timestamp < sessionCreationDate;

  // 3600 is one hour
  const sessionTimeStampDelta = Math.abs(sessionCreationDate - timestamp);
  const hasSessionExpirationTimeElapsed = (sessionTimeStampDelta / 3600) >= session.getSessionExpirationThresholdInHours();

  return timestampWasBeforeSession && hasSessionExpirationTimeElapsed;
};

module.exports = {
  prune: (tracker, trainingSession) => {
    const entries = tracker.asEntryIterator();
    const currentAssessmentId = getCurrentAssessmentId(trainingSession);

    const deadEntries = entries.filter(
      (entry) => {
        const entryId = entry.getAssessmentId();
        const timestamp = entry.getTimestamp();
        const hasCompletedToday = trainingSession.hasCompletedSomeAssessmentWithId(entryId);
        const isNoLongerCaredAbout = (entryId !== currentAssessmentId) || hasCompletedToday;
        const timestampDeclaresInvalid = isTimestampPastSessionBoundaryTiming(timestamp, trainingSession);

        // There is two checks since we have to consider that local storage can come from other users as well in the shared terminal case
        return isNoLongerCaredAbout && timestampDeclaresInvalid;
      }
    );

    deadEntries.forEach((entry) => {
      tracker.stopTrackingAssessmentId(entry.getAssessmentId());
    });

    return deadEntries.length;
  }
};
