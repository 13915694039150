const { LayoutView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const FileFactory = require('@common/libs/file/FileFactory');
const MediaInput = require('@common/components/forms/editors/mediaThumbnail/Form.Editor.MediaInput');
const ImageCropImagePreviewsView = require('@common/components/forms/editors/imageCrop/ImageCropImagePreviewsView');
const ImageFile = require('@common/libs/file/ImageFile');

require('@common/components/forms/editors/imageCrop/ImageCropEditor.less');

class ImageCropAddImageView extends LayoutView {
  get className() {
    return 'image-crop';
  }

  get template() {
    return require('@common/components/forms/editors/imageCrop/ImageCropAddImageView.html');
  }

  templateHelpers() {
    return {
      maximumImageSize: ImageFile.SIZE_LIMIT
    };
  }

  ui() {
    return {
      cameraButton: '.js-camera-button'
    };
  }
}

class ImageCropAddImageController extends LayoutController {
  initialize(options = {}) {
    ({
      isImageRequired: this.isImageRequired,
      thumbnailSizes: this.thumbnailSizes,
      allowedExtensions: this.allowedExtensions
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: ImageCropAddImageView,
      regions: {
        fileInput: '.media-thumbnail-input',
        mediaPreviews: '.media-previews'
      }
    };
  }

  regionControllers() {
    return {
      fileInput: {
        viewDefinition: () => {
          return {
            ViewClass: MediaInput,
            $cameraButton: this.getView().ui.cameraButton,
            fileFactory: FileFactory,
            fileOptions: {
              videoAllowed: false,
              allowedExtensions: this.allowedExtensions
            }
          };
        },
        delegateEvents: {
          'view:change': (controller, editor) => {
            if (editor.getError() != null) {
              this.triggerMethod('error:media', editor.getError());
              editor.clearError();
            } else {
              this.triggerMethod('change:media', editor);
            }
          }
        }
      },
      mediaPreviews: {
        viewDefinition: () => {
          return {
            ViewClass: ImageCropImagePreviewsView,
            isImageRequired: this.isImageRequired,
            thumbnailSizes: this.thumbnailSizes
          };
        }
      }
    };
  }
}

module.exports = ImageCropAddImageController;
