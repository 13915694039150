const Backbone = require('Backbone');
const TaskAttachment = require('@common/data/models/TaskAttachment');

class TaskAttachmentList extends Backbone.Collection {
  initialize(models, options) {
    this.model = TaskAttachment;
    super.initialize(models, options);
  }
}

module.exports = TaskAttachmentList;
