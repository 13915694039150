const { ItemView } = require('Marionette');
const { history } = require('Backbone');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

const KeyCode = require('@common/data/enums/KeyCode');
const I18n = require('@common/libs/I18n');
const logging = require('logging');

const Form = require('@common/components/forms/Form');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');
const SettingsTabModel = require('@training/apps/search/communitiesManagement/models/SettingsTabModel');

class SettingsTabView extends ItemView {
  initialize() {
    this.model = new SettingsTabModel();

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/settings/SettingsTabView.html');
  }

  ui() {
    return {
      form: '.js-form',
      info: '.js-info-icon',
      disclaimer: '.js-disclaimer'
    };
  }

  events() {
    return {
      'click @ui.info': this._openDisclaimer,
      'keyup @ui.info': this._onKeyupDisclaimerButton,
      'focus @ui.disclaimer': 'onFocus',
      'blur @ui.disclaimer': 'onBlur'
    };
  }

  onFocus() {
    // focussing on any element in this component should cancel any timed requests to close the popover
    clearTimeout(this.timeout);
  }

  // Required for keyboard navigation to work properly. This would normally be enough to hide the dropdown menus
  // when the user clicks away, however, Safari and FF don't give focus to clicked buttons, so no blur happens
  // and attempting to enforce a focus/blur leads to other issues,
  // Ex. dropdown disappears before click event registers or dropdown hides before a longer click is completed.
  onBlur() {
    clearTimeout(this.timeout);
    // This code would accomplish the "click elsewhere to close" behaviour on the menu if not for the above issues.
    // There is a really elegant way to accomplish this using e.relatedTarget; for a good example of that go
    // and look at the code for the Reactions Tray in ReactionsView.js.  All fine and dandy, but e.relatedTarget won't
    // work in FF38. So for all browsers we are going to use an old hack using a timer. Basically when we blur away
    // from an element, we set a timer; if there is a focus event on something within this same component then we
    // cancel that timer. The result is that we get an action that will happen when we click away or tab away from
    // the menu, and that action is to close the popover.
    this.timeout = setTimeout(this.clearAndHide.bind(this), 10);
  }

  clearAndHide() {
    // in case there is a timer waiting to do the hide... kill it here
    clearTimeout(this.timeout);
    this._closeDisclaimer();
  }

  _onKeyupDisclaimerButton(e) {
    e.preventDefault();
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      this._openDisclaimer();
    }
  }

  _openDisclaimer() {
    this.ui.disclaimer.removeClass('hidden');
    this.ui.disclaimer.attr('tabindex', '-1').trigger('focus');
  }

  _closeDisclaimer() {
    this.ui.disclaimer.addClass('hidden');
  }

  onRender() {
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        enableTranslationsOptions: {
          ariaLabel: I18n.t('communitiesManagement.settings.enableTranslationsSwitch')
        }
      }
    });

    this.listenTo(this.model, 'change', () => {
      this.model.saveProperties().done(
        () => {
          logging.info(`Successfully updated Translations Enabled tenant property`);
        }
      )
        .fail((xhr) => {
          let error;
          xhr.skipGlobalHandler = true;

          const exception = AxonifyExceptionFactory.fromResponse(xhr);
          const errorCode = exception.getErrorCode();
          logging.error(exception.getErrorMessage());

          switch (errorCode) {
            case AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED:
              error = I18n.t('discover.pageAccess.community.error.3017');
              break;
            case AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY:
              error = I18n.t('discover.pageAccess.community.error.3001');
              break;
            case AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE:
              error = I18n.t('discover.access.error.2012');
              break;
            default:
              xhr.skipGlobalHandler = false;
          }

          if (error) {
            window.app.layout.flash.error(error);
          }

          history.navigate('#hub/search/communitiesManagement/communities/1', true);
        });
    });
  }

  onAttach() {
    triggerResize(true);
  }
}

module.exports = SettingsTabView;
