const logging = require('logging');
const Marionette = require('Marionette');

const dateHelpers = require('@common/libs/dateHelpers');
const I18n = require('@common/libs/I18n');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const ProfileIconHandler = require('@common/components/ProfileIconHandler');

require('@common/libs/behaviors/card/Card');

class ReferralPendingView extends Marionette.LayoutView {
  getTemplate() {
    return require('../templates/ReferralPendingView.html');
  }

  events() {
    return {
      'click .invite-button-bar .js-remove-bounty': 'removeBountyClicked'
    };
  }

  templateHelpers() {
    return {
      claimed: this.pending,
      user: this.pending.target,
      department: this._getDepartment(),
      bonusPointsDuration: this.configuration.get('bonusPointsDuration'),
      daysLeft: dateHelpers.getDaysRemaining(apps.auth.session.serverTimeOffset, this.pending.expiryDate)
    };
  }

  behaviors() {
    return {
      Card: {
        target: '.claimed-invite'
      }
    };
  }

  initialize() {
    ({
      collection: this.collection,
      referrals: this.referrals,
      pending: this.pending,
      configuration: this.configuration
    } = this.options);
  }

  _getDepartment() {
    const user = this.pending.target;

    if (user.department && user.department.name) {
      return user.department.name;
    } else if (user.location && user.location.name) {
      return user.location.name;
    }
    return '';

  }

  onRender() {
    this.iconHandler = new ProfileIconHandler({
      user: this.pending.target,
      profileElement: this.$('.large-name-logo')
    });
  }

  removeBountyClicked(e) {
    if (this.removingBounty) {
      return;
    }
    this.removingBounty = true;

    // get id of user
    const referralId = $(e.currentTarget).data('referral-id');

    this.referrals.removeBounty(referralId, {
      success: () => {
        this.destroy();
      },
      error: (response) => {
        let errorMsg;
        const exception = AxonifyExceptionFactory.fromResponse(response);
        const errCode = exception.getErrorCode();

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_BOUNTY_CLOSED) {
          response.skipGlobalHandler = true;
          errorMsg = I18n.t('referral.errors.3094');
        } else {
          errorMsg = I18n.t('referral.errors.failedToRemoveBounty');
        }
        logging.error(`error occured while cancelling a bounty: ${ errorMsg } with errorCode: ${ errCode }`);
        window.app.layout.flash.error(errorMsg);
        this.destroy();
      }
    });
  }
}

module.exports = ReferralPendingView;
