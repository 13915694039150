const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const Backbone = require('Backbone');
const _ = require('underscore');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const BASE_SEARCH_HASH = '#hub/search';

// Backbone source keeps a decoded reference to the URL hash
// Each time the hash changes, Backbone compares their decoded reference to the actual URL hash
// When these differ, it results in another Backbone navigate call...
// For us that means one extra navigate call and one extra request to the server for search
// We limit differences by replacing space with '__' because '_' does not get encoded
const convertTextToBackboneUrlHashString = (value = '') => {
  try {
    return encodeURIComponent(value.replace(/ /g, '__'));
  } catch (e) {
    return value;
  }
};

const convertBackboneUrlHashStringToText = (value = '') => {
  try {
    return decodeURIComponent(value.replace(/__/g, ' '));
  } catch (e) {
    return value;
  }
};

const getHashForCurrentTabState = (pageState, tab, updatePageNum = false) => {
  let searchCategory, subCategory;
  const reportsSubCategories = [
    SearchSubCategoryEnum.ARTICLE_REPORTS,
    SearchSubCategoryEnum.TOP_PAGES,
    SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH,
    SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH
  ];

  if (pageState != null) {
    searchCategory = pageState.get('searchCategory');
    subCategory = pageState.get('subCategory');
  }

  if (pageState == null || !_.isFunction(pageState.get)) {
    return `${ BASE_SEARCH_HASH }/${ SearchCategoryEnum.ALL }/1/`;
  } else if (searchCategory === SearchCategoryEnum.ARTICLES && _.contains(reportsSubCategories, subCategory)) {
    return window.location.hash;
  }

  const tabName = tab || searchCategory || SearchCategoryEnum.ALL;

  const tabUrlString = encodeURIComponent(tabName);
  let pageNum = (pageState.get('pageNum') + 1) || 1;
  const searchQuery = convertTextToBackboneUrlHashString(pageState.get('searchString'));
  const pageTypeUriFragment = getPageTypeUriFragment(pageState, tabName);

  // If you are going to update the pageNum from the current results (if applicable) - determine what it should be
  if (updatePageNum) {
    if (tabName !== SearchCategoryEnum.ALL) {
      pageNum = 1;

      if (searchQuery || pageTypeUriFragment) {
        const curTabResults = (pageState.get('results') || {})[tabName];
        const pageInResults = curTabResults
          ? (curTabResults.state && curTabResults.state.currentPage) || 0
          : 0;

        pageNum += pageInResults;
      }
    }

    //  Update the pageState appropriate value
    pageState.set('pageNum', pageNum - 1);
  }

  return `${ BASE_SEARCH_HASH }/${ tabUrlString }/${ pageTypeUriFragment }${ pageNum }/${ searchQuery }`;
};

const getHashForBrowseCommunities = (pageState, tabName) => {
  let pageNum = 1;
  const type = pageState.get('communityType');
  const tabUrlString = encodeURIComponent(type || tabName);
  const pageTypeUriFragment = getPageTypeUriFragment(pageState, tabName);

  const curTabResults = pageState.get('communityList');
  const pageInResults = (curTabResults && curTabResults.state && curTabResults.state.currentPage) || 0;

  pageNum += pageInResults;

  return `${ BASE_SEARCH_HASH }/${ tabUrlString }/${ pageTypeUriFragment }${ pageNum }`;
};

const getHashForCommunitySearch = (pageState) => {
  if (pageState == null || !_.isFunction(pageState.get)) {
    return `${ BASE_SEARCH_HASH }/${ SearchCategoryEnum.ALL }/1/`;
  }

  let communityUrl = '';
  const searchQuery = convertTextToBackboneUrlHashString(pageState.get('searchString'));
  const community = pageState.get('community');
  const communityId = `community-${ community.get('id') }`;
  const communityTab = encodeURIComponent(pageState.get('communitySearchCategory'));
  const tagList = pageState.get('results') ? _getTagsHashForArticleCollection(pageState) : '';
  const pageNum = pageState.get('pageNum') + 1;

  if (communityTab === SearchCategoryEnum.ARTICLES) {
    communityUrl = `${ BASE_SEARCH_HASH }/${ communityId }/${ communityTab }/${ tagList }${ pageNum }`;
  } else {
    communityUrl = `${ BASE_SEARCH_HASH }/${ communityId }/${ communityTab }/${ pageNum }`;
  }

  if (searchQuery) {
    return `${ communityUrl }/${ searchQuery }`;
  }
  return communityUrl;
};

const isHashMissingCommunityTab = (community, hash, tabNames) => {
  let isMissing = true;
  const communityId = `community-${ community.get('id') }`;
  const uriParts = hash.split(communityId);

  if (uriParts.length > 1) {
    const foundTab = _.find(tabNames, (tabName) => {
      return uriParts[1].indexOf(tabName) > -1;
    });

    isMissing = !foundTab;
  }

  return isMissing;
};

// Returns the trailing page number in the communities import history path as an int
const getImportPageNumberFromUrl = () => {
  const hash = UrlHelpers.getLocationHash();
  const fragments = hash.split('/');
  return parseInt(fragments[fragments.length - 1], 10) || 1;
};

// Updates the trailing page number in the communities import history path after a successful fetch
const updateImportHistoryPageNumberInUrl = (importHistoryCollection) => {
  const newPageNum = importHistoryCollection.state.currentPage + 1;
  const newHash = `hub/search/communitiesManagement/importHistory/${ newPageNum }`;
  Backbone.history.navigate(newHash, { trigger: false });
};

const updatePageNumberInUrl = (pageState, collection) => {
  const pageInResults = collection && collection.state ? (collection.state.currentPage || 0) : 0;

  pageState.set('pageNum', pageInResults);

  const newHash = getHashForCommunitySearch(pageState);

  Backbone.history.navigate(
    newHash,
    { trigger: false }
  );
};

const getPageTypeUriFragment = (pageState, tabName) => {
  if (tabName !== SearchCategoryEnum.ARTICLES) {
    return '';
  }
  const community = pageState.get('community') ? `community-${ pageState.get('community').get('id') }/` : '';
  const tagList = pageState.get('results') ? _getTagsHashForArticleCollection(pageState) : '';

  // We don't want to filter within pending or favorites pages if the user is searching. Search all articles instead.
  if (pageState.get('searchString') != null
    && pageState.get('searchString') !== ''
    && pageState.get('subCategory') !== SearchSubCategoryEnum.COMMUNITY) {
    pageState.unset('subCategory');
  }

  const uriFragments = {};
  uriFragments[SearchSubCategoryEnum.COMMUNITY] = `${ community }${ tagList }`;
  uriFragments[SearchSubCategoryEnum.PENDING] = 'needs-review/';
  uriFragments[SearchSubCategoryEnum.FAVORITES] = 'favorites/';

  return uriFragments[pageState.get('subCategory')] || tagList;
};

const parseSearchFacets = (tagListString = '') => {
  const result = [];

  if (tagListString.length > 0) {
    const tags = tagListString.split('--');

    tags.forEach((tag) => {
      const groupedTags = tag.split(',');

      if (groupedTags.length > 1) {
        const initialUrlGroupId = _.uniqueId('url-group-');

        groupedTags.forEach((tagItem) => {
          result.push(_getTagObject(tagItem, initialUrlGroupId));
        });
      } else {
        result.push(_getTagObject(tag));
      }
    });
  }

  return result;
};

// Helper Functions
const _getTagsHashForArticleCollection = (pageState) => {
  // If you don't have a viable pageState object - stop
  if (!pageState || !_.isFunction(pageState.get)) {
    return '';
  }

  // If you can't find an article collection object, you won't need tags for it
  const collection = pageState.get('results')[SearchCategoryEnum.ARTICLES];
  if ( !collection || !_.isFunction(collection.getFacetList) ) {
    return '';

  // Otherwise, make sure either there is an active search or a selected community (the only times tags will be relevant)
  } else if (pageState.get('searchString') || pageState.get('subCategory') === SearchSubCategoryEnum.COMMUNITY) {
    const tags = [];
    const groupedTagsKeyed = {};

    if (pageState.get('articleUrlTags')) {
      pageState.get('articleUrlTags').forEach((facet) => {
        if (facet && facet.get('isSelected')) {
          const tagGroupName = facet.get('tagGroupName') || facet.get('initialUrlGroupId');
          const tagName = facet.get('tag');

          if (tagGroupName) {
            groupedTagsKeyed[tagGroupName] = groupedTagsKeyed[tagGroupName] || [];
            groupedTagsKeyed[tagGroupName].push(tagName);
          } else {
            tags.push(tagName);
          }
        }
      });

      for (const groupName in groupedTagsKeyed) { // tags inside arrays are "OR'd" together by API
        if (Object.prototype.hasOwnProperty.call(groupedTagsKeyed, groupName)) {
          const groupedTagNames = [];
          groupedTagsKeyed[groupName].forEach((tag) => {
            groupedTagNames.push(tag);
          });
          tags.push(groupedTagNames.join(','));
        }
      }
    }

    const tagsString = `tags-${ tags.join('--') }`;

    return tags.length
      ? `${ convertTextToBackboneUrlHashString(tagsString) }/`
      : '';

  // If you get here, there is no active search or no community selected, so no tags are needed
  }
  return '';

};

const _getTagObject = (tagValue, initialUrlGroupId) => {
  return new Backbone.Model({
    tag: convertBackboneUrlHashStringToText(tagValue),
    isSelected: true,
    initialUrlGroupId
  });
};

module.exports = {
  BASE_SEARCH_HASH,
  convertTextToBackboneUrlHashString,
  convertBackboneUrlHashStringToText,
  getHashForCurrentTabState,
  getHashForBrowseCommunities,
  getHashForCommunitySearch,
  isHashMissingCommunityTab,
  getImportPageNumberFromUrl,
  updateImportHistoryPageNumberInUrl,
  updatePageNumberInUrl,
  parseSearchFacets
};
