const logging = require('logging');
const _ = require('underscore');
let UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
UIKit = require('@training/widgets/UIKit');
const LearningEventNotificationView = require('@training/apps/training/views/LearningEventNotificationView');
class LearningEventsNotificationsPage extends UIKit.View {
  template = _.tpl(require('@training/apps/training/templates/LearningEventsNotificationsPage.html'));

  constructor(...args) {
    super(...args);

    this.onNext = this.onNext.bind(this);
  }

  initialize(options = {}) {
    ({notifications: this.notifications} = options);
  }

  render() {
    this.$el.html(this.template());

    this.actionBar.setActionButton({
      type: 'continue',
      onClick: this.onNext
    });

    this.eventListView = new UIKit.ListView({
      collection: this.notifications,
      el: this.$('#eventlist ul'),
      EmptyView: UIKit.View,
      FirstItemView: LearningEventNotificationView,
      ModelView: LearningEventNotificationView
    });

    this.eventListView.renderList();

    return this;
  }

  viewDidAppear() {
    logging.info('LearningEventsNotificationsPage - viewDidAppear');

    window.app.layout.setTitle(I18n.t('hub.learningEvents'));

    this.triggerAdjustment();
  }

  onClose(...args) {
    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }
    this.actionBar?.setActionButton(null);
    super.onClose(...args);
  }

  onNext() {
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    if (typeof this.options.continue === 'function') {
      this.options.continue();
    }
    return true;
  }
}

module.exports = LearningEventsNotificationsPage;
