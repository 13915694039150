import { type KeyboardEvent } from 'react';

import KeyCode from '@common/data/enums/KeyCode';

export const FOCUSABLE_ELEMENTS_STRING = `a[href], area[href], input:not([disabled]), select:not([disabled]),
textarea:not([disabled]), button:not([disabled]), iframe, object, embed,
*[tabindex], *[contenteditable]`;

export const VISIBLE_TABBABLE_ELEMENTS_STRING = ':visible:not([tabindex=-1])';

export default function trapTabKey(e: KeyboardEvent, el: HTMLElement | null): void {
  if (e.which === KeyCode.TAB) {
    const focusableItems = $(el ?? {})
      .find('*')
      .filter(FOCUSABLE_ELEMENTS_STRING)
      .filter(VISIBLE_TABBABLE_ELEMENTS_STRING);

    const focusableItemsLength = focusableItems.length;

    const focusedItem = $(':focus');
    const focusedItemIndex = focusableItems.index(focusedItem);

    if (e.shiftKey) {
      if (focusedItemIndex === 0 || focusedItemIndex === -1) {
        focusableItems.get(focusableItemsLength - 1)?.focus();
        e.preventDefault();
      }
    } else if (focusedItemIndex === focusableItemsLength - 1 || focusedItemIndex === -1) {
      focusableItems.get(0)?.focus();
      e.preventDefault();
    }
  }
}
