const _ = require('underscore');
const { ItemView } = require('Marionette');

class DiscoverTrainingCardBottomInfoView extends ItemView {
  getTemplate() {
    return `
    <%= axButton({ className: 'off-screen', label: t('training.cards.actions.discover') }) %>
    <%= axButton({ className: 'js-action-button', branded: true, label: t('training.cards.actions.discover') }) %>
    `;
  }

  ui() {
    return {
      actionButton: '.js-action-button'
    };
  }

  events() {
    return {
      'click @ui.actionButton': '_handleActionClick'
    };
  }

  className() {
    return 'training-card__bottom-info-action';
  }

  _handleActionClick(e) {
    const actionCallback = this.getOption('actionCallback');
    if (_.isFunction(actionCallback)) {
      actionCallback();
    }
    e.stopPropagation();
  }
}

module.exports = DiscoverTrainingCardBottomInfoView;
