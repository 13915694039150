const Enum = require('@common/data/enums/Enum');

const TrainingModuleType = Enum({
  SCORM: 'scorm',
  TINCAN: 'tincan',
  TRANSCODED_VIDEO: 'VideoFile',
  EXTERNAL_LINK: 'externalLink',
  EXTERNAL_VIDEO: 'VideoUrl',
  PDF: 'pdf'
});

module.exports = TrainingModuleType;
