/* eslint-disable func-style */

const logging = require('logging');

/**
 * This exception is used in order to signal that something is being redirected and the rest of the flow is not needed.
 *
 * See: http://bluebirdjs.com/docs/api/catch.html for implementation notes
 */

function RedirectingAbortedAuthentication(message) {
  this.message = message;
  this.name = 'RedirectingAbortedAuthentication';

  // This is to capture the stack trace
  const error = new Error(this.message);
  this.stack = error.stack;
}

RedirectingAbortedAuthentication.prototype = Object.create(Error.prototype);
RedirectingAbortedAuthentication.prototype.constructor = RedirectingAbortedAuthentication;

const catchAndLog = (func, catchFunc = () => {}) => {
  return Promise.try(func).catch(RedirectingAbortedAuthentication, (err) => {
    // The error has more info about the redirect
    logging.info(err);
    catchFunc(err);
  });
};

module.exports = {
  Error: RedirectingAbortedAuthentication,
  catchAndLog
};
