import { CompositeView } from 'Marionette';
import I18n from '@common/libs/I18n';

import SelfServeAreasOfInterest from '@common/modules/auth/common/collections/SelfServeAreasOfInterest';
import AoiCheckboxlistRowView from '@common/components/aoiCheckboxlist/AoiCheckboxlistRowView';

export default class AoiCheckboxlistView extends CompositeView {
  initialize(): void {
    this.collection = new SelfServeAreasOfInterest();
    this.listenTo(this.collection, 'sync', this.render);
    this.collection.fetch();
  }

  get template(): string {
    return `
      <div class='js-checkboxlist-wrapper hidden'>
        <h2 class='update-questions'>${ I18n.t('activateaccount.selectaois') }</h2>
        <ul class='js-aoi-collection-region checkboxlist'></ul>
      </div>
    `;
  }

  childViewContainer() {
    return '.js-aoi-collection-region';
  }

  ui() {
    return {
      aoiWrapper: '.js-checkboxlist-wrapper'
    };
  }

  onRender(): void {
    if (this.collection.isEmpty()) {
      return;
    }

    const ui: any = this.ui;
    ui.aoiWrapper.removeClass('hidden');
  }

  getChildView() {
    return AoiCheckboxlistRowView;
  }

  getSelected() {
    const $rows = this.$('.js-aoi-collection-region :checked').closest('li');
    const $aoiArray = $rows.map((row: number, el: object) => {
      const id = $(el).attr('data-id');
      return {
        id: id != null ? parseInt(id, 10) : undefined
      };
    });

    return $aoiArray.get();
  }
}
