const logging = require('logging');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const Sync = require('@common/libs/Sync');

const GameScoresDetailsView = require('@training/apps/training/views/GameScoresDetailsView');
const Game = require('@common/data/models/Game');

class GameScoresDetailsPage extends UIKit.View {
  constructor(...args) {
    super(...args);
    this.template = _.tpl(`\
      <h1 class="page-header">
        <div class="ax-container">
          <div class="page-header__content">
            <span class="page-header__icon game-type-icon <%- game.type %>" aria-hidden="true"></span>
            <span class="page-header__title"><%- game.name %></span>
          </div>
        </div>
      </h1>

      <div class="ax-container">
        <div class="page-content">
          <div class="game-scores-details-view "></div>
        </div>
      </div>
      \
    `);
  }

  className() {
    return 'game-scores-details-page';
  }

  initialize() {
    // set game object
    const { gameId } = this.options;
    this.game = this.options.game != null ? this.options.game : new Game({id: gameId});

    if (gameId != null) {
      this.gameScoresPromise = this.game.fetch().then(() => {
        return this.game.getGameScores();
      });
    } else {
      this.gameScoresPromise = this.game.getGameScores();
    }
  }

  render() {
    this.gameScoresPromise.done(() => {
      const game = this.game.toJSON();
      this.$el.html(this.template({game}));
    });

    return this;
  }

  viewDidAppear() {
    logging.info('GameScoresDetailsPage - viewDidAppear');

    // Set up navbar
    window.app.layout.showHeaderBackButton();

    this.gameScoresPromise.done(() => {
      const gameScoresDetailsView = new GameScoresDetailsView({model: this.game});
      this.setSubviewIn(gameScoresDetailsView, {
        regionSelector: '.game-scores-details-view',
        transition: UIKit.View.Transitions.NONE
      });
    });
  }

  onClose() {
    this.game.abortXHR(Sync.Method.READ);
    this.game.abortGameScores();

    window.app.layout.resetLeftHeaderView();
    super.onClose(...arguments);
  }
}

module.exports = GameScoresDetailsPage;
