// Module
var code = `<div class="ax-grid ax-grid--align-items-center">
  <div class="ax-grid__col--auto-size user-avatar">
    <div class="name-logo" aria-hidden="true" role="presentation"></div>
    <div class="reaction-badge reaction-<%= reactionType %>"></div>
  </div>
  <div class="ax-grid ax-grid--direction-column">
    <div aria-hidden="true" class="ax-font ax-font__caption ax-font__caption--bold"><%- salutationName %></div>
    <div aria-hidden="true" class="ax-font ax-font__caption"><%- locationName %></div>
    <span class="off-screen"><%= t(\`reaction.reactionType.\${ reactionType.toUpperCase() }.userReaction\`, {
      salutationName: salutationName,
      locationName: locationName
    }) %></span>
  </div>
</div>
`;
// Exports
module.exports = code;