// Module
var code = `<div class="task-detail-header-wrapper">
  <div class="task-detail-header-info">
    <p class="task-detail-header-program"><%- programName %></p>
    <h2 class="task-detail-header-name"><%- taskName %></h2>
  </div>
  <% if (rewardPointsMsg !== '') { %>
  <div class="task-detail-header-points <%- rewardPointsCssClass %>">
    <p class="task-detail-header-points-wrapper"><%- rewardPointsMsg %></p>
  </div>
  <% } %>
</div>
`;
// Exports
module.exports = code;