const { LayoutView } = require('Marionette');

class EvaluationCriteriaCommentView extends LayoutView {
  className() {
    const baseClassName = 'evaluation-criteria-comment';
    return (this.options.isGeneralNotes ? `${ baseClassName } general-notes-comment` : baseClassName);
  }

  tagName() {
    return 'div';
  }

  getTemplate() {
    return `\
<div class="general-notes-wrapper">
  <% if (isGeneralNotes) { %>
    <h2 class="general-notes-header  ax-font ax-font__l"><%- t('observations.details.labels.generalNotes') %></h2>
  <% } %>
  <div class="comment-details">
    <div class="comment-details__icon">
      <span class="item-icon icon-comment"></span>
    </div>
    <div class="comment-details__content">
      <h3><%- t('observations.details.labels.evaluatorComment') %></h3>
      <p><%- comment %></p>
    </div>
  </div>
</div>`;
  }

  templateHelpers() {
    return {
      isGeneralNotes: this.options.isGeneralNotes,
      comment: this.options.comment
    };
  }
}

module.exports = EvaluationCriteriaCommentView;
