const AssessmentInitatorContext = require('@common/data/models/assessments/AssessmentInitatorContext');
const AssessmentType = require('@common/data/enums/AssessmentType');

class TopicSelectionForAssessmentInitiatorContext extends AssessmentInitatorContext {
  constructor(assessmentType, pageContext = {}, timestamp) {
    super();
    this.assessmentType = AssessmentType.assertLegalValue(assessmentType);
    this.pageContext = pageContext;
    this.timestamp = timestamp || this.timestamp;
  }

  getAssessmentType() {
    return this.assessmentType;
  }

  getType() {
    return this.assessmentType;
  }
}

module.exports = TopicSelectionForAssessmentInitiatorContext;
