const LearningObjectiveCardGraphicView = require('@common/components/learningObjectives/LearningObjectiveCardGraphicView');
const AssessmentType = require('@common/data/enums/AssessmentType');

const ICON_CSS_CLASS_BY_TYPE = {
  [AssessmentType.CertificationTraining]: 'icon-certifications',
  [AssessmentType.IntroductoryTraining]: 'icon-topic',
  [AssessmentType.RefresherTraining]: 'icon-refresher',
  [AssessmentType.FormalExamTraining]: 'icon-exam'
};

class AssessmentCardGraphicView extends LearningObjectiveCardGraphicView {
  templateHelpers() {
    const type = this.getOption('assessmentType');

    return {
      iconCssClass: ICON_CSS_CLASS_BY_TYPE[type],
      thumbnailCssClass: 'hidden'
    };
  }
}

module.exports = AssessmentCardGraphicView;
