const UserSnapshotView = require('@common/components/user/snapshot/UserSnapshotView');

class HiddenUsersSnapshotView extends UserSnapshotView {
  getTemplate() {
    return `\
<div class="name-logo icon-groups"></div>
<div class="user-info">
  <p class="user-name"><%- t('events.scheduledEvents.hiddenUsers.label') %></p>
  <div class="meta-text">
    <span class="hidden-attendees"><%- t('events.scheduledEvents.hiddenUsers.count', { count: hiddenUserCount }) %></span>
  </div>
</div>\
`;
  }

  className() {
    return 'hidden-users-snapshot-view';
  }

  initialize() {
    // Don't need to do what the parent does
  }

  onRender() {
    // Don't need to do what the parent does
  }
}

module.exports = HiddenUsersSnapshotView;
