import {
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  type ListItemSecondaryActionProps as MuiListItemSecondaryActionProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxListItemSecondaryActionProps = MuiListItemSecondaryActionProps;

const styledOptions = {
  name: 'AxListItemSecondaryAction'
};

const StyledAxListItemSecondaryAction = styled(MuiListItemSecondaryAction, styledOptions)<AxListItemSecondaryActionProps>(() => {
  return {};
});

/**
* You can use the `AxListItemSecondaryAction` component to render a secondary action within a list item.
*
* ℹ️ Note: AxListItem has `secondaryAction` prop that can be used to render a secondary action within a list item as well.
*
* ### Links
* - • [MUI ListItemSecondaryAction | API](https://mui.com/material-ui/api/list-item-secondary-action/)
*
* @example
* <AxListItem>
*  <AxListItemText primary='Drafts' />
*  <AxListItemSecondaryAction>
*    <AxIconButton>
*      <AxIcon iconName='icon-delete' />
*    </AxIconButton>
*  </AxListItemSecondaryAction>
* </AxListItem>
*/
export const AxListItemSecondaryAction = forwardRef(({
  children,
  ...otherProps
}: AxListItemSecondaryActionProps, ref: Ref<HTMLLIElement>) => {
  const ListItemSecondaryActionProps: AxListItemSecondaryActionProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemSecondaryAction { ...ListItemSecondaryActionProps } ref={ ref }>
      { children }
    </StyledAxListItemSecondaryAction>
  );
});

export default AxListItemSecondaryAction;
