import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  TableSortLabel as MuiTableSortLabel,
  styled,
  type TableSortLabelTypeMap as MuiTableSortLabelTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTableSortLabelCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  customIcon?: {
    asc: JSX.Element | undefined;
    desc: JSX.Element | undefined;
  } | undefined;
};

type PropsToOmit = 'centerRipple' | 'disableRipple' | 'disableTouchRipple' | 'focusRipple' | 'TouchRippleProps' | 'touchRippleRef';

export type AxTableSortLabelTypeMap<P = object, D extends React.ElementType = 'span'> = {
  props: P & Omit<MuiTableSortLabelTypeMap['props'], PropsToOmit> & AxTableSortLabelCustomProps,
  defaultComponent: D
};

export type AxTableSortLabelProps<
  D extends ElementType = AxTableSortLabelTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableSortLabelTypeMap<P & AxTableSortLabelCustomProps, D>, D>;


const styledOptions = {
  name: 'AxTableSortLabel'
};

const StyledAxTableSortLabel = styled(MuiTableSortLabel, styledOptions)<AxTableSortLabelProps>(({theme}) => {
  return {
    '.MuiTableSortLabel-icon': {
      fontSize: theme.uiKit.fontSizeXL,
      marginLeft: theme.uiKit.spacingS
    },
    '&.Mui-active': {
      '.MuiTableSortLabel-icon': {
        color: theme.uiKit.colorGrey80
      }
    }
  };
});

/**
 * A component that renders a sort label for the table header cells.
 *
 * ## Links
 * - • [MUI | Sort Label API](https://mui.com/material-ui/api/table-sort-label/)
 */
export const AxTableSortLabel: MuiOverridableComponent<AxTableSortLabelTypeMap> = forwardRef(({
  active,
  ...otherProps
}: AxTableSortLabelProps, ref: Ref<HTMLSpanElement>) => {
  const tableSortLabelProps: AxTableSortLabelProps = {
    active,
    ...otherProps
  };

  return (
    <StyledAxTableSortLabel
      // IconComponent requires SVG icon, not font icon like AxIcon
      // It's possible to use AxIcon but demands handling direction and active state
      // It also removes the hover effect on the deactivated sorted header cells which displays the sort icon
      IconComponent={ ExpandMoreIcon }
      { ...tableSortLabelProps }
      ref={ ref }
      disableRipple
      disableTouchRipple
    />
  );
});

export default AxTableSortLabel;
