const Promise = require('bluebird');

const { sendErrorLog } = require('LoggingService');

const HubTileCollection = require('@common/data/collections/HubTileCollection');

const {
  HUB,
  PROFILE,
  DISCOVER
} = require('@common/data/enums/HubTileIdentifiers').default;

const createConfig = (identifier) => {
  return { identifier };
};

const getTilesAsync = () => {
  const hubTiles = HubTileCollection.forLearnerZone();

  return Promise.resolve(hubTiles.fetch({
    showSpinner: false,
    shouldShowErrorPage: false,
    // error logging is added here to differentiate fetch errors from others
    // and be able to diagnose future errors
    error: (response, xhr) => {
      if (xhr.status !== 401) {
        xhr.skipGlobalHandler = true;
        sendErrorLog({
          logData: {
            logMessage: `TrainingMenuConfigProvider - Failed to fetch hubtile data with an HTTP status of ${ xhr.status }`
          }
        });
      }
    }
  })
    .then((hubTileResponse = {}) => {
      if (!Array.isArray(hubTileResponse.tiles)) {
        return null;
      }

      return hubTileResponse.tiles;
    })
    .catch(() => {
      return Promise.resolve([]);
    }));
};

const getTileConfigsAsync = (options = {}) => {
  const {
    isMenuEnabled = true
  } = options;

  if (isMenuEnabled) {
    return getTilesAsync()
      .then((tiles) => {
        return [createConfig(HUB), createConfig(DISCOVER), createConfig(PROFILE), ...tiles];
      })
      .catch( () => {
        return Promise.resolve([]);
      } );
  }

  return Promise.resolve([]);
};

module.exports = (options = {}) => {
  const menuConfigList = HubTileCollection.forLearnerZone();

  return {
    fetch: () => {
      return getTileConfigsAsync(options)
        .then( (configArray) => {
          menuConfigList.reset(configArray);
          return menuConfigList.getAvailableTiles();
        } );
    },
    getAvailableConfigs: () => {
      return menuConfigList.getAvailableTiles();
    }
  };
};
