import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  Collapse as MuiCollapse,
  type CollapseProps as MuiCollapseProps
} from '@mui/material';

type AxCollapseProps = MuiCollapseProps;

const styledOptions = {
  name: 'AxCollapse'
};

const StyledAxCollapse = styled(MuiCollapse, styledOptions)<AxCollapseProps>(() => {
  return {};
});

export const AxCollapse = forwardRef(({
  ...otherProps
}: AxCollapseProps, ref: Ref<HTMLDivElement>) => {
  const CollapseProps: AxCollapseProps = {
    ...otherProps
  };

  return (
    <StyledAxCollapse { ...CollapseProps } ref={ ref }/>
  );
});

export default AxCollapse;
