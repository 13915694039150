const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'NEW_WINDOW',
  'IFRAME',
  'DISABLED'
], {
  getDefaultValue() {
    return this.NEW_WINDOW;
  }
});
