const _ = require('underscore');

class Builder {
  constructor() {
    this.matchers = [];
  }

  withMatcher(matcher) {
    this.matchers.push(matcher);
    return this;
  }

  build() {
    return (mediaFile) => {
      const mediaFactory = _.find(this.matchers, (matcher) => {
        return matcher.isMatch(mediaFile);
      });
      return mediaFactory ? mediaFactory.build(mediaFile) : null;
    };
  }
}

module.exports = () => {
  return new Builder();
};
