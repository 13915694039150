const Backbone = require('Backbone');
const _ = require('underscore');
const logging = require('logging');
const {DocumentFile} = require('@common/data/models/media/DocumentMedia');
const FileHelpers = require('@common/libs/file/FileHelpers');
class ImageFile extends DocumentFile {

  constructor(attrs, options) {
    super(attrs, options);
    this.getMedia = this.getMedia.bind(this);
  }

  initialize(attributes, options) {
    super.initialize(attributes, options);
    const {imageMedia} = options || {};
    this.imageMediaModel = imageMedia ? new ImageMedia(imageMedia) : null;
  }

  save(key, value, options = {}) {
    let attrs = {};
    let saveOptions = options;

    if ((key === null) || (typeof key === 'object')) {
      attrs = key;
      saveOptions = value || {};
    } else {
      attrs[key] = value;
    }

    saveOptions.allowedExtensions = saveOptions.allowedExtensions || ['gif', 'jpg', 'jpeg', 'png'];
    saveOptions.sizeLimit = 15;

    return super.save(attrs, saveOptions);
  }

  createMedia(options = {}) {
    const {
      data,
      success,
      error
    } = options;

    const deferred = $.Deferred();

    this.imageMediaModel = new ImageMedia(data, {
      successCallback: success,
      errorCallback: error
    });

    this.imageMediaModel.set('originalFile', this.attributes);

    if (this.imageMediaModel.get('originalFile') != null) {
      if (this.imageMediaModel.isNew()) {
        this.imageMediaModel.save(null, {
          success: (model, response) => {
            if (FileHelpers.isGif(response.originalFile.originalFileName)) {
              this.imageMediaModel.setTotalTimeout(60000 * 5);
            }
            this.imageMediaModel.set('id', response.id);
            this.imageMediaModel.mediaImageFileSizesStatusPoll(response);
          }
        }).then(deferred.resolve, deferred.reject);
      } else {
        deferred.resolve();
      }
    } else {
      deferred.reject();
    }

    return deferred.promise();
  }

  getMedia() {
    return (this.imageMediaModel && this.imageMediaModel.toJSON()) || undefined;
  }
}

class ImageMedia extends Backbone.Model {
  static platformDefaultSizes = [
    {
      width: 1200,
      height: 1200
    },
    {
      width: 600,
      height: 600
    },
    {
      width: 300,
      height: 300
    },
    {
      width: 120,
      height: 120
    },
    {
      width: 80,
      height: 80
    }
  ];

  get idAttribute() {
    return 'uuid';
  }

  constructor(attrs, options) {
    super(attrs, options);
    this.poll = this.poll.bind(this);
    this.mediaImageFileSizesStatusPoll = this.mediaImageFileSizesStatusPoll.bind(this);
    this.handleTotalTimeWating = this.handleTotalTimeWating.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  urlRoot() {
    return '/axonify/media/image/';
  }

  initialize(attributes, options) {
    ({
      successCallback: this.successCallback,
      errorCallback: this.errorCallback
    } = options || {});

    this.timeout = 250;
    this.currentTimeout = null;
    this.totalTimeout = options.totalTimeout ?? 60000;
    this.totalTimeoutId = null;
  }

  setTotalTimeout(time) {
    this.totalTimeout = time;
  }

  poll() {
    if (this.currentTimeout != null) {
      clearTimeout(this.currentTimeout);
    }
    this.currentTimeout = setTimeout(this.fetch, this.timeout);
  }

  isDoneProcessingAllSizes() {
    // Return `true` if all sizes are `done` processing
    // Return `false` as soon as one is found that is not `done` processing
    for (const imageSize of this.get('sizes')) {
      if (imageSize.status !== 'done') {
        return false;
      }
    }

    return true;
  }

  mediaImageFileSizesStatusPoll() {
    this.totalTimeoutId = setTimeout(this.handleTotalTimeWating, this.totalTimeout);
    return this.fetch();
  }

  _callbackHelper(callbackSwitch, args) {
    if (this.currentTimeout != null) {
      clearTimeout(this.currentTimeout);
    }

    if (this.totalTimeoutId != null) {
      clearTimeout(this.totalTimeoutId);
    }

    if (callbackSwitch === 'success') {
      if (_.isFunction(this.successCallback)) {
        this.successCallback(...args);
      }
    } else if (callbackSwitch === 'error') {
      if (_.isFunction(this.errorCallback)) {
        this.errorCallback(...args);
      }
    }
  }

  handleTotalTimeWating() {
    logging.error(`mediaImageFileSizesStatusPoll took ${ this.totalTimeout }`);
    this._callbackHelper('error', [null, null, {processingTimedOut: true}]);
  }

  fetch(options) {
    const callbackOptions = {
      success: (model, response) => {
        if (model.isDoneProcessingAllSizes()) {
          this._callbackHelper('success', [model, response]);
        } else {
          this.poll(response.uuid);
        }
      },

      error: (model, response) => {
        logging.error(`Error happened on ping: ${ response }`);
        this._callbackHelper('error', [model, response]);
      }
    };

    return super.fetch(_.extend({}, callbackOptions, options));
  }

  toJSON(...args) {
    const json = super.toJSON(...args);

    if (json.originalFile && _.isFunction(json.originalFile.toJSON)) {
      json.originalFile = json.originalFile.toJSON();
    }

    return json;
  }
}

class ImageAltText extends ImageMedia {
  getPath() {
    return super.urlRoot() + this.get('uuid') + '/text';
  }
}

module.exports = {
  ImageFile,
  ImageMedia,
  ImageAltText
};
