module.exports = {
  privateSpecialRepair: {
    resultMessage: 'Error came from OEM Vivo browser running chrome 87',
    isValid: (errorObj = {}) => {
      const { message = '' } = errorObj;

      return !(message.length > 0 && message.indexOf && message.indexOf('privateSpecialRepair') !== -1);
    }
  },

  liveReload: {
    resultMessage: 'Error came from livereload script which isn\'t relevant to us.',
    isValid: (errorObj = {}) => {
      const { sourceURL = '' } = errorObj;
      return !(sourceURL.length > 0 && sourceURL.indexOf('livereload') !== -1);
    }
  },

  resizeObserver: {
    resultMessage: 'Chrome issue (v68 or so), throwing error on video elements even when ResizeObserver set to undefined in prerequisites.',
    isValid: (errorObj = {}) => {
      const { message = '' } = errorObj;
      return !(message.length > 0 && message.indexOf && message.indexOf('ResizeObserver') !== -1);
    }
  },

  anonScript: {
    resultMessage: 'Missing script url but has line# which indicates the error isn\'t coming from our app.',
    isValid: (errorObj = {}) => {
      const {
        sourceURL = '',
        line
      } = errorObj;
      return !(sourceURL.length === 0 && line != null);
    }
  },

  getReadMode: {
    resultMessage: 'Ignore an error coming from our app.',
    isValid: (errorObj = {}) => {
      const {
        stackTrace = '',
        message = ''
      } = errorObj;

      const hasGetReadMode = message.length > 0 && message.indexOf && message.indexOf('getReadMode') !== -1;
      const isAnonymous = stackTrace.length > 0 && stackTrace.indexOf && stackTrace.indexOf('<anonymous>') !== -1;

      return !(isAnonymous && hasGetReadMode);
    }
  },

  offsetTop: {
    resultMessage: 'Ignore error trying to access offsetTop from <anonymous>',
    isValid: (errorObj = {}) => {
      const {
        stackTrace = '',
        message = ''
      } = errorObj;

      const hasOffsetTopOfNull = message.length > 0 && message.indexOf && message.indexOf('offsetTop') !== -1 && message.indexOf('null') !== -1;
      const isAnonymous = stackTrace.length > 0 && stackTrace.indexOf && stackTrace.indexOf('<anonymous>') !== -1;

      return !(hasOffsetTopOfNull && isAnonymous);
    }
  },


  // Froala has a number of uncaught errors that don't actually break functionality.
  // Their devs do not bother to handle the errors, so we have to either ignore them or crash the app.
  froalaImage: {
    resultMessage: 'Error came from existing issue in froala image handling script which isn\'t relevant to us.',
    isValid: (errorObj = {}) => {
      const { sourceURL = ''} = errorObj;
      return !(sourceURL.indexOf && sourceURL.length > 0 && sourceURL.indexOf('froala-editor/js/plugins/image.min.js') !== -1);
    }
  },

  froalaVideo: {
    resultMessage: 'Error came from existing issue in froala video handling script which resolves itself after a moment',
    isValid: (errorObj = {}) => {
      const { sourceURL = '' } = errorObj;
      return !(sourceURL.indexOf && sourceURL.length > 0 && sourceURL.indexOf('froala-editor/js/plugins/video.min.js') !== -1);
    }
  },

  froalaTable: {
    resultMessage: 'Error came from existing issue in froala table handling script. Error is thrown by nesting tables within other elements but is not a breaking bug.',
    isValid: (errorObj = {}) => {
      const { sourceURL = '' } = errorObj;
      return !(sourceURL.indexOf && sourceURL.length > 0 && sourceURL.indexOf('froala-editor/js/plugins/table.min.js') !== -1);
    }
  },

  froalaEditor: {
    resultMessage: 'Error came from existing issue in froala editor. Not a breaking bug.',
    isValid: (errorObj = {}) => {
      const { sourceURL = '' } = errorObj;
      return !(sourceURL.indexOf && sourceURL.length > 0 && sourceURL.indexOf('froala-editor/js/froala_editor.min.js') !== -1);
    }
  },

  betterJsPop: {
    resultMessage: 'Error came from BetterJsPop code found in a Chrome ad-blocker extension',
    isValid: (errorObj = {}) => {
      const { message = '' } = errorObj;

      return !(message.length > 0 && message.indexOf && message.indexOf('BetterJsPop') !== -1);
    }
  },

  evalError: {
    resultMessage: 'Chrome issue in ~v102 - throwing error when we type in the dev tools console.',
    isValid: (errorObj = {}) => {
      const { message = '' } = errorObj;
      return !(message.length > 0 && message.indexOf && message.indexOf('debug-evaluate') !== -1);
    }
  },

  zaloJSV2: {
    resultMessage: 'Error came from Zalo Mobile App, currently unsupported by Axonify',
    isValid: (errorObj = {}) => {
      const { message = '' } = errorObj;

      return !(message.length > 0 && message.indexOf && message.indexOf('zaloJSV2') !== -1);
    }
  },

  __gCrWeb: {
    resultMessage: 'Microsoft Edge issue with v124, throwing error when using on mobile iOS',
    isValid: (errorObj = {}) => {
      const { message = '' } = errorObj;

      return !(message.length > 0 && message.indexOf && message.indexOf('__gCrWeb.edgeTranslate.detectPageState') !== -1);
    }
  }
};
