const Marionette = require('Marionette');

class EmptyLearningEventListView extends Marionette.ItemView {
  getTemplate() {
    return '\
      <p class="noevents"><%-t(\'learningEvents.noEvents\') %></p>\
    ';
  }
}

module.exports = EmptyLearningEventListView;
