const dateHelpers = require('@common/libs/dateHelpers');
const Form = require('@common/components/forms/Form');
require('@common/components/forms/editors/date/Form.Editor.DateTimeNew');

// This class exists mostly for compatability reasons; it extends the `Date` editor in order
// to allow time selection to be used. If you need time editing, use this class. Otherwise, just
// the standard date selector if it suits your needs.
Form.Editor.DateNew = class Date extends Form.Editor.DateTimeNew {
  initialize(options = {}) {
    if (options.options == null) {
      options.options = {};
    }
    options.options.showTimeControls = false;
    options.options.placeholder = options.options.placeholder != null ? options.options.placeholder : dateHelpers.getFormatToDefaultDate();
    options.options.format = (date) => {
      return dateHelpers.convertDateFormatToDefaultDate(date);
    };
    super.initialize(options);
  }
};

module.exports = Form.Editor.DateNew;
