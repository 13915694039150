// Module
var code = `<div class="recovery-input">
  <div class="title-wrapper">
    <div class="title-block">
      <div class="recovery-icon icon-question-nobg"></div>
      <div class="recovery-title-wrapper">
        <div class="recovery-title"><%- t('login.forgotPassword.answers.title') %></div>
        <div><%- t('login.forgotPassword.answers.subTitle') %></div>
      </div>
    </div>
  </div>
  <div class="recovery-question"><%- recoveryQuestion %></div>
  <div>
    <label>
      <%- t('login.forgotPassword.answers.inputLabel') %>
      <div>
        <input id="recovery-answer" type="text" data-field="recoveryAnswer" autocomplete="off" />
      </div>
    </label>
  </div>
  <div class="recovery-submit">
    <a href="#" class="axon-button submit" role="button"><%- t('login.submit') %></a>
  </div>
</div>
`;
// Exports
module.exports = code;