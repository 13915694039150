const Marionette = require('Marionette');

class TaskIndexLayoutHeaderView extends Marionette.ItemView {
  getTemplate() {
    return `\
        <div class="ax-container">
          <div class="page-header__content">
            <span class="page-header__icon icon-task" aria-hidden="true"></span>
            <h1 class="page-header__title"><%- t('tasks.details.title') %></h1>
          </div>
        </div>\
      `;
  }

  className() {
    return 'page-header';
  }
}

module.exports = TaskIndexLayoutHeaderView;
