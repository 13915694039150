const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

class AttemptDetailsCardHeaderView extends Marionette.ItemView {

  className() {
    return 'attempt-details-header';
  }

  getTemplate() {
    return `\
      <h2 class="attempt-details-header__attempt-number ax-font ax-font__m ax-font--no-margin">
        <%- attemptNumberText %>
      </h2>\
    `;
  }

  templateHelpers() {
    return {
      attemptNumberText: I18n.t('GuidedLearning.evaluation.details.attemptNumber', { attemptNumber: this.getOption('attemptNumber') })
    };
  }
}

module.exports = AttemptDetailsCardHeaderView;
