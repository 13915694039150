// Module
var code = `<div class="page-content relative">

  <div class="bet-meta clearfix ax-grid ax-grid--align-items-center">
    <div class="game-type ax-grid ax-grid--no-gutter ax-grid--align-items-center">
      <div class="ax-grid__col--auto-size">
        <div class="game-type-icon <%- game.type %>"></div>
      </div>
      <h2 class="game-name ax-font--no-margin">
        <%- t(\`games.\${ game.type }\`) %>
        <span class="game-name-meta"><%- t('selectGame.gameChallenge.challengeHeading') %></span>
      </h2>
    </div>

    <div class="ax-grid__col--auto-size">
      <%= axButton({
        className: 'js-how-to-link how-to-link right',
        size: 'm',
        iconClassLeft: 'icon-question_sign icon--reset-styles',
        label: t('referral.howTo.link')
      }) %>
    </div>
  </div>

  <div class="challenge-selection ax-grid">
    <div class="challenge-selection-region"></div>
    <div class="sidebar ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--l-3">
      <div class="recent-challenges-region"></div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;