module.exports = {
  logMessage: (value) => {
    return `${ value }`;
  },

  error: (error = {}) => {
    return error.message;
  },

  api: ({
    url = '',
    method = '',
    status = '',
    thrownError,
    errorCode
  } = {}) => {
    const statusErrorText = errorCode != -1 ? `${ status }:${ errorCode }` : status;
    const statusText = thrownError != null ? thrownError : statusErrorText;

    return [method, url, statusText].join(' ');
  },

  logs: (logs = []) => {
    return logs.join('\n');
  },

  suppressed: (isSuppressed) => {
    if (isSuppressed) {
      return 'Ignored!';
    }
  }
};
