// Module
var code = `<div class="modalHeader">
  <h1><%- t('user.imageUploader.title') %></h1>
  <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
</div>

<div class="upload clearfix ax-grid ax-grid--no-gutter">
  <form class="image-upload-form ax-grid__col--8">
    <div class="media-thumbnail-input" data-field="media" data-editor="MediaInput" data-options="mediaInputOptions"></div>
    <div class="adjust-photo-wrap">
      <div class="adjust-photo">
        <h3 class="adjust-photo-title"><%-  t('user.imageUploader.adjustPhoto.title')  %></h3>
        <p class="adjust-photo-subTitle"><%-  t('user.imageUploader.adjustPhoto.subTitle')  %></p>
        <div class="media-thumbnail-wrap">
          <div class="media-thumbnail" id="choose-photo-thumbnail">
            <div class="thumbnail-wrap inline-block">
              <p class="off-screen"><%- t('user.adjustPhoto.croppableImage') %></p>
              <div class="remove-wrap hidden">
                <button type="button" class="remove" aria-label="<%- t('user.adjustPhoto.remove') %>"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="change-photo camera-button ax-button ax-button--s ax-button--branded-secondary qa-change-photo-button" role="button" tabindex="0">
          <%-  t('user.imageUploader.adjustPhoto.button.title')  %>
        </div>
      </div>
      <div class="choose-photo">
        <h3 class="adjust-photo-title"><%-  t('user.imageUploader.choosePhoto.title')  %></h3>
        <p class="adjust-photo-subTitle"><%= t('user.imageUploader.choosePhoto.subTitle', {'maximumImageSize': 15}) %></p>
        <div class="add-photo axon-button camera-button qa-add-photo-button" role="button" tabindex="0">
          <%-  t('user.imageUploader.choosePhoto.button.title')  %>
        </div>
      </div>
    </div>
  </form>

  <div class="adjust-photo-preview ax-grid__col--4">
    <h3 class="adjust-photo-title"><%- t('user.imageUploader.preview.title') %></h3>
    <p class="adjust-photo-subTitle"><%- t('user.imageUploader.preview.subTitle') %></p>
    <div class="preview-wrap">
      <div class="thumbnail-preview">
        <img src="" alt="<%- t('photoCrop.preview.largeThumbnailLabel') %>"/>
      </div>

      <div class="small-previews-wrap clearfix">
        <div class="thumbnail-preview-size-1 thumbnail-preview-size floatleft">
          <img src="" alt="<%- t('photoCrop.preview.mediumThumbnailLabel') %>"/>
        </div>
        <div class="thumbnail-preview-size-2 thumbnail-preview-size floatleft">
          <img src="" alt="<%- t('photoCrop.preview.smallThumbnailLabel') %>"/>
        </div>
        <div class="thumbnail-preview-size-3 thumbnail-preview-size floatleft">
          <img src="" alt="<%- t('photoCrop.preview.tinyThumbnailLabel') %>"/>
        </div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;