const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

const getPageInsightsUrl = (pageModelId, teamId = null) => {
  const baseInsightsPage = `${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ pageModelId }/insights`;
  if (!teamId) {
    return baseInsightsPage;
  }
  return `${ baseInsightsPage }/team-${ teamId }`;
};

module.exports = {
  getPageInsightsUrl
};
