import {
  type Ref,
  forwardRef
} from 'react';
import {
  styled,
  ImageListItemBar as MuiImageListItemBar,
  type ImageListItemBarProps as MuiImageListItemBarProps
} from '@mui/material';

type AxImageListItemBarProps = MuiImageListItemBarProps;

const styledOptions = {
  name: 'AxImageListItemBar'
};

const StyledAxImageListItemBar = styled(MuiImageListItemBar, styledOptions)<AxImageListItemBarProps>(() => {
  return {};
});

export const AxImageListItemBar = forwardRef(({
  ...otherProps
}: AxImageListItemBarProps, ref: Ref<HTMLDivElement>) => {
  const ImageListItemBarProps: AxImageListItemBarProps = {
    ...otherProps
  };

  return (
    <StyledAxImageListItemBar { ...ImageListItemBarProps } ref={ ref }/>
  );
});

export default AxImageListItemBar;
