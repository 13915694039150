const Backbone = require('Backbone');

const NUDGE_TIMEOUT = 1000 * 60 * 30;

class CMSPoller extends Backbone.Model {
  url() {
    return `${ window.location.origin }/nudge/api/v1/cms/insights/unread`;
  }

  fetch(options = {}) {
    return super.fetch({
      ...options,
      // prevents oops page from showing on api errors
      skipGlobalHandler: true,
      // prevents black spinner from showing on screen in Training app while this runs in the background
      showSpinner: false
    });
  }
}

module.exports = {
  NUDGE_TIMEOUT,
  CMSPoller
};
