const _ = require('underscore');

/*
 * The following code was pretty much all ripped from the source code for Lodash.js
 * https://github.com/lodash/lodash/blob/2f79053d7bc7c9c9561a30dda202b3dcd2b72b90/.internal/memoizeCapped.js
*/

/** Used as the maximum memoize cache size. */
const MAX_MEMOIZE_SIZE = 500;

/**
 * A specialized version of `memoize` which clears the memoized function's
 * cache when it exceeds `MAX_MEMOIZE_SIZE`.
 *
 * @param {Function} func The function to have its output memoized.
 * @returns {Function} Returns the new memoized function.
 */
const memoizeCapped = (func) => {
  const result = _.memoize(func, (key) => {
    const { cache } = result;

    if (Object.keys(cache).length === MAX_MEMOIZE_SIZE) {
      result.cache = {};
    }

    return key;
  });

  return result;
};

module.exports = memoizeCapped;
