const CommunityList = require('@common/data/collections/CommunityList');

class RecentCommunityList extends CommunityList {
  get apiEndpoint() {
    return '/pageCommunities/user/recent';
  }

  comparator() {
    // we need to remove CommunityList's lexicographic sort so I'm replacing this with an empty implementation.
  }

  parse(data) {
    return data.results;
  }
}

module.exports = RecentCommunityList;
