const logging = require('logging');
const _ = require('underscore');

const EntityPoller = require('@common/libs/EntityPoller');
const Sync = require('@common/libs/Sync');

const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');

const FileHelpers = require('@common/libs/file/FileHelpers');
const FileFactory = require('@common/libs/file/FileFactory');

const VideoMedia = require('@common/libs/file/VideoMedia');
const AxonifyExceptionFactory = require('@common/services/error/AxonifyExceptionFactory');
require('@common/libs/file/ImageMedia');
const LegacyVideoMedia = require('@common/data/models/media/VideoMedia').VideoMedia;


const saveMedia = function(mediaModel, saveOptions = {}, pollOptions = {}) {
  const deferred = $.Deferred();
  const file = mediaModel.getFile();

  if (file != null) {
    const executeMediaSave = () => {
      return mediaModel.save({}, saveOptions);
    };

    // Replace rejection model with mediaModel since that's what we care about here. This is "Media"Helpers after all.
    const executeFileSaveFailure = function(fileModel, ...args) {
      logging.debug('File save error while saving media file (FileHelpers.saveFile): ', fileModel);
      return deferred.reject(mediaModel, ...args);
    };

    FileHelpers.saveFile(file, saveOptions, pollOptions)
      .progress(deferred.notify)
      .then(executeMediaSave, executeFileSaveFailure)
      .then(deferred.resolve, (...args) => {
        logging.debug('File save error while saving media (mediaModel.save): ', mediaModel);
        deferred.reject(mediaModel, ...args);
      });
  } else {
    deferred.reject(mediaModel);
  }

  return deferred.promise();
};

const abortSaveMedia = function(mediaModel) {
  FileHelpers.abortSaveFile(mediaModel.getFile());
  return mediaModel.abortXHR(Sync.Method.CREATE);
};

const mediaImageFileSizesStatusPoll = (mediaModel, successCallback = () => {}, errorCallback = () => {}, options = {}) => {
  return mediaIsProcessedStatusPoll(mediaModel, successCallback, errorCallback, options);
};

// poll mediaModel and call successCallback(model, resp) (or errorCallback(model, resp) )
// when the poll eventually succeeds in determining the the media has been processed (or fails)
const mediaIsProcessedStatusPoll = (mediaModel, successCallback = () => {}, errorCallback = () => {}, options = {}) => {
  return new Promise((resolve, reject) => {
    EntityPoller.startPoll(mediaModel, {
      successCallback: (...args) => {
        successCallback(...args);
        resolve(...args);
      },
      errorCallback: (entity, response, options) => {
        errorCallback(entity, response, options);
        reject(AxonifyExceptionFactory.fromResponse(response));
      },
      pollPredicate(model) {
        return !model.isProcessed();
      },
      ...options
    });
  })
};

// From a set of image sizes this finds the sizes file that's just a bit bigger than the maxWidth and maxHeight or
// fallback to the original file.
const getDisplayFile = function(mediaModel, maxDimensions = {}) {
  if (mediaModel == null) {
    return null;
  }

  const defaultFile = mediaModel.getFile();
  const fileType = mediaModel.get('type');

  // Use native file if it's available first
  if (defaultFile.hasNativeFile() || (fileType === 'video')) {
    return defaultFile;
  }

  if (fileType === 'document' | fileType === 'file') {
    return defaultFile;
  }

  // Find processed media size files if available
  const fileJson = ImageHelpers.getBiggestImageFileBySize(mediaModel.getSizes(), maxDimensions);

  // Return a FileModel for the most desired size or fall back to the original uploaded file.
  if (fileJson != null) {
    return FileFactory.createFileFromJSON(fileType, fileJson);
  }
  return defaultFile;

};

const addNaturalFixedSize = function(mediaModel) {
  const file = mediaModel.getFile();

  const addFixedSize = mediaModel.addFixedSize.bind(mediaModel);

  if (file.hasNativeFile()) {
    return ImageHelpers.getNaturalDimensions(file)
      .done(addFixedSize);
  }
  return $.Deferred().resolve(0, 0)
    .promise();

};

const addVideoCaptions = function(videoMediaModel, language, serviceType) {
  // if there's no serviceType defined, reject as server requires serviceType
  if (!serviceType || serviceType === '') {
    return Promise.reject(new Error('No serviceType defined'));
  }

  const isVideoMedia = (videoMediaModel instanceof VideoMedia) || (videoMediaModel instanceof LegacyVideoMedia);

  // This check is mostly so that you don't try and put a image media in and then end up creating closed captions for it
  // and a video happens to have the same ID. It's a safety check.
  if (!isVideoMedia) {
    throw new Error('There must be a valid video passed into this method in order to use it. You cannot use any other type of media');
  }

  const options = {
    language,
    method: serviceType
  };

  return $.ajax({
    type: 'POST',
    url: `/axonify/media/video/${ videoMediaModel.get('uuid') }/cc`,
    data: JSON.stringify(options)
  });
};

/**
 * Checks if the crop dimensions of an image media are dirty (changed).
 *
 * @param {Object|null} [original] - The original image media object. (optional)
 * @param {ImageMedia} imageMedia - The new image media object.
 * @returns {boolean} - Returns true if the crop dimensions are dirty, false otherwise.
 */
const isCropDirty = function(original, imageMedia) {
  const originalFileId = original?.file?.id;
  const newFileId = imageMedia.get('originalFile').get('id');

  // No need to check cropped dimensions if it's a new file
  if (originalFileId !== newFileId) {
    return true;
  }

  const originalCropped = original?.cropped;
  const newCropped = imageMedia.get('cropped');
  return !_.isMatch(originalCropped, newCropped);
};

module.exports = {
  saveMedia,
  abortSaveMedia,
  mediaImageFileSizesStatusPoll,
  mediaIsProcessedStatusPoll,
  getDisplayFile,
  addNaturalFixedSize,
  addVideoCaptions,
  isCropDirty
};
