const {
  HUB,
  HOME,
  ACHIEVEMENTS,
  REWARDS,
  CERTIFICATION_TRAINING,
  GAME_SCORES,
  GAME_SCORES_DETAILS,
  REPORT_CARD,
  TOP_PERFORMERS,
  INTRO_TRAINING,
  FEED,
  REFERRAL,
  LEARNING_EVENTS,
  LEARNING_EVENT_DETAILS,
  FORMAL_EXAM,
  REFRESHER_TRAINING,
  PROFILE,
  GUIDED_LEARNING,
  TASKS,
  MESSAGE,
  SEARCH,
  SELF_DIRECTED_PATHS,
  TIMELINE,
  CONVERSATIONAL_SEARCH,
  LEARNER_TRAINING,
  EXECUTION_TASKS,
  CAMPAIGN_COMMUNICATIONS,
  EXTRA_TRAINING,
  RECOGNITION_PINS,
  CONSOLIDATED_COMMS
} = require('@common/data/enums/HubTileIdentifiers').default;


module.exports = {
  [HOME]: {
    configId: 'hub-item-home',
    iconClass: 'icon-home',
    textKey: 'training.menu.home',
    hash: '#daily-activities'
  },
  [HUB]: {
    configId: 'hub-item-hub',
    iconClass: 'icon-home',
    textKey: 'training.menu.home',
    hash: '#hub'
  },
  [ACHIEVEMENTS]: {
    configId: 'hub-item-achievements',
    iconClass: 'icon-achievements',
    textKey: 'training.menu.achievements',
    hash: '#hub/achievements/progress'
  },
  [REWARDS]: {
    configId: 'hub-item-rewards',
    iconClass: 'icon-rewards',
    textKey: 'training.menu.auctions',
    hash: '#hub/rewards'
  },
  [CERTIFICATION_TRAINING]: {
    configId: 'hub-item-certificaton-training',
    iconClass: 'icon-certifications',
    textKey: 'training.menu.certifications',
    hash: '#hub/certifications'
  },
  [EXTRA_TRAINING]: {
    configId: 'hub-item-extra-training',
    iconClass: 'icon-training',
    textKey: 'training.menu.trainingpage',
    hash: '#hub/training/topics',
    rootHash: '#hub/training'
  },
  [GAME_SCORES]: {
    configId: 'hub-item-game-scores',
    iconClass: 'icon-games',
    textKey: 'training.menu.gamescores',
    hash: '#hub/gameScores'
  },
  [GAME_SCORES_DETAILS]: {
    configId: 'hub-item-game-scores-details',
    hash: '#hub/gameScores',
    parentConfigId: GAME_SCORES
  },
  [REPORT_CARD]: {
    configId: 'hub-item-report-card',
    iconClass: 'icon-reportcard',
    textKey: 'training.menu.knowledgemap',
    hash: '#hub/reportCard'
  },
  [TOP_PERFORMERS]: {
    configId: 'hub-item-top-performers',
    iconClass: 'icon-topperformers',
    textKey: 'training.menu.leaderboards',
    hash: '#hub/topPerformers'
  },
  [INTRO_TRAINING]: {
    configId: 'hub-item-intro-training',
    iconClass: 'icon-intro',
    textKey: 'training.menu.newTopics',
    hash: '#hub/newTopics'
  },
  [FEED]: {
    configId: 'hub-item-feed',
    iconClass: 'icon-news',
    textKey: 'training.menu.newsfeed',
    hash: '#hub/feed'
  },
  [MESSAGE]: {
    configId: 'hub-item-message',
    iconClass: 'icon-bullhorn',
    hash: '#hub/message',
    parentConfigId: FEED
  },
  [REFERRAL]: {
    configId: 'hub-item-referral',
    iconClass: 'icon-tellafriend',
    textKey: 'training.menu.referral',
    hash: '#hub/referral'
  },
  [LEARNING_EVENTS]: {
    configId: 'hub-item-learning-events',
    iconClass: 'icon-events',
    textKey: 'training.menu.learningEvents',
    hash: '#hub/events'
  },
  [LEARNING_EVENT_DETAILS]: {
    configId: 'hub-item-learning-event-details',
    iconClass: 'icon-events',
    textKey: 'learningEvents.details.event',
    hash: '#hub/eventDetails',
    parentConfigId: LEARNING_EVENTS
  },
  [FORMAL_EXAM]: {
    configId: 'hub-item-formal-exam',
    iconClass: 'icon-formalexam',
    textKey: 'training.menu.formalExams',
    hash: '#hub/exams'
  },
  [REFRESHER_TRAINING]: {
    configId: 'hub-item-refresher',
    iconClass: 'icon-refresher',
    textKey: 'training.menu.refresher',
    hash: '#hub/refresher'
  },
  [PROFILE]: {
    configId: 'hub-item-profile',
    iconClass: 'icon-profile',
    textKey: 'training.menu.profile',
    isProfileItem: true,
    isLockedToTop: true,
    hash: '#hub/profile'
  },
  [GUIDED_LEARNING]: {
    configId: 'hub-item-guided-learning',
    iconClass: 'icon-guided_learning',
    textKey: 'training.menu.guidedLearning',
    hash: '#hub/guided'
  },
  [TASKS]: {
    configId: 'hub-item-tasks',
    iconClass: 'icon-todo',
    textKey: 'training.menu.tasks',
    hash: '#hub/tasks'
  },
  [LEARNER_TRAINING]: {
    configId: 'hub-item-extra-training',
    iconClass: 'icon-extratraining',
    textKey: 'training.menu.learnerTraining',
    hash: '#hub/training/topics'
  },
  [SEARCH]: {
    configId: 'hub-item-search',
    iconClass: 'icon-search',
    textKey: 'training.menu.search',
    hash: '#hub/search'
  },
  [SELF_DIRECTED_PATHS]: {
    configId: 'hub-item-self-directed-paths',
    iconClass: 'icon-path',
    textKey: 'training.menu.selfDirectedPaths',
    hash: '#hub/paths'
  },
  [TIMELINE]: {
    configId: 'hub-item-timeline',
    iconClass: 'icon-news',
    textKey: 'training.menu.timeline',
    hash: '#hub/timeline'
  },
  [CONVERSATIONAL_SEARCH]: {
    configId: 'hub-item-conversationalSearch',
    iconClass: 'icon-max',
    textKey: 'training.menu.askMax',
    hash: '#hub/max'
  },
  [EXECUTION_TASKS]: {
    configId: 'hub-item-nudge-tasks',
    iconClass: 'icon-tasks',
    textKey: 'training.menu.nudgeTasks',
    hash: '#hub/taskList'
  },
  [CAMPAIGN_COMMUNICATIONS]: {
    configId: 'hub-item-campaign-communications',
    iconClass: 'icon-complete-communications',
    hash: '#hub/communication'
  },
  [RECOGNITION_PINS]: {
    configId: 'hub-item-recognition-pins',
    iconClass: 'icon-clap',
    hash: '#hub/achievements/pins'
  },
  [CONSOLIDATED_COMMS]: {
    configId: 'hub-item-consolidated-comms'
  }
};
