const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
const PageableListViewController = require('@common/components/pageableList/PageableListViewController');
const CatalogListEmptyView = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogListEmptyView');

class CatalogListResultsViewController extends PageableListViewController {

  _getListRegionViewDefinitionWithController() {
    return {
      ViewControllerClass: CollectionLayoutViewController,
      collection: this.collection,
      viewDefinition: {
        className: 'search-results ax-grid',
        tagName: 'ul',
        childViewOptions: {
          tagName: 'li',
          className: 'list-item touchable ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-6'
        },
        emptyView: CatalogListEmptyView,
        emptyViewOptions: {
          collection: this.collection
        }
      },
      ChildViewControllerDefinition: (model) => {
        return () => {
          return this.childViewControllerDefinitionFn(model, this.itemViewOptions);
        };
      }
    };
  }
}

module.exports = CatalogListResultsViewController;
