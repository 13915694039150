// Module
var code = `<div id="editor-page" class="fit-parent editor-page">
  <div id="title" class="title-container page-header page-header--fixed">
  </div>
  <div class="row-content">
    <div id="content" class="full-width parent-height"></div>
    <div id="metadata" class="parent-height"></div>
  </div>
  <div class="action-bar full-width" id="action-bar"></div>
</div>
`;
// Exports
module.exports = code;