const { LayoutView } = require('Marionette');

class GuidedLearningSubtextHeaderTitle extends LayoutView {
  className() {
    return 'subtext-header-title qa-subtext';
  }

  getTemplate() {
    return `
      <h4 class="ax-font--no-margin qa-subtext-title"><%- title %></h4>
      <div class="text-muted qa-subtext-text"><%- subtext %></div>
      <div class="subtext-header-title--progress-wrapper">
        <div class="progressBarRegion"></div>
        <p class="subtext-header-title__message"><%- message %></p>
      </div>
    `;
  }

  templateHelpers() {
    return {
      title: this.getOption('title'),
      subtext: this.getOption('subtext'),
      message: this.getOption('itemsMessage')
    };
  }

  regions() {
    return {
      progressBarRegion: '.progressBarRegion'
    };
  }
}

module.exports = GuidedLearningSubtextHeaderTitle;
