import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitProgressBar(cssVars: CSSStyleDeclaration) {
  return {
    progressBar: {
      backgroundColor: getCssValue('--ax-progress-bar-background-color', cssVars),
      borderRadius: getCssValue('--ax-progress-bar-border-radius', cssVars),
      border: getCssValue('--ax-progress-bar-border', cssVars),
      insetBackgroundColor: getCssValue('--ax-progress-bar-inset-background-color', cssVars),
      height: getCssValue('--ax-progress-bar-height', cssVars)
    }
  };
}
