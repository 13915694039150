const logging = require('logging');
const _ = require('underscore');
const LeaderboardViewBase = require('./LeaderboardViewBase');

class TeamLeaderboardView extends LeaderboardViewBase {
  get id() {
    return 'team-leaderboard-modal';
  }

  constructor(options = {}) {
    super(options);

    ({
      scoreCard: this.scoreCard,
      questionsRemaining: this.questionsRemaining
    } = options);
  }

  contentTemplate() {
    const templateFn = _.tpl(require('../templates/TeamLeaderboardView.html'));
    return templateFn(this.computeData());
  }

  viewDidAppear() {
    super.viewDidAppear();
    logging.info('TeamLeaderboardView - viewDidAppear');
  }

  computeData() {
    return {
      scoreCard: this.scoreCard,
      questionsRemaining: this.questionsRemaining
    };
  }
}

module.exports = TeamLeaderboardView;
