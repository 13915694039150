const _ = require('underscore');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TabbedLayout = require('@common/components/tabbed_layout/TabbedLayout');

/*
  TabConfig Object Interface

  tab:
    tabName: (String; Required) - String id for the config object
    tabText: (String = '') - String to be shown in the tab bar
  tabContentController:
    ViewControllerClass: (ViewControllerClass; Required) - ViewController class to be instantiated and shown
      in the TabbedLayout's tabContentRegion

*/

class TabController extends LayoutController {

  static TAB_CONTENT_REGION = 'tabContentRegion';

  constructor(options = {}) {
    super(options);

    this.viewDefinition = this.viewDefinition.bind(this);
    ({
      initialTab: this.initialTab,
      showLoneTab: this.showLoneTab = false,
      tabbedLayoutTemplate: this.tabbedLayoutTemplate
    } = options);

    this._initializeTabConfigs(options);
  }

  viewDefinition() {
    return {
      ViewClass: TabbedLayout,
      initialTabConfigs: this.getIntialTabs(),
      initialTab: this.initialTab,
      showLoneTab: this.showLoneTab,
      tabbedLayoutTemplate: this.tabbedLayoutTemplate
    };
  }

  getIntialTabs() {
    return this._tabs;
  }

  inflateView() {
    super.inflateView();

    this.listenTo(this.getView(), 'show:tab', this._onViewShowTab);
  }

  resetTabContent() {
    this.resetRegionController(TabController.TAB_CONTENT_REGION);
  }

  _initializeTabConfigs(options) {
    let tabConfigs = this.getOption('tabConfigs');
    if (_.isFunction(tabConfigs)) {
      tabConfigs = tabConfigs(options);
    }

    this._tabs = _.pluck(tabConfigs, 'tab');
    this._tabContentControllers = _.reduce(
      tabConfigs,
      (memo, tabConfig) => {
        memo[tabConfig.tab.tabName] = tabConfig.tabContentController;
        return memo;
      },
      {}
    );
  }

  _onViewShowTab(tabName) {
    this._showTab(tabName);
  }

  _showTab(tabName) {
    this.addRegionController('tabContentRegion', this._tabContentControllers[tabName]);
    this.showRegionController('tabContentRegion');
  }
}

module.exports = TabController;
