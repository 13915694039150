const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const MediaHelpers = require('@common/libs/file/MediaHelpers');
const MediaThumbnailSizes = require('@common/libs/file/MediaThumbnailSizes');
const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');
const ImageMedia = require('@common/libs/file/ImageMedia');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const Form = require('@common/components/forms/Form');
const ImageCropImageState = require('@common/data/models/ImageCropImageState');
const ImageCropAddImageController = require('@common/components/forms/editors/imageCrop/ImageCropAddImageController');
const ImageCropEditImageController = require('@common/components/forms/editors/imageCrop/ImageCropEditImageController');

const { imageExtensionList } = require('@common/libs/helpers/types/StringHelpers');
const { intersection } = require('underscore');

Form.Editor.ImageCrop = class ImageCrop extends Form.Editor {
  initialize(options = {}) {
    this.options = options.options || options;

    // A flag to inform this component whether the image is required in the wrapping form
    // When true, the empty preview thumbnails will be replaced with a prompt to upload the required image
    this.isImageRequired = this.getOption('imageRequired');
    this.thumbnailSizes = this.getOption('thumbnailSizes') || Object.values(MediaThumbnailSizes);
    this.flashErrorFn = this.getOption('flashErrorFn') || $.noop;

    this.allowedExtensions = intersection(imageExtensionList, ['jpg', 'jpeg', 'png', 'gif']);

    this.imageState = new ImageCropImageState();
    this.listenTo(this.imageState, `change:${ ImageCropImageState.FIELDS.IMAGE_STATE }`, this._onChangeImageState);

    this.render();
  }

  onRender() {
    // Form.Editor views are unfortunately ItemViews, but we want to manage regions in ImageCrop
    this.rootRegion = Marionette.Region.buildRegion({ el: this.el }, Marionette.Region);
  }

  onDestroy() {
    this.rootRegion.destroy();
  }

  setValue(value) {
    let mediaValue = value;

    if (value != null && !(value instanceof ImageMedia)) {
      mediaValue = MediaFactory.createMediaFromJSON(FileFactory, value);
    }

    if (this.getValue() == null || value == null || value.id !== this.getValue().get('id')) {
      this.imageState.setState(mediaValue);
    }
  }

  getValue() {
    return this.imageState.getState();
  }

  _getSubview(state) {
    let viewController;
    if (state != null) {
      viewController = {
        ViewControllerClass: ImageCropEditImageController,
        thumbnailImage: this.imageState.getState(),
        thumbnailSizes: this.thumbnailSizes,
        allowedExtensions: this.allowedExtensions,
        onImageRemoved: this.onImageRemoved.bind(this),
        onImageUpload: this.onImageUpload.bind(this),
        delegateEvents: {
          'error:media': this.flashErrorFn,
          'image:removed': this.onImageRemoved.bind(this),
          'image:upload': this.onImageUpload.bind(this),
          'change:media': this.onChangeMedia.bind(this)
        }
      };
    } else {
      viewController = {
        ViewControllerClass: ImageCropAddImageController,
        isImageRequired: this.isImageRequired,
        thumbnailSizes: this.thumbnailSizes,
        allowedExtensions: this.allowedExtensions,
        delegateEvents: {
          'change:media': this.onChangeMedia.bind(this),
          'error:media': this.flashErrorFn
        }
      };
    }

    return ViewControllerFactory.createLegacyView(viewController);
  }

  onChangeMedia(editor) {
    this.setValue(editor.getValue());
    this.triggerMethod('change');
  }

  onImageRemoved() {
    this.setValue(null);
    this.triggerMethod('change');
  }

  onImageUpload(options = {}) {
    const thumbnailImage = this._prepareThumbnailForUpload(options.imageMedia, options.cropped);
    window.app.layout.showSpinner(true);

    const mediaPollSuccess = () => {
      window.app.layout.hideSpinner();
    };

    const mediaPollError = () => {
      options.onMediaPollError();

      window.app.layout.hideSpinner();
      this.flashErrorFn((I18n.t('general.upload.file.error')));
    };

    const pollForMediaSize = () => {
      this.setValue(thumbnailImage);
      this.trigger('change');
      return MediaHelpers.mediaImageFileSizesStatusPoll(thumbnailImage, mediaPollSuccess, mediaPollError);
    };

    MediaHelpers.saveMedia(thumbnailImage).then(pollForMediaSize, mediaPollError);
  }

  _onChangeImageState() {
    const state = this.imageState.getState();
    const SubView = this._getSubview(state);
    this.rootRegion.show(SubView);
  }

  _prepareThumbnailForUpload(imageMedia, cropped) {
    if (!isNaN(cropped.x)
      && !isNaN(cropped.y)
      && !isNaN(cropped.width)
      && !isNaN(cropped.height)) {
      imageMedia.set('cropped', cropped);
    }
    imageMedia.set('maintainAspectRatio', false);
    imageMedia.set('fixedSizes', this.thumbnailSizes);

    // Media upload endpoint only allows for creation, PUT not aloud so unset id and treat this as a new image
    const data = imageMedia.omit(['sizes', 'uuid', 'id']);
    return MediaFactory.createMediaFromJSON(FileFactory, data);
  }
};

module.exports = Form.Editor.ImageCrop;
