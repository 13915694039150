const _ = require('underscore');
const Backbone = require('Backbone');

class PublicKey extends Backbone.Model {
  urlRoot() {
    return '/axonify/crypto/pubkey';
  }

  fetch(options = {}) {
    options = _.extend({
      dataType: 'text'
    }, options);
    return super.fetch(options);
  }

  parse(res) {
    return {
      key: res
    };
  }

  getKey() {
    return this.get('key');
  }
}

module.exports = PublicKey;
