const { ItemView } = require('Marionette');

class PostRteActionBar extends ItemView {
  getTemplate() {
    return require('@training/apps/timeline/PostRteActionBarTemplate.html');
  }

  templateHelpers() {
    return {
      buttonText: this.options.buttonText
    };
  }

  ui() {
    return {
      mediaInput: '.local-media'
    };
  }

  events() {
    return {
      'focus #insert-media': '_toggleAccessibilityFocus',
      'blur #insert-media': '_toggleAccessibilityFocus',
      'click #insert-media': '_onClickInsertMedia'
    };
  }

  _onClickInsertMedia() {
    this.options.onClickInsertMedia();
  }

  _toggleAccessibilityFocus(e) {
    this.$('#insert-media').toggleClass('focus-ring', $(e.target).hasClass('focus-visible'));
  }

}

module.exports = PostRteActionBar;
