const I18n = require('@common/libs/I18n');
const SCORMMagicConstants = require('@common/libs/scorm/SCORMMagicConstants');

const SCORMHelpers = {
  isScormComplete(versionAPIKey, getValueCb) {
    let lessonStatus = versionAPIKey === SCORMMagicConstants.API_VERSIONS.SCORM_1_2
      ? getValueCb('cmi.core.lesson_status') : getValueCb('cmi.completion_status');

    if (lessonStatus == null) {
      return false;
    }

    lessonStatus = lessonStatus.toLowerCase().replace(/\s+/g, '');
    return (['completed', 'passed', 'complete'].includes(lessonStatus));
  },

  getSubModuleTitle(title, order) {
    // Having a backup title in case title is not specified in the module
    return title || `${ I18n.t('discover.dzArticleTypes.module') } ${ order }`;
  }
};

module.exports = SCORMHelpers;
