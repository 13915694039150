// Module
var code = `<div class="challenger pull-left">
  <div class="small-name-logo margin-center" aria-hidden="true" role="presentation"></div>
  <div class="name text-ellipsis"><%- challenge.challenger.displayName %></div>
  <div class="score"><%- numberWithCommas(challenge.challengerScore) %></div>
  <%
    if (challenge.accepted && challenge.challengerScore > challenge.challengeeScore) {
      var winnerText = _.has(challenge, 'wager') ?
        t('games.outro.challengeWinnerWithWager', challenge) :
        t('games.outro.challengeWinner');
  %>
    <div class="winner"><%- winnerText %></div>
  <% } %>
</div>
<div class="versus pull-left"><div><%- t('games.outro.challengeVersus') %></div></div>
<div class="challengee pull-left clearfix">
  <div class="small-name-logo margin-center"  aria-hidden="true" role="presentation"></div>
  <div class="name text-ellipsis"><%- challenge.challengee.displayName %></div>
  <% if (challenge.accepted) { %>
    <div class="score"><%- numberWithCommas(challenge.challengeeScore) %></div>
    <%
      if (challenge.challengerScore < challenge.challengeeScore) {
        var winnerText = _.has(challenge, 'wager') ?
          t('games.outro.challengeWinnerWithWager', challenge) :
          t('games.outro.challengeWinner');
    %>
      <div class="winner"><%- winnerText %></div>
    <% } %>
  <% } else { %>
    <div class="no-score"><%- t('games.outro.challengeNotAccepted') %></div>
  <% } %>
</div>
<% if (challenge.accepted && challenge.challengerScore === challenge.challengeeScore) { %>
<div class="tie"><%- t('recentChallenges.tie') %></div>
<% } %>
<div class="clear"></div>
`;
// Exports
module.exports = code;