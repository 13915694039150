const { LayoutView } = require('Marionette');
const KeyCode = require('@common/data/enums/KeyCode');

class EvaluationSubmissionPreviewView extends LayoutView {
  className() {
    return 'submission-preview ax-grid';
  }

  getTemplate() {
    return `\
      <div class="submission-preview__display-wrapper ax-grid__col--12"></div>
      <div class="submission-preview__details-wrapper ax-grid__col--12 ax-grid__col--m"></div>
      <div class="submission-preview__reselect-file-wrapper ax-grid__col--12 ax-grid__col--m-auto-size">
      <%= axButton({
        label: t('GuidedLearning.evaluation.details.changeFile'),
        className: 'js-reselect-file qa-reselect-file',
        ghost: true
      }) %>
      </div>
      <div class="submission-preview__progress-wrapper ax-grid__col--12"></div>
      <div class="submission-preview__reupload-wrapper"></div>
    `;
  }

  regions() {
    return {
      displayRegion: '.submission-preview__display-wrapper',
      detailsRegion: '.submission-preview__details-wrapper',
      progressRegion: '.submission-preview__progress-wrapper',
      reuploadRegion: '.submission-preview__reupload-wrapper'
    };
  }

  events() {
    return {
      'click .js-reselect-file': this._triggerReuploadSelect,
      'keypress .js-reselect-file': this._onPressEnter
    };
  }

  _triggerReuploadSelect() {
    this.reuploadRegion.currentView.ui.clickTarget.trigger('click');
  }

  _onPressEnter(e) {
    if (e.which === KeyCode.ENTER) {
      this._triggerReuploadSelect();
    }
  }
}

module.exports = EvaluationSubmissionPreviewView;
