// Module
var code = `<div class="broadcast-article__header">
  <div class="broadcast-article__header-content">
    <div class="broadcast-article__type-icon broadcast-article__type-icon--<%- page.type %> icon-<%- getTypeIcon() %>"></div>
    <% if(previewImageUrl) { %>
      <div class="broadcast-article__image" style="<%- previewImageUrl %>"></div>
    <% } %>

    <div class="broadcast-article__headlines">
      <%= titleElement %>
      <h5 class="broadcast-article__kicker"><%- publishedInCommunity %></h5>
    </div>
  </div>

  <button class="broadcast-article__read-button" aria-expanded="false" aria-controls="broadcast-article-content-wrapper">
    <span class="icon-chevron_down"></span>
  </button>
</div>

<div class="broadcast-article__content-wrapper" id="broadcast-article-content-wrapper" role="region" aria-label="<%- t('appNames.knowledge') + ' ' +t('broadcastMessage.region.dzContentRegion') %>">
  <div class="broadcast-article-content dz-article" role="article"><%= page.currentVersion.richContent.content %></div>
  <div class="broadcast-article__module-wrapper"></div>
  <div class="broadcast-article__attachment-wrapper"></div>

  <a href="<%= link.url %>" class="open-dz-button axon-button white" target="<%= link.target %>">
    <span class="icon-external_link"></span>
    <span class="open-dz-text"><%- t('broadcastMessage.dzArticleOpenBtn') %></span>
  </a>
</div>
`;
// Exports
module.exports = code;