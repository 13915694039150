const _ = require('underscore');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const RichContentHelper = {

  imageTemplate: _.tpl(`\
  <figure class="page__media media--image" data-media-id="<%- id %>" contenteditable="false">
    <div class="image-placeholder"></div>
  </figure>
  <div>
    <br />
  </div>\
  `),

  videoTemplate: _.tpl(`\
  <div>
  <br />
  </div>
  <figure class="page__media media--video fr-deletable" data-media-id="<%- id %>" contenteditable="false">
    <img class="video-placeholder" data-media-id="<%- id %>" />
  </figure>
  <div>
    <br />
  </div>\
  `),

  embeddedVideoPlaceholderTemplate: _.tpl(`\
  <div>
  <br />
  </div>
  <figure class="page__media media--embeddedVideo fr-deletable" data-media-id="<%- id %>" data-source="<%- source %>" contenteditable="false">
    <img class="video-placeholder" data-media-id="<%- id %>" />
  </figure>
  <div>
    <br />
  </div>\
  `),

  createImageContainerHtml(imageMedia) {
    // get data required
    const data
      = {id: imageMedia.get('id')};

    return this.imageTemplate(data);
  },

  createVideoContainerHtml(videoMedia) {
    // get data required
    const data
      = {id: videoMedia.get('id')};

    return this.videoTemplate(data);
  },

  createEmbeddedVideoContainerHtml(source, id) {
    const data = {
      source,
      id
    };

    return this.embeddedVideoPlaceholderTemplate(data);
  },

  cleanContent($content) {
    // need to put content in container in case the content is
    // nothing but a media tag that we need
    const $newContent = $('<tmp></tmp>').append($content);

    // target has to be replaced from _blank to _self for internal links,
    // that were added with paste and not with url button
    $newContent.find('a[target="_blank"]').each((index, element) => {
      if (!UrlHelpers.isExternalLink(element.href)) {
        $(element).attr('target', '_self');
      }
    });

    // Remove the contenteditable attribute from all media
    $newContent.find('.page__media').removeAttr('contenteditable');

    // Protect text that was added in IE11, because contenteditable='false' wasn't recognized. Appends text to before the .page__media
    $newContent.find('.page__media.media--video, .page__media.media--embeddedVideo').each((index, element) => {
      const $el = $(element);
      const $prevEl = $el.prev();

      if (!$prevEl.is('p') || $prevEl.text() !== '') {
        const text = $el.text();
        $el.before(`<p>${ text }</p>`);
      }
    });

    // XXX - remove the class and data-media-id attributes from
    // the div containers that don't contain img
    // An issue was created: https://github.com/neilj/Squire/issues/179
    $newContent.find('.page__media').not(':has(img)')
      .removeAttr('class')
      .removeAttr('data-media-id')
      .removeData();

    // add a container around images that do not have a proper container (has mediaId data attributes and classes)
    $newContent.find('img').not('.video-placeholder')
      .parent()
      .not('.page__media')
      .each((index, element) => {
        this._wrapElement($(element), 'image');
      });

    // add a container around video placeholders that do not have a proper container (has mediaId data attributes and classes)
    $newContent.find('img.video-placeholder').parent()
      .not('.page__media')
      .each((index, element) => {
        this._wrapElement($(element), 'video');
      });

    // remove all html within media containers
    const $mediaContainers = $newContent.find('.page__media');
    $mediaContainers.empty();

    // need to get the list of media-id still contained
    const mediaIdList = $mediaContainers.map((index, el) => {
      return $(el).data('mediaId');
    }).get();

    const richContent = {
      content: $newContent[0].innerHTML,
      mediaIdList
    };

    return richContent;
  },

  _wrapElement($element, mediaType) {
    const mediaId = $element.find('img').data('mediaId');
    const $wrapperDiv = $('<div>').addClass(`page__media media--${ mediaType }`)
      .attr('data-media-id', mediaId);
    $element.find('img').wrap($wrapperDiv);
  }
};

module.exports = RichContentHelper;
