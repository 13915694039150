const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const ActionBar = require('@training/apps/main/views/ActionBar');

class FlexibleActionBar extends ActionBar {

  static LEFT = 'leftBin';

  static CENTER = 'centerBin';

  static RIGHT = 'rightBin';


  initialize(options = {}) {
    options.actionTemplate = `
                            <div class="actioncontent">
                              <div class="flex-actioncontent ax-grid ax-grid--no-gutter">
                                <div class="flex-actioncontent__left ax-grid__col--align-self-start ax-grid__col--auto-size hidden"></div>
                                <div class="flex-actioncontent__center ax-grid__col--grow hidden"></div>
                                <div class="flex-actioncontent__right ax-grid__col--align-self-end ax-grid__col--auto-size hidden"></div>
                              </div>
                            </div>
                            `;

    super.initialize(options);
  }

  ui() {
    const ui = _.extend({}, super.ui(), {
      leftBin: '.flex-actioncontent__left',
      centerBin: '.flex-actioncontent__center',
      rightBin: '.flex-actioncontent__right'
    });

    return ui;
  }

  toggleScrollBarBuffer(toggleBuffer) {
    const padding = toggleBuffer ? `${ BrowserHelpers.getScrollbarWidth() }px` : '';
    const paddingProp = I18n.isCurrentLanguageRtl() ? 'padding-left' : 'padding-right';
    this.ui.centerBin.css(paddingProp, padding);
  }

  addConfiguredButtonToDOM($actionButton, options) {
    const selectedBin = options.bin || FlexibleActionBar.CENTER;
    this.ui[selectedBin].append($actionButton);

    if (selectedBin !== FlexibleActionBar.CENTER) {
      const $leftBin = this.ui[FlexibleActionBar.LEFT];
      const $rightBin = this.ui[FlexibleActionBar.RIGHT];

      // In order for the buttons in the center to appear centered (if one or the other corners have a button in it)
      // we show both corners
      $leftBin.removeClass('hidden');
      $rightBin.removeClass('hidden');

      const maxWidth = Math.max($leftBin.innerWidth(), $rightBin.innerWidth());

      $leftBin.innerWidth(maxWidth);
      $rightBin.innerWidth(maxWidth);

    } else {
      this.ui[selectedBin].removeClass('hidden');
    }
  }

  // this goes through the button array and adds each button
  // not calling `super()` becaues we have bin-specific logic
  addButtons(options = [], titleText) {
    const optionsCompact = _.compact([].concat(options));

    for (let [index, option] of optionsCompact.entries()) {
      const buttonsInBin = _.filter(optionsCompact, (opt) => {
        return opt.bin === option.bin;
      });

      option = $.extend(true, {}, this.getActionButtonDefaultOptions(), option);
      option.buttonIndex = index;

      this.addButton(option, buttonsInBin.length, titleText);
    }
  }

  removeButtons() {
    this.ui.leftBin.addClass('hidden').empty();
    this.ui.centerBin.addClass('hidden').empty();
    this.ui.rightBin.addClass('hidden').empty();
    this.ui.content.find('.title').remove();
  }

  hasButtons() {
    return [
      ...this.ui.leftBin.children(),
      ...this.ui.centerBin.children(),
      ...this.ui.rightBin.children()
    ].length > 0;
  }
}

module.exports = FlexibleActionBar;
