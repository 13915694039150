import type ErrorInterface from '@common/configs/interfaces/ErrorInterface';
import { createAction } from '@reduxjs/toolkit';
import { type UserRegistrationGroupSearchInterface } from '@training/apps/common/interfaces/CompanyInterface';
import type FlashMessageInterface from '@training/apps/common/interfaces/FlashMessageInterface';
import type {
  DueTaskFetchListInterface,
  TaskAssignmentStatusType,
  TaskCompletionInterface,
  TaskFetchListInterface
} from '@training/apps/tasks/interfaces/TaskInterface';

/* Store: Reset all slices to original state */
export const resetStore = createAction('resetStore');

/* User */
export const loadFeaturesAction = createAction('loadFeatures');
export const extendSession = createAction('extendSession');
export const deleteUserAction = createAction('deleteUser');

/* Tidbits */
export const loadTidbit = createAction<{
  id: number
}>('tidbit/loadTidbit');
export const loadTidbits = createAction<{
  page?: number | null
  tidbitListID?: number | null
}>('tidbit/loadTidbits');
export const markTidbitRead = createAction<{
  tidbitListID: string | number
  tidbitID: string | number
}>('tidbit/markTidbitRead');
export const clearTidbitStatusStateAction = createAction(
  'clearTidbitStatusState'
);

export const pageChangeAction = createAction<string>('pageChange');

/* Errors */
export const logErrorAction = createAction<ErrorInterface>('logError');
export const expiredSessionAction = createAction('expiredSession');

/* Tasks */
export const fetchSuggestedTasksAction
  = createAction<number>('fetchSuggestedTasks');
export const fetchTaskAssignableUsersAction = createAction<{
  groupId: number
  searchTerm: string
}>('fetchAssignableUsers');
export const fetchTaskAssignmentByCompleteTypeAction = createAction<{
  taskId: number
  completeType: TaskAssignmentStatusType
}>('fetchTaskAssignmentByCompleteTypeAction');
export const fetchTaskCompletionSummaryAction = createAction<number>(
  'fetchTaskCompletionSummary'
);
export const fetchTaskDetailsAction = createAction<number>('fetchTaskDetails');
export const fetchTaskGroupsAction = createAction<number>('fetchTaskGroups');
export const fetchTaskListAction = createAction<TaskFetchListInterface>('fetchTaskList');
export const fetchDueTaskListAction = createAction<DueTaskFetchListInterface | undefined>('fetchDueTasksList');
export const selectTaskGroupAction = createAction<TaskFetchListInterface>('selectTaskGroup');
export const markTaskCompleteAction = createAction<TaskCompletionInterface>('markTaskComplete');
export const markTaskCompleteExtendedAction = createAction<TaskCompletionInterface>('markTaskCompleteExtended');
export const markTaskIncompleteAction = createAction<TaskCompletionInterface>('markTaskIncomplete');
export const markTaskIncompleteExtendedAction = createAction<TaskCompletionInterface>('markTaskIncompleteExtended');
export const resetTaskAction = createAction('resetTaskAction');
export const fetchTaskTimelineEntriesAction = createAction<number>(
  'fetchTaskTimelineEntries'
);

/* Group */
export const fetchRegistrationGroupsAction = createAction<UserRegistrationGroupSearchInterface>('fetchGroups');

/* Flash messages */
export const showFlashMessageAction = createAction<FlashMessageInterface>('showFlashMessage');
