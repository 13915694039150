const Backbone = require('Backbone');
const ArticleDetailsCommentModel = require('@training/apps/articles/ArticleDetailsCommentModel');

class ArticleDetailsCommentCollection extends Backbone.Collection {
  constructor(models = [], options = {}) {
    super(models, options);
    ({pageId: this.pageId} = options);
  }

  get model() {
    return ArticleDetailsCommentModel;
  }

  apiEndpoint() {
    return `/pages/${ this.pageId }/comment`;
  }

  parse(data) {
    const comments = data.entity && data.entity.comments;

    if (comments) {
      comments.reverse();
    }

    return comments;
  }

  // For sorting newest first if necessary
  // comparator(a, b) {
  //   return b.get('createDateMillis') - a.get('createDateMillis');
  // }

}

module.exports = ArticleDetailsCommentCollection;
