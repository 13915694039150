import env from 'env';

import { getValue } from '@common/libs/helpers/types/ObjectHelpers';
import UrlHelpers from '@common/libs/helpers/app/UrlHelpers';

const resolveUrl = (scope: UrlBuilder, fragmentList: string[] = []) => {
  const resolvedPaths = fragmentList.map((path) => {
    return getValue(path, scope);
  });
  return UrlHelpers.combineUrlPaths(...resolvedPaths);
};

type UrlBuilderOptions = {
  baseUrl?: string
  authFragment?: string
  apiEndpoint?: string
  exportEndpoint?: string
};

export class UrlBuilder {
  baseUrl: string;

  authFragment: string;

  apiEndpoint: string;

  exportEndpoint: string;

  apiEndpointUrl: () => string;

  exportEndpointUrl: () => string;

  constructor(options: UrlBuilderOptions = {}) {
    ({
      baseUrl: this.baseUrl = env.settings.baseURL ?? '',
      authFragment: this.authFragment = '',
      apiEndpoint: this.apiEndpoint = '',
      exportEndpoint: this.exportEndpoint = ''
    } = options);
    this.apiEndpointUrl = function() {
      return resolveUrl(this, [this.baseUrl, this.authFragment, this.apiEndpoint]);
    };
  
    this.exportEndpointUrl = function() {
      return resolveUrl(this, [this.baseUrl, this.authFragment, this.exportEndpoint]);
    };
  }
}

export function createApiEndpointPrefilter(builderOptions: UrlBuilderOptions = {}) {
  return (settings: JQuery.AjaxSettings = {}) => {
    const { apiEndpoint } = settings;

    if (apiEndpoint != null) {
      const builder = new UrlBuilder(Object.assign({}, builderOptions, { apiEndpoint }));

      Object.assign(settings, {
        url: builder.apiEndpointUrl()
      });
    }
  };
}
