const Form = require('@common/components/forms/Form');
const Backbone = require('Backbone');
const _ = require('underscore');
const { axRadio } = require('@common/components/axForm/AxFormTemplateHelpers');

Form.Editor.RadioGroup = class RadioGroup extends Form.Editor {
  preinitialize(options) {
    super.preinitialize(options);
  }

  initialize(options = {}) {
    if (options.options) {
      this.setOptions(options.options);
    }
  }

  setOptions(options) {
    if (options == null) {
      throw new Error('RadioGroup options was undefined/null!');
    }

    if (!(options instanceof Backbone.Collection)) {
      throw new Error('RadioGroup options required to be instance of Backbone.Collection!');
    }

    this.collection = options;
    this.renderOptions();
    this.listenTo(this.collection, 'reset update', this.renderOptions);
  }

  renderOptions() {
    this.$el.empty();

    const tagName = this.$el.prop('tagName').toLowerCase() === 'ul' ? 'li' : undefined;

    const options = this.collection.map((model) => {
      const data = model.toJSON();

      // extend the data to include a name key used to set the same name
      // attribute for all of the radio options in the group
      _.extend(data, {
        tagName,
        name: data.name || this.cid != null ? this.cid : 'radio-group',
        label: data.label || data.value,
        ariaLabelledby: data.ariaLabelledby || null
      });

      // set the default attributes for the radio button if not defined
      _.defaults(data, {disabled: false});

      return axRadio(data);
    });

    this.$el.append(options);
  }

  render() {
    this.setValue(this.value);
    this.trigger('change', this);
  }

  getValue() {
    return this.$('input:checked').val();
  }

  setValue(value = []) {
    this.value = value;
    if (this.collection) {
      this.$('[type="radio"]').each((index, element) => {
        const checked = $(element).val() === value;
        $(element).prop('checked', checked);
      });
    }
  }

  setError() {
    this.$el.addClass('rederror');
  }

  clearError() {
    this.$el.removeClass('rederror');
  }

  disable() {
    this.$('[type="radio"]').each((index, element) => {
      $(element).prop('disabled', true);
    });
  }
};

module.exports = Form.Editor.RadioGroup;
