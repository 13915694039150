const $ = require('jquery');

if (!$.support.transition) {
  $.fn.transition = $.fn.animate;

  // Extend jQuery.easing to include our 'ease' method so that $.fn.animate doesn't throw an error
  $.extend($.easing, {
    ease(x, t, b, c, d) {
      // Use the easeInOutQuad function since it's similar to 'ease'
      if ((t /= d / 2) < 1) {
        return ((c / 2) * t * t) + b;
      }
      return ((-c / 2) * (((--t) * (t - 2)) - 1)) + b;
    }
  });
}
