const ProgramOverviewController = require('@common/components/guidedLearning/programs/ProgramOverviewController');
const ProgramOverviewIntroController = require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewIntroController');
const ProgramOverviewPathContentController = require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewPathContentController');
const SelfDirectedPathProgramOverviewProgressController = require('@training/apps/training/views/selfDirectedPaths/programs/ProgramOverview/SelfDirectedPathProgramOverviewProgressController');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');
const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');


class SelfDirectedPathsProgramOverviewController extends ProgramOverviewController {
  get childControllers() {
    return [
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('path-overview__intro ax-grid__col--12 ax-grid__col--m-8'),
        {
          ViewControllerClass: ProgramOverviewIntroController,
          model: this.options.model
        }),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('path-overview__sidebar ax-grid__col--12 ax-grid__col--m-4'),
        {
          ViewControllerClass: SelfDirectedPathProgramOverviewProgressController,
          model: this.options.model,
          objectiveStarter: this.options.objectiveStarter,
          objectiveValidator: this.options.objectiveValidator,
          sortedObjectives: this.options.sortedObjectives,
          reloadCallback: this.options.reloadCallback
        }),
      StackedLayoutViewController.createConfigObject(ViewOptionHelpers.classNameAppender('path-overview__content ax-grid__col--12 ax-grid__col--m-8'),
        {
          ViewControllerClass: ProgramOverviewPathContentController,
          viewDefinition: {
            model: this.options.model,
            objectiveStarter: this.options.objectiveStarter,
            objectiveValidator: this.options.objectiveValidator,
            sortedObjectives: this.options.sortedObjectives,
            viewTopicDrillDown: this.options.viewTopicDrillDown,
            isSelfDirected: true,
            isEnrolled: this.options.model.getEnrollmentStatus()
          }
        })
    ];
  }
}

module.exports = SelfDirectedPathsProgramOverviewController;
