const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const KeyCode = require('@common/data/enums/KeyCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const { LayoutView } = require('Marionette');

class EventCheckinCardView extends LayoutView {
  constructor(options = {}) {
    super(options);

    const EventCheckinModel = Backbone.Model.extend({
      apiEndpoint: '/learning-event-enrollments/checkin'
    });
    this.model = new EventCheckinModel();
  }

  getTemplate() {
    return require('@training/apps/home/eventSelfCheckin/EventCheckinCardView.html');
  }

  ui() {
    return {
      codeInput: 'input',
      submitButton: '.js-submit-button',
      noneInfoString: '.js-none-info'
    };
  }

  events() {
    return {
      'keydown @ui.codeInput': '_onKeydownInput',
      'click @ui.submitButton': '_onClickSubmit'
    };
  }

  _onKeydownInput(e) {
    if (e.which === KeyCode.ENTER) {
      this._onClickSubmit();
    }
  }

  _onClickSubmit() {
    if (this.ui.codeInput.val().trim() === '') {
      this.ui.codeInput.toggleClass('rederror', true);
      window.app.layout.flash.error(I18n.t('eventCheckin.errors.empty'));
    } else if (this.model != null) {
      this.model.save({
        checkInCode: this.ui.codeInput.val()
      },
      {
        success: this._onCheckinSuccess.bind(this),
        error: this._onCheckinFailure.bind(this)
      });
    }
  }

  _onCheckinSuccess(model, response) {
    this.ui.codeInput.toggleClass('rederror', false).val('');

    const eventName = response.eventName;
    const checkInAvailable = response.checkInAvailable;

    window.app.layout.flash.success(I18n.t('eventCheckin.success', { eventName }));

    if (!checkInAvailable) {
      window.app.sessionController.setSelfCheckinModel({ checkInAvailable });

      this.ui.codeInput.hide();
      this.ui.submitButton.hide();
      this.ui.noneInfoString.removeClass('hidden');
    }
  }

  _onCheckinFailure(model, response) {
    response.skipGlobalHandler = true;
    this.ui.codeInput.toggleClass('rederror', true);

    const exception = AxonifyExceptionFactory.fromResponse(response);
    const errorCode = exception.getErrorCode();

    window.app.layout.flash.error(I18n.t('eventCheckin.errors.' + errorCode));
  }
}

module.exports = EventCheckinCardView;
