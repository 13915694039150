const { LayoutView } = require('Marionette');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const _ = require('underscore');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/mutationObservable/MutationObservable');

class TopicDetailsPageView extends LayoutView {

  constructor(options = {}) {
    super(options);

    this.listenTo(this, 'dom:mutate', () => {
      triggerResize(true);
    });
  }

  className() {
    if (!_.isEmpty(this.model.get('media'))) {
      return 'topic-details';
    }
    return 'topic-details no-image';

  }

  getTemplate() {
    return `
    <div class="ax-container">
      <div class="page-content">
        <div class="topic-details__header js-topic-title-region"></div>
        <div class="topic-details__content ax-grid clearfix">
        <%if (!_.isEmpty(media)) { %>
          <div role="presentation" class="topic-details__region topic-details__region--image ax-grid__col--12 ax-grid__col--m-7"></div>
        <% } %>
          <div class="topic-details__region topic-details__region--performance js-topic-performance-region ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--m-offset-1"></div>
          <div class="topic-details__region topic-details__region--description js-topic-description-region ax-grid__col--12 ax-grid__col--m-7"></div>
          <div class="topic-details__region topic-details__region--paths js-topic-path-region <%- pathsClass %> ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--m-offset-1"></div>
        </div>
      </div>
    </div>
`;
  }

  templateHelpers() {
    const hasGLPrograms = !_.isEmpty(this.model.get('guidedLearningPrograms'));
    const hasSDPrograms = !_.isEmpty(this.model.get('selfDirectedPrograms'));

    return {
      pathsClass: (hasGLPrograms || hasSDPrograms) ? '' : 'hidden'
    };
  }

  regions() {
    return {
      titleRegion: '.js-topic-title-region',
      performanceRegion: '.js-topic-performance-region',
      descriptionRegion: '.js-topic-description-region',
      learningPathsRegion: '.js-topic-path-region'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      MutationObservable: {
        observeOptions: {
          childList: true,
          subtree: true
        }
      }
    };
  }

  onAttach() {
    this.media = this.model.get('media');
    this.parent = this.$el;

    if (!_.isEmpty(this.media)) {
      this.topicImage = ImageViewerFactory.createViewerInstance({
        media: this.media,
        $el: this.parent.find('.topic-details__region--image')
      });

      if (this.topicImage != null) {
        this.listenTo(this.topicImage, 'image:loaded', () => {
          this.trigger('image:loaded');
        });
        this.topicImage.render();

        this.onResize = () => {
          return this.topicImage.resize();
        };
      }
    }

    window.app.layout.showHeaderBackButton();
  }

  onDestroy() {
    window.app.layout.resetLeftHeaderView();
  }
}

module.exports = TopicDetailsPageView;
