import {
  TableBody as MuiTableBody,
  styled,
  type TableBodyTypeMap as MuiTableBodyTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTableBodyCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableBodyTypeMap<P = object, D extends React.ElementType = 'tbody'> = MuiTableBodyTypeMap<P & AxTableBodyCustomProps, D>;

export type AxTableBodyProps<
  D extends ElementType = AxTableBodyTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableBodyTypeMap<P & AxTableBodyCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableBody'
};

const StyledAxTableBody = styled(MuiTableBody, styledOptions)<AxTableBodyProps>(() => {
  return {};
});

/**
 * The container for the body rows of `<AxTable />`. Renders as a `<tbody>` by default.
 *
 * ## Links
 * - • [MUI | Table Body API](https://mui.com/material-ui/api/table-body/)
 */
export const AxTableBody: MuiOverridableComponent<AxTableBodyTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableBodyProps, ref: Ref<HTMLTableSectionElement>) => {
  const tableBodyProps: AxTableBodyProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableBody { ...tableBodyProps } ref={ ref }>
      { children }
    </StyledAxTableBody>
  );
});

export default AxTableBody;
