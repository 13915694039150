const {IntroductoryTraining} = require('@common/data/enums/SessionTrainingType');

const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');

const QuizboardSelectableAssessmentController = require('@training/apps/training/controllers/assessments/QuizboardSelectableAssessmentController');

class IntroductoryTrainingAssessmentController extends QuizboardSelectableAssessmentController {
  getTimeLogOptions(options = {}) {
    const {
      isFromHub = false
    } = options;

    if (isFromHub) {
      return TimeLogConfig.PageView.HubIntroductoryTraining;
    }
    return TimeLogConfig.PageView.AutomaticIntroductoryTraining;

  }

  getSessionTrainingType() {
    return IntroductoryTraining;
  }
}

module.exports = IntroductoryTrainingAssessmentController;
