const AxonifyObject = require('@common/libs/AxonifyObject');
const I18n = require('@common/libs/I18n');

const QuestionAnswer = require('@common/data/models/questions/QuestionAnswer');
const QuestionTypeFactory = require('@common/components/questions/QuestionTypeFactory');

const QuestionLayoutWrapper = require('@common/components/questions/QuestionLayoutWrapper');
const ImageZoomController = require('@common/components/questions/ImageZoomController');

const VariantType = require('@common/data/enums/VariantType');
const ModalSettings = require('@common/components/modal/ModalSettings');

class QuestionPreviewController extends AxonifyObject {

  constructor(...args) {
    super(...args);

    this._initializeQuestionWrapper = this._initializeQuestionWrapper.bind(this);
    this.loadQuestionPreview = this.loadQuestionPreview.bind(this);
    this._loadQuestionPreviewByModel = this._loadQuestionPreviewByModel.bind(this);
    this._createQuestionAnswerModelFromData = this._createQuestionAnswerModelFromData.bind(this);
    this._populateQuestionAnswerWithProperData = this._populateQuestionAnswerWithProperData.bind(this);
    this._loadQuestionPreviewByFetch = this._loadQuestionPreviewByFetch.bind(this);
    this._fetchQuestionAnswer = this._fetchQuestionAnswer.bind(this);
    this._renderQuestionWrapper = this._renderQuestionWrapper.bind(this);
    this._onQuestionWrapperRender = this._onQuestionWrapperRender.bind(this);
    this._setupQuestionLayoutAreas = this._setupQuestionLayoutAreas.bind(this);
    this._showQuestionLayoutAreas = this._showQuestionLayoutAreas.bind(this);
    this._setupQuestionTypeWrapperView = this._setupQuestionTypeWrapperView.bind(this);
    this._setupQuestionTypeWrapperTitle = this._setupQuestionTypeWrapperTitle.bind(this);
    this._showQuestionTypeTitle = this._showQuestionTypeTitle.bind(this);
    this._showQuestionTypeView = this._showQuestionTypeView.bind(this);
    this._getQuestionView = this._getQuestionView.bind(this);
    this._getQuestionTypeTitle = this._getQuestionTypeTitle.bind(this);
    this.onDestroy = this.onDestroy.bind(this);
  }

  initialize(options = {}) {
    const modalDefaults = ModalSettings;
    const extendedModalOptions = $.extend(true, {}, modalDefaults, options);
    this._initializeQuestionWrapper(extendedModalOptions);

    if (options.imageZoom) {
      this.imageZoom = options.imageZoom;
    } else {
      const zoomOptions = this._getImageZoomOptions(options);
      this.imageZoom = new ImageZoomController({
        extendedModalOptions,
        zoomOptions
      });
    }
  }

  _initializeQuestionWrapper(extendedModalOptions) {
    this.questionLayoutWrapper = new QuestionLayoutWrapper({
      extendedModalOptions,
      modalHeaderOptions: this.modalHeaderOptions,
      modalFooterOptions: this.modalFooterOptions
    });
    this.listenTo(this.questionLayoutWrapper, 'render', this._onQuestionWrapperRender);
  }

  _getImageZoomOptions(options) {
    return {
      container: options.zoomContainer != null ? options.zoomContainer : '#root-view',
      zIndex: options.zoomOptions && options.zoomOptions.zIndex || 900
    };
  }

  loadQuestionPreview(questionId) {
    this._loadQuestionPreviewByFetch(questionId);
  }

  _loadQuestionPreviewByModel(options) {
    this._createQuestionAnswerModelFromData(options);

    // If the question hasn't been answered (i.e Admin/Content Question Views)
    // Just render the page instead of checking for valid answers (as in this case there is none)
    if (!options.hasAnswered) {
      this.questionLayoutWrapper.render();
    } else {
      this._renderQuestionWrapper();
    }
  }

  _createQuestionAnswerModelFromData(options) {
    const {
      model,
      translatedLanguage,
      hasAnswered
    } = options;
    this.questionAnswer = new QuestionAnswer();

    this.questionAnswer.set({
      variant: model,
      hasAnswered,
      translatedLanguage,
      questionOptionList: !hasAnswered ? '' : undefined // Unanswered questions wont have this list so just set it an empty string
    });

    //# TODO Implement features is other branches
    this._populateQuestionAnswerWithProperData();
  }

  _populateQuestionAnswerWithProperData() {
    if (this.questionAnswer.get('variant').get('variantType') === VariantType.Hotspot) {
      this.questionAnswer.setupHotspotAnswerOptions();
    }

    if (this.questionAnswer.get('variant').get('variantType') === VariantType.JumbledSentence) {
      this.questionAnswer.setupFITBAnswerOptions();
    }

    if (this.questionAnswer.get('variant').get('variantType') === VariantType.Matching) {
      this.questionAnswer.setupMatchingAnswerOptions();
    }
  }

  _loadQuestionPreviewByFetch(questionId) {
    this.questionAnswer = new QuestionAnswer({
      id: questionId
    });
    return $.when(this._fetchQuestionAnswer()).done(this._renderQuestionWrapper);
  }

  _fetchQuestionAnswer() {
    return this.questionAnswer.fetch();
  }

  _renderQuestionWrapper() {
    if (this.isDestroyed) {
      return;
    }

    if (this.questionAnswer.isStillValidAnswer()) {
      this.questionLayoutWrapper.render();
    } else {
      const options = {
        cssClass: 'error',
        message: I18n.t('question.previewUnavailable')
      };
      this.trigger('invalid:answer', options);
    }
  }

  // By default we show a new view.  Only on the next/previous in admin/content
  // are we showing an updated view.  We only want to update the view in this case
  // so the modal in QuestionLayoutWrapper doesn't try to re-open the modal
  _onQuestionWrapperRender() {
    this._setupQuestionLayoutAreas();
    this._showQuestionLayoutAreas();
  }

  _setupQuestionLayoutAreas() {
    this._setupQuestionTypeWrapperView();
    this._setupQuestionTypeWrapperTitle();
  }

  _showQuestionLayoutAreas(isUpdatedView = false) {
    this._showQuestionTypeTitle();
    this._showQuestionTypeView(isUpdatedView);
  }

  _setupQuestionTypeWrapperView() {
    this.questionLayoutWrapper.setQuestionTypeView(this._getQuestionView());
  }

  _setupQuestionTypeWrapperTitle() {
    this.questionLayoutWrapper.setQuestionTypeTitle(this._getQuestionTypeTitle());
  }

  _showQuestionTypeTitle() {
    this.questionLayoutWrapper.showQuestionTitle();
  }

  _showQuestionTypeView(isUpdatedView) {
    this.questionLayoutWrapper.showQuestionPreviewView(isUpdatedView);
  }

  _getQuestionView() {
    const view = QuestionTypeFactory.getQuestionBody(this.questionAnswer);

    this.listenTo(view, 'show:zoom:modal', (imageMedia) => {
      this.imageZoom.showImageZoomView(imageMedia);
    });

    return view;
  }

  _getQuestionTypeTitle() {
    return VariantType.getQuestionTypeString(this.questionAnswer.get('variant').get('variantType'));
  }

  onDestroy() {
    this.imageZoom.closeModal();
    this.questionLayoutWrapper.closeModal();
  }
}

module.exports = QuestionPreviewController;
