const _ = require('underscore');
const {
  classListContainerInput,
  classListDefault,
  dataListDefault,
  getPropsInput
} = require('./AxFormPropertyHelpers');

const inputTemplate = (containerClass, props) => {
  const classDefault = containerClass.replace('.', '');

  return _.tpl(`
    <div class="${ classListContainerInput(classDefault, props) }">
      ${ props.label ? `
        <label
          for="${ props.id }"
          class="ax-font ${ props.classNameLabel ? props.classNameLabel + ' ' : '' } ${ props.required ? 'label--required' : '' }"
        >
          ${ props.label }
        </label>` : '' }
      ${ props.iconClassLeft ? '<span class="' + props.iconClassLeft + '" aria-hidden="true" role="presentation"></span>' : '' }
      <${ props.tagName }
        type="${ props.type }"
        id="${ props.id }"
        ${ props.ariaLabel ? 'aria-label="' + props.ariaLabel + '"' : '' }
        ${ props.disabled ? 'disabled' : '' }
        ${ props.required ? 'required' : '' }
        ${ props.placeholder ? 'placeholder="' + props.placeholder + '"' : '' }
        ${ props.name ? 'name="' + props.name + '"' : '' }
        ${ props.value && props.type !== 'textarea' ? 'value="' + _.escape(props.value) + '"' : '' }
        ${ props.autocomplete ? 'autocomplete="' + props.autocomplete + '"' : '' }
        ${ props.autocapitalize ? 'autocapitalize="' + props.autocapitalize + '"' : '' }
        ${ props.autocorrect ? 'autocorrect="' + props.autocorrect + '"' : '' }
        ${ props.ariaControls ? 'aria-controls="' + props.ariaControls + '"' : '' }
        ${ classListDefault(props.qaClassNameInput, props.classNameInput) }
        ${ dataListDefault(props) }

        ${ props.min ? 'min="' + props.min + '"' : '' }
        ${ props.max ? 'max="' + props.max + '"' : '' }
        ${ props.step ? 'step="' + props.step + '"' : '' }

        ${ props.rows ? 'rows="' + props.rows + '"' : '' }
        ${ props.cols ? 'cols="' + props.cols + '"' : '' }
      >${ props.value && props.type === 'textarea' ? _.escape(props.value) : '' }</${ props.tagName }>
      ${ props.iconClassRight ? `
        ${ props.showDropdownIcon ? `
          <button
            class="dropdown-icon-right ${ props.iconClassRight }"
            disabled=true
            aria-hidden="true"
          ></button>` : `
            <button
              class="${ props.iconClassRight }"
              ${ props.iconAriaLabelRight ? 'aria-label="' + props.iconAriaLabelRight + '"' : '' }
              type="button"
            ></button>` }` : '' }
    </div>
  `)(props);
};

const inputContainer = '.ax-input';

const AxFormInputHelpers = {
  axFormSelectors: {
    inputContainer: inputContainer,
    input: inputContainer + ' input',
    textArea: inputContainer + ' textarea'
  },

  axInput(customProps) {
    return inputTemplate(inputContainer, _.defaults(customProps, getPropsInput(customProps)));
  }
};

module.exports = AxFormInputHelpers;
