const { ItemView } = require('Marionette');

class DiscoverTrainingCardGraphicView extends ItemView {

  getTemplate() {
    return `
      <div class="icon--discover icon--discover-lg icon--badge-app-zone icon-search"></div>
    `;
  }

  className() {
    return 'training-card__graphic training-card__graphic';
  }
}


module.exports = DiscoverTrainingCardGraphicView;
