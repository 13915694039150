// Module
var code = `<div class="recovery-input">
  <div class="title-wrapper">
    <div class="title-block">
      <div class="recovery-icon icon-lock"></div>
      <div class="recovery-title-wrapper">
          <div class="recovery-title"><%- t('login.forgotPassword.inputTitle') %></div>
          <div><%- t('login.forgotPassword.inputSubtitle') %></div>
      </div>
    </div>
  </div>

  <form class="js-recovery-form">
    <div>
      <label for="password-recovery-username">
        <%- t('login.forgotPassword.inputLabel') %>
      </label>
      <input type="text" id="password-recovery-username" />
    </div>
    <div class="recovery-option-container">
      <label for="flowoptions"><%- t('login.forgotPassword.flowOptions.title') %></label>
      <ul id="flowoptions" class="flow-options" data-field="nextFlowState" data-editor="RadioGroup" data-options="flowOptions"></ul>
    </div>

    <div class="captcha-container">
      <div data-editor="Captcha" data-field="recaptcha" data-options="captchaOptions"></div>
    </div>

    <div class="recovery-submit">
      <a href="#" class="axon-button submit" role="button"><%- t('login.submit') %></a>
    </div>
  </form>
</div>
`;
// Exports
module.exports = code;