// Module
var code = `<div class="learning-objectives__progress js-progress-region">
  <div class="learning-objectives__progress-connector learning-objectives__progress-connector--previous <%- previousCssClass %>"></div>
  <div class="learning-objectives__progress-connector learning-objectives__progress-connector--next <%- nextCssClass %>"></div>
  <div class="learning-objectives__progress-indicator learning-objectives__progress-indicator--large icon-placeholder <%- indicatorCssClass %>"></div>
</div>
<div class="milestone-content">
  <div class="milestone-content__icon icon-milestone"></div>
  <div class="milestone-content__info-wrapper">
    <div class="milestone-content__info">
      <h2 class="milestone-content__title">
        <span title="<%- name %>"><%- name %></span>
      </h2>
      <% if(isEnrolled()) { %>
        <p class="<%- isGrandfathered() %>"><%= getDueDate() %></p>
      <% } else { %>
        <p><%= getDuration() %></p>
      <% } %>
    </div>
    <div class="milestone-content__rewards <%- showRewardsSection() %>">
      <p><%- t('GuidedLearning.milestoneRow.rewardPointsText') %></p>
      <div>
        <span class="<%- getRewardIcon() %> milestone-content__reward-icon"></span>
        <span class="milestone-content__reward-text"><%- getPointsAvailableText() %></span>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;