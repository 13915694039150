const { ItemView } = require('Marionette');

require('@common/libs/behaviors/pressedHoverButton/PressedHoverButtonBehavior');

class MenuItemView extends ItemView {
  tagName() {
    return 'li';
  }

  className() {
    return 'inline-block';
  }

  getTemplate() {
    return `
      <a href="<%- href %>" class="js-menu-item-link <%- linkClass %>">
        <span class="item-icon <%- iconClass %>"></span><span class="item-text"><%- text %></span><span class="item-selected-bar"></span>
      </a>
    `;
  }

  ui() {
    return {
      text: '.item-text'
    };
  }

  behaviors() {
    return Object.assign(super.behaviors(), {
      PressedHoverButtonBehavior: {
        buttonTarget: '.js-menu-item-link'
      }
    });
  }

  constructor(options = {}) {
    super(options);
    ({
      text: this.text,
      iconClass: this.iconClass,
      linkClass: this.linkClass = 'menu-item'
    } = options);

    // It is possible for the href option to be passed in with a value of '' (empty string)
    // We must check and account for this independently of what is happening in the block above
    this.href = (options.href != null && options.href !== '') ? options.href : '#';
  }

  templateHelpers() {
    return {
      text: this.text,
      iconClass: this.iconClass,
      linkClass: this.linkClass,
      href: this.href
    };
  }
}

module.exports = MenuItemView;
