const logging = require('logging');
const _ = require('underscore');

const ProfileIconHandler = require('@common/components/ProfileIconHandler');

const LeaderboardViewBase = require('./LeaderboardViewBase');
const GameChallenge = require('../models/GameChallenge');

class LeaderboardView extends LeaderboardViewBase {

  get challengeResultDetailsTemplate() {
    return _.tpl(require('../templates/_challenge_result_details.html'));
  }

  attributes() {
    return {
      id: 'leaderboardmodal'
    };
  }

  constructor(options = {}) {
    super(options);

    ({
      game: this.game,
      score: this.score,
      questionsRemaining: this.questionsRemaining,
      gameOverStringId: this.gameOverStringId
    } = options);

    this.data = this.computeData();
  }

  contentTemplate() {
    const templateFn = _.tpl(require('../templates/LeaderboardView.html'));
    return templateFn(this.data);
  }

  onRenderContent() {
    for (let i = 1; i <= 3; i++) {
      const user = this.data.highScores[i - 1];
      if (user != null) {
        const $avatarElement = this.$(`.user-${ i }`).find('.avatar');
        // eslint-disable-next-line no-new
        new ProfileIconHandler({
          user,
          profileElement: $avatarElement
        });
      }
    }

    const {challenge} = this._computeChallengeInfo();
    if (challenge != null) {
      this.$('.challenge-region').html(this.challengeResultDetailsTemplate({challenge}));

      const $challengerLogo = this.$('.challenge-region .challenger .small-name-logo');
      // eslint-disable-next-line no-new
      new ProfileIconHandler({
        user: challenge.challenger,
        profileElement: $challengerLogo
      });

      const $challengeeLogo = this.$('.challenge-region .challengee .small-name-logo');
      // eslint-disable-next-line no-new
      new ProfileIconHandler({
        user: challenge.challengee,
        profileElement: $challengeeLogo
      });
    }
  }

  viewDidAppear() {
    super.viewDidAppear();
    logging.info('LeaderboardView - viewDidAppear');
  }

  computeData() {
    return Object.assign({}, this._computeScores(), this._computeChallengeInfo());
  }

  _computeScores() {
    // Compute scores
    // if this is singlePlayer game, get user score from 'singlePlayerScoreBoard' mechanic
    let highScores, score, userHighScore, bestHighScore;
    const scoreBoardMechanic = this.game.getMechanic('singlePlayerScoreBoard');
    if (scoreBoardMechanic != null) {
      if (scoreBoardMechanic.state != null) {
        if (scoreBoardMechanic.state.userScore != null) {
          userHighScore = scoreBoardMechanic.state.userScore.highScore;
        }

        highScores = scoreBoardMechanic.state.highScores;
      }
    }
    let higherInList = false;

    let i = 0;
    while (i < highScores.length) {
      score = highScores[i];

      if (score.current) {
        if (score.leadScore < this.score) {
          // Don't increment i on splice
          highScores.splice(i, 1);
        } else {
          higherInList = true;
          i++;
        }
      } else {
        i++;
      }
    }

    if (!higherInList) {
      const highScore = {
        name: window.apps.auth.session.user.get('salutationName'),
        leadScore: this.score
      };
      highScores.push(highScore);
    }

    highScores = _.sortBy(highScores, (x) => {
      return -x.leadScore;
    }); // sort in descending order
    highScores = highScores.splice(0, 3);

    if (userHighScore != null) {
      bestHighScore = Math.max(userHighScore, this.score);
    } else {
      bestHighScore = this.score;
    }

    // Render the template
    return {
      score: this.score,
      highScores,
      userHighScore,
      bestHighScore,
      questionsRemaining: this.questionsRemaining,
      gameOverStringId: this.gameOverStringId
    };
  }

  _computeChallengeInfo() {
    let challenge;
    const challengeObj = this.game.getMechanic('challenge');

    if (challengeObj != null) {
      if (challengeObj.state != null) {
        challenge = challengeObj.state.challenge;
      }
    }

    if (challenge != null) {
      const gameChallenge = new GameChallenge(challenge);
      challenge = gameChallenge.toJSON();
    }

    return {challenge};
  }
}

module.exports = LeaderboardView;
