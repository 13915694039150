const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

class ArticleDetailsCommentModel extends Backbone.Model {

  constructor(attrs = {}, options = {}) {
    super(attrs, options);
    this.apiEndpoint = this.apiEndpoint.bind(this);
    ({
      pageId: this.pageId,
      commentText: this.commentText,
      authorUserId: this.authorUserId
    } = options);
    this.validators = this.validators.bind(this);
  }

  get className() {
    return 'ArticleDetailsCommentModel';
  }

  apiEndpoint() {
    return `/userComment`;
  }

  validators() {
    return {
      commentText: ['required', this.validateComment]
    };
  }

  validateComment (value) {
    const v = value.trim().length;
    if (v <= 0) {
      return [I18n.t('discover.comments.post.textarea.error.empty')];
    }
    return undefined;
  }

}

module.exports = ArticleDetailsCommentModel;
