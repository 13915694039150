const Marionette = require('Marionette');
const { INFORMATIONAL } = require('@common/libs/behaviors/card/CardModifierClasses');

const ProgressBar = require('@common/components/progress/progressBar/ProgressBar');

class ProgressCardView extends Marionette.LayoutView {
  initialize(options = {}) {
    ({
      shouldUsePercentage: this.shouldUsePercentage
    } = options);
  }

  modelEvents() {
    return {
      change: 'render'
    };
  }

  getTemplate() {
    return require('@common/components/learningObjectives/ProgressCardView.html');
  }

  className() {
    return 'progress-card qa-progress-card';
  }

  templateHelpers() {
    return {
      completed: this.model.get('finishedCount'),
      available: this.model.get('availableCount'),
      percent: this.model.get('percent'),
      shouldUsePercentage: this.shouldUsePercentage
    };
  }

  onRender() {
    const progressBar = new ProgressBar({
      value: this.model.get('percent'),
      animate: this.model.get('animate'),
      barColor: this.model.get('barColor')
    });

    this.progress.show(progressBar);
  }

  regions() {
    return {
      progress: '.progress-container'
    };
  }

  behaviors() {
    return {
      Card: {
        modifierClasses: [INFORMATIONAL]
      }
    };
  }
}

module.exports = ProgressCardView;
