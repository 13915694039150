import I18n from '@common/libs/I18n';
import {
  AxIconButton,
  AxTooltip,
  type AxIconButtonProps,
  type AxTooltipProps
} from '@common/modules/react/themes/components';
import { ClickAwayListener } from '@mui/material';
import { useState } from 'react';


export type AccessibleTooltipButtonProps = {
  tooltipProps: MakeKeyOptional<AxTooltipProps, 'children'>,
  buttonProps: AxIconButtonProps
};

export default function AccessibleTooltipButton(props: AccessibleTooltipButtonProps) {
  const {
    tooltipProps,
    buttonProps
  } = props;

  const [open, setOpen] = useState(false);

  const handleIconButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    buttonProps?.onClick?.(event);
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    tooltipProps?.onClose?.(new Event('click'));
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={ handleTooltipClose }>
      <AxTooltip
        aria-label={ I18n.t('accessibility.tooltipInstructions') }
        placement='top'
        { ...tooltipProps }
        open={ open }
        onClose={ handleTooltipClose }
        //These are important to disable to preserve the click handler effects
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <AxIconButton
          size='medium'
          variant='ghost'
          className='icon-info_sign'
          { ...buttonProps }
          onClick={ handleIconButtonClick }
        />
      </AxTooltip>
    </ClickAwayListener>
  );
}
