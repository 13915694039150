const _ = require('underscore');
const Polyglot = require('@common/data/models/translationStrings/Polyglot');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class LocalizableString extends Polyglot {
  initialize(attributes, options) {
    this.className = 'LocalizableString';
    this.translationProperty = 'translations';
    super.initialize(attributes, options);
  }

  toJSON(...args) {
    const json = super.toJSON(...args);
    _.each(this.get(this.translationProperty), (value, k) => {
      if (!HTMLHelpers.htmlHasSomeText(value)) {
        // Since we're not going to send an invalid value, remove it from the model.
        this.setValueForLanguage(k, '');
        delete json.translations[k];
      }
    });

    if (!HTMLHelpers.htmlHasSomeText(this.get('default'))) {
      // Since we aren't sending the 'bad' default value, clear it
      this.setDefault('');
      delete json.default;
      if (!_.isEmpty(this.get(this.translationProperty))) {
        // Some default setting logic for a weird edge-case where setting the non-preferred locale translation first,
        // then setting the preferred locale translation to an invalid string ex: <script>
        // will delete the default. This will default the translation to the first translation it finds
        this.setDefault(this.getValueForLanguage('', true)); // Find the first 'good' translation
        json.default = this.get('default');
      }
    }

    return json;
  }

  getValues() {
    return this.get('translations');
  }

  getAvailableTranslationLangCodes() {
    return _.keys(this.getValues());
  }

  getDefault() {
    return this.get('default');
  }

  setDefault(value) {
    this.set('default', value);
  }

  setDefaultWithPreferredLocale(preferredLocaleCode) {
    const currentValueForLanguage = this.getValueForLanguage(preferredLocaleCode, false);
    if (currentValueForLanguage) {
      this.setDefault(currentValueForLanguage);
    } else {
      this.unset('default'); // This is to force the _getFallbackValue() call to reset the default instead of falling back to it
      const fallback = this.getValueForLanguage(preferredLocaleCode, true);
      this.setDefault(fallback);
    }
  }

  _getFallbackValue() {
    const defaultString = this.getDefault();
    if (defaultString != null) {
      return defaultString;
    }
    const attributes = this._safeGetTranslationAttributes();
    return attributes['EN'];

  }

  static fromString(str, language = 'EN') {
    const localeStr = new LocalizableString();
    localeStr.setValueForLanguage(language, str);
    return localeStr;
  }

  static fromValue(value) {
    if (value == null) {
      return undefined;
    }

    if (_.isString(value)) {
      return this.fromString(value);
    } else if (_.isObject(value)) {
      return new LocalizableString(value);
    }

    return undefined;
  }
}

module.exports = LocalizableString;
