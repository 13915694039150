const Community = require('@common/data/models/Community');
const PageableCollection = require('@common/components/pageableList/PageableCollection');

const PAGE_SIZE = 50;
class CommunityList extends PageableCollection {
  initialize(models, options = {}) {
    ({extraParams: this.extraParams} = options);

    super.initialize(models, options);
    this.state.pageSize = PAGE_SIZE;
    this.state.currentPage = this.extraParams.p;
  }

  // server will return a boolean instead of a list here
  static hasAvailableCommunities() {
    return $.ajax({
      type: 'GET',
      apiEndpoint: 'pageCommunities/accessible'
    });
  }

  apiEndpoint() {
    return '/pageCommunities';
  }

  get model() {
    return Community;
  }

  parseState(resp) {
    return {
      totalRecords: resp.totalCount
    };
  }

  fetch(options = {}) {
    options.type = 'GET';
    return super.fetch($.extend(true, {}, { data: this.extraParams }, options));
  }

  comparator(communityA, communityB) {
    const nameA = communityA.getName().toLowerCase();
    const nameB = communityB.getName().toLowerCase();

    // When the names are alphabetically equivalent, compare the IDs
    if (nameA === nameB) {
      return communityA.get('id') < communityB.get('id') ? -1 : 1;
    }

    return nameA < nameB ? -1 : 1;
  }
}

module.exports = CommunityList;
