const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'START',
  'RETAKE',
  'LOCKED',
  'VIEW',
  'VIEW_INTERACTIVE',
  'ENROLL',
  'ENROLL_INTERACTIVE',
  'NONE',
  'REASSIGN',
  'RESUME'
]);
