const { LayoutView } = require('Marionette');
class PageHistoryLayout extends LayoutView {
  className() {
    return 'article-contributor-history-layout';
  }

  getTemplate() {
    return require('@training/apps/articles/PageHistoryLayout.html');
  }

  regions() {
    return {
      titleRegion: '#title',
      contentRegion: '#content'
    };
  }

  ui() {
    return {
      contentRow: '.row-content',
      content: '#content'
    };
  }

  onAttach() {
    window.app.layout.showHeaderBackButton();
  }
}

module.exports = PageHistoryLayout;
