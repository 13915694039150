const { sendWarnLog } = require('LoggingService');
const logging = require('logging');
const $os = require('detectOS');

const { View } = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const SCORMRTE = require('@common/libs/scorm/scorm-rte');
const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');
const SCORMErrorView = require('@training/apps/training/views/activities/pages/scorm/SCORMErrorView');

const KeyCode = require('@common/data/enums/KeyCode');

const {
  ActionBarType,
  createButtonType
} = require('@common/components/actionBarButton/ActionBarButtonFactory');

class SCORMBaseView extends View {
  // Double submit prevention
  nextDisabled = false;

  isErrorLoadingModule = false;

  constructor(options = {}) {
    logging.info('SCORMBaseView - constructor');

    super(options);

    this.keyPress = this.keyPress.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.completeScorm = this.completeScorm.bind(this);

    this.activity = assertPropertyExists(options, 'activity');
    this.continueButtonFactory = assertPropertyExists(options, 'continueButtonFactory');

    ({
      isRetake: this.isRetake = false,
      isExtraTraining: this.isExtraTraining = false,
      complete: this.complete = () => {}
    } = options);
  }

  render() {
    logging.info('SCORMBaseView - render');
    // Load view
    this.$el.html(this.template(this.templateHelpers ? this.templateHelpers() : {}));

    return this;
  }

  onAttach() {
    logging.info('SCORMBaseView - onAttach');

    window.app.layout.setTitle(I18n.t('scorm.title'));

    // Only display skip for retake or extratraining assessments.
    if (this.isRetake || this.isExtraTraining) {
      this.actionBar.setActionButton(createButtonType(ActionBarType.SKIP, this.onSkip));
      this.triggerAdjustment();
    }

    // Display flash message if scorm is not mobile safe and user is on mobile
    const isNotMobileSafeOnMobile = ($os.mobile && !this.activity.get('body').mobileSafe);
    if (isNotMobileSafeOnMobile) {
      window.app.layout.flash.error(I18n.t('scorm.desktopRequired'), 0);
    }

    // Window events
    $(document).on('keypress', this.keyPress);
  }

  keyPress(e) {
    logging.info(`SCORMBaseView - keyPress(${ e.which })`);

    if (this.scormComplete && (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE)) {
      return this.onNext();
    }

    return undefined;
  }

  onNext() {
    logging.info('SCORMBaseView - onNext');

    return this.completeScorm(false);
  }

  onSkip() {
    logging.info('SCORMBaseView - onSkip (User clicked SKIP SCORM.)');

    this._saveScormProgress(() => {
      this.completeScorm(true);
    });
  }

  _saveScormProgress(callback = () => {}) {
    logging.info('SCORMBaseView - _saveScormProgress');

    const completeProcessingCb = () => {
      logging.info('SCORMBaseView - completeProcessing');
      this.nextDisabled = false;
      callback();
    };

    this.nextDisabled = true;
    return this.scorm != null ? this.scorm.completeProcessing(completeProcessingCb) : completeProcessingCb();
  }

  completeScorm(skipped = false) {
    logging.info(`SCORMBaseView - completeScorm ${ skipped } ${ this.activity.id } ${ this.nextDisabled }`);

    const isScoComplete = this.areAllSubModulesComplete();

    if (this.nextDisabled && !isScoComplete) {
      return;
    }
    this.nextDisabled = true;

    if (isScoComplete) {
      window.apps.auth.session.user.addPoints(this.activity.get('points'));
    }

    // Skipping an "ongoing" scorm needs to update the activity and set
    // `status` as `SKIPPED`
    if (skipped && !isScoComplete) {
      this.skipActivity();
    } else {
      this.complete();
    }
  }

  skipActivity() {
    logging.info('SCORMBaseView - skipActivity');

    this.activity.skipActivity().done(() => {
      logging.info('SCORMBaseView - scormSkip success');
      this.complete();
    })
      .fail(() => {
        logging.error('SCORMBaseView - scormSkip error');
      });
  }

  onClose() {
    logging.info('SCORMBaseView - onClose');

    this.actionBar?.setActionButton(null);

    // unbind event listeners
    $(document).off('keypress', this.keyPress);

    super.onClose();

    logging.info('SCORMBaseView - onClose Complete');
  }

  _createSCORM() {
    const scormConfig = this.getScormConfig();

    logging.info(`SCORMBaseView - render | scormModuleId:${ scormConfig.scormModuleId } | scormScoId: ${ scormConfig.scormScoId } | lastModify: ${ scormConfig.lastModify }`);

    try {
      this.scorm = new SCORMRTE(scormConfig);
      this.scorm.updateScoIfComplete();
    } catch (error) {
      this.handleScormError(error, 'There was an exception creating the SCORM.');
    }
  }

  getScormConfig() {
    logging.info('SCORMBaseView - getScormConfig');

    const scormPackage = this.activity.getScormPackage();
    const manifestURL = scormPackage.manifestDownloadUrl;

    return {
      manifestURL,
      scormModuleId: this.activity.get('body').id,
      student: {
        id: window.apps.auth.session.user.get('employeeId'),
        name: window.apps.auth.session.user.get('salutationName')
      },
      // onComplete is called when the module sends a completion status
      onComplete: this.onComplete.bind(this)
    };
  }

  onComplete() {
    logging.info('SCORMBaseView - onComplete');

    this._saveScormProgress();
    if (this.areAllSubModulesComplete()) {
      // Show next button
      this.actionBar.setActionButton(this.continueButtonFactory(this.onNext));
      this.triggerAdjustment();
    }
  }

  handleScormError(error, logMessage) {
    logging.info(`SCORMBaseView - handleScormError (${ logMessage })`);

    window.app.layout.hideSpinner();
    logging.error(error);
    sendWarnLog({
      logData: {
        logMessage,
        error
      }
    });
    this.isErrorLoadingModule = true;
    this.setSubviewIn(new SCORMErrorView(), {
      regionSelector: this.getErrorRegionSelector(),
      transition: View.Transitions.NONE
    });
  }
}

module.exports = SCORMBaseView;
