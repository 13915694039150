const I18n = require('@common/libs/I18n');

const SupportHelper = {
  getSupportHtml(helpEmailValue, helpPhoneValue) {
    let helpEmail, helpPhone = null;

    if (helpEmailValue) {
      helpEmail = `<span class="contact__email"><a href="mailto:${ helpEmailValue }">${ helpEmailValue }</a></span>`;
    }

    if (helpPhoneValue) {
      helpPhone = `<span class="contact__phone">${ helpPhoneValue }</span>`;
    }

    if (helpEmail && helpPhone) {
      return I18n.t('login.helpEmailAndPhone', {
        helpEmail,
        helpPhone
      });
    } else if (helpEmail || helpPhone) {
      return I18n.t('login.helpEmailOrPhone', { helpEmailOrPhone: (helpEmail || helpPhone) });
    }
    return '';
  }
};

module.exports = SupportHelper;
