const Backbone = require('Backbone');

const I18n = require('@common/libs/I18n');

class CustomerApiKey extends Backbone.Model {
  get validators() {
    return {
      type: 'required',
      name: ['required', (value) => {
        return $.trim(value).length < 1 ? I18n.t('communities.apiKeys.emptyName') : undefined;
      }]
    };
  }

  get apiEndpoint() {
    return 'customerKey';
  }

  save(attrs, options = {}) {
    const model = this.toJSON();
    options.attrs = {
      communityId: model.communityId,
      name: model.name,
      user: {id: model.user.id},
      type: 'custom',
      apiKey: model.apiKey
    };
    return super.save(attrs, options);
  }
}

module.exports = CustomerApiKey;
