const _ = require('underscore');
const Backbone = require('Backbone');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const ArticlePublishingTableView = require('@training/apps/articles/publishAll/ArticlePublishingTableView');
const ArticlePublishingItemView = require('@training/apps/articles/publishAll/ArticlePublishingItemView');
const Page = require('@common/components/discover/models/Page');
const PageFactory = require('@common/components/discover/factories/PageFactory');
const async = require('async');
const logging = require('logging');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const DateHelpers = require('@common/libs/dateHelpers');

class ArticlePublishAllModal extends Marionette.LayoutView {
  initialize(options = {}) {
    ({
      languageData: this.languageData,
      saveCurrentPage: this.saveCurrentPage
    } = options);

    this.articlesByLanguage = new Backbone.Collection([], {
      comparator: (model) => {
        return model.get('languageName');
      }
    });
    this.listenTo(this.articlesByLanguage, 'change add', () => {
      if (this.articlesByLanguage.some((article) => {
        return article.get('checked');
      })) {
        this.actionBar.enableButton('customText');
      } else {
        this.actionBar.disableButton('customText');
      }
    });

    this.processing = false;
  }

  getTemplate() {
    return require('@training/apps/articles/modals/ArticlePublishAllModal.html');
  }

  className() {
    return 'page-wrapper parent-height';
  }

  regions() {
    return {
      articleTableRegion: '.languages-table'
    };
  }

  onRender() {
    const buttons = [
      {
        type: 'cancel',
        className: 'white',
        onClick: this.destroy.bind(this)
      },
      {
        type: 'customText',
        text: I18n.t('general.publish'),
        onClick: this._publishAll.bind(this)
      }
    ];

    this.actionBar.setActionBar({ buttons });

    const articleTable = new ArticlePublishingTableView({
      headerNames: [
        I18n.t('general.language'),
        I18n.t('observations.details.labels.status')
      ],
      rowItemView: ArticlePublishingItemView,
      collection: this.articlesByLanguage
    });

    this.articleTableRegion.show(articleTable);
  }

  viewDidAppear() {
    if (!this.languageData.get('bundleId')) {
      this._showAvailableLanguageVariants();
      return;
    }

    this.languageData.fetch({
      success: () => {
        this._showAvailableLanguageVariants();
      },
      error: (model, xhr) => {
        this._onLanguageDataFetchError(xhr);
      }
    });
  }

  _getLanguageI18nKey(languageCode) {
    return 'languages.' + languageCode.toUpperCase().replace(/-|_/g, '');
  }

  _showAvailableLanguageVariants() {
    _.each(this.languageData.keys(), (languageCode) => {
      let datum = this.languageData.get(languageCode);
      if (languageCode === this.model.get('language')) {
        if (!datum) {
          datum = { pageStatus: 'INPROGRESS' };
        }
        if (this.model.hasChanged() || this.model.get('isDirty') || this.model.currentVersion.richContent.get('isDirty')) {
          datum.pageStatus = 'MODIFIED';
        }
        datum.lockingUser = null;
      } else if (languageCode === 'bundleId' || !datum) {
        return;
      }

      let checked = !datum.publishTimestamp || datum.publishTimestamp <= DateHelpers.getTime();
      if (datum.lockingUser) {
        checked = null;
      }

      this.articlesByLanguage.add(_.extend({
        checked,
        language: languageCode,
        languageName: I18n.t(this._getLanguageI18nKey(languageCode)),
        processing: false
      }, datum));
    });
  }

  _onLanguageDataFetchError(xhr) {
    xhr.skipGlobalHandler = true;

    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    logging.error(exception.getErrorMessage());

    window.app.layout.flash.error(I18n.t('errors.genericError'));
  }

  _publishArticle(article, callback) {
    const language = article.get('language');

    const runOnPage = (page, navigateToEdit) => {
      page.set('markFact', true);
      page.currentVersion.set('publishTimestamp', null);
      return page.save({}, {
        success: () => {
          this._updateStatus({
            language,
            success: true
          });
          if (!this.languageData.get('bundleId')) {
            this.languageData.set('bundleId', page.get('bundleId'));
          }
          if (navigateToEdit) {
            Backbone.history.navigate(`hub/search/article/${ page.get('id') }/edit`);
          }
        },
        error: (model, xhr) => {
          this._onPublishFailed(language, xhr);
        }
      }).always(() => {
        callback();
      });
    };

    if (this.model.get('language') === language) {
      this.saveCurrentPage(() => {
        return runOnPage(this.model, this.model.isNew());
      }, (errors) => {
        this._updateStatus({
          language,
          success: false,
          errors
        });
        callback();
      });
      return;
    }

    const page = new Page({id: article.get('pageId')});
    page.fetch({
      success: () => {
        const pageFactory = new PageFactory();
        const typedPage = pageFactory.getTypedPage(page);
        typedPage.currentVersion.unset('id');
        typedPage.currentVersion.richContent.unset('id');
        runOnPage(typedPage);
      },
      error: (model, xhr) => {
        this._onPublishFailed(language, xhr);
        callback();
      }
    });
  }

  _onPublishFailed(language, xhr) {
    xhr.skipGlobalHandler = true;

    this._updateStatus({
      language,
      success: false
    });
  }

  _updateStatus(options = {}) {
    if (this.isDestroyed) {
      return;
    }

    let statusText = I18n.t('discover.publishFlow.publishError');
    if (options.success) {
      this.successfullyPublished++;
      this.$('.disclaimer').text(I18n.t('discover.publishFlow.successfullyPublished', {
        successful: this.successfullyPublished,
        total: this.articlesByLanguage.length
      }));

      statusText = I18n.t('discover.publishFlow.publishState.published');
    } else if (options.errors) {
      statusText = I18n.t('discover.publishFlow.publishState.notPublishedWithReason', {
        notPublishedReason: _.compact(options.errors).join(' ')
      });
    }

    this.$('.js-article-status-' + options.language).text(statusText);
  }

  _publishAll() {
    if (this.processing) {
      return;
    }
    this.processing = true;

    this.stopListening(this.articlesByLanguage, 'change');

    this.actionBar.setActionBar({ buttons: [{
      type: 'customText',
      text: I18n.t('discover.publishFlow.viewArticle'),
      disabled: true,
      onClick: this._viewArticle.bind(this)
    }]});
    this.actionBar.disableButton('customText');

    const articlesToPublish = this.articlesByLanguage.filter({checked: true});

    articlesToPublish.forEach((article) => {
      article.set('processing', true);
    });
    this.articlesByLanguage.reset(articlesToPublish);

    this.successfullyPublished = 0;
    this.$('.disclaimer').text(I18n.t('discover.publishFlow.successfullyPublished', {
      successful: this.successfullyPublished,
      total: this.articlesByLanguage.length
    }));

    async.forEach(articlesToPublish, this._publishArticle.bind(this), () => {
      if (!this.isDestroyed) {
        this.actionBar.enableButton('customText');
      }

      if (this.languageData.get('bundleId')) {
        this.languageData.fetch({
          error: (model, xhr) => {
            this._onLanguageDataFetchError(xhr);
          }
        });
      }
    });
  }

  _viewArticle() {
    if (this.model.isNew()) {
      this.destroy(); //closes modal and returns to edit mode
    } else {
      Backbone.history.navigate(`hub/search/article/${ this.model.get('id') }`, true);
    }
  }
}

module.exports = ArticlePublishAllModal;
