const { Model } = require('Backbone');

const API_FIELDS = {
  name: 'name'
};

class TagGroupListItemModel extends Model {

  getName() {
    return this.get(API_FIELDS.name);
  }

  setName(name) {
    this.set(API_FIELDS.name, name);
  }

  toOption() {
    return {
      value: this.getName()
    };
  }
}

module.exports = TagGroupListItemModel;
