const { map } = require('underscore');
const { Collection } = require('Backbone');
const Activity = require('@common/data/models/Activity');

class ActivityList extends Collection {

  model(attrs, options) {
    return new Activity(attrs, options);
  }

  parse(res = {}) {
    return res.entities || res;
  }

  comparator(activity) {
    if (activity.isTrainingContentActivity()) {
      return 1;
    } else if (activity.isQuestionContentActivity()) {
      return 2;
    }
  }

  // Finds the first activity that is UNDERWAY and passes the optional truth test
  nextActivity(truthTest = () => {
    return true;
  }) {
    return this.find((activity) => {
      return activity.isUnderway() && truthTest(activity);
    });
  }

  getInProgressActivity() {
    return this.find((activity) => {
      return activity.isInProgress();
    });
  }

  markAllDeletedQuestionActivitesAsSkipped() {
    const activities = this.filter((activity) => {
      return activity.isQuestionContentActivity() && activity.isBypassable() && !activity.isQuestionActivityActive();
    });

    return $.when.apply(map(activities, (activity) => {
      return activity.skipActivity();
    }));
  }

  // Finds first activity of this type
  findActivityByType(type) {
    return this.find((activity) => {
      return activity.get('type') === type;
    });
  }

  getNextTrainingModule() {
    return this.nextActivity((activity) => {
      return activity.isTrainingContentActivity();
    });
  }

  getTotalModulesActivityCount() {
    return this.filter((activity) => {
      return activity.isTrainingContentActivity();
    }).length;
  }

  getClosedModulesActivityCount() {
    return this.filter((activity) => {
      return activity.isTrainingContentActivity() && activity.isClosed();
    }).length;
  }

  getNextQuestion() {
    // Search for an available activity
    return this.nextActivity((activity) => {
      return activity.isQuestionContentActivity();
    });
  }

  stopInProgressCompletedActivityTimer() {
    this.chain()
      .filter((activity) => {
        return activity.isInProgress() && activity.isComplete();
      })
      .each((activity) => {
        return activity.stopTimer();
      });
  }

  numberOfQuestionsUnderway() {
    return this.filter((activity) => {
      return activity.isQuestionContentActivity() && activity.isUnderway();
    }).length;
  }

  numberOfUnderwayActivities() {
    return this.filter((activity) => {
      return activity.isUnderway();
    }).length;
  }

  getTotalQuizActivityCount() {
    return this.filter((activity) => {
      return activity.isQuestionContentActivity();
    }).length;
  }

  getClosedQuizActivityCount() {
    return this.filter((activity) => {
      return activity.isQuestionContentActivity() && activity.isClosed();
    }).length;
  }

}

module.exports = ActivityList;
