const _ = require('underscore');
const Backbone = require('Backbone');
const logging = require('logging');
const { sum } = require('lodash');

/**
 * The API for timeline/update is a bit different than what you'd usually encounter.
 * I'm writing up some notes for reference here.
 *
 * /timeline/update is a notifier endpoint for the timeline. it exposes two methods,
 * GET and PUT. Neither call requires arguments.
 *
 * GET - returns a bare boolean indicating whether there have been updates to the
 *       timeline since the user last visited it.
 *
 * PUT - returns a 204 No Content. This call tells the server the user arrived on the
 *       Timeline page and has seen the updates.
 *
 * This is very rudimentary and we plan to learn more about what our customers need,
 * and feed that into a better-designed notification system.
 *
 * Now that you understand the API, the purpose of this class is clearer. The only
 * state we need to track is whether to show or hide a `hidden` class in the UI.
 *
 */
class TimelineNotifierModel extends Backbone.Model {
  apiEndpoint() {
    return 'timeline/update';
  }

  parse(resp = {}) {
    const response = (typeof resp === 'string') ? JSON.parse(resp) : resp;
    if (_.isEmpty(response)) {
      return {
        priorityUpdateCounts: {},
        aggregatePriorityCount: 0,
        allMessagesUpdateCount: 0,
        availablePoints: 0,
        hasUpdates: false
      };
    }

    const aggregatePriorityUpdateCount = sum(Object.values(response.priorityUpdateCounts ?? {}));
    return {
      ...response,
      aggregatePriorityCount: aggregatePriorityUpdateCount,
      hasUpdates: (aggregatePriorityUpdateCount > 0 || response.allMessagesUpdateCount > 0)
    };
  }

  fetch(options = {}) {
    const opts = _.extend({
      data: {
        pageStatus: 'fact'
      }
    }, options);

    return super.fetch(opts);
  }

  save(attributes = {}, options = {}) {
    const opts = _.extend({
      type: 'PUT',
      dataType: 'text'
    }, options);

    return super.save(attributes, opts);
  }

  sync(method, model, options = {}) {
    options.error = _.wrap(options.error, (originalOptionError = () => {}, xhr, status, error, ...args) => {
      this.onServerError(xhr);
      originalOptionError(xhr, status, error, ...args);
    });

    return super.sync(method, model, options);
  }

  onServerError(xhr) {
    if (xhr.status === 500) {
      xhr.skipGlobalHandler = true;
      logging.devWarn(`Error fetching Timeline Notification decorator. Try connecting to the VPN and reloading the page. If you're already connected, try disconnecting.`);
    }
  }
}

module.exports = TimelineNotifierModel;
