const LoadingController = require('@common/components/loading/LoadingController');

const { getMessagesCardControllerDefinition } = require('@training/apps/home/hubPreviews/messages/MessagesCardControllerDefinitionFactory');

module.exports = (messageCollection, callback) => {
  return () => {
    messageCollection.fetch({
      data: {
        p: 0,
        rpp: 3
      },
      showSpinner: false
    });

    // Load the config object for this particular ToDo card
    const contentControllerDefinition = getMessagesCardControllerDefinition({
      messageCollection,
      callback
    });

    return {
      ViewControllerClass: LoadingController.Wrapper,
      syncTracker: {
        entities: messageCollection,
        loadOnSync: true
      },
      viewDefinition: {
        spinner: false
      },
      contentControllerDefinition
    };
  };
};
