// Module
var code = `<div class="game-scores-details">

  <% if(gameDetails.length == 0) { %>
    <div class="nooneplayed">
      <span><%- t('gamescores.nooneplayed') %></div>
    </div>
  <% } else { %>
  <div class="highscores clearfix">
    <div class="yourhighscore">
    <% if( (typeof additionalDetails !== "undefined") ) { %>
      <div class="score"><%- numberWithCommas(additionalDetails.wins) %></div>
      <div class="scorelabel ax-font--secondary"><%- t('gamescores.team_wins') %></div>
    <% } else { %>
      <div class="notplayed"><div class="score">--</div> <div class="scorelabel ax-font--secondary"><%- t('gamescores.youhavenotplayed') %></div></div>
    <% } %>
    </div>
    <div class="alltimehighscore">
      <div class="scorecontainer">
      <% if(((typeof additionalDetails !== "undefined"))) { %>

      <div class="score"><%- numberWithCommas(additionalDetails.losses) %></div>
        <div class="scorelabel ax-font--secondary">
          <%- t('gamescores.team_losses') %>
        </div>

    <% } else { %>
      <div class="notplayed"><div class="score">--</div> <div class="scorelabel ax-font--secondary"><%- t('gamescores.youhavenotplayed') %></div></div>
    <% } %>
      </div>
    </div>
  </div>

  <div class="gameboard users">
    <table>

      <tr>
        <th class="rank center"><%- t('gamescores.rank') %></th>
        <th class="team text-align-default-dir"></th>
        <th class="score center"><%- t('gamescores.wins') %></th>
        <th class="score center"><%- t('gamescores.losses') %></th>
      </tr>

      <% if(gameDetails.length < 1) { %>
        <tr>
          <td colspan="3" class="nooneplayed"><%- t('gamescores.nooneplayed') %></td>
        </tr>
      <% } else { %>
        <% var previousRank = 0; %>
        <% for(var i = 0; i < gameDetails.length; i++) { %>
          <% var item = gameDetails[i];
             var youClass = item.current ? 'you' : '';
             var separatorClass = previousRank != item.rank ? 'separator' : ''; %>

          <tr class="<%- youClass + ' ' + separatorClass %>">

            <% previousRank = gameDetails[i].rank + 1; %>

            <td class="rank center cell-<%- item.rank + 1 %>" >
              <div class="position-<%- item.rank + 1 %>"></div>
              <span><%- item.rank + 1%></span>
            </td>
            <td class="user text-ellipsis" ><%- item.name %>
              <% if (item.current) { %>
              (<%- t('stats.you') %>)
              <% } %>
            </td>
            <td class="score center" ><%- numberWithCommas(item.data.wins) %></td>
            <td class="score center" ><%- numberWithCommas(item.data.losses) %></td>
          </tr>
        <% } %>
      <% } %>

    </table>
  </div>

  <% } %>
</div>
`;
// Exports
module.exports = code;