const {
  CompositeView,
  ItemView
} = require('Marionette');

require('@common/components/tableLayout/TableLayout.less');

class TableLayoutView extends CompositeView {
  initialize(options = {}) {
    ({
      collection: this.collection,
      headerNames: this.headerNames = [],
      headerClasses: this.headerClasses = null,
      emptyViewClass: this.emptyViewClass = EmptyRowView,
      emptyViewOptions: this.emptyViewOptions,
      noHeaderSpan: this.noHeaderSpan = null,
      rowItemView: this.rowItemView
    } = options);
  }

  className() {
    return 'js-table-layout table-layout';
  }

  tagName() {
    return 'table';
  }

  childViewContainer() {
    return 'tbody';
  }

  getChildView() {
    return this.rowItemView;
  }

  templateHelpers() {
    return {
      collection: this.collection,
      headerNames: this.headerNames,
      headerClasses: this.headerClasses
    };
  }

  getTemplate() {
    let headerNodeTemplateString;
    if (this.headerClasses) {
      headerNodeTemplateString = this.noHeaderSpan
        ? `<th <%= headerClasses ? 'class="' + headerClasses[i] + '"' : ''%>><%- headerNames[i] %></th>`
        : `<th><span <%= headerClasses ? 'class="' + headerClasses[i] + '"' : ''%>><%- headerNames[i] %></span></th>`;
    } else {
      headerNodeTemplateString = this.noHeaderSpan
        ? `<th><%- headerNames[i] %></th>`
        : `<th><span><%- headerNames[i] %></span></th>`;
    }
    return `
      <thead>
        <tr>
        <% for (i = 0; i < headerNames.length; i++) { %>
          ${ headerNodeTemplateString }
        <% } %>
        </tr>
      </thead>
    <tbody></tbody>
    `;
  }

  getEmptyView() {
    return this.emptyViewClass;
  }
}

class EmptyRowView extends ItemView {
  get tagName() {
    return 'tr';
  }

  getTemplate() {
    return `
      <td colspan="999" class="empty-view"><%- t('general.empty') %></td> `;
  }
}

module.exports = TableLayoutView;

