const I18n = require('@common/libs/I18n');
const Page = require('@common/components/discover/models/Page');

class Article extends Page {
  apiEndpoint() {
    return '/articles';
  }

  get className() {
    return 'Article';
  }

  defaults() {
    return Object.assign(super.defaults(), {
      title: ''
    });
  }

  validators() {
    return {
      title: ['required', function(value) {
        if (value.length > Article.MAX_TITLE_LENGTH) {
          return I18n.t('discover.errors.Article.title.length', {maxTitleLength: Article.MAX_TITLE_LENGTH});
        }
      }
      ],
      language: 'required',
      community: 'required'
    };
  }
}

module.exports = Article;
