const { Behavior } = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');

Behaviors.PressedHoverButtonBehavior = class PressedHoverButtonBehavior extends Behavior {

  defaults() {
    return {
      buttonTarget: ''
    };
  }

  events() {
    const buttonTarget = this.getOption('buttonTarget');

    return {
      [`touchstart ${ buttonTarget }`]: 'onTouchStart',
      [`touchend ${ buttonTarget }`]: 'onTouchEnd',
      [`touchcancel ${ buttonTarget }`]: 'onTouchCancel',
      [`mouseenter ${ buttonTarget }`]: 'onMouseEnter',
      [`mouseleave ${ buttonTarget }`]: 'onMouseLeave',
      [`mousedown ${ buttonTarget }`]: 'onMouseDown',
      [`click ${ buttonTarget }`]: 'onClick'
    };
  }

  initialize() {
    this.touchEvent = false;
  }

  onMouseEnter(e) {
    if (!this.touchEvent) {
      this._toggleHover(e, true);
    }
  }

  onMouseLeave(e) {
    if (!this.touchEvent) {
      this._toggleHover(e, false);
      this._toggleActive(e, false);
    }
  }

  onMouseDown(e) {
    if (!this.touchEvent) {
      this._toggleActive(e, true);
    }
  }

  onClick(e) {
    if (!this.touchEvent) {
      this._toggleActive(e, false);
    }

    this.touchEvent = false;
  }

  onTouchStart(e) {
    this.touchEvent = true;
    this._toggleActive(e, true);
  }

  onTouchEnd(e) {
    this._toggleActive(e, false);
  }

  onTouchCancel(e) {
    e.preventDefault();
    this.touchEvent = false;
    this._toggleActive(e, false);
  }

  _toggleHover(e, toggle = false) {
    $(e.currentTarget).toggleClass('hover', toggle);
  }

  _toggleActive(e, toggle = false) {
    $(e.currentTarget).toggleClass('active', toggle);
  }
};

module.exports = Behaviors.PressedHoverButtonBehavior;
