const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

require('@common/components/training_modules/PreviewUnavailableView.less');

class PreviewUnavailableView extends Marionette.ItemView {
  className() {
    return 'preview-unavailable';
  }

  getTemplate() {
    return `\
    <div class="row empty-text">
      <div class="col-xs-12 text-center padall">
        <span class="text-muted margin-top-med"><%- message %></span>
      </div>
    </div>\
    `;
  }

  templateHelpers() {
    return {
      message: this.getOption('message') || I18n.t('content.module.previewUnavailable')
    };
  }
}

module.exports = PreviewUnavailableView;
