const _ = require('underscore');
const logging = require('logging');

// Abstract class for uploading files
class UploadHandler {

  constructor(options = {}) {
    this.queue = [];
    this.params = []; // params for files in queue

    ({
      maxConnections: this.maxConnections,
      url: this.url,
      fileInputName: this.fileInputName,
      fileType: this.fileType,
      onUpload: this.onUpload,
      onCancel: this.onCancel,
      onError: this.onError,
      onComplete: this.onComplete,
      onProgress: this.onProgress
    } = options);
  }

  // Add file to the queue
  // @returns id
  add() {
    const error = new Error('Method not implemented');
    logging.error(error);
    throw error;
  }

  // Send the file to the server
  upload(id, params) {
    const len = this.queue.push(id);
    this.params[id] = _.clone(params);

    // If too many active uploads, wait...
    if (len <= this.maxConnections) {
      this._upload(id, this.params[id]);
    }
  }

  // Cancel file upload by id
  cancel(id) {
    this._cancel(id);
    this._dequeue(id);
  }

  // Cancel all uploads
  cancelAll() {
    for (const id of this.queue) {
      this._cancel(id);
    }
    this.queue = [];
  }

  // Return the name of file identified by id
  getName() {
    const error = new Error('Method not implemented');
    logging.error(error);
    throw error;
  }

  // Return the size of file identified by id
  getSize() {
    const error = new Error('Method not implemented');
    logging.error(error);
    throw error;
  }

  // Return id of files being uploaded or waiting for their turn
  getQueue() {
    return this.queue;
  }

  // Actual upload method
  _upload() {
    const error = new Error('Method not implemented');
    logging.error(error);
    throw error;
  }

  // Actual cancel method
  _cancel() {
    const error = new Error('Method not implemented');
    logging.error(error);
    throw error;
  }

  // Remove element from queue, starts upload of next
  _dequeue(id) {
    const i = this.queue.indexOf(id);
    this.queue.splice(i, i - i + 1, ...[].concat([]));

    const max = this.maxConnections;
    if ((this.queue.length >= max) && (i < max)) {
      const nextId = this.queue[max - 1];
      this._upload(nextId, this.params[nextId]);
    }
  }
}

module.exports = UploadHandler;
