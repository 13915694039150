const Marionette = require('Marionette');

require('@common/components/training_modules/external_link/ExternalLinkPreviewView.less');

class ExternalLinkPreviewView extends Marionette.ItemView {
  getTemplate() {
    return require('./ExternalLinkPreviewView.html');
  }

  initialize(options) {
    this.language = options.language;
  }

  templateHelpers() {
    const pkg = this.model.moduleLinks.getPackageForLanguage(this.language);
    const translatedDesc = this.model.get('translationDesc');
    return $.extend({}, pkg.get('externalLinkPackage'), {
      nameString: this.model.getPrimaryTranslatableFieldForLanguage(this.language),
      descString: (translatedDesc != null) ? translatedDesc.getValueForLanguage(this.language, false) : ''
    });
  }
}

module.exports = ExternalLinkPreviewView;
