const Backbone = require('Backbone');
const logging = require('logging');
const _ = require('underscore');

const AssessmentType = require('@common/data/enums/AssessmentType');
const AssessmentModelFactory = require('@common/data/models/assessments/AssessmentModelFactory');
const AssessmentResult = require('@common/data/models/assessments/AssessmentResult');

const { getModelForSessionType } = require('@common/data/models/assessments/AssessmentOptionFactory');

const AssessmentOptionSearchCriteriaFactory = require('@common/data/models/assessments/AssessmentOptionSearchCriteriaFactory');

class CurrentTrainingSession extends Backbone.Model {
  apiEndpoint() {
    return '/sessions';
  }

  defaults() {
    return {
      currentAssessment: null,
      inProgressAssessments: [],
      currentAssessmentOptions: []
    };
  }

  constructor(attrs, options) {
    super(attrs, options);

    this._onCurrentAssessmentChange = this._onCurrentAssessmentChange.bind(this);
  }

  preinitialize() {
    this._initCurrentAssessment();
    this._initInProgressAssessments();
    this._initCurrentAssessmentOptionsLists();
  }

  _initCurrentAssessment() {
    this.setCurrentAssessment();

    // Make sure the child models/collections get updated when they get updated
    // in the session
    this.on('change:currentAssessment', (sessionModel, currentAssessment) => {
      if (currentAssessment == null) {
        this.setCurrentAssessment();

      } else if (this.hasCurrentAssessment() && currentAssessment && currentAssessment.id === this.currentAssessment.id) {
        this.currentAssessment.clear({ silent: true });
        this.currentAssessment.set(currentAssessment);

        // Only set the currentAssessment to the session if it's legit (ie. exists on the server)
      } else if (currentAssessment && currentAssessment.id != null) {
        const assessment = AssessmentModelFactory.create(currentAssessment.type, currentAssessment, { parse: true });
        this.setCurrentAssessment(assessment);
      }
    });
  }

  _initCurrentAssessmentOptionsLists() {
    this._currentAssessmentOptionsLists = new Backbone.Collection(
      [],
      {
        comparator: false
      }
    );

    this.listenTo(this, 'change:currentAssessmentOptions', (model, values) => {
      this._parseAssessmentOptionsLists(values);
    });
  }

  _initAssessmentOptionsList(values, sessionType) {
    // Need this to be a collection of current assessments, with a TopicOption sub-model
    const tmpCollection = new Backbone.Collection(values || [], {
      model: (attrs = {}, options) => {
        const nextItem = getModelForSessionType(sessionType, attrs);

        if (nextItem != null) {
          this.extendWithAssessmentGetter(nextItem.attributes, AssessmentOptionSearchCriteriaFactory.createCriteria(nextItem));
          nextItem.set('sessionType', sessionType);
        }

        const modAttrs = Object.assign({}, attrs, { nextItem });

        return new Backbone.Model(modAttrs, options);
      }
    });
    this.proxySyncMutatorMethods(tmpCollection);
    return tmpCollection;
  }

  _parseAssessmentOptionsLists(optionsListsArray = []) {
    // If no array values were provided - create/store a default options array with an empty collection based on the current session type
    if (optionsListsArray.length === 0) {
      const sessionType = this.getTrainingSessionType();
      const tmpCollection = this._initAssessmentOptionsList([], sessionType);
      this._currentAssessmentOptionsLists.reset([{
        type: sessionType,
        values: tmpCollection
      }]);
      return;
    }

    // Otherwise, if we do have array values - parse them
    const parsedListArray = [];
    // Sort the array
    optionsListsArray.sort((a, b) => {
      try {
        return a.precedence - b.precedence;
      } catch (e) {
        return 0;
      }
    });

    _.each(optionsListsArray, (list) => {
      const tmpCollection = this._initAssessmentOptionsList(list.values, list.type);
      parsedListArray.push({
        type: list.type,
        values: tmpCollection
      });
    }, this);
    this._currentAssessmentOptionsLists.reset(parsedListArray);
  }

  _initInProgressAssessments() {
    this._inProgressAssessments = new Backbone.Collection([], {
      model: (attrs = {}, options) => {
        return AssessmentModelFactory.createFromJson(attrs, Object.assign({}, options, { silent: false }));
      }
    });

    this.listenTo(this._inProgressAssessments, 'change', () => {
      this.set('inProgressAssessments', this._inProgressAssessments.toJSON());
    });

    this.listenTo(this, 'change:inProgressAssessments', (model, values, options = {}) => {
      this._inProgressAssessments.reset(values, options);
    });
  }

  pauseAssessment() {
    // Removing the payload as it trips the XSS, but keeping the save action to trigger correct events
    return this.save({}, {
      data: '',
      type: 'POST',
      url: `${ this.url() }/unsetCurrentAssessment`
    });
  }

  setCurrentAssessment(currentAssessment = AssessmentModelFactory.create()) {
    const oldAssessment = this.currentAssessment;

    try {
      currentAssessment.on('change', this._onCurrentAssessmentChange);

      this.currentAssessment = currentAssessment;

      if (oldAssessment) {
        oldAssessment.off('change', this._onCurrentAssessmentChange);
      }
    } catch (error) {
      logging.error('Can\'t set current assessment in setCurrentAssessment');
    }
  }

  extendWithAssessmentGetter(obj = {}, searchCriteria) {
    if (searchCriteria != null) {
      Object.assign(obj, {
        inProgressAssessmentGetter: () => {
          return this.getInProgressAssessment(searchCriteria);
        }
      });
    }
  }

  _onCurrentAssessmentChange(assessmentModel) {
    this.set({ currentAssessment: assessmentModel.toJSON() });
  }

  parse(res) {
    return res.session;
  }

  sendBadgeUpdate(options = {}) {
    if (this.isNew()) {
      logging.error('Can\'t send a badge update, a session with id doesn\'t exist');
      return;
    }

    // this API call is not critical so an error on this API endpoint should not
    // affect the user's experience, there skip the global handler by default
    $.ajax(_.extend({ skipGlobalHandler: true }, options, {
      type: 'GET',
      url: `${ this.url() }/badge`
    }));
  }

  isMinDailyTrainingFulfilled() {
    // If the property doesn't exist, there's no requirement to fulfill (nothing in user's target)
    if (!this.has('minDailyTrainingFulfilled')) {
      return true;
    }
    return this.get('minDailyTrainingFulfilled');
  }

  getCreateDate() {
    return this.get('createDate');
  }

  getSessionExpirationThresholdInHours() {
    return 24;
  }

  isExpired() {
    const serverTimeNow = new Date().getTime() + window.apps.auth.session.serverTimeOffset;
    return this.get('expiryDate') < serverTimeNow;
  }

  skipTraining() {
    return this.save({}, {
      url: this.urlRoot() + '/skipTraining',
      type: 'PUT'
    });
  }

  getTrainingSessionType() {
    return this.get('sessionTrainingType');
  }

  hasCompletedSomeAssessmentForTopic(topicId) {
    return _.any(this.get('assessments'), (assessment) => {
      return assessment.topicId === topicId && assessment.isComplete;
    });
  }

  hasCompletedSomeAssessmentWithId(assessmentId) {
    return _.any(this.get('assessments'), (assessment) => {
      return assessment.id === assessmentId && assessment.isComplete;
    });
  }

  hasCompletedSomeAssessment() {
    return _.any(this.get('assessments'), (assessment) => {
      return assessment.isComplete;
    });
  }

  hasCompletedAssessmentOfType(assessmentType) {
    return _.any(this.get('assessments'), (assessment) => {
      return assessment.type === assessmentType && assessment.isComplete;
    });
  }

  getCompletedAssessmentsCountOfType(assessmentType) {
    const assessments = _.filter(
      this.get('assessments'),
      (assessment) => {
        return assessment.isComplete && [assessmentType].includes(assessment.type);
      }
    );

    return assessments.length;
  }

  hasExtraQuestionsAssessmentsForTopicIdLevel(topicId, level) {
    const extraQuestionsAssessment = _.find(
      this.get('assessments'),
      (assessment) => {
        // SE-1986: Doing an Intro or Certification assessment on a topicId & level
        // excludes this topic/level from Extra Training in the same session.
        return assessment.isComplete
        && assessment.topicId === topicId
        && assessment.level === level
        && [
          AssessmentType.ExtraTraining,
          AssessmentType.IntroductoryTraining,
          AssessmentType.CertificationTraining,
          AssessmentType.RefresherTraining
        ].includes(assessment.type);
      }
    );

    return extraQuestionsAssessment != null;
  }

  getInProgressAssessment(findCriteria = {}) {
    return this._inProgressAssessments.findWhere(findCriteria);
  }

  hasCurrentAssessment() {
    return !this.currentAssessment.isNew();
  }

  getCurrentAssessment() {
    return this.currentAssessment;
  }

  getCurrentAssessmentOptionsList(listIndex) {
    // Return the options list at either the provided index or the default
    const optionsList = this._currentAssessmentOptionsLists.at(listIndex ?? 0);
    if (optionsList) {
      return optionsList.get('values');
    }
    // If there is no list - return an empty collection (methods calling this function are expecting a collection)
    return new Backbone.Collection();
  }

  getAssessmentOptionsLists() {
    return this._currentAssessmentOptionsLists;
  }

  getAssessmentOptionsListOfType(assessmentType) {
    return this._currentAssessmentOptionsLists.findWhere({ type: assessmentType })?.get('values');
  }

  getAllAssessmentResults() {
    return $.ajax({
      type: 'GET',
      apiEndpoint: `/sessions/${ this.id }/assessmentResults`
    }).then((response) => {
      const assessmentResults = response.assessmentResults.map((resultJson) => {
        return new AssessmentResult(resultJson, { parse: true });
      });

      return assessmentResults;
    });
  }
}

module.exports = CurrentTrainingSession;
