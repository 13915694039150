const { extend } = require('underscore');
const Marionette = require('Marionette');
const BroadcastMessage = require('@training/apps/training/models/BroadcastMessage');
const BroadcastMessagePage = require('@training/apps/training/views/BroadcastMessagePage');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
class MessageDetailsPage extends Marionette.LayoutView {
  getTemplate() {
    return '<div class="message-wrapper"></div>';
  }

  regions() {
    return {messageWrapper: '.message-wrapper'};
  }

  initialize(options) {
    ({
      messageId: this.messageId,
      language: this.language,
      isConsolidatedCommsEnabled: this.isConsolidatedCommsEnabled
    } = options);
  }

  onRender() {
    const languageForAPI = this.language.replace('_', '-');

    const messagePromise = $.ajax({
      type: 'GET',
      apiEndpoint: `/broadcastMessages/inbox/${ this.messageId }/${ languageForAPI }`
    });

    messagePromise.then((data) => {
      if (this.isDestroyed) {
        return;
      }
      this.prepareMessageView(data.entity);
    }, (xhr) => {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);

      if ([AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_VALID_ENTITY, AxonifyExceptionCode.CLIENT_ERROR_NO_BROADCAST_MESSAGE_PERMISSION].includes(exception.getErrorCode())) {
        xhr.skipGlobalHandler = true;
        Backbone.history.navigate('hub/feed/messages', {
          trigger: true,
          replace: true
        });
        window.app.layout.flash.error(I18n.t( 'news.messageNotFound' ));
      }
    });
  }

  onShow() {
    window.app.layout.showHeaderBackButton();
    triggerResize(true);
  }

  prepareMessageView(message) {
    const model = new BroadcastMessage(extend({}, message, {delivered: true}));

    const messageView = new BroadcastMessagePage({
      model,
      isFromMessageFeed: true,
      isConsolidatedCommsEnabled: this.isConsolidatedCommsEnabled
    });

    this.messageWrapper.show(messageView);
    triggerResize(true);
  }

  onDestroy() {
    window.app.layout.resetLeftHeaderView();
  }
}

module.exports = MessageDetailsPage;
