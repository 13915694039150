const { LayoutView } = require('Marionette');

class AverageTopicScore extends LayoutView {

  getTemplate() {
    if (this.model.isXHRPending()) {
      return false;
    } else if (this.model.isXHRDone() && this.model.get('userLocationScore') != null) {
      return `<div class="team-stats-region__average-score"><%= userLocationScore %><%= t('general.percent') %></div>
<p><%= t('stats.teampoints') %></p>`;
    }
    return `<div class="team-stats-region__average-score">--<%= t('general.percent') %></div>
<p><%= t('stats.teampoints') %></p>`;

  }

  className() {
    return 'average-topic-score';
  }

  modelEvents() {
    return {
      sync: 'render',
      error: 'render'
    };
  }
}

module.exports = AverageTopicScore;
