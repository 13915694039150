const _ = require('underscore');

const BaseLoadingController = require('@common/components/loading/BaseLoadingController');
const LoadingWrapperView = require('@common/components/loading/wrapper/LoadingWrapperView');

/*
  LoadingWrapperController is a LoadingController that wraps the content component whose data is loading and layers a
  semi transparent blanket on top. The blanket's opacity can be configured along with 3 styles of spinners:
  'small', 'large' or none at all. Besides indicating to the user that the component under it is stale/incomplete and is
  being fetched, the blanket also makes sure the user can't interact with the content component while loading.

  Enums:
    'Options': defines all valid options that can be passed in as constructor options including the BaseLoadingController.Options

      'CONTENT_CONTROLLER_DEFINITION': (View Controller Definition) - the content you want to show once all the data has
          loaded.
 */

const OptionKeys = BaseLoadingController.Options.extend({
  CONTENT_CONTROLLER_DEFINITION: 'contentControllerDefinition'
});

class LoadingWrapperController extends BaseLoadingController {

  static Options = OptionKeys;

  constructor(options = {}) {
    super(options);

    ({
      [OptionKeys.CONTENT_CONTROLLER_DEFINITION]: this._contentControllerDefinition
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: LoadingWrapperView
    };
  }

  regionControllers() {
    return {
      [LoadingWrapperView.CONTENT_REGION]: this._contentControllerDefinition
    };
  }

  syncTracker() {
    const contentController = this.findControllerByRegion(LoadingWrapperView.CONTENT_REGION);
    const contentView = contentController && contentController.getView();
    const entities = _.chain(contentView)
      .pick('model', 'collection')
      .toArray()
      .compact()
      .value();

    return {
      entities
    };
  }

  onAddRegionController(loadingController, manager, regionName, contentController) {
    if (regionName === LoadingWrapperView.CONTENT_REGION) {
      if (contentController.isViewRendered()) {
        this.onContentControllerViewRendered(contentController, contentController.getView());
      } else {
        this.listenToOnce(contentController, 'view:render', this.onContentControllerViewRendered);
      }
    }
  }

  onBeforeRemoveRegionController(loadingController, manager, regionName) {
    if (regionName === LoadingWrapperView.CONTENT_REGION) {
      this.destroyEntitySyncTracker();
    }
  }

  onContentControllerViewRendered() {
    this.initializeSyncTracker();
    this.startEntitySyncTracker();
  }

  onStartLoading(syncTracker, options) {
    this.showLoadingView(options);
  }

  onStopLoading() {
    this.hideLoadingView();
  }

  showLoadingView(options = {}) {
    if (options.inProgress) {
      this.getView().show({ fade: false });
    } else {
      this.getView().show();
    }
  }

  hideLoadingView() {
    this.getView().hide();
  }

  destroy() {
    this.destroyEntitySyncTracker();
    super.destroy();
  }
}

module.exports = LoadingWrapperController;
