const Backbone = require('Backbone');
const _ = require('underscore');

// This is a basic model for wrapping settings that need to be passed around
// the different controllers. It maintains all state that is shared between
// them and allows a central reference where things can be listened to on and
// delegate events to.
class CommunityContext extends Backbone.Model {
  initialize(options = {}) {
    if (!_.isString(options.languageFormatCode)) {
      throw new Error('Must pass in a shared formatting language code.');
    }
    if (!_.isObject(options.groupList)) {
      throw new Error('Must pass in a group list to be shared.');
    }

    this.set('languageFormatCode', options.languageFormatCode);
    this.set('groupList', options.groupList);
  }
}

module.exports = CommunityContext;
