const Backbone = require('Backbone');

const { has } = require('underscore');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const AchievementsDefinitionFactory = require('@training/apps/home/performance/achievements/AchievementsDefinitionFactory');
const TopicProgressDefinitionFactory = require('@training/apps/home/performance/topicProgress/TopicProgressDefinitionFactory');

const {
  REPORT_CARD,
  ACHIEVEMENTS
} = require('@common/data/enums/HubTileIdentifiers').default;
const AxBreakpointEnum = require('@common/data/enums/AxBreakpointEnum').default;
const HubMenuConfigMap = require('@common/modules/base/HubMenuConfigMap');

const { CONTAINER_CLASSES } = require('@common/libs/behaviors/carousel/Carousel');

const SLIDES_PER_VIEW_BREAKPOINTS_CONFIG = [{
  isDefault: true,
  slidesForSizes: [
    {
      minSlideCount: 1,
      slidesPerView: 1
    },
    {
      minSlideCount: 2,
      slidesPerView: 2
    }
  ]
}, {
  breakpoint: AxBreakpointEnum.MOBILE_MAX,
  slidesForSizes: [
    {
      minSlideCount: 1,
      slidesPerView: 1
    },
    {
      minSlideCount: 2,
      slidesPerView: 1.1
    }
  ]
}];

const generateHubPageNavigator = (hubIdentifier) => {
  return Backbone.history.navigate.bind(Backbone.history, HubMenuConfigMap[hubIdentifier].hash, true);
};

module.exports = (availableTiles, topicProgressList, session) => {
  const areAchievementReportCardDisabled = !has(availableTiles, ACHIEVEMENTS) && !has(availableTiles, REPORT_CARD);
  if (areAchievementReportCardDisabled) {
    return null;
  }

  return () => {
    return {
      ViewControllerClass: StackedLayoutViewController,
      viewDefinition: {
        className: 'hub-performance__list',
        tagName: 'ul',
        childViewOptions: {
          className: 'hub-performance__item-region',
          tagName: 'li'
        },
        behaviors: {
          Carousel: {
            keyboard: false,
            containerClass: `hub-performance ${ CONTAINER_CLASSES.STANDARD_GAP }`,
            slidesPerViewCountConfig: SLIDES_PER_VIEW_BREAKPOINTS_CONFIG
          }
        }
      },
      childControllers: [
        AchievementsDefinitionFactory(availableTiles, session),
        TopicProgressDefinitionFactory(availableTiles, topicProgressList, generateHubPageNavigator(REPORT_CARD))
      ]
    };
  };
};
