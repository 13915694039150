const { extend } = require('underscore');
const I18n = require('@common/libs/I18n');
const { VIDEO_MODULE } = require('@common/data/enums/ActivityType');

const { Done } = require('@training/apps/training/assessmentProgress/ProgressMarkerOptions');

const AbstractProgressConfig = require('@training/apps/training/assessmentProgress/AbstractProgressConfig');

const INITIAL_PROGRESS = 10;

const getModuleActivity = (assessment) => {
  return assessment.activities.find((activity) => {
    return activity.isTrainingContentActivity();
  });
};

const hasMixedModuleTypes = (assessment) => {
  const {
    hasVideos,
    hasModules
  } = assessment.activities.filter((activity) => {
    return activity.isTrainingContentActivity();
  }).reduce((memo, activity) => {
    const isVideo = activity.getType() === VIDEO_MODULE;

    memo.hasVideos |= isVideo;
    memo.hasModules |= !isVideo;

    return memo;
  }, {
    hasVideos: false,
    hasModules: false
  });

  return hasVideos && hasModules;
};

const getModuleMarkerIconOptions = (assessment) => {
  const totalModuleCount = assessment.activities.getTotalModulesActivityCount();
  const completedModuleCount = assessment.activities.getClosedModulesActivityCount();

  if (totalModuleCount === completedModuleCount) {
    return Done;
  }

  return {};
};

class AssessmentModuleProgressConfig extends AbstractProgressConfig {

  static ProgressBarConfigId = 'module-progress-bar';

  static MarkerConfigId = 'module-marker';

  constructor(assessment) {
    super();

    this.assessment = assessment;
  }

  isValid() {
    return this.assessment.activities.getTotalModulesActivityCount() > 0;
  }

  getSegmentLabelText() {
    const totalModuleCount = this.assessment.activities.getTotalModulesActivityCount();
    const trainingModuleActivity = getModuleActivity(this.assessment);
    const mixedModules = totalModuleCount > 1 && hasMixedModuleTypes(this.assessment);
    const showModuleText = mixedModules || trainingModuleActivity.getType() !== VIDEO_MODULE;

    if (showModuleText) {
      if (totalModuleCount === 1) {
        return I18n.t('assessments.progress.segment.module.singular');
      }
      return I18n.t('assessments.progress.segment.module.plural', { count: totalModuleCount });

    }
    if (totalModuleCount === 1) {
      return I18n.t('assessments.progress.segment.video.singular');
    }
    return I18n.t('assessments.progress.segment.video.plural', { count: totalModuleCount });


  }

  getSegmentValue() {
    const totalModuleCount = this.assessment.activities.getTotalModulesActivityCount();
    const completedModuleCount = this.assessment.activities.getClosedModulesActivityCount();
    const percentProgress = completedModuleCount / totalModuleCount * 100;

    if (completedModuleCount === 0 && (1 / totalModuleCount * 100) > INITIAL_PROGRESS) {
      return INITIAL_PROGRESS;
    }

    return percentProgress;
  }

  getProgressBarOptions() {
    return {
      id: AssessmentModuleProgressConfig.ProgressBarConfigId,
      labelText: this.getSegmentLabelText(),
      value: this.getSegmentValue()
    };
  }

  getMarkerOptions() {
    return extend(getModuleMarkerIconOptions(this.assessment), { id: AssessmentModuleProgressConfig.MarkerConfigId });
  }
}

module.exports = AssessmentModuleProgressConfig;
