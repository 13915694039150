const {CollectionView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const logging = require('logging');
const EventsCardRowView = require('@training/apps/home/hubPreviews/events/EventsCardRowView');
const NoEventsView = require('@training/apps/home/hubPreviews/events/NoEventsView');

const getEventsCardControllerDefinition = (options = {}) => {

  if (!options.eventsCollection) {
    logging.error('The eventsCollection object is required to generate the EventsCardControllerDefinition');
    return null;
  }

  return getBaseCardControllerDefinition({
    cardClass: 'events-view',
    titleOptions: {
      title: I18n.t('hub.postTraining.previews.events.title')
    },
    linkOptions: {
      target: 'fullCard',
      callback: options.callback,
      linkTextKeyOverride: 'hub.postTraining.previews.events.linkText',
      linkAriaLabel: I18n.t('hub.postTraining.previews.events.linkText')
    },
    hasLink: true,
    tabbableLinkClickableCard: true,
    bottomWrapperControllerDefinition: {
      viewDefinition: {
        emptyView: NoEventsView,
        ViewClass: CollectionView,
        tagName: 'ul',
        className: 'events__list full-width',
        childView: EventsCardRowView,
        collection: options.eventsCollection,
        childViewOptions: {
          tagName: 'li',
          className: 'events__row'
        }
      }
    }
  });
};

module.exports = {
  getEventsCardControllerDefinition
};
