const logging = require('logging');
const CookieUtil = require('CookieUtil');
const Backbone = require('Backbone');
const _ = require('underscore');

const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const RedirectingAbortedAuthentication = require('@training/apps/auth/exceptions/RedirectingAbortedAuthentication');

const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const RecoverUsernameController = require('@common/modules/auth/views/recovery/username/RecoverUsernameController');
const ResetPasswordController = require('@common/modules/auth/views/reset/ResetPasswordController');
const RecoverPasswordController = require('@common/modules/auth/views/recovery/password/RecoverPasswordController');

const CommonFlashAdapter = require('@training/apps/training/CommonFlashAdapter');

class Router extends Backbone.Router {
  routes() {
    return {
      'login(?*path)': 'login',
      'loginError(?*path)': 'loginError',
      'recover-username': 'recoverUsername',
      'recover-password': 'recoverPassword',
      'user-agreements': 'userAgreements',
      'activate-account(?*path)': 'activateAccount',
      'selfreg(?*path)': 'selfRegistration',
      'selfreg-complete': 'selfRegistrationComplete',
      resetpassword: 'resetPassword'
    };
  }

  constructor(options = {}) {
    super(options);

    ({ nbChannel: this.nbChannel } = options);

    this._setupNavChannel();
  }

  _doNav(route, options) {
    RedirectingAbortedAuthentication.catchAndLog(() => {
      window.apps.auth.redirectToAuthPageFrag(route, options);
    }, () => {
      logging.info('The user could not be sent to the auth frag.');
    });
  }

  _setupNavChannel() {
    this.navChannel = Backbone.Wreqr.radio.channel('navigation');
    this.navChannel.commands.setHandlers({
      'show:login': _.partial(this._doNav.bind(this), '#login')
    });
  }

  login(queryString = '') {
    const query = UrlHelpers.getQueryParams(`?${ queryString }`);
    const loginPrompt = (query.loginPrompt !== 'false');

    if (!CookieUtil.isEnabled()) {
      this.showCookieDisabledWarning();
      return;
    }

    const LoginPage = require('@training/apps/auth/views/LoginPage');
    const loginPage = new LoginPage({ query });

    const LoginLayout = require('@common/modules/auth/views/login/LoginLayout');
    const loginLayout = new LoginLayout({
      loginFormView: loginPage,
      loginPrompt
    });

    window.app.layout.toggleLayoutHeaderDesktopHideable(true); // needs to happen before view is displayed to avoid flicker effect on header
    window.app.layout.toggleFooter(false);

    loginLayout.listenTo(loginLayout, 'render', () => {
      window.app.layout.toggleLayoutHeaderDesktopHideable(true);
      window.app.layout.toggleFullPage(true);
      window.app.layout.toggleFooter(false);
    });
    loginLayout.listenTo(loginLayout, 'destroy', () => {
      window.app.layout.toggleLayoutHeaderDesktopHideable(false);
      window.app.layout.toggleFullPage(false);
      window.app.layout.toggleFooter(true);
    });

    window.app.layout.setView(loginLayout);
  }

  loginError() {
    const LoginErrorPage = require('@training/apps/auth/views/LoginErrorPage');
    const v = new LoginErrorPage();
    window.app.layout.setView(v);
  }

  resetPassword(queryString = '') {
    const queryParams = UrlHelpers.getQueryParams(`?${ queryString }`);
    const token = queryParams.token;
    if (!token || token === '') {
      window.apps.auth.redirectToIndexPage();
      return;
    }

    if (this._passwordResetController == null || this._passwordResetController.isDestroyed) {
      const viewControllerFactory = new ViewControllerFactory();
      this._passwordResetController = viewControllerFactory.create(ResetPasswordController, {
        navChannel: this.navChannel,
        flash: CommonFlashAdapter,
        tenantPropertyPool: TenantPropertyProvider.get(),
        token: token
      });
    }

    this._passwordResetController.inflateView();
    window.app.layout.setView(this._passwordResetController.getView());
  }

  recoverPassword() {
    window.location.hash = '';
    if (this._passwordRecoveryController == null || this._passwordRecoveryController.isDestroyed) {
      const viewControllerFactory = new ViewControllerFactory();
      this._passwordRecoveryController = viewControllerFactory.create(RecoverPasswordController, {
        navChannel: this.navChannel,
        flash: CommonFlashAdapter,
        tenantPropertyPool: TenantPropertyProvider.get()
      });
    }

    this._passwordRecoveryController.inflateView();
    window.app.layout.setView(this._passwordRecoveryController.getView());
  }

  recoverUsername() {
    window.location.hash = '';
    if (this._usernameRecoveryController == null || this._usernameRecoveryController.isDestroyed) {
      const viewControllerFactory = new ViewControllerFactory();
      this._usernameRecoveryController = viewControllerFactory.create(RecoverUsernameController, {
        navChannel: this.navChannel,
        flash: CommonFlashAdapter,
        tenantPropertyPool: TenantPropertyProvider.get()
      });
    }

    this._usernameRecoveryController.inflateView();
    window.app.layout.setView(this._usernameRecoveryController.getView());
  }

  activateAccount() {
    const ActivateAccountPage = require('@training/apps/auth/views/ActivateAccountPage');
    const v = new ActivateAccountPage();
    window.app.layout.setView(v);
  }

  userAgreements() {
    const finishedReadingAgreements = () => {
      window.apps.auth.checkSession();
    };

    window.app.layout.toggleFooter(false);
    const UserAgreementAcceptanceWrapperView = require('@training/apps/auth/views/UserAgreementAcceptanceWrapperView');
    const v = new UserAgreementAcceptanceWrapperView({
      callback: finishedReadingAgreements,
      session: window.apps.auth.session
    });

    window.app.layout.setView(v);
  }

  selfRegistration() {
    const SelfRegistrationPage = require('@training/apps/auth/views/SelfRegistrationPage');
    const v = new SelfRegistrationPage();
    window.app.layout.setView(v);
  }

  selfRegistrationComplete() {
    const SelfRegistrationCompletePage = require('@training/apps/auth/views/SelfRegistrationCompletePage');
    const v = new SelfRegistrationCompletePage();
    window.app.layout.setView(v);
  }

  showCookieDisabledWarning() {
    const DisabledCookiesWarningView = require('@common/modules/auth/views/disabledCookies/DisabledCookiesWarningView');
    const cookieWarningPage = new DisabledCookiesWarningView({
      tenantPropertyPool: TenantPropertyProvider.get(),
      isInApp: this.nbChannel.reqres.request('isInApp')
    });

    cookieWarningPage.listenTo(cookieWarningPage, 'click:retry', () => {
      window.apps.auth.checkSession();
    });

    window.app.layout.setView(cookieWarningPage);
  }
}

module.exports = Router;
