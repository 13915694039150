// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__title"><%- msgTypeTitle %></span>
    </div>
  </div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="user-messaging">
      <div class="broadcastmessage <%- subType %>">
        <div class="referral-points-info">
          <p class="upper-text"><%- t('referral.firstLoginMessage.points.upperText') %></p>
          <div class="pts-wrapper">
            <p class="login-points"><%- loginPoints %></p>
          </div>
          <p class="lower-text"><%- t('referral.firstLoginMessage.points.lowerText') %></p>
        </div>
        <div class="invite-msg-meta">
          <p class="text-ellipsis-softwrap">
            <%= t('referral.firstLoginMessage.text.referralAccepted', {name: name}) %>
          </p>
          <p class="text-ellipsis-softwrap">
            <%= t('referral.firstLoginMessage.text.subsequentLoginInfo', {name: name, points: subsequentPoints, days: daysLeft}) %>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;