// Module
var code = `<div class="page-header">
	<div class="ax-container relative">
		<div class="page-header__content">
			<span class="page-header__icon icon-tellafriend" aria-hidden="true"></span>
			<h1 class="page-header__title"><%- t('referral.title') %></h1>
			<%= axButton({
				className: 'js-how-to-link how-to-link',
				size: 'm',
				iconClassLeft: 'icon-question_sign icon--reset-styles',
				label: t('referral.howTo.link'),
				ariaLabel: t('referral.howTo.link')
			}) %>
		</div>
	</div>
</div>

<div class="ax-container">
	<div class="page-content ax-grid clearfix">
		<div id="left-pane" class="ax-grid__col--12 ax-grid__col--m-8 padding-bottom--s">
			<p class="no-invites">
				<%= t('referral.noinvites') %>
			</p>
		</div>
		<div id="right-pane" class="ax-grid__col--12 ax-grid__col--m-4">
			<div class="active-invites-container"></div>
			<div class="recent-invites-container"></div>
		</div>
		<div class="js-referral-how-to__dialog-region hidden"></div>
	</div>
</div>

`;
// Exports
module.exports = code;