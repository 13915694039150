const Backbone = require('Backbone');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const {
  BADGE_MODIFIERS,
  TitleHeaderDefinitionBuilder
} = require('@common/components/titleHeader/TitleHeader');
const EvaluationsPrintFactory = require('@training/apps/common/evaluations/EvaluationsPrintFactory');
const EvaluationObjectiveStatus = require('@common/data/enums/EvaluationObjectiveStatus');

const EvaluationDetailsLayoutView = require('@training/apps/training/views/evaluations/EvaluationDetailsLayoutView');
const EvaluationDetailsDescriptionController = require('@training/apps/training/views/evaluations/EvaluationDetailsDescriptionController');
const EvaluationDetailsInfoController = require('@training/apps/training/views/evaluations/EvaluationDetailsInfoController');
const EvaluationDetailsContentView = require('@training/apps/training/views/evaluations/EvaluationDetailsContentView');
const BehaviourTypeEnum = require('@common/data/enums/BehaviourTypeEnum');
const EvaluationSubmissionUploadFileLinkView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionUploadFileLinkView');
const FileFactory = require('@common/libs/file/FileFactory');
const { getStylePath } = require('@common/libs/helpers/app/CompilationInfoHelpers');

class EvaluationDetailsController extends LayoutController {

  initialize(options = {}) {
    ({
      guidedLearningFlowCallback: this.guidedLearningFlowCallback,
      startEvaluationCallback: this.startEvaluationCallback,
      evaluationOption: this.evaluationOption,
      evaluationForm: this.evaluationForm,
      evaluationDetailsRoute: this.evaluationDetailsRoute,
      isViewingLastAttempt: this.isViewingLastAttempt = false
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: EvaluationDetailsLayoutView,
      evaluationForm: this.evaluationForm,
      guidedLearningFlowCallback: this.guidedLearningFlowCallback,
      startEvaluationCallback: this.startEvaluationCallback,
      isViewingLastAttempt: this.isViewingLastAttempt
    };
  }

  regionControllers() {
    const evaluationTitle = this.evaluationForm.getEvaluationTitle();
    const formType = this.evaluationForm.getEvalType();

    const regionControllers = {
      titleRegion: this._getTitleRegionController(evaluationTitle),
      infoRegion: {
        ViewControllerClass: EvaluationDetailsInfoController,
        model: this.evaluationForm,
        objective: this.evaluationOption.getObjective(),
        evaluationOption: this.evaluationOption,
        evaluationController: EvaluationDetailsController,
        evaluationDetailsRoute: this.evaluationDetailsRoute,
        isViewingLastAttempt: this.isViewingLastAttempt,
        delegateEvents: {
          'view:childview:print:clicked': this._printEvaluationForm.bind(this)
        }
      },
      contentRegion: {
        ViewControllerClass: LayoutController,
        viewDefinition: {
          ViewClass: EvaluationDetailsContentView,
          model: this.evaluationForm
        }
      },
      submissionUploadRegion: this._getSubmissionUploadRegionConfig(formType)
    };

    if (this.evaluationForm.getDescription()) {
      regionControllers.descriptionRegion = {
        ViewControllerClass: EvaluationDetailsDescriptionController,
        description: this.evaluationForm.getDescription(),
        isNotStarted: this.evaluationForm.getEvaluationStatus() === EvaluationObjectiveStatus.NOT_STARTED
      };
    }

    return regionControllers;
  }

  _getTitleRegionController(title) {
    return new TitleHeaderDefinitionBuilder({
      isSemanticHeader: false,
      badgeModifierClasses: [BADGE_MODIFIERS.MEDIUM],
      iconClass: 'page-header__icon icon-evaluation',
      title,
      titleTag: 'h1',
      titleClass: 'page-header__title evaluation-details__header--title'
    }).build();
  }

  _printEvaluationForm() {
    const glChannel = Backbone.Wreqr.radio.channel('global');
    const config = {
      cssPaths: getStylePath(process.styleSourceKey.evaluationPrintCss),
      view: EvaluationsPrintFactory.create(this.evaluationForm)
    };
    glChannel.commands.execute('app:print', '_window', config);
  }

  _getSubmissionUploadRegionConfig(formType) {
    const config = (formType === BehaviourTypeEnum.VirtualEvaluation)
      ? {
        viewDefinition: {
          ViewClass: EvaluationSubmissionUploadFileLinkView,
          fileFactory: FileFactory,
          fileOptions: {
            documentsAllowed: true
          }
        },
        delegateEvents: {
          'view:select:file': (controller, view, file) => {
            view.trigger('file:upload:ready', file);
          }
        }
      }
      : {};
    return config;
  }
}

module.exports = EvaluationDetailsController;
