const _ = require('underscore');
const { ItemView } = require('Marionette');
const TagGroupTagItem = require('@training/apps/search/tagGroupFilter/TagGroupTagItem');
const Form = require('@common/components/forms/Form');
const TagGroupModal = require('@training/apps/search/tagGroupFilter/TagGroupModal');

require('@common/components/forms/editors/checkboxes/Form.Editor.Checkboxes');

class TagGroupView extends ItemView {
  constructor(options = {}) {
    super(options);

    ({
      onApply: this.onApply,
      filterState: this.filterState
    } = options);

    this.initialTags = this.model.get('selectedTags');
    this.isOpen = this.initialTags.length > 0; // Open up if we have URL defined selected tags.
    this.onClickApply = this.onClickApply.bind(this);
    this.onClickClearAll = this.onClickClearAll.bind(this);
    this.syncSelectedStatusFromCollection = this.syncSelectedStatusFromCollection.bind(this);
    this.syncSelectedStatusFromModel = this.syncSelectedStatusFromModel.bind(this);
    this._onFilterStateChange = this._onFilterStateChange.bind(this);

    this.listenTo(this.filterState, 'change:lastActiveTagGroup', this._onFilterStateChange);
  }

  getTemplate() {
    return require('@training/apps/search/tagGroupFilter/TagGroupViewTemplate.html');
  }

  ui() {
    return {
      tagGroupDesktop: '.js-tag-group-desktop',
      modalLaunch: '.js-modal-launch',
      form: '.js-form',
      applyButton: '.js-tag-group__apply-button',
      clearAllButton: '.js-tag-group__clear-all-button',
      viewToggleButton: '.js-tag-group__header',
      titleMin: '.js-tag-group-min-title',
      titleOpen: '.js-tag-group-open-title',
      contents: '.js-tag-group__contents'
    };
  }

  events() {
    return {
      'click @ui.modalLaunch': 'showTagGroupModal',
      'click @ui.applyButton:not(.disabled)': 'onClickApply',
      'click @ui.clearAllButton:not(.disabled)': 'onClickClearAll',
      'click @ui.viewToggleButton': 'toggleExpandedView',
      'focus input': 'openExpandedViewFromFocus',
      'focus button': 'openExpandedViewFromFocus'
    };
  }

  modelEvents() {
    return {
      'change:selectedTags': (model, selectedTags) => {
        this._toggleButtonStates(selectedTags);
        this.filterState.set('lastActiveTagGroup', model.get('name'));
      }
    };
  }

  collectionEvents() {
    return {
      change: 'syncSelectedStatusFromCollection'
    };
  }

  onAttach() {
    this.setTitleWrap();
  }

  onRender() {
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        selectedTags: {
          collection: this.collection
        }
      }
    });
    this._toggleButtonStates(this.model.get('selectedTags'));
    this.setTitleWrap();
    this._updateExpandedRenderState();
  }

  _toggleButtonStates(selectedTags = []) {
    const hasNoSelectedTags = selectedTags.length === 0;
    const hasNoSelectedTagChanges = !this._hasSelectedTagChanges(selectedTags);

    this.ui.clearAllButton.toggleClass('disabled', hasNoSelectedTags);
    this.ui.clearAllButton.attr('aria-disabled', hasNoSelectedTags);

    this.ui.applyButton.toggleClass('disabled', hasNoSelectedTagChanges);
    this.ui.applyButton.attr('aria-disabled', hasNoSelectedTagChanges);
  }

  toggleExpandedView() {
    this.isOpen = !this.isOpen;
    this._updateExpandedRenderState();
  }

  openExpandedViewFromFocus() {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;
    this._updateExpandedRenderState();
  }

  _updateExpandedRenderState() {
    this.ui.tagGroupDesktop.toggleClass('tag-group__desktop-view--open', this.isOpen);
  }

  get childView() {
    return TagGroupTagItem;
  }

  templateHelpers() {
    return {
      name: this.model.get('name'),
      modalLaunchActiveClass: this.model.get('selectedTags').length > 0 ? 'ax-button--ghost' : '',
      modalLaunchActiveCount: this.model.get('selectedTags').length > 0 ? `: ${ this.model.get('selectedTags').length }` : '',
      chevronStyle: this.isOpen ? 'icon-chevron_up' : 'icon-chevron_down',
      tagGroupSelected: this.model.get('selectedTags').length > 0 ? 'tag-group__selected' : ''
    };
  }

  onClickApply() {
    this.syncSelectedStatusFromModel();
    this.ui.applyButton.addClass('disabled');
    this.onApply(this.model.get('tags'));
  }

  onClickClearAll() {
    this.model.set('selectedTags', []);
    this.syncSelectedStatusFromModel();
    this.onApply(this.model.get('tags'));
  }

  showTagGroupModal() {
    window.app.layout.presentModal(new TagGroupModal({
      model: this.model,
      collection: this.collection,
      name: this.model.get('name'),
      applyCallback: this.onClickApply,
      clearCallback: this.onClickClearAll
    }), {
      onClose: () => {
        // TODO: throw "reset" event for selections like cancel, maybe
        this.render();
      }
    });
  }

  syncSelectedStatusFromModel() {
    const selectedTags = this.model.get('selectedTags');
    this.collection.forEach((tag) => {
      const tagFormId = tag.get('formId');
      if (selectedTags.includes(tagFormId)) {
        tag.set('isSelected', true, { silent: true });
      } else {
        tag.unset('isSelected', { silent: true });
      }
    });

    this._setSelectedTags();
  }

  syncSelectedStatusFromCollection() {
    this._setSelectedTags();
    this.render();
  }

  _hasSelectedTagChanges(selectedTags) {
    const selTagsSet = new Set(selectedTags);
    const initTagsSet = new Set(this.initialTags);

    return !_.isEqual(Array.from(selTagsSet), Array.from(initTagsSet));
  }

  _setSelectedTags() {
    let selectedTags = this.model.get('selectedTags');

    this.collection.forEach((tag) => {
      const tagFormId = tag.get('formId');
      const tagIsSelected = tag.get('isSelected');

      if (!selectedTags.includes(tagFormId) && tagIsSelected) {
        selectedTags.push(tagFormId);
      } else if (!tagIsSelected && selectedTags.includes(tagFormId)) {
        const index = selectedTags.indexOf(tagFormId);
        if (index > -1) {
          selectedTags = selectedTags.splice(index, 1);
        }
      }
    });

    this.form.update(this.model);
  }

  _onFilterStateChange() {
    if (this.filterState.get('lastActiveTagGroup') !== this.model.get('name') && this._hasSelectedTagChanges(this.model.get('selectedTags'))) {
      this.model.set('selectedTags', JSON.parse(JSON.stringify(this.initialTags)), { silent: true });
      this.isOpen = true;
      this.render();
    }
  }

  setTitleWrap() {
    $(this.ui.titleMin).dotdotdot({
      watch: true,
      height: parseInt($(this.ui.titleMin).css('lineHeight'), 10)
    });
  }
}

module.exports = TagGroupView;
