const I18n = require('@common/libs/I18n');
const EventCheckinCardView = require('@training/apps/home/eventSelfCheckin/EventCheckinCardView');

const { BADGE_MODIFIERS } = require('@common/components/titleHeader/TitleHeader');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');

const getEventCheckinCardControllerDefinition = () => {
  const cardOptions = {
    viewClass: EventCheckinCardView,
    cardClass: 'event-checkin__card',
    titleOptions: {
      title: I18n.t('eventCheckin.title'),
      subTitle: I18n.t('eventCheckin.subtext'),
      iconClass: 'icon-calendar-check',
      badgeModifierClasses: [BADGE_MODIFIERS.LARGE, BADGE_MODIFIERS.APP_ZONE]
    },
    templateHelpersOverride: {
      ariaInputLabel: I18n.t('eventCheckin.title') + '. ' + I18n.t('eventCheckin.prompt')
    }
  };

  return getBaseCardControllerDefinition(cardOptions);
};

module.exports = {
  getEventCheckinCardControllerDefinition
};
