const _ = require('underscore');
const CommunityAccessLevelEnum = require('@common/data/enums/CommunityAccessLevelEnum');

const ACCESS_LEVEL_INT_MAPPING = {
  [CommunityAccessLevelEnum.NONE]: undefined,
  [CommunityAccessLevelEnum.VIEW]: 10, // Member acccess
  [CommunityAccessLevelEnum.CREATE]: 20,
  [CommunityAccessLevelEnum.EDIT]: 30,
  [CommunityAccessLevelEnum.MODERATE]: 40
};

const toInteger = (accessLevel) => {
  if (Number.isInteger(accessLevel)) {
    return accessLevel;
  }

  return ACCESS_LEVEL_INT_MAPPING[accessLevel];
};

const toString = (num) => {
  if (num == null) {
    return CommunityAccessLevelEnum.NONE;
  } else if (typeof num === 'string') {
    return num;
  }

  return _.find(_.keys(ACCESS_LEVEL_INT_MAPPING), (key) => {
    return ACCESS_LEVEL_INT_MAPPING[key] === num;
  });
};

const getAccessLevelValues = () => {
  return _.values(ACCESS_LEVEL_INT_MAPPING);
};

const generateModelsData = (values, headerNames) => {
  const data = [];
  _.each(values, (level, index) => {
    data.push({
      level,
      columnHeader: headerNames[index + 1]
    });
  });

  return data;
};

module.exports = {
  toInteger,
  toString,
  getAccessLevelValues,
  generateModelsData
};
