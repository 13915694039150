const _ = require('underscore');
const TabbedLayout = require('@common/components/tabbed_layout/TabbedLayout');
const I18n = require('@common/libs/I18n');
const Backbone = require('Backbone');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

class SearchTabbedLayout extends TabbedLayout {
  constructor(...args) {
    super(...args);

    this._tabTemplate = `<li class="tab <%- tabClass || tabName %>" data-tab="<%- tabName %>" role="presentation">
  <a class="tab-content" href="#" role="tab" aria-selected="false"><%= tabText %><% if (showCount) { %>
    <span class="itemCountWrapper"><span class="itemCount"><%= collectionCount %></span></span>
  <% } %></a>
</li>`;
  }

  getTemplate() {
    return `
    <div class="tabbed-content-wrapper">\
      <div class="tab-scroll-gradient"></div>
      <ul class="tab-container tab-bar ax-grid ax-grid--no-gutter clearfix" role="tablist"></ul>
      <div class="tab-content-container invisible"></div>\
    </div>`;
  }

  className() {
    return 'tabbed-layout search-tabbed-layout';
  }

  ui(...args) {
    return _.extend(super.ui(...args), {
      contentContainer: '.tab-content-container'
    });
  }

  _createTabElement(tabOptions = {}) {
    Object.assign(tabOptions, {
      collectionCount: this._getCollectionCount(tabOptions.contentCollection)
    });
    const newTab = super._createTabElement(tabOptions);
    $('.itemCount', newTab).text('');

    if (tabOptions.contentCollection && tabOptions.showCount) {
      this.listenTo(tabOptions.contentCollection, 'sync', () => {
        // Once the fetch call for the current tab's collection has finished, update the search results count
        $('.itemCount', newTab).text(`(${ tabOptions.contentCollection.state.totalRecords || 0 })`);
      });
    }

    return newTab;
  }

  _getCollectionCount(collection) {
    if (collection == null) {
      return 0;
    } else if (collection.isLoading) {
      return '';
    }
    return collection.state.totalRecords || 0;

  }

  setActiveTab(tabName) {
    if (!this.isValidTabName(tabName)) {
      const pageState = this.getOption('searchPageState');
      const community = pageState.get('community');
      window.app.layout.flash.error(I18n.t('selfDirected.search.errors.tabNotAvailable'));
      Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ community.id }`,
        {
          trigger: true,
          replace: true
        });
      return;
    }

    if (tabName === this._activeTab) {
      return;
    }

    if (this._activeTab != null) {
      this._hideTab(this._activeTab);
    }

    this.ui.contentContainer.toggleClass('invisible', true);
    this._activeTab = tabName;
    this._showTab(tabName);

    $.when(...(this.options.searchPageState.get('searchesArray'))).done(() => {
      // Once any/all of the search calls are finished running, then make the view visible again
      this.$('.tab-content-container').toggleClass('invisible', false);
    });
  }
}

module.exports = SearchTabbedLayout;
