const $ = require('jquery');

require('jquery.ajaxretry');

const AjaxDefaultSetup = require('@common/libs/ajax/AjaxDefaultSetup');
const RequestTokenPrefilter = require('@common/libs/ajax/RequestTokenPrefilter');
const UrlValidatorPrefilter = require('@common/libs/ajax/UrlValidatorPrefilter');
const JSONPrefilter = require('@common/libs/ajax/JSONPrefilter');
const BeaconAjax = require('@common/libs/ajax/BeaconAjax');

$.ajaxSetup(AjaxDefaultSetup);

// Specifying all normal data types so these prefilters take precedence over the prefilter in ajaxRetry.js which uses *
const dataTypes = 'json xml html script jsonp';

// Order matters here so the url is validated before the beacon prefilter code gets to run and only after that do we add the request token
$.ajaxPrefilter(dataTypes, UrlValidatorPrefilter);

// Specify the transport for sending data with the Beacon api
BeaconAjax.setup($);

$.ajaxPrefilter(dataTypes, RequestTokenPrefilter);

// JSON.stringifies the provided data param
$.ajaxPrefilter('json', JSONPrefilter);
