const { Model } = require('Backbone');
const { Behavior } = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');

Behaviors.SelectableBehavior = class SelectableBehavior extends Behavior {

  defaults() {
    return {
      selectableId: ''
    };
  }

  ui() {
    return {
      selectable: '.selectable'
    };
  }

  initialize() {
    this.selectableId = this.getOption('selectableId');
    this.selectedState = this.view.getOption('selectedState') || new Model();
  }

  events() {
    return {
      click: 'onClick'
    };
  }

  onClick() {
    this.setSelected();
  }

  onRender() {
    this.toggleSelected(this.selectedState.get('selectedId') === this.selectableId);

    this.listenTo(this.selectedState, 'change:selectedId', (model, selectedId) => {
      this.toggleSelected(selectedId === this.selectableId);
    });
  }

  toggleSelected(selected = false) {
    this.ui.selectable.toggleClass('selected', selected);
    this.ui.selectable.attr('aria-current', selected);
  }

  setSelected() {
    this.selectedState.set('selectedId', this.selectableId);
  }
};

module.exports = Behaviors.SelectableBehavior;
