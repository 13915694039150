const Backbone = require('Backbone');

const PageReportType = require('@common/data/models/PageReportType');

class PageReportTypeCollection extends Backbone.Collection {
  get model() {
    return PageReportType;
  }

  // We want to sort alphabetically based on their display name
  comparator(pageReportType) {
    return pageReportType.toOption().value;
  }
}

module.exports = PageReportTypeCollection;
