const _ = require('underscore');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const User = require('@common/components/discover/models/User');
const PageVersion = require('@common/components/discover/models/PageVersion');
const Community = require('@common/data/models/Community');
const PageType = require('@common/components/discover/enums/PageType');
const dateHelpers = require('@common/libs/dateHelpers');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class Page extends Backbone.Model {
  static MAX_TITLE_LENGTH = 256

  get publishStatus() {
    return {
      past: 'PAST',
      future: 'FUTURE'
    };
  }

  get expiryStatus() {
    return {
      past: 'PAST',
      future: 'FUTURE'
    };
  }

  apiEndpoint() {
    return '/pages';
  }

  get className() {
    return 'Page';
  }

  defaults() {
    return {
      language: '',
      recommendable: true
    };
  }

  validators() {
    return {
      title: 'required',
      language: 'required',
      community: 'required'
    };
  }

  constructor(attrs, options) {
    super(attrs, options);

    this._setDefaultContributorList();
    this._setDefaultCurrentVersion();
    this._setDefaultPublishTimeZone();
  }

  /**
   * In case the publish time zone is not already in the data, we inject the user's presumed time
   * zone into the model so it prepopulates that select box. Handy!
   * Why are we doing it here, in the constructor?
   * Because then it won't trigger a model "change" event. Why does that matter? Because if the model
   * changes at any point after this, the return value of model.hasChanged() will be true, and the
   * fancy language picker will think it needs to show the "are you sure" dialog popup that prompts
   * the user to save their work.
   * If we were to add the time zone when the form is instantiated, that would count as "changing the
   * model", and then model.hasChanged() would return true. We don't want that. So it must happen here,
   * at the moment of construction.
   * Maybe this comment seems long-winded to you, but I spent about 6 hours trying to figure
   * out why that popup was being triggered so I hope this explanation helps the next person who stumbles in here.
   */
  _setDefaultPublishTimeZone() {
    if (this.currentVersion && this.currentVersion.get('publishTimeZone') == null) {
      this.currentVersion.set('publishTimeZone', dateHelpers.getBrowserTimezone());
    }
  }

  _setDefaultCurrentVersion() {
    if (this.currentVersion != null) {
      return;
    }

    this.currentVersion = new PageVersion(this.get('currentVersion'));
    this.listenTo(this.currentVersion, 'change', (model) => {
      return this.set('currentVersion', model.toJSON());
    });
    this.listenTo(this, 'change:currentVersion', (model, value) => {
      return this.currentVersion.set(value);
    });
  }

  _setDefaultContributorList() {
    if (this.contributorList == null) {
      this.contributorList = new Backbone.Collection();
    }
    if (this.contributors == null) {
      this.contributors = {};
    }
  }

  parse(data = {}) {
    let user;

    this._setDefaultContributorList();
    this._setDefaultCurrentVersion();

    const instance = data.entity != null ? data.entity : data;
    instance.locked = (instance.lock != null);

    if (instance.locked) {
      user = new User(instance.lock != null ? instance.lock.creation.user : undefined, {
        parse: true
      });
    } else {
      user = null;
    }
    instance.lockedBy = user;

    if (instance.community == null && instance.communities != null) {
      instance.community = this._getHomeCommunity(instance.communities);
    }

    if (instance.community && !(instance.community instanceof Community)) {
      this.community = new Community(instance.community);
      instance.community = this.community;
    }

    // Need to get the user objects of the contributors
    // 1. Initial Author of page
    // 2. Latest Editor of page
    // 3. last 3 that have verified
    let author = null;
    let editor = null;
    const agreed = new Backbone.Collection();

    if ((instance.author != null ? instance.author.user : undefined) != null) {
      author = new User(instance.author.user, {
        parse: true
      });
      this.contributors = {
        author
      };
      this.contributorList.add(author);

      // can only get editors and agree'ers if there's a current version
      if (instance.currentVersion != null) {
        // handle editors, agree'ers and contributors
        editor = new User(instance.currentVersion.author.user, {
          parse: true
        });
        agreed.add(_.map(instance.currentVersion.latestAgrees, (agree) => {
          return new User(agree.author.user, {
            parse: true
          });
        }));

        // add editor and agree'ers to contributors
        this.contributors = $.extend(true, this.contributors, {
          editor,
          agreed
        });

        // set contributor collection, this is a collection unique collection
        // of the contributors in the following order (author, editor, agree'ers...)
        if (author.id !== editor.id) {
          this.contributorList.add(editor);
        }
        this.contributorList.add(agreed.models);
      }
    }

    return instance;
  }

  _getActionText() {
    return null;
  }

  _getHomeCommunity(communities = []) {
    const homeCommunity = communities.filter((community) => {
      return community.isHomeCommunity;
    });

    if (homeCommunity.length > 0) {
      return homeCommunity[0].community;
    }

    return undefined;
  }

  canReport() {
    return !this.currentVersion.has('pageReport') && !this.currentVersion.isNew();
  }

  canShare() {
    // neither questions nor posts can be shared.
    if (this.get('type') === PageType.QUESTION || this.get('type') === PageType.POST) {
      return false;
    }

    // otherwise, if the user can View it, they can share it. We aren't bothering to code this
    // in the API, since the rule is so simple.
    return this.canView();
  }

  // This check is kept separate from `this.canEdit()` by design. This gives us the
  // ability to indicate in the UI that a page is editable but locked, which is a
  // distinct state from a page you can't edit.
  isLocked(userId) {
    return this.get('locked') && (this.get('lockedBy').id !== userId);
  }

  // this is used to determine whether to show the author and contributor list.
  isViewOnly() {
    return this.canView() && !(this.canEdit() || this.canPublish());
  }

  userHasAgreed() {
    return this.currentVersion.has('existingAgreeId');
  }

  canView() {
    return this.get('permittedPageActions') && this.get('permittedPageActions').VIEW;
  }

  canEdit() {
    return this.get('permittedPageActions') && this.get('permittedPageActions').EDIT;
  }

  canDelete() {
    return this.get('permittedPageActions') && this.get('permittedPageActions').DELETE;
  }

  canPublish() {
    return this.get('permittedPageActions') && this.get('permittedPageActions').PUBLISH;
  }

  canStar(withUser) {
    return !withUser.isGuestOrSuperuser();
  }

  view(viewData = {}) {
    return $.ajax({
      type: 'POST',
      url: `${ this.urlRoot() }/${ this.getId() }/views`,
      data: JSON.stringify(viewData)
    }); // this is needed because server framework doesn't support a POST with empty body
  }

  lock(options = {}) {
    let ajaxOptions = {
      type: 'PUT',
      url: `${ this.urlRoot() }/${ this.getId() }/lock`
    };

    ajaxOptions = $.extend(true, ajaxOptions, options);
    return $.ajax(ajaxOptions);
  }

  unlock(options = {}) {
    let ajaxOptions = {
      type: 'DELETE',
      url: `${ this.urlRoot() }/${ this.getId() }/lock`
    };

    ajaxOptions = $.extend(true, ajaxOptions, options);
    return $.ajax(ajaxOptions);
  }

  toggleFavorite() {
    if (this.isStarToggleInProgress) {
      return;
    }

    this.isStarToggleInProgress = true;

    $.ajax({
      type: (this.get('existingFavoriteId') != null) ? 'DELETE' : 'PUT',
      url: `${ this.urlRoot() }/${ this.getId() }/favorite`,
      success: (resp) => {
        let favoriteCount = this.get('favoriteCount');
        let existingFavoriteId = null;

        if (resp.entity != null) {
          existingFavoriteId = resp.entity.id;
          favoriteCount = favoriteCount + 1;
        } else {
          favoriteCount = favoriteCount - 1;
        }

        this.set('existingFavoriteId', existingFavoriteId);
        this.set('favoriteCount', favoriteCount);
      },
      error: () => {
        this.trigger('error:existingFavoriteId');
      },
      complete: () => {
        this.isStarToggleInProgress = false;
      }
    });
  }

  delete(options = {}) {
    return this.destroy(options);
  }

  clone(options = {}) {
    if (options.deepCopy) {
      const attr = $.extend(true, {}, this.attributes);
      return new this.constructor(attr);
    }
    return super.clone(options);

  }

  hasPastPublishDate() {
    return this.getPublishTimestampStatus() === this.publishStatus.past;
  }

  hasFuturePublishDate() {
    return this.getPublishTimestampStatus() === this.publishStatus.future;
  }

  hasPastExpiryDate() {
    return this.getExpiryTimestampStatus() === this.expiryStatus.past;
  }


  toJSON(...args) {
    const json = super.toJSON(...args);
    const actionText = this._getActionText();
    return $.extend(true, json, {
      actionText
    });
  }

  getLastEditor() {
    if (this.currentVersion.isNew()) {
      return this.get('author');
    }
    return this.currentVersion.get('author');

  }

  getAuthorId() {
    const editor = this.contributors.editor;

    if (editor != null) {
      return editor.id;
    }

    return undefined;
  }

  getTitle() {
    return HTMLHelpers.htmlDecode(this.get('title'));
  }

  getAriaTitle() {
    if (this.getType() === PageType.POST) {
      const author = this.get('authorName');
      const lastModified = dateHelpers.timeFromEvent(this.get('lastModified'));

      return I18n.t('comms.posts.postCreatedBy') + author + '. ' + lastModified + '. ' + I18n.t('selfDirected.search.viewDetailsAria');
    }
    return this.getTitle() + '. ' + I18n.t('selfDirected.search.viewDetailsAria');
  }

  getPinned() {
    return this.get('pinned');
  }

  getFavoriteCount() {
    const favoriteCount = this.get('favoriteCount');
    if (favoriteCount === 0) {
      return '';
    }
    return favoriteCount;

  }

  getType() {
    return this.get('type');
  }

  getId() {
    return this.get('id');
  }

  getReferenceUrl() {
    return this.get('url');
  }

  getLanguage() {
    return this.get('language');
  }

  getTrainingModule() {
    return this.get('trainingModule');
  }

  getContent() {
    return this.currentVersion.richContent.get('content');
  }

  getCurrentVersion() {
    return this.get('currentVersion');
  }

  getPublishTimestamp() {
    return this.currentVersion.get('publishTimestamp');
  }

  getPublishTimestampStatus() {
    return this.currentVersion.get('publishTimestampStatus');
  }

  getPageReportType() {
    return this.currentVersion.get('pageReport').reportType;
  }

  getStatus() {
    return this.get('status') && this.get('status').toUpperCase();
  }

  getAgreeCount() {
    return this.currentVersion.get('agreeCount');
  }

  getMedia() {
    return this.currentVersion.richContent.get('media');
  }

  getFirstImage() {
    // find the first media--image element and get its media-id
    const $newContent = $('<tmp></tmp>').append(this.getContent());
    const imageMediaId = $newContent.find('.page__media.media--image').first()
      .data('mediaId');

    // if imageMediaId is not null, then there is an image, so return
    // the media element or null if it does not exist
    const imageMedia = _.find(this.getMedia(), (media) => {
      return media.id === imageMediaId;
    });

    return imageMedia;
  }

  getRecommendationId() {
    return this.get('recommendationId');
  }

  isFact() {
    return this.currentVersion.get('approvedAsFact');
  }

  hasFirstImage() {
    return this.getFirstImage() != null;
  }
}

module.exports = Page;
