// Module
var code = `<div class="js-menu-dropdown-container menu-dropdown__container">
  <div class="js-menu-dropdown-ellipsis-container menu-dropdown__button-container">
    <%= axButton({
      label: buttonConfig.parsedButtonText,
      ariaLabel: buttonConfig.parsedAriaLabel,
      ariaExpanded: false,
      className: 'qa-menu-dropdown-toggle-button js-menu-dropdown-toggle-button menu-dropdown__toggle-button ' + buttonConfig.buttonClass,
      iconClassLeft: buttonConfig.parsedButtonText ? buttonConfig.buttonIcon : '',
      iconClass: buttonConfig.parsedButtonText ? '' : buttonConfig.buttonIcon,
      branded: buttonConfig.parsedButtonText ? true : false
    }) %>
  </div>

  <div class="js-menu-dropdown-popover menu-dropdown__popover mobile-overlay hidden">
    <ul>
    <% for (var i = 0; i < optionsListConfig.length; i++) { %>
      <li class="clear js-menu-dropdown-popover-option-<%- i %>">
        <<%- tagName(optionsListConfig[i].buttonLink) %>
        <%- addHref(optionsListConfig[i].buttonLink) %>
        class="qa-menu-dropdown-popover-action-<%- i %> js-menu-dropdown-popover-action <%- optionsListConfig[i].buttonClass %> inline-block"
        aria-label="<%- optionsListConfig[i].parsedAriaLabel %>">
          <span class="block left dropdown-button-icon <%- optionsListConfig[i].buttonIcon %>"></span>
          <span class="dropdown-button-text"><%- optionsListConfig[i].parsedButtonText %></span>
        </<%- tagName(optionsListConfig[i].buttonLink) %>>
      </li>
    <% } %>
    </ul>
  </div>
</div>
`;
// Exports
module.exports = code;