const I18n = require('@common/libs/I18n');
const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');
const GuidedLearningPathLinkView = require('./GuidedLearningPathLinkView');

class SelfDirectedPathLinkView extends GuidedLearningPathLinkView {
  _getIcon() {
    return 'icon-path';
  }

  _constructPathMarkup() {
    const pathName = `<a class="path" href="#">${ this.model.get('name') }</a>`;
    return I18n.t('selfDirected.topicDetails.topicPath', { pathName });
  }

  onClickPath() {
    window.app.sessionController.getHubController().showSelfDirectedPathPage({
      pageId: GuidedLearningPageEnum.DetailsPage,
      pageOptions: { programId: this.model.get('id') }
    });
  }
}

module.exports = SelfDirectedPathLinkView;
