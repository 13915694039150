const {
  LayoutView
} = require('Marionette');
const KeyCode = require('@common/data/enums/KeyCode');

class HeaderSlideoutLayout extends LayoutView {
  getTemplate() {
    return `
     <div class="ax-button ax-button--ghost js-menu-button header-slideout__button" tabindex="0" role="button" aria-label="<%- t('header.navMenuButtonLabel') %>" >
     <span class="item-icon icon-menu"></span>
      </div>
      <div class="js-header-slideout-menu header-slideout__menu off-screen"></div>
      <div class="js-slideout-title header-slideout__title hidden"></div>
    `;
  }

  className() {
    return 'header-slideout';
  }

  regions() {
    return {
      slideoutMenu: '@ui.slideoutMenu'
    };
  }

  ui() {
    return {
      menuButton: '.js-menu-button',
      slideoutMenu: '.js-header-slideout-menu',
      title: '.js-slideout-title'
    };
  }

  events() {
    return {
      'keydown @ui.menuButton': (e) => {
        if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
          this._toggleMenuOpen();
        }
      },
      'click @ui.menuButton': '_toggleMenuOpen',
      'mousedown @ui.slideoutMenu': 'onMousedownMenu',
      'click @ui.slideoutMenu': '_toggleMenuOpen',
      focusout: '_onFocusOut',
      'focusin @ui.slideoutMenu': '_onFocusIn'
    };
  }

  onMousedownMenu(e) {
    e.preventDefault();
  }

  _onFocusOut() {
    this._toggleMenuOpen(false);
  }

  _onFocusIn() {
    this._toggleMenuOpen(true);
  }

  _toggleMenuOpen(toggle) {
    let show = this.ui.slideoutMenu.hasClass('off-screen');
    if ( !toggle) {
      show = false;
    }
    this.ui.slideoutMenu.toggleClass('off-screen', !show);
  }

  setTitleText(text) {
    if (this.isDestroyed) {
      return;
    }
    this.ui.title.text(text);
    this.ui.title.toggleClass('hidden', text === '');
  }
}

module.exports = HeaderSlideoutLayout;
