const { LayoutView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const BannerDefinitionFactory = require('@common/components/banner/BannerDefinitionFactory');

const isShorter = function(user) {
  return user.getCoachType() === 'NoCoach';
};

module.exports = ({
  brandingChannelModel,
  contentRegion,
  user
} = {}) => {

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LayoutView,
      template: `
        <div class="js-home-page-banner-region home-page-layout__banner <%- shorterClass %>"></div>
        <div class="js-home-page-content-region home-page-layout__content"></div>
      `,
      regions: {
        bannerRegion: '.js-home-page-banner-region',
        contentRegion: '.js-home-page-content-region'
      },
      templateHelpers: {
        shorterClass: isShorter(user) ? 'home-page-layout__banner--shorter' : ''
      }
    },
    regionControllers: {
      bannerRegion: BannerDefinitionFactory({model: brandingChannelModel}),
      contentRegion
    }
  };

};
