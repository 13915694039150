const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'ALL',
  'TOPIC',
  'PATH'
], {
  isValidBrowseType(browseType) {
    return [
      this.ALL,
      this.TOPICS,
      this.PATHS
    ].includes(browseType);
  }
});
