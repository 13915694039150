const renderHeader = (showHeader) => {
  if (showHeader) {
    return `
      <div id="header" class="error-header">
        <div class="wrap clearfix parent-height">
          <div class="logo"></div>
        </div>
      </div>
    `;
  }

  return '';
};

const renderTemplate = (templateData) => {
  const {
    errorDetailsMessage,
    buttonText,
    errorDetails,
    detailsCSSDisplay
  } = templateData;

  return `
    <div class="error-body">
      <div class="full-width relative">
        <h1 class="page-header">
          <div class="ax-container">
            <div class="page-header__content">
              <span class="page-header__title">Oops!</span>
            </div>
          </div>
        </h1>
        <div class="ax-container">
          <div class="page-content">
            <div class="message">
              ${ errorDetailsMessage }
            </div>

            <div class="buttonbar">
              <a href="#"
                class="axon-button retry"
                onclick="window.location.reload(); return false;"
              >${ buttonText }</a>
            </div>

            <div class="error-container" style="display:${ detailsCSSDisplay }">
              <div id="detailsToggle">
                <a class="details-title">Error Details</a>
              </div>
              <div id="error-message-details" class="error-message-wrapper">
                <pre>${ errorDetails }</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

const htmlEncoder = document.createElement('TEXTAREA');

const htmlEncode = (value = '') => {
  htmlEncoder.innerContent = (htmlEncoder.innerText = value);
  return htmlEncoder.innerHTML;
};

const mapTemplateOptions = (options = {}) => {
  return {
    detailsCSSDisplay: options.showDetails ? 'block' : 'none',
    errorDetails: htmlEncode(options.errorDetailsText),
    errorDetailsMessage: options.errorDetailsMessage,
    buttonText: options.buttonText,
    showHeader: options.showHeader
  };
};

class ErrorPage {

  constructor(options = {}) {
    ({
      showDetails: this.showDetails = true,
      errorDetailsText: this.errorDetailsText = '',
      errorDetailsMessage: this.errorDetailsMessage = 'The application suffered an error. Please click Retry to reload the page.',
      buttonText: this.buttonText = 'Retry',
      showHeader: this.showHeader = true
    } = options);
  }

  render() {
    return [
      renderHeader(this.showHeader),
      renderTemplate(mapTemplateOptions(this))
    ].join('');
  }

  toggleDetails() {
    const detailsElement = document.getElementById('error-message-details');

    if (detailsElement.className.indexOf('expanded') === -1) {
      detailsElement.className += ' expanded';
    } else {
      detailsElement.className = detailsElement.className.replace(' expanded', '');
    }
  }
}

module.exports = ErrorPage;
