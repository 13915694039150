// Module
var code = `<div class="page-content">
  <div class="js-how-to-dialog hidden"></div>

  <div class="selectgame">

    <p class="off-screen"><%- t('selectGame.accessibilityNote') %></p>

    <% if (featuredGame) { %>
    <div class="featured">
      <h2 class="featured-title"><%- t('selectGame.section.featured') %></h2>
      <div class="gameslist ax-grid clearfix">
        <div class="ax-grid__col--12 ax-grid__col--l-9">
          <button class="game featured touchable" data-game-id="<%- featuredGame.id %>" aria-label="<%- t('selectGame.section.featured') %> <%- featuredGame.name %>">
            <div class="featured-type <%- featuredGame.type %>"></div>
            <div class="featured-text">
              <span class="featured-name ax-font--no-margin"><%- t('games.' + featuredGame.type) %></span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <% } %>

    <div class="skip-game-wrapper ax-grid">
      <div class="ax-grid__col--12 ax-grid__col--l-9">
        <button class="skip-game reverse">
          <span class="no-games-title-meta ax-font ax-font__caption ax-font__caption--bold" aria-hidden="true" role="presentation"><%- t('selectGame.noGame.title') %></span>
          <span class="no-games-title-meta ax-font ax-font__caption" aria-hidden="true" role="presentation"><%- t('selectGame.noGame.meta') %></span>
          <span class="no-games-title-meta off-screen"><%- t('selectGame.noGame.accessibile') %></span>
          <div class="no-games-arrow"></div>
        </button>
      </div>
    </div>

    <div class="challenges">
      <h2><%- t('selectGame.section.challenges') %></h2>
      <div class="gameslist"></div>
    </div>

    <div class="recent">
      <h2><%- t('selectGame.section.recentlyPlayed') %></h2>
      <div class="gameslist"></div>
    </div>

    <div class="popular">
      <h2><%- t('selectGame.section.popularGames') %></h2>
      <div class="gameslist"></div>
    </div>

    <div class="all-games">
      <h2><%- t('selectGame.section.allGames') %></h2>
      <div class="gameslist"></div>
    </div>

  </div>

</div>
`;
// Exports
module.exports = code;