import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitAvatar(cssVars: CSSStyleDeclaration) {
  return {
    avatar: {
      size: {
        xs: getCssValue('--ax-avatar-size-xs', cssVars),
        sm: getCssValue('--ax-avatar-size-sm', cssVars),
        md: getCssValue('--ax-avatar-size-md', cssVars),
        lg: getCssValue('--ax-avatar-size-lg', cssVars),
        xl: getCssValue('--ax-avatar-size-xl', cssVars)
      }
    }
  };
}
