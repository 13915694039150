const _ = require('underscore');

const AssessmentInitatorContext = require('@common/data/models/assessments/AssessmentInitatorContext');

class GuidedLearningAssessmentInitiatorContext extends AssessmentInitatorContext {
  constructor(programId, timestamp) {
    super();
    this.programId = programId;
    this.timestamp = timestamp || this.timestamp;

    this.validateState();
  }

  validateState() {
    if (!_.isNumber(this.getProgramId())) {
      throw new Error(
        'You must pass in a valid program ID that is numeric from whence you came.'
      );
    }
  }

  getProgramId() {
    return this.programId;
  }

  static getType() {
    return 'GuidedLearningContext';
  }
}

module.exports = GuidedLearningAssessmentInitiatorContext;
