import { extractFragmentChildren } from '@common/libs/helpers/app/ReactHelpers';
import {
  RadioGroup as MuiRadioGroup,
  type RadioGroupProps as MuiRadioGroupProps,
  styled,
  useTheme
} from '@mui/material';
import {
  type Ref,
  cloneElement,
  forwardRef,
  isValidElement
} from 'react';

type AxRadioGroupProps = MuiRadioGroupProps;

const styledOptions = {
  name: 'AxRadioGroup'
};

const StyledAxRadioGroup = styled(MuiRadioGroup, styledOptions)<AxRadioGroupProps>(() => {
  return '';
});

export const AxRadioGroup = forwardRef(({
  children,
  ...otherProps
}: AxRadioGroupProps, ref: Ref<HTMLDivElement>) => {
  const theme = useTheme();
  
  const RadioGroupProps: AxRadioGroupProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxRadioGroup { ...RadioGroupProps } ref={ ref }>
      { // As we removed the margin in form inputs we need to add it here
        // if children are provided and the row prop is set, add spacing between.
        children && otherProps.row ? (
          extractFragmentChildren(children).map((child, index) => {
            return (
              isValidElement(child) ? cloneElement(child, {
                key: index,
                ...child.props,
                sx: {
                  marginRight: theme.uiKit.spacingM,
                  ...child.props.sx
                }
              }) : child
            );
          })
        ) : (
          children
        ) }
    </StyledAxRadioGroup>
  );
});

export default AxRadioGroup;

