const _ = require('underscore');
const Backbone = require('Backbone');
const ReactionsEnum = require('@common/data/enums/ReactionsEnum');

class ReactionsDetailsModel extends Backbone.Model {
  apiEndpoint() {
    return '/userReaction';
  }

  parse(res) {
    const data = res.results;
    const reactionTypes = ['all', ...ReactionsEnum.values()];

    _.each(reactionTypes, (reaction) => {
      data[reaction].reactions = new Backbone.Collection(data[reaction].reactions);
    });

    return data;
  }

  fetch(options = {}) {
    const limit = this.get('limit') || null;
    const pageId = this.get('pageId') || null;
    return super.fetch($.extend(true, {}, { data: { limit, pageId } }, options));
  }
}

module.exports = ReactionsDetailsModel;
