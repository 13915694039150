const I18n = require('@common/libs/I18n');
const logging = require('logging');

const TitleHeaderTypeEnum = require('@common/components/titleHeader/TitleHeaderTypeEnum');
const TitleHeaderDefinitionBuilder = require('@common/components/titleHeader/TitleHeaderDefinitionBuilder');
const GuidedLearningController = require('@training/apps/training/controllers/GuidedLearningController');

const SelfDirectedPathAssessmentInitiatorContext = require('@training/apps/training/controllers/selfDirectedPath/SelfDirectedPathAssessmentInitiatorContext');

const SelfDirectedPathCategoriesList = require('@common/components/guidedLearning/selfDirectedPaths/collections/SelfDirectedPathCategoriesList');
const SelfDirectedPathLayoutView = require('@common/components/guidedLearning/selfDirectedPaths/SelfDirectedPathLayoutView');
const SelfDirectedPathOption = require('@common/data/models/assessments/SelfDirectedPathOption');

const SelfDirectedPathsProgramOverviewController = require('@training/apps/training/views/selfDirectedPaths/programs/SelfDirectedPathsProgramOverviewController');

const SelfDirectedPathNextStartableAssessmentProvider = require('@training/apps/training/controllers/selfDirectedPath/SelfDirectedPathNextStartableAssessmentProvider');


class SelfDirectedPathsController extends GuidedLearningController {
  constructor(parentProcessor, taskPresenterService, eventPresenterService, assessmentFactory) {
    super(parentProcessor, taskPresenterService, eventPresenterService, assessmentFactory);

    this.storageNamespace = 'training/selfDirectedPaths';
    this.urlSlug = 'paths';
    this.enableScrollToFirstButtonAvailable = false;
  }

  getModelCollection() {
    return new SelfDirectedPathCategoriesList([], { sessionModel: this.sessionModel });
  }

  getNextItemProvider(assessmentInitiatorContext) {
    const newSelfDirectedPathCategory = this.modelCollection.add({
      id: assessmentInitiatorContext.getProgramId()
    });
    return new SelfDirectedPathNextStartableAssessmentProvider(newSelfDirectedPathCategory);
  }

  getSessionTrainingType() {
    return 'PathTraining'; // There is no SessionTrainingType for path but we need 'PathTrainingType" to be able render "View {page}" button
  }

  getDiscriminator() {
    return SelfDirectedPathAssessmentInitiatorContext.getType();
  }

  createInitiatorContext(json) {
    return new SelfDirectedPathAssessmentInitiatorContext(json.programId);
  }

  getRootLayout(containsMilestones = false) {
    return new SelfDirectedPathLayoutView({
      continuePredicate: () => {
        return false;
      },
      continueCallback: () => {
        return false;
      },
      containsMilestones
    });
  }

  setTitle(programName = '') {
    const tabTitle = `${ I18n.t('assessments.select.header.title.SelfDirectedTraining') } - ${ programName }`;

    window.app.layout.setTitle(tabTitle);
  }

  _createTitle(program) {
    return new TitleHeaderDefinitionBuilder({
      titleType: TitleHeaderTypeEnum.PRETEXT_TITLE,
      title: program.getName(apps.auth.session.user.get('language')),
      titleClass: 'page-header__title',
      badgeModifierClasses: ['page-header__icon'],
      iconClass: 'icon-path'
    }).build();
  }

  getDetailView() {
    return SelfDirectedPathsProgramOverviewController;
  }

  _getRoutePrefix(programId) {
    return `hub/paths/${ programId }`;
  }

  _getProgramDetails(programId) {
    return this.getCategoriesAvailable().then(() => {
      let program = this.modelCollection.get(programId);

      // fallback: user probably hadn't enrolled in the program so let's load the path directly.
      if (program == null) {
        program = this.modelCollection.add({
          id: programId
        });
      }

      return program.fetch()
        .then(() => {
          return program;
        })
        .fail((xhr) => {
          xhr.skipGlobalHandler = true;

          window.app.layout.flash.error(I18n.t('SelfDirectedPaths.search.notFound'));
          logging.info(`Could not locate the program with id '${ programId }'`);
        });
    });
  }

  getProgramOptions(objective, programId) {
    return new SelfDirectedPathOption(objective, programId);
  }

  getTrainingIconData() {
    return {
      iconClass: 'icon-path',
      iconLabel: I18n.t('assessments.types.SelfDirected')
    };
  }

  getTitleForAssessment() {
    return I18n.t('SelfDirectedPaths.title');
  }
}

module.exports = SelfDirectedPathsController;
