const AxonifyObject = require('@common/libs/AxonifyObject');

/*
  DestroyableObject

  Extension on AxonifyObject to include 'isDestroyed' state and events like everything else in Backbone/Marionette
  land that has destroy functionality.

*/

class DestroyableObject extends AxonifyObject {
  constructor(options) {
    super(options);
    this.isDestroyed = false;
  }

  // Override destroy so we can set isDestroyed = false in between the before and after events
  // which gives it the same behaviour as views.
  destroy(options = {}) {
    this.triggerMethod('before:destroy', this, options);
    this.isDestroyed = true;
    this.triggerMethod('destroy', this, options);
    this.stopListening();
    return this;
  }
}

module.exports = DestroyableObject;
