const SelectableMenuItemView = require('@common/modules/main/menu/item/selectable/SelectableMenuItemView');
const I18n = require('@common/libs/I18n');
const Backbone = require('Backbone');

/**
 * Overrides the basic SelectableMenuItemView to give us a way to show/hide a
 * visual cue to indicate there is new content on that page.
 */
class TimelineSelectableNotifiableMenuItemView extends SelectableMenuItemView {

  constructor(options = {}) {
    super(options);

    this.timelineNotifier = options.timelineNotifier;

    this.listenTo(this.timelineNotifier, 'change', this.render);
  }

  getTemplate() {
    return `
      <a href="<%- href %>" class="js-menu-item-link <%- linkClass %>">
        <span class="item-icon <%- iconClass %>"></span>
        <span class="item-text"><%- text %></span><span class="item-selected-bar"></span>
        <span class="notification<%- hidden %>"></span>
      </a>
    `;
  }

  templateHelpers() {
    const helpers = super.templateHelpers();
    helpers.hidden = this.timelineNotifier.get('hasUpdates') ? '' : ' hidden';
    return helpers;
  }

  ui() {
    return Object.assign(super.ui(), {
      menuItem: '.js-menu-item-link'
    });
  }

  events() {
    return {
      'click .js-menu-item-link': 'onClick'
    };
  }

  onRender() {
    if (this.timelineNotifier.get('hasUpdates')) {
      this.ui.menuItem.attr('aria-label', I18n.t('training.menu.timelineAriaLabel'));
    }
  }

  onClick() {
    Backbone.history.navigate('#!', {
      trigger: false,
      replace: true
    });

    Backbone.history.navigate('#hub/timeline', {
      trigger: true,
      replace: true
    });
  }
}

module.exports = TimelineSelectableNotifiableMenuItemView;
