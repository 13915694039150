// Module
var code = `<h1 class="games-intro-name"><%- t('games.teamGame.scoreCard.title') %></h1>

<table class="podium padding">
  <colgroup>
    <col class="rank"/>
    <col class="team-name"/>
    <col class="wins"/>
    <col class="losses"/>
  </colgroup>
  <tr>
    <th colspan="2" class="team-rank"><%- t('games.teamGame.scoreCard.header.teamRank') %></th>
    <th class="wins text-center no-padding"><%- t('games.teamGame.scoreCard.header.wins') %></th>
    <th class="losses text-center no-padding"><%- t('games.teamGame.scoreCard.header.losses') %></th>
  </tr>
  <% if (scoreCard.length > 0) { %>
    <% _.each(scoreCard, function(teamRank){ %>
    <tr>
      <td class="rank"><%- teamRank.rank + 1 %></td>
      <td class="team text-ellipsis-softwrap"><%- teamRank.name %></td>
      <td class="wins text-center no-padding"><%- teamRank.data.wins %></td>
      <td class="losses text-center no-padding"><%- teamRank.data.losses %></td>
    </tr>
    <% }) %>
  <% } else { %>
    <tr>
      <td colspan="4">
        <h2 class="no-scores"><%- t('games.teamGame.scoreCard.noScores') %></h2>
      </td>
    </tr>
  <% } %>
</table>

<% if (questionsRemaining > 0) { %>
  <h2 class="questionsleft">
    <% if(questionsRemaining == 1) {%>
      <%- t('games.gameover.remainingquestion') %>
    <% } else { %>
      <%- t('games.gameover.remainingquestions', {questionsleft: questionsRemaining}) %>
    <% } %>
  </h2>
<% } %>

`;
// Exports
module.exports = code;