const colors = [
  '#3761A2',
  '#46a3fe',
  '#FF5A0C',
  '#73B744',
  '#FE4493',
  '#CC60FA',
  '#8D3A26',
  '#1565E4',
  '#935468',
  '#FC6A62',
  '#8C249B',
  '#9D2768',
  '#DE779A',
  '#594DAA',
  '#D48110',
  '#F854BA',
  '#D683FB',
  '#FC5476',
  '#942438',
  '#514979',
  '#2F6A24',
  '#AC0F81',
  '#8A4A11',
  '#6F4CC4',
  '#FD7BB8',
  '#0C74E1',
  '#F18432'
];

class AxonifySeriesColorMapperStrategy {
  _current = 0;

  next() {
    const currentColor = colors[this._current++];
    this._current %= colors.length;
    return currentColor;
  }
}

module.exports = AxonifySeriesColorMapperStrategy;
