const Marionette = require('Marionette');

const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');

const create = (header, content) => {
  const formattedHTML = applyVideoPlaceholdersHTML(content);
  return new StackedCollectionView({
    className: 'article-print-form',
    viewConfigs: [
      getHeaderView(header.model),
      getContributorHeaderView(content.model.contributorList),
      getContentView(formattedHTML)
    ]
  });
};

const getHeaderView = (model) => {
  return {
    viewClass: Marionette.ItemView,
    viewOptions: {
      tagName: 'h1',
      model,
      className: 'print-article-header',
      template: '<%= title %>'
    }
  };
};

const getContributorHeaderView = (contributorList) => {
  return {
    viewClass: Marionette.CompositeView,
    viewOptions: {
      className: 'print-article-contributor-header',
      collection: contributorList,
      template: `\
<h3 class="print-contributor-header"> <%- t('discover.print.contributors') %> </h3>
<div class="print-contributor-list">
    <ul id="quad-column"></ul>
</div>\
`,
      childViewContainer: '#quad-column',
      childViewOptions: {
        tagName: 'li',
        template: '<%= fullname %>'
      }
    }
  };
};

const getContentView = (contentHtml) => {
  return {
    viewClass: Marionette.ItemView,
    viewOptions: {
      className: 'print-article-content',
      template: contentHtml
    }
  };
};

const applyVideoPlaceholdersHTML = (content) => {
  const $formattedEl = content.$el.clone();
  $formattedEl.find('.page__media.media--video, .page__media.media--embeddedVideo')
    .each((index, el) => {
      const mediaId = $(el).attr('data-media-id');
      // replace video with placeholder image
      const divPlacholder = $('<div>').addClass('video-placeholder-print__icon')
        .attr('data-media-id', mediaId);
      $(el).empty()
        .addClass('media--image video-placeholder-print')
        .removeClass('media--video media--embeddedVideo')
        .append(divPlacholder);
    });
  return $formattedEl.html();
};

module.exports = {
  create
};
