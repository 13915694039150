/*
  This file was originally in admin/libs, but has been moved to common as a direct result of SE-18519 which
  requires the use of bulkImport.
*/

// Waits till file is done processing
const pollTillDone = function(uuid) {
  const _defer = $.Deferred();

  const poll = () => {
    return $.ajax({
      type: 'GET',
      url: `/axonify/media/file/${ uuid }`,
      success(res) {
        if (res.status !== 'done') {
          return setTimeout(poll.bind(), 1000);
        }
        return _defer.resolve(uuid);
      },
      error: _defer.reject
    });
  };
  poll();

  return _defer.promise();
};

// Ask server to import the file
const bulkImport = (type, id) => {
  return $.ajax({
    type: 'POST',
    apiEndpoint: '/bulkimport',
    data: JSON.stringify({
      fileId: `${ id }`,
      type
    })
  });
};

module.exports = {pollTillDone, bulkImport};
