const $os = require('detectOS');
const logging = require('logging');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');

const GameChallengesUsersList = require('../collections/GameChallengesUsersList');
const MultiColumnListView = require('./MultiColumnListView');
const ChallengeUserCardView = require('./ChallengeUserCardView');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class EmptyChallengesUsersListView extends UIKit.View {
  template = _.tpl('<div class="no-users"><%- t(\'selectGame.gameChallenge.emptyList\') %></div>');

  tagName = 'li';

  render() {
    this.$el.html(this.template());
    return this;
  }
}

class ChallengeSelectionView extends UIKit.View {
  template = _.tpl(require('../templates/ChallengeSelectionView.html'));

  constructor(...args) {
    super(...args);

    this.onSearch = this.onSearch.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this._renderChallengesUserList = this._renderChallengesUserList.bind(this);

    this.gameChallengesUsersList = new GameChallengesUsersList();
  }

  ui() {
    return {
      wagerCheckbox: 'input[type="checkbox"]',
      wagerCard: '.js-wager-card'
    };
  }

  events() {
    return {
      'click @ui.wagerCheckbox': 'toggleWagerCard'
    };
  }

  render() {
    const tenantStore = TenantPropertyProvider.get();
    const gameChallengeWager = tenantStore.getProperty('gameChallengeWager') || 0;
    this.$el.html(this.template({gameChallengeWager}));
    this.searchBox = new UIKit.Autocomplete({
      el: this.$('.user-search'),
      minLength: 1
    });

    this._renderChallengesUserList();

    return this;
  }

  viewDidAppear() {
    logging.info('ChallengeSelectionView - viewDidAppear');

    this.gameChallengesUsersList.fetch();

    this.listenTo(this.searchBox, 'autocomplete search', this.onSearch);
    this.listenTo(this.searchBox, 'clearSearch', this.onClearSearch);
  }

  onSearch(query) {
    this.gameChallengesUsersList.search(query);

    if (query != null) {
      this.$('#number-of-results').text(I18n.t('search.numberOfResultsFor', {
        number: this.gameChallengesUsersList.length,
        query
      }));

      this.$('[type=search]').attr('aria-labelledby', 'number-of-results');
    }
  }

  onClearSearch() {
    this.$('#number-of-results').text('');
    this.$('[type=search]').removeAttr('aria-labelledby')
      .trigger('focus');
    this.gameChallengesUsersList.clearSearch();
  }

  _renderChallengesUserList() {
    logging.info('Rendering Challenge Selection User List');

    const numColumns = $os.mobile ? 1 : 2;

    this.challengesUserListView = new MultiColumnListView({
      collection: this.gameChallengesUsersList,
      columns: numColumns,
      el: this.$('.challengee-list'),
      EmptyView: EmptyChallengesUsersListView,
      ModelView: ChallengeUserCardView
    });

    this.listenTo(this.challengesUserListView, 'user:challenge:selected', (listItemView, model) => {
      const includeWager = this.$('input[type="checkbox"]').prop('checked');
      this.trigger('user:challenge:selected', model, includeWager);
    });

    this.challengesUserListView.render();
  }

  toggleWagerCard() {
    this.$(this.ui.wagerCard).toggleClass('challenge__wager-card--selected', this.$(this.ui.wagerCheckbox).prop('checked'));
  }

  onClose(...args) {
    if (this.searchBox != null) {
      this.searchBox.destroy();
    }
    if (this.challengesUserListView != null) {
      this.challengesUserListView.unload();
    }
    this.gameChallengesUsersList.abortXHR(Sync.Method.READ);
    super.onClose(...args);
  }
}

module.exports = ChallengeSelectionView;
