const LEFT_CONTROLLER_DEFINITION = 'leftHeaderControllerDefinition';
const RIGHT_CONTROLLER_DEFINITION = 'rightHeaderControllerDefinition';
const CHAT_CONTROLLER_DEFINITION = 'chatControllerDefinition';
const LOGO_VISIBILITY = 'headerLogoVisibility';
const HEADER_DESKTOP_HIDEABLE = 'headerDesktopHideable';

module.exports = {
  Keys: {
    LEFT_CONTROLLER_DEFINITION,
    RIGHT_CONTROLLER_DEFINITION,
    CHAT_CONTROLLER_DEFINITION,
    LOGO_VISIBILITY,
    HEADER_DESKTOP_HIDEABLE
  },

  defaults() {
    return {
      [LEFT_CONTROLLER_DEFINITION]: {},
      [RIGHT_CONTROLLER_DEFINITION]: {},
      [CHAT_CONTROLLER_DEFINITION]: {},
      [LOGO_VISIBILITY]: false,
      [HEADER_DESKTOP_HIDEABLE]: false
    };
  },

  getLeftControllerDefinition(contextModel) {
    return contextModel.get(LEFT_CONTROLLER_DEFINITION);
  },

  getRightControllerDefinition(contextModel) {
    return contextModel.get(RIGHT_CONTROLLER_DEFINITION);
  },

  getChatControllerDefinition(contextModel) {
    return contextModel.get(CHAT_CONTROLLER_DEFINITION);
  },

  setLeftHeaderViewDefinition(contextModel, viewDefinition = {}, options = {}) {
    contextModel.set(LEFT_CONTROLLER_DEFINITION, { viewDefinition }, options);
  },

  setRightHeaderControllerDefinition(contextModel, controllerDefinition = {}, options = {}) {
    contextModel.set(RIGHT_CONTROLLER_DEFINITION, controllerDefinition, options);
  },

  setChatHeaderControllerDefinition(contextModel, controllerDefinition = {}, options = {}) {
    contextModel.set(CHAT_CONTROLLER_DEFINITION, controllerDefinition, options);
  },

  getLogoVisibility(contextModel) {
    return contextModel.get(LOGO_VISIBILITY);
  },

  setLogoVisibility(contextModel, visible) {
    contextModel.set(LOGO_VISIBILITY, visible);
  },

  setHeaderDesktopHideable(contextModel, visible) {
    contextModel.set(HEADER_DESKTOP_HIDEABLE, visible);
  },

  getHeaderDesktopHideable(contextModel) {
    return contextModel.get(HEADER_DESKTOP_HIDEABLE);
  }
};
