const { ItemView } = require('Marionette');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');

class UserProfileIcon extends ItemView {

  getTemplate() {
    return '<div class="user-profile-icon" aria-label="<%- t("training.menu.profile") %>"></div>';
  }

  ui() {
    return {
      icon: '.user-profile-icon'
    };
  }

  modelEvents() {
    return {
      change: 'onChangeModel'
    };
  }

  onRender() {
    this.iconHandler = new ProfileIconHandler({
      user: this.model.toJSON(),
      profileElement: this.ui.icon
    });
  }

  onChangeModel(model) {
    this.iconHandler.updateImage(model.toJSON(), this.ui.icon);
  }
}

module.exports = UserProfileIcon;
