const {
  DailyTraining,
  ExtraTraining,
  CertificationTraining,
  IntroductoryTraining,
  RefresherTraining,
  FormalExamTraining
} = require('@common/data/enums/AssessmentType');

const {
  Assessment,
  DailyTrainingAssessment,
  ExtraTrainingAssessment,
  IntroductoryTrainingAssessment,
  CertificationTrainingAssessment,
  RefresherTrainingAssessment,
  FormalExamTrainingAssessment
} = require('@common/data/models/assessments/Assessment');

const assessmentTypeClassMap = {
  [DailyTraining]: DailyTrainingAssessment,
  [ExtraTraining]: ExtraTrainingAssessment,
  [CertificationTraining]: CertificationTrainingAssessment,
  [IntroductoryTraining]: IntroductoryTrainingAssessment,
  [RefresherTraining]: RefresherTrainingAssessment,
  [FormalExamTraining]: FormalExamTrainingAssessment
};

module.exports = {
  create(assessmentType, attrs = {}, options = {}) {
    const AssessmentModel = assessmentTypeClassMap[assessmentType] || Assessment;
    return new AssessmentModel(attrs, options);
  },

  createFromJson(attrs = {}, options = {}) {
    return this.create(attrs.type, attrs, options);
  }
};
