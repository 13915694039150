const logging = require('logging');
const UploadHandler = require('./UploadHandler');

const XhrHeaders = require('XhrHeaders');

// Class for uploading files using xhr
class UploadHandlerXhr extends UploadHandler {

  // Is XHR2 supported by the browser?
  static isSupported() {
    const $input = $('<input type=\'file\'>');
    return ('multiple' in $input[0]) && (typeof File !== 'undefined' && File !== null) && (typeof FormData !== 'undefined' && FormData !== null) && ((new XMLHttpRequest()).upload != null);
  }

  constructor() {
    super(...arguments);

    this.files = [];
    this.xhrs = [];

    // Current loaded size in bytes for each file
    this.loaded = [];
  }

  add(file) {
    if (!(file instanceof File)) {
      throw new Error('Passed obj is not a File.');
    }
    return this.files.push(file) - 1;
  }

  getName(id) {
    const file = this.files[id];
    return file.fileName != null ? file.fileName : file.name;
  }

  getSize(id) {
    const file = this.files[id];
    return file.fileSize != null ? file.fileSize : file.size;
  }

  // Return uploaded bytes for file identified by id
  getLoaded(id) {
    return this.loaded[id] != null ? this.loaded[id] : 0;
  }

  // Send the file to the server
  _upload(id) {
    this.onUpload(id, this.getName(id), true);

    let file = this.files[id];
    const name = this.getName(id);
    this.loaded[id] = 0;

    const xhr = (this.xhrs[id] = new XMLHttpRequest());
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        this.loaded[id] = e.loaded;
        this.onProgress(id, name, e.loaded, e.total);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        this._onComplete(id, xhr);
      }
    };

    xhr.open('POST', this.url, true);
    xhr.setRequestHeader(XhrHeaders.XSRF, 'X');

    const formData = new FormData();
    formData.append(this.fileInputName, file);
    // `fileType` is used for the old upload API endpoint, and is not required
    // for upload paths like `media/upload`
    if (this.fileType != null) {
      formData.append('filetype', this.fileType);
    }
    file = formData;
    xhr.send(file);
  }

  _onComplete(id, xhr) {
    // The request was aborted/cancelled
    if (!this.files[id]) {
      return;
    }

    const name = this.getName(id);
    const size = this.getSize(id);

    this.onProgress(id, name, size, size);

    if (xhr.status === 200) {
      logging.debug(`xhr - server response received: ${ xhr.responseText }`);
      if (xhr.responseText.length > 0) {
        this.onComplete(id, name, JSON.parse(xhr.responseText));
      } else {
        this.onComplete(id, name, {});
      }
    } else {
      this.onError(id, name, xhr);
    }

    this.files[id] = null;
    this.xhrs[id] = null;
    this._dequeue(id);
  }

  _cancel(id) {
    if (this.files[id] != null) {
      this.onCancel(id, this.getName(id));
      this.files[id] = null;
    }

    if (this.xhrs[id]) {
      this.xhrs[id].abort();
      this.xhrs[id] = null;
    }
  }
}

module.exports = UploadHandlerXhr;
