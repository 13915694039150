const logging = require('logging');

const _ = require('underscore');
const { Collection } = require('Backbone');
const { CollectionView } = require('Marionette');

const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const ActionBarType = require('@common/components/actionBarButton/ActionBarType');

const LoadingController = require('@common/components/loading/LoadingController');

const SelectCoachPage = require('@training/apps/training/coachSelection/SelectCoachPage');
const CoachItemView = require('@training/apps/training/coachSelection/CoachItemView');


class SelectCoachController extends LayoutController {

  constructor(options = {}) {
    super(options);

    (({
      actionBar: this.actionBar,
      onComplete: this.onComplete = () => {},
      selectedCoachId: this.selectedCoachId,
      coachList: this.coachList = new Collection(),
      pageTitle: this.pageTitle = '',
      actionButtonType: this.actionButtonType = ActionBarType.PROFILE_SAVE
    } = options));

    this.viewDefinition = this.viewDefinition.bind(this);

    this.coachesStylesTemplate = _.tpl(require('@common/components/coaches/CoachesTilesStyles.html'));
  }

  viewDefinition() {
    return {
      ViewClass: SelectCoachPage,
      templateHelpers: () => {
        return {
          pageTitle: this.pageTitle
        };
      }
    };
  }

  regionControllers() {
    return {
      coachesListRegion: {
        ViewControllerClass: LoadingController.CollectionView,
        syncTracker: {
          entities: this.coachList,
          loadOnSync: true
        },
        contentControllerDefinition: {
          viewDefinition: {
            ViewClass: CollectionView,
            collection: this.coachList,
            className: 'clearfix ax-grid',
            attributes: {
              role: 'radiogroup',
              'aria-label': I18n.t('coaches.title')
            },
            childView: CoachItemView,
            childEvents: {
              'select:coach': this.onSelectCoach.bind(this)
            },
            collectionEvents: {
              'update reset': this.onCoachesListUpdate.bind(this),
              'selection:updated': this.onSelectionUpdated.bind(this)
            }
          }
        }
      }
    };
  }

  onViewInflate(controller, view) {
    window.apps.base.timeLogController.bindPageViewLog(view, 'CoachSelection');
  }

  onViewShow() {
    logging.info('SelectCoachPage - viewDidAppear');
    window.app.layout.setTitle(I18n.t('coaches.title'));
    this.setActionButton();
    this.onCoachesListUpdate();
  }

  onDestroy() {
    this.resetActionButton();
  }

  onSelectCoach(childView, { model }) {
    this.coachList.setSelected(model);
  }

  onSelectionUpdated(collection) {
    const model = collection.getSelected();
    this.setActionButton(model.id === this.selectedCoachId);
  }

  onCoachesListUpdate() {
    if (this.coachList.length > 0) {
      this.reloadCoachesStylesFile(this.coachList.toArray());
      BrowserHelpers.triggerResize(true);
    }
  }

  setActionButton(disabled = true) {
    this.actionBar.setActionButton([{
      type: this.actionButtonType,
      onClick: this.onSave.bind(this),
      disabled
    }]);
  }

  resetActionButton() {
    this.actionBar?.setActionButton(null);
  }

  onSave() {
    this.setActionButton();

    const coach = this.coachList.getSelected();
    coach.save().then(() => {
      window.apps.base.timeLogController.stopPageViewCoachSelection(this.timeLogId, coach.id);
      window.apps.auth.session.user.set('coach', coach.toJSON());

      this.selectedCoachId = coach.id;

      this.onComplete();
    }, () => {
      window.app.layout.flash.error(I18n.t('errors.genericError'));
      this.setActionButton(false);
    });
  }

  reloadCoachesStylesFile(coaches) {
    //reload coach style file
    $('#custom-coaches-style').remove();

    this.coachesStyles = this.coachesStylesTemplate({
      id: 'custom-coaches-style',
      coaches,
      selectCoachUrl(coach) {
        const imageMedia = coach.get('customHelloImage');
        if (imageMedia && (imageMedia.sizes != null)) {
          return {
            coachUrl: imageMedia.sizes[0].file.path,
            retinaCoachUrl: imageMedia.sizes[1].file.path
          };
        }
        const url = coach.get('downloadForHello');
        return {
          coachUrl: UrlHelpers.coachUrl(url),
          retinaCoachUrl: UrlHelpers.retinaCoachUrl(url)
        };

      },
      getCssClass(coach) {
        return `.selectcoach .coacheslist .type.coach${ coach.get('id') }`;
      }
    });

    $('head').append($(this.coachesStyles));
  }
}

module.exports = SelectCoachController;
