const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const ReactionsController = require('@training/apps/search/ReactionsController');
const ArticleDetailsCommentRegionController = require('@training/apps/articles/ArticleDetailsCommentRegionController');
const PageStatus = require('@common/components/discover/enums/PageStatus');

class ContentRegionController extends LayoutController {
  initialize(options = {}) {
    ({
      viewClass: this.viewClass,
      model: this.articleData,
      sessionUser: this.sessionUser
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
    this.regionControllers = this.regionControllers.bind(this);
  }

  scrollToFormInput() {
    this.findControllerByRegion('commentsRegion').scrollToCommentForm();
  }

  onViewImageLoaded() {
    const commentsRegionController = this.findControllerByRegion('commentsRegion');
    if (commentsRegionController) {
      commentsRegionController.scrollToCommentForm(true);
    }
  }

  viewDefinition() {
    return {
      ViewClass: this.viewClass,
      model: this.articleData,
      sessionUser: this.sessionUser
    };
  }

  regionControllers() {
    if (this.articleData.getStatus() !== PageStatus.FACT) {
      // we should not show reactions or comments on a Pending page
      return {};
    }

    const contentRegionControllers = {
      reactionsRegion: {
        ViewControllerClass: ReactionsController,
        favoriteModel: this.articleData,
        reactionsModel: this.articleData,
        showComments: true, // show the call-to-action button in the reaction tray
        showFavorites: true, // show faves
        // If there is a better way of determing whether you're on an article details page vs a search result page, please
        // adjust this to suit!
        showReactionsModal: Boolean(this.articleData.get('id')),
        delegateEvents: {
          'view:scroll:comments': this.scrollToFormInput.bind(this)
        }
      }
    };

    if (this.articleData.get('isCommentable')) {
      contentRegionControllers.commentsRegion = {
        ViewControllerClass: ArticleDetailsCommentRegionController,
        articleData: this.articleData,
        showComments: true // show the actual collection of comments
      };
    }

    return contentRegionControllers;
  }

}

module.exports = ContentRegionController;
