const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

const CommunitySettingsButtonOption = {
  buttonText: 'discover.browse.communitiesManagement',
  buttonClass: 'community-settings',
  buttonIcon: 'icon-settings',
  buttonAriaLabel: 'discover.browse.communitiesManagement',
  buttonLink: `${ SearchUrlHelper.BASE_SEARCH_HASH }/communitiesManagement/communities/1`
};

const ReportsButtonOption = {
  buttonText: 'discover.browse.reports.title',
  buttonClass: 'reports',
  buttonIcon: 'page-preview__icon icon-reports',
  buttonAriaLabel: 'discover.browse.reports.title',
  buttonLink: `${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/reports`
};

const CommunityInsightsButtonOption = {
  buttonText: 'discover.browse.insights.title',
  buttonClass: 'item-community-insights',
  buttonIcon: 'page-preview__icon icon-metrics',
  buttonAriaLabel: 'discover.browse.insights.title',
  buttonLink: `${ SearchUrlHelper.BASE_SEARCH_HASH }/communicationsInsights`
};

module.exports = {
  CommunitySettingsButtonOption,
  ReportsButtonOption,
  CommunityInsightsButtonOption
};
