const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const Backbone = require('Backbone');

const { canDownload } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const pdfClicked = (mediaId, articleId, currentVersion) => {
  const documentJson = _.find( ((currentVersion || {}).richContent || {}).media || [], (media) => {
    return media.id === mediaId;
  });

  if (!documentJson) { //media is invalid.  Possibly deleted
    window.app.layout.flash.error(I18n.t('discover.pageTypes.fetch.error'));

  } else {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ articleId }/${ mediaId }`, {
      replace: false,
      trigger: true
    });
  }
};

const ExternalLinkMediaHandler = {
  handlePdfClicked: pdfClicked,
  handleExternalLinkMedia: (e, articleId, currentVersion) => {
    // if mobile in app, just show flash message saying that we
    // don't support download in-app
    const $currentTarget = $(e.currentTarget);

    const download = $currentTarget.attr('download');
    const mediaId = $currentTarget.data('media-id');

    if ((mediaId != null) && (download == null)) {
      e.preventDefault();
      e.stopPropagation();
      pdfClicked(mediaId, articleId, currentVersion);
    }

    if (!canDownload() && (download != null)) {
      e.preventDefault();
      e.stopPropagation();
      window.app.layout.flash.error(I18n.t('flash.downloadNotSupported'));
    }
  }
};

module.exports = ExternalLinkMediaHandler;
