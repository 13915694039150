const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const I18n = require('@common/libs/I18n');
const ProgressBarFactory = require('@common/components/progress/progressBar/ProgressBarViewConfigFactory');
const FileFactory = require('@common/libs/file/FileFactory');

const SubmissionMediaPreview = require('@training/apps/training/views/evaluations/submissionModal/SubmissionMediaPreview');
const EvaluationSubmissionPreviewView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionPreviewView');
const EvaluationSubmissionPreviewDetailsView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionPreviewDetailsView');
const EvaluationSubmissionUploadFileLinkView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionUploadFileLinkView');

class EvaluationSubmissionPreviewController extends LayoutController {

  initialize(options = {}) {
    ({
      evaluationData: this.evaluationData,
      fileChangeHandler: this.fileChangeHandler = () => {}
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: EvaluationSubmissionPreviewView
    };
  }

  regionControllers() {
    const mediaModel = this.evaluationData.getMedia();

    const regionControllers = {
      displayRegion: this._getDisplayRegionController(mediaModel),
      detailsRegion: this._getDetailsRegionController(mediaModel),
      progressRegion: this._getProgressRegionController(),
      reuploadRegion: this._getReuploadRegionController()
    };

    return regionControllers;
  }

  _getDisplayRegionController(model) {
    return {
      viewDefinition: {
        ViewClass: SubmissionMediaPreview,
        model
      }
    };
  }

  _getDetailsRegionController(model) {
    return {
      viewDefinition: {
        ViewClass: EvaluationSubmissionPreviewDetailsView,
        model
      }
    };
  }

  _getProgressRegionController() {
    return {
      ViewControllerClass: LayoutController,
      viewDefinition: ProgressBarFactory.getProgressBarViewDefinition({
        viewClassName: 'upload-progress',
        message: I18n.t('GuidedLearning.evaluation.details.percentUploaded', { percent: 100 })
      }),
      regionControllers: ProgressBarFactory.getProgressBarRegionControllers({
        value: 100,
        animate: true
      })
    };
  }

  _getReuploadRegionController() {
    return {
      viewDefinition: {
        ViewClass: EvaluationSubmissionUploadFileLinkView,
        fileFactory: FileFactory,
        fileOptions: {
          documentsAllowed: true
        }
      },
      delegateEvents: {
        'view:select:file': (controller, view, file) => {
          this.fileChangeHandler(file);
        }
      }
    };
  }
}

module.exports = EvaluationSubmissionPreviewController;
