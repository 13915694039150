const Marionette = require('Marionette');
const _ = require('underscore');
const logging = require('logging');

const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');

class StatefulRegion extends Marionette.Region {
  constructor(options = {}) {
    super(options);
    this.storage = options.storage || LocalStorageHelpers.getStateStorageObject();
  }

  show(view, options = {}) {
    this.restoreState = options.restoreState != null ? options.restoreState : true;
    super.show(view, options);
    delete this.restoreState;
  }

  saveInstanceState(view = this.currentView) {
    try {
      logging.info('Save instance state for current view.');
      if (_.isFunction(view.saveInstanceState)) {
        view.saveInstanceState(this.storage);
      }
    } catch (e) {
      logging.warn(e);
    }
  }

  restoreInstanceState(view) {
    try {
      logging.info('Restore instance state for new view.');
      if (_.isFunction(view.restoreInstanceState)) {
        view.restoreInstanceState(this.storage);
      }
    } catch (e) {
      logging.warn(e);
    }
  }

  empty(options = {}) {
    this.saveInstanceState();
    super.empty(options);
  }

  attachHtml(view) {
    super.attachHtml(view);
    if (this.restoreState) {
      this.restoreInstanceState(view);
    }
  }
}
module.exports = StatefulRegion;
