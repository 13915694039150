const Backbone = require('Backbone');
const Community = require('@common/data/models/Community');
const Tag = require('@common/components/discover/models/Tag');

class FacetList extends Backbone.Collection {
  model(attrs = {}, options = {}) {
    const FacetModel = (() => {
      switch (attrs.type) {
        case 'community': return Community;
        case 'tag': return Tag;
        default: return Backbone.Model;
      }
    })();
    return new FacetModel(attrs, options);
  }
}

module.exports = FacetList;
