// Module
var code = `<div class="left-header-region pull-left ax-grid__col--l-auto-size"></div>

<div class="center-header ax-grid__col--6 ax-grid__col--l ax-grid ax-grid--no-gutter">
  <div class="logo ax-grid__col--auto-size ax-grid__col--align-self-center qa-header-logo" alt="<%- t('header.logo.alt') %>"></div>
  <div class="menu-region" aria-label="<%- t('menu.main') %>"></div>
  <div class="menu-overflow-region parent-heightlogo ax-grid__col--auto-size"></div>
</div>

<div class="right-header-region ax-grid__col--l-auto-size ax-grid ax-grid--no-gutter ax-grid--justify-content-end">
  <div class="js-chat-header-nav-button-region ax-grid__col--auto-size chat-menu-nav"></div>
  <div class="header-profile-dropdown-region ax-grid__col--auto-size ax-grid ax-grid--align-items-center ax-grid--justify-content-end"></div>
</div>

<div class="off-screen-menu-measurements ax-grid__col--auto-size" aria-hidden="true"></div>
`;
// Exports
module.exports = code;