const AssessmentType = require('@common/data/enums/AssessmentType');

const topicSearchCriteria = (assessmentOption) => {
  return {
    type: assessmentOption.getForAssessmentType(),
    topicId: assessmentOption.getTopicId(),
    level: assessmentOption.getTopicLevel()
  };
};

const assessmentTypeSearchCriteria = (assessmentOption) => {
  return {
    type: assessmentOption.getForAssessmentType()
  };
};

const formalExamSearchCriteria = (assessmentOption) => {
  return {
    type: assessmentOption.getForAssessmentType(),
    programId: assessmentOption.get('program').id
  };
};

const assessmentCriteriaMapping = {
  [AssessmentType.CertificationTraining]: topicSearchCriteria,
  [AssessmentType.RefresherTraining]: topicSearchCriteria,
  [AssessmentType.IntroductoryTraining]: topicSearchCriteria,
  [AssessmentType.ExtraTraining]: topicSearchCriteria,
  [AssessmentType.FormalExamTraining]: formalExamSearchCriteria,
  [AssessmentType.DailyTraining]: assessmentTypeSearchCriteria,
  [AssessmentType.TaskTraining]: assessmentTypeSearchCriteria,
  [AssessmentType.EventEnrollment]: assessmentTypeSearchCriteria,
  [AssessmentType.EvaluationTraining]: assessmentTypeSearchCriteria
};

module.exports = {
  createCriteria: (assessmentOption) => {
    const assessmentType = assessmentOption.getForAssessmentType();
    return assessmentCriteriaMapping[assessmentType](assessmentOption);
  }
};
