const _ = require('underscore');
const Cocktail = require('backbone.cocktail');
const { ChildViewContainer } = require('Backbone');
const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');
const CommunityCollectionView = require('@common/components/filterableCommunity/CommunityCollectionView');
const KeyboardNavigationScrollHelper = require('@common/components/filterableCommunity/KeyboardNavigationScrollHelper');

class MultiselectCommunityCollectionView extends CommunityCollectionView {
  attributes() {
    return {
      'aria-activedescendant': ''
    };
  }

  initialize(options = {}) {
    super.initialize(options);
    ({
      committedSelections: this.committedSelections,
      inProgressSelections: this.inProgressSelections
    } = options);

    this.keyboardNavigationScrollHelper = new KeyboardNavigationScrollHelper();
    this.childrenModels = new ChildViewContainer();

    this.checkSelected = this.checkSelected.bind(this);
    this.shouldLoadMorePages = this.shouldLoadMorePages.bind(this);
  }

  onAttach() {
    this.$scrollContainer = this.$el.closest('.filtered-communities-list__wrapper');
  }

  addChild(viewConfig, ConfigView, index) {
    const view = super.addChild(viewConfig, ConfigView, index);
    this.childrenModels.add(view, viewConfig.id);
    return view;
  }

  // see CommunityCollectionView shouldLoadMorePages for more details
  shouldLoadMorePages(scrollContainer) {
    const dropdownContentHeight = scrollContainer.get(0).scrollHeight;
    const childBuffer = 4 * scrollContainer.find('.filtered-communities-list li') // we want a buffer equal to 4 * the height of the first item in the list
      .first()
      .outerHeight(true);
    const scrollTopTriggerHeight = scrollContainer.scrollTop() + childBuffer + scrollContainer.outerHeight();

    return scrollTopTriggerHeight > dropdownContentHeight;
  }

  fetchSuccess(response) {
    if (this.noMorePages) {
      return;
    }

    const viewItems = response.results;
    if (viewItems.length < this.rpp && this.collection.length !== 0) {
      this.noMorePages = true;
    }

    if (this.noResultsHandler) {
      this.noResultsHandler(this.collection.length === 0);
    }
  }

  checkSelected() {
    // parse through all children to find models in the inProgressSelections collection
    this.children.each((modelView) => {
      const id = modelView.model.get('id');

      if (_.isFunction(modelView.setSelected)) {
        modelView.setSelected(this.inProgressSelections.findWhere({ id }) != null);
      }
    });
  }

  toggleSelectAllOption(showSelectAllOption) {
    this.showSelectAllOption = showSelectAllOption;
  }

  highlight(model, shouldHighlight) {
    const row = $(`#${ model.get('id') }`).closest('li');

    if (shouldHighlight) {
      this.keyboardNavigationScrollHelper.adjustDropdownScrollTop($('.filtered-communities-list__wrapper'), row);
      row.addClass('highlight');
    } else {
      row.removeClass('highlight');
    }
  }

  setActiveDescendant(id) {
    this.$el.attr('aria-activedescendant', id);
  }

  checkModel(id) {
    // cid could differ so need to find by idl; id must be declared when adding the child view
    const modelView = this.childrenModels.findByCustom(id);
    modelView.setSelected(this.inProgressSelections.findWhere({ id }) != null);
  }
}

Cocktail.mixin(MultiselectCommunityCollectionView, InfiniteScrollViewMixin);

module.exports = MultiselectCommunityCollectionView;
