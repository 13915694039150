const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

const REWARD_CSS_CLASSES = {
  POINTS_AVAILABLE: 'task-points-available',
  POINTS_EARNED: 'task-points-earned'
};

class TaskDetailHeaderView extends Marionette.ItemView {
  getTemplate() {
    return require('@training/apps/legacyTasks/views/taskDetail/TaskDetailHeaderView.html');
  }

  className() {
    return 'task-detail-header';
  }

  templateHelpers() {
    const msgInfo = this._getRewardPointsMsgInfo();

    return {
      rewardPointsMsg: msgInfo.msg,
      rewardPointsCssClass: msgInfo.cssClass
    };
  }

  _getRewardPointsMsgInfo() {
    const pointsAvailable = this.model.get('pointsAvailable');
    const pointsEarned = this.model.get('pointsEarned');

    if (pointsAvailable === 1) {
      return {
        cssClass: REWARD_CSS_CLASSES.POINTS_AVAILABLE,
        msg: I18n.t('tasks.summary.rewardPointsSingular')
      };
    } else if (pointsAvailable > 1) {
      return {
        cssClass: REWARD_CSS_CLASSES.POINTS_AVAILABLE,
        msg: I18n.t('tasks.summary.rewardPointsPlural', {numRewardPoints: pointsAvailable})
      };
    } else if (pointsEarned === 1) {
      return {
        cssClass: REWARD_CSS_CLASSES.POINTS_EARNED,
        msg: I18n.t('tasks.summary.rewardPointsEarnedSingular')
      };
    } else if (pointsEarned > 1) {
      return {
        cssClass: REWARD_CSS_CLASSES.POINTS_EARNED,
        msg: I18n.t('tasks.summary.rewardPointsEarnedPlural', {numRewardPoints: pointsEarned})
      };
    }

    return {
      cssClass: '',
      msg: ''
    };
  }

}

module.exports = TaskDetailHeaderView;
