const { ItemView } = require('Marionette');

const I18n = require('@common/libs/I18n');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

class PageInsightsEngagementHeaderView extends ItemView {
  initialize(options = {}) {
    ({
      model: this.model,
      pageId: this.pageId,
      teamId: this.teamId,
      includeSubTeams: this.includeSubTeams
    } = options);

    this.getTitle = this.getTitle.bind(this);
  }

  className() {
    return 'ax-grid page-insights-engagement__header';
  }

  getTemplate() {
    return `
    <div class="ax-grid__col--12 ax-grid__col--m-10">
      <h2><%- t('discover.insights.engagement') %></h2>
      <p><%= getTitle() %></p>
    </div>
    <% if (hasSubteams) { %>
      <%= axCheckbox({id: 'include-subteams', checked: includeSubTeams, label: t('discover.insights.includeSubteams'), ariaLabel: t('discover.insights.includeSubteams')}) %>
    <% } %>`;
  }

  getTitle() {
    const parentTeam = this.model.get('parentTeam');
    const team = this.model.get('team');

    if (!team) {
      return I18n.t('general.overall');
    }

    if (this.model.get('isOnlyVisibleRootLocation')) {
      return HTMLHelpers.htmlEncode(team.name);
    }

    if (parentTeam) {
      return I18n.t('discover.insights.engagementTeamTitle', {
        teamName: team.name,
        parentTeamName: `<a href="${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ this.pageId }/insights/team-${ parentTeam.id }">${ HTMLHelpers.htmlEncode(parentTeam.name) } </a>`
      });
    }

    return I18n.t('discover.insights.engagementTeamTitle', {
      teamName: team.name,
      parentTeamName: `<a href="${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ this.pageId }/insights/">${ I18n.t('discover.insights.teams') } </a>`
    });
  }

  templateHelpers() {
    return {
      includeSubTeams: this.includeSubTeams,
      hasSubteams: this.model.get('hasSubteams'),
      getTitle: this.getTitle
    };
  }

  modelEvents() {
    return {
      sync: this.render
    };
  }

  events() {
    return {
      'change #include-subteams': (e) => {
        this.triggerMethod('toggle:includeSubTeams');
        this.includeSubTeams = e.currentTarget.checked;

        const options = {
          data: {
            includeSubteams: this.includeSubTeams
          }
        };

        this.model.fetch(options);
      }
    };
  }
}

module.exports = PageInsightsEngagementHeaderView;
