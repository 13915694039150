const I18n = require('@common/libs/I18n');
const Marionette = require('Marionette');
const Form = require('@common/components/forms/Form');
const EventNotificationsProperty = require('@common/data/models/EventNotificationsProperty');

class ProfileNotificationsSettingView extends Marionette.LayoutView {

  getTemplate() {
    return require('../templates/ProfileNotificationsSettingView.html');
  }

  ui() {
    return {
      notificationsForm: '.managerselect'
    };
  }

  modelEvents() {
    return {'change:form': 'render'};
  }

  initialize() {
    this.setActionButton = this.setActionButton.bind(this);
    this.onSave = this.onSave.bind(this);
    this.model = new EventNotificationsProperty();
    this.model.fetch();
    this.actionBar = this.options.actionBar;
    this.listenTo(this.model, 'change', this.onRender);
  }

  onRender() {
    this.hasSaved = false;
    const toggleNotifications = this.getNotificationsPropertyCollection();
    this.form = new Form({
      el: this.ui.notificationsForm,
      model: this.model,
      context: {
        EventNotificationsProperty: toggleNotifications
      }
    });
    this.setActionButton();

  }

  getNotificationsPropertyCollection() {
    return EventNotificationsProperty;
  }

  setActionButton() {
    const disableButton = !(this.model.isChangedByUser());

    const btns = [{
      type: 'profile-save',
      onClick: this.onSave,
      disabled: disableButton
    }];

    this.actionBar.setActionButton(btns);
  }

  onSave() {
    if (this.hasSaved) {
      return;
    }
    this.hasSaved = true;
    this.model.save().success(() => {
      window.app.layout.flash.success(I18n.t('settings.notificationssaved'));
      this.setActionButton();
    });
  }
}

module.exports = ProfileNotificationsSettingView;
