import { ItemView } from 'Marionette';

export default class AoiCheckboxlistRowView extends ItemView {
  get tagName() {
    return 'li';
  }

  get className() {
    return 'aoi-row';
  }

  get attributes() {
    return {
      'data-id': this.model.get('id')
    };
  }

  get template() {
    return `
      <%= axCheckbox({
        className: 'checkbox',
        id: 'checkbox-${ this.model.get('id') }',
        checked: ${ this.model.get('selected') },
        ariaChecked: ${ this.model.get('selected') },
        role: 'checkbox',
        label: '${ this.model.get('name')
    .replace(/'/g, '&apos;')
    .replace(/\\/g, '\\\\') }'
      }) %>
    `;
  }
}
