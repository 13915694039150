const Marionette = require('Marionette');

class EmptyRowView extends Marionette.ItemView {
  get className() {
    return 'empty-view';
  }

  getTemplate() {
    return '<%- t("general.empty") %>';
  }
}

module.exports = EmptyRowView;
