import {
  StepButton as MuiStepButton,
  styled,
  type StepButtonTypeMap as MuiStepButtonTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type PropsToOmit =
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'optional'; // it does not work when we use styled() (check here: https://github.com/mui/material-ui/blob/32112b76aa821c2a1e98120545019ef1a71ea274/packages/mui-material/src/StepButton/StepButton.js#L74). However, AxStepLabel handles it.

type AxStepButtonCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * Setting the `disabled` prop will not pass down the `disabled` prop to the children, causing unexpected styling behavior. Disable steps in AxStep instead.
   */
  disabled?: boolean;
};

export type AxStepButtonTypeMap<P = object, D extends React.ElementType = 'button'> = {
  defaultComponent: D;
  props: P & Omit<MuiStepButtonTypeMap<P, D>['props'], PropsToOmit> & AxStepButtonCustomProps;
};

export type AxStepButtonProps<
  D extends ElementType = AxStepButtonTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxStepButtonTypeMap<P & AxStepButtonCustomProps, D>, D>;

const styledOptions = {
  name: 'AxStepButton'
};

const StyledAxStepButton = styled(MuiStepButton, styledOptions)<AxStepButtonProps>(() => {
  return {};
});

/**
 * A wrapper around MUI `StepButton` that applies our custom styles.
 *
 * This component creates a clickable button wrapper that we can use around `AxStepLabel`.
 *
 * ### Links
 * - [MUI StepButton API](https://mui.com/material-ui/api/step-button/)
 */
export const AxStepButton: MuiOverridableComponent<AxStepButtonTypeMap> = forwardRef(({
  ...otherProps
}: AxStepButtonProps, ref: Ref<HTMLButtonElement>) => {
  const stepButtonProps: AxStepButtonProps = {
    ...otherProps
  };

  return (
    <StyledAxStepButton
      { ...stepButtonProps }
      ref={ ref }
      disableTouchRipple
      disableRipple
      focusRipple={ false }
      centerRipple={ false }
    />
  );
});

export default AxStepButton;
