import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitRadio(cssVars: CSSStyleDeclaration) {
  return {
    radio: {
      fill: {
        checked: getCssValue('--ax-radio-icon-fill--checked', cssVars),
        checkedBranded: getCssValue('--ax-radio-icon-fill--checked-branded', cssVars),
        checkedDisabled: getCssValue('--ax-radio-icon-fill--checked-disabled', cssVars),
        unchecked: getCssValue('--ax-radio-icon-fill--unchecked', cssVars),
        uncheckedDisabled: getCssValue('--ax-radio-icon-fill--unchecked-disabled', cssVars)
      },
      stroke: {
        checked: getCssValue('--ax-radio-icon-stroke--checked', cssVars),
        checkedBranded: getCssValue('--ax-radio-icon-stroke--checked-branded', cssVars),
        checkedDisabled: getCssValue('--ax-radio-icon-stroke--checked-disabled', cssVars),
        unchecked: getCssValue('--ax-radio-icon-stroke--unchecked', cssVars),
        uncheckedDisabled: getCssValue('--ax-radio-icon-stroke--unchecked-disabled', cssVars),
        uncheckedBranded: getCssValue('--ax-radio-icon-stroke--unchecked-branded', cssVars)
      }
    }
  };
}

