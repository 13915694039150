class LocaleBundle {

  // eslint-disable-next-line no-unused-vars
  load(localeCode) {
    return Promise.resolve();
  }

  // eslint-disable-next-line no-unused-vars
  onPostLoad(locale) {
    // No-op on purpose by default
  }
}

module.exports = LocaleBundle;
