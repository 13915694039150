const Marionette = require('Marionette');
const $os = require('detectOS');

require('@common/libs/behaviors/resizable/Resizable');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

class ZoomImageModalView extends Marionette.LayoutView {
  constructor(...args) {
    super(...args);
    this.onShow = this.onShow.bind(this);
    this.onResize = this.onResize.bind(this);
    this._loadImage = this._loadImage.bind(this);
    this._onResizeImage = this._onResizeImage.bind(this);
  }

  className() {
    return 'modal zoom-modal';
  }

  getTemplate() {
    return require('@common/components/image/zoom/_zoom_image_modal.html');
  }

  ui() {
    return {
      spinner: '#spinner',
      zoomImageWrapper: '.zoom-image-wrapper'
    };
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  initialize(options = {}) {
    ({
      media: this.media
    } = options);
  }

  onShow() {
    this._loadImage();
  }

  onResize() {
    this._onResizeImage();
  }

  _loadImage() {
    this.ui.spinner.show();

    this.imageViewer = ImageViewerFactory.createViewerInstance({
      $el: this.ui.zoomImageWrapper,
      media: this.media
    });
    this.listenTo(this.imageViewer, 'image:loaded', this._onImageLoaded);
    this.imageViewer.render();
  }

  _onImageLoaded() {
    this.ui.spinner.hide();

    this._$previewImage = this.ui.zoomImageWrapper.find('img');
    this.$el.css({
      width: 'auto',
      height: 'auto'
    });

    this.trigger('image:loaded');
    $(window).resize();
    this._onResizeImage();
  }

  _onResizeImage() {
    if (this.isDestroyed || $os.mobile || (this._$previewImage == null)) {
      return;
    }

    this.$el.css('visibility', 'visible');

    const imageWidth = this._$previewImage[0].naturalWidth;
    const imageHeight = this._$previewImage[0].naturalHeight;

    // If the images current size is less than max height remove the bottom style
    if (imageHeight >= $(window).outerHeight(true)) {
      this.$el.addClass('modal-bottom');
    } else {
      this.$el.removeClass('modal-bottom');
    }

    if (imageWidth > 0) {
      if (imageWidth > $(window).outerWidth(true)) {
        this.$el.addClass('full-width-image');
        this.$el.css('width', '100%');
      } else {
        this.$el.removeClass('full-width-image');
        this.$el.css('width', imageWidth);
      }
    }
  }
}

module.exports = ZoomImageModalView;
