// Module
var code = `<% var letterlabel = 'abcdefghijklmnopqrstuvwxyz'.charAt(i); %>
<div id="answer_option<%-i %>" data-answer-index="<%- i %>" data-answer-option="<%-option.id %>" data-letter-label="<%-letterlabel %>" class="answer answer-text-limit-max-width touchable clearfix">
  <div class="answerspacer">
    <div class="table-helper" cellpadding="0px" role="presentation">
      <div class="table-row-helper">
        <!--<td style="height: 32px; width: 0px;"></td>-->
        <div class="table-cell-helper answerradio" id="<%-option.id %>-radio" aria-label="<%= optionText %>" tabindex="-1" role="radio" aria-posinset="<%- i+1 %>" aria-setsize="<%- optionSize %>" name="multiple_choice" aria-checked="false"><div class="radiobutton"></div></div>
        <!-- <td class="answerletter"><div class="letterlabel"><%-letterlabel %></div></td> -->
        <div class="table-cell-helper answerimage touchable <% if (!option.optionImg) { %>empty<% } %>">
          <% if (option.optionImg) { %>
            <button class="image-wrapper imgwrap zoom-image-wrap" data-media-id="<%- option.optionImg.preferred.id %>"></button>
          <% } %>
        </div>
        <div class="table-cell-helper answertext" aria-hidden="true" role="presentation">
          <label aria-hidden="true" role="presentation" id="<%-option.id %>-label"><%= option.optionText %></label>
        </div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;