const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const {
  onDeleteLanguageVersion
} = require('@training/apps/articles/PageDeleteHelper');

class MetadataRootView extends Marionette.LayoutView {

  initialize(options = {}) {
    ({
      model: this.model
    } = options);
  }

  getTemplate() {
    return `
      <div class="js-reference-header-region"></div>
      <div class="js-language-chooser-region language_region"></div>
      <div class="js-community-region"></div>
      <div class="js-attachments-region"></div>
      <div class="js-tags-region"></div>
      <div class="js-editor-notes-region"></div>
      <div class="js-publish-settings-region"></div>
      <div class="js-delete-action-region metadata__input">
      <% if (canDeletePage()) { %>
        <%= axButton({ label: getDeleteText(), className: 'qa-button-delete js-button-delete', destructive: true }) %>
      <% } %>
      </div>
      `;
  }

  id() {
    return 'meta-data-container';
  }

  className() {
    return 'knowledge-item-metadata-view';
  }

  ui() {
    return {
      deleteButton: '.js-button-delete'
    };
  }

  events() {
    return {
      'click @ui.deleteButton': 'onDeleteButtonClicked'
    };
  }

  templateHelpers() {
    return {
      getDeleteText() {
        return I18n.t(`discover.pageTypes.${ this.type }.actionText.delete.action`);
      },
      canDeletePage: () => {
        return !this.model.isNew() && this.model.canDelete();
      }
    };
  }

  behaviors() {
    return {
      Scrollable: {
        scrollableContainer: '#meta-data-container'
      }
    };
  }

  regions() {
    return {
      referenceHeaderRegion: '.js-reference-header-region',
      editorNotesRegion: '.js-editor-notes-region',
      attachmentsRegion: '.js-attachments-region',
      languageRegion: '.js-language-chooser-region',
      communityRegion: '.js-community-region',
      tagsRegion: '.js-tags-region',
      publishSettingsRegion: '.js-publish-settings-region',
      deleteActionRegion: '.js-delete-action-region'
    };
  }

  onDeleteButtonClicked() {
    onDeleteLanguageVersion(this.model, this.languageData);
  }

}

module.exports = MetadataRootView;
