const { LayoutView } = require('Marionette');

class EvaluationSubmissionUploadModalContentView extends LayoutView {
  initialize(options = {}) {
    ({
      evaluationForm: this.evaluationForm
    } = options);
  }

  getTemplate() {
    return require('@training/apps/training/templates/EvaluationSubmissionUploadModal.html');
  }

  templateHelpers() {
    return {
      formName: this.evaluationForm.getEvaluationTitle()
    };
  }

  regions() {
    return {
      uploadRegion: '.file-upload'
    };
  }

  events() {
    return {
      'click .close-modal-button': () => {
        this.trigger('close:modal');
      }
    };
  }

  className() {
    return 'modal__content';
  }
}

module.exports = EvaluationSubmissionUploadModalContentView;
