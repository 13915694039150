const MediaModel = require('@common/libs/file/MediaModel');

class TinCanMedia extends MediaModel {

  defaults() {
    return {type: 'tincan'};
  }

  get idAttribute() {
    return 'id';
  }

  urlRoot() {
    return this.apiEndpointUrl();
  }
  
  apiEndpoint() {
    return '/tincan';
  }

  getFile() {
    return this.get('tincanFile');
  }
}

module.exports = TinCanMedia;
