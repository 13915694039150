const logging = require('logging');

/*
  Silently notifies and updates the application. It does this using window reloading logic, by default. It is possible
  that other silent reloading logic could be injected but often you want the defaults.
*/
class SilentAxonifyUpdateNotificationStrategy {
  constructor(updater) {
    if (!updater) {
      throw new Error('You have to specify some kind of update strategy');
    }

    this.applicationUpdater = updater;
  }

  notify() {
    logging.info('Performing an application update...');
    this.applicationUpdater();
  }
}

module.exports = SilentAxonifyUpdateNotificationStrategy;
