const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

const TrainingCardModel = require('@common/components/trainingCards/TrainingCardModel');
const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');

const { TOPIC_DEFAULT } = require('@common/components/trainingCards/enums/TrainingCardTypes');

const {
  TRAINING_CARD_TYPE_BY_ASSESSMENT_TYPE,
  getCardActionMetaData,
  tryGetStartableAction
} = require('@common/components/trainingCards/TrainingCardsCollectionHelpers');

const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');
const AxonifyExceptionFactory = require('@common/services/error/AxonifyExceptionFactory');

class TopicTrainingCardsCollection extends InfiniteCollection {

  constructor(models = [], options = {}) {
    const newOptions = Object.assign({
      state: {
        pageSize: 12
      },
      fetchType: 'GET'
    }, options);

    super(models, newOptions);
    this.session = options.session;
  }

  get model() {
    return TrainingCardModel;
  }

  apiEndpoint() {
    return '/training/topics';
  }

  search(query, pageNum, options = {}) {
    this.extraParams.search = query;
    return super.search(query, pageNum, options);
  }

  resetSearchParams() {
    this.extraParams.search = null;
  }

  parseRecords(data = []) {
    return data.map((card) => {
      const action = tryGetStartableAction(card, this.session) || card[TrainingCardModel.FIELDS.ACTION];

      return Object.assign({}, card, {
        [TrainingCardModel.FIELDS.CARD_TYPE]: this._getCardType(action),
        [TrainingCardModel.FIELDS.CARD_ACTION_META_DATA]: getCardActionMetaData(action)
      });
    });
  }

  fetch(options = {}) {
    return super.fetch(Object.assign({ showSpinner: false }, options));
  }

  _getCardType(action) {
    if (action) {
      const assessmentType = action[TrainingCardModel.FIELDS.ASSESSMENT_TYPE];
      return TRAINING_CARD_TYPE_BY_ASSESSMENT_TYPE[assessmentType] || TOPIC_DEFAULT;
    }

    return undefined;
  }

  onError(...[,, error]) {
    super.onError();

    const { xhr } = error;
    const exception = AxonifyExceptionFactory.fromResponse(xhr);

    if (exception.getErrorCode() === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.access.error.2012'));

      Backbone.history.navigate('hub', {
        replace: true,
        trigger: true
      });
    }
  }
}

module.exports = TopicTrainingCardsCollection;
