const { ItemView } = require('Marionette');
const { Model } = require('Backbone');
const _ = require('underscore');
const Form = require('@common/components/forms/Form');

const PageType = require('@common/components/discover/enums/PageType');

require('@common/components/forms/editors/fileUpload/Form.Editor.MediaFileUpload');
require('jquery.dotdotdot');

class AttachmentsView extends ItemView {
  getTemplate() {
    return `<form id="document-form" class="metadata-item metadata__input">
    <div id="attachment-header">
      <h4><%- t('discover.metadata.attachments') %>
        <span class="metadata-value">(<span class="attachment-counter"><%- getDocumentCount() %></span>)</span>
      </h4>
    </div>
    <div class="documents-container" data-field="media" data-editor="MediaFileUpload" data-options="mediaFileUploadOptions"></div>
  </form>`;
  }

  templateHelpers() {
    return {
      getDocumentCount: () => {
        return this.model.currentVersion.richContent.getDocumentCount();
      }
    };
  }

  ui() {
    return {
      attachmentCount: '.attachment-counter'
    };
  }

  initialize() {
    this.canEditContent = this.model.get('type') !== PageType.TRAINING;

    if (this.canEditContent) {
      this.richContent = this.model.currentVersion.richContent;
      this.newRichContent = new Model({
        media: this.richContent.get('media')
      });
    }
  }

  onRender() {
    this.documentForm = new Form({
      el: this.$('#document-form'),
      model: this.newRichContent,
      context: {
        mediaFileUploadOptions: {
          fileFactory: this.options.fileFactory,
          viewTypes: ['document']
        }
      }
    });

    this.listenTo(this.documentForm, 'change:media', this._updateDocumentCount);
    this.listenTo(this.documentForm.fields.media.editor, 'removeMedia', this._updateDocumentCount);
    this.listenTo(this.documentForm.fields.media.editor, 'upload:start', () => {
      window.app.layout.showSpinner();
    });
    this.listenTo(this.documentForm.fields.media.editor, 'exception', this._handleFileException);
    this.listenTo(this.documentForm.fields.media.editor, 'upload:complete', () => {
      window.app.layout.hideSpinner();
    });
  }

  onAttach() {
    // add ellipses for filename
    this.$('.attachment-name').dotdotdot({
      height: 40
    });
  }

  commit() {
    let errors = [];

    errors = errors.concat(this.documentForm.commit());

    let trueMediaList = this.richContent.get('media');
    const tmpMediaList = this.newRichContent.get('media');

    // get the list of new media Ids to help filtering
    const tmpMediaIds = _.map(tmpMediaList, (media) => {
      return media.id;
    });

    // need to go through the media and remove the deleted ones
    trueMediaList = _.reject(trueMediaList, (media) => {
      // if media type is document and it's not in the new list
      return (media.type === 'document') && !(Array.from(tmpMediaIds).includes(media.id));
    });

    const trueMediaIds = _.map(trueMediaList, (media) => {
      return media.id;
    });

    // need to add the ones that are not yet there
    _.each(tmpMediaList, (media) => {
      if ((media.type === 'document') && !(Array.from(trueMediaIds).includes(media.id))) {
        // create media model and add it to the media list
        trueMediaList.push(media);
      }
    });

    this.richContent.set('media', trueMediaList);

    return errors;
  }

  _handleFileException(reason) {
    window.app.layout.flash.error(reason);
  }

  _updateDocumentCount() {
    const documents = this.documentForm.fields.media.editor.getValue() || [];
    this.ui.attachmentCount.html(`${ documents.length }`);
    this.trigger('change:attachments', documents);
  }

  onDestroy() {
    this.documentForm.destroy();
  }
}

module.exports = AttachmentsView;
