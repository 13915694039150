const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const {
  ItemView,
  CollectionView
} = require('Marionette');
const I18n = require('@common/libs/I18n');

const PageInsightsReactionsTileView = require('@training/apps/insights/engagement/PageInsightsReactionsTileView');

class PageInsightsReactionsTileController extends LayoutController {
  initialize(options = {}) {
    ({
      collection: this.collection,
      model: this.model,
      pageId: this.pageId,
      teamId: this.teamId,
      includeSubTeams: this.includeSubTeams,
      reactionsErrorHandler: this.reactionsErrorHandler
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: PageInsightsReactionsTileView,
      pageId: this.pageId,
      teamId: this.teamId,
      includeSubTeams: this.includeSubTeams,
      reactionsErrorHandler: this.reactionsErrorHandler
    };
  }

  regionControllers() {
    return {
      reactionsCountRegion: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'ax-grid__col--6 ax-grid__col--m-4',
          model: this.model,
          modelEvents: {
            'change:reactions': () => {
              this.getRegion('reactionsCountRegion').currentView.render();
            }
          },
          template: `
            <div class="highlight subhighlight">
              <div class="wrap clearfix">
                <div class="ax-font__display ax-font--no-margin"> <%= reactions.totalCount %> </div>
                <div class="ax-font__caption ax-font--secondary"> <%- t('discover.insights.totalReactions') %></div>
              </div>
            </div>
          `
        }
      },
      reactionsRegion: {
        viewDefinition: {
          ViewClass: CollectionView,
          collection: this.collection,
          className: 'ax-grid ax-grid__col--12 ax-grid__col--m-6',
          childView: ItemView,
          childViewOptions: (model) => {
            return {
              templateHelpers: {
                reactions: model.get('key'),
                count: model.get('value'),
                offscreenCountText: I18n.t(`reaction.reactionType.${ model.get('key').toUpperCase() }.reactionCount`, { count: model.get('value') })
              },
              className: 'reactions__categories--wrapper ax-grid__col--3 ax-grid__col--m-2',
              template: `
                <div class="ax-grid reactions__categories">
                  <span class="reaction-icon__filled--<%-reactions %> filled" aria-hidden="true"></span>
                  <div class="sr-only"><%- offscreenCountText %></div>
                  <div class="reactions__categories--count ax-font__caption ax-font--secondary" aria-hidden="true"> <%- count %> </div>
                </div>
              `
            };
          }
        }
      }
    };
  }
}

module.exports = PageInsightsReactionsTileController;
