const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
const { articleSearchResultControllerDefinition } = require('@training/apps/search/ArticleSearchResultControllerDefinitionFactory');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const { CONTAINER_CLASSES } = require('@common/libs/behaviors/carousel/Carousel');
const AxBreakpointEnum = require('@common/data/enums/AxBreakpointEnum').default;

const SLIDES_PER_VIEW_BREAKPOINTS_CONFIG = [
  {
    isDefault: true,
    slidesForSizes: [
      {
        minSlideCount: 1,
        slidesPerView: 3
      }
    ]
  }, {
    breakpoint: AxBreakpointEnum.DESKTOP_MIN,
    slidesForSizes: [
      {
        minSlideCount: 1,
        slidesPerView: 2
      },
      {
        minSlideCount: 2,
        slidesPerView: 2
      },
      {
        minSlideCount: 3,
        slidesPerView: 2.2
      }
    ]
  }, {
    breakpoint: AxBreakpointEnum.MOBILE_MAX,
    slidesForSizes: [
      {
        minSlideCount: 1,
        slidesPerView: 1
      },
      {
        minSlideCount: 2,
        slidesPerView: 1.1
      }
    ]
  }
];

module.exports = (recommendedArticlesList, discoveryZoneEnabled, recommendedArticlesEnabled) => {
  if (!discoveryZoneEnabled || !recommendedArticlesEnabled || recommendedArticlesList.length === 0) {
    return null;
  }

  const showAuthor = TenantPropertyProvider.get().getProperty('dzShowAuthorAndContributorInfo') || false;

  const showReactions = false; // because Recommended Articles shouldn't show the reactions tray

  return () => {
    return {
      ViewControllerClass: CollectionLayoutViewController,
      collection: recommendedArticlesList,
      viewDefinition: {
        tagName: 'ul',
        className: 'recommended-articles__list',
        behaviors: {
          Carousel: {
            keyboard: false,
            containerClass: `recommended-articles ${ CONTAINER_CLASSES.STANDARD_GAP }`,
            slidesPerViewCountConfig: SLIDES_PER_VIEW_BREAKPOINTS_CONFIG
          }
        },
        childViewOptions: {
          tagName: 'li',
          className: 'recommended-articles__item-region'
        }
      },
      delegateEvents: {
        'view:attach': (controller, view) => {
          view.$el.find('.subtext-header-title')
            .each((i, title) => {
              const lineHeight = parseInt($(title).css('lineHeight'), 10) + 1;
              $(title).dotdotdot({
                watch: true,
                height: lineHeight
              });
            });
        }
      },
      ChildViewControllerDefinition: (model) => {
        return articleSearchResultControllerDefinition(model.get('recommendedPage'), {
          showAuthor,
          showReactions
        });
      }
    };
  };
};
