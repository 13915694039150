const { CollectionView } = require('Marionette');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const HeaderDropdownLayout = require('@common/modules/main/header/headerDropdown/HeaderDropdownLayout');
const HeaderDropdownItem = require('@common/modules/main/header/headerDropdown/HeaderDropdownItem');
const UserProfileIcon = require('@common/modules/main/header/userProfileIcon/UserProfileIcon');

const USER_PROFILE = 'user-profile';

module.exports = {

  createUserProfileIcon(options) {
    const viewOptions = {
      configId: USER_PROFILE,
      ViewClass: UserProfileIcon,
      profileMenuItems: options.profileMenuItems,
      model: options.user
    };

    return viewOptions;
  },

  createDropdown({
    buttonContentsViewDefinition,
    dropdownMenuItems,
    hideDownIndicator = false
  } = {}) {
    const viewOptions = {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: HeaderDropdownLayout,
        collection: dropdownMenuItems,
        hideDownIndicator
      },
      regionControllers: {
        dropdownButton: {
          viewDefinition: buttonContentsViewDefinition
        },
        dropdownMenu: {
          viewDefinition: {
            ViewClass: CollectionView,
            tagName: 'ul',
            childView: HeaderDropdownItem,
            collection: dropdownMenuItems
          }
        }
      }
    };

    return viewOptions;
  }
};

