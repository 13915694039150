const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { LayoutView } = require('Marionette');
const $os = require('detectOS');
const I18n = require('@common/libs/I18n');
const ButtonGroupRadioView = require('@common/components/buttonGroup/ButtonGroupRadioView');
const CommunityInsightsReportsTimeFrameEnum = require('@training/apps/training/communitiesInsights/CommunityInsightsReportsTimeFrameEnum');
const PageableTableController = require('@common/components/pageableList/PageableTableController');
const CurrentPagesTableLayoutView = require('@training/apps/training/communitiesInsights/components/tables/CurrentPagesTableLayoutView');
const CurrentPagesTableItemView = require('@training/apps/training/communitiesInsights/components/tables/CurrentPagesTableItemView');


class CurrentInsightsLayoutController extends LayoutController {
  initialize(options = {}) {
    ({
      collection: this.collection,
      filtersModel: this.filtersModel,
      timeFilterCollection: this.timeFilterCollection
    } = options);

    this._handleCurrentTimeChange = this._handleCurrentTimeChange.bind(this);
    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: require('@training/apps/training/communitiesInsights/components/InsightsLayoutTemplate.html'),
      templateHelpers: {
        isMobile: $os.mobile
      },
      regions: {
        timeSelect: '.js-current-insights__time-select',
        table: '.js-insights__table'
      },
      events: {
        'click .js-insights__export': () => {
          this.trigger('request:export');
        }
      }
    };
  }

  regionControllers() {
    return {
      timeSelect: this._getTimeScaleDefinition(),
      table: this._getTableDefinition()
    };
  }

  _getTimeScaleDefinition() {
    return {
      viewDefinition: {
        ViewClass: ButtonGroupRadioView,
        className: 'ax-grid community-insights-date-filter__grid',
        childClass: 'ax-grid__col--6 ax-grid__col--m-auto-size ax-button--m community-insights-date-filter__item',
        collection: this.timeFilterCollection
      },
      delegateEvents: {
        'view:buttonGroup:click': this._handleCurrentTimeChange
      }
    };
  }

  _getTableDefinition() {
    return {
      ViewControllerClass: PageableTableController,
      itemViewClass: CurrentPagesTableLayoutView,
      tableViewClass: CurrentPagesTableLayoutView,
      collection: this.collection,
      rowItemView: CurrentPagesTableItemView,
      headerNames: [
        I18n.t('general.published'),
        I18n.t('general.page'),
        I18n.t('general.type'),
        {
          value: I18n.t('discover.insights.impressions'),
          className: 'reports-table__value-column'
        },
        {
          value: I18n.t('discover.insights.engagements'),
          className: 'reports-table__value-column'
        },
        {
          value: I18n.t('discover.insights.views'),
          className: 'reports-table__value-column'
        },
        {
          value: I18n.t('discover.insights.viewers'),
          className: 'reports-table__value-column'
        },
        // Empty header above external-link icon column
        {
          value: I18n.t('discover.insights.pageInsights'),
          className: 'reports-table__value-column off-screen'
        }
      ]
    };
  }

  _handleCurrentTimeChange(view, controller, buttonValueSelected) {
    if (this.filtersModel.get('periodLength') === buttonValueSelected) {
      return;
    }

    // If 'All Time', use the server default behaviour.
    if (buttonValueSelected === CommunityInsightsReportsTimeFrameEnum.ALL) {
      this.filtersModel.unset('periodLength', buttonValueSelected);
    } else {
      this.filtersModel.set('periodLength', buttonValueSelected);
    }
  }
}

module.exports = CurrentInsightsLayoutController;
