import {
  StepContent as MuiStepContent,
  type StepContentProps as MuiStepContentProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

export type AxStepContentProps = MuiStepContentProps;

const styledOptions = {
  name: 'AxStepContent'
};

const StyledAxStepContent = styled(MuiStepContent, styledOptions)<AxStepContentProps>(() => {
  return {};
});

export const AxStepContent = forwardRef(({
  ...otherProps
}: AxStepContentProps, ref: Ref<HTMLDivElement>) => {
  const stepContentProps: AxStepContentProps = {
    ...otherProps
  };

  return (
    <StyledAxStepContent { ...stepContentProps } ref={ ref } />
  );
});

export default AxStepContent;
