const _ = require('underscore');

const DocumentView = require('@common/components/window/DocumentView');

class PrintDocument extends DocumentView {
  generateLinks(cssPaths = []) {
    const screenLinks = super.generateLinks(...arguments);
    const printLinks = this._generateLinks(cssPaths, 'print');

    return _.flatten([screenLinks, printLinks]);
  }

  onLoad() {
    _.delay(this.print.bind(this), 500);
  }

  print() {
    this._ensureViewIsIntact();

    this.getWindow().focus();
    this.getWindow().print();
  }
}

module.exports = PrintDocument;
