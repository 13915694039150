// Module
var code = `<div id="menu-container" class="page-content-notifications">
  <div class="agree-container"></div>
  <div class="reported-banner-container"> </div>
  <% if (isEditing) { %>
  <div class="warning-container notify-container clearfix">
    <div class="icon icon-exclamation_sign-outline"></div>
    <div class="info"><%- t('discover.pageTypes.training.editWarning') %></div>
  </div>
  <% } %>
  <% if (isLockedForEditing()) { %>
  <div class="warning-container notify-container clearfix">
    <div class="icon icon-exclamation_sign-outline"></div>
    <div class="info"><%- getLockedMessage() %></div>
  </div>
  <% } %>
</div>
<% if (isEditing) { %>
<div id="squire_editor" class="squire-editor page-content-view training-module">
  <% } else { %>
  <div id="page-content-view" class="squire-editor training-module">
    <% } %>
    <div id="page-container" class="full-width <%- pageContainerClass %>">
      <div class="page-content"></div>
      <div class="page-module <%- moduleClass %>-module"></div>
      <div class="article-footer">
        <div class="reactions-region"></div>
      </div>
      <div class="comments-region"></div>
    </div>
  </div>
`;
// Exports
module.exports = code;