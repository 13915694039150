import {
  List as MuiList,
  styled,
  type ListTypeMap as MuiListTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxListCustomProps = {
  component?: React.ElementType; // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxListTypeMap<
  P = object,
  D extends React.ElementType = 'ul'
> = MuiListTypeMap<P & AxListCustomProps, D>;

export type AxListProps<
  D extends ElementType = AxListTypeMap['defaultComponent'],
  P = object
> = MuiOverrideProps<AxListTypeMap<P & AxListCustomProps, D>, D>;

const styledOptions = {
  name: 'AxList'
};

const StyledAxList = styled(
  MuiList,
  styledOptions
)<AxListProps>(() => {
  return {
    padding: 0,
    margin: 0
  };
});
/**
* A list is a compound component that can be used to create a variety of list types.
*
* The primary components are:
*
* - `AxList`: a wrapper for list items. Renders as a ul by default.
* - `AxListItem`: a common list item. Renders as an li by default.
* - `AxListItemButton`: an action element to be used inside a list item.
* - `AxListItemIcon`: an icon to be used inside of a list item.
* - `AxListItemAvatar`: an avatar to be used inside of a list item.
* - `AxListItemText`: a container inside a list item, used to display text content.
* - `AxListDivider`: a separator between list items.
* - `AxListSubheader`: a label for a nested list.
*
* ### Links
* - • [MUI List | API](https://mui.com/material-ui/api/list/)
* - • [MUI List | Demo](https://mui.com/material-ui/react-list/)
*/
export const AxList: MuiOverridableComponent<AxListTypeMap> = forwardRef(
  ({
    children,
    ...otherProps
  }: AxListProps, ref: Ref<HTMLUListElement>) => {
    const ListProps: AxListProps = {
      children,
      ...otherProps
    };

    return (
      <StyledAxList { ...ListProps } ref={ ref }>
        { children }
      </StyledAxList>
    );
  }
);

export default AxList;
