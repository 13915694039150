import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitButton(cssVars: CSSStyleDeclaration) {
  return {
    buttonColor: getCssValue('--axButton-color', cssVars),
    buttonFont: getCssValue('--axButton-font', cssVars),
    buttonLineHeight: getCssValue('--axButton-line-height', cssVars),
    buttonPaddingL: getCssValue('--axButton-padding-l', cssVars),
    buttonPaddingM: getCssValue('--axButton-padding-m', cssVars),
    buttonPaddingS: getCssValue('--axButton-padding-s', cssVars),
    buttonWeight: getCssValue('--axButton-weight', cssVars),

    // Branded button
    buttonBrandedBackground: getCssValue('--axButton-branded-background', cssVars, '--ax-color-green-60'), // branding style
    buttonBrandedBackgroundHover: getCssValue('--axButton-branded-background-hover', cssVars, '--ax-color-green-80'), // branding style
    buttonBrandedBackgroundDisabled: getCssValue('--axButton-branded-background-disabled', cssVars),
    buttonBrandedColor: getCssValue('--axButton-branded-color', cssVars, '--ax-color-white'), // branding style
    buttonBrandedColorHover: getCssValue('--axButton-branded-color-hover', cssVars, '--ax-color-white'), // branding style
    buttonBrandedColorDisabled: getCssValue('--axButton-branded-color-disabled', cssVars),
    buttonBrandedBorderColorDisabled: getCssValue('--axButton-branded-border-color-disabled', cssVars),

    // Default button
    buttonDefaultBackground: getCssValue('--axButton-default-background', cssVars),
    buttonDefaultBackgroundHover: getCssValue('--axButton-default-background-hover', cssVars),
    buttonDefaultBorder: getCssValue('--axButton-default-border', cssVars),
    buttonDefaultBorderHover: getCssValue('--axButton-default-border-hover', cssVars),
    buttonDefaultBackgroundDisabled: getCssValue('--axButton-dafault-background-disabled', cssVars),
    buttonDefaultBorderDisabled: getCssValue('--axButton-default-border-disabled', cssVars),
    buttonDefaultColorDisabled: getCssValue('--axButton-default-color-disabled', cssVars),

    // Destructive button
    buttonDestructiveBackground: getCssValue('--axButton-destructive-background', cssVars),
    buttonDestructiveBackgroundHover: getCssValue('--axButton-destructive-background-hover', cssVars),
    buttonDestructiveBorder: getCssValue('--axButton-destructive-border', cssVars),
    buttonDestructiveColor: getCssValue('--axButton-destructive-color', cssVars),
    buttonDestructiveColorHover: getCssValue('--axButton-destructive-color-hover', cssVars),

    // Ghost button
    buttonGhostBackground: getCssValue('--axButton-ghost-background', cssVars),
    buttonGhostBorder: getCssValue('--axButton-ghost-border', cssVars),
    buttonGhostBorderDisabled: getCssValue('--axButton-ghost-border-disabled', cssVars),

    // Floating button
    buttonFloatingBackground: getCssValue('--axButton-floating-background', cssVars),
    buttonFloatingBackgroundHover: getCssValue('--axButton-floating-background-hover', cssVars)
  };
}
