const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const logging = require('logging');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');
require('@common/libs/behaviors/card/Card');

const KeyCode = require('@common/data/enums/KeyCode');
const dateHelpers = require('@common/libs/dateHelpers');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');


const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');

class UserMessagePage extends UIKit.View {

  behaviors() {
    return {
      Resizable: {},
      ZoomOverlay: {
        imageWrapper: '.media'
      },
      Card: {
        target: '.claimed-invite'
      }
    };
  }

  initialize() {
    this.userMessageTemplate = _.tpl(require('@training/apps/training/templates/UserMessagePage.html'));
    this.userMessageReferralFirstLoginTemplate = _.tpl(require('@training/apps/training/templates/UserMessageReferralFirstLoginPage.html'));
    this.userMessageReferralExpiredTemplate = _.tpl(require('@training/apps/training/templates/UserMessageReferralExpiredPage.html'));
    this.userMessagePrizeWinnerTemplate = _.tpl(require('@training/apps/training/templates/UserMessagePrizeWinnerPage.html'));
    this.userMessagePrizeLoserTemplate = _.tpl(require('@training/apps/training/templates/UserMessagePrizeLoserPage.html'));
    this.userMessagePrizeSummaryTemplate = _.tpl(require('@training/apps/training/templates/UserMessagePrizeSummaryTemplate.html'));
    this.userMessageTeamGameOverTemplate = _.tpl(require('@training/apps/training/templates/UserMessageTeamGameOver.html'));

    this.userRowTemplate = _.tpl(require('@training/apps/training/templates/_prize_user_row.html'));

    // get message type and message object
    const messageType = this.model.get('type');
    const message = this.model.get('message');
    const simpleMessage = this.model.get('simpleMessage');

    // get text values based on messageType
    let typeData = {};
    if ((messageType === 'DIRECT')
      || (messageType === 'QUEUED_DIRECT')
      || (messageType === 'CONGRATULATIONS_GRADUATION')
      || (messageType === 'CONGRATULATIONS_PRIZE')
      || (messageType === 'GAME_VICTORY_DECLARATION')
      || (messageType === 'BOUNTY_FIRST_LOGIN_BONUS')
      || (messageType === 'BOUNTY_EXPIRED_POINTS_EARNED')
      || (messageType === 'TEAM_GAME_OVER')) {

      const subType = this.getSubtype(messageType, message);
      typeData = {
        text: message.text,
        subType,
        msgTypeTitle: I18n.t(`usermessage.msgTypeTitle.${ subType }`,
          message.params),
        hasSender: false
      };

      // if the sender's id is greater than 0, add the sender to the data
      const { sender } = this.model.toJSON();
      if ((sender != null) && (sender.id > 0)) {
        typeData.senderFullName = sender.user.displayName;
        typeData.hasSender = true;
      }
    }

    // get media and/or video
    const mediaData = {
      hasMedia: false,
      hasVideo: false
    };
    if (this.model.get('media')) {
      mediaData.hasMedia = true;
      mediaData.media = this.model.get('media');
    }
    if (this.model.get('video')) {
      mediaData.hasVideo = true;
      mediaData.videoPackage = this.model.get('video');
    }

    // set the data object for the template
    this.data = {
      activity: this.model.toJSON()
    };

    const messageData = this.getMessageData(messageType, message, simpleMessage);
    $.extend(true, this.data, typeData, messageData, mediaData);

    // Load view
    const template = this.getTemplate(messageType);
    this.$el.html(template(this.data));
    this.$videowrapper = this.$('.videowrapper');

    if (messageType === 'GAME_VICTORY_DECLARATION') {
      // Switch the icon to the one provided
      this.$el.find('.icon').addClass('h2h-icon');
    } else if (messageType === 'BOUNTY_EXPIRED_POINTS_EARNED') {
      new ProfileIconHandler({ // eslint-disable-line no-new
        user: message.user,
        profileElement: this.$el.find('.large-name-logo')
      });
    } else if (messageType === 'PRIZE_SUMMARY') {

      const otherWinners = message.otherWinners;
      const winnersInLocation = message.winnersInLocation;

      if (otherWinners) {
        this._renderWinnerRow(otherWinners, this.$el.find('.other-winners-list'), message);
      }

      if (winnersInLocation) {
        this._renderWinnerRow(winnersInLocation, this.$el.find('.winners-in-location-list'), message);
      }
    }

    this.listenTo(this, 'image:clicked', () => {
      window.app.layout.showMedia(mediaData.media);
    });

    this.onNext = this.onNext.bind(this);
    this.onClose = this.onClose.bind(this);

    // Window events
    return $(document).on('keypress', this.keyPress.bind(this));
  }

  _renderWinnerRow(winnerList, $list, message) {
    _.each(winnerList, (winnerBundle) => {
      const winner = winnerBundle.winner || winnerBundle;
      const detail = (message.gameType !== 'raffle') ? winnerBundle.detail || {} : {};
      const department = winner.department && winner.department.name;
      const location = winner.location && winner.location.name;
      const departmentName = department || location || '';

      const winnerUserHtml = this.userRowTemplate({
        name: winner.salutationName,
        department: departmentName,
        detail: detail,
        gameType: message.gameType
      });
      const $winnerUserHtml = $(winnerUserHtml);
      const $currentView = $winnerUserHtml.find('.name-logo');
      new ProfileIconHandler({ // eslint-disable-line no-new
        user: winner,
        profileElement: $currentView
      });

      $list.append($winnerUserHtml);
    });
  }

  getSubtype(messageType, message) {
    let subType = messageType.toLowerCase();

    // set subtype based on messageType
    // This is done to be backward compatible to v21 which supports
    // DIRECT msg type with subType graduate/prize_winner
    if ((messageType === 'DIRECT') || (messageType === 'QUEUED_DIRECT')) {
      // message is a direct msg, so get sub type from message type or set to
      // default if it doesn't exists.
      subType = (message.type) ? message.type.toLowerCase() : 'default';
    } else if (messageType === 'CONGRATULATIONS_GRADUATION') {
      subType = 'graduation';
    } else if (messageType === 'CONGRATULATIONS_PRIZE') {
      subType = 'prize_winner';
    }

    return subType;
  }

  getMessageData(messageType, message, simpleMessage) {
    // messageData for messageType specific data
    let messageData = {};

    if (messageType === 'BOUNTY_FIRST_LOGIN_BONUS') {
      const daysLeft = dateHelpers.getDaysRemaining(apps.auth.session.serverTimeOffset, message.expiryDate);

      messageData = {
        name: message.user.displayName,
        loginPoints: message.loginPoints,
        subsequentPoints: message.subsequentPoints,
        daysLeft
      };
    } else if (messageType === 'BOUNTY_EXPIRED_POINTS_EARNED') {
      // get color related to user id
      const totalSubsequentPoints = message.totalPoints - message.loginPoints;

      let deptName;

      if (message.user.department && message.user.department.name) {
        deptName = message.user.department.name;
      } else if (message.user.location && message.user.location.name) {
        deptName = message.user.location.name;
      } else {
        deptName = '';
      }

      messageData = {
        name: message.user.displayName,
        department: deptName,
        loginPoints: message.loginPoints,
        duration: message.duration,
        totalSubsequentPoints,
        loginCount: message.loginCount
      };
    } else if (messageType === 'PRIZE_WINNER') {
      messageData = {
        name: message.name,
        fulfillment: message.fulfillment
      };

      if (message.auctionEnabled) {
        messageData.gameType = 'auction';
      }
      if (message.rafflesEnabled) {
        messageData.gameType = 'raffles';
      }

      // This is a legacy thing -- before raffles was introduced, messages may have not had the enabled
      // flags sent down. In this case, the only messages at the time that would be sent were auction ones.
      // Therefore, it is a safe assumption that there is just "auction"
      if (messageData.gameType == null) {
        messageData.gameType = 'auction';
      }

    } else if (messageType === 'PRIZE_LOSER') {
      // Only show one winning bidder if the high and low bids are the same
      if ((message.highestWinningBidder && message.lowestWinningBidder)
        && (message.highestWinningBidder.user.id === message.lowestWinningBidder.user.id)
        && (message.highestWinningBidder.points === message.lowestWinningBidder.points)) {
        message.singleWinningBidder = message.highestWinningBidder;
      }

      // Server determines if the displayname is either fullname or nickname
      messageData = _.extend({}, message, {
        hasHighestWinningBidder: (message.highestWinningBidder != null),
        hasLowestWinningBidder: (message.lowestWinningBidder != null),
        hasSingleWinningBidder: (message.singleWinningBidder != null)
      });

    } else if (messageType === 'PRIZE_SUMMARY') {
      messageData = {
        gameType: message.gameType,
        otherWinners: message.otherWinners,
        winnersInLocation: message.winnersInLocation,
        simpleMessage: simpleMessage
      };

    } else if (messageType === 'TEAM_GAME_OVER') {
      messageData = message;
      const scores = messageData.finalScore.split('-');
      messageData.winningScore = scores[0];
      messageData.losingScore = scores[1];
    }

    return messageData;
  }

  getTemplate(messageType) {
    let template = this.userMessageTemplate;

    if (messageType === 'BOUNTY_FIRST_LOGIN_BONUS') {
      template = this.userMessageReferralFirstLoginTemplate;
    } else if (messageType === 'BOUNTY_EXPIRED_POINTS_EARNED') {
      template = this.userMessageReferralExpiredTemplate;
    } else if (messageType === 'PRIZE_WINNER') {
      template = this.userMessagePrizeWinnerTemplate;
    } else if (messageType === 'PRIZE_LOSER') {
      template = this.userMessagePrizeLoserTemplate;
    } else if (messageType === 'PRIZE_SUMMARY') {
      template = this.userMessagePrizeSummaryTemplate;
    } else if (messageType === 'TEAM_GAME_OVER') {
      template = this.userMessageTeamGameOverTemplate;
    }

    return template;
  }

  viewDidAppear() {
    logging.info('UserMessagePage - viewDidAppear');

    window.app.layout.setTitle(I18n.t('usermessage.title'));

    this.actionBar.setActionButton({
      type: 'continue',
      onClick: this.onNext
    });

    // if media is video, load it
    this._renderVideoPlayer();
    this._renderImageMedia();
  }

  onResize() {
    if (this.imageViewer) {
      this.imageViewer.maxWidth = this.imageViewer.$el.width();
      this.imageViewer.resize();
    }

    if (this.videoPlayer) {
      this.videoPlayer.resize();
    }
  }

  _renderImageMedia() {
    const $imageContainer = this.$('.media');

    if (this.data.hasMedia && ($imageContainer.length > 0)) {
      this.imageViewer = ImageViewerFactory.createViewerInstance({
        media: this.data.media,
        $el: $imageContainer,
        maxWidth: $imageContainer.width()
      });
      this.listenTo(this.imageViewer, 'image:loaded', this.triggerAdjustment);
      this.imageViewer.render();
    }
  }

  _renderVideoPlayer() {
    if (this.data.hasVideo) {
      this.videoPlayer = VideoPlayerFactory.createPlayerInstance({
        videoPackage: this.data.videoPackage,
        viewOptions: {
          el: this.$videowrapper,
          userLanguage: window.apps.auth.session.user.get('language'),
          maxHeight: 385
        }
      });

      this.videoPlayer.render();
    }
  }

  keyPress(e) {
    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      this.onNext();
    }
  }

  onNext() {
    if (apps.base.checkDoubleSubmit()) {
      return false;
    }
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }
    return true;
  }

  destroy(...args) {
    if (this.videoPlayer != null) {
      this.videoPlayer.destroy();
    }

    window.app.layout.dismissMedia();

    return super.destroy(...args);
  }

  onClose() {
    $(document).off('keypress', this.keypress);

    this.actionBar?.setActionButton(null);
    this.actionBar?.hide();
  }
}

module.exports = UserMessagePage;
