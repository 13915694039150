const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const SCORMHelpers = require('@common/libs/helpers/app/SCORMHelpers');
const SCORMStatusEnum = require('@common/data/enums/SCORMStatusEnum');

const {
  MEDIUM_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');

require('@common/libs/behaviors/card/Card');
require('@common/components/training_modules/scorm/SCORM.less');

class SCORMNewWindowCardView extends Marionette.ItemView {
  constructor(options = {}) {
    super(options);

    ({
      scormVersion: this.scormVersion,
      index: this.index
    } = options);

    this._getValue = this._getValue.bind(this);
  }

  getTemplate() {
    return `\
      <div class="ax-grid ax-grid--no-gutter">
        <div class="ax-grid__col--grow">
          <p><%- displayTitle %></p>
          <p class="scorm-status"><%- status %></p>
        </div>
        <div class="ax-grid__col--auto-size">
          <%= axButton({
            branded: true,
            label: t('general.launch'),
            ariaLabel: t('externalLink.launchExternalLink') + ' ' + displayTitle,
            size: 'm',
            className: 'js-launch-button',
          }) %>
        </div>
      </div>\
    `;
  }

  behaviors() {
    return {
      Card: {
        modifierClasses: [MEDIUM_PADDING]
      }
    };
  }

  triggers() {
    return {
      'click @ui.launchButton': 'click:launch'
    };
  }

  templateHelpers() {
    return {
      status: I18n.t('training.cards.info.notStarted'),
      displayTitle: SCORMHelpers.getSubModuleTitle(this.model.get('title'), this.index + 1)
    };
  }

  ui() {
    return {
      launchButton: '.js-launch-button',
      scormStatus: '.scorm-status'
    };
  }

  modelEvents() {
    return {
      'change:status': '_onStatusChange'
    };
  }

  onRender() {
    this._updateStatusAndButton();
  }

  _getValue(key) {
    return this.model.get('scoItem')?.scormModuleAnswer?.scoValues.find((item) => {
      return item.scoKey === key
    })?.scoValue;
  }

  _updateStatusAndButton() {
    this.model.set('status', SCORMStatusEnum.notStarted);
    if (SCORMHelpers.isScormComplete(this.scormVersion, this._getValue)) {
      this.model.set('status', SCORMStatusEnum.completed);
    } else if (this.model.get('scoItem')?.scormModuleAnswer?.scoValues.length > 0) {
      this.model.set('status', SCORMStatusEnum.started);
    }
  }

  _onStatusChange(model, status) {
    switch (status) {
      case SCORMStatusEnum.completed:
        this.indicateModuleCompleted();
        break;
      case SCORMStatusEnum.started:
        this.indicateModuleStarted()
        break;
      default:
        /* nomnomnom */
    }
  }

  indicateModuleStarted() {
    this.ui.launchButton.text(I18n.t('general.continue'));
    this.ui.scormStatus.text(I18n.t('metrics.milestones.categories.started'));
  }

  indicateModuleCompleted() {
    this.ui.launchButton.text(I18n.t('general.relaunch'));
    this.ui.scormStatus.text(I18n.t('referral.expiredMessage.completed'));
    this.ui.launchButton.toggleClass('ax-button--branded', false);
  }
}

module.exports = SCORMNewWindowCardView;
