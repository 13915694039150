// Module
var code = `<div class="tile-top">
	<div class="topic">
		<span class="hidden icon" role="img"></span>
		<p class="topic-name qa-topic-name"><%- program.name %></p>
	</div>

	<div class="topic-statuses">

		<div class="grade">
			<p class="grade-value"></p>
			<p class="grade-text"></p>
		</div>

		<div class="lock-icon"></div>

	</div>

</div>

<div class="tile-bottom-wrapper">
	<div class="tile-bottom clearfix">

		<div class="tile-meta scheduled-exam-tile-meta clearfix">

			<p class="first content"></p>
			<p class="mid qa-rewards rewards"></p>
			<p class="last qa-attempts attempts"></p>

		</div>

		<div class="start-button-wrapper">
			<button class="axon-button small start-button"><%- t('assessments.item.action.pass') %></button>
		</div>

	</div>
</div>
`;
// Exports
module.exports = code;