// Module
var code = `<div class="hotspot">
  <div class="questionarea <%- getTranslationStatusCSS() %> clearfix">
    <div class="questionask">
      <%- getTranslationStringIfNoText() %>
      <%= variant.get('questionText') %></div>
    <div class="clear"></div>
    <div class="bonuscharacter"><div class="<%= bonuscharacter %> ponder" aria-hidden="true"></div></div>
  </div>
  <div class="hinttext <%- answerStatus %>">
    <div class="question-instruction clearfix">
      <span id="balance-points-wrapper" class="clearfix <%- answerStatus %>">
        <span class="correct-incorrect full-size"><%- answerStatusText %></span>
      </span>
    </div>
    <div id="reason" class="clearfix">
      <% if(getReasonImage()) {%>
        <div class="image-wrapper imgwrap reason-image" data-media-id="<%- getReasonImage().id %>"></div>
      <%}%>
      <div id="reasonContent"><%= getReasonText() %></div>
    </div>
  </div>
  <div class="answerwrappersize">
    <div class="answerwrapper">
      <div class="hotspotimagewrapper">
        <div class="questionimage" data-media-id="<%- getQuestionImage().id %>">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="zoom-placeholder hidden">
`;
// Exports
module.exports = code;