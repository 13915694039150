class TincanCompletionTracker {
  /* eslint-disable
    no-unused-vars,
    disabled because of the signature needs it but requires a superclass to deal with it
  */
  startTracking(tincanPackage, lrsConfig, user) {
    throw new Error('You must implement this method.');
  }

  stopTracking() {
    throw new Error('You must implement this method.');
  }
}

module.exports = TincanCompletionTracker;
