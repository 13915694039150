const { createProgressMarkerViewConfig } = require('@training/apps/training/assessmentProgress/ProgressViewConfigFactory');
const { Started } = require('@training/apps/training/assessmentProgress/ProgressMarkerOptions');

class StartMarkerBuilder {
  build() {
    return createProgressMarkerViewConfig(Started);
  }
}

module.exports = StartMarkerBuilder;
