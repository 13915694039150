const _ = require('underscore');
const Backbone = require('Backbone');
const GameHelper = require('@common/libs/helpers/features/GameHelper');
const User = require('@common/data/models/AuthUser');
const ObjectHelpers = require('@common/libs/helpers/types/ObjectHelpers');

class Game extends Backbone.Model {
  defaults() {
    return {type: 'Quiz'};
  }

  apiEndpoint() {
    return '/games';
  }

  constructor() {
    super(...arguments);

    this.allTimeUser = new User();
    this.userScoreUser = new User();

    this.listenTo(this, 'change:scores', function(model, attributes) {
      const scores = attributes.singlePlayerScores;

      if ((scores != null ? scores.allTimeHigh : undefined) != null) {
        this.allTimeUser.set(scores.allTimeHigh.user);
      }

      if ((scores != null ? scores.userScore : undefined) != null) {
        return this.userScoreUser.set(scores.userScore.user);
      }
    });
  }

  parse(res = {}) {
    return res.entity || res;
  }

  clear() {
    super.clear(...arguments);
    this.set(this.defaults);
  }

  getMechanic(mechanicId = '') {
    return this.get('mechanics')[mechanicId];
  }

  isSupported() {
    return GameHelper.isSupportedGameType(this.get('type'));
  }

  isTeamGame() {
    return GameHelper.doesRequireTeam(this.get('type'));
  }

  hasBody() {
    return !_.isEmpty(this.get('body'));
  }

  hasLeaderboard() {
    return this.get('type') !== 'Quiz';
  }

  getGameScores() {
    const gameScoresDeferred = $.Deferred();

    if (this.get('type') === 'Quiz') {
      gameScoresDeferred.reject();
    } else {
      this.gameScoresRequest = this.fetch({url: `${ this.url() }/scores`})
        .done(() => {
          return gameScoresDeferred.resolve();
        })
        .fail(() => {
          return gameScoresDeferred.reject();
        });
    }

    return gameScoresDeferred.promise();
  }

  abortGameScores() {
    if (this.gameScoresRequest != null) {
      this.gameScoresRequest.abort();
    }
  }

  getGameMetadata() {
    const {
      poster,
      videoLinks
    } = GameHelper.getGameInfo(this.get('type'));
    const genre = GameHelper.getGameGenre(this.get('type'));
    const instructions = GameHelper.getGameInstructions(this.get('type'));

    return {
      genre,
      instructions,
      poster,
      videoLinks
    };
  }

  toJSON() {
    const JSON = super.toJSON(...arguments);

    if (ObjectHelpers.getDescendantProp(JSON, 'scores.singlePlayerScores.allTimeHigh') != null) {
      JSON.scores.singlePlayerScores.allTimeHigh.user = this.allTimeUser.toJSON();
    }

    if (ObjectHelpers.getDescendantProp(JSON, 'scores.singlePlayerScores.userScore') != null) {
      JSON.scores.singlePlayerScores.userScore.user = this.userScoreUser.toJSON();
    }

    return $.extend(true, {}, JSON, {metadata: this.getGameMetadata()});
  }
}

module.exports = Game;
