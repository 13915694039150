// Module
var code = `<div class="js-community-icon community-header__icon ax-grid__col ax-grid__col--auto-size ax-grid__col--align-self-start" aria-hidden="true"></div>
<div class="ax-grid ax-grid--direction-column mobile-breadcrumb-flex">
  <div class="breadcrumb-path" role="navigation">
    <div class="js-community-breadcrumb community-breadcrumb header-breadcrumbs"></div>
  </div>
  <h1 class="js-community-name community-name ax-font--no-margin"><%- communityName %></h1>
  <% if (!\$os.mobile && communityDescription) { %>
    <div class="js-community-description community-description"><%= communityDescription %></div>
    <div class="community-description-more">
      <button class="js-see-more-button qa-see-more-button community-description-more__button button-reset-style"><%- t('discover.browse.communityDescription.seeMore') %></button>
    </div>
  <% } %>
</div>
<div class="ax-grid ax-grid__col--auto-size ax-grid--justify-content-end ax-grid--no-gutter ax-grid__col--align-self-start">
  <div class="js-create-button-region community-header-create-button-region ax-grid ax-grid__col--auto-size ax-grid__col--align-self-end"></div>
  <% if (shouldShowCommunityManagementButton) { %>
    <div class="community-context-button-container ax-grid__col--auto-size">
      <button type="button" class="js-community-management-button community-context-button ax-button--icon" aria-label="<%- t('discover.browse.communitySettings') %>">
        <span class="icon-settings"></span>
      </button>
    </div>
  <% } %>
</div>
<% if (\$os.mobile && communityDescription) { %>
  <div class="ax-grid__col--12">
    <div class="js-community-description community-description"><%= communityDescription %></div>
    <div class="community-description-more">
      <button class="js-see-more-button qa-see-more-button community-description-more__button button-reset-style"><%- t('discover.browse.communityDescription.seeMore') %></button>
    </div>
  </div>
<% } %>
`;
// Exports
module.exports = code;