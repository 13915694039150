const { Model } = require('Backbone');

class UserKnowledgeScore extends Model {
  apiEndpoint() {
    return '/knowledge/scores';
  }

  parse(res) {
    return res.result || {};
  }
}

module.exports = UserKnowledgeScore;
