const Backbone = require('Backbone');
const Marionette = require('Marionette');

const TaskDetailHeaderView = require('@training/apps/legacyTasks/views/taskDetail/TaskDetailHeaderView');
const TaskDetailMainView = require('@training/apps/legacyTasks/views/taskDetail/TaskDetailMainView');
const TaskDetailAttachmentListView = require('@training/apps/legacyTasks/views/taskDetail/TaskDetailAttachmentListView');
const TaskDetailInfoView = require('@training/apps/legacyTasks/views/taskDetail/TaskDetailInfoView');


class TaskDetailLayoutView extends Marionette.LayoutView {
  getTemplate() {
    return require('@training/apps/legacyTasks/views/taskDetail/TaskDetailLayoutView.html');
  }

  className() {
    return 'task-detail';
  }

  regions() {
    return {
      headerRegion: '.task-detail-header-region',
      mainRegion: '.task-detail-main-region',
      attachmentsRegion: '.task-detail-attachments-region',
      infoRegionLargerViewport: '.task-detail-info-region-larger-viewport',
      infoRegionSmallerViewport: '.task-detail-info-region-smaller-viewport'
    };
  }

  onRender() {
    this._renderHeaderView();
    this._renderMainView();
    this._tryRenderAttachmentsView();
    this._renderInfoView(this.infoRegionLargerViewport);
    this._renderInfoView(this.infoRegionSmallerViewport);
  }

  _renderHeaderView() {
    const headerView = new TaskDetailHeaderView({
      model: new Backbone.Model({
        taskName: this.model.getName(),
        pointsAvailable: this.model.getPointsAvailable(),
        pointsEarned: this.model.getPointsEarned(),
        programName: this.model.getProgramName()
      })
    });
    this.headerRegion.show(headerView);
  }

  _renderMainView() {
    const mainView = new TaskDetailMainView({
      model: new Backbone.Model({
        doesRequireCompletion: this.model.doesRequireCompletion(),
        completionDate: this.model.getCompletionDate(),
        taskDescription: this.model.getDescription()
      })
    });
    this.mainRegion.show(mainView);
  }

  _tryRenderAttachmentsView() {
    const attachments = this.model.getAttachments();

    if (attachments.length) {
      const attachmentsView = new TaskDetailAttachmentListView({
        collection: attachments
      });

      this.listenTo(attachmentsView, 'childview:click:attachment', (childView, {model}) => {
        window.app.layout.showMedia(model);
      });

      this.attachmentsRegion.show(attachmentsView);
    } else {
      this.attachmentsRegion.$el.hide();
    }
  }

  _renderInfoView(region) {
    const infoView = new TaskDetailInfoView({
      model: new Backbone.Model({
        subjectName: this.model.getSubjectName(),
        topicName: this.model.getTopicName()
      })
    });
    region.show(infoView);
  }

  onDestroy() {
    window.app.layout.dismissMedia();
  }
}

module.exports = TaskDetailLayoutView;
