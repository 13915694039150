const { LayoutView } = require('Marionette');
const UIKit = require('@training/widgets/UIKit');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const {
  ARTICLE,
  POST,
  QUESTION,
  REFERENCE,
  TRAINING_ARTICLE
} = require('@training/apps/training/communitiesInsights/components/TypeFilterEnum');


class SelectionModel extends Backbone.Model {
  toOption() {
    const id = this.get('value');
    const value = this.get('name');
    return {
      id,
      value
    };
  }
}

class TypeFilterView extends LayoutView {
  initialize(options = {}) {
    this.filtersModel = options.filtersModel;

    const pageTypes = [ARTICLE, POST, QUESTION, REFERENCE, TRAINING_ARTICLE].map((type) => {
      return {
        value: type.serverId,
        name: I18n.t(type.string)
      };
    });

    pageTypes.unshift({
      value: -1,
      name: I18n.t('discover.insights.allTypes'),
      selected: true
    });

    this.typeCollection = new Backbone.Collection(pageTypes, {model: SelectionModel});
  }

  className() {
    return 'ax-grid ax-grid--no-gutter comms-type-filter';
  }

  getTemplate() {
    return require('@training/apps/training/communitiesInsights/components/TypeFilterTemplate.html');
  }

  ui() {
    return {
      form: 'form'
    };
  }

  onRender() {
    this.typeFilter = new UIKit.Form({
      el: this.ui.form,
      model: this.filtersModel,
      context: {
        typeFilterSelectOptions: {
          collection: this.typeCollection,
          axonSelectOptions: {
            width: '100%',
            disableSearch: true,
            useChosen: false
          }
        }
      }
    });
  }
}

module.exports = TypeFilterView;
