import LocalStorageHelpers from '@common/libs/helpers/app/LocalStorageHelpers';
import UrlHelpers from '@common/libs/helpers/app/UrlHelpers';
import Backbone from 'backbone';

export function persistTimelinePageData(
  timelineId: string,
  timelineTab: string,
  searchString: string = '',
  userId: number
) {
  // Store the timeline ID and tab in the URL hash as a primary reference, local storage as a backup
  // Search query only goes in the URL
  let url = `${ UrlHelpers.getLocationHash() }?id=${ timelineId }&tab=${ timelineTab }`;
  if (searchString !== '') {
    url += `&searchQuery=${ searchString }`;
  } else {
    // Store pageID in local storage, but only if a search wasn't performed
    LocalStorageHelpers.setItem('timelineReferenceItemIds', {
      [userId]: {
        item: timelineId,
        tab: timelineTab
      }
    });
  }

  Backbone.history.navigate(url, {
    trigger: false,
    replace: true
  });
}
