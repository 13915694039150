import {
  AvatarGroup as MuiAvatarGroup,
  styled,
  type AvatarGroupProps as MuiAvatarGroupProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';
import { useGetSizeStyles } from './hooks';
import { type AX_AVATAR_SIZE } from './types';

type AxAvatarGroupProps = Omit<MuiAvatarGroupProps, 'spacing'> & {
  /**
   * The size for all avatars in the group.
   * @default 'm'
   */
  size?: AX_AVATAR_SIZE;
};

const styledOptions = {
  name: 'AxAvatarGroup'
};

const StyledAxAvatarGroup = styled(MuiAvatarGroup, styledOptions)<AxAvatarGroupProps>(({
  size,
  theme
}) => {

  const fontSize = {
    xs: '1rem',
    sm: theme.uiKit.fontSizeXS,
    md: theme.uiKit.fontSizeM,
    lg: theme.uiKit.fontSizeL,
    xl: theme.uiKit.fontSizeXL
  };
  
  return {
    '.MuiAvatar-root': {
      ...useGetSizeStyles(size || 'md'),
      fontSize: fontSize[size || 'md']
    }
  };
});

/**
 * Wrapper for MUI AvatarGroup component.
 *
 * ### Links
 * - [AvatarGroup | MUI](https://mui.com/material-ui/react-avatar/#grouped)
 */
export const AxAvatarGroup = forwardRef(({
  children,
  ...otherProps
}: AxAvatarGroupProps, ref: Ref<HTMLDivElement>) => {
  const AvatarGroupProps: AxAvatarGroupProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxAvatarGroup { ...AvatarGroupProps } ref={ ref }>
      { children }
    </StyledAxAvatarGroup>
  );
});

export default AxAvatarGroup;
