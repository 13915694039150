const MenuPosition = require('@common/modules/main/menu/MenuPosition');

const MAX_ITEMS_DISPLAY_BOTTOM = 5; // including more button
const MAX_ITEMS_DISPLAY_TOP = 5; // not including more button
const MIN_ITEMS_DISPLAY_LOCKED_TO_TOP = 3;

const _getDisplayItemsArrayTop = ({
  items,
  menuTopWidthAvailable,
  isLockedToTop
}) => {
  if (!menuTopWidthAvailable) {
    return [];
  }

  let totalWidth = 0;
  const result = [];

  items.some((menuItem) => {
    const itemWidth = menuItem.get('displayWidth');
    if (
      itemWidth != null
      && ((itemWidth + totalWidth) < menuTopWidthAvailable)
      && (result.length < MAX_ITEMS_DISPLAY_TOP)
    ) {
      totalWidth += itemWidth;
      result.push(menuItem);
      return false;
    } else if (isLockedToTop && (result.length < MIN_ITEMS_DISPLAY_LOCKED_TO_TOP)) {
      result.push(menuItem);
      return false;
    }

    return true;
  });

  return result;
};

const _getDisplayItemsArrayBottom = ({
  items,
  isMinimalLayout
}) => {
  if (isMinimalLayout) {
    return [];
  }

  const numItems = items.length;
  let result;

  if (numItems === MAX_ITEMS_DISPLAY_BOTTOM) {
    result = items.slice(0, MAX_ITEMS_DISPLAY_BOTTOM);
  } else if (numItems > MAX_ITEMS_DISPLAY_BOTTOM) {
    result = items.slice(0, MAX_ITEMS_DISPLAY_BOTTOM - 1); // more button required
  } else {
    result = items.slice(0);
  }

  return result;
};

const _getDisplayItemsArray = ({
  allItemsCollection,
  currentMenuPosition,
  menuTopWidthAvailable,
  isMinimalLayout,
  isLockedToTop
} = {}) => {
  const filteredItems = allItemsCollection.filter((viewConfig) => {
    return (
      (isLockedToTop && viewConfig.get('isLockedToTop'))
      || (!viewConfig.get('isProfileItem') && !viewConfig.get('isAppItem'))
    );
  });

  const displayItems = currentMenuPosition === MenuPosition.Bottom
    ? _getDisplayItemsArrayBottom({
      items: filteredItems,
      isMinimalLayout
    })
    : _getDisplayItemsArrayTop({
      items: filteredItems,
      menuTopWidthAvailable,
      isLockedToTop
    });

  return displayItems;
};

const _getProfileItemsArray = ({
  allItemsCollection,
  isLockedToTop
} = {}) => {
  return allItemsCollection.filter((viewConfig) => {
    return viewConfig.get('isProfileItem')
      && (!isLockedToTop || !viewConfig.get('isLockedToTop'));
  });
};

const _getOverflowItemsArray = ({
  allItemsCollection,
  displayItemsArray,
  profileItemsArray
} = {}) => {
  return allItemsCollection.filter((viewConfig) => {
    const id = viewConfig.get('id');
    const notInOverflowItems = displayItemsArray.concat(profileItemsArray);
    return !notInOverflowItems.find((item) => {
      return item.get('id') === id;
    });
  });
};

const _adjustDisplayAndOverflowItemsForBottom = ({
  displayItemsArray,
  overflowItemsArray
} = {}) => {
  if (overflowItemsArray.length > 0 && displayItemsArray.length === MAX_ITEMS_DISPLAY_TOP) {
    overflowItemsArray.unshift(displayItemsArray.pop());
  }
};

const getItemArraysFromMenuItemsCollection = ({
  allItemsCollection,
  currentMenuPosition,
  menuTopWidthAvailable,
  isMinimalLayout,
  isLockedToTop
} = {}) => {
  const displayItemsArray = _getDisplayItemsArray({
    allItemsCollection,
    currentMenuPosition,
    menuTopWidthAvailable,
    isMinimalLayout,
    isLockedToTop
  });
  const profileItemsArray = _getProfileItemsArray({
    allItemsCollection,
    isLockedToTop
  });
  const overflowItemsArray = _getOverflowItemsArray({
    allItemsCollection,
    displayItemsArray,
    profileItemsArray
  });

  if (currentMenuPosition === MenuPosition.Bottom) {
    _adjustDisplayAndOverflowItemsForBottom({
      displayItemsArray,
      overflowItemsArray
    });
  }

  return {
    displayItemsArray,
    overflowItemsArray,
    profileItemsArray
  };
};

const getMsTeamsItemArray = (allItemsCollection) => {
  return allItemsCollection.filter((viewConfig) => {
    return !viewConfig.get('hideMsTeams');
  });
};

module.exports = {
  getItemArraysFromMenuItemsCollection,
  getMsTeamsItemArray
};
