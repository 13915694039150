const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  Local: {
    serverId: 'LocalDB',
    modelAttr: 'isLocalEnabled',
    urlStr: 'axonify-managed'
  },
  SAML: {
    serverId: 'SAML',
    modelAttr: 'isSAMLEnabled',
    urlStr: 'saml'
  },
  SecureUrl: {
    serverId: 'URIToken',
    modelAttr: 'isSecureUrlEnabled',
    urlStr: 'secure-url'
  },
  SelfServe: {
    serverId: 'SelfServe',
    modelAttr: 'isSelfServeEnabled'
  },
  OAuth: {
    serverId: 'OAuth',
    modelAttr: 'isOAuthEnabled',
    urlStr: 'oauth'
  }
});
