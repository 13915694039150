const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');

const AssessmentInitiatorPersistantTracker = require('./AssessmentInitiatorPersistantTracker');
const ClientStorageAssessmentInitiatorTracker = require('./ClientStorageAssessmentInitiatorTracker');
const CookieStorageAssessmentInitiatorTracker = require('./CookieStorageAssessmentInitiatorTracker');

const ClientStorageTrackerFactory = (registry) => {
  const indexableStateObject = LocalStorageHelpers.getStateStorageObject();
  return new ClientStorageAssessmentInitiatorTracker(registry, indexableStateObject);
};


// Arbitrary time offset so that cookie expires eventually. 2 weeks
const COOKIE_STORAGE_EXPIRY_OFFSET_HOURS = 24;
const CookieStorageTrackerFactory = (registry) => {
  const expiryDate = new Date();
  expiryDate.setHours(expiryDate.getHours() + COOKIE_STORAGE_EXPIRY_OFFSET_HOURS);

  return new CookieStorageAssessmentInitiatorTracker(registry, {
    expires: expiryDate
  });
};

class AssessmentInitiatorTrackerFactory {
  static create(registry) {
    return new AssessmentInitiatorPersistantTracker(registry, ClientStorageTrackerFactory, CookieStorageTrackerFactory);
  }
}

module.exports = AssessmentInitiatorTrackerFactory;
