// Module
var code = `<tr class="ranked-row<% if (item.isMe) {%> you<% } %>" data-rank="<%- item.rank %>">
  <td class="center rank-wrapper">
    <% if (item.rank > -1) { %>
      <div class="rank">
        <%- item.rank + 1 %>
      </div>
    <% } %>
  </td>
  <td class="avatar-wrapper" aria-hidden="true">
    <div class="name-logo"></div>
  </td>
  <td>
    <div class="name text-ellipsis-softwrap">
      <%- item.name %>
        <% if (item.isMe) { %>
          (<%- t('stats.you') %>)
        <% } %>
    </div>
  </td>
  <td class="center">
    <%- item.points %>
  </td>
</tr>
`;
// Exports
module.exports = code;