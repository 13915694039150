const { getValue } = require('@common/libs/helpers/types/ObjectHelpers');
const {
  proxyEvent,
  unproxyEvent
} = require('@common/libs/helpers/features/EventHelpers');

const ViewController = require('@common/libs/UI/controllers/ViewController');

/*

  LayoutController

  ViewController class that wraps a LayoutView and is responsible for configuring Region to ViewController mappings
  and making sure they get shown properly.

*/

class LayoutController extends ViewController {

  _regionControllerDefinitions = {};

  constructor(options) {
    super(options);

    this.findControllerById = this.findControllerById.bind(this);
    this.findControllerByView = this.findControllerByView.bind(this);

    this._initializeRegionControllerManager(options);

    // Make sure to call this on the controller after it's created. This ensures that the constructor of all it's sub classes
    // have a chance to fully run and initialize data before the regionControllers mapping is generated and added.
    this.initializeRegionControllers = this._initializeRegionControllers.bind(this, options);
  }

  inflateView(...args) {
    super.inflateView(...args);
    this.listenTo(this.getView(), 'before:show', this._onViewBeforeShow);
    this.listenTo(this.getView(), 'dom:refresh', this._onViewBeforeShow);
  }

  hasRegion(regionName) {
    return this.getRegion(regionName) != null;
  }

  getRegion(regionName) {
    return this.getView().getRegion(regionName);
  }

  addRegionController(regionName, controllerDefinition) {
    this._regionControllerDefinitions[regionName] = controllerDefinition;
    this._regionControllerManager.addRegionController(regionName, controllerDefinition);
  }

  removeRegionController(regionName, options = {}) {
    this.emptyRegion(regionName, options);
    delete this._regionControllerDefinitions[regionName];
    this._regionControllerManager.removeController(regionName);
  }

  addRegionControllers(regionControllers = {}) {
    Object.assign(this._regionControllerDefinitions, regionControllers);
    this._regionControllerManager.addRegionControllers(regionControllers);
  }

  showRegionControllers(regionControllers = {}, showOptions = {}) {
    Object.keys(regionControllers).forEach((key) => {
      if (!regionControllers[key].isDestroyed) {
        this.showRegionController(key, showOptions);
      }
    });
  }

  showRegionController(regionName, showOptions) {
    this._assertInflatedView();
    this._assertRegionName(regionName);

    const region = this.getRegion(regionName);
    const controller = this.findControllerByRegion(regionName);
    controller.show(region, showOptions);
  }

  emptyRegion(regionName, options) {
    this._assertInflatedView();

    if (this.hasRegion(regionName)) {
      this.getRegion(regionName).empty(options);
    }
  }

  replaceRegionController(regionName, controllerDefinition) {
    this.removeRegionController(regionName);
    this.addRegionController(regionName, controllerDefinition);
  }

  resetRegionController(regionName, showOptions) {
    const controllerDefinition = this.getRegionControllerDefinition(regionName);

    this.replaceRegionController(regionName, controllerDefinition);
    this.showRegionController(regionName, showOptions);
  }

  swapRegionController(regionName, controllerDefinition, showOptions) {
    if (controllerDefinition == null) {
      this.removeRegionController(regionName);
    } else {
      this.replaceRegionController(regionName, controllerDefinition);
      this.showRegionController(regionName, showOptions);
    }
  }

  getRegionControllerDefinition(regionName) {
    return this._regionControllerDefinitions[regionName];
  }

  findControllerById(id) {
    let controller = super.findControllerById(id);

    if (controller == null) {
      controller = Object.values(this._regionControllerManager.getControllers()).reduce((foundController, controller) => {
        if (foundController != null) {
          return foundController;
        }

        return controller.findControllerById(id);
      }, null);
    }

    return controller;
  }

  findControllerByView(view) {
    let controller = super.findControllerByView(view);

    if (controller == null) {
      controller = Object.values(this._regionControllerManager.getControllers()).reduce((foundController, controller) => {
        if (foundController != null) {
          return foundController;
        }
        return controller.findControllerByView(view);
      }, null);
    }

    return controller;
  }

  findControllerByRegion(regionName) {
    return this._regionControllerManager.get(regionName);
  }

  getRegionControllerManager() {
    return this._regionControllerManager;
  }

  destroy(...args) {
    this._destroyRegionControllerManager();

    super.destroy(...args);

    return this;
  }

  /* Private methods */


  // XXX I regret everything about this design to have the LayoutController add it's own region-controlller mappings :(
  _initializeRegionControllers(options = {}) {
    const regionControllers = getValue(this.regionControllers, this, options);
    const optionRegionControllers = getValue(options.regionControllers, this, options);

    this.addRegionControllers(Object.assign({}, regionControllers, optionRegionControllers));
  }

  _initializeRegionControllerManager(options = {}) {
    const { regionControllerManager } = options;

    if (!regionControllerManager) {
      throw new Error('Missing \'regionControllerManager\' option while creating LayoutController');
    }

    this._regionControllerManager = regionControllerManager;

    proxyEvent(this, this._regionControllerManager, 'all');
  }

  _destroyRegionControllerManager() {
    unproxyEvent(this, this._regionControllerManager, 'all');

    this._regionControllerManager.destroy();
  }

  _onViewBeforeShow(view, region, showOptions) {
    this.showRegionControllers(this._regionControllerManager.getControllers(), showOptions);
  }

  _assertInflatedView() {
    if (!this.isViewInflated()) {
      throw new Error('The view you\'re trying to access hasn\'t been instantiated yet. Check your lifecycle reasoning.');
    }
  }

  _assertRegionName(regionName) {
    if (!this.hasRegion(regionName)) {
      throw new Error(`The region '${ regionName }' doesn't exist in the layout. Has it been misspelled or not been defined in the layout?`);
    }
  }
}

module.exports = LayoutController;
