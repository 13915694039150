const _ = require('underscore');
const logging = require('logging');
const UIKit = require('@training/widgets/UIKit');

const FlowController = require('./FlowController');
const UserMessageList = require('../collections/UserMessageList');
const UserMessagePage = require('../views/UserMessagePage');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
const RecognitionPinsUserMsgFactory = require('@training/apps/recognitions/notifications/RecognitionPinsUserMsgFactory').default;

class UserMessagesController extends FlowController {
  constructor(...args) {
    super(...args);

    this.processSequenceFlow = this.processSequenceFlow.bind(this);
    this.show = this.show.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);

    this.userMessageList = new UserMessageList();
    this.getUserMessages = _.memoize(() => {
      return Promise.resolve(this.userMessageList.fetch({showSpinner: false}));
    });
  }

  processSequenceFlow() {
    logging.debug('Processing User Messages');

    return this.getUserMessages().then(() => {
      if (this.userMessageList.hasUnreadMessages()) {
        const unreadUserMessages = this.userMessageList.getUnreadUserMessages();

        if (unreadUserMessages.length > 0) {
          const userMessage = unreadUserMessages.first();
          let timeLogId = null;

          this.show(userMessage, {
            onShow: () => {
              timeLogId = window.apps.base.timeLogController.startPageViewUserMessage(userMessage.get('id'));
            },
            complete: () => {
              try {
                window.apps.base.timeLogController.stop(timeLogId);
              } catch (e) {
                logging.error(e);
              }


              userMessage.markAsRead();
              this.finishedProcessing();
            }
          });
        
          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        }

        const unreadRecognitionPinMessages = this.userMessageList.getUnreadRecognitionPinMessages();

        if (unreadRecognitionPinMessages.length > 0) {
          window.app.layout.setView(RecognitionPinsUserMsgFactory({
            messages: unreadRecognitionPinMessages.toJSON(),
            actionBar: window.app.layout.actionBar,
            complete: () => {
              unreadRecognitionPinMessages.markAllRead();
              this.finishedProcessing();
            }
          }), UIKit.View.Transitions.FADE);

          return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
        }
      }

      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  show(model, options = {}) {
    const defaultOptions = {
      transition: UIKit.View.Transitions.FADE,
      modal: false,
      complete: _.noop,
      onShow: _.noop
    };

    const mergedOptions = _.defaults(options, defaultOptions);

    const {
      transition,
      modal,
      complete,
      onShow
    } = mergedOptions;

    logging.info(`Showing UserMessagePage - id: ${ model.get('id') } - type: ${ model.get('type') } - transition: ${ transition } - modal: ${ modal }`);

    const userMessagePage = new UserMessagePage({
      model,
      complete: () => {
        logging.info(`Completing Activity - id: ${ model.get('id') }`);
        window.app.layout.flash.clear();
        complete();
      }
    });

    if (modal) {
      window.app.layout.setViewInModal(userMessagePage, transition);
    } else {
      window.app.layout.setView(userMessagePage, transition);
    }

    onShow();
  }

  finishedProcessing() {
    logging.debug('Finished processing User Messages');

    if (this.parentProcessor.processSequenceFlow) {
      this.parentProcessor.processSequenceFlow();
    }
  }
}

module.exports = UserMessagesController;
