const logging = require('logging');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

const {
  DAILY_REINFORCEMENT,
  TOPIC_CERTIFICATION,
  TOPIC_EXTRA_TRAINING,
  TOPIC_REFRESHER,
  TOPIC_INTRO,
  PATH_ENROLLED,
  PATH_ASSIGNED,
  SUMMARY_EXAMS,
  SUMMARY_INTRO,
  SUMMARY_REFRESHER,
  SUMMARY_CERTIFICATIONS,
  DISCOVER_CARD,
  TRAINING_PAGE
} = require('@common/components/trainingCards/enums/TrainingCardTypes');

module.exports = {
  getData(model, startAssessment, actionOverride) {
    let trainingData = {};
    const trainingCardType = model.getCardType();

    if (this._isTopic(trainingCardType)) {

      trainingData = this.buildTopicData(model, trainingCardType, startAssessment);

    } else if (this._isDailyReinforcement(trainingCardType)) {

      trainingData = this.buildReinforcementData(model, startAssessment);

    } else if (this._isPath(trainingCardType)) {

      trainingData = this.buildPathData(model, trainingCardType, actionOverride);

    } else if (this._isSummary(trainingCardType)) {

      trainingData = this.buildSummaryData(model, trainingCardType);

    } else if (trainingCardType === TRAINING_PAGE) {

      trainingData = this.buildTrainingData(model, trainingCardType);

    } else if (trainingCardType === DISCOVER_CARD) {

      trainingData = {
        trainingCardType
      };

    } else {

      logging.warn(`Training Card Type could not be identified: ${ trainingCardType }`);

    }

    return trainingData;
  },


  buildTopicData() {
    // Noop
    return {};
  },

  buildReinforcementData() {
    // Noop
    return {};
  },

  buildPathData(model, trainingCardType, actionOverride) {
    const daysUntilDue = model.getDaysUntilDue();
    let action = () => {};

    if (actionOverride) {
      action = () => {
        actionOverride(model);
      };
    }

    return {
      trainingCardType,
      thumbnailImage: model.getThumbnailImage(),
      isAssigned: model.getIsAssigned(),
      title: model.getName(),
      isComplete: model.getIsComplete(),
      numCompleted: model.getNumCompleted(),
      total: model.getTotal(),
      daysUntilDue,
      isDueToday: daysUntilDue === 0,
      isOverdue: daysUntilDue && daysUntilDue < 1,
      progressPercent: model.getProgress(),
      hasReassignedItems: model.hasReassignedItems(),
      fullCardCallback: action
    };
  },

  buildSummaryData(model, trainingCardType) {
    const config = this._getSummaryConfig(trainingCardType);
    const total = model.getTotal();
    const numCompleted = model.getNumCompleted();
    return {
      trainingCardType,
      isAssigned: trainingCardType !== TRAINING_PAGE,
      title: config.title,
      isComplete: model.getIsComplete(),
      numCompleted,
      total,
      numAvailable: total - numCompleted,
      progressPercent: total > 0 ? Math.round((numCompleted / total) * 100) : 100,
      fullCardCallback: () => {
        Backbone.history.navigate(config.hash, {
          trigger: true
        });
      }
    };
  },

  buildTrainingData(model, trainingCardType) {
    const hash = '#hub/training/topics';
    const title = I18n.t('hub.myTraining');
    const total = model.getTotal();
    const numCompleted = model.getNumCompleted();
    return {
      trainingCardType,
      isAssigned: false,
      title,
      isComplete: model.getIsComplete(),
      numCompleted,
      total,
      numAvailable: total - numCompleted,
      progressPercent: total > 0 ? Math.round((numCompleted / total) * 100) : 100,
      actionType: '',
      actionCallback: () => {
        Backbone.history.navigate(hash, {
          trigger: true
        });
      },
      fullCardCallback: () => {
        Backbone.history.navigate(hash, {
          trigger: true
        });
      }
    };
  },

  _isDailyReinforcement(trainingCardType) {
    return DAILY_REINFORCEMENT === trainingCardType;
  },

  _isTopic(trainingCardType) {
    return [
      TOPIC_CERTIFICATION,
      TOPIC_EXTRA_TRAINING,
      TOPIC_REFRESHER,
      TOPIC_INTRO
    ].includes(trainingCardType);
  },

  _isPath(trainingCardType) {
    return [
      PATH_ENROLLED,
      PATH_ASSIGNED
    ].includes(trainingCardType);
  },

  _isSummary(trainingCardType) {
    return [
      SUMMARY_EXAMS,
      SUMMARY_INTRO,
      SUMMARY_REFRESHER,
      SUMMARY_CERTIFICATIONS
    ].includes(trainingCardType);
  },

  _getSummaryConfig(trainingCardType) {
    switch (trainingCardType) {
      case SUMMARY_EXAMS:
        return {
          hash: '#hub/exams',
          title: I18n.t('hub.formalExams')
        };
      case SUMMARY_INTRO:
        return {
          hash: '#hub/newTopics',
          title: I18n.t('hub.newTopics')
        };
      case SUMMARY_REFRESHER:
        return {
          hash: '#hub/refresher',
          title: I18n.t('hub.refresher')
        };
      case SUMMARY_CERTIFICATIONS:
        return {
          hash: '#hub/certifications',
          title: I18n.t('hub.certifications')
        };
      case TRAINING_PAGE:
        return {
          hash: '#hub/training/topics',
          title: I18n.t('hub.myTraining')
        };
      default:
        return {};
    }
  }
};
