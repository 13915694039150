const { LayoutView } = require('Marionette');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const Form = require('@common/components/forms/Form');
const LanguageList = require('@common/data/collections/LanguageList');
const I18n = require('@common/libs/I18n');

require('@common/components/forms/editors/translatableName/Form.Editor.TranslatableName');
class TagGroupFiltersContentView extends LayoutView {
  initialize(options = {}) {
    this.model = options.model;
  }

  get template() {
    return require('@training/apps/search/communitiesManagement/tagGroupFilters/content/TagGroupFiltersEditContentView.html');
  }

  templateHelpers() {
    return {
      name: this.model.getName() || ''
    };
  }

  className() {
    return 'tag-group-filters-content';
  }

  ui() {
    return {
      form: '.js-form',
      saveButton: '.js-tag-save',
      cancelButton: '.js-tag-cancel',
      deleteButton: '.js-tag-delete'
    };
  }

  events() {
    return {
      'click @ui.saveButton': this._onSave,
      'click @ui.cancelButton': this._onCancel,
      'click @ui.deleteButton': this._onDelete
    };
  }

  regions() {
    return {
      tagsRegion: '#tags_region'
    };
  }

  _onSave() {
    this.trigger('save');
  }

  _onCancel() {
    this.trigger('cancel');
  }

  _onDelete() {
    this.trigger('delete');
  }

  onAttach() {
    const languages = TenantPropertyProvider.get().getProperty('languages');
    if (!this.model.get('id')) {
      this.ui.deleteButton.hide();
    }
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        translatableNameOptions: {
          isLocalizableString: true,
          maxLength: 60,
          inputLabel: I18n.t('communitiesManagement.filters.name'),
          languageList: LanguageList.fromLanguageCodeList(languages),
          useChosen: true,
          showValuesObject: this.model.get('names').get('translations')
        }
      }
    });

    this.listenTo(this.model, 'sync change update', () => {
      this._updateForm();
    });
    this._updateForm();
  }

  _updateForm() {
    this.form.update(this.model);
  }

}

module.exports = TagGroupFiltersContentView;
