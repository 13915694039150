const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const UIKit = require('@training/widgets/UIKit');
const _ = require('underscore');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const PageType = require('@common/components/discover/enums/PageType');

const onDeletePage = (languageData, pageType) => {
  if (!languageData.get('bundleId')) {
    return;
  }

  _showDeleteConfirmation({
    bundleId: languageData.get('bundleId'),
    pageType
  });
};

const onDeleteLanguageVersion = (model, languageData) => {
  _showDeleteConfirmation({
    model,
    languageData
  });
};

const _getLanguageName = (languageCode) => {
  return I18n.t('languages.' + languageCode
    .toUpperCase()
    .replace(/-|_/g, ''));
};

const _getDeleteAllLanguagesConfirmationText = (pageType) => {
  switch (pageType) {
    case PageType.ARTICLE:
      // This will permanently remove <strong>all language versions</strong> of the article and cannot be undone.
      return I18n.t('discover.publishFlow.deleteArticleConfirmation');

    case PageType.QUESTION:
      // This will permanently remove the question and cannot be undone.
      return I18n.t('discover.publishFlow.deleteQuestionMonoglotConfirmation');
      // // This will permanently remove <strong>all language versions</strong> of the question and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteQuestionConfirmation');

    case PageType.TRAINING:
      // // NOTE: in the UI we are calling Training Modules "articles".
      // This will permanently remove the article and cannot be undone.
      return I18n.t('discover.publishFlow.deleteArticleMonoglotConfirmation');
      // This will permanently remove the training module and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteTrainingModuleMonoglotConfirmation');
      // // This will permanently remove <strong>all language versions</strong> of the training module and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteTrainingModuleConfirmation');

    case PageType.REFERENCE:
      // This will permanently remove the link and cannot be undone.
      return I18n.t('discover.publishFlow.deleteLinkMonoglotConfirmation');
      // // This will permanently remove <strong>all language versions</strong> of the link and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteLinkConfirmation');

    default:
      return I18n.t('discover.publishFlow.deleteArticleConfirmation');
  }
};

const _getDeleteOneLanguageConfirmationText = (pageType, languageName) => {
  switch (pageType) {
    case PageType.ARTICLE:
      // This will permanently remove the <strong><%- languageName %></strong> version of your article and cannot be undone.
      return I18n.t('discover.publishFlow.deleteArticleLanguageVersionConfirmation', {languageName});

    case PageType.QUESTION:
      // This will permanently remove the question and cannot be undone.
      return I18n.t('discover.publishFlow.deleteQuestionMonoglotConfirmation');
      // // This will permanently remove the <strong><%- languageName %></strong> version of the link and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteQuestionLanguageVersionConfirmation', {languageName});

    case PageType.TRAINING:
      // // NOTE: In the UI, training modules are called "Articles"
      // This will permanently remove the article and cannot be undone.
      return I18n.t('discover.publishFlow.deleteArticleMonoglotConfirmation');
      // // This will permanently remove the training module and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteTrainingModuleMonoglotConfirmation');
      // // This will permanently remove the <strong><%- languageName %></strong> version of the training module and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteTrainingModuleLanguageVersionConfirmation', {languageName});

    case PageType.REFERENCE:
      // This will permanently remove the training module and cannot be undone.
      return I18n.t('discover.publishFlow.deleteLinkMonoglotConfirmation');
      // // This will permanently remove the <strong><%- languageName %></strong> version of the link and cannot be undone.
      // return I18n.t('discover.publishFlow.deleteLinkLanguageVersionConfirmation', {languageName});

    default:
      return I18n.t('discover.publishFlow.deleteArticleLanguageVersionConfirmation', {languageName});
  }
};

const _getDeleteAllLanguagesSuccessMessage = (pageType) => {
  switch (pageType) {
    case PageType.ARTICLE:
      // The article has been deleted.
      return I18n.t('discover.publishFlow.deleteArticleSuccess');

    case PageType.QUESTION:
      // The question has been deleted.
      return I18n.t('discover.publishFlow.deleteQuestionSuccess');

    case PageType.TRAINING:
      // // NOTE: in the UI, training modules are called "articles"
      // The article has been deleted.
      return I18n.t('discover.publishFlow.deleteArticleSuccess');
      // // The training module has been deleted.
      // return I18n.t('discover.publishFlow.deleteTrainingModuleSuccess');

    case PageType.REFERENCE:
      // The link has been deleted.
      return I18n.t('discover.publishFlow.deleteLinkSuccess');

    default:
      return I18n.t('discover.publishFlow.deleteArticleSuccess');
  }
};

const _getDeleteOneLanguageSuccessMessage = (pageType, languageName) => {
  switch (pageType) {
    case PageType.ARTICLE:
      // The <strong><%- languageName %></strong> version of the article has been deleted.
      return I18n.t('discover.publishFlow.deleteArticleLanguageVersionSuccess', {languageName});

    case PageType.QUESTION:
      // The question has been deleted.
      return I18n.t('discover.publishFlow.deleteQuestionSuccess');
      // // The <strong><%- languageName %></strong> version of the question has been deleted.
      // return I18n.t('discover.publishFlow.deleteQuestionLanguageVerionSuccess', {languageName});

    case PageType.TRAINING:
      // // NOTE: in the UI, training modules are called "articles"
      // The article has been deleted.
      return I18n.t('discover.publishFlow.deleteArticleSuccess');
      // // The training module has been deleted.
      // return I18n.t('discover.publishFlow.deleteTrainingModuleSuccess');
      // // The <strong><%- languageName %></strong> version of the training module has been deleted.
      // return I18n.t('discover.publishFlow.deleteTrainingModuleLanguageVersionSuccess', {languageName});

    case PageType.REFERENCE:
      // The link has been deleted.
      return I18n.t('discover.publishFlow.deleteLinkSuccess', {languageName});
      // // The <strong><%- languageName %></strong> version of the link has been deleted.
      // return I18n.t('discover.publishFlow.deleteLinkLanguageVersionSuccess', {languageName});

    default:
      return I18n.t('discover.publishFlow.deleteArticleSuccess', {languageName});
  }
};

/*
 * Note that this one returns the key, not the string itself
 */
const _getDeleteTitleKey = (pageType) => {
  switch (pageType) {
    case PageType.ARTICLE:
      // // Delete Article
      return 'discover.publishFlow.deleteArticle';

    case PageType.QUESTION:
      // // Delete Question
      return 'discover.publishFlow.deleteQuestion';

    case PageType.TRAINING:
      // // NOTE: In the UI we are referring to Training Modules as "Articles". This is contentious
      // // Delete Article
      return 'discover.publishFlow.deleteArticle';
      // // Delete Training Module
      // // return 'discover.publishFlow.deleteTrainingModule';

    case PageType.REFERENCE:
      // Delete Link
      return 'discover.publishFlow.deleteLink';

    default:
      return 'discover.publishFlow.deleteArticle';
  }
};

/*
 * Note that this one returns the key, not the string itself
 */
const _getDeleteLanguageVersionTitleKey = (pageType) => {
  switch (pageType) {
    case PageType.ARTICLE:
      // // Delete <%- languageName %> Article
      return 'discover.publishFlow.deleteLanguageNameArticle';
      // // Delete Language Version
      // return 'discover.publishFlow.deleteLanguageVersion';

    case PageType.QUESTION:
      // // Delete <%- languageName %> Question
      return 'discover.publishFlow.deleteLanguageNameQuestion';
      // // Delete Question
      // return 'discover.publishFlow.deleteQuestion';

    case PageType.TRAINING:
      // // NOTE: in the UI we are referring to Training Modules as "Articles"
      // // Delete <%- languageName %> Article
      return 'discover.publishFlow.deleteLanguageNameArticle';
      // // Delete Article
      // return 'discover.publishFlow.deleteArticle';
      // // Delete Training Module
      // return 'discover.publishFlow.deleteTrainingModule';

    case PageType.REFERENCE:
      // // Delete <%- languageName %> Link
      return 'discover.publishFlow.deleteLanguageNameLink';
      // // Delete Link
      // return 'discover.publishFlow.deleteLink';

    default:
      // // Delete <%- languageName %> Article
      return 'discover.publishFlow.deleteLanguageNameArticle';
      // return 'discover.publishFlow.deleteLanguageVersion';
  }
};

const _showDeleteConfirmation = (options = {}) => {
  const modalView = new AccessibleModalView({
    id: 'modalview',
    className: 'modal confirm-dialog-view modal--s'
  });

  let pageType = PageType.ARTICLE;
  if (options.model) {
    // when the user clicks "delete", there is a model
    pageType = options.model.get('type');
  } else if (options.pageType) {
    // when user clicks "delete all", there is no model in the options, so it passes in the pageType instead
    pageType = options.pageType;
  }

  // by default, we set these to the values we'd expect when there is no model, ie when the user clicked "delete all"
  let titleKey = _getDeleteTitleKey(pageType);
  let titleContextObject = {};
  let confirmationText = _getDeleteAllLanguagesConfirmationText(pageType);

  // by putting the pageType into the options, now it can be passed to the _deletePage method elegantly
  options.pageType = pageType;

  let confirmCallback = () => {
    _deletePage(options);
  };

  // if there is a model, we rewrite the titleKey and confirmationText with phrases appropriate to that, and the
  // comfirmCallback is different.
  if (options.model) {
    titleKey = _getDeleteLanguageVersionTitleKey(
      options.model.get('type')
    );
    titleContextObject = {
      languageName: _getLanguageName(options.model.get('language'))
    };
    
    confirmationText = _getDeleteOneLanguageConfirmationText(
      options.model.get('type'),
      _getLanguageName(options.model.get('language'))
    );
    confirmCallback = () => {
      _deleteLanguageVersion(options);
    };
  }

  const modalChildView = new ConfirmDialogView({
    confirmCallback,
    title: I18n.t(titleKey, titleContextObject),
    confirmationText,
    buttonText: I18n.t('general.delete'),
    iconClass: 'icon-trash red'
  });

  window.app.layout.presentModal(modalView, { closeClick: false });
  modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

  modalView.listenToOnce(modalChildView, 'destroy', () => {
    window.app.layout.dismissModal();
  });
};

const _delete = (options = {
  deleteFn: () => {
    return Promise.resolve();
  },
  successMessage: '',
  navigateFn: () => {}
}) => {
  options.deleteFn().then(() => {
    window.app.layout.flash.success(options.successMessage);
    
    options.navigateFn();
  },
  (xhr) => {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();
    
    // Users access rights have changed, flash error and reload page
    if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      _handlePageAccessError(xhr, 'discover.pageTypes.generic.error.3017');
    
      window.location.reload(true);
    
      // Page either didn't exist or has already been deleted. Show success anyways.
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_VALID_ENTITY) {
      xhr.skipGlobalHandler = true;
    
      window.app.layout.flash.success(options.successMessage);
    
      options.navigateFn();
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      _handlePageAccessError(xhr, `discover.pageTypes.article.error.${ errCode }`);
      options.navigateFn();
    }
  });
};

/**
 * This method does a "delete all" of an entire bundle
 */
const _deletePage = (options) => {
  _delete({
    deleteFn: () => {
      return $.ajax({
        type: 'DELETE',
        apiEndpoint: `/pages/bundle/${ options.bundleId }`
      });
    },
    successMessage: _getDeleteAllLanguagesSuccessMessage(options.pageType),
    navigateFn: _navigateBack
  });
};

/**
 * This method does a delete of just one page, aka a language version
 */
const _deleteLanguageVersion = (options) => {
  _delete({
    deleteFn: () => {
      return options.model.delete();
    },
    successMessage: _getDeleteOneLanguageSuccessMessage(
      options.model.get('type'),
      _getLanguageName(options.model.get('language'))
    ),
    navigateFn: () => {
      const fragment = Backbone.history.getFragment();
      if (fragment.includes('/edit')) {
        _navigateToNewLanguageVersion(options);
      } else {
        _navigateToNextLanguageVersionOrBack(options);
      }
    }
  });
};

const _navigateToNextLanguageVersionOrBack = (options) => {
  const langCode = options.model.get('language');

  if (!options.languageData) {
    _navigateBack();
    return;
  }

  const nextLang = _.find(options.languageData.keys(), (key) => {
    return key !== 'bundleId' && key !== langCode && options.languageData.get(key) !== null;
  });

  if (!nextLang) {
    _navigateBack();
    return;
  }

  const pageId = options.languageData.get(nextLang).pageId;

  Backbone.history.navigate(`hub/search/article/${ pageId }`, true);
};

const _navigateToNewLanguageVersion = (options) => {
  // in cases where the page type isn't multilingual, we aren't passing languageData around. Catch that here.
  if (!options.languageData) {
    _navigateBack();
    return;
  }

  const langCode = options.model.get('language');

  const otherLang = _.find(options.languageData.keys(), (key) => {
    return key !== 'bundleId' && key !== langCode && options.languageData.get(key) !== null;
  });

  const query = otherLang ? '?fromArticle=' + options.languageData.get(otherLang).pageId : '';

  Backbone.history.navigate(`hub/search/article/create/new-article/${ langCode }${ query }`, true);
};

const _navigateBack = () => {
  Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/type-all/1/`, {
    trigger: true
  });
};

const _handlePageAccessError = (xhr, errMsgKey) => {
  xhr.skipGlobalHandler = true;
  window.app.layout.flash.error(I18n.t(errMsgKey));
};

module.exports = {
  onDeletePage,
  onDeleteLanguageVersion
};
