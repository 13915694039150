const {
  unique,
  without,
  extend
} = require('underscore');

const ActionBarType = require('@common/components/actionBarButton/ActionBarType');

const createButtonType = (type, onClick = () => {}) => {
  ActionBarType.assertLegalValue(type);
  return { type, onClick };
};

const createButtonWithCustomText = (text, callback = () => {}, ariaLabel) => {
  const button = createButtonType(ActionBarType.CUSTOM_TEXT, callback);
  return extend(button, { text, ariaLabel });
};

const createButtonWithTextAndIcon = (actionText, actionIcon, callback = () => {}) => {
  const text = `
    <span aria-hidden="true" role="presentation" class='action-icon ${ actionIcon }'></span>
    <span class='action-text'>${ actionText }</span>
  `;

  return createButtonWithCustomText(text, callback, actionText);
};

const toggleButtonSecondary = (buttonConfig = {}, isSecondary = true) => {
  const {
    className = ''
  } = buttonConfig;

  let currentClassNames = className.split(' ');

  if (isSecondary) {
    currentClassNames = unique(currentClassNames.concat(['white']));
  } else {
    currentClassNames = without(currentClassNames, 'white');
  }

  return extend({}, buttonConfig, { className: currentClassNames.join(' ') });
};

module.exports = {
  createButtonType,
  createButtonWithCustomText,
  createButtonWithTextAndIcon,
  toggleButtonSecondary,
  ActionBarType
};
