/*** The following is an example of how to use this Common Component in a region:

dropdownToggleButtonRegion: {
  viewDefinition: {
    ViewClass: MenuDropdown,
    buttonConfig: {
      buttonText: 'button.text.key', @string
      buttonTextContext: {key: 'words'}, @object <-- optional. This is the variable context that can be passed into i18n.t()
      buttonIcon: 'full-icon-class-name(s)', @string
      buttonClass: 'class-name', @string <-- for styling. if left undefined, will default to the grey square.
      buttonAriaLabel: 'accessibility.text.key', @string
      buttonAriaLabelContext: {key: 'words'}, @object <-- optional. This is the variable context that can be passed into i18n.t()
      popupAlignment: bitmask using the enums in MenuDropdownPositionEnum
    },
    optionsListConfig: [
      {
        buttonText: 'button.text.key', @string
        buttonTextContext: {key: 'words'}, @object <-- optional. This is the variable context that can be passed into i18n.t()
        buttonClass: 'class-name(s)', @string <-- this must be unique among its siblings, it's used (by default) as a selector for click events
        buttonSelectorClass: 'class-name' @string <-- optional. if this exists, it overrides buttonClass and will be used as the selector for attaching button events.
                                                      this is needed if you are including multiple classes in "buttonClass"
        buttonIcon: 'full-icon-class-name(s)', @string
        buttonAriaLabel: 'accessibility.text.key', @string
        buttonAriaLabelContext: {key: 'words'}, @object <-- optional. This is the variable context that can be passed into i18n.t()
        buttonLink: 'www.axonify.com',
        clickCallback: function()
      },
      {
        buttonText: 'button.text.key', @string
        buttonTextContext: {key: 'words'}, @object
        buttonClass: 'class-name', @string
        buttonSelectorClass: 'class-name', @string
        buttonIcon: 'full-icon-class-name(s)', @string
        buttonAriaLabel: 'accessibility.text.key', @string
        buttonAriaLabelContext: {key: 'words'}, @object
        buttonLink: href: 'www.axonify.com',
        clickCallback: function()
      }
    ]
  }
}

buttonConfig: an @object of options, all of which are strings
optionsListConfig: an @array of @objects, of which the objects will contain @strings and @function callback for the click event
clickCallback:  make sure it's bound "binded?" to the scope of the caller, esp if the view definition is passing in
                a "this" method in another class. For example, if the defintion (like above) is being defined in
                regionControllers() of a controller, then do (this.regionControllers = regionControllers.bind(this);)
                in the init

Shit can get a little complex in here so let's lay out some terminology
 - the whole component is called a MenuDropdown
 - there's a button that usually has a "..." as its label, that opens and closes the menu. We'll call it the "toggle-button"
 - there's the menu itself. Because it does a show/hide thing, we'll call it the "popover" and it gets shown and hidden as a whole
 - inside the popover there will be buttons that we'll call "actions"

Inline comments in this file are pretty verbose, but please leave them in because all the UI interactions were a pain
in the royal hiney to get working, and I've attemted to document the weirdness inline.

*/

const _ = require('underscore');
const Backbone = require('Backbone');
const { ItemView } = require('Marionette');
const KeyCode = require('@common/data/enums/KeyCode');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');
const AxSpacingEnum = require('@common/data/enums/AxSpacingEnum');
const I18n = require('@common/libs/I18n');

require('@common/components/dropdownButton/MenuDropdown.less');

const defaultInteractionKeys = [KeyCode.ENTER, KeyCode.SPACE];

class MenuDropdown extends ItemView {

  /* Static methods so we can keep track of things 'extra-locally' */
  static numInstances = 0; //Current number of menu dropdown instances loaded

  static incrementNumInstances() {
    MenuDropdown.numInstances += 1;
  }

  static decrementNumInstances() {
    MenuDropdown.numInstances -= 1;
  }

  static getNumInstances() {
    return MenuDropdown.numInstances;
  }

  // We are going to close all open popovers on the page, except the one belonging to the targeted ellipsis menu
  // that way it can actually 'toggle' itself.
  // If we're ending up on a different ellipsis menu, or anywhere else on the page, we hide every menu dropdown away
  static closeAllMenuDropdowns(e) {
    let $dropdownMenus = $('.js-menu-dropdown-popover');
    // Check if we're targeting the ellipsis menu
    const $targetEllipsisContainer = $(e.target).closest('.js-menu-dropdown-ellipsis-container');

    // If we're targeting an ellipsis menu and we have an active dropdown, check if they belong to each other.
    if ($targetEllipsisContainer.length > 0 && MenuDropdown.getActiveMenuDropdown().length > 0) {
      const $targetEllipsisPopover = $targetEllipsisContainer.siblings('.js-menu-dropdown-popover');
      if ($targetEllipsisPopover.length > 0 && $targetEllipsisPopover[0] === MenuDropdown.getActiveMenuDropdown()[0]) {
        // If they belong to each other, ignore the active dropdown from the click (this will allow the toggle handler to take over)
        $dropdownMenus = $dropdownMenus.not($targetEllipsisPopover);
      } else {
        MenuDropdown.clearActiveMenuDropdown();
      }
    } else {
      MenuDropdown.clearActiveMenuDropdown();
    }
    // Hide all remaining dropdowns
    $dropdownMenus.addClass('hidden');
    $('.js-menu-dropdown-toggle-button').attr('aria-expanded', false);
  }

  static $activeDropdownInstance = $(null);

  static getActiveMenuDropdown() {
    return MenuDropdown.$activeDropdownInstance;
  }

  static setActiveMenuDropdown($el) {
    MenuDropdown.$activeDropdownInstance = $el;
  }

  static clearActiveMenuDropdown() {
    MenuDropdown.setActiveMenuDropdown($(null));
  }

  // When tapping/mouseup on anything outside of a dropdown menu we will close the dropdown.
  static releaseAway(e) {
    if (!$(e.target).closest('.js-menu-dropdown-container').length) {
      MenuDropdown.closeAllMenuDropdowns(e);
    }
  }
  /***************End Static Methods**********************************/

  preinitialize(options) {
    ({
      buttonConfig: this.buttonConfig,
      optionsListConfig: this.optionsListConfig,
      renderCallback: this.renderCallback
    } = options);

    if (!this.buttonConfig.buttonClass) {
      this.buttonConfig.buttonClass = 'menu-dropdown__default-secondary-button ax-button--icon';
    }

    if (!this.buttonConfig.popupAlignment) {
      this.popupAlignment = MenuDropdownPositionEnum.LEFT + MenuDropdownPositionEnum.BOTTOM; // this equals zero, by the way.
    } else {
      this.popupAlignment = this.buttonConfig.popupAlignment;
    }

    // in cases where the user's language is RTL, we should fling that popup in the opposite
    // direction to what was configured in the view definition.
    const userLanguageIsRtl = I18n.isRtlLanguage(this._getUserLanguage());
    if (userLanguageIsRtl) {
      this.popupAlignment = this.popupAlignment ^ MenuDropdownPositionEnum.RIGHT; // bitwise operators are so cool
    }
    
    // determine if the trigger button needs ax-button--icon-left; when there is both text and an icon
    if (
      this.buttonConfig.buttonText
      && this.buttonConfig.buttonText !== ''
      && this.buttonConfig.buttonIcon
      && this.buttonConfig.buttonIcon !== ''
    ) {
      this.buttonConfig.buttonClass += ' ax-button--icon-left';
    }

    const creationErrors = {
      buttonError: 'Configuration for the Dropdown Button was not provided, is empty, or is not an Object',
      optionListError: 'The object list of options for the Dropdown menu was not provided, is empty, or is not an Object'
    };

    this._isObjectNotEmpty(this.buttonConfig, creationErrors.buttonError);
    this._isObjectNotEmpty(this.optionsListConfig, creationErrors.optionListError);
  }

  _getUserLanguage() {
    // ok get this. Here we have a component in common that is used in training, manager, and admin.
    // in the training app, we have a global object named 'apps' that has the user data in it.
    // in the manager app, there is no such global. Instead, we yoink the session user from a
    // radio channel. Obviously this is less than ideal for consistency, so if you're reading this
    // and you are bored and need something to do, there's a fun project for you
    if (typeof apps !== 'undefined') {
      return window.apps.auth.session.user.get('language');
    }

    // if you reach this point, then we are in the manager app, where "apps" doesn't exist as a global.
    const authChannel = Backbone.Wreqr.radio.channel('auth');
    const user = authChannel.reqres.request('session:user');
    return user.get('language');
  }

  initialize() {
    this.toggleDropdownPopup = this.toggleDropdownPopup.bind(this);
    this._doCallback = this._doCallback.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.optionCount = this.optionsListConfig.length;

    // If this is the first MenuDropdown created, we want to bind our mouseup handler on the body, to ensure we close
    // any open popovers after every click.
    // Mouseup/touchend need to be bound in case the click/tap starts on the dropdown menu and ends elsewhere on the
    // page (in that case it would not count as a click event).
    if (MenuDropdown.numInstances === 0) {
      // All three of these are needed to ensure cross-browser compatibility, even though (in essence) they're redundant
      $('body').on('click', MenuDropdown.closeAllMenuDropdowns);
      $('body').on('mouseup', MenuDropdown.releaseAway);
      $('body').on('touchend', MenuDropdown.releaseAway);
    }
    // Keep track of how many instances we have by incrementing on initialize
    MenuDropdown.incrementNumInstances();
  }

  getTemplate() {
    return require('@common/components/dropdownButton/MenuDropdownTemplate.html');
  }

  templateHelpers() {
    this.buttonConfig.parsedButtonText = this._getParsedText(this.buttonConfig.buttonText, this.buttonConfig.buttonTextContext);
    this.buttonConfig.parsedAriaLabel = this._getParsedText(this.buttonConfig.buttonAriaLabel, this.buttonConfig.buttonAriaLabelContext);

    this.optionsListConfig.forEach((option) => {

      option.parsedButtonText = this._getParsedText(option.buttonText, option.buttonTextContext);
      option.parsedAriaLabel = this._getParsedText(option.buttonAriaLabel, option.buttonAriaLabelContext);
    });

    return {
      buttonConfig: this.buttonConfig,
      optionsListConfig: this.optionsListConfig,
      tagName: (buttonLink) => {
        return buttonLink ? 'a' : 'button';
      },
      addHref: (buttonLink) => {
        return buttonLink ? 'href=' + buttonLink : '';
      }
    };
  }

  className() {
    return 'parent-height ax-grid__col--auto-size';
  }

  ui() {
    return {
      dropdownToggleButton: '.js-menu-dropdown-toggle-button',
      dropdownPopover: '.js-menu-dropdown-popover'
    };
  }

  events() {
    // these events are present for the toggle button and the entire component
    const eventsObj = {
      'click @ui.dropdownToggleButton': 'onToggleButtonClicked',
      'keyup @ui.dropdownToggleButton': 'onToggleButtonKeyed',
      'focus button': 'onFocus',
      'blur button': 'onBlur',
      'focus a': 'onFocus',
      'blur a': 'onBlur',
      'keyup @ui.dropdownPopover': 'onKeyupInsidePopover'
    };

    // then there are events that are applied to each of the actions...
    for (let i = 0; i < this.optionsListConfig.length; i++) {
      if (this.optionsListConfig[i].clickCallback) {
        let actionButtonSelector = this.optionsListConfig[i].buttonClass;
        if (this.optionsListConfig[i].buttonSelectorClass) {
          actionButtonSelector = this.optionsListConfig[i].buttonSelectorClass;
        }

        // obviously, something that captures a click on the action, so it does the callback
        eventsObj[`click .${ actionButtonSelector }`] = ((e) => {
          e.stopPropagation();
          // _doCallback is defined so that we do the callback but also close the menu
          this._doCallback(this.optionsListConfig[i].clickCallback);
        });

        // this captures keydown on the action
        eventsObj[`keydown .${ actionButtonSelector }`] = ((e) => {
          // defaultInteractionKeys (space, enter) will trigger a click event, so we don't need to
          // call _doCallback here... that's already gonna happen. We still want to capture the Esc key.
          if (!defaultInteractionKeys.includes(e.keyCode)) {
            this.handleOtherKeyPresses(e);
          }
        });
      }
    }

    return eventsObj;
  }

  // Required for keyboard navigation to work properly
  onFocus() {
    // focussing on any element in this component should cancel any timed requests to close the popover
    clearTimeout(this.timeout);
  }

  // Required for keyboard navigation to work properly. This would normally be enough to hide the dropdown menus
  // when the user clicks away, however, Safari and FF don't give focus to clicked buttons, so no blur happens
  // and attempting to enforce a focus/blur leads to other issues,
  // Ex. dropdown disappears before click event registers or dropdown hides before a longer click is completed.
  onBlur() {
    clearTimeout(this.timeout);
    // This code would accomplish the "click elsewhere to close" behaviour on the menu if not for the above issues.
    // There is a really elegant way to accomplish this using e.relatedTarget; for a good example of that go
    // and look at the code for the Reactions Tray in ReactionsView.js.  All fine and dandy, but e.relatedTarget won't
    // work in FF38. So for all browsers we are going to use an old hack using a timer. Basically when we blur away
    // from an element, we set a timer; if there is a focus event on something within this same component then we
    // cancel that timer. The result is that we get an action that will happen when we click away or tab away from
    // the menu, and that action is to close the popover.
    this.timeout = setTimeout(this.clearAndHide.bind(this), 10);
  }

  _doCallback(callback) {
    callback(); // this must be bound to its scope to do the right thing
    this.toggleDropdownPopup(false);
  }

  onToggleButtonClicked(e) {
    e.stopPropagation();
    this.toggleDropdownPopup(null); // this is a true "toggle" so we pass in null
  }

  clearAndHide() {
    // in case there is a timer waiting to do the hide... kill it here
    clearTimeout(this.timeout);
    this.toggleDropdownPopup(false);
  }

  onKeyupInsidePopover(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.originalEvent.which === KeyCode.DOWN) {
      this.tabNext();
    }
    if (e.originalEvent.which === KeyCode.UP) {
      this.tabPrevious();
    }
  }

  getIndexOfFocused() {
    const $focusedButton = $('.js-menu-dropdown-popover').find(':focus');
    const $parent = $focusedButton.closest('li');
    return $parent.index();
  }

  selectByIndex(index) {
    const selector = `.js-menu-dropdown-popover-option-${ index } button`;
    $(selector).trigger('focus');
    return true;
  }

  tabPrevious() {
    const indexOfFocused = this.getIndexOfFocused();
    if (indexOfFocused <= 0) {
      this.ui.dropdownToggleButton.trigger('focus');
      this.clearAndHide();
    } else {
      const newFocusedIndex = indexOfFocused - 1;
      this.selectByIndex(newFocusedIndex);
    }
  }

  tabNext() {
    const indexOfFocused = this.getIndexOfFocused();
    if (indexOfFocused >= (this.optionCount - 1)) {
      this.ui.dropdownToggleButton.trigger('focus');
      this.clearAndHide();
    } else {
      const newFocusedIndex = indexOfFocused + 1;
      this.selectByIndex(newFocusedIndex);
    }
  }

  /**
   * handles the Event where a key is pressed while the toggle button is focused
   * @param Event e
   */
  onToggleButtonKeyed(e) {
    e.stopPropagation();
    if (e.originalEvent.which === KeyCode.DOWN) {
      this.toggleDropdownPopup(true);
      this.selectByIndex(0);
    }

    this.handleOtherKeyPresses(e); // for instance pressing Esc to hide the popup
  }

  handleOtherKeyPresses(e) {
    if (e.keyCode === KeyCode.ESCAPE) {
      this.toggleDropdownPopup(false);
    }
  }

  getVerticalSpacingBetweenButtonAndPopup() {
    return AxSpacingEnum.REM_S;
  }

  /**
   * This is not an Event handler! this should not accept the Event as an argument, it should be a boolean
   * @param Boolean show
   */
  toggleDropdownPopup(show = false) {
    let innerShow = false;
    if (show === null) {
      // then this is a true toggle
      innerShow = this.ui.dropdownPopover.hasClass('hidden');
    } else {
      innerShow = show;
    }
    if (this.ui.dropdownPopover) {
      // Close all dropdowns except this one (if it is open).
      MenuDropdown.closeAllMenuDropdowns({target: this.ui.dropdownPopover});
      this.ui.dropdownPopover.toggleClass('hidden', !innerShow);
      if (innerShow) {

        const $triggerContainer = $(this.ui.dropdownToggleButton).closest('.js-menu-dropdown-ellipsis-container');

        // converting a bunch of px dimensions into rem units
        const triggerLeftRem = $triggerContainer.position().left * AxSpacingEnum.PX_TO_REM_MULTIPLIER;
        const triggerHeightRem = $triggerContainer.height() * AxSpacingEnum.PX_TO_REM_MULTIPLIER;
        const triggerContainerWidthRem = $triggerContainer.width() * AxSpacingEnum.PX_TO_REM_MULTIPLIER;
        const dropdownPopoverWidthRem = this.ui.dropdownPopover.width() * AxSpacingEnum.PX_TO_REM_MULTIPLIER;
        const dropdownPopoverHeightRem = this.ui.dropdownPopover.height() * AxSpacingEnum.PX_TO_REM_MULTIPLIER;

        let leftPos = 0;
        let topPos = 0;
        if (this._isTopAligned()) {
          topPos = -1 * (dropdownPopoverHeightRem + this.getVerticalSpacingBetweenButtonAndPopup());
        } else {
          topPos = triggerHeightRem + this.getVerticalSpacingBetweenButtonAndPopup();
        }

        if (this._isRightAligned()) {
          leftPos = triggerLeftRem + triggerContainerWidthRem - dropdownPopoverWidthRem;
        } else {
          leftPos = triggerLeftRem;
        }

        this.ui.dropdownPopover.css('left', leftPos + 'rem');
        this.ui.dropdownPopover.css('top', topPos + 'rem');
      }

      MenuDropdown.setActiveMenuDropdown(innerShow ? this.ui.dropdownPopover : $(null)); // There can be only one
    }
    if (this.ui.dropdownToggleButton != null) {
      this.ui.dropdownToggleButton.attr('aria-expanded', innerShow);
    }
  }

  _isTopAligned() {
    // take note, this is a bitwise AND, not a logical AND.
    return this.popupAlignment & MenuDropdownPositionEnum.TOP;
  }

  _isRightAligned() {
    // take note, this is a bitwise AND, not a logical AND.
    return this.popupAlignment & MenuDropdownPositionEnum.RIGHT;
  }

  _isObjectNotEmpty(targetObjects, error) {
    if (!_.isObject(targetObjects) || _.isEmpty(targetObjects)) {
      throw new Error(error);
    }
  }

  _getParsedText(stringLocation, stringContextLocation) {
    if (stringLocation && stringContextLocation) {
      return I18n.t(stringLocation, stringContextLocation);
    } else if (stringLocation) {
      return I18n.t(stringLocation);
    }
    return '';
  }


  onDestroy() {
    // Keep track of how many MenuDropdown instances we have after destroying this one
    MenuDropdown.decrementNumInstances();
    // If there are no more instances, remove the event handler for click away
    if (MenuDropdown.numInstances === 0) {
      $('body').off('click', MenuDropdown.closeAllMenuDropdowns);
      $('body').off('mouseup', MenuDropdown.clickAway);
      $('body').off('touchend', MenuDropdown.clickAway);
    }
  }
}

module.exports = MenuDropdown;
