// Module
var code = `<div class="externalSourceIFrame">
  <button class="icon-remove close js-close" aria-label="<%- t('general.close') %>"></button>

  <!-- NavBar -->
  <div class="header">
    <div class="page-title clearfix"></div>
  </div>

  <!-- Iframe for external links-->
  <div class="contentwrapper">
    <iframe class="external-link-iframe" src="<%- href %>"></iframe>
  </div>
</div>
`;
// Exports
module.exports = code;