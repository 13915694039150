const { Model } = require('Backbone');
const { LayoutView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const CommunityHeaderController = require('@training/apps/search/CommunityHeaderController');
const SearchTabbedLayout = require('@training/apps/search/SearchTabbedLayout');
const CommunitySearchTabController = require('@training/apps/search/CommunitySearchTabController');

const {
  getTabConfigs
} = require('@training/apps/search/CommunityTabConfigHelper');

class CommunityContentController extends LayoutController {
  initialize() {
    this.searchPageState = this.getOption('searchPageState') || new Model();
    this.community = this.getOption('selectedCommunity');
    this.showLoneTab = this.getOption('showLoneTab');
    this.tabNamePassedIn = this.getOption('tabName');

    this.tabData = getTabConfigs(this.searchPageState, this.community, this.tabNamePassedIn);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'community-browse',
      template: `
        <div class="community-header-region"></div>
        <div class="tabs-region"></div>
      `,
      regions: {
        communityHeader: '.community-header-region',
        tabSelector: '.tabs-region'
      }
    };
  }

  regionControllers() {
    return {
      communityHeader: {
        ViewControllerClass: CommunityHeaderController,
        searchPageState: this.searchPageState,
        community: this.community
      },
      tabSelector: {
        ViewControllerClass: CommunitySearchTabController,
        viewDefinition: {
          ViewClass: SearchTabbedLayout,
          searchPageState: this.searchPageState
        },
        searchPageState: this.searchPageState,
        showLoneTab: this.showLoneTab,
        initialTab: this.tabData.initialTab,
        tabConfigs: this.tabData.tabList
      }
    };
  }
}

module.exports = CommunityContentController;
