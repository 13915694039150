const Backbone = require('Backbone');
const Marionette = require('Marionette');

const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/androidBackHandler/AndroidBackHandler');

require('@common/components/iframeView/IFrameView.less');

class IFrameView extends Marionette.ItemView {
  behaviors() {
    return {
      Resizable: {},
      AndroidBackHandler: {
        handler: 'closeView'
      }
    };
  }

  getTemplate() {
    return require('./IFrameView.html');
  }

  templateHelpers() {
    return {
      href: this.href
    };
  }

  events() {
    return {'click .js-close': 'closeView'};
  }

  initialize() {
    this.href = this.options.href;

    // set the page title to the linkText
    this.title = this.options.linkText;
  }

  attachElContent(html = '') {
    this.$el.append(html);
    return this;
  }

  onRender() {
    this.$container = this.$('.externalSourceIFrame');
    this.$iFrame = this.$('iframe.external-link-iframe');

    BrowserHelpers.preventDragAndDrop(this.$iFrame);

    if (this.title != null) {
      this.$container.find('.page-title').html(this.title);
    }

    this.listenTo(Backbone.history, 'route', () => {
      this.closeView();
    });

    this.showView();
  }

  onResize(winHeight, winWidth) {
    this.winHeight = winHeight;

    const $containerHeader = this.$container.find('.header');
    const containerHeader = $containerHeader[0];
    const offsetHeight = containerHeader ? containerHeader.offsetHeight : 0;
    const height = this.winHeight - offsetHeight;

    this.$container.find('.contentwrapper').css({width: winWidth, height});
    this.$container.find('iframe').css({width: winWidth, height})
      .attr({width: winWidth, height});
  }

  showView() {
    this.$container.css({y: this.winHeight});
    this.$container.animate({y: 0}, 'fast', () => {
      $('body').css('overflow', 'hidden');
    });
  }

  closeView() {
    // hide the view and remove the view
    this.$container.animate({y: this.winHeight}, 'fast', () => {
      this.onClose();
    });
  }

  onClose() {
    this.$container.remove();
    $('body').css('overflow', '');
  }
}

module.exports = IFrameView;
