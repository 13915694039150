const { LayoutView } = require('Marionette');
const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const {
  PageInsightsOverviewTileView,
  PageInsightsOverviewTimelineTileView
} = require('@training/apps/insights/overview/PageInsightsOverviewTileView');

const CHILD_CONTROLLER_CONFIG = StackedLayoutViewController.createConfigObject(
  ViewOptionHelpers.classNameAppender('page-insights-overview-tile'),
  {}
);

class PageInsightsOverviewController extends LayoutController {
  initialize(options = {}) {
    ({
      model: this.model
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: `
        <h2 class="page-insights-overview__header">
          <span><%- t('general.overview') %></span>
          <span class="page-insights-overview__header--hyphen" aria-hidden="true"> - </span>
          <span class="page-insights-overview__header--allTeams"><%- t('general.allTeams') %></span>
        </h2>
        <div class="js-overview-tile-region"></div>
      `,
      regions: {
        overviewTileRegion: '.js-overview-tile-region'
      }
    };
  }

  regionControllers() {
    return {
      overviewTileRegion: {
        ViewControllerClass: StackedLayoutViewController,
        viewDefinition: {
          className: 'page-insights-overview-tile-group',
          childViewOptions: {
            className: 'page-insights-overview-tile-wrapper'
          }
        },
        childControllers: this._getChildControllers()
      }
    };
  }

  // Returns child controller object for the Stacked Collection Layout using the view class, model, and title passed in
  _generateChildViewDefinition(viewClass, model, title) {
    return {
      viewControllerDefinition: {
        viewDefinition: {
          ViewClass: viewClass,
          model,
          title
        }
      }
    };
  }

  _getChildControllers() {
    return [
      Object.assign({}, CHILD_CONTROLLER_CONFIG, this._generateChildViewDefinition(
        PageInsightsOverviewTimelineTileView,
        this.model.get('timelineModel'),
        I18n.t('training.menu.timeline')
      )),
      Object.assign({}, CHILD_CONTROLLER_CONFIG, this._generateChildViewDefinition(
        PageInsightsOverviewTileView,
        this.model.get('first24Model'),
        I18n.t('discover.insights.firstHours')
      )),
      Object.assign({}, CHILD_CONTROLLER_CONFIG, this._generateChildViewDefinition(
        PageInsightsOverviewTileView,
        this.model.get('first7Model'),
        I18n.t('discover.insights.firstDays')
      ))
    ];
  }
}

module.exports = PageInsightsOverviewController;
