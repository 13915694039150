const TabbedLayout = require('@common/components/tabbed_layout/TabbedLayout');
const TabController = require('@common/components/tabbed_layout/TabController');

class CommunityManagementTabbedLayout extends TabbedLayout {
  setActiveTab(tabName) {
    if (!this.isValidTabName(tabName) || (tabName === this._activeTab)) {
      return;
    }

    const errors = this._validateForm();

    if (this._activeTab != null && (errors == null || errors.length === 0)) {
      this._hideTab(this._activeTab);
    }

    if (errors == null || errors.length === 0) {
      this._activeTab = tabName;
      this._showTab(tabName);
    } else {
      window.app.layout.flash.error(errors);
    }
  }

  _validateForm() {
    if (this.tabContentRegion.currentView != null && this.tabContentRegion.currentView.form != null) {
      return this.tabContentRegion.currentView.form.commit();
    }
    return [];
  }
}

class CommunityManagementTabController extends TabController {
  viewDefinition() {
    return {
      ViewClass: CommunityManagementTabbedLayout,
      initialTabConfigs: this.getIntialTabs(),
      initialTab: this.initialTab,
      showLoneTab: this.showLoneTab
    };
  }

  getCurrentTabView() {
    const layoutView = this.getView();
    if (layoutView != null && layoutView.tabContentRegion != null && layoutView.tabContentRegion.currentView != null) {
      return layoutView.tabContentRegion.currentView;
    }

    return undefined;
  }
}

module.exports = CommunityManagementTabController;
