const logging = require('logging');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const Sync = require('@common/libs/Sync');
const Game = require('@common/data/models/Game');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ChallengeSelectionView = require('./ChallengeSelectionView');
const RecentChallengesView = require('./RecentChallengesView');
const GameChallengeHowToModal = require('./GameChallengeHowToModal');
class GameChallengeSelectPage extends UIKit.View {
  template = _.tpl(require('../templates/GameChallengeSelectPage.html'));

  events() {
    return {
      'click .js-how-to-link': 'displayHowToModal'
    };
  }

  className() {
    return 'ax-container';
  }

  initialize(options = {}) {
    ({
      gameId: this.gameId,
      challengeUser: this.challengeUser
    } = options);
    this.model = new Game({
      id: this.gameId
    });
    this.gameModelPromise = this.model.fetch();
    this.challengeSelectionView = new ChallengeSelectionView();
    this.listenTo(this.challengeSelectionView, 'user:challenge:selected', (user, includeWager) => {
      this.challengeUser(this.model, user, includeWager);
    });
    this.recentChallengesView = new RecentChallengesView();
  }

  render() {
    this.gameModelPromise.done(() => {
      if (this.isDestroyed) {
        return;
      }
      this.$el.html(this.template({
        game: this.model.toJSON()
      }));
      this.setSubviewIn(this.challengeSelectionView, {
        regionSelector: '.challenge-selection-region',
        transition: UIKit.View.Transitions.NONE
      });
      this.setSubviewIn(this.recentChallengesView, {
        regionSelector: '.recent-challenges-region',
        transition: UIKit.View.Transitions.NONE
      });
    });
    return this;
  }

  viewDidAppear() {
    logging.info('GameChallengeSelectPage - viewDidAppear');
    // Set up navbar
    window.app.layout.showHeaderBackButton();
  }

  displayHowToModal() {
    const modalView = new AccessibleModalView({
      className: 'how-to-modal confirm-dialog-view'
    });
    window.app.layout.presentModal(modalView);
    modalView.setSubviewIn(new GameChallengeHowToModal(), { transition: UIKit.View.Transitions.NONE });
  }

  onClose() {
    this.model.abortXHR(Sync.Method.READ);
    window.app.layout.resetLeftHeaderView();

    super.onClose();
  }
}

module.exports = GameChallengeSelectPage;
