const $os = require('detectOS');
const logging = require('logging');

const _ = require('underscore');
const { history } = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const AuthTypesEnum = require('@common/data/enums/AuthTypesEnum');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const SelectCoachController = require('@training/apps/training/coachSelection/SelectCoachController');

const CoachesList = require('@training/apps/training/collections/CoachesList');
const ProfileLanguageSettingView = require('@training/apps/training/views/ProfileLanguageSettingView');
const ProfileNotificationsSettingView = require('@training/apps/training/views/ProfileNotificationsSettingView');
const ActivateAccountPage = require('@training/apps/auth/views/ActivateAccountPage');
const ProfileLanguageModel = require('@training/apps/common/models/ProfileLanguageModel');
const ProfileUserSettingView = ($os.ipad) ? require('@training/apps/mobile/views/ProfileUserSettingView') : require('@training/apps/training/views/ProfileUserSettingView');

require('@common/libs/behaviors/msHideable/MsHideable');
require('jquery.velocity');

class ProfilePage extends UIKit.View {
  get template() {
    return _.tpl(require('../templates/ProfilePage.html'));
  }

  get settingItemTemplate() {
    return _.tpl(require('../templates/_profile_setting_item.html'));
  }

  events() {
    return {'click .setting': 'settingClicked'};
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  initialize(options = {}) {
    ({
      complete: this.completeCallback = () => {},
      initialTab: this.initialTab
    } = options);
    this.availableSettings = this._getAvailableSettings();
  }

  // render the settings list and select the first one
  render() {
    // Load view
    this.$el.html(this.template());
    return this;
  }

  onRender() {
    this.availableSettings.forEach(this.appendSetting.bind(this));
  }

  viewDidAppear() {
    logging.info('ProfilePage - viewDidAppear');
    window.app.layout.setTitle(I18n.t('settings.title'));

    this.selectDefaultSetting(this.getInitialResourceKey() || this.getDefaultResourceKey());
  }

  selectDefaultSetting(resourceKey) {
    if (resourceKey != null) {
      //if there aren't any menu items in the DOM, don't highlight, and don't
      // display any page contents.
      this.$(`[data-setting-id=${ resourceKey }]`).addClass('selected');
      this.$(`[data-setting-id=${ resourceKey }]`).attr('aria-current', true);
      this.showSettingDetails(resourceKey);
    }
  }

  getInitialResourceKey() {
    const { resourceKey } = _.findWhere(this.availableSettings, { resourceKey: this.initialTab }) || {};
    return resourceKey;
  }

  getDefaultResourceKey() {
    // this function exists because the mobile client overrides this function
    // in order to keep the user from having to drill down.
    return (_.first(this.availableSettings) || {}).resourceKey;
  }

  _getAvailableSettings() {
    this.tenantStore = TenantPropertyProvider.get();
    const settings = [];

    if (this.hasProfilePage()) {
      settings.push({
        name: I18n.t('settings.userTitle'),
        resourceKey: 'user'
      });
    }

    // determine if there are more than one coach available
    // if there is less than one, then don't display the coach
    // tab.
    if (apps.auth.session.user.get('coach') != null) {
      settings.push({
        name: I18n.t('settings.coachtitle'),
        resourceKey: 'coach'
      });
    }

    // add language setting item
    settings.push({
      name: I18n.t('settings.languagetitle'),
      resourceKey: 'language'
    });

    // We only show the account nav if SelfServe is one of the auth types, and if the user has a password
    if (
      this.tenantStore.getProperty('authenticationSchemes').includes(AuthTypesEnum.SelfServe.serverId)
      && window.apps.auth.session.user.get('hasPassword')
    ) {
      settings.push({
        name: I18n.t('settings.accounttitle'),
        resourceKey: 'account'
      });
    }

    if (this.tenantStore.getProperty('managerNotificationsEnabled') && window.apps.auth.session.user.get('email')) {
      settings.push({
        name: I18n.t('settings.notificationsTitle'),
        resourceKey: 'notifications'
      });

    }

    return settings;
  }

  hasProfilePage() {

    const profileSettingObj = {
      allowUserProfileImageChange: this.tenantStore.getProperty('allowUserProfileImageChange'),
      allowUserProfileNicknameChange: this.tenantStore.getProperty('allowUserProfileNicknameChange'),
      allowUserProfileGenderChange: this.tenantStore.getProperty('allowUserProfileGenderChange'),
      allowUserProfileBirthYearChange: this.tenantStore.getProperty('allowUserProfileBirthYearChange')
    };

    return _.some(profileSettingObj);
  }

  onClose(...args) {
    super.onClose(...args);
    this.getActionBar()?.setActionButton(null);
    this.completeCallback();
  }

  appendSetting(setting) {
    const settingHtml = this.settingItemTemplate({setting});
    this.$('.settingswrapper .list').append(settingHtml);
  }

  // the user clicked on a setting so get the setting id
  // and call show details method
  settingClicked(e) {
    this.$('.setting.menu-thumb').removeClass('selected');
    this.$('.setting.menu-thumb').removeAttr('aria-current');
    $(e.currentTarget).addClass('selected');
    $(e.currentTarget).attr('aria-current', true);
    const settingId = $(e.currentTarget).data('setting-id');

    this.showSettingDetails(settingId, UIKit.View.Transitions.FADE);
  }

  // decide which view to display
  showSettingDetails(settingId, transition) {
    const settingView = this._getSettingsView(settingId);

    if (settingView) {
      this.routeToTab(settingId);
      this.displayView(settingView, transition);
    }
  }

  _getSettingsView(settingId) {
    switch (settingId) {
      case 'coach':
        return this.getCoachSettingDetails();
      case 'language':
        return this.getLanguageSettingDetails();
      case 'account':
        return this.getActivateAccountDetails();
      case 'user':
        return this.getUserSettingDetails();
      case 'notifications':
        return this.getManagerNotificationDetails();
      default:
        return null;
    }
  }

  routeToTab(settingId, options = { replace: true }) {
    history.navigate(`#hub/profile/${ settingId }`, options);
  }

  displayView(view, transition) {
    const rightEl = '.right';
    this.setSubviewIn(view, {
      regionSelector: rightEl,
      fadeEl: rightEl,
      transition
    });
  }

  getUserSettingDetails() {
    return new ProfileUserSettingView({actionBar: this.getActionBar()});
  }

  getManagerNotificationDetails() {
    return new ProfileNotificationsSettingView({actionBar: this.getActionBar()});
  }

  // need to display to the user the coach that
  // they have selected and allow them to select another
  getCoachSettingDetails() {
    const coachList = new CoachesList();
    const selectedCoach = window.apps.auth.session.user.get('coach');

    coachList.fetch({
      showSpinner: false
    }).then(() => {
      coachList.setSelected(selectedCoach);
    });

    return ViewControllerFactory.createLegacyView({
      ViewControllerClass: SelectCoachController,
      actionBar: this.getActionBar(),
      selectedCoachId: selectedCoach.id,
      coachList,
      pageTitle: I18n.t('settings.coachtitle'),
      onComplete: () => {
        window.app.layout.flash.success(I18n.t('settings.coachsaved'));
      }
    });
  }

  // need to display to the user the language that
  // they have selected and allow them to select another
  getLanguageSettingDetails() {
    // let the language selector know the user's selected
    // language
    const profileLanguages = new ProfileLanguageModel();
    profileLanguages.fetch();

    return new ProfileLanguageSettingView({
      model: profileLanguages,
      actionBar: this.getActionBar()
    });
  }

  // Show the Activate Account page for users on a self-serve tenant
  getActivateAccountDetails() {
    return new ActivateAccountPage({
      actionBar: this.getActionBar(),
      title: I18n.t('settings.accounttitle'),
      saveOnly: true
    });
  }

  getActionBar() {
    return window.app.layout.actionBar;
  }
}

module.exports = ProfilePage;
