const Backbone = require('Backbone');
const RaffleModel = require('../models/RaffleModel');

class RaffleList extends Backbone.Collection {
  get model() {
    return RaffleModel;
  }

  apiEndpoint() {
    return '/rewards/raffle/prizes';
  }

  comparator(model) {
    return model.get('nextEndMillis');
  }

  parse(response = {}) {
    return response.items;
  }
}

module.exports = RaffleList;
