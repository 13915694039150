const UserView = require('@training/apps/training/views/UserView');
const DetailTemplate = require('@training/apps/search/modals/reactionsModal/ReactionDetailView.html');

class ReactionDetailView extends UserView {
  getTemplate() {
    return DetailTemplate;
  }

  tagName() {
    return 'li';
  }

  className() {
    return 'userview-reaction';
  }
}

module.exports = ReactionDetailView;
