const _ = require('underscore');
const { Renderer } = require('Marionette');

const AxonifyTemplateCache = require('@common/libs/AxonifyTemplateCache');

const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');
const dateHelpers = require('@common/libs/dateHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

_.extend(Renderer, {
  // Override Marionette's render function to use our tpl mixin by default.
  // Means we can just do a "template: require 'dir/to/template.html'" in the views.
  // data is a combination of the View's Model, Collection and any dataHelper objects/functions you define.
  render(template = '', data = {}) {
    const dataAndHelpers = _.extend({}, data, ViewHelpers, UrlHelpers, { dateHelpers });
    return AxonifyTemplateCache.get(template)(dataAndHelpers);
  }
});
