const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const ApiKeyView = require('@training/apps/search/communitiesManagement/integration/ApiKeyView');
const KeyItemView = require('@training/apps/search/communitiesManagement/integration/KeyItemView');
const ApiModalView = require('@training/apps/search/communitiesManagement/integration/ApiModalView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const CustomerApiKeyList = require('@common/data/collections/CustomerApiKeyList');
const UserList = require('@common/data/collections/UserList');

const UIKit = require('@training/widgets/UIKit');

const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

class IntegrationController extends LayoutController {
  initialize(options = {}) {
    ({context: this.context} = options);

    this.viewDefinition = this.viewDefinition.bind(this);
    this.renderModal = this.renderModal.bind(this);

    this.userList = new UserList();
    this._setup();

    this.listenTo(this.context, 'update:community change:community sync:community', this._setup);

    triggerResize(true);
  }

  _setup() {
    this.community = this.context.get('community');
    if (this.community) {
      this.communityId = this.context.get('community').get('id');
      if (!this.customerApiKeyList) {
        this.customerApiKeyList = new CustomerApiKeyList([], {communityId: this.communityId});
      } else {
        this.customerApiKeyList.communityId = this.communityId;
      }
      this.customerApiKeyList.fetch();
    }
  }

  viewDefinition() {
    let view = {};

    if (this.community) {
      view = {
        ViewClass: Marionette.LayoutView,
        className: 'integration-access-view',
        template: `
          <div class="js-integration-tab__actions"></div>
          <div class="js-integration-tab__table"></div>
          <div class="api-modal"></div>
          `,
        regions: {
          actionsRegion: '.js-integration-tab__actions',
          tableRegion: '.js-integration-tab__table'
        }
      };
    }
    return view;
  }

  ui() {
    return {modal: '.api-modal'};
  }

  regionControllers() {
    return {
      actionsRegion: {
        viewDefinition: {
          ViewClass: ApiKeyView,
          collection: this.customerApiKeyList,
          userList: this.userList,
          context: this.context
        },
        delegateEvents: {
          'view:show:modal': (controller, view, model) => {
            this.renderModal(model);
          }
        }
      },
      tableRegion: {
        viewDefinition: {
          ViewClass: TableLayoutView,
          collection: this.customerApiKeyList,
          headerNames: [I18n.t('table.headers.name')],
          rowItemView: KeyItemView
        },
        delegateEvents: {
          'view:childview:show:modal': (controller, view, childView, targetId) => {
            this.renderModal(this.customerApiKeyList.get(targetId).clone());
          }
        }
      }
    };
  }

  onViewInflate() {
    triggerResize(true);
  }

  renderModal(model) {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal api-modal api-key-modal'
    });
    const childView = new ApiModalView({
      el: this.ui.modal,
      model,
      collection: this.customerApiKeyList,
      userList: this.userList
    });

    window.app.layout.presentModal(modalView);
    modalView.setSubviewIn(childView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(childView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }
}

module.exports = IntegrationController;
