const KeyboardNavigationHelper = require('@common/components/filterableCommunity/KeyboardNavigationHelper');
const KeyCode = require('@common/data/enums/KeyCode');

const DESELECTED = -1;
// const ON = true;
const OFF = false;

class MultiselectKeyboardNavigationHelper extends KeyboardNavigationHelper {
  navHandler(e) {
    let isNavKey = true;

    // Ignore the Enter key if nothing's selected.
    if (e.which === KeyCode.ENTER && this.focusedItem === DESELECTED) {
      return isNavKey;
    }

    // Ignore the Space key if nothing's selected, and still allow typing
    if (e.which === KeyCode.SPACE && this.focusedItem === DESELECTED) {
      return false;
    }

    this._clearHighlights();
    this.recentCommunityIsVisible = !e.target.value;

    switch (e.which) {
      case KeyCode.DOWN:
        this.focusedItem++;
        break;
      case KeyCode.UP:
        this.focusedItem--;
        break;
      case KeyCode.ENTER:
        this._selectHighlighedItem(this.focusedItem);
        break;
      case KeyCode.SPACE:
        this._selectHighlighedItem(this.focusedItem);
        break;
      default:
        this.focusedItem = DESELECTED;
        isNavKey = false;
    }

    this.focusedItem = this._constrainFocusItem(this.focusedItem);

    if (this.focusedItem !== DESELECTED) {
      this._setActiveDescendant(this.focusedItem);
    }

    if (this.focusedItem === DESELECTED && e.shiftKey && [KeyCode.UP, KeyCode.ENTER, KeyCode.TAB].indexOf(e.which) !== -1) {
      this.switchDropdown(OFF);
    }

    this._setHighlight(this.focusedItem);

    return isNavKey;
  }

  // use jQuery to set/unset highlights since Backbone model.set removes checkbox state due to re-render
  _clearHighlights() {
    this.filteredCommunityList.each((model) => {
      this.view.highlight(model, false);
      model.unset('highlight', { silent: true });
    });
  }

  _setHighlight(focusedItem) {
    const model = this._getModelAtFocusedItem(focusedItem);

    if (model) {
      this.view.highlight(model, true);
      model.set('highlight', true, { silent: true });
    }
  }

  _selectHighlighedItem(focusedItem) {
    const model = this._getModelAtFocusedItem(focusedItem);
    if (model) {
      this.setCommunityHandler(null, model, true);
      this.view.checkModel(model.get('id'));
    }
  }

  _setActiveDescendant(focusedItem) {
    const id = this._getModelAtFocusedItem(focusedItem).get('id');
    this.view.setActiveDescendant(id);
  }

  setViewReference(view) {
    this.view = view;
  }
}

module.exports = MultiselectKeyboardNavigationHelper;
