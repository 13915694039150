import { fieldRequiredAsteriskStyles } from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import {
  FormLabel as MuiFormLabel,
  styled,
  type FormLabelTypeMap as MuiFormLabelTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxFormLabelCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

type PropsToRemove = 'filled' | 'focused';

export type AxFormLabelTypeMap<P = object, D extends React.ElementType = 'label'> = {
  props: P & Omit<MuiFormLabelTypeMap['props'], PropsToRemove> & AxFormLabelCustomProps;
  defaultComponent: D;
};


export type AxFormLabelProps<
  D extends ElementType = AxFormLabelTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxFormLabelTypeMap<P & AxFormLabelCustomProps, D>, D>;

const styledOptions = {
  name: 'AxFormLabel'
};

const StyledAxFormLabel = styled(MuiFormLabel, styledOptions)<AxFormLabelProps>(({ theme }) => {
  return {
    color: theme.uiKit.colorBlack,
    fontWeight: theme.uiKit.fontWeightBold,
    lineHeight: theme.uiKit.lineHeightM,
    fontSize: theme.uiKit.fontSizeM,
    margin: 0,
    marginBottom: theme.uiKit.spacingXS,
    '&.Mui-error': {
      color: theme.uiKit.colorRed60
    },
    '.MuiFormLabel-asterisk': fieldRequiredAsteriskStyles(theme)
  };
});

/**
 * A label for a form field. Don't use it as input label.
 * Styles are based on the recent CRUD page designs.
 *
 * - [UIKit Figma](https://www.figma.com/design/GBT10L4YNfhM2ox0jJn5qu/Admin-Communications?node-id=4158-47180&m=dev)
 */
export const AxFormLabel: MuiOverridableComponent<AxFormLabelTypeMap> = forwardRef(({
  children,
  component = 'h2',
  ...otherProps
}: AxFormLabelProps, ref: Ref<HTMLLabelElement>) => {
  const FormLabelProps: AxFormLabelProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxFormLabel
      component={ component }
      { ...FormLabelProps }
      ref={ ref }
    >
      { children }
    </StyledAxFormLabel>
  );
});

export default AxFormLabel;
