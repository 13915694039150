const _ = require('underscore');
const Marionette = require('Marionette');
const DocumentHelpers = require('@common/components/window/DocumentHelpers');

class DocumentView extends Marionette.ItemView {
  getTemplate() {
    return require('@common/components/window/DocumentTemplate.html');
  }

  preinitialize(options = {}) {
    super.preinitialize(options);
    this._validateEl(options.el);
  }

  initialize() {
    this.isRtl = false;
    this.cssPaths = '';

    this._isRtl = this.getOption('isRtl');
    this._cssPaths = this.getOption('cssPaths');
    this._bodyView = this.getOption('view');
  }

  serializeData(...args) {
    return _.extend({}, super.serializeData(...args), {
      rtlDir: this._isRtl ? 'rtl' : 'ltr',
      stylesheets: this.serializeStylesheets(this._cssPaths),
      bodyHtml: this.serializeBodyView(this._bodyView),
      bodyClassName: this.serializeBodyClassName(this._bodyView)
    });
  }

  serializeStylesheets(cssPaths = []) {
    const paths = this._normalizeCssPaths(cssPaths);
    const links = this.generateLinks(paths);
    return this._serializeLinks(links);
  }

  generateLinks(cssPaths) {
    return this._generateLinks(cssPaths, 'screen');
  }

  serializeBodyView(view) {
    return (view && view.render().$el.html()) || '';
  }

  serializeBodyClassName(view) {
    return _.result(view, 'className', '');
  }

  attachElContent(html) {
    this.el.open();
    this._bindWindowEvents();
    this.el.write(html);
    this.el.close();
  }

  remove() {
    this._unbindWindowEvents();
    this.getWindow().close();
  }

  getWindow() {
    return this.el.parentWindow != null ? this.el.parentWindow : this.el.defaultView;
  }

  _validateEl(el = {}) {
    if (!(el.nodeType === Node.DOCUMENT_NODE)) {
      throw new Error('DocumentView requires a window document element as its "el" option!');
    }
  }

  _normalizeCssPaths(cssPaths = []) {
    return [].concat(cssPaths);
  }

  _generateLinks(cssPaths = [], media) {
    return _.map(cssPaths, _.partial(DocumentHelpers.generateCSSLink, _, media));
  }

  _serializeLinks(links) {
    return $('<div/>').append(links)
      .html();
  }

  _bindWindowEvents() {
    const $win = $(this.getWindow());
    $win.on('load', _.partial(Marionette.triggerMethodOn, this, 'load'));
    $win.on('unload', _.partial(Marionette.triggerMethodOn, this, 'unload'));
  }

  _unbindWindowEvents() {
    const $win = $(this.getWindow());
    $win.off('load');
    $win.off('unload');
  }
}

module.exports = DocumentView;
