const HEADER_CONTROLLER_DEFINITION = 'pageHeaderControllerDefinition';
const HEADER_VISIBLE = 'pageHeaderVisible';

module.exports = {
  Keys: {
    HEADER_CONTROLLER_DEFINITION,
    HEADER_VISIBLE
  },

  defaults() {
    return {
      [HEADER_VISIBLE]: false
    };
  },

  toggleHeaderVisible(contextModel, toggle = true) {
    contextModel.set(HEADER_VISIBLE, toggle);
  },

  isHeaderVisible(contextModel) {
    return contextModel.get(HEADER_VISIBLE);
  },

  getPageHeaderControllerDefinition(contextModel) {
    return contextModel.get(HEADER_CONTROLLER_DEFINITION);
  },

  setPageHeaderControllerDefinition(contextModel, controllerDefinition, options = {}) {
    contextModel.set(HEADER_CONTROLLER_DEFINITION, controllerDefinition, options);
  }
};
