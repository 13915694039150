const _ = require('underscore');
const { Module } = require('Marionette');

const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

class UserCoachModule extends Module {

  initialize(moduleName, app, options) {
    ({
      user: this.user
    } = options);

    this.coachesStylesTemplate = _.tpl(require('@common/components/coaches/CoachesStyles.html'));
  }

  onStart() {
    this.listenTo(this.user, 'change:coach', (model, coachData) => {
      this.reloadCoachStyles(coachData);
    });
  }

  onStop() {
    this.stopListening();
  }

  reloadCoachStyles(coachData) {
    $('head').remove('#selected-coach-style');

    if (coachData == null || coachData.coachType === 'NoCoach') {
      return;
    }

    const coachesStyles = this.coachesStylesTemplate({
      id: 'selected-coach-style',
      selectCoachUrl: (type) => {
        const imageMedia = coachData[`custom${ type }Image`];

        if (imageMedia && (imageMedia.sizes != null)) {
          return {
            coachUrl: imageMedia.sizes[0].file.path,
            retinaCoachUrl: imageMedia.sizes[1].file.path
          };
        }
        const url = coachData[`defaultDownloadFor${ type }`];
        return {
          coachUrl: UrlHelpers.coachUrl(url),
          retinaCoachUrl: UrlHelpers.retinaCoachUrl(url)
        };

      },
      getCssClass: (coachTypeClass) => {
        return `.bonuscharacter .${ coachData.coachType }${ coachTypeClass }`;
      },
      coachTypes: {
        Hello: '',
        Correct: '.correct',
        Incorrect: '.incorrect',
        Thinking: '.ponder'
      }
    });

    $('head').append($(coachesStyles));
  }
}

module.exports = UserCoachModule;
