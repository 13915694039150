const $os = require('detectOS');
const { extend } = require('underscore');
require('jquery.velocity');

const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');

require('@common/components/modal/ModalScrollIndicatorBehavior');

const AdjustableLayout = require('@training/apps/main/views/AdjustableLayout');
const ActionBar = require('@training/apps/main/views/ActionBar');

require('@common/components/modal/modal.less');
require('@training/assets/stylesheets/TrainingModals.less');

const BOTTOM_OFFSET_DEFAULT = $os.desktop ? 25 : 0;
const TOP_OFFSET_DEFAULT = $os.desktop ? 25 : 0;

class AdjustableModalView extends AdjustableLayout {

  behaviors() {
    return Object.assign({}, super.behaviors(), {
      Resizable: {},
      ModalScrollIndicatorBehavior: {
        downScrollerTarget: '.adjustable-container'
      }
    });
  }

  getTemplate() {
    return `
      <div class="adjustable-container">
        <div class="adjustable-content-wrapper">
          <div class="adjustable-content"></div>
        </div>
      </div>

      <div class="actionbar"></div>
    `;
  }

  ui() {
    return Object.assign({}, super.ui(), {
      actionBar: '.actionbar'
    });
  }

  events() {
    return {};
  }

  viewStateEvents() {
    return Object.assign({}, super.viewStateEvents(), {
      'change:isContentOverflow': 'onIsContentOverflowChange'
    });
  }

  constructor(options = {}) {
    super(options);

    (({
      bottomOffset: this.bottomOffset = BOTTOM_OFFSET_DEFAULT,
      topOffset: this.topOffset = TOP_OFFSET_DEFAULT,
      ActionBarClass: this.ActionBarClass = ActionBar
    } = options));
  }

  onRender() {
    super.onRender();

    this.$el.addClass('adjustable-modal');

    this._renderModalContent();
    this._renderModalActionBar();

    this.bindUIElements();
  }

  viewDidAppear() {
    this.$('img').on('load', this.triggerAdjustment);

    // Make sure everything is adjusted properly once the view is fully visible
    this.triggerAdjustment();
  }

  onResize() {
    this.updateStructuralViewState();
  }

  onScroll(scrollData) {
    super.onScroll(scrollData);

    this.$el.trigger('reposition');
  }

  onContentUnderflow() {
    // Don't want to reset the container height in case the content switches to be underflowed
  }

  onIsContentOverflowChange(model, isContentOverflow) {
    this.$el.css({
      bottom: isContentOverflow ? this.bottomOffset : ''
    });
  }

  calculateContainerHeight() {
    // Subtract the actionBar's height when it's fixed since it's not in the content anymore
    // and we want the content to sit above it completely height wise and not have content scroll behind it.
    const actionBarHeight = this.getActionBarHeight();
    return actionBarHeight === 0 ? '' : `calc(100% - ${ actionBarHeight }px)`;
  }

  calculateContentHeight() {
    return super.calculateContentHeight() + this.getActionBarHeight();
  }

  contentTemplate() {
    return '';
  }

  getActionBarHeight(includeHidden) {
    return this.actionBar.getOuterHeight(null, true, includeHidden);
  }

  calculateAvailableHeight() {
    return this.superview.getOuterHeight() - this.bottomOffset - this.topOffset;
  }

  onBeforeSetSubviewIn(view) {
    view.actionBar = this.actionBarWrapper;
  }

  onSetSubviewIn() {
    Scrollable.scrollToTop(this);
    this.$('img').on('load', this.triggerAdjustment);
  }

  getFadingEl() {
    return this.getContentWrapper();
  }

  onDestroy() {
    // Turn off the 'load' listener before the views elements are all removed
    // in the super call.
    this.$('img').off('load');

    this.actionBar.destroy();
  }

  /* Private methods */

  _renderModalContent() {
    this.triggerMethod('before:render:content', this);

    this.getContent().html(this.contentTemplate());
    this.bindUIElements();

    this.triggerMethod('render:content', this);
  }

  _renderModalActionBar() {
    this.triggerMethod('before:render:actionBar', this);

    this.actionBar = new this.ActionBarClass({
      el: this.ui.actionBar
    });

    this.actionBar.render();
    this.actionBar.toggleFixed($os.mobile);

    this.listenTo(this.actionBar, 'action:button:update', (isActive) => {
      if (isActive) {
        this.updateStructuralViewState();
      }
    });

    this.actionBarWrapper = {
      setActionBar: (...args) => {
        this.actionBar.setActionBar(...args);
      },
      setActionButton: (...args) => {
        this.actionBar.setActionButton(...args);
      },
      show: (options = {}) => {
        extend(options, { animate: Boolean(options.animate) });

        this._animateShowModalWithActionBar(options);
        this.actionBar.show(options);
      },
      hide: (options = {}) => {
        extend(options, { animate: Boolean(options.animate) });

        this._animateHideModalWithActionBar(options);
        this.actionBar.hide(options);
      },
      disableButton: (...args) => {
        this.actionBar.disableButton(...args);
      },
      enableButton: (...args) => {
        this.actionBar.enableButton(...args);
      },
      trySetFocus: (...args) => {
        this.actionBar.trySetFocus(...args);
      }
    };

    this.bindUIElements();

    this.triggerMethod('render:actionBar', this);
  }

  /*
   * These animate methods complement the slideUp/Down animations in ActionBar so that
   * when the slideUp/Down animations run into the edge of the layout container there isn't any
   * jumping anymore.
   */
  _animateShowModalWithActionBar(options = {}) {
    if (options.animate && !this.actionBar.isVisible()) {
      const container = this.getContainer();
      const containerHeight = this.getHeight(container);
      const availableHeight = this.getAvailableHeight();
      const actionBarHeight = this.getActionBarHeight(true);

      const roomToGrow = availableHeight - containerHeight;
      const height = availableHeight - actionBarHeight;

      if (roomToGrow < actionBarHeight) {
        container.velocity('stop');
        container.velocity({ height }, { duration: 150 });

        this.updateStructuralViewState();
      }
    }
  }

  _animateHideModalWithActionBar({ animate } = {}) {
    if (animate && this.actionBar.isVisible()) {
      const container = this.getContainer();
      const wrapper = this.getContentWrapper();
      const wrapperHeight = this.getHeight(wrapper);
      const actionBarHeight = this.getActionBarHeight(true);
      const availableHeight = this.getAvailableHeight();

      const roomToGrow = wrapperHeight - availableHeight;

      const height = Math.min(wrapperHeight, availableHeight);

      if (roomToGrow > -actionBarHeight) {
        container.velocity('stop');
        container.velocity({ height }, { duration: 150 } );

        this.updateStructuralViewState();
      }
    }
  }
}

module.exports = AdjustableModalView;
