const logging = require('logging');
const {
  isMsTeamsIntegration,
  getMsTeamsContentUrl
} = require('@common/libs/helpers/app/NativeBridgeHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const HeartbeatController = require('@common/libs/HeartbeatController');


const MsTeamsHelpers = {
  tryRedirectToMsTeamsContentUrl() {
    if (isMsTeamsIntegration()) {
      this.saveUrlState();
      logging.info('Reload Teams Home URL:', getMsTeamsContentUrl());
      window.location.href = getMsTeamsContentUrl();
      return true;
    }
    return false;
  },

  keepSessionAlive(loggedIn = false) {
    if (isMsTeamsIntegration() && loggedIn) {
      // This statement only applies to users logged into MsTeams App.
      // It starts the infinite heartbeat to prevent users from being logged out of MsTeams Tab App and redirected to login page
      // We don't want users to get back to login page because we can't automatically re-authenticate them like in mobile apps
      logging.info('MsTeams: Start heartbeat to prevent timeout');
      this.heartbeatController = new HeartbeatController({maxTimeout: Infinity});
      this.heartbeatController.startHeartbeat();
    }
  },

  loadUrlState() {
    const hashQueryParams = UrlHelpers.getQueryParams(window.location.search || '');
    if (isMsTeamsIntegration() && hashQueryParams.supportedFeatures) {
      if (sessionStorage.getItem('LastPageVisit')) {
        const lastURL = sessionStorage.getItem('LastPageVisit');
        sessionStorage.removeItem('LastPageVisit');
        if (window.location.href === lastURL) {
          return;
        }
        window.location.href = lastURL;
      }
    }
  },

  saveUrlState() {
    const hashQueryParams = UrlHelpers.getQueryParams(window.location.search || '');
    //This Query check is to prevent an infinite loop when reloading a timed out session
    if (isMsTeamsIntegration() && !hashQueryParams.supportedFeatures) {
      sessionStorage.setItem('LastPageVisit', window.location.href);
    }
  }
};

module.exports = MsTeamsHelpers;
