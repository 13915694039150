const GuidedLearningOption = require('@common/data/models/assessments/GuidedLearningOption');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');

class SelfDirectedPathOption extends GuidedLearningOption {
  getLaunchContext() {
    return AssessmentLaunchContext.SELF_DIRECTED_PATHS;
  }
}

module.exports = SelfDirectedPathOption;
