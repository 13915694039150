const _ = require('underscore');
const logging = require('logging');

const Cocktail = require('backbone.cocktail');
const BackbonePageableCollection = require('backbone.pageable');

const Sync = require('@common/libs/Sync');

const LoadingEventsMixin = require('@common/mixins/data_loading/LoadingEventsMixin');

const Modes = {
  SERVER: 'server',
  CLIENT: 'client',
  INFINITE_PAGING: 'infinite'
};

// See https://github.com/backbone-paginator/backbone-pageable#adapting-to-a-server-api for
// what/how these configurations do/work
const defaultState = {
  firstPage: 0,
  pageSize: 20,
  order: -1
};

const defaultQueryParams = {
  totalPages: null,
  totalRecords: null,
  pageSize: 'rpp',
  currentPage: 'p',
  order: 'sortDirection',
  sortKey: 'sortValue',
  directions: {
    '-1': 'ASC',
    1: 'DESC'
  }
};

const getDefaultState = (mode) => {
  if (mode === Modes.INFINITE_PAGING) {
    return Object.assign({}, defaultState, {
      // Made up large numbers so that 'infinite' mode generates the proper links when it goes to grab them.
      totalRecords: 100000,
      totalPages: 2500
    });
  }

  return defaultState;
};

class PageableCollection extends BackbonePageableCollection {
  static Modes = Modes;

  initialize(models, options = {}) {
    super.initialize(models, options);

    // Needs to be bound here before the rest of PageableCollection's constructor runs cause due to scroping issue
    // when in 'infinite' mode.
    if (_.isFunction(this.url)) {
      this.url = this.url.bind(this);
    }

    ({
      mode: this.mode = PageableCollection.Modes.SERVER,
      // Make sure defaults set on subclass prototypes are accounted for
      sortState: this.sortState = this.sortState || {},
      extraParams: this.extraParams = this.extraParams || {}
    } = options);

    this.state = Object.assign({}, this.state, getDefaultState(this.mode));
    this.queryParams = Object.assign({}, this.queryParams, defaultQueryParams);

    this.setQueryToken(options.queryToken);
    this.setFetchType(options.fetchType);

    this.setHasNext(true);

    this.on('sync', this.onSync);
    this.on('error', this.onError);
    this.on('reset', this.onReset);
  }

  defaultFetchOptions() {
    return {
      type: this._fetchType
    };
  }

  setFetchType(type) {
    this._fetchType = type || 'POST';
  }

  parse(response, options) {
    try {
      return super.parse(response, options);
    } catch (e) {
      this.getFirstPage();
      return undefined;
    }
  }

  // Needed for 'infinite' mode
  parseLinks() {
    return {
      first: this.url,
      prev: this.url,
      next: this.url
    };
  }

  parseState(resp) {
    return {
      totalRecords: resp.totalSearchResults
    };
  }

  parseRecords(res) {
    const records = res && (res.results || res.entities || res);

    if (_.isArray(records)) {
      return records;
    }

    throw new Error('Standard results/entities key not found in response, please implement `parseRecords` in extended Collection');
  }

  fetch(fetchOptions = {}) {
    const defaultFetchOptions = _.result(this, 'defaultFetchOptions');

    const from = Math.max(0, this.state.currentPage - 1);
    const to = this.state.currentPage;

    const pageOptions = {
      from,
      to,
      success: _.wrap(fetchOptions.success, (success = () => {}, ...args) => {
        const response = args[1];
        // Add queryToken to the params so that it can be used for the next page
        const queryToken = this._getQueryTokenFromResponse(response);
        this.setQueryToken(queryToken);
        success(...args);
      }),
      error: _.wrap(fetchOptions.error, (error = () => {}, ...args) => {
        logging.debug(`Error loading page ${ to }`);
        error(...args);
      })
    };

    const dataOptions = {
      data: $.extend(true, {}, fetchOptions.data, this.sortState, this._getExtraParameters(), this._getQueryTokenParameters())
    };

    const options = Object.assign(pageOptions, defaultFetchOptions, fetchOptions, dataOptions);

    return super.fetch(options);
  }

  sync(method, model, options = {}) {
    // We need to override `sync` instead of `fetch` because `backbone.pageable`
    // also overrides `fetch` and modifies the `options` to set paging data which
    // we need to stringify on `POST` so we do it in `sync` instead of `fetch`.
    options.data = this._serializePostData(options);

    const requestData = _.pick(options, 'url', 'data', 'type');

    // deduplicate requests if there's already one pending for the specified request options
    if (this.isXHRPending(method)) {
      if (_.isEqual(this._lastRequestData, requestData)) {
        return this.getXHR(method);
      }

      this.abortXHR(method);
    }

    this._lastRequestData = requestData;

    return super.sync(method, model, options);
  }

  onSync(collection, response = {}, syncOptions = {}) {
    if (collection === this) {
      const requestData = this._deserializePostData(syncOptions);
      const requestPageSize = requestData[this.queryParams.pageSize];
      const responsePageSize = this.parse(response, syncOptions).length;

      this.setHasNext(responsePageSize >= requestPageSize);
    }
  }

  onError() {
    this.setHasNext(false);
  }

  onReset(collectionModels = [], options = {}) {
    // Make sure this only gets reset if it's an explicit call to reset() and not due to a network request
    if (options.xhr == null) {
      this.setHasNext(collectionModels.length === 0);
    }
  }

  search(query, pageNum = 0, options = {}) {
    this.searchQuery = query;
    return this.getPage(pageNum, options);
  }

  setHasNext(hasNext) {
    this._hasNext = hasNext;
  }

  hasNext() {
    return this._hasNext;
  }

  getPageSize() {
    return this.state.pageSize;
  }

  getPendingPage() {
    return this.isXHRPending(Sync.Method.READ) ? this.getXHRAjaxOptions(Sync.Method.READ).to : null;
  }

  isPagePending(pageNum) {
    return this.getPendingPage() === pageNum;
  }

  isFirstPagePending() {
    return this.isPagePending(this.state.firstPage);
  }

  isCurrentPagePending() {
    return this.isPagePending(this.state.currentPage);
  }

  isNextPagePending() {
    return this.isPagePending(this.state.currentPage + 1);
  }

  getNextPage(options) {
    if (this.hasNext()) {
      return super.getNextPage(options);
    }

    return $.Deferred().reject();
  }

  getPreviousPage(options) {
    if (this.hasPreviousPage()) {
      return super.getPreviousPage(options);
    }

    return $.Deferred().reject();
  }

  getPage(index = 0, options = {}) {
    let pageIndex = index;

    if (pageIndex < 0) {
      pageIndex = 'first';
    }

    try {
      return super.getPage(pageIndex, options);
    } catch (e) {
      logging.error(`PageableCollection.getPage: Failed to get page when pageIndex = ${ pageIndex }`);
      return $.Deferred().reject(); //return a rejection just in case anyone wants to catch it and do some extra logic when they extend this class
    }
  }

  changeSortState(sortState = {}) {
    this.sortState = sortState;
    return this.getFirstPage();
  }

  changeExtraParameters(extraParams, options = {}) {
    this.extraParams = extraParams;

    const modifiedOptions = Object.assign({}, {
      reset: true,
      fetch: true
    }, options);

    if (modifiedOptions.fetch !== false) {
      return this.getFirstPage(modifiedOptions);
    }

    return $.Deferred().reject();
  }

  setQueryToken(queryToken) {
    this.queryToken = queryToken;
  }

  getInitialSort() {
    return _.get(this, 'sortState.sortingCriteria[0]');
  }

  _getExtraParameters() {
    return this.extraParams;
  }

  _getQueryTokenParameters() {
    return {
      queryToken: this.queryToken
    }
  }

  _getQueryTokenFromResponse(response) {
    return _.get(response, 'pagination.queryToken');
  }

  _serializePostData(options = {}) {
    const {
      type,
      data
    } = options;

    if (type === 'POST') {
      return JSON.stringify(data);
    }

    return data;
  }

  _deserializePostData(options = {}) {
    const {
      type,
      data
    } = options;

    if (type === 'POST') {
      return JSON.parse(data);
    }

    return data;
  }
}

Cocktail.mixin(PageableCollection, LoadingEventsMixin);

module.exports = PageableCollection;
