// Module
var code = `<div class="ax-grid">
  <div class="ax-grid__col--m-6 month-year-date-select-wrapper">
    <div class="ax-grid">
      <div>
        <%= axSelect({
          ariaLabel: t('UIKit.Form.Editor.DateTime.selectMonth'),
          className: 'month-year-date-select',
          classNameSelect: 'qa-month-select',
          id: 'monthPicker',
          fullWidth: 'true'
        }) %>
      </div>
      <div>
        <%= axSelect({
          ariaLabel: t('UIKit.Form.Editor.DateTime.selectYear'),
          className: 'month-year-date-select',
          classNameSelect: 'qa-year-select',
          id: 'yearPicker',
          fullWidth: 'true'
        }) %>
      </div>
    </div>
  </div>
  <div class="ax-grid__col--auto-size">
    <button type="button" id="prevButton" class="direction-button prev-button" title="Prev" aria-label="<%- t('UIKit.Form.Editor.DateTime.selectPreviousMonth') %>"><span class="icon-angle_left"></span></button>
    <button type="button" id="nextButton" class="direction-button next-button" title="Next" aria-label="<%- t('UIKit.Form.Editor.DateTime.selectNextMonth') %>"><span class="icon-angle_right"></span></button>
  </div>
</div>
`;
// Exports
module.exports = code;