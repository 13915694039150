import {
  type Ref,
  forwardRef
} from 'react';
import { styled } from '@mui/material';
import {
  TabPanel as MuiTabPanel,
  type TabPanelProps as MuiTabPanelProps
} from '@mui/lab';

export type AxTabPanelProps = MuiTabPanelProps;

const styledOptions = {
  name: 'AxTabPanel'
};

const StyledAxTabPanel = styled(MuiTabPanel, styledOptions)<AxTabPanelProps>(() => {
  return {};
});

export const AxTabPanel = forwardRef(({
  ...otherProps
}: AxTabPanelProps, ref: Ref<HTMLButtonElement>) => {
  const TabPanelProps: AxTabPanelProps = {
    ...otherProps
  };

  return (
    <StyledAxTabPanel { ...TabPanelProps } ref={ ref } />
  );
});

export default AxTabPanel;
