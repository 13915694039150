const Marionette = require('Marionette');
const ArticleDetailsCommentView = require('@training/apps/articles/ArticleDetailsCommentView');
const ArticleDetailsNoCommentView = require('@training/apps/articles/ArticleDetailsNoCommentView');

class ArticleDetailsCommentCollectionView extends Marionette.CollectionView {
  className() {
    return 'comment-collection';
  }

  get childView() {
    return ArticleDetailsCommentView;
  }

  get emptyView() {
    return ArticleDetailsNoCommentView; // shows if there are no comments. make sure you test that
  }

}

module.exports = ArticleDetailsCommentCollectionView;
