const Marionette = require('Marionette');

class AutocompleteBasicNameParamView extends Marionette.ItemView {
  tagName() {
    return 'li';
  }

  getTemplate() {
    return '<a class="basic-link clickable"><%- nameParam %></a>';
  }

  events() {
    return {'mousedown a.basic-link': 'onMousedown'};
  }

  templateHelpers() {
    return {nameParam: this.model.get(this.nameParam)};
  }

  initialize(options = {}) {
    ({nameParam: this.nameParam} = options);
  }

  onMousedown(e) {
    if (e.button === 0) {
      if (this.model.id != null) {
        this.trigger('click:add', this.model, this.model.get(this.nameParam));
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }
}

class AutocompleteDropdownGenericResultsProviderCollectionView extends Marionette.CollectionView {
  tagName() {
    return 'ul';
  }

  getChildView() {
    return this.AutocompleteItem;
  }

  childViewOptions() {
    return {nameParam: this.nameParam};
  }

  initialize(options = {}) {
    ({
      nameParam: this.nameParam,
      AutocompleteItem: this.AutocompleteItem
    } = options);
  }
}

module.exports = {AutocompleteDropdownGenericResultsProviderCollectionView, AutocompleteBasicNameParamView};
