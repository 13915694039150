const logging = require('logging');
const _ = require('underscore');

const ActivitiesController = require('../ActivitiesController');

class TrainingModuleController extends ActivitiesController {

  constructor(...args) {
    super(...args);
    this.processActivities = this.processActivities.bind(this);
    this.finishedProcessing = this.finishedProcessing.bind(this);
  }

  getNextActivity() {
    return this.sessionModel.currentAssessment.activities.getNextTrainingModule();
  }

  processActivities() {
    logging.debug('Processing training module activities');
    super.processActivities(...arguments);
  }

  finishedProcessing() {
    logging.debug('Finished processing training modules');
    if (this.parentProcessor && _.isFunction(this.parentProcessor.processActivities)) {
      this.parentProcessor.processActivities();
    }
  }
}

module.exports = TrainingModuleController;
