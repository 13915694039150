const $os = require('detectOS');
const { LayoutView } = require('Marionette');

const TabTrapper = require('@common/libs/behaviors/tabTrapper/TabTrapper');
require('@common/libs/behaviors/androidBackHandler/AndroidBackHandler');

const {
  enableScalingInWebView,
  disableScalingInWebView
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

const key = require('keymaster');

require('jquery.velocity');
require('@common/components/mediaZoom/MediaZoom.less');

class MediaZoomLayout extends LayoutView {
  behaviors() {
    return Object.assign(super.behaviors(), {
      TabTrapper: {},
      AndroidBackHandler: {
        handler: '_close'
      }
    });
  }

  className() {
    const {
      isImage,
      isVideo
    } = this.options;
    const cssClassImage = isImage ? 'media-zoom--image' : '';
    const cssClassVideo = isVideo ? 'media-zoom--video' : '';
    return `media-zoom fit-parent ${ cssClassImage } ${ cssClassVideo }`;
  }

  attributes() {
    return {
      role: 'dialog'
    };
  }

  regions() {
    return {
      imageRegion: '.media-zoom-region'
    };
  }

  ui() {
    return {
      mediaCloseButton: '.media-close-button'
    };
  }

  events() {
    const events = {
      'click @ui.mediaCloseButton': 'onClick'
    };

    if ($os.desktop) {
      events['click'] = 'onClick';
    }

    return events;
  }

  childEvents() {
    return {
      'toggle:zoomable': (childView, isZoomable) => {
        if (isZoomable) {
          enableScalingInWebView();
        } else {
          disableScalingInWebView();
        }
      }
    };
  }

  getTemplate() {
    return `
      <div class="media-zoom-click-layer fit-parent">
      <div class="media-zoom-vertical-stilt inline-block vertical-align-middle parent-height"></div>
      <div class="media-zoom-wrapper inline-block vertical-align-middle">
      <div class="media-zoom-region"></div>
      </div>
      <button class="media-close-button clickable" aria-label="<%- t('general.close') %>">
        <span class="icon-remove"></span>
      </button>
      </div>
    `;
  }

  constructor(...arg) {
    super(...arg);

    this.onEscapeKey = this.onEscapeKey.bind(this);

    key('escape', 'MediaZoomLayout', this.onEscapeKey);
    key.setScope('MediaZoomLayout');
  }

  onAttach() {
    TabTrapper.toggleTrapper(this, true);
    this._fadeIn();
  }

  onEscapeKey() {
    this._close();
    return false;
  }

  onClick() {
    this._close();
  }

  _close() {
    this._fadeOut(() => {
      if (this.isDestroyed) {
        return;
      }
      this.destroy();
    });

    if (this.previousActiveElement && this.previousActiveElement.focus) {
      this.previousActiveElement.focus();
    }
  }

  onDestroy() {
    TabTrapper.toggleTrapper(this, false);
    // Prevent pich to zoom and panning
    disableScalingInWebView();
    key.deleteScope('MediaZoomLayout');
  }

  _fadeIn() {
    this.previousActiveElement = document.activeElement;

    this.$el.velocity('stop');
    this.$el.velocity({
      opacity: 1
    }, {
      duration: 300,
      complete: () => {
        this._focusFirstAvailable();
      }
    });
  }

  _fadeOut(complete = $.noop) {
    this.$el.velocity('stop');
    this.$el.velocity({
      opacity: 0
    }, {
      duration: 300,
      complete
    });
  }

  _focusFirstAvailable() {
    this.$el.find(TabTrapper.FOCUSABLE_ELEMENTS_STRING)
      .filter(TabTrapper.VISIBLE_TABBABLE_ELEMENTS_STRING)
      .first()
      .trigger('focus');
  }
}

module.exports = MediaZoomLayout;
