import {
  ListItemText as MuiListItemText,
  type ListItemTextProps as MuiListItemTextProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxListItemTextProps = MuiListItemTextProps;

const styledOptions = {
  name: 'AxListItemText'
};

const StyledAxListItemText = styled(MuiListItemText, styledOptions)<AxListItemTextProps>(() => {
  // Many consumers use this component to render text, each one with its own default styles
  // We should not set any default styles here
  // If we need to set default styles, we should do it in the consumer component or directly to this component in the consumer component with the sx prop
  return {};
});

/**
* A container inside a list item, used to display text content.
*
* ### Links
* - • [MUI ListItemText | API](https://mui.com/material-ui/api/list-item-text/)
*
* @example
* <AxListItem>
*  <AxListItemText primary='Drafts' />
* </AxListItem>
*/
export const AxListItemText = forwardRef(({
  children,
  ...otherProps
}: AxListItemTextProps, ref: Ref<HTMLLIElement>) => {
  const ListItemTextProps: AxListItemTextProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemText { ...ListItemTextProps } ref={ ref }>
      { children }
    </StyledAxListItemText>
  );
});

export default AxListItemText;
