const UIKit = require('@training/widgets/UIKit');
const logging = require('logging');
const _ = require('underscore');
const { Model } = require('Backbone');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const I18n = require('@common/libs/I18n');
const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

class AuctionSlotsView extends UIKit.View {
  constructor(...args) {
    super(...args);
    this.startReelAnimation = this.startReelAnimation.bind(this);
    this.reelImageReplacement = this.reelImageReplacement.bind(this);
    this.defaultSpin = this.defaultSpin.bind(this);
    this.check = this.check.bind(this);
    this.template = _.tpl(`\
<div class="ax-grid ax-grid--no-gutter">
  <div class="ax-grid__col--12 ax-grid__col--l-10">
    <div id="slot-machine">
      <div class="slot-machine-content clearfix" aria-hidden="true">
        <div class="payline"></div>
        <div class="ax-grid">
          <div class="reel-container">
            <div class="reel-shadow"></div>
            <div class="reel" id="reel-one"></div>
          </div>
          <div class="reel-container">
            <div class="reel-shadow"></div>
            <div class="reel" id="reel-two"></div>
          </div>
          <div class="reel-container">
            <div class="reel-shadow"></div>
            <div class="reel" id="reel-three"></div>
          </div>
        </div>
      </div>
      <div class="slot-machine-content footer clearfix ax-grid">
        <div class="cost-per-spin ax-grid__col--12 ax-grid__col--m-3 ax-grid__col--l-2">
          <div class="qa-cost-per-spin-container cost-per-spin-container ax-grid ax-grid--no-gutter ax-grid--align-items-center ax-grid--justify-content-center">
            <span class="auc-label ax-font ax-font__caption ax-grid__col--12"><%- t('auction.costPerSpin.title')%></span>
            <span class="qa-auc-value auc-value auc-value--l ax-font--no-margin ax-grid__col--12"><%- slotMachine.costPerSpin %></span>
          </div>
        </div>
        <div class="qa-slot-machine-messages-wrapper slot-machine-messages-wrapper ax-grid__col--8 ax-grid__col--m-6 ax-grid__col--l-8">
          <div class="slot-machine-message ax-grid ax-grid--align-items-center ax-grid--justify-content-center ax-grid--no-gutter">
            <h2 class="qa-msg msg msg--h2 ax-font--no-margin" aria-live="assertive"><%- t('auction.spinToWinText') %></h2>
          </div>
          <div class="ax-grid">
            <div class="qa-points-balance slot-machine-status points-balance ax-grid__col--12 ax-grid__col--m-6">
              <span class="auc-label ax-font__caption"><%- t('auction.pointBalance')%></span>
              <span class="qa-auc-value auc-value auc-value--m ax-font--no-margin"><%- slotMachine.pointsBalance %></span>
            </div>
            <div class="qa-spin-count slot-machine-status spin-count ax-grid__col--12 ax-grid__col--m-6">
              <span class="auc-label ax-font__caption"><%- t('auction.spinsLeft')%></span>
              <span class="qa-auc-value auc-value auc-value--m ax-font--no-margin"><%- slotMachine.spinCount %></span>
            </div>
          </div>
        </div>
        <div class="group lever ax-grid__col--4 ax-grid__col--m-3 ax-grid__col--l-2">
          <button class="spin-button ax-button <% if(slotMachine.spinCount === 0) { %> disabled <% } %>" <% if(slotMachine.spinCount === 0) { %> aria-disabled="true" <% } %>>
            <%- t('auction.spin')%>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>\
`);
    this.prizeTemplate = _.tpl(`\
<div class="prize-image<% if(isLocked) {%> locked <%}%>" data-prize-id="<%- id %>" > <img aria-hidden="true" src="<%- img %>" />
  <div class="locks"></div>
</div>\
`);
  }

  events() {
    return {
      'click .spin-button': 'startSpin'
    };
  }

  initialize() {
    this.slotMachine = {
      costPerSpin: TenantPropertyProvider.get().getProperty('pointCostPerSlotSpin'),
      pointsBalance: window.apps.auth.session.user.availablePoints(),
      maxSpins: TenantPropertyProvider.get().getProperty('maxSlotSpinsPerSession'),
      spinCount: 0
    };

    this.listenTo(this, 'change:spinCount', () => {
      return this.render();
    });

    this.apiReturned = false;
    // Default Values for slot machine
    this.maxSpinTime = 2000; //time in ms the slots will run
    this.reelOneSpinTime = this.maxSpinTime * .6;
    this.reelTwoSpinTime = this.maxSpinTime * .75;
    this.height = 620;
    this.speeds = [2, 4, 3];
    this.start = undefined;
    this.revolutions = [];
  }

  render() {
    this.prizeArray = this.collection.toJSON() || [];
    this.$el.html(
      this.template({slotMachine: this.slotMachine})
    );

    if (this.slotMachine.spinCount === 0) {
      this.$('.msg').html(I18n.t('auction.outOfSpins'));
      this.disableSpinButton();
    }

    this.setDefaultReels();
    this.triggerAdjustment();
    return this;
  }

  getSpinCount(slotMachine) {
    this.slotMachine = (slotMachine != null) ? slotMachine : this.slotMachine;
    this.spinCountRequest = this.collection.getSpinsCount({
      success: (response = {}) => {
        const spinCount = response.count != null ? response.count : 0;
        const maxPossibleSpins = Math.floor(this.slotMachine.pointsBalance / this.slotMachine.costPerSpin);

        if (this.slotMachine.maxSpins === 0) {
          this.trigger('change:spinCount', this.slotMachine);
        } else if (spinCount > this.slotMachine.maxSpins) {
          this.slotMachine.spinCount = 0;
        } else if (spinCount === 0) {
          // User can do more than the default maxspins
          // Set Spin Count to the max
          // else set to the users max possible spins
          this.slotMachine.spinCount = maxPossibleSpins;
          if (maxPossibleSpins > this.slotMachine.maxSpins) {
            this.slotMachine.spinCount = this.slotMachine.maxSpins;
          }
        } else {
          // Same checks as above, but user has done spins in the session
          // So the spin count has to be taken off
          this.slotMachine.spinCount = Math.min(maxPossibleSpins, this.slotMachine.maxSpins - spinCount);
        }

        this.trigger('change:spinCount', this.slotMachine);
      },

      error(response) {
        const exception = AxonifyExceptionFactory.fromResponse(response);

        if ([AxonifyExceptionCode.CLIENT_ERROR_MAX_SPINS_PER_DAY_REACHED, AxonifyExceptionCode.CLIENT_ERROR_INSUFFICIENT_QUANTITY].includes(exception.getErrorCode())) {
          window.app.layout.flash.error(I18n.t('auction.outOfSpins'));
          response.skipGlobalHandler = true;
        }
      }
    });
  }

  setDefaultReels() {
    this.rewardProgramPrizeImages = _.map(this.prizeArray, (list) => {
      const media = list.prize.prizeImg;
      const imageFile = ImageHelpers.getBiggestImageFileBySize(media.sizes, {
        maxHeight: 100,
        maxWidth: 100
      }) || media.originalFile;

      const prize = {
        id: list.id,
        img: imageFile.path,
        isLocked: list.isLocked
      };

      return prize;
    });

    const barImage = {
      id: -1,
      img: `/training/${ env.settings.version }/images/auction/bar@2x.png`,
      isLocked: false
    };

    const cherryImage = {
      id: -2,
      img: `/training/${ env.settings.version }/images/auction/cherries@2x.png`,
      isLocked: false
    };

    const plumImage = {
      id: -3,
      img: `/training/${ env.settings.version }/images/auction/plum@2x.png`,
      isLocked: false
    };

    const orangeImage = {
      id: -4,
      img: `/training/${ env.settings.version }/images/auction/orange@2x.png`,
      isLocked: false
    };

    this.dummyImagePoolOne = [barImage, cherryImage];
    this.dummyImagePoolTwo = [plumImage, orangeImage];
    this.dummyImagePoolThree = [barImage, cherryImage, plumImage, orangeImage];

    const reelOneImagePool = this.rewardProgramPrizeImages.concat(this.dummyImagePoolOne);
    const reelTwoImagePool = this.rewardProgramPrizeImages.concat(this.dummyImagePoolTwo);
    const reelThreeImagePool = this.dummyImagePoolThree;

    const reelOne = _.shuffle(reelOneImagePool);
    const reelTwo = _.shuffle(reelTwoImagePool);
    const reelThree = _.shuffle(reelThreeImagePool);

    this.reels = [
      reelOne,
      reelTwo,
      reelThree
    ];

    this.attachReels(this.reels);
  }

  attachReels(reels) {

    // For each reel in the slot machine
    this.$reels = this.$('.reel').each((r, el) => {
      const $imgSrc = $(el);
      const $container = $('<div />');

      _.each(reels[r], (prize) => {
        $container.append(this.prizeTemplate(prize));
      });

      return (() => {
        const result = [];
        for (let i = 0; i <= 3; i++) {
          result.push($imgSrc.append($($container).html()));
        }
        return result;
      })();
    });

    const $prizeImagesInReel = this.$('#reel-one .prize-image');
    const $prizeImagesInThirdReel = this.$('#reel-three .prize-image');

    this.prizeImageArrayLength = Math.min($prizeImagesInReel.length, $prizeImagesInThirdReel.length);
    this.prizeImageHeight = $prizeImagesInReel.first().height();
    this.maxScrollTopPosition = (this.prizeImageArrayLength - 2) * this.prizeImageHeight;

    // Create an upperLimit (75%) for the reel to scroll to
    // Once it reaches that set it back to a default position
    // Based on amount of prize items in the dom and their image height
    this.upperLimit = Math.floor( ((this.prizeImageArrayLength * this.prizeImageHeight) - this.prizeImageHeight ) * .75);


    // Get the default images for the winning slots
    // Get a default image to replace the a winning image with
    const reelOneWinningSlot = this.$('#reel-one .prize-image')[1];
    const reelTwoWinningSlot = this.$('#reel-two .prize-image')[1];
    const reelThreeWinningSlot = this.$('#reel-three .prize-image')[1];

    this.$reelOneWinningSlotImage = $(reelOneWinningSlot).find('img')[0];
    this.$reelTwoWinningSlotImage = $(reelTwoWinningSlot).find('img')[0];
    this.$reelThreeWinningSlotImage = $(reelThreeWinningSlot).find('img')[0];
  }

  disableSpinButton() {
    const $spinButton = this.$('.spin-button');
    $spinButton.addClass('disabled');
    $spinButton.attr('aria-disabled', 'true');
    this.spinButtonDisabled = true;
  }

  enableSpinButton() {
    const $spinButton = this.$('.spin-button');
    $spinButton.removeClass('disabled');
    $spinButton.attr('aria-disabled', 'false');
    this.spinButtonDisabled = false;
  }

  startSpin() {
    if (this.spinButtonDisabled) {
      return;
    }
    this.$('.msg').html(I18n.t('auction.spinning'));
    // Reset conditional variable
    // Event if a connection is lost; reel will spin until
    // Connection is regained or ajax retry limit is reached
    this.prizeWon = null;
    this.apiReturned = false;
    this.disableSpinButton();

    if (this.slotMachine.spinCount > 0) {
      //Start spinning before the api has returned
      this.startSpinAnimation();

      this.collection.spinReels({
        success: (result) => {
          this.prizeWon = result.spin;
          logging.info('Successfully spun the slot machine');
          logging.debug(`Won a prize - ${ this.prizeWon.win }`);

          if (this.prizeWon.prizeWon != null) {
            this.winningImage = _.find(this.rewardProgramPrizeImages, (value) => {
              if (this.prizeWon.prizeWon.id === value.id) {
                return value;
              }
              return null;
            });
          }

          this.reelImageReplacement();
          this.updateSpinCount();
        },

        error: (response) => {
          const exception = AxonifyExceptionFactory.fromResponse(response);
          const errCode = exception.getErrorCode();

          if ([AxonifyExceptionCode.CLIENT_ERROR_MAX_SPINS_PER_DAY_REACHED, AxonifyExceptionCode.CLIENT_ERROR_INSUFFICIENT_QUANTITY].includes(errCode)) {
            this.$('.msg').html(I18n.t('auction.outOfSpins'));

            this.slotMachine.spinCount = 0;
            this.$('.slot-machine-status.spin-count .auc-value').html(this.slotMachine.spinCount);
            response.skipGlobalHandler = true;
            this.apiReturned = true;
          } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_CANNOT_WIN_PRIZE) {
            // In the event the auction finishes mid spin
            // Just swallow the error and continue normal spin behaviour
            response.skipGlobalHandler = true;
            this.updateSpinCount();
          }
        }
      });

    } else {
      this.$('.msg').html(I18n.t('auction.outOfSpins'));
    }
  }

  updateSpinCount() {
    this.apiReturned = true;
    this.slotMachine.spinCount--;
    window.apps.auth.session.user.subtractPoints(this.slotMachine.costPerSpin);
    this.slotMachine.pointsBalance -= this.slotMachine.costPerSpin;
    this.$('.slot-machine-status.spin-count .auc-value').html(this.slotMachine.spinCount);
    this.$('.slot-machine-status.points-balance .auc-value').html(this.slotMachine.pointsBalance);
  }

  startSpinAnimation() {
    // Set some positions
    this.randomFirstReel = _.range(0, $('#reel-one .prize-image').length - 1);
    this.randomSecondReel = _.range(0, $('#reel-two .prize-image').length - 1);
    this.randomThirdReel = _.range(0, $('#reel-three .prize-image').length - 1);

    // Create an random position to use for all reels
    // Don't want the value to be the first or last position
    // To allow the reel image to have one above and one below
    const reelValue = Math.min(this.randomThirdReel.length, this.randomFirstReel.length);
    const tmpValue = reelValue - 2 - 2;
    // For the losingIndex value we want to exclude the at the least the first two elements
    // and the last two elements.
    // so the index needs to add the +2 (for first elements in the array)
    this.randomLosingReelIndex = Math.floor((Math.random() * tmpValue) + 2);
    this.randomSecondReelIndex = Math.floor((Math.random() * tmpValue) + 2);

    this.startReelAnimation();
  }

  startReelAnimation(now) {
    if (!this.start) {
      this.start = now;
    }
    let time = (now - this.start) || 0;

    // Keep Spinning until api returns
    if (!this.apiReturned) {
      time = 0;
    }

    // Set the final position for reel one and two if the current spin time matches the reels default time
    // Otherwise just continue with the spin animation
    if (time > this.reelOneSpinTime) {
      this.setFinalReelPositions($('#reel-one .prize-image'), 'reelOne', 0);
    } else {
      this.spinReel($('.reel')[0]);
    }

    if (time > this.reelTwoSpinTime) {
      this.setFinalReelPositions($('#reel-two .prize-image'), 'reelTwo', 1);
    } else {
      this.spinReel($('.reel')[1]);
    }

    // The third reel should just end when the maxSpinTime has been reached
    // So just spin the third reel until time is up
    this.spinReel($('.reel')[2]);

    if (time < this.maxSpinTime) {
      this.frameRequestId = requestAnimationFrame(this.startReelAnimation);
      return;
    }

    this.setFinalReelPositions($('#reel-three .prize-image'), 'reelThree', 2);
    this.start = undefined;
    this.check();
  }

  reelImageReplacement() {
    if (this.prizeWon != null ? this.prizeWon.win : undefined) {
      this.$reelOneWinningSlotImage.src = this.winningImage.img;
      this.$reelTwoWinningSlotImage.src = this.winningImage.img;
      this.$reelThreeWinningSlotImage.src = this.winningImage.img;
    } else {
      this.$reelOneWinningSlotImage.src = this.dummyImagePoolOne[0].img;
      this.$reelTwoWinningSlotImage.src = this.dummyImagePoolTwo[0].img;

      this.$reelThreeWinningSlotImage.src = $(this.$('#reel-three .prize-image')[0]).find('img')[0].src;
    }
  }

  spinReel(reel) {
    reel.scrollTop = this.modifyScrollTop(reel.scrollTop);
  }

  modifyScrollTop(reelScrollTop) {
    let scrollTop;

    if (reelScrollTop > this.upperLimit) {
      scrollTop = this.prizeImageHeight;
    } else {
      scrollTop = reelScrollTop + 80;
    }
    // Increment the scrolltop to give the effect of spinning
    return scrollTop;
  }

  setFinalReelPositions($reel, reelName, reelNumber) {
    if (this.prizeWon != null ? this.prizeWon.win : undefined) {
      this.setWinningImage($reel, reelNumber);
      return;
    }
    switch (reelName) {
      case 'reelOne':
        $('.reel')[0].scrollTop = this.getReelScrollTop($reel, this.randomFirstReel, false);
        break;
      case 'reelTwo':
        $('.reel')[1].scrollTop = this.getReelScrollTop($reel, this.randomSecondReel, false, this.randomSecondReelIndex);
        break;
      case 'reelThree':
        $('.reel')[2].scrollTop = this.getReelScrollTop($reel, this.randomThirdReel, true);
        break;
      default: break;
    }
  }

  getReelScrollTop($reel, randomReel, addOffset = false, randomReelIndex = this.randomLosingReelIndex) {
    let offset = 0;

    if (addOffset) {
      offset = ($reel.first().outerHeight(true) / 2) + 1;
    }

    return ($($reel[randomReel[randomReelIndex]]).offset().top - $($reel[1]).offset().top) + offset;
  }

  setWinningImage(reel, spunReel) {
    $('.reel')[spunReel].scrollTop = 0;
  }

  getScrollPosition(imageToDisplayLocation, initialDisplayImage) {
    const scrollToPosition = $(imageToDisplayLocation).offset().top - $(initialDisplayImage).offset().top;
    return scrollToPosition;
  }

  defaultSpin() {
    if (this.start !== undefined) {
      return;
    }
    this.$('.msg').html(I18n.t('auction.spinning'));
    for (let i = 0; i <= 3; i++) {
      this.speeds[i] = Math.random() + .5;
      this.revolutions[i] = (((Math.random() * 3) | 0) * this.height) / 3;
    }

    this.defaultReelShuffle();
  }

  defaultReelShuffle(now) {
    if (!this.start) {
      this.start = now;
    }
    const t = (now - this.start) || 0;

    return [0, 1, 2].map((i) => {
      return (this.$reels[i].scrollTop = ((((this.speeds[i] / this.maxSpinTime / 2) * (this.maxSpinTime - t) * (this.maxSpinTime - t)) + this.revolutions[i]) % this.height) | 0);
    });
  }

  check() {
    if (this.prizeWon != null ? this.prizeWon.win : undefined) {
      logging.debug(`Successfully won a prize from the slot machine - prize ${ this.prizeWon.prizeWon.prize.name }`);
      this.$('.msg').html(`<span class="slotwinner">${ I18n.t('auction.winner') }</span>`);

      const winModel = new Model(Object.assign({}, this.prizeWon.prizeWon, { slotMachineWin: true }));

      const winModal = new WinModal({
        model: winModel
      });

      window.app.layout.presentModal(winModal, {
        closeClick: false,
        closeEsc: false
      });
    } else {
      this.$('.msg').html(I18n.t('auction.tryAgain'));
    }

    if (this.slotMachine.spinCount === 0) {
      this.disableSpinButton();
      this.$('.msg').html(I18n.t('auction.outOfSpins'));
    } else {
      return setTimeout(() => {
        return this.enableSpinButton();
      }
      , 250);
    }
    return null;
  }

  onClose() {
    cancelAnimationFrame(this.frameRequestId);
    if (this.spinCountRequest != null) {
      this.spinCountRequest.abort();
    }

    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }

    super.onClose();
  }

  onDestroy() {
    window.app.layout.dismissMedia();
  }
}

class WinModal extends AccessibleModalView {

  id() {
    return 'modalview';
  }

  className() {
    return 'modal win-modal';
  }

  behaviors() {
    return Object.assign({}, super.behaviors(), {
      ZoomOverlay: {}
    });
  }

  contentTemplate() {
    const templateFn = _.tpl(require('../templates/_win_modal.html'));
    return templateFn({
      model: this.model.toJSON()
    });
  }

  viewDidAppear() {
    super.viewDidAppear();
    const { prizeImg } = this.model.get('prize');
    if (prizeImg != null) {
      const imageViewer = ImageViewerFactory.createViewerInstance({
        media: prizeImg,
        $el: this.$('.itemimage'),
        ariaHidden: true
      });
      imageViewer.render();
    }

    this.listenTo(this, 'image:clicked', () => {
      window.app.layout.showMedia(prizeImg);
    });
  }
}

module.exports = AuctionSlotsView;
