const Enum = require('@common/data/enums/Enum');
const I18n = require('@common/libs/I18n');

const configTypes = {
  OPENID_CONNECT: {
    type: 'OPENID_CONNECT',
    url: 'openid'
  },
  OAUTH_2: {
    type: 'OAUTH_2',
    url: 'oauth2'
  }
};

const OAuthConfigTypeEnum = Enum(
  configTypes, {
    getTypeStr(type) {
      return {
        [configTypes.OPENID_CONNECT.type]: I18n.t('OAuthTypes.openIdConnect'),
        [configTypes.OAUTH_2.type]: I18n.t('OAuthTypes.oauth2')
      }[type];
    }
  }
);

module.exports = OAuthConfigTypeEnum;
