const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const { LayoutView } = require('Marionette');
const { getScrollParent } = require('@common/libs/helpers/app/BrowserHelpers');

const ActionBarButtonFactory = require('@common/components/actionBarButton/ActionBarButtonFactory');
const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');

const Cocktail = require('backbone.cocktail');

class FilteredEventLayoutView extends LayoutView {
  p = 0;

  rpp = 10;

  noMorePages = false;

  initialize(options = {}) {
    ({
      guidedLearningFlowCallback: this.guidedLearningFlowCallback,
      urlSlug: this.urlSlug
    } = options);

    this.backToList = this.backToList.bind(this);
  }

  get template() {
    return require('@training/apps/training/templates/_learning_event_filtered.html');
  }

  get className() {
    return 'filtered-event rap';
  }

  regions() {
    return {filteredEventRegion: '.filtered-event__region'};
  }

  viewDidAppear() {
    this.getNextEvents();
  }

  onAttach() {
    // Infinite Scroll
    this.$scrollContainer = $(getScrollParent(this.el));

    // Back to GL Flow list
    const button = ActionBarButtonFactory.createButtonWithCustomText(
      this._getButtonText(),
      this.backToList
    );
    this.actionBar.setActionButton(ActionBarButtonFactory.toggleButtonSecondary(button, true));

    window.app.layout.showHeaderBackButton();
  }

  _getButtonText() {
    switch (this.urlSlug) {
      case 'paths':
        return I18n.t('assessments.select.viewPage.PathTraining');
      case 'guided':
        return I18n.t('assessments.select.viewPage.GuidedLearningTraining');
      default:
        return '';
    }
  }

  backToList() {
    this.guidedLearningFlowCallback();
  }

  onLoadNext() {
    this.getNextEvents();
  }

  getNextEvents() {
    if (this.isLoadingPage || this.noMorePages) {
      return;
    }

    this.isLoadingPage = true;

    const dataOptions = {
      p: this.p,
      rpp: this.rpp
    };

    const shouldRemove = this.p === 0;

    const fetchOptions = {
      add: true,
      update: true,
      remove: shouldRemove,
      data: dataOptions,
      complete: () => {
        const activeElement = this.$(document.activeElement);
        if (activeElement.hasClass('event-item')) {
          this.activeRow = activeElement.attr('aria-posinset');
        }

        this.setAriaProperties();
        this.isLoadingPage = false;
        this.p += 1;
        return this.p;
      },
      success: (collection, response) => {
        return this.fetchSuccess(response);
      }
    };

    this.collection.fetch(fetchOptions);
  }

  fetchSuccess(response) {
    if (response.results.length < this.rpp) {
      this.noMorePages = true;
    }
  }

  // Set values needed for aria-attributes in row templates
  setAriaProperties() {
    _.each(this.collection.models, (model, index) => {
      model.set('listLength', this.collection.length);
      model.set('posInSet', index + 1);
    });
  }

  onDestroy() {
    this.actionBar.setActionButton();
  }
}

Cocktail.mixin(FilteredEventLayoutView, InfiniteScrollViewMixin);

module.exports = FilteredEventLayoutView;
