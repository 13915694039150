const ViewOptionHelpers = require('@common/libs/helpers/app/ViewOptionHelpers');
const ArticleDetailsCommentCollectionController = require('@training/apps/articles/ArticleDetailsCommentCollectionController');
const ArticleDetailsCommentView = require('@training/apps/articles/ArticleDetailsCommentView');
const ArticleDetailsCommentTitleController = require('@training/apps/articles/ArticleDetailsCommentTitleController');
const ArticleDetailsCommentFormController = require('@training/apps/articles/ArticleDetailsCommentFormController');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const ArticleDetailsCommentCollection = require('@training/apps/articles/ArticleDetailsCommentCollection');
const ArticleDetailsCommentController = require('@training/apps/articles/ArticleDetailsCommentController');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

class ArticleDetailsCommentRegionController extends StackedLayoutViewController {
  initialize(options = {}) {
    ({
      articleData: this.articleData,
      showComments: this.showComments
    } = options);

    this.scrollToCommentForm = this.scrollToCommentForm.bind(this);

    // OK so by default comments are off (for a community) but we turn it on based on one of two different properties
    // that might exist in the data
    // this is a bit redundant: the server checks the tenant property when calculating isCommentable
    this.commentsAllowed = this.isCommsTenantPropertyOn() && this.articleData.get('isCommentable');

    if (this.commentsAllowed && this.showComments) {
      if (!this.commentsCollection) {
        this.commentsCollection = new ArticleDetailsCommentCollection([], {pageId: this.articleData.get('id')});
        this.commentsCollection.fetch({ data: {
          p: 0,
          rpp: 200,
          sortDirection: 'DESC'
        }});
      }
    }
  }

  ui() {
    return {
      commentTextarea: '.comment-textarea'
    };
  }

  scrollToCommentForm(wasLoadDelayed = false) {
    this.findControllerById('comments-form-controller').scrollToFormInput(wasLoadDelayed);
  }

  isCommsTenantPropertyOn() {
    return TenantPropertyProvider.get().getProperty('communicationsEnabled');
  }

  viewDefinition() {
    return Object.assign(super.viewDefinition(), {
      className: 'comments-container'
    });
  }

  behaviors() {
    return {
      Autosizable: {
        autosizableContainer: '.comment-textarea',
        resizeOnRender: true
      }
    };
  }

  childControllers() {
    let childControllers = [];
    if (this.commentsAllowed && this.showComments) {
      childControllers = [
        // where we put the title that says "8 Comments"
        StackedLayoutViewController.createConfigObject(
          ViewOptionHelpers.classNameAppender('comments-count-container'),
          {
            ViewControllerClass: ArticleDetailsCommentTitleController,
            collection: this.commentsCollection
          }
        ),

        // where we stack up all the comments, including the overflow "..." menu
        StackedLayoutViewController.createConfigObject(
          ViewOptionHelpers.classNameAppender('comments-collection-container'),
          {
            ViewControllerClass: ArticleDetailsCommentCollectionController,
            collection: this.commentsCollection,
            ChildViewControllerDefinition: (model) => {
              return {
                ViewControllerClass: StackedLayoutViewController,
                childControllers: [
                  {
                    ViewControllerClass: ArticleDetailsCommentController,
                    viewDefinition: {
                      ViewClass: ArticleDetailsCommentView,
                      model
                    }
                  }
                ]
              };
            }
          }
        ),

        // where the form goes
        StackedLayoutViewController.createConfigObject(
          ViewOptionHelpers.classNameAppender('comments-form-container'),
          {
            ViewControllerClass: ArticleDetailsCommentFormController,
            collection: this.commentsCollection,
            id: 'comments-form-controller'
          }
        )
      ];
    }
    return childControllers;
  }
}

module.exports = ArticleDetailsCommentRegionController;
