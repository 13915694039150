const {
  reduce,
  intersection,
  keys,
  negate,
  isFunction,
  chain,
  extendOwn
} = require('underscore');

// check if the passed in ordinal is legal given the enum values
const assertLegalValue = (legalValues, ordinal) => {
  const containsLegalValue = legalValues.includes(ordinal);

  if (!containsLegalValue) {
    throw new Error(`The client code passed in ${ ordinal } as an ordinal but it is not of one of the supported types of ${ legalValues }`);
  }

  // We return this mostly to make it easier for callers to validation without having to worry about then passing the value back in
  // This lets you write code like
  // this.state = StateThing.assertLegalValue(state);
  // instead of code like

  /**
   * StateThing.assertLegalValue(state);
   * this.state = state;
   *
   * ... it just reads a bit cleaner in the end.
   */

  return ordinal;
};

const getValues = (obj = {}) => {
  const enumPropertiesOnly = extendOwn({}, obj);
  return chain(enumPropertiesOnly)
    .filter(negate(isFunction))
    .values()
    .value();
};

const getKeys = (obj = {}) => {
  const enumKeys = keys(obj);
  return enumKeys.filter((k) => {
    return !isFunction(obj[k]);
  });
};

/**
 * Makes a class an enum by doing a few things to it
 *
 * 1. Merges in helper functions
 * 2. Freezing it so it cannot be modified
 */
const Enum = (enumObject = {}, helpers = {}) => {
  const ENTRIES = Object.freeze(Object.assign({}, enumObject));
  const HELPERS = Object.freeze(Object.assign({}, helpers));
  const LEGAL_VALUES = getValues(enumObject);
  const KEYS = getKeys(enumObject);

  const newEnumObject = Object.assign({}, ENTRIES, HELPERS, {
    assertLegalValue(ordinal) {
      return assertLegalValue(LEGAL_VALUES, ordinal);
    },
    values: () => {
      return LEGAL_VALUES;
    },
    keys: () => {
      return KEYS;
    },
    entries: () => {
      return ENTRIES;
    },
    helpers: () => {
      return HELPERS;
    },
    extend: (newEnums, newHelpers) => {
      return Enum(Object.assign({}, ENTRIES, newEnums), Object.assign({}, HELPERS, newHelpers));
    }
  });

  return Object.freeze(newEnumObject);
};

/**
 * Makes an Enum given an array and a function that can reduce the items into a single object
 */
Enum.fromArray = (array = [], helpers = {}, keyValueMapper = () => {}) => {
  const generatedEnumObject = reduce(array, (memo, item) => {
    const {
      key,
      value
    } = keyValueMapper(item);

    if (key == null) {
      throw new Error('keyValueMapper generated null/undefined key');
    }

    memo[key] = value;
    return memo;
  }, {});

  if (intersection(keys(generatedEnumObject), keys(helpers)).length > 0) {
    throw new Error('You\'re helpers override enum keys which is not allowed!');
  }

  return Enum(generatedEnumObject, helpers);
};

/**
 * Makes an array of Strings into an Enum
 */
Enum.fromStringArray = (strArray = [], helpers = {}) => {
  const strArrayReducer = (str) => {
    const typeOf = typeof str;

    if (typeOf !== 'string') {
      throw TypeError(`Expected string; got typeof '${ str }': ${ typeOf }`);
    }

    return {
      key: str,
      value: str
    };
  };

  return Enum.fromArray(strArray, helpers, strArrayReducer);
};

module.exports = Enum;
