const ProgramOverviewProgressController = require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewProgressController');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const EnrollmentSelectorView = require('@training/apps/training/views/selfDirectedPaths/programs/EnrollmentSelectorView');
const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');
const MenuDropdownPositionEnum = require('@common/components/dropdownButton/MenuDropdownPositionEnum');
const ActionBarType = require('@common/components/actionBarButton/ActionBarType');
const UnenrollModalView = require('@common/components/guidedLearning/selfDirectedPaths/programs/UnenrollModalView');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

const logging = require('logging');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');

const ENROLL = 'enroll';
const UNENROLL = 'unenroll';


class SelfDirectedPathProgramOverviewProgressController extends ProgramOverviewProgressController {
  initialize(options = {}) {
    ({
      model: this.model,
      reloadCallback: this.reloadCallback
    } = options);
  }

  get childControllers() {
    return [
      this._buildEnrollSelector(),
      this.buildProgressCard(),
      this.milestoneText()
    ];
  }

  _buildEnrollSelector() {
    return {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: EnrollmentSelectorView,
        model: this.model
      },
      regionControllers: {
        unenrollTrigger: {
          viewDefinition: {
            ViewClass: MenuDropdown,
            buttonConfig: {
              buttonIcon: 'icon-chevron_down',
              buttonAriaLabel: 'SelfDirectedPaths.path.enrollmentOptionsLabel',
              popupAlignment: MenuDropdownPositionEnum.RIGHT + MenuDropdownPositionEnum.BOTTOM
            },
            optionsListConfig: [
              {
                buttonText: 'SelfDirectedPaths.path.unenroll',
                buttonClass: 'unenroll__actions',
                clickCallback: this.openModal.bind(this)
              }
            ]
          }
        }
      },
      regions: {
        unenrollTrigger: '.unenroll__trigger'
      },
      delegateEvents: {
        'view:enroll': this.triggerEnroll.bind(this)
      }
    };
  }

  regions() {
    return {
      unenrollTrigger: '.unenroll__trigger'
    };
  }

  triggerEnroll() {
    this._updateEnrollmentStatus(ENROLL);
  }

  triggerUnenroll() {
    this._updateEnrollmentStatus(UNENROLL);
  }

  _updateEnrollmentStatus(status) {
    const programId = this.model.id;

    $.ajax({
      apiEndpoint: `/selfDirected/${ programId }/${ status }`,
      type: 'PUT'
    }).done(() => {
      logging.info(`Successfully ${ status }ed the program with id '${ programId }'`);
      app.layout.flash.success(I18n.t(`SelfDirectedPaths.enrollment.${ status }`, { title: this.model.getName() }));
      this.reloadCallback({ programId });
    })
      .fail((xhr) => {
        xhr.skipGlobalHandler = true;
        app.layout.flash.error(I18n.t('SelfDirectedPaths.search.notFound'));
        logging.info(`Could not locate the program with id '${ programId }' when the user tried to ${ status }`);
      });
  }

  openModal() {
    const modalView = new AccessibleModalView({
      className: 'gl-unenroll-modal card'
    });

    const unenrollModalView = new UnenrollModalView({
      model: this.model
    });

    app.layout.presentModal(modalView);
    modalView.setSubviewIn(unenrollModalView, { transition: UIKit.View.Transitions.NONE });
    modalView.actionBar.setActionButton([
      {
        type: ActionBarType.DISMISS_UNENROLL_MODAL,
        onClick: () => {
          app.layout.dismissModal();
        }
      },
      {
        type: ActionBarType.UNENROLL,
        onClick: () => {
          this.triggerUnenroll();
          app.layout.dismissModal();
        }
      }
    ]);

    this.listenToOnce(unenrollModalView, 'destroy', () => {
      app.layout.dismissModal();
    });
  }
}


module.exports = SelfDirectedPathProgramOverviewProgressController;
