const BaseLoadingController = require('@common/components/loading/BaseLoadingController');

const LoadingRowView = require('@common/mixins/data_loading/LoadingRowView');
const LoadingSkeletonView = require('@common/components/loading/skeleton/LoadingSkeletonView');

/*
  LoadingSkeletonController is a ViewController that allows for showing any generic ViewController while the data is
  loaded. The term "Skeleton" comes from the Design effect where a skeleton of the actual component is shown while it's
  loading the data with a "glimmer" effect and then replaced with the fully populated component when the request(s)
  succeed. It also allows for showing an error state component if any of the data fails to load.

  Enums:
    'Options': defines all valid options that can be passed in as constructor options.

      'CONTENT_CONTROLLER_DEFINITION': (View Controller Definition) - the content you want to show once all the data has
          loaded.
      'ERROR_CONTROLLER_DEFINITION': (View Controller Definition) - the content you want to show if ANY of the data syncs
          fail.
      'SKELETON_CONTROLLER_DEFINITION': (View Controller Definition) - the content you want to show while the data requests
          are in progress. Defaults to showing a simple LoadingRowView.
 */

const OptionKeys = BaseLoadingController.Options.extend({
  CONTENT_CONTROLLER_DEFINITION: 'contentControllerDefinition',
  ERROR_CONTROLLER_DEFINITION: 'errorControllerDefinition',
  SKELETON_CONTROLLER_DEFINITION: 'skeletonControllerDefinition'
});

class LoadingSkeletonController extends BaseLoadingController {

  static Options = OptionKeys;

  constructor(options = {}) {
    super(options);

    ({
      [OptionKeys.CONTENT_CONTROLLER_DEFINITION]: this._contentControllerDefinition,
      [OptionKeys.ERROR_CONTROLLER_DEFINITION]: this._errorControllerDefinition,
      [OptionKeys.SKELETON_CONTROLLER_DEFINITION]: this._skeletonControllerDefinition = {
        viewDefinition: {
          ViewClass: LoadingRowView
        }
      }
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: LoadingSkeletonView
    };
  }

  onViewShow() {
    this.initializeSyncTracker();
    this.startEntitySyncTracker();

    if (this._entitySyncTacker.areEntitiesResolved()) {
      this.showContentView();
    } else if (this._entitySyncTacker.areEntitiesRejected()) {
      this.showErrorView();
    }
  }

  onStartLoading(syncTracker, options) {
    this.showSkeletonView(options);
  }

  onSuccessLoading() {
    this.showContentView();
  }

  onErrorLoading() {
    this.showErrorView();
  }

  showSkeletonView() {
    this.swapRegionController(LoadingSkeletonView.CONTENT_REGION, this._skeletonControllerDefinition);
  }

  showContentView() {
    this.swapRegionController(LoadingSkeletonView.CONTENT_REGION, this._contentControllerDefinition);
  }

  showErrorView() {
    this.swapRegionController(LoadingSkeletonView.CONTENT_REGION, this._errorControllerDefinition);
  }

  destroy() {
    this.destroyEntitySyncTracker();
    super.destroy();
  }
}

module.exports = LoadingSkeletonController;
