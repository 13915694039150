class Stopwatch {

  constructor() {
    this._startTime = null;
    this.setElapsedTime = this.setElapsedTime.bind(this);
    this.start = this.start.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.stop = this.stop.bind(this);
    this.reset = this.reset.bind(this);
    this.elapsedTime = this.elapsedTime.bind(this);
    this._elapsedTime = 0.0;
  }

  setElapsedTime(elapsedTime) {
    this._elapsedTime = elapsedTime;
    this._startTime = Math.floor(Date.now() - (elapsedTime * 1000));
  }

  start() {
    this.setElapsedTime(this._elapsedTime);
    this.timer = setInterval(this.updateTimer, 1000);
  }

  updateTimer() {
    this._elapsedTime = Math.floor((Date.now() - this._startTime) / 1000);
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  reset() {
    this.stop();
    this._startTime = null;
    this._elapsedTime = 0.0;
  }

  elapsedTime() {
    return this._elapsedTime;
  }
}

module.exports = Stopwatch;
