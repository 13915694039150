const XssScrubber = require('@common/libs/scrubber/XssScrubber');
const _ = require('underscore');

const StringHelpers = require('@common/libs/helpers/types/StringHelpers');

const htmlSingleChar = '(?:[\xf0-\xf7][\x80-\xbf][\x80-\xbf][\x80-\xbf]|[\xe0-\xef][\x80-\xbf][\x80-\xbf]|'
  + '[\xc0-\xdf][\x80-\xbf]|&#[0-9]+;|&#x[0-9A-Fa-f]+;|&[a-zA-Z0-9]*?;|[ \t\n\r]{1,}|[^ \t\n\r])';
const htmlVisibleChar = '(?:[\xf0-\xf7][\x80-\xbf][\x80-\xbf][\x80-\xbf]|[\xe0-\xef][\x80-\xbf][\x80-\xbf]|'
  + '[\xc0-\xdf][\x80-\xbf]|&#[0-9]+;|&#x[0-9A-Fa-f]+;|&[a-zA-Z0-9]*?;|[^ \t\n\r])';
const htmlWhitespaceChar = '(?:[ \t\n\r]{1,})'; // contiguous are treated as single whitespace
const htmlWhitespaceEndRE = new RegExp(`(?:${ htmlWhitespaceChar })$`);
const htmlWhitespaceStartRE = new RegExp(`^(?:${ htmlWhitespaceChar })`);
const htmlWordAndSpaceEndRE = new RegExp(`(${ htmlVisibleChar }{1,}${ htmlWhitespaceChar }{0,})$`);
const htmlSpaceAndWordStartRE = new RegExp(`^(${ htmlWhitespaceChar }{0,}${ htmlVisibleChar }{1,})`);


const HTMLHelpers = {
  htmlEncode(value) {
    const newValue = StringHelpers.defaultToEmptyString(value);

    return $('<textarea></textarea>').text(newValue)
      .html();
  },

  htmlDecode(value) {
    const newValue = StringHelpers.defaultToEmptyString(value);

    return $('<textarea></textarea>').html(newValue)
      .text();
  },

  /* WARNING.  Use caution. This function turns HTML into _raw_ text.If html
   * contains HTML entities like ''&lt;' this function turns them into '<'.
   * This means it's not safe (it would allow XSS attacks) to  interpolate
   * the result of this function using <%= %> in an underscore template.  Use <%- %>,
   * or stripHtmlForDisplay instead  */
  stripHtmlUnsafe(html) {
    const newHtml = StringHelpers.defaultToEmptyString(html);
    return $('<div/>').html(newHtml)
      .text();
  },

  stripHtmlInlineElements(html) {
    const newHtml = StringHelpers.defaultToEmptyString(html);
    return newHtml.replace(/<\x2f?(?:a|i|b|big|small|tt|abbr|acronym|cite|code|dfn|em|kbd|strong|samp|time|var|bdo|map|img|object|q|script|span|sub|sup|svg|button|input|label|select|textarea)(?:\s[^>]*>|>)/g, '');
  },

  stripHtmlForDisplay(html) {
    const newHtml = StringHelpers.defaultToEmptyString(html);
    return _.escape(this.stripHtmlUnsafe(newHtml));
  },

  stripHtmlForTimelinePreview(html) {
    const newHtml = StringHelpers.defaultToEmptyString(html);

    return newHtml.replace(/<[^>]+>/g, (tag) => {
      //replace the following with a line break:
      // - start of a table, ordered list or unordered list (to seperate from the previous line of text)
      // - end of a list item or table row
      // - end of a div or paragraph (user intended contents to be on own line)
      if ((/<(table|ol|ul|br|\/li|\/tr|\/div|\/p)(\s|>)/i).test(tag)) {
        return '<br />';
      }
      
      //replace all other html tags with spaces
      return ' ';
    })
      .replace(/(<br \/>\s*)+/ig, '<br />') //squash multiple adjacent line breaks
      .replace(/\s+/g, ' ') //squash repeated linespace to a single space
      .replace(/^\s*<br \/>/i, ''); //remove white space and line break from the start
  },

  htmlHasSomeText(html) {
    const newHtml = StringHelpers.defaultToEmptyString(html);
    const cleanerHtml = HTMLHelpers.stripHtmlInlineElements(newHtml);
    return HTMLHelpers.stripHtmlUnsafe(cleanerHtml).trim().length > 0;
  },

  sanitizeString(questionText, sanitizeOptions = {}) {
    const $docfrag = $(document.createDocumentFragment());
    const $tmpContainer = $('<div />').html(questionText);
    $docfrag.append($tmpContainer.html());
    const xssScrubber = new XssScrubber(sanitizeOptions);
    xssScrubber.scrubElements($docfrag);
    const scrubbedHtml = $('<div />').html($docfrag)
      .html();
    return scrubbedHtml;
  },

  getTextWidth(text) {
    const html = $(`<span style="position:absolute;width:auto;left:-9999px">${ text }</span>`);
    $('body').append(html);
    const width = html.width();
    html.remove();
    return width;
  },

  cleanString(text) {
    const newText = StringHelpers.defaultToEmptyString(text);

    return newText.trim()
      .replace(/\r\n|\n{2,}/g, '<br />')
      .replace(/\r\n|\n/g, ' ');
  },

  lineBreakToBr(text) {
    const newText = StringHelpers.defaultToEmptyString(text);

    return newText.replace(/\r?\n/g, '<br />');
  },

  brToLineBreak(text) {
    const newText = StringHelpers.defaultToEmptyString(text);

    return newText.replace(/<br *\/?>/gi, '\n');
  },

  isHtmlWithinLength( text, maxlength ) {
    const regexp = new RegExp( `^(?:${ htmlSingleChar }{0,${ maxlength }})$` );
    return regexp.test( text );
  },

  isWhitespaceAtEnd(text) {
    return htmlWhitespaceEndRE.test(text);
  },

  isWhitespaceAtStart(text) {
    return htmlWhitespaceStartRE.test(text);
  },

  excerptHtmlTextByLength(prematchString, match, postmatchString, maxlength) {
    // isolate some context of a string match, preferring text preceding the match.
    // pass in a string broken into three parts, and a maximum length.
    // returns that string broken into five parts, where the middle three mostly fit into maxlength
    // breaks will always be on word boundaries, unless match is close to maxlength.
    // originally written to summarize matching text in content admin, it could also summarize
    // for prefixes for flash messages, activity feeds, etc, by passing the entire string in postmatch.
    // note that the 'maxlength' is best-effort; if the match is longer
    // than the maxlength, the entire match is still returned
    // should be easy to change the bias to text following the match, for RTL languages
    let prematch = prematchString, postmatch = postmatchString, suffix = '', prefix = '';
    if (this.isHtmlWithinLength(match, maxlength)) {
      // okay, there's room to grow surrounding the match.  Iteratively move text from the prematch
      // and postmatch into the prefix and suffix that will be returned.
      // First, if the match is part of a word, then try and get the whole word in the result.
      // Then, add more words before the match.  If there's room, add more words after the match.
      // Finally, if stuff was left off the front, put an ellipsis at the front, and ditto for the end.
      let firstPostWord, lastPreWord;
      if (match.length > 0) {
        if (!this.isWhitespaceAtStart(match) && !this.isWhitespaceAtEnd(prematch) && (prematch.length > 0)) {
          //move the last part of prematch into the prefix (if it'll fit)
          lastPreWord = htmlWordAndSpaceEndRE.exec(prematch);
          if ((lastPreWord != null) && this.isHtmlWithinLength(lastPreWord[1] + match, maxlength)) {
            prefix = lastPreWord[1];
            prematch = prematch.substr(0, prematch.length - lastPreWord[1].length );
          }
        }
        if (!this.isWhitespaceAtEnd(match) && !this.isWhitespaceAtStart(postmatch) && (postmatch.length > 0)) {
          //move the first part of postmatch into the suffix (if it'll fit)
          firstPostWord = htmlSpaceAndWordStartRE.exec(postmatch);
          if ((firstPostWord != null) && this.isHtmlWithinLength(prefix + match + firstPostWord[1], maxlength)) {
            suffix = firstPostWord[1];
            postmatch = postmatch.substr(firstPostWord[1].length);
          }
        }
      }
      // Now try to include as much of the prematch as possible
      while (prematch.length > 0) {
        lastPreWord = htmlWordAndSpaceEndRE.exec(prematch);
        if ((lastPreWord != null) && this.isHtmlWithinLength(lastPreWord[1] + prefix + match + suffix, maxlength)) {
          prefix = lastPreWord[1] + prefix;
          prematch = prematch.substr(0, prematch.length - lastPreWord[1].length );
        } else {
          break;
        }
      }
      // Now try to include as much of the postmatch as possible
      while (postmatch.length > 0) {
        firstPostWord = htmlSpaceAndWordStartRE.exec(postmatch);
        if ((firstPostWord != null) && this.isHtmlWithinLength(prefix + match + suffix + firstPostWord[1], maxlength)) {
          suffix = suffix + firstPostWord[1];
          postmatch = postmatch.substr(firstPostWord[1].length);
        } else {
          break;
        }
      }
    }
    return [prematch, prefix, match, suffix, postmatch];
  },

  // Strips HTML and truncates the result to max and add an ellipsis
  // Tries to make it look nicer by removing excess spaces and
  // adding spaces between paragraphs and list items
  summarizeHTMLToRange(htmlContent = '', max = 100) {
    let html = htmlContent;
    if (html === null) {
      return '';
    }

    // Replace any encoded space to prevent long lines in word wrap.
    html = html.replace(/&nbsp;/g, ' ');

    // Wraps spaces between any html tag to make list and paragraphs look nicer
    // in summary and prevent issue in with stripHtmlUnsafe on cross browsers
    html = html.replace(/<.+?>/g, (a) => {
      return ` ${ a } `;
    });

    // strip all HTML. Why "unsafe"? Because the output could contain literal < or > characters.
    // it just means this text is "raw", not HTML encoded, and should not be put in the DOM as is.
    let text = this.stripHtmlUnsafe(html);

    text = text.replace(/\n/g, ' '); // Replace any newlines with spaces.
    text = text.replace(/[ ]+/g, ' '); // Remove any excess spaces.
    text = text.trim();

    // Explode the text into a character array. This respects multibyte character boundaries.
    // Note that string length is not the same as character count. See:
    // '🍀🍀🍀'.length
    // -> 6
    // Thankfully, splitting a string into an array does respect character boundaries
    // [... '🍀🍀🍀'].length
    // -> 3
    const explodedText = [...text];

    if (explodedText.length <= max) {
      return _.escape(text);
    }

    // slice out a subset of characters, join them, remove any trailing space and add an ellipsis at the end.
    const chars = (
      explodedText.slice(0, max)
        .join('')
        .trimEnd()
    ) + '…';
    return _.escape(chars);
  },

  colorById(colorId) {
    let id = colorId;
    // make sure the number is a number
    if (isNaN(id)) {
      id = 0;
    }
    // make sure the number is positive
    id = Math.abs(id);
    const colors = [
      '#3761A2',
      '#46a3fe',
      '#FF5A0C',
      '#73B744',
      '#FE4493',
      '#CC60FA',
      '#8D3A26',
      '#1565E4',
      '#935468',
      '#FC6A62',
      '#8C249B',
      '#9D2768',
      '#DE779A',
      '#594DAA',
      '#D48110',
      '#F854BA',
      '#D683FB',
      '#FC5476',
      '#942438',
      '#514979',
      '#2F6A24',
      '#AC0F81',
      '#8A4A11',
      '#6F4CC4',
      '#FD7BB8',
      '#0C74E1',
      '#F18432'
    ];
    return colors[id % colors.length];
  },

  /**
   * Joins multiple class names together into a single string.
   * Removes any falsy values from the list of class names.
   * Trims any leading or trailing whitespace from the class names.
   *
   * @param {...(string | undefined)} classNames - The class names to join.
   * @returns {string} The joined class names.
   */
  joinClassNames(...classNames) {
    return _.compact(classNames)
      .filter(_.isString)
      .map((className) => {
        return className.trim()
      })
      .join(' ');
  },

  /**
 * Changes the tag of a given HTML element to a new tag while preserving its attributes and inner HTML.
 *
 * @param {HTMLElement} element - The original HTML element to be changed.
 * @param {string} newTag - The new tag name to replace the original element's tag.
 * @returns {HTMLElement} - The newly created HTML element with the new tag.
 */
  changeTag(element, newTag) {
    const newElement = document.createElement(newTag);
    newElement.innerHTML = element.innerHTML;
  
    // Copy attributes
    Array.from(element.attributes).forEach((attr) => {
      newElement.setAttribute(attr.name, attr.value);
    });
  
    element.replaceWith(newElement);
    return newElement;
  },

  /**
  * Utility function to conditionally joining classNames together.
  *
  * @param {string} classNames - The class names to merge.
  * @param {Object.<string, boolean>} optionalClasses - The optional class names to merge.
  * @returns {string} The merged class names.
  *
  * @example
  * ```tsx
  * const mergedClassNames = mergeConditionalClasses('class1 class2', {
  *  'class3': true,
  *  'class4': false
  * }); // 'class1 class2 class3'
  * ```
  */
  mergeConditionalClasses(classNames = '', optionalClasses = {}) {
    const optionalClassesArray = Object.entries(optionalClasses).map(([key, value]) => {
      return value ? key : '';
    });
    
    return this.joinClassNames(classNames, ...optionalClassesArray);
  }
};

module.exports = HTMLHelpers;
