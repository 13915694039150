import I18n from '@common/libs/I18n';

import { ReactControllerDefinitionFactory } from '@common/modules/react';

export default function ConversationalSearchControllerDefinitionFactory() {
  const config = ReactControllerDefinitionFactory({
    component: import('@training/apps/conversationalSearch/ConversationalSearch'),
    props: {}
  });

  return Object.assign({}, config, {
    delegateEvents: {
      'view:inflate': () => {
        window.app.layout.setTitle(I18n.t('training.menu.askMax'));
      }
    }
  });
}
