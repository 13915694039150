const Form = require('@common/components/forms/Form');

require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');
require('@common/components/forms/editors/hasValueDropdown/HasValueDropDown.less');

/**
 * HasValueDropDown creates a select element using chosen.js and decorates the visual options with an icon based on if
 * the option id has a value in an object provided to this element
 *
 * In order for this component to work you must provide an object to showValuesObject for the optionsClass callback to itterate over.
 * If you don't the icons for chosen will not show up at all because the class callback will not be sent to the parent class and thus
 * your options will not be decorated with classes
 */

Form.Editor.HasValueDropDown = class HasValueDropDown extends Form.Editor.AxonSelect {
  preinitialize(options) {
    this.axonSelectOptions = {
      disableSearch: true,
      useChosen: true
    };
    if (options.options && options.options.showValuesObject) {
      this.showValuesObject = options.options.showValuesObject;
      options.options.optionsClass = this._setOptionClass;
    }

    super.preinitialize(options);
  }

  onShowDropdown() {
    this._addValueIcons();
  }

  _addValueIcons() {
    this.getDropdownEl().find('li.has-value-dropdown-item--has-value')
      .append('<span class="icon-ok"></span>');
    this.getDropdownEl().find('li.has-value-dropdown-item--has-no-value')
      .append('<span class="icon-ban_circle"></span>');
  }

  _setOptionClass(id) {
    return this.showValuesObject[id] ? 'has-value-dropdown-item has-value-dropdown-item--has-value' : 'has-value-dropdown-item has-value-dropdown-item--has-no-value';
  }
};


module.exports = Form.Editor.HasValueDropDown;
