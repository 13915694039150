const SessionTrainingType = require('@common/data/enums/SessionTrainingType');
const { CONTAINER_CLASSES } = require('@common/libs/behaviors/carousel/Carousel');

const SLIDES_PER_VIEW_BREAKPOINTS_CONFIG = [{
  isDefault: true,
  slidesForSizes: [
    {
      minSlideCount: 1,
      slidesPerView: 1
    }, {
      minSlideCount: 2,
      slidesPerView: 1.1
    }
  ]
}];

const getSessionTypeViewComparator = (sessionType) => {
  if (sessionType === SessionTrainingType.GuidedLearningTraining) {

    return (first, second) => {
      const firstName = first.get('program').name;

      const secondName = second.get('program').name;

      return firstName.localeCompare(secondName, {numeric: true});
    };
  }

  return undefined;
};

module.exports = {
  CONTAINER_CLASSES,
  SLIDES_PER_VIEW_BREAKPOINTS_CONFIG,
  getSessionTypeViewComparator
}
