// This implements the tri-state requirements as described inside of https://axonify.atlassian.net/browse/CI-139
(function($) {
  $.ui.fancytree.registerExtension({
    name: 'hierarchical-parent-tristate',
    version: '0.0.1',
    author: 'Vaughan Hilts (Axonify)',
    options: {
      allowTristate: false
    },
    nodeSetSelected(ctx, flag) {
      const wasSelectedMap = {};

      let activeNode = ctx.node;
      while (activeNode != null) {
        wasSelectedMap[activeNode.key] = {selected: activeNode.selected, partsel: activeNode.partsel};
        activeNode = activeNode.parent;
      }

      // Allow Fancytree to take over and do as it needs to do
      this._superApply([ctx, flag]);

      const fixSelection = (node) => {
        const parentNode = node.parent;
        if ((parentNode == null) || !wasSelectedMap[parentNode.key] || parentNode.unselectable) {
          return;
        }

        // Were you partly selected before all of this?
        const wasParentPartlySelected = wasSelectedMap[parentNode.key].partsel;
        const wasParentSelected = wasSelectedMap[parentNode.key].selected;

        const parentWasNotSelectedAtAllButChildIsNow = (!wasParentPartlySelected && !wasParentSelected) && node.selected;

        fixSelection(parentNode);

        // If the parent was partly selected and now it's not, we'll go ahead and revert it back to being partly
        // selected for this, since that's what we want.
        if ((wasParentPartlySelected && ((parentNode.selected === true) && (parentNode.partsel === true))) || parentWasNotSelectedAtAllButChildIsNow) {
          parentNode.selected = false;
          parentNode._lastSelectIntent = false;
          parentNode.render(true);
          parentNode.renderStatus();
        }

        
      };
      fixSelection(ctx.node);
    }
  });

})(jQuery, window, document);
