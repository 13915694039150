const Marionette = require('Marionette');
const ViewController = require('@common/libs/UI/controllers/ViewController');
const I18n = require('@common/libs/I18n');

class ZoomOverlayRegionController extends ViewController {
  constructor(options = {}) {
    super(options);
    ({
      media: this.media,
      onClick: this.onClick = () => {}
    } = options);
    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: Marionette.ItemView,
      className: 'zoom-overlay',
      template: `<button class="zoom-icon" aria-label="${ I18n.t('popover.zoomImage') }"></button>`,
      events: {
        'click .zoom-icon': (e) => {
          e.stopPropagation();
          this.onClick(this.media.id);
        },
        'keydown .zoom-icon': (e) => {
          e.stopPropagation();
        },
        'touchend .zoom-icon': (event) => {
          const e = event.originalEvent;
          const touches = e.targetTouches;

          if (touches.length > 0) {
            this.onClick(this.media.id);
          }
        }
      }
    };
  }
}

module.exports = ZoomOverlayRegionController;
