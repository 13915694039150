import {
  AxDialogConfirmationHeader,
  AxDialogDefaultActionBar,
  AxDialogDefaultContent,
  type AxDialogConfirmationHeaderProps,
  type AxDialogDefaultContentProps
} from '@common/modules/react/themes/components';

export interface AxDialogConfirmationLayoutProps extends AxDialogConfirmationHeaderProps, AxDialogDefaultContentProps {
  title?: JSX.Element | string
  content?: JSX.Element
  buttons?: JSX.Element
}

export default function AxDialogConfirmationLayout(props: AxDialogConfirmationLayoutProps) {
  const {
    title,
    icon,
    content,
    buttons,
    qaClassName
  } = props;

  return <>
    <AxDialogConfirmationHeader icon={ icon } qaClassName={ `${ qaClassName }-header` }>{ title }</AxDialogConfirmationHeader>
    <AxDialogDefaultContent dividers={ false } qaClassName={ `${ qaClassName }-content` }>{ content }</AxDialogDefaultContent>
    <AxDialogDefaultActionBar sx={{ justifyContent: 'center' }} qaClassName={ `${ qaClassName }-action-bar` }>{ buttons }</AxDialogDefaultActionBar>
  </>;
}
