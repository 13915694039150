const _ = require('underscore');

const I18n = require('@common/libs/I18n');

const AssessmentTopicItemView = require('@training/apps/training/views/assessments/AssessmentTopicItemView');

class AssessmentTaskItemView extends AssessmentTopicItemView {
  template = _.tpl(require('@training/apps/training/templates/assessments/AssessmentTaskItemView.html'));

  _renderLastResult() {
    const taskCompletionRecord = this.model.get('taskCompletionRecord');
    let resultClass;

    if (taskCompletionRecord != null) {
      resultClass = this._getResultClass(taskCompletionRecord);

      this._renderPassedState(resultClass);
    }
  }

  _getResultClass() {
    // For the case of Tasks we only display the Result Page when it's "completed" so we always style it as "passed"
    return 'pass';
  }

  _renderPassedState(resultClass) {
    this.$el.addClass(resultClass);
  }

  _renderIcon() {
    this.$('.topic .icon')
      .addClass('icon-task')
      .removeClass('hidden')
      .attr('aria-label', I18n.t('GuidedLearning.icons.task'));
  }

  _renderRewardPointsString() {
    const taskCompletionRecord = this.model.get('taskCompletionRecord');
    const pointsAvailable = this.model.get('pointsAvailable');

    const $rewardStringDiv = this.$('.tile-meta .rewards');

    if (taskCompletionRecord && taskCompletionRecord.completed === true && pointsAvailable > 0) {
      $rewardStringDiv.text(I18n.t('assessments.item.rewardPoints.earned', {points: pointsAvailable}));
    } else if (pointsAvailable === 0) {
      $rewardStringDiv.hide();
    } else {
      $rewardStringDiv.text(I18n.t('assessments.item.rewardPoints.possible', {points: pointsAvailable}));
    }
  }
}

module.exports = AssessmentTaskItemView;
