// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-rewards" aria-hidden="true"></span>
      <span class="page-header__title">
        <%- t('usermessage.prizeSummary.title') %>
      </span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="user-messaging prize-loser">
      <div class="broadcastmessage">
        <h2><%- t('usermessage.prizeSummary.messageHeader') %></h2>
        <% if (hasMedia) { %>
        <div class="media">
          <img src="<%- r(media.download) %>"/>
        </div>
        <% } %>
        <div class="broadcast">
        <h3><%- simpleMessage %></h3>
        <% if (winnersInLocation.length > 0) { %>
          <div class="prize-winner-title"><%- t('usermessage.prizeSummary.winnersInLocation') %></div>
          <div class="winners-in-location-list winners-list"></div>
        <% } %>
        <% if (otherWinners.length > 0) { %>
          <div class="prize-winner-title"><%- t('usermessage.prizeSummary.otherWinners') %></div>
          <div class="other-winners-list winners-list"></div>
        <% } %>
        </div>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;