import ViewController from '@common/libs/UI/controllers/ViewController';
import ViewControllerFactory from '@common/libs/UI/controllers/ViewControllerFactory';
import LoadingRowView from '@common/mixins/data_loading/LoadingRowView';
import type {
  AddPortalOptions,
  ComponentModule
} from '@common/modules/react';
import {
  initReactBundle,
  removePortal,
  renderPortal
} from '@common/modules/react/ReactChannel';
import type { Model } from 'backbone';
import {
  ItemView,
  Region
} from 'backbone.marionette';

export interface ReactControllerOptions<P> extends Pick<AddPortalOptions<P>, 'component' | 'props'> {
  loadingControllerDefinition?: Record<string, unknown> | (() => Record<string, unknown>)
  contentViewDefinition?: Record<string, unknown>
}

export default class ReactController<P> extends ViewController {
  private reactRegion?: Region;

  private component: ReactControllerOptions<P>[ 'component' ];

  private props: ReactControllerOptions<P>[ 'props' ];

  private loadingControllerDefinition: ReactControllerOptions<P>[ 'loadingControllerDefinition' ];

  private promise: Promise<ComponentModule<P>>;

  constructor(options: ReactControllerOptions<P>) {
    super(options);

    ({
      component: this.component,
      props: this.props,
      loadingControllerDefinition: this.loadingControllerDefinition = {
        viewDefinition: {
          ViewClass: LoadingRowView
        }
      }
    } = options);

    this.promise = initReactBundle().then(() => {
      return Promise.resolve(this.component);
    });
  }

  viewDefinition() {
    return {
      ViewClass: ItemView,
      template: false
    };
  }

  onViewInflate(controller: ReactController<P>, view: ItemView<Model>) {
    this.reactRegion = new Region({ el: view.el });
  }
  
  onViewBeforeAttach() {
    this.reactRegion?.show(ViewControllerFactory.createLegacyView(this.loadingControllerDefinition));
  }

  onViewAttach(controller: ReactController<P>, view: ItemView<Model>) {
    this.promise.then(() => {
      if (!this.isDestroyed) {
        renderPortal({
          id: view.cid,
          component: this.component,
          props: this.props,
          el: view.el
        });
      }
    });
  }

  onViewBeforeDestroy(controller: ReactController<P>, view: ItemView<Model>) {
    removePortal(view.cid);
    
    this.reactRegion?.empty();
    delete this.reactRegion;
  }
}
