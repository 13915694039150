import StackedLayoutViewController from '@common/libs/UI/controllers/StackedLayoutViewController';
import { ReactControllerDefinitionFactory } from '@common/modules/react';
import { memoize } from 'lodash';

export const ChatDefinitionFactory = memoize((onCloseChat) => {
  return ReactControllerDefinitionFactory({
    component: import('@training/apps/chat/ChatHeaderNavButton'),
    props: {
      onCloseChat
    },
    loadingControllerDefinition: {
      ViewControllerClass: StackedLayoutViewController,
      viewDefinition: {
        tag: 'ul',
        className: 'menu-list menu-list-top'
      },
      childControllers: [
        {
          viewDefinition: {
            template: false
          }
        }
      ]
    }
  });
});
