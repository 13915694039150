// Module
var code = `
<div class="container__ax-button-ghost-parent">
  <div class="base-card__wrapper base-card__wrapper--upper js-base-card__wrapper--upper <%- topRegionClass %>" <%- topRegionAttributes %>>
    <div class="base-card__title"></div>
    <div class="base-card__view-all">
      <%= axButton({
        ariaLabel: linkAriaLabel,
        className: 'qa-view-all',
        disableKeyboardNav: linkAttributes,
        ghost: 'true',
        ghostParentHover: 'true',
        iconClassRight: 'icon-chevron_right view-all__icon',
        label: linkText,
        size: 'm'
      }) %>
    </div>
  </div>
  <div class="base-card__wrapper base-card__wrapper--lower"></div>
</div>
`;
// Exports
module.exports = code;