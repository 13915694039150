const { Region } = require('Marionette');
const { extend } = require('underscore');

/**
 * Reset fix, based on https://github.com/marionettejs/backbone.marionette/issues/3077
 */

extend(Region.prototype, {
  reset: function () {
    this.empty();

    if (this.getOption('el')) {
      this.el = this.options.el;
    }

    delete this.$el;
    return this;
  }
});
