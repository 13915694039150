import {
  BottomNavigation as MuiBottomNavigation,
  styled,
  type BottomNavigationTypeMap as MuiBottomNavigationTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxBottomNavigationCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxBottomNavigationTypeMap<P = object, D extends React.ElementType = 'div'> = MuiBottomNavigationTypeMap<P & AxBottomNavigationCustomProps, D>;

export type AxBottomNavigationProps<
  D extends ElementType = AxBottomNavigationTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxBottomNavigationTypeMap<P & AxBottomNavigationCustomProps, D>, D>;

const styledOptions = {
  name: 'AxBottomNavigation'
};

const StyledAxBottomNavigation = styled(MuiBottomNavigation, styledOptions)<AxBottomNavigationProps>(() => {
  return {};
});

/**
 * A wrapper around the MUI BottomNavigation component.
 *
 * ### Links
 * - [MUI BottomNavigation](https://mui.com/material-ui/react-bottom-navigation/)
 * - [Figma specs](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=10383-17069&t=3q5vBhIavgQnR2Aa-0)
 */
export const AxBottomNavigation: MuiOverridableComponent<AxBottomNavigationTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxBottomNavigationProps, ref: Ref<HTMLDivElement>) => {
  const BottomNavigationProps: AxBottomNavigationProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxBottomNavigation { ...BottomNavigationProps } ref={ ref } >
      { children }
    </StyledAxBottomNavigation>
  );
});

export default AxBottomNavigation;
