// Module
var code = `
<div class="comment">
  <div class="comment-left">
    <div class="article-comment__avatar"></div>
  </div>
  <div class="comment-right">
    <div class="comment-header">
      <div class="comment-header-left">
        <h3 class="comment-name"><%- authorName %></h3>
        <div class="comment-when"><%- timestamp %></div>
      </div>
      <div class="comment-header-right">
        <div class="ellipsis-menu-region"></div>
      </div>
    </div>
    <div class="comment-body">
      <p class="<%- languageDirectionClass %>">
        <%= HTMLHelpers.lineBreakToBr(HTMLHelpers.htmlEncode(commentText)) %>
      </p>
    </div>
    <div class="js-content-translation-region"></div>
  </div>
</div>
`;
// Exports
module.exports = code;