// Module
var code = `<div class="soldout">
  <button aria-label="<%- t('general.close') %>"></button>

  <h2><%- t('auction.issoldout', {name: model.prize.name})%></h2>

  <% if (model.prize.prizeImg) { %>
    <div class="itemimage"></div>
  <% } %>

  <p class="fulfillment">
    Sorry.
  </p>
</div>
`;
// Exports
module.exports = code;