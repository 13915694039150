const Backbone = require('Backbone');
const dateHelpers = require('@common/libs/dateHelpers');

const {AllGamesList, GameList} = require('../collections/GameList');

class GamesData extends Backbone.Model {
  apiEndpoint() {
    return '/games/available';
  }

  initialize(attributes, options = {}) {
    const hideTeamGames = options.hideTeamGames != null ? options.hideTeamGames : false;

    this.featured = new GameList([], {hideTeamGames});
    this.popular = new GameList([], {hideTeamGames});
    this.recent = new GameList([], {hideTeamGames});
    this.all = new AllGamesList([], {hideTeamGames});
  }

  getGames() {
    return this.fetch({
      data: {
        withScoring: false
      }
    });
  }

  parse(response = {}) {
    if (response.popular != null) {
      this.popular.set(response.popular);
    }
    if (response.recent != null) {
      this.recent.set(response.recent);
    }
    if (response.games != null) {
      this.featured.set(response.games);
      this.all.set(response.games, {remove: false});
    }

    return response;
  }

  getPopularGames(length) {
    return this.popular.getSupportedGames(length);
  }

  getRecentGames(length) {
    return this.recent.getSupportedGames(length);
  }

  getFeaturedGame() {
    const supportedGames = this.featured.getSupportedGames();
    const serverTimestampNow = dateHelpers.getTime(apps.auth.session.serverTimeOffset);
    const weekOfYear = dateHelpers.calculateWeek(serverTimestampNow);

    return supportedGames.at(weekOfYear % supportedGames.length);
  }

  getAllSupportedGames() {
    return this.all.getSupportedGames();
  }
}

module.exports = GamesData;
