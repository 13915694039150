const logging = require('logging');
const Marionette = require('Marionette');

const {
  enableScalingInWebView,
  disableScalingInWebView
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

class MobileZoomImageModal extends Marionette.LayoutView {
  constructor(...args) {
    super(...args);
    this.onShow = this.onShow.bind(this);
    this._loadImage = this._loadImage.bind(this);

  }

  className() {
    return 'modal zoom-modal';
  }

  getTemplate() {
    return require('@common/components/image/zoom/_mobile_zoom_image_modal.html');
  }

  ui() {
    return {
      zoomImageWrapper: '.zoom-image-wrapper'
    };
  }

  initialize(options = {}) {
    return ({
      media: this.media
    } = options);
  }

  onShow() {
    logging.info('Mobile ImageZoom Modal shown');
    this._loadImage();
  }

  _loadImage() {
    this.imageViewer = ImageViewerFactory.createViewerInstance({
      $el: this.ui.zoomImageWrapper,
      media: this.media
    });
    this.listenTo(this.imageViewer, 'image:loaded', this._onImageLoaded);
    this.imageViewer.render();
  }

  _onImageLoaded() {
    if (this.isDestroyed) {
      return;
    }

    this.$el.css('visibility', 'visible');
    // Enable pich to zoom and panning
    enableScalingInWebView();
    this.$el.find('img').css('pointer-events', 'none');
    this.trigger('image:loaded');
  }

  onDestroy() {
    // Disable pich to zoom and panning
    disableScalingInWebView();
  }
}

module.exports = MobileZoomImageModal;
