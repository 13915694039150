const { Collection } = require('Backbone');
const KeyCode = require('@common/data/enums/KeyCode');

const DESELECTED = -1;
const ON = true;
const OFF = false;

/**
 * KeyboardNavigationHelper is optimized for use with the FilterableCommunityController
 *
 */
class KeyboardNavigationHelper {

  // recentCommunityList is treated as optional and only currently used for Post Creation in Discover & Timeline
  constructor(filteredCommunityList, switchDropdown, setCommunityHandler, recentCommunityList = new Collection()) {
    this.recentCommunityIsVisible = true;
    this.recentCommunityList = recentCommunityList;
    this.filteredCommunityList = filteredCommunityList;
    this.switchDropdown = switchDropdown;
    this.setCommunityHandler = setCommunityHandler;

    // this.focusedItem is 0-indexed; if none of the items
    // are focused in the dropdown, we set it to DESELECTED (-1)
    this.focusedItem = DESELECTED;

    this.navHandler = this.navHandler.bind(this);
  }

  /**
   * navHandler is the lone public method designed to analyze keyboard events and
   *  update the status of the dropdown, or select items, accordingly.
   *
   * @param {jQuery.event} e
   * @return {Boolean} whether the key pressed was a navigation key.
   */
  navHandler(e) {
    let isNavKey = true;

    // Ignore the Enter key if nothing's selected.
    if (e.which === KeyCode.ENTER && this.focusedItem === DESELECTED) {
      return isNavKey;
    }

    this._clearHighlights();
    this.recentCommunityIsVisible = !e.target.value;

    switch (e.which) {
      case KeyCode.DOWN:
        this.focusedItem++;
        break;
      case KeyCode.UP:
        this.focusedItem--;
        break;
      case KeyCode.ENTER:
        /* select the item indexed by `this.focusedItem` */
        this._selectHighlighedItem(this.focusedItem);
        this.focusedItem = DESELECTED;
        break;
      default:
        this.focusedItem = DESELECTED;
        isNavKey = false;
    }

    this.focusedItem = this._constrainFocusItem(this.focusedItem);

    const switchState = (this.focusedItem === DESELECTED && [KeyCode.UP, KeyCode.ENTER, KeyCode.TAB].indexOf(e.which) !== -1) ? OFF : ON;

    this.switchDropdown(switchState);
    this._setHighlight(this.focusedItem);

    return isNavKey;
  }

  /**
   * handler method for clearing the selection state of the dropdown when it is hidden.
   *
   * @see FilterableCommunityController._switchDropdown()
   */
  reset() {
    this.focusedItem = DESELECTED;
    this._clearHighlights();
  }

  /**
   * _constrainFocusItem ensures that the selected focusItem remains in bounds.
   *
   * Allowed values are integers running from -1 (indicating a deselected state) to the index of the last menu item.
   *
   * @private
   * @param {Number} focusedItem
   * @return {Number} a constrained integer between -1 and the index of the last menu item
   */
  _constrainFocusItem(focusedItem) {
    const maxIndex = this._getMaxSelectableItemIndex();
    let fi = focusedItem;

    fi = (fi < DESELECTED) ? DESELECTED : fi;
    fi = (fi > maxIndex) ? maxIndex : fi;

    return fi;
  }

  /**
   * removes the 'highlight' attribute from all models
   *
   * @private
   */
  _clearHighlights() {
    this.recentCommunityList.each((model) => {
      model.unset('highlight');
    });

    this.filteredCommunityList.each((model) => {
      model.unset('highlight');
    });
  }

  /**
   * sets a 'highlight' attribute to the model
   *
   * @private
   * @param {Number} focusedItem
   */
  _setHighlight(focusedItem) {
    const model = this._getModelAtFocusedItem(focusedItem);

    if (model) {
      model.set('highlight', true);
    }
  }

  /**
   * selects the currently highlighted menuItem and preloads it into the input
   *
   * @private
   * @param {Number} focusedItem
   */
  _selectHighlighedItem(focusedItem) {
    const model = this._getModelAtFocusedItem(focusedItem);
    if (model) {
      model.unset('highlight');
      this.setCommunityHandler(model);
    }
  }

  /**
   * ensures we return the correct model given the value of `focusedItem`
   *
   * @private
   * @param {Number} focusedItem
   * @return {Model|null} either a Community model or null.
   */
  _getModelAtFocusedItem(focusedItem) {
    if (focusedItem === DESELECTED) {
      return null;
    }

    // Recent Communities is not showing, return the selected item from filteredCommunityList and bail
    if (!this.recentCommunityIsVisible) {
      return this.filteredCommunityList.at(focusedItem);
    }

    // Recent Communities is showing, but the highlighted item is a filteredCommunity; return that and bail
    if (focusedItem >= this.recentCommunityList.length) {
      return this.filteredCommunityList.at(focusedItem - this.recentCommunityList.length);
    }

    // Recent Communities is showing, and the highlighted item is also a recent community; return that
    return this.recentCommunityList.at(focusedItem);
  }

  /**
   * @private
   * @return {Number} the highest legally indexible menu item
   */
  _getMaxSelectableItemIndex() {
    const recentCommunitiesLength = this.recentCommunityIsVisible ? this.recentCommunityList.length : 0;

    // We don't want to know how many items there are, rather the index of the last item. So we subtract 1 from the length.
    return recentCommunitiesLength + this.filteredCommunityList.length - 1;
  }
}


module.exports = KeyboardNavigationHelper;
