import { AxIcon } from '@common/modules/react/themes/components';
import {
  Alert as MuiAlert,
  styled,
  type AlertProps as MuiAlertProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

export type ALERT_VARIANTS =
  'inline' |
  'toast' |
  'banner';

export type ALERT_SEVERITIES =
  'informational' |
  'positive' |
  'negative' |
  'warning';

declare module '@mui/material/Alert' {
  interface AlertPropsVariantOverrides {
    'inline': true
    'toast': true
    'banner': true
  }

  interface AlertPropsColorOverrides {
    'informational': true
    'positive': true
    'negative': true
    'warning': true,

    'info': false,
    'success': false,
    'error': false
  }
}

export type AxAlertProps = Omit<MuiAlertProps, 'elevation' | 'square' | 'severity'> & {
  variant?: ALERT_VARIANTS,
  /**
   * The severity of the alert. This defines the color and icon used.
   * @default 'positive'
   */
  severity?: ALERT_SEVERITIES
};

const customIconMapping = {
  informational: <AxIcon className='icon-info_sign'/>,
  positive: <AxIcon className='icon-ok'/>,
  negative: <AxIcon className='icon-exclamation'/>,
  warning: <AxIcon className='icon-warning_sign'/>
};

const styledOptions = {
  name: 'AxAlert'
};

const StyledAxAlert = styled(MuiAlert, styledOptions)<AxAlertProps>(({
  theme,
  variant
}) => {
  // TODO - add LESS/CSS variables to theme.uiKit for component-specific properties that are also used by legacy alerts
  // https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7244-5616&mode=design&t=hVEcz054xk5GGZgJ-0
  if (variant === 'banner') {
    return {
      color: theme.uiKit.colorGrey70,
      borderRadius: theme.uiKit.borderRadiusM,
      padding: theme.uiKit.spacingS,
      border: `0.1rem solid ${ theme.uiKit.colorGrey30 }`,
      '& .MuiAlertTitle-root': {
        color: theme.uiKit.colorGrey80
      },
      '& .MuiAlert-message': {
        padding: 0,
        fontSize: theme.uiKit.fontSizeXS,
        lineHeight: theme.uiKit.lineHeightXS
      },
      '& .MuiAlert-icon': {
        color: theme.uiKit.colorGrey70,
        padding: 0,
        alignItems: 'center'
      },

      // severity level styling
      '&.MuiAlert-bannerPositive': {
        borderLeft: `${ theme.uiKit.spacingXS } solid ${ theme.uiKit.colorSemanticPositive }`
      },
      '&.MuiAlert-bannerNegative': {
        borderLeft: `${ theme.uiKit.spacingXS } solid ${ theme.uiKit.colorSemanticNegative }`
      },
      '&.MuiAlert-bannerWarning': {
        borderLeft: `${ theme.uiKit.spacingXS } solid ${ theme.uiKit.colorSemanticWarning }`
      },
      '&.MuiAlert-bannerInformational': {
        borderLeft: `${ theme.uiKit.spacingXS } solid ${ theme.uiKit.colorSemanticInformational }`
      }
    };
  }

  if (variant === 'inline') {
    return {
      '&.MuiAlert-inlinePositive': {
        '& .MuiAlert-icon': {
          color: theme.uiKit.colorSemanticPositive
        }
      },
      '&.MuiAlert-inlineNegative': {
        '& .MuiAlert-icon': {
          color: theme.uiKit.colorSemanticNegative
        }
      },
      '&.MuiAlert-inlineWarning': {
        '& .MuiAlert-icon': {
          color: theme.uiKit.colorSemanticWarning
        }
      },
      '&.MuiAlert-inlineInformational': {
        '& .MuiAlert-icon': {
          color: theme.uiKit.colorSemanticInformational
        }
      }
    };
  }

  if (variant === 'toast') {
    return {
      borderRadius: theme.uiKit.borderRadiusS,
      '& .MuiAlertTitle-root': {
        fontSize: theme.uiKit.fontSizeM
      },
      '& .MuiIcon-root': {
        fontSize: theme.uiKit.fontSizeXL
      },
      '& .MuiAlert-message': {
        fontSize: theme.uiKit.fontSizeXS
      },

      '&.MuiAlert-toastPositive': {
        backgroundColor: theme.uiKit.colorSemanticPositive,
        color: theme.uiKit.colorWhite
      },
      '&.MuiAlert-toastNegative': {
        backgroundColor: theme.uiKit.colorSemanticNegative,
        color: theme.uiKit.colorWhite
      },
      '&.MuiAlert-toastWarning': {
        backgroundColor: theme.uiKit.colorSemanticWarning,
        color: theme.uiKit.colorBlack
      },
      '&.MuiAlert-toastInformational': {
        backgroundColor: theme.uiKit.colorSemanticInformational,
        color: theme.uiKit.colorWhite
      }
    };
  }

  return {};
}) as React.ComponentType<AxAlertProps>;

/**
 * Alerts display brief messages for the user without interrupting their use of the app.
 *
 * ### Links
 * - • [MUI API](https://mui.com/material-ui/api/alert/)
 * - • [MUI Demo](https://mui.com/material-ui/react-alert/)
 * - • [Mockup](https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7244-5616&mode=design&t=hVEcz054xk5GGZgJ-0)
 */
export const AxAlert = forwardRef(({
  variant = 'inline',
  iconMapping = customIconMapping,
  severity = 'positive',
  ...otherProps
}: AxAlertProps, ref: Ref<HTMLDivElement>) => {

  const alertProps: AxAlertProps = {
    variant,
    iconMapping,
    severity,
    ...otherProps
  };

  return (
    <StyledAxAlert { ...alertProps } ref={ ref }/>
  );
});

export default AxAlert;
