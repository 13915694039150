const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const LayeredLayout = require('@common/components/layeredLayout/LayeredLayout');
const FixedHeaderLayout = require('@common/components/fixedLayout/FixedHeaderLayout');

const PageLayoutContextConfig = require('@training/apps/training/pageLayout/PageLayoutContextConfig');

const PageView = require('@training/apps/main/views/PageView');

const regionControllers = {
  fixedContentRegion: {
    id: 'training-mobile-layers',
    ViewControllerClass: LayoutController,
    viewDefinition: LayeredLayout,
    regionControllers: {
      contentRegion: {
        id: 'page-view',
        viewDefinition: {
          id: 'page-view',
          attributes: {
            role: 'main'
          },
          ViewClass: PageView,
          className: 'fit-parent white-background'
        }
      },
      overlayRegion: {
        id: 'details-view',
        viewDefinition: {
          id: 'details-view',
          ViewClass: PageView,
          className: 'fit-parent white-background'
        }
      }
    }
  }
};

module.exports = (contextModel) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: FixedHeaderLayout,
    regionControllers: () => {
      const controllers = Object.assign({}, regionControllers);

      const pageHeaderDefinition = PageLayoutContextConfig.getPageHeaderControllerDefinition(contextModel);

      if (pageHeaderDefinition != null) {
        controllers.fixedHeaderRegion = pageHeaderDefinition;
      }

      return controllers;
    },
    delegateEvents: {
      'view:inflate': (controller) => {
        controller.listenTo(contextModel, `change:${ PageLayoutContextConfig.Keys.HEADER_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('fixedHeaderRegion', controllerDefinition, options.showOptions);
        });
      },
      'view:render': (controller, view) => {
        view.toggleHeader(PageLayoutContextConfig.isHeaderVisible(contextModel));
        controller.listenTo(contextModel, `change:${ PageLayoutContextConfig.Keys.HEADER_VISIBLE }`, (state, visible) => {
          view.toggleHeader(visible);
        });
      }
    }
  };
};

