// Module
var code = `<div class="page-content">
  <div class="selectgame">
    <div class="game-info-video-wrapper"></div>
    <div class="game-action-wrapper ax-grid ax-grid--no-gutter">
      <div class="ax-grid__col--12 ax-grid__col--m-3 ax-grid__col--m-offset-1 ax-grid__col--m-order-2">
        <div class="game-info-action-buttons ax-grid ax-grid--m-no-gutter">
          <% if (_.isUndefined(challenge)) { %>
            <div class="ax-grid__col--6 ax-grid__col--m-12">
              <%= axButton({
                className: 'js-play-now play-now-button',
                branded: true,
                fullWidth: true,
                label: t('selectGame.gameInfo.playNow')
              }) %>
            </div>
            <% if ( _.has(game.mechanics, 'challenge') ) { %>
              <div class="ax-grid__col--6 ax-grid__col--m-12">
                <%= axButton({
                  className: 'js-challengebtn',
                  fullWidth: true,
                  iconClassLeft: 'icon-male',
                  label: t('selectGame.gameInfo.challengeFriend')
                }) %>
              </div>
            <% } %>
          <% } else { %>
            <div class="game-challenge-info">
              <div class="small-name-logo"></div>
              <div class="game-challenge-meta clearfix">
                <p class="game-challenge-meta__description text-ellipsis-softwrap ax-font--no-margin"><%- t('selectGame.challengeInfo.challenger', challenge.challenger) %></p>
                <p>
                  <span><%- t('selectGame.gameInfo.challengeInfoWithoutWager') %></span>
                  <% if (_.has(challenge, 'wager')) { %>
                  <span class="game-challenge-meta__wager"><%- t('selectGame.gameInfo.challengeInfoWithWager', {"wager": challenge.wager}) %></span>
                  <% } %>
                </p>
              </div>
              <div class="ax-grid ax-grid--m-no-gutter">
                <div class="ax-grid__col--6 ax-grid__col--m-12">
                  <%= axButton({
                    className: 'challenge-play-now-button js-accept-challenge',
                    branded: true,
                    fullWidth: true,
                    label: t('selectGame.gameInfo.acceptChallenge')
                  }) %>
                </div>
                <div class="ax-grid__col--6 ax-grid__col--m-12">
                  <%= axButton({
                    className: 'js-decline-challenge',
                    fullWidth: true,
                    label: t('selectGame.gameInfo.declineChallenge')
                  }) %>
                </div>
              </div>
            </div>
          <% } %>
        </div>
      </div>
      <div class="game-info-text ax-grid__col--12 ax-grid__col--m-8">
        <div class="game-title clearfix">
          <div class="game-type-icon <%- game.type %>"></div>
          <h2 class="game-name ax-font--no-margin"><%- t(\`games.\${ game.type }\`) %></h2>
          <div class="game-info-meta ax-font ax-font__caption ax-font--secondary"><%- t('games.genre.' + game.metadata.genre) %></div>
        </div>
        <h3><%- t('selectGame.gameInfo.instructions') %></h3>
        <p class="instructions ax-font--no-margin"><%= gameMetadata.instructions %></p>
      </div>
    </div>

    <div class="game-scores-details-view"></div>
  </div>

</div>
`;
// Exports
module.exports = code;