const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  PRIMARY: 'card-primary',
  INFORMATIONAL: 'card--informational',
  INTERACTIVE: 'card--interactive',
  INTERACTIVE_NO_CURSOR_CHANGE: 'card--interactive-no-cursor-change',
  MEDIUM_PADDING: 'card--medium',
  SMALL_PADDING: 'card--small',
  LARGE_PADDING: 'card--large'
});
