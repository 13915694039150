const LocaleBundle = require('@common/libs/locale/LocaleBundle');
const { getLocaleMappedForNoStrings } = require('@common/libs/locale/LocaleBundleHelper');

// Include the default language in our bundle
require(`@${ process.env.project }/assets/strings/${ process.env.defaultLocale }/str.js`);

class ProjectLocaleBundle extends LocaleBundle {

  load(localeCode) {
    const code = getLocaleMappedForNoStrings(localeCode);
    return code === process.env.defaultLocale ? this._importDefault() : [
      this._importDefault(),
      this._importLocale(code)
    ];
  }

  _importDefault() {
    return import(`@${ process.env.project }/assets/strings/${ process.env.defaultLocale }/str.js`).catch(this.onCatch);
  }

  _importLocale(localeCode) {
    return import(`@${ process.env.project }/assets/strings/${ localeCode }/str.js`).catch(this.onCatch);
  }

  onCatch(err) {
    if (err.code === 'MODULE_NOT_FOUND') {
      return {};
    }
      
    throw err;
  }
}

module.exports = ProjectLocaleBundle;
