const AttachmentItemView = require('@common/components/media/AttachmentItemView');
const MediaViewModel = require('@common/components/media/MediaViewModel');

class EvaluationSubmissionPreviewDetailsView extends AttachmentItemView {

  templateHelpers() {
    const modelFile = this.model.get('originalFile');
    const type = MediaViewModel.inferTypeFromName(modelFile.name);
    const icon = MediaViewModel.withIconFromType(type);

    return {
      id: 0,
      type,
      icon,
      name: modelFile.name,
      isViewable: false,
      isDownloadable: false,
      isDownloadAttributeSupported: false,
      downloadUrl: ''
    };
  }

}

module.exports = EvaluationSubmissionPreviewDetailsView;
