const {isFunction} = require('underscore');
const Cocktail = require('backbone.cocktail');

const LoadingEvents = require('@common/mixins/data_loading/LoadingEvents');

const LoadingEventsMixin = {
  hasLoadedOnce: false,
  isLoading: false,
  showLoading: true,

  initialize() {
    this.on('request', this.startLoading);
    this.on('sync', this.endLoading);
  },

  startLoading(collection_model, xhr, options) {
    let left;
    // this.showLoading could be a function that returns a bool, or it could be a bool.
    if ((left = (typeof this.showLoading === 'function' ? this.showLoading(collection_model, xhr, options) : undefined)) != null ? left : this.showLoading) {
      this.isLoading = true;
      collection_model.trigger(this.LoadingEvents.START, this);
      this.hasLoadedOnce = true;
    }
  },

  endLoading(collection_model) {
    this.isLoading = false;
    collection_model.trigger(this.LoadingEvents.END, this);
  },

  reset(models = []) {
    if (models.length === 0) {
      this._resetLoadingState();
    }
  },

  clear() {
    this._resetLoadingState();
  },

  mixinClone() {
    //Stop the override of Backbone's clone
    //On IE<=8 accessing the @__proto__.__proto__ was only returning the first level
    const cloned = this.clone();
    cloned.hasLoadedOnce = this.hasLoadedOnce;
    cloned.isLoading = this.isLoading;
    cloned.showLoading = !isFunction(this.showLoading) ? this.showLoading : undefined;
    return cloned;
  },

  _resetLoadingState() {
    this.hasLoadedOnce = false;
    this.isLoading = false;
  }
};

Cocktail.mixin(LoadingEventsMixin, LoadingEvents);

module.exports = LoadingEventsMixin;
