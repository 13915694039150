const availabilityBuilder = require('@training/apps/training/controllers/guidedLearning/GuidedLearningContextualAvailabilityBuilder');

const getBuilder = (session) => {
  return availabilityBuilder(session);
};

module.exports = function(session) {

  return {
    createForGuidedLearningSessionType(requirementsCompleted) {
      if (requirementsCompleted) {
        return getBuilder(session).build();
      }
      return getBuilder(session)
        .withStartableItem()
        .build();

    },

    createForAfterTrainingSession() {
      return getBuilder(session)
        .withStartableItem()
        .build();
    },

    createForHub() {
      return getBuilder(session).build();
    }
  };
};
