const { ItemView } = require('Marionette');

class PDFComplianceView extends ItemView {

  className() {
    return `
      ax-grid
      ax-grid--align-items-start
      ax-grid--justify-content-center
      ax-grid--m-align-items-center
      ax-grid--m-justify-content-center
      pdf-compliance-content
    `;
  }

  getTemplate() {
    return `
      <div class="pdf-checkbox-wrapper ax-grid__col--m-auto-size">
        <%= axCheckbox({
          id: 'pdf-compliance-checkbox',
          label: t('pdf.complianceMessage')
        }) %>
      </div>
    `;
  }

  ui() {
    return {
      complianceCheckbox: '#pdf-compliance-checkbox'
    };
  }

  events() {
    return {
      'click @ui.complianceCheckbox': this._onClickCheckbox
    };
  }

  _onClickCheckbox() {
    this.model.set('isChecked', this.ui.complianceCheckbox[0].checked);
  }
}

module.exports = PDFComplianceView;
