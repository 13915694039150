// Module
var code = `<div class="page-content">
  <div class="ax-grid ax-grid--no-gutter">
    <div class="ax-grid__col--12 ax-grid__col--m-9 community-insights__breadcrumbs breadcrumb-title-wrapper">
      <div class="breadcrumb-path ax-grid__col--auto-size" role="navigation"><div class="header-breadcrumbs js-community-insights__breadcrumbs"></div></div>
      <div class="community-insights__title">
        <h1 class="ax-font"><%- t('discover.insights.commsInsightsAllPagesTitle') %></h1>
      </div>
    </div>
    <div class="ax-grid__col--12 ax-grid__col--m-3">
      <div class="js-community-insights__how-it-works community-insights__how-it-works ax-grid"></div>
    </div>
  </div>

  <div class="filter-wrap ax-grid">
    <div class="community-insights__filter-by-type js-community-insights__filter-by-type ax-grid__col--12 ax-grid__col--m-4 ax-grid__col--l-3"></div>
    <div class="community-insights__communities js-community-insights__communities ax-grid__col--12 ax-grid__col--m-auto-size"></div>
    <div class="community-insights__filters js-community-insights__filters ax-grid__col--12 ax-grid__col--m-auto-size"></div>
  </div>

  <button type="button" class="community-insights__reset js-community-insights__reset"><%-t('discover.insights.reset') %></button>

  <div class="js-community-insights__tab-container community-insights__tab-container"></div>
</div>
`;
// Exports
module.exports = code;