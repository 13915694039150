const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const { ItemView } = require('Marionette');
const Backbone = require('Backbone');
const async = require('async');

const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const CommunityUserCounts = require('@common/data/models/CommunityUserCounts');
const CommunityContentCount = require('@common/data/models/CommunityContentCount');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const CommunityAccessType = require('@training/apps/training/enums/CommunityAccessType');

class CommunitiesTableItemView extends ItemView {
  initialize() {
    const userLanguage = this._getDefaultLanguage();
    const localizableString = this.model && this.model.get('names');
    this.communityName = localizableString.getValueForLanguage(userLanguage);
    this.communityIcon = this._getCommunityThumbnail();
  }

  tagName() {
    return 'tr';
  }

  modelEvents() {
    return {change: 'render'};
  }

  _getCommunityThumbnail() {
    if (this.model.get('thumbnailImage')) {
      return this.model.get('thumbnailImage').sizes[1].file.path;
    }
    return null;
  }

  templateHelpers() {
    const communityIsArchived = this.model.get('isArchived');
    const communityAccessType = this.model.get('communityAccessType') === CommunityAccessType.GROUP
      ? I18n.t('communitiesManagement.community.groupType') : I18n.t('communitiesManagement.community.teamType');
    const communityStatus = communityIsArchived
      ? I18n.t('communitiesManagement.community.archivedCommunity') : I18n.t('communitiesManagement.community.activeCommunity');

    return {
      isCommsEnabled: TenantPropertyProvider.get().getProperty('communicationsEnabled'),
      communityCode: this.model.get('code'),
      communityAccessType,
      communityStatus,
      communityIsArchived,
      communityName: this.communityName,
      communityDeleteLabel: I18n.t('communitiesManagement.communities.deleteButtonAriaLabel', {
        communityName: this.communityName
      }),
      communitySettingsLabel: I18n.t('communitiesManagement.communities.settingsLinkAriaLabel', {
        communityName: this.communityName
      }),
      communityRestoreLabel: I18n.t('communitiesManagement.communities.restoreButtonAriaLabel', {
        communityName: this.communityName
      }),
      communityArchiveLabel: I18n.t('communitiesManagement.communities.archiveButtonAriaLabel', {
        communityName: this.communityName
      }),
      canArchive: this.model.canArchiveCommunity(),
      canDelete: this.model.canDeleteCommunity(),
      canEditSettings: this.model.canEditCommunitySettings()
    };
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/communities/CommunitiesTableItemViewTemplate.html');
  }

  events() {
    return {
      'click .js-delete-community': this._onClickDelete,
      'click .js-settings-redirect': this._onClickRedirect,
      'click .js-archive-community': this._onClickArchive,
      'click .js-restore-community': this._onClickRestore,
      'click .js-community-navigate': this._onClickGotoCommunity
    };
  }

  _getDefaultLanguage() {
    const userDefaultLanguage = window.apps.auth.session.user.get('language') || 'EN';
    // Handle situation where admin's language may not be set
    if (userDefaultLanguage === 'XX') {
      return window.apps.auth.session.get('tenant').defaultLanguage;
    }
    return userDefaultLanguage;
  }

  _onClickDelete() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s'
    });

    const modalChildView = new ConfirmDialogView({
      confirmCallback: this._deleteCommunity,
      title: I18n.t('communitiesManagement.communities.deleteModal.title'),
      confirmationText: I18n.t('communitiesManagement.communities.deleteModal.confirmationText', {
        communityName: this.communityName
      }),
      buttonText: I18n.t('general.delete'),
      iconClass: 'icon-trash red',
      model: this.model
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _onClickRedirect() {
    const url = `${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/community-${ this.model.get('id') }/communityManagement?fromCommunitiesManagement=true`;
    Backbone.history.navigate(url, true);
  }

  _onClickGotoCommunity() {
    const url = `${ SearchUrlHelper.BASE_SEARCH_HASH }/community-${ this.model.get('id') }`;
    window.open(url, '_blank');
  }

  _onClickArchive() {
    this._onClickArchiveOrRestore(this.showArchiveConfirmModal.bind(this), true);
  }

  _onClickRestore() {
    this._onClickArchiveOrRestore(this.showRestoreConfirmModal.bind(this), false);
  }

  /**
  * One shared handler for both confirm modals, to do the async fetching of meta info.
  */
  _onClickArchiveOrRestore(showModalCallback, archiveClicked) {
    // do two async fetches to get member count and content count, then on both complete pass them to the confirm modal.
    const communityId = this.model.get('id');
    const userType = window.apps.auth.getAuthFragment();
    const communityUserCountsUrl = archiveClicked ? `/axonify/${ userType }/pageCommunities/${ communityId }/userCount` : `/axonify/${ userType }/pageCommunities/config/${ communityId }/userCount`;
    this.communityUserCounts = new CommunityUserCounts({
      communityId
    });
    this.communityContentCount = new CommunityContentCount({
      communityId
    });

    async.auto(
      [
        (done) => {
          return this.communityUserCounts.fetch({
            url: communityUserCountsUrl,
            skipGlobalHandler: true,
            success: () => {
              done();
            },
            error: () => {
              this.communityUserCounts.set('count', null); // setting it to null just means it won't be shown
              done();
            }
          });
        },
        (done) => {
          return this.communityContentCount.fetch({
            skipGlobalHandler: true,
            success: () => {
              done();
            },
            error: () => {
              this.communityContentCount.set('count', null); // setting it to null just means it won't be shown
              done();
            }
          });
        }
      ],
      showModalCallback
    );
  }

  showArchiveConfirmModal() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s'
    });

    const modalChildView = new ConfirmDialogView({
      confirmCallback: this._archiveCommunity,
      title: I18n.t('communitiesManagement.communities.archiveModal.title'),
      confirmationText: I18n.t('communitiesManagement.communities.archiveModal.confirmationText', {
        communityName: this.communityName
      }),
      buttonText: I18n.t('general.archive'),
      buttonColorClassName: 'red',
      iconClass: 'icon-archive red',
      model: this.model,
      extraContentView: new ItemView({
        model: this.model,
        className: 'confirm-extra-content-inner',
        templateHelpers: {
          communityName: this.communityName,
          communityIcon: this.communityIcon,
          memberCount: this.communityUserCounts.get('count'), // this could be null
          contentCount: this.communityContentCount.get('count') // this could also be null
        },
        template: this._getConfirmModalExtraContentTemplate()
      })
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  showRestoreConfirmModal() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s'
    });

    const modalChildView = new ConfirmDialogView({
      confirmCallback: this._restoreCommunity,
      title: I18n.t('communitiesManagement.communities.restoreModal.title'),
      confirmationText: I18n.t('communitiesManagement.communities.restoreModal.confirmationText', {
        communityName: this.communityName
      }),
      buttonText: I18n.t('general.restore'),
      buttonColorClassName: 'blue',
      iconClass: 'icon-refresh blue',
      model: this.model,
      extraContentView: new ItemView({
        model: this.model,
        className: 'confirm-extra-content-inner',
        templateHelpers: {
          communityName: this.communityName,
          communityIcon: this.communityIcon,
          memberCount: this.communityUserCounts.get('count'), // this could be null
          contentCount: this.communityContentCount.get('count') // this could also be null
        },
        template: this._getConfirmModalExtraContentTemplate()
      })
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }


  /**
   * This is the same for both Archive and Restore. It uses the i18n keys for archive, there's no need to duplicate
   * them for restore
   */
  _getConfirmModalExtraContentTemplate() {
    return `
    <div class="archive-confirm-modal ax-grid">
      <% if (communityIcon !== null) { %>
        <div class="ax-grid__col ax-grid__col--auto-size">
          <img class="community-icon" src="<%- communityIcon %>"></img>
        </div>
      <% } %>
      <div class="ax-grid__col ax-grid--align-items-start">
       <div>
         <h2><%- communityName %></h2>
         <% if (memberCount !== null || contentCount !== null) { %>
           <p>
           <% if (memberCount !== null) { %>
             <%- t('communitiesManagement.communities.archiveModal.details.memberCount', {memberCount: memberCount}) %>
           <% } %>
            <% if (memberCount !== null && contentCount !== null) { %>
             &middot;
           <% } %>
            <% if (contentCount !== null) { %>
             <%- t('communitiesManagement.communities.archiveModal.details.contentCount', {contentCount: contentCount}) %>
           <% } %>
          </p>
        <% } %>
      </div>
    </div>`;
  }

  _archiveCommunity() {
    this.model.archiveCommunity();
  }

  _restoreCommunity() {
    this.model.restoreCommunity();
  }

  _deleteCommunity() {
    this.model.destroy({
      wait: true,
      dataType: 'text', // this prevents the system from trying to parse an empty string as JSON.
      success() {
        const successText = I18n.t('communitiesManagement.communities.success');
        window.app.layout.flash.success(successText);
      }
    }).fail( (xhr) => {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      const errCode = exception.getErrorCode();

      if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY || errCode === AxonifyExceptionCode.CLIENT_ERROR_DELETE_FAILED_HAS_CHILDREN) {
        window.app.layout.flash.error(I18n.t(`errors.Community.${ errCode }`));
        xhr.skipGlobalHandler = true;
      } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED ) {
        window.app.layout.flash.error(I18n.t(`discover.pageAccess.community.error.3017`));
        xhr.skipGlobalHandler = true;
      }
    });
  }
}

module.exports = CommunitiesTableItemView;
