module.exports = ({
  platform = '',
  href = '',
  userAgent = '',
  buildId = '',
  instanceId = ''
} = {}) => {
  const messages = [
    `Instance ID: ${ instanceId }`,
    `Page Href: ${ href }`,
    `Platform: ${ platform }`,
    `User Agent: ${ userAgent }`,
    `Build ID: ${ buildId }`
  ];

  return `\nEnvironment:\n  ${ messages.join('\n  ') }`;
};
