const logging = require('logging');
const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');

const Form = require('@common/components/forms/Form');

/**
 * IMPORTANT: Please read this before using this widget.
 *
 * reCAPTCHA does not...
 *
 * 1. Work / supported under anything that is not IE11+ which means it cannot be used in a majority of places at time of writing.
 * The places it IS used right now are authorized to be broken in those browsers, however in general usage we cannot do this.
 *
 * 2. The data here is collected and sent to Google. For some customers, this might be a deal breaker. Keep this in mind.
 *
 * 3. There is a dependency on Google here, so you have to be willing for this to fail if Google goes down. i.e: don't tie auth
 * to this service.
 */
Form.Editor.Captcha = class Captcha extends Form.Editor {
  initialize(options = {}) {
    this.siteKey = assertPropertyExists(options.options, 'secretKey');

    this.render();
  }

  getTemplate() {
    // The script is hard-coded here since you have to allow this for Google Captcha to work :(
    return `
      <script src="https://www.google.com/recaptcha/api.js" async='false' defer></script>
      <div id="recaptcha-checkbox" class="g-recaptcha" data-sitekey="${ this.siteKey }"></div>
    `;
  }

  getValue() {
    if (window.grecaptcha) {
      return window.grecaptcha.getResponse();
    }

    // This is OK since a blank string is a non-valid captcha -- and since the user could not have seen it yet,
    // they could not have checked it.
    return '';
  }

  setValue() {
    this.reset(); // this is the only supported operation for reCAPTCHA
  }

  reset() {
    // If it's not loaded yet, it will load in reset, so there is no need to reset manually
    if (window.grecaptcha) {
      try {
        window.grecaptcha.reset();
      } catch (e) {
        logging.warn(e);
      }
    }
  }

  onDestroy() {
    delete window.grecaptcha;
  }
};

module.exports = Form.Editor.Location;
