const _ = require('underscore');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

class SoldOutModal extends AccessibleModalView {
  className() {
    return 'modal sold-out-modal';
  }

  id() {
    return 'modalview';
  }

  contentTemplate() {
    const templateFn = _.tpl(require('../templates/_sold_out_modal.html'));
    return templateFn({
      model: this.model.toJSON()
    });
  }
}

module.exports = SoldOutModal;
