// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
			<span class="page-header__icon icon-events" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('hub.learningEvents') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <ul class="subtabs events-tabs clearfix">
      <li data-tab="upcoming" class="tab">
        <a href="#" id="upcoming-tab"><%- t('learningEvents.upcoming')%></a>
      </li>
      <li data-tab="scheduled" class="tab">
        <a href="#" id="scheduled-tab"><%- t('learningEvents.scheduled')%></a>
      </li>
      <li data-tab="history" class="tab">
        <a href="#" id="history-tab"><%- t('learningEvents.history')%></a>
      </li>
    </ul>

    <div class="learningevent-list">
      <div class="learningevent-listcontainer">

        <div class="contentarea" data-tab="upcoming">
          <div class="event-search clearfix" role="search">
            <%= axSearch({
              ariaControls: 'event-list-upcoming',
              ariaLabel: t('learningEvents.searchLabel'),
              fullWidth: true,
              placeholder: t('learningEvents.searchLabel')
            }) %>
            <p class="off-screen" id="number-of-upcoming" aria-live="polite"></p>
          </div>
          <div id="event-list-upcoming" role="feed" class="event-list-container" aria-labelledby="upcoming-tab"></div>
        </div>
        <div class="contentarea pad" data-tab="scheduled">
          <div class="event-search clearfix" role="search">
            <%= axSearch({
              ariaControls: 'event-list-scheduled',
              ariaLabel: t('learningEvents.searchLabel'),
              fullWidth: true,
              placeholder: t('learningEvents.searchLabel')
            }) %>
            <p class="off-screen" id="number-of-scheduled" aria-live="polite"></p>
          </div>
          <div id="event-list-scheduled" role="feed" class="event-list-container" aria-labelledby="scheduled-tab"></div>
        </div>
        <div class="contentarea" data-tab="history">
          <div class="event-search clearfix" role="search">
            <%= axSearch({
              ariaControls: 'event-list-history',
              ariaLabel: t('learningEvents.searchLabel'),
              fullWidth: true,
              placeholder: t('learningEvents.searchLabel')
            }) %>
            <p class="off-screen" id="number-of-history" aria-live="polite"></p>
          </div>
          <div id="event-list-history" role="feed" class="event-list-container" aria-labelledby="history-tab"></div>
        </div>
      </div>
    </div>
  </div>

</div>

`;
// Exports
module.exports = code;