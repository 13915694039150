const Enum = require('@common/data/enums/Enum');

// Some of these are duplicated from AssessmentType but they are NOT the same. These are representing the types of hints
// the server is giving the client to figure out what it should be using for the day. They are not same as the types
// of individual given assessments
module.exports = Enum({
  NoTraining: 'NoTraining',
  DailyTraining: 'DailyTraining',
  GuidedLearningTraining: 'GuidedLearningTraining',
  CertificationTraining: 'CertificationTraining',
  IntroductoryTraining: 'IntroductoryTraining',
  RefresherTraining: 'RefresherTraining',
  FormalExamTraining: 'FormalExamTraining',

  // This is used if Skip Weekends is enabled and it's a weekend
  Weekend: 'Weekend'
}, {
  isAssessable(trainingType) {
    this.assertLegalValue(trainingType);

    return ![this.NoTraining, this.Weekend].includes(trainingType);
  }
});
