const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

const AxonifySeriesColorMapperStrategy = require('./AxonifySeriesColorMapperStrategy');
const DataSource = require('@training/apps/search/reports/pages/dashboard/previews/pages_by_status/ChartDataSource');

const getHighcharts = () => {
  return import('@common/configs/highcharts');
};

class ChartComponentView extends Marionette.ItemView {
  initialize(options = {}) {
    this.noDataMessage = '<%- t(\'discover.browse.reports.chart.noData\') %>';

    this._chartConfig = options.chartConfig;
    this._dataSource = options.dataSource;

    this.listenTo(this._dataSource, 'change', () => {
      return this._updateChart();
    });
  }

  getTemplate() {
    return false;
  }

  className() {
    return 'chart-container';
  }

  onAttach() {
    const chartContainer = this.$el;
    this._chartConfig.chart.renderTo = chartContainer[0];
    this._isNew = true;
    this._updateChart();
  }

  _updateChart(collection) {
    if (this.$el.prev('h3').length === 0) {
      this.$el.parent().prepend(`<h3 class="align-left"> ${ I18n.t('discover.browse.reports.pages.PAGES_BY_STATUS.title') } </h3>`);
    }

    if (collection) {
      this._dataSource = new DataSource({collection: collection});
    }

    getHighcharts().then((Highcharts) => {
      if (this.isDestroyed) {
        return;
      }

      let series;
      let seriesData = {};

      if (!this._dataSource.isDataAvailable()) {
        this._chartConfig.series = seriesData;
      } else {
        seriesData = this._dataSource.getSeriesData();
        this._applyColorToSeries(seriesData);
      }

      // Update the series
      if (this._isNew) {
        this._chartConfig.series = seriesData;
        this._chart = new Highcharts.Chart(this._chartConfig);
        this._isNew = false;
      } else {
        for (series of this._chart.series) {
          series.remove();
        }

        for (series of seriesData) {
          this._chart.addSeries(series);
        }

        this._chart.redraw();
        this._chart.reflow();
      }

      if (this._chart.series.length === 0) {
        this._showEmptyDataMessage();
      } else {
        this._chart.hideLoading();
      }
    });
  }

  _showEmptyDataMessage() {
    this._chart.showLoading(Marionette.Renderer.render(this.noDataMessage));
  }

  _applyColorToSeries(seriesData) {
    const mapper = new AxonifySeriesColorMapperStrategy();
    seriesData[0].data.forEach((series) => {
      series.color = mapper.next();
    });
  }
}

module.exports = ChartComponentView;
