const Marionette = require('Marionette');
const LearningEventDisplayHelper = require('@training/apps/training/views/LearningEventDisplayHelper');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');

class EventsCardRowView extends Marionette.ItemView {
  initialize(options = {}) {
    ({ model: this.model } = options);

    this.currentUserLanguage = window.apps.auth.session.user.get('language');

    this.eventStatusInfo = LearningEventDisplayHelper.getLearningEventStatusDisplayInfo(this.model, true);
    this.eventStatusInfo.title = this._getItemTitle();
  }

  get template() {
    return require('@training/apps/home/hubPreviews/events/EventsCardRowTemplate.html');
  }

  templateHelpers() {
    return this.eventStatusInfo;
  }

  _getItemTitle() {
    const scheduledEvent = this.model.get('scheduledEvent');
    const localizableName = new LocalizableString(scheduledEvent.learningEvent.localizableName);
    return localizableName.getValueForLanguage(this.currentUserLanguage);
  }
}

module.exports = EventsCardRowView;
