const Backbone = require('Backbone');

const { joinUrlFragments, combineUrlPaths } = require('@common/libs/helpers/app/UrlHelpers');

const FilteredEventController = require('@training/apps/events/filteredEvents/FilteredEventController');
const LearningEventDetailsPage = require('@training/apps/training/views/LearningEventDetailsPage');

class FilteredEventIndex {
  displayFilteredEventSummary(options = {}) {
    const {
      routePrefix,
      urlSlug,
      eventId,
      guidedLearningFlowCallback,
      eventDetailsCallback,
      navigate = true
    } = options;

    if (navigate) {
      Backbone.history.navigate(combineUrlPaths(routePrefix, 'events', eventId));
    }

    window.app.layout.setView({
      ViewControllerClass: FilteredEventController,
      eventId,
      guidedLearningFlowCallback,
      eventDetailsCallback,
      urlSlug
    });
  }

  displayFilteredEventDetails(options = {}) {
    const {
      routePrefix,
      eventId,
      scheduledEventId,
      enrollmentId,
      initialTab,
      eventEnrollmentRecordCallback,
      navigate = true
    } = options;

    if (navigate) {
      const hashFragment = joinUrlFragments([eventId, scheduledEventId], '-');
      Backbone.history.navigate(combineUrlPaths(routePrefix, 'eventDetails', hashFragment));
    }

    window.app.layout.setView({
      viewDefinition: {
        ViewClass: LearningEventDetailsPage,
        eventId,
        scheduledEventId,
        enrollmentId,
        initialTab,
        routePrefix,
        eventEnrollmentRecordCallback
      }
    });
  }
}

module.exports = FilteredEventIndex;
