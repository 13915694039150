const EvaluationSubmissionPreviewView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionPreviewView');

class EvaluationSubmissionFailureView extends EvaluationSubmissionPreviewView {
  className() {
    return 'submission-warning ax-grid';
  }

  getTemplate() {
    return `\
      <div class="submission-warning__display-wrapper ax-grid__col--12"></div>
      <div class="submission-warning__details-wrapper ax-grid__col--12 ax-grid__col--m-8 ax-grid__col--l-9"></div>
      <div class="submission-warning__reselect-file-wrapper ax-grid__col--12 ax-grid__col--m-auto-size">
      <%= axButton({
        label: t('GuidedLearning.evaluation.details.changeFile'),
        className: 'js-reselect-file qa-reselect-file',
        ghost: true
      }) %>
      </div>
      <div class="submission-warning__reupload-wrapper"></div>
    `;
  }

  regions() {
    return {
      displayRegion: '.submission-warning__display-wrapper',
      detailsRegion: '.submission-warning__details-wrapper',
      reuploadRegion: '.submission-warning__reupload-wrapper'
    };
  }
}

module.exports = EvaluationSubmissionFailureView;
