const I18n = require('@common/libs/I18n');
const Marionette = require('Marionette');
const Form = require('@common/components/forms/Form');
const LanguageList = require('@common/data/collections/LanguageList');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class ProfileLanguageSettingView extends Marionette.LayoutView {

  getTemplate() {
    return require('../templates/ProfileLanguageSettingView.html');
  }

  templateHelpers() {
    return {
      selectAriaLabel: I18n.t('settings.preferredLanguageTitle'),
      showSecondaryLanguages: !TenantPropertyProvider.get().getProperty('dzAlwaysSearchAllLanguages')
    };
  }

  ui() {
    return {
      languageForm: '.languageselect',
      secondaryLanguageList: '.js-search-languages'
    };
  }

  modelEvents() {
    return {change: 'render'};
  }

  initialize() {
    this.setActionButton = this.setActionButton.bind(this);
    this.onSave = this.onSave.bind(this);

    this.actionBar = this.options.actionBar;

    this.rootLanguageList = TenantPropertyProvider.get().getProperty('rootLanguages') || [];
  }

  onRender() {
    this.hasSaved = false;

    window.app.layout.setTitle(I18n.t('settings.languagetitle'));
    this.setupLanguageSelector();
    this.setActionButton();
    this._syncLanguageDisplays();
  }

  setupLanguageSelector() {
    const primarayLanguages = this.getLanguageCollection('languages');
    const searchLanguages = this.getLanguageCollection('rootLanguages');

    this.form = new Form({
      el: this.ui.languageForm,
      model: this.model,
      context: {
        languageList: searchLanguages,
        languageListOptions: {
          collection: primarayLanguages,
          axonSelectOptions: {
            width: 'auto',
            useChosen: false
          }
        }
      }
    });
  }

  getLanguageCollection(languageType) {
    const languages = TenantPropertyProvider.get().getProperty(languageType);
    return LanguageList.fromLanguageCodeList(languages);
  }

  setActionButton() {
    const disableButton = !(this.model.isChangedByUser()) || (this.model.get('primaryLanguage') == null);

    const btns = [{
      type: 'profile-save',
      onClick: this.onSave,
      disabled: disableButton
    }];

    this.actionBar.setActionButton(btns);
  }

  onSave() {
    if (this.hasSaved) {
      return;
    }
    this.hasSaved = true;

    this.model.save()
      .done(() => {
        if (this.model.didPrimaryLanguageChange()) {
          window.location.reload();
        } else {
          window.app.layout.flash.success(I18n.t('settings.languagesaved'));
          this.setActionButton();
        }
      });
  }

  _syncLanguageDisplays() {
    // some of our root languages are actually 4-letter code, so we can't just assume that we need
    // to break them down into 2-letter. The rootLanguages tenant property is where we find out.
    const secondaryLanguageMatch = this.model.getMatchingRootLanguage(this.rootLanguageList);

    const $searchLangElement = $(this.ui.secondaryLanguageList.find(`[data-item-id='${ secondaryLanguageMatch }']`)).closest('li');

    if ($searchLangElement) {
      $searchLangElement.hide();
    }
  }
}

module.exports = ProfileLanguageSettingView;
