// Module
var code = `<!DOCTYPE html>
<html dir="<%= rtlDir %>">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <%= stylesheets %>
  </head>
  <body class="<%= bodyClassName %>">
    <%= bodyHtml %>
  </body>
</html>
`;
// Exports
module.exports = code;