const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const dateHelpers = require('@common/libs/dateHelpers');
class ReferralRecentInvitesView extends UIKit.View {
  template = _.tpl(require('../templates/ReferralRecentInvitesView.html'));

  itemTemplate = _.tpl(require('../templates/_referral_recent_invite_item.html'));

  render() {
    this.$el.html(this.template());
    const recentActivityList = this.collection.toJSON();
    // check if there are some referral inProgress
    if (recentActivityList.length < 1) {
      return this;
    }

    // empty the recent_invites ul
    this.$('.recent-invites').html('');

    // go through the active items and render them
    for (const recentActivity of Array.from(recentActivityList)) {
      // get days left which equals to current server time - initialLoginTime
      const deltaTime = window.apps.auth.session.serverTimeMillis - window.apps.auth.session.localTimeMillis;
      const activityTime = recentActivity.initialLoginDate - deltaTime;
      const serverTimeOffset = this.getOption('serverTimeOffset');
      const recentActivityHtml = this.itemTemplate({
        bountyName: recentActivity.target.displayName,
        hunterName: recentActivity.hunter.displayName,
        activityTime: dateHelpers.timeFromEvent(activityTime, {serverTimeOffset: serverTimeOffset})
      });
      this.$('.recent-invites').append(recentActivityHtml);
    }

    return this;
  }
}

module.exports = ReferralRecentInvitesView;
