const _ = require('underscore');
const ConfidenceLevel = require('@training/apps/training/enums/ConfidenceLevel');
const ActionBarButtonFactory = require('@common/components/actionBarButton/ActionBarButtonFactory');

const I18n = require('@common/libs/I18n');

const QuestionActionButtonFactory = {
  getConfidenceButtons(onSubmitFunction) {
    return this._getStandardButtonConfig(onSubmitFunction);
  },

  getSubmitAnswersButton(onSubmitFunction) {
    return this._getSubmitAnswersButtonConfig(onSubmitFunction);
  },

  _getStandardButtonConfig(onSubmitFunction) {
    return {
      title: I18n.t('question.confidencetitle'),
      animate: true,
      show: false,
      buttons: [
        ActionBarButtonFactory.createButtonWithCustomText(I18n.t('question.confidencelow'), _.partial(onSubmitFunction, ConfidenceLevel.CONFIDENCE_LOW)),
        ActionBarButtonFactory.createButtonWithCustomText(I18n.t('question.confidencemedium'), _.partial(onSubmitFunction, ConfidenceLevel.CONFIDENCE_MEDIUM)),
        ActionBarButtonFactory.createButtonWithCustomText(I18n.t('question.confidencehigh'), _.partial(onSubmitFunction, ConfidenceLevel.CONFIDENCE_HIGH))
      ]
    };
  },

  _getSubmitAnswersButtonConfig(onSubmitFunction) {
    return {
      animate: true,
      show: false,
      buttons: [
        ActionBarButtonFactory.createButtonWithCustomText(I18n.t('question.submitAnswersButton'), _.partial(onSubmitFunction, ConfidenceLevel.CONFIDENCE_MEDIUM))
      ]
    }
  }
};

module.exports = QuestionActionButtonFactory;
