const { ItemView } = require('Marionette');
const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');

const {
  DAILY_REINFORCEMENT,
  TOPIC_CERTIFICATION,
  TOPIC_EXTRA_TRAINING,
  TOPIC_REFRESHER,
  TOPIC_INTRO,
  PATH_ENROLLED,
  PATH_ASSIGNED,
  SUMMARY_TASKS,
  SUMMARY_EXAMS,
  SUMMARY_REFRESHER,
  SUMMARY_CERTIFICATIONS,
  SUMMARY_INTRO,
  TRAINING_PAGE
} = require('@common/components/trainingCards/enums/TrainingCardTypes');

const ROOT_CSS_CLASS = 'training-card__graphic';
const ICON_CSS_CLASS = 'training-card__graphic-icon';

const ICON_CSS_CLASS_BY_TYPE = {
  [DAILY_REINFORCEMENT]: {
    icon: `${ ICON_CSS_CLASS }--reinforcement icon-reinforcement`,
    root: `${ ROOT_CSS_CLASS }--reinforcement`
  },
  [TOPIC_CERTIFICATION]: {
    icon: `${ ICON_CSS_CLASS }--certifications icon-certifications`,
    root: `${ ROOT_CSS_CLASS }--certifications`
  },
  [SUMMARY_CERTIFICATIONS]: {
    icon: `${ ICON_CSS_CLASS }--certifications icon-certifications`,
    root: `${ ROOT_CSS_CLASS }--certifications`
  },
  [TOPIC_EXTRA_TRAINING]: {
    icon: `${ ICON_CSS_CLASS }--topic icon-topic`,
    root: `${ ROOT_CSS_CLASS }--topic`
  },
  [SUMMARY_TASKS]: {
    icon: `${ ICON_CSS_CLASS }--task icon-task`,
    root: `${ ROOT_CSS_CLASS }--task`
  },
  [PATH_ASSIGNED]: {
    icon: `${ ICON_CSS_CLASS }--path icon-path`,
    root: `${ ROOT_CSS_CLASS }--path`
  },
  [PATH_ENROLLED]: {
    icon: `${ ICON_CSS_CLASS }--path icon-path`,
    root: `${ ROOT_CSS_CLASS }--path`
  },
  [SUMMARY_EXAMS]: {
    icon: `${ ICON_CSS_CLASS }--exam icon-exam`,
    root: `${ ROOT_CSS_CLASS }--exam`
  },
  [TOPIC_REFRESHER]: {
    icon: `${ ICON_CSS_CLASS }--refresher icon-refresher`,
    root: `${ ROOT_CSS_CLASS }--refresher`
  },
  [SUMMARY_REFRESHER]: {
    icon: `${ ICON_CSS_CLASS }--refresher icon-refresher`,
    root: `${ ROOT_CSS_CLASS }--refresher`
  },
  [TOPIC_INTRO]: {
    icon: `${ ICON_CSS_CLASS }--intro icon-intro`,
    root: `${ ROOT_CSS_CLASS }--intro`
  },
  [SUMMARY_INTRO]: {
    icon: `${ ICON_CSS_CLASS }--intro icon-intro`,
    root: `${ ROOT_CSS_CLASS }--intro`
  },
  [TRAINING_PAGE]: {
    icon: `${ ICON_CSS_CLASS }--training_page icon-training`,
    root: `${ ROOT_CSS_CLASS }--training_page`
  }
};

class TrainingCardGraphicView extends ItemView {
  getTemplate() {
    return `
      <div class="${ ICON_CSS_CLASS } <%- iconCssClass %>" role="presentation" aria-hidden="true"></div>
      <div class="js-thumbnail-wrapper training-card__graphic-thumbnail <%- thumbnailCssClass %> hidden"></div>
    `;
  }

  className() {
    const trainingCardType = this.getOption('trainingCardType');
    const iconCssClasses = ICON_CSS_CLASS_BY_TYPE[trainingCardType];
    const typeCssClass = iconCssClasses ? ICON_CSS_CLASS_BY_TYPE[trainingCardType].root : '';

    return `${ ROOT_CSS_CLASS } ${ typeCssClass }`;
  }

  ui() {
    return {
      thumbnailImageWrapper: '.js-thumbnail-wrapper'
    };
  }

  templateHelpers() {
    const trainingCardType = this.getOption('trainingCardType');
    const thumbnailImage = this.getOption('thumbnailImage');
    const iconCssClasses = ICON_CSS_CLASS_BY_TYPE[trainingCardType];
    const typeCssClass = iconCssClasses ? ICON_CSS_CLASS_BY_TYPE[trainingCardType].icon : '';

    const useThumbnailImage = this._shouldDisplayThumbnail({
      trainingCardType,
      thumbnailImage
    });

    return {
      iconCssClass: useThumbnailImage ? 'hidden' : typeCssClass,
      thumbnailCssClass: useThumbnailImage ? '' : 'hidden'
    };
  }

  onRender() {
    if (this._shouldDisplayThumbnail({
      trainingCardType: this.getOption('trainingCardType'),
      thumbnailImage: this.getOption('thumbnailImage')
    })) {
      this._renderThumbnailImage();
    }
  }

  _renderThumbnailImage() {
    const thumbnailImage = this.getOption('thumbnailImage');
    const thumbnailPath = (ImageHelpers.getBiggestImageFileBySize(
      thumbnailImage.sizes,
      {
        maxHeight: 500,
        maxWidth: 500
      }
    ) || thumbnailImage.originalFile).path;
    this.ui.thumbnailImageWrapper.css('background-image', 'url(' + thumbnailPath + ')');
    this.ui.thumbnailImageWrapper.attr('role', 'img');
    this.ui.thumbnailImageWrapper.removeClass('hidden');

    if (thumbnailImage.altText && thumbnailImage.altText.preferred) {
      this.ui.thumbnailImageWrapper.attr('aria-label', thumbnailImage.altText.preferred);
    } else if (thumbnailImage.originalFile) {
      this.ui.thumbnailImageWrapper.attr('aria-label', thumbnailImage.originalFile.originalFileName);
    }
  }

  _shouldDisplayThumbnail({
    trainingCardType,
    thumbnailImage
  } = {}) {
    return (trainingCardType !== SUMMARY_TASKS) && thumbnailImage;
  }
}

module.exports = TrainingCardGraphicView;
