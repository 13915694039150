const Backbone = require('Backbone');
const { ItemView } = require('Marionette');

class MoreResultsButton extends ItemView {

  initialize(options = {}) {
    ({
      collection: this.collection,
      tabType: this.tabType,
      searchString: this.searchString
    } = options);
  }

  getTemplate() {
    return `
    <button type="button" class="axon-button white hidden more-results-button">
      <span><%- t('selfDirected.search.moreResults') %></span><span role="presentation" aria-hidden="true" class="icon-chevron_right"></span>
    </button>`;
  }

  className() {
    return 'results-button-item-view';
  }

  ui() {
    return {
      button: '.more-results-button'
    };
  }

  events() {
    return {
      'click .more-results-button': () => {
        const searchString = encodeURIComponent(this.searchString.replace(/ /g, '__'));
        Backbone.history.navigate(`#hub/search/${ this.tabType }/1/${ searchString }`, {trigger: true});
      }
    };
  }

  onAttach() {
    //Check the collection in case it updated prior to onAttach. Otherwise the sync event won't fire and update the button
    this.ui.button.toggleClass('hidden', this.collection.state.totalRecords <= 3);

    this.listenTo(this.collection, 'sync', () => {
      this.ui.button.toggleClass('hidden', this.collection.state.totalRecords <= 3);
    });
  }
}

module.exports = MoreResultsButton;
