const Backbone = require('Backbone');
const _ = require('underscore');

class AssessmentResult extends Backbone.Model {
  initialize() {
    this.idAttribute = 'assessmentId';
  }

  apiEndpoint() {
    return '/assessments';
  }

  defaults() {
    return {pointsForPass: 0};
  }

  parse(response = {}) {
    return response.result != null ? response.result : response;
  }

  url() {
    return `${ super.url(...arguments) }/result`;
  }

  fetch(options = {}) {
    options.data = _.extend({}, options.data, {assessmentType: this.get('type')});

    return super.fetch(options);
  }

  isPassingResult() {
    return this.get('passed') === true;
  }

  getAssesmentType() {
    return this.get('type');
  }
}

module.exports = AssessmentResult;
