const SessionTrainingType = require('@common/data/enums/SessionTrainingType');
const I18n = require('@common/libs/I18n');

module.exports = (trainingSessionType) => {
  switch (trainingSessionType) {
    case SessionTrainingType.CertificationTraining:
      return {
        iconClass: 'icon-certification',
        iconLabel: I18n.t('assessments.types.CertificationTraining')
      };
    case SessionTrainingType.DailyTraining:
      return {
        iconClass: 'icon-reinforcement',
        iconLabel: I18n.t('start.assessmentType.DailyTraining')
      };
    case SessionTrainingType.FormalExamTraining:
      return {
        iconClass: 'icon-exam',
        iconLabel: I18n.t('assessments.types.FormalExamTraining')
      };
    case SessionTrainingType.GuidedLearningTraining:
      return {
        iconClass: 'icon-guided_learning',
        iconLabel: I18n.t('GuidedLearning.title')
      };
    case SessionTrainingType.IntroductoryTraining:
      return {
        iconClass: 'icon-intro',
        iconLabel: I18n.t('start.assessmentType.IntroductoryTraining')
      };
    case SessionTrainingType.RefresherTraining:
      return {
        iconClass: 'icon-refresher',
        iconLabel: I18n.t('assessments.types.RefresherTraining')
      };
    default:
      return {
        iconClass: 'icon-topic',
        iconLabel: I18n.t('start.assessmentType.DailyTraining')
      };
  }
};
