import {
  TableHead as MuiTableHead,
  styled,
  type TableHeadTypeMap as MuiTableHeadTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTableHeadCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableHeadTypeMap<P = object, D extends React.ElementType = 'thead'> = MuiTableHeadTypeMap<P & AxTableHeadCustomProps, D>;

export type AxTableHeadProps<
  D extends ElementType = AxTableHeadTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableHeadTypeMap<P & AxTableHeadCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableHead'
};

const StyledAxTableHead = styled(MuiTableHead, styledOptions)<AxTableHeadProps>(() => {
  return {};
});

/**
 * The container for the header row(s) of `AxTable`. Renders as a `thead` by default.
 *
 * ### Links
 * - • [MUI | Table Head API](https://mui.com/material-ui/api/table-head/)
 */
export const AxTableHead: MuiOverridableComponent<AxTableHeadTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableHeadProps, ref: Ref<HTMLTableSectionElement>) => {
  const tableHeadProps: AxTableHeadProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableHead { ...tableHeadProps } ref={ ref }>
      { children }
    </StyledAxTableHead>
  );
});

export default AxTableHead;
