const { START_PAGE } = require('@common/data/enums/AssessmentLaunchContext');
const AssessmentType = require('@common/data/enums/AssessmentType');
const { getTrainingCardDefinition } = require('@common/components/trainingCards/TrainingCardFactory');

const TrainingPageDataHelpers = require('@training/apps/learnerTraining/learnerTrainingTab/TrainingPageDataHelpers');

const getContinueTrainingCardControllerDefinition = (
  {
    model,
    session,
    assessmentCallback,
    startCurrentSessionTraining
  } = {}
) => {

  let startAssessment;

  if (model.getActionType() === AssessmentType.DailyTraining) {
    startAssessment = (assessmentModel) => {
      const actionSessionType = assessmentModel.getActionType();
      const reinforcementOptions = session.getAssessmentOptionsListOfType(actionSessionType);
      const assessmentOptions = reinforcementOptions?.first()?.get('nextItem');

      if (assessmentOptions) {
        startCurrentSessionTraining(assessmentOptions);
      }
    };

  } else {
    startAssessment = (assessmentModel) => {
      const topicId = assessmentModel.get('action').topicLevel.topicId;
      const level = assessmentModel.get('action').topicLevel.level;
      const assessmentType = assessmentModel.get('action').assessmentType;
      const programId = assessmentModel.get('action').programId;

      const assessmentOptions = {
        topicId,
        level,
        assessmentType,
        programId,
        launchContext: START_PAGE
      };

      assessmentCallback(assessmentOptions);
    };
  }


  return getTrainingCardDefinition(TrainingPageDataHelpers.getData(model, startAssessment));
};

module.exports = {
  getContinueTrainingCardControllerDefinition
};
