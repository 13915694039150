const { ItemView } = require('Marionette');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

module.exports = (skipTrainingCallback) => {
  if (apps.auth.session.get('isDebugMode')) {
    return () => {
      return {
        ViewControllerClass: LayoutController,
        viewDefinition: {
          ViewClass: ItemView,
          className: 'skip-wrapper',
          template: `
          <button type="button" class="ax-button ax-button--icon-left skip-button">
            <span class="icon-remove"></span>
            Skip Training
          </button>`,
          events: {
            'click .skip-button': skipTrainingCallback
          }
        }
      };
    };
  }

  return undefined;
};
