const { Behavior } = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');
const {
  default: trapTabKey,
  FOCUSABLE_ELEMENTS_STRING,
  VISIBLE_TABBABLE_ELEMENTS_STRING
} = require('@common/libs/behaviors/tabTrapper/TabTrapperHelpers')

Behaviors.TabTrapper = class TabTrapper extends Behavior {

  static toggleTrapper(view, toggle) {
    view.trigger('enable:trapper', toggle);
  }

  static VISIBLE_TABBABLE_ELEMENTS_STRING = VISIBLE_TABBABLE_ELEMENTS_STRING;

  static FOCUSABLE_ELEMENTS_STRING = FOCUSABLE_ELEMENTS_STRING;

  initialize() {
    this.listenTo(this.view, 'enable:trapper', this._enableTrapper);
  }

  events() {
    return {
      keydown: '_trapTabKey'
    };
  }

  _enableTrapper(toggle = true) {
    this.isEnabled = toggle;
  }

  // This function was adapted into ES6 from https://labs.levelaccess.com/index.php/ARIA_Dialog
  _trapTabKey(e) {
    if (!this.isEnabled) {
      return;
    }

    trapTabKey(e, this.$el)
  }
};

module.exports = Behaviors.TabTrapper;
