// Module
var code = `<p class="ax-font--no-margin"><%- t('users.password.rules.title') %></p>
<p class="ax-font ax-font__label"><%= t('users.password.rules.lengthRule', {minPasswordLength: minPasswordLength}) %></p>
<% if (complexPassword) { %>
  <p class="ax-font ax-font__label"><%= t('users.password.rules.complexityRule.title', {minNumberOfCharacterGroups: minNumberOfCharacterGroups}) %></p>
  <p><%= t('users.password.rules.complexityRule.uppercase') %></p>
  <p><%= t('users.password.rules.complexityRule.lowercase') %></p>
  <p><%= t('users.password.rules.complexityRule.number') %></p>
  <p><%= t('users.password.rules.complexityRule.symbol') %></p>
<% } %>
`;
// Exports
module.exports = code;