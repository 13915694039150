// Module
var code = `<div class="message-row ax-grid ax-grid--align-items-center">
  <div>
    <div class="icon-bullhorn pull-left"></div>
    <div class="message">
      <div class="name semiboldfont">
        <h4><%- t('news.messageDetails.title') %></h4>
      </div>
      <div class="text-ellipsis text-muted"><%- title %></div>
    </div>
  </div>
  <span class="time-since text-muted ax-font__caption ax-grid__col--auto-size"><%= timeSince %></span>
</div>
`;
// Exports
module.exports = code;