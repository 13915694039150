const UIKit = require('@training/widgets/UIKit');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const DELAY = 3000;
const KeyCode = require('@common/data/enums/KeyCode');
// This is a server limit on the maximum number of tickets a user can buy in one transaction.
const MAX_TICKET_COUNT = 100;
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');
require('@common/libs/behaviors/card/Card');

class AuctionRaffleView extends UIKit.View {
  constructor(options) {
    super(options);
    this.template = _.tpl(require('../templates/AuctionRafflePage.html'));
  }

  events() {
    return {
      'click .itemunlocked .js-buynow': 'showBuyNowModal'
    };
  }

  collectionEvents() {
    return {
      'sync change': 'render'
    };
  }

  render() {
    const items = this.collection.toJSON() || [];
    this.$el.html(this.template({
      items
    }));

    this._closeItemViews();
    this._renderItemViews();

    return this;
  }

  viewDidAppear() {
    this.triggerAdjustment();
  }

  showBuyNowModal(e) {
    if (this.buyNowModalActive) {
      return;
    }
    this.buyNowModalActive = true;
    const $itemrow = this.$(e.currentTarget).closest('.auctionitem');
    const itemid = $itemrow.data('raffle-id');
    const auctionBuyNowItem = this.collection.find((model) => {
      return model.id === itemid;
    });
    auctionBuyNowItem.on('purchased', () => {
      const currentAuctionItem = $('.auctionlist').find(`[data-raffle-id='${ auctionBuyNowItem.id }']`);
      currentAuctionItem.find('.boughtitem').show();
      this.setAvailablePoints();
    });

    window.app.layout.presentModal(new BuyNowModal({
      model: auctionBuyNowItem,
      $itemrow
    }), {
      onClose: () => {
        this.buyNowModalActive = false;
        auctionBuyNowItem.off('purchased');
      }
    });
  }

  setAvailablePoints() {
    $('.points-balance .score').text(
      I18n.numberWithCommas(apps.auth.session.user.availablePoints())
    );
  }

  onClose(...args) {
    this._closeItemViews();
    if (typeof this.options.complete === 'function') {
      this.options.complete();
    }

    super.onClose(...args);
  }

  onDestroy() {
    window.app.layout.dismissMedia();
  }

  _closeItemViews() {
    for (const view of Array.from((this.itemViews != null ? this.itemViews : []))) {
      view.unload();
    }

    this.itemViews = null;
  }

  _renderItemViews() {
    this.itemViews = this.collection.map((item) => {
      return new ItemView({
        el: this.$(`#raffle-${ item.id }`),
        model: item
      }).render();
    });
  }
}


class ItemView extends UIKit.View {

  behaviors() {
    return {
      ZoomOverlay: {},
      Card: {}
    };
  }

  modelEvents() {
    return {
      change: 'render'
    };
  }

  initialize() {
    this.listenTo(this, 'image:clicked', () => {
      window.app.layout.showMedia(this.model.get('prize').prizeImg);
    });
  }

  render() {
    this._closeSecondTimer();

    if (this.model.get('isLocked')) {
      this._renderLocked();
    } else {
      this._renderUnlocked();
    }

    return this;
  }

  onRender() {
    const {
      prizeImg
    } = this.model.get('prize');
    if (prizeImg) {
      this._imageViewer = ImageViewerFactory.createViewerInstance({
        media: prizeImg,
        $el: this.$('.auctionitemimage'),
        ariaHidden: true
      });
      if (this._imageViewer) {
        this._imageViewer.render();
      }
    }
  }

  onClose() {
    this._closeSecondTimer();
  }

  _closeSecondTimer() {
    if (this.timer) {
      this.timer.stop();
    }
  }

  _renderLocked() {
    const totalTime = this.model.get('unlocksInMillis') / 1000;
    this.timer = new UIKit.SecondTimer({
      el: this.$el.find('.unlocksin'),
      timeFormat: true,
      totalTime,
      finishCallback: () => {
        return this.model.set({
          unlocksInMillis: 0
        });
      }
    });
    this.timer.start();
  }

  _renderUnlocked() {
    const rewardProgramPrize = $.extend(true, {}, this.model.toJSON());
    const totalTime = (rewardProgramPrize.nextEndMillis - 10) / 1000.0;
    if (totalTime <= 0) {
      this.$el.addClass('itemtimeout');
      this.$('.auctiontime').text(I18n.t('auction.raffleItem.raffleEnded'));
    } else {
      this.timer = new UIKit.SecondTimer({
        el: this.$el.find('.timeleft'),
        timeFormat: true,
        totalTime,
        finishCallback: () => {
          rewardProgramPrize.nextEndMillis = 0;
          return this.model.set({
            rewardProgramPrize
          });
        }
      });
      this.timer.start();
    }
  }
}

class BuyNowModal extends AccessibleModalView {
  constructor(options) {
    super(options);

    this.buyNow = this.buyNow.bind(this);
    this.onKeypress = this.onKeypress.bind(this);

    this.buyNowInProgress = false;
  }

  id() {
    return 'modalview';
  }

  className() {
    return 'modal buy-now-modal confirm-dialog-view';
  }

  behaviors() {
    return Object.assign({}, super.behaviors(), {
      ZoomOverlay: {}
    });
  }

  events() {
    return Object.assign({}, super.events(), {
      'click .axon-button.cancel': 'dismiss',
      'click .axon-button.buy': 'buyNow',
      'keypress #bid': 'onKeypress'
    });
  }

  modelEvents() {
    return {
      'change:rewardProgramPrize': 'onChangeRewardPrize'
    };
  }

  onKeypress(e) {
    // Check and disable input if keypressed is not in range 0-9 or space/delete
    // 0 and 8 are for Firefox's delete and arrow keys
    const acceptedCharacters = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    acceptedCharacters.push(0, KeyCode.BACKSPACE, KeyCode.ENTER, KeyCode.SPACE);
    if (!acceptedCharacters.includes(e.which)) {
      e.preventDefault()
      return;
    }
    if (e.which === KeyCode.ENTER) {
      this.buyNow(e);
    }
  }

  contentTemplate() {
    const templateFn = _.tpl(require('@training/apps/training/templates/_place_raffle_modal.html'));
    return templateFn({
      model: this.model.toJSON(),
      pointBalance: window.apps.auth.session.user.availablePoints(),
      MAX_TICKET_COUNT
    });
  }

  onRenderContent() {
    this.$('.notice').hide();
  }

  onRenderActionBar() {
    const buttons = {
      type: 'customText',
      text: I18n.t('auction.buybtn'),
      onClick: this.buyNow
    };

    this.actionBar.setActionBar({
      buttons
    });
  }

  viewDidAppear() {
    super.viewDidAppear();

    const {
      prizeImg
    } = this.model.get('prize');
    if (prizeImg) {
      this._imageViewer = ImageViewerFactory.createViewerInstance({
        media: prizeImg,
        $el: this.$('.itemimage'),
        ariaHidden: true
      });
      if (this._imageViewer) {
        this._imageViewer.render();
      }
    }

    this.listenTo(this, 'image:clicked', () => {
      window.app.layout.showMedia(this.model.get('prize').prizeImg);
    });
  }

  buyNow() {
    let message;
    if (this.buyNowInProgress) {
      return;
    }
    this.buyNowInProgress = true;

    let ticketCount = 0;
    const inputValue = parseInt(this.$('input').val()
      .trim(), 10);
    if ((inputValue > 0) && !isNaN(inputValue)) {
      ticketCount = inputValue;
    } else {
      this.$('.notice').html(I18n.t('auction.raffleItem.notice.invalidTicketCount'))
        .show()
        .delay(DELAY)
        .fadeOut();
      this.buyNowInProgress = false;
      return;
    }

    if ((ticketCount < 1) || (ticketCount > MAX_TICKET_COUNT)) {
      message = I18n.t('auction.raffleItem.notice.exceedsMaxTicketCount', {
        maxTicketCount: MAX_TICKET_COUNT
      });
      this.$('.notice').html(message)
        .show()
        .delay(DELAY)
        .fadeOut();
      this.buyNowInProgress = false;
      return;
    }

    const totalUserPoints = window.apps.auth.session.user.availablePoints();

    if (totalUserPoints < (ticketCount * this.model.get('raffleTicketPrice'))) {
      message = I18n.t('auction.raffleItem.notice.insufficientFunds', {
        count: ticketCount
      });
      if (ticketCount === 1) {
        message = I18n.t('auction.raffleItem.notice.insufficientFundsSingular');
      }
      this.$('.notice').html(message)
        .show()
        .delay(DELAY)
        .fadeOut();
      this.buyNowInProgress = false;
      return;
    }

    this.model.buyNow(ticketCount, {
      success: () => {
        window.apps.auth.session.user.subtractPoints(ticketCount * this.model.get('raffleTicketPrice'));
        let raffleTicketCount = this.model.get('raffleTicketCount') || 0;
        raffleTicketCount += ticketCount;
        this.model.set({
          raffleTicketCount
        });
        this.model.trigger('purchased');
        this.dismiss();
      },
      error: (response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        const errCode = exception.getErrorCode();

        if ([AxonifyExceptionCode.CLIENT_ERROR_AUCTION_CLOSED, AxonifyExceptionCode.CLIENT_ERROR_CANNOT_WIN_PRIZE].includes(errCode)) {
          this.dismiss();
          this.options.$itemrow.addClass('itemtimeout');
          this.options.$itemrow.find('.auctiontime').text(I18n.t('auction.raffleItem.raffleEnded'));
          response.skipGlobalHandler = true;
        } else if ([AxonifyExceptionCode.CLIENT_ERROR_INSUFFICIENT_QUANTITY, AxonifyExceptionCode.CLIENT_ERROR_CANNOT_WITHDRAW_POINTS].includes(errCode)) {
          const messages = {
            3012: I18n.t('auction.raffleItem.notice.nobuynow'),
            3037: I18n.t('auction.raffleItem.notice.nobuynow')
          };
          window.app.layout.flash.error(messages[errCode]);
          response.skipGlobalHandler = true;
        }
      },
      complete: () => {
        this.buyNowInProgress = false;
      }
    });
  }

  onChangeRewardPrize(model, value = {}) {
    if (value.nextEndMillis <= 0) {
      this.dismiss();
    }
  }
}

module.exports = AuctionRaffleView;
