const { ItemView } = require('Marionette');
const { Model } = require('Backbone');

require('@training/apps/contentTranslations/behaviors/TranslationToggler');

class ContentTranslationView extends ItemView {
  getTemplate() {
    return `
      <div class="content-translations">
        <span class="content-translations__toggle" tabindex="0" role="button"></span>
        <span class="middot" aria-hidden="true">&middot;</span>
        <div class="content-translations__google-disclaimer"></div>
      </div>
      `;
  }

  behaviors() {
    return {
      TranslationToggler: {
        translationModel: new Model({
          autoTranslatedContent: {
            translatedLanguage: this.getOption('translatedLanguage'),
            translatedContent: this.getOption('translatedContent')
          },
          originalContent: {
            language: this.getOption('originalLanguage'),
            content: this.getOption('originalContent')
          }
        }),
        clickSelector: '.content-translations__toggle',
        $content: this.getOption('$content'),
        htmlEncode: this.getOption('htmlEncode')
      }
    };
  }
}

module.exports = ContentTranslationView;
