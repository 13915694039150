const KeyCode = require('@common/data/enums/KeyCode');

const randomSort = (arrayCollection) => {
  return arrayCollection.slice(0).sort(() => {
    return 0.5 - Math.random();
  });
};

class MatchingQuestionOptions {

  constructor({
    firstOptions,
    secondOptions,
    correctOptionCount,
    verticalPadding = 10,
    sortArrayRandomOrderFn = randomSort
  } = {}) {
    this._options = {
      paired: {
        first: [],
        second: []
      },
      unpaired: {
        first: sortArrayRandomOrderFn(
          this._mapOptions(
            this._filterOptions(firstOptions)
          )
        ),
        second: sortArrayRandomOrderFn(
          this._mapOptions(
            this._filterOptions(secondOptions)
          )
        )
      }
    };

    this._correctOptionCount = correctOptionCount;
    this._verticalPadding = verticalPadding;
  }

  getOptions() {
    this._updateCalculatedHeightsAndPositions();
    this._updateOptionCorrectness();

    return {
      paired: {
        first: this._options.paired.first,
        second: this._options.paired.second
      },
      unpaired: {
        first: this._options.unpaired.first,
        second: this._options.unpaired.second
      },
      all: this._getAllOptions(),
      totalCalculatedHeight: this._getTotalHeight()
    };
  }

  getOptionCounts() {
    return {
      paired: this._options.paired.first.length,
      max: this._correctOptionCount
    };
  }

  getAnswerPairs() {
    const result = [];
    for (let x = 0; x < this._options.paired.first.length; x++) {
      result.push({
        option1: this._options.paired.first[x].id,
        option2: this._options.paired.second[x].id
      });
    }
    return result;
  }

  getTotalCalculatedHeight() {
    this._updateCalculatedHeightsAndPositions();
    return this._getTotalHeight();
  }

  pairCorrectOptions() {
    const firstOptions = this._getAllFirstOptions();
    const options = firstOptions.filter((option) => {
      return Boolean(option.correctPairedOptionId);
    })
      .sort((optionA, optionB) => {
        return optionA.correctOrderIndex - optionB.correctOrderIndex;
      });

    this._unpairAllOptions();

    options.forEach((option) => {
      this.pairOptions(option.id, option.correctPairedOptionId);
    }, this);
  }

  pairOptions(optionIdOne, optionIdTwo) { // order does matter in the case that both are paired
    const optionOne = this._getOption(optionIdOne);
    const optionTwo = this._getOption(optionIdTwo);

    if (optionOne.pairedOptionId === optionTwo.id) {
      this._setOptionUnpaired(optionOne);
      this._setOptionUnpaired(optionTwo);
    } else if (!optionOne.pairedOptionId && !optionTwo.pairedOptionId) {
      this._setUnpairedOptionsPaired(optionOne, optionTwo);
    } else {
      const cleanupIds = [optionOne.pairedOptionId, optionTwo.pairedOptionId];

      if (optionOne.pairedOptionId) {
        this._swapOptions(this._getOption(optionOne.pairedOptionId), optionTwo);
      } else {
        this._swapOptions(this._getOption(optionTwo.pairedOptionId), optionOne);
      }

      cleanupIds.forEach((optionId) => {
        if (optionId) {
          this._setOptionUnpaired(this._getOption(optionId));
        }
      });
    }
  }

  setCorrectlyMatchedPairs(optionIdOne, optionIdTwo, index) {
    const optionOne = this._getOption(optionIdOne);
    const optionTwo = this._getOption(optionIdTwo);

    optionOne.correctPairedOptionId = optionIdTwo;
    optionOne.correctOrderIndex = index;

    optionTwo.correctPairedOptionId = optionIdOne;
    optionTwo.correctOrderIndex = index;
  }

  tryGetOptionNextTo(optionId, keydownDirection) {
    let result = null;
    [
      {
        list: this._options.paired.first,
        listRight: this._options.paired.second,
        listLeft: null,
        listDown: this._options.unpaired.first,
        listUp: null
      },
      {
        list: this._options.paired.second,
        listRight: null,
        listLeft: this._options.paired.first,
        listDown: this._options.unpaired.second,
        listUp: null
      },
      {
        list: this._options.unpaired.first,
        listRight: this._options.unpaired.second,
        listLeft: null,
        listDown: null,
        listUp: this._options.paired.first
      },
      {
        list: this._options.unpaired.second,
        listRight: null,
        listLeft: this._options.unpaired.first,
        listDown: null,
        listUp: this._options.paired.second
      }
    ].forEach(({
      list,
      listRight,
      listLeft,
      listDown,
      listUp
    }) => {
      const index = list.findIndex((option) => {
        return (option.id === optionId);
      });
      if (index === -1) {
        return;
      }

      if (keydownDirection === KeyCode.RIGHT && listRight && listRight.length) {
        result = listRight[Math.min(listRight.length - 1, index)]; // these lists may have different lengths
      }

      if (keydownDirection === KeyCode.LEFT && listLeft && listLeft.length) {
        result = listLeft[Math.min(listLeft.length - 1, index)];
      }

      if (keydownDirection === KeyCode.UP) {
        if (index === 0 && listUp && listUp.length) {
          result = listUp[listUp.length - 1];
        } else if (index > 0) {
          result = list[index - 1];
        }
      }

      if (keydownDirection === KeyCode.DOWN) {
        if (index === (list.length - 1) && listDown && listDown.length) {
          result = listDown[0];
        } else if (index < (list.length - 1)) {
          result = list[index + 1];
        }
      }
    });
    return result;
  }

  updateAutoHeight(optionId, autoHeight) {
    const option = this._getOption(optionId);
    if (option) {
      option.autoHeight = autoHeight;
    }
  }

  _filterOptions(options = []) { // filter from API data
    return options.filter((option) => {
      return ((option.optionText != null && option.optionText.length > 0) || option.optionImg)
        || ((option.secondText != null && option.secondText.length > 0) || option.secondImg);
    });
  }

  _getAllOptions() {
    return this._options.paired.first.concat(
      this._options.paired.second, this._options.unpaired.first, this._options.unpaired.second
    );
  }

  _getAllFirstOptions() {
    return this._options.paired.first.concat(this._options.unpaired.first);
  }

  _getAllSecondOptions() {
    return this._options.paired.second.concat(this._options.unpaired.second);
  }

  _getOption(optionId) {
    return this._getAllOptions().find((option) => {
      return option.id === optionId;
    });
  }

  _getTotalHeight() {
    let firstHeight = 0;
    this._getAllFirstOptions().forEach((option) => {
      firstHeight += option.calculatedHeight + this._verticalPadding;
    });

    let secondHeight = 0;
    this._getAllSecondOptions().forEach((option) => {
      secondHeight += option.calculatedHeight + this._verticalPadding;
    });
    return Math.max(firstHeight, secondHeight);
  }

  _mapOptions(options = []) {
    if (!options.length) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return options.map((option) => {
      let imgId = null;
      if (option.optionImg) {
        imgId = option.optionImg.preferred.id;
      } else if (option.secondImg) {
        imgId = option.secondImg.preferred.id;
      }

      return {
        id: option.id || option.secondId,
        text: option.optionText || option.secondText,
        imgId: imgId,
        pairedOptionId: null,
        autoHeight: 0,
        calculatedHeight: 0,
        topPosition: 0,
        isCorrect: null,
        correctPairedOptionId: null,
        correctOrderIndex: null
      };
    });
  }

  _setOptionUnpaired(option) {
    option.pairedOptionId = null;
    [
      {
        source: this._options.paired.first,
        target: this._options.unpaired.first
      },
      {
        source: this._options.paired.second,
        target: this._options.unpaired.second
      }
    ].forEach(({
      source,
      target
    }) => {
      for (let x = 0; x < source.length; x++) {
        if (source[x].id === option.id) {
          source.splice(x, 1);
          target.unshift(option);
          option.pairedOptionId = null;
        }
      }
    });
  }

  _setUnpairedOptionsPaired(optionOne, optionTwo) {
    if (optionOne.pairedOptionId || optionTwo.pairedOptionId ) {
      return;
    }

    [
      {
        option: optionOne,
        pairId: optionTwo.id
      },
      {
        option: optionTwo,
        pairId: optionOne.id
      }
    ].forEach(({
      option,
      pairId
    }) => {
      option.pairedOptionId = pairId;

      [
        {
          source: this._options.unpaired.first,
          target: this._options.paired.first
        },
        {
          source: this._options.unpaired.second,
          target: this._options.paired.second
        }
      ].forEach(({
        source,
        target
      }) => {
        for (let x = 0; x < source.length; x++) {
          if (source[x].id === option.id) {
            source.splice(x, 1);
            target.push(option);
          }
        }
      });
    });
  }

  _swapOptions(optionOne, optionTwo) {
    let listOne, listTwo, indexOne, indexTwo;
    [
      this._options.unpaired.first,
      this._options.paired.first,
      this._options.unpaired.second,
      this._options.paired.second
    ].forEach((optionList) => {
      optionList.forEach((option, index) => {
        if (option.id === optionOne.id) {
          listOne = optionList;
          indexOne = index;
        } else if (option.id === optionTwo.id) {
          listTwo = optionList;
          indexTwo = index;
        }
      });
    });

    if (optionOne.pairedOptionId) {
      this._getOption(optionOne.pairedOptionId).pairedOptionId = optionTwo.id;
    }
    if (optionTwo.pairedOptionId) {
      this._getOption(optionTwo.pairedOptionId).pairedOptionId = optionOne.id;
    }

    const tempId = optionOne.pairedOptionId;
    optionOne.pairedOptionId = optionTwo.pairedOptionId;
    optionTwo.pairedOptionId = tempId;

    listOne.splice(indexOne, 1, optionTwo);
    listTwo.splice(indexTwo, 1, optionOne);
  }

  _updateCalculatedHeightsAndPositions() {
    const firstOptions = this._getAllFirstOptions();
    const secondOptions = this._getAllSecondOptions();

    let topPosition = 0;

    for (let x = 0; x < Math.max(firstOptions.length, secondOptions.length); x++) {
      const firstAutoHeight = firstOptions[x] ? firstOptions[x].autoHeight : 0;
      const secondAutoHeight = secondOptions[x] ? secondOptions[x].autoHeight : 0;
      const maxHeight = Math.max(firstAutoHeight, secondAutoHeight);

      if (firstOptions[x]) {
        firstOptions[x].calculatedHeight = maxHeight;
        firstOptions[x].topPosition = topPosition;
      }
      if (secondOptions[x]) {
        secondOptions[x].calculatedHeight = maxHeight;
        secondOptions[x].topPosition = topPosition;
      }

      topPosition += this._verticalPadding + maxHeight;
    }
  }

  _updateOptionCorrectness() {
    const options = this._getAllOptions();

    options.forEach((option) => {
      option.isCorrect = (option.pairedOptionId && option.correctPairedOptionId && option.pairedOptionId === option.correctPairedOptionId);
    });
  }

  _unpairAllOptions() {
    this._options.paired.first.slice().forEach((option) => {
      this.pairOptions(option.id, option.pairedOptionId);
    }, this);
  }

}

module.exports = MatchingQuestionOptions;
