const Backbone = require('Backbone');
const _ = require('underscore');

const I18n = require('@common/libs/I18n');

class PageReportType extends Backbone.Model {
  constructor(arg) {
    let attributes = {};
    if (_.isString(arg)) {
      attributes = { reportType: arg.toString() };
    }
    super(attributes, {});
  }

  toOption() {
    const reportType = this.get('reportType');
    return { id: reportType, value: I18n.t(`discover.report.type.${ reportType }`) };
  }
}
module.exports = PageReportType;
