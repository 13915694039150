import {
  type SpacingFactorNumber,
  type SpacingUiKitSizeString
} from '@common/modules/react/themes/ThemeSpacing';
import {
  type AX_DIALOG_VARIANTS,
  type AxTypographyProps
} from '@common/modules/react/themes/components';
import { useIsMobile } from '@common/util/useScreenSize';
import {
  Dialog as MUIDialog,
  dialogClasses,
  type DialogProps as MUIDialogProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { merge } from 'lodash';

export interface AxThemedDialogProps extends MUIDialogProps {
  variant?: AX_DIALOG_VARIANTS
  verticalPosition?: 'top' | 'center' | 'bottom'
  fullHeight?: boolean
  outsideSpacing?: SpacingUiKitSizeString | SpacingFactorNumber
  alignContentText?: AxTypographyProps['textAlign']
}

interface StyledDialogProps extends AxThemedDialogProps {
  isMobile: boolean
}

const verticalPositionMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};

const createDialog = styled(MUIDialog, {
  name: 'AxDialog',
  shouldForwardProp: (prop: string) => {
    return !['fullHeight', 'verticalPosition', 'outsideSpacing', 'isMobile', 'alignContentText'].includes(prop);
  }
})<StyledDialogProps>;

type DialogStyles = Parameters<typeof createDialog>[1];

const Dialog = createDialog((props) => {
  const {
    variant = 'default',
    verticalPosition = 'top',
    alignContentText = 'center',
    outsideSpacing = 'l',
    fullScreen,
    scroll = 'paper',
    fullWidth = false,
    maxWidth = 'sm',
    fullHeight = false,
    PaperProps = {},
    isMobile,
    theme
  } = props;

  const spacing = theme.spacing(outsideSpacing);
  const spacingPx = theme.mixins.toPx(spacing);

  // Type here required or TS complains about this method as a whole :/
  let defaultStyles: DialogStyles = {
    position: 'absolute',
    '& > .MuiBackdrop-root': {
      position: 'inherit',
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    '& .MuiDialog-container': {
      alignItems: verticalPositionMap[verticalPosition]
    },
    '& .MuiPaper-root[role="dialog"]': {
      // matches styling of existing Axonify modals
      ...(isMobile ? {
        boxShadow: 'none'
      } : {
        minHeight: '30rem',
        maxWidth: '94rem'
      }),

      /**
       * The following styles are copied from the default MUI dialog styles for the paper element but modified to allow for overriding the spacing around the dialog.
       */
      margin: spacing,

      ...(scroll === 'paper' && {
        maxHeight: `calc(100% - ${ spacing } * 2)`
      }),
      ...(!maxWidth && {
        maxWidth: `calc(100% - ${ spacing } * 2)`
      }),

      ...(maxWidth === 'xs' && {
        [`&.${ dialogClasses.paperScrollBody }`]: {
          [theme.breakpoints.down(Math.max(theme.breakpoints.values.xs, 444) + spacingPx * 2)]: {
            maxWidth: `calc(100% - ${ spacing } * 2)`
          }
        }
      }),
      ...(maxWidth && maxWidth !== 'xs' && {
        [`&.${ dialogClasses.paperScrollBody }`]: {
          [theme.breakpoints.down(theme.breakpoints.values[maxWidth] + spacingPx * 2)]: {
            maxWidth: `calc(100% - ${ spacing } * 2)`
          }
        }
      }),

      ...(fullWidth && {
        width: `calc(100% - ${ spacing } * 2)`
      }),

      ...(fullHeight && {
        height: `calc(100% - ${ spacing } * 2)`
      }),

      ...((fullScreen ?? isMobile) && {
        margin: 0,
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        ...(isMobile && {
          borderTop: `0.1rem solid ${ theme.uiKit.colorGrey30 }`,
          borderBottom: `0.1rem solid ${ theme.uiKit.colorGrey30 }`
        })
      })
    },
    '& .MuiDialogTitle-root': {
      ...(isMobile ? {
        padding: theme.spacing('s', 'm')
      } : {
        padding: theme.spacing('m', 'l')
      })
    },
    '& .MuiDialogContent-root': {
      padding: 0,
      textAlign: alignContentText
    },
    '& .MuiDialogActions-root': {
      ...(isMobile ? {
        padding: theme.spacing('s')
      } : {
        padding: theme.spacing('m')
      }),
      alignItems: 'stretch'
    }
  };

  if (variant === 'confirmation') {
    defaultStyles = merge(defaultStyles, {
      '& .MuiDialogTitle-root': {
        padding: theme.spacing('m')
      },
      '& .MuiPaper-root[role="dialog"]': {
        ...(isMobile ? {
          minHeight: '15rem',
          minWidth: '20rem'
        } : {
          minHeight: '15rem',
          maxWidth: '45rem'
        })
      },
      '& .MuiDialogContentText-root': {
        padding: theme.spacing('', 'm'),
        justifyContent: 'center',
        alignItems: 'center'
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center'
      }
    });
  }

  if (PaperProps.sx != null) {
    defaultStyles = merge(defaultStyles, {
      '& .MuiPaper-root[role="dialog"]': {
        ...PaperProps.sx
      }
    });
  }

  return defaultStyles;
});

const defaultProps: Record<AX_DIALOG_VARIANTS, Partial<AxThemedDialogProps>> = {
  default: {
    maxWidth: 'md',
    fullWidth: true,
    alignContentText: 'start'
  },
  confirmation: {
    maxWidth: 'xs',
    fullWidth: true,
    alignContentText: 'center'
  }
};

export default function AxThemedDialog(props: AxThemedDialogProps): JSX.Element {
  const isMobile = useIsMobile();

  const {
    variant = 'default'
  } = props;

  const dialogProps = {
    disableEnforceFocus: true,
    isMobile,
    ...defaultProps[variant],
    ...props
  };

  return <Dialog { ...dialogProps } />;
}
