const TincanCompletionTrigger = require('@common/data/enums/TincanCompletionTrigger');
const NullTincanCompletionTracker = require('./NullTincanCompletionTracker');
const SCORMProfileTincanCompletionTracker = require('./SCORMProfileTincanCompletionTracker');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class TincanCompletionTrackerFactory {
  createTracker(tincanPackage) {
    switch (tincanPackage.completionTrigger) {
      case TincanCompletionTrigger.AlwaysCompleted:
        return new NullTincanCompletionTracker();
      case TincanCompletionTrigger.ScormProfile:
        return new SCORMProfileTincanCompletionTracker({
          tenantPropertyPool: TenantPropertyProvider.get()
        });
      default:
        return null;
    }
  }
}

module.exports = TincanCompletionTrackerFactory;
