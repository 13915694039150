const $os = require('detectOS');

const AdjustableModalView = require('@training/apps/main/views/AdjustableModalView');
const TabTrapper = require('@common/libs/behaviors/tabTrapper/TabTrapper');

class AccessibleModalView extends AdjustableModalView {
  behaviors() {
    return Object.assign(super.behaviors(), {
      TabTrapper: {}
    });
  }

  attributes() {
    return {
      role: 'dialog'
    };
  }

  viewDidAppear() {
    super.viewDidAppear();
    TabTrapper.toggleTrapper(this, true);
    this._setModalFocus();
  }

  onClose() {
    TabTrapper.toggleTrapper(this, false);
    super.onClose();
  }

  _setModalFocus() {
    const modalElement = this.$el;
    const modalFocus = modalElement.find('.modal-focus')[0];
    const modalInput = modalElement.find('input')[0];
    const modalHeader = modalElement.find('h1, h2')[0];

    const modalDataEditor = modalInput && $(modalInput).parents('[data-editor]');
    const isMediaInput = modalDataEditor && $(modalDataEditor).attr('data-editor') === 'MediaInput';

    if (modalFocus) {
      const oldTabindex = $(modalFocus).attr('tabindex');
      $(modalFocus).attr('tabindex', '-1')
        .trigger('focus')
        .attr('tabindex', oldTabindex);
    } else if ($os.desktop && modalInput && !isMediaInput) {
      $(modalInput).trigger('focus');
    } else if (modalHeader) {
      $(modalHeader).attr('tabindex', '-1')
        .trigger('focus');
    } else {
      this.$el.attr('tabindex', '-1').trigger('focus');
    }

    if (modalHeader) {
      const modalHeaderId = $(modalHeader).attr('id');

      if (modalHeaderId == null) {
        $(modalHeader).attr('id', 'modal-label');
        $(modalElement).attr('aria-labelledby', 'modal-label');
      } else {
        $(modalElement).attr('aria-labelledby', modalHeaderId);
      }
    }
  }
}

module.exports = AccessibleModalView;
