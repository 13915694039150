const _ = require('underscore');
const Backbone = require('Backbone');
const Game = require('@common/data/models/Game');
const User = require('@common/data/models/AuthUser');

class GameChallenge extends Backbone.Model {
  apiEndpoint() {
    return '/games/challenges';
  }

  parse(response = {}) {
    const challenge = response.entity != null ? response.entity : response;

    // We extend the attributes object because we instantiate the model with
    // `game` data that we want to merge with the `challenge`
    return $.extend(true, {}, this.attributes, challenge);
  }

  preinitialize() {
    // Make a `Game` class instance so that we get the game name as part of the
    // `game` object in the challenge
    this.game = new Game();

    this.challengerUser = new User();
    this.challengeeUser = new User();

    this.listenTo(this, 'change:challengee', (model, attributes) => {
      this.challengeeUser.set(attributes);
    });

    this.listenTo(this, 'change:challenger', (model, attributes) => {
      this.challengerUser.set(attributes);
    });

    this.listenTo(this, 'change:game', (gameChallengeModel, attributes) => {
      this.game.set(this.game.parse(attributes));
    });

    this.listenTo(this.game, 'change', (gameModel) => {
      this.set({game: gameModel.toJSON()});
    });
  }

  toJSON(...args) {
    const JSON = super.toJSON(...args);
    _.extend(JSON.challengee, this.challengeeUser.toJSON());
    _.extend(JSON.challenger, this.challengerUser.toJSON());
    return JSON;
  }
}

module.exports = GameChallenge;
