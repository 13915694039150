const _ = require('underscore');

/**
 * Default configuration options for froala, required for instantiating a new Froala editor
 * See the Froala APIs for more details on each option
 */
const FroalaConfig = {
  // These defaults are unlikely to change, but can be overridden if necessary.
  default: {
    // DON'T MODIFY THIS KEY Unless you got it directly from Froala
    key: 'uXD2lA5E6B4D4H4B3kOPVe1f1d1Le1b1D1AWMSGSAFDTGHWsF4I4A11B7C2C5G5B1E3E3==',
    attribution: false,
    htmlAllowedEmptyTags: ['textarea', 'iframe', 'object', 'video', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'span'],
    imagePaste: false,
    imageResize: true,
    linkAlwaysBlank: true,
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    linkNoOpener: false,
    linkNoReferrer: false,
    listAdvancedTypes: false,
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4'
    },
    pasteDeniedTags: ['img', 'script'],
    shortcutsEnabled: ['show', 'bold', 'italic', 'underline', 'strikeThrough', 'indent', 'outdent', 'undo', 'redo', 'createLink', 'code'],
    tableInsertHelper: false,
    videoAllowedProviders: ['youtube', 'vimeo', 'vidyard', 'dailymotion'],
    videoEditButtons: ['videoReplace', 'videoRemove', '|', 'videoDisplay', 'videoSize', 'autoplay'], //Autoplay and replace are mandatory by Froala
    videoInsertButtons: ['videoBack', '|', 'videoByURL', 'axVideoUpload', 'videoEmbed'],
    wordDeniedAttrs: ['language', 'data-ccp-charstyle', 'data-ccp-props', 'rel']
  },

  /**
   * Helper function to return toolbars based on the currently configured instance of the Froala Editor
   * @param {Object} [mediaAttachmentsEnabled={images: false, embeddedVideos: false, uploadedVideos: false}] - Which media attachments are enabled?
   * @param {boolean} [useExtendedToolbars=false] - Should we use the extended toolbar configuration with additional plugins?
   */
  getToolbars(mediaAttachmentsEnabled = {
    images: false,
    embeddedVideos: false,
    uploadedVideos: false
  }, useExtendedToolbars = false) {
    const moreRichButtons = ['insertLink', 'insertTable', 'insertHR'];
    const moreTextButtons = ['bold', 'italic', 'underline', 'h1', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'];
    const moreTextButtonsXS = ['bold', 'italic', 'underline', 'h1', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'inlineClass', 'inlineStyle', 'clearFormatting'];
    const moreParagraphButtons = ['paragraphFormat', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'];
    const moreMiscButtons = ['undo', 'redo', 'selectAll', 'help'];
    const moreMiscButtonsXS = moreMiscButtons.slice(); // This will never change

    let richSpliceIndex = 1;
    if (mediaAttachmentsEnabled.embeddedVideos || mediaAttachmentsEnabled.uploadedVideos) {
      moreRichButtons.splice(1, 0, 'insertVideo');
      richSpliceIndex += 1;
    }
    if (mediaAttachmentsEnabled.images) {
      moreRichButtons.splice(1, 0, 'uploadImage');
      richSpliceIndex += 1;
    }

    if (useExtendedToolbars) {
      moreRichButtons.splice(richSpliceIndex, 0, 'emoticons', 'specialCharacters');
      moreTextButtons.splice(7, 0, 'fontFamily');
    } else {
      // Currently our extended toolbars should NOT allow fullscreen because of sizing issues
      // TODO? Should this be its own option instead (allowFullscreen)?
      moreMiscButtons.splice(1, 0, 'fullscreen');
    }

    return {
      toolbarButtons: {
        moreText: {
          buttons: moreTextButtons,
          buttonsVisible: 4
        },
        moreParagraph: {
          buttons: moreParagraphButtons
        },
        moreRich: {
          buttons: moreRichButtons,
          buttonsVisible: 3
        },
        moreMisc: {
          buttons: moreMiscButtons,
          align: 'right',
          buttonsVisible: 2
        }
      },
      toolbarButtonsXS: {
        moreText: {
          buttons: moreTextButtonsXS,
          buttonsVisible: 0
        },
        moreParagraph: {
          buttons: moreParagraphButtons,
          buttonsVisible: 0
        },
        moreRich: {
          buttons: moreRichButtons,
          buttonsVisible: 0
        },
        moreMisc: {
          buttons: moreMiscButtonsXS,
          align: 'right',
          buttonsVisible: 1
        }
      }
    };
  },

  load() {
    let FroalaEditor;
    return Promise.all([
      import('froala').then(({ default: froala }) => {
        FroalaEditor = froala;
      }),
      import('froala.align'),
      import('froala.colors'),
      import('froala.embedly'),
      import('froala.fontFamily'),
      import('froala.fontSize'),
      import('froala.fullscreen'),
      import('froala.image'),
      import('froala.link'),
      import('froala.lists'),
      import('froala.paragraphFormat'),
      import('froala.quote'),
      import('froala.save'),
      import('froala.table'),
      import('froala.url'),
      import('froala.video'),
      import('froala.wordPaste'),
      import('froala.emoticons'),
      import('froala.charCounter')
    ]).then(() => {
      return FroalaEditor;
    });
  },

  /**
   * The plugins we allow Froala to load
   */
  plugins: ['froala.align', 'froala.colors', 'froala.embedly', 'froala.fontFamily',
    'froala.fontSize', 'froala.fullscreen', 'froala.image', 'froala.link', 'froala.lists',
    'froala.paragraphFormat', 'froala.quote', 'froala.save', 'froala.table', 'froala.url', 'froala.video',
    'froala.wordPaste', 'froala.emoticons', 'froala.charCounter'],

  /**
   * String overrides for any existing plugins that we repurpose or rename
   * Each override should provide the i18n key where we store our translations.
   */
  stringOverrides: {
    'Choose Link': 'selfDirected.rteView.linkToExistingContent'
  },

  /**
   * A helper function to set the configuration. Uses our defaults as a base, and overrides any custom settings we would
   * like to override and the toolbars based on the parameters passed in
   * @param {*} [customSettings={}] - Custom overrides for default settings, should be an object similar to this.default
   * @param {Object} [mediaAttachmentsEnabled={images: false, uploadedVideos: false, embeddedVideos: false}] - Which media attachments are enabled?
   * @param {*} [toolbarsEnabled] - Should toolbars be visible?
   * @param {boolean} [useExtendedToolbars=false] - Should we use the extended toolbar configuration with additional plugins?
   * @returns {Object} Configuration object for instantiating Froala
   */
  initConfig(customSettings = {}, mediaAttachmentsEnabled = {
    images: false,
    videos: false
  }, toolbarsEnabled = true, useExtendedToolbars = false) {
    return _.extend({},
      this.default,
      customSettings,
      toolbarsEnabled ? this.getToolbars(mediaAttachmentsEnabled, useExtendedToolbars) : {});
  }
};

module.exports = FroalaConfig;
