// Module
var code = `<ul class="auctionlist ax-grid">
  <% _.each(items, function (item) { %>
    <li
      class="auctionitem ax-grid__col--12 ax-grid__col--m-6 ax-grid__col--l-4 <% if(item.isLocked) { %>itemlocked<% } else { %>itemunlocked<% } %>"
      data-auction-id="<%- item.id %>"
      id="auction<%- item.id %>"
    >
      <div class="wrap">

        <p class="boughtitem" style="display:none"><%- t('auction.purchased') %></p>

        <div class="auctionimage">
          <div
            role="button"
            tabindex="0"
            aria-label="<%= t('auction.imageZoomLabel', { prizeName: item.prize.name }) %>"
            class="auctionitemimage image-wrapper"
          ></div>
          <div class="locked"></div>
        </div>

        <h2 class="qa-auction-title auctiontitle ax-font ax-font__display" title="<%= item.prize.name %>"><%= item.prize.name %></h2>

        <p class="auctiontime ax-font--no-margin ax-font--secondary">
          <% if(item.isLocked) { %>
            <%- t('auction.locked') %> <span class="unlocksin"></span><br />
            <% if(item.showReasonIfLocked) { %>
              <span class="prizeLockedReason"><%- item.prizeLockedReason %></span>
            <% } %>
          <% } else { %>
            <%- t('auction.timeremaining') %> <span class="timeleft"></span>
          <% } %>
          </p>

        <div class="auctionCost">
          <div class="amountbid ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(item.buyNowPrice) %></div>
          <div class="pointBalance ax-font ax-font__caption"><%- t('auction.pointBalance') %></div>
        </div>
      </div>

      <div class="wrapbuttons">
        <div class="auctionbuttons">
          <%= axButton({
            className: 'auction js-buynow',
            label: t('auction.buybtn'),
            ariaLabel: t('auction.buybtnAccessibility', { prizeName: item.prize.name, prizeCost: numberWithCommas(item.buyNowPrice) })
          }) %>
        </div>
      </div>
    </li>
  <% }); %>
  </ul>
`;
// Exports
module.exports = code;