const logging = require('logging');

const { View } = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');
const PersistentObject = require('@common/libs/UI/PersistentObject');

const KeyCode = require('@common/data/enums/KeyCode');

const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');
const VideoProgressPersistenceWrapper = require('@common/components/video/VideoProgressPersistenceWrapper');
const VideoTimeRemainingCallbackWrapper = require('@common/components/video/VideoTimeRemainingCallbackWrapper');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const {
  ActionBarType,
  createButtonType,
  createButtonWithTextAndIcon,
  toggleButtonSecondary
} = require('@common/components/actionBarButton/ActionBarButtonFactory');

const ScrollEvents = require('@common/libs/behaviors/scrollable/ScrollEvents');

const MIN_PLAYER_HEIGHT = 200;

class VideoPage extends View.extend(PersistentObject) {
  startTime = 0;

  constructor(options = {}) {
    super(options);

    this.activity = assertPropertyExists(options, 'activity');
    this.continueButtonFactory = assertPropertyExists(options, 'continueButtonFactory');
    this.pollingInterval = TenantPropertyProvider.get().getProperty('videoProgressPollingInterval') * 60000;

    ({
      isRetake: this.isRetake = false,
      isExtraTraining: this.isExtraTraining = false,
      complete: this.complete = () => {}
    } = options);
  }

  get template() {
    return require('@training/apps/training/templates/activities/pages/VideoPage.html');
  }

  className() {
    return 'video-page';
  }

  behaviors() {
    return {
      ScrollEvents: {}
    };
  }

  regions() {
    return {
      pageContentRegion: '.js-page-content'
    };
  }

  ui() {
    return {
      pageContent: '.js-page-content'
    };
  }

  useMarionetteRender() {
    return true;
  }

  onBeforeAttach() {
    window.app.layout.toggleFullPage(true);
    window.app.layout.toggleFooter(false);
  }

  onAttach() {
    logging.info(`VideoPage - viewDidAppear ${ this.activity.id }`);

    window.app.layout.setTitle(I18n.t('video.title'));

    this.mediaLink = this.activity.get('body').mediaLink || this.activity.get('body').urlLink;
    const mediaCurrentTime = this.mediaLink.media ? this.mediaLink.media.currentTime : 0;
    const maxTimeViewed = this.mediaLink.media ? this.mediaLink.media.maxTime : 0;

    const containerWidth = this.getWidth(this.ui.pageContent);
    const containerHeight = ScrollEvents.getScrollEventData(this, ScrollEvents.ScrollEventKeys.CONTAINER_HEIGHT);
    const {
      maxWidth,
      maxHeight
    } = this.calculateMaxDimensions(containerWidth, containerHeight);

    const videoPlayerOptions = {
      userLanguage: window.apps.auth.session.user.get('language'),
      maxHeight,
      maxWidth,
      preventSeekForward: !this._canSeekForward(),
      startTime: mediaCurrentTime,
      maxTimeViewed
    };

    this.initializeVideoPlayer(this.mediaLink, videoPlayerOptions);
    this.updateActionBar();
    $(document).on('keypress.VideoPage', this.onKeyPress.bind(this));
  }

  _canSeekForward() {
    return TenantPropertyProvider.get().getProperty('allowVideoSeeking') || this.isExtraTraining || this.isRetake;
  }

  onScroll(scrollData, changes = {}) {
    if (this.videoPlayer != null && ScrollEvents.hasStructuralChanges(changes)) {
      const containerWidth = this.getWidth(this.ui.pageContent);
      const containerHeight = scrollData[ScrollEvents.ScrollEventKeys.CONTAINER_HEIGHT];
      const {
        maxWidth,
        maxHeight
      } = this.calculateMaxDimensions(containerWidth, containerHeight);

      this.videoPlayer.setMaxDimensions(maxWidth, maxHeight);
    }
  }

  initializeVideoPlayer(mediaLink, videoPlayerOptions) {
    this.videoPlayer = VideoPlayerFactory.createPlayerInstance({
      videoPackage: mediaLink,
      viewOptions: videoPlayerOptions
    });

    this.listenTo(this.videoPlayer, 'player:load', this.onPlayerLoad);
    this.listenTo(this.videoPlayer, 'player:end', this.onPlayerEnd);
    this.listenTo(this.videoPlayer, 'player:error', this.onPlayerError);
    this.listenTo(this.videoPlayer, 'player:play', this.onPlayerPlay);
    this.listenTo(this.videoPlayer, 'player:pause', this.onPlayerPause);

    const videoFullscreenExitTimeOffset = TenantPropertyProvider.get().getProperty('videoFullscreenExitTimeOffset');

    VideoTimeRemainingCallbackWrapper({
      videoPlayer: this.videoPlayer,
      timeRemaining: videoFullscreenExitTimeOffset,
      preventSeekForward: !this._canSeekForward(),
      callback: this.onTimeRemainingCallback.bind(this)
    });

    VideoProgressPersistenceWrapper({
      videoPlayer: this.videoPlayer,
      mediaLink: this.mediaLink,
      frequency: this.pollingInterval,
      preventSeekForward: !this._canSeekForward()
    });

    this.pageContentRegion.show(this.videoPlayer);
  }

  onPlayerLoad() {
    this.updateActionBar();
  }

  onPlayerEnd() {
    this.donePlayback();
  }

  onPlayerError() {
    this.displayContinueButton();
  }

  onTimeRemainingCallback() {
    if (this.finished) {
      return;
    }
    this.donePlayback();
  }

  donePlayback() {
    this.videoPlayer.exitFullScreen();
    this.displayContinueButton();
  }

  calculateMaxDimensions(containerWidth, containerHeight) {
    const padding = this.getOuterHeight(this.ui.pageContent, true) - this.getHeight(this.ui.pageContent);

    return {
      maxWidth: containerWidth,
      maxHeight: Math.max(containerHeight - padding, MIN_PLAYER_HEIGHT)
    };
  }

  onSkip() {
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    return this.activity.skipActivity().done(() => {
      logging.info('VideoPage - the module was skipped');
      this.finishVideoPage();
    })
      .fail(() => {
        logging.error('There was a problem skipping this video page module. It\'s possible that the server rejected the request to skip.');
      });
  }

  onNext() {
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    logging.info(`VideoPage - onNext ${ this.activity.id }`);

    const activityBody = { duration: this.activity.activityTimer.elapsedTime() };

    this.activity.setAction('VIDEOSAVE', activityBody, {
      success: () => {
        logging.info(`VideoPage - setAction success ${ this.activity.id }`);
        const points = this.activity.get('points');
        window.apps.auth.session.user.addPoints(points);
        this.finishVideoPage();
      }
    });

    return undefined;
  }

  onPlayerPlay() {
    this.updateActionBar();
  }

  onPlayerPause() {
    this.updateActionBar();
  }

  finishVideoPage() {
    this.finished = true;

    this.stopListening(this.videoPlayer, 'player:load', this.onPlayerLoad);
    this.stopListening(this.videoPlayer, 'player:end', this.onPlayerEnd);
    this.stopListening(this.videoPlayer, 'player:error', this.onPlayerError);
    this.stopListening(this.videoPlayer, 'player:play', this.onPlayerPlay);
    this.stopListening(this.videoPlayer, 'player:pause', this.onPlayerPause);

    this.complete();
  }

  displayContinueButton() {
    if (this.continueShown) {
      return;
    }

    this.continueShown = true;

    this.updateActionBar();
  }

  onKeyPress(e) {
    if (e.which === KeyCode.SPACE) {
      const isInteractiveElement = $(e.target).is('button, a, [class^="mejs-"]');
      const isMediaElementContainer = $(e.target).hasClass('mejs-container');

      if (!isInteractiveElement || isMediaElementContainer) {
        if (this.videoPlayer && this.videoPlayer.isLoaded()) {
          if (this.videoPlayer.isPlaying()) {
            this.pausePlayer();
          } else {
            this.playPlayer();
          }

          return true;
        }
      }
    }

    return undefined;
  }

  pausePlayer() {
    if (this.videoPlayer != null) {
      this.videoPlayer.pause();
      this.updateActionBar();
    }
  }

  playPlayer() {
    if (this.videoPlayer != null) {
      this.videoPlayer.play();
      this.updateActionBar();
    }
  }

  isSkippable() {
    return this.isRetake || this.activity.isBypassable();
  }

  getContinueButtonConfig() {
    return this.continueButtonFactory(this.onNext.bind(this));
  }

  getPauseButtonConfig() {
    return createButtonWithTextAndIcon(I18n.t('video.pause'), 'icon-pause', this.pausePlayer.bind(this));
  }

  getPlayButtonConfig() {
    return createButtonWithTextAndIcon(I18n.t('video.play'), 'icon-play', this.playPlayer.bind(this));
  }

  getSkipButtonConfig() {
    if (this.activity.isBypassable()) {
      return createButtonType(ActionBarType.SKIP, this.onSkip.bind(this));
    } else if (this.isRetake) {
      return createButtonType(ActionBarType.SKIP, this.onNext.bind(this));
    }

    return null;
  }

  getPlayerButtonConfig(isSecondary = false) {
    let button = null;

    if (this.videoPlayer.isLoaded()) {
      if (this.videoPlayer.isPlaying()) {
        button = this.getPauseButtonConfig();
        button = toggleButtonSecondary(button, true);
      } else {
        button = this.getPlayButtonConfig();
        button = toggleButtonSecondary(button, isSecondary);
      }
    }

    return button;
  }

  updateActionBar() {
    let firstButton;
    let secondButton;

    if (this.continueShown) {
      firstButton = this.getContinueButtonConfig();
    } else {
      firstButton = this.getPlayerButtonConfig(this.isSkippable());
      secondButton = this.getSkipButtonConfig();
    }

    this.actionBar.setActionButton([firstButton, secondButton], { hide: false });
  }

  destroy() {
    if (this.videoPlayer != null) {
      this.videoPlayer.destroy();
    }

    return super.destroy();
  }

  onDestroy() {
    window.app.layout.toggleFullPage(false);
    window.app.layout.toggleFooter(true);
  }

  onClose() {
    $(document).off('keypress.VideoPage');
    this.actionBar?.setActionButton(null);
  }
}

module.exports = VideoPage;
