// Module
var code = `<div class="js-task-index-header"></div>

<div class="ax-container">
  <div class="page-content ax-grid">
    <div class='js-task-index-content-region task-index-content-region'></div>
  </div>
</div>
`;
// Exports
module.exports = code;