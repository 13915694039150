const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'SURVEY_QUESTION',
  'QUESTION',
  'PDF_MODULE',
  'SCORM_MODULE',
  'VIDEO_MODULE',
  'TINCAN_MODULE',
  'EXTERNAL_LINK_MODULE'
], {
  getTrainingContentTypes() {
    return [
      this.PDF_MODULE,
      this.SCORM_MODULE,
      this.VIDEO_MODULE,
      this.TINCAN_MODULE,
      this.EXTERNAL_LINK_MODULE
    ];
  },
  getQuestionContentTypes() {
    return [
      this.QUESTION,
      this.SURVEY_QUESTION
    ];
  }
});
