const TitleView = require('@common/components/discover/views/TitleView');

class ArticleTitleSubView extends TitleView {

  className() {
    return 'title__sub-container';
  }

  getTemplate() {
    return `
<div class="more-container">
  <button class="hidden js-more-button more-button <%- getMoreButtonClass() %>">
    <div class="page-header__action-container">
      <span class="icon icon-paper_clip"></span>
      <span class="icon icon-ellipsis_horizontal"></span>
    </div>
  </button>
</div>`;
  }
}

module.exports = ArticleTitleSubView;
