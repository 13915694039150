// Module
var code = `<!--QUESTION-->
<div class="matching-question">
  <div class="questionarea mq-displayed clearfix">
    <div class="questionask">
      <% if (displayQuestionImg) { %>
        <div class="questionimage imgwrap zoom-image-wrap"  data-media-id="<%- questionImgId %>"></div>
      <% } %>
      <%= questionText %>
    </div>
    <div class="clear"></div>
    <div class="bonuscharacter" <% if (bonuscharacter === 'NoCoach') { %>aria-hidden="true" role="presentation"<% } %>>
      <div role="img" class="<%- bonuscharacter %> ponder" aria-label="<%- t('coaches.thinking') %>"></div>
    </div>
  </div>
  <div class="hinttext" aria-live="polite">
    <p role="heading" aria-level="3" class="question-instruction clearfix">
      <span class="js-question-instruction" aria-hidden="true"><%- correctOptionCountCurrent %> <%- t('question.numberseperator') %> <%- correctOptionCountMax %></span>
      <span class="js-mq-a11y-question-instructions off-screen"><%- t('question.accessibility.matchingQuestion.questionInstructions', {paired: correctOptionCountCurrent, max: correctOptionCountMax}) %></span>
    </p>
  </div>
  <!--ANSWERS-->
  <p class="off-screen mq-a11y-general-instructions"><%- t('question.accessibility.matchingQuestion.generalInstructions') %></p>
  <p class="off-screen mq-a11y-hint" aria-live="assertive"><%- t('question.accessibility.matchingQuestion.hintDefault') %></p>
  <div class="optionsarea">
    <div class="mq-options-position-wrapper">
      <div class="mq-paired-list">
        <ul class="mq-first-options" aria-label="<%- t('question.accessibility.matchingQuestion.matchedItemsFirst') %>"></ul>
        <ul class="mq-second-options" aria-label="<%- t('question.accessibility.matchingQuestion.matchedItemsSecond') %>"></ul>
      </div>
      <div class="mq-unpaired-list">
        <ul class="mq-first-options" aria-label="<%- t('question.accessibility.matchingQuestion.unmatchedItemsFirst') %>"></ul>
        <ul class="mq-second-options" aria-label="<%- t('question.accessibility.matchingQuestion.unmatchedItemsSecond') %>"></ul>
      </div>
    </div>
  </div>
  <p class="off-screen mq-a11y-submission-instructions"><%- t('question.accessibility.matchingQuestion.submissionInstructions') %></p>
</div>
`;
// Exports
module.exports = code;