const PublicKey = require('@common/data/models/PublicKey');
const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const URL_PREFIX = '/axonify/account/recovery';

class ResetPasswordService {
  static PasswordError = {
    INVALIDTOKEN: 0,
    INVALIDPASSWORD: 1,
    NOUSER: 2,
    PASSWORDRECYCLED: 3
  }

  constructor(networkService = $.ajax) {
    this.networkService = networkService;
  }

  fetchPubKey() {
    this.publicKey = new PublicKey();
    this.keyDeferred = this.publicKey.fetch();
  }

  changePasswordWithEmailToken(password, emailToken) {
    /*
    PUT /axonify/account/recovery/email/reset (reset password given an emailed token)

    Request:
    {
      “token”: “uNNPezFYNU35uDje3qNP5DZtizCHqfGd”,
      “password”: <password encrypted with server’s public key>
    }

    Response:
    • HTTP 200
    • HTTP 400 (error code 3101 - invalid token)
    */

    return this._changePassword(password, emailToken, `${ URL_PREFIX }/email/reset`);
  }

  changePasswordWithQuestionToken(password, questionToken) {
    /*
      PUT /axonify/account/recovery/questions/reset (reset password given an authenticated question token)

      Request:
      {
        “token”: “uNNPezFYNU35uDje3qNP5DZtizCHqfGd”,
        “password”: <password encrypted with server’s public key>
      }

      Response:
      • HTTP 200
      • HTTP 400 (error code 3101 - invalid token)
    */

    return this._changePassword(password, questionToken, `${ URL_PREFIX }/questions/reset`);
  }

  _changePassword(password, token, url) {
    if (!this.publicKey) {
      this.fetchPubKey();
    }

    const pwdSubmitDeferred = $.Deferred();

    Promise.resolve(this.keyDeferred).then(() => {
      return CryptographyHelper.encryptWithRSA(password, this.publicKey);
    })
      .then((encryptedString) => {
        const payload = {
          token: token,
          password: encryptedString
        };

        this.networkService({
          type: 'PUT',
          url: url,
          contentType: 'application/json',
          data: JSON.stringify(payload),
          success: () => {
            pwdSubmitDeferred.resolve();
          },
          error: (response) => {
            const exception = AxonifyExceptionFactory.fromResponse(response);
            if (exception.getErrorCode() === AxonifyExceptionCode.ACCOUNT_RECOVERY_INVALID_TOKEN) {
              response.skipGlobalHandler = true;
              pwdSubmitDeferred.reject(ResetPasswordService.PasswordError.INVALIDTOKEN);
            } else if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_INVALID_PASSWORD) {
              response.skipGlobalHandler = true;
              pwdSubmitDeferred.reject(ResetPasswordService.PasswordError.INVALIDPASSWORD);
            } else if (exception.getErrorCode() === AxonifyExceptionCode.ACCOUNT_RECOVERY_SUSPENDED_USER || exception.getErrorCode() === AxonifyExceptionCode.ACCOUNT_RECOVERY_NO_SUCH_USER) {
            // this gets treated as an invalid token error.
              response.skipGlobalHandler = true;
              pwdSubmitDeferred.reject(ResetPasswordService.PasswordError.NOUSER);
            } else if (exception.getErrorCode() === AxonifyExceptionCode.RECYCLED_PASSWORD) {
              response.skipGlobalHandler = true;
              pwdSubmitDeferred.reject(ResetPasswordService.PasswordError.PASSWORDRECYCLED);
            } else {
              pwdSubmitDeferred.reject();
            }
          }
        });
      })
      .catch(() => {
        pwdSubmitDeferred.reject();
      });

    return pwdSubmitDeferred;
  }

  validateToken(token) {
    /*
      GET /axonify/account/recovery/email/valid?token=<token>

      Request:
      {}

      Response:
      {
        "isTokenValid": true
      }

      This has no special errors, so let the global handler take care of it.
    */
    const queryParams = {
      token: token
    };

    const validateDeferred = $.Deferred();

    this.networkService({
      type: 'GET',
      url: `${ URL_PREFIX }/email/valid`,
      data: queryParams,
      success: (model) => {
        validateDeferred.resolve(Boolean(model.isTokenValid));
      }
    });

    return validateDeferred;
  }
}

module.exports = ResetPasswordService;
