const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const FixedFooterLayout = require('@common/components/fixedLayout/FixedFooterLayout');
const MenuPosition = require('@common/modules/main/menu/MenuPosition');

const AppRegionControllerDefinitionFactory = require('@common/modules/main/app/AppRegionControllerDefinitionFactory');

module.exports = (contextModel) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: FixedFooterLayout,
    delegateEvents: {
      'view:resize': (controllerInstance, viewInstance, winHeight, winWidth) => {
        MenuContextConfig.setViewportWidth(contextModel, winWidth);
      },
      'view:render': (controller, view) => {
        view.toggleFooter(MenuContextConfig.getCurrentMenuPosition(contextModel) === MenuPosition.Bottom);
      },
      'view:inflate': (controller, view) => {
        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.CURRENT_MENU_POSITION }`, (state, contextMenuPosition) => {
          view.toggleFooter(contextMenuPosition === MenuPosition.Bottom);
        });
      }
    },
    regionControllers: AppRegionControllerDefinitionFactory(contextModel)
  };
};
