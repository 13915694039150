const _ = require('underscore');

const I18n = require('@common/libs/I18n');
const Marionette = require('Marionette');
const Backbone = require('Backbone');
const CaptchaConfig = require('@common/data/models/config/CaptchaConfig');

const Form = require('@common/components/forms/Form');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
require('@common/components/forms/editors/radioGroup/Form.Editor.RadioGroup');
require('@common/components/forms/editors/captcha/Form.Editor.Captcha');

class RecoverPasswordPage extends Marionette.LayoutView {
  getTemplate() {
    return require('./RecoverPasswordPage.html');
  }

  ui() {
    return {
      username: '#password-recovery-username',
      optionsContainer: '.recovery-option-container',
      submitButton: '.submit',
      recoveryForm: '.js-recovery-form'
    };
  }

  events() {
    return {
      'click .submit:not(.disabled)': this.onClickSubmit.bind(this)
    };
  }

  constructor(options = {}) {
    super(options);
    ({flowStateModel: this._flowStateModel} = options);

    this.listenTo(this._flowStateModel, 'change:recoveryOptions', this.onRecoveryOptionsChange);
  }

  initialize() {
    this.recaptchaConfig = new CaptchaConfig();
    this.tenantRecaptchaEnabled = TenantPropertyProvider.get().getProperty('forgotPasswordReCaptchaEnabled');
  }

  onRender() {
    this.ui.optionsContainer.hide();
  }

  onAttach() {
    if (this.tenantRecaptchaEnabled) {
      this.recaptchaConfig.fetch().then(this._displayCaptcha.bind(this));
    }
  }

  _displayCaptcha() {
    this.recForm = new Form({
      el: this.ui.recoveryForm,
      model: this.answerModel,
      context: {
        captchaOptions: {
          secretKey: this.recaptchaConfig.getSiteKey()
        }
      }
    });
  }

  onClickSubmit() {
    const username = this.ui.username.val();

    if (username.trim() === '') {
      this.trigger('error', I18n.t('login.forgotPassword.inputEmptyError'), true);
      return;
    }

    if (this.tenantRecaptchaEnabled === true) {
      const recaptchaVal = this.recForm.fields.recaptcha.editor.getValue();
      if (recaptchaVal === '') {
        this.trigger('error', I18n.t('login.forgotPassword.recaptchaInvalid'), true);
        return;
      }
      this._flowStateModel.set('recaptcha', recaptchaVal);
    }

    this._flowStateModel.set('username', this.ui.username.val());

    if (this._flowStateModel.get('nextFlowState')) {
      this.ui.submitButton.addClass('disabled');
      this._moveToNextFlowState();
    } else {
      this._submitUsername();
    }
  }

  _submitUsername() {
    this.trigger('submit');
    this.ui.username.prop('disabled', true);
  }

  _moveToNextFlowState() {
    this.trigger('flow:next');
  }

  onRecoveryOptionsChange() {
    this.ui.optionsContainer.hide();
    const recoveryOptions = this._flowStateModel.get('recoveryOptions');

    if (recoveryOptions.length === 0) {
      this.trigger('error', I18n.t('login.forgotPassword.genericError'));
    } else if (recoveryOptions.length === 1) {
      this._flowStateModel.set('nextFlowState', recoveryOptions[0]);
      this._moveToNextFlowState();
    } else {
      this.ui.optionsContainer.show();
      const flowOptionCollection = new Backbone.Collection();

      _.each(recoveryOptions, (option) => {
        flowOptionCollection.push(new Backbone.Model({
          id: option,
          label: I18n.t(`login.forgotPassword.flowOptions.${ option.toLowerCase() }`),
          tagName: 'li'
        }));
      });

      this.form = new Form({
        el: this.ui.optionsContainer,
        model: this._flowStateModel,
        context: {
          flowOptions: flowOptionCollection
        }
      });

      this.listenTo(this.form, 'change:nextFlowState', () => {
        this.ui.submitButton.toggleClass('disabled', (this.form.fields.nextFlowState.editor.getValue() == null));
      });
    }
  }
}

module.exports = RecoverPasswordPage;
