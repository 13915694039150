const _ = require('underscore');
const Backbone = require('Backbone');
const logging = require('logging');
const FroalaRichContentHelper = require('@common/libs/froala/FroalaRichContentHelper');

class RichContent extends Backbone.Model {

  get className() {
    return 'RichContent';
  }

  defaults() {
    return {
      content: '',
      media: []
    };
  }

  // Helper functions
  getFirstImage() {
    // find the first media--image element and get its media-id
    const $newContent = $('<tmp></tmp>').append(this.get('content'));
    const imageMediaId = $newContent.find('.page__media.media--image').first()
      .data('mediaId');

    // if imageMediaId is not null, then there is an image, so return
    // the media element or null if it does not exist
    const imageMedia = this._getMediaById(imageMediaId);

    return imageMedia;
  }

  addTags(isNewEditor = false) {
    const $content = $('<tmp></tmp>').append(this.get('content'));

    this.addImagePlaceholderTagsToElement($content, isNewEditor);
    this.addVideoPlaceholderTagsToElement($content);
    this.addContentEditableTagsToElement($content, isNewEditor);

    return $content[0].innerHTML;
  }

  addImagePlaceholderTagsToElement($element, isNewEditor) {
    $element.find('.page__media.media--image[data-media-id]').each((index, el) => {
      const $imgParent = FroalaRichContentHelper.getImageParentElement($(el));
      if (isNewEditor) {
        // Anchor tags cannot be empty in the editor, so we add a placeholder
        // Otherwise, we don't add placeholders
        if ($imgParent.is('a') && $imgParent.children().length === 0) {
          $imgParent.prepend('<div class="image-placeholder"></div>');
        }
      } else {
        $(el).append('<div class="image-placeholder"></div>');
      }
    });
  }

  addContentEditableTagsToElement($element, isNewEditor) {
    // This tag is added to prevent the user from being able to add text within the media
    $element.find('.page__media').attr('contenteditable', false);

    // Froala requires contentEditable = true for image resize.
    if (isNewEditor) {
      $element.find('.page__media.media--image[data-media-id]').attr('contenteditable', true);
    }
  }

  addVideoPlaceholderTagsToElement($element) {
    // look for all divs with page__media.media--video tag
    $element.find('.page__media.media--video[data-media-id], .page__media.media--embeddedVideo[data-media-id]').each((index, el) => {
      const $el = $(el);
      const mediaId = $el.attr('data-media-id');

      // need to add the placeholder div tag
      const divPlacholder = $('<img>').addClass('video-placeholder')
        .attr('data-media-id', mediaId);

      $el.append(divPlacholder);
    });
  }

  getImageMediaInfo($element) {
    return $element.find('.page__media.media--image').map((index, el) => {
      const $el = $(el);
      const mediaId = $el.data('mediaId');

      const media = this._getMediaById(mediaId);

      if (media) {
        return {
          $el,
          media
        };
      }
      logging.error(`An image media element with media ID ${ mediaId } was found in the rich content but there is no media with that ID on the model.`);
      return null;

    })
      .get();
  }

  getVideoMediaInfo($element) {
    return $element.find('.page__media.media--video').map((index, el) => {
      const $el = $(el);
      const mediaId = $el.data('mediaId');

      const media = this._getMediaById(mediaId);
      const mediaLink = {media};

      if (media) {
        return {
          $el,
          mediaLink
        };
      }
      logging.error(`A video media element with media ID ${ mediaId } was found in the rich content but there is no media with that ID on the model.`);
      return null;

    })
      .get();
  }

  getEmbeddedVideoInfo($element) {
    return $element.find('.page__media.media--embeddedVideo').map((index, el) => {
      const $el = $(el);
      const mediaId = $el.data('mediaId');
      const source = $el.data('source');

      if (mediaId && source) {
        return {
          $el,
          source,
          mediaId
        };
      }
      logging.error('An embedded video media element was found in the rich content but no media ID and source/provider value was found.');
      return null;

    })
      .get();
  }

  getDocumentCount() {
    return _.reduce(this.get('media'), (count, media) => {
      if (media.type === 'document') {
        return count + 1;
      }
      return count;

    }, 0);
  }

  getDocumentInfoList() {
    // look through the media object and get the list
    // with type of 'document'
    return _.filter(this.get('media'), (media) => {
      return media.type === 'document';
    });
  }

  _getMediaById(mediaId) {
    return _.find(this.get('media'), (media) => {
      return media.id === mediaId;
    });
  }
}

module.exports = RichContent;
