const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const TrainingDataHelpers = require('@common/components/learningPathExplorer/TrainingDataHelpers');

const {
  GRADUATE: PROGRESS_LEVEL_GRADUATE
} = require('@common/components/trainingCards/enums/TrainingCardProgressLevelTypes');

const TopicLevelActionType = require('@common/data/enums/TopicLevelActionType');
const TopicCTAButtonHelper = require('@common/components/search/TopicCTAButtonHelper');

const { DISCOVER_CARD } = require('@common/components/trainingCards/enums/TrainingCardTypes');
const TrainingCardModel = require('@common/components/trainingCards/TrainingCardModel');

const { SEARCH_TOPIC_ROUTE } = require('@training/apps/training/enums/SearchRoutesEnum');
const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');

module.exports = Object.assign({}, TrainingDataHelpers, {
  buildTopicData(model, trainingCardType, startAssessment) {
    const session = window.app.sessionController.session;
    const isStartable = TopicCTAButtonHelper.isStartable(model.getAction(), session);
    const actionMetaData = model.getCardActionMetaData();
    const actionType = model.getActionType();
    const topicLevels = model.getTopicLevels();
    const isGraduated = (topicLevel) => {
      return topicLevel.progress === PROGRESS_LEVEL_GRADUATE;
    };
    const isGraduatedFromAllLevel = topicLevels.every(isGraduated);

    return {
      trainingCardType,
      isStartable,
      thumbnailImage: model.getThumbnailImage(),
      isAssigned: model.getIsAssigned(),
      isEnrolled: model.getIsEnrolled(),
      isRecommended: model.getIsRecommended(),
      subjectName: model.getSubjectName(),
      title: model.getTitle(),
      isGraduatedFromAllLevel,
      actionType,
      actionMetaData,
      actionCallback: () => {
        if (actionType === TopicLevelActionType.VISIT_PATH) {
          const programId = model.get('action').programId;

          window.app.sessionController.redirectGL({
            pageId: GuidedLearningPageEnum.DetailsPage,
            pageOptions: { programId }
          });
        } else {
          startAssessment(model);
        }
      },
      fullCardCallback: () => {
        Backbone.history.navigate(`#${ SEARCH_TOPIC_ROUTE }/${ model.get('id') }`, {
          trigger: true
        });
      }
    };
  },

  buildPathData(model, trainingCardType) {
    const daysUntilDue = model.getDaysUntilDue();
    return {
      trainingCardType,
      thumbnailImage: model.getThumbnailImage(),
      isAssigned: model.getIsAssigned(),
      isEnrolled: model.getIsEnrolled(),
      title: model.getName(),
      isComplete: model.getIsComplete(),
      numCompleted: model.getNumCompleted(),
      total: model.getTotal(),
      daysUntilDue,
      isDueToday: daysUntilDue === 0,
      isOverdue: daysUntilDue && daysUntilDue < 1,
      progressPercent: model.getProgress(),
      hasReassignedItems: model.hasReassignedItems(),
      fullCardCallback: () => {
        if (model.getIsAssigned()) {
          window.app.sessionController.redirectGL({
            pageId: GuidedLearningPageEnum.DetailsPage,
            pageOptions: { programId: model.get('id') }
          })
        } else {
          Backbone.history.navigate(`#hub/paths/${ model.get('id') }`, {
            trigger: true
          });
        }

      }
    };
  },

  buildTrainingData(model, trainingCardType) {
    const hash = '#hub/training/topics';
    const title = I18n.t('hub.myTraining');
    const total = model.getTotal();
    const numCompleted = model.getNumCompleted();
    return {
      trainingCardType,
      isAssigned: false,
      isEnrolled: model.getIsEnrolled(),
      title,
      isComplete: model.getIsComplete(),
      numCompleted,
      total,
      numAvailable: total - numCompleted,
      progressPercent: total > 0 ? Math.round((numCompleted / total) * 100) : 100,
      actionType: TopicLevelActionType.VIEW_ALL,
      actionCallback: () => {
        Backbone.history.navigate(hash, {
          trigger: true
        });
      },
      fullCardCallback: () => {
        Backbone.history.navigate(hash, {
          trigger: true
        });
      }
    };
  },

  generateDiscoverCardData(completed) {
    return new TrainingCardModel({
      completed,
      cardType: DISCOVER_CARD,
      trainingCardType: DISCOVER_CARD,
      id: DISCOVER_CARD
    });
  },

  buildReinforcementData(model, startAssessment) {
    const actionMetaData = model.getCardActionMetaData();
    const title = I18n.t('start.assessmentType.DailyTraining');

    return {
      trainingCardType: model.getCardType(),
      title,
      actionMetaData,
      isStartable: true, // This is a trigger to ensure the action button is always displayed

      actionType: actionMetaData.type,

      actionCallback: () => {
        startAssessment(model);
      },
      fullCardCallback: () => {
        startAssessment(model);
      }
    };
  }
});
