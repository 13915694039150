const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');
const $os = require('detectOS');
const {
  CommunitySettingsButtonOption,
  ReportsButtonOption,
  CommunityInsightsButtonOption
} = require('@training/apps/search/ArticleMenuDropdownOptions');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class ArticleMenuDropdown extends MenuDropdown {
  initialize(options = {}) {
    super.initialize(options);
    ({
      userMetadata: this.userMetadata
    } = options);
  }

  ui() {
    return Object.assign(super.ui(), {
      reports: '.' + ReportsButtonOption.buttonClass,
      communitiesSettings: '.' + CommunitySettingsButtonOption.buttonClass,
      communityInsights: '.' + CommunityInsightsButtonOption.buttonClass
    });
  }

  onAttach() {
    const communitiesEditSupported = $os.desktop && window.apps.auth.session.user.hasCommunityManagementAccess();
    const canViewReports = this.userMetadata.canViewReports();
    const isAdminUser = window.apps.auth.session.user.isAdminUser();
    // Reset any elements that may have already been hidden
    this.ui.dropdownToggleButton.removeClass('hidden');
    this.ui.dropdownPopover.find('li').removeClass(['hidden', 'only-visible-child']);

    const communitiesSettingsParentLi = this.ui.communitiesSettings.closest('li');
    const reportsParentLi = this.ui.reports.closest('li');
    const communityInsightsParentLi = this.ui.communityInsights.closest('li');
    const propertyProvider = TenantPropertyProvider.get();
    const isCommsEnabled = propertyProvider.getProperty('communicationsEnabled');

    if (!canViewReports && !communitiesEditSupported && !isAdminUser) {
      this.ui.dropdownToggleButton.addClass('hidden');
    } else {
      if (!communitiesEditSupported) {
        communitiesSettingsParentLi.addClass('hidden');
      }
      if (!canViewReports) {
        reportsParentLi.addClass('hidden');
      }
      if (!isAdminUser || !isCommsEnabled) {
        communityInsightsParentLi.addClass('hidden');
      }
    }
  }
}

module.exports = ArticleMenuDropdown;
