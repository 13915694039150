import { AxFormControlLabel } from '@common/modules/react/themes/components/inputs';
import {
  MenuItem as MuiMenuItem,
  styled,
  useTheme,
  type MenuItemTypeMap as MuiMenuItemTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  find,
  merge
} from 'lodash';
import {
  Children,
  forwardRef,
  isValidElement,
  type ElementType,
  type Ref
} from 'react';

type PropsToOmit = 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'TouchRippleProps'
  | 'touchRippleRef';

type AxMenuItemCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxMenuItemTypeMap<P = object, D extends React.ElementType = 'li'> = {
  props: P & Omit<MuiMenuItemTypeMap['props'], PropsToOmit> & AxMenuItemCustomProps;
  defaultComponent: D;
};


export type AxMenuItemProps<
  D extends ElementType = AxMenuItemTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxMenuItemTypeMap<P & AxMenuItemCustomProps, D>, D>;

const StyledAxMenuItem = styled(MuiMenuItem, {
  name: 'AxMenuItem'
})<AxMenuItemProps>(({
  theme
}) => {
  return {
    padding: `${ theme.uiKit.spacingS } ${ theme.uiKit.spacingM }`,
    '& + .MuiDivider-root': {
      marginTop: 0,
      marginBottom: 0
    },
    '.MuiListItemIcon-root': {
      color: theme.uiKit.colorGrey80,
      minWidth: 'unset'
    },
    '.MuiListItemText-root': {
      color: theme.uiKit.colorGrey70
    }
  };
});

/**
 * AxMenuItem is a wrapper around the MUI `MenuItem` component, and used to create a menu item within a `AxMenu` component.
 *
 * To compose the `AxMenuItem` component, you can use the `AxListItemIcon`, `AxListItemText`, and other `AxListItem` components.
 *
 * ### Links
 * - • [MUI MenuItem | API](https://mui.com/material-ui/api/menu-item/)
 */
export const AxMenuItem: MuiOverridableComponent<AxMenuItemTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxMenuItemProps, ref: Ref<HTMLLIElement>) => {
  const buttonProps = {
    ...otherProps
  };

  const theme = useTheme();

  // This code checks if the children of the AxMenuItem component include an AxFormControlLabel component.
  // If an AxFormControlLabel component is found, it modifies the buttonProps object to update the styling.
  // The padding and flexBasis properties are adjusted to align with the UI Kit styling.
  const hasFormControlLabelChild = find(Children.toArray(children), (child) => {
    return isValidElement(child) && child.type === AxFormControlLabel;
  }) != null;

  if (hasFormControlLabelChild) {
    merge(buttonProps, {
      sx: {
        padding: 0,
        '& > .MuiFormControlLabel-root': {
          padding: `${ theme.uiKit.spacingXS } ${ theme.uiKit.spacingS }`,
          flexBasis: '100%'
        }
      }
    });
  }

  return (
    <StyledAxMenuItem
      { ...buttonProps }
      ref={ ref }
      disableRipple
      disableTouchRipple
    >
      { children }
    </StyledAxMenuItem>
  );
});

export default AxMenuItem;
