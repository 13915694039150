const Backbone = require('Backbone');

class MessageCollection extends Backbone.Collection {
  apiEndpoint() {
    return '/messages';
  }

  parse(data) {
    return data.messages != null ? data.messages : data.entities;
  }
}

module.exports = MessageCollection;
