const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');

// Helper functions
const isTopicObjective = (objective) => {
  return objective.getType() === GuidedLearningObjectiveType.TOPIC_OBJECTIVE;
};

// Pure free functions that can be configured
const categoryHasStartableItem = (availableItems) => {
  return availableItems.hasStartableItems();
};

const objectiveIsStartable = function(objective) {
  if (isTopicObjective(objective)) {
    return objective.isStartable();
  }
  return objective.canBegin();
};

class ContextualBuilder {
  constructor(session) {
    this.session = session;
    this.hasActionableItemsInSummary = () => {
      return true;
    };
    this.canActionOnObjective = () => {
      return true;
    };
  }

  // Return the interface with everything we need
  build() {
    return {hasActionableItemsInSummary: this.hasActionableItemsInSummary, canActionOnObjective: this.canActionOnObjective};
  }


  withStartableItem() {
    this.hasActionableItemsInSummary = categoryHasStartableItem;
    this.canActionOnObjective = objectiveIsStartable;
    return this;
  }
}

module.exports = (session) => {
  return new ContextualBuilder(session);
};
