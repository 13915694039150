const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'ARCHIVE_COMMUNITY',
  'AUTHOR_ACCESS', // The user has sufficient access to author an article of any page type in the community
  'AUTHOR_PAGETYPE', // The user has sufficient access to author an article of a specific page type in the community
  'DELETE_COMMUNITY',
  'EDIT_COMMUNITY_SETTINGS',
  'MOVE_ACCESS',
  'PUBLISH_ACCESS',
  'VIEW_COMMUNITY',
  'VIEW_REPORTS'
]);
