const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

require('@common/libs/behaviors/resizable/Resizable');
require('jquery.velocity');

const CONTENT_CONTAINER_EXPANDED_CLASS = 'read-more__content--expanded';

require('@common/components/readMore/ReadMore.less');

// Override style of previewGradient for a different gradient
class ReadMoreView extends Marionette.LayoutView {
  getTemplate() {
    return require('./ReadMoreView.html');
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  regions() {
    return {
      contentRegion: '.read-more__overflow-content'
    };
  }

  templateHelpers() {
    return {
      enablePreviewGradient: this.enablePreviewGradient
    };
  }

  initialize(options = {}) {
    super.initialize(options);

    ({
      enablePreviewGradient: this.enablePreviewGradient = false,
      shouldShowMoreContent: this.shouldShowMoreContent = false
    } = options);
  }

  events() {
    return {
      'click @ui.readButton': 'toggleContent'
    };
  }

  ui() {
    return {
      content: '.read-more__overflow-content',
      contentContainer: '.read-more__content--container',
      readButton: '.read-more__button',
      previewGradient: '.read-more__preview-gradient'
    };
  }

  toggleContent() {
    this.shouldShowMoreContent = !this.shouldShowMoreContent;
    this.showContent();
  }

  showContent() {
    this.ui.readButton.text(this.shouldShowMoreContent ? I18n.t('general.readLess') : I18n.t('general.readMore'));

    const $contentContainer = this.ui.contentContainer;
    const $previewGradient = this.ui.previewGradient;

    if (this.shouldShowMoreContent) {
      $contentContainer.addClass(CONTENT_CONTAINER_EXPANDED_CLASS);
      $previewGradient.velocity('fadeOut', {
        duration: 'fast',
        complete: function() {
          triggerResize(true);
        }
      });
    } else {
      $contentContainer.removeClass(CONTENT_CONTAINER_EXPANDED_CLASS);
      $previewGradient.velocity('fadeIn', {
        duration: 'fast',
        complete: function() {
          triggerResize(true);
        }
      });
    }
  }

  onAttach() {
    this.containerHeight = this.getHeight(this.ui.contentContainer);

    this.resetPreview();
    if (this.shouldShowMoreContent) {
      this.showContent();
    }
  }

  onResize() {
    this.resetPreview();
  }

  resetPreview() {
    this.fullHeight = this.getHeight(this.ui.content);
    this.previewHeight = this.getHeight(this.ui.contentContainer);

    if (this.fullHeight > this.previewHeight) {
      this.ui.readButton.show();

      if (!this.shouldShowMoreContent) {
        this.ui.previewGradient.show();
      }
    } else {
      if (this.previewHeight > this.containerHeight) {
        this.ui.readButton.show();
      } else {
        this.ui.readButton.hide();
      }

      this.ui.previewGradient.hide();
    }
  }
}

module.exports = ReadMoreView;
