const _ = require('underscore');
const logging = require('logging');
const Marionette = require('Marionette');
const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');
const VideoEmptyView = require('@common/components/video/VideoPlayerView/VideoEmptyView');
const VideoPackageModel = require('@common/components/video/VideoPackageModel');
require('@common/components/mediaPreview/video/VideoPreview.less');

class VideoPreview extends Marionette.LayoutView {
  initialize(options = {}) {
    ({
      maxDimensions: this.maxDimensions,
      videoPlayerOptions: this.videoPlayerOptions = {},
      model: this.model
    } = options);
    this.file = this.model.get('originalFile');
  }

  getTemplate() {
    return '<div class="js-region-video-player"></div>';
  }

  regions() {
    return {
      videoRegion: '.js-region-video-player'
    };
  }

  className() {
    return 'video-preview fit-parent';
  }

  events() {
    return {
      click: (e) => {
        return e.stopPropagation();
      }
    };
  }

  onRender() {
    let videoPackage;
    if (this.model?.isDoneTranscoding()) {
      videoPackage = { media: this.model?.toJSON?.() ?? this.model };
    } else {
      const url = this.file.acquireUrl();

      videoPackage = new VideoPackageModel({
        url: url,
        media: this.model?.toJSON?.() ?? this.model
      });
    
      // The type property is populated by calling isValidVideoInstance, which
      // in turn calls model.getType. So it is technically not necessary to call videoPackage.getType here, but having
      // this line makes things easier to debug with a breakpoint. If the URL isn't valid, type will remain undefined.
      videoPackage.getType();
    }

    if (VideoPlayerFactory.isValidVideoInstance({videoPackage: videoPackage})) {

      this._videoPlayer = VideoPlayerFactory.createPlayerInstance({
        videoPackage: videoPackage,
        viewOptions: {
          maxWidth: this.maxDimensions.get('maxWidth'),
          maxHeight: this.maxDimensions.get('maxHeight'),
          ...this.videoPlayerOptions
        }
      });

      this._videoPlayer.on('player:load', _.bind(this.triggerMethod, this, 'load'));
      this._videoPlayer.on('player:error', _.bind(this.triggerMethod, this, 'error'));

      this.videoRegion.show(this._videoPlayer);
      return;
    }

    this.videoRegion.show(new VideoEmptyView());
    this.triggerMethod('load');
  }

  onError(event) {
    if (event) {
      logging.error(event.target || event.error);
    }
    this.file.releaseUrl();
  }

  onDestroy() {
    this.file.releaseUrl();
  }
}

module.exports = VideoPreview;
