import I18n from '@common/libs/I18n';
import { type Translatable } from './I18nTypes';

export const DEFAULT_TAG: keyof JSX.IntrinsicElements = 'span';

export const ATTRIBUTE_PREFIX = 'i18n-';

export const translateItem = (item: Translatable ): string => {
  if (typeof item === 'string') {
    return I18n.t(item);
  }
  return I18n.t(item.key, item.values);
};

export const getTranslatedProperties = (tagProps: Record<string, unknown>): Record<string, unknown> => {
  const properties: Record<string, unknown> = {};
  Object.keys(tagProps).forEach((key) => {
    if (key.startsWith(ATTRIBUTE_PREFIX)) {
      const keyWithoutPrefix = key.replace(ATTRIBUTE_PREFIX, '');
      const value = tagProps[key] as Translatable;
      const propertyValue = translateItem(value);
      properties[keyWithoutPrefix] = propertyValue;
    } else {
      properties[key] = tagProps[key];
    }
  });
  return properties;
};

