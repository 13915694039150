const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

class GameChallengeHowToModal extends UIKit.View {
  template = _.tpl(require('../templates/GameChallengeHowToModal.html'));

  className() {
    return 'modal';
  }

  events() {
    return {'click .axon-button.accept': 'close'};
  }

  initialize() {
    this.close = this.close.bind(this);
  }

  render() {
    let left;
    const tenantStore = TenantPropertyProvider.get();
    const gameChallengeWager = (left = tenantStore.getProperty('gameChallengeWager')) != null ? left : 0;
    this.$el.html(this.template({gameChallengeWager}));
    this.actionBar.setActionBar({
      buttons: {
        type: 'customText',
        text: I18n.t('selectGame.gameChallenge.howTo.accept'),
        onClick: this.close
      }
    });
    return this;
  }

  close() {
    return this.dismiss();
  }
}

module.exports = GameChallengeHowToModal;
