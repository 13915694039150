// Module
var code = `<div class="base-card__wrapper base-card__wrapper--whole">
  <div class="grid-banner-region"></div>
  <div class="article-type-icon icon-<%- type %>" aria-hidden="true"></div>
  <div class="base-card__image article-background"></div>
  <div class="base-card__wrapper--content ax-grid ax-grid--no-gutter">
    <div class="list-banner-region"></div>
    <div class="base-card__wrapper base-card__wrapper--upper js-base-card__wrapper--upper <%- topRegionClass %>" <%- topRegionAttributes %>>
      <div class="title-region">
        <div class="base-card__title"></div>
      </div>
    </div>
    <div class="base-card__wrapper base-card__wrapper--lower"></div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;