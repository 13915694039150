const $os = require('detectOS');

function addTodayActivitiesRegionStyles(view, isQuickLinksOnHub) {
  const todaysActivitiesRegion = view.$el.find('.titled-section__region').first();
  const hasHomePageUserStats = view.$el.find('.home-page-layout__region.stats').first().length;

  if (!isQuickLinksOnHub || $os.mobile) {
    // When quick links are displayed on hub, Today's Activities are shifted down below the branded background
    // image and there is no need for branding the text
    // HOWEVER, sometimes if there is not much to do in Today's Activities, the second region might still be over the
    // branded background image, so we need to add a second class to the second region so that the contrast of the second
    // region title still meets accessibility standards
    const titledSectionRegions = view.$el.find('.titled-section__region').has('.titled-section__region--title');
    $('.titled-section__region--title', titledSectionRegions)
      .first()
      .addClass('titled-section__region--overlay');
    titledSectionRegions
      .eq(1)
      .addClass('titled-section__region--second');
  } else if (!hasHomePageUserStats) {
    // when there are no stats displayed, we need to add extra margin to the Today's Activities
    todaysActivitiesRegion.addClass('titled-section__region--extra-margin');
  }
}

module.exports = {
  addTodayActivitiesRegionStyles
}
