const $os = require('detectOS');

const { Model } = require('Backbone');
const {
  omit,
  uniqueId,
  compact
} = require('underscore');
const { Behavior } = require('Marionette');

const I18n = require('@common/libs/I18n');

const Behaviors = require('@common/libs/behaviors/Behaviors');
require('@common/libs/behaviors/resizable/Resizable');

const { getSlidesPerViewParams } = require('@common/libs/behaviors/carousel/CarouselHelpers');

const Swiper = require('Swiper');

const carouselOptions = ['target', 'id', 'carouselMessage', 'allowOverflow', 'slidesPerViewCountConfig'];

const TRANSITION_DURATION = 200;

const toggleButtonHidden = ($button, toggle, fade = true) => {
  const display = toggle ? 'none' : '';

  if (fade) {
    const animation = toggle ? 'fadeOut' : 'fadeIn';

    $button.velocity('stop');
    $button.velocity(animation, {
      display,
      duration: TRANSITION_DURATION,
      complete: () => {
        $button.css('opacity', '');
      }
    });
  } else {
    $button.css({ display });
  }
};

const getContainerClass = ({ containerClass } = {}) => {
  return compact(['swiper-container', containerClass]).join(' ');
};

Behaviors.Carousel = class Carousel extends Behavior {
  static CONTAINER_CLASSES = {
    OVERFLOW: 'swiper-container--overflow',
    STANDARD_GAP: 'swiper-container--gap-std'
  }

  defaults() {
    const defaults = {
      // default Carousel options
      target: '',
      id: uniqueId('carousel'),
      carouselMessage: '',
      containerClass: '',
      slidesPerViewCountConfig: [],

      // default Swiper options
      init: false,
      slidesPerView: 2.5,
      keyboard: true,
      mousewheel: {
        forceToAxis: true,
        releaseOnEdges: true,
        sensitivity: .3
      },
      freeMode: true,
      freeModeMomentumRatio: .15,
      freeModeMomentumVelocityRatio: 1.2,
      freeModeSticky: true,
      watchOverflow: true,
      grabCursor: true,
      observer: true,
      observeSlideChildren: true,
      simulateTouch: false,
      a11y: {
        nextSlideMessage: I18n.t('carousel.nextSlide'),
        prevSlideMessage: I18n.t('carousel.prevSlide')
      },
      breakpoints: {
        679: {
          slidesPerView: 1.1
        }
      }
    };

    if ($os.mobile) {
      defaults.navigation = false;
    } else {
      defaults.navigation = {
        nextEl: '.button-next',
        prevEl: '.button-prev',
        hiddenClass: 'hidden',
        disabledClass: 'disabled'
      };
    }

    return defaults;
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  initialize() {
    this.carouselOptions = omit(this.options, carouselOptions);
    this.carouselState = new Model();

    this.isClickEventInQueue = false;
  }

  ui() {
    return {
      target: this.getOption('target')
    };
  }

  events() {
    return {
      'focusin .swiper-slide': 'onFocusInSlide',
      'mousedown .swiper-slide': 'onMouseDownSlide',
      'mouseup .swiper-slide': 'onMouseUpSlide'
    };
  }

  onRender() {
    // A11y stuff here is based on input from: https://github.com/nolimits4web/swiper/issues/3149
    const $target = this._getCarouselTarget();
    $target.addClass('swiper-wrapper');

    this._convertChildrenToSlides();
  }

  onObserverUpdate() {
    this._updateSlideCount(this._getSlideCount());
    this._convertChildrenToSlides();
    this._updateCarousel();
  }

  onAttach() {
    const $target = this._getCarouselTarget();
    const carouselId = this.getOption('id');

    // A11y stuff here is based on input from: https://github.com/nolimits4web/swiper/issues/3149
    this.$container = $('<section>', {
      id: carouselId,
      class: getContainerClass(this.options),
      'aria-label': this.getOption('carouselMessage'),
      'aria-live': (this.getOption('autoPlay') ? 'off' : 'polite')
    });

    $target.wrap(this.$container);
    $target.before(`<button class="button-prev" aria-controls="${ carouselId }"><span class="icon-chevron_left"></span></button>`);
    $target.after(`<button class="button-next" aria-controls="${ carouselId }"><span class="icon-chevron_right"></span></button>`);

    this.$nextButton = $target.siblings('.button-next');
    this.$prevButton = $target.siblings('.button-prev');

    this.carousel = new Swiper(this._getCarouselTarget().parent()[0], this.carouselOptions);

    this.listenTo(this.carouselState, 'change:prevHidden', (state, prevHidden, options = {}) => {
      toggleButtonHidden(this.$prevButton, prevHidden, options.fade);
    });

    this.listenTo(this.carouselState, 'change:nextHidden', (state, nextHidden, options = {}) => {
      toggleButtonHidden(this.$nextButton, nextHidden, options.fade);
    });

    this.listenTo(this.carouselState, 'change:slideCount', (state, slideCount) => {
      this._updateCarouselSlidesPerViewBreakpoints(slideCount);
      this._convertChildrenToSlides();
      this._updateCarousel();
    });

    this._updateNavVisibility(false);
    this._updateSlideCount(this._getSlideCount());

    this.carousel.on('breakpoint', this.onBreakpointChange.bind(this));
    this.carousel.on('progress', this.onProgress.bind(this));
    this.carousel.on('observerUpdate', this.onObserverUpdate.bind(this));

    this.carousel.init();
  }

  onProgress(progress) {
    if (this.prevProgress != null) {
      const wasEnd = this.prevProgress >= 1;
      const wasBeginning = this.prevProgress <= 0;
      const isEnd = progress >= 1;
      const isBeginning = progress <= 0;
      const diff = Math.abs(this.prevProgress - progress);
      const reachEnd = !wasEnd && isEnd;
      const reachBeginning = !wasBeginning && isBeginning;

      if (diff > 0 || reachEnd || reachBeginning) {
        this._updateNavVisibility();
      }
    }

    this.prevProgress = progress;
  }

  onResize() {
    this._updateCarousel();
  }

  onFocusInSlide(e) {
    if (!this.isClickEventInQueue) {
      this.carousel.slideTo($(e.currentTarget).index());
    }
  }

  onMouseDownSlide() {
    this.isClickEventInQueue = true;
  }

  onMouseUpSlide() {
    this.isClickEventInQueue = false;
  }

  onBreakpointChange() {
    this._updateNavVisibility(false);
  }

  onBeforeDestroy() {
    // XXX - Fix for when the mouse swipe timeout still fires after the carousel is already destroyed. Filed a ticket:
    // https://github.com/nolimits4web/swiper/issues/3189
    try {
      clearTimeout(this.carousel.mousewheel.timeout);
      this.carousel.navigation.update = () => {};
      this.carousel.destroy();
      this.carousel = null;
    } catch (e) { /* nomnomnom */ }
  }

  _getCarouselTarget() {
    if (this.ui.target.length > 0) {
      return this.ui.target;
    }
    return this.$el;

  }

  _updateNavVisibility(fade = true) {
    const navOff = this.carousel.params.navigation === false;
    const nextHidden = navOff || this.carousel.progress >= 1;
    const prevHidden = navOff || this.carousel.progress <= 0;

    this.carouselState.set({
      nextHidden,
      prevHidden
    }, { fade });
  }

  _getSlideCount() {
    return this._getCarouselTarget().children().length;
  }

  _updateSlideCount(slideCount) {
    this.carouselState.set('slideCount', slideCount);
  }

  _updateCarousel() {
    if (this.carousel) {
      this.carousel.update();
    }
  }

  _convertChildrenToSlides() {
    const $children = this._getCarouselTarget().children();
    const childCount = $children.length;

    $children.each((index, child) => {
      this._convertElToSlide($(child), index, childCount);
    });
  }

  _convertElToSlide($el, index, total) {
    $el.addClass('swiper-slide');
    $el.attr('aria-label', I18n.t('general.partOfTotal', {
      part: index + 1,
      total: total
    }));
  }

  _updateCarouselSlidesPerViewBreakpoints(slideCount) {
    const slidesPerViewBreakpoints = this.getOption('slidesPerViewCountConfig');

    if (this.carousel && slidesPerViewBreakpoints) {
      const slidePerViewBreakpointsOptions = getSlidesPerViewParams({
        slidesPerViewBreakpoints,
        slideCount
      });

      // XXX - Ensure the various params are all updated and the currentBreakpoint is unset so values are updated
      // properly when the carousel updates.
      this.carousel.originalParams = Object.assign({}, this.carousel.originalParams, slidePerViewBreakpointsOptions);
      this.carousel.params = Object.assign({}, this.carousel.params, slidePerViewBreakpointsOptions);
      this.carousel.currentBreakpoint = undefined;
    }
  }
};

module.exports = Behaviors.Carousel;
