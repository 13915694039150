const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

class MilestoneItemView extends ItemView {

  getTemplate() {
    return require('@training/apps/training/templates/assessments/MilestoneItemView.html');
  }

  templateHelpers() {
    const helpers = {
      pointsMessage: '',
      pointsClass: ' hidden'
    };
    const details = this.model.get('objectiveDetails');
    const pointsTotal = (details.pointsEarned + details.bonusPointsEarned) || 0;

    if (pointsTotal) {
      helpers.pointsMessage = pointsTotal > 1
        ? I18n.t('tasks.summary.rewardPointsEarnedPlural', {
          numRewardPoints: pointsTotal
        })
        : I18n.t('tasks.summary.rewardPointsEarnedSingular');

      helpers.pointsClass = ''
    }

    return helpers;
  }

  className() {
    return 'next-milestone ax-grid__col--12 ax-grid__col--l-6';
  }
}

module.exports = MilestoneItemView;
