import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitTooltip(cssVars: CSSStyleDeclaration) {
  return {
    tooltip: {
      textAlign: getCssValue('--ax-tooltip-textAlign', cssVars),
      fontSize: getCssValue('--ax-tooltip-fontSize', cssVars),
      lineHeight: getCssValue('--ax-tooltip-lineHeight', cssVars),
      backgroundColor: getCssValue('--ax-tooltip-background-color', cssVars),
      padding: getCssValue('--ax-tooltip-padding', cssVars)
    }
  };
}
