// Module
var code = `<div class="page-content">
  <div class="select-assessment clearfix">
    <div class="assessment-right">
      <% if (externalLink) { %>
        <h2 class="external-link-text"><%- t('externalLink.launch') %></h2>
      <% } %>

      <div class="options-container clearfix">
        <div class="topic-tile clearfix">
          <div class="tile-top">
            <div class="topic">
              <% if (externalLink) { %> <h3 <% } else { %> <h2 <% } %> class="topic-hierarchy-details external-link-name">
                <%- packageName %>
              <% if (externalLink) { %> </h3> <% } else { %> </h2> <% } %>

              <p class="external-link-description"><%= descriptionText %></p>
            </div>
          </div>

          <% if (externalLink) { %>
          <a id="start" class="axon-button floatright external-link-button" href="<%- externalLink.url %>" target="_new"
            aria-label="<%- t('externalLink.launchExternalLink') + ' ' + packageName %>"><%- t('externalLink.start') %></a>
          <% } else if (isPDFModule) { %>
            <button id="start" class="axon-button floatright external-link-button"
            aria-label="<%- t('externalLink.launchExternalLink') + ' ' + packageName %>"><%- t('general.start') %>
            </button>
          <% } else if (showLaunchButton) { %>
          <button id="start" class="axon-button floatright external-link-button"
            aria-label="<%- t('externalLink.launchExternalLink') + ' ' + packageName %>"><%- t('general.launch') %>
          </button>
          <% } %>
        </div>
      </div>
    </div>
    <% if (isPDFModule) { %>
      <div class="pdf-compliance-region hidden">
        <%= axCheckbox({
          id: 'pdf-compliance-checkbox',
          label: t('pdf.complianceMessage')
        }) %>
      </div>
    <% } %>
  </div>
</div>
`;
// Exports
module.exports = code;