// Module
var code = `<div class="attachment-card__image <%= icon %>"></div>
<div class="attachment-card__filename"><%= name %></div>
<% if (canDownload) { %>
  <%= downloadLink(uuid, name, {
    class: "attachment-card__download-link",
    "aria-label": t('broadcastMessage.dzArticleMediaDownloadWhich', {name: name}),
    html: linkHtml
  }) %>
<% } %>
`;
// Exports
module.exports = code;