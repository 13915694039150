const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const {
  CollectionView,
  ItemView
} = require('Marionette');

const I18n = require('@common/libs/I18n');

const PageInsightsEngagementTileView = require('@training/apps/insights/engagement/PageInsightsEngagementTileView');

class PageInsightsEngagementTileController extends LayoutController {
  initialize(options = {}) {
    ({
      model: this.model
    } = options);

    this.collection = this.model.get('engagementsCollection');

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: PageInsightsEngagementTileView,
      model: this.model
    };
  }

  regionControllers() {
    return {
      engagementsRegion: {
        viewDefinition: {
          ViewClass: CollectionView,
          collection: this.collection,
          className: 'page-insights-engagement__wrapper--content highlights ax-grid ax-grid__col--12',
          childView: ItemView,
          childViewOptions: (model) => {
            return {
              templateHelpers: {
                engagements: I18n.t('discover.insights.' + model.get('key')),
                counts: model.get('value')
              },
              className: 'ax-grid__col--6 ax-grid__col--m-4',
              template: `
              <div class="highlight subhighlight">
                <div class="wrap clearfix">
                  <div class="ax-font__display ax-font--no-margin"> <%- counts %> </div>
                  <div class="ax-font__caption ax-font--secondary"> <%- engagements %></div>
                </div>
              </div>
          `
            };
          }
        }
      }
    };
  }
}

module.exports = PageInsightsEngagementTileController;
