const { LayoutView } = require('Marionette');

class TagGroupFiltersIndexView extends LayoutView {
  initialize() {
    this.tagGroupFilterList = this.options.tagGroupFilterList;
  }

  get template() {
    return require('@training/apps/search/communitiesManagement/tagGroupFilters/index/TagGroupFiltersIndexView.html');
  }

  className() {
    return 'tag-group-filters-index';
  }

  regions() {
    return {
      tagGroupFilterList: '.js-tag-group-filters-list-region',
      tagGroupFilterCount: '.js-count'
    };
  }

  ui() {
    return {
      newButton: '.js-new-button'
    };
  }

  events() {
    return {
      'click @ui.newButton': '_newFilterButtonClick'
    };
  }

  _newFilterButtonClick() {
    this.trigger('item:new');
  }
}

module.exports = TagGroupFiltersIndexView;
