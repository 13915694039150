const Backbone = require('Backbone');
const SelectOptionModel = require('@common/libs/selectOptionCollection/SelectOptionModel');

class SelectOptionCollection extends Backbone.Collection {
  model(attrs, options) {
    return new SelectOptionModel(attrs, options);
  }

  getAttrOfId(id, attr) {
    return this.get(id).get(attr);
  }
}

module.exports = SelectOptionCollection;
