const { LayoutView } = require('Marionette');
const Backbone = require('Backbone');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

require('@common/libs/behaviors/scrollable/Scrollable');
require('@common/libs/behaviors/pressedHoverButton/PressedHoverButtonBehavior');
require('@common/libs/behaviors/resizable/Resizable');

require('jquery.velocity');

const MENU_ANIMATION_DURATION = 200;
const MENU_BAR_SLIDE_IN_ANIMATION_DURATION = 160;

class BottomMenuLayoutView extends LayoutView {

  get attributes() {
    return {
      role: 'navigation'
    };
  }

  get className() {
    return 'bottom-menu hidden';
  }

  get template() {
    return `
      <div class="js-overflow-menu-region bottom-menu-overflow-menu hidden" aria-label="<%- t('menu.moreButton') %>"></div>
      <div class="bottom-menu-wrapper">
        <div class="js-menu-region parent-height"></div>
        <button class="js-overflow-button bottom-menu-overflow-button" aria-label="<%- t('menu.moreButton') %>" aria-label="<%- t('menu.moreButton') %>" aria-haspopup="true" aria-expanded="false">
          <span class="menu-item">
            <span class="item-icon icon-ellipsis_horizontal"></span>
            <span class="item-text"><%- t('menu.moreButton') %></span>
          </span>
        </button>
      </div>
    `;
  }

  regions() {
    return {
      menu: '.js-menu-region',
      overflowMenu: '.js-overflow-menu-region'
    };
  }

  ui() {
    return {
      bottomMenuWrapper: '.bottom-menu-wrapper',
      overflowButton: '.js-overflow-button',
      overflowMenu: '.js-overflow-menu-region',
      menu: '.js-menu-region'
    };
  }

  events() {
    return {
      'click @ui.overflowButton': 'onOverflowButtonClick',
      click: 'onClick'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      Scrollable: {
        scrollableContainer: '.js-overflow-menu-region'
      },
      PressedHoverButtonBehavior: {
        buttonTarget: '.js-overflow-button'
      }
    };
  }

  constructor(options = {}) {
    super(options);

    this.glChannel = Backbone.Wreqr.radio.channel('global');

    this._internalState = new Backbone.Model({
      isOpen: false,
      displayCount: 0,
      overflowCount: 0,
      minLayout: false,
      bottomMenuHeight: 0,
      hiddenLayout: false
    });

    this.listenTo(this._internalState, 'change:bottomMenuHeight', this.onChangeHeight);
    this.listenTo(this._internalState, 'change:isOpen', this.onToggleMenu);
    this.listenTo(this._internalState, 'change:displayCount change:overflowCount change:minLayout change:hiddenLayout', this.onUpdateLayout);
  }

  onResize() {
    this._internalState.set('bottomMenuHeight', this.getHeight());
  }

  onOverflowButtonClick() {
    this.toggleMenuOpen();
    return false;
  }

  onClick() {
    this.toggleMenuOpen(false);
  }

  toggleMenuOpen(toggle = !this._internalState.get('isOpen')) {
    this._internalState.set('isOpen', toggle);
  }

  onToggleMenu(model, isOpen) {
    if (isOpen) {
      this.ui.overflowMenu.toggleClass('hidden', false);
      this.$el.toggleClass('menu-open', true);
      this.ui.overflowButton.attr('aria-expanded', true);
    } else {
      setTimeout(() => {
        if (this.isDestroyed) {
          return;
        }

        this.ui.overflowMenu.toggleClass('hidden', true);
        this.$el.toggleClass('menu-open', false);
        this.ui.overflowButton.attr('aria-expanded', false);
      }, MENU_ANIMATION_DURATION);
    }

    const topValue = isOpen ? '0' : '100%';
    this.ui.overflowMenu.velocity({ top: topValue }, { duration: MENU_ANIMATION_DURATION });

    // this.glChannel.vent.trigger('open:bottom:menu', isOpen); TEMP - hide bottom menu during training
  }

  updateLayout({
    displayCount = 0,
    overflowCount = 0,
    minLayout = false,
    hiddenLayout = false
  } = {}) {
    this._internalState.set({
      displayCount,
      overflowCount,
      minLayout,
      hiddenLayout
    });
  }

  onUpdateLayout(model) {
    const {
      displayCount = 0,
      overflowCount = 0,
      minLayout = false,
      hiddenLayout = false
    } = model.toJSON();

    const total = displayCount + Math.min(overflowCount, 1);

    if (!total) {
      return;
    }

    const percentDisplay = displayCount / total * 100;

    this.ui.overflowButton.toggleClass('hidden', overflowCount === 0);
    this.ui.menu.width(`${ percentDisplay }%`);

    this.$el.toggleClass('hidden', !displayCount && !overflowCount);

    this.$el.toggleClass('bottom-menu-minimal', minLayout);

    setTimeout(() => {
      if (this.isDestroyed) {
        return;
      }

      this.$el.toggleClass('hidden', hiddenLayout);
    }, MENU_BAR_SLIDE_IN_ANIMATION_DURATION);
    this.$el.velocity({ bottom: hiddenLayout ? '-55px' : '0%' }, { duration: MENU_BAR_SLIDE_IN_ANIMATION_DURATION });

    BrowserHelpers.triggerResize(true);
  }

  onChangeHeight(model, bottomMenuHeight) {
    this.ui.overflowMenu.css('height', `calc(100% - ${ bottomMenuHeight }px)`);
  }
}

module.exports = BottomMenuLayoutView;
