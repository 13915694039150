const { LayoutView } = require('Marionette');

class FlashWrapperLayout extends LayoutView {

  className() {
    return 'flash-wrapper-layout relative fit-parent';
  }

  getTemplate() {
    return `
      <div class="flash-wrapper-content-region fit-parent"></div>
      <div class="flash-region" aria-live="assertive" role="alert"></div>
    `;
  }

  regions() {
    return {
      viewportRegion: '.flash-wrapper-content-region',
      flashRegion: '.flash-region'
    };
  }
}

module.exports = FlashWrapperLayout;
