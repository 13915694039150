const _ = require('underscore');
const Backbone = require('Backbone');
const Marionette = require('Marionette');
const Sync = require('@common/libs/Sync');
const Cocktail = require('backbone.cocktail');

const Form = require('@common/components/forms/Form');
const LoadingCollectionViewMixin = require('@common/components/loading/collection_view/LoadingCollectionViewMixin');

class OptionView extends Marionette.ItemView {
  tagName() {
    return 'li';
  }

  constructor(options = {}) {
    super(options);

    ({
      toOptionCheckbox: this.toOptionCheckbox
    } = options);
  }

  templateHelpers() {
    return Object.assign({
      data: {
        itemId: '<%- id %>'
      }
    }, this._getModelTemplateOptions());
  }

  _getModelTemplateOptions() {
    if (this.toOptionCheckbox != null) {
      return this.toOptionCheckbox(this.model);
    }
    return this.model.toOptionCheckbox != null ? this.model.toOptionCheckbox() : this.model.toOption();
  }
}

class OptionCollectionView extends Marionette.CollectionView {
  get childView() {
    return OptionView;
  }

  collectionEvents() {
    return {
      request: 'onCollectionRequest',
      sync: 'closeCollectionLoadingView',
      error: 'closeCollectionLoadingView'
    };
  }

  onRender() {
    if (this.collection.isXHRPending(Sync.Method.READ)) {
      this.onCollectionRequest();
    }
  }

  onCollectionRequest() {
    if (this.collection.length === 0) {
      this.showCollectionLoadingView();
    }
  }
}

Cocktail.mixin(OptionCollectionView, LoadingCollectionViewMixin);

Form.Editor.Checkboxes = class Checkboxes extends Form.Editor {
  constructor(options) {
    super(options);
    this.setOptions = this.setOptions.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.render = this.render.bind(this);
    this.getValue = this.getValue.bind(this);
    this.setValue = this.setValue.bind(this);
    this.search = this.search.bind(this);
  }

  preinitialize(options) {
    super.preinitialize(options);
    this.default = [];
  }

  initialize(optionsArg = {}) {
    const {options} = optionsArg;
    this.shallowValue = (this.$el.attr('data-shallow') != null);

    if (options != null) {
      this.setOptions(options);
    }

    this.setValue();
  }

  setOptions(options) {
    if ((options == null)) {
      throw new Error('Checkboxes options was undefined/null!');
    }

    if (options instanceof Backbone.Collection) {
      this.setCollection(options);
    } else {
      this.toOptionCheckbox = options.toOptionCheckbox;
      this.setCollection(options.collection);
    }
  }

  setCollection(options) {
    if (!(options instanceof Backbone.Collection)) {
      throw new Error('Checkboxes options required to be instance of Backbone.Collection!');
    }

    this.collection = options;
    this.renderOptions();

    this.listenTo(this.collection, 'reset update', () => {
      this.renderCheckedOptions();
    });
  }

  renderOptions() {
    this.$el.empty();
    const optionCollectionViewOptions = Object.assign({}, {
      el: this.$el,
      collection: this.collection
    }, this.getChildOptionConfigs());
    this.optionCollectionView = this._getOptionCollectionView(optionCollectionViewOptions);
    this.optionCollectionView.render();
  }

  _getOptionCollectionView(options) {
    return new OptionCollectionView(options);
  }

  getChildOptionConfigs() {
    return {
      childViewOptions: {
        template: this.optionTemplate || '<%= axCheckbox(obj) %>',
        toOptionCheckbox: this.toOptionCheckbox
      }
    };
  }

  renderCheckedOptions() {
    if (this.collection && this.collection.length && this.value.length) {
      const ids = this.shallowValue ? this.value : _.pluck(this.value, 'id');

      this.$(':checkbox').each((index, element) => {
        const parsedValue = this._getSerializedValueFromElement(element);
        const checked = Array.from(ids).includes(parsedValue);
        $(element).prop('checked', checked);
      });
    }
  }

  render() {
    this.setValue(this.value);
    this.trigger('change', this);
    return this;
  }

  getValue() {
    const value = [];

    this.$(':checked').each((index, element) => {
      const id = this._getSerializedValueFromElement(element);

      if (this.shallowValue) {
        value.push(id);
      } else {
        const item = this.collection.get(id);
        if (item != null) {
          value.push(item.toJSON());
        }
      }
    });
    return value;
  }

  setValue(value = this.default) {
    this.value = value;
    this.renderCheckedOptions();
  }

  _getSerializedValueFromElement(element) {
    const elementValue = $(element).val();
    let parsedValue = parseInt(elementValue, 10);
    if (isNaN(parsedValue)) {
      parsedValue = elementValue;
    }
    return parsedValue;
  }

  search(query) {
    this.collection.search(query);
  }

  resetCheckboxes() {
    this.$(':checkbox').each((index, element) => {
      $(element).prop('checked', false);
    });
  }
};

module.exports = Form.Editor.Checkboxes;
