// Module
var code = `<% if (isCommsEnabled) { %>
  <form class="js-community-type-toggle community-type-toggle">
    <p><%- t('communitiesManagement.community.communityTypeQuestion') %></p>
    <div class="ax-grid ax-grid__col--12">
      <ul id="community-types" class="ax-grid__col--3" data-field="communityAccessType" data-editor="RadioGroup" data-options="communityAccessTypes"></ul>
      <div class="ax-grid__col--6">
        <p id="group-type-extra-info" class="ax-font--secondary" aria-hidden="true"><%- t('communitiesManagement.community.groupTypeGrantAccess') %></p>
        <p id="team-type-extra-info" class="sax-font--secondary" aria-hidden="true"><%- t('communitiesManagement.community.teamTypeGrantAccess') %></p>
      </div>
    </div>
  </form>
  <hr aria-hidden="true">
<% } %>

<div class="js-table-region"></div>
`;
// Exports
module.exports = code;