const { Collection } = require('Backbone');

class ImportHistoryErrorsCollection extends Collection {
  apiEndpoint() {
    return 'bulkimport/errors';
  }

  parse(res) {
    return res.results;
  }
}

module.exports = ImportHistoryErrorsCollection;
