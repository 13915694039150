import { createSlice } from '@reduxjs/toolkit';

/**
 * This is currently a minimal implementation of the auth slice.
 * It only contains the isAuthenticated state and the setIsAuthenticated action
 * which are used to let the react code know that the user's session is expired and they're
 * not authenticated in any more.
 * In the future we can look to expand it's respsonsibilities.
 */

export interface AuthState {
  isAuthenticated: boolean;
}

const initialState: AuthState = { isAuthenticated: false };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated(state, action: { payload: boolean }) {
      state.isAuthenticated = action.payload;
    }
  },
  selectors: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  }
});

export default authSlice;

export const { isAuthenticated: isAuthenticatedSelector } = authSlice.selectors;

export const { setIsAuthenticated } = authSlice.actions;

export type AuthSlice = typeof authSlice;
