const { tpl } = require('underscore');

const DesktopProfilePage = require('@training/apps/training/views/ProfilePage');

const ProfileUserSettingView = require('@training/apps/mobile/views/ProfileUserSettingView');

class ProfilePage extends DesktopProfilePage {
  get template() {
    return tpl(require('../templates/ProfilePage.html'));
  }

  getDefaultResourceKey() {
    return undefined;
  }

  selectDefaultSetting(resourceKey) {
    if (resourceKey != null) {
      // hide this view and allow the detail view to be shown
      this.$el.toggleClass('hidden', true);
      super.selectDefaultSetting(resourceKey);
    }
  }

  displayView(settingView, transition) {
    settingView.listenTo(settingView, 'show', () => {
      window.app.layout.showHeaderBackButton();
    });

    settingView.listenTo(settingView, 'destroy', () => {
      window.app.layout.resetLeftHeaderView();
    });

    window.app.layout.pushDetailsView(settingView, transition);
  }

  routeToTab(settingId) {
    super.routeToTab(settingId, {});
  }

  getUserSettingDetails() {
    return new ProfileUserSettingView({
      actionBar: this.getActionBar()
    });
  }

  getActionBar() {
    return window.app.layout.detailActionBar;
  }
}

module.exports = ProfilePage;
