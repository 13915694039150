// Module
var code = `<div class="learningevent-notice"></div>
<div class="learningevent-detail ax-grid ax-grid--no-gutter">
  <div class="ax-grid__col--12">
    <h2 class="learningevent-title"><%- enrollmentTitle %></h2>
    <div class="learningevent-sessions">
      <div class="learningevent-sessionslist"></div>
    </div>
  </div>
  <div class="ax-grid__col--12 ax-grid__col--m-6">
    <h3><%- t('learningEvents.details.titles.description') %></h3>
    <p><%= enrollmentDescription %></p>
    <h3><%- t('learningEvents.details.titles.instructor') %></h3>
    <div class="instructor-container"></div>
    <div class="learningevent-link">
      <% if (enrollmentLink.hasEnrollmentLink) { %>
      <h3><%- t('learningEvents.details.titles.onlineLink') %></h3>
      <p>
        <a class="learningevent-details-link" href="<%- enrollmentLink.enrollmentLinkAddress %>" target="_blank"><%= enrollmentLink.enrollmentLinkAddress %></a>
      </p>
      <% } %>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;