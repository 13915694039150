const Backbone = require('Backbone');

const UserAgreement = require('@common/data/models/UserAgreement');

class UserAgreementList extends Backbone.Collection {
  apiEndpoint() {
    return '/agreements';
  }

  initialize(models = [], options = {}) {
    this.model = UserAgreement;
    super.initialize(models, options);
  }

  fetchPendingForActiveUser(options = {}) {
    return super.fetch(
      $.extend(true, {
        apiEndpoint: 'agreements/pending'
      }, options)
    );
  }

  parse(json) {
    return json.entities || json;
  }

  hasPendingAgreements() {
    return this.length > 0;
  }

  getNextAgreement() {
    const nextAgreement = this.first();
    if (nextAgreement) {
      return nextAgreement;
    }
    throw new Error('You can only call this method when there are pending agreements in the list');

  }
}

module.exports = UserAgreementList;
