const Backbone = require('Backbone');
const Marionette = require('Marionette');
const $os = require('detectOS');

const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const KeyCode = require('@common/data/enums/KeyCode');
const {
  AutocompleteDropdownGenericResultsProviderCollectionView,
  AutocompleteBasicNameParamView
} = require('./AutocompleteDropdownGenericResultsProviderCollectionView');

// Autocomplete
class Autocomplete extends Marionette.ItemView {

  getTemplate() {
    return false;
  }

  events() {
    return {
      'focus :input': 'onFocus',
      'blur :input': 'onBlur',
      'keydown :input': 'onKeydown',
      'keyup :input': 'onKeyup',
      'keypress :input': 'onKeypress',
      'input :input': 'onTextChange',
      'mousedown ul li': 'onMouseDown',
      'mouseout ul li': 'onMouseOut',
      'click ul li': 'onClick',
      'click .js-clear': 'onClear'
    };
  }

  initialize(options = {}) {
    ({
      delay: this.delay = 300,
      minLength: this.minLength = 3,
      src: this.src,
      nameParam: this.nameParam = 'name',
      placeholder: this.placeholder = '',
      AutocompleteItem: this.AutocompleteItem = AutocompleteBasicNameParamView,
      SuggestionCollectionView: this.SuggestionCollectionView = AutocompleteDropdownGenericResultsProviderCollectionView,
      initialQuery: this.query,
      suggestionsEnabled: this.suggestionsEnabled = true,
      hasStaticSuggestions: this.hasStaticSuggestions = false,
      showEmptySuggestions: this.showEmptySuggestions = false // show message that there are no suggestions
    } = options);

    this.previousLength = 0;
    this.baseClassName = 'auto-complete';

    if (this.placeholder === '') {
      const inputFieldPlaceholder = this.$el.find(':input').attr('placeholder');
      this.placeholder = inputFieldPlaceholder != null ? inputFieldPlaceholder : this.placeholder;
    }

    if (this.query == null) {
      this.query = '';
    }
    this.suggestions = new Backbone.Collection();
    this.selectedRow = 0;
    this.$menu = $('<div class=\'autocompletemenu\'><ul class=\'clearfix\'></ul></div>');

    this.$el.append(this.$menu);
    this.$el.addClass(this.baseClassName);
    if (this.query.length < 1) {
      this.$('.js-clear').addClass('hidden');
    }

    this.$(':input').val(this.query);
    this.$(':input').attr('placeholder', this.placeholder);

    this.$(':input').attr('aria-label', options.ariaLabel);
  }

  onRender() {
    this.$el.css({
      position: 'relative'
    });

    if (this._shouldShowSuggestionMenu()) {
      const $input = this.$(':input');
      const width = $input.parent().outerWidth();
      const height = $input.outerHeight();

      this.$menu.css({
        position: 'absolute',
        left: 0,
        top: height,
        width
      });

      if (this.collectionView == null) {
        const $ul = this.$menu.find('ul').empty();
        $ul.css({
          width
        });

        this.collection = this.suggestions;
        this.collectionView = new this.SuggestionCollectionView({
          collection: this.collection,
          AutocompleteItem: this.AutocompleteItem,
          nameParam: this.nameParam,
          el: $ul
        });

        this.collectionView.render();
      } else if (!this.hasStaticSuggestions) {
        this.collection.reset(this.suggestions.toJSON());
      }

      this.listenToOnce(this.collectionView, 'childview:click:add', this.onAddItem);

      this.$menu.show();
    } else {
      this.$menu.hide();
    }
  }

  _shouldShowSuggestionMenu() {
    const isPositiveAmountOfSuggestions = this.suggestions != null && this.suggestions.length > 0;

    return (isPositiveAmountOfSuggestions || this.showEmptySuggestions) && this.suggestionsEnabled;
  }

  onBlur() {
    // Preventing menu from showing after blur event
    clearTimeout(this.searching);
    let length = 0;
    this.$menu.find('ul li')
      .each((index, el) => {
        if ($(el).data('suggestion-select')) {
          length += 1;
        }
      });
    if (length === 0) {
      this.$menu.hide();
    }

    this._updateTextClass();
  }

  onFocus(e) {
    if (!$(e.target).is('button')) {
      this._updateTextClass();
      this.search({
        force: true
      });
    }
  }

  _updateTextClass() {
    const $input = this.$(':input');
    $input.toggleClass('has-text', $input.val().trim() !== '');
  }

  onMouseDown(e) {
    $(e.currentTarget).data('suggestion-select', true);
  }

  onMouseOut(e) {
    $(e.currentTarget).data('suggestion-select', null);
  }

  onAddItem(view, model, query) {
    this.sendAutocomplete(model, query);
  }

  sendAutocomplete(model, completedString) {
    this.query = completedString;
    this.$(':input').val(this.query);
    this.$menu.hide();
    this.trigger('search', this.query, model.id);
  }

  onKeydown(e) {
    const isMenuHidden = this.$menu.css('display') === 'none';
    // If we're showing our auto menu...
    if (!isMenuHidden && this.collectionView != null) {
      switch (e.which) {
        case KeyCode.LEFT:
        case KeyCode.UP:
          if (this.selectedRow > 1) {
            this.selectedRow--;
          } else {
            this.selectedRow = this.$menu.find('ul li').length;
          }
          this.$menu.find('ul li').removeClass('selected');
          this.$menu.find(`ul li:nth-child(${ this.selectedRow })`).addClass('selected');
          break;
        case KeyCode.RIGHT:
        case KeyCode.DOWN:
          if (this.selectedRow < this.$menu.find('ul li').length) {
            this.selectedRow++;
          } else {
            this.selectedRow = 1;
          }
          this.$menu.find('ul li').removeClass('selected');
          this.$menu.find(`ul li:nth-child(${ this.selectedRow })`).addClass('selected');
          break;
        case KeyCode.TAB:
        case KeyCode.ENTER: {
          const view = this.collectionView.children.findByIndex(this.selectedRow - 1);
          if (view != null) {
            this.sendAutocomplete(view.model, view.model.get(this.nameParam));
          }
          break;
        }
        default:
          this.search();
      }
    }
  }

  search(options = {}) {
    if (options.force == null) {
      options.force = false;
    }

    this.selectedRow = 0;
    clearTimeout(this.searching);
    const currentQuery = this.query;
    const inputValue = this.$(':input').val()
      .trim();
    this.searching = setTimeout(() => {
      if ((currentQuery !== inputValue) || (options.force === true)) {
        this.query = this.$(':input').val()
          .trim();
        this.suggestions = new Backbone.Collection();
        if (this.query.length >= this.minLength) {
          this.getSuggestionsForQuery(this.query);
        }
        this.render();
      }
    }, this.delay);
  }

  getSuggestionsForQuery(query) {
    if (this.hasStaticSuggestions) {
      this.suggestions = this.src;
    } else if (this.src) {
      const suggestionQuery = StringHelpers.escapeRegExp(query);
      const regex = new RegExp(suggestionQuery, 'i');
      this.suggestions.reset(this.src.filter((item) => {
        return item.get(this.nameParam).match(regex);
      }));
    } else {
      this.trigger('autocomplete', query);
    }
  }

  onKeyup() {
    const currentLength = this.$(':input').val().length;

    if (currentLength > 0) {
      this.$('.js-clear').removeClass('hidden');
    } else if ((currentLength === 0) && (this.previousLength > 0)) {
      this.onClear();
    }

    this.previousLength = currentLength;
  }

  onTextChange(e) {
    if ($os.ieVersion === 11) {
      // IE fires a text change when the placeholder text changes
      // This is trying to detect if a this is a placeholder change or something else.
      // If this isn't the active element, then that means that it might be a placeholder change,
      // since it's not in focus.
      // If it is active, we need to check whether there's a placeholder, and whether we've been
      // through this already.  If there is, and we have, it's probably a placeholder change, because
      // the first time through should have removed the placholder.
      const isActive = (e.target === document.activeElement);
      if (!isActive || (e.target.placeholder && e.target.has_interacted !== true)) {
        // eslint-disable-next-line camelcase
        e.target.has_interacted = isActive;
        return false;
      }
    }
    this.search();
    return undefined;
  }

  onKeypress(e) {
    if (e.which === KeyCode.ENTER) {
      if ($(e.target).hasClass('js-clear')) {
        this.onKeydown(e);
      } else {
        if (this.selectedRow === 0) {
          this.query = this.$(':input').val()
            .trim();
          this.trigger('search', this.query);
          this.$menu.hide();
        }
        return false;
      }
    }
    return undefined;
  }

  onClear() {
    this.clear();
  }

  clear() {
    this.$(':input').val('');
    this._updateTextClass();
    if (!this.hasStaticSuggestions) {
      this.$menu.hide();
    }
    this.$('.js-clear').addClass('hidden');
    this.trigger('clearSearch');
    this.$(':input').trigger('focus');
  }

  toggleSuggestionsEnabled(suggestionsEnabled) {
    this.suggestionsEnabled = suggestionsEnabled;

    if (!this.suggestionsEnabled) {
      this.$menu.hide();
    }
  }

  onDestroy() {
    clearTimeout(this.searching);
  }
}

module.exports = Autocomplete;
