const EvaluationForm = require('@training/apps/training/models/EvaluationForm');

class EvaluationLastFailedForm extends EvaluationForm {
  constructor(options = {}) {
    super(options);

    ({
      lastFailedFormId: this.lastFailedFormId
    } = options);
  }

  apiEndpoint() {
    return `/evaluations/lastFailed/${ this.lastFailedFormId }`;
  }
}

module.exports = EvaluationLastFailedForm;
