// Module
var code = `<h1 class="page-header">
    <div class="ax-container">
        <div class="page-header__content">
            <span class="page-header__icon icon-profile" aria-hidden="true"></span>
            <span class="page-header__title"><%- t('completeprofile.chooselang') %></span>
        </div>
    </div>
</h1>

<div class="ax-container">
    <div class="page-content">
        <div class="completeprofile">
            <div class="languageselect clearfix">
            <% for (var i = 0; i < languages.length; i++) { %>
                <div class="language" data-lang-code="<%- languages[i] %>">
                    <div class="radioinputcontainer" id="radio-<%- languages[i] %>" tabindex="0" role="radio" aria-checked="false">
                        <div class="radiobutton">
                            <div class="status"></div>
                        </div>
                        <label class="label" for="radio-<%- languages[i] %>"><%- I18n.languageNameNative(languages[i]) %></label>
                    </div>
                </div>
            <% } %>
            </div>
        </div>
    </div>
</div>
`;
// Exports
module.exports = code;