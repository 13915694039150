const { LayoutView } = require('Marionette');

class PageInsightsEngagementTileView extends LayoutView {
  initialize(options = {}) {
    ({
      model: this.model
    } = options);
  }

  regions() {
    return {
      engagementsRegion: '.js-engagements-region'
    };
  }

  getTemplate() {
    return `
      <div class="ax-container">
        <h3 class="page-insights-engagement__title"><%- t('discover.insights.engagement') %></h3>
        <div class="js-engagements-region"></div>
      </div>`;
  }
}

module.exports = PageInsightsEngagementTileView;
