const $os = require('detectOS');
const _ = require('underscore');
const Backbone = require('Backbone');
const logging = require('logging');
const { ItemView} = require('Marionette');

const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const AppFileHelpers = require('@common/libs/helpers/app/FileHelpers');
const VideoFileHelpers = require('@common/libs/helpers/app/VideoFileHelpers');
const FroalaRichContentHelper = require('@common/libs/froala/FroalaRichContentHelper');
const FileHelpers = require('@common/libs/file/FileHelpers');
const {
  registerFroalaCustomPlugins,
  initializeFroalaEditor,
  loadFroalaLanguage
} = require('@common/libs/froala/FroalaLoader');

const Form = require('@common/components/forms/Form');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

const CSSLoaderService = require('@common/services/cssLoader/CSSLoaderService');

const FroalaCSSPackage = require('@common/libs/froala/FroalaCSSPackage');
const FroalaConfig = require('@common/libs/froala/FroalaConfig');

require('@common/libs/behaviors/scrollable/Scrollable');

class RteView extends ItemView {
  behaviors() {
    return {
      Scrollable: {}
    };
  }

  getTemplate() {
    return require('@common/components/discover/edit/RteViewTemplate.html');
  }

  className() {
    return 'parent-height js-editor-container';
  }

  ui() {
    return {
      imageInput: '#local-image',
      videoInput: '#local-video',
      videoUploadButton: '#video-upload-button',
      videoUploadForm: '.video-upload-form',
      attachments: '.link-metadata__attachments',
      froala: '.froala-editor'
    };
  }

  events() {
    return {
      'change @ui.imageInput': 'uploadImage',
      'change @ui.videoInput': 'uploadVideo',
      'click .froala-editor a': '_onLinkClick'
    };
  }

  templateHelpers() {
    return {
      allowedVideoMimeTypes: VideoFileHelpers.allowedVideoMimeTypes.join(','),
      getWarningMangedBy: () => {
        return this.warningManagedBy;
      },
      getVideoProviders: this._getVideoProviders
    };
  }

  initialize(options = {}) {
    ({
      isMediaAttachmentsEnabled: this.isMediaAttachmentsEnabled = true,
      fileFactory: this.fileFactory,
      supportedLanguages: this.supportedLanguages,
      warningManagedBy: this.warningManagedBy,
      charCounterMax: this.charCounterMax
    } = options);

    this.imageSelector = '#local-image';
    this.videoSelector = '#local-video';

    // this is overridden in the PostRteView
    this.htmlAllowedTags = null; // indicating to Froala it should use the default set
    this.htmlAllowedAttrs = null; // indicating to Froala it should use the default set
    this.wordPasteEnabled = true;

    this.videoUploadOptions = new Backbone.Model();
    this._updateDocumentList();
    this.toolbarsEnabled = true;
    this.customToolbar = {};
    this.scrollableContainer = '.js-editor-container';

    this.listenTo(this, 'upload:done', this.resetMediaUploaderInput);

    // If the attachments and media list both change, then on saving the document we get
    // _updateDocumentList being called twice in the same instant and that causes issues inside
    // Froala. Froala tries to call the "destroy" method on something that doesn't exist. We are
    // not going to hack the Froala source, so instead... by adding a debounce to the listeners,
    // we ensure that _updateDocumentList is called, but it isn't called twice quickly in succession.
    this.listenTo(this, 'change:attachments', _.debounce(this._updateDocumentList, 200));
    this.listenTo(this.model, 'change:media update:media', _.debounce(this._updateDocumentList, 200));

    this.listenTo(this, 'upload:start', () => {
      window.app.layout.showSpinner();
    });
    this.listenTo(this, 'upload:done', () => {
      window.app.layout.hideSpinner();
    });
    this.listenTo(this, 'exception', (reason) => {
      window.app.layout.flash.error(reason);
    });

    this.loadFroala = this.loadFroala.bind(this);
    this.loadPageDetails = this.loadPageDetails.bind(this);
    this.updateFigureClass = this.updateFigureClass.bind(this);
    this.updateAltTextForImages = this.updateAltTextForImages.bind(this);
    this.removeEmptyFigure = this.removeEmptyFigure.bind(this);
    this._setupVideoForm = this._setupVideoForm.bind(this);
    this.refreshEditor = this.refreshEditor.bind(this);
    this.onEditorTextChange = this.onEditorTextChange.bind(this);
    this.updateAttachmentLinkAttrs = this.updateAttachmentLinkAttrs.bind(this);
    this.openAttachmentLink = this.openAttachmentLink.bind(this);

    CSSLoaderService.registerVersionedFactory(new FroalaCSSPackage.Factory());
  }

  loadFroala() {
    if (this.isDestroyed) {
      return undefined;
    }

    const library = new Promise((resolve, reject) => {
      FroalaConfig.load().then((FroalaEditor) => {
        if (this.isDestroyed) {
          reject(new Error('The Editor view has been destroyed before Froala finished loading'));
        }

        resolve(FroalaEditor);
      });
    });

    if (!this.css) {
      this.css = CSSLoaderService.load(FroalaCSSPackage.KEY, {pivot: '#branding'});
    }

    Promise.all([library, this.css]).then(([FroalaEditor]) => {
      loadFroalaLanguage(FroalaEditor, () => {
        registerFroalaCustomPlugins(FroalaEditor,
          {
            codeFormat: () => {
              this.editor.format.toggle('pre');
            },
            onInsertImage: () => {
              this.ui.imageInput.trigger('click');
            }
          });

        const afterInit = (editor) => {
          if (!this.isDestroyed) {
            this.editor = editor;
            this.loadPageDetails();
            this.$('.fr-toolbar').attr('role', 'toolbar');
            BrowserHelpers.triggerResize(true);
            this.onRteInitialized();
          }
        };

        const froalaConfigObject = {
          afterInit,
          mediaAttachmentsEnabled: {
            images: this.isMediaAttachmentsEnabled,
            embeddedVideos: this.isMediaAttachmentsEnabled,
            uploadedVideos: this.isMediaAttachmentsEnabled
          },
          linkList: this.documentInfoList,
          onLinkOpen: this.openAttachmentLink,
          onEditorTextChange: this.onEditorTextChange,
          onImageDisplay: this.updateFigureClass,
          onImageSetAlt: this.updateAltTextForImages,
          onImageRemoved: this.removeEmptyFigure,
          onLinkBeforeInsert: this.updateAttachmentLinkAttrs,
          onShowVideoUploadPopup: this._setupVideoForm,
          scrollableContainer: this.scrollableContainer,
          toolbarsEnabled: this.toolbarsEnabled,
          customToolbarButtons: this.customToolbarButtons,
          wordPasteEnabled: this.wordPasteEnabled,
          htmlAllowedTags: this.htmlAllowedTags,
          htmlAllowedAttrs: this.htmlAllowedAttrs,
          charCounterMax: this.charCounterMax
        };

        initializeFroalaEditor(FroalaEditor, froalaConfigObject);
      });
    });

    return null;
  }

  onRteInitialized() {
    // nom nom nom
  }

  openAttachmentLink(link) {
    const $link = $(link);
    const href = $link.attr('href');

    if (href.indexOf(FileHelpers.DOWNLOAD_FILE_PLACEHOLDER) > -1) {
      const linkMediaId = parseInt($link.attr('data-media-id'), 10);
      const linkData = _.findWhere(this.documentInfoList, {id: linkMediaId});

      if (linkData != null) {
        const {
          'data-file-uuid': uuid,
          download: fileName
        } = linkData;

        if (AppFileHelpers.getFileType(fileName) === 'pdf') {
          const media = this.model._getMediaById(linkMediaId);
          window.app.layout.showMedia(media);
        } else {
          FileHelpers.downloadFileByUUID(uuid, fileName);
        }

        return false;
      }
    }

    return true;
  }

  updateAttachmentLinkAttrs(link, text, attrs) {
    const mediaId = parseInt(attrs['data-media-id'], 10);

    if (!isNaN(mediaId)) {
      const media = this.model._getMediaById(mediaId);
      let fileType = null;
      let filename = null;

      if (media && media.originalFile && media.originalFile.originalFileName) {
        filename = media.originalFile.originalFileName;
        fileType = AppFileHelpers.getFileType(filename);
      }

      if (fileType && filename) {
        const previewFileTypes = ['pdf'];
        if (!previewFileTypes.includes(fileType)) {
          attrs['download'] = filename;
        }
      }
    }
  }

  onAttach() {
    this.loadFroala();
  }

  onDestroy() {
    this.isDestroyed = true;

    //Cancel any incomplete uploads
    if (this._isImageUploading) {
      if (this._imageXhr) {
        this._imageXhr.abort();
      }
      this.trigger('upload:done', this.ui.imageInput);
    }

    if (this._isVideoUploading) {
      if (this._videoXhr) {
        this._videoXhr.abort();
      }
      this.trigger('upload:done', this.ui.videoInput);
    }

    if (this.editor) {
      this.editor.destroy();
    }
  }

  _updateDocumentList(documentInfoList) {
    let documentInfoListCopy = documentInfoList;
    if (documentInfoList == null || !_.isArray(documentInfoList)) {
      documentInfoListCopy = this.model.getDocumentInfoList();
    } else {
      documentInfoListCopy = _.filter(documentInfoList, (media) => {
        return media.type === 'document';
      });
    }

    this.documentInfoList = documentInfoListCopy.map((documentInfo) => {
      const {
        uuid,
        originalFileName
      } = documentInfo.originalFile;

      return FileHelpers.generateDownloadLinkAttributes(uuid, originalFileName, {
        id: documentInfo.id,
        text: originalFileName,
        target: '_self',
        'data-media-id': documentInfo.id
      });
    });

    this._cleanNonExistingAttachments(documentInfoListCopy);

    setTimeout(this.refreshEditor, 0);
  }

  // Always call this with setTimeout(this.refreshEditor, 0))
  // Otherwise we can end up in a state where this view/ the editor still 'exists' (on the sync call), but by the time
  // the editor's (async) destroy method fires, the view has been destroyed.
  refreshEditor() {
    // This is the only way to dynamically update things. It's hacky, but everything(?) is cached.
    if (!this.isDestroyed && this.editor != null) {
      this._videoOptionsForm = null;
      // Required to not insert <br> if froala has empty <p> tags.
      this.tempContent = FroalaRichContentHelper.stripEmptyParagraphs(this.editor.html.get());
      this.editor.destroy();
      this.loadFroala();
    }
  }

  _cleanNonExistingAttachments(documentInfoList) {
    if (this.editor == null) {
      return;
    }
    // OriginalDocumentInfoList is not updating -- the model is not adding new items if any have been removed.
    const originalDocumentInfoList = this.model.getDocumentInfoList();
    const $html = $('<tmp></tmp>').append(this.editor.html.get());

    const removedDocuments = _.filter(originalDocumentInfoList, (item) => {
      return !_.findWhere(documentInfoList, {id: item.id});
    });

    _.each(removedDocuments, (documentInfo) => {
      const downloadUrl = documentInfo.originalFile.path || '-1';
      $html.find(`a[href="${ downloadUrl }"]`).each((ind, link) => {
        $(link).contents()
          .unwrap();
      });
    });
    this.editor.html.set($html.html());
  }

  _applyCompatabilitySettings() {
    if (!this.isMediaAttachmentsEnabled) {
      this.ui.groupImage.addClass('hidden');
      this.ui.groupVideo.addClass('hidden');
    }
  }

  _checkLanguage(result = null) {
    if (this.$videoUploadButton) {
      const hasValue = result && result.getValue();
      this.$videoUploadButton.toggleClass('disabled', !hasValue).prop('disabled', !hasValue);
    }
  }

  uploadImage() {
    if (this.ui.imageInput.val().length < 4) {
      return;
    }

    const fileObj = {
      file: this.$(this.imageSelector),
      fileType: 'image'
    };

    if (fileObj.file) {
      const simpleFileObject = this.fileFactory(fileObj.fileType, {
        'file-type': fileObj.fileType,
        file: fileObj.file
      });

      this.trigger('upload:start');
      this._isImageUploading = true;

      this._imageXhr = simpleFileObject.save(null, {
        success: (model) => {
          // need to create the image media object as that is what we
          // need to pass in the page creation
          model.createMedia({
            data: {
              maintainAspectRatio: true,
              // 300px is for non-retina mobile
              // 600px is for retina mobile and non-retina small desktop
              // 1200px is for retina tablet/large desktop
              fixedSizes: [{
                width: 300,
                height: 300
              },
              {
                width: 600,
                height: 600
              },
              {
                width: 1200,
                height: 1200
              }
              ]
            },

            success: (modelObj) => {
              this._isImageUploading = false;
              this.trigger('upload:done', this.ui.imageInput);

              if (!this.isDestroyed) {
                // add the media model to the page
                this.insertImage(modelObj);
              }
            },

            error: () => {
              this._isImageUploading = false;
              this.trigger('upload:done', this.ui.imageInput);

              // TODO handle the error condition when creating an image media
              logging.error('Error creating image media');
            }
          });
        },

        error: (model, response, options) => {
          this._isImageUploading = false;
          this.trigger('upload:done', this.ui.imageInput);
          const errorKey = response.errMessage;
          this.handleFileUploadError(fileObj.fileType, model, response, options, errorKey);
        }
      });
    }
  }

  // iOS issue
  _onLinkClick(e) {
    if ($os.isInMobileApp()) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  resetMediaUploaderInput($input) {
    $input.closest('form').get(0)
      .reset();
  }

  insertImage(imageMedia) {
    // add media to list
    const media = this.model.get('media') || [];
    media.push(imageMedia.toJSON());
    this.model.set('media', media);
    this.model.set('isDirty', true);

    this.model.trigger('change', this.model);

    this.moveOutsideExistingFigure(); // Ensure we're not inserting the image within an existing image's figure tag

    const imgHtml = FroalaRichContentHelper.createImageContainerHtml(imageMedia);
    const wrappingHtml = $('<span>').addClass('axonify-temp-img-span')
      .html('a');
    const htmlToInsert = $('<span>')
      .append(wrappingHtml.clone())
      .append(imgHtml)
      .append(wrappingHtml.clone());
    this.editor.html.insert(htmlToInsert.html());
    $('.axonify-temp-img-span').remove();

    this.initializeImageViewers();
    // Move our cursor outside of the image's figure tag to ensure we're not adding content inside the <figure>
    this.moveOutsideExistingFigure();
  }

  initializeImageViewers() {
    const imageMediaInfo = this.model.getImageMediaInfo(this.$('.froala-editor'));

    for (const imageMedia of imageMediaInfo) {
      const {
        $el,
        media
      } = imageMedia;
      if ($el.length > 0 && media && $el.find('img').length === 0) {
        $el.children('br').remove(); // Froala will add empty <br> tags if a figure is empty, so we must remove them
        let displayWidth = null;
        const $parent = FroalaRichContentHelper.getImageParentElement($el);
        //Make sure we don't pass a width if the caption-container is already resized, and don't pass 0px as a width
        if ($parent[0].style.width !== '' && $parent[0].style.width !== '0px') {
          displayWidth = $parent.css('width');
        }
        const fallbackAlt = $el.data().altText;
        const froalaImgClass = FroalaRichContentHelper.getFroalaClassNamesToMove($parent[0]);
        $el.removeAttr('data-alt-text'); // Unset the data-alt-text attr from the figure. It will be reattached on save
        $el.css('width', ''); // Unset the 'figure' width and leave it on the img tag using displayWidth.
        $el.css('height', '');
        /*
          Removing the width above will ensure that we get the highest quality image for the screensize while editing
          and not the edited size.
          Ex. If an image was resized too small, then they decide to scale up on a subsequent edit, we want the image to
          be clear. However, on display in the article, it will use the adjusted size).
        */
        let maxWidth = parseInt($el.width(), 10);
        if (displayWidth) {
          maxWidth = Math.max(maxWidth, parseInt(displayWidth, 10));
        }
        const imageViewerOptions = _.extend({
          maxWidth,
          displayWidth,
          displayHeight: 'auto',
          fallbackAlt,
          imgClass: froalaImgClass,
          // We only add a placeholder if there are no other child nodes and the parent is an anchor tag.
          // In that case we can blow everything away safely. Otherwise, we keep the existing children.
          keepExistingChildNodes: !($parent.find('.image-placeholder').length > 0)
        }, imageMedia, {
          $el: $parent
        });
        const imageViewer = ImageViewerFactory.createViewerInstance(imageViewerOptions);
        imageViewer.render();
      }
    }
  }

  updateAltTextForImages() {
    if (typeof this.model.getImageMediaInfo !== 'function') {
      return;
    }

    const imageMediaInfo = this.model.getImageMediaInfo(this.$('.froala-editor'));
    for (const imageMedia of imageMediaInfo) {
      const {$el} = imageMedia;
      const altText = $el.find('img').attr('alt');
      $el.closest('figure').data('altText', altText);
    }
  }

  removeEmptyFigure() {
    const $figure = $('figure');
    $figure.each((ind, figure) => {
      if (!$(figure).find('img').length) {
        $(figure).remove();
      }
    });
  }

  uploadVideo() {
    if (this.$(this.videoSelector).first()
      .val().length < 4) {
      return;
    }

    const fileObj = {
      file: this.$(this.videoSelector),
      fileType: 'video'
    };

    if (fileObj.file) {
      const simpleFileObject = this.fileFactory(fileObj.fileType, {
        'file-type': fileObj.fileType,
        file: fileObj.file
      });

      this.trigger('upload:start');
      this._isVideoUploading = true;
      this._videoXhr = simpleFileObject.save(null, {
        mediaOptions: {
          language: this.videoUploadOptions.get('language').id
        },
        success: (model) => {
          this._isVideoUploading = false;
          this.trigger('upload:done', this.ui.videoInput);
          this.insertVideo(model.videoMediaModel);
          this.editor.popups.hide('axVideoUpload.popup');
        },
        error: (model, response, options) => {
          this._isVideoUploading = false;
          this.trigger('upload:done', this.ui.videoInput);
          const errorKey = response.errMessage;
          this.handleFileUploadError(fileObj.fileType, model, response, options, errorKey);
        }
      });
    }
  }

  insertVideo(videoMedia) {
    const media = this.model.get('media') || [];
    media.push(videoMedia.toJSON());
    this.model.set('media', media);
    this.model.set('isDirty', true);

    this.model.trigger('change', this.model);

    const videoHtml = FroalaRichContentHelper.createVideoContainerHtml(videoMedia);
    this.editor.html.insert(videoHtml);
  }

  handleFileUploadError(fileType, model, response, options, errorKey) {
    this.trigger('exception', errorKey);
  }

  loadPageDetails() {
    let content = this.tempContent ? this.tempContent : this.model.addTags(true);
    content = FroalaRichContentHelper.updateVideoPlaceholders(content);
    this.editor.html.set(content);
    // initializeImageViewers on initial load only.
    if (!this.tempContent) {
      this.initializeImageViewers();
    }
  }

  // Don't allow figures within figures.
  moveOutsideExistingFigure() {
    const parentFigure = this.editor.selection.element().closest('figure');
    if (parentFigure != null) {
      this.editor.selection.setAfter(parentFigure);
      this.editor.selection.restore();
    }
  }

  // This is temporary to ensure we can maintain alt text for the time being.
  // TODO: Use ImageAltText models instead.
  saveAltText() {
    $('figure').each((ind, fig) => {
      const $fig = $(fig);
      const alt = $(fig).data('altText');
      if (alt) {
        $fig.attr('data-alt-text', alt);
      } else {
        $fig.removeAttr('data-alt-text');
      }
    });
  }

  // If the display mode of an img is changed, we need to update that img's container to use the same display mode.
  updateFigureClass(cmd, displayParam = 'inline') {
    const $figure = this.editor.image.get().closest('.page__media');
    if ($figure.length > 0) {
      if (displayParam === 'block') {
        $figure.addClass('fr-dib');
        $figure.removeClass('fr-dii');
      } else {
        $figure.removeClass('fr-dib');
        $figure.addClass('fr-dii');
      }
    }
    this.editor.events.trigger('blur');
  }

  _setupVideoForm() {
    if (!this._videoOptionsForm) {
      // Setup the form for the video editing stuff
      this._videoOptionsForm = new Form({
        el: this.$('#video-form'),
        model: this.videoUploadOptions,
        context: {
          languages: this.supportedLanguages
        }
      });

      this.listenTo(this._videoOptionsForm, 'change:language', (result, ed) => {
        this._checkLanguage(ed);
      });

      this.$videoUploadButton = this.$('#video-upload-button');
      this.$videoUploadButton.on('click', () => {
        this.ui.videoInput.trigger('click');
      });
    }
  }

  onEditorTextChange() {
    const $html = $('<tmp></tmp>').append(this.editor.html.get());
    const contentUpdated = FroalaRichContentHelper.moveContentOutsideImageContainer($html);
    const removedImage = this._deleteUnwrappedImages($html);
    this.model.set('isDirty', true);

    if (removedImage || contentUpdated) {
      this.editor.html.set($html.html());
      if (contentUpdated) {
        //setAtEnd requires a DOM node instead of a selector so we find our selection target.
        this.editor.selection.setAtEnd(this.editor.$('.js-selection-target')[0]);
        this.editor.selection.restore();
      }
    }
  }

  _deleteUnwrappedImages($html) {
    // Delete any unwrapped images (the encapsulating tag with ".page__media" was removed)
    // Includes captions and anchor tags around images.
    let removedImage = false;
    $html.find('img, .fr-img-space-wrap').each((index, imgContainer) => {
      const $img = $(imgContainer);
      const $anchor = $img.closest('a'); //for linked images, we need to remove the links as well.
      const $eleToRemove = $anchor.length > 0 ? $anchor : $img;
      if ($eleToRemove.closest('.page__media').length === 0) {
        $eleToRemove.remove();
        removedImage = true;
      }
    });
    return removedImage;
  }

  commit() {
    const errors = [];

    this.saveAltText();

    // need to empty the media tags and get list of 'active' media ids
    const {
      content,
      mediaIdList
    } = FroalaRichContentHelper.cleanContent(this.editor.html.get());

    if (FroalaRichContentHelper.hasContent(content, mediaIdList)) {
      this.model.set('content', content);
    } else {
      errors.push(I18n.t('errors.noContent'));
    }

    // the media list may contain media that is no longer referenced in the content
    // so we need to filter out those media objects
    const newMedia = _.filter(this.model.get('media'), (media) => {
      // filter out if media is not in list and is an image or video
      return mediaIdList.includes(media.id) || !(['image', 'video'].includes(media.type));
    });

    this.model.set('media', newMedia);
    return errors;
  }
}

module.exports = RteView;
