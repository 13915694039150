const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const I18n = require('@common/libs/I18n');
const FileFactory = require('@common/libs/file/FileFactory');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const MediaFactory = require('@common/libs/file/MediaFactory');
const MediaHelpers = require('@common/libs/file/MediaHelpers');

const EvaluationSubmissionUploadModalContentView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionUploadModalContentView');
const EvaluationSubmissionFailureController = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionFailureController');
const EvaluationSubmissionUploadProgressController = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionUploadProgressController');
const EvaluationSubmissionPreviewController = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionPreviewController');

const SUBVIEW = {
  current: null,
  uploading: null,
  previewing: null,
  warning: null
};

class EvaluationSubmissionUploadModalContentController extends LayoutController {
  constructor(...args) {
    super(...args);
    this.viewDefinition = this.viewDefinition.bind(this);
    this._showCurrentView = this._showCurrentView.bind(this);
    this._getCurrentViewController = this._getCurrentViewController.bind(this);
    this._uploadFile = this._uploadFile.bind(this);
  }

  initialize(options = {}) {
    ({
      evaluationForm: this.evaluationForm,
      submissionFile: this.submissionFile
    } = options);

    this._uploadFile(this.submissionFile);
  }

  viewDefinition() {
    return {
      ViewClass: EvaluationSubmissionUploadModalContentView,
      evaluationForm: this.evaluationForm
    };
  }

  regionControllers() {
    return {
      uploadRegion: this._getCurrentViewController()
    };
  }

  _showCurrentView(options) {
    const curController = this._getCurrentViewController(options);
    if (curController) {
      this.swapRegionController('uploadRegion', curController);
    }
  }

  _getCurrentViewController(options) {
    // Check if the upload is still in progress
    const ufpState = (this.uploadFilePromise || {}).state;

    if (ufpState) {
      if (ufpState() === 'pending') {
        const percent = Math.round(options || 0);

        // If we are already displaying the uploading view, just update it with the new options
        if (SUBVIEW.current === 'uploading') {
          const curView = this.getView();
          if (curView) {
            this.glChannel.vent.trigger('submission:upload:progress', percent);
          }
          return undefined;
        }

        this._setSubmitEnabled(false);
        SUBVIEW.current = 'uploading';
        SUBVIEW.uploading = {
          ViewControllerClass: EvaluationSubmissionUploadProgressController,
          model: this.submissionFile,
          percent
        };
        return SUBVIEW.uploading;

      } else if (ufpState() === 'resolved') {
        this._setSubmitEnabled(true);
        SUBVIEW.current = 'previewing';
        SUBVIEW.previewing = {
          ViewControllerClass: EvaluationSubmissionPreviewController,
          evaluationData: this.evaluationForm,
          fileChangeHandler: this._uploadFile
        };
        return SUBVIEW.previewing;
      }
    }

    // If the file upload process fails (the state is likely 'rejected'), prompt the user to load a new/different file
    this._setSubmitEnabled(false);
    SUBVIEW.current = 'warning';
    SUBVIEW.warning = {
      ViewControllerClass: EvaluationSubmissionFailureController,
      submissionFile: this.submissionFile,
      fileChangeHandler: this._uploadFile
    };
    return SUBVIEW.warning;
  }

  _setSubmitEnabled(enabled) {
    if (enabled == null) {
      return;
    }
    const curView = this.getView();
    if (curView) {
      curView.trigger('enable:submit', enabled);
    }
  }

  _uploadFile(file, updateView = false) {

    this._abortCurrentSubmission();
    this.submissionFile = MediaFactory.createMediaFromFile(file);

    const onFail = (resFile, xhr) => {
      if (xhr.status === 400) {
        xhr.skipGlobalHandler = true;
        let message;
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        if (exception.getErrorCode() === AxonifyExceptionCode.SIMPLE_FILE_UPLOAD_RETRY) {
          message = I18n.t('flash.simpleFileUploadRetry');
        } else if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_UNSAFE_CONTENT) {
          message = I18n.t('flash.common.3064');
        } else {
          message = xhr.errMessage ? xhr.errMessage : I18n.t('flash.failed_to_upload_file');
        }
        window.app.layout.flash.error(message);

        this.uploadFilePromise = xhr;

        const curView = this.getView();
        if (curView) {
          this._showCurrentView();
        }
      }

      return $.Deferred().reject({
        file: resFile,
        xhr
      });
    };

    const mediaPromise = MediaHelpers.saveMedia(
      this.submissionFile, undefined, {
        timeout: 1000,
        pollPredicate: () => {
          return false;
        }
      }
    )
      .progress((fileModel, id, fileName, bytesLoaded, totalBytes) => {
        this._showCurrentView((bytesLoaded / (totalBytes || 1) * 100));
      })
      .then((fileModel, status, xhr) => {
        this.submissionFile = MediaFactory.createMediaFromJSON(FileFactory, fileModel, {
          videosAllowed: true,
          documentsAllowed: true
        });
        this.submissionFile.id = this.submissionFile.get('id');
        this.uploadFilePromise = xhr;
        this.evaluationForm.setMedia(this.submissionFile);
        this._showCurrentView();
      })
      .fail(onFail);

    this.uploadFilePromise = mediaPromise;

    if (updateView) {
      this._showCurrentView(0);
    }

    return mediaPromise;
  }

  _abortCurrentSubmission() {
    if (this.submissionFile && this.submissionFile.getFile) {
      SUBVIEW.current = null;
      MediaHelpers.abortSaveMedia(this.submissionFile);
    }
  }

  onDestroy() {
    this._abortCurrentSubmission();
  }

}

module.exports = EvaluationSubmissionUploadModalContentController;
