// Module
var code = `<div data-answer-option="<%-option.id %>" data-letter-label="<%-letterlabel %>" class="answer touchable clearfix answer-text-limit-max-width" tabindex="0" role="checkbox" aria-label="<%= optionText %>" aria-checked="false">
  <div class="answerspacer" aria-hidden="true" role="presentation">
    <div class="table-helper" cellpadding="0px">
      <div class="table-row-helper">
        <!--<td></td>-->
        <% if (showCheck) { %>
          <div class="answerletter table-cell-helper"><div class="multicheck" aria-hidden="true"></div></div>
        <% } %>
        <% if (option.optionImg) { %>
          <div class="answerimage table-cell-helper">
            <button class="image-wrapper imgwrap zoom-image-wrap" data-media-id="<%- option.optionImg.preferred.id %>"></button>
          </div>
        <% } %>
        <div class="answertext table-cell-helper"><div><%= option.optionText %></div></div>
        </div>
      </div>
  </div>
</div>
`;
// Exports
module.exports = code;