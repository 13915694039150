const logging = require('logging');

const _ = require('underscore');

class NativeAndroidHandler {

  constructor(nativeBridge) {
    this.registerBackHandler = this.registerBackHandler.bind(this);
    this.unregisterBackHandler = this.unregisterBackHandler.bind(this);
    this.backPressed = this.backPressed.bind(this);

    this.nativeBridge = nativeBridge;
    this.backHandlerStack = [];
  }

  registerBackHandler(handler) {
    if (!_.isFunction(handler)) {
      logging.warn('A handler callback is needed to use this API!!');
    } else {
      this.backHandlerStack.push(handler);
    }
  }

  unregisterBackHandler(handler) {
    if (!_.isFunction(handler)) {
      logging.warn('No \'handler\' was passed in to unregister!');
    } else {
      // Filter out the handler that we want to unregister. We do a slice to avoid doing
      // an indiscriminate 'pop()' that any caller could trigger accidentally
      const option = _.find(this.backHandlerStack, (backHandler) => {
        return backHandler === handler;
      });
      if (option) {
        this.backHandlerStack.splice(_.indexOf(this.backHandlerStack, option), 1);
      }
    }
  }

  backPressed() {
    let handled = false;

    // This is modeled after how Android does it's keyDown/keyUp event handling
    // which this is an extension of. It'll call down the stack of possible listeners
    // until one of them returns true for having handled the event. It's up to the listener
    // to decide if it's ok that any listeners lower in the stack get to execute. In our
    // case, if the entire stack were allowed to execute, then the last handler's navigate
    // implimentation will just clobber anything the previous handlers were trying to do.
    for (let i = this.backHandlerStack.length - 1; i >= 0; i--) {
      handled = this.backHandlerStack[i]();
      if (handled) {
        break;
      }
    }

    return this.nativeBridge.sendMessage({action: 'backPressHandled', data: handled});
  }
}

module.exports = NativeAndroidHandler;
