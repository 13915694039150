const Marionette = require('Marionette');
const logging = require('logging');

class DeepLinkingSplashPageView extends Marionette.LayoutView {
  className() {
    return 'welcome-page';
  }

  getTemplate() {
    return require('./DeepLinkingSplashPageView.html');
  }

  templateHelpers() {
    return {
      questionCount: this.assessmentQuestionCount,
      user: window.apps.auth.session.user.toJSON()
    };
  }

  events() {
    return {
      'click .large-block-button': () => {
        return this.onComplete();
      }
    };
  }

  constructor(options = {}) {
    super(...arguments);
    ({
      onComplete: this.onComplete,
      assessmentQuestionCount: this.assessmentQuestionCount
    } = options);
  }

  onRender() {
    logging.info('DeepLinkingSplashPageView - Showing the splash page');
  }
}

module.exports = DeepLinkingSplashPageView;
