const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const ProgramOverviewPathContentView = require('@common/components/guidedLearning/programs/ProgramOverview/ProgramOverviewPathContentView');


class ProgramOverviewPathContentController extends LayoutController {
  viewDefinition() {
    return {
      ViewClass: ProgramOverviewPathContentView
    };
  }
}


module.exports = ProgramOverviewPathContentController;
