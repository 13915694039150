import ReactController, { type ReactControllerOptions } from '@common/modules/react/viewController/ReactController';

export interface ReactControllerDefinitionFactoryProps<P> extends ReactControllerOptions<P> {}

/**
 * Component to be used when you want to nest any React component/layout inside a ViewController hierarchy.
 * Renders out a default loading spinner while the React component and library webpack bundles are asynchronously imported.
 */
export default function ReactControllerDefinitionFactory<P>(options: ReactControllerDefinitionFactoryProps<P>): object {
  return {
    ViewControllerClass: ReactController<P>,
    ...options
  };
}

