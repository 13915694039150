const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const {
  compact,
  extend,
  omit,
  chain,
  identity
} = require('underscore');

const I18n = require('@common/libs/I18n');

const {
  HUB,
  HOME,
  ACHIEVEMENTS,
  RECOGNITION_PINS,
  REWARDS,
  GAME_SCORES,
  REPORT_CARD,
  TOP_PERFORMERS,
  FEED,
  REFERRAL,
  LEARNING_EVENTS,
  PROFILE,
  SEARCH,
  TIMELINE,
  CONVERSATIONAL_SEARCH,
  EXTRA_TRAINING,
  EXECUTION_TASKS,
  assertLegalValue
} = require('@common/data/enums/HubTileIdentifiers').default;

const {
  createSelectableItemViewDefinition,
  createSelectableNotifiableItemViewDefinition
} = require('@common/modules/main/menu/item/MenuItemFactory');

const {
  HUB_SEQUENCE,
  FLOW_SEQUENCE,
  DZ_SUPERUSER_SEQUENCE,
  DZ_GUEST_SEQUENCE,
  assertLegalValue: assertLegalSequenceEnum
} = require('@common/modules/base/TrainingMenuConfigSequenceEnum');

const HubMenuConfigMap = require('@common/modules/base/HubMenuConfigMap');

const configureHrefFormatter = (baseUrl) => {
  if (baseUrl != null) {
    return (hash) => {
      return `${ baseUrl }${ hash }`;
    };
  }

  return identity;
};

let hrefFormatter = configureHrefFormatter();

const getAvailableHubItemConfig = (activityTypes, type, itemViewDefinition = createSelectableItemViewDefinition) => {
  if (activityTypes[assertLegalValue(type)] === true) {
    return getHubItemConfig(HubMenuConfigMap[type], itemViewDefinition);
  }

  return undefined;
};

const getAvailableHubItemAchievementsConfig = (activityTypes) => {
  //The achievements page menu item requires either the achievements tile or the recognition pins tile to be enabled
  if (activityTypes[assertLegalValue(ACHIEVEMENTS)] === true || activityTypes[assertLegalValue(RECOGNITION_PINS)] === true) {
    return getHubItemConfig(HubMenuConfigMap[ACHIEVEMENTS], createSelectableItemViewDefinition);
  }

  return undefined;
};

const getAvailableHubItemTasksFlowConfig = (activityTypes) => {
  // The tasks page menu item requires the feature flag to be enabled
  if (TenantPropertyProvider.get().getProperty('tasksEnabled')) {
    return getAvailableHubItemConfig(activityTypes, EXECUTION_TASKS);
  }

  return undefined;
};

const getHubItemConfig = (config, viewDefinition) => {
  const viewConfig = extend({
    text: I18n.t(config.textKey),
    href: hrefFormatter(config.hash)
  }, omit(config, 'textKey', 'hash') );

  return viewDefinition(viewConfig);
};

const getHomeItemConfig = (authSession, activityTypes) => {
  if (!authSession.user.isGuestOrSuperuser()) {
    return getAvailableHubItemConfig(activityTypes, HOME);
  }

  return undefined;
};

const hubConfigSequenceFactory = (options = {}) => {
  const {
    getActivityTypes = () => {
      return [];
    },
    authSession
  } = options;

  const activityTypes = getActivityTypes();

  return [
    getHomeItemConfig(authSession, activityTypes),
    getAvailableHubItemConfig(activityTypes, HUB),
    getAvailableHubItemConfig(activityTypes, CONVERSATIONAL_SEARCH),
    getAvailableHubItemConfig(activityTypes, TIMELINE, createSelectableNotifiableItemViewDefinition),
    getAvailableHubItemConfig(activityTypes, SEARCH),
    getAvailableHubItemConfig(activityTypes, EXECUTION_TASKS),
    getAvailableHubItemConfig(activityTypes, EXTRA_TRAINING),
    getAvailableHubItemConfig(activityTypes, REPORT_CARD),
    getAvailableHubItemConfig(activityTypes, REWARDS),
    getAvailableHubItemAchievementsConfig(activityTypes),
    getAvailableHubItemConfig(activityTypes, TOP_PERFORMERS),
    getAvailableHubItemConfig(activityTypes, FEED),
    getAvailableHubItemConfig(activityTypes, LEARNING_EVENTS),
    getAvailableHubItemConfig(activityTypes, GAME_SCORES),
    getAvailableHubItemConfig(activityTypes, REFERRAL),
    getAvailableHubItemConfig(activityTypes, PROFILE)
  ];
};

const flowConfigSequenceFactory = (options = {}) => {
  const {
    getActivityTypes = () => {
      return [];
    },
    authSession
  } = options;

  const activityTypes = getActivityTypes();

  return [
    getHomeItemConfig(authSession, { [HOME]: true }),
    getAvailableHubItemConfig(activityTypes, CONVERSATIONAL_SEARCH),
    getAvailableHubItemConfig(activityTypes, TIMELINE, createSelectableNotifiableItemViewDefinition),
    getAvailableHubItemConfig(activityTypes, SEARCH),
    getAvailableHubItemTasksFlowConfig(activityTypes),
    getAvailableHubItemConfig(activityTypes, EXTRA_TRAINING),
    getAvailableHubItemConfig(activityTypes, LEARNING_EVENTS),
    getAvailableHubItemConfig(activityTypes, PROFILE)
  ];
};

const dzGuestConfigSequenceFactory = () => {
  return [
    getHubItemConfig(HubMenuConfigMap[SEARCH], createSelectableItemViewDefinition)
  ];
};

const dzSuperUserConfigSequenceFactory = () => {
  const isConversationalSearchEnabled = TenantPropertyProvider.get().getProperty('conversationalSearchEnabled');

  return [
    getHubItemConfig(HubMenuConfigMap[SEARCH], createSelectableItemViewDefinition),
    isConversationalSearchEnabled ? getHubItemConfig(HubMenuConfigMap[CONVERSATIONAL_SEARCH], createSelectableItemViewDefinition) : undefined
  ];
};

const nullConfigSequenceFactory = () => {
  return [];
};

const getConfigSequenceFactory = (sequenceEnum) => {
  switch (assertLegalSequenceEnum(sequenceEnum)) {
    case HUB_SEQUENCE:
      return hubConfigSequenceFactory;
    case FLOW_SEQUENCE:
      return flowConfigSequenceFactory;
    case DZ_SUPERUSER_SEQUENCE:
      return dzSuperUserConfigSequenceFactory
    case DZ_GUEST_SEQUENCE:
      return dzGuestConfigSequenceFactory;
    default:
      return nullConfigSequenceFactory;
  }
};

const create = (options = {}) => {
  const {
    getConfigSequence = () => {}
  } = options;

  const getSequence = getConfigSequenceFactory( getConfigSequence() );

  return compact( getSequence(options) );
};

const mapHashToConfigId = (hashString = '') => {
  let config = chain(HubMenuConfigMap)
    .values()
    .reduce((mostSpecificConfigMatch, configObj) => {
      if (hashString.indexOf(configObj.rootHash || configObj.hash) > -1 && configObj.hash.length >= mostSpecificConfigMatch.hash.length) {
        return configObj;
      }

      return mostSpecificConfigMatch;
    }, { hash: '' })
    .value() || {};

  while (config.parentConfigId != null) {
    config = HubMenuConfigMap[config.parentConfigId];
  }

  return config.configId || '';
};

const factory = (options = {}) => {
  hrefFormatter = configureHrefFormatter(options.baseUrl);

  return () => {
    return create(options);
  };
};

module.exports = Object.assign(factory, { mapHashToConfigId });
