const I18n = require('@common/libs/I18n');
const CommentHelpers = {
  getCommentCountPhrase(commentCount) {
    if (commentCount === 1) {
      return I18n.t('discover.comments.count.singular');
    }
    return I18n.t('discover.comments.count.plural', {commentCount});
  }
};

module.exports = CommentHelpers;
