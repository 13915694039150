// Module
var code = `<div class="modalHeader">
    <h1><%- t('discover.publishFlow.publishAllTitle') %></h1>
    <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
</div>
<div class="disclaimer">
    <%- t('discover.publishFlow.publishAllDisclaimer') %></span>
</div>
<div class="languages-table"></div>`;
// Exports
module.exports = code;