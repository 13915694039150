const AssessmentType = require('@common/data/enums/AssessmentType');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');

const _assessmentContextMap = {
  [AssessmentType.CertificationTraining]: AssessmentLaunchContext.CERTIFICATION,
  [AssessmentType.RefresherTraining]: AssessmentLaunchContext.REFRESHER,
  [AssessmentType.FormalExamTraining]: AssessmentLaunchContext.EXAM,
  [AssessmentType.IntroductoryTraining]: AssessmentLaunchContext.NEW_TOPIC
};

const getLaunchContextForAssessmentType = (assessmentType) => {
  return _assessmentContextMap[assessmentType];
};

module.exports = getLaunchContextForAssessmentType;
