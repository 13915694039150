const {LayoutView} = require('Marionette');
class TagsView extends LayoutView {
  get template() {
    return `\
        <div class="js-tags-search-region"></div>
        <div class="js-tags-table-region"></div>
      `;
  }

  regions() {
    return {
      searchRegion: '.js-tags-search-region',
      tagsRegion: '.js-tags-table-region'
    };
  }
}

module.exports = TagsView;
