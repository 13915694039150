const { ItemView } = require('Marionette');
const Backbone = require('Backbone');
const KeyCode = require('@common/data/enums/KeyCode');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const I18n = require('@common/libs/I18n');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');

class SearchInputView extends ItemView {
  initialize(options = {}) {
    ({
      searchPageState: this.pageState = {}
    } = options);
  }

  events() {
    return {
      'keyup input': this.onSearchKeyup,
      'focus input': this.onSearchFocus,
      'blur input': this.onSearchBlur,
      'click .btn-search': this.onSearch,
      'click .js-remove': this.clearSearch
    };
  }

  ui() {
    return {
      searchInput: ':input',
      searchButton: '.btn-search'
    };
  }

  className() {
    return 'search-input-view ax-grid ax-grid--no-gutter ax-grid--align-items-center';
  }

  getTemplate() {
    return `
      <%= axSearch({
        fullWidth: true,
        iconClassRight: 'qa-input-search-clear js-close-button icon-remove js-remove ' + clearSearchClass,
        placeholder: placeholderText,
        size: 'l',
        value: searchQuery
      }) %>
      <button class="button btn-search hidden ax-button ax-button--secondary ax-button--l ax-grid__col--auto-size" tabindex="0" aria-label="<%= t('general.search') %>">
        <span class="icon-search" aria-hidden="true" role="presentation"></span><span class="search-btn-text"><%- t('general.search') %></span>
      </button>
    `;
  }

  templateHelpers() {
    return {
      placeholderText: this.getPlaceholderText(),
      searchQuery: this.pageState.get('searchString'),
      clearSearchClass: this.getClearSearchClass()
    };
  }

  onAttach() {
    if (this.pageState.get('community')) {
      if (this.pageState.get('communitySearchCategory')) {
        this.setInputPlaceholder();
      }

      this.listenTo(this.pageState, 'change', (state) => {
        if (state.changed.community || state.changed.searchCategory) {
          this.setInputPlaceholder();
        }
      });
    }
  }

  onSearch() {
    this.pageState.set({
      pageNum: 0,
      searchString: this.ui.searchInput[0].value.trim()
    });

    if (this.pageState.get('communitySearchCategory')) {
      Backbone.history.navigate( SearchUrlHelper.getHashForCommunitySearch(this.pageState), {
        trigger: true
      });
    } else {
      Backbone.history.navigate(SearchUrlHelper.getHashForCurrentTabState(this.pageState), {
        trigger: true
      });
    }
  }

  onSearchKeyup(e) {
    if (e.which === KeyCode.ENTER) {
      this.onSearch();
    } else if (e.target.value !== this.pageState.get('searchString') || e.which === KeyCode.TAB) {
      this.setSearchVisibility(true);
    } else {
      this.setSearchVisibility(false);
    }
  }

  onSearchFocus() {
    this.$el.toggleClass('focused', true);
  }

  onSearchBlur() {
    this.$el.toggleClass('focused', false);
  }

  getPlaceholderText() {
    if (this.pageState.get('searchCategory') === SearchCategoryEnum.ARTICLES && this.pageState.get('community')) {
      const selectedCommunity = this.pageState.get('community');

      if (!selectedCommunity) {
        return '';
      }

      return I18n.t('selfDirected.search.inputPlaceholderWithCommunity', {
        communityName: selectedCommunity.getName()
      });
    }
    return I18n.t('general.search');
  }

  setInputPlaceholder() {
    this.ui.searchInput.attr('placeholder', this.getPlaceholderText());
  }

  getClearSearchClass() {
    return this.pageState.get('searchString') ? '' : ' hidden';
  }

  setSearchVisibility(visible = false) {
    this.ui.searchButton.toggleClass('hidden', !visible);
  }

  clearSearch() {
    this.pageState.set({
      pageNum: 0,
      searchString: ''
    });

    if (this.pageState.get('communitySearchCategory')) {
      Backbone.history.navigate( SearchUrlHelper.getHashForCommunitySearch(this.pageState), {
        trigger: true
      });
    } else {
      Backbone.history.navigate(SearchUrlHelper.getHashForCurrentTabState(this.pageState), {
        trigger: true
      });
    }
  }
}

module.exports = SearchInputView;
