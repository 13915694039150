const {
  TARGETS,
  invalidateCacheQuery
} = require('env');
const _ = require('underscore');

const CSSInjectorPackageFactory = require('@common/services/cssLoader/CSSInjectorPackageFactory');
const NullCSSInjectorPackageFactory = require('@common/services/cssLoader/NullCSSInjectorPackageFactory');

const Enum = require('@common/data/enums/Enum');

const BUST_STRATEGIES = Enum({
  DEV: TARGETS.DEV,
  PROD: TARGETS.PROD,
  NULL: ''
});

// appends the timebusting query param to a url
const appendCacheBustingQuery = (url = '', bustStrategy = BUST_STRATEGIES.NULL) => {
  BUST_STRATEGIES.assertLegalValue(bustStrategy);
  return `${ url }${ invalidateCacheQuery }`;
};

class CacheBustingCSSInjectorPackageFactory extends CSSInjectorPackageFactory {
  static BUST_STRATEGIES = BUST_STRATEGIES

  constructor(key, packageFactory) {
    super(key);

    this._packageFactory = packageFactory || new NullCSSInjectorPackageFactory();
  }

  create(options) {
    const { key, paths } = this._packageFactory.create(options);

    return {
      key,
      paths: paths.map(_.partial(appendCacheBustingQuery, _, options.bustStrategy))
    };
  }
}

module.exports = CacheBustingCSSInjectorPackageFactory;
