const {GameList} = require('./GameList');

class GameScoresList extends GameList {
  apiEndpoint() {
    return '/games/available';
  }

  comparator(model) {
    return model.get('name');
  }

  parse(response = {}) {
    return response.games || response;
  }

  getGamesWithScoring() {
    return this.fetch({
      data: {
        withScoring: true
      }
    });
  }
}

module.exports = GameScoresList;
