import { useEffect } from 'react';

const {
  registerBackHandler,
  unregisterBackHandler
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

// onBack should be a stable function reference
export default function useAndroidBackHandler(onBack: () => boolean): void {
  useEffect(() => {
    registerBackHandler(onBack);
    return () => {
      unregisterBackHandler(onBack);
    };
  });
}
