const Marionette = require('Marionette');
require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');

const Form = require('@common/components/forms/Form');

class TimePeriodSelectorView extends Marionette.ItemView {
  getTemplate() {
    return `\
      <form>
        <label class="off-screen" for="reportsTimeSelect"><%- t('discover.browse.reports.filterByDateLabel') %></label>
        <%= axSelect({
          classNameContainer: 'pull-right',
          classNameSelect: 'qa-time-period-select',
          data: {
            editor: 'AxonSelect',
            field: 'timePeriodDate',
            options: 'timePeriodOptions',
            shallow: 'true'
          },
          fullWidth: true,
          id: 'reportsTimeSelect',
          noFormEditorDefault: 'true'
        }) %>
      </form>\
    `;
  }

  ui() {
    return {
      form: 'form',
      select: 'select'
    };
  }

  initialize(options = {}) {
    ({
      timePeriodList: this.timePeriodList,
      timePeriodModel: this.timePeriodModel
    } = options);
  }

  onRender() {
    const form = new Form({
      el: this.ui.form,
      model: this.timePeriodModel,
      context: {timePeriodOptions: {
        collection: this.timePeriodList,
        axonSelectOptions: {
          disableSearch: true,
          useChosen: false
        }
      }}
    });
    this.listenTo(this.timePeriodModel, 'change', () => {
      form.update(this.timePeriodModel);
    });
  }
}

module.exports = TimePeriodSelectorView;
