const { settings } = require('env');

const CSSInjector = require('@common/services/cssLoader/CSSInjector');
const CSSLoader = require('@common/services/cssLoader/CSSLoader');

const VersionedCSSInjectorPackageFactory = require('@common/services/cssLoader/VersionedCSSInjectorPackageFactory');
const CacheBustingCSSInjectorPackageFactory = require('@common/services/cssLoader/CacheBustingCSSInjectorPackageFactory');

const loader = new CSSLoader(new CSSInjector());

module.exports = {
  CACHE_BUST_STRATEGIES: CacheBustingCSSInjectorPackageFactory.BUST_STRATEGIES,
  deregisterPackageFactory: loader.deregisterPackageFactory.bind(loader),
  registerPackageFactory(packageFactory) {
    loader.registerPackageFactory( new CacheBustingCSSInjectorPackageFactory(packageFactory.key, packageFactory) );
  },
  load(key, options = {}) {
    const loadOptions = Object.assign({ bustStrategy: settings.target }, options);
    return loader.load(key, loadOptions);
  },
  registerVersionedFactory(packageFactory) {
    this.registerPackageFactory( new VersionedCSSInjectorPackageFactory(packageFactory.key, packageFactory) );
  }
};
