const {LayoutView} = require('Marionette');
class CommunitiesView extends LayoutView {
  initialize(options) {
    ({
      fileFactory: this.fileFactory
    } = options);
  }

  get template() {
    return `\
        <div class="js-communities-search-region"></div>
        <div class="js-communities-table-region"></div>
        <input type="file" class="import-team-communities-file hidden" accept=".csv"></input>
      `;
  }

  ui() {
    return {
      importRef: '.import-team-communities-file'
    };
  }

  events() {
    return {
      'change @ui.importRef': this.onFileChange
    };
  }

  regions() {
    return {
      searchRegion: '.js-communities-search-region',
      communitiesRegion: '.js-communities-table-region',
      ellipsisMenuRegion: '.js-communities-search__menu'
    };
  }

  onFileChange() {
    if (this.ui.importRef.val().length < 4) {
      return;
    }

    let simpleFileObject;

    try {
      simpleFileObject = this.fileFactory.createFileFromInput(this.ui.importRef, {allowedExtensions: ['csv']});
    } catch (e) {
      window.app.layout.flash.error(e);
      this.trigger('reset:import');
    }
    if (simpleFileObject) {
      this.trigger('select:file', simpleFileObject);
    }
  }

  onRender() {
    this.trigger('set:import', this.ui.importRef);
  }
}

module.exports = CommunitiesView;
