const DefaultFormatter = require('@common/prerequisites/libs/error/formatters/DefaultFormatter');

const DataFormatter = {
  format: (data = {}, formattingOptions = {}) => {
    const {
      formatters = {},
      formatSequence = Object.keys(data),
      defaultFormatter = DefaultFormatter,
      valueMappers = {}
    } = formattingOptions;

    const fullData = DataFormatter.applyDefaults(data, valueMappers);

    const messageLines = formatSequence.reduce((memo, key) => {
      if (fullData[key] != null) {
        const detailsValue = fullData[key];
        const format = formatters[key] || defaultFormatter;
        const formattedValue = format(detailsValue);
        if (formattedValue != null) {
          memo.push(formattedValue);
        }
      }

      return memo;
    }, []);

    return messageLines.join('\n');
  },

  mergeFormatterOptions: (...options) => {
    const extractedOptions = options.reduce((memo, {
      formatters,
      valueMappers
    } = {}) => {
      memo.formatters.push(formatters);
      memo.valueMappers.push(valueMappers);
      return memo;
    }, {
      formatters: [],
      valueMappers: []
    });

    return Object.assign({}, ...options, {
      formatters: Object.assign({}, ...extractedOptions.formatters),
      valueMappers: Object.assign({}, ...extractedOptions.valueMappers)
    });
  },

  applyDefaults: (data = {}, defaultMappers = {}) => {
    const details = Object.assign({}, data);
    const defaultKeys = Object.keys(defaultMappers);

    defaultKeys.forEach((detailKey) => {
      const defaultFn = defaultMappers[detailKey];
      const detailValue = defaultFn(data[detailKey]);

      if (detailValue === undefined) {
        delete details[detailKey];
      } else {
        details[detailKey] = detailValue
      }
    });

    return details;
  }
};

module.exports = DataFormatter;
