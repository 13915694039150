class AssessmentInitiatorRegistry {
  constructor() {
    this.initiatorMapping = {};
  }

  registerInitiator(initiatorController) {
    const discriminator = initiatorController.getDiscriminator();
    const previousInitiator = this.findInitiatorWithHandlerFor(
      discriminator
    );
    if (previousInitiator) {
      throw new Error(
        'There is something already registered with this discrimination. These must be unique across registrations.'
      );
    }

    this.initiatorMapping[discriminator] = initiatorController;
  }

  findInitiatorWithHandlerFor(discriminator) {
    return this.initiatorMapping[discriminator];
  }
}

module.exports = AssessmentInitiatorRegistry;
