const File = require('@common/libs/file/File');

class VTTFile extends File {

  constructor(attrs, options = {}) {
    super(attrs, options);

    this.fileType = 'vtt';
  }

  preinitialize(options) {
    super.preinitialize(options);

    this.sizeLimit = 50; // in MB
  }
}

module.exports = VTTFile;
