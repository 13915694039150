const logging = require('logging');

const { history } = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const KnowledgeDetailPage = require('./KnowledgeDetailPage');

const DesktopKnowledgePage = require('@training/apps/training/views/KnowledgePage');
const KnowledgeItemListView = require('@training/apps/training/views/KnowledgeItemListView');

class KnowledgePage extends DesktopKnowledgePage {

  constructor(options = {}) {
    super(options);

    ({
      showDetails: this.showDetails = false,
      topicId: this.topicId,
      level: this.level
    } = options);

    this.showQuestions = this.showQuestions.bind(this);
    this.takeAction = this.takeAction.bind(this);

    this.numberOfQuestionsInKnowledgeChart = 15;

    this.$el.toggleClass('hidden', this.showDetails);
  }

  _renderKnowledgeItemListView() {
    // KnowledgeItemListView
    // set the template
    this.knowledgeItemListView = new KnowledgeItemListView({
      el: this.$('#knowledgemap'),
      collection: this.knowledgeItemList
    });
  }

  _fetchKnowledgeItems() {
    return super._fetchKnowledgeItems().then(() => {
      if (this.showDetails) {
        const def = $.Deferred();

        this.getKnowledgeData(this.topicId, this.level, (levelNode) => {
          this.showDetailView(`${ this.topicId }-${ this.level }`, levelNode);
          def.resolve();
        });

        return def.promise();
      }

      return $.Deferred().resolve()
        .promise();
    });
  }

  _renderActionBar() {
    // Action bar isn't shown in mobile
  }

  viewDidAppear(...args) {
    super.viewDidAppear(...args);
    logging.info('KnowledgePage (Mobile) - viewDidAppear');
  }

  // user clicked on knowledge item so go
  // to details view
  showQuestions(e) {
    const $knowledgeitem = $(e.currentTarget).closest('.knowledgeitem');
    const topicId = $knowledgeitem.data('topic-id');
    const level = $knowledgeitem.data('level');
    const knowledgeItemId = $knowledgeitem.data('generatedId');

    this.getKnowledgeData(topicId, level, (levelNode) => {
      this.showDetailView(knowledgeItemId, levelNode, UIKit.View.Transitions.FADE);
      this.$el.attr('aria-hidden', true);
    });
  }

  showDetailView(knowledgeItemId, levelNode, fade) {
    const knowledgeItem = this.knowledgeItemListView.collection.get(knowledgeItemId);

    const details = new KnowledgeDetailPage({
      model: knowledgeItem,
      questions: levelNode,
      isExtraTrainingEnabled: window.app.sessionController.isExtraTrainingAvailable(),
      complete: () => {
        window.app.layout.popDetailsView();
      },
      takeAction: this.takeAction
    });

    window.app.layout.pushDetailsView(details, fade);

    history.navigate(`#hub/reportCardDetails/${ knowledgeItemId }`);
  }

  takeAction(topicId, level, complete = () => {}) {
    complete();
    return super.takeAction(topicId, level);
  }
}

module.exports = KnowledgePage;
