const MediaModel = require('@common/libs/file/MediaModel');

class DocumentMedia extends MediaModel {
  defaults() {
    return {type: 'document'};
  }

  urlRoot() {
    return `${ super.urlRoot(...arguments) }/document`;
  }
}

module.exports = DocumentMedia;
