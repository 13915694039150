const Marionette = require('Marionette');
const _ = require('underscore');

class FilterableSelectorItem extends Marionette.ItemView {
  tagName() {
    return 'li';
  }

  className() {
    // Although there can be a case where multiple items will have the same id,
    // this case is very rare. This is why we keep using id here instead of using cid.
    return `filterable-selector-item qa-${ this.model.get('id') } ax-button`;
  }

  getTemplate() {
    return '<a href="#" tabindex="-1" data-cid="<%- cid %>"><%- value %></a>';
  }

  modelEvents() {
    return {'change:selected': 'toggleSelected'};
  }

  templateHelpers() {
    return {
      ...this.model.toOption(),
      cid: this.model.cid
    };
  }

  onRender() {
    this.toggleSelected();
  }

  toggleSelected() {
    if (this.model.get('selected')) {
      this.$el.addClass('selected ax-button--branded');
    } else {
      this.$el.removeClass('selected ax-button--branded');
    }
  }

  serializeData(...args) {
    if (_.isFunction(this.model.toOption)) {
      this.model.toOption();
    } else {
      super.serializeData(args);
    }
  }
}

module.exports = FilterableSelectorItem;
