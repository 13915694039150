const { ItemView } = require('Marionette');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const DiscoverTrainingCardTopRegionLayoutView = require('@common/components/trainingCards/DiscoverTrainingCardTopRegionLayoutView');
const DiscoverTrainingCardHeaderInfoView = require('@common/components/trainingCards/DiscoverTrainingCardHeaderInfoView');
const DiscoverTrainingCardGraphicView = require('@common/components/trainingCards/DiscoverTrainingCardGraphicView');

const getGraphicRegionConfig = function() {
  return {
    viewControllerClass: ItemView,
    viewDefinition: {
      ViewClass: DiscoverTrainingCardGraphicView
    }
  };
};

const getHeaderInfoRegion = function({title, description}) {
  return {
    viewControllerClass: ItemView,
    viewDefinition: {
      ViewClass: DiscoverTrainingCardHeaderInfoView,
      title,
      description
    }
  };
};

module.exports = {
  getDiscoverCardTopRegionConfig: ({
    title,
    description
  } = {}) => {
    const controllerDefinition = {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: DiscoverTrainingCardTopRegionLayoutView
      },
      regionControllers: {
        graphicRegion: getGraphicRegionConfig(),
        headerInfoRegion: getHeaderInfoRegion({title, description})
      }
    };

    return controllerDefinition;
  }
};
