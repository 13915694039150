const Backbone = require('Backbone');
const _ = require('underscore');

class Tag extends Backbone.Model {
  className() {
    return 'Tag';
  }

  apiEndpoint() {
    return '/tags';
  }

  defaults() {
    return {formId: parseInt(_.uniqueId(), 10)};
  }

  parse(res) {
    return res.entity || res;
  }

  toOptionCheckbox() {
    return {
      value: this.get('formId'),
      label: this.get('count') !== undefined ? `${ this.get('tag') } (${ this.get('count') })` : `${ this.get('tag') }`
    };
  }
}

module.exports = Tag;
