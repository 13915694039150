const { LayoutView } = require('Marionette');

class SelectCoachPage extends LayoutView {
  get template() {
    return require('@training/apps/training/coachSelection/SelectCoachPage.html');
  }

  className() {
    return 'fullwidth selectcoach';
  }

  regions() {
    return {
      coachesListRegion: '.coacheslist'
    };
  }
}

module.exports = SelectCoachPage;
