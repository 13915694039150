const $ = require('jquery');
const logging = require('logging');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

const I18n = require('@common/libs/I18n');
const AssessmentType = require('@common/data/enums/AssessmentType');

const AssessmentModelFactory = require('@common/data/models/assessments/AssessmentModelFactory');

const DEFAULT_TOAST_TIMEOUT = 7000; //10s

class SessionBoundAssessmentFactory {
  constructor(trainingSession, assessmentInitiatorTracker) {
    this.trainingSession = trainingSession;
    this.assessmentInitiatorTracker = assessmentInitiatorTracker;
  }

  createForAssessmentTypeAsync(assessmentType, context, assessmentOptions = {}, params = {}) {
    logging.debug(`Creating for assessment type: ${ assessmentType }`);

    return Promise.resolve(
      this.createTrainingAssessmentOfAssessmentType(
        AssessmentType.assertLegalValue(assessmentType), assessmentOptions, params
      ).then(() => {
        const assessment = this.trainingSession.getCurrentAssessment();

        this.beginTracking(assessment, context);

        return {
          assessment,
          context
        };
      })
    );
  }

  createTrainingAssessmentOfAssessmentType(assessmentType, assessmentOptions = {}, params = {}) {
    const deferred = $.Deferred();
    const {deepLink = false} = params;

    if (this.trainingSession.isNew()) {
      logging.error('Session does not have an ID -- cannot create an assessment.');
      deferred.reject();
      return deferred.promise();
    }

    // Define a different URL only if deepLink is true, just so it makes it easier to identify when the param is set
    const url = deepLink
      ? `${ this.trainingSession.url() }/assessments?deepLink=true`
      : `${ this.trainingSession.url() }/assessments`;

    this.trainingSession.setCurrentAssessment(AssessmentModelFactory.create(assessmentType));

    return this.trainingSession.currentAssessment.save(assessmentOptions, { url }).fail( (xhr) => {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      const errCode = exception.getErrorCode();

      // We want to skip the global error handler for these cases and handel the flash messages here.
      if ([
        AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY,
        AxonifyExceptionCode.CLIENT_ERROR_EMPTY_ASSESSMENT,
        AxonifyExceptionCode.CLIENT_ERROR_ASSESSMENT_NOT_APPLICABLE,
        AxonifyExceptionCode.CLIENT_ERROR_ON_THE_CLOCK_REQUIRED
      ].includes(errCode)) {
        logging.error(`Assessment creation failed for type: ${ assessmentType }, status '${ xhr.status }', errCode '${ errCode }'`);

        xhr.skipGlobalHandler = true;

        this.trainingSession.setCurrentAssessment();

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_ON_THE_CLOCK_REQUIRED) {
          const {
            string,
            timeout
          } = AxonifyExceptionCode.onTheClockRequiredErrorFlashOptions();
          window.app.layout.flash.error(string, timeout);
        } else {
          const hasCustomErrorMessage = I18n.hasString(`errors.assessment.${ errCode }`);
          const errorMessage = hasCustomErrorMessage ? I18n.t(`errors.assessment.${ errCode }`) : I18n.t('errors.assessment.errorCreatingAssessment');
          window.app.layout.flash.error(errorMessage, DEFAULT_TOAST_TIMEOUT);
        }
      }
    });
  }

  beginTracking(assessment, initiatorContext) {
    this.assessmentInitiatorTracker.trackAssessment(initiatorContext, assessment);
  }
}

module.exports = SessionBoundAssessmentFactory;
