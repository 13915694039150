const LayerContextConfig = require('@common/modules/main/layer/LayerContextConfig');

module.exports = (contextModel) => {
  const layerRegionControllers = {
    contentRegion: () => {
      return LayerContextConfig.getContentControllerDefinition(contextModel);
    }
  };

  const overlayController = LayerContextConfig.getOverlayControllerDefinition(contextModel);
  if (overlayController) {
    layerRegionControllers.overlayRegion = overlayController;
  }

  return layerRegionControllers;
};
