// Module
var code = `<div class="full-width relative">
  <h1 class="page-header">
    <div class="ax-container">
      <div class="page-header__content">
        <span class="page-header__title"><%- t('errorPage.oops') %></span>
      </div>
    </div>
  </h1>
  <div class="ax-container">
    <div class="page-content">
      <div class="message">
        <%- errorMsg %>
      </div>

      <div class="buttonbar topmargin">
        <a href="#" class="axon-button retry continue"><%- t('general.continue') %></a>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;