const { CollectionView } = require('Marionette');

const MenuControllerDefinitionFactory = require('@common/modules/main/menu/MenuControllerDefinitionFactory');
const BottomMenuLayoutView = require('@common/modules/main/footer/BottomMenuLayoutView');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const MenuPosition = require('@common/modules/main/menu/MenuPosition');
const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');
const OverflowMenuItem = require('@common/modules/main/footer/OverflowMenuItem');

module.exports = (contextModel) => {
  const overflowItems = MenuContextConfig.getItemsOverflow(contextModel);
  const displayItems = MenuContextConfig.getItemsDisplay(contextModel);

  const _updateLayout = (view) => {
    view.updateLayout({
      displayCount: displayItems.length,
      overflowCount: overflowItems.length,
      minLayout: MenuContextConfig.getIsMinimalLayout(contextModel),
      hiddenLayout: MenuContextConfig.getIsHiddenlyLayout(contextModel)
    });
  };

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: BottomMenuLayoutView,
    regionControllers: {
      menu: MenuControllerDefinitionFactory(contextModel, MenuPosition.Bottom),
      overflowMenu: {
        viewDefinition: {
          ViewClass: CollectionView,
          tagName: 'ul',
          childView: OverflowMenuItem,
          collection: overflowItems
        }
      }
    },
    delegateEvents: {
      'view:inflate': (controller, view) => {
        controller.listenTo(overflowItems, 'reset add remove', () => {
          _updateLayout(view);
        });

        controller.listenTo(displayItems, 'reset add remove', () => {
          _updateLayout(view);
        });

        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.CURRENT_MENU_POSITION }`, () => {
          view.toggleMenuOpen(false);
        });

        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.IS_HIDDEN_LAYOUT }`, () => {
          _updateLayout(view);
        });
      }
    }
  };
};
