const Backbone = require('Backbone');

class Coach extends Backbone.Model {
  className() {
    return 'Coach';
  }

  urlRoot() {
    return '/axonify/selfserve/coaches';
  }

  parse(res = {}) {
    return res.entity || res;
  }

  sync(method, model, options = {}) {
    const methodName = method.toLowerCase();
    switch (methodName) {
      case 'update':
        options.url = '/axonify/selfserve/coaches/mine/';
        break;
    }
    return Backbone.sync(method, model, options);
  }
}

module.exports = Coach;
