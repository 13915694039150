const _ = require('underscore');
const logging = require('logging');

const PasswordComplexityRules = require('@common/modules/auth/common/PasswordComplexityRules');
const NumberHelpers = require('@common/libs/helpers/types/NumberHelpers');

/**
 * This class is used for parsing password complexity rules in the form of..
 *
 * 8x4 (8 characters min, 4 groups required)
 * 6x4 (6 characters required, 4 groups required)
 *
 * ... etc. It is used to prevent duplicating a bunch of the logic for places that to get the rules out.
  Complex format convention: AxB where A is min password length, B is min number of unique groups
 */
class PasswordComplexityParser {
  parse(passwordComplexityString) {
    if (_.isString(passwordComplexityString)) {
      let [minLength, minGroups] = passwordComplexityString.toLowerCase().split('x');

      minLength = NumberHelpers.filterInt(minLength);
      minGroups = NumberHelpers.filterInt(minGroups);

      if (isNaN(minLength) || isNaN(minGroups)) {
        logging.error(`Password's ComplexFormat not valid: ${ passwordComplexityString }`);
        return new PasswordComplexityRules(0, 0);
      }
      return new PasswordComplexityRules(minLength, minGroups);

    }
    // This will not enforce anything at all
    return new PasswordComplexityRules(0, 0);

  }

  static getStringForMinCharactersDefault() {
    const DEFAULT_MIN_CHARS = 8;
    return PasswordComplexityParser.getStringForMinCharacters(DEFAULT_MIN_CHARS);
  }

  static getStringForMinCharacters(minCharacters) {
    return `${ minCharacters }x0`;
  }
}

module.exports = PasswordComplexityParser;
