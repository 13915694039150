const Backbone = require('Backbone');

const Sync = require('@common/libs/Sync');

require('backbone.virtual-collection');

const _VC = Backbone.VirtualCollection;

// Since the VirtualCollection is a in memory wrapper around it's given collection,
// we can generate the wrapping functions for all of the crudUtilities functions
// to allow them all to work still. Overriding the constructor allows us to
// proxy the sync mutator methods properly for each generated instance of the VirtualCollection
Backbone.VirtualCollection = function(collection, options) {
  const vc = new _VC(collection, options);
  Sync.proxySyncMutatorMethods(collection, vc);
  return vc;
};
