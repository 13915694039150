const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const ReactionsEnum = require('@common/data/enums/ReactionsEnum');

const ReactionTabTemplate = require('@training/apps/search/modals/reactionsModal/ReactionTabTemplate.html');
const ReactionModalTabContentCollectionView = require('@training/apps/search/modals/reactionsModal/ReactionModalTabContentCollectionView');

const _getTabText = function(name) {
  return (name === 'all') ? I18n.t('selfDirected.search.categoryNames.all') : null;
};

module.exports = {
  getTabConfigs: (reactionsModel) => {
    const tabList = ['all', ...ReactionsEnum.values()];

    const tabData = {
      initialTab: 'all',
      tabConfigs: [],
      tabbedLayoutTemplate: ReactionTabTemplate
    };

    _.each(tabList, (key) => {
      const reactionCollection = reactionsModel.get(key).reactions;
      const collectionCount = reactionsModel.get(key).count;

      tabData.tabConfigs.push({
        tab: {
          tabName: key,
          tabKey: key.toUpperCase(),
          tabText: _getTabText(key),
          showCount: true,
          collectionCount,
          getAriaLabel: (type, count) => {
            if (type === 'ALL') {
              return [
                I18n.t(`reaction.reactionType.${ type }.name`),
                ' ',
                I18n.t(`reaction.reactionType.${ type }.reactionCount`, { count })
              ].join('');
            }
            return [
              I18n.t(`reaction.reactionType.${ type }.name`),
              ': ',
              I18n.t(`reaction.reactionType.${ type }.reactionCount`, { count })
            ].join('');
          },
          contentCollection: reactionCollection
        },
        tabContentController: {
          viewDefinition: {
            ViewClass: ReactionModalTabContentCollectionView,
            collection: reactionCollection,
            tabName: key
          }
        }
      });
    });

    return tabData;
  }
};
