// Module
var code = `<div class="catalog-filter__header">
  <h2 class="catalog-filter__title"><%- t('learnerTrainingPage.catalog.filter.filter') %></h2>
  <button class="icon-remove js-close-catalog-filter"></button>
</div>

<form class="js-form catalog-filter__form">
  <div class="catalog-filter__type-container">
    <h2 class="catalog-filter__title catalog-filter__title--first"><%- t('learnerTrainingPage.catalog.filter.type') %></h2>
    <ul class="catalog-filter__type-radios" data-editor="RadioGroup" data-options="browseTypeFilters" data-field="browseType"></ul>
  </div>

  <div class="catalog-filter__subject-container hidden">
    <h2>
      <p class="catalog-filter__title ax-font--no-margin"><%- t('learnerTrainingPage.catalog.filter.subjects') %></p>
    </h2>
    <ul class="catalog-filter__subject-checkboxes" data-editor="Checkboxes" data-options="subjectFilters" data-field="subjectList" data-shallow="true"></ul>
  </div>
</form>

<div class="catalog-filter__action-button--wrapper">
  <button type="button" class="ax-button catalog-filter__reset-button">
    <%- t('general.reset') %>
  </button>
  <button type="button" class="ax-button ax-button--branded catalog-filter__apply-button">
    <%- t('general.apply') %>
  </button>
</div>
`;
// Exports
module.exports = code;