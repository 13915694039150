class PeriodicCollectionPoller {
  constructor(options = {}) {
    if (options.collection == null) {
      throw new Error('A collection must be provided');
    }
    // Assign defaults
    this._collection = options.collection;
    this._timeoutPeriod = options.periodMs != null ? options.periodMs : 3500;
    this._continuePollingPredicate = options.predicate;
    // This function needs to return a jQuery Deferred
    this._fetchingStrategy = options.fetchingStrategy != null ? options.fetchingStrategy : () => {
      return this._collection.fetch();
    };
    this._pollingToken = null;
  }

  startPolling() {
    // You cannot start the queue while it's in progress, this is a no-op
    if (this._pollingToken !== null) {
      return this.deferred.promise();
    }

    this.deferred = $.Deferred();
    const check = () => {
      return this._fetchingStrategy().done(() => {
        if (!this._continuePollingPredicate()) {
          this.stopPolling();
          return this.deferred.resolve();
        }
      })
        .fail(() => {
          this.stopPolling();
          return this.deferred.reject();
        })
        .promise();
    };

    check();
    this._pollingToken = setInterval(check, this._timeoutPeriod);
    return this.deferred.promise();
  }

  stopPolling() {
    if (this._pollingToken != null) {
      clearInterval(this._pollingToken);
      this._pollingToken = null;
    }
  }
}
module.exports = PeriodicCollectionPoller;
