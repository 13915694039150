import {
  Link as MuiLink,
  styled,
  type LinkTypeMap as MuiLinkTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { isString } from 'lodash';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';
import { type ThemeTypographyVariantsType } from '../../ThemeTypography';


type AxLinkCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * Class name for the link
   */
  className?: string
  /**
   * Uses the brandable link color
   */
  branded?: boolean
  variant?: ThemeTypographyVariantsType // redefines variant type, so it shows the correct possible values in props description in Storybook
};

export type AxLinkTypeMap<P = object, D extends React.ElementType = 'a'> = MuiLinkTypeMap<P & AxLinkCustomProps, D>;

export type AxLinkProps<
  D extends ElementType = AxLinkTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxLinkTypeMap<P & AxLinkCustomProps, D>, D>;

const shouldForwardProp = (prop: string): boolean => {
  return prop !== 'branded';
};

const StyledAxLink = styled(MuiLink, {
  name: 'AxLink',
  shouldForwardProp
})<AxLinkProps>(({
  branded,
  theme,
  color
}) => {
  const styles = {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    cursor: 'pointer',
    color: theme.uiKit.colorBlue60,
    textDecorationColor: theme.uiKit.colorBlue60,
    '.MuiIcon-root, \
     .MuiSvgIcon-root, \
     [class^="icon-"]': {
      color: 'inherit'
    },
    '&.Mui-focusVisible:focus': theme.mixins.componentKeyboardFocusStyles(),
    '&[disabled]': {
      color: theme.uiKit.colorGrey50,
      textDecoration: 'none',
      cursor: 'auto',
      pointerEvents: 'none'
    }
  };

  if (branded) {
    styles.color = theme.uiKit.linkColor;
    styles.textDecorationColor = theme.uiKit.linkColor;
  }

  if (isString(color)) {
    styles.color = color;
    styles.textDecorationColor = color;
  }

  return styles;
});

export const AxLink: MuiOverridableComponent<AxLinkTypeMap> = forwardRef(({
  children,
  variant = 'paragraph',
  underline = 'always',
  branded = true,
  ...otherProps
}: AxLinkProps, ref: Ref<HTMLAnchorElement>) => {
  const linkProps: AxLinkProps = {
    children,
    variant,
    underline,
    branded,
    ...otherProps
  };

  return (
    <StyledAxLink { ...linkProps } ref={ ref }>
      { children }
    </StyledAxLink>
  );
});

export default AxLink;
