const I18n = require('@common/libs/I18n');

const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

const File = require('@common/libs/file/File');
const ImageFile = require('@common/libs/file/ImageFile');
const VideoFile = require('@common/libs/file/VideoFile');
const DocumentFile = require('@common/libs/file/DocumentFile');
const VTTFile = require('@common/libs/file/VTTFile');
const SCORMFile = require('@common/libs/file/SCORMFile');
const TinCanFile = require('@common/libs/file/TinCanFile');

const fileTypeClassMap = {
  image: ImageFile,
  video: VideoFile,
  csv: File,
  vtt: VTTFile,
  file: DocumentFile,
  document: DocumentFile,
  scorm: SCORMFile,
  tincan: TinCanFile
};

// $input is a reference to an <input> element with type 'file'
// if $input doesn't have any files, returns null
const createFileFromInput = ($input, fileOptions = {}) => {
  let name;
  const fileInput = $input.get(0);
  const nativeFileAPI = isFileApiSupported();

  if (nativeFileAPI) {
    name = fileInput.files && fileInput.files[0] && fileInput.files[0].name;
  } else {
    // It is an old browser that doesn't support the File API
    // Get input value and remove path to normalize
    name = $input.val() && $input.val().replace(/.*(\/|\\)/, '');
  }

  if (name == null) {
    return null;
  }

  const fileType = getFileTypeOfInput($input);
  const options = Object.assign({}, fileOptions, {
    fileInput,
    nativeFileAPI,
    name,
    fileType
  });

  return createFile(fileType, {}, options);
};

// Create a File model from the server side representation, we just pass the json
// straight through, the class will parse it and set the fields.
const createFileFromJSON = (fileType, json, fileOptions) => {
  const options = Object.assign({}, fileOptions, { fileType });
  return createFile(fileType, json, options);
};

const createFileFromUUID = (uuid) => {
  const options = Object.assign({ fileType: 'file', documentsAllowed: true });
  return createFile(options.fileType, { uuid }, options);
};

const isFileApiSupported = () => {
  return BrowserHelpers.isFileApiSupported();
};

const getFileTypeOfInput = ($input) => {
  return BrowserHelpers.getFileTypeOfInput($input);
};

const createFile = function(fileType, attrs, options) {
  validateFileType(fileType, options);

  const FileClass = fileTypeClassMap[fileType];
  return new FileClass(attrs, options);
};

const validateFileType = function(fileType, fileOptions = {}) {
  if (!Object.keys(fileTypeClassMap).includes(fileType)) {
    throw new Error(I18n.t('fileFactory.fileTypeNotSupported'));
  } else if ((fileType === 'video') && !(fileOptions.videoAllowed != null ? fileOptions.videoAllowed : true)) {
    throw new Error(I18n.t('fileFactory.noVideosAllowed'));
  } else if (['file', 'document'].includes(fileType) && !(fileOptions.documentsAllowed != null ? fileOptions.documentsAllowed : false)) {
    // TODO: consolidate file types so that file and document are one in the same?
    throw new Error(I18n.t('fileFactory.noDocumentsAllowed'));
  }
};

module.exports = {
  createFileFromInput,
  createFileFromJSON,
  createFileFromUUID
};
