const BannerView = require('@common/components/banner/BannerView');

module.exports = ({model}) => {
  return () => {
    return {
      viewDefinition: {
        ViewClass: BannerView,
        model
      }
    };
  };
};
