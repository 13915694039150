const env = require('env');

const XhrHeaders = require('XhrHeaders');

const addRequestTokenHeader = (settings = {}, token) => {
  Object.assign(settings, {
    headers: Object.assign({
      [XhrHeaders.REQUEST_TOKEN]: token
    }, settings.headers)
  });
};

module.exports = (settings = {}, originalSettings = {}) => {
  if (originalSettings.headers != null && originalSettings.headers[XhrHeaders.REQUEST_TOKEN] != null) {
    return;
  }

  const token = env.uuid();

  // Setting the same token on the current settings, so that the first request has the header,
  // as well as the original settings, so that any retry request will have the same request token.
  // The token should only get generated once so that's why there's a check at the beginning of the function.
  addRequestTokenHeader(settings, token);
  addRequestTokenHeader(originalSettings, token);
};
