const Backbone = require('Backbone');

class UserMetadata extends Backbone.Model {
  apiEndpoint() {
    return '/pageCommunities/userMetadata';
  }

  canViewReports() {
    return this.get('permittedActions') && this.get('permittedActions').VIEW_REPORTS;
  }

  canPost() {
    return this.get('authorablePageTypes') && this.get('authorablePageTypes').POST;
  }
}

module.exports = UserMetadata;
