const LocaleBundle = require('./LocaleBundle');

const NonTranslatableString = require(`@common/assets/strings/nonTranslatable/en/str.js`);

class CommonNonTranslatableLocaleBundle extends LocaleBundle {
  load() {
    return Promise.resolve(NonTranslatableString);
  }
}

module.exports = CommonNonTranslatableLocaleBundle;
