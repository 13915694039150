const I18n = require('@common/libs/I18n');
const logging = require('logging');

const KnowledgeItemRecommendationFactory = {
  getReasonString(reasonKey) {
    switch (reasonKey) {
      case 'PERFORMANCE':
        return I18n.t('extratraining.recommended.reasons.performance');
      case 'PROGRESS_LEVEL':
        return I18n.t('extratraining.recommended.reasons.pregress');
      case 'RECENCY':
        return I18n.t('extratraining.recommended.reasons.recency');
      case 'CURRENT_CONFIDENCE':
        return I18n.t('extratraining.recommended.reasons.confidence');
      default:
        logging.error(`"${ reasonKey }" is an invalid recommendation reasonKey.`);
        return '';
    }
  }
};

module.exports = KnowledgeItemRecommendationFactory;
