class Timer {

  constructor(appIdentifier) {
    this.setTimeDeltaToServerMs(0);
    this.setAppStartDateMs(null);

    this.autoIncrement = 1;
    this.startedLog = [];
    this.timeLog = [];
    this.appIdentifier = appIdentifier;
  }

  setTimeDeltaToServerMs(timeDeltaToServerMs = 0) {
    this.timeDeltaToServerMs = timeDeltaToServerMs;
  }

  setAppStartDateMs(appStartDateMs) {
    this.appStartDateMs = this.appStartDateMs || appStartDateMs;
  }

  getMsTime(id) {
    const startLogEntry = this._getStartLogEntry(id);

    if (!startLogEntry) {
      return 0;
    }

    if (startLogEntry.startTimeMs != null) {
      return startLogEntry.durationMs + (new Date()).getTime() - startLogEntry.startTimeMs;
    }

    return startLogEntry.durationMs || 0;
  }

  getTime(id) {
    return Math.floor(this.getMsTime(id) / 1000);
  }

  start(options = {}, referenceId) {
    const {startEventType, type} = options;

    if (startEventType != null) {
      this.generateTimeLog({
        type: startEventType,
        appStartDate: this.appStartDateMs,
        referenceId,
        seconds: 0,
        appIdentifier: this.appIdentifier
      });
    }

    return this._generateStartLog({
      type,
      appStartDate: this.appStartDateMs,
      referenceId,
      startTimeMs: new Date().getTime(),
      appIdentifier: this.appIdentifier,
      options
    });
  }

  pause(id) {
    const startLogEntry = this._getStartLogEntry(id);
    if (!startLogEntry) {
      return undefined;
    }

    startLogEntry.durationMs = this.getMsTime(id);
    delete startLogEntry.startTimeMs;

    return id;
  }

  resume(id) {
    const startLogEntry = this._getStartLogEntry(id);
    if (!startLogEntry) {
      return undefined;
    }

    startLogEntry.startTimeMs = new Date().getTime();

    return id;
  }

  complete(id, referenceIdOverride) {
    const startLogEntry = this._getStartLogEntry(id);

    if (!startLogEntry) {
      return undefined;
    }

    // Answering a question should use the referenceId from the answer so we need
    // to override the original referenceId
    if (referenceIdOverride != null) {
      startLogEntry.referenceId = referenceIdOverride;
    }

    // Mark this start log entry as "complete" so that it can be stopped
    startLogEntry.isComplete = true;

    return id;
  }

  stop(id, referenceIdOverride) {
    const startLogEntry = this._getStartLogEntry(id);

    if (!startLogEntry) {
      return undefined;
    }

    // Check if `isComplete` and don't stop unless it's true
    if (!startLogEntry.isComplete) {
      return undefined;
    }

    if (referenceIdOverride != null) {
      startLogEntry.referenceId = referenceIdOverride;
    }

    const { type, referenceId, appStartDate, appIdentifier } = startLogEntry;
    const seconds = this.getTime(id);

    this._removeStartLogEntry(startLogEntry);

    return this.generateTimeLog({type, referenceId, seconds, appStartDate, appIdentifier});
  }

  stopAll() {
    // Stop all started logs. E.g. in the case of page unload we want to stop all
    // started logs and send the time log. We do it in reverse however so that
    // the most recently started time log is the one stopped first, i.e. LIFO.
    [...this.startedLog]
      .reverse()
      .forEach((log) => {
        this.stop(log.id);
      });
  }

  getTimeLogEntries() {
    // return the time log entries so far and start a new time log
    const { timeLog } = this;
    this.timeLog = [];
    return timeLog;
  }

  getStartLogEntryByType(type) {
    for (const logEntry of this.startedLog) {
      if (logEntry.type === type) {
        return logEntry;
      }
    }
    return undefined;
  }

  _generateStartLog(data) {
    if (data == null) {
      return null;
    }

    const id = this.autoIncrement++;

    data.id = id;

    if (data.durationMs == null) {
      data.durationMs = 0;
    }

    // If the `logOnlyWhenComplete` option is `true` we shouldn't stop this time
    // log until it's "complete." This means on page unload we'll just discard
    // this log entry because we need to start the timer from the beginning the
    // next time. This is necessary for app backgrounding and to set the correct
    // reference id for questions for instance.
    data.isComplete = !data.options.logOnlyWhenComplete;

    this.startedLog.push(data);
    return id;
  }

  generateTimeLog(data) {
    if (data == null) {
      return data;
    }

    data.recordDate = new Date().getTime() + this.timeDeltaToServerMs;

    this.timeLog.push(data);

    return data;
  }

  _getStartLogEntry(id) {
    for (const logEntry of this.startedLog) {
      if (logEntry.id === id) {
        return logEntry;
      }
    }
    return undefined;
  }

  _removeStartLogEntry(logEntry) {
    const logEntryIndex = this.startedLog.indexOf(logEntry);

    if (logEntryIndex < 0) {
      return undefined;
    }

    const partA = this.startedLog.slice(0, logEntryIndex);
    const partB = this.startedLog.slice(logEntryIndex + 1);

    this.startedLog = [].concat(partA, partB);

    return logEntry;
  }
}

module.exports = Timer;
