// Module
var code = `<div class="contentwrapper">
  <div class="modal-title-container">
    <h1 class="games-intro-name"><%- t('games.teamGame.challengeSelection.howTo')%></h1>
  </div>
  <div class="challenge-wrapper">
    <div class="header-container">
      <div class="header challenge-team"><%- t('games.teamGame.challengeSelection.headers.team')%></div>
      <div class="header challenge-score"><%- t('games.teamGame.challengeSelection.headers.score')%></div>
      <div class="header challenge-time"><%- t('games.teamGame.challengeSelection.headers.timeRemaining')%></div>
    </div>
    <% _.each(challenges, function(challenge){ %>
      <div class="challenge active clearfix" data-challenge-id="<%- challenge.id %>">
        <div class="participants">
          <div class="participant-row">
            <div class="item challenge-team">
              <span class="team-name"><%- challenge.currentTeam.team.name %></span> <span class="team-name-suffix"><%- t('games.teamGame.challengeSelection.currentTeamSuffix')%></span>
            </div>
            <div class="item challenge-score">
              <span class="current-team-score"><%- challenge.currentTeam.score %></span>
            </div>
          </div>
          <div class="participant-row">
            <div class="item challenge-team">
              <span class="team-name"><%- challenge.opponent.team.name %></span>
            </div>
            <div class="item challenge-score">
              <span class="current-team-score"><%- challenge.opponent.score %></span>
            </div>
          </div>
        </div>
        <div class="item challenge-time <%- challenge.timeClass %>">
          <span><%- challenge.timeRemaining %></span>
        </div>
      </div>
    <% }) %>
  </div>
</div>
`;
// Exports
module.exports = code;