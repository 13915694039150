// Module
var code = `<form id="login-wrapper" class="<%- loginWrapperClasses %>">
  <h1 class="login-heading"><%= t('login.trainingLoginMsg') %></h1>

  <div id="login-branding" class="clearfix">
    <div class="login-form clearfix">
      <% if(shouldShowLoginInputFields) { %>

      <label for="username"><%- t('login.username') %></label>
      <input id="username" name="username" data-field="user" type="text" autocorrect="off" autocapitalize="off"
        autocomplete="username" placeholder="<%- t('login.enterUsername') %>" aria-label="<%- t('login.username') %>" class="username" />

      <label for="password"><%- t('login.password') %></label>
      <div id="password" data-editor="Password" data-field="passwd" data-options="passwordOptions" placeholder="<%- t('login.enterPassword') %>"></div>

      <div class="buttonbar clearfix">
        <button class="axon-button login"><%- t('login.loginbtn') %></button>
      </div>
        <% if (schemes.includes(AuthenticationScheme.SelfServe)) { %>
          <div class="reset-links-wrapper">
            <div class="reset-links">
              <a href="#" id="forgotUsernameLink"><%- t('login.forgotusername') %></a><span class="pipe-sign" aria-hidden="true">|</span>
              <a href="#" id="forgotPasswordLink"><%- t('login.forgotpassword') %></a>
            </div>
          </div>
        <% } %>

      <% } %>
      <div class="saml-button-wrapper"></div>
    </div>

    <% if (oauthSettings.length && isAuthSchemesOAuthEnabled) { %>
    <div class="oauth-providers clearfix">
      <% var loginWithGoogleText = t('login.withOAuthProvider', {"oauthProvider": "Google"}); %>
      <p class="hidden boldfont"><%- t('login.googleMobileOAuthWarning', {"oauthProvider": loginWithGoogleText}) %></p>
    </div>
    <% } %>
  </div>
  <div id="mfa-branding" class=" mfa-form hidden clearfix">
    <%= axInput({
      label: t('login.mfa.mfaauthcode'),
      name:'code',
      autocorrect:'off',
      autocomplete:'off',
      autocapitalize:'off',
      ariaLabel: t('login.mfa.mfaauthcode'),
      autocomplete: 'off',
      classNameInput: 'mfa-form__code',
      data: {
        field: 'code'
      },
      placeholder: ''
    }) %>
    <%= axCheckbox({ className: 'mfa-form__checkbox', label: t('login.mfa.rememberMe', {days: superUserRememberMeDuration}) }) %>
      <div class="buttonbar clearfix">
        <button class="axon-button verify"><%- t('login.mfa.verify') %></button>
      </div>
  </div>

  <div class="contact"><%= helpContact %></div>

  <div id="login-bottom">
    <a href="#" id="activation-link"><%- t('login.resetapp') %> <br><span><%- workplaceUrl %></span></a>
  </div>

  <% if (showLanguageSelector) { %>
  <div class="login-language-selector-wrapper">
    <div class="login-language-selector">
      <label for="language-select"><%- t('general.language') %></label>
      <%= axSelect({
        ariaLabel: t('login.forgotUsername.languageSelector'),
        classNameSelect: 'qa-language-select',
        data: {
          field: 'lang',
          options: 'languages'
        },
        id: 'language-select',
        name: 'language',
        noFormEditorDefault: 'true'
      }) %>
    </div>
  </div>
  <% } %>

  <% if (inApp) { %>
  <div class="privacy-policy">
    <a href="/policies/privacy.html" target="_blank"><%- t('login.privacyPolicy') %></a>
  </div>
  <% } %>

</form>
`;
// Exports
module.exports = code;