const LocaleBundle = require('./LocaleBundle');
const moment = require('moment');

class MomentLocaleBundle extends LocaleBundle {
  constructor(options = {}) {
    super(options);

    this.localeMap = {
      es: 'es-us',
      'es-es': 'es',
      fl: 'tl-ph',
      fr: 'fr-ca',
      'fr-fr': 'fr',
      hy: 'hy-am',
      pt: 'pt-br',
      'pt-pt': 'pt',
      'ro-md': 'ro',
      sr: 'sr-cyrl',
      'sr-lt': 'sr',
      no: 'nb',
      pa: 'pa-in',
      zh: 'zh-cn'
    };
    this.notSupportedLocales = ['my-ha', 'ht', 'so'];
  }

  load(localeCode) {
    // set locale to first part (Base locale) if it doesn't exist in mapping
    this.locale = this.localeMap[localeCode] != null ? this.localeMap[localeCode] : localeCode;

    if (localeCode !== 'en' && !this.notSupportedLocales.includes(localeCode)) {
      return import(`moment/locale/${ this.locale }`);
    }

    // This is done on purpose, bundles return null
    // to indiciate that they need not be loaded in this current context
    return super.load();
  }

  onPostLoad() {
    return moment.locale(this.locale);
  }
}

module.exports = MomentLocaleBundle;
