const Backbone = require('Backbone');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const VideoTypeEnum = require('@common/components/video/VideoTypeEnum');

const YOU_TUBE_REGEX = /^http(s)?:\/\/(www\.)?youtu(be\.com\/watch(.*?)(\?|\&)v=|\.be\/|be\.com\/v\/)([a-z0-9_-]+)/i; //eslint-disable-line
const VIMEO_REGEX = /^http(s)?:\/\/(player\.)?vimeo\.com(\/video)?\/([0-9]+)(\?(.*?&)?h=([0-9a-z]+)|\/([0-9a-z]+))?/i;
const BLOB_REGEX = /^blob:(.*)/i;

class VideoPackageModel extends Backbone.Model {

  constructor(attributes = {}, options = {}) {
    if (attributes instanceof Backbone.Model) {
      super(attributes.toJSON(), options);
    } else {
      super(attributes, options);
    }
  }

  /**
   * This method not only returns the type, it also sets the type attribute to the model. Smelly!
   */
  getType() {
    let type = this.get('type');
    if ((type == null)) {
      if (this.isYouTubeMedia()) {
        type = VideoTypeEnum.YOUTUBE;
      } else if (this.isVimeoMedia()) {
        type = VideoTypeEnum.VIMEO;
      } else if (this.isBlobMedia()) {
        type = VideoTypeEnum.BLOB;
      } else if (this.isTranscodedMedia()) {
        type = VideoTypeEnum.TRANSCODED;
      } else if (this.isValidUrl()) {
        type = VideoTypeEnum.OTHER;
      }
    }
    this.set('type', type);
    return type;
  }

  getUrl() {
    return this.get('url') || '';
  }

  getMedia() {
    return this.get('media');
  }

  getMediaFormats() {
    const mediaTranscoding = this.getMediaTranscoding();
    return mediaTranscoding ? mediaTranscoding.formats : undefined;
  }

  getMediaClosedCaptions() {
    const media = this.getMedia();
    return media ? media.closedCaptions : undefined;
  }

  getMediaTranscoding() {
    const media = this.getMedia();
    if (!media) {
      return undefined;
    }
    if (media.transcoding) {
      return media.transcoding;
    }
    if (_.isFunction(media.get)) {
      return media.get('transcoding');
    }
    return undefined;
  }

  getMediaPoster() {
    const media = this.getMedia() || {};
    if (media.thumbnail != null) {
      return media.thumbnail.file.path;
    }
    return undefined;
  }

  getMediaOriginalFileName() {
    const media = this.getMedia() || {};
    if (media.originalFile != null) {
      return media.originalFile.originalFileName;
    }
    return undefined;
  }

  isEmptyLink() {
    if (this.isTranscodedMedia()) {
      return (this.getMedia() == null);
    }
    return this.getUrl().length === 0;

  }

  getYouTubeKey() {
    return YOU_TUBE_REGEX.exec(this.getUrl())[6];
  }

  getVimeoKey() {
    const vimeoRegexMatches = VIMEO_REGEX.exec(this.getUrl());
    // for vimeo.com/key OR player.vimeo.com/video/key
    return vimeoRegexMatches[4];
  }

  getVimeoHash() {
    const vimeoRegexMatches = VIMEO_REGEX.exec(this.getUrl());
    // for vimeo.com/key/unlisted_hash
    const hash = vimeoRegexMatches[8];
    if (hash) {
      return hash;
    }
    // for player.vimeo.com/video/key?h=unlisted_hash
    return vimeoRegexMatches[7];
  }

  getNativeWidth() {
    const mediaTranscoding = this.getMediaTranscoding();
    return mediaTranscoding ? mediaTranscoding.width : undefined;
  }

  getNativeHeight() {
    const mediaTranscoding = this.getMediaTranscoding();
    return mediaTranscoding ? mediaTranscoding.height : undefined;
  }

  // Determine the video's aspect ratio if the nativeWidth/Height is passed along.
  getNativeAspectRatio() {
    const nativeWidth = this.getNativeWidth();
    const nativeHeight = this.getNativeHeight();
    if (((nativeWidth != null) > 0) && ((nativeHeight != null) > 0)) {
      return nativeWidth / nativeHeight;
    }
    return null;

  }

  isMediaTranscodingAvailable() {
    const mediaTranscoding = this.getMediaTranscoding();
    return mediaTranscoding ? mediaTranscoding.status === 'done' : false;
  }

  isMediaTranscodingFailed() {
    const mediaTranscoding = this.getMediaTranscoding();
    return mediaTranscoding ? mediaTranscoding.status === 'failed' : false;
  }

  isYouTubeMedia() {
    return YOU_TUBE_REGEX.test(this.getUrl());
  }

  isVimeoMedia() {
    return VIMEO_REGEX.test(this.getUrl());
  }

  isSupportedVideoUrl() {
    return this.isYouTubeMedia() || this.isVimeoMedia() || this.isValidUrl();
  }

  isBlobMedia() {
    return BLOB_REGEX.test(this.getUrl());
  }

  isTranscodedMedia() {
    return (this.getMediaTranscoding() != null);
  }

  isValidUrl() {
    return UrlHelpers.isUrl(this.getUrl()) && !(this.getUrl().match(/youtu/i) || this.getUrl().match(/vimeo/i));
  }

  validate() {
    const errors = [];
    // Validate the URL is a valid one if not empty
    if (!this.isTranscodedMedia()) {
      if (!this.isSupportedVideoUrl(this.getUrl()) && (this.getUrl() !== '')) {
        errors.push(I18n.t('errors.VideoPackageModel.requiresValidUrl'));
      }
    }
    return errors;
  }
}

module.exports = VideoPackageModel;
