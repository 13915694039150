const Marionette = require('Marionette');
const TaskIndexLayoutHeaderView = require('@training/apps/legacyTasks/views/TaskIndexLayoutHeaderView');

class TaskIndexLayoutView extends Marionette.LayoutView {
  getTemplate() {
    return require('@training/apps/legacyTasks/views/TaskIndexLayoutView.html');
  }

  className() {
    return 'task-index';
  }

  regions() {
    return {
      contentRegion: '.js-task-index-content-region',
      headerRegion: '.js-task-index-header'
    };
  }

  showInContentRegion(view) {
    return this.contentRegion.show(view);
  }

  showDetailHeader() {
    const headerView = new TaskIndexLayoutHeaderView();
    this.headerRegion.show(headerView);
  }

  registerActionBar(actionBarSettings) {
    return this.actionBar.setActionBar(actionBarSettings);
  }

  onDestroy() {
    return this.actionBar.setActionBar({});
  }
}

module.exports = TaskIndexLayoutView;
