const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const ReportsIndexLayout = require('@training/apps/search/reports/ReportsIndexLayout');
const CommunityAction = require('@common/data/enums/CommunityAction');
const FilterableCommunityController = require('@common/components/filterableCommunity/FilterableCommunityController');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const ReportDataList = require('@training/apps/search/reports/domain/ReportDataList');
const PageStatusPreview = require('@training/apps/search/reports/pages/dashboard/previews/pages_by_status/PageStatusPreview');

const ReportsTableLayoutController = require('@training/apps/search/reports/pages/dashboard/ReportsTableLayoutController');
const ReportsTableCollectionView = require('@training/apps/search/reports/pages/dashboard/ReportsTableCollectionView');

const { BreadcrumbCollectionView } = require('@common/components/breadcrumb/BreadcrumbCollectionView');
const { parseBreadcrumbsFromSearchPageState } = require('@training/apps/common/libs/helpers/BreadcrumbHelper');
const { getAllCommunitiesModel } = require('@common/components/filterableCommunity/ReportsCommunityHelper');

const { ItemView } = require('Marionette');
const Backbone = require('Backbone');

class ArticleReportsController extends LayoutController {
  initialize(options = {}) {
    ({
      searchPageState: this.searchPageState,
      title: this.title
    } = options);

    this.filterContext = getAllCommunitiesModel();
    this.searchPageState.set('filterContext', this.filterContext);
  }

  viewDefinition() {
    return {
      ViewClass: ReportsIndexLayout
    };
  }

  _onUpdateChartFail(collection, xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.pageAccess.community.error.3017'));
    }
  }

  regionControllers() {
    const searchPageState = this.searchPageState;

    this.param = {};

    this.chartCollection = new ReportDataList([], {
      reportType: 'pagestatus',
      additionalOptions: this.param
    });

    this.chartCollection.fetch({ error: this._onUpdateChartFail });

    return {
      breadcrumbRegion: {
        viewDefinition: {
          ViewClass: BreadcrumbCollectionView,
          collection: parseBreadcrumbsFromSearchPageState(this.searchPageState),
          searchPageState,
          doOnClick: (model) => {
            if (model && model.get('target')) {
              Backbone.history.navigate(model.get('target'), { trigger: true });
            }
          },
          hideLastCrumb: true
        }
      },
      titleRegion: {
        viewDefinition: {
          ViewClass: ItemView,
          className: 'community-title-wrapper',
          template: `
            <h2>
              <div class="community-title">${ this.title }</div>
            </h2>
          `
        }
      },
      community: {
        ViewControllerClass: FilterableCommunityController,
        contextModel: this.filterContext,
        community: this.filterContext.get('community'),
        hasAllCommunitiesOption: true,
        label: I18n.t('discover.insights.selectSingleCommunityLabel'),
        communityAction: CommunityAction.VIEW_REPORTS,
        setCommunityHandler: (model) => {
          if (model.get('id') != null) {
            this.filterContext.set('community', model);
            this.searchPageState.set('filterContext', this.filterContext);
          }
        }
      },
      pagesByStatus: {
        viewDefinition: {
          ViewClass: PageStatusPreview,
          collection: this.chartCollection
        },
        delegateEvents: {
          'view:attach': (controller, view) => {
            this.listenTo(this.filterContext, 'change', () => {
              this.param.communityId = this.filterContext.get('community').get('id');

              if (this.param.communityId != null) {
                this.chartCollection.reset();
                this.chartCollection.fetch({ error: this._onUpdateChartFail });
              }

              this.listenTo(this.chartCollection, 'sync', () => {
                view._updateChart(this.chartCollection);
              });
            });
          }
        }
      },
      topPages: {
        ViewControllerClass: ReportsTableLayoutController,
        pageTitle: 'discover.browse.reports.pages.TOP_PAGES.title',
        tableTitle: 'discover.browse.reports.pages.TOP_PAGES.table.name',
        tableCount: 'discover.browse.reports.pages.TOP_PAGES.table.count',
        collectionClass: ReportsTableCollectionView,
        reportType: 'pageviews',
        hasResult: null,
        delegateEvents: {
          'view:attach': (controller) => {
            this.listenTo(this.filterContext, 'change', () => {
              controller._updateTable(this.filterContext, ReportsTableCollectionView);
            });
          }
        }
      },
      topKeywords: {
        ViewControllerClass: ReportsTableLayoutController,
        pageTitle: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH.title',
        tableTitle: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH.table.name',
        tableCount: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH.table.count',
        collectionClass: ReportsTableCollectionView,
        reportType: 'searchrecords',
        hasResult: null,
        delegateEvents: {
          'view:attach': (controller) => {
            this.listenTo(this.filterContext, 'change', () => {
              controller._updateTable(this.filterContext, ReportsTableCollectionView);
            });
          }
        }
      },
      topKeywordsEmptySearch: {
        ViewControllerClass: ReportsTableLayoutController,
        pageTitle: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH_EMPTY.title',
        tableTitle: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH_EMPTY.table.name',
        tableCount: 'discover.browse.reports.pages.TOP_KEYWORDS_IN_SEARCH_EMPTY.table.count',
        collectionClass: ReportsTableCollectionView,
        reportType: 'searchrecords',
        hasResult: false,
        delegateEvents: {
          'view:attach': (controller) => {
            this.listenTo(this.filterContext, 'change', () => {
              controller._updateTable(this.filterContext, ReportsTableCollectionView);
            });
          }
        }
      }
    };
  }
}

module.exports = ArticleReportsController;
