const Backbone = require('Backbone');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');

class User extends Backbone.Model {
  get className() {
    return 'User';
  }

  parse(res) {
    const instance = res.entity || res;
    instance.initials = StringHelpers.getInitials(instance.fullname);
    return instance;
  }
}

module.exports = User;
