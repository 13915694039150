const GreetingView = require('@training/apps/home/greeting/GreetingView');

module.exports = (trainingSession, user, hasTrained) => {
  return () => {
    return {
      viewDefinition: {
        ViewClass: GreetingView,
        trainingSession,
        user,
        hasTrained
      }
    };
  };
};
