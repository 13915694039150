const $os = require('detectOS');
const _ = require('underscore');
const logging = require('logging');
const GameManager = require('./GameManager');
const ChallengeSelectionModal = require('../views/ChallengeSelectionModal');
const TeamLeaderboardView = require('../views/TeamLeaderboardView');

class TeamGameManager extends GameManager {
  constructor(questionsController, sessionModel) {
    super(questionsController, sessionModel);
    this.challengeSelected = this.challengeSelected.bind(this);
    this.createChallenge = this.createChallenge.bind(this);
    this.updateTeamGame = this.updateTeamGame.bind(this);
    this.getScoreForLeaderboard = this.getScoreForLeaderboard.bind(this);
  }

  getGameData() {
    const gameData = super.getGameData(...arguments);

    // if this game contains the team game mechanic, add team game specific data
    if (this.game.getMechanic('teamGame') != null) {
      gameData.teamGame = {};
      gameData.teamGame.selectedChallenge = this.game.selectedChallenge;

      // determine the current participant
      if (this.game.selectedChallenge != null) {
        const currentTeam = this.getCurrentTeam();
        const participant = _.find(this.game.selectedChallenge.participants, (participant) => {
          return participant.team.id === currentTeam.id;
        });
        gameData.teamGame.currentParticipant = participant;
      }
    }

    return gameData;
  }

  getCurrentTeam() {
    // Decide if user's current team is its location
    // or customTeam
    if (apps.auth.session.hasCustomTeamLeaderboard()) {
      return window.apps.auth.session.user.get('customTeam');
    }
    return window.apps.auth.session.user.get('location');

  }

  // display a modal to let the user choose which
  // challenge to display
  displayChallengeSelections(startGameCallback) {
    // get game data
    const gameData = this.getGameData();
    const {state: gameState} = gameData && gameData.mechanics && gameData.mechanics.teamGame;

    // create challenges
    const activeChallenges = gameState.activeGames;
    const availableOpponents = [];

    // top it up with the closestPossibleOpponents
    _.each(gameState.closestPossibleOpponents, (team) => {
      // check if team is already part of challenges
      const activeChallenge = _.find(activeChallenges, (challenge) => {
        return _.find(challenge.participants, (participant) => {
          return participant.team.id === team.id;
        });
      });

      // if not in the currently active list,  add
      // them to the list of available opponents
      if (!activeChallenge) {
        availableOpponents.push(team);
      }
    });

    // Show challenge selection modal
    const challengeSelectionModal = new ChallengeSelectionModal({
      challenges: activeChallenges,
      availableOpponents,
      complete: this.challengeSelected,
      startGameCallback,
      currentTeam: this.getCurrentTeam()
    });

    window.app.layout.presentModal(challengeSelectionModal, {
      closeClick: false,
      closeEsc: false,
      forceFixed: $os.mobile,
      showOverlay: $os.desktop,
      overlaySpeed: 0
    });
  }

  challengeSelected(challenge, options = {}) {
    if (challenge == null) {
      logging.error('challengeSelected called with null challenge');
      if (typeof options.startGameCallback === 'function') {
        options.startGameCallback(false);
      }
      return;
    }

    // if id is less than 0, then the challenge needs to be created
    // and initial data needs to be gathered from the game
    if (challenge.id < 0) {
      this.createChallenge(challenge, options);
    } else {
      // set the currentGame attribute to selected one
      // but first need to fetch it from mechanic
      const teamGameMech = this.game.getMechanic('teamGame');
      const gameObject = _.find(teamGameMech.state.activeGames, (activeGame) => {
        return activeGame.id === challenge.id;
      });

      this.game.selectedChallenge = gameObject;

      // call the callback if it was passed in
      (typeof options.startGameCallback === 'function' ? options.startGameCallback(true) : undefined);
    }
  }

  createChallenge(challenge, options) {
    const newGameInfo = this.gameApi.getNewGameInfo();
    const {info = ''} = newGameInfo;
    const {levels = []} = newGameInfo;

    // set the participants
    const participants = [{
      team: challenge.currentTeam.team,
      levels
    }, {
      team: challenge.opponent.team,
      levels
    }];

    const action = {
      mechanic: {
        mechanicId: 'teamGame'
      },
      actionType: 'newTeamGame',
      actionBody: {
        gameInfo: info,
        participants
      }
    };

    this.performGameActions(action, {}, (response = {}) => {
      const gameObject = response.responses && response.responses[0] && response.responses[0].actionResponse;

      // set the data so that the game can know
      // which challenge the user has selected
      this.game.selectedChallenge = gameObject;

      // update the game object within the mechanic
      this.updateTeamGame(gameObject);

      (typeof options.startGameCallback === 'function' ? options.startGameCallback(true) : undefined);
    });
  }

  updateTeamGame(teamGame) {
    const mechanics = this.game.get('mechanics');

    let gameIndex = -1;
    _.each(mechanics.teamGame.state.activeGames, (game, index) => {
      if (game.id === teamGame.id) {
        gameIndex = index;
      }
    });

    if (gameIndex > -1) {
      mechanics.teamGame.state.activeGames[gameIndex] = teamGame;
    } else {
      mechanics.teamGame.state.activeGames.push(teamGame);
    }
  }

  showLeaderboard(scoreCard, gameOverStringId, questionsRemaining, next) {
    // Show game leaderboard
    const v = new TeamLeaderboardView({
      scoreCard,
      questionsRemaining,
      game: this.game,
      gameOverStringId,
      continue: next
    });

    window.app.layout.presentModal(v, {
      closeClick: false,
      closeEsc: false
    });
  }

  getScoreForLeaderboard() {
    const teamGameMechanic = this.game.getMechanic('teamGame');
    return (teamGameMechanic && teamGameMechanic.state && teamGameMechanic.state.scoreBoard) || [];
  }

  saveScore(score) {
    const stampValue = this.generateStamp(score.score);
    $.extend(score, {stamp: stampValue});

    const action = {
      mechanic: {
        mechanicId: 'teamGame'
      },
      actionType: 'postLevelResultScore',
      actionBody: score
    };

    return this.performGameActions(action);
  }

  getActions(score, gameState) {
    const actions = [];
    if (score != null) {
      actions.push([{
        mechanic: {
          mechanicId: 'teamGame'
        },
        actionType: 'postLevelResultScore',
        actionBody: score
      }]);
    }

    if (this.game.getMechanic('userGameState')) {
      actions.push(this._createUserGameStateMechanic(gameState));
    }

    return actions;
  }
}

module.exports = TeamGameManager;
