const Backbone = require('Backbone');

class KnowledgeModulePreview extends Backbone.Model {

  initialize(options = {}) {
    ({
      moduleId: this.moduleId
    } = options);
  }

  apiEndpoint() {
    return `/knowledge/module/${ this.moduleId }/preview`;
  }
}
module.exports = KnowledgeModulePreview;
