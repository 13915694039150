const logging = require('logging');

const { Collection } = require('Backbone');
const { CollectionView } = require('Marionette');
const _ = require('underscore');

const Form = require('@common/components/forms/Form');
const AttachmentItemView = require('@common/components/media/AttachmentItemView');

const ImageFile = require('@common/libs/file/ImageFile');
const VideoFile = require('@common/libs/file/VideoFile');
const DocumentFile = require('@common/libs/file/DocumentFile');

require('jquery.dotdotdot');

require('@common/components/media/AttachmentItemView.less');

Form.Editor.MediaFileUpload = class MediaFileUpload extends Form.Editor {
  getTemplate() {
    // This is a rare use case of tabindex < 0 + aria-hidden, where we do NOT want the native file input HTML to show up
    // in the tab order, nor seen by screen readers, since we are using a <button> to act as the actionable item.
    // Do not use this pattern unless you are aware of the implications
    return `\
      <div class="media-button">
        <%= axButton({
          label: t('discover.metadata.documents.upload'),
          className: 'js-media-file-input',
          size: 'm'
        }) %>
      </div>
      <div class="media-input-wrap">
        <input type="file" id="media-file-input" tabindex="-1" aria-hidden="true"/>
      </div>
      <div class="media-list"></div>\
    `;
  }

  get mediaTemplate() {
    return `\
      <div class="attachment__container clearfix" data-media-id="<%= id %>">
        <div class="attachment-icon icon-<%= type %> blue" aria-hidden="true"></div>
        <p class="attachment-name"><%- name %></p>
        <%= axButton({
          className: 'action-button action-button--delete icon-remove',
          ariaLabel: t('discover.metadata.documents.deleteFile', {name: name}),
        }) %>
      </div>\
    `;
  }

  events() {
    return {
      'change input': 'onChangeFileInput',
      'click .action-button--delete': 'removeMedia',
      'click .js-media-file-input': 'clickMediaInput'
    };
  }

  ui() {
    return {
      mediaList: '.media-list'
    };
  }

  preinitialize(options = {}) {
    // Warning: Keep all initialization above super.initialize()
    // Super can call methods which depends on these values already
    // being initialized.
    this.defaultValue = [];
    this.fileFactory = options.options.fileFactory;
    this.viewTypes = options.options.viewTypes != null ? options.options.viewTypes : [];

    this.documentMediaList = new Collection();
  }

  constructor(options) {
    super(options);

    this.render();
  }

  initialize(options = {}) {
    super.initialize(options);
    this.listenTo(this, 'upload:complete', this.resetMediaUploaderInput);
  }

  onRender() {
    if (this.mediaCollectionView && !this.mediaCollectionView.isDestroyed) {
      this.mediaCollectionView.destroy();
    }

    this.$mediaFileInput = this.$('#media-file-input');
    this.$mediaFileLabel = this.$('label[for=media-file-input]');

    this.mediaCollectionView = new CollectionView({
      el: this.ui.mediaList,
      collection: this.documentMediaList,
      childView: AttachmentItemView,
      childViewOptions: {
        template: this.mediaTemplate
      }
    });
    this.mediaCollectionView.render();
  }

  getValue() {
    return this.mediaCollection;
  }

  setValue(value) {
    if (value == null) {
      this.reset();
      return;
    }
    this.mediaCollection = value;
    this.documentMediaList.reset();

    // Add media to file list
    this.documentMediaList.reset(_.filter(this.mediaCollection, (media) => {
      return this.viewTypes.includes(media.type);
    }));
  }

  clickMediaInput() {
    // Visually shown button will handle invoking the native HTML File input
    this.$mediaFileInput.trigger('click');
  }

  onChangeFileInput(e) {
    this.$input = $(e.target);

    if (this.$input.val() !== '') {
      this.uploadFile(this.$input, 'document');
    }
  }

  uploadFile(file, type) {
    // if there is a file attached, create a file model and attach it
    const fileObj = {
      file,
      fileType: type
    };

    if (fileObj.file) {
      const simpleFileObject = this.fileFactory(fileObj.fileType, {
        'file-type': fileObj.fileType,
        file: fileObj.file
      });

      this.trigger('upload:start');
      this._isFileUploading = true;

      this._fileXhr = simpleFileObject.save(null, {
        success: (model) => {
          return model.createMedia().then(() => {
            this.addDocumentMedia(model.documentMediaModel.toJSON());
            this._isFileUploading = false;
            this.trigger('upload:complete');
            this.trigger('change');
          }, () => {
            this._isFileUploading = false;
            this.trigger('upload:complete');

            // TODO handle the error condition when creating an image media
            logging.error('Error creating document media');
          });
        },

        error: (model, response, options) => {
          this._isFileUploading = false;
          this.trigger('upload:complete');

          const errorKey = response.errMessage;
          this.handleFileUploadError(fileObj.fileType, model, response, options, errorKey);
        }
      });
    }
  }

  // progress: (e, loaded, total) => will be added in the future

  handleFileUploadError(fileType, model, response, options, errorKey) {
    this.trigger('exception', errorKey);
    this.trigger('upload:complete');
  }

  addDocumentMedia(documentMedia) {
    // add this to the collection
    this.mediaCollection.push(documentMedia);
    this.addDocumentMediaToList(documentMedia);
    this.$('.attachment-name').dotdotdot({
      height: 40
    });
  }

  addDocumentMediaToList(documentMedia) {
    // only add media if it's part of the viewTypes
    if (!this.viewTypes.includes(documentMedia.type)) {
      return;
    }

    this.documentMediaList.add(documentMedia);
  }

  onDestroy() {
    if (this._isFileUploading) {
      this._fileXhr.abort();
      this.trigger('upload:complete');
    }

    if (this.$mediaFileLabel != null) {
      this.$mediaFileLabel.off();
    }
  }

  removeMedia(e) {
    const $mediaContainer = $(e.currentTarget).closest('.attachment__container');
    const mediaIdToRemove = $mediaContainer.data('mediaId');

    // remove the item from the media collection
    this.mediaCollection = _.reject(this.mediaCollection, (media) => {
      return media.id === mediaIdToRemove;
    });
    this.documentMediaList.remove(mediaIdToRemove);

    // remove the item from the list
    this.trigger('removeMedia');
  }

  resetMediaUploaderInput() {
    // reset the input to allow to upload the same file again
    this.$mediaFileInput.val('');
    this.$mediaFileInput.replaceWith(this.$mediaFileInput.clone(true));
    this.$mediaFileInput = this.$('#media-file-input');
  }

  reset() {
    this.mediaCollection = [];
    this.file = null;
    this.value = null;
    this.simpleFileObject = null;
  }

  _createMedia(fileType, fileData, fileOptions = {}) {
    const options = _.extend({}, fileOptions, {
      fileType
    });
    const FileClass = (() => {
      switch (fileType) {
        case 'image':
          return ImageFile;
        case 'video':
          return VideoFile;
        case 'document':
          return DocumentFile;
        default:
          return File;
      }
    })();
    return new FileClass(fileData, options);
  }
};

module.exports = Form.Editor.MediaFileUpload;
