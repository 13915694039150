const _ = require('underscore');
class XssScrubber {
  constructor(options = {}) {
    let tagWhitelist, customSchemeWhitelist;
    ({tagWhitelist = {}, customSchemeWhitelist = []} = options);
    this.scrubElements = this.scrubElements.bind(this);
    this._tagWhitelist = $.extend(true, {}, XssScrubber.DefaultWhitelist, tagWhitelist);

    const defaultProtocolWhitelist = ['ftp', 'http', 'https', 'mailto', 'tel'];
    const protocolWhitelist = [...new Set([...defaultProtocolWhitelist, ...customSchemeWhitelist])];

    this._scrubOptions = {
      protocolWhitelist: protocolWhitelist
    };
  }

  scrubElements($element) {
    const $elementsToScrub = $element.contents();
    return $elementsToScrub.each((index, element) => {
      if (element.nodeType === 3) { // Text node; IE8 sucks and doesn't define Node :(
        return;
      } else if (element.nodeType === 8) { // Comment node; IE8 sucks and doesn't define Node
        $(element).remove();
        return;
      }
      let $handle = $(element);
      const nodeName = element.nodeName.toLowerCase();
      if (this._tagWhitelist.tags[nodeName]) {
        $handle = this._tagWhitelist.tags[nodeName].scrubTag($handle, index, $element, this._scrubOptions);
      }
      if (element.attributes) {
        const attributes = $.map(element.attributes, (item) => {
          const attr = item.name.toString().toLowerCase();
          const tagsToIgnore = (this._tagWhitelist.tags[nodeName] && this._tagWhitelist.tags[nodeName].attr) ? this._tagWhitelist.tags[nodeName].attr : [];
          if (tagsToIgnore.includes(attr)) {
            return;
          }
          return attr;
        });
        $.each(attributes, (index, attr) => {
          return $handle.removeAttr(attr);
        });
      }
      $handle.removeData();
      // now clean this element's children
      return this.scrubElements($handle);
    });
  }
}

XssScrubber.DefaultWhitelist = {
  tags: {
    a: {
      attr: ['href', 'target'],
      scrubTag($handle, index, $element, scrubOptions = {protocolWhitelist: []}) {
        let linkText;
        $handle.attr('target', '_blank');
        // need to make sure the protocol is one of the
        // defined or custom protocol whitelist
        const protocol = ($handle.attr('href') && $handle.attr('href').split(':')[0]) ? $handle.attr('href').split(':')[0].toLowerCase() : '';
        if (!_.contains(scrubOptions.protocolWhitelist, protocol)) {
          // does not contain an approved protocol so set the href tag
          // to '' and set the text to it
          linkText = $handle.attr('href');
          $handle.attr('href', '');
        } else {
          // protocol is fine so get the link's text, if it's empty,
          // put the link's href
          linkText = $handle.text();
          if (linkText.length < 1) {
            linkText = $handle.attr('href');
          }
        }
        $handle.html(linkText);
        return $handle;
      }
    }
  }
};
module.exports = XssScrubber;
