const File = require('@common/libs/file/File');

const FileHelpers = require('@common/libs/helpers/app/FileHelpers');

class DocumentFile extends File {
  static SIZE_LIMIT = 30; // in MB;

  initialize(attrs, options) {
    super.initialize(attrs, options);

    this.fileType = 'document';
    this.sizeLimit = DocumentFile.SIZE_LIMIT;
  }

  isPreviewable() {
    // TODO: refactor the `isPreviewable` out to the preview or media layer
    return FileHelpers.getFileType(this.getName()) === 'pdf';
  }
}

module.exports = DocumentFile;
