const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');

class FilterableSelectorEmptyView extends Marionette.ItemView {
  tagName() {
    return 'li';
  }

  className() {
    return 'filterable-selector-empty-view';
  }

  getTemplate() {
    return '<%- getEmptyText() %>';
  }

  templateHelpers() {
    return {getEmptyText: () => {
      return this.emptyText != null ? this.emptyText : I18n.t('multiFilter.emptyText');
    }};
  }

  initialize(options = {}) {
    ({emptyText: this.emptyText} = options);
  }
}

module.exports = FilterableSelectorEmptyView;
