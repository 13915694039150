const _ = require('underscore');
const VideoPlayer = require('@common/components/video/players/VideoPlayer');

class OtherUrlPlayer extends VideoPlayer {
  constructor(...args) {
    super(...args);
    this.template = _.tpl(` <iframe
      width="<%= width %>"
      height="<%= height %>"
      src="<%= url %>"
      frameborder="0"
      allowfullscreen
      sandbox="allow-same-origin allow-scripts allow-forms">
    </iframe>\
    `);
  }

  renderPlayer() {
    this._$iframe = this.$('iframe');
    this._triggerLoad();
    return this._triggerEnd();
  }

  getTemplateOptions() {
    return _.extend(super.getTemplateOptions(), {url: this.getVideoUrl()});
  }

  getVideoUrl() {
    return this.model.getUrl();
  }

  removePlayer() {
    this._$iframe.attr('src', '');
    return this._$iframe.attr('src', this.getVideoUrl());
  }

  _setAspectRatio() {
    return super._setAspectRatio(16 / 9);
  }
}

module.exports = OtherUrlPlayer;
