const { Behavior, isNodeAttached } = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');

Behaviors.MutationObservable = class MutationObservable extends Behavior {

  static takeRecords(view, callback) {
    view.$el.trigger('takeRecords.mutationObservable', [callback]);
  }

  defaults() {
    return {
      target: '',
      observeOptions: {}
    };
  }

  ui() {
    return {
      target: this.getOption('target')
    };
  }

  events() {
    return {
      'takeRecords.mutationObservable': 'onTakeRecords'
    };
  }

  initialize() {
    this.observer = new MutationObserver(this.onMutation.bind(this));
  }

  onMutation(...args) {
    if (isNodeAttached(this._getTargetEl())) {
      this.view.triggerMethod('dom:mutate', ...args);
    }
  }

  onAttach() {
    this.observer.observe(this._getTargetEl(), this.getOption('observeOptions'));
  }

  onTakeRecords(e, callback = () => {}) {
    callback(this.observer.takeRecords());
  }

  onDestroy() {
    this.observer.disconnect();
  }

  _getTargetEl() {
    if (this.ui.target.length > 0) {
      return this.ui.target[0];
    }

    return this.$el[0];
  }
};

module.exports = Behaviors.MutationObservable;
