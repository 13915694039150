// Module
var code = `<style id="<%- id %>">
<%
_.each(coaches, function(coach) {
  var urlObj = selectCoachUrl(coach);
  var cssClass = getCssClass(coach);
%>
<%- cssClass %>{ background-image: url('<%= urlObj.coachUrl %>'); }

@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (min-resolution: 124.8dpi),
only screen and (min-resolution: 1.3dppx) {
  <%- cssClass %>{ background-image: url('<%= urlObj.retinaCoachUrl %>'); }
}
<%
  });
%>
</style>

`;
// Exports
module.exports = code;