const AdjustableModalView = require('@training/apps/main/views/AdjustableModalView');
const KeyCode = require('@common/data/enums/KeyCode');

class LeaderboardViewBase extends AdjustableModalView {

  constructor(options = {}) {
    super(options);

    this.keyPress = this.keyPress.bind(this);
    this.onContinue = this.onContinue.bind(this);
  }

  onRenderActionBar() {
    this.actionBar.setActionButton({
      type: 'donegame',
      onClick: this.onContinue
    });
  }

  keyPress(e) {
    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      return this.onContinue();
    }

    return undefined;
  }

  viewDidAppear() {
    super.viewDidAppear();
    $(document).on('keypress', this.keyPress);
  }

  onContinue() {
    if (this.exitedGame) {
      return false;
    }
    this.exitedGame = true;

    $(document).off('keypress');

    this.dismiss();

    // Exit full screen
    if (typeof window.app.layout.exitFullScreen === 'function') {
      window.app.layout.exitFullScreen();
    }

    // Move on
    this.options.continue();
    return false;
  }
}

module.exports = LeaderboardViewBase;
