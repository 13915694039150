const { ItemView } = require('Marionette');

class BannerView extends ItemView {
  className() {
    return 'branded-banner';
  }

  get template() {
    return `<div class="branded-banner__low-res"></div>
    <div class="branded-banner__high-res js-banner-full-res"></div>
    <div class="branded-banner__overlay"></div>
`;
  }

  ui() {
    return {
      bannerFullRes: '.js-banner-full-res'
    };
  }

  onRender() {
    this._fadeInFullRes();
  }

  _fadeInFullRes() {
    const $banner = this.ui.bannerFullRes;
    if (!$banner || $banner.length === 0) {
      return;
    }

    const imgPath = this.model.get('bannerFullResPath');
    $banner.hide();

    setTimeout(() => {
      if (imgPath) {
        $('<img>').attr('src', imgPath)
          .on('load', () => {
            $banner.velocity('fadeIn', { duration: 800 } ); // start fade in once image is loaded in memory
          });
      } else {
        $banner.velocity('fadeIn', { duration: 800 } ); // fallback, safe to display div even if image isn't set
      }
    }, 1); // timeout allows img path to be set in DOM
  }
}

module.exports = BannerView;
