const _ = require('underscore');

const getMemoizedFunctionWithInvalidator = function(fn, {memoizeFn = _.memoize} = {}) {
  let invalidationCount = 0;

  const invalidateFn = function() {
    invalidationCount++;
  };

  const memoizedFn = memoizeFn(fn, () => {
    return invalidationCount;
  });

  return {
    memoizedFn,
    invalidateFn
  };
};

module.exports = {
  getMemoizedFunctionWithInvalidator
};
