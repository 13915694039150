// Module
var code = `<div class="page-content">
  <div class="ax-grid ax-grid--no-gutter">
    <div class="ax-grid__col--12 ax-grid__col--m-9 community-insights__breadcrumbs breadcrumb-title-wrapper">
      <div class="breadcrumb-path ax-grid__col--auto-size" role="navigation"><div class="header-breadcrumbs js-community-insights__breadcrumbs"></div></div>
      <div class="community-insights__title">
        <h1 class="ax-font"><%- t('discover.insights.communicationInsightsTitle') %></h1>
      </div>
    </div>
    <div class="ax-grid__col--12 ax-grid__col--m-3">
      <div class="js-community-insights__how-it-works community-insights__how-it-works ax-grid"></div>
    </div>
  </div>
  <div class="community-insights__filters js-community-insights__filters"></div>

  <div class="community-insights__overview-wrapper card card--large">
    <div class="community-insights__overview-header ax-grid">
      <div class="ax-col ax-grid__col--12 ax-grid__col--m-3">
        <h2 class="community-insights__overview-title"><%- t('general.overview') %></h2>

      </div>
      <div class="ax-col ax-grid__col--12 ax-grid__col--m-9">
        <div class="community-insights-date-filter community-insights-date-filter--overview js-community-insights__date-filter"></div>
      </div>
    </div>
    <div class="community-insights__overview js-community-insights__overview"></div>
  </div>
  <div class="community-insights__table-region js-community-insights__table-region"></div>
</div>
`;
// Exports
module.exports = code;