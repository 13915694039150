// Module
var code = `<button class="enroll__enroll-button axon-button block js-enroll"><%- t('SelfDirectedPaths.path.enroll') %></button>
<div class="unenroll card card--informational">
  <div class="unenroll__title">
    <%- t('SelfDirectedPaths.path.enrolled') %>
  </div>
  <div class="unenroll__trigger"></div>
</div>
<ul class="unenroll__actions card hidden">
  <li class="unenroll__action-item js-unenroll-modal">
    <%- t('SelfDirectedPaths.path.unenroll') %>
  </li>
</ul>
`;
// Exports
module.exports = code;