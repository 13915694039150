const Backbone = require('Backbone');

class LearningObjectiveProgressModel extends Backbone.Model {
  defaults() {
    return {
      finishedCount: 0,
      availableCount: 1,
      barColor: '#1767DD', // @ax-color-blue-60
      animate: true
    };
  }

  getTotalObjectiveProgress() {
    return (this.get('finishedCount') / this.get('availableCount')) * 100;
  }
}

module.exports = LearningObjectiveProgressModel;
