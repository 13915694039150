const BackButton = require('@common/modules/main/header/back/BackButton');

module.exports = {
  createBackButton(backClickHandler) {
    return {
      ViewClass: BackButton,
      events: {
        click: backClickHandler
      }
    };
  }
};
