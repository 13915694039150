const I18n = require('@common/libs/I18n');
const $os = require('detectOS');
const { ItemView } = require('Marionette');

const HeartbeatController = require('@common/libs/HeartbeatController');
const ExternalWindowService = require('@common/services/externalWindow/ExternalWindowService');
const ExternalLinkDefaultTemplateHelpers
  = require('@training/apps/training/views/activities/pages/ExternalLinkDefaultTemplateHelpers');

class PDFNewWindowView extends ItemView {
  initialize(options = {}) {
    ({
      checkBoxState: this.checkBoxState,
      activity: this.activity,
      canSkip: this.canSkip
    } = options);

    this.heartbeatController = new HeartbeatController();
  }

  className() {
    return 'external-link-page ax-container';
  }

  getTemplate() {
    return require('@training/apps/training/templates/activities/pages/ExternalLinkPage.html');
  }

  templateHelpers() {
    return Object.assign({}, ExternalLinkDefaultTemplateHelpers.get(this.activity), {
      isPDFModule: true
    });
  }

  ui() {
    return {
      startButton: '#start',
      pdfComplianceRegion: '.pdf-compliance-region',
      complianceCheckbox: '#pdf-compliance-checkbox'
    };
  }

  events() {
    return {
      'click @ui.startButton': this.onClickStart,
      'click @ui.complianceCheckbox': this.onClickCheckbox
    };
  }

  behaviors() {
    return {
      Card: {
        target: '.topic-tile'
      }
    };
  }

  onRender() {
    const previewerUrl = this._buildPreviewerURL();

    this.PDFExternalWindowService = new ExternalWindowService({
      windowOptions: {
        href: previewerUrl,
        windowId: 'Axonify_External_PDF'
      },
      popupBlockedHandler: (message) => {
        window.app.layout.flash.error(message);
      }
    });
  }

  _buildPreviewerURL() {
    const fileLink = this.activity.get('body').fileLink;
    const moduleUUID = fileLink.file.uuid;
    const language = fileLink.language;

    // CI-1242 - Some pdfs causing app to freeze when viewed in pdfjs on iOS 16+
    if ($os.ios) {
      return fileLink.file.path;
    }

    return `/previewer?type=pdf&token=${ moduleUUID }&language=${ language }`;
  }

  onClickStart() {
    this.PDFExternalWindowService.openWindow();

    this._updateUiElementsModuleCompleted();
    if (!this.canSkip) {
      this._showComplianceMessage();
    }

    this.heartbeatController.startHeartbeat();
  }

  onClickCheckbox() {
    this.checkBoxState.set('isChecked', this.ui.complianceCheckbox[0].checked);
  }

  _updateUiElementsModuleCompleted() {
    this.ui.startButton.text(I18n.t('general.relaunch'));
    this.ui.startButton.toggleClass('white', true);
  }

  _showComplianceMessage() {
    this.ui.pdfComplianceRegion.toggleClass('hidden', false);

    this.checkBoxState.set('isShown', true);
  }

  onDestroy() {
    if (this.PDFExternalWindowService != null) {
      this.PDFExternalWindowService.closeWindow();
    }

    this.heartbeatController.stopHeartbeat();
  }
}

module.exports = PDFNewWindowView;
