const CSSInjectorPackageFactory = require('@common/services/cssLoader/CSSInjectorPackageFactory');

const { getStylePath } = require('@common/libs/helpers/app/CompilationInfoHelpers');

const KEY = 'metadata-edit-package';

class Select2CssPackage extends CSSInjectorPackageFactory {
  create(options = {}) {
    const { key, paths } = super.create(options);

    return {
      key,
      paths: [
        ...paths,
        getStylePath(process.styleSourceKey.select2Css)
      ]
    };
  }
}

module.exports = {
  KEY,
  Factory: Select2CssPackage.bind(Select2CssPackage, KEY)
};
