const logging = require('logging');

const localStorageTest = (value = '') => {
  const key = 'local-storage-test';

  localStorage.setItem(key, value);
  localStorage.removeItem(key);

  // Workaround for IE11, IE7 mode since it does fully support all the
  // use cases we use localStorage as (such as an indexable object)
  delete localStorage[key];
};

// Different browsers throw different errors when localstorage is full so we do a test up front and use the result
// for our localstorage specific helper methods.
const DOM_STORAGE_QUOTA_REACHED_ERROR = (() => {
  const repeat = function(str, x) {
    return new Array(x + 1).join(str);
  };

  // each JS character is 2 bytes which makes this a 12MB large string which should be bigger than most browsers allow.
  const tooBig = repeat('x', 12 * 1024 * 1024 / 2);

  try {
    localStorageTest(tooBig);
  } catch (e) {
    return e.code;
  }

  // Most browsers use this error code for this purpose so we'll use it as a fallback if the test above fails for whatever reason.
  return DOMException.QUOTA_EXCEEDED_ERR;
})();

const supportsLocalStorage = () => {
  try {
    const supported = ('localStorage' in window);
    if (supported) {
      localStorageTest();
    }
    return supported;
  } catch (error) {
    return false;
  }
};

const LocalStorageHelpers = {
  supportsLocalStorage,

  // Memoize the reference to a storage object for saving state. Use `localStorage`
  // if supported or a simple object that stays in memory until the app is closed.
  getStateStorageObject: (function() {
    const localStore = supportsLocalStorage()
      ? window.localStorage
      : {};

    return () => {
      return localStore;
    };
  })(),

  // The following is not meant to replace userCredentials in storage, but is used as part of a quick check that
  // persistent objects belong to the same users that saved them.
  localStorageUserKey: 'localStorageUserId',

  getLocalStorageUserId() {
    return this.getStateStorageObject()[this.localStorageUserKey];
  },

  setLocalStorageUserId(id) {
    this.getStateStorageObject()[this.localStorageUserKey] = id;
  },

  isLocalStorageFull() {
    try {
      localStorageTest();
      return false;
    } catch (error) {
      return this.isLocalStorageFullError(error);
    }
  },

  isLocalStorageFullError(error) {
    return error.code === DOM_STORAGE_QUOTA_REACHED_ERROR;
  },

  setItem(key, value) {
    this.getStateStorageObject()[key] = JSON.stringify(value);
  },

  removeItem(key) {
    delete this.getStateStorageObject()[key];
  },

  getItem(key) {
    try {
      const value = this.getStateStorageObject()[key];
      return value ? JSON.parse(value) : undefined;
    } catch (e) {
      logging.error('Error getting item from localStorage: ', e);
      return undefined;
    }
  }
};

module.exports = LocalStorageHelpers;
