const CSSInjectorPackageFactory = require('@common/services/cssLoader/CSSInjectorPackageFactory');

const KEY = 'froala-editor';

const { getStylePath } = require('@common/libs/helpers/app/CompilationInfoHelpers');

class FroalaCSSPackage extends CSSInjectorPackageFactory {
  create(options = {}) {
    const { key, paths } = super.create(options);

    return {
      key,
      paths: [
        ...paths,
        getStylePath(process.styleSourceKey.froalaPkgdCss)
      ]
    };
  }
}

module.exports = {
  KEY,
  Factory: FroalaCSSPackage.bind(FroalaCSSPackage, KEY)
};
