const _ = require('underscore');
const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');

class ReportDataList extends InfiniteCollection {
  apiEndpoint() {
    return '/report';
  }

  initialize(models = [], options = {}) {
    super.initialize(models, options);
    (
      {
        reportType: this.reportType,
        additionalOptions: this.additionalOptions
      } = options
    );

    this.state.pageSize = 40;
  }

  parse(res = {}) {
    return (res && res.results && res.results.children) ? res.results.children : res.results || res;
  }

  parseRecords(data = {}) {
    return data.results;
  }

  setParameters(parameters) {
    this.searchParameters = parameters;
  }

  fetch(options = {}) {
    const optionsClone = _.clone(options);
    const reportType = optionsClone.reportType != null ? optionsClone.reportType : this.reportType;
    optionsClone.url = this.url() + `/${ reportType }`;
    optionsClone.contentType = 'application/json';

    let searchParameters = $.extend(true, {}, this.searchParameters);
    searchParameters = $.extend(true, searchParameters, this.additionalOptions);

    return super.fetch($.extend(true, {data: searchParameters}, optionsClone));
  }

  getInitialSort() {
    return {
      sortValue: 'row_count',
      sortDirection: 'DESC'
    };
  }
}

module.exports = ReportDataList;
