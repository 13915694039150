const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const TrainingModuleDeliveryMethodEnum = require('@common/data/enums/TrainingModuleDeliveryMethodEnum');
const {
  isInApp,
  canOpenChildWebViews,
  isMsTeamsIntegration
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

const PDFActivityViewFactory = (PDFDeliveryMethodControllers = {}) => {
  return (options = {}) => {
    const { activity } = options;

    let PDFDeliveryMethod = activity.getDeliveryMethod() || TrainingModuleDeliveryMethodEnum.EMBEDDED;

    // not all mobile app versions support opening child windows
    if (isInApp() && !canOpenChildWebViews() || isMsTeamsIntegration()) {
      PDFDeliveryMethod = TrainingModuleDeliveryMethodEnum.EMBEDDED;
    }

    const ViewController = PDFDeliveryMethodControllers[PDFDeliveryMethod];

    return ViewControllerFactory.createLegacyView(ViewController, options);
  };
};

module.exports = PDFActivityViewFactory;
