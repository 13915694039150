const _ = require('underscore');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

const {
  QuestionPreviewPage,
  QuestionItemView
} = require('@common/components/questions/views/QuestionPreviewPage/QuestionPreviewPage');

class FillInTheBlanksQuestionPreviewPage extends QuestionPreviewPage {

  getTemplate() {
    return `\
<div id="answer-header"></div>
<div id="answer-body" class="answerwrapper"></div>
<div id="reason-text-body"></div>\
`;
  }

  className() {
    return 'fitb-view';
  }

  regions() {
    return _.extend(super.regions(...arguments), {
      answerOptions: '#answer-body'
    });
  }

  _getQuestionTextview() {
    return new FillInTheBlanksQuestionItemView({
      model: this.model
    });
  }
}

class FillInTheBlanksQuestionItemView extends QuestionItemView {

  constructor(...args) {
    super(...args);
    this.answerTemplate = require('@common/components/questions/templates/fillInTheBlank/questionAnswerText.html');
    this.unAnsweredTemplate = require('@common/components/questions/templates/fillInTheBlank/unansweredQuestionAnswerText.html');
    this.scrollBarWidth = 60;
  }

  getTemplate() {
    return require('@common/components/questions/templates/fillInTheBlank/questionText.html');
  }

  ui() {
    return {
      hintText: '.hinttext',
      reasonText: '#reason',
      questionAnswer: '.questionask',
      questionContainer: '#fitb-question-text',
      blankAnswer: '.blankanswer'
    };
  }

  initialize() {
    super.initialize(...arguments);

    this.translatedLanguage = this.model.get('translatedLanguage');
    this.answerOptionList = this.model.get('questionOptionList');
    this.hasAnswered = (this.model.get('hasAnswered') == null) || this.model.get('hasAnswered');
    this.variantOptions = this.variant.get('unAnsweredOptions') || this.variant.get('options');
    this.selectedAnswers = this._getSelectedAnswer();
  }

  onShow() {
    this.ui.questionContainer.html(this._getQuestionText());
    this._populateSelectedAnswers();
    super.onShow(...arguments);
  }

  _getQuestionText() {
    const answerOptions = _.shuffle(this.variantOptions);
    return this._generateQuestionTextTemplateWithResults(answerOptions);
  }

  _generateQuestionTextTemplateWithResults(answerOptions) {
    let questionText = this.variant.get('questionText');
    const replacementPositions = StringHelpers.getIndicesOf(questionText, '[]', false).reverse();

    const template = this.hasAnswered
      ? _.tpl(this.answerTemplate)
      : _.tpl(this.unAnsweredTemplate);

    for (let index = 0; index < replacementPositions.length; index++) {
      const replacementPosition = replacementPositions[index];
      const templateHtml = template({
        maxWidth: this._getMaxWidth(),
        ordinal: replacementPositions.length - index - 1,
        options: answerOptions
      });

      questionText = StringHelpers.splice(questionText, replacementPosition, 2, templateHtml);
    }

    return questionText;
  }

  _getMaxWidth() {
    let maxWidth = 0;

    for (const option of this.variantOptions) {
      const width = HTMLHelpers.getTextWidth(option.optionText);
      if (width > maxWidth) {
        maxWidth = width;
      }
    }

    maxWidth += this.scrollBarWidth;
    return maxWidth;
  }

  _getSelectedAnswer() {
    if ((this.model.get('questionOptionList') != null) && (this.model.get('questionOptionList').length > 0)) {
      return this.answerOptionList.split(',').map((id) => {
        return parseInt(id, 10);
      });
    }
    return _.map(this.variantOptions, (option) => {
      return option.id != null ? option.id : option.previewId;
    });

  }

  _populateSelectedAnswers() {
    const correctOptions = _.filter(this.variantOptions, (option) => {
      return option.correct;
    });
    const $blankAnswer = this.ui.questionContainer.find('.blankanswer');

    correctOptions.sort((a, b) => {
      return a.locationOrdinal - b.locationOrdinal;
    });

    if (this.hasAnswered) {
      this._populateSelectedAnswersForAnsweredQuestion(correctOptions, $blankAnswer);
    } else {
      this._populateSelectedAnswersForNonAnsweredQuestion(correctOptions, $blankAnswer);
    }
  }

  _populateSelectedAnswersForAnsweredQuestion(correctOptions, $blankAnswer) {
    for (var i = 0; i < $blankAnswer.length; i++) {
      let answerClass;
      const answerArea = $blankAnswer[i];
      if (correctOptions[i].id === this.selectedAnswers[i]) {
        answerClass = 'correct';
      } else {
        answerClass = 'incorrect';
        $(answerArea).after(`<span class='correctforincorrect'>${ correctOptions[i].optionText }</span>`);
      }

      const dpContainer = $(answerArea).find('.largedropdowncontainer');
      dpContainer.addClass(answerClass);
      const option = _.find(this.variantOptions, (op) => {
        return op.id === this.selectedAnswers[i];
      });
      dpContainer.find('span').text(option.optionText);
    }
  }

  _populateSelectedAnswersForNonAnsweredQuestion(correctOptions, $blankAnswer) {
    for (let i = 0; i < $blankAnswer.length; i++) {
      const answerArea = $blankAnswer[i];
      const optionIdToCheck = correctOptions[i].id != null ? correctOptions[i].id : correctOptions[i].previewId;

      const correctOption = _.find(this.variantOptions, (op) => {
        const idToUse = op.id != null ? op.id : op.previewId;
        return idToUse === optionIdToCheck;
      });

      _.each($(answerArea).find('option'), (option) => {
        const idToCheck = correctOption.id != null ? correctOption.id : correctOption.previewId;

        if (parseInt(option.value, 10) === idToCheck) {
          option.selected = true;
        }
      });
    }
  }

  serializeData() {
    const data = super.serializeData(...arguments);
    const language = data.translatedLanguage;
    const optionListToUse = data.variant.get('unAnsweredOptions') || data.variant.get('options');

    _.each(optionListToUse, (option) => {
      if (option.id != null) {
        option.previewId = option.id;
      }
    });

    if (language) {
      data.variant.set('questionText', data.variant.get('questionTextTranslation').getValueForLanguage(language, true));

      _.each(optionListToUse, (option) => {
        return option.optionText = option.optionTextTranslation.getValueForLanguage(language, true);
      });

      data.variant.set('reasonText', data.variant.get('reasonTextTranslation').getValueForLanguage(language, true));
    }

    return data;
  }
}

module.exports = FillInTheBlanksQuestionPreviewPage;
