import {
  TableFooter as MuiTableFooter,
  styled,
  type TableFooterTypeMap as MuiTableFooterTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTableFooterCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableFooterTypeMap<P = object, D extends React.ElementType = 'tfoot'> = MuiTableFooterTypeMap<P & AxTableFooterCustomProps, D>;

export type AxTableFooterProps<
  D extends ElementType = AxTableFooterTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableFooterTypeMap<P & AxTableFooterCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTableFooter'
};

const StyledAxTableFooter = styled(MuiTableFooter, styledOptions)<AxTableFooterProps>(() => {
  return {};
});

/**
 * An optional container for the footer row(s) of the table. Renders as a `<tfoot>` by default.
 *
 * ## Links
 * - • [MUI | Table Footer API](https://mui.com/material-ui/api/table-footer/)
 */
export const AxTableFooter: MuiOverridableComponent<AxTableFooterTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableFooterProps, ref: Ref<HTMLTableSectionElement>) => {
  const tableFooterProps: AxTableFooterProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableFooter { ...tableFooterProps } ref={ ref }>
      { children }
    </StyledAxTableFooter>
  );
});

export default AxTableFooter;
