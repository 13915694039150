const { CollectionView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');
const Community = require('@common/data/models/Community');
const RecentCommunityList = require('@common/data/collections/RecentCommunityList');

const Cocktail = require('backbone.cocktail');

class CommunityCollectionView extends CollectionView {
  tagName() {
    return 'ul';
  }

  className() {
    return 'filtered-communities-list communities-list';
  }

  initialize(options = {}) {
    ({
      fetchDataOptions: this.fetchDataOptionsModel,
      noResultsHandler: this.noResultsHandler,
      oneResultHandler: this.oneResultHandler,
      showAllCommunities: this.showAllCommunities = false,
      preventFetch: this.preventFetch = false
    } = options);

    this._refetchData();

    this.shouldLoadMorePages = this.shouldLoadMorePages.bind(this);

    this.listenTo(this.fetchDataOptionsModel, 'change', this._refetchData);
  }

  onAttach() {
    this.$scrollContainer = this.$el.closest('.js-dropdown-menu');
  }

  onLoadNext() {
    if (!this.preventFetch) {
      this.fetchNext();
    }
  }

  shouldLoadMorePages(scrollContainer) {
    if (this.collection instanceof RecentCommunityList) {
      return false;
    }

    const dropdownContentHeight = scrollContainer.find('.js-filtered-communities-list').outerHeight(true);
    const childBuffer = 4 * scrollContainer.find('.js-filtered-communities-list li') // we want a buffer equal to 4 * the height of the first item in the list
      .first()
      .outerHeight(true);
    const scrollTopTriggerHeight = scrollContainer.scrollTop() + childBuffer + scrollContainer.outerHeight();

    return scrollTopTriggerHeight > dropdownContentHeight;
  }

  fetchSuccess(response) {
    if (this.noMorePages) {
      return;
    }

    const viewItems = response.results;
    if (viewItems.length < this.rpp && this.collection.length !== 0) {
      this.noMorePages = true;
    }

    if (this.noResultsHandler) {
      this.noResultsHandler(this.collection.length === 0);
    }

    // preselect a community if there's only one, AND we have no query.
    if (this.oneResultHandler && this.collection.length === 1 && this.fetchDataOptions.length > 0) {
      this.oneResultHandler(this.collection.at(0));
    }

    if (this.showAllCommunities) {
      this.collection.unshift(new Community({
        id: -1,
        names: {
          default: I18n.t('components.communityPicker.heading.allCommunities'),
          translations: {}
        }
      }));
    }
  }

  toggleAllCommunitiesOption(showAllCommunities) {
    this.showAllCommunities = showAllCommunities;
  }

  _refetchData() {
    if (this.preventFetch) {
      return;
    }

    this.noMorePages = false;
    this.p = 0;
    this.reset = this.fetchDataOptionsModel.get('reset') || this.reset;
    this.fetchDataOptionsModel.unset('reset', {silent: true});

    this.fetchDataOptions = this.fetchDataOptionsModel.toJSON();

    this.onLoadNext();
  }
}

Cocktail.mixin(CommunityCollectionView, InfiniteScrollViewMixin);

module.exports = CommunityCollectionView;
