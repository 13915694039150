const $os = require('detectOS');

const PlainScrollbar = require('PlainScrollbar');

const FancytreeHelpers = {
  getViewportScrollbarOptions: () => {
    // only showing scrollbar on desktop
    if ($os.desktop && !$os.tablet) {
      return {
        preInit: (event, data) => {
          const tree = data.tree;
          const $fancytreeGrid = tree.$container.closest('div.fancytree-grid-container');
          $fancytreeGrid.wrap( '<div class="fancytree-viewport-with-scrollbar"></div>' );
          const $viewportWithScrollbar = $fancytreeGrid.parent();
          $viewportWithScrollbar.append('<div class="fancytree-vertical-scrollbar"></div>');

          tree.verticalScrollbar = new PlainScrollbar({
            orientation: 'vertical',
            onSet: (numberOfItems) => {
              tree.setViewport({
                start: Math.round(numberOfItems.start),
                count: tree.viewport.count
              });
            },
            scrollbarElement: $viewportWithScrollbar.find('.fancytree-vertical-scrollbar')[0]
          });
        },
        updateViewport: (event, data) => {
          const tree = data.tree;
          const total = tree.visibleNodeList.length;
          const start = tree.viewport.start;
          const visible = tree.viewport.count;

          const $fancytreeGrid = tree.$container.closest('div.fancytree-grid-container');

          if (total <= visible) {
            tree.verticalScrollbar.alwaysVisible(false);
            $fancytreeGrid.removeClass('fancytree-grid-container--hide-scrollbar');
          } else {
            tree.verticalScrollbar.alwaysVisible(true);
            tree.verticalScrollbar.set({
              start,
              total,
              visible
            }, true); // do not trigger `onSet`

            $fancytreeGrid.addClass('fancytree-grid-container--hide-scrollbar');
            const hasReachedEnd = start + visible >= total;
            if (hasReachedEnd) {
              const hasDateConditionExpanded = $('.root__date-condition-form').length;
              if (hasDateConditionExpanded) {
                $fancytreeGrid.scrollTop($fancytreeGrid[0].scrollHeight);
              }
            }
          }
        }
      };
    }
    return {};
  }
};

module.exports = FancytreeHelpers;
