const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');

/**
 * ActionableMenuDropdown.js
 * This is a component that combines MenuDropdown with an ordinary button.
 * It gives the user the ability to take a primary click action and open a dropdown to take secondary actions,
 * styled as one button
 */
class ActionableMenuDropdown extends LayoutView {
  initialize(options = {}) {
    // See MenuDropdown.js to see how each option is to be configured and what others could be added
    ({
      primaryText: this.primaryText,
      primaryOnClick: this.primaryOnClick = () => {},
      buttonConfig: this.buttonConfig = {},
      optionsListConfig: this.optionsListConfig = [],
      primaryButtonClass: this.primaryButtonClass = '',
      iconClass: this.iconClass
    } = options);

    if (this.optionsListConfig.length > 0) {
      this.primaryButtonClass += ' main-action--with-options';
    }
  }

  className() {
    return 'actionable-menu-dropdown ax-grid ax-grid--no-gutter';
  }

  getTemplate() {
    return `
      <%= axButton({
        className: 'qa-main-action js-main-action ${ this.primaryButtonClass }',
        iconClassLeft: "${ this.iconClass }",
        label: "${ I18n.t(this.primaryText) }",
        ariaLabel: "${ I18n.t(this.primaryText) }",
        autosize: true
      }) %>
      <div class="js-dropdown-region ax-grid__col--auto-size"></div>\
    `;
  }

  templateHelpers() {
    return {
      iconClass: this.iconClass
    };
  }

  regions() {
    return {
      dropdownRegion: '.js-dropdown-region'
    };
  }

  events() {
    return {
      'click button.js-main-action': () => {
        this.primaryOnClick();
      }
    };
  }

  onRender() {
    if (this.optionsListConfig.length > 0) {
      this.dropdownRegion.show(new MenuDropdown({
        buttonConfig: this.buttonConfig,
        optionsListConfig: this.optionsListConfig
      }));
    }
  }
}

module.exports = ActionableMenuDropdown;
