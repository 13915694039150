// Module
var code = `<div class="attachment__container clearfix <% if(isDownloadable){ %> isDownloadable <% } %>" data-media-id="<%= id %>">

  <% if (isViewable) { %>
    <div class="attachment-icon <%= icon %> attachment--viewable blue" role="presentation" aria-hidden="true"></div>
    <button class="attachment-name attachment-icon-<%= type %> attachment--viewable ax-font ax-font__caption"
      aria-label="<%- t('attachments.preview', {name: name}) %>"><%- name %></button>
  <% } else { %>
    <div class="attachment-icon <%= icon %> blue" role="presentation" aria-hidden="true"></div>
    <p class="attachment-name attachment-icon-<%= type %> ax-font ax-font__caption"><%- name %></p>
  <% } %>

  <% if (isDownloadable) { %>
    <%= downloadLink(uuid, name, {
      class: "action-button action-button--download icon-download_alt",
      "aria-label": ariaLabel
    }) %>
  <% } %>
</div>
`;
// Exports
module.exports = code;