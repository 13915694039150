const AssessmentType = require('@common/data/enums/AssessmentType');
const I18n = require('@common/libs/I18n');

module.exports = (assessmentType) => {
  switch (AssessmentType.assertLegalValue(assessmentType)) {
    case AssessmentType.CertificationTraining:
      return {
        iconClass: 'icon-certifications',
        iconLabel: I18n.t('assessments.types.CertificationTraining')
      };
    case AssessmentType.DailyTraining:
      return {
        iconClass: 'icon-reinforcement',
        iconLabel: I18n.t('start.assessmentType.DailyTraining')
      };
    case AssessmentType.FormalExamTraining:
      return {
        iconClass: 'icon-exam',
        iconLabel: I18n.t('assessments.types.FormalExamTraining')
      };
    case AssessmentType.IntroductoryTraining:
      return {
        iconClass: 'icon-topic',
        iconLabel: I18n.t('assessments.types.IntroductoryTraining')
      };
    case AssessmentType.RefresherTraining:
      return {
        iconClass: 'icon-refresher',
        iconLabel: I18n.t('assessments.types.RefresherTraining')
      };
    case AssessmentType.ExtraTraining:
      return {
        iconClass: 'icon-extratraining',
        iconLabel: I18n.t('assessments.types.ExtraTrainingQuestions')
      };
  }
};
