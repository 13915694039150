const Backbone = require('Backbone');
const $os = require('detectOS');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const TimelineCardContentView = require('@training/apps/timeline/TimelineCardContentView');
const PageType = require('@common/components/discover/enums/PageType');
const MediaType = require('@common/data/enums/MediaType');
const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');
const ContentTranslationView = require('@training/apps/contentTranslations/ContentTranslationView');
const {getLanguageDirectionClass} = require('@training/apps/contentTranslations/ContentTranslationHelpers');

require('@training/apps/timeline/TimelineZoomOverlay');

const { isEmpty } = require('underscore');

class PostCardContentView extends TimelineCardContentView {

  initialize(options = {}) {
    super.initialize(options);

    ({
      showFullContent: this.showFullContent,
      searchString: this.searchString
    } = options);

    this.listenTo(this.model, 'sync', () => {
      this.render();
      this._getMedia();
    });

    this.getContent = this.getContent.bind(this);
  }

  className() {
    return 'timeline-card-content post-card-content';
  }

  getTemplate() {
    return `
      <div class="content-container">
        <div class="content post-content squire-editor js-impression-tracking-zone <%- languageDirectionClass %>"><%= getPreviewContent() %></div>
        <a href="#" class="continue-link hidden"><%- t('comms.posts.continueReading') %></a>
      </div>
      <div class="js-content-translation-region"></div>
      <div class="image-container hidden"></div>
      <div class="video-container ax-grid ax-grid--no-gutter ax-grid--align-items-center hidden"></div>
    `;
  }

  regions() {
    return {
      contentTranslationRegion: '.js-content-translation-region'
    };
  }

  templateHelpers() {
    return {
      getPreviewContent: this.getContent,
      languageDirectionClass: getLanguageDirectionClass(this.model)
    };
  }

  getContent() {
    if (this.showFullContent) {
      return this.model.get('currentVersion').richContent.content;
    }

    return this.model.get('previewContent');
  }

  ui() {
    return Object.assign(super.ui(), {
      continueLink: '.continue-link',
      videoContainer: '.video-container'
    });
  }

  events() {
    return {
      'click .continue-link': this._onClickContinueReading,
      'click .player-wrapper': () => {
        return false;
      },
      'click a': this._onClickLink
    };
  }

  behaviors() {
    const behaviors = Object.assign({}, super.behaviors(), { Resizable: {} });

    if (this.getOption('showFullContent')) {
      behaviors.TimelineZoomOverlay = {
        imageWrapper: '.image-container'
      };
    }

    return behaviors;
  }

  onShow() {
    if ($os.mobile && this.model.get('type') === PageType.ARTICLE) {
      this.ui.previewContent.show();
    }
  }

  onAttach() {
    this._getMedia();

    this.$el.find('.image-container')
      .attr('tabindex', 0);

    this._renderTranslationToggle();

    if (!this.showFullContent) {
      const lineHeight = parseInt(this.ui.previewContent.css('line-height'), 10);
      const contentHeight = this.ui.previewContent.height();
      const maxHeight = lineHeight * 4;

      // If post preview text overflows 4 lines, cut it at 4 lines and add ellipsis
      if (contentHeight > maxHeight) {
        this.ui.previewContent.css('-webkit-line-clamp', '4');
        this.ui.continueLink.removeClass('hidden');
      }
    }
  }

  onResize() {
    this.videoPlayer?.setMaxDimensions(
      this.ui.videoContainer.width(),
      this.ui.videoContainer.height()
    )
  }

  _onClickLink(e) {
    e.stopPropagation();
  }

  // Required instead of standard href attribute because the click was propagating up to the full card click handler
  _onClickContinueReading(e) {
    e.stopPropagation();

    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ encodeURIComponent(this.model.get('pageId')) }?query=${ encodeURIComponent(this.searchString) }`, {
      trigger: true
    });

    return false;
  }

  _getMedia() {
    // Timeline view vs details view
    let media = this.model.get('media') || this.model.currentVersion.get('richContent').media;
    if (isEmpty(media) || media[0].type == null) {
      return undefined;
    }

    media = media[0];

    if (media.type === MediaType.VIDEO) {
      this._getVideo(media);
    } else if (media.type === MediaType.IMAGE) {
      return this._renderCardImage(media);
    }

    return undefined;
  }

  _getVideo(media) {
    this.ui.videoContainer.removeClass('hidden');

    const videoPlayerOptions = {
      el: this.ui.videoContainer,
      maxWidth: this.ui.videoContainer.width(),
      maxHeight: this.ui.videoContainer.height()
    };

    this.videoPlayer = VideoPlayerFactory.createPlayerInstance({
      videoPackage: { media },
      viewOptions: videoPlayerOptions
    });

    return this.videoPlayer.render();
  }

  _renderTranslationToggle() {
    const autoTranslatedContent = this.model.get('autoTranslatedContent');

    // when content is deemed untranslatable by the server, or when the translations are not yet available,
    // the API should omit the autoTranslatedContent property and the client should do nothing special.
    if (!autoTranslatedContent) {
      return;
    }

    // Currently this is only returned on the Post model, so only translating posts and not articles
    const contentTranslationView = new ContentTranslationView({
      translatedLanguage: autoTranslatedContent.translatedLanguage,
      translatedContent: autoTranslatedContent.translatedContent,
      originalLanguage: autoTranslatedContent.detectedLanguage,
      originalContent: this.showFullContent ? this.model.getContent() : this.model.get('previewContent'),
      $content: this.ui.previewContent,

      // Posts may contain HTML formatting, and the server provides the content already marked up. It should be
      // rendered as is without any encoding. Since we're storing content with HTML (and not some other, safer
      // form of formatting markup), the server is responsible for making sure there's no XSS in the Post body.
      htmlEncode: false
    });

    this.contentTranslationRegion.show(contentTranslationView);

    if (!this.showFullContent) {
      this.$('.content-translations__toggle').hide();
      this.$('.middot').hide();
    }
  }

  onDestroy() {
    window.app.layout.dismissMedia();
  }
}

module.exports = PostCardContentView;
