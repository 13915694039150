const $os = require('detectOS');
const logging = require('logging');

const {
  has,
  once,
  isObject,
  partial,
  isEmpty,
  result,
  defer,
  delay,
  throttle,
  findIndex,
  get,
  wrap,
  omit
} = require('underscore');
const {
  Behavior,
  isNodeAttached
} = require('Marionette');

const Enum = require('@common/data/enums/Enum');

const Behaviors = require('@common/libs/behaviors/Behaviors');
const Resizable = require('@common/libs/behaviors/resizable/Resizable');
const MutationObservable = require('@common/libs/behaviors/mutationObservable/MutationObservable');

const ScrollDataModel = require('@common/libs/behaviors/scrollable/ScrollDataModel');

/*
  Scrollable is a View Behavior that allows us to abstract away most
  of the issues with using HA scrolling styles (iOS). It allows us to maintain only one
  "scrollable" container at a time to prevent scroll chaining (ie. double scroll bars).
  This is accomplished by bubbling custom events up the DOM tree using jQuery. The
  code both controls it's own scrollableContainer as well as listens for un/freeze events
  from child (DOM children) Scrollables. It makes sure to save the scrollTop of the
  scrollableContainer and then scrolls the view that triggered the event into view
  before freezing the scrollableContainer. To unfreeze the container it reverses the
  procedure restoring the scrollTop at the end.

  Scrollable will also provide a pubSub system for view that want to know if the user has scrolled or something has
  changed the scroll data. Any child View that wishes to be informed of scroll state changes can apply the ScrollEvents
  behavior. An example of this system in action is InfiniteCollectionPageLoader that allows a
  collection view to have infinite scroll if the scroll container is several levels higher then the parent.

  Scroll data recalculation is throttled to avoid layout thrashing if something changes with the container and it's
  content due to event callbacks.

  Static enums and helper methods:
    - provided to allow child Views to interact with its parent Scrollable

    Enums:
      'Options': defines all valid options that can be passed in as constructor options.

        'FREEZABLE': (boolean - true) that determines if the view that this Behavior belongs
          will freeze itself when a child Scrollable view wishes to be the only Scrollable that
          scrolls (What a mouthful...). 'Freezing' in this context means to set 'overflow:hidden'
          to the scrollContainer preventing scrolling.
        'TRIGGER_FREEZE_ON_HOVER': (boolean - false) control flag to enable the mouse hover freezing behavior
        'SCROLLABLE_CONTAINER': (jquery selector string - '') that is part of the view 's template. If none is passed
          in then it falls back to the view's @$el.
        'CONTENT_DIMENSIONS_CALCULATOR': function that returns the calculated height and width of the content contained
          in the SCROLLABLE_CONTAINER. The default functionality uses a wrapper div to measure this but View's have
          the option to calculate this themselves if needed (ie. due to structureal requirements to gets styles working)
        'VERTICAL': (boolean - true) that determines if scrolling in the vertical direction is allowed
        'HORIZONTAL': (boolean - false) that determines if scrolling in the horizontal direction is allowed

      'ScrollDataKeys': defines all valid attribute keys that correspond to the various scroll data that is maintained in
        the behavior.

        'SCROLL_BOTTOM': how far the bottom of the content is from the bottom of container
        'SCROLL_TOP': how far the top of the content is from the top of container
        'SCROLL_LEFT': how far the left of the content is from the left of container
        'SCROLL_RIGHT': how far the right of the content is from the right of container
        'CONTAINER_HEIGHT': height of the scrollable container
        'CONTAINER_WIDTH': width of the scrollable container
        'CONTENT_HEIGHT': height on the content
        'CONTENT_WIDTH': width of the content

    Methods:
      'registerEventHandler': allows Views to register a callback that's fired anytime any of the scroll data changes.
        Makes sure the View's el is actually attached to the DOM to account for View's calling this before they're attached.

      'unregisterEventHandler': reciprocol of `registerEventHandler` to remove the callback when updates are no longer
        needed. Callbacks also get stopped when the View is destroyed automatically.

      'getScrollData': Explicit request to the current scroll data. Pass in an optional ScrollDataKey enum to get a specific piece of data.
        Makes sure the data is recalculated before and after the passed in callback is executed (in case of changes due to the callback)

      'scrollToBottom': Tell the scrollable to scroll the content to the bottom, minus the given offset if it's passed in.

      'scrollToTop': Tell the scrollable to scroll the content to the top, minus the given offset if it 's passed in.

      'scrollToLeft': Tell the scrollable to scroll the content to the left, minus the given offset if it 's passed in.

      'scrollToRight': Tell the scrollable to scroll the content to the right, minus the given offset if it 's passed in.

      'scrollIntoView': Tell the scrollable to scroll the passed in target element into view. By default it uses our animation library
        to make sure the scroll is smooth, but can be set to use the native implementation if desired. (Only some browsers support smooth scrolling natively)
*/

const Classes = Enum({
  SCROLLABLE: 'scrollable',
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  AUTO: 'auto',
  OVERFLOW_TOUCH: 'overflow-touch',
  SCROLL_CONTENT_WRAPPER: 'scrollable-content-wrapper'
});

const ScrollDirection = Enum.fromStringArray([
  'TOP',
  'BOTTOM',
  'RIGHT',
  'LEFT'
]);

const OptionKeys = Enum({
  FREEZABLE: 'freezable',
  TRIGGER_FREEZE_ON_HOVER: 'triggerFreezeOnHover',
  SCROLLABLE_CONTAINER: 'scrollableContainer',
  CONTENT_DIMENSIONS_CALCULATOR: 'scrollContentDimensionsCalculator',
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
});

const SCROLL_THROTTLE_WAIT_MS = 10;
const MUTATION_THROTTLE_WAIT_MS = 50;
const IOS_SCROLL_FIX_DELAY = 100;
const SCROLL_ANIMATION_DURATION = 160;

// helpers methods

const triggerOnAttached = (view, callback) => {

  if (isNodeAttached(view.el)) {
    callback();
  } else if (!view.isDestroyed) {
    logging.devWarn('Delaying execution till the view is attached to the DOM. If this is unexpected, check your code.');

    view.listenTo(view, 'attach', () => {
      callback();
    });
  } else {
    logging.devWarn('Action not executed since the View is already destroyed!');
  }
};

const registerEventHandler = (view, callback) => {
  const triggerRegisterEventHandler = () => {
    view.$el.trigger('registerEventHandler.scrollable', [view, callback]);
  };

  triggerOnAttached(view, triggerRegisterEventHandler, 'Tried to register event handler after the passed in View\'s been destroyed!');
};

// Unregister with the closes scroll parent for scroll data changes
const unregisterEventHandler = (view, callback) => {
  if (isNodeAttached(view.el)) {
    view.$el.trigger('unregisterEventHandler.scrollable', [view, callback]);
  }
};

// Request the entire set of the current scroll data from the nearest scroll parent and gets passed back into the callback
const getScrollData = (view, key) => {
  let data = {};

  view.$el.trigger('getScrollData.scrollable', [(scrollData) => {
    data = scrollData;
  }]);

  if (!isNodeAttached(view.el)) {
    logging.warn('Passed in `view` is not attached to the DOM yet! Are you calling `getScrollData()` this from the right place in the View lifecycle?');
  } else if (isEmpty(data)) {
    logging.warn('No scroll data was returned, most likely because there was no View that uses Scrollable higher up in the View hierarchy.');
  }

  if (key != null) {
    ScrollDataModel.Keys.assertLegalValue(key);
    return get(data, key);
  }

  return data;
};

// Request that the closest scroll parant scrolls all the way in the specified direction, up to the provided offset.
const scrollTo = (view, direction, offset, options) => {
  view.$el.trigger('scrollTo.scrollable', [direction, offset, options]);
};

const scrollToBottom = (view, offset, options) => {
  triggerOnAttached(view, partial(scrollTo, view, ScrollDirection.BOTTOM, offset, options));
};

const scrollToTop = (view, offset, options) => {
  triggerOnAttached(view, partial(scrollTo, view, ScrollDirection.TOP, offset, options));
};

const scrollToLeft = (view, offset, options) => {
  triggerOnAttached(view, partial(scrollTo, view, ScrollDirection.LEFT, offset, options));
};

const scrollToRight = (view, offset, options) => {
  triggerOnAttached(view, partial(scrollTo, view, ScrollDirection.RIGHT, offset, options));
};

const scrollIntoView = (view, target, options) => {
  triggerOnAttached(view, () => {
    view.$el.trigger('scrollIntoView.scrollable', [target, options]);
  });
};

Behaviors.Scrollable = class Scrollable extends Behavior {

  static Options = OptionKeys;

  static ScrollDataKeys = ScrollDataModel.Keys;

  static registerEventHandler = registerEventHandler;

  static unregisterEventHandler = unregisterEventHandler;

  static getScrollData = getScrollData;

  static scrollToBottom = scrollToBottom;

  static scrollToTop = scrollToTop;

  static scrollToLeft = scrollToLeft;

  static scrollToRight = scrollToRight;

  static scrollIntoView = scrollIntoView;

  defaults() {
    return {
      [OptionKeys.FREEZABLE]: true,
      [OptionKeys.TRIGGER_FREEZE_ON_HOVER]: false,
      [OptionKeys.SCROLLABLE_CONTAINER]: '',
      [OptionKeys.CONTENT_DIMENSIONS_CALCULATOR]: null,
      [OptionKeys.VERTICAL]: true,
      [OptionKeys.HORIZONTAL]: false
    };
  }

  initialize() {
    this._onScroll = this._onScroll.bind(this);
    this._getContentWrapperDimensions = this._getContentWrapperDimensions.bind(this);

    this._scrollData = new ScrollDataModel();
    this._throttledUpdateScrollData = throttle(this._updateScrollData.bind(this), SCROLL_THROTTLE_WAIT_MS);
    this._throttledDomMutationUpdate = throttle(this._onDomMutate.bind(this), MUTATION_THROTTLE_WAIT_MS);
    this._handlerCache = [];

    this._isStructuralDataDirty = true;
  }

  behaviors() {
    return {
      Resizable: {
        [Resizable.Options.TRIGGERED_EVENT]: 'scrollable:resize'
      },
      MutationObservable: {
        target: this.getOption(OptionKeys.SCROLLABLE_CONTAINER),
        observeOptions: {
          attributes: true,
          childList: true,
          subtree: true
        }
      }
    };
  }

  ui() {
    return {
      scrollableContainer: this.getOption(OptionKeys.SCROLLABLE_CONTAINER),
      scrollableContentWrapper: `.${ Classes.SCROLL_CONTENT_WRAPPER }`
    };
  }

  events() {
    const events = {
      'registerEventHandler.scrollable': this.onRegisterEventHandler.bind(this),
      'unregisterEventHandler.scrollable': this.onUnregisterEventHandler.bind(this),
      'getScrollData.scrollable': this.onGetScrollData.bind(this),
      'scrollTo.scrollable': this.onScrollTo.bind(this),
      'scrollIntoView.scrollable': this.onScrollIntoView.bind(this)
    };

    // If this view doesn't want to be frozen then we know there's no point in listening
    // for the events.
    if (this.getOption(OptionKeys.FREEZABLE)) {
      Object.assign(events, {
        'freeze:scrollable @ui.scrollableContainer': this.onFreezeScrollableContainer.bind(this),
        'unfreeze:scrollable @ui.scrollableContainer': this.onUnfreezeScrollableContainer.bind(this)
      });
    }

    // Only listen for these events if the options were set to triggerFreezeOnHover
    if (this._shouldTriggerFreezeOnHover()) {
      Object.assign(events, {
        'mouseenter.scrollable @ui.scrollableContainer': this.onFreezeScrollable.bind(this),
        'mouseleave.scrollable @ui.scrollableContainer': this.onUnfreezeScrollable.bind(this)
      });
    }

    return events;
  }

  onRender() {
    // Make sure the designated container has the 'scrollable' class applied.
    this._applyScrollableStyles();
    this._applyContentWrapper();
    this._createScrollEventHandler();
  }

  onAttach() {
    this._throttledUpdateScrollData();
    this._iosScrollFix();
  }

  onScrollableResize() {
    this._isStructuralDataDirty = true;
    this._throttledUpdateScrollData();
  }

  onDomMutate() {
    this._isStructuralDataDirty = true;
    this._throttledDomMutationUpdate();
  }

  _onDomMutate() {
    this._throttledUpdateScrollData();
  }

  _onScroll() {
    this._throttledUpdateScrollData();
  }

  onDestroy() {
    this.onUnfreezeScrollable();
    this._removeScrollEventHandler();
    this._removeUpdateThrottle();
    this._handlerCache = [];
  }

  /*
    Trigger functions fire the custom event up the DOM tree to un/feeze parent
    Scrollables.
  */
  onFreezeScrollable(options = {}) {
    const parentEl = result(this._getScrollableContainer(), 'parent');
    if (parentEl) {
      parentEl.trigger('freeze:scrollable', options);
    }
  }

  onUnfreezeScrollable(options = {}) {
    const parentEl = result(this._getScrollableContainer(), 'parent');
    if (parentEl) {
      parentEl.trigger('unfreeze:scrollable', options);
    }
  }

  onRegisterEventHandler(e, contextEventObject, callback) {
    const handler = (scrollData) => {
      callback(scrollData.toJSON());
    };

    this._handlerCache.push({
      callback,
      handler
    });

    contextEventObject.listenTo(this._scrollData, 'change', handler);

    this._createScrollEventHandler();

    return false;
  }

  onUnregisterEventHandler(e, contextEventObject, callback) {
    const handlerIndex = findIndex(this._handlerCache, (cacheEntry) => {
      return cacheEntry.callback === callback;
    });

    if (handlerIndex >= 0) {
      const { handler } = this._handlerCache.splice(handlerIndex, 1);

      contextEventObject.stopListening(this._scrollData, 'change', handler);

      this._removeScrollEventHandler();
    }

    return false;
  }

  onGetScrollData(e, callback) {
    this._updateScrollData();
    callback(this._scrollData.toJSON());
    this._updateScrollData();
    return false;
  }

  onScrollTo(e, direction, offset = 0, options = {}) {
    ScrollDirection.assertLegalValue(direction);

    const {
      preventDefault = true,
      stopPropagation = true,
      animate = false
    } = options;

    const $target = this._getScrollableContainer().children()
      .first();

    if ($target.length === 0) {
      return;
    }

    if (preventDefault) {
      e.preventDefault();
    }

    if (stopPropagation) {
      e.stopPropagation();
    }

    const directionalOffset = this._calcDirectionalOffset(direction, offset);
    const isVerticalScrollDirection = [ScrollDirection.TOP, ScrollDirection.BOTTOM].includes(direction);

    $target.velocity('finish');

    if (animate === true || isObject(animate)) {
      const animateOptions = animate === true ? {} : animate;
      this._animatedScrollTo(isVerticalScrollDirection, directionalOffset, $target, animateOptions);
    } else {
      this._nativeScrollTo(isVerticalScrollDirection, directionalOffset);
    }

    this._fixMissingIosRows();
  }

  _calcDirectionalOffset(direction, offset) {
    if (direction === ScrollDirection.BOTTOM) {
      const {
        contentHeight,
        containerHeight
      } = this._scrollData.toJSON();

      return contentHeight - containerHeight - offset;
    } else if (direction === ScrollDirection.RIGHT) {
      const {
        contentWidth,
        containerWidth
      } = this._scrollData.toJSON();

      return contentWidth - containerWidth - offset;
    }

    return offset;
  }

  _animatedScrollTo(isVerticalScrollDirection, offset, $target, options) {
    // velocity scrolling requires the overflow container to not be static,
    // for our purposes, relative works the best since it keeps the container in the natural DOM flow
    const position = this._getScrollableContainer().css('position');
    if (position === 'static') {
      this._getScrollableContainer().css('position', 'relative');
    }

    const complete = () => {
      if (position === 'static') {
        this._getScrollableContainer().css('position', '');
      }
    };

    const axis = isVerticalScrollDirection ? 'y' : 'x';

    const scrollDirectionDataKey = isVerticalScrollDirection ? ScrollDataModel.Keys.SCROLL_TOP : ScrollDataModel.Keys.SCROLL_LEFT;
    const scrollDirectionData = this._scrollData.get(scrollDirectionDataKey);

    const targetPositionKey = isVerticalScrollDirection ? 'top' : 'left';
    const targetPositionData = $target.position()[targetPositionKey];

    const animatedOffset = offset - scrollDirectionData - targetPositionData;

    this._animateScroll($target, Object.assign({
      offset: animatedOffset,
      axis,
      complete: wrap(options.complete, (wrappedComplete = () => {}, ...args) => {
        complete(...args);
        wrappedComplete(...args);
      })
    }, omit(options, 'complete')));
  }

  _nativeScrollTo(isVerticalScrollDirection, offset) {
    const method = isVerticalScrollDirection ? 'scrollTop' : 'scrollLeft';
    this._getScrollableContainer()[method](offset);
  }

  onScrollIntoView(e, target, options = {}) {
    const {
      preventDefault = true,
      stopPropagation = true,
      animate = true
    } = options;

    const $target = $(target);

    if ($target.length === 0) {
      return;
    }

    if (preventDefault) {
      e.preventDefault();
    }

    if (stopPropagation) {
      e.stopPropagation();
    }

    $target.velocity('finish');

    if (animate === true || isObject(animate)) {
      const animateOptions = animate === true ? {} : animate;
      this._animatedScrollIntoView($target, animateOptions);
    } else {
      this._nativeScrollIntoView($target);
    }
  }

  _animatedScrollIntoView($target, options) {
    // velocity scrolling requires the overflow container to not be static,
    // for our purposes, relative works the best since it keeps the container in the natural DOM flow
    const position = this._getScrollableContainer().css('position');
    if (position === 'static') {
      this._getScrollableContainer().css('position', 'relative');
    }

    const complete = once( () => {
      if (position === 'static') {
        this._getScrollableContainer().css('position', '');
      }
    });

    const {
      containerHeight,
      contentHeight,
      containerWidth,
      contentWidth
    } = this._scrollData.toJSON();

    const isVerticalOverflow = contentHeight > containerHeight;
    const isHorizontalOverflow = contentWidth > containerWidth;

    const targetPosition = $target.position();
    const targetOffset = $target.offset();
    const containerOffset = this._getScrollableContainer().offset();

    if (isVerticalOverflow) {
      const offset = targetOffset.top - containerOffset.top - targetPosition.top;
      this._animateScroll($target, Object.assign({
        axis: 'y',
        offset,
        complete: wrap(options.complete, (wrappedComplete = () => {}, ...args) => {
          complete(...args);
          wrappedComplete(...args);
        })
      }, omit(options, 'complete')));
    }

    if (isHorizontalOverflow) {
      const offset = targetOffset.left - containerOffset.left - targetPosition.left;
      this._animateScroll($target, Object.assign({
        axis: 'x',
        offset,
        complete: wrap(options.complete, (wrappedComplete = () => {}, ...args) => {
          complete(...args);
          wrappedComplete(...args);
        })
      }, omit(options, 'complete')));
    }
  }

  _animateScroll($target, options) {
    $target.velocity('scroll', Object.assign({
      container: this._getScrollableContainer(),
      duration: SCROLL_ANIMATION_DURATION,
      easing: 'ease-in-out'
    }, options));
  }

  _nativeScrollIntoView($target) {
    if (has($target, '[0].scrollIntoView')) {
      $target[0].scrollIntoView({ behavior: 'smooth' });
    }
  }

  /*
    The main heavy lifting occurs here. It saves the scrollTop, triggers
    'before:freeze:scrolling' methods on its view, proceeds to scroll the
    target views element into view and then applies the 'freeze' style to the
    scrollableContainer. Finally it triggers 'freeze:scrolling' method on its
    view to notify it that it's been frozen.
  */
  onFreezeScrollableContainer(e, options = {}) {
    if (!this.isFrozen) {
      this.isFrozen = true;

      const {
        saveScrollTop = true,
        scrollTargetIntoView = false
      } = options;

      if (saveScrollTop) {
        this._saveScrollTop();
      }

      this.view.triggerMethod('before:freeze:scrolling');

      const $scrollable = this._getScrollableContainer();

      if (scrollTargetIntoView) {
        e.target.scrollIntoView();
      }

      if (!$os.mobile) {
        $scrollable.css({
          top: -$scrollable.scrollTop(),
          position: 'relative'
        });
      }

      $scrollable.removeClass(Classes.SCROLLABLE);

      this.view.triggerMethod('freeze:scrolling');
    }

    return false;
  }

  // This does the reverse of 'onFreezeScrollableContainer'
  onUnfreezeScrollableContainer(e, options = {}) {
    if (this.isFrozen) {
      this.isFrozen = false;

      const { restoreScrollTop = true } = options;

      this.view.triggerMethod('before:unfreeze:scrolling');

      if (!$os.mobile) {
        this._getScrollableContainer().css({
          top: '',
          position: ''
        });
      }

      this._getScrollableContainer().addClass(Classes.SCROLLABLE);

      this.view.triggerMethod('unfreeze:scrolling');

      if (restoreScrollTop) {
        this._restoreScrollTop();
      }
    }

    return false;
  }

  _getScrollableContainer() {
    if (this.ui.scrollableContainer.length > 0) {
      return this.ui.scrollableContainer;
    }

    return this.$el;
  }

  _updateScrollData() {
    const structuralData = this._isStructuralDataDirty ? this._calculateStructuralData() : this._scrollData.pick(ScrollDataModel.StructuralKeys);
    const scrollData = this._calculateScrollData(structuralData);

    this._scrollData.set(Object.assign({}, structuralData, scrollData));
  }

  _calculateStructuralData() {
    const container = this._getScrollableContainer();
    const {
      contentHeight,
      contentWidth
    } = this._calcuateContentDimensions();

    const data = {
      [ScrollDataModel.Keys.CONTENT_HEIGHT]: contentHeight,
      [ScrollDataModel.Keys.CONTENT_WIDTH]: contentWidth,
      [ScrollDataModel.Keys.CONTAINER_HEIGHT]: container.innerHeight(),
      [ScrollDataModel.Keys.CONTAINER_WIDTH]: container.innerWidth()
    };

    // jquery height/width api's cause attribute changes so we need to take mutation records created by them to avoid
    // a feedback loop.
    MutationObservable.takeRecords(this.view);

    return data;
  }

  _calculateScrollData(structuralData = {}) {
    const container = this._getScrollableContainer();
    const scrollTop = container.scrollTop();
    const scrollLeft = container.scrollLeft();

    const contentHeight = (structuralData.contentHeight * 1000);
    const contentWidth = (structuralData.contentWidth * 1000);
    const containerHeight = (structuralData.containerHeight * 1000);
    const containerWidth = (structuralData.containerWidth * 1000);

    // This scrollBottom value works the same as scrollTop in that it's difference in pixels
    // between the bottom of the container and the bottom of the content.
    const scrollBottom = Math.floor(Math.max((contentHeight - containerHeight - scrollTop * 1000) / 1000, 0));
    const scrollRight = Math.floor(Math.max((contentWidth - containerWidth - scrollLeft * 1000) / 1000, 0));

    return {
      [ScrollDataModel.Keys.SCROLL_TOP]: scrollTop,
      [ScrollDataModel.Keys.SCROLL_LEFT]: scrollLeft,
      [ScrollDataModel.Keys.SCROLL_RIGHT]: scrollRight,
      [ScrollDataModel.Keys.SCROLL_BOTTOM]: scrollBottom
    };
  }

  _calcuateContentDimensions() {
    const dimensionCalculator = this.getOption(OptionKeys.CONTENT_DIMENSIONS_CALCULATOR) || this._getContentWrapperDimensions;
    return dimensionCalculator();
  }

  _getContentWrapperDimensions() {
    return {
      contentHeight: this.ui.scrollableContentWrapper.outerHeight(true),
      contentWidth: this.ui.scrollableContentWrapper.outerWidth(true)
    };
  }

  _applyScrollableStyles() {
    const styleClasses = [Classes.SCROLLABLE];

    if ($os.ios) {
      styleClasses.push(Classes.OVERFLOW_TOUCH);
    }

    // Even iOS overflow-touch needs to be accompanied by overflow: auto
    styleClasses.push(Classes.AUTO);

    if (this.getOption(OptionKeys.VERTICAL) && !this.getOption(OptionKeys.HORIZONTAL)) {
      styleClasses.push(Classes.VERTICAL);
    }

    if (this.getOption(OptionKeys.HORIZONTAL) && !this.getOption(OptionKeys.VERTICAL)) {
      styleClasses.push(Classes.HORIZONTAL);
    }

    this._getScrollableContainer().addClass(styleClasses.join(' '));
  }

  // Content wrapper is used to reliably calculate the contents true rendered height
  _applyContentWrapper() {
    if (this.getOption(OptionKeys.CONTENT_DIMENSIONS_CALCULATOR) == null) {
      const children = this._getScrollableContainer().children();
      const contentWrapperHtml = `<div class="${ Classes.SCROLL_CONTENT_WRAPPER }"></div>`;

      if (children.length > 0) {
        children.wrapAll(contentWrapperHtml);
      } else {
        this._getScrollableContainer().append(contentWrapperHtml);
      }

      this.view.bindUIElements();
    }
  }

  _createScrollEventHandler() {
    if (this._scrollData.hasEventListeners() && this.view.isRendered) {
      this._getScrollableContainer().on('scroll.scrollable', this._onScroll);
      this._updateScrollData();
    }
  }

  _removeScrollEventHandler() {
    if (!this._scrollData.hasEventListeners() && !this.view.isDestroyed) {
      this._getScrollableContainer().off('scroll.scrollable', this._onScroll);
    }
  }

  _removeUpdateThrottle() {
    this._throttledUpdateScrollData.cancel();
    this._throttledUpdateScrollData = () => {};

    this._throttledDomMutationUpdate.cancel();
    this._throttledDomMutationUpdate = () => {};
  }

  _shouldTriggerFreezeOnHover() {
    let triggerFreezeOnHover = this.getOption(OptionKeys.TRIGGER_FREEZE_ON_HOVER);

    if (triggerFreezeOnHover == null) {
      // If the options isn't explicitly set then we traverse up the DOM tree for any other
      // '.scrollable' elements as an indicator that we need to trigger freeze events.
      // (This requires that the View is in the DOM already.)
      triggerFreezeOnHover = this._getScrollableContainer().parent()
        .closest(`.${ Classes.SCROLLABLE }`).length > 0;
    }

    return triggerFreezeOnHover;
  }

  // Saves the scrollTop position of the scrollableContainer so it can be
  // restored when it's unfrozen again.
  _saveScrollTop() {
    this._updateScrollData();
    this._scrollTop = this._scrollData.get(ScrollDataModel.Keys.SCROLL_TOP);
  }

  // Restore the scrollTop position of the scrollableContainer
  _restoreScrollTop() {
    this._getScrollableContainer().scrollTop(this._scrollTop);
    delete this._scrollTop;
  }

  _iosScrollFix() {
    if ($os.ios) {
      this._getScrollableContainer().css('webkitOverflowScrolling', 'auto');

      delay(() => {
        if (this.view.isDestroyed) {
          return;
        }

        this._getScrollableContainer().css('webkitOverflowScrolling', '');
      }, IOS_SCROLL_FIX_DELAY);
    }
  }

  _fixMissingIosRows() {
    if ($os.ios) {
      // this is done to fix an issue with ios webview where list items disapear after
      // scrolling to the specified location
      this._getScrollableContainer().find('li')
        .css('-webkit-transform', 'translate3d(0,0,0)');

      defer(() => {
        if (this.view.isDestroyed) {
          return;
        }

        this._getScrollableContainer().find('li')
          .css('-webkit-transform', '');
      });
    }
  }
};

module.exports = Behaviors.Scrollable;
