const _ = require('underscore');
const { LayoutView } = require('Marionette');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/components/view/rootLayout/Root.less');

class RootLayout extends LayoutView {

  el() {
    return 'body';
  }

  className() {
    return `root-layout fit-parent ${ process.rootCssClass }`;
  }

  getTemplate() {
    return `
      <div id="react-root"></div>
      <div id="main" class="relative fit-parent">
        <div id="spinner">
          <div class="img"></div>
        </div>
      </div>
    `;
  }

  regions() {
    return {
      main: '#main'
    };
  }

  ui() {
    return {
      main: '#main',
      reactRoot: '#react-root'
    };
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  /*
   * XXX - Since the RootLayout controls the body el, we'll render it explicitly in the constructor
   * to make sure the Resizable behavior gets properly initialized.
   */
  constructor(options) {
    super(options);
    this.$el.addClass(_.result(this, 'className'));

    this.render();
  }

  onResize(winHeight, winWidth) {
    return this.ui.main.css({
      height: winHeight,
      width: winWidth
    });
  }
}

module.exports = RootLayout;
