const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

class DiscoverTrainingCardHeaderInfoView extends ItemView {
  getTemplate() {
    return `
      <p class="training-card__header-info-title">${ I18n.t('training.cards.discoverTitle') }</p>
      <p class="training-card__header-info-description">${ I18n.t('training.cards.info.discoverDescription') }</p>
    `;
  }

  className() {
    return 'training-card__header-info';
  }
}

module.exports = DiscoverTrainingCardHeaderInfoView;
