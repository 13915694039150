const logging = require('logging');
const NumberHelpers = require('@common/libs/helpers/types/NumberHelpers');

const PasswordHelpers = {
  // Returns the number of character groups in a password
  // Character groups include:
  //  - Upper case
  //  - Lower case
  //  - Numeric
  //  - Symbols
  numberOfCharacterGroups(pass) {
    const matchedGroups = {};
    const passArray = pass.split('');
    for (const passChar of Array.from(passArray)) {
      const charCode = passChar.charCodeAt(0);
      // Numeric
      if (NumberHelpers.areAllCharsDigits(passChar)) {
        matchedGroups['numeric'] = true;
      // Symbol (see ASCII table http://www.asciitable.com/)
      } else if (((charCode >= 32) && (charCode <= 47))
              || ((charCode >= 58) && (charCode <= 64))
              || ((charCode >= 91) && (charCode <= 96))
              || ((charCode >= 123) && (charCode <= 126))) {
        matchedGroups['symbol'] = true;
      // Lower case
      } else if (passChar === passChar.toLowerCase()) {
        matchedGroups['lowercase'] = true;
      // Upper case
      } else {
        matchedGroups['uppercase'] = true;
      }
    }
    const { length } = _.keys(matchedGroups);
    logging.debug(`numberOfCharacterGroups: length ${ length } : ${ JSON.stringify(matchedGroups) }`);
    return length;
  }
};

module.exports = PasswordHelpers;
