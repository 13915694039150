const _ = require('underscore');
const Backbone = require('Backbone');

const I18n = require('@common/libs/I18n');

class Language extends Backbone.Model {
  constructor(arg) {
    let attributes = {};
    if (_.isString(arg)) {
      attributes = { id: arg.toString() };
    }
    super(attributes, {});
  }

  toOption() {
    const langCode = this.get('id');
    return {id: langCode, value: I18n.languageNameNative(langCode)};
  }
}

module.exports = Language;
