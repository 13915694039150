import Enum from '@common/data/enums/Enum';
import type EnumType from '@common/data/types/EnumType';

type MediaTypesType = EnumType & {
  FILE: string,
  IMAGE: string,
  VIDEO: string,
  DOCUMENT: string
};

const MediaTypesEnum = Enum({
  FILE: 'file',
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document'
}) as MediaTypesType;

export default MediaTypesEnum;
