const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const dateHelpers = require('@common/libs/dateHelpers');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');

require('@common/libs/behaviors/card/Card');
const { INFORMATIONAL } = require('@common/libs/behaviors/card/CardModifierClasses');

class ReferralActiveInvitesView extends UIKit.View {
  template = _.tpl(require('../templates/ReferralActiveInvitesView.html'));

  itemTemplate = _.tpl(require('../templates/_referral_active_invite_item.html'));

  behaviors() {
    return {
      Card: {
        target: '.active-invite',
        modifierClasses: [INFORMATIONAL]
      }
    };
  }

  initialize(options = {}) {
    ({
      configuration: this.configuration,
      collection: this.collection
    } = options);
  }

  render() {
    const inProgressBounties = this.collection.toJSON();
    if (inProgressBounties && inProgressBounties.length < 1) {
      return this;
    }

    this.$el.html(this.template());
    // go through the active items and render them

    for (let i = 0; i < inProgressBounties.length; i++) {
      const activeReferral = inProgressBounties[i];
      const user = activeReferral.target;

      let department;
      const departmentName = user.department && user.department.name;
      const locationName = user.location && user.location.name;

      if (departmentName) {
        department = departmentName;
      } else if (locationName) {
        department = user.location.name;
      } else {
        department = '';
      }

      const daysLeft = dateHelpers.getDaysRemaining(apps.auth.session.serverTimeOffset, activeReferral.expiryDate);

      const activeReferralHtml = this.itemTemplate({
        name: user.displayName,
        department,
        daysLeft
      });
      const $activeReferralHtml = $(activeReferralHtml);
      const $currentView = $activeReferralHtml.find('.name-logo');
      new ProfileIconHandler({user, profileElement: $currentView});

      this.$('.active-invites').append($activeReferralHtml);
    }

    return this;
  }
}
module.exports = ReferralActiveInvitesView;
