const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const FileFactory = require('@common/libs/file/FileFactory');

const EvaluationSubmissionFailureView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionFailureView');
const EvaluationSubmissionPreviewDetailsView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionPreviewDetailsView');
const EvaluationSubmissionUploadFileLinkView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionUploadFileLinkView');

class EvaluationSubmissionFailureController extends LayoutController {

  initialize(options = {}) {
    ({
      submissionFile: this.submissionFile,
      fileChangeHandler: this.fileChangeHandler = () => {}
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: EvaluationSubmissionFailureView
    };
  }

  regionControllers() {
    return {
      displayRegion: {
        viewDefinition: {
          ViewClass: ItemView,
          tagName: 'p',
          className: 'warning-text',
          template: `${ I18n.t('GuidedLearning.evaluation.details.uploadWarning') }`
        }
      },
      detailsRegion: {
        viewDefinition: {
          ViewClass: EvaluationSubmissionPreviewDetailsView,
          model: this.submissionFile
        }
      },
      reuploadRegion: {
        viewDefinition: {
          ViewClass: EvaluationSubmissionUploadFileLinkView,
          fileFactory: FileFactory,
          fileOptions: {
            documentsAllowed: true
          }
        },
        delegateEvents: {
          'view:select:file': (controller, view, file) => {
            this.fileChangeHandler(file);
          }
        }
      }
    };
  }
}

module.exports = EvaluationSubmissionFailureController;
