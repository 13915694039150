const Backbone = require('Backbone');

const User = require('@common/data/models/AuthUser');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');

class UserMessage extends Backbone.Model {
  get urlRoot() {
    return '/axonify/userMessages';
  }

  defaults() {
    return {
      message: {
        text: ''
      }
    };
  }

  preinitialize() {
    this.senderUser = new User();
    this.messageUser = new User();
    this.markAsRead = this.markAsRead.bind(this);

    this.listenTo(this, 'change:sender', (model, attributes) => {
      this.senderUser.set(attributes);
    });

    this.listenTo(this, 'change:message', (model, attributes = {}) => {
      if (attributes.user) {
        this.messageUser.set(attributes.user);
      }
    });
  }

  markAsRead() {
    return this.save({delivered: true}, {
      error(model, xhr) {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);

        // Handle the case where the user tries to "mark as read" in two tabs
        if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_STALE_ENTITY) {
          xhr.skipGlobalHandler = true;
        }
      }
    });
  }

  toJSON() {
    const JSON = super.toJSON();

    if (JSON.sender != null) {
      JSON.sender.user = this.senderUser.toJSON();
    }

    const message = JSON.message;
    if (message && message.user) {
      JSON.message.user = this.messageUser.toJSON();
    }

    return JSON;
  }
}

module.exports = UserMessage;
