import {
  ListItemIcon as MuiListItemIcon,
  type ListItemIconProps as MuiListItemIconProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxListItemIconProps = MuiListItemIconProps;

const styledOptions = {
  name: 'AxListItemIcon'
};

const StyledAxListItemIcon = styled(MuiListItemIcon, styledOptions)<AxListItemIconProps>(({ theme }) => {
  return {
    minWidth: 'unset',
    marginRight: theme.uiKit.spacingS
  };
});

/**
* An icon to be used inside of a list item.
*
* ### Links
* - • [MUI ListItemIcon | API](https://mui.com/material-ui/api/list-item-icon/)
*
* @example
* <AxListItem>
*  <AxListItemIcon>
*   <AxIcon iconName='icon-gamepad' />
*  </AxListItemIcon>
* </AxListItem>
*/
export const AxListItemIcon = forwardRef(({
  children,
  ...otherProps
}: AxListItemIconProps, ref: Ref<HTMLLIElement>) => {
  const listItemIconProps: AxListItemIconProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemIcon { ...listItemIconProps } ref={ ref }>
      { children }
    </StyledAxListItemIcon>
  );
});

export default AxListItemIcon;
