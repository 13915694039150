// Module
var code = `<div class="modalHeader">
  <h1>
    <% if (model.slotMachineWin) { %>
      <%= t('auction.slotMachineWin', {name: model.prize.name}) %>
    <%} else {%>
      <%= t('auction.prizepurchased', {name: model.prize.name}) %>
    <%}%>
  </h1>
  <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
</div>

<div class="win">
  <% if (model.prize.prizeImg) { %>
    <div class="itemimage image-wrapper" role="presentation" aria-hidden="true"></div>
  <% } %>
  <p class="fulfillment">
    <%= model.rewardProgram.fulfillment %>
  </p>
</div>
`;
// Exports
module.exports = code;