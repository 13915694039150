const Form = require('@common/components/forms/Form');
const KeyCode = require('@common/data/enums/KeyCode');

const {
  axToggle,
  axFormSelectors
} = require('@common/components/axForm/AxFormTemplateHelpers');

Form.Editor.Switch = class Switch extends Form.Editor {
  getTemplate() {
    return axToggle(this._toggleOptions);
  }

  events() {
    return {
      'click @ui.switch': 'onClick',
      'keyup @ui.switch': 'onKeyup'
    };
  }

  ui() {
    return {
      switch: axFormSelectors.toggle
    };
  }

  initialize(options = {}) {
    if (this.enabled == null) {
      this.enabled = false;
    }
    this._isSwitchDisabled = (this.$el.attr('disabled') != null);

    const optDefaults = {
      checked: this.enabled,
      disabled: this._isSwitchDisabled
    };

    this._toggleOptions = Object.assign(optDefaults, options.options || {});

    this.render();
  }

  getValue() {
    return this.enabled;
  }

  setValue(state) {
    if (this.enabled !== state) {
      this.enabled = state;
      this.ui.switch.prop('checked', this.enabled);
    }
  }

  onClick() {
    if (!this._isSwitchDisabled) {
      if (this.enabled) {
        this.enabled = false;
      } else {
        this.enabled = true;
      }

      this.ui.switch.prop('checked', this.enabled);
      this.$el.trigger('changed', this.enabled);
      return this.trigger('change', this);
    }
    return false;
  }

  onKeyup(e) {
    e.preventDefault();
    if (e.which === KeyCode.SPACE || e.which === KeyCode.ENTER) {
      this.onClick();
    }
  }
};

module.exports = Form.Editor.Switch;
