const _ = require('underscore');

const VideoExtensionMimeTypes = {
  '3g2': 'video/3gpp2',
  '3gp': 'video/3gpp',
  asf: 'video/x-ms-asf',
  asx: 'video/x-ms-asf',
  avi: 'video/x-msvideo',
  dat: 'video/x-mpeg',
  flv: 'video/x-flv',
  m4v: 'video/x-m4v',
  mkv: 'video/x-matroska',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  ogg: 'video/ogg',
  rm: 'application/vnd.rn-realmedia',
  swf: 'application/x-shockwave-flash',
  vob: ['video/dvd', 'video/mpeg'],
  webm: 'video/webm',
  wmv: 'video/x-ms-wmv'
};

const VideoFileHelpers = {
  VideoExtensionMimeTypes,
  allowedVideoExtensions: _.keys(VideoExtensionMimeTypes),
  allowedVideoMimeTypes: _.uniq(_.flatten(_.values(VideoExtensionMimeTypes)))
};

module.exports = VideoFileHelpers;
