const { ReactControllerDefinitionFactory } = require('@common/modules/react')
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const displayModal = (region) => {
  const dialog = ViewControllerFactory.createLegacyView(ReactControllerDefinitionFactory({
    component: import('@training/apps/training/howTo/SelectGameHowToDialog')
  }));
  region.show(dialog);
};

module.exports = {
  displayModal
};
