const Backbone = require('Backbone');

/*
 * Backbone collection of elements with type MilestoneInterface
 * Created By: GuidedLearningCategory
 */
class MilestoneInterfaceList extends Backbone.Collection {
  initialize() {
    Object.defineProperty(this, 'numObj', {value: {numComplete: 0, numItemsTotal: 0}, writable: false});

    this.on('add', (model) => {
      this.countItems(this.numObj, model.getPrereqs());
      model.set({
        numComplete: this.numObj.numComplete,
        numItemsTotal: this.numObj.numItemsTotal
      });
    });
  }

  countItems(numObj, nestedAr) {
    for (const item of nestedAr) {
      if (item instanceof Array) {
        this.countItems(numObj, item);
      } else if (item.complete === true) {
        numObj.numComplete++;
        numObj.numItemsTotal++;
      } else {
        numObj.numItemsTotal++;
      }
    }

  }
}

module.exports = MilestoneInterfaceList;
