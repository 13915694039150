// Module
var code = `<div class="read-more__content--container">
  <div class="read-more__overflow-content"></div>
  <% if (enablePreviewGradient) { %>
    <div class="read-more__preview-gradient"></div>
  <% } %>
</div>
<div class="read-more__button--container">
  <button class="read-more__button axon-link-button"><%- t('general.readMore') %></button>
</div>
`;
// Exports
module.exports = code;