// Module
var code = `<div>
  <div class="title-wrapper">
    <div class="title-block">
      <div class="recovery-icon icon-overconfident"></div>
      <div class="recovery-title-wrapper">
          <div class="recovery-title"><%- t('login.forgotPassword.incorrectAnswers.title') %></div>
    <div><%- t('login.forgotPassword.incorrectAnswers.subTitle') %></div>
      </div>
    </div>
  </div>
  <div class="recovery-success-message"><%= failureMessage %></div>
</div>
`;
// Exports
module.exports = code;