const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'SEARCH',
  'TOPIC_DETAILS',
  'ARTICLE_DETAILS',
  'ARTICLE_ATTACHMENT',
  'ARTICLE_EDIT',
  'ARTICLE_CREATE',
  'ARTICLE_HISTORY',
  'ARTICLE_REPORTS',
  'SELF_DIRECTED_PATHS',
  'COMMUNITIES',
  'SETTINGS',
  'IMPORT_HISTORY',
  'COMMUNITY_MANAGEMENT',
  'PAGE_INSIGHTS',
  'COMMUNITIES_INSIGHTS',
  'COMMUNITIES_INSIGHTS_REPORTS',
  'FILTERS',
  'PROFANITY_FILTER',
  'TAGS'
]);
