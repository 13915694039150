const I18n = require('@common/libs/I18n');
const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const DetailViewConfig = require('@common/components/details/DetailViewConfig');
const MetadataDetailItemView = require('@common/components/details/item/MetadataDetailItemView');
const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

const {
  getDueDateViewDefinition,
  getModulesViewDefinition,
  getVideosViewDefinition,
  getQuestionsViewDefinition,
  getAttemptsRemainingViewDefinition
} = require('@common/components/cardMetadata/MetadataStringHelper');

const {
  getMetadataProperties,
  isTopicRetake
} = require('@common/components/todaysActivities/TodaysActivitiesCardHelper');

/***  VIEW DEFINITIONS ***/
const _rewardPointsViewDefinition = (metaProperties) => {
  if (metaProperties.rewardPoints > 0) {
    let rewardString;
    if (metaProperties.rewardPoints === 1) {
      rewardString = I18n.t('assessments.item.rewardPoints.singular');
    } else {
      rewardString = I18n.t('assessments.item.rewardPoints.possible', { points: metaProperties.rewardPoints });
    }

    return {
      id: 'rewardPoints',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: rewardString
      }
    };
  }

  return null;
};

const _eventsViewDefinition = (metaProperties) => {
  if (metaProperties.events > 0) {
    let eventSessionsString;
    if (metaProperties.events === 1) {
      eventSessionsString = I18n.t('assessments.item.event.scheduledEvents.singular');
    } else {
      eventSessionsString = I18n.t('assessments.item.event.scheduledEvents.plural', {count: metaProperties.events});
    }

    return {
      id: 'events',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: eventSessionsString
      }
    };
  }

  return null;
};

/***  HELPER FUNCTIONS ***/
const _getMetadataString = (trainingType, nextStartableItem, videoAndModuleStats) => {
  const metaProperties = getMetadataProperties(trainingType, nextStartableItem, videoAndModuleStats);
  return [
    _rewardPointsViewDefinition(metaProperties),
    getModulesViewDefinition(metaProperties),
    getVideosViewDefinition(metaProperties),
    getQuestionsViewDefinition(metaProperties),
    _eventsViewDefinition(metaProperties)
  ];
};

const _getMetadataStringWithDueDate = (trainingType, nextStartableItem, videoAndModuleStats) => {
  return [
    getDueDateViewDefinition(nextStartableItem.dueDate),
    ..._getMetadataString(trainingType, nextStartableItem, videoAndModuleStats)
  ];
};

const _getMetadataStringWithAttemptsRemaining = (trainingType, nextStartableItem, videoAndModuleStats) => {
  return [
    ..._getMetadataString(trainingType, nextStartableItem, videoAndModuleStats),
    getAttemptsRemainingViewDefinition(nextStartableItem.attemptsRemaining)
  ];
};

const sessionTypeMetadataViewConfigMap = {
  [SessionTrainingType.DailyTraining]: _getMetadataString,
  [SessionTrainingType.CertificationTraining]: _getMetadataStringWithDueDate,
  [SessionTrainingType.IntroductoryTraining]: _getMetadataString,
  [SessionTrainingType.GuidedLearningTraining]: _getMetadataString,
  [SessionTrainingType.RefresherTraining]: _getMetadataString,
  [SessionTrainingType.FormalExamTraining]: _getMetadataStringWithAttemptsRemaining
};

const _getMetaDataViewForTrainingType = (trainingType, assessmentOptions) => {
  if (assessmentOptions == null) {
    return null;
  }

  const videoAndModuleStats = {
    moduleCount: assessmentOptions.moduleCount,
    videoCount: assessmentOptions.videoCount
  };

  let nextStartableItem = {};
  if (trainingType !== SessionTrainingType.DailyTraining && assessmentOptions.nextItem) {
    nextStartableItem = assessmentOptions.nextItem.toJSON();
  } else {
    nextStartableItem = assessmentOptions;
  }

  return sessionTypeMetadataViewConfigMap[trainingType](trainingType, nextStartableItem, videoAndModuleStats);
};

const _getMetaDataViewConfigForCertRetake = (assessmentOptions) => {
  const lastResult = assessmentOptions.lastRelevantResult;
  const nextItem = assessmentOptions.nextItem.toJSON();
  let grade;

  if (lastResult.totalAnswerCount !== 0) {
    grade = (lastResult.correctAnswerCount / lastResult.totalAnswerCount) * 100;
  }

  const values = {
    grade,
    numCorrect: lastResult.correctAnswerCount,
    total: lastResult.totalAnswerCount,
    gradeToPass: nextItem.passingGrade
  };

  const viewConfigs = [
    {
      id: 'numCorrect',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: I18n.t('assessments.item.lastScore', {
          correct: values.numCorrect,
          total: values.total
        })
      }
    },
    {
      id: 'percentPassed',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: values.grade != null ? parseInt(values.grade, 10) + '% ' + I18n.t('assessments.item.grade.text.fail') : ''
      }
    },
    {
      id: 'gradeToPass',
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: I18n.t('assessments.item.passGrade', { grade: values.gradeToPass })
      }
    }
  ];
  viewConfigs.unshift(getDueDateViewDefinition(nextItem.dueDate));
  return viewConfigs;
};

const _getMetaDataViewConfigForNewGuidedLearning = (itemsTotal) => {
  const text = itemsTotal > 1 ? I18n.t('hub.numItems', { numItems: itemsTotal }) : I18n.t('hub.numItemsSingular');

  return [
    {
      viewClass: MetadataDetailItemView,
      viewOptions: {
        value: text
      }
    }
  ];
};

const _getMetadataViewConfigs = (sessionType, currentAssessmentOption) => {
  const nextStartableItem = currentAssessmentOption.nextItem ? currentAssessmentOption.nextItem.toJSON() : null;

  if (sessionType === SessionTrainingType.CertificationTraining && isTopicRetake(nextStartableItem)) {
    return _getMetaDataViewConfigForCertRetake(currentAssessmentOption);
  } else if (sessionType === SessionTrainingType.GuidedLearningTraining && currentAssessmentOption.itemsCompleted === 0) {
    return _getMetaDataViewConfigForNewGuidedLearning(currentAssessmentOption.itemsTotal);
  }

  return _getMetaDataViewForTrainingType(sessionType, currentAssessmentOption);
};

const getMetaDataViewDefinition = (sessionType, currentAssessmentOption) => {
  return {
    ViewClass: StackedCollectionView,
    viewConfigModel: DetailViewConfig,
    className: 'stacked-collection-view',
    viewConfigs: _getMetadataViewConfigs(sessionType, currentAssessmentOption)
  };
};

module.exports = {
  getMetaDataViewDefinition
};
