const NextStartableAssessmentProvider = require('@training/apps/training/controllers/assessments/providers/NextStartableAssessmentProvider');

class DeepLinkingNextStartableAssessmentProvider extends NextStartableAssessmentProvider {
  constructor(initiatorControllerNextStartableAssessmentProvider) {
    super();
    this.initiatorControllerNextStartableAssessmentProvider = initiatorControllerNextStartableAssessmentProvider;
  }

  // canProduceNext is used to determine if the "Back to list" button should be shown on the AssessmentResultPage
  // Since deep link can produce an intro or extra training assessment it gets really complicated to say:
  // "Don't show the back to list button when assessment failed if the assessment was created from a deep link"
  // because Extra Training assessments can also be created from a deep link and those don't have the concept of
  // passed or failed. So simply on deep link we won't show the "back to list" regardless of assessment result or
  // assessment type. However the "Start Next" button may still be available to the user and after that assessment
  // is created the context is no longer "deep link" so it will assume the logic of the respective assessment
  // initiator's "NextStartable" implementation.
  canProduceNext() {
    return false;
  }

  getNext() {
    return this.initiatorControllerNextStartableAssessmentProvider.getNext();
  }
}

module.exports = DeepLinkingNextStartableAssessmentProvider;
