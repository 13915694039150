// Module
var code = `<div class="community-insights-actions__wrapper">
  <div class="js-current-insights__time-select community-insights-date-filter community-insights-date-filter--current"></div>
  <% if (!isMobile) { %>
    <button class="ax-button ax-button--m ax-button--branded js-insights__export community-insights-export-button">
      <span class="icon-download" aria-hidden="true"></span>
      <span class="export-button-label"><%= t('general.export') %></span>
    </button>
  <% } %>
</div>
<div class="js-insights__table"></div>
`;
// Exports
module.exports = code;