const PageableCollection = require('@common/components/pageableList/PageableCollection');

class PathSearchCollection extends PageableCollection {

  apiEndpoint() {
    return '/paths/search';
  }

  fetch(options = {}) {
    Object.assign(options, {
      data: {
        query: encodeURIComponent(this.searchQuery)
      }
    });
    return super.fetch(options);
  }
}

module.exports = PathSearchCollection;
