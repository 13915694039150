const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');

const Backbone = require('Backbone');

const SEAT_PERCENT_THRESHOLD = 15;
const SEAT_NUM_THRESHOLD = 5;

class LearningEvent extends Backbone.Model {
  parse(res = {}) {
    res = res.entity || res;

    // transform into locale strings for the client now
    res.localizableName = new LocalizableString(res.scheduledEvent.learningEvent.localizableName);
    res.localizableDescription = new LocalizableString(res.scheduledEvent.learningEvent.localizableDescription);

    return res;
  }

  isPastEvent() {
    return this.get('scheduledEvent').endDate < (new Date()).getTime();
  }

  getRemainingSeatCount() {
    const maxSeats = this.get('scheduledEvent').maxEnrollment;
    return maxSeats - this.get('numEnrolled');
  }

  hasSeatsRemaining() {
    return this.getRemainingSeatCount() > 0;
  }

  hasLowSeatInventory() {
    const maxSeats = this.get('scheduledEvent').maxEnrollment;
    const remainingSeats = this.getRemainingSeatCount();

    return (((remainingSeats / maxSeats) * 100) <= SEAT_PERCENT_THRESHOLD) || (remainingSeats < SEAT_NUM_THRESHOLD);
  }
}

module.exports = LearningEvent;
