const Backbone = require('Backbone');

// XXX: This only exists because of some bootstrapping oddities. Ideally,
// an auth app should be in charge of redirecting like this neccessairly but we'll
// allow it for now and use this is as a stopgap.
class DeferredRouteDispatcher {
  constructor() {
    this._queued = {};
    this._deferredMode = true;
  }

  navigate(hash, options) {
    if (this._deferredMode) {
      this._queued = {hash, options};
    } else {
      this._performNavigation(hash, options);
    }
  }

  setReady() {
    // Don't allow the queue to be called out more than once
    if (!this._deferredMode) {
      throw new Error('You can only ready the queue once.');
    }

    this._deferredMode = false;
    this._performNavigation(this._queued.hash, this._queued.options);
    this._queued = {};
  }

  _performNavigation(hash, options) {
    Backbone.history.loadUrl(hash, options);
  }
}

module.exports = DeferredRouteDispatcher;
