const _ = require('underscore');
const logging = require('logging');
const dateHelpers = require('@common/libs/dateHelpers');
const I18n = require('@common/libs/I18n');
const moment = require('moment');

const KeyCode = require('@common/data/enums/KeyCode');

const Form = require('@common/components/forms/Form');

require('jquery.ui');
require('@common/components/forms/editors/date/DateTimeEditor.less');

// Date Editor
Form.Editor.DateTime = class DateTime extends Form.Editor {
  timeToAnimate = 300;

  events() {
    return {
      keyup: 'onKeyboardPress',
      blur: 'onBlur',
      focus: 'onFocus'
    };
  }

  _setFunctionBindings() {
    this.getValue = this.getValue.bind(this);
    this.setValue = this.setValue.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.openPicker = this.openPicker.bind(this);
    this.hidePicker = this.hidePicker.bind(this);
    this.setError = this.setError.bind(this);
    this.getError = this.getError.bind(this);
    this.clearError = this.clearError.bind(this);
    this.validateDate = this.validateDate.bind(this);
  }

  initialize(options = {}) {
    this.template = _.tpl(require('@common/components/forms/editors/date/DateTimeEditor.html'));
    this._setFunctionBindings();

    this.opt = _.clone(options.options != null ? options.options : (options.options = {}));

    if (this.opt.showTimeControls == null) {
      this.opt.showTimeControls = true;
    }

    this._isPickerInUtcTime = this.opt.isPickerInUtcTime != null ? this.opt.isPickerInUtcTime : false;
    this._allowFieldToBeUnset = this.opt.allowFieldToBeUnset != null ? this.opt.allowFieldToBeUnset : false;
    this.minuteSelectorInterval = this.opt.minuteSelectorInterval != null ? this.opt.minuteSelectorInterval : 15;

    // 60 (minutes in an hour) should be divisible by @minuteSelectorInterval without a remainder
    if (((60 % this.minuteSelectorInterval) !== 0) || (this.minuteSelectorInterval <= 0) || (this.minuteSelectorInterval > 60)) {
      throw new Error('minuteSelectorInterval can only be one of the following factors of 60: \
1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30, 60');
    }

    // Set data formats
    this.opt.placeholder = this.opt.placeholder != null ? this.opt.placeholder : dateHelpers.getFormatToDefaultDateTime();
    this.format = this.opt.format != null ? this.opt.format : function (date) {
      if (this._isPickerInUtcTime) {
        return dateHelpers.convertUtcDateFormatToDefaultDateTime(date);
      }
      return dateHelpers.convertDateFormatToDefaultDateTime(date);

    };

    // Some options are over-ridden here, they're musts
    this.opt.changeYear = true;
    this.opt.changeMonth = true;
    this.opt.showButtonPanel = false; // Hide for our own custom panel

    this.disabled = false;
    this._onKeyPressValue = null;

    // Enforce constraints
    if (this.opt.minDate) {
      this.setMinValue(this.opt.minDate);
    }
    if (this.opt.maxDate) {
      this.setMaxValue(this.opt.maxDate);
    }

    // The first valid date is either the minimum or today; depending if a minimum is enforced or not.
    if (this.opt.minDate != null) {
      this.firstValidDate = this.opt.minDate;
    } else {
      this.firstValidDate = this._calculateToNextAvailableMinuteInterval(dateHelpers.createDate().valueOf());
    }

    this._setupView();
    this._injectRolloverHandler();

    // Show the time controls if they're are involved here
    if (this.opt.showTimeControls) {
      this._initializeTimeControls();
    }

    this.$datepicker.datepicker(this.opt);
  }

  _initializeTimeControls() {
    this.$goToTimepicker = this.$datepickerContainer.find('.go-to-timepicker');
    this.$goToTimepicker.show();

    this.$datepickerContainer.on('change', '[name=\'hours\'], [name=\'minutes\'], [name=\'ampm\']', this._commitPicker.bind(this));
    this.$datepickerContainer.on('click', '.go-to-timepicker', this._openTimePicker.bind(this));
    this.$datepickerContainer.on('click', '.back-to-calendar', this._closeTimePicker.bind(this));

    this.$timePicker = this.$datepickerContainer.find('.time-picker');
    this.$hours = this.$datepickerContainer.find('select[name=\'hours\']');
    this.$minutes = this.$datepickerContainer.find('select[name=\'minutes\']');
    this.$meridiem = this.$datepickerContainer.find('select[name=\'ampm\']');
  }

  _bodyHandler(e) {
    const $clickedOn = $(e.target);

    const parentClasses = [
      '.ui-datepicker-prev',
      '.ui-datepicker-next',
      '.ui-datepicker-calendar',
      '.datetime',
      '.datetime-popdown',
      '.ui-datepicker-year',
      '.ui-datepicker-title'
    ];

    const numberOfParents = _.reduce(parentClasses, (memo, cls) => {
      let tempMemo = memo;
      tempMemo += $clickedOn.closest(cls).length;
      return tempMemo;
    }, 0);

    if ((numberOfParents === 0) && this.$datepickerContainer.is(':visible') && !$clickedOn.is(this.$el)) {
      this.hidePicker();
    } else if ($clickedOn.is(this.$el)) {
      this.openPicker();
    }
  }

  _setupView() {
    this.$datepickerContainer = $(this.template({
      minuteSelectorInterval: this.minuteSelectorInterval
    }));
    // If popover, inject directly after
    if (this.$el.is('input')) {
      this.$el.attr('placeholder', `${ I18n.t('general.ex') } ${ this._getDisplayString(this.firstValidDate) }`);
      this.$el.attr('autocomplete', 'off');
      this.$el.after(this.$datepickerContainer);
      this.$datepickerContainer.hide();
      $('body').on('click', this._bodyHandler.bind(this));
    } else { // Inline mode
      this.$el.html(this.$datepickerContainer.html());
      this.$datepickerContainer = this.$el;
    }

    this.$datepicker = this.$datepickerContainer.find('.date');

    // Setup events that aren't strictly a part of this element but rather the container...
    this.$datepickerContainer.on('click', '#now', () => {
      this.setDatePickerDate(this.firstValidDate);
      this._commitPicker();
      if (!this.opt.showTimeControls) {
        this.hidePicker();
      }
    });
  }

  _injectRolloverHandler() {
    const oldCallbackonChangeMonthYear = this.opt.onChangeMonthYear;
    this.opt.onChangeMonthYear = (year, month, dateField) => {

      const oldDate = this.$datepicker.datepicker('getDate');

      // Date wrap around
      if (oldDate && ((oldDate.getFullYear() !== year) || ((oldDate.getMonth() + 1) !== month))) {
        const day = oldDate.getDate();
        const newDate = this.createDateWithControlsData(year, month - 1, day);

        this.rollDateToEndOfMonth(newDate, day);
        this.setDatePickerDate(newDate);
      }

      // Bubble the callback up
      const fieldName = $(this.$el).attr('data-field');
      if (typeof oldCallbackonChangeMonthYear === 'function') {
        oldCallbackonChangeMonthYear(year, month, dateField, fieldName);
      }

      this._commitPicker();
    };

    const oldCallbackonSelect = this.opt.onSelect;
    this.opt.onSelect = (dateText) => {
      if (typeof oldCallbackonSelect === 'function') {
        oldCallbackonSelect(dateText);
      }
      this._commitPicker();
    };
  }

  _commitPicker() {
    const date = this.$datepicker.datepicker('getDate');
    let hours = 0;
    let minutes = 0;

    // If time controls are shown, then commit that data, too
    if (this.opt.showTimeControls) {
      hours = parseInt(this.$hours.val(), 10);

      if (this.$minutes.val() == null) {
        minutes = moment(this._value()).minutes() || 0;
      } else {
        minutes = parseInt(this.$minutes.val(), 10);
      }

      const meridiem = this.$meridiem.val();

      // Wrap if needed
      if ((meridiem === 'PM') && (hours !== 12)) {
        hours += 12;
      } else if ((meridiem === 'AM') && (hours === 12)) {
        hours = 0;
      }
    }

    const timestampToCommit = (this.createDateWithControlsData(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0)).getTime();
    this.setValue(timestampToCommit, false);
  }

  _updateControls(date) {
    // Update the time controls if required when dealing with this
    if (this.opt.showTimeControls) {
      const data = this.getHoursMinutesMeridiem(date);
      this.$hours.val(data.hours);
      this.$minutes.val(data.minutes);
      this.$meridiem.val(data.meridiem);
    }

    this.setDatePickerDate(date);
  }

  getHoursMinutesMeridiem(date) {
    let hours, minutes, ref;
    if (this._isPickerInUtcTime) {
      hours = date.getUTCHours();
      minutes = date.getUTCMinutes();
    } else {
      hours = date.getHours();
      minutes = date.getMinutes();
    }

    return {
      hours: (ref = (hours % 12)) === 0 ? 12 : ref,
      minutes,
      meridiem: hours >= 12 ? I18n.t('UIKit.Form.Editor.DateTime.PM') : I18n.t('UIKit.Form.Editor.DateTime.AM')
    };
  }

  _updateInputIfNeeded(timestamp) {
    if (this.$el.is('input') && this._value()) {
      this.$el.val(this._getDisplayString(timestamp));
    }
  }

  _resetInput() {
    this.$el.val('');
  }

  disable() {
    if (!this.$el.is('input')) {
      return;
    }
    this.$el.addClass('disabled').prop('readonly', true);
    this.disabled = true;
  }

  enable() {
    if (!this.$el.is('input')) {
      return;
    }
    this.$el.removeClass('disabled').prop('readonly', false);
    this.disabled = false;
  }

  // This method can be overridden to change how things would be displayed,
  // otherwise just over-ride the date format string
  _getDisplayString(timestamp) {
    return this.format(timestamp);
  }

  _openTimePicker() {
    this.$timePicker.show();
    this.$timePicker.animate({
      height: this.$datepickerContainer.height()
    }, this.timeToAnimate);
  }

  _closeTimePicker(animate = true) {
    const heightToAnimate = this.$goToTimepicker.height();
    if (animate) {
      this.$timePicker.animate({
        height: heightToAnimate
      }, this.timeToAnimate, () => {
        return this.$timePicker.hide();
      });
    } else {
      this.$timePicker.css('height', heightToAnimate);
      this.$timePicker.hide();
    }
  }

  // Keep the state on the DOM since if the form ends up recreated,
  // the state would not survive, where-as it does on the DOM
  _value() {
    return this.$el.data('currentTimestamp');
  }

  _setValue(timestamp) {
    return this.$el.data('currentTimestamp', timestamp);
  }

  getValue() {
    return this._value();
  }

  // This will update the internal state along with the picker itself
  setValue(timestamp, updateControls = true) {
    if (timestamp) {
      if (!_.isNumber(timestamp) || _.isNaN(timestamp)) {
        throw new Error('timestamp needs to be a number');
      }

      this._setValue(timestamp);
      this._onKeyPressValue = null;
      this.trigger('change:date', timestamp, this);
      this.trigger('change', this);

      // If not inline (was set on an input), then update the input box with the formatted date
      this._updateInputIfNeeded(timestamp);
      if (updateControls) {
        this._updateControls(new Date(timestamp));
      }
    } else if (this._allowFieldToBeUnset) {
      //Need to reset the state of editor to initial state.
      this._onKeyPressValue = null;
      this._setValue(null);
      this._resetInput();
    }
  }

  onKeyboardPress(e) {
    if (!this.$el.is('input') || !$(e.target).is(this.$el) || (e.which === KeyCode.TAB)) {
      return;
    }

    if (this.$datepickerContainer.is(':visible')) {
      this.hidePicker();
    }

    if (this._allowFieldToBeUnset && _(this.$el.val()).isEmpty()) {
      this.setValue(null);
    } else if (e.which === KeyCode.ENTER) {
      this._onKeyPressValueUpdate();
    } else {
      const timeStamp = this.convertInputStringToTimestamp();

      if (isNaN(timeStamp)) {
        return;
      }

      const date = new Date(timeStamp);

      if (!isNaN(date.getTime()) && date.getFullYear() > 1000) {
        this._onKeyPressValue = date;
      }
    }
  }

  _onKeyPressValueUpdate() {
    if ((this._onKeyPressValue != null ? this._onKeyPressValue.getTime() : undefined) != null) {
      this.setValue(this._onKeyPressValue.getTime());
    }
  }

  onBlur() {
    this._onKeyPressValueUpdate();
    super.onBlur();
  }

  onFocus() {
    this.openPicker();
    super.onFocus();
  }

  openPicker() {
    if (this.disabled) {
      return;
    }

    this._onKeyPressValueUpdate();

    // Override invalid values
    if (!this._value()) {
      this._updateControls(this.firstValidDate);
    }
    this.$datepickerContainer.show();
    this.$datepicker.show();
    // tabindex is set to -1 to all <a> and <select> inside datepicker container to prevent tabbing on this elements
    this.$datepickerContainer.find('a, select').attr('tabindex', '-1');
  }

  hidePicker() {
    this.$datepickerContainer.hide();
    if (this.opt.showTimeControls) {
      this._closeTimePicker(false);
    }
  }

  setError() {
    this.$el.addClass('rederror');
  }

  getError() {
    return this.$el.attr('data-error');
  }

  clearError() {
    this.$el.removeClass('rederror');
  }

  setMinValue(value) {
    if (value) {
      try {
        this.opt.minDate = (this.minValue = this._calculateToNextAvailableMinuteInterval(value));
      } catch (error) {
        logging.debug(`Invalid Min Date value: ${ error }`);
      }
    }
  }

  setMaxValue(value) {
    if (value) {
      try {
        this.opt.maxDate = (this.maxValue = this._calculateToNextAvailableMinuteInterval(value));
      } catch (error) {
        logging.debug(`Invalid Max Date value: ${ error }`);
      }
    }
  }

  updateValidDateRange(min, max) {
    this.setMinValue(min);
    this.setMaxValue(max);
    this.firstValidDate = this.minValue;
    this.$datepicker.datepicker('option', this.opt);
  }

  _calculateToNextAvailableMinuteInterval(value) {
    if (!this.opt.showTimeControls) {
      return dateHelpers.createDate(value).startOf('day')
        .toDate();
    }

    const interval = this.minuteSelectorInterval;
    const date = dateHelpers.createDate(value);
    date.subtract(date.minute() % interval, 'minutes').add(interval, 'minutes');
    return date.startOf('minute').toDate();
  }

  validateDate() {
    const value = this._value();

    if (!value) {
      return this._allowFieldToBeUnset ? value === null : false; // If we let the user unset the value, then null is valid, otherwise any falsy value is invalid
    }
    if (this.minValue && (value < this.minValue.getTime())) {
      return false;
    }
    if (this.maxValue && (value > this.maxValue.getTime())) {
      return false;
    }

    return true;
  }

  createDateWithControlsData(...args) {
    let day = 1;
    if (args[2] != null) {
      day = args[2];
      args[2] = 1;
    }

    let momentDate = dateHelpers.createDate(args);
    if (this._isPickerInUtcTime) {
      momentDate = dateHelpers.createUtcDate(args);
    }

    return dateHelpers.performMomentOperation('add', day - 1, 'days', momentDate);
  }

  setDatePickerDate(date) {
    let formattedDate = date;
    if (this._isPickerInUtcTime) {
      formattedDate = dateHelpers.getSameUtcTimeInTimeZone(date, dateHelpers.getBrowserTimezone()).toDate();
    }

    this.$datepicker.datepicker('setDate', formattedDate);
  }

  rollDateToEndOfMonth(newDate, day) {
    if (this._isPickerInUtcTime) {
      if (newDate.getUTCDate() < day) {
        newDate.setUTCDate(0); // ECMASCript specifies that '0' is always the end of a month
      }
    } else if (newDate.getDate() < day) {
      newDate.setDate(0);
    }
  }

  convertInputStringToTimestamp() {
    if (this._isPickerInUtcTime) {
      return dateHelpers.convertUtcDateFormat(this.$el.val(), this.opt.placeholder);
    }
    return dateHelpers.convertDateFormat(this.$el.val(), this.opt.placeholder);

  }

  // Since we injected into the DOM, be sure to clean up after ourselves
  onDestroy() {
    this.$datepickerContainer.off('click');
    this.$datepickerContainer.off('change');
    this.$datepickerContainer.remove();
    this.$datepicker.datepicker('destroy');
    $('body').off();
  }
};


module.exports = Form.Editor.DateTime;
