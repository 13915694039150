import {
  Drawer as MuiDrawer,
  styled,
  type DrawerProps as MuiDrawerProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

export type AxDrawerProps = Omit<MuiDrawerProps, 'elevation'>;

const styledOptions = {
  name: 'AxDrawer'
};

const StyledAxDrawer = styled(MuiDrawer, styledOptions)<AxDrawerProps>(() => {
  return {};
});

/**
 * The `AxDrawer` component is a wrapper around the MUI `Drawer` component.
 *
 * ### Links
 * MUI: [Drawer](https://mui.com/material-ui/react-drawer/)
 */
export const AxDrawer = forwardRef(({
  ...otherProps
}: AxDrawerProps, ref: Ref<HTMLDivElement>) => {
  const DrawerProps: AxDrawerProps = {
    ...otherProps
  };

  return (
    <StyledAxDrawer { ...DrawerProps } ref={ ref } />
  );
});

export default AxDrawer;
