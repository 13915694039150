const $ = require('jquery');
const logging = require('logging');

class UriTokenAuthenticationStrategy {
  // state =
  //   employeeid : number
  //   authtoken : string
  //   timestamp : number
  login(state = {}) {
    const deferred = $.Deferred();

    logging.debug('module:auth - UriTokenAuthenticationStrategy begin');

    if (state.employeeid && state.authtoken && state.timestamp) {
      // The request came from the customer's portal.
      // Make a call to 'securl' to verify the token.
      return $.ajax({
        url: '/axonify/securl',
        data: state,
        skipGlobalHandler: true
      }).then((data = {}) => {
        logging.debug('module:auth - UriTokenAuthenticationStrategy: secure URL login was successful.');
        return data.entity; // user returned by the server
      })
        .fail(() => {
          logging.debug('securl Login Error; rejecting login');
        });
    }
    logging.error('Missing parameters. Rejecting the promise.');
    deferred.reject();


    return deferred.promise();
  }
}

module.exports = UriTokenAuthenticationStrategy;
