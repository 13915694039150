// Module
var code = `<div
  class="knowledgeitem <%- item.rating %> level<%- ['', 'one', 'two', 'three', 'four', 'five'][item.level] %> status<%- item.progressLevel %> clearfix"
  data-generated-id="<%- item.generatedId %>"
  data-topic-id="<%- item.topic.id %>"
  data-level="<%- item.level %>"
  <% if(item.trainingModule) { %>
    data-module-id="<%- item.trainingModule.id %>"
  <% } %>
>
  <div class="topicholder clearfix">
    <button class="droparrow icon-angle_right" aria-expanded="false" aria-label="<%- t('knowledge.showQuestions') %>"></button>
    <% if(isRecommended) { %>
    <div role="img" title="<%- t('knowledge.statusTitle.recommended') %>" class="recommended icon-light_bulb"></div>
    <% } else if (!isOnlyTraining) { %>
    <div class="percentage <%- percentageHighlightClass %>" title="<%- percentageHighlightA11yNote %>">
      <span class="off-screen"><%- percentageHighlightA11yNote %></span>
      <%- percentageString %>
    </div>
    <div role="img" title="<%- performanceTitle %>" class="score <%- performanceClass %> icon-<%- performanceClass %>"></div>
    <% } else { %>
    <div class="percentage <%- percentageHighlightClass %>">
      <%- percentageString %>
    </div>
    <div role="img" title="<%- performanceTitle %>" class="score <%- performanceClass %> icon-<%- performanceClass %>"></div>
    <% } %>
    <h3 class="areas subscontainer">
      <span class="subs"><%- item.category %>, <%- item.subject %></span>
      <span class="topicname semiboldfont"><%- item.topic.name %> – <%- t('knowledge.level', {level: item.level}) %></span>
        <% if(isRecommended) { %>
        <span><%- item.recommendationReason %></span>
        <% } %>
    </h3>
    <button class="icon-angle_right row-arrow silver" aria-label="<%- t('knowledge.showQuestions') %>"></button>
  </div>

  <div class="questions clearfix">
    <ul class="questionswrap clearfix">
      <li class="noquestions hidden"><%- t('extratraining.noquestions') %></li>
    </ul>
  </div>

  <div class="trainingoptions ax-grid">
    <% if (hasTrainingModule && trainingModulePreviewEnabled) { %>
      <div class="preview-module ax-grid__col--auto-size">
        <%= axButton({ label: modulePreviewButtonLabel, className: 'qa-axon-button--preview-training preview-training' }) %>
      </div>
    <% } %>
    <% if (isExtraTrainingEnabled) { %>
      <div class="extratrainingbuttons ax-grid__col--auto-size">
        <% if (hasInProgressAssessment) { %>
          <%= axButton({ label: t('assessments.item.action.resume'), className: takeTrainingButtonClass + ' viewtraining' }) %>
        <% } else { %>
          <%= axButton({ label: t('extratraining.viewtraining'), className: takeTrainingButtonClass + ' viewtraining' }) %>
        <% } %>
      </div>
    <% } %>
  </div>

  <div class="certification-status">
    <div role="img"
      class="icon <%- item.certificationStatus.toLowerCase() === 'na' ? 'hidden' : 'icon-' + item.certificationStatus.toLowerCase() %>"
      title="<%- t('knowledge.certification.' + item.certificationStatus) %>">
    </div>
  </div>

  <div class="clear"></div>
</div>
`;
// Exports
module.exports = code;