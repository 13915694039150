const GuidedLearningObjective = require('@common/data/models/objectives/GuidedLearningObjective');
const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');

const TASK_NAME_FIELD = 'name';
const QUESTION_COUNT_FIELD_NAME = 'questionCount';
const MODULE_COUNT_FIELD_NAME = 'moduleCount';
const TOPIC_LEVEL_PROGRESS = 'topicLevelProgress';
const IS_INTRO_FIELD_NAME = 'isIntro';
const START_FIELD_NAME = 'started';
const VIDEO_COUNT_FIELD_NAME = 'videoCount';

class TopicGuidedLearningObjective extends GuidedLearningObjective {
  defaults() {
    return {
      name: '',
      pointsAvailable: 0,
      objectiveDetails: {
        [TOPIC_LEVEL_PROGRESS]: {
          level: 1,
          graduated: false,
          started: false
        }
      },
      prerequisiteId: null
    };
  }

  getName() {
    return this.get(TASK_NAME_FIELD);
  }

  canBegin() {
    return this.getActionableItem() != null && this.getEnrollmentStatus();
  }

  getType() {
    return GuidedLearningObjectiveType.TOPIC_OBJECTIVE;
  }

  getNumberOfQuestions() {
    const questionCount = this.getObjectiveDetails()[QUESTION_COUNT_FIELD_NAME];
    return questionCount || 0;
  }

  getNumberOfVideos() {
    const videoCount = this.getObjectiveDetails()[VIDEO_COUNT_FIELD_NAME];
    return videoCount || 0;
  }

  getNumberOfModules() {
    const moduleCount = this.get(MODULE_COUNT_FIELD_NAME);
    return moduleCount || 0;
  }

  getTopicLevelProgress() {
    return this.getObjectiveDetails()[TOPIC_LEVEL_PROGRESS];
  }

  hasDependencies() {
    return this.getPrerequisite() != null;
  }

  isIntro() {
    return this.getObjectiveDetails()[IS_INTRO_FIELD_NAME] === true;
  }

  hasStarted() {
    const item = this.getTopicLevelProgress();
    return item[START_FIELD_NAME] === true;
  }
}

module.exports = TopicGuidedLearningObjective;
