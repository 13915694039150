const $os = require('detectOS');
const logging = require('logging');

const Backbone = require('Backbone');
const Marionette = require('Marionette');

const UIKit = require('@training/widgets/UIKit');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');

const {
  joinUrlFragments,
  getLocationHash
} = require('@common/libs/helpers/app/UrlHelpers');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

const EventEnrollmentStatus = require('@common/data/enums/EventEnrollmentStatus');

const ScheduledEventDetailsView = require('@training/apps/training/views/ScheduledEventDetailsView');
const LearningEventAttendeesView = require('@training/apps/training/views/LearningEventAttendeesView');

const LearningEventSessionList = require('@training/apps/training/collections/LearningEventSessionList');
const LearningEventAttendeesList = require('@training/apps/training/collections/LearningEventAttendeesList');

const ScheduledEvent = require('@training/apps/training/models/ScheduledEvent');
const LearningEventEnrollment = require('@training/apps/training/models/LearningEventEnrollment');
require('@common/libs/behaviors/msHideable/MsHideable');

class LearningEventDetailsPage extends Marionette.LayoutView {

  static TAB_NAME = {
    DETAILS: 'details',
    ATTENDEES: 'attendees'
  }

  getTemplate() {
    return require('@training/apps/training/templates/LearningEventDetailsPage.html');
  }

  ui() {
    return {
      tabContainer: '.tab-container',
      subTabs: '.subtabs'
    };
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  initialize(options = {}) {
    ({
      eventId: this.eventId,
      scheduledEventId: this.scheduledEventId,
      enrollmentId: this.enrollmentId,
      initialTab: this.initialTab = LearningEventDetailsPage.TAB_NAME.DETAILS,
      routePrefix: this.routePrefix = '',
      eventEnrollmentRecordCallback: this.eventEnrollmentRecordCallback = () => {}
    } = options);

    this.attendeesList = new LearningEventAttendeesList([], {
      eventId: this.eventId,
      scheduledEventId: this.scheduledEventId,
      pageSize: this.getAttendeesPageSize()
    });

    this.scheduledEvent = new ScheduledEvent({
      id: this.scheduledEventId
    }, {
      eventId: this.eventId
    });

    this.learningEventSessions = new LearningEventSessionList([], {
      eventId: this.eventId,
      scheduledEventId: this.scheduledEventId
    });

    this.listenTo(this.scheduledEvent, 'sync change', this.onUpdateEvent);

    this.enrollment = new LearningEventEnrollment({}, {
      id: this.enrollmentId,
      eventId: this.eventId,
      scheduledEventId: this.scheduledEventId
    });

    this.listenToOnce(this.enrollment, 'error', (enrollment, xhr) => {
      this._showEnrollmentWarning(xhr);
    });

    this._updateTabHashes();
  }

  onRender() {
    this.tabs = new UIKit.TabBar({
      el: this.ui.subTabs,
      container: this.ui.tabContainer
    });

    this.listenTo(this.tabs, 'change:tabs', this.onChangeTabs);

    this.listenTo(this.enrollment, 'change', () => {
      this._updateTabHashes();
      this._updateTabRoutes(this.tabs.currentTabName);
      this._setButtons();
    });

    this.tabs.clickTab(this.initialTab);
  }

  viewDidAppear() {
    logging.info('LearningEventsPage - viewDidAppear');

    window.app.layout.showHeaderBackButton();
  }

  onChangeTabs(tabName) {
    this._updateTabRoutes(tabName);

    const v = this.tabs.views[tabName];
    if (!v) {
      const viewPromise = (tabName === 'details') ? this._createDetailView() : this._createAttendeesView();

      viewPromise.then((view) => {
        if (this.isDestroyed) {
          return;
        }

        view.render();
        this.tabs.setViewForTabName(view, tabName);
        this.tabs.triggerAdjustment();
      });
    }

    if (tabName === LearningEventDetailsPage.TAB_NAME.DETAILS ) {
      app.layout.setTitle(I18n.t('learningEvents.details.detailsTabTitle'));
    } else {
      app.layout.setTitle(I18n.t('learningEvents.details.attendeesTabTitle'));
    }
  }

  onDestroy() {
    this.enrollment.abortXHR(Sync.Method.READ);
    this.attendeesList.abortXHR(Sync.Method.READ);
    this.scheduledEvent.abortXHR(Sync.Method.READ);

    window.app.layout.resetLeftHeaderView();

    this.actionBar?.setActionButton(null);
    this.tabs.unload();
  }

  _updateTabHashes() {
    const hashFragment = joinUrlFragments([this.eventId, this.scheduledEventId, this.enrollment.get('id')], '-');

    this.tabHashes = {
      [LearningEventDetailsPage.TAB_NAME.DETAILS]: joinUrlFragments([this.routePrefix, 'eventDetails', hashFragment, LearningEventDetailsPage.TAB_NAME.DETAILS]),
      [LearningEventDetailsPage.TAB_NAME.ATTENDEES]: joinUrlFragments([this.routePrefix, 'eventDetails', hashFragment, LearningEventDetailsPage.TAB_NAME.ATTENDEES])
    };
  }

  _updateTabRoutes(tabName) {
    Backbone.history.navigate(this.tabHashes[tabName], {
      trigger: false,
      replace: true
    });
  }

  _createDetailView() {
    return Promise.all([this._getScheduledEvent(), this._getEnrollment(), this._getEventSessions()]).then(() => {
      return new ScheduledEventDetailsView({
        model: this.scheduledEvent,
        learningEventSessions: this.learningEventSessions,
        enrollment: this.enrollment
      });
    });
  }

  _getScheduledEvent() {
    return Promise.resolve(this.scheduledEvent.fetch());
  }

  _getEnrollment() {
    return Promise.resolve(this.enrollment.fetch());
  }

  _getEventSessions() {
    return Promise.resolve(this.learningEventSessions.fetch());
  }

  _getAttendees() {
    return Promise.resolve(this.attendeesList.getFirstPage());
  }

  _refreshEventCheckinModel() {
    return Promise.resolve(app.sessionController.eventCheckinModel.fetch());
  }

  _createAttendeesView() {
    return Promise.all([this._getEnrollment(), this._getAttendees(), this._getScheduledEvent()]).then(() => {
      return new LearningEventAttendeesView({
        model: this.scheduledEvent,
        enrollment: this.enrollment,
        collection: this.attendeesList,
        collectionEvents: {
          'reset update': triggerResize.bind(true)
        }
      });
    });
  }

  onUpdateEvent() {
    this._setButtons();
  }

  _onEnrollmentCompleted() {
    return this.eventEnrollmentRecordCallback(this.enrollment);
  }

  _setButtons() {
    const buttonProperties = this._getEnrollButtonProperties();

    this.actionBar.setActionButton(buttonProperties);
  }

  _getButtonEnrollmentText() {
    if (this.scheduledEvent.isPastEvent()) {
      return null;
    } else if (this.enrollment.isEnrolled()) {
      if (this.scheduledEvent.get('enableSelfEnrollment')) {
        return I18n.t('learningEvents.details.cancelEnrollment');
      }
      return I18n.t('learningEvents.details.enrolled');

    } else if (this.enrollment.isOnWaitingList()) {
      return I18n.t('learningEvents.details.leaveWaitingList');
    }
    if (this.scheduledEvent.isFull() && this.scheduledEvent.get('enableWaitingList')) {
      return I18n.t('learningEvents.details.joinWaitingList');
    } else if (!this.scheduledEvent.isFull()) {
      return I18n.t('learningEvents.details.enroll');
    }
    return null;


  }

  _getEnrollButtonProperties() {
    const buttonText = this._getButtonEnrollmentText();

    if (!buttonText) {
      return null;
    }

    const buttonOptions = {
      type: 'customText',
      text: buttonText,
      onClick: this._enrollEvent.bind(this)
    };

    if (!this.scheduledEvent.get('enableSelfEnrollment')) {
      buttonOptions.onClick = null;
      buttonOptions.disabled = true;
    }

    return buttonOptions;
  }

  _enrollEvent() {
    this.actionBar.disableButton('customText');

    const refreshData = () => {
      Promise.all([this._getAttendees(), this._getScheduledEvent(), this._refreshEventCheckinModel()]).then(() => {
        this.scheduledEvent.trigger('enrollment:updated');
        this._onEnrollmentCompleted();
      });
    };

    const requiresSigningUp = !this.enrollment.hasEnrollmentState();
    if (requiresSigningUp) {

      let enrollmentStatus;

      if (this.scheduledEvent.isFull() && this.scheduledEvent.get('enableWaitingList')) {
        enrollmentStatus = EventEnrollmentStatus.Waiting;
      } else if (!this.scheduledEvent.isFull()) {
        enrollmentStatus = EventEnrollmentStatus.Enrolled;
      } else {
        return;
      }

      this.enrollment.save({
        user: window.apps.auth.session.user.id,
        status: enrollmentStatus
      }, {
        success: refreshData
      });
    } else {
      this.enrollment.destroy({
        success: refreshData
      });
    }
  }

  _showEnrollmentWarning(xhr) {
    // this should not happen, but if it does, it shouldn't break the users session
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();

    const errorsToHandleLocally = [
      AxonifyExceptionCode.EVENT_MGMT_WOULD_EXCEED_CAPACITY,
      AxonifyExceptionCode.EVENT_MGMT_USER_NOT_QUALIFIED,
      AxonifyExceptionCode.EVENT_MGMT_SELF_ENROLLMENT_NOT_ALLOWED
    ];

    const errorsRequiringRedirect = [
      AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED,
      AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY
    ]
    
    if (errorsRequiringRedirect.includes(errCode)) {
      xhr.skipGlobalHandler = true;
      const shortenedId = `${ this.eventId }-${ this.scheduledEventId }`;
      const URL = getLocationHash();
      if (URL.includes('details')) {
        Backbone.history.navigate(`#hub/eventDetails/${ shortenedId }/details`, {
          trigger: true,
          replace: true
        });
      } else if (URL.includes('attendees')) {
        Backbone.history.navigate(`#hub/eventDetails/${ shortenedId }/attendees`, {
          trigger: true,
          replace: true
        });
      }
    }

    if (errorsToHandleLocally.includes(errCode)) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t(`learningEvents.details.errors.${ errCode }`));

      this.actionBar.disableButton('customText');
    }
  }

  // number of users to load in pagination
  getAttendeesPageSize(os = $os) {
    return os.mobile ? 20 : 200;
  }
}

module.exports = LearningEventDetailsPage;
