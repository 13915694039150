const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { LayoutView } = require('Marionette');
const ViewController = require('@common/libs/UI/controllers/ViewController');
const EvaluationSubmissionPreviewDetailsView = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionPreviewDetailsView');
const ProgressBarFactory = require('@common/components/progress/progressBar/ProgressBarViewConfigFactory');
const I18n = require('@common/libs/I18n');

class EvaluationSubmissionUploadProgressController extends LayoutController {

  initialize(options = {}) {
    ({
      model: this.model,
      percent: this.percent
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'file-uploading',
      template: `\
        <div class="file-uploading__file-name-region"></div>
        <div class="file-uploading__progress-region"></div>`,
      regions: {
        fileNameRegion: '.file-uploading__file-name-region',
        progressRegion: '.file-uploading__progress-region'
      }
    };
  }

  regionControllers() {
    return {
      fileNameRegion: {
        ViewControllerClass: ViewController,
        viewDefinition: {
          ViewClass: EvaluationSubmissionPreviewDetailsView,
          model: this.model,
          enableDownload: false
        }
      },
      progressRegion: {
        ViewControllerClass: LayoutController,
        viewDefinition: ProgressBarFactory.getProgressBarViewDefinition({
          viewClassName: 'upload-progress',
          message: I18n.t('GuidedLearning.evaluation.details.percentUploaded', { percent: this.percent })
        }),
        regionControllers: ProgressBarFactory.getProgressBarRegionControllers({
          value: this.percent,
          animate: true
        }),
        delegateEvents: {
          'view:show': (name, controller, view) => {
            view.listenTo(this.glChannel.vent, 'submission:upload:progress', (percent) => {
              view.currentView.progressBar.currentView.setValue(percent);
              if (percent >= 100) {
                $('.progress-message', view.$el).text(I18n.t('GuidedLearning.evaluation.details.uploadProcessing'));
              } else {
                $('.progress-message', view.$el).text(I18n.t('GuidedLearning.evaluation.details.percentUploaded', { percent }));
              }
            });

          }
        }
      }
    };
  }
}

module.exports = EvaluationSubmissionUploadProgressController;
