import { type PropsWithChildren } from 'react';
import {
  type AxDialogContextState,
  AxDialogContextProvider,
  type AxDialogRendererProps,
  AxDialogRenderer
} from '@common/modules/react/themes/components';
import { useAppContext } from '@common/modules/react/bootstrap/AppContext';

export interface AxDialogHostProps extends PropsWithChildren {
  initialState?: AxDialogContextState
  defaultDialogProps?: AxDialogRendererProps['defaultDialogProps']
}

export default function AxDialogHost(props: AxDialogHostProps): JSX.Element {
  const {
    children,
    initialState
  } = props;

  const [defaultDialogProps] = useAppContext((store) => {
    return store.defaultDialogProps;
  });

  return <AxDialogContextProvider initialState={ initialState }>
    { children }
    <AxDialogRenderer defaultDialogProps={ defaultDialogProps }/>
  </AxDialogContextProvider>;
}
