const _ = require('underscore');

const AssessmentType = require('@common/data/enums/AssessmentType');
const AssessmentTopicOption = require('@common/data/models/assessments/AssessmentTopicOption');
const AssessmentLaunchContext = require('@common/data/enums/AssessmentLaunchContext');

// We use `IntroductoryTraining` as the default simply because that was the business decision at this time, changing
// this may mean broken links if they expect that omitting the `assessmentType` query parameter would yield this type.
const DEFAULT_ASSESSMENT_TYPE = AssessmentType.IntroductoryTraining;
// Similar to the default assessment type we default the level to 1 if the query parameter is omitted or not parsable.
const DEFAULT_LEVEL_VALUE = 1;

module.exports = (queryParams = {}) => {
  const topicId = parseInt(queryParams.topicId, 10) || undefined;
  const level = parseInt(queryParams.level, 10) || DEFAULT_LEVEL_VALUE;
  const programId = parseInt(queryParams.programId, 10) || undefined;
  const course = queryParams.course || undefined;
  const assessmentType = AssessmentType.assertLegalValue(queryParams.assessmentType || DEFAULT_ASSESSMENT_TYPE);

  if (!_.isNumber(topicId)) {
    throw new Error('You must pass in a valid Topic ID.');
  }

  return new AssessmentTopicOption({
    topic: {
      id: topicId
    },
    level,
    programId,
    course,
    [AssessmentTopicOption.FIELDS.FOR_ASSESSMENT_TYPE]: assessmentType,
    launchContext: AssessmentLaunchContext.DEEP_LINK
  });
};
