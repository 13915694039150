const Backbone = require('Backbone');
const dateHelpers = require('@common/libs/dateHelpers');

class NativeAppReviewModel extends Backbone.Model {
  apiEndpoint() {
    return '/mobileReviews';
  }

  parse(response = {}) {
    return response.entity;
  }

  shouldShowReviewModal() {
    const reviewModalWasNevenShown = !this.has('createDate');
    const sixMonthsAgo = dateHelpers.performMomentOperation('subtract', 6, 'months');
    const reviewModalShownMoreThanSixMonthsAgo = dateHelpers.compareDates(this.get('createDate'), 'isBefore', sixMonthsAgo, 'day');

    return reviewModalWasNevenShown || reviewModalShownMoreThanSixMonthsAgo;
  }
}

module.exports = NativeAppReviewModel;
