const I18n = require('@common/libs/I18n');
const LegacyUIKitView = require('@common/components/view/LegacyUIKitView');

class GuidedLearningLayoutView extends LegacyUIKitView {
  useMarionetteRender() {
    return true;
  }

  getTemplate() {
    return require('./GuidedLearningLayoutView.html');
  }

  templateHelpers() {
    return {
      title: this._getTitle(),
      icon: this._getIcon()
    };
  }

  _getTitle() {
    return I18n.t('GuidedLearning.title');
  }

  _getIcon() {
    return 'icon-guided_learning';
  }

  ui() {
    return {contentRegion: '.content-region'};
  }

  regions() {
    return {
      titleRegion: '.js-title-region',
      contentRegion: '.content-region',
      howToModalRegion: '.js-gl-how-to__dialog-region'
    };
  }

  triggers() {
    return {'click .js-how-to-link': 'openGLModal'};
  }

  initialize(options = {}) {
    ({
      continuePredicate: this.continuePredicate,
      continueCallback: this.continueCallback
    } = options);
    this._token = null;
  }

  onShow() {
    if (this.continuePredicate()) {
      this._showActionBar();
    }
  }

  getFadingEl() {
    if (this.isDestroyed) {
      return this.$el; // Safety against destroyed views
    }
    return this.ui.contentRegion;

  }

  _showActionBar() {
    this.actionBar.setActionButton({
      type: 'done',
      onClick: this.continueCallback
    });
  }

  onDestroy() {
    this.actionBar.setActionBar();
    window.app.layout.resetLeftHeaderView();
  }
}

module.exports = GuidedLearningLayoutView;
