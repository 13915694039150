const AbstractAssessmentInitiatorTracker = require('./AbstractAssessmentInitiatorTracker');
const InitiatorStorageEntry = require('./InitiatorStorageEntry');

const ASSESSMENT_STORAGE_TRACKING_KEY = 'assessmentInitiator';

class ClientStorageAssessmentInitiatorTracker extends AbstractAssessmentInitiatorTracker {
  constructor(initiatorRegistry, indexableStateStorage) {
    super(initiatorRegistry);
    this.indexableStateStorage = indexableStateStorage;
  }

  trackAssessment(context, assessment) {
    const key = this._buildKeyForAssessment(assessment.id);
    this.indexableStateStorage[key] = JSON.stringify(context);
  }

  findAssessmentInitiator(assessment) {
    const key = this._buildKeyForAssessment(assessment.id);
    const contextJson = this._getContextFromAssessmentKey(key);
    const initiator = this.initiatorRegistry.findInitiatorWithHandlerFor(contextJson.type);

    if (initiator) {
      const context = initiator.createInitiatorContext(contextJson);
      return { context, initiator };
    }
    return null;

  }

  stopTrackingAssessmentId(assessmentId) {
    const key = this._buildKeyForAssessment(assessmentId);
    delete this.indexableStateStorage[key];
  }

  asEntryIterator() {
    const iterableEntries = [];
    for (const entryKey in this.indexableStateStorage) {
      if (entryKey.startsWith(ASSESSMENT_STORAGE_TRACKING_KEY)) {
        const assessmentIdComponent = entryKey.split('-')[1];
        const assessmentId = parseInt(assessmentIdComponent, 10);
        const jsonContext = this._getContextFromAssessmentKey(entryKey);
        let {timestamp} = jsonContext;

        // Since some older entries might not have timestamps, we set them to a day in the past since this is a full
        // session period. This does mean that things that are tracked in the past might be trimmed a bit more aggressively
        // but this will clear itself up fairly quick.
        if (!timestamp) {
          timestamp = new Date().setHours(-24);
        } else {
          timestamp = new Date(timestamp).getTime();
        }

        // This context is not the same as an init. contxt, it's just metadata attached to the items
        iterableEntries.push(new InitiatorStorageEntry(assessmentId, {}, timestamp));
      }
    }

    return iterableEntries;
  }

  _getContextFromAssessmentKey(key) {
    const jsonString = this.indexableStateStorage[key];
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return {};
    }
  }

  _buildKeyForAssessment(assessmentId) {
    return `${ ASSESSMENT_STORAGE_TRACKING_KEY }-${ assessmentId }`;
  }
}

module.exports = ClientStorageAssessmentInitiatorTracker;
