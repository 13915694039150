import {
  Stepper as MuiStepper,
  styled,
  type StepperTypeMap as MuiStepperTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxStepperCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

type PropsToOmit = 'elevation' | 'variant';

export type AxStepperTypeMap<P = object, D extends React.ElementType = 'div'> = {
  defaultComponent: D;
  props: P & Omit<MuiStepperTypeMap<P, D>['props'], PropsToOmit> & AxStepperCustomProps;
};

export type AxStepperProps<
  D extends ElementType = AxStepperTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxStepperTypeMap<P & AxStepperCustomProps, D>, D>;

const styledOptions = {
  name: 'AxStepper'
};

const StyledAxStepper = styled(MuiStepper, styledOptions)<AxStepperProps>(() => {
  return {};
});

/**
 * A wrapper around MuiStepper that applies our custom styles.
 *
 *  Note: this component adds icons and connectors to the stepper whitout having to use `AxStepIcon` and `AxStepConnector`.
 *
 * ### Links
 * - [MUI Stepper API](https://mui.com/material-ui/api/stepper/)
 */
export const AxStepper: MuiOverridableComponent<AxStepperTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxStepperProps, ref: Ref<HTMLDivElement>) => {
  const stepperProps: AxStepperProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxStepper { ...stepperProps } ref={ ref } >
      { children }
    </StyledAxStepper>
  );
});

export default AxStepper;
