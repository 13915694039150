const logging = require('logging');
const _ = require('underscore');

const { View } = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const { preventDragAndDrop } = require('@common/libs/helpers/app/BrowserHelpers');
const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');

const TincanCompletionTrackerFactory = require('@training/apps/training/views/activities/tincanTracker/TincanCompletionTrackerFactory');
const TinCanView = require('@common/components/training_modules/tincan/TinCanView');

const {
  ActionBarType,
  createButtonType
} = require('@common/components/actionBarButton/ActionBarButtonFactory');

class TinCanPage extends View {
  template = _.tpl(require('@training/apps/training/templates/activities/pages/TinCanPage.html'));

  id() {
    return 'tincan-content-holder';
  }

  regions() {
    return {
      tincanWrapper: '#tincan-content-wrapper'
    };
  }

  constructor(options = {}) {
    super(options);

    this.onNext = this.onNext.bind(this);
    this.onSkip = this.onSkip.bind(this);

    this.activity = assertPropertyExists(options, 'activity');
    this.continueButtonFactory = assertPropertyExists(options, 'continueButtonFactory');

    ({
      isRetake: this.isRetake = false,
      continueButtonFactory: this.continueButtonFactory,
      complete: this.complete = () => {}
    } = options);

    this.package = this.activity.getTincanPackage();
  }

  render() {
    logging.info(`TinCanPage - render - training module id: ${ this.activity?.get('objectId') }`);
    this.$el.html(this.template());

    const { user } = window.apps.auth.session;
    const language = user.get('language');

    const tincanView = new TinCanView({
      model: this.activity,
      previewingUser: user,
      language,
      packageProvider: () => {
        return this.package;
      }
    });

    this.tincanWrapper.show(tincanView);

    preventDragAndDrop(this.$('iframe'));

    let skipButton;

    if (this.activity.isBypassable()) {
      skipButton = createButtonType(ActionBarType.SKIP, this.onSkip);
    } else if (this.isRetake) {
      skipButton = createButtonType(ActionBarType.SKIP, this.onNext);
    }

    logging.info('TinCanPage - render - _.defer _setupListener');
    _.defer(() => {
      this.actionBar.setActionButton(skipButton);
      this._setupListener();
    });

    return this;
  }

  _setupListener() {
    const newButton = this.continueButtonFactory(this.onNext);

    const listener = new TincanCompletionTrackerFactory().createTracker(this.package);
    listener
      .startTracking(this.package, window.apps.auth.session.user)
      .then((completed) => {
        if (completed) {
          logging.info(`TinCanPage - _setupListener - newButton: ${ JSON.stringify(newButton) }`);
          this.actionBar.setActionButton(newButton);
        }
      })
      .catch((error) => {
        logging.error('There was an error. Reporting this.');
        logging.error(error);
      });
  }

  viewDidAppear() {
    logging.info('TinCanPage - viewDidAppear');
    window.app.layout.setTitle(I18n.t('tincan.title'));
  }

  onBeforeAttach() {
    window.app.layout.toggleFullPage(true);
    window.app.layout.toggleFooter(false);
  }

  onNext() {
    this.completeTinCan(false);
  }

  onSkip() {
    this.completeTinCan(true);
  }

  completeTinCan(skipped = false) {
    if (this.nextDisabled) {
      return;
    }
    this.nextDisabled = true;

    logging.info(`TinCanPage - completeTinCan ${ skipped } ${ this.activity.id }`);

    if (skipped) {
      this.activity.skipActivity().then(() => {
        this.complete();
      });
    } else {
      this.activity.setAction('TINCANSAVE', {}, {
        success: () => {
          this.complete();
        }
      });
    }
  }

  onDestroy() {
    window.app.layout.toggleFullPage(false);
    window.app.layout.toggleFooter(true);
  }

  onClose() {
    this.actionBar?.setActionButton(null);
    super.onClose();
  }
}

module.exports = TinCanPage;
