const Backbone = require('Backbone');
const _ = require('underscore');

const dateHelpers = require('@common/libs/dateHelpers');
const I18n = require('@common/libs/I18n');

const INCREMENT_TIME = 0.1; // in seconds

class SecondTimer extends Backbone.View {
  initialize(options = {}) {
    this.timeFormats = [
      {id: '%Y', str: I18n.t('secondTimer.timeFormatShortform.year'), seconds: 31536000},
      {id: '%W', str: I18n.t('secondTimer.timeFormatShortform.week'), seconds: 604800},
      {id: '%D', str: I18n.t('secondTimer.timeFormatShortform.day'), seconds: 86400},
      {id: '%h', str: I18n.t('secondTimer.timeFormatShortform.hour'), seconds: 3600},
      {id: '%m', str: I18n.t('secondTimer.timeFormatShortform.minute'), seconds: 60}
    ];

    // {options.totalTime} required
    // {options.timeFormat} optional
    ({finishCallback: this.finishCallback = _.noop} = options);

    this.totalTime = options.totalTime;
    this.timeFormat = options.timeFormat;

    this.$el.text(this.formatTime(this.totalTime));
  }

  start() {
    this._startTime = dateHelpers.getTime();
    this.$el.trigger('started');

    this.interval = setInterval(() => {
      const remainingTime = Math.round(this.totalTime - this.elapsedTime());

      if (remainingTime <= 0.0) {
        this.$el.text(this.formatTime(0));
        this.stop();
        this.finishCallback();
      }
    }, INCREMENT_TIME * 1000.0);
  }

  stop() {
    clearInterval(this.interval);
  }

  elapsedTime() {
    return (dateHelpers.getTime() - this._startTime) / 1000; // in seconds
  }

  formatTime(time) { // time in seconds
    if (this.timeFormat) {
      let timeLeft = time;
      const timeArr = [];
      if (timeLeft < 60) {
        return Math.round(time) + 's';
      } else if (timeLeft < 120) {
        return '2m';
      }
      for (const format of Array.from(this.timeFormats)) {
        const n = Math.floor(timeLeft / format.seconds);
        if (n !== 0) {
          timeLeft -= n * format.seconds;
          const timeStr = n.toFixed(0) + format.str;
          timeArr.push(timeStr);
        }
      }
      return timeArr.join(' ');
    }
    return Math.round(time).toFixed(0);

  }
}

module.exports = SecondTimer;
