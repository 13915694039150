const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const HeaderBarLayout = require('@common/modules/main/header/HeaderBarLayout');

const HeaderContextConfig = require('@common/modules/main/header/HeaderContextConfig');
const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');
const MenuPosition = require('@common/modules/main/menu/MenuPosition');
const HeaderRegionControllerDefinitionFactory = require('@common/modules/main/header/HeaderRegionControllerDefinitionFactory');

module.exports = (contextModel) => {
  const overflowItemsCollection = MenuContextConfig.getItemsOverflow(contextModel);
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: HeaderBarLayout
    },
    regionControllers: HeaderRegionControllerDefinitionFactory(contextModel),
    delegateEvents: {
      'view:render': (controller, view) => {
        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.LEFT_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('leftHeaderRegion', controllerDefinition, options.showOptions);
        });

        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.RIGHT_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('rightHeaderRegion', controllerDefinition, options.showOptions);
        });

        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.CHAT_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('chatHeaderNavButtonRegion', controllerDefinition, options.showOptions);
        });

        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.LOGO_VISIBILITY }`, (state, visible) => {
          view.toggleLogo(visible);
        });

        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.HEADER_DESKTOP_HIDEABLE }`, (state, toggle) => {
          view.toggleDesktopHideable(toggle);
        });

        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.CURRENT_MENU_POSITION }`, (state, contextMenuPosition) => {
          view.toggleTopPosition(contextMenuPosition === MenuPosition.Top);
        });

        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.IS_LOCKED_TO_TOP }`, (state, contextIsLockedToTop) => {
          view.toggleLockedToTop(contextIsLockedToTop);
        });

        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.ALWAYS_SHOW_BACK_BUTTON }`, (state, contextAlwaysShowBackButton) => {
          view.toggleLeftRegionAlwaysShow(contextAlwaysShowBackButton);
        });

        controller.listenTo(overflowItemsCollection, 'reset add remove', () => {
          view.toggleMenuOverflow(overflowItemsCollection.length > 0);
        });

        view.toggleLogo(HeaderContextConfig.getLogoVisibility(contextModel));
        view.toggleDesktopHideable(HeaderContextConfig.getHeaderDesktopHideable(contextModel));
        view.toggleTopPosition(MenuContextConfig.getCurrentMenuPosition(contextModel) === MenuPosition.Top);
        view.toggleMenuOverflow(overflowItemsCollection.length > 0);
        view.toggleLeftRegionAlwaysShow(MenuContextConfig.getAlwaysShowBackButton(contextModel));
        view.toggleLockedToTop(MenuContextConfig.getIsLockedToTop(contextModel));
      },
      'view:attach': (controller, view) => {
        view.toggleNavigationRole(MenuContextConfig.getCurrentMenuPosition(contextModel) === MenuPosition.Top);
        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.CURRENT_MENU_POSITION }`, (state, position) => {
          view.toggleNavigationRole(position === MenuPosition.Top);
        });
      },
      'view:resize view:dom:mutate': (controller, view) => {
        const widthAvailable = view.getMenuWidthAvailable();

        contextModel.set(MenuContextConfig.Keys.MENU_TOP_WIDTH_AVAILABLE, widthAvailable);
      }
    }
  };
};
