const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const $os = require('detectOS');
const PageStatus = require('@common/components/discover/enums/PageStatus');

class ChangeLanguageModal extends Marionette.LayoutView {
  initialize(options = {}) {
    ({
      submitChanges: this.submitChanges,
      callback: this.callback
    } = options);
  }

  getTemplate() {
    return `
      <div class="modalHeader">
        <button class="close icon-remove" aria-label="<%- t('general.cancel') %>"></button>
      </div>
      <p class="confirm-dialog__title ax-font--no-margin"><%- t('discover.publishFlow.navigateConfirmTitle') %></p>
      <div class="confirm-dialog__phrase ax-font--secondary">
        <%= t('discover.publishFlow.navigateConfirmBody', { saveAction: secondaryText, languageName: languageName }) %>
      </div>
    `;
  }

  templateHelpers() {
    return {
      languageName: this._getCurrentLanguageName(),
      secondaryText: this._getSecondaryText()
    };
  }

  className() {
    return 'confirm-dialog parent-height';
  }

  onRender() {
    this.actionBar.setActionBar({ buttons: this._getButtons() });
  }

  _save() {
    this.submitChanges(() => {
      this.model.save().done(() => {
        window.app.layout.flash.success(I18n.t(`discover.pageTypes.${ this.model.get('type') }.save.success`,
          { title: this.model.getTitle() }));

        this.callback();
      })
        .fail((xhr) => {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('discover.publishFlow.publishError'));
        });
    });
  }

  _getSecondaryText() {
    if (this.model.getStatus() === PageStatus.REVIEW || this.model.getStatus() === PageStatus.QUARANTINE) {
      return $os.mobile ? I18n.t('general.save') : I18n.t('general.saveChanges');
    }
    
    return $os.mobile ? I18n.t('general.send') : I18n.t('discover.publishFlow.sendForReview');
  }

  _getButtons() {
    return [
      {
        type: 'cancel',
        className: 'qa-newlanguage-modal-cancel-button white',
        onClick: () => {
          this.destroy();
        }
      },
      {
        type: 'customText',
        text: I18n.t('general.discard'),
        className: 'qa-newlanguage-modal-discard-button white',
        onClick: () => {
          this.callback();
          this.destroy();
        }
      },
      {
        type: 'customText',
        text: this._getSecondaryText(),
        className: 'qa-newlanguage-modal-review-button',
        onClick: () => {
          this.model.set('markFact', false);
          this._save();
          this.destroy();
        }
      }
    ];
  }

  _getCurrentLanguageName() {
    return I18n.t('languages.' + this.model.get('language')
      .toUpperCase()
      .replace(/-|_/g, ''));
  }
}

module.exports = ChangeLanguageModal;
