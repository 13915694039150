// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__icon icon-events" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('learningEvents.notification.title') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="learningevent-list">
      <div class="learningevent-listcontainer">
        <div id="eventlist" class="learningevent-list">
          <ul></ul>
        </div>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;