const Backbone = require('Backbone');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const _ = require('underscore');

const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');

const CommunityInsightsFactory = require('@training/apps/training/communitiesInsights/CommunityInsightsFactory');
const CommunityInsightsReportsFactory = require('@training/apps/training/communitiesInsights/CommunityInsightsReportsFactory');

const {
  COMMUNITIES_INSIGHTS,
  COMMUNITIES_INSIGHTS_REPORTS
} = require('@common/data/enums/SearchPageEnum');

class CommunitiesInsightsFlowController extends FlowController {
  constructor(...args) {
    super(...args);

    this.pageState = {};
  }

  processSequenceFlow(options) {
    return this.showPage(options).then((handled) => {
      if (handled) {
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  showPage(options = {}) {
    const {
      pageId,
      pageOptions
    } = this._getPageArgs(options);

    if (options.pageId == null) {
      return this._attemptToRestoreDiscoverPage().then(() => {
        return true;
      },
      Promise.resolve.bind(null, false));
    }

    return this._showPageView(pageId, pageOptions).then(() => {
      this._clearPageState();
      return true;
    },
    Promise.resolve.bind(null, false));
  }

  _getPageArgs(options = {}) {
    if (options.pageId != null) {
      return options;
    }

    return Object.assign(options, {
      pageId: this.pageState.pageId,
      pageOptions: _.omit(this.pageState.pageOptions || {}, 'navigate')
    });
  }

  _clearPageState() {
    this.pageState = {};
  }

  _showPageView(pageId, pageOptions) {
    switch (pageId) {
      case COMMUNITIES_INSIGHTS:
        return this._showCommunityInsights(pageOptions);
      case COMMUNITIES_INSIGHTS_REPORTS:
        return this._showCommunityInsightsReports(pageOptions);
      default:
        return Promise.reject(Promise.OperationalError('No discover flow matched...'));
    }
  }

  _attemptToRestoreDiscoverPage() {
    const hash = UrlHelpers.getLocationHash();

    if (hash.includes('hub/search') || hash.includes('hub/paths')) {
      _.defer(() => {
        Backbone.history.loadUrl(hash);
      });
      return Promise.resolve();
    }
    return Promise.reject(Promise.OperationalError('No discover flow matched...'));

  }

  _showCommunityInsights(pageOptions) {
    window.app.layout.setView(CommunityInsightsFactory(pageOptions));
    return Promise.resolve();
  }

  _showCommunityInsightsReports(pageOptions) {
    window.app.layout.setView(CommunityInsightsReportsFactory(pageOptions));
    return Promise.resolve();
  }
}


module.exports = CommunitiesInsightsFlowController;
