const { has } = require('underscore');

const { REPORT_CARD } = require('@common/data/enums/HubTileIdentifiers').default;

const LoadingController = require('@common/components/loading/LoadingController');

const { getTopicProgressCardControllerDefinition } = require('@training/apps/home/performance/topicProgress/TopicProgressCardControllerDefinitionFactory');

module.exports = (availableTiles, topicProgressItemList, callback) => {
  if (!has(availableTiles, REPORT_CARD)) {
    return null;
  }

  return () => {
    topicProgressItemList.fetch({ showSpinner: false });

    const contentControllerDefinition = getTopicProgressCardControllerDefinition({
      topicProgressItemList,
      callback
    });

    return {
      ViewControllerClass: LoadingController.Wrapper,
      syncTracker: {
        entities: topicProgressItemList,
        loadOnSync: true
      },
      viewDefinition: {
        spinner: false
      },
      contentControllerDefinition
    };
  };
};

