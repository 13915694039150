const Backbone = require('Backbone');
const Marionette = require('Marionette');
const Form = require('@common/components/forms/Form');
const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');

class SecurityQuestionPage extends Marionette.LayoutView {
  getTemplate() {
    return require('@common/modules/auth/views/recovery/password/SecurityQuestionPage.html');
  }

  templateHelpers() {
    const currentQuestion = this._flowStateModel.get('currentQuestion');
    return {
      recoveryQuestion: currentQuestion
    };
  }

  events() {
    return {
      'click .submit': 'onSubmitAnswer'
    };
  }

  initialize(options = {}) {
    ({
      flowStateModel: this._flowStateModel
    } = options);

    this.answerModel = new Backbone.Model({
      recoveryAnswer: ''
    });
  }

  onRender() {
    this.form = new Form({
      el: this.$el,
      model: this.answerModel
    });
  }

  onSubmitAnswer() {
    this.trigger('submit', CryptographyHelper.getMD5Hash(this.answerModel.get('recoveryAnswer')));
  }
}

module.exports = SecurityQuestionPage;
