const logging = require('logging');
const LearnerTrainingTabsEnum = require('@training/apps/learnerTraining/LearnerTrainingTabsEnum');
const LearnerTrainingPage = require('@training/apps/learnerTraining/LearnerTrainingPage');
const LearnerTrainingTabController = require('@training/apps/learnerTraining/learnerTrainingTab/LearnerTrainingTabController');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { LEARNER_TRAINING } = require('@common/data/enums/HubTileIdentifiers').default;

const { getTodaysActivitiesCardControllerDefinition } = require('@common/components/todaysActivities/TodaysActivitiesCardControllerDefinitionFactory');

const _getTabName = (options = {}) => {
  let initialTab = 'topics';
  if (LearnerTrainingTabsEnum.isValidTrainingTab(options.initialTab)) {
    initialTab = options.initialTab;
  }
  return initialTab;
};

module.exports = (options = {}) => {
  const {
    topicTrainingCardsCollection,
    pathTrainingCardsCollection,
    catalogTrainingCardsCollection,
    catalogFilterSubjectList,
    assessmentCallback,
    startTrainingCallback,
    complete,
    sessionModel
  } = options;

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: LearnerTrainingPage
    },

    regionControllers: {
      dailyTrainingBanner: () => {
        if (sessionModel.isMinDailyTrainingFulfilled()) {
          return {};
        }

        const session = options.sessionController.session;
        const currentAssessmentOptionModel = session.getCurrentAssessmentOptionsList().first();

        return getTodaysActivitiesCardControllerDefinition({
          sessionType: session.getTrainingSessionType(),
          currentAssessmentOptionModel,
          complete,
          startTrainingCallback
        });
      },
      tabContent: {
        initialTab: _getTabName(options),
        ViewControllerClass: LearnerTrainingTabController,
        assessmentCallback,
        topicTrainingCardsCollection,
        pathTrainingCardsCollection,
        catalogTrainingCardsCollection,
        catalogFilterSubjectList,
        browseType: options.browseType,
        pageNum: options.pageNum,
        searchQuery: options.searchQuery
      }
    },

    delegateEvents: {
      'view:render': (controller, view) => {
        const timeLogId = window.apps.base.timeLogController.bindPageViewLog(view, 'Training');
        logging.info(`Showing ${ LEARNER_TRAINING }, timeLogId: ${ timeLogId }`);
      }
    }
  };
};

