const _ = require('underscore');
const Marionette = require('Marionette');
const SelectableCollectionItem = require('./SelectableCollectionItem');

require('@common/components/selectable_collection_list/selectable_list.less');

class SelectableCollectionView extends Marionette.CollectionView {
  className() {
    return 'collection-list';
  }

  tagName() {
    return 'ul';
  }

  getChildView() {
    return SelectableCollectionItem;
  }

  get childEvents() {
    return {
      'item:selected': '_itemSelected'
    };
  }

  constructor(...args) {
    super(...args);
    this.childViewOptions = this.childViewOptions.bind(this);
  }

  childViewOptions() {
    return {
      formattingOptions: this.formattingOptions,
      onDeleteClick: this.onDeleteClick,
      showDeleteButton: this.showDeleteButton
    };
  }

  initialize(options = {}) {
    _.defaults(options,
      {
        formattingOptions: {},
        onDeleteClick: _.noop,
        showDeleteButton: true,
        autoSelect: true // List will select the first item on render
      });

    this.listenTo(this.collection, 'clear:selected', () => {
      this.clearSelectedItem();
    });

    return ({ formattingOptions: this.formattingOptions, onDeleteClick: this.onDeleteClick, autoSelect: this.autoSelect} = options);
  }

  onAddChild() {
    if (this.children.length === this.collection.length && this.autoSelect) {
      this.setSelectedItemByIndex(0);
    }
  }

  onRemoveChild(child) {
    if (child.isDestroyed && (this._currentChild === child) && this.autoSelect) {
      const couldSet = this.setSelectedItemByIndex(child._index - 1);
      if (!couldSet) {
        this.setSelectedItemByIndex(child._index + 1);
      }
    }
  }

  setToLastItem() {
    const child = this.children.last();
    if (child != null) {
      this.setSelectedItem(child, child.model);
    }
  }

  clearSelectedItem() {
    if (this._currentChild) {
      this._currentChild.selected = false;
      this._currentChild.render();
      this._currentChild = null;
    }
  }

  setSelectedItem(child, model) {
    // Re-assign immediately; no need to keep the context
    this._currentModel = model;

    // If we have an old child, re-render it before reassigning
    if (this._currentChild) {
      this._currentChild.selected = false;
    }

    if (!(this._currentChild != null ? this._currentChild.isDestroyed : undefined)) {
      if (this._currentChild != null) {
        this._currentChild.render();
      }
    }

    // Update new child
    this._currentChild = child;
    if (this._currentChild) {
      this._currentChild.selected = true;
    }

    if (this._currentChild) {
      this._currentChild.render();
    }

    // Notify people that care about this change
    this.trigger('item:selected', this._currentModel);
  }

  _itemSelected(child, model) {
    this.setSelectedItem(child, model);
  }

  setSelectedItemByIndex(index) {
    const child = this.children.findByIndex(index);
    // If there is a first child to render
    if (child != null) {
      this.setSelectedItem(child, child.model);
      return true;
    }
    // Set it to nothing, ths selection is empty / not available
    this.setSelectedItem(null, null);
    return false;

  }
}

module.exports = SelectableCollectionView;
