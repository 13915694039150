module.exports = {
  LARGE: {
    width: 200,
    height: 200
  },
  MEDIUM: {
    width: 50,
    height: 50
  },
  SMALL: {
    width: 40,
    height: 40
  }
};
