const ErrorDataValueMappers = require('@common/prerequisites/libs/error/ErrorDataValueMappers');

module.exports = {
  error: (errorObj) => {
    errorObj = ErrorDataValueMappers.error(errorObj);

    if (errorObj != null && errorObj.stackTrace != null) {
      errorObj.stackTrace = errorObj.stackTrace.join('\n');
    }

    return errorObj;
  },
  environment: (environment) => {
    environment = ErrorDataValueMappers.environment(environment);

    if (environment != null) {
      delete environment.userAgent;
    }

    return environment;
  },
  logs: (logs) => {
    logs = ErrorDataValueMappers.logs(logs);
    return logs && logs.join('\n');
  }
};
