// Module
var code = `<tr class="ranked-row<% if (item.isMe) {%> you<% } %>" data-rank="<%- item.rank %>">
  <td colspan="1" class="center rank-wrapper">
    <% if (item.rank > -1) { %>
      <div class="rank">
        <%- item.rank + 1 %>
      </div>
    <% } %>
  </td>
  <td colspan="1" width="50%">
    <%- item.name %>
      <% if (item.isMe) { %>
        (<%- t('stats.yourteam') %>)
      <% } %>
  </td>
  <td colspan="1" class="center">
    <%- item.points %>
  </td>
</tr>
`;
// Exports
module.exports = code;