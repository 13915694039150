import {
  AxDialogDefaultActionBar,
  AxDialogDefaultContent,
  AxDialogDefaultHeader,
  type AxDialogDefaultContentProps,
  type AxDialogDefaultHeaderProps
} from '@common/modules/react/themes/components';

export interface AxDialogDefaultLayoutProps extends AxDialogDefaultHeaderProps, AxDialogDefaultContentProps {
  content?: JSX.Element
  buttons?: JSX.Element
  title?: JSX.Element | string
  headerProps?: AxDialogDefaultHeaderProps
}

export default function AxDialogDefaultLayout(props: AxDialogDefaultLayoutProps): JSX.Element {
  const {
    title,
    onClose,
    onBack,
    content,
    buttons,
    qaClassName,
    headerProps,
    dividers = true
  } = props;

  const headerDefaultProps = {
    onClose,
    onBack,
    ...headerProps
  };

  const contentProps = {
    dividers
  };

  return <>
    <AxDialogDefaultHeader { ...headerDefaultProps } qaClassName={ `${ qaClassName }-header` }>{ title }</AxDialogDefaultHeader>
    <AxDialogDefaultContent { ...contentProps } qaClassName={ `${ qaClassName }-content` }>{ content }</AxDialogDefaultContent>
    <AxDialogDefaultActionBar qaClassName={ `${ qaClassName }-action-bar` }>{ buttons }</AxDialogDefaultActionBar>
  </>;
}
