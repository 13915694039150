import { useTheme } from '@mui/material';
import { type AX_AVATAR_SIZE } from './types';

type sizeStyles = {
  width: number | string;
  height: number | string;
};

export function useGetSizeStyles(size: AX_AVATAR_SIZE): sizeStyles {
  const theme = useTheme();
  
  const sizeStylesMap: { [key in AX_AVATAR_SIZE]: sizeStyles } = {
    xs: {
      width: theme.uiKit.avatar.size.xs,
      height: theme.uiKit.avatar.size.xs
    },
    sm: {
      width: theme.uiKit.avatar.size.sm,
      height: theme.uiKit.avatar.size.sm
    },
    md: {
      width: theme.uiKit.avatar.size.md,
      height: theme.uiKit.avatar.size.md
    },
    lg: {
      width: theme.uiKit.avatar.size.lg,
      height: theme.uiKit.avatar.size.lg
    },
    xl: {
      width: theme.uiKit.avatar.size.xl,
      height: theme.uiKit.avatar.size.xl
    }
  };

  return sizeStylesMap[size || 'medium'];
}
