const I18n = require('@common/libs/I18n');
const Page = require('@common/components/discover/models/Page');

const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

class Reference extends Page {
  apiEndpoint() {
    return '/references';
  }

  get className() {
    return 'Reference';
  }

  defaults() {
    return Object.assign(super.defaults(), {
      title: ''
    });
  }

  validators() {
    return {
      url: [function(value) {
        if (!UrlHelpers.isUrl(value)) {
          return I18n.t('discover.errors.Reference.url');
        }
        return undefined;

      }
      ],
      title: ['required', function(value) {
        if (value.length > Reference.MAX_TITLE_LENGTH) {
          return I18n.t('discover.errors.Reference.title.length', {maxTitleLength: Reference.MAX_TITLE_LENGTH});
        }
        return undefined;
      }
      ],
      language: 'required',
      community: 'required'
    };
  }
}

module.exports = Reference;
