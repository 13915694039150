const logging = require('logging');
const {
  isFunction,
  isString
} = require('underscore');

const { Behavior } = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');

const {
  registerBackHandler,
  unregisterBackHandler
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

/*
  AndroidBackHandler

  Behavior that allows assigning a handler function for Android Native back button handling.

  Options:
    'handler' - handler function or string that represents the name of the function on the View class
                to be executed when Android back button is pressed. Handler function should return `true`
                if no more handlers should be executed, 'false` otherwise.

                !!IMPORTANT!! If nothing is returned from the handler function, the result defaults to 'true'.
*/

Behaviors.AndroidBackHandler = class AndroidBackHandler extends Behavior {

  initialize(options = {}) {
    this._backHandler = this._backHandler.bind(this);

    this.handler = this._initHandler(options.handler);
  }

  onRender() {
    if (this.handler) {
      registerBackHandler(this._backHandler);
    }
  }

  onDestroy() {
    if (this.handler) {
      unregisterBackHandler(this._backHandler);
    }
  }

  _backHandler() {
    const handled = this.handler();

    if (handled == null) {
      return true;
    }

    return handled;
  }

  _initHandler(handler) {
    if (isString(handler)) {
      handler = this.view[handler];
    }

    if (isFunction(handler)) {
      return handler.bind(this.view);
    }

    logging.warn('`handler` option is missing or missconfigured for the AndroidBackHandler!');
    return null;
  }
};

module.exports = Behaviors.AndroidBackHandler;
