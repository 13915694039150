import { createElement } from 'react';
import {
  DEFAULT_TAG,
  getTranslatedProperties,
  translateItem
} from './I18nHelper';
import {
  type I18nStringProps,
  type PassedComponentPropType
} from './I18nTypes';

/**
 * A custom React wrapper for I18n
*
* @example
* // Basic usage
* <I18nString i18nKey="greeting" values={{ name: 'John' }} />
*
* // Usage with additional props
* <I18nString i18nKey="greeting" values={{ name: 'John' }} className="my-greeting" />
*
* // Usage with "as" prop
* <I18nString i18nKey='greeting' values={{ name: 'John' }} as={ Typography }/>
*
* <I18nString i18nKey='greeting' values={{ name: 'John' }} as='button'/>
*
* // Usage with translatable attribute
* // Only keys with "i18n-" prefixes will be translated.
*
*   <I18nString
*     as='img'
*     src='/someimage/image.png'
*     i18n-aria-label='label'
*     i18n-alt={{
*       key: 'greeting',
*       values: {name: 'John'}
*     }}/>
*/
export default function I18nString<T extends PassedComponentPropType = typeof DEFAULT_TAG> ({
  i18nKey,
  values = {},
  as: component = DEFAULT_TAG,
  ...props
}: I18nStringProps<T>): JSX.Element {

  const selfClosingTagSet = new Set([
    'area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'
  ]);

  const content = i18nKey ? translateItem({
    key: i18nKey,
    values
  }) : '';

  const properties = getTranslatedProperties(props);

  if (typeof component === 'string') {
    if (!selfClosingTagSet.has(component)) {
      properties.dangerouslySetInnerHTML = { __html: content };
    }
    return createElement(component, properties);
  }

  const childSpan = createElement('span', {dangerouslySetInnerHTML: { __html: content }});
  return createElement(component, properties, childSpan);

}
