/**
 * This file contains helper functions for working with date and time. As we convert files into Typescript, functions from dateHelpers.js will be moved here. The "_" prefix is to prevent name conflicts with the dateHelpers.js file.
 */
import I18n from '@common/libs/I18n';
import {
  format,
  intlFormat,
  isBefore,
  isDate as isDateFn,
  isSameDay as isSameDayFn,
  isThisYear as isThisYearFn,
  isToday as isTodayFn,
  isTomorrow as isTomorrowFn,
  isValid as isValidFn,
  isYesterday as isYesterdayFns,
  parseISO as parseISOFn,
  startOfToday
} from 'date-fns';

// Tied to a specific format: <Jan. 21, 4:30 PM> - May not fit all needs, review implementation as we go
export function localizedDateTime(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output: January 21, 2022
export function longMonthDateFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output January 21, 2022 at 10:00 AM
export function longMonthYearDateTimeFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output 10:00 AM
export function timeFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output Wednesday, January 21
export function longWeekdayMonthDateFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output Wednesday, January 21, 2022
export function longWeekdayMonthYearDateFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output 2022-06-20
export function shortDashDateFormat(isoDate: string): string {
  return format(parseISOFn(isoDate), 'yyyy-MM-dd');
}

// i.e ouput 03:45
export function formatSecondsToMinutes(seconds: number): string {
  return intlFormat(
    new Date(0, 0, 0, 0, 0, seconds),
    {
      minute: '2-digit',
      second: '2-digit'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

export function isToday(isoDate: string): boolean {
  return isTodayFn(parseISOFn(isoDate));
}

export function isYesterday(isoDate: string): boolean {
  return isYesterdayFns(parseISOFn(isoDate));
}

export function isBeforeToday(isoDate: string): boolean {
  const beforeDate = parseISOFn(isoDate);
  return isBefore(beforeDate, startOfToday());
}

export function isTomorrow(isoDate: string): boolean {
  return isTomorrowFn(parseISOFn(isoDate));
}

export function isCurrentYear(isoDate: string): boolean {
  return isThisYearFn(parseISOFn(isoDate));
}

export function isSameDay(isoDate1: string, isoDate2: string): boolean {
  return isSameDayFn(parseISOFn(isoDate1), parseISOFn(isoDate2));
}

// i.e output: Jan 28, 2022
export function shortMonthDateYearFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output: Jan, Apr, Jul
export function shortMonthDateFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      month: 'short',
      day: 'numeric'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

// i.e output: Mon, Tue, Wed
export function shortWeekdayFormat(isoDate: string): string {
  return intlFormat(
    parseISOFn(isoDate),
    {
      weekday: 'short'
    },
    {
      locale: I18n.getLocale()
    }
  );
}

export function isDate(value: unknown): value is Date {
  return isDateFn(value) && isValidFn(value);
}
