// Module
var code = `<div class="ax-grid ax-grid--no-gutter page-insights-overview-tile__title">
  <h3 class="ax-grid__col--auto-size ax-font--no-margin page-insights-overview-tile__title--label"><%= title %></h3>
  <p class="ax-font--no-margin ax-font--secondary page-insights-overview-tile__title--date"><%= date %></p>
</div>
<div class="ax-grid ax-grid--no--gutter">
  <div class="page-insights-overview-tile__stats qa-tile__stats-left">
    <p class="ax-font--no-margin page-insights-overview-tile__stats--value qa-tile__stats-value"><%= leftStatValue %></p>
    <div class="ax-grid ax-grid--no-gutter page-insights-overview-tile__stats--label">
      <span class="ax-grid__col--auto-size label-icon <%= leftIcon %>" aria-hidden="true"></span>
      <p class="ax-font--secondary ax-font--no-margin"><%= leftStatLabel %></p>
    </div>
  </div>
  <div class="page-insights-overview-tile--stats qa-tile__stats-right">
    <p class="ax-font--no-margin page-insights-overview-tile__stats--value qa-tile__stats-value"><%= rightStatValue %></p>
    <div class="ax-grid ax-grid--no-gutter page-insights-overview-tile__stats--label">
      <span class="ax-grid__col--auto-size label-icon <%= rightIcon %>" aria-hidden="true"></span>
      <p class="ax-font--secondary ax-font--no-margin"><%= rightStatLabel %></p>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;