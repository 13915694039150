import {
  removeHandlers,
  setHandlers,
  type AddPortalOptions
} from '@common/modules/react';
import {
  type ReactDOMModule,
  type ReactRootContainerModule,
  type ReactRootContainerProps
} from '@common/modules/react/bootstrap';
import type { EnhancedStore } from '@reduxjs/toolkit';
import { Module } from 'Marionette';
import { type Root } from 'react-dom/client';

type ReactModuleOptions<S extends EnhancedStore> = {
  store: S
  el: Element | DocumentFragment
  contextState?: ReactRootContainerProps<S>['contextState']
};

let ReactDOM: ReactDOMModule;
let ReactRootContainer: ReactRootContainerModule;

export default class ReactModule<S extends EnhancedStore = EnhancedStore> extends Module {
  private readonly el: ReactModuleOptions<S>['el'];

  private readonly contextState?: ReactRootContainerProps<S>['contextState'];

  private readonly portalComponents: ReactRootContainerProps<S>['portalData'] = new Map();

  private initReactPromise?: Promise<void>;

  private _root?: Root;

  constructor(moduleName: string, app: unknown, options: ReactModuleOptions<S>) {
    super(moduleName, app, options);

    this.el = options.el;
    this.contextState = options.contextState;
    this.store = options.store;
  }

  onStart(): void {
    setHandlers(
      this.onInitializeReactBundle.bind(this),
      this.onRenderPortal.bind(this),
      this.onRemovePortal.bind(this)
    );
  }

  onStop(): void {
    removeHandlers();

    this.deInitReact();
  }

  private async onInitializeReactBundle(): Promise<void> {
    return this.initReactPromise ?? (this.initReactPromise = this.initReact());
  }

  private async onRenderPortal(options: AddPortalOptions<object>): Promise<void> {
    const {
      id,
      component,
      props = {},
      el: container
    } = options;

    this.portalComponents.set(id, undefined);

    await this.onInitializeReactBundle();

    const Component = await Promise.resolve(component);

    if (!this.portalComponents.has(id)) {
      return;
    }

    this.portalComponents.set(id, ['default' in Component ? Component.default : Component, props, container]);

    this.renderRootContainer();
  }

  private onRemovePortal(id: string): void {
    this.portalComponents.delete(id);

    this.renderRootContainer();
  }

  private renderRootContainer(): void {
    this._root?.render(
      <ReactRootContainer<S>
        store={ this.store }
        portalData={ this.portalComponents }
        contextState={ this.contextState }
      />
    );
  }

  private async initReact(): Promise<void> {
    const ReactModuleImport = await import('@common/modules/react/bootstrap');

    ReactDOM = ReactModuleImport.ReactDOM;
    ReactRootContainer = ReactModuleImport.ReactRootContainer;
    this._root = ReactDOM.createRoot(this.el);
  }

  private deInitReact(): void {
    this._root?.unmount();
    delete this._root;
  }

}
