const _ = require('underscore');

class PageRequestData {

  constructor() {
    this.pageData = {};
  }

  setRequest(pageIndex, xhr) {
    this.abortRequest(pageIndex);
    this.pageData[pageIndex] = xhr;
  }

  getRequest(pageIndex) {
    return this.pageData[pageIndex];
  }

  abortRequest(pageIndex) {
    if (this.hasData(pageIndex)) {
      this.getRequest(pageIndex).abort();
    }
  }

  isEmpty() {
    return _.isEmpty(this.pageData);
  }

  hasData(pageIndex) {
    const data = this.getRequest(pageIndex);
    return (data != null) && (data.state() !== 'failed');
  }

  clearData() {
    Object.keys(this.pageData).forEach(this.abortRequest.bind(this));
    this.pageData = {};
  }
}

module.exports = PageRequestData;
