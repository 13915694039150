const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { CollectionView } = require('Marionette');
const MenuContextConfig = require('@common/modules/main/menu/MenuContextConfig');
const MsTeamsHeaderBarLayout = require('@common/modules/main/msTeams/MsTeamsHeaderBarLayout');
const HeaderContextConfig = require('@common/modules/main/header/HeaderContextConfig');
const HeaderSlideoutLayout = require('@common/modules/main/msTeams/headerSlideout/HeaderSlideoutLayout');
const HeaderSlideoutItem = require('@common/modules/main/msTeams/headerSlideout/HeaderSlideoutItem');
const { isMsTeamsIntegration } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const getMenuRegionDefinition = (contextModel) => {
  let itemCollection;
  if (isMsTeamsIntegration()) {
    itemCollection = MenuContextConfig.getItemsMsTeams(contextModel);
  } else { //This will be called by NavPosition.hamburger, but it's not MsTeams so don't hide any items
    itemCollection = MenuContextConfig.getItems(contextModel);
  }
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: HeaderSlideoutLayout
    },
    regionControllers: {
      slideoutMenu: {
        viewDefinition: {
          ViewClass: CollectionView,
          tagName: 'ul',
          childView: HeaderSlideoutItem,
          collection: itemCollection
        }
      }
    },
    delegateEvents: {
      'view:render': (controller, view) => {
        const selectedMenuItemStateModel = MenuContextConfig.getSelectedMenuItemState(contextModel);
        controller.listenTo(selectedMenuItemStateModel, `change:selectedId`, (state, selectedId) => {
          const selectedItem = itemCollection.get(selectedId);
          let titleText = '';
          if (selectedItem) {
            const selectedItemViewOptions = selectedItem.get('viewOptions');
            titleText = selectedItemViewOptions.msTeamsHideTitle ? '' : selectedItemViewOptions.text;
          }
          view.setTitleText(titleText);
        });
      }
    }
  };
};

module.exports = (contextModel) => {
  MenuContextConfig.toggleLockedToTop(contextModel, 'enable');

  return {
    ViewControllerClass: LayoutController,
    viewDefinition: {
      ViewClass: MsTeamsHeaderBarLayout
    },
    regionControllers: {
      leftHeaderRegion: HeaderContextConfig.getLeftControllerDefinition(contextModel),
      rightHeaderRegion: HeaderContextConfig.getRightControllerDefinition(contextModel),
      menuRegion: getMenuRegionDefinition(contextModel)
    },
    delegateEvents: {
      'view:render': (controller, view) => {
        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.LEFT_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('leftHeaderRegion', controllerDefinition, options.showOptions);
        });

        controller.listenTo(contextModel, `change:${ MenuContextConfig.Keys.ALWAYS_SHOW_BACK_BUTTON }`, (state, contextAlwaysShowBackButton) => {
          view.toggleLeftRegionAlwaysShow(contextAlwaysShowBackButton);
        });

        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.HEADER_DESKTOP_HIDEABLE }`, (state, toggle) => {
          view.toggleDesktopHideable(toggle);
        });

        controller.listenTo(contextModel, `change:${ HeaderContextConfig.Keys.RIGHT_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController(
            'rightHeaderRegion',
            { viewDefinition: controllerDefinition },
            options.showOptions
          );
        });
        const rightControllerDefinition = contextModel.get(HeaderContextConfig.Keys.RIGHT_CONTROLLER_DEFINITION);
        if (rightControllerDefinition && rightControllerDefinition.ViewClass) {
          controller.swapRegionController(
            'rightHeaderRegion',
            { viewDefinition: rightControllerDefinition }
          );
        }
        view.toggleLeftRegionAlwaysShow(MenuContextConfig.getAlwaysShowBackButton(contextModel));
        view.toggleDesktopHideable(HeaderContextConfig.getHeaderDesktopHideable(contextModel));
      }
    }
  };
};
