const _ = require('underscore');
const logging = require('logging');
const UploadHandlerXhr = require('./UploadHandlerXhr');
const UploadHandlerForm = require('./UploadHandlerForm');
const FileHelpers = require('@common/libs/helpers/app/FileHelpers');
const StringHelpers = require('@common/libs/helpers/types/StringHelpers');
const I18n = require('@common/libs/I18n');

//validate file, then send to server.
class FileUploader {

  constructor(options = {}) {
    ({
      params: this.params = {},
      multiple: this.multiple = false,
      onSubmit: this.onSubmit = () => {
        return {};
      },
      sizeLimit: this.sizeLimit = 0, // in MB
      allowedExtensions: this.allowedExtensions = ['*'],
      disallowedExtensions: this.disallowedExtensions = [],
      onError: this.onError = () => {
        return {};
      }
    } = options);
    const uploadOptions = {
      url: options.url,
      maxConnections: 3,
      fileType: options.fileType != null ? options.fileType : null, //Required if using old /files API endpoint
      fileInputName: options.fileInputName != null ? options.fileInputName : 'file',
      onUpload: options.onUpload != null ? options.onUpload : () => {
        return {};
      },
      onProgress: options.onProgress != null ? options.onProgress : () => {
        return {};
      },
      onComplete: options.onComplete != null ? options.onComplete : () => {
        return {};
      },
      onCancel: options.onCancel != null ? options.onCancel : () => {
        return {};
      },
      onError: this.onError
    };

    // Does the browser support XHR2 (ajax file upload) ?
    this.useXhr2 = UploadHandlerXhr.isSupported();

    // Create upload handler
    this.handler = this._createUploadHandler(uploadOptions);
  }

  _createUploadHandler(uploadOptions) {
    if (this.useXhr2) {
      return new UploadHandlerXhr(uploadOptions);
    }
    return new UploadHandlerForm(uploadOptions);

  }

  upload(input) {
    if (this.useXhr2) {
      return this._uploadFileList(input.files);
    }
    return this._uploadLegacyFileInput($(input));

  }

  cancelUpload(id) {
    return this.handler.cancel(id);
  }

  //param is a DOM FileList
  _uploadFileList(files) {
    let file, i;
    if (files.length === 0) {
      return;
    }

    for (i = 0; i < files.length; i++) {
      file = files[i];
      if (!this._validateFile(file.name, file.size)) {
        return;
      }
    }

    if (this.multiple) {
      return (() => {
        const result = [];
        for (i = 0; i < files.length; i++) {
          file = files[i];
          result.push(this._uploadFile(file));
        }
        return result;
      })();
    }
    return this._uploadFile(files[0]);

  }

  _uploadLegacyFileInput(file) {
    const name = file.val().replace(/.*(\/|\\)/, '');
    if (this._validateFile(name)) {
      return this._uploadFile(file);
    }
  }

  // Unfortunately, the param can be either a (DOM) File object or a file input ($input)
  _uploadFile(file) {
    const id = this.handler.add(file);
    const filename = this.handler.getName(id);
    if (this.onSubmit(id, filename) !== false) {
      return this.handler.upload(id, this.params);
    }
  }

  //inputs are a filename (string) and optionally a size if it's known
  _validateFile(name, size = -1) {
    if (!this._isAllowedExtension(name) || this._isDisallowedExtension(name)) {
      logging.debug(`File type is not allowed. ${ name }`);
      this._handleValidationError(name, I18n.t('fileUploader.fileTypeNotAllowed'));
      return false;
    } else if (size === 0) {
      logging.debug(`File is empty. ${ name }`);
      this._handleValidationError(name, I18n.t('fileUploader.fileEmpty'));
      return false;
    } else if (size > (this.sizeLimit * 1024 * 1024)) {
      logging.debug(`File is too large. ${ name }`);
      this._handleValidationError(name,
        I18n.t('fileUploader.fileTooBig', {maximumSize: this.sizeLimit}));
      return false;
    }

    return true;
  }

  _isDisallowedExtension(filename) {
    const blockedExtensionList = this.disallowedExtensions.concat(StringHelpers.blockedExtensionList);
    const extension = FileHelpers.getFileExtension(filename);

    if (this.allowedExtensions.includes(extension)) {
      return false;
    }

    return blockedExtensionList.includes(extension);
  }

  _handleValidationError(name, message) {
    this.onError(null, name, {errMessage: message});
  }

  _isAllowedExtension(filename) {
    let needle;
    if (_.contains(this.allowedExtensions, '*')) {
      return true;
    }
    return (needle = FileHelpers.getFileExtension(filename), this.allowedExtensions.includes(needle));
  }
}

module.exports = FileUploader;
