const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');
const CommunitiesManagementAccessLevelHelpers = require('@training/apps/search/communitiesManagement/CommunitiesManagementAccessLevelHelpers');

class GroupAccessTableView extends TableLayoutView {
  initialize(options = {}) {
    super.initialize(options);

    ({
      model: this.model,
      groupList: this.groupList
    } = options);

    this._setupTableData();
  }

  onRender() {
    // When the table renders, if there is an everyone access setting, we must apply the same selections to all groups
    const everyoneAccess = this.collection.get(-1).get('accessLevel');
    if (everyoneAccess != null) {
      this._toggleAllGroupStates(undefined, everyoneAccess);
    }
  }

  onAttach() {
    BrowserHelpers.triggerResize(true);
  }

  _setupTableData() {
    if (this.collection.length === 0) {
      this._setSelectedGroups(this.model.get('groupAccess'));
    }

    // Changes to the Everyone (ID: -1) group should change the values of all other groups in the table
    this.listenTo(this.collection.get(-1), 'change:accessLevel', this._toggleAllGroupStates);
  }

  _setSelectedGroups(selectedGroups) {
    // Map the groupAccess data from server to actual group models to populate the table
    const selected = this.groupList.filter((group) => {
      const selectedOption = _.findWhere(selectedGroups, { groupId: group.get('id') });
      if (selectedOption != null) {
        group.set('accessLevel', CommunitiesManagementAccessLevelHelpers.toInteger(selectedOption.accessLevel));
      }

      return selectedOption;
    });

    this.collection.set(selected, { remove: false });
    this._addEveryoneGroup();
  }

  _toggleAllGroupStates(groupModel, everyoneValue) {
    const values = _.compact(CommunitiesManagementAccessLevelHelpers.getAccessLevelValues());

    this.collection.each((group) => {
      // All groups except Everyone (ID: -1) should have the same checkboxes checked but also disabled/inactive
      if (group.get('id') !== -1) {
        const accessLevel = group.get('accessLevel');
        let inactive;
        let disabled;

        group.set('everyoneAccessLevel', everyoneValue);

        if (accessLevel != null) {
          if (accessLevel < everyoneValue) {
            // If the group has a permission level < the selected 'everyone' level, set the difference as disabled and
            // the group select as inactive
            const valuesClone = [...values];
            const accessLevelIndex = valuesClone.indexOf(accessLevel);
            inactive = valuesClone.splice(0, accessLevelIndex + 1);
            const everyoneLevelIndex = valuesClone.indexOf(everyoneValue);
            disabled = valuesClone.splice(0, everyoneLevelIndex + 1);
          } else if (accessLevel === everyoneValue) {
            // If the accessLevel is equal to the everyoneValue, set checkboxes to inactive
            const valuesClone = [...values];
            valuesClone.splice(valuesClone.indexOf(everyoneValue) + 1);
            inactive = valuesClone;
          }
        } else {
          // If the accessLevel is undefined, maintain its 'NONE' value and disable checkboxes that correspond with
          // 'everyone' permission level
          const valuesClone = [...values];
          valuesClone.splice(valuesClone.indexOf(everyoneValue) + 1);
          disabled = valuesClone;
        }

        // Note we don't do anything to the inactive or disabled values when group accessLevel > everyoneAccessLevel
        group.set(
          {
            checkboxState: {
              inactive,
              disabled
            }
          },
          { silent: true }
        );

        // Need to manually trigger change event to ensure the item view responds even when the inactive/disabled values
        // are the same
        group.trigger('change:checkboxState', group, group.get('checkboxState'));
      }
    });

    this.model.set('everyoneAccessLevel', everyoneValue);
  }

  _addEveryoneGroup() {
    // Special Everyone group represented ID of -1
    const everyoneGroup = {
      id: -1,
      name: I18n.t('communities.layout.content.access.everyone'),
      accessLevel: this.model.get('everyoneAccessLevel')
    };

    this.collection.unshift(everyoneGroup);
  }
}

module.exports = GroupAccessTableView;
