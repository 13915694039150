const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');
const { CONTAINER_CLASSES } = require('@common/libs/behaviors/carousel/Carousel');
const AxBreakpointEnum = require('@common/data/enums/AxBreakpointEnum').default;

const { getContinueTrainingCardControllerDefinition } = require('@training/apps/home/continueTraining/ContinueTrainingCardControllerDefinitionFactory');

const SLIDES_PER_VIEW_BREAKPOINTS_CONFIG = [
  {
    isDefault: true,
    slidesForSizes: [
      {
        minSlideCount: 1,
        slidesPerView: 3
      },
      {
        minSlideCount: 2,
        slidesPerView: 3
      },
      {
        minSlideCount: 3,
        slidesPerView: 3
      },
      {
        minSlideCount: 1,
        slidesPerView: 3.1
      }
    ]
  },
  {
    breakpoint: AxBreakpointEnum.TABLET_MAX,
    slidesForSizes: [
      {
        minSlideCount: 1,
        slidesPerView: 2
      },
      {
        minSlideCount: 2,
        slidesPerView: 2.1
      }
    ]
  },
  {
    breakpoint: AxBreakpointEnum.MOBILE_MAX,
    slidesForSizes: [
      {
        minSlideCount: 1,
        slidesPerView: 1
      },
      {
        minSlideCount: 2,
        slidesPerView: 1.1
      }
    ]
  }
];

module.exports = (
  {
    continueTrainingItems,
    session,
    assessmentCallback = () => {},
    startCurrentSessionTraining = () => {}
  } = {}
) => {
  if (continueTrainingItems.length === 0) {
    return null;
  }

  return () => {
    return {
      ViewControllerClass: CollectionLayoutViewController,
      collection: continueTrainingItems,
      viewDefinition: {
        tagName: 'ul',
        className: 'continue-training__list',
        behaviors: {
          Carousel: {
            keyboard: false,
            containerClass: `continue-training ${ CONTAINER_CLASSES.STANDARD_GAP }`,
            slidesPerViewCountConfig: SLIDES_PER_VIEW_BREAKPOINTS_CONFIG
          }
        },
        childViewOptions: {
          tagName: 'li',
          className: 'continue-training__item-region'
        }
      },
      ChildViewControllerDefinition: (model) => {
        return getContinueTrainingCardControllerDefinition({
          model,
          session,
          assessmentCallback,
          startCurrentSessionTraining
        });
      }
    };
  };
};
