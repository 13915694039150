const logging = require('logging');
const _ = require('underscore');

const FlowController = require('@training/apps/training/controllers/FlowController');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');
class NotificationsController extends FlowController {
  initialize(options = {}) {
    ({
      notificationsGroup: this.notificationsGroup,
      displayClass: this.displayClass,
      notificationsList: this.notificationsList
    } = options);
  }

  hasNotifications() {
    return this.notificationsList.length > 0;
  }

  showNotifications() {
    const notificationsDisplay = new this.displayClass({
      notifications: this.notificationsList,
      continue: () => {
        return this.notificationsList.clearEvents().done(() => {
          return this.finishedProcessing();
        });
      }
    });
    window.app.layout.setView(notificationsDisplay);
  }

  processSequenceFlow() {
    return this.getNotifications().then(() => {
      logging.debug(`Processing Notifications for ${ this.notificationsGroup }`);
      if (this._hasNotifications()) {
        this.showNotifications();
        return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
      }
      return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
    });
  }

  finishedProcessing() {
    logging.debug(`Finished processing Notifications for ${ this.notificationsGroup }`);

    if (this.parentProcessor && _.isFunction(this.parentProcessor.processSequenceFlow)) {
      this.parentProcessor.processSequenceFlow();
    }
  }

  _hasNotifications() {
    return this.notificationsList.some((notification) => {
      return notification.get('group') === this.notificationsGroup;
    });
  }
}
module.exports = NotificationsController;
