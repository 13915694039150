// Module
var code = `<div class="icon-angle_right row-arrow silver"></div>
<div class="needsreview-border-highlight"></div>
<div class="community-list-item-wrapper">
  <div class="community-logo-wrapper">
    <div class="community-logo"></div>
  </div>
  <div class="text">
    <div class="name"><%- t('discover.browse.needsReview') %></div>
    <div class="ax-grid ax-grid--no-gutter">
      <div class="reported ax-grid__col--auto-size">
        <%- t('discover.browse.reportedCount', { numReported: reportedCount }) %>
      </div>
      <div class="meta needs-review">
        <%- t('discover.browse.pending', { numPending: reviewCount }) %>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;