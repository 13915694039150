const _ = require('underscore');

// This lets you specify the dimensionMethod you want to get from a specific
// JQuery element or the Views default @$el.
const _getDimension = function(dimensionMethod, $element, options) {
  if (dimensionMethod == null) {
    dimensionMethod = '';
  }
  if ($element == null) {
    $element = this.$el;
  }
  if (options == null) {
    options = {};
  }
  const includeHidden = options.includeHidden != null ? options.includeHidden : false;

  // JQuery gives you the dimension of the element regardless of its
  // display state which isn't always what's desired so 'includeHidden'
  // allows for ignoring the visibility of the element.
  if (includeHidden || this.isVisible($element)) {
    if (['outerHeight', 'outerWidth'].includes(dimensionMethod)) {
      return (typeof $element[dimensionMethod] === 'function' ? $element[dimensionMethod](options.includeMargin != null ? options.includeMargin : false) : undefined);
    }
    return (typeof $element[dimensionMethod] === 'function' ? $element[dimensionMethod]() : undefined);

  }
  return 0;

};

/* Utility Methods for jQuery Dimensions */
// Valid list of jquery methods: height, innerHeight, outerHeight, width, innerWidth, outerWidth

module.exports = {
  getHeight($element, includeHidden) {
    return _getDimension.call(this, 'height', $element, {includeHidden});
  },

  getInnerHeight($element, includeHidden) {
    return _getDimension.call(this, 'innerHeight', $element, {includeHidden});
  },

  getOuterHeight($element, includeMargin, includeHidden) {
    return _getDimension.call(this, 'outerHeight', $element, {includeMargin, includeHidden});
  },

  getWidth($element, includeHidden) {
    return _getDimension.call(this, 'width', $element, {includeHidden});
  },

  getInnerWidth($element, includeHidden) {
    return _getDimension.call(this, 'innerWidth', $element, {includeHidden});
  },

  getOuterWidth($element, includeMargin, includeHidden) {
    return _getDimension.call(this, 'outerWidth', $element, {includeMargin, includeHidden});
  },

  isVisible($element) {
    if ($element == null) {
      $element = this.$el;
    }
    return $element.is(':visible');
  },

  // To get a more accurate outerWidth/outerHeight of an element
  // (ie. to account for floating point dimensions) we need to use
  // getBoundingClientRect along with a feature detection since
  // < IE9 doesn't return the width/height.
  getBoundingClientRect(element) {
    if (element == null) {
      element = this.$el;
    }
    const rect = element[0].getBoundingClientRect();

    if (rect.width != null) {
      return rect;
    }
    return _.extend({}, rect, {
      width: rect.right - rect.left,
      height: rect.bottom - rect.top
    });

  }
};
