const _ = require('underscore');
const { Collection } = require('Backbone');
const { CollectionView } = require('Marionette');
const GuidedLearningPathLinkView = require('./GuidedLearningPathLinkView');
const SelfDirectedPathLinkView = require('./SelfDirectedPathLinkView');

const getTopicLearningPathsControllerDefinition = (topicData = {}) => {

  if (_.isEmpty(topicData)) {
    return {};
  }

  let controllerDefinition = _getControllerDefinition(topicData.get('selfDirectedPrograms'), SelfDirectedPathLinkView);

  if (!_.isEmpty(topicData.get('guidedLearningPrograms'))) {
    controllerDefinition = _getControllerDefinition(topicData.get('guidedLearningPrograms'), GuidedLearningPathLinkView);
  }

  return controllerDefinition;
};

const _getControllerDefinition = (programData, childView) => {
  return {
    viewDefinition: {
      ViewClass: CollectionView,
      childView,
      collection: new Collection(programData),
      tagName: 'ul',
      className: 'learning-paths-list'
    }
  };
};

module.exports = {
  getTopicLearningPathsControllerDefinition
};
