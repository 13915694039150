const _ = require('underscore');
const { Model } = require('Backbone');

const TranscriptionMethods = require('@common/libs/file/TranscriptionMethods').default;
const TranscriptionStatus = require('@common/libs/file/TranscriptionStatus');

class TranscriptionModel extends Model {

  static FIELDS = {
    LANGUAGE: 'language',
    CONTENT: 'content',
    METHOD: 'method',
    STATUS: 'status'
  }

  static VTT_START_TEXT = 'WEBVTT';

  get validators() {
    return {
      language: 'required',
      method: 'required'
    };
  }

  initialize(attributes, options = {}) {
    const {videoMediaUUID} = options;

    this.urlRoot = videoMediaUUID != null ? `/axonify/media/video/${ videoMediaUUID }/cc` : undefined;
  }

  get className() {
    return 'Transcription';
  }

  defaults() {
    return {
      [TranscriptionModel.FIELDS.CONTENT]: '',
      [TranscriptionModel.FIELDS.METHOD]: TranscriptionMethods.MANUAL
    };
  }

  url() {
    const base = _.result(this, 'urlRoot') || _.result(this.collection, 'url');

    if (this.isNew()) {
      return base;
    }

    const id = this.get(TranscriptionModel.FIELDS.LANGUAGE);

    return base.replace(/[^/]$/, '$&/') + encodeURIComponent(id);
  }

  parse(response = {}) {
    return response.closedCaption || response;
  }

  toGrid() {
    return this.toJSON();
  }

  save(key, value, options) {
    const saveData = {};
    const saveOptions = {
      sender: this,
      skipGlobalSuccessMessageHandler: true
    };

    if (key == null || typeof key === 'object') {
      Object.assign(saveData, key);
      Object.assign(saveOptions, value);
    } else {
      Object.assign(saveData, { [key]: value });
      Object.assign(saveOptions, options);
    }

    return super.save(saveData, saveOptions);
  }

  canEdit() {
    const isMechOrProf = this.isMechanical() || this.isProfessional();
    return !isMechOrProf || isMechOrProf && this.isDone();
  }

  isFailed() {
    return this.get(TranscriptionModel.FIELDS.STATUS) === TranscriptionStatus.FAILED;
  }

  isDone() {
    return this.get(TranscriptionModel.FIELDS.STATUS) === TranscriptionStatus.DONE;
  }

  isMechanical() {
    return this.get(TranscriptionModel.FIELDS.METHOD) === TranscriptionMethods.MECHANICAL;
  }

  isProfessional() {
    return this.get(TranscriptionModel.FIELDS.METHOD) === TranscriptionMethods.PROFESSIONAL;
  }

  hasContent() {
    const content = this.get(TranscriptionModel.FIELDS.CONTENT);
    return content.startsWith(TranscriptionModel.VTT_START_TEXT)
      && content.length > TranscriptionModel.VTT_START_TEXT.length;
  }

  isMechanicalProcessingInProgress() {
    return this.isMechanical() && !this.isDone() && !this.isFailed();
  }

  isProfessionalProcessingInProgress() {
    return this.isProfessional() && !this.isDone();
  }
}

module.exports = TranscriptionModel;
