const logging = require('logging');
const _ = require('underscore');
const Backbone = require('Backbone');
const RegistrationTicket = require('@training/apps/auth/models/RegistrationTicket');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const UIKit = require('@training/widgets/UIKit');
const Language = require('@training/apps/auth/models/Language');
const RegistrationOption = require('@training/apps/auth/models/RegistrationOption');
const SelfRegistrationCode = require('@training/apps/auth/models/SelfRegistrationCode');

const RecoveryQuestion = require('@training/apps/auth/models/RecoveryQuestion');
const PasswordComplexityParser = require('@common/modules/auth/common/PasswordComplexityParser');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const PublicKey = require('@common/data/models/PublicKey');
const { NullValueSerializer } = require('@common/components/forms/editors/password/PasswordSerializer');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const PasswordRulesetView = require('@common/components/passwordRuleset/PasswordRulesetView');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const CaptchaConfig = require('@common/data/models/config/CaptchaConfig');

require('@common/components/forms/editors/password/Form.Editor.Password');
require('@training/widgets/Form.Editor.Location');
require('@training/widgets/Form.Editor.Captcha');

class SelfRegistrationPage extends UIKit.View {
  NUMBER_OF_RECOVERY_QUESTIONS = 3; // Number of recovery questions the user must pick

  RECOVERY_CHOICES_PER_OPTION = 5; // Number of choices the user will get for each recovery question

  template = _.tpl(require('@training/apps/auth/templates/SelfRegistrationPage.html'));

  recoveryTemplate = _.tpl(require('@training/apps/auth/templates/_recovery_question.html'));

  regions() {
    return {
      passwordRulesetRegion: '#js-password-ruleset'
    };
  }

  events() {
    return {
      'click #gotoLoginLink': 'onClickAlreadyRegisteredLink'
    };
  }

  constructor(options) {
    super(options);
    this.complexPassword = TenantPropertyProvider.get().getProperty('complex');
    const complexityParser = new PasswordComplexityParser();
    this.complexityRules = complexityParser.parse(TenantPropertyProvider.get().getProperty('complexFormat'));
    this.register = this.register.bind(this);
    const queryParams = UrlHelpers.getQueryParams(window.location.hash);
    this.token = queryParams.token;

    this.publicKey = new PublicKey();
    this.recaptchaConfig = new CaptchaConfig();
    this.registrationCode = new SelfRegistrationCode({token: this.token});

    this.model = new RegistrationTicket({
      registrationCode: this.token
    }, {
      enforceComplexPassword: TenantPropertyProvider.get().getProperty('complex'),
      publicKey: this.publicKey
    });

    this.recoveryModels = new Backbone.Collection();

    for (let i = 0; i < this.NUMBER_OF_RECOVERY_QUESTIONS; i++) {
      this.recoveryModels.add(new Backbone.Model({
        recoveryQuestion: '',
        recoveryAnswer: ''
      }));
    }
  }

  render() {
    if (this.form) {
      this.form.destroy();
    }

    this.$el.html(this.template());

    const passwordRulesetView = new PasswordRulesetView({
      complexPassword: this.complexPassword,
      complexityRules: this.complexityRules
    });

    this.passwordRulesetRegion.show(passwordRulesetView);

    this.$recoveryContainer = this.$('#recovery-container');

    return this;
  }

  viewDidAppear() {
    if (this.token) {
      const publicKeyPromise = this.publicKey.fetch();
      const registrationCodePromise = this.registrationCode.fetch({skipGlobalHandler: true});
      const captchaPromise = this.recaptchaConfig.fetch();

      // captchaOptions something here
      return $.when.apply(this, [publicKeyPromise, registrationCodePromise, captchaPromise]).done(() => {
        this._populateFields();
        this._populateReadOnlyFields();
        this._setActionBar();
        this.setupEmail();
        this._reloadLocale(this.model.get('language').id);
      })
        .fail(() => {
          this._backToLogin();
          window.app.layout.flash.error(I18n.t('selfRegistration.errors.publicKey'));
        });
    } // No token provided, then send to login page
    this._backToLogin();

    return undefined;
  }

  _setActionBar() {
    this.actionBar.setActionBar({
      buttons: {
        type: 'customText',
        text: I18n.t('selfRegistration.buttonTitle'),
        onClick: this.register
      }
    });
  }

  _backToLogin() {
    window.app.layout.flash.error(I18n.t('selfRegistration.errors.invalidCode'));
    window.apps.auth.removeQueryParamsAndRedirect('#login');
  }

  // This callback is fired when the view is done loading completely; i.e everything is finishing loading and ready to bind
  _populateFields() {
    const context = {
      languages: this.registrationCode.get('language'),
      jobTitles: this.registrationCode.get('jobTitle'),
      departments: this.registrationCode.get('department'),
      locations: this.registrationCode.get('location'),
      lineOfBusinesses: this.registrationCode.get('lineOfBusiness')
    };

    // We need to do this because `languages.value` may still exist, yet options may not
    if (context.languages != null) {
      context.languages.options = TenantPropertyProvider.get().getProperty('languages');
    }

    this._hideHiddenFields(context);

    const formContext = {
      passwordOptions: {
        serializer: new NullValueSerializer(),
        autocomplete: 'new-password'
      }
    };

    const modelListToSort = ['jobTitles', 'departments', 'lineOfBusinesses'];
    const comparator = (model) => {
      return model.get('name').toLowerCase();
    };

    for (const key in context) {
      if (context[key] != null) {
        const value = context[key];
        const models = _.map(value.options, (entity) => {
          if (key === 'languages') {
            return new Language(entity);
          }

          return new RegistrationOption(entity);
        });

        const options = {};

        if (modelListToSort.includes(key)) {
          options.comparator = comparator;
        }

        formContext[key] = new Backbone.Collection(models, options);
      }
    }

    formContext['captchaOptions'] = {
      secretKey: this.recaptchaConfig.getSiteKey()
    };

    this.form = new UIKit.Form({
      el: this.$('form'),
      model: this.model,
      context: formContext
    });

    this.listenTo(this.form, 'change:language', (form, editor) => {
      this._reloadLocale(editor.getValue().id);
    });

    this._setupRecoveryQuestions();
    this._convertSelectToInputBoxForFieldsThatAreReadOnly();
  }

  _reloadLocale(locale) {
    // Save the data before re-rendering the form and losing it all
    this._commitRecoveryForms();
    this.model.set('language', locale);

    I18n.setLocale(locale, {
      always: () => {
        this.render();
        this._populateFields();
      }
    });
  }

  _convertSelectToInputBoxForFieldsThatAreReadOnly() {
    // Disable anything that has a value so that it's 'read only' and cannot be modified by the end user
    for (const key in this.registrationCode.attributes) {
      if (this.registrationCode.attributes[key] != null) {
        const val = this.registrationCode.attributes[key];
        if (['language', 'jobTitle', 'lineOfBusiness', 'location', 'department'].includes(key)) {
          if ((val.options == null)) {
            const $select = this.form.fields[key].editor.$el.parent();
            $select.find('select, #location').remove();

            const value = val.value.name ? HTMLHelpers.stripHtmlForDisplay(val.value.name) : I18n.languageNameNative(val.id);
            $select.append(`<input class="readonly" disabled aria-disabled="true" value="${ value }"></input>`);
            $select.find('input').prop('disabled', true);
          } else {
            this.model.markRequired(key);
          }
        }
      }
    }
  }

  _setupRecoveryQuestions() {
    let i;
    const questionOptions = new Backbone.Collection();
    for (i = 1; i <= 15; i++) {
      questionOptions.add(new RecoveryQuestion({
        id: i,
        value: I18n.t(`securityQuestions.q${ i }`)
      }));
    }
    this.recoveryForms = [];

    for (i = 0; i < this.NUMBER_OF_RECOVERY_QUESTIONS; i++) {
      this.recoveryModels.at(i).set('recoveryAnswer', '');
      const form = new UIKit.Form({
        html: this.recoveryTemplate({id: i + 1}),
        model: this.recoveryModels.at(i),
        context: {
          recoveryOptions: new Backbone.Collection(questionOptions.slice(this.RECOVERY_CHOICES_PER_OPTION * i, (this.RECOVERY_CHOICES_PER_OPTION * i) + this.RECOVERY_CHOICES_PER_OPTION))
        }
      });

      this.recoveryForms.push(form);
      this.$recoveryContainer.append(form.el);
    }
  }

  _hideHiddenFields(context) {
    for (const key in context) {
      if (context[key] != null) {
        let nominalKey;
        if (key === 'lineOfBusinesses') {
          nominalKey = 'lineOfBusiness';
        } else {
          nominalKey = key.slice(0, -1); // Slice off the 's'...
        }

        if (!context[key].value) {
          this.model.markRequired(nominalKey);
        }
      } else {
        this.$(`#container-${ key }`).remove();
      }
    }
  }

  _populateReadOnlyFields() {
    const jobTitle = this.registrationCode.get('jobTitle');
    const department = this.registrationCode.get('department');
    const lineOfBusiness = this.registrationCode.get('lineOfBusiness');
    const location = this.registrationCode.get('location');
    const language = this.registrationCode.get('language');

    if (jobTitle) {
      this.model.set('jobTitle', jobTitle.value);
    }

    if (department) {
      this.model.set('department', department.value);
    }

    if (lineOfBusiness) {
      this.model.set('lineOfBusiness', lineOfBusiness.value);
    }

    if (location) {
      this.model.set('location', location.value);
    }

    if (language && language.value != null) {
      this.model.set('language', {
        id: language.value
      });
    } else {
      // Let's just whatever locale is set currently
      this.model.set('language', {
        id: this._getDefaultLanguage()
      });
    }
  }

  _getDefaultLanguage() {
    const i18nLanguage = I18n.getLocale();
    const tenantLanguages = TenantPropertyProvider.get().getProperty('languages');
    const tenantDefaultLanguage = TenantPropertyProvider.get().getProperty('defaultLanguage');

    return tenantLanguages.includes(i18nLanguage) ? i18nLanguage : tenantDefaultLanguage;
  }

  register() {
    this._commitRecoveryForms();
    this._addErrorBorder();

    this.model.set({
      recoveryQuestions: this.recoveryModels.toJSON()
    });

    if (this.isFormValid()) {
      this.model.secureJSON().then((secureJson) => {
        return this.model.save(secureJson, {
          success() {
            logging.info('Successfully registered the account');
            window.apps.auth.removeQueryParamsAndRedirect('#selfreg-complete');
          },
          error: (model, response) => {
            logging.error('Failed to register account');

            const exception = AxonifyExceptionFactory.fromResponse(response);

            logging.error('Failed to register account');

            // captchas are no longer valid once they have been consumed by the server, so we have to reset them
            this.form.fields.recaptcha.editor.reset();

            if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_DUPLICATE_EMPLOYEE_ID) {
              return window.app.layout.flash.error(I18n.t('selfRegistration.errors.usernameTaken'));
            } else if (exception.getErrorCode() === AxonifyExceptionCode.FAILED_RECAPTCHA_VALIDATION) {
              return window.app.layout.flash.error(I18n.t('selfRegistration.errors.captchaInvalid'));
            } else if (exception.getErrorCode() === AxonifyExceptionCode.SECURITY_QUESTION_ANSWER_TOO_SHORT) {
              return window.app.layout.flash.error(I18n.t('users.securityAnswer.error.tooShort', {
                minLength: exception.response.minimumLength,
                actualLength: exception.response.actualLength
              }));
            } else if (exception.getErrorCode() === AxonifyExceptionCode.SECURITY_QUESTION_DUPLICATE_ANSWER) {
              return window.app.layout.flash.error(I18n.t('users.securityAnswer.error.duplicate'));
            }
            window.app.layout.flash.error(I18n.t('selfRegistration.errors.failedToRegister'));

            return undefined;
          },
          validate: false, // We already validated just before this with custom logic; don't bother again
          skipGlobalHandler: true
        });
      });
    }
  }

  setupEmail() {
    // This marked the field as optional as required when scanning through fields
    if (!this.registrationCode.isEmailOptional()) {
      this.model.markRequired('email');
    }
  }


  // Attempts to commit the form and returns whether or not is is valid
  isFormValid() {
    if (apps.base.checkDoubleSubmit()) {
      return false;
    }
    if (!this._commitForm()) {
      return false;
    }
    return true;
  }

  _addErrorBorder() {
    ['jobTitle', 'lineOfBusiness', 'department'].forEach(function(item) {
      const itemSelect = this.$(`#${ item }`);
      if (itemSelect.val() === '-1') {
        itemSelect.addClass('rederror').attr('aria-invalid', 'true');
      }
    }, this);

    for (let i = 0; i < this.NUMBER_OF_RECOVERY_QUESTIONS; i++) {
      const questionSelect = this.$(`#securityquestion${ i + 1 }`);

      if (questionSelect.val() === '-1') {
        questionSelect.addClass('rederror').attr('aria-invalid', 'true');
      }

      const answerEditor = this.$(`#securityanswer${ i + 1 }`);
      const answer = answerEditor.val().trim();

      if (answer.length === 0) {
        answerEditor.addClass('rederror').attr('aria-invalid', 'true');
      }
    }
  }

  _commitRecoveryForms() {
    this.recoveryForms.map(
      (form) => {
        return form.commit();
      }
    );
  }

  _commitForm() {
    let errors = [];
    const formError = this.form.commit();
    if (formError) {
      errors = errors.concat(formError);
    }
    // Attempt to do advanced validation on the model
    errors = errors.concat(this.model.validate());
    if (errors.length > 0) {
      window.app.layout.flash.error(errors);
      return false;
    }
    return true;
  }

  onClickAlreadyRegisteredLink() {
    window.apps.auth.removeQueryParamsAndRedirect('#login');
  }

  onClose() {
    this.actionBar.setActionBar();
  }
}

module.exports = SelfRegistrationPage;
