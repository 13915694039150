// Module
var code = `<h2 class="progress-text"><%- t('GuidedLearning.details.progress')%></h2>
<% if (shouldUsePercentage) { %>
  <p class="progress-value"><%- percent %>%</p>
<% } else { %>
  <p class="progress-value"><%- t('assessments.select.progress.score', {complete: completed, total: available}) %></p>
<% } %>
<div class="progress-container" tabindex="-1" role="progressbar"
  aria-valuenow="<%- completed %>" aria-valuemax="<%- available %>" aria-valuemin="0"
  aria-label="<%- t('GuidedLearning.details.checkpointCount', {completed: completed, available: available}) %>">
</div>
`;
// Exports
module.exports = code;