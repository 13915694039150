// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
			<span class="page-header__icon icon-events"></span>
      <span class="page-header__title"><%- t('hub.learningEvents') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="learningevent-list">
      <div class="learningevent-listcontainer">
        <h3>
          <%- t('learningEvents.filtered.selectSessionEnroll') %>
        </h3>
        <div class="event-list-container filtered-event__region"></div>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;