const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const LoadingWrapperView = require('@common/components/loading/wrapper/LoadingWrapperView');

const DataLoadingSpinnerContextConfig = require('@common/components/dataLoadingSpinner/DataLoadingSpinnerContextConfig');

class DataLoadingSpinnerController extends LayoutController {

  constructor(options = {}) {
    super(options);

    this.onAjaxSend = this.onAjaxSend.bind(this);
    this.onAjaxComplete = this.onAjaxComplete.bind(this);
    this.onToggleEnabled = this.onToggleEnabled.bind(this);
    this.onShowSpinner = this.onShowSpinner.bind(this);

    ({
      contextModel: this.contextModel
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: LoadingWrapperView,
      blanketOpacity: 0
    };
  }

  onViewAttach() {
    this.listenTo(this.contextModel, `change:${ DataLoadingSpinnerContextConfig.Keys.DATA_LOADING_SPINNER_ENABLED }`, this.onToggleEnabled);

    this.onToggleEnabled(this.contextModel, DataLoadingSpinnerContextConfig.isEnabled(this.contextModel));
  }

  onToggleEnabled(model, enabled) {
    const show = DataLoadingSpinnerContextConfig.isShowing(model);

    this._listenForAjaxEvents(enabled);
    this._toggleViewSpinner(enabled && show, false);
  }

  _listenForAjaxEvents(enabled) {
    if (enabled) {
      this.listenTo(this.contextModel, `change:${ DataLoadingSpinnerContextConfig.Keys.DATA_LOADING_SPINNER_SHOW }`, this.onShowSpinner);

      $(document).on('ajaxSend.pagespinner', this.onAjaxSend);
      $(document).on('ajaxComplete.pagespinner', this.onAjaxComplete);
    } else {
      this.stopListening(this.contextModel, `change:${ DataLoadingSpinnerContextConfig.Keys.DATA_LOADING_SPINNER_SHOW }`, this.onShowSpinner);

      $(document).off('ajaxSend.pagespinner', this.onAjaxSend);
      $(document).off('ajaxComplete.pagespinner', this.onAjaxComplete);
    }
  }

  onAjaxSend(event, jqXHR, { showSpinner = true } = {}) {
    if (showSpinner) {
      DataLoadingSpinnerContextConfig.showSpinner(this.contextModel);
    }
  }

  onAjaxComplete(event, jqXHR, { showSpinner = true } = {}) {
    if (showSpinner) {
      DataLoadingSpinnerContextConfig.hideSpinner(this.contextModel);
    }
  }

  onShowSpinner(model, show) {
    this._toggleViewSpinner(show);
  }

  _toggleViewSpinner(show, fade = true) {
    const view = this.getView();
    const spinnerOptions = { fade };

    if (show) {
      view.show(spinnerOptions);
    } else {
      view.hide(spinnerOptions);
    }
  }
}

module.exports = DataLoadingSpinnerController;
