const {
  LayoutView,
  CollectionView
} = require('Marionette');
const { isFunction } = require('underscore');

const PageableModel = require('@common/components/pageableList/PageableModel');
const PaginationControlsItemView = require('@common/components/pageableList/PaginationControlsItemView');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const PagingLayoutModeEnum = require('@common/data/enums/PagingLayoutModeEnum');
const CollectionLayoutViewController = require('@common/libs/UI/controllers/CollectionLayoutViewController');

require('@common/components/pageableList/Pageable.less');

class PageableListViewController extends LayoutController {

  constructor(options = {}) {
    super(options);
    ({
      collection: this.collection,
      itemViewClass: this.itemViewClass,
      itemViewOptions: this.itemViewOptions,
      emptyViewClass: this.emptyViewClass,
      emptyViewOptions: this.emptyViewOptions,
      childViewControllerDefinitionFn: this.childViewControllerDefinitionFn,
      collectionView: this.collectionView,
      listRegionTagName: this.listRegionTagName = 'ul',
      listRegionViewClass: this.listRegionViewClass = 'search__results',
      itemView: this.itemView,
      hideSinglePage: this.hideSinglePage,
      pagingControlLocation: this.pagingControlLocation = PagingLayoutModeEnum.BOTTOM
    } = options);

    this.hideSinglePage = Boolean(this.hideSinglePage);

    if (this.collection == null || (this.itemViewClass == null && !isFunction(this.childViewControllerDefinitionFn))) {
      throw new Error(`You must at least provide PageableListViewController with a valid Marionette.ItemView or
        ChildControllerDefinition function, alongside a PageableCollection as options.`);
    }

    this.pageableModel = new PageableModel({
      pageableList: this.collection
    });
    this.viewDefinition = this.viewDefinition.bind(this);
  }

  _getTemplate() {
    return `
      <div class="page-control-top-region"></div>
      <div class="list-region"></div>
      <div class="page-control-bottom-region"></div>
    `;
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      template: this._getTemplate(),
      regions: {
        pageControlTopRegion: '.page-control-top-region',
        listRegion: '.list-region',
        pageControlBottomRegion: '.page-control-bottom-region'
      }
    };
  }

  regionControllers() {
    const regionControllers = {
      listRegion: this._getListRegionController()
    };

    if (this.pagingControlLocation === PagingLayoutModeEnum.TOP || this.pagingControlLocation === PagingLayoutModeEnum.TOP_AND_BOTTOM) {
      regionControllers.pageControlTopRegion = this._getPaginationControlDefinition();
    }
    if (this.pagingControlLocation === PagingLayoutModeEnum.BOTTOM || this.pagingControlLocation === PagingLayoutModeEnum.TOP_AND_BOTTOM) {
      regionControllers.pageControlBottomRegion = this._getPaginationControlDefinition();
    }

    return regionControllers;
  }

  _getListRegionController() {
    if (this.itemViewClass != null) {
      return this._getListRegionViewDefinition();
    } else if (this.childViewControllerDefinitionFn != null && isFunction(this.childViewControllerDefinitionFn)) {
      return this._getListRegionViewDefinitionWithController();
    }

    return {};
  }

  _getListRegionViewDefinition() {
    return {
      viewDefinition: {
        ViewClass: CollectionView,
        itemView: this.itemViewClass,
        collection: this.collection,
        className: this.listRegionViewClass,
        tagName: this.listRegionTagName,
        childView: this.itemViewClass,
        childViewOptions: this.itemViewOptions,
        emptyView: this.emptyViewClass,
        emptyViewOptions: this.emptyViewOptions
      }
    };
  }

  _getListRegionViewDefinitionWithController() {
    return {
      ViewControllerClass: CollectionLayoutViewController,
      collection: this.collection,
      viewDefinition: {
        className: this.listRegionViewClass,
        tagName: this.listRegionTagName,
        // NOTE: This would be more extensible if it were replaced with:
        //   childViewOptions: $.extend(true, {tagName: 'li'}, (this.childViewOptions || itemViewOptions || {}))
        // Second NOTE: if you go with the above option, you would need to parse the options for this.childViewOptions
        childViewOptions: {
          tagName: 'li'
        },
        emptyView: this.emptyViewClass,
        emptyViewOptions: this.emptyViewOptions
      },
      ChildViewControllerDefinition: (model) => {
        return this.childViewControllerDefinitionFn(model, this.itemViewOptions);
      }
    };
  }

  _getPaginationControlDefinition() {
    return {
      viewDefinition: {
        ViewClass: PaginationControlsItemView,
        model: this.pageableModel,
        hideSinglePage: this.hideSinglePage
      },
      delegateEvents: {
        'view:pagination:clicked': () => {
          this.glChannel.vent.trigger('pagination:clicked');
        }
      }
    };
  }
}

module.exports = PageableListViewController;
