const Backbone = require('Backbone');
const _ = require('underscore');

const AchievementTypeEnum = require('@common/data/enums/AchievementTypeEnum').default;
const achievementTypeList = _.without(AchievementTypeEnum.values(), AchievementTypeEnum.MILESTONE_ACHIEVEMENT);

class AchievementCollection extends Backbone.Collection {

  apiEndpoint() {
    return '/achievements/report';
  }

  fetch(options = {}) {
    const fetchOptions = _.clone(options);
    fetchOptions.data = Object.assign({}, fetchOptions.data, {
      query: JSON.stringify({
        achievementTypes: achievementTypeList,
        p: 0,
        rpp: 999999
      })
    });
    return super.fetch(fetchOptions);
  }

  parse(res = {}) {
    return _.result(res, 'achievements.achievements') || [];
  }
}

module.exports = AchievementCollection;
