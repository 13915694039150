const _ = require('underscore');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const GuidedLearningCategory = require('@common/components/guidedLearning/models/GuidedLearningCategory');
const GuidedLearningObjectiveList = require('@common/components/guidedLearning/collections/GuidedLearningObjectiveList');

const NextStartableAssessmentProvider = require('@training/apps/training/controllers/assessments/providers/NextStartableAssessmentProvider');
const GuidedLearningOption = require('@common/data/models/assessments/GuidedLearningOption');

class GuidedLearningNextStartableAssessmentProvider extends NextStartableAssessmentProvider {
  constructor(guidedLearningCategory, ...args) {
    super(guidedLearningCategory, ...args);

    this.guidedLearningCategory = guidedLearningCategory;
    if (!(guidedLearningCategory instanceof GuidedLearningCategory)) {
      throw new Error('You must provide a legal guided learning category in order to use this provider');
    }
  }

  canProduceNext() {
    return true;
  }

  getNext() {
    const findItemsFromCategory = () => {
      const availableOptions = new GuidedLearningObjectiveList(
        _.flatten(this.guidedLearningCategory.getAvailableObjectives())
        , {parse: true}
      );

      const nextStartableItem = availableOptions.getNextStartableItem();

      if (!nextStartableItem) {
        return Promise.resolve(null);
      }

      const topicOption = this.getOptionInstance(nextStartableItem, this.guidedLearningCategory.id);

      return Promise.resolve(topicOption);
    };

    return Promise.resolve(
      // In the case of DeepLink if we don't have programId as a query param it will be undefined and causes issues here...
      // See GuidedLearningController::getNextItemProvider
      this.guidedLearningCategory.fetch()
        .fail((xhr) => {
          const exception = AxonifyExceptionFactory.fromResponse(xhr);
          const errCode = exception.getErrorCode();

          // "No Such Entity" exception can happen if the program is retired mid-assessment
          if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
            xhr.skipGlobalHandler = true;
          }
        })
    ).then(findItemsFromCategory);
  }

  getOptionInstance(nextStartableItem, categoryId) {
    return new GuidedLearningOption(nextStartableItem, categoryId);
  }
}

module.exports = GuidedLearningNextStartableAssessmentProvider;
