const $os = require('detectOS');

const SUPPORTED_BROWSERS_DUET_DATEPICKER = {
  chrome: 61,
  safari: 10,
  firefox: 63,
  edge: 17,
  opera: 63,
  samsung: 8.2,
  ie: 12
};

const isDuetSupported = () => {
  return $os.msTeamsMobileApp
        || $os.isInMobileApp()
        || $os.browser in SUPPORTED_BROWSERS_DUET_DATEPICKER
        && $os.version >= SUPPORTED_BROWSERS_DUET_DATEPICKER[$os.browser];
}

if (isDuetSupported()) {
  const {
    applyPolyfills,
    defineCustomElements
  } = require('@duetds/date-picker/dist/loader');

  applyPolyfills().then(() => {
    defineCustomElements(window);
  });
}

module.exports = {
  isDuetSupported
};
