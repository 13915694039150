import { type PropsWithChildren } from 'react';

import {
  AxBox,
  AxDialogDefaultTitle,
  AxStack
} from '@common/modules/react/themes/components';

export type AxDialogConfirmationTitleProps = PropsWithChildren<{
  qaClassName: `qa-${ string }`
  icon?: JSX.Element
}>;

export default function AxDialogConfirmationTitle(props: AxDialogConfirmationTitleProps) {
  const {
    icon,
    qaClassName,
    children
  } = props;

  const hasIcon = icon != null;
  const iconSpan = hasIcon && <AxBox
    className={ `${ qaClassName }-icon` }
    sx={{
      marginBottom: 1,
      '.MuiIcon-root': {
        fontSize: '5rem'
      }
    }}
  >
    { icon }
  </AxBox>;

  return <AxStack justifyContent='center' alignItems='center'>
    { iconSpan }
    <AxDialogDefaultTitle
      qaClassName={ qaClassName }
      sx={{
        textAlign: 'center'
      }}
    >{ children }</AxDialogDefaultTitle>
  </AxStack>;
}
