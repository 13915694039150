const $os = require('detectOS');
const _ = require('underscore');
const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const CommunityUserCounts = require('@common/data/models/CommunityUserCounts');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

const ON = true;

class FilterControlView extends LayoutView {

  getTemplate() {
    return `
    <div class="js-community-icon community-icon ax-grid__col--auto-size icon-groups hidden" aria-hidden="true"></div>
    <input class="js-filter-input filter-input ax-grid__col--grow-2" type="text" aria-labelledby="share-to-header" placeholder="<%- placeholder %>" value="" />
    <div class="js-user-count user-count ax-grid__col--auto-size hidden" aria-hidden="true"></div>
    <div class="js-group-icon icon-groups ax-grid__col--auto-size hidden"></div>
    <button class="js-trigger-dropdown trigger-dropdown icon-caret_down ax-grid__col--auto-size" aria-expanded="false" aria-label="<%- t('components.communityPicker.aria.showDropdown') %>" tabindex="-1"></button>
    `;
  }

  className() {
    return 'ax-grid ax-grid--no-gutter';
  }

  templateHelpers() {
    return {
      placeholder: this.placeholderText
    };
  }

  events() {
    const events = {
      'input .js-filter-input': '_updateFilter',
      'keydown .js-filter-input': '_handleKeyDown',
      'click .js-filter-input': '_showDropdown',
      'focus .js-filter-input': '_selectContents',
      'click .js-trigger-dropdown': '_toggleDropdown'
    };

    if ($os.browser === 'safari') {
      events['click .js-trigger-dropdown'] = '_toggleSafariDropdown';
    }

    return events;
  }

  ui() {
    return {
      communityIcon: '.js-community-icon',
      filterInput: '.js-filter-input',
      userCount: '.js-user-count',
      groupIcon: '.js-group-icon',
      triggerDropdown: '.js-trigger-dropdown'
    };
  }

  initialize(options = {}) {
    ({
      switchDropdown: this.switchDropdown,
      updateFilter: this.updateFilter,
      keyboardNavigationHandler: this.keyboardNavigationHandler,
      updateRecentStatus: this.updateRecentStatus
    } = options);
    this.placeholderText = options.placeholderText || I18n.t('components.communityPicker.placeholderText');

    this.updateFilter = _.debounce(this.updateFilter, 150);
    this.userCount = new CommunityUserCounts();

    this.listenTo(this.model, 'change', this._updateCommunityName);
    this.listenTo(this.userCount, 'change', this._updateUserCount);
  }

  onRender() {
    this._updateCommunityName();
  }

  getCommunityIcon() {
    const media = this.model.get('thumbnailImage');
    const communityIcon = this.ui.communityIcon;
    communityIcon.removeClass('icon-groups hidden').empty();

    if (media != null) {
      const imageViewer = ImageViewerFactory.createViewerInstance({
        $el: communityIcon,
        maxWidth: 20,
        maxHeight: 20,
        media
      });

      imageViewer.render();
    } else {
      communityIcon.addClass('icon-groups');
    }
  }

  _selectContents(e) {
    $(e.target).trigger('select');
    this.justClosed = false;
    this.switchDropdown(ON);
  }

  _showDropdown(e) {
    e.preventDefault();
    this.justClosed = false;
    this.switchDropdown(ON);
  }

  _handleKeyDown(e) {
    this.justClosed = false;
    const isNavKey = this.keyboardNavigationHandler(e);

    if (isNavKey) {
      e.preventDefault();
    }
  }

  _updateFilter() {
    if (this.model.getName() !== this.ui.filterInput.val()) {
      const isSearching = this.ui.filterInput.val() !== '';
      this.updateFilter(this.ui.filterInput.val(), isSearching);
    }
  }

  _updateCommunityName() {
    this.ui.filterInput.val(this.model.getName());

    if (this.model.id && this.model.id !== -1) {
      this.userCount.fetch({
        data: { communityId: this.model.id },
        error: (model, xhr) => {
          xhr.skipGlobalHandler = true;
          this.userCount.clear();
        }
      });
      this.getCommunityIcon();
    } else {
      this.userCount.clear();
    }
  }

  _updateUserCount () {
    const userCountNum = this.userCount.get('count');

    if (userCountNum >= 0) {
      this.ui.userCount.text(userCountNum);
      this.ui.userCount.removeClass('hidden');
      this.ui.groupIcon.removeClass('hidden');
      this.ui.groupIcon.attr('aria-label', `${ I18n.t('components.communityPicker.aria.numMembers') } ${ userCountNum }`);
    } else {
      this.ui.userCount.addClass('hidden');
      this.ui.groupIcon.addClass('hidden');
      this.ui.groupIcon.removeAttr('aria-label');
      this.ui.communityIcon.addClass('hidden');
    }
  }

  _toggleDropdown(e) {
    e.preventDefault();

    const isExpanded = this.ui.triggerDropdown.attr('aria-expanded') === 'true';

    if (isExpanded) {
      this.switchDropdown(!isExpanded);
    } else if (!this.justClosed) {
      this.ui.filterInput.trigger('focus').trigger('click');
    }

    this.justClosed = false;
  }

  _toggleSafariDropdown(e) {
    // needed to work around a Safari-specific behaviour where `this._toggleDropdown()` is called before
    // `this._hideDropdown()` (all other browsers call `this._hideDropdown()` before `this._toggleDropdown()`)
    this.justClosed = false;
    this._toggleDropdown(e);
  }
}

module.exports = FilterControlView;
