import {
  Toolbar as MuiToolbar,
  styled,
  type ToolbarTypeMap as MuiToolbarTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxToolbarCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxToolbarTypeMap<P = object, D extends React.ElementType = 'div'> = MuiToolbarTypeMap<P & AxToolbarCustomProps, D>;

export type AxToolbarProps<
  D extends ElementType = AxToolbarTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxToolbarTypeMap<P & AxToolbarCustomProps, D>, D>;

const styledOptions = {
  name: 'AxToolbar'
};

const StyledAxToolbar = styled(MuiToolbar, styledOptions)<AxToolbarProps>(() => {
  return {};
});

export const AxToolbar: MuiOverridableComponent<AxToolbarTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxToolbarProps, ref: Ref<HTMLDivElement>) => {
  const ToolbarProps: AxToolbarProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxToolbar { ...ToolbarProps } ref={ ref }>
      { children }
    </StyledAxToolbar>
  );
});

export default AxToolbar;
