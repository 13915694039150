// Module
var code = `<div class="content-container ax-grid__col--grow-2">
  <% if (!isArticle()) { %>
    <div class="page-type-label ax-grid__col--auto-size <%- type %>"><%- getPageTypeString() %></div>
  <% } %>
  <% if (isReference()) { %>
    <div class="clearfix">
      <a href="#" class="title qa-timeline-title reference">
        <p><%- getTitle() %></p>
        <span class="icon-external_link"></span>
      </a>
    </div>
  <% } else { %>
    <div class="ax-grid axgrid--no-gutter ax-grid--align-items-center title-container">
      <div class="title qa-timeline-title ax-grid__col--auto-size"><%- getTitle() %></div>
    </div>
  <% } %>
  <p class="content article-content js-impression-tracking-zone"><%= getPreviewContent() %></p>
</div>
<div class="image-container ax-grid ax-grid--no-gutter hidden"></div>
`;
// Exports
module.exports = code;