const Backbone = require('Backbone');
const News = require('../models/News');

class NewsList extends Backbone.Collection {
  get model() {
    return News;
  }

  apiEndpoint() {
    return '/news';
  }

  parse(data) {
    return data.news != null ? data.news : data;
  }
}
module.exports = NewsList;
