const {
  Model,
  Collection
} = require('Backbone');

class HubTileCollection extends Collection {

  get model() {
    return Model.extend({ idAttribute: 'identifier' });
  }

  constructor(models, options) {
    super(models, options);

    this.apiEndpoint = options.apiEndpoint;
  }

  parse(response) {
    return response.tiles;
  }

  getAvailableTiles() {
    // returns a object with all the current activity types
    return this.reduce((memo, activity) => {
      memo[activity.get('identifier')] = true;
      return memo;
    }, {});
  }
}

const createCollection = (appZone, models, options) => {
  return new HubTileCollection(models, Object.assign({ apiEndpoint: `/hubtiles/available/${ appZone }` }, options));
};

module.exports = {
  forLearnerZone: (models, options = {}) => {
    return createCollection('LEARNER_ZONE', models, options);
  },
  forLeaderZone: (models, options = {}) => {
    return createCollection('LEADER_ZONE', models, options);
  }
};
