const TincanQueryService = require('@common/libs/tincan/TincanQueryService');
const {
  getDefaultLrsConfigurationForUser
} = require('@common/libs/tincan/DefaultTincanConfigurationFactory');

const TincanCompletionTracker = require('./TincanCompletionTracker');
require('bluebird');

const POLL_DELAY_MS = 5000;

class SCORMProfileTincanCompletionTracker extends TincanCompletionTracker {
  constructor(options = {}) {
    super();
    this._tenantPropertyPool = options.tenantPropertyPool;
    this._intervalToken = null;
  }

  startTracking(tincanPackage, user) {
    const queryService = new TincanQueryService({
      lrsConfig: getDefaultLrsConfigurationForUser(this._tenantPropertyPool, user)
    });

    return new Promise((resolve) => {
      let stillPolling = false;
      const poll = (callback) => {
        if (stillPolling === true) {
          return Promise.reject(new Error('done polling!'));
        }

        stillPolling = true;
        return queryService
          .isModuleCompletedFor(user, tincanPackage)
          .then((isComplete) => {
            if (isComplete) {
              callback(true);
              this.stopTracking();
            }
          })
          .finally(() => {
            stillPolling = false;
          });
      };

      // The simplest implementation is to poll every so often, but this can be changed to a AJAX hook with more
      // work if required.
      poll(resolve);

      this._intervalToken = setInterval(() => {
        return poll(resolve);
      }, POLL_DELAY_MS);
    });
  }

  stopTracking() {
    clearInterval(this._intervalToken);
  }
}

module.exports = SCORMProfileTincanCompletionTracker;
