const { Model } = require('Backbone');
const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const Form = require('@common/components/forms/Form');
const AxonSelect = require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');
const HasValueDropDown = require('@common/components/forms/editors/hasValueDropdown/Form.Editor.HasValueDropDown');
const TranslationInput = require('@common/components/forms/editors/translationInput/Form.Editor.TranslationInput');
const TranslationTextArea = require('@common/components/forms/editors/translationTextArea/Form.Editor.TranslationTextArea');
const InputTypeEnum = require('@common/data/enums/InputTypeEnum');

require('@common/components/forms/editors/translatableName/TranslatableNameEditor.less');

class TranslatableNameLayout extends LayoutView {
  getTemplate() {
    return require('@common/components/forms/editors/translatableName/TranslatableNameLayout.html');
  }

  templateHelpers() {
    return {
      limit: this.getOption('maxLength') ? I18n.t('translatableName.characterLimit', { maxLength: this.getOption('maxLength') }) : '',
      label: this.getOption('inputLabel'),
      inputNote: this.getOption('inputNote') || I18n.t('translatableName.note')
    };
  }

  ui() {
    return {
      languageSelector: '.js-language-selector',
      translatableField: '.js-translatable-field'
    };
  }

  onRender() {
    this._renderLanguageSelector();
    this._renderTranslatableField();
  }

  _renderLanguageSelector() {
    const SelectElement = this.getOption('showValuesObject') ? HasValueDropDown : AxonSelect;

    this.languageSelector = new SelectElement({
      el: this.ui.languageSelector,
      options: {
        collection: this.getOption('languageList'),
        axonSelectOptions: {
          useChosen: this.getOption('useChosen')
        },
        showValuesObject: this.getOption('showValuesObject'),
        optionsClass: this.getOption('optionClass')
      }
    });

    this.languageSelector.render();
  }

  _renderTranslatableField() {
    let TranslationFieldType = TranslationInput;
    if (this.getOption('inputType') === InputTypeEnum.TEXTAREA) {
      TranslationFieldType = TranslationTextArea;
    }
    this.translatableField = new TranslationFieldType({
      el: this.ui.translatableField,
      options: {
        translatedLanguage: this.getOption('currentLanguage'),
        isLocalizableString: this.getOption('isLocalizableString'),
        maxLength: this.getOption('maxLength')
      }
    });

    this.translatableField.render();
  }
}

Form.Editor.TranslatableName = class TranslatableName extends Form.Editor {
  preinitialize(options = {}) {
    super.preinitialize(options);

    this.options = options.options || options;

    this.currentLanguage = '';
    this.languageModel = new Model();
    this.listenTo(this.languageModel, 'change:language', this.onLanguageChange);
  }

  initialize(options = {}) {
    super.initialize(options.options);
    ({
      useChosen: this.useChosen = true,
      optionClass: this.optionClass = '',
      showValuesObject: this.showValuesObject = false
    } = options.options);

    this._layout = this._getLayout();
    this._renderLayout();

    this.listenTo(this._layout.languageSelector, 'change', this.onLanguageChange);
    this.listenTo(this._layout.translatableField, 'change', this.onTranslationChange);
  }

  setValue(val) {
    if (val != null && this._layout != null) {
      if ( this._layout.languageSelector.getValue() == null) {
        this._updateLanguage();
      }
      this._layout.translatableField.setValue(val);
    }

    super.setValue(val);
  }

  onLanguageChange() {
    const langVal = this._layout.languageSelector.getValue();
    if (langVal != null) {
      this.currentLanguage = this._layout.languageSelector.getValue();
    }

    this._layout.translatableField.updateSelectedLanguage(this.currentLanguage);
  }

  onTranslationChange() {
    const langVal = this.getValue();
    // compatibility with TranslatableString
    if (typeof langVal.setDefault !== 'function') {
      return;
    }
    const tenantDefaultLanguage = TenantPropertyProvider.get().getProperty('defaultLanguage');

    // Remove the default first so the fallback logic cannot fallback on the previous default set
    // We do this because we don't want to keep a default value that was possibly a translation removed during edit
    langVal.setDefault(null);
    langVal.setDefaultWithPreferredLocale(tenantDefaultLanguage);
  }

  _renderLayout() {
    this._layout.render();
  }

  _getLayout() {
    return new TranslatableNameLayout({
      el: this.$el,
      currentLanguage: this.currentLanguage,
      isLocalizableString: this.getOption('isLocalizableString'),
      maxLength: this.getOption('maxLength'),
      inputLabel: this.getOption('inputLabel'),
      languageList: this.getOption('languageList'),
      useChosen: this.getOption('useChosen'),
      inputType: this.getOption('inputType'),
      inputNote: this.getOption('inputNote'),
      showValuesObject: this.showValuesObject
    });
  }

  _updateLanguage() {
    this.userDefaultLanguage = window.apps.auth.session.user.get('language') || 'EN';

    // Handle situation where admin's language may not be set
    if (this.userDefaultLanguage === 'XX') {
      this.userDefaultLanguage = TenantPropertyProvider.get().getProperty('defaultLanguage');
    }

    this.currentLanguage = this.userDefaultLanguage;
    this.languageModel.set('language', this.userDefaultLanguage);
    this._layout.languageSelector.setValue(this.userDefaultLanguage);
  }
};

module.exports = Form.Editor.TranslatableName;
