const GuestRouter = require('@training/apps/training/GuestRouter');
const CommunitiesManagementController = require('@training/apps/search/communitiesManagement/CommunitiesManagementController');
const CommunityManagementController = require('@training/apps/search/communitiesManagement/CommunityManagementController');
const CommunityInsightsController = require('@training/apps/training/communitiesInsights/CommunityInsightsController');
const CommunityInsightsReportsController = require('@training/apps/training/communitiesInsights/CommunityInsightsReportsController');
const ConversationalSearchControllerDefinitionFactory = require('@training/apps/conversationalSearch/ConversationalSearchControllerDefinitionFactory').default;

const CommunitiesManagementTabEnum = require('@training/apps/training/enums/CommunitiesManagementTabEnum');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

// Extend GuestRouter because Superusers get the same Discover access plus community management and reports
class SuperuserRouter extends GuestRouter {
  initialize() {
    super.initialize();
    const propertyProvider = TenantPropertyProvider.get();
    this.isCommsEnabled = propertyProvider.getProperty('communicationsEnabled');
  }

  routes() {
    const routes = super.routes();

    // Overwriting the subclass routes below with the superclass routes because ordering matters.
    // If community management routes exist after the superclass routes, the default route definied in Guest Router
    // will resolve before any community mangement routes are resolved.
    return Object.assign({
      'hub/search/communitiesManagement/communities(/)(:pageNum)(/)': 'communitiesManagementCommunities',
      'hub/search/communitiesManagement/filters(/)(:filterID)(/)': 'communitiesManagementFilters',
      'hub/search/communitiesManagement/importHistory(/)(:pageNum)(/)': 'communitiesManagementImportHistory',
      'hub/search/articles/community-:communityId/communityManagement': 'communityManagement',
      'hub/search/articles/communityManagement/new': 'communityManagementNew',

      'hub/search/communicationsInsights': 'communicationsInsights',
      'hub/search/communicationsInsights-includeTeamBasedCommunities': 'communicationsInsightsIncludeTeamBasedCommunities',
      'hub/search/communicationsInsights/reports': 'communicationsInsightsReports',
      'hub/search/communicationsInsights/reports/(:tabName)': 'communicationsInsightsReports',
      'hub/search/communicationsInsights-includeTeamBasedCommunities/reports/(:tabName)': 'communicationsInsightsReportsIncludeTeamBasedCommunities',

      'hub/max': 'conversationalSearch'
    }, routes);
  }

  communitiesManagementCommunities(pageNumberParam) {
    let pageNum = 1;
    if (pageNumberParam != null && pageNumberParam > 0) {
      pageNum = pageNumberParam;
    }

    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      pageNum
    }, 'fade');
  }

  communitiesManagementFilters(filterId) {
    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      filterId,
      initialTab: CommunitiesManagementTabEnum.FILTERS
    }, 'fade');
  }

  communitiesManagementImportHistory(pageNumberParam) {
    let pageNum = 1;
    if (pageNumberParam != null && pageNumberParam > 0) {
      pageNum = pageNumberParam;
    }

    window.app.layout.setView({
      ViewControllerClass: CommunitiesManagementController,
      initialTab: CommunitiesManagementTabEnum.IMPORT_HISTORY,
      pageNum
    }, 'fade');
  }

  communityManagement(communityId) {
    window.app.layout.setView({
      ViewControllerClass: CommunityManagementController,
      communityId
    }, 'fade');
  }

  communityManagementNew() {
    // Force communityManagement route to be resolved with an undefined communityId for new-community flow
    this.communityManagement();
  }

  communicationsInsightsIncludeTeamBasedCommunities() {
    this.communicationsInsights(true);
  }

  communicationsInsights(includeTeamBasedCommunities) {
    if (this.isCommsEnabled) {
      window.app.layout.setView({
        ViewControllerClass: CommunityInsightsController,
        includeTeamBasedCommunities: includeTeamBasedCommunities != null
      }, 'fade');
    } else {
      this.articleSearch();
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));
    }
  }

  communicationsInsightsReportsIncludeTeamBasedCommunities(tabName = 'initial') {
    this.communicationsInsightsReports(tabName, true);
  }

  communicationsInsightsReports(tabName, includeTeamBasedCommunities = null) {
    const boolIncludeTeamBasedCommunities = Boolean(includeTeamBasedCommunities);
    if (this.isCommsEnabled) {
      window.app.layout.setView({
        ViewControllerClass: CommunityInsightsReportsController,
        includeTeamBasedCommunities: boolIncludeTeamBasedCommunities,
        currentTabName: tabName
      }, 'fade');
    } else {
      this.articleSearch();
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));
    }
  }

  conversationalSearch() {
    if (TenantPropertyProvider.get().getProperty('conversationalSearchEnabled')) {
      window.app.layout.setView(ConversationalSearchControllerDefinitionFactory());
    } else {
      this.default();
    }
  }

}

module.exports = SuperuserRouter;
