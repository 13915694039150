const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const User = require('@common/data/models/AuthUser');

class Like extends Backbone.Model {
  apiEndpoint() {
    return '/newsStoryLikes';
  }

  parse(data) {
    return data.entity;
  }
}

class News extends Backbone.Model {
  preinitialize() {
    this.newsUser = new User();
    
    this.listenTo(this, 'change:user', (model, attributes) => {
      return this.newsUser.set(attributes);
    });
  }

  initialize(data) {
    if (data.myLike != null) {
      this.myLike = new Like(data.myLike);
    }
  }

  parse(response) {
    const news = response.news ?? response;
    
    if (news.content.gameRank != null) {
      news.content.gameRank = I18n.t(`news.gameRank.${ news.content.gameRank }`);
    }

    if (news.content.gameType != null) {
      news.content.gameName = I18n.t(`games.${ news.content.gameType }`);
    }

    return news;
  }

  likeNews(options = {}) {
    if (this.myLike != null) {
      return undefined;
    }

    this.myLike = new Like();
    
    const onSuccess = options.success;
    
    options.success = () => {
      // Unset the 'newsStory' from creation
      this.myLike.unset('newsStory', {silent: true});
      
      // Update the News model
      this.set({
        likeCount: this.get('likeCount') + 1,
        myLike: this.myLike.toJSON()
      });

      // Execute the success callback with the response data
      onSuccess?.(this.myLike.toJSON());
    };

    return this.myLike.save({
      newsStory: {
        id: this.get('id')
      }
    }, options);
  }

  unlikeNews(options) {
    if (this.myLike == null) {
      return undefined;
    }

    const onSuccess = options.success;
    
    options.success = () => {
      // Update the News model
      this.set({
        likeCount: this.get('likeCount') - 1,
        myLike: null
      });

      // Clean up and execute the success callback
      this.myLike = null;
      
      onSuccess?.();
    };

    return this.myLike.destroy(options);
  }

  toJSON(...args) {
    return {
      ...super.toJSON(...args),
      user: this.newsUser.toJSON()
    };
  }
}
module.exports = News;
