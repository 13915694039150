const Marionette = require('Marionette');

const EvaluationCriteriaCardDetailsView = require('@training/apps/training/views/evaluations/EvaluationCriteriaCardDetailsView');
const EvaluationCriteriaCommentView = require('@training/apps/training/views/evaluations/EvaluationCriteriaCommentView');

class EvaluationCriteriaCardView extends Marionette.LayoutView {
  className() {
    return 'evaluation-criteria card card--informational';
  }

  tagName() {
    return 'li';
  }

  _setStatusClass() {
    if (this.model.get('negativeObservations') > 0) {
      this.$el.toggleClass('not-passed', true);
    } else if (this.model.get('positiveObservations') > 0) {
      this.$el.toggleClass('passed', true);
    } else {
      this.$el.toggleClass('pending', true);
    }
  }

  getTemplate() {
    return `\
<div class="evaluation-criteria__details-wrapper"></div>
<div class="evaluation-criteria__comment-wrapper"></div>`;
  }

  regions() {
    return {
      detailsRegion: '.evaluation-criteria__details-wrapper',
      commentRegion: '.evaluation-criteria__comment-wrapper'
    };
  }

  onRender() {
    this._setStatusClass();
    this._renderDetailsView();
    this._renderCommentView();
  }

  _renderDetailsView() {
    const detailsView = new EvaluationCriteriaCardDetailsView({
      model: this.model
    });

    this.detailsRegion.show(detailsView);
  }

  _renderCommentView() {
    if (this.model.get('comment')) {
      const commentView = new EvaluationCriteriaCommentView({
        comment: this.model.get('comment'),
        isGeneralNotes: false
      });

      this.commentRegion.show(commentView);
    }
  }
}

module.exports = EvaluationCriteriaCardView;
