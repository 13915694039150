const Backbone = require('Backbone');
const Cocktail = require('backbone.cocktail');

const SelectableListMixin = require('@common/components/selectable_list/SelectableListMixin');

const Coach = require('@training/apps/training/models/Coach');

class CoachesList extends Backbone.Collection {
  get model() {
    return Coach;
  }

  url() {
    return '/axonify/selfserve/coaches';
  }

  parse(res = {}) {
    return res.entities || res;
  }

  comparator(a, b) {
    if (a.get('coachType') === 'NoCoach') {
      return -1;
    }
    if (b.get('coachType') === 'NoCoach') {
      return 1;
    }

    return a.id - b.id;
  }
}

Cocktail.mixin(CoachesList, SelectableListMixin);

module.exports = CoachesList;
