const { reduce } = require('underscore');

class ViewConfigBuilder {
  _builders = [];

  addBuilder(builder) {
    this._builders.push(builder);
  }

  build() {
    return reduce(this._builders, (configs, builder) => {
      return configs.concat(builder.build());
    }, []);
  }
}

module.exports = ViewConfigBuilder;
