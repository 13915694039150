const Enum = require('@common/data/enums/Enum');

const OAuthProviderEnum = Enum.fromStringArray([
  'Facebook',
  'Google',
  'LinkedIn',
  'Salesforce',
  'Zendesk'
]);

module.exports = OAuthProviderEnum;
