import { ButtonCommonStyles } from '@common/modules/react/themes/components';
import {
  Button as MuiButton,
  styled,
  type ButtonTypeMap as MuiButtonTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { merge } from 'lodash';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

export type BUTTON_VARIANTS =
  'default' |
  'branded' |
  'branded-secondary' |
  'destructive' |
  'ghost' |
  'ghost-link-branded';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'branded': true
    'branded-secondary': true
    'default': true
    'destructive': true
    'ghost': true
    'ghost-link-branded': true
  }
}

type AxButtonCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  variant?: BUTTON_VARIANTS
  /**
  *  Apply default disabled state CSS. Can set to false for buttons that are functionality disabled but shouldn't look it.
  *  @default true
  **/
  enableDisabledStyles?: boolean
  // TODO - better description with defaults needed for offScreen
  offScreen?: boolean
  /**
  *  When hovering on a parent element that contains a button, a ghost button will display using it's hover state
  *  @default false
  **/
  ghostParentHover?: boolean
};

export type AxButtonTypeMap<P = object, D extends React.ElementType = 'button'> = MuiButtonTypeMap<P & AxButtonCustomProps, D>;

export type AxButtonProps<
  D extends ElementType = AxButtonTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxButtonTypeMap<P & AxButtonCustomProps, D>, D>;

const styledOptions = {
  name: 'AxButton',
  shouldForwardProp: (prop: string) => {
    return prop !== 'variant' && prop !== 'enableDisabledStyles' && prop !== 'offScreen' && prop !== 'ghostParentHover';
  }
};

const StyledAxButton = styled(MuiButton, styledOptions)<AxButtonProps>((props) => {
  const {
    variant,
    theme,
    size
  } = props;

  return merge(ButtonCommonStyles(variant, theme),
    (size === 'small' && {
      padding: theme.uiKit.buttonPaddingS
    }),
    (size === 'medium' && {
      padding: theme.uiKit.buttonPaddingM
    }),
    (size === 'large' && {
      padding: theme.uiKit.buttonPaddingL
    }),
    {
      '& .MuiButton-startIcon': {
        marginLeft: 0
      },
      '& .MuiButton-endIcon': {
        marginRight: 0
      }
    });
});

export const AxButton: MuiOverridableComponent<AxButtonTypeMap<AxButtonProps, React.ElementType>> = forwardRef(({
  children,
  className = '',
  disableElevation = true,
  disableFocusRipple = true,
  disableRipple = true,
  disableTouchRipple = true,
  enableDisabledStyles = true,
  ghostParentHover = false,
  offScreen = false,
  size = 'large',
  variant = 'default',
  disabled = false,
  ...otherProps
}: AxButtonProps, ref: Ref<HTMLButtonElement>) => {
  const buttonProps: AxButtonProps = {
    className,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    disableTouchRipple,
    ghostParentHover,
    size,
    variant,
    disabled,
    ...otherProps
  };

  if (!enableDisabledStyles) {
    buttonProps.className += ' MuiButton--remove-disabled-styles';
  }

  if (offScreen) {
    buttonProps.className += ' MuiButton--off-screen';
  }

  if (ghostParentHover) {
    buttonProps.className += ' MuiButton--ghost-parent-hover';
  }

  return (
    <StyledAxButton { ...buttonProps } ref={ ref }>
      { children }
    </StyledAxButton>
  );
});

export default AxButton;
