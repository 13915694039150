import { joinClassNames } from '@common/libs/helpers/app/HTMLHelpers';
import {
  Icon as MuiIcon,
  styled,
  type CSSObject,
  type IconTypeMap as MuiIconTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';
import {
  type BaseIconCustomProps,
  type IconNames
} from './types';
import { getIconSizeStyles } from './utils';

declare module '@mui/material/Icon' {
  interface IconPropsSizeOverrides {
    'small': true
    'medium': true
    'large': true
    'x-large': true
    'inherit': true
  }

  interface IconPropsColorOverrides {
    'inherit': true
    'primary': true
    'secondary': true
    'error': true
    'disabled': true
    'info': true
    'success': true
    'warning': true

    'action': false
  }
}

type PropsToRemove = 'fontSize' | 'color' | 'baseClassName'; // to update with our custom prop

export type AxIconTypeMap<P = object, D extends React.ElementType = 'span'> = {
  props: P & Omit<MuiIconTypeMap['props'], PropsToRemove> & BaseIconCustomProps & {
    /**
     * The name of the icomoon class icon to display.
     */
    iconName?: IconNames;
    /**
     * Override or extend the styles applied to the component.
     * @deprecated Use the `iconName` prop for icon selection.
     */
    className?: string;

  };
  defaultComponent: D;
};

export type AxIconProps<
  D extends ElementType = AxIconTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxIconTypeMap<P & BaseIconCustomProps, D>, D>;


const styledOptions = {
  name: 'AxIcon',
  shouldForwardProp: (prop: string) => {
    return prop !== 'iconName';
  }
};

const StyledAxIcon = styled(MuiIcon, styledOptions)<AxIconProps>((props) => {
  const {
    fontSize,
    theme
  } = props;
  
  const style: CSSObject = {
    ...getIconSizeStyles(fontSize, theme)
  };
  
  return style;
});

/**
 *
 * A wrapper around the MUI Icon component.
 *
 * ### Links
 * MUI: https://mui.com/components/icons/ <br />
 * Figma: https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=4846-4699&t=VHMLzyY56EOzj2PM-0
 *
 * @example
 * <AxIcon fontSize='large' color='primary' className='icon-chevron_right' />
 *
 */
export const AxIcon: MuiOverridableComponent<AxIconTypeMap> = forwardRef(({
  children,
  fontSize = 'medium',
  color = 'inherit',
  ...otherProps
}: AxIconProps, ref: Ref<HTMLSpanElement>) => {
  const IconProps: AxIconProps = {
    children,
    fontSize,
    color,
    ...otherProps
  };

  const iconClass = joinClassNames(otherProps.className, otherProps.iconName);

  return (
    <StyledAxIcon
      { ...IconProps }
      ref={ ref }
      className={ iconClass }>
      { children }
    </StyledAxIcon>
  );
});

export default AxIcon;
