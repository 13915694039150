const I18n = require('@common/libs/I18n');
const MarionetteViewFactory = require('@common/libs/UI/views/MarionetteViewFactory');

const WindowFactory = require('@common/components/window/WindowFactory');
const DocumentView = require('@common/components/window/DocumentView');

const PrintDocument = require('@common/services/print/PrintDocument');

class PrintService {
  defaultWindowFactory = new WindowFactory();

  defaultViewFactory = new MarionetteViewFactory({
    defaultDefinition: {
      ViewClass: DocumentView
    }
  });

  defaultWindowFeatures = {
    width: 750,
    height: 650,
    top: 50,
    left: 50,
    toolbars: 'no',
    scrollbars: 'yes',
    status: 'no',
    resizable: 'yes'
  };

  constructor(options = {}) {
    this.print = this.print.bind(this);
    ({
      popupBlockedHandler: this.popupBlockedHandler
    } = options);
    this.windowFactory = options.windowFactory != null ? options.windowFactory : this.defaultWindowFactory;
    this.viewFactory = options.viewFactory != null ? options.viewFactory : this.defaultViewFactory;

    this.windowCache = {};
  }

  print(windowId = '', documentOptions = {}) {
    const cachedWindow = this._getCachedWindow(windowId);

    if (this._isUsableWindow(cachedWindow)) {
      this._foregroundPrintWindow(cachedWindow);
    } else {
      this._showNewPrintWindow(windowId, documentOptions);
    }
  }

  _foregroundPrintWindow(win) {
    win.focus();
  }

  _showNewPrintWindow(windowId, documentOptions) {
    const win = this.windowFactory.create({
      windowId,
      windowFeatures: this.defaultWindowFeatures
    });

    if (win != null) {
      this._cacheWindow(windowId, win);

      const printLayout = this._createPrintDocument(win, documentOptions);
      printLayout.render();
    } else if (typeof this.popupBlockedHandler === 'function') {
      this.popupBlockedHandler(I18n.t('flash.printPopUpBlocked'));
    }
  }

  _createPrintDocument(win, options) {
    const viewDefinition = {
      ViewClass: PrintDocument,
      isRtl: I18n.isCurrentLanguageRtl()
    };

    return this.viewFactory.create(viewDefinition, options, {
      el: win.document
    });
  }

  _isUsableWindow(win) {
    return (win != null) && !win.closed;
  }

  _getCachedWindow(windowId = '') {
    return this.windowCache[windowId];
  }

  _cacheWindow(windowId = '', win) {
    return this.windowCache[windowId] = win;
  }
}

module.exports = PrintService;
