const logging = require('logging');

const { compact } = require('underscore');

const Behaviors = require('@common/libs/behaviors/Behaviors');

const CardModifierClasses = require('@common/libs/behaviors/card/CardModifierClasses');

class CardBehaviorConfigBuilder {

  constructor(target = '') {
    this._target = target;
    this._mainModifier = null;
    this._isInteractive = false;
    this._paddingModifier = null;
    this._additiveModifiers = [];
  }

  setTarget(target = '') {
    this._target = target;
    return this;
  }

  setDefault() {
    this._mainModifier = null;
    return this;
  }

  setPrimary() {
    this._mainModifier = CardModifierClasses.PRIMARY;
    return this;
  }

  setInteractive(toggle = true) {
    this._isInteractive = toggle;
    return this;
  }

  setInformational() {
    this._mainModifier = CardModifierClasses.INFORMATIONAL;
    return this;
  }

  setSmallPadding() {
    this._paddingModifier = CardModifierClasses.SMALL_PADDING;
    return this;
  }

  setMediumPadding() {
    this._paddingModifier = CardModifierClasses.MEDIUM_PADDING;
    return this;
  }

  setLargePadding() {
    this._paddingModifier = CardModifierClasses.LARGE_PADDING;
    return this;
  }

  clearPadding() {
    this._paddingModifier = CardModifierClasses.SMALL_PADDING;
    return this;
  }

  setNoTransform() {
    this._additiveModifiers.push(CardModifierClasses.NO_TRANSFORM);
    return this;
  }

  setOverflowVisible() {
    this._additiveModifiers.push(CardModifierClasses.OVERFLOW_VISIBLE);
    return this;
  }

  buildModifierClasses() {
    const modifierClasses = [this._paddingModifier, this._mainModifier].concat(this._additiveModifiers);

    if (this._isInteractive) {
      if (this._mainModifier === CardModifierClasses.INFORMATIONAL) {
        logging.warn('Informational Cards are static, ignoring the Interactive flag.');
      } else {
        modifierClasses.push(CardModifierClasses.INTERACTIVE);
      }
    }

    return compact(modifierClasses);
  }

  build(behaviorObject = {}) {
    const behaviorConfig = {
      target: this._target,
      modifierClasses: this.buildModifierClasses()
    };

    return [
      ...Behaviors.convertConfigObjectToArray(behaviorObject),
      ...Behaviors.convertConfigObjectToArray(behaviorConfig)
    ];
  }
}

module.exports = CardBehaviorConfigBuilder;
