const BLANKET_CONTENT_CONTROLLER_DEFINITION = 'blanketContentControllerDefinition';

module.exports = {
  Keys: {
    BLANKET_CONTENT_CONTROLLER_DEFINITION
  },

  getContentControllerDefinition(contextModel) {
    return contextModel.get(BLANKET_CONTENT_CONTROLLER_DEFINITION);
  },

  setContentControllerDefinition(contextModel, controllerDefinition, options = {}) {
    contextModel.set(BLANKET_CONTENT_CONTROLLER_DEFINITION, controllerDefinition, options);
  }
};
