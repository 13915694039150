const {CertificationTraining} = require('@common/data/enums/SessionTrainingType');

const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');

const QuizboardSelectableAssessmentController = require('@training/apps/training/controllers/assessments/QuizboardSelectableAssessmentController');

class CertificationTrainingAssessmentController extends QuizboardSelectableAssessmentController {
  getTimeLogOptions(options = {}) {
    const {
      isFromHub = false
    } = options;

    if (isFromHub) {
      return TimeLogConfig.PageView.HubCertificationTraining;
    }
    return TimeLogConfig.PageView.AutomaticCertificationTraining;

  }

  getSessionTrainingType() {
    return CertificationTraining;
  }
}

module.exports = CertificationTrainingAssessmentController;
