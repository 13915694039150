import {
  type AxButtonProps,
  type AxIconButtonProps
} from '@common/modules/react/themes/components';
import {
  type CSSObject,
  type Theme
} from '@mui/material/styles';

export default function ButtonCommonStyles(
  variant: AxButtonProps['variant'] | AxIconButtonProps['variant'] | undefined,
  theme: Theme
): CSSObject {
  const varitantStyles = getVariantStyles(variant, theme);

  const defaultStyles = {
    backgroundColor: theme.uiKit.buttonDefaultBackground,
    border: theme.uiKit.buttonDefaultBorder,
    borderRadius: theme.uiKit.borderRadiusM,
    color: theme.uiKit.buttonColor,
    fontSize: theme.uiKit.buttonFont,
    fontWeight: theme.uiKit.buttonWeight,
    letterSpacing: 'normal',
    lineHeight: theme.uiKit.buttonLineHeight,
    minWidth: 'auto',
    margin: 0,
    '&:hover': {
      ...getHoverStyles(variant, theme)
    },
    '&.Mui-disabled': {
      '& > .MuiIcon-root, \
       & > .MuiSvgIcon-root, \
       & > [class^="icon-"]': {
        color: theme.uiKit.buttonColor
      },
      '&.MuiButton--remove-disabled-styles': {
        color: varitantStyles?.color || theme.uiKit.buttonColor,
        cursor: 'not-allowed'
      }
    },
    '&.Mui-focusVisible': theme.mixins.componentFocusStyles(),
    '& > .MuiIcon-root, \
     & > .MuiSvgIcon-root, \
     & > [class^="icon-"], \
     & > .MuiButton-startIcon .MuiIcon-root, \
     & > .MuiButton-endIcon .MuiIcon-root': {
      opacity: 1,
      color: theme.uiKit.buttonColor,
      fontSize: theme.uiKit.buttonLineHeight
    },
    '&.MuiButton--off-screen': {
      ...theme.mixins.componentOffScreenStyles()
    },
    '&.Mui-disabled:not(.MuiButton--remove-disabled-styles)': {
      ...getVariantDisabledStyles(variant, theme)
    }
  };

  return {
    ...defaultStyles,
    ...varitantStyles
  };
}

export function getVariantDisabledStyles(variant: string | undefined, theme: Theme): object {
  const defaultStyles = {
    backgroundColor: 'transparent',
    border: theme.uiKit.buttonDefaultBorderDisabled,
    color: theme.uiKit.buttonDefaultColorDisabled,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    fontWeight: theme.uiKit.fontWeightBold,
    '& > .MuiIcon-root, \
     & > .MuiSvgIcon-root, \
     & > [class^="icon-"]': {
      color: theme.uiKit.buttonDefaultColorDisabled
    }
  };

  const variantDisabledStylesMap: Record<string, CSSObject> = {
    branded: {
      backgroundColor: theme.uiKit.buttonBrandedBackgroundDisabled,
      borderColor: theme.uiKit.buttonBrandedBorderColorDisabled,
      color: theme.uiKit.buttonBrandedColorDisabled
    },
    ghost: {
      border: theme.uiKit.buttonGhostBorderDisabled
    },
    'ghost-link-branded': {
      border: theme.uiKit.buttonGhostBorderDisabled
    }
  };

  return {
    ...defaultStyles,
    ...variantDisabledStylesMap[variant || '']
  };
}

const getButtonIconColorStyles = (color: keyof Theme['uiKit'] = 'buttonBrandedColor', theme: Theme): Record<string, unknown> => {
  return {
    '& > .MuiIcon-root, \
     & > .MuiSvgIcon-root, \
     & > [class^="icon-"], \
     & > .MuiButton-startIcon .MuiIcon-root, \
     & > .MuiButton-endIcon .MuiIcon-root': {
      color: theme.uiKit[color]
    }
  };
};

const getVariantStyles = (variant: string | undefined, theme: Theme): CSSObject => {
  const variantStylesMap: Record<string, CSSObject> = {
    branded: {
      backgroundColor: theme.uiKit.buttonBrandedBackground,
      borderColor: theme.uiKit.buttonBrandedBackground,
      color: theme.uiKit.buttonBrandedColor,
      ...getButtonIconColorStyles('buttonBrandedColor', theme)
    },
    'branded-secondary': {
      borderColor: theme.uiKit.buttonBrandedBackground,
      '&:hover': getHoverStyles(variant, theme)
    },
    destructive: {
      backgroundColor: theme.uiKit.buttonDestructiveBackground,
      border: theme.uiKit.buttonDestructiveBorder,
      color: theme.uiKit.buttonDestructiveColor,
      ...getButtonIconColorStyles('buttonDestructiveColor', theme)
    },
    ghost: {
      backgroundColor: theme.uiKit.buttonGhostBackground,
      border: theme.uiKit.buttonGhostBorder,
      '&:hover': getHoverStyles(variant, theme)
    },
    'ghost-link-branded': {
      backgroundColor: theme.uiKit.buttonGhostBackground,
      border: theme.uiKit.buttonGhostBorder,
      color: theme.uiKit.linkColor
    }
  };

  return variantStylesMap[variant || ''] || {};
};

const getHoverStyles = (variant: string | undefined, theme: Theme): CSSObject => {
  const hoverStylesMap: Record<string, CSSObject> = {
    branded: {
      backgroundColor: theme.uiKit.buttonBrandedBackgroundHover,
      borderColor: theme.uiKit.buttonBrandedBackgroundHover,
      color: theme.uiKit.buttonBrandedColorHover,
      ...getButtonIconColorStyles('buttonBrandedColorHover', theme)
    },
    'branded-secondary': {
      backgroundColor: theme.uiKit.buttonBrandedBackgroundHover,
      borderColor: theme.uiKit.buttonBrandedBackgroundHover,
      color: theme.uiKit.buttonBrandedColorHover,
      ...getButtonIconColorStyles('buttonBrandedColorHover', theme)
    },
    destructive: {
      backgroundColor: theme.uiKit.buttonDestructiveBackgroundHover,
      border: theme.uiKit.buttonDestructiveBorder,
      color: theme.uiKit.buttonDestructiveColorHover,
      ...getButtonIconColorStyles('buttonDestructiveColorHover', theme)
    },
    ghost: {
      backgroundColor: theme.uiKit.buttonGhostBackground,
      borderColor: theme.uiKit.buttonBrandedBackground
    },
    'ghost-link-branded': {
      backgroundColor: theme.uiKit.buttonGhostBackground,
      borderColor: theme.uiKit.linkColor
    }
  };

  return hoverStylesMap[variant || ''] || {
    backgroundColor: theme.uiKit.buttonDefaultBackgroundHover,
    border: theme.uiKit.buttonDefaultBorderHover
  };
};

