const Marionette = require('Marionette');
const _ = require('underscore');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

class ReportsTableItem extends Marionette.ItemView {
  initialize(options = {}) {
    _.defaults(options, {linkToPage: false});
    this.mergeOptions(options, 'linkToPage');
    ({
      linkToPage: this.linkToPage,
      tableTitle: this.tableTitle,
      tableCount: this.tableCount
    } = options);
  }

  tagName() {
    return 'tr';
  }

  getTemplate() {
    return `\
      <td width="80%" align="left">
      <label class="off-screen"><%- t(tableTitle) %></label>
      <%= getName() %>
        <div class="meta-text"><%= communityName %></div>
      </td>
      <td width="20%" align="right">
      <label class="off-screen"><%- t(tableCount) %></label>
      <%- numberWithCommas(count) %>
      </td>\
    `;
  }

  className() {
    return 'table-list-item';
  }

  templateHelpers() {
    return {
      getName: () => {
        const pageId = this.model.get('page_id');
        const pageName = this.model.get('page_name') || undefined;
        const query = this.model.get('query') || undefined;
        if (this.linkToPage) {
          return `<a href="${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ pageId }">${ pageName }</a>`;
        }
        return pageName || query;
      },
      tableTitle: this.tableTitle,
      tableCount: this.tableCount
    };
  }
}

module.exports = ReportsTableItem;
