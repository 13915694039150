const { ItemView } = require('Marionette');
const logging = require('logging');
const _ = require('underscore');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');
const RecentChallengesList = require('../collections/RecentChallengesList');
class EmptyRecentChallengeListView extends UIKit.View {
  get tagName() {
    return 'li';
  }

  template = _.tpl('\
<div class="no-recent-challenges"><%- t(\'recentChallenges.emptyList\') %></div>\
');

  render() {
    this.$el.html(this.template());
    return this;
  }
}
class RecentChallengeUserView extends ItemView {
  initialize() {
    this.challenge = this.model.toJSON();
  }

  getTemplate() {
    return `
  <div class="meta-win-loss-result">
    <% if (challenge.userScore > challenge.opponentScore) { %>
      <span class="win"><%- t('recentChallenges.win') %></span>
    <% } else if (challenge.userScore < challenge.opponentScore) { %>
      <span class="loss"><%- t('recentChallenges.loss') %></span>
    <% } else { %>
      <span class="tied"><%- t('recentChallenges.tie') %></span>
    <% } %>
    <span class="against"><%- t('recentChallenges.against') %></span>
    <span class="opponent text-ellipsis fit-parent"><%= challenge.opponent.displayName %></span>
  </div>

  <div class="ax-font ax-font__caption ax-font--secondary"><%- metaText %></div>
  `
  }

  templateHelpers() {
    return {
      metaText: this._getMetaText(),
      challenge: this.challenge
    };
  }

  _getMetaText() {
    const {
      type,
      name
    } = this.model.get('game');
    const gameName = I18n.hasString(`games.${ type }`) ? I18n.t(`games.${ type }`) : name;

    // to avoid editing existing translations create an obj that translations expect
    const metaText = {
      game: { name: gameName },
      wager: this.challenge.wager
    };

    if (_.has(this.challenge, 'wager')) {
      const challengeResultKey = this.challenge.userScore > this.challenge.opponentScore ? 'wonWager' : 'lostWager';
      if (challengeResultKey === 'wonWager' || challengeResultKey === 'lostWager') {
        return I18n.t(`recentChallenges.${ challengeResultKey }`, metaText);
      }
    }

    return metaText.game.name;
  }

  get tagName() {
    return 'li';
  }
}
class RecentChallengeDetailedUserView extends RecentChallengeUserView {
  challengeResultDetailsTemplate = _.tpl(require('../templates/_challenge_result_details.html'));

  render(...args) {
    super.render(...args);
    this.$el.prepend(this.challengeResultDetailsTemplate({
      challenge: this.model.toChallengeResultJSON()
    }));

    const {
      challenger,
      challengee
    } = this.model.toChallengeResultJSON();

    this.challengerIconHandler = new ProfileIconHandler({
      user: challenger,
      profileElement: this.$('.challenger .small-name-logo')
    });
    this.challengeeIconHandler = new ProfileIconHandler({
      user: challengee,
      profileElement: this.$('.challengee .small-name-logo')
    });
    return this;
  }
}
class RecentChallengesView extends UIKit.View {
  template = _.tpl(`\
<p class="streak"><span class="streak-icon" role="presentation" aria-hidden="true"></span><span class="label"></span></p>
<h3><%- t('recentChallenges.heading') %></h3>
<ul class="recent-challenges-list"></ul>\
`);

  initialize() {
    this.recentChallengesList = new RecentChallengesList([], {
      user: window.apps.auth.session.user
    });
  }

  render() {
    this.$el.html(this.template());
    this._renderChallengesUserList();
    return this;
  }

  viewDidAppear() {
    logging.info('RecentChallengesView - viewDidAppear');
    this.recentChallengesList.fetch().done(this._renderStreakInfo.bind(this));
  }

  onClose(...args) {
    this.recentChallengesList.abortXHR(Sync.Method.READ);
    super.onClose(...args);
  }

  _renderStreakInfo() {
    if (this.isDestroyed) {
      return;
    }
    const smallStreak = 2;
    const bigStreak = 5;
    const streak = this.recentChallengesList.getUserStreak();
    if (streak >= smallStreak) {
      this.$('.streak span.streak-icon').addClass('icon-fire');
      if (streak >= bigStreak) {
        this.$('.streak').addClass('hot-streak');
        this.$('.streak span.label').text(I18n.t('recentChallenges.hotStreak'));
      } else {
        this.$('.streak').addClass('winning-streak');
        this.$('.streak span.label').text(I18n.t('recentChallenges.winningStreak'));
      }
    } else if (Math.abs(streak) >= smallStreak) {
      this.$('.streak span.streak-icon').addClass('icon-snowflake');
      if (Math.abs(streak) >= bigStreak) {
        this.$('.streak').addClass('cold-streak');
        this.$('.streak span.label').text(I18n.t('recentChallenges.coldStreak'));
      } else {
        this.$('.streak').addClass('losing-streak');
        this.$('.streak span.label').text(I18n.t('recentChallenges.coldStreak'));
      }
    } else {
      this.$('.streak').hide();
    }
  }

  _renderChallengesUserList() {
    logging.info('Rendering Recent Challenges List');
    this.challengesUserListView = new UIKit.ListView({
      disableGrid: true,
      collection: this.recentChallengesList,
      el: this.$('.recent-challenges-list'),
      EmptyView: EmptyRecentChallengeListView,
      FirstItemView: RecentChallengeDetailedUserView,
      ModelView: RecentChallengeUserView
    });
    return this.challengesUserListView.render();
  }
}
module.exports = RecentChallengesView;
