const { extend } = require('underscore');
const I18n = require('@common/libs/I18n');

const {
  Failed,
  Done
} = require('@training/apps/training/assessmentProgress/ProgressMarkerOptions');

const AbstractProgressConfig = require('@training/apps/training/assessmentProgress/AbstractProgressConfig');

const getGradableQuizMarkerIconOptions = (assessmentResult) => {
  const passed = assessmentResult.get('passed');

  if (passed === true) {
    return Done;
  } else if (passed === false) {
    return Failed;
  }
  return {};

};

const getQuizMarkerIconOptions = (assessment) => {
  const totalQuestionCount = assessment.activities.getTotalQuizActivityCount();
  const completedQuestionCount = assessment.activities.getClosedQuizActivityCount();

  if (assessment.isGradable()) {
    return getGradableQuizMarkerIconOptions(assessment.assessmentResult);
  } else if (totalQuestionCount === completedQuestionCount) {
    return Done;
  }
  return {};

};

class AssessmentQuizProgressConfig extends AbstractProgressConfig {

  static ProgressBarConfigId = 'quiz-progress-bar';

  static MarkerConfigId = 'quiz-marker';

  constructor(assessment) {
    super();

    this.assessment = assessment;
  }

  isValid() {
    return this.assessment.activities.getTotalQuizActivityCount() > 0;
  }

  getSegmentLabelText() {
    const totalQuestionCount = this.assessment.activities.getTotalQuizActivityCount();

    if (totalQuestionCount === 1) {
      return I18n.t('assessments.progress.segment.questions.singular');
    }
    return I18n.t('assessments.progress.segment.questions.plural', {count: totalQuestionCount});

  }

  getSegmentValue() {
    const totalQuestionCount = this.assessment.activities.getTotalQuizActivityCount(this.assessment);
    const completedQuestionCount = this.assessment.activities.getClosedQuizActivityCount(this.assessment);

    return completedQuestionCount / totalQuestionCount * 100;
  }

  getProgressBarOptions() {
    return {
      id: AssessmentQuizProgressConfig.ProgressBarConfigId,
      labelText: this.getSegmentLabelText(),
      value: this.getSegmentValue()
    };
  }

  getMarkerOptions() {
    return extend(getQuizMarkerIconOptions(this.assessment), { id: AssessmentQuizProgressConfig.MarkerConfigId });
  }
}

module.exports = AssessmentQuizProgressConfig;
