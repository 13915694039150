const PageableCollection = require('@common/components/pageableList/PageableCollection');
const TrainingCardModel = require('@common/components/trainingCards/TrainingCardModel');
const {
  getCardType,
  getCardActionMetaData,
  tryGetStartableAction
} = require('@common/components/trainingCards/TrainingCardsCollectionHelpers');
const CatalogBrowseTypeEnum = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogBrowseTypeEnum');

const PAGE_SIZE = 10;

class CatalogTrainingCardsCollection extends PageableCollection {

  constructor(models = [], options = {}) {
    const newOptions = Object.assign({
      state: {
        pageSize: PAGE_SIZE
      },
      fetchType: 'POST'
    }, options);

    super(models, newOptions);
    this.session = options.session;

    this.resultCount = 0;

    // Search and filter state variables
    this.searchQuery = undefined;
    this.browseType = CatalogBrowseTypeEnum.ALL;
    this.subjectList = [];
  }

  get model() {
    return TrainingCardModel;
  }

  apiEndpoint() {
    return '/training/catalog';
  }

  search(query, pageNum = 0, options = {}) {
    // Set current state of filter and search params
    if (options.browseType) {
      this.browseType = CatalogBrowseTypeEnum.assertLegalValue(options.browseType);
    } else if (!this.type) {
      this.browseType = CatalogBrowseTypeEnum.ALL;
    }
    this.subjectList = options.subjectList || [];

    this.searchQuery = query;
    const apiSearchQuery = this.searchQuery || '';

    // Set params used in API payload
    this.extraParams = {
      search: encodeURIComponent(apiSearchQuery),
      type: this.browseType,
      subjectList: (this.browseType === CatalogBrowseTypeEnum.TOPIC) ? this.subjectList : []
    };

    return this.getPage(pageNum, options);
  }

  applyFilterParams(browseType, subjectList) {
    // Set filter type and subject list, use existing search query
    this.search(this.searchQuery, 0, { browseType, subjectList });
  }

  applySearchParams(searchQuery) {
    // Set new search query, use existing filter type and subject list
    const browseType = this.browseType;
    const subjectList = this.subjectList;
    this.search(searchQuery, 0, { browseType, subjectList });
  }

  resetSearchParams() {
    // Clear search
    this.applySearchParams(null);
  }

  getQueryString() {
    return this.searchQuery;
  }

  getBrowseType() {
    return this.browseType;
  }

  getSubjectList() {
    return this.subjectList;
  }

  getResultCount() {
    return this.resultCount;
  }

  parse(data) {
    this.resultCount = data.totalSearchResults;

    data.results.forEach((card) => {
      const cardType = card[TrainingCardModel.FIELDS.CARD_TYPE];
      const action = tryGetStartableAction(card, this.session) || card[TrainingCardModel.FIELDS.ACTION];
      card[TrainingCardModel.FIELDS.CARD_TYPE] = getCardType(cardType, action);
      card[TrainingCardModel.FIELDS.CARD_ACTION_META_DATA] = getCardActionMetaData(action);
    });

    return super.parse(data);
  }
}

module.exports = CatalogTrainingCardsCollection;
