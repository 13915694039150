const logging = require('logging');

// The stub game manager is a drop in replacement for the game manager that
// can be used when a game is over. This is an implemenation of the null object
// pattern to prevent calls to a missing object crashing our application.

// Games that produce these warnings should be patched to not.
class StubGameApi {
  constructor(gameType) {
    this.gameType = gameType;
  }


  validateApiSetup() {
    this._writeStubWarning('validateApiSetup');
  }

  initGame() {
    this._writeStubWarning('initGame');
  }

  // Game data, e.g. time limit, added time per question, question count.
  getGameData() {
    this._writeStubWarning('getGameData');
    return {};
  }

  // Queue question for display
  queueQuestion() {
    this._writeStubWarning('queueQuestion');
  }

  displayQuestions() {
    this._writeStubWarning('displayQuestions');
  }

  gameOver() {
    this._writeStubWarning('gameOver');
  }

  getString(stringId, stringVars) {
    if (stringVars == null) {
      stringVars = {};
    }
    this._writeStubWarning('getString');
    return '';
  }

  updateGameState() {
    this._writeStubWarning('updateGameState');
  }

  // Callback stubs

  startGame() {
    this._writeStubWarning('startGame');
  }

  pauseGame() {
    this._writeStubWarning('pauseGame');
  }

  questionAnsweredCorrect() {
    this._writeStubWarning('questionAnsweredCorrect')({
      questionAnsweredIncorrect() {
        this._writeStubWarning('questionAnsweredIncorrect');
      }
    });
  }

  resumeGame() {
    this._writeStubWarning('resumeGame');
  }

  endGame() {
    this._writeStubWarning('endGame');
  }

  // Private definitions
  _writeStubWarning(warning) {
    logging.warn(`${ warning } invoked after game ended. ${ this.gameType } is at fault.`);
  }
}

module.exports = StubGameApi;
