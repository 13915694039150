const FilterableCommunityList = require('@common/data/collections/FilterableCommunityList');

class InsightsFilterableCommunityList extends FilterableCommunityList {
  get apiEndpoint() {
    // pageCommunities/config/search is only available for admins
    return '/pageCommunities/config/search';
  }
}

module.exports = InsightsFilterableCommunityList;
