const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');
const logging = require('logging');
const Resizable = require('@common/libs/behaviors/resizable/Resizable');
const KeyCode = require('@common/data/enums/KeyCode');
const $os = require('detectOS');
require('@common/libs/behaviors/resizable/Resizable');

const QuestionView = require('@training/apps/training/views/activities/pages/questions/QuestionView');
const MatchingQuestionOptions = require('@training/apps/training/views/activities/pages/questions/MatchingQuestionOptions');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const ZoomOverlayController = require('@common/components/image/zoomOverlay/ZoomOverlayController');
const ZoomOverlay = require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');

const ANIMATION_BUFFER_MS = 3;
const ANIMATION_TIME_TOP_HEIGHT_MS = 180;
const ANIMATION_TIME_TOP_HEIGHT_BUFFED_MS = ANIMATION_TIME_TOP_HEIGHT_MS + ANIMATION_BUFFER_MS;
const ANIMATION_TIME_LEFT_RIGHT_MS = 150;
const ANIMATION_TIME_LEFT_RIGHT_BUFFED_MS = ANIMATION_TIME_LEFT_RIGHT_MS + ANIMATION_BUFFER_MS;

class MatchingQuestionPage extends QuestionView {
  constructor(...args) {
    super(...args);
    this.template = _.tpl(require('@training/apps/training/templates/activities/pages/questions/MatchingQuestionPage.html'));
    this._optionTemplate = _.tpl(require('@training/apps/training/templates/activities/pages/questions/_matchingQuestion_option.html'));
  }

  behaviors() {
    return [
      {
        behaviorClass: Resizable
      },
      {
        behaviorClass: ZoomOverlay,
        imageWrapper: '.zoom-image-wrap.reason-image'
      }
    ];
  }

  ui() {
    return {
      questionInstruction: '.js-question-instruction',
      unpairedFirstOptionsList: '.mq-unpaired-list .mq-first-options',
      unpairedSecondOptionsList: '.mq-unpaired-list .mq-second-options',
      pairedFirstOptionsList: '.mq-paired-list .mq-first-options',
      pairedSecondOptionsList: '.mq-paired-list .mq-second-options',
      optionsArea: '.optionsarea',
      optionsPositionWrapper: '.mq-options-position-wrapper',
      a11yQuestionInstructions: '.js-mq-a11y-question-instructions',
      a11ySubmissionInstructions: '.mq-a11y-submission-instructions',
      a11yGeneralInstructions: '.mq-a11y-general-instructions',
      a11yHint: '.mq-a11y-hint'
    };
  }

  events() {
    return {
      'click .mq-option': '_onOptionClick',
      'keydown .mq-option': '_onOptionKeydown'
    };
  }

  initialize(...args) {
    super.initialize(...args);
    this._matchingQuestionOptions = new MatchingQuestionOptions({
      firstOptions: this.variant.options,
      secondOptions: this.variant.secondOptions,
      correctOptionCount: this.variant.correctOptionCount
    });
    this._question = {
      questionText: this.variant.questionText,
      displayQuestionImg: Boolean(this.variant.questionImg),
      questionImgId: this.variant.questionImg ? this.variant.questionImg.preferred.id : null
    };
    this._variantId = this.variant.id;
    this._isAnswered = false;
    this._showCorrectAnswers = false;
    this._complete = this.options.complete;
  }

  onSubmit(confidenceLevel) {
    const activityBody = {
      questionVariantId: this._variantId,
      questionOptionPairs: this._matchingQuestionOptions.getAnswerPairs(),
      confidenceLevel
    };

    this.activity.setAction('ANSWERQUESTION', activityBody, {
      success: this._handleSubmitResponse.bind(this)
    });
    return false;
  }

  render() {
    const {
      max,
      paired
    } = this._matchingQuestionOptions.getOptionCounts();
    this.setupActionBarWithConfidence();
    this.$el.html(this.template({
      questionText: this._question.questionText,
      displayQuestionImg: this._question.displayQuestionImg,
      questionImgId: this._question.questionImgId,
      correctOptionCountCurrent: paired,
      correctOptionCountMax: max
    }));
  }

  onNext() {
    if (_.isFunction(this._complete)) {
      this._complete();
    }
  }

  onRender() {
    this._renderOptions();
  }

  viewDidAppear() {
    logging.info('QuestionPage - viewDidAppear');
    super.viewDidAppear();
  }

  onResize() {
    this._updateOptionAutoHeights();
    this._adjustOptionHeights();
    this._adjustOptionTopPositions();
    this._adjustTotalHeight();
  }

  createImageViewer(media, $el) {
    const zoomViewer = this._imageViewers[media.id] = ViewControllerFactory.createLegacyView(Object.assign({
      ViewControllerClass: ZoomOverlayController,
      media: media,
      maxWidth: 120,
      maxHeight: 120,
      onClick: () => {
        this.trigger('image:clicked', media.id);
      }
    }));

    const region = this.addRegion(`imageRegion-${ media.id }`, {
      el: $el
    });

    region.show(zoomViewer);
  }

  _adjustOptionHeights() {
    const options = this._matchingQuestionOptions.getOptions();

    options.all.forEach((option) => {
      const $option = this._getOptionEl(option.id);
      $option.css('height', option.calculatedHeight);
    });
  }

  _adjustOptionTopPositions() {
    const options = this._matchingQuestionOptions.getOptions();
    let somethingWasChanged = false;

    options.all.forEach((option) => {
      const $option = this._getOptionEl(option.id);
      const startingTop = parseInt($option.css('top'), 10);
      somethingWasChanged = somethingWasChanged || (startingTop !== option.topPosition);
      $option.css('top', option.topPosition);
    });
    return somethingWasChanged;
  }

  _adjustOptionPairedPositions() {
    const options = this._matchingQuestionOptions.getOptions();
    let somethingWasChanged = false;

    options.all.forEach((option) => {
      const $option = this._getOptionEl(option.id);

      if (option.pairedOptionId && !this._isOptionElPaired($option)) {
        somethingWasChanged = true;
        this._pairOptionEl($option);
      }
      if (!option.pairedOptionId && this._isOptionElPaired($option)) {
        somethingWasChanged = true;
        this._unpairOptionEl($option);
      }
    });
    return somethingWasChanged;
  }

  _adjustOptionUnpairedPositions() {
    const options = this._matchingQuestionOptions.getOptions();
    let somethingWasChanged = false;

    options.all.forEach((option) => {
      const $option = this._getOptionEl(option.id);

      if (!option.pairedOptionId && this._isOptionElPaired($option)) {
        somethingWasChanged = true;
        this._unpairOptionEl($option);
      }
    });
    return somethingWasChanged;
  }

  _adjustTotalHeight() {
    this.ui.optionsPositionWrapper.height(this._matchingQuestionOptions.getTotalCalculatedHeight());
  }

  _animateNewPositioning({
    afterTopChangedCb,
    finalCb
  } = {}) {
    const cssTransitionSupported = BrowserHelpers.cssTransitionSupported();
    this._enableAnimations();

    const unpairedChanged = this._adjustOptionUnpairedPositions();
    const unpairedChangedTimeout = cssTransitionSupported && unpairedChanged ? ANIMATION_TIME_LEFT_RIGHT_BUFFED_MS : 0;

    setTimeout(() => {
      this._adjustTotalHeight();
      this._adjustOptionHeights();
      const topChanged = this._adjustOptionTopPositions();
      const topChangedTimeout = cssTransitionSupported && topChanged ? ANIMATION_TIME_TOP_HEIGHT_BUFFED_MS : 0;

      setTimeout(() => {
        if (afterTopChangedCb) {
          afterTopChangedCb();
        }
        const pairedChanged = this._adjustOptionPairedPositions();
        const pairedChangedTimeout = cssTransitionSupported && pairedChanged ? ANIMATION_TIME_LEFT_RIGHT_BUFFED_MS : 0;

        setTimeout(() => {
          this._disableAnimations();
          this._reorderOptionEls();
          if (finalCb) {
            finalCb();
          }
        }, pairedChangedTimeout);

      }, topChangedTimeout);

    }, unpairedChangedTimeout);
  }

  _disableAnimations() {
    this._getAllOptionEls().css('transition', 'initial');
  }

  _disableOptionEls() {
    this._getAllOptionEls().attr('aria-disabled', 'true')
      .prop('disabled', true)
      .attr('tabindex', 'false');
  }

  _enableAnimations() {
    this._getAllOptionEls().css(
      'transition',
      `top ${ ANIMATION_TIME_TOP_HEIGHT_MS }ms, height ${ ANIMATION_TIME_TOP_HEIGHT_MS }ms, left ${ ANIMATION_TIME_LEFT_RIGHT_MS }ms, right ${ ANIMATION_TIME_LEFT_RIGHT_MS }ms`
    );
  }

  _getAllOptionEls() {
    return this.ui.optionsArea.find('.mq-option');
  }

  _getMultipleOptionEls(isSelected, isFirstOption) {
    const selectorSide = isFirstOption ? '.mq-first-options' : '.mq-second-options';
    const selectorSelected = isSelected ? '.mq-selected' : '';

    return this.$(`${ selectorSide } .mq-option${ selectorSelected }`);
  }

  _getOptionEl(optionId) {
    return this.$('#' + this._getOptionHtmlId(optionId));
  }

  _getOptionHtmlId(optionId) {
    return `mq-option-${ optionId }`;
  }

  _getOptionIdFromEl($option) {
    return $option.data('id');
  }

  _getRtlDirectionKeyCode(keyCode) {
    const isRtl = I18n.isCurrentLanguageRtl();
    if (isRtl && keyCode === KeyCode.LEFT) {
      return KeyCode.RIGHT;
    }

    if (isRtl && keyCode === KeyCode.RIGHT) {
      return KeyCode.LEFT;
    }

    return keyCode;
  }

  _getUnpairedOptionEls() {
    return this.$('.unpaired .mq-option');
  }

  _handleSubmitResponse(actionResponse) {
    const { hideAnswersAndReason } = this.options;
    this.hasGotServerResponse = true;
    if (hideAnswersAndReason) {
      this.next();
    } else {
      const question = actionResponse.answer.question.variants[0];
      const responseOptions = question.options;
      const { isAnsweredCorrectly } = actionResponse.answer;
      const pointsEarned = actionResponse.pointsEarned || 0;

      if (isAnsweredCorrectly) {
        this._handleSubmitResponseCorrect(question);
      } else {
        this._handleSubmitResponseIncorrect(question);
      }

      this.ui.optionsArea.addClass('mq-answered');
      this._isAnswered = true;
      this._getAllOptionEls().removeClass('mq-selected')
        .prop('title', '');

      const a11yFeedbackString = isAnsweredCorrectly ? null : I18n.t('question.accessibility.matchingQuestion.feedbackInstructions');
      this.showPointsArea(isAnsweredCorrectly, pointsEarned, a11yFeedbackString);
      this.scrollResultBannerIntoView(this.setFocusOnCorrectIncorrect);
      window.apps.auth.session.user.addPoints(pointsEarned);

      responseOptions.forEach(({
        id,
        secondId,
        correct
      }, index) => {
        if (correct) {
          this._matchingQuestionOptions.setCorrectlyMatchedPairs(id, secondId, index);
        }
      });

      this._updateOptionElsCorrectness();
      this._disableOptionEls();
      this.onResize();
    }
  }

  _handleSubmitResponseCorrect(question) {
    this.setupAndShowActionBarWithContinue();

    if (this.gameManager != null) {
      this.gameManager.questionAnsweredCorrect();
    }

    this._updateCoachHintCorrect();
    this.createReason(question);
  }

  _handleSubmitResponseIncorrect(question) {
    this.actionBar.setActionBar({
      title: '',
      buttons: {
        type: 'showcorrectmatches',
        onClick: this._showCorrectMatches.bind(this, question)
      }
    });

    if (this.gameManager != null) {
      this.gameManager.questionAnsweredIncorrect();
    }

    this._updateCoachHintIncorrect();
  }

  _isOptionElFirst($option) {
    const $parentUl = $option.closest('ul');
    return $parentUl.hasClass('mq-first-options');
  }

  _isOptionElPaired($option) {
    return $option.hasClass('mq-paired-option');
  }

  _isOptionElSelected($option) {
    return $option.hasClass('mq-selected');
  }

  _onOptionClick(e) {
    if (this._isAnswered) {
      return;
    }
    const $target = $(e.target);

    const $option = $target.closest('.mq-option');

    if (this._isOptionElSelected($option)) {
      this._unselectOptionEl($option);
      this.ui.a11yHint.text(I18n.t('question.accessibility.matchingQuestion.hintUnselected'));
      return;
    }

    const $oppositeSideSelectedOption = this._getMultipleOptionEls(true, !this._isOptionElFirst($option));

    if ($oppositeSideSelectedOption.length) {
      this._unselectOptionEl($oppositeSideSelectedOption);
      this._pairOptionEls($oppositeSideSelectedOption, $option);
      this.ui.a11yHint.text('');
      return;
    }

    const isFirstOption = this._isOptionElFirst($option);
    const $sameSideOptions = this._getMultipleOptionEls(false, isFirstOption);
    this._unselectOptionEl($sameSideOptions);
    this._selectOptionEl($option);
    this.ui.a11yHint.text(isFirstOption
      ? I18n.t('question.accessibility.matchingQuestion.hintSelectSecondItem')
      : I18n.t('question.accessibility.matchingQuestion.hintSelectFirstItem'));
  }

  _onOptionKeydown(e) {
    if ($(e.target).hasClass('imgwrap')) {
      return;
    }

    if ([KeyCode.LEFT, KeyCode.RIGHT, KeyCode.UP, KeyCode.DOWN].includes(e.which)) {
      const keyCode = this._getRtlDirectionKeyCode(e.which);
      const $option = $(e.target).closest('.mq-option');
      const nextOption = this._matchingQuestionOptions.tryGetOptionNextTo(
        this._getOptionIdFromEl($option),
        keyCode
      );
      if (nextOption) {
        $('#' + this._getOptionHtmlId(nextOption.id)).trigger('focus');
      }
    }
    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      e.preventDefault();
      this._onOptionClick(e);
    }
  }

  _pairOptionEl($option) {
    $option.addClass('mq-paired-option');
  }

  _pairOptionEls($optionA, $optionB) {
    const keyboardUsed = $optionA.hasClass('focus-visible') || $optionB.hasClass('focus-visible');
    const listToFocus = this.ui.unpairedFirstOptionsList;

    this._matchingQuestionOptions.pairOptions(this._getOptionIdFromEl($optionA), this._getOptionIdFromEl($optionB));

    $optionA.add($optionB).addClass('mq-animate');

    const afterTopChangedCb = function() {
      this.$('.mq-option.mq-animate').removeClass('mq-animate'); // this css class was used to force paired items to "snap out" in some cases
    }.bind(this);

    const finalCb = function() {
      if (keyboardUsed) {
        listToFocus.find('.mq-option').first()
          .trigger('focus');
      }
      this._refreshAnswerCount();
    }.bind(this);

    this._animateNewPositioning({
      afterTopChangedCb,
      finalCb
    });
  }

  _refreshAnswerCount() {
    const {
      paired,
      max
    } = this._matchingQuestionOptions.getOptionCounts();

    if (paired >= max) {
      this.showActionBarWithConfidence();
      this.ui.a11ySubmissionInstructions.hide();
      this.ui.a11yGeneralInstructions.hide();
      this.ui.a11yHint.hide();
    } else {
      this.hideActionBar();
      this.ui.a11ySubmissionInstructions.show();
      this.ui.a11yGeneralInstructions.show();
      this.ui.a11yHint.show();
    }

    this.ui.questionInstruction.text(`${ paired } ${ I18n.t('question.numberseperator') } ${ max }`);
    this.ui.a11yQuestionInstructions.text(I18n.t('question.accessibility.matchingQuestion.questionInstructions', {
      paired: paired,
      max: max
    }));
  }

  _renderOptions() {
    const options = this._matchingQuestionOptions.getOptions();
    [
      {
        $ulEl: this.ui.pairedFirstOptionsList,
        innerOptions: options.paired.first
      },
      {
        $ulEl: this.ui.pairedSecondOptionsList,
        innerOptions: options.paired.second
      },
      {
        $ulEl: this.ui.unpairedFirstOptionsList,
        innerOptions: options.unpaired.first
      },
      {
        $ulEl: this.ui.unpairedSecondOptionsList,
        innerOptions: options.unpaired.second
      }
    ].forEach(({
      $ulEl,
      innerOptions
    }) => {

      $ulEl.empty();
      innerOptions.forEach((option) => {
        const $liEl = $(this._optionTemplate({
          id: option.id,
          htmlId: this._getOptionHtmlId(option.id),
          displayText: Boolean(option.text) || $os.ios, // ios and voiceover need the text element (even if empty) in order to select option
          text: option.text,
          displayImg: Boolean(option.imgId),
          imgId: option.imgId
        }));
        $ulEl.append($liEl);
      }, this);

    });
  }

  _reorderOptionEls() {
    const options = this._matchingQuestionOptions.getOptions();
    [
      {
        innerOptions: options.paired.first,
        $ul: this.ui.pairedFirstOptionsList
      },
      {
        innerOptions: options.paired.second,
        $ul: this.ui.pairedSecondOptionsList
      },
      {
        innerOptions: options.unpaired.first,
        $ul: this.ui.unpairedFirstOptionsList
      },
      {
        innerOptions: options.unpaired.second,
        $ul: this.ui.unpairedSecondOptionsList
      }
    ].forEach(({
      $ul,
      innerOptions
    }) => {
      innerOptions.forEach((option) => {
        const $li = this._getOptionEl(option.id).closest('li')
          .detach();
        $ul.append($li);
      });
    });
  }

  _selectOptionEl($option) {
    $option.addClass('mq-selected');
    $option.attr('aria-pressed', 'true');
  }

  _showCorrectMatches(question) {
    this._matchingQuestionOptions.pairCorrectOptions();
    this._updateOptionElsCorrectness();

    this.onResize();
    this._adjustOptionPairedPositions();
    this._adjustOptionUnpairedPositions();
    this._adjustTotalHeight();
    this._reorderOptionEls();

    this.createReason(question);
    this.setupAndShowActionBarWithContinue();
  }

  _unpairOptionEl($option) {
    $option.removeClass('mq-paired-option');
  }

  _unselectOptionEl($option) {
    $option.removeClass('mq-selected');
    $option.attr('aria-pressed', 'false');
  }

  _updateCoachHintCorrect() {
    const altText = I18n.t('coaches.correctAnswer');
    this.$('.bonuscharacter div, .hinttext').removeClass('ponder incorrect')
      .addClass('correct');
    this.$('.bonuscharacter div').attr('aria-label', altText);
  }

  _updateCoachHintIncorrect() {
    const altText = I18n.t('coaches.incorrectAnswer');
    this.$('.bonuscharacter div, .hinttext').removeClass('ponder correct')
      .addClass('incorrect');

    this.$('.bonuscharacter div, .hinttext').addClass('empty');

    this.$('.bonuscharacter div').attr('aria-label', altText);
  }

  _updateOptionAutoHeights() {
    const $answerOptions = this._getAllOptionEls();
    $answerOptions.css('height', 'auto');

    $answerOptions.each((index, option) => {
      const $option = $(option);
      const id = this._getOptionIdFromEl($option);
      const height = $option.innerHeight();
      this._matchingQuestionOptions.updateAutoHeight(id, height);
    });
  }

  _updateOptionElsCorrectness() {
    const options = this._matchingQuestionOptions.getOptions().all;
    options.forEach((option) => {
      this._getOptionEl(option.id).addClass(option.isCorrect ? 'mq-correct' : 'mq-incorrect')
        .removeClass(option.isCorrect ? 'mq-incorrect' : 'mq-correct');
    });
  }

}

module.exports = MatchingQuestionPage;
