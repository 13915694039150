const { LayoutView } = require('Marionette');
const _ = require('underscore');
const KeyCode = require('@common/data/enums/KeyCode');
class CheckboxGroup extends LayoutView {
  events() {
    return {
      'change .listselectall input[type="checkbox"]': 'changeAll',
      'focus .listselectall input[type="checkbox"]': 'onCheckboxFocus',
      'blur .listselectall input[type="checkbox"]': 'onCheckboxBlur',
      'change .selectableList input': 'unsetChangeAll',
      'focus .selectableList input[type=checkbox]': 'onCheckboxFocus',
      'blur .selectableList input[type=checkbox]': 'onCheckboxBlur',
      'keydown .searchstring input': 'onSearchKeydown',
      'keyup .searchstring input': 'filterList',
      'click .js-clear': 'clearFilter'
    };
  }

  initialize(options = {}) {
    super.initialize(options);
    this._exclusionList = [];

    this.filterFunction = options.filterFunction;
    this.collection = options.collection;
    this._validateParameters();

    this.$('.js-clear').toggleClass('hidden', !this.$('.searchstring input').val());
  }

  _validateParameters() {
    if (!this.collection) {
      throw new Error(('A collection must be specified!'));
    }
  }

  onSearchKeydown(e) {
    if (e.which === KeyCode.ENTER) {
      e.preventDefault();
    }
  }

  onCheckboxFocus(e) {
    const $checkbox = $(e.target);
    $checkbox.parent().toggleClass('keyboard-focus-boxshadow', $checkbox.hasClass('focus-visible'));
  }

  onCheckboxBlur(e) {
    const $checkbox = $(e.target);
    $checkbox.parent().removeClass('keyboard-focus-boxshadow');
  }

  checkAll() {
    this.$('.listselectall input[type="checkbox"]').prop('checked', 'checked');
    this.setChangeAll(true);
  }

  unsetChangeAll() {
    const checkboxesAllChecked = this.verifyAllChecked();
    this.$('.listselectall input[type="checkbox"]')[0].checked = checkboxesAllChecked;
    this.trigger('checkboxGroup:updated', checkboxesAllChecked, this.verifyAllUnchecked());
  }

  uncheckAll() {
    this.$('.listselectall input[type="checkbox"]').prop('checked', false);
    this.setChangeAll(false);
  }

  allChecked() {
    return this.$('.listselectall input[type="checkbox"]')[0].checked;
  }

  changeAll(e) {
    this.setChangeAll(e.currentTarget.checked);
    this.trigger('checkboxGroup:updated', this.allChecked(), this.verifyAllUnchecked());
  }

  setChangeAll(checked) {
    if (checked) {
      this._updateListOptions(checked);
    } else {
      this.$('.selectableList input[type="checkbox"]').each((index, $el) => {
        if (!$el.disabled) {
          $el.checked = false;
        }
      });
    }
  }

  verifyAllChecked() {
    return this.$('.selectableList input[type="checkbox"]').not(':checked').length === 0;
  }

  verifyAllUnchecked() {
    return this.$('.selectableList input[type="checkbox"]:checked').length === 0;
  }

  filterList() {
    if ((typeof this.filterFunction !== 'function')) {
      throw new Error(('A filter function and a collection must be specified'));
    }

    this._updateFilterDisplay();
    this._updateListOptions();
  }

  clearFilter() {
    this.$('.searchstring input').val('');
    this.filterList();
  }

  setExclusionList(exclusions) {
    this._exclusionList = exclusions;
    this._updateListOptions();
    this.trigger('checkboxGroup:updated', this.allChecked(), this.verifyAllUnchecked());
  }

  _updateListOptions(allChecked = this.allChecked()) {
    const rawSearchInput = this.$('.searchstring input').val() || '';
    const searchString = rawSearchInput.trim();
    const $listElements = this.$el.find('.selectableList li');
    const filteredIdList = _.map(this._getFilteredIdList(searchString), (item) => {
      return item.get('id').toString();
    });

    $listElements.each((i, element) => {
      const $el = $(element);
      const $checkbox = $el.find('input[type="checkbox"]');
      const itemId = $checkbox.attr('id');
      const isExcluded = this._exclusionList.includes(itemId);
      const isVisible = filteredIdList.includes(itemId) && !isExcluded;

      $(element).toggle(isVisible);
      if (isExcluded) {
        $checkbox.prop('checked', false);
      } else if (allChecked && !$checkbox.prop('disabled')) {
        $checkbox.prop('checked', true);
      }
    });
  }

  _getFilteredIdList(value) {
    if (value !== '') {
      const filterTextList = value.toLowerCase().split(/\s/);
      return _.filter(this.collection.toArray(), (item) => {
        const filter = _.partial(this.filterFunction, item);
        return _.some(filterTextList, (filterFragment) => {
          return filter(filterFragment);
        });
      });
    }
    return this.collection.toArray();

  }

  _updateFilterDisplay() {
    const $input = this.$('.searchstring input');
    const value = $input.val().trim();
    $input.toggleClass('has-text', value !== '');

    this.$('.js-clear').toggleClass('hidden', !value.length > 0);
  }
}

module.exports = CheckboxGroup;
