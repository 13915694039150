import type UserType from '@common/data/types/User';
import type {
  ImageMediaType,
  ImageSizes
} from '@common/libs/file/FileType';
import StringHelpers from '@common/libs/helpers/types/StringHelpers';
import { AX_AVATAR_COLORS } from '@common/modules/react/themes/components/data-display/AxAvatar/helper';
import {
  find,
  some,
  without
} from 'underscore';

export function getColorByNumber(number?: number) {
  // make sure the number is a number or return
  // undefined otherwise
  if (number == null || isNaN(number)) {
    return undefined;
  }
  
  // make sure the number is positive
  const absNumber = Math.abs(number);
    
  const index = absNumber % (AX_AVATAR_COLORS.length);
  
  // return the color
  return AX_AVATAR_COLORS[index];
}
  
export function getPhotoUrl(user: Pick<UserType, 'profileImage'>) {
  if ((user.profileImage != null) && hasValidProfileImageSize(user.profileImage)) {
    let profileImage;
    const pixelDensity = window.devicePixelRatio || 1;
  
    if (pixelDensity >= 1.3) {
      profileImage = _getBestImageSize(user.profileImage.sizes, 3);
    } else {
      profileImage = _getBestImageSize(user.profileImage.sizes, 2);
    }
  
    return profileImage?.file.path;
  }

  return undefined;
}
  
export function getUserName(user: Partial<UserType & { user: Partial<UserType> }>) {
  let userName = '';

  if (user.salutationName) {
    userName = user.salutationName;
  } else if (user.nickname) {
    userName = user.nickname;
  } else if (user.fullname) {
    userName = user.fullname;
  } else if (user.user && user.user.fullname) {
    userName = user.user.fullname;
  } else if (user.name) {
    userName = user.name;
  }

  return userName;
}

export function getUserInitials(user: Partial<UserType>) {
  const userName = getUserName(user);
  return StringHelpers.getInitials(userName);
}

export function hasValidProfileImageSize(profileImage: ImageMediaType) {
  return some(profileImage.sizes, (size: ImageSizes) => {
    return size.status === 'done';
  });
}

function _getBestImageSize(sizesData: ImageSizes[], preferredSizeIndex: number) {
  // If the size we want is available, we should use that
  const preferredSize = sizesData[preferredSizeIndex];
  if (preferredSize && preferredSize.status === 'done') {
    return preferredSize;
  }

  // Otherwise, let's traverse downwards until we find something we can use
  // even if it not the optimal resolution
  const leftovers = without(sizesData, preferredSize);
  return find(leftovers, (size: ImageSizes) => {
    return size.status === 'done';
  });
}
