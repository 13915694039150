const logging = require('logging');
const {
  isNaN,
  isNumber,
  map,
  findWhere,
  get
} = require('underscore');
const { Model } = require('Backbone');

const UserType = require('@common/data/enums/UserType');
const MobileDeviceList = require('@common/data/collections/MobileDeviceList');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const FilterableAttributesEnum = require('../enums/FilterableAttributesEnum');
const DefaultAdminAppRoutingPermissions = require('@common/data/models/DefaultAdminAppRoutingPermissions').default;

const defaultCoach = {
  coachType: 'NoCoach'
};

class AuthUser extends Model {

  url() {
    return '/axonify/selfserve/user';
  }

  defaults() {
    return {
      escrowPoints: 0,
      pointsBalance: 0,
      pointsTotal: 0,
      isTeamMetricsEnabled: true,
      coach: null,
      userType: UserType.User,
      atWorkRequired: false,
      trainingPaywallEnabled: false,
      isOnTheClock: true,
      permissions: []
    };
  }

  constructor(attrs = {}, options = {}) {
    super(attrs, options);

    this.currentMobileDevice = this.currentMobileDevice.bind(this);
    this.unregisterCurrentDevice = this.unregisterCurrentDevice.bind(this);

    this.options = options;

    this.mobileDevices = new MobileDeviceList();
    this.listenTo(this, 'change:mobileDevices', this.onMobileDeviceListChanged);
  }

  parse(res = {}) {
    return res.entity ?? res;
  }

  set(key, val, options = {}) {
    if (key == null) {
      return this;
    }

    let attrs;

    if (typeof key === 'object') {
      attrs = key;
      // eslint-disable-next-line no-param-reassign
      options = val;
    } else {
      (attrs = {})[key] = val;
    }

    if (attrs.mobileDevices) {
      this.mobileDevices = new MobileDeviceList();
      this.mobileDevices.reset(attrs.mobileDevices, {parse: true});
    }

    if (UserType.hasAdminGroupAccess(attrs.userType ?? this.get('userType')) && get(attrs, 'permissions.length', 0) === 0) {
      attrs.permissions = DefaultAdminAppRoutingPermissions;
    }

    return super.set(attrs, options);
  }

  onMobileDeviceListChanged(model, array) {
    logging.info('User mobile device list changed.');
    this.mobileDevices.reset(array, {parse: true});
  }

  // return the mobile device currently tied to this user, if the app is being used inside of a native wrapper.
  currentMobileDevice() {
    return (this.mobileDevices != null ? this.mobileDevices.where({current: true})[0] : undefined);
  }

  // Unregisters the user's mobile device and then executes the callback function passed in.
  unregisterCurrentDevice(callback) {
    if (!this.currentMobileDevice()) {
      callback();
    } else {
      this.currentMobileDevice().destroy({
        success: () => {
          return callback();
        },
        error: () => {
          return callback();
        }
      });
    }
  }

  getUserType() {
    return this.get('userType');
  }

  getCoachType() {
    const coach = this.get('coach') || defaultCoach;
    return coach.coachType;
  }

  isAxonifySuperuser() {
    return this.get('isAxonifySuperuser') === true;
  }

  isSuperuser() {
    return this.get('isSuperuser') === true;
  }

  isTechAdminUser() {
    return UserType.isTechAdminUser(this.getUserType());
  }

  isAdminUser() {
    return UserType.isAdminUser(this.getUserType());
  }

  isGuestUser() {
    return UserType.isDZGuestUser(this.getUserType())
  }

  isGuestOrSuperuser() {
    return UserType.isDZGuestUser(this.getUserType()) || this.isSuperuser();
  }

  isBuAdminUser() {
    return UserType.isBuAdminUser(this.getUserType());
  }

  isUserUser() {
    return UserType.isUserUser(this.getUserType());
  }

  isSelfRegisteredUser() {
    return UserType.isSelfRegisteredUser(this.getUserType());
  }

  isManagerUser() {
    return UserType.isManagerUser(this.getUserType());
  }

  isContentUser() {
    return UserType.isContentUser(this.getUserType());
  }

  isBuContentUser() {
    return UserType.isBuContentUser(this.getUserType());
  }

  isReportUser() {
    return UserType.isReportUser(this.getUserType());
  }

  isAdminAccessBlockedForReportUsers() {
    return TenantPropertyProvider.get().getProperty('blockAdminAccessForReportUsers');
  }

  hasContentExchangeAccess() {
    return TenantPropertyProvider.get().getProperty('contentExchangeEnabled')
    && !this.isSuperuser()
    && UserType.hasContentExchangeAccess(this.getUserType())
    && this.hasFlexAdminAccess('trainingContent');
  }

  isFacilitatorUser() {
    return UserType.isFacilitatorUser(this.getUserType());
  }

  hasAdminAppAccess() {
    return UserType.hasAdminAppAccess(this.getUserType());
  }

  hasStrictAdminAppAccess() {
    return UserType.hasStrictAdminAppAccess(this.getUserType());
  }

  hasTrainingAppAccess() {
    return UserType.hasTrainingAppAccess(this.getUserType());
  }

  hasManagerAppAccess() {
    return UserType.hasManagerAppAccess(this.getUserType()) && this.hasFlexAdminAccess('reporting');
  }

  hasKnowledgeAppAccess() {
    return UserType.hasKnowledgeAppAccess(this.getUserType());
  }

  hasAdminGroupAccess() {
    return UserType.hasAdminGroupAccess(this.getUserType());
  }

  hasReportGroupAccess() {
    return UserType.hasReportGroupAccess(this.getUserType());
  }

  hasFeedbackGroupAccess() {
    return UserType.hasFeedbackGroupAccess(this.getUserType());
  }

  hasEventsAdminGroupAccess() {
    return UserType.hasEventsAdminGroupAccess(this.getUserType());
  }

  hasContentGroupAccess() {
    return UserType.hasContentGroupAccess(this.getUserType());
  }

  hasSettingsGroupAccess() {
    return UserType.hasSettingsGroupAccess(this.getUserType());
  }

  hasSupportGroupAccess() {
    return UserType.hasSupportGroupAccess(this.getUserType());
  }

  hasUserImporterGroupAccess() {
    return UserType.hasUserImporterGroupAccess(this.getUserType());
  }

  hasUserImporterAccess() {
    return ((TenantPropertyProvider.get().getProperty('allowUserFileImportByManager') && this.hasUserImporterGroupAccess) || this.isAdminUser())
      && this.hasFlexAdminAccess('editAdmin')
  }

  hasHistoricalImportAccess() {
    return TenantPropertyProvider.get().getProperty('historicalLearningRecordsImporterEnabled')
      && this.isAdminUser();
  }

  hasUsersGroupAccess() {
    return UserType.hasUsersGroupAccess(this.getUserType());
  }

  canSetAllReportCustomAccess() {
    return UserType.canSetAllReportCustomAccess(this.getUserType());
  }

  canAccessContentReportExport() {
    return UserType.canAccessContentReportExport(this.getUserType());
  }

  canAccessContentUsageReportExport() {
    return UserType.canAccessContentUsageReportExport(this.getUserType());
  }

  canImpersonateUsers() {
    return UserType.canImpersonateUsers(this.getUserType());
  }

  canImpersonateAdmins() {
    return this.canImpersonateUsers() && this.hasFlexAdminAccess('editAdmin');
  }

  canEditAdminUsers() {
    return this.hasFlexAdminAccess('editAdmin');
  }

  hasChatAccess() {
    return this.hasTrainingAppAccess()
      && !this.isGuestUser()
      && !this.isSuperuser();
  }

  checkTimezoneOffset() {
    const deferred = $.Deferred();

    // We only want to check the timezone offset if this is a "set" type of call
    // with user data (e.g. `timezoneOffset`) so check `isNew`
    if (this.isNew()) {
      deferred.reject();
    } else {
      const timezoneOffset = (new Date()).getTimezoneOffset();

      if (timezoneOffset === this.get('timezoneOffset')) {
        deferred.resolve();
      } else {
        $.ajax({
          skipGlobalHandler: true,
          type: 'PUT',
          url: '/axonify/selfserve/tz',
          shouldRetry: false,
          data: JSON.stringify({timezoneOffset}),
          success: () => {
            this.set({timezoneOffset}, {silent: true});
            return deferred.resolve();
          },
          error() {
            logging.error('Error setting `timezoneOffset`');
            return deferred.reject();
          }
        });
      }
    }

    return deferred.promise();
  }

  availablePoints() {
    const availablePoints = this.get('pointsBalance') - this.get('escrowPoints');
    return Math.max(availablePoints, 0);
  }

  addPoints(points) {
    if (isNaN(points) || !isNumber(points)) {
      logging.warn('Tried to add a non numeric value to the users points totals! Ignoring...');
      return;
    }

    this.set({pointsBalance: this.get('pointsBalance') + points});
    this.set({pointsTotal: this.get('pointsTotal') + points});
  }

  subtractPoints(points) {
    if (isNaN(points) || !isNumber(points)) {
      logging.warn('Tried to subtract a non numeric value from the users points balance! Ignoring...');
      return;
    }

    this.set({pointsBalance: this.get('pointsBalance') - points});
  }

  setEscrowPoints(points) {
    if (isNaN(points) || !isNumber(points)) {
      logging.warn('Tried to set a non numeric value to the users escrow points! Ignoring...');
      return;
    }

    this.set({escrowPoints: points});
  }

  isLoggedIn() {
    return this.has('id');
  }

  toJSON(...args) {
    const JSON = super.toJSON(...args);
    JSON.displayName = this.get('salutationName');
    return JSON;
  }

  getPreferredDzLanguagesAsObjectArray() {
    return map(this.get('preferredDzLanguages'), (element) => {
      return {id: element};
    });
  }

  hasDashboardAccess() {
    return this.hasAdminGroupAccess();
  }

  hasEventsAccess() {
    return TenantPropertyProvider.get().getProperty('eventManagementEnabled')
      && this.hasEventsAdminGroupAccess()
      && this.hasFlexAdminAccess('trainingEvents');
  }

  hasExportsPageAccess() {
    if (this.isReportUser() && this.isAdminAccessBlockedForReportUsers()) {
      return false;
    }

    return UserType.hasExportsAccess(this.getUserType());
  }

  hasSubscriptionExportsTabAccess() {
    return UserType.hasExportSubscriptionsAccess(this.getUserType());
  }

  hasScheduledExportsTabAccess() {
    return UserType.hasScheduledExportsAccess(this.getUserType()) && this.hasFlexAdminAccess('reporting');
  }

  hasFeedbackAccess() {
    return this.hasFeedbackGroupAccess() && this.hasFlexAdminAccess('trainingContent');
  }

  hasGroupsAccess() {
    return this.hasAdminGroupAccess() && this.hasFlexAdminAccess('groups');
  }

  hasProgramsAccess() {
    return this.hasAdminGroupAccess() && this.hasFlexAdminAccess('trainingPrograms');
  }

  hasSelfDirectedAccess() {
    return this.hasAdminGroupAccess() && this.hasFlexAdminAccess('trainingPrograms');
  }

  hasUsersAccess() {
    return this.hasUsersGroupAccess() && this.hasFlexAdminAccess('users');
  }

  hasRewardsAccess() {
    return this.hasAdminGroupAccess() && this.hasFlexAdminAccess('gamesAndRewards');
  }

  hasClassicReportsAccess() {
    const customAccessFilter = this.get('reportAuthGroup')
    if (this.isReportUser() && this.isAdminAccessBlockedForReportUsers()
    || customAccessFilter && customAccessFilter[FilterableAttributesEnum.GroupTeamAttributes]?.length) {
      return false;
    }

    return TenantPropertyProvider.get().getProperty('classicReportsEnabled')
    && this.hasReportGroupAccess()
    && this.hasFlexAdminAccess('reporting');
  }

  hasNewReportsAccess() {
    if (this.isReportUser() && this.isAdminAccessBlockedForReportUsers()) {
      return false;
    }

    return TenantPropertyProvider.get().getProperty('newReportPageEnabled')
      && this.hasReportGroupAccess()
      && this.hasFlexAdminAccess('reporting');
  }

  hasGamesAccess() {
    return (this.isAdminUser() && this.hasFlexAdminAccess('gamesAndRewards'));
  }

  hasContentAccess() {
    return (window.apps.auth.session.get('shouldShowContent') === true
      || this.hasContentGroupAccess())
      && this.hasFlexAdminAccess('trainingContent')
      && !this.isTechAdminUser();
  }

  hasSettingsAccess() {
    return this.hasSettingsGroupAccess()
      && (this.hasFlexAdminAccess('platformSettings')
        || this.hasFlexAdminAccess('gamesAndRewards')
        || this.hasFlexAdminAccess('trainingContent')
        || this.hasFlexAdminAccess('communications')
        || this.hasFlexAdminAccess('operations'))
        || this.hasTechnicalConfigurationsAccess();
  }

  hasSupportAccess() {
    return UserType.hasSupportGroupAccess(this.getUserType());
  }

  hasImpactsAccess() {
    return window.apps.auth.session.canAccessImpacts()
    && this.isAdminUser()
    && this.hasFlexAdminAccess('reporting');
  }

  hasCommunityManagementAccess() {
    return this.isAdminUser() && this.hasFlexAdminAccess('discover');
  }

  hasDiscoverReportingAccess() {
    return this.isAdminUser() && this.hasFlexAdminAccess('reporting');
  }

  hasFlexAdminAccess(area) {
    //non admin user types will always return true, as they can not have features disabled in Flex Admin settings
    if (!this.hasAdminGroupAccess()) {
      return true;
    }

    const permissionsList = this.get('permissions');
    const permission = findWhere(permissionsList, {id: area})
    return permission?.enabled ?? false;
  }

  hasFeedbackOpenCountAccess() {
    return UserType.hasFeedbackOpenCountAccess(this.getUserType());
  }

  hasNudgeAccess() {
    return TenantPropertyProvider.get().getProperty('integrationBetaFeaturesEnabled')
      && (TenantPropertyProvider.get().getProperty('executionPlatformEnabled') || TenantPropertyProvider.get().getProperty('communicationsPlatformEnabled'))
      && (this.isAdminUser()
      && !this.isSuperuser()
      && (this.hasFlexAdminAccess('communications') || this.hasFlexAdminAccess('operations')));
  }

  hasAppzonesSettingsAccess() {
    return this.isAdminUser()
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasApiManagementSettingsAccess() {
    return this.hasTechnicalConfigurationsAccess()
      && this.hasFlexAdminAccess('platformSettings')
  }

  hasAuthenticationSettingsAccess() {
    return TenantPropertyProvider.get().getProperty('authenticationSchemes')
      && this.hasTechnicalConfigurationsAccess()
      && this.hasFlexAdminAccess('platformSettings')
  }

  hasBehavioursAndInspectionsSettingsPageAccess() {
    return (TenantPropertyProvider.get().getProperty('behavioursEnabled') || TenantPropertyProvider.get().getProperty('reviewsEnabled'))
      && (this.isAdminUser() || this.isBuAdminUser())
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('operations') || this.hasFlexAdminAccess('trainingContent'));
  }

  hasBehaviourTemplatesSettingsTabAccess() {
    return this.hasBehavioursAndInspectionsSettingsPageAccess()
      && TenantPropertyProvider.get().getProperty('behavioursEnabled')
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('trainingContent'));
  }

  hasBehaviourThresholdsSettingsTabAccess() {
    return this.hasBehavioursAndInspectionsSettingsPageAccess()
      && TenantPropertyProvider.get().getProperty('behavioursEnabled')
      && this.hasFlexAdminAccess('platformSettings')
      && !this.isBuAdminUser();
  }

  hasInspectionTemplatesSettingsTabAccess() {
    return this.hasBehavioursAndInspectionsSettingsPageAccess()
      && TenantPropertyProvider.get().getProperty('reviewsEnabled')
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('operations'))
      && !this.isBuAdminUser();
  }

  hasBrandingSettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser())
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasCompanySettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser())
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasGamesSettingsAccess() {
    return this.isAdminUser()
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('gamesAndRewards'));
  }

  hasRecognitionPinsSettingsAccess() {
    return this.hasAdminGroupAccess()
      && TenantPropertyProvider.get().getProperty('recognitionPinsEnabled')
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('gamesAndRewards'));
  }

  hasIpAllowlistSettingsAccess() {
    return this.hasTechnicalConfigurationsAccess()
      && this.hasFlexAdminAccess('platformSettings')
  }

  hasMessagesSettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser())
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('communications'));
  }

  hasProgramsSettingsAccess() {
    return this.isAdminUser()
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasQuestionsSettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser())
      && this.isSuperuser()
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasRewardsSettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser())
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('gamesAndRewards'));
  }

  hasKronosIntegrationSettingsAccess() {
    return this.hasTechnicalConfigurationsAccess()
      && TenantPropertyProvider.get().getProperty('kronosWorkforceDimensionsIntegration')
      && this.hasFlexAdminAccess('platformSettings')
  }

  hasOpenSesameIntegrationSettingsAccess() {
    return this.isAdminUser()
      && TenantPropertyProvider.get().getProperty('openSesameIntegration')
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasOpenSesameIntegrationV2SettingsAccess() {
    return this.isAdminUser()
      && TenantPropertyProvider.get().getProperty('openSesameIntegrationV2')
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasSapIntegrationSettingsAccess() {
    return (this.isAdminUser() || this.isTechAdminUser())
      && TenantPropertyProvider.get().getProperty('sapIntegrationFeaturesEnabled')
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasLrsConnectorSettingsAccess() {
    if (TenantPropertyProvider.get().getProperty('technicalAdminEnabled')) {
      return this.isAdminUser()
      && this.isSuperuser()
      && this.hasFlexAdminAccess('platformSettings')
    }
    return this.isAdminUser()
      && this.hasFlexAdminAccess('platformSettings')
  }

  hasSFTPSettingsAccess() {
    return this.hasTechnicalConfigurationsAccess()
      && this.hasFlexAdminAccess('platformSettings')
  }

  hasStringOverridesSettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser() || this.isTechAdminUser())
      && this.isSuperuser()
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasTenantPropertySettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser() || this.isTechAdminUser())
      && this.isSuperuser()
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasTranslationsSettingsAccess() {
    return (this.isAdminUser() || this.isBuAdminUser())
      && (this.hasFlexAdminAccess('platformSettings') || this.hasFlexAdminAccess('trainingContent'));
  }

  hasTranslationsExportsAccess() {
    return !this.isBuAdminUser()
      && this.hasFlexAdminAccess('platformSettings')
  }

  hasSelfRegistrationSettingsAccess() {
    return TenantPropertyProvider.get().getProperty('allowSelfRegistration')
      && this.isAdminUser()
      && this.hasFlexAdminAccess('platformSettings');
  }

  hasTechnicalConfigurationsAccess() {
    if (TenantPropertyProvider.get().getProperty('technicalAdminEnabled')) {
      return this.isTechAdminUser() || this.isSuperuser();
    }
    return this.isAdminUser();
  }

  hasTasksAccess() {
    return TenantPropertyProvider.get().getProperty('tasksEnabled')
      && ((this.isAdminUser() || this.isBuAdminUser()) && this.hasFlexAdminAccess('operations'));
  }
}


module.exports = AuthUser;
