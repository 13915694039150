const _ = require('underscore');
const VideoPlayer = require('@common/components/video/players/VideoPlayer');

class BlobPlayer extends VideoPlayer {
  constructor(...args) {
    super(...args);

    // notice the absence of any width or height properties in this template. :)
    this.template = _.tpl(`
    <video controls>
        <source src="<%= url %>"></source>
    </video>
    `);

  }

  renderPlayer() {
    this._triggerLoad();
    this._triggerEnd();
  }

  resize() {
    // resize needs to be clobbered in this subclass
    // blob videos don't have a detection of width and height, so we can't
    // do anything intelligent with resizing. We allow the browser to render it
    // in the size appropriate to the HTML element container.
    _.noop();
  }

  getTemplateOptions() {
    return {
      url: this.getVideoUrl()
    };
  }

  getVideoUrl() {
    return this.model.getUrl();
  }

  _setAspectRatio() {
    return super._setAspectRatio(16 / 9);
  }
}

module.exports = BlobPlayer;
