const Backbone = require('backbone');
const _ = require('underscore');

const LanguageList = require('@common/data/collections/LanguageList');

class Tenant extends Backbone.Model {
  static FIELDS = {
    SUPPORTED_MECHANICAL_TRANSCRIPTION_LANGUAGES: 'videoClosedCaptionsMechanicalTranscriptionSupportedLanguages',
    SUPPORTED_MECHANICAL_TRANSLATION_LANGUAGES: 'videoClosedCaptionsMechanicalTranslationSupportedLanguages',
    PREFERRED_TRANSCRIPTION_SERVICE_TYPE: 'automaticVideoClosedCaptionGeneratedType'
  };

  defaults() {
    return {
      reviewsEnabled: false,
      behavioursEnabled: false,
      discoveryZoneEnabled: false,
      employeeIdPrivacyEnabled: false,
      eventManagementEnabled: false,
      guidedLearningEnabled: false,
      usesLineOfBusinessBranding: false,
      impactZoneEnabled: false,
      impactZoneAuthorizedUsers: [],
      tenantAliases: [],
      videoFullscreenExitTimeOffset: 0,
      showInAppBackButton: false,
      atWorkCheckEnabled: false,
      atWorkCheckUserDefault: false,
      atWorkCheckUrl: '',
      onTheClockEnabled: false,
      onTheClockUserDefault: false,
      classicReportsEnabled: true
    };
  }

  getSupportedRootLanguages() {
    return LanguageList.fromLanguageCodeList(this.get('rootLanguages'));
  }

  getSupportedLanguages() {
    return LanguageList.fromLanguageCodeList(this.get('languages'));
  }

  isTenantNameAcceptableAliasForThisTenant(tenantName) {
    const isSomeSupportedAlias = _.any(this.get('tenantAliases'), (aliasConfig) => {
      return aliasConfig.alias === tenantName;
    });
    const isTenantName = tenantName === this.get('name');

    return isTenantName || isSomeSupportedAlias;
  }

  isGuidedLearningEnabled() {
    return this.get('guidedLearningEnabled') === true;
  }

  canShareContent() {
    return this.get('contentShareTenantWhitelistSet') === true;
  }
}

module.exports = Tenant;
