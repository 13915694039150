const UIKit = require('@training/widgets/UIKit');

const logging = require('logging');
const _ = require('underscore');

require('@common/libs/behaviors/card/Card');
const { INTERACTIVE } = require('@common/libs/behaviors/card/CardModifierClasses');

const I18n = require('@common/libs/I18n');
const { GameItemView } = require('@training/apps/training/views/GameItem');

class GameChallengeItemView extends UIKit.View {
  get template() {
    return _.tpl(`\
<div class="game-challenge">
  <% if (_.has(challenge, 'wager')) { %>
  <div class="challenge-wager"><%- t('selectGame.challengeInfo.wager', {"wager": challenge.wager}) %></div>
  <% } %>
  <button class="close js-decline-challenge" aria-label="<%- t('selectGame.dismissChallenge', {gameName: gameName})%>">&times;</button>
</div>
<div class="game-wrapper"></div>
<div class="challenge-info"> <caption class="fit-parent text-ellipsis-softwrap ax-font ax-font__caption--bold"> <%- t('selectGame.challengeInfo.challenger', {displayName: challenge.challenger.displayName}) %> </caption> </div>\
`);
  }

  className() {
    return 'challenge-cell ax-grid__col--4 ax-grid__col--l-3';
  }

  attributes() {
    return {
      role: 'listitem'
    };
  }

  events() {
    return {
      'click .js-decline-challenge': 'declineChallengeClick',
      'focus button.game': 'onGameTileFocus'
    };
  }

  behaviors() {
    return {
      Card: {
        modifierClasses: [INTERACTIVE]
      }
    };
  }

  render() {
    const challenge = this.model.toJSON();

    this.$el.html(this.template({
      challenge: challenge,
      gameName: this.model.game.get('name')
    }));

    const gameItemView = new GameItemView({
      el: this.$('.game-wrapper'),
      model: this.model.game
    });

    gameItemView.render();
    gameItemView.$('.game').data('challengeId', this.model.id);

    let ariaLabel = this.model.game.get('name') + '. ' + I18n.t('selectGame.challengeInfo.challenger', {displayName: challenge.challenger.displayName});
    if (this.model.get('wager') != null) {
      ariaLabel += ' ' + I18n.t('selectGame.challengeInfo.wager', {wager: challenge.wager});
    }

    this.$('button.game').attr('aria-label', ariaLabel);

    return this;
  }

  declineChallengeClick(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.declineChallengeInProgress) {
      return;
    }

    logging.info(`Declining challenge, id = ${ this.model.id }`);
    this.declineChallengeInProgress = true;

    // Wait for the server response before removing the model from the collection
    // Fixes SE-3347
    this.model.destroy({wait: true}).fail(() => {
      this.declineChallengeInProgress = false;
    });
  }

  onGameTileFocus() {
    this.$el.toggleClass('challenge-cell-focused');
  }
}
module.exports = GameChallengeItemView;
