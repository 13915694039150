const _ = require('underscore');
const Backbone = require('Backbone');
const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');
const LocalStorageUserCredentialsRepo = require('@common/modules/auth/libs/LocalStorageUserCredentialsRepo');

class UserCredentialsRepoFactory {
  constructor() {
    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');
  }

  getRepo(type) {
    const isInApp = this.nbChannel.reqres.request('isInApp');
    const canStoreUserCredentials = this.nbChannel.reqres.request('canStoreUserCredentials');

    if (type === 'LocalStorage') {
      if (isInApp && LocalStorageHelpers.supportsLocalStorage()) {
        return new LocalStorageUserCredentialsRepo();
      }
      throw new Error('Unsupported User Credentials Repo type');
    }
    if (isInApp && canStoreUserCredentials) {
      return new NativeUserCredentialsRepo();
    } else if (isInApp && LocalStorageHelpers.supportsLocalStorage()) {
      return new LocalStorageUserCredentialsRepo();
    }
    return new NullUserCredentialsRepo();
  }
}

class NullUserCredentialsRepo {
  storeCredentials() {}

  clearCredentials() {}

  retrieveCredentials() {
    const deferred = $.Deferred();
    _.defer(() => {
      return deferred.reject({});
    });
    return deferred.promise();
  }
}

class NativeUserCredentialsRepo {
  constructor() {
    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');
  }

  storeCredentials(credentials = {}) {
    return this.nbChannel.commands.execute('storeCredentials', JSON.stringify(credentials));
  }

  clearCredentials() {
    return this.nbChannel.commands.execute('clearCredentials');
  }

  retrieveCredentials() {
    return this.nbChannel.reqres.request('retrieveCredentials');
  }
}

module.exports = UserCredentialsRepoFactory;
