const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const URL_PREFIX = '/axonify/account/recovery';

class AccountRecoveryService {
  constructor(networkService = $.ajax) {
    this.networkService = networkService;
  }

  emailRecoveryByAddress(address) {
    /*
    POST /axonify/account/recovery/email/emailaddress (request recovery email given an email address)
    Request:
    {
      “email”: “jlessard@axonify.com”
    }


    Response:
    HTTP 200
    */
    const payload = {
      email: address
    };

    return this.networkService({
      type: 'POST',
      url: `${ URL_PREFIX }/email/emailaddress`,
      contentType: 'application/json',
      data: JSON.stringify(payload)
    });
  }

  emailRecoveryByUsername(username) {
    // Sends in the username, which triggers an email.  The response is just a 200.
    /*
      POST /axonify/account/recovery/email/username (request recovery email given a username)
      Request:
      {
        “username”: “jlessard”
      }

      Response:
      HTTP 200
    */
    const payload = {
      username: username
    };

    return this.networkService({
      type: 'POST',
      url: `${ URL_PREFIX }/email/username`,
      contentType: 'application/json',
      data: JSON.stringify(payload)
    });
  }

  getRecoveryOptions(username, recaptcha) {
    // sends in the username, and returns a list of valid recovery options.
    /*
      GET /axonify/account/recovery/options?username=<username>
      Response:
      {
        “options”: [
            “EMAIL”,
            “RECOVERY_QUESTIONS”
        ]
      }
    */

    const queryParams = {
      username: username,
      recaptcha: recaptcha
    };

    return this.networkService({
      type: 'POST',
      url: `${ URL_PREFIX }/options`,
      contentType: 'application/json',
      data: JSON.stringify(queryParams),
      error: (response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        if (exception.getErrorCode() === AxonifyExceptionCode.FAILED_RECAPTCHA_VALIDATION) {
          response.skipGlobalHandler = true;
        }
      }
    });
  }

  getSecurityQuestionToken(username) {
    // Sends in a username, the response sends a token which needs to be attached to
    // subsequent answer requests If the user is out of questions, it will return an error
    // code of 3104 to let the user know that their account is locked
    /*
      POST /axonify/account/recovery/questions (begin recovery using questions)

      Request:
      {
        “username”: “jlessard”
      }

      Response:
      {
        “token”: “uNNPezFYNU35uDje3qNP5DZtizCHqfGd”
      }
    */
    const securityTokenDeferred = $.Deferred();

    const payload = {
      username: username
    };

    this.networkService({
      type: 'POST',
      url: `${ URL_PREFIX }/questions`,
      contentType: 'application/json',
      data: JSON.stringify(payload),
      success(response) {
        securityTokenDeferred.resolve(response.token);
      },
      error: (response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        if (exception.getErrorCode() === AxonifyExceptionCode.ACCOUNT_RECOVERY_USER_LOCKED_OUT) {
          response.skipGlobalHandler = true;
          securityTokenDeferred.reject(true);
        } else {
          securityTokenDeferred.reject();
        }
      }
    });

    return securityTokenDeferred;
  }

  geCurrentSecurityQuestion(token) {
    // The server takes care of the flow state, here, so if there's another chance
    // to answer the existing question, it wil send back that question, or it will
    // send back a new one.  If the user is out of questions, it will return an error
    // code of 3101 to let the user know that their account is locked
    /*
      GET /axonify/account/recovery/questions/current?token=uNNPezFYNU35uDje3qNP5DZtizCHqfGd

      Response:
      {
        “question”: “Does pineapple belong on pizza?”
      }
    */

    const securityQuestionDeferred = $.Deferred();

    const queryParams = {
      token: token
    };

    this.networkService({
      type: 'GET',
      url: `${ URL_PREFIX }/questions/current`,
      contentType: 'application/json',
      data: queryParams,
      success(response) {
        securityQuestionDeferred.resolve(response.question);
      },
      error(response) {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        if (exception.getErrorCode() === AxonifyExceptionCode.ACCOUNT_RECOVERY_INVALID_TOKEN) {
          response.skipGlobalHandler = true;
          securityQuestionDeferred.reject(true);
        } else {
          securityQuestionDeferred.reject();
        }
      }
    });

    return securityQuestionDeferred;
  }

  answerCurrentSecurityQuestion(token, answerHash) {
    // TODO: Hook up the API to send an answer here.
    // Sends in the token and an MD5 of the answer.
    // if the response is false, this will show the user a flash message.
    // if the response is true, this will take them to the password reset page.
    /*
      POST /axonify/account/recovery/questions/current/answer

      Request:
      {
        “token”: “uNNPezFYNU35uDje3qNP5DZtizCHqfGd”,
        “answer”: <MD5 of answer string>
      }

      Response:
      {
        "answeredCorrectly": true
      }
    */
    const answerDeferred = $.Deferred();

    const payload = {
      token: token,
      answer: answerHash
    };

    this.networkService({
      type: 'POST',
      url: `${ URL_PREFIX }/questions/current/answer`,
      contentType: 'application/json',
      data: JSON.stringify(payload),
      success(response) {
        answerDeferred.resolve(response.answeredCorrectly);
      },
      error(response) {
        const exception = AxonifyExceptionFactory.fromResponse(response);
        if (exception.getErrorCode() === AxonifyExceptionCode.ACCOUNT_RECOVERY_INVALID_TOKEN) {
          response.skipGlobalHandler = true;
          answerDeferred.reject(true);
        } else {
          answerDeferred.reject();
        }
      }
    });

    return answerDeferred;
  }
}

module.exports = AccountRecoveryService;
