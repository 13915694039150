const Backbone = require('Backbone');

class AuctionItem extends Backbone.Model {
  placeBid(bid, options = {}) {
    const data = {
      points: bid,

      //Attaching the whole object so that server can get the iteration number information
      rewardProgramPrize: this.get('rewardProgramPrize')
    };

    if (this.has('bid')) {
      data['lastModify'] = this.get('bid').lastModify;
    }

    return $.ajax($.extend(true, options, {
      type: 'PUT',
      apiEndpoint: '/rewards/bids',
      data: JSON.stringify(data)
    }));
  }

  removeBid(options = {}) {
    const bid = this.get('bid') || {};
    const id = bid.id;

    if (id == null) {
      return $.Deferred().resolve();
    }

    return $.ajax($.extend(true, options, {
      type: 'DELETE',
      apiEndpoint: `/rewards/bids/${ id }`
    }));
  }

  buyNow(options = {}) {
    const data = {
      rewardProgramPrize: {
        id: this.get('id')
      }
    };

    return $.ajax($.extend(true, options, {
      type: 'POST',
      apiEndpoint: '/rewards/buys',
      data: JSON.stringify(data)
    }));
  }
}

module.exports = AuctionItem;
