const Backbone = require('Backbone');

class Team extends Backbone.Model {
  defaults() {
    return {name: ''};
  }

  apiEndpoint() {
    return '/locations';
  }

  initialize() {
    this.needsFetch = true;
  }

  parse(resp = {}) {
    const response = resp.entity || resp;
    this.needsFetch = (response.createDate == null);
    return response;
  }

  toOption() {
    return {
      id: this.id,
      value: this.get('name')
    };
  }

  matches(text = '') {
    return this.get('name').toLowerCase()
      .indexOf(text.toLowerCase()) > -1;
  }
}
module.exports = Team;
