const logging = require('logging');

/**
    This acts as a decorator over top of any other notifier that will surpress the notifier until the condition is met.

    This could be factored into a general predicate logic but it's implemented as a wrapper on top to keep the business
    logic of the actual update notification process clean and free of checks. If the checks get more complex, things
    could be routed out from there.
 */
class SessionContextAwareUpdateNotifier {
  wrappedNotifier = null;

  session = null;

  constructor(session, wrappedNotifier) {
    this.wrappedNotifier = wrappedNotifier;
    this.session = session;

    if (!this.session) {
      throw new Error('You must provide a session');
    }

    if (!this.wrappedNotifier) {
      throw new Error('You must provide a notifier');
    }
  }

  notify() {
    if (!this.session.hasCurrentAssessment()) {
      this.wrappedNotifier.notify();
    } else {
      logging.info('An update is available but supressing the update since an assessment is in progress for the user');
    }
  }
}

module.exports = SessionContextAwareUpdateNotifier;
