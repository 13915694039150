const _ = require('underscore');

class ChildCollectionTreeSourceMapper {

  constructor(options = {}) {
    this._createTreeData = this._createTreeData.bind(this);
    this._createNodeForModel = this._createNodeForModel.bind(this);

    ({
      showIcon: this._showIcon = false,
      showCheckbox: this._showCheckbox = true
    } = options);

    this._options = options;
  }

  getSourceForTree(collection) {
    const sources = this._createTreeData(null, collection.toJSON());
    // Reset it once this call is done so the mapper can be reused
    return sources;
  }

  _createTreeData(parentKey = null, collection) {
    return _.map(collection, (modelInstance) => {
      // Strip all the way down if needed
      const model = _.isFunction(modelInstance.toJSON) ? modelInstance.toJSON() : modelInstance;

      const nodeData = this._createNodeForModel(model, parentKey === null);
      const children = this._getModelChildren(model, parentKey === null);

      if ((children != null ? children.length : undefined) > 0) {
        nodeData.children = this._createTreeData(nodeData.key, children);
      }

      return nodeData;
    });
  }

  // Private implementation; may be over-ridden
  _createNodeForModel(model) {
    const nodeData = {
      model,
      key: this._getKeyForModel(model),
      icon: this._showIcon
    };

    if (!this._showCheckbox) {
      nodeData.checkbox = false;
    }

    if (model.isSelectedInTree) {
      nodeData.selected = true;
    }

    if (model.isUnselectable) {
      nodeData.unselectable = true;
    }

    if (model.isUnselectableStatus) {
      nodeData.unselectableStatus = true;
    }

    return nodeData;
  }

  _getModelChildren(model) {
    return model.children;
  }

  _getKeyForModel(modelBare) {
    return modelBare.id || modelBare.key;
  }
}

module.exports = ChildCollectionTreeSourceMapper;
