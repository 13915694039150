const env = require('env');
const $os = require('detectOS');
const ErrorHandler = require('ErrorHandler');
const logging = require('logging');
const CookieUtil = require('CookieUtil');

Object.assign(window, {
  env,
  $os,
  ErrorHandler,
  CookieUtil,
  logging,
    
  // Bind the errorHandler to the window.
  onerror: ErrorHandler.onError,
    
  // Setup a stub native bridge to listen for events
  apps: {},
  app: {
    nativeBridge: {
      handleMessage: () => { /* nomnomnom */ }
    }
  }
});
