const Marionette = require('Marionette');

class SelectableCollectionItem extends Marionette.ItemView {
  tagName() {
    return 'li';
  }

  getTemplate() {
    return `\
<span class="name"><a href="#"><%- getName() %></a></span>
<span class="js-delete icon-trash <%= deleteIconClass %>"></span>\
`;
  }

  ui() {
    return {
      delete: '.js-delete'
    };
  }

  events() {
    return {
      'click a': 'onItemClicked',
      'click .js-delete': 'onClickDelete'
    };
  }

  modelEvents() {
    return {
      change: 'render',
      selected: 'onItemClicked'
    };
  }

  onRender() {
    this.$el.toggleClass('selected', this.selected === true);
  }

  initialize(options) {
    this.templateHelpers = this.templateHelpers.bind(this);
    ({formattingOptions: this.formattingOptions, onDeleteClick: this.onDeleteClick, showDeleteButton: this.showDeleteButton} = options);
  }

  templateHelpers() {
    return {
      getName: () => {
        return (typeof this.model.toOption === 'function' ? this.model.toOption(this.formattingOptions).value : undefined);
      },
      deleteIconClass: this.showDeleteButton ? '' : 'hidden'
    };
  }

  onItemClicked() {
    this.trigger('item:selected', this.model);
  }

  onClickDelete() {
    this.onDeleteClick(this.model);
  }
}

module.exports = SelectableCollectionItem;
