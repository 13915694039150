const { LayoutView } = require('Marionette');
const Backbone = require('Backbone');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const KeyCode = require('@common/data/enums/KeyCode');

require('@common/libs/behaviors/scrollable/Scrollable');

class ReportsIndexLayout extends LayoutView {
  className() {
    return 'reports-view parent-height';
  }

  getTemplate() {
    return `
      <div id="content-section" class="row-content">
        <div id="js-container" class="ax-container">
          <div class="page-content">
            <div class="ax-grid ax-grid--align-items-center report-header-wrapper">
              <div class="breadcrumb-title-wrapper ax-grid--no-gutter ax-grid--align-items-center">
                <div class="breadcrumb-path ax-grid__col--auto-size" role="navigation">
                  <div class="header-breadcrumbs"></div>
                </div>
                <div class="title-region ax-grid__col--auto-size"></div>
              </div>
              <div class="region-filters clearfix ax-grid__col--12 ax-grid__col--m-auto-size">
                <div class="region-filters-community pull-right"></div>
              </div>
            </div>
            <div id="region-viewport">
              <div class="reports-dashboard-view ax-grid">
                <div class="pages-by-status ax-grid__col--12 ax-grid__col--l-6 report-tile card card--medium"></div>
                <div class="top-pages ax-grid__col--12 ax-grid__col--l-6 report-tile card card--medium card--interactive"></div>
                <div class="top-keywords ax-grid__col--12 ax-grid__col--l-6 report-tile card card--medium card--interactive"></div>
                <div class="top-keywords-empty ax-grid__col--12 ax-grid__col--l-6 report-tile card card--medium card--interactive"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  regions() {
    return {
      breadcrumbRegion: '.header-breadcrumbs',
      titleRegion: '.title-region',
      community: '.region-filters-community',
      pagesByStatus: '.pages-by-status',
      topPages: '.top-pages',
      topKeywords: '.top-keywords',
      topKeywordsEmptySearch: '.top-keywords-empty'
    };
  }

  ui() {
    return {
      topPages: '.top-pages',
      topKeywords: '.top-keywords',
      topKeywordsEmptySearch: '.top-keywords-empty'
    };
  }

  events() {
    return {
      'click @ui.topPages': '_onTopPagesClicked',
      'click @ui.topKeywords': '_onTopKeywordsClicked',
      'click @ui.topKeywordsEmptySearch': '_onTopKeywordsEmptySearchClicked',
      'keydown @ui.topPages': '_onKeypress',
      'keydown @ui.topKeywords': '_onKeypress',
      'keydown @ui.topKeywordsEmptySearch': '_onKeypress'
    };
  }

  _onKeypress(e) {
    if (e.which === KeyCode.ENTER) {
      $(e.currentTarget).trigger('click');
    }
  }

  _onTopPagesClicked() {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/top_pages`, {
      trigger: true
    });
  }

  _onTopKeywordsClicked() {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/top_keywords_in_search`, {
      trigger: true
    });
  }

  _onTopKeywordsEmptySearchClicked() {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/top_keywords_in_search_empty`, {
      trigger: true
    });
  }
}

module.exports = ReportsIndexLayout;
