const PromiseController = require('@common/components/loading/LoadingPromiseController').default;
const Wrapper = require('@common/components/loading/wrapper/LoadingWrapperController');
const Skeleton = require('@common/components/loading/skeleton/LoadingSkeletonController');
const LoadingCollectionViewController = require('@common/components/loading/collection_view/LoadingCollectionViewController');
const InfiniteCollectionView = require('@common/components/loading/collection_view/infiniteCollectionView/InfiniteCollectionViewController');

module.exports = {
  Wrapper,
  Skeleton,
  CollectionView: LoadingCollectionViewController,
  InfiniteCollectionView,
  PromiseController
};
