const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const { LayoutView } = require('Marionette');
const CommunityInsightsOverviewModel = require('@training/apps/training/communitiesInsights/CommunityInsightsOverviewModel');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const TeamFilterView = require('@training/apps/training/communitiesInsights/components/TeamFilterView');
const CommunityInsightsHowItWorksView = require('@training/apps/training/communitiesInsights/components/CommunityInsightsHowItWorksView');
const RecentPagesTableLayoutView = require('@training/apps/training/communitiesInsights/components/tables/RecentPagesTableLayoutView');
const CommunityInsightsRecentPagesCollection = require('@training/apps/training/communitiesInsights/CommunityInsightsRecentPagesCollection');
const CommunityInsightsRecentPagesTableItemView = require('@training/apps/training/communitiesInsights/components/tables/CommunityInsightsRecentPagesTableItemView');
const { getBaseCardControllerDefinition } = require('@common/components/baseCard/BaseCardControllerDefinitionFactory');
const { CollectionView } = require('Marionette');
const {
  Collection,
  Model
} = require('Backbone');
const ButtonGroupRadioView = require('@common/components/buttonGroup/ButtonGroupRadioView');
const CommunityInsightsOverviewTimeFrameEnum = require('@training/apps/training/communitiesInsights/CommunityInsightsOverviewTimeFrameEnum');
const {
  CommunityInsightsOverviewItemView,
  CommunityInsightsOverviewEmptyView
} = require('@training/apps/training/communitiesInsights/CommunityInsightsOverviewItemView');
const { getBreadcrumbs } = require('@training/apps/training/communitiesInsights/components/CommunityInsightsBreadcrumbFactory');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');


class CommunityInsightsController extends LayoutController {
  initialize(options) {
    this.viewDefinition = this.viewDefinition.bind(this);
    this._handleDateFilterChange = this._handleDateFilterChange.bind(this);
    this._resetOverviewViewData = this._resetOverviewViewData.bind(this);
    this.includeTeamBasedCommunities = options.includeTeamBasedCommunities;
    this.currentMetrics = new Collection();
    this.currentDateStamps = new Model();
    this.overviewModel = new CommunityInsightsOverviewModel([], {teamBasedCommunityFilter: this.includeTeamBasedCommunities});
    this.listenTo(this.overviewModel, 'sync', this._resetOverviewViewData);
    this.overviewModel.fetch();
    this._handleTeamBasedCommunityFilterChange = this._handleTeamBasedCommunityFilterChange.bind(this);
    this.recentPagesCollection = new CommunityInsightsRecentPagesCollection([], {includeTeamBasedCommunities: this.includeTeamBasedCommunities});
    this.recentPagesCollection.fetch().then(() => {
      BrowserHelpers.triggerResize(true);
    });
  }

  viewDefinition() {
    return {
      ViewClass: LayoutView,
      className: 'community-insights ax-container',
      template: require('@training/apps/training/communitiesInsights/CommunityInsightsTemplate.html'),
      regions: {
        breadCrumbs: '.js-community-insights__breadcrumbs',
        teamBasedFilter: '.js-community-insights__filters',
        howItWorks: '.js-community-insights__how-it-works',
        overview: '.js-community-insights__overview',
        table: '.js-community-insights__table-region',
        dateFilter: '.js-community-insights__date-filter'
      },
      events: {
        'click .js-community-insights__view-reports': (e) => {
          e.preventDefault();
          this._navigateToReports();
        }
      }
    };
  }

  regionControllers() {
    return {
      breadCrumbs: this._getBreadcrumbsDefinition(),
      teamBasedFilter: this._getTeamFilterDefinition(),
      dateFilter: this._getTimeScaleDefinition(),
      overview: this._getOverviewDefinition(),
      howItWorks: this._getHowItWorksDefinition(),
      table: this._getTableDefinition()
    };
  }

  _getBreadcrumbsDefinition() {
    return getBreadcrumbs('overview', (model) => {
      Backbone.history.navigate(model.get('target'), { trigger: true });
    });
  }

  _getTeamFilterDefinition() {
    return {
      viewDefinition: {
        ViewClass: TeamFilterView,
        value: this.recentPagesCollection.getTeamBasedSelected()
      },
      delegateEvents: {
        'view:changeTeamBasedSelect': this._handleTeamBasedCommunityFilterChange
      }
    };
  }

  _getTimeScaleDefinition() {
    return {
      viewDefinition: {
        ViewClass: ButtonGroupRadioView,
        className: 'ax-grid community-insights-date-filter__grid community-insights-date-filter--overview',
        childClass: 'ax-grid__col--6 ax-grid__col--m-auto-size ax-button--m community-insights-date-filter__item',
        buttonsConfig: [
          {
            value: CommunityInsightsOverviewTimeFrameEnum.LAST7,
            title: I18n.t('discover.insights.lastNumOfDays', { numberOfDays: 7 }),
            ariaLabel: I18n.t('discover.insights.lastNumOfDaysAriaLabel', { numberOfDays: 7 }),
            isActive: true
          },
          {
            value: CommunityInsightsOverviewTimeFrameEnum.LAST30,
            title: I18n.t('discover.insights.lastNumOfDays', { numberOfDays: 30 }),
            ariaLabel: I18n.t('discover.insights.lastNumOfDaysAriaLabel', { numberOfDays: 30 })
          },
          {
            value: CommunityInsightsOverviewTimeFrameEnum.LAST90,
            title: I18n.t('discover.insights.lastNumOfDays', { numberOfDays: 90 }),
            ariaLabel: I18n.t('discover.insights.lastNumOfDaysAriaLabel', { numberOfDays: 90 })
          }
        ]
      },
      delegateEvents: {
        'view:buttonGroup:click': this._handleDateFilterChange
      }
    };
  }

  _getHowItWorksDefinition() {
    return {
      viewDefinition: {
        ViewClass: CommunityInsightsHowItWorksView
      }
    };
  }

  _getOverviewDefinition() {
    return {
      viewDefinition: {
        ViewClass: CollectionView,
        childView: CommunityInsightsOverviewItemView,
        emptyView: CommunityInsightsOverviewEmptyView,
        collection: this.currentMetrics,
        dateStamps: this.currentDateStamps,
        tagName: 'ul',
        className: 'overview-items full-width ax-grid',
        dateRange: this.dateRange,
        childViewOptions: {
          dateStamps: this.currentDateStamps
        }
      }
    };
  }

  _getTableDefinition() {
    return getBaseCardControllerDefinition({
      titleOptions: {
        title: I18n.t('discover.insights.recentpages')
      },
      hasLink: true,
      linkOptions: {
        target: 'link',
        callback: () => {
          this._navigateToReports();
        }
      },
      bottomWrapperControllerDefinition: {
        viewDefinition: {
          ViewClass: RecentPagesTableLayoutView,
          collection: this.recentPagesCollection,
          rowItemView: CommunityInsightsRecentPagesTableItemView
        }
      }
    });
  }

  _handleTeamBasedCommunityFilterChange(view, controller, includeTeamBasedCommunities) {
    this.overviewModel.resetTeamBasedCommunityFilter(includeTeamBasedCommunities).then(() => {
      this.getRegion('overview').currentView.render();
    });
    this.recentPagesCollection.setTeamBasedSelected(includeTeamBasedCommunities);
    this.recentPagesCollection.fetch();

    const route = includeTeamBasedCommunities ? '-includeTeamBasedCommunities' : '';
    Backbone.history.navigate(SearchUrlHelper.BASE_SEARCH_HASH + `/communicationsInsights${ route }`, {
      trigger: false,
      replace: true
    });
  }

  _handleDateFilterChange(view, controller, dateFilter) {
    this.overviewModel.setDateRange(dateFilter);
    this.dateRange = dateFilter;
    this._resetOverviewViewData();
  }

  _resetOverviewViewData() {
    const currentOverviewModel = this.overviewModel.getCurrentOverviewModel();
    if (currentOverviewModel) {
      this.currentMetrics.reset(Object.keys(currentOverviewModel).map((key) => {
        return Object.assign({}, currentOverviewModel[key], {name: key});
      }));
      this.currentDateStamps.set(this.overviewModel.getCurrentDateStamps());
    }
    if (this.getRegion('overview') && this.getRegion('overview').currentView) {
      this.getRegion('overview').currentView.render();
    }
  }

  _navigateToReports() {
    const route = this.recentPagesCollection.getTeamBasedSelected() ? '-includeTeamBasedCommunities' : '';
    Backbone.history.navigate(`hub/search/communicationsInsights${ route }/reports/initial`, {
      trigger: true
    });
  }
}

module.exports = CommunityInsightsController;
