const { LayoutView } = require('Marionette');

class EvaluationDetailsInfoLayout extends LayoutView {
  className() {
    return 'evaluation-details-info';
  }

  getTemplate() {
    return `\
      <div class="evaluation-details-info__coach-message--wrapper"></div>
      <div class="evaluation-details-info__attempt-details--wrapper"></div>
      <div class="evaluation-details-info__submission-preview--wrapper"></div>
      <div class="evaluation-details-info__print-form--wrapper"></div>\
    `;
  }

  regions() {
    return {
      coachMessageRegion: '.evaluation-details-info__coach-message--wrapper',
      attemptDetailsRegion: '.evaluation-details-info__attempt-details--wrapper',
      submissionPreviewRegion: '.evaluation-details-info__submission-preview--wrapper',
      printFormRegion: '.evaluation-details-info__print-form--wrapper'
    };
  }
}

module.exports = EvaluationDetailsInfoLayout;
