import {
  Menu as MuiMenu,
  styled,
  type MenuProps as MuiMenuProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type PropsToOmit = 'elevation';
export type AxMenuProps = Omit<MuiMenuProps, PropsToOmit>;

const styledOptions = {
  name: 'AxMenu'
};

const StyledAxMenu = styled(MuiMenu, styledOptions)<AxMenuProps>(({ theme }) => {
  return {
    '& .MuiMenu-list': {
      padding: 0
    },
    '& .MuiPaper-root': {
      boxShadow: theme.uiKit.boxShadowM
    }
  };
});

/**
 * AxMenu is a wrapper around the MUI `Menu` component.
 *
 * ### Links
 * - • [MUI Menu | API](https://mui.com/material-ui/api/menu/)
 * - • [MUI Menu | Demo](https://mui.com/material-ui/react-menu/)
 */
export const AxMenu = forwardRef(({
  ...otherProps
}: AxMenuProps, ref: Ref<HTMLDivElement>) => {
  const menuProps: AxMenuProps = {
    ...otherProps
  };

  return (
    <StyledAxMenu { ...menuProps } ref={ ref } />
  );
});

export default AxMenu;
