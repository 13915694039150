const { Collection } = require('Backbone');
const PagePreviewSearchResult = require('@common/components/discover/models/PagePreviewSearchResult');

class RecommendedArticlesList extends Collection {
  apiEndpoint() {
    return '/pages/recommendation';
  }

  parse(res = {}) {
    const entity = res.entity || {};
    const recommendations = entity.recommendations || [];

    return recommendations.map((recommendation) => {
      const page = recommendation.recommendedPage;
      page.recommendationId = recommendation.recommendationId;

      recommendation.recommendedPage = new PagePreviewSearchResult(page);

      return recommendation;
    });
  }
}

module.exports = RecommendedArticlesList;
