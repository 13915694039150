// Module
var code = `<div class="metadata__input">
  <div class="js-community-selector-region"></div>
  <form class="js-community-form">
    <% if (canChangeRecommendable()) {%>
      <div class="metadata__recommendations--switch-container">
        <h4 class="metadata__recommendations--text metadata__recommendations--text-subtitle"><%- t('discover.metadata.recommendations.subtitle') %></h>
        <div data-editor="Switch" data-field="recommendable" data-options="switchOptions"></div>
      </div>
      <p class="metadata__recommendations--text metadata__recommendations--text-detail ax-font--no-margin"><%- t('discover.metadata.recommendations.description') %></p>
    <% } %>
    <div class="metadata__input shared-communities hidden">
      <label><%- t('discover.metadata.sharedCommunities') %></label>
      <div class="js-metadata-shared-communities metadata-shared-communities"></div>
    </div>
  </form>
</div>
`;
// Exports
module.exports = code;