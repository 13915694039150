const $os = require('detectOS');
const logging = require('logging');
const { isFunction } = require('underscore');
const { sendErrorLog } = require('LoggingService');

const LegacyUIKitView = require('@common/components/view/LegacyUIKitView');

require('jquery.lightbox_me');

const modalDefaults = {
  overlaySpeed: 100,
  lightboxSpeed: 200,
  centered: false,
  zIndex: 800, // use the index trick to keep the header visible since it avoids scrollbar flicker
  modalCSS: {
    position: 'absolute',
    top: 25
  },
  overlayCSS: {
    background: 'black',
    opacity: .8
  },
  closeSelector: '.close',
  destroyOnClose: true
};

// DEPRECATED - DO NOT USE FOR NEW CODE!!!
// Training specific implementation of the modal functionality
class View extends LegacyUIKitView {

  preinitialize(options) {
    super.preinitialize(options);

    this.presentModal = this.presentModal.bind(this);
    this.setViewInModal = this.setViewInModal.bind(this);
    this.dismissModal = this.dismissModal.bind(this);

    if ($os.mobile) {
      modalDefaults.overlaySpeed = 0;
      modalDefaults.lightboxSpeed = 0;
      modalDefaults.modalCSS.top = 0;
    }
  }

  presentModal(modal, options = {}) {
    this.modal = modal;
    this.modal.superview = this;
    logging.info(`View - presentModal was invoked; modal isn't null?: ${ this.modal != null }`);

    const {
      onLoad: onload = () => {},
      onClose: onclose = () => {}
    } = options;

    delete options.onLoad;
    delete options.onClose;

    const previousActiveElement = options.previousActiveElement || document.activeElement;
    const modalOptions = $.extend(true, { sendErrorLog }, modalDefaults, {container: this.$el}, options, {
      onLoad: () => {
        logging.info(`View - onLoad was invoked; modal isn't null?: ${ this.modal != null }`);
        if (this.modal != null) {
          this.modal.triggerMethod('attach', this.modal);

          if (isFunction(this.modal.viewDidAppear)) {
            this.modal.viewDidAppear();
          }

          this.$el.children('.adjustable-container').attr('aria-hidden', 'true');
          this.$el.children('[role="dialog"]').attr('aria-hidden', 'false');
          this.$el.css('overflow', 'hidden');
          this.scrollable = this.$el.closest('.scrollable').css('overflow', 'hidden');

          onload();
          this.triggerAdjustment();
        }
      },
      onClose: () => {
        logging.info(`View - onClose was invoked; modal isn't null?: ${ this.modal != null }`);
        $(previousActiveElement).trigger('focus');
        this.$el.children('.adjustable-container').attr('aria-hidden', 'false');

        this.modal.destroy();

        this.modal = null;
        this.$el.css('overflow', '');

        if (this.scrollable != null) {
          this.scrollable.css('overflow', '');
        }

        onclose();
        this.triggerAdjustment();
      }
    });
    return this.modal.render().$el.lightbox_me(modalOptions);
  }

  setViewInModal(v, transition = LegacyUIKitView.Transitions.NONE) {
    if (this.modal.setSubviewIn) {
      this.modal.setSubviewIn(v, {transition});
    }
  }

  dismissModal(complete = () => {}) {
    if (this.modal) {
      logging.debug('Dismissing ModalView');
      this.modal.$el.trigger('close');
    }

    complete();
  }

  dismiss() {
    this.$el.trigger('close');
    return false;
  }

  triggerAdjustment() {
    super.triggerAdjustment();

    if ($os.ios && $os.isInMobileApp()) {
      // XXX - XXX - XXX - HACK
      // This seems to be the only way to fix the scrolling issue with the
      // wkwebview apps.
      const $scrollables = $('.scrollable');
      $scrollables.css('webkitOverflowScrolling', 'auto');
      window.setTimeout( () => {
        $scrollables.css('webkitOverflowScrolling', 'touch');
      }, 100);
    }
  }

  show($element = this.$el) {
    $element.show();
  }

  hide($element = this.$el) {
    $element.hide();
  }
}

module.exports = View;
