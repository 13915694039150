const EvaluationSubmissionUploadModalContentController = require('@training/apps/training/views/evaluations/submissionModal/EvaluationSubmissionUploadModalContentController');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const UIKit = require('@training/widgets/UIKit');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const { toggleButtonSecondary, createButtonType } = require('@common/components/actionBarButton/ActionBarButtonFactory');

let modalGuard = false; // to avoid multiple calls to load the modal on desktop

const displayModal = (layoutView, evaluationForm, submissionFile, onSubmitAction) => {
  if (
    layoutView == null
    || evaluationForm == null
    || submissionFile == null
    || onSubmitAction == null
    || modalGuard) {
    return undefined;
  }

  // Make sure we don't open the modal twice
  modalGuard = true;

  // Create the base AdjustableView for the modal
  const modalView = new EvaluationSubmissionUploadModalView({
    onSubmitAction
  });

  // Present/display the base view
  layoutView.presentModal(modalView, {
    onClose: () => {
      modalGuard = false;
    }
  });

  // Load the subview/contents of the modal (not including the action buttons, which are part of the base view)
  const modalContentViewConfig = {
    ViewControllerClass: EvaluationSubmissionUploadModalContentController,
    evaluationForm,
    submissionFile
  };
  const modalContentView = ViewControllerFactory.createLegacyView(modalContentViewConfig);

  modalView.setSubviewIn(modalContentView, { transition: UIKit.View.Transitions.NONE });

  return modalView;
};

class EvaluationSubmissionUploadModalView extends AccessibleModalView {
  get className() {
    return 'modal upload-evaluation-submission-modal';
  }

  constructor(options = {}) {
    super(options);

    ({
      onSubmitAction: this.onSubmitAction = () => {}
    } = options);
  }

  setSubviewIn(v, options) {
    super.setSubviewIn(v, options);

    this.listenTo(v, 'close:modal', this.dismiss);
    this.listenTo(v, 'enable:submit', this.enableSubmit);
  }

  onRenderActionBar() {
    const cancelButton = toggleButtonSecondary(
      createButtonType('cancel', () => {
        this.dismiss();
      })
    );

    const submitButton = createButtonType('submitgeneric', () => {
      // NOTE: It's possible that form validation *could* already be contained in the onSubmitAction method()
      // If not - we need to validate the form here (e.g. make sure that a file was uploaded)
      this.onSubmitAction();
      this.dismiss();
    });

    this.actionBar.setActionBar({
      buttons: [
        cancelButton,
        submitButton
      ]
    });
  }

  enableSubmit(setActive) {
    // Validate that the form is complete/valid before enabling the submit button
    // If invalid, there should probably be some sort of toast error message (if those even work from a modal) or change in the UI.
    if (setActive) {
      this.actionBar.enableButton('submitgeneric');
    } else {
      this.actionBar.disableButton('submitgeneric');
    }
  }
}

module.exports = {
  EvaluationSubmissionUploadModalView,
  displayModal
};
