const Marionette = require('Marionette');
const { Collection } = require('Backbone');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const ActionBarButtonFactory = require('@common/components/actionBarButton/ActionBarButtonFactory');
const ActionBarType = require('@common/components/actionBarButton/ActionBarType');
const FeedbackType = require('@training/apps/common/models/FeedbackType');
const QuestionFeedbackModel = require('@training/apps/common/models/QuestionFeedbackModel');
const FeedbackTypes = require('@training/apps/common/enums/FeedbackTypes');


class FeedbackModalFormView extends Marionette.ItemView {
  constructor(options = {}) {
    super(options);

    ({
      onClose: this.onClose = () => {},
      feedbackContext: this.feedbackContext,
      collection: this.collection = new Collection(
        FeedbackTypes,
        {
          model: FeedbackType
        }
      )
    } = options);

    this.model = new QuestionFeedbackModel(undefined, this.feedbackContext);

    this.listenTo(this.model, 'change:issueType', this._updateFormSubmitButtonState);
  }

  events() {
    return {
      'keyup #feedback-comment': '_updateFormSubmitButtonStateFromEvent',
      'change #feedback-comment': '_updateFormSubmitButtonStateFromEvent'
    };
  }

  _updateFormSubmitButtonStateFromEvent(event) {
    const model = this.model.clone();
    model.set('additionalDetails', event.target.value);

    this._updateFormSubmitButtonState(model);
  }

  _updateFormSubmitButtonState(model) {
    if (this._isModelPopulated(model)) {
      this.actionBar.enableButton(ActionBarType.SUBMIT_GENERIC);
    } else {
      this.actionBar.disableButton(ActionBarType.SUBMIT_GENERIC);
    }
  }

  _isModelPopulated(model = this.model) {
    const hasAdditionalDetails = (model.get('additionalDetails') || '').trim().length;
    const hasIssueType = (model.get('issueType') || '').length;

    return hasAdditionalDetails || hasIssueType;
  }

  templateHelpers() {
    return {
      feedbackContextType: I18n.t(`feedback.contextTypeLabel.${ this.model.getContextType() }` || ''),
      feedbackContextDetail: this.model.getContextText() || '',
      feedbackTypePrompt: I18n.t(`feedback.contextTypePrompt.${ this.model.getContextType() }`) || ''
    };
  }

  ui() {
    return {
      feedbackComment: '#feedback-comment',
      modalHeader: '.modalHeader h1'
    };
  }

  getTemplate() {
    return require('@training/apps/training/templates/FeedbackTemplate.html');
  }

  className() {
    return 'feedback-view';
  }

  viewDidAppear() {
    this.ui.modalHeader.attr('tabindex', '-1').trigger('focus');
  }

  onRender() {
    this.feedbackForm = new Form({
      el: this.$('#feedback-form'),
      model: this.model,
      context: {
        feedbackTypeListOptions: {
          collection: this.collection,
          pillOptions: {
            isSingleSelect: true
          }
        }
      }
    });

    // Add the unique qa-specific classes to the form buttons
    $('input.qa-pill-item-input', this.feedbackForm.$el).each((index, inputButton) => {
      inputButton.classList.add(`qa-pill-item-input--${ inputButton.id.toLowerCase().replace(/\s+/g, '-') }`);
    });

    const submitButton = ActionBarButtonFactory.createButtonType(ActionBarType.SUBMIT_GENERIC, this._commitForm.bind(this));
    let cancelButton = ActionBarButtonFactory.createButtonType(ActionBarType.CANCEL, this.onClose);
    cancelButton = ActionBarButtonFactory.toggleButtonSecondary(cancelButton);

    const buttons = [
      cancelButton,
      submitButton
    ];
    this.actionBar.setActionBar({ buttons });
  }

  _commitForm() {
    const errors = this.feedbackForm.commit();

    if (errors) {
      window.app.layout.flash.error(errors);
    } else if (!this._isModelPopulated()) {
      window.app.layout.flash.error(I18n.t('feedback.emptyFormError'));
    } else {
      this._submitFeedback();
    }
  }

  _submitFeedback() {
    // NOTE: The api is expecting the save details in a format that isn't compatible with the normal Backbone save pattern, so we construct our own ajax call instead
    const modelJSON = this.model.toJSON();
    delete modelJSON.id;
    $.ajax({
      type: 'POST',
      url: this.model.url(),
      data: JSON.stringify(modelJSON),
      dataType: 'json',
      skipGlobalHandler: true,
      success: () => {
        window.app.layout.mainLayout.displayFlash({
          cssClass: 'success',
          message: [I18n.t('feedback.messages.successText')],
          title: I18n.t('feedback.messages.successHeader')
        });
        window.apps.glChannel.vent.trigger('app:feedbackSubmitted');
      },
      error: () => {
        window.app.layout.flash.error(I18n.t('feedback.messages.failureText'));
      }
    });

    this.onClose();
    this.destroy();
  }
}

module.exports = FeedbackModalFormView;
