const Backbone = require('Backbone');

class KnowledgeItemAnswersList extends Backbone.Collection {
  apiEndpoint() {
    return '/knowledge/answers';
  }

  parse(response) {
    return response.result.lastQuestionsAnswerHistory;
  }
}

module.exports = KnowledgeItemAnswersList;
