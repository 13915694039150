const logging = require('logging');

const $ = require('jquery');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const XhrHeaders = require('XhrHeaders');

// set the retry count the following function and
// only retry on status code 0s and retry is less than 2
// for a total of 3 tries
module.exports = (jqXHR, textStatus, retryCount) => {
  // set the number of retries. If status is 409, then num of retries is 9
  // because Michel said so.
  const numOfRetries = jqXHR.status === 409 ? 9 : 3;

  // determine if it's a WinInet network related issue:
  // 12002 - Server timeout
  // 12029,12030, 12031 - dropped connections (either web server or DB server)
  // 12152 - Connection closed by server.
  // 13030 - StatusText properties are unavailable, and a query attempt throws an exception
  // full list available at: http://support.microsoft.com/kb/193625
  const isWinInetErrorCode = ((jqXHR.status >= 12001) && (jqXHR.status <= 12156)) || (jqXHR.status === 13030);

  // determine if it's a response that signifies that there was a optimistic lock on the server
  // by checking the following: (status is 500 AND errCode is 9001)
  let serverOptimisticLockResponse = false;
  let isGcpReferenceError = false;

  if (jqXHR.status === 500) {
    const exception = AxonifyExceptionFactory.fromResponse(jqXHR);
    const errCode = exception.getErrorCode();

    if (errCode === AxonifyExceptionCode.SERVER_ERROR_STALE_RESOURCE) {
      serverOptimisticLockResponse = true;
    } else if (errCode === AxonifyExceptionCode.GCP_REFERENCE_ERROR) {
      isGcpReferenceError = true;
    }
  }

  // we get this if an app server is down and we need to wait a bit for LB to
  // change connection to a different server
  const is503Error = jqXHR.status === 503;

  // The following 50X errors just need to retry right away
  const is50XError = [502, 504].includes(jqXHR.status) || is503Error;

  // determine if this is the last retry
  const isLastRetry = retryCount === (numOfRetries - 1);

  // determine if this is not a network timeout
  const isNotTimeout = (jqXHR.status === 0) && (jqXHR.statusText !== 'timeout');

  // determine if this request was explicitly aborted
  const isAborted = (jqXHR.status === 0) && (jqXHR.statusText === 'abort');

  const deferredHelper = (returnValue, retryInterval) => {
    return $.Deferred((dfr) => {
      return setTimeout(() => {
        return dfr.resolve(returnValue);
      }
      , retryInterval);
    }).promise();
  };

  // if there is still attempts to try and it's not a timeout or it's
  // related to winInet error code, then it's most likely an internet
  // connection related issue so wait 6 seconds before the next attempt
  // waiting 6 seconds for 409 (server still processing previous request)
  // (15/30 seconds for 503's attempts/last attempt).
  const retry = function(retryOptions = {}) {
    let retryInterval;
    retryOptions.willRetry = true;
    jqXHR.skipGlobalHandler = true;

    if (isAborted) {
      return false; // Exit immediately; no need to retry...
    } else if (isNotTimeout || isWinInetErrorCode) {
      retryInterval = 6000;
    } else if (is503Error) {
      retryInterval = 15000;
    } else if (isGcpReferenceError) {
      // only want to retry once for this type of error
      retryOptions.retryCount = numOfRetries;
      retryInterval = 750;
    } else {
      retryInterval = 6000;
    }

    // If this is the last retry, then we don't care... we'll override the timeout value manually
    if (isLastRetry) {
      retryInterval = 30000;
    }
    return deferredHelper(retryOptions, retryInterval);
  };

  if (retryCount < numOfRetries) {
    // if response is 400, determine if the response is not from our server
    // by checking the X-Axonify-Version header
    if ((jqXHR.status === 400) && (jqXHR.getResponseHeader(XhrHeaders.SERVER_VERSION) == null)) {
      // if retryCount < numOfRetries, then this is the first and only time we're retrying
      logging.debug(`AJAX Request with Status 400 without X-Axonify-Version header will retry ONCE. StatusText: ${ jqXHR.statusText }.`);

      // only want to retry once so set retryCount to number of retries
      const retObj = retry({retryCount: numOfRetries});

      // wait a bit before trying again
      return deferredHelper(retObj, 750);
    }

    // it should only retry for following criteria:
    //   - (status is 0) OR status isWinInetErrorCode OR server optimistic lock OR status is 50X
    if ((jqXHR.status === 0) || isWinInetErrorCode || serverOptimisticLockResponse || is50XError) {
      logging.debug(`AJAX Request ${ retryCount } failed with status:${ jqXHR.status } statusText:${ jqXHR.statusText }.`);
      return retry();

    } else if ((jqXHR.status === 200) && (textStatus === 'parsererror')) {
      logging.debug(`AJAX Request ${ retryCount } got a 200, but parsing of JSON failed.  Need to retry.`);
      return retry();

    // the server sends 409 when it still processing the previous request
    } else if (jqXHR.status === 409) {
      logging.debug(`AJAX Request ${ retryCount } got a 409. Need to wait and retry.`);
      return retry();
    } else if (isGcpReferenceError) {
      logging.debug(`GCP Reference Error - will retry ONCE.`);
      return retry();
    }

    return false;

  }
  if ((jqXHR.status === 400) && (jqXHR.getResponseHeader(XhrHeaders.SERVER_VERSION) == null)) {
    // already retried once so just set log and return false for retrying
    logging.debug(`AJAX Request with Status 400 without X-Axonify-Version header will NOT continue. StatusText: ${ jqXHR.statusText }.  No other AJAX requests will be attempted!`);

  } else {
    logging.debug(`AJAX Request ${ retryCount + 1 } (out of ${ numOfRetries }) will NOT continue. Status:${ jqXHR.status } statusText:${ jqXHR.statusText }.  No other AJAX requests will be attempted!`);
  }

  return false;

};
