const Enum = require('@common/data/enums/Enum');

// Enum is built like (key)Sentiment: (value)Icon
// so we can quickly check if a given sentiment is valid and what that sentiment should be represented by.
module.exports = Enum({
  LIKE: 'like',
  LOVE: 'love',
  CELEBRATE: 'celebrate',
  THINKING: 'thinking',
  UPSET: 'upset'
});
