import {
  Unstable_Grid2 as MuiGrid,
  styled,
  type Grid2TypeMap as MuiGridTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

// Note - this component uses MUI Grid V2, despite the naming differences

type AxGridCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxGridTypeMap<P = object, D extends React.ElementType = 'div'> = {
  props: P & AxGridCustomProps & Omit<MuiGridTypeMap<P, D>['props'], 'gap'>;
  defaultComponent: D;
};

export type AxGridProps<
  D extends ElementType = AxGridTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxGridTypeMap<P & AxGridCustomProps, D>, D>;

const styledOptions = {
  name: 'AxGrid'
};

const StyledAxGrid = styled(MuiGrid, styledOptions)<AxGridProps>(() => {
  return {};
});

export const AxGrid: MuiOverridableComponent<AxGridTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxGridProps, ref: Ref<HTMLDivElement>) => {
  const GridProps: AxGridProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxGrid { ...GridProps } ref={ ref }>
      { children }
    </StyledAxGrid>
  );
});

export default AxGrid;
