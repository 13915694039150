const Enum = require('@common/data/enums/Enum');

// Enum is built like (key)Sentiment: (value)Icon
// so we can quickly check if a given sentiment is valid and what that sentiment should be represented by.
module.exports = Enum({
  TOPICS: 'topics',
  PATHS: 'paths',
  CATALOG: 'catalog'
}, {
  isValidTrainingTab(tabType) {
    return [
      this.TOPICS,
      this.PATHS,
      this.CATALOG
    ].includes(tabType);
  }
});
