const { LayoutView } = require('Marionette');
const CommentHelpers = require('@training/apps/articles/ArticleDetailsCommentHelpers');

class ArticleDetailsCommentTitleView extends LayoutView {

  initialize() {
    this.listenTo(this.collection, 'change', this._updateHeader);
    this.listenTo(this.collection, 'update', this._updateHeader);
  }

  ui() {
    return {
      header: '.page-comment-count'
    };
  }

  templateHelpers() {
    return {
      commentCount: this.getCommentTitle()
    };
  }

  _updateHeader() {
    this.ui.header.html(this.getCommentTitle());
  }

  getTemplate() {
    return '<p class="page-comment-count"><%- commentCount %></p>';
  }

  getCommentTitle() {
    return this.collection.length > 0 ? CommentHelpers.getCommentCountPhrase(this.collection.length) : '';
  }
}

module.exports = ArticleDetailsCommentTitleView;
