const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/scrollable/Scrollable');

const AxBreakpointEnum = require('@common/data/enums/AxBreakpointEnum').default;

const MOBILE_MAX_WIDTH = AxBreakpointEnum.MOBILE_MAX;

class PageEditViewLayout extends LayoutView {
  className() {
    return 'edit-layout parent-height';
  }

  getTemplate() {
    return require('@training/apps/articles/PageEditViewLayoutTemplate.html');
  }

  regions() {
    return {
      titleRegion: '#title',
      contentRegion: '#content',
      metadataRegion: '#metadata',
      actionBarRegion: '#action-bar'
    };
  }

  ui() {
    return {
      contentRow: '.row-content',
      actionBar: '#action-bar',
      content: '#content',
      metadata: '#metadata',
      editorPage: '#editor-page',
      titleContainer: '#title'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      Scrollable: {
        scrollableContainer: '#metadata'
      }
    };
  }

  constructor(options = {}) {
    super(options);

    const isRtl = I18n.isCurrentLanguageRtl();
    this._cssDirection = isRtl ? 'right' : 'left';
    this._translateXDirection = isRtl ? '-' : '';
    this._oppositeTranslateXDirection = isRtl ? '' : '-';
  }

  onAttach() {
    if (this.ui.contentRow.find('.training-module').length > 0) {
      this.ui.contentRow.addClass('scroll');
    }
  }

  onResize(winHeight, winWidth) {
    const containerHeight = this.$el.closest('#page-view').innerHeight();
    const titleHeight = this.ui.titleContainer.outerHeight();
    const buttonBarHeight = this.ui.actionBar.outerHeight();

    // The -1 offset in the next line is a small correction that eliminates a vertical scrollbar. I have looked for
    // something that could account for that 1 pixel difference. I cannot find it.
    //
    // Also note that for SCORM content types, the contentHeight calculation can not be adjusted to remove the scrollbar.
    // Fixing this would be a significant undertaking.
    const contentHeight = containerHeight - titleHeight - buttonBarHeight - 1;

    this.ui.contentRow.height(contentHeight);
    this.ui.metadata.height(contentHeight);

    // Setup new editor heights
    const $editor = $('#squire_editor');
    const toolbarHeight = $('#menu-container').outerHeight();
    const editorMargins = parseInt($editor.css('padding-top'), 10) + parseInt($editor.css('padding-bottom'), 10);
    const newEditorHeight = contentHeight - toolbarHeight - editorMargins;
    $editor.height(newEditorHeight);

    // Setup new editor heights
    const $froalaViewport = $('.fr-view');
    const froalaToolbarHeight = $('.froala-editor').outerHeight() - $froalaViewport.height();
    const newHeight = contentHeight - froalaToolbarHeight;
    $froalaViewport.height(newHeight);

    this.metaDataContainerWidth = this.ui.metadata.outerWidth();
    const editorParentWidth = this.ui.editorPage.width();
    this.containerWidth = editorParentWidth - this.metaDataContainerWidth;

    // At the MAX_WIDTH value the metadata becomes permanently visible
    // so we clear out any transforms and make sure the two side-by-side
    // elements fit properly.
    if (winWidth > MOBILE_MAX_WIDTH) {
      this.metadataOpen = true;
      if (!this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', true);
      }
      this.metadataAlwaysOpen = true;
      this.ui.content.css({width: `${ this.containerWidth }px`});
      this.ui.metadata.css({
        [this._cssDirection]: `${ this.containerWidth }px`,
        transform: ''
      });
    } else {
      this.metadataOpen = false;
      if (this.metadataAlwaysOpen) {
        this.trigger('metadata:isDisplayed', false);
      }
      this.metadataAlwaysOpen = false;
      this.ui.content.css({width: ''});
      this.ui.metadata.css({[this._cssDirection]: ''});
    }

    this.containerWidth = this.$el.width();
  }

  openMetadata() {
    if (this.metadataAlwaysOpen) {
      return;
    }
    this.metadataOpen = true;
    this.trigger('metadata:isDisplayed', true);
    this._animateOpenMetadata();
  }

  closeMetadata() {
    if (this.metadataAlwaysOpen) {
      return;
    }
    this.metadataOpen = false;
    this.trigger('metadata:isDisplayed', false);
    this._animateCloseMetadata();
  }

  toggleMetadata() {
    if (this.metadataAlwaysOpen) {
      return;
    }
    if (this.metadataOpen) {
      this.closeMetadata();
    } else {
      this.openMetadata();
    }
  }

  _animateOpenMetadata() {
    // any browser that doesn't support transitions needs to have the lft prop animated
    const options = ($.support.transition == null)
      ? {[this._cssDirection]: this.containerWidth - this.metaDataContainerWidth}
      : {
        translateZ: 0, // Triggers 3d acceleration when available
        translateX: parseInt(`${ this._oppositeTranslateXDirection }${ this.metaDataContainerWidth }`, 10)
      };

    this.ui.metadata.velocity('stop');
    this.ui.metadata.velocity(options, () => {
      // Set focus on the first header in the MetaData Pane after velocity animation
      const firstMetaHeader = $('.knowledge-item-metadata-view h3').first();
      $(firstMetaHeader).attr('tabindex', '-1')
        .trigger('focus');
    });
  }

  _animateCloseMetadata() {
    // any browser that doesn't support transitions needs to have the lft prop animated
    const options = ($.support.transition == null)
      ? {[this._cssDirection]: this.containerWidth}
      : {
        translateZ: 0, // Triggers 3d acceleration when available
        translateX: parseInt(`${ this._translateXDirection }${ this.metaDataContainerWidth }`, 10)
      };

    this.ui.metadata.velocity('stop');
    this.ui.metadata.velocity(options);
  }
}

module.exports = PageEditViewLayout;
