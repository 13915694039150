const _ = require('underscore');
const Backbone = require('Backbone');

const TopicStatus = require('@common/data/enums/TopicStatus');
const AssessmentType = require('@common/data/enums/AssessmentType');

const KnowledgeItem = require('../models/KnowledgeItem');

class KnowledgeItemList extends Backbone.Collection {
  static RATING_VALUE = 1000000;

  static PERCENTAGE_VALUE = 1000;

  get model() {
    return KnowledgeItem;
  }

  apiEndpoint() {
    return '/knowledge';
  }

  initialize(models, options = {}) {
    ({
      sessionModel: this.sessionModel
    } = options);

    const {
      includeRecommendations = false,
      includeTrainingModuleData = false
    } = options;

    this.params = {
      includeTrainingModuleData,
      recommend: includeRecommendations
    };

    this.listenTo(this, 'sync', this.resetOriginalModelList);
  }

  parse(response) {
    // XXX didn't feel like making another call just for these two pieces of info
    ({
      levelsGraduatedCount: this.levelsGraduatedCount,
      levelsAvailableCount: this.levelsAvailableCount
    } = response.result);

    const items = response.result.knowledgeMapResults;

    items.forEach((item) => {
      this.sessionModel.extendWithAssessmentGetter(item, {
        type: AssessmentType.ExtraTraining,
        topicId: item.topic.id,
        level: item.level
      });
    });

    return items;
  }

  resetOriginalModelList() {
    this._originalModelList = this.toArray();
  }

  comparator(a, b) {
    const labelOrdering = {
      [TopicStatus.NEEDS_COACHING]: 0,
      [TopicStatus.OVERCONFIDENT]: 1,
      [TopicStatus.UNDERCONFIDENT]: 2,
      [TopicStatus.BEGINNER]: 3,
      [TopicStatus.INTERMEDIATE]: 4,
      [TopicStatus.GRADUATE]: 5,
      [TopicStatus.EXPERT]: 6,
      [TopicStatus.NOT_STARTED]: 7
    };

    // Create the sort value by first rating and then percentage for both item a
    // and item b. Floor the percentage as that is what the user sees.

    const aRating = (-labelOrdering[a.get('topicStatus')] * KnowledgeItemList.RATING_VALUE);
    const aPercent = -(Math.floor(a.get('currentMetrics').percentCorrect) * KnowledgeItemList.PERCENTAGE_VALUE);
    const aSortValue = aRating + aPercent;
    const bRating = -(labelOrdering[b.get('topicStatus')] * KnowledgeItemList.RATING_VALUE);
    const bPercent = -(Math.floor(b.get('currentMetrics').percentCorrect) * KnowledgeItemList.PERCENTAGE_VALUE);
    const bSortValue = bRating + bPercent;

    if (aSortValue < bSortValue) {
      return 1;
    } else if (aSortValue > bSortValue) {
      return -1;
    }
    // the sort values of both items a and b are the same
    // so sort by topic name
    const aName = a.get('topic').name;
    const bName = b.get('topic').name;
    if (aName > bName) {
      return 1;
    } else if (aName < bName) {
      return -1;
    }
    // the topic names of both items a and b are the same
    // so sort by level, which cannot be the same
    const aLevel = a.get('level');
    const bLevel = b.get('level');
    if (aLevel > bLevel) {
      return 1;
    }
    return -1;


  }

  search(query = '') {
    if (query.trim() !== '') {
      const filterTextList = query.toLowerCase().split(/\s/);
      const filteredList = _.filter(this._originalModelList, (item) => {
        return _.some(filterTextList, (filterFragment) => {
          return (item.get('category').toLowerCase()
            .indexOf(filterFragment) >= 0)
          || (item.get('topic').name.toLowerCase().indexOf(filterFragment) >= 0)
          || (item.get('subject').toLowerCase()
            .indexOf(filterFragment) >= 0);
        });
      });
      this.reset(filteredList);
    }
  }

  clearSearch() {
    this.reset(this._originalModelList);
  }

  fetch(options = {}) {
    return super.fetch(_.extend(options, {data: this.params}));
  }
}

module.exports = KnowledgeItemList;
