const { Model } = require('Backbone');
const I18n = require('@common/libs/I18n');
const Community = require('@common/data/models/Community');

const getAllCommunitiesModel = () => {
  return new Model({
    community: new Community({
      id: -1,
      names: {
        default: I18n.t('components.communityPicker.heading.allCommunities'),
        translations: {}
      }
    })
  });
};

module.exports = { getAllCommunitiesModel };
