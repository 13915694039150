const _ = require('underscore');

const RecoverPasswordPage = require('@common/modules/auth/views/recovery/password/RecoverPasswordPage');
const SecurityQuestionPage = require('@common/modules/auth/views/recovery/password/SecurityQuestionPage');
const RecoverPasswordSubmittedPage = require('@common/modules/auth/views/recovery/password/RecoverPasswordSubmittedPage');
const SecurityQuestionErrorPage = require('@common/modules/auth/views/recovery/password/SecurityQuestionErrorPage');
const ResetPasswordPage = require('@common/modules/auth/views/reset/ResetPasswordPage');


const PasswordRecoveryViewFactory = {
  getViewControllerFor(nextPage, options, delegateEvents) {
    if (nextPage === 'EMAIL') {
      return this._getEmailRecoveryViewController(options, delegateEvents);
    } else if (nextPage === 'RECOVERY_QUESTIONS') {
      return this._getSecurityQuestionViewController(options, delegateEvents);
    } else if (nextPage === 'QUESTION_ERROR') {
      return this._getSecurityQuestionErrorViewController(options, delegateEvents);
    } else if (nextPage === 'CHANGE_PASSWORD') {
      return this._getChangePasswordViewController(options, delegateEvents);
    }
    return this._getInitialViewController(options, delegateEvents);

  },

  _buildViewDefintion(viewClass, options) {
    const definition = {
      ViewClass: viewClass
    };

    _.each(options, (value, key) => {
      definition[key] = value;
    });

    return definition;
  },

  _getInitialViewController(options, delegateEvents) {
    return {
      viewDefinition: this._buildViewDefintion(RecoverPasswordPage, options),
      delegateEvents: delegateEvents
    };
  },

  _getSecurityQuestionViewController(options, delegateEvents) {
    return {
      viewDefinition: this._buildViewDefintion(SecurityQuestionPage, options),
      delegateEvents: delegateEvents
    };
  },

  _getEmailRecoveryViewController(options, delegateEvents) {
    return {
      viewDefinition: this._buildViewDefintion(RecoverPasswordSubmittedPage, options),
      delegateEvents: delegateEvents
    };
  },

  _getSecurityQuestionErrorViewController(options, delegateEvents) {
    return {
      viewDefinition: this._buildViewDefintion(SecurityQuestionErrorPage, options),
      delegateEvents: delegateEvents
    };
  },

  _getChangePasswordViewController(options, delegateEvents) {
    return {
      viewDefinition: this._buildViewDefintion(ResetPasswordPage, options),
      delegateEvents: delegateEvents
    };
  }
};

module.exports = PasswordRecoveryViewFactory;
