// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
			<span class="page-header__icon icon-news" aria-hidden="true"></span>
      <span class="page-header__title"><%- t('news.title') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <% if (isMessagesEnabled) { %>
    <ul class="subtabs feed-tabs clearfix">
      <% if (isFeedAvailable) { %>
        <li data-tab="news" class="selected two-tab-view tab">
          <a href="#" class="selected"><%- t('news.tabs.newsFeed')%></a>
        </li>
      <% } %>
      <% if (!isConsolidatedCommsEnabled) { %>
      <li data-tab="messages" class="two-tab-view tab">
        <a href="#"><%- t('news.tabs.messageFeed')%></a>
      </li>
      <% } %>
    </ul>
    <% } %>
    <div class="news-container" id="friendNews"></div>
  </div>
</div>

`;
// Exports
module.exports = code;