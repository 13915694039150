// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
      <span class="page-header__title"><%- msgTypeTitle %></span>
    </div>
  </div>
</h1>

<div class="ax-container">
  <div class="page-content">
    <div class="user-messaging">
      <div class="broadcastmessage <%- subType %>">
        <div class="broadcast">
          <h2><%- t('usermessage.teamGameOver', {"winningTeam": winnerTeamName, "losingTeam": loserTeamNames, "gameName": gameName}) %></h2>
          <div class="score-wrapper">
              <div class="score-containter clearfix">
                <span class="team-label"><%= winnerTeamName %></span>
                <span class="team-score"><%- winningScore %></span>
              </div>
              <div class="score-containter border clearfix">
                <span class="team-label"><%= loserTeamNames %></span>
                <span class="team-score"><%- losingScore %></span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

`;
// Exports
module.exports = code;