const Backbone = require('Backbone');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const TrainingCardLayoutView = require('@common/components/trainingCards/TrainingCardLayoutView');
const { getTrainingCardTopRegionConfig } = require('@common/components/trainingCards/TrainingCardTopRegionFactory');
const { getTrainingCardBottomRegionConfig } = require('@common/components/trainingCards/TrainingCardBottomRegionFactory');
const { getDiscoverCardTopRegionConfig } = require('@common/components/trainingCards/DiscoverTrainingCardTopRegionFactory');
const { getDiscoverCardBottomRegionConfig } = require('@common/components/trainingCards/DiscoverTrainingCardBottomRegionFactory');

const {
  DISCOVER_CARD
} = require('@common/components/trainingCards/enums/TrainingCardTypes');

module.exports = {
  getTrainingCardDefinition(trainingData) {
    let {
      trainingCardType,
      thumbnailImage,
      subjectName,
      title,
      progressPercent,
      isStartable,
      isAssigned,
      isEnrolled,
      isRecommended,
      isOverdue,
      isDueToday,
      isComplete,
      numAvailable,
      actionMetaData,
      actionType,
      numCompleted,
      total,
      daysUntilDue,
      isGraduatedFromAllLevel,
      hasReassignedItems,
      actionCallback,
      fullCardCallback
    } = trainingData;

    if (trainingData.trainingCardType === DISCOVER_CARD) {
      fullCardCallback = function () {
        Backbone.history.navigate('#hub/search', true);
      };

      const controllerDefinition = {
        ViewControllerClass: LayoutController,
        viewDefinition: {
          ViewClass: TrainingCardLayoutView,
          fullCardCallback
        },
        regionControllers: {
          topRegion: getDiscoverCardTopRegionConfig({
            trainingCardType
          }),
          bottomRegion: getDiscoverCardBottomRegionConfig({
            trainingCardType,
            actionCallback: fullCardCallback
          })
        }
      };

      return controllerDefinition;
    }

    const controllerDefinition = {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: TrainingCardLayoutView,
        fullCardCallback
      },
      regionControllers: {
        topRegion: getTrainingCardTopRegionConfig({
          subjectName,
          title,
          trainingCardType,
          thumbnailImage,
          isAssigned,
          isEnrolled,
          isRecommended,
          hasReassignedItems
        }),
        bottomRegion: getTrainingCardBottomRegionConfig({
          trainingCardType,
          progressPercent,
          numAvailable,
          fullCardCallback,
          title,
          isStartable,
          actionCallback,
          actionMetaData,
          actionType,
          isRecommended,
          isOverdue,
          isDueToday,
          isComplete,
          numCompleted,
          daysUntilDue,
          total,
          isGraduatedFromAllLevel
        })
      }
    };

    return controllerDefinition;
  }
};
