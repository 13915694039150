import {
  AccordionDetails as MuiAccordionDetails,
  type AccordionDetailsProps as MuiAccordionDetailsProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxAccordionDetailsProps = MuiAccordionDetailsProps;

const styledOptions = {
  name: 'AxAccordionDetails'
};

const StyledAxAccordionDetails = styled(MuiAccordionDetails, styledOptions)<AxAccordionDetailsProps>(() => {
  return {};
});

export const AxAccordionDetails = forwardRef(({
  ...otherProps
}: AxAccordionDetailsProps, ref: Ref<HTMLDivElement>) => {
  const AccordionDetailsProps: AxAccordionDetailsProps = {
    ...otherProps
  };

  return (
    <StyledAxAccordionDetails { ...AccordionDetailsProps } ref={ ref }/>
  );
});

export default AxAccordionDetails;
