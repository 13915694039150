const logging = require('logging');
const _ = require('underscore');

const OtherUrlPlayer = require('@common/components/video/players/otherUrl/OtherUrlPlayer');
const VideoPlayer = require('@common/components/video/players/VideoPlayer');
const YouTubePlayer = require('@common/components/video/players/youtube/YouTubePlayer');
const VimeoPlayer = require('@common/components/video/players/vimeo/VimeoPlayer');
const TranscodedPlayer = require('@common/components/video/players/transcoded/TranscodedPlayer');
const BlobPlayer = require('@common/components/video/players/blob/BlobPlayer');
const VideoTypeEnum = require('@common/components/video/VideoTypeEnum');

const VideoPackageModel = require('@common/components/video/VideoPackageModel');
const ExternalVideoView = require('@common/components/training_modules/video/ExternalVideoView');

const EmbeddedVideoProviders = require('@common/data/enums/EmbeddedVideoProviders');
const TrainingModuleDeliveryMethodEnum = require('@common/data/enums/TrainingModuleDeliveryMethodEnum');
const TrainingModuleType = require('@common/data/enums/TrainingModuleType');

const VideoPlayerFactory = {

  videoTemplate: _.tpl(`\
  <iframe src="<%= src %>"
    class="media--embeddedVideo__iframe"
    width="<%= width %>"
    height="<%= height %>"
    frameborder="0"
    allow="<%= allow %>"
    allowfullscreen
    sandbox="allow-same-origin allow-scripts">
  </iframe>
`),

  /*
    This is the factory method to call to create VideoPlayer instances for the various
    media types we support.

    options:
      videoPackage (Backbone.Model or object) - Required
      viewOptions (object) - Optional
  */
  createPlayerInstance(options = {}) {
    const {
      viewOptions = {},
      videoPackage
    } = options;

    const model = new VideoPackageModel(videoPackage);
    const type = model.getType();
    let Player;

    if (viewOptions.moduleType === TrainingModuleType.EXTERNAL_VIDEO && viewOptions.deliveryMethod === TrainingModuleDeliveryMethodEnum.NEW_WINDOW) {
      Player = ExternalVideoView;
    } else {
      switch (type) {
        case VideoTypeEnum.YOUTUBE: {
          Player = YouTubePlayer;
          break;
        }
        case VideoTypeEnum.VIMEO: {
          Player = VimeoPlayer;
          break;
        }
        case VideoTypeEnum.BLOB: {
          Player = BlobPlayer;
          break;
        }
        case VideoTypeEnum.TRANSCODED: {
          Player = TranscodedPlayer;
          break;
        }
        case VideoTypeEnum.OTHER: {
          Player = OtherUrlPlayer;
          break;
        }
        default: {
          logging.error(`VideoPlayer type "${ type }" not supported!`);
          Player = VideoPlayer;
        }
      }
    }

    return new Player(_.defaults({ model }, viewOptions));
  },

  isValidVideoInstance(options = {}) {
    const model = new VideoPackageModel(options.videoPackage);
    const type = model.getType();
    return type != null && type.length > 0;
  },

  getEmbeddedVideoInstance(id, provider) {
    const embeddedProviderTemplateMap = {
      [EmbeddedVideoProviders.VIMEO]: this.videoTemplate({
        src: `https://player.vimeo.com/video/${ id }?color=ffffff`,
        width: 640,
        height: 360,
        allow: 'autoplay; fullscreen'
      }),
      [EmbeddedVideoProviders.YOUTUBE]: this.videoTemplate({
        src: `https://www.youtube.com/embed/${ id }`,
        width: 560,
        height: 315,
        allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      }),
      [EmbeddedVideoProviders.OTHER]: this.videoTemplate({
        src: `<%= url %>`,
        width: 560,
        height: 315,
        allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      })
    };

    const videoTemplate = embeddedProviderTemplateMap[provider];
    if (videoTemplate !== null) {
      return videoTemplate;
    }
    return logging.error('A mapping for video provider ' + provider + ' to an embedded video template could not be found.');

  }
};

module.exports = VideoPlayerFactory;
