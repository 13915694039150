const $os = require('detectOS');
const {
  extend,
  omit
} = require('underscore');
const {
  Collection,
  Model
} = require('Backbone');
const NavPosition = require('@common/data/enums/NavPosition');

const { getNavPosition } = require('@common/libs/helpers/app/NativeBridgeHelpers');

const ViewConfig = require('@common/components/view/ViewConfig');

const MenuPosition = require('@common/modules/main/menu/MenuPosition');
const MenuFilterHelpers = require('@common/modules/main/menu/MenuFilterHelpers');

const MENU_ITEMS_FULL = 'menuItemsFull';
const MENU_ITEMS_DISPLAY = 'menuItemsDisplay';
const MENU_ITEMS_OVERFLOW = 'menuItemsOverflow';
const MENU_ITEMS_PROFILE = 'menuItemsProfile';
const MENU_ITEMS_MS_TEAMS = 'menuItemsMsTeams';
const SELECTED_MENU_ITEM_STATE = 'selectedMenuItemState';
const MENU_TOP_WIDTH_AVAILABLE = 'menuTopWidthAvailable';
const CURRENT_MENU_POSITION = 'currentMenuPosition';
const IS_MINIMAL_LAYOUT = 'isMinimalLayout';
const IS_HIDDEN_LAYOUT = 'isHiddenLayout';
const IS_LOCKED_TO_TOP = 'isLockedToTop';
const ALWAYS_SHOW_BACK_BUTTON = 'alwaysShowBackButton';
const VIEWPORT_WIDTH = 'viewportWidth';

const DESKTOP_BREAKPOINT = 992;

module.exports = {
  Keys: {
    MENU_ITEMS_FULL,
    MENU_ITEMS_DISPLAY,
    MENU_ITEMS_OVERFLOW,
    MENU_ITEMS_PROFILE,
    MENU_ITEMS_MS_TEAMS,
    SELECTED_MENU_ITEM_STATE,
    MENU_TOP_WIDTH_AVAILABLE,
    CURRENT_MENU_POSITION,
    IS_MINIMAL_LAYOUT,
    IS_HIDDEN_LAYOUT,
    IS_LOCKED_TO_TOP,
    ALWAYS_SHOW_BACK_BUTTON,
    VIEWPORT_WIDTH
  },

  defaults() {
    return {
      [MENU_ITEMS_FULL]: new Collection([], { model: ViewConfig }),
      [MENU_ITEMS_DISPLAY]: new Collection([], { model: ViewConfig }),
      [MENU_ITEMS_OVERFLOW]: new Collection([], { model: ViewConfig }),
      [MENU_ITEMS_PROFILE]: new Collection([], { model: ViewConfig }),
      [MENU_ITEMS_MS_TEAMS]: new Collection([], { model: ViewConfig }),
      [SELECTED_MENU_ITEM_STATE]: new Model({ selectedId: ''}),
      [MENU_TOP_WIDTH_AVAILABLE]: 0,
      [CURRENT_MENU_POSITION]: MenuPosition.Bottom,
      [IS_MINIMAL_LAYOUT]: false, // currently only applicable for bottom/mobile
      [IS_HIDDEN_LAYOUT]: false, // currently only applicable for bottom/mobile
      [IS_LOCKED_TO_TOP]: getNavPosition() === NavPosition.top,
      [ALWAYS_SHOW_BACK_BUTTON]: true,
      [VIEWPORT_WIDTH]: 0
    };
  },

  getItems(contextModel) {
    return contextModel.get(MENU_ITEMS_FULL);
  },

  getItemsDisplay(contextModel) {
    return contextModel.get(MENU_ITEMS_DISPLAY);
  },

  getItemsOverflow(contextModel) {
    return contextModel.get(MENU_ITEMS_OVERFLOW);
  },

  getItemsProfile(contextModel) {
    return contextModel.get(MENU_ITEMS_PROFILE);
  },

  getItemsMsTeams(contextModel) {
    return contextModel.get(MENU_ITEMS_MS_TEAMS);
  },

  getCurrentMenuPosition(contextModel) {
    return contextModel.get(CURRENT_MENU_POSITION);
  },

  getIsMinimalLayout(contextModel) {
    return contextModel.get(IS_MINIMAL_LAYOUT);
  },

  getIsHiddenlyLayout(contextModel) {
    return contextModel.get(IS_HIDDEN_LAYOUT);
  },

  getIsLockedToTop(contextModel) {
    return contextModel.get(IS_LOCKED_TO_TOP);
  },

  getAlwaysShowBackButton(contextModel) {
    return contextModel.get(ALWAYS_SHOW_BACK_BUTTON);
  },

  init(contextModel) {
    contextModel.on(`change:${ CURRENT_MENU_POSITION }`, () => {
      this._updateFilteredCollections(contextModel);
    });

    contextModel.on(`change:${ MENU_TOP_WIDTH_AVAILABLE }`, () => {
      this._updateFilteredCollections(contextModel);
    });

    const allItems = contextModel.get(MENU_ITEMS_FULL);
    allItems.on('change add remove reset', () => {
      this._updateFilteredCollections(contextModel);
      this._updateMsTeamsCollection(contextModel);
    });

    contextModel.on(`change:${ IS_MINIMAL_LAYOUT }`, () => {
      this._updateFilteredCollections(contextModel);
    });

    contextModel.on(`change:${ VIEWPORT_WIDTH }`, () => {
      this._updateFilteredCollections(contextModel);
      this._updateMenuPosition(contextModel);
    });

    contextModel.on(`change:${ IS_LOCKED_TO_TOP }`, () => {
      this._updateFilteredCollections(contextModel);
      this._updateMenuPosition(contextModel);
    });

    this._updateFilteredCollections(contextModel);
    this._updateMenuPosition(contextModel);
    this._updateMsTeamsCollection(contextModel);
  },

  setMenuItemDefinitions(contextModel, viewDefinitions = [], setOptions = {}) {
    const selectedItemState = contextModel.get(SELECTED_MENU_ITEM_STATE);
    let items = [].concat(viewDefinitions);

    items = items.map((itemDefinitions) => {
      const viewOptions = extend( omit(itemDefinitions, 'configId', 'ViewClass'), {
        selectedState: selectedItemState,
        linkClass: this._tryGetLinkClass(itemDefinitions)
      });

      return {
        id: itemDefinitions.configId,
        viewClass: itemDefinitions.ViewClass,
        isAppItem: itemDefinitions.isAppItem,
        isProfileItem: itemDefinitions.isProfileItem,
        isLockedToTop: itemDefinitions.isLockedToTop,
        events: itemDefinitions.events,
        includeDivider: false,
        hideMsTeams: itemDefinitions.hideMsTeams,
        viewOptions
      };
    });

    contextModel.get(MENU_ITEMS_FULL).set(items, setOptions);
  },

  getSelectedMenuItemState(contextModel) {
    return contextModel.get(SELECTED_MENU_ITEM_STATE);
  },

  setSelectedMenuItem(contextModel, selectedId) {
    contextModel.get(SELECTED_MENU_ITEM_STATE).set('selectedId', selectedId);
  },

  setViewportWidth(contextModel, viewportWidth) {
    contextModel.set(VIEWPORT_WIDTH, viewportWidth);
  },

  getViewportWidth(contextModel) {
    return contextModel.get(VIEWPORT_WIDTH);
  },

  toggleMinimalLayout(contextModel, toggle) {
    contextModel.set(IS_MINIMAL_LAYOUT, toggle);
  },

  toggleHiddenLayout(contextModel, toggle) {
    contextModel.set(IS_HIDDEN_LAYOUT, toggle);
  },

  toggleLockedToTop(contextModel, toggle) {
    contextModel.set(IS_LOCKED_TO_TOP, toggle);
  },

  toggleAlwaysShowBackButton(contextModel, toggle) {
    contextModel.set(ALWAYS_SHOW_BACK_BUTTON, toggle);
  },

  _updateMenuPosition(contextModel) {
    const isLockedToTop = this.getIsLockedToTop(contextModel);
    const isTopViewportWidth = this.getViewportWidth(contextModel) >= DESKTOP_BREAKPOINT;
    const isTop = isLockedToTop || (!$os.isInMobileApp() && isTopViewportWidth);
    const position = isTop ? MenuPosition.Top : MenuPosition.Bottom;

    contextModel.set(CURRENT_MENU_POSITION, position);
  },

  _updateFilteredCollections(contextModel) {
    const allItemsCollection = this.getItems(contextModel);
    const currentMenuPosition = this.getCurrentMenuPosition(contextModel);
    const menuTopWidthAvailable = contextModel.get(this.Keys.MENU_TOP_WIDTH_AVAILABLE);
    const isMinimalLayout = this.getIsMinimalLayout(contextModel);
    const isLockedToTop = this.getIsLockedToTop(contextModel);

    const displayItems = this.getItemsDisplay(contextModel);
    const overflowItems = this.getItemsOverflow(contextModel);
    const profileItems = this.getItemsProfile(contextModel);

    const {
      displayItemsArray,
      overflowItemsArray,
      profileItemsArray
    } = MenuFilterHelpers.getItemArraysFromMenuItemsCollection({
      allItemsCollection,
      currentMenuPosition,
      menuTopWidthAvailable,
      isMinimalLayout,
      isLockedToTop
    });

    this._tryMarkDividerItem(overflowItemsArray);

    displayItems.reset(displayItemsArray);
    overflowItems.reset(overflowItemsArray);
    profileItems.reset(profileItemsArray);
  },

  _updateMsTeamsCollection(contextModel) {
    const allItemsCollection = this.getItems(contextModel);
    const msTeamsCollection = this.getItemsMsTeams(contextModel);

    const msTeamsItemsArray = MenuFilterHelpers.getMsTeamsItemArray(allItemsCollection);

    msTeamsCollection.reset(msTeamsItemsArray);
  },

  _tryGetLinkClass({
    isAppItem,
    appColorClass
  }) {
    if (!isAppItem) {
      return undefined;
    }

    return `app-item ${ appColorClass }`;
  },

  _tryMarkDividerItem(items) {
    const foundIndex = items.findIndex((item) => {
      return item.get('isAppItem');
    });

    items.forEach((item, index) => {
      item.set('includeDivider', (foundIndex > 0 && foundIndex === index));
    });
  }
};
