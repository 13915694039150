const logging = require('logging');

const LocalStorageHelpers = require('@common/libs/helpers/app/LocalStorageHelpers');

const AbstractAssessmentInitiatorTracker = require('./AbstractAssessmentInitiatorTracker');

class AssessmentInitiatorPersistantTracker extends AbstractAssessmentInitiatorTracker {
  constructor(registry, ClientStorageTrackerFactory, CookieStorageTrackerFactory) {
    super(registry);

    this._localStorageTracker = ClientStorageTrackerFactory(registry);
    this._cookieStorageTracker = CookieStorageTrackerFactory(registry);

    this._primaryTracker = this._getInitialTracker();
  }

  trackAssessment(...args) {
    this._executeWithFallback('trackAssessment', ...args);
  }

  stopTrackingAssessmentId(...args) {
    this._executeWithFallback('stopTrackingAssessmentId', ...args);
  }

  findAssessmentInitiator(...args) {
    return this._executeWithFallback('findAssessmentInitiator', ...args);
  }

  asEntryIterator() {
    return this._executeWithFallback('asEntryIterator');
  }

  _getInitialTracker() {
    // Verify that localstorage will work for us from the get go and fallback to cookie storage if not.
    if (LocalStorageHelpers.supportsLocalStorage() && !LocalStorageHelpers.isLocalStorageFull()) {
      return this._localStorageTracker;
    }

    logging.warn('Storage quota error, falling back to Cookie storage');
    return this._cookieStorageTracker;
  }

  _executeWithFallback(method, ...args) {
    try {
      // Attempt to call method on tacker instance
      return this._primaryTracker[method](...args);
    } catch (error) {
      // If there's an error related to local storage quota being reached, then fallback to the cookiestorage
      // and continue using it for the remainder of the session.
      if (LocalStorageHelpers.isLocalStorageFullError(error)) {
        logging.warn('Storage quota error, falling back to Cookie storage');
        this._primaryTracker = this._cookieStorageTracker;
        return this._primaryTracker[method](...args);
      }

      throw error;
    }
  }
}

module.exports = AssessmentInitiatorPersistantTracker;
