import type { ComponentModule } from '@common/modules/react';
import { Wreqr } from 'backbone';

export const INITIALIZE_REACT_BUNDLE = 'initialize:react:bundle';
export const ADD_PORTAL_REQRES = 'add:portal';
export const REMOVE_PORTAL_REQRES = 'remove:portal';

const reactChannel = Wreqr.radio.channel('react');

export interface AddPortalOptions<P> {
  component: Promise<ComponentModule<P>> | ComponentModule<P>
  props?: P
  id: string
  el: Element
}

export async function initReactBundle(): Promise<void> {
  return reactChannel.reqres.request(INITIALIZE_REACT_BUNDLE);
}

export function renderPortal<P>(options: AddPortalOptions<P>): void {
  reactChannel.reqres.request(ADD_PORTAL_REQRES, options);
}

export function removePortal(id: string): void {
  reactChannel.reqres.request(REMOVE_PORTAL_REQRES, id);
}

export function setHandlers<P>(
  initHandler: () => Promise<void>,
  renderPortalHandler: (options: AddPortalOptions<P>) => Promise<void>,
  removePortalHandler: (id: string) => void
): void {
  reactChannel.reqres.setHandler(INITIALIZE_REACT_BUNDLE, initHandler);
  reactChannel.reqres.setHandler(ADD_PORTAL_REQRES, renderPortalHandler);
  reactChannel.reqres.setHandler(REMOVE_PORTAL_REQRES, removePortalHandler);
}

export function removeHandlers() {
  reactChannel.reqres.removeHandler(INITIALIZE_REACT_BUNDLE);
  reactChannel.reqres.removeHandler(ADD_PORTAL_REQRES);
  reactChannel.reqres.removeHandler(REMOVE_PORTAL_REQRES);
}
