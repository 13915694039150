const { Model } = require('Backbone');
const TrainingCardModel = require('@common/components/trainingCards/TrainingCardModel');
const TopicCTAButtonHelper = require('@common/components/search/TopicCTAButtonHelper');

const {
  DAILY_REINFORCEMENT,
  TOPIC_CERTIFICATION,
  TOPIC_EXTRA_TRAINING,
  TOPIC_REFRESHER,
  TOPIC_INTRO,
  TOPIC_DEFAULT
} = require('@common/components/trainingCards/enums/TrainingCardTypes');

const TRAINING_CARD_TYPE_BY_ASSESSMENT_TYPE = {
  DailyTraining: DAILY_REINFORCEMENT,
  CertificationTraining: TOPIC_CERTIFICATION,
  ExtraTrainingQuestions: TOPIC_EXTRA_TRAINING,
  RefresherTraining: TOPIC_REFRESHER,
  IntroductoryTraining: TOPIC_INTRO
};

const _isCardTypeTopic = (cardType) => {
  return [
    'RECOMMENDED_TOPICS',
    'ASSESSMENT',
    'TOPIC'
  ].includes(cardType);
};

const getCardType = (cardType, action) => {
  if (_isCardTypeTopic(cardType) && action) {
    const assessmentType = action[TrainingCardModel.FIELDS.ASSESSMENT_TYPE];
    return TRAINING_CARD_TYPE_BY_ASSESSMENT_TYPE[assessmentType] || TOPIC_DEFAULT;
  }

  return cardType;
};

const getCardActionMetaData = (action) => {
  if (action) {
    return action[TrainingCardModel.FIELDS.META_DATA];
  }
  return '';
};

const tryGetStartableAction = (card, session) => {
  let resultAction;
  const modelAction = card[TrainingCardModel.FIELDS.ACTION];

  const isStartable = TopicCTAButtonHelper.isStartable(modelAction, session);

  if (!isStartable) {
    resultAction = TopicCTAButtonHelper.findStartableTopicLevelAction(new Model(card), session);
  } else {
    resultAction = modelAction;
  }

  return resultAction;
};

module.exports = {
  TRAINING_CARD_TYPE_BY_ASSESSMENT_TYPE,
  getCardType,
  getCardActionMetaData,
  tryGetStartableAction
};
