import { ButtonCommonStyles } from '@common/modules/react/themes/components';
import {
  IconButton as MuiIconButton,
  styled,
  type IconButtonTypeMap as MuiIconButtonTypeMap,
  type Theme
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { defaults } from 'lodash';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

export type ICON_BUTTON_VARIANTS =
  'default' |
  'floating' |
  'branded' |
  'branded-secondary' |
  'destructive' |
  'ghost' |
  'ghost-link-branded' |
  'contained';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsVariantOverrides {
    'default': true
    'floating': true
    'branded': true
    'branded-secondary': true
    'destructive': true
    'ghost': true
    'ghost-link-branded': true
  }
}

type AxIconButtonCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  variant?: ICON_BUTTON_VARIANTS
  /**
  *  Apply default disabled state CSS. Can set to false for buttons that are functionality disabled but shouldn't look it.
  *  @default true
  **/
  enableDisabledStyles?: boolean
  /**
  *  When hovering on a parent element that contains a button, a ghost button will display using it's hover state
  *  @default false
  **/
  ghostParentHover?: boolean
};

export type AxIconButtonTypeMap<P = object, D extends React.ElementType = 'button'> = MuiIconButtonTypeMap<P & AxIconButtonCustomProps, D>;

export type AxIconButtonProps<
  D extends ElementType = AxIconButtonTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxIconButtonTypeMap<P & AxIconButtonCustomProps, D>, D>;

const styledOptions = {
  name: 'AxIconButton',
  shouldForwardProp: (prop: string) => {
    return prop !== 'ghostParentHover' && prop !== 'enableDisabledStyles';
  }
};

const StyledAxIconButton = styled(MuiIconButton, styledOptions)<AxIconButtonProps>((props) => {
  const {
    variant,
    theme,
    size
  } = props;

  const defaultStyles = defaults({
    ...getSizeStyles(size, theme)
  }, ButtonCommonStyles(variant, theme));
 

  if (variant === 'floating') {
    return defaults({
      backgroundColor: theme.uiKit.buttonFloatingBackground,
      border: 'none',
      borderRadius: '50%',
      boxShadow: theme.uiKit.boxShadowS,
      '&:hover': {
        backgroundColor: theme.uiKit.buttonFloatingBackgroundHover,
        border: 'none',
        boxShadow: theme.uiKit.boxShadowS
      },
      '&.MuiIconButton-floating.MuiIconButton-sizeSmall': {
        padding: theme.uiKit.spacingXS
      },
      '&.MuiIconButton-floating.MuiIconButton-sizeMedium, \
       &.MuiIconButton-floating.MuiIconButton-sizeLarge': {
        padding: theme.uiKit.spacingS
      },
      '&.MuiIconButton-floating.MuiIconButton-sizeSmall .MuiIcon-root, \
       &.MuiIconButton-floating.MuiIconButton-sizeSmall [class^="icon-"], \
       &.MuiIconButton-floating.MuiIconButton-sizeMedium .MuiIcon-root, \
       &.MuiIconButton-floating.MuiIconButton-sizeMedium [class^="icon-"]': {
        fontSize: theme.uiKit.fontSizeS
      }
    }, defaultStyles);
  }

  return defaultStyles;
});

export const AxIconButton: MuiOverridableComponent<AxIconButtonTypeMap> = forwardRef(({
  disableFocusRipple = true,
  disableRipple = true,
  enableDisabledStyles = true,
  ghostParentHover = false,
  size = 'large',
  variant = 'default',
  children,
  ...otherProps
}: AxIconButtonProps, ref: Ref<HTMLButtonElement>) => {
  const buttonProps = {
    disableFocusRipple,
    disableRipple,
    ghostParentHover,
    size,
    variant,
    classes: {
      root: `MuiIconButton-${ variant }`
    },
    ...otherProps
  };

  if (!enableDisabledStyles) {
    buttonProps.className += ' MuiButton--remove-disabled-styles';
  }

  if (ghostParentHover) {
    buttonProps.className += ' MuiIconButton--ghost-parent-hover';
  }

  return (
    <StyledAxIconButton { ...buttonProps } ref={ ref }>
      { children }
    </StyledAxIconButton>
  );
});

export default AxIconButton;

function getSizeStyles(size: AxIconButtonProps['size'] = 'medium', theme: Theme) {
  if (size === 'small') {
    return {
      padding: theme.uiKit.buttonPaddingS,
      paddingLeft: theme.uiKit.spacingXXS,
      paddingRight: theme.uiKit.spacingXXS
    };
  }

  if (size === 'medium') {
    return {
      padding: theme.uiKit.buttonPaddingM,
      paddingLeft: theme.uiKit.spacingXS,
      paddingRight: theme.uiKit.spacingXS
    };
  }

  if (size === 'large') {
    return {
      padding: theme.uiKit.buttonPaddingL,
      paddingLeft: theme.uiKit.spacingS,
      paddingRight: theme.uiKit.spacingS
    };
  }

  return {};
}
