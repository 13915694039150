const Marionette = require('Marionette');

const dateHelpers = require('@common/libs/dateHelpers');

class LearningEventListHeaderView extends Marionette.ItemView {
  getTemplate() {
    return require('@training/apps/training/templates/_learning_event_list_header.html');
  }

  className() {
    return 'event-item learningevent-item-header';
  }

  templateHelpers() {
    const startMonthYear = dateHelpers.convertDateFormatCustom(this.model.get('scheduledEvent').startDate, 'epoch', 'MMMM, YYYY');
    return {startMonthYear};
  }
}

module.exports = LearningEventListHeaderView;
