const {ItemView} = require('Marionette');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');

const Select2CssPackage = require('@common/libs/Select2CssPackage');
const CSSLoaderService = require('@common/services/cssLoader/CSSLoaderService');

class TagsView extends ItemView {
  getTemplate() {
    return `<form id="tags_form">
      <div id="tag-select-wrapper" class="metadata__input">
        <label><%- t('discover.metadata.tag.label') %></label>
        <div class="tag-select-container">
          <select id="tag-select" class="hidden" aria-label="<%- t('discover.metadata.tag.label') %>" multiple="multiple"></select>
        </div>
      </div>
    </form>`;
  }

  ui() {
    return {
      tagsForm: '#tags_form',
      tagSelect: '#tag-select'
    };
  }

  initialize(options = {}) {
    ({
      tagList: this.tagList,
      hideLabel: this.hideLabel
    } = options);

    CSSLoaderService.registerVersionedFactory(new Select2CssPackage.Factory());
  }

  templateHelpers() {
    return {
      labelCssClass: this.hideLabel ? 'off-screen' : ''
    };
  }

  onRender() {
    const dependencies = [
      this.getSelectLibrary(),
      this.tagList.fetch()
    ].map((x) => {
      return Promise.resolve(x);
    });

    Promise.all(dependencies).then(() => {
      this.ui.tagSelect.toggleClass('hidden', false);
      this.updateTagList();
    });
  }

  getTags() {
    return _.map(this.ui.tagSelect.select2('data'), (data) => {
      return {tag: data.text};
    });
  }

  getSelectLibrary() {
    // The library is chunky, so we're going to load it async to save some space
    // in the bundle since editing an article is more rare in Discover
    const library = import('select2').then(({ default: select}) => {
      return select;
    });

    const css = CSSLoaderService.load(Select2CssPackage.KEY, { pivot: '#branding' });
    return Promise.all([css, library]);
  }

  updateTagList() {
    if (!this.model.isNew()) {
      $.each(this.model.get('tags'), (i, tag) => {
        const option = $('<option>', {
          value: tag.tag,
          text: tag.tag
        }).prop('selected', true);

        this.ui.tagSelect.append(option);
      });
    }

    const jsonTagList = _.map(this.tagList.models, (tag) => {
      let text;
      const id = (text = tag.get('tag'));
      return {
        id,
        text
      };
    });

    const formatListElement = function(state) {
      // Need to return state.text explicitly
      // Otherwise the contents of the list are empty
      return state.text;
    };

    const tagTextUppercase = jsonTagList.map(({text}) => {
      return text.toUpperCase();
    });

    this.ui.tagSelect.select2({
      tags: true,
      data: jsonTagList,
      width: '100%',
      placeholder: I18n.t('discover.metadata.tag.placeholder'),
      templateResult: formatListElement,
      tokenSeparators: [','],

      createTag(tag) {
        //https://stackoverflow.com/questions/30944275/select2-disable-case-sensitive-matches
        // check if the option is already there
        let found = false;

        const term = tag.term.toUpperCase().trim();

        tagTextUppercase.forEach((text) => {
          if (term === text) {
            found = true;
          }
        });

        // if it's not there, then show the suggestion
        if (!found) {
          return {
            id: tag.term,
            text: tag.term
          };
        }

        return undefined;
      }
    });
    this.ui.tagSelect.on('change', () => {
      const selectedTags = this.getTags();
      this.model.set('tags', selectedTags);
    });
  }

  commit() {
    const selectedTags = this.getTags();
    this.model.set('tags', selectedTags);
  }

  onBeforeDestroy() {
    if (this.ui.tagSelect.hasClass('select2-hidden-accessible')) {
      if (!this.isDestroyed && !_.isEmpty(this.ui.tagSelect.data())) {
        this.ui.tagSelect.select2('destroy').remove();
      }
    }
  }
}

module.exports = TagsView;
