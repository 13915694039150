// Module
var code = `<div class="events__row--wrapper">
  <h4 class="event__column date">
    <div class="date_day"><%- dateHelpers.convertDateFormatWeekdayShort(scheduledEvent.startDate) %></div>
    <div class="date_M_d"><%- dateHelpers.convertDateFormatShortDateWithStringsAndNoYear(scheduledEvent.startDate) %></div>
  </h4>

  <div class="event__column info">
    <div class="event-name"><%- title %></div>
    <div class="event-status color-<%- color %>"><%- text %></div>
  </div>
</div>
`;
// Exports
module.exports = code;