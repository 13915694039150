const Marionette = require('Marionette');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');

require('@common/components/user/snapshot/UserSnapshotView.less');

class UserSnapshotView extends Marionette.ItemView {
  getTemplate() {
    return `\
<div class="name-logo"></div>
<div class="user-info">
  <div class="user-name"><%= fullname %></div>
  <div class="meta-text">
  <span class="user-id <%- showUserId ? '' : 'hidden' %>"><%- t('events.scheduledEvents.addUsers.id', {id: employeeId}) %></span>
  <span class="user-location"><%- t('events.scheduledEvents.addUsers.location', {name: location.name}) %></span>
  </div>
</div>\
`;
  }

  className() {
    return 'pull-left user-snapshot';
  }

  ui() {
    return {profileContainer: '.name-logo'};
  }

  constructor(...args) {
    super(...args);
    this.templateHelpers = this.templateHelpers.bind(this);
  }

  templateHelpers() {
    return {showUserId: this.showUserId};
  }

  initalize(options = {}) {
    ({showUserId: this.showUserId} = options);
    return this.showUserId != null ? this.showUserId : (this.showUserId = true);
  }

  onRender() {
    new ProfileIconHandler({user: this.model.toJSON(), profileElement: this.ui.profileContainer});
  }
}

module.exports = UserSnapshotView;
