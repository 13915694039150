const { Model } = require('Backbone');
const DateHelpers = require('@common/libs/dateHelpers');

class PageInsightsOverviewModel extends Model {
  static DAYS_DEFAULTS = {
    reach: '--',
    viewers: '--'
  };

  initialize(options = {}) {
    ({
      pageId: this.pageId,
      tenantTimeZone: this.tenantTimeZone
    } = options);
  }

  defaults() {
    // Define 3 unique, single instances of a Model under unique property names to be passed in to each of the
    // Overview child view (the tiles - top region of the insights page)
    return {
      timelineModel: new Model(),
      first24Model: new Model(PageInsightsOverviewModel.DAYS_DEFAULTS),
      first7Model: new Model(PageInsightsOverviewModel.DAYS_DEFAULTS)
    };
  }

  apiEndpoint() {
    return `pageInsights/${ this.pageId }/overview`;
  }

  parse(resp) {
    const response = super.parse(resp);

    response.timeline.lastModifyDateString = DateHelpers.createDate(response.timeline.lastModifyDate);
    response.timeline.publishDateString = DateHelpers.createDate(response.timeline.publishDate);
    const publishDate = response.timeline.publishDateString;

    // Convert dates to date strings in tenant timezone
    response.timeline.lastModifyDateString = DateHelpers.convertDateFormatShortDateWithStrings(
      response.timeline.lastModifyDateString,
      this.tenantTimeZone
    );
    response.timeline.publishDateString = DateHelpers.convertDateFormatShortDateWithStrings(
      response.timeline.publishDateString,
      this.tenantTimeZone
    );
    response.first24Hours.date = DateHelpers.convertDateFormatLongDateWithStrings(publishDate, this.tenantTimeZone);

    // Calculate 7 days from publish and generate a date range string
    response.first7Days.date = this._get7DayRange(publishDate);

    // Convert reach values to percentages and round to two decimal places
    response.first24Hours.reachPercentage = this._generateReachPercentage(response.first24Hours.reach);
    response.first7Days.reachPercentage = this._generateReachPercentage(response.first7Days.reach);

    // Add the appropriate data to each respective Model instance
    this.get('timelineModel').set(response.timeline);
    this.get('first24Model').set(response.first24Hours);
    this.get('first7Model').set(response.first7Days);

    // Delete the objects on the response after setting up the Models. These will no longer be needed, and shouldn't
    // Be kept around to confuse others
    delete response.timeline;
    delete response.first24Hours;
    delete response.first7Days;

    return response;
  }

  _generateReachPercentage(num) {
    // Server is returning 'Infinity' (when Viewers is 1) or 'NaN' (when Viewers is 0) as a reach value on articles recently created. A cron job responsible for
    // the calculation runs every hour
    if (num === 'Infinity' || num === 'NaN') {
      return '--';
    }

    return (num * 100).toFixed(2);
  }

  _get7DayRange(publishDate) {
    let from = DateHelpers.createDate(publishDate);
    from = from.tz(this.tenantTimeZone);

    let to = DateHelpers.performMomentOperation('add', 6, 'days', from);

    from = DateHelpers.convertDateFormatShortDateWithStrings(from, this.tenantTimeZone);
    to = DateHelpers.convertDateFormatShortDateWithStrings(to, this.tenantTimeZone);

    return `${ from } - ${ to }`;
  }
}

module.exports = PageInsightsOverviewModel;
