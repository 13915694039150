const TableLayoutView = require('@common/components/tableLayout/TableLayoutView');

class RecentPagesTableLayoutView extends TableLayoutView {
  initialize(options = {}) {
    ({
      collection: this.collection,
      rowItemView: this.rowItemView,
      headerNames: this.headerNames = []
    } = options);
  }

  className() {
    return 'community-insights__table';
  }


  getTemplate() {
    return `
      <thead>
        <tr>
          <th rowspan="2" scope="rowgroup" class="ax-font ax-font__caption ax-font__caption--bold community-insights__desktop-column"><%- t('general.published') %></th>
          <th rowspan="2" scope="rowgroup" class="ax-font ax-font__caption ax-font__caption--bold recent-table__title-column"><%- t('general.page') %></th>
          <th colspan="2" scope="colgroup" class="ax-font ax-font__caption community-insights__desktop-column">
            <div class="community-insights-table__subheader ax-font ax-font__caption"><%- t('discover.insights.firstHours') %></div>
          </th>
          <th class="community-insights__mobile-column"><%- t('discover.insights.recentPagesMobileReach') %></th>
          <!-- Empty header for external-link icon column -->
          <th rowspan="2" scope="rowgroup"><p class="off-screen"><%- t('discover.insights.pageInsights') %></p></th>
        </tr>
        <tr>
          <th class="ax-font ax-font__caption ax-font ax-font__caption ax-font__caption--bold community-insights__desktop-column">
            <div class="ax-grid__col ax-grid__col--auto-size">
              <%- t('discover.insights.reach') %>
            </div>
          </th>
          <th class="ax-font ax-font__caption ax-font ax-font__caption ax-font__caption--bold community-insights__desktop-column">
            <div class="ax-grid__col">
              <%- t('discover.insights.engagmentRate') %>
            </div>
          </th>
        </tr>
      </thead>
    <tbody></tbody>
    `;
  }
}

module.exports = RecentPagesTableLayoutView;
