const _ = require('underscore');
const Backbone = require('Backbone');
const Marionette = require('Marionette');

class SelectedState {

  constructor(options = {}) {
    this._isMultiSelect = options.multiSelect || false;
    this._selectedCache = new Backbone.Collection([], {model: options.model});
    this._setOptions = {
      add: true,
      merge: true,
      remove: !this._isMultiSelect
    };

    this.listenTo(this._selectedCache, 'update', this._onCacheUpdate);
    this.listenTo(this._selectedCache, 'change', this._onCacheItemChange);

    this.setSelected(options.initialSelection);
  }

  static TriggeredEventList = [
    'selected',
    'unselected',
    'selected:item:change',
    'selection:added',
    'selection:removed',
    'selection:items:changed',
    'selection:updated'
  ];

  static hasAdditionOnlyChanges = ({ added = [], removed = [], merged = []} = {}) => {
    return added.length > 0 && removed.length === 0 && merged.length === 0;
  }

  static hasRemovalOnlyChanges = ({ added = [], removed = [], merged = []} = {}) => {
    return added.length === 0 && removed.length > 0 && merged.length === 0;
  }

  static hasMergeOnlyChanges = ({ added = [], removed = [], merged = []} = {}) => {
    const hasAdded = added.length > 0;
    const hasRemoved = removed.length > 0;
    const hasMergedChanges = _.some(merged, (mergedModel) => {
      return mergedModel.hasChanged();
    });

    return !hasAdded && !hasRemoved && hasMergedChanges;
  }

  // set the selected item(s)
  setSelected(models, options) {
    this._assertModelsForMultiSelect(models);

    if (!_.isEmpty(models)) {
      this._selectedCache.set(models, Object.assign({}, this._setOptions, options));
    }
  }

  unsetSelected(models, options) {
    this._assertModelsForMultiSelect(models);

    this._selectedCache.remove(models || this._selectedCache.toArray(), options);
  }

  updateSelected(models, options) {
    if (_.isEmpty(models)) {
      this.unsetSelected(models, options);
    } else {
      this.setSelected(models, options);
    }
  }

  getSelected() {
    if (this._isMultiSelect) {
      return this._selectedCache.toArray();
    }
    return this._selectedCache.first();

  }

  isSelected(model) {
    return this._selectedCache.has(model);
  }

  hasSelection() {
    return this._selectedCache.length > 0;
  }

  // Make sure the selected models conform to the _isMultiSelect setting.
  _assertModelsForMultiSelect(models) {
    if (!this._isMultiSelect && ((models && models.length) > 1)) {
      throw new Error('Tried to set multiple selected models when _isMultiSelect = false');
    }
  }

  _onCacheUpdate(collection, options = {}) {
    const {changes} = options;

    this._triggerChangeEvents(changes.merged, options);
    this._triggerRemovedEvents(changes.removed, options);
    this._triggerAddedEvents(changes.added, options);

    // Trigger a general event that the selected state was updated in someway.
    if (!options.silent) {
      Marionette.triggerMethodOn(this, 'selection:updated', this, changes, options);
    }
  }

  _onCacheItemChange(model, options = {}) {
    // Only trigger change event if change was applied to the model directly and not through Collection.set()
    // Duplicate events get fired otherwise.
    if ((options.add == null) && (options.remove == null) && (options.merge == null)) {
      this._triggerChangeEvents([model], options);
    }
  }

  _triggerAddedEvents(added = [], options = {}) {
    // Trigger event for each selected item added
    _.each(added, (model) => {
      model.set('selected', true);
      if (!options.silent) {
        Marionette.triggerMethodOn(this, 'selected', this, model, options);
      }
    });

    // Trigger a general event that at least one item was added
    if (added.length > 0 && !options.silent) {
      Marionette.triggerMethodOn(this, 'selection:added', this, added, options);
    }
  }

  _triggerRemovedEvents(removed = [], options = {}) {
    // Trigger event for each selected item removed
    _.each(removed, (model) => {
      model.unset('selected');
      if (!options.silent) {
        Marionette.triggerMethodOn(this, 'unselected', this, model, options);
      }
    });

    // Trigger a general event that at least one selected item was removed
    if (removed.length > 0 && !options.silent) {
      Marionette.triggerMethodOn(this, 'selection:removed', this, removed, options);
    }
  }

  _triggerChangeEvents(changedModels = [], options = {}) {
    let hasModelChanges = false;

    // Trigger event for each selected item removed
    _.each(changedModels, (model) => {
      const hasChanged = model.hasChanged();
      hasModelChanges |= hasChanged;

      if (hasChanged && !options.silent) {
        Marionette.triggerMethodOn(this, 'selected:item:change', this, model, options);
      }
    });

    // Trigger a general event that at least one selected item was changed
    if (hasModelChanges && !options.silent) {
      Marionette.triggerMethodOn(this, 'selection:items:changed', this, changedModels, options);
    }
  }
}

_.extend(SelectedState.prototype, Backbone.Events);

module.exports = SelectedState;
