import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitFeatures(cssVars: CSSStyleDeclaration) {
  return {
    // Assessment
    colorAssessmentsSelfDirected60: getCssValue('--ax-color-assessments-self-directed-60', cssVars),
    // Achievements
    colorAnswerStreak: getCssValue('--ax-color-answer-streak', cssVars),
    colorTrainingFrequency: getCssValue('--ax-color-training-frequency', cssVars)
  };
}
