const { LayoutView } = require('Marionette');
const ProgressBar = require('@common/components/progress/progressBar/ProgressBar');

const getProgressBarViewDefinition = (options = {}) => {
  return {
    ViewClass: LayoutView,
    className: options.viewClassName,
    template: `
      <div class="progress-bar-region"></div>
      <p class="progress-message" aria-live="assertive"><%- progressBarMessage %></p>`,
    templateHelpers: {
      progressBarMessage: options.message || ''
    },
    regions: {
      progressBar: '.progress-bar-region'
    }
  };
};

const getProgressBarRegionControllers = (options = {}) => {
  return {
    progressBar: {
      viewDefinition: {
        ViewClass: ProgressBar,
        className: 'progress-bar-wrapper',
        value: options.value || 0,
        animate: options.animate || false
      }
    }
  };
};

module.exports = {
  getProgressBarViewDefinition,
  getProgressBarRegionControllers
};
