const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  // rem spacing values (as per ax-variables.less)
  REM_XXL: 4.0,
  REM_XL: 3.2,
  REM_L: 2.4,
  REM_M: 1.6,
  REM_S: 0.8,
  REM_XS: 0.4,
  REM_XXS: 0.2,
  ZERO: 0,

  // if working with px values, ensure you calculate and render a rem value (1rem == 10px)
  PX_TO_REM_MULTIPLIER: 0.1
});
