import {
  Table as MuiTable,
  styled,
  type TableTypeMap as MuiTableTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTableCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTableTypeMap<P = object, D extends React.ElementType = 'table'> = MuiTableTypeMap<P & AxTableCustomProps, D>;

export type AxTableProps<
  D extends ElementType = AxTableTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTableTypeMap<P & AxTableCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTable'
};

const StyledAxTable = styled(MuiTable, styledOptions)<AxTableProps>(() => {
  return {};
});

/**
 * Display sets of data.
 * Tables are implemented using a collection of related components:
 * - • `<AxTableContainer />`: A wrapper that provides horizontally scrolling behavior for the `<AxTable />` component.
 * - • `<AxTable />`: The main component for the table element. Renders as a `<table>` by default.
 * - • `<AxTableHead />`: The container for the header row(s) of `<AxTable />`. Renders as a `<thead>` by default.
 * - • `<AxTableBody />`: The container for the body rows of `<AxTable />`. Renders as a `<tbody>` by default.
 * - • `<AxTableRow />`: A row in a table. Can be used in `<AxTableHead />`, `<AxTableBody />`, or `<AxTableFooter />`. Renders as a `<tr>` by default.
 * - • `<AxTableCell />`: A cell in a table. Can be used in `<AxTableRow />`. Renders as a `<th>` in `<AxTableHead />` and `<td>` in `<AxTableBody />` by default.
 * - • `<AxTableFooter />`: An optional container for the footer row(s) of the table. Renders as a `<tfoot>` by default.
 *
 * ## Links
 * - • [MUI | Tables](https://mui.com/material-ui/react-table/)
 * - • [MUI | Table API](https://mui.com/material-ui/api/table/)
 * - • [Figma | Mockup](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=12172-4719&m=dev)
 */

export const AxTable: MuiOverridableComponent<AxTableTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxTableProps, ref: Ref<HTMLTableElement>) => {
  const tableProps: AxTableProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTable { ...tableProps } ref={ ref }>
      { children }
    </StyledAxTable>
  );
});

export default AxTable;
