const EntitySyncTracker = require('@common/libs/EntitySyncTracker');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const Enum = require('@common/data/enums/Enum');

/*
  Base class for all LoadingControllers that knows how to setup/start/stop the EntitySyncTracker

  Enums:
    'Options': defines all valid options that can be passed in as constructor options.

      'SYNC_TRACKER_CONFIG': (Object) Contains all options to configure how data syncing is tracked for the content.
          This includes which Model/Collection and request Methods should be observed. See `EntitySyncTracker.js` for
          its Option details.
 */

const OptionKeys = Enum({
  SYNC_TRACKER_CONFIG: 'syncTracker'
});

class BaseLoadingController extends LayoutController {
  static Options = OptionKeys;

  constructor(options = {}) {
    super(options);

    ({
      [OptionKeys.SYNC_TRACKER_CONFIG]: this._syncTrackerConfig = this.syncTracker
    } = options);
  }

  syncTracker() {
    return {};
  }

  initializeSyncTracker() {
    let syncTrackerOptions = {};

    if (this._syncTrackerConfig != null) {
      if (this._syncTrackerConfig.call != null) {
        syncTrackerOptions = this._syncTrackerConfig.call(this);
      } else {
        syncTrackerOptions = this._syncTrackerConfig;
      }
    }

    const options = Object.assign(syncTrackerOptions, {
      [EntitySyncTracker.Options.EVENTS_RECEIVER]: this
    });

    this._entitySyncTacker = new EntitySyncTracker(options);
  }

  startEntitySyncTracker() {
    if (this._entitySyncTacker != null) {
      this._entitySyncTacker.start();
    }
  }

  destroyEntitySyncTracker() {
    if (this._entitySyncTacker != null) {
      this._entitySyncTacker.destroy();
    }
  }
}

module.exports = BaseLoadingController;
