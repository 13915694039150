const MenuItemView = require('@common/modules/main/menu/item/MenuItemView');

const NAV_ITEM_FONT = '400 15px Roboto, sans-serif';
const NAV_ITEM_ICON_WIDTH = 32; // width + 8px margin
const NAV_ITEM_PADDING = 48; // 24px padding on each side

class OffscreenMeasurementMenuItemView extends MenuItemView {
  getTemplate() {
    return `
      <div class="<%- linkClass %>">
        <span class="item-icon <%- iconClass %>"></span><span class="item-text"><%- text %></span>
      </div>
    `;
  }

  constructor(options = {}) {
    const viewOptions = $.extend({ model: options.model}, options.model.get('viewOptions'));
    super(viewOptions);
  }

  _getMenuItemTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = this._getMenuItemTextWidth.canvas || (this._getMenuItemTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    let { width } = metrics;
    // Add the icon width, padding, and a 5px buffer to our menu item width calculation
    width += (NAV_ITEM_ICON_WIDTH + NAV_ITEM_PADDING + 5);
    return Math.ceil(width);
  }

  onAttach() {
    let displayWidth = this.getWidth();
    // CI-2141: Some tenants are experiencing a 0 displayWidth that we have been unable to explain. This is a fallback
    // fix to ensure a width is calculated based on the menu item string. This allows our width calculations below to
    // still be relied on, preventing any adverse display issues to the nav menu.
    // https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
    if (displayWidth === 0) {
      displayWidth = this._getMenuItemTextWidth(this.model.get('viewOptions').text, NAV_ITEM_FONT);
    }
    this.model.set('displayWidth', displayWidth);
  }
}

module.exports = OffscreenMeasurementMenuItemView;
