const {
  CertificationTraining,
  IntroductoryTraining,
  RefresherTraining,
  FormalExamTraining
} = require('@common/data/enums/AssessmentType');

const {
  CertificationTopicOptionList,
  IntroductoryTopicOptionList,
  RefresherTopicOptionList,
  FormalExamOptionList
} = require('@training/apps/training/collections/assessments/AssessmentOptionList');

const assessmentTypeToOptionListMap = {
  [CertificationTraining]: CertificationTopicOptionList,
  [IntroductoryTraining]: IntroductoryTopicOptionList,
  [RefresherTraining]: RefresherTopicOptionList,
  [FormalExamTraining]: FormalExamOptionList
};

module.exports = {
  create(assessmentType, models = [], options = {}) {
    const AssessmentOptionList = assessmentTypeToOptionListMap[assessmentType];

    if (AssessmentOptionList == null) {
      throw new Error(`No valid options list for assessment type: ${ assessmentType }!`);
    }

    return new AssessmentOptionList(models, options);
  }
};
