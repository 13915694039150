require('jquery');

const FileUploader = require('@common/libs/file/uploader/FileUploader');

const upload = function(input, options = {}) {
  const {
    url,
    fileType,
    sizeLimit,
    allowedExtensions
  } = options;

  // Imitate jquery ajax operations
  const s = $.ajaxSetup({}, options);
  const callbackContext = s.context != null ? s.context : s;
  const deferred = new $.Deferred();
  const completeDeferred = $.Callbacks( 'once memory' );

  // Default abort message
  let strAbort = 'canceled';

  const uploadRequest = {
    uploadId: null,
    readyState: 0
  };

  const uploader = new FileUploader({
    url,
    fileType,
    sizeLimit,
    allowedExtensions,
    fileInputName: 'file',
    onUpload(id) {
      uploadRequest.uploadId = id;
      strAbort = 'abort';
    },
    onProgress(id, name, loaded, total) {
      return deferred.notifyWith(callbackContext, [id, name, loaded, total]);
    },
    onComplete(id, filename, json) {
      uploadRequest.readyState = 4;
      const statusText = uploadRequest.statusText != null ? uploadRequest.statusText : 'success';
      deferred.resolveWith(callbackContext, [json, statusText, uploadRequest]);
      completeDeferred.fireWith(callbackContext, [uploadRequest, statusText]);
    },
    onError(id, filename, error) {
      uploadRequest.readyState = 0;
      uploadRequest.status = error.status;
      uploadRequest.errCode = error.errCode;
      uploadRequest.errMessage = error.errMessage;
      uploadRequest.responseText = error.responseText;
      const statusText = uploadRequest.statusText != null ? uploadRequest.statusText : 'error';
      deferred.rejectWith(callbackContext, [uploadRequest, statusText, error]);
      completeDeferred.fireWith(callbackContext, [uploadRequest, statusText]);
    }
  });

  uploadRequest.abort = function(statusText = strAbort) {
    uploadRequest.statusText = statusText;
    uploader.cancelUpload(uploadRequest.uploadId);
  };

  // Attach deferreds
  deferred.promise(uploadRequest);
  uploadRequest.success = uploadRequest.done;
  uploadRequest.error = uploadRequest.fail;
  uploadRequest.complete = completeDeferred.add;

  // Install callbacks on deferreds
  for (const method in {success: 1, error: 1, complete: 1, progress: 1}) {
    uploadRequest[method](s[method]);
  }

  s.beforeSend(uploadRequest, s);

  //Force the uploader to upload the file.
  uploader.upload(input);

  return uploadRequest;
};

module.exports = { upload };
