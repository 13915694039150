const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

class AskAQuestionAccessibleModalView extends AccessibleModalView {
  _setModalFocus() {
    const modalElement = this.$el;
    const modalInput = modalElement.find('textarea')[0];
    if (modalInput != null && modalInput.length > 0) {
      modalInput.trigger('focus');
    } else {
      modalElement.attr('tabindex', '-1').trigger('focus');
    }
  }
}

module.exports = AskAQuestionAccessibleModalView;
