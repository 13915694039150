// Module
var code = `<h1 class="page-header page-header--fixed">
  <div class="page-header__content">
    <span class="page-header__icon <%- headerIconClass %>" aria-hidden="true"></span>
    <span class="page-header__title"><%- headerTitle %></span>
  </div>
</h1>

<div
  class="knowledgeitem <%- item.rating %> level<%- ['', 'one', 'two', 'three', 'four', 'five'][item.level] %> status<%- item.progressLevel %> clearfix"
  data-generated-id="<%- item.generatedId %>"
  data-topic-id="<%- item.topic.id %>"
  data-level="<%- item.level %>"
>

  <div class="topicholder">

    <p class="percentage"><%- percentageString %></p>

    <p class="score <%- performanceClass %>"></p>

    <!-- TOPIC, CATEGORY, SUBJECT-->
    <h3 class="areas subscontainer">
      <span class="subs"><%- item.category %>, <%- item.subject %></span>
      <span class="topicname semiboldfont"><%- item.topic.name %> – <%- t('knowledge.level', {level: item.level}) %></span>
    </h3>

  </div>

  <div class="clear"></div>

  <!-- HIDDEN ANSWER AREA -->
  <div class="questions clearfix">
    <ul class="questionswrap clearfix">
      <li class="noquestions hidden"><%- t('extratraining.noquestions') %></li>
    </ul>
  </div>

</div>
`;
// Exports
module.exports = code;