const { Collection } = require('Backbone');

const TrainingCardModel = require('@common/components/trainingCards/TrainingCardModel');
const {
  getCardType,
  getCardActionMetaData,
  tryGetStartableAction
} = require('@common/components/trainingCards/TrainingCardsCollectionHelpers');

class ContinueTrainingCardsCollection extends Collection {

  constructor(models = [], options = {}) {
    super(models, options);
    this.session = options.session;
  }

  get model() {
    return TrainingCardModel;
  }

  apiEndpoint() {
    return '/training/carousel';
  }

  parse(data) {
    data.forEach((card) => {
      const cardType = card[TrainingCardModel.FIELDS.CARD_TYPE];
      const action = tryGetStartableAction(card, this.session) || card[TrainingCardModel.FIELDS.ACTION];
      card[TrainingCardModel.FIELDS.CARD_TYPE] = getCardType(cardType, action);
      card[TrainingCardModel.FIELDS.CARD_ACTION_META_DATA] = getCardActionMetaData(action);
    });

    return data;
  }
}

module.exports = ContinueTrainingCardsCollection;
