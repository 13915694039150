const HubHelper = {

  // Returns
  // [overallAverage, correctAnswerCount, totalAnswerCount]
  knowledgeStats: (knowledgeMapResults) => {
    let totalAnswerCount = 0;
    let correctAnswerCount = 0;
    for (const map of knowledgeMapResults) {
      totalAnswerCount += map.currentMetrics.totalAnswerCount;
      correctAnswerCount += map.currentMetrics.correctAnswerCount;
    }

    const overallAverage = Math.round((correctAnswerCount * 100) / Math.max(totalAnswerCount, 1));
    return [overallAverage, correctAnswerCount, totalAnswerCount];
  },

  // Returns one of: 'BEGINNER', 'POOR', 'FAIR', or 'GOOD'
  getOverallAverageClass(overallAverage, totalAnswerCount = 0) {
    let overallAverageClass = 'BEGINNER';
    if (totalAnswerCount > 0) {
      if (overallAverage >= 80) {
        overallAverageClass = 'GOOD';
      } else if (overallAverage >= 50) {
        overallAverageClass = 'FAIR';
      } else {
        overallAverageClass = 'POOR';
      }
    }
    return overallAverageClass;
  }
};

module.exports = HubHelper;
