import type { TimelineObjectTypes } from '@common/data/types/Timeline';
import { sendWarnLog } from 'LoggingService';
import logging from 'logging';

export type ObjectType = TimelineObjectTypes;

export type ImpressionSource = 'TIMELINE'
  | 'COMMUNITY_TIMELINE'
  | 'COMMUNITY_ARTICLES'
  | 'COMMUNITY_POSTS'
  | 'ARTICLE_SEARCH_RESULTS';

type Impression = {
  objectId: number
  objectType: ObjectType
  impressionDateMs: number
  fromWhere: ImpressionSource
};

let loggedImpressions: Impression[] = [];
const duplicateEventLUT: Record<number, number> = {};
const originTime = Date.now();
let flushIntervalId: number | undefined;
let observer: IntersectionObserver | undefined;

// The IntersectionObserver does not give us any provision to know how many elements are being observed. Define a
// reference counter to keep track; every time it's 0, we'll cancel the flushImpressions interval.
let observableRefCount: number = 0;

export function createObserver(el: HTMLElement, timeOffset: number) {
  if (observer == null) {
    observer = new IntersectionObserver(createObserverCallback(timeOffset), {
      root: el,
      rootMargin: '0px',
      threshold: 1.0
    });
  }
}

export function observeElement(el: HTMLElement | null | undefined, objectId: number, objectType: ObjectType, source: ImpressionSource) {
  if (el != null) {
    $(el).data({
      objectId: objectId,
      objectType: objectType,
      source: source
    });

    observer?.observe(el);
    observableRefCount++;
  }
}

export function unobserveElement(el: HTMLElement | null | undefined) {
  if (el != null) {
    observer?.unobserve(el);
    observableRefCount--;
  }

  if (observableRefCount === 0) {
    cancelFlushInterval();
  }
}

/**
  * Collects intersecting entries, grabs contextual data like the pageId and source, and creates a
  * payload representing an impression that we'll eventually send to an API.
  *
  * @param {Array} entries a list of IntersectionObserverEntry objects to process
  */
function createObserverCallback(timeOffset: number) {
  return (entries: IntersectionObserverEntry[]) => {
    entries.forEach(( entry ) => {
      if (entry.isIntersecting) {
        const objectId: number = $(entry.target).data('objectId');
        const objectType: ObjectType = $(entry.target).data('objectType');
        const source: ImpressionSource = $(entry.target).data('source');
  
        // @see IntersectionObserverEntry.time - `entry.time` is a high-resolution timestamp specified in milliseconds
        // since the creation of the containing document. To get an absolute date-time, we must add `this.originTime`
        // (and also factor in a correction to match to the serverTimeOffsest)
        const impressionDateMs = originTime + entry.time + timeOffset;
  
        if (objectId == null) {
          const error = `Missing item id from source '${ source }'`;
          logging.warn(error);
          sendWarnLog({
            logData: {
              logMessage: 'Missing page id for impression tracking',
              error
            }
          });
          return;
        }
  
        if (!isDuplicateEvent(objectId, impressionDateMs)) {
          loggedImpressions.push({
            objectId,
            objectType,
            impressionDateMs,
            fromWhere: source
          });
        }
      }
    });
  };
}

export function startFlushInterval() {
  if (flushIntervalId == null) {
    flushIntervalId = window.setInterval(flushImpressions, 5000); // business requirements wanted a 5s update with the server
  }
}

/**
  * Typically gets called on a regular interval. Collects all recorded impressions and sends them to the server.
  */
export function flushImpressions() {
  if (loggedImpressions.length > 0) {
    const data = JSON.stringify(loggedImpressions.slice(0));
    loggedImpressions = [];

    $.ajax({
      type: 'POST',
      apiEndpoint: `impressions`,
      skipGlobalHandler: true,
      showSpinner: false,
      data
    });
  }
}

/**
  * Deduplication function for impressions.
  *
  * @param {Integer} id the objectId to check duplicates against.
  * @param {Float} timestamp a date timestamp in ms.
  * @returns {Boolean} true if the objectId has occured within the past second.
  */
function isDuplicateEvent(id: number, timestamp: number) {
  const candidateTimestamp = duplicateEventLUT[id];

  if (!candidateTimestamp || (candidateTimestamp < (timestamp - 1000))) { // our threshold for duplicate events is 1 second (1000ms)
    duplicateEventLUT[id] = timestamp;
    return false;
  }

  return true;
}

/**
  * Cancels the `setInterval()` call to flush the recorded impressions
  */
export function cancelFlushInterval() {
  if (observableRefCount === 0) {
    flushImpressions();
    clearInterval(flushIntervalId);
    flushIntervalId = undefined;
  }
}
