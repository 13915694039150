const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'RESUME',
  'START',
  'CONTINUE',
  'BOOST',
  'BOOST_NEEDS_COACHING',
  'BOOST_UNDERCONFIDENT',
  'BOOST_OVERCONFIDENT',
  'BOOST_RECOMMENDATION',
  'CERTIFIED_LEVEL'
]);
