// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
			<span class="page-header__icon js-icon" aria-hidden="true"></span>
			<span class="page-header__title"><%- t('assessments.select.header.title.' + trainingType) %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
	<div class="page-content">

		<div class="overview-container ax-grid">
			<div class="overview-actions ax-grid__col--12 ax-grid__col--m-9">
				<div class="topic-search auto-complete clearfix" role="search">
					<%= axSearch({ ariaControls: 'assessment-list', fullWidth: true }) %>
					<p class="off-screen js-number-of-results" id="number-of-results" aria-live="polite"></p>
				</div>
				<h2 class="js-instruction select-instruction"><%- instructionString %></h2>
			</div>
			<div class="overview-sidebar ax-grid__col--12 ax-grid__col--m-3">
				<div class="progress-container"></div>
			</div>
			<div class="overview-assessments ax-grid__col--12 ax-grid__col--m-9">
				<div class="topics-container js-topics-container ax-grid" id="assessment-list"></div>
			</div>
		</div>
</div>

`;
// Exports
module.exports = code;