const {
  some,
  has
} = require('underscore');
const Backbone = require('Backbone');

require('@common/libs/behaviors/mutationObservable/MutationObservable');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');

const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const MessagesDefinitionFactory = require('@training/apps/home/hubPreviews/messages/MessagesDefinitionFactory');
const LeaderboardDefinitionFactory = require('@training/apps/home/hubPreviews/leaderboard/LeaderboardDefinitionFactory');
const RewardsDefinitionFactory = require('@training/apps/home/hubPreviews/rewards/RewardsDefinitionFactory');
const EventsDefinitionFactory = require('@training/apps/home/hubPreviews/events/EventsDefinitionFactory');

const { TAB_NAME } = require('@training/apps/training/views/FeedPage');

const {
  REWARDS,
  TOP_PERFORMERS,
  LEARNING_EVENTS,
  FEED,
  CONSOLIDATED_COMMS
} = require('@common/data/enums/HubTileIdentifiers').default;
const HubMenuConfigMap = require('@common/modules/base/HubMenuConfigMap');

const relevantHubTiles = [REWARDS, TOP_PERFORMERS, FEED, LEARNING_EVENTS];

const hasSomeAvailableHubTiles = (availableTiles) => {
  return some(relevantHubTiles, (hubTileIdentifier) => {
    return has(availableTiles, hubTileIdentifier);
  });
};

const generateHubPageNavigator = (hubIdentifier, subFragments = []) => {
  return Backbone.history.navigate.bind(Backbone.history, [HubMenuConfigMap[hubIdentifier].hash, ...subFragments].join('/'), true);
};

const createHubTileFilter = (availableTiles) => {
  return (hubTileIdentifier, definitionFactory) => {
    if (has(availableTiles, hubTileIdentifier)) {
      return definitionFactory;
    }

    return null;
  };
};

module.exports = (availableTiles, messageCollection, rewardsSummaryList, eventsList, authUser) => {
  if (!hasSomeAvailableHubTiles(availableTiles)) {
    return null;
  }

  const isConsolidatedCommsEnabled = has(availableTiles, CONSOLIDATED_COMMS);

  const tileFilter = createHubTileFilter(availableTiles);

  return () => {
    return {
      ViewControllerClass: StackedLayoutViewController,
      viewDefinition: {
        className: 'hub-preview-group',
        childViewOptions: {
          className: 'hub-preview-group__region'
        },
        behaviors: {
          MutationObservable: {
            observeOptions: {
              childList: true,
              subtree: true
            }
          }
        }
      },
      childControllers: [
        tileFilter(REWARDS, RewardsDefinitionFactory(rewardsSummaryList, generateHubPageNavigator(REWARDS))),
        tileFilter(TOP_PERFORMERS, LeaderboardDefinitionFactory(authUser, generateHubPageNavigator(TOP_PERFORMERS))),
        isConsolidatedCommsEnabled ? null : tileFilter(FEED, MessagesDefinitionFactory(messageCollection, generateHubPageNavigator(FEED, [TAB_NAME.MESSAGES]))),
        tileFilter(LEARNING_EVENTS, EventsDefinitionFactory(eventsList, generateHubPageNavigator(LEARNING_EVENTS)))
      ],
      delegateEvents: {
        // Use this to notify children that changes have resulted in layout changes that require resize logic to run.
        'view:dom:mutate': () => {
          triggerResize(true);
        }
      }
    };
  };
};
