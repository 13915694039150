const FixedLayout = require('@common/components/fixedLayout/FixedLayout');

class FixedHeaderLayout extends FixedLayout {
  getTemplate() {
    return `
      <div class="js-fixed fixed-header-region parent-width no-spacing" role="banner"></div>
      <div class="js-content fixed-content-region parent-width no-spacing"></div>
    `;
  }

  regions() {
    return {
      fixedHeaderRegion: '.js-fixed',
      fixedContentRegion: '.js-content'
    };
  }

  toggleHeader(show) {
    this.toggleFixed(show);
  }
}

module.exports = FixedHeaderLayout;
