const File = require('@common/data/models/media/File');
const {
  ImageFile,
  ImageMedia
} = require('@common/data/models/media/ImageMedia');
const {
  VideoFile,
  VideoMedia
} = require('@common/data/models/media/VideoMedia');
const {
  DocumentFile,
  DocumentMedia
} = require('@common/data/models/media/DocumentMedia');

class FileFactory {
  _typeEnum = ['image', 'video', 'document'];

  _mediaClassEnum = {
    default: DocumentMedia,
    document: DocumentMedia,
    image: ImageMedia,
    video: VideoMedia
  };

  _fileClassEnum = {
    default: File,
    document: DocumentFile,
    image: ImageFile,
    video: VideoFile
  };

  _getClassForType(classType, optionType) {
    if (classType === 'media') {
      return this._typeEnum.some((option) => {
        return option === optionType;
      })
        ? this._mediaClassEnum[optionType]
        : this._mediaClassEnum.default;
    } // i.e. classType === 'file' (or some other type of unanticpated type)
    return this._typeEnum.some((option) => {
      return option === optionType;
    })
      ? this._fileClassEnum[optionType]
      : this._fileClassEnum.default;

  }

  createMediaFromJSON(json) {
    const MediaClass = this._getClassForType('media', json.type);

    return new MediaClass(json);
  }

  createFileFromInput($input, options = {}, fileData = {}) {
    const fileType = fileData['file-type'] != null ? fileData['file-type'] : options.fileType;
    const FileClass = this._getClassForType('file', fileType);

    return new FileClass(fileData, options);
  }
}

module.exports = FileFactory;
