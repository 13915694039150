import { DialogTitle } from '@mui/material';

import {
  AxDialogConfirmationTitle,
  type AxDialogConfirmationTitleProps
} from '@common/modules/react/themes/components';

export type AxDialogConfirmationHeaderProps = AxDialogConfirmationTitleProps;

export default function AxDialogConfirmationHeader(props: AxDialogConfirmationHeaderProps) {
  const {
    children,
    qaClassName,
    icon
  } = props;

  const hasIcon = icon != null;
  const hasTitle = children != null;

  if (hasTitle || hasIcon) {
    return <DialogTitle className={ qaClassName }>
      <AxDialogConfirmationTitle icon={ icon } qaClassName={ `${ qaClassName }-title` }>{ children }</AxDialogConfirmationTitle>
    </DialogTitle>;
  }

  return <></>;
}
