/**
 * This behaviour resizes the height of a textarea by manipulating its "rows" attribute.
 * The actual height CSS property must always be "auto".
 *
 * To determine the number of rows that can be shown, an off-screen textarea clone is filled with the content, and
 * styled to match the real textarea. A loop increments the clone's rows until the scrolling disappears; then the
 * number of rows is applied to the real visible textarea. The result is a rather spiffy and smooth vertical resize
 * with no side effects or jitter.
 *
 * Unlike previous versions of this behaviour, it is required that the autosizableContainer is a reference to the
 * textarea itself, not merely a container that has textarea(s) in it.
 *
 * If a textarea need to trigger a resize, for instance if a script alters its .val(), that can be triggered by doing a
 * $el.blur()
 */
const Marionette = require('Marionette');
const Behaviors = require('@common/libs/behaviors/Behaviors');
const TEXTAREA_MINIMUM_ROWS = 1;
const TEXTAREA_MAXIMUM_ROWS = 30;
const $os = require('detectOS');

Behaviors.Autosizable = class Autosizable extends Marionette.Behavior {
  events() {
    return {
      'focus @ui.autosizableContainer': 'handleResize',
      'blur @ui.autosizableContainer': 'handleResize',
      'input @ui.autosizableContainer': 'handleResize',
      'change @ui.autosizableContainer': 'handleResize' // this is necessary when something besides a key click changes the content
    };
  }

  ui() {
    const container = this.getOption('autosizableContainer');
    return {autosizableContainer: container != null ? container : 'textarea.autosizable'};
  }

  initialize(options = {}) {
    ({
      autosizableContainer: this.autosizableContainer,
      resizeOnRender: this.resizeOnRender
    } = options);

    this.createClone = this.createClone.bind(this);
    this.clones = [];
  }

  onAttach() {
    this._findAndResize();
  }

  onDomRefresh() {
    this._findAndResize();
  }

  handleResize(e) {
    this.resizeTextarea(e.target);
  }

  /**
   * removes all the textarea clones when this view is destroyed
   */
  onDestroy() {
    $('.autoresize-textarea-clone').remove();
  }

  _findAndResize() {
    const textarea = this.$el.find(this.ui.autosizableContainer);
    this.resizeTextarea(textarea);
  }

  createClone(el) {
    const $el = $(el); // this relies heavily on jQuery stuff

    // you should be able to set these as attributes on the textarea
    const minRows = $el.attr('minrows') || TEXTAREA_MINIMUM_ROWS;
    const maxRows = $el.attr('maxrows') || TEXTAREA_MAXIMUM_ROWS;

    // we'll need to select this, so give it a random guid
    const guid = 'textarea-clone-' + Math.random()
      .toString(36)
      .substr(2, 5);

    $el.data('clone', guid);
    $el.css('height', 'auto');

    const newClone = $('<textarea></textarea>', {
      rows: minRows,
      maxRows: maxRows,
      id: guid,
      class: $el.attr('class'),
      'aria-hidden': true, // because we don't want screen readers reading this
      'tab-index': -1 // because we don't want people tabbing through it
    })
      .css({
        position: 'absolute',
        top: 0,
        height: 'auto',
        width: $el.width(), // this is important so they wrap at the same width
        visibility: 'hidden'
      })
      .addClass('autoresize-textarea-clone')
      .insertAfter($el);

    this.clones.push(newClone);
  }

  resizeTextarea(el) {
    const $el = $(el);

    const minRows = $el.attr('minrows') && parseInt($el.attr('minrows'), 10) || TEXTAREA_MINIMUM_ROWS;
    const maxRows = $el.attr('maxrows') && parseInt($el.attr('maxrows'), 10) || TEXTAREA_MAXIMUM_ROWS;

    // If we're on legacy edge, this doesn't work.
    // Legacy Edge does not resize textareas on the row attribute change until after a paint (and only if height: auto)
    // So we need to enforce the minRows attribute as rows and set the auto height, then skip the resize logic as
    // best case it only causes a resize on blur to rows = maxRows which causes page formatting jumps and feels like bad UX
    // Legacy Edge is anything prior to v79 (Edge version numbers jump
    // from edgeHTML 18.7... to ~79 being the first stable chromium edge)
    if ($os.browser === 'edge' && $os.version < 78) {
      $el.attr('rows', minRows);
      $el.height('auto'); // To enforce rows attribute in Edge
      return;
    }

    // first check if there is already a clone
    if (!$(el).data('clone')) {
      this.createClone($el);
    }

    // in case some other stupid process set the textarea height... wipe it and make it auto
    $el.height('auto');

    const textareaCloneId = $(el).data('clone');
    const $textareaClone = $('#' + textareaCloneId);

    // as a shortcut, if the textarea is empty we set it to the min.
    if ($el.val() === '') {
      $el.attr('rows', minRows);
      return;
    }

    // textareas that do the autosize thing should be "pre-wrap", so that long lines are wrapped, and
    // newline characters are respected
    $el.css('white-space', 'pre-wrap');

    // This magical CSS property is required for Safari to accept new lines in a textarea, both mobile and
    // desktop. Does no harm to other browsers. Naturally this property has to be the same as the visible textarea
    $textareaClone.css('white-space', 'pre-wrap');

    // put the content into the clone
    $textareaClone.val($el.val());

    // set the clone rows as small as possible to get the real scroll height
    $textareaClone.attr('rows', 1);
    // it works better in all browsers if this is false
    $textareaClone.attr('resize', false);
    // if CSS has given this a height, kill it
    $textareaClone.css('height', 'auto');
    // the width sometimes isn't right, so we need to make sure it's the same as the textarea
    $textareaClone.css('width', $el.width());

    // save the real scroll height
    const scrollHeight = $textareaClone.prop('scrollHeight');

    // increase the number of rows until the content fits without scrolling
    let newRows = minRows >= 0 ? minRows : 0;

    // IE11 magic - textarea has extra spacing between lines, so half the line height to make up for it.
    // Equivalent to doing 2 * num rows
    const lineHeight = parseInt($textareaClone.css('line-height'), 10) * 0.5;

    for (newRows; newRows < maxRows; newRows++) {
      $textareaClone.attr('rows', newRows);
      if ($textareaClone.height() > scrollHeight || $os.browser === 'ie' && lineHeight * newRows > scrollHeight) {
        // this will happen until the cloned textarea is one row too big, which is
        // why the increment happens after the loop
        break;
      }
    }

    // fixes an issue in MSIE11 where the scrollbars flash on for a fraction of
    // a second while we work out the new height of the textarea.
    $el.css('overflow', (newRows === maxRows) ? 'auto' : 'hidden');

    // copy the rows value back to the real textarea
    $el.attr('rows', newRows);
  }
};

module.exports = Behaviors.Autosizable;
