const { Model } = require('Backbone');
const _ = require('underscore');

class QuestionFeedbackModel extends Model {

  initialize(attrs, options) {
    this.context = options || {};

    if (_.isFunction(this.context.isQuestionType) && this.context.isQuestionType()) {
      this.isQuestion = true;
      this.set('id', this._getVariantId());
      this.set('language', window.apps.auth.session.user.get('language'));
    }
  }

  get className() {
    return 'QuestionFeedbackModel';
  }

  apiEndpoint() {
    return 'questionVariant/feedback/submit';
  }

  parse(res) {
    return res.result || res || {};
  }

  getContextType() {
    return this.isQuestion ? this.context.getType() : '';
  }

  _getVariantId() {
    return this._getQuestionVariantData().id;
  }

  _getQuestionVariantData() {
    return this.isQuestion ? this.context.getData().variants[0] : {};
  }

  getContextText() {
    return this._getQuestionVariantData().questionText || '';
  }
}

module.exports = QuestionFeedbackModel;
