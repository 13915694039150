const _ = require('underscore');
const RichContentHelper = require('@common/libs/RichContentHelper');

const PostsRichContentHelper = _.clone(RichContentHelper);

PostsRichContentHelper.imageTemplate = _.tpl(`\
  <figure class="page__media media--image" data-media-id="<%- id %>" contenteditable="false">
    <div class="image-placeholder"></div>
  </figure>\
  `);

PostsRichContentHelper.videoTemplate = _.tpl(`\
  <figure class="page__media media--video" data-media-id="<%- id %>" contenteditable="false">
    <img class="video-placeholder" data-media-id="<%- id %>" />
  </figure>\
  `);

PostsRichContentHelper.embeddedVideoPlaceholderTemplate = _.tpl(`\
  <figure class="page__media media--embeddedVideo" data-media-id="<%- id %>" data-source="<%- source %>" contenteditable="false">
    <img class="video-placeholder" data-media-id="<%- id %>" />
  </figure>\
  `);

module.exports = PostsRichContentHelper;

