const AppHelpers = require('@common/libs/helpers/app/AppHelpers');
const MediaFactory = require('@common/libs/file/MediaFactory');

const Form = require('@common/components/forms/Form');
const logging = require('logging');

class MediaInput extends Form.Editor {
  getTemplate() {
    // For Safari, we need to specify "video/mp4" to be able to upload mp4 files
    return `\
    <input
      type="file"
      <% if (acceptedFileTypes) { %>
      accept = "<%= acceptedFileTypes %>"
      <% } else if (!acceptAllFileTypes) { %>
      accept = "image/*<%= videoAllowed ? ',video/*, video/mp4':'' %>"
      <% } %>
      id="<%= cameraInputId %>"
      tabindex="-1"
    />\
    `;
  }

  events () {
    return {
      'change @ui.cameraFileInput': 'onChangeFileInput'
    };
  }

  ui() {
    return {
      cameraFileInput: `#${ this.cameraInputId }`
    };
  }

  templateHelpers() {
    return {
      cameraInputId: this.cameraInputId,
      videoAllowed: this.fileOptions.videoAllowed,
      acceptAllFileTypes: this.acceptAllFileTypes,
      acceptedFileTypes: this.acceptedFileTypes
    };
  }

  preinitialize() {
    const uid = AppHelpers.generateGUID();
    this.cameraInputId = `camera-file-input-${ uid }`;
  }

  initialize(options = {}) {
    this.options = options.options != null ? options.options : options;

    ({
      fileFactory: this.fileFactory,
      fileOptions: this.fileOptions = {},
      acceptAllFileTypes: this.acceptAllFileTypes = false,
      acceptedFileTypes: this.acceptedFileTypes
    } = options);

    if (this.acceptAllFileTypes) {
      this.fileOptions = Object.assign({}, this.fileOptions, {
        videoAllowed: true,
        documentsAllowed: true
      });
    } else {
      this.fileOptions = Object.assign({ videoAllowed: true }, this.fileOptions);
    }

    const { $cameraButton } = options;

    this._mediaModel = null;
    this.setError(null);

    this._setupInputs($cameraButton);
  }

  setValue(value) {
    if (!this.isRendered) {
      this.render();
    }

    try {
      const mediaValue = MediaFactory.createMediaFromValue(this.fileFactory, value, this.fileOptions);
      this._setMediaValue(mediaValue);
    } catch (error) {
      this._setError(error.message);
      this.onChange();
    }
  }

  getValue() {
    return this._mediaModel;
  }

  getError() {
    return this._error;
  }

  setError(error) {
    this._error = error;
  }

  clearError() {
    this._error = null;
  }

  onChangeFileInput(e) {
    // XXX - We always want to trigger a change event when the file input changes so we need to do everything
    // ourselves here instead of going through setValue() which only triggers a change event when there's an error.
    // Not pretty, I know. :(
    try {
      const mediaValue = MediaFactory.createMediaFromValue(this.fileFactory, $(e.target), this.fileOptions);
      this._setMediaValue(mediaValue);
    } catch (error) {
      this._setError(error.message);
    }


    // force recreating the fileInputs, so the file won't unexpectedly wrap an
    // input with no value. This is because Chrome mutates the value of a
    // file-input if you select something, and then click cancel.
    this.render();

    this.onChange();
    return false;
  }

  onBeforeDestroy() {
    if (this.$cameraLabel != null) {
      this.$cameraLabel.off();
    }
  }

  reset() {
    this.setValue(null);
    this.onChange();
  }

  _setMediaValue(newMedia) {
    this._mediaModel = newMedia;
  }

  _setError(error) {
    logging.error(error);
    this.setError(error);
    this._setMediaValue(null);
  }

  _setupInputs($cameraButton) {
    if ($cameraButton != null) {
      // unwrap any other labels that might already be wrapped around these buttons.
      this._unwrap($cameraButton, 'label');

      // wrap the buttons in labels so the file inputs can respond to click events
      $cameraButton.wrap(`<label for="${ this.cameraInputId }" class="camera-file-input-label"></label>`);
    }
  }

  _unwrap($el, selector) {
    return ($el != null ? $el.parent(selector).not('body')
      .each(() => {
        return $(this).replaceWith(this.childNodes);
      }) : undefined);
  }
}

module.exports = MediaInput;
