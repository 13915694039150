const StackedCollectionView = require('@common/components/view/stacked_collection_view/StackedCollectionView');
const MenuPosition = require('@common/modules/main/menu/MenuPosition');

const CSS_CLASS_NAME_BY_POSITION = {
  [MenuPosition.Top]: 'menu-list-top',
  [MenuPosition.Bottom]: 'menu-list-bottom'
};

class MenuItemCollectionView extends StackedCollectionView {
  constructor(options = {}) {
    super(options);
    ({
      menuPosition: this.menuPosition
    } = options);
  }

  get className() {
    const typeCssClass = CSS_CLASS_NAME_BY_POSITION[
      this.type || this.options.menuPosition
    ];
    return `menu-list ${ typeCssClass }`;
  }

  get tagName() {
    return 'ul';
  }

  toggleHidden(toggle = false) {
    this.$el.toggleClass('hidden', toggle);
  }

  toggleLockedToTop(toggle = false) {
    this.$el.toggleClass('menu-list-locked-to-top', toggle);
  }
}

module.exports = MenuItemCollectionView;
