const Enum = require('@common/data/enums/Enum');

module.exports = Enum({
  DailyTraining: 'DailyTraining',

  // It's worth noting that this Assessment Type actually now returns modules and questions
  // but in the past it returned only questions. There is hair on the server for migrating this,
  // so it was left alone for now.
  ExtraTraining: 'ExtraTrainingQuestions',

  // Tasks are an oddball, maybe shouldn't be in here but it's tightly coupled with "assessments" for now
  TaskTraining: 'TaskTraining',
  EventEnrollment: 'EventEnrollment',
  EvaluationTraining: 'EvaluationTraining',

  CertificationTraining: 'CertificationTraining',
  IntroductoryTraining: 'IntroductoryTraining',
  RefresherTraining: 'RefresherTraining',
  FormalExamTraining: 'FormalExamTraining'
}, {
  supportsQuestions(assessmentType) {
    return [
      this.DailyTraining,
      this.ExtraTraining,
      this.CertificationTraining,
      this.IntroductoryTraining,
      this.RefresherTraining,
      this.FormalExamTraining
    ].includes(assessmentType);
  },
  supportsHistoricalRetake(assessmentType) {
    return [
      this.CertificationTraining,
      this.IntroductoryTraining,
      this.RefresherTraining,
      this.FormalExamTraining
    ].includes(assessmentType);
  }
});
