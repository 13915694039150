// Module
var code = `<div class="modalHeader">
  <h1><%- t('discover.report.title') %></h1>
  <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
</div>
<form id="report-form">
  <p class="report-type-prompt ax-font--no-margin"><%- t('discover.report.type.prompt') %></p>
  <div id="report-type-select">
    <div>
      <ul
        class="pill-container"
        role="radiogroup"
        name="reportTypeSelector"
        data-field="reportType"
        data-options="pageReportTypeListOptions"
        data-editor="PillSelect">
      </ul>
    </div>
    <div class="report-comment-wrapper">
      <label for="report-comment"><%- t('discover.report.comment.label') %></label>
      <textarea id="report-comment" data-field="comment" placeholder="<%- t('discover.report.comment.placeholder') %>" />
    </div>
  </div>
</form>
`;
// Exports
module.exports = code;