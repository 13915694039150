const Marionette = require('Marionette');

class LastItemReactionDetailView extends Marionette.ItemView {

  getTemplate() {
    const key = this.options.reactionsCount === 1 ? 'reactionsShowOneResult' : 'reactionsShowResults';
    return `<div class="collection-footer"><%- t('discover.insights.${ key }', {reactionsCount: reactionsCount}) %></div>`;
  }

  templateHelpers() {
    return {
      reactionsCount: this.options.reactionsCount
    };
  }

  className() {
    return 'userview-reaction';
  }
}

module.exports = LastItemReactionDetailView;
