const { Model } = require('Backbone');
const Community = require('@common/data/models/Community');
const _ = require('underscore');

const RELEVANT_FIELDS = ['pageId', 'communityId'];

class CommunityPin extends Model {
  initialize() {
    this.destroy = this.destroy.bind(this);
  }

  url() {
    return `/axonify/${ window.apps.auth.getAuthFragment() }/pageCommunityPin`;
  }

  parse(resp = {}) {
    const entity = resp.entity;
    return _.pick(entity, RELEVANT_FIELDS);
  }

  destroy() {
    // Save as a DELETE because server expects a DELETE request with a request body
    return this.save(null, { type: 'DELETE' });
  }
}


class PageSharedCommunity extends Model {
  parse(resp) {
    this.community = new Community(resp.community);

    if (resp.isPinned) {
      resp.communityPin = new CommunityPin({
        pageId: resp.pageId,
        communityId: this.community.get('id')
      });
    }

    return resp;
  }

  initializePinAndSave() {
    this.initializePin();
    return this.get('communityPin').save({}, { type: 'POST' });
  }

  initializePin() {
    this.set('communityPin', new CommunityPin({
      pageId: this.get('pageId'),
      communityId: this.community.get('id')
    }));
  }

  togglePin() {
    if (this.isPinToggleInProgress || !this.get('userCanPin')) {
      return $.Deferred().reject();
    }

    this.isPinToggleInProgress = true;
    const communityPin = this.get('communityPin');

    let pinDeferred;
    if (communityPin === undefined) {
      pinDeferred = this.initializePinAndSave.bind(this);
    } else {
      pinDeferred = this.get('communityPin').destroy;
      this.unset('communityPin');
    }

    return pinDeferred()
      .always(() => {
        this.isPinToggleInProgress = false;
      });
  }
}

module.exports = PageSharedCommunity;
