const { LayoutView } = require('Marionette');

require('@common/components/titleHeader/TitleHeader.less');

class TitleHeaderLayout extends LayoutView {

  className() {
    return 'title-header';
  }

  getTemplate() {
    return `
      <div class="title-header__badge-region"></div>
      <div class="title-header__title-region"></div>
    `;
  }

  regions() {
    return {
      badgeRegion: '.title-header__badge-region',
      titleRegion: '.title-header__title-region'
    };
  }
}

module.exports = TitleHeaderLayout;
