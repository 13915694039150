// Module
var code = `<div class="modalHeader">
  <h1><%- t('auction.confirmPurchase') %></h1>
  <button class="close icon-remove" aria-label="<%- t('general.close') %>"></button>
</div>
<div class="buynowmodal placebid">
  <div class="notice" aria-live="assertive"></div>

  <% if (model.prize.prizeImg) { %>
  <div
    role="button"
    tabindex="0"
    aria-label="<%= t('auction.imageZoomLabel', { prizeName: model.prize.name }) %>"
    class="ax-button ax-button--ghost itemimage image-wrapper"
  ></div>
  <% } %>

  <div class="title ax-font ax-font__display"><%= model.prize.name %></div>
  <p class="buynowask ax-font--no-margin">
    <%= t('auction.buynowmodal', {price: numberWithCommas(model.buyNowPrice)}) %>
  </p>
</div>

<div class="biddetails clearfix">
  <div class="biddetailsinner ax-grid ax-grid--no-gutter">

    <p class="biddetail balance buynow">
      <span class="point-balance ax-font ax-font__caption"><%- t('auction.pointBalance') %></span>
      <span class="amountbid ax-font ax-font__m ax-font--no-margin"><%- numberWithCommas(pointBalance) %></span>
    </p>

  </div>
</div>
`;
// Exports
module.exports = code;