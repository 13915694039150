const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const FlashWrapperLayout = require('@common/modules/main/flashWrapper/FlashWrapperLayout');

const FlashWrapperRegionControllerDefinitionFactory = require('@common/modules/main/flashWrapper/FlashWrapperRegionControllerDefinitionFactory');

module.exports = (contextModel) => {
  return {
    ViewControllerClass: LayoutController,
    viewDefinition: FlashWrapperLayout,
    regionControllers: FlashWrapperRegionControllerDefinitionFactory(contextModel)
  };
};
