const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const LayeredLayout = require('@common/components/layeredLayout/LayeredLayout');

const LayerRegionControllerDefinitionFactory = require('@common/modules/main/layer/LayerRegionControllerDefinitionFactory');
const LayerContextConfig = require('@common/modules/main/layer/LayerContextConfig');

module.exports = (contextModel) => {
  return {
    id: 'content-layers',
    ViewControllerClass: LayoutController,
    viewDefinition: LayeredLayout,
    regionControllers: LayerRegionControllerDefinitionFactory(contextModel),
    delegateEvents: {
      'view:inflate': (controller) => {
        controller.listenTo(contextModel, `change:${ LayerContextConfig.Keys.CONTENT_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('contentRegion', controllerDefinition, options.showOptions);
        });

        controller.listenTo(contextModel, `change:${ LayerContextConfig.Keys.OVERLAY_CONTROLLER_DEFINITION }`, (state, controllerDefinition, options) => {
          controller.swapRegionController('overlayRegion', controllerDefinition, options.showOptions);
        });

      }
    }
  };
};
