const { Model } = require('Backbone');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const MediaHelpers = require('@common/libs/file/MediaHelpers');
const FileFactory = require('@common/libs/file/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

const CommunitiesManagementAccessLevelHelpers = require('@training/apps/search/communitiesManagement/CommunitiesManagementAccessLevelHelpers');
const CommunityAccessLevelEnum = require('@common/data/enums/CommunityAccessLevelEnum');
const CommunityAccessType = require('@training/apps/training/enums/CommunityAccessType');
const TranslatableStringValidators = require('@common/components/forms/validators/TranslatableStringValidators');

const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const CommunityType = require('@training/apps/training/enums/CommunityType');
const FroalaRichContentHelper = require('@common/libs/froala/FroalaRichContentHelper');

class CommunitySettingsModel extends Model {
  get className() {
    return 'CommunitySettingsModel';
  }

  defaults() {
    return {
      names: new LocalizableString(),
      descriptions: new LocalizableString(),
      thumbnailId: null,
      thumbnailImage: null,
      hiddenFromAdmin: false,
      includeSubteams: false,
      recommendationsEnabled: true,
      questionsEnabled: false,
      postsEnabled: false,
      articlesEnabled: true,
      commentsEnabled: false,
      publishAccessLevel: CommunityAccessLevelEnum.EDIT,
      groupAccess: [],
      everyoneAccessLevel: CommunityAccessLevelEnum.NONE,
      type: CommunityType.OTHER,
      communityAccessType: CommunityAccessType.GROUP,
      includeInConversationalSearch: false,
      hideFromGlobalTimeline: TenantPropertyProvider.get().getProperty('contentHiddenFromTimelineByDefault') || false
    };
  }

  initialize(attrs, options = {}) {
    ({
      id: this.id,
      isCommsEnabled: this.isCommsEnabled
    } = options);

    // If this is a new community, we have to additionally check if the Tenant is paying for Advanced Comms
    // and if they are, we turn Reactions on by default.
    // This is also to prevent sending true to the server when the tenant does not have AdvancedComms enabled
    if (!this.id) {
      this._setReactionsDefault();
    }

    this.userType = window.apps.auth.session.user.getUserType().toLowerCase();
  }

  apiEndpoint() {
    return '/pageCommunities/config';
  }

  fetch(options = {}) {
    options.apiEndpoint = '/pageCommunities/config/' + this.id;
    return super.fetch(options);
  }

  parse(resp, options) {
    const data = super.parse(resp, options);

    if (data.thumbnailImage != null) {
      data.originalMedia = {
        cropped: data.thumbnailImage.cropped,
        file: data.thumbnailImage.originalFile
      };
    }

    if (data.names != null) {
      data.names = new LocalizableString(data.names);
    }

    if (data.descriptions != null) {
      data.descriptions = this.cleanDescriptions(data.descriptions);
    }

    if (data.everyoneAccessLevel != null && !Number.isInteger(data.everyoneAccessLevel)) {
      data.everyoneAccessLevel = CommunitiesManagementAccessLevelHelpers.toInteger(data.everyoneAccessLevel);
    }

    if (data.communityAccessType) {
      data.communityAccessType = data.communityAccessType.toUpperCase();
    }

    if (!data.type) {
      data.type = CommunityType.OTHER;
    } else {
      data.type = data.type.toLowerCase();
    }

    return data;
  }

  cleanDescriptions(descriptions) {
    const cleanedDescriptions = {};

    if (typeof descriptions.default === 'string') {
      const defaultClean = FroalaRichContentHelper.cleanContent(descriptions.default);
      cleanedDescriptions.default = defaultClean.content;
    }

    if (descriptions.translations != null) {
      cleanedDescriptions.translations = {};

      Object.keys(descriptions.translations).forEach((lang) => {
        const rawContent = descriptions.translations[lang];
        const cleaned = FroalaRichContentHelper.cleanContent(rawContent);
        cleanedDescriptions.translations[lang] = cleaned.content;
      });

      return new LocalizableString(cleanedDescriptions);
    }

    return descriptions;
  }

  validators() {
    return {
      names: [TranslatableStringValidators.required]
    };
  }

  _stripHtmlFromTranslations(propertyName) {
    let changed = false;
    const localizableString = this.get(propertyName);
    _.each(localizableString.getValues(), (value, lang) => {
      if (value) {
        const stripped = HTMLHelpers.stripHtmlUnsafe(value);
        localizableString.setValueForLanguage(lang, stripped);
        changed |= stripped !== value;
      }
    });

    const defVal = localizableString.getDefault();
    if (defVal) {
      const stripped = HTMLHelpers.stripHtmlUnsafe(defVal);
      localizableString.setDefault(stripped);
      changed |= stripped !== defVal;
    }
    return changed;
  }

  save(attrs, options = {}) {
    const deferred = $.Deferred();
    const _finalSave = () => {
      const namesChanged = this._stripHtmlFromTranslations('names');
      if (namesChanged) {
        window.app.layout.flash.warning( I18n.t('communities.unsafeContentRemoved'));
      }

      const imageData = this.get('thumbnailImage');
      if (imageData != null && imageData.get('uuid') != null) {
        this.set('thumbnailId', imageData.get('uuid'));
      }

      // Remove 'everyone' group from group access list & convert the access values to the strings the server expects
      if (this.get('selectedGroups') != null) {
        const groups = this.get('selectedGroups');
        groups.remove(-1, { silent: true });
        const accessGroups = [];
        groups.each((group) => {
          accessGroups.push({
            groupId: group.get('id'),
            accessLevel: CommunitiesManagementAccessLevelHelpers.toString(group.get('accessLevel'))
          });
        });
        this.set('groupAccess', accessGroups);
        this.unset('selectedGroups');
      }

      this.set('everyoneAccessLevel', CommunitiesManagementAccessLevelHelpers.toString(this.get('everyoneAccessLevel')));

      this.unset('originalMedia');

      this.unset('permittedActions');
      this.unset('authorablePageTypes');

      super.save(attrs, options)
        .then(deferred.resolve)
        .fail(deferred.reject);
    };

    const _mediaPollError = () => {
      return deferred.reject(I18n.t('general.upload.file.error'));
    };

    if (this.get('thumbnailImage') && MediaHelpers.isCropDirty(this.get('originalMedia'), this.get('thumbnailImage'))) {
      this._uploadImage()
        .then(() => {
          this.set('thumbnailImage', this.thumbnailImage);
          MediaHelpers.mediaImageFileSizesStatusPoll(this.thumbnailImage, _finalSave, _mediaPollError);
        })
        .fail((model, xhr) => {
          deferred.reject(xhr);
        });
    } else {
      _finalSave();
    }

    return deferred.promise();
  }

  _uploadImage() {
    this.thumbnailImage = this._prepareThumbnailForUpload(this.get('thumbnailImage'));
    return MediaHelpers.saveMedia(this.thumbnailImage);
  }

  _prepareThumbnailForUpload(imageMedia) {
    imageMedia.set('maintainAspectRatio', false);
    imageMedia.set('fixedSizes', this.thumbnailSizes);

    // Media upload endpoint only allows for creation, PUT not aloud so unset id and treat this as a new image
    const data = imageMedia.omit(['sizes', 'uuid', 'id']);
    return MediaFactory.createMediaFromJSON(FileFactory, data);
  }

  getName() {
    const userLanguage = this._getDefaultLanguage();
    const localizableString = this.get('names');
    const communityName = localizableString.getValueForLanguage(userLanguage);

    return communityName;
  }

  _getDefaultLanguage() {
    const userDefaultLanguage = window.apps.auth.session.user.get('language') || 'EN';
    // Handle situation where a user's language may not be set
    if (userDefaultLanguage === 'XX') {
      return TenantPropertyProvider.get().getProperty('defaultLanguage');
    }
    return userDefaultLanguage;
  }

  isArchived() {
    return this.get('isArchived');
  }

  canDeleteCommunity() {
    return this.get('permittedActions').DELETE_COMMUNITY;
  }

  canArchiveCommunity() {
    return this.get('permittedActions').ARCHIVE_COMMUNITY;
  }

  canEditCommunitySettings() {
    return this.get('permittedActions').EDIT_COMMUNITY_SETTINGS && !this.isArchived();
  }

  _setReactionsDefault() {
    return this.set('reactionsEnabled', this.isCommsEnabled);
  }

  archiveCommunity(options = {}) {
    $.ajax(_.extend(options, {
      type: 'PUT',
      apiEndpoint: `/pageCommunities/config/${ this.get('id') }/archive`
    }))
      .then(() => {
        window.app.layout.flash.success(I18n.t('communitiesManagement.communities.archive.success'));
        this.collection.fetch();
      })
      .fail((xhr) => {
        this._onCommunityError(xhr);
      });
  }

  restoreCommunity(options = {}) {
    $.ajax(_.extend(options, {
      type: 'PUT',
      apiEndpoint: `/pageCommunities/config/${ this.get('id') }/restoreCommunity`
    }))
      .then(() => {
        window.app.layout.flash.success(I18n.t('communitiesManagement.communities.restore.success'));
        this.collection.fetch();
      })
      .fail((xhr) => {
        this._onCommunityError(xhr);
      });
  }

  deleteCommunity() {
    this.destroy({
      wait: true,
      dataType: 'text', // this prevents the system from trying to parse an empty string as JSON.
      success() {
        const successText = I18n.t('communitiesManagement.communities.success');
        window.app.layout.flash.success(successText);
      }
    }).fail( (xhr) => {
      const exception = AxonifyExceptionFactory.fromResponse(xhr);
      const errCode = exception.getErrorCode();

      if (errCode !== AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_VALID_ENTITY) {
        const errorText = I18n.t(`errors.Community.${ errCode }`);
        if (errorText) {
          window.app.layout.flash.error(errorText);
          xhr.skipGlobalHandler = true;
        }
      }
    });
  }

  _onCommunityError(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();
    if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t('discover.pageAccess.error.3017'));
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t(`discover.pageAccess.community.error.3001`));
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_TOO_MANY_REQUESTS) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t(`discover.pageAccess.community.error.3060`));
    }
  }
}

module.exports = CommunitySettingsModel;
