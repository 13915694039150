const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const { ItemView } = require('Marionette');
const Form = require('@common/components/forms/Form');
const CheckboxState = require('@common/components/forms/editors/checkboxes/CheckboxState');

require('@common/components/forms/editors/checkboxes/Form.Editor.CascadingSelectCheckboxes');

class GroupAccessItemView extends ItemView {
  initialize(options = {}) {
    ({
      accessLevelsList: this.accessLevelsList,
      values: this.values
    } = options);

    this.listenTo(this.model, 'change:accessLevel', (group, accessLevel) => {
      _.defer(this._onChangeAccessLevel.bind(this), group, accessLevel);
    });
    this.listenTo(this.model, 'change:checkboxState', this._onChangeCheckboxState);
  }

  tagName() {
    return 'tr';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/accessSettings/GroupAccessItemView.html');
  }

  templateHelpers() {
    return {
      isEveryoneGroup: this._isEveryoneGroup(this.model),
      removeGroupLabel: I18n.t('communitiesManagement.accessSettings.groupTable.removeGroup', {
        name: this.model.get('name')
      })
    };
  }

  ui() {
    return {
      form: '.js-form'
    };
  }

  triggers() {
    return {
      'click .js-delete-btn': 'group:delete'
    };
  }

  onRender() {
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        accessLevelsList: this.accessLevelsList
      }
    });
  }

  setState(values, state) {
    if (values != null) {
      const editor = this.form.fields.accessLevel.editor;
      if (values.length > 0) {
        editor.setState(state, values);
      } else {
        this.clearState();
      }
    }
  }

  clearState() {
    this.form.fields.accessLevel.editor.clearState();
  }

  _onChangeAccessLevel(groupModel, accessLevel) {
    // Special case where we must absolutely make sure the checkbox is inactive when its value === everyoneAccessLevel
    if (this.model.get('id') !== -1 && accessLevel === this.model.get('everyoneAccessLevel')) {
      const valuesClone = [...this.values];
      valuesClone.splice(this.values.indexOf(accessLevel) + 1);
      this.setState(valuesClone, CheckboxState.OVERRIDDEN);
    }
  }

  _onChangeCheckboxState(groupModel, checkboxState) {
    const editor = this.form.fields.accessLevel.editor;
    const accessLevel = this.model.get('accessLevel');

    // Protect against any possibility of this logic running when accessLevel > everyoneAccessLevel
    if ((this.model.get('everyoneAccessLevel') >= accessLevel) || accessLevel == null) {
      if (checkboxState.inactive != null || checkboxState.disabled != null) {
        editor.clearState();
        this.setState(checkboxState.inactive, CheckboxState.OVERRIDDEN);
        this.setState(checkboxState.disabled, CheckboxState.DISABLED_SELECTED);
      } else {
        // If inactive and disabled states are undefined, fallback to the group's accessLevel value
        editor.setValue(accessLevel);
      }
    } else {
      // Also fallback to group's accessLevel value if it's greater than the everyone level
      editor.setValue(accessLevel);
    }
  }

  _isEveryoneGroup(group) {
    return group.get('id') === -1;
  }
}

module.exports = GroupAccessItemView;
