const I18n = require('@common/libs/I18n');
const StringValidators = require('@common/components/forms/validators/StringValidators');

module.exports = {
  maxLength(maxLength, context) {
    return function(value) {
      const errors = Object.values(value.getValues()).filter( (v) => {
        return (v.length > maxLength);
      })
        .map((v) => {
          return I18n.t('UIKit.Form.errors.maxCharactersNamed', {
            maxLength,
            context: context || v
          });
        });
      if (errors.length > 0) {
        return errors;
      } return null;
    };
  },

  required(value, attrs, className, fieldName) {
    const hasSomeTextualValue = value.hasSomeTextualValue();
    if (!hasSomeTextualValue) {
      return StringValidators.getRequiredValueErrorMessage(className, fieldName);
    }
    return undefined;
  }
};
