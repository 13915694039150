const Enum = require('@common/data/enums/Enum');

const Behaviors = require('@common/libs/behaviors/Behaviors');
const ScrollEvents = require('@common/libs/behaviors/scrollable/ScrollEvents');

const InfiniteCollection = require('@common/components/infiniteCollection/InfiniteCollection');

/**
 * InfiniteCollectionPageLoader
 * Attach this Behavior to any view that has a scroll parent defined at a heigher level then where the view is
 * created, the collection will automatically load the next page once the scroll parent has reached the scroll bottom.
 * NOTE: the collection must be an InfiniteCollection.
 */

const OptionKeys = Enum(Object.assign({}, ScrollEvents.Options, {
  AUTO_INITIAL_FETCH: 'autoInitialFetch',
  COLLECTION: 'collection'
}));

Behaviors.InfiniteCollectionPageLoader = class InfiniteCollectionPageLoader extends ScrollEvents {

  static Options = OptionKeys;

  defaults() {
    return {
      [OptionKeys.AUTO_INITIAL_FETCH]: true,
      [OptionKeys.BOTTOM_BUFFER]: 500
    };
  }

  initialize(options = {}) {
    super.initialize(options);

    this._collection = this.getOption(OptionKeys.COLLECTION) || this.view.getOption('collection');

    if (!(this._collection instanceof InfiniteCollection)) {
      throw new Error('You must set an instance of InfiniteCollection in order to use InfiniteCollectionPageLoader.');
    }
  }

  _setupScrollableHooks() {
    super._setupScrollableHooks();

    if (this.getOption('autoInitialFetch')) {
      this._loadPage();
    }
  }

  onScroll(scrollData, changes) {
    const hasStructuralChanges = ScrollEvents.hasStructuralChanges(changes);
    const hasInBottomBufferChanged = ScrollEvents.hasDataChanges(changes, ScrollEvents.ScrollEventKeys.IN_BOTTOM_BUFFER);
    const isInBottomBuffer = scrollData[ScrollEvents.ScrollEventKeys.IN_BOTTOM_BUFFER];
    const hasEnteredBottomBuffer = hasInBottomBufferChanged && isInBottomBuffer;

    if (hasStructuralChanges || hasEnteredBottomBuffer) {
      this._loadPage();
    }
  }

  _loadPage() {
    const {
      scrollBottomBuffer,
      scrollVerticalOverflow
    } = this.scrollEventState.toJSON();

    if ((scrollBottomBuffer || !scrollVerticalOverflow) && !this._collection.isCurrentPagePending()) {
      this._collection.getNextPage();
    }
  }
};

module.exports = Behaviors.InfiniteCollectionPageLoader;
