import { type PropsWithChildren } from 'react';

import {
  DialogContent,
  DialogContentText
} from '@mui/material';

export type AxDialogDefaultContentProps = PropsWithChildren<{
  qaClassName: `qa-${ string }`
  dividers?: boolean
}>;

export default function AxDialogDefaultContent(props: AxDialogDefaultContentProps ): JSX.Element {
  const {
    dividers = true,
    qaClassName,
    children
  } = props;

  const content = typeof children === 'string'
    ? <DialogContentText component='div' className={ `${ qaClassName }-text` }>{ children }</DialogContentText>
    : children;

  return children != null ? <DialogContent dividers={ dividers } className={ qaClassName }>
    { content }
  </DialogContent> : <></>;
}
