/*
# CollectionLayoutViewController

## Availible Options

`collection`: (Required) Backbone.Collection

`ChildViewControllerDefinition`: (Required) Controller Definition Object that'll get the pertinent model merged in automatically:
  ChildViewControllerDefinition: {
    viewDefinition: {
      ViewClass: MainStatsItemView,
      formatter: thisIsJustOnePropertyYouMightUse,
    }
  }

or a function of the form:

  ChildViewControllerDefinition: (model) => {
    return {
      viewDefinition: {
        ViewClass: MainStatsItemView,
        formatter: thisIsJustOnePropertyYouMightUse,
      },
      model <- Model passed in from the Collection
    }
  }


`viewDefiniton`: (Optional) View Definition Object for overriding any CollectionView options. (`ViewClass` can only be CollectionView or a subclass there of.)

  {
    tagName: 'ul',
    className: 'example-class',
    formatter: thisIsJustOnePropertyYouMightUse,
    etc...
  }


## Full Usage Example

class ExampleController extends LayoutController {
  ...
  regionControllers() {
    return {
      someRegionName: {
        ViewControllerClass: CollectionLayoutViewController,
        collection: this.exampleCollection,
        viewDefinition: {
          // override any CollectionView options here
          tagName: 'ul',
          className: 'example-css-class',
          childViewOptions: {
            tagName: 'li',
            // Recommend adding `-region` to the end of the className since the child views act as regions. This helps
            // when devs are reading the DOM explorer in the browser
            className: 'li-class-name-region'
          },
          emptyView: SomeEmptyViewClass,
        },
        ChildViewControllerDefinition: (model) => { // will receive model
          // potential to use model to conditionally provide different viewClass per collection item
          return {
            viewDefinition: {
              ViewClass: MainStatsItemView,
              formatter: thisIsJustOnePropertyYouMightUse,
              model
            }
          };
        }
      }
    }
  }
  ...
}
*/

const { isFunction } = require('underscore');
const { ChildViewContainer } = require('Backbone');
const {
  CollectionView,
  LayoutView
} = require('Marionette');

const MarionetteViewFactory = require('@common/libs/UI/views/MarionetteViewFactory');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');

const collectionViewFactory = new MarionetteViewFactory({
  defaultDefinition: {
    ViewClass: CollectionView
  }
});

class CollectionLayoutViewController extends LayoutController {

  constructor(options) {
    // Override incoming viewFactory to ensure the View that's created is a CollectionView or a subclass there of
    super(Object.assign(options, {
      viewFactory: collectionViewFactory
    }));

    ({
      collection: this.collection,
      ChildViewControllerDefinition: this.ChildViewControllerDefinition // function, will be passed model
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);

    this._viewRegions = new ChildViewContainer();
  }

  viewDefinition() {
    return {
      collection: this.collection,
      childView: LayoutView.extend({ template: false })
    };
  }

  inflateView() {
    super.inflateView();

    const view = this.getView();

    this.listenTo(view, 'add:child', this._onViewAddChild);
    this.listenTo(view, 'remove:child', this._onViewRemoveChild);
    this.listenTo(view, 'show', this._onViewShow);
    this.listenTo(view, 'destroy', this._onViewDestroy);
  }

  childViewDefinitionFactory(view) {
    const { model } = view;
    let controllerDefinition = this.ChildViewControllerDefinition;

    if (isFunction(controllerDefinition)) {
      controllerDefinition = controllerDefinition.call(controllerDefinition, model);
    } else {
      controllerDefinition = Object.assign({ model }, controllerDefinition);
    }

    return controllerDefinition;
  }

  getRegion(regionName) {
    return this._getViewRegion(regionName).getRegion(regionName);
  }


  ///* Internal methods *///

  _onViewShow() {
    // handlers to make sure controllers are reset properly after the controllers CollectionView has been shown.
    this.listenTo(this.collection, 'change', this._onCollectionChange);
    this.listenTo(this.collection, 'update', this._onCollectionUpdated);
    this.listenTo(this.collection, 'reset', this._onCollectionReset);
  }

  _onViewDestroy() {
    this.stopListening(this.collection, 'change update reset');
  }

  _onCollectionChange(model) {
    this._resetChildViewControllers(model);
  }

  _onCollectionUpdated(collection, options = {}) {
    options.changes.added.forEach((model) => {
      this._resetChildViewControllers(model);
    });
  }

  _onCollectionReset(collection) {
    collection.each((model) => {
      this._resetChildViewControllers(model);
    });
  }

  _resetChildViewControllers(model) {
    const childView = this.getView().children.findByModel(model);
    const regionName = this._getViewRegionName(childView);
    this.resetRegionController(regionName);
  }

  _onViewAddChild(childView) {
    if (this.collection.has(childView.model)) {
      const regionName = this._getViewRegionName(childView);

      this._addViewRegion(regionName, childView);
      this.addRegionController(regionName, this.childViewDefinitionFactory(childView));
    }
  }

  _onViewRemoveChild(childView) {
    if (this.collection.has(childView.model)) {
      const regionName = this._getViewRegionName(childView);

      if (this._hasViewRegion(regionName)) {
        this.removeRegionController(regionName);
        this._removeViewRegion(regionName);
      }
    }
  }

  _addViewRegion(regionName, view) {
    view.addRegion(regionName, { el: view.$el });
    this._viewRegions.add(view, regionName);
  }

  _removeViewRegion(regionName) {
    if (this._hasViewRegion(regionName)) {
      this._viewRegions.remove(this._getViewRegion(regionName));
    }
  }

  _getViewRegion(regionName) {
    return this._viewRegions.findByCustom(regionName);
  }

  _hasViewRegion(regionName) {
    return this._getViewRegion(regionName) != null;
  }

  _getViewRegionName(view) {
    const { model } = view;

    if (model) {
      return this.collection.modelId(model.attributes) || model.cid;
    }

    return view.cid;
  }
}

module.exports = CollectionLayoutViewController;
