const Backbone = require('Backbone');
const _ = require('underscore');
const QuestionVariant = require('@common/data/models/questions/QuestionVariant');
const VariantType = require('@common/data/enums/VariantType');

class QuestionAnswer extends Backbone.Model {
  constructor() {
    super(...arguments);
    this.setupFITBAnswerOptions = this.setupFITBAnswerOptions.bind(this);
    this.setupHotspotAnswerOptions = this.setupHotspotAnswerOptions.bind(this);
    this.setupMatchingAnswerOptions = this.setupMatchingAnswerOptions.bind(this);
  }

  get className() {
    return 'QuestionAnswer';
  }

  apiEndpoint() {
    return '/answers';
  }

  parse(response) {
    const {
      entity = {
        question: {
          variants: []
        }
      }
    } = response;

    // We only want the first variant, since that is the only one the server
    // sends in the first place
    const variant = new QuestionVariant(entity.question.variants[0], {parse: true});
    entity.variant = variant;

    return entity;
  }

  isStillValidAnswer() {
    const selectedOptions = this._getOptionIdList();
    const variant = this.get('variant');
    const variantType = variant.get('variantType');
    const variantOptions = variant.get('options');

    // Bail early for hotspots, recovery always possible for them
    if (variantType === VariantType.Hotspot) {
      return true;
    }

    const allSelectedOptionsInVariantOptions = _.all(selectedOptions, (option) => {
      const isMatch = _.some(variantOptions, (variantOption) => {
        let isMatched = option === variantOption.id;

        if (variantOption.secondId != null) {
          isMatched = isMatched || (option === variantOption.secondId);
        }
        return isMatched;
      });

      return isMatch;
    });

    let correctCount = variant.get('correctOptionCount');

    // We check the length on these guys because it is possible answers
    // will no longer map 1:1 to the options selected in the case
    // of matching and FITB... in which case we fail to map an answer
    // for something new.
    let isValid = allSelectedOptionsInVariantOptions;
    if (variantType === VariantType.JumbledSentence) {
      isValid = allSelectedOptionsInVariantOptions && (correctCount === selectedOptions.length);
    }
    // The server sends both options for matching, so we are required to
    // multiply by two
    if (variantType === VariantType.Matching) {
      correctCount *= 2;

      isValid = allSelectedOptionsInVariantOptions && (correctCount <= selectedOptions.length);
    }

    return isValid;
  }

  _getOptionIdList() {
    const optionList = this.get('questionOptionList');
    const optionsList = optionList.split(',');

    return _.map(optionsList, (option) => {
      return parseInt(option, 10);
    });
  }

  setupFITBAnswerOptions() {
    const modifiedOptions = [];
    this.get('variant').options.each((option, index) => {

      if ((option.get('id') == null)) {
        option.set('previewId', index);
      }

      modifiedOptions.push(option.attributes);
    });

    this.get('variant').set('unAnsweredOptions', modifiedOptions);
  }

  setupHotspotAnswerOptions() {
    // When preveiwing uanswered hotspot questions the variant
    // doesnt have the hotspot attribute like an answered question
    // so pack up the image options into a hotspot attributes
    let hotspot;
    const variantOptions = this.get('variant').get('options') || [];
    const correctRegions = [];

    for (const option of variantOptions) {
      correctRegions.push({
        top: option.top,
        left: option.left,
        width: option.width,
        height: option.height
      });
      hotspot = {
        incorrectRegions: [],
        clicks: [],
        correctRegions
      };
    }

    this.set('hotspot', hotspot);
  }

  setupMatchingAnswerOptions() {
    const modifiedOptions = [];
    this.get('variant').options.each((option, index) => {
      if ((option.get('id') == null)) {
        option.set('previewId', index);
      }
      if ((option.get('secondId') == null)) {
        option.set('previewSecondId', index + Math.floor(Math.random() + 10));
      }

      modifiedOptions.push(option.attributes);
    });

    this.get('variant').set('unAnsweredOptions', modifiedOptions);
  }
}

module.exports = QuestionAnswer;
