// Module
var code = `<div class="page-header">
	<div class="ax-container relative">
		<div class="ax-grid ax-grid--no-gutter ax-grid--align-items-center">
			<h1 class="page-header__content">
        <span class="page-header__icon icon-topperformers" aria-hidden="true"></span>
        <span class="page-header__title"> <%- t('stats.title') %></span>
			</h1>
			<div class="ax-grid__col--auto-size">
				<%= axButton({
					className: 'qa-how-to-link js-how-to-link how-to-link',
					ariaLabel: t('general.howItWorks'),
					iconClassLeft: 'icon-question_sign icon--reset-styles',
					label: t('general.howItWorks'),
					size: 'm'
				}) %>
				<div class="js-how-to__modal hidden"></div>
			</div>
		</div>
	</div>
</div>

<div class="ax-container">
  <div class="page-content">
    <ul class="tab-bar">
      <% if(isMonthlyLeaderboard) { %>
        <li data-tab="month" class="tab">
          <a href="#">
            <%- t('stats.timeframe.month')%>
          </a>
        </li>
      <% } else { %>
        <li data-tab="last30Days" class="tab">
          <a href="#">
            <%- t('stats.timeframe.last30Days')%>
          </a>
        </li>
      <% } %>

      <% if (isAllTimeEnabled) { %>
        <li data-tab="allTime" class="tab">
          <a href="#">
            <%- t('stats.timeframe.allTime')%>
          </a>
        </li>
      <% } %>
    </ul>

    <div class="leaderboard-list clearfix">
      <div class="leaderboard-listcontainer full-width"></div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;