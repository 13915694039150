const Enum = require('@common/data/enums/Enum');

module.exports = Enum.fromStringArray([
  'READY',
  'ERROR',
  'PURGED',
  'ABORTED',
  'COMPLETE',
  'PENDING',
  'PENDING_REVERT',
  'REVERTED',
  'REVERT_ERROR'
]);
