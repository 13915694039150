const AssessmentType = require('@common/data/enums/AssessmentType');
const AssessmentTopicOption = require('@common/data/models/assessments/AssessmentTopicOption');
const AssessmentExamOption = require('@common/data/models/assessments/AssessmentExamOption');
const DailyTrainingAssessmentTopicOption = require('@common/data/models/assessments/DailyTrainingAssessmentTopicOption');

const AssessmentLaunchContextFactory = require('@common/data/models/assessments/AssessmentLaunchContextFactory');

const assessmentModelMap = {
  [AssessmentType.CertificationTraining]: AssessmentTopicOption,
  [AssessmentType.IntroductoryTraining]: AssessmentTopicOption,
  [AssessmentType.RefresherTraining]: AssessmentTopicOption,
  [AssessmentType.ExtraTraining]: AssessmentTopicOption,
  [AssessmentType.FormalExamTraining]: AssessmentExamOption,
  [AssessmentType.DailyTraining]: DailyTrainingAssessmentTopicOption
};

const getTopicOptionModelForAssessmentType = (assessmentType, values = {}) => {
  const TopicOptionModelClass = assessmentModelMap[assessmentType];
  const topicOptionModel = new TopicOptionModelClass(values);
  topicOptionModel.setForAssessmentType(assessmentType);
  topicOptionModel.setLaunchContext(values.launchContext || AssessmentLaunchContextFactory(assessmentType));
  return topicOptionModel;
};

module.exports = { getTopicOptionModelForAssessmentType };
