const ChartComponentView = require('@training/apps/components/chart/ChartComponentView');
const DataSource = require('@training/apps/search/reports/pages/dashboard/previews/pages_by_status/ChartDataSource');
const I18n = require('@common/libs/I18n');
const _ = require('underscore');


// The highcharts config object is down below
// You will not likely need to modify this, but if you do, it is available
const chartConfig = {
  chart: {
    type: 'pie',
    style: {
      fontFamily: 'inherit'
    }
  },
  title: {
    text: '',
    align: 'center'
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    },
    series: {
      states: {
        inactive: {
          opacity: 1
        }
      }
    }
  },
  credits: {
    enabled: false
  },
  legend: {
    useHTML: true,
    itemWidth: 200,
    layout: 'horizontal',
    itemStyle: {
      fontSize: '1.6rem',
      color: '#343437', // @ax-color-grey-80
      fontWeight: 'normal',
      lineHeight: '1.8rem',
      textOverflow: 'unset'
    }
  },
  tooltip: {
    useHTML: true,
    style: {
      color: '#343437', // @ax-color-grey-80
      fontSize: '1.6rem',
      fontWeight: 'normal'
    }
  }
};


class PageStatusPreview extends ChartComponentView {
  initialize(options = {}) {
    this._collection = options.collection;
    this._dataSource = new DataSource({collection: this._collection});

    const chartDescription = I18n.t('discover.browse.reports.pages.PAGES_BY_STATUS.series.description');

    // adding this directly to chart config does not work
    const a11yObject = {
      accessibility: {
        description: chartDescription
      }
    };

    const fullConfig = _.extend(a11yObject, chartConfig);

    const chartOptions = {
      chartConfig: fullConfig,
      dataSource: this._dataSource
    };

    super.initialize(chartOptions);
  }
}

module.exports = PageStatusPreview;
