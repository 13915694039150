// Module
var code = `<% if (tabBorderColour) { %>
  /*Change the subtabs to match header color*/

  /*tabs*/
  [dir] .subtabs li a.selected,
  [dir] .subtabs li a:hover.selected,
  [dir] .subtabs li a:focus.selected,
  /*in page subtabs - programs*/
  [dir] .tab-bar .tab a.selected,
  [dir] .tab-bar .tab a:hover.selected,
  [dir] .tab-bar .tab a:focus.selected,
  /*settings area top tabs*/
  [dir] .rightcol.settings .subtabs li a.selected,
  [dir] .rightcol.settings .subtabs li a:hover.selected,
  [dir] .rightcol.settings .subtabs li a:focus.selected,
  [dir] .tab-bar .tab.selected a,
  [dir]  .tab-bar .tab a:hover.selected,
  [dir] .tab-bar .tab a:focus.selected,
  /*settings left navigation, program left navigation, rewards navigation*/
  [dir] #page-view ul.settingslist li.selected a.selected,
  [dir] #page-view ul.settingslist li.selected a,
  [dir] .selectableList li:hover.selected, .selectableList li.selected,
  [dir] .leftcol .list li a.selected{
    border-color: <%- tabBorderColour %>;
  }

  /*Override for hierarchy list items in Content*/
  [dir] .hierarchyList .list-group-item.selected{
    border-left: 3px solid <%- tabBorderColour %>;
  }

  /*Override for fancy dropdown sorting/searching 3rd party control */
  [dir] .chosen-container-active.chosen-container-single.chosen-single.chosen-drop.result-selected,
  [dir] .chosen-container-active.chosen-container-single .chosen-drop ul li.result-selected,
  [dir] .chosen-container-multi .chosen-choices li.search-choice,
  [dir] .chosen-container-multi .chosen-drop ul li.result-selected {
    border-left: 4px solid <%- tabBorderColour %>;
  }

  [dir] html[dir='rtl'] .chosen-container-active.chosen-container-single.chosen-single.chosen-drop.result-selected,
  [dir] html[dir='rtl'] .chosen-container-active.chosen-container-single .chosen-drop ul li.result-selected,
  [dir] html[dir='rtl'] .chosen-container-multi .chosen-choices li.search-choice,
  [dir] html[dir='rtl'] .chosen-container-multi .chosen-drop ul li.result-selected {
    border-left: none;
    border-right: 4px solid <%- tabBorderColour %>;
  }
<% } %>
`;
// Exports
module.exports = code;