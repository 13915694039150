// Module
var code = `<div class="search-box__wrap">
  <%= axSearch({
    fullWidth: true,
    iconClassRight: 'qa-input-search-clear js-close-button icon-remove search-box__close',
    placeholder: placeholderText,
    size: inputSize
  }) %>
</div>
`;
// Exports
module.exports = code;