// Module
var code = `<td width="35%">
  <span><%- tag %></span>
</td>
<td width="35%">
  <span><%- filterName %></span>
</td>
<td width="15%">
  <span><%- numberOfUsages %></span>
</td>
<td width="15%">
  <button class="js-delete-tag settings-redirect" title="<%- t('general.delete') %>" aria-label="<%- t('communitiesManagement.tags.deleteButtonAriaLabel', {tagName: name}) %>">
    <span class="delete icon-trash"></span>
  </button>
</td>
`;
// Exports
module.exports = code;