const _ = require('underscore');
const logging = require('logging');
const Marionette = require('Marionette');

const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const FileHelpers = require('@common/libs/file/FileHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

require('@common/libs/behaviors/resizable/Resizable');

require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');
const FroalaRichContentHelper = require('@common/libs/froala/FroalaRichContentHelper');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');

const PageStatus = require('@common/components/discover/enums/PageStatus');

const {
  generateArticleBannerView,
  generateReportedArticleBannerView,
  generateInReviewArticleBannerView
} = require('@common/components/discover/article/ArticleBannerViewFactoryHelper');

class ContentView extends Marionette.LayoutView {

  className() {
    return 'page-wrapper parent-height';
  }

  ui() {
    return {
      pageContainer: '#page-container',
      articleFooter: '.article-footer',
      publishButton: '.js-button-publish'
    };
  }

  events() {
    return {
      'click @ui.pageContainer a': 'onLinkClick'
    };
  }

  regions() {
    return {
      scheduledBannerContainer: '.scheduled-banner-container',
      reportedBannerContainer: '.reported-banner-container',
      inReviewBannerContainer: '.inReview-banner-container',
      expiredBannerContainer: '.expired-banner-container',

      reactionsRegion: '.reactions-region',
      commentsRegion: '.comments-region'
    };
  }

  triggers() {
    return {
      'click @ui.publishButton': 'page:publish'
    };
  }

  behaviors() {
    return {
      Resizable: {},
      ZoomOverlay: {
        imageWrapper: '.media--image:not(:has(a img))'
      }
    };
  }

  getTemplate() {
    return require('@common/components/discover/views/ContentViewTemplate.html');
  }

  initialize(options = {}) {
    ({sessionUser: this.sessionUser} = options);

    this.listenTo(this.model, 'sync', this.onRender);
  }

  templateHelpers() {
    return {
      isLockedForEditing: () => {
        const sessionUser = this.getOption('sessionUser');
        return this.model.isLocked(sessionUser.id);
      },
      getLockedMessage: () => {
        return I18n.t(`discover.pageTypes.${ this.model.get('type') }.actionText.locked`, {
          lockedBy: this.model.get('lockedBy').get('salutationName')
        });
      }
    };
  }

  onRender() {
    this._toggleScheduledBanner();
    this._toggleExpiredBanner();
    this._toggleReportedBanner();
    this._toggleInReviewBanner();
  }

  onAttach() {
    const { richContent } = this.model.currentVersion;
    this.imageViewers = {};
    this.videoPlayers = {};
    // if the model doesn't have content, then nothing to render
    if (richContent.has('content') == null) {
      return;
    }

    this.ui.pageContainer.html(richContent.get('content'));

    // add the image media info and add the images to the iframe
    const imageMediaInfo = richContent.getImageMediaInfo(this.ui.pageContainer);
    this.imageViewers = this.initializeImageViewers(imageMediaInfo);

    _.each(this.imageViewers, (el) => {
      this.listenTo(el, 'image:loaded', () => {
        this.triggerMethod('image:loaded');

        el.$el.find('img')
          .attr('tabindex', 0);
      });
    });

    // get the video info and add them to the iframe
    const videoMedias = richContent.getVideoMediaInfo(this.ui.pageContainer);
    const embeddedVideos = richContent.getEmbeddedVideoInfo(this.ui.pageContainer);

    _.each(videoMedias, (videoMedia) => {
      // if transcoding is complete, create video package
      // and view and set it

      // 1. setup settings
      const videoPlayerOptions = {
        el: videoMedia.$el,
        maxHeight: 385
      };

      this.initializeVideoPlayer(videoMedia.mediaLink, videoPlayerOptions);

      // TODO - if transcoding is not complete, decide what to do :O
    });

    _.each(embeddedVideos, (embeddedVideo) => {
      embeddedVideo.$el.html(VideoPlayerFactory.getEmbeddedVideoInstance(embeddedVideo.mediaId, embeddedVideo.source));
    });

    this._customizeAttachmentLinks(richContent);
  }

  // listen for link clicks
  onLinkClick(e) {
    this.triggerMethod('link:clicked', e);
  }

  _customizeAttachmentLinks(richContent) {
    this.ui.pageContainer.find('a[data-media-id]').each((i, el) => {
      const $el = $(el);
      $el.addClass('action-button--download');

      const mediaJSON = richContent._getMediaById($el.data('media-id'));

      // `_getMediaById` could return `undefined` if the media was deleted but the link was not removed.
      // Our code doesn't remove links in the content when the attachment was removed.
      if (mediaJSON != null) {
        const { originalFile } = mediaJSON;
        FileHelpers.customizeAttachmentLinks($el, originalFile);
      }
    });
  }

  _isReactionsRegionLoaded() {
    return this.reactionsRegion.currentView && this.reactionsRegion.currentView.ui;
  }

  onResize() {
    if (this.bottomPad == null && this.ui.articleFooter) {
      this.bottomPad = parseInt(this.ui.articleFooter.css('bottom'), 10);
      this.bottomPad = Number.isNaN(this.bottomPad) ? 0 : this.bottomPad;
    }
    if (this._isReactionsRegionLoaded() && this.ui.articleFooter) {
      if (this.reactionsRegion.currentView.ui.reactionButtons) {
        const bottom = this.reactionsRegion.currentView.ui.reactionButtons.outerHeight() - this.reactionsRegion.currentView.$el.outerHeight();
        this.ui.articleFooter.css('bottom', bottom + this.bottomPad);
      }
    }

    _.each(this.imageViewers, (imageViewer) => {
      imageViewer.maxWidth = imageViewer.$el.width();
      return imageViewer.resize();
    });

    _.each(this.videoPlayers, (videoPlayer) => {
      return videoPlayer.resize();
    });
  }

  onBeforeDestroy() {
    return _.each(this.videoPlayers, (videoPlayer) => {
      return videoPlayer.destroy();
    });
  }

  initializeImageViewers(imageMediaInfo = []) {
    const result = [];

    for (const imageMedia of imageMediaInfo) {
      const {
        $el,
        media
      } = imageMedia;
      if (media != null) {

        //For froala edited articles (these params will not exist otherwise)
        let displayWidth = null;
        const $parent = FroalaRichContentHelper.getImageParentElement($el);
        //Make sure we don't pass a width if the caption-container is already resized, and don't pass 0px as a width
        if ($parent[0].style.width !== '' && $parent[0].style.width !== '0px') {
          displayWidth = $parent.css('width');
        }
        const fallbackAlt = $el.data().altText;
        const froalaImgClass = FroalaRichContentHelper.getFroalaClassNamesToMove($parent[0]);
        $el.removeAttr('data-alt-text'); // Unset the data-alt-text attr from the figure. It will be reattached on save
        const maxWidth = Math.max(parseInt($el.width(), 10), parseInt(displayWidth, 10));
        $el.css('height', '');
        $el.css('width', '');

        const imageViewerOptions = _.extend({
          maxWidth,
          displayWidth,
          fallbackAlt,
          imgClass: froalaImgClass,
          keepExistingChildNodes: true
        }, imageMedia, {
          $el: $parent
        });
        this.imageViewers[media.id] = ImageViewerFactory.createViewerInstance(imageViewerOptions);
        result.push(this.imageViewers[media.id].render());
      } else {
        result.push(logging.warn('Media with some ID could not be located. Server failed to send it / markup error?'));
      }
    }

    return result;
  }

  initializeVideoPlayer(mediaLink, videoPlayerOptions) {
    this.videoPlayers[mediaLink.media.id] = VideoPlayerFactory.createPlayerInstance({
      videoPackage: mediaLink,
      viewOptions: videoPlayerOptions
    });

    return this.videoPlayers[mediaLink.media.id].render();
  }

  _publishDateIsPastDue() {
    return this.model.hasPastPublishDate() && !this.model.currentVersion.get('approvedAsFact');
  }

  _shouldShowScheduledBanner() {
    return this._publishDateIsPastDue() || this.model.hasFuturePublishDate();
  }

  _toggleScheduledBanner() {
    if (this._shouldShowScheduledBanner()) {
      const publishDateRaw = this.model.currentVersion.get('publishTimestamp') || new Date();
      const publishDate = dateHelpers.convertDateFormatShortDateWithStrings(publishDateRaw);
      const publishDateStr = I18n.t('discover.pageTypes.generic.scheduledDate', {
        publishDate
      });

      this.scheduledBannerContainer.show(generateArticleBannerView({
        id: 'scheduled-container',
        className: 'scheduled-banner',
        icon: 'calendar-outline',
        text: publishDateStr
      }));

      if (this._publishDateIsPastDue()) {
        this.scheduledBannerContainer.$el.find('.scheduled-banner').addClass('late-publish');
      }
    } else {
      this.scheduledBannerContainer.empty();
    }
  }

  _shouldShowExpiredBanner() {
    // we don't need to do any date comparisons here. If the article has expired, it has a status of "expired"
    return this.model.get('status') === 'expired';
  }

  _toggleExpiredBanner() {
    if (this._shouldShowExpiredBanner()) {
      const expiryDateRaw = this.model.get('expiry')?.date || new Date();
      const expiryDate = dateHelpers.convertDateFormatShortDateWithStrings(expiryDateRaw);
      const expiryDateStr = I18n.t('discover.pageTypes.generic.expiryDate', {
        expiryDate
      });
      this.expiredBannerContainer.show(generateArticleBannerView({
        id: 'expired-container',
        className: 'expired-banner',
        icon: 'exclamation',
        text: expiryDateStr,
        showDeclarationText: true,
        declarationText: I18n.t('discover.pageView.expired'),
        declarationClass: 'expired'
      }));
    } else {
      this.expiredBannerContainer.empty();
    }
  }

  _toggleInReviewBanner() {
    if (!this.model.isFact() && this.model.getStatus() === PageStatus.REVIEW) {
      const hasContent = this.model.getCurrentVersion() != null && this.model.getCurrentVersion().richContent && this.model.getCurrentVersion().richContent.content != null && this.model.getCurrentVersion().richContent.content !== '';
      const canPublish = this.model.canPublish() && this.model.get('community').canPublish() && hasContent;

      const sessionUser = this.getOption('sessionUser');

      let explanation = I18n.t(`discover.pageTypes.inReview.consideredForPublishing`);
      let showButton = false;

      if (canPublish && !this.model.isLocked(sessionUser.id)) {
        explanation = I18n.t(`discover.pageTypes.inReview.publish`);
        showButton = true;
      }

      this.inReviewBannerContainer.show(generateInReviewArticleBannerView({
        className: 'inReview-banner',
        icon: 'checkbox-outline',
        reason: I18n.t(`discover.pageTypes.inReview.title`),
        explanation: explanation,
        showButton: showButton
      }));
    } else {
      this.inReviewBannerContainer.empty();
    }
  }

  _toggleReportedBanner() {
    if (this.model.currentVersion != null && this.model.currentVersion.has('pageReport')) {
      const pageReport = this.model.currentVersion.get('pageReport');
      const {reportType} = pageReport;
      const reportingUser = pageReport.author.user;
      const date = dateHelpers.convertDateFormatToDefaultDate(pageReport.author.timestamp);
      let name = reportingUser.salutationName;

      if (this._canViewReportingUser(reportingUser)) {
        const link = UrlHelpers.getLinkToAdminUserProfile(pageReport.author.user.id);
        name = `<a href='${ link }' target='_blank'>${ name }</a>`;
      }

      this.reportedBannerContainer.show(generateReportedArticleBannerView({
        className: 'reported-banner',
        icon: 'exclamation_sign-outline',
        reason: I18n.t(`discover.pageView.report.reasons.${ reportType }`),
        explanation: pageReport.comment,
        reportedBy: I18n.t('discover.pageView.report.by', {
          name,
          date
        })
      }));
    }
  }

  _canViewReportingUser(reportingUser) {
    return BrowserHelpers.isAdminZoneSupported() && this.sessionUser.isAdminUser() && !reportingUser.isSuperuser;
  }
}

module.exports = ContentView;
