import { getCssValue } from '@common/modules/react/themes/UiKit';

export default function uiKitBottomNavigation(cssVars: CSSStyleDeclaration) {
  return {
    bottomNavigation: {
      label: {
        color: getCssValue('--ax-bottom-navigation-label-color', cssVars),
        fontSize: getCssValue('--ax-bottom-navigation-label-font-size', cssVars),
        fontWeight: getCssValue('--ax-bottom-navigation-label-font-weight', cssVars),
        lineHeight: getCssValue('--ax-bottom-navigation-label-line-height', cssVars),
        padding: getCssValue('--ax-bottom-navigation-label-padding', cssVars),
        borderBottom: getCssValue('--ax-bottom-navigation-label-boder-bottom', cssVars),
        selected: {
          borderBottom: getCssValue('--ax-bottom-navigation-label-boder-bottom-selected', cssVars)
        }
      },
      icon: {
        color: getCssValue('--ax-bottom-navigation-icon-color', cssVars),
        size: getCssValue('--ax-bottom-navigation-icon-size', cssVars)
      }
    }
  };
}
