// Module
var code = `<div class="languageSelector">
    <h1 class="page-header">
        <div class="ax-container">
            <div class="page-header__content">
                <span class="page-header__icon icon-profile" aria-hidden="true"></span>
                <div class="page-header__title"><%- t('settings.languagetitle')%></div>
            </div>
        </div>
    </h1>

    <div class="ax-container">
        <div class="page-content">
            <div class="completeprofile">
                <form class="languageselect">
                    <h2 class="ax-font--no-margin"><%- t('settings.preferredLanguageTitle') %></h2>
                <p class="ax-font--secondary"><%- t('settings.preferredLanguageHelp') %></p>

                    <%= axSelect({
                        classNameSelect: 'js-change-selection primary-language-select qa-language-select',
                        data: {
                            editor: 'AxonSelect',
                            field: 'primaryLanguage',
                            options: 'languageListOptions',
                            shallow: 'true'
                        },
                        ariaLabel: selectAriaLabel,
                        name: 'primaryLanguageSelection'
                    }) %>

                    <% if(showSecondaryLanguages) { %>
                        <h2 class="ax-font--no-margin header-margin"><%- t('settings.secondaryLanguageTitle') %></h2>
                        <p class="ax-font--secondary"><%- t('settings.secondaryLanguageHelp') %></p>

                        <ul class="selectableList nomargin js-search-languages" data-field="preferredSearchLanguages" data-options="languageList" data-editor="Checkboxes" data-shallow></ul>
                    <% } %>
                </form>
            </div>
        </div>
    </div>
</div>
`;
// Exports
module.exports = code;