const Backbone = require('Backbone');
const _ = require('underscore');

const I18n = require('@common/libs/I18n');
const { assertPropertyExists } = require('@common/libs/helpers/types/ObjectHelpers');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const PasswordComplexityParser = require('@common/modules/auth/common/PasswordComplexityParser');
const CryptographyHelper = require('@common/libs/cryptography/CryptographyHelper');

class RegistrationTicket extends Backbone.Model {
  className = 'RegistrationTicket';

  validators() {
    const fixedValidators = {
      passwordPlain: ['password', 'required'],
      passwordRepeated: ['password', 'required'],
      employeeId: 'required',
      fullname: 'required',
      recoveryQuestions: 'required'
    };

    if (this.requiredFields.includes('email')) {
      fixedValidators['email'] = ['required', 'email'];
    }

    if (this.requiredFields.includes('location')) {
      fixedValidators['location'] = 'required';
    }

    return fixedValidators;
  }

  defaults() {
    return {
      recoveryQuestions: [],
      passwordPlain: '',
      passwordRepeated: ''
    };
  }

  urlRoot() {
    return '/axonify/register';
  }

  initialize(attrs, options) {
    this.enforceComplexPassword = options.enforceComplexPassword != null ? options.enforceComplexPassword : false;
    this.publicKey = assertPropertyExists(options, 'publicKey');
    this.requiredFields = [];
  }

  // Marks a field as required dynamically. This is used because some fields not always known in advance, up front.
  markRequired(key) {
    this.requiredFields.push(key);
    this.requiredFields = _.uniq(this.requiredFields);
  }

  secureJSON() {
    const attrs = _.extend({}, _.clone(this.attributes));

    const encryptAnswers = [];

    // Flatten the ID out
    for (const opt of attrs.recoveryQuestions) {
      opt.recoveryQuestion = I18n.t(`securityQuestions.q${ opt.recoveryQuestion }`);
      encryptAnswers.push(CryptographyHelper.encryptWithRSA(opt.recoveryAnswer, this.publicKey).then((rsaAnswer) => {
        opt.recoveryAnswer = rsaAnswer;
      }));
    }

    return Promise.all(encryptAnswers).then(() => {
      return CryptographyHelper.encryptWithRSA(attrs.passwordPlain, this.publicKey);
    })
      .then((rsaString) => {
        attrs.password = rsaString;
        delete attrs.passwordPlain;
        delete attrs.passwordRepeated;
        return attrs;
      });
  }

  // We validate here and ensure that our requirements are met...
  // Some of these require multiple fields to be checked together, so it is best to validate the form
  validate() {
    this.set('name', this.get('employeeId'));

    // Check our dynamically marked and required fields
    const errors = [];
    for (const field of Array.from(this.requiredFields)) {
      // The email exception is here since it handles validation in the validator above
      if ((!this.get(field) || (this.get(field) === '')) && field !== 'email') {
        errors.push(I18n.t(`errors.RegistrationTicket.${ field }`));
      }
    }

    const pwd1 = this.get('passwordPlain');
    const pwd2 = this.get('passwordRepeated');

    const complexityParser = new PasswordComplexityParser();
    const complexityRules = complexityParser.parse(this._getComplexityString());
    const passwordValidationResult = complexityRules.validateAgainstPassword(pwd1);

    if (passwordValidationResult.isTooShort()) {
      errors.push(I18n.t('resetpassword.invalidcomplexpasswordshort', {minPasswordLength: complexityRules.getMinLength()}));
    }

    if (passwordValidationResult.isWithoutEnoughGroups()) {
      errors.push(I18n.t('resetpassword.invalidcomplexpasswordgroups', {minNumberOfCharacterGroups: complexityRules.getMinGroups()}));
    }

    if (pwd1 !== pwd2) {
      errors.push(I18n.t('resetpassword.passwordmismatch'));
    }

    // Recovery questions cannot be blank; validate them. '-1' is no option
    for (const recoveryChoice of this.get('recoveryQuestions')) {
      const wasEmptyHash = recoveryChoice.recoveryAnswer === CryptographyHelper.EMPTY_STRING_MD5;
      if ((!recoveryChoice.recoveryQuestion > 0) || (recoveryChoice.recoveryAnswer === '') || wasEmptyHash) {
        errors.push(I18n.t('selfRegistration.errors.emptySecurity'));
        break;
      }
    }

    return errors;
  }

  _getComplexityString() {
    if (this.enforceComplexPassword) {
      return TenantPropertyProvider.get().getProperty('complexFormat');
    }
    return PasswordComplexityParser.getStringForMinCharactersDefault();

  }
}

module.exports = RegistrationTicket;
