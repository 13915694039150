// Module
var code = `<div class="evaluation-details__header--wrapper page-header">
  <div class="ax-container relative">
    <div class="ax-grid ax-grid--no-gutter ax-grid--align-items-center">
      <div class="evaluation-details__header page-header__content page-header__content--justify-start js-title-region"></div>
    </div>
  </div>
</div>

<div class="ax-container">
  <div class="page-content">
    <div class="clearfix content-region">
      <div class="evaluation-details ax-grid">
        <div class="evaluation-details__description <%- descriptionClass %> ax-grid__col--12 ax-grid__col--m-8"></div>
        <div class="evaluation-details__info ax-grid__col--12 ax-grid__col--m-4"></div>
        <div class="evaluation-details__content <%- descriptionClass %> ax-grid__col--12 ax-grid__col--m-8"></div>
        <div class="evaluation-details__submission-upload ax-grid__col--12" aria-hidden="true"></div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;