const logging = require('logging');

module.exports = {
  getStylePath: (entryPointKey) => {
    const entryPoint = window.axonifyCopmilationInfo.entryPoints[entryPointKey];
    if (entryPoint && entryPoint.filesByExtension.css) {
      if (entryPoint.filesByExtension.css.length > 1) {
        logging.error('getStylePath called for entry point with more than one css file');
      }
      return entryPoint.filesByExtension.css[0];
    }

    logging.error('getStylePath called for entry point with no css file found');
    return '';
  }
};
