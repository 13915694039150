import {
  LinearProgress as MuiLinearProgress,
  styled,
  type LinearProgressProps as MuiLinearProgressProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';


type PropsToOmit = 'valueBuffer';
interface AxLinearProgressProps extends Omit<MuiLinearProgressProps, PropsToOmit> {
  variant?: 'determinate' | 'indeterminate';
}
declare module '@mui/material/LinearProgress' {
  export interface LinearProgressPropsColorOverrides {
    'positive': true
    'warning': true
    'negative': true
    'informational': true
    'inherit': true
    
    'primary': false
    'secondary': false
    'error': false
    'info': false
    'success': false
  }
}

const styledOptions = {
  name: 'AxLinearProgress'
};

const StyledAxLinearProgress = styled(MuiLinearProgress, styledOptions)<AxLinearProgressProps>((props) => {
  const {
    theme,
    variant
  } = props;

  if (variant === 'determinate') {
    return {
      borderRadius: theme.uiKit.progressBar.borderRadius,
      // override the progress bar (the part inside the container that fills up with color as the progress increases)
      '.MuiLinearProgress-bar': {
        borderRadius: theme.uiKit.progressBar.borderRadius
      },
      height: theme.uiKit.progressBar.height,
      backgroundColor: theme.uiKit.progressBar.backgroundColor,
      border: theme.uiKit.progressBar.border
    };
  }

  return {};
});


/**
 * Represents a linear progress component with customizable styles and variants.
 *
 * @component
 * @example
 * ```tsx
 * import AxLinearProgress from './AxLinearProgress';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <AxLinearProgress variant="determinate" value={50} color="positive" />
 *       <AxLinearProgress variant="indeterminate" color="warning" />
 *     </div>
 *   );
 * };
 * ```
 */
export const AxLinearProgress = forwardRef(({
  color = 'informational',
  ...otherProps
}: AxLinearProgressProps, ref: Ref<HTMLDivElement>) => {
  const LinearProgressProps: AxLinearProgressProps = {
    color,
    ...otherProps
  };

  return (
    <StyledAxLinearProgress { ...LinearProgressProps } ref={ ref }/>
  );
});

export default AxLinearProgress;
