const Backbone = require('Backbone');
const RichContent = require('@common/components/discover/models/RichContent');

class PageVersion extends Backbone.Model {
  defaults() {
    return {agreeCount: 0};
  }

  apiEndpoint() {
    return '/pageVersions';
  }

  get className() {
    return 'PageVersion';
  }

  preinitialize() {
    this.richContent = new RichContent();
    this.listenTo(this.richContent, 'change', (model) => {
      this.set('richContent', model.toJSON());
      this.trigger('change', this);
    });
    this.listenTo(this, 'change:richContent', (model, value) => {
      this.richContent.set(value);
    });
  }

  report(options) {
    let ajaxOptions = {
      type: 'POST',
      url: `${ this.url() }/reports`,
      data: JSON.stringify(options.data)
    };
    ajaxOptions = $.extend(true, options, ajaxOptions);

    return $.ajax(ajaxOptions);
  }
}

module.exports = PageVersion;
