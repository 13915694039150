const { ItemView } = require('Marionette');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const KeyCode = require('@common/data/enums/KeyCode');
const I18n = require('@common/libs/I18n');

class FavoriteItemView extends ItemView {

  getTemplate() {
    return `
    <button
      type="button"
      aria-pressed="<%- (existingFavoriteId != null) ? 'true': 'false' %>"
      aria-label="<%- (existingFavoriteId != null) ? favoriteChecked : favoriteUnchecked %>"
      class="reaction-bar-button js-favorite-button <%- (existingFavoriteId != null) ? 'qa-favorited': 'qa-not-favorited' %>"
    >
    <span class="reaction-bar-button-icon js-star-icon star-icon reaction-button-icon icon-star-outline blue
        <% if (existingFavoriteId != null) { %>
          icon-star-solid
        <% } %>
      "></span>

      <span class="star-count
      <% if (showFavoriteCount === false) { %>
        hidden
      <% } %>
      ">
      <% if (favoriteCount == 1) { %>
        <%- t('discover.reactions.favorite.count.singular') %>
      <% } else { %>
        <%- t('discover.reactions.favorite.count.plural', {favoriteCount: favoriteCount}) %>
      <% } %>
      </span>

      <span class="reaction-bar-button-text qa-reaction-bar-button-text reaction-button-label
      <% if (showFavoriteLabel === false) { %>
        hidden
      <% } %>
      "><%- t('discover.reactions.favorite.label') %></span>
    </button>
    `;
  }

  isCommsTenantPropertyOn() {
    return TenantPropertyProvider.get().getProperty('communicationsEnabled');
  }

  shouldShowFavoriteCount() {
    // If COMMS is off, then all that shows in the reaction tray is the favorites.
    // In that case, there is plenty of room, and no other indicator of popularity, so we should show the
    // count number.
    // If COMMS is on, then we have the # of rections showing, and there isn't as much real estate. So we don't
    // need to show the # of faves.
    return !this.isCommsTenantPropertyOn();
  }

  shouldShowFavoriteLabel() {
    // The label is (in English), just the word "Favorite". It is a call to action to click the button. Let it not be
    // conflated with the count descriptor, where we are showing "1 Favorite" or "3 Favorites".
    // We shouldn't show both, that would look stupid, so basically if the count is shown, then we should not be
    // showing the label.
    return !this.shouldShowFavoriteCount();
  }

  templateHelpers() {
    return {
      favoriteChecked: I18n.t('selfDirected.browse.favoriteChecked', { title: this.model.get('title') }),
      favoriteUnchecked: I18n.t('selfDirected.browse.favoriteUnchecked', { title: this.model.get('title') }),
      showFavoriteCount: this.shouldShowFavoriteCount(),
      showFavoriteLabel: this.shouldShowFavoriteLabel()
    };
  }

  ui() {
    return {
      starIcon: '.js-star-icon'
    };
  }

  modelEvents() {
    return {
      change: 'render'
    };
  }

  events() {
    return {
      'click .js-favorite-button': '_onFavoriteClick',
      'keypress .js-favorite-button': '_onFavoriteKeypress'
    };
  }

  _onFavoriteClick(e) {
    // prevents click on the card as a whole, which navigates to the article
    e.stopPropagation();
    this._toggleFavorite();
  }

  _onFavoriteKeypress(e) {
    e.stopPropagation();

    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      this._toggleFavorite(true);
    }

    return false;
  }

  _toggleFavorite(fromKeypress = false) {
    const pageId = this.model.get('pageId') || this.model.get('id');

    $.ajax({
      type: this.model.get('existingFavoriteId') != null ? 'DELETE' : 'PUT',
      apiEndpoint: `/articles/${ pageId }/favorite`,
      success: (resp) => {
        let favoriteCount = this.model.get('favoriteCount');
        let existingFavoriteId = null;

        if (resp.entity != null) {
          existingFavoriteId = resp.entity.id;
          favoriteCount = favoriteCount + 1;
        } else {
          favoriteCount = favoriteCount - 1;
        }

        this.model.set({
          existingFavoriteId,
          favoriteCount
        });
        this.getOption('onFavoriteItem')?.({
          existingFavoriteId,
          favoriteCount
        });

        // When the favorite is toggled, we re-render the icon and count thus losing focus on the button
        // If the toggle was initiated by keypress, lets re-focus the button when finished for accessibility
        if (fromKeypress) {
          this.ui.starIcon.trigger('focus').addClass('focus-visible');
        }
      }
    });
  }
}

module.exports = FavoriteItemView;
