import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  type CARD_PADDING,
  getCardPadding
} from './AxCard';
import {
  styled,
  CardHeader as MuiCardHeader,
  type CardHeaderTypeMap as MuiCardHeaderTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxCardHeaderCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  padding?: CARD_PADDING
};

export type AxCardHeaderTypeMap<P = object, D extends React.ElementType = 'div'> = MuiCardHeaderTypeMap<P & AxCardHeaderCustomProps, D>;

export type AxCardHeaderProps<
  D extends ElementType = AxCardHeaderTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxCardHeaderTypeMap<P & AxCardHeaderCustomProps, D>, D>;

const styledOptions = {
  name: 'AxCardHeader',
  shouldForwardProp: (prop: string) => {
    return prop !== 'padding';
  }
};

const StyledAxCardHeader = styled(MuiCardHeader, styledOptions)<AxCardHeaderProps>(({
  theme,
  padding
}) => {
  return {
    padding: getCardPadding(padding),
    ' .MuiCardHeader-avatar': {
      marginRight: theme.uiKit.spacingS
    },
    ' .MuiCardHeader-action': {
      margin: 0,
      flex: '0 1 auto',
      overflowWrap: 'anywhere',
      paddingLeft: theme.uiKit.spacingS
    }
  };
});

export const AxCardHeader: MuiOverridableComponent<AxCardHeaderTypeMap> = forwardRef(({
  titleTypographyProps = {
    variant: 'captionMedium'
  },
  subheaderTypographyProps = {
    variant: 'caption'
  },
  padding = 'medium',
  ...otherProps
}: AxCardHeaderProps, ref: Ref<HTMLDivElement>) => {
  const cardHeaderProps: AxCardHeaderProps = {
    titleTypographyProps,
    subheaderTypographyProps,
    padding,
    ...otherProps
  };

  return <StyledAxCardHeader { ...cardHeaderProps } ref={ ref }/>;
});

export default AxCardHeader;
