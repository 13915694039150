const Backbone = require('Backbone');

class PathStatus extends Backbone.Model {
  apiEndpoint = '/paths/hasSeenPathHelpText';

  markPathHelpTextAsSeen() {
    this.save({hasSeenPathHelpText: true}, {method: 'PUT'});
  }

}

module.exports = PathStatus;
