const { ItemView } = require('Marionette');
const moment = require('moment');
const I18n = require('@common/libs/I18n');
const FormatCommunityNumbers = require('@training/apps/training/communitiesInsights/components/FormatCommunityNumbers');

class CommunityInsightsOverviewItemView extends ItemView {
  initialize(options = {}) {
    ({dateStamps: this.dateStamps} = options);
  }

  tagName() {
    return 'li';
  }

  className() {
    return 'communications-insights-overview__tile';
  }

  getTemplate() {
    return `\
      <div class="overview-item ax-row">
      <div>
        <p class="off-screen"><%- value() %> <%- label %></p>
        <p class="overview-item__value" aria-hidden="true"><%- value() %></p>
        <p class="overview-item__label" aria-hidden="true"><%- label %></p>
        <p class="off-screen"><%- changeOffscreen %></p>
        <span class="overview-item__change-arrow <%- changeArrow %>" aria-hidden="true"></span>
        <span class="overview-item__change <%- changeClass %>" aria-hidden="true"><%- changePercent %></span>
        <span class="overview-item__change overview-item__date-range "> <%- t('general.versus') %> <%- dateRange %></span>
        </div>
      </div>\
    `;
  }

  templateHelpers() {
    const change = this.getChange();
    return {
      value: () => {
        return FormatCommunityNumbers(this.getValue());
      },
      label: this.getNameString(),
      changeArrow: change.arrow,
      changeOffscreen: change.changeOffscreen,
      changePercent: change.string,
      changeClass: change.className,
      dateRange: this.getRangeString()
    };
  }

  getValue() {
    return this.model.get('current');
  }

  getNameString() {
    const lib = {
      newPages: 'discover.insights.newPages',
      impressions: 'discover.insights.impressions',
      views: 'discover.insights.views',
      engagements: 'discover.insights.engagements'
    };

    return I18n.t(lib[this.model.get('name')]);
  }

  getChange() {
    const percent = this.model.get('pctChange');
    if (percent > 0) {
      return {
        arrow: 'category-icon icon-high inline-block',
        changeOffscreen: I18n.t('discover.insights.changeLabel.positive', {percent: percent}),
        string: I18n.t('general.percentValueWithSign', {percent: percent}),
        className: 'overview-item__change--non-negative'
      };
    } else if (percent < 0) {
      return {
        arrow: 'category-icon icon-low inline-block',
        changeOffscreen: I18n.t('discover.insights.changeLabel.negative', {percent: Math.abs(percent)}),
        string: I18n.t('general.percentValueWithSign', {percent: Math.abs(percent)}),
        className: 'overview-item__change--negative'
      };
    }
    return {
      arrow: '',
      changeOffscreen: I18n.t('discover.insights.noChange'),
      string: I18n.t('discover.insights.noChange'),
      className: 'overview-item__change--non-negative'
    };
  }

  getRangeString() {
    if (this.dateStamps.get('previous')) {
      const startDate = moment(this.dateStamps.get('previous').start, 'YYYY-MM-DD').format('MMM DD');
      const endDate = moment(this.dateStamps.get('previous').end, 'YYYY-MM-DD').format('MMM DD');
      return I18n.t('discover.insights.timePeriod', {
        startDate: startDate,
        endDate: endDate
      });
    }
    return '';
  }
}

class CommunityInsightsOverviewEmptyView extends ItemView {
  tagName() {
    return 'li';
  }

  className() {
    return 'community-insights-overview_empty';
  }

  getTemplate() {
    return `\
      <p class="center"></p>
    `;
  }
}

module.exports = {
  CommunityInsightsOverviewItemView,
  CommunityInsightsOverviewEmptyView
};
