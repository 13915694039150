// Module
var code = `<% if (mainHeaderColour) { %>
  [dir] #header:not(.header-slideout__menu) {
    background: <%- mainHeaderColour %>;
  }
<% } %>

<% if (headerImageDesktop && headerImageMobile) { %>
  [dir] #header .logo {
    background-image: url('<%= headerImageMobile.file.path %>');
  }

  [dir] #header.header-top .logo {
    background-image: url('<%= headerImageDesktop.file.path %>');
    min-width: <%- headerImageDesktopMinWidth() %>px;
  }
<% } %>

<% if (headerImageDesktop) { %>
  [dir] .login-logo {
    background-image: url('<%= headerImageDesktop.file.path %>');
  }
<% } %>
`;
// Exports
module.exports = code;