const md5 = require('md5');

/**
 * You should note that this function does network calls in order to get Forge.  You would have to be willing
 * to accept a potential network hit if the module is not cached when calling this.
 */
const getForge = () => {
  return import('node-forge').then(({ default: forge } = {}) => {
    return forge;
  });
};

// Contains some helper methods for using crytography in an abstracted way.
const CryptographyHelper = Object.freeze({
  EMPTY_STRING_MD5: 'd41d8cd98f00b204e9800998ecf8427e',

  getMD5Hash(stringToHash) {
    return md5(stringToHash);
  },

  /**
   *
   *  Given some string payload and some public key that is loaded from a resource, it returns the RSA'd Base64'd encoded
   *  string of this resource string.
   *
   *  It should be noted that this method does network IO for a large resource, so the first time it is called
   *  will be expensive.
   *
   * @param {String} stringToEncrypt The string to encrypt across the wire
   * @param {PublicKey} publicKey The Axonify Public Key model that contains the public key info
   */
  encryptWithRSA(stringToEncrypt, publicKey) {
    return getForge().then((forge) => {
      const forgeKey = forge.pki.publicKeyFromPem(publicKey.getKey());
      const encoded = forge.util.encodeUtf8(stringToEncrypt);
      const encrypted = forgeKey.encrypt(encoded);
      return forge.util.encode64(encrypted);
    });
  }
});

module.exports = CryptographyHelper;
