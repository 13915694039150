const { ItemView } = require('Marionette');
const dateHelpers = require('@common/libs/dateHelpers');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const SessionTrainingType = require('@common/data/enums/SessionTrainingType');

class GreetingView extends ItemView {
  initialize(options = {}) {
    // NOTE: Only the trainingSession and user values are actually required. The rest are optional.
    ({
      trainingSession: this.trainingSession, // REQUIRED
      user: this.user, // REQUIRED
      hasTrained: this.hasTrained,
      coachClass: this.coachClass,
      mainText: this.mainText,
      secondaryText: this.secondaryText
    } = options);

    // Get default values for properties that are not provided
    if (this.coachClass == null) {
      this.coachClass = this._getCoachClass();
    }

    if (this.mainText == null) {
      this.mainText = this._getMainText();
    }

    if (this.secondaryText == null) {
      this.secondaryText = this._getSecondaryText();
    }
  }

  className() {
    return 'greeting-view';
  }

  tagName() {
    return 'div';
  }

  get template() {
    return `<% if (coachType != 'NoCoach') { %>
      <div class="characterarea">
        <div class="bonuscharacter">
          <div class="<%- coachType %><%- coachClass ? ' ' : '' %><%- coachClass %>" role="img" aria-label="<%- t('start.coach.welcomeCoach') %>"></div>
        </div>
      </div><% } %>
      <div class="textarea">
        <h1 class="welcome-top text-ellipsis-softwrap"><span class="time-greeting"><%= mainText %></span></h1>
        <h2 class="welcome-bottom ax-font__display"><%- secondaryText %></h2>
      </div>
`;
  }

  templateHelpers() {
    return {
      coachType: this.user.getCoachType(),
      coachClass: this.coachClass,
      mainText: this.mainText,
      secondaryText: this.secondaryText
    };
  }

  ui() {
    return {
      greeting: '.time-greeting',
      message: '.welcome-bottom'
    };
  }

  onRender() {
    if (!this.mainText) {
      this.ui.greeting.toggleClass('hidden', true);
    }
    if (!this.secondaryText) {
      this.ui.message.toggleClass('hidden', true);
    }
  }

  _getTimeGreeting() {
    const nowHour = dateHelpers.createDate().format('H');
    if (nowHour < 5) {
      // Normally we would return the timeGreeting.night value ('Welcome Back'), but we're not sure if this is a new user or not.
      return I18n.t('hub.preTraining.timeGreeting.generic', {
        name: this.user.get('salutationName')
      });
    } else if (nowHour < 12) {
      return I18n.t('hub.preTraining.timeGreeting.morning', {
        name: this.user.get('salutationName')
      });
    } else if (nowHour < 18) {
      return I18n.t('hub.preTraining.timeGreeting.afternoon', {
        name: this.user.get('salutationName')
      });
    }
    return I18n.t('hub.preTraining.timeGreeting.evening', {
      name: this.user.get('salutationName')
    });

  }

  _getMainText() {
    // For now, we're just going to display the time greeting.
    return this._getTimeGreeting();

    // // First determine if training has taken place or not
    // if (this.hasTrained) {

    //   // User has already taken training today (unless they don't do training at all)
    //   if (this.trainingSession.get('sessionTrainingType') === SessionTrainingType.NoTraining
    //   || this.trainingSession.get('sessionTrainingType') === SessionTrainingType.Weekend) {
    //     return this._getTimeGreeting();
    //   }

    //   // TODO: Determine how the user did on training
    //   let streakLength, resultPercent;
    //   // streakLength = 5;
    //   // resultPercent = 100;
    //   if (streakLength > 5) {
    //     return I18n.t('hub.postTraining.resultMessageStreak');
    //   } else if (resultPercent >= 50) {
    //     return I18n.t('hub.postTraining.resultMessageGreat', {
    //       name: this.user.get('salutationName')
    //     });
    //   } else {
    //     return I18n.t('hub.postTraining.resultMessageNiceTry', {
    //       name: this.user.get('salutationName')
    //     });
    //   }
    // } else {
    //   // The user has not yet had training today - present a time-based greeting

    //   if (!this.user.get('pointsTotal')) { // TODO: Find a better way to determine if the user is new
    //     return I18n.t('hub.preTraining.timeGreeting.generic', {
    //       name: this.user.get('salutationName')
    //     });
    //   } else {
    //     return this._getTimeGreeting();
    //   }
    // }
  }

  _getSecondaryText() {
    // First determine if this is a user that has communications or tasks enabled
    if (TenantPropertyProvider.get().getProperty('integrationBetaFeaturesEnabled')
      && (TenantPropertyProvider.get().getProperty('executionPlatformEnabled')
      || TenantPropertyProvider.get().getProperty('communicationsPlatformEnabled'))) {
      return I18n.t('start.message.integration');
    }

    // Second determine if training is even an option
    const trainingType = this.trainingSession.get('sessionTrainingType');

    if (trainingType === SessionTrainingType.NoTraining
      || trainingType === SessionTrainingType.Weekend) {
      return I18n.t('hub.postTraining.noTrainingMessage');
    }

    // Next determine if there are any points to earn and if the recommended training is finished
    const points = this.trainingSession.get('pointsRemainingEligible');

    // Refresher training has different string parameters to accommodate
    if (trainingType === SessionTrainingType.RefresherTraining) {
      const percent = TenantPropertyProvider.get().getProperty('refresherTrainingScoreThreshold');
      if (points) {
        return I18n.t('start.message.withPoints.' + trainingType, {
          percent: percent
        });
      }
      // If no points still available but there is still recommended training to do, notify the user
      if ( !this.hasTrained ) {
        return I18n.t('start.message.noPoints.' + trainingType, {
          percent: percent
        });
      }

    } else {
      // For non-refresher training, check if there are points/training available
      if (points) {
        return I18n.t('start.message.withPoints.' + SessionTrainingType.DailyTraining, { // Daily Training has a generic message about points applicable to all types
          points
        });
      }
      // If no points still available but there is still recommended training to do, notify the user
      if ( !this.hasTrained ) {
        return I18n.t('start.message.noPoints.' + trainingType);
      }
    }

    // If we get here, there are no more points available, and the minimum "recommended" training has been done
    return I18n.t('hub.postTraining.doneMessage');
  }

  _getCoachClass() {
    // TODO: Determine how the user did on training (from trainingSession, presumably)
    if (this.hasTrained) {
      return 'correct';
    }
    return '';

  }
}

module.exports = GreetingView;
