const Marionette = require('Marionette');

class PasswordRulesetView extends Marionette.ItemView {
  className() {
    return 'password-ruleset-data';
  }

  getTemplate() {
    return require('@common/components/passwordRuleset/PasswordRuleset.html');
  }

  initialize(options = {}) {
    this.complexPassword = options.complexPassword;
    this.minPasswordLength = options.complexityRules.getMinLength();
    this.minNumberOfCharacterGroups = options.complexityRules.getMinGroups();
  }

  templateHelpers() {
    return {
      complexPassword: this.complexPassword,
      minPasswordLength: this.minPasswordLength,
      minNumberOfCharacterGroups: this.minNumberOfCharacterGroups
    };
  }
}

module.exports = PasswordRulesetView;
