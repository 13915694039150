import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  styled,
  Container as MuiContainer,
  type ContainerTypeMap as MuiContainerTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';

type AxContainerCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxContainerTypeMap<P = object, D extends React.ElementType = 'div'> = MuiContainerTypeMap<P & AxContainerCustomProps, D>;

export type AxContainerProps<
  D extends ElementType = AxContainerTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxContainerTypeMap<P & AxContainerCustomProps, D>, D>;

const styledOptions = {
  name: 'AxContainer'
};

const StyledAxContainer = styled(MuiContainer, styledOptions)<AxContainerProps>(() => {
  return {};
});

export const AxContainer: MuiOverridableComponent<AxContainerTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxContainerProps, ref: Ref<HTMLDivElement>) => {
  const ContainerProps: AxContainerProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxContainer { ...ContainerProps } ref={ ref }>
      { children }
    </StyledAxContainer>
  );
});

export default AxContainer;
