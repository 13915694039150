const $os = require('detectOS');
const logging = require('logging');
const ErrorHandler = require('ErrorHandler');
const _ = require('underscore');
const Backbone = require('Backbone');

const I18n = require('@common/libs/I18n');

const ZoomOverlay = require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');
const Resizable = require('@common/libs/behaviors/resizable/Resizable');

const KeyCode = require('@common/data/enums/KeyCode');

const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');

const QuestionView = require('@training/apps/training/views/activities/pages/questions/QuestionView');
const { isWithinRegion } = require('@common/libs/helpers/features/QuestionHelpers');

class HotspotQuestionPage extends QuestionView {
  constructor(...args) {
    super(...args);
    this.render = this.render.bind(this);
    this.onImageLoadError = this.onImageLoadError.bind(this);
    this.template = _.tpl(require('@training/apps/training/templates/activities/pages/questions/HotspotQuestionPage.html'));
  }


  regionTemplate(options) {
    return _.tpl(require('@training/apps/training/templates/activities/pages/questions/_hotspot_region.html'))(options);
  }

  clickTemplate(options) {
    return _.tpl(require('@training/apps/training/templates/activities/pages/questions/_hotspot_click.html'))(options);
  }

  events() {
    return {
      'click .hotspotimagewrapper': 'addMarker',
      'click .hotspotmarkerwrapper': 'removeMarker',
      'dragstart .hotspotimage': 'cancelDrag'
    };
  }

  behaviors() {
    return [
      {behaviorClass: Resizable},
      {
        behaviorClass: ZoomOverlay,
        imageWrapper: '.zoom-image-wrap'
      }
    ];
  }

  initialize(options) {
    super.initialize(options);

    this.answer = this.options.answer;

    this.viewState = new HotspotQuestionPage.ViewState();

    this.MARKER_TOP_OFFSET = 20;
    this.MARKER_LEFT_OFFSET = 20;

    this.listenTo(this.viewState, 'change:markerCount', this.onMarkerCountChange);
    this.listenTo(this.viewState, 'change:scale', this.onScaleChange);
  }

  render() {
    this.setupActionBarWithConfidence();

    this.markerLimit = this.variant.regionCount;

    this.$el.html(this.template({variant: this.variant}));

    return this;
  }

  viewDidAppear() {
    logging.info('HotspotQuestionPage - viewDidAppear');

    window.app.layout.flash.clear();

    this.onMarkerCountChange();

    //Add class to parent to fix scrolling
    $('#root-view .contentwrapper, #modalview .modal-content-wrapper').addClass('matchwrap');

    window.app.layout.showSpinner();

    return super.viewDidAppear();
  }

  _initializeImageViewers() {
    _.each(this._imageMediaInfo, (media) => {
      this._imageViewers[media.id] = ImageViewerFactory.createViewerInstance({
        media,
        fitToScreen: true,
        $el: this.$el.find(`[data-media-id = '${ media.id }']`)
      });

      if (media.id === this.variant.questionImg.preferred.id) {
        this.listenTo(this._imageViewers[media.id], 'image:loaded', this.onImageLoadSuccess);
        this.listenTo(this._imageViewers[media.id], 'image:error', this.onImageLoadError);
        this.listenTo(this._imageViewers[media.id], 'image:complete', this.onImageLoadComplete);
      }

      this._imageViewers[media.id].render();
    });
  }

  onImageLoadSuccess(image) {
    this.imageReady = true;

    this.$image = this.$el.find('.questionimage img').addClass('hotspotimage');

    logging.debug(`HotspotQuestionPage::onImageLoadSuccess - replaceWith -> imgWidth: ${ (this.$image[0] != null ? this.$image[0].naturalWidth : undefined) }`);

    this.updateImageScale(image);

    this.triggerAdjustment();
  }

  onImageLoadError(e) {
    if (this.isDestroyed) {
      return;
    }

    // Since the hotspot question type doesn't work without the image we show a hard failure with the Oops page and send the log
    // to the server so we have a record of it.
    ErrorHandler.handleError({
      errorMessage: `Hotspot image load failure for question id "${ this.activity.get('body').question.id }" and src "${ $(e.currentTarget).attr('src') }".`,
      errorDetailsMessage: I18n.t('errors.questions.hotspot.imageLoadFailure'),
      showDetails: false
    });
  }

  onImageLoadComplete() {
    if (this.isDestroyed) {
      return;
    }

    window.app.layout.hideSpinner();
  }

  onClose() {
    super.onClose();

    window.app.layout.hideSpinner();
  }

  addMarker(e) {
    if (this.submitted || !this.imageReady) {
      return undefined;
    }

    // If they've reached the marker limit
    if (this.viewState.getMarkerCount() === this.markerLimit) {
      return false;
    }

    const offset = this.$('.hotspotimage').offset();
    const offsetTop = offset.top - $(window).scrollTop();
    const offsetLeft = offset.left - $(window).scrollLeft();
    const xCoordinate = e.clientX - offsetLeft;
    const yCoordinate = e.clientY - offsetTop;
    const scale = this.viewState.getScale();

    const position = {};
    position.x = xCoordinate;
    position.y = yCoordinate;
    position.absoluteX = xCoordinate / scale;
    position.absoluteY = yCoordinate / scale;
    logging.debug(`Adding marker at (${ xCoordinate }, ${ yCoordinate }) -- absolute (${ position.absoluteX }, ${ position.absoluteY }) -- scale ${ scale }`);

    this.renderMarker(position);

    this.viewState.incrementMarkerCount();

    logging.debug(`HotspotQuestionPage - Add Marker, count: ${ this.viewState.getMarkerCount() }`);
    return undefined;
  }

  renderMarker(position) {
    // get the top left of the markers while thinking about the marker's offset
    const top = position.y - (this.MARKER_TOP_OFFSET / 2);
    const left = position.x - (this.MARKER_LEFT_OFFSET / 2);

    const $click = $(this.clickTemplate(position));
    $click.data('left', position.absoluteX);
    $click.data('top', position.absoluteY);
    $click.css({
      top,
      left
    });

    logging.debug(`HotspotQuestionPage - Render Marker, left: ${ position.absoluteX } - top: ${ position.absoluteY }`);

    this.$('.hotspotimagewrapper').append($click);
  }

  removeMarker(e) {
    if (this.submitted || !this.imageReady) {
      return undefined;
    }

    $(e.currentTarget).remove();

    this.viewState.decrementMarkerCount();

    logging.debug(`HotspotQuestionPage - Remove Marker, count: ${ this.viewState.getMarkerCount() }`);

    e.preventDefault();
    return false;
  }

  onMarkerCountChange() {
    if (this.viewState.getMarkerCount() === this.markerLimit) {
      this.hasSelectedAnswers = this.markerLimit;
      this.showActionBarWithConfidence();

      $(document).on('keypress', this.keyPress);
    } else {
      this.hideActionBar();

      $(document).off('keypress');
    }

    this.$el.find('.question-instruction').text(`${ this.viewState.getMarkerCount() } ${ I18n.t('question.numberseperator') } ${ this.markerLimit }`);

    // add class to update the colour of the hint text when the user has at least 1 selected answer
    if (this.viewState.getMarkerCount() > 0) {
      this.$el.find('.hinttext').addClass('enabled');
    } else {
      this.$el.find('.hinttext').removeClass('enabled');
    }
  }

  cancelDrag(e) {
    logging.debug('HotspotQuestionPage - Cancel drag');
    e.preventDefault();
    return false;
  }

  onSubmit(confidenceLevel) {
    if (!this.hasSelectedAnswers || this.submitted) {
      return;
    }

    $(document).off('keypress');

    this.submitted = true;

    const markers = [];
    const markerWrappers = [];
    this.$('.hotspotmarkerwrapper').each((index, hotspotMarkerWrapper) => {
      const $hotspotMarkerWrapper = $(hotspotMarkerWrapper);
      const left = $hotspotMarkerWrapper.data('left');
      const top = $hotspotMarkerWrapper.data('top');
      const normalizedLeft = left / this.$image[0].naturalWidth;
      const normalizedTop = top / this.$image[0].naturalHeight;

      logging.debug(`HotspotQuestionPage - onSubmit, left: (${ left }, ${ normalizedLeft }) - top: (${ top }, ${ normalizedTop })`);

      markers.push({
        left: normalizedLeft,
        top: normalizedTop
      });
      markerWrappers.push({
        element: $hotspotMarkerWrapper,
        normalizedLeft,
        normalizedTop
      });
    });

    // Submit answer and load next activity JSON
    const activityBody = {
      questionVariantId: this.activity.get('body').question.variants[0].id,
      questionCoordinates: markers,
      confidenceLevel
    };

    const { hideAnswersAndReason } = this.options;
    this.activity.setAction('ANSWERQUESTION', activityBody, {
      success: (actionResponse) => {
        this.hasGotServerResponse = true;
        if (hideAnswersAndReason) {
          this.next();
        } else {
          this.renderResult(actionResponse.answer.hotspot, markerWrappers);
          this.setPoints(actionResponse);
        }
      }
    });
  }

  renderResult(hotspot, markerWrappers = []) {
    let $hotspotAnswer;
    const addRegion = (option) => {
      const imgHeight = this.$image[0].naturalHeight;
      const imgWidth = this.$image[0].naturalWidth;
      const scale = this.viewState.getScale();

      $hotspotAnswer = $(this.regionTemplate(option));
      $hotspotAnswer.css({
        top: (option.top * imgHeight * scale) + 3,
        left: (option.left * imgWidth * scale) + 3,
        height: (option.height * imgHeight * scale) - 6,
        width: (option.width * imgWidth * scale) - 6
      });

      // if marker inside add correct class, otherwise incorrect class
      this.$('.hotspotimagewrapper').append($hotspotAnswer);
      return $hotspotAnswer;
    };

    const {
      correctRegions = [],
      incorrectRegions = []
    } = hotspot;
      
    // Option contains .height, .width, .top, .left scaled to 1.0 of the original height/width
    for (const option of incorrectRegions) {
      $hotspotAnswer = addRegion(option);
      $hotspotAnswer.addClass('correct');
    }

    markerWrappers.forEach((wrapper) => {
      const {
        element,
        normalizedLeft,
        normalizedTop
      } = wrapper;
      const isCorrect = correctRegions.some((region) => {
        return isWithinRegion(normalizedLeft, normalizedTop, region);
      });
      const markerClasses = isCorrect ? 'icon-CorrectAnswerStreak1 marker-icon correct-marker' : 'icon-remove marker-icon incorrect-marker';
      element.children().addClass(markerClasses);
    });
  }

  setPoints(response) {
    const points = response.pointsEarned != null ? response.pointsEarned : 0;
    const answer = response.answer;

    if (answer.isAnsweredCorrectly) {
      // Correct answer
      this.correct = true;
      this.$('.bonuscharacter div, .hinttext').removeClass('ponder')
        .addClass('correct');

      if (this.gameManager != null) {
        this.gameManager.questionAnsweredCorrect();
      }
    } else {
      // Incorrect answer
      this.correct = false;
      this.$('.bonuscharacter div, .hinttext').removeClass('ponder')
        .addClass('incorrect');

      if (this.gameManager != null) {
        this.gameManager.questionAnsweredIncorrect();
      }
    }

    this.showPointsArea(this.correct, points);
    window.apps.auth.session.user.addPoints(points);

    this.createReason(answer.question.variants[0]);

    // Update the markers
    if ($os.mobile) {
      $('#root-view .contentwrapper, #modalview .modal-content-wrapper').removeClass('matchwrap');
    }

    this.setupAndShowActionBarWithContinue();

    this.scrollResultBannerIntoView(this.setFocusOnCorrectIncorrect);

    $(document).on('keypress', this.keyPress);
  }

  onResize() {
    this.updateImageScale(this.$image != null ? this.$image[0] : undefined);
  }

  updateImageScale(imgEl) {
    const imgWidth = imgEl ? imgEl.naturalWidth : 0;

    if (imgWidth > 0) {
      // Scaling only to width
      const viewWidth = this.$('.answerwrapper').width();
      const scale = Math.min(viewWidth / imgWidth, 1.0);

      logging.debug(`HotspotQuestionPage::onResize -> set scale to: ${ scale }`);

      this.viewState.setScale(scale);
    }
  }

  onScaleChange() {
    const imgHeight = this.$image[0].naturalHeight;
    const imgWidth = this.$image[0].naturalWidth;
    const scale = this.viewState.getScale();

    const newHeight = scale * imgHeight;
    const newWidth = scale * imgWidth;

    this.$('.questionimage img, .hotspotimagewrapper').css({
      height: newHeight,
      width: newWidth
    });

    // Scale points
    this.$('.hotspotmarkerwrapper').each((index, hotspotMarkerWrapper) => {
      const $hotspotMarkerWrapper = $(hotspotMarkerWrapper);
      const markerPositioning = {
        top: ($hotspotMarkerWrapper.data('top') * scale) - (this.MARKER_TOP_OFFSET / 2),
        left: ($hotspotMarkerWrapper.data('left') * scale) - (this.MARKER_LEFT_OFFSET / 2)
      };
      $hotspotMarkerWrapper.css(markerPositioning);
    });

    // Scale answer regions, too
    this.$('.hotspotanswer').each((index, hotspotAnswer) => {
      const $hotspotAnswer = $(hotspotAnswer);
      const answerPositioning = {
        top: $hotspotAnswer.data('top') * newHeight,
        left: $hotspotAnswer.data('left') * newWidth,
        height: $hotspotAnswer.data('height') * newHeight,
        width: $hotspotAnswer.data('width') * newWidth
      };
      $hotspotAnswer.css(answerPositioning);
    });
  }

  onNext() {
    $(document).off('keypress');
    return (typeof this.options.complete === 'function' ? this.options.complete() : undefined);
  }

  keyPress(e) {
    if ([KeyCode.ENTER, KeyCode.SPACE].includes(e.which)) {
      if (this.submitted) {
        this.next();
      }
    }
  }
}

// ViewState model to respond to changes in the markerCount and scale values.
HotspotQuestionPage.ViewState = class ViewState extends Backbone.Model {

  defaults() {
    return {
      markerCount: 0,
      scale: 0
    };
  }

  getMarkerCount() {
    return this.get('markerCount');
  }

  incrementMarkerCount() {
    const count = this.getMarkerCount();
    this.setMarkerCount(count + 1);
  }

  decrementMarkerCount() {
    const count = this.getMarkerCount();
    this.setMarkerCount(count - 1);
  }

  setMarkerCount(count) {
    this.set('markerCount', count);
  }

  getScale() {
    return this.get('scale');
  }

  setScale(scale) {
    this.set('scale', scale);
  }
};

module.exports = HotspotQuestionPage;
