const { Model } = require('Backbone');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');
const Tag = require('@common/components/discover/models/Tag');

const API_FIELDS = {
  name: 'name',
  names: 'names',
  translations: 'translations',
  default: 'default',
  tags: 'tags'
};

class TagGroupFilterModel extends Model {
  defaults() {
    return {
      [API_FIELDS.tags]: [],
      [API_FIELDS.names]: new LocalizableString('')
    };
  }

  getName() {
    return this.get(API_FIELDS.names).getDefault();
  }

  apiEndpoint() {
    return 'tagGroup';
  }

  parse(data = {}) {
    data[API_FIELDS.names] = new LocalizableString(data[API_FIELDS.names]);

    if (data.tags) {
      data.tags.map((tag) => {
        return new Tag({id: tag, isActive: true, tag: tag});
      });
    }

    return data;
  }

  fetch(options = {}) {
    if (!this.id) {
      return;
    }

    super.fetch(options);
  }
}

module.exports = TagGroupFilterModel;
