// Module
var code = `<div class="page-header">
  <div class="ax-container relative">
    <div class="ax-grid ax-grid--no-gutter ax-grid--align-items-center">
      <h1 class="page-header__content page-header__content--justify-start guided-learning__page-header js-title-region"></h1>
      <div class="ax-grid__col--auto-size">
        <button class="js-how-to-link how-to-link gl-how-to-link ax-button ax-button--m ax-button--icon-left margin-right--m" aria-label="<%- t('general.howItWorks') %>">
          <span class="icon-question_sign icon--badge-refresher icon--reset-styles"></span>
          <span class="text"><%- t('general.howItWorks') %></span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="scroll-content">
  <div class="ax-container">
    <div class="page-content">
      <div class="clearfix content-region"></div>
      <div class="js-gl-how-to__dialog-region hidden"></div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;