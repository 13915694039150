const {
  ItemView
} = require('Marionette');
const I18n = require('@common/libs/I18n');

//TODO: when time permits, this can be passed into this View, to allow people to configure how many numbers they want to see on each side of the current page
const pageRangeConfig = 2;

class PaginationControlsItemView extends ItemView {

  initialize(options = {}) {
    ({
      hideSinglePage: this.hideSinglePage
    } = options);

    if (this.model.get('pageableList') == null) {
      throw new Error('Expected model to be instance of PageableModel, or model\'s pageableList is not set');
    }

    this.pageableListState = this.model.get('pageableList').state;
    this.pageRangeConfig = pageRangeConfig;

    this.listenTo(this.model.get('pageableList'), 'sync', () => {
      this.pageableListState = this.model.get('pageableList').state;
      this.render();
    });

    this.hasRecords = this.hasRecords.bind(this);
    this.hasPreviousPage = this.hasPreviousPage.bind(this);
    this.hasNextPage = this.hasNextPage.bind(this);
    this.getPageRangeView = this.getPageRangeView.bind(this);
  }

  getTemplate() {
    return require('@common/components/pageableList/paginationControlsItemView.html');
  }

  templateHelpers() {
    return {
      hasRecords: this.hasRecords,
      hasPreviousPage: this.hasPreviousPage,
      hasNextPage: this.hasNextPage,
      getPageNumberList: this.getPageRangeView,
      currentPage: this.pageableListState.currentPage,
      totalPages: this.pageableListState.totalPages,
      pageRangeConfig: this.pageRangeConfig
    };
  }

  className() {
    return 'pagination-controls';
  }

  events() {
    return {
      'click .next-page-button': 'onClickNextPage',
      'click .last-page-button': 'onClickLastPage',
      'click .previous-page-button': 'onClickPreviousPage',
      'click .first-page-button': 'onClickFirstPage',
      'click .page-number': 'onClickPageNumber'
    };
  }

  ui() {
    return {
      pageList: '.page-number-list'
    };
  }

  hasRecords() {
    return this.pageableListState.totalRecords > 0;
  }

  hasPreviousPage() {
    return this.pageableListState.currentPage !== this.pageableListState.firstPage;
  }

  hasNextPage() {
    return this.pageableListState.currentPage !== this.pageableListState.lastPage;
  }

  onClickNextPage() {
    this.model.getNextPage();
    this.trigger('pagination:clicked');
  }

  onClickLastPage() {
    this.model.getLastPage();
    this.trigger('pagination:clicked');
  }

  onClickPreviousPage() {
    this.model.getPreviousPage();
    this.trigger('pagination:clicked');
  }

  onClickFirstPage() {
    this.model.getFirstPage();
    this.trigger('pagination:clicked');
  }

  onClickPageNumber(e) {
    this.pageNumber = $(e.currentTarget).data('id');
    this.model.getPage(this.pageNumber);
    this.trigger('pagination:clicked');
  }

  getPageRangeView(argumentPageRangeConfig) {
    let adjustedPageRangeConfig = argumentPageRangeConfig;

    ({
      currentPage: this.currentPage,
      lastPage: this.lastPage,
      firstPage: this.firstPage,
      totalPages: this.totalPages
    } = this.pageableListState);

    if (this.hideSinglePage && this.totalPages <= 1) {
      return '';
    }

    // screens 480px or less require a smaller range of page controls to fit on the screen
    if ($(window).width() <= 480) {
      adjustedPageRangeConfig = 1;
    }

    if (this.totalPages <= (adjustedPageRangeConfig * 2 + 1)) {
      // Exactly or less totalPages in the range
      return this.createPageRangeView(this.firstPage, this.totalPages, this.currentPage);
      // More pages in the list than will be rendered
    }

    if (this.currentPage <= adjustedPageRangeConfig) {
      // Show FirstPage to (configAmount*2)+1
      return this.createPageRangeView(this.firstPage, this.firstPage + (adjustedPageRangeConfig * 2 + 1), this.currentPage);
    }

    if (this.currentPage + adjustedPageRangeConfig >= this.lastPage) {
      // Show (conifgAmount*2)+1 to LastPage
      return this.createPageRangeView(this.totalPages - (adjustedPageRangeConfig * 2 + 1), this.totalPages, this.currentPage);
    }
    // numbers that will show with even numbers on each side
    return this.createPageRangeView(this.currentPage - adjustedPageRangeConfig, this.currentPage + adjustedPageRangeConfig + 1, this.currentPage);


  }

  //create HTML number range given: start + end + current positions, and create respective styles for currentPage vs !currentPage
  createPageRangeView(start, end, currentPage) {
    let htmlNumbersString = '';
    for (let i = start; i < end; i++) {
      if (i === currentPage) {
        htmlNumbersString += `<button data-id="${ i }" class="current-page-number" aria-label="${ I18n.t('pagination.currentPage', {i: i + 1}) }" disabled>${ i + 1 }</button>`;
      } else {
        htmlNumbersString += `<button class="pagination-button page-number" data-id="${ i }" aria-label="${ I18n.t('pagination.selectPage', {i: i + 1}) }">
          <span class="page-number-style">${ i + 1 }</span>
        </button>`;
      }
    }

    return htmlNumbersString;
  }
}

module.exports = PaginationControlsItemView;
