const {
  isObject,
  isFunction,
  isString
} = require('underscore');
const { isJSONString } = require('@common/libs/helpers/types/StringHelpers');

// We should only stringify data when we're making a PUT/POST, data is an object, and content intended to be json
function shouldStringifyData(settings = {}, data) {
  const {
    type,
    contentType
  } = settings;

  return data != null
    && contentType === 'application/json'
    && !isJSONString(data)
    && isString(type)
    && ['POST', 'PUT'].includes(type.toUpperCase())
    && isObject(data)
    && !isFunction(data);
}

module.exports = (settings = {}, originalSettings = {}) => {
  const { data } = originalSettings;

  if (shouldStringifyData(settings, data)) {
    settings.data = JSON.stringify(data);
  }
};
