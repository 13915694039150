const Backbone = require('Backbone');

const GuidedLearningObjectiveList = require('@common/components/guidedLearning/collections/GuidedLearningObjectiveList');
const Marionette = require('Marionette');
const LearningObjectiveViewFactory = require('@common/components/guidedLearning/objectives/LearningObjectiveViewFactory');
const { INTERACTIVE, INTERACTIVE_NO_CURSOR_CHANGE } = require('@common/libs/behaviors/card/CardModifierClasses');
const { COMPLETE, INCOMPLETE, NONE } = require('@common/data/models/objectives/ObjectiveConnectorStatus');

require('@common/libs/behaviors/card/Card');

class ObjectiveItemCollectionWrapperView extends Marionette.LayoutView {
  className() {
    return 'grouped-objective-items';
  }

  tagName() {
    return 'li';
  }

  getTemplate() {
    return '<div class="js-content-region"></div>';
  }

  regions() {
    return { contentRegion: '.js-content-region'};
  }

  initialize() {
    const items = this.model.get('items');
    this.collection = new GuidedLearningObjectiveList(items);
  }

  onRender() {
    const collectionView = new ProgramCollectionView({
      collection: this.collection,
      childOptions: Object.assign({}, {collection: this.collection}, this.options.childOptions)
    });

    this.contentRegion.show(collectionView);
  }
}

class ProgramCollectionView extends Marionette.CollectionView {
  behaviors() {
    const cardOptions = [];
    const disabled = this.getOption('childOptions').disabled;

    if (this.getOption('collection').containsInteractiveObjective() && !disabled) {
      cardOptions.push(INTERACTIVE, INTERACTIVE_NO_CURSOR_CHANGE);
    }

    return {
      Card: {
        modifierClasses: cardOptions
      }
    };
  }

  className() {
    return 'learning-objectives js-learning-objectives';
  }

  tagName() {
    return 'ul';
  }

  childView() {
    return Marionette.ItemVIew;
  }

  buildChildView(child, ChildViewClass, childViewOptions) {
    const view = LearningObjectiveViewFactory.create(child, childViewOptions);
    return view;
  }

  childViewOptions(model) {
    const childOptions = this.options.childOptions;
    const enabled = !childOptions.disabled && !this.collection.isLocked(model);
    return Object.assign({}, childOptions, {
      disabled: !enabled
    });
  }
}

class ProgramCollectionGroupView extends Marionette.CollectionView {
  getChildView() {
    return ObjectiveItemCollectionWrapperView;
  }

  tagName() {
    return 'ul';
  }

  filter(child) {
    return child.get('items').length > 0;
  }

  buildChildView(child) {
    return new ObjectiveItemCollectionWrapperView({
      model: child,
      childOptions: this.options.childOptions,
      index: this.collection.indexOf(child),
      length: this.collection.length
    });
  }
}

class GLItemViewHelpers {
  static makeListsWithJsonObjectives (listOfObjectiveBlobs) {
    return listOfObjectiveBlobs.map((objectiveBlob) => {
      return new GuidedLearningObjectiveList(objectiveBlob, {parse: true});
    });
  }

  // _getCollectionView: Array, Obj
  static getCollectionView(objectiveCollection, options = {}) {
    // All items dependent on each other come back in this order from the server
    // and we maintain this. If we had to topological sort, it would be more of a pain.
    // _createGroupedCollection: Array of GuidedLearningObjectiveList
    const _createGroupedCollection = (collection) => {
      let id = 0;
      return new Backbone.Collection(collection.map((newSet) => {
        return new Backbone.Model({items: newSet.models, id: id++});
      }));
    };

    const groupedCollection = _createGroupedCollection(objectiveCollection);
    const collectionView = new ProgramCollectionGroupView({collection: groupedCollection, childOptions: options});
    return collectionView;
  }

  static setVisualConnectionStatusForAvailableComponents({
    availableObjectiveComponents,
    eventObjectiveComponents,
    milestoneCollection
  }) {
    if (!availableObjectiveComponents.length) {
      return;
    }

    let lastMilestoneStatus = NONE;
    const lastMilestone = milestoneCollection.last();
    if (lastMilestone && lastMilestone.isMilestoneComplete()) {
      lastMilestoneStatus = COMPLETE;
    } else if (milestoneCollection.length) {
      lastMilestoneStatus = INCOMPLETE;
    }

    let firstEventStatus = NONE;
    if (eventObjectiveComponents.length) {
      firstEventStatus = INCOMPLETE;

      const lastAvailableObjective = GLItemViewHelpers._getLastObjective(availableObjectiveComponents);
      const firstEventObjective = GLItemViewHelpers._getLastObjective(eventObjectiveComponents);
      if (firstEventObjective.isComplete() && lastAvailableObjective.isComplete()) {
        firstEventStatus = COMPLETE;
      }
    }

    GLItemViewHelpers.setVisualConnectionStatusForObjectiveComponents(
      availableObjectiveComponents,
      { previousToCollectionStatus: lastMilestoneStatus, nextToCollectionStatus: firstEventStatus }
    );
  }

  static setVisualConnectionStatusForEvents({
    eventObjectiveComponents,
    availableObjectiveComponents,
    milestoneCollection
  }) {
    if (!eventObjectiveComponents.length) {
      return undefined;
    }

    let previousStatus = NONE;
    if (availableObjectiveComponents.length) {
      previousStatus = INCOMPLETE;

      const lastAvailableObjective = GLItemViewHelpers._getLastObjective(availableObjectiveComponents);
      const firstEventObjective = GLItemViewHelpers._getLastObjective(eventObjectiveComponents);
      if (firstEventObjective.isComplete() && lastAvailableObjective.isComplete()) {
        previousStatus = COMPLETE;
      }
    } else if (milestoneCollection.length) {
      previousStatus = INCOMPLETE;
      if (milestoneCollection.last().isMilestoneComplete()) {
        previousStatus = COMPLETE;
      }
    }

    return GLItemViewHelpers.setVisualConnectionStatusForObjectiveComponents(
      eventObjectiveComponents,
      { previousToCollectionStatus: previousStatus }
    );
  }

  static setVisualConnectionStatusForMilestones({
    prereqsList,
    milestoneInterfaceModel,
    milestoneCollection,
    milestoneIndex,
    followingObjectiveComponents,
    milestoneModel
  } = {}) {
    const numMilestoneItems = milestoneCollection.length;

    let previousToCollectionExists = false;
    let previousToCollectionComplete = false;
    let nextToCollectionExists = false;
    let nextToCollectionComplete = false;

    if (milestoneIndex > 0) {
      previousToCollectionExists = true;
      previousToCollectionComplete = milestoneCollection.at(milestoneIndex - 1).isMilestoneComplete();
    }

    if (milestoneIndex === (numMilestoneItems - 1)) {
      nextToCollectionExists = Boolean(followingObjectiveComponents.length);
      if (nextToCollectionExists) {
        const firstFollowingObjective = GLItemViewHelpers._getFirstObjective(followingObjectiveComponents);
        nextToCollectionComplete = firstFollowingObjective.isComplete() && milestoneModel.isMilestoneComplete();
      }
    } else {
      const nextMilestoneColletion = milestoneCollection.at(milestoneIndex + 1);
      const nextPrereqs = nextMilestoneColletion.getPrereqs();
      nextToCollectionExists = true;

      if (nextPrereqs.length) {
        nextToCollectionComplete = nextPrereqs[0][0].complete;
      } else {
        nextToCollectionComplete = nextMilestoneColletion.isMilestoneComplete();
      }
    }

    const milestoneIsComplete = milestoneInterfaceModel.isMilestoneComplete();
    const nextToCollectionStatus = milestoneIsComplete ? COMPLETE : INCOMPLETE;

    let previousToCollectionStatus = NONE;
    if (previousToCollectionExists) {
      previousToCollectionStatus = INCOMPLETE;
    }
    if (previousToCollectionComplete) {
      previousToCollectionStatus = COMPLETE;
    }

    GLItemViewHelpers.setVisualConnectionStatusForObjectiveComponents(prereqsList, {nextToCollectionStatus, previousToCollectionStatus});

    let previousVisualConnectorStatus = NONE;

    if (prereqsList.length) {
      const isPrereqsListComplete = prereqsList[prereqsList.length - 1].last().isComplete();
      previousVisualConnectorStatus = isPrereqsListComplete ? COMPLETE : INCOMPLETE;
    } else if (previousToCollectionExists) {
      previousVisualConnectorStatus = previousToCollectionComplete ? COMPLETE : INCOMPLETE;
    }

    if (previousVisualConnectorStatus === COMPLETE && !milestoneModel.isMilestoneComplete()) {
      previousVisualConnectorStatus = INCOMPLETE;
    }

    milestoneInterfaceModel.setPreviousVisualConnectorStatus(previousVisualConnectorStatus);

    let nextVisualConnectorStatus = NONE;

    if (nextToCollectionExists) {
      nextVisualConnectorStatus = nextToCollectionComplete ? COMPLETE : INCOMPLETE;
    }

    if (nextVisualConnectorStatus === COMPLETE && !milestoneModel.isMilestoneComplete()) {
      nextVisualConnectorStatus = INCOMPLETE;
    }

    milestoneInterfaceModel.setNextVisualConnectorStatus(nextVisualConnectorStatus);
  }

  static setVisualConnectionStatusForObjectiveComponents(
    objectiveComponents,
    {
      previousToCollectionStatus = NONE,
      nextToCollectionStatus = NONE
    } = {}
  ) {
    let guidedLearningObjectives = [];
    objectiveComponents.forEach((objectievComponent) => {
      guidedLearningObjectives = guidedLearningObjectives.concat(objectievComponent.models);
    });

    const objectsLength = guidedLearningObjectives.length;

    for (let x = 0; x < objectsLength; x++) {
      const currentObjective = guidedLearningObjectives[x];

      if (x === 0) {
        let previousStatus = INCOMPLETE;
        if (previousToCollectionStatus === NONE) {
          previousStatus = NONE;
        } else if (previousToCollectionStatus === COMPLETE && currentObjective.isComplete()) {
          previousStatus = COMPLETE;
        }

        currentObjective.setPreviousVisualConnectorStatus(previousStatus);
      } else {
        const isPreviousComplete = guidedLearningObjectives[x - 1].isComplete();
        const previousVisualConnectorStatus = currentObjective.isComplete() && isPreviousComplete ? COMPLETE : INCOMPLETE;
        currentObjective.setPreviousVisualConnectorStatus(previousVisualConnectorStatus);
      }

      if (x === (objectsLength - 1)) {
        let nextStatus = INCOMPLETE;
        if (nextToCollectionStatus === NONE) {
          nextStatus = NONE;
        } else if (nextToCollectionStatus === COMPLETE && currentObjective.isComplete()) {
          nextStatus = COMPLETE;
        }

        currentObjective.setNextVisualConnectorStatus(nextStatus);
      } else {
        const isNextComplete = guidedLearningObjectives[x + 1].isComplete();
        const nextVisualConnectorStatus = (currentObjective.isComplete() && isNextComplete) ? COMPLETE : INCOMPLETE;
        currentObjective.setNextVisualConnectorStatus(nextVisualConnectorStatus);
      }
    }
  }

  static _getLastObjective(objectiveComponents) {
    const lastObjectiveComponent = objectiveComponents[objectiveComponents.length - 1];
    return lastObjectiveComponent.at(lastObjectiveComponent.length - 1);
  }

  static _getFirstObjective(objectiveComponents) {
    return objectiveComponents[0].at(0);
  }
}
module.exports = GLItemViewHelpers;
