// Module
var code = `:root {
  --ax-app-header-background-color: <%- mainHeaderColour %>;
  --ax-app-header-button-hover-background-color: rgba(255, 255, 255, 0.2);
  --ax-app-header-font-color: var(--ax-color-white);
}

<% if (darkTheme) { %>
  #header .header-dropdown-button-wrapper .item-icon,
  #header .header-dropdown-button-wrapper .item-text,
  #header .header-dropdown-button-wrapper .menu-overflow-wrapper .item-icon,
  #header .header-dropdown-button-wrapper .menu-overflow-wrapper .item-text,
  #header .header-dropdown-item .item-text,
  #header .header-dropdown-item .item-icon,
  #header .back-button .back-text,
  #header .back-button span,
  #header .menu-list.menu-list-top .menu-item .item-icon,
  #header .menu-list.menu-list-top .menu-item .item-text,
  #header .menu-list.menu-list-top .menu-item.selected .item-icon,
  #header .menu-list.menu-list-top .menu-item.selected .item-text,
  #header .header-slideout__button,
  #header .header-slideout__title {
    color: #202020;
  }

  [dir] #header .menu-list.menu-list-top .menu-item.selected .item-selected-bar {
    background-color: #202020;
  }

  [dir] #header .menu-list.menu-list-top .menu-item.focus-visible:focus,
  [dir] #header .menu-list.menu-list-top .menu-item.hover,
  [dir] #header .menu-list.menu-list-top .menu-item.active,
  [dir] #header .menu-open .header-dropdown-button-wrapper,
  [dir] #header .header-dropdown-button-wrapper.hover,
  [dir] #header .header-dropdown-button-wrapper.active,
  [dir] #header .header-dropdown-button-wrapper.focus-visible:focus,
  [dir] #header .header-button.hover,
  [dir] #header .header-button.active,
  [dir] #header .header-button.focus-visible:focus {
    background-color: rgba(89, 89, 89, 0.2);
  }

  :root {
    --ax-app-header-button-hover-background-color: rgba(89, 89, 89, 0.2);
    --ax-app-header-font-color: #202020 ;
  }
<% } %>

<% if (headerBorderColor) { %>
  [dir] #header {
    border-bottom: 1px solid <%- headerBorderColor %>;
  }

  :root {
    --ax-app-header-border-color: <%- headerBorderColor %>;
  }
<% } %>

<% if (headerBorderWidth) { %>
  [dir] #header {
    border-bottom-width: <%- headerBorderWidth %>rem;
  }

  :root {
    --ax-app-header-border-width: <%- headerBorderWidth %>rem;
  }
<% } %>

<% if (primaryButtonDefaultColour) { // primary button color is used because it works well for Grab (only client using top nav in mobile), but we should consider updating this when more branding options are available %>

  #header .menu-list.menu-list-locked-to-top .menu-item.selected .item-text,
  #header .menu-list.menu-list-locked-to-top .menu-item.selected .item-icon {
    color: <%- primaryButtonDefaultColour %>;
  }
<% } %>
`;
// Exports
module.exports = code;