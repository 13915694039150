const logging = require('logging');
const $os = require('detectOS');
const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');
const AxonifyExceptionCode = require('AxonifyExceptionCode');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const _ = require('underscore');
const AchievementTypeEnum = require('@common/data/enums/AchievementTypeEnum').default;

class NewsItemView extends Marionette.ItemView {
  static NewsItemStringOverrides = {
    TopicLevelGraduation1: 'TopicLevelGraduation',
    TopicLevelGraduation2: 'TopicLevelGraduation',
    TopicLevelGraduation3: 'TopicLevelGraduation',
    TopicLevelGraduation4: 'TopicLevelGraduation',
    TopicLevelGraduation5: 'TopicLevelGraduation'
  };

  tagName() {
    return 'li';
  }

  className() {
    return 'newsstory clearfix';
  }

  getTemplate() {
    return require('@training/apps/training/templates/_news_item.html');
  }

  events() {
    return {
      'click .like': 'likeNews',
      'click .unlike': 'unlikeNews'
    };
  }

  modelEvents() {
    return {
      change: 'render'
    };
  }

  constructor(...args) {
    super(...args);

    this._isNetworkIO = false;
  }

  templateHelpers() {
    const iconType = NewsItemView.NewsItemStringOverrides[this.model.get('iconType')] || this.model.get('iconType');

    return {
      iconLabel: this.getIconLabelString(iconType),
      contentString: this.getContentString(),
      contentAriaLabel: this.getContentAriaLabel(),
      likeText: this.getLikeText(),
      timeSince: this.getTimeSince(),
      contentID: this.model.get('id') + '-content',
      myLike: this.model.get('myLike'),
      isMobile: $os.mobile
    };
  }

  onRender() {
    logging.info('Render NewsItemView');
    const {
      iconType,
      type,
      myLike
    } = this.model.toJSON();

    this.$el.addClass(`${ iconType } ${ type }`);

    const $likeToggle = this.$('.js-like-toggle');
    const unlikeTemplate = `
      <span>${ I18n.t('news.unlike') }</span>
      <span class="icon-thumbs_up_alt"></span>
    `;

    if (myLike != null) {
      $likeToggle.addClass('unlike').attr('aria-pressed', true)
        .append(unlikeTemplate)
        .trigger('focus');
    } else {
      $likeToggle.addClass('like').attr('aria-pressed', false)
        .text(I18n.t('news.like'))
        .trigger('focus');
    }
  }

  getContentString() {
    const news = this.model.toJSON();

    let contentString = `news.type.${ news.type }`;
    if ((news.content.streakSize != null) && (news.content.streakSize === '1')) {
      contentString += 'Singular';
    }
    if (news.type === AchievementTypeEnum.MILESTONE_ACHIEVEMENT) {
      if (news.content.bonusPointsAchieved > 0) {
        contentString += '_allPointsAchieved';
      } else if (news.content.pointsAchieved > 0) {
        contentString += '_onlyBasePointsAchieved';
      }
    }

    return I18n.t(contentString, news);
  }

  getContentAriaLabel() {
    const contentString = HTMLHelpers.stripHtmlInlineElements(this.getContentString());

    if (this.model.get('myLike')) {
      return contentString + ' ' + I18n.t('news.likes.clickToUnlike');
    }
    return contentString + ' ' + I18n.t('news.likes.clickToLike');

  }

  getIconLabelString(iconType) {
    const streakSize = this.model.get('content').streakSize;
    let label = 'achievements.iconLabels.';
    if (this.model.get('type') === AchievementTypeEnum.MILESTONE_ACHIEVEMENT) {
      label = 'settings.hub.training.news.newsTypes.';
    }

    if (streakSize != null) {
      return I18n.t(label + this.model.get('type'), {streakSize: streakSize});
    }
    return I18n.t(label + iconType);

  }

  getTimeSince() {
    const newsCreateTimeServer = this.model.get('newsDate');
    const newsCreateTimeClient = newsCreateTimeServer - window.apps.auth.session.serverTimeOffset;
    return dateHelpers.timeFromEvent(newsCreateTimeClient);
  }

  getLikeText() {
    const newsStory = this.model.toJSON();

    const parameters = [];
    if (newsStory.topLikes == null) {
      newsStory.topLikes = [];
    }
    let others = newsStory.likeCount - newsStory.topLikes.length;
    if (newsStory.myLike != null) {
      parameters.push('you');
      others -= 1;
    }

    if (0 < newsStory.topLikes.length && newsStory.topLikes.length <= 3) {
      const likeIndex = newsStory.topLikes.length - 1; // subtract one for array offset
      parameters.push(['one', 'two', 'three'][likeIndex]);
    }

    if (others === 1) {
      parameters.push('one_other');
    } else if (others > 1) {
      parameters.push('n_others');
    }

    // Server determines if the displayname is either fullname or nickname
    const topLikes = _.map(newsStory.topLikes, (topLike) => {
      return topLike.user.fullname;
    });

    // Return
    if (parameters.length > 0) {
      const key = `news.likes.${ parameters.join('_and_') }`;
      logging.debug(`News Story ${ newsStory.id } Key ${ key }`);
      return `\u00A0\u2022 \u00A0${ I18n.t(key, {
        names: topLikes,
        count: others
      }) }`;
    }
    return '';

  }

  likeNews(e) {
    if (this._isNetworkIO) {
      return;
    }
    this._isNetworkIO = true;
    e.preventDefault();
    this.model.likeNews({
      showSpinner: false,
      error: (model, response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);

        if ([AxonifyExceptionCode.CLIENT_ERROR_DUPLICATE_ENTITY, AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_VALID_ENTITY].includes(exception.getErrorCode())) {
          response.skipGlobalHandler = true;
          this.model.fetch();
        }
      }
    }).always(() => {
      this._isNetworkIO = false;
    });
  }

  unlikeNews(e) {
    if (this._isNetworkIO) {
      return;
    }
    this._isNetworkIO = true;
    e.preventDefault();
    this.model.unlikeNews({
      showSpinner: false,
      error: (model, response) => {
        const exception = AxonifyExceptionFactory.fromResponse(response);

        if ([AxonifyExceptionCode.CLIENT_ERROR_DUPLICATE_ENTITY, AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_VALID_ENTITY].includes(exception.getErrorCode())) {
          response.skipGlobalHandler = true;
          this.model.fetch();
        }
      }
    }).always(() => {
      this._isNetworkIO = false;
    });
  }
}

class NewsFeedItemView extends NewsItemView {
  tagName() {
    return 'div';
  }

  attributes() {
    if ($os.desktop) {
      return {
        role: 'article',
        'aria-labelledby': this.model.get('id') + '-content',
        'aria-posinset': this.model.get('posInSet'),
        'aria-setsize': this.model.get('listLength')
      };
    }
    return {
      role: 'listitem'
    };

  }
}

class NoNewsItem extends Marionette.ItemView {
  getTemplate() {
    return `
      <p class="nonews center"><%- t('news.nonews') %></p>
    `;
  }
}

class NoMoreNews extends Marionette.ItemView {
  getTemplate() {
    return `
      <p class="nomorepages"><%- t('news.nomorepages') %></p>
    `;
  }
}

class NoRecentAchievements extends Marionette.ItemView {
  getTemplate() {
    return `
      <li class="nonews"><%- t('news.noAchievements') %></li>
    `;
  }
}

module.exports = {
  NewsFeedItemView,
  NewsItemView,
  NoNewsItem,
  NoMoreNews,
  NoRecentAchievements
};
