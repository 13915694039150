const Backbone = require('Backbone');

class RegistrationOption extends Backbone.Model {
  toOption() {
    const id = this.get('id');
    const value = this.get('name');
    return {id, value};
  }
}

module.exports = RegistrationOption;
