const Backbone = require('Backbone');

class PageReport extends Backbone.Model {
  get className() {
    return 'PageReport';
  }

  get defaults() {
    return {
      comment: ''
    };
  }

  get validators() {
    return {
      reportType: 'required',
      comment: 'required'
    };
  }
}

module.exports = PageReport;
