const _ = require('underscore');

const UIKit = require('@training/widgets/UIKit');
const ProfileIconHandler = require('@common/components/ProfileIconHandler');

require('@common/libs/behaviors/card/Card');

class UserCardView extends UIKit.View {
  get template() {
    return _.tpl(`\
    <div class="user-small">
      <div class="small-info-wrapper clearfix ax-grid ax-grid--align-items-center">
        <div class="small-info-left ax-grid__col--auto-size">
          <div class="small-name-logo"></div>
        </div>
        <div class="small-info-right">
          <div class="name text-ellipsis ax-font ax-font__display ax-font--no-margin"><%- user.displayName %></div>
          <p class="department ax-font--no-margin ax-font--secondary"><%- user.location.name %></p>
        </div>
      </div>
      <div class="small-bottom-bar clearfix ax-grid ax-grid--no-gutter ax-grid--align-items-center">
        <div class="meta <%- bottomBar.meta.className %> ax-font ax-font__caption ax-grid ax-grid--no-gutter ax-grid--align-items-center">
          <%= bottomBar.meta.html %>
        </div>
        <div class="ax-grid__col--auto-size">
          <%= axButton({ className: 'js-user-button', label: bottomBar.buttonText }) %>
        </div>
      </div>
    </div>\
`);
  }

  behaviors() {
    return {
      Card: {
        target: '.user-small'
      }
    };
  }

  events() {
    return {'click .js-user-button': 'onButtonClick'};
  }

  /**
   * This is here to mark the interface -- the extending classes implement the actual logic for this button click
   * handler. You should not add anything here since it will be overriden completely and super is not called
   * in most cases.
   */
  onButtonClick() {
    return undefined;
  }

  render() {
    const user = this._getUserData();
    const bottomBar = this._getBottomBarData();

    this.$el.html(this.template({
      user,
      bottomBar
    }));

    if (bottomBar.data != null) {
      this.$('.js-user-button').data(bottomBar.data);
    }

    // Inject the user icon profile handler
    this.iconHandler = new ProfileIconHandler({
      user,
      profileElement: this.$('.small-name-logo')
    });

    return this;
  }

  _getUserData() {
    const user = this.model.toJSON();
    return _.defaults(user, {
      location: {
        name: ''
      }
    });
  }

  _getBottomBarData() {
    return {
      buttonText: '',
      meta: {
        className: '',
        html: ''
      }
    };
  }
}

module.exports = UserCardView;
