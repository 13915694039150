// Module
var code = `<div class="heading"><%- t('login.cookiesRequired') %></div>

<div class="message clearfix">
  <div class="text"><%- t('login.cookiesWarningMessage') %></div>

  <div class="buttonbar clearfix">
    <a href="#" class="axon-button retry"><%- t('general.tryAgain') %></a>
  </div>

  <div class="topborder"></div>

  <div class="contact"><%= helpContact %></div>

  <% if (showLanguageSelector) { %>
  <div class="language-selector-wrapper">
    <select no-default class="language-selector text-left" name="language" data-field="lang" data-options="languages"></select>
  </div>
  <% } %>

  <% if (isInApp) { %>
  <div class="privacy-policy">
    <a href="/policies/privacy.html" target="_blank"><%- t('login.privacyPolicy') %></a>
  </div>
  <% } %>

</div>
`;
// Exports
module.exports = code;