const Backbone = require('Backbone');

const LearningEvent = require('@training/apps/training/models/LearningEvent');

class ScheduledEvent extends Backbone.Model {
  static FIELDS = {
    EVENT_URL: 'eventUrl',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    MAX_ENROLLMENT: 'maxEnrollment',
    NUM_ENROLLED: 'numEnrolled',
    LEARNING_EVENT: 'learningEvent'
  };

  defaults() {
    return {
      [ScheduledEvent.FIELDS.EVENT_URL]: ''
    };
  }

  queryParams = {includeCounts: true};

  apiEndpoint() {
    return `/learning-events/${ this.eventId }/scheduled-events/`;
  }

  initialize(attributes, options = {}) {
    ({
      scheduledEventId: this.scheduledEventId,
      eventId: this.eventId
    } = options);
    super.initialize(attributes, options);
  }

  fetch(options = {}) {
    // This is a custom endpoint that is not part of the standard RESTful API
    // it returns a smaller subset of data needed for the scheduled event details page in LearnerZone
    options.url = `${ this.url() }/learner-details`;
    return super.fetch($.extend(true, {}, { data: this.queryParams }, options));
  }

  getLearningEventModel() {
    return new LearningEvent({
      scheduledEvent: {
        learningEvent: this.get(ScheduledEvent.FIELDS.LEARNING_EVENT)
      }
    }, {parse: true});
  }

  hasEventUrl() {
    const eventURL = this.get(ScheduledEvent.FIELDS.EVENT_URL);
    return eventURL.trim().length > 0;
  }

  hasEventCode() {
    const learningEvent = this.get(ScheduledEvent.FIELDS.LEARNING_EVENT);

    if (learningEvent) {
      const learningEventCode = learningEvent.code;
      return learningEventCode.trim().length > 0;
    }

    return false;
  }

  isPastEvent() {
    return this.get(ScheduledEvent.FIELDS.END_DATE) < (new Date()).getTime();
  }

  isFull() {
    return this.get(ScheduledEvent.FIELDS.NUM_ENROLLED) >= this.get(ScheduledEvent.FIELDS.MAX_ENROLLMENT);
  }

  parse(res) {
    if (res?.instructor != null) {
      res.instructor.location = {
        name: res.instructor.locationName
      };
    }

    return res;
  }
}

module.exports = ScheduledEvent;
