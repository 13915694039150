const { ItemView } = require('Marionette');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const FileHelpers = require('@common/libs/file/FileHelpers');
const DateHelpers = require('@common/libs/dateHelpers');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ImportHistoryErrorsModalView = require('@training/apps/search/communitiesManagement/ImportHistoryErrorsModalView');
const BulkImportStatusEnum = require('@common/data/enums/BulkImportStatusEnum');

class ImportHistoryTableItemView extends ItemView {
  tagName() {
    return 'tr';
  }

  templateHelpers() {
    return {
      statusPhrase: this._getStatusPhrase(this.model.get('status')),
      authFragment: `/axonify/${ window.apps.auth.getAuthFragment() }`,
      date: DateHelpers.convertDateFormatLongDateTimeWithStrings(this.model.get('createDate')),
      userFullName: this.getUserFullName(),
      getFileDownload: () => {
        const uuid = this.model.get('file').uuid;
        const fileName = this.model.get('file').originalFileName;
        return FileHelpers.downloadLink(uuid, fileName, { text: fileName });
      }
    };
  }

  _getStatusPhrase(status) {
    // override this one case, so it says "Complete" instead of "Ready"
    if (status === BulkImportStatusEnum.READY) {
      return I18n.t('imports.error.error_type.COMPLETE');
    }
    return I18n.t(`imports.error.error_type.${ this.model.get('status') }`);
  }

  getUserFullName() {
    const user = this.model.get('user');
    if (user) {
      return user.fullname;
    }
    // in the case that a superadmin does an import, the user field is null.
    return I18n.t('imports.supportUsername');
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/ImportHistoryTableItemTemplate.html');
  }

  ui() {
    return {
      viewErrorsLink: '.js-view-errors'
    };
  }

  events() {
    return {
      'click @ui.viewErrorsLink': this._showErrorsModal
    };
  }

  onRender() {
    this.ui.viewErrorsLink.toggle(this.model.get('status') === BulkImportStatusEnum.ERROR);
  }

  _showErrorsModal() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal import-errors-modal'
    });
    const modalChildView = new ImportHistoryErrorsModalView({
      id: this.model.get('id')
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });
  }
}

module.exports = ImportHistoryTableItemView;
