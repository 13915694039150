import {
  type Ref,
  forwardRef
} from 'react';
import {
  type CARD_PADDING,
  getCardPadding
} from './AxCard';
import {
  styled,
  CardActions as MuiCardActions,
  type CardActionsProps as MuiCardActionsProps
} from '@mui/material';

export interface AxCardActionsProps extends MuiCardActionsProps {
  padding?: CARD_PADDING
  enableTopPadding?: boolean
}

const styledOptions = {
  name: 'AxCardActions',
  shouldForwardProp: (prop: string) => {
    return prop !== 'padding' && prop !== 'enableTopPadding';
  }
};

const StyledAxCardActions = styled(MuiCardActions, styledOptions)<AxCardActionsProps>(({
  padding,
  enableTopPadding
}) => {
  const defaultStyles = {
    padding: getCardPadding(padding)
  };

  if (!enableTopPadding) {
    return {
      ...defaultStyles,
      paddingTop: '0'
    };
  }

  return defaultStyles;
});

export const AxCardActions = forwardRef(({
  children,
  enableTopPadding = true,
  padding = 'medium',
  ...otherProps
}: AxCardActionsProps, ref: Ref<HTMLDivElement>) => {
  const cardActionsProps: AxCardActionsProps = {
    children,
    enableTopPadding,
    padding,
    ...otherProps
  };

  return (
    <StyledAxCardActions { ...cardActionsProps } ref={ ref }>
      { children }
    </StyledAxCardActions>
  );
});

export default AxCardActions;
