const { isSymbol, isString } = require('underscore');

/*
 * The following code was pretty much all ripped from the source code for Lodash.js
 * https://github.com/lodash/lodash/blob/2f79053d7bc7c9c9561a30dda202b3dcd2b72b90/.internal/toKey.js
*/

/** Used as references for various `Number` constants. */
const INFINITY = 1 / 0;

/**
 * Converts `value` to a string key if it's not a string or symbol.
 *
 * @param {*} value The value to inspect.
 * @returns {string|symbol} Returns the key.
 */

const toKey = (value) => {
  if (isString(value) || isSymbol(value)) {
    return value;
  }

  const result = `${ value }`;
  return (result === '0' && (1 / value) === -INFINITY) ? '-0' : result;
};

module.exports = toKey;
