const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const I18n = require('@common/libs/I18n');
const Backbone = require('Backbone');

const RecoverUsernamePage = require('@common/modules/auth/views/recovery/username/RecoverUsernamePage');
const RecoverUsernameSubmittedPage = require('@common/modules/auth/views/recovery/username/RecoverUsernameSubmittedPage');
const RecoveryBaseView = require('@common/modules/auth/views/recovery/RecoveryBaseView');
const AccountRecoveryService = require('@common/modules/auth/views/recovery/AccountRecoveryService');

const StringHelpers = require('@common/libs/helpers/types/StringHelpers');

class RecoverUsernameController extends LayoutController {
  viewDefinition() {
    return {
      ViewClass: RecoveryBaseView,
      tenantPropertyPool: this.tenantPropertyPool,
      navChannel: this.navChannel,
      languageStateModel: this._languageStateModel
    };
  }

  regionControllers() {
    return {
      recoveryRegion: this._getInitialRecoveryRegionController()
    };
  }

  initialize(options = {}) {
    this.viewDefinition = this.viewDefinition.bind(this);

    ({
      navChannel: this.navChannel,
      flash: this.flash,
      tenantPropertyPool: this.tenantPropertyPool
    } = options);

    this._languageStateModel = new Backbone.Model({hasLanguageSelector: true});
  }

  onViewChangeLanguage(controller, view, formEditor) {
    const lang = formEditor.getValue().id;
    I18n.setLocale(lang, {
      always: () => {
        if (this.isDestroyed) {
          return;
        }
        this._resetView();
      }
    });
  }

  _resetView() {
    this.getView().render();
    this.replaceRegionController('recoveryRegion', this._getInitialRecoveryRegionController());
    this.showRegionController('recoveryRegion');
  }

  _getInitialRecoveryRegionController() {
    return {
      viewDefinition: {
        ViewClass: RecoverUsernamePage
      },
      delegateEvents: {
        'view:username:submit': this.onViewUsernameSubmit.bind(this)
      }
    };
  }

  onViewClickReturn() {
    this.navChannel.commands.execute('show:login', { loginPrompt: false });
  }

  onViewUsernameSubmit(controller, view, options = {}) {
    const value = options.value.trim();
    if (value !== '' && StringHelpers.isValidEmailAddress(value)) {
      this.submitEmailRecovery(value);
    } else {
      this.showUsernameError();
    }
  }

  showUsernameError() {
    this.flash.error({message: I18n.t('login.forgotUsername.invalidEmail')});
  }

  submitEmailRecovery(address) {
    const accountRecoveryService = new AccountRecoveryService();
    accountRecoveryService.emailRecoveryByAddress(address).then(() => {
      this.showEmailRecoverySuccess(address);
    });
  }

  showEmailRecoverySuccess(address) {
    this.replaceRegionController('recoveryRegion', {
      viewDefinition: {
        ViewClass: RecoverUsernameSubmittedPage,
        addressSubmitted: address
      }
    });

    this._languageStateModel.set('hasLanguageSelector', false);

    this.showRegionController('recoveryRegion');
  }
}

module.exports = RecoverUsernameController;
