const _ = require('underscore');
const {
  QuestionPreviewPage,
  QuestionOptionItemView,
  QuestionCollectionView
} = require('@common/components/questions/views/QuestionPreviewPage/QuestionPreviewPage');
const I18n = require('@common/libs/I18n');
const { getSelectedMultiLangImage } = require('@common/libs/helpers/app/ImageHelpers');

class MultipleChoiceAdvancedQuestionPreviewPage extends QuestionPreviewPage {
  className() {
    return 'multiple-choice-view advanced';
  }

  regions(options) {
    return _.extend(super.regions(options), {
      answerOptions: '#answer-body'
    });
  }

  onShow() {
    super.onShow();
    const optionTextView = this._getOptionCollectionView();
    this.answerOptions.show(optionTextView);

    return this.listenTo(optionTextView, 'image:clicked', (itemView, mediaId) => {
      return this.trigger('show:zoom:modal', itemView.getImageMedia(mediaId));
    });
  }

  _getOptionCollectionView() {
    return new MultipleChoiceAdvancedQuestionCollectionView({model: this.model});
  }
}

class MultipleChoiceAdvancedQuestionOptionItemView extends QuestionOptionItemView {
  getTemplate() {
    return require('@common/components/questions/templates/multipleChoiceAdvanced/answerOptionItem.html');
  }

  ui() {
    return {
      answerImage: '.answerimage',
      reasonImage: '.reason-image',
      imageWrapper: '.image-wrapper'
    };
  }

  regions(options) {
    return _.extend(super.regions(options), {
      reasonZoomPlaceholder: '.reason-zoom-placeholder'
    });
  }

  templateHelpers() {
    const translatedLanguage = this.translatedLanguage;
    return _.extend(super.templateHelpers(), {
      selectedStatus: this.selectedAnswer ? 'selected' : '',
      hasExplanation() {
        const reasonImg = getSelectedMultiLangImage(this.reasonImg, translatedLanguage)
        return (this.reasonText !== '') || (reasonImg != null);
      },
      getReasonImage() {
        const reasonImg = getSelectedMultiLangImage(this.reasonImg, translatedLanguage)
        return reasonImg != null ? reasonImg : null;
      },
      getReasonText() {
        return this.reasonText != null ? this.reasonText : '';
      }
    });
  }

  _hideAnswerImages() {
    const optionImg = getSelectedMultiLangImage(this.model.get('optionImg'), this.translatedLanguage);
    const reasonImg = getSelectedMultiLangImage(this.model.get('reasonImg'), this.translatedLanguage);
    if ((optionImg == null) && (reasonImg == null)) {
      this.ui.answerImage.hide();
      this.ui.reasonImage.hide();
    }
  }

  getBaseImageElementToAttachTo() {
    return this.ui.answerImage;
  }

  onShow() {
    super.onShow();
    const reasonImg = getSelectedMultiLangImage(this.reasonImg, this.translatedLanguage);
    if (reasonImg != null) {
      const zoomView = this.initPopoverZoomView({
        imgSrc: {image: reasonImg},
        container: '.context-leftpane'
      });
      if (zoomView.preventPopverLoading != null) {
        return;
      }

      const zoomOptions = {
        className: 'popover-image-zoom',
        placement: I18n.isCurrentLanguageRtl() ? 'right' : 'left',
        container: '.context-leftpane',
        $elementToAttachTo: this.ui.reasonImage,
        view: zoomView,
        viewportSelector: '.context-leftpane',
        scrollContainerSelector: '.scrollable',
        trigger: 'manual'
      };

      this.reasonZoomPlaceholder.show(zoomView);
      this.reasonMediaId = reasonImg.id;

      this.reasonPopoverView = zoomView;
      this.initPopoverController(zoomOptions);
    }
  }

  // Yes, it is intentional that this method is overriden completely
  previewImage(e) {
    const mediaId = $(e.currentTarget).data('media-id');
    let { popoverView } = this;
    let element = this.$elementToAttachTo;


    if (mediaId === this.reasonMediaId) {
      element = this.ui.reasonImage;
      popoverView = this.reasonPopoverView;
    }

    if (popoverView != null) {
      popoverView.triggerMethod('popover:show');
      element.popover('toggle');
    }
    return false;
  }

  _createImageMediaInfo() {
    super._createImageMediaInfo();
    this.reasonImg = this.model.get('reasonImg');
    const reasonImg = getSelectedMultiLangImage(this.reasonImg, this.translatedLanguage);
    if (reasonImg != null) {
      this._imageMediaInfo[reasonImg.id] = reasonImg;
    }
  }

  serializeData() {
    const data = super.serializeData();

    if (this.translatedLanguage) {
      data.optionText = data.optionTextTranslation != null ? data.optionTextTranslation.getValueForLanguage(this.translatedLanguage, false) : undefined;
      data.reasonText = data.reasonTextTranslation != null ? data.reasonTextTranslation.getValueForLanguage(this.translatedLanguage, false) : undefined;
    }

    return data;
  }
}

class MultipleChoiceAdvancedQuestionCollectionView extends QuestionCollectionView {
  getChildView() {
    return MultipleChoiceAdvancedQuestionOptionItemView;
  }

  childViewOptions(model, index) {
    return _.extend(super.childViewOptions(model, index), {
      selectedAnswer: model.get('id') === parseInt(this.options.model.get('questionOptionList'), 10)
    });
  }
}

module.exports = MultipleChoiceAdvancedQuestionPreviewPage;
