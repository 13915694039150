const Page = require('@common/components/discover/models/Page');

class TrainingArticle extends Page {
  apiEndpoint() {
    return '/trainingArticles';
  }

  get className() {
    return 'TrainingArticle';
  }
}

module.exports = TrainingArticle;
