const logging = require('logging');
const _ = require('underscore');

const { preventDragAndDrop } = require('@common/libs/helpers/app/BrowserHelpers');

const SCORMBaseView = require('@training/apps/training/views/activities/pages/scorm/SCORMBaseView');
const SCORMActivityPersistanceStrategy = require('@training/libs/SCORMActivityPersistanceStrategy');
const HeartbeatController = require('@common/libs/HeartbeatController');

require('@common/libs/behaviors/resizable/Resizable');

class SCORMEmbeddedView extends SCORMBaseView {

  template = _.tpl(require('@training/apps/training/templates/activities/pages/SCORMEmbedded.html'));

  className() {
    return 'fit-parent';
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  render() {
    logging.info('SCORMEmbeddedView - render');
    super.render();
    this._createSCORM();
    this.heartbeatController = new HeartbeatController();
    this.heartbeatController.startHeartbeat();

    try {
      this.scorm.launchInContentFrame(this.$('#scorm-content'));
    } catch (error) {
      this.handleScormError(error, 'There was an exception rendering SCORM in iFrame.');
    }

    if (!this.isErrorLoadingModule) {
      preventDragAndDrop(this.$('iframe'));
    }
  }

  onBeforeAttach() {
    logging.info('SCORMEmbeddedView - onBeforeAttach');

    window.app.layout.toggleFullPage(true);
    window.app.layout.toggleFooter(false);
  }

  onResize() {
    const visibleWidth = this.getInnerWidth();
    const visibleHeight = this.getInnerHeight();

    this.$('#scorm-content').css({
      width: `${ visibleWidth }px`,
      height: `${ visibleHeight }px`
    });
  }

  onClose() {
    logging.info('SCORMEmbeddedView - onClose');
    super.onClose();
    this.heartbeatController?.stopHeartbeat();

    window.app.layout.toggleFullPage(false);
    window.app.layout.toggleFooter(true);
  }

  getErrorRegionSelector() {
    return '.embedded-scorm-container';
  }

  getScormConfig() {
    const scormPackage = this.activity.getScormPackage();
    // When viewing Embedded SCORM, we assume it is not a Multi SCORM
    const scoItem = scormPackage.scoItems[0];

    return Object.assign(super.getScormConfig(), {
      scormScoId: scoItem.id,
      scoValues: (scoItem.scormModuleAnswer != null ? scoItem.scormModuleAnswer.scoValues : undefined),
      lastModify: (scoItem.scormModuleAnswer != null ? scoItem.scormModuleAnswer.lastModify : undefined),
      duration: scoItem.scormModuleAnswer?.duration,
      // assuming embedded scorm is created not is a completed state
      persistanceStrategy: new SCORMActivityPersistanceStrategy(this.activity, false)
    });
  }

  areAllSubModulesComplete() {
    // When viewing Embedded SCORM, we assume it is not a Multi SCORM
    return this.scorm.isScoComplete();
  }
}

module.exports = SCORMEmbeddedView;
