const _ = require('underscore');
const Backbone = require('Backbone');
const { isDuetSupported } = require('DuetDatePicker');
require('jquery.ui');

const dateHelpers = require('@common/libs/dateHelpers');
const I18n = require('@common/libs/I18n');

const Form = require('@common/components/forms/Form');
require('@common/components/forms/editors/date/DuetDatePicker.less');

Form.Editor.DateTimeNew = class DateTimeNew extends Form.Editor {

  initialize(options = {}) {
    this.state = new Backbone.Model();
    this.template = _.tpl(this.getDatePickerTemplate());

    this.opt = _.clone(options.options != null ? options.options : (options.options = {}));
    this._isPickerInUtcTime = this.opt.isPickerInUtcTime != null ? this.opt.isPickerInUtcTime : false;

    this.format = this.opt.format != null ? this.opt.format : function (date) {
      if (this._isPickerInUtcTime) {
        return dateHelpers.convertUtcDateFormatToDefaultDateTime(date);
      }
      return dateHelpers.convertDateFormatToDefaultDateTime(date);
    };

    if (this.opt.showTimeControls == null) {
      this.opt.showTimeControls = true;
    }

    // Enforce constraints
    if (this.opt.minDate) {
      this.setMinValue(this.opt.minDate);
    }
    if (this.opt.maxDate) {
      this.setMaxValue(this.opt.maxDate);
    }

    // The first valid date is either the minimum or today; depending if a minimum is enforced or not.
    if (this.$el.data('currentTimestamp') != null) {
      this.firstValidDate = this.$el.data('currentTimestamp');
    } else if (this.opt.minDate != null) {
      this.firstValidDate = this.opt.minDate;
    } else {
      this.firstValidDate = this._calculateToNextAvailableMinuteInterval(dateHelpers.createDate().valueOf());
    }

    this._renderDatePicker();
    super.initialize(options);
  }

  getDatePickerTemplate() {
    return `\
      <% if (isDuetSupported()) { %>
        <duet-date-picker class="date-picker duet-input" identifier="date" value="<%- date %>" min="<%- min %>" max="<%- max %>"></duet-date-picker>
      <% } %>
      <% if (showTimeControls) { %>
        <input type="time" class="time-picker" value="<%- time %>" aria-label="Time"/>
      <% } %>
    `;
  }

  _renderDatePicker() {
    // set current date/time or previously saved data as default placeholders
    this._updateControls(this.firstValidDate, true);
    const militaryTime = this.state.get('time');
    const placeholderDate = I18n.t('general.ex') + ' ' + dateHelpers.timestampToISODate(this.firstValidDate);

    this.datepickerTemplate = this.template({
      placeholder: placeholderDate,
      localization: this.localization,
      showTimeControls: this.opt.showTimeControls,
      isDuetSupported: isDuetSupported,
      date: this.state.get('date'),
      time: militaryTime,
      ariaLabel: this.opt.ariaLabel || I18n.t('time.title'),
      min: this.minValue || '',
      max: this.maxValue || ''
    });

    this.$datepicker = $(this.datepickerTemplate);
    this.$datepickerInput = $('#date.duet-date__input');

    this.form = new Form({
      html: this.$datepicker,
      model: this.state,
      context: {
        formOptions: this.formOptions
      }
    });

    this.$el.html(this.form.el);

    this.$datepicker[0].localization = this.localization(placeholderDate);

    //listen to changes on calendar
    this.$datepicker.on('duetChange', () => {
      if (!this.opt.showTimeControls) {
        this._updateModelWithDateTime();
      }
      this.trigger('change');
    });
    this.$datepicker.on('duetOpen', () => {
      if (this.opt.onOpenDuet) {
        this.opt.onOpenDuet();
      }
    });
    this.$datepicker.on('duetClose', () => {
      if (this.opt.onCloseDuet) {
        this.opt.onCloseDuet();
      }
    });

    // avoid updating model in this change listener unless necessary
    // 'change' event cascades down the Form.js, and can cause
    //  unexpected race-conditions in certain places
    this.on('change', () => {
      if (this.opt.showTimeControls) {
        this._updateModelWithDateTime();
      }
    });
  }

  _updateModelWithDateTime() {
    const date = this.$datepicker.val();
    let timestamp = dateHelpers.ISODateToTimestamp(date);
    if (this.opt.showTimeControls) {
      const timeInSeconds = this._getTime();
      timestamp += timeInSeconds;
    }
    this._setValue(timestamp);
    this._commitPicker();
  }

  _getTime() {
    const time = this.$('.time-picker').val();
    const times = time.split(':');

    // ((hours * 60) + minutes) * 60(seconds) * 1000 (milliseconds)
    const timeinMilliSeconds = ((times[0] * 60) + Number(times[1])) * 60 * 1000;
    return timeinMilliSeconds;
  }

  // Keep the state on the DOM since if the form ends up recreated,
  // the state would not survive, where-as it does on the DOM
  _value() {
    return this.$el.data('currentTimestamp');
  }

  _setValue(timestamp) {
    if (timestamp) {
      this.state.set('date', timestamp);
      this.$el.data('currentTimestamp', timestamp);
    } else if (this.opt.allowFieldToBeUnset) {
      this.state.set('date', null);
      this.$el.data('currentTimestamp', null);
    }
  }

  getValue() {
    return this._value();
  }

  setError(error) {
    this._error = error;
  }

  getError() {
    return this._error;
  }

  clearError() {
    this._error = null;
  }

  setMinValue(value) {
    if (value) {
      this.opt.minDate = (this.minValue = dateHelpers.timestampToISODate(value));
    }
  }

  setMaxValue(value) {
    if (value) {
      this.opt.maxDate = (this.maxValue = dateHelpers.timestampToISODate(value));
    }
  }

  validateFormEditor(required) {
    return this.validateDate(required);
  }

  validateDate(required = false) {
    const value = this._value();

    if (isDuetSupported()) {
      const datePickerVal = this.$('#date.duet-date__input').val();
      
      if (!dateHelpers.validateDateString(datePickerVal, required)) {
        return false;
      }
    }

    if (!value) {
      return this.opt.allowFieldToBeUnset ? value === null : false; // If we let the user unset the value, null is valid, otherwise they are invalid
    }
    if (this.minValue && (value < dateHelpers.ISODateToTimestamp(this.minValue))) {
      return false;
    }
    if (this.maxValue && (value > dateHelpers.ISODateToTimestamp(this.maxValue))) {
      return false;
    }

    return true;
  }

  _updateControls(date, onlyUpdateTime = false) {
    // change state's values
    if (!onlyUpdateTime) {
      this.state.set('date', dateHelpers.timestampToISODate(date));
      // change values within view
      this.$('duet-date-picker').val(this.state.get('date'));
    }

    this.state.set('time', dateHelpers.getMilitaryTimeFromDate(date));

    // change values within view
    this.$('.time-picker').val(this.state.get('time'));
  }

  _commitPicker() {
    const timestampToCommit = this.state.get('date');
    this._setValue(timestampToCommit, false);
  }

  setValue(timestamp, updateControls = true) {
    if (timestamp != null) {
      if (!_.isNumber(timestamp) || _.isNaN(timestamp)) {
        throw new Error('timestamp needs to be a number');
      }

      if (updateControls) {
        this._updateControls(new Date(timestamp));
      }

      this._setValue(timestamp);
      this.trigger('change:date', timestamp, this);
      this.trigger('change', this);
    } else if (this.opt.allowFieldToBeUnset) {
      this._setValue(null);
    }
  }

  _calculateToNextAvailableMinuteInterval(value) {
    if (!this.opt.showTimeControls) {
      return dateHelpers.createDate(value).startOf('day')
        .toDate();
    }

    const interval = this.minuteSelectorInterval;
    const date = dateHelpers.createDate(value);
    date.subtract(date.minute() % interval, 'minutes').add(interval, 'minutes');
    return date.startOf('minute').toDate();
  }

  onDestroy() {
    this.$datepicker.off('duetChange');
    this.off('change');
    if (this.form != null) {
      this.form.destroy();
    }
  }

  localization(placeHolderDate) {
    return {
      buttonLabel: I18n.t('UIKit.Form.Editor.DateTime.change'),
      placeholder: placeHolderDate || I18n.t('UIKit.Form.Editor.Date.placeholder'),
      selectedDateMessage: I18n.t('UIKit.Form.Editor.Date.selectedDate'),
      prevMonthLabel: I18n.t('UIKit.Form.Editor.DateTime.selectPreviousMonth'),
      nextMonthLabel: I18n.t('UIKit.Form.Editor.DateTime.selectNextMonth'),
      monthSelectLabel: I18n.t('UIKit.Form.Editor.Date.Month'),
      yearSelectLabel: I18n.t('UIKit.Form.Editor.Date.Year'),
      closeLabel: I18n.t('general.close'),
      calendarHeading: I18n.t('UIKit.Form.Editor.DateTime.change'),
      dayNames: dateHelpers.getDayNamesShort(),
      monthNames: dateHelpers.getMonthNames(),
      monthNamesShort: dateHelpers.getMonthNamesShort(),
      locale: I18n.getLocale()
    };
  }
};

module.exports = Form.Editor.DateTimeNew
