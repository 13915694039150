const I18n = require('@common/libs/I18n');

/**
 * To whomever looks at this code in the future, I expanded the if conditions and added copious inline comments to help you
 * understand WTF is going on here. Don't be clever and refactor it, OK? Cheers
 */
const getLanguageDirectionClass = (model) => {
  const ltrClassname = 'ltr-content';
  const rtlClassname = 'rtl-content';

  const detectedLanguage = _getDetectedLanguage(model);

  if (I18n.isRtlLanguage(apps.auth.session.user.get('language'))) {
    // this means the user's interface is in a RTL language, and all the CSS is flipped horizontally.
    // Left is right, right is left. Welcome through the looking-glass, Alice!

    if (model.get('autoTranslatedContent') == null) {
      // This content wasn't translated. That means it is either old (from when the feature wasn't on) or it is
      // in one of the user's preferred languages. We don't know which at this point.
      
      if (detectedLanguage) {
        // aha. So if this field exists, that means the feature was/is on, and a language was detected. That means we can use that knowledge

        if (!I18n.isRtlLanguage(detectedLanguage)) {
          // this is LTR content being shown to a RTL user. We need to class this as RTL, so it shows LTR! Fun, right?
          return rtlClassname;
        }
      }
    }
    // To get here, it means:
    // - the content was translated, so it is in the user's preferred language. Mic drop.
    // or
    // - the language was detected, and it's the same direction as the user's primary language (RTL)
    // or
    // - the language was not detected, so we can't really impose a direction
    //
    // in all of those cases, we can class this as LTR.
    return ltrClassname;
  }

  // below, you see the same conditions as above, but for a LTR user so we are looking for whether the content is
  // RTL. No need to annotate as much here, if you understand what is happening above.
  if (model.get('autoTranslatedContent') == null) {
    if (detectedLanguage) {
      if (I18n.isRtlLanguage(detectedLanguage)) {
        // this is RTL content being shown to a LTR user. We need to class this as RTL. Nothing weird going on here!
        return rtlClassname;
      }
    }
    return ltrClassname;
  }
  return ltrClassname;
};

// this method exists because the API is inconsistent.
const _getDetectedLanguage = (model) => {
  if (model.get('detectedLanguage')) {
    return model.get('detectedLanguage');
  }
  if (model.get('detectedLanguageId')) {
    return model.get('detectedLanguageId');
  }
  return null;
};

module.exports = {
  getLanguageDirectionClass
};
