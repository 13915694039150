import {
  SvgIcon,
  useTheme
} from '@mui/material';

export function CheckBoxOutlineBlank({ disabled = false }: { disabled?: boolean }) {
  const theme = useTheme();

  return disabled ? (
    <SvgIcon
      viewBox='0 0 20 20'
      sx={{
        fill: 'none'
      }}>
      <rect
        x='0.5'
        y='0.5'
        width='19'
        height='19'
        rx='3.5'
        fill={ theme.uiKit.colorGrey20 }/>
      <rect
        x='0.5'
        y='0.5'
        width='19'
        height='19'
        rx='3.5'
        stroke={ theme.uiKit.colorGrey30 }/>
    </SvgIcon>
  ) : (
    <SvgIcon
      viewBox='0 0 20 20'
      sx={{
        fill: 'none'
      }}>
      <rect
        width='19'
        height='19'
        x='.5'
        y='.5'
        rx='3.5'
        fill={ theme.uiKit.colorWhite }/>
      <rect
        width='19'
        height='19'
        x='.5'
        y='.5'
        rx='3.5'
        stroke={ theme.uiKit.colorGrey50 }/>
    </SvgIcon>
  );
}
