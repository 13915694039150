const GuidedLearningCategory = require('@common/components/guidedLearning/models/GuidedLearningCategory');

// This is not to be confused with a content category, these are two different concepts. This is just a way
// of generate a hierarchy of nodes which eventually the leaf nodes will contain objectives.

class SelfDirectedPathCategory extends GuidedLearningCategory {

  apiEndpoint() {
    return `/selfDirected/available/program${ this.programId && this.userId ? `/${ this.programId }/${ this.userId }` : '' }`;
  }

  isEventTraining() {
    return false; // we do not support event training in SD Paths
  }
}

module.exports = SelfDirectedPathCategory;
