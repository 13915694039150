// Module
var code = `<div class="ax-grid ax-grid--no-gutter ax-grid--align-items-center">
  <div class="ax-grid__col--auto-size">
    <div class="name-logo" aria-hidden="true" role="presentation"></div>
  </div>
  <div class="ax-grid ax-grid--direction-column">
    <div class="qa-user-name ax-font ax-font__caption ax-font__caption--bold text-ellipsis"><%= salutationName %></div>
    <div class="ax-font ax-font__caption text-ellipsis"><%= location.name %></div>
  </div>
</div>
`;
// Exports
module.exports = code;