const HTMLHelpers = require('@common/libs/helpers/app/HTMLHelpers');

const ExternalLinkDefaultTemplateHelpers = {
  get(activity) {
    return {
      externalLink: null,
      isPDFModule: false,
      showLaunchButton: false,
      packageName: activity.getModuleName(),
      descriptionText: HTMLHelpers.lineBreakToBr(HTMLHelpers.htmlEncode(activity.getModuleDescription()))
    };
  }
};

module.exports = ExternalLinkDefaultTemplateHelpers;
