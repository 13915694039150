const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const I18n = require('@common/libs/I18n');

const ResetPasswordPage = require('@common/modules/auth/views/reset/ResetPasswordPage');
const InvalidTokenPage = require('@common/modules/auth/views/reset/InvalidTokenPage');
const RecoveryBaseView = require('@common/modules/auth/views/recovery/RecoveryBaseView');

const ResetPasswordService = require('@common/modules/auth/views/reset/ResetPasswordService');

class ResetPasswordController extends LayoutController {
  viewDefinition() {
    return {
      ViewClass: RecoveryBaseView,
      tenantPropertyPool: this.tenantPropertyPool,
      navChannel: this.navChannel
    };
  }

  initialize(options = {}) {
    this.viewDefinition = this.viewDefinition.bind(this);

    ({
      navChannel: this.navChannel,
      tenantPropertyPool: this.tenantPropertyPool,
      token: this.token,
      flash: this.flash
    } = options);

    this.resetPasswordService = new ResetPasswordService();

    this.isValidToken = false;
  }

  onViewRender() {
    this._validateToken().then((result) => {
      if (result === true) {
        this.resetPasswordService.fetchPubKey();
        this.isValidToken = true;
        this.replaceRegionController('recoveryRegion', this._getRecoveryRegionController());
      } else {
        location.hash = '';
        this.isValidToken = false;
        this.replaceRegionController('recoveryRegion', this._getRecoveryRegionController());
      }
      this.showRegionController('recoveryRegion');
    });
  }

  _getRecoveryRegionController() {
    if (this.isValidToken) {
      return {
        viewDefinition: {
          ViewClass: ResetPasswordPage,
          tenantPropertyPool: this.tenantPropertyPool,
          flash: this.flash
        },
        delegateEvents: {
          'view:click:return': this.onViewClickReturn.bind(this),
          'view:submit': this.onViewPasswordSubmit.bind(this)
        }
      };
    }
    return {
      viewDefinition: {
        ViewClass: InvalidTokenPage
      },
      delegateEvents: {
        'view:click:retry': this.onViewClickRetry.bind(this)
      }
    };

  }

  onViewClickReturn() {
    this._returnToLogin();
  }

  onViewClickRetry() {
    this._returnToLogin();
  }

  onViewPasswordSubmit(controller, view, pwd) {
    const resetPromise = this.resetPasswordService.changePasswordWithEmailToken(pwd, this.token);
    resetPromise.then(() => {
      this.flash.success({
        message: I18n.t('login.passwordReset.reset.success')
      });
      this._returnToLogin();
    }, (error = {}) => {
      switch (error) {
        case ResetPasswordService.PasswordError.INVALIDTOKEN:
        case ResetPasswordService.PasswordError.NOUSER:
          this.isValidToken = false;
          this._resetView();
          break;
        case ResetPasswordService.PasswordError.PASSWORDRECYCLED:
          this.flash.error({
            message: I18n.t('users.password.error.recycled')
          });
          break;
        case ResetPasswordService.PasswordError.INVALIDPASSWORD:
          this.flash.error({
            message: I18n.t('login.passwordReset.reset.failure')
          });
          break;
        default:
          break;
      }
    });
  }

  _returnToLogin() {
    location.hash = '';
    this.navChannel.commands.execute('show:login', { loginPrompt: false });
  }

  _validateToken() {
    return this.resetPasswordService.validateToken(this.token);
  }

  onViewChangeLanguage(controller, view, formEditor) {
    const lang = formEditor.getValue().id;
    I18n.setLocale(lang, {
      always: () => {
        if (this.isDestroyed) {
          return;
        }
        this._resetView();
      }
    });
  }

  _resetView() {
    this.getView().render();
    this.replaceRegionController('recoveryRegion', this._getRecoveryRegionController());
    this.showRegionController('recoveryRegion');
  }
}

module.exports = ResetPasswordController;
