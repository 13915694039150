const Backbone = require('Backbone');
const logging = require('logging');
const $os = require('detectOS');

const Form = require('@common/components/forms/Form');
const AppHelpers = require('@common/libs/helpers/app/AppHelpers');

Form.Editor.FileUploadInput = class FileUploadInput extends Form.Editor {

  getTemplate() {
    return '\
      <input class="hidden" type="file" accept="<%- acceptedTypes %>" id="<%= fileInputId %>" >\
    ';
  }

  ui() {
    return {
      fileInput: `#${ this.fileInputId }`
    };
  }

  events() {
    return {
      'change @ui.fileInput': 'onChangeFileInput',
      'focus @ui.fileInput': 'onFocus',
      'blur @ui.fileInput': 'onBlur'
    };
  }

  preinitialize(options = {}) {
    this.options = options.options || options;

    this.$fileUploadButton = this.getOption('$fileUploadButton');
    this.acceptedTypes = this.getOption('acceptedTypes');
    this.fileFactory = this.getOption('fileFactory');
    this.fileOptions = this.getOption('fileOptions');
    this._error = null;

    this.getValue = this.getValue.bind(this);
    this.hasValue = this.hasValue.bind(this);
    this.getError = this.getError.bind(this);
    this.reset = this.reset.bind(this);

    //we need to define _unique_ ids for the inputs so we
    //can create labels to activate the inputs. This is because IE9
    //doesn't support clicking on inputs programatically.
    //This needs to be done in the constructor so that events are
    //bound correctly to the dynamic IDs.
    const uid = AppHelpers.generateGUID();
    this.fileInputId = `file-input-${ uid }`;
  }

  onRender() {
    this.setupInput(this.$fileUploadButton, this.fileInputId);
  }

  setupInput($inputButton, inputId) {
    // wrap the buttons in labels so the file inputs can respond to  click events
    $inputButton.wrap(`<label for="${ inputId }" class="file-input-label ax-font--no-margin"> </label>`);
    //Firefox before version 23 has a bug where clicking on a label doesn't
    //trigger its associated input. So, we manually send a click.
    if (($os.browser === 'firefox') && ($os.version < 23)) {
      //stash a reference so we can later remove the handler
      this.$inputLabel = $inputButton.parent();
      this.$inputLabel.click(() => {
        this.$(`#${ inputId }`).click();
      });
    }
  }

  templateHelpers() {
    return {
      fileInputId: this.fileInputId,
      acceptedTypes: this.acceptedTypes
    };
  }

  getValue() {
    return this.file;
  }

  hasValue() {
    return (this.file != null);
  }

  setValue(value) {
    this._error = null;
    //Yuck, sometimes the Form sets a model, sometimes the result of toJSON...
    if (value instanceof Backbone.Model) {
      this.stopListening(value, 'error', this.onFileUploadError);
      this.file = value;
      this.listenTo(this.file, 'error', this.onFileUploadError);
    } else if (value != null) { //not a model must be a json value from toJSON or the server
      this.file = this.fileFactory.createFileFromJSON(value);
      this.listenTo(this.file, 'error', this.onFileUploadError);
    } else {
      this.file = null;
    }
  }

  getError() {
    return this._error;
  }

  onChangeFileInput(e) {
    const fileOptions = this.fileOptions;
    try {
      const file = this.fileFactory.createFileFromInput($(e.target), fileOptions);
      if (file != null) {
        this.setValue(file);
        // force recreating the fileInputs, so the file won't unexpectedly wrap an
        // input with no value. This is because Chrome mutates the value of a
        // file-input if you select something, and then click cancel.
        this.render();
      }
      this.onChange();
    } catch (error) {
      this._error = error.message;
      logging.error(this._error);
      this.showErrorFlash(this._error);
      // Since the current value can not properly parse into a file, remove it from the input and rebuild
      // This will prevent re-loading previously selected file
      this.setValue(null);
      this.render();
      this.onChange();
      return false;
    }
    return undefined;
  }

  onFileUploadError() {
    // if the file was unable to upload to the server we should not be
    // showing a thumbnail of it.
    this.reset();
    this.onChange();
  }

  reset() {
    this.setValue(null);
  }

  showErrorFlash() {
    // This function shows error flash message.
    // It shouldn't be implemented in common because flash messages used differently depending on the zone.
  }
};

module.exports = Form.Editor.FileUploadInput;
