const _ = require('underscore');

const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

_.mixin({
  tpl(templateString = '') {
    const templateFunction = _.template(templateString);

    return function(data = {}) {
      return templateFunction(_.extend({}, data, ViewHelpers, UrlHelpers));
    };
  }
});
