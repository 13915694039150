const logging = require('logging');
const Backbone = require('Backbone');
const View = require('./View');
const _ = require('underscore');

class ListView extends View {
  className() {
    let className = 'list-view';
    if (!this.getOption('disableGrid')) {
      className += ' ax-grid ax-grid--align-items-stretch';
    }
    return className;
  }

  attributes() {
    return {
      role: 'list'
    };
  }

  initialize(options = {}) {
    const viewOverrides = _.pick(options, 'EmptyView', 'FirstItemView', 'ModelView', 'HeaderView');
    (
      {
        EmptyView: this.EmptyView = View,
        FirstItemView: this.FirstItemView,
        ModelView: this.ModelView = View,
        HeaderView: this.HeaderView
      } = viewOverrides
    );
    if ((this.collection == null)) {
      logging.warn('ListView was not given a collection, defaulting to Backbone');
      this.collection = new Backbone.Collection();
    }
    ({groupBy: this.groupBy} = options);
    this.listenTo(this.collection, 'add remove reset sort sync', _.debounce(this.renderList, 100));
  }

  render() {
    this.$el.addClass(this.className());
    return this;
  }

  renderList() {
    this.getListEl().empty();
    const groupList = [];
    this.groupListCounter = 0;

    // Set empty view if the collection is empty
    if (this.collection.length === 0) {
      const emptyView = new this.EmptyView();
      this.addItemView(emptyView);
    } else {
      this.collection.each((model, index) => {
        if (this.isHeader(groupList, model)) {
          groupList.push(this.groupBy(model));
          const headerView = new this.HeaderView({model});
          this.addItemView(headerView);

          // Create a new enumerated List for the next grouped items
          this.groupListCounter++;
          this.hasHeaders = true;
          this.getListEl().append(`<ul class="group-list-${ this.groupListCounter }"></ul>`);
        }

        const ItemView = (index === 0) && (this.FirstItemView != null) ? this.FirstItemView : this.ModelView;
        const listItemView = new ItemView({model});
        // Event propagation since the ListView owns the ModelView
        this.listenTo(listItemView, 'all', (eventName, ...args) => {
          args.unshift(eventName, listItemView);
          this.trigger(...args);
        });

        // If collection has header, add to new enumerated list, otherwise add to wrapping element
        if (this.hasHeaders) {
          this.addItemViewToList(listItemView);
        } else {
          this.addItemView(listItemView);
        }

      });
    }
    this.triggerAdjustment();
  }

  isHeader(groupList, model) {
    return this.groupBy != null && (this.HeaderView != null) && !_.contains(groupList, this.groupBy(model));
  }

  addItemViewToList(itemView) {
    this.$(`.group-list-${ this.groupListCounter }`).append(itemView.render().el);
  }

  addItemView(itemView) {
    this.getListEl().append(itemView.render().el);
  }

  getListEl() {
    return this.$el;
  }
}

module.exports = ListView;
