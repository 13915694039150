const {
  TARGETS: {
    DEV
  },
  settings: {
    logLevel,
    logWriter,
    logFormatter,
    logMaxEntries,
    target
  }
} = require('env');
const Logger = require('@common/prerequisites/libs/logging/Logger');

module.exports = new Logger({
  logLevel,
  logWriter,
  logFormatter,
  logMaxEntries,
  development: target === DEV
});
