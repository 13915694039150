// Module
var code = `<div class="clearfix recovery-wrapper">
  <div id="recovery-region">
  </div>
  <div class="login-footer">
    <div>
      <a href="#" class="cancel"><%- t('login.backToLogin') %></a>
    </div>
    <div class="contact"><%= helpContact %></div>
    <form class="language-form">
      <% if (showLanguageSelector) { %>
      <div class="login-language-selector">
        <%= axSelect({
          ariaLabel: t('login.forgotUsername.languageSelector'),
          classNameSelect: 'language-selector text-left qa-language-select',
          data: {
            editor: 'AxonSelect',
            field: 'lang',
            options: 'languages'
          },
          name: 'language',
          noFormEditorDefault: 'true'
        }) %>
      </div>
      <% } %>
    </form>
  </div>
</div>
`;
// Exports
module.exports = code;