const Backbone = require('Backbone');

const User = require('@common/data/models/AuthUser');

class ReferralBountyHunterModel extends Backbone.Model {
  preinitialize() {
    this.target = new User();
    this.hunter = null;

    this.listenTo(this, 'change:target', function(model, attr) {
      this.target.set(attr);
    });

    this.listenTo(this, 'change:hunter', function(model, attr) {
      if (this.hunter == null) {
        this.hunter = new User();
      }
      this.hunter.set(attr);
    });

    this.listenTo(this, 'change:id', function(model, attr) {
      this.id = attr;
    });
  }

  getHunter() {
    return this.hunter;
  }

  getTarget() {
    return this.target;
  }

  toJSON(options = {}) {
    const JSON = super.toJSON(options);

    JSON.target = this.target.toJSON();
    JSON.hunter = this.hunter != null ? this.hunter.toJSON() : undefined;
    return JSON;
  }
}

class ReferralAvailableUserList extends Backbone.Collection {
  get model() {
    return ReferralBountyHunterModel;
  }
}

class ReferralBountyHunterList extends Backbone.Collection {
  get model() {
    return ReferralBountyHunterModel;
  }
}

module.exports = {ReferralBountyHunterList, ReferralAvailableUserList, ReferralBountyHunterModel};
