const _ = require('underscore');

const FALLBACK_SLIDE_NUMBER = 1;

const getSlidesPerViewForBreakpointFn = function({slidesPerViewBreakpoints = [], slideCount}) {

  slidesPerViewBreakpoints = slidesPerViewBreakpoints.slice();

  slidesPerViewBreakpoints.forEach((slidesPerView) => {
    slidesPerView.slidesForSizes.sort((slidesForSizeA, slidesForSizeB) => {
      return slidesForSizeB.minSlideCount - slidesForSizeA.minSlideCount;
    });
  });

  return (breakpoint) => {
    let slidesPerView;

    if (_.isNumber(breakpoint)) {
      slidesPerView = slidesPerViewBreakpoints.find((slidesPerView) => {
        return slidesPerView.breakpoint === breakpoint;
      });
    }

    if (!slidesPerView) {
      slidesPerView = slidesPerViewBreakpoints.find((slidesPerView) => {
        return slidesPerView.isDefault;
      });
    }

    if (!slidesPerView) {
      return FALLBACK_SLIDE_NUMBER;
    }

    const slidesForSize = slidesPerView.slidesForSizes.filter((slidesForSize) => {
      return slidesForSize.minSlideCount <= slideCount;
    })[0];

    return slidesForSize ? slidesForSize.slidesPerView : FALLBACK_SLIDE_NUMBER;
  };
};

const getSlidesPerViewParams = ({slidesPerViewBreakpoints, slideCount}) => {
  const slidesPerViewGenerator = getSlidesPerViewForBreakpointFn({ slidesPerViewBreakpoints, slideCount });

  return slidesPerViewBreakpoints.reduce((memo, config) => {
    if (config.isDefault) {
      memo.slidesPerView = slidesPerViewGenerator();
    } else {
      memo.breakpoints[config.breakpoint] = {
        slidesPerView: slidesPerViewGenerator(config.breakpoint)
      };
    }

    return memo;
  }, {
    breakpoints: {}
  });
};

module.exports = {
  getSlidesPerViewForBreakpointFn,
  getSlidesPerViewParams
};
