const PreTrainingHomePageController = require('@training/apps/home/layout/PreTrainingHomePageController');

const getCreateStartPageView = ({
  availableTiles,
  eventCheckinModel,
  discoveryZoneEnabled,
  recommendedArticlesList,
  startTrainingCallback,
  skipTrainingCallback,
  recommendedArticlesEnabled,
  brandingChannelModel,
  quickLinksList,
  quickLinksOnPaywallEnabled,
  openExternalLinksEnabled
}) => {
  return function ({
    model,
    complete
  }) {
    return PreTrainingHomePageController({
      availableTiles,
      eventCheckinModel,
      discoveryZoneEnabled,
      recommendedArticlesList,
      startTrainingCallback,
      skipTrainingCallback,
      recommendedArticlesEnabled,
      model,
      complete,
      brandingChannelModel,
      quickLinksList,
      quickLinksOnPaywallEnabled,
      openExternalLinksEnabled
    });
  };
};

module.exports = {
  getCreateStartPageView
};
