// Module
var code = `<div id="menu-container">
  <div class="squire-ui">
    <div class="menu" contenteditable="false">
      <div class="group group--image">
        <div id="insert-media" data-action="insert-media" aria-hidden="true">
          <span class="squire-toolbar-icon icon-picture"></span>
          <span class="add-media-text"><%= buttonText %></span>
        </div>
      </div>
    </div>
  </div>
</div>
`;
// Exports
module.exports = code;