const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');

class SearchMessageView extends ItemView {

  initialize(options = {}) {
    ({
      searchPageState: this.pageState
    } = options);

    this._createSyncListeners();
    this._createErrorListeners();

    this.listenTo(this.pageState, 'change:searchCategory', () => {
      this.$el.text(this._getMessage());
    });
  }

  tagName() {
    return 'p';
  }

  className() {
    return 'search-results-message';
  }

  getTemplate() {
    return '<%= message %>';
  }

  templateHelpers() {
    return {
      message: this._getMessage()
    };
  }

  _createSyncListeners() {
    Object.keys(this.pageState.get('results')).forEach((key) => {
      this.listenTo(this.pageState.get('results')[key], 'sync', () => {
        this.render();
        this.$el.attr('tabindex', -1).trigger('focus');
      });
    });
  }

  _createErrorListeners() {
    Object.keys(this.pageState.get('results')).forEach((key) => {
      this.listenTo(this.pageState.get('results')[key], 'error', () => {
        this.$el.hide();
      });
    });
  }

  _getMessage() {
    const results = this.pageState.get('results');
    let isStillLoading = false;

    // If results are still loading, let the user know
    Object.keys(results).forEach((key) => {
      if (results[key].isLoading) {
        isStillLoading = true;

      }
    });
    if (isStillLoading) {
      this.$el.toggleClass('invisible', false);
      return I18n.t('selfDirected.search.searchingMessage');
    }

    if (this.pageState.get('searchString')) {
      this.$el.toggleClass('invisible', false);
      return I18n.t('search.numberOfResultsFor', {
        number: this._getCategoryMessageCount(),
        query: this.pageState.get('searchString')
      });
    }
    this.$el.toggleClass('invisible', true);

  }

  _getCategoryMessageCount() {
    const category = this.pageState.get('searchCategory');
    const results = this.pageState.get('results');

    if (category === SearchCategoryEnum.ALL) {
      return results[SearchCategoryEnum.ARTICLES].state.totalRecords
          + results[SearchCategoryEnum.TOPICS].state.totalRecords;
    }
    return results[category].state.totalRecords;

  }
}

module.exports = SearchMessageView;
