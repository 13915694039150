const { LayoutView } = require('Marionette');

class CommunitiesManagementLayout extends LayoutView {

  className() {
    return 'communities-management-layout parent-height';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/CommunitiesManagementLayout.html');
  }

  regions() {
    return {
      tabsRegion: '.js-communities-management-tabs'
    };
  }
}

module.exports = CommunitiesManagementLayout;
