const FacetList = require('@common/components/discover/collections/FacetList');

class TagBarFacetList extends FacetList {
  getSelectedFacets() {
    return this.where({
      isSelected: true
    });
  }
}

module.exports = TagBarFacetList;
