const { ItemView } = require('Marionette');
const Backbone = require('Backbone');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const KeyCode = require('@common/data/enums/KeyCode');
const I18n = require('@common/libs/I18n');

class PageInsightsSubteamsTableItemView extends ItemView {

  initialize(options = {}) {
    ({
      pageId: this.pageId
    } = options);
  }

  tagName() {
    return 'tr';
  }

  templateHelpers() {
    return {
      linkLabel: I18n.t('discover.insights.teamNavigationAriaLabel', {teamName: this.model.get('name')})
    };
  }

  getTemplate() {
    return `
      <td width="auto" class="code-text-break subteam-table-name">
        <span><%- name %></span>
      </td>
      <td class="right-align subteam-table-impressions">
        <span><%- engagements.impressions %></span>
      </td>
      <td class="right-align subteam-table-engagements">
        <span><%- engagements.views %></span>
      </td>
      <td class="right-align subteam-table-viewers">
        <span><%- engagements.viewers %></span>
      </td>
      <td class="right-align subteam-table-navigation-arrow">
        <button class="icon-angle_right" aria-label="<%- linkLabel %>" title="<%- linkLabel %>"></button>
      </td>
    `;
  }

  events() {
    return {
      click: () => {
        this._onClickSubteamNavigate();
      },
      keypress: (e) => {
        if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
          this._onClickSubteamNavigate();
        }
      }
    };
  }

  _onClickSubteamNavigate() {
    let url = `${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ this.pageId }/insights`;
    if (this.model.get('id')) {
      url = `${ url }/team-${ this.model.get('id') }`;
    }
    Backbone.history.navigate(url, true);
  }

}

module.exports = PageInsightsSubteamsTableItemView;
