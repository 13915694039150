// Module
var code = `<% if (loginBgAvgColor) { %>
  [dir] .login-background-color {
    background-color: <%- loginBgAvgColor %>
  }
<% } %>

<% if (loginBgLowResPath) { %>
  [dir] .login-background-low-res {
    background-image: url('<%= loginBgLowResPath %>');
  }
<% } %>

<% if (loginBgFullResPath) { %>
  [dir] .login-background-full-res {
    background-image: url('<%= loginBgFullResPath %>');
  }
<% } %>

<%if (!loginBgLowResPath && !loginBgFullResPath && !loginBgAvgColor) { %>
  [dir] .login-background-default {
    display: block;
  }
<% } %>
`;
// Exports
module.exports = code;