const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const MediaFactory = require('@common/libs/file/MediaFactory');
const FileFactory = require('@common/libs/file/FileFactory');
const MediaThumbnail = require('@common/components/forms/editors/mediaThumbnail/Form.Editor.MediaThumbnail');
const ImageCropImagePreviewsView = require('@common/components/forms/editors/imageCrop/ImageCropImagePreviewsView');

require('jcrop');
require('@common/components/forms/editors/imageCrop/ImageCropEditor.less');

const ASPECT_RATIO = 1;

class ImageCropEditImageView extends LayoutView {
  get template() {
    return require('@common/components/forms/editors/imageCrop/ImageCropEditImageView.html');
  }

  get className() {
    return 'image-crop';
  }

  ui() {
    return {
      cameraButton: '.js-camera-button'
    };
  }
}

class ImageCropEditImageController extends LayoutController {
  initialize(options = {}) {
    ({
      thumbnailImage: this.thumbnailImage,
      thumbnailSizes: this.thumbnailSizes,
      allowedExtensions: this.allowedExtensions
    } = options);
  }

  viewDefinition() {
    return {
      ViewClass: ImageCropEditImageView,
      regions: {
        mediaThumbnailRegion: '.media-thumbnail-region',
        mediaPreviews: '.media-previews'
      }
    };
  }

  regionControllers() {
    return {
      mediaThumbnailRegion: {
        viewDefinition: () => {
          // For the purpose of cropping, we need to rely on the original file to be displayed
          const file = this.thumbnailImage.getFile();
          const imageMedia = MediaFactory.createMediaFromFile(file);
          return {
            ViewClass: MediaThumbnail,
            $cameraButton: this.getView().ui.cameraButton,
            fileFactory: FileFactory,
            fileOptions: {
              videoAllowed: false,
              allowedExtensions: this.allowedExtensions
            },
            forceRender: false,
            value: imageMedia,
            attributes: {
              maxWidth: 200,
              maxHeight: 200
            }
          };
        },
        delegateEvents: {
          'view:change': (controller, editor) => {
            if (editor.getError() != null) {
              this.triggerMethod('error:media', editor.getError());
              editor.clearError();
            } else if (editor.getValue() == null) {
              this.triggerMethod('image:removed');
            } else {
              const mediaValue = editor.getValue();
              this.thumbnailImage.set({
                ...mediaValue.attributes,
                cropped: undefined
              });
              this.triggerMethod('change:media', editor);
            }
          },
          'view:image:loaded': (controller, editor, image) => {
            // We need to add jcrop here, only once the image has loaded
            this.addJcrop(image);
          },
          'view:image:error': (controller, editor) => {
            this.triggerMethod('error:media', I18n.t('general.upload.file.error'));
            editor.setValue(null);
            this.triggerMethod('image:removed');
          }
        }
      },
      mediaPreviews: {
        viewDefinition: () => {
          return {
            ViewClass: ImageCropImagePreviewsView,
            model: this.thumbnailImage,
            isImageRequired: this.isImageRequired,
            thumbnailSizes: this.thumbnailSizes
          };
        },
        delegateEvents: {
          'view:image:upload': (controller, view, options) => {
            this.triggerMethod('image:upload', options);
          }
        }
      }
    };
  }

  calculateInitialSelect() {
    let maxSelectedH, maxSelectedW, minSelectedH, minSelectedW;

    const cropped = this.thumbnailImage != null ? this.thumbnailImage.get('cropped') : undefined;

    if (cropped) {
      minSelectedW = cropped.x;
      maxSelectedW = cropped.x + cropped.width;
      minSelectedH = cropped.y;
      maxSelectedH = cropped.y + cropped.height;
      return [minSelectedW, minSelectedH, maxSelectedW, maxSelectedH];
    }

    const originalDimensions = this.thumbnailImage.get('originalDimensions');
    const centerW = originalDimensions.width / 2;
    const centerH = originalDimensions.height / 2;

    if (originalDimensions.width > originalDimensions.height) {
      const widthRadius = originalDimensions.width / 4;

      minSelectedW = centerW - widthRadius;
      maxSelectedW = centerW + widthRadius;
      minSelectedH = centerH - widthRadius;
      maxSelectedH = centerH + widthRadius;
    } else {
      const heightRadius = originalDimensions.height / 4;

      minSelectedW = centerW - heightRadius;
      maxSelectedW = centerW + heightRadius;
      minSelectedH = centerH - heightRadius;
      maxSelectedH = centerH + heightRadius;
    }

    return [minSelectedW, minSelectedH, maxSelectedW, maxSelectedH];
  }

  addJcrop(image) {
    if (this.getView().isDestroyed) {
      return;
    }

    this.thumbnailImage.set('originalDimensions', {
      width: image.naturalWidth,
      height: image.naturalHeight
    }, { silent: true });

    const $image = $(image);
    this.jcrop = $.Jcrop($image, {
      bgColor: '', // Transparent Jcrop background
      onChange: this._onCropChange.bind(this),
      onSelect: this._onCropChange.bind(this),
      trueSize: [image.naturalWidth, image.naturalHeight],
      allowSelect: false,
      aspectRatio: ASPECT_RATIO,
      setSelect: this.calculateInitialSelect()
    });

    this.getView().$('.jcrop-holder')
      .attr('aria-hidden', 'true');

    this.thumbnailImage.trigger('change');
  }

  _onCropChange(coordinates) {
    if (!isNaN(coordinates.x) && !isNaN(coordinates.y) && !isNaN(coordinates.w) && !isNaN(coordinates.h)) {
      this.thumbnailImage.set('cropped', {
        x: Math.round(coordinates.x),
        y: Math.round(coordinates.y),
        width: Math.round(coordinates.w),
        height: Math.round(coordinates.h)
      });
    }
  }
}

module.exports = ImageCropEditImageController;
