/**
 * Because the data returned from the server is structured differently for each training session type,
 * the purpose of this file is to provide helper functions for TodaysActivitivesCardControllerDefinitionFactory
 * and MetadataControllerDefinitionFactory that take the data and build one specifically structured object regardless
 * of the training type that will drive the display of the subject, topic, and other metadata in the card and remove
 * all that complexity from the factory itself.
 */

const I18n = require('@common/libs/I18n');
const SessionTrainingType = require('@common/data/enums/SessionTrainingType');
const TitleHeaderDefinitionBuilder = require('@common/components/titleHeader/TitleHeaderDefinitionBuilder');
const { TitleHeaderTypeEnum } = require('@common/components/titleHeader/TitleHeader');

const AssessmentType = require('@common/data/enums/AssessmentType');
const AssessmentTopicOption = require('@common/data/models/assessments/AssessmentTopicOption');
const GuidedLearningObjectiveType = require('@common/data/models/objectives/GuidedLearningObjectiveType');

const isTopicRetake = (nextStartableItem) => {
  return (nextStartableItem != null
    && nextStartableItem.lastRelevantResult != null
    && !nextStartableItem.lastRelevantResult.passed);
};

const isGuidedTopicRetake = (nextStartableItem) => {
  return nextStartableItem != null && nextStartableItem.isRetake;
};

const isCertificationOption = (nextStartableItem) => {
  return (nextStartableItem || {})[AssessmentTopicOption.FIELDS.FOR_ASSESSMENT_TYPE] === AssessmentType.CertificationTraining;
};

const isTask = (nextStartableItem) => {
  return (nextStartableItem || {}).type === GuidedLearningObjectiveType.TASK_OBJECTIVE;
};

const isEvent = (nextStartableItem) => {
  return (nextStartableItem || {}).type === GuidedLearningObjectiveType.EVENT_OBJECTIVE;
};

const isEvaluation = (nextStartableItem) => {
  return (nextStartableItem || {}).type === GuidedLearningObjectiveType.EVALUATION_OBJECTIVE;
};

/**
 * LOWER CARD REGION TITLE HELPERS
 */

const getGuidedLearningLowerTitleInfo = (nextStartableItem) => {
  const lowerTitleInfo = {};

  if (isTask(nextStartableItem)) {
    lowerTitleInfo.title = nextStartableItem.task ? nextStartableItem.task.name : '';
    lowerTitleInfo.pretext = nextStartableItem.topic ? nextStartableItem.topic.name : '';
  } else if (isEvent(nextStartableItem)) {
    lowerTitleInfo.title = nextStartableItem.event ? nextStartableItem.event.name : '';
    lowerTitleInfo.pretext = I18n.t('start.assessmentType.event');
  } else if (isEvaluation(nextStartableItem)) {
    lowerTitleInfo.title = nextStartableItem.evaluation ? nextStartableItem.evaluation.name : '';
    lowerTitleInfo.pretext = I18n.t('start.assessmentType.evaluation');
  } else {
    lowerTitleInfo.title = nextStartableItem.topic ? nextStartableItem.topic.name : '';
    lowerTitleInfo.pretext = nextStartableItem.subject ? nextStartableItem.subject.name : '';
  }

  return lowerTitleInfo;
};

const _getGuidedLearningLowerTitleDefinition = (nextStartableItem) => {
  const lowerTitleInfo = getGuidedLearningLowerTitleInfo(nextStartableItem);

  return new TitleHeaderDefinitionBuilder({
    title: lowerTitleInfo.title,
    pretext: lowerTitleInfo.pretext,
    titleType: TitleHeaderTypeEnum.PRETEXT_TITLE,
    titleClass: 'ax-font--no-margin pretext-header__lower-title',
    titleTag: 'h4'
  }).build();
};

const _getCommonLowerTitleDefinition = (nextStartableItem) => {
  return new TitleHeaderDefinitionBuilder({
    title: nextStartableItem.topic ? nextStartableItem.topic.name : '',
    pretext: nextStartableItem.subject ? nextStartableItem.subject.name : '',
    titleType: TitleHeaderTypeEnum.PRETEXT_TITLE,
    titleClass: 'ax-font--no-margin pretext-header__lower-title',
    titleTag: 'h4'
  }).build();
};

const _getLowerTitleDefinitionForFormalExam = (nextStartableItem) => {
  if (nextStartableItem.program != null) {
    return new TitleHeaderDefinitionBuilder({
      title: nextStartableItem.program.name,
      titleType: TitleHeaderTypeEnum.BASIC_TITLE,
      titleClass: 'ax-font--no-margin pretext-header__lower-title',
      titleTag: 'h4'
    }).build();
  }
  return undefined;
};

const titleDefinitionForSessionTypeMap = {
  [SessionTrainingType.GuidedLearningTraining]: _getGuidedLearningLowerTitleDefinition,
  [SessionTrainingType.CertificationTraining]: _getCommonLowerTitleDefinition,
  [SessionTrainingType.IntroductoryTraining]: _getCommonLowerTitleDefinition,
  [SessionTrainingType.RefresherTraining]: _getCommonLowerTitleDefinition,
  [SessionTrainingType.FormalExamTraining]: _getLowerTitleDefinitionForFormalExam,
  [SessionTrainingType.DailyTraining]: () => {}
};

const getLowerTitleDefinitionForSessionType = (sessionType, nextStartableItem) => {
  return titleDefinitionForSessionTypeMap[sessionType](nextStartableItem);
};


/**
 * METADATA HELPERS
 */
const _getModuleAndVideoStrings = (videoAndModuleStats) => {
  let moduleString, videoString;

  if (videoAndModuleStats.moduleCount > 0) {
    if (videoAndModuleStats.moduleCount === 1) {
      moduleString = { strKey: 'assessments.item.content.training.scorm' };
    } else {
      moduleString = {
        strKey: 'assessments.item.content.training.module.plural',
        value: videoAndModuleStats.moduleCount
      };
    }
  }

  if (videoAndModuleStats.videoCount > 0) {
    if (videoAndModuleStats.videoCount === 1) {
      videoString = { strKey: 'assessments.item.content.training.VideoFile' };
    } else {
      videoString = {
        strKey: 'assessments.item.content.training.video.plural',
        value: videoAndModuleStats.videoCount
      };
    }
  }

  return {
    moduleString,
    videoString
  };
};

const _getCommonMetaValues = (nextStartableItem, videoAndModuleStats) => {
  const moduleProperties = _getModuleAndVideoStrings(videoAndModuleStats);
  return {
    rewardPoints: nextStartableItem.pointsForPass,
    modules: moduleProperties.moduleString,
    videos: moduleProperties.videoString,
    questions: nextStartableItem.questionCount,
    events: (nextStartableItem.event || {}).numScheduledLearningEventsAvailable || 0
  };
};

const _getMetaValuesForDailyTraining = (nextStartableItem, videoAndModuleStats) => {
  const moduleVideoData = _getModuleAndVideoStrings(videoAndModuleStats);
  return {
    rewardPoints: nextStartableItem.eligiblePoints,
    modules: moduleVideoData.moduleString,
    videos: moduleVideoData.videoString,
    questions: nextStartableItem.questionCount
  };
};

// Can have both modules & videos in 1 DailyTraining session so we use _getModuleAndVideoStrings()
const _getMetaValuesForFormalExam = (nextStartableItem, videoAndModuleStats) => {
  const moduleVideoData = _getModuleAndVideoStrings(videoAndModuleStats);
  return {
    rewardPoints: nextStartableItem.totalPointsEarnable,
    modules: moduleVideoData.moduleString,
    videos: moduleVideoData.videoString,
    questions: nextStartableItem.questionCount
  };
};

const metadataForSessionTypeMap = {
  [SessionTrainingType.DailyTraining]: _getMetaValuesForDailyTraining,
  [SessionTrainingType.CertificationTraining]: _getCommonMetaValues,
  [SessionTrainingType.GuidedLearningTraining]: _getCommonMetaValues,
  [SessionTrainingType.IntroductoryTraining]: _getCommonMetaValues,
  [SessionTrainingType.RefresherTraining]: _getCommonMetaValues,
  [SessionTrainingType.FormalExamTraining]: _getMetaValuesForFormalExam
};

const getMetadataProperties = (sessionType, nextStartableItem, videoAndModuleStats) => {
  return metadataForSessionTypeMap[sessionType](nextStartableItem, videoAndModuleStats);
};

module.exports = {
  isTopicRetake,
  isCertificationOption,
  isGuidedTopicRetake,
  isTask,
  isEvent,
  isEvaluation,
  getLowerTitleDefinitionForSessionType,
  getMetadataProperties,
  getGuidedLearningLowerTitleInfo
};
