// Module
var code = `<div class="game-scores-details">
<% if (_.isUndefined(gameDetails.allTimeHigh)) { %>
  <p class="nooneplayed">
    <span><%- t('gamescores.nooneplayed') %></span>
  </p>
<% } else { %>
  <div class="highscores clearfix">
    <div class="yourhighscore" role="heading" aria-level="2">
    <% if (_.isUndefined(gameDetails.userScore)) { %>
      <div class="notplayed">
        <div class="score ax-font ax-font__display ax-font--no-margin">--</div>
        <div class="scorelabel ax-font--secondary"><%- t('gamescores.youhavenotplayed') %></div>
      </div>
    <% } else { %>
      <div class="score ax-font ax-font__display ax-font--no-margin"><%- numberWithCommas(gameDetails.userScore.highScore) %></div>
      <div class="scorelabel ax-font--secondary"><%- t('gamescores.yourbest') %></div>
    <% } %>
    </div>
    <div class="alltimehighscore" role="heading" aria-level="3">
      <div class="scorecontainer">
        <div class="score ax-font ax-font__display ax-font--no-margin"><%- numberWithCommas(gameDetails.allTimeHigh.highScore) %></div>
        <div class="ax-font--secondary">
          <%- t('gamescores.alltime') %>
          <div class="scoreinfocontainer text-ellipsis">
            <span class="name"><%- gameDetails.allTimeHigh.user.salutationName %></span>
            <span><%- gameDetails.allTimeHigh.location.name %></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="gameboard users">
    <h2 id="top-performers-individual-header"><%- t('stats.people', {'teamName': teamName, 'timeFrame': timeFrame}) %></h2>
    <table>
      <thead>
        <tr>
          <th class="rank center"><%- t('gamescores.rank') %></th>
          <th class="user text-ellipsis-softwrap"><span class="off-screen"><%- t('gamescores.user') %></span></th>
          <th class="score center"><%- t('gamescores.score') %></th>
        </tr>
      </thead>
      <tbody>
      <% if (gameDetails.highScores.length < 1) { %>
        <tr>
          <td colspan="3" class="nooneplayed"><%- t('gamescores.nooneplayedinlast7days') %></td>
        </tr>
      <% } else { %>
        <%
          var previousRank = 0;
          for (var i = 0; i < gameDetails.highScores.length; i++) {
            var item = gameDetails.highScores[i];
            var youClass = item.current ? 'you' : '';
            var separatorClass = previousRank != item.rank ||  item.rank == -1 ? 'separator' : '';
            previousRank = item.rank + 1;
        %>
        <tr class="<%- youClass + ' ' + separatorClass %>">
          <td class="rank center cell-<%- item.rank + 1 %>">
            <% if (item.rank != -1) { %>
              <div aria-hidden="true" role="presentation" class="position-<%- item.rank + 1 %>"></div>
              <span><%- item.rank + 1 %></span>
            <% } %>
            </td>
          <td class="user text-ellipsis"><%- item.name %>
            <% if (item.current) { %>
            (<%- t('stats.you') %>)
            <% } %>
          </td>
          <td class="score center" ><%- numberWithCommas(item.leadScore) %></td>
        </tr>
        <% } %>
      <% } %>
      </tbody>
    </table>
  </div>
<% } %>
</div>
`;
// Exports
module.exports = code;