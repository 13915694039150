const Backbone = require('Backbone');
const _ = require('underscore');

const I18n = require('@common/libs/I18n');
const UIKit = require('@training/widgets/UIKit');
const dateHelpers = require('@common/libs/dateHelpers');
const KeyCode = require('@common/data/enums/KeyCode');

const LearningEventDisplayHelper = require('@training/apps/training/views/LearningEventDisplayHelper');

require('@common/libs/behaviors/card/Card');
const {
  INTERACTIVE,
  MEDIUM_PADDING
} = require('@common/libs/behaviors/card/CardModifierClasses');

class LearningEventView extends UIKit.View {
  get template() {
    return _.tpl(require('@training/apps/training/templates/_learning_event.html'));
  }

  className() {
    return 'event-item learningevent-item';
  }

  tagName() {
    return 'li';
  }

  behaviors() {
    return {
      Card: {
        target: '.learning-event-item',
        modifierClasses: [INTERACTIVE, MEDIUM_PADDING]
      }
    };
  }

  events() {
    return {
      click: 'onClickViewEvent',
      keydown: 'onKeyDown'
    };
  }

  attributes() {
    this.startDate = this.model.get('scheduledEvent').startDate;
    const localizableName = this.model.get('localizableName');
    const currentLanguage = this.model.get('currentUserLanguage');
    this.itemTitle = localizableName.getValueForLanguage(currentLanguage);

    return {
      role: 'button',
      tabindex: '0',
      'aria-label': this._generateAriaLabel(),
      'aria-posinset': this.model.get('posInSet'),
      'aria-setsize': this.model.get('listLength'),
      id: 'event-row-' + this.model.get('posInSet')
    };
  }

  render() {
    const isPastEvent = this.model.isPastEvent();
    const itemStartTime = dateHelpers.convertDateFormatTime(this.startDate, 'epoch');
    const itemEndTime = dateHelpers.convertDateFormatTime(this.model.get('scheduledEvent').endDate, 'epoch');
    const itemLanguage = I18n.languageNameFromCurrentLocale(this.model.get('scheduledEvent').language);
    const dayOfWeek = dateHelpers.convertDateFormatCustom(this.startDate, 'epoch', 'ddd');
    const dayOfMonth = dateHelpers.convertDateFormatCustom(this.startDate, 'epoch', 'MMM D');

    let statusInfo;
    if (isPastEvent) {
      statusInfo = LearningEventDisplayHelper.getAttendedDisplayInfo(this.model);
    } else {
      statusInfo = LearningEventDisplayHelper.getLearningEventStatusDisplayInfo(this.model);
    }

    this.$el.html(this.template({
      itemTitle: this.itemTitle,
      itemStartTime,
      itemEndTime,
      itemLanguage,
      itemLocation: this.model.get('scheduledEvent').location,
      itemStatus: statusInfo.text,
      itemStatusClass: statusInfo.class,
      itemDayOfWeek: dayOfWeek,
      itemDayOfMonth: dayOfMonth
    }));

    return this;
  }

  onClickViewEvent() {
    this.viewEvent();
  }

  onKeyDown(e) {
    if (e.which === KeyCode.ENTER || e.which === KeyCode.SPACE) {
      this.viewEvent();
    }
  }

  viewEvent() {
    const enrollment = this.model.get('enrollment') || {};
    const enrollmentId = enrollment.id;
    const scheduledEventId = this.model.get('scheduledEvent').id;
    const eventId = this.model.get('scheduledEvent').learningEvent.id;
    const hashFragment = _.compact([eventId, scheduledEventId, enrollmentId]).join('-');

    Backbone.history.navigate(`#hub/eventDetails/${ hashFragment }`, { trigger: true });
  }

  _generateAriaLabel() {
    const ariaDateString = dateHelpers.convertDateFormatCustom(this.startDate, 'epoch', 'dddd MMMM D');
    const itemStartTime = dateHelpers.convertDateFormatTime(this.startDate, 'epoch');
    const itemEndTime = dateHelpers.convertDateFormatTime(this.model.get('scheduledEvent').endDate, 'epoch');
    const itemLanguage = I18n.languageNameFromCurrentLocale(this.model.get('scheduledEvent').language);
    const itemLocation = this.model.get('scheduledEvent').location;

    let ariaLabel = `${ ariaDateString } - ${ this.itemTitle }.  ${ I18n.t('accessibility.clickPrompt') }`;
    if (itemStartTime != null && itemEndTime != null && itemLanguage != null && itemLocation != null) {
      ariaLabel = `${ ariaDateString } - ${ this.itemTitle } - ${ itemStartTime } - ${ itemEndTime } - \
                   ${ itemLanguage } - ${ itemLocation }.  ${ I18n.t('accessibility.clickPrompt') }`;
    }

    return ariaLabel;
  }
}

module.exports = LearningEventView;
