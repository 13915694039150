import { type QuickLinksCollection } from '@training/apps/home/quickLinks/types/QuickLinksCollection';

import { ReactControllerDefinitionFactory } from '@common/modules/react';
import QuickLinksCardSkeletonDefinition from '@training/apps/home/quickLinks/QuickLinksCardSkeletonDefinitionFactory';

export default function QuickLinksDefinitionFactory(
  quickLinksList: QuickLinksCollection,
  location: string
) {
  return () => {
    return ReactControllerDefinitionFactory({
      component: import('@training/apps/home/quickLinks/QuickLinksCardWrapper'),
      loadingControllerDefinition: QuickLinksCardSkeletonDefinition(),
      props: {
        quickLinksList,
        location
      }
    });
  };
}
