const {
  Model,
  Collection,
  VirtualCollection
} = require('Backbone');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const PageStatus = require('@common/components/discover/enums/PageStatus');
const PageableCollection = require('@common/components/pageableList/PageableCollection');
const PagePreviewSearchResult = require('@common/components/discover/models/PagePreviewSearchResult');
const TagBarFacetList = require('@common/components/discover/collections/TagBarFacetList');
const _ = require('underscore');

const SUB_CATEGORY_APIS = {
  [SearchSubCategoryEnum.FAVORITES]: '/pages/favorites',
  [SearchSubCategoryEnum.PENDING]: '/pages/requiresAttention'
};

class GroupedFacet extends Model {
  getSelectedTagIds() {
    const urlTags = this.get('tags').where({isSelected: true});
    const selectedIds = [];
    urlTags.forEach((selectedTag) => {
      selectedIds.push(selectedTag.get('formId'));
    });
    return selectedIds;
  }
}

class GroupedFacetList extends Collection {
  get model() {
    return GroupedFacet;
  }

  get comparator() {
    return 'name';
  }
}

class ArticleSearchCollection extends PageableCollection {
  apiEndpoint() {
    let endpoint = '/pages/search';

    if (this._isBrowsingCommunity()) {
      endpoint = `/pages/community/${ this.extraParams.communityId }`;
    }

    if (this.subCategory != null && this.subCategory !== SearchSubCategoryEnum.COMMUNITY) {
      endpoint = SUB_CATEGORY_APIS[this.subCategory];
    }

    return endpoint;
  }

  get model() {
    if (this.subCategory != null && this.subCategory === SearchSubCategoryEnum.PENDING) {
      return PagePreviewSearchResult;
    }

    return Model;
  }

  initialize(models, options = {}) {
    this.facetList = new TagBarFacetList([]);
    this.groupedFacetLists = new GroupedFacetList();

    this.subCategory = options.subCategory;

    this.urlTags = [];

    this.pendingFilter = [PageStatus.QUARANTINE, PageStatus.REVIEW, PageStatus.SCHEDULED, PageStatus.EXPIRED]

    this._trySelectTagsFromUrl = this._trySelectTagsFromUrl.bind(this);

    super.initialize(models, options);
  }

  search(searchQuery, urlTags = [], pageNum, options = {}) {
    this.searchQuery = searchQuery || '';

    this.urlTags = urlTags;

    return this.getPage(pageNum || 0, options);
  }

  fetch(options = {}) {
    let data = {};

    if (this.subCategory === SearchSubCategoryEnum.PENDING) {
      this.extraParams.filter = this.pendingFilter;
      options.traditional = true;
    } else {
      this.extraParams.filter = 'no_posts';
    }

    if (this._isBrowsingCommunity() || this.subCategory === SearchSubCategoryEnum.FAVORITES || this.subCategory === SearchSubCategoryEnum.PENDING) {
      options.type = 'GET';
    } else {
      options.type = 'POST';
      data = {
        query: this.searchQuery,
        tagsFilter: this._getFetchTags()
      };
    }
    this.extraParams.returnTags = true;

    return super.fetch($.extend(true, { data }, options));
  }

  parse(res) {
    const {
      parsedTags,
      groupNamesUnique
    } = this._getParsedTagResults(res);

    this._tagGroupNames = _.keys(groupNamesUnique);

    this._trySelectTagsFromUrl(parsedTags);

    this._sortTags(parsedTags);

    res.facets.tags = parsedTags;

    this._resetFacetLists(res);

    return super.parse(res);
  }

  _getParsedTagResults(res) {
    const parsedTags = [];
    const groupNamesUnique = {};

    if (res.facets.tags && res.facets.tags.length) {
      res.facets.tags.forEach((tag) => {
        if (tag.name && tag.tags) {
          tag.tags.forEach((tagGroupedTag) => {
            tagGroupedTag.type = 'tag';// Needed for downstream model type selection
            tagGroupedTag.tagGroupName = tag.name;
            parsedTags.push(tagGroupedTag);
          });
          groupNamesUnique[tag.name] = tag.name;
        } else {
          if ( tag.tagGroupName ) {
            groupNamesUnique[tag.tagGroupName] = tag.tagGroupName;
          }
          parsedTags.push(tag);
        }
      });
    }

    return {
      parsedTags,
      groupNamesUnique
    };
  }

  _trySelectTagsFromUrl(parsedTags) {
    this.urlTags.forEach((urlTag) => {
      const tagName = urlTag.get('tag');

      for (let i = 0; i < parsedTags.length; i++) {
        if (parsedTags[i].tag === tagName) {
          parsedTags[i].isSelected = true;
          break;
        }
      }
    });
  }

  _sortTags(parsedTags) {
    parsedTags.sort((a, b) => {
      if (a.isSelected && !b.isSelected) {
        return -1;
      } else if (!a.isSelected && b.isSelected) {
        return 1;
      } else if (a.count > b.count) {
        return -1;
      } else if (a.count < b.count) {
        return 1;
      }

      const nameA = a.tag.toUpperCase();
      const nameB = b.tag.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  getFacetList() {
    return this.facetList;
  }

  getGroupedFacetLists() {
    return this.groupedFacetLists;
  }

  _resetFacetLists({facets = {}} = {}) {
    this.facetList.reset([].concat(facets.tags));

    const groupedFacets = [];
    this._tagGroupNames.forEach((tagGroupName) => {
      groupedFacets.push({
        name: tagGroupName,
        tags: new VirtualCollection(this.facetList, {
          filter: (facet) => {
            return facet.get('tagGroupName') === tagGroupName;
          }
        })
      });
    });

    this.groupedFacetLists.reset(groupedFacets);
  }

  _isBrowsingCommunity() {
    const isInACommunity = this.extraParams && this.extraParams.communityId != null;
    const hasNoTags = _.isEmpty(this.urlTags);

    return isInACommunity && !this.searchQuery && hasNoTags;
  }

  _getFetchTags() {
    const tags = [];
    const selectedFacets = this.facetList.getSelectedFacets();

    selectedFacets.forEach((tag) => {
      tags.push({
        tag: tag.get('tag'),
        groupName: tag.get('tagGroupName')
      });
    });

    if (this.urlTags && this.urlTags.length > 0) {

      this.urlTags.forEach((tag) => {
        const tagName = tag.get('tag');

        if (!selectedFacets.some((tagItem) => {
          return tagItem.get('tag') === tagName;
        })) {
          tags.push({
            tag: tagName,
            groupName: tag.get('tagGroupName') || tag.get('initialUrlGroupId')
          });
        }
      });
    }

    const uniqueTagNames = {};
    const uniqueTags = [];
    const uniqueTagsGrouped = {};

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      const tagName = tag.tag;
      const groupName = tag.groupName || tag.tag.initialUrlGroupId;

      if (!uniqueTagNames[tagName]) {
        uniqueTagNames[tagName] = true;

        if (groupName) {
          uniqueTagsGrouped[groupName] = uniqueTagsGrouped[groupName] || [];
          uniqueTagsGrouped[groupName].push(tag.tag);
        } else {
          // tags inside arrays are "OR'd" together by API, but an OR of 1 is the equivalent of not or-ing at all
          uniqueTags.push([tag.tag]);
        }
      }
    }

    for (const groupName in uniqueTagsGrouped) { // tags inside arrays are "OR'd" together by API
      if (Object.prototype.hasOwnProperty.call(uniqueTagsGrouped, groupName)) {
        uniqueTags.push(uniqueTagsGrouped[groupName]);
      }
    }

    return _.compact(uniqueTags);
  }
}

module.exports = ArticleSearchCollection;
