const AbstractAssessmentInitiatorController = require('@training/apps/training/controllers/assessments/AbstractAssessmentInitiatorController');
const SelfDirectedAssessmentInitiatorContext = require('@training/apps/training/controllers/assessments/SelfDirectedAssessmentInitiatorContext');
const NullStartableAssessmentProvider = require('@training/apps/training/controllers/assessments/providers/NullStartableAssessmentProvider');
const ProcessSequenceMessageCode = require('@training/apps/training/controllers/ProcessSequenceMessageCode');

class SelfDirectedAssessmentInitiatorController extends AbstractAssessmentInitiatorController {
  constructor(parentProcessor, options = {}) {
    super(parentProcessor, options);

    ({
      assessmentFactory: this.assessmentFactory
    } = options);

    this.pageContext = null;
  }

  loadConfigurationAsync() {
    return this.contextualConfigurationProvider.getConfigurationAsync().then((configuration) => {
      this.assessmentType = configuration.assessmentType;
      this.pageContext = configuration.pageContext;
    });
  }

  createAndProcessAssessment(assessmentTopicOption) {
    return this.loadConfigurationAsync().then(() => {
      return super.createAndProcessAssessment(assessmentTopicOption);
    });
  }

  createAssessment(assessmentTopicOption) {
    const assessmentType = assessmentTopicOption.getAssessmentType() || this.assessmentType;

    this.context = this.createInitiatorContext({
      assessmentType,
      pageContext: this.pageContext
    });

    return this.assessmentFactory.createForAssessmentTypeAsync(
      assessmentType,
      this.context,
      assessmentTopicOption.toAssessmentRequestJson()
    );
  }

  createInitiatorContext(json) {
    const {
      assessmentType,
      pageContext,
      timestamp
    } = json;

    return new SelfDirectedAssessmentInitiatorContext(assessmentType, pageContext, timestamp);
  }

  setConfigurationProvider(provider) {
    this.contextualConfigurationProvider = provider;
  }

  getDiscriminator() {
    return SelfDirectedAssessmentInitiatorContext.SELF_DIRECTED_CONTEXT_TYPE;
  }

  processSequenceFlow() {
    if (this.context != null && this.context.pageContext != null) {
      this.parentProcessor.processSequenceFlow(this.context.pageContext);
      this.context = null;
      return Promise.reject(Promise.OperationalError(ProcessSequenceMessageCode.HANDLING));
    }

    return Promise.resolve(ProcessSequenceMessageCode.NOTHING_TO_DO);
  }

  processAssessment(assessment, context) {
    if (this.context == null) {
      this.context = context;
    }

    const assessmentController = this.getAssessmentProcessor({
      assessment,
      nextItemProvider: this.getNextItemProvider(context)
    });

    return assessmentController.processSequenceFlow();
  }

  getNextItemProvider() {
    return new NullStartableAssessmentProvider();
  }
}

module.exports = SelfDirectedAssessmentInitiatorController;
