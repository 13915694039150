const PageableCollection = require('@common/components/pageableList/PageableCollection');

const LIST_SIZE = 40;
const PENDING_STATUSES = ['PENDING', 'PENDING_REVERT'];
const TYPE_GROUP = 'LOCATION_COMMUNITY_IMPORT_GROUP';

class ImportHistoryCollection extends PageableCollection {
  initialize(models, options = {}) {
    Object.assign(options, {
      fetchType: 'GET'
    }, options);

    this.extraParams = {
      typeGroup: TYPE_GROUP
    };

    this.hasProcessingFiles = this.hasProcessingFiles.bind(this);

    super.initialize(models, options);
    this.state.pageSize = LIST_SIZE;
  }

  apiEndpoint() {
    return '/bulkimport/list';
  }

  hasProcessingFiles() {
    return this.any((model) => {
      return PENDING_STATUSES.includes(model.get('status'));
    });
  }

  getCurrentPage() {
    return this.state.currentPage;
  }

  parse(res) {
    const results = super.parse(res);

    this.state.totalRecords = res.count;
    this.state.totalPages = Math.ceil(res.count / this.state.pageSize);
    this.state.lastPage = this.state.totalPages - 1;

    return results;
  }
}

module.exports = ImportHistoryCollection;
