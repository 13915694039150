const _ = require('underscore');

const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');
const Sync = require('@common/libs/Sync');
const dateHelpers = require('@common/libs/dateHelpers');

const LearningEventView = require('@training/apps/training/views/LearningEventView');
const LearningEventListHeaderView = require('@training/apps/training/views/LearningEventListHeaderView');
const EmptyLearningEventListView = require('@training/apps/training/views/EmptyLearningEventListView');
const InfiniteScrollViewMixin = require('@common/mixins/InfiniteScrollViewMixin');

const Cocktail = require('backbone.cocktail');

class LearningEventListView extends UIKit.View {
  p = 0;

  rpp = 10;

  noMorePages = false;

  initialize(options = {}) {
    ({
      eventList: this.eventList,
      $el: this.$el,
      isActive: this.isActive
    } = options);
  }

  render() {
    this._renderList();
    this._renderSearchBox();

    return this;
  }

  viewDidAppear() {
    this.getNextEvents();
    this.$scrollContainer = $('.adjustable-container.scrollable');
  }

  _renderSearchBox() {
    this.searchBox = new UIKit.Autocomplete({
      el: this.$('.event-search'),
      minLength: 1
    });

    this.listenTo(this.searchBox, 'autocomplete search clearSearch', this.onSearch);
  }

  _renderList() {
    const list = new UIKit.ListView({
      collection: this.eventList,
      el: this.$('.event-list-container'),
      EmptyView: EmptyLearningEventListView,
      FirstItemView: LearningEventView,
      ModelView: LearningEventView,
      HeaderView: LearningEventListHeaderView,
      groupBy(model) {
        return dateHelpers.convertDateFormatCustom(model.get('scheduledEvent').startDate, 'epoch', 'MMMM, YYYY');
      }
    });

    // Focus the previous active row before the ListView was re-rendered
    this.listenTo(list, 'render', (eventRowView) => {
      if (this.activeRow) {
        const index = parseInt(this.activeRow, 10);

        // Offset the target row to focus by one so we can guarentee that row is attached in the DOM
        if ((index + 1) === parseInt(eventRowView.$el.attr('aria-posinset'), 10)) {
          this.$('#event-row-' + index).trigger('focus');
        }
      }
    });
  }

  onSearch(queryParam) {
    const dataOptions = {};
    let query = queryParam;

    // If query is null on search, this means a search has been cleared. Server needs this data object sent to display all Events results
    if (query == null) {
      query = '';
      dataOptions.data = {
        page: this.p = 0,
        recordsPerPage: this.rpp
      };
    }

    this.noMorePages = false;

    return this.eventList.search(query, dataOptions).then(() => {
      this.setAriaProperties();
      if (dataOptions.data) {
        this.p += 1;
      }

      const labelID = `#number-of-${ this.$el.attr('data-tab') }`;

      if (query !== '') {
        this.$(labelID).text(I18n.t('search.numberOfResultsFor', {
          number: this.eventList.length,
          query
        }));

        this.$('[type=search]').attr('aria-labelledby', labelID);
      } else {
        this.$(labelID).text('');
        this.$('[type=search]').removeAttr('aria-labelledby')
          .trigger('focus');
      }
    });
  }

  onLoadNext() {
    this.getNextEvents();
  }

  onClose() {
    this.eventList.abortXHR(Sync.Method.READ);
    super.onClose();
  }

  getNextEvents() {
    if (this.isLoadingPage || this.noMorePages || !this.isActive) {
      return;
    }

    this.isLoadingPage = true;

    const dataOptions = {
      p: this.p,
      rpp: this.rpp
    };

    const shouldRemove = this.p === 0;

    const fetchOptions = {
      add: true,
      update: true,
      remove: shouldRemove,
      data: dataOptions,
      complete: () => {
        const activeElement = this.$(document.activeElement);
        if (activeElement.hasClass('event-item')) {
          this.activeRow = activeElement.attr('aria-posinset');
        }

        this.setAriaProperties();
        this.isLoadingPage = false;
        this.p += 1;
        return this.p;
      },
      success: (collection, response) => {
        return this.fetchSuccess(response);
      }
    };

    this.eventList.fetch(fetchOptions);
  }

  fetchSuccess(response) {
    if (response.results.length < this.rpp) {
      this.noMorePages = true;
      return this.noMorePages;
    }
    return false;
  }

  // Set values needed for aria-attributes in row templates
  setAriaProperties() {
    _.each(this.eventList.models, (model, index) => {
      model.set('listLength', this.eventList.length);
      model.set('posInSet', index + 1);
    });
  }
}

Cocktail.mixin(LearningEventListView, InfiniteScrollViewMixin);
module.exports = LearningEventListView;
