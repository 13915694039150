const _ = require('underscore');

const AssessmentInitatorContext = require('@common/data/models/assessments/AssessmentInitatorContext');

class DeepLinkingAssessmentInitiatorContext extends AssessmentInitatorContext {
  constructor(topicId, level, programId, showSplashPage) {
    super();

    this.topicId = topicId;
    this.level = level;
    this.programId = programId;
    this.showSplashPage = showSplashPage;

    this.validateState();
  }

  validateState() {
    if (!_.isNumber(this.topicId)) {
      throw new Error('You must pass in a valid Topic ID.');
    }

    if (!_.isNumber(this.level)) {
      throw new Error('You must pass in a valid Topic level.');
    }
  }

  getProgramId() {
    return this.programId;
  }

  static getType() {
    return 'DeepLinkingContext';
  }
}

module.exports = DeepLinkingAssessmentInitiatorContext;
