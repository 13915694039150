const MenuDropdown = require('@common/components/dropdownButton/MenuDropdown');
const AxSpacingEnum = require('@common/data/enums/AxSpacingEnum');

class BundledLanguageChooserDropdown extends MenuDropdown {

  getTemplate() {
    return require('@common/components/bundledLanguageChooser/BundledLanguageChooserTemplate.html');
  }

  getVerticalSpacingBetweenButtonAndPopup() {
    return AxSpacingEnum.ZERO;
  }

}

module.exports = BundledLanguageChooserDropdown;
