const Marionette = require('Marionette');

const dateHelpers = require('@common/libs/dateHelpers');
const I18n = require('@common/libs/I18n');

class LearningEventDetailSessionView extends Marionette.ItemView {
  getTemplate() {
    return require('@training/apps/training/templates/_learning_event_detail_session.html');
  }

  className() {
    return 'learning-events-details-session';
  }

  templateHelpers() {
    return {
      sessionDate: dateHelpers.convertDateFormatLongDateWithStrings(this.model.get('startDate'), 'epoch'),
      sessionStartTime: dateHelpers.convertDateFormatTime(this.model.get('startDate'), 'epoch'),
      sessionEndTime: dateHelpers.convertDateFormatTime(this.model.get('endDate'), 'epoch'),
      sessionLanguage: I18n.languageNameFromCurrentLocale(this.model.get('scheduledLearningEvent').language),
      sessionLocation: this.model.get('scheduledLearningEvent').location,
      sessionRoom: this.model.get('room'),
      sessionTitle: this.model.get('title')
    };
  }
}

module.exports = LearningEventDetailSessionView;
