const logging = require('logging');

const {
  compact,
  isFunction
} = require('underscore');
const { Behavior } = require('Marionette');

const Behaviors = require('@common/libs/behaviors/Behaviors');

const CardModifierClasses = require('@common/libs/behaviors/card/CardModifierClasses');
const CardBehaviorConfigBuilder = require('@common/libs/behaviors/card/CardBehaviorConfigBuilder');

const BASE_CLASS = 'card';

/*
  Card Behavior

  Can be attached to any view to style add classes and styles targetted elements as a Card.

  Options:
    - `target`: jquery selector for the elements that should be targeted inside this view. Default target is the view's root $el.
    - `modifierClasses`: Array of available modifier classes that can be specified are located in 'common/libs/behaviors/card/CardModifierClasses'.
      Anything else passed in will be ignore and a warning will be logged.
      Note that this can be a function that gets called for every target element to allow for dynamically configuring the various elements.

  Triggered Events:
    - Anything trigger the the `update:card:classes` event on the view to update/reapply the classes to the elements.

*/

Behaviors.Card = class Card extends Behavior {

  static Builder = CardBehaviorConfigBuilder;

  static UPDATE_EVENT = 'update:card:classes';

  defaults() {
    return {
      target: '',
      modifierClasses: []
    };
  }

  ui() {
    return {
      target: this.getOption('target')
    };
  }

  initialize(options = {}) {
    ({
      modifierClasses: this.modifierClasses
    } = options);
  }

  onRender() {
    this.view.triggerMethod(Behaviors.Card.UPDATE_EVENT);
  }

  onUpdateCardClasses() {
    this._getTargetEl().each((index, element) => {
      const modifiers = isFunction(this.modifierClasses) ? this.modifierClasses(index, element) : this.modifierClasses;
      const verifiedModifiers = this._verifyModifierClasses(modifiers);
      const newClasses = [BASE_CLASS, ...verifiedModifiers].join(' ');
      const oldClasses = CardModifierClasses.values().join(' ');
      $(element).removeClass(oldClasses)
        .addClass(newClasses);
    });
  }

  _getTargetEl() {
    if (this.getOption('target').length > 0) {
      return this.ui.target;
    }
    return this.$el;

  }

  _verifyModifierClasses(classes = []) {
    try {
      return compact(classes).map((modifier) => {
        return CardModifierClasses.assertLegalValue(modifier);
      });
    } catch (e) {
      logging.warn('Invalid `modifierClass`:', e.message);
      return null;
    }
  }
};

module.exports = Behaviors.Card;
