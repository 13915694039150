// Module
var code = `<% if(isMonthlyLeaderboard) { %>
  <div class="datepicker-wrapper">
    <form id="dateForm" class="month-year-datepicker-form"></form>
  </div>
<% } %>
<div class="ax-grid">
  <div class="top-performers-list-left ax-grid__col--12 ax-grid__col--l-6">
    <h2 id="top-performers-individual-header"><%- t('stats.people', {'teamName': teamName, 'timeFrame': timeFrame}) %></h2>

    <table class="usertable">
      <caption class="off-screen" id="top-performers-individual-caption" ></caption>
      <thead>
        <th class="cell-rank text-align-default-dir"><%- t('stats.rank') %></th>
        <th class="cell-photo" aria-hidden="true"><span class="off-screen"><%- t('stats.avatar') %></span></th>
        <th class="cell-name full-width"><span class="off-screen"><%- t('stats.name') %></span></th>
        <th class="cell-points center"><%-t('stats.points') %></th>
      </thead>
    </table>
  </div>

  <div class="top-performers-list-right ax-grid__col--12 ax-grid__col--l-6">
    <h2 id="top-performers-team-header"><%- t('stats.teams', {'timeFrame': timeFrame}) %></h2>
    <table class="storetable">
      <caption class="off-screen" id="top-performers-team-caption"></caption>
      <thead>
        <th class="cell-rank text-align-default-dir"><%- t('stats.rank') %></th>
        <th><span class="off-screen"><%- t('stats.team') %></span></th>
        <th class="cell-points center"><%-t('stats.teampoints') %></th>
      </thead>
    </table>
  </div>
</div>
`;
// Exports
module.exports = code;