const UIKit = require('@training/widgets/UIKit');
const logging = require('logging');
const $os = require('detectOS');
const _ = require('underscore');

const I18n = require('@common/libs/I18n');

const BrowserHelpers = require('@common/libs/helpers/app/BrowserHelpers');

const ActionBarType = require('@common/components/actionBarButton/ActionBarType');

const actionButtonOptionDefaults = {
  disabled: false
};
const actionBarOptionDefaults = {
  show: true,
  animate: false
};

class ActionBar extends UIKit.View {

  ui() {
    return {
      content: '.actioncontent'
    };
  }

  initialize(options = {}) {
    this.actionTemplate = options.actionTemplate || `
                            <div class="actioncontent"></div>
                          `;
    this.bottomOffset = options.bottomOffset != null ? options.bottomOffset : 0;
    this.forceFixed = options.forceFixed != null ? options.forceFixed : false;
    this.$viewContainer = options.viewContainer != null ? options.viewContainer : $(window);

    this.isAndroidTabletInApp = $os.android && $os.desktop && $os.isInMobileApp();

    this.fixed = false;
  }

  render() {
    this.$el.html(this.actionTemplate);
    return this;
  }

  onRender() {
    this.turnOff();
    this._hide();
  }

  getActionBarDefaultOptions() {
    return actionBarOptionDefaults;
  }

  getActionButtonDefaultOptions() {
    return actionButtonOptionDefaults;
  }

  isFixed() {
    return this.fixed || false;
  }

  toggleFixed(fixed = false ) {
    this.fixed = fixed;

    this.$el.toggleClass('fixed', fixed);

    logging.info(`ActionBar - toggleFixed - fixVisibility`);
    const fixVisibility = !this.isVisible();

    logging.info(`ActionBar - toggleFixed - fixed: ${ fixed }, fixVisibility: ${ fixVisibility }`);
    if (fixVisibility) {
      this._show();
    }

    if (fixVisibility) {
      this._hide();
    }
  }

  toggleScrollBarBuffer(toggleBuffer) {
    const padding = toggleBuffer ? `${ BrowserHelpers.getScrollbarWidth() }px` : '';
    const paddingProp = I18n.isCurrentLanguageRtl() ? 'padding-left' : 'padding-right';
    logging.info(`ActionBar - toggleScrollBarBuffer - toggleBuffer: ${ toggleBuffer }`);
    this.ui.content.css(paddingProp, padding);
  }

  // Allows setting up the action bar with an optional title, buttons and supports display options
  // display options:
  //   show (true/false): if the action bar should be displayed
  //   animate (true/false): if a slideUp/Down animation should be used when show is true
  setActionBar(options = {}) {
    this.turnOff({ hide: options.buttons == null });

    const optionsExtended = $.extend(true, {}, this.getActionBarDefaultOptions(), options);

    // set the title if it's defined
    if (optionsExtended.title != null) {
      this.ui.content.prepend(`<div role="heading" aria-level="3" aria-hidden="${ optionsExtended.title === '' }" class="title">${ options.title }</div>`);
    }

    // add buttons if there are any
    if (optionsExtended.buttons != null) {
      let displayOptions = this.addButtons(
        optionsExtended.buttons,
        optionsExtended.title
      );

      // turn on the action bar and decide if it should get displayed or not
      displayOptions = $.extend(true, {}, displayOptions, {
        show: optionsExtended.show,
        animate: optionsExtended.animate
      });
      this.turnOn(displayOptions);
    }

    this.triggerAdjustment();

    return this;
  }

  trySetFocus(buttonType) {
    this.$(`.js-action-${ buttonType }`).trigger('focus');
  }

  /**
   * Options can be null, object or an array of objects.
   * If it's an object, it must contain atleast a 'type' param.
   * Same goes for any objects in the array version.
   * If it's null then it just turns the entire action bar off.
   * Optional params include 'onClick' callback and 'disabled' which controls the starting state.
   * @param {Object | Object[] | null } options - The button options (or null if no options).
   * @param {{ hide?: boolean }} [displayOptions] - The display options.
   * @returns {void}
  */
  setActionButton(options = null, displayOptions) {
    this.turnOff(displayOptions);

    if (options != null) {
      this.addButtons(options);
      this.turnOn(displayOptions);
    }

    this.triggerAdjustment();
  }

  // this goes through the button array and adds each button
  addButtons(options = [], titleText) {
    const optionsCompact = _.compact([].concat(options));

    for (let [index, option] of optionsCompact.entries()) {
      option = $.extend(true, {}, this.getActionButtonDefaultOptions(), option);
      option.buttonIndex = index;
      this.addButton(option, optionsCompact.length, titleText);
    }
  }

  addButton(options = {}, buttonCount = 1, titleText) {
    let classToAdd;
    options.titleText = titleText;
    const $actionButton = this.createActionButton(options);

    // add specific classes based on number of buttons
    // in the action bar
    switch (buttonCount) {
      case 1:
        classToAdd = 'single';
        break;
      case 2:
        classToAdd = 'sidebyside';
        break;
      case 3:
        classToAdd = 'threebyside';
        break;
      default:
        break;
    }

    $actionButton.addClass(classToAdd);

    this.setupClick($actionButton, options);

    this.addConfiguredButtonToDOM($actionButton, options);

    this.setupInitialState(options);

    this.trigger('force:icon:reflow');
  }

  /**
   * This helper method can be overridden by subclasses to inject extra logic, but
   * obstenibly this method adds a configured actionbutton to the DOM.
   *
   * @param {jQueryObj} $actionButton the configured button to add
   * @param {Object} options Optional. The options hash
   */
  addConfiguredButtonToDOM($actionButton) {
    this.ui.content.append($actionButton);
  }

  createActionButton(options = {}) {
    const {
      type,
      className
    } = options;
    const $actionButton = $(`<button id="action-${ type }" class="axon-button qa-axon-button qa-action-${ type } js-action-${ type } enabled"></button>`);
    let label = '';

    $actionButton.addClass(className);

    switch (type) {
      case ActionBarType.CONTINUE:
        label = I18n.t('general.continue');
        $actionButton.html(`${ label }<span class='rightarrow'></span>`);
        $actionButton.addClass('qa-axon-button--continue');
        break;
      case ActionBarType.SKIP: {
        label = I18n.t('layout.skip');
        const text = `
          <span class='action-text'>${ label }</span>
          <span class='action-icon icon-skip'></span>
        `;
        $actionButton.html(text);
        $actionButton.addClass('qa-axon-button--skip');
        break;
      }
      case ActionBarType.START_GAME:
        label = I18n.t('games.start');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--start');
        break;
      case ActionBarType.SUBMIT:
        label = I18n.t('question.submit');
        options.disabled = true;
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--submit-matches');
        break;
      case ActionBarType.SUBMIT_GENERIC:
      case ActionBarType.SUBMIT_MULTI_ANSWER:
        label = I18n.t('question.submitanswers');
        options.disabled = true;
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--submit');
        break;
      case ActionBarType.SUBMIT_HOTSPOT:
        label = I18n.t('question.submitanswers');
        options.disabled = true;
        $actionButton.html(`${ label } (<span class='markercounter'></span>) `);
        $actionButton.addClass('qa-axon-button--submit-hotspot');
        break;
      case ActionBarType.SUBMIT_PUZZLE:
        label = I18n.t('puzzle.submit');
        $actionButton.html(label);
        $actionButton.removeClass('normal');
        $actionButton.addClass('qa-axon-button--submit-puzzle');
        break;
      case ActionBarType.HINT:
        label = I18n.t('puzzle.hint');
        $actionButton.html(label);
        $actionButton.removeClass('normal');
        $actionButton.addClass('qa-axon-button--hint');
        break;
      case ActionBarType.FINISH:
        label = I18n.t('stats.finish');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--finish');
        break;
      case ActionBarType.SHOW_CORRECT_MATCHES:
        label = I18n.t('question.showcorrectmatches');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--show-correct');
        break;
      case ActionBarType.RIGHT_ARROW:
        label = I18n.t('general.continue');
        $actionButton.html(`${ label }<span class='rightarrow'></span>`);
        $actionButton.addClass('qa-axon-button--right-arrow languagebtn');
        break;
      case ActionBarType.BACK_HUB:
        label = I18n.t('general.back');
        $actionButton.attr('href', '#hub');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--back-hub');
        break;
      case ActionBarType.PROFILE_SAVE:
        label = I18n.t('general.save');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--save-profile');
        break;
      case ActionBarType.DONE_GAME:
        label = I18n.t('general.continue');
        $actionButton.html(label);
        $actionButton.addClass('spaceright save');
        $actionButton.addClass('qa-axon-button--done-game');
        break;
      case ActionBarType.PREVIEW_LINK:
        label = I18n.t('trainingPreview.externalLinkButtonLabel');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--preview-training');
        break;
      case ActionBarType.PREVIEW_PDF:
        label = I18n.t('trainingPreview.pdfButtonLabel');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--preview-training');
        break;
      case ActionBarType.PREVIEW_VIDEO:
        label = I18n.t('trainingPreview.videoButtonLabel');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--preview-training');
        break;
      case ActionBarType.PREVIEW_MODULE:
        label = I18n.t('trainingPreview.moduleButtonLabel');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--preview-training');
        break;
      case ActionBarType.VIEW_TRAINING:
        label = I18n.t('extratraining.viewtraining');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--training-view');
        break;
      case ActionBarType.COMPLETED_TRAINING:
        label = I18n.t('training.completed');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--training-completed');
        break;
      case ActionBarType.SKIP_TRAINING:
        label = I18n.t('training.skip');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--training-skip');
        break;
      case ActionBarType.DONE:
      case ActionBarType.DISMISS_MODAL:
        label = I18n.t('general.done');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--done');
        break;
      case ActionBarType.CANCEL:
      case ActionBarType.DISMISS_UNENROLL_MODAL:
        label = I18n.t('general.cancel');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--cancel');
        break;
      case ActionBarType.UNENROLL:
        label = I18n.t('SelfDirectedPaths.path.unenroll');
        $actionButton.html(label);
        $actionButton.addClass('qa-axon-button--unenroll red');
        break;
      case ActionBarType.FEEDBACK:
        $actionButton.html('<span class=\'icon-flag\'></span>');
        $actionButton.addClass('submit-feedback-button ax-button ax-button--icon ax-button--ghost ax-button--m qa-axon-button--feedback');
        $actionButton.removeClass('axon-button');
        break;
      case ActionBarType.CUSTOM_TEXT:
        label = options.text;
        $actionButton.html(label);
        $actionButton.attr('id', `action-custtomText${ options.buttonIndex }`);
        $actionButton.addClass(`qa-axon-button--customText${ options.buttonIndex } ${ options.className ? options.className : '' }`);
        break;
      default:
        break;
    }

    let ariaLabel = options.ariaLabel || label;
    ariaLabel = options.titleText ? `${ ariaLabel }, ${ options.titleText }` : ariaLabel;
    $actionButton.attr('aria-label', ariaLabel);

    if (options.tooltip) {
      $actionButton.attr('title', options.tooltip);
    }

    return $actionButton;
  }

  setupClick($actionButton, options = {}) {
    // Cache the click listener for enabling/disabling purposes
    $actionButton.data('actionClick', options.onClick != null ? options.onClick : null);

    if (options.onClick) {
      $actionButton.on('click', options.onClick);
    }
  }

  setupInitialState(options = {}) {
    if (options.disabled) {
      this.disableButton(options.type);
    } else {
      this.enableButton(options.type);
    }
  }

  enableButton(type) {
    const $actionButton = this.$(`.js-action-${ type }`);
    const onClick = $actionButton.data('actionClick');
    if ($actionButton.hasClass('disabled')) {
      $actionButton.removeClass('disabled').addClass('enabled')
        .removeAttr('aria-disabled')
        .removeAttr('tabIndex');
      if (onClick) {
        $actionButton.on('click', onClick);
      }
    }
  }

  disableButton(type) {
    const $actionButton = this.$(`.js-action-${ type }`);
    const onClick = $actionButton.data('actionClick');
    if ($actionButton.hasClass('enabled')) {
      $actionButton.removeClass('enabled').addClass('disabled')
        .attr('aria-disabled', true)
        .attr('tabIndex', -1);
      if (onClick) {
        $actionButton.off('click', onClick);
      }
    }
  }

  removeButtons() {
    this.ui.content.empty();
  }

  hasButtons() {
    return this.ui.content.children().length > 0;
  }

  turnOn({
    show = true,
    animate
  } = {}) {
    // Check options to see if the action bar should be displayed
    // if it's not in the options, the default is to display it
    if (show) {
      this.show({ animate });
    }
  }

  turnOff({ hide = true } = {}) {
    // Button Hover
    this.$('.axon-button').on('mouseenter', function() {
      this.$(this).stop() // eslint-disable-line
        .animate({'background-color': '#24a5ff'}, 200);
    });

    // Remove and unbind the current buttons
    this.removeButtons();

    if (hide) {
      this.hide();
    }
  }

  // if animate is set, then display the actionBar with slideDown animation
  // once completed, trigger a resize
  show(options = {}) {
    if (!this.isVisible() && this.hasButtons()) {
      this._show(options);
    }
  }

  _show({ animate = false } = {}) {
    if (animate) {
      this.$el.velocity('slideDown', {
        duration: 150,
        complete: () => {
          this.triggerAdjustment();
        }
      });
    } else {
      this.$el.show();
      this.triggerAdjustment();
    }
  }

  // if animate is set, then hide the actionBar with the slideUp animation
  hide(options = {}) {
    if (this.isVisible()) {
      this._hide(options);
    }
  }

  _hide({ animate = false } = {}) {
    if (animate) {
      this.$el.velocity('slideUp', {
        duration: 150,
        complete: () => {
          this.triggerAdjustment();
        }
      });
    } else {
      this.$el.hide();
      this.triggerAdjustment();
    }
  }

  triggerAdjustment() {
    this.trigger('action:button:update', this.isVisible() && this.hasButtons());
    super.triggerAdjustment();
  }

  onBeforeDestroy() {
    this.turnOff();
  }
}

module.exports = ActionBar;
