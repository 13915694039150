const logging = require('logging');
const $os = require('detectOS');
const dateHelpers = require('@common/libs/dateHelpers');
const _ = require('underscore');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const NativeAppReviewModel = require('@training/apps/common/models/NativeAppReviewModel');
const AchievementCollection = require('@training/apps/common/collections/AchievementCollection');
const AchievementTypeEnum = require('@common/data/enums/AchievementTypeEnum').default;

const {
  canShowMobileReviews,
  getMobileAppInfo,
  showReviewMobileApp
} = require('@common/libs/helpers/app/NativeBridgeHelpers');

const MIN_ACHIEVEMENT_REQUIRED = {
  [AchievementTypeEnum.TOPIC_LEVEL_GRADUATION_STREAK]: 5,
  [AchievementTypeEnum.CORRECT_ANSWER_STREAK]: 50,
  [AchievementTypeEnum.PARTICIPATION_FREQUENCY]: 10,
  [AchievementTypeEnum.SESSION_STREAK]: 10
};
const MIN_ACHIEVEMENT_REQUIRED_NUM = 1;

const NativeAppReviewService = {
  checkAndShowMobileAppReviewModal() {
    if (this.canShowReviewModal()) {
      const nativeAppReviewModel = new NativeAppReviewModel();
      const achievementCollection = new AchievementCollection();
      this.checkMobileAppReviewModal(nativeAppReviewModel, achievementCollection)
        .then(() => {
          showReviewMobileApp();
        })
        .catch((e) => {
          logging.info(`Not showing mobile app review modal because: ${ e.message }`);
        });
    }
  },

  checkMobileAppReviewModal(nativeAppReviewModel, achievementCollection) {
    const deviceType = this.getDeviceType($os);
    let appVersion;
    return getMobileAppInfo().then((appInfo) => {
      appVersion = _.result(appInfo, 'versionData.appVersionCode');
      if (this.usedMobileAppLongEnough(appInfo.firstInstallTime)) {
        return nativeAppReviewModel.fetch({
          data: { deviceType },
          showSpinner: false
        });
      }
      return Promise.reject(new Error('User havent used the mobile app long enough.'));
    })
      .then(() => {
        if (nativeAppReviewModel.shouldShowReviewModal()) {
          return achievementCollection.fetch({showSpinner: false});
        }
        return Promise.reject(new Error('Review modal was shown recently.'));
      })
      .then(() => {
        if (this.hasEnoughAchievements(achievementCollection)) {
          const newNativeAppReviewModel = new NativeAppReviewModel();
          return newNativeAppReviewModel.save({
            deviceType,
            appVersion
          }, {
            showSpinner: false
          });
        }
        return Promise.reject(new Error('User doesnt have enough achievements.'));
      });
  },

  canShowReviewModal(canShowMobileReviewsArg, mobileAppReviewEnabled) {
    const canShowMobileReviewsVal = canShowMobileReviewsArg != null ? canShowMobileReviewsArg : canShowMobileReviews();
    const mobileAppReviewEnabledVal = mobileAppReviewEnabled != null ? mobileAppReviewEnabled : TenantPropertyProvider.get().getProperty('mobileAppReviewEnabled');

    return canShowMobileReviewsVal && mobileAppReviewEnabledVal;
  },

  getDeviceType(os) {
    if (os.android) {
      return 'Android';
    } else if (os.ios) {
      return 'Apple';
    }
    logging.warn('NativeAppReviewService - Unknown device type');
    return undefined;
  },

  hasEnoughAchievements(achievementCollection) {
    const numAchievements = Object.entries(MIN_ACHIEVEMENT_REQUIRED).reduce((memo, [achievementType, min]) => {
      const hasAchievementType = achievementCollection.some((achievementModel) => {
        if (achievementModel.get('achievementType') === achievementType) {
          const iconType = achievementModel.get('iconType'); // example: CorrectAnswerStreak10
          const iconTypeSuffix = iconType.substring(achievementType.length); // example: 10 (string)
          return isNaN(iconTypeSuffix) ? false : parseInt(iconTypeSuffix, 10) >= min;
        }
        return false;
      });
      if (hasAchievementType) {
        return memo + 1;
      }
      return memo;
    }, 0);
    return numAchievements >= MIN_ACHIEVEMENT_REQUIRED_NUM;
  },

  // user should use the mobile app for at least 2 weeks before we show the reviw modal
  usedMobileAppLongEnough(firstInstallTime) {
    const twoWeeksAgo = dateHelpers.performMomentOperation('subtract', 14, 'days');
    return dateHelpers.compareDates(firstInstallTime, 'isBefore', twoWeeksAgo, 'day');
  }
};

module.exports = NativeAppReviewService;
