import {
  Snackbar as MuiSnackbar,
  styled,
  type SnackbarProps as MuiSnackbarProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

export type AxSnackbarProps = MuiSnackbarProps;

const styledOptions = {
  name: 'AxSnackbar'
};

const StyledAxSnackbar = styled(MuiSnackbar, styledOptions)<AxSnackbarProps>(() => {
  return {};
});

/**
 * The `AxSnackbar` component is a wrapper around the MUI `Snackbar` component.
 *
 * ### Links
 * MUI: [Snackbar](https://mui.com/material-ui/react-snackbar/)
 */
export const AxSnackbar = forwardRef(({
  ...otherProps
}: AxSnackbarProps, ref: Ref<HTMLDivElement>) => {
  const SnackbarProps: AxSnackbarProps = {
    ...otherProps
  };

  return (
    <StyledAxSnackbar { ...SnackbarProps } ref={ ref }/>
  );
});

export default AxSnackbar;
