const {
  ItemView
} = require('Marionette');
const Backbone = require('backbone');
const { isDuetSupported } = require('DuetDatePicker');
const I18n = require('@common/libs/I18n');

const Form = require('@common/components/forms/Form');
const dateHelpers = require('@common/libs/dateHelpers');
const SelectOptionCollection = require('@common/libs/selectOptionCollection/SelectOptionCollection');
const CharacterCountView = require('@common/components/discover/views/CharacterCountView');
const Community = require('@common/data/models/Community');
const PageStatus = require('@common/components/discover/enums/PageStatus');

require('@common/components/forms/editors/date/Form.Editor.Date');
require('@common/components/forms/editors/date/Form.Editor.DateTime');
require('@common/components/forms/editors/date/Form.Editor.DateNew');
require('@common/components/forms/editors/date/Form.Editor.DateTimeNew');

const EXPIRY_NOTES_MAX_CHAR_COUNT = 100;

/**
 * For clarity:
 * We refer to this region as "Publish Settings"
 * There are two subsections, "scheduled date" and "expiry date" that are shown or hidden by checkboxes.
 * The Scheduled Date section has a date picker and time zone.
 * The Expiry Date section has just a date (no time or time zone) and an "Expiry Notes" text field.
 */

class PublishSettingsView extends ItemView {
  getTemplate() {
    return `<form class="js-publish_settings_form">
      <div class="js-publish-settings" class="metadata__input hide">

      <h4><%- t('discover.metadata.publishSettings') %></h4>

        <div class="scheduled-date-select">
          <%= axCheckbox({ id: 'scheduled-date-checkbox', classNameInput: 'js-scheduled-date-checkbox qa-scheduled-date-checkbox scheduled-date-checkbox', label: t('discover.metadata.publishOnDate') }) %>
        </div>

        <div class="scheduled-date-controls">
          <div class="scheduled-date-controls__control">
            <label for="startDate"><%- t('discover.metadata.publishDate') %></label>
            <% if (isDuetSupported) { %>
              <div class="date-pick-wrap date-picker-with-time-zone-display">
                <div
                id="startDate" class="date"
                data-field="currentVersion.publishTimestamp" data-editor="DateTimeNew" data-options="scheduledDateOptions"></div>
              </div>
            <% } else { %>
              <div class="date-pick-wrap date-picker-with-time-zone-display">
              <div class="icon-calendar_empty"></div>
              <div class="date-input-container">
                <input id="startDate" class="date" type="text" data-field="currentVersion.publishTimestamp" data-editor="DateTime" data-options="scheduledDateOptions"/>
              </div>
            </div>
            <% } %>
          </div>

          <div class="scheduled-date-controls__control">
            <label for="timezone"><%- t('discover.metadata.timeZone') %></label>
            <span>
              <select id="timezone" class="job-title-selector"
                data-editor="AxonSelect" data-shallow="true" data-field="currentVersion.publishTimeZone" data-options="timezoneCollection" no-default>
              </select>
            </span>
          </div>
        </div>

        <div class="expiry-date-select">
          <%= axCheckbox({ id: 'expiry-date-checkbox', classNameInput: 'js-expiry-date-checkbox expiry-date-checkbox', label: t('discover.metadata.expiryOnDate') }) %>
        </div>

        <div class="expiry-date-controls js-expiry-date-controls">
          <div class="expiry-date-controls__control">

            <label for="expiry-date"><%- t('discover.metadata.expiryDate') %></label>
            <% if (isDuetSupported) { %>

              <div
                id="expiry-date"
                class="date qa-content-expiry-date__input"
                aria-label="<%- t('discover.metadata.expiryDate') %>"
                data-field="expiry.date"
                data-editor="DateNew"
                data-options="expiryDateOptions"
              >
              </div>

            <% } else { %>

              <span class="date-pick-wrap">
              <div class="icon-calendar_empty"></div>
              <input
                id="expiry-date"
                class="date qa-content-expiry-date__input"
                type="text"
                data-field="expiry.date"
                data-editor="Date"
                autocomplete="off"
                data-options="expiryDateOptions"
              >
              </div>

            <% } %>

          </div>

          <div class="expiry-date-controls__control">
            <label for="expiryNotes"><%- t('discover.metadata.expiryNotes') %></label>
            <div class="js-expiry-notes">
            </div>

          </div>
        </div>

      </div>
    </form>`;
  }


  className() {
    return 'metadata__input';
  }

  templateHelpers() {
    return {
      isDuetSupported: isDuetSupported()
    };
  }

  ui() {
    return {

      publishSettingsForm: '.js-publish_settings_form',
      publishSettings: '.js-publish-settings',

      scheduledDatePanel: '.scheduled-date-controls',
      scheduledDateCheckbox: '.js-scheduled-date-checkbox',

      expiryDatePanel: '.js-expiry-date-controls',
      expiryDateCheckbox: '.js-expiry-date-checkbox',
      expiryNotes: '.js-expiry-notes'
    };
  }

  events() {
    return {
      'change .js-scheduled-date-checkbox': '_toggleScheduledDateSettings',
      'change .js-expiry-date-checkbox': '_toggleExpiryDateSettings'
    };
  }

  initialize(options = {}) {
    ({
      communityList: this.communityList
    } = options);

    this._setupTimeZone();

    // we need to listen to any changes to a community, because the user may have different permissions (edit or author) and
    // that will affect whether they are allowed to see or change any publishing settings
    this.listenTo(this, 'change:community', this._togglePublishSettings);
  }

  onRender() {
    // if page has already expired, we want to clear expiry info on edit
    if (this.model.getStatus() === PageStatus.EXPIRED) {
      this.model.set('expiry', {});
    }

    this._togglePublishSettings();

    const expiry = this.model.get('expiry') ?? { notes: '' };
    this.expiryNotesCharacterCountView = new CharacterCountView({
      model: new Backbone.Model(expiry),
      fieldName: 'notes',
      textareaId: 'expiryNotes',
      el: this.ui.expiryNotes,
      maxCharacterCount: EXPIRY_NOTES_MAX_CHAR_COUNT,
      extraCSSClasses: 'expiry-notes',
      extraCharacterCountClasses: 'qa-expiry-notes-character-count',
      placeHolderI18nKey: 'discover.metadata.expiryNotes'
    });
  }

  // this method initializes the Form, including setup of the field editors
  _setupPublishSettingsForm() {
    if (this.publishSettingsForm) {
      return;
    }

    const minScheduledDate = dateHelpers.createCurrentTimeZoneDateInUtc(this.model.currentVersion.get('publishTimeZone'));
    const minExpiryDate = dateHelpers.createDate().add(1, 'day');

    this.publishSettingsForm = new Form({
      el: this.ui.publishSettingsForm,
      model: this.model,
      context: {
        timezoneCollection: {
          collection: this._timeZoneNameCollection,
          axonSelectOptions: {
            width: '100%'
          }
        },
        scheduledDateOptions: {
          minDate: (this.model.currentVersion.get('publishTimestamp') != null) ? null : minScheduledDate.valueOf(),
          allowFieldToBeUnset: true
        },
        expiryDateOptions: {
          minDate: minExpiryDate.valueOf(),
          allowFieldToBeUnset: true
        }
      }
    });
  }

  _togglePublishSettings() {
    // this will show or hide the entire publish settings region, based on the user's permissions
    this.ui.publishSettings.toggle(this._canChangeScheduledOrExpiryDate());

    // set the hide/show state of the two sections, based on whether the model has data
    this._toggleScheduledDateCheckbox();
    this._toggleExpiryDateCheckbox();
  }

  // adjusts state of the checkbox, based on the model data
  _toggleScheduledDateCheckbox() {
    const isScheduled = this.model.get('currentVersion').publishTimestamp != null;
    this.ui.scheduledDateCheckbox.prop('checked', isScheduled);
    this._toggleScheduledDateSettings();
  }

  // adjusts state of the checkbox, based on the model data
  // take note here, that the publishTimestamp datum is inside the currentVersion, but expiryDate is not.
  _toggleExpiryDateCheckbox() {
    const isExpiring = this.model.get('expiry') != null;
    this.ui.expiryDateCheckbox.prop('checked', isExpiring);
    this._toggleExpiryDateSettings();
  }

  // adjusts visibility of the scheduled date panel, based on the state of the checkbox
  _toggleScheduledDateSettings() {
    this.ui.scheduledDatePanel.toggle(this.ui.scheduledDateCheckbox.is(':checked'));
    this._setupPublishSettingsForm();
  }

  // adjusts visibility of the expiry date panel, based on the state of the checkbox
  _toggleExpiryDateSettings() {
    this.ui.expiryDatePanel.toggle(this.ui.expiryDateCheckbox.is(':checked'));
    this._setupPublishSettingsForm();
  }

  _setupTimeZone() {
    this._timeZoneNameCollection = new SelectOptionCollection(dateHelpers.getTimeZoneSelectOptions());
  }

  /**
   * permission to change scheduled or expiry dates is governed by whether the user can edit or create in that community
   * @returns boolean
   */
  _canChangeScheduledOrExpiryDate() {
    const community = this.model.get('community') || new Community();
    return this.model.canEdit() || community.canAuthor(this.model.className) || false;
  }

  commit() {
    const errors = [];

    const expirySet = this.ui.expiryDateCheckbox.is(':checked')
    const expiry = this.model.get('expiry');
    const expiryDate = expiry?.date;
    const minExpiryDate = dateHelpers.createDate().add(1, 'day')
      .startOf('day');
    // we deem the scheduled timestamp valid if scheduled is checked and both the date/time and time zone are provided
    const pubTimestamp = this.model.currentVersion.get('publishTimestamp');
    const isValidScheduledTimeStamp = this.ui.scheduledDateCheckbox.is(':checked') && this.model.currentVersion.get('publishTimeZone') && pubTimestamp;

    if (expirySet) {
      const field = this.publishSettingsForm.fields['expiry.date'];

      // specifically want to check for 'false' value
      if (field.editor.validateFormEditor(true) === false) {
        errors.push(I18n.t('UIKit.Form.errors.date.invalid'));
      }
      if (!expiryDate || expiryDate < minExpiryDate.valueOf() || (isValidScheduledTimeStamp && expiryDate < pubTimestamp) ) {
        errors.push(I18n.t('discover.metadata.errors.expiryInPast'));
      }
      if (this.expiryNotesCharacterCountView.getValue().length > EXPIRY_NOTES_MAX_CHAR_COUNT) {
        errors.push(I18n.t('discover.metadata.errors.expiryNotesTooLong'));
      }
    }

    if (this.ui.scheduledDateCheckbox.is(':checked')) {
      const field = this.publishSettingsForm.fields['currentVersion.publishTimestamp'];

      // specifically want to check for 'false' value
      if (field.editor.validateFormEditor(true) === false) {
        errors.push(I18n.t('UIKit.Form.errors.date.invalid'));
      }
    }

    if (errors.length > 0) {
      return errors;
    }

    if (!this._canChangeScheduledOrExpiryDate()) {
      return [];
    }

    if (!expirySet) {
      this.model.set('expiry', null);
    } else {
      // grab content from that CountView and put it in the model
      this.model.set({
        expiry: {
          date: expiryDate,
          notes: this.expiryNotesCharacterCountView.getValue()
        }
      });
    }

    if (!isValidScheduledTimeStamp) {
      this.model.currentVersion.set('publishTimeZone', null);
      this.model.currentVersion.set('publishTimestamp', null);
    }

    return [];
  }

  destroy() {
    if (this.publishSettingsForm && !this.publishSettingsForm.isDestroyed) {
      this.publishSettingsForm.destroy();
    }
    this.expiryNotesCharacterCountView.destroy();
  }
}

module.exports = PublishSettingsView;
