const env = require('env');

const XhrHelpers = require('XhrHelpers');
const XhrHeaders = require('XhrHeaders');

const SERVER_LOG_LEVELS = {
  IGNORE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4
};

module.exports = {
  LOG_LEVELS: SERVER_LOG_LEVELS,
  send(options = {}) {
    const {
      timestamp = new Date().getTime(),
      message: msg = '',
      level = SERVER_LOG_LEVELS.INFO,
      indexedFields = {},
      async = true
    } = options;

    try {
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open('POST', '/axonify/log', async);

      xmlhttp.setRequestHeader(XhrHeaders.CONTENT_TYPE, 'application/json;charset=UTF-8');
      Object.entries(XhrHelpers.defaultHeaders()).forEach(([header, value]) => {
        xmlhttp.setRequestHeader(header, value);
      });

      xmlhttp.send(JSON.stringify([{
        app: env.settings.app,
        timestamp,
        msg,
        level,
        indexedFields
      }]));
    } catch (error1) { /* nomnomnom */ }
  }
};
