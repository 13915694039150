const PageableCollection = require('@common/components/pageableList/PageableCollection');
class CommunityInsightsReportsCollection extends PageableCollection {
  constructor(models = [], options = {}) {
    options.pageSize = 25;
    super(models, options);
    this.datasetEndpoint = options.datasetEndpoint;
    this.fetchOptions = options.fetchOptions;
  }

  parseState(resp) {
    return {
      totalRecords: resp.totalResults
    };
  }

  apiEndpoint() {
    return `/page/engagement/${ this.datasetEndpoint }`;
  }

  fetch(options = {}) {
    return super.fetch({data: Object.assign({}, this.fetchOptions, options)});
  }
}

module.exports = CommunityInsightsReportsCollection;
