const Backbone = require('Backbone');
const _ = require('underscore');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const StackedLayoutViewController = require('@common/libs/UI/controllers/StackedLayoutViewController');

const ProgressCardView = require('@common/components/learningObjectives/ProgressCardView');
const MilestonesStatusView = require('@common/components/guidedLearning/programs/MilestonesStatusView');
const MilestonesStatusEnrollView = require('@common/components/guidedLearning/programs/MilestoneStatusEnrollView');
const MilestonesStatusCompleteView = require('@common/components/guidedLearning/programs/MilestoneStatusCompleteView');

const { ReactControllerDefinitionFactory } = require('@common/modules/react');

const LearningObjectiveProgressModel = require('@common/components/learningObjectives/LearningObjectiveProgressModel');

class ProgramOverviewProgressController extends StackedLayoutViewController {
  initialize(options = {}) {
    ({
      authChannel: this.authChannel = Backbone.Wreqr.radio.channel('auth'),
      model: this.model,
      showUnassign: this.showUnassign
    } = options);
  }

  viewDefiniton() {
    return {
      className: 'path-progress'
    };
  }

  get childControllers() {
    return [
      this.buildAssignedBadge(),
      this.buildProgressCard(),
      this.milestoneText()
    ];
  }

  buildProgressCard() {
    const model = this.getOption('model');

    const progressModel = new LearningObjectiveProgressModel({
      finishedCount: model.getComputedCompletedCount(),
      availableCount: model.getComputedAvailableCount(),
      percent: model.getTotalObjectiveProgress(),
      animate: true
    });

    return {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: ProgressCardView,
        model: progressModel,
        shouldUsePercentage: true
      }
    };
  }

  milestoneText() {
    // if firstIncompleteMilestone is not null then send in status view
    if (this.getOption('sortedObjectives').firstIncompleteMilestone) {
      return {
        viewDefinition: {
          ViewClass: this.options.model.getEnrollmentStatus() ? MilestonesStatusView : MilestonesStatusEnrollView,
          model: this.getOption('sortedObjectives').firstIncompleteMilestone,
          stayOnTrackNumber: this.getOption('model').stayOnTrackNumber,
          objectives: this.getOption('sortedObjectives'),
          isReadOnly: this.getOption('isReadOnly')
        }
      };
    }
    if (_.isNull(this.getOption('sortedObjectives').firstIncompleteMilestone) && this.getOption('sortedObjectives').milestones.length > 0 && !this.getOption('isReadOnly')) {
      return {
        viewDefinition: {
          ViewClass: MilestonesStatusCompleteView,
          model: new Backbone.Model(),
          stayOnTrackNumber: this.getOption('model').stayOnTrackNumber
        }
      };
    }
    return null;
  }

  buildAssignedBadge() {
    return ReactControllerDefinitionFactory({
      component: import('@common/components/guidedLearning/programs/assignedBadge/UnassignPathContextHost'),
      props: {
        showUnassign: this.showUnassign && this.model.getManagerAssigned(),
        programName: this.model.getName(),
        userId: this.model.userId,
        programId: this.model.programId
      }
    })
  }
}

module.exports = ProgramOverviewProgressController;
