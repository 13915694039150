import {
  type Ref,
  forwardRef,
  type ElementType
} from 'react';
import {
  type CARD_PADDING,
  getCardPadding
} from './AxCard';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  styled,
  CardMedia as MuiCardMedia,
  type CardMediaTypeMap as MuiCardMediaTypeMap
} from '@mui/material';

type AxCardMediaCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  padding?: CARD_PADDING
};

export type AxCardMediaTypeMap<P = object, D extends React.ElementType = 'div'> = MuiCardMediaTypeMap<P & AxCardMediaCustomProps, D>;

export type AxCardMediaProps<
  D extends ElementType = AxCardMediaTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxCardMediaTypeMap<P & AxCardMediaCustomProps, D>, D>;

const styledOptions = {
  name: 'AxCardMedia',
  shouldForwardProp: (prop: string) => {
    return prop !== 'padding';
  }
};

const StyledCardMedia = styled(MuiCardMedia, styledOptions)<AxCardMediaProps>((props) => {
  return {
    padding: getCardPadding(props.padding)
  };
});

export const AxCardMedia: MuiOverridableComponent<AxCardMediaTypeMap> = forwardRef(
  ({
    children,
    padding = 'none',
    ...otherProps
  }: AxCardMediaProps, ref: Ref<HTMLDivElement>) => {
    const propsWithDefaults: AxCardMediaProps = {
      children,
      padding,
      ...otherProps
    };

    return (
      <StyledCardMedia { ...propsWithDefaults } ref={ ref }>
        { children }
      </StyledCardMedia>
    );
  }
);

export default AxCardMedia;
