const {
  forEach,
  keys
} = require('underscore');

const { assertLegalValue } = require('@common/data/enums/VariantType');

module.exports = (QuestionVariantTypePages = {}) => {
  forEach(keys(QuestionVariantTypePages), (key) => {
    return assertLegalValue(parseInt(key, 10));
  });

  return (options = {}) => {
    const { activity } = options;

    const variantType = activity.getQuestionVariantType();

    assertLegalValue(variantType);

    const Page = QuestionVariantTypePages[variantType];

    return new Page(options);
  };
};
