const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const ObjectiveConnectorStatusCssClasses = require('@common/components/guidedLearning/objectives/ObjectiveConnectorStatusCssClasses');

/*
 * In the DOM this is the row including the milestone marker, due date, points availible, bonus points available
 * Created By: MilestoneItemView
 * Model: produced by MilestoneInterface.getMilestoneRowViewModel()
 */
class MilestoneRowView extends Marionette.ItemView {

  modelEvents() {
    return {
      change: 'render'
    };
  }

  getTemplate() {
    return require('./MilestoneRow.html');
  }

  className() {
    return 'milestone-row-container';
  }

  onAttach() {
    if (this.model.get('isGrandfathered')) {
      this.$el.addClass('grandfathered');
    }
  }

  templateHelpers() {
    const complete = this.model.get('isMilestoneComplete');
    return {
      name: this.model.get('name'),
      previousCssClass: ObjectiveConnectorStatusCssClasses[this.model.get('previousVisualConnectorStatus')],
      nextCssClass: ObjectiveConnectorStatusCssClasses[this.model.get('nextVisualConnectorStatus')],
      indicatorCssClass: complete ? 'learning-objectives__progress-indicator--complete' : '',
      getPointsAvailableText: () => {
        let bonusPoints, points, bonusPointsText;

        if (complete) {
          points = this.model.get('pointsEarned');
          bonusPoints = this.model.get('bonusPointsEarned') || 0;
        } else {
          bonusPoints = this.model.get('bonusPointsAvailable') || 0;
          points = this.model.get('pointsAvailable');
        }

        if (bonusPoints === 0 || (complete && !this.model.get('completeOnTime')) || this.model.get('timeRemaining') < 0 ) {
          bonusPointsText = '';
        } else if (points === 0) {
          bonusPointsText = I18n.t('GuidedLearning.milestoneRow.onlyBonusReward', {bonusPointsAvailable: bonusPoints});
        } else {
          bonusPointsText = I18n.t('GuidedLearning.milestoneRow.bonusReward', {bonusPointsAvailable: bonusPoints});
        }

        return (points || '') + bonusPointsText;
      },
      showRewardsSection: () => {
        if (complete) {
          return this.model.get('isGrandfathered') || (this.model.get('bonusPointsEarned') === 0 && this.model.get('pointsEarned') === 0) ? 'hidden' : '';
        }
        return this.model.get('isGrandfathered') || (this.model.get('bonusPointsAvailable') === 0 && this.model.get('pointsAvailable') === 0) ? 'hidden' : '';
      },
      isEnrolled: () => {
        return this.model.get('isEnrolled');
      },
      isGrandfathered: () => {
        return this.model.get('isGrandfathered') ? 'hidden' : '';
      },
      getDueDate: () => {
        if (this.model.get('status') === 'NotStarted') {
          return '';
        }
        const dueDate = this.model.get('dueDate');
        let humanReadableDate = dateHelpers.convertDateFormatShortDateWithStrings(dueDate);

        if (complete) {
          humanReadableDate = dateHelpers.convertDateFormatShortDateWithStrings(this.model.get('completionDate'));
          return I18n.t('GuidedLearning.milestoneRow.dueDateComplete', {completionDate: humanReadableDate});
        }
        return I18n.t('GuidedLearning.milestoneRow.dueDate', {
          numComplete: this.model.get('numComplete'),
          numItemsTotal: this.model.get('numItemsTotal'),
          humanReadableDate: humanReadableDate
        });
      },
      getDuration: () => {
        const duration = this.model.get('duration');

        return I18n.t('GuidedLearning.milestoneRow.duration', {duration});
      },
      getRewardIcon: () => {
        return complete ? 'icon-ok green-background' : 'hidden';
      }
    };
  }
}

module.exports = MilestoneRowView;
