class CSSInjectorPackageFactory {

  constructor(key) {
    this.key = key;
  }

  create() {
    return {
      key: this.key,
      paths: []
    };
  }
}

module.exports = CSSInjectorPackageFactory;
