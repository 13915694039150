const Backbone = require('Backbone');

const UserMessage = require('../models/UserMessage');

class UserMessageList extends Backbone.Collection {
  get model() {
    return UserMessage;
  }

  comparator(model) {
    return model.get('createDate');
  }

  url() {
    return '/axonify/userMessages';
  }

  parse(response) {
    return response.entities;
  }

  hasUnreadMessages() {
    return this.getUnreadMessages().length > 0;
  }

  getUnreadMessages() {
    return this.where({delivered: false});
  }

  getUnreadUserMessages() {
    return new UserMessageList(this.filter(
      (message) => {
        return message.get('delivered') === false && message.get('type') !== 'PIN_AWARD';
      }
    ));
  }

  getUnreadRecognitionPinMessages() {
    return new UserMessageList(this.where({
      delivered: false,
      type: 'PIN_AWARD'
    }));
  }

  markAllRead() {
    this.each((message) => {
      message.set('delivered', true);
    });

    $.ajax({
      type: 'PUT',
      url: `${ this.url() }/markDelivered`,
      data: JSON.stringify(this.pluck('id'))
    });
  }
}

module.exports = UserMessageList;
