const TabController = require('@common/components/tabbed_layout/TabController');
require('@common/libs/behaviors/scrollable/Scrollable');

class ReactionsTabController extends TabController {
  viewDefinition() {
    return Object.assign({
      behaviors: {
        Scrollable: {
          scrollableContainer: '.tabbed-layout'
        }
      }
    }, super.viewDefinition());
  }
}

module.exports = ReactionsTabController;
