const { ItemView } = require('Marionette');
const I18n = require('@common/libs/I18n');

module.exports = (pauseAssessmentCallback = () => {}) => {
  return () => {
    return {
      viewDefinition: {
        ViewClass: ItemView,
        className: 'assessment-bail-button',
        template: `<button type="button" class="icon-remove" aria-label="${ I18n.t('assessments.bail.label') }"></button>`,
        events: {
          click: () => {
            pauseAssessmentCallback();
          }
        }
      }
    };
  };
};
